export const CitiesList = [
  {
    "city": "Tokyo",
    "city_ascii": "Tokyo",
    "lat": 35.6897,
    "lng": 139.6922,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Tōkyō",
    "capital": "primary",
    "population": 37977000,
    "id": 1392685764
  },
  {
    "city": "Jakarta",
    "city_ascii": "Jakarta",
    "lat": -6.2146,
    "lng": 106.8451,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Jakarta",
    "capital": "primary",
    "population": 34540000,
    "id": 1360771077
  },
  {
    "city": "Delhi",
    "city_ascii": "Delhi",
    "lat": 28.66,
    "lng": 77.23,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Delhi",
    "capital": "admin",
    "population": 29617000,
    "id": 1356872604
  },
  {
    "city": "Mumbai",
    "city_ascii": "Mumbai",
    "lat": 18.9667,
    "lng": 72.8333,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Mahārāshtra",
    "capital": "admin",
    "population": 23355000,
    "id": 1356226629
  },
  {
    "city": "Manila",
    "city_ascii": "Manila",
    "lat": 14.5958,
    "lng": 120.9772,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Manila",
    "capital": "primary",
    "population": 23088000,
    "id": 1608618140
  },
  {
    "city": "Shanghai",
    "city_ascii": "Shanghai",
    "lat": 31.1667,
    "lng": 121.4667,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Shanghai",
    "capital": "admin",
    "population": 22120000,
    "id": 1156073548
  },
  {
    "city": "São Paulo",
    "city_ascii": "Sao Paulo",
    "lat": -23.5504,
    "lng": -46.6339,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "admin",
    "population": 22046000,
    "id": 1076532519
  },
  {
    "city": "Seoul",
    "city_ascii": "Seoul",
    "lat": 37.5833,
    "lng": 127,
    "country": "Korea, South",
    "iso2": "KR",
    "iso3": "KOR",
    "admin_name": "Seoul",
    "capital": "primary",
    "population": 21794000,
    "id": 1410836482
  },
  {
    "city": "Mexico City",
    "city_ascii": "Mexico City",
    "lat": 19.4333,
    "lng": -99.1333,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Ciudad de México",
    "capital": "primary",
    "population": 20996000,
    "id": 1484247881
  },
  {
    "city": "Guangzhou",
    "city_ascii": "Guangzhou",
    "lat": 23.1288,
    "lng": 113.259,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Guangdong",
    "capital": "admin",
    "population": 20902000,
    "id": 1156237133
  },
  {
    "city": "Beijing",
    "city_ascii": "Beijing",
    "lat": 39.905,
    "lng": 116.3914,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Beijing",
    "capital": "primary",
    "population": 19433000,
    "id": 1156228865
  },
  {
    "city": "Cairo",
    "city_ascii": "Cairo",
    "lat": 30.0561,
    "lng": 31.2394,
    "country": "Egypt",
    "iso2": "EG",
    "iso3": "EGY",
    "admin_name": "Al Qāhirah",
    "capital": "primary",
    "population": 19372000,
    "id": 1818253931
  },
  {
    "city": "New York",
    "city_ascii": "New York",
    "lat": 40.6943,
    "lng": -73.9249,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 18713220,
    "id": 1840034016
  },
  {
    "city": "Kolkāta",
    "city_ascii": "Kolkata",
    "lat": 22.5411,
    "lng": 88.3378,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "West Bengal",
    "capital": "admin",
    "population": 17560000,
    "id": 1356060520
  },
  {
    "city": "Moscow",
    "city_ascii": "Moscow",
    "lat": 55.7558,
    "lng": 37.6178,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Moskva",
    "capital": "primary",
    "population": 17125000,
    "id": 1643318494
  },
  {
    "city": "Bangkok",
    "city_ascii": "Bangkok",
    "lat": 13.75,
    "lng": 100.5167,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Krung Thep Maha Nakhon",
    "capital": "primary",
    "population": 17066000,
    "id": 1764068610
  },
  {
    "city": "Buenos Aires",
    "city_ascii": "Buenos Aires",
    "lat": -34.5997,
    "lng": -58.3819,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires, Ciudad Autónoma de",
    "capital": "primary",
    "population": 16157000,
    "id": 1032717330
  },
  {
    "city": "Shenzhen",
    "city_ascii": "Shenzhen",
    "lat": 22.535,
    "lng": 114.054,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Guangdong",
    "capital": "minor",
    "population": 15929000,
    "id": 1156158707
  },
  {
    "city": "Dhaka",
    "city_ascii": "Dhaka",
    "lat": 23.7161,
    "lng": 90.3961,
    "country": "Bangladesh",
    "iso2": "BD",
    "iso3": "BGD",
    "admin_name": "Dhaka",
    "capital": "primary",
    "population": 15443000,
    "id": 1050529279
  },
  {
    "city": "Lagos",
    "city_ascii": "Lagos",
    "lat": 6.45,
    "lng": 3.4,
    "country": "Nigeria",
    "iso2": "NG",
    "iso3": "NGA",
    "admin_name": "Lagos",
    "capital": "minor",
    "population": 15279000,
    "id": 1566593751
  },
  {
    "city": "Istanbul",
    "city_ascii": "Istanbul",
    "lat": 41.01,
    "lng": 28.9603,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "İstanbul",
    "capital": "admin",
    "population": 15154000,
    "id": 1792756324
  },
  {
    "city": "Ōsaka",
    "city_ascii": "Osaka",
    "lat": 34.6936,
    "lng": 135.5019,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Ōsaka",
    "capital": "admin",
    "population": 14977000,
    "id": 1392419823
  },
  {
    "city": "Karachi",
    "city_ascii": "Karachi",
    "lat": 24.86,
    "lng": 67.01,
    "country": "Pakistan",
    "iso2": "PK",
    "iso3": "PAK",
    "admin_name": "Sindh",
    "capital": "admin",
    "population": 14835000,
    "id": 1586129469
  },
  {
    "city": "Bangalore",
    "city_ascii": "Bangalore",
    "lat": 12.9699,
    "lng": 77.598,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Karnātaka",
    "capital": "admin",
    "population": 13707000,
    "id": 1356410365
  },
  {
    "city": "Tehran",
    "city_ascii": "Tehran",
    "lat": 35.7,
    "lng": 51.4167,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Tehrān",
    "capital": "primary",
    "population": 13633000,
    "id": 1364305026
  },
  {
    "city": "Kinshasa",
    "city_ascii": "Kinshasa",
    "lat": -4.3317,
    "lng": 15.3139,
    "country": "Congo (Kinshasa)",
    "iso2": "CD",
    "iso3": "COD",
    "admin_name": "Kinshasa",
    "capital": "primary",
    "population": 13528000,
    "id": 1180000363
  },
  {
    "city": "Ho Chi Minh City",
    "city_ascii": "Ho Chi Minh City",
    "lat": 10.8167,
    "lng": 106.6333,
    "country": "Vietnam",
    "iso2": "VN",
    "iso3": "VNM",
    "admin_name": "Hồ Chí Minh",
    "capital": "admin",
    "population": 13312000,
    "id": 1704774326
  },
  {
    "city": "Los Angeles",
    "city_ascii": "Los Angeles",
    "lat": 34.1139,
    "lng": -118.4068,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 12750807,
    "id": 1840020491
  },
  {
    "city": "Rio de Janeiro",
    "city_ascii": "Rio de Janeiro",
    "lat": -22.9083,
    "lng": -43.1964,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Rio de Janeiro",
    "capital": "admin",
    "population": 12272000,
    "id": 1076887657
  },
  {
    "city": "Nanyang",
    "city_ascii": "Nanyang",
    "lat": 32.9987,
    "lng": 112.5292,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Henan",
    "capital": "",
    "population": 12010000,
    "id": 1156192287
  },
  {
    "city": "Chennai",
    "city_ascii": "Chennai",
    "lat": 13.0825,
    "lng": 80.275,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Tamil Nādu",
    "capital": "admin",
    "population": 11324000,
    "id": 1356374944
  },
  {
    "city": "Chengdu",
    "city_ascii": "Chengdu",
    "lat": 30.6636,
    "lng": 104.0667,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Sichuan",
    "capital": "admin",
    "population": 11309000,
    "id": 1156421555
  },
  {
    "city": "Lahore",
    "city_ascii": "Lahore",
    "lat": 31.5497,
    "lng": 74.3436,
    "country": "Pakistan",
    "iso2": "PK",
    "iso3": "PAK",
    "admin_name": "Punjab",
    "capital": "admin",
    "population": 11021000,
    "id": 1586801463
  },
  {
    "city": "Paris",
    "city_ascii": "Paris",
    "lat": 48.8566,
    "lng": 2.3522,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "primary",
    "population": 11020000,
    "id": 1250015082
  },
  {
    "city": "London",
    "city_ascii": "London",
    "lat": 51.5072,
    "lng": -0.1275,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "London, City of",
    "capital": "primary",
    "population": 10979000,
    "id": 1826645935
  },
  {
    "city": "Linyi",
    "city_ascii": "Linyi",
    "lat": 35.0606,
    "lng": 118.3425,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Shandong",
    "capital": "",
    "population": 10820000,
    "id": 1156086320
  },
  {
    "city": "Tianjin",
    "city_ascii": "Tianjin",
    "lat": 39.1467,
    "lng": 117.2056,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Tianjin",
    "capital": "admin",
    "population": 10800000,
    "id": 1156174046
  },
  {
    "city": "Shijiazhuang",
    "city_ascii": "Shijiazhuang",
    "lat": 38.0422,
    "lng": 114.5086,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hebei",
    "capital": "admin",
    "population": 10784600,
    "id": 1156217541
  },
  {
    "city": "Baoding",
    "city_ascii": "Baoding",
    "lat": 38.8671,
    "lng": 115.4845,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hebei",
    "capital": "",
    "population": 10700000,
    "id": 1156256829
  },
  {
    "city": "Zhoukou",
    "city_ascii": "Zhoukou",
    "lat": 33.625,
    "lng": 114.6418,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Henan",
    "capital": "minor",
    "population": 9901000,
    "id": 1156272098
  },
  {
    "city": "Lima",
    "city_ascii": "Lima",
    "lat": -12.05,
    "lng": -77.0333,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "Lima",
    "capital": "primary",
    "population": 9848000,
    "id": 1604728603
  },
  {
    "city": "Hyderābād",
    "city_ascii": "Hyderabad",
    "lat": 17.3667,
    "lng": 78.4667,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Telangana",
    "capital": "admin",
    "population": 9746000,
    "id": 1356871768
  },
  {
    "city": "Bogotá",
    "city_ascii": "Bogota",
    "lat": 4.6126,
    "lng": -74.0705,
    "country": "Colombia",
    "iso2": "CO",
    "iso3": "COL",
    "admin_name": "Bogotá",
    "capital": "primary",
    "population": 9464000,
    "id": 1170483426
  },
  {
    "city": "Weifang",
    "city_ascii": "Weifang",
    "lat": 36.7167,
    "lng": 119.1,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Shandong",
    "capital": "",
    "population": 9373000,
    "id": 1156912965
  },
  {
    "city": "Nagoya",
    "city_ascii": "Nagoya",
    "lat": 35.1167,
    "lng": 136.9333,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Aichi",
    "capital": "admin",
    "population": 9113000,
    "id": 1392407472
  },
  {
    "city": "Wuhan",
    "city_ascii": "Wuhan",
    "lat": 30.5872,
    "lng": 114.2881,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hubei",
    "capital": "admin",
    "population": 8962000,
    "id": 1156117184
  },
  {
    "city": "Heze",
    "city_ascii": "Heze",
    "lat": 35.2333,
    "lng": 115.4333,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Shandong",
    "capital": "minor",
    "population": 8750000,
    "id": 1156225008
  },
  {
    "city": "Ganzhou",
    "city_ascii": "Ganzhou",
    "lat": 25.8292,
    "lng": 114.9336,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Jiangxi",
    "capital": "minor",
    "population": 8677600,
    "id": 1156832475
  },
  {
    "city": "Tongshan",
    "city_ascii": "Tongshan",
    "lat": 34.261,
    "lng": 117.1859,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Jiangsu",
    "capital": "minor",
    "population": 8669000,
    "id": 1156241678
  },
  {
    "city": "Chicago",
    "city_ascii": "Chicago",
    "lat": 41.8373,
    "lng": -87.6862,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 8604203,
    "id": 1840000494
  },
  {
    "city": "Handan",
    "city_ascii": "Handan",
    "lat": 36.6116,
    "lng": 114.4894,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hebei",
    "capital": "",
    "population": 8499000,
    "id": 1156957080
  },
  {
    "city": "Luanda",
    "city_ascii": "Luanda",
    "lat": -8.8383,
    "lng": 13.2344,
    "country": "Angola",
    "iso2": "AO",
    "iso3": "AGO",
    "admin_name": "Luanda",
    "capital": "primary",
    "population": 8417000,
    "id": 1024949724
  },
  {
    "city": "Fuyang",
    "city_ascii": "Fuyang",
    "lat": 32.8986,
    "lng": 115.8045,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Anhui",
    "capital": "",
    "population": 8360000,
    "id": 1156248008
  },
  {
    "city": "Kuala Lumpur",
    "city_ascii": "Kuala Lumpur",
    "lat": 3.1478,
    "lng": 101.6953,
    "country": "Malaysia",
    "iso2": "MY",
    "iso3": "MYS",
    "admin_name": "Kuala Lumpur",
    "capital": "primary",
    "population": 8285000,
    "id": 1458988644
  },
  {
    "city": "Jining",
    "city_ascii": "Jining",
    "lat": 35.4,
    "lng": 116.5667,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Shandong",
    "capital": "",
    "population": 8023000,
    "id": 1156504601
  },
  {
    "city": "Dongguan",
    "city_ascii": "Dongguan",
    "lat": 23.0475,
    "lng": 113.7493,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Guangdong",
    "capital": "minor",
    "population": 7981000,
    "id": 1156478242
  },
  {
    "city": "Hanoi",
    "city_ascii": "Hanoi",
    "lat": 21.0245,
    "lng": 105.8412,
    "country": "Vietnam",
    "iso2": "VN",
    "iso3": "VNM",
    "admin_name": "Hà Nội",
    "capital": "primary",
    "population": 7785000,
    "id": 1704413791
  },
  {
    "city": "Pune",
    "city_ascii": "Pune",
    "lat": 18.5196,
    "lng": 73.8553,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Mahārāshtra",
    "capital": "",
    "population": 7764000,
    "id": 1356081074
  },
  {
    "city": "Chongqing",
    "city_ascii": "Chongqing",
    "lat": 29.55,
    "lng": 106.5069,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Chongqing",
    "capital": "admin",
    "population": 7739000,
    "id": 1156936556
  },
  {
    "city": "Changchun",
    "city_ascii": "Changchun",
    "lat": 43.9,
    "lng": 125.2,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Jilin",
    "capital": "admin",
    "population": 7674439,
    "id": 1156078103
  },
  {
    "city": "Zhumadian",
    "city_ascii": "Zhumadian",
    "lat": 32.9773,
    "lng": 114.0253,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Henan",
    "capital": "",
    "population": 7640000,
    "id": 1156184822
  },
  {
    "city": "Ningbo",
    "city_ascii": "Ningbo",
    "lat": 29.875,
    "lng": 121.5492,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Zhejiang",
    "capital": "minor",
    "population": 7639000,
    "id": 1156170787
  },
  {
    "city": "Onitsha",
    "city_ascii": "Onitsha",
    "lat": 6.1667,
    "lng": 6.7833,
    "country": "Nigeria",
    "iso2": "NG",
    "iso3": "NGA",
    "admin_name": "Anambra",
    "capital": "minor",
    "population": 7635000,
    "id": 1566593483
  },
  {
    "city": "Nanjing",
    "city_ascii": "Nanjing",
    "lat": 32.05,
    "lng": 118.7667,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Jiangsu",
    "capital": "admin",
    "population": 7496000,
    "id": 1156644065
  },
  {
    "city": "Hefei",
    "city_ascii": "Hefei",
    "lat": 31.8639,
    "lng": 117.2808,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Anhui",
    "capital": "admin",
    "population": 7457027,
    "id": 1156332710
  },
  {
    "city": "Ahmadābād",
    "city_ascii": "Ahmadabad",
    "lat": 23.03,
    "lng": 72.58,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Gujarāt",
    "capital": "minor",
    "population": 7410000,
    "id": 1356304381
  },
  {
    "city": "Hong Kong",
    "city_ascii": "Hong Kong",
    "lat": 22.305,
    "lng": 114.185,
    "country": "Hong Kong",
    "iso2": "HK",
    "iso3": "HKG",
    "admin_name": "",
    "capital": "",
    "population": 7347000,
    "id": 1344982653
  },
  {
    "city": "Khartoum",
    "city_ascii": "Khartoum",
    "lat": 15.6031,
    "lng": 32.5265,
    "country": "Sudan",
    "iso2": "SD",
    "iso3": "SDN",
    "admin_name": "Khartoum",
    "capital": "primary",
    "population": 7282000,
    "id": 1729268475
  },
  {
    "city": "Nantong",
    "city_ascii": "Nantong",
    "lat": 31.9829,
    "lng": 120.8873,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Jiangsu",
    "capital": "minor",
    "population": 7282835,
    "id": 1156045365
  },
  {
    "city": "Yancheng",
    "city_ascii": "Yancheng",
    "lat": 33.3936,
    "lng": 120.1339,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Jiangsu",
    "capital": "minor",
    "population": 7260240,
    "id": 1156995410
  },
  {
    "city": "Foshan",
    "city_ascii": "Foshan",
    "lat": 23.0292,
    "lng": 113.1056,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Guangdong",
    "capital": "minor",
    "population": 7194311,
    "id": 1156738403
  },
  {
    "city": "Nanning",
    "city_ascii": "Nanning",
    "lat": 22.8192,
    "lng": 108.315,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Guangxi",
    "capital": "admin",
    "population": 7153300,
    "id": 1156605439
  },
  {
    "city": "Hengyang",
    "city_ascii": "Hengyang",
    "lat": 26.8968,
    "lng": 112.5857,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hunan",
    "capital": "",
    "population": 7148344,
    "id": 1156696884
  },
  {
    "city": "Xi’an",
    "city_ascii": "Xi'an",
    "lat": 34.2667,
    "lng": 108.9,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Shaanxi",
    "capital": "admin",
    "population": 7135000,
    "id": 1156244079
  },
  {
    "city": "Shenyang",
    "city_ascii": "Shenyang",
    "lat": 41.8039,
    "lng": 123.4258,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Liaoning",
    "capital": "admin",
    "population": 7105000,
    "id": 1156309382
  },
  {
    "city": "Tangshan",
    "city_ascii": "Tangshan",
    "lat": 39.6292,
    "lng": 118.1742,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hebei",
    "capital": "",
    "population": 7100000,
    "id": 1156904299
  },
  {
    "city": "Shaoyang",
    "city_ascii": "Shaoyang",
    "lat": 27.2418,
    "lng": 111.4725,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hunan",
    "capital": "",
    "population": 7071000,
    "id": 1156310366
  },
  {
    "city": "Changsha",
    "city_ascii": "Changsha",
    "lat": 28.1987,
    "lng": 112.9709,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hunan",
    "capital": "admin",
    "population": 7044118,
    "id": 1156961497
  },
  {
    "city": "Santiago",
    "city_ascii": "Santiago",
    "lat": -33.45,
    "lng": -70.6667,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Región Metropolitana",
    "capital": "primary",
    "population": 7007000,
    "id": 1152554349
  },
  {
    "city": "Zhengzhou",
    "city_ascii": "Zhengzhou",
    "lat": 34.7492,
    "lng": 113.6605,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Henan",
    "capital": "admin",
    "population": 7005000,
    "id": 1156183137
  },
  {
    "city": "Zhanjiang",
    "city_ascii": "Zhanjiang",
    "lat": 21.1967,
    "lng": 110.4031,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Guangdong",
    "capital": "minor",
    "population": 6900000,
    "id": 1156502170
  },
  {
    "city": "Riyadh",
    "city_ascii": "Riyadh",
    "lat": 24.65,
    "lng": 46.71,
    "country": "Saudi Arabia",
    "iso2": "SA",
    "iso3": "SAU",
    "admin_name": "Ar Riyāḑ",
    "capital": "primary",
    "population": 6881000,
    "id": 1682999334
  },
  {
    "city": "Cangzhou",
    "city_ascii": "Cangzhou",
    "lat": 38.3037,
    "lng": 116.8452,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hebei",
    "capital": "",
    "population": 6800000,
    "id": 1156698069
  },
  {
    "city": "Dar es Salaam",
    "city_ascii": "Dar es Salaam",
    "lat": -6.8,
    "lng": 39.2833,
    "country": "Tanzania",
    "iso2": "TZ",
    "iso3": "TZA",
    "admin_name": "Dar es Salaam",
    "capital": "primary",
    "population": 6698000,
    "id": 1834843853
  },
  {
    "city": "Maoming",
    "city_ascii": "Maoming",
    "lat": 21.6618,
    "lng": 110.9178,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Guangdong",
    "capital": "minor",
    "population": 6706000,
    "id": 1156568722
  },
  {
    "city": "Huanggang",
    "city_ascii": "Huanggang",
    "lat": 30.45,
    "lng": 114.875,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hubei",
    "capital": "minor",
    "population": 6667000,
    "id": 1156200037
  },
  {
    "city": "Xinyang",
    "city_ascii": "Xinyang",
    "lat": 32.1264,
    "lng": 114.0672,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Henan",
    "capital": "",
    "population": 6634000,
    "id": 1156273453
  },
  {
    "city": "Shangrao",
    "city_ascii": "Shangrao",
    "lat": 28.4419,
    "lng": 117.9633,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Jiangxi",
    "capital": "minor",
    "population": 6579714,
    "id": 1156405492
  },
  {
    "city": "Luoyang",
    "city_ascii": "Luoyang",
    "lat": 34.6587,
    "lng": 112.4245,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Henan",
    "capital": "",
    "population": 6549941,
    "id": 1156069315
  },
  {
    "city": "Bijie",
    "city_ascii": "Bijie",
    "lat": 27.3019,
    "lng": 105.2863,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Guizhou",
    "capital": "",
    "population": 6537498,
    "id": 1156018927
  },
  {
    "city": "Yantai",
    "city_ascii": "Yantai",
    "lat": 37.3997,
    "lng": 121.2664,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Shandong",
    "capital": "",
    "population": 6516900,
    "id": 1156932948
  },
  {
    "city": "Quanzhou",
    "city_ascii": "Quanzhou",
    "lat": 24.9139,
    "lng": 118.5858,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Fujian",
    "capital": "minor",
    "population": 6480000,
    "id": 1156829655
  },
  {
    "city": "Hangzhou",
    "city_ascii": "Hangzhou",
    "lat": 30.25,
    "lng": 120.1675,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Zhejiang",
    "capital": "admin",
    "population": 6446000,
    "id": 1156275243
  },
  {
    "city": "Miami",
    "city_ascii": "Miami",
    "lat": 25.7839,
    "lng": -80.2102,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 6445545,
    "id": 1840015149
  },
  {
    "city": "Kunming",
    "city_ascii": "Kunming",
    "lat": 25.0433,
    "lng": 102.7061,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Yunnan",
    "capital": "admin",
    "population": 6250000,
    "id": 1156477539
  },
  {
    "city": "Nanchong",
    "city_ascii": "Nanchong",
    "lat": 30.7991,
    "lng": 106.0784,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Sichuan",
    "capital": "minor",
    "population": 6183000,
    "id": 1156762337
  },
  {
    "city": "Zunyi",
    "city_ascii": "Zunyi",
    "lat": 27.705,
    "lng": 106.9336,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Guizhou",
    "capital": "",
    "population": 6127009,
    "id": 1156539782
  },
  {
    "city": "Lu’an",
    "city_ascii": "Lu'an",
    "lat": 31.7542,
    "lng": 116.5078,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Anhui",
    "capital": "minor",
    "population": 6090000,
    "id": 1156499624
  },
  {
    "city": "Yichun",
    "city_ascii": "Yichun",
    "lat": 27.8041,
    "lng": 114.383,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Jiangxi",
    "capital": "minor",
    "population": 6048700,
    "id": 1156229590
  },
  {
    "city": "Taizhou",
    "city_ascii": "Taizhou",
    "lat": 28.6583,
    "lng": 121.4221,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Zhejiang",
    "capital": "minor",
    "population": 5968838,
    "id": 1156322307
  },
  {
    "city": "Liaocheng",
    "city_ascii": "Liaocheng",
    "lat": 36.45,
    "lng": 115.9833,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Shandong",
    "capital": "minor",
    "population": 5955300,
    "id": 1156006600
  },
  {
    "city": "Qujing",
    "city_ascii": "Qujing",
    "lat": 25.5102,
    "lng": 103.8029,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Yunnan",
    "capital": "",
    "population": 5855055,
    "id": 1156747998
  },
  {
    "city": "Xiangyang",
    "city_ascii": "Xiangyang",
    "lat": 32.0654,
    "lng": 112.1531,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hubei",
    "capital": "minor",
    "population": 5844000,
    "id": 1156107325
  },
  {
    "city": "Sūrat",
    "city_ascii": "Surat",
    "lat": 21.17,
    "lng": 72.83,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Gujarāt",
    "capital": "",
    "population": 5807000,
    "id": 1356758738
  },
  {
    "city": "Baghdad",
    "city_ascii": "Baghdad",
    "lat": 33.35,
    "lng": 44.4167,
    "country": "Iraq",
    "iso2": "IQ",
    "iso3": "IRQ",
    "admin_name": "Baghdād",
    "capital": "primary",
    "population": 5796000,
    "id": 1368596238
  },
  {
    "city": "Qingdao",
    "city_ascii": "Qingdao",
    "lat": 36.1167,
    "lng": 120.4,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Shandong",
    "capital": "minor",
    "population": 5775000,
    "id": 1156112588
  },
  {
    "city": "Singapore",
    "city_ascii": "Singapore",
    "lat": 1.3,
    "lng": 103.8,
    "country": "Singapore",
    "iso2": "SG",
    "iso3": "SGP",
    "admin_name": "Central Singapore",
    "capital": "primary",
    "population": 5745000,
    "id": 1702341327
  },
  {
    "city": "Dallas",
    "city_ascii": "Dallas",
    "lat": 32.7936,
    "lng": -96.7662,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 5743938,
    "id": 1840019440
  },
  {
    "city": "Changde",
    "city_ascii": "Changde",
    "lat": 29.0397,
    "lng": 111.6839,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hunan",
    "capital": "minor",
    "population": 5717200,
    "id": 1156449091
  },
  {
    "city": "Dazhou",
    "city_ascii": "Dazhou",
    "lat": 31.2152,
    "lng": 107.4947,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Sichuan",
    "capital": "minor",
    "population": 5693000,
    "id": 1156834076
  },
  {
    "city": "Suzhou",
    "city_ascii": "Suzhou",
    "lat": 33.6333,
    "lng": 116.9683,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Anhui",
    "capital": "",
    "population": 5690000,
    "id": 1156871297
  },
  {
    "city": "Philadelphia",
    "city_ascii": "Philadelphia",
    "lat": 40.0077,
    "lng": -75.1339,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5649300,
    "id": 1840000673
  },
  {
    "city": "Jieyang",
    "city_ascii": "Jieyang",
    "lat": 23.5533,
    "lng": 116.3649,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Guangdong",
    "capital": "minor",
    "population": 5634000,
    "id": 1156260378
  },
  {
    "city": "Nairobi",
    "city_ascii": "Nairobi",
    "lat": -1.2864,
    "lng": 36.8172,
    "country": "Kenya",
    "iso2": "KE",
    "iso3": "KEN",
    "admin_name": "Nairobi City",
    "capital": "primary",
    "population": 5545000,
    "id": 1404000661
  },
  {
    "city": "Nangandao",
    "city_ascii": "Nangandao",
    "lat": 35.2992,
    "lng": 113.8851,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Henan",
    "capital": "minor",
    "population": 5516000,
    "id": 1156127660
  },
  {
    "city": "Ankara",
    "city_ascii": "Ankara",
    "lat": 39.93,
    "lng": 32.85,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Ankara",
    "capital": "primary",
    "population": 5503985,
    "id": 1792572891
  },
  {
    "city": "Tai’an",
    "city_ascii": "Tai'an",
    "lat": 36.2001,
    "lng": 117.0809,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Shandong",
    "capital": "",
    "population": 5499000,
    "id": 1156095188
  },
  {
    "city": "Yulin",
    "city_ascii": "Yulin",
    "lat": 22.6293,
    "lng": 110.1507,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Guangxi",
    "capital": "",
    "population": 5487368,
    "id": 1156901312
  },
  {
    "city": "Dezhou",
    "city_ascii": "Dezhou",
    "lat": 37.4513,
    "lng": 116.3105,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Shandong",
    "capital": "minor",
    "population": 5470000,
    "id": 1156524080
  },
  {
    "city": "Houston",
    "city_ascii": "Houston",
    "lat": 29.7863,
    "lng": -95.3889,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 5464251,
    "id": 1840020925
  },
  {
    "city": "Atlanta",
    "city_ascii": "Atlanta",
    "lat": 33.7627,
    "lng": -84.4224,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "admin",
    "population": 5449398,
    "id": 1840013660
  },
  {
    "city": "Rangoon",
    "city_ascii": "Rangoon",
    "lat": 16.8,
    "lng": 96.15,
    "country": "Burma",
    "iso2": "MM",
    "iso3": "MMR",
    "admin_name": "Yangon",
    "capital": "primary",
    "population": 5430000,
    "id": 1104616656
  },
  {
    "city": "Toronto",
    "city_ascii": "Toronto",
    "lat": 43.7417,
    "lng": -79.3733,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 5429524,
    "id": 1124279679
  },
  {
    "city": "Suihua",
    "city_ascii": "Suihua",
    "lat": 46.6384,
    "lng": 126.9808,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Heilongjiang",
    "capital": "minor",
    "population": 5420000,
    "id": 1156235493
  },
  {
    "city": "Washington",
    "city_ascii": "Washington",
    "lat": 38.9047,
    "lng": -77.0163,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "District of Columbia",
    "capital": "primary",
    "population": 5379184,
    "id": 1840006060
  },
  {
    "city": "Qiqihar",
    "city_ascii": "Qiqihar",
    "lat": 47.3398,
    "lng": 123.9512,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Heilongjiang",
    "capital": "minor",
    "population": 5367003,
    "id": 1156775905
  },
  {
    "city": "Jinhua",
    "city_ascii": "Jinhua",
    "lat": 29.1046,
    "lng": 119.6494,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Zhejiang",
    "capital": "minor",
    "population": 5361572,
    "id": 1156547188
  },
  {
    "city": "Saint Petersburg",
    "city_ascii": "Saint Petersburg",
    "lat": 59.95,
    "lng": 30.3167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sankt-Peterburg",
    "capital": "admin",
    "population": 5351935,
    "id": 1643616350
  },
  {
    "city": "Shantou",
    "city_ascii": "Shantou",
    "lat": 23.3735,
    "lng": 116.6941,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Guangdong",
    "capital": "minor",
    "population": 5319028,
    "id": 1156457499
  },
  {
    "city": "Sydney",
    "city_ascii": "Sydney",
    "lat": -33.865,
    "lng": 151.2094,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "admin",
    "population": 5312163,
    "id": 1036074917
  },
  {
    "city": "Weinan",
    "city_ascii": "Weinan",
    "lat": 34.4996,
    "lng": 109.4684,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Shaanxi",
    "capital": "minor",
    "population": 5300000,
    "id": 1156903687
  },
  {
    "city": "Suqian",
    "city_ascii": "Suqian",
    "lat": 33.9331,
    "lng": 118.2831,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Jiangsu",
    "capital": "",
    "population": 5290000,
    "id": 1156212349
  },
  {
    "city": "Guadalajara",
    "city_ascii": "Guadalajara",
    "lat": 20.6767,
    "lng": -103.3475,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Jalisco",
    "capital": "admin",
    "population": 5253000,
    "id": 1484950208
  },
  {
    "city": "Suzhou",
    "city_ascii": "Suzhou",
    "lat": 31.304,
    "lng": 120.6164,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Jiangsu",
    "capital": "minor",
    "population": 5250000,
    "id": 1156029196
  },
  {
    "city": "Fuzhou",
    "city_ascii": "Fuzhou",
    "lat": 26.0769,
    "lng": 119.2917,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Fujian",
    "capital": "admin",
    "population": 5245000,
    "id": 1156188037
  },
  {
    "city": "Zhaotong",
    "city_ascii": "Zhaotong",
    "lat": 27.3328,
    "lng": 103.7144,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Yunnan",
    "capital": "",
    "population": 5213521,
    "id": 1156924687
  },
  {
    "city": "Pudong",
    "city_ascii": "Pudong",
    "lat": 31.2231,
    "lng": 121.5397,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Shanghai",
    "capital": "minor",
    "population": 5187200,
    "id": 1156644508
  },
  {
    "city": "Yongzhou",
    "city_ascii": "Yongzhou",
    "lat": 26.4515,
    "lng": 111.5953,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hunan",
    "capital": "",
    "population": 5180235,
    "id": 1156694479
  },
  {
    "city": "Belo Horizonte",
    "city_ascii": "Belo Horizonte",
    "lat": -19.8917,
    "lng": -43.9478,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Minas Gerais",
    "capital": "admin",
    "population": 5159000,
    "id": 1076967355
  },
  {
    "city": "Zhangzhou",
    "city_ascii": "Zhangzhou",
    "lat": 24.5093,
    "lng": 117.6612,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Fujian",
    "capital": "minor",
    "population": 5140000,
    "id": 1156241637
  },
  {
    "city": "Bozhou",
    "city_ascii": "Bozhou",
    "lat": 33.8626,
    "lng": 115.7742,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Anhui",
    "capital": "",
    "population": 5100000,
    "id": 1156356860
  },
  {
    "city": "Melbourne",
    "city_ascii": "Melbourne",
    "lat": -37.8136,
    "lng": 144.9631,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Victoria",
    "capital": "admin",
    "population": 5078193,
    "id": 1036533631
  },
  {
    "city": "Nanchang",
    "city_ascii": "Nanchang",
    "lat": 28.6842,
    "lng": 115.8872,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Jiangxi",
    "capital": "admin",
    "population": 5042565,
    "id": 1156198892
  },
  {
    "city": "Xianyang",
    "city_ascii": "Xianyang",
    "lat": 34.35,
    "lng": 108.7167,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Shaanxi",
    "capital": "minor",
    "population": 5040000,
    "id": 1156120117
  },
  {
    "city": "Taizhou",
    "city_ascii": "Taizhou",
    "lat": 32.4831,
    "lng": 119.9,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Jiangsu",
    "capital": "minor",
    "population": 5031000,
    "id": 1156119229
  },
  {
    "city": "Surabaya",
    "city_ascii": "Surabaya",
    "lat": -7.2458,
    "lng": 112.7378,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Jawa Timur",
    "capital": "admin",
    "population": 4975000,
    "id": 1360484663
  },
  {
    "city": "Abidjan",
    "city_ascii": "Abidjan",
    "lat": 5.3364,
    "lng": -4.0267,
    "country": "Côte D’Ivoire",
    "iso2": "CI",
    "iso3": "CIV",
    "admin_name": "Abidjan",
    "capital": "primary",
    "population": 4980000,
    "id": 1384207980
  },
  {
    "city": "Ji’an",
    "city_ascii": "Ji'an",
    "lat": 27.1172,
    "lng": 114.9793,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Jiangxi",
    "capital": "minor",
    "population": 4956600,
    "id": 1156278215
  },
  {
    "city": "Mianyang",
    "city_ascii": "Mianyang",
    "lat": 31.4669,
    "lng": 104.7385,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Sichuan",
    "capital": "minor",
    "population": 4945000,
    "id": 1156417758
  },
  {
    "city": "Shaoxing",
    "city_ascii": "Shaoxing",
    "lat": 30,
    "lng": 120.5833,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Zhejiang",
    "capital": "minor",
    "population": 4912239,
    "id": 1156893449
  },
  {
    "city": "Alexandria",
    "city_ascii": "Alexandria",
    "lat": 31.2,
    "lng": 29.9167,
    "country": "Egypt",
    "iso2": "EG",
    "iso3": "EGY",
    "admin_name": "Al Iskandarīyah",
    "capital": "admin",
    "population": 4870000,
    "id": 1818695837
  },
  {
    "city": "Yuncheng",
    "city_ascii": "Yuncheng",
    "lat": 35.0304,
    "lng": 110.998,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Shanxi",
    "capital": "",
    "population": 4860000,
    "id": 1156705644
  },
  {
    "city": "Pingdingshan",
    "city_ascii": "Pingdingshan",
    "lat": 33.735,
    "lng": 113.2999,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Henan",
    "capital": "",
    "population": 4838000,
    "id": 1156735124
  },
  {
    "city": "Huai’an",
    "city_ascii": "Huai'an",
    "lat": 33.5,
    "lng": 119.1331,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Jiangsu",
    "capital": "",
    "population": 4799889,
    "id": 1156634228
  },
  {
    "city": "Xinpu",
    "city_ascii": "Xinpu",
    "lat": 34.5906,
    "lng": 119.1801,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Jiangsu",
    "capital": "minor",
    "population": 4790000,
    "id": 1156035381
  },
  {
    "city": "Guilin",
    "city_ascii": "Guilin",
    "lat": 25.2667,
    "lng": 110.2833,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Guangxi",
    "capital": "minor",
    "population": 4747963,
    "id": 1156235364
  },
  {
    "city": "Huaihua",
    "city_ascii": "Huaihua",
    "lat": 27.5494,
    "lng": 109.9592,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hunan",
    "capital": "",
    "population": 4741948,
    "id": 1156353465
  },
  {
    "city": "Jiujiang",
    "city_ascii": "Jiujiang",
    "lat": 29.7048,
    "lng": 116.0021,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Jiangxi",
    "capital": "minor",
    "population": 4728778,
    "id": 1156206041
  },
  {
    "city": "Anqing",
    "city_ascii": "Anqing",
    "lat": 30.5,
    "lng": 117.0333,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Anhui",
    "capital": "minor",
    "population": 4723000,
    "id": 1156238875
  },
  {
    "city": "Boston",
    "city_ascii": "Boston",
    "lat": 42.3188,
    "lng": -71.0846,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "admin",
    "population": 4688346,
    "id": 1840000455
  },
  {
    "city": "Huanglongsi",
    "city_ascii": "Huanglongsi",
    "lat": 34.7936,
    "lng": 114.3403,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Henan",
    "capital": "",
    "population": 4676159,
    "id": 1156198356
  },
  {
    "city": "Xiaoganzhan",
    "city_ascii": "Xiaoganzhan",
    "lat": 30.9273,
    "lng": 113.911,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hubei",
    "capital": "",
    "population": 4665000,
    "id": 1156002290
  },
  {
    "city": "Changzhou",
    "city_ascii": "Changzhou",
    "lat": 31.8122,
    "lng": 119.9692,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Jiangsu",
    "capital": "minor",
    "population": 4592431,
    "id": 1156185511
  },
  {
    "city": "Barcelona",
    "city_ascii": "Barcelona",
    "lat": 41.3825,
    "lng": 2.1769,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Catalonia",
    "capital": "admin",
    "population": 4588000,
    "id": 1724594040
  },
  {
    "city": "Chenzhou",
    "city_ascii": "Chenzhou",
    "lat": 25.7989,
    "lng": 113.0267,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hunan",
    "capital": "minor",
    "population": 4581778,
    "id": 1156291915
  },
  {
    "city": "Wuxi",
    "city_ascii": "Wuxi",
    "lat": 31.5667,
    "lng": 120.2833,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Jiangsu",
    "capital": "minor",
    "population": 4580000,
    "id": 1156019650
  },
  {
    "city": "Zibo",
    "city_ascii": "Zibo",
    "lat": 36.7831,
    "lng": 118.0497,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Shandong",
    "capital": "",
    "population": 4530597,
    "id": 1156381094
  },
  {
    "city": "Jiaxing",
    "city_ascii": "Jiaxing",
    "lat": 30.7522,
    "lng": 120.75,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Zhejiang",
    "capital": "minor",
    "population": 4501657,
    "id": 1156491661
  },
  {
    "city": "Dalian",
    "city_ascii": "Dalian",
    "lat": 38.9131,
    "lng": 121.61,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Liaoning",
    "capital": "",
    "population": 4480000,
    "id": 1156175472
  },
  {
    "city": "Harbin",
    "city_ascii": "Harbin",
    "lat": 45.75,
    "lng": 126.6333,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Heilongjiang",
    "capital": "admin",
    "population": 4458000,
    "id": 1156241528
  },
  {
    "city": "Yangzhou",
    "city_ascii": "Yangzhou",
    "lat": 32.3912,
    "lng": 119.4363,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Jiangsu",
    "capital": "",
    "population": 4459000,
    "id": 1156818601
  },
  {
    "city": "Yibin",
    "city_ascii": "Yibin",
    "lat": 28.7596,
    "lng": 104.64,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Sichuan",
    "capital": "minor",
    "population": 4456000,
    "id": 1156107603
  },
  {
    "city": "Jiangmen",
    "city_ascii": "Jiangmen",
    "lat": 22.5833,
    "lng": 113.0833,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Guangdong",
    "capital": "minor",
    "population": 4448871,
    "id": 1156105613
  },
  {
    "city": "Johannesburg",
    "city_ascii": "Johannesburg",
    "lat": -26.2044,
    "lng": 28.0416,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "Gauteng",
    "capital": "admin",
    "population": 4434827,
    "id": 1710550792
  },
  {
    "city": "Meizhou",
    "city_ascii": "Meizhou",
    "lat": 24.2998,
    "lng": 116.1191,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Guangdong",
    "capital": "minor",
    "population": 4378800,
    "id": 1156361028
  },
  {
    "city": "Chifeng",
    "city_ascii": "Chifeng",
    "lat": 42.2663,
    "lng": 118.9223,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Inner Mongolia",
    "capital": "minor",
    "population": 4376600,
    "id": 1156277458
  },
  {
    "city": "Casablanca",
    "city_ascii": "Casablanca",
    "lat": 33.5992,
    "lng": -7.62,
    "country": "Morocco",
    "iso2": "MA",
    "iso3": "MAR",
    "admin_name": "Casablanca-Settat",
    "capital": "admin",
    "population": 4370000,
    "id": 1504175315
  },
  {
    "city": "Guiyang",
    "city_ascii": "Guiyang",
    "lat": 26.5794,
    "lng": 106.7078,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Guizhou",
    "capital": "admin",
    "population": 4365000,
    "id": 1156932620
  },
  {
    "city": "Langfang",
    "city_ascii": "Langfang",
    "lat": 39.5196,
    "lng": 116.7006,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hebei",
    "capital": "",
    "population": 4358839,
    "id": 1156109017
  },
  {
    "city": "Zhangjiakou",
    "city_ascii": "Zhangjiakou",
    "lat": 40.8108,
    "lng": 114.8811,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hebei",
    "capital": "",
    "population": 4345491,
    "id": 1156800221
  },
  {
    "city": "İzmir",
    "city_ascii": "Izmir",
    "lat": 38.4127,
    "lng": 27.1384,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "İzmir",
    "capital": "admin",
    "population": 4320519,
    "id": 1792725579
  },
  {
    "city": "Linfen",
    "city_ascii": "Linfen",
    "lat": 36.0812,
    "lng": 111.5087,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Shanxi",
    "capital": "",
    "population": 4316610,
    "id": 1156416074
  },
  {
    "city": "Wenzhou",
    "city_ascii": "Wenzhou",
    "lat": 27.9991,
    "lng": 120.6561,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Zhejiang",
    "capital": "",
    "population": 4310000,
    "id": 1156188829
  },
  {
    "city": "Monterrey",
    "city_ascii": "Monterrey",
    "lat": 25.6667,
    "lng": -100.3,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Nuevo León",
    "capital": "admin",
    "population": 4295000,
    "id": 1484559591
  },
  {
    "city": "Luzhou",
    "city_ascii": "Luzhou",
    "lat": 28.8918,
    "lng": 105.4409,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Sichuan",
    "capital": "minor",
    "population": 4291000,
    "id": 1156582079
  },
  {
    "city": "Jiangguanchi",
    "city_ascii": "Jiangguanchi",
    "lat": 34.0244,
    "lng": 113.8201,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Henan",
    "capital": "minor",
    "population": 4289000,
    "id": 1156235735
  },
  {
    "city": "Neijiang",
    "city_ascii": "Neijiang",
    "lat": 29.5872,
    "lng": 105.0635,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Sichuan",
    "capital": "minor",
    "population": 4238000,
    "id": 1156516335
  },
  {
    "city": "Phoenix",
    "city_ascii": "Phoenix",
    "lat": 33.5722,
    "lng": -112.0891,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "admin",
    "population": 4219697,
    "id": 1840020568
  },
  {
    "city": "Yanjiang",
    "city_ascii": "Yanjiang",
    "lat": 30.1256,
    "lng": 104.6397,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Sichuan",
    "capital": "minor",
    "population": 4217000,
    "id": 1156217114
  },
  {
    "city": "Yiyang",
    "city_ascii": "Yiyang",
    "lat": 28.5833,
    "lng": 112.3333,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hunan",
    "capital": "",
    "population": 4174000,
    "id": 1156218615
  },
  {
    "city": "Zhaoqing",
    "city_ascii": "Zhaoqing",
    "lat": 23.047,
    "lng": 112.465,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Guangdong",
    "capital": "minor",
    "population": 4151700,
    "id": 1156170144
  },
  {
    "city": "Hengshui",
    "city_ascii": "Hengshui",
    "lat": 37.7348,
    "lng": 115.686,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hebei",
    "capital": "",
    "population": 4150000,
    "id": 1156803028
  },
  {
    "city": "Guigang",
    "city_ascii": "Guigang",
    "lat": 23.0961,
    "lng": 109.6092,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Guangxi",
    "capital": "",
    "population": 4118808,
    "id": 1156895251
  },
  {
    "city": "Xiaoxita",
    "city_ascii": "Xiaoxita",
    "lat": 30.7083,
    "lng": 111.2803,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hubei",
    "capital": "minor",
    "population": 4115000,
    "id": 1156764447
  },
  {
    "city": "Xiamen",
    "city_ascii": "Xiamen",
    "lat": 24.4797,
    "lng": 118.0819,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Fujian",
    "capital": "minor",
    "population": 4110000,
    "id": 1156212809
  },
  {
    "city": "Chuzhou",
    "city_ascii": "Chuzhou",
    "lat": 32.3062,
    "lng": 118.3115,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Anhui",
    "capital": "",
    "population": 4110000,
    "id": 1156036420
  },
  {
    "city": "Fuzhou",
    "city_ascii": "Fuzhou",
    "lat": 27.9814,
    "lng": 116.3577,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Jiangxi",
    "capital": "",
    "population": 4047200,
    "id": 1156915325
  },
  {
    "city": "Amman",
    "city_ascii": "Amman",
    "lat": 31.95,
    "lng": 35.9333,
    "country": "Jordan",
    "iso2": "JO",
    "iso3": "JOR",
    "admin_name": "Al ‘Āşimah",
    "capital": "primary",
    "population": 4007526,
    "id": 1400522593
  },
  {
    "city": "Jeddah",
    "city_ascii": "Jeddah",
    "lat": 21.5428,
    "lng": 39.1728,
    "country": "Saudi Arabia",
    "iso2": "SA",
    "iso3": "SAU",
    "admin_name": "Makkah al Mukarramah",
    "capital": "",
    "population": 3976000,
    "id": 1682926944
  },
  {
    "city": "Sialkot City",
    "city_ascii": "Sialkot City",
    "lat": 32.5,
    "lng": 74.5333,
    "country": "Pakistan",
    "iso2": "PK",
    "iso3": "PAK",
    "admin_name": "Punjab",
    "capital": "minor",
    "population": 3893672,
    "id": 1586141444
  },
  {
    "city": "Huizhou",
    "city_ascii": "Huizhou",
    "lat": 23.1115,
    "lng": 114.4152,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Guangdong",
    "capital": "minor",
    "population": 3875000,
    "id": 1156203268
  },
  {
    "city": "Qingyuan",
    "city_ascii": "Qingyuan",
    "lat": 23.6842,
    "lng": 113.0507,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Guangdong",
    "capital": "minor",
    "population": 3874000,
    "id": 1156135890
  },
  {
    "city": "Zhuzhou",
    "city_ascii": "Zhuzhou",
    "lat": 27.8407,
    "lng": 113.1469,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hunan",
    "capital": "minor",
    "population": 3855609,
    "id": 1156041962
  },
  {
    "city": "Wuhu",
    "city_ascii": "Wuhu",
    "lat": 31.334,
    "lng": 118.3622,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Anhui",
    "capital": "minor",
    "population": 3842100,
    "id": 1156315512
  },
  {
    "city": "Hechi",
    "city_ascii": "Hechi",
    "lat": 23.0965,
    "lng": 109.6091,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Guangxi",
    "capital": "",
    "population": 3830000,
    "id": 1156868701
  },
  {
    "city": "Seattle",
    "city_ascii": "Seattle",
    "lat": 47.6211,
    "lng": -122.3244,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 3789215,
    "id": 1840021117
  },
  {
    "city": "Loudi",
    "city_ascii": "Loudi",
    "lat": 27.7378,
    "lng": 111.9974,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hunan",
    "capital": "",
    "population": 3785627,
    "id": 1156010654
  },
  {
    "city": "Binzhou",
    "city_ascii": "Binzhou",
    "lat": 37.3806,
    "lng": 118.0125,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Shandong",
    "capital": "",
    "population": 3779200,
    "id": 1156564962
  },
  {
    "city": "Liuzhou",
    "city_ascii": "Liuzhou",
    "lat": 24.3181,
    "lng": 109.4069,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Guangxi",
    "capital": "minor",
    "population": 3758704,
    "id": 1156360785
  },
  {
    "city": "Yokohama",
    "city_ascii": "Yokohama",
    "lat": 35.4333,
    "lng": 139.6333,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Kanagawa",
    "capital": "admin",
    "population": 3748781,
    "id": 1392118339
  },
  {
    "city": "Baojishi",
    "city_ascii": "Baojishi",
    "lat": 34.3609,
    "lng": 107.1751,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Shaanxi",
    "capital": "",
    "population": 3738700,
    "id": 1156101096
  },
  {
    "city": "Guang’an",
    "city_ascii": "Guang'an",
    "lat": 30.4673,
    "lng": 106.6336,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Sichuan",
    "capital": "minor",
    "population": 3723000,
    "id": 1156377302
  },
  {
    "city": "Hanzhong",
    "city_ascii": "Hanzhong",
    "lat": 33.0794,
    "lng": 107.026,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Shaanxi",
    "capital": "",
    "population": 3700000,
    "id": 1156382678
  },
  {
    "city": "Kabul",
    "city_ascii": "Kabul",
    "lat": 34.5328,
    "lng": 69.1658,
    "country": "Afghanistan",
    "iso2": "AF",
    "iso3": "AFG",
    "admin_name": "Kābul",
    "capital": "primary",
    "population": 3678034,
    "id": 1004993580
  },
  {
    "city": "Zaozhuang",
    "city_ascii": "Zaozhuang",
    "lat": 34.8667,
    "lng": 117.55,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Shandong",
    "capital": "",
    "population": 3650000,
    "id": 1156796454
  },
  {
    "city": "Berlin",
    "city_ascii": "Berlin",
    "lat": 52.5167,
    "lng": 13.3833,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Berlin",
    "capital": "primary",
    "population": 3644826,
    "id": 1276451290
  },
  {
    "city": "Anshan",
    "city_ascii": "Anshan",
    "lat": 41.1066,
    "lng": 122.9895,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Liaoning",
    "capital": "",
    "population": 3645884,
    "id": 1156901825
  },
  {
    "city": "Deyang",
    "city_ascii": "Deyang",
    "lat": 31.1289,
    "lng": 104.395,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Sichuan",
    "capital": "minor",
    "population": 3636000,
    "id": 1156127147
  },
  {
    "city": "Lanzhou",
    "city_ascii": "Lanzhou",
    "lat": 36.0617,
    "lng": 103.8318,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Gansu",
    "capital": "admin",
    "population": 3616163,
    "id": 1156280566
  },
  {
    "city": "Chengde",
    "city_ascii": "Chengde",
    "lat": 40.9739,
    "lng": 117.9322,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hebei",
    "capital": "",
    "population": 3610000,
    "id": 1156197800
  },
  {
    "city": "San Francisco",
    "city_ascii": "San Francisco",
    "lat": 37.7562,
    "lng": -122.443,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 3592294,
    "id": 1840021543
  },
  {
    "city": "Puyang",
    "city_ascii": "Puyang",
    "lat": 35.7639,
    "lng": 115.03,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Henan",
    "capital": "minor",
    "population": 3580000,
    "id": 1156431924
  },
  {
    "city": "Suining",
    "city_ascii": "Suining",
    "lat": 30.5098,
    "lng": 105.5737,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Sichuan",
    "capital": "minor",
    "population": 3556000,
    "id": 1156655650
  },
  {
    "city": "Jiaozuo",
    "city_ascii": "Jiaozuo",
    "lat": 35.229,
    "lng": 113.2304,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Henan",
    "capital": "",
    "population": 3540101,
    "id": 1156157854
  },
  {
    "city": "Bengbu",
    "city_ascii": "Bengbu",
    "lat": 32.9354,
    "lng": 117.3531,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Anhui",
    "capital": "",
    "population": 3525000,
    "id": 1156440668
  },
  {
    "city": "Montréal",
    "city_ascii": "Montreal",
    "lat": 45.5089,
    "lng": -73.5617,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 3519595,
    "id": 1124586170
  },
  {
    "city": "Detroit",
    "city_ascii": "Detroit",
    "lat": 42.3834,
    "lng": -83.1024,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 3506126,
    "id": 1840003971
  },
  {
    "city": "Baicheng",
    "city_ascii": "Baicheng",
    "lat": 23.901,
    "lng": 106.6194,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Guangxi",
    "capital": "minor",
    "population": 3466758,
    "id": 1156981113
  },
  {
    "city": "Busan",
    "city_ascii": "Busan",
    "lat": 35.1,
    "lng": 129.0403,
    "country": "Korea, South",
    "iso2": "KR",
    "iso3": "KOR",
    "admin_name": "Busan",
    "capital": "admin",
    "population": 3440484,
    "id": 1410601465
  },
  {
    "city": "Algiers",
    "city_ascii": "Algiers",
    "lat": 36.7764,
    "lng": 3.0586,
    "country": "Algeria",
    "iso2": "DZ",
    "iso3": "DZA",
    "admin_name": "Alger",
    "capital": "primary",
    "population": 3415811,
    "id": 1012973369
  },
  {
    "city": "Qincheng",
    "city_ascii": "Qincheng",
    "lat": 34.5809,
    "lng": 105.7311,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Gansu",
    "capital": "minor",
    "population": 3400000,
    "id": 1156104017
  },
  {
    "city": "Taiyuan",
    "city_ascii": "Taiyuan",
    "lat": 37.8733,
    "lng": 112.5425,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Shanxi",
    "capital": "admin",
    "population": 3398000,
    "id": 1156632014
  },
  {
    "city": "Lucknow",
    "city_ascii": "Lucknow",
    "lat": 26.847,
    "lng": 80.947,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Uttar Pradesh",
    "capital": "admin",
    "population": 3382000,
    "id": 1356891790
  },
  {
    "city": "Chaoyang",
    "city_ascii": "Chaoyang",
    "lat": 41.5757,
    "lng": 120.4486,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Liaoning",
    "capital": "",
    "population": 3370000,
    "id": 1156706927
  },
  {
    "city": "Hechi",
    "city_ascii": "Hechi",
    "lat": 24.6928,
    "lng": 108.085,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Guangxi",
    "capital": "",
    "population": 3369251,
    "id": 1156167204
  },
  {
    "city": "Leshan",
    "city_ascii": "Leshan",
    "lat": 29.5854,
    "lng": 103.7575,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Sichuan",
    "capital": "",
    "population": 3364000,
    "id": 1156203130
  },
  {
    "city": "Yulinshi",
    "city_ascii": "Yulinshi",
    "lat": 38.2655,
    "lng": 109.7388,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Shaanxi",
    "capital": "",
    "population": 3328000,
    "id": 1156280672
  },
  {
    "city": "Siping",
    "city_ascii": "Siping",
    "lat": 43.1715,
    "lng": 124.3644,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Jilin",
    "capital": "",
    "population": 3300000,
    "id": 1156063295
  },
  {
    "city": "Madrid",
    "city_ascii": "Madrid",
    "lat": 40.4189,
    "lng": -3.6919,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Madrid",
    "capital": "primary",
    "population": 3266126,
    "id": 1724616994
  },
  {
    "city": "Jinan",
    "city_ascii": "Jinan",
    "lat": 36.6667,
    "lng": 116.9833,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Shandong",
    "capital": "admin",
    "population": 3250000,
    "id": 1156972469
  },
  {
    "city": "Shiyan",
    "city_ascii": "Shiyan",
    "lat": 32.6351,
    "lng": 110.7755,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hubei",
    "capital": "minor",
    "population": 3228000,
    "id": 1156383921
  },
  {
    "city": "Changzhi",
    "city_ascii": "Changzhi",
    "lat": 36.1953,
    "lng": 113.097,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Shanxi",
    "capital": "minor",
    "population": 3230000,
    "id": 1156057944
  },
  {
    "city": "San Diego",
    "city_ascii": "San Diego",
    "lat": 32.8312,
    "lng": -117.1225,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 3220118,
    "id": 1840021990
  },
  {
    "city": "Faisalabad",
    "city_ascii": "Faisalabad",
    "lat": 31.418,
    "lng": 73.079,
    "country": "Pakistan",
    "iso2": "PK",
    "iso3": "PAK",
    "admin_name": "Punjab",
    "capital": "minor",
    "population": 3203846,
    "id": 1586323916
  },
  {
    "city": "Santa Cruz",
    "city_ascii": "Santa Cruz",
    "lat": -17.7892,
    "lng": -63.1975,
    "country": "Bolivia",
    "iso2": "BO",
    "iso3": "BOL",
    "admin_name": "Santa Cruz",
    "capital": "admin",
    "population": 3151676,
    "id": 1068129363
  },
  {
    "city": "Bazhou",
    "city_ascii": "Bazhou",
    "lat": 31.8576,
    "lng": 106.7559,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Sichuan",
    "capital": "minor",
    "population": 3134000,
    "id": 1156271365
  },
  {
    "city": "Zhongshan",
    "city_ascii": "Zhongshan",
    "lat": 22.5333,
    "lng": 113.35,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Guangdong",
    "capital": "minor",
    "population": 3121275,
    "id": 1156579621
  },
  {
    "city": "Zhenjiang",
    "city_ascii": "Zhenjiang",
    "lat": 32.2109,
    "lng": 119.4551,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Jiangsu",
    "capital": "minor",
    "population": 3113384,
    "id": 1156934125
  },
  {
    "city": "Ürümqi",
    "city_ascii": "Urumqi",
    "lat": 43.825,
    "lng": 87.6,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Xinjiang",
    "capital": "admin",
    "population": 3112559,
    "id": 1156051276
  },
  {
    "city": "Tongliao",
    "city_ascii": "Tongliao",
    "lat": 43.6172,
    "lng": 122.264,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Inner Mongolia",
    "capital": "minor",
    "population": 3094600,
    "id": 1156358686
  },
  {
    "city": "Heyuan",
    "city_ascii": "Heyuan",
    "lat": 23.7503,
    "lng": 114.6923,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Guangdong",
    "capital": "minor",
    "population": 3093900,
    "id": 1156665165
  },
  {
    "city": "Tongren",
    "city_ascii": "Tongren",
    "lat": 27.7233,
    "lng": 109.1885,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Guizhou",
    "capital": "",
    "population": 3093204,
    "id": 1156803442
  },
  {
    "city": "Qinzhou",
    "city_ascii": "Qinzhou",
    "lat": 21.95,
    "lng": 108.6167,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Guangxi",
    "capital": "minor",
    "population": 3079721,
    "id": 1156106602
  },
  {
    "city": "Jaipur",
    "city_ascii": "Jaipur",
    "lat": 26.9167,
    "lng": 75.8667,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Rājasthān",
    "capital": "admin",
    "population": 3073350,
    "id": 1356293537
  },
  {
    "city": "Xinzhou",
    "city_ascii": "Xinzhou",
    "lat": 38.4178,
    "lng": 112.7233,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Shanxi",
    "capital": "minor",
    "population": 3067501,
    "id": 1156617534
  },
  {
    "city": "Addis Ababa",
    "city_ascii": "Addis Ababa",
    "lat": 9.0272,
    "lng": 38.7369,
    "country": "Ethiopia",
    "iso2": "ET",
    "iso3": "ETH",
    "admin_name": "Ādīs Ābeba",
    "capital": "primary",
    "population": 3041002,
    "id": 1231824991
  },
  {
    "city": "Giza",
    "city_ascii": "Giza",
    "lat": 29.987,
    "lng": 31.2118,
    "country": "Egypt",
    "iso2": "EG",
    "iso3": "EGY",
    "admin_name": "Al Jīzah",
    "capital": "admin",
    "population": 3021542,
    "id": 1818925479
  },
  {
    "city": "Meishan",
    "city_ascii": "Meishan",
    "lat": 30.0575,
    "lng": 103.8381,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Sichuan",
    "capital": "",
    "population": 3020000,
    "id": 1156185501
  },
  {
    "city": "Brasília",
    "city_ascii": "Brasilia",
    "lat": -15.7744,
    "lng": -48.0773,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Distrito Federal",
    "capital": "primary",
    "population": 3015268,
    "id": 1076144436
  },
  {
    "city": "Mashhad",
    "city_ascii": "Mashhad",
    "lat": 36.3069,
    "lng": 59.6042,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Khorāsān-e Raẕavī",
    "capital": "admin",
    "population": 3001184,
    "id": 1364123206
  },
  {
    "city": "Jinzhou",
    "city_ascii": "Jinzhou",
    "lat": 41.1144,
    "lng": 121.1292,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Liaoning",
    "capital": "",
    "population": 3000000,
    "id": 1156157151
  },
  {
    "city": "Tieling",
    "city_ascii": "Tieling",
    "lat": 42.2841,
    "lng": 123.8365,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Liaoning",
    "capital": "",
    "population": 3000000,
    "id": 1156089994
  },
  {
    "city": "Shaoguan",
    "city_ascii": "Shaoguan",
    "lat": 24.8011,
    "lng": 113.5927,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Guangdong",
    "capital": "minor",
    "population": 2997600,
    "id": 1156617066
  },
  {
    "city": "Shanwei",
    "city_ascii": "Shanwei",
    "lat": 22.7664,
    "lng": 115.3331,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Guangdong",
    "capital": "minor",
    "population": 2990000,
    "id": 1156939010
  },
  {
    "city": "Minneapolis",
    "city_ascii": "Minneapolis",
    "lat": 44.9635,
    "lng": -93.2678,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 2977172,
    "id": 1840007830
  },
  {
    "city": "Kyiv",
    "city_ascii": "Kyiv",
    "lat": 50.45,
    "lng": 30.5236,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Kyyiv, Misto",
    "capital": "primary",
    "population": 2967000,
    "id": 1804382913
  },
  {
    "city": "Sanaa",
    "city_ascii": "Sanaa",
    "lat": 15.35,
    "lng": 44.2,
    "country": "Yemen",
    "iso2": "YE",
    "iso3": "YEM",
    "admin_name": "Amānat al ‘Āşimah",
    "capital": "primary",
    "population": 2957000,
    "id": 1887750814
  },
  {
    "city": "Quezon City",
    "city_ascii": "Quezon City",
    "lat": 14.6333,
    "lng": 121.0333,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Quezon",
    "capital": "admin",
    "population": 2936116,
    "id": 1608974097
  },
  {
    "city": "Dingxi",
    "city_ascii": "Dingxi",
    "lat": 35.5806,
    "lng": 104.6263,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Gansu",
    "capital": "minor",
    "population": 2930000,
    "id": 1156518274
  },
  {
    "city": "Salvador",
    "city_ascii": "Salvador",
    "lat": -12.9708,
    "lng": -38.5108,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Bahia",
    "capital": "admin",
    "population": 2921087,
    "id": 1076923789
  },
  {
    "city": "Incheon",
    "city_ascii": "Incheon",
    "lat": 37.4639,
    "lng": 126.6486,
    "country": "Korea, South",
    "iso2": "KR",
    "iso3": "KOR",
    "admin_name": "Incheon",
    "capital": "admin",
    "population": 2913024,
    "id": 1410160313
  },
  {
    "city": "Bursa",
    "city_ascii": "Bursa",
    "lat": 40.1833,
    "lng": 29.0667,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Bursa",
    "capital": "admin",
    "population": 2901396,
    "id": 1792903330
  },
  {
    "city": "Ningde",
    "city_ascii": "Ningde",
    "lat": 26.6667,
    "lng": 119.5167,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Fujian",
    "capital": "minor",
    "population": 2910000,
    "id": 1156909802
  },
  {
    "city": "Tampa",
    "city_ascii": "Tampa",
    "lat": 27.9942,
    "lng": -82.4451,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 2908063,
    "id": 1840015982
  },
  {
    "city": "Daqing",
    "city_ascii": "Daqing",
    "lat": 46.5979,
    "lng": 125.008,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Heilongjiang",
    "capital": "",
    "population": 2904532,
    "id": 1156403962
  },
  {
    "city": "Birmingham",
    "city_ascii": "Birmingham",
    "lat": 52.48,
    "lng": -1.9025,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Birmingham",
    "capital": "",
    "population": 2897303,
    "id": 1826423213
  },
  {
    "city": "Putian",
    "city_ascii": "Putian",
    "lat": 25.4394,
    "lng": 119.0103,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Fujian",
    "capital": "minor",
    "population": 2900000,
    "id": 1156811601
  },
  {
    "city": "Huzhou",
    "city_ascii": "Huzhou",
    "lat": 30.8695,
    "lng": 120.0958,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Zhejiang",
    "capital": "minor",
    "population": 2893542,
    "id": 1156335543
  },
  {
    "city": "Wuzhou",
    "city_ascii": "Wuzhou",
    "lat": 23.4833,
    "lng": 111.3167,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Guangxi",
    "capital": "minor",
    "population": 2882200,
    "id": 1156620133
  },
  {
    "city": "Denver",
    "city_ascii": "Denver",
    "lat": 39.7621,
    "lng": -104.8759,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "admin",
    "population": 2876625,
    "id": 1840018789
  },
  {
    "city": "Rome",
    "city_ascii": "Rome",
    "lat": 41.8931,
    "lng": 12.4828,
    "country": "Italy",
    "iso2": "IT",
    "iso3": "ITA",
    "admin_name": "Lazio",
    "capital": "primary",
    "population": 2872800,
    "id": 1380382862
  },
  {
    "city": "La Paz",
    "city_ascii": "La Paz",
    "lat": -16.4942,
    "lng": -68.1475,
    "country": "Bolivia",
    "iso2": "BO",
    "iso3": "BOL",
    "admin_name": "La Paz",
    "capital": "primary",
    "population": 2867504,
    "id": 1068000064
  },
  {
    "city": "Pyongyang",
    "city_ascii": "Pyongyang",
    "lat": 39.03,
    "lng": 125.73,
    "country": "Korea, North",
    "iso2": "KP",
    "iso3": "PRK",
    "admin_name": "P’yŏngyang",
    "capital": "primary",
    "population": 2863000,
    "id": 1408738594
  },
  {
    "city": "Kano",
    "city_ascii": "Kano",
    "lat": 12,
    "lng": 8.5167,
    "country": "Nigeria",
    "iso2": "NG",
    "iso3": "NGA",
    "admin_name": "Kano",
    "capital": "admin",
    "population": 2828861,
    "id": 1566422868
  },
  {
    "city": "Taichung",
    "city_ascii": "Taichung",
    "lat": 24.15,
    "lng": 120.6667,
    "country": "Taiwan",
    "iso2": "TW",
    "iso3": "TWN",
    "admin_name": "Taichung",
    "capital": "admin",
    "population": 2803894,
    "id": 1158689622
  },
  {
    "city": "Omdurman",
    "city_ascii": "Omdurman",
    "lat": 15.6835,
    "lng": 32.4629,
    "country": "Sudan",
    "iso2": "SD",
    "iso3": "SDN",
    "admin_name": "Khartoum",
    "capital": "",
    "population": 2805396,
    "id": 1729912179
  },
  {
    "city": "Zigong",
    "city_ascii": "Zigong",
    "lat": 29.3498,
    "lng": 104.7645,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Sichuan",
    "capital": "",
    "population": 2800000,
    "id": 1156023354
  },
  {
    "city": "Qinhuangdao",
    "city_ascii": "Qinhuangdao",
    "lat": 39.9398,
    "lng": 119.5881,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hebei",
    "capital": "",
    "population": 2800000,
    "id": 1156091093
  },
  {
    "city": "Mudanjiang",
    "city_ascii": "Mudanjiang",
    "lat": 44.5861,
    "lng": 129.5997,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Heilongjiang",
    "capital": "minor",
    "population": 2790000,
    "id": 1156249698
  },
  {
    "city": "Huludao",
    "city_ascii": "Huludao",
    "lat": 40.7094,
    "lng": 120.8378,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Liaoning",
    "capital": "minor",
    "population": 2787000,
    "id": 1156580218
  },
  {
    "city": "Kaohsiung",
    "city_ascii": "Kaohsiung",
    "lat": 22.6167,
    "lng": 120.3,
    "country": "Taiwan",
    "iso2": "TW",
    "iso3": "TWN",
    "admin_name": "Kaohsiung",
    "capital": "admin",
    "population": 2773533,
    "id": 1158331334
  },
  {
    "city": "Xiangtan",
    "city_ascii": "Xiangtan",
    "lat": 27.8431,
    "lng": 112.9228,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hunan",
    "capital": "",
    "population": 2748552,
    "id": 1156195684
  },
  {
    "city": "Guayaquil",
    "city_ascii": "Guayaquil",
    "lat": -2.1833,
    "lng": -79.8833,
    "country": "Ecuador",
    "iso2": "EC",
    "iso3": "ECU",
    "admin_name": "Guayas",
    "capital": "admin",
    "population": 2723665,
    "id": 1218802178
  },
  {
    "city": "Rizhao",
    "city_ascii": "Rizhao",
    "lat": 35.4164,
    "lng": 119.4331,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Shandong",
    "capital": "minor",
    "population": 2720000,
    "id": 1156032841
  },
  {
    "city": "Manchester",
    "city_ascii": "Manchester",
    "lat": 53.4794,
    "lng": -2.2453,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Manchester",
    "capital": "",
    "population": 2705000,
    "id": 1826246335
  },
  {
    "city": "Cawnpore",
    "city_ascii": "Cawnpore",
    "lat": 26.4725,
    "lng": 80.3311,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Uttar Pradesh",
    "capital": "",
    "population": 2701324,
    "id": 1356575238
  },
  {
    "city": "Baotou",
    "city_ascii": "Baotou",
    "lat": 40.6562,
    "lng": 109.8345,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Inner Mongolia",
    "capital": "",
    "population": 2700000,
    "id": 1156032751
  },
  {
    "city": "Taipei",
    "city_ascii": "Taipei",
    "lat": 25.0478,
    "lng": 121.5319,
    "country": "Taiwan",
    "iso2": "TW",
    "iso3": "TWN",
    "admin_name": "Taipei",
    "capital": "primary",
    "population": 2684567,
    "id": 1158881289
  },
  {
    "city": "Nanping",
    "city_ascii": "Nanping",
    "lat": 26.6448,
    "lng": 118.1728,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Fujian",
    "capital": "minor",
    "population": 2690000,
    "id": 1156927445
  },
  {
    "city": "Longyan",
    "city_ascii": "Longyan",
    "lat": 25.0881,
    "lng": 117.0244,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Fujian",
    "capital": "minor",
    "population": 2640000,
    "id": 1156012979
  },
  {
    "city": "Ibadan",
    "city_ascii": "Ibadan",
    "lat": 7.3964,
    "lng": 3.9167,
    "country": "Nigeria",
    "iso2": "NG",
    "iso3": "NGA",
    "admin_name": "Oyo",
    "capital": "admin",
    "population": 2628000,
    "id": 1566366407
  },
  {
    "city": "Hohhot",
    "city_ascii": "Hohhot",
    "lat": 40.8151,
    "lng": 111.6629,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Inner Mongolia",
    "capital": "admin",
    "population": 2635000,
    "id": 1156210167
  },
  {
    "city": "Chaozhou",
    "city_ascii": "Chaozhou",
    "lat": 23.67,
    "lng": 116.63,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Guangdong",
    "capital": "minor",
    "population": 2620000,
    "id": 1156549118
  },
  {
    "city": "Antananarivo",
    "city_ascii": "Antananarivo",
    "lat": -18.9386,
    "lng": 47.5214,
    "country": "Madagascar",
    "iso2": "MG",
    "iso3": "MDG",
    "admin_name": "Antananarivo",
    "capital": "primary",
    "population": 2610000,
    "id": 1450978461
  },
  {
    "city": "Longba",
    "city_ascii": "Longba",
    "lat": 33.535,
    "lng": 105.349,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Gansu",
    "capital": "",
    "population": 2614000,
    "id": 1156633259
  },
  {
    "city": "Weihai",
    "city_ascii": "Weihai",
    "lat": 37.5,
    "lng": 122.1,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Shandong",
    "capital": "minor",
    "population": 2596753,
    "id": 1156473300
  },
  {
    "city": "Chattogram",
    "city_ascii": "Chattogram",
    "lat": 22.335,
    "lng": 91.8325,
    "country": "Bangladesh",
    "iso2": "BD",
    "iso3": "BGD",
    "admin_name": "Chittagong",
    "capital": "admin",
    "population": 2581643,
    "id": 1050830722
  },
  {
    "city": "Santo Domingo",
    "city_ascii": "Santo Domingo",
    "lat": 18.4764,
    "lng": -69.8933,
    "country": "Dominican Republic",
    "iso2": "DO",
    "iso3": "DOM",
    "admin_name": "Ozama",
    "capital": "primary",
    "population": 2581827,
    "id": 1214636202
  },
  {
    "city": "Xuanzhou",
    "city_ascii": "Xuanzhou",
    "lat": 30.9475,
    "lng": 118.7518,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Anhui",
    "capital": "",
    "population": 2580000,
    "id": 1156424311
  },
  {
    "city": "Sanming",
    "city_ascii": "Sanming",
    "lat": 26.2658,
    "lng": 117.6302,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Fujian",
    "capital": "minor",
    "population": 2580000,
    "id": 1156294429
  },
  {
    "city": "Brooklyn",
    "city_ascii": "Brooklyn",
    "lat": 40.6501,
    "lng": -73.9496,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 2559903,
    "id": 1840034030
  },
  {
    "city": "Medellín",
    "city_ascii": "Medellin",
    "lat": 6.2447,
    "lng": -75.5748,
    "country": "Colombia",
    "iso2": "CO",
    "iso3": "COL",
    "admin_name": "Antioquia",
    "capital": "admin",
    "population": 2529403,
    "id": 1170680389
  },
  {
    "city": "Brisbane",
    "city_ascii": "Brisbane",
    "lat": -27.4678,
    "lng": 153.0281,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Queensland",
    "capital": "admin",
    "population": 2514184,
    "id": 1036192929
  },
  {
    "city": "Baoshan",
    "city_ascii": "Baoshan",
    "lat": 25.1211,
    "lng": 99.169,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Yunnan",
    "capital": "",
    "population": 2506491,
    "id": 1156546994
  },
  {
    "city": "Dubai",
    "city_ascii": "Dubai",
    "lat": 25.2697,
    "lng": 55.3094,
    "country": "United Arab Emirates",
    "iso2": "AE",
    "iso3": "ARE",
    "admin_name": "Dubayy",
    "capital": "admin",
    "population": 2502715,
    "id": 1784736618
  },
  {
    "city": "Luohe",
    "city_ascii": "Luohe",
    "lat": 33.583,
    "lng": 114.0109,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Henan",
    "capital": "minor",
    "population": 2500000,
    "id": 1156330876
  },
  {
    "city": "Qinbaling",
    "city_ascii": "Qinbaling",
    "lat": 35.7278,
    "lng": 107.64,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Gansu",
    "capital": "",
    "population": 2500000,
    "id": 1156032582
  },
  {
    "city": "Mirzāpur",
    "city_ascii": "Mirzapur",
    "lat": 25.15,
    "lng": 82.58,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Uttar Pradesh",
    "capital": "",
    "population": 2496970,
    "id": 1356427908
  },
  {
    "city": "Guangyuan",
    "city_ascii": "Guangyuan",
    "lat": 32.4353,
    "lng": 105.8398,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Sichuan",
    "capital": "",
    "population": 2484122,
    "id": 1156084562
  },
  {
    "city": "Cali",
    "city_ascii": "Cali",
    "lat": 3.44,
    "lng": -76.5197,
    "country": "Colombia",
    "iso2": "CO",
    "iso3": "COL",
    "admin_name": "Valle del Cauca",
    "capital": "admin",
    "population": 2471474,
    "id": 1170417589
  },
  {
    "city": "Daegu",
    "city_ascii": "Daegu",
    "lat": 35.8667,
    "lng": 128.6,
    "country": "Korea, South",
    "iso2": "KR",
    "iso3": "KOR",
    "admin_name": "Daegu",
    "capital": "admin",
    "population": 2461002,
    "id": 1410095252
  },
  {
    "city": "Fortaleza",
    "city_ascii": "Fortaleza",
    "lat": -3.7275,
    "lng": -38.5275,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Ceará",
    "capital": "admin",
    "population": 2452185,
    "id": 1076567885
  },
  {
    "city": "Guatemala City",
    "city_ascii": "Guatemala City",
    "lat": 14.6099,
    "lng": -90.5252,
    "country": "Guatemala",
    "iso2": "GT",
    "iso3": "GTM",
    "admin_name": "Guatemala",
    "capital": "primary",
    "population": 2450212,
    "id": 1320197916
  },
  {
    "city": "Yaoundé",
    "city_ascii": "Yaounde",
    "lat": 3.8578,
    "lng": 11.5181,
    "country": "Cameroon",
    "iso2": "CM",
    "iso3": "CMR",
    "admin_name": "Centre",
    "capital": "primary",
    "population": 2440462,
    "id": 1120298240
  },
  {
    "city": "Douala",
    "city_ascii": "Douala",
    "lat": 4.05,
    "lng": 9.7,
    "country": "Cameroon",
    "iso2": "CM",
    "iso3": "CMR",
    "admin_name": "Littoral",
    "capital": "admin",
    "population": 2446945,
    "id": 1120494607
  },
  {
    "city": "Jilin",
    "city_ascii": "Jilin",
    "lat": 43.85,
    "lng": 126.55,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Jilin",
    "capital": "",
    "population": 2396000,
    "id": 1156646448
  },
  {
    "city": "Lianshan",
    "city_ascii": "Lianshan",
    "lat": 40.7503,
    "lng": 120.83,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Liaoning",
    "capital": "minor",
    "population": 2426000,
    "id": 1156046704
  },
  {
    "city": "Lincang",
    "city_ascii": "Lincang",
    "lat": 23.8864,
    "lng": 100.0871,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Yunnan",
    "capital": "",
    "population": 2429497,
    "id": 1156044130
  },
  {
    "city": "Antalya",
    "city_ascii": "Antalya",
    "lat": 36.9081,
    "lng": 30.6956,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Antalya",
    "capital": "admin",
    "population": 2426356,
    "id": 1792988434
  },
  {
    "city": "Tashkent",
    "city_ascii": "Tashkent",
    "lat": 41.3,
    "lng": 69.2667,
    "country": "Uzbekistan",
    "iso2": "UZ",
    "iso3": "UZB",
    "admin_name": "Toshkent",
    "capital": "primary",
    "population": 2424100,
    "id": 1860331871
  },
  {
    "city": "Huangshi",
    "city_ascii": "Huangshi",
    "lat": 30.2018,
    "lng": 115.0326,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hubei",
    "capital": "minor",
    "population": 2419000,
    "id": 1156234290
  },
  {
    "city": "Bandung",
    "city_ascii": "Bandung",
    "lat": -6.95,
    "lng": 107.5667,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Jawa Barat",
    "capital": "admin",
    "population": 2394873,
    "id": 1360313023
  },
  {
    "city": "Nāgpur",
    "city_ascii": "Nagpur",
    "lat": 21.1539,
    "lng": 79.0831,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Mahārāshtra",
    "capital": "",
    "population": 2405665,
    "id": 1356365743
  },
  {
    "city": "Dandong",
    "city_ascii": "Dandong",
    "lat": 40.1167,
    "lng": 124.3833,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Liaoning",
    "capital": "",
    "population": 2400000,
    "id": 1156178316
  },
  {
    "city": "Huainan",
    "city_ascii": "Huainan",
    "lat": 32.4831,
    "lng": 117.0164,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Anhui",
    "capital": "",
    "population": 2409000,
    "id": 1156877299
  },
  {
    "city": "Shangzhou",
    "city_ascii": "Shangzhou",
    "lat": 33.868,
    "lng": 109.9244,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Shaanxi",
    "capital": "",
    "population": 2400000,
    "id": 1156091241
  },
  {
    "city": "Bekasi",
    "city_ascii": "Bekasi",
    "lat": -6.2333,
    "lng": 107,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Jawa Barat",
    "capital": "",
    "population": 2381053,
    "id": 1360006015
  },
  {
    "city": "Ghāziābād",
    "city_ascii": "Ghaziabad",
    "lat": 28.6667,
    "lng": 77.4167,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Uttar Pradesh",
    "capital": "",
    "population": 2381452,
    "id": 1356691871
  },
  {
    "city": "Tijuana",
    "city_ascii": "Tijuana",
    "lat": 32.525,
    "lng": -117.0333,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Baja California",
    "capital": "minor",
    "population": 2384609,
    "id": 1484708778
  },
  {
    "city": "Jiamusi",
    "city_ascii": "Jiamusi",
    "lat": 46.8081,
    "lng": 130.3653,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Heilongjiang",
    "capital": "minor",
    "population": 2360000,
    "id": 1156350864
  },
  {
    "city": "Yangjiang",
    "city_ascii": "Yangjiang",
    "lat": 21.8556,
    "lng": 111.9627,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Guangdong",
    "capital": "minor",
    "population": 2357000,
    "id": 1156123172
  },
  {
    "city": "Accra",
    "city_ascii": "Accra",
    "lat": 5.6037,
    "lng": -0.187,
    "country": "Ghana",
    "iso2": "GH",
    "iso3": "GHA",
    "admin_name": "Greater Accra",
    "capital": "primary",
    "population": 2291352,
    "id": 1288299415
  },
  {
    "city": "Yuxi",
    "city_ascii": "Yuxi",
    "lat": 24.3495,
    "lng": 102.5432,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Yunnan",
    "capital": "minor",
    "population": 2303518,
    "id": 1156682309
  },
  {
    "city": "Fushun",
    "city_ascii": "Fushun",
    "lat": 41.8708,
    "lng": 123.8917,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Liaoning",
    "capital": "",
    "population": 2300000,
    "id": 1156857743
  },
  {
    "city": "Anshun",
    "city_ascii": "Anshun",
    "lat": 26.2456,
    "lng": 105.934,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Guizhou",
    "capital": "",
    "population": 2297612,
    "id": 1156415948
  },
  {
    "city": "Vancouver",
    "city_ascii": "Vancouver",
    "lat": 49.25,
    "lng": -123.1,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "British Columbia",
    "capital": "",
    "population": 2264823,
    "id": 1124825478
  },
  {
    "city": "Tangerang",
    "city_ascii": "Tangerang",
    "lat": -6.1783,
    "lng": 106.6319,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Jawa Barat",
    "capital": "",
    "population": 2237006,
    "id": 1360002844
  },
  {
    "city": "Konya",
    "city_ascii": "Konya",
    "lat": 37.8714,
    "lng": 32.4847,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Konya",
    "capital": "admin",
    "population": 2232374,
    "id": 1792014004
  },
  {
    "city": "Queens",
    "city_ascii": "Queens",
    "lat": 40.7498,
    "lng": -73.7976,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 2230722,
    "id": 1840034002
  },
  {
    "city": "Yingkou",
    "city_ascii": "Yingkou",
    "lat": 40.6653,
    "lng": 122.2297,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Liaoning",
    "capital": "minor",
    "population": 2220000,
    "id": 1156257074
  },
  {
    "city": "Adana",
    "city_ascii": "Adana",
    "lat": 37,
    "lng": 35.325,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Adana",
    "capital": "admin",
    "population": 2220125,
    "id": 1792783772
  },
  {
    "city": "Medan",
    "city_ascii": "Medan",
    "lat": 3.6667,
    "lng": 98.6667,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Sumatera Utara",
    "capital": "admin",
    "population": 2210625,
    "id": 1360543171
  },
  {
    "city": "Sanmenxia",
    "city_ascii": "Sanmenxia",
    "lat": 34.7736,
    "lng": 111.195,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Henan",
    "capital": "",
    "population": 2213000,
    "id": 1156529682
  },
  {
    "city": "Indore",
    "city_ascii": "Indore",
    "lat": 22.7206,
    "lng": 75.8472,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Madhya Pradesh",
    "capital": "",
    "population": 2201927,
    "id": 1356402812
  },
  {
    "city": "Ma’anshan",
    "city_ascii": "Ma'anshan",
    "lat": 31.7135,
    "lng": 118.3605,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Anhui",
    "capital": "minor",
    "population": 2202899,
    "id": 1156847452
  },
  {
    "city": "Pingliang",
    "city_ascii": "Pingliang",
    "lat": 35.5412,
    "lng": 106.6819,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Gansu",
    "capital": "minor",
    "population": 2190000,
    "id": 1156216904
  },
  {
    "city": "Quzhou",
    "city_ascii": "Quzhou",
    "lat": 28.9545,
    "lng": 118.8763,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Zhejiang",
    "capital": "",
    "population": 2185000,
    "id": 1156180376
  },
  {
    "city": "Baku",
    "city_ascii": "Baku",
    "lat": 40.3667,
    "lng": 49.8352,
    "country": "Azerbaijan",
    "iso2": "AZ",
    "iso3": "AZE",
    "admin_name": "Bakı",
    "capital": "primary",
    "population": 2181800,
    "id": 1031946365
  },
  {
    "city": "Gaoping",
    "city_ascii": "Gaoping",
    "lat": 30.7804,
    "lng": 106.13,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Sichuan",
    "capital": "minor",
    "population": 2174000,
    "id": 1156522457
  },
  {
    "city": "Huaibei",
    "city_ascii": "Huaibei",
    "lat": 33.9562,
    "lng": 116.789,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Anhui",
    "capital": "",
    "population": 2158000,
    "id": 1156142626
  },
  {
    "city": "Xining",
    "city_ascii": "Xining",
    "lat": 36.6239,
    "lng": 101.7787,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Qinghai",
    "capital": "admin",
    "population": 2154000,
    "id": 1156081448
  },
  {
    "city": "Yan’an",
    "city_ascii": "Yan'an",
    "lat": 36.5952,
    "lng": 109.4863,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Shaanxi",
    "capital": "",
    "population": 2150000,
    "id": 1156546342
  },
  {
    "city": "Havana",
    "city_ascii": "Havana",
    "lat": 23.1367,
    "lng": -82.3589,
    "country": "Cuba",
    "iso2": "CU",
    "iso3": "CUB",
    "admin_name": "La Habana",
    "capital": "primary",
    "population": 2141652,
    "id": 1192752771
  },
  {
    "city": "Phnom Penh",
    "city_ascii": "Phnom Penh",
    "lat": 11.5696,
    "lng": 104.921,
    "country": "Cambodia",
    "iso2": "KH",
    "iso3": "KHM",
    "admin_name": "Phnom Penh",
    "capital": "primary",
    "population": 2129371,
    "id": 1116260534
  },
  {
    "city": "Fukuoka",
    "city_ascii": "Fukuoka",
    "lat": 33.5903,
    "lng": 130.4019,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Fukuoka",
    "capital": "admin",
    "population": 2128000,
    "id": 1392576294
  },
  {
    "city": "Mogadishu",
    "city_ascii": "Mogadishu",
    "lat": 2.0408,
    "lng": 45.3425,
    "country": "Somalia",
    "iso2": "SO",
    "iso3": "SOM",
    "admin_name": "Banaadir",
    "capital": "primary",
    "population": 2120000,
    "id": 1706893395
  },
  {
    "city": "Jincheng",
    "city_ascii": "Jincheng",
    "lat": 35.4906,
    "lng": 112.8483,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Shanxi",
    "capital": "minor",
    "population": 2120000,
    "id": 1156163376
  },
  {
    "city": "Lishui",
    "city_ascii": "Lishui",
    "lat": 28.45,
    "lng": 119.9167,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Zhejiang",
    "capital": "minor",
    "population": 2116957,
    "id": 1156858793
  },
  {
    "city": "Qingyang",
    "city_ascii": "Qingyang",
    "lat": 24.8141,
    "lng": 118.5792,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Fujian",
    "capital": "minor",
    "population": 2111000,
    "id": 1156273372
  },
  {
    "city": "Riverside",
    "city_ascii": "Riverside",
    "lat": 33.9381,
    "lng": -117.3948,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 2107852,
    "id": 1840020551
  },
  {
    "city": "Baltimore",
    "city_ascii": "Baltimore",
    "lat": 39.3051,
    "lng": -76.6144,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 2106068,
    "id": 1840001592
  },
  {
    "city": "Haiphong",
    "city_ascii": "Haiphong",
    "lat": 20.8,
    "lng": 106.6667,
    "country": "Vietnam",
    "iso2": "VN",
    "iso3": "VNM",
    "admin_name": "Hải Phòng",
    "capital": "admin",
    "population": 2103500,
    "id": 1704000623
  },
  {
    "city": "Las Vegas",
    "city_ascii": "Las Vegas",
    "lat": 36.2333,
    "lng": -115.2654,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Nevada",
    "capital": "",
    "population": 2104198,
    "id": 1840020364
  },
  {
    "city": "Laibin",
    "city_ascii": "Laibin",
    "lat": 23.7333,
    "lng": 109.2333,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Guangxi",
    "capital": "",
    "population": 2099711,
    "id": 1156925734
  },
  {
    "city": "Rawalpindi",
    "city_ascii": "Rawalpindi",
    "lat": 33.6007,
    "lng": 73.0679,
    "country": "Pakistan",
    "iso2": "PK",
    "iso3": "PAK",
    "admin_name": "Punjab",
    "capital": "minor",
    "population": 2098231,
    "id": 1586344001
  },
  {
    "city": "Kumasi",
    "city_ascii": "Kumasi",
    "lat": 6.6833,
    "lng": -1.6167,
    "country": "Ghana",
    "iso2": "GH",
    "iso3": "GHA",
    "admin_name": "Ashanti",
    "capital": "admin",
    "population": 2069350,
    "id": 1288181103
  },
  {
    "city": "Portland",
    "city_ascii": "Portland",
    "lat": 45.5372,
    "lng": -122.65,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 2074775,
    "id": 1840019941
  },
  {
    "city": "Vadodara",
    "city_ascii": "Vadodara",
    "lat": 22.3,
    "lng": 73.2,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Gujarāt",
    "capital": "",
    "population": 2065771,
    "id": 1356705249
  },
  {
    "city": "Perth",
    "city_ascii": "Perth",
    "lat": -31.9522,
    "lng": 115.8589,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Western Australia",
    "capital": "admin",
    "population": 2059484,
    "id": 1036178956
  },
  {
    "city": "Puning",
    "city_ascii": "Puning",
    "lat": 23.2993,
    "lng": 116.1586,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Guangdong",
    "capital": "",
    "population": 2055552,
    "id": 1156756494
  },
  {
    "city": "San Antonio",
    "city_ascii": "San Antonio",
    "lat": 29.4658,
    "lng": -98.5253,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 2049293,
    "id": 1840022220
  },
  {
    "city": "Haikou",
    "city_ascii": "Haikou",
    "lat": 20.02,
    "lng": 110.32,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hainan",
    "capital": "admin",
    "population": 2046189,
    "id": 1156183327
  },
  {
    "city": "Vishākhapatnam",
    "city_ascii": "Vishakhapatnam",
    "lat": 17.7333,
    "lng": 83.3167,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Andhra Pradesh",
    "capital": "",
    "population": 2035922,
    "id": 1356692563
  },
  {
    "city": "Gaziantep",
    "city_ascii": "Gaziantep",
    "lat": 37.0667,
    "lng": 37.3833,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Gaziantep",
    "capital": "admin",
    "population": 2028563,
    "id": 1792911843
  },
  {
    "city": "Minsk",
    "city_ascii": "Minsk",
    "lat": 53.9022,
    "lng": 27.5618,
    "country": "Belarus",
    "iso2": "BY",
    "iso3": "BLR",
    "admin_name": "Minsk",
    "capital": "primary",
    "population": 2020600,
    "id": 1112348503
  },
  {
    "city": "St. Louis",
    "city_ascii": "St. Louis",
    "lat": 38.6358,
    "lng": -90.2451,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 2024074,
    "id": 1840001651
  },
  {
    "city": "Bamako",
    "city_ascii": "Bamako",
    "lat": 12.6458,
    "lng": -7.9922,
    "country": "Mali",
    "iso2": "ML",
    "iso3": "MLI",
    "admin_name": "Bamako",
    "capital": "primary",
    "population": 2009109,
    "id": 1466965925
  },
  {
    "city": "Quito",
    "city_ascii": "Quito",
    "lat": -0.2186,
    "lng": -78.5097,
    "country": "Ecuador",
    "iso2": "EC",
    "iso3": "ECU",
    "admin_name": "Pichincha",
    "capital": "primary",
    "population": 2011388,
    "id": 1218441993
  },
  {
    "city": "Pingxiang",
    "city_ascii": "Pingxiang",
    "lat": 27.6333,
    "lng": 113.85,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Jiangxi",
    "capital": "minor",
    "population": 2001027,
    "id": 1156066465
  },
  {
    "city": "Chongzuo",
    "city_ascii": "Chongzuo",
    "lat": 22.4167,
    "lng": 107.3667,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Guangxi",
    "capital": "minor",
    "population": 1994285,
    "id": 1156438670
  },
  {
    "city": "Şanlıurfa",
    "city_ascii": "Sanliurfa",
    "lat": 37.1583,
    "lng": 38.7917,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Şanlıurfa",
    "capital": "admin",
    "population": 1985753,
    "id": 1792937570
  },
  {
    "city": "Kananga",
    "city_ascii": "Kananga",
    "lat": -5.897,
    "lng": 22.4488,
    "country": "Congo (Kinshasa)",
    "iso2": "CD",
    "iso3": "COD",
    "admin_name": "Kasaï Central",
    "capital": "admin",
    "population": 1971704,
    "id": 1180172460
  },
  {
    "city": "Peshawar",
    "city_ascii": "Peshawar",
    "lat": 34,
    "lng": 71.5,
    "country": "Pakistan",
    "iso2": "PK",
    "iso3": "PAK",
    "admin_name": "Khyber Pakhtunkhwa",
    "capital": "admin",
    "population": 1970042,
    "id": 1586851102
  },
  {
    "city": "Sapporo",
    "city_ascii": "Sapporo",
    "lat": 43.0621,
    "lng": 141.3544,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hokkaidō",
    "capital": "admin",
    "population": 1958756,
    "id": 1392000195
  },
  {
    "city": "Jixi",
    "city_ascii": "Jixi",
    "lat": 45.2937,
    "lng": 130.965,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Heilongjiang",
    "capital": "minor",
    "population": 1950000,
    "id": 1156393765
  },
  {
    "city": "Caracas",
    "city_ascii": "Caracas",
    "lat": 10.5,
    "lng": -66.9333,
    "country": "Venezuela",
    "iso2": "VE",
    "iso3": "VEN",
    "admin_name": "Distrito Capital",
    "capital": "primary",
    "population": 1943901,
    "id": 1862748204
  },
  {
    "city": "Fuxin",
    "city_ascii": "Fuxin",
    "lat": 42.0127,
    "lng": 121.6486,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Liaoning",
    "capital": "",
    "population": 1930000,
    "id": 1156141954
  },
  {
    "city": "Leeds",
    "city_ascii": "Leeds",
    "lat": 53.7997,
    "lng": -1.5492,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Leeds",
    "capital": "",
    "population": 1901934,
    "id": 1826343963
  },
  {
    "city": "Sacramento",
    "city_ascii": "Sacramento",
    "lat": 38.5667,
    "lng": -121.4683,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "admin",
    "population": 1898019,
    "id": 1840021491
  },
  {
    "city": "Blantyre",
    "city_ascii": "Blantyre",
    "lat": -15.7861,
    "lng": 35.0058,
    "country": "Malawi",
    "iso2": "MW",
    "iso3": "MWI",
    "admin_name": "Blantyre",
    "capital": "admin",
    "population": 1895973,
    "id": 1454145012
  },
  {
    "city": "Tainan",
    "city_ascii": "Tainan",
    "lat": 22.9833,
    "lng": 120.1833,
    "country": "Taiwan",
    "iso2": "TW",
    "iso3": "TWN",
    "admin_name": "Tainan",
    "capital": "admin",
    "population": 1883831,
    "id": 1158061376
  },
  {
    "city": "Bucharest",
    "city_ascii": "Bucharest",
    "lat": 44.4,
    "lng": 26.0833,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Bucureşti",
    "capital": "primary",
    "population": 1883425,
    "id": 1642414442
  },
  {
    "city": "Wuwei",
    "city_ascii": "Wuwei",
    "lat": 37.9278,
    "lng": 102.6329,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Gansu",
    "capital": "minor",
    "population": 1890000,
    "id": 1156760291
  },
  {
    "city": "Bhopāl",
    "city_ascii": "Bhopal",
    "lat": 23.25,
    "lng": 77.4167,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Madhya Pradesh",
    "capital": "admin",
    "population": 1883381,
    "id": 1356734039
  },
  {
    "city": "Curitiba",
    "city_ascii": "Curitiba",
    "lat": -25.4297,
    "lng": -49.2719,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Paraná",
    "capital": "admin",
    "population": 1879355,
    "id": 1076701712
  },
  {
    "city": "Xiping",
    "city_ascii": "Xiping",
    "lat": 40.08,
    "lng": 113.3,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Shanxi",
    "capital": "minor",
    "population": 1873000,
    "id": 1156898522
  },
  {
    "city": "Asunción",
    "city_ascii": "Asuncion",
    "lat": -25.3,
    "lng": -57.6333,
    "country": "Paraguay",
    "iso2": "PY",
    "iso3": "PRY",
    "admin_name": "Asunción",
    "capital": "primary",
    "population": 1870000,
    "id": 1600057911
  },
  {
    "city": "Saidu Sharif",
    "city_ascii": "Saidu Sharif",
    "lat": 34.75,
    "lng": 72.3572,
    "country": "Pakistan",
    "iso2": "PK",
    "iso3": "PAK",
    "admin_name": "Khyber Pakhtunkhwa",
    "capital": "minor",
    "population": 1860310,
    "id": 1586619596
  },
  {
    "city": "Vienna",
    "city_ascii": "Vienna",
    "lat": 48.2083,
    "lng": 16.3731,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Wien",
    "capital": "primary",
    "population": 1840573,
    "id": 1040261752
  },
  {
    "city": "Aleppo",
    "city_ascii": "Aleppo",
    "lat": 36.2,
    "lng": 37.15,
    "country": "Syria",
    "iso2": "SY",
    "iso3": "SYR",
    "admin_name": "Ḩalab",
    "capital": "admin",
    "population": 1834093,
    "id": 1760366651
  },
  {
    "city": "Hamburg",
    "city_ascii": "Hamburg",
    "lat": 53.55,
    "lng": 10,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hamburg",
    "capital": "admin",
    "population": 1841179,
    "id": 1276041799
  },
  {
    "city": "Meru",
    "city_ascii": "Meru",
    "lat": 0.05,
    "lng": 37.65,
    "country": "Kenya",
    "iso2": "KE",
    "iso3": "KEN",
    "admin_name": "Meru",
    "capital": "admin",
    "population": 1833000,
    "id": 1404588252
  },
  {
    "city": "Brazzaville",
    "city_ascii": "Brazzaville",
    "lat": -4.2667,
    "lng": 15.2833,
    "country": "Congo (Brazzaville)",
    "iso2": "CG",
    "iso3": "COG",
    "admin_name": "Brazzaville",
    "capital": "primary",
    "population": 1827000,
    "id": 1178340306
  },
  {
    "city": "Orlando",
    "city_ascii": "Orlando",
    "lat": 28.4772,
    "lng": -81.3369,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 1822394,
    "id": 1840015099
  },
  {
    "city": "Mersin",
    "city_ascii": "Mersin",
    "lat": 36.8,
    "lng": 34.6167,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Mersin",
    "capital": "admin",
    "population": 1814468,
    "id": 1792520282
  },
  {
    "city": "Almaty",
    "city_ascii": "Almaty",
    "lat": 43.25,
    "lng": 76.9,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Almaty",
    "capital": "",
    "population": 1806833,
    "id": 1398351701
  },
  {
    "city": "Barranquilla",
    "city_ascii": "Barranquilla",
    "lat": 10.96,
    "lng": -74.8,
    "country": "Colombia",
    "iso2": "CO",
    "iso3": "COL",
    "admin_name": "Atlántico",
    "capital": "admin",
    "population": 1798000,
    "id": 1170179113
  },
  {
    "city": "Kyōto",
    "city_ascii": "Kyoto",
    "lat": 35.0111,
    "lng": 135.7669,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Kyōto",
    "capital": "admin",
    "population": 1805000,
    "id": 1392622735
  },
  {
    "city": "Manaus",
    "city_ascii": "Manaus",
    "lat": -3.1,
    "lng": -60.0167,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Amazonas",
    "capital": "admin",
    "population": 1802014,
    "id": 1076607274
  },
  {
    "city": "Liaoyang",
    "city_ascii": "Liaoyang",
    "lat": 41.2643,
    "lng": 123.1772,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Liaoning",
    "capital": "",
    "population": 1800000,
    "id": 1156202749
  },
  {
    "city": "Baiyin",
    "city_ascii": "Baiyin",
    "lat": 36.5448,
    "lng": 104.1766,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Gansu",
    "capital": "minor",
    "population": 1799000,
    "id": 1156140738
  },
  {
    "city": "San Jose",
    "city_ascii": "San Jose",
    "lat": 37.3019,
    "lng": -121.8486,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 1798103,
    "id": 1840021570
  },
  {
    "city": "Warsaw",
    "city_ascii": "Warsaw",
    "lat": 52.2167,
    "lng": 21.0333,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Mazowieckie",
    "capital": "primary",
    "population": 1790658,
    "id": 1616024847
  },
  {
    "city": "Shengli",
    "city_ascii": "Shengli",
    "lat": 37.45,
    "lng": 118.4667,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Shandong",
    "capital": "minor",
    "population": 1790000,
    "id": 1156672254
  },
  {
    "city": "Lubumbashi",
    "city_ascii": "Lubumbashi",
    "lat": -11.6697,
    "lng": 27.4581,
    "country": "Congo (Kinshasa)",
    "iso2": "CD",
    "iso3": "COD",
    "admin_name": "Haut-Katanga",
    "capital": "admin",
    "population": 1786397,
    "id": 1180506234
  },
  {
    "city": "Damascus",
    "city_ascii": "Damascus",
    "lat": 33.5131,
    "lng": 36.2919,
    "country": "Syria",
    "iso2": "SY",
    "iso3": "SYR",
    "admin_name": "Dimashq",
    "capital": "primary",
    "population": 1754000,
    "id": 1760685964
  },
  {
    "city": "Shuyangzha",
    "city_ascii": "Shuyangzha",
    "lat": 34.1299,
    "lng": 118.7734,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Jiangsu",
    "capital": "",
    "population": 1770000,
    "id": 1156051957
  },
  {
    "city": "Shangqiu",
    "city_ascii": "Shangqiu",
    "lat": 34.4504,
    "lng": 115.65,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Henan",
    "capital": "",
    "population": 1753000,
    "id": 1156805441
  },
  {
    "city": "Eşfahān",
    "city_ascii": "Esfahan",
    "lat": 32.6447,
    "lng": 51.6675,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Eşfahān",
    "capital": "admin",
    "population": 1756126,
    "id": 1364023865
  },
  {
    "city": "Budapest",
    "city_ascii": "Budapest",
    "lat": 47.4983,
    "lng": 19.0408,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Budapest",
    "capital": "primary",
    "population": 1752286,
    "id": 1348611435
  },
  {
    "city": "Heihe",
    "city_ascii": "Heihe",
    "lat": 50.2458,
    "lng": 127.4886,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Heilongjiang",
    "capital": "minor",
    "population": 1750000,
    "id": 1156339225
  },
  {
    "city": "Lusaka",
    "city_ascii": "Lusaka",
    "lat": -15.4167,
    "lng": 28.2833,
    "country": "Zambia",
    "iso2": "ZM",
    "iso3": "ZMB",
    "admin_name": "Lusaka",
    "capital": "primary",
    "population": 1742979,
    "id": 1894157390
  },
  {
    "city": "Diyarbakır",
    "city_ascii": "Diyarbakir",
    "lat": 37.9108,
    "lng": 40.2367,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Diyarbakır",
    "capital": "admin",
    "population": 1732396,
    "id": 1792536241
  },
  {
    "city": "Chinchvad",
    "city_ascii": "Chinchvad",
    "lat": 18.6278,
    "lng": 73.8131,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Mahārāshtra",
    "capital": "",
    "population": 1729320,
    "id": 1356475483
  },
  {
    "city": "Shuozhou",
    "city_ascii": "Shuozhou",
    "lat": 39.3408,
    "lng": 112.4292,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Shanxi",
    "capital": "minor",
    "population": 1714857,
    "id": 1156227167
  },
  {
    "city": "Vitória",
    "city_ascii": "Vitoria",
    "lat": -20.324,
    "lng": -40.366,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Espírito Santo",
    "capital": "admin",
    "population": 1704000,
    "id": 1076376829
  },
  {
    "city": "Cleveland",
    "city_ascii": "Cleveland",
    "lat": 41.4767,
    "lng": -81.6804,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 1710093,
    "id": 1840000596
  },
  {
    "city": "Pittsburgh",
    "city_ascii": "Pittsburgh",
    "lat": 40.4396,
    "lng": -79.9762,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 1703266,
    "id": 1840001254
  },
  {
    "city": "Mecca",
    "city_ascii": "Mecca",
    "lat": 21.4225,
    "lng": 39.8261,
    "country": "Saudi Arabia",
    "iso2": "SA",
    "iso3": "SAU",
    "admin_name": "Makkah al Mukarramah",
    "capital": "admin",
    "population": 1700000,
    "id": 1682169241
  },
  {
    "city": "Patna",
    "city_ascii": "Patna",
    "lat": 25.61,
    "lng": 85.1414,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Bihār",
    "capital": "admin",
    "population": 1684222,
    "id": 1356821429
  },
  {
    "city": "Mosul",
    "city_ascii": "Mosul",
    "lat": 36.3667,
    "lng": 43.1167,
    "country": "Iraq",
    "iso2": "IQ",
    "iso3": "IRQ",
    "admin_name": "Nīnawá",
    "capital": "admin",
    "population": 1694000,
    "id": 1368194914
  },
  {
    "city": "Austin",
    "city_ascii": "Austin",
    "lat": 30.3004,
    "lng": -97.7522,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "admin",
    "population": 1687311,
    "id": 1840019590
  },
  {
    "city": "Sanzhou",
    "city_ascii": "Sanzhou",
    "lat": 30.82,
    "lng": 108.4,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Chongqing",
    "capital": "",
    "population": 1680000,
    "id": 1156025536
  },
  {
    "city": "Conakry",
    "city_ascii": "Conakry",
    "lat": 9.5092,
    "lng": -13.7122,
    "country": "Guinea",
    "iso2": "GN",
    "iso3": "GIN",
    "admin_name": "Conakry",
    "capital": "primary",
    "population": 1667864,
    "id": 1324568159
  },
  {
    "city": "Kampala",
    "city_ascii": "Kampala",
    "lat": 0.3136,
    "lng": 32.5811,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Kampala",
    "capital": "primary",
    "population": 1659600,
    "id": 1800406299
  },
  {
    "city": "Ecatepec",
    "city_ascii": "Ecatepec",
    "lat": 19.6097,
    "lng": -99.06,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "México",
    "capital": "minor",
    "population": 1656107,
    "id": 1484003694
  },
  {
    "city": "Cincinnati",
    "city_ascii": "Cincinnati",
    "lat": 39.1413,
    "lng": -84.5061,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 1662691,
    "id": 1840003814
  },
  {
    "city": "Makassar",
    "city_ascii": "Makassar",
    "lat": -5.1331,
    "lng": 119.4136,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Sulawesi Selatan",
    "capital": "admin",
    "population": 1651146,
    "id": 1360051337
  },
  {
    "city": "Yushan",
    "city_ascii": "Yushan",
    "lat": 31.3867,
    "lng": 120.9766,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Jiangsu",
    "capital": "minor",
    "population": 1651200,
    "id": 1156245000
  },
  {
    "city": "Ludhiāna",
    "city_ascii": "Ludhiana",
    "lat": 30.9083,
    "lng": 75.8486,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Punjab",
    "capital": "",
    "population": 1649000,
    "id": 1356929498
  },
  {
    "city": "Newcastle",
    "city_ascii": "Newcastle",
    "lat": 55.0077,
    "lng": -1.6578,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Newcastle upon Tyne",
    "capital": "",
    "population": 1650000,
    "id": 1826292220
  },
  {
    "city": "Depok",
    "city_ascii": "Depok",
    "lat": -6.394,
    "lng": 106.8225,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Jawa Barat",
    "capital": "",
    "population": 1631951,
    "id": 1360962899
  },
  {
    "city": "Zhongli",
    "city_ascii": "Zhongli",
    "lat": 24.965,
    "lng": 121.2168,
    "country": "Taiwan",
    "iso2": "TW",
    "iso3": "TWN",
    "admin_name": "Taoyuan",
    "capital": "",
    "population": 1632616,
    "id": 1158025380
  },
  {
    "city": "Kansas City",
    "city_ascii": "Kansas City",
    "lat": 39.1239,
    "lng": -94.5541,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 1636715,
    "id": 1840008535
  },
  {
    "city": "Rabat",
    "city_ascii": "Rabat",
    "lat": 34.0253,
    "lng": -6.8361,
    "country": "Morocco",
    "iso2": "MA",
    "iso3": "MAR",
    "admin_name": "Rabat-Salé-Kénitra",
    "capital": "primary",
    "population": 1628000,
    "id": 1504023252
  },
  {
    "city": "Ouagadougou",
    "city_ascii": "Ouagadougou",
    "lat": 12.3572,
    "lng": -1.5353,
    "country": "Burkina Faso",
    "iso2": "BF",
    "iso3": "BFA",
    "admin_name": "Centre",
    "capital": "primary",
    "population": 1626950,
    "id": 1854029208
  },
  {
    "city": "Davao",
    "city_ascii": "Davao",
    "lat": 7.0667,
    "lng": 125.6,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Davao",
    "capital": "admin",
    "population": 1632991,
    "id": 1608906877
  },
  {
    "city": "Manhattan",
    "city_ascii": "Manhattan",
    "lat": 40.7834,
    "lng": -73.9662,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 1628706,
    "id": 1840034000
  },
  {
    "city": "Semarang",
    "city_ascii": "Semarang",
    "lat": -6.9667,
    "lng": 110.4167,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Jawa Tengah",
    "capital": "admin",
    "population": 1621384,
    "id": 1360745537
  },
  {
    "city": "Yinchuan",
    "city_ascii": "Yinchuan",
    "lat": 38.4795,
    "lng": 106.2254,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Ningxia",
    "capital": "admin",
    "population": 1616000,
    "id": 1156359072
  },
  {
    "city": "Multan",
    "city_ascii": "Multan",
    "lat": 30.1978,
    "lng": 71.4711,
    "country": "Pakistan",
    "iso2": "PK",
    "iso3": "PAK",
    "admin_name": "Punjab",
    "capital": "minor",
    "population": 1606481,
    "id": 1586256291
  },
  {
    "city": "Caloocan City",
    "city_ascii": "Caloocan City",
    "lat": 14.65,
    "lng": 120.9667,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Caloocan",
    "capital": "admin",
    "population": 1583978,
    "id": 1608897690
  },
  {
    "city": "Harare",
    "city_ascii": "Harare",
    "lat": -17.8292,
    "lng": 31.0522,
    "country": "Zimbabwe",
    "iso2": "ZW",
    "iso3": "ZWE",
    "admin_name": "Harare",
    "capital": "primary",
    "population": 1606000,
    "id": 1716196799
  },
  {
    "city": "Novosibirsk",
    "city_ascii": "Novosibirsk",
    "lat": 55.0333,
    "lng": 82.9167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Novosibirskaya Oblast’",
    "capital": "admin",
    "population": 1602915,
    "id": 1643399240
  },
  {
    "city": "Chengtangcun",
    "city_ascii": "Chengtangcun",
    "lat": 35.0833,
    "lng": 117.15,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Shandong",
    "capital": "",
    "population": 1603659,
    "id": 1156883901
  },
  {
    "city": "Āgra",
    "city_ascii": "Agra",
    "lat": 27.18,
    "lng": 78.02,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Uttar Pradesh",
    "capital": "",
    "population": 1585705,
    "id": 1356187395
  },
  {
    "city": "Karaj",
    "city_ascii": "Karaj",
    "lat": 35.8327,
    "lng": 50.9915,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Alborz",
    "capital": "admin",
    "population": 1592492,
    "id": 1364258511
  },
  {
    "city": "Indianapolis",
    "city_ascii": "Indianapolis",
    "lat": 39.7771,
    "lng": -86.1458,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "admin",
    "population": 1588961,
    "id": 1840030084
  },
  {
    "city": "Jingdezhen",
    "city_ascii": "Jingdezhen",
    "lat": 29.2942,
    "lng": 117.2036,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Jiangxi",
    "capital": "minor",
    "population": 1587477,
    "id": 1156038165
  },
  {
    "city": "Puebla",
    "city_ascii": "Puebla",
    "lat": 19.0333,
    "lng": -98.1833,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Puebla",
    "capital": "admin",
    "population": 1576259,
    "id": 1484416394
  },
  {
    "city": "Kalyān",
    "city_ascii": "Kalyan",
    "lat": 19.2502,
    "lng": 73.1602,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Mahārāshtra",
    "capital": "",
    "population": 1576614,
    "id": 1356172246
  },
  {
    "city": "Madurai",
    "city_ascii": "Madurai",
    "lat": 9.9197,
    "lng": 78.1194,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Tamil Nādu",
    "capital": "minor",
    "population": 1561129,
    "id": 1356257289
  },
  {
    "city": "Gujranwala",
    "city_ascii": "Gujranwala",
    "lat": 32.15,
    "lng": 74.1833,
    "country": "Pakistan",
    "iso2": "PK",
    "iso3": "PAK",
    "admin_name": "Punjab",
    "capital": "minor",
    "population": 1569090,
    "id": 1586400472
  },
  {
    "city": "Benxi",
    "city_ascii": "Benxi",
    "lat": 41.292,
    "lng": 123.761,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Liaoning",
    "capital": "",
    "population": 1567000,
    "id": 1156097909
  },
  {
    "city": "Jamshedpur",
    "city_ascii": "Jamshedpur",
    "lat": 22.8,
    "lng": 86.1833,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Jharkhand",
    "capital": "",
    "population": 1558000,
    "id": 1356501259
  },
  {
    "city": "Zhuhai",
    "city_ascii": "Zhuhai",
    "lat": 22.2769,
    "lng": 113.5678,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Guangdong",
    "capital": "minor",
    "population": 1562000,
    "id": 1156722242
  },
  {
    "city": "Recife",
    "city_ascii": "Recife",
    "lat": -8.0539,
    "lng": -34.8808,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Pernambuco",
    "capital": "admin",
    "population": 1555039,
    "id": 1076137337
  },
  {
    "city": "Columbus",
    "city_ascii": "Columbus",
    "lat": 39.9862,
    "lng": -82.985,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "admin",
    "population": 1562009,
    "id": 1840003760
  },
  {
    "city": "Tabrīz",
    "city_ascii": "Tabriz",
    "lat": 38.0833,
    "lng": 46.2833,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Āz̄arbāyjān-e Sharqī",
    "capital": "admin",
    "population": 1558693,
    "id": 1364141756
  },
  {
    "city": "Santiago",
    "city_ascii": "Santiago",
    "lat": 19.5,
    "lng": -70.67,
    "country": "Dominican Republic",
    "iso2": "DO",
    "iso3": "DOM",
    "admin_name": "Cibao Norte",
    "capital": "admin",
    "population": 1550753,
    "id": 1214985348
  },
  {
    "city": "Maracaibo",
    "city_ascii": "Maracaibo",
    "lat": 10.6333,
    "lng": -71.6333,
    "country": "Venezuela",
    "iso2": "VE",
    "iso3": "VEN",
    "admin_name": "Zulia",
    "capital": "admin",
    "population": 1551539,
    "id": 1862072711
  },
  {
    "city": "Beihai",
    "city_ascii": "Beihai",
    "lat": 21.4667,
    "lng": 109.1,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Guangxi",
    "capital": "",
    "population": 1539251,
    "id": 1156421449
  },
  {
    "city": "Shuangyashan",
    "city_ascii": "Shuangyashan",
    "lat": 46.6388,
    "lng": 131.1545,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Heilongjiang",
    "capital": "",
    "population": 1530000,
    "id": 1156245904
  },
  {
    "city": "Kōbe",
    "city_ascii": "Kobe",
    "lat": 34.6913,
    "lng": 135.183,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hyōgo",
    "capital": "admin",
    "population": 1522944,
    "id": 1392978082
  },
  {
    "city": "Charlotte",
    "city_ascii": "Charlotte",
    "lat": 35.208,
    "lng": -80.8304,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 1512923,
    "id": 1840014557
  },
  {
    "city": "Yucheng",
    "city_ascii": "Yucheng",
    "lat": 29.9888,
    "lng": 103.0007,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Sichuan",
    "capital": "minor",
    "population": 1512000,
    "id": 1156817119
  },
  {
    "city": "Changshu",
    "city_ascii": "Changshu",
    "lat": 31.65,
    "lng": 120.7333,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Jiangsu",
    "capital": "",
    "population": 1510100,
    "id": 1156901538
  },
  {
    "city": "Ximeicun",
    "city_ascii": "Ximeicun",
    "lat": 24.9633,
    "lng": 118.3811,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Fujian",
    "capital": "",
    "population": 1508000,
    "id": 1156173813
  },
  {
    "city": "Jianguang",
    "city_ascii": "Jianguang",
    "lat": 28.1958,
    "lng": 115.7833,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Jiangxi",
    "capital": "",
    "population": 1507402,
    "id": 1156545200
  },
  {
    "city": "Gwangju",
    "city_ascii": "Gwangju",
    "lat": 35.1667,
    "lng": 126.9167,
    "country": "Korea, South",
    "iso2": "KR",
    "iso3": "KOR",
    "admin_name": "Gwangju",
    "capital": "admin",
    "population": 1501557,
    "id": 1410000087
  },
  {
    "city": "Xushan",
    "city_ascii": "Xushan",
    "lat": 30.1697,
    "lng": 121.2665,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Zhejiang",
    "capital": "minor",
    "population": 1502000,
    "id": 1156046916
  },
  {
    "city": "Guiping",
    "city_ascii": "Guiping",
    "lat": 23.4,
    "lng": 110.0833,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Guangxi",
    "capital": "minor",
    "population": 1496904,
    "id": 1156165517
  },
  {
    "city": "Nāsik",
    "city_ascii": "Nasik",
    "lat": 20,
    "lng": 73.7833,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Mahārāshtra",
    "capital": "",
    "population": 1486053,
    "id": 1356562598
  },
  {
    "city": "Porto Alegre",
    "city_ascii": "Porto Alegre",
    "lat": -30.0328,
    "lng": -51.23,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Rio Grande do Sul",
    "capital": "admin",
    "population": 1484941,
    "id": 1076658221
  },
  {
    "city": "Zhangjiajie",
    "city_ascii": "Zhangjiajie",
    "lat": 29.1255,
    "lng": 110.4844,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hunan",
    "capital": "minor",
    "population": 1486000,
    "id": 1156072884
  },
  {
    "city": "Valencia",
    "city_ascii": "Valencia",
    "lat": 10.1667,
    "lng": -68,
    "country": "Venezuela",
    "iso2": "VE",
    "iso3": "VEN",
    "admin_name": "Carabobo",
    "capital": "admin",
    "population": 1484430,
    "id": 1862309871
  },
  {
    "city": "Virginia Beach",
    "city_ascii": "Virginia Beach",
    "lat": 36.7335,
    "lng": -76.0435,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 1478868,
    "id": 1840003871
  },
  {
    "city": "Daejeon",
    "city_ascii": "Daejeon",
    "lat": 36.351,
    "lng": 127.385,
    "country": "Korea, South",
    "iso2": "KR",
    "iso3": "KOR",
    "admin_name": "Daejeon",
    "capital": "admin",
    "population": 1475221,
    "id": 1410993875
  },
  {
    "city": "Munich",
    "city_ascii": "Munich",
    "lat": 48.1372,
    "lng": 11.5755,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "admin",
    "population": 1471508,
    "id": 1276692352
  },
  {
    "city": "Yekaterinburg",
    "city_ascii": "Yekaterinburg",
    "lat": 56.8356,
    "lng": 60.6128,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sverdlovskaya Oblast’",
    "capital": "admin",
    "population": 1468833,
    "id": 1643582706
  },
  {
    "city": "Auckland",
    "city_ascii": "Auckland",
    "lat": -36.85,
    "lng": 174.7833,
    "country": "New Zealand",
    "iso2": "NZ",
    "iso3": "NZL",
    "admin_name": "Auckland",
    "capital": "admin",
    "population": 1467800,
    "id": 1554435911
  },
  {
    "city": "Yunfu",
    "city_ascii": "Yunfu",
    "lat": 22.9242,
    "lng": 112.0353,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Guangdong",
    "capital": "minor",
    "population": 1470000,
    "id": 1156057251
  },
  {
    "city": "Huangshan",
    "city_ascii": "Huangshan",
    "lat": 29.7132,
    "lng": 118.3151,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Anhui",
    "capital": "minor",
    "population": 1470000,
    "id": 1156527426
  },
  {
    "city": "Huazhou",
    "city_ascii": "Huazhou",
    "lat": 32.6832,
    "lng": 112.079,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Henan",
    "capital": "minor",
    "population": 1468061,
    "id": 1156796734
  },
  {
    "city": "Shīrāz",
    "city_ascii": "Shiraz",
    "lat": 29.61,
    "lng": 52.5425,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Fārs",
    "capital": "admin",
    "population": 1460665,
    "id": 1364432238
  },
  {
    "city": "León de los Aldama",
    "city_ascii": "Leon de los Aldama",
    "lat": 21.1167,
    "lng": -101.6833,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Guanajuato",
    "capital": "minor",
    "population": 1454793,
    "id": 1484811199
  },
  {
    "city": "Pizhou",
    "city_ascii": "Pizhou",
    "lat": 34.3422,
    "lng": 118.0097,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Jiangsu",
    "capital": "",
    "population": 1458038,
    "id": 1156114238
  },
  {
    "city": "Palembang",
    "city_ascii": "Palembang",
    "lat": -2.9833,
    "lng": 104.7644,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Sumatera Selatan",
    "capital": "admin",
    "population": 1452456,
    "id": 1360902897
  },
  {
    "city": "Kharkiv",
    "city_ascii": "Kharkiv",
    "lat": 50,
    "lng": 36.2292,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Kharkivs’ka Oblast’",
    "capital": "admin",
    "population": 1446107,
    "id": 1804588111
  },
  {
    "city": "Kawanakajima",
    "city_ascii": "Kawanakajima",
    "lat": 35.53,
    "lng": 139.705,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Kanagawa",
    "capital": "",
    "population": 1437266,
    "id": 1392879600
  },
  {
    "city": "Lianjiang",
    "city_ascii": "Lianjiang",
    "lat": 21.6146,
    "lng": 110.2794,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Guangdong",
    "capital": "",
    "population": 1443099,
    "id": 1156944106
  },
  {
    "city": "Chizhou",
    "city_ascii": "Chizhou",
    "lat": 30.6583,
    "lng": 117.4849,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Anhui",
    "capital": "minor",
    "population": 1430000,
    "id": 1156131504
  },
  {
    "city": "Leizhou",
    "city_ascii": "Leizhou",
    "lat": 20.9147,
    "lng": 110.0806,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Guangdong",
    "capital": "",
    "population": 1427664,
    "id": 1156863840
  },
  {
    "city": "Guyuan",
    "city_ascii": "Guyuan",
    "lat": 36.008,
    "lng": 106.2782,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Ningxia",
    "capital": "",
    "population": 1426000,
    "id": 1156817541
  },
  {
    "city": "Rui’an",
    "city_ascii": "Rui'an",
    "lat": 27.778,
    "lng": 120.6526,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Zhejiang",
    "capital": "",
    "population": 1424667,
    "id": 1156993705
  },
  {
    "city": "Birstall",
    "city_ascii": "Birstall",
    "lat": 52.6736,
    "lng": -1.12,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Leicester",
    "capital": "",
    "population": 1424216,
    "id": 1826141026
  },
  {
    "city": "Muscat",
    "city_ascii": "Muscat",
    "lat": 23.6139,
    "lng": 58.5922,
    "country": "Oman",
    "iso2": "OM",
    "iso3": "OMN",
    "admin_name": "Masqaţ",
    "capital": "primary",
    "population": 1421409,
    "id": 1512035506
  },
  {
    "city": "Hebi",
    "city_ascii": "Hebi",
    "lat": 35.7497,
    "lng": 114.2887,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Henan",
    "capital": "",
    "population": 1419000,
    "id": 1156114258
  },
  {
    "city": "Jingling",
    "city_ascii": "Jingling",
    "lat": 30.6667,
    "lng": 113.1667,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hubei",
    "capital": "",
    "population": 1418913,
    "id": 1156060544
  },
  {
    "city": "Bronx",
    "city_ascii": "Bronx",
    "lat": 40.8501,
    "lng": -73.8662,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 1418207,
    "id": 1840033999
  },
  {
    "city": "The Hague",
    "city_ascii": "The Hague",
    "lat": 52.08,
    "lng": 4.27,
    "country": "Netherlands",
    "iso2": "NL",
    "iso3": "NLD",
    "admin_name": "Zuid-Holland",
    "capital": "primary",
    "population": 1406000,
    "id": 1528799905
  },
  {
    "city": "Owerri",
    "city_ascii": "Owerri",
    "lat": 5.4833,
    "lng": 7.0333,
    "country": "Nigeria",
    "iso2": "NG",
    "iso3": "NGA",
    "admin_name": "Imo",
    "capital": "admin",
    "population": 1401873,
    "id": 1566679132
  },
  {
    "city": "Sharjah",
    "city_ascii": "Sharjah",
    "lat": 25.3575,
    "lng": 55.3919,
    "country": "United Arab Emirates",
    "iso2": "AE",
    "iso3": "ARE",
    "admin_name": "Ash Shāriqah",
    "capital": "admin",
    "population": 1400000,
    "id": 1784152993
  },
  {
    "city": "Farīdābād",
    "city_ascii": "Faridabad",
    "lat": 28.4333,
    "lng": 77.3167,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Haryāna",
    "capital": "",
    "population": 1394000,
    "id": 1356013205
  },
  {
    "city": "Ulaanbaatar",
    "city_ascii": "Ulaanbaatar",
    "lat": 47.9203,
    "lng": 106.9172,
    "country": "Mongolia",
    "iso2": "MN",
    "iso3": "MNG",
    "admin_name": "Ulaanbaatar",
    "capital": "primary",
    "population": 1396288,
    "id": 1496024767
  },
  {
    "city": "Goiânia",
    "city_ascii": "Goiania",
    "lat": -16.6789,
    "lng": -49.2539,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Goiás",
    "capital": "admin",
    "population": 1393575,
    "id": 1076034054
  },
  {
    "city": "Belém",
    "city_ascii": "Belem",
    "lat": -1.4558,
    "lng": -48.5039,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Pará",
    "capital": "admin",
    "population": 1393399,
    "id": 1076646823
  },
  {
    "city": "Kayseri",
    "city_ascii": "Kayseri",
    "lat": 38.7333,
    "lng": 35.4833,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Kayseri",
    "capital": "admin",
    "population": 1389680,
    "id": 1792945815
  },
  {
    "city": "Yueqing",
    "city_ascii": "Yueqing",
    "lat": 28.1188,
    "lng": 120.9621,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Zhejiang",
    "capital": "",
    "population": 1389332,
    "id": 1156900058
  },
  {
    "city": "Belgrade",
    "city_ascii": "Belgrade",
    "lat": 44.8167,
    "lng": 20.4667,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Beograd",
    "capital": "primary",
    "population": 1378682,
    "id": 1688374696
  },
  {
    "city": "Pingdu",
    "city_ascii": "Pingdu",
    "lat": 36.7833,
    "lng": 119.9556,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Shandong",
    "capital": "",
    "population": 1378900,
    "id": 1156157078
  },
  {
    "city": "Aurangābād",
    "city_ascii": "Aurangabad",
    "lat": 19.88,
    "lng": 75.32,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Mahārāshtra",
    "capital": "",
    "population": 1371330,
    "id": 1356840670
  },
  {
    "city": "Yutan",
    "city_ascii": "Yutan",
    "lat": 28.3147,
    "lng": 112.554,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hunan",
    "capital": "minor",
    "population": 1368117,
    "id": 1156592042
  },
  {
    "city": "Wenling",
    "city_ascii": "Wenling",
    "lat": 28.3797,
    "lng": 121.3718,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Zhejiang",
    "capital": "",
    "population": 1366794,
    "id": 1156052493
  },
  {
    "city": "Islamabad",
    "city_ascii": "Islamabad",
    "lat": 33.6989,
    "lng": 73.0369,
    "country": "Pakistan",
    "iso2": "PK",
    "iso3": "PAK",
    "admin_name": "Islāmābād",
    "capital": "primary",
    "population": 1365000,
    "id": 1586306717
  },
  {
    "city": "Milwaukee",
    "city_ascii": "Milwaukee",
    "lat": 43.0642,
    "lng": -87.9673,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 1365787,
    "id": 1840003046
  },
  {
    "city": "Milan",
    "city_ascii": "Milan",
    "lat": 45.4669,
    "lng": 9.19,
    "country": "Italy",
    "iso2": "IT",
    "iso3": "ITA",
    "admin_name": "Lombardy",
    "capital": "admin",
    "population": 1351562,
    "id": 1380724377
  },
  {
    "city": "Sofia",
    "city_ascii": "Sofia",
    "lat": 42.6975,
    "lng": 23.3241,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Sofia-Grad",
    "capital": "primary",
    "population": 1355142,
    "id": 1100762037
  },
  {
    "city": "Adelaide",
    "city_ascii": "Adelaide",
    "lat": -34.9289,
    "lng": 138.6011,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "South Australia",
    "capital": "admin",
    "population": 1345777,
    "id": 1036538171
  },
  {
    "city": "Samsun",
    "city_ascii": "Samsun",
    "lat": 41.2867,
    "lng": 36.33,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Samsun",
    "capital": "admin",
    "population": 1335716,
    "id": 1792169057
  },
  {
    "city": "Rājkot",
    "city_ascii": "Rajkot",
    "lat": 22.2969,
    "lng": 70.7984,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Gujarāt",
    "capital": "",
    "population": 1335397,
    "id": 1356317190
  },
  {
    "city": "Khulna",
    "city_ascii": "Khulna",
    "lat": 22.8167,
    "lng": 89.55,
    "country": "Bangladesh",
    "iso2": "BD",
    "iso3": "BGD",
    "admin_name": "Khulna",
    "capital": "admin",
    "population": 1311100,
    "id": 1050667339
  },
  {
    "city": "Córdoba",
    "city_ascii": "Cordoba",
    "lat": -31.4167,
    "lng": -64.1833,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Córdoba",
    "capital": "admin",
    "population": 1329604,
    "id": 1032803249
  },
  {
    "city": "Guarulhos",
    "city_ascii": "Guarulhos",
    "lat": -23.4667,
    "lng": -46.5333,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 1324781,
    "id": 1076730420
  },
  {
    "city": "Juárez",
    "city_ascii": "Juarez",
    "lat": 31.7386,
    "lng": -106.487,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Chihuahua",
    "capital": "minor",
    "population": 1321004,
    "id": 1484840083
  },
  {
    "city": "Prague",
    "city_ascii": "Prague",
    "lat": 50.0833,
    "lng": 14.4167,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Praha, Hlavní Město",
    "capital": "primary",
    "population": 1324277,
    "id": 1203744823
  },
  {
    "city": "Montevideo",
    "city_ascii": "Montevideo",
    "lat": -34.8667,
    "lng": -56.1667,
    "country": "Uruguay",
    "iso2": "UY",
    "iso3": "URY",
    "admin_name": "Montevideo",
    "capital": "primary",
    "population": 1319108,
    "id": 1858107000
  },
  {
    "city": "Mbuji-Mayi",
    "city_ascii": "Mbuji-Mayi",
    "lat": -6.1209,
    "lng": 23.5967,
    "country": "Congo (Kinshasa)",
    "iso2": "CD",
    "iso3": "COD",
    "admin_name": "Kasaï Oriental",
    "capital": "admin",
    "population": 1295000,
    "id": 1180399586
  },
  {
    "city": "Fuqing",
    "city_ascii": "Fuqing",
    "lat": 25.7232,
    "lng": 119.3735,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Fujian",
    "capital": "minor",
    "population": 1316000,
    "id": 1156893569
  },
  {
    "city": "Xintai",
    "city_ascii": "Xintai",
    "lat": 35.91,
    "lng": 117.78,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Shandong",
    "capital": "",
    "population": 1315942,
    "id": 1156761230
  },
  {
    "city": "Doha",
    "city_ascii": "Doha",
    "lat": 25.3,
    "lng": 51.5333,
    "country": "Qatar",
    "iso2": "QA",
    "iso3": "QAT",
    "admin_name": "Ad Dawḩah",
    "capital": "primary",
    "population": 1312947,
    "id": 1634459660
  },
  {
    "city": "Saitama",
    "city_ascii": "Saitama",
    "lat": 35.8617,
    "lng": 139.6453,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Saitama",
    "capital": "admin",
    "population": 1307931,
    "id": 1392017719
  },
  {
    "city": "Hiroshima",
    "city_ascii": "Hiroshima",
    "lat": 34.3853,
    "lng": 132.4553,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hiroshima",
    "capital": "admin",
    "population": 1306589,
    "id": 1392373695
  },
  {
    "city": "Meerut",
    "city_ascii": "Meerut",
    "lat": 28.99,
    "lng": 77.7,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Uttar Pradesh",
    "capital": "",
    "population": 1305429,
    "id": 1356390476
  },
  {
    "city": "Yushu",
    "city_ascii": "Yushu",
    "lat": 44.8249,
    "lng": 126.5451,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Jilin",
    "capital": "",
    "population": 1304436,
    "id": 1156326773
  },
  {
    "city": "Rongcheng",
    "city_ascii": "Rongcheng",
    "lat": 26.2312,
    "lng": 104.0966,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Yunnan",
    "capital": "",
    "population": 1302891,
    "id": 1156122888
  },
  {
    "city": "Yangquan",
    "city_ascii": "Yangquan",
    "lat": 37.8576,
    "lng": 113.5762,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Shanxi",
    "capital": "minor",
    "population": 1300000,
    "id": 1156157844
  },
  {
    "city": "Haicheng",
    "city_ascii": "Haicheng",
    "lat": 40.8523,
    "lng": 122.7474,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Liaoning",
    "capital": "minor",
    "population": 1293877,
    "id": 1156052713
  },
  {
    "city": "Gaozhou",
    "city_ascii": "Gaozhou",
    "lat": 21.9392,
    "lng": 110.8461,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Guangdong",
    "capital": "",
    "population": 1288665,
    "id": 1156785303
  },
  {
    "city": "Yingtan",
    "city_ascii": "Yingtan",
    "lat": 28.2333,
    "lng": 117,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Jiangxi",
    "capital": "",
    "population": 1288615,
    "id": 1156301061
  },
  {
    "city": "Huaiyin",
    "city_ascii": "Huaiyin",
    "lat": 33.58,
    "lng": 119.03,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Jiangsu",
    "capital": "",
    "population": 1264000,
    "id": 1156662372
  },
  {
    "city": "Wuzhong",
    "city_ascii": "Wuzhong",
    "lat": 37.9874,
    "lng": 106.1919,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Ningxia",
    "capital": "minor",
    "population": 1284000,
    "id": 1156139322
  },
  {
    "city": "Jabalpur",
    "city_ascii": "Jabalpur",
    "lat": 23.1667,
    "lng": 79.9333,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Madhya Pradesh",
    "capital": "",
    "population": 1267564,
    "id": 1356115460
  },
  {
    "city": "Thāne",
    "city_ascii": "Thane",
    "lat": 19.18,
    "lng": 72.9633,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Mahārāshtra",
    "capital": "",
    "population": 1261517,
    "id": 1356681152
  },
  {
    "city": "Zhangye",
    "city_ascii": "Zhangye",
    "lat": 38.9355,
    "lng": 100.4553,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Gansu",
    "capital": "minor",
    "population": 1270000,
    "id": 1156180353
  },
  {
    "city": "Rucheng",
    "city_ascii": "Rucheng",
    "lat": 32.3852,
    "lng": 120.5634,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Jiangsu",
    "capital": "minor",
    "population": 1267066,
    "id": 1156323529
  },
  {
    "city": "Nizhniy Novgorod",
    "city_ascii": "Nizhniy Novgorod",
    "lat": 56.3269,
    "lng": 44.0075,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Nizhegorodskaya Oblast’",
    "capital": "admin",
    "population": 1264075,
    "id": 1643012126
  },
  {
    "city": "Comayagüela",
    "city_ascii": "Comayaguela",
    "lat": 14.0833,
    "lng": -87.2167,
    "country": "Honduras",
    "iso2": "HN",
    "iso3": "HND",
    "admin_name": "Francisco Morazán",
    "capital": "",
    "population": 1250000,
    "id": 1340762485
  },
  {
    "city": "Yangshe",
    "city_ascii": "Yangshe",
    "lat": 31.8775,
    "lng": 120.5512,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Jiangsu",
    "capital": "minor",
    "population": 1253100,
    "id": 1156203000
  },
  {
    "city": "Shaoyang",
    "city_ascii": "Shaoyang",
    "lat": 32.9387,
    "lng": 119.8404,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Jiangsu",
    "capital": "minor",
    "population": 1253548,
    "id": 1156668366
  },
  {
    "city": "Dhanbād",
    "city_ascii": "Dhanbad",
    "lat": 23.7928,
    "lng": 86.435,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Jharkhand",
    "capital": "",
    "population": 1246000,
    "id": 1356103392
  },
  {
    "city": "Yichun",
    "city_ascii": "Yichun",
    "lat": 47.7235,
    "lng": 128.8893,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Heilongjiang",
    "capital": "minor",
    "population": 1250000,
    "id": 1156757274
  },
  {
    "city": "Laiwu",
    "city_ascii": "Laiwu",
    "lat": 36.1833,
    "lng": 117.6667,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Shandong",
    "capital": "",
    "population": 1248636,
    "id": 1156207332
  },
  {
    "city": "Kazan",
    "city_ascii": "Kazan",
    "lat": 55.7908,
    "lng": 49.1144,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Tatarstan",
    "capital": "admin",
    "population": 1243500,
    "id": 1643101260
  },
  {
    "city": "Dayan",
    "city_ascii": "Dayan",
    "lat": 26.8808,
    "lng": 100.2208,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Yunnan",
    "capital": "",
    "population": 1244769,
    "id": 1156828380
  },
  {
    "city": "Suwon",
    "city_ascii": "Suwon",
    "lat": 37.2858,
    "lng": 127.01,
    "country": "Korea, South",
    "iso2": "KR",
    "iso3": "KOR",
    "admin_name": "Gyeonggi",
    "capital": "admin",
    "population": 1234300,
    "id": 1410679777
  },
  {
    "city": "Jiangyin",
    "city_ascii": "Jiangyin",
    "lat": 31.9087,
    "lng": 120.2653,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Jiangsu",
    "capital": "",
    "population": 1241045,
    "id": 1156033234
  },
  {
    "city": "Yongcheng",
    "city_ascii": "Yongcheng",
    "lat": 33.9317,
    "lng": 116.4459,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Henan",
    "capital": "",
    "population": 1240382,
    "id": 1156841802
  },
  {
    "city": "Calgary",
    "city_ascii": "Calgary",
    "lat": 51.05,
    "lng": -114.0667,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Alberta",
    "capital": "",
    "population": 1239220,
    "id": 1124690423
  },
  {
    "city": "Cần Thơ",
    "city_ascii": "Can Tho",
    "lat": 10.0333,
    "lng": 105.7833,
    "country": "Vietnam",
    "iso2": "VN",
    "iso3": "VNM",
    "admin_name": "Cần Thơ",
    "capital": "admin",
    "population": 1237300,
    "id": 1704783472
  },
  {
    "city": "Abuja",
    "city_ascii": "Abuja",
    "lat": 9.0556,
    "lng": 7.4914,
    "country": "Nigeria",
    "iso2": "NG",
    "iso3": "NGA",
    "admin_name": "Federal Capital Territory",
    "capital": "primary",
    "population": 1235880,
    "id": 1566342270
  },
  {
    "city": "Yiwu",
    "city_ascii": "Yiwu",
    "lat": 29.3081,
    "lng": 120.0698,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Zhejiang",
    "capital": "minor",
    "population": 1234015,
    "id": 1156078044
  },
  {
    "city": "Mandalay",
    "city_ascii": "Mandalay",
    "lat": 21.9769,
    "lng": 96.0869,
    "country": "Burma",
    "iso2": "MM",
    "iso3": "MMR",
    "admin_name": "Mandalay",
    "capital": "admin",
    "population": 1225546,
    "id": 1104468217
  },
  {
    "city": "Beidao",
    "city_ascii": "Beidao",
    "lat": 34.6,
    "lng": 105.92,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Gansu",
    "capital": "minor",
    "population": 1225000,
    "id": 1156137209
  },
  {
    "city": "Al Başrah",
    "city_ascii": "Al Basrah",
    "lat": 30.4889,
    "lng": 47.81,
    "country": "Iraq",
    "iso2": "IQ",
    "iso3": "IRQ",
    "admin_name": "Al Başrah",
    "capital": "admin",
    "population": 1225793,
    "id": 1368714645
  },
  {
    "city": "Shuangshui",
    "city_ascii": "Shuangshui",
    "lat": 26.5944,
    "lng": 104.8333,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Guizhou",
    "capital": "",
    "population": 1221000,
    "id": 1156070389
  },
  {
    "city": "Sevilla",
    "city_ascii": "Sevilla",
    "lat": 37.3828,
    "lng": -5.9731,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Andalusia",
    "capital": "admin",
    "population": 1212045,
    "id": 1724991838
  },
  {
    "city": "Vila Velha",
    "city_ascii": "Vila Velha",
    "lat": 3.2167,
    "lng": -51.2167,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Amapá",
    "capital": "",
    "population": 1209091,
    "id": 1076575691
  },
  {
    "city": "Allahābād",
    "city_ascii": "Allahabad",
    "lat": 25.455,
    "lng": 81.84,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Uttar Pradesh",
    "capital": "",
    "population": 1201000,
    "id": 1356718332
  },
  {
    "city": "Vārānasi",
    "city_ascii": "Varanasi",
    "lat": 25.3189,
    "lng": 83.0128,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Uttar Pradesh",
    "capital": "",
    "population": 1198491,
    "id": 1356145782
  },
  {
    "city": "Tunis",
    "city_ascii": "Tunis",
    "lat": 36.8008,
    "lng": 10.18,
    "country": "Tunisia",
    "iso2": "TN",
    "iso3": "TUN",
    "admin_name": "Tunis",
    "capital": "primary",
    "population": 1200000,
    "id": 1788742103
  },
  {
    "city": "Chelyabinsk",
    "city_ascii": "Chelyabinsk",
    "lat": 55.15,
    "lng": 61.4,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Chelyabinskaya Oblast’",
    "capital": "admin",
    "population": 1202371,
    "id": 1643224011
  },
  {
    "city": "Mombasa",
    "city_ascii": "Mombasa",
    "lat": -4.05,
    "lng": 39.6667,
    "country": "Kenya",
    "iso2": "KE",
    "iso3": "KEN",
    "admin_name": "Mombasa",
    "capital": "admin",
    "population": 1200000,
    "id": 1404512373
  },
  {
    "city": "Providence",
    "city_ascii": "Providence",
    "lat": 41.823,
    "lng": -71.4187,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Rhode Island",
    "capital": "admin",
    "population": 1203230,
    "id": 1840003289
  },
  {
    "city": "Qom",
    "city_ascii": "Qom",
    "lat": 34.6461,
    "lng": 50.8789,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Qom",
    "capital": "admin",
    "population": 1201158,
    "id": 1364701633
  },
  {
    "city": "Maiduguri",
    "city_ascii": "Maiduguri",
    "lat": 11.8333,
    "lng": 13.15,
    "country": "Nigeria",
    "iso2": "NG",
    "iso3": "NGA",
    "admin_name": "Borno",
    "capital": "admin",
    "population": 1197497,
    "id": 1566540296
  },
  {
    "city": "Maputo",
    "city_ascii": "Maputo",
    "lat": -25.9153,
    "lng": 32.5764,
    "country": "Mozambique",
    "iso2": "MZ",
    "iso3": "MOZ",
    "admin_name": "Maputo",
    "capital": "primary",
    "population": 1191613,
    "id": 1508074843
  },
  {
    "city": "Rosario",
    "city_ascii": "Rosario",
    "lat": -32.9575,
    "lng": -60.6394,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Santa Fe",
    "capital": "minor",
    "population": 1193605,
    "id": 1032770677
  },
  {
    "city": "Benin City",
    "city_ascii": "Benin City",
    "lat": 6.3176,
    "lng": 5.6145,
    "country": "Nigeria",
    "iso2": "NG",
    "iso3": "NGA",
    "admin_name": "Edo",
    "capital": "admin",
    "population": 1190000,
    "id": 1566080151
  },
  {
    "city": "Xinyu",
    "city_ascii": "Xinyu",
    "lat": 27.795,
    "lng": 114.9242,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Jiangxi",
    "capital": "minor",
    "population": 1186700,
    "id": 1156138735
  },
  {
    "city": "Ahvāz",
    "city_ascii": "Ahvaz",
    "lat": 31.3203,
    "lng": 48.6692,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Khūzestān",
    "capital": "admin",
    "population": 1184788,
    "id": 1364350188
  },
  {
    "city": "Dublin",
    "city_ascii": "Dublin",
    "lat": 53.3425,
    "lng": -6.2658,
    "country": "Ireland",
    "iso2": "IE",
    "iso3": "IRL",
    "admin_name": "Dublin",
    "capital": "primary",
    "population": 1173179,
    "id": 1372595407
  },
  {
    "city": "Jacksonville",
    "city_ascii": "Jacksonville",
    "lat": 30.3322,
    "lng": -81.6749,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 1181496,
    "id": 1840015031
  },
  {
    "city": "Medina",
    "city_ascii": "Medina",
    "lat": 24.4667,
    "lng": 39.6,
    "country": "Saudi Arabia",
    "iso2": "SA",
    "iso3": "SAU",
    "admin_name": "Al Madīnah al Munawwarah",
    "capital": "admin",
    "population": 1180770,
    "id": 1682345785
  },
  {
    "city": "Srīnagar",
    "city_ascii": "Srinagar",
    "lat": 34.0911,
    "lng": 74.8061,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Jammu and Kashmīr",
    "capital": "admin",
    "population": 1180570,
    "id": 1356442950
  },
  {
    "city": "Omsk",
    "city_ascii": "Omsk",
    "lat": 54.9667,
    "lng": 73.3833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Omskaya Oblast’",
    "capital": "admin",
    "population": 1178391,
    "id": 1643360863
  },
  {
    "city": "Huazhou",
    "city_ascii": "Huazhou",
    "lat": 21.654,
    "lng": 110.6294,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Guangdong",
    "capital": "",
    "population": 1178809,
    "id": 1156282637
  },
  {
    "city": "Cilacap",
    "city_ascii": "Cilacap",
    "lat": -7.7167,
    "lng": 109.017,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Jawa Tengah",
    "capital": "minor",
    "population": 1174964,
    "id": 1360503809
  },
  {
    "city": "Xiantao",
    "city_ascii": "Xiantao",
    "lat": 30.3833,
    "lng": 113.4,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hubei",
    "capital": "",
    "population": 1175085,
    "id": 1156216927
  },
  {
    "city": "Bandar Lampung",
    "city_ascii": "Bandar Lampung",
    "lat": -5.4294,
    "lng": 105.2625,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Lampung",
    "capital": "admin",
    "population": 1166761,
    "id": 1360243491
  },
  {
    "city": "Samara",
    "city_ascii": "Samara",
    "lat": 53.1833,
    "lng": 50.1167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Samarskaya Oblast’",
    "capital": "admin",
    "population": 1169719,
    "id": 1643205757
  },
  {
    "city": "Guankou",
    "city_ascii": "Guankou",
    "lat": 28.1417,
    "lng": 113.625,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hunan",
    "capital": "minor",
    "population": 1168056,
    "id": 1156249346
  },
  {
    "city": "Ulsan",
    "city_ascii": "Ulsan",
    "lat": 35.55,
    "lng": 129.3167,
    "country": "Korea, South",
    "iso2": "KR",
    "iso3": "KOR",
    "admin_name": "Ulsan",
    "capital": "admin",
    "population": 1166033,
    "id": 1410134602
  },
  {
    "city": "Dingzhou",
    "city_ascii": "Dingzhou",
    "lat": 38.5158,
    "lng": 114.9845,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hebei",
    "capital": "",
    "population": 1165182,
    "id": 1156656582
  },
  {
    "city": "Campinas",
    "city_ascii": "Campinas",
    "lat": -22.9009,
    "lng": -47.0573,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 1164098,
    "id": 1076145766
  },
  {
    "city": "Lianyuan",
    "city_ascii": "Lianyuan",
    "lat": 27.6961,
    "lng": 111.6659,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hunan",
    "capital": "minor",
    "population": 1162928,
    "id": 1156490109
  },
  {
    "city": "Rongcheng",
    "city_ascii": "Rongcheng",
    "lat": 29.8239,
    "lng": 112.9019,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hubei",
    "capital": "minor",
    "population": 1162770,
    "id": 1156102432
  },
  {
    "city": "Kaiyuan",
    "city_ascii": "Kaiyuan",
    "lat": 36.0656,
    "lng": 113.8153,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Henan",
    "capital": "minor",
    "population": 1160000,
    "id": 1156441554
  },
  {
    "city": "Nay Pyi Taw",
    "city_ascii": "Nay Pyi Taw",
    "lat": 19.8028,
    "lng": 96.1583,
    "country": "Burma",
    "iso2": "MM",
    "iso3": "MMR",
    "admin_name": "Nay Pyi Taw",
    "capital": "primary",
    "population": 1160242,
    "id": 1104838105
  },
  {
    "city": "Dakar",
    "city_ascii": "Dakar",
    "lat": 14.7319,
    "lng": -17.4572,
    "country": "Senegal",
    "iso2": "SN",
    "iso3": "SEN",
    "admin_name": "Dakar",
    "capital": "primary",
    "population": 1146053,
    "id": 1686604760
  },
  {
    "city": "Zhuji",
    "city_ascii": "Zhuji",
    "lat": 29.7169,
    "lng": 120.2314,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Zhejiang",
    "capital": "minor",
    "population": 1157938,
    "id": 1156504227
  },
  {
    "city": "Zapopan",
    "city_ascii": "Zapopan",
    "lat": 20.7167,
    "lng": -103.4,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Jalisco",
    "capital": "minor",
    "population": 1155790,
    "id": 1484198680
  },
  {
    "city": "Leiyang",
    "city_ascii": "Leiyang",
    "lat": 26.4179,
    "lng": 112.8457,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hunan",
    "capital": "minor",
    "population": 1151554,
    "id": 1156057789
  },
  {
    "city": "Dadukou",
    "city_ascii": "Dadukou",
    "lat": 26.5849,
    "lng": 101.7149,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Sichuan",
    "capital": "minor",
    "population": 1144000,
    "id": 1156901342
  },
  {
    "city": "Quetta",
    "city_ascii": "Quetta",
    "lat": 30.192,
    "lng": 67.007,
    "country": "Pakistan",
    "iso2": "PK",
    "iso3": "PAK",
    "admin_name": "Balochistān",
    "capital": "admin",
    "population": 1140000,
    "id": 1586556108
  },
  {
    "city": "Amritsar",
    "city_ascii": "Amritsar",
    "lat": 31.6167,
    "lng": 74.85,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Punjab",
    "capital": "",
    "population": 1132383,
    "id": 1356926507
  },
  {
    "city": "Callao",
    "city_ascii": "Callao",
    "lat": -12.0611,
    "lng": -77.1333,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "Callao",
    "capital": "admin",
    "population": 1129854,
    "id": 1604422141
  },
  {
    "city": "Alīgarh",
    "city_ascii": "Aligarh",
    "lat": 27.88,
    "lng": 78.08,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Uttar Pradesh",
    "capital": "",
    "population": 1131160,
    "id": 1356076391
  },
  {
    "city": "Yingchuan",
    "city_ascii": "Yingchuan",
    "lat": 34.1511,
    "lng": 113.4733,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Henan",
    "capital": "minor",
    "population": 1131896,
    "id": 1156748426
  },
  {
    "city": "Tegucigalpa",
    "city_ascii": "Tegucigalpa",
    "lat": 14.0942,
    "lng": -87.2067,
    "country": "Honduras",
    "iso2": "HN",
    "iso3": "HND",
    "admin_name": "Francisco Morazán",
    "capital": "primary",
    "population": 1126534,
    "id": 1340344059
  },
  {
    "city": "Ciudad Nezahualcóyotl",
    "city_ascii": "Ciudad Nezahualcoyotl",
    "lat": 19.41,
    "lng": -99.03,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "México",
    "capital": "",
    "population": 1109363,
    "id": 1484016661
  },
  {
    "city": "Tripoli",
    "city_ascii": "Tripoli",
    "lat": 32.8752,
    "lng": 13.1875,
    "country": "Libya",
    "iso2": "LY",
    "iso3": "LBY",
    "admin_name": "Ţarābulus",
    "capital": "primary",
    "population": 1126000,
    "id": 1434201852
  },
  {
    "city": "Rostov",
    "city_ascii": "Rostov",
    "lat": 47.2333,
    "lng": 39.7167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Rostovskaya Oblast’",
    "capital": "admin",
    "population": 1125299,
    "id": 1643013518
  },
  {
    "city": "Nezahualcóyotl",
    "city_ascii": "Nezahualcoyotl",
    "lat": 19.4,
    "lng": -98.9889,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "México",
    "capital": "minor",
    "population": 1110565,
    "id": 1484074285
  },
  {
    "city": "Bhiwandi",
    "city_ascii": "Bhiwandi",
    "lat": 19.3,
    "lng": 73.0667,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Mahārāshtra",
    "capital": "",
    "population": 1125898,
    "id": 1356763571
  },
  {
    "city": "Zhoushan",
    "city_ascii": "Zhoushan",
    "lat": 30.0162,
    "lng": 122.0988,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Zhejiang",
    "capital": "minor",
    "population": 1121261,
    "id": 1156200283
  },
  {
    "city": "Tbilisi",
    "city_ascii": "Tbilisi",
    "lat": 41.7225,
    "lng": 44.7925,
    "country": "Georgia",
    "iso2": "GE",
    "iso3": "GEO",
    "admin_name": "Tbilisi",
    "capital": "primary",
    "population": 1118035,
    "id": 1268203191
  },
  {
    "city": "Ufa",
    "city_ascii": "Ufa",
    "lat": 54.7333,
    "lng": 55.9667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Bashkortostan",
    "capital": "admin",
    "population": 1115560,
    "id": 1643722928
  },
  {
    "city": "Fès",
    "city_ascii": "Fes",
    "lat": 34.0433,
    "lng": -5.0033,
    "country": "Morocco",
    "iso2": "MA",
    "iso3": "MAR",
    "admin_name": "Fès-Meknès",
    "capital": "admin",
    "population": 1112072,
    "id": 1504861997
  },
  {
    "city": "Biên Hòa",
    "city_ascii": "Bien Hoa",
    "lat": 10.9575,
    "lng": 106.8426,
    "country": "Vietnam",
    "iso2": "VN",
    "iso3": "VNM",
    "admin_name": "Đồng Nai",
    "capital": "admin",
    "population": 1104000,
    "id": 1704863046
  },
  {
    "city": "Mexicali",
    "city_ascii": "Mexicali",
    "lat": 32.6633,
    "lng": -115.4678,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Baja California",
    "capital": "admin",
    "population": 1102342,
    "id": 1484788702
  },
  {
    "city": "Gwalior",
    "city_ascii": "Gwalior",
    "lat": 26.215,
    "lng": 78.1931,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Madhya Pradesh",
    "capital": "",
    "population": 1101981,
    "id": 1356363967
  },
  {
    "city": "Ankang",
    "city_ascii": "Ankang",
    "lat": 32.6877,
    "lng": 109.0235,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Shaanxi",
    "capital": "",
    "population": 1100000,
    "id": 1156255711
  },
  {
    "city": "Ikare",
    "city_ascii": "Ikare",
    "lat": 7.5304,
    "lng": 5.76,
    "country": "Nigeria",
    "iso2": "NG",
    "iso3": "NGA",
    "admin_name": "Ondo",
    "capital": "minor",
    "population": 1099931,
    "id": 1566683694
  },
  {
    "city": "Hegang",
    "city_ascii": "Hegang",
    "lat": 47.3139,
    "lng": 130.2775,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Heilongjiang",
    "capital": "minor",
    "population": 1100000,
    "id": 1156541759
  },
  {
    "city": "Salt Lake City",
    "city_ascii": "Salt Lake City",
    "lat": 40.7777,
    "lng": -111.9306,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Utah",
    "capital": "admin",
    "population": 1098526,
    "id": 1840021383
  },
  {
    "city": "Bhilai",
    "city_ascii": "Bhilai",
    "lat": 21.2167,
    "lng": 81.4333,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Chhattīsgarh",
    "capital": "",
    "population": 1097000,
    "id": 1356071083
  },
  {
    "city": "Yuyao",
    "city_ascii": "Yuyao",
    "lat": 30.0372,
    "lng": 121.1546,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Zhejiang",
    "capital": "",
    "population": 1095000,
    "id": 1156221984
  },
  {
    "city": "N’Djamena",
    "city_ascii": "N'Djamena",
    "lat": 12.11,
    "lng": 15.05,
    "country": "Chad",
    "iso2": "TD",
    "iso3": "TCD",
    "admin_name": "Ville de N’Djaména",
    "capital": "primary",
    "population": 1092066,
    "id": 1148708596
  },
  {
    "city": "Hanchuan",
    "city_ascii": "Hanchuan",
    "lat": 30.652,
    "lng": 113.8274,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hubei",
    "capital": "",
    "population": 1092700,
    "id": 1156149248
  },
  {
    "city": "Gongzhuling",
    "city_ascii": "Gongzhuling",
    "lat": 43.5036,
    "lng": 124.8088,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Jilin",
    "capital": "",
    "population": 1092692,
    "id": 1156696810
  },
  {
    "city": "Copenhagen",
    "city_ascii": "Copenhagen",
    "lat": 55.6786,
    "lng": 12.5635,
    "country": "Denmark",
    "iso2": "DK",
    "iso3": "DNK",
    "admin_name": "Hovedstaden",
    "capital": "primary",
    "population": 1085000,
    "id": 1208763942
  },
  {
    "city": "Irbid",
    "city_ascii": "Irbid",
    "lat": 32.5556,
    "lng": 35.85,
    "country": "Jordan",
    "iso2": "JO",
    "iso3": "JOR",
    "admin_name": "Irbid",
    "capital": "admin",
    "population": 1088100,
    "id": 1400221568
  },
  {
    "city": "Hāora",
    "city_ascii": "Haora",
    "lat": 22.59,
    "lng": 88.31,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "West Bengal",
    "capital": "",
    "population": 1077075,
    "id": 1356986516
  },
  {
    "city": "Cologne",
    "city_ascii": "Cologne",
    "lat": 50.9422,
    "lng": 6.9578,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 1085664,
    "id": 1276015998
  },
  {
    "city": "Krasnoyarsk",
    "city_ascii": "Krasnoyarsk",
    "lat": 56.0167,
    "lng": 92.8667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Krasnoyarskiy Kray",
    "capital": "admin",
    "population": 1083865,
    "id": 1643544975
  },
  {
    "city": "Yicheng",
    "city_ascii": "Yicheng",
    "lat": 31.3697,
    "lng": 119.8239,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Jiangsu",
    "capital": "minor",
    "population": 1082929,
    "id": 1156292419
  },
  {
    "city": "Mizhou",
    "city_ascii": "Mizhou",
    "lat": 36,
    "lng": 119.4167,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Shandong",
    "capital": "minor",
    "population": 1081960,
    "id": 1156935002
  },
  {
    "city": "Nashville",
    "city_ascii": "Nashville",
    "lat": 36.1715,
    "lng": -86.7843,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "admin",
    "population": 1081903,
    "id": 1840036155
  },
  {
    "city": "Yerevan",
    "city_ascii": "Yerevan",
    "lat": 40.1814,
    "lng": 44.5144,
    "country": "Armenia",
    "iso2": "AM",
    "iso3": "ARM",
    "admin_name": "Yerevan",
    "capital": "primary",
    "population": 1075800,
    "id": 1051074169
  },
  {
    "city": "Rānchi",
    "city_ascii": "Ranchi",
    "lat": 23.3556,
    "lng": 85.3347,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Jharkhand",
    "capital": "admin",
    "population": 1073440,
    "id": 1356110588
  },
  {
    "city": "Nur-Sultan",
    "city_ascii": "Nur-Sultan",
    "lat": 51.1333,
    "lng": 71.4333,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Astana",
    "capital": "primary",
    "population": 1078362,
    "id": 1398516045
  },
  {
    "city": "Nouakchott",
    "city_ascii": "Nouakchott",
    "lat": 18.0783,
    "lng": -15.9744,
    "country": "Mauritania",
    "iso2": "MR",
    "iso3": "MRT",
    "admin_name": "",
    "capital": "primary",
    "population": 1077169,
    "id": 1478414984
  },
  {
    "city": "Vereeniging",
    "city_ascii": "Vereeniging",
    "lat": -26.6736,
    "lng": 27.9319,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "Gauteng",
    "capital": "",
    "population": 1074000,
    "id": 1710271883
  },
  {
    "city": "Richmond",
    "city_ascii": "Richmond",
    "lat": 37.5295,
    "lng": -77.4756,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "admin",
    "population": 1075798,
    "id": 1840001698
  },
  {
    "city": "São Luís",
    "city_ascii": "Sao Luis",
    "lat": -2.53,
    "lng": -44.3028,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Maranhão",
    "capital": "admin",
    "population": 1073893,
    "id": 1076819308
  },
  {
    "city": "San Pedro Sula",
    "city_ascii": "San Pedro Sula",
    "lat": 15.5,
    "lng": -88.0333,
    "country": "Honduras",
    "iso2": "HN",
    "iso3": "HND",
    "admin_name": "Cortés",
    "capital": "admin",
    "population": 1073824,
    "id": 1340568412
  },
  {
    "city": "Taixing",
    "city_ascii": "Taixing",
    "lat": 32.1724,
    "lng": 120.0142,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Jiangsu",
    "capital": "minor",
    "population": 1073921,
    "id": 1156331200
  },
  {
    "city": "Memphis",
    "city_ascii": "Memphis",
    "lat": 35.1046,
    "lng": -89.9773,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 1066967,
    "id": 1840015457
  },
  {
    "city": "Goyang",
    "city_ascii": "Goyang",
    "lat": 37.6564,
    "lng": 126.835,
    "country": "Korea, South",
    "iso2": "KR",
    "iso3": "KOR",
    "admin_name": "Gyeonggi",
    "capital": "minor",
    "population": 1061929,
    "id": 1410511255
  },
  {
    "city": "Bezwāda",
    "city_ascii": "Bezwada",
    "lat": 16.5167,
    "lng": 80.6167,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Andhra Pradesh",
    "capital": "",
    "population": 1048240,
    "id": 1356807575
  },
  {
    "city": "Edmonton",
    "city_ascii": "Edmonton",
    "lat": 53.5344,
    "lng": -113.4903,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Alberta",
    "capital": "",
    "population": 1062643,
    "id": 1124290735
  },
  {
    "city": "Xishan",
    "city_ascii": "Xishan",
    "lat": 27.6609,
    "lng": 113.4946,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hunan",
    "capital": "minor",
    "population": 1060000,
    "id": 1156155575
  },
  {
    "city": "Barquisimeto",
    "city_ascii": "Barquisimeto",
    "lat": 10.0678,
    "lng": -69.3467,
    "country": "Venezuela",
    "iso2": "VE",
    "iso3": "VEN",
    "admin_name": "Lara",
    "capital": "admin",
    "population": 1059092,
    "id": 1862860739
  },
  {
    "city": "Sendai",
    "city_ascii": "Sendai",
    "lat": 38.2683,
    "lng": 140.8694,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Miyagi",
    "capital": "admin",
    "population": 1058070,
    "id": 1392457903
  },
  {
    "city": "Voronezh",
    "city_ascii": "Voronezh",
    "lat": 51.6717,
    "lng": 39.2106,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Voronezhskaya Oblast’",
    "capital": "admin",
    "population": 1047549,
    "id": 1643811064
  },
  {
    "city": "Perm",
    "city_ascii": "Perm",
    "lat": 58.0139,
    "lng": 56.2489,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Permskiy Kray",
    "capital": "admin",
    "population": 1048005,
    "id": 1643294442
  },
  {
    "city": "Changwon",
    "city_ascii": "Changwon",
    "lat": 35.2281,
    "lng": 128.6811,
    "country": "Korea, South",
    "iso2": "KR",
    "iso3": "KOR",
    "admin_name": "Gyeongnam",
    "capital": "admin",
    "population": 1046054,
    "id": 1410592618
  },
  {
    "city": "Zhongwei",
    "city_ascii": "Zhongwei",
    "lat": 37.5139,
    "lng": 105.1884,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Ningxia",
    "capital": "minor",
    "population": 1046000,
    "id": 1156956616
  },
  {
    "city": "Shouguang",
    "city_ascii": "Shouguang",
    "lat": 36.8833,
    "lng": 118.7333,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Shandong",
    "capital": "",
    "population": 1039205,
    "id": 1156787809
  },
  {
    "city": "Bogor",
    "city_ascii": "Bogor",
    "lat": -6.6,
    "lng": 106.8,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Jawa Barat",
    "capital": "",
    "population": 1030720,
    "id": 1360771925
  },
  {
    "city": "Raleigh",
    "city_ascii": "Raleigh",
    "lat": 35.8325,
    "lng": -78.6435,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "admin",
    "population": 1038738,
    "id": 1840014497
  },
  {
    "city": "Cartagena",
    "city_ascii": "Cartagena",
    "lat": 10.4236,
    "lng": -75.5253,
    "country": "Colombia",
    "iso2": "CO",
    "iso3": "COL",
    "admin_name": "Bolívar",
    "capital": "admin",
    "population": 1036412,
    "id": 1170886102
  },
  {
    "city": "Chandīgarh",
    "city_ascii": "Chandigarh",
    "lat": 30.7353,
    "lng": 76.7911,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Chandīgarh",
    "capital": "admin",
    "population": 1026459,
    "id": 1356739179
  },
  {
    "city": "Bishkek",
    "city_ascii": "Bishkek",
    "lat": 42.8667,
    "lng": 74.5667,
    "country": "Kyrgyzstan",
    "iso2": "KG",
    "iso3": "KGZ",
    "admin_name": "Bishkek",
    "capital": "primary",
    "population": 1027200,
    "id": 1417191971
  },
  {
    "city": "Amsterdam",
    "city_ascii": "Amsterdam",
    "lat": 52.35,
    "lng": 4.9166,
    "country": "Netherlands",
    "iso2": "NL",
    "iso3": "NLD",
    "admin_name": "Noord-Holland",
    "capital": "primary",
    "population": 1031000,
    "id": 1528355309
  },
  {
    "city": "Matola",
    "city_ascii": "Matola",
    "lat": -25.9667,
    "lng": 32.4667,
    "country": "Mozambique",
    "iso2": "MZ",
    "iso3": "MOZ",
    "admin_name": "Maputo",
    "capital": "",
    "population": 1032197,
    "id": 1508768577
  },
  {
    "city": "Ogbomoso",
    "city_ascii": "Ogbomoso",
    "lat": 8.1333,
    "lng": 4.25,
    "country": "Nigeria",
    "iso2": "NG",
    "iso3": "NGA",
    "admin_name": "Oyo",
    "capital": "minor",
    "population": 1032000,
    "id": 1566373448
  },
  {
    "city": "Ashgabat",
    "city_ascii": "Ashgabat",
    "lat": 37.95,
    "lng": 58.3833,
    "country": "Turkmenistan",
    "iso2": "TM",
    "iso3": "TKM",
    "admin_name": "Ahal",
    "capital": "primary",
    "population": 1031992,
    "id": 1795049992
  },
  {
    "city": "E’zhou",
    "city_ascii": "E'zhou",
    "lat": 30.3972,
    "lng": 114.8842,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hubei",
    "capital": "minor",
    "population": 1031000,
    "id": 1156414637
  },
  {
    "city": "Maceió",
    "city_ascii": "Maceio",
    "lat": -9.6658,
    "lng": -35.735,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Alagoas",
    "capital": "admin",
    "population": 1029129,
    "id": 1076440479
  },
  {
    "city": "Niamey",
    "city_ascii": "Niamey",
    "lat": 13.5086,
    "lng": 2.1111,
    "country": "Niger",
    "iso2": "NE",
    "iso3": "NER",
    "admin_name": "Niamey",
    "capital": "primary",
    "population": 1026848,
    "id": 1562932886
  },
  {
    "city": "Managua",
    "city_ascii": "Managua",
    "lat": 12.15,
    "lng": -86.2667,
    "country": "Nicaragua",
    "iso2": "NI",
    "iso3": "NIC",
    "admin_name": "Managua",
    "capital": "primary",
    "population": 1028808,
    "id": 1558296252
  },
  {
    "city": "Patam",
    "city_ascii": "Patam",
    "lat": 1.0678,
    "lng": 104.0167,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Kepulauan Riau",
    "capital": "",
    "population": 1029808,
    "id": 1360893799
  },
  {
    "city": "Tekirdağ",
    "city_ascii": "Tekirdag",
    "lat": 40.9833,
    "lng": 27.5167,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Tekirdağ",
    "capital": "admin",
    "population": 1029927,
    "id": 1792519395
  },
  {
    "city": "Linhai",
    "city_ascii": "Linhai",
    "lat": 28.8523,
    "lng": 121.1409,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Zhejiang",
    "capital": "",
    "population": 1028813,
    "id": 1156451663
  },
  {
    "city": "Shubrā al Khaymah",
    "city_ascii": "Shubra al Khaymah",
    "lat": 30.1286,
    "lng": 31.2422,
    "country": "Egypt",
    "iso2": "EG",
    "iso3": "EGY",
    "admin_name": "Al Qalyūbīyah",
    "capital": "",
    "population": 1025569,
    "id": 1818909612
  },
  {
    "city": "Monrovia",
    "city_ascii": "Monrovia",
    "lat": 6.3106,
    "lng": -10.8047,
    "country": "Liberia",
    "iso2": "LR",
    "iso3": "LBR",
    "admin_name": "Montserrado",
    "capital": "primary",
    "population": 1021762,
    "id": 1430477826
  },
  {
    "city": "Wafangdian",
    "city_ascii": "Wafangdian",
    "lat": 39.6271,
    "lng": 121.9972,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Liaoning",
    "capital": "",
    "population": 1024876,
    "id": 1156476417
  },
  {
    "city": "Zhongxiang",
    "city_ascii": "Zhongxiang",
    "lat": 31.169,
    "lng": 112.5853,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hubei",
    "capital": "",
    "population": 1022514,
    "id": 1156631720
  },
  {
    "city": "Shymkent",
    "city_ascii": "Shymkent",
    "lat": 42.3,
    "lng": 69.6,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Ongtüstik Qazaqstan",
    "capital": "admin",
    "population": 1018974,
    "id": 1398791401
  },
  {
    "city": "New Orleans",
    "city_ascii": "New Orleans",
    "lat": 30.0687,
    "lng": -89.9288,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 1020886,
    "id": 1840001839
  },
  {
    "city": "Volgograd",
    "city_ascii": "Volgograd",
    "lat": 48.7,
    "lng": 44.4833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Volgogradskaya Oblast’",
    "capital": "admin",
    "population": 1015586,
    "id": 1643577201
  },
  {
    "city": "Port-au-Prince",
    "city_ascii": "Port-au-Prince",
    "lat": 18.5425,
    "lng": -72.3386,
    "country": "Haiti",
    "iso2": "HT",
    "iso3": "HTI",
    "admin_name": "Ouest",
    "capital": "primary",
    "population": 987310,
    "id": 1332401940
  },
  {
    "city": "Mysore",
    "city_ascii": "Mysore",
    "lat": 12.3086,
    "lng": 76.6531,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Karnātaka",
    "capital": "",
    "population": 1014227,
    "id": 1356779323
  },
  {
    "city": "Odesa",
    "city_ascii": "Odesa",
    "lat": 46.4775,
    "lng": 30.7326,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Odes’ka Oblast’",
    "capital": "admin",
    "population": 1010783,
    "id": 1804178102
  },
  {
    "city": "Kathmandu",
    "city_ascii": "Kathmandu",
    "lat": 27.7167,
    "lng": 85.3667,
    "country": "Nepal",
    "iso2": "NP",
    "iso3": "NPL",
    "admin_name": "Bāgmatī",
    "capital": "primary",
    "population": 975453,
    "id": 1524589448
  },
  {
    "city": "Raipur",
    "city_ascii": "Raipur",
    "lat": 21.2379,
    "lng": 81.6337,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Chhattīsgarh",
    "capital": "admin",
    "population": 1010087,
    "id": 1356374983
  },
  {
    "city": "Nice",
    "city_ascii": "Nice",
    "lat": 43.7034,
    "lng": 7.2663,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Provence-Alpes-Côte d’Azur",
    "capital": "minor",
    "population": 1006402,
    "id": 1250774553
  },
  {
    "city": "Arequipa",
    "city_ascii": "Arequipa",
    "lat": -16.3988,
    "lng": -71.5369,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "Arequipa",
    "capital": "admin",
    "population": 1008290,
    "id": 1604723954
  },
  {
    "city": "Port Harcourt",
    "city_ascii": "Port Harcourt",
    "lat": 4.75,
    "lng": 7,
    "country": "Nigeria",
    "iso2": "NG",
    "iso3": "NGA",
    "admin_name": "Rivers",
    "capital": "admin",
    "population": 1005904,
    "id": 1566070649
  },
  {
    "city": "Rotterdam",
    "city_ascii": "Rotterdam",
    "lat": 51.92,
    "lng": 4.48,
    "country": "Netherlands",
    "iso2": "NL",
    "iso3": "NLD",
    "admin_name": "Zuid-Holland",
    "capital": "minor",
    "population": 1005000,
    "id": 1528892850
  },
  {
    "city": "Louisville",
    "city_ascii": "Louisville",
    "lat": 38.1663,
    "lng": -85.6485,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kentucky",
    "capital": "",
    "population": 1005654,
    "id": 1840030815
  },
  {
    "city": "Zaoyang",
    "city_ascii": "Zaoyang",
    "lat": 32.1287,
    "lng": 112.7581,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hubei",
    "capital": "",
    "population": 1004741,
    "id": 1156197076
  },
  {
    "city": "Shuizhai",
    "city_ascii": "Shuizhai",
    "lat": 33.4433,
    "lng": 114.8994,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Henan",
    "capital": "",
    "population": 1003698,
    "id": 1156462734
  },
  {
    "city": "Kota",
    "city_ascii": "Kota",
    "lat": 25.18,
    "lng": 75.83,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Rājasthān",
    "capital": "",
    "population": 1001694,
    "id": 1356820310
  },
  {
    "city": "Abu Dhabi",
    "city_ascii": "Abu Dhabi",
    "lat": 24.4781,
    "lng": 54.3686,
    "country": "United Arab Emirates",
    "iso2": "AE",
    "iso3": "ARE",
    "admin_name": "Abū Z̧aby",
    "capital": "primary",
    "population": 1000000,
    "id": 1784176710
  },
  {
    "city": "Stockholm",
    "city_ascii": "Stockholm",
    "lat": 59.3294,
    "lng": 18.0686,
    "country": "Sweden",
    "iso2": "SE",
    "iso3": "SWE",
    "admin_name": "Stockholm",
    "capital": "primary",
    "population": 972647,
    "id": 1752425602
  },
  {
    "city": "Ottawa",
    "city_ascii": "Ottawa",
    "lat": 45.4247,
    "lng": -75.695,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 989567,
    "id": 1124399363
  },
  {
    "city": "Asmara",
    "city_ascii": "Asmara",
    "lat": 15.3333,
    "lng": 38.9167,
    "country": "Eritrea",
    "iso2": "ER",
    "iso3": "ERI",
    "admin_name": "Ma’ākel",
    "capital": "primary",
    "population": 963000,
    "id": 1232791236
  },
  {
    "city": "Freetown",
    "city_ascii": "Freetown",
    "lat": 8.4833,
    "lng": -13.2331,
    "country": "Sierra Leone",
    "iso2": "SL",
    "iso3": "SLE",
    "admin_name": "Western Area",
    "capital": "primary",
    "population": 951000,
    "id": 1694085071
  },
  {
    "city": "Jerusalem",
    "city_ascii": "Jerusalem",
    "lat": 31.7833,
    "lng": 35.2167,
    "country": "Israel",
    "iso2": "IL",
    "iso3": "ISR",
    "admin_name": "Southern",
    "capital": "primary",
    "population": 919438,
    "id": 1376261644
  },
  {
    "city": "Panama City",
    "city_ascii": "Panama City",
    "lat": 9,
    "lng": -79.5,
    "country": "Panama",
    "iso2": "PA",
    "iso3": "PAN",
    "admin_name": "Panamá",
    "capital": "primary",
    "population": 880691,
    "id": 1591672475
  },
  {
    "city": "Lomé",
    "city_ascii": "Lome",
    "lat": 6.1319,
    "lng": 1.2228,
    "country": "Togo",
    "iso2": "TG",
    "iso3": "TGO",
    "admin_name": "Maritime",
    "capital": "primary",
    "population": 837437,
    "id": 1768409132
  },
  {
    "city": "Libreville",
    "city_ascii": "Libreville",
    "lat": 0.3901,
    "lng": 9.4544,
    "country": "Gabon",
    "iso2": "GA",
    "iso3": "GAB",
    "admin_name": "Estuaire",
    "capital": "primary",
    "population": 797003,
    "id": 1266952885
  },
  {
    "city": "Zagreb",
    "city_ascii": "Zagreb",
    "lat": 45.8,
    "lng": 15.95,
    "country": "Croatia",
    "iso2": "HR",
    "iso3": "HRV",
    "admin_name": "Zagreb, Grad",
    "capital": "primary",
    "population": 790017,
    "id": 1191233290
  },
  {
    "city": "Dushanbe",
    "city_ascii": "Dushanbe",
    "lat": 38.5731,
    "lng": 68.7864,
    "country": "Tajikistan",
    "iso2": "TJ",
    "iso3": "TJK",
    "admin_name": "Dushanbe",
    "capital": "primary",
    "population": 780000,
    "id": 1762930616
  },
  {
    "city": "Lilongwe",
    "city_ascii": "Lilongwe",
    "lat": -13.9833,
    "lng": 33.7833,
    "country": "Malawi",
    "iso2": "MW",
    "iso3": "MWI",
    "admin_name": "Lilongwe",
    "capital": "primary",
    "population": 781538,
    "id": 1454688499
  },
  {
    "city": "Cotonou",
    "city_ascii": "Cotonou",
    "lat": 6.4,
    "lng": 2.52,
    "country": "Benin",
    "iso2": "BJ",
    "iso3": "BEN",
    "admin_name": "Littoral",
    "capital": "primary",
    "population": 762000,
    "id": 1204955174
  },
  {
    "city": "Vientiane",
    "city_ascii": "Vientiane",
    "lat": 17.9667,
    "lng": 102.6,
    "country": "Laos",
    "iso2": "LA",
    "iso3": "LAO",
    "admin_name": "Viangchan",
    "capital": "primary",
    "population": 760000,
    "id": 1418732714
  },
  {
    "city": "Colombo",
    "city_ascii": "Colombo",
    "lat": 6.9167,
    "lng": 79.8333,
    "country": "Sri Lanka",
    "iso2": "LK",
    "iso3": "LKA",
    "admin_name": "Western",
    "capital": "primary",
    "population": 752993,
    "id": 1144251314
  },
  {
    "city": "Kigali",
    "city_ascii": "Kigali",
    "lat": -1.9536,
    "lng": 30.0606,
    "country": "Rwanda",
    "iso2": "RW",
    "iso3": "RWA",
    "admin_name": "Kigali",
    "capital": "primary",
    "population": 745261,
    "id": 1646923541
  },
  {
    "city": "Pretoria",
    "city_ascii": "Pretoria",
    "lat": -25.7464,
    "lng": 28.1881,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "Gauteng",
    "capital": "primary",
    "population": 741651,
    "id": 1710176249
  },
  {
    "city": "Bangui",
    "city_ascii": "Bangui",
    "lat": 4.3732,
    "lng": 18.5628,
    "country": "Central African Republic",
    "iso2": "CF",
    "iso3": "CAF",
    "admin_name": "Bangui",
    "capital": "primary",
    "population": 734350,
    "id": 1140080881
  },
  {
    "city": "Winnipeg",
    "city_ascii": "Winnipeg",
    "lat": 49.8844,
    "lng": -97.1464,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Manitoba",
    "capital": "",
    "population": 705244,
    "id": 1124224963
  },
  {
    "city": "Quebec City",
    "city_ascii": "Quebec City",
    "lat": 46.8139,
    "lng": -71.2081,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 705103,
    "id": 1124823933
  },
  {
    "city": "Riga",
    "city_ascii": "Riga",
    "lat": 56.9475,
    "lng": 24.1069,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Rīga",
    "capital": "primary",
    "population": 698529,
    "id": 1428586192
  },
  {
    "city": "Oslo",
    "city_ascii": "Oslo",
    "lat": 59.9111,
    "lng": 10.7528,
    "country": "Norway",
    "iso2": "NO",
    "iso3": "NOR",
    "admin_name": "Oslo",
    "capital": "primary",
    "population": 693494,
    "id": 1578324706
  },
  {
    "city": "Chisinau",
    "city_ascii": "Chisinau",
    "lat": 47.0056,
    "lng": 28.8575,
    "country": "Moldova",
    "iso2": "MD",
    "iso3": "MDA",
    "admin_name": "Chişinău",
    "capital": "primary",
    "population": 685900,
    "id": 1498011437
  },
  {
    "city": "Athens",
    "city_ascii": "Athens",
    "lat": 37.9794,
    "lng": 23.7161,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Attikí",
    "capital": "primary",
    "population": 664046,
    "id": 1300715560
  },
  {
    "city": "Bujumbura",
    "city_ascii": "Bujumbura",
    "lat": -3.3825,
    "lng": 29.3611,
    "country": "Burundi",
    "iso2": "BI",
    "iso3": "BDI",
    "admin_name": "Bujumbura Mairie",
    "capital": "primary",
    "population": 658859,
    "id": 1108101689
  },
  {
    "city": "Helsinki",
    "city_ascii": "Helsinki",
    "lat": 60.1756,
    "lng": 24.9342,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Uusimaa",
    "capital": "primary",
    "population": 642045,
    "id": 1246177997
  },
  {
    "city": "Skopje",
    "city_ascii": "Skopje",
    "lat": 41.9833,
    "lng": 21.4333,
    "country": "Macedonia",
    "iso2": "MK",
    "iso3": "MKD",
    "admin_name": "Skopje",
    "capital": "primary",
    "population": 640000,
    "id": 1807600615
  },
  {
    "city": "Kuwait City",
    "city_ascii": "Kuwait City",
    "lat": 29.375,
    "lng": 47.98,
    "country": "Kuwait",
    "iso2": "KW",
    "iso3": "KWT",
    "admin_name": "Al ‘Āşimah",
    "capital": "primary",
    "population": 637411,
    "id": 1414102075
  },
  {
    "city": "Kingston",
    "city_ascii": "Kingston",
    "lat": 17.9714,
    "lng": -76.7931,
    "country": "Jamaica",
    "iso2": "JM",
    "iso3": "JAM",
    "admin_name": "Kingston",
    "capital": "primary",
    "population": 580000,
    "id": 1388709177
  },
  {
    "city": "Vilnius",
    "city_ascii": "Vilnius",
    "lat": 54.6833,
    "lng": 25.2833,
    "country": "Lithuania",
    "iso2": "LT",
    "iso3": "LTU",
    "admin_name": "Vilniaus Miestas",
    "capital": "primary",
    "population": 574147,
    "id": 1440887149
  },
  {
    "city": "San Salvador",
    "city_ascii": "San Salvador",
    "lat": 13.6989,
    "lng": -89.1914,
    "country": "El Salvador",
    "iso2": "SV",
    "iso3": "SLV",
    "admin_name": "San Salvador",
    "capital": "primary",
    "population": 567698,
    "id": 1222647454
  },
  {
    "city": "Djibouti",
    "city_ascii": "Djibouti",
    "lat": 11.595,
    "lng": 43.1481,
    "country": "Djibouti",
    "iso2": "DJ",
    "iso3": "DJI",
    "admin_name": "Djibouti",
    "capital": "primary",
    "population": 562000,
    "id": 1262028958
  },
  {
    "city": "Lisbon",
    "city_ascii": "Lisbon",
    "lat": 38.7452,
    "lng": -9.1604,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Lisboa",
    "capital": "primary",
    "population": 506654,
    "id": 1620619017
  },
  {
    "city": "Kitchener",
    "city_ascii": "Kitchener",
    "lat": 43.4186,
    "lng": -80.4728,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 470015,
    "id": 1124158530
  },
  {
    "city": "Tallinn",
    "city_ascii": "Tallinn",
    "lat": 59.4372,
    "lng": 24.745,
    "country": "Estonia",
    "iso2": "EE",
    "iso3": "EST",
    "admin_name": "Harjumaa",
    "capital": "primary",
    "population": 434562,
    "id": 1233260021
  },
  {
    "city": "Cape Town",
    "city_ascii": "Cape Town",
    "lat": -33.925,
    "lng": 18.425,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "Western Cape",
    "capital": "primary",
    "population": 433688,
    "id": 1710680650
  },
  {
    "city": "Bratislava",
    "city_ascii": "Bratislava",
    "lat": 48.1447,
    "lng": 17.1128,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Bratislavský",
    "capital": "primary",
    "population": 429564,
    "id": 1703195001
  },
  {
    "city": "Tirana",
    "city_ascii": "Tirana",
    "lat": 41.33,
    "lng": 19.82,
    "country": "Albania",
    "iso2": "AL",
    "iso3": "ALB",
    "admin_name": "Tiranë",
    "capital": "primary",
    "population": 418495,
    "id": 1008162156
  },
  {
    "city": "Canberra",
    "city_ascii": "Canberra",
    "lat": -35.2931,
    "lng": 149.1269,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Australian Capital Territory",
    "capital": "primary",
    "population": 426704,
    "id": 1036142029
  },
  {
    "city": "Wellington",
    "city_ascii": "Wellington",
    "lat": -41.2889,
    "lng": 174.7772,
    "country": "New Zealand",
    "iso2": "NZ",
    "iso3": "NZL",
    "admin_name": "Wellington",
    "capital": "primary",
    "population": 418500,
    "id": 1554772152
  },
  {
    "city": "Beirut",
    "city_ascii": "Beirut",
    "lat": 33.8869,
    "lng": 35.5131,
    "country": "Lebanon",
    "iso2": "LB",
    "iso3": "LBN",
    "admin_name": "Beyrouth",
    "capital": "primary",
    "population": 361366,
    "id": 1422847713
  },
  {
    "city": "Dodoma",
    "city_ascii": "Dodoma",
    "lat": -6.25,
    "lng": 35.75,
    "country": "Tanzania",
    "iso2": "TZ",
    "iso3": "TZA",
    "admin_name": "Dodoma",
    "capital": "primary",
    "population": 410956,
    "id": 1834288497
  },
  {
    "city": "Halifax",
    "city_ascii": "Halifax",
    "lat": 44.6475,
    "lng": -63.5906,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Nova Scotia",
    "capital": "",
    "population": 403131,
    "id": 1124130981
  },
  {
    "city": "Bissau",
    "city_ascii": "Bissau",
    "lat": 11.8592,
    "lng": -15.5956,
    "country": "Guinea-Bissau",
    "iso2": "GW",
    "iso3": "GNB",
    "admin_name": "Bissau",
    "capital": "primary",
    "population": 395954,
    "id": 1624168850
  },
  {
    "city": "Juba",
    "city_ascii": "Juba",
    "lat": 4.85,
    "lng": 31.6,
    "country": "South Sudan",
    "iso2": "SS",
    "iso3": "SSD",
    "admin_name": "Central Equatoria",
    "capital": "primary",
    "population": 372410,
    "id": 1728444337
  },
  {
    "city": "Port Moresby",
    "city_ascii": "Port Moresby",
    "lat": -9.4789,
    "lng": 147.1494,
    "country": "Papua New Guinea",
    "iso2": "PG",
    "iso3": "PNG",
    "admin_name": "National Capital",
    "capital": "primary",
    "population": 364125,
    "id": 1598685395
  },
  {
    "city": "Yamoussoukro",
    "city_ascii": "Yamoussoukro",
    "lat": 6.8161,
    "lng": -5.2742,
    "country": "Côte D’Ivoire",
    "iso2": "CI",
    "iso3": "CIV",
    "admin_name": "Yamoussoukro",
    "capital": "primary",
    "population": 355573,
    "id": 1384683557
  },
  {
    "city": "Victoria",
    "city_ascii": "Victoria",
    "lat": 48.4283,
    "lng": -123.3647,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "British Columbia",
    "capital": "",
    "population": 335696,
    "id": 1124147219
  },
  {
    "city": "Maseru",
    "city_ascii": "Maseru",
    "lat": -29.31,
    "lng": 27.48,
    "country": "Lesotho",
    "iso2": "LS",
    "iso3": "LSO",
    "admin_name": "Maseru",
    "capital": "primary",
    "population": 330790,
    "id": 1426977668
  },
  {
    "city": "Nicosia",
    "city_ascii": "Nicosia",
    "lat": 35.1725,
    "lng": 33.365,
    "country": "Cyprus",
    "iso2": "CY",
    "iso3": "CYP",
    "admin_name": "Lefkosía",
    "capital": "primary",
    "population": 330000,
    "id": 1196944155
  },
  {
    "city": "Windhoek",
    "city_ascii": "Windhoek",
    "lat": -22.57,
    "lng": 17.0836,
    "country": "Namibia",
    "iso2": "NA",
    "iso3": "NAM",
    "admin_name": "Khomas",
    "capital": "primary",
    "population": 322500,
    "id": 1516802003
  },
  {
    "city": "Porto-Novo",
    "city_ascii": "Porto-Novo",
    "lat": 6.4833,
    "lng": 2.6167,
    "country": "Benin",
    "iso2": "BJ",
    "iso3": "BEN",
    "admin_name": "Ouémé",
    "capital": "primary",
    "population": 300000,
    "id": 1204172060
  },
  {
    "city": "Sucre",
    "city_ascii": "Sucre",
    "lat": -19.0431,
    "lng": -65.2592,
    "country": "Bolivia",
    "iso2": "BO",
    "iso3": "BOL",
    "admin_name": "Chuquisaca",
    "capital": "primary",
    "population": 300000,
    "id": 1068823873
  },
  {
    "city": "San José",
    "city_ascii": "San Jose",
    "lat": 9.9333,
    "lng": -84.0833,
    "country": "Costa Rica",
    "iso2": "CR",
    "iso3": "CRI",
    "admin_name": "San José",
    "capital": "primary",
    "population": 288054,
    "id": 1188749877
  },
  {
    "city": "Ljubljana",
    "city_ascii": "Ljubljana",
    "lat": 46.05,
    "lng": 14.5167,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Ljubljana",
    "capital": "primary",
    "population": 284355,
    "id": 1705917455
  },
  {
    "city": "Sarajevo",
    "city_ascii": "Sarajevo",
    "lat": 43.8667,
    "lng": 18.4167,
    "country": "Bosnia And Herzegovina",
    "iso2": "BA",
    "iso3": "BIH",
    "admin_name": "Bosnia and Herzegovina, Federation of",
    "capital": "primary",
    "population": 275524,
    "id": 1070966777
  },
  {
    "city": "Nassau",
    "city_ascii": "Nassau",
    "lat": 25.0667,
    "lng": -77.3333,
    "country": "Bahamas, The",
    "iso2": "BS",
    "iso3": "BHS",
    "admin_name": "",
    "capital": "primary",
    "population": 274400,
    "id": 1044318561
  },
  {
    "city": "Bloemfontein",
    "city_ascii": "Bloemfontein",
    "lat": -29.1,
    "lng": 26.2167,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "Free State",
    "capital": "primary",
    "population": 256185,
    "id": 1710495933
  },
  {
    "city": "Gaborone",
    "city_ascii": "Gaborone",
    "lat": -24.6569,
    "lng": 25.9086,
    "country": "Botswana",
    "iso2": "BW",
    "iso3": "BWA",
    "admin_name": "Gaborone",
    "capital": "primary",
    "population": 231626,
    "id": 1072756768
  },
  {
    "city": "Paramaribo",
    "city_ascii": "Paramaribo",
    "lat": 5.8667,
    "lng": -55.1667,
    "country": "Suriname",
    "iso2": "SR",
    "iso3": "SUR",
    "admin_name": "Paramaribo",
    "capital": "primary",
    "population": 223757,
    "id": 1740518660
  },
  {
    "city": "Dili",
    "city_ascii": "Dili",
    "lat": -8.5586,
    "lng": 125.5736,
    "country": "Timor-Leste",
    "iso2": "TL",
    "iso3": "TLS",
    "admin_name": "Díli",
    "capital": "primary",
    "population": 222323,
    "id": 1626308942
  },
  {
    "city": "Pristina",
    "city_ascii": "Pristina",
    "lat": 42.6667,
    "lng": 21.1667,
    "country": "Kosovo",
    "iso2": "XK",
    "iso3": "XKS",
    "admin_name": "Prishtinë",
    "capital": "primary",
    "population": 204725,
    "id": 1901760068
  },
  {
    "city": "Georgetown",
    "city_ascii": "Georgetown",
    "lat": 6.7833,
    "lng": -58.1667,
    "country": "Guyana",
    "iso2": "GY",
    "iso3": "GUY",
    "admin_name": "Demerara-Mahaica",
    "capital": "primary",
    "population": 200500,
    "id": 1328160906
  },
  {
    "city": "Malabo",
    "city_ascii": "Malabo",
    "lat": 3.7521,
    "lng": 8.7737,
    "country": "Equatorial Guinea",
    "iso2": "GQ",
    "iso3": "GNQ",
    "admin_name": "Bioko Norte",
    "capital": "primary",
    "population": 187302,
    "id": 1226861333
  },
  {
    "city": "Brussels",
    "city_ascii": "Brussels",
    "lat": 50.8467,
    "lng": 4.3517,
    "country": "Belgium",
    "iso2": "BE",
    "iso3": "BEL",
    "admin_name": "Brussels-Capital Region",
    "capital": "primary",
    "population": 185103,
    "id": 1056469830
  },
  {
    "city": "Male",
    "city_ascii": "Male",
    "lat": 4.175,
    "lng": 73.5083,
    "country": "Maldives",
    "iso2": "MV",
    "iso3": "MDV",
    "admin_name": "Maale",
    "capital": "primary",
    "population": 133019,
    "id": 1462441685
  },
  {
    "city": "Podgorica",
    "city_ascii": "Podgorica",
    "lat": 42.4397,
    "lng": 19.2661,
    "country": "Montenegro",
    "iso2": "ME",
    "iso3": "MNE",
    "admin_name": "Podgorica",
    "capital": "primary",
    "population": 174515,
    "id": 1499454516
  },
  {
    "city": "Manama",
    "city_ascii": "Manama",
    "lat": 26.2167,
    "lng": 50.5831,
    "country": "Bahrain",
    "iso2": "BH",
    "iso3": "BHR",
    "admin_name": "Al ‘Āşimah",
    "capital": "primary",
    "population": 157474,
    "id": 1048989486
  },
  {
    "city": "Port Louis",
    "city_ascii": "Port Louis",
    "lat": -20.1667,
    "lng": 57.5,
    "country": "Mauritius",
    "iso2": "MU",
    "iso3": "MUS",
    "admin_name": "Port Louis",
    "capital": "primary",
    "population": 149194,
    "id": 1480131261
  },
  {
    "city": "New Delhi",
    "city_ascii": "New Delhi",
    "lat": 28.7,
    "lng": 77.2,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Delhi",
    "capital": "primary",
    "population": 142004,
    "id": 1356215164
  },
  {
    "city": "Bern",
    "city_ascii": "Bern",
    "lat": 46.948,
    "lng": 7.4474,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Bern",
    "capital": "primary",
    "population": 133798,
    "id": 1756374318
  },
  {
    "city": "Reykjavík",
    "city_ascii": "Reykjavik",
    "lat": 64.1475,
    "lng": -21.935,
    "country": "Iceland",
    "iso2": "IS",
    "iso3": "ISL",
    "admin_name": "",
    "capital": "primary",
    "population": 128793,
    "id": 1352327190
  },
  {
    "city": "Praia",
    "city_ascii": "Praia",
    "lat": 14.9177,
    "lng": -23.5092,
    "country": "Cabo Verde",
    "iso2": "CV",
    "iso3": "CPV",
    "admin_name": "Praia",
    "capital": "primary",
    "population": 127832,
    "id": 1132398770
  },
  {
    "city": "Luxembourg",
    "city_ascii": "Luxembourg",
    "lat": 49.6106,
    "lng": 6.1328,
    "country": "Luxembourg",
    "iso2": "LU",
    "iso3": "LUX",
    "admin_name": "Luxembourg",
    "capital": "primary",
    "population": 122273,
    "id": 1442262731
  },
  {
    "city": "Sri Jayewardenepura Kotte",
    "city_ascii": "Sri Jayewardenepura Kotte",
    "lat": 6.9,
    "lng": 79.9164,
    "country": "Sri Lanka",
    "iso2": "LK",
    "iso3": "LKA",
    "admin_name": "Western",
    "capital": "primary",
    "population": 115826,
    "id": 1144239330
  },
  {
    "city": "Bridgetown",
    "city_ascii": "Bridgetown",
    "lat": 13.0975,
    "lng": -59.6167,
    "country": "Barbados",
    "iso2": "BB",
    "iso3": "BRB",
    "admin_name": "Saint Michael",
    "capital": "primary",
    "population": 110000,
    "id": 1052274244
  },
  {
    "city": "Moroni",
    "city_ascii": "Moroni",
    "lat": -11.7036,
    "lng": 43.2536,
    "country": "Comoros",
    "iso2": "KM",
    "iso3": "COM",
    "admin_name": "Grande Comore",
    "capital": "primary",
    "population": 111329,
    "id": 1174793581
  },
  {
    "city": "Thimphu",
    "city_ascii": "Thimphu",
    "lat": 27.4833,
    "lng": 89.6333,
    "country": "Bhutan",
    "iso2": "BT",
    "iso3": "BTN",
    "admin_name": "Thimphu",
    "capital": "primary",
    "population": 104000,
    "id": 1064010361
  },
  {
    "city": "Mbabane",
    "city_ascii": "Mbabane",
    "lat": -26.3167,
    "lng": 31.1333,
    "country": "Swaziland",
    "iso2": "SZ",
    "iso3": "SWZ",
    "admin_name": "Hhohho",
    "capital": "primary",
    "population": 90138,
    "id": 1748811945
  },
  {
    "city": "Honiara",
    "city_ascii": "Honiara",
    "lat": -9.4333,
    "lng": 159.95,
    "country": "Solomon Islands",
    "iso2": "SB",
    "iso3": "SLB",
    "admin_name": "Honiara",
    "capital": "primary",
    "population": 84520,
    "id": 1090737486
  },
  {
    "city": "Suva",
    "city_ascii": "Suva",
    "lat": -18.1333,
    "lng": 178.4333,
    "country": "Fiji",
    "iso2": "FJ",
    "iso3": "FJI",
    "admin_name": "Rewa",
    "capital": "primary",
    "population": 88271,
    "id": 1242615095
  },
  {
    "city": "Castries",
    "city_ascii": "Castries",
    "lat": 14.0167,
    "lng": -60.9833,
    "country": "Saint Lucia",
    "iso2": "LC",
    "iso3": "LCA",
    "admin_name": "Castries",
    "capital": "primary",
    "population": 70000,
    "id": 1662922505
  },
  {
    "city": "São Tomé",
    "city_ascii": "Sao Tome",
    "lat": 0.3333,
    "lng": 6.7333,
    "country": "Sao Tome And Principe",
    "iso2": "ST",
    "iso3": "STP",
    "admin_name": "São Tomé",
    "capital": "primary",
    "population": 56166,
    "id": 1678301324
  },
  {
    "city": "Port-Vila",
    "city_ascii": "Port-Vila",
    "lat": -17.75,
    "lng": 168.3,
    "country": "Vanuatu",
    "iso2": "VU",
    "iso3": "VUT",
    "admin_name": "Shefa",
    "capital": "primary",
    "population": 51437,
    "id": 1548805075
  },
  {
    "city": "Bandar Seri Begawan",
    "city_ascii": "Bandar Seri Begawan",
    "lat": 4.9167,
    "lng": 114.9167,
    "country": "Brunei",
    "iso2": "BN",
    "iso3": "BRN",
    "admin_name": "Brunei and Muara",
    "capital": "primary",
    "population": 50000,
    "id": 1096465895
  },
  {
    "city": "Monaco",
    "city_ascii": "Monaco",
    "lat": 43.7396,
    "lng": 7.4069,
    "country": "Monaco",
    "iso2": "MC",
    "iso3": "MCO",
    "admin_name": "",
    "capital": "primary",
    "population": 36371,
    "id": 1492854256
  },
  {
    "city": "Gitega",
    "city_ascii": "Gitega",
    "lat": -3.4264,
    "lng": 29.9306,
    "country": "Burundi",
    "iso2": "BI",
    "iso3": "BDI",
    "admin_name": "Gitega",
    "capital": "primary",
    "population": 41944,
    "id": 1108778000
  },
  {
    "city": "Port of Spain",
    "city_ascii": "Port of Spain",
    "lat": 10.6667,
    "lng": -61.5167,
    "country": "Trinidad And Tobago",
    "iso2": "TT",
    "iso3": "TTO",
    "admin_name": "Port of Spain",
    "capital": "primary",
    "population": 37074,
    "id": 1780670676
  },
  {
    "city": "Apia",
    "city_ascii": "Apia",
    "lat": -13.8333,
    "lng": -171.8333,
    "country": "Samoa",
    "iso2": "WS",
    "iso3": "WSM",
    "admin_name": "Tuamasaga",
    "capital": "primary",
    "population": 37708,
    "id": 1882489296
  },
  {
    "city": "Vaduz",
    "city_ascii": "Vaduz",
    "lat": 47.1415,
    "lng": 9.5215,
    "country": "Liechtenstein",
    "iso2": "LI",
    "iso3": "LIE",
    "admin_name": "Vaduz",
    "capital": "primary",
    "population": 36281,
    "id": 1438317747
  },
  {
    "city": "Banjul",
    "city_ascii": "Banjul",
    "lat": 13.4531,
    "lng": -16.5775,
    "country": "Gambia, The",
    "iso2": "GM",
    "iso3": "GMB",
    "admin_name": "Banjul",
    "capital": "primary",
    "population": 31356,
    "id": 1270723713
  },
  {
    "city": "Tarawa",
    "city_ascii": "Tarawa",
    "lat": 1.3382,
    "lng": 173.0176,
    "country": "Kiribati",
    "iso2": "KI",
    "iso3": "KIR",
    "admin_name": "",
    "capital": "primary",
    "population": 28802,
    "id": 1296152641
  },
  {
    "city": "Victoria",
    "city_ascii": "Victoria",
    "lat": -4.6236,
    "lng": 55.4544,
    "country": "Seychelles",
    "iso2": "SC",
    "iso3": "SYC",
    "admin_name": "English River",
    "capital": "primary",
    "population": 26450,
    "id": 1690193579
  },
  {
    "city": "Majuro",
    "city_ascii": "Majuro",
    "lat": 7.1167,
    "lng": 171.3667,
    "country": "Marshall Islands",
    "iso2": "MH",
    "iso3": "MHL",
    "admin_name": "Majuro",
    "capital": "primary",
    "population": 25400,
    "id": 1584000000
  },
  {
    "city": "Kingstown",
    "city_ascii": "Kingstown",
    "lat": 13.1667,
    "lng": -61.2333,
    "country": "Saint Vincent And The Grenadines",
    "iso2": "VC",
    "iso3": "VCT",
    "admin_name": "Saint George",
    "capital": "primary",
    "population": 25418,
    "id": 1670376659
  },
  {
    "city": "Nuku‘alofa",
    "city_ascii": "Nuku`alofa",
    "lat": -21.1347,
    "lng": -175.2083,
    "country": "Tonga",
    "iso2": "TO",
    "iso3": "TON",
    "admin_name": "Tongatapu",
    "capital": "primary",
    "population": 24571,
    "id": 1776737461
  },
  {
    "city": "Saint John’s",
    "city_ascii": "Saint John's",
    "lat": 17.1211,
    "lng": -61.8447,
    "country": "Antigua And Barbuda",
    "iso2": "AG",
    "iso3": "ATG",
    "admin_name": "Saint John",
    "capital": "primary",
    "population": 21926,
    "id": 1028912067
  },
  {
    "city": "Andorra la Vella",
    "city_ascii": "Andorra la Vella",
    "lat": 42.5,
    "lng": 1.5,
    "country": "Andorra",
    "iso2": "AD",
    "iso3": "AND",
    "admin_name": "Andorra la Vella",
    "capital": "primary",
    "population": 22151,
    "id": 1020828846
  },
  {
    "city": "Belmopan",
    "city_ascii": "Belmopan",
    "lat": 17.25,
    "lng": -88.7675,
    "country": "Belize",
    "iso2": "BZ",
    "iso3": "BLZ",
    "admin_name": "Cayo",
    "capital": "primary",
    "population": 17222,
    "id": 1084586375
  },
  {
    "city": "Roseau",
    "city_ascii": "Roseau",
    "lat": 15.3,
    "lng": -61.3833,
    "country": "Dominica",
    "iso2": "DM",
    "iso3": "DMA",
    "admin_name": "Saint George",
    "capital": "primary",
    "population": 16582,
    "id": 1212060440
  },
  {
    "city": "Basseterre",
    "city_ascii": "Basseterre",
    "lat": 17.2983,
    "lng": -62.7342,
    "country": "Saint Kitts And Nevis",
    "iso2": "KN",
    "iso3": "KNA",
    "admin_name": "Saint George Basseterre",
    "capital": "primary",
    "population": 13220,
    "id": 1659198919
  },
  {
    "city": "Pago Pago",
    "city_ascii": "Pago Pago",
    "lat": -14.274,
    "lng": -170.7046,
    "country": "American Samoa",
    "iso2": "AS",
    "iso3": "ASM",
    "admin_name": "",
    "capital": "primary",
    "population": 12576,
    "id": 1016976740
  },
  {
    "city": "Valletta",
    "city_ascii": "Valletta",
    "lat": 35.8978,
    "lng": 14.5125,
    "country": "Malta",
    "iso2": "MT",
    "iso3": "MLT",
    "admin_name": "Valletta",
    "capital": "primary",
    "population": 6444,
    "id": 1470574399
  },
  {
    "city": "Palikir",
    "city_ascii": "Palikir",
    "lat": 6.9178,
    "lng": 158.185,
    "country": "Micronesia, Federated States Of",
    "iso2": "FM",
    "iso3": "FSM",
    "admin_name": "Pohnpei",
    "capital": "primary",
    "population": 6227,
    "id": 1583008885
  },
  {
    "city": "Funafuti",
    "city_ascii": "Funafuti",
    "lat": -8.5167,
    "lng": 179.2167,
    "country": "Tuvalu",
    "iso2": "TV",
    "iso3": "TUV",
    "admin_name": "Funafuti",
    "capital": "primary",
    "population": 6025,
    "id": 1798251391
  },
  {
    "city": "Lobamba",
    "city_ascii": "Lobamba",
    "lat": -26.4167,
    "lng": 31.1667,
    "country": "Swaziland",
    "iso2": "SZ",
    "iso3": "SWZ",
    "admin_name": "Hhohho",
    "capital": "primary",
    "population": 5800,
    "id": 1748963140
  },
  {
    "city": "Saint George’s",
    "city_ascii": "Saint George's",
    "lat": 12.0444,
    "lng": -61.7417,
    "country": "Grenada",
    "iso2": "GD",
    "iso3": "GRD",
    "admin_name": "Saint George",
    "capital": "primary",
    "population": 4315,
    "id": 1308891766
  },
  {
    "city": "San Marino",
    "city_ascii": "San Marino",
    "lat": 43.932,
    "lng": 12.4484,
    "country": "San Marino",
    "iso2": "SM",
    "iso3": "SMR",
    "admin_name": "San Marino Città",
    "capital": "primary",
    "population": 4040,
    "id": 1674741947
  },
  {
    "city": "Al Quds",
    "city_ascii": "Al Quds",
    "lat": 31.7764,
    "lng": 35.2269,
    "country": "West Bank",
    "iso2": "XW",
    "iso3": "XWB",
    "admin_name": "",
    "capital": "primary",
    "population": null,
    "id": 1934000006
  },
  {
    "city": "Capitol Hill",
    "city_ascii": "Capitol Hill",
    "lat": 15.2137,
    "lng": 145.7546,
    "country": "Northern Mariana Islands",
    "iso2": "MP",
    "iso3": "MNP",
    "admin_name": "",
    "capital": "primary",
    "population": 2500,
    "id": 1580556972
  },
  {
    "city": "Hagåtña",
    "city_ascii": "Hagta",
    "lat": 13.4745,
    "lng": 144.7504,
    "country": "Guam",
    "iso2": "GU",
    "iso3": "GUM",
    "admin_name": "",
    "capital": "primary",
    "population": 1051,
    "id": 1316937540
  },
  {
    "city": "Ngerulmud",
    "city_ascii": "Ngerulmud",
    "lat": 7.5006,
    "lng": 134.6242,
    "country": "Palau",
    "iso2": "PW",
    "iso3": "PLW",
    "admin_name": "Melekeok",
    "capital": "primary",
    "population": null,
    "id": 1585525081
  },
  {
    "city": "Bareilly",
    "city_ascii": "Bareilly",
    "lat": 28.364,
    "lng": 79.415,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Uttar Pradesh",
    "capital": "",
    "population": 1000000,
    "id": 1356698199
  },
  {
    "city": "Quảng Hà",
    "city_ascii": "Quang Ha",
    "lat": 16.06,
    "lng": 108.25,
    "country": "Vietnam",
    "iso2": "VN",
    "iso3": "VNM",
    "admin_name": "Quảng Nam",
    "capital": "",
    "population": 1000000,
    "id": 1704966442
  },
  {
    "city": "Kitaku",
    "city_ascii": "Kitaku",
    "lat": 33.8704,
    "lng": 130.82,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Fukuoka",
    "capital": "",
    "population": 997536,
    "id": 1392129541
  },
  {
    "city": "Jodhpur",
    "city_ascii": "Jodhpur",
    "lat": 26.2918,
    "lng": 73.0168,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Rājasthān",
    "capital": "",
    "population": 995000,
    "id": 1356727084
  },
  {
    "city": "Xingcheng",
    "city_ascii": "Xingcheng",
    "lat": 24.1681,
    "lng": 115.6669,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Guangdong",
    "capital": "minor",
    "population": 993000,
    "id": 1156913008
  },
  {
    "city": "Dongtai",
    "city_ascii": "Dongtai",
    "lat": 32.8534,
    "lng": 120.3037,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Jiangsu",
    "capital": "minor",
    "population": 990306,
    "id": 1156536785
  },
  {
    "city": "Joinvile",
    "city_ascii": "Joinvile",
    "lat": -26.32,
    "lng": -48.8399,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Santa Catarina",
    "capital": "",
    "population": 988000,
    "id": 1076050637
  },
  {
    "city": "Yingcheng",
    "city_ascii": "Yingcheng",
    "lat": 24.1876,
    "lng": 113.4042,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Guangdong",
    "capital": "minor",
    "population": 986400,
    "id": 1156012356
  },
  {
    "city": "Dnipro",
    "city_ascii": "Dnipro",
    "lat": 48.45,
    "lng": 34.9833,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Dnipropetrovs’ka Oblast’",
    "capital": "admin",
    "population": 983836,
    "id": 1804152933
  },
  {
    "city": "Glasgow",
    "city_ascii": "Glasgow",
    "lat": 55.8609,
    "lng": -4.2514,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Glasgow City",
    "capital": "",
    "population": 985290,
    "id": 1826836462
  },
  {
    "city": "Chiba",
    "city_ascii": "Chiba",
    "lat": 35.6,
    "lng": 140.1,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Chiba",
    "capital": "admin",
    "population": 980203,
    "id": 1392107144
  },
  {
    "city": "Danyang",
    "city_ascii": "Danyang",
    "lat": 31.9948,
    "lng": 119.575,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Jiangsu",
    "capital": "",
    "population": 980662,
    "id": 1156578273
  },
  {
    "city": "Natal",
    "city_ascii": "Natal",
    "lat": -6.9838,
    "lng": -60.2699,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Amazonas",
    "capital": "",
    "population": 980588,
    "id": 1076336975
  },
  {
    "city": "Naples",
    "city_ascii": "Naples",
    "lat": 40.8333,
    "lng": 14.25,
    "country": "Italy",
    "iso2": "IT",
    "iso3": "ITA",
    "admin_name": "Campania",
    "capital": "admin",
    "population": 972212,
    "id": 1380646673
  },
  {
    "city": "Zhaodong",
    "city_ascii": "Zhaodong",
    "lat": 46.0635,
    "lng": 125.9773,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Heilongjiang",
    "capital": "minor",
    "population": 980000,
    "id": 1156601489
  },
  {
    "city": "Xibeijie",
    "city_ascii": "Xibeijie",
    "lat": 39.737,
    "lng": 98.5049,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Gansu",
    "capital": "minor",
    "population": 977600,
    "id": 1156960560
  },
  {
    "city": "Kirkuk",
    "city_ascii": "Kirkuk",
    "lat": 35.4667,
    "lng": 44.4,
    "country": "Iraq",
    "iso2": "IQ",
    "iso3": "IRQ",
    "admin_name": "Kirkūk",
    "capital": "admin",
    "population": 975000,
    "id": 1368344172
  },
  {
    "city": "Huilong",
    "city_ascii": "Huilong",
    "lat": 31.8131,
    "lng": 121.6574,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Jiangsu",
    "capital": "minor",
    "population": 972525,
    "id": 1156485081
  },
  {
    "city": "Tongjin",
    "city_ascii": "Tongjin",
    "lat": 37.2361,
    "lng": 127.2011,
    "country": "Korea, South",
    "iso2": "KR",
    "iso3": "KOR",
    "admin_name": "Gyeonggi",
    "capital": "",
    "population": 971327,
    "id": 1410160862
  },
  {
    "city": "Oklahoma City",
    "city_ascii": "Oklahoma City",
    "lat": 35.4676,
    "lng": -97.5136,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oklahoma",
    "capital": "admin",
    "population": 972943,
    "id": 1840020428
  },
  {
    "city": "Toulouse",
    "city_ascii": "Toulouse",
    "lat": 43.6045,
    "lng": 1.444,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Occitanie",
    "capital": "admin",
    "population": 968638,
    "id": 1250258110
  },
  {
    "city": "Denizli",
    "city_ascii": "Denizli",
    "lat": 37.7731,
    "lng": 29.0878,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Denizli",
    "capital": "admin",
    "population": 963464,
    "id": 1792161179
  },
  {
    "city": "Dispur",
    "city_ascii": "Dispur",
    "lat": 26.15,
    "lng": 91.77,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Assam",
    "capital": "admin",
    "population": 957352,
    "id": 1356960169
  },
  {
    "city": "Coimbatore",
    "city_ascii": "Coimbatore",
    "lat": 11,
    "lng": 76.9667,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Tamil Nādu",
    "capital": "",
    "population": 959823,
    "id": 1356999503
  },
  {
    "city": "Luocheng",
    "city_ascii": "Luocheng",
    "lat": 22.7645,
    "lng": 111.5745,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Guangdong",
    "capital": "minor",
    "population": 959006,
    "id": 1156138895
  },
  {
    "city": "Guwāhāti",
    "city_ascii": "Guwahati",
    "lat": 26.1667,
    "lng": 91.7667,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Assam",
    "capital": "",
    "population": 957352,
    "id": 1356593800
  },
  {
    "city": "Shima",
    "city_ascii": "Shima",
    "lat": 24.4475,
    "lng": 117.8118,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Fujian",
    "capital": "minor",
    "population": 956400,
    "id": 1156202451
  },
  {
    "city": "Sŏngnam",
    "city_ascii": "Songnam",
    "lat": 37.4386,
    "lng": 127.1378,
    "country": "Korea, South",
    "iso2": "KR",
    "iso3": "KOR",
    "admin_name": "Gyeonggi",
    "capital": "",
    "population": 948757,
    "id": 1410622472
  },
  {
    "city": "Solāpur",
    "city_ascii": "Solapur",
    "lat": 17.6833,
    "lng": 75.9167,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Mahārāshtra",
    "capital": "",
    "population": 951558,
    "id": 1356314385
  },
  {
    "city": "Tangier",
    "city_ascii": "Tangier",
    "lat": 35.7847,
    "lng": -5.8125,
    "country": "Morocco",
    "iso2": "MA",
    "iso3": "MAR",
    "admin_name": "Tanger-Tétouan-Al Hoceïma",
    "capital": "admin",
    "population": 947952,
    "id": 1504861504
  },
  {
    "city": "Anqiu",
    "city_ascii": "Anqiu",
    "lat": 36.3619,
    "lng": 119.1072,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Shandong",
    "capital": "",
    "population": 947723,
    "id": 1156592047
  },
  {
    "city": "Feicheng",
    "city_ascii": "Feicheng",
    "lat": 36.186,
    "lng": 116.772,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Shandong",
    "capital": "",
    "population": 946627,
    "id": 1156303834
  },
  {
    "city": "Taishan",
    "city_ascii": "Taishan",
    "lat": 22.2486,
    "lng": 112.785,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Guangdong",
    "capital": "",
    "population": 941095,
    "id": 1156105450
  },
  {
    "city": "Meishan",
    "city_ascii": "Meishan",
    "lat": 34.1736,
    "lng": 112.839,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Henan",
    "capital": "minor",
    "population": 936000,
    "id": 1156023073
  },
  {
    "city": "Kisangani",
    "city_ascii": "Kisangani",
    "lat": 0.5153,
    "lng": 25.1911,
    "country": "Congo (Kinshasa)",
    "iso2": "CD",
    "iso3": "COD",
    "admin_name": "Tshopo",
    "capital": "admin",
    "population": 935977,
    "id": 1180478201
  },
  {
    "city": "Khartoum North",
    "city_ascii": "Khartoum North",
    "lat": 15.6333,
    "lng": 32.6333,
    "country": "Sudan",
    "iso2": "SD",
    "iso3": "SDN",
    "admin_name": "Khartoum",
    "capital": "",
    "population": 936349,
    "id": 1729318628
  },
  {
    "city": "Aguascalientes",
    "city_ascii": "Aguascalientes",
    "lat": 21.876,
    "lng": -102.296,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Aguascalientes",
    "capital": "admin",
    "population": 934424,
    "id": 1484666646
  },
  {
    "city": "Marrakech",
    "city_ascii": "Marrakech",
    "lat": 31.6295,
    "lng": -7.9811,
    "country": "Morocco",
    "iso2": "MA",
    "iso3": "MAR",
    "admin_name": "Marrakech-Safi",
    "capital": "admin",
    "population": 928850,
    "id": 1504172585
  },
  {
    "city": "Nada",
    "city_ascii": "Nada",
    "lat": 19.5,
    "lng": 109.5833,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hainan",
    "capital": "minor",
    "population": 932356,
    "id": 1156911678
  },
  {
    "city": "Donetsk",
    "city_ascii": "Donetsk",
    "lat": 48.0089,
    "lng": 37.8042,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Donets’ka Oblast’",
    "capital": "admin",
    "population": 929063,
    "id": 1804963805
  },
  {
    "city": "Taihecun",
    "city_ascii": "Taihecun",
    "lat": 45.768,
    "lng": 131.0063,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Heilongjiang",
    "capital": "",
    "population": 930000,
    "id": 1156875484
  },
  {
    "city": "Wuchuan",
    "city_ascii": "Wuchuan",
    "lat": 21.4571,
    "lng": 110.7659,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Guangdong",
    "capital": "",
    "population": 927275,
    "id": 1156198103
  },
  {
    "city": "Trujillo",
    "city_ascii": "Trujillo",
    "lat": -8.1119,
    "lng": -79.0289,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "La Libertad",
    "capital": "admin",
    "population": 919899,
    "id": 1604613706
  },
  {
    "city": "Cebu City",
    "city_ascii": "Cebu City",
    "lat": 10.3,
    "lng": 123.9,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Cebu",
    "capital": "admin",
    "population": 922611,
    "id": 1608136151
  },
  {
    "city": "Taihe",
    "city_ascii": "Taihe",
    "lat": 30.8706,
    "lng": 105.3784,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Sichuan",
    "capital": "minor",
    "population": 924531,
    "id": 1156281983
  },
  {
    "city": "Olinda",
    "city_ascii": "Olinda",
    "lat": -8,
    "lng": -34.85,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Pernambuco",
    "capital": "",
    "population": 921840,
    "id": 1076784718
  },
  {
    "city": "Bridgeport",
    "city_ascii": "Bridgeport",
    "lat": 41.1918,
    "lng": -73.1953,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 924449,
    "id": 1840004836
  },
  {
    "city": "Trichinopoly",
    "city_ascii": "Trichinopoly",
    "lat": 10.8269,
    "lng": 78.6928,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Tamil Nādu",
    "capital": "",
    "population": 916857,
    "id": 1356884472
  },
  {
    "city": "Xin’an",
    "city_ascii": "Xin'an",
    "lat": 34.3662,
    "lng": 118.3369,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Jiangsu",
    "capital": "",
    "population": 920628,
    "id": 1156236053
  },
  {
    "city": "Padang",
    "city_ascii": "Padang",
    "lat": -0.9556,
    "lng": 100.3606,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Sumatera Barat",
    "capital": "admin",
    "population": 914970,
    "id": 1360900986
  },
  {
    "city": "Qingzhou",
    "city_ascii": "Qingzhou",
    "lat": 36.6967,
    "lng": 118.4797,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Shandong",
    "capital": "",
    "population": 914047,
    "id": 1156874864
  },
  {
    "city": "Buffalo",
    "city_ascii": "Buffalo",
    "lat": 42.9016,
    "lng": -78.8487,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 914341,
    "id": 1840000386
  },
  {
    "city": "Xinyi",
    "city_ascii": "Xinyi",
    "lat": 22.3559,
    "lng": 110.9369,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Guangdong",
    "capital": "",
    "population": 913708,
    "id": 1156936644
  },
  {
    "city": "Lichuan",
    "city_ascii": "Lichuan",
    "lat": 30.2965,
    "lng": 108.9378,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hubei",
    "capital": "",
    "population": 913700,
    "id": 1156302762
  },
  {
    "city": "Daye",
    "city_ascii": "Daye",
    "lat": 30.1003,
    "lng": 114.9699,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hubei",
    "capital": "",
    "population": 909724,
    "id": 1156255811
  },
  {
    "city": "Fort Worth",
    "city_ascii": "Fort Worth",
    "lat": 32.7811,
    "lng": -97.3473,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 909585,
    "id": 1840020696
  },
  {
    "city": "Zhuanghe",
    "city_ascii": "Zhuanghe",
    "lat": 39.6896,
    "lng": 122.9664,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Liaoning",
    "capital": "",
    "population": 905852,
    "id": 1156410076
  },
  {
    "city": "Hartford",
    "city_ascii": "Hartford",
    "lat": 41.7661,
    "lng": -72.6834,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 905152,
    "id": 1840004773
  },
  {
    "city": "Ad Dammām",
    "city_ascii": "Ad Dammam",
    "lat": 26.4333,
    "lng": 50.1,
    "country": "Saudi Arabia",
    "iso2": "SA",
    "iso3": "SAU",
    "admin_name": "Ash Sharqīyah",
    "capital": "admin",
    "population": 903312,
    "id": 1682452296
  },
  {
    "city": "Bucheon",
    "city_ascii": "Bucheon",
    "lat": 37.4989,
    "lng": 126.7831,
    "country": "Korea, South",
    "iso2": "KR",
    "iso3": "KOR",
    "admin_name": "Gyeonggi",
    "capital": "minor",
    "population": 867678,
    "id": 1410428222
  },
  {
    "city": "Lhasa",
    "city_ascii": "Lhasa",
    "lat": 29.65,
    "lng": 91.1,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Tibet",
    "capital": "admin",
    "population": 902500,
    "id": 1156369972
  },
  {
    "city": "Homs",
    "city_ascii": "Homs",
    "lat": 34.7333,
    "lng": 36.7167,
    "country": "Syria",
    "iso2": "SY",
    "iso3": "SYR",
    "admin_name": "Ḩimş",
    "capital": "admin",
    "population": 900492,
    "id": 1760013934
  },
  {
    "city": "Jiaozhou",
    "city_ascii": "Jiaozhou",
    "lat": 36.2481,
    "lng": 119.9625,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Shandong",
    "capital": "",
    "population": 900500,
    "id": 1156752602
  },
  {
    "city": "Mérida",
    "city_ascii": "Merida",
    "lat": 20.97,
    "lng": -89.62,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Yucatán",
    "capital": "admin",
    "population": 892363,
    "id": 1484396779
  },
  {
    "city": "Dengtalu",
    "city_ascii": "Dengtalu",
    "lat": 36.08,
    "lng": 114.35,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Henan",
    "capital": "minor",
    "population": 887000,
    "id": 1156202014
  },
  {
    "city": "Zaria",
    "city_ascii": "Zaria",
    "lat": 11.0667,
    "lng": 7.7,
    "country": "Nigeria",
    "iso2": "NG",
    "iso3": "NGA",
    "admin_name": "Kaduna",
    "capital": "minor",
    "population": 889000,
    "id": 1566287704
  },
  {
    "city": "Hubli",
    "city_ascii": "Hubli",
    "lat": 15.36,
    "lng": 75.125,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Karnātaka",
    "capital": "",
    "population": 890000,
    "id": 1356102365
  },
  {
    "city": "Abeokuta",
    "city_ascii": "Abeokuta",
    "lat": 7.15,
    "lng": 3.35,
    "country": "Nigeria",
    "iso2": "NG",
    "iso3": "NGA",
    "admin_name": "Ogun",
    "capital": "admin",
    "population": 888924,
    "id": 1566979497
  },
  {
    "city": "Tucson",
    "city_ascii": "Tucson",
    "lat": 32.1545,
    "lng": -110.8782,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 888486,
    "id": 1840022101
  },
  {
    "city": "Krasnodar",
    "city_ascii": "Krasnodar",
    "lat": 45.0333,
    "lng": 38.9833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Krasnodarskiy Kray",
    "capital": "admin",
    "population": 881476,
    "id": 1643438227
  },
  {
    "city": "Natal",
    "city_ascii": "Natal",
    "lat": -5.795,
    "lng": -35.2089,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Rio Grande do Norte",
    "capital": "admin",
    "population": 877662,
    "id": 1076697777
  },
  {
    "city": "Novo Hamburgo",
    "city_ascii": "Novo Hamburgo",
    "lat": -29.7096,
    "lng": -51.14,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Rio Grande do Sul",
    "capital": "",
    "population": 876990,
    "id": 1076221386
  },
  {
    "city": "Chihuahua",
    "city_ascii": "Chihuahua",
    "lat": 28.6353,
    "lng": -106.0889,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Chihuahua",
    "capital": "admin",
    "population": 878062,
    "id": 1484335603
  },
  {
    "city": "Klang",
    "city_ascii": "Klang",
    "lat": 3.0333,
    "lng": 101.45,
    "country": "Malaysia",
    "iso2": "MY",
    "iso3": "MYS",
    "admin_name": "Selangor",
    "capital": "",
    "population": 878000,
    "id": 1458509635
  },
  {
    "city": "Turin",
    "city_ascii": "Turin",
    "lat": 45.0667,
    "lng": 7.7,
    "country": "Italy",
    "iso2": "IT",
    "iso3": "ITA",
    "admin_name": "Piedmont",
    "capital": "admin",
    "population": 870952,
    "id": 1380244627
  },
  {
    "city": "Jos",
    "city_ascii": "Jos",
    "lat": 9.9333,
    "lng": 8.8833,
    "country": "Nigeria",
    "iso2": "NG",
    "iso3": "NGA",
    "admin_name": "Plateau",
    "capital": "admin",
    "population": 873943,
    "id": 1566773679
  },
  {
    "city": "Laiyang",
    "city_ascii": "Laiyang",
    "lat": 36.9758,
    "lng": 120.7136,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Shandong",
    "capital": "",
    "population": 874127,
    "id": 1156207330
  },
  {
    "city": "Jalandhar",
    "city_ascii": "Jalandhar",
    "lat": 31.3256,
    "lng": 75.5792,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Punjab",
    "capital": "",
    "population": 873725,
    "id": 1356494816
  },
  {
    "city": "Campo Grande",
    "city_ascii": "Campo Grande",
    "lat": -20.4686,
    "lng": -54.6222,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Mato Grosso do Sul",
    "capital": "admin",
    "population": 874210,
    "id": 1076660445
  },
  {
    "city": "Sale",
    "city_ascii": "Sale",
    "lat": 34.05,
    "lng": -6.8167,
    "country": "Morocco",
    "iso2": "MA",
    "iso3": "MAR",
    "admin_name": "Rabat-Salé-Kénitra",
    "capital": "",
    "population": 850403,
    "id": 1504989034
  },
  {
    "city": "Barinas",
    "city_ascii": "Barinas",
    "lat": 8.6333,
    "lng": -70.2167,
    "country": "Venezuela",
    "iso2": "VE",
    "iso3": "VEN",
    "admin_name": "Barinas",
    "capital": "admin",
    "population": 873962,
    "id": 1862647790
  },
  {
    "city": "Marseille",
    "city_ascii": "Marseille",
    "lat": 43.2964,
    "lng": 5.37,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Provence-Alpes-Côte d’Azur",
    "capital": "admin",
    "population": 870018,
    "id": 1250774071
  },
  {
    "city": "Kaifeng Chengguanzhen",
    "city_ascii": "Kaifeng Chengguanzhen",
    "lat": 34.85,
    "lng": 114.35,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Henan",
    "capital": "minor",
    "population": 872000,
    "id": 1156686574
  },
  {
    "city": "Eskişehir",
    "city_ascii": "Eskisehir",
    "lat": 39.7767,
    "lng": 30.5206,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Eskişehir",
    "capital": "admin",
    "population": 871187,
    "id": 1792933981
  },
  {
    "city": "Gaomi",
    "city_ascii": "Gaomi",
    "lat": 36.3833,
    "lng": 119.75,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Shandong",
    "capital": "",
    "population": 868715,
    "id": 1156673331
  },
  {
    "city": "Ipoh",
    "city_ascii": "Ipoh",
    "lat": 4.6,
    "lng": 101.07,
    "country": "Malaysia",
    "iso2": "MY",
    "iso3": "MYS",
    "admin_name": "Perak",
    "capital": "admin",
    "population": 866772,
    "id": 1458423505
  },
  {
    "city": "Hai’an",
    "city_ascii": "Hai'an",
    "lat": 32.532,
    "lng": 120.4604,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Jiangsu",
    "capital": "minor",
    "population": 866337,
    "id": 1156332302
  },
  {
    "city": "Liverpool",
    "city_ascii": "Liverpool",
    "lat": 53.4,
    "lng": -2.9833,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Liverpool",
    "capital": "",
    "population": 864122,
    "id": 1826558678
  },
  {
    "city": "Zamboanga City",
    "city_ascii": "Zamboanga City",
    "lat": 6.9167,
    "lng": 122.0833,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Zamboanga",
    "capital": "admin",
    "population": 861799,
    "id": 1608189351
  },
  {
    "city": "Oran",
    "city_ascii": "Oran",
    "lat": 35.6969,
    "lng": -0.6331,
    "country": "Algeria",
    "iso2": "DZ",
    "iso3": "DZA",
    "admin_name": "Oran",
    "capital": "admin",
    "population": 852000,
    "id": 1012126319
  },
  {
    "city": "Southampton",
    "city_ascii": "Southampton",
    "lat": 50.9,
    "lng": -1.4,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Southampton",
    "capital": "",
    "population": 855569,
    "id": 1826602892
  },
  {
    "city": "Weichanglu",
    "city_ascii": "Weichanglu",
    "lat": 37.1792,
    "lng": 119.9333,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Shandong",
    "capital": "minor",
    "population": 859424,
    "id": 1156194600
  },
  {
    "city": "Pekanbaru",
    "city_ascii": "Pekanbaru",
    "lat": 0.5333,
    "lng": 101.45,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Riau",
    "capital": "admin",
    "population": 855221,
    "id": 1360422679
  },
  {
    "city": "Portsmouth",
    "city_ascii": "Portsmouth",
    "lat": 50.8058,
    "lng": -1.0872,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Portsmouth",
    "capital": "",
    "population": 855679,
    "id": 1826964096
  },
  {
    "city": "Leping",
    "city_ascii": "Leping",
    "lat": 28.9632,
    "lng": 117.1203,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Jiangxi",
    "capital": "minor",
    "population": 852800,
    "id": 1156705082
  },
  {
    "city": "Erbil",
    "city_ascii": "Erbil",
    "lat": 36.1911,
    "lng": 44.0094,
    "country": "Iraq",
    "iso2": "IQ",
    "iso3": "IRQ",
    "admin_name": "Arbīl",
    "capital": "admin",
    "population": 852500,
    "id": 1368578192
  },
  {
    "city": "Kermānshāh",
    "city_ascii": "Kermanshah",
    "lat": 34.3167,
    "lng": 47.0686,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Kermānshāh",
    "capital": "admin",
    "population": 851405,
    "id": 1364535690
  },
  {
    "city": "Hailun",
    "city_ascii": "Hailun",
    "lat": 47.4585,
    "lng": 126.9632,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Heilongjiang",
    "capital": "minor",
    "population": 850000,
    "id": 1156804488
  },
  {
    "city": "Yangchun",
    "city_ascii": "Yangchun",
    "lat": 22.1667,
    "lng": 111.7833,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Guangdong",
    "capital": "",
    "population": 849504,
    "id": 1156787307
  },
  {
    "city": "Macheng",
    "city_ascii": "Macheng",
    "lat": 31.1817,
    "lng": 115.0189,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hubei",
    "capital": "",
    "population": 849090,
    "id": 1156949639
  },
  {
    "city": "Ilorin",
    "city_ascii": "Ilorin",
    "lat": 8.5,
    "lng": 4.55,
    "country": "Nigeria",
    "iso2": "NG",
    "iso3": "NGA",
    "admin_name": "Kwara",
    "capital": "admin",
    "population": 847582,
    "id": 1566480168
  },
  {
    "city": "Teresina",
    "city_ascii": "Teresina",
    "lat": -5.0949,
    "lng": -42.8042,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Piauí",
    "capital": "admin",
    "population": 847430,
    "id": 1076727667
  },
  {
    "city": "Omaha",
    "city_ascii": "Omaha",
    "lat": 41.2627,
    "lng": -96.0522,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Nebraska",
    "capital": "",
    "population": 847819,
    "id": 1840009315
  },
  {
    "city": "Yuci",
    "city_ascii": "Yuci",
    "lat": 37.6804,
    "lng": 112.73,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Shanxi",
    "capital": "",
    "population": 840000,
    "id": 1156177817
  },
  {
    "city": "Saratov",
    "city_ascii": "Saratov",
    "lat": 51.5333,
    "lng": 46,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Saratovskaya Oblast’",
    "capital": "admin",
    "population": 845300,
    "id": 1643556540
  },
  {
    "city": "Iguaçu",
    "city_ascii": "Iguacu",
    "lat": -22.74,
    "lng": -43.47,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Rio de Janeiro",
    "capital": "",
    "population": 844583,
    "id": 1076339714
  },
  {
    "city": "El Paso",
    "city_ascii": "El Paso",
    "lat": 31.8479,
    "lng": -106.4309,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 843386,
    "id": 1840023252
  },
  {
    "city": "Denpasar",
    "city_ascii": "Denpasar",
    "lat": -8.6667,
    "lng": 115.2167,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Bali",
    "capital": "admin",
    "population": 834881,
    "id": 1360848527
  },
  {
    "city": "Dehui",
    "city_ascii": "Dehui",
    "lat": 44.5323,
    "lng": 125.6965,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Jilin",
    "capital": "",
    "population": 839786,
    "id": 1156898134
  },
  {
    "city": "Naucalpan de Juárez",
    "city_ascii": "Naucalpan de Juarez",
    "lat": 19.4753,
    "lng": -99.2378,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "México",
    "capital": "minor",
    "population": 833779,
    "id": 1484850125
  },
  {
    "city": "Bhubaneshwar",
    "city_ascii": "Bhubaneshwar",
    "lat": 20.2644,
    "lng": 85.8281,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Odisha",
    "capital": "admin",
    "population": 837737,
    "id": 1356140143
  },
  {
    "city": "Tongchuan",
    "city_ascii": "Tongchuan",
    "lat": 34.9057,
    "lng": 108.9422,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Shaanxi",
    "capital": "minor",
    "population": 835600,
    "id": 1156849608
  },
  {
    "city": "Cheongju",
    "city_ascii": "Cheongju",
    "lat": 36.6372,
    "lng": 127.4897,
    "country": "Korea, South",
    "iso2": "KR",
    "iso3": "KOR",
    "admin_name": "Chungbuk",
    "capital": "admin",
    "population": 833276,
    "id": 1410964361
  },
  {
    "city": "Warri",
    "city_ascii": "Warri",
    "lat": 5.5167,
    "lng": 5.75,
    "country": "Nigeria",
    "iso2": "NG",
    "iso3": "NGA",
    "admin_name": "Delta",
    "capital": "minor",
    "population": 830106,
    "id": 1566325734
  },
  {
    "city": "Pointe-Noire",
    "city_ascii": "Pointe-Noire",
    "lat": -4.7889,
    "lng": 11.8653,
    "country": "Congo (Brazzaville)",
    "iso2": "CG",
    "iso3": "COG",
    "admin_name": "Pointe-Noire",
    "capital": "admin",
    "population": 829134,
    "id": 1178411563
  },
  {
    "city": "Rongjiawan",
    "city_ascii": "Rongjiawan",
    "lat": 29.3801,
    "lng": 113.1,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hunan",
    "capital": "minor",
    "population": 826000,
    "id": 1156795164
  },
  {
    "city": "San Luis Potosí",
    "city_ascii": "San Luis Potosi",
    "lat": 22.1511,
    "lng": -100.9761,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "San Luis Potosí",
    "capital": "admin",
    "population": 824229,
    "id": 1484583620
  },
  {
    "city": "Butterworth",
    "city_ascii": "Butterworth",
    "lat": 5.3942,
    "lng": 100.3664,
    "country": "Malaysia",
    "iso2": "MY",
    "iso3": "MYS",
    "admin_name": "Pulau Pinang",
    "capital": "",
    "population": 821652,
    "id": 1458469233
  },
  {
    "city": "Bhayandar",
    "city_ascii": "Bhayandar",
    "lat": 19.3,
    "lng": 72.85,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Mahārāshtra",
    "capital": "",
    "population": 814655,
    "id": 1356019118
  },
  {
    "city": "Renqiu",
    "city_ascii": "Renqiu",
    "lat": 38.7094,
    "lng": 116.1008,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hebei",
    "capital": "",
    "population": 822455,
    "id": 1156943939
  },
  {
    "city": "Toluca",
    "city_ascii": "Toluca",
    "lat": 19.2925,
    "lng": -99.6569,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "México",
    "capital": "admin",
    "population": 819561,
    "id": 1484124846
  },
  {
    "city": "Honolulu",
    "city_ascii": "Honolulu",
    "lat": 21.3294,
    "lng": -157.846,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Hawaii",
    "capital": "admin",
    "population": 820987,
    "id": 1840013305
  },
  {
    "city": "Querétaro",
    "city_ascii": "Queretaro",
    "lat": 20.5875,
    "lng": -100.3928,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Querétaro",
    "capital": "admin",
    "population": 804663,
    "id": 1484604775
  },
  {
    "city": "Xindi",
    "city_ascii": "Xindi",
    "lat": 29.8182,
    "lng": 113.4653,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hubei",
    "capital": "",
    "population": 819446,
    "id": 1156263165
  },
  {
    "city": "Wu’an",
    "city_ascii": "Wu'an",
    "lat": 36.6941,
    "lng": 114.1847,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hebei",
    "capital": "",
    "population": 819000,
    "id": 1156729960
  },
  {
    "city": "São Bernardo do Campo",
    "city_ascii": "Sao Bernardo do Campo",
    "lat": -23.6939,
    "lng": -46.565,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 816925,
    "id": 1076793227
  },
  {
    "city": "Hermosillo",
    "city_ascii": "Hermosillo",
    "lat": 29.0989,
    "lng": -110.9542,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Sonora",
    "capital": "admin",
    "population": 812229,
    "id": 1484015319
  },
  {
    "city": "Wutong",
    "city_ascii": "Wutong",
    "lat": 30.6326,
    "lng": 120.5474,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Zhejiang",
    "capital": "minor",
    "population": 815848,
    "id": 1156182309
  },
  {
    "city": "Taguig City",
    "city_ascii": "Taguig City",
    "lat": 14.5167,
    "lng": 121.05,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Taguig",
    "capital": "admin",
    "population": 804915,
    "id": 1608766695
  },
  {
    "city": "Saltillo",
    "city_ascii": "Saltillo",
    "lat": 25.4333,
    "lng": -101,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Coahuila de Zaragoza",
    "capital": "admin",
    "population": 807537,
    "id": 1484107245
  },
  {
    "city": "Gaoyou",
    "city_ascii": "Gaoyou",
    "lat": 32.7847,
    "lng": 119.4432,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Jiangsu",
    "capital": "minor",
    "population": 811800,
    "id": 1156235854
  },
  {
    "city": "Hejian",
    "city_ascii": "Hejian",
    "lat": 38.4451,
    "lng": 116.0897,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hebei",
    "capital": "",
    "population": 810306,
    "id": 1156405731
  },
  {
    "city": "Yiyang",
    "city_ascii": "Yiyang",
    "lat": 26.4103,
    "lng": 112.3913,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hunan",
    "capital": "minor",
    "population": 810447,
    "id": 1156142520
  },
  {
    "city": "Puxi",
    "city_ascii": "Puxi",
    "lat": 35.2125,
    "lng": 114.735,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Henan",
    "capital": "minor",
    "population": 809535,
    "id": 1156046618
  },
  {
    "city": "Zijinglu",
    "city_ascii": "Zijinglu",
    "lat": 34.7513,
    "lng": 112.9854,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Henan",
    "capital": "minor",
    "population": 807857,
    "id": 1156086381
  },
  {
    "city": "Xiashi",
    "city_ascii": "Xiashi",
    "lat": 30.5333,
    "lng": 120.6833,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Zhejiang",
    "capital": "minor",
    "population": 806966,
    "id": 1156080077
  },
  {
    "city": "Trabzon",
    "city_ascii": "Trabzon",
    "lat": 41.005,
    "lng": 39.7225,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Trabzon",
    "capital": "admin",
    "population": 807903,
    "id": 1792019202
  },
  {
    "city": "João Pessoa",
    "city_ascii": "Joao Pessoa",
    "lat": -7.0833,
    "lng": -34.8333,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Paraíba",
    "capital": "admin",
    "population": 801718,
    "id": 1076922014
  },
  {
    "city": "Dongyang",
    "city_ascii": "Dongyang",
    "lat": 29.2785,
    "lng": 120.2282,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Zhejiang",
    "capital": "minor",
    "population": 804398,
    "id": 1156259752
  },
  {
    "city": "McAllen",
    "city_ascii": "McAllen",
    "lat": 26.2273,
    "lng": -98.2471,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 804114,
    "id": 1840021024
  },
  {
    "city": "Valencia",
    "city_ascii": "Valencia",
    "lat": 39.4667,
    "lng": -0.375,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Valencia",
    "capital": "admin",
    "population": 794288,
    "id": 1724981666
  },
  {
    "city": "Qingping",
    "city_ascii": "Qingping",
    "lat": 34.538,
    "lng": 113.3796,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Henan",
    "capital": "minor",
    "population": 797200,
    "id": 1156033855
  },
  {
    "city": "Niigata",
    "city_ascii": "Niigata",
    "lat": 37.9161,
    "lng": 139.0364,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Niigata",
    "capital": "admin",
    "population": 796500,
    "id": 1392913753
  },
  {
    "city": "Hamamatsu",
    "city_ascii": "Hamamatsu",
    "lat": 34.7167,
    "lng": 137.7333,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Shizuoka",
    "capital": "",
    "population": 791770,
    "id": 1392174500
  },
  {
    "city": "Morelia",
    "city_ascii": "Morelia",
    "lat": 19.7683,
    "lng": -101.1894,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Michoacán de Ocampo",
    "capital": "",
    "population": 784776,
    "id": 1484669072
  },
  {
    "city": "Pasig City",
    "city_ascii": "Pasig City",
    "lat": 14.575,
    "lng": 121.0833,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Pasig",
    "capital": "admin",
    "population": 755300,
    "id": 1608278212
  },
  {
    "city": "Morādābād",
    "city_ascii": "Moradabad",
    "lat": 28.8418,
    "lng": 78.7568,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Uttar Pradesh",
    "capital": "",
    "population": 787000,
    "id": 1356269375
  },
  {
    "city": "Culiacán",
    "city_ascii": "Culiacan",
    "lat": 24.8069,
    "lng": -107.3939,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Sinaloa",
    "capital": "admin",
    "population": 785800,
    "id": 1484516810
  },
  {
    "city": "Xiangxiang",
    "city_ascii": "Xiangxiang",
    "lat": 27.7389,
    "lng": 112.5223,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hunan",
    "capital": "",
    "population": 787216,
    "id": 1156524101
  },
  {
    "city": "Malang",
    "city_ascii": "Malang",
    "lat": -7.98,
    "lng": 112.62,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Jawa Timur",
    "capital": "",
    "population": 780000,
    "id": 1360141408
  },
  {
    "city": "Xingyi",
    "city_ascii": "Xingyi",
    "lat": 25.091,
    "lng": 104.9011,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Guizhou",
    "capital": "",
    "population": 783120,
    "id": 1156255316
  },
  {
    "city": "Chaohucun",
    "city_ascii": "Chaohucun",
    "lat": 31.6783,
    "lng": 117.7353,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Anhui",
    "capital": "",
    "population": 782000,
    "id": 1156671495
  },
  {
    "city": "Fuyang",
    "city_ascii": "Fuyang",
    "lat": 30.0533,
    "lng": 119.9519,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Zhejiang",
    "capital": "minor",
    "population": 771000,
    "id": 1156340393
  },
  {
    "city": "Antipolo",
    "city_ascii": "Antipolo",
    "lat": 14.5842,
    "lng": 121.1763,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Rizal",
    "capital": "admin",
    "population": 776386,
    "id": 1608824345
  },
  {
    "city": "Nottingham",
    "city_ascii": "Nottingham",
    "lat": 52.95,
    "lng": -1.15,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Nottingham",
    "capital": "",
    "population": 768638,
    "id": 1826122597
  },
  {
    "city": "Cencheng",
    "city_ascii": "Cencheng",
    "lat": 22.9297,
    "lng": 111.0186,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Guangxi",
    "capital": "",
    "population": 772113,
    "id": 1156000460
  },
  {
    "city": "Kraków",
    "city_ascii": "Krakow",
    "lat": 50.0614,
    "lng": 19.9372,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Małopolskie",
    "capital": "admin",
    "population": 766739,
    "id": 1616172264
  },
  {
    "city": "Hempstead",
    "city_ascii": "Hempstead",
    "lat": 40.6629,
    "lng": -73.6089,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 768057,
    "id": 1840005293
  },
  {
    "city": "Erzurum",
    "city_ascii": "Erzurum",
    "lat": 39.9097,
    "lng": 41.2756,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Erzurum",
    "capital": "admin",
    "population": 767848,
    "id": 1792511957
  },
  {
    "city": "Al ‘Ayn",
    "city_ascii": "Al `Ayn",
    "lat": 24.2075,
    "lng": 55.7447,
    "country": "United Arab Emirates",
    "iso2": "AE",
    "iso3": "ARE",
    "admin_name": "Abū Z̧aby",
    "capital": "",
    "population": 766936,
    "id": 1784674685
  },
  {
    "city": "Songzi",
    "city_ascii": "Songzi",
    "lat": 30.1772,
    "lng": 111.7732,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hubei",
    "capital": "",
    "population": 765911,
    "id": 1156837705
  },
  {
    "city": "Laixi",
    "city_ascii": "Laixi",
    "lat": 36.8667,
    "lng": 120.5333,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Shandong",
    "capital": "",
    "population": 762900,
    "id": 1156973102
  },
  {
    "city": "Zhongba",
    "city_ascii": "Zhongba",
    "lat": 31.7761,
    "lng": 104.7406,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Sichuan",
    "capital": "minor",
    "population": 762140,
    "id": 1156515985
  },
  {
    "city": "Qingnian",
    "city_ascii": "Qingnian",
    "lat": 36.8494,
    "lng": 115.7061,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Shandong",
    "capital": "minor",
    "population": 760781,
    "id": 1156689964
  },
  {
    "city": "Albuquerque",
    "city_ascii": "Albuquerque",
    "lat": 35.1053,
    "lng": -106.6464,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Mexico",
    "capital": "",
    "population": 761195,
    "id": 1840019176
  },
  {
    "city": "Kaduna",
    "city_ascii": "Kaduna",
    "lat": 10.5231,
    "lng": 7.4403,
    "country": "Nigeria",
    "iso2": "NG",
    "iso3": "NGA",
    "admin_name": "Kaduna",
    "capital": "admin",
    "population": 760084,
    "id": 1566368729
  },
  {
    "city": "Tlalnepantla",
    "city_ascii": "Tlalnepantla",
    "lat": 19.5367,
    "lng": -99.1947,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "México",
    "capital": "minor",
    "population": 750224,
    "id": 1484861603
  },
  {
    "city": "Xinhualu",
    "city_ascii": "Xinhualu",
    "lat": 34.3962,
    "lng": 113.7249,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Henan",
    "capital": "minor",
    "population": 758079,
    "id": 1156544851
  },
  {
    "city": "Guangshui",
    "city_ascii": "Guangshui",
    "lat": 31.6189,
    "lng": 113.8229,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hubei",
    "capital": "",
    "population": 755910,
    "id": 1156267419
  },
  {
    "city": "Frankfurt",
    "city_ascii": "Frankfurt",
    "lat": 50.1136,
    "lng": 8.6797,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "minor",
    "population": 753056,
    "id": 1276054552
  },
  {
    "city": "Samarinda",
    "city_ascii": "Samarinda",
    "lat": -0.5022,
    "lng": 117.1536,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Kalimantan Timur",
    "capital": "admin",
    "population": 753370,
    "id": 1360684979
  },
  {
    "city": "Changhua",
    "city_ascii": "Changhua",
    "lat": 24.0667,
    "lng": 120.5333,
    "country": "Taiwan",
    "iso2": "TW",
    "iso3": "TWN",
    "admin_name": "Changhua",
    "capital": "admin",
    "population": 750000,
    "id": 1158439005
  },
  {
    "city": "Pietermaritzburg",
    "city_ascii": "Pietermaritzburg",
    "lat": -29.5833,
    "lng": 30.4167,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "KwaZulu-Natal",
    "capital": "admin",
    "population": 750845,
    "id": 1710690280
  },
  {
    "city": "Hŭngnam",
    "city_ascii": "Hungnam",
    "lat": 39.8231,
    "lng": 127.6232,
    "country": "Korea, North",
    "iso2": "KP",
    "iso3": "PRK",
    "admin_name": "Hamnam",
    "capital": "",
    "population": 751322,
    "id": 1408328889
  },
  {
    "city": "Kolhāpur",
    "city_ascii": "Kolhapur",
    "lat": 16.7,
    "lng": 74.2333,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Mahārāshtra",
    "capital": "",
    "population": 750000,
    "id": 1356110593
  },
  {
    "city": "Ciudad Guayana",
    "city_ascii": "Ciudad Guayana",
    "lat": 8.3596,
    "lng": -62.6517,
    "country": "Venezuela",
    "iso2": "VE",
    "iso3": "VEN",
    "admin_name": "Bolívar",
    "capital": "minor",
    "population": 751331,
    "id": 1862889027
  },
  {
    "city": "Cúcuta",
    "city_ascii": "Cucuta",
    "lat": 7.9075,
    "lng": -72.5047,
    "country": "Colombia",
    "iso2": "CO",
    "iso3": "COL",
    "admin_name": "Norte de Santander",
    "capital": "admin",
    "population": 750000,
    "id": 1170743755
  },
  {
    "city": "Licheng",
    "city_ascii": "Licheng",
    "lat": 31.4174,
    "lng": 119.4786,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Jiangsu",
    "capital": "minor",
    "population": 749522,
    "id": 1156933354
  },
  {
    "city": "Thiruvananthapuram",
    "city_ascii": "Thiruvananthapuram",
    "lat": 8.5,
    "lng": 76.8997,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Kerala",
    "capital": "admin",
    "population": 743691,
    "id": 1356024354
  },
  {
    "city": "Tyumen",
    "city_ascii": "Tyumen",
    "lat": 57.15,
    "lng": 65.5333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Tyumenskaya Oblast’",
    "capital": "admin",
    "population": 744554,
    "id": 1643040435
  },
  {
    "city": "San Cristóbal",
    "city_ascii": "San Cristobal",
    "lat": 7.7682,
    "lng": -72.2322,
    "country": "Venezuela",
    "iso2": "VE",
    "iso3": "VEN",
    "admin_name": "Táchira",
    "capital": "admin",
    "population": 743924,
    "id": 1862252264
  },
  {
    "city": "Zaporizhzhia",
    "city_ascii": "Zaporizhzhia",
    "lat": 47.8378,
    "lng": 35.1383,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Zaporiz’ka Oblast’",
    "capital": "admin",
    "population": 741717,
    "id": 1804644752
  },
  {
    "city": "Cancún",
    "city_ascii": "Cancun",
    "lat": 21.1606,
    "lng": -86.8475,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Quintana Roo",
    "capital": "minor",
    "population": 743626,
    "id": 1484010310
  },
  {
    "city": "Kumamoto",
    "city_ascii": "Kumamoto",
    "lat": 32.8031,
    "lng": 130.7078,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Kumamoto",
    "capital": "admin",
    "population": 739393,
    "id": 1392106283
  },
  {
    "city": "Chengguan",
    "city_ascii": "Chengguan",
    "lat": 35.4599,
    "lng": 113.7975,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Henan",
    "capital": "minor",
    "population": 740435,
    "id": 1156866870
  },
  {
    "city": "Nehe",
    "city_ascii": "Nehe",
    "lat": 48.48,
    "lng": 124.8738,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Heilongjiang",
    "capital": "",
    "population": 740000,
    "id": 1156609562
  },
  {
    "city": "Sokoto",
    "city_ascii": "Sokoto",
    "lat": 13.0622,
    "lng": 5.2339,
    "country": "Nigeria",
    "iso2": "NG",
    "iso3": "NGA",
    "admin_name": "Sokoto",
    "capital": "admin",
    "population": 732178,
    "id": 1566574518
  },
  {
    "city": "Birmingham",
    "city_ascii": "Birmingham",
    "lat": 33.5277,
    "lng": -86.7987,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 739573,
    "id": 1840006507
  },
  {
    "city": "Zunhua",
    "city_ascii": "Zunhua",
    "lat": 40.1881,
    "lng": 117.9593,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hebei",
    "capital": "minor",
    "population": 737011,
    "id": 1156756935
  },
  {
    "city": "Orūmīyeh",
    "city_ascii": "Orumiyeh",
    "lat": 37.55,
    "lng": 45.1,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Āz̄arbāyjān-e Gharbī",
    "capital": "admin",
    "population": 736224,
    "id": 1364158221
  },
  {
    "city": "Oyo",
    "city_ascii": "Oyo",
    "lat": 7.8333,
    "lng": 3.9333,
    "country": "Nigeria",
    "iso2": "NG",
    "iso3": "NGA",
    "admin_name": "Oyo",
    "capital": "minor",
    "population": 736072,
    "id": 1566930579
  },
  {
    "city": "Wugang",
    "city_ascii": "Wugang",
    "lat": 26.7345,
    "lng": 110.6293,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hunan",
    "capital": "",
    "population": 734870,
    "id": 1156089788
  },
  {
    "city": "Shuangqiao",
    "city_ascii": "Shuangqiao",
    "lat": 35.0833,
    "lng": 112.5833,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Henan",
    "capital": "minor",
    "population": 733000,
    "id": 1156968311
  },
  {
    "city": "Rennes",
    "city_ascii": "Rennes",
    "lat": 48.1147,
    "lng": -1.6794,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Bretagne",
    "capital": "admin",
    "population": 727357,
    "id": 1250363682
  },
  {
    "city": "Sizhan",
    "city_ascii": "Sizhan",
    "lat": 39.0099,
    "lng": 106.3694,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Ningxia",
    "capital": "",
    "population": 730400,
    "id": 1156450911
  },
  {
    "city": "Langzhong",
    "city_ascii": "Langzhong",
    "lat": 31.5833,
    "lng": 105.972,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Sichuan",
    "capital": "minor",
    "population": 728935,
    "id": 1156930148
  },
  {
    "city": "Bristol",
    "city_ascii": "Bristol",
    "lat": 51.45,
    "lng": -2.5833,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Bristol, City of",
    "capital": "",
    "population": 724000,
    "id": 1826921049
  },
  {
    "city": "Qian’an",
    "city_ascii": "Qian'an",
    "lat": 40.0059,
    "lng": 118.6973,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hebei",
    "capital": "",
    "population": 728160,
    "id": 1156315498
  },
  {
    "city": "Lviv",
    "city_ascii": "Lviv",
    "lat": 49.8419,
    "lng": 24.0315,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "L’vivs’ka Oblast’",
    "capital": "admin",
    "population": 724314,
    "id": 1804939658
  },
  {
    "city": "Zouping",
    "city_ascii": "Zouping",
    "lat": 36.8625,
    "lng": 117.7424,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Shandong",
    "capital": "",
    "population": 726722,
    "id": 1156817518
  },
  {
    "city": "Bremen",
    "city_ascii": "Bremen",
    "lat": 53.1153,
    "lng": 8.7975,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bremen",
    "capital": "admin",
    "population": 724909,
    "id": 1276376509
  },
  {
    "city": "Reynosa",
    "city_ascii": "Reynosa",
    "lat": 26.0922,
    "lng": -98.2778,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Tamaulipas",
    "capital": "minor",
    "population": 725793,
    "id": 1484737671
  },
  {
    "city": "An Najaf",
    "city_ascii": "An Najaf",
    "lat": 32.029,
    "lng": 44.3396,
    "country": "Iraq",
    "iso2": "IQ",
    "iso3": "IRQ",
    "admin_name": "An Najaf",
    "capital": "admin",
    "population": 724700,
    "id": 1368003357
  },
  {
    "city": "Sagamihara",
    "city_ascii": "Sagamihara",
    "lat": 35.5667,
    "lng": 139.3667,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Kanagawa",
    "capital": "",
    "population": 722828,
    "id": 1392967248
  },
  {
    "city": "Guli",
    "city_ascii": "Guli",
    "lat": 28.9008,
    "lng": 120.0284,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Zhejiang",
    "capital": "",
    "population": 723490,
    "id": 1156383681
  },
  {
    "city": "Sarasota",
    "city_ascii": "Sarasota",
    "lat": 27.3386,
    "lng": -82.5431,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 722243,
    "id": 1840015988
  },
  {
    "city": "Okayama",
    "city_ascii": "Okayama",
    "lat": 34.65,
    "lng": 133.9167,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Okayama",
    "capital": "admin",
    "population": 720865,
    "id": 1392954750
  },
  {
    "city": "Mississauga",
    "city_ascii": "Mississauga",
    "lat": 43.6,
    "lng": -79.65,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 721599,
    "id": 1124112672
  },
  {
    "city": "Lingbao Chengguanzhen",
    "city_ascii": "Lingbao Chengguanzhen",
    "lat": 34.5221,
    "lng": 110.8786,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Henan",
    "capital": "minor",
    "population": 721049,
    "id": 1156837145
  },
  {
    "city": "Anlu",
    "city_ascii": "Anlu",
    "lat": 31.2575,
    "lng": 113.6783,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hubei",
    "capital": "minor",
    "population": 720000,
    "id": 1156406202
  },
  {
    "city": "Wusong",
    "city_ascii": "Wusong",
    "lat": 30.9333,
    "lng": 117.7667,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Anhui",
    "capital": "minor",
    "population": 720000,
    "id": 1156264408
  },
  {
    "city": "Dayton",
    "city_ascii": "Dayton",
    "lat": 39.7797,
    "lng": -84.1998,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 718353,
    "id": 1840034249
  },
  {
    "city": "Enugu",
    "city_ascii": "Enugu",
    "lat": 6.4403,
    "lng": 7.4942,
    "country": "Nigeria",
    "iso2": "NG",
    "iso3": "NGA",
    "admin_name": "Enugu",
    "capital": "admin",
    "population": 715774,
    "id": 1566212729
  },
  {
    "city": "George Town",
    "city_ascii": "George Town",
    "lat": 5.4145,
    "lng": 100.3292,
    "country": "Malaysia",
    "iso2": "MY",
    "iso3": "MYS",
    "admin_name": "Pulau Pinang",
    "capital": "admin",
    "population": 708127,
    "id": 1458236750
  },
  {
    "city": "Jaboatão",
    "city_ascii": "Jaboatao",
    "lat": -8.1803,
    "lng": -35.0014,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Pernambuco",
    "capital": "",
    "population": 702621,
    "id": 1076000699
  },
  {
    "city": "Santo André",
    "city_ascii": "Santo Andre",
    "lat": -23.6572,
    "lng": -46.5333,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 710210,
    "id": 1076625886
  },
  {
    "city": "Xichang",
    "city_ascii": "Xichang",
    "lat": 27.8983,
    "lng": 102.2706,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Sichuan",
    "capital": "minor",
    "population": 712434,
    "id": 1156116456
  },
  {
    "city": "Soledad",
    "city_ascii": "Soledad",
    "lat": 10.92,
    "lng": -74.77,
    "country": "Colombia",
    "iso2": "CO",
    "iso3": "COL",
    "admin_name": "Atlántico",
    "capital": "minor",
    "population": 698852,
    "id": 1170765625
  },
  {
    "city": "Chengxiang",
    "city_ascii": "Chengxiang",
    "lat": 31.4515,
    "lng": 121.1043,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Jiangsu",
    "capital": "minor",
    "population": 709500,
    "id": 1156254788
  },
  {
    "city": "Tolyatti",
    "city_ascii": "Tolyatti",
    "lat": 53.5167,
    "lng": 49.4167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Samarskaya Oblast’",
    "capital": "",
    "population": 707408,
    "id": 1643256295
  },
  {
    "city": "Sahāranpur",
    "city_ascii": "Saharanpur",
    "lat": 29.964,
    "lng": 77.546,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Uttar Pradesh",
    "capital": "",
    "population": 705478,
    "id": 1356139858
  },
  {
    "city": "Warangal",
    "city_ascii": "Warangal",
    "lat": 17.9756,
    "lng": 79.6011,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Telangana",
    "capital": "",
    "population": 704570,
    "id": 1356851618
  },
  {
    "city": "Osasco",
    "city_ascii": "Osasco",
    "lat": -23.5328,
    "lng": -46.7919,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 694844,
    "id": 1076786452
  },
  {
    "city": "Dashiqiao",
    "city_ascii": "Dashiqiao",
    "lat": 40.6328,
    "lng": 122.5021,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Liaoning",
    "capital": "minor",
    "population": 704891,
    "id": 1156141962
  },
  {
    "city": "Latakia",
    "city_ascii": "Latakia",
    "lat": 35.5236,
    "lng": 35.7917,
    "country": "Syria",
    "iso2": "SY",
    "iso3": "SYR",
    "admin_name": "Al Lādhiqīyah",
    "capital": "admin",
    "population": 700000,
    "id": 1760555544
  },
  {
    "city": "Rochester",
    "city_ascii": "Rochester",
    "lat": 43.168,
    "lng": -77.6162,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 703952,
    "id": 1840000373
  },
  {
    "city": "Fresno",
    "city_ascii": "Fresno",
    "lat": 36.7831,
    "lng": -119.7941,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 703498,
    "id": 1840020319
  },
  {
    "city": "Banjarmasin",
    "city_ascii": "Banjarmasin",
    "lat": -3.3333,
    "lng": 114.5833,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Kalimantan Selatan",
    "capital": "admin",
    "population": 692793,
    "id": 1360685223
  },
  {
    "city": "Salem",
    "city_ascii": "Salem",
    "lat": 11.65,
    "lng": 78.1667,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Tamil Nādu",
    "capital": "",
    "population": 693236,
    "id": 1356567087
  },
  {
    "city": "Changsha",
    "city_ascii": "Changsha",
    "lat": 22.3762,
    "lng": 112.6877,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Guangdong",
    "capital": "minor",
    "population": 699242,
    "id": 1156011120
  },
  {
    "city": "Grenoble",
    "city_ascii": "Grenoble",
    "lat": 45.1715,
    "lng": 5.7224,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Auvergne-Rhône-Alpes",
    "capital": "minor",
    "population": 687985,
    "id": 1250071647
  },
  {
    "city": "Shishi",
    "city_ascii": "Shishi",
    "lat": 24.7355,
    "lng": 118.6434,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Fujian",
    "capital": "minor",
    "population": 693000,
    "id": 1156077046
  },
  {
    "city": "Guadalupe",
    "city_ascii": "Guadalupe",
    "lat": 25.6775,
    "lng": -100.2597,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Nuevo León",
    "capital": "minor",
    "population": 691931,
    "id": 1484002410
  },
  {
    "city": "Aracaju",
    "city_ascii": "Aracaju",
    "lat": -10.9111,
    "lng": -37.0717,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Sergipe",
    "capital": "admin",
    "population": 685356,
    "id": 1076527830
  },
  {
    "city": "Bauchi",
    "city_ascii": "Bauchi",
    "lat": 10.3158,
    "lng": 9.8442,
    "country": "Nigeria",
    "iso2": "NG",
    "iso3": "NGA",
    "admin_name": "Bauchi",
    "capital": "admin",
    "population": 693700,
    "id": 1566483284
  },
  {
    "city": "Hamilton",
    "city_ascii": "Hamilton",
    "lat": 43.2567,
    "lng": -79.8692,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 693645,
    "id": 1124567288
  },
  {
    "city": "Łódź",
    "city_ascii": "Lodz",
    "lat": 51.7769,
    "lng": 19.4547,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Łódzkie",
    "capital": "admin",
    "population": 690422,
    "id": 1616832750
  },
  {
    "city": "Miluo Chengguanzhen",
    "city_ascii": "Miluo Chengguanzhen",
    "lat": 28.8049,
    "lng": 113.0745,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hunan",
    "capital": "minor",
    "population": 692080,
    "id": 1156690651
  },
  {
    "city": "Gaizhou",
    "city_ascii": "Gaizhou",
    "lat": 40.4019,
    "lng": 122.3499,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Liaoning",
    "capital": "minor",
    "population": 691595,
    "id": 1156145272
  },
  {
    "city": "Shizuoka",
    "city_ascii": "Shizuoka",
    "lat": 34.9756,
    "lng": 138.3825,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Shizuoka",
    "capital": "admin",
    "population": 691185,
    "id": 1392235505
  },
  {
    "city": "Mālegaon",
    "city_ascii": "Malegaon",
    "lat": 20.55,
    "lng": 74.55,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Mahārāshtra",
    "capital": "",
    "population": 690844,
    "id": 1356113556
  },
  {
    "city": "Karbalā’",
    "city_ascii": "Karbala'",
    "lat": 32.6167,
    "lng": 44.0333,
    "country": "Iraq",
    "iso2": "IQ",
    "iso3": "IRQ",
    "admin_name": "Karbalā’",
    "capital": "admin",
    "population": 690100,
    "id": 1368597794
  },
  {
    "city": "Leling",
    "city_ascii": "Leling",
    "lat": 37.7333,
    "lng": 117.2167,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Shandong",
    "capital": "minor",
    "population": 689867,
    "id": 1156044041
  },
  {
    "city": "São José dos Campos",
    "city_ascii": "Sao Jose dos Campos",
    "lat": -23.1789,
    "lng": -45.8869,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 688597,
    "id": 1076783887
  },
  {
    "city": "Jianshe",
    "city_ascii": "Jianshe",
    "lat": 34.2189,
    "lng": 113.7693,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Henan",
    "capital": "minor",
    "population": 687130,
    "id": 1156444449
  },
  {
    "city": "Acapulco de Juárez",
    "city_ascii": "Acapulco de Juarez",
    "lat": 16.8636,
    "lng": -99.8825,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Guerrero",
    "capital": "minor",
    "population": 687608,
    "id": 1484463183
  },
  {
    "city": "Sheffield",
    "city_ascii": "Sheffield",
    "lat": 53.3833,
    "lng": -1.4667,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Sheffield",
    "capital": "",
    "population": 685368,
    "id": 1826289852
  },
  {
    "city": "Jingcheng",
    "city_ascii": "Jingcheng",
    "lat": 32.0058,
    "lng": 120.2547,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Jiangsu",
    "capital": "minor",
    "population": 684360,
    "id": 1156033208
  },
  {
    "city": "City of Parañaque",
    "city_ascii": "City of Paranaque",
    "lat": 14.4667,
    "lng": 121.0167,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Parañaque",
    "capital": "admin",
    "population": 665822,
    "id": 1608379532
  },
  {
    "city": "Kochi",
    "city_ascii": "Kochi",
    "lat": 9.9667,
    "lng": 76.2833,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Kerala",
    "capital": "",
    "population": 677381,
    "id": 1356707865
  },
  {
    "city": "Allentown",
    "city_ascii": "Allentown",
    "lat": 40.5961,
    "lng": -75.4756,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 683794,
    "id": 1840001044
  },
  {
    "city": "Bahawalpur",
    "city_ascii": "Bahawalpur",
    "lat": 29.3956,
    "lng": 71.6722,
    "country": "Pakistan",
    "iso2": "PK",
    "iso3": "PAK",
    "admin_name": "Punjab",
    "capital": "minor",
    "population": 681696,
    "id": 1586038700
  },
  {
    "city": "Tasikmalaya",
    "city_ascii": "Tasikmalaya",
    "lat": -7.3333,
    "lng": 108.2,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Jawa Barat",
    "capital": "",
    "population": 678027,
    "id": 1360027771
  },
  {
    "city": "Macau",
    "city_ascii": "Macau",
    "lat": 22.203,
    "lng": 113.545,
    "country": "Macau",
    "iso2": "MO",
    "iso3": "MAC",
    "admin_name": "",
    "capital": "",
    "population": 568700,
    "id": 1446227359
  },
  {
    "city": "Torreón",
    "city_ascii": "Torreon",
    "lat": 25.5394,
    "lng": -103.4486,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Coahuila de Zaragoza",
    "capital": "minor",
    "population": 679288,
    "id": 1484215762
  },
  {
    "city": "Xinmin",
    "city_ascii": "Xinmin",
    "lat": 41.9952,
    "lng": 122.8224,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Liaoning",
    "capital": "",
    "population": 680000,
    "id": 1156368551
  },
  {
    "city": "Shanhu",
    "city_ascii": "Shanhu",
    "lat": 29.5908,
    "lng": 120.8176,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Zhejiang",
    "capital": "minor",
    "population": 679762,
    "id": 1156029691
  },
  {
    "city": "Zhongshu",
    "city_ascii": "Zhongshu",
    "lat": 27.8116,
    "lng": 106.4133,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Guizhou",
    "capital": "",
    "population": 679624,
    "id": 1156658422
  },
  {
    "city": "Xigazê",
    "city_ascii": "Xigaze",
    "lat": 29.2649,
    "lng": 88.8799,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Tibet",
    "capital": "minor",
    "population": 679771,
    "id": 1156107208
  },
  {
    "city": "Gold Coast",
    "city_ascii": "Gold Coast",
    "lat": -28.0167,
    "lng": 153.4,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Queensland",
    "capital": "",
    "population": 679127,
    "id": 1036153217
  },
  {
    "city": "Palermo",
    "city_ascii": "Palermo",
    "lat": 38.1157,
    "lng": 13.3613,
    "country": "Italy",
    "iso2": "IT",
    "iso3": "ITA",
    "admin_name": "Sicilia",
    "capital": "admin",
    "population": 674435,
    "id": 1380428434
  },
  {
    "city": "Cagayan de Oro",
    "city_ascii": "Cagayan de Oro",
    "lat": 8.4833,
    "lng": 124.65,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Cagayan de Oro",
    "capital": "admin",
    "population": 675950,
    "id": 1608831546
  },
  {
    "city": "Gorakhpur",
    "city_ascii": "Gorakhpur",
    "lat": 26.7611,
    "lng": 83.3667,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Uttar Pradesh",
    "capital": "",
    "population": 673446,
    "id": 1356190221
  },
  {
    "city": "Pinghu",
    "city_ascii": "Pinghu",
    "lat": 30.7005,
    "lng": 121.0104,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Zhejiang",
    "capital": "",
    "population": 671834,
    "id": 1156720536
  },
  {
    "city": "Guankou",
    "city_ascii": "Guankou",
    "lat": 30.9933,
    "lng": 103.624,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Sichuan",
    "capital": "",
    "population": 671200,
    "id": 1156503907
  },
  {
    "city": "Tulsa",
    "city_ascii": "Tulsa",
    "lat": 36.1284,
    "lng": -95.9042,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oklahoma",
    "capital": "",
    "population": 671033,
    "id": 1840021672
  },
  {
    "city": "Yatou",
    "city_ascii": "Yatou",
    "lat": 37.1653,
    "lng": 122.4867,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Shandong",
    "capital": "minor",
    "population": 670251,
    "id": 1156009046
  },
  {
    "city": "Songyang",
    "city_ascii": "Songyang",
    "lat": 34.4161,
    "lng": 113.0424,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Henan",
    "capital": "minor",
    "population": 668592,
    "id": 1156143149
  },
  {
    "city": "Cape Coral",
    "city_ascii": "Cape Coral",
    "lat": 26.6446,
    "lng": -81.9956,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 668382,
    "id": 1840015130
  },
  {
    "city": "Ch’ŏngjin",
    "city_ascii": "Ch'ongjin",
    "lat": 41.8,
    "lng": 129.7831,
    "country": "Korea, North",
    "iso2": "KP",
    "iso3": "PRK",
    "admin_name": "Hambuk",
    "capital": "minor",
    "population": 667929,
    "id": 1408498177
  },
  {
    "city": "São José dos Pinhais",
    "city_ascii": "Sao Jose dos Pinhais",
    "lat": -25.57,
    "lng": -49.18,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Paraná",
    "capital": "",
    "population": 665063,
    "id": 1076174955
  },
  {
    "city": "Puyang Chengguanzhen",
    "city_ascii": "Puyang Chengguanzhen",
    "lat": 35.7004,
    "lng": 114.98,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Henan",
    "capital": "minor",
    "population": 666322,
    "id": 1156103317
  },
  {
    "city": "Qionghu",
    "city_ascii": "Qionghu",
    "lat": 28.8417,
    "lng": 112.3595,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hunan",
    "capital": "minor",
    "population": 667104,
    "id": 1156678111
  },
  {
    "city": "Yanshi Chengguanzhen",
    "city_ascii": "Yanshi Chengguanzhen",
    "lat": 34.723,
    "lng": 112.7796,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Henan",
    "capital": "minor",
    "population": 666696,
    "id": 1156660138
  },
  {
    "city": "Ribeirão Prêto",
    "city_ascii": "Ribeirao Preto",
    "lat": -21.1783,
    "lng": -47.8067,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 666323,
    "id": 1076524478
  },
  {
    "city": "Dasmariñas",
    "city_ascii": "Dasmarinas",
    "lat": 14.3294,
    "lng": 120.9367,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Cavite",
    "capital": "",
    "population": 659019,
    "id": 1608418544
  },
  {
    "city": "Huambo",
    "city_ascii": "Huambo",
    "lat": -12.7667,
    "lng": 15.7333,
    "country": "Angola",
    "iso2": "AO",
    "iso3": "AGO",
    "admin_name": "Huambo",
    "capital": "admin",
    "population": 665564,
    "id": 1024079828
  },
  {
    "city": "Wenchang",
    "city_ascii": "Wenchang",
    "lat": 31.054,
    "lng": 116.9507,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Anhui",
    "capital": "minor",
    "population": 664455,
    "id": 1156574542
  },
  {
    "city": "Shulan",
    "city_ascii": "Shulan",
    "lat": 44.4167,
    "lng": 126.95,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Jilin",
    "capital": "",
    "population": 663403,
    "id": 1156989733
  },
  {
    "city": "Catia La Mar",
    "city_ascii": "Catia La Mar",
    "lat": 10.6,
    "lng": -67.0333,
    "country": "Venezuela",
    "iso2": "VE",
    "iso3": "VEN",
    "admin_name": "Vargas",
    "capital": "minor",
    "population": 661897,
    "id": 1862657837
  },
  {
    "city": "Bouaké",
    "city_ascii": "Bouake",
    "lat": 7.6833,
    "lng": -5.0331,
    "country": "Côte D’Ivoire",
    "iso2": "CI",
    "iso3": "CIV",
    "admin_name": "Vallée du Bandama",
    "capital": "admin",
    "population": 659233,
    "id": 1384983838
  },
  {
    "city": "As Sulaymānīyah",
    "city_ascii": "As Sulaymaniyah",
    "lat": 35.55,
    "lng": 45.4333,
    "country": "Iraq",
    "iso2": "IQ",
    "iso3": "IRQ",
    "admin_name": "As Sulaymānīyah",
    "capital": "admin",
    "population": 656100,
    "id": 1368735317
  },
  {
    "city": "Hwasu-dong",
    "city_ascii": "Hwasu-dong",
    "lat": 37.2,
    "lng": 126.7667,
    "country": "Korea, South",
    "iso2": "KR",
    "iso3": "KOR",
    "admin_name": "Gyeonggi",
    "capital": "",
    "population": 655350,
    "id": 1410037247
  },
  {
    "city": "Jeonju",
    "city_ascii": "Jeonju",
    "lat": 35.8219,
    "lng": 127.1489,
    "country": "Korea, South",
    "iso2": "KR",
    "iso3": "KOR",
    "admin_name": "Jeonbuk",
    "capital": "minor",
    "population": 653055,
    "id": 1410494847
  },
  {
    "city": "Durango",
    "city_ascii": "Durango",
    "lat": 24.0167,
    "lng": -104.6667,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Durango",
    "capital": "admin",
    "population": 654876,
    "id": 1484854616
  },
  {
    "city": "Shimoga",
    "city_ascii": "Shimoga",
    "lat": 13.9304,
    "lng": 75.56,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Karnātaka",
    "capital": "",
    "population": 654055,
    "id": 1356016156
  },
  {
    "city": "Ansan",
    "city_ascii": "Ansan",
    "lat": 37.3236,
    "lng": 126.8219,
    "country": "Korea, South",
    "iso2": "KR",
    "iso3": "KOR",
    "admin_name": "Gyeonggi",
    "capital": "",
    "population": 652800,
    "id": 1410585171
  },
  {
    "city": "Bulawayo",
    "city_ascii": "Bulawayo",
    "lat": -20.1667,
    "lng": 28.5667,
    "country": "Zimbabwe",
    "iso2": "ZW",
    "iso3": "ZWE",
    "admin_name": "Bulawayo",
    "capital": "admin",
    "population": 653337,
    "id": 1716032632
  },
  {
    "city": "Xiping",
    "city_ascii": "Xiping",
    "lat": 25.6005,
    "lng": 103.8166,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Yunnan",
    "capital": "minor",
    "population": 652604,
    "id": 1156207794
  },
  {
    "city": "Sanhe",
    "city_ascii": "Sanhe",
    "lat": 39.9808,
    "lng": 117.0701,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hebei",
    "capital": "",
    "population": 652042,
    "id": 1156476348
  },
  {
    "city": "Guntūr",
    "city_ascii": "Guntur",
    "lat": 16.3,
    "lng": 80.45,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Andhra Pradesh",
    "capital": "",
    "population": 647508,
    "id": 1356374905
  },
  {
    "city": "Dali",
    "city_ascii": "Dali",
    "lat": 25.7003,
    "lng": 100.1564,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Yunnan",
    "capital": "",
    "population": 652045,
    "id": 1156840276
  },
  {
    "city": "Concord",
    "city_ascii": "Concord",
    "lat": 37.9722,
    "lng": -122.0016,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 652308,
    "id": 1840018905
  },
  {
    "city": "Tiruppūr",
    "city_ascii": "Tiruppur",
    "lat": 11.1075,
    "lng": 77.3398,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Tamil Nādu",
    "capital": "",
    "population": 650000,
    "id": 1356539761
  },
  {
    "city": "Ch’ŏnan",
    "city_ascii": "Ch'onan",
    "lat": 36.8064,
    "lng": 127.1522,
    "country": "Korea, South",
    "iso2": "KR",
    "iso3": "KOR",
    "admin_name": "Chungnam",
    "capital": "",
    "population": 651661,
    "id": 1410020455
  },
  {
    "city": "Zaragoza",
    "city_ascii": "Zaragoza",
    "lat": 41.6483,
    "lng": -0.883,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Aragon",
    "capital": "admin",
    "population": 649404,
    "id": 1724907391
  },
  {
    "city": "Izhevsk",
    "city_ascii": "Izhevsk",
    "lat": 56.85,
    "lng": 53.2167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Udmurtiya",
    "capital": "admin",
    "population": 646277,
    "id": 1643983138
  },
  {
    "city": "Guixi",
    "city_ascii": "Guixi",
    "lat": 28.2861,
    "lng": 117.2083,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Jiangxi",
    "capital": "minor",
    "population": 647240,
    "id": 1156259342
  },
  {
    "city": "Honchō",
    "city_ascii": "Honcho",
    "lat": 35.6947,
    "lng": 139.9825,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Chiba",
    "capital": "",
    "population": 639107,
    "id": 1392505502
  },
  {
    "city": "Sorocaba",
    "city_ascii": "Sorocaba",
    "lat": -23.5019,
    "lng": -47.4578,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 644919,
    "id": 1076758708
  },
  {
    "city": "Villahermosa",
    "city_ascii": "Villahermosa",
    "lat": 17.9892,
    "lng": -92.9281,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Tabasco",
    "capital": "admin",
    "population": 640359,
    "id": 1484460894
  },
  {
    "city": "Petaling Jaya",
    "city_ascii": "Petaling Jaya",
    "lat": 3.1073,
    "lng": 101.6067,
    "country": "Malaysia",
    "iso2": "MY",
    "iso3": "MYS",
    "admin_name": "Selangor",
    "capital": "",
    "population": 638516,
    "id": 1458989343
  },
  {
    "city": "Wuxue",
    "city_ascii": "Wuxue",
    "lat": 29.8518,
    "lng": 115.5539,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hubei",
    "capital": "minor",
    "population": 644247,
    "id": 1156027100
  },
  {
    "city": "Utrecht",
    "city_ascii": "Utrecht",
    "lat": 52.1003,
    "lng": 5.12,
    "country": "Netherlands",
    "iso2": "NL",
    "iso3": "NLD",
    "admin_name": "Utrecht",
    "capital": "admin",
    "population": 640000,
    "id": 1528871692
  },
  {
    "city": "Kikwit",
    "city_ascii": "Kikwit",
    "lat": -5.0333,
    "lng": 18.8167,
    "country": "Congo (Kinshasa)",
    "iso2": "CD",
    "iso3": "COD",
    "admin_name": "Kwilu",
    "capital": "admin",
    "population": 637736,
    "id": 1180518247
  },
  {
    "city": "Colorado Springs",
    "city_ascii": "Colorado Springs",
    "lat": 38.8674,
    "lng": -104.7606,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 642413,
    "id": 1840018825
  },
  {
    "city": "Valenzuela",
    "city_ascii": "Valenzuela",
    "lat": 14.7,
    "lng": 120.9833,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Valenzuela",
    "capital": "admin",
    "population": 620422,
    "id": 1608492120
  },
  {
    "city": "Gaobeidian",
    "city_ascii": "Gaobeidian",
    "lat": 39.3257,
    "lng": 115.8678,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hebei",
    "capital": "",
    "population": 640280,
    "id": 1156487293
  },
  {
    "city": "Qufu",
    "city_ascii": "Qufu",
    "lat": 35.6,
    "lng": 116.9833,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Shandong",
    "capital": "",
    "population": 640498,
    "id": 1156490037
  },
  {
    "city": "Ruiming",
    "city_ascii": "Ruiming",
    "lat": 25.8833,
    "lng": 116.0333,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Jiangxi",
    "capital": "",
    "population": 640600,
    "id": 1156104573
  },
  {
    "city": "Wrocław",
    "city_ascii": "Wroclaw",
    "lat": 51.1077,
    "lng": 17.0353,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Dolnośląskie",
    "capital": "admin",
    "population": 638586,
    "id": 1616108520
  },
  {
    "city": "Rasht",
    "city_ascii": "Rasht",
    "lat": 37.2833,
    "lng": 49.6,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Gīlān",
    "capital": "admin",
    "population": 639951,
    "id": 1364630382
  },
  {
    "city": "Nantes",
    "city_ascii": "Nantes",
    "lat": 47.2181,
    "lng": -1.5528,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Pays de la Loire",
    "capital": "admin",
    "population": 633690,
    "id": 1250091495
  },
  {
    "city": "Stuttgart",
    "city_ascii": "Stuttgart",
    "lat": 48.7761,
    "lng": 9.1775,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "admin",
    "population": 634830,
    "id": 1276171358
  },
  {
    "city": "Al Hufūf",
    "city_ascii": "Al Hufuf",
    "lat": 25.3608,
    "lng": 49.5997,
    "country": "Saudi Arabia",
    "iso2": "SA",
    "iso3": "SAU",
    "admin_name": "Ash Sharqīyah",
    "capital": "",
    "population": 637389,
    "id": 1682958157
  },
  {
    "city": "Xinshi",
    "city_ascii": "Xinshi",
    "lat": 31.0236,
    "lng": 113.1079,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hubei",
    "capital": "minor",
    "population": 636776,
    "id": 1156422214
  },
  {
    "city": "Cochabamba",
    "city_ascii": "Cochabamba",
    "lat": -17.3935,
    "lng": -66.157,
    "country": "Bolivia",
    "iso2": "BO",
    "iso3": "BOL",
    "admin_name": "Cochabamba",
    "capital": "admin",
    "population": 632013,
    "id": 1068793894
  },
  {
    "city": "Barnaul",
    "city_ascii": "Barnaul",
    "lat": 53.3567,
    "lng": 83.7872,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Altayskiy Kray",
    "capital": "admin",
    "population": 633301,
    "id": 1643318931
  },
  {
    "city": "Tripoli",
    "city_ascii": "Tripoli",
    "lat": 34.4333,
    "lng": 35.8333,
    "country": "Lebanon",
    "iso2": "LB",
    "iso3": "LBN",
    "admin_name": "Liban-Nord",
    "capital": "admin",
    "population": 530000,
    "id": 1422886065
  },
  {
    "city": "Jin’e",
    "city_ascii": "Jin'e",
    "lat": 29.3395,
    "lng": 105.2868,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Sichuan",
    "capital": "minor",
    "population": 633210,
    "id": 1156661253
  },
  {
    "city": "Benghazi",
    "city_ascii": "Benghazi",
    "lat": 32.1167,
    "lng": 20.0667,
    "country": "Libya",
    "iso2": "LY",
    "iso3": "LBY",
    "admin_name": "Banghāzī",
    "capital": "admin",
    "population": 631555,
    "id": 1434915572
  },
  {
    "city": "Kryvyy Rih",
    "city_ascii": "Kryvyy Rih",
    "lat": 47.9086,
    "lng": 33.3433,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Dnipropetrovs’ka Oblast’",
    "capital": "minor",
    "population": 629695,
    "id": 1804544901
  },
  {
    "city": "Yanggok",
    "city_ascii": "Yanggok",
    "lat": 37.6367,
    "lng": 127.2142,
    "country": "Korea, South",
    "iso2": "KR",
    "iso3": "KOR",
    "admin_name": "Gyeonggi",
    "capital": "",
    "population": 629061,
    "id": 1410188674
  },
  {
    "city": "Changping",
    "city_ascii": "Changping",
    "lat": 40.2248,
    "lng": 116.1944,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Beijing",
    "capital": "",
    "population": 614821,
    "id": 1156166828
  },
  {
    "city": "Raurkela",
    "city_ascii": "Raurkela",
    "lat": 22.2492,
    "lng": 84.8828,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Odisha",
    "capital": "",
    "population": 625831,
    "id": 1356096816
  },
  {
    "city": "Ḩalwān",
    "city_ascii": "Halwan",
    "lat": 29.8419,
    "lng": 31.3342,
    "country": "Egypt",
    "iso2": "EG",
    "iso3": "EGY",
    "admin_name": "Al Qāhirah",
    "capital": "",
    "population": 619293,
    "id": 1818000039
  },
  {
    "city": "Charleston",
    "city_ascii": "Charleston",
    "lat": 32.8153,
    "lng": -79.9628,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 628377,
    "id": 1840015163
  },
  {
    "city": "Chimalhuacán",
    "city_ascii": "Chimalhuacan",
    "lat": 19.4167,
    "lng": -98.9,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "México",
    "capital": "minor",
    "population": 614453,
    "id": 1484504936
  },
  {
    "city": "Xinxing",
    "city_ascii": "Xinxing",
    "lat": 39.8734,
    "lng": 124.1453,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Liaoning",
    "capital": "minor",
    "population": 627475,
    "id": 1156258586
  },
  {
    "city": "Suohe",
    "city_ascii": "Suohe",
    "lat": 34.7879,
    "lng": 113.392,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Henan",
    "capital": "minor",
    "population": 626700,
    "id": 1156236948
  },
  {
    "city": "Mangalore",
    "city_ascii": "Mangalore",
    "lat": 12.8703,
    "lng": 74.8806,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Karnātaka",
    "capital": "",
    "population": 623841,
    "id": 1356972351
  },
  {
    "city": "Zhuangyuan",
    "city_ascii": "Zhuangyuan",
    "lat": 37.3,
    "lng": 120.8333,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Shandong",
    "capital": "minor",
    "population": 626683,
    "id": 1156263400
  },
  {
    "city": "Ulyanovsk",
    "city_ascii": "Ulyanovsk",
    "lat": 54.3167,
    "lng": 48.3667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Ul’yanovskaya Oblast’",
    "capital": "admin",
    "population": 624518,
    "id": 1643668944
  },
  {
    "city": "Irkutsk",
    "city_ascii": "Irkutsk",
    "lat": 52.2833,
    "lng": 104.3,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Irkutskaya Oblast’",
    "capital": "admin",
    "population": 623736,
    "id": 1643439037
  },
  {
    "city": "Nānded",
    "city_ascii": "Nanded",
    "lat": 19.15,
    "lng": 77.3333,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Mahārāshtra",
    "capital": "",
    "population": 623708,
    "id": 1356722310
  },
  {
    "city": "Bacoor",
    "city_ascii": "Bacoor",
    "lat": 14.4624,
    "lng": 120.9645,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Cavite",
    "capital": "",
    "population": 600609,
    "id": 1608869358
  },
  {
    "city": "Pontianak",
    "city_ascii": "Pontianak",
    "lat": -0.0206,
    "lng": 109.3414,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Kalimantan Barat",
    "capital": "admin",
    "population": 618388,
    "id": 1360992394
  },
  {
    "city": "Bazhou",
    "city_ascii": "Bazhou",
    "lat": 39.1235,
    "lng": 116.386,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hebei",
    "capital": "",
    "population": 622975,
    "id": 1156127276
  },
  {
    "city": "Springfield",
    "city_ascii": "Springfield",
    "lat": 42.1155,
    "lng": -72.5395,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 623401,
    "id": 1840000466
  },
  {
    "city": "Turpan",
    "city_ascii": "Turpan",
    "lat": 42.95,
    "lng": 89.1822,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Xinjiang",
    "capital": "minor",
    "population": 622903,
    "id": 1156573684
  },
  {
    "city": "Düsseldorf",
    "city_ascii": "Dusseldorf",
    "lat": 51.2311,
    "lng": 6.7724,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "admin",
    "population": 619294,
    "id": 1276615258
  },
  {
    "city": "Xingtai",
    "city_ascii": "Xingtai",
    "lat": 37.0659,
    "lng": 114.4753,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hebei",
    "capital": "",
    "population": 611739,
    "id": 1156294952
  },
  {
    "city": "Meihekou",
    "city_ascii": "Meihekou",
    "lat": 42.5279,
    "lng": 125.678,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Jilin",
    "capital": "minor",
    "population": 618251,
    "id": 1156474288
  },
  {
    "city": "Jurong",
    "city_ascii": "Jurong",
    "lat": 31.9579,
    "lng": 119.1595,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Jiangsu",
    "capital": "",
    "population": 617706,
    "id": 1156897463
  },
  {
    "city": "Zhugang",
    "city_ascii": "Zhugang",
    "lat": 28.1277,
    "lng": 121.2288,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Zhejiang",
    "capital": "minor",
    "population": 616346,
    "id": 1156077330
  },
  {
    "city": "Khabarovsk",
    "city_ascii": "Khabarovsk",
    "lat": 48.4833,
    "lng": 135.0667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Khabarovskiy Kray",
    "capital": "admin",
    "population": 616242,
    "id": 1643175363
  },
  {
    "city": "Xinji",
    "city_ascii": "Xinji",
    "lat": 37.9423,
    "lng": 115.2118,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hebei",
    "capital": "",
    "population": 615919,
    "id": 1156456552
  },
  {
    "city": "Hamhŭng",
    "city_ascii": "Hamhung",
    "lat": 39.9167,
    "lng": 127.5333,
    "country": "Korea, North",
    "iso2": "KP",
    "iso3": "PRK",
    "admin_name": "Hamnam",
    "capital": "admin",
    "population": 614198,
    "id": 1408482040
  },
  {
    "city": "Serang",
    "city_ascii": "Serang",
    "lat": -6.12,
    "lng": 106.1503,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Banten",
    "capital": "admin",
    "population": 613356,
    "id": 1360329102
  },
  {
    "city": "Buraydah",
    "city_ascii": "Buraydah",
    "lat": 26.3333,
    "lng": 43.9667,
    "country": "Saudi Arabia",
    "iso2": "SA",
    "iso3": "SAU",
    "admin_name": "Al Qaşīm",
    "capital": "admin",
    "population": 614093,
    "id": 1682470726
  },
  {
    "city": "Ta‘izz",
    "city_ascii": "Ta`izz",
    "lat": 13.5789,
    "lng": 44.0219,
    "country": "Yemen",
    "iso2": "YE",
    "iso3": "YEM",
    "admin_name": "Ta‘izz",
    "capital": "admin",
    "population": 596672,
    "id": 1887324526
  },
  {
    "city": "Montpellier",
    "city_ascii": "Montpellier",
    "lat": 43.6119,
    "lng": 3.8772,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Occitanie",
    "capital": "minor",
    "population": 607896,
    "id": 1250819356
  },
  {
    "city": "San Miguel de Tucumán",
    "city_ascii": "San Miguel de Tucuman",
    "lat": -26.8167,
    "lng": -65.2167,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Tucumán",
    "capital": "admin",
    "population": 605767,
    "id": 1032233996
  },
  {
    "city": "Yaroslavl",
    "city_ascii": "Yaroslavl",
    "lat": 57.6167,
    "lng": 39.85,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Yaroslavskaya Oblast’",
    "capital": "admin",
    "population": 608079,
    "id": 1643338528
  },
  {
    "city": "Zhangshu",
    "city_ascii": "Zhangshu",
    "lat": 28.0667,
    "lng": 115.5375,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Jiangxi",
    "capital": "",
    "population": 610424,
    "id": 1156996913
  },
  {
    "city": "Grand Rapids",
    "city_ascii": "Grand Rapids",
    "lat": 42.962,
    "lng": -85.6562,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 609314,
    "id": 1840002928
  },
  {
    "city": "Vladivostok",
    "city_ascii": "Vladivostok",
    "lat": 43.1167,
    "lng": 131.9,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Primorskiy Kray",
    "capital": "admin",
    "population": 606589,
    "id": 1643832017
  },
  {
    "city": "Kuantan",
    "city_ascii": "Kuantan",
    "lat": 3.8167,
    "lng": 103.3333,
    "country": "Malaysia",
    "iso2": "MY",
    "iso3": "MYS",
    "admin_name": "Pahang",
    "capital": "admin",
    "population": 607778,
    "id": 1458763489
  },
  {
    "city": "Cuttack",
    "city_ascii": "Cuttack",
    "lat": 20.45,
    "lng": 85.8667,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Odisha",
    "capital": "",
    "population": 606007,
    "id": 1356872061
  },
  {
    "city": "Jambi",
    "city_ascii": "Jambi",
    "lat": -1.59,
    "lng": 103.61,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Jambi",
    "capital": "admin",
    "population": 602187,
    "id": 1360645580
  },
  {
    "city": "Zhuozhou",
    "city_ascii": "Zhuozhou",
    "lat": 39.4887,
    "lng": 115.9918,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hebei",
    "capital": "",
    "population": 603535,
    "id": 1156045160
  },
  {
    "city": "Uberlândia",
    "city_ascii": "Uberlandia",
    "lat": -18.9189,
    "lng": -48.2769,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Minas Gerais",
    "capital": "",
    "population": 604013,
    "id": 1076495001
  },
  {
    "city": "Gothenburg",
    "city_ascii": "Gothenburg",
    "lat": 57.6717,
    "lng": 11.981,
    "country": "Sweden",
    "iso2": "SE",
    "iso3": "SWE",
    "admin_name": "Västra Götaland",
    "capital": "admin",
    "population": 600473,
    "id": 1752059374
  },
  {
    "city": "Tianchang",
    "city_ascii": "Tianchang",
    "lat": 32.6853,
    "lng": 119.0041,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Anhui",
    "capital": "minor",
    "population": 602840,
    "id": 1156770851
  },
  {
    "city": "Canoas",
    "city_ascii": "Canoas",
    "lat": -29.92,
    "lng": -51.18,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Rio Grande do Sul",
    "capital": "",
    "population": 600000,
    "id": 1076009922
  },
  {
    "city": "Sargodha",
    "city_ascii": "Sargodha",
    "lat": 32.0836,
    "lng": 72.6711,
    "country": "Pakistan",
    "iso2": "PK",
    "iso3": "PAK",
    "admin_name": "Punjab",
    "capital": "minor",
    "population": 602631,
    "id": 1586696588
  },
  {
    "city": "Las Piñas City",
    "city_ascii": "Las Pinas City",
    "lat": 14.45,
    "lng": 120.9833,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Las Piñas",
    "capital": "",
    "population": 588894,
    "id": 1608544537
  },
  {
    "city": "Cimahi",
    "city_ascii": "Cimahi",
    "lat": -6.8833,
    "lng": 107.5333,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Jawa Barat",
    "capital": "",
    "population": 586580,
    "id": 1360181436
  },
  {
    "city": "Kawaguchi",
    "city_ascii": "Kawaguchi",
    "lat": 35.8078,
    "lng": 139.7242,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Saitama",
    "capital": "",
    "population": 592373,
    "id": 1392975133
  },
  {
    "city": "Tuxtla",
    "city_ascii": "Tuxtla",
    "lat": 16.7528,
    "lng": -93.1167,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Chiapas",
    "capital": "admin",
    "population": 598710,
    "id": 1484832519
  },
  {
    "city": "Balikpapan",
    "city_ascii": "Balikpapan",
    "lat": -1.1489,
    "lng": 116.9031,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Kalimantan Timur",
    "capital": "",
    "population": 598043,
    "id": 1360500922
  },
  {
    "city": "Qamdo",
    "city_ascii": "Qamdo",
    "lat": 31.1375,
    "lng": 97.1777,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Tibet",
    "capital": "minor",
    "population": 598862,
    "id": 1156277802
  },
  {
    "city": "Durban",
    "city_ascii": "Durban",
    "lat": -29.8583,
    "lng": 31.025,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "KwaZulu-Natal",
    "capital": "",
    "population": 595061,
    "id": 1710149138
  },
  {
    "city": "Kagoshima",
    "city_ascii": "Kagoshima",
    "lat": 31.58,
    "lng": 130.5281,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Kagoshima",
    "capital": "admin",
    "population": 595319,
    "id": 1392120675
  },
  {
    "city": "General Santos",
    "city_ascii": "General Santos",
    "lat": 6.1167,
    "lng": 125.1667,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "General Santos",
    "capital": "admin",
    "population": 594446,
    "id": 1608171585
  },
  {
    "city": "Chānda",
    "city_ascii": "Chanda",
    "lat": 19.95,
    "lng": 79.3,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Mahārāshtra",
    "capital": "",
    "population": 595118,
    "id": 1356592607
  },
  {
    "city": "Al Ḩillah",
    "city_ascii": "Al Hillah",
    "lat": 23.4895,
    "lng": 46.7564,
    "country": "Saudi Arabia",
    "iso2": "SA",
    "iso3": "SAU",
    "admin_name": "Ar Riyāḑ",
    "capital": "",
    "population": 594605,
    "id": 1682494753
  },
  {
    "city": "Makhachkala",
    "city_ascii": "Makhachkala",
    "lat": 42.9833,
    "lng": 47.4833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Dagestan",
    "capital": "admin",
    "population": 592976,
    "id": 1643580480
  },
  {
    "city": "Mar del Plata",
    "city_ascii": "Mar del Plata",
    "lat": -38,
    "lng": -57.55,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires",
    "capital": "minor",
    "population": 593337,
    "id": 1032456103
  },
  {
    "city": "Brampton",
    "city_ascii": "Brampton",
    "lat": 43.6833,
    "lng": -79.7667,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 593638,
    "id": 1124625989
  },
  {
    "city": "Luocheng",
    "city_ascii": "Luocheng",
    "lat": 30.9793,
    "lng": 104.28,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Sichuan",
    "capital": "minor",
    "population": 591115,
    "id": 1156180482
  },
  {
    "city": "Mission Viejo",
    "city_ascii": "Mission Viejo",
    "lat": 33.6095,
    "lng": -117.655,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 590249,
    "id": 1840020580
  },
  {
    "city": "Dortmund",
    "city_ascii": "Dortmund",
    "lat": 51.5139,
    "lng": 7.4653,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "minor",
    "population": 587010,
    "id": 1276562886
  },
  {
    "city": "Chuxiong",
    "city_ascii": "Chuxiong",
    "lat": 25.0461,
    "lng": 101.5436,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Yunnan",
    "capital": "",
    "population": 588620,
    "id": 1156225985
  },
  {
    "city": "Makati City",
    "city_ascii": "Makati City",
    "lat": 14.55,
    "lng": 121.0333,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Makati",
    "capital": "admin",
    "population": 582602,
    "id": 1608725647
  },
  {
    "city": "Albany",
    "city_ascii": "Albany",
    "lat": 42.6664,
    "lng": -73.7987,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "admin",
    "population": 586383,
    "id": 1840000417
  },
  {
    "city": "Shah Alam",
    "city_ascii": "Shah Alam",
    "lat": 3.0833,
    "lng": 101.5333,
    "country": "Malaysia",
    "iso2": "MY",
    "iso3": "MYS",
    "admin_name": "Selangor",
    "capital": "admin",
    "population": 584340,
    "id": 1458194263
  },
  {
    "city": "Knoxville",
    "city_ascii": "Knoxville",
    "lat": 35.9692,
    "lng": -83.9496,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 586048,
    "id": 1840014486
  },
  {
    "city": "Essen",
    "city_ascii": "Essen",
    "lat": 51.4508,
    "lng": 7.0131,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "minor",
    "population": 583109,
    "id": 1276769247
  },
  {
    "city": "Cuiabá",
    "city_ascii": "Cuiaba",
    "lat": -15.5958,
    "lng": -56.0969,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Mato Grosso",
    "capital": "admin",
    "population": 585367,
    "id": 1076112309
  },
  {
    "city": "Shangzhi",
    "city_ascii": "Shangzhi",
    "lat": 45.2116,
    "lng": 127.962,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Heilongjiang",
    "capital": "minor",
    "population": 585386,
    "id": 1156107332
  },
  {
    "city": "Botou",
    "city_ascii": "Botou",
    "lat": 38.06,
    "lng": 116.56,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hebei",
    "capital": "",
    "population": 584308,
    "id": 1156018338
  },
  {
    "city": "Bucaramanga",
    "city_ascii": "Bucaramanga",
    "lat": 7.1186,
    "lng": -73.1161,
    "country": "Colombia",
    "iso2": "CO",
    "iso3": "COL",
    "admin_name": "Santander",
    "capital": "admin",
    "population": 581130,
    "id": 1170940590
  },
  {
    "city": "Anyang",
    "city_ascii": "Anyang",
    "lat": 37.3925,
    "lng": 126.9269,
    "country": "Korea, South",
    "iso2": "KR",
    "iso3": "KOR",
    "admin_name": "Gyeonggi",
    "capital": "",
    "population": 573296,
    "id": 1410581095
  },
  {
    "city": "Genoa",
    "city_ascii": "Genoa",
    "lat": 44.4072,
    "lng": 8.934,
    "country": "Italy",
    "iso2": "IT",
    "iso3": "ITA",
    "admin_name": "Liguria",
    "capital": "admin",
    "population": 580097,
    "id": 1380716540
  },
  {
    "city": "Kuiju",
    "city_ascii": "Kuiju",
    "lat": 36.8528,
    "lng": 119.3904,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Shandong",
    "capital": "minor",
    "population": 581470,
    "id": 1156174997
  },
  {
    "city": "Tlaquepaque",
    "city_ascii": "Tlaquepaque",
    "lat": 20.6167,
    "lng": -103.3167,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Jalisco",
    "capital": "minor",
    "population": 575942,
    "id": 1484087945
  },
  {
    "city": "Hachiōji",
    "city_ascii": "Hachioji",
    "lat": 35.6667,
    "lng": 139.3167,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Tōkyō",
    "capital": "",
    "population": 577560,
    "id": 1392172935
  },
  {
    "city": "Dehra Dūn",
    "city_ascii": "Dehra Dun",
    "lat": 30.318,
    "lng": 78.029,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Uttarakhand",
    "capital": "admin",
    "population": 578420,
    "id": 1356970407
  },
  {
    "city": "Aţ Ţā’if",
    "city_ascii": "At Ta'if",
    "lat": 21.2667,
    "lng": 40.4167,
    "country": "Saudi Arabia",
    "iso2": "SA",
    "iso3": "SAU",
    "admin_name": "Makkah al Mukarramah",
    "capital": "",
    "population": 579970,
    "id": 1682956102
  },
  {
    "city": "San Jose del Monte",
    "city_ascii": "San Jose del Monte",
    "lat": 14.8139,
    "lng": 121.0453,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Bulacan",
    "capital": "",
    "population": 574089,
    "id": 1608850554
  },
  {
    "city": "Bakersfield",
    "city_ascii": "Bakersfield",
    "lat": 35.3529,
    "lng": -119.0359,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 579295,
    "id": 1840019148
  },
  {
    "city": "Ogden",
    "city_ascii": "Ogden",
    "lat": 41.2278,
    "lng": -111.9682,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Utah",
    "capital": "",
    "population": 578523,
    "id": 1840020135
  },
  {
    "city": "Xiulin",
    "city_ascii": "Xiulin",
    "lat": 29.7211,
    "lng": 112.4037,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hubei",
    "capital": "",
    "population": 577022,
    "id": 1156284109
  },
  {
    "city": "Fu’an",
    "city_ascii": "Fu'an",
    "lat": 27.1,
    "lng": 119.6333,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Fujian",
    "capital": "minor",
    "population": 577000,
    "id": 1156244234
  },
  {
    "city": "Málaga",
    "city_ascii": "Malaga",
    "lat": 36.7167,
    "lng": -4.4167,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Andalusia",
    "capital": "minor",
    "population": 574654,
    "id": 1724397372
  },
  {
    "city": "Tomsk",
    "city_ascii": "Tomsk",
    "lat": 56.4886,
    "lng": 84.9522,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Tomskaya Oblast’",
    "capital": "admin",
    "population": 572740,
    "id": 1643258928
  },
  {
    "city": "Kermān",
    "city_ascii": "Kerman",
    "lat": 30.2833,
    "lng": 57.0667,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Kermān",
    "capital": "admin",
    "population": 573449,
    "id": 1364554896
  },
  {
    "city": "Kingston upon Hull",
    "city_ascii": "Kingston upon Hull",
    "lat": 53.7444,
    "lng": -0.3325,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Kingston upon Hull, City of",
    "capital": "",
    "population": 573300,
    "id": 1826028894
  },
  {
    "city": "Al Maḩallah al Kubrá",
    "city_ascii": "Al Mahallah al Kubra",
    "lat": 30.9667,
    "lng": 31.1667,
    "country": "Egypt",
    "iso2": "EG",
    "iso3": "EGY",
    "admin_name": "Al Gharbīyah",
    "capital": "",
    "population": 543271,
    "id": 1818406471
  },
  {
    "city": "Luofeng",
    "city_ascii": "Luofeng",
    "lat": 37.359,
    "lng": 120.396,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Shandong",
    "capital": "minor",
    "population": 570898,
    "id": 1156158643
  },
  {
    "city": "Lingyuan",
    "city_ascii": "Lingyuan",
    "lat": 41.2407,
    "lng": 119.3957,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Liaoning",
    "capital": "",
    "population": 570660,
    "id": 1156184681
  },
  {
    "city": "Baton Rouge",
    "city_ascii": "Baton Rouge",
    "lat": 30.4418,
    "lng": -91.131,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "admin",
    "population": 570308,
    "id": 1840013941
  },
  {
    "city": "Pereira",
    "city_ascii": "Pereira",
    "lat": 4.8104,
    "lng": -75.68,
    "country": "Colombia",
    "iso2": "CO",
    "iso3": "COL",
    "admin_name": "Risaralda",
    "capital": "admin",
    "population": 568750,
    "id": 1170386718
  },
  {
    "city": "Ciudad Bolívar",
    "city_ascii": "Ciudad Bolivar",
    "lat": 8.1167,
    "lng": -63.55,
    "country": "Venezuela",
    "iso2": "VE",
    "iso3": "VEN",
    "admin_name": "Bolívar",
    "capital": "admin",
    "population": 567953,
    "id": 1862318264
  },
  {
    "city": "Durgāpur",
    "city_ascii": "Durgapur",
    "lat": 23.55,
    "lng": 87.32,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "West Bengal",
    "capital": "",
    "population": 566517,
    "id": 1356045686
  },
  {
    "city": "Orenburg",
    "city_ascii": "Orenburg",
    "lat": 51.7667,
    "lng": 55.1,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Orenburgskaya Oblast’",
    "capital": "admin",
    "population": 564443,
    "id": 1643685056
  },
  {
    "city": "Shenzhou",
    "city_ascii": "Shenzhou",
    "lat": 38.0005,
    "lng": 115.5541,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hebei",
    "capital": "",
    "population": 566087,
    "id": 1156921843
  },
  {
    "city": "Āsansol",
    "city_ascii": "Asansol",
    "lat": 23.6833,
    "lng": 86.9667,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "West Bengal",
    "capital": "",
    "population": 563917,
    "id": 1356093694
  },
  {
    "city": "Bacolod",
    "city_ascii": "Bacolod",
    "lat": 10.6765,
    "lng": 122.9509,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Bacolod",
    "capital": "admin",
    "population": 561875,
    "id": 1608244137
  },
  {
    "city": "Akron",
    "city_ascii": "Akron",
    "lat": 41.0798,
    "lng": -81.5219,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 565208,
    "id": 1840000791
  },
  {
    "city": "New Haven",
    "city_ascii": "New Haven",
    "lat": 41.3112,
    "lng": -72.9246,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 564830,
    "id": 1840004850
  },
  {
    "city": "Zhenzhou",
    "city_ascii": "Zhenzhou",
    "lat": 32.2739,
    "lng": 119.1619,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Jiangsu",
    "capital": "minor",
    "population": 564021,
    "id": 1156634387
  },
  {
    "city": "Surakarta",
    "city_ascii": "Surakarta",
    "lat": -7.5667,
    "lng": 110.8167,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Jawa Tengah",
    "capital": "",
    "population": 552118,
    "id": 1360853933
  },
  {
    "city": "Tlajomulco de Zúñiga",
    "city_ascii": "Tlajomulco de Zuniga",
    "lat": 20.4736,
    "lng": -103.4431,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Jalisco",
    "capital": "minor",
    "population": 549442,
    "id": 1484017493
  },
  {
    "city": "Jieshou",
    "city_ascii": "Jieshou",
    "lat": 33.2605,
    "lng": 115.3618,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Anhui",
    "capital": "",
    "population": 561956,
    "id": 1156304827
  },
  {
    "city": "Lanxi",
    "city_ascii": "Lanxi",
    "lat": 29.2167,
    "lng": 119.4722,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Zhejiang",
    "capital": "",
    "population": 560514,
    "id": 1156686465
  },
  {
    "city": "Zāhedān",
    "city_ascii": "Zahedan",
    "lat": 29.4833,
    "lng": 60.8667,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Sīstān va Balūchestān",
    "capital": "admin",
    "population": 560725,
    "id": 1364072353
  },
  {
    "city": "Dangyang",
    "city_ascii": "Dangyang",
    "lat": 30.8258,
    "lng": 111.791,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hubei",
    "capital": "",
    "population": 560000,
    "id": 1156585505
  },
  {
    "city": "Columbia",
    "city_ascii": "Columbia",
    "lat": 34.0376,
    "lng": -80.9037,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "admin",
    "population": 560041,
    "id": 1840014730
  },
  {
    "city": "Kemerovo",
    "city_ascii": "Kemerovo",
    "lat": 55.3333,
    "lng": 86.0667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kemerovskaya Oblast’",
    "capital": "admin",
    "population": 556920,
    "id": 1643216311
  },
  {
    "city": "Dresden",
    "city_ascii": "Dresden",
    "lat": 51.0493,
    "lng": 13.7384,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "admin",
    "population": 555805,
    "id": 1276561036
  },
  {
    "city": "Uyo",
    "city_ascii": "Uyo",
    "lat": 5.008,
    "lng": 7.85,
    "country": "Nigeria",
    "iso2": "NG",
    "iso3": "NGA",
    "admin_name": "Akwa Ibom",
    "capital": "admin",
    "population": 554906,
    "id": 1566061694
  },
  {
    "city": "Bhāvnagar",
    "city_ascii": "Bhavnagar",
    "lat": 21.765,
    "lng": 72.1369,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Gujarāt",
    "capital": "",
    "population": 554978,
    "id": 1356982375
  },
  {
    "city": "Luanzhou",
    "city_ascii": "Luanzhou",
    "lat": 39.7396,
    "lng": 118.6978,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hebei",
    "capital": "minor",
    "population": 554315,
    "id": 1156092104
  },
  {
    "city": "Veracruz",
    "city_ascii": "Veracruz",
    "lat": 19.1903,
    "lng": -96.1533,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Veracruz",
    "capital": "minor",
    "population": 552156,
    "id": 1484992153
  },
  {
    "city": "Novokuznetsk",
    "city_ascii": "Novokuznetsk",
    "lat": 53.75,
    "lng": 87.1167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kemerovskaya Oblast’",
    "capital": "",
    "population": 552105,
    "id": 1643628717
  },
  {
    "city": "Nellore",
    "city_ascii": "Nellore",
    "lat": 14.4333,
    "lng": 79.9667,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Andhra Pradesh",
    "capital": "",
    "population": 547621,
    "id": 1356280972
  },
  {
    "city": "Chiclayo",
    "city_ascii": "Chiclayo",
    "lat": -6.763,
    "lng": -79.8366,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "Lambayeque",
    "capital": "admin",
    "population": 552508,
    "id": 1604799615
  },
  {
    "city": "Al Ḩudaydah",
    "city_ascii": "Al Hudaydah",
    "lat": 14.8022,
    "lng": 42.9511,
    "country": "Yemen",
    "iso2": "YE",
    "iso3": "YEM",
    "admin_name": "Al Ḩudaydah",
    "capital": "admin",
    "population": 548433,
    "id": 1887984890
  },
  {
    "city": "Eslāmshahr",
    "city_ascii": "Eslamshahr",
    "lat": 35.5333,
    "lng": 51.2,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Tehrān",
    "capital": "minor",
    "population": 548620,
    "id": 1364870126
  },
  {
    "city": "Cabinda",
    "city_ascii": "Cabinda",
    "lat": -5.55,
    "lng": 12.2,
    "country": "Angola",
    "iso2": "AO",
    "iso3": "AGO",
    "admin_name": "Cabinda",
    "capital": "admin",
    "population": 550000,
    "id": 1024474012
  },
  {
    "city": "Amrāvati",
    "city_ascii": "Amravati",
    "lat": 20.9333,
    "lng": 77.75,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Mahārāshtra",
    "capital": "",
    "population": 549370,
    "id": 1356462099
  },
  {
    "city": "Korla",
    "city_ascii": "Korla",
    "lat": 41.7646,
    "lng": 86.1527,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Xinjiang",
    "capital": "minor",
    "population": 549324,
    "id": 1156965620
  },
  {
    "city": "Huanghua",
    "city_ascii": "Huanghua",
    "lat": 38.371,
    "lng": 117.3329,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hebei",
    "capital": "",
    "population": 548507,
    "id": 1156131099
  },
  {
    "city": "Xingcheng",
    "city_ascii": "Xingcheng",
    "lat": 40.6189,
    "lng": 120.7205,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Liaoning",
    "capital": "",
    "population": 546176,
    "id": 1156705922
  },
  {
    "city": "Wancheng",
    "city_ascii": "Wancheng",
    "lat": 18.8,
    "lng": 110.4,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hainan",
    "capital": "minor",
    "population": 545597,
    "id": 1156017483
  },
  {
    "city": "Kaiyuan",
    "city_ascii": "Kaiyuan",
    "lat": 42.538,
    "lng": 124.0371,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Liaoning",
    "capital": "",
    "population": 545624,
    "id": 1156081841
  },
  {
    "city": "Leipzig",
    "city_ascii": "Leipzig",
    "lat": 51.35,
    "lng": 12.3833,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "minor",
    "population": 542529,
    "id": 1276563678
  },
  {
    "city": "Fengcheng",
    "city_ascii": "Fengcheng",
    "lat": 40.4543,
    "lng": 124.0646,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Liaoning",
    "capital": "",
    "population": 543933,
    "id": 1156519626
  },
  {
    "city": "Ajmer",
    "city_ascii": "Ajmer",
    "lat": 26.468,
    "lng": 74.639,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Rājasthān",
    "capital": "",
    "population": 542321,
    "id": 1356058258
  },
  {
    "city": "Sihui",
    "city_ascii": "Sihui",
    "lat": 23.3448,
    "lng": 112.6956,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Guangdong",
    "capital": "",
    "population": 542873,
    "id": 1156609451
  },
  {
    "city": "Tinnevelly",
    "city_ascii": "Tinnevelly",
    "lat": 8.7289,
    "lng": 77.7081,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Tamil Nādu",
    "capital": "",
    "population": 542200,
    "id": 1356037155
  },
  {
    "city": "Fuding",
    "city_ascii": "Fuding",
    "lat": 27.2,
    "lng": 120.2,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Fujian",
    "capital": "minor",
    "population": 542000,
    "id": 1156530120
  },
  {
    "city": "Maturín",
    "city_ascii": "Maturin",
    "lat": 9.7457,
    "lng": -63.1832,
    "country": "Venezuela",
    "iso2": "VE",
    "iso3": "VEN",
    "admin_name": "Monagas",
    "capital": "admin",
    "population": 542259,
    "id": 1862546949
  },
  {
    "city": "An Nāşirīyah",
    "city_ascii": "An Nasiriyah",
    "lat": 31.0539,
    "lng": 46.2503,
    "country": "Iraq",
    "iso2": "IQ",
    "iso3": "IRQ",
    "admin_name": "Dhī Qār",
    "capital": "admin",
    "population": 541600,
    "id": 1368273311
  },
  {
    "city": "Al Ḩillah",
    "city_ascii": "Al Hillah",
    "lat": 32.4833,
    "lng": 44.4333,
    "country": "Iraq",
    "iso2": "IQ",
    "iso3": "IRQ",
    "admin_name": "Bābil",
    "capital": "admin",
    "population": 541034,
    "id": 1368916692
  },
  {
    "city": "Ibagué",
    "city_ascii": "Ibague",
    "lat": 4.4378,
    "lng": -75.2006,
    "country": "Colombia",
    "iso2": "CO",
    "iso3": "COL",
    "admin_name": "Tolima",
    "capital": "admin",
    "population": 541101,
    "id": 1170245063
  },
  {
    "city": "Hannover",
    "city_ascii": "Hannover",
    "lat": 52.3744,
    "lng": 9.7386,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "admin",
    "population": 538068,
    "id": 1276457707
  },
  {
    "city": "Poznań",
    "city_ascii": "Poznan",
    "lat": 52.4,
    "lng": 16.9167,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Wielkopolskie",
    "capital": "admin",
    "population": 538633,
    "id": 1616725607
  },
  {
    "city": "Ryazan",
    "city_ascii": "Ryazan",
    "lat": 54.6167,
    "lng": 39.7167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Ryazanskaya Oblast’",
    "capital": "admin",
    "population": 537622,
    "id": 1643205787
  },
  {
    "city": "Panshi",
    "city_ascii": "Panshi",
    "lat": 42.9392,
    "lng": 126.0561,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Jilin",
    "capital": "",
    "population": 539804,
    "id": 1156659612
  },
  {
    "city": "Kassala",
    "city_ascii": "Kassala",
    "lat": 15.45,
    "lng": 36.4,
    "country": "Sudan",
    "iso2": "SD",
    "iso3": "SDN",
    "admin_name": "Kassala",
    "capital": "admin",
    "population": 536009,
    "id": 1729157224
  },
  {
    "city": "Chang’an",
    "city_ascii": "Chang'an",
    "lat": 29.4761,
    "lng": 113.448,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hunan",
    "capital": "minor",
    "population": 537500,
    "id": 1156139784
  },
  {
    "city": "Wencheng",
    "city_ascii": "Wencheng",
    "lat": 19.6167,
    "lng": 110.75,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hainan",
    "capital": "minor",
    "population": 537426,
    "id": 1156985313
  },
  {
    "city": "Shashi",
    "city_ascii": "Shashi",
    "lat": 30.32,
    "lng": 112.23,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hubei",
    "capital": "",
    "population": 520000,
    "id": 1156254380
  },
  {
    "city": "Aksu",
    "city_ascii": "Aksu",
    "lat": 41.1664,
    "lng": 80.25,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Xinjiang",
    "capital": "minor",
    "population": 535657,
    "id": 1156436913
  },
  {
    "city": "Salta",
    "city_ascii": "Salta",
    "lat": -24.7883,
    "lng": -65.4106,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Salta",
    "capital": "admin",
    "population": 535303,
    "id": 1032926124
  },
  {
    "city": "Kimhae",
    "city_ascii": "Kimhae",
    "lat": 35.2342,
    "lng": 128.8811,
    "country": "Korea, South",
    "iso2": "KR",
    "iso3": "KOR",
    "admin_name": "Gyeongnam",
    "capital": "",
    "population": 534124,
    "id": 1410836094
  },
  {
    "city": "Astrakhan",
    "city_ascii": "Astrakhan",
    "lat": 46.3333,
    "lng": 48.0167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Astrakhanskaya Oblast’",
    "capital": "admin",
    "population": 532504,
    "id": 1643407929
  },
  {
    "city": "Mingguang",
    "city_ascii": "Mingguang",
    "lat": 32.7816,
    "lng": 117.9899,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Anhui",
    "capital": "",
    "population": 532732,
    "id": 1156864248
  },
  {
    "city": "Naberezhnyye Chelny",
    "city_ascii": "Naberezhnyye Chelny",
    "lat": 55.7,
    "lng": 52.3333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Tatarstan",
    "capital": "",
    "population": 529797,
    "id": 1643008493
  },
  {
    "city": "Antwerp",
    "city_ascii": "Antwerp",
    "lat": 51.2211,
    "lng": 4.3997,
    "country": "Belgium",
    "iso2": "BE",
    "iso3": "BEL",
    "admin_name": "Flanders",
    "capital": "minor",
    "population": 529247,
    "id": 1056168623
  },
  {
    "city": "Bīkaner",
    "city_ascii": "Bikaner",
    "lat": 28.0181,
    "lng": 73.3169,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Rājasthān",
    "capital": "",
    "population": 529007,
    "id": 1356026593
  },
  {
    "city": "Agartala",
    "city_ascii": "Agartala",
    "lat": 23.8333,
    "lng": 91.2667,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Tripura",
    "capital": "admin",
    "population": 522613,
    "id": 1356148992
  },
  {
    "city": "Xalapa",
    "city_ascii": "Xalapa",
    "lat": 19.54,
    "lng": -96.9275,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Veracruz",
    "capital": "admin",
    "population": 525147,
    "id": 1484334948
  },
  {
    "city": "Ndola",
    "city_ascii": "Ndola",
    "lat": -12.9683,
    "lng": 28.6337,
    "country": "Zambia",
    "iso2": "ZM",
    "iso3": "ZMB",
    "admin_name": "Copperbelt",
    "capital": "admin",
    "population": 528330,
    "id": 1894032745
  },
  {
    "city": "Hamadān",
    "city_ascii": "Hamadan",
    "lat": 34.8065,
    "lng": 48.5162,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Hamadān",
    "capital": "admin",
    "population": 528256,
    "id": 1364126377
  },
  {
    "city": "Villavicencio",
    "city_ascii": "Villavicencio",
    "lat": 4.1425,
    "lng": -73.6294,
    "country": "Colombia",
    "iso2": "CO",
    "iso3": "COL",
    "admin_name": "Meta",
    "capital": "admin",
    "population": 527668,
    "id": 1170426927
  },
  {
    "city": "Ailan Mubage",
    "city_ascii": "Ailan Mubage",
    "lat": 43.9167,
    "lng": 81.3167,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Xinjiang",
    "capital": "minor",
    "population": 526745,
    "id": 1156279608
  },
  {
    "city": "Ensenada",
    "city_ascii": "Ensenada",
    "lat": 31.8578,
    "lng": -116.6058,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Baja California",
    "capital": "minor",
    "population": 522768,
    "id": 1484714297
  },
  {
    "city": "Lyon",
    "city_ascii": "Lyon",
    "lat": 45.76,
    "lng": 4.84,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Auvergne-Rhône-Alpes",
    "capital": "admin",
    "population": 516092,
    "id": 1250196189
  },
  {
    "city": "Bắc Ninh",
    "city_ascii": "Bac Ninh",
    "lat": 21.1861,
    "lng": 106.0763,
    "country": "Vietnam",
    "iso2": "VN",
    "iso3": "VNM",
    "admin_name": "Bắc Ninh",
    "capital": "admin",
    "population": 520000,
    "id": 1704025181
  },
  {
    "city": "Ciudad Apodaca",
    "city_ascii": "Ciudad Apodaca",
    "lat": 25.7833,
    "lng": -100.1833,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Nuevo León",
    "capital": "minor",
    "population": 523370,
    "id": 1484855080
  },
  {
    "city": "Santa Teresa del Tuy",
    "city_ascii": "Santa Teresa del Tuy",
    "lat": 10.2333,
    "lng": -66.65,
    "country": "Venezuela",
    "iso2": "VE",
    "iso3": "VEN",
    "admin_name": "Miranda",
    "capital": "minor",
    "population": 525321,
    "id": 1862528581
  },
  {
    "city": "Londrina",
    "city_ascii": "Londrina",
    "lat": -23.3,
    "lng": -51.18,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Paraná",
    "capital": "",
    "population": 520238,
    "id": 1076723241
  },
  {
    "city": "Penza",
    "city_ascii": "Penza",
    "lat": 53.2,
    "lng": 45,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Penzenskaya Oblast’",
    "capital": "admin",
    "population": 523726,
    "id": 1643492830
  },
  {
    "city": "Heroica Matamoros",
    "city_ascii": "Heroica Matamoros",
    "lat": 25.8797,
    "lng": -97.5042,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Tamaulipas",
    "capital": "minor",
    "population": 520367,
    "id": 1484614906
  },
  {
    "city": "Port Said",
    "city_ascii": "Port Said",
    "lat": 31.25,
    "lng": 32.2833,
    "country": "Egypt",
    "iso2": "EG",
    "iso3": "EGY",
    "admin_name": "Būr Sa‘īd",
    "capital": "admin",
    "population": 524433,
    "id": 1818628574
  },
  {
    "city": "New Mirpur",
    "city_ascii": "New Mirpur",
    "lat": 33.1333,
    "lng": 73.75,
    "country": "Pakistan",
    "iso2": "PK",
    "iso3": "PAK",
    "admin_name": "Azad Kashmir",
    "capital": "minor",
    "population": 523500,
    "id": 1586007078
  },
  {
    "city": "Yucheng",
    "city_ascii": "Yucheng",
    "lat": 36.9329,
    "lng": 116.6403,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Shandong",
    "capital": "",
    "population": 522878,
    "id": 1156530681
  },
  {
    "city": "Bello",
    "city_ascii": "Bello",
    "lat": 6.33,
    "lng": -75.57,
    "country": "Colombia",
    "iso2": "CO",
    "iso3": "COL",
    "admin_name": "Antioquia",
    "capital": "minor",
    "population": 519670,
    "id": 1170423489
  },
  {
    "city": "Meknès",
    "city_ascii": "Meknes",
    "lat": 33.8833,
    "lng": -5.55,
    "country": "Morocco",
    "iso2": "MA",
    "iso3": "MAR",
    "admin_name": "Fès-Meknès",
    "capital": "",
    "population": 520428,
    "id": 1504333193
  },
  {
    "city": "Nuremberg",
    "city_ascii": "Nuremberg",
    "lat": 49.4528,
    "lng": 11.0778,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "minor",
    "population": 518365,
    "id": 1276166609
  },
  {
    "city": "Pohang",
    "city_ascii": "Pohang",
    "lat": 36.0322,
    "lng": 129.365,
    "country": "Korea, South",
    "iso2": "KR",
    "iso3": "KOR",
    "admin_name": "Gyeongbuk",
    "capital": "minor",
    "population": 520305,
    "id": 1410983252
  },
  {
    "city": "Utsunomiya",
    "city_ascii": "Utsunomiya",
    "lat": 36.55,
    "lng": 139.8833,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Tochigi",
    "capital": "admin",
    "population": 519255,
    "id": 1392506125
  },
  {
    "city": "Anda",
    "city_ascii": "Anda",
    "lat": 46.4078,
    "lng": 125.3252,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Heilongjiang",
    "capital": "minor",
    "population": 520000,
    "id": 1156142150
  },
  {
    "city": "Jinghong",
    "city_ascii": "Jinghong",
    "lat": 22.0057,
    "lng": 100.7948,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Yunnan",
    "capital": "",
    "population": 519935,
    "id": 1156181533
  },
  {
    "city": "Liaoyuan",
    "city_ascii": "Liaoyuan",
    "lat": 42.8976,
    "lng": 125.1381,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Jilin",
    "capital": "",
    "population": 506548,
    "id": 1156249772
  },
  {
    "city": "Mesa",
    "city_ascii": "Mesa",
    "lat": 33.4017,
    "lng": -111.718,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 518012,
    "id": 1840020566
  },
  {
    "city": "Surrey",
    "city_ascii": "Surrey",
    "lat": 49.19,
    "lng": -122.8489,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "British Columbia",
    "capital": "",
    "population": 517887,
    "id": 1124001454
  },
  {
    "city": "Cuautitlán Izcalli",
    "city_ascii": "Cuautitlan Izcalli",
    "lat": 19.65,
    "lng": -99.25,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "México",
    "capital": "minor",
    "population": 511675,
    "id": 1484282338
  },
  {
    "city": "Ujjain",
    "city_ascii": "Ujjain",
    "lat": 23.1828,
    "lng": 75.7772,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Madhya Pradesh",
    "capital": "",
    "population": 515215,
    "id": 1356050735
  },
  {
    "city": "Santa Marta",
    "city_ascii": "Santa Marta",
    "lat": 11.2361,
    "lng": -74.2017,
    "country": "Colombia",
    "iso2": "CO",
    "iso3": "COL",
    "admin_name": "Magdalena",
    "capital": "admin",
    "population": 515556,
    "id": 1170715594
  },
  {
    "city": "Beining",
    "city_ascii": "Beining",
    "lat": 41.5933,
    "lng": 121.7932,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Liaoning",
    "capital": "",
    "population": 514898,
    "id": 1156424678
  },
  {
    "city": "Hải Dương",
    "city_ascii": "Hai Duong",
    "lat": 20.9411,
    "lng": 106.3331,
    "country": "Vietnam",
    "iso2": "VN",
    "iso3": "VNM",
    "admin_name": "Hải Dương",
    "capital": "admin",
    "population": 507469,
    "id": 1704909566
  },
  {
    "city": "Carrefour",
    "city_ascii": "Carrefour",
    "lat": 18.5333,
    "lng": -72.4,
    "country": "Haiti",
    "iso2": "HT",
    "iso3": "HTI",
    "admin_name": "Ouest",
    "capital": "",
    "population": 511345,
    "id": 1332000098
  },
  {
    "city": "Homyel’",
    "city_ascii": "Homyel'",
    "lat": 52.4417,
    "lng": 30.9833,
    "country": "Belarus",
    "iso2": "BY",
    "iso3": "BLR",
    "admin_name": "Homyel’skaya Voblasts’",
    "capital": "admin",
    "population": 510300,
    "id": 1112580227
  },
  {
    "city": "Leicester",
    "city_ascii": "Leicester",
    "lat": 52.6333,
    "lng": -1.1333,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Leicester",
    "capital": "",
    "population": 508916,
    "id": 1826431946
  },
  {
    "city": "Yanji",
    "city_ascii": "Yanji",
    "lat": 42.9044,
    "lng": 129.5067,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Jilin",
    "capital": "",
    "population": 513072,
    "id": 1156405468
  },
  {
    "city": "Benguela",
    "city_ascii": "Benguela",
    "lat": -12.55,
    "lng": 13.4167,
    "country": "Angola",
    "iso2": "AO",
    "iso3": "AGO",
    "admin_name": "Benguela",
    "capital": "admin",
    "population": 513000,
    "id": 1024141961
  },
  {
    "city": "Yicheng",
    "city_ascii": "Yicheng",
    "lat": 31.7117,
    "lng": 112.2551,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hubei",
    "capital": "minor",
    "population": 512530,
    "id": 1156742818
  },
  {
    "city": "Tabūk",
    "city_ascii": "Tabuk",
    "lat": 28.3972,
    "lng": 36.5789,
    "country": "Saudi Arabia",
    "iso2": "SA",
    "iso3": "SAU",
    "admin_name": "Tabūk",
    "capital": "admin",
    "population": 512629,
    "id": 1682590992
  },
  {
    "city": "Lipetsk",
    "city_ascii": "Lipetsk",
    "lat": 52.6167,
    "lng": 39.6,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Lipetskaya Oblast’",
    "capital": "admin",
    "population": 510439,
    "id": 1643772906
  },
  {
    "city": "Ulhāsnagar",
    "city_ascii": "Ulhasnagar",
    "lat": 19.2167,
    "lng": 73.15,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Mahārāshtra",
    "capital": "",
    "population": 506098,
    "id": 1356375341
  },
  {
    "city": "Matsuyama",
    "city_ascii": "Matsuyama",
    "lat": 33.8333,
    "lng": 132.7667,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Ehime",
    "capital": "admin",
    "population": 509139,
    "id": 1392220893
  },
  {
    "city": "Muntinlupa City",
    "city_ascii": "Muntinlupa City",
    "lat": 14.3833,
    "lng": 121.05,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Muntinlupa",
    "capital": "admin",
    "population": 504509,
    "id": 1608473406
  },
  {
    "city": "Kashgar",
    "city_ascii": "Kashgar",
    "lat": 39.45,
    "lng": 75.9833,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Xinjiang",
    "capital": "minor",
    "population": 506640,
    "id": 1156113193
  },
  {
    "city": "Linghai",
    "city_ascii": "Linghai",
    "lat": 41.1676,
    "lng": 121.3558,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Liaoning",
    "capital": "",
    "population": 508079,
    "id": 1156081300
  },
  {
    "city": "Aden",
    "city_ascii": "Aden",
    "lat": 12.8,
    "lng": 45.0333,
    "country": "Yemen",
    "iso2": "YE",
    "iso3": "YEM",
    "admin_name": "‘Adan",
    "capital": "admin",
    "population": 507355,
    "id": 1887867886
  },
  {
    "city": "Jhānsi",
    "city_ascii": "Jhansi",
    "lat": 25.4486,
    "lng": 78.5696,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Uttar Pradesh",
    "capital": "",
    "population": 507000,
    "id": 1356712664
  },
  {
    "city": "Kitwe",
    "city_ascii": "Kitwe",
    "lat": -12.8208,
    "lng": 28.2119,
    "country": "Zambia",
    "iso2": "ZM",
    "iso3": "ZMB",
    "admin_name": "Copperbelt",
    "capital": "",
    "population": 504194,
    "id": 1894321752
  },
  {
    "city": "Aba",
    "city_ascii": "Aba",
    "lat": 5.1167,
    "lng": 7.3667,
    "country": "Nigeria",
    "iso2": "NG",
    "iso3": "NGA",
    "admin_name": "Abia",
    "capital": "minor",
    "population": 500183,
    "id": 1566262000
  },
  {
    "city": "Palm Bay",
    "city_ascii": "Palm Bay",
    "lat": 27.955,
    "lng": -80.6627,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 507002,
    "id": 1840015094
  },
  {
    "city": "Pingtung",
    "city_ascii": "Pingtung",
    "lat": 22.6761,
    "lng": 120.4942,
    "country": "Taiwan",
    "iso2": "TW",
    "iso3": "TWN",
    "admin_name": "Pingtung",
    "capital": "admin",
    "population": 503530,
    "id": 1158209038
  },
  {
    "city": "Samarkand",
    "city_ascii": "Samarkand",
    "lat": 39.6542,
    "lng": 66.9597,
    "country": "Uzbekistan",
    "iso2": "UZ",
    "iso3": "UZB",
    "admin_name": "Samarqand",
    "capital": "admin",
    "population": 504423,
    "id": 1860388706
  },
  {
    "city": "Davangere",
    "city_ascii": "Davangere",
    "lat": 14.4667,
    "lng": 75.9167,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Karnātaka",
    "capital": "",
    "population": 503564,
    "id": 1356445061
  },
  {
    "city": "Ichikawa",
    "city_ascii": "Ichikawa",
    "lat": 35.7219,
    "lng": 139.9311,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Chiba",
    "capital": "",
    "population": 495592,
    "id": 1392076347
  },
  {
    "city": "Jammu",
    "city_ascii": "Jammu",
    "lat": 32.7333,
    "lng": 74.85,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Jammu and Kashmīr",
    "capital": "",
    "population": 502197,
    "id": 1356491738
  },
  {
    "city": "Mazatlán",
    "city_ascii": "Mazatlan",
    "lat": 23.22,
    "lng": -106.42,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Sinaloa",
    "capital": "minor",
    "population": 502547,
    "id": 1484841466
  },
  {
    "city": "Higashi-ōsaka",
    "city_ascii": "Higashi-osaka",
    "lat": 34.6794,
    "lng": 135.6008,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Ōsaka",
    "capital": "",
    "population": 494640,
    "id": 1392117396
  },
  {
    "city": "Ile-Ife",
    "city_ascii": "Ile-Ife",
    "lat": 7.4667,
    "lng": 4.5667,
    "country": "Nigeria",
    "iso2": "NG",
    "iso3": "NGA",
    "admin_name": "Osun",
    "capital": "minor",
    "population": 501952,
    "id": 1566756897
  },
  {
    "city": "Kirov",
    "city_ascii": "Kirov",
    "lat": 58.6,
    "lng": 49.65,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kirovskaya Oblast’",
    "capital": "admin",
    "population": 501468,
    "id": 1643106064
  },
  {
    "city": "Madīnat as Sādis min Uktūbar",
    "city_ascii": "Madinat as Sadis min Uktubar",
    "lat": 29.9361,
    "lng": 30.9269,
    "country": "Egypt",
    "iso2": "EG",
    "iso3": "EGY",
    "admin_name": "Al Jīzah",
    "capital": "",
    "population": 500000,
    "id": 1818735651
  },
  {
    "city": "Qaraghandy",
    "city_ascii": "Qaraghandy",
    "lat": 49.8,
    "lng": 73.1167,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Qaraghandy",
    "capital": "admin",
    "population": 501129,
    "id": 1398274508
  },
  {
    "city": "Mazatán",
    "city_ascii": "Mazatan",
    "lat": 29.0167,
    "lng": -110.1333,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Sonora",
    "capital": "minor",
    "population": 500000,
    "id": 1484350756
  },
  {
    "city": "Duisburg",
    "city_ascii": "Duisburg",
    "lat": 51.4322,
    "lng": 6.7611,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "minor",
    "population": 498590,
    "id": 1276977172
  },
  {
    "city": "Mykolaiv",
    "city_ascii": "Mykolaiv",
    "lat": 46.9667,
    "lng": 32,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Mykolayivs’ka Oblast’",
    "capital": "admin",
    "population": 498748,
    "id": 1804398395
  },
  {
    "city": "Matsudo",
    "city_ascii": "Matsudo",
    "lat": 35.7878,
    "lng": 139.9031,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Chiba",
    "capital": "",
    "population": 492671,
    "id": 1392642525
  },
  {
    "city": "Provo",
    "city_ascii": "Provo",
    "lat": 40.2457,
    "lng": -111.6457,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Utah",
    "capital": "",
    "population": 500617,
    "id": 1840020174
  },
  {
    "city": "Meicheng",
    "city_ascii": "Meicheng",
    "lat": 30.6412,
    "lng": 116.5689,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Anhui",
    "capital": "minor",
    "population": 500292,
    "id": 1156136207
  },
  {
    "city": "Niterói",
    "city_ascii": "Niteroi",
    "lat": -22.8833,
    "lng": -43.1036,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Rio de Janeiro",
    "capital": "",
    "population": 496696,
    "id": 1076501425
  },
  {
    "city": "Rouen",
    "city_ascii": "Rouen",
    "lat": 49.4428,
    "lng": 1.0886,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Normandie",
    "capital": "admin",
    "population": 494382,
    "id": 1250372069
  },
  {
    "city": "Oujda-Angad",
    "city_ascii": "Oujda-Angad",
    "lat": 34.6867,
    "lng": -1.9114,
    "country": "Morocco",
    "iso2": "MA",
    "iso3": "MAR",
    "admin_name": "Oriental",
    "capital": "admin",
    "population": 494252,
    "id": 1504891036
  },
  {
    "city": "Johor Bahru",
    "city_ascii": "Johor Bahru",
    "lat": 1.4556,
    "lng": 103.7611,
    "country": "Malaysia",
    "iso2": "MY",
    "iso3": "MYS",
    "admin_name": "Johor",
    "capital": "admin",
    "population": 497097,
    "id": 1458747615
  },
  {
    "city": "Worcester",
    "city_ascii": "Worcester",
    "lat": 42.2705,
    "lng": -71.8079,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 498328,
    "id": 1840000434
  },
  {
    "city": "Hongjiang",
    "city_ascii": "Hongjiang",
    "lat": 27.1167,
    "lng": 109.95,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hunan",
    "capital": "",
    "population": 498100,
    "id": 1156039152
  },
  {
    "city": "Chimbote",
    "city_ascii": "Chimbote",
    "lat": -9.0745,
    "lng": -78.5936,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "Ancash",
    "capital": "",
    "population": 497875,
    "id": 1604199858
  },
  {
    "city": "Dengtacun",
    "city_ascii": "Dengtacun",
    "lat": 41.4237,
    "lng": 123.3203,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Liaoning",
    "capital": "",
    "population": 496122,
    "id": 1156096868
  },
  {
    "city": "Ixtapaluca",
    "city_ascii": "Ixtapaluca",
    "lat": 19.3186,
    "lng": -98.8822,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "México",
    "capital": "minor",
    "population": 495563,
    "id": 1484452937
  },
  {
    "city": "Zhijiang",
    "city_ascii": "Zhijiang",
    "lat": 30.4271,
    "lng": 111.7506,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hubei",
    "capital": "",
    "population": 495995,
    "id": 1156769646
  },
  {
    "city": "Chengjiao",
    "city_ascii": "Chengjiao",
    "lat": 35.4043,
    "lng": 114.0593,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Henan",
    "capital": "minor",
    "population": 495744,
    "id": 1156571332
  },
  {
    "city": "Beipiao",
    "city_ascii": "Beipiao",
    "lat": 41.802,
    "lng": 120.7621,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Liaoning",
    "capital": "",
    "population": 496164,
    "id": 1156378599
  },
  {
    "city": "Murrieta",
    "city_ascii": "Murrieta",
    "lat": 33.572,
    "lng": -117.1909,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 496046,
    "id": 1840020553
  },
  {
    "city": "Kota Bharu",
    "city_ascii": "Kota Bharu",
    "lat": 6.1333,
    "lng": 102.25,
    "country": "Malaysia",
    "iso2": "MY",
    "iso3": "MYS",
    "admin_name": "Kelantan",
    "capital": "admin",
    "population": 491237,
    "id": 1458150101
  },
  {
    "city": "Heshan",
    "city_ascii": "Heshan",
    "lat": 22.7697,
    "lng": 112.9578,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Guangdong",
    "capital": "",
    "population": 494938,
    "id": 1156013872
  },
  {
    "city": "Ciudad López Mateos",
    "city_ascii": "Ciudad Lopez Mateos",
    "lat": 19.55,
    "lng": -99.2833,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "México",
    "capital": "minor",
    "population": 489937,
    "id": 1484363879
  },
  {
    "city": "Vinh",
    "city_ascii": "Vinh",
    "lat": 18.6733,
    "lng": 105.6922,
    "country": "Vietnam",
    "iso2": "VN",
    "iso3": "VNM",
    "admin_name": "Nghệ An",
    "capital": "admin",
    "population": 490000,
    "id": 1704960059
  },
  {
    "city": "Tultitlán de Mariano Escobedo",
    "city_ascii": "Tultitlan de Mariano Escobedo",
    "lat": 19.645,
    "lng": -99.1694,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "México",
    "capital": "minor",
    "population": 486998,
    "id": 1484562374
  },
  {
    "city": "Duyun",
    "city_ascii": "Duyun",
    "lat": 26.2672,
    "lng": 107.5143,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Guizhou",
    "capital": "",
    "population": 493574,
    "id": 1156084618
  },
  {
    "city": "Encheng",
    "city_ascii": "Encheng",
    "lat": 22.1879,
    "lng": 112.3131,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Guangdong",
    "capital": "minor",
    "population": 492814,
    "id": 1156653230
  },
  {
    "city": "Nishinomiya-hama",
    "city_ascii": "Nishinomiya-hama",
    "lat": 34.7333,
    "lng": 135.3333,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hyōgo",
    "capital": "",
    "population": 487413,
    "id": 1392114116
  },
  {
    "city": "Kandahār",
    "city_ascii": "Kandahar",
    "lat": 31.6078,
    "lng": 65.7053,
    "country": "Afghanistan",
    "iso2": "AF",
    "iso3": "AFG",
    "admin_name": "Kandahār",
    "capital": "admin",
    "population": 491500,
    "id": 1004003059
  },
  {
    "city": "Cheboksary",
    "city_ascii": "Cheboksary",
    "lat": 56.1333,
    "lng": 47.25,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Chuvashiya",
    "capital": "admin",
    "population": 489498,
    "id": 1643163382
  },
  {
    "city": "Yuanping",
    "city_ascii": "Yuanping",
    "lat": 38.7299,
    "lng": 112.7134,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Shanxi",
    "capital": "",
    "population": 491213,
    "id": 1156294277
  },
  {
    "city": "Port Sudan",
    "city_ascii": "Port Sudan",
    "lat": 19.6158,
    "lng": 37.2164,
    "country": "Sudan",
    "iso2": "SD",
    "iso3": "SDN",
    "admin_name": "Red Sea",
    "capital": "admin",
    "population": 489275,
    "id": 1729710259
  },
  {
    "city": "Valledupar",
    "city_ascii": "Valledupar",
    "lat": 10.4603,
    "lng": -73.2597,
    "country": "Colombia",
    "iso2": "CO",
    "iso3": "COL",
    "admin_name": "Cesar",
    "capital": "admin",
    "population": 490075,
    "id": 1170481949
  },
  {
    "city": "Edinburgh",
    "city_ascii": "Edinburgh",
    "lat": 55.953,
    "lng": -3.189,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Edinburgh, City of",
    "capital": "",
    "population": 488050,
    "id": 1826492520
  },
  {
    "city": "Belgaum",
    "city_ascii": "Belgaum",
    "lat": 15.8667,
    "lng": 74.5,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Karnātaka",
    "capital": "",
    "population": 488157,
    "id": 1356390535
  },
  {
    "city": "Tula",
    "city_ascii": "Tula",
    "lat": 54.2,
    "lng": 37.6167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Tul’skaya Oblast’",
    "capital": "admin",
    "population": 485221,
    "id": 1643255206
  },
  {
    "city": "Taozhou",
    "city_ascii": "Taozhou",
    "lat": 30.8956,
    "lng": 119.411,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Anhui",
    "capital": "minor",
    "population": 487243,
    "id": 1156438524
  },
  {
    "city": "Suez",
    "city_ascii": "Suez",
    "lat": 29.9667,
    "lng": 32.5333,
    "country": "Egypt",
    "iso2": "EG",
    "iso3": "EGY",
    "admin_name": "As Suways",
    "capital": "admin",
    "population": 485342,
    "id": 1818495972
  },
  {
    "city": "Shahe",
    "city_ascii": "Shahe",
    "lat": 36.8622,
    "lng": 114.502,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hebei",
    "capital": "",
    "population": 486329,
    "id": 1156690538
  },
  {
    "city": "Yazd",
    "city_ascii": "Yazd",
    "lat": 31.8972,
    "lng": 54.3678,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Yazd",
    "capital": "admin",
    "population": 486152,
    "id": 1364429875
  },
  {
    "city": "Nazrēt",
    "city_ascii": "Nazret",
    "lat": 8.55,
    "lng": 39.27,
    "country": "Ethiopia",
    "iso2": "ET",
    "iso3": "ETH",
    "admin_name": "Oromīya",
    "capital": "",
    "population": 476892,
    "id": 1231826873
  },
  {
    "city": "Gaoping",
    "city_ascii": "Gaoping",
    "lat": 35.7911,
    "lng": 112.9259,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Shanxi",
    "capital": "",
    "population": 484862,
    "id": 1156238773
  },
  {
    "city": "Brookhaven",
    "city_ascii": "Brookhaven",
    "lat": 40.8321,
    "lng": -72.9518,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 484671,
    "id": 1840057464
  },
  {
    "city": "Greenville",
    "city_ascii": "Greenville",
    "lat": 34.8353,
    "lng": -82.3647,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 484465,
    "id": 1840013501
  },
  {
    "city": "Arāk",
    "city_ascii": "Arak",
    "lat": 34.08,
    "lng": 49.7,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Markazī",
    "capital": "admin",
    "population": 484212,
    "id": 1364624691
  },
  {
    "city": "San Nicolás de los Garza",
    "city_ascii": "San Nicolas de los Garza",
    "lat": 25.75,
    "lng": -100.2833,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Nuevo León",
    "capital": "minor",
    "population": 476761,
    "id": 1484264138
  },
  {
    "city": "Gulbarga",
    "city_ascii": "Gulbarga",
    "lat": 17.3333,
    "lng": 76.8333,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Karnātaka",
    "capital": "",
    "population": 483615,
    "id": 1356091187
  },
  {
    "city": "Juiz de Fora",
    "city_ascii": "Juiz de Fora",
    "lat": -21.7642,
    "lng": -43.3503,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Minas Gerais",
    "capital": "",
    "population": 470193,
    "id": 1076006144
  },
  {
    "city": "Dunhua",
    "city_ascii": "Dunhua",
    "lat": 43.3667,
    "lng": 128.2333,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Jilin",
    "capital": "minor",
    "population": 483811,
    "id": 1156058709
  },
  {
    "city": "Feira de Santana",
    "city_ascii": "Feira de Santana",
    "lat": -12.25,
    "lng": -38.97,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Bahia",
    "capital": "",
    "population": 481911,
    "id": 1076892244
  },
  {
    "city": "Jiaji",
    "city_ascii": "Jiaji",
    "lat": 19.2431,
    "lng": 110.4642,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hainan",
    "capital": "minor",
    "population": 483217,
    "id": 1156658217
  },
  {
    "city": "Az Zarqā’",
    "city_ascii": "Az Zarqa'",
    "lat": 32.0833,
    "lng": 36.1,
    "country": "Jordan",
    "iso2": "JO",
    "iso3": "JOR",
    "admin_name": "Az Zarqā’",
    "capital": "admin",
    "population": 481300,
    "id": 1400161837
  },
  {
    "city": "Americana",
    "city_ascii": "Americana",
    "lat": -22.7392,
    "lng": -47.3314,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 479472,
    "id": 1076922153
  },
  {
    "city": "Ardabīl",
    "city_ascii": "Ardabil",
    "lat": 38.25,
    "lng": 48.3,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Ardabīl",
    "capital": "admin",
    "population": 482632,
    "id": 1364437616
  },
  {
    "city": "Sylhet",
    "city_ascii": "Sylhet",
    "lat": 24.9,
    "lng": 91.8667,
    "country": "Bangladesh",
    "iso2": "BD",
    "iso3": "BGD",
    "admin_name": "Sylhet",
    "capital": "admin",
    "population": 479837,
    "id": 1050646703
  },
  {
    "city": "Wichita",
    "city_ascii": "Wichita",
    "lat": 37.6896,
    "lng": -97.3442,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kansas",
    "capital": "",
    "population": 482249,
    "id": 1840001686
  },
  {
    "city": "Toledo",
    "city_ascii": "Toledo",
    "lat": 41.6639,
    "lng": -83.5822,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 482111,
    "id": 1840000572
  },
  {
    "city": "Kaihua",
    "city_ascii": "Kaihua",
    "lat": 23.3715,
    "lng": 104.2437,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Yunnan",
    "capital": "minor",
    "population": 481505,
    "id": 1156017759
  },
  {
    "city": "Caerdydd",
    "city_ascii": "Caerdydd",
    "lat": 51.4833,
    "lng": -3.1833,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Cardiff",
    "capital": "",
    "population": 479000,
    "id": 1826362422
  },
  {
    "city": "Jāmnagar",
    "city_ascii": "Jamnagar",
    "lat": 22.47,
    "lng": 70.07,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Gujarāt",
    "capital": "",
    "population": 479920,
    "id": 1356104995
  },
  {
    "city": "Fuyuan",
    "city_ascii": "Fuyuan",
    "lat": 48.3614,
    "lng": 134.2875,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Heilongjiang",
    "capital": "",
    "population": 480000,
    "id": 1156196485
  },
  {
    "city": "Dhūlia",
    "city_ascii": "Dhulia",
    "lat": 20.9,
    "lng": 74.7833,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Mahārāshtra",
    "capital": "",
    "population": 479073,
    "id": 1356224695
  },
  {
    "city": "Nampula",
    "city_ascii": "Nampula",
    "lat": -15.1167,
    "lng": 39.2667,
    "country": "Mozambique",
    "iso2": "MZ",
    "iso3": "MOZ",
    "admin_name": "Nampula",
    "capital": "admin",
    "population": 477900,
    "id": 1508958445
  },
  {
    "city": "Gaya",
    "city_ascii": "Gaya",
    "lat": 24.75,
    "lng": 85.0167,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Bihār",
    "capital": "",
    "population": 474093,
    "id": 1356045727
  },
  {
    "city": "Piraeus",
    "city_ascii": "Piraeus",
    "lat": 37.95,
    "lng": 23.7,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Attikí",
    "capital": "minor",
    "population": 466065,
    "id": 1300458367
  },
  {
    "city": "Ōita",
    "city_ascii": "Oita",
    "lat": 33.2333,
    "lng": 131.6067,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Ōita",
    "capital": "admin",
    "population": 477701,
    "id": 1392081193
  },
  {
    "city": "Florianópolis",
    "city_ascii": "Florianopolis",
    "lat": -27.5933,
    "lng": -48.553,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Santa Catarina",
    "capital": "admin",
    "population": 477798,
    "id": 1076190259
  },
  {
    "city": "Chiniot",
    "city_ascii": "Chiniot",
    "lat": 31.7167,
    "lng": 72.9833,
    "country": "Pakistan",
    "iso2": "PK",
    "iso3": "PAK",
    "admin_name": "Punjab",
    "capital": "minor",
    "population": 477781,
    "id": 1586413435
  },
  {
    "city": "Jiannan",
    "city_ascii": "Jiannan",
    "lat": 31.3446,
    "lng": 104.1994,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Sichuan",
    "capital": "minor",
    "population": 477868,
    "id": 1156155414
  },
  {
    "city": "Wuhai",
    "city_ascii": "Wuhai",
    "lat": 39.6708,
    "lng": 106.8114,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Inner Mongolia",
    "capital": "minor",
    "population": 477000,
    "id": 1156575615
  },
  {
    "city": "Kaliningrad",
    "city_ascii": "Kaliningrad",
    "lat": 54.7167,
    "lng": 20.5,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kaliningradskaya Oblast’",
    "capital": "admin",
    "population": 475056,
    "id": 1643178106
  },
  {
    "city": "Sukkur",
    "city_ascii": "Sukkur",
    "lat": 27.6995,
    "lng": 68.8673,
    "country": "Pakistan",
    "iso2": "PK",
    "iso3": "PAK",
    "admin_name": "Sindh",
    "capital": "minor",
    "population": 476776,
    "id": 1586383591
  },
  {
    "city": "Nangong",
    "city_ascii": "Nangong",
    "lat": 37.3606,
    "lng": 115.3803,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hebei",
    "capital": "",
    "population": 476096,
    "id": 1156426803
  },
  {
    "city": "Staten Island",
    "city_ascii": "Staten Island",
    "lat": 40.5834,
    "lng": -74.1496,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 476143,
    "id": 1840034032
  },
  {
    "city": "San Juan",
    "city_ascii": "San Juan",
    "lat": -31.5375,
    "lng": -68.5364,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "San Juan",
    "capital": "admin",
    "population": 471389,
    "id": 1032869625
  },
  {
    "city": "Vila Velha",
    "city_ascii": "Vila Velha",
    "lat": -20.3364,
    "lng": -40.2936,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Espírito Santo",
    "capital": "",
    "population": 472762,
    "id": 1076404077
  },
  {
    "city": "Macapá",
    "city_ascii": "Macapa",
    "lat": 0.033,
    "lng": -51.0653,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Amapá",
    "capital": "admin",
    "population": 474706,
    "id": 1076189884
  },
  {
    "city": "Des Moines",
    "city_ascii": "Des Moines",
    "lat": 41.5725,
    "lng": -93.6105,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Iowa",
    "capital": "admin",
    "population": 473957,
    "id": 1840007069
  },
  {
    "city": "Piura",
    "city_ascii": "Piura",
    "lat": -5.2008,
    "lng": -80.6253,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "Piura",
    "capital": "admin",
    "population": 473025,
    "id": 1604331641
  },
  {
    "city": "Jiaojiangcun",
    "city_ascii": "Jiaojiangcun",
    "lat": 28.6804,
    "lng": 121.45,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Zhejiang",
    "capital": "",
    "population": 471500,
    "id": 1156812073
  },
  {
    "city": "Laohekou",
    "city_ascii": "Laohekou",
    "lat": 32.3849,
    "lng": 111.6695,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hubei",
    "capital": "",
    "population": 471482,
    "id": 1156791804
  },
  {
    "city": "Fujin",
    "city_ascii": "Fujin",
    "lat": 47.2489,
    "lng": 132.0341,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Heilongjiang",
    "capital": "",
    "population": 470000,
    "id": 1156404715
  },
  {
    "city": "Beian",
    "city_ascii": "Beian",
    "lat": 48.2395,
    "lng": 126.5037,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Heilongjiang",
    "capital": "",
    "population": 470000,
    "id": 1156084414
  },
  {
    "city": "Celaya",
    "city_ascii": "Celaya",
    "lat": 20.5222,
    "lng": -100.8122,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Guanajuato",
    "capital": "minor",
    "population": 468469,
    "id": 1484979555
  },
  {
    "city": "Xiaoyi",
    "city_ascii": "Xiaoyi",
    "lat": 37.1449,
    "lng": 111.7718,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Shanxi",
    "capital": "",
    "population": 468770,
    "id": 1156910270
  },
  {
    "city": "Strasbourg",
    "city_ascii": "Strasbourg",
    "lat": 48.5833,
    "lng": 7.7458,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Grand Est",
    "capital": "admin",
    "population": 465069,
    "id": 1250789140
  },
  {
    "city": "Lanús",
    "city_ascii": "Lanus",
    "lat": -34.7,
    "lng": -58.4,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires",
    "capital": "minor",
    "population": 459263,
    "id": 1032009573
  },
  {
    "city": "Qingzhen",
    "city_ascii": "Qingzhen",
    "lat": 26.5555,
    "lng": 106.4646,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Guizhou",
    "capital": "",
    "population": 467992,
    "id": 1156587033
  },
  {
    "city": "Jiangshan",
    "city_ascii": "Jiangshan",
    "lat": 28.7412,
    "lng": 118.6225,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Zhejiang",
    "capital": "",
    "population": 467862,
    "id": 1156346497
  },
  {
    "city": "Ba‘qūbah",
    "city_ascii": "Ba`qubah",
    "lat": 33.7447,
    "lng": 44.6436,
    "country": "Iraq",
    "iso2": "IQ",
    "iso3": "IRQ",
    "admin_name": "Diyālá",
    "capital": "admin",
    "population": 467900,
    "id": 1368444902
  },
  {
    "city": "Tamale",
    "city_ascii": "Tamale",
    "lat": 9.4075,
    "lng": -0.8533,
    "country": "Ghana",
    "iso2": "GH",
    "iso3": "GHA",
    "admin_name": "Northern",
    "capital": "admin",
    "population": 466723,
    "id": 1288890688
  },
  {
    "city": "Gdańsk",
    "city_ascii": "Gdansk",
    "lat": 54.3667,
    "lng": 18.6333,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Pomorskie",
    "capital": "admin",
    "population": 464254,
    "id": 1616406372
  },
  {
    "city": "Kanazawa",
    "city_ascii": "Kanazawa",
    "lat": 36.5667,
    "lng": 136.65,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Ishikawa",
    "capital": "admin",
    "population": 463873,
    "id": 1392684679
  },
  {
    "city": "Manado",
    "city_ascii": "Manado",
    "lat": 1.4931,
    "lng": 124.8413,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Sulawesi Utara",
    "capital": "admin",
    "population": 461636,
    "id": 1360771278
  },
  {
    "city": "Jinchang",
    "city_ascii": "Jinchang",
    "lat": 38.5168,
    "lng": 102.1866,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Gansu",
    "capital": "minor",
    "population": 464200,
    "id": 1156184861
  },
  {
    "city": "Calabar",
    "city_ascii": "Calabar",
    "lat": 4.95,
    "lng": 8.325,
    "country": "Nigeria",
    "iso2": "NG",
    "iso3": "NGA",
    "admin_name": "Cross River",
    "capital": "admin",
    "population": 461796,
    "id": 1566495489
  },
  {
    "city": "Fukuyama",
    "city_ascii": "Fukuyama",
    "lat": 34.4858,
    "lng": 133.3625,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hiroshima",
    "capital": "",
    "population": 462203,
    "id": 1392551602
  },
  {
    "city": "Long Beach",
    "city_ascii": "Long Beach",
    "lat": 33.7981,
    "lng": -118.1675,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 462628,
    "id": 1840020490
  },
  {
    "city": "Malatya",
    "city_ascii": "Malatya",
    "lat": 38.3554,
    "lng": 38.3337,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Malatya",
    "capital": "admin",
    "population": 461574,
    "id": 1792485155
  },
  {
    "city": "Huế",
    "city_ascii": "Hue",
    "lat": 16.4667,
    "lng": 107.5833,
    "country": "Vietnam",
    "iso2": "VN",
    "iso3": "VNM",
    "admin_name": "Thừa Thiên-Huế",
    "capital": "admin",
    "population": 455230,
    "id": 1704016023
  },
  {
    "city": "Jalgaon",
    "city_ascii": "Jalgaon",
    "lat": 21.0167,
    "lng": 75.5667,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Mahārāshtra",
    "capital": "",
    "population": 460468,
    "id": 1356734842
  },
  {
    "city": "Port St. Lucie",
    "city_ascii": "Port St. Lucie",
    "lat": 27.2796,
    "lng": -80.3883,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 461152,
    "id": 1840015119
  },
  {
    "city": "Mauá",
    "city_ascii": "Maua",
    "lat": -23.6678,
    "lng": -46.4608,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 453286,
    "id": 1076477498
  },
  {
    "city": "Montería",
    "city_ascii": "Monteria",
    "lat": 8.76,
    "lng": -75.8856,
    "country": "Colombia",
    "iso2": "CO",
    "iso3": "COL",
    "admin_name": "Córdoba",
    "capital": "admin",
    "population": 460223,
    "id": 1170855299
  },
  {
    "city": "Tel Aviv-Yafo",
    "city_ascii": "Tel Aviv-Yafo",
    "lat": 32.0833,
    "lng": 34.8,
    "country": "Israel",
    "iso2": "IL",
    "iso3": "ISR",
    "admin_name": "Tel Aviv",
    "capital": "admin",
    "population": 451523,
    "id": 1376401542
  },
  {
    "city": "Xicheng",
    "city_ascii": "Xicheng",
    "lat": 23.3629,
    "lng": 103.1545,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Yunnan",
    "capital": "minor",
    "population": 459781,
    "id": 1156161838
  },
  {
    "city": "Marikina City",
    "city_ascii": "Marikina City",
    "lat": 14.65,
    "lng": 121.1,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Marikina",
    "capital": "admin",
    "population": 450741,
    "id": 1608000077
  },
  {
    "city": "Pyeongtaek",
    "city_ascii": "Pyeongtaek",
    "lat": 36.9947,
    "lng": 127.0889,
    "country": "Korea, South",
    "iso2": "KR",
    "iso3": "KOR",
    "admin_name": "Gyeonggi",
    "capital": "",
    "population": 457873,
    "id": 1410641095
  },
  {
    "city": "Kurnool",
    "city_ascii": "Kurnool",
    "lat": 15.8222,
    "lng": 78.035,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Andhra Pradesh",
    "capital": "",
    "population": 457633,
    "id": 1356058372
  },
  {
    "city": "Sfax",
    "city_ascii": "Sfax",
    "lat": 34.75,
    "lng": 10.72,
    "country": "Tunisia",
    "iso2": "TN",
    "iso3": "TUN",
    "admin_name": "Sfax",
    "capital": "admin",
    "population": 453050,
    "id": 1788741658
  },
  {
    "city": "City of Calamba",
    "city_ascii": "City of Calamba",
    "lat": 14.2167,
    "lng": 121.1667,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Laguna",
    "capital": "",
    "population": 454486,
    "id": 1608985581
  },
  {
    "city": "Denton",
    "city_ascii": "Denton",
    "lat": 33.2176,
    "lng": -97.1419,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 457177,
    "id": 1840019390
  },
  {
    "city": "Ar Ramādī",
    "city_ascii": "Ar Ramadi",
    "lat": 33.4167,
    "lng": 43.3,
    "country": "Iraq",
    "iso2": "IQ",
    "iso3": "IRQ",
    "admin_name": "Al Anbār",
    "capital": "admin",
    "population": 456853,
    "id": 1368838855
  },
  {
    "city": "Melaka",
    "city_ascii": "Melaka",
    "lat": 2.1889,
    "lng": 102.2511,
    "country": "Malaysia",
    "iso2": "MY",
    "iso3": "MYS",
    "admin_name": "Melaka",
    "capital": "admin",
    "population": 455300,
    "id": 1458467506
  },
  {
    "city": "Volta Redonda",
    "city_ascii": "Volta Redonda",
    "lat": -22.5196,
    "lng": -44.095,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Rio de Janeiro",
    "capital": "",
    "population": 456362,
    "id": 1076343513
  },
  {
    "city": "Jian’ou",
    "city_ascii": "Jian'ou",
    "lat": 27.0387,
    "lng": 118.3215,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Fujian",
    "capital": "minor",
    "population": 456000,
    "id": 1156278784
  },
  {
    "city": "Shenmu",
    "city_ascii": "Shenmu",
    "lat": 38.8389,
    "lng": 110.4896,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Shaanxi",
    "capital": "",
    "population": 455493,
    "id": 1156106796
  },
  {
    "city": "Huadian",
    "city_ascii": "Huadian",
    "lat": 42.9688,
    "lng": 126.7388,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Jilin",
    "capital": "",
    "population": 455339,
    "id": 1156018839
  },
  {
    "city": "Taoyuan District",
    "city_ascii": "Taoyuan District",
    "lat": 24.9889,
    "lng": 121.3111,
    "country": "Taiwan",
    "iso2": "TW",
    "iso3": "TWN",
    "admin_name": "Taoyuan",
    "capital": "admin",
    "population": 443273,
    "id": 1158127875
  },
  {
    "city": "Iloilo",
    "city_ascii": "Iloilo",
    "lat": 10.7167,
    "lng": 122.5667,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Iloilo",
    "capital": "admin",
    "population": 447992,
    "id": 1608875987
  },
  {
    "city": "Kota Kinabalu",
    "city_ascii": "Kota Kinabalu",
    "lat": 5.975,
    "lng": 116.0725,
    "country": "Malaysia",
    "iso2": "MY",
    "iso3": "MYS",
    "admin_name": "Sabah",
    "capital": "admin",
    "population": 452058,
    "id": 1458441859
  },
  {
    "city": "Minzhu",
    "city_ascii": "Minzhu",
    "lat": 43.7192,
    "lng": 127.337,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Jilin",
    "capital": "minor",
    "population": 453265,
    "id": 1156295044
  },
  {
    "city": "Rājshāhi",
    "city_ascii": "Rajshahi",
    "lat": 24.3667,
    "lng": 88.6,
    "country": "Bangladesh",
    "iso2": "BD",
    "iso3": "BGD",
    "admin_name": "Rājshāhi",
    "capital": "admin",
    "population": 448087,
    "id": 1050547831
  },
  {
    "city": "Ţanţā",
    "city_ascii": "Tanta",
    "lat": 30.7833,
    "lng": 31,
    "country": "Egypt",
    "iso2": "EG",
    "iso3": "EGY",
    "admin_name": "Al Gharbīyah",
    "capital": "admin",
    "population": 429503,
    "id": 1818501770
  },
  {
    "city": "Balashikha",
    "city_ascii": "Balashikha",
    "lat": 55.8,
    "lng": 37.95,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Moskovskaya Oblast’",
    "capital": "",
    "population": 450771,
    "id": 1643989845
  },
  {
    "city": "Udaipur",
    "city_ascii": "Udaipur",
    "lat": 24.5833,
    "lng": 73.6833,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Rājasthān",
    "capital": "",
    "population": 451100,
    "id": 1356266094
  },
  {
    "city": "Kursk",
    "city_ascii": "Kursk",
    "lat": 51.7372,
    "lng": 36.1872,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kurskaya Oblast’",
    "capital": "admin",
    "population": 449063,
    "id": 1643011077
  },
  {
    "city": "Mariupol",
    "city_ascii": "Mariupol",
    "lat": 47.1306,
    "lng": 37.5639,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Donets’ka Oblast’",
    "capital": "",
    "population": 449498,
    "id": 1804630021
  },
  {
    "city": "Bukavu",
    "city_ascii": "Bukavu",
    "lat": -2.5,
    "lng": 28.8667,
    "country": "Congo (Kinshasa)",
    "iso2": "CD",
    "iso3": "COD",
    "admin_name": "Sud-Kivu",
    "capital": "admin",
    "population": 436779,
    "id": 1180475821
  },
  {
    "city": "Hsinchu",
    "city_ascii": "Hsinchu",
    "lat": 24.8047,
    "lng": 120.9714,
    "country": "Taiwan",
    "iso2": "TW",
    "iso3": "TWN",
    "admin_name": "Hsinchu",
    "capital": "admin",
    "population": 445635,
    "id": 1158276420
  },
  {
    "city": "Barcelona",
    "city_ascii": "Barcelona",
    "lat": 10.1333,
    "lng": -64.6833,
    "country": "Venezuela",
    "iso2": "VE",
    "iso3": "VEN",
    "admin_name": "Anzoátegui",
    "capital": "admin",
    "population": 448016,
    "id": 1862418619
  },
  {
    "city": "Constantine",
    "city_ascii": "Constantine",
    "lat": 36.365,
    "lng": 6.6147,
    "country": "Algeria",
    "iso2": "DZ",
    "iso3": "DZA",
    "admin_name": "Constantine",
    "capital": "admin",
    "population": 448374,
    "id": 1012275076
  },
  {
    "city": "Tanbei",
    "city_ascii": "Tanbei",
    "lat": 35.0907,
    "lng": 112.9317,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Henan",
    "capital": "minor",
    "population": 447701,
    "id": 1156690688
  },
  {
    "city": "Ado-Ekiti",
    "city_ascii": "Ado-Ekiti",
    "lat": 7.6167,
    "lng": 5.2167,
    "country": "Nigeria",
    "iso2": "NG",
    "iso3": "NGA",
    "admin_name": "Ekiti",
    "capital": "admin",
    "population": 446749,
    "id": 1566196391
  },
  {
    "city": "Batman",
    "city_ascii": "Batman",
    "lat": 37.8833,
    "lng": 41.1333,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Batman",
    "capital": "admin",
    "population": 447106,
    "id": 1792630786
  },
  {
    "city": "Pasay City",
    "city_ascii": "Pasay City",
    "lat": 14.55,
    "lng": 121,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Pasay",
    "capital": "admin",
    "population": 416522,
    "id": 1608448280
  },
  {
    "city": "Madison",
    "city_ascii": "Madison",
    "lat": 43.0826,
    "lng": -89.3931,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "admin",
    "population": 447245,
    "id": 1840002915
  },
  {
    "city": "Pingquan",
    "city_ascii": "Pingquan",
    "lat": 41.0042,
    "lng": 118.6848,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hebei",
    "capital": "minor",
    "population": 446939,
    "id": 1156442510
  },
  {
    "city": "Baisha",
    "city_ascii": "Baisha",
    "lat": 29.4774,
    "lng": 119.2853,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Zhejiang",
    "capital": "",
    "population": 446000,
    "id": 1156988315
  },
  {
    "city": "Bellary",
    "city_ascii": "Bellary",
    "lat": 15.15,
    "lng": 76.915,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Karnātaka",
    "capital": "",
    "population": 445388,
    "id": 1356209152
  },
  {
    "city": "Santiago de Cuba",
    "city_ascii": "Santiago de Cuba",
    "lat": 20.0283,
    "lng": -75.8206,
    "country": "Cuba",
    "iso2": "CU",
    "iso3": "CUB",
    "admin_name": "Santiago de Cuba",
    "capital": "admin",
    "population": 444851,
    "id": 1192766111
  },
  {
    "city": "Yongji",
    "city_ascii": "Yongji",
    "lat": 34.867,
    "lng": 110.4417,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Shanxi",
    "capital": "",
    "population": 444724,
    "id": 1156001205
  },
  {
    "city": "Reno",
    "city_ascii": "Reno",
    "lat": 39.5497,
    "lng": -119.8483,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Nevada",
    "capital": "",
    "population": 445020,
    "id": 1840020121
  },
  {
    "city": "Danjiangkou",
    "city_ascii": "Danjiangkou",
    "lat": 32.5408,
    "lng": 111.5098,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hubei",
    "capital": "",
    "population": 443755,
    "id": 1156347994
  },
  {
    "city": "Kahramanmaraş",
    "city_ascii": "Kahramanmaras",
    "lat": 37.5875,
    "lng": 36.9453,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Kahramanmaraş",
    "capital": "admin",
    "population": 443575,
    "id": 1792726797
  },
  {
    "city": "São José do Rio Prêto",
    "city_ascii": "Sao Jose do Rio Preto",
    "lat": -20.82,
    "lng": -49.3789,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 442548,
    "id": 1076760827
  },
  {
    "city": "Andijon",
    "city_ascii": "Andijon",
    "lat": 40.7,
    "lng": 72.35,
    "country": "Uzbekistan",
    "iso2": "UZ",
    "iso3": "UZB",
    "admin_name": "Andijon",
    "capital": "admin",
    "population": 441700,
    "id": 1860445095
  },
  {
    "city": "Harrisburg",
    "city_ascii": "Harrisburg",
    "lat": 40.2752,
    "lng": -76.8843,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "admin",
    "population": 442289,
    "id": 1840001288
  },
  {
    "city": "Nancy",
    "city_ascii": "Nancy",
    "lat": 48.6936,
    "lng": 6.1846,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Grand Est",
    "capital": "minor",
    "population": 434565,
    "id": 1250925250
  },
  {
    "city": "Al Manşūrah",
    "city_ascii": "Al Mansurah",
    "lat": 31.05,
    "lng": 31.3833,
    "country": "Egypt",
    "iso2": "EG",
    "iso3": "EGY",
    "admin_name": "Ad Daqahlīyah",
    "capital": "admin",
    "population": 439348,
    "id": 1818255319
  },
  {
    "city": "Machida",
    "city_ascii": "Machida",
    "lat": 35.5486,
    "lng": 139.4467,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Tōkyō",
    "capital": "",
    "population": 434407,
    "id": 1392788615
  },
  {
    "city": "Ning’an",
    "city_ascii": "Ning'an",
    "lat": 44.3429,
    "lng": 129.4621,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Heilongjiang",
    "capital": "minor",
    "population": 440000,
    "id": 1156629205
  },
  {
    "city": "Beira",
    "city_ascii": "Beira",
    "lat": -19.8333,
    "lng": 34.85,
    "country": "Mozambique",
    "iso2": "MZ",
    "iso3": "MOZ",
    "admin_name": "Sofala",
    "capital": "admin",
    "population": 439264,
    "id": 1508407369
  },
  {
    "city": "Little Rock",
    "city_ascii": "Little Rock",
    "lat": 34.7256,
    "lng": -92.3576,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arkansas",
    "capital": "admin",
    "population": 439815,
    "id": 1840015509
  },
  {
    "city": "Zürich",
    "city_ascii": "Zurich",
    "lat": 47.3786,
    "lng": 8.54,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Zürich",
    "capital": "admin",
    "population": 434008,
    "id": 1756539143
  },
  {
    "city": "Zhangjiakou Shi Xuanhua Qu",
    "city_ascii": "Zhangjiakou Shi Xuanhua Qu",
    "lat": 40.5944,
    "lng": 115.0243,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hebei",
    "capital": "minor",
    "population": 409745,
    "id": 1156240430
  },
  {
    "city": "Sunch’ŏn",
    "city_ascii": "Sunch'on",
    "lat": 39.4167,
    "lng": 125.9333,
    "country": "Korea, North",
    "iso2": "KP",
    "iso3": "PRK",
    "admin_name": "P’yŏngnam",
    "capital": "",
    "population": 437000,
    "id": 1408510801
  },
  {
    "city": "Diadema",
    "city_ascii": "Diadema",
    "lat": -23.6858,
    "lng": -46.6228,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 412428,
    "id": 1076867279
  },
  {
    "city": "Guangming",
    "city_ascii": "Guangming",
    "lat": 45.3357,
    "lng": 122.7765,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Jilin",
    "capital": "minor",
    "population": 437559,
    "id": 1156955625
  },
  {
    "city": "Sāngli",
    "city_ascii": "Sangli",
    "lat": 16.8667,
    "lng": 74.5667,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Mahārāshtra",
    "capital": "",
    "population": 436639,
    "id": 1356921375
  },
  {
    "city": "Tuticorin",
    "city_ascii": "Tuticorin",
    "lat": 8.7833,
    "lng": 78.1333,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Tamil Nādu",
    "capital": "",
    "population": 436094,
    "id": 1356751023
  },
  {
    "city": "Herāt",
    "city_ascii": "Herat",
    "lat": 34.3738,
    "lng": 62.1792,
    "country": "Afghanistan",
    "iso2": "AF",
    "iso3": "AFG",
    "admin_name": "Herāt",
    "capital": "admin",
    "population": 436300,
    "id": 1004237782
  },
  {
    "city": "Kupang",
    "city_ascii": "Kupang",
    "lat": -10.1633,
    "lng": 123.5778,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Nusa Tenggara Timur",
    "capital": "admin",
    "population": 433970,
    "id": 1360696757
  },
  {
    "city": "Larkana",
    "city_ascii": "Larkana",
    "lat": 27.56,
    "lng": 68.2264,
    "country": "Pakistan",
    "iso2": "PK",
    "iso3": "PAK",
    "admin_name": "Sindh",
    "capital": "minor",
    "population": 435817,
    "id": 1586678302
  },
  {
    "city": "Jeju",
    "city_ascii": "Jeju",
    "lat": 33.5097,
    "lng": 126.5219,
    "country": "Korea, South",
    "iso2": "KR",
    "iso3": "KOR",
    "admin_name": "Jeju",
    "capital": "admin",
    "population": 435413,
    "id": 1410364424
  },
  {
    "city": "Bandar ‘Abbās",
    "city_ascii": "Bandar `Abbas",
    "lat": 27.2,
    "lng": 56.25,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Hormozgān",
    "capital": "admin",
    "population": 435751,
    "id": 1364120623
  },
  {
    "city": "Santos",
    "city_ascii": "Santos",
    "lat": -23.9608,
    "lng": -46.3339,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 433966,
    "id": 1076033299
  },
  {
    "city": "Stavropol",
    "city_ascii": "Stavropol",
    "lat": 45.05,
    "lng": 41.9833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Stavropol’skiy Kray",
    "capital": "admin",
    "population": 433577,
    "id": 1643947228
  },
  {
    "city": "Katsina",
    "city_ascii": "Katsina",
    "lat": 12.9908,
    "lng": 7.6017,
    "country": "Nigeria",
    "iso2": "NG",
    "iso3": "NGA",
    "admin_name": "Katsina",
    "capital": "admin",
    "population": 432149,
    "id": 1566777297
  },
  {
    "city": "Yogyakarta",
    "city_ascii": "Yogyakarta",
    "lat": -7.8014,
    "lng": 110.3644,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Yogyakarta",
    "capital": "admin",
    "population": 422732,
    "id": 1360384833
  },
  {
    "city": "Calicut",
    "city_ascii": "Calicut",
    "lat": 11.25,
    "lng": 75.7667,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Kerala",
    "capital": "minor",
    "population": 431560,
    "id": 1356010401
  },
  {
    "city": "Zanjān",
    "city_ascii": "Zanjan",
    "lat": 36.6667,
    "lng": 48.4833,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Zanjān",
    "capital": "admin",
    "population": 433475,
    "id": 1364312712
  },
  {
    "city": "Welkom",
    "city_ascii": "Welkom",
    "lat": -27.9831,
    "lng": 26.7208,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "Free State",
    "capital": "",
    "population": 431944,
    "id": 1710480365
  },
  {
    "city": "Ulan-Ude",
    "city_ascii": "Ulan-Ude",
    "lat": 51.8272,
    "lng": 107.6064,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Buryatiya",
    "capital": "admin",
    "population": 431922,
    "id": 1643774900
  },
  {
    "city": "Oakland",
    "city_ascii": "Oakland",
    "lat": 37.7903,
    "lng": -122.2165,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 433031,
    "id": 1840020296
  },
  {
    "city": "Kashiwa",
    "city_ascii": "Kashiwa",
    "lat": 35.8544,
    "lng": 139.9689,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Chiba",
    "capital": "",
    "population": 429070,
    "id": 1392133166
  },
  {
    "city": "Mazār-e Sharīf",
    "city_ascii": "Mazar-e Sharif",
    "lat": 36.7,
    "lng": 67.1167,
    "country": "Afghanistan",
    "iso2": "AF",
    "iso3": "AFG",
    "admin_name": "Balkh",
    "capital": "admin",
    "population": 427600,
    "id": 1004436363
  },
  {
    "city": "Kāshān",
    "city_ascii": "Kashan",
    "lat": 33.9833,
    "lng": 51.4333,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Eşfahān",
    "capital": "minor",
    "population": 432557,
    "id": 1364006067
  },
  {
    "city": "Kenitra",
    "city_ascii": "Kenitra",
    "lat": 34.25,
    "lng": -6.5833,
    "country": "Morocco",
    "iso2": "MA",
    "iso3": "MAR",
    "admin_name": "Rabat-Salé-Kénitra",
    "capital": "",
    "population": 431282,
    "id": 1504534876
  },
  {
    "city": "Khamīs Mushayţ",
    "city_ascii": "Khamis Mushayt",
    "lat": 18.3,
    "lng": 42.7333,
    "country": "Saudi Arabia",
    "iso2": "SA",
    "iso3": "SAU",
    "admin_name": "‘Asīr",
    "capital": "",
    "population": 430828,
    "id": 1682497044
  },
  {
    "city": "Masan",
    "city_ascii": "Masan",
    "lat": 35.1833,
    "lng": 128.55,
    "country": "Korea, South",
    "iso2": "KR",
    "iso3": "KOR",
    "admin_name": "Gyeongnam",
    "capital": "",
    "population": 429612,
    "id": 1410394650
  },
  {
    "city": "Cusco",
    "city_ascii": "Cusco",
    "lat": -13.5183,
    "lng": -71.9781,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "Cusco",
    "capital": "admin",
    "population": 428450,
    "id": 1604162901
  },
  {
    "city": "Sevastopol",
    "city_ascii": "Sevastopol",
    "lat": 44.6,
    "lng": 33.5333,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Sevastopol’, Misto",
    "capital": "admin",
    "population": 428753,
    "id": 1804806030
  },
  {
    "city": "Mandaluyong City",
    "city_ascii": "Mandaluyong City",
    "lat": 14.5833,
    "lng": 121.0333,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Mandaluyong",
    "capital": "admin",
    "population": 386276,
    "id": 1608166320
  },
  {
    "city": "Kihŭng",
    "city_ascii": "Kihung",
    "lat": 37.4167,
    "lng": 127.1167,
    "country": "Korea, South",
    "iso2": "KR",
    "iso3": "KOR",
    "admin_name": "Gyeonggi",
    "capital": "",
    "population": 425184,
    "id": 1410396744
  },
  {
    "city": "Toyota",
    "city_ascii": "Toyota",
    "lat": 35.0833,
    "lng": 137.1567,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Aichi",
    "capital": "",
    "population": 426477,
    "id": 1392498272
  },
  {
    "city": "Akola",
    "city_ascii": "Akola",
    "lat": 20.7333,
    "lng": 77,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Mahārāshtra",
    "capital": "",
    "population": 425817,
    "id": 1356089496
  },
  {
    "city": "Yan’an Beilu",
    "city_ascii": "Yan'an Beilu",
    "lat": 44.0222,
    "lng": 87.2961,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Xinjiang",
    "capital": "minor",
    "population": 426253,
    "id": 1156048097
  },
  {
    "city": "Agadir",
    "city_ascii": "Agadir",
    "lat": 30.4167,
    "lng": -9.5833,
    "country": "Morocco",
    "iso2": "MA",
    "iso3": "MAR",
    "admin_name": "Souss-Massa",
    "capital": "admin",
    "population": 421844,
    "id": 1504116858
  },
  {
    "city": "Mogi das Cruzes",
    "city_ascii": "Mogi das Cruzes",
    "lat": -23.5228,
    "lng": -46.1931,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 424633,
    "id": 1076857883
  },
  {
    "city": "Durham",
    "city_ascii": "Durham",
    "lat": 35.9794,
    "lng": -78.9031,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 424700,
    "id": 1840013364
  },
  {
    "city": "Likasi",
    "city_ascii": "Likasi",
    "lat": -10.9833,
    "lng": 26.7333,
    "country": "Congo (Kinshasa)",
    "iso2": "CD",
    "iso3": "COD",
    "admin_name": "Haut-Katanga",
    "capital": "",
    "population": 422535,
    "id": 1180978192
  },
  {
    "city": "Laval",
    "city_ascii": "Laval",
    "lat": 45.5833,
    "lng": -73.75,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 422993,
    "id": 1124922301
  },
  {
    "city": "Winston-Salem",
    "city_ascii": "Winston-Salem",
    "lat": 36.1029,
    "lng": -80.2611,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 422269,
    "id": 1840015324
  },
  {
    "city": "Uijeongbu",
    "city_ascii": "Uijeongbu",
    "lat": 37.7486,
    "lng": 127.0389,
    "country": "Korea, South",
    "iso2": "KR",
    "iso3": "KOR",
    "admin_name": "Gyeonggi",
    "capital": "",
    "population": 421579,
    "id": 1410888678
  },
  {
    "city": "Tver",
    "city_ascii": "Tver",
    "lat": 56.8578,
    "lng": 35.9219,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Tverskaya Oblast’",
    "capital": "admin",
    "population": 419363,
    "id": 1643456559
  },
  {
    "city": "Elazığ",
    "city_ascii": "Elazig",
    "lat": 38.6667,
    "lng": 39.2167,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Elazığ",
    "capital": "admin",
    "population": 421726,
    "id": 1792156766
  },
  {
    "city": "Akure",
    "city_ascii": "Akure",
    "lat": 7.25,
    "lng": 5.195,
    "country": "Nigeria",
    "iso2": "NG",
    "iso3": "NGA",
    "admin_name": "Ondo",
    "capital": "admin",
    "population": 420594,
    "id": 1566568277
  },
  {
    "city": "Kumi",
    "city_ascii": "Kumi",
    "lat": 36.21,
    "lng": 128.3544,
    "country": "Korea, South",
    "iso2": "KR",
    "iso3": "KOR",
    "admin_name": "Gyeongbuk",
    "capital": "",
    "population": 421075,
    "id": 1410638233
  },
  {
    "city": "Hpa-An",
    "city_ascii": "Hpa-An",
    "lat": 16.8906,
    "lng": 97.6333,
    "country": "Burma",
    "iso2": "MM",
    "iso3": "MMR",
    "admin_name": "Kayin State",
    "capital": "admin",
    "population": 421525,
    "id": 1104494124
  },
  {
    "city": "Bonita Springs",
    "city_ascii": "Bonita Springs",
    "lat": 26.3559,
    "lng": -81.7861,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 421291,
    "id": 1840014227
  },
  {
    "city": "Hailin",
    "city_ascii": "Hailin",
    "lat": 44.5735,
    "lng": 129.3825,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Heilongjiang",
    "capital": "minor",
    "population": 420000,
    "id": 1156267191
  },
  {
    "city": "Seremban",
    "city_ascii": "Seremban",
    "lat": 2.7297,
    "lng": 101.9381,
    "country": "Malaysia",
    "iso2": "MY",
    "iso3": "MYS",
    "admin_name": "Negeri Sembilan",
    "capital": "admin",
    "population": 419536,
    "id": 1458051507
  },
  {
    "city": "Takamatsu",
    "city_ascii": "Takamatsu",
    "lat": 34.35,
    "lng": 134.05,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Kagawa",
    "capital": "admin",
    "population": 418772,
    "id": 1392427161
  },
  {
    "city": "Lecheng",
    "city_ascii": "Lecheng",
    "lat": 25.1307,
    "lng": 113.3472,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Guangdong",
    "capital": "minor",
    "population": 419500,
    "id": 1156612198
  },
  {
    "city": "Zhengjiatun",
    "city_ascii": "Zhengjiatun",
    "lat": 43.513,
    "lng": 123.5003,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Jilin",
    "capital": "",
    "population": 419529,
    "id": 1156759747
  },
  {
    "city": "Luhansk",
    "city_ascii": "Luhansk",
    "lat": 48.5833,
    "lng": 39.3333,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Luhans’ka Oblast’",
    "capital": "admin",
    "population": 417990,
    "id": 1804688214
  },
  {
    "city": "Pencheng",
    "city_ascii": "Pencheng",
    "lat": 29.6792,
    "lng": 115.6611,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Jiangxi",
    "capital": "",
    "population": 419047,
    "id": 1156147187
  },
  {
    "city": "Magnitogorsk",
    "city_ascii": "Magnitogorsk",
    "lat": 53.3833,
    "lng": 59.0333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Chelyabinskaya Oblast’",
    "capital": "",
    "population": 418241,
    "id": 1643987830
  },
  {
    "city": "Angeles City",
    "city_ascii": "Angeles City",
    "lat": 15.1472,
    "lng": 120.5847,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Angeles",
    "capital": "admin",
    "population": 411634,
    "id": 1608036027
  },
  {
    "city": "El Obeid",
    "city_ascii": "El Obeid",
    "lat": 13.1833,
    "lng": 30.2167,
    "country": "Sudan",
    "iso2": "SD",
    "iso3": "SDN",
    "admin_name": "North Kordofan",
    "capital": "admin",
    "population": 418280,
    "id": 1729215531
  },
  {
    "city": "Dalai",
    "city_ascii": "Dalai",
    "lat": 45.505,
    "lng": 124.2863,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Jilin",
    "capital": "",
    "population": 418232,
    "id": 1156912899
  },
  {
    "city": "Xingren",
    "city_ascii": "Xingren",
    "lat": 25.4352,
    "lng": 105.1907,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Guizhou",
    "capital": "",
    "population": 417919,
    "id": 1156533633
  },
  {
    "city": "Palma",
    "city_ascii": "Palma",
    "lat": 39.5667,
    "lng": 2.6497,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Balearic Islands",
    "capital": "admin",
    "population": 416065,
    "id": 1724728111
  },
  {
    "city": "Kolwezi",
    "city_ascii": "Kolwezi",
    "lat": -10.7167,
    "lng": 25.4667,
    "country": "Congo (Kinshasa)",
    "iso2": "CD",
    "iso3": "COD",
    "admin_name": "Lualaba",
    "capital": "admin",
    "population": 418000,
    "id": 1180380965
  },
  {
    "city": "Wenlan",
    "city_ascii": "Wenlan",
    "lat": 23.3689,
    "lng": 103.3881,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Yunnan",
    "capital": "minor",
    "population": 417156,
    "id": 1156385754
  },
  {
    "city": "Indio",
    "city_ascii": "Indio",
    "lat": 33.7346,
    "lng": -116.2346,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 417059,
    "id": 1840019304
  },
  {
    "city": "Palm Coast",
    "city_ascii": "Palm Coast",
    "lat": 29.5392,
    "lng": -81.246,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 417007,
    "id": 1840015064
  },
  {
    "city": "Arusha",
    "city_ascii": "Arusha",
    "lat": -3.3667,
    "lng": 36.6833,
    "country": "Tanzania",
    "iso2": "TZ",
    "iso3": "TZA",
    "admin_name": "Arusha",
    "capital": "admin",
    "population": 416442,
    "id": 1834484937
  },
  {
    "city": "Fenyang",
    "city_ascii": "Fenyang",
    "lat": 37.2652,
    "lng": 111.7793,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Shanxi",
    "capital": "",
    "population": 416212,
    "id": 1156455704
  },
  {
    "city": "Toyama",
    "city_ascii": "Toyama",
    "lat": 36.7,
    "lng": 137.22,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Toyama",
    "capital": "admin",
    "population": 415816,
    "id": 1392000560
  },
  {
    "city": "Paju",
    "city_ascii": "Paju",
    "lat": 37.8328,
    "lng": 126.8169,
    "country": "Korea, South",
    "iso2": "KR",
    "iso3": "KOR",
    "admin_name": "Gyeonggi",
    "capital": "minor",
    "population": 415345,
    "id": 1410365324
  },
  {
    "city": "Mataram",
    "city_ascii": "Mataram",
    "lat": -8.5833,
    "lng": 116.1167,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Nusa Tenggara Barat",
    "capital": "admin",
    "population": 408900,
    "id": 1360021952
  },
  {
    "city": "Chattanooga",
    "city_ascii": "Chattanooga",
    "lat": 35.0657,
    "lng": -85.2488,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 415501,
    "id": 1840014569
  },
  {
    "city": "Lapu-Lapu City",
    "city_ascii": "Lapu-Lapu City",
    "lat": 10.315,
    "lng": 123.9761,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Lapu-Lapu",
    "capital": "admin",
    "population": 408112,
    "id": 1608430676
  },
  {
    "city": "Nagqu",
    "city_ascii": "Nagqu",
    "lat": 31.4766,
    "lng": 92.0569,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Tibet",
    "capital": "minor",
    "population": 414554,
    "id": 1156260844
  },
  {
    "city": "Kisumu",
    "city_ascii": "Kisumu",
    "lat": -0.1,
    "lng": 34.75,
    "country": "Kenya",
    "iso2": "KE",
    "iso3": "KEN",
    "admin_name": "Kisumu",
    "capital": "admin",
    "population": 409928,
    "id": 1404511920
  },
  {
    "city": "Jayapura",
    "city_ascii": "Jayapura",
    "lat": -2.5333,
    "lng": 140.7167,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Papua",
    "capital": "admin",
    "population": 413283,
    "id": 1360517692
  },
  {
    "city": "Fangting",
    "city_ascii": "Fangting",
    "lat": 31.1282,
    "lng": 104.1695,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Sichuan",
    "capital": "minor",
    "population": 412758,
    "id": 1156384608
  },
  {
    "city": "Nagasaki",
    "city_ascii": "Nagasaki",
    "lat": 32.7833,
    "lng": 129.8667,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Nagasaki",
    "capital": "admin",
    "population": 411421,
    "id": 1392162566
  },
  {
    "city": "Spokane",
    "city_ascii": "Spokane",
    "lat": 47.6671,
    "lng": -117.433,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 412275,
    "id": 1840021093
  },
  {
    "city": "Shekhupura",
    "city_ascii": "Shekhupura",
    "lat": 31.7083,
    "lng": 74,
    "country": "Pakistan",
    "iso2": "PK",
    "iso3": "PAK",
    "admin_name": "Punjab",
    "capital": "minor",
    "population": 411834,
    "id": 1586291726
  },
  {
    "city": "Sochi",
    "city_ascii": "Sochi",
    "lat": 43.5853,
    "lng": 39.7203,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Krasnodarskiy Kray",
    "capital": "",
    "population": 411524,
    "id": 1643009782
  },
  {
    "city": "Bhāgalpur",
    "city_ascii": "Bhagalpur",
    "lat": 25.25,
    "lng": 87.0167,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Bihār",
    "capital": "",
    "population": 410210,
    "id": 1356760990
  },
  {
    "city": "Ipatinga",
    "city_ascii": "Ipatinga",
    "lat": -19.4796,
    "lng": -42.52,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Minas Gerais",
    "capital": "",
    "population": 407894,
    "id": 1076484921
  },
  {
    "city": "Ivanovo",
    "city_ascii": "Ivanovo",
    "lat": 57,
    "lng": 41,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Ivanovskaya Oblast’",
    "capital": "admin",
    "population": 406933,
    "id": 1643297945
  },
  {
    "city": "Osogbo",
    "city_ascii": "Osogbo",
    "lat": 7.7667,
    "lng": 4.5667,
    "country": "Nigeria",
    "iso2": "NG",
    "iso3": "NGA",
    "admin_name": "Osun",
    "capital": "admin",
    "population": 408245,
    "id": 1566395088
  },
  {
    "city": "Imus",
    "city_ascii": "Imus",
    "lat": 14.4297,
    "lng": 120.9367,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Cavite",
    "capital": "",
    "population": 403785,
    "id": 1608802508
  },
  {
    "city": "Ciudad Obregón",
    "city_ascii": "Ciudad Obregon",
    "lat": 27.4939,
    "lng": -109.9389,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Sonora",
    "capital": "minor",
    "population": 405000,
    "id": 1484887986
  },
  {
    "city": "Türkmenabat",
    "city_ascii": "Turkmenabat",
    "lat": 39.1,
    "lng": 63.5667,
    "country": "Turkmenistan",
    "iso2": "TM",
    "iso3": "TKM",
    "admin_name": "Lebap",
    "capital": "admin",
    "population": 408906,
    "id": 1795079064
  },
  {
    "city": "Namangan",
    "city_ascii": "Namangan",
    "lat": 40.9953,
    "lng": 71.6725,
    "country": "Uzbekistan",
    "iso2": "UZ",
    "iso3": "UZB",
    "admin_name": "Namangan",
    "capital": "admin",
    "population": 408500,
    "id": 1860894033
  },
  {
    "city": "Bryansk",
    "city_ascii": "Bryansk",
    "lat": 53.25,
    "lng": 34.3667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Bryanskaya Oblast’",
    "capital": "admin",
    "population": 406553,
    "id": 1643208827
  },
  {
    "city": "Basuo",
    "city_ascii": "Basuo",
    "lat": 19.1,
    "lng": 108.65,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hainan",
    "capital": "minor",
    "population": 408309,
    "id": 1156260069
  },
  {
    "city": "Taiping",
    "city_ascii": "Taiping",
    "lat": 32.0691,
    "lng": 108.0351,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Sichuan",
    "capital": "",
    "population": 407594,
    "id": 1156077074
  },
  {
    "city": "Maracay",
    "city_ascii": "Maracay",
    "lat": 10.2469,
    "lng": -67.5958,
    "country": "Venezuela",
    "iso2": "VE",
    "iso3": "VEN",
    "admin_name": "Aragua",
    "capital": "admin",
    "population": 407109,
    "id": 1862284350
  },
  {
    "city": "Murcia",
    "city_ascii": "Murcia",
    "lat": 37.9866,
    "lng": -1.1415,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Murcia",
    "capital": "admin",
    "population": 406807,
    "id": 1724107739
  },
  {
    "city": "Jiexiu",
    "city_ascii": "Jiexiu",
    "lat": 37.0282,
    "lng": 111.9103,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Shanxi",
    "capital": "",
    "population": 406517,
    "id": 1156275703
  },
  {
    "city": "Mbale",
    "city_ascii": "Mbale",
    "lat": 1.0806,
    "lng": 34.175,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Mbale",
    "capital": "admin",
    "population": 402368,
    "id": 1800265900
  },
  {
    "city": "Taraz",
    "city_ascii": "Taraz",
    "lat": 42.8833,
    "lng": 71.3667,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Zhambyl",
    "capital": "admin",
    "population": 406262,
    "id": 1398626276
  },
  {
    "city": "Asyūţ",
    "city_ascii": "Asyut",
    "lat": 27.1869,
    "lng": 31.1714,
    "country": "Egypt",
    "iso2": "EG",
    "iso3": "EGY",
    "admin_name": "Asyūţ",
    "capital": "admin",
    "population": 389307,
    "id": 1818456592
  },
  {
    "city": "Santa Fe",
    "city_ascii": "Santa Fe",
    "lat": -31.6333,
    "lng": -60.7,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Santa Fe",
    "capital": "admin",
    "population": 405683,
    "id": 1032817575
  },
  {
    "city": "Campina Grande",
    "city_ascii": "Campina Grande",
    "lat": -7.2306,
    "lng": -35.8811,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Paraíba",
    "capital": "",
    "population": 405072,
    "id": 1076218697
  },
  {
    "city": "Szczecin",
    "city_ascii": "Szczecin",
    "lat": 53.4247,
    "lng": 14.5553,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Zachodniopomorskie",
    "capital": "admin",
    "population": 403833,
    "id": 1616256546
  },
  {
    "city": "Ghulja",
    "city_ascii": "Ghulja",
    "lat": 43.9,
    "lng": 81.35,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Xinjiang",
    "capital": "minor",
    "population": 403489,
    "id": 1156064979
  },
  {
    "city": "Syracuse",
    "city_ascii": "Syracuse",
    "lat": 43.0409,
    "lng": -76.1438,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 404223,
    "id": 1840000378
  },
  {
    "city": "Carapicuíba",
    "city_ascii": "Carapicuiba",
    "lat": -23.5228,
    "lng": -46.8358,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 392294,
    "id": 1076874103
  },
  {
    "city": "Gifu",
    "city_ascii": "Gifu",
    "lat": 35.4167,
    "lng": 136.7667,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Gifu",
    "capital": "admin",
    "population": 401534,
    "id": 1392097787
  },
  {
    "city": "Quilon",
    "city_ascii": "Quilon",
    "lat": 8.8853,
    "lng": 76.5864,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Kerala",
    "capital": "",
    "population": 397419,
    "id": 1356304110
  },
  {
    "city": "Jundiaí",
    "city_ascii": "Jundiai",
    "lat": -23.1864,
    "lng": -46.8842,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 401896,
    "id": 1076998392
  },
  {
    "city": "Lancaster",
    "city_ascii": "Lancaster",
    "lat": 40.0421,
    "lng": -76.3012,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 401524,
    "id": 1840003718
  },
  {
    "city": "Eindhoven",
    "city_ascii": "Eindhoven",
    "lat": 51.43,
    "lng": 5.5,
    "country": "Netherlands",
    "iso2": "NL",
    "iso3": "NLD",
    "admin_name": "Noord-Brabant",
    "capital": "minor",
    "population": 398053,
    "id": 1528636212
  },
  {
    "city": "Sīkar",
    "city_ascii": "Sikar",
    "lat": 27.6119,
    "lng": 75.1397,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Rājasthān",
    "capital": "",
    "population": 400000,
    "id": 1356163729
  },
  {
    "city": "Tumkūr",
    "city_ascii": "Tumkur",
    "lat": 13.33,
    "lng": 77.1,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Karnātaka",
    "capital": "",
    "population": 399606,
    "id": 1356033724
  },
  {
    "city": "Jiangjiafan",
    "city_ascii": "Jiangjiafan",
    "lat": 31.0304,
    "lng": 112.1,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hubei",
    "capital": "",
    "population": 400000,
    "id": 1156352568
  },
  {
    "city": "Miyazaki",
    "city_ascii": "Miyazaki",
    "lat": 31.9167,
    "lng": 131.4167,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Miyazaki",
    "capital": "admin",
    "population": 398307,
    "id": 1392216224
  },
  {
    "city": "Arlington",
    "city_ascii": "Arlington",
    "lat": 32.6998,
    "lng": -97.125,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 398854,
    "id": 1840019422
  },
  {
    "city": "Stockton",
    "city_ascii": "Stockton",
    "lat": 37.9765,
    "lng": -121.3109,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 397264,
    "id": 1840021517
  },
  {
    "city": "Bhātpāra",
    "city_ascii": "Bhatpara",
    "lat": 22.8667,
    "lng": 88.4167,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "West Bengal",
    "capital": "",
    "population": 386019,
    "id": 1356022456
  },
  {
    "city": "Sandakan",
    "city_ascii": "Sandakan",
    "lat": 5.8388,
    "lng": 118.1173,
    "country": "Malaysia",
    "iso2": "MY",
    "iso3": "MYS",
    "admin_name": "Sabah",
    "capital": "",
    "population": 396290,
    "id": 1458405180
  },
  {
    "city": "Hejin",
    "city_ascii": "Hejin",
    "lat": 35.5914,
    "lng": 110.706,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Shanxi",
    "capital": "",
    "population": 395527,
    "id": 1156225234
  },
  {
    "city": "Thanh Hóa",
    "city_ascii": "Thanh Hoa",
    "lat": 19.8075,
    "lng": 105.7764,
    "country": "Vietnam",
    "iso2": "VN",
    "iso3": "VNM",
    "admin_name": "Thanh Hóa",
    "capital": "admin",
    "population": 393294,
    "id": 1704016079
  },
  {
    "city": "Muzaffarnagar",
    "city_ascii": "Muzaffarnagar",
    "lat": 29.4708,
    "lng": 77.7033,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Uttar Pradesh",
    "capital": "",
    "population": 392451,
    "id": 1356592356
  },
  {
    "city": "Poughkeepsie",
    "city_ascii": "Poughkeepsie",
    "lat": 41.6949,
    "lng": -73.921,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 394760,
    "id": 1840000500
  },
  {
    "city": "Comilla",
    "city_ascii": "Comilla",
    "lat": 23.4704,
    "lng": 91.17,
    "country": "Bangladesh",
    "iso2": "BD",
    "iso3": "BGD",
    "admin_name": "Chittagong",
    "capital": "",
    "population": 389411,
    "id": 1050609330
  },
  {
    "city": "Metz",
    "city_ascii": "Metz",
    "lat": 49.1203,
    "lng": 6.1778,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Grand Est",
    "capital": "minor",
    "population": 391187,
    "id": 1250778717
  },
  {
    "city": "Campos",
    "city_ascii": "Campos",
    "lat": -21.7523,
    "lng": -41.3304,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Rio de Janeiro",
    "capital": "",
    "population": 387417,
    "id": 1076606365
  },
  {
    "city": "Nha Trang",
    "city_ascii": "Nha Trang",
    "lat": 12.25,
    "lng": 109.1833,
    "country": "Vietnam",
    "iso2": "VN",
    "iso3": "VNM",
    "admin_name": "Khánh Hòa",
    "capital": "admin",
    "population": 392279,
    "id": 1704497901
  },
  {
    "city": "Belgorod",
    "city_ascii": "Belgorod",
    "lat": 50.6,
    "lng": 36.6,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Belgorodskaya Oblast’",
    "capital": "admin",
    "population": 391135,
    "id": 1643407900
  },
  {
    "city": "Yola",
    "city_ascii": "Yola",
    "lat": 9.2,
    "lng": 12.4833,
    "country": "Nigeria",
    "iso2": "NG",
    "iso3": "NGA",
    "admin_name": "Adamawa",
    "capital": "admin",
    "population": 392854,
    "id": 1566782065
  },
  {
    "city": "Minamisuita",
    "city_ascii": "Minamisuita",
    "lat": 34.7667,
    "lng": 135.5167,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Ōsaka",
    "capital": "",
    "population": 381829,
    "id": 1392948850
  },
  {
    "city": "San Lorenzo",
    "city_ascii": "San Lorenzo",
    "lat": -25.3431,
    "lng": -57.5094,
    "country": "Paraguay",
    "iso2": "PY",
    "iso3": "PRY",
    "admin_name": "Central",
    "capital": "",
    "population": 387977,
    "id": 1600667303
  },
  {
    "city": "Ad Dīwānīyah",
    "city_ascii": "Ad Diwaniyah",
    "lat": 31.9892,
    "lng": 44.9247,
    "country": "Iraq",
    "iso2": "IQ",
    "iso3": "IRQ",
    "admin_name": "Al Qādisīyah",
    "capital": "admin",
    "population": 391600,
    "id": 1368864006
  },
  {
    "city": "Hancheng",
    "city_ascii": "Hancheng",
    "lat": 35.4603,
    "lng": 110.4292,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Shaanxi",
    "capital": "",
    "population": 391164,
    "id": 1156265838
  },
  {
    "city": "Fuyu",
    "city_ascii": "Fuyu",
    "lat": 45.1804,
    "lng": 124.82,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Jilin",
    "capital": "",
    "population": 356905,
    "id": 1156595629
  },
  {
    "city": "Bologna",
    "city_ascii": "Bologna",
    "lat": 44.4939,
    "lng": 11.3428,
    "country": "Italy",
    "iso2": "IT",
    "iso3": "ITA",
    "admin_name": "Emilia-Romagna",
    "capital": "admin",
    "population": 388367,
    "id": 1380202039
  },
  {
    "city": "Karamay",
    "city_ascii": "Karamay",
    "lat": 45.5928,
    "lng": 84.8711,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Xinjiang",
    "capital": "minor",
    "population": 391008,
    "id": 1156892128
  },
  {
    "city": "Kākināda",
    "city_ascii": "Kakinada",
    "lat": 16.9333,
    "lng": 82.2167,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Andhra Pradesh",
    "capital": "",
    "population": 384182,
    "id": 1356573609
  },
  {
    "city": "Augusta",
    "city_ascii": "Augusta",
    "lat": 33.3645,
    "lng": -82.0708,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "admin",
    "population": 390809,
    "id": 1840029462
  },
  {
    "city": "Bhīlwāra",
    "city_ascii": "Bhilwara",
    "lat": 25.35,
    "lng": 74.6333,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Rājasthān",
    "capital": "",
    "population": 389911,
    "id": 1356077831
  },
  {
    "city": "Caxias do Sul",
    "city_ascii": "Caxias do Sul",
    "lat": -29.18,
    "lng": -51.17,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Rio Grande do Sul",
    "capital": "",
    "population": 381270,
    "id": 1076809957
  },
  {
    "city": "Tieli",
    "city_ascii": "Tieli",
    "lat": 46.9804,
    "lng": 128.045,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Heilongjiang",
    "capital": "minor",
    "population": 390000,
    "id": 1156769379
  },
  {
    "city": "Cilegon",
    "city_ascii": "Cilegon",
    "lat": -6.0027,
    "lng": 106.0112,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Banten",
    "capital": "",
    "population": 387543,
    "id": 1360643410
  },
  {
    "city": "Baicheng",
    "city_ascii": "Baicheng",
    "lat": 45.6148,
    "lng": 122.832,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Jilin",
    "capital": "minor",
    "population": 386861,
    "id": 1156435764
  },
  {
    "city": "Nizāmābād",
    "city_ascii": "Nizamabad",
    "lat": 18.6704,
    "lng": 78.1,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Telangana",
    "capital": "",
    "population": 388505,
    "id": 1356242168
  },
  {
    "city": "Boise",
    "city_ascii": "Boise",
    "lat": 43.6007,
    "lng": -116.2312,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Idaho",
    "capital": "admin",
    "population": 389280,
    "id": 1840027142
  },
  {
    "city": "Tonalá",
    "city_ascii": "Tonala",
    "lat": 20.6167,
    "lng": -103.2333,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Jalisco",
    "capital": "minor",
    "population": 374258,
    "id": 1484750897
  },
  {
    "city": "Okazaki",
    "city_ascii": "Okazaki",
    "lat": 34.95,
    "lng": 137.1667,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Aichi",
    "capital": "",
    "population": 387294,
    "id": 1392862345
  },
  {
    "city": "Mwanza",
    "city_ascii": "Mwanza",
    "lat": -2.5167,
    "lng": 32.9,
    "country": "Tanzania",
    "iso2": "TZ",
    "iso3": "TZA",
    "admin_name": "Mwanza",
    "capital": "admin",
    "population": 385810,
    "id": 1834011288
  },
  {
    "city": "Aqtöbe",
    "city_ascii": "Aqtobe",
    "lat": 50.3,
    "lng": 57.1667,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Aqtöbe",
    "capital": "admin",
    "population": 387807,
    "id": 1398602716
  },
  {
    "city": "Plovdiv",
    "city_ascii": "Plovdiv",
    "lat": 42.1433,
    "lng": 24.7489,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Plovdiv",
    "capital": "admin",
    "population": 384088,
    "id": 1100436706
  },
  {
    "city": "Oxnard",
    "city_ascii": "Oxnard",
    "lat": 34.1963,
    "lng": -119.1815,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 387647,
    "id": 1840020474
  },
  {
    "city": "Tétouan",
    "city_ascii": "Tetouan",
    "lat": 35.5667,
    "lng": -5.3667,
    "country": "Morocco",
    "iso2": "MA",
    "iso3": "MAR",
    "admin_name": "Tanger-Tétouan-Al Hoceïma",
    "capital": "",
    "population": 380787,
    "id": 1504709141
  },
  {
    "city": "Florence",
    "city_ascii": "Florence",
    "lat": 43.7714,
    "lng": 11.2542,
    "country": "Italy",
    "iso2": "IT",
    "iso3": "ITA",
    "admin_name": "Tuscany",
    "capital": "admin",
    "population": 382258,
    "id": 1380726561
  },
  {
    "city": "Ḩā’il",
    "city_ascii": "Ha'il",
    "lat": 27.5236,
    "lng": 41.7001,
    "country": "Saudi Arabia",
    "iso2": "SA",
    "iso3": "SAU",
    "admin_name": "Ḩā’il",
    "capital": "admin",
    "population": 385257,
    "id": 1682249750
  },
  {
    "city": "Yidu",
    "city_ascii": "Yidu",
    "lat": 30.388,
    "lng": 111.4505,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hubei",
    "capital": "",
    "population": 384598,
    "id": 1156230664
  },
  {
    "city": "Lianzhou",
    "city_ascii": "Lianzhou",
    "lat": 24.7868,
    "lng": 112.3735,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Guangdong",
    "capital": "minor",
    "population": 384700,
    "id": 1156020454
  },
  {
    "city": "Scranton",
    "city_ascii": "Scranton",
    "lat": 41.4044,
    "lng": -75.6649,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 384250,
    "id": 1840003389
  },
  {
    "city": "London",
    "city_ascii": "London",
    "lat": 42.9836,
    "lng": -81.2497,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 383822,
    "id": 1124469960
  },
  {
    "city": "Las Palmas",
    "city_ascii": "Las Palmas",
    "lat": 28.1272,
    "lng": -15.4314,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Canary Islands",
    "capital": "admin",
    "population": 379925,
    "id": 1724501748
  },
  {
    "city": "Rio Branco",
    "city_ascii": "Rio Branco",
    "lat": -9.9781,
    "lng": -67.8117,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Acre",
    "capital": "admin",
    "population": 383443,
    "id": 1076391263
  },
  {
    "city": "Modesto",
    "city_ascii": "Modesto",
    "lat": 37.6374,
    "lng": -121.0028,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 383134,
    "id": 1840020287
  },
  {
    "city": "Ichinomiya",
    "city_ascii": "Ichinomiya",
    "lat": 35.3039,
    "lng": 136.8031,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Aichi",
    "capital": "",
    "population": 379689,
    "id": 1392379530
  },
  {
    "city": "Brno",
    "city_ascii": "Brno",
    "lat": 49.1953,
    "lng": 16.6083,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Jihomoravský Kraj",
    "capital": "admin",
    "population": 381346,
    "id": 1203676514
  },
  {
    "city": "Kissimmee",
    "city_ascii": "Kissimmee",
    "lat": 28.3042,
    "lng": -81.4164,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 382720,
    "id": 1840015109
  },
  {
    "city": "Pasto",
    "city_ascii": "Pasto",
    "lat": 1.2136,
    "lng": -77.2811,
    "country": "Colombia",
    "iso2": "CO",
    "iso3": "COL",
    "admin_name": "Nariño",
    "capital": "admin",
    "population": 382236,
    "id": 1170320545
  },
  {
    "city": "Qazvīn",
    "city_ascii": "Qazvin",
    "lat": 36.2688,
    "lng": 50.0041,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Qazvīn",
    "capital": "admin",
    "population": 381598,
    "id": 1364073420
  },
  {
    "city": "Irapuato",
    "city_ascii": "Irapuato",
    "lat": 20.6667,
    "lng": -101.35,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Guanajuato",
    "capital": "minor",
    "population": 380941,
    "id": 1484625454
  },
  {
    "city": "Novi Sad",
    "city_ascii": "Novi Sad",
    "lat": 45.2644,
    "lng": 19.8317,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Novi Sad",
    "capital": "admin",
    "population": 380000,
    "id": 1688169087
  },
  {
    "city": "Antofagasta",
    "city_ascii": "Antofagasta",
    "lat": -23.6464,
    "lng": -70.398,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Antofagasta",
    "capital": "admin",
    "population": 380695,
    "id": 1152734289
  },
  {
    "city": "Shihezi",
    "city_ascii": "Shihezi",
    "lat": 44.3,
    "lng": 86.0333,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Xinjiang",
    "capital": "minor",
    "population": 380130,
    "id": 1156232270
  },
  {
    "city": "Shache",
    "city_ascii": "Shache",
    "lat": 38.4261,
    "lng": 77.25,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Xinjiang",
    "capital": "",
    "population": 373492,
    "id": 1156278934
  },
  {
    "city": "Pānihāti",
    "city_ascii": "Panihati",
    "lat": 22.69,
    "lng": 88.37,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "West Bengal",
    "capital": "",
    "population": 377347,
    "id": 1356118632
  },
  {
    "city": "Huancayo",
    "city_ascii": "Huancayo",
    "lat": -12.0667,
    "lng": -75.2167,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "Junín",
    "capital": "admin",
    "population": 378203,
    "id": 1604249985
  },
  {
    "city": "Aurora",
    "city_ascii": "Aurora",
    "lat": 39.7087,
    "lng": -104.7273,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 379289,
    "id": 1840018794
  },
  {
    "city": "Malabon",
    "city_ascii": "Malabon",
    "lat": 14.6625,
    "lng": 120.9567,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Malabon",
    "capital": "admin",
    "population": 365525,
    "id": 1608045841
  },
  {
    "city": "Parbhani",
    "city_ascii": "Parbhani",
    "lat": 19.2704,
    "lng": 76.76,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Mahārāshtra",
    "capital": "",
    "population": 378326,
    "id": 1356632842
  },
  {
    "city": "Usulután",
    "city_ascii": "Usulutan",
    "lat": 13.35,
    "lng": -88.45,
    "country": "El Salvador",
    "iso2": "SV",
    "iso3": "SLV",
    "admin_name": "Usulután",
    "capital": "admin",
    "population": 378606,
    "id": 1222984157
  },
  {
    "city": "Youngstown",
    "city_ascii": "Youngstown",
    "lat": 41.0993,
    "lng": -80.6463,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 378732,
    "id": 1840003563
  },
  {
    "city": "Christchurch",
    "city_ascii": "Christchurch",
    "lat": -43.5309,
    "lng": 172.6365,
    "country": "New Zealand",
    "iso2": "NZ",
    "iso3": "NZL",
    "admin_name": "Canterbury",
    "capital": "admin",
    "population": 377200,
    "id": 1554377228
  },
  {
    "city": "Hatay",
    "city_ascii": "Hatay",
    "lat": 36.2,
    "lng": 36.15,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Hatay",
    "capital": "admin",
    "population": 377793,
    "id": 1792115763
  },
  {
    "city": "Iquitos",
    "city_ascii": "Iquitos",
    "lat": -3.7333,
    "lng": -73.25,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "Loreto",
    "capital": "admin",
    "population": 377609,
    "id": 1604493809
  },
  {
    "city": "Sivas",
    "city_ascii": "Sivas",
    "lat": 39.75,
    "lng": 37.0167,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Sivas",
    "capital": "admin",
    "population": 377561,
    "id": 1792938326
  },
  {
    "city": "Helixi",
    "city_ascii": "Helixi",
    "lat": 30.6267,
    "lng": 118.9861,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Anhui",
    "capital": "minor",
    "population": 376857,
    "id": 1156734041
  },
  {
    "city": "A Coruña",
    "city_ascii": "A Coruna",
    "lat": 43.3713,
    "lng": -8.4188,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Galicia",
    "capital": "minor",
    "population": 370610,
    "id": 1724417375
  },
  {
    "city": "Manizales",
    "city_ascii": "Manizales",
    "lat": 5.06,
    "lng": -75.52,
    "country": "Colombia",
    "iso2": "CO",
    "iso3": "COL",
    "admin_name": "Caldas",
    "capital": "admin",
    "population": 375848,
    "id": 1170930654
  },
  {
    "city": "Manukau City",
    "city_ascii": "Manukau City",
    "lat": -36.9833,
    "lng": 174.8833,
    "country": "New Zealand",
    "iso2": "NZ",
    "iso3": "NZL",
    "admin_name": "Auckland",
    "capital": "",
    "population": 375600,
    "id": 1554797148
  },
  {
    "city": "Stoke-on-Trent",
    "city_ascii": "Stoke-on-Trent",
    "lat": 53,
    "lng": -2.1833,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Stoke-on-Trent",
    "capital": "",
    "population": 372775,
    "id": 1826799578
  },
  {
    "city": "Cumaná",
    "city_ascii": "Cumana",
    "lat": 10.45,
    "lng": -64.1667,
    "country": "Venezuela",
    "iso2": "VE",
    "iso3": "VEN",
    "admin_name": "Sucre",
    "capital": "admin",
    "population": 374706,
    "id": 1862583279
  },
  {
    "city": "Vinnytsia",
    "city_ascii": "Vinnytsia",
    "lat": 49.2372,
    "lng": 28.4672,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Vinnyts’ka Oblast’",
    "capital": "admin",
    "population": 371855,
    "id": 1804658571
  },
  {
    "city": "Rohtak",
    "city_ascii": "Rohtak",
    "lat": 28.9,
    "lng": 76.5667,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Haryāna",
    "capital": "",
    "population": 374292,
    "id": 1356162915
  },
  {
    "city": "Lātūr",
    "city_ascii": "Latur",
    "lat": 18.4004,
    "lng": 76.57,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Mahārāshtra",
    "capital": "",
    "population": 374394,
    "id": 1356918265
  },
  {
    "city": "Toyohashi",
    "city_ascii": "Toyohashi",
    "lat": 34.7667,
    "lng": 137.3833,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Aichi",
    "capital": "",
    "population": 373234,
    "id": 1392482960
  },
  {
    "city": "Sanandaj",
    "city_ascii": "Sanandaj",
    "lat": 35.3097,
    "lng": 46.9989,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Kordestān",
    "capital": "admin",
    "population": 373987,
    "id": 1364178783
  },
  {
    "city": "Nuevo Laredo",
    "city_ascii": "Nuevo Laredo",
    "lat": 27.4861,
    "lng": -99.5069,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Tamaulipas",
    "capital": "minor",
    "population": 373725,
    "id": 1484597234
  },
  {
    "city": "Anguo",
    "city_ascii": "Anguo",
    "lat": 38.4177,
    "lng": 115.3204,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hebei",
    "capital": "",
    "population": 370317,
    "id": 1156172518
  },
  {
    "city": "Ambon",
    "city_ascii": "Ambon",
    "lat": -3.705,
    "lng": 128.17,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Maluku",
    "capital": "admin",
    "population": 372249,
    "id": 1360756802
  },
  {
    "city": "Mandaue City",
    "city_ascii": "Mandaue City",
    "lat": 10.3333,
    "lng": 123.9333,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Mandaue",
    "capital": "admin",
    "population": 362654,
    "id": 1608794590
  },
  {
    "city": "Keelung",
    "city_ascii": "Keelung",
    "lat": 25.1283,
    "lng": 121.7419,
    "country": "Taiwan",
    "iso2": "TW",
    "iso3": "TWN",
    "admin_name": "Keelung",
    "capital": "admin",
    "population": 370155,
    "id": 1158820647
  },
  {
    "city": "Varna",
    "city_ascii": "Varna",
    "lat": 43.2078,
    "lng": 27.9169,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Varna",
    "capital": "admin",
    "population": 369162,
    "id": 1100789115
  },
  {
    "city": "Lengshuijiang",
    "city_ascii": "Lengshuijiang",
    "lat": 27.6858,
    "lng": 111.4279,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hunan",
    "capital": "",
    "population": 370300,
    "id": 1156002852
  },
  {
    "city": "Rājapālaiyam",
    "city_ascii": "Rajapalaiyam",
    "lat": 9.4204,
    "lng": 77.58,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Tamil Nādu",
    "capital": "",
    "population": 369991,
    "id": 1356110350
  },
  {
    "city": "Nagano",
    "city_ascii": "Nagano",
    "lat": 36.6486,
    "lng": 138.1928,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Nagano",
    "capital": "admin",
    "population": 370057,
    "id": 1392603439
  },
  {
    "city": "Az Zubayr",
    "city_ascii": "Az Zubayr",
    "lat": 30.3892,
    "lng": 47.7081,
    "country": "Iraq",
    "iso2": "IQ",
    "iso3": "IRQ",
    "admin_name": "Al Başrah",
    "capital": "minor",
    "population": 370000,
    "id": 1368147923
  },
  {
    "city": "Al Qaţīf",
    "city_ascii": "Al Qatif",
    "lat": 26.5196,
    "lng": 50.0115,
    "country": "Saudi Arabia",
    "iso2": "SA",
    "iso3": "SAU",
    "admin_name": "Ash Sharqīyah",
    "capital": "",
    "population": 368892,
    "id": 1682001245
  },
  {
    "city": "Cuernavaca",
    "city_ascii": "Cuernavaca",
    "lat": 18.9186,
    "lng": -99.2342,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Morelos",
    "capital": "admin",
    "population": 366321,
    "id": 1484926471
  },
  {
    "city": "Sanya",
    "city_ascii": "Sanya",
    "lat": 18.2536,
    "lng": 109.5019,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hainan",
    "capital": "minor",
    "population": 362689,
    "id": 1156373900
  },
  {
    "city": "Nicolás Romero",
    "city_ascii": "Nicolas Romero",
    "lat": 19.5833,
    "lng": -99.3667,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "México",
    "capital": "minor",
    "population": 366602,
    "id": 1484589606
  },
  {
    "city": "Huichang",
    "city_ascii": "Huichang",
    "lat": 34.9136,
    "lng": 112.7852,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Henan",
    "capital": "minor",
    "population": 367113,
    "id": 1156035769
  },
  {
    "city": "Vitsyebsk",
    "city_ascii": "Vitsyebsk",
    "lat": 55.1833,
    "lng": 30.1667,
    "country": "Belarus",
    "iso2": "BY",
    "iso3": "BLR",
    "admin_name": "Vitsyebskaya Voblasts’",
    "capital": "admin",
    "population": 364800,
    "id": 1112480679
  },
  {
    "city": "Bauru",
    "city_ascii": "Bauru",
    "lat": -22.3147,
    "lng": -49.0606,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 366992,
    "id": 1076905338
  },
  {
    "city": "Bochum",
    "city_ascii": "Bochum",
    "lat": 51.4833,
    "lng": 7.2167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "minor",
    "population": 364628,
    "id": 1276227288
  },
  {
    "city": "Anápolis",
    "city_ascii": "Anapolis",
    "lat": -16.3269,
    "lng": -48.9528,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Goiás",
    "capital": "",
    "population": 366491,
    "id": 1076208869
  },
  {
    "city": "Coventry",
    "city_ascii": "Coventry",
    "lat": 52.4081,
    "lng": -1.5106,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Coventry",
    "capital": "",
    "population": 362690,
    "id": 1826979198
  },
  {
    "city": "Zalantun",
    "city_ascii": "Zalantun",
    "lat": 48.0033,
    "lng": 122.7365,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Inner Mongolia",
    "capital": "minor",
    "population": 366323,
    "id": 1156280237
  },
  {
    "city": "Tecámac",
    "city_ascii": "Tecamac",
    "lat": 19.7131,
    "lng": -98.9683,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "México",
    "capital": "minor",
    "population": 364579,
    "id": 1484363321
  },
  {
    "city": "Jhang City",
    "city_ascii": "Jhang City",
    "lat": 31.2681,
    "lng": 72.3181,
    "country": "Pakistan",
    "iso2": "PK",
    "iso3": "PAK",
    "admin_name": "Punjab",
    "capital": "minor",
    "population": 365198,
    "id": 1586288677
  },
  {
    "city": "Ciudad General Escobedo",
    "city_ascii": "Ciudad General Escobedo",
    "lat": 25.7933,
    "lng": -100.1583,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Nuevo León",
    "capital": "minor",
    "population": 363436,
    "id": 1484345554
  },
  {
    "city": "Wŏnsan",
    "city_ascii": "Wonsan",
    "lat": 39.1475,
    "lng": 127.4461,
    "country": "Korea, North",
    "iso2": "KP",
    "iso3": "PRK",
    "admin_name": "Kangwŏn",
    "capital": "admin",
    "population": 363127,
    "id": 1408692125
  },
  {
    "city": "Kocaeli",
    "city_ascii": "Kocaeli",
    "lat": 40.7656,
    "lng": 29.9406,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Kocaeli",
    "capital": "admin",
    "population": 363416,
    "id": 1792965114
  },
  {
    "city": "Bengkulu",
    "city_ascii": "Bengkulu",
    "lat": -3.7956,
    "lng": 102.2592,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Bengkulu",
    "capital": "admin",
    "population": 360495,
    "id": 1360191448
  },
  {
    "city": "Montes Claros",
    "city_ascii": "Montes Claros",
    "lat": -16.7322,
    "lng": -43.8636,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Minas Gerais",
    "capital": "",
    "population": 361915,
    "id": 1076113275
  },
  {
    "city": "Pétion-Ville",
    "city_ascii": "Petion-Ville",
    "lat": 18.5098,
    "lng": -72.2856,
    "country": "Haiti",
    "iso2": "HT",
    "iso3": "HTI",
    "admin_name": "Ouest",
    "capital": "",
    "population": 359615,
    "id": 1332051873
  },
  {
    "city": "Shuanghejiedao",
    "city_ascii": "Shuanghejiedao",
    "lat": 30.3866,
    "lng": 106.7756,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Sichuan",
    "capital": "minor",
    "population": 360000,
    "id": 1156052635
  },
  {
    "city": "Bydgoszcz",
    "city_ascii": "Bydgoszcz",
    "lat": 53.1167,
    "lng": 18,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Kujawsko-Pomorskie",
    "capital": "admin",
    "population": 358614,
    "id": 1616454014
  },
  {
    "city": "Surgut",
    "city_ascii": "Surgut",
    "lat": 61.25,
    "lng": 73.4333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Khanty-Mansiyskiy Avtonomnyy Okrug-Yugra",
    "capital": "",
    "population": 360590,
    "id": 1643007475
  },
  {
    "city": "Donostia",
    "city_ascii": "Donostia",
    "lat": 43.3204,
    "lng": -1.98,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Basque Country",
    "capital": "minor",
    "population": 357468,
    "id": 1724910555
  },
  {
    "city": "Bobo-Dioulasso",
    "city_ascii": "Bobo-Dioulasso",
    "lat": 11.1833,
    "lng": -4.2833,
    "country": "Burkina Faso",
    "iso2": "BF",
    "iso3": "BFA",
    "admin_name": "Hauts-Bassins",
    "capital": "admin",
    "population": 360106,
    "id": 1854348796
  },
  {
    "city": "Umuahia",
    "city_ascii": "Umuahia",
    "lat": 5.5333,
    "lng": 7.4833,
    "country": "Nigeria",
    "iso2": "NG",
    "iso3": "NGA",
    "admin_name": "Abia",
    "capital": "admin",
    "population": 359230,
    "id": 1566217980
  },
  {
    "city": "Gedaref",
    "city_ascii": "Gedaref",
    "lat": 14.0333,
    "lng": 35.3833,
    "country": "Sudan",
    "iso2": "SD",
    "iso3": "SDN",
    "admin_name": "Al Gedaref",
    "capital": "admin",
    "population": 354927,
    "id": 1729215057
  },
  {
    "city": "Palu",
    "city_ascii": "Palu",
    "lat": -0.895,
    "lng": 119.8594,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Sulawesi Tengah",
    "capital": "admin",
    "population": 359350,
    "id": 1360518272
  },
  {
    "city": "Santa Rosa",
    "city_ascii": "Santa Rosa",
    "lat": 14.3167,
    "lng": 121.1167,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Laguna",
    "capital": "",
    "population": 353767,
    "id": 1608571683
  },
  {
    "city": "Pokhara",
    "city_ascii": "Pokhara",
    "lat": 28.2097,
    "lng": 83.9853,
    "country": "Nepal",
    "iso2": "NP",
    "iso3": "NPL",
    "admin_name": "Gandakī",
    "capital": "admin",
    "population": 353841,
    "id": 1524416084
  },
  {
    "city": "Mahilyow",
    "city_ascii": "Mahilyow",
    "lat": 53.9089,
    "lng": 30.343,
    "country": "Belarus",
    "iso2": "BY",
    "iso3": "BLR",
    "admin_name": "Mahilyowskaya Voblasts’",
    "capital": "admin",
    "population": 357100,
    "id": 1112518993
  },
  {
    "city": "Wudalianchi",
    "city_ascii": "Wudalianchi",
    "lat": 48.6433,
    "lng": 126.1408,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Heilongjiang",
    "capital": "",
    "population": 360000,
    "id": 1156059331
  },
  {
    "city": "Sungai Petani",
    "city_ascii": "Sungai Petani",
    "lat": 5.65,
    "lng": 100.48,
    "country": "Malaysia",
    "iso2": "MY",
    "iso3": "MYS",
    "admin_name": "Kedah",
    "capital": "",
    "population": 358499,
    "id": 1458639918
  },
  {
    "city": "Nam Định",
    "city_ascii": "Nam Dinh",
    "lat": 20.42,
    "lng": 106.1683,
    "country": "Vietnam",
    "iso2": "VN",
    "iso3": "VNM",
    "admin_name": "Nam Định",
    "capital": "admin",
    "population": 352108,
    "id": 1704906277
  },
  {
    "city": "Sinŭiju",
    "city_ascii": "Sinuiju",
    "lat": 40.1,
    "lng": 124.4,
    "country": "Korea, North",
    "iso2": "KP",
    "iso3": "PRK",
    "admin_name": "P’yŏngbuk",
    "capital": "admin",
    "population": 359341,
    "id": 1408740449
  },
  {
    "city": "Hrodna",
    "city_ascii": "Hrodna",
    "lat": 53.6667,
    "lng": 23.8167,
    "country": "Belarus",
    "iso2": "BY",
    "iso3": "BLR",
    "admin_name": "Hrodzyenskaya Voblasts’",
    "capital": "admin",
    "population": 356900,
    "id": 1112187197
  },
  {
    "city": "Vladimir",
    "city_ascii": "Vladimir",
    "lat": 56.1333,
    "lng": 40.4167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Vladimirskaya Oblast’",
    "capital": "admin",
    "population": 356168,
    "id": 1643712167
  },
  {
    "city": "São Vicente",
    "city_ascii": "Sao Vicente",
    "lat": -23.9633,
    "lng": -46.3922,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 355542,
    "id": 1076857089
  },
  {
    "city": "Wakayama",
    "city_ascii": "Wakayama",
    "lat": 34.2333,
    "lng": 135.1667,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Wakayama",
    "capital": "admin",
    "population": 355825,
    "id": 1392907296
  },
  {
    "city": "Yong’an",
    "city_ascii": "Yong'an",
    "lat": 25.9733,
    "lng": 117.3593,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Fujian",
    "capital": "minor",
    "population": 357000,
    "id": 1156185328
  },
  {
    "city": "Itaquaquecetuba",
    "city_ascii": "Itaquaquecetuba",
    "lat": -23.4864,
    "lng": -46.3486,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 352801,
    "id": 1076942224
  },
  {
    "city": "Wuppertal",
    "city_ascii": "Wuppertal",
    "lat": 51.2667,
    "lng": 7.1833,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "minor",
    "population": 354382,
    "id": 1276004611
  },
  {
    "city": "Minatitlán",
    "city_ascii": "Minatitlan",
    "lat": 17.9833,
    "lng": -94.55,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Veracruz",
    "capital": "minor",
    "population": 356020,
    "id": 1484735713
  },
  {
    "city": "Nizhniy Tagil",
    "city_ascii": "Nizhniy Tagil",
    "lat": 57.9167,
    "lng": 59.9667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sverdlovskaya Oblast’",
    "capital": "",
    "population": 355693,
    "id": 1643992310
  },
  {
    "city": "Nara",
    "city_ascii": "Nara",
    "lat": 34.6833,
    "lng": 135.7833,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Nara",
    "capital": "admin",
    "population": 353989,
    "id": 1392520831
  },
  {
    "city": "Hongzhai",
    "city_ascii": "Hongzhai",
    "lat": 35.0476,
    "lng": 104.6394,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Gansu",
    "capital": "",
    "population": 355037,
    "id": 1156905289
  },
  {
    "city": "Bilbao",
    "city_ascii": "Bilbao",
    "lat": 43.2622,
    "lng": -2.9533,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Basque Country",
    "capital": "minor",
    "population": 346843,
    "id": 1724331267
  },
  {
    "city": "Haarlem",
    "city_ascii": "Haarlem",
    "lat": 52.3804,
    "lng": 4.63,
    "country": "Netherlands",
    "iso2": "NL",
    "iso3": "NLD",
    "admin_name": "Noord-Holland",
    "capital": "admin",
    "population": 349957,
    "id": 1528822178
  },
  {
    "city": "Pavlodar",
    "city_ascii": "Pavlodar",
    "lat": 52.3156,
    "lng": 76.9564,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Pavlodar",
    "capital": "admin",
    "population": 353930,
    "id": 1398790087
  },
  {
    "city": "Gimpo",
    "city_ascii": "Gimpo",
    "lat": 37.6236,
    "lng": 126.7142,
    "country": "Korea, South",
    "iso2": "KR",
    "iso3": "KOR",
    "admin_name": "Gyeonggi",
    "capital": "minor",
    "population": 352683,
    "id": 1410797548
  },
  {
    "city": "Rahimyar Khan",
    "city_ascii": "Rahimyar Khan",
    "lat": 28.4202,
    "lng": 70.2952,
    "country": "Pakistan",
    "iso2": "PK",
    "iso3": "PAK",
    "admin_name": "Punjab",
    "capital": "",
    "population": 353203,
    "id": 1586136399
  },
  {
    "city": "Van",
    "city_ascii": "Van",
    "lat": 38.5019,
    "lng": 43.4167,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Van",
    "capital": "admin",
    "population": 353419,
    "id": 1792940471
  },
  {
    "city": "Corrientes",
    "city_ascii": "Corrientes",
    "lat": -27.4833,
    "lng": -58.8167,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Corrientes",
    "capital": "admin",
    "population": 352646,
    "id": 1032173097
  },
  {
    "city": "San Pedro",
    "city_ascii": "San Pedro",
    "lat": 14.3583,
    "lng": 121.0583,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Laguna",
    "capital": "",
    "population": 325809,
    "id": 1608848483
  },
  {
    "city": "Arkhangelsk",
    "city_ascii": "Arkhangelsk",
    "lat": 64.55,
    "lng": 40.5333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Arkhangel’skaya Oblast’",
    "capital": "admin",
    "population": 351488,
    "id": 1643797198
  },
  {
    "city": "Licheng",
    "city_ascii": "Licheng",
    "lat": 24.4935,
    "lng": 110.3902,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Guangxi",
    "capital": "minor",
    "population": 352472,
    "id": 1156208986
  },
  {
    "city": "Cabimas",
    "city_ascii": "Cabimas",
    "lat": 10.4,
    "lng": -71.4333,
    "country": "Venezuela",
    "iso2": "VE",
    "iso3": "VEN",
    "admin_name": "Zulia",
    "capital": "minor",
    "population": 351735,
    "id": 1862398719
  },
  {
    "city": "Yakeshi",
    "city_ascii": "Yakeshi",
    "lat": 49.2842,
    "lng": 120.7283,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Inner Mongolia",
    "capital": "minor",
    "population": 352173,
    "id": 1156666223
  },
  {
    "city": "Baguio City",
    "city_ascii": "Baguio City",
    "lat": 16.4152,
    "lng": 120.5956,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Baguio",
    "capital": "admin",
    "population": 345366,
    "id": 1608164766
  },
  {
    "city": "Ahmadnagar",
    "city_ascii": "Ahmadnagar",
    "lat": 19.0833,
    "lng": 74.7333,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Mahārāshtra",
    "capital": "",
    "population": 350859,
    "id": 1356972679
  },
  {
    "city": "Fayetteville",
    "city_ascii": "Fayetteville",
    "lat": 36.0713,
    "lng": -94.166,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arkansas",
    "capital": "",
    "population": 351246,
    "id": 1840013368
  },
  {
    "city": "Koshigaya",
    "city_ascii": "Koshigaya",
    "lat": 35.8911,
    "lng": 139.7908,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Saitama",
    "capital": "",
    "population": 345458,
    "id": 1392606124
  },
  {
    "city": "Holguín",
    "city_ascii": "Holguin",
    "lat": 20.8883,
    "lng": -76.2558,
    "country": "Cuba",
    "iso2": "CU",
    "iso3": "CUB",
    "admin_name": "Holguín",
    "capital": "admin",
    "population": 350191,
    "id": 1192920574
  },
  {
    "city": "Qo‘qon",
    "city_ascii": "Qo`qon",
    "lat": 40.5404,
    "lng": 70.94,
    "country": "Uzbekistan",
    "iso2": "UZ",
    "iso3": "UZB",
    "admin_name": "Farg‘ona",
    "capital": "",
    "population": 350000,
    "id": 1860403462
  },
  {
    "city": "Anaheim",
    "city_ascii": "Anaheim",
    "lat": 33.839,
    "lng": -117.8572,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 350365,
    "id": 1840019322
  },
  {
    "city": "Yingmen",
    "city_ascii": "Yingmen",
    "lat": 39.83,
    "lng": 97.73,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Gansu",
    "capital": "",
    "population": 350000,
    "id": 1156386711
  },
  {
    "city": "Piracicaba",
    "city_ascii": "Piracicaba",
    "lat": -22.725,
    "lng": -47.6489,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 342209,
    "id": 1076166252
  },
  {
    "city": "Khorramābād",
    "city_ascii": "Khorramabad",
    "lat": 33.4667,
    "lng": 48.35,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Lorestān",
    "capital": "admin",
    "population": 348216,
    "id": 1364548806
  },
  {
    "city": "Rājahmundry",
    "city_ascii": "Rajahmundry",
    "lat": 16.9833,
    "lng": 81.7833,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Andhra Pradesh",
    "capital": "",
    "population": 341831,
    "id": 1356831716
  },
  {
    "city": "Chita",
    "city_ascii": "Chita",
    "lat": 52.0333,
    "lng": 113.5,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Zabaykal’skiy Kray",
    "capital": "admin",
    "population": 347088,
    "id": 1643036673
  },
  {
    "city": "Sārī",
    "city_ascii": "Sari",
    "lat": 36.5658,
    "lng": 53.0597,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Māzandarān",
    "capital": "admin",
    "population": 347402,
    "id": 1364501878
  },
  {
    "city": "Makiivka",
    "city_ascii": "Makiivka",
    "lat": 48.0556,
    "lng": 37.9611,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Donets’ka Oblast’",
    "capital": "",
    "population": 347376,
    "id": 1804926154
  },
  {
    "city": "Chitungwiza",
    "city_ascii": "Chitungwiza",
    "lat": -18,
    "lng": 31.1,
    "country": "Zimbabwe",
    "iso2": "ZW",
    "iso3": "ZWE",
    "admin_name": "Harare",
    "capital": "",
    "population": 340360,
    "id": 1716688236
  },
  {
    "city": "Pensacola",
    "city_ascii": "Pensacola",
    "lat": 30.4427,
    "lng": -87.1886,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 346921,
    "id": 1840015005
  },
  {
    "city": "Victorville",
    "city_ascii": "Victorville",
    "lat": 34.5277,
    "lng": -117.3536,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 346789,
    "id": 1840021731
  },
  {
    "city": "Tokorozawa",
    "city_ascii": "Tokorozawa",
    "lat": 35.7994,
    "lng": 139.4689,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Saitama",
    "capital": "",
    "population": 341409,
    "id": 1392340764
  },
  {
    "city": "Tanch’ŏn",
    "city_ascii": "Tanch'on",
    "lat": 40.458,
    "lng": 128.911,
    "country": "Korea, North",
    "iso2": "KP",
    "iso3": "PRK",
    "admin_name": "Hamnam",
    "capital": "",
    "population": 345876,
    "id": 1408940912
  },
  {
    "city": "Sumqayıt",
    "city_ascii": "Sumqayit",
    "lat": 40.5917,
    "lng": 49.6397,
    "country": "Azerbaijan",
    "iso2": "AZ",
    "iso3": "AZE",
    "admin_name": "Sumqayıt",
    "capital": "admin",
    "population": 341200,
    "id": 1031630283
  },
  {
    "city": "Kūstī",
    "city_ascii": "Kusti",
    "lat": 13.17,
    "lng": 32.66,
    "country": "Sudan",
    "iso2": "SD",
    "iso3": "SDN",
    "admin_name": "White Nile",
    "capital": "",
    "population": 345068,
    "id": 1729229371
  },
  {
    "city": "Al ‘Amārah",
    "city_ascii": "Al `Amarah",
    "lat": 31.8416,
    "lng": 47.1512,
    "country": "Iraq",
    "iso2": "IQ",
    "iso3": "IRQ",
    "admin_name": "Maysān",
    "capital": "admin",
    "population": 345007,
    "id": 1368336235
  },
  {
    "city": "Cuddapah",
    "city_ascii": "Cuddapah",
    "lat": 14.4667,
    "lng": 78.8167,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Andhra Pradesh",
    "capital": "",
    "population": 344893,
    "id": 1356360140
  },
  {
    "city": "Simferopol",
    "city_ascii": "Simferopol",
    "lat": 44.9484,
    "lng": 34.1,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Krym, Avtonomna Respublika",
    "capital": "admin",
    "population": 341799,
    "id": 1804665958
  },
  {
    "city": "Tarlac City",
    "city_ascii": "Tarlac City",
    "lat": 15.4802,
    "lng": 120.5979,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Tarlac",
    "capital": "admin",
    "population": 342493,
    "id": 1608237027
  },
  {
    "city": "Lancaster",
    "city_ascii": "Lancaster",
    "lat": 34.6935,
    "lng": -118.1753,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 343304,
    "id": 1840020516
  },
  {
    "city": "Greensboro",
    "city_ascii": "Greensboro",
    "lat": 36.0956,
    "lng": -79.8269,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 343090,
    "id": 1840013356
  },
  {
    "city": "Iligan",
    "city_ascii": "Iligan",
    "lat": 8.2333,
    "lng": 124.25,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Iligan",
    "capital": "admin",
    "population": 342618,
    "id": 1608000268
  },
  {
    "city": "East London",
    "city_ascii": "East London",
    "lat": -33.0153,
    "lng": 27.9116,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "Eastern Cape",
    "capital": "",
    "population": 338627,
    "id": 1710178149
  },
  {
    "city": "Ōtsu",
    "city_ascii": "Otsu",
    "lat": 35.0167,
    "lng": 135.85,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Shiga",
    "capital": "admin",
    "population": 342175,
    "id": 1392164106
  },
  {
    "city": "Franca",
    "city_ascii": "Franca",
    "lat": -20.5389,
    "lng": -47.4008,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 342112,
    "id": 1076740256
  },
  {
    "city": "Kaluga",
    "city_ascii": "Kaluga",
    "lat": 54.5333,
    "lng": 36.2667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kaluzhskaya Oblast’",
    "capital": "admin",
    "population": 341892,
    "id": 1643161747
  },
  {
    "city": "Yeosu",
    "city_ascii": "Yeosu",
    "lat": 34.7368,
    "lng": 127.7458,
    "country": "Korea, South",
    "iso2": "KR",
    "iso3": "KOR",
    "admin_name": "Jeonnam",
    "capital": "minor",
    "population": 341994,
    "id": 1410002025
  },
  {
    "city": "Corpus Christi",
    "city_ascii": "Corpus Christi",
    "lat": 27.726,
    "lng": -97.3755,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 342464,
    "id": 1840019718
  },
  {
    "city": "Muzaffarpur",
    "city_ascii": "Muzaffarpur",
    "lat": 26.12,
    "lng": 85.3833,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Bihār",
    "capital": "",
    "population": 333200,
    "id": 1356897425
  },
  {
    "city": "Lublin",
    "city_ascii": "Lublin",
    "lat": 51.2333,
    "lng": 22.5667,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Lubelskie",
    "capital": "admin",
    "population": 339850,
    "id": 1616953501
  },
  {
    "city": "Brest",
    "city_ascii": "Brest",
    "lat": 52.0847,
    "lng": 23.6569,
    "country": "Belarus",
    "iso2": "BY",
    "iso3": "BLR",
    "admin_name": "Brestskaya Voblasts’",
    "capital": "admin",
    "population": 339700,
    "id": 1112896927
  },
  {
    "city": "Lianran",
    "city_ascii": "Lianran",
    "lat": 24.9211,
    "lng": 102.4778,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Yunnan",
    "capital": "minor",
    "population": 341341,
    "id": 1156332794
  },
  {
    "city": "Alwar",
    "city_ascii": "Alwar",
    "lat": 27.5667,
    "lng": 76.6167,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Rājasthān",
    "capital": "",
    "population": 341422,
    "id": 1356570855
  },
  {
    "city": "Baishan",
    "city_ascii": "Baishan",
    "lat": 41.9377,
    "lng": 126.4179,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Jilin",
    "capital": "minor",
    "population": 330000,
    "id": 1156960624
  },
  {
    "city": "Kawagoe",
    "city_ascii": "Kawagoe",
    "lat": 35.9177,
    "lng": 139.4911,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Saitama",
    "capital": "",
    "population": 337931,
    "id": 1392825080
  },
  {
    "city": "Farg‘ona",
    "city_ascii": "Farg`ona",
    "lat": 40.3864,
    "lng": 71.7864,
    "country": "Uzbekistan",
    "iso2": "UZ",
    "iso3": "UZB",
    "admin_name": "Farg‘ona",
    "capital": "admin",
    "population": 340600,
    "id": 1860479627
  },
  {
    "city": "Tamuramachi-moriyama",
    "city_ascii": "Tamuramachi-moriyama",
    "lat": 37.41,
    "lng": 140.38,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Fukushima",
    "capital": "",
    "population": 340560,
    "id": 1392675180
  },
  {
    "city": "Brahmapur",
    "city_ascii": "Brahmapur",
    "lat": 19.32,
    "lng": 84.8,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Odisha",
    "capital": "",
    "population": 324726,
    "id": 1356569354
  },
  {
    "city": "Buôn Ma Thuột",
    "city_ascii": "Buon Ma Thuot",
    "lat": 12.6667,
    "lng": 108.05,
    "country": "Vietnam",
    "iso2": "VN",
    "iso3": "VNM",
    "admin_name": "Đắk Lắk",
    "capital": "admin",
    "population": 340000,
    "id": 1704542086
  },
  {
    "city": "Biñan",
    "city_ascii": "Binan",
    "lat": 14.3333,
    "lng": 121.0833,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Laguna",
    "capital": "",
    "population": 333028,
    "id": 1608039268
  },
  {
    "city": "Iwaki",
    "city_ascii": "Iwaki",
    "lat": 37.0333,
    "lng": 140.8833,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Fukushima",
    "capital": "",
    "population": 340231,
    "id": 1392158088
  },
  {
    "city": "Semey",
    "city_ascii": "Semey",
    "lat": 50.4111,
    "lng": 80.2275,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Shyghys Qazaqstan",
    "capital": "",
    "population": 340000,
    "id": 1398405657
  },
  {
    "city": "Tarsus",
    "city_ascii": "Tarsus",
    "lat": 36.9167,
    "lng": 34.9,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Mersin",
    "capital": "minor",
    "population": 339676,
    "id": 1792003860
  },
  {
    "city": "Randburg",
    "city_ascii": "Randburg",
    "lat": -26.0936,
    "lng": 28.0064,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "Gauteng",
    "capital": "",
    "population": 337053,
    "id": 1710279034
  },
  {
    "city": "Tepic",
    "city_ascii": "Tepic",
    "lat": 21.5083,
    "lng": -104.8931,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Nayarit",
    "capital": "admin",
    "population": 332863,
    "id": 1484433877
  },
  {
    "city": "Jitpur",
    "city_ascii": "Jitpur",
    "lat": 27.6666,
    "lng": 85.3333,
    "country": "Nepal",
    "iso2": "NP",
    "iso3": "NPL",
    "admin_name": "Bāgmatī",
    "capital": "",
    "population": 337785,
    "id": 1524593184
  },
  {
    "city": "Kaesŏng",
    "city_ascii": "Kaesong",
    "lat": 37.964,
    "lng": 126.5644,
    "country": "Korea, North",
    "iso2": "KP",
    "iso3": "PRK",
    "admin_name": "Hwangnam",
    "capital": "minor",
    "population": 338155,
    "id": 1408725697
  },
  {
    "city": "Beni",
    "city_ascii": "Beni",
    "lat": 0.5,
    "lng": 29.4667,
    "country": "Congo (Kinshasa)",
    "iso2": "CD",
    "iso3": "COD",
    "admin_name": "Nord-Kivu",
    "capital": "",
    "population": 332903,
    "id": 1180325051
  },
  {
    "city": "Chinju",
    "city_ascii": "Chinju",
    "lat": 35.1928,
    "lng": 128.0847,
    "country": "Korea, South",
    "iso2": "KR",
    "iso3": "KOR",
    "admin_name": "Gyeongnam",
    "capital": "",
    "population": 337230,
    "id": 1410560599
  },
  {
    "city": "Tangdong",
    "city_ascii": "Tangdong",
    "lat": 25.9755,
    "lng": 113.2302,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hunan",
    "capital": "minor",
    "population": 337495,
    "id": 1156682620
  },
  {
    "city": "Butuan",
    "city_ascii": "Butuan",
    "lat": 8.9534,
    "lng": 125.5288,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Butuan",
    "capital": "admin",
    "population": 337063,
    "id": 1608000722
  },
  {
    "city": "Reading",
    "city_ascii": "Reading",
    "lat": 51.4542,
    "lng": -0.9731,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Reading",
    "capital": "",
    "population": 337108,
    "id": 1826869043
  },
  {
    "city": "Belfast",
    "city_ascii": "Belfast",
    "lat": 54.5964,
    "lng": -5.93,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Belfast",
    "capital": "",
    "population": 333871,
    "id": 1826325087
  },
  {
    "city": "Alicante",
    "city_ascii": "Alicante",
    "lat": 38.3453,
    "lng": -0.4831,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Valencia",
    "capital": "minor",
    "population": 334887,
    "id": 1724923163
  },
  {
    "city": "Blida",
    "city_ascii": "Blida",
    "lat": 36.4722,
    "lng": 2.8333,
    "country": "Algeria",
    "iso2": "DZ",
    "iso3": "DZA",
    "admin_name": "Blida",
    "capital": "admin",
    "population": 331779,
    "id": 1012045589
  },
  {
    "city": "Hangu",
    "city_ascii": "Hangu",
    "lat": 39.232,
    "lng": 117.777,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Tianjin",
    "capital": "",
    "population": 332793,
    "id": 1156202457
  },
  {
    "city": "Yingzhong",
    "city_ascii": "Yingzhong",
    "lat": 32.2381,
    "lng": 119.8133,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Jiangsu",
    "capital": "",
    "population": 334977,
    "id": 1156969537
  },
  {
    "city": "Viña del Mar",
    "city_ascii": "Vina del Mar",
    "lat": -33.0244,
    "lng": -71.5517,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Valparaíso",
    "capital": "",
    "population": 332875,
    "id": 1152898644
  },
  {
    "city": "Asahikawa",
    "city_ascii": "Asahikawa",
    "lat": 43.7706,
    "lng": 142.365,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hokkaidō",
    "capital": "",
    "population": 334692,
    "id": 1392380358
  },
  {
    "city": "Bielefeld",
    "city_ascii": "Bielefeld",
    "lat": 52.0167,
    "lng": 8.5333,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "minor",
    "population": 333786,
    "id": 1276112885
  },
  {
    "city": "Cuenca",
    "city_ascii": "Cuenca",
    "lat": -2.8974,
    "lng": -79.0045,
    "country": "Ecuador",
    "iso2": "EC",
    "iso3": "ECU",
    "admin_name": "Azuay",
    "capital": "admin",
    "population": 329928,
    "id": 1218041020
  },
  {
    "city": "Fort Wayne",
    "city_ascii": "Fort Wayne",
    "lat": 41.0886,
    "lng": -85.1437,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 334122,
    "id": 1840008261
  },
  {
    "city": "Maebashi",
    "city_ascii": "Maebashi",
    "lat": 36.3833,
    "lng": 139.0667,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Gunma",
    "capital": "admin",
    "population": 332999,
    "id": 1392333295
  },
  {
    "city": "Lipa City",
    "city_ascii": "Lipa City",
    "lat": 13.9411,
    "lng": 121.1622,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Batangas",
    "capital": "",
    "population": 332386,
    "id": 1608615113
  },
  {
    "city": "Wad Medani",
    "city_ascii": "Wad Medani",
    "lat": 14.4,
    "lng": 33.51,
    "country": "Sudan",
    "iso2": "SD",
    "iso3": "SDN",
    "admin_name": "Al Gazira",
    "capital": "admin",
    "population": 332714,
    "id": 1729581572
  },
  {
    "city": "Islip",
    "city_ascii": "Islip",
    "lat": 40.7384,
    "lng": -73.1887,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 332862,
    "id": 1840058203
  },
  {
    "city": "Sousse",
    "city_ascii": "Sousse",
    "lat": 35.83,
    "lng": 10.625,
    "country": "Tunisia",
    "iso2": "TN",
    "iso3": "TUN",
    "admin_name": "Sousse",
    "capital": "admin",
    "population": 327004,
    "id": 1788927583
  },
  {
    "city": "Kāmārhāti",
    "city_ascii": "Kamarhati",
    "lat": 22.67,
    "lng": 88.37,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "West Bengal",
    "capital": "",
    "population": 330211,
    "id": 1356676559
  },
  {
    "city": "Thessaloníki",
    "city_ascii": "Thessaloniki",
    "lat": 40.6333,
    "lng": 22.95,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Kentrikí Makedonía",
    "capital": "admin",
    "population": 315196,
    "id": 1300397999
  },
  {
    "city": "Gəncə",
    "city_ascii": "Ganca",
    "lat": 40.6828,
    "lng": 46.3606,
    "country": "Azerbaijan",
    "iso2": "AZ",
    "iso3": "AZE",
    "admin_name": "Gəncə",
    "capital": "admin",
    "population": 331400,
    "id": 1031197327
  },
  {
    "city": "Bilāspur",
    "city_ascii": "Bilaspur",
    "lat": 22.15,
    "lng": 82.0167,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Chhattīsgarh",
    "capital": "",
    "population": 331030,
    "id": 1356820491
  },
  {
    "city": "Santa Ana",
    "city_ascii": "Santa Ana",
    "lat": 33.7366,
    "lng": -117.8819,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 332318,
    "id": 1840021964
  },
  {
    "city": "Mymensingh",
    "city_ascii": "Mymensingh",
    "lat": 24.7504,
    "lng": 90.38,
    "country": "Bangladesh",
    "iso2": "BD",
    "iso3": "BGD",
    "admin_name": "Mymensingh",
    "capital": "admin",
    "population": 330126,
    "id": 1050399881
  },
  {
    "city": "Al Fayyūm",
    "city_ascii": "Al Fayyum",
    "lat": 29.3,
    "lng": 30.8333,
    "country": "Egypt",
    "iso2": "EG",
    "iso3": "EGY",
    "admin_name": "Al Fayyūm",
    "capital": "admin",
    "population": 315940,
    "id": 1818281002
  },
  {
    "city": "Flint",
    "city_ascii": "Flint",
    "lat": 43.0235,
    "lng": -83.6922,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 332190,
    "id": 1840002949
  },
  {
    "city": "Kendari",
    "city_ascii": "Kendari",
    "lat": -3.9675,
    "lng": 122.5947,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Sulawesi Tenggara",
    "capital": "admin",
    "population": 331013,
    "id": 1360992102
  },
  {
    "city": "Balıkesir",
    "city_ascii": "Balikesir",
    "lat": 39.6511,
    "lng": 27.8842,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Balıkesir",
    "capital": "admin",
    "population": 331788,
    "id": 1792020804
  },
  {
    "city": "Az Zaqāzīq",
    "city_ascii": "Az Zaqaziq",
    "lat": 30.5667,
    "lng": 31.5,
    "country": "Egypt",
    "iso2": "EG",
    "iso3": "EGY",
    "admin_name": "Ash Sharqīyah",
    "capital": "admin",
    "population": 302840,
    "id": 1818017750
  },
  {
    "city": "Thái Nguyên",
    "city_ascii": "Thai Nguyen",
    "lat": 21.6,
    "lng": 105.85,
    "country": "Vietnam",
    "iso2": "VN",
    "iso3": "VNM",
    "admin_name": "Thái Nguyên",
    "capital": "admin",
    "population": 330000,
    "id": 1704613715
  },
  {
    "city": "Smolensk",
    "city_ascii": "Smolensk",
    "lat": 54.7828,
    "lng": 32.0453,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Smolenskaya Oblast’",
    "capital": "admin",
    "population": 329853,
    "id": 1643627051
  },
  {
    "city": "Wŏnju",
    "city_ascii": "Wonju",
    "lat": 37.3417,
    "lng": 127.9208,
    "country": "Korea, South",
    "iso2": "KR",
    "iso3": "KOR",
    "admin_name": "Gangwon",
    "capital": "",
    "population": 330854,
    "id": 1410782685
  },
  {
    "city": "Maringá",
    "city_ascii": "Maringa",
    "lat": -23.4095,
    "lng": -51.93,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Paraná",
    "capital": "",
    "population": 328335,
    "id": 1076013378
  },
  {
    "city": "San Juan",
    "city_ascii": "San Juan",
    "lat": 18.4037,
    "lng": -66.0636,
    "country": "Puerto Rico",
    "iso2": "PR",
    "iso3": "PRI",
    "admin_name": "Puerto Rico",
    "capital": "",
    "population": 331165,
    "id": 1630035577
  },
  {
    "city": "Dahūk",
    "city_ascii": "Dahuk",
    "lat": 36.85,
    "lng": 42.9833,
    "country": "Iraq",
    "iso2": "IQ",
    "iso3": "IRQ",
    "admin_name": "Dahūk",
    "capital": "admin",
    "population": 330600,
    "id": 1368137126
  },
  {
    "city": "Batangas",
    "city_ascii": "Batangas",
    "lat": 13.75,
    "lng": 121.05,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Batangas",
    "capital": "admin",
    "population": 329874,
    "id": 1608417616
  },
  {
    "city": "Mathura",
    "city_ascii": "Mathura",
    "lat": 27.4833,
    "lng": 77.6833,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Uttar Pradesh",
    "capital": "",
    "population": 330511,
    "id": 1356066081
  },
  {
    "city": "Pelotas",
    "city_ascii": "Pelotas",
    "lat": -31.75,
    "lng": -52.33,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Rio Grande do Sul",
    "capital": "",
    "population": 320674,
    "id": 1076815692
  },
  {
    "city": "Bamiantong",
    "city_ascii": "Bamiantong",
    "lat": 44.9164,
    "lng": 130.5212,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Heilongjiang",
    "capital": "minor",
    "population": 330000,
    "id": 1156971194
  },
  {
    "city": "Kōchi",
    "city_ascii": "Kochi",
    "lat": 33.5667,
    "lng": 133.5333,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Kōchi",
    "capital": "admin",
    "population": 328930,
    "id": 1392086071
  },
  {
    "city": "Patiāla",
    "city_ascii": "Patiala",
    "lat": 30.3204,
    "lng": 76.385,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Punjab",
    "capital": "",
    "population": 329224,
    "id": 1356458416
  },
  {
    "city": "Gujrat",
    "city_ascii": "Gujrat",
    "lat": 32.5736,
    "lng": 74.0789,
    "country": "Pakistan",
    "iso2": "PK",
    "iso3": "PAK",
    "admin_name": "Punjab",
    "capital": "minor",
    "population": 328512,
    "id": 1586006990
  },
  {
    "city": "Bonn",
    "city_ascii": "Bonn",
    "lat": 50.7339,
    "lng": 7.0997,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "minor",
    "population": 327258,
    "id": 1276807850
  },
  {
    "city": "Vũng Tàu",
    "city_ascii": "Vung Tau",
    "lat": 10.4042,
    "lng": 107.1417,
    "country": "Vietnam",
    "iso2": "VN",
    "iso3": "VNM",
    "admin_name": "Bà Rịa-Vũng Tàu",
    "capital": "admin",
    "population": 327000,
    "id": 1704581438
  },
  {
    "city": "Markham",
    "city_ascii": "Markham",
    "lat": 43.8767,
    "lng": -79.2633,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 328966,
    "id": 1124272698
  },
  {
    "city": "Saugor",
    "city_ascii": "Saugor",
    "lat": 23.8504,
    "lng": 78.75,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Madhya Pradesh",
    "capital": "",
    "population": 328240,
    "id": 1356781800
  },
  {
    "city": "Roodepoort",
    "city_ascii": "Roodepoort",
    "lat": -26.1625,
    "lng": 27.8725,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "Gauteng",
    "capital": "",
    "population": 326416,
    "id": 1710090212
  },
  {
    "city": "Gómez Palacio",
    "city_ascii": "Gomez Palacio",
    "lat": 25.5611,
    "lng": -103.4983,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Durango",
    "capital": "minor",
    "population": 327985,
    "id": 1484092318
  },
  {
    "city": "Volzhskiy",
    "city_ascii": "Volzhskiy",
    "lat": 48.7833,
    "lng": 44.7667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Volgogradskaya Oblast’",
    "capital": "",
    "population": 326055,
    "id": 1643875527
  },
  {
    "city": "Bari",
    "city_ascii": "Bari",
    "lat": 41.1253,
    "lng": 16.8667,
    "country": "Italy",
    "iso2": "IT",
    "iso3": "ITA",
    "admin_name": "Puglia",
    "capital": "admin",
    "population": 325183,
    "id": 1380991126
  },
  {
    "city": "Bijāpur",
    "city_ascii": "Bijapur",
    "lat": 16.8244,
    "lng": 75.7154,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Karnātaka",
    "capital": "",
    "population": 327427,
    "id": 1356499139
  },
  {
    "city": "Sukabumi",
    "city_ascii": "Sukabumi",
    "lat": -6.9197,
    "lng": 106.9272,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Jawa Barat",
    "capital": "",
    "population": 320970,
    "id": 1360447543
  },
  {
    "city": "Ulanhot",
    "city_ascii": "Ulanhot",
    "lat": 46.0726,
    "lng": 122.0719,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Inner Mongolia",
    "capital": "",
    "population": 327081,
    "id": 1156182060
  },
  {
    "city": "Fayetteville",
    "city_ascii": "Fayetteville",
    "lat": 35.0846,
    "lng": -78.9776,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 327435,
    "id": 1840013490
  },
  {
    "city": "Itajaí",
    "city_ascii": "Itajai",
    "lat": -26.8996,
    "lng": -48.68,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Santa Catarina",
    "capital": "",
    "population": 327126,
    "id": 1076348188
  },
  {
    "city": "Nakuru",
    "city_ascii": "Nakuru",
    "lat": -0.2833,
    "lng": 36.0667,
    "country": "Kenya",
    "iso2": "KE",
    "iso3": "KEN",
    "admin_name": "Nakuru",
    "capital": "admin",
    "population": 307990,
    "id": 1404140179
  },
  {
    "city": "Yunzhong",
    "city_ascii": "Yunzhong",
    "lat": 39.8143,
    "lng": 113.0946,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Shanxi",
    "capital": "minor",
    "population": 326849,
    "id": 1156589240
  },
  {
    "city": "Al Fallūjah",
    "city_ascii": "Al Fallujah",
    "lat": 33.35,
    "lng": 43.7833,
    "country": "Iraq",
    "iso2": "IQ",
    "iso3": "IRQ",
    "admin_name": "Al Anbār",
    "capital": "minor",
    "population": 326471,
    "id": 1368081888
  },
  {
    "city": "Boa Vista",
    "city_ascii": "Boa Vista",
    "lat": 2.82,
    "lng": -60.6719,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Roraima",
    "capital": "admin",
    "population": 326419,
    "id": 1076621300
  },
  {
    "city": "Cluj-Napoca",
    "city_ascii": "Cluj-Napoca",
    "lat": 46.78,
    "lng": 23.5594,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Cluj",
    "capital": "admin",
    "population": 324576,
    "id": 1642503974
  },
  {
    "city": "Malmö",
    "city_ascii": "Malmo",
    "lat": 55.5932,
    "lng": 13.0214,
    "country": "Sweden",
    "iso2": "SE",
    "iso3": "SWE",
    "admin_name": "Skåne",
    "capital": "admin",
    "population": 321845,
    "id": 1752705818
  },
  {
    "city": "Gwangmyeongni",
    "city_ascii": "Gwangmyeongni",
    "lat": 37.4772,
    "lng": 126.8664,
    "country": "Korea, South",
    "iso2": "KR",
    "iso3": "KOR",
    "admin_name": "Gyeonggi",
    "capital": "",
    "population": 318021,
    "id": 1410103113
  },
  {
    "city": "Pucallpa",
    "city_ascii": "Pucallpa",
    "lat": -8.3833,
    "lng": -74.55,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "Ucayali",
    "capital": "admin",
    "population": 326040,
    "id": 1604478712
  },
  {
    "city": "Córdoba",
    "city_ascii": "Cordoba",
    "lat": 37.8833,
    "lng": -4.7667,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Andalusia",
    "capital": "minor",
    "population": 325701,
    "id": 1724706440
  },
  {
    "city": "Kuching",
    "city_ascii": "Kuching",
    "lat": 1.5397,
    "lng": 110.3542,
    "country": "Malaysia",
    "iso2": "MY",
    "iso3": "MYS",
    "admin_name": "Sarawak",
    "capital": "admin",
    "population": 325132,
    "id": 1458462262
  },
  {
    "city": "Zinder",
    "city_ascii": "Zinder",
    "lat": 13.8,
    "lng": 8.9833,
    "country": "Niger",
    "iso2": "NE",
    "iso3": "NER",
    "admin_name": "Zinder",
    "capital": "admin",
    "population": 322935,
    "id": 1562758258
  },
  {
    "city": "Naha",
    "city_ascii": "Naha",
    "lat": 26.2122,
    "lng": 127.6789,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Okinawa",
    "capital": "admin",
    "population": 317606,
    "id": 1392583118
  },
  {
    "city": "Gonder",
    "city_ascii": "Gonder",
    "lat": 12.6,
    "lng": 37.4667,
    "country": "Ethiopia",
    "iso2": "ET",
    "iso3": "ETH",
    "admin_name": "Āmara",
    "capital": "",
    "population": 323900,
    "id": 1231234833
  },
  {
    "city": "Uberaba",
    "city_ascii": "Uberaba",
    "lat": -19.7478,
    "lng": -47.9319,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Minas Gerais",
    "capital": "",
    "population": 325279,
    "id": 1076230331
  },
  {
    "city": "Jackson",
    "city_ascii": "Jackson",
    "lat": 32.3163,
    "lng": -90.2124,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Mississippi",
    "capital": "admin",
    "population": 325350,
    "id": 1840014895
  },
  {
    "city": "Mekele",
    "city_ascii": "Mekele",
    "lat": 13.4833,
    "lng": 39.4667,
    "country": "Ethiopia",
    "iso2": "ET",
    "iso3": "ETH",
    "admin_name": "Tigray",
    "capital": "admin",
    "population": 323700,
    "id": 1231468531
  },
  {
    "city": "Kulti",
    "city_ascii": "Kulti",
    "lat": 23.73,
    "lng": 86.85,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "West Bengal",
    "capital": "",
    "population": 313809,
    "id": 1356058458
  },
  {
    "city": "Santa Rosa",
    "city_ascii": "Santa Rosa",
    "lat": 38.4458,
    "lng": -122.7067,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 324634,
    "id": 1840021488
  },
  {
    "city": "Gonaïves",
    "city_ascii": "Gonaives",
    "lat": 19.45,
    "lng": -72.6833,
    "country": "Haiti",
    "iso2": "HT",
    "iso3": "HTI",
    "admin_name": "Artibonite",
    "capital": "admin",
    "population": 324043,
    "id": 1332384588
  },
  {
    "city": "Lansing",
    "city_ascii": "Lansing",
    "lat": 42.7142,
    "lng": -84.5601,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "admin",
    "population": 324286,
    "id": 1840003102
  },
  {
    "city": "Binxian",
    "city_ascii": "Binxian",
    "lat": 35.0364,
    "lng": 108.0764,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Shaanxi",
    "capital": "",
    "population": 323256,
    "id": 1156167534
  },
  {
    "city": "Newcastle",
    "city_ascii": "Newcastle",
    "lat": -32.9167,
    "lng": 151.75,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 322278,
    "id": 1036468001
  },
  {
    "city": "Kurgan",
    "city_ascii": "Kurgan",
    "lat": 55.4408,
    "lng": 65.3411,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kurganskaya Oblast’",
    "capital": "admin",
    "population": 322042,
    "id": 1643201853
  },
  {
    "city": "Kaiyuan",
    "city_ascii": "Kaiyuan",
    "lat": 23.7147,
    "lng": 103.2585,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Yunnan",
    "capital": "",
    "population": 322693,
    "id": 1156888008
  },
  {
    "city": "Temara",
    "city_ascii": "Temara",
    "lat": 33.9234,
    "lng": -6.9076,
    "country": "Morocco",
    "iso2": "MA",
    "iso3": "MAR",
    "admin_name": "Rabat-Salé-Kénitra",
    "capital": "",
    "population": 313510,
    "id": 1504016606
  },
  {
    "city": "Uruapan",
    "city_ascii": "Uruapan",
    "lat": 19.4208,
    "lng": -102.0628,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Michoacán de Ocampo",
    "capital": "minor",
    "population": 315350,
    "id": 1484051478
  },
  {
    "city": "Hotan",
    "city_ascii": "Hotan",
    "lat": 37.1012,
    "lng": 79.9327,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Xinjiang",
    "capital": "minor",
    "population": 322300,
    "id": 1156111844
  },
  {
    "city": "Camagüey",
    "city_ascii": "Camaguey",
    "lat": 21.3786,
    "lng": -77.9186,
    "country": "Cuba",
    "iso2": "CU",
    "iso3": "CUB",
    "admin_name": "Camagüey",
    "capital": "admin",
    "population": 321992,
    "id": 1192667983
  },
  {
    "city": "Ann Arbor",
    "city_ascii": "Ann Arbor",
    "lat": 42.2755,
    "lng": -83.7312,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 322267,
    "id": 1840003172
  },
  {
    "city": "San Salvador de Jujuy",
    "city_ascii": "San Salvador de Jujuy",
    "lat": -24.1856,
    "lng": -65.2994,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Jujuy",
    "capital": "admin",
    "population": 321789,
    "id": 1032815808
  },
  {
    "city": "Timişoara",
    "city_ascii": "Timisoara",
    "lat": 45.7597,
    "lng": 21.23,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Timiş",
    "capital": "admin",
    "population": 319279,
    "id": 1642603121
  },
  {
    "city": "Al Kūt",
    "city_ascii": "Al Kut",
    "lat": 32.4907,
    "lng": 45.8304,
    "country": "Iraq",
    "iso2": "IQ",
    "iso3": "IRQ",
    "admin_name": "Wāsiţ",
    "capital": "admin",
    "population": 321521,
    "id": 1368569694
  },
  {
    "city": "Tapachula",
    "city_ascii": "Tapachula",
    "lat": 14.9,
    "lng": -92.2667,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Chiapas",
    "capital": "minor",
    "population": 320451,
    "id": 1484997178
  },
  {
    "city": "Shāhjānpur",
    "city_ascii": "Shahjanpur",
    "lat": 27.8804,
    "lng": 79.905,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Uttar Pradesh",
    "capital": "",
    "population": 320434,
    "id": 1356415439
  },
  {
    "city": "Cherepovets",
    "city_ascii": "Cherepovets",
    "lat": 59.1333,
    "lng": 37.9167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Vologodskaya Oblast’",
    "capital": "",
    "population": 318856,
    "id": 1643937680
  },
  {
    "city": "Poltava",
    "city_ascii": "Poltava",
    "lat": 49.5744,
    "lng": 34.5686,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Poltavs’ka Oblast’",
    "capital": "admin",
    "population": 317847,
    "id": 1804547489
  },
  {
    "city": "Henderson",
    "city_ascii": "Henderson",
    "lat": 36.0133,
    "lng": -115.038,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Nevada",
    "capital": "",
    "population": 320189,
    "id": 1840020361
  },
  {
    "city": "Maroua",
    "city_ascii": "Maroua",
    "lat": 10.5823,
    "lng": 14.3275,
    "country": "Cameroon",
    "iso2": "CM",
    "iso3": "CMR",
    "admin_name": "Extrême-Nord",
    "capital": "admin",
    "population": 319941,
    "id": 1120956931
  },
  {
    "city": "Kaech’ŏn",
    "city_ascii": "Kaech'on",
    "lat": 39.6986,
    "lng": 125.9061,
    "country": "Korea, North",
    "iso2": "KP",
    "iso3": "PRK",
    "admin_name": "P’yŏngnam",
    "capital": "",
    "population": 319554,
    "id": 1408781295
  },
  {
    "city": "Asan",
    "city_ascii": "Asan",
    "lat": 36.35,
    "lng": 126.9167,
    "country": "Korea, South",
    "iso2": "KR",
    "iso3": "KOR",
    "admin_name": "Chungnam",
    "capital": "",
    "population": 319929,
    "id": 1410904940
  },
  {
    "city": "Coatzacoalcos",
    "city_ascii": "Coatzacoalcos",
    "lat": 18.15,
    "lng": -94.4333,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Veracruz",
    "capital": "minor",
    "population": 319187,
    "id": 1484016854
  },
  {
    "city": "Huntsville",
    "city_ascii": "Huntsville",
    "lat": 34.6988,
    "lng": -86.6412,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 319249,
    "id": 1840005061
  },
  {
    "city": "Trichūr",
    "city_ascii": "Trichur",
    "lat": 10.52,
    "lng": 76.21,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Kerala",
    "capital": "",
    "population": 315596,
    "id": 1356589298
  },
  {
    "city": "General Trias",
    "city_ascii": "General Trias",
    "lat": 14.3833,
    "lng": 120.8833,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Cavite",
    "capital": "",
    "population": 314303,
    "id": 1608826995
  },
  {
    "city": "Cirebon",
    "city_ascii": "Cirebon",
    "lat": -6.7167,
    "lng": 108.5667,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Jawa Barat",
    "capital": "",
    "population": 316126,
    "id": 1360011375
  },
  {
    "city": "Tampico",
    "city_ascii": "Tampico",
    "lat": 22.2553,
    "lng": -97.8686,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Tamaulipas",
    "capital": "minor",
    "population": 314418,
    "id": 1484060031
  },
  {
    "city": "Lexington",
    "city_ascii": "Lexington",
    "lat": 38.0423,
    "lng": -84.4587,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kentucky",
    "capital": "",
    "population": 317110,
    "id": 1840015211
  },
  {
    "city": "Boaco",
    "city_ascii": "Boaco",
    "lat": 12.4722,
    "lng": -85.6586,
    "country": "Nicaragua",
    "iso2": "NI",
    "iso3": "NIC",
    "admin_name": "Boaco",
    "capital": "admin",
    "population": 317000,
    "id": 1558023610
  },
  {
    "city": "Öskemen",
    "city_ascii": "Oskemen",
    "lat": 49.95,
    "lng": 82.6167,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Shyghys Qazaqstan",
    "capital": "admin",
    "population": 316699,
    "id": 1398371414
  },
  {
    "city": "Cà Mau",
    "city_ascii": "Ca Mau",
    "lat": 9.1833,
    "lng": 105.15,
    "country": "Vietnam",
    "iso2": "VN",
    "iso3": "VNM",
    "admin_name": "Cà Mau",
    "capital": "admin",
    "population": 315270,
    "id": 1704320474
  },
  {
    "city": "Neiva",
    "city_ascii": "Neiva",
    "lat": 2.9275,
    "lng": -75.2875,
    "country": "Colombia",
    "iso2": "CO",
    "iso3": "COL",
    "admin_name": "Huila",
    "capital": "admin",
    "population": 316033,
    "id": 1170159963
  },
  {
    "city": "Cabuyao",
    "city_ascii": "Cabuyao",
    "lat": 14.275,
    "lng": 121.125,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Laguna",
    "capital": "",
    "population": 308745,
    "id": 1608604441
  },
  {
    "city": "Vologda",
    "city_ascii": "Vologda",
    "lat": 59.2167,
    "lng": 39.9,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Vologodskaya Oblast’",
    "capital": "admin",
    "population": 313012,
    "id": 1643013685
  },
  {
    "city": "Saransk",
    "city_ascii": "Saransk",
    "lat": 54.1833,
    "lng": 45.1833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Mordoviya",
    "capital": "admin",
    "population": 314789,
    "id": 1643003229
  },
  {
    "city": "Mobile",
    "city_ascii": "Mobile",
    "lat": 30.6783,
    "lng": -88.1162,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 315502,
    "id": 1840006009
  },
  {
    "city": "Bor",
    "city_ascii": "Bor",
    "lat": 6.2167,
    "lng": 31.55,
    "country": "South Sudan",
    "iso2": "SS",
    "iso3": "SSD",
    "admin_name": "Jonglei",
    "capital": "admin",
    "population": 315351,
    "id": 1728186782
  },
  {
    "city": "Münster",
    "city_ascii": "Munster",
    "lat": 51.9625,
    "lng": 7.6256,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "minor",
    "population": 314319,
    "id": 1276714504
  },
  {
    "city": "Barddhamān",
    "city_ascii": "Barddhaman",
    "lat": 23.25,
    "lng": 87.85,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "West Bengal",
    "capital": "",
    "population": 314265,
    "id": 1356062166
  },
  {
    "city": "Ksar El Kebir",
    "city_ascii": "Ksar El Kebir",
    "lat": 35.0204,
    "lng": -5.91,
    "country": "Morocco",
    "iso2": "MA",
    "iso3": "MAR",
    "admin_name": "Tanger-Tétouan-Al Hoceïma",
    "capital": "",
    "population": 306600,
    "id": 1504230428
  },
  {
    "city": "Karlsruhe",
    "city_ascii": "Karlsruhe",
    "lat": 49.0167,
    "lng": 8.4,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "minor",
    "population": 313092,
    "id": 1276923210
  },
  {
    "city": "Kasur",
    "city_ascii": "Kasur",
    "lat": 31.1167,
    "lng": 74.45,
    "country": "Pakistan",
    "iso2": "PK",
    "iso3": "PAK",
    "admin_name": "Punjab",
    "capital": "minor",
    "population": 314617,
    "id": 1586858135
  },
  {
    "city": "Yakou",
    "city_ascii": "Yakou",
    "lat": 33.2937,
    "lng": 113.5203,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Henan",
    "capital": "minor",
    "population": 313828,
    "id": 1156326854
  },
  {
    "city": "Orël",
    "city_ascii": "Orel",
    "lat": 52.9686,
    "lng": 36.0694,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Orlovskaya Oblast’",
    "capital": "admin",
    "population": 311625,
    "id": 1643703818
  },
  {
    "city": "Safi",
    "city_ascii": "Safi",
    "lat": 32.2833,
    "lng": -9.2333,
    "country": "Morocco",
    "iso2": "MA",
    "iso3": "MAR",
    "admin_name": "Marrakech-Safi",
    "capital": "",
    "population": 308508,
    "id": 1504000717
  },
  {
    "city": "Shahr-e Qods",
    "city_ascii": "Shahr-e Qods",
    "lat": 35.7214,
    "lng": 51.1089,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Tehrān",
    "capital": "minor",
    "population": 309605,
    "id": 1364272235
  },
  {
    "city": "Vitória da Conquista",
    "city_ascii": "Vitoria da Conquista",
    "lat": -14.8661,
    "lng": -40.8394,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Bahia",
    "capital": "",
    "population": 308204,
    "id": 1076812020
  },
  {
    "city": "Ḩamāh",
    "city_ascii": "Hamah",
    "lat": 35.1333,
    "lng": 36.75,
    "country": "Syria",
    "iso2": "SY",
    "iso3": "SYR",
    "admin_name": "Ḩamāh",
    "capital": "admin",
    "population": 312994,
    "id": 1760197502
  },
  {
    "city": "Guarujá",
    "city_ascii": "Guaruja",
    "lat": -23.9936,
    "lng": -46.2564,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 311230,
    "id": 1076832747
  },
  {
    "city": "Catania",
    "city_ascii": "Catania",
    "lat": 37.5027,
    "lng": 15.0873,
    "country": "Italy",
    "iso2": "IT",
    "iso3": "ITA",
    "admin_name": "Sicilia",
    "capital": "minor",
    "population": 311584,
    "id": 1380562508
  },
  {
    "city": "Purnea",
    "city_ascii": "Purnea",
    "lat": 25.78,
    "lng": 87.47,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Bihār",
    "capital": "",
    "population": 310817,
    "id": 1356486511
  },
  {
    "city": "Fort Collins",
    "city_ascii": "Fort Collins",
    "lat": 40.5478,
    "lng": -105.0656,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 312666,
    "id": 1840020151
  },
  {
    "city": "Port Elizabeth",
    "city_ascii": "Port Elizabeth",
    "lat": -33.9581,
    "lng": 25.6,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "Eastern Cape",
    "capital": "",
    "population": 312392,
    "id": 1710918997
  },
  {
    "city": "Alanya",
    "city_ascii": "Alanya",
    "lat": 36.5436,
    "lng": 31.9997,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Antalya",
    "capital": "minor",
    "population": 312319,
    "id": 1792233416
  },
  {
    "city": "Asheville",
    "city_ascii": "Asheville",
    "lat": 35.5704,
    "lng": -82.5536,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 312507,
    "id": 1840013411
  },
  {
    "city": "Santa Clarita",
    "city_ascii": "Santa Clarita",
    "lat": 34.4175,
    "lng": -118.4964,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 312446,
    "id": 1840021864
  },
  {
    "city": "Gorgān",
    "city_ascii": "Gorgan",
    "lat": 36.83,
    "lng": 54.48,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Golestān",
    "capital": "admin",
    "population": 312223,
    "id": 1364802450
  },
  {
    "city": "Porto Velho",
    "city_ascii": "Porto Velho",
    "lat": -8.7619,
    "lng": -63.9039,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Rondônia",
    "capital": "admin",
    "population": 306180,
    "id": 1076045591
  },
  {
    "city": "Quy Nhơn",
    "city_ascii": "Quy Nhon",
    "lat": 13.7765,
    "lng": 109.2237,
    "country": "Vietnam",
    "iso2": "VN",
    "iso3": "VNM",
    "admin_name": "Bình Định",
    "capital": "admin",
    "population": 311000,
    "id": 1704056461
  },
  {
    "city": "Sambalpur",
    "city_ascii": "Sambalpur",
    "lat": 21.4704,
    "lng": 83.9701,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Odisha",
    "capital": "",
    "population": 310852,
    "id": 1356772339
  },
  {
    "city": "Yokkaichi",
    "city_ascii": "Yokkaichi",
    "lat": 34.9667,
    "lng": 136.6167,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Mie",
    "capital": "",
    "population": 310145,
    "id": 1392198395
  },
  {
    "city": "Chalco",
    "city_ascii": "Chalco",
    "lat": 19.2647,
    "lng": -98.8975,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "México",
    "capital": "minor",
    "population": 310130,
    "id": 1484834149
  },
  {
    "city": "Mannheim",
    "city_ascii": "Mannheim",
    "lat": 49.4878,
    "lng": 8.4661,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "minor",
    "population": 309370,
    "id": 1276150104
  },
  {
    "city": "Namp’o",
    "city_ascii": "Namp'o",
    "lat": 38.7333,
    "lng": 125.4,
    "country": "Korea, North",
    "iso2": "KP",
    "iso3": "PRK",
    "admin_name": "P’yŏngnam",
    "capital": "admin",
    "population": 310864,
    "id": 1408892768
  },
  {
    "city": "Shahrīār",
    "city_ascii": "Shahriar",
    "lat": 35.6597,
    "lng": 51.0592,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Tehrān",
    "capital": "minor",
    "population": 309607,
    "id": 1364482289
  },
  {
    "city": "Kasugai",
    "city_ascii": "Kasugai",
    "lat": 35.25,
    "lng": 136.9667,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Aichi",
    "capital": "",
    "population": 306876,
    "id": 1392431449
  },
  {
    "city": "Sapele",
    "city_ascii": "Sapele",
    "lat": 5.8904,
    "lng": 5.68,
    "country": "Nigeria",
    "iso2": "NG",
    "iso3": "NGA",
    "admin_name": "Delta",
    "capital": "minor",
    "population": 309162,
    "id": 1566310893
  },
  {
    "city": "Blumenau",
    "city_ascii": "Blumenau",
    "lat": -26.9189,
    "lng": -49.0658,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Santa Catarina",
    "capital": "",
    "population": 309011,
    "id": 1076618665
  },
  {
    "city": "Sariwŏn",
    "city_ascii": "Sariwon",
    "lat": 38.5039,
    "lng": 125.7589,
    "country": "Korea, North",
    "iso2": "KP",
    "iso3": "PRK",
    "admin_name": "Hwangbuk",
    "capital": "admin",
    "population": 307764,
    "id": 1408018673
  },
  {
    "city": "St. Catharines",
    "city_ascii": "St. Catharines",
    "lat": 43.1833,
    "lng": -79.2333,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 309319,
    "id": 1124140229
  },
  {
    "city": "Matadi",
    "city_ascii": "Matadi",
    "lat": -5.8167,
    "lng": 13.4833,
    "country": "Congo (Kinshasa)",
    "iso2": "CD",
    "iso3": "COD",
    "admin_name": "Kongo Central",
    "capital": "admin",
    "population": 306053,
    "id": 1180529604
  },
  {
    "city": "Niagara Falls",
    "city_ascii": "Niagara Falls",
    "lat": 43.06,
    "lng": -79.1067,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 308596,
    "id": 1124704011
  },
  {
    "city": "Fīrozābād",
    "city_ascii": "Firozabad",
    "lat": 27.15,
    "lng": 78.3949,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Uttar Pradesh",
    "capital": "",
    "population": 306409,
    "id": 1356031978
  },
  {
    "city": "San Fernando",
    "city_ascii": "San Fernando",
    "lat": 15.0333,
    "lng": 120.6833,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Pampanga",
    "capital": "admin",
    "population": 306659,
    "id": 1608862124
  },
  {
    "city": "St. Paul",
    "city_ascii": "St. Paul",
    "lat": 44.9477,
    "lng": -93.104,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "admin",
    "population": 308096,
    "id": 1840008940
  },
  {
    "city": "Vladikavkaz",
    "city_ascii": "Vladikavkaz",
    "lat": 43.0167,
    "lng": 44.65,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "North Ossetia",
    "capital": "admin",
    "population": 306978,
    "id": 1643438348
  },
  {
    "city": "Yakutsk",
    "city_ascii": "Yakutsk",
    "lat": 62.0272,
    "lng": 129.7319,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sakha (Yakutiya)",
    "capital": "admin",
    "population": 307911,
    "id": 1643163546
  },
  {
    "city": "Minna",
    "city_ascii": "Minna",
    "lat": 9.6139,
    "lng": 6.5569,
    "country": "Nigeria",
    "iso2": "NG",
    "iso3": "NGA",
    "admin_name": "Niger",
    "capital": "admin",
    "population": 304113,
    "id": 1566285237
  },
  {
    "city": "Hisar",
    "city_ascii": "Hisar",
    "lat": 29.1489,
    "lng": 75.7367,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Haryāna",
    "capital": "",
    "population": 307024,
    "id": 1356004790
  },
  {
    "city": "Puerto La Cruz",
    "city_ascii": "Puerto La Cruz",
    "lat": 10.2167,
    "lng": -64.6167,
    "country": "Venezuela",
    "iso2": "VE",
    "iso3": "VEN",
    "admin_name": "Anzoátegui",
    "capital": "minor",
    "population": 306261,
    "id": 1862856141
  },
  {
    "city": "Ciudad del Este",
    "city_ascii": "Ciudad del Este",
    "lat": -25.5167,
    "lng": -54.6167,
    "country": "Paraguay",
    "iso2": "PY",
    "iso3": "PRY",
    "admin_name": "Alto Paraná",
    "capital": "admin",
    "population": 304282,
    "id": 1600265252
  },
  {
    "city": "Podolsk",
    "city_ascii": "Podolsk",
    "lat": 55.4297,
    "lng": 37.5444,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Moskovskaya Oblast’",
    "capital": "",
    "population": 299660,
    "id": 1643693800
  },
  {
    "city": "Ciudad Victoria",
    "city_ascii": "Ciudad Victoria",
    "lat": 23.7333,
    "lng": -99.1333,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Tamaulipas",
    "capital": "admin",
    "population": 305155,
    "id": 1484616519
  },
  {
    "city": "Akita",
    "city_ascii": "Akita",
    "lat": 39.7197,
    "lng": 140.1025,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Akita",
    "capital": "admin",
    "population": 306178,
    "id": 1392129688
  },
  {
    "city": "Kumul",
    "city_ascii": "Kumul",
    "lat": 42.827,
    "lng": 93.515,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Xinjiang",
    "capital": "minor",
    "population": 300848,
    "id": 1156131545
  },
  {
    "city": "Vaughan",
    "city_ascii": "Vaughan",
    "lat": 43.8333,
    "lng": -79.5,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 306233,
    "id": 1124000141
  },
  {
    "city": "Ōakashichō",
    "city_ascii": "Oakashicho",
    "lat": 34.6431,
    "lng": 134.9975,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hyōgo",
    "capital": "",
    "population": 299094,
    "id": 1392000137
  },
  {
    "city": "Pekalongan",
    "city_ascii": "Pekalongan",
    "lat": -6.8883,
    "lng": 109.6753,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Jawa Tengah",
    "capital": "",
    "population": 298386,
    "id": 1360039434
  },
  {
    "city": "Adıyaman",
    "city_ascii": "Adiyaman",
    "lat": 37.7644,
    "lng": 38.2763,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Adıyaman",
    "capital": "admin",
    "population": 304615,
    "id": 1792686663
  },
  {
    "city": "Vila Nova de Gaia",
    "city_ascii": "Vila Nova de Gaia",
    "lat": 41.1333,
    "lng": -8.6167,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Porto",
    "capital": "minor",
    "population": 302295,
    "id": 1620040225
  },
  {
    "city": "Curepipe",
    "city_ascii": "Curepipe",
    "lat": -20.3162,
    "lng": 57.5166,
    "country": "Mauritius",
    "iso2": "MU",
    "iso3": "MUS",
    "admin_name": "Plaines Wilhems",
    "capital": "",
    "population": 299975,
    "id": 1480343099
  },
  {
    "city": "Cabanatuan City",
    "city_ascii": "Cabanatuan City",
    "lat": 15.4833,
    "lng": 120.9667,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Nueva Ecija",
    "capital": "",
    "population": 302231,
    "id": 1608247694
  },
  {
    "city": "Oaxaca",
    "city_ascii": "Oaxaca",
    "lat": 17.0833,
    "lng": -96.75,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Oaxaca",
    "capital": "admin",
    "population": 300050,
    "id": 1484301467
  },
  {
    "city": "Armenia",
    "city_ascii": "Armenia",
    "lat": 4.5389,
    "lng": -75.6725,
    "country": "Colombia",
    "iso2": "CO",
    "iso3": "COL",
    "admin_name": "Quindío",
    "capital": "admin",
    "population": 301226,
    "id": 1170398459
  },
  {
    "city": "Wollongong",
    "city_ascii": "Wollongong",
    "lat": -34.4331,
    "lng": 150.8831,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 302739,
    "id": 1036502269
  },
  {
    "city": "Brest",
    "city_ascii": "Brest",
    "lat": 48.39,
    "lng": -4.49,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Bretagne",
    "capital": "minor",
    "population": 300300,
    "id": 1250882453
  },
  {
    "city": "Awka",
    "city_ascii": "Awka",
    "lat": 6.2,
    "lng": 7.0667,
    "country": "Nigeria",
    "iso2": "NG",
    "iso3": "NGA",
    "admin_name": "Anambra",
    "capital": "admin",
    "population": 301657,
    "id": 1566560581
  },
  {
    "city": "Iksan",
    "city_ascii": "Iksan",
    "lat": 35.9439,
    "lng": 126.9544,
    "country": "Korea, South",
    "iso2": "KR",
    "iso3": "KOR",
    "admin_name": "Jeonbuk",
    "capital": "",
    "population": 302310,
    "id": 1410134391
  },
  {
    "city": "Taubaté",
    "city_ascii": "Taubate",
    "lat": -23.0333,
    "lng": -45.55,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 302331,
    "id": 1076146252
  },
  {
    "city": "Antioch",
    "city_ascii": "Antioch",
    "lat": 37.9789,
    "lng": -121.7958,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 302419,
    "id": 1840018903
  },
  {
    "city": "Lakeland",
    "city_ascii": "Lakeland",
    "lat": 28.0556,
    "lng": -81.9545,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 302284,
    "id": 1840015108
  },
  {
    "city": "Cadiz",
    "city_ascii": "Cadiz",
    "lat": 10.9587,
    "lng": 123.3086,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Negros Occidental",
    "capital": "",
    "population": 283157,
    "id": 1608224037
  },
  {
    "city": "Sóc Trăng",
    "city_ascii": "Soc Trang",
    "lat": 9.6,
    "lng": 105.9719,
    "country": "Vietnam",
    "iso2": "VN",
    "iso3": "VNM",
    "admin_name": "Sóc Trăng",
    "capital": "admin",
    "population": 300000,
    "id": 1704758756
  },
  {
    "city": "Āwasa",
    "city_ascii": "Awasa",
    "lat": 7.05,
    "lng": 38.4667,
    "country": "Ethiopia",
    "iso2": "ET",
    "iso3": "ETH",
    "admin_name": "YeDebub Bihēroch Bihēreseboch na Hizboch",
    "capital": "admin",
    "population": 300100,
    "id": 1231663122
  },
  {
    "city": "Mardan",
    "city_ascii": "Mardan",
    "lat": 34.1958,
    "lng": 72.0447,
    "country": "Pakistan",
    "iso2": "PK",
    "iso3": "PAK",
    "admin_name": "Khyber Pakhtunkhwa",
    "capital": "minor",
    "population": 300424,
    "id": 1586001546
  },
  {
    "city": "Popayán",
    "city_ascii": "Popayan",
    "lat": 2.4411,
    "lng": -76.6061,
    "country": "Colombia",
    "iso2": "CO",
    "iso3": "COL",
    "admin_name": "Cauca",
    "capital": "admin",
    "population": 300837,
    "id": 1170764265
  },
  {
    "city": "Praia Grande",
    "city_ascii": "Praia Grande",
    "lat": -24.0058,
    "lng": -46.4028,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 299261,
    "id": 1076548222
  },
  {
    "city": "Qianzhou",
    "city_ascii": "Qianzhou",
    "lat": 28.3185,
    "lng": 109.7318,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hunan",
    "capital": "minor",
    "population": 301000,
    "id": 1156103756
  },
  {
    "city": "Cotabato",
    "city_ascii": "Cotabato",
    "lat": 7.2167,
    "lng": 124.25,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Cotabato",
    "capital": "admin",
    "population": 299438,
    "id": 1608753128
  },
  {
    "city": "Bīdar",
    "city_ascii": "Bidar",
    "lat": 17.9229,
    "lng": 77.5175,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Karnātaka",
    "capital": "",
    "population": 300136,
    "id": 1356933453
  },
  {
    "city": "Białystok",
    "city_ascii": "Bialystok",
    "lat": 53.1167,
    "lng": 23.1667,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Podlaskie",
    "capital": "admin",
    "population": 297288,
    "id": 1616443575
  },
  {
    "city": "Mérida",
    "city_ascii": "Merida",
    "lat": 8.5833,
    "lng": -71.1333,
    "country": "Venezuela",
    "iso2": "VE",
    "iso3": "VEN",
    "admin_name": "Mérida",
    "capital": "admin",
    "population": 300000,
    "id": 1862668122
  },
  {
    "city": "Murmansk",
    "city_ascii": "Murmansk",
    "lat": 68.9667,
    "lng": 33.0833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Murmanskaya Oblast’",
    "capital": "admin",
    "population": 298096,
    "id": 1643755251
  },
  {
    "city": "Afyonkarahisar",
    "city_ascii": "Afyonkarahisar",
    "lat": 38.7581,
    "lng": 30.5386,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Afyonkarahisar",
    "capital": "admin",
    "population": 299673,
    "id": 1792855788
  },
  {
    "city": "Valladolid",
    "city_ascii": "Valladolid",
    "lat": 41.652,
    "lng": -4.7286,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Castille-Leon",
    "capital": "admin",
    "population": 298412,
    "id": 1724133771
  },
  {
    "city": "Jember",
    "city_ascii": "Jember",
    "lat": -8.1727,
    "lng": 113.6873,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Jawa Timur",
    "capital": "minor",
    "population": 298585,
    "id": 1360316553
  },
  {
    "city": "Bahía Blanca",
    "city_ascii": "Bahia Blanca",
    "lat": -38.7167,
    "lng": -62.2667,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires",
    "capital": "minor",
    "population": 299101,
    "id": 1032795766
  },
  {
    "city": "İskenderun",
    "city_ascii": "Iskenderun",
    "lat": 36.5804,
    "lng": 36.17,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Hatay",
    "capital": "minor",
    "population": 297943,
    "id": 1792962911
  },
  {
    "city": "Al Mubarraz",
    "city_ascii": "Al Mubarraz",
    "lat": 25.4416,
    "lng": 49.6642,
    "country": "Saudi Arabia",
    "iso2": "SA",
    "iso3": "SAU",
    "admin_name": "Ash Sharqīyah",
    "capital": "",
    "population": 298562,
    "id": 1682484039
  },
  {
    "city": "Petrópolis",
    "city_ascii": "Petropolis",
    "lat": -22.505,
    "lng": -43.1789,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Rio de Janeiro",
    "capital": "",
    "population": 298142,
    "id": 1076352504
  },
  {
    "city": "Al Kharj",
    "city_ascii": "Al Kharj",
    "lat": 24.1556,
    "lng": 47.312,
    "country": "Saudi Arabia",
    "iso2": "SA",
    "iso3": "SAU",
    "admin_name": "Ar Riyāḑ",
    "capital": "",
    "population": 298428,
    "id": 1682947408
  },
  {
    "city": "Rāmpur",
    "city_ascii": "Rampur",
    "lat": 28.8154,
    "lng": 79.025,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Uttar Pradesh",
    "capital": "",
    "population": 296418,
    "id": 1356666569
  },
  {
    "city": "Najrān",
    "city_ascii": "Najran",
    "lat": 17.4917,
    "lng": 44.1322,
    "country": "Saudi Arabia",
    "iso2": "SA",
    "iso3": "SAU",
    "admin_name": "Najrān",
    "capital": "admin",
    "population": 298288,
    "id": 1682069716
  },
  {
    "city": "Chernihiv",
    "city_ascii": "Chernihiv",
    "lat": 51.5,
    "lng": 31.3,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Chernihivs’ka Oblast’",
    "capital": "admin",
    "population": 294522,
    "id": 1804565996
  },
  {
    "city": "Yangsan",
    "city_ascii": "Yangsan",
    "lat": 35.3386,
    "lng": 129.0386,
    "country": "Korea, South",
    "iso2": "KR",
    "iso3": "KOR",
    "admin_name": "Gyeongnam",
    "capital": "minor",
    "population": 297532,
    "id": 1410795549
  },
  {
    "city": "Vigo",
    "city_ascii": "Vigo",
    "lat": 42.2358,
    "lng": -8.7267,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Galicia",
    "capital": "",
    "population": 295364,
    "id": 1724045676
  },
  {
    "city": "Oyster Bay",
    "city_ascii": "Oyster Bay",
    "lat": 40.7845,
    "lng": -73.5139,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 297531,
    "id": 1840058379
  },
  {
    "city": "Valparaíso",
    "city_ascii": "Valparaiso",
    "lat": -33.0458,
    "lng": -71.6197,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Valparaíso",
    "capital": "admin",
    "population": 296655,
    "id": 1152337970
  },
  {
    "city": "Augsburg",
    "city_ascii": "Augsburg",
    "lat": 48.3717,
    "lng": 10.8983,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "minor",
    "population": 295135,
    "id": 1276314100
  },
  {
    "city": "Mbeya",
    "city_ascii": "Mbeya",
    "lat": -8.9,
    "lng": 33.45,
    "country": "Tanzania",
    "iso2": "TZ",
    "iso3": "TZA",
    "admin_name": "Mbeya",
    "capital": "admin",
    "population": 291649,
    "id": 1834396594
  },
  {
    "city": "Limeira",
    "city_ascii": "Limeira",
    "lat": -22.5647,
    "lng": -47.4017,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 296440,
    "id": 1076508566
  },
  {
    "city": "Rangpur",
    "city_ascii": "Rangpur",
    "lat": 25.75,
    "lng": 89.25,
    "country": "Bangladesh",
    "iso2": "BD",
    "iso3": "BGD",
    "admin_name": "Rangpur",
    "capital": "admin",
    "population": 294265,
    "id": 1050667437
  },
  {
    "city": "Ponta Grossa",
    "city_ascii": "Ponta Grossa",
    "lat": -25.09,
    "lng": -50.16,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Paraná",
    "capital": "",
    "population": 292177,
    "id": 1076716439
  },
  {
    "city": "Shiliguri",
    "city_ascii": "Shiliguri",
    "lat": 26.72,
    "lng": 88.42,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "West Bengal",
    "capital": "",
    "population": 294546,
    "id": 1356724691
  },
  {
    "city": "Aksaray",
    "city_ascii": "Aksaray",
    "lat": 38.3686,
    "lng": 34.0297,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Aksaray",
    "capital": "admin",
    "population": 295351,
    "id": 1792650390
  },
  {
    "city": "Navotas",
    "city_ascii": "Navotas",
    "lat": 14.6667,
    "lng": 120.9417,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Navotas",
    "capital": "admin",
    "population": 249463,
    "id": 1608538447
  },
  {
    "city": "Çorum",
    "city_ascii": "Corum",
    "lat": 40.5489,
    "lng": 34.9533,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Çorum",
    "capital": "admin",
    "population": 294807,
    "id": 1792065200
  },
  {
    "city": "Bāli",
    "city_ascii": "Bali",
    "lat": 22.65,
    "lng": 88.34,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "West Bengal",
    "capital": "",
    "population": 293373,
    "id": 1356113138
  },
  {
    "city": "Ismailia",
    "city_ascii": "Ismailia",
    "lat": 30.5833,
    "lng": 32.2667,
    "country": "Egypt",
    "iso2": "EG",
    "iso3": "EGY",
    "admin_name": "Al Ismā‘īlīyah",
    "capital": "admin",
    "population": 293184,
    "id": 1818532766
  },
  {
    "city": "Pānīpat",
    "city_ascii": "Panipat",
    "lat": 29.4004,
    "lng": 76.97,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Haryāna",
    "capital": "",
    "population": 292808,
    "id": 1356526780
  },
  {
    "city": "Cagliari",
    "city_ascii": "Cagliari",
    "lat": 39.2224,
    "lng": 9.104,
    "country": "Italy",
    "iso2": "IT",
    "iso3": "ITA",
    "admin_name": "Sardegna",
    "capital": "admin",
    "population": 291511,
    "id": 1380685705
  },
  {
    "city": "Delmas",
    "city_ascii": "Delmas",
    "lat": 18.55,
    "lng": -72.3,
    "country": "Haiti",
    "iso2": "HT",
    "iso3": "HTI",
    "admin_name": "Ouest",
    "capital": "",
    "population": 284079,
    "id": 1332257532
  },
  {
    "city": "Batna",
    "city_ascii": "Batna",
    "lat": 35.55,
    "lng": 6.1667,
    "country": "Algeria",
    "iso2": "DZ",
    "iso3": "DZA",
    "admin_name": "Batna",
    "capital": "admin",
    "population": 290645,
    "id": 1012093229
  },
  {
    "city": "Kunp’o",
    "city_ascii": "Kunp'o",
    "lat": 37.3675,
    "lng": 126.9469,
    "country": "Korea, South",
    "iso2": "KR",
    "iso3": "KOR",
    "admin_name": "Gyeonggi",
    "capital": "",
    "population": 285721,
    "id": 1410672540
  },
  {
    "city": "Tambov",
    "city_ascii": "Tambov",
    "lat": 52.7167,
    "lng": 41.4333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Tambovskaya Oblast’",
    "capital": "admin",
    "population": 290365,
    "id": 1643802687
  },
  {
    "city": "Iaşi",
    "city_ascii": "Iasi",
    "lat": 47.1622,
    "lng": 27.5889,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Iaşi",
    "capital": "admin",
    "population": 290422,
    "id": 1642367695
  },
  {
    "city": "Thiès",
    "city_ascii": "Thies",
    "lat": 14.7833,
    "lng": -16.9167,
    "country": "Senegal",
    "iso2": "SN",
    "iso3": "SEN",
    "admin_name": "Thiès",
    "capital": "admin",
    "population": 293001,
    "id": 1686527948
  },
  {
    "city": "Makurdi",
    "city_ascii": "Makurdi",
    "lat": 7.73,
    "lng": 8.53,
    "country": "Nigeria",
    "iso2": "NG",
    "iso3": "NGA",
    "admin_name": "Benue",
    "capital": "admin",
    "population": 292645,
    "id": 1566873994
  },
  {
    "city": "Morioka",
    "city_ascii": "Morioka",
    "lat": 39.6833,
    "lng": 141.15,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Iwate",
    "capital": "admin",
    "population": 292554,
    "id": 1392981957
  },
  {
    "city": "Kherson",
    "city_ascii": "Kherson",
    "lat": 46.6333,
    "lng": 32.6,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Khersons’ka Oblast’",
    "capital": "admin",
    "population": 291428,
    "id": 1804514036
  },
  {
    "city": "Groznyy",
    "city_ascii": "Groznyy",
    "lat": 43.3125,
    "lng": 45.6986,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Chechnya",
    "capital": "admin",
    "population": 291687,
    "id": 1643049853
  },
  {
    "city": "Hong’an",
    "city_ascii": "Hong'an",
    "lat": 47.21,
    "lng": 123.61,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Heilongjiang",
    "capital": "minor",
    "population": 289999,
    "id": 1156999688
  },
  {
    "city": "Bafoussam",
    "city_ascii": "Bafoussam",
    "lat": 5.4667,
    "lng": 10.4167,
    "country": "Cameroon",
    "iso2": "CM",
    "iso3": "CMR",
    "admin_name": "Ouest",
    "capital": "admin",
    "population": 290768,
    "id": 1120435460
  },
  {
    "city": "Resistencia",
    "city_ascii": "Resistencia",
    "lat": -27.4514,
    "lng": -58.9867,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Chaco",
    "capital": "admin",
    "population": 291720,
    "id": 1032720891
  },
  {
    "city": "Braşov",
    "city_ascii": "Brasov",
    "lat": 45.65,
    "lng": 25.6,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Braşov",
    "capital": "admin",
    "population": 290743,
    "id": 1642328429
  },
  {
    "city": "Juliaca",
    "city_ascii": "Juliaca",
    "lat": -15.4908,
    "lng": -70.1269,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "Puno",
    "capital": "",
    "population": 291189,
    "id": 1604174464
  },
  {
    "city": "Graz",
    "city_ascii": "Graz",
    "lat": 47.0667,
    "lng": 15.4333,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Steiermark",
    "capital": "admin",
    "population": 289440,
    "id": 1040184559
  },
  {
    "city": "Karīmnagar",
    "city_ascii": "Karimnagar",
    "lat": 18.4333,
    "lng": 79.15,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Telangana",
    "capital": "",
    "population": 289821,
    "id": 1356578937
  },
  {
    "city": "Sétif",
    "city_ascii": "Setif",
    "lat": 36.19,
    "lng": 5.41,
    "country": "Algeria",
    "iso2": "DZ",
    "iso3": "DZA",
    "admin_name": "Sétif",
    "capital": "admin",
    "population": 288461,
    "id": 1012981116
  },
  {
    "city": "Trenton",
    "city_ascii": "Trenton",
    "lat": 40.2236,
    "lng": -74.7641,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "admin",
    "population": 290716,
    "id": 1840001383
  },
  {
    "city": "Kaunas",
    "city_ascii": "Kaunas",
    "lat": 54.9,
    "lng": 23.9333,
    "country": "Lithuania",
    "iso2": "LT",
    "iso3": "LTU",
    "admin_name": "Kauno Miestas",
    "capital": "admin",
    "population": 288466,
    "id": 1440302852
  },
  {
    "city": "Mulhouse",
    "city_ascii": "Mulhouse",
    "lat": 47.75,
    "lng": 7.34,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Grand Est",
    "capital": "minor",
    "population": 285121,
    "id": 1250218452
  },
  {
    "city": "Hulin",
    "city_ascii": "Hulin",
    "lat": 45.7671,
    "lng": 132.9646,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Heilongjiang",
    "capital": "",
    "population": 290000,
    "id": 1156161638
  },
  {
    "city": "Sekondi",
    "city_ascii": "Sekondi",
    "lat": 4.9433,
    "lng": -1.704,
    "country": "Ghana",
    "iso2": "GH",
    "iso3": "GHA",
    "admin_name": "Western",
    "capital": "admin",
    "population": 286248,
    "id": 1288186231
  },
  {
    "city": "Lincoln",
    "city_ascii": "Lincoln",
    "lat": 40.809,
    "lng": -96.6788,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Nebraska",
    "capital": "admin",
    "population": 289490,
    "id": 1840009357
  },
  {
    "city": "Bhuj",
    "city_ascii": "Bhuj",
    "lat": 23.2504,
    "lng": 69.81,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Gujarāt",
    "capital": "",
    "population": 289429,
    "id": 1356806356
  },
  {
    "city": "Ostrava",
    "city_ascii": "Ostrava",
    "lat": 49.8356,
    "lng": 18.2925,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Moravskoslezský Kraj",
    "capital": "admin",
    "population": 287968,
    "id": 1203687206
  },
  {
    "city": "Ichalkaranji",
    "city_ascii": "Ichalkaranji",
    "lat": 16.7,
    "lng": 74.47,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Mahārāshtra",
    "capital": "",
    "population": 287570,
    "id": 1356651502
  },
  {
    "city": "Tirupati",
    "city_ascii": "Tirupati",
    "lat": 13.65,
    "lng": 79.42,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Andhra Pradesh",
    "capital": "",
    "population": 287482,
    "id": 1356362995
  },
  {
    "city": "Springfield",
    "city_ascii": "Springfield",
    "lat": 37.1943,
    "lng": -93.2916,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 288090,
    "id": 1840009904
  },
  {
    "city": "Punto Fijo",
    "city_ascii": "Punto Fijo",
    "lat": 11.7167,
    "lng": -70.1833,
    "country": "Venezuela",
    "iso2": "VE",
    "iso3": "VEN",
    "admin_name": "Falcón",
    "capital": "minor",
    "population": 287558,
    "id": 1862535644
  },
  {
    "city": "Plano",
    "city_ascii": "Plano",
    "lat": 33.0502,
    "lng": -96.7487,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 287677,
    "id": 1840020662
  },
  {
    "city": "Irvine",
    "city_ascii": "Irvine",
    "lat": 33.6772,
    "lng": -117.7738,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 287401,
    "id": 1840019325
  },
  {
    "city": "Fukushima",
    "city_ascii": "Fukushima",
    "lat": 37.7608,
    "lng": 140.4733,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Fukushima",
    "capital": "admin",
    "population": 286742,
    "id": 1392570027
  },
  {
    "city": "Orléans",
    "city_ascii": "Orleans",
    "lat": 47.9025,
    "lng": 1.909,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Centre-Val de Loire",
    "capital": "admin",
    "population": 282828,
    "id": 1250441405
  },
  {
    "city": "Hospet",
    "city_ascii": "Hospet",
    "lat": 15.2667,
    "lng": 76.4,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Karnātaka",
    "capital": "",
    "population": 286007,
    "id": 1356241463
  },
  {
    "city": "Tacna",
    "city_ascii": "Tacna",
    "lat": -18.0556,
    "lng": -70.2483,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "Tacna",
    "capital": "admin",
    "population": 286240,
    "id": 1604388134
  },
  {
    "city": "Korhogo",
    "city_ascii": "Korhogo",
    "lat": 9.4578,
    "lng": -5.6294,
    "country": "Côte D’Ivoire",
    "iso2": "CI",
    "iso3": "CIV",
    "admin_name": "Savanes",
    "capital": "admin",
    "population": 286071,
    "id": 1384205840
  },
  {
    "city": "Constanţa",
    "city_ascii": "Constanta",
    "lat": 44.1733,
    "lng": 28.6383,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Constanţa",
    "capital": "admin",
    "population": 283872,
    "id": 1642851858
  },
  {
    "city": "Haifa",
    "city_ascii": "Haifa",
    "lat": 32.8,
    "lng": 34.9833,
    "country": "Israel",
    "iso2": "IL",
    "iso3": "ISR",
    "admin_name": "Haifa",
    "capital": "admin",
    "population": 281087,
    "id": 1376133727
  },
  {
    "city": "Coacalco",
    "city_ascii": "Coacalco",
    "lat": 19.6333,
    "lng": -99.0931,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "México",
    "capital": "minor",
    "population": 278064,
    "id": 1484929782
  },
  {
    "city": "Crato",
    "city_ascii": "Crato",
    "lat": -7.2342,
    "lng": -39.4094,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Ceará",
    "capital": "",
    "population": 273883,
    "id": 1076894967
  },
  {
    "city": "Limoges",
    "city_ascii": "Limoges",
    "lat": 45.8353,
    "lng": 1.2625,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Nouvelle-Aquitaine",
    "capital": "minor",
    "population": 283823,
    "id": 1250736774
  },
  {
    "city": "Davenport",
    "city_ascii": "Davenport",
    "lat": 41.5563,
    "lng": -90.6052,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Iowa",
    "capital": "",
    "population": 285362,
    "id": 1840007081
  },
  {
    "city": "Coro",
    "city_ascii": "Coro",
    "lat": 11.395,
    "lng": -69.6816,
    "country": "Venezuela",
    "iso2": "VE",
    "iso3": "VEN",
    "admin_name": "Falcón",
    "capital": "admin",
    "population": 284266,
    "id": 1862429150
  },
  {
    "city": "Bago",
    "city_ascii": "Bago",
    "lat": 17.3433,
    "lng": 96.4981,
    "country": "Burma",
    "iso2": "MM",
    "iso3": "MMR",
    "admin_name": "Bago",
    "capital": "admin",
    "population": 284179,
    "id": 1104027443
  },
  {
    "city": "Fuquan",
    "city_ascii": "Fuquan",
    "lat": 26.7039,
    "lng": 107.5087,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Guizhou",
    "capital": "",
    "population": 283904,
    "id": 1156672189
  },
  {
    "city": "Tongchuanshi",
    "city_ascii": "Tongchuanshi",
    "lat": 35.08,
    "lng": 109.03,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Shaanxi",
    "capital": "",
    "population": 282258,
    "id": 1156606433
  },
  {
    "city": "Āīzawl",
    "city_ascii": "Aizawl",
    "lat": 23.7104,
    "lng": 92.72,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Mizoram",
    "capital": "admin",
    "population": 283021,
    "id": 1356342768
  },
  {
    "city": "Taboão da Serra",
    "city_ascii": "Taboao da Serra",
    "lat": -23.6019,
    "lng": -46.7528,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 272177,
    "id": 1076000585
  },
  {
    "city": "Sannai",
    "city_ascii": "Sannai",
    "lat": 24.16,
    "lng": 80.83,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Madhya Pradesh",
    "capital": "",
    "population": 283004,
    "id": 1356009968
  },
  {
    "city": "Huozhou",
    "city_ascii": "Huozhou",
    "lat": 36.5726,
    "lng": 111.7176,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Shanxi",
    "capital": "",
    "population": 282905,
    "id": 1156917368
  },
  {
    "city": "Temuco",
    "city_ascii": "Temuco",
    "lat": -38.7399,
    "lng": -72.5901,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Araucanía",
    "capital": "admin",
    "population": 282415,
    "id": 1152318955
  },
  {
    "city": "Sterlitamak",
    "city_ascii": "Sterlitamak",
    "lat": 53.6333,
    "lng": 55.95,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Bashkortostan",
    "capital": "",
    "population": 280233,
    "id": 1643812452
  },
  {
    "city": "Rockford",
    "city_ascii": "Rockford",
    "lat": 42.2597,
    "lng": -89.0641,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 282762,
    "id": 1840009132
  },
  {
    "city": "Tegal",
    "city_ascii": "Tegal",
    "lat": -6.8667,
    "lng": 109.1333,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Jawa Tengah",
    "capital": "",
    "population": 275789,
    "id": 1360035814
  },
  {
    "city": "Ica",
    "city_ascii": "Ica",
    "lat": -14.0667,
    "lng": -75.7333,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "Ica",
    "capital": "admin",
    "population": 282407,
    "id": 1604607907
  },
  {
    "city": "Newark",
    "city_ascii": "Newark",
    "lat": 40.7245,
    "lng": -74.1725,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 282011,
    "id": 1840002791
  },
  {
    "city": "Jining",
    "city_ascii": "Jining",
    "lat": 41.03,
    "lng": 113.08,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Inner Mongolia",
    "capital": "",
    "population": 281716,
    "id": 1156261326
  },
  {
    "city": "Chuncheon",
    "city_ascii": "Chuncheon",
    "lat": 37.8747,
    "lng": 127.7342,
    "country": "Korea, South",
    "iso2": "KR",
    "iso3": "KOR",
    "admin_name": "Gangwon",
    "capital": "admin",
    "population": 281596,
    "id": 1410231130
  },
  {
    "city": "Malārd",
    "city_ascii": "Malard",
    "lat": 35.6658,
    "lng": 50.9767,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Tehrān",
    "capital": "minor",
    "population": 281027,
    "id": 1364376065
  },
  {
    "city": "Pematangsiantar",
    "city_ascii": "Pematangsiantar",
    "lat": 2.96,
    "lng": 99.06,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Sumatera Utara",
    "capital": "",
    "population": 278055,
    "id": 1360769496
  },
  {
    "city": "Long Xuyên",
    "city_ascii": "Long Xuyen",
    "lat": 10.3686,
    "lng": 105.4234,
    "country": "Vietnam",
    "iso2": "VN",
    "iso3": "VNM",
    "admin_name": "An Giang",
    "capital": "admin",
    "population": 278658,
    "id": 1704453892
  },
  {
    "city": "Petrozavodsk",
    "city_ascii": "Petrozavodsk",
    "lat": 61.7833,
    "lng": 34.35,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kareliya",
    "capital": "admin",
    "population": 278551,
    "id": 1643319966
  },
  {
    "city": "South Bend",
    "city_ascii": "South Bend",
    "lat": 41.6767,
    "lng": -86.2696,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 280498,
    "id": 1840009241
  },
  {
    "city": "Mingaora",
    "city_ascii": "Mingaora",
    "lat": 34.7717,
    "lng": 72.36,
    "country": "Pakistan",
    "iso2": "PK",
    "iso3": "PAK",
    "admin_name": "Khyber Pakhtunkhwa",
    "capital": "",
    "population": 279914,
    "id": 1586782526
  },
  {
    "city": "Bārāsat",
    "city_ascii": "Barasat",
    "lat": 22.2333,
    "lng": 88.45,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "West Bengal",
    "capital": "",
    "population": 278435,
    "id": 1356550583
  },
  {
    "city": "Shreveport",
    "city_ascii": "Shreveport",
    "lat": 32.4656,
    "lng": -93.7956,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 280062,
    "id": 1840015768
  },
  {
    "city": "Sincelejo",
    "city_ascii": "Sincelejo",
    "lat": 9.2994,
    "lng": -75.3958,
    "country": "Colombia",
    "iso2": "CO",
    "iso3": "COL",
    "admin_name": "Sucre",
    "capital": "admin",
    "population": 279031,
    "id": 1170364198
  },
  {
    "city": "Việt Trì",
    "city_ascii": "Viet Tri",
    "lat": 21.3228,
    "lng": 105.4019,
    "country": "Vietnam",
    "iso2": "VN",
    "iso3": "VNM",
    "admin_name": "Phú Thọ",
    "capital": "admin",
    "population": 277539,
    "id": 1704332956
  },
  {
    "city": "Cherkasy",
    "city_ascii": "Cherkasy",
    "lat": 49.4444,
    "lng": 32.0597,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Cherkas’ka Oblast’",
    "capital": "admin",
    "population": 276360,
    "id": 1804459212
  },
  {
    "city": "Wiesbaden",
    "city_ascii": "Wiesbaden",
    "lat": 50.0825,
    "lng": 8.24,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "admin",
    "population": 278342,
    "id": 1276652118
  },
  {
    "city": "Kostroma",
    "city_ascii": "Kostroma",
    "lat": 57.7667,
    "lng": 40.9333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kostromskaya Oblast’",
    "capital": "admin",
    "population": 277648,
    "id": 1643869304
  },
  {
    "city": "Round Lake Beach",
    "city_ascii": "Round Lake Beach",
    "lat": 42.379,
    "lng": -88.0811,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 279381,
    "id": 1840011171
  },
  {
    "city": "Gyeongsan",
    "city_ascii": "Gyeongsan",
    "lat": 35.8167,
    "lng": 128.7333,
    "country": "Korea, South",
    "iso2": "KR",
    "iso3": "KOR",
    "admin_name": "Gyeongbuk",
    "capital": "",
    "population": 278500,
    "id": 1410545168
  },
  {
    "city": "Katowice",
    "city_ascii": "Katowice",
    "lat": 50.25,
    "lng": 19,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Śląskie",
    "capital": "admin",
    "population": 276499,
    "id": 1616798495
  },
  {
    "city": "Shaowu",
    "city_ascii": "Shaowu",
    "lat": 27.3417,
    "lng": 117.4869,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Fujian",
    "capital": "minor",
    "population": 278000,
    "id": 1156978131
  },
  {
    "city": "Linxia Chengguanzhen",
    "city_ascii": "Linxia Chengguanzhen",
    "lat": 35.6,
    "lng": 103.2167,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Gansu",
    "capital": "minor",
    "population": 274466,
    "id": 1156043522
  },
  {
    "city": "Pachuca",
    "city_ascii": "Pachuca",
    "lat": 20.1,
    "lng": -98.75,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Hidalgo",
    "capital": "admin",
    "population": 277375,
    "id": 1484575026
  },
  {
    "city": "Dire Dawa",
    "city_ascii": "Dire Dawa",
    "lat": 9.5833,
    "lng": 41.8667,
    "country": "Ethiopia",
    "iso2": "ET",
    "iso3": "ETH",
    "admin_name": "Dirē Dawa",
    "capital": "admin",
    "population": 277000,
    "id": 1231089714
  },
  {
    "city": "Khmelnytskyi",
    "city_ascii": "Khmelnytskyi",
    "lat": 49.42,
    "lng": 27,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Khmel’nyts’ka Oblast’",
    "capital": "admin",
    "population": 274176,
    "id": 1804347108
  },
  {
    "city": "Aomori",
    "city_ascii": "Aomori",
    "lat": 40.8222,
    "lng": 140.7475,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Aomori",
    "capital": "admin",
    "population": 276567,
    "id": 1392083898
  },
  {
    "city": "Owo",
    "city_ascii": "Owo",
    "lat": 7.2004,
    "lng": 5.59,
    "country": "Nigeria",
    "iso2": "NG",
    "iso3": "NGA",
    "admin_name": "Ondo",
    "capital": "minor",
    "population": 276574,
    "id": 1566833788
  },
  {
    "city": "Savannah",
    "city_ascii": "Savannah",
    "lat": 32.0281,
    "lng": -81.1784,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 276295,
    "id": 1840015830
  },
  {
    "city": "Posadas",
    "city_ascii": "Posadas",
    "lat": -27.3667,
    "lng": -55.8969,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Misiones",
    "capital": "admin",
    "population": 275988,
    "id": 1032970696
  },
  {
    "city": "Gatineau",
    "city_ascii": "Gatineau",
    "lat": 45.4833,
    "lng": -75.65,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 276245,
    "id": 1124722129
  },
  {
    "city": "Windsor",
    "city_ascii": "Windsor",
    "lat": 42.2833,
    "lng": -83,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 276165,
    "id": 1124261024
  },
  {
    "city": "Tsu",
    "city_ascii": "Tsu",
    "lat": 34.7186,
    "lng": 136.5056,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Mie",
    "capital": "admin",
    "population": 275371,
    "id": 1392082102
  },
  {
    "city": "Myrtle Beach",
    "city_ascii": "Myrtle Beach",
    "lat": 33.7096,
    "lng": -78.8843,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 275579,
    "id": 1840014717
  },
  {
    "city": "Mbandaka",
    "city_ascii": "Mbandaka",
    "lat": 0.0486,
    "lng": 18.2603,
    "country": "Congo (Kinshasa)",
    "iso2": "CD",
    "iso3": "COD",
    "admin_name": "Équateur",
    "capital": "admin",
    "population": 274996,
    "id": 1180720887
  },
  {
    "city": "Tehuacán",
    "city_ascii": "Tehuacan",
    "lat": 18.4617,
    "lng": -97.3928,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Puebla",
    "capital": "minor",
    "population": 274906,
    "id": 1484817336
  },
  {
    "city": "La Guaira",
    "city_ascii": "La Guaira",
    "lat": 10.6,
    "lng": -66.9331,
    "country": "Venezuela",
    "iso2": "VE",
    "iso3": "VEN",
    "admin_name": "Vargas",
    "capital": "admin",
    "population": 275000,
    "id": 1862282663
  },
  {
    "city": "Kunsan",
    "city_ascii": "Kunsan",
    "lat": 35.9786,
    "lng": 126.7114,
    "country": "Korea, South",
    "iso2": "KR",
    "iso3": "KOR",
    "admin_name": "Jeonbuk",
    "capital": "",
    "population": 275155,
    "id": 1410913202
  },
  {
    "city": "Nizhnevartovsk",
    "city_ascii": "Nizhnevartovsk",
    "lat": 60.9389,
    "lng": 76.595,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Khanty-Mansiyskiy Avtonomnyy Okrug-Yugra",
    "capital": "",
    "population": 274575,
    "id": 1643786132
  },
  {
    "city": "Chula Vista",
    "city_ascii": "Chula Vista",
    "lat": 32.6281,
    "lng": -117.0145,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 274492,
    "id": 1840019350
  },
  {
    "city": "Ratlām",
    "city_ascii": "Ratlam",
    "lat": 23.3167,
    "lng": 75.0667,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Madhya Pradesh",
    "capital": "",
    "population": 273892,
    "id": 1356241663
  },
  {
    "city": "Yeosu",
    "city_ascii": "Yeosu",
    "lat": 34.7607,
    "lng": 127.6622,
    "country": "Korea, South",
    "iso2": "KR",
    "iso3": "KOR",
    "admin_name": "Jeonnam",
    "capital": "minor",
    "population": 273761,
    "id": 1410698161
  },
  {
    "city": "Crato",
    "city_ascii": "Crato",
    "lat": -7.4639,
    "lng": -63.04,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Amazonas",
    "capital": "",
    "population": 273883,
    "id": 1076425706
  },
  {
    "city": "Derby",
    "city_ascii": "Derby",
    "lat": 52.9167,
    "lng": -1.4667,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Derby",
    "capital": "",
    "population": 270500,
    "id": 1826333435
  },
  {
    "city": "Kafr ad Dawwār",
    "city_ascii": "Kafr ad Dawwar",
    "lat": 31.1417,
    "lng": 30.1272,
    "country": "Egypt",
    "iso2": "EG",
    "iso3": "EGY",
    "admin_name": "Al Buḩayrah",
    "capital": "",
    "population": 262748,
    "id": 1818347704
  },
  {
    "city": "Eugene",
    "city_ascii": "Eugene",
    "lat": 44.0563,
    "lng": -123.1173,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 273439,
    "id": 1840020007
  },
  {
    "city": "Gijón",
    "city_ascii": "Gijon",
    "lat": 43.5293,
    "lng": -5.6773,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Asturias",
    "capital": "",
    "population": 271780,
    "id": 1724170659
  },
  {
    "city": "Chiayi",
    "city_ascii": "Chiayi",
    "lat": 23.48,
    "lng": 120.4497,
    "country": "Taiwan",
    "iso2": "TW",
    "iso3": "TWN",
    "admin_name": "Chiayi",
    "capital": "admin",
    "population": 268622,
    "id": 1158867217
  },
  {
    "city": "Fuchū",
    "city_ascii": "Fuchu",
    "lat": 35.6689,
    "lng": 139.4778,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Tōkyō",
    "capital": "",
    "population": 263932,
    "id": 1392370709
  },
  {
    "city": "Palmas",
    "city_ascii": "Palmas",
    "lat": -10.2128,
    "lng": -48.3603,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Tocantins",
    "capital": "admin",
    "population": 272726,
    "id": 1076052802
  },
  {
    "city": "Craiova",
    "city_ascii": "Craiova",
    "lat": 44.3333,
    "lng": 23.8167,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Dolj",
    "capital": "admin",
    "population": 269506,
    "id": 1642859458
  },
  {
    "city": "Maradi",
    "city_ascii": "Maradi",
    "lat": 13.5,
    "lng": 7.1,
    "country": "Niger",
    "iso2": "NE",
    "iso3": "NER",
    "admin_name": "Maradi",
    "capital": "admin",
    "population": 267249,
    "id": 1562200314
  },
  {
    "city": "Sorong",
    "city_ascii": "Sorong",
    "lat": -0.8667,
    "lng": 131.25,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Papua Barat",
    "capital": "",
    "population": 272349,
    "id": 1360018780
  },
  {
    "city": "Majene",
    "city_ascii": "Majene",
    "lat": -3.5336,
    "lng": 118.966,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Sulawesi Barat",
    "capital": "minor",
    "population": 272377,
    "id": 1360498576
  },
  {
    "city": "Bukhara",
    "city_ascii": "Bukhara",
    "lat": 39.7747,
    "lng": 64.4286,
    "country": "Uzbekistan",
    "iso2": "UZ",
    "iso3": "UZB",
    "admin_name": "Buxoro",
    "capital": "admin",
    "population": 272500,
    "id": 1860523678
  },
  {
    "city": "Thái Bình",
    "city_ascii": "Thai Binh",
    "lat": 20.4461,
    "lng": 106.3422,
    "country": "Vietnam",
    "iso2": "VN",
    "iso3": "VNM",
    "admin_name": "Thái Bình",
    "capital": "admin",
    "population": 268167,
    "id": 1704716027
  },
  {
    "city": "Binjai",
    "city_ascii": "Binjai",
    "lat": 3.6,
    "lng": 98.4853,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Sumatera Utara",
    "capital": "",
    "population": 269053,
    "id": 1360884608
  },
  {
    "city": "Oral",
    "city_ascii": "Oral",
    "lat": 51.2333,
    "lng": 51.3667,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Batys Qazaqstan",
    "capital": "admin",
    "population": 271900,
    "id": 1398593972
  },
  {
    "city": "Brāhmanbāria",
    "city_ascii": "Brahmanbaria",
    "lat": 23.9667,
    "lng": 91.1,
    "country": "Bangladesh",
    "iso2": "BD",
    "iso3": "BGD",
    "admin_name": "Chittagong",
    "capital": "",
    "population": 268279,
    "id": 1050193121
  },
  {
    "city": "Sartā",
    "city_ascii": "Sarta",
    "lat": 36.5504,
    "lng": 53.1,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Māzandarān",
    "capital": "",
    "population": 271467,
    "id": 1364143380
  },
  {
    "city": "Imphāl",
    "city_ascii": "Imphal",
    "lat": 24.82,
    "lng": 93.95,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Manipur",
    "capital": "admin",
    "population": 268243,
    "id": 1356107172
  },
  {
    "city": "Plymouth",
    "city_ascii": "Plymouth",
    "lat": 50.3714,
    "lng": -4.1422,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Plymouth",
    "capital": "",
    "population": 267918,
    "id": 1826947183
  },
  {
    "city": "Ichihara",
    "city_ascii": "Ichihara",
    "lat": 35.4981,
    "lng": 140.1156,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Chiba",
    "capital": "",
    "population": 270478,
    "id": 1392167256
  },
  {
    "city": "Santo Domingo de los Colorados",
    "city_ascii": "Santo Domingo de los Colorados",
    "lat": -0.2542,
    "lng": -79.1719,
    "country": "Ecuador",
    "iso2": "EC",
    "iso3": "ECU",
    "admin_name": "Santo Domingo de los Tsáchilas",
    "capital": "admin",
    "population": 270875,
    "id": 1218148017
  },
  {
    "city": "Novorossiysk",
    "city_ascii": "Novorossiysk",
    "lat": 44.7167,
    "lng": 37.7667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Krasnodarskiy Kray",
    "capital": "",
    "population": 270774,
    "id": 1643087180
  },
  {
    "city": "Santa Ana",
    "city_ascii": "Santa Ana",
    "lat": 13.9833,
    "lng": -89.5333,
    "country": "El Salvador",
    "iso2": "SV",
    "iso3": "SLV",
    "admin_name": "Santa Ana",
    "capital": "admin",
    "population": 270413,
    "id": 1222021362
  },
  {
    "city": "Zhytomyr",
    "city_ascii": "Zhytomyr",
    "lat": 50.2544,
    "lng": 28.6578,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Zhytomyrs’ka Oblast’",
    "capital": "admin",
    "population": 266936,
    "id": 1804540656
  },
  {
    "city": "Mito",
    "city_ascii": "Mito",
    "lat": 36.3667,
    "lng": 140.4667,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Ibaraki",
    "capital": "admin",
    "population": 269661,
    "id": 1392665227
  },
  {
    "city": "Gombe",
    "city_ascii": "Gombe",
    "lat": 10.2904,
    "lng": 11.17,
    "country": "Nigeria",
    "iso2": "NG",
    "iso3": "NGA",
    "admin_name": "Gombe",
    "capital": "admin",
    "population": 270366,
    "id": 1566855445
  },
  {
    "city": "Espoo",
    "city_ascii": "Espoo",
    "lat": 60.21,
    "lng": 24.66,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Uusimaa",
    "capital": "minor",
    "population": 269802,
    "id": 1246357316
  },
  {
    "city": "Drug",
    "city_ascii": "Drug",
    "lat": 21.19,
    "lng": 81.28,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Chhattīsgarh",
    "capital": "",
    "population": 268806,
    "id": 1356910418
  },
  {
    "city": "Bamenda",
    "city_ascii": "Bamenda",
    "lat": 5.9333,
    "lng": 10.1667,
    "country": "Cameroon",
    "iso2": "CM",
    "iso3": "CMR",
    "admin_name": "North-West",
    "capital": "admin",
    "population": 269530,
    "id": 1120909610
  },
  {
    "city": "Handwāra",
    "city_ascii": "Handwara",
    "lat": 34.4,
    "lng": 74.28,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Jammu and Kashmīr",
    "capital": "",
    "population": 269311,
    "id": 1356080179
  },
  {
    "city": "Lucena",
    "city_ascii": "Lucena",
    "lat": 13.9333,
    "lng": 121.6167,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Lucena",
    "capital": "admin",
    "population": 266248,
    "id": 1608000461
  },
  {
    "city": "Canton",
    "city_ascii": "Canton",
    "lat": 40.8075,
    "lng": -81.3677,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 269418,
    "id": 1840000963
  },
  {
    "city": "Yoshkar-Ola",
    "city_ascii": "Yoshkar-Ola",
    "lat": 56.6328,
    "lng": 47.8958,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Mariy-El",
    "capital": "admin",
    "population": 266675,
    "id": 1643701904
  },
  {
    "city": "Nalchik",
    "city_ascii": "Nalchik",
    "lat": 43.4833,
    "lng": 43.6167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kabardino-Balkariya",
    "capital": "admin",
    "population": 265162,
    "id": 1643746064
  },
  {
    "city": "Aswān",
    "city_ascii": "Aswan",
    "lat": 24.0889,
    "lng": 32.8997,
    "country": "Egypt",
    "iso2": "EG",
    "iso3": "EGY",
    "admin_name": "Aswān",
    "capital": "admin",
    "population": 267913,
    "id": 1818262512
  },
  {
    "city": "Salamanca",
    "city_ascii": "Salamanca",
    "lat": 20.5703,
    "lng": -101.1972,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Guanajuato",
    "capital": "minor",
    "population": 260759,
    "id": 1484741627
  },
  {
    "city": "İnegöl",
    "city_ascii": "Inegol",
    "lat": 40.0806,
    "lng": 29.5097,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Bursa",
    "capital": "minor",
    "population": 268155,
    "id": 1792592205
  },
  {
    "city": "Chernivtsi",
    "city_ascii": "Chernivtsi",
    "lat": 48.2908,
    "lng": 25.9344,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Chernivets’ka Oblast’",
    "capital": "admin",
    "population": 266550,
    "id": 1804652872
  },
  {
    "city": "Yao",
    "city_ascii": "Yao",
    "lat": 34.6269,
    "lng": 135.6008,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Nara",
    "capital": "",
    "population": 266386,
    "id": 1392943074
  },
  {
    "city": "Sumaré",
    "city_ascii": "Sumare",
    "lat": -22.8219,
    "lng": -47.2669,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 265955,
    "id": 1076684312
  },
  {
    "city": "Lubbock",
    "city_ascii": "Lubbock",
    "lat": 33.5659,
    "lng": -101.8878,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 267648,
    "id": 1840020604
  },
  {
    "city": "Yanbu‘",
    "city_ascii": "Yanbu`",
    "lat": 24.0943,
    "lng": 38.0493,
    "country": "Saudi Arabia",
    "iso2": "SA",
    "iso3": "SAU",
    "admin_name": "Al Madīnah al Munawwarah",
    "capital": "",
    "population": 267590,
    "id": 1682000044
  },
  {
    "city": "Sumy",
    "city_ascii": "Sumy",
    "lat": 50.9068,
    "lng": 34.7992,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Sums’ka Oblast’",
    "capital": "admin",
    "population": 265758,
    "id": 1804489982
  },
  {
    "city": "Tshikapa",
    "city_ascii": "Tshikapa",
    "lat": -6.4167,
    "lng": 20.8,
    "country": "Congo (Kinshasa)",
    "iso2": "CD",
    "iso3": "COD",
    "admin_name": "Kasaï",
    "capital": "",
    "population": 267462,
    "id": 1180581239
  },
  {
    "city": "Anantapur",
    "city_ascii": "Anantapur",
    "lat": 14.6833,
    "lng": 77.6,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Andhra Pradesh",
    "capital": "",
    "population": 267161,
    "id": 1356296545
  },
  {
    "city": "San Pablo",
    "city_ascii": "San Pablo",
    "lat": 14.0667,
    "lng": 121.3167,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Laguna",
    "capital": "",
    "population": 266068,
    "id": 1608801564
  },
  {
    "city": "Reading",
    "city_ascii": "Reading",
    "lat": 40.34,
    "lng": -75.9267,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 267155,
    "id": 1840001185
  },
  {
    "city": "Winter Haven",
    "city_ascii": "Winter Haven",
    "lat": 28.0118,
    "lng": -81.7017,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 267069,
    "id": 1840015970
  },
  {
    "city": "Myeik",
    "city_ascii": "Myeik",
    "lat": 12.4394,
    "lng": 98.6003,
    "country": "Burma",
    "iso2": "MM",
    "iso3": "MMR",
    "admin_name": "Taninthayi",
    "capital": "",
    "population": 266720,
    "id": 1104715655
  },
  {
    "city": "Marabá",
    "city_ascii": "Maraba",
    "lat": -5.3689,
    "lng": -49.1178,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Pará",
    "capital": "",
    "population": 266932,
    "id": 1076974144
  },
  {
    "city": "Kütahya",
    "city_ascii": "Kutahya",
    "lat": 39.4242,
    "lng": 29.9833,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Kütahya",
    "capital": "admin",
    "population": 266784,
    "id": 1792671735
  },
  {
    "city": "Salem",
    "city_ascii": "Salem",
    "lat": 44.9231,
    "lng": -123.0246,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "admin",
    "population": 266804,
    "id": 1840021224
  },
  {
    "city": "Djelfa",
    "city_ascii": "Djelfa",
    "lat": 34.6667,
    "lng": 3.25,
    "country": "Algeria",
    "iso2": "DZ",
    "iso3": "DZA",
    "admin_name": "Djelfa",
    "capital": "admin",
    "population": 265833,
    "id": 1012268984
  },
  {
    "city": "Petrolina",
    "city_ascii": "Petrolina",
    "lat": -9.38,
    "lng": -40.51,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Pernambuco",
    "capital": "",
    "population": 260985,
    "id": 1076843988
  },
  {
    "city": "Suncheon",
    "city_ascii": "Suncheon",
    "lat": 34.9506,
    "lng": 127.4875,
    "country": "Korea, South",
    "iso2": "KR",
    "iso3": "KOR",
    "admin_name": "Jeonnam",
    "capital": "minor",
    "population": 265390,
    "id": 1410109654
  },
  {
    "city": "St. Petersburg",
    "city_ascii": "St. Petersburg",
    "lat": 27.7931,
    "lng": -82.6652,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 265351,
    "id": 1840015977
  },
  {
    "city": "Dera Ghazi Khan",
    "city_ascii": "Dera Ghazi Khan",
    "lat": 30.05,
    "lng": 70.6333,
    "country": "Pakistan",
    "iso2": "PK",
    "iso3": "PAK",
    "admin_name": "Punjab",
    "capital": "minor",
    "population": 264742,
    "id": 1586002238
  },
  {
    "city": "Kindu",
    "city_ascii": "Kindu",
    "lat": -2.95,
    "lng": 25.9167,
    "country": "Congo (Kinshasa)",
    "iso2": "CD",
    "iso3": "COD",
    "admin_name": "Maniema",
    "capital": "admin",
    "population": 262914,
    "id": 1180106925
  },
  {
    "city": "Oruro",
    "city_ascii": "Oruro",
    "lat": -17.9667,
    "lng": -67.1167,
    "country": "Bolivia",
    "iso2": "BO",
    "iso3": "BOL",
    "admin_name": "Oruro",
    "capital": "",
    "population": 264683,
    "id": 1068626742
  },
  {
    "city": "Dezfūl",
    "city_ascii": "Dezful",
    "lat": 32.3878,
    "lng": 48.4033,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Khūzestān",
    "capital": "minor",
    "population": 264709,
    "id": 1364663520
  },
  {
    "city": "Osmaniye",
    "city_ascii": "Osmaniye",
    "lat": 37.075,
    "lng": 36.25,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Osmaniye",
    "capital": "admin",
    "population": 264373,
    "id": 1792340207
  },
  {
    "city": "Lafayette",
    "city_ascii": "Lafayette",
    "lat": 30.2084,
    "lng": -92.0323,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 264357,
    "id": 1840015044
  },
  {
    "city": "Kyŏngju",
    "city_ascii": "Kyongju",
    "lat": 35.85,
    "lng": 129.2167,
    "country": "Korea, South",
    "iso2": "KR",
    "iso3": "KOR",
    "admin_name": "Gyeongbuk",
    "capital": "",
    "population": 264091,
    "id": 1410173146
  },
  {
    "city": "Dumai",
    "city_ascii": "Dumai",
    "lat": 1.6667,
    "lng": 101.45,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Riau",
    "capital": "",
    "population": 264084,
    "id": 1360153292
  },
  {
    "city": "Kakogawachō-honmachi",
    "city_ascii": "Kakogawacho-honmachi",
    "lat": 34.7569,
    "lng": 134.8414,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hyōgo",
    "capital": "",
    "population": 262308,
    "id": 1392189230
  },
  {
    "city": "Gent",
    "city_ascii": "Gent",
    "lat": 51.0536,
    "lng": 3.7253,
    "country": "Belgium",
    "iso2": "BE",
    "iso3": "BEL",
    "admin_name": "Flanders",
    "capital": "minor",
    "population": 262219,
    "id": 1056062897
  },
  {
    "city": "Geelong",
    "city_ascii": "Geelong",
    "lat": -38.15,
    "lng": 144.35,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Victoria",
    "capital": "",
    "population": 263280,
    "id": 1036870987
  },
  {
    "city": "Çorlu",
    "city_ascii": "Corlu",
    "lat": 41.1597,
    "lng": 27.8028,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Tekirdağ",
    "capital": "minor",
    "population": 262862,
    "id": 1792855069
  },
  {
    "city": "Nawabshah",
    "city_ascii": "Nawabshah",
    "lat": 26.2442,
    "lng": 68.41,
    "country": "Pakistan",
    "iso2": "PK",
    "iso3": "PAK",
    "admin_name": "Sindh",
    "capital": "minor",
    "population": 263102,
    "id": 1586294005
  },
  {
    "city": "Gelsenkirchen",
    "city_ascii": "Gelsenkirchen",
    "lat": 51.5167,
    "lng": 7.1,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "minor",
    "population": 260654,
    "id": 1276788537
  },
  {
    "city": "Fukui",
    "city_ascii": "Fukui",
    "lat": 36.0641,
    "lng": 136.2196,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Fukui",
    "capital": "admin",
    "population": 262530,
    "id": 1392897510
  },
  {
    "city": "Mönchengladbach",
    "city_ascii": "Monchengladbach",
    "lat": 51.2,
    "lng": 6.4333,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "minor",
    "population": 261454,
    "id": 1276982173
  },
  {
    "city": "Bordeaux",
    "city_ascii": "Bordeaux",
    "lat": 44.84,
    "lng": -0.58,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Nouvelle-Aquitaine",
    "capital": "admin",
    "population": 257804,
    "id": 1250449238
  },
  {
    "city": "Annaba",
    "city_ascii": "Annaba",
    "lat": 36.9,
    "lng": 7.7667,
    "country": "Algeria",
    "iso2": "DZ",
    "iso3": "DZA",
    "admin_name": "Annaba",
    "capital": "admin",
    "population": 257359,
    "id": 1012600001
  },
  {
    "city": "Venice",
    "city_ascii": "Venice",
    "lat": 45.4397,
    "lng": 12.3319,
    "country": "Italy",
    "iso2": "IT",
    "iso3": "ITA",
    "admin_name": "Veneto",
    "capital": "admin",
    "population": 261905,
    "id": 1380660414
  },
  {
    "city": "Barueri",
    "city_ascii": "Barueri",
    "lat": -23.5111,
    "lng": -46.8764,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 262275,
    "id": 1076211114
  },
  {
    "city": "Nonthaburi",
    "city_ascii": "Nonthaburi",
    "lat": 13.8667,
    "lng": 100.5167,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Nonthaburi",
    "capital": "admin",
    "population": 255671,
    "id": 1764667935
  },
  {
    "city": "Laredo",
    "city_ascii": "Laredo",
    "lat": 27.5629,
    "lng": -99.4875,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 262079,
    "id": 1840021002
  },
  {
    "city": "Jersey City",
    "city_ascii": "Jersey City",
    "lat": 40.7161,
    "lng": -74.0682,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 262075,
    "id": 1840003600
  },
  {
    "city": "Quảng Ngãi",
    "city_ascii": "Quang Ngai",
    "lat": 15.1206,
    "lng": 108.7922,
    "country": "Vietnam",
    "iso2": "VN",
    "iso3": "VNM",
    "admin_name": "Quảng Ngãi",
    "capital": "admin",
    "population": 260252,
    "id": 1704029499
  },
  {
    "city": "Concord",
    "city_ascii": "Concord",
    "lat": 35.3933,
    "lng": -80.6366,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 261801,
    "id": 1840014559
  },
  {
    "city": "Damanhūr",
    "city_ascii": "Damanhur",
    "lat": 31.05,
    "lng": 30.4667,
    "country": "Egypt",
    "iso2": "EG",
    "iso3": "EGY",
    "admin_name": "Al Buḩayrah",
    "capital": "admin",
    "population": 244043,
    "id": 1818708838
  },
  {
    "city": "Dongta",
    "city_ascii": "Dongta",
    "lat": 38.0937,
    "lng": 106.3321,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Ningxia",
    "capital": "minor",
    "population": 261677,
    "id": 1156958987
  },
  {
    "city": "Ciudad Benito Juárez",
    "city_ascii": "Ciudad Benito Juarez",
    "lat": 25.65,
    "lng": -100.0833,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Nuevo León",
    "capital": "minor",
    "population": 260683,
    "id": 1484140727
  },
  {
    "city": "Cascavel",
    "city_ascii": "Cascavel",
    "lat": -24.9558,
    "lng": -53.4553,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Paraná",
    "capital": "",
    "population": 257172,
    "id": 1076509946
  },
  {
    "city": "Hiratsuka",
    "city_ascii": "Hiratsuka",
    "lat": 35.3231,
    "lng": 139.3422,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Kanagawa",
    "capital": "",
    "population": 257729,
    "id": 1392311050
  },
  {
    "city": "Columbus",
    "city_ascii": "Columbus",
    "lat": 32.51,
    "lng": -84.8771,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 261445,
    "id": 1840014887
  },
  {
    "city": "Chandler",
    "city_ascii": "Chandler",
    "lat": 33.2826,
    "lng": -111.8516,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 261165,
    "id": 1840019309
  },
  {
    "city": "Governador Valadares",
    "city_ascii": "Governador Valadares",
    "lat": -18.87,
    "lng": -41.97,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Minas Gerais",
    "capital": "",
    "population": 250878,
    "id": 1076271003
  },
  {
    "city": "Sakarya",
    "city_ascii": "Sakarya",
    "lat": 40.7778,
    "lng": 30.4028,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Sakarya",
    "capital": "admin",
    "population": 260109,
    "id": 1792191587
  },
  {
    "city": "Tagum",
    "city_ascii": "Tagum",
    "lat": 7.4478,
    "lng": 125.8078,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Davao del Norte",
    "capital": "admin",
    "population": 259444,
    "id": 1608755366
  },
  {
    "city": "Kunduz",
    "city_ascii": "Kunduz",
    "lat": 36.728,
    "lng": 68.8725,
    "country": "Afghanistan",
    "iso2": "AF",
    "iso3": "AFG",
    "admin_name": "Kunduz",
    "capital": "admin",
    "population": 259809,
    "id": 1004227517
  },
  {
    "city": "Ciudad Santa Catarina",
    "city_ascii": "Ciudad Santa Catarina",
    "lat": 25.6833,
    "lng": -100.45,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Nuevo León",
    "capital": "minor",
    "population": 259896,
    "id": 1484858068
  },
  {
    "city": "Los Mochis",
    "city_ascii": "Los Mochis",
    "lat": 25.7835,
    "lng": -108.9937,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Sinaloa",
    "capital": "minor",
    "population": 256613,
    "id": 1484443585
  },
  {
    "city": "Sōka",
    "city_ascii": "Soka",
    "lat": 35.8256,
    "lng": 139.8056,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Saitama",
    "capital": "",
    "population": 250939,
    "id": 1392354483
  },
  {
    "city": "Texcoco",
    "city_ascii": "Texcoco",
    "lat": 19.52,
    "lng": -98.88,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "México",
    "capital": "minor",
    "population": 259308,
    "id": 1484653265
  },
  {
    "city": "Mişrātah",
    "city_ascii": "Misratah",
    "lat": 32.3778,
    "lng": 15.0901,
    "country": "Libya",
    "iso2": "LY",
    "iso3": "LBY",
    "admin_name": "Mişrātah",
    "capital": "admin",
    "population": 259056,
    "id": 1434518407
  },
  {
    "city": "Isparta",
    "city_ascii": "Isparta",
    "lat": 37.7667,
    "lng": 30.55,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Isparta",
    "capital": "admin",
    "population": 258375,
    "id": 1792105249
  },
  {
    "city": "Etāwah",
    "city_ascii": "Etawah",
    "lat": 26.7855,
    "lng": 79.015,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Uttar Pradesh",
    "capital": "",
    "population": 257448,
    "id": 1356241312
  },
  {
    "city": "Huayin",
    "city_ascii": "Huayin",
    "lat": 34.5664,
    "lng": 110.0866,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Shaanxi",
    "capital": "",
    "population": 258113,
    "id": 1156156462
  },
  {
    "city": "McKinney",
    "city_ascii": "McKinney",
    "lat": 33.2015,
    "lng": -96.6669,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 258277,
    "id": 1840020657
  },
  {
    "city": "Playa del Carmen",
    "city_ascii": "Playa del Carmen",
    "lat": 20.6275,
    "lng": -87.0811,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Quintana Roo",
    "capital": "minor",
    "population": 252087,
    "id": 1484559458
  },
  {
    "city": "Scottsdale",
    "city_ascii": "Scottsdale",
    "lat": 33.6872,
    "lng": -111.8651,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 258069,
    "id": 1840021940
  },
  {
    "city": "Wolverhampton",
    "city_ascii": "Wolverhampton",
    "lat": 52.5833,
    "lng": -2.1333,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Wolverhampton",
    "capital": "",
    "population": 254406,
    "id": 1826636841
  },
  {
    "city": "Killeen",
    "city_ascii": "Killeen",
    "lat": 31.0754,
    "lng": -97.7296,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 258009,
    "id": 1840020854
  },
  {
    "city": "Bergen",
    "city_ascii": "Bergen",
    "lat": 60.3925,
    "lng": 5.3233,
    "country": "Norway",
    "iso2": "NO",
    "iso3": "NOR",
    "admin_name": "Hordaland",
    "capital": "admin",
    "population": 257087,
    "id": 1578465701
  },
  {
    "city": "Tallahassee",
    "city_ascii": "Tallahassee",
    "lat": 30.4551,
    "lng": -84.2526,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "admin",
    "population": 257601,
    "id": 1840015913
  },
  {
    "city": "Shinozaki",
    "city_ascii": "Shinozaki",
    "lat": 33.95,
    "lng": 130.9333,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Fukuoka",
    "capital": "",
    "population": 257038,
    "id": 1392516557
  },
  {
    "city": "Horlivka",
    "city_ascii": "Horlivka",
    "lat": 48.3336,
    "lng": 38.0925,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Donets’ka Oblast’",
    "capital": "",
    "population": 256714,
    "id": 1804235146
  },
  {
    "city": "Antsirabe",
    "city_ascii": "Antsirabe",
    "lat": -19.8667,
    "lng": 47.0333,
    "country": "Madagascar",
    "iso2": "MG",
    "iso3": "MDG",
    "admin_name": "Antananarivo",
    "capital": "minor",
    "population": 257163,
    "id": 1450791699
  },
  {
    "city": "Ondo",
    "city_ascii": "Ondo",
    "lat": 7.0904,
    "lng": 4.84,
    "country": "Nigeria",
    "iso2": "NG",
    "iso3": "NGA",
    "admin_name": "Ondo",
    "capital": "minor",
    "population": 257005,
    "id": 1566993724
  },
  {
    "city": "Hakodate",
    "city_ascii": "Hakodate",
    "lat": 41.7733,
    "lng": 140.7261,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hokkaidō",
    "capital": "",
    "population": 256222,
    "id": 1392708558
  },
  {
    "city": "Ấp Đa Lợi",
    "city_ascii": "Ap Da Loi",
    "lat": 11.9304,
    "lng": 108.42,
    "country": "Vietnam",
    "iso2": "VN",
    "iso3": "VNM",
    "admin_name": "Lâm Đồng",
    "capital": "",
    "population": 256019,
    "id": 1704613422
  },
  {
    "city": "Foz do Iguaçu",
    "city_ascii": "Foz do Iguacu",
    "lat": -25.5478,
    "lng": -54.5878,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Paraná",
    "capital": "",
    "population": 256088,
    "id": 1076025805
  },
  {
    "city": "Tokushima",
    "city_ascii": "Tokushima",
    "lat": 34.0667,
    "lng": 134.55,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Tokushima",
    "capital": "admin",
    "population": 255167,
    "id": 1392795984
  },
  {
    "city": "Peoria",
    "city_ascii": "Peoria",
    "lat": 40.752,
    "lng": -89.6153,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 256286,
    "id": 1840009373
  },
  {
    "city": "Santa Maria",
    "city_ascii": "Santa Maria",
    "lat": -29.6833,
    "lng": -53.8,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Rio Grande do Sul",
    "capital": "",
    "population": 249219,
    "id": 1076178738
  },
  {
    "city": "Kediri",
    "city_ascii": "Kediri",
    "lat": -7.8166,
    "lng": 112.0119,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Jawa Timur",
    "capital": "",
    "population": 252000,
    "id": 1360498846
  },
  {
    "city": "Fort-de-France",
    "city_ascii": "Fort-de-France",
    "lat": 14.6104,
    "lng": -61.08,
    "country": "Martinique",
    "iso2": "MQ",
    "iso3": "MTQ",
    "admin_name": "",
    "capital": "admin",
    "population": 253995,
    "id": 1474969110
  },
  {
    "city": "Damaturu",
    "city_ascii": "Damaturu",
    "lat": 11.75,
    "lng": 11.9667,
    "country": "Nigeria",
    "iso2": "NG",
    "iso3": "NGA",
    "admin_name": "Yobe",
    "capital": "admin",
    "population": 255895,
    "id": 1566897107
  },
  {
    "city": "Malolos",
    "city_ascii": "Malolos",
    "lat": 14.8433,
    "lng": 120.8114,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Bulacan",
    "capital": "admin",
    "population": 252074,
    "id": 1608358783
  },
  {
    "city": "La Paz",
    "city_ascii": "La Paz",
    "lat": 24.1422,
    "lng": -110.3108,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Baja California Sur",
    "capital": "admin",
    "population": 244219,
    "id": 1484005187
  },
  {
    "city": "Wilmington",
    "city_ascii": "Wilmington",
    "lat": 34.21,
    "lng": -77.8866,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 255616,
    "id": 1840015576
  },
  {
    "city": "Rāichūr",
    "city_ascii": "Raichur",
    "lat": 16.2104,
    "lng": 77.355,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Karnātaka",
    "capital": "",
    "population": 255240,
    "id": 1356217280
  },
  {
    "city": "Mawlamyine",
    "city_ascii": "Mawlamyine",
    "lat": 16.4847,
    "lng": 97.6258,
    "country": "Burma",
    "iso2": "MM",
    "iso3": "MMR",
    "admin_name": "Mon State",
    "capital": "admin",
    "population": 253734,
    "id": 1104530702
  },
  {
    "city": "Turmero",
    "city_ascii": "Turmero",
    "lat": 10.2283,
    "lng": -67.4753,
    "country": "Venezuela",
    "iso2": "VE",
    "iso3": "VEN",
    "admin_name": "Aragua",
    "capital": "minor",
    "population": 254880,
    "id": 1862335549
  },
  {
    "city": "Kuala Terengganu",
    "city_ascii": "Kuala Terengganu",
    "lat": 5.3303,
    "lng": 103.1408,
    "country": "Malaysia",
    "iso2": "MY",
    "iso3": "MYS",
    "admin_name": "Terengganu",
    "capital": "admin",
    "population": 255109,
    "id": 1458475563
  },
  {
    "city": "Daloa",
    "city_ascii": "Daloa",
    "lat": 6.89,
    "lng": -6.45,
    "country": "Côte D’Ivoire",
    "iso2": "CI",
    "iso3": "CIV",
    "admin_name": "Sassandra-Marahoué",
    "capital": "admin",
    "population": 255168,
    "id": 1384564974
  },
  {
    "city": "Puerto Princesa",
    "city_ascii": "Puerto Princesa",
    "lat": 9.75,
    "lng": 118.75,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Puerto Princesa",
    "capital": "admin",
    "population": 255116,
    "id": 1608455700
  },
  {
    "city": "Qarshi",
    "city_ascii": "Qarshi",
    "lat": 38.8667,
    "lng": 65.8,
    "country": "Uzbekistan",
    "iso2": "UZ",
    "iso3": "UZB",
    "admin_name": "Qashqadaryo",
    "capital": "admin",
    "population": 254600,
    "id": 1860343319
  },
  {
    "city": "Montgomery",
    "city_ascii": "Montgomery",
    "lat": 32.3473,
    "lng": -86.2666,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "admin",
    "population": 254634,
    "id": 1840008353
  },
  {
    "city": "Gilbert",
    "city_ascii": "Gilbert",
    "lat": 33.3101,
    "lng": -111.7463,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 254114,
    "id": 1840021947
  },
  {
    "city": "Rishon LeẔiyyon",
    "city_ascii": "Rishon LeZiyyon",
    "lat": 31.95,
    "lng": 34.8,
    "country": "Israel",
    "iso2": "IL",
    "iso3": "ISR",
    "admin_name": "Central",
    "capital": "",
    "population": 249860,
    "id": 1376642268
  },
  {
    "city": "Ongole",
    "city_ascii": "Ongole",
    "lat": 15.5,
    "lng": 80.05,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Andhra Pradesh",
    "capital": "",
    "population": 252739,
    "id": 1356385136
  },
  {
    "city": "Arua",
    "city_ascii": "Arua",
    "lat": 3.03,
    "lng": 30.91,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Arua",
    "capital": "admin",
    "population": 250000,
    "id": 1800812511
  },
  {
    "city": "Verona",
    "city_ascii": "Verona",
    "lat": 45.4386,
    "lng": 10.9928,
    "country": "Italy",
    "iso2": "IT",
    "iso3": "ITA",
    "admin_name": "Veneto",
    "capital": "minor",
    "population": 252520,
    "id": 1380324879
  },
  {
    "city": "Bharatpur",
    "city_ascii": "Bharatpur",
    "lat": 27.2172,
    "lng": 77.49,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Rājasthān",
    "capital": "",
    "population": 252342,
    "id": 1356168278
  },
  {
    "city": "Rạch Giá",
    "city_ascii": "Rach Gia",
    "lat": 10.0125,
    "lng": 105.0808,
    "country": "Vietnam",
    "iso2": "VN",
    "iso3": "VNM",
    "admin_name": "Kiến Giang",
    "capital": "admin",
    "population": 250660,
    "id": 1704872008
  },
  {
    "city": "Kanggye",
    "city_ascii": "Kanggye",
    "lat": 40.9667,
    "lng": 126.6,
    "country": "Korea, North",
    "iso2": "KP",
    "iso3": "PRK",
    "admin_name": "Chagang",
    "capital": "admin",
    "population": 251971,
    "id": 1408728939
  },
  {
    "city": "Taganrog",
    "city_ascii": "Taganrog",
    "lat": 47.2333,
    "lng": 38.9,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Rostovskaya Oblast’",
    "capital": "",
    "population": 250287,
    "id": 1643228316
  },
  {
    "city": "Buenaventura",
    "city_ascii": "Buenaventura",
    "lat": 3.8724,
    "lng": -77.0505,
    "country": "Colombia",
    "iso2": "CO",
    "iso3": "COL",
    "admin_name": "Valle del Cauca",
    "capital": "minor",
    "population": 252805,
    "id": 1170287988
  },
  {
    "city": "Kızıltepe",
    "city_ascii": "Kiziltepe",
    "lat": 37.1939,
    "lng": 40.5861,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Mardin",
    "capital": "minor",
    "population": 252656,
    "id": 1792229490
  },
  {
    "city": "Vitoria-Gasteiz",
    "city_ascii": "Vitoria-Gasteiz",
    "lat": 42.8467,
    "lng": -2.6731,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Basque Country",
    "capital": "admin",
    "population": 251774,
    "id": 1724975338
  },
  {
    "city": "El Fasher",
    "city_ascii": "El Fasher",
    "lat": 13.63,
    "lng": 25.35,
    "country": "Sudan",
    "iso2": "SD",
    "iso3": "SDN",
    "admin_name": "North Darfur",
    "capital": "admin",
    "population": 252609,
    "id": 1729372385
  },
  {
    "city": "Várzea Grande",
    "city_ascii": "Varzea Grande",
    "lat": -15.65,
    "lng": -56.14,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Mato Grosso",
    "capital": "",
    "population": 249752,
    "id": 1076359879
  },
  {
    "city": "Glendale",
    "city_ascii": "Glendale",
    "lat": 33.5791,
    "lng": -112.2311,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 252381,
    "id": 1840020563
  },
  {
    "city": "Santiago del Estero",
    "city_ascii": "Santiago del Estero",
    "lat": -27.7844,
    "lng": -64.2669,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Santiago del Estero",
    "capital": "admin",
    "population": 252192,
    "id": 1032492280
  },
  {
    "city": "Uşak",
    "city_ascii": "Usak",
    "lat": 38.6833,
    "lng": 29.4,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Uşak",
    "capital": "admin",
    "population": 252044,
    "id": 1792360822
  },
  {
    "city": "Begusarai",
    "city_ascii": "Begusarai",
    "lat": 25.42,
    "lng": 86.13,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Bihār",
    "capital": "",
    "population": 252008,
    "id": 1356619346
  },
  {
    "city": "North Las Vegas",
    "city_ascii": "North Las Vegas",
    "lat": 36.288,
    "lng": -115.0901,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Nevada",
    "capital": "",
    "population": 251974,
    "id": 1840020363
  },
  {
    "city": "Sonīpat",
    "city_ascii": "Sonipat",
    "lat": 28.9958,
    "lng": 77.0114,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Haryāna",
    "capital": "",
    "population": 250521,
    "id": 1356648808
  },
  {
    "city": "Los Teques",
    "city_ascii": "Los Teques",
    "lat": 10.3411,
    "lng": -67.0406,
    "country": "Venezuela",
    "iso2": "VE",
    "iso3": "VEN",
    "admin_name": "Miranda",
    "capital": "admin",
    "population": 251200,
    "id": 1862066931
  },
  {
    "city": "Mabalacat",
    "city_ascii": "Mabalacat",
    "lat": 15.2167,
    "lng": 120.5833,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Pampanga",
    "capital": "",
    "population": 250799,
    "id": 1608402560
  },
  {
    "city": "Jinshi",
    "city_ascii": "Jinshi",
    "lat": 29.6334,
    "lng": 111.8746,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hunan",
    "capital": "minor",
    "population": 251064,
    "id": 1156062960
  },
  {
    "city": "Osh",
    "city_ascii": "Osh",
    "lat": 40.5333,
    "lng": 72.7833,
    "country": "Kyrgyzstan",
    "iso2": "KG",
    "iso3": "KGZ",
    "admin_name": "Osh",
    "capital": "admin",
    "population": 251000,
    "id": 1417797596
  },
  {
    "city": "Iwo",
    "city_ascii": "Iwo",
    "lat": 7.63,
    "lng": 4.18,
    "country": "Nigeria",
    "iso2": "NG",
    "iso3": "NGA",
    "admin_name": "Osun",
    "capital": "minor",
    "population": 250443,
    "id": 1566462707
  },
  {
    "city": "Bata",
    "city_ascii": "Bata",
    "lat": 1.85,
    "lng": 9.75,
    "country": "Equatorial Guinea",
    "iso2": "GQ",
    "iso3": "GNQ",
    "admin_name": "Litoral",
    "capital": "admin",
    "population": 250770,
    "id": 1226528087
  },
  {
    "city": "Chōfugaoka",
    "city_ascii": "Chofugaoka",
    "lat": 35.6506,
    "lng": 139.5408,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Tōkyō",
    "capital": "",
    "population": 239650,
    "id": 1392692794
  },
  {
    "city": "Komsomol’sk-na-Amure",
    "city_ascii": "Komsomol'sk-na-Amure",
    "lat": 50.55,
    "lng": 137,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Khabarovskiy Kray",
    "capital": "minor",
    "population": 249810,
    "id": 1643862307
  },
  {
    "city": "Bābol",
    "city_ascii": "Babol",
    "lat": 36.55,
    "lng": 52.6833,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Māzandarān",
    "capital": "minor",
    "population": 250217,
    "id": 1364476070
  },
  {
    "city": "Galaţi",
    "city_ascii": "Galati",
    "lat": 45.4233,
    "lng": 28.0425,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Galaţi",
    "capital": "admin",
    "population": 249432,
    "id": 1642571620
  },
  {
    "city": "Al Bayḑā’",
    "city_ascii": "Al Bayda'",
    "lat": 32.7628,
    "lng": 21.755,
    "country": "Libya",
    "iso2": "LY",
    "iso3": "LBY",
    "admin_name": "Al Jabal al Akhḑar",
    "capital": "admin",
    "population": 250000,
    "id": 1434263017
  },
  {
    "city": "Yamagata",
    "city_ascii": "Yamagata",
    "lat": 38.25,
    "lng": 140.3333,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Yamagata",
    "capital": "admin",
    "population": 249327,
    "id": 1392300081
  },
  {
    "city": "Manzhouli",
    "city_ascii": "Manzhouli",
    "lat": 49.5881,
    "lng": 117.4525,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Inner Mongolia",
    "capital": "",
    "population": 249472,
    "id": 1156354568
  },
  {
    "city": "Kiel",
    "city_ascii": "Kiel",
    "lat": 54.3233,
    "lng": 10.1394,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Schleswig-Holstein",
    "capital": "admin",
    "population": 247548,
    "id": 1276305306
  },
  {
    "city": "Braunschweig",
    "city_ascii": "Braunschweig",
    "lat": 52.2692,
    "lng": 10.5211,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "minor",
    "population": 248292,
    "id": 1276941443
  },
  {
    "city": "Rivne",
    "city_ascii": "Rivne",
    "lat": 50.6197,
    "lng": 26.2514,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Rivnens’ka Oblast’",
    "capital": "admin",
    "population": 245630,
    "id": 1804144712
  },
  {
    "city": "Gdynia",
    "city_ascii": "Gdynia",
    "lat": 54.5189,
    "lng": 18.5319,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Pomorskie",
    "capital": "minor",
    "population": 247672,
    "id": 1616385189
  },
  {
    "city": "Palangkaraya",
    "city_ascii": "Palangkaraya",
    "lat": -2.21,
    "lng": 113.92,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Kalimantan Tengah",
    "capital": "admin",
    "population": 249434,
    "id": 1360268553
  },
  {
    "city": "Al Minyā",
    "city_ascii": "Al Minya",
    "lat": 28.0833,
    "lng": 30.75,
    "country": "Egypt",
    "iso2": "EG",
    "iso3": "EGY",
    "admin_name": "Al Minyā",
    "capital": "admin",
    "population": 236043,
    "id": 1818607968
  },
  {
    "city": "Ḩafr al Bāţin",
    "city_ascii": "Hafr al Batin",
    "lat": 28.4337,
    "lng": 45.9601,
    "country": "Saudi Arabia",
    "iso2": "SA",
    "iso3": "SAU",
    "admin_name": "Ash Sharqīyah",
    "capital": "",
    "population": 249194,
    "id": 1682394909
  },
  {
    "city": "Caruaru",
    "city_ascii": "Caruaru",
    "lat": -8.28,
    "lng": -35.98,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Pernambuco",
    "capital": "",
    "population": 242094,
    "id": 1076397475
  },
  {
    "city": "San Bernardo",
    "city_ascii": "San Bernardo",
    "lat": -33.6,
    "lng": -70.7,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Región Metropolitana",
    "capital": "minor",
    "population": 246762,
    "id": 1152050361
  },
  {
    "city": "Būkān",
    "city_ascii": "Bukan",
    "lat": 36.5208,
    "lng": 46.2117,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Āz̄arbāyjān-e Gharbī",
    "capital": "minor",
    "population": 248900,
    "id": 1364530447
  },
  {
    "city": "Aachen",
    "city_ascii": "Aachen",
    "lat": 50.7762,
    "lng": 6.0838,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "minor",
    "population": 247380,
    "id": 1276805572
  },
  {
    "city": "Sahiwal",
    "city_ascii": "Sahiwal",
    "lat": 30.6706,
    "lng": 73.1064,
    "country": "Pakistan",
    "iso2": "PK",
    "iso3": "PAK",
    "admin_name": "Punjab",
    "capital": "minor",
    "population": 247706,
    "id": 1586672083
  },
  {
    "city": "Chigasaki",
    "city_ascii": "Chigasaki",
    "lat": 35.3339,
    "lng": 139.4047,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Kanagawa",
    "capital": "",
    "population": 241887,
    "id": 1392346493
  },
  {
    "city": "Thủ Dầu Một",
    "city_ascii": "Thu Dau Mot",
    "lat": 11.0042,
    "lng": 106.6583,
    "country": "Vietnam",
    "iso2": "VN",
    "iso3": "VNM",
    "admin_name": "Bình Dương",
    "capital": "admin",
    "population": 244277,
    "id": 1704890399
  },
  {
    "city": "Sibu",
    "city_ascii": "Sibu",
    "lat": 2.3,
    "lng": 111.8167,
    "country": "Malaysia",
    "iso2": "MY",
    "iso3": "MYS",
    "admin_name": "Sarawak",
    "capital": "",
    "population": 247995,
    "id": 1458970454
  },
  {
    "city": "Anchorage",
    "city_ascii": "Anchorage",
    "lat": 61.1508,
    "lng": -149.1091,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alaska",
    "capital": "",
    "population": 247949,
    "id": 1840023385
  },
  {
    "city": "Paraná",
    "city_ascii": "Parana",
    "lat": -31.7444,
    "lng": -60.5175,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Entre Ríos",
    "capital": "admin",
    "population": 247863,
    "id": 1032266693
  },
  {
    "city": "Oruro",
    "city_ascii": "Oruro",
    "lat": -17.98,
    "lng": -67.13,
    "country": "Bolivia",
    "iso2": "BO",
    "iso3": "BOL",
    "admin_name": "Oruro",
    "capital": "admin",
    "population": 246501,
    "id": 1068271533
  },
  {
    "city": "Merlo",
    "city_ascii": "Merlo",
    "lat": -34.6653,
    "lng": -58.7275,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires",
    "capital": "minor",
    "population": 244168,
    "id": 1032232613
  },
  {
    "city": "Syktyvkar",
    "city_ascii": "Syktyvkar",
    "lat": 61.6667,
    "lng": 50.8167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Komi",
    "capital": "admin",
    "population": 245313,
    "id": 1643573386
  },
  {
    "city": "Khimki",
    "city_ascii": "Khimki",
    "lat": 55.8892,
    "lng": 37.445,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Moskva",
    "capital": "",
    "population": 244668,
    "id": 1643601857
  },
  {
    "city": "Birāṭnagar",
    "city_ascii": "Biratnagar",
    "lat": 26.4833,
    "lng": 87.2833,
    "country": "Nepal",
    "iso2": "NP",
    "iso3": "NPL",
    "admin_name": "Kosī",
    "capital": "admin",
    "population": 242548,
    "id": 1524520011
  },
  {
    "city": "Chemnitz",
    "city_ascii": "Chemnitz",
    "lat": 50.8333,
    "lng": 12.9167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "minor",
    "population": 245403,
    "id": 1276519956
  },
  {
    "city": "Saskatoon",
    "city_ascii": "Saskatoon",
    "lat": 52.1333,
    "lng": -106.6833,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Saskatchewan",
    "capital": "",
    "population": 246376,
    "id": 1124612546
  },
  {
    "city": "Yato",
    "city_ascii": "Yato",
    "lat": 35.4693,
    "lng": 139.4616,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Kanagawa",
    "capital": "",
    "population": 237445,
    "id": 1392469032
  },
  {
    "city": "Colón",
    "city_ascii": "Colon",
    "lat": 9.3598,
    "lng": -79.9004,
    "country": "Panama",
    "iso2": "PA",
    "iso3": "PAN",
    "admin_name": "Colón",
    "capital": "admin",
    "population": 241817,
    "id": 1591599922
  },
  {
    "city": "Abertawe",
    "city_ascii": "Abertawe",
    "lat": 51.6167,
    "lng": -3.95,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Swansea",
    "capital": "",
    "population": 245500,
    "id": 1826574888
  },
  {
    "city": "Fuji",
    "city_ascii": "Fuji",
    "lat": 35.1614,
    "lng": 138.6764,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Shizuoka",
    "capital": "",
    "population": 245078,
    "id": 1392249213
  },
  {
    "city": "Puerto Montt",
    "city_ascii": "Puerto Montt",
    "lat": -41.4718,
    "lng": -72.9396,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Los Lagos",
    "capital": "admin",
    "population": 245902,
    "id": 1152443957
  },
  {
    "city": "Jessore",
    "city_ascii": "Jessore",
    "lat": 23.1704,
    "lng": 89.2,
    "country": "Bangladesh",
    "iso2": "BD",
    "iso3": "BGD",
    "admin_name": "Khulna",
    "capital": "",
    "population": 243987,
    "id": 1050244487
  },
  {
    "city": "Beichengqu",
    "city_ascii": "Beichengqu",
    "lat": 40.4348,
    "lng": 113.1565,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Inner Mongolia",
    "capital": "minor",
    "population": 245608,
    "id": 1156401666
  },
  {
    "city": "Tuy Hòa",
    "city_ascii": "Tuy Hoa",
    "lat": 13.0875,
    "lng": 109.3106,
    "country": "Vietnam",
    "iso2": "VN",
    "iso3": "VNM",
    "admin_name": "Phú Yên",
    "capital": "admin",
    "population": 242840,
    "id": 1704697424
  },
  {
    "city": "Shrīrāmpur",
    "city_ascii": "Shrirampur",
    "lat": 22.75,
    "lng": 88.34,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "West Bengal",
    "capital": "",
    "population": 181842,
    "id": 1356021505
  },
  {
    "city": "Chesapeake",
    "city_ascii": "Chesapeake",
    "lat": 36.6778,
    "lng": -76.3024,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 244835,
    "id": 1840003874
  },
  {
    "city": "Hāpur",
    "city_ascii": "Hapur",
    "lat": 28.7437,
    "lng": 77.7628,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Uttar Pradesh",
    "capital": "",
    "population": 242920,
    "id": 1356820012
  },
  {
    "city": "Bahir Dar",
    "city_ascii": "Bahir Dar",
    "lat": 11.585,
    "lng": 37.39,
    "country": "Ethiopia",
    "iso2": "ET",
    "iso3": "ETH",
    "admin_name": "Āmara",
    "capital": "admin",
    "population": 243300,
    "id": 1231784118
  },
  {
    "city": "Manisa",
    "city_ascii": "Manisa",
    "lat": 38.6131,
    "lng": 27.4258,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Manisa",
    "capital": "admin",
    "population": 243971,
    "id": 1792804328
  },
  {
    "city": "Tanga",
    "city_ascii": "Tanga",
    "lat": -5.0667,
    "lng": 39.1,
    "country": "Tanzania",
    "iso2": "TZ",
    "iso3": "TZA",
    "admin_name": "Tanga",
    "capital": "admin",
    "population": 243580,
    "id": 1834004568
  },
  {
    "city": "Sabzevār",
    "city_ascii": "Sabzevar",
    "lat": 36.2167,
    "lng": 57.6667,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Khorāsān-e Raẕavī",
    "capital": "minor",
    "population": 243700,
    "id": 1364379130
  },
  {
    "city": "Rāmgundam",
    "city_ascii": "Ramgundam",
    "lat": 18.8,
    "lng": 79.45,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Telangana",
    "capital": "",
    "population": 242979,
    "id": 1356590864
  },
  {
    "city": "Porto",
    "city_ascii": "Porto",
    "lat": 41.1495,
    "lng": -8.6108,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Porto",
    "capital": "admin",
    "population": 237591,
    "id": 1620356810
  },
  {
    "city": "Tacloban",
    "city_ascii": "Tacloban",
    "lat": 11.2444,
    "lng": 125.0039,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Tacloban",
    "capital": "admin",
    "population": 242089,
    "id": 1608710592
  },
  {
    "city": "Myitkyina",
    "city_ascii": "Myitkyina",
    "lat": 25.3867,
    "lng": 97.3936,
    "country": "Burma",
    "iso2": "MM",
    "iso3": "MMR",
    "admin_name": "Kachin State",
    "capital": "admin",
    "population": 243031,
    "id": 1104327329
  },
  {
    "city": "Barnstable",
    "city_ascii": "Barnstable",
    "lat": 41.6722,
    "lng": -70.3599,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 242847,
    "id": 1840031233
  },
  {
    "city": "Haeju",
    "city_ascii": "Haeju",
    "lat": 38.0333,
    "lng": 125.7167,
    "country": "Korea, North",
    "iso2": "KP",
    "iso3": "PRK",
    "admin_name": "Hwangnam",
    "capital": "admin",
    "population": 241599,
    "id": 1408348983
  },
  {
    "city": "Petaẖ Tiqwa",
    "city_ascii": "Petah Tiqwa",
    "lat": 32.0833,
    "lng": 34.8833,
    "country": "Israel",
    "iso2": "IL",
    "iso3": "ISR",
    "admin_name": "Central",
    "capital": "",
    "population": 236169,
    "id": 1376362310
  },
  {
    "city": "Norfolk",
    "city_ascii": "Norfolk",
    "lat": 36.8945,
    "lng": -76.259,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 242742,
    "id": 1840003869
  },
  {
    "city": "González Catán",
    "city_ascii": "Gonzalez Catan",
    "lat": -34.7708,
    "lng": -58.6464,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires",
    "capital": "",
    "population": 238067,
    "id": 1032883563
  },
  {
    "city": "Juazeiro do Norte",
    "city_ascii": "Juazeiro do Norte",
    "lat": -7.21,
    "lng": -39.32,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Ceará",
    "capital": "",
    "population": 225230,
    "id": 1076912078
  },
  {
    "city": "Diaobingshancun",
    "city_ascii": "Diaobingshancun",
    "lat": 42.4391,
    "lng": 123.5426,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Liaoning",
    "capital": "",
    "population": 241431,
    "id": 1156893347
  },
  {
    "city": "Tarapoto",
    "city_ascii": "Tarapoto",
    "lat": -6.4833,
    "lng": -76.3667,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "San Martín",
    "capital": "",
    "population": 240453,
    "id": 1604622202
  },
  {
    "city": "Zhangping",
    "city_ascii": "Zhangping",
    "lat": 25.2938,
    "lng": 117.4148,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Fujian",
    "capital": "minor",
    "population": 242000,
    "id": 1156352113
  },
  {
    "city": "San Juan del Río",
    "city_ascii": "San Juan del Rio",
    "lat": 20.3833,
    "lng": -99.9833,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Querétaro",
    "capital": "minor",
    "population": 241699,
    "id": 1484890205
  },
  {
    "city": "Tsukuba-kenkyūgakuen-toshi",
    "city_ascii": "Tsukuba-kenkyugakuen-toshi",
    "lat": 36.0333,
    "lng": 140.0667,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Ibaraki",
    "capital": "",
    "population": 240987,
    "id": 1392112466
  },
  {
    "city": "São Carlos",
    "city_ascii": "Sao Carlos",
    "lat": -22.0178,
    "lng": -47.8908,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 241389,
    "id": 1076673445
  },
  {
    "city": "Ilhéus",
    "city_ascii": "Ilheus",
    "lat": -14.7936,
    "lng": -39.0395,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Bahia",
    "capital": "",
    "population": 230622,
    "id": 1076021121
  },
  {
    "city": "Fremont",
    "city_ascii": "Fremont",
    "lat": 37.5265,
    "lng": -121.9852,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 241110,
    "id": 1840020292
  },
  {
    "city": "Halle",
    "city_ascii": "Halle",
    "lat": 51.4828,
    "lng": 11.9697,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony-Anhalt",
    "capital": "minor",
    "population": 239257,
    "id": 1276167077
  },
  {
    "city": "Düzce",
    "city_ascii": "Duzce",
    "lat": 40.8417,
    "lng": 31.1583,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Düzce",
    "capital": "admin",
    "population": 240633,
    "id": 1792999306
  },
  {
    "city": "Anju",
    "city_ascii": "Anju",
    "lat": 39.6167,
    "lng": 125.6667,
    "country": "Korea, North",
    "iso2": "KP",
    "iso3": "PRK",
    "admin_name": "P’yŏngnam",
    "capital": "",
    "population": 240117,
    "id": 1408625970
  },
  {
    "city": "Aarhus",
    "city_ascii": "Aarhus",
    "lat": 56.1572,
    "lng": 10.2107,
    "country": "Denmark",
    "iso2": "DK",
    "iso3": "DNK",
    "admin_name": "Midtjylland",
    "capital": "minor",
    "population": 237551,
    "id": 1208841031
  },
  {
    "city": "Kennewick",
    "city_ascii": "Kennewick",
    "lat": 46.1978,
    "lng": -119.1732,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 240735,
    "id": 1840018481
  },
  {
    "city": "Hobart",
    "city_ascii": "Hobart",
    "lat": -42.8806,
    "lng": 147.325,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Tasmania",
    "capital": "admin",
    "population": 240342,
    "id": 1036679838
  },
  {
    "city": "Artux",
    "city_ascii": "Artux",
    "lat": 39.7114,
    "lng": 76.1753,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Xinjiang",
    "capital": "",
    "population": 240368,
    "id": 1156047949
  },
  {
    "city": "Chimoio",
    "city_ascii": "Chimoio",
    "lat": -19.1167,
    "lng": 33.45,
    "country": "Mozambique",
    "iso2": "MZ",
    "iso3": "MOZ",
    "admin_name": "Manica",
    "capital": "admin",
    "population": 238976,
    "id": 1508460246
  },
  {
    "city": "Limassol",
    "city_ascii": "Limassol",
    "lat": 34.675,
    "lng": 33.0443,
    "country": "Cyprus",
    "iso2": "CY",
    "iso3": "CYP",
    "admin_name": "Lemesós",
    "capital": "admin",
    "population": 235056,
    "id": 1196136222
  },
  {
    "city": "Garland",
    "city_ascii": "Garland",
    "lat": 32.91,
    "lng": -96.6305,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 239928,
    "id": 1840020707
  },
  {
    "city": "Magdeburg",
    "city_ascii": "Magdeburg",
    "lat": 52.1278,
    "lng": 11.6292,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony-Anhalt",
    "capital": "admin",
    "population": 238697,
    "id": 1276418236
  },
  {
    "city": "Irving",
    "city_ascii": "Irving",
    "lat": 32.8584,
    "lng": -96.9702,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 239798,
    "id": 1840019438
  },
  {
    "city": "Hachimanchō",
    "city_ascii": "Hachimancho",
    "lat": 40.51,
    "lng": 141.54,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Aomori",
    "capital": "",
    "population": 239046,
    "id": 1392220933
  },
  {
    "city": "Longueuil",
    "city_ascii": "Longueuil",
    "lat": 45.5333,
    "lng": -73.5167,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 239700,
    "id": 1124122753
  },
  {
    "city": "Mokpo",
    "city_ascii": "Mokpo",
    "lat": 34.7936,
    "lng": 126.3886,
    "country": "Korea, South",
    "iso2": "KR",
    "iso3": "KOR",
    "admin_name": "Jeonnam",
    "capital": "minor",
    "population": 239524,
    "id": 1410279439
  },
  {
    "city": "Košice",
    "city_ascii": "Kosice",
    "lat": 48.7167,
    "lng": 21.25,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Košický",
    "capital": "admin",
    "population": 238593,
    "id": 1703452690
  },
  {
    "city": "‘Ajmān",
    "city_ascii": "`Ajman",
    "lat": 25.3994,
    "lng": 55.4797,
    "country": "United Arab Emirates",
    "iso2": "AE",
    "iso3": "ARE",
    "admin_name": "‘Ajmān",
    "capital": "admin",
    "population": 238119,
    "id": 1784337875
  },
  {
    "city": "Lille",
    "city_ascii": "Lille",
    "lat": 50.6278,
    "lng": 3.0583,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Hauts-de-France",
    "capital": "admin",
    "population": 232787,
    "id": 1250071961
  },
  {
    "city": "Neya",
    "city_ascii": "Neya",
    "lat": 34.7661,
    "lng": 135.6281,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Ōsaka",
    "capital": "",
    "population": 229974,
    "id": 1392993634
  },
  {
    "city": "Banda Aceh",
    "city_ascii": "Banda Aceh",
    "lat": 5.55,
    "lng": 95.3175,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Aceh",
    "capital": "admin",
    "population": 235305,
    "id": 1360353144
  },
  {
    "city": "Le Havre",
    "city_ascii": "Le Havre",
    "lat": 49.49,
    "lng": 0.1,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Normandie",
    "capital": "minor",
    "population": 235218,
    "id": 1250775297
  },
  {
    "city": "Taiping",
    "city_ascii": "Taiping",
    "lat": 4.85,
    "lng": 100.7333,
    "country": "Malaysia",
    "iso2": "MY",
    "iso3": "MYS",
    "admin_name": "Perak",
    "capital": "",
    "population": 237095,
    "id": 1458446120
  },
  {
    "city": "P’yŏng-dong",
    "city_ascii": "P'yong-dong",
    "lat": 39.25,
    "lng": 125.85,
    "country": "Korea, North",
    "iso2": "KP",
    "iso3": "PRK",
    "admin_name": "P’yŏngyang",
    "capital": "",
    "population": 236583,
    "id": 1408184982
  },
  {
    "city": "Springs",
    "city_ascii": "Springs",
    "lat": -26.2547,
    "lng": 28.4428,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "Gauteng",
    "capital": "",
    "population": 236083,
    "id": 1710058382
  },
  {
    "city": "Ivano-Frankivsk",
    "city_ascii": "Ivano-Frankivsk",
    "lat": 48.9228,
    "lng": 24.7106,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Ivano-Frankivs’ka Oblast’",
    "capital": "admin",
    "population": 236602,
    "id": 1804208152
  },
  {
    "city": "As Sīb",
    "city_ascii": "As Sib",
    "lat": 23.6802,
    "lng": 58.1825,
    "country": "Oman",
    "iso2": "OM",
    "iso3": "OMN",
    "admin_name": "Masqaţ",
    "capital": "",
    "population": 237816,
    "id": 1512461161
  },
  {
    "city": "Nārāyanganj",
    "city_ascii": "Narayanganj",
    "lat": 23.6167,
    "lng": 90.5,
    "country": "Bangladesh",
    "iso2": "BD",
    "iso3": "BGD",
    "admin_name": "Dhaka",
    "capital": "",
    "population": 223622,
    "id": 1050424421
  },
  {
    "city": "Wuyishan",
    "city_ascii": "Wuyishan",
    "lat": 27.7562,
    "lng": 118.0267,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Fujian",
    "capital": "minor",
    "population": 238000,
    "id": 1156519625
  },
  {
    "city": "Sato",
    "city_ascii": "Sato",
    "lat": 33.1631,
    "lng": 129.7177,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Nagasaki",
    "capital": "",
    "population": 237444,
    "id": 1392043131
  },
  {
    "city": "Āmol",
    "city_ascii": "Amol",
    "lat": 36.4669,
    "lng": 52.3569,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Māzandarān",
    "capital": "minor",
    "population": 237528,
    "id": 1364107935
  },
  {
    "city": "Quilmes",
    "city_ascii": "Quilmes",
    "lat": -34.7167,
    "lng": -58.2667,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires",
    "capital": "minor",
    "population": 230810,
    "id": 1032880202
  },
  {
    "city": "Mirpur Khas",
    "city_ascii": "Mirpur Khas",
    "lat": 25.5269,
    "lng": 69.0111,
    "country": "Pakistan",
    "iso2": "PK",
    "iso3": "PAK",
    "admin_name": "Sindh",
    "capital": "minor",
    "population": 236961,
    "id": 1586156251
  },
  {
    "city": "Nizhnekamsk",
    "city_ascii": "Nizhnekamsk",
    "lat": 55.6333,
    "lng": 51.8167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Tatarstan",
    "capital": "",
    "population": 237250,
    "id": 1643493938
  },
  {
    "city": "Visalia",
    "city_ascii": "Visalia",
    "lat": 36.3276,
    "lng": -119.3269,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 237386,
    "id": 1840021639
  },
  {
    "city": "Al Jubayl",
    "city_ascii": "Al Jubayl",
    "lat": 27.0046,
    "lng": 49.646,
    "country": "Saudi Arabia",
    "iso2": "SA",
    "iso3": "SAU",
    "admin_name": "Ash Sharqīyah",
    "capital": "",
    "population": 237274,
    "id": 1682564079
  },
  {
    "city": "Pathein",
    "city_ascii": "Pathein",
    "lat": 16.7667,
    "lng": 94.7333,
    "country": "Burma",
    "iso2": "MM",
    "iso3": "MMR",
    "admin_name": "Ayeyawady",
    "capital": "admin",
    "population": 237089,
    "id": 1104832430
  },
  {
    "city": "Secunderābād",
    "city_ascii": "Secunderabad",
    "lat": 17.45,
    "lng": 78.5,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Telangana",
    "capital": "",
    "population": 213698,
    "id": 1356779437
  },
  {
    "city": "Chishui",
    "city_ascii": "Chishui",
    "lat": 28.5902,
    "lng": 105.6946,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Guizhou",
    "capital": "",
    "population": 237052,
    "id": 1156325764
  },
  {
    "city": "Centurion",
    "city_ascii": "Centurion",
    "lat": -25.8603,
    "lng": 28.1894,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "Gauteng",
    "capital": "",
    "population": 236580,
    "id": 1710138774
  },
  {
    "city": "Atlantic City",
    "city_ascii": "Atlantic City",
    "lat": 39.3797,
    "lng": -74.4527,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 237054,
    "id": 1840003798
  },
  {
    "city": "Uluberiya",
    "city_ascii": "Uluberiya",
    "lat": 22.47,
    "lng": 88.11,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "West Bengal",
    "capital": "",
    "population": 235345,
    "id": 1356354610
  },
  {
    "city": "Shakhty",
    "city_ascii": "Shakhty",
    "lat": 47.7,
    "lng": 40.2333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Rostovskaya Oblast’",
    "capital": "",
    "population": 235492,
    "id": 1643542365
  },
  {
    "city": "Messina",
    "city_ascii": "Messina",
    "lat": 38.1833,
    "lng": 15.55,
    "country": "Italy",
    "iso2": "IT",
    "iso3": "ITA",
    "admin_name": "Sicilia",
    "capital": "minor",
    "population": 236962,
    "id": 1380622386
  },
  {
    "city": "Pākdasht",
    "city_ascii": "Pakdasht",
    "lat": 35.4817,
    "lng": 51.6803,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Tehrān",
    "capital": "minor",
    "population": 236319,
    "id": 1364370088
  },
  {
    "city": "El Tigre",
    "city_ascii": "El Tigre",
    "lat": 8.8858,
    "lng": -64.2611,
    "country": "Venezuela",
    "iso2": "VE",
    "iso3": "VEN",
    "admin_name": "Anzoátegui",
    "capital": "minor",
    "population": 236566,
    "id": 1862428934
  },
  {
    "city": "Kremenchuk",
    "city_ascii": "Kremenchuk",
    "lat": 49.0775,
    "lng": 33.4239,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Poltavs’ka Oblast’",
    "capital": "minor",
    "population": 234073,
    "id": 1804343754
  },
  {
    "city": "Abhā",
    "city_ascii": "Abha",
    "lat": 18.2167,
    "lng": 42.5,
    "country": "Saudi Arabia",
    "iso2": "SA",
    "iso3": "SAU",
    "admin_name": "‘Asīr",
    "capital": "admin",
    "population": 236157,
    "id": 1682759151
  },
  {
    "city": "Ibb",
    "city_ascii": "Ibb",
    "lat": 13.9759,
    "lng": 44.1709,
    "country": "Yemen",
    "iso2": "YE",
    "iso3": "YEM",
    "admin_name": "Ibb",
    "capital": "",
    "population": 234837,
    "id": 1887036494
  },
  {
    "city": "Garoua",
    "city_ascii": "Garoua",
    "lat": 9.3,
    "lng": 13.4,
    "country": "Cameroon",
    "iso2": "CM",
    "iso3": "CMR",
    "admin_name": "Nord",
    "capital": "admin",
    "population": 235996,
    "id": 1120803808
  },
  {
    "city": "Bole",
    "city_ascii": "Bole",
    "lat": 44.898,
    "lng": 82.0726,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Xinjiang",
    "capital": "",
    "population": 235585,
    "id": 1156784761
  },
  {
    "city": "Najafābād",
    "city_ascii": "Najafabad",
    "lat": 32.6342,
    "lng": 51.3667,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Eşfahān",
    "capital": "minor",
    "population": 235281,
    "id": 1364053850
  },
  {
    "city": "Bayamo",
    "city_ascii": "Bayamo",
    "lat": 20.3817,
    "lng": -76.6428,
    "country": "Cuba",
    "iso2": "CU",
    "iso3": "CUB",
    "admin_name": "Granma",
    "capital": "admin",
    "population": 235107,
    "id": 1192297857
  },
  {
    "city": "Porbandar",
    "city_ascii": "Porbandar",
    "lat": 21.6425,
    "lng": 69.6047,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Gujarāt",
    "capital": "",
    "population": 234684,
    "id": 1356634492
  },
  {
    "city": "Granada",
    "city_ascii": "Granada",
    "lat": 37.1781,
    "lng": -3.6008,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Andalusia",
    "capital": "minor",
    "population": 232462,
    "id": 1724929320
  },
  {
    "city": "Kamianske",
    "city_ascii": "Kamianske",
    "lat": 48.5076,
    "lng": 34.6132,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Dnipropetrovs’ka Oblast’",
    "capital": "",
    "population": 235066,
    "id": 1804033242
  },
  {
    "city": "Borūjerd",
    "city_ascii": "Borujerd",
    "lat": 33.8972,
    "lng": 48.7514,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Lorestān",
    "capital": "minor",
    "population": 234997,
    "id": 1364855573
  },
  {
    "city": "Singaraja",
    "city_ascii": "Singaraja",
    "lat": -8.1152,
    "lng": 115.0944,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Bali",
    "capital": "minor",
    "population": 234468,
    "id": 1360426282
  },
  {
    "city": "Miri",
    "city_ascii": "Miri",
    "lat": 4.4028,
    "lng": 113.9933,
    "country": "Malaysia",
    "iso2": "MY",
    "iso3": "MYS",
    "admin_name": "Sarawak",
    "capital": "",
    "population": 234541,
    "id": 1458079883
  },
  {
    "city": "Machala",
    "city_ascii": "Machala",
    "lat": -3.2667,
    "lng": -79.9667,
    "country": "Ecuador",
    "iso2": "EC",
    "iso3": "ECU",
    "admin_name": "El Oro",
    "capital": "admin",
    "population": 231260,
    "id": 1218378191
  },
  {
    "city": "Longquan",
    "city_ascii": "Longquan",
    "lat": 28.0733,
    "lng": 119.1277,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Zhejiang",
    "capital": "",
    "population": 234626,
    "id": 1156962780
  },
  {
    "city": "Monclova",
    "city_ascii": "Monclova",
    "lat": 26.9103,
    "lng": -101.4222,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Coahuila de Zaragoza",
    "capital": "minor",
    "population": 231107,
    "id": 1484281759
  },
  {
    "city": "Puducherry",
    "city_ascii": "Puducherry",
    "lat": 11.93,
    "lng": 79.83,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Puducherry",
    "capital": "admin",
    "population": 227411,
    "id": 1356368903
  },
  {
    "city": "Olongapo",
    "city_ascii": "Olongapo",
    "lat": 14.8333,
    "lng": 120.2833,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Olongapo",
    "capital": "admin",
    "population": 233040,
    "id": 1608001640
  },
  {
    "city": "Saga",
    "city_ascii": "Saga",
    "lat": 33.2667,
    "lng": 130.3,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Saga",
    "capital": "admin",
    "population": 233514,
    "id": 1392618841
  },
  {
    "city": "Nashua",
    "city_ascii": "Nashua",
    "lat": 42.7491,
    "lng": -71.491,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Hampshire",
    "capital": "",
    "population": 233917,
    "id": 1840002984
  },
  {
    "city": "Santarém",
    "city_ascii": "Santarem",
    "lat": -2.4431,
    "lng": -54.7083,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Pará",
    "capital": "",
    "population": 230428,
    "id": 1076256494
  },
  {
    "city": "Paradise",
    "city_ascii": "Paradise",
    "lat": 36.0807,
    "lng": -115.1369,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Nevada",
    "capital": "",
    "population": 233689,
    "id": 1840033743
  },
  {
    "city": "Okara",
    "city_ascii": "Okara",
    "lat": 30.81,
    "lng": 73.4597,
    "country": "Pakistan",
    "iso2": "PK",
    "iso3": "PAK",
    "admin_name": "Punjab",
    "capital": "minor",
    "population": 232386,
    "id": 1586880900
  },
  {
    "city": "Kasukabe",
    "city_ascii": "Kasukabe",
    "lat": 35.9753,
    "lng": 139.7525,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Saitama",
    "capital": "",
    "population": 229932,
    "id": 1392430282
  },
  {
    "city": "Talisay",
    "city_ascii": "Talisay",
    "lat": 10.25,
    "lng": 123.8333,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Cebu",
    "capital": "",
    "population": 227645,
    "id": 1608040297
  },
  {
    "city": "Hialeah",
    "city_ascii": "Hialeah",
    "lat": 25.8696,
    "lng": -80.3046,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 233339,
    "id": 1840015157
  },
  {
    "city": "Saidpur",
    "city_ascii": "Saidpur",
    "lat": 25.8004,
    "lng": 89,
    "country": "Bangladesh",
    "iso2": "BD",
    "iso3": "BGD",
    "admin_name": "Rangpur",
    "capital": "",
    "population": 232209,
    "id": 1050512022
  },
  {
    "city": "York",
    "city_ascii": "York",
    "lat": 39.9651,
    "lng": -76.7315,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 233184,
    "id": 1840001472
  },
  {
    "city": "Burnaby",
    "city_ascii": "Burnaby",
    "lat": 49.2667,
    "lng": -122.9667,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "British Columbia",
    "capital": "",
    "population": 232755,
    "id": 1124817304
  },
  {
    "city": "Rancagua",
    "city_ascii": "Rancagua",
    "lat": -34.1654,
    "lng": -70.7398,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Libertador General Bernardo O’Higgins",
    "capital": "admin",
    "population": 231370,
    "id": 1152672356
  },
  {
    "city": "Dzerzhinsk",
    "city_ascii": "Dzerzhinsk",
    "lat": 56.2333,
    "lng": 43.45,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Nizhegorodskaya Oblast’",
    "capital": "",
    "population": 231797,
    "id": 1643076798
  },
  {
    "city": "Milton Keynes",
    "city_ascii": "Milton Keynes",
    "lat": 52.04,
    "lng": -0.76,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Milton Keynes",
    "capital": "",
    "population": 229941,
    "id": 1826291745
  },
  {
    "city": "Marília",
    "city_ascii": "Marilia",
    "lat": -22.2139,
    "lng": -49.9458,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 232006,
    "id": 1076042151
  },
  {
    "city": "Barishal",
    "city_ascii": "Barishal",
    "lat": 22.7,
    "lng": 90.3667,
    "country": "Bangladesh",
    "iso2": "BD",
    "iso3": "BGD",
    "admin_name": "Barisāl",
    "capital": "admin",
    "population": 230000,
    "id": 1050297281
  },
  {
    "city": "Bratsk",
    "city_ascii": "Bratsk",
    "lat": 56.1667,
    "lng": 101.6167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Irkutskaya Oblast’",
    "capital": "",
    "population": 231602,
    "id": 1643485535
  },
  {
    "city": "Jiayuguan",
    "city_ascii": "Jiayuguan",
    "lat": 39.8112,
    "lng": 98.2862,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Gansu",
    "capital": "minor",
    "population": 231853,
    "id": 1156177210
  },
  {
    "city": "Arlington",
    "city_ascii": "Arlington",
    "lat": 38.8786,
    "lng": -77.1011,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 231803,
    "id": 1840006112
  },
  {
    "city": "Indaiatuba",
    "city_ascii": "Indaiatuba",
    "lat": -23.0903,
    "lng": -47.2181,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 231033,
    "id": 1076347789
  },
  {
    "city": "Freiburg im Breisgau",
    "city_ascii": "Freiburg im Breisgau",
    "lat": 47.9947,
    "lng": 7.8497,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "minor",
    "population": 230241,
    "id": 1276371228
  },
  {
    "city": "Madan",
    "city_ascii": "Madan",
    "lat": 30.3392,
    "lng": 48.3042,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Khūzestān",
    "capital": "",
    "population": 231476,
    "id": 1364708556
  },
  {
    "city": "Qarchak",
    "city_ascii": "Qarchak",
    "lat": 35.4394,
    "lng": 51.5689,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Tehrān",
    "capital": "",
    "population": 231075,
    "id": 1364173799
  },
  {
    "city": "Itabuna",
    "city_ascii": "Itabuna",
    "lat": -14.7896,
    "lng": -39.28,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Bahia",
    "capital": "",
    "population": 221938,
    "id": 1076003792
  },
  {
    "city": "Neuquén",
    "city_ascii": "Neuquen",
    "lat": -38.9573,
    "lng": -68.0455,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Neuquén",
    "capital": "admin",
    "population": 231198,
    "id": 1032525768
  },
  {
    "city": "Ibb",
    "city_ascii": "Ibb",
    "lat": 13.9667,
    "lng": 44.1667,
    "country": "Yemen",
    "iso2": "YE",
    "iso3": "YEM",
    "admin_name": "Ibb",
    "capital": "admin",
    "population": 225611,
    "id": 1887321491
  },
  {
    "city": "Ageoshimo",
    "city_ascii": "Ageoshimo",
    "lat": 35.9775,
    "lng": 139.5933,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Saitama",
    "capital": "",
    "population": 225907,
    "id": 1392046664
  },
  {
    "city": "Badalona",
    "city_ascii": "Badalona",
    "lat": 41.4333,
    "lng": 2.2333,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Catalonia",
    "capital": "",
    "population": 220440,
    "id": 1724090334
  },
  {
    "city": "Singkawang",
    "city_ascii": "Singkawang",
    "lat": 0.9,
    "lng": 108.9833,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Kalimantan Barat",
    "capital": "",
    "population": 230376,
    "id": 1360906363
  },
  {
    "city": "Orsk",
    "city_ascii": "Orsk",
    "lat": 51.2145,
    "lng": 58.5441,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Orenburgskaya Oblast’",
    "capital": "",
    "population": 230414,
    "id": 1643003114
  },
  {
    "city": "Vizianagaram",
    "city_ascii": "Vizianagaram",
    "lat": 18.1167,
    "lng": 83.4167,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Andhra Pradesh",
    "capital": "",
    "population": 228720,
    "id": 1356212761
  },
  {
    "city": "Evansville",
    "city_ascii": "Evansville",
    "lat": 37.9881,
    "lng": -87.5341,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 230427,
    "id": 1840013730
  },
  {
    "city": "Cotia",
    "city_ascii": "Cotia",
    "lat": -23.6039,
    "lng": -46.9189,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 229548,
    "id": 1076098590
  },
  {
    "city": "North Hempstead",
    "city_ascii": "North Hempstead",
    "lat": 40.7912,
    "lng": -73.6688,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 230241,
    "id": 1840087737
  },
  {
    "city": "Pāli",
    "city_ascii": "Pali",
    "lat": 25.7725,
    "lng": 73.3233,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Rājasthān",
    "capital": "",
    "population": 229956,
    "id": 1356212172
  },
  {
    "city": "Noginsk",
    "city_ascii": "Noginsk",
    "lat": 64.4833,
    "lng": 91.2333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Krasnoyarskiy Kray",
    "capital": "",
    "population": 229731,
    "id": 1643129651
  },
  {
    "city": "Kropyvnytskyi",
    "city_ascii": "Kropyvnytskyi",
    "lat": 48.5103,
    "lng": 32.2667,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Kirovohrads’ka Oblast’",
    "capital": "admin",
    "population": 227413,
    "id": 1804374492
  },
  {
    "city": "Częstochowa",
    "city_ascii": "Czestochowa",
    "lat": 50.8,
    "lng": 19.1167,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Śląskie",
    "capital": "minor",
    "population": 228179,
    "id": 1616881632
  },
  {
    "city": "Guantánamo",
    "city_ascii": "Guantanamo",
    "lat": 20.1383,
    "lng": -75.2061,
    "country": "Cuba",
    "iso2": "CU",
    "iso3": "CUB",
    "admin_name": "Guantánamo",
    "capital": "admin",
    "population": 228436,
    "id": 1192866062
  },
  {
    "city": "Krefeld",
    "city_ascii": "Krefeld",
    "lat": 51.3333,
    "lng": 6.5667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "minor",
    "population": 227020,
    "id": 1276149829
  },
  {
    "city": "Guadalupe",
    "city_ascii": "Guadalupe",
    "lat": 22.7528,
    "lng": -102.5078,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Zacatecas",
    "capital": "minor",
    "population": 215000,
    "id": 1484781342
  },
  {
    "city": "Kolpino",
    "city_ascii": "Kolpino",
    "lat": 59.73,
    "lng": 30.65,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sankt-Peterburg",
    "capital": "",
    "population": 225801,
    "id": 1643448070
  },
  {
    "city": "Huixquilucan",
    "city_ascii": "Huixquilucan",
    "lat": 19.3611,
    "lng": -99.3508,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "México",
    "capital": "minor",
    "population": 224042,
    "id": 1484187110
  },
  {
    "city": "Burgas",
    "city_ascii": "Burgas",
    "lat": 42.5,
    "lng": 27.4667,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Burgas",
    "capital": "admin",
    "population": 226868,
    "id": 1100365645
  },
  {
    "city": "Puerto Vallarta",
    "city_ascii": "Puerto Vallarta",
    "lat": 20.6667,
    "lng": -105.2667,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Jalisco",
    "capital": "minor",
    "population": 221200,
    "id": 1484408906
  },
  {
    "city": "Avondale",
    "city_ascii": "Avondale",
    "lat": 33.3858,
    "lng": -112.3236,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 227289,
    "id": 1840019310
  },
  {
    "city": "Nyala",
    "city_ascii": "Nyala",
    "lat": 12.05,
    "lng": 24.8833,
    "country": "Sudan",
    "iso2": "SD",
    "iso3": "SDN",
    "admin_name": "South Darfur",
    "capital": "admin",
    "population": 227183,
    "id": 1729833248
  },
  {
    "city": "Brownsville",
    "city_ascii": "Brownsville",
    "lat": 25.998,
    "lng": -97.4565,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 227239,
    "id": 1840019743
  },
  {
    "city": "Sakurazuka",
    "city_ascii": "Sakurazuka",
    "lat": 34.8,
    "lng": 135.3603,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Ōsaka",
    "capital": "",
    "population": 225008,
    "id": 1392995608
  },
  {
    "city": "Daşoguz",
    "city_ascii": "Dasoguz",
    "lat": 41.8167,
    "lng": 59.9831,
    "country": "Turkmenistan",
    "iso2": "TM",
    "iso3": "TKM",
    "admin_name": "Daşoguz",
    "capital": "admin",
    "population": 227184,
    "id": 1795167397
  },
  {
    "city": "Türkistan",
    "city_ascii": "Turkistan",
    "lat": 43.3,
    "lng": 68.2436,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Ongtüstik Qazaqstan",
    "capital": "admin",
    "population": 227098,
    "id": 1398398278
  },
  {
    "city": "Probolinggo",
    "city_ascii": "Probolinggo",
    "lat": -7.75,
    "lng": 113.2167,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Jawa Timur",
    "capital": "",
    "population": 223159,
    "id": 1360138476
  },
  {
    "city": "Atsugichō",
    "city_ascii": "Atsugicho",
    "lat": 35.4333,
    "lng": 139.3667,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Kanagawa",
    "capital": "",
    "population": 224677,
    "id": 1392522298
  },
  {
    "city": "Jacareí",
    "city_ascii": "Jacarei",
    "lat": -23.305,
    "lng": -45.9658,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 226539,
    "id": 1076592769
  },
  {
    "city": "Gusau",
    "city_ascii": "Gusau",
    "lat": 12.1704,
    "lng": 6.66,
    "country": "Nigeria",
    "iso2": "NG",
    "iso3": "NGA",
    "admin_name": "Zamfara",
    "capital": "admin",
    "population": 226857,
    "id": 1566383682
  },
  {
    "city": "Buenavista",
    "city_ascii": "Buenavista",
    "lat": 19.6083,
    "lng": -99.1694,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "México",
    "capital": "",
    "population": 206081,
    "id": 1484774061
  },
  {
    "city": "Quetzaltenango",
    "city_ascii": "Quetzaltenango",
    "lat": 14.8333,
    "lng": -91.5167,
    "country": "Guatemala",
    "iso2": "GT",
    "iso3": "GTM",
    "admin_name": "Quetzaltenango",
    "capital": "admin",
    "population": 225000,
    "id": 1320083599
  },
  {
    "city": "Araraquara",
    "city_ascii": "Araraquara",
    "lat": -21.7939,
    "lng": -48.1758,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 226508,
    "id": 1076251019
  },
  {
    "city": "Angarsk",
    "city_ascii": "Angarsk",
    "lat": 52.5667,
    "lng": 103.9167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Irkutskaya Oblast’",
    "capital": "",
    "population": 226374,
    "id": 1643989118
  },
  {
    "city": "Nāgercoil",
    "city_ascii": "Nagercoil",
    "lat": 8.17,
    "lng": 77.43,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Tamil Nādu",
    "capital": "",
    "population": 224329,
    "id": 1356051733
  },
  {
    "city": "Heroica Nogales",
    "city_ascii": "Heroica Nogales",
    "lat": 31.3186,
    "lng": -110.9458,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Sonora",
    "capital": "minor",
    "population": 220292,
    "id": 1484364591
  },
  {
    "city": "Apapa",
    "city_ascii": "Apapa",
    "lat": 6.4489,
    "lng": 3.3589,
    "country": "Nigeria",
    "iso2": "NG",
    "iso3": "NGA",
    "admin_name": "Lagos",
    "capital": "minor",
    "population": 217362,
    "id": 1566625487
  },
  {
    "city": "Nyanza",
    "city_ascii": "Nyanza",
    "lat": -2.3496,
    "lng": 29.74,
    "country": "Rwanda",
    "iso2": "RW",
    "iso3": "RWA",
    "admin_name": "Southern Province",
    "capital": "admin",
    "population": 225209,
    "id": 1646257566
  },
  {
    "city": "Itapevi",
    "city_ascii": "Itapevi",
    "lat": -23.5489,
    "lng": -46.9342,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 223404,
    "id": 1076008789
  },
  {
    "city": "Bạc Liêu",
    "city_ascii": "Bac Lieu",
    "lat": 9.2833,
    "lng": 105.7167,
    "country": "Vietnam",
    "iso2": "VN",
    "iso3": "VNM",
    "admin_name": "Bạc Liêu",
    "capital": "admin",
    "population": 225000,
    "id": 1704298745
  },
  {
    "city": "Varāmīn",
    "city_ascii": "Varamin",
    "lat": 35.2714,
    "lng": 51.6511,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Tehrān",
    "capital": "minor",
    "population": 225628,
    "id": 1364275103
  },
  {
    "city": "Padangsidempuan",
    "city_ascii": "Padangsidempuan",
    "lat": 1.3667,
    "lng": 99.2667,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Sumatera Utara",
    "capital": "",
    "population": 225544,
    "id": 1360506609
  },
  {
    "city": "Karnāl",
    "city_ascii": "Karnal",
    "lat": 29.6804,
    "lng": 76.97,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Haryāna",
    "capital": "",
    "population": 225049,
    "id": 1356145204
  },
  {
    "city": "Mubi",
    "city_ascii": "Mubi",
    "lat": 10.2686,
    "lng": 13.267,
    "country": "Nigeria",
    "iso2": "NG",
    "iso3": "NGA",
    "admin_name": "Adamawa",
    "capital": "minor",
    "population": 225705,
    "id": 1566265170
  },
  {
    "city": "Toamasina",
    "city_ascii": "Toamasina",
    "lat": -18.1492,
    "lng": 49.4023,
    "country": "Madagascar",
    "iso2": "MG",
    "iso3": "MDG",
    "admin_name": "Toamasina",
    "capital": "admin",
    "population": 225116,
    "id": 1450540529
  },
  {
    "city": "Engels",
    "city_ascii": "Engels",
    "lat": 51.4667,
    "lng": 46.1167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Saratovskaya Oblast’",
    "capital": "",
    "population": 225752,
    "id": 1643653861
  },
  {
    "city": "Campeche",
    "city_ascii": "Campeche",
    "lat": 19.85,
    "lng": -90.5306,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Campeche",
    "capital": "admin",
    "population": 220389,
    "id": 1484431512
  },
  {
    "city": "Korolëv",
    "city_ascii": "Korolev",
    "lat": 55.9167,
    "lng": 37.8167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Moskovskaya Oblast’",
    "capital": "",
    "population": 221797,
    "id": 1643323167
  },
  {
    "city": "Cidade de Nacala",
    "city_ascii": "Cidade de Nacala",
    "lat": -14.5186,
    "lng": 40.715,
    "country": "Mozambique",
    "iso2": "MZ",
    "iso3": "MOZ",
    "admin_name": "Nampula",
    "capital": "",
    "population": 224795,
    "id": 1508752825
  },
  {
    "city": "Hunchun",
    "city_ascii": "Hunchun",
    "lat": 42.8679,
    "lng": 130.3585,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Jilin",
    "capital": "minor",
    "population": 225454,
    "id": 1156644593
  },
  {
    "city": "Tampere",
    "city_ascii": "Tampere",
    "lat": 61.4981,
    "lng": 23.7608,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Pirkanmaa",
    "capital": "admin",
    "population": 225118,
    "id": 1246964318
  },
  {
    "city": "Minamiōzuma",
    "city_ascii": "Minamiozuma",
    "lat": 36.2404,
    "lng": 137.97,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Nagano",
    "capital": "",
    "population": 224926,
    "id": 1392373962
  },
  {
    "city": "Blagoveshchensk",
    "city_ascii": "Blagoveshchensk",
    "lat": 50.2578,
    "lng": 127.5364,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Amurskaya Oblast’",
    "capital": "admin",
    "population": 224419,
    "id": 1643308239
  },
  {
    "city": "Velikiy Novgorod",
    "city_ascii": "Velikiy Novgorod",
    "lat": 58.521,
    "lng": 31.2758,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Novgorodskaya Oblast’",
    "capital": "admin",
    "population": 222594,
    "id": 1643774241
  },
  {
    "city": "Ashdod",
    "city_ascii": "Ashdod",
    "lat": 31.7978,
    "lng": 34.6503,
    "country": "Israel",
    "iso2": "IL",
    "iso3": "ISR",
    "admin_name": "Southern",
    "capital": "",
    "population": 220174,
    "id": 1376458766
  },
  {
    "city": "Carlos Manuel de Céspedes",
    "city_ascii": "Carlos Manuel de Cespedes",
    "lat": 21.5767,
    "lng": -78.2775,
    "country": "Cuba",
    "iso2": "CU",
    "iso3": "CUB",
    "admin_name": "Camagüey",
    "capital": "minor",
    "population": 224488,
    "id": 1192400731
  },
  {
    "city": "Niğde",
    "city_ascii": "Nigde",
    "lat": 37.9667,
    "lng": 34.6792,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Niğde",
    "capital": "admin",
    "population": 224289,
    "id": 1792867993
  },
  {
    "city": "Sandton",
    "city_ascii": "Sandton",
    "lat": -26.107,
    "lng": 28.0517,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "Gauteng",
    "capital": "",
    "population": 222415,
    "id": 1710113163
  },
  {
    "city": "Tanjore",
    "city_ascii": "Tanjore",
    "lat": 10.8,
    "lng": 79.15,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Tamil Nādu",
    "capital": "",
    "population": 222943,
    "id": 1356599194
  },
  {
    "city": "Staryy Oskol",
    "city_ascii": "Staryy Oskol",
    "lat": 51.2981,
    "lng": 37.835,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Belgorodskaya Oblast’",
    "capital": "",
    "population": 223360,
    "id": 1643807951
  },
  {
    "city": "Puri",
    "city_ascii": "Puri",
    "lat": 19.8,
    "lng": 85.8167,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Odisha",
    "capital": "",
    "population": 201026,
    "id": 1356558409
  },
  {
    "city": "Cartago",
    "city_ascii": "Cartago",
    "lat": 9.8667,
    "lng": -83.9167,
    "country": "Costa Rica",
    "iso2": "CR",
    "iso3": "CRI",
    "admin_name": "Cartago",
    "capital": "admin",
    "population": 221733,
    "id": 1188911724
  },
  {
    "city": "Ji’an Shi",
    "city_ascii": "Ji'an Shi",
    "lat": 41.1231,
    "lng": 126.1815,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Jilin",
    "capital": "minor",
    "population": 222938,
    "id": 1156207117
  },
  {
    "city": "Soledad de Graciano Sánchez",
    "city_ascii": "Soledad de Graciano Sanchez",
    "lat": 22.1833,
    "lng": -100.9333,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "San Luis Potosí",
    "capital": "minor",
    "population": 215968,
    "id": 1484610487
  },
  {
    "city": "Presidente Prudente",
    "city_ascii": "Presidente Prudente",
    "lat": -22.1258,
    "lng": -51.3889,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 222192,
    "id": 1076239965
  },
  {
    "city": "José C. Paz",
    "city_ascii": "Jose C. Paz",
    "lat": -34.5167,
    "lng": -58.7667,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires",
    "capital": "minor",
    "population": 216637,
    "id": 1032741145
  },
  {
    "city": "Ternopil",
    "city_ascii": "Ternopil",
    "lat": 49.5667,
    "lng": 25.6,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Ternopil’s’ka Oblast’",
    "capital": "admin",
    "population": 218653,
    "id": 1804000146
  },
  {
    "city": "Rufisque",
    "city_ascii": "Rufisque",
    "lat": 14.7167,
    "lng": -17.2667,
    "country": "Senegal",
    "iso2": "SN",
    "iso3": "SEN",
    "admin_name": "Dakar",
    "capital": "",
    "population": 221066,
    "id": 1686348669
  },
  {
    "city": "Guéckédou",
    "city_ascii": "Gueckedou",
    "lat": 8.5667,
    "lng": -10.1333,
    "country": "Guinea",
    "iso2": "GN",
    "iso3": "GIN",
    "admin_name": "N’Zérékoré",
    "capital": "minor",
    "population": 221715,
    "id": 1324580681
  },
  {
    "city": "Formosa",
    "city_ascii": "Formosa",
    "lat": -26.1847,
    "lng": -58.1758,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Formosa",
    "capital": "admin",
    "population": 222226,
    "id": 1032802536
  },
  {
    "city": "Gabès",
    "city_ascii": "Gabes",
    "lat": 33.9004,
    "lng": 10.1,
    "country": "Tunisia",
    "iso2": "TN",
    "iso3": "TUN",
    "admin_name": "Gabès",
    "capital": "admin",
    "population": 219517,
    "id": 1788064276
  },
  {
    "city": "Imperatriz",
    "city_ascii": "Imperatriz",
    "lat": -5.52,
    "lng": -47.49,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Maranhão",
    "capital": "",
    "population": 218106,
    "id": 1076486025
  },
  {
    "city": "Sambhal",
    "city_ascii": "Sambhal",
    "lat": 28.58,
    "lng": 78.55,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Uttar Pradesh",
    "capital": "",
    "population": 220813,
    "id": 1356376216
  },
  {
    "city": "Polokwane",
    "city_ascii": "Polokwane",
    "lat": -23.9,
    "lng": 29.45,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "Limpopo",
    "capital": "admin",
    "population": 220045,
    "id": 1710908010
  },
  {
    "city": "Neyshābūr",
    "city_ascii": "Neyshabur",
    "lat": 36.22,
    "lng": 58.82,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Khorāsān-e Raẕavī",
    "capital": "minor",
    "population": 221700,
    "id": 1364706573
  },
  {
    "city": "Sidon",
    "city_ascii": "Sidon",
    "lat": 33.5606,
    "lng": 35.3981,
    "country": "Lebanon",
    "iso2": "LB",
    "iso3": "LBN",
    "admin_name": "Liban-Sud",
    "capital": "admin",
    "population": 200000,
    "id": 1422897920
  },
  {
    "city": "Radom",
    "city_ascii": "Radom",
    "lat": 51.4036,
    "lng": 21.1567,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Mazowieckie",
    "capital": "minor",
    "population": 219703,
    "id": 1616327727
  },
  {
    "city": "Lutsk",
    "city_ascii": "Lutsk",
    "lat": 50.7478,
    "lng": 25.3244,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Volyns’ka Oblast’",
    "capital": "admin",
    "population": 216505,
    "id": 1804014744
  },
  {
    "city": "La Serena",
    "city_ascii": "La Serena",
    "lat": -29.9027,
    "lng": -71.252,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Coquimbo",
    "capital": "admin",
    "population": 221054,
    "id": 1152155065
  },
  {
    "city": "Gujiao",
    "city_ascii": "Gujiao",
    "lat": 37.9069,
    "lng": 112.17,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Shanxi",
    "capital": "",
    "population": 220876,
    "id": 1156571525
  },
  {
    "city": "Gulfport",
    "city_ascii": "Gulfport",
    "lat": 30.4271,
    "lng": -89.0703,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Mississippi",
    "capital": "",
    "population": 220987,
    "id": 1840013947
  },
  {
    "city": "San Felipe",
    "city_ascii": "San Felipe",
    "lat": 10.3353,
    "lng": -68.7458,
    "country": "Venezuela",
    "iso2": "VE",
    "iso3": "VEN",
    "admin_name": "Yaracuy",
    "capital": "admin",
    "population": 220786,
    "id": 1862716082
  },
  {
    "city": "Sāveh",
    "city_ascii": "Saveh",
    "lat": 35.0211,
    "lng": 50.3564,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Markazī",
    "capital": "minor",
    "population": 220762,
    "id": 1364844111
  },
  {
    "city": "La Vega",
    "city_ascii": "La Vega",
    "lat": 19.2242,
    "lng": -70.5283,
    "country": "Dominican Republic",
    "iso2": "DO",
    "iso3": "DOM",
    "admin_name": "Cibao Sur",
    "capital": "admin",
    "population": 220279,
    "id": 1214691060
  },
  {
    "city": "Appleton",
    "city_ascii": "Appleton",
    "lat": 44.2779,
    "lng": -88.3891,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 220570,
    "id": 1840002400
  },
  {
    "city": "Khomeynī Shahr",
    "city_ascii": "Khomeyni Shahr",
    "lat": 32.7,
    "lng": 51.5211,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Eşfahān",
    "capital": "minor",
    "population": 218737,
    "id": 1364403465
  },
  {
    "city": "Al Khubar",
    "city_ascii": "Al Khubar",
    "lat": 26.3,
    "lng": 50.2167,
    "country": "Saudi Arabia",
    "iso2": "SA",
    "iso3": "SAU",
    "admin_name": "Ash Sharqīyah",
    "capital": "",
    "population": 219679,
    "id": 1682059414
  },
  {
    "city": "Islington",
    "city_ascii": "Islington",
    "lat": 51.544,
    "lng": -0.1027,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Islington",
    "capital": "",
    "population": 206125,
    "id": 1826267810
  },
  {
    "city": "Córdoba",
    "city_ascii": "Cordoba",
    "lat": 18.8942,
    "lng": -96.9347,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Veracruz",
    "capital": "minor",
    "population": 218153,
    "id": 1484179679
  },
  {
    "city": "San Pedro de Macorís",
    "city_ascii": "San Pedro de Macoris",
    "lat": 18.4504,
    "lng": -69.3,
    "country": "Dominican Republic",
    "iso2": "DO",
    "iso3": "DOM",
    "admin_name": "Higuamo",
    "capital": "admin",
    "population": 217899,
    "id": 1214233006
  },
  {
    "city": "Ciudad Acuña",
    "city_ascii": "Ciudad Acuna",
    "lat": 29.3242,
    "lng": -100.9317,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Coahuila de Zaragoza",
    "capital": "minor",
    "population": 216099,
    "id": 1484828397
  },
  {
    "city": "Sabadell",
    "city_ascii": "Sabadell",
    "lat": 41.5483,
    "lng": 2.1075,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Catalonia",
    "capital": "",
    "population": 213644,
    "id": 1724348384
  },
  {
    "city": "Hortolândia",
    "city_ascii": "Hortolandia",
    "lat": -22.8583,
    "lng": -47.22,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 215819,
    "id": 1076591779
  },
  {
    "city": "Bitung",
    "city_ascii": "Bitung",
    "lat": 1.4472,
    "lng": 125.1978,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Sulawesi Utara",
    "capital": "",
    "population": 218520,
    "id": 1360695939
  },
  {
    "city": "Naihāti",
    "city_ascii": "Naihati",
    "lat": 22.9,
    "lng": 88.42,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "West Bengal",
    "capital": "",
    "population": 217900,
    "id": 1356715598
  },
  {
    "city": "Damietta",
    "city_ascii": "Damietta",
    "lat": 31.4167,
    "lng": 31.8167,
    "country": "Egypt",
    "iso2": "EG",
    "iso3": "EGY",
    "admin_name": "Dumyāţ",
    "capital": "admin",
    "population": 206664,
    "id": 1818333966
  },
  {
    "city": "Groningen",
    "city_ascii": "Groningen",
    "lat": 53.2204,
    "lng": 6.58,
    "country": "Netherlands",
    "iso2": "NL",
    "iso3": "NLD",
    "admin_name": "Groningen",
    "capital": "admin",
    "population": 216688,
    "id": 1528295161
  },
  {
    "city": "Kure",
    "city_ascii": "Kure",
    "lat": 34.2492,
    "lng": 132.5658,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hiroshima",
    "capital": "",
    "population": 218351,
    "id": 1392991987
  },
  {
    "city": "Manta",
    "city_ascii": "Manta",
    "lat": -0.95,
    "lng": -80.7162,
    "country": "Ecuador",
    "iso2": "EC",
    "iso3": "ECU",
    "admin_name": "Manabí",
    "capital": "",
    "population": 217553,
    "id": 1218160109
  },
  {
    "city": "Butembo",
    "city_ascii": "Butembo",
    "lat": 0.1251,
    "lng": 29.299,
    "country": "Congo (Kinshasa)",
    "iso2": "CD",
    "iso3": "COD",
    "admin_name": "Nord-Kivu",
    "capital": "",
    "population": 217625,
    "id": 1180446432
  },
  {
    "city": "San Miguel",
    "city_ascii": "San Miguel",
    "lat": 13.4833,
    "lng": -88.1833,
    "country": "El Salvador",
    "iso2": "SV",
    "iso3": "SLV",
    "admin_name": "San Miguel",
    "capital": "admin",
    "population": 218410,
    "id": 1222009747
  },
  {
    "city": "Concepción",
    "city_ascii": "Concepcion",
    "lat": -36.8271,
    "lng": -73.0503,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Biobío",
    "capital": "admin",
    "population": 217537,
    "id": 1152838958
  },
  {
    "city": "Netanya",
    "city_ascii": "Netanya",
    "lat": 32.3328,
    "lng": 34.86,
    "country": "Israel",
    "iso2": "IL",
    "iso3": "ISR",
    "admin_name": "Central",
    "capital": "",
    "population": 217244,
    "id": 1376203187
  },
  {
    "city": "Bremerton",
    "city_ascii": "Bremerton",
    "lat": 47.5436,
    "lng": -122.7122,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 218400,
    "id": 1840018410
  },
  {
    "city": "Alor Setar",
    "city_ascii": "Alor Setar",
    "lat": 6.1167,
    "lng": 100.3667,
    "country": "Malaysia",
    "iso2": "MY",
    "iso3": "MYS",
    "admin_name": "Kedah",
    "capital": "admin",
    "population": 217000,
    "id": 1458273978
  },
  {
    "city": "Hickory",
    "city_ascii": "Hickory",
    "lat": 35.7425,
    "lng": -81.323,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 218323,
    "id": 1840014524
  },
  {
    "city": "Lübeck",
    "city_ascii": "Lubeck",
    "lat": 53.8697,
    "lng": 10.6864,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Schleswig-Holstein",
    "capital": "minor",
    "population": 217198,
    "id": 1276852342
  },
  {
    "city": "Los Ángeles",
    "city_ascii": "Los Angeles",
    "lat": -37.4707,
    "lng": -72.3517,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Biobío",
    "capital": "minor",
    "population": 218000,
    "id": 1152810305
  },
  {
    "city": "Aberdeen",
    "city_ascii": "Aberdeen",
    "lat": 57.15,
    "lng": -2.11,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Aberdeen City",
    "capital": "",
    "population": 214610,
    "id": 1826324306
  },
  {
    "city": "Luton",
    "city_ascii": "Luton",
    "lat": 51.8783,
    "lng": -0.4147,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Luton",
    "capital": "",
    "population": 213052,
    "id": 1826630574
  },
  {
    "city": "Tacoma",
    "city_ascii": "Tacoma",
    "lat": 47.2431,
    "lng": -122.4531,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 217827,
    "id": 1840021129
  },
  {
    "city": "Denov",
    "city_ascii": "Denov",
    "lat": 38.2772,
    "lng": 67.8872,
    "country": "Uzbekistan",
    "iso2": "UZ",
    "iso3": "UZB",
    "admin_name": "Surxondaryo",
    "capital": "",
    "population": 217274,
    "id": 1860150082
  },
  {
    "city": "Qostanay",
    "city_ascii": "Qostanay",
    "lat": 53.2144,
    "lng": 63.6246,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Qostanay",
    "capital": "admin",
    "population": 217135,
    "id": 1398455461
  },
  {
    "city": "Banjarbaru",
    "city_ascii": "Banjarbaru",
    "lat": -3.4667,
    "lng": 114.75,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Kalimantan Selatan",
    "capital": "",
    "population": 216600,
    "id": 1360085654
  },
  {
    "city": "Sa Đéc",
    "city_ascii": "Sa Dec",
    "lat": 10.3105,
    "lng": 105.7397,
    "country": "Vietnam",
    "iso2": "VN",
    "iso3": "VNM",
    "admin_name": "Đồng Tháp",
    "capital": "minor",
    "population": 213610,
    "id": 1704983576
  },
  {
    "city": "Norwich",
    "city_ascii": "Norwich",
    "lat": 52.63,
    "lng": 1.297,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Norfolk",
    "capital": "",
    "population": 213166,
    "id": 1826688051
  },
  {
    "city": "Petropavl",
    "city_ascii": "Petropavl",
    "lat": 54.8622,
    "lng": 69.1408,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Soltüstik Qazaqstan",
    "capital": "admin",
    "population": 216406,
    "id": 1398252858
  },
  {
    "city": "Marg‘ilon",
    "city_ascii": "Marg`ilon",
    "lat": 40.4667,
    "lng": 71.7167,
    "country": "Uzbekistan",
    "iso2": "UZ",
    "iso3": "UZB",
    "admin_name": "Farg‘ona",
    "capital": "",
    "population": 215400,
    "id": 1860419224
  },
  {
    "city": "Gangneung",
    "city_ascii": "Gangneung",
    "lat": 37.7556,
    "lng": 128.8961,
    "country": "Korea, South",
    "iso2": "KR",
    "iso3": "KOR",
    "admin_name": "Gangwon",
    "capital": "minor",
    "population": 215677,
    "id": 1410450550
  },
  {
    "city": "San Bernardino",
    "city_ascii": "San Bernardino",
    "lat": 34.1417,
    "lng": -117.2946,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 215784,
    "id": 1840021728
  },
  {
    "city": "Cúa",
    "city_ascii": "Cua",
    "lat": 10.1667,
    "lng": -66.8833,
    "country": "Venezuela",
    "iso2": "VE",
    "iso3": "VEN",
    "admin_name": "Miranda",
    "capital": "minor",
    "population": 214823,
    "id": 1862770169
  },
  {
    "city": "Meycauayan",
    "city_ascii": "Meycauayan",
    "lat": 14.7333,
    "lng": 120.95,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Bulacan",
    "capital": "",
    "population": 209083,
    "id": 1608553352
  },
  {
    "city": "Vantaa",
    "city_ascii": "Vantaa",
    "lat": 60.3,
    "lng": 25.0333,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Uusimaa",
    "capital": "minor",
    "population": 214605,
    "id": 1246957545
  },
  {
    "city": "As Samāwah",
    "city_ascii": "As Samawah",
    "lat": 31.3167,
    "lng": 45.2833,
    "country": "Iraq",
    "iso2": "IQ",
    "iso3": "IRQ",
    "admin_name": "Al Muthanná",
    "capital": "admin",
    "population": 215000,
    "id": 1368246912
  },
  {
    "city": "Ormoc",
    "city_ascii": "Ormoc",
    "lat": 11.0167,
    "lng": 124.6167,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Ormoc",
    "capital": "admin",
    "population": 215031,
    "id": 1608269612
  },
  {
    "city": "College Station",
    "city_ascii": "College Station",
    "lat": 30.5852,
    "lng": -96.296,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 215243,
    "id": 1840019570
  },
  {
    "city": "Golmud",
    "city_ascii": "Golmud",
    "lat": 36.4028,
    "lng": 94.9042,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Qinghai",
    "capital": "minor",
    "population": 215214,
    "id": 1156083724
  },
  {
    "city": "Kalamazoo",
    "city_ascii": "Kalamazoo",
    "lat": 42.2749,
    "lng": -85.5882,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 215193,
    "id": 1840003185
  },
  {
    "city": "Cartagena",
    "city_ascii": "Cartagena",
    "lat": 37.6,
    "lng": -0.9819,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Murcia",
    "capital": "",
    "population": 214802,
    "id": 1724143241
  },
  {
    "city": "Regina",
    "city_ascii": "Regina",
    "lat": 50.4547,
    "lng": -104.6067,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Saskatchewan",
    "capital": "",
    "population": 215106,
    "id": 1124342541
  },
  {
    "city": "Thousand Oaks",
    "city_ascii": "Thousand Oaks",
    "lat": 34.1914,
    "lng": -118.8755,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 215029,
    "id": 1840021844
  },
  {
    "city": "La Victoria",
    "city_ascii": "La Victoria",
    "lat": 10.2278,
    "lng": -67.3336,
    "country": "Venezuela",
    "iso2": "VE",
    "iso3": "VEN",
    "admin_name": "Aragua",
    "capital": "minor",
    "population": 214492,
    "id": 1862054455
  },
  {
    "city": "Shimla",
    "city_ascii": "Shimla",
    "lat": 31.1033,
    "lng": 77.1722,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Himāchal Pradesh",
    "capital": "admin",
    "population": 206575,
    "id": 1356962932
  },
  {
    "city": "Mohammedia",
    "city_ascii": "Mohammedia",
    "lat": 33.6833,
    "lng": -7.3833,
    "country": "Morocco",
    "iso2": "MA",
    "iso3": "MAR",
    "admin_name": "Casablanca-Settat",
    "capital": "",
    "population": 208612,
    "id": 1504542634
  },
  {
    "city": "Babruysk",
    "city_ascii": "Babruysk",
    "lat": 53.15,
    "lng": 29.2333,
    "country": "Belarus",
    "iso2": "BY",
    "iso3": "BLR",
    "admin_name": "Mahilyowskaya Voblasts’",
    "capital": "minor",
    "population": 212200,
    "id": 1112573808
  },
  {
    "city": "Roanoke",
    "city_ascii": "Roanoke",
    "lat": 37.2785,
    "lng": -79.958,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 214674,
    "id": 1840003858
  },
  {
    "city": "Osan",
    "city_ascii": "Osan",
    "lat": 37.145,
    "lng": 127.0694,
    "country": "Korea, South",
    "iso2": "KR",
    "iso3": "KOR",
    "admin_name": "Gyeonggi",
    "capital": "",
    "population": 213840,
    "id": 1410606985
  },
  {
    "city": "Geneva",
    "city_ascii": "Geneva",
    "lat": 46.2,
    "lng": 6.15,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Genève",
    "capital": "admin",
    "population": 201818,
    "id": 1756810813
  },
  {
    "city": "Criciúma",
    "city_ascii": "Criciuma",
    "lat": -28.68,
    "lng": -49.39,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Santa Catarina",
    "capital": "",
    "population": 204217,
    "id": 1076000206
  },
  {
    "city": "Fontana",
    "city_ascii": "Fontana",
    "lat": 34.0968,
    "lng": -117.4599,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 214547,
    "id": 1840020402
  },
  {
    "city": "Sikasso",
    "city_ascii": "Sikasso",
    "lat": 11.3167,
    "lng": -5.6667,
    "country": "Mali",
    "iso2": "ML",
    "iso3": "MLI",
    "admin_name": "Sikasso",
    "capital": "admin",
    "population": 213977,
    "id": 1466666150
  },
  {
    "city": "Warnes",
    "city_ascii": "Warnes",
    "lat": -17.5103,
    "lng": -63.1647,
    "country": "Bolivia",
    "iso2": "BO",
    "iso3": "BOL",
    "admin_name": "Santa Cruz",
    "capital": "",
    "population": 214216,
    "id": 1068890339
  },
  {
    "city": "Padova",
    "city_ascii": "Padova",
    "lat": 45.4064,
    "lng": 11.8778,
    "country": "Italy",
    "iso2": "IT",
    "iso3": "ITA",
    "admin_name": "Veneto",
    "capital": "minor",
    "population": 211560,
    "id": 1380964615
  },
  {
    "city": "Ciudad Madero",
    "city_ascii": "Ciudad Madero",
    "lat": 22.25,
    "lng": -97.8333,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Tamaulipas",
    "capital": "minor",
    "population": 209175,
    "id": 1484185237
  },
  {
    "city": "Oberhausen",
    "city_ascii": "Oberhausen",
    "lat": 51.4699,
    "lng": 6.8514,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "minor",
    "population": 210829,
    "id": 1276448936
  },
  {
    "city": "Jalālābād",
    "city_ascii": "Jalalabad",
    "lat": 34.4303,
    "lng": 70.4528,
    "country": "Afghanistan",
    "iso2": "AF",
    "iso3": "AFG",
    "admin_name": "Nangarhār",
    "capital": "admin",
    "population": 205423,
    "id": 1004315012
  },
  {
    "city": "Moreno Valley",
    "city_ascii": "Moreno Valley",
    "lat": 33.9244,
    "lng": -117.2045,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 213055,
    "id": 1840020552
  },
  {
    "city": "Sidi Bel Abbès",
    "city_ascii": "Sidi Bel Abbes",
    "lat": 35.2,
    "lng": -0.6333,
    "country": "Algeria",
    "iso2": "DZ",
    "iso3": "DZA",
    "admin_name": "Sidi Bel Abbès",
    "capital": "admin",
    "population": 212935,
    "id": 1012123414
  },
  {
    "city": "Jerez de la Frontera",
    "city_ascii": "Jerez de la Frontera",
    "lat": 36.7,
    "lng": -6.1167,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Andalusia",
    "capital": "",
    "population": 212749,
    "id": 1724866806
  },
  {
    "city": "Limbe",
    "city_ascii": "Limbe",
    "lat": 4.0167,
    "lng": 9.2167,
    "country": "Cameroon",
    "iso2": "CM",
    "iso3": "CMR",
    "admin_name": "South-West",
    "capital": "",
    "population": 212474,
    "id": 1120635273
  },
  {
    "city": "Timon",
    "city_ascii": "Timon",
    "lat": -5.115,
    "lng": -42.845,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Maranhão",
    "capital": "",
    "population": 203157,
    "id": 1076758286
  },
  {
    "city": "Ploieşti",
    "city_ascii": "Ploiesti",
    "lat": 44.9386,
    "lng": 26.0225,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Prahova",
    "capital": "admin",
    "population": 209945,
    "id": 1642169731
  },
  {
    "city": "Metepec",
    "city_ascii": "Metepec",
    "lat": 19.2511,
    "lng": -99.6047,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Morelos",
    "capital": "minor",
    "population": 206005,
    "id": 1484782768
  },
  {
    "city": "Pskov",
    "city_ascii": "Pskov",
    "lat": 57.8167,
    "lng": 28.3333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Pskovskaya Oblast’",
    "capital": "admin",
    "population": 209840,
    "id": 1643877414
  },
  {
    "city": "Bila Tserkva",
    "city_ascii": "Bila Tserkva",
    "lat": 49.7956,
    "lng": 30.1167,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Kyyivs’ka Oblast’",
    "capital": "minor",
    "population": 208944,
    "id": 1804758853
  },
  {
    "city": "Dayr az Zawr",
    "city_ascii": "Dayr az Zawr",
    "lat": 35.3333,
    "lng": 40.15,
    "country": "Syria",
    "iso2": "SY",
    "iso3": "SYR",
    "admin_name": "Dayr az Zawr",
    "capital": "admin",
    "population": 211857,
    "id": 1760792603
  },
  {
    "city": "Babylon",
    "city_ascii": "Babylon",
    "lat": 40.6924,
    "lng": -73.3585,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 211562,
    "id": 1840005142
  },
  {
    "city": "Chungju",
    "city_ascii": "Chungju",
    "lat": 36.9706,
    "lng": 127.9322,
    "country": "Korea, South",
    "iso2": "KR",
    "iso3": "KOR",
    "admin_name": "Chungbuk",
    "capital": "",
    "population": 211005,
    "id": 1410333057
  },
  {
    "city": "San-Pédro",
    "city_ascii": "San-Pedro",
    "lat": 4.7704,
    "lng": -6.64,
    "country": "Côte D’Ivoire",
    "iso2": "CI",
    "iso3": "CIV",
    "admin_name": "Bas-Sassandra",
    "capital": "admin",
    "population": 210273,
    "id": 1384436547
  },
  {
    "city": "Bukit Mertajam",
    "city_ascii": "Bukit Mertajam",
    "lat": 5.3617,
    "lng": 100.461,
    "country": "Malaysia",
    "iso2": "MY",
    "iso3": "MYS",
    "admin_name": "Pulau Pinang",
    "capital": "",
    "population": 210000,
    "id": 1458619046
  },
  {
    "city": "Ijebu-Ode",
    "city_ascii": "Ijebu-Ode",
    "lat": 6.8204,
    "lng": 3.92,
    "country": "Nigeria",
    "iso2": "NG",
    "iso3": "NGA",
    "admin_name": "Ogun",
    "capital": "minor",
    "population": 209175,
    "id": 1566495747
  },
  {
    "city": "Cork",
    "city_ascii": "Cork",
    "lat": 51.9,
    "lng": -8.4731,
    "country": "Ireland",
    "iso2": "IE",
    "iso3": "IRL",
    "admin_name": "Cork",
    "capital": "admin",
    "population": 208669,
    "id": 1372221853
  },
  {
    "city": "Pamplona",
    "city_ascii": "Pamplona",
    "lat": 42.8167,
    "lng": -1.65,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Navarre",
    "capital": "admin",
    "population": 201653,
    "id": 1724483329
  },
  {
    "city": "Guarenas",
    "city_ascii": "Guarenas",
    "lat": 10.4739,
    "lng": -66.5383,
    "country": "Venezuela",
    "iso2": "VE",
    "iso3": "VEN",
    "admin_name": "Miranda",
    "capital": "minor",
    "population": 208663,
    "id": 1862083525
  },
  {
    "city": "Rostock",
    "city_ascii": "Rostock",
    "lat": 54.0833,
    "lng": 12.1333,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Mecklenburg-Western Pomerania",
    "capital": "",
    "population": 208886,
    "id": 1276210265
  },
  {
    "city": "Dongning",
    "city_ascii": "Dongning",
    "lat": 44.0608,
    "lng": 131.1187,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Heilongjiang",
    "capital": "minor",
    "population": 210000,
    "id": 1156856366
  },
  {
    "city": "Moratuwa",
    "city_ascii": "Moratuwa",
    "lat": 6.7804,
    "lng": 79.88,
    "country": "Sri Lanka",
    "iso2": "LK",
    "iso3": "LKA",
    "admin_name": "Western",
    "capital": "",
    "population": 200000,
    "id": 1144324309
  },
  {
    "city": "Ich’ŏn",
    "city_ascii": "Ich'on",
    "lat": 37.2792,
    "lng": 127.4425,
    "country": "Korea, South",
    "iso2": "KR",
    "iso3": "KOR",
    "admin_name": "Gyeonggi",
    "capital": "",
    "population": 209003,
    "id": 1410736435
  },
  {
    "city": "Puerto Cabello",
    "city_ascii": "Puerto Cabello",
    "lat": 10.4667,
    "lng": -68.0167,
    "country": "Venezuela",
    "iso2": "VE",
    "iso3": "VEN",
    "admin_name": "Carabobo",
    "capital": "minor",
    "population": 209080,
    "id": 1862031193
  },
  {
    "city": "Beersheba",
    "city_ascii": "Beersheba",
    "lat": 31.2589,
    "lng": 34.7978,
    "country": "Israel",
    "iso2": "IL",
    "iso3": "ISR",
    "admin_name": "Southern",
    "capital": "admin",
    "population": 209000,
    "id": 1376023307
  },
  {
    "city": "Nasīm Shahr",
    "city_ascii": "Nasim Shahr",
    "lat": 35.5644,
    "lng": 51.1647,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Tehrān",
    "capital": "minor",
    "population": 200393,
    "id": 1364571196
  },
  {
    "city": "North Port",
    "city_ascii": "North Port",
    "lat": 27.0577,
    "lng": -82.1975,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 209070,
    "id": 1840015120
  },
  {
    "city": "La Romana",
    "city_ascii": "La Romana",
    "lat": 18.417,
    "lng": -68.9666,
    "country": "Dominican Republic",
    "iso2": "DO",
    "iso3": "DOM",
    "admin_name": "Yuma",
    "capital": "admin",
    "population": 208437,
    "id": 1214760518
  },
  {
    "city": "Lubuklinggau",
    "city_ascii": "Lubuklinggau",
    "lat": -3.2967,
    "lng": 102.8617,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Sumatera Selatan",
    "capital": "",
    "population": 208225,
    "id": 1360359449
  },
  {
    "city": "Qinā",
    "city_ascii": "Qina",
    "lat": 26.1667,
    "lng": 32.7167,
    "country": "Egypt",
    "iso2": "EG",
    "iso3": "EGY",
    "admin_name": "Qinā",
    "capital": "admin",
    "population": 201191,
    "id": 1818868450
  },
  {
    "city": "Mary",
    "city_ascii": "Mary",
    "lat": 37.6,
    "lng": 61.8333,
    "country": "Turkmenistan",
    "iso2": "TM",
    "iso3": "TKM",
    "admin_name": "Mary",
    "capital": "admin",
    "population": 208682,
    "id": 1795413265
  },
  {
    "city": "Santa Cruz",
    "city_ascii": "Santa Cruz",
    "lat": 28.4667,
    "lng": -16.25,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Canary Islands",
    "capital": "admin",
    "population": 207312,
    "id": 1724813158
  },
  {
    "city": "Fargo",
    "city_ascii": "Fargo",
    "lat": 46.8652,
    "lng": -96.8292,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Dakota",
    "capital": "",
    "population": 208675,
    "id": 1840000177
  },
  {
    "city": "Huangyan",
    "city_ascii": "Huangyan",
    "lat": 28.65,
    "lng": 121.25,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Zhejiang",
    "capital": "",
    "population": 198713,
    "id": 1156149186
  },
  {
    "city": "Santa Clara",
    "city_ascii": "Santa Clara",
    "lat": 22.4067,
    "lng": -79.9531,
    "country": "Cuba",
    "iso2": "CU",
    "iso3": "CUB",
    "admin_name": "Villa Clara",
    "capital": "admin",
    "population": 207963,
    "id": 1192329773
  },
  {
    "city": "Kharagpur",
    "city_ascii": "Kharagpur",
    "lat": 22.3302,
    "lng": 87.3237,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "West Bengal",
    "capital": "",
    "population": 207604,
    "id": 1356305803
  },
  {
    "city": "Northcote",
    "city_ascii": "Northcote",
    "lat": -36.7913,
    "lng": 174.7758,
    "country": "New Zealand",
    "iso2": "NZ",
    "iso3": "NZL",
    "admin_name": "Auckland",
    "capital": "",
    "population": 205605,
    "id": 1554717659
  },
  {
    "city": "Waitakere",
    "city_ascii": "Waitakere",
    "lat": -36.849,
    "lng": 174.543,
    "country": "New Zealand",
    "iso2": "NZ",
    "iso3": "NZL",
    "admin_name": "Auckland",
    "capital": "",
    "population": 208100,
    "id": 1554034359
  },
  {
    "city": "Lobito",
    "city_ascii": "Lobito",
    "lat": -12.37,
    "lng": 13.5412,
    "country": "Angola",
    "iso2": "AO",
    "iso3": "AGO",
    "admin_name": "Benguela",
    "capital": "",
    "population": 207932,
    "id": 1024128946
  },
  {
    "city": "Monywa",
    "city_ascii": "Monywa",
    "lat": 22.1083,
    "lng": 95.1358,
    "country": "Burma",
    "iso2": "MM",
    "iso3": "MMR",
    "admin_name": "Sagaing",
    "capital": "",
    "population": 207489,
    "id": 1104777345
  },
  {
    "city": "Dindigul",
    "city_ascii": "Dindigul",
    "lat": 10.35,
    "lng": 77.95,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Tamil Nādu",
    "capital": "",
    "population": 207327,
    "id": 1356106153
  },
  {
    "city": "Morogoro",
    "city_ascii": "Morogoro",
    "lat": -6.8242,
    "lng": 37.6633,
    "country": "Tanzania",
    "iso2": "TZ",
    "iso3": "TZA",
    "admin_name": "Morogoro",
    "capital": "admin",
    "population": 207000,
    "id": 1834185429
  },
  {
    "city": "Green Bay",
    "city_ascii": "Green Bay",
    "lat": 44.515,
    "lng": -87.9896,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 207557,
    "id": 1840002344
  },
  {
    "city": "Portoviejo",
    "city_ascii": "Portoviejo",
    "lat": -1.0544,
    "lng": -80.4544,
    "country": "Ecuador",
    "iso2": "EC",
    "iso3": "ECU",
    "admin_name": "Manabí",
    "capital": "admin",
    "population": 206682,
    "id": 1218169586
  },
  {
    "city": "Pingzhen",
    "city_ascii": "Pingzhen",
    "lat": 24.9439,
    "lng": 121.2161,
    "country": "Taiwan",
    "iso2": "TW",
    "iso3": "TWN",
    "admin_name": "Taoyuan",
    "capital": "",
    "population": 201632,
    "id": 1158047789
  },
  {
    "city": "Linz",
    "city_ascii": "Linz",
    "lat": 48.3,
    "lng": 14.2833,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Oberösterreich",
    "capital": "admin",
    "population": 204846,
    "id": 1040261171
  },
  {
    "city": "Trieste",
    "city_ascii": "Trieste",
    "lat": 45.6361,
    "lng": 13.8042,
    "country": "Italy",
    "iso2": "IT",
    "iso3": "ITA",
    "admin_name": "Friuli-Venezia Giulia",
    "capital": "admin",
    "population": 204338,
    "id": 1380102581
  },
  {
    "city": "Sacala",
    "city_ascii": "Sacala",
    "lat": -14.45,
    "lng": 40.6667,
    "country": "Mozambique",
    "iso2": "MZ",
    "iso3": "MOZ",
    "admin_name": "Nampula",
    "capital": "",
    "population": 206449,
    "id": 1508052353
  },
  {
    "city": "Itami",
    "city_ascii": "Itami",
    "lat": 34.7867,
    "lng": 135.4056,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hyōgo",
    "capital": "",
    "population": 198395,
    "id": 1392384479
  },
  {
    "city": "Phan Thiết",
    "city_ascii": "Phan Thiet",
    "lat": 10.9375,
    "lng": 108.1583,
    "country": "Vietnam",
    "iso2": "VN",
    "iso3": "VNM",
    "admin_name": "Bình Thuận",
    "capital": "admin",
    "population": 205333,
    "id": 1704221456
  },
  {
    "city": "Kalemie",
    "city_ascii": "Kalemie",
    "lat": -5.9147,
    "lng": 29.1939,
    "country": "Congo (Kinshasa)",
    "iso2": "CD",
    "iso3": "COD",
    "admin_name": "Tanganyika",
    "capital": "admin",
    "population": 206257,
    "id": 1180574233
  },
  {
    "city": "Biskra",
    "city_ascii": "Biskra",
    "lat": 34.85,
    "lng": 5.7333,
    "country": "Algeria",
    "iso2": "DZ",
    "iso3": "DZA",
    "admin_name": "Biskra",
    "capital": "admin",
    "population": 204661,
    "id": 1012947247
  },
  {
    "city": "Ingrāj Bāzār",
    "city_ascii": "Ingraj Bazar",
    "lat": 25,
    "lng": 88.15,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "West Bengal",
    "capital": "",
    "population": 205521,
    "id": 1356142829
  },
  {
    "city": "Banī Suwayf",
    "city_ascii": "Bani Suwayf",
    "lat": 29.0667,
    "lng": 31.0833,
    "country": "Egypt",
    "iso2": "EG",
    "iso3": "EGY",
    "admin_name": "Banī Suwayf",
    "capital": "admin",
    "population": 193048,
    "id": 1818902849
  },
  {
    "city": "Ellore",
    "city_ascii": "Ellore",
    "lat": 16.7,
    "lng": 81.1,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Andhra Pradesh",
    "capital": "",
    "population": 203780,
    "id": 1356931882
  },
  {
    "city": "Mossoró",
    "city_ascii": "Mossoro",
    "lat": -5.19,
    "lng": -37.34,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Rio Grande do Norte",
    "capital": "",
    "population": 202583,
    "id": 1076461194
  },
  {
    "city": "Mytishchi",
    "city_ascii": "Mytishchi",
    "lat": 55.9167,
    "lng": 37.7333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Moskovskaya Oblast’",
    "capital": "",
    "population": 205397,
    "id": 1643322494
  },
  {
    "city": "Zanzibar",
    "city_ascii": "Zanzibar",
    "lat": -6.1667,
    "lng": 39.2,
    "country": "Tanzania",
    "iso2": "TZ",
    "iso3": "TZA",
    "admin_name": "Zanzibar Urban/West",
    "capital": "admin",
    "population": 205870,
    "id": 1834823848
  },
  {
    "city": "Jiutai",
    "city_ascii": "Jiutai",
    "lat": 44.1447,
    "lng": 125.8443,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Jilin",
    "capital": "",
    "population": 205399,
    "id": 1156061722
  },
  {
    "city": "Qā’em Shahr",
    "city_ascii": "Qa'em Shahr",
    "lat": 36.4611,
    "lng": 52.8606,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Māzandarān",
    "capital": "",
    "population": 204953,
    "id": 1364170406
  },
  {
    "city": "Bolu",
    "city_ascii": "Bolu",
    "lat": 40.7333,
    "lng": 31.6,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Bolu",
    "capital": "admin",
    "population": 205525,
    "id": 1792807068
  },
  {
    "city": "Amarillo",
    "city_ascii": "Amarillo",
    "lat": 35.1988,
    "lng": -101.8311,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 205603,
    "id": 1840019156
  },
  {
    "city": "Ziguinchor",
    "city_ascii": "Ziguinchor",
    "lat": 12.5833,
    "lng": -16.2667,
    "country": "Senegal",
    "iso2": "SN",
    "iso3": "SEN",
    "admin_name": "Ziguinchor",
    "capital": "admin",
    "population": 205294,
    "id": 1686273643
  },
  {
    "city": "Ternate",
    "city_ascii": "Ternate",
    "lat": 0.7833,
    "lng": 127.3667,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Maluku Utara",
    "capital": "",
    "population": 204215,
    "id": 1360041663
  },
  {
    "city": "Puqi",
    "city_ascii": "Puqi",
    "lat": 29.7204,
    "lng": 113.88,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hubei",
    "capital": "",
    "population": 205164,
    "id": 1156189796
  },
  {
    "city": "Sete Lagoas",
    "city_ascii": "Sete Lagoas",
    "lat": -19.4496,
    "lng": -44.25,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Minas Gerais",
    "capital": "",
    "population": 201334,
    "id": 1076863850
  },
  {
    "city": "Bené Beraq",
    "city_ascii": "Bene Beraq",
    "lat": 32.0807,
    "lng": 34.8338,
    "country": "Israel",
    "iso2": "IL",
    "iso3": "ISR",
    "admin_name": "Tel Aviv",
    "capital": "",
    "population": 193774,
    "id": 1376944837
  },
  {
    "city": "Toruń",
    "city_ascii": "Torun",
    "lat": 53.0167,
    "lng": 18.6167,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Kujawsko-Pomorskie",
    "capital": "admin",
    "population": 202521,
    "id": 1616267221
  },
  {
    "city": "Coquimbo",
    "city_ascii": "Coquimbo",
    "lat": -29.9532,
    "lng": -71.338,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Coquimbo",
    "capital": "minor",
    "population": 204068,
    "id": 1152038253
  },
  {
    "city": "Mandi Burewala",
    "city_ascii": "Mandi Burewala",
    "lat": 30.15,
    "lng": 72.6833,
    "country": "Pakistan",
    "iso2": "PK",
    "iso3": "PAK",
    "admin_name": "Punjab",
    "capital": "",
    "population": 203454,
    "id": 1586997587
  },
  {
    "city": "Kamirenjaku",
    "city_ascii": "Kamirenjaku",
    "lat": 35.6833,
    "lng": 139.5594,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Tōkyō",
    "capital": "",
    "population": 192489,
    "id": 1392889022
  },
  {
    "city": "Marawi City",
    "city_ascii": "Marawi City",
    "lat": 8,
    "lng": 124.3,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Lanao del Sur",
    "capital": "admin",
    "population": 201785,
    "id": 1608812117
  },
  {
    "city": "Erfurt",
    "city_ascii": "Erfurt",
    "lat": 50.9787,
    "lng": 11.0328,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Thuringia",
    "capital": "admin",
    "population": 203254,
    "id": 1276924655
  },
  {
    "city": "Portland",
    "city_ascii": "Portland",
    "lat": 43.6773,
    "lng": -70.2715,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maine",
    "capital": "",
    "population": 203969,
    "id": 1840000327
  },
  {
    "city": "Matsue",
    "city_ascii": "Matsue",
    "lat": 35.4681,
    "lng": 133.0486,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Shimane",
    "capital": "admin",
    "population": 203565,
    "id": 1392692238
  },
  {
    "city": "Biysk",
    "city_ascii": "Biysk",
    "lat": 52.5167,
    "lng": 85.1667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Altayskiy Kray",
    "capital": "",
    "population": 203108,
    "id": 1643652314
  },
  {
    "city": "Charleroi",
    "city_ascii": "Charleroi",
    "lat": 50.4167,
    "lng": 4.4442,
    "country": "Belgium",
    "iso2": "BE",
    "iso3": "BEL",
    "admin_name": "Wallonia",
    "capital": "minor",
    "population": 201816,
    "id": 1056263311
  },
  {
    "city": "Kassel",
    "city_ascii": "Kassel",
    "lat": 51.3158,
    "lng": 9.4979,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "minor",
    "population": 201585,
    "id": 1276791686
  },
  {
    "city": "Poza Rica de Hidalgo",
    "city_ascii": "Poza Rica de Hidalgo",
    "lat": 20.5333,
    "lng": -97.45,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Veracruz",
    "capital": "minor",
    "population": 200119,
    "id": 1484808798
  },
  {
    "city": "Tanjungpinang",
    "city_ascii": "Tanjungpinang",
    "lat": 0.9188,
    "lng": 104.4554,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Kepulauan Riau",
    "capital": "admin",
    "population": 203008,
    "id": 1360990589
  },
  {
    "city": "Zhubei",
    "city_ascii": "Zhubei",
    "lat": 24.8333,
    "lng": 121.0119,
    "country": "Taiwan",
    "iso2": "TW",
    "iso3": "TWN",
    "admin_name": "Hsinchu",
    "capital": "admin",
    "population": 200000,
    "id": 1158417233
  },
  {
    "city": "Djougou",
    "city_ascii": "Djougou",
    "lat": 9.7004,
    "lng": 1.68,
    "country": "Benin",
    "iso2": "BJ",
    "iso3": "BEN",
    "admin_name": "Donga",
    "capital": "admin",
    "population": 202810,
    "id": 1204972666
  },
  {
    "city": "Luxor",
    "city_ascii": "Luxor",
    "lat": 25.6969,
    "lng": 32.6422,
    "country": "Egypt",
    "iso2": "EG",
    "iso3": "EGY",
    "admin_name": "Al Uqşur",
    "capital": "admin",
    "population": 202232,
    "id": 1818948225
  },
  {
    "city": "Huntington",
    "city_ascii": "Huntington",
    "lat": 40.8522,
    "lng": -73.3824,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 202673,
    "id": 1840058192
  },
  {
    "city": "Tarakan",
    "city_ascii": "Tarakan",
    "lat": 3.3,
    "lng": 117.6333,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Kalimantan Utara",
    "capital": "",
    "population": 201635,
    "id": 1360080072
  },
  {
    "city": "Santa Barbara",
    "city_ascii": "Santa Barbara",
    "lat": 34.4285,
    "lng": -119.7202,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 202401,
    "id": 1840021829
  },
  {
    "city": "Gainesville",
    "city_ascii": "Gainesville",
    "lat": 29.6804,
    "lng": -82.3458,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 202335,
    "id": 1840014022
  },
  {
    "city": "Las Tunas",
    "city_ascii": "Las Tunas",
    "lat": 20.9667,
    "lng": -76.95,
    "country": "Cuba",
    "iso2": "CU",
    "iso3": "CUB",
    "admin_name": "Las Tunas",
    "capital": "admin",
    "population": 202105,
    "id": 1192626319
  },
  {
    "city": "Lyubertsy",
    "city_ascii": "Lyubertsy",
    "lat": 55.6814,
    "lng": 37.8939,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Moskovskaya Oblast’",
    "capital": "",
    "population": 197705,
    "id": 1643024284
  },
  {
    "city": "Sosnowiec",
    "city_ascii": "Sosnowiec",
    "lat": 50.3,
    "lng": 19.1667,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Śląskie",
    "capital": "minor",
    "population": 199974,
    "id": 1616057255
  },
  {
    "city": "Arica",
    "city_ascii": "Arica",
    "lat": -18.4784,
    "lng": -70.3211,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Arica y Parinacota",
    "capital": "admin",
    "population": 202131,
    "id": 1152531089
  },
  {
    "city": "Huacho",
    "city_ascii": "Huacho",
    "lat": -11.1083,
    "lng": -77.6083,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "Lima",
    "capital": "admin",
    "population": 200585,
    "id": 1604316784
  },
  {
    "city": "Debrecen",
    "city_ascii": "Debrecen",
    "lat": 47.53,
    "lng": 21.6392,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Hajdú-Bihar",
    "capital": "admin",
    "population": 201432,
    "id": 1348460698
  },
  {
    "city": "Nandyāl",
    "city_ascii": "Nandyal",
    "lat": 15.48,
    "lng": 78.48,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Andhra Pradesh",
    "capital": "",
    "population": 200516,
    "id": 1356671879
  },
  {
    "city": "Cajamarca",
    "city_ascii": "Cajamarca",
    "lat": -7.1644,
    "lng": -78.5106,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "Cajamarca",
    "capital": "admin",
    "population": 201329,
    "id": 1604091119
  },
  {
    "city": "Croydon",
    "city_ascii": "Croydon",
    "lat": 51.3727,
    "lng": -0.1099,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Croydon",
    "capital": "",
    "population": 192064,
    "id": 1826126675
  },
  {
    "city": "Lashkar Gāh",
    "city_ascii": "Lashkar Gah",
    "lat": 31.5938,
    "lng": 64.3716,
    "country": "Afghanistan",
    "iso2": "AF",
    "iso3": "AFG",
    "admin_name": "Helmand",
    "capital": "admin",
    "population": 201546,
    "id": 1004765445
  },
  {
    "city": "Yachiyo",
    "city_ascii": "Yachiyo",
    "lat": 35.7225,
    "lng": 140.0997,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Chiba",
    "capital": "",
    "population": 197792,
    "id": 1392929073
  },
  {
    "city": "Haldia",
    "city_ascii": "Haldia",
    "lat": 22.0257,
    "lng": 88.0583,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "West Bengal",
    "capital": "",
    "population": 200762,
    "id": 1356491171
  },
  {
    "city": "San Pablo de las Salinas",
    "city_ascii": "San Pablo de las Salinas",
    "lat": 19.6656,
    "lng": -99.0917,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "México",
    "capital": "",
    "population": 189453,
    "id": 1484379698
  },
  {
    "city": "Jacobabad",
    "city_ascii": "Jacobabad",
    "lat": 28.2769,
    "lng": 68.4514,
    "country": "Pakistan",
    "iso2": "PK",
    "iso3": "PAK",
    "admin_name": "Sindh",
    "capital": "minor",
    "population": 200815,
    "id": 1586964444
  },
  {
    "city": "Tokat",
    "city_ascii": "Tokat",
    "lat": 40.3097,
    "lng": 36.5542,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Tokat",
    "capital": "admin",
    "population": 201294,
    "id": 1792534905
  },
  {
    "city": "Qyzylorda",
    "city_ascii": "Qyzylorda",
    "lat": 44.8479,
    "lng": 65.4999,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Qyzylorda",
    "capital": "",
    "population": 200900,
    "id": 1398248334
  },
  {
    "city": "Néma",
    "city_ascii": "Nema",
    "lat": 16.6171,
    "lng": -7.25,
    "country": "Mauritania",
    "iso2": "MR",
    "iso3": "MRT",
    "admin_name": "Hodh ech Chargui",
    "capital": "admin",
    "population": 200000,
    "id": 1478009046
  },
  {
    "city": "Olympia",
    "city_ascii": "Olympia",
    "lat": 47.0417,
    "lng": -122.8959,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "admin",
    "population": 200915,
    "id": 1840019865
  },
  {
    "city": "Gurgaon",
    "city_ascii": "Gurgaon",
    "lat": 28.45,
    "lng": 77.02,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Haryāna",
    "capital": "",
    "population": 197340,
    "id": 1356836043
  },
  {
    "city": "Liège",
    "city_ascii": "Liege",
    "lat": 50.625,
    "lng": 5.5907,
    "country": "Belgium",
    "iso2": "BE",
    "iso3": "BEL",
    "admin_name": "Wallonia",
    "capital": "minor",
    "population": 197885,
    "id": 1056513284
  },
  {
    "city": "Oulu",
    "city_ascii": "Oulu",
    "lat": 65.0142,
    "lng": 25.4719,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Pohjois-Pohjanmaa",
    "capital": "admin",
    "population": 200526,
    "id": 1246093273
  },
  {
    "city": "El Jadid",
    "city_ascii": "El Jadid",
    "lat": 33.2333,
    "lng": -8.5,
    "country": "Morocco",
    "iso2": "MA",
    "iso3": "MAR",
    "admin_name": "Casablanca-Settat",
    "capital": "",
    "population": 194934,
    "id": 1504964063
  },
  {
    "city": "Frisco",
    "city_ascii": "Frisco",
    "lat": 33.1555,
    "lng": -96.8215,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 200490,
    "id": 1840020654
  },
  {
    "city": "Isidro Casanova",
    "city_ascii": "Isidro Casanova",
    "lat": -34.7,
    "lng": -58.5833,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires",
    "capital": "",
    "population": 190696,
    "id": 1032715964
  },
  {
    "city": "Maīmanah",
    "city_ascii": "Maimanah",
    "lat": 35.9302,
    "lng": 64.7701,
    "country": "Afghanistan",
    "iso2": "AF",
    "iso3": "AFG",
    "admin_name": "Fāryāb",
    "capital": "admin",
    "population": 199795,
    "id": 1004622920
  },
  {
    "city": "Yonkers",
    "city_ascii": "Yonkers",
    "lat": 40.9466,
    "lng": -73.8674,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 200370,
    "id": 1840003478
  },
  {
    "city": "Rio Claro",
    "city_ascii": "Rio Claro",
    "lat": -22.4108,
    "lng": -47.5608,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 199961,
    "id": 1076411428
  },
  {
    "city": "Norwich",
    "city_ascii": "Norwich",
    "lat": 41.5495,
    "lng": -72.0882,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 200289,
    "id": 1840004828
  },
  {
    "city": "Az Zāwīyah",
    "city_ascii": "Az Zawiyah",
    "lat": 32.7604,
    "lng": 12.72,
    "country": "Libya",
    "iso2": "LY",
    "iso3": "LBY",
    "admin_name": "Az Zāwiyah",
    "capital": "admin",
    "population": 200000,
    "id": 1434421178
  },
  {
    "city": "Bulandshahr",
    "city_ascii": "Bulandshahr",
    "lat": 28.4104,
    "lng": 77.8484,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Uttar Pradesh",
    "capital": "",
    "population": 198612,
    "id": 1356067648
  },
  {
    "city": "Kasama",
    "city_ascii": "Kasama",
    "lat": -10.1996,
    "lng": 31.1799,
    "country": "Zambia",
    "iso2": "ZM",
    "iso3": "ZMB",
    "admin_name": "Northern",
    "capital": "admin",
    "population": 200000,
    "id": 1894305215
  },
  {
    "city": "Divinópolis",
    "city_ascii": "Divinopolis",
    "lat": -20.1495,
    "lng": -44.9,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Minas Gerais",
    "capital": "",
    "population": 193832,
    "id": 1076716552
  },
  {
    "city": "Puerto Cortés",
    "city_ascii": "Puerto Cortes",
    "lat": 15.8833,
    "lng": -87.95,
    "country": "Honduras",
    "iso2": "HN",
    "iso3": "HND",
    "admin_name": "Cortés",
    "capital": "",
    "population": 200000,
    "id": 1340000062
  },
  {
    "city": "Lahad Datu",
    "city_ascii": "Lahad Datu",
    "lat": 5.03,
    "lng": 118.34,
    "country": "Malaysia",
    "iso2": "MY",
    "iso3": "MYS",
    "admin_name": "Sabah",
    "capital": "",
    "population": 199830,
    "id": 1458472057
  },
  {
    "city": "Bojnūrd",
    "city_ascii": "Bojnurd",
    "lat": 37.4667,
    "lng": 57.3333,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Khorāsān-e Shomālī",
    "capital": "admin",
    "population": 199791,
    "id": 1364981174
  },
  {
    "city": "Spring Valley",
    "city_ascii": "Spring Valley",
    "lat": 36.0987,
    "lng": -115.2619,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Nevada",
    "capital": "",
    "population": 199722,
    "id": 1840033832
  },
  {
    "city": "Pagadian",
    "city_ascii": "Pagadian",
    "lat": 7.8333,
    "lng": 123.4333,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Zamboanga del Sur",
    "capital": "admin",
    "population": 199060,
    "id": 1608329397
  },
  {
    "city": "Nagareyama",
    "city_ascii": "Nagareyama",
    "lat": 35.8561,
    "lng": 139.9025,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Saitama",
    "capital": "",
    "population": 193976,
    "id": 1392398873
  },
  {
    "city": "Banja Luka",
    "city_ascii": "Banja Luka",
    "lat": 44.7667,
    "lng": 17.1833,
    "country": "Bosnia And Herzegovina",
    "iso2": "BA",
    "iso3": "BIH",
    "admin_name": "Srpska, Republika",
    "capital": "admin",
    "population": 199191,
    "id": 1070859064
  },
  {
    "city": "Glendale",
    "city_ascii": "Glendale",
    "lat": 34.1818,
    "lng": -118.2468,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 199303,
    "id": 1840020483
  },
  {
    "city": "Huntington Beach",
    "city_ascii": "Huntington Beach",
    "lat": 33.696,
    "lng": -118.0025,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 199223,
    "id": 1840020578
  },
  {
    "city": "Almería",
    "city_ascii": "Almeria",
    "lat": 36.8333,
    "lng": -2.45,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Andalusia",
    "capital": "minor",
    "population": 198533,
    "id": 1724745640
  },
  {
    "city": "Brescia",
    "city_ascii": "Brescia",
    "lat": 45.5389,
    "lng": 10.2203,
    "country": "Italy",
    "iso2": "IT",
    "iso3": "ITA",
    "admin_name": "Lombardy",
    "capital": "minor",
    "population": 197008,
    "id": 1380432555
  },
  {
    "city": "Baharampur",
    "city_ascii": "Baharampur",
    "lat": 24.1,
    "lng": 88.25,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "West Bengal",
    "capital": "",
    "population": 195223,
    "id": 1356612685
  },
  {
    "city": "Taranto",
    "city_ascii": "Taranto",
    "lat": 40.4181,
    "lng": 17.2408,
    "country": "Italy",
    "iso2": "IT",
    "iso3": "ITA",
    "admin_name": "Puglia",
    "capital": "minor",
    "population": 198283,
    "id": 1380707445
  },
  {
    "city": "Ashino",
    "city_ascii": "Ashino",
    "lat": 42.975,
    "lng": 144.3747,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hokkaidō",
    "capital": "",
    "population": 198566,
    "id": 1392810778
  },
  {
    "city": "Talca",
    "city_ascii": "Talca",
    "lat": -35.455,
    "lng": -71.67,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Maule",
    "capital": "admin",
    "population": 197479,
    "id": 1152736946
  },
  {
    "city": "Martapura",
    "city_ascii": "Martapura",
    "lat": -3.4135,
    "lng": 114.8365,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Kalimantan Selatan",
    "capital": "minor",
    "population": 198239,
    "id": 1360139854
  },
  {
    "city": "Chakradharpur",
    "city_ascii": "Chakradharpur",
    "lat": 22.7,
    "lng": 85.63,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Jharkhand",
    "capital": "",
    "population": 197953,
    "id": 1356082013
  },
  {
    "city": "Deltona",
    "city_ascii": "Deltona",
    "lat": 28.905,
    "lng": -81.2137,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 198361,
    "id": 1840015072
  },
  {
    "city": "Chilpancingo",
    "city_ascii": "Chilpancingo",
    "lat": 17.55,
    "lng": -99.5,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Guerrero",
    "capital": "admin",
    "population": 187251,
    "id": 1484290575
  },
  {
    "city": "Naga City",
    "city_ascii": "Naga City",
    "lat": 13.6167,
    "lng": 123.1667,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Naga",
    "capital": "admin",
    "population": 196003,
    "id": 1608775993
  },
  {
    "city": "Richmond",
    "city_ascii": "Richmond",
    "lat": 49.1667,
    "lng": -123.1333,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "British Columbia",
    "capital": "",
    "population": 198309,
    "id": 1124121940
  },
  {
    "city": "H̱olon",
    "city_ascii": "Holon",
    "lat": 32.0167,
    "lng": 34.7667,
    "country": "Israel",
    "iso2": "IL",
    "iso3": "ISR",
    "admin_name": "Tel Aviv",
    "capital": "",
    "population": 188834,
    "id": 1376222772
  },
  {
    "city": "Gemena",
    "city_ascii": "Gemena",
    "lat": 3.25,
    "lng": 19.7667,
    "country": "Congo (Kinshasa)",
    "iso2": "CD",
    "iso3": "COD",
    "admin_name": "Sud-Ubangi",
    "capital": "admin",
    "population": 198056,
    "id": 1180000163
  },
  {
    "city": "Tocuyito",
    "city_ascii": "Tocuyito",
    "lat": 10.0889,
    "lng": -68.0922,
    "country": "Venezuela",
    "iso2": "VE",
    "iso3": "VEN",
    "admin_name": "Carabobo",
    "capital": "minor",
    "population": 197446,
    "id": 1862659974
  },
  {
    "city": "Oradea",
    "city_ascii": "Oradea",
    "lat": 47.0722,
    "lng": 21.9211,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Bihor",
    "capital": "admin",
    "population": 196367,
    "id": 1642066626
  },
  {
    "city": "Legazpi City",
    "city_ascii": "Legazpi City",
    "lat": 13.1333,
    "lng": 123.7333,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Albay",
    "capital": "admin",
    "population": 196639,
    "id": 1608693683
  },
  {
    "city": "Hedong",
    "city_ascii": "Hedong",
    "lat": 42.5404,
    "lng": 129.0039,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Jilin",
    "capital": "",
    "population": 197885,
    "id": 1156223178
  },
  {
    "city": "Madhyamgram",
    "city_ascii": "Madhyamgram",
    "lat": 22.7,
    "lng": 88.45,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "West Bengal",
    "capital": "",
    "population": 196127,
    "id": 1356501701
  },
  {
    "city": "Aurora",
    "city_ascii": "Aurora",
    "lat": 41.7637,
    "lng": -88.2901,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 197757,
    "id": 1840007034
  },
  {
    "city": "Bhiwāni",
    "city_ascii": "Bhiwani",
    "lat": 28.7833,
    "lng": 76.1333,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Haryāna",
    "capital": "",
    "population": 197662,
    "id": 1356780092
  },
  {
    "city": "Burhānpur",
    "city_ascii": "Burhanpur",
    "lat": 21.3004,
    "lng": 76.13,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Madhya Pradesh",
    "capital": "",
    "population": 197233,
    "id": 1356857493
  },
  {
    "city": "Mbanza-Ngungu",
    "city_ascii": "Mbanza-Ngungu",
    "lat": -5.2496,
    "lng": 14.86,
    "country": "Congo (Kinshasa)",
    "iso2": "CD",
    "iso3": "COD",
    "admin_name": "Kongo Central",
    "capital": "",
    "population": 197545,
    "id": 1180474388
  },
  {
    "city": "Huánuco",
    "city_ascii": "Huanuco",
    "lat": -9.9294,
    "lng": -76.2397,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "Huánuco",
    "capital": "admin",
    "population": 196627,
    "id": 1604589102
  },
  {
    "city": "Prokopyevsk",
    "city_ascii": "Prokopyevsk",
    "lat": 53.8833,
    "lng": 86.7167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kemerovskaya Oblast’",
    "capital": "",
    "population": 196406,
    "id": 1643653477
  },
  {
    "city": "Rajin",
    "city_ascii": "Rajin",
    "lat": 42.3444,
    "lng": 130.3844,
    "country": "Korea, North",
    "iso2": "KP",
    "iso3": "PRK",
    "admin_name": "Nasŏn",
    "capital": "admin",
    "population": 196954,
    "id": 1408449973
  },
  {
    "city": "Ghāndīnagar",
    "city_ascii": "Ghandinagar",
    "lat": 23.22,
    "lng": 72.68,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Gujarāt",
    "capital": "admin",
    "population": 195891,
    "id": 1356140914
  },
  {
    "city": "Eldoret",
    "city_ascii": "Eldoret",
    "lat": 0.5167,
    "lng": 35.2833,
    "country": "Kenya",
    "iso2": "KE",
    "iso3": "KEN",
    "admin_name": "Uasin Gishu",
    "capital": "admin",
    "population": 193830,
    "id": 1404676289
  },
  {
    "city": "Tegucigalpita",
    "city_ascii": "Tegucigalpita",
    "lat": 15.78,
    "lng": -86.7878,
    "country": "Honduras",
    "iso2": "HN",
    "iso3": "HND",
    "admin_name": "Atlántida",
    "capital": "",
    "population": 196856,
    "id": 1340541864
  },
  {
    "city": "Hino",
    "city_ascii": "Hino",
    "lat": 35.6714,
    "lng": 139.395,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Tōkyō",
    "capital": "",
    "population": 189945,
    "id": 1392686410
  },
  {
    "city": "Kusŏng",
    "city_ascii": "Kusong",
    "lat": 39.9667,
    "lng": 125.1667,
    "country": "Korea, North",
    "iso2": "KP",
    "iso3": "PRK",
    "admin_name": "P’yŏngbuk",
    "capital": "",
    "population": 196515,
    "id": 1408227006
  },
  {
    "city": "Sūhāj",
    "city_ascii": "Suhaj",
    "lat": 26.5606,
    "lng": 31.6917,
    "country": "Egypt",
    "iso2": "EG",
    "iso3": "EGY",
    "admin_name": "Sūhāj",
    "capital": "admin",
    "population": 193931,
    "id": 1818460403
  },
  {
    "city": "Suzuka",
    "city_ascii": "Suzuka",
    "lat": 34.8822,
    "lng": 136.5842,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Mie",
    "capital": "",
    "population": 195762,
    "id": 1392913924
  },
  {
    "city": "Khammam",
    "city_ascii": "Khammam",
    "lat": 17.25,
    "lng": 80.15,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Telangana",
    "capital": "",
    "population": 196283,
    "id": 1356007170
  },
  {
    "city": "Kırıkkale",
    "city_ascii": "Kirikkale",
    "lat": 39.8417,
    "lng": 33.5139,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Kırıkkale",
    "capital": "admin",
    "population": 196645,
    "id": 1792859558
  },
  {
    "city": "Ar Raqqah",
    "city_ascii": "Ar Raqqah",
    "lat": 35.95,
    "lng": 39.0167,
    "country": "Syria",
    "iso2": "SY",
    "iso3": "SYR",
    "admin_name": "Ar Raqqah",
    "capital": "admin",
    "population": 196529,
    "id": 1760035911
  },
  {
    "city": "Kumagaya",
    "city_ascii": "Kumagaya",
    "lat": 36.1472,
    "lng": 139.3886,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Saitama",
    "capital": "",
    "population": 194959,
    "id": 1392500750
  },
  {
    "city": "Higashi-Hiroshima",
    "city_ascii": "Higashi-Hiroshima",
    "lat": 34.4167,
    "lng": 132.7333,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hiroshima",
    "capital": "",
    "population": 195805,
    "id": 1392464732
  },
  {
    "city": "Brikama",
    "city_ascii": "Brikama",
    "lat": 13.2804,
    "lng": -16.6599,
    "country": "Gambia, The",
    "iso2": "GM",
    "iso3": "GMB",
    "admin_name": "Western",
    "capital": "admin",
    "population": 195136,
    "id": 1270953861
  },
  {
    "city": "Acarigua",
    "city_ascii": "Acarigua",
    "lat": 9.5597,
    "lng": -69.2019,
    "country": "Venezuela",
    "iso2": "VE",
    "iso3": "VEN",
    "admin_name": "Portuguesa",
    "capital": "minor",
    "population": 195637,
    "id": 1862134462
  },
  {
    "city": "Hugli",
    "city_ascii": "Hugli",
    "lat": 22.9,
    "lng": 88.39,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "West Bengal",
    "capital": "",
    "population": 179931,
    "id": 1356109548
  },
  {
    "city": "Viranşehir",
    "city_ascii": "Viransehir",
    "lat": 37.2306,
    "lng": 39.7653,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Şanlıurfa",
    "capital": "minor",
    "population": 195910,
    "id": 1792566461
  },
  {
    "city": "San Luis Río Colorado",
    "city_ascii": "San Luis Rio Colorado",
    "lat": 32.4767,
    "lng": -114.7625,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Sonora",
    "capital": "minor",
    "population": 192739,
    "id": 1484573106
  },
  {
    "city": "Iskandar",
    "city_ascii": "Iskandar",
    "lat": 41.5507,
    "lng": 69.6807,
    "country": "Uzbekistan",
    "iso2": "UZ",
    "iso3": "UZB",
    "admin_name": "Toshkent",
    "capital": "",
    "population": 195633,
    "id": 1860285858
  },
  {
    "city": "Tempe",
    "city_ascii": "Tempe",
    "lat": 33.3881,
    "lng": -111.9318,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 195805,
    "id": 1840021942
  },
  {
    "city": "Naka",
    "city_ascii": "Naka",
    "lat": 37.4504,
    "lng": 138.86,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Niigata",
    "capital": "",
    "population": 195318,
    "id": 1392479949
  },
  {
    "city": "Kenema",
    "city_ascii": "Kenema",
    "lat": 7.8833,
    "lng": -11.1833,
    "country": "Sierra Leone",
    "iso2": "SL",
    "iso3": "SLE",
    "admin_name": "Eastern",
    "capital": "admin",
    "population": 188463,
    "id": 1694780903
  },
  {
    "city": "Overland Park",
    "city_ascii": "Overland Park",
    "lat": 38.887,
    "lng": -94.687,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kansas",
    "capital": "",
    "population": 195494,
    "id": 1840003834
  },
  {
    "city": "Bandar-e Būshehr",
    "city_ascii": "Bandar-e Bushehr",
    "lat": 28.9667,
    "lng": 50.8333,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Būshehr",
    "capital": "admin",
    "population": 195222,
    "id": 1364483819
  },
  {
    "city": "Yamaguchi",
    "city_ascii": "Yamaguchi",
    "lat": 34.1781,
    "lng": 131.4739,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Yamaguchi",
    "capital": "admin",
    "population": 194990,
    "id": 1392887215
  },
  {
    "city": "Parma",
    "city_ascii": "Parma",
    "lat": 44.8015,
    "lng": 10.328,
    "country": "Italy",
    "iso2": "IT",
    "iso3": "ITA",
    "admin_name": "Emilia-Romagna",
    "capital": "minor",
    "population": 194417,
    "id": 1380655938
  },
  {
    "city": "Anseong",
    "city_ascii": "Anseong",
    "lat": 37.0078,
    "lng": 127.2797,
    "country": "Korea, South",
    "iso2": "KR",
    "iso3": "KOR",
    "admin_name": "Gyeonggi",
    "capital": "",
    "population": 194765,
    "id": 1410960903
  },
  {
    "city": "Yuzhno-Sakhalinsk",
    "city_ascii": "Yuzhno-Sakhalinsk",
    "lat": 46.95,
    "lng": 142.7333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sakhalinskaya Oblast’",
    "capital": "admin",
    "population": 194882,
    "id": 1643703767
  },
  {
    "city": "Richmond Hill",
    "city_ascii": "Richmond Hill",
    "lat": 43.8667,
    "lng": -79.4333,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 195022,
    "id": 1124364273
  },
  {
    "city": "Desē",
    "city_ascii": "Dese",
    "lat": 11.1333,
    "lng": 39.6333,
    "country": "Ethiopia",
    "iso2": "ET",
    "iso3": "ETH",
    "admin_name": "Āmara",
    "capital": "",
    "population": 187900,
    "id": 1231359719
  },
  {
    "city": "Gorontalo",
    "city_ascii": "Gorontalo",
    "lat": 0.5411,
    "lng": 123.0594,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Gorontalo",
    "capital": "admin",
    "population": 191897,
    "id": 1360408580
  },
  {
    "city": "Cholula de Rivadabia",
    "city_ascii": "Cholula de Rivadabia",
    "lat": 19.0633,
    "lng": -98.3064,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Puebla",
    "capital": "",
    "population": 193554,
    "id": 1484237822
  },
  {
    "city": "Guacara",
    "city_ascii": "Guacara",
    "lat": 10.2261,
    "lng": -67.877,
    "country": "Venezuela",
    "iso2": "VE",
    "iso3": "VEN",
    "admin_name": "Carabobo",
    "capital": "minor",
    "population": 194009,
    "id": 1862573240
  },
  {
    "city": "Grand Prairie",
    "city_ascii": "Grand Prairie",
    "lat": 32.6872,
    "lng": -97.0209,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 194543,
    "id": 1840020709
  },
  {
    "city": "Quelimane",
    "city_ascii": "Quelimane",
    "lat": -17.8764,
    "lng": 36.8872,
    "country": "Mozambique",
    "iso2": "MZ",
    "iso3": "MOZ",
    "admin_name": "Zambézia",
    "capital": "admin",
    "population": 192876,
    "id": 1508396687
  },
  {
    "city": "Tébessa",
    "city_ascii": "Tebessa",
    "lat": 35.4,
    "lng": 8.1167,
    "country": "Algeria",
    "iso2": "DZ",
    "iso3": "DZA",
    "admin_name": "Tébessa",
    "capital": "admin",
    "population": 194461,
    "id": 1012196375
  },
  {
    "city": "San Fernando",
    "city_ascii": "San Fernando",
    "lat": 7.894,
    "lng": -67.473,
    "country": "Venezuela",
    "iso2": "VE",
    "iso3": "VEN",
    "admin_name": "Apure",
    "capital": "admin",
    "population": 194274,
    "id": 1862404343
  },
  {
    "city": "Al Jahrā’",
    "city_ascii": "Al Jahra'",
    "lat": 29.3375,
    "lng": 47.6581,
    "country": "Kuwait",
    "iso2": "KW",
    "iso3": "KWT",
    "admin_name": "Al Jahrā’",
    "capital": "",
    "population": 194193,
    "id": 1414182001
  },
  {
    "city": "Karaman",
    "city_ascii": "Karaman",
    "lat": 37.1833,
    "lng": 33.2167,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Karaman",
    "capital": "admin",
    "population": 194018,
    "id": 1792285496
  },
  {
    "city": "Cap-Haïtien",
    "city_ascii": "Cap-Haitien",
    "lat": 19.75,
    "lng": -72.2,
    "country": "Haiti",
    "iso2": "HT",
    "iso3": "HTI",
    "admin_name": "Nord",
    "capital": "admin",
    "population": 190289,
    "id": 1332333795
  },
  {
    "city": "Oakville",
    "city_ascii": "Oakville",
    "lat": 43.45,
    "lng": -79.6833,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 193832,
    "id": 1124080468
  },
  {
    "city": "Muar",
    "city_ascii": "Muar",
    "lat": 2.05,
    "lng": 102.56,
    "country": "Malaysia",
    "iso2": "MY",
    "iso3": "MYS",
    "admin_name": "Johor",
    "capital": "",
    "population": 191346,
    "id": 1458170112
  },
  {
    "city": "Prato",
    "city_ascii": "Prato",
    "lat": 43.8808,
    "lng": 11.0966,
    "country": "Italy",
    "iso2": "IT",
    "iso3": "ITA",
    "admin_name": "Tuscany",
    "capital": "minor",
    "population": 191749,
    "id": 1380751753
  },
  {
    "city": "Godoy Cruz",
    "city_ascii": "Godoy Cruz",
    "lat": -32.9167,
    "lng": -68.8333,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Mendoza",
    "capital": "minor",
    "population": 191299,
    "id": 1032263200
  },
  {
    "city": "Muş",
    "city_ascii": "Mus",
    "lat": 38.7333,
    "lng": 41.4911,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Muş",
    "capital": "admin",
    "population": 193394,
    "id": 1792530937
  },
  {
    "city": "Hyesan",
    "city_ascii": "Hyesan",
    "lat": 41.4,
    "lng": 128.1833,
    "country": "Korea, North",
    "iso2": "KP",
    "iso3": "PRK",
    "admin_name": "Yanggang",
    "capital": "minor",
    "population": 192680,
    "id": 1408642146
  },
  {
    "city": "Kielce",
    "city_ascii": "Kielce",
    "lat": 50.8725,
    "lng": 20.6319,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Świętokrzyskie",
    "capital": "admin",
    "population": 191605,
    "id": 1616176351
  },
  {
    "city": "Arapiraca",
    "city_ascii": "Arapiraca",
    "lat": -9.7525,
    "lng": -36.6611,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Alagoas",
    "capital": "",
    "population": 187668,
    "id": 1076615737
  },
  {
    "city": "Valencia",
    "city_ascii": "Valencia",
    "lat": 7.9,
    "lng": 125.0833,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Bukidnon",
    "capital": "",
    "population": 192993,
    "id": 1608418474
  },
  {
    "city": "Sunrise Manor",
    "city_ascii": "Sunrise Manor",
    "lat": 36.1785,
    "lng": -115.049,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Nevada",
    "capital": "",
    "population": 192934,
    "id": 1840033833
  },
  {
    "city": "Araçatuba",
    "city_ascii": "Aracatuba",
    "lat": -21.2089,
    "lng": -50.4328,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 192757,
    "id": 1076630308
  },
  {
    "city": "Swindon",
    "city_ascii": "Swindon",
    "lat": 51.56,
    "lng": -1.78,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Swindon",
    "capital": "",
    "population": 192599,
    "id": 1826498106
  },
  {
    "city": "Guanare",
    "city_ascii": "Guanare",
    "lat": 9.0436,
    "lng": -69.7489,
    "country": "Venezuela",
    "iso2": "VE",
    "iso3": "VEN",
    "admin_name": "Portuguesa",
    "capital": "admin",
    "population": 192644,
    "id": 1862645590
  },
  {
    "city": "Waco",
    "city_ascii": "Waco",
    "lat": 31.5598,
    "lng": -97.1881,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 192315,
    "id": 1840022140
  },
  {
    "city": "Basildon",
    "city_ascii": "Basildon",
    "lat": 51.5761,
    "lng": 0.4886,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Essex",
    "capital": "",
    "population": 185900,
    "id": 1826481004
  },
  {
    "city": "Madiun",
    "city_ascii": "Madiun",
    "lat": -7.63,
    "lng": 111.5231,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Jawa Timur",
    "capital": "",
    "population": 186099,
    "id": 1360921516
  },
  {
    "city": "Kawara",
    "city_ascii": "Kawara",
    "lat": 35.2647,
    "lng": 139.1522,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Kanagawa",
    "capital": "",
    "population": 190109,
    "id": 1392427702
  },
  {
    "city": "Gibraltar",
    "city_ascii": "Gibraltar",
    "lat": 36.1324,
    "lng": -5.3781,
    "country": "Gibraltar",
    "iso2": "GI",
    "iso3": "GIB",
    "admin_name": "",
    "capital": "",
    "population": 187083,
    "id": 1292385245
  },
  {
    "city": "Valera",
    "city_ascii": "Valera",
    "lat": 9.32,
    "lng": -70.62,
    "country": "Venezuela",
    "iso2": "VE",
    "iso3": "VEN",
    "admin_name": "Trujillo",
    "capital": "minor",
    "population": 191167,
    "id": 1862735261
  },
  {
    "city": "Barrancabermeja",
    "city_ascii": "Barrancabermeja",
    "lat": 7.09,
    "lng": -73.85,
    "country": "Colombia",
    "iso2": "CO",
    "iso3": "COL",
    "admin_name": "Santander",
    "capital": "minor",
    "population": 191403,
    "id": 1170362340
  },
  {
    "city": "Mahbūbnagar",
    "city_ascii": "Mahbubnagar",
    "lat": 16.7333,
    "lng": 77.9833,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Telangana",
    "capital": "",
    "population": 190400,
    "id": 1356014528
  },
  {
    "city": "Pasuruan",
    "city_ascii": "Pasuruan",
    "lat": -7.6406,
    "lng": 112.9065,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Jawa Timur",
    "capital": "",
    "population": 186262,
    "id": 1360084806
  },
  {
    "city": "Aydın",
    "city_ascii": "Aydin",
    "lat": 37.8481,
    "lng": 27.8453,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Aydın",
    "capital": "admin",
    "population": 191037,
    "id": 1792696058
  },
  {
    "city": "Shibīn al Kawm",
    "city_ascii": "Shibin al Kawm",
    "lat": 30.592,
    "lng": 30.9,
    "country": "Egypt",
    "iso2": "EG",
    "iso3": "EGY",
    "admin_name": "Al Minūfīyah",
    "capital": "admin",
    "population": 182900,
    "id": 1818787044
  },
  {
    "city": "Armavir",
    "city_ascii": "Armavir",
    "lat": 45,
    "lng": 41.1167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Krasnodarskiy Kray",
    "capital": "",
    "population": 190709,
    "id": 1643809754
  },
  {
    "city": "Balakovo",
    "city_ascii": "Balakovo",
    "lat": 52.039,
    "lng": 47.7839,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Saratovskaya Oblast’",
    "capital": "",
    "population": 191260,
    "id": 1643480033
  },
  {
    "city": "Rio Grande",
    "city_ascii": "Rio Grande",
    "lat": -32.0495,
    "lng": -52.12,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Rio Grande do Sul",
    "capital": "",
    "population": 187838,
    "id": 1076567920
  },
  {
    "city": "Batu",
    "city_ascii": "Batu",
    "lat": -7.8672,
    "lng": 112.5239,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Jawa Timur",
    "capital": "",
    "population": 190184,
    "id": 1360427259
  },
  {
    "city": "Salinas",
    "city_ascii": "Salinas",
    "lat": 36.6884,
    "lng": -121.6317,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 190972,
    "id": 1840021632
  },
  {
    "city": "Mwene-Ditu",
    "city_ascii": "Mwene-Ditu",
    "lat": -7,
    "lng": 23.45,
    "country": "Congo (Kinshasa)",
    "iso2": "CD",
    "iso3": "COD",
    "admin_name": "Lomami",
    "capital": "",
    "population": 189177,
    "id": 1180134137
  },
  {
    "city": "Sơn Tây",
    "city_ascii": "Son Tay",
    "lat": 21.1382,
    "lng": 105.505,
    "country": "Vietnam",
    "iso2": "VN",
    "iso3": "VNM",
    "admin_name": "Hà Nội",
    "capital": "minor",
    "population": 189547,
    "id": 1704714941
  },
  {
    "city": "Ferraz de Vasconcelos",
    "city_ascii": "Ferraz de Vasconcelos",
    "lat": -23.5411,
    "lng": -46.3689,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 184700,
    "id": 1076173995
  },
  {
    "city": "Santa Bárbara d’Oeste",
    "city_ascii": "Santa Barbara d'Oeste",
    "lat": -22.7539,
    "lng": -47.4139,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 190139,
    "id": 1076199404
  },
  {
    "city": "Oviedo",
    "city_ascii": "Oviedo",
    "lat": 43.3634,
    "lng": -5.8423,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Asturias",
    "capital": "admin",
    "population": 189434,
    "id": 1724090743
  },
  {
    "city": "Saddiqabad",
    "city_ascii": "Saddiqabad",
    "lat": 28.3006,
    "lng": 70.1302,
    "country": "Pakistan",
    "iso2": "PK",
    "iso3": "PAK",
    "admin_name": "Punjab",
    "capital": "",
    "population": 189876,
    "id": 1586378758
  },
  {
    "city": "Rybinsk",
    "city_ascii": "Rybinsk",
    "lat": 58.05,
    "lng": 38.8333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Yaroslavskaya Oblast’",
    "capital": "",
    "population": 190429,
    "id": 1643113701
  },
  {
    "city": "Anjōmachi",
    "city_ascii": "Anjomachi",
    "lat": 34.9667,
    "lng": 137.0833,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Aichi",
    "capital": "",
    "population": 188181,
    "id": 1392081830
  },
  {
    "city": "Chŏngju",
    "city_ascii": "Chongju",
    "lat": 39.65,
    "lng": 125.3333,
    "country": "Korea, North",
    "iso2": "KP",
    "iso3": "PRK",
    "admin_name": "P’yŏngbuk",
    "capital": "",
    "population": 189742,
    "id": 1408650871
  },
  {
    "city": "Cachoeiro de Itapemirim",
    "city_ascii": "Cachoeiro de Itapemirim",
    "lat": -20.8489,
    "lng": -41.1128,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Espírito Santo",
    "capital": "",
    "population": 189889,
    "id": 1076536175
  },
  {
    "city": "Jōetsu",
    "city_ascii": "Joetsu",
    "lat": 37.15,
    "lng": 138.2333,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Niigata",
    "capital": "",
    "population": 189881,
    "id": 1392040772
  },
  {
    "city": "Saint-Denis",
    "city_ascii": "Saint-Denis",
    "lat": -20.8789,
    "lng": 55.4481,
    "country": "Reunion",
    "iso2": "RE",
    "iso3": "REU",
    "admin_name": "",
    "capital": "admin",
    "population": 190047,
    "id": 1638024662
  },
  {
    "city": "Hagen",
    "city_ascii": "Hagen",
    "lat": 51.3594,
    "lng": 7.475,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "minor",
    "population": 188814,
    "id": 1276389303
  },
  {
    "city": "Ngaoundéré",
    "city_ascii": "Ngaoundere",
    "lat": 7.3214,
    "lng": 13.5839,
    "country": "Cameroon",
    "iso2": "CM",
    "iso3": "CMR",
    "admin_name": "Adamaoua",
    "capital": "admin",
    "population": 189800,
    "id": 1120011020
  },
  {
    "city": "Potosí",
    "city_ascii": "Potosi",
    "lat": -19.5833,
    "lng": -65.75,
    "country": "Bolivia",
    "iso2": "BO",
    "iso3": "BOL",
    "admin_name": "Potosí",
    "capital": "admin",
    "population": 189652,
    "id": 1068933987
  },
  {
    "city": "San Carlos City",
    "city_ascii": "San Carlos City",
    "lat": 15.9281,
    "lng": 120.3489,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Pangasinan",
    "capital": "",
    "population": 188571,
    "id": 1608542323
  },
  {
    "city": "Pak Kret",
    "city_ascii": "Pak Kret",
    "lat": 13.9125,
    "lng": 100.4978,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Nonthaburi",
    "capital": "minor",
    "population": 184501,
    "id": 1764165203
  },
  {
    "city": "Waterbury",
    "city_ascii": "Waterbury",
    "lat": 41.5583,
    "lng": -73.0361,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 189614,
    "id": 1840004851
  },
  {
    "city": "Donghua",
    "city_ascii": "Donghua",
    "lat": 35.2175,
    "lng": 106.6545,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Gansu",
    "capital": "minor",
    "population": 189333,
    "id": 1156975773
  },
  {
    "city": "Parakou",
    "city_ascii": "Parakou",
    "lat": 9.34,
    "lng": 2.62,
    "country": "Benin",
    "iso2": "BJ",
    "iso3": "BEN",
    "admin_name": "Borgou",
    "capital": "admin",
    "population": 188853,
    "id": 1204807468
  },
  {
    "city": "Mutare",
    "city_ascii": "Mutare",
    "lat": -18.9728,
    "lng": 32.6694,
    "country": "Zimbabwe",
    "iso2": "ZW",
    "iso3": "ZWE",
    "admin_name": "Manicaland",
    "capital": "admin",
    "population": 188243,
    "id": 1716060405
  },
  {
    "city": "Kōfu",
    "city_ascii": "Kofu",
    "lat": 35.6667,
    "lng": 138.5667,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Yamanashi",
    "capital": "admin",
    "population": 188406,
    "id": 1392307565
  },
  {
    "city": "Mataró",
    "city_ascii": "Mataro",
    "lat": 41.54,
    "lng": 2.45,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Catalonia",
    "capital": "",
    "population": 183293,
    "id": 1724901780
  },
  {
    "city": "Narsingdi",
    "city_ascii": "Narsingdi",
    "lat": 23.9,
    "lng": 90.7167,
    "country": "Bangladesh",
    "iso2": "BD",
    "iso3": "BGD",
    "admin_name": "Dhaka",
    "capital": "",
    "population": 185128,
    "id": 1050904616
  },
  {
    "city": "Araure",
    "city_ascii": "Araure",
    "lat": 9.5667,
    "lng": -69.2167,
    "country": "Venezuela",
    "iso2": "VE",
    "iso3": "VEN",
    "admin_name": "Portuguesa",
    "capital": "minor",
    "population": 188800,
    "id": 1862226710
  },
  {
    "city": "Tottori",
    "city_ascii": "Tottori",
    "lat": 35.5011,
    "lng": 134.235,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Tottori",
    "capital": "",
    "population": 188740,
    "id": 1392416969
  },
  {
    "city": "Pinar del Río",
    "city_ascii": "Pinar del Rio",
    "lat": 22.4122,
    "lng": -83.6719,
    "country": "Cuba",
    "iso2": "CU",
    "iso3": "CUB",
    "admin_name": "Pinar del Río",
    "capital": "admin",
    "population": 188614,
    "id": 1192216820
  },
  {
    "city": "Clarksville",
    "city_ascii": "Clarksville",
    "lat": 36.5695,
    "lng": -87.342,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 188746,
    "id": 1840014421
  },
  {
    "city": "Rāiganj",
    "city_ascii": "Raiganj",
    "lat": 25.6167,
    "lng": 88.1167,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "West Bengal",
    "capital": "",
    "population": 183612,
    "id": 1356159126
  },
  {
    "city": "Prabumulih",
    "city_ascii": "Prabumulih",
    "lat": -3.4328,
    "lng": 104.2356,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Sumatera Selatan",
    "capital": "minor",
    "population": 188082,
    "id": 1360148993
  },
  {
    "city": "Tachikawa",
    "city_ascii": "Tachikawa",
    "lat": 35.6939,
    "lng": 139.4194,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Tōkyō",
    "capital": "",
    "population": 180880,
    "id": 1392042507
  },
  {
    "city": "Al Ḩasakah",
    "city_ascii": "Al Hasakah",
    "lat": 36.5117,
    "lng": 40.7422,
    "country": "Syria",
    "iso2": "SY",
    "iso3": "SYR",
    "admin_name": "Al Ḩasakah",
    "capital": "admin",
    "population": 188160,
    "id": 1760476337
  },
  {
    "city": "Turku",
    "city_ascii": "Turku",
    "lat": 60.4517,
    "lng": 22.27,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Varsinais-Suomi",
    "capital": "admin",
    "population": 187604,
    "id": 1246456609
  },
  {
    "city": "Guatire",
    "city_ascii": "Guatire",
    "lat": 10.4717,
    "lng": -66.5406,
    "country": "Venezuela",
    "iso2": "VE",
    "iso3": "VEN",
    "admin_name": "Miranda",
    "capital": "minor",
    "population": 187262,
    "id": 1862199442
  },
  {
    "city": "Iquique",
    "city_ascii": "Iquique",
    "lat": -20.2141,
    "lng": -70.1524,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Tarapacá",
    "capital": "admin",
    "population": 188003,
    "id": 1152234625
  },
  {
    "city": "Cedar Rapids",
    "city_ascii": "Cedar Rapids",
    "lat": 41.9665,
    "lng": -91.6782,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Iowa",
    "capital": "",
    "population": 188038,
    "id": 1840000471
  },
  {
    "city": "San Diego",
    "city_ascii": "San Diego",
    "lat": 10.2558,
    "lng": -67.9539,
    "country": "Venezuela",
    "iso2": "VE",
    "iso3": "VEN",
    "admin_name": "Carabobo",
    "capital": "minor",
    "population": 187215,
    "id": 1862530883
  },
  {
    "city": "Gharyān",
    "city_ascii": "Gharyan",
    "lat": 32.1669,
    "lng": 13.0167,
    "country": "Libya",
    "iso2": "LY",
    "iso3": "LBY",
    "admin_name": "Al Jabal al Gharbī",
    "capital": "admin",
    "population": 187854,
    "id": 1434507846
  },
  {
    "city": "Bournemouth",
    "city_ascii": "Bournemouth",
    "lat": 50.72,
    "lng": -1.88,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Bournemouth",
    "capital": "",
    "population": 183491,
    "id": 1826769743
  },
  {
    "city": "Chaedŏk",
    "city_ascii": "Chaedok",
    "lat": 40.6723,
    "lng": 129.2027,
    "country": "Korea, North",
    "iso2": "KP",
    "iso3": "PRK",
    "admin_name": "Hambuk",
    "capital": "",
    "population": 187270,
    "id": 1408001232
  },
  {
    "city": "Izuo",
    "city_ascii": "Izuo",
    "lat": 34.4836,
    "lng": 135.4236,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Ōsaka",
    "capital": "",
    "population": 185208,
    "id": 1392291253
  },
  {
    "city": "La Plata",
    "city_ascii": "La Plata",
    "lat": -34.9333,
    "lng": -57.95,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires",
    "capital": "admin",
    "population": 186527,
    "id": 1032403831
  },
  {
    "city": "Prizren",
    "city_ascii": "Prizren",
    "lat": 42.2167,
    "lng": 20.7333,
    "country": "Kosovo",
    "iso2": "XK",
    "iso3": "XKS",
    "admin_name": "Prizren",
    "capital": "admin",
    "population": 186986,
    "id": 1901360309
  },
  {
    "city": "Sioux Falls",
    "city_ascii": "Sioux Falls",
    "lat": 43.5397,
    "lng": -96.732,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Dakota",
    "capital": "",
    "population": 187239,
    "id": 1840002648
  },
  {
    "city": "Mainz",
    "city_ascii": "Mainz",
    "lat": 50,
    "lng": 8.2711,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Rhineland-Palatinate",
    "capital": "admin",
    "population": 184997,
    "id": 1276801317
  },
  {
    "city": "Cabo Frio",
    "city_ascii": "Cabo Frio",
    "lat": -22.8789,
    "lng": -42.0189,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Rio de Janeiro",
    "capital": "",
    "population": 186227,
    "id": 1076449553
  },
  {
    "city": "Shendi",
    "city_ascii": "Shendi",
    "lat": 16.6805,
    "lng": 33.42,
    "country": "Sudan",
    "iso2": "SD",
    "iso3": "SDN",
    "admin_name": "River Nile",
    "capital": "",
    "population": 186611,
    "id": 1729244380
  },
  {
    "city": "Reims",
    "city_ascii": "Reims",
    "lat": 49.2628,
    "lng": 4.0347,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Grand Est",
    "capital": "minor",
    "population": 182460,
    "id": 1250608730
  },
  {
    "city": "Ed Damazin",
    "city_ascii": "Ed Damazin",
    "lat": 11.7704,
    "lng": 34.35,
    "country": "Sudan",
    "iso2": "SD",
    "iso3": "SDN",
    "admin_name": "Blue Nile",
    "capital": "admin",
    "population": 186051,
    "id": 1729684147
  },
  {
    "city": "Khŭjand",
    "city_ascii": "Khujand",
    "lat": 40.2833,
    "lng": 69.6167,
    "country": "Tajikistan",
    "iso2": "TJ",
    "iso3": "TJK",
    "admin_name": "Sughd",
    "capital": "admin",
    "population": 181600,
    "id": 1762372787
  },
  {
    "city": "Huntington",
    "city_ascii": "Huntington",
    "lat": 38.4109,
    "lng": -82.4344,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "West Virginia",
    "capital": "",
    "population": 186034,
    "id": 1840006211
  },
  {
    "city": "Dunhuang",
    "city_ascii": "Dunhuang",
    "lat": 40.1411,
    "lng": 94.6616,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Gansu",
    "capital": "",
    "population": 186027,
    "id": 1156068377
  },
  {
    "city": "Mahesāna",
    "city_ascii": "Mahesana",
    "lat": 23.6,
    "lng": 72.4,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Gujarāt",
    "capital": "",
    "population": 184991,
    "id": 1356719082
  },
  {
    "city": "Modena",
    "city_ascii": "Modena",
    "lat": 44.6458,
    "lng": 10.9257,
    "country": "Italy",
    "iso2": "IT",
    "iso3": "ITA",
    "admin_name": "Emilia-Romagna",
    "capital": "minor",
    "population": 184727,
    "id": 1380306270
  },
  {
    "city": "Guri",
    "city_ascii": "Guri",
    "lat": 37.5947,
    "lng": 127.1428,
    "country": "Korea, South",
    "iso2": "KR",
    "iso3": "KOR",
    "admin_name": "Gyeonggi",
    "capital": "",
    "population": 180063,
    "id": 1410478813
  },
  {
    "city": "Jamaame",
    "city_ascii": "Jamaame",
    "lat": 0.0722,
    "lng": 42.7506,
    "country": "Somalia",
    "iso2": "SO",
    "iso3": "SOM",
    "admin_name": "Jubbada Hoose",
    "capital": "",
    "population": 185270,
    "id": 1706288768
  },
  {
    "city": "Panabo",
    "city_ascii": "Panabo",
    "lat": 7.3,
    "lng": 125.6833,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Davao del Norte",
    "capital": "",
    "population": 184599,
    "id": 1608577408
  },
  {
    "city": "Ādoni",
    "city_ascii": "Adoni",
    "lat": 15.63,
    "lng": 77.28,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Andhra Pradesh",
    "capital": "",
    "population": 184625,
    "id": 1356357749
  },
  {
    "city": "Temirtaū",
    "city_ascii": "Temirtau",
    "lat": 50.0667,
    "lng": 72.9667,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Qaraghandy",
    "capital": "",
    "population": 185082,
    "id": 1398081646
  },
  {
    "city": "Basel",
    "city_ascii": "Basel",
    "lat": 47.5606,
    "lng": 7.5906,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Basel-Stadt",
    "capital": "admin",
    "population": 177595,
    "id": 1756731313
  },
  {
    "city": "Yei",
    "city_ascii": "Yei",
    "lat": 4.0904,
    "lng": 30.68,
    "country": "South Sudan",
    "iso2": "SS",
    "iso3": "SSD",
    "admin_name": "Central Equatoria",
    "capital": "",
    "population": 185000,
    "id": 1728607274
  },
  {
    "city": "Santo Agostinho",
    "city_ascii": "Santo Agostinho",
    "lat": -8.2869,
    "lng": -35.035,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Pernambuco",
    "capital": "",
    "population": 185025,
    "id": 1076363089
  },
  {
    "city": "Ontario",
    "city_ascii": "Ontario",
    "lat": 34.0393,
    "lng": -117.6064,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 185010,
    "id": 1840020410
  },
  {
    "city": "Toyokawa",
    "city_ascii": "Toyokawa",
    "lat": 34.8268,
    "lng": 137.3759,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Aichi",
    "capital": "",
    "population": 183823,
    "id": 1392297078
  },
  {
    "city": "Luzhang",
    "city_ascii": "Luzhang",
    "lat": 25.8519,
    "lng": 98.8562,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Yunnan",
    "capital": "minor",
    "population": 184835,
    "id": 1156305822
  },
  {
    "city": "Hagerstown",
    "city_ascii": "Hagerstown",
    "lat": 39.6401,
    "lng": -77.7217,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 184755,
    "id": 1840005613
  },
  {
    "city": "Al Qāmishlī",
    "city_ascii": "Al Qamishli",
    "lat": 37.05,
    "lng": 41.2167,
    "country": "Syria",
    "iso2": "SY",
    "iso3": "SYR",
    "admin_name": "Al Ḩasakah",
    "capital": "minor",
    "population": 184231,
    "id": 1760560461
  },
  {
    "city": "Manzanillo",
    "city_ascii": "Manzanillo",
    "lat": 19.0522,
    "lng": -104.3158,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Colima",
    "capital": "minor",
    "population": 184541,
    "id": 1484223913
  },
  {
    "city": "Erie",
    "city_ascii": "Erie",
    "lat": 42.1168,
    "lng": -80.0733,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 184484,
    "id": 1840000478
  },
  {
    "city": "Vancouver",
    "city_ascii": "Vancouver",
    "lat": 45.6366,
    "lng": -122.5967,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 184463,
    "id": 1840021189
  },
  {
    "city": "Nampa",
    "city_ascii": "Nampa",
    "lat": 43.5845,
    "lng": -116.5631,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Idaho",
    "capital": "",
    "population": 184428,
    "id": 1840020041
  },
  {
    "city": "Fardīs",
    "city_ascii": "Fardis",
    "lat": 35.7247,
    "lng": 50.9883,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Tehrān",
    "capital": "",
    "population": 181174,
    "id": 1364967800
  },
  {
    "city": "Fianarantsoa",
    "city_ascii": "Fianarantsoa",
    "lat": -21.4333,
    "lng": 47.0833,
    "country": "Madagascar",
    "iso2": "MG",
    "iso3": "MDG",
    "admin_name": "Fianarantsoa",
    "capital": "admin",
    "population": 184184,
    "id": 1450070223
  },
  {
    "city": "Banjar",
    "city_ascii": "Banjar",
    "lat": -7.3667,
    "lng": 108.5333,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Jawa Barat",
    "capital": "minor",
    "population": 182819,
    "id": 1360289512
  },
  {
    "city": "Severodvinsk",
    "city_ascii": "Severodvinsk",
    "lat": 64.5667,
    "lng": 39.85,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Arkhangel’skaya Oblast’",
    "capital": "",
    "population": 183996,
    "id": 1643427671
  },
  {
    "city": "Rzeszów",
    "city_ascii": "Rzeszow",
    "lat": 50.05,
    "lng": 22,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Podkarpackie",
    "capital": "admin",
    "population": 182548,
    "id": 1616879570
  },
  {
    "city": "Calbayog City",
    "city_ascii": "Calbayog City",
    "lat": 12.0667,
    "lng": 124.6,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Samar",
    "capital": "",
    "population": 183851,
    "id": 1608088739
  },
  {
    "city": "Bhusāval",
    "city_ascii": "Bhusaval",
    "lat": 21.02,
    "lng": 75.83,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Mahārāshtra",
    "capital": "",
    "population": 183001,
    "id": 1356338789
  },
  {
    "city": "Trondheim",
    "city_ascii": "Trondheim",
    "lat": 63.44,
    "lng": 10.4,
    "country": "Norway",
    "iso2": "NO",
    "iso3": "NOR",
    "admin_name": "Sør-Trøndelag",
    "capital": "minor",
    "population": 183378,
    "id": 1578633461
  },
  {
    "city": "Uji",
    "city_ascii": "Uji",
    "lat": 34.8808,
    "lng": 135.7794,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Kyōto",
    "capital": "",
    "population": 180975,
    "id": 1392148678
  },
  {
    "city": "Passo Fundo",
    "city_ascii": "Passo Fundo",
    "lat": -28.25,
    "lng": -52.42,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Rio Grande do Sul",
    "capital": "",
    "population": 179529,
    "id": 1076763340
  },
  {
    "city": "Aqtaū",
    "city_ascii": "Aqtau",
    "lat": 43.65,
    "lng": 51.15,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Mangghystaū",
    "capital": "admin",
    "population": 183350,
    "id": 1398046759
  },
  {
    "city": "Reggio di Calabria",
    "city_ascii": "Reggio di Calabria",
    "lat": 38.1144,
    "lng": 15.65,
    "country": "Italy",
    "iso2": "IT",
    "iso3": "ITA",
    "admin_name": "Calabria",
    "capital": "minor",
    "population": 182703,
    "id": 1380630212
  },
  {
    "city": "Niš",
    "city_ascii": "Nis",
    "lat": 43.3192,
    "lng": 21.8961,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Niš",
    "capital": "admin",
    "population": 183164,
    "id": 1688661068
  },
  {
    "city": "Abakan",
    "city_ascii": "Abakan",
    "lat": 53.7167,
    "lng": 91.4167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Khakasiya",
    "capital": "admin",
    "population": 181709,
    "id": 1643946203
  },
  {
    "city": "Burlington",
    "city_ascii": "Burlington",
    "lat": 43.3167,
    "lng": -79.8,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 183314,
    "id": 1124955083
  },
  {
    "city": "Bahraigh",
    "city_ascii": "Bahraigh",
    "lat": 27.6204,
    "lng": 81.6699,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Uttar Pradesh",
    "capital": "",
    "population": 182218,
    "id": 1356833304
  },
  {
    "city": "Bo",
    "city_ascii": "Bo",
    "lat": 7.9564,
    "lng": -11.74,
    "country": "Sierra Leone",
    "iso2": "SL",
    "iso3": "SLE",
    "admin_name": "Southern",
    "capital": "admin",
    "population": 174354,
    "id": 1694651111
  },
  {
    "city": "Worthing",
    "city_ascii": "Worthing",
    "lat": 50.8147,
    "lng": -0.3714,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "West Sussex",
    "capital": "",
    "population": 183000,
    "id": 1826936965
  },
  {
    "city": "Pinrang",
    "city_ascii": "Pinrang",
    "lat": -3.7857,
    "lng": 119.6522,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Sulawesi Selatan",
    "capital": "minor",
    "population": 182731,
    "id": 1360941841
  },
  {
    "city": "Spartanburg",
    "city_ascii": "Spartanburg",
    "lat": 34.9437,
    "lng": -81.9257,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 182701,
    "id": 1840015482
  },
  {
    "city": "Brăila",
    "city_ascii": "Braila",
    "lat": 45.2692,
    "lng": 27.9575,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Brăila",
    "capital": "admin",
    "population": 180302,
    "id": 1642286806
  },
  {
    "city": "Nova Friburgo",
    "city_ascii": "Nova Friburgo",
    "lat": -22.26,
    "lng": -42.54,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Rio de Janeiro",
    "capital": "",
    "population": 171991,
    "id": 1076922983
  },
  {
    "city": "Gastonia",
    "city_ascii": "Gastonia",
    "lat": 35.2494,
    "lng": -81.1853,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 182581,
    "id": 1840013468
  },
  {
    "city": "Amadora",
    "city_ascii": "Amadora",
    "lat": 38.75,
    "lng": -9.2333,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Lisboa",
    "capital": "minor",
    "population": 175136,
    "id": 1620896557
  },
  {
    "city": "Braga",
    "city_ascii": "Braga",
    "lat": 41.5333,
    "lng": -8.4167,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Braga",
    "capital": "admin",
    "population": 181494,
    "id": 1620949401
  },
  {
    "city": "Fort Lauderdale",
    "city_ascii": "Fort Lauderdale",
    "lat": 26.1412,
    "lng": -80.1464,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 182437,
    "id": 1840014236
  },
  {
    "city": "Kabankalan",
    "city_ascii": "Kabankalan",
    "lat": 9.9833,
    "lng": 122.8167,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Negros Occidental",
    "capital": "",
    "population": 181977,
    "id": 1608697923
  },
  {
    "city": "Tonk",
    "city_ascii": "Tonk",
    "lat": 26.1505,
    "lng": 75.79,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Rājasthān",
    "capital": "",
    "population": 181734,
    "id": 1356991408
  },
  {
    "city": "Berazategui",
    "city_ascii": "Berazategui",
    "lat": -34.7679,
    "lng": -58.2133,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires",
    "capital": "minor",
    "population": 180523,
    "id": 1032535314
  },
  {
    "city": "Khowy",
    "city_ascii": "Khowy",
    "lat": 38.5503,
    "lng": 44.9519,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Āz̄arbāyjān-e Gharbī",
    "capital": "minor",
    "population": 182000,
    "id": 1364186788
  },
  {
    "city": "Sirsa",
    "city_ascii": "Sirsa",
    "lat": 29.4904,
    "lng": 75.03,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Haryāna",
    "capital": "",
    "population": 181639,
    "id": 1356966764
  },
  {
    "city": "Narashino",
    "city_ascii": "Narashino",
    "lat": 35.6808,
    "lng": 140.0267,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Chiba",
    "capital": "",
    "population": 173716,
    "id": 1392933042
  },
  {
    "city": "Tāngāil",
    "city_ascii": "Tangail",
    "lat": 24.25,
    "lng": 89.92,
    "country": "Bangladesh",
    "iso2": "BD",
    "iso3": "BGD",
    "admin_name": "Dhaka",
    "capital": "",
    "population": 180144,
    "id": 1050628940
  },
  {
    "city": "Jaunpur",
    "city_ascii": "Jaunpur",
    "lat": 25.7333,
    "lng": 82.6833,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Uttar Pradesh",
    "capital": "",
    "population": 180362,
    "id": 1356646586
  },
  {
    "city": "Saarbrücken",
    "city_ascii": "Saarbrucken",
    "lat": 49.2333,
    "lng": 7,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saarland",
    "capital": "admin",
    "population": 180741,
    "id": 1276419711
  },
  {
    "city": "Santo Tomas",
    "city_ascii": "Santo Tomas",
    "lat": 14.0833,
    "lng": 121.1833,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Batangas",
    "capital": "",
    "population": 179844,
    "id": 1608845898
  },
  {
    "city": "Petropavlovsk-Kamchatskiy",
    "city_ascii": "Petropavlovsk-Kamchatskiy",
    "lat": 53.0167,
    "lng": 158.65,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kamchatskiy Kray",
    "capital": "admin",
    "population": 181216,
    "id": 1643129247
  },
  {
    "city": "Sittwe",
    "city_ascii": "Sittwe",
    "lat": 20.1444,
    "lng": 92.8969,
    "country": "Burma",
    "iso2": "MM",
    "iso3": "MMR",
    "admin_name": "Rakhine State",
    "capital": "admin",
    "population": 181000,
    "id": 1104269305
  },
  {
    "city": "Phan Rang-Tháp Chàm",
    "city_ascii": "Phan Rang-Thap Cham",
    "lat": 11.5643,
    "lng": 108.9886,
    "country": "Vietnam",
    "iso2": "VN",
    "iso3": "VNM",
    "admin_name": "Ninh Thuận",
    "capital": "admin",
    "population": 179773,
    "id": 1704094609
  },
  {
    "city": "Jīma",
    "city_ascii": "Jima",
    "lat": 7.6667,
    "lng": 36.8333,
    "country": "Ethiopia",
    "iso2": "ET",
    "iso3": "ETH",
    "admin_name": "Oromīya",
    "capital": "",
    "population": 177900,
    "id": 1231214366
  },
  {
    "city": "Townsville",
    "city_ascii": "Townsville",
    "lat": -19.2564,
    "lng": 146.8183,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Queensland",
    "capital": "",
    "population": 180820,
    "id": 1036500020
  },
  {
    "city": "Lhokseumawe",
    "city_ascii": "Lhokseumawe",
    "lat": 5.18,
    "lng": 97.1506,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Aceh",
    "capital": "",
    "population": 180200,
    "id": 1360418625
  },
  {
    "city": "Gliwice",
    "city_ascii": "Gliwice",
    "lat": 50.2976,
    "lng": 18.6766,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Śląskie",
    "capital": "minor",
    "population": 179806,
    "id": 1616311376
  },
  {
    "city": "Funtua",
    "city_ascii": "Funtua",
    "lat": 11.5204,
    "lng": 7.32,
    "country": "Nigeria",
    "iso2": "NG",
    "iso3": "NGA",
    "admin_name": "Katsina",
    "capital": "minor",
    "population": 180475,
    "id": 1566877863
  },
  {
    "city": "Hinthada",
    "city_ascii": "Hinthada",
    "lat": 17.6483,
    "lng": 95.4679,
    "country": "Burma",
    "iso2": "MM",
    "iso3": "MMR",
    "admin_name": "Ayeyawady",
    "capital": "",
    "population": 180728,
    "id": 1104524588
  },
  {
    "city": "La Rioja",
    "city_ascii": "La Rioja",
    "lat": -29.4131,
    "lng": -66.8558,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "La Rioja",
    "capital": "admin",
    "population": 180995,
    "id": 1032864257
  },
  {
    "city": "Çanakkale",
    "city_ascii": "Canakkale",
    "lat": 40.15,
    "lng": 26.4,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Çanakkale",
    "capital": "admin",
    "population": 180823,
    "id": 1792403093
  },
  {
    "city": "Madanapalle",
    "city_ascii": "Madanapalle",
    "lat": 13.55,
    "lng": 78.5,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Andhra Pradesh",
    "capital": "",
    "population": 180180,
    "id": 1356232508
  },
  {
    "city": "Palopo",
    "city_ascii": "Palopo",
    "lat": -3,
    "lng": 120.2,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Sulawesi Selatan",
    "capital": "",
    "population": 180130,
    "id": 1360740288
  },
  {
    "city": "Ayacucho",
    "city_ascii": "Ayacucho",
    "lat": -13.1631,
    "lng": -74.2244,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "Ayacucho",
    "capital": "admin",
    "population": 180766,
    "id": 1604300430
  },
  {
    "city": "Tunja",
    "city_ascii": "Tunja",
    "lat": 5.5403,
    "lng": -73.3614,
    "country": "Colombia",
    "iso2": "CO",
    "iso3": "COL",
    "admin_name": "Boyacá",
    "capital": "admin",
    "population": 179263,
    "id": 1170843027
  },
  {
    "city": "Obuase",
    "city_ascii": "Obuase",
    "lat": 6.2,
    "lng": -1.6833,
    "country": "Ghana",
    "iso2": "GH",
    "iso3": "GHA",
    "admin_name": "Ashanti",
    "capital": "",
    "population": 180460,
    "id": 1288615192
  },
  {
    "city": "Alleppey",
    "city_ascii": "Alleppey",
    "lat": 9.5004,
    "lng": 76.37,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Kerala",
    "capital": "",
    "population": 176783,
    "id": 1356293762
  },
  {
    "city": "Ipswich",
    "city_ascii": "Ipswich",
    "lat": 52.0594,
    "lng": 1.1556,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Suffolk",
    "capital": "",
    "population": 180000,
    "id": 1826629486
  },
  {
    "city": "Boma",
    "city_ascii": "Boma",
    "lat": -5.85,
    "lng": 13.05,
    "country": "Congo (Kinshasa)",
    "iso2": "CD",
    "iso3": "COD",
    "admin_name": "Kongo Central",
    "capital": "",
    "population": 178638,
    "id": 1180611452
  },
  {
    "city": "Tiaret",
    "city_ascii": "Tiaret",
    "lat": 35.3667,
    "lng": 1.3167,
    "country": "Algeria",
    "iso2": "DZ",
    "iso3": "DZA",
    "admin_name": "Tiaret",
    "capital": "admin",
    "population": 178915,
    "id": 1012785868
  },
  {
    "city": "Edirne",
    "city_ascii": "Edirne",
    "lat": 41.6781,
    "lng": 26.5594,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Edirne",
    "capital": "admin",
    "population": 180327,
    "id": 1792102607
  },
  {
    "city": "Chicoloapan",
    "city_ascii": "Chicoloapan",
    "lat": 19.4167,
    "lng": -98.9,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "México",
    "capital": "minor",
    "population": 175053,
    "id": 1484980825
  },
  {
    "city": "Longjing",
    "city_ascii": "Longjing",
    "lat": 42.77,
    "lng": 129.4197,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Jilin",
    "capital": "minor",
    "population": 180307,
    "id": 1156772152
  },
  {
    "city": "Split",
    "city_ascii": "Split",
    "lat": 43.51,
    "lng": 16.45,
    "country": "Croatia",
    "iso2": "HR",
    "iso3": "HRV",
    "admin_name": "Splitsko-Dalmatinska Županija",
    "capital": "admin",
    "population": 178102,
    "id": 1191440208
  },
  {
    "city": "Lorain",
    "city_ascii": "Lorain",
    "lat": 41.4409,
    "lng": -82.184,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 180279,
    "id": 1840000644
  },
  {
    "city": "Klerksdorp",
    "city_ascii": "Klerksdorp",
    "lat": -26.8667,
    "lng": 26.6667,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "North West",
    "capital": "",
    "population": 178921,
    "id": 1710416691
  },
  {
    "city": "Murfreesboro",
    "city_ascii": "Murfreesboro",
    "lat": 35.8492,
    "lng": -86.4119,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 179951,
    "id": 1840014495
  },
  {
    "city": "High Point",
    "city_ascii": "High Point",
    "lat": 35.9907,
    "lng": -79.9938,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 179913,
    "id": 1840014479
  },
  {
    "city": "Hamm",
    "city_ascii": "Hamm",
    "lat": 51.6667,
    "lng": 7.8167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "minor",
    "population": 179111,
    "id": 1276808737
  },
  {
    "city": "Tarija",
    "city_ascii": "Tarija",
    "lat": -21.5317,
    "lng": -64.7311,
    "country": "Bolivia",
    "iso2": "BO",
    "iso3": "BOL",
    "admin_name": "Tarija",
    "capital": "admin",
    "population": 179528,
    "id": 1068356085
  },
  {
    "city": "Al ‘Arīsh",
    "city_ascii": "Al `Arish",
    "lat": 31.1249,
    "lng": 33.8006,
    "country": "Egypt",
    "iso2": "EG",
    "iso3": "EGY",
    "admin_name": "Shamāl Sīnā’",
    "capital": "admin",
    "population": 178651,
    "id": 1818291145
  },
  {
    "city": "El Oued",
    "city_ascii": "El Oued",
    "lat": 33.3704,
    "lng": 6.86,
    "country": "Algeria",
    "iso2": "DZ",
    "iso3": "DZA",
    "admin_name": "El Oued",
    "capital": "admin",
    "population": 177497,
    "id": 1012148937
  },
  {
    "city": "Paita",
    "city_ascii": "Paita",
    "lat": -5.0667,
    "lng": -81.1,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "Piura",
    "capital": "",
    "population": 179346,
    "id": 1604588759
  },
  {
    "city": "Newport News",
    "city_ascii": "Newport News",
    "lat": 37.1051,
    "lng": -76.5185,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 179225,
    "id": 1840003862
  },
  {
    "city": "Torbalı",
    "city_ascii": "Torbali",
    "lat": 38.1619,
    "lng": 27.3583,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "İzmir",
    "capital": "minor",
    "population": 178772,
    "id": 1792319298
  },
  {
    "city": "Potsdam",
    "city_ascii": "Potsdam",
    "lat": 52.4,
    "lng": 13.0667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Brandenburg",
    "capital": "admin",
    "population": 178089,
    "id": 1276395100
  },
  {
    "city": "Langsa",
    "city_ascii": "Langsa",
    "lat": 4.4667,
    "lng": 97.95,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Aceh",
    "capital": "",
    "population": 178334,
    "id": 1360337304
  },
  {
    "city": "Bade",
    "city_ascii": "Bade",
    "lat": 24.9575,
    "lng": 121.2989,
    "country": "Taiwan",
    "iso2": "TW",
    "iso3": "TWN",
    "admin_name": "Taoyuan",
    "capital": "",
    "population": 172065,
    "id": 1158126203
  },
  {
    "city": "Bida",
    "city_ascii": "Bida",
    "lat": 9.0804,
    "lng": 6.01,
    "country": "Nigeria",
    "iso2": "NG",
    "iso3": "NGA",
    "admin_name": "Niger",
    "capital": "minor",
    "population": 173849,
    "id": 1566468363
  },
  {
    "city": "Şalālah",
    "city_ascii": "Salalah",
    "lat": 17.0197,
    "lng": 54.0897,
    "country": "Oman",
    "iso2": "OM",
    "iso3": "OMN",
    "admin_name": "Z̧ufār",
    "capital": "admin",
    "population": 178469,
    "id": 1512995148
  },
  {
    "city": "Matosinhos",
    "city_ascii": "Matosinhos",
    "lat": 41.2077,
    "lng": -8.6674,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Porto",
    "capital": "minor",
    "population": 175478,
    "id": 1620049442
  },
  {
    "city": "Baranavichy",
    "city_ascii": "Baranavichy",
    "lat": 53.1167,
    "lng": 25.9833,
    "country": "Belarus",
    "iso2": "BY",
    "iso3": "BLR",
    "admin_name": "Brestskaya Voblasts’",
    "capital": "minor",
    "population": 175000,
    "id": 1112421165
  },
  {
    "city": "Bīrjand",
    "city_ascii": "Birjand",
    "lat": 32.8667,
    "lng": 59.2,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Khorāsān-e Jonūbī",
    "capital": "admin",
    "population": 178020,
    "id": 1364897698
  },
  {
    "city": "Vellore",
    "city_ascii": "Vellore",
    "lat": 12.9204,
    "lng": 79.15,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Tamil Nādu",
    "capital": "",
    "population": 177081,
    "id": 1356977661
  },
  {
    "city": "Dam Dam",
    "city_ascii": "Dam Dam",
    "lat": 22.62,
    "lng": 88.42,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "West Bengal",
    "capital": "",
    "population": 114786,
    "id": 1356051452
  },
  {
    "city": "Bejaïa",
    "city_ascii": "Bejaia",
    "lat": 36.75,
    "lng": 5.0667,
    "country": "Algeria",
    "iso2": "DZ",
    "iso3": "DZA",
    "admin_name": "Bejaïa",
    "capital": "admin",
    "population": 176139,
    "id": 1012140298
  },
  {
    "city": "Rancho Cucamonga",
    "city_ascii": "Rancho Cucamonga",
    "lat": 34.1248,
    "lng": -117.5666,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 177603,
    "id": 1840020411
  },
  {
    "city": "Norilsk",
    "city_ascii": "Norilsk",
    "lat": 69.3333,
    "lng": 88.2167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Krasnoyarskiy Kray",
    "capital": "",
    "population": 177428,
    "id": 1643832764
  },
  {
    "city": "Santander",
    "city_ascii": "Santander",
    "lat": 43.4667,
    "lng": -3.8,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Cantabria",
    "capital": "admin",
    "population": 172539,
    "id": 1724247413
  },
  {
    "city": "Hemet",
    "city_ascii": "Hemet",
    "lat": 33.7341,
    "lng": -116.9969,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 177253,
    "id": 1840020550
  },
  {
    "city": "Yangmei",
    "city_ascii": "Yangmei",
    "lat": 24.9167,
    "lng": 121.15,
    "country": "Taiwan",
    "iso2": "TW",
    "iso3": "TWN",
    "admin_name": "Taoyuan",
    "capital": "",
    "population": 175000,
    "id": 1158247880
  },
  {
    "city": "Ciudad Valles",
    "city_ascii": "Ciudad Valles",
    "lat": 21.9833,
    "lng": -99.0167,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "San Luis Potosí",
    "capital": "minor",
    "population": 176935,
    "id": 1484904610
  },
  {
    "city": "Kadugli",
    "city_ascii": "Kadugli",
    "lat": 11.01,
    "lng": 29.7,
    "country": "Sudan",
    "iso2": "SD",
    "iso3": "SDN",
    "admin_name": "South Kordofan",
    "capital": "admin",
    "population": 176931,
    "id": 1729757472
  },
  {
    "city": "Les Cayes",
    "city_ascii": "Les Cayes",
    "lat": 18.2004,
    "lng": -73.75,
    "country": "Haiti",
    "iso2": "HT",
    "iso3": "HTI",
    "admin_name": "Sud",
    "capital": "admin",
    "population": 175457,
    "id": 1332587854
  },
  {
    "city": "Hitachi",
    "city_ascii": "Hitachi",
    "lat": 36.6,
    "lng": 140.65,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Ibaraki",
    "capital": "",
    "population": 176069,
    "id": 1392145167
  },
  {
    "city": "Sunderland",
    "city_ascii": "Sunderland",
    "lat": 54.9061,
    "lng": -1.3811,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Sunderland",
    "capital": "",
    "population": 174286,
    "id": 1826736387
  },
  {
    "city": "Letpandan",
    "city_ascii": "Letpandan",
    "lat": 17.7866,
    "lng": 95.7507,
    "country": "Burma",
    "iso2": "MM",
    "iso3": "MMR",
    "admin_name": "Bago",
    "capital": "",
    "population": 176571,
    "id": 1104882792
  },
  {
    "city": "Kamakurayama",
    "city_ascii": "Kamakurayama",
    "lat": 35.3197,
    "lng": 139.5525,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Kanagawa",
    "capital": "",
    "population": 172262,
    "id": 1392220561
  },
  {
    "city": "Zabrze",
    "city_ascii": "Zabrze",
    "lat": 50.3,
    "lng": 18.7833,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Śląskie",
    "capital": "minor",
    "population": 174349,
    "id": 1616784915
  },
  {
    "city": "Almada",
    "city_ascii": "Almada",
    "lat": 38.6803,
    "lng": -9.1583,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Setúbal",
    "capital": "minor",
    "population": 174030,
    "id": 1620388276
  },
  {
    "city": "Ilford",
    "city_ascii": "Ilford",
    "lat": 51.5588,
    "lng": 0.0855,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Redbridge",
    "capital": "",
    "population": 168168,
    "id": 1826381000
  },
  {
    "city": "Cuautla",
    "city_ascii": "Cuautla",
    "lat": 18.8167,
    "lng": -98.95,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Morelos",
    "capital": "minor",
    "population": 175208,
    "id": 1484359683
  },
  {
    "city": "Santa Cruz",
    "city_ascii": "Santa Cruz",
    "lat": 36.9789,
    "lng": -122.0346,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 176428,
    "id": 1840021579
  },
  {
    "city": "Saint-Louis",
    "city_ascii": "Saint-Louis",
    "lat": 16.0333,
    "lng": -16.5,
    "country": "Senegal",
    "iso2": "SN",
    "iso3": "SEN",
    "admin_name": "Saint-Louis",
    "capital": "admin",
    "population": 176000,
    "id": 1686542859
  },
  {
    "city": "Barra Mansa",
    "city_ascii": "Barra Mansa",
    "lat": -22.56,
    "lng": -44.17,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Rio de Janeiro",
    "capital": "",
    "population": 169386,
    "id": 1076686083
  },
  {
    "city": "Ait Melloul",
    "city_ascii": "Ait Melloul",
    "lat": 30.3342,
    "lng": -9.4972,
    "country": "Morocco",
    "iso2": "MA",
    "iso3": "MAR",
    "admin_name": "Souss-Massa",
    "capital": "",
    "population": 171847,
    "id": 1504134516
  },
  {
    "city": "Plzeň",
    "city_ascii": "Plzen",
    "lat": 49.7414,
    "lng": 13.3825,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Plzeňský Kraj",
    "capital": "admin",
    "population": 174842,
    "id": 1203402469
  },
  {
    "city": "Ciudad del Carmen",
    "city_ascii": "Ciudad del Carmen",
    "lat": 18.6333,
    "lng": -91.8333,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Campeche",
    "capital": "minor",
    "population": 169466,
    "id": 1484192447
  },
  {
    "city": "Danbury",
    "city_ascii": "Danbury",
    "lat": 41.4016,
    "lng": -73.471,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 176030,
    "id": 1840004837
  },
  {
    "city": "Toulon",
    "city_ascii": "Toulon",
    "lat": 43.1258,
    "lng": 5.9306,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Provence-Alpes-Côte d’Azur",
    "capital": "minor",
    "population": 171953,
    "id": 1250541629
  },
  {
    "city": "Peoria",
    "city_ascii": "Peoria",
    "lat": 33.7844,
    "lng": -112.2989,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 175961,
    "id": 1840020567
  },
  {
    "city": "Oeiras",
    "city_ascii": "Oeiras",
    "lat": 38.697,
    "lng": -9.3017,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Lisboa",
    "capital": "minor",
    "population": 172120,
    "id": 1620375757
  },
  {
    "city": "Dagupan City",
    "city_ascii": "Dagupan City",
    "lat": 16.0333,
    "lng": 120.3333,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Dagupan",
    "capital": "admin",
    "population": 171271,
    "id": 1608530964
  },
  {
    "city": "Ereğli",
    "city_ascii": "Eregli",
    "lat": 41.2583,
    "lng": 31.425,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Zonguldak",
    "capital": "minor",
    "population": 175605,
    "id": 1792160689
  },
  {
    "city": "Oceanside",
    "city_ascii": "Oceanside",
    "lat": 33.2247,
    "lng": -117.3083,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 175742,
    "id": 1840020623
  },
  {
    "city": "Shibirghān",
    "city_ascii": "Shibirghan",
    "lat": 36.665,
    "lng": 65.752,
    "country": "Afghanistan",
    "iso2": "AF",
    "iso3": "AFG",
    "admin_name": "Jowzjān",
    "capital": "admin",
    "population": 175599,
    "id": 1004805783
  },
  {
    "city": "Chandannagar",
    "city_ascii": "Chandannagar",
    "lat": 22.8667,
    "lng": 88.3833,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "West Bengal",
    "capital": "",
    "population": 166867,
    "id": 1356174802
  },
  {
    "city": "Harar",
    "city_ascii": "Harar",
    "lat": 9.32,
    "lng": 42.15,
    "country": "Ethiopia",
    "iso2": "ET",
    "iso3": "ETH",
    "admin_name": "Hārerī Hizb",
    "capital": "admin",
    "population": 174994,
    "id": 1231785978
  },
  {
    "city": "Middlesbrough",
    "city_ascii": "Middlesbrough",
    "lat": 54.5767,
    "lng": -1.2355,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Redcar and Cleveland",
    "capital": "",
    "population": 174700,
    "id": 1826407067
  },
  {
    "city": "Tyre",
    "city_ascii": "Tyre",
    "lat": 33.2667,
    "lng": 35.2,
    "country": "Lebanon",
    "iso2": "LB",
    "iso3": "LBN",
    "admin_name": "Liban-Sud",
    "capital": "minor",
    "population": 160000,
    "id": 1422316734
  },
  {
    "city": "Koronadal",
    "city_ascii": "Koronadal",
    "lat": 6.5,
    "lng": 124.85,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "South Cotabato",
    "capital": "",
    "population": 174942,
    "id": 1608000651
  },
  {
    "city": "Olsztyn",
    "city_ascii": "Olsztyn",
    "lat": 53.78,
    "lng": 20.4942,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Warmińsko-Mazurskie",
    "capital": "admin",
    "population": 173599,
    "id": 1616383275
  },
  {
    "city": "Cuddalore",
    "city_ascii": "Cuddalore",
    "lat": 11.75,
    "lng": 79.75,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Tamil Nādu",
    "capital": "",
    "population": 173676,
    "id": 1356544008
  },
  {
    "city": "Marāgheh",
    "city_ascii": "Maragheh",
    "lat": 37.3833,
    "lng": 46.2667,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Āz̄arbāyjān-e Sharqī",
    "capital": "minor",
    "population": 175255,
    "id": 1364532780
  },
  {
    "city": "Ōbiraki",
    "city_ascii": "Obiraki",
    "lat": 40.57,
    "lng": 140.47,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Aomori",
    "capital": "",
    "population": 174972,
    "id": 1392794397
  },
  {
    "city": "Tebingtinggi",
    "city_ascii": "Tebingtinggi",
    "lat": 3.3283,
    "lng": 99.1625,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Sumatera Utara",
    "capital": "",
    "population": 169786,
    "id": 1360386781
  },
  {
    "city": "Comitán",
    "city_ascii": "Comitan",
    "lat": 16.2511,
    "lng": -92.1342,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Chiapas",
    "capital": "minor",
    "population": 170000,
    "id": 1484955658
  },
  {
    "city": "San Francisco de Macorís",
    "city_ascii": "San Francisco de Macoris",
    "lat": 19.3,
    "lng": -70.25,
    "country": "Dominican Republic",
    "iso2": "DO",
    "iso3": "DOM",
    "admin_name": "Cibao Nordeste",
    "capital": "admin",
    "population": 174879,
    "id": 1214710545
  },
  {
    "city": "Takaoka",
    "city_ascii": "Takaoka",
    "lat": 42.6504,
    "lng": 141.55,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hokkaidō",
    "capital": "",
    "population": 174806,
    "id": 1392760442
  },
  {
    "city": "Sīrjān",
    "city_ascii": "Sirjan",
    "lat": 29.47,
    "lng": 55.73,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Kermān",
    "capital": "minor",
    "population": 175000,
    "id": 1364204506
  },
  {
    "city": "Tanauan",
    "city_ascii": "Tanauan",
    "lat": 14.0833,
    "lng": 121.15,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Batangas",
    "capital": "",
    "population": 173366,
    "id": 1608053818
  },
  {
    "city": "Menemen",
    "city_ascii": "Menemen",
    "lat": 38.6,
    "lng": 27.0667,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "İzmir",
    "capital": "minor",
    "population": 174564,
    "id": 1792699814
  },
  {
    "city": "Malaybalay",
    "city_ascii": "Malaybalay",
    "lat": 8.1575,
    "lng": 125.1278,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Bukidnon",
    "capital": "admin",
    "population": 174625,
    "id": 1608859265
  },
  {
    "city": "Elk Grove",
    "city_ascii": "Elk Grove",
    "lat": 38.416,
    "lng": -121.3842,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 174775,
    "id": 1840020245
  },
  {
    "city": "Syzran",
    "city_ascii": "Syzran",
    "lat": 53.1667,
    "lng": 48.4667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Samarskaya Oblast’",
    "capital": "",
    "population": 173260,
    "id": 1643711617
  },
  {
    "city": "Carúpano",
    "city_ascii": "Carupano",
    "lat": 10.6722,
    "lng": -63.2403,
    "country": "Venezuela",
    "iso2": "VE",
    "iso3": "VEN",
    "admin_name": "Sucre",
    "capital": "minor",
    "population": 173877,
    "id": 1862777685
  },
  {
    "city": "Saint-Étienne",
    "city_ascii": "Saint-Etienne",
    "lat": 45.4347,
    "lng": 4.3903,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Auvergne-Rhône-Alpes",
    "capital": "minor",
    "population": 172565,
    "id": 1250000540
  },
  {
    "city": "Lagos de Moreno",
    "city_ascii": "Lagos de Moreno",
    "lat": 21.3564,
    "lng": -101.9292,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Jalisco",
    "capital": "minor",
    "population": 164981,
    "id": 1484759605
  },
  {
    "city": "León",
    "city_ascii": "Leon",
    "lat": 12.4333,
    "lng": -86.8833,
    "country": "Nicaragua",
    "iso2": "NI",
    "iso3": "NIC",
    "admin_name": "León",
    "capital": "admin",
    "population": 174051,
    "id": 1558654236
  },
  {
    "city": "Chīrāla",
    "city_ascii": "Chirala",
    "lat": 15.82,
    "lng": 80.35,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Andhra Pradesh",
    "capital": "",
    "population": 172826,
    "id": 1356100300
  },
  {
    "city": "Titāgarh",
    "city_ascii": "Titagarh",
    "lat": 22.74,
    "lng": 88.37,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "West Bengal",
    "capital": "",
    "population": 116541,
    "id": 1356590173
  },
  {
    "city": "Bielsko-Biała",
    "city_ascii": "Bielsko-Biala",
    "lat": 49.8225,
    "lng": 19.0444,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Śląskie",
    "capital": "minor",
    "population": 172781,
    "id": 1616302636
  },
  {
    "city": "Beppuchō",
    "city_ascii": "Beppucho",
    "lat": 42.9304,
    "lng": 143.17,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hokkaidō",
    "capital": "",
    "population": 173890,
    "id": 1392712158
  },
  {
    "city": "Bytom",
    "city_ascii": "Bytom",
    "lat": 50.347,
    "lng": 18.923,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Śląskie",
    "capital": "minor",
    "population": 171515,
    "id": 1616740907
  },
  {
    "city": "Linjiang",
    "city_ascii": "Linjiang",
    "lat": 41.8082,
    "lng": 126.9137,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Jilin",
    "capital": "minor",
    "population": 173903,
    "id": 1156357534
  },
  {
    "city": "Hạ Long",
    "city_ascii": "Ha Long",
    "lat": 20.95,
    "lng": 107.0833,
    "country": "Vietnam",
    "iso2": "VN",
    "iso3": "VNM",
    "admin_name": "Quảng Ninh",
    "capital": "admin",
    "population": 172915,
    "id": 1704379547
  },
  {
    "city": "Deo",
    "city_ascii": "Deo",
    "lat": 24.6561,
    "lng": 84.4356,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Bihār",
    "capital": "",
    "population": 173216,
    "id": 1356131438
  },
  {
    "city": "Pembroke Pines",
    "city_ascii": "Pembroke Pines",
    "lat": 26.0128,
    "lng": -80.3382,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 173591,
    "id": 1840015141
  },
  {
    "city": "Tlemcen",
    "city_ascii": "Tlemcen",
    "lat": 34.8828,
    "lng": -1.3167,
    "country": "Algeria",
    "iso2": "DZ",
    "iso3": "DZA",
    "admin_name": "Tlemcen",
    "capital": "admin",
    "population": 173531,
    "id": 1012978325
  },
  {
    "city": "Albacete",
    "city_ascii": "Albacete",
    "lat": 38.9956,
    "lng": -1.8558,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Castille-La Mancha",
    "capital": "minor",
    "population": 173329,
    "id": 1724000069
  },
  {
    "city": "Castellón de la Plana",
    "city_ascii": "Castellon de la Plana",
    "lat": 39.97,
    "lng": -0.05,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Valencia",
    "capital": "minor",
    "population": 171728,
    "id": 1724579885
  },
  {
    "city": "Shāhīn Shahr",
    "city_ascii": "Shahin Shahr",
    "lat": 32.8639,
    "lng": 51.5475,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Eşfahān",
    "capital": "minor",
    "population": 173329,
    "id": 1364921034
  },
  {
    "city": "Comodoro Rivadavia",
    "city_ascii": "Comodoro Rivadavia",
    "lat": -45.8667,
    "lng": -67.5,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Chubut",
    "capital": "minor",
    "population": 173266,
    "id": 1032042035
  },
  {
    "city": "Ludwigshafen",
    "city_ascii": "Ludwigshafen",
    "lat": 49.4811,
    "lng": 8.4353,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Rhineland-Palatinate",
    "capital": "minor",
    "population": 171061,
    "id": 1276912330
  },
  {
    "city": "Vallejo",
    "city_ascii": "Vallejo",
    "lat": 38.1133,
    "lng": -122.2358,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 173256,
    "id": 1840021499
  },
  {
    "city": "Escuintla",
    "city_ascii": "Escuintla",
    "lat": 14.305,
    "lng": -90.785,
    "country": "Guatemala",
    "iso2": "GT",
    "iso3": "GTM",
    "admin_name": "Escuintla",
    "capital": "admin",
    "population": 172708,
    "id": 1320838903
  },
  {
    "city": "Izumo",
    "city_ascii": "Izumo",
    "lat": 35.3681,
    "lng": 132.755,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Shimane",
    "capital": "",
    "population": 172784,
    "id": 1392299364
  },
  {
    "city": "Bertoua",
    "city_ascii": "Bertoua",
    "lat": 4.5833,
    "lng": 13.6833,
    "country": "Cameroon",
    "iso2": "CM",
    "iso3": "CMR",
    "admin_name": "Est",
    "capital": "admin",
    "population": 173000,
    "id": 1120406967
  },
  {
    "city": "Banyuwangi",
    "city_ascii": "Banyuwangi",
    "lat": -8.195,
    "lng": 114.3696,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Jawa Timur",
    "capital": "minor",
    "population": 172424,
    "id": 1360008480
  },
  {
    "city": "Mülheim",
    "city_ascii": "Mulheim",
    "lat": 51.4283,
    "lng": 6.8789,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "minor",
    "population": 170880,
    "id": 1276676856
  },
  {
    "city": "Sacaba",
    "city_ascii": "Sacaba",
    "lat": -17.4042,
    "lng": -66.0408,
    "country": "Bolivia",
    "iso2": "BO",
    "iso3": "BOL",
    "admin_name": "Cochabamba",
    "capital": "",
    "population": 172466,
    "id": 1068288609
  },
  {
    "city": "Carpina",
    "city_ascii": "Carpina",
    "lat": -7.8508,
    "lng": -35.2547,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Pernambuco",
    "capital": "",
    "population": 165579,
    "id": 1076005200
  },
  {
    "city": "Banhā",
    "city_ascii": "Banha",
    "lat": 30.4628,
    "lng": 31.1797,
    "country": "Egypt",
    "iso2": "EG",
    "iso3": "EGY",
    "admin_name": "Al Qalyūbīyah",
    "capital": "admin",
    "population": 157701,
    "id": 1818596265
  },
  {
    "city": "Barrie",
    "city_ascii": "Barrie",
    "lat": 44.3711,
    "lng": -79.6769,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 172657,
    "id": 1124340223
  },
  {
    "city": "Krasnogorsk",
    "city_ascii": "Krasnogorsk",
    "lat": 55.8167,
    "lng": 37.3333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Moskovskaya Oblast’",
    "capital": "",
    "population": 171793,
    "id": 1643674336
  },
  {
    "city": "Kaolack",
    "city_ascii": "Kaolack",
    "lat": 14.152,
    "lng": -16.0726,
    "country": "Senegal",
    "iso2": "SN",
    "iso3": "SEN",
    "admin_name": "Kaolack",
    "capital": "admin",
    "population": 172305,
    "id": 1686256343
  },
  {
    "city": "Sakura",
    "city_ascii": "Sakura",
    "lat": 35.7239,
    "lng": 140.2239,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Chiba",
    "capital": "",
    "population": 170906,
    "id": 1392611314
  },
  {
    "city": "Burgos",
    "city_ascii": "Burgos",
    "lat": 42.35,
    "lng": -3.6822,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Castille-Leon",
    "capital": "minor",
    "population": 170183,
    "id": 1724993285
  },
  {
    "city": "Guanajuato",
    "city_ascii": "Guanajuato",
    "lat": 21.0178,
    "lng": -101.2567,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Guanajuato",
    "capital": "admin",
    "population": 171709,
    "id": 1484185068
  },
  {
    "city": "Reggio Emilia",
    "city_ascii": "Reggio Emilia",
    "lat": 44.7,
    "lng": 10.6333,
    "country": "Italy",
    "iso2": "IT",
    "iso3": "ITA",
    "admin_name": "Emilia-Romagna",
    "capital": "minor",
    "population": 170996,
    "id": 1380487033
  },
  {
    "city": "Francisco Morato",
    "city_ascii": "Francisco Morato",
    "lat": -23.2817,
    "lng": -46.7425,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 168243,
    "id": 1076808330
  },
  {
    "city": "Garden Grove",
    "city_ascii": "Garden Grove",
    "lat": 33.7787,
    "lng": -117.9601,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 171644,
    "id": 1840020577
  },
  {
    "city": "Volgodonsk",
    "city_ascii": "Volgodonsk",
    "lat": 47.5167,
    "lng": 42.15,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Rostovskaya Oblast’",
    "capital": "",
    "population": 171471,
    "id": 1643784914
  },
  {
    "city": "Bago",
    "city_ascii": "Bago",
    "lat": 10.5388,
    "lng": 122.8384,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Negros Occidental",
    "capital": "",
    "population": 170981,
    "id": 1608943172
  },
  {
    "city": "Niiza",
    "city_ascii": "Niiza",
    "lat": 35.7933,
    "lng": 139.5653,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Saitama",
    "capital": "",
    "population": 164083,
    "id": 1392772700
  },
  {
    "city": "Toledo",
    "city_ascii": "Toledo",
    "lat": 10.3833,
    "lng": 123.65,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Cebu",
    "capital": "",
    "population": 170335,
    "id": 1608665464
  },
  {
    "city": "Kamensk-Ural’skiy",
    "city_ascii": "Kamensk-Ural'skiy",
    "lat": 56.4,
    "lng": 61.9333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sverdlovskaya Oblast’",
    "capital": "",
    "population": 169929,
    "id": 1643640451
  },
  {
    "city": "Kohat",
    "city_ascii": "Kohat",
    "lat": 33.5869,
    "lng": 71.4414,
    "country": "Pakistan",
    "iso2": "PK",
    "iso3": "PAK",
    "admin_name": "Khyber Pakhtunkhwa",
    "capital": "minor",
    "population": 170800,
    "id": 1586349992
  },
  {
    "city": "Nishio",
    "city_ascii": "Nishio",
    "lat": 34.8667,
    "lng": 137.0667,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Aichi",
    "capital": "",
    "population": 169926,
    "id": 1392425838
  },
  {
    "city": "Loja",
    "city_ascii": "Loja",
    "lat": -3.9906,
    "lng": -79.205,
    "country": "Ecuador",
    "iso2": "EC",
    "iso3": "ECU",
    "admin_name": "Loja",
    "capital": "admin",
    "population": 170280,
    "id": 1218739896
  },
  {
    "city": "Cuauhtémoc",
    "city_ascii": "Cuauhtemoc",
    "lat": 28.405,
    "lng": -106.8667,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Chihuahua",
    "capital": "minor",
    "population": 168482,
    "id": 1484365643
  },
  {
    "city": "Medford",
    "city_ascii": "Medford",
    "lat": 42.3372,
    "lng": -122.8537,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 170876,
    "id": 1840020076
  },
  {
    "city": "Hamilton",
    "city_ascii": "Hamilton",
    "lat": -37.7833,
    "lng": 175.2833,
    "country": "New Zealand",
    "iso2": "NZ",
    "iso3": "NZL",
    "admin_name": "Waikato",
    "capital": "admin",
    "population": 169300,
    "id": 1554873040
  },
  {
    "city": "Ussuriysk",
    "city_ascii": "Ussuriysk",
    "lat": 43.8,
    "lng": 131.95,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Primorskiy Kray",
    "capital": "",
    "population": 170660,
    "id": 1643001263
  },
  {
    "city": "Uvira",
    "city_ascii": "Uvira",
    "lat": -3.4044,
    "lng": 29.1379,
    "country": "Congo (Kinshasa)",
    "iso2": "CD",
    "iso3": "COD",
    "admin_name": "Sud-Kivu",
    "capital": "",
    "population": 170391,
    "id": 1180205443
  },
  {
    "city": "Portmore",
    "city_ascii": "Portmore",
    "lat": 17.9667,
    "lng": -76.8667,
    "country": "Jamaica",
    "iso2": "JM",
    "iso3": "JAM",
    "admin_name": "Saint Catherine",
    "capital": "",
    "population": 170000,
    "id": 1388926551
  },
  {
    "city": "Machilīpatnam",
    "city_ascii": "Machilipatnam",
    "lat": 16.1667,
    "lng": 81.1333,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Andhra Pradesh",
    "capital": "",
    "population": 169892,
    "id": 1356067784
  },
  {
    "city": "Bordj Bou Arreridj",
    "city_ascii": "Bordj Bou Arreridj",
    "lat": 36.0667,
    "lng": 4.7667,
    "country": "Algeria",
    "iso2": "DZ",
    "iso3": "DZA",
    "admin_name": "Bordj Bou Arréridj",
    "capital": "admin",
    "population": 168346,
    "id": 1012016677
  },
  {
    "city": "Nukus",
    "city_ascii": "Nukus",
    "lat": 42.4647,
    "lng": 59.6022,
    "country": "Uzbekistan",
    "iso2": "UZ",
    "iso3": "UZB",
    "admin_name": "Qoraqalpog‘iston",
    "capital": "admin",
    "population": 170362,
    "id": 1860620079
  },
  {
    "city": "Malāyer",
    "city_ascii": "Malayer",
    "lat": 34.2942,
    "lng": 48.82,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Hamadān",
    "capital": "minor",
    "population": 170237,
    "id": 1364030346
  },
  {
    "city": "N’Zérékoré",
    "city_ascii": "N'Zerekore",
    "lat": 7.76,
    "lng": -8.83,
    "country": "Guinea",
    "iso2": "GN",
    "iso3": "GIN",
    "admin_name": "N’Zérékoré",
    "capital": "admin",
    "population": 168121,
    "id": 1324336588
  },
  {
    "city": "Cary",
    "city_ascii": "Cary",
    "lat": 35.782,
    "lng": -78.8191,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 170282,
    "id": 1840016196
  },
  {
    "city": "Kluang",
    "city_ascii": "Kluang",
    "lat": 2.0383,
    "lng": 103.3179,
    "country": "Malaysia",
    "iso2": "MY",
    "iso3": "MYS",
    "admin_name": "Johor",
    "capital": "",
    "population": 169828,
    "id": 1458692921
  },
  {
    "city": "Novocherkassk",
    "city_ascii": "Novocherkassk",
    "lat": 47.4222,
    "lng": 40.0939,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Rostovskaya Oblast’",
    "capital": "",
    "population": 168766,
    "id": 1643990727
  },
  {
    "city": "Marysville",
    "city_ascii": "Marysville",
    "lat": 48.0809,
    "lng": -122.1561,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 170009,
    "id": 1840019789
  },
  {
    "city": "Digos",
    "city_ascii": "Digos",
    "lat": 6.75,
    "lng": 125.35,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Davao del Sur",
    "capital": "admin",
    "population": 169393,
    "id": 1608635653
  },
  {
    "city": "San Luis",
    "city_ascii": "San Luis",
    "lat": -33.2994,
    "lng": -66.3392,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "San Luis",
    "capital": "admin",
    "population": 169947,
    "id": 1032547434
  },
  {
    "city": "Corona",
    "city_ascii": "Corona",
    "lat": 33.8616,
    "lng": -117.5649,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 169868,
    "id": 1840019305
  },
  {
    "city": "Oldenburg",
    "city_ascii": "Oldenburg",
    "lat": 53.1439,
    "lng": 8.2139,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "minor",
    "population": 168210,
    "id": 1276946953
  },
  {
    "city": "Medinīpur",
    "city_ascii": "Medinipur",
    "lat": 22.4333,
    "lng": 87.3333,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "West Bengal",
    "capital": "",
    "population": 169264,
    "id": 1356432910
  },
  {
    "city": "Fenglu",
    "city_ascii": "Fenglu",
    "lat": 24.6728,
    "lng": 102.9149,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Yunnan",
    "capital": "minor",
    "population": 169366,
    "id": 1156317766
  },
  {
    "city": "Al Marj",
    "city_ascii": "Al Marj",
    "lat": 32.5005,
    "lng": 20.83,
    "country": "Libya",
    "iso2": "LY",
    "iso3": "LBY",
    "admin_name": "Al Marj",
    "capital": "admin",
    "population": 169540,
    "id": 1434742923
  },
  {
    "city": "Sŏsan",
    "city_ascii": "Sosan",
    "lat": 36.7817,
    "lng": 126.4522,
    "country": "Korea, South",
    "iso2": "KR",
    "iso3": "KOR",
    "admin_name": "Chungnam",
    "capital": "",
    "population": 169221,
    "id": 1410171679
  },
  {
    "city": "Ocala",
    "city_ascii": "Ocala",
    "lat": 29.178,
    "lng": -82.1511,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 169383,
    "id": 1840015067
  },
  {
    "city": "Sonsonate",
    "city_ascii": "Sonsonate",
    "lat": 13.72,
    "lng": -89.73,
    "country": "El Salvador",
    "iso2": "SV",
    "iso3": "SLV",
    "admin_name": "Sonsonate",
    "capital": "admin",
    "population": 168947,
    "id": 1222380208
  },
  {
    "city": "Gondomar",
    "city_ascii": "Gondomar",
    "lat": 41.15,
    "lng": -8.5333,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Porto",
    "capital": "minor",
    "population": 168027,
    "id": 1620506259
  },
  {
    "city": "Sarh",
    "city_ascii": "Sarh",
    "lat": 9.15,
    "lng": 18.3833,
    "country": "Chad",
    "iso2": "TD",
    "iso3": "TCD",
    "admin_name": "Moyen-Chari",
    "capital": "admin",
    "population": 169196,
    "id": 1148442794
  },
  {
    "city": "Jamālpur",
    "city_ascii": "Jamalpur",
    "lat": 24.9004,
    "lng": 89.95,
    "country": "Bangladesh",
    "iso2": "BD",
    "iso3": "BGD",
    "admin_name": "Mymensingh",
    "capital": "",
    "population": 167900,
    "id": 1050233680
  },
  {
    "city": "Fredericksburg",
    "city_ascii": "Fredericksburg",
    "lat": 38.2992,
    "lng": -77.4872,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 168909,
    "id": 1840003845
  },
  {
    "city": "Sobral",
    "city_ascii": "Sobral",
    "lat": -3.6861,
    "lng": -40.3497,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Ceará",
    "capital": "",
    "population": 157996,
    "id": 1076577102
  },
  {
    "city": "Ambato",
    "city_ascii": "Ambato",
    "lat": -1.2417,
    "lng": -78.6197,
    "country": "Ecuador",
    "iso2": "EC",
    "iso3": "ECU",
    "admin_name": "Tungurahua",
    "capital": "admin",
    "population": 165185,
    "id": 1218046148
  },
  {
    "city": "Bāramūla",
    "city_ascii": "Baramula",
    "lat": 34.2,
    "lng": 74.34,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Jammu and Kashmīr",
    "capital": "",
    "population": 167986,
    "id": 1356671176
  },
  {
    "city": "Roxas City",
    "city_ascii": "Roxas City",
    "lat": 11.5833,
    "lng": 122.75,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Capiz",
    "capital": "admin",
    "population": 167003,
    "id": 1608344583
  },
  {
    "city": "Sorsogon",
    "city_ascii": "Sorsogon",
    "lat": 12.9667,
    "lng": 124,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Sorsogon",
    "capital": "admin",
    "population": 168110,
    "id": 1608265953
  },
  {
    "city": "Oyama",
    "city_ascii": "Oyama",
    "lat": 36.3147,
    "lng": 139.8003,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Tochigi",
    "capital": "",
    "population": 167633,
    "id": 1392122083
  },
  {
    "city": "Takaoka",
    "city_ascii": "Takaoka",
    "lat": 36.75,
    "lng": 137.0333,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Toyama",
    "capital": "",
    "population": 167800,
    "id": 1392754231
  },
  {
    "city": "Tanjungbalai",
    "city_ascii": "Tanjungbalai",
    "lat": 2.9667,
    "lng": 99.8,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Sumatera Utara",
    "capital": "",
    "population": 165763,
    "id": 1360992709
  },
  {
    "city": "Hŭich’ŏn",
    "city_ascii": "Huich'on",
    "lat": 40.1711,
    "lng": 126.2758,
    "country": "Korea, North",
    "iso2": "KP",
    "iso3": "PRK",
    "admin_name": "Chagang",
    "capital": "",
    "population": 168180,
    "id": 1408445793
  },
  {
    "city": "Uppsala",
    "city_ascii": "Uppsala",
    "lat": 59.8498,
    "lng": 17.6389,
    "country": "Sweden",
    "iso2": "SE",
    "iso3": "SWE",
    "admin_name": "Uppsala",
    "capital": "admin",
    "population": 164535,
    "id": 1752953686
  },
  {
    "city": "Itapecerica da Serra",
    "city_ascii": "Itapecerica da Serra",
    "lat": -23.7172,
    "lng": -46.8494,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 167236,
    "id": 1076163359
  },
  {
    "city": "São Caetano do Sul",
    "city_ascii": "Sao Caetano do Sul",
    "lat": -23.6228,
    "lng": -46.5508,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 158024,
    "id": 1076527920
  },
  {
    "city": "Zlatoust",
    "city_ascii": "Zlatoust",
    "lat": 55.1667,
    "lng": 59.6667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Chelyabinskaya Oblast’",
    "capital": "",
    "population": 167978,
    "id": 1643000102
  },
  {
    "city": "Rustenburg",
    "city_ascii": "Rustenburg",
    "lat": -25.65,
    "lng": 27.24,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "North West",
    "capital": "",
    "population": 165976,
    "id": 1710326039
  },
  {
    "city": "Pátra",
    "city_ascii": "Patra",
    "lat": 38.25,
    "lng": 21.7333,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Dytikí Elláda",
    "capital": "admin",
    "population": 167446,
    "id": 1300201099
  },
  {
    "city": "Ríohacha",
    "city_ascii": "Riohacha",
    "lat": 11.5442,
    "lng": -72.9069,
    "country": "Colombia",
    "iso2": "CO",
    "iso3": "COL",
    "admin_name": "La Guajira",
    "capital": "admin",
    "population": 167865,
    "id": 1170903069
  },
  {
    "city": "Nador",
    "city_ascii": "Nador",
    "lat": 35.1667,
    "lng": -2.9333,
    "country": "Morocco",
    "iso2": "MA",
    "iso3": "MAR",
    "admin_name": "Oriental",
    "capital": "",
    "population": 161726,
    "id": 1504811178
  },
  {
    "city": "Iwata",
    "city_ascii": "Iwata",
    "lat": 34.7178,
    "lng": 137.8514,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Shizuoka",
    "capital": "",
    "population": 166393,
    "id": 1392088261
  },
  {
    "city": "Gainesville",
    "city_ascii": "Gainesville",
    "lat": 34.2903,
    "lng": -83.8301,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 167371,
    "id": 1840013612
  },
  {
    "city": "Itu",
    "city_ascii": "Itu",
    "lat": -23.2642,
    "lng": -47.2992,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 167095,
    "id": 1076622789
  },
  {
    "city": "Fatehpur",
    "city_ascii": "Fatehpur",
    "lat": 25.8804,
    "lng": 80.8,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Uttar Pradesh",
    "capital": "",
    "population": 166480,
    "id": 1356059135
  },
  {
    "city": "‘Ar‘ar",
    "city_ascii": "`Ar`ar",
    "lat": 30.9833,
    "lng": 41.0167,
    "country": "Saudi Arabia",
    "iso2": "SA",
    "iso3": "SAU",
    "admin_name": "Al Ḩudūd ash Shamālīyah",
    "capital": "admin",
    "population": 167057,
    "id": 1682664517
  },
  {
    "city": "Hà Tĩnh",
    "city_ascii": "Ha Tinh",
    "lat": 18.3428,
    "lng": 105.9058,
    "country": "Vietnam",
    "iso2": "VN",
    "iso3": "VNM",
    "admin_name": "Hà Tĩnh",
    "capital": "admin",
    "population": 165396,
    "id": 1704544061
  },
  {
    "city": "Al Kūfah",
    "city_ascii": "Al Kufah",
    "lat": 32.03,
    "lng": 44.4,
    "country": "Iraq",
    "iso2": "IQ",
    "iso3": "IRQ",
    "admin_name": "An Najaf",
    "capital": "minor",
    "population": 166100,
    "id": 1368929021
  },
  {
    "city": "Kimberley",
    "city_ascii": "Kimberley",
    "lat": -28.7386,
    "lng": 24.7586,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "Northern Cape",
    "capital": "admin",
    "population": 165264,
    "id": 1710282622
  },
  {
    "city": "Tenāli",
    "city_ascii": "Tenali",
    "lat": 16.243,
    "lng": 80.64,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Andhra Pradesh",
    "capital": "",
    "population": 164937,
    "id": 1356831482
  },
  {
    "city": "Skikda",
    "city_ascii": "Skikda",
    "lat": 36.8667,
    "lng": 6.9,
    "country": "Algeria",
    "iso2": "DZ",
    "iso3": "DZA",
    "admin_name": "Skikda",
    "capital": "admin",
    "population": 163318,
    "id": 1012619903
  },
  {
    "city": "Nantou",
    "city_ascii": "Nantou",
    "lat": 23.9167,
    "lng": 120.6833,
    "country": "Taiwan",
    "iso2": "TW",
    "iso3": "TWN",
    "admin_name": "Nantou",
    "capital": "admin",
    "population": 165000,
    "id": 1158112865
  },
  {
    "city": "Siirt",
    "city_ascii": "Siirt",
    "lat": 37.8417,
    "lng": 41.9458,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Siirt",
    "capital": "admin",
    "population": 166332,
    "id": 1792190820
  },
  {
    "city": "Kuytun",
    "city_ascii": "Kuytun",
    "lat": 44.4196,
    "lng": 84.9012,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Xinjiang",
    "capital": "minor",
    "population": 166261,
    "id": 1156287095
  },
  {
    "city": "Osnabrück",
    "city_ascii": "Osnabruck",
    "lat": 52.2789,
    "lng": 8.0431,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "minor",
    "population": 164748,
    "id": 1276554949
  },
  {
    "city": "Rio Largo",
    "city_ascii": "Rio Largo",
    "lat": -9.4783,
    "lng": -35.8533,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Alagoas",
    "capital": "",
    "population": 158545,
    "id": 1076018887
  },
  {
    "city": "Körfez",
    "city_ascii": "Korfez",
    "lat": 40.7706,
    "lng": 29.7661,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Kocaeli",
    "capital": "minor",
    "population": 165503,
    "id": 1792917174
  },
  {
    "city": "Perugia",
    "city_ascii": "Perugia",
    "lat": 43.1121,
    "lng": 12.3888,
    "country": "Italy",
    "iso2": "IT",
    "iso3": "ITA",
    "admin_name": "Umbria",
    "capital": "admin",
    "population": 165683,
    "id": 1380939013
  },
  {
    "city": "Udipi",
    "city_ascii": "Udipi",
    "lat": 13.3322,
    "lng": 74.7461,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Karnātaka",
    "capital": "",
    "population": 165401,
    "id": 1356145399
  },
  {
    "city": "Oshawa",
    "city_ascii": "Oshawa",
    "lat": 43.9,
    "lng": -78.85,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 166000,
    "id": 1124541904
  },
  {
    "city": "Klaipėda",
    "city_ascii": "Klaipeda",
    "lat": 55.7075,
    "lng": 21.1428,
    "country": "Lithuania",
    "iso2": "LT",
    "iso3": "LTU",
    "admin_name": "Klaipėdos Miestas",
    "capital": "admin",
    "population": 164310,
    "id": 1440897790
  },
  {
    "city": "Leverkusen",
    "city_ascii": "Leverkusen",
    "lat": 51.0333,
    "lng": 6.9833,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "minor",
    "population": 163838,
    "id": 1276002465
  },
  {
    "city": "Tuluá",
    "city_ascii": "Tulua",
    "lat": 4.0904,
    "lng": -76.21,
    "country": "Colombia",
    "iso2": "CO",
    "iso3": "COL",
    "admin_name": "Valle del Cauca",
    "capital": "minor",
    "population": 165501,
    "id": 1170721938
  },
  {
    "city": "Idlib",
    "city_ascii": "Idlib",
    "lat": 35.9333,
    "lng": 36.6333,
    "country": "Syria",
    "iso2": "SY",
    "iso3": "SYR",
    "admin_name": "Idlib",
    "capital": "admin",
    "population": 165000,
    "id": 1760305230
  },
  {
    "city": "Warrington",
    "city_ascii": "Warrington",
    "lat": 53.3917,
    "lng": -2.5972,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Warrington",
    "capital": "",
    "population": 165456,
    "id": 1826261547
  },
  {
    "city": "Sitalpur",
    "city_ascii": "Sitalpur",
    "lat": 27.63,
    "lng": 80.75,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Uttar Pradesh",
    "capital": "",
    "population": 164435,
    "id": 1356838415
  },
  {
    "city": "Cienfuegos",
    "city_ascii": "Cienfuegos",
    "lat": 22.1456,
    "lng": -80.4364,
    "country": "Cuba",
    "iso2": "CU",
    "iso3": "CUB",
    "admin_name": "Cienfuegos",
    "capital": "admin",
    "population": 164924,
    "id": 1192020054
  },
  {
    "city": "Bayamón",
    "city_ascii": "Bayamon",
    "lat": 18.3793,
    "lng": -66.1635,
    "country": "Puerto Rico",
    "iso2": "PR",
    "iso3": "PRI",
    "admin_name": "Puerto Rico",
    "capital": "",
    "population": 165383,
    "id": 1630035622
  },
  {
    "city": "Proddatūr",
    "city_ascii": "Proddatur",
    "lat": 14.73,
    "lng": 78.55,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Andhra Pradesh",
    "capital": "",
    "population": 163970,
    "id": 1356025913
  },
  {
    "city": "Conjeeveram",
    "city_ascii": "Conjeeveram",
    "lat": 12.8308,
    "lng": 79.7078,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Tamil Nādu",
    "capital": "",
    "population": 164384,
    "id": 1356148941
  },
  {
    "city": "Saqqez",
    "city_ascii": "Saqqez",
    "lat": 36.2497,
    "lng": 46.2733,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Kordestān",
    "capital": "minor",
    "population": 165258,
    "id": 1364098155
  },
  {
    "city": "Dourados",
    "city_ascii": "Dourados",
    "lat": -22.23,
    "lng": -54.81,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Mato Grosso do Sul",
    "capital": "",
    "population": 162202,
    "id": 1076544482
  },
  {
    "city": "Huddersfield",
    "city_ascii": "Huddersfield",
    "lat": 53.645,
    "lng": -1.7798,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Kirklees",
    "capital": "",
    "population": 162949,
    "id": 1826672445
  },
  {
    "city": "Fukang",
    "city_ascii": "Fukang",
    "lat": 44.1523,
    "lng": 87.9793,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Xinjiang",
    "capital": "minor",
    "population": 165006,
    "id": 1156761099
  },
  {
    "city": "Ube",
    "city_ascii": "Ube",
    "lat": 33.9517,
    "lng": 131.2467,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Yamaguchi",
    "capital": "",
    "population": 164325,
    "id": 1392198814
  },
  {
    "city": "Slough",
    "city_ascii": "Slough",
    "lat": 51.51,
    "lng": -0.59,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Slough",
    "capital": "",
    "population": 164455,
    "id": 1826807636
  },
  {
    "city": "Bhisho",
    "city_ascii": "Bhisho",
    "lat": -32.8494,
    "lng": 27.4381,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "Eastern Cape",
    "capital": "admin",
    "population": 160997,
    "id": 1710672530
  },
  {
    "city": "Chillán",
    "city_ascii": "Chillan",
    "lat": -36.6067,
    "lng": -72.1033,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Biobío",
    "capital": "admin",
    "population": 164270,
    "id": 1152082093
  },
  {
    "city": "Enterprise",
    "city_ascii": "Enterprise",
    "lat": 36.0164,
    "lng": -115.2208,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Nevada",
    "capital": "",
    "population": 164314,
    "id": 1840033827
  },
  {
    "city": "Odense",
    "city_ascii": "Odense",
    "lat": 55.4004,
    "lng": 10.3833,
    "country": "Denmark",
    "iso2": "DK",
    "iso3": "DNK",
    "admin_name": "Syddanmark",
    "capital": "minor",
    "population": 158222,
    "id": 1208194249
  },
  {
    "city": "Metro",
    "city_ascii": "Metro",
    "lat": -5.1167,
    "lng": 105.3,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Lampung",
    "capital": "",
    "population": 161799,
    "id": 1360659228
  },
  {
    "city": "Ramat Gan",
    "city_ascii": "Ramat Gan",
    "lat": 32.0833,
    "lng": 34.8167,
    "country": "Israel",
    "iso2": "IL",
    "iso3": "ISR",
    "admin_name": "Tel Aviv",
    "capital": "",
    "population": 152596,
    "id": 1376357911
  },
  {
    "city": "Muridke",
    "city_ascii": "Muridke",
    "lat": 31.802,
    "lng": 74.255,
    "country": "Pakistan",
    "iso2": "PK",
    "iso3": "PAK",
    "admin_name": "Punjab",
    "capital": "",
    "population": 163268,
    "id": 1586205456
  },
  {
    "city": "Ipswich",
    "city_ascii": "Ipswich",
    "lat": -27.6167,
    "lng": 152.7667,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Queensland",
    "capital": "",
    "population": 163000,
    "id": 1036244388
  },
  {
    "city": "Ruse",
    "city_ascii": "Ruse",
    "lat": 43.8475,
    "lng": 25.9544,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Ruse",
    "capital": "admin",
    "population": 163712,
    "id": 1100854249
  },
  {
    "city": "Myingyan",
    "city_ascii": "Myingyan",
    "lat": 21.4618,
    "lng": 95.3914,
    "country": "Burma",
    "iso2": "MM",
    "iso3": "MMR",
    "admin_name": "Mandalay",
    "capital": "",
    "population": 163812,
    "id": 1104162015
  },
  {
    "city": "Chapecó",
    "city_ascii": "Chapeco",
    "lat": -27.1,
    "lng": -52.64,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Santa Catarina",
    "capital": "",
    "population": 160157,
    "id": 1076258255
  },
  {
    "city": "Tangjin",
    "city_ascii": "Tangjin",
    "lat": 36.8944,
    "lng": 126.6297,
    "country": "Korea, South",
    "iso2": "KR",
    "iso3": "KOR",
    "admin_name": "Chungnam",
    "capital": "",
    "population": 163762,
    "id": 1410304730
  },
  {
    "city": "Tema",
    "city_ascii": "Tema",
    "lat": 5.6667,
    "lng": -0.0167,
    "country": "Ghana",
    "iso2": "GH",
    "iso3": "GHA",
    "admin_name": "Greater Accra",
    "capital": "",
    "population": 161612,
    "id": 1288205885
  },
  {
    "city": "‘Unayzah",
    "city_ascii": "`Unayzah",
    "lat": 26.084,
    "lng": 43.994,
    "country": "Saudi Arabia",
    "iso2": "SA",
    "iso3": "SAU",
    "admin_name": "Al Qaşīm",
    "capital": "",
    "population": 163729,
    "id": 1682546519
  },
  {
    "city": "Barreiras",
    "city_ascii": "Barreiras",
    "lat": -12.1528,
    "lng": -44.99,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Bahia",
    "capital": "",
    "population": 158292,
    "id": 1076854674
  },
  {
    "city": "Piedras Negras",
    "city_ascii": "Piedras Negras",
    "lat": 28.7,
    "lng": -100.5231,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Coahuila de Zaragoza",
    "capital": "minor",
    "population": 163595,
    "id": 1484000400
  },
  {
    "city": "Itapetininga",
    "city_ascii": "Itapetininga",
    "lat": -23.5917,
    "lng": -48.0531,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 157016,
    "id": 1076425970
  },
  {
    "city": "Pocheon",
    "city_ascii": "Pocheon",
    "lat": 37.8944,
    "lng": 127.1992,
    "country": "Korea, South",
    "iso2": "KR",
    "iso3": "KOR",
    "admin_name": "Gyeonggi",
    "capital": "minor",
    "population": 163388,
    "id": 1410780187
  },
  {
    "city": "Turgutlu",
    "city_ascii": "Turgutlu",
    "lat": 38.5,
    "lng": 27.7,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Manisa",
    "capital": "minor",
    "population": 163223,
    "id": 1792010631
  },
  {
    "city": "Navsāri",
    "city_ascii": "Navsari",
    "lat": 20.8504,
    "lng": 72.92,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Gujarāt",
    "capital": "",
    "population": 163000,
    "id": 1356120513
  },
  {
    "city": "Wau",
    "city_ascii": "Wau",
    "lat": 7.7,
    "lng": 27.9833,
    "country": "South Sudan",
    "iso2": "SS",
    "iso3": "SSD",
    "admin_name": "Western Bahr el Ghazal",
    "capital": "admin",
    "population": 163442,
    "id": 1728877501
  },
  {
    "city": "Gölcük",
    "city_ascii": "Golcuk",
    "lat": 40.6667,
    "lng": 29.8333,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Kocaeli",
    "capital": "minor",
    "population": 162584,
    "id": 1792590945
  },
  {
    "city": "Muzaffargarh",
    "city_ascii": "Muzaffargarh",
    "lat": 30.0703,
    "lng": 71.1933,
    "country": "Pakistan",
    "iso2": "PK",
    "iso3": "PAK",
    "admin_name": "Punjab",
    "capital": "minor",
    "population": 163268,
    "id": 1586492401
  },
  {
    "city": "Jizzax",
    "city_ascii": "Jizzax",
    "lat": 40.1167,
    "lng": 67.85,
    "country": "Uzbekistan",
    "iso2": "UZ",
    "iso3": "UZB",
    "admin_name": "Jizzax",
    "capital": "admin",
    "population": 163200,
    "id": 1860218891
  },
  {
    "city": "Atyraū",
    "city_ascii": "Atyrau",
    "lat": 47.1167,
    "lng": 51.8833,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Atyraū",
    "capital": "admin",
    "population": 163221,
    "id": 1398169063
  },
  {
    "city": "Simao",
    "city_ascii": "Simao",
    "lat": 22.7807,
    "lng": 100.9782,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Yunnan",
    "capital": "minor",
    "population": 162725,
    "id": 1156008347
  },
  {
    "city": "Mostar",
    "city_ascii": "Mostar",
    "lat": 43.3494,
    "lng": 17.8125,
    "country": "Bosnia And Herzegovina",
    "iso2": "BA",
    "iso3": "BIH",
    "admin_name": "Bosnia and Herzegovina, Federation of",
    "capital": "minor",
    "population": 163067,
    "id": 1070000220
  },
  {
    "city": "El Geneina",
    "city_ascii": "El Geneina",
    "lat": 13.45,
    "lng": 22.44,
    "country": "Sudan",
    "iso2": "SD",
    "iso3": "SDN",
    "admin_name": "West Darfur",
    "capital": "admin",
    "population": 162981,
    "id": 1729642412
  },
  {
    "city": "Bandar-e Māhshahr",
    "city_ascii": "Bandar-e Mahshahr",
    "lat": 30.5589,
    "lng": 49.1981,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Khūzestān",
    "capital": "minor",
    "population": 162797,
    "id": 1364956793
  },
  {
    "city": "Manchester",
    "city_ascii": "Manchester",
    "lat": 42.9848,
    "lng": -71.4447,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Hampshire",
    "capital": "",
    "population": 162877,
    "id": 1840002983
  },
  {
    "city": "Anaco",
    "city_ascii": "Anaco",
    "lat": 9.4333,
    "lng": -64.4667,
    "country": "Venezuela",
    "iso2": "VE",
    "iso3": "VEN",
    "admin_name": "Anzoátegui",
    "capital": "minor",
    "population": 162704,
    "id": 1862074247
  },
  {
    "city": "Sullana",
    "city_ascii": "Sullana",
    "lat": -4.9,
    "lng": -80.7,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "Piura",
    "capital": "",
    "population": 162434,
    "id": 1604666111
  },
  {
    "city": "Godhra",
    "city_ascii": "Godhra",
    "lat": 22.7755,
    "lng": 73.6149,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Gujarāt",
    "capital": "",
    "population": 161925,
    "id": 1356386046
  },
  {
    "city": "Zemun",
    "city_ascii": "Zemun",
    "lat": 44.85,
    "lng": 20.4,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Beograd",
    "capital": "minor",
    "population": 161596,
    "id": 1688453076
  },
  {
    "city": "Mahābād",
    "city_ascii": "Mahabad",
    "lat": 36.7704,
    "lng": 45.72,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Āz̄arbāyjān-e Gharbī",
    "capital": "",
    "population": 162434,
    "id": 1364192213
  },
  {
    "city": "Budaun",
    "city_ascii": "Budaun",
    "lat": 28.03,
    "lng": 79.09,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Uttar Pradesh",
    "capital": "",
    "population": 161555,
    "id": 1356639213
  },
  {
    "city": "Chittoor",
    "city_ascii": "Chittoor",
    "lat": 13.2,
    "lng": 79.1167,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Andhra Pradesh",
    "capital": "",
    "population": 160722,
    "id": 1356026391
  },
  {
    "city": "Andong",
    "city_ascii": "Andong",
    "lat": 36.5656,
    "lng": 128.725,
    "country": "Korea, South",
    "iso2": "KR",
    "iso3": "KOR",
    "admin_name": "Gyeongbuk",
    "capital": "admin",
    "population": 162180,
    "id": 1410095935
  },
  {
    "city": "Ash Shaţrah",
    "city_ascii": "Ash Shatrah",
    "lat": 31.4175,
    "lng": 46.1772,
    "country": "Iraq",
    "iso2": "IQ",
    "iso3": "IRQ",
    "admin_name": "Dhī Qār",
    "capital": "minor",
    "population": 161949,
    "id": 1368920164
  },
  {
    "city": "Moçâmedes",
    "city_ascii": "Mocamedes",
    "lat": -15.1953,
    "lng": 12.1508,
    "country": "Angola",
    "iso2": "AO",
    "iso3": "AGO",
    "admin_name": "Namibe",
    "capital": "admin",
    "population": 162000,
    "id": 1024785067
  },
  {
    "city": "Rafsanjān",
    "city_ascii": "Rafsanjan",
    "lat": 30.4,
    "lng": 56,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Kermān",
    "capital": "minor",
    "population": 161909,
    "id": 1364354751
  },
  {
    "city": "Benoni",
    "city_ascii": "Benoni",
    "lat": -26.1883,
    "lng": 28.3206,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "Gauteng",
    "capital": "",
    "population": 158777,
    "id": 1710880986
  },
  {
    "city": "Heidelberg",
    "city_ascii": "Heidelberg",
    "lat": 49.4122,
    "lng": 8.71,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "minor",
    "population": 160355,
    "id": 1276519742
  },
  {
    "city": "Al Ghardaqah",
    "city_ascii": "Al Ghardaqah",
    "lat": 27.2578,
    "lng": 33.8117,
    "country": "Egypt",
    "iso2": "EG",
    "iso3": "EGY",
    "admin_name": "Al Baḩr al Aḩmar",
    "capital": "admin",
    "population": 160901,
    "id": 1818666245
  },
  {
    "city": "Salihli",
    "city_ascii": "Salihli",
    "lat": 38.4811,
    "lng": 28.1392,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Manisa",
    "capital": "minor",
    "population": 161562,
    "id": 1792382417
  },
  {
    "city": "Elektrostal",
    "city_ascii": "Elektrostal",
    "lat": 55.8,
    "lng": 38.45,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Moskovskaya Oblast’",
    "capital": "",
    "population": 158508,
    "id": 1643790299
  },
  {
    "city": "Bontang",
    "city_ascii": "Bontang",
    "lat": 0.1333,
    "lng": 117.5,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Kalimantan Timur",
    "capital": "",
    "population": 161413,
    "id": 1360692406
  },
  {
    "city": "Đồng Hới",
    "city_ascii": "Dong Hoi",
    "lat": 17.4833,
    "lng": 106.6,
    "country": "Vietnam",
    "iso2": "VN",
    "iso3": "VNM",
    "admin_name": "Quảng Bình",
    "capital": "admin",
    "population": 160325,
    "id": 1704323043
  },
  {
    "city": "Krishnanagar",
    "city_ascii": "Krishnanagar",
    "lat": 23.4,
    "lng": 88.5,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "West Bengal",
    "capital": "",
    "population": 153062,
    "id": 1356128689
  },
  {
    "city": "Szeged",
    "city_ascii": "Szeged",
    "lat": 46.25,
    "lng": 20.1667,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Csongrád",
    "capital": "admin",
    "population": 160766,
    "id": 1348655615
  },
  {
    "city": "Sherbrooke",
    "city_ascii": "Sherbrooke",
    "lat": 45.4,
    "lng": -71.9,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 161323,
    "id": 1124559506
  },
  {
    "city": "Solingen",
    "city_ascii": "Solingen",
    "lat": 51.1667,
    "lng": 7.0833,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "minor",
    "population": 159360,
    "id": 1276728190
  },
  {
    "city": "Tacheng",
    "city_ascii": "Tacheng",
    "lat": 46.7468,
    "lng": 82.979,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Xinjiang",
    "capital": "minor",
    "population": 161037,
    "id": 1156191514
  },
  {
    "city": "Dhamār",
    "city_ascii": "Dhamar",
    "lat": 14.55,
    "lng": 44.4017,
    "country": "Yemen",
    "iso2": "YE",
    "iso3": "YEM",
    "admin_name": "Dhamār",
    "capital": "admin",
    "population": 160114,
    "id": 1887135215
  },
  {
    "city": "José María Ezeiza",
    "city_ascii": "Jose Maria Ezeiza",
    "lat": -34.8333,
    "lng": -58.5167,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires",
    "capital": "minor",
    "population": 160219,
    "id": 1032337881
  },
  {
    "city": "Bragança Paulista",
    "city_ascii": "Braganca Paulista",
    "lat": -22.9189,
    "lng": -46.5425,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 160665,
    "id": 1076245528
  },
  {
    "city": "Ra’s al Khaymah",
    "city_ascii": "Ra's al Khaymah",
    "lat": 25.7915,
    "lng": 55.9428,
    "country": "United Arab Emirates",
    "iso2": "AE",
    "iso3": "ARE",
    "admin_name": "Ra’s al Khaymah",
    "capital": "admin",
    "population": 160849,
    "id": 1784053892
  },
  {
    "city": "Pindamonhangaba",
    "city_ascii": "Pindamonhangaba",
    "lat": -22.9239,
    "lng": -45.4617,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 160614,
    "id": 1076937468
  },
  {
    "city": "Dijon",
    "city_ascii": "Dijon",
    "lat": 47.3167,
    "lng": 5.0167,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Bourgogne-Franche-Comté",
    "capital": "admin",
    "population": 156920,
    "id": 1250505606
  },
  {
    "city": "Livorno",
    "city_ascii": "Livorno",
    "lat": 43.55,
    "lng": 10.3167,
    "country": "Italy",
    "iso2": "IT",
    "iso3": "ITA",
    "admin_name": "Tuscany",
    "capital": "minor",
    "population": 159020,
    "id": 1380159305
  },
  {
    "city": "Khōst",
    "city_ascii": "Khost",
    "lat": 33.3333,
    "lng": 69.9167,
    "country": "Afghanistan",
    "iso2": "AF",
    "iso3": "AFG",
    "admin_name": "Khōst",
    "capital": "admin",
    "population": 160214,
    "id": 1004919977
  },
  {
    "city": "Darmstadt",
    "city_ascii": "Darmstadt",
    "lat": 49.8667,
    "lng": 8.65,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "minor",
    "population": 159207,
    "id": 1276607395
  },
  {
    "city": "Saint-Marc",
    "city_ascii": "Saint-Marc",
    "lat": 19.1167,
    "lng": -72.7,
    "country": "Haiti",
    "iso2": "HT",
    "iso3": "HTI",
    "admin_name": "Artibonite",
    "capital": "",
    "population": 160181,
    "id": 1332084366
  },
  {
    "city": "Arad",
    "city_ascii": "Arad",
    "lat": 46.1667,
    "lng": 21.3167,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Arad",
    "capital": "admin",
    "population": 159074,
    "id": 1642996909
  },
  {
    "city": "Khanpur",
    "city_ascii": "Khanpur",
    "lat": 28.6471,
    "lng": 70.662,
    "country": "Pakistan",
    "iso2": "PK",
    "iso3": "PAK",
    "admin_name": "Punjab",
    "capital": "",
    "population": 160308,
    "id": 1586169401
  },
  {
    "city": "Matsuzaka",
    "city_ascii": "Matsuzaka",
    "lat": 34.5781,
    "lng": 136.5275,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Mie",
    "capital": "",
    "population": 160080,
    "id": 1392893621
  },
  {
    "city": "Taunggyi",
    "city_ascii": "Taunggyi",
    "lat": 20.7833,
    "lng": 96.9667,
    "country": "Burma",
    "iso2": "MM",
    "iso3": "MMR",
    "admin_name": "Shan State",
    "capital": "admin",
    "population": 160115,
    "id": 1104364374
  },
  {
    "city": "Bingöl",
    "city_ascii": "Bingol",
    "lat": 38.8861,
    "lng": 40.5017,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Bingöl",
    "capital": "admin",
    "population": 160165,
    "id": 1792920183
  },
  {
    "city": "San Nicolás de los Arroyos",
    "city_ascii": "San Nicolas de los Arroyos",
    "lat": -33.3333,
    "lng": -60.2167,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires",
    "capital": "minor",
    "population": 160000,
    "id": 1032067887
  },
  {
    "city": "Huaycan",
    "city_ascii": "Huaycan",
    "lat": -12.0139,
    "lng": -76.825,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "Lima",
    "capital": "",
    "population": 160000,
    "id": 1604578883
  },
  {
    "city": "Tiraspol",
    "city_ascii": "Tiraspol",
    "lat": 46.8319,
    "lng": 29.5767,
    "country": "Moldova",
    "iso2": "MD",
    "iso3": "MDA",
    "admin_name": "Stînga Nistrului",
    "capital": "admin",
    "population": 157000,
    "id": 1498104958
  },
  {
    "city": "Shahr-e Kord",
    "city_ascii": "Shahr-e Kord",
    "lat": 32.3256,
    "lng": 50.8644,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Chahār Maḩāl va Bakhtīārī",
    "capital": "admin",
    "population": 159775,
    "id": 1364643377
  },
  {
    "city": "Talas",
    "city_ascii": "Talas",
    "lat": 38.6833,
    "lng": 35.5667,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Kayseri",
    "capital": "minor",
    "population": 157695,
    "id": 1792443013
  },
  {
    "city": "Punta del Este",
    "city_ascii": "Punta del Este",
    "lat": -34.97,
    "lng": -54.95,
    "country": "Uruguay",
    "iso2": "UY",
    "iso3": "URY",
    "admin_name": "Maldonado",
    "capital": "",
    "population": 159000,
    "id": 1858267433
  },
  {
    "city": "Ekibastuz",
    "city_ascii": "Ekibastuz",
    "lat": 51.7298,
    "lng": 75.3266,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Pavlodar",
    "capital": "",
    "population": 159760,
    "id": 1398583266
  },
  {
    "city": "Trece Martires City",
    "city_ascii": "Trece Martires City",
    "lat": 14.2833,
    "lng": 120.8667,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Cavite",
    "capital": "admin",
    "population": 155713,
    "id": 1608990723
  },
  {
    "city": "Ōgaki",
    "city_ascii": "Ogaki",
    "lat": 35.3594,
    "lng": 136.6128,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Gifu",
    "capital": "",
    "population": 158911,
    "id": 1392727505
  },
  {
    "city": "Hat Yai",
    "city_ascii": "Hat Yai",
    "lat": 7.0167,
    "lng": 100.4667,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Songkhla",
    "capital": "minor",
    "population": 159130,
    "id": 1764161022
  },
  {
    "city": "Chirchiq",
    "city_ascii": "Chirchiq",
    "lat": 41.4667,
    "lng": 69.5833,
    "country": "Uzbekistan",
    "iso2": "UZ",
    "iso3": "UZB",
    "admin_name": "Toshkent",
    "capital": "",
    "population": 159400,
    "id": 1860343195
  },
  {
    "city": "Catamarca",
    "city_ascii": "Catamarca",
    "lat": -28.4686,
    "lng": -65.7792,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Catamarca",
    "capital": "admin",
    "population": 159139,
    "id": 1032260908
  },
  {
    "city": "Nakhodka",
    "city_ascii": "Nakhodka",
    "lat": 67.7504,
    "lng": 77.52,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Yamalo-Nenetskiy Avtonomnyy Okrug",
    "capital": "",
    "population": 159551,
    "id": 1643884797
  },
  {
    "city": "Jijiga",
    "city_ascii": "Jijiga",
    "lat": 9.35,
    "lng": 42.8,
    "country": "Ethiopia",
    "iso2": "ET",
    "iso3": "ETH",
    "admin_name": "Sumalē",
    "capital": "admin",
    "population": 159300,
    "id": 1231737909
  },
  {
    "city": "Kramatorsk",
    "city_ascii": "Kramatorsk",
    "lat": 48.7208,
    "lng": 37.5556,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Donets’ka Oblast’",
    "capital": "",
    "population": 159445,
    "id": 1804461469
  },
  {
    "city": "Champaign",
    "city_ascii": "Champaign",
    "lat": 40.1144,
    "lng": -88.2735,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 159443,
    "id": 1840007239
  },
  {
    "city": "Alexandria",
    "city_ascii": "Alexandria",
    "lat": 38.8185,
    "lng": -77.0861,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 159428,
    "id": 1840003837
  },
  {
    "city": "George",
    "city_ascii": "George",
    "lat": -33.9667,
    "lng": 22.45,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "Western Cape",
    "capital": "",
    "population": 157394,
    "id": 1710164136
  },
  {
    "city": "Herne",
    "city_ascii": "Herne",
    "lat": 51.5426,
    "lng": 7.219,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "minor",
    "population": 156374,
    "id": 1276656117
  },
  {
    "city": "Ravenna",
    "city_ascii": "Ravenna",
    "lat": 44.4178,
    "lng": 12.1994,
    "country": "Italy",
    "iso2": "IT",
    "iso3": "ITA",
    "admin_name": "Emilia-Romagna",
    "capital": "minor",
    "population": 159162,
    "id": 1380964180
  },
  {
    "city": "Zarzis",
    "city_ascii": "Zarzis",
    "lat": 33.5104,
    "lng": 11.1,
    "country": "Tunisia",
    "iso2": "TN",
    "iso3": "TUN",
    "admin_name": "Médenine",
    "capital": "",
    "population": 159161,
    "id": 1788933524
  },
  {
    "city": "Hayward",
    "city_ascii": "Hayward",
    "lat": 37.6328,
    "lng": -122.0772,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 159203,
    "id": 1840020293
  },
  {
    "city": "La Laguna",
    "city_ascii": "La Laguna",
    "lat": 28.48,
    "lng": -16.32,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Canary Islands",
    "capital": "",
    "population": 157503,
    "id": 1724625025
  },
  {
    "city": "Higashi-murayama",
    "city_ascii": "Higashi-murayama",
    "lat": 35.7547,
    "lng": 139.4686,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Tōkyō",
    "capital": "",
    "population": 150186,
    "id": 1392034645
  },
  {
    "city": "Saharsa",
    "city_ascii": "Saharsa",
    "lat": 25.88,
    "lng": 86.6,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Bihār",
    "capital": "",
    "population": 156540,
    "id": 1356492911
  },
  {
    "city": "Stara Zagora",
    "city_ascii": "Stara Zagora",
    "lat": 42.4247,
    "lng": 25.6256,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Stara Zagora",
    "capital": "admin",
    "population": 158668,
    "id": 1100903924
  },
  {
    "city": "Châu Đốc",
    "city_ascii": "Chau Doc",
    "lat": 10.7,
    "lng": 105.1167,
    "country": "Vietnam",
    "iso2": "VN",
    "iso3": "VNM",
    "admin_name": "An Giang",
    "capital": "",
    "population": 157298,
    "id": 1704020910
  },
  {
    "city": "Guimarães",
    "city_ascii": "Guimaraes",
    "lat": 41.445,
    "lng": -8.2908,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Braga",
    "capital": "minor",
    "population": 158124,
    "id": 1620771539
  },
  {
    "city": "Harīpur",
    "city_ascii": "Haripur",
    "lat": 31.52,
    "lng": 75.98,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Punjab",
    "capital": "",
    "population": 158142,
    "id": 1356141115
  },
  {
    "city": "Springfield",
    "city_ascii": "Springfield",
    "lat": 39.771,
    "lng": -89.6538,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "admin",
    "population": 158509,
    "id": 1840009517
  },
  {
    "city": "San Cristóbal",
    "city_ascii": "San Cristobal",
    "lat": 16.7367,
    "lng": -92.6383,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Chiapas",
    "capital": "minor",
    "population": 158027,
    "id": 1484451215
  },
  {
    "city": "Rafael Castillo",
    "city_ascii": "Rafael Castillo",
    "lat": -34.7167,
    "lng": -58.6167,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires",
    "capital": "",
    "population": 147965,
    "id": 1032812310
  },
  {
    "city": "Villanueva",
    "city_ascii": "Villanueva",
    "lat": 15.3167,
    "lng": -88.0167,
    "country": "Honduras",
    "iso2": "HN",
    "iso3": "HND",
    "admin_name": "Cortés",
    "capital": "",
    "population": 157615,
    "id": 1340079427
  },
  {
    "city": "Gojra",
    "city_ascii": "Gojra",
    "lat": 31.15,
    "lng": 72.6833,
    "country": "Pakistan",
    "iso2": "PK",
    "iso3": "PAK",
    "admin_name": "Punjab",
    "capital": "",
    "population": 157863,
    "id": 1586904918
  },
  {
    "city": "Lakewood",
    "city_ascii": "Lakewood",
    "lat": 39.6977,
    "lng": -105.1168,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 157935,
    "id": 1840020198
  },
  {
    "city": "Oxford",
    "city_ascii": "Oxford",
    "lat": 51.7519,
    "lng": -1.2578,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Oxfordshire",
    "capital": "",
    "population": 154600,
    "id": 1826689568
  },
  {
    "city": "Villeurbanne",
    "city_ascii": "Villeurbanne",
    "lat": 45.7667,
    "lng": 4.8803,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Auvergne-Rhône-Alpes",
    "capital": "",
    "population": 147712,
    "id": 1250002615
  },
  {
    "city": "Lafayette",
    "city_ascii": "Lafayette",
    "lat": 40.399,
    "lng": -86.8594,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 157820,
    "id": 1840008354
  },
  {
    "city": "Fethiye",
    "city_ascii": "Fethiye",
    "lat": 36.6206,
    "lng": 29.1142,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Muğla",
    "capital": "minor",
    "population": 157745,
    "id": 1792001223
  },
  {
    "city": "Ŭiwang",
    "city_ascii": "Uiwang",
    "lat": 37.3447,
    "lng": 126.9683,
    "country": "Korea, South",
    "iso2": "KR",
    "iso3": "KOR",
    "admin_name": "Gyeonggi",
    "capital": "",
    "population": 154879,
    "id": 1410211816
  },
  {
    "city": "Navojoa",
    "city_ascii": "Navojoa",
    "lat": 27.0813,
    "lng": -109.4461,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Sonora",
    "capital": "minor",
    "population": 157729,
    "id": 1484166303
  },
  {
    "city": "Calama",
    "city_ascii": "Calama",
    "lat": -22.4624,
    "lng": -68.9272,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Antofagasta",
    "capital": "minor",
    "population": 157575,
    "id": 1152168074
  },
  {
    "city": "Erzincan",
    "city_ascii": "Erzincan",
    "lat": 39.7464,
    "lng": 39.4914,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Erzincan",
    "capital": "admin",
    "population": 157452,
    "id": 1792742754
  },
  {
    "city": "Middelburg",
    "city_ascii": "Middelburg",
    "lat": -25.7684,
    "lng": 29.4783,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "Mpumalanga",
    "capital": "",
    "population": 154706,
    "id": 1710495632
  },
  {
    "city": "Mandi Bahauddin",
    "city_ascii": "Mandi Bahauddin",
    "lat": 32.5861,
    "lng": 73.4917,
    "country": "Pakistan",
    "iso2": "PK",
    "iso3": "PAK",
    "admin_name": "Punjab",
    "capital": "minor",
    "population": 157352,
    "id": 1586202018
  },
  {
    "city": "Zonguldak",
    "city_ascii": "Zonguldak",
    "lat": 41.4304,
    "lng": 31.78,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Zonguldak",
    "capital": "admin",
    "population": 156918,
    "id": 1792410633
  },
  {
    "city": "Hitachi-Naka",
    "city_ascii": "Hitachi-Naka",
    "lat": 36.3964,
    "lng": 140.5344,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Ibaraki",
    "capital": "",
    "population": 155680,
    "id": 1392134860
  },
  {
    "city": "Isiro",
    "city_ascii": "Isiro",
    "lat": 2.7833,
    "lng": 27.6167,
    "country": "Congo (Kinshasa)",
    "iso2": "CD",
    "iso3": "COD",
    "admin_name": "Haut-Uélé",
    "capital": "admin",
    "population": 157196,
    "id": 1180183850
  },
  {
    "city": "Jinggangshan",
    "city_ascii": "Jinggangshan",
    "lat": 26.5736,
    "lng": 114.1639,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Jiangxi",
    "capital": "",
    "population": 157000,
    "id": 1156451783
  },
  {
    "city": "Jiutepec",
    "city_ascii": "Jiutepec",
    "lat": 18.8833,
    "lng": -99.1667,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Morelos",
    "capital": "minor",
    "population": 153704,
    "id": 1484001709
  },
  {
    "city": "Batumi",
    "city_ascii": "Batumi",
    "lat": 41.6458,
    "lng": 41.6417,
    "country": "Georgia",
    "iso2": "GE",
    "iso3": "GEO",
    "admin_name": "Ajaria",
    "capital": "admin",
    "population": 154600,
    "id": 1268193611
  },
  {
    "city": "Pathānkot",
    "city_ascii": "Pathankot",
    "lat": 32.2689,
    "lng": 75.6497,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Punjab",
    "capital": "",
    "population": 155909,
    "id": 1356878237
  },
  {
    "city": "Nazilli",
    "city_ascii": "Nazilli",
    "lat": 37.9125,
    "lng": 28.3206,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Aydın",
    "capital": "minor",
    "population": 156748,
    "id": 1792517713
  },
  {
    "city": "Frederick",
    "city_ascii": "Frederick",
    "lat": 39.4336,
    "lng": -77.4141,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 156787,
    "id": 1840005710
  },
  {
    "city": "Lajes",
    "city_ascii": "Lajes",
    "lat": -27.8158,
    "lng": -50.3258,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Santa Catarina",
    "capital": "",
    "population": 156727,
    "id": 1076517475
  },
  {
    "city": "Piteşti",
    "city_ascii": "Pitesti",
    "lat": 44.8667,
    "lng": 24.8833,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Argeş",
    "capital": "admin",
    "population": 155383,
    "id": 1642781938
  },
  {
    "city": "Chlef",
    "city_ascii": "Chlef",
    "lat": 36.1647,
    "lng": 1.3317,
    "country": "Algeria",
    "iso2": "DZ",
    "iso3": "DZA",
    "admin_name": "Chlef",
    "capital": "admin",
    "population": 155134,
    "id": 1012772239
  },
  {
    "city": "Chetumal",
    "city_ascii": "Chetumal",
    "lat": 18.5036,
    "lng": -88.3053,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Quintana Roo",
    "capital": "admin",
    "population": 151243,
    "id": 1484338031
  },
  {
    "city": "Rondonópolis",
    "city_ascii": "Rondonopolis",
    "lat": -16.4695,
    "lng": -54.64,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Mato Grosso",
    "capital": "",
    "population": 152912,
    "id": 1076627175
  },
  {
    "city": "Esmeraldas",
    "city_ascii": "Esmeraldas",
    "lat": 0.95,
    "lng": -79.6667,
    "country": "Ecuador",
    "iso2": "EC",
    "iso3": "ECU",
    "admin_name": "Esmeraldas",
    "capital": "admin",
    "population": 154035,
    "id": 1218601363
  },
  {
    "city": "Angers",
    "city_ascii": "Angers",
    "lat": 47.4736,
    "lng": -0.5542,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Pays de la Loire",
    "capital": "minor",
    "population": 152960,
    "id": 1250962174
  },
  {
    "city": "Hanam",
    "city_ascii": "Hanam",
    "lat": 37.5392,
    "lng": 127.2147,
    "country": "Korea, South",
    "iso2": "KR",
    "iso3": "KOR",
    "admin_name": "Gyeonggi",
    "capital": "",
    "population": 154838,
    "id": 1410241338
  },
  {
    "city": "Daiwanishi",
    "city_ascii": "Daiwanishi",
    "lat": 34.83,
    "lng": 135.4172,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hyōgo",
    "capital": "",
    "population": 153597,
    "id": 1392478155
  },
  {
    "city": "Matagalpa",
    "city_ascii": "Matagalpa",
    "lat": 12.9167,
    "lng": -85.9167,
    "country": "Nicaragua",
    "iso2": "NI",
    "iso3": "NIC",
    "admin_name": "Matagalpa",
    "capital": "admin",
    "population": 156218,
    "id": 1558728443
  },
  {
    "city": "Salzburg",
    "city_ascii": "Salzburg",
    "lat": 47.7972,
    "lng": 13.0477,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Salzburg",
    "capital": "admin",
    "population": 154076,
    "id": 1040312147
  },
  {
    "city": "Vidisha",
    "city_ascii": "Vidisha",
    "lat": 23.5239,
    "lng": 77.8061,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Madhya Pradesh",
    "capital": "",
    "population": 155959,
    "id": 1356299932
  },
  {
    "city": "Cẩm Phả",
    "city_ascii": "Cam Pha",
    "lat": 21.0071,
    "lng": 107.2845,
    "country": "Vietnam",
    "iso2": "VN",
    "iso3": "VNM",
    "admin_name": "Quảng Ninh",
    "capital": "minor",
    "population": 156000,
    "id": 1704985016
  },
  {
    "city": "Kariya",
    "city_ascii": "Kariya",
    "lat": 34.9833,
    "lng": 137,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Aichi",
    "capital": "",
    "population": 153190,
    "id": 1392620119
  },
  {
    "city": "Tochigi",
    "city_ascii": "Tochigi",
    "lat": 36.3824,
    "lng": 139.7341,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Tochigi",
    "capital": "",
    "population": 155714,
    "id": 1392660923
  },
  {
    "city": "Lake Charles",
    "city_ascii": "Lake Charles",
    "lat": 30.2012,
    "lng": -93.2122,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 156181,
    "id": 1840015039
  },
  {
    "city": "Odessa",
    "city_ascii": "Odessa",
    "lat": 31.8831,
    "lng": -102.3406,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 155998,
    "id": 1840020794
  },
  {
    "city": "Nalgonda",
    "city_ascii": "Nalgonda",
    "lat": 17.05,
    "lng": 79.27,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Telangana",
    "capital": "",
    "population": 154326,
    "id": 1356414359
  },
  {
    "city": "Ordu",
    "city_ascii": "Ordu",
    "lat": 40.9833,
    "lng": 37.8833,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Ordu",
    "capital": "admin",
    "population": 155117,
    "id": 1792540951
  },
  {
    "city": "San Cristóbal",
    "city_ascii": "San Cristobal",
    "lat": 18.416,
    "lng": -70.109,
    "country": "Dominican Republic",
    "iso2": "DO",
    "iso3": "DOM",
    "admin_name": "Valdesia",
    "capital": "admin",
    "population": 154040,
    "id": 1214335207
  },
  {
    "city": "Tuscaloosa",
    "city_ascii": "Tuscaloosa",
    "lat": 33.2348,
    "lng": -87.5267,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 155499,
    "id": 1840005563
  },
  {
    "city": "Horad Barysaw",
    "city_ascii": "Horad Barysaw",
    "lat": 54.226,
    "lng": 28.4922,
    "country": "Belarus",
    "iso2": "BY",
    "iso3": "BLR",
    "admin_name": "Minskaya Voblasts’",
    "capital": "minor",
    "population": 155389,
    "id": 1112659163
  },
  {
    "city": "Neuss",
    "city_ascii": "Neuss",
    "lat": 51.2003,
    "lng": 6.6939,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "minor",
    "population": 153796,
    "id": 1276075502
  },
  {
    "city": "Miskolc",
    "city_ascii": "Miskolc",
    "lat": 48.1,
    "lng": 20.7833,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Borsod-Abaúj-Zemplén",
    "capital": "admin",
    "population": 154521,
    "id": 1348668815
  },
  {
    "city": "Warner Robins",
    "city_ascii": "Warner Robins",
    "lat": 32.597,
    "lng": -83.6529,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 155140,
    "id": 1840015800
  },
  {
    "city": "Bartın",
    "city_ascii": "Bartin",
    "lat": 41.6344,
    "lng": 32.3375,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Bartın",
    "capital": "admin",
    "population": 155016,
    "id": 1792863603
  },
  {
    "city": "Palmdale",
    "city_ascii": "Palmdale",
    "lat": 34.5944,
    "lng": -118.1057,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 155079,
    "id": 1840020502
  },
  {
    "city": "Melitopol’",
    "city_ascii": "Melitopol'",
    "lat": 46.8333,
    "lng": 35.3667,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Zaporiz’ka Oblast’",
    "capital": "minor",
    "population": 154992,
    "id": 1804906240
  },
  {
    "city": "Zango",
    "city_ascii": "Zango",
    "lat": 12.9333,
    "lng": 8.5333,
    "country": "Nigeria",
    "iso2": "NG",
    "iso3": "NGA",
    "admin_name": "Katsina",
    "capital": "minor",
    "population": 154743,
    "id": 1566483135
  },
  {
    "city": "Tama",
    "city_ascii": "Tama",
    "lat": 35.6333,
    "lng": 139.45,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Tōkyō",
    "capital": "",
    "population": 147832,
    "id": 1392092333
  },
  {
    "city": "Hollywood",
    "city_ascii": "Hollywood",
    "lat": 26.0294,
    "lng": -80.1679,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 154817,
    "id": 1840015144
  },
  {
    "city": "Bālurghāt",
    "city_ascii": "Balurghat",
    "lat": 25.2167,
    "lng": 88.7667,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "West Bengal",
    "capital": "",
    "population": 153279,
    "id": 1356496150
  },
  {
    "city": "Midland",
    "city_ascii": "Midland",
    "lat": 32.0249,
    "lng": -102.1137,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 154793,
    "id": 1840020792
  },
  {
    "city": "Surigao",
    "city_ascii": "Surigao",
    "lat": 9.7833,
    "lng": 125.4833,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Surigao del Norte",
    "capital": "admin",
    "population": 154137,
    "id": 1608611556
  },
  {
    "city": "Dibrugarh",
    "city_ascii": "Dibrugarh",
    "lat": 27.4833,
    "lng": 95,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Assam",
    "capital": "",
    "population": 154019,
    "id": 1356100193
  },
  {
    "city": "Mahajanga",
    "city_ascii": "Mahajanga",
    "lat": -15.67,
    "lng": 46.345,
    "country": "Madagascar",
    "iso2": "MG",
    "iso3": "MDG",
    "admin_name": "Mahajanga",
    "capital": "admin",
    "population": 154657,
    "id": 1450421961
  },
  {
    "city": "Salavat",
    "city_ascii": "Salavat",
    "lat": 53.3667,
    "lng": 55.9333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Bashkortostan",
    "capital": "",
    "population": 153181,
    "id": 1643996483
  },
  {
    "city": "Bandırma",
    "city_ascii": "Bandirma",
    "lat": 40.3542,
    "lng": 27.9725,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Balıkesir",
    "capital": "minor",
    "population": 154359,
    "id": 1792423107
  },
  {
    "city": "Leesburg",
    "city_ascii": "Leesburg",
    "lat": 28.7657,
    "lng": -81.8996,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 154574,
    "id": 1840015083
  },
  {
    "city": "Tuguegarao",
    "city_ascii": "Tuguegarao",
    "lat": 17.6167,
    "lng": 121.7167,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Cagayan",
    "capital": "admin",
    "population": 153502,
    "id": 1608110634
  },
  {
    "city": "Gandajika",
    "city_ascii": "Gandajika",
    "lat": -6.75,
    "lng": 23.9667,
    "country": "Congo (Kinshasa)",
    "iso2": "CD",
    "iso3": "COD",
    "admin_name": "Lomami",
    "capital": "",
    "population": 154425,
    "id": 1180466776
  },
  {
    "city": "Silao",
    "city_ascii": "Silao",
    "lat": 20.9478,
    "lng": -101.4281,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Guanajuato",
    "capital": "minor",
    "population": 147123,
    "id": 1484276023
  },
  {
    "city": "Regensburg",
    "city_ascii": "Regensburg",
    "lat": 49.0167,
    "lng": 12.0833,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "minor",
    "population": 152610,
    "id": 1276000325
  },
  {
    "city": "Ueda",
    "city_ascii": "Ueda",
    "lat": 36.4019,
    "lng": 138.2492,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Nagano",
    "capital": "",
    "population": 154201,
    "id": 1392438902
  },
  {
    "city": "Port Arthur",
    "city_ascii": "Port Arthur",
    "lat": 29.8554,
    "lng": -93.9264,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 154468,
    "id": 1840020919
  },
  {
    "city": "York",
    "city_ascii": "York",
    "lat": 53.9583,
    "lng": -1.0803,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "York",
    "capital": "",
    "population": 153717,
    "id": 1826149980
  },
  {
    "city": "Palo Negro",
    "city_ascii": "Palo Negro",
    "lat": 10.16,
    "lng": -67.5582,
    "country": "Venezuela",
    "iso2": "VE",
    "iso3": "VEN",
    "admin_name": "Aragua",
    "capital": "minor",
    "population": 153706,
    "id": 1862061949
  },
  {
    "city": "Almetyevsk",
    "city_ascii": "Almetyevsk",
    "lat": 54.9,
    "lng": 52.3,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Tatarstan",
    "capital": "",
    "population": 154262,
    "id": 1643421011
  },
  {
    "city": "Kafr ash Shaykh",
    "city_ascii": "Kafr ash Shaykh",
    "lat": 31.1,
    "lng": 30.95,
    "country": "Egypt",
    "iso2": "EG",
    "iso3": "EGY",
    "admin_name": "Kafr ash Shaykh",
    "capital": "admin",
    "population": 147393,
    "id": 1818743040
  },
  {
    "city": "Noda",
    "city_ascii": "Noda",
    "lat": 35.955,
    "lng": 139.8747,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Chiba",
    "capital": "",
    "population": 152652,
    "id": 1392224794
  },
  {
    "city": "Seogwipo",
    "city_ascii": "Seogwipo",
    "lat": 33.2497,
    "lng": 126.56,
    "country": "Korea, South",
    "iso2": "KR",
    "iso3": "KOR",
    "admin_name": "Jeju",
    "capital": "",
    "population": 153861,
    "id": 1410542763
  },
  {
    "city": "Moanda",
    "city_ascii": "Moanda",
    "lat": -5.9229,
    "lng": 12.355,
    "country": "Congo (Kinshasa)",
    "iso2": "CD",
    "iso3": "COD",
    "admin_name": "Kongo Central",
    "capital": "",
    "population": 153915,
    "id": 1180108441
  },
  {
    "city": "Tete",
    "city_ascii": "Tete",
    "lat": -16.1579,
    "lng": 33.5898,
    "country": "Mozambique",
    "iso2": "MZ",
    "iso3": "MOZ",
    "admin_name": "Tete",
    "capital": "admin",
    "population": 152909,
    "id": 1508476900
  },
  {
    "city": "Mzuzu",
    "city_ascii": "Mzuzu",
    "lat": -11.45,
    "lng": 34.0333,
    "country": "Malawi",
    "iso2": "MW",
    "iso3": "MWI",
    "admin_name": "Mzimba",
    "capital": "",
    "population": 150100,
    "id": 1454525586
  },
  {
    "city": "Fyzābād",
    "city_ascii": "Fyzabad",
    "lat": 26.7504,
    "lng": 82.17,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Uttar Pradesh",
    "capital": "",
    "population": 153047,
    "id": 1356032860
  },
  {
    "city": "Harrow",
    "city_ascii": "Harrow",
    "lat": 51.5836,
    "lng": -0.3464,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Harrow",
    "capital": "",
    "population": 149246,
    "id": 1826648099
  },
  {
    "city": "Poole",
    "city_ascii": "Poole",
    "lat": 50.7167,
    "lng": -1.9833,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Poole",
    "capital": "",
    "population": 151500,
    "id": 1826379738
  },
  {
    "city": "Semnān",
    "city_ascii": "Semnan",
    "lat": 35.5833,
    "lng": 53.3833,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Semnān",
    "capital": "admin",
    "population": 153680,
    "id": 1364417512
  },
  {
    "city": "Hoeryŏng",
    "city_ascii": "Hoeryong",
    "lat": 42.4333,
    "lng": 129.75,
    "country": "Korea, North",
    "iso2": "KP",
    "iso3": "PRK",
    "admin_name": "Hambuk",
    "capital": "minor",
    "population": 153532,
    "id": 1408656165
  },
  {
    "city": "Kırşehir",
    "city_ascii": "Kirsehir",
    "lat": 39.145,
    "lng": 34.1608,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Kırşehir",
    "capital": "admin",
    "population": 153511,
    "id": 1792825471
  },
  {
    "city": "Muskegon",
    "city_ascii": "Muskegon",
    "lat": 43.2281,
    "lng": -86.2563,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 153565,
    "id": 1840002870
  },
  {
    "city": "Sievierodonetsk",
    "city_ascii": "Sievierodonetsk",
    "lat": 48.95,
    "lng": 38.4833,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Luhans’ka Oblast’",
    "capital": "",
    "population": 150000,
    "id": 1804577024
  },
  {
    "city": "Guarapuava",
    "city_ascii": "Guarapuava",
    "lat": -25.38,
    "lng": -51.48,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Paraná",
    "capital": "",
    "population": 150850,
    "id": 1076415327
  },
  {
    "city": "Sinp’o",
    "city_ascii": "Sinp'o",
    "lat": 40.0833,
    "lng": 128.25,
    "country": "Korea, North",
    "iso2": "KP",
    "iso3": "PRK",
    "admin_name": "Hamnam",
    "capital": "",
    "population": 152759,
    "id": 1408269466
  },
  {
    "city": "Silchar",
    "city_ascii": "Silchar",
    "lat": 24.7904,
    "lng": 92.79,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Assam",
    "capital": "",
    "population": 152393,
    "id": 1356102467
  },
  {
    "city": "Macon",
    "city_ascii": "Macon",
    "lat": 32.8065,
    "lng": -83.6974,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 153159,
    "id": 1840043455
  },
  {
    "city": "Kökshetaū",
    "city_ascii": "Kokshetau",
    "lat": 53.2824,
    "lng": 69.3969,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Aqmola",
    "capital": "admin",
    "population": 153057,
    "id": 1398375387
  },
  {
    "city": "Macaé",
    "city_ascii": "Macae",
    "lat": -22.38,
    "lng": -41.79,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Rio de Janeiro",
    "capital": "",
    "population": 143029,
    "id": 1076074287
  },
  {
    "city": "Cairns",
    "city_ascii": "Cairns",
    "lat": -16.9303,
    "lng": 145.7703,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Queensland",
    "capital": "",
    "population": 152729,
    "id": 1036016939
  },
  {
    "city": "Yilan",
    "city_ascii": "Yilan",
    "lat": 24.75,
    "lng": 121.75,
    "country": "Taiwan",
    "iso2": "TW",
    "iso3": "TWN",
    "admin_name": "Yilan",
    "capital": "admin",
    "population": 150000,
    "id": 1158534780
  },
  {
    "city": "Papantla de Olarte",
    "city_ascii": "Papantla de Olarte",
    "lat": 20.4478,
    "lng": -97.32,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Veracruz",
    "capital": "minor",
    "population": 152863,
    "id": 1484844355
  },
  {
    "city": "Kansas City",
    "city_ascii": "Kansas City",
    "lat": 39.1234,
    "lng": -94.7443,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kansas",
    "capital": "",
    "population": 152960,
    "id": 1840001626
  },
  {
    "city": "Ciudad de Melilla",
    "city_ascii": "Ciudad de Melilla",
    "lat": 35.2937,
    "lng": -2.9383,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Melilla",
    "capital": "",
    "population": 141308,
    "id": 1724144891
  },
  {
    "city": "Shāntipur",
    "city_ascii": "Shantipur",
    "lat": 23.25,
    "lng": 88.43,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "West Bengal",
    "capital": "",
    "population": 151777,
    "id": 1356584320
  },
  {
    "city": "Kukichūō",
    "city_ascii": "Kukichuo",
    "lat": 36.0622,
    "lng": 139.6669,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Saitama",
    "capital": "",
    "population": 151106,
    "id": 1392642279
  },
  {
    "city": "Golmeh",
    "city_ascii": "Golmeh",
    "lat": 33.6304,
    "lng": 46.43,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Īlām",
    "capital": "",
    "population": 152894,
    "id": 1364909317
  },
  {
    "city": "Zabīd",
    "city_ascii": "Zabid",
    "lat": 14.1951,
    "lng": 43.3155,
    "country": "Yemen",
    "iso2": "YE",
    "iso3": "YEM",
    "admin_name": "Al Ḩudaydah",
    "capital": "minor",
    "population": 152504,
    "id": 1887877995
  },
  {
    "city": "Peristéri",
    "city_ascii": "Peristeri",
    "lat": 38.0167,
    "lng": 23.6833,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Attikí",
    "capital": "minor",
    "population": 139981,
    "id": 1300302779
  },
  {
    "city": "Hindupur",
    "city_ascii": "Hindupur",
    "lat": 13.83,
    "lng": 77.49,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Andhra Pradesh",
    "capital": "",
    "population": 151677,
    "id": 1356805611
  },
  {
    "city": "Sunnyvale",
    "city_ascii": "Sunnyvale",
    "lat": 37.3836,
    "lng": -122.0256,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 152703,
    "id": 1840021573
  },
  {
    "city": "Baubau",
    "city_ascii": "Baubau",
    "lat": -5.4667,
    "lng": 122.633,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Sulawesi Tenggara",
    "capital": "",
    "population": 152143,
    "id": 1360789994
  },
  {
    "city": "Mudon",
    "city_ascii": "Mudon",
    "lat": 16.2618,
    "lng": 97.7215,
    "country": "Burma",
    "iso2": "MM",
    "iso3": "MMR",
    "admin_name": "Mon State",
    "capital": "",
    "population": 152300,
    "id": 1104174785
  },
  {
    "city": "Sayama",
    "city_ascii": "Sayama",
    "lat": 35.8531,
    "lng": 139.4122,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Saitama",
    "capital": "",
    "population": 149423,
    "id": 1392752018
  },
  {
    "city": "Taza",
    "city_ascii": "Taza",
    "lat": 34.2144,
    "lng": -4.0088,
    "country": "Morocco",
    "iso2": "MA",
    "iso3": "MAR",
    "admin_name": "Fès-Meknès",
    "capital": "",
    "population": 148456,
    "id": 1504405154
  },
  {
    "city": "Settat",
    "city_ascii": "Settat",
    "lat": 33.0023,
    "lng": -7.6198,
    "country": "Morocco",
    "iso2": "MA",
    "iso3": "MAR",
    "admin_name": "Casablanca-Settat",
    "capital": "",
    "population": 142250,
    "id": 1504720478
  },
  {
    "city": "Imabari",
    "city_ascii": "Imabari",
    "lat": 34.0661,
    "lng": 132.9978,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Ehime",
    "capital": "",
    "population": 151956,
    "id": 1392801732
  },
  {
    "city": "Foggia",
    "city_ascii": "Foggia",
    "lat": 41.4584,
    "lng": 15.5519,
    "country": "Italy",
    "iso2": "IT",
    "iso3": "ITA",
    "admin_name": "Puglia",
    "capital": "minor",
    "population": 151975,
    "id": 1380084447
  },
  {
    "city": "Erode",
    "city_ascii": "Erode",
    "lat": 11.3408,
    "lng": 77.7172,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Tamil Nādu",
    "capital": "",
    "population": 151184,
    "id": 1356040695
  },
  {
    "city": "Poços de Caldas",
    "city_ascii": "Pocos de Caldas",
    "lat": -21.78,
    "lng": -46.57,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Minas Gerais",
    "capital": "",
    "population": 146588,
    "id": 1076336113
  },
  {
    "city": "Gwangyang",
    "city_ascii": "Gwangyang",
    "lat": 34.9403,
    "lng": 127.7017,
    "country": "Korea, South",
    "iso2": "KR",
    "iso3": "KOR",
    "admin_name": "Jeonnam",
    "capital": "minor",
    "population": 151834,
    "id": 1410398748
  },
  {
    "city": "Tororo",
    "city_ascii": "Tororo",
    "lat": 0.6928,
    "lng": 34.1808,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Tororo",
    "capital": "admin",
    "population": 150000,
    "id": 1800214991
  },
  {
    "city": "Gonbad-e Kāvūs",
    "city_ascii": "Gonbad-e Kavus",
    "lat": 37.25,
    "lng": 55.1672,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Golestān",
    "capital": "minor",
    "population": 151910,
    "id": 1364336980
  },
  {
    "city": "Al Manāqil",
    "city_ascii": "Al Manaqil",
    "lat": 14.2504,
    "lng": 32.98,
    "country": "Sudan",
    "iso2": "SD",
    "iso3": "SDN",
    "admin_name": "Al Gazira",
    "capital": "",
    "population": 151827,
    "id": 1729742500
  },
  {
    "city": "Miass",
    "city_ascii": "Miass",
    "lat": 55.05,
    "lng": 60.1,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Chelyabinskaya Oblast’",
    "capital": "",
    "population": 151856,
    "id": 1643043679
  },
  {
    "city": "Tulancingo",
    "city_ascii": "Tulancingo",
    "lat": 20.0833,
    "lng": -98.3667,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Hidalgo",
    "capital": "minor",
    "population": 151582,
    "id": 1484419416
  },
  {
    "city": "Nîmes",
    "city_ascii": "Nimes",
    "lat": 43.838,
    "lng": 4.361,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Occitanie",
    "capital": "minor",
    "population": 151001,
    "id": 1250417467
  },
  {
    "city": "Chinandega",
    "city_ascii": "Chinandega",
    "lat": 12.6242,
    "lng": -87.1297,
    "country": "Nicaragua",
    "iso2": "NI",
    "iso3": "NIC",
    "admin_name": "Chinandega",
    "capital": "admin",
    "population": 151707,
    "id": 1558172276
  },
  {
    "city": "Saint Albans",
    "city_ascii": "Saint Albans",
    "lat": 51.755,
    "lng": -0.336,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hertfordshire",
    "capital": "",
    "population": 147373,
    "id": 1826053841
  },
  {
    "city": "Nakhodka",
    "city_ascii": "Nakhodka",
    "lat": 42.8167,
    "lng": 132.8833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Primorskiy Kray",
    "capital": "",
    "population": 151420,
    "id": 1643144321
  },
  {
    "city": "Quevedo",
    "city_ascii": "Quevedo",
    "lat": -1.0333,
    "lng": -79.45,
    "country": "Ecuador",
    "iso2": "EC",
    "iso3": "ECU",
    "admin_name": "Los Ríos",
    "capital": "",
    "population": 150827,
    "id": 1218928435
  },
  {
    "city": "Bintulu",
    "city_ascii": "Bintulu",
    "lat": 3.17,
    "lng": 113.03,
    "country": "Malaysia",
    "iso2": "MY",
    "iso3": "MYS",
    "admin_name": "Sarawak",
    "capital": "",
    "population": 151617,
    "id": 1458164361
  },
  {
    "city": "Pomona",
    "city_ascii": "Pomona",
    "lat": 34.0585,
    "lng": -117.7626,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 151691,
    "id": 1840020507
  },
  {
    "city": "Siem Reap",
    "city_ascii": "Siem Reap",
    "lat": 13.3622,
    "lng": 103.8597,
    "country": "Cambodia",
    "iso2": "KH",
    "iso3": "KHM",
    "admin_name": "Siem Reap",
    "capital": "admin",
    "population": 147866,
    "id": 1116263015
  },
  {
    "city": "Escondido",
    "city_ascii": "Escondido",
    "lat": 33.1347,
    "lng": -117.0722,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 151625,
    "id": 1840020620
  },
  {
    "city": "Cao Lãnh",
    "city_ascii": "Cao Lanh",
    "lat": 10.4603,
    "lng": 105.6331,
    "country": "Vietnam",
    "iso2": "VN",
    "iso3": "VNM",
    "admin_name": "Đồng Tháp",
    "capital": "admin",
    "population": 149837,
    "id": 1704276832
  },
  {
    "city": "Riobamba",
    "city_ascii": "Riobamba",
    "lat": -1.6743,
    "lng": -78.6483,
    "country": "Ecuador",
    "iso2": "EC",
    "iso3": "ECU",
    "admin_name": "Chimborazo",
    "capital": "admin",
    "population": 146324,
    "id": 1218552075
  },
  {
    "city": "Vryheid",
    "city_ascii": "Vryheid",
    "lat": -27.7694,
    "lng": 30.7914,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "KwaZulu-Natal",
    "capital": "",
    "population": 150012,
    "id": 1710716158
  },
  {
    "city": "Aş Şuwayḩirah as Sāḩil",
    "city_ascii": "As Suwayhirah as Sahil",
    "lat": 24.362,
    "lng": 56.7344,
    "country": "Oman",
    "iso2": "OM",
    "iso3": "OMN",
    "admin_name": "Shamāl al Bāţinah",
    "capital": "",
    "population": 151349,
    "id": 1512145575
  },
  {
    "city": "Paderborn",
    "city_ascii": "Paderborn",
    "lat": 51.7167,
    "lng": 8.7667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "minor",
    "population": 150580,
    "id": 1276339087
  },
  {
    "city": "Zamora",
    "city_ascii": "Zamora",
    "lat": 19.9833,
    "lng": -102.2833,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Michoacán de Ocampo",
    "capital": "",
    "population": 141627,
    "id": 1484229977
  },
  {
    "city": "Manzanillo",
    "city_ascii": "Manzanillo",
    "lat": 20.3397,
    "lng": -77.1086,
    "country": "Cuba",
    "iso2": "CU",
    "iso3": "CUB",
    "admin_name": "Granma",
    "capital": "minor",
    "population": 150999,
    "id": 1192805452
  },
  {
    "city": "Pasadena",
    "city_ascii": "Pasadena",
    "lat": 29.6583,
    "lng": -95.1499,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 151227,
    "id": 1840020930
  },
  {
    "city": "Florencio Varela",
    "city_ascii": "Florencio Varela",
    "lat": -34.8167,
    "lng": -58.3833,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires",
    "capital": "minor",
    "population": 146704,
    "id": 1032021324
  },
  {
    "city": "Komaki",
    "city_ascii": "Komaki",
    "lat": 35.2833,
    "lng": 136.9167,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Aichi",
    "capital": "",
    "population": 148709,
    "id": 1392770958
  },
  {
    "city": "Talcahuano",
    "city_ascii": "Talcahuano",
    "lat": -36.7145,
    "lng": -73.1141,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Biobío",
    "capital": "",
    "population": 147831,
    "id": 1152023044
  },
  {
    "city": "Kerch",
    "city_ascii": "Kerch",
    "lat": 45.3386,
    "lng": 36.4681,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Krym, Avtonomna Respublika",
    "capital": "",
    "population": 149566,
    "id": 1804437456
  },
  {
    "city": "Patos de Minas",
    "city_ascii": "Patos de Minas",
    "lat": -18.5871,
    "lng": -46.5149,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Minas Gerais",
    "capital": "",
    "population": 150833,
    "id": 1076100026
  },
  {
    "city": "Copiapó",
    "city_ascii": "Copiapo",
    "lat": -27.3665,
    "lng": -70.3323,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Atacama",
    "capital": "admin",
    "population": 150804,
    "id": 1152406965
  },
  {
    "city": "Badajoz",
    "city_ascii": "Badajoz",
    "lat": 38.8803,
    "lng": -6.9753,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Extremadura",
    "capital": "minor",
    "population": 150702,
    "id": 1724759742
  },
  {
    "city": "M’Sila",
    "city_ascii": "M'Sila",
    "lat": 35.7,
    "lng": 4.545,
    "country": "Algeria",
    "iso2": "DZ",
    "iso3": "DZA",
    "admin_name": "M’sila",
    "capital": "admin",
    "population": 150000,
    "id": 1012857730
  },
  {
    "city": "Kragujevac",
    "city_ascii": "Kragujevac",
    "lat": 44.0142,
    "lng": 20.9394,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Kragujevac",
    "capital": "admin",
    "population": 150623,
    "id": 1688832387
  },
  {
    "city": "Pointe-à-Pitre",
    "city_ascii": "Pointe-a-Pitre",
    "lat": 16.2415,
    "lng": -61.533,
    "country": "Guadeloupe",
    "iso2": "GP",
    "iso3": "GLP",
    "admin_name": "",
    "capital": "",
    "population": 145511,
    "id": 1312460524
  },
  {
    "city": "Willemstad",
    "city_ascii": "Willemstad",
    "lat": 12.1167,
    "lng": -68.9333,
    "country": "Curaçao",
    "iso2": "CW",
    "iso3": "CUW",
    "admin_name": "",
    "capital": "",
    "population": 150000,
    "id": 1531000000
  },
  {
    "city": "Rimini",
    "city_ascii": "Rimini",
    "lat": 44.0594,
    "lng": 12.5683,
    "country": "Italy",
    "iso2": "IT",
    "iso3": "ITA",
    "admin_name": "Emilia-Romagna",
    "capital": "minor",
    "population": 149403,
    "id": 1380527853
  },
  {
    "city": "Dundee",
    "city_ascii": "Dundee",
    "lat": 56.462,
    "lng": -2.9707,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Dundee City",
    "capital": "",
    "population": 148270,
    "id": 1826581481
  },
  {
    "city": "Jijel",
    "city_ascii": "Jijel",
    "lat": 36.822,
    "lng": 5.766,
    "country": "Algeria",
    "iso2": "DZ",
    "iso3": "DZA",
    "admin_name": "Jijel",
    "capital": "admin",
    "population": 148000,
    "id": 1012782547
  },
  {
    "city": "Ocumare del Tuy",
    "city_ascii": "Ocumare del Tuy",
    "lat": 10.1136,
    "lng": -66.7814,
    "country": "Venezuela",
    "iso2": "VE",
    "iso3": "VEN",
    "admin_name": "Miranda",
    "capital": "minor",
    "population": 150000,
    "id": 1862015576
  },
  {
    "city": "Valdivia",
    "city_ascii": "Valdivia",
    "lat": -39.8142,
    "lng": -73.2459,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Los Ríos",
    "capital": "admin",
    "population": 150048,
    "id": 1152201132
  },
  {
    "city": "Gulu",
    "city_ascii": "Gulu",
    "lat": 2.7667,
    "lng": 32.3056,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Gulu",
    "capital": "admin",
    "population": 146858,
    "id": 1800406007
  },
  {
    "city": "La Ceiba",
    "city_ascii": "La Ceiba",
    "lat": 15.7631,
    "lng": -86.797,
    "country": "Honduras",
    "iso2": "HN",
    "iso3": "HND",
    "admin_name": "Atlántida",
    "capital": "admin",
    "population": 145926,
    "id": 1340870189
  },
  {
    "city": "Shāhrūd",
    "city_ascii": "Shahrud",
    "lat": 36.4167,
    "lng": 55.0167,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Semnān",
    "capital": "minor",
    "population": 150129,
    "id": 1364363854
  },
  {
    "city": "Iruma",
    "city_ascii": "Iruma",
    "lat": 35.8358,
    "lng": 139.3911,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Saitama",
    "capital": "",
    "population": 146802,
    "id": 1392661533
  },
  {
    "city": "Kashikishi",
    "city_ascii": "Kashikishi",
    "lat": -9.3172,
    "lng": 28.7331,
    "country": "Zambia",
    "iso2": "ZM",
    "iso3": "ZMB",
    "admin_name": "Luapula",
    "capital": "",
    "population": 150000,
    "id": 1894795016
  },
  {
    "city": "Sumbawanga",
    "city_ascii": "Sumbawanga",
    "lat": -7.9667,
    "lng": 31.6167,
    "country": "Tanzania",
    "iso2": "TZ",
    "iso3": "TZA",
    "admin_name": "Rukwa",
    "capital": "admin",
    "population": 147483,
    "id": 1834684278
  },
  {
    "city": "Keren",
    "city_ascii": "Keren",
    "lat": 15.6804,
    "lng": 38.45,
    "country": "Eritrea",
    "iso2": "ER",
    "iso3": "ERI",
    "admin_name": "Ānseba",
    "capital": "admin",
    "population": 150000,
    "id": 1232693598
  },
  {
    "city": "Odivelas",
    "city_ascii": "Odivelas",
    "lat": 38.8,
    "lng": -9.1833,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Lisboa",
    "capital": "minor",
    "population": 144549,
    "id": 1620010482
  },
  {
    "city": "New Bedford",
    "city_ascii": "New Bedford",
    "lat": 41.6697,
    "lng": -70.9428,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 149910,
    "id": 1840003219
  },
  {
    "city": "Jāmuria",
    "city_ascii": "Jamuria",
    "lat": 23.7,
    "lng": 87.08,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "West Bengal",
    "capital": "",
    "population": 149220,
    "id": 1356777057
  },
  {
    "city": "Kutaisi",
    "city_ascii": "Kutaisi",
    "lat": 42.25,
    "lng": 42.7,
    "country": "Georgia",
    "iso2": "GE",
    "iso3": "GEO",
    "admin_name": "Imereti",
    "capital": "admin",
    "population": 147900,
    "id": 1268369835
  },
  {
    "city": "Potchefstroom",
    "city_ascii": "Potchefstroom",
    "lat": -26.715,
    "lng": 27.1033,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "North West",
    "capital": "",
    "population": 148804,
    "id": 1710838885
  },
  {
    "city": "Shimotoda",
    "city_ascii": "Shimotoda",
    "lat": 35.8175,
    "lng": 139.6778,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Saitama",
    "capital": "",
    "population": 141724,
    "id": 1392735490
  },
  {
    "city": "Concordia",
    "city_ascii": "Concordia",
    "lat": -31.3922,
    "lng": -58.0169,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Entre Ríos",
    "capital": "minor",
    "population": 149450,
    "id": 1032923957
  },
  {
    "city": "Abbottabad",
    "city_ascii": "Abbottabad",
    "lat": 34.15,
    "lng": 73.2167,
    "country": "Pakistan",
    "iso2": "PK",
    "iso3": "PAK",
    "admin_name": "Khyber Pakhtunkhwa",
    "capital": "minor",
    "population": 148587,
    "id": 1586788758
  },
  {
    "city": "Delicias",
    "city_ascii": "Delicias",
    "lat": 28.1931,
    "lng": -105.4717,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Chihuahua",
    "capital": "minor",
    "population": 148045,
    "id": 1484405772
  },
  {
    "city": "Mallawī",
    "city_ascii": "Mallawi",
    "lat": 27.7306,
    "lng": 30.8425,
    "country": "Egypt",
    "iso2": "EG",
    "iso3": "EGY",
    "admin_name": "Al Minyā",
    "capital": "",
    "population": 139929,
    "id": 1818284236
  },
  {
    "city": "Ağrı",
    "city_ascii": "Agri",
    "lat": 39.7225,
    "lng": 43.0544,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Ağrı",
    "capital": "admin",
    "population": 149188,
    "id": 1792385654
  },
  {
    "city": "Amasya",
    "city_ascii": "Amasya",
    "lat": 40.65,
    "lng": 35.8333,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Amasya",
    "capital": "admin",
    "population": 149084,
    "id": 1792344167
  },
  {
    "city": "Lạng Sơn",
    "city_ascii": "Lang Son",
    "lat": 21.8478,
    "lng": 106.7578,
    "country": "Vietnam",
    "iso2": "VN",
    "iso3": "VNM",
    "admin_name": "Lạng Sơn",
    "capital": "admin",
    "population": 148000,
    "id": 1704004349
  },
  {
    "city": "Salamanca",
    "city_ascii": "Salamanca",
    "lat": 40.9667,
    "lng": -5.6639,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Castille-Leon",
    "capital": "minor",
    "population": 144228,
    "id": 1724960825
  },
  {
    "city": "Kastamonu",
    "city_ascii": "Kastamonu",
    "lat": 41.3833,
    "lng": 33.7833,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Kastamonu",
    "capital": "admin",
    "population": 148931,
    "id": 1792386602
  },
  {
    "city": "Marvdasht",
    "city_ascii": "Marvdasht",
    "lat": 29.8742,
    "lng": 52.8025,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Fārs",
    "capital": "minor",
    "population": 148858,
    "id": 1364881808
  },
  {
    "city": "Goma",
    "city_ascii": "Goma",
    "lat": -1.69,
    "lng": 29.22,
    "country": "Congo (Kinshasa)",
    "iso2": "CD",
    "iso3": "COD",
    "admin_name": "Nord-Kivu",
    "capital": "admin",
    "population": 144124,
    "id": 1180805022
  },
  {
    "city": "Yonago",
    "city_ascii": "Yonago",
    "lat": 35.4281,
    "lng": 133.3311,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Tottori",
    "capital": "",
    "population": 147667,
    "id": 1392391989
  },
  {
    "city": "Chingola",
    "city_ascii": "Chingola",
    "lat": -12.5447,
    "lng": 27.8708,
    "country": "Zambia",
    "iso2": "ZM",
    "iso3": "ZMB",
    "admin_name": "Copperbelt",
    "capital": "",
    "population": 147448,
    "id": 1894202007
  },
  {
    "city": "Fairfield",
    "city_ascii": "Fairfield",
    "lat": 38.2592,
    "lng": -122.0324,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 148683,
    "id": 1840020253
  },
  {
    "city": "Mostaganem",
    "city_ascii": "Mostaganem",
    "lat": 35.9333,
    "lng": 0.0903,
    "country": "Algeria",
    "iso2": "DZ",
    "iso3": "DZA",
    "admin_name": "Mostaganem",
    "capital": "admin",
    "population": 145696,
    "id": 1012061703
  },
  {
    "city": "Hābra",
    "city_ascii": "Habra",
    "lat": 22.83,
    "lng": 88.63,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "West Bengal",
    "capital": "",
    "population": 147221,
    "id": 1356091822
  },
  {
    "city": "Mauli",
    "city_ascii": "Mauli",
    "lat": 30.7194,
    "lng": 76.7181,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Punjab",
    "capital": "",
    "population": 146213,
    "id": 1356181198
  },
  {
    "city": "Sibiu",
    "city_ascii": "Sibiu",
    "lat": 45.8,
    "lng": 24.15,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Sibiu",
    "capital": "admin",
    "population": 147245,
    "id": 1642393086
  },
  {
    "city": "Naperville",
    "city_ascii": "Naperville",
    "lat": 41.7483,
    "lng": -88.1657,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 148449,
    "id": 1840008143
  },
  {
    "city": "Quilpué",
    "city_ascii": "Quilpue",
    "lat": -33.0498,
    "lng": -71.4415,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Valparaíso",
    "capital": "minor",
    "population": 147991,
    "id": 1152063222
  },
  {
    "city": "Dolores Hidalgo Cuna de la Independencia Nacional",
    "city_ascii": "Dolores Hidalgo Cuna de la Independencia Nacional",
    "lat": 21.1516,
    "lng": -100.9369,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Guanajuato",
    "capital": "minor",
    "population": 148173,
    "id": 1484115665
  },
  {
    "city": "Kopeysk",
    "city_ascii": "Kopeysk",
    "lat": 55.1,
    "lng": 61.6167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Chelyabinskaya Oblast’",
    "capital": "",
    "population": 147573,
    "id": 1643343511
  },
  {
    "city": "Lüleburgaz",
    "city_ascii": "Luleburgaz",
    "lat": 41.4056,
    "lng": 27.3569,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Kırklareli",
    "capital": "minor",
    "population": 148037,
    "id": 1792000752
  },
  {
    "city": "Al Ḩawīyah",
    "city_ascii": "Al Hawiyah",
    "lat": 21.4411,
    "lng": 40.4975,
    "country": "Saudi Arabia",
    "iso2": "SA",
    "iso3": "SAU",
    "admin_name": "Makkah al Mukarramah",
    "capital": "",
    "population": 148151,
    "id": 1682803387
  },
  {
    "city": "Bellevue",
    "city_ascii": "Bellevue",
    "lat": 47.5951,
    "lng": -122.1535,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 148164,
    "id": 1840018417
  },
  {
    "city": "Binghamton",
    "city_ascii": "Binghamton",
    "lat": 42.1014,
    "lng": -75.9093,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 148145,
    "id": 1840000460
  },
  {
    "city": "Nchelenge",
    "city_ascii": "Nchelenge",
    "lat": -9.3467,
    "lng": 28.7344,
    "country": "Zambia",
    "iso2": "ZM",
    "iso3": "ZMB",
    "admin_name": "Luapula",
    "capital": "",
    "population": 147927,
    "id": 1894890604
  },
  {
    "city": "Negombo",
    "city_ascii": "Negombo",
    "lat": 7.2111,
    "lng": 79.8386,
    "country": "Sri Lanka",
    "iso2": "LK",
    "iso3": "LKA",
    "admin_name": "Western",
    "capital": "",
    "population": 142136,
    "id": 1144271397
  },
  {
    "city": "Ambāla",
    "city_ascii": "Ambala",
    "lat": 30.3786,
    "lng": 76.7725,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Haryāna",
    "capital": "",
    "population": 146787,
    "id": 1356947216
  },
  {
    "city": "Disūq",
    "city_ascii": "Disuq",
    "lat": 31.1308,
    "lng": 30.6479,
    "country": "Egypt",
    "iso2": "EG",
    "iso3": "EGY",
    "admin_name": "Kafr ash Shaykh",
    "capital": "",
    "population": 143404,
    "id": 1818106054
  },
  {
    "city": "Mangghystaū",
    "city_ascii": "Mangghystau",
    "lat": 43.6905,
    "lng": 51.1417,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Mangghystaū",
    "capital": "",
    "population": 147443,
    "id": 1398790140
  },
  {
    "city": "Malakal",
    "city_ascii": "Malakal",
    "lat": 9.5333,
    "lng": 31.65,
    "country": "South Sudan",
    "iso2": "SS",
    "iso3": "SSD",
    "admin_name": "Upper Nile",
    "capital": "admin",
    "population": 147450,
    "id": 1728299711
  },
  {
    "city": "Bacău",
    "city_ascii": "Bacau",
    "lat": 46.5833,
    "lng": 26.9167,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Bacău",
    "capital": "admin",
    "population": 144307,
    "id": 1642838304
  },
  {
    "city": "Osorno",
    "city_ascii": "Osorno",
    "lat": -40.5667,
    "lng": -73.15,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Los Lagos",
    "capital": "minor",
    "population": 147460,
    "id": 1152866014
  },
  {
    "city": "Elkhart",
    "city_ascii": "Elkhart",
    "lat": 41.6916,
    "lng": -85.9628,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 147567,
    "id": 1840008187
  },
  {
    "city": "Topeka",
    "city_ascii": "Topeka",
    "lat": 39.0346,
    "lng": -95.6955,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kansas",
    "capital": "admin",
    "population": 147458,
    "id": 1840001621
  },
  {
    "city": "Mogi Guaçu",
    "city_ascii": "Mogi Guacu",
    "lat": -22.3719,
    "lng": -46.9419,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 147233,
    "id": 1076749992
  },
  {
    "city": "Man",
    "city_ascii": "Man",
    "lat": 7.4004,
    "lng": -7.55,
    "country": "Côte D’Ivoire",
    "iso2": "CI",
    "iso3": "CIV",
    "admin_name": "Montagnes",
    "capital": "admin",
    "population": 146974,
    "id": 1384112524
  },
  {
    "city": "Joliet",
    "city_ascii": "Joliet",
    "lat": 41.5189,
    "lng": -88.1499,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 147344,
    "id": 1840008192
  },
  {
    "city": "Pyatigorsk",
    "city_ascii": "Pyatigorsk",
    "lat": 44.0333,
    "lng": 43.05,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Stavropol’skiy Kray",
    "capital": "",
    "population": 145836,
    "id": 1643815630
  },
  {
    "city": "Clermont-Ferrand",
    "city_ascii": "Clermont-Ferrand",
    "lat": 45.7831,
    "lng": 3.0824,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Auvergne-Rhône-Alpes",
    "capital": "minor",
    "population": 143886,
    "id": 1250135509
  },
  {
    "city": "Moshi",
    "city_ascii": "Moshi",
    "lat": -3.3349,
    "lng": 37.3404,
    "country": "Tanzania",
    "iso2": "TZ",
    "iso3": "TZA",
    "admin_name": "Kilimanjaro",
    "capital": "admin",
    "population": 144739,
    "id": 1834217168
  },
  {
    "city": "Dar‘ā",
    "city_ascii": "Dar`a",
    "lat": 32.625,
    "lng": 36.105,
    "country": "Syria",
    "iso2": "SY",
    "iso3": "SYR",
    "admin_name": "Dar‘ā",
    "capital": "admin",
    "population": 146481,
    "id": 1760427038
  },
  {
    "city": "Rubtsovsk",
    "city_ascii": "Rubtsovsk",
    "lat": 51.5167,
    "lng": 81.2,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Altayskiy Kray",
    "capital": "",
    "population": 145333,
    "id": 1643450182
  },
  {
    "city": "Cam Ranh",
    "city_ascii": "Cam Ranh",
    "lat": 11.902,
    "lng": 109.2207,
    "country": "Vietnam",
    "iso2": "VN",
    "iso3": "VNM",
    "admin_name": "Khánh Hòa",
    "capital": "",
    "population": 146771,
    "id": 1704104189
  },
  {
    "city": "Wamba",
    "city_ascii": "Wamba",
    "lat": 2.1404,
    "lng": 27.99,
    "country": "Congo (Kinshasa)",
    "iso2": "CD",
    "iso3": "COD",
    "admin_name": "Haut-Uélé",
    "capital": "",
    "population": 146871,
    "id": 1180866253
  },
  {
    "city": "Franco da Rocha",
    "city_ascii": "Franco da Rocha",
    "lat": -23.3286,
    "lng": -46.7244,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 145755,
    "id": 1076071582
  },
  {
    "city": "Dadu",
    "city_ascii": "Dadu",
    "lat": 26.7319,
    "lng": 67.775,
    "country": "Pakistan",
    "iso2": "PK",
    "iso3": "PAK",
    "admin_name": "Sindh",
    "capital": "minor",
    "population": 146179,
    "id": 1586791198
  },
  {
    "city": "Sagay",
    "city_ascii": "Sagay",
    "lat": 10.9,
    "lng": 123.4167,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Negros Occidental",
    "capital": "",
    "population": 146264,
    "id": 1608284971
  },
  {
    "city": "Bălţi",
    "city_ascii": "Balti",
    "lat": 47.7617,
    "lng": 27.9289,
    "country": "Moldova",
    "iso2": "MD",
    "iso3": "MDA",
    "admin_name": "Bălţi",
    "capital": "admin",
    "population": 144800,
    "id": 1498172131
  },
  {
    "city": "San Juan",
    "city_ascii": "San Juan",
    "lat": 14.6,
    "lng": 121.0333,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "San Juan",
    "capital": "admin",
    "population": 122180,
    "id": 1608817515
  },
  {
    "city": "Kolomna",
    "city_ascii": "Kolomna",
    "lat": 55.0833,
    "lng": 38.7833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Moskovskaya Oblast’",
    "capital": "",
    "population": 144125,
    "id": 1643196698
  },
  {
    "city": "Lárisa",
    "city_ascii": "Larisa",
    "lat": 39.6385,
    "lng": 22.4131,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Thessalía",
    "capital": "admin",
    "population": 144651,
    "id": 1300003141
  },
  {
    "city": "Misato",
    "city_ascii": "Misato",
    "lat": 35.8303,
    "lng": 139.8725,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Saitama",
    "capital": "",
    "population": 141514,
    "id": 1392464033
  },
  {
    "city": "San Miguel de Allende",
    "city_ascii": "San Miguel de Allende",
    "lat": 20.9142,
    "lng": -100.7436,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Guanajuato",
    "capital": "minor",
    "population": 139297,
    "id": 1484007926
  },
  {
    "city": "Beaumont",
    "city_ascii": "Beaumont",
    "lat": 30.0849,
    "lng": -94.1451,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 146091,
    "id": 1840019612
  },
  {
    "city": "Paranaguá",
    "city_ascii": "Paranagua",
    "lat": -25.5279,
    "lng": -48.5345,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Paraná",
    "capital": "",
    "population": 141013,
    "id": 1076265266
  },
  {
    "city": "García",
    "city_ascii": "Garcia",
    "lat": 25.8167,
    "lng": -100.5833,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Nuevo León",
    "capital": "minor",
    "population": 145867,
    "id": 1484753599
  },
  {
    "city": "Kakamigahara",
    "city_ascii": "Kakamigahara",
    "lat": 35.3989,
    "lng": 136.8486,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Gifu",
    "capital": "",
    "population": 144335,
    "id": 1392578597
  },
  {
    "city": "Logroño",
    "city_ascii": "Logrono",
    "lat": 42.4704,
    "lng": -2.43,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "La Rioja",
    "capital": "admin",
    "population": 143698,
    "id": 1724721513
  },
  {
    "city": "Matanzas",
    "city_ascii": "Matanzas",
    "lat": 23.0494,
    "lng": -81.5736,
    "country": "Cuba",
    "iso2": "CU",
    "iso3": "CUB",
    "admin_name": "Matanzas",
    "capital": "admin",
    "population": 145246,
    "id": 1192888416
  },
  {
    "city": "Ilagan",
    "city_ascii": "Ilagan",
    "lat": 17.1333,
    "lng": 121.8833,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Isabela",
    "capital": "admin",
    "population": 145568,
    "id": 1608190610
  },
  {
    "city": "David",
    "city_ascii": "David",
    "lat": 8.4333,
    "lng": -82.4333,
    "country": "Panama",
    "iso2": "PA",
    "iso3": "PAN",
    "admin_name": "Chiriquí",
    "capital": "admin",
    "population": 144858,
    "id": 1591487342
  },
  {
    "city": "Le Mans",
    "city_ascii": "Le Mans",
    "lat": 48.0077,
    "lng": 0.1984,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Pays de la Loire",
    "capital": "minor",
    "population": 142946,
    "id": 1250829671
  },
  {
    "city": "San Justo",
    "city_ascii": "San Justo",
    "lat": -34.6833,
    "lng": -58.55,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires",
    "capital": "minor",
    "population": 136604,
    "id": 1032899295
  },
  {
    "city": "Qūchān",
    "city_ascii": "Quchan",
    "lat": 37.1118,
    "lng": 58.5015,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Khorāsān-e Raẕavī",
    "capital": "minor",
    "population": 145531,
    "id": 1364255116
  },
  {
    "city": "Berezniki",
    "city_ascii": "Berezniki",
    "lat": 59.4167,
    "lng": 56.7833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Permskiy Kray",
    "capital": "",
    "population": 145115,
    "id": 1643757810
  },
  {
    "city": "Shillong",
    "city_ascii": "Shillong",
    "lat": 25.5744,
    "lng": 91.8789,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Meghālaya",
    "capital": "admin",
    "population": 143229,
    "id": 1356533936
  },
  {
    "city": "Ereğli",
    "city_ascii": "Eregli",
    "lat": 37.5058,
    "lng": 34.0517,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Konya",
    "capital": "minor",
    "population": 145389,
    "id": 1792110470
  },
  {
    "city": "Tizi Ouzou",
    "city_ascii": "Tizi Ouzou",
    "lat": 36.8,
    "lng": 4.0333,
    "country": "Algeria",
    "iso2": "DZ",
    "iso3": "DZA",
    "admin_name": "Tizi Ouzou",
    "capital": "admin",
    "population": 144000,
    "id": 1012126997
  },
  {
    "city": "Durrës",
    "city_ascii": "Durres",
    "lat": 41.3167,
    "lng": 19.45,
    "country": "Albania",
    "iso2": "AL",
    "iso3": "ALB",
    "admin_name": "Durrës",
    "capital": "admin",
    "population": 142432,
    "id": 1008173690
  },
  {
    "city": "Yalova",
    "city_ascii": "Yalova",
    "lat": 40.6556,
    "lng": 29.275,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Yalova",
    "capital": "admin",
    "population": 144407,
    "id": 1792838138
  },
  {
    "city": "Villa de Cura",
    "city_ascii": "Villa de Cura",
    "lat": 10.0386,
    "lng": -67.4894,
    "country": "Venezuela",
    "iso2": "VE",
    "iso3": "VEN",
    "admin_name": "Aragua",
    "capital": "minor",
    "population": 145098,
    "id": 1862979204
  },
  {
    "city": "Paterson",
    "city_ascii": "Paterson",
    "lat": 40.9147,
    "lng": -74.1624,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 145233,
    "id": 1840000838
  },
  {
    "city": "Merced",
    "city_ascii": "Merced",
    "lat": 37.3057,
    "lng": -120.4778,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 145149,
    "id": 1840020313
  },
  {
    "city": "Kolār",
    "city_ascii": "Kolar",
    "lat": 13.1333,
    "lng": 78.1333,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Karnātaka",
    "capital": "",
    "population": 144625,
    "id": 1356953848
  },
  {
    "city": "Cape Coast",
    "city_ascii": "Cape Coast",
    "lat": 5.1,
    "lng": -1.25,
    "country": "Ghana",
    "iso2": "GH",
    "iso3": "GHA",
    "admin_name": "Central",
    "capital": "admin",
    "population": 143015,
    "id": 1288506097
  },
  {
    "city": "Al Mukallā",
    "city_ascii": "Al Mukalla",
    "lat": 14.5333,
    "lng": 49.1333,
    "country": "Yemen",
    "iso2": "YE",
    "iso3": "YEM",
    "admin_name": "Ḩaḑramawt",
    "capital": "admin",
    "population": 144137,
    "id": 1887202594
  },
  {
    "city": "Dongxing",
    "city_ascii": "Dongxing",
    "lat": 21.5833,
    "lng": 108.05,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Guangxi",
    "capital": "",
    "population": 144709,
    "id": 1156419338
  },
  {
    "city": "Comayagua",
    "city_ascii": "Comayagua",
    "lat": 14.46,
    "lng": -87.65,
    "country": "Honduras",
    "iso2": "HN",
    "iso3": "HND",
    "admin_name": "Comayagua",
    "capital": "admin",
    "population": 144785,
    "id": 1340015594
  },
  {
    "city": "Barranca",
    "city_ascii": "Barranca",
    "lat": -10.7539,
    "lng": -77.761,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "Lima",
    "capital": "",
    "population": 144381,
    "id": 1604547675
  },
  {
    "city": "Khasavyurt",
    "city_ascii": "Khasavyurt",
    "lat": 43.25,
    "lng": 46.5833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Dagestan",
    "capital": "minor",
    "population": 141259,
    "id": 1643734694
  },
  {
    "city": "Saguenay",
    "city_ascii": "Saguenay",
    "lat": 48.4167,
    "lng": -71.0667,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 144746,
    "id": 1124001930
  },
  {
    "city": "Tuxtepec",
    "city_ascii": "Tuxtepec",
    "lat": 18.1,
    "lng": -96.1167,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Oaxaca",
    "capital": "",
    "population": 144555,
    "id": 1484448356
  },
  {
    "city": "Kairouan",
    "city_ascii": "Kairouan",
    "lat": 35.6804,
    "lng": 10.1,
    "country": "Tunisia",
    "iso2": "TN",
    "iso3": "TUN",
    "admin_name": "Kairouan",
    "capital": "admin",
    "population": 144522,
    "id": 1788844707
  },
  {
    "city": "Marbella",
    "city_ascii": "Marbella",
    "lat": 36.5114,
    "lng": -4.8834,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Andalusia",
    "capital": "",
    "population": 143386,
    "id": 1724685705
  },
  {
    "city": "Huelva",
    "city_ascii": "Huelva",
    "lat": 37.25,
    "lng": -6.95,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Andalusia",
    "capital": "minor",
    "population": 143663,
    "id": 1724420824
  },
  {
    "city": "Kumba",
    "city_ascii": "Kumba",
    "lat": 4.6333,
    "lng": 9.45,
    "country": "Cameroon",
    "iso2": "CM",
    "iso3": "CMR",
    "admin_name": "South-West",
    "capital": "",
    "population": 144413,
    "id": 1120492761
  },
  {
    "city": "Parnaíba",
    "city_ascii": "Parnaiba",
    "lat": -2.91,
    "lng": -41.77,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Piauí",
    "capital": "",
    "population": 138008,
    "id": 1076568871
  },
  {
    "city": "Barrechid",
    "city_ascii": "Barrechid",
    "lat": 33.27,
    "lng": -7.5872,
    "country": "Morocco",
    "iso2": "MA",
    "iso3": "MAR",
    "admin_name": "Casablanca-Settat",
    "capital": "",
    "population": 136634,
    "id": 1504194885
  },
  {
    "city": "Bharatpur",
    "city_ascii": "Bharatpur",
    "lat": 27.6833,
    "lng": 84.4333,
    "country": "Nepal",
    "iso2": "NP",
    "iso3": "NPL",
    "admin_name": "Nārāyanī",
    "capital": "",
    "population": 143836,
    "id": 1524780273
  },
  {
    "city": "Saïda",
    "city_ascii": "Saida",
    "lat": 34.8404,
    "lng": 0.14,
    "country": "Algeria",
    "iso2": "DZ",
    "iso3": "DZA",
    "admin_name": "Saïda",
    "capital": "admin",
    "population": 142213,
    "id": 1012285874
  },
  {
    "city": "Kusatsu",
    "city_ascii": "Kusatsu",
    "lat": 35.0167,
    "lng": 135.9667,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Shiga",
    "capital": "",
    "population": 141928,
    "id": 1392082200
  },
  {
    "city": "Florencia",
    "city_ascii": "Florencia",
    "lat": 1.6142,
    "lng": -75.6117,
    "country": "Colombia",
    "iso2": "CO",
    "iso3": "COL",
    "admin_name": "Caquetá",
    "capital": "admin",
    "population": 143871,
    "id": 1170606634
  },
  {
    "city": "Pueblo",
    "city_ascii": "Pueblo",
    "lat": 38.2705,
    "lng": -104.6112,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 143932,
    "id": 1840020255
  },
  {
    "city": "Pleiku",
    "city_ascii": "Pleiku",
    "lat": 13.9833,
    "lng": 108,
    "country": "Vietnam",
    "iso2": "VN",
    "iso3": "VNM",
    "admin_name": "Gia Lai",
    "capital": "admin",
    "population": 142900,
    "id": 1704220080
  },
  {
    "city": "Coimbra",
    "city_ascii": "Coimbra",
    "lat": 40.2111,
    "lng": -8.4291,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Coimbra",
    "capital": "admin",
    "population": 143396,
    "id": 1620166724
  },
  {
    "city": "Ajdābiyā",
    "city_ascii": "Ajdabiya",
    "lat": 30.77,
    "lng": 20.22,
    "country": "Libya",
    "iso2": "LY",
    "iso3": "LBY",
    "admin_name": "Al Wāḩāt",
    "capital": "admin",
    "population": 143833,
    "id": 1434401280
  },
  {
    "city": "Tyler",
    "city_ascii": "Tyler",
    "lat": 32.3184,
    "lng": -95.3062,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 143796,
    "id": 1840022076
  },
  {
    "city": "Ciego de Ávila",
    "city_ascii": "Ciego de Avila",
    "lat": 21.8481,
    "lng": -78.7631,
    "country": "Cuba",
    "iso2": "CU",
    "iso3": "CUB",
    "admin_name": "Ciego de Ávila",
    "capital": "admin",
    "population": 143449,
    "id": 1192302618
  },
  {
    "city": "Pécs",
    "city_ascii": "Pecs",
    "lat": 46.0708,
    "lng": 18.2331,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Baranya",
    "capital": "admin",
    "population": 142873,
    "id": 1348611992
  },
  {
    "city": "Ghaznī",
    "city_ascii": "Ghazni",
    "lat": 33.5492,
    "lng": 68.4233,
    "country": "Afghanistan",
    "iso2": "AF",
    "iso3": "AFG",
    "admin_name": "Ghaznī",
    "capital": "admin",
    "population": 143379,
    "id": 1004167490
  },
  {
    "city": "Blackpool",
    "city_ascii": "Blackpool",
    "lat": 53.8142,
    "lng": -3.0503,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Blackpool",
    "capital": "",
    "population": 139720,
    "id": 1826093175
  },
  {
    "city": "Nawābganj",
    "city_ascii": "Nawabganj",
    "lat": 24.5804,
    "lng": 88.35,
    "country": "Bangladesh",
    "iso2": "BD",
    "iso3": "BGD",
    "admin_name": "Rājshāhi",
    "capital": "",
    "population": 142361,
    "id": 1050253103
  },
  {
    "city": "Altay",
    "city_ascii": "Altay",
    "lat": 47.8666,
    "lng": 88.1166,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Xinjiang",
    "capital": "minor",
    "population": 142000,
    "id": 1156855629
  },
  {
    "city": "El Progreso",
    "city_ascii": "El Progreso",
    "lat": 15.4,
    "lng": -87.8,
    "country": "Honduras",
    "iso2": "HN",
    "iso3": "HND",
    "admin_name": "Yoro",
    "capital": "",
    "population": 143360,
    "id": 1340231948
  },
  {
    "city": "Torrance",
    "city_ascii": "Torrance",
    "lat": 33.8346,
    "lng": -118.3417,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 143592,
    "id": 1840021873
  },
  {
    "city": "Pemba",
    "city_ascii": "Pemba",
    "lat": -12.9667,
    "lng": 40.5167,
    "country": "Mozambique",
    "iso2": "MZ",
    "iso3": "MOZ",
    "admin_name": "Cabo Delgado",
    "capital": "admin",
    "population": 141316,
    "id": 1508872233
  },
  {
    "city": "Jaú",
    "city_ascii": "Jau",
    "lat": -22.2958,
    "lng": -48.5578,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 143283,
    "id": 1076312559
  },
  {
    "city": "Metairie",
    "city_ascii": "Metairie",
    "lat": 29.9977,
    "lng": -90.178,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 143481,
    "id": 1840013994
  },
  {
    "city": "Touggourt",
    "city_ascii": "Touggourt",
    "lat": 33.1,
    "lng": 6.0667,
    "country": "Algeria",
    "iso2": "DZ",
    "iso3": "DZA",
    "admin_name": "Ouargla",
    "capital": "",
    "population": 143270,
    "id": 1012546670
  },
  {
    "city": "Túxpam de Rodríguez Cano",
    "city_ascii": "Tuxpam de Rodriguez Cano",
    "lat": 20.95,
    "lng": -97.4,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Veracruz",
    "capital": "minor",
    "population": 143362,
    "id": 1484316468
  },
  {
    "city": "Nevşehir",
    "city_ascii": "Nevsehir",
    "lat": 38.625,
    "lng": 34.7122,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Nevşehir",
    "capital": "admin",
    "population": 143194,
    "id": 1792583760
  },
  {
    "city": "Béchar",
    "city_ascii": "Bechar",
    "lat": 31.6111,
    "lng": -2.23,
    "country": "Algeria",
    "iso2": "DZ",
    "iso3": "DZA",
    "admin_name": "Béchar",
    "capital": "admin",
    "population": 143382,
    "id": 1012371820
  },
  {
    "city": "Lévis",
    "city_ascii": "Levis",
    "lat": 46.8,
    "lng": -71.1833,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 143414,
    "id": 1124958950
  },
  {
    "city": "Bhīmavaram",
    "city_ascii": "Bhimavaram",
    "lat": 16.5333,
    "lng": 81.5333,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Andhra Pradesh",
    "capital": "",
    "population": 142184,
    "id": 1356195520
  },
  {
    "city": "Bilbays",
    "city_ascii": "Bilbays",
    "lat": 30.4167,
    "lng": 31.5667,
    "country": "Egypt",
    "iso2": "EG",
    "iso3": "EGY",
    "admin_name": "Ash Sharqīyah",
    "capital": "",
    "population": 141285,
    "id": 1818863783
  },
  {
    "city": "Tottenham",
    "city_ascii": "Tottenham",
    "lat": 51.588,
    "lng": -0.072,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Haringey",
    "capital": "",
    "population": 129237,
    "id": 1826538674
  },
  {
    "city": "Aix-en-Provence",
    "city_ascii": "Aix-en-Provence",
    "lat": 43.5263,
    "lng": 5.4454,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Provence-Alpes-Côte d’Azur",
    "capital": "minor",
    "population": 142482,
    "id": 1250001687
  },
  {
    "city": "Cizre",
    "city_ascii": "Cizre",
    "lat": 37.325,
    "lng": 42.1958,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Şırnak",
    "capital": "minor",
    "population": 143124,
    "id": 1792431845
  },
  {
    "city": "Arnhem",
    "city_ascii": "Arnhem",
    "lat": 51.988,
    "lng": 5.923,
    "country": "Netherlands",
    "iso2": "NL",
    "iso3": "NLD",
    "admin_name": "Gelderland",
    "capital": "admin",
    "population": 141674,
    "id": 1528862562
  },
  {
    "city": "Gisenyi",
    "city_ascii": "Gisenyi",
    "lat": -1.6928,
    "lng": 29.25,
    "country": "Rwanda",
    "iso2": "RW",
    "iso3": "RWA",
    "admin_name": "Western Province",
    "capital": "",
    "population": 136830,
    "id": 1646226936
  },
  {
    "city": "Fukayachō",
    "city_ascii": "Fukayacho",
    "lat": 36.2,
    "lng": 139.2833,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Saitama",
    "capital": "",
    "population": 141966,
    "id": 1392571441
  },
  {
    "city": "Yuma",
    "city_ascii": "Yuma",
    "lat": 32.5992,
    "lng": -114.5488,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 142847,
    "id": 1840021998
  },
  {
    "city": "Telford",
    "city_ascii": "Telford",
    "lat": 52.6766,
    "lng": -2.4469,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Telford and Wrekin",
    "capital": "",
    "population": 142723,
    "id": 1826968934
  },
  {
    "city": "Lichinga",
    "city_ascii": "Lichinga",
    "lat": -13.3,
    "lng": 35.2456,
    "country": "Mozambique",
    "iso2": "MZ",
    "iso3": "MOZ",
    "admin_name": "Niassa",
    "capital": "admin",
    "population": 142253,
    "id": 1508041628
  },
  {
    "city": "Ozamiz City",
    "city_ascii": "Ozamiz City",
    "lat": 8.15,
    "lng": 123.85,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Misamis Occidental",
    "capital": "",
    "population": 141828,
    "id": 1608100412
  },
  {
    "city": "Moundou",
    "city_ascii": "Moundou",
    "lat": 8.5667,
    "lng": 16.0833,
    "country": "Chad",
    "iso2": "TD",
    "iso3": "TCD",
    "admin_name": "Logone Occidental",
    "capital": "admin",
    "population": 142462,
    "id": 1148369114
  },
  {
    "city": "Tetovo",
    "city_ascii": "Tetovo",
    "lat": 42.0103,
    "lng": 20.9714,
    "country": "Macedonia",
    "iso2": "MK",
    "iso3": "MKD",
    "admin_name": "Tetovo",
    "capital": "admin",
    "population": 142030,
    "id": 1807020286
  },
  {
    "city": "Nizip",
    "city_ascii": "Nizip",
    "lat": 37.0104,
    "lng": 37.7985,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Gaziantep",
    "capital": "minor",
    "population": 142389,
    "id": 1792748330
  },
  {
    "city": "Maykop",
    "city_ascii": "Maykop",
    "lat": 44.6,
    "lng": 40.0833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Adygeya",
    "capital": "admin",
    "population": 141970,
    "id": 1643584159
  },
  {
    "city": "Lausanne",
    "city_ascii": "Lausanne",
    "lat": 46.5333,
    "lng": 6.6333,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Vaud",
    "capital": "admin",
    "population": 138905,
    "id": 1756055099
  },
  {
    "city": "Kamālshahr",
    "city_ascii": "Kamalshahr",
    "lat": 35.8622,
    "lng": 50.8717,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Alborz",
    "capital": "",
    "population": 141166,
    "id": 1364102350
  },
  {
    "city": "Gweru",
    "city_ascii": "Gweru",
    "lat": -19.458,
    "lng": 29.82,
    "country": "Zimbabwe",
    "iso2": "ZW",
    "iso3": "ZWE",
    "admin_name": "Midlands",
    "capital": "admin",
    "population": 141260,
    "id": 1716621481
  },
  {
    "city": "Kelowna",
    "city_ascii": "Kelowna",
    "lat": 49.8881,
    "lng": -119.4956,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "British Columbia",
    "capital": "",
    "population": 142146,
    "id": 1124080626
  },
  {
    "city": "Castanhal",
    "city_ascii": "Castanhal",
    "lat": -1.2939,
    "lng": -47.9264,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Pará",
    "capital": "",
    "population": 137406,
    "id": 1076216938
  },
  {
    "city": "Irákleio",
    "city_ascii": "Irakleio",
    "lat": 35.3333,
    "lng": 25.1333,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Kríti",
    "capital": "admin",
    "population": 140730,
    "id": 1300777854
  },
  {
    "city": "Mandsaur",
    "city_ascii": "Mandsaur",
    "lat": 24.03,
    "lng": 75.08,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Madhya Pradesh",
    "capital": "",
    "population": 141667,
    "id": 1356467529
  },
  {
    "city": "Rybnik",
    "city_ascii": "Rybnik",
    "lat": 50.0833,
    "lng": 18.5,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Śląskie",
    "capital": "minor",
    "population": 141000,
    "id": 1616092098
  },
  {
    "city": "Nong’an",
    "city_ascii": "Nong'an",
    "lat": 44.4304,
    "lng": 125.1701,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Jilin",
    "capital": "minor",
    "population": 141482,
    "id": 1156278589
  },
  {
    "city": "Rize",
    "city_ascii": "Rize",
    "lat": 41.0167,
    "lng": 40.5167,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Rize",
    "capital": "admin",
    "population": 141143,
    "id": 1792024731
  },
  {
    "city": "Vlorë",
    "city_ascii": "Vlore",
    "lat": 40.45,
    "lng": 19.4833,
    "country": "Albania",
    "iso2": "AL",
    "iso3": "ALB",
    "admin_name": "Vlorë",
    "capital": "admin",
    "population": 130827,
    "id": 1008276050
  },
  {
    "city": "Bizerte",
    "city_ascii": "Bizerte",
    "lat": 37.2904,
    "lng": 9.855,
    "country": "Tunisia",
    "iso2": "TN",
    "iso3": "TUN",
    "admin_name": "Bizerte",
    "capital": "admin",
    "population": 139843,
    "id": 1788330365
  },
  {
    "city": "Jahrom",
    "city_ascii": "Jahrom",
    "lat": 28.5,
    "lng": 53.56,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Fārs",
    "capital": "minor",
    "population": 141634,
    "id": 1364271412
  },
  {
    "city": "Surprise",
    "city_ascii": "Surprise",
    "lat": 33.68,
    "lng": -112.4524,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 141664,
    "id": 1840021941
  },
  {
    "city": "Columbia",
    "city_ascii": "Columbia",
    "lat": 38.9478,
    "lng": -92.3256,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 141645,
    "id": 1840007391
  },
  {
    "city": "Colima",
    "city_ascii": "Colima",
    "lat": 19.2433,
    "lng": -103.7247,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Colima",
    "capital": "admin",
    "population": 137383,
    "id": 1484585697
  },
  {
    "city": "Ishizaki",
    "city_ascii": "Ishizaki",
    "lat": 38.4281,
    "lng": 141.3061,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Miyagi",
    "capital": "",
    "population": 141293,
    "id": 1392384377
  },
  {
    "city": "Athens",
    "city_ascii": "Athens",
    "lat": 33.9508,
    "lng": -83.3689,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 141532,
    "id": 1840029463
  },
  {
    "city": "Roseville",
    "city_ascii": "Roseville",
    "lat": 38.7703,
    "lng": -121.3196,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 141500,
    "id": 1840020233
  },
  {
    "city": "Thornton",
    "city_ascii": "Thornton",
    "lat": 39.9197,
    "lng": -104.9438,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 141464,
    "id": 1840021422
  },
  {
    "city": "Khuzdar",
    "city_ascii": "Khuzdar",
    "lat": 27.8,
    "lng": 66.6167,
    "country": "Pakistan",
    "iso2": "PK",
    "iso3": "PAK",
    "admin_name": "Balochistān",
    "capital": "",
    "population": 141395,
    "id": 1586530458
  },
  {
    "city": "Tepatitlán de Morelos",
    "city_ascii": "Tepatitlan de Morelos",
    "lat": 20.817,
    "lng": -102.733,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Jalisco",
    "capital": "minor",
    "population": 141322,
    "id": 1484777621
  },
  {
    "city": "Abbotsford",
    "city_ascii": "Abbotsford",
    "lat": 49.05,
    "lng": -122.3167,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "British Columbia",
    "capital": "",
    "population": 141397,
    "id": 1124808029
  },
  {
    "city": "Mati",
    "city_ascii": "Mati",
    "lat": 6.95,
    "lng": 126.2333,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Davao Oriental",
    "capital": "admin",
    "population": 141141,
    "id": 1608000731
  },
  {
    "city": "Ruda Śląska",
    "city_ascii": "Ruda Slaska",
    "lat": 50.2628,
    "lng": 18.8536,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Śląskie",
    "capital": "minor",
    "population": 139412,
    "id": 1616148566
  },
  {
    "city": "Miramar",
    "city_ascii": "Miramar",
    "lat": 25.9773,
    "lng": -80.3351,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 141191,
    "id": 1840015137
  },
  {
    "city": "Batu Pahat",
    "city_ascii": "Batu Pahat",
    "lat": 1.85,
    "lng": 102.93,
    "country": "Malaysia",
    "iso2": "MY",
    "iso3": "MYS",
    "admin_name": "Johor",
    "capital": "",
    "population": 140000,
    "id": 1458310769
  },
  {
    "city": "Schaarbeek",
    "city_ascii": "Schaarbeek",
    "lat": 50.8675,
    "lng": 4.3736,
    "country": "Belgium",
    "iso2": "BE",
    "iso3": "BEL",
    "admin_name": "Brussels-Capital Region",
    "capital": "",
    "population": 133010,
    "id": 1056091146
  },
  {
    "city": "Pasadena",
    "city_ascii": "Pasadena",
    "lat": 34.1597,
    "lng": -118.139,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 141029,
    "id": 1840020505
  },
  {
    "city": "Kovrov",
    "city_ascii": "Kovrov",
    "lat": 56.3606,
    "lng": 41.3197,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Vladimirskaya Oblast’",
    "capital": "",
    "population": 138552,
    "id": 1643110693
  },
  {
    "city": "Mesquite",
    "city_ascii": "Mesquite",
    "lat": 32.7623,
    "lng": -96.5889,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 140937,
    "id": 1840020713
  },
  {
    "city": "Odintsovo",
    "city_ascii": "Odintsovo",
    "lat": 55.6733,
    "lng": 37.2733,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Moskovskaya Oblast’",
    "capital": "",
    "population": 140537,
    "id": 1643102939
  },
  {
    "city": "Kuwana",
    "city_ascii": "Kuwana",
    "lat": 35.0622,
    "lng": 136.6839,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Mie",
    "capital": "",
    "population": 139587,
    "id": 1392018722
  },
  {
    "city": "Kidapawan",
    "city_ascii": "Kidapawan",
    "lat": 7.0083,
    "lng": 125.0894,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Cotabato",
    "capital": "admin",
    "population": 140195,
    "id": 1608883158
  },
  {
    "city": "Santa Maria",
    "city_ascii": "Santa Maria",
    "lat": 34.9334,
    "lng": -120.4432,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 140545,
    "id": 1840021830
  },
  {
    "city": "Olathe",
    "city_ascii": "Olathe",
    "lat": 38.8832,
    "lng": -94.8198,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kansas",
    "capital": "",
    "population": 140545,
    "id": 1840003833
  },
  {
    "city": "Masaya",
    "city_ascii": "Masaya",
    "lat": 11.9667,
    "lng": -86.1,
    "country": "Nicaragua",
    "iso2": "NI",
    "iso3": "NIC",
    "admin_name": "Masaya",
    "capital": "admin",
    "population": 139582,
    "id": 1558765329
  },
  {
    "city": "Médéa",
    "city_ascii": "Medea",
    "lat": 36.2675,
    "lng": 2.75,
    "country": "Algeria",
    "iso2": "DZ",
    "iso3": "DZA",
    "admin_name": "Médéa",
    "capital": "admin",
    "population": 138355,
    "id": 1012758282
  },
  {
    "city": "Yaritagua",
    "city_ascii": "Yaritagua",
    "lat": 10.08,
    "lng": -69.1261,
    "country": "Venezuela",
    "iso2": "VE",
    "iso3": "VEN",
    "admin_name": "Yaracuy",
    "capital": "minor",
    "population": 140256,
    "id": 1862905123
  },
  {
    "city": "San Jose",
    "city_ascii": "San Jose",
    "lat": 15.7833,
    "lng": 121,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Nueva Ecija",
    "capital": "",
    "population": 139738,
    "id": 1608000478
  },
  {
    "city": "Houma",
    "city_ascii": "Houma",
    "lat": 29.5799,
    "lng": -90.7058,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 140469,
    "id": 1840015063
  },
  {
    "city": "Sāmarrā’",
    "city_ascii": "Samarra'",
    "lat": 34.1959,
    "lng": 43.8857,
    "country": "Iraq",
    "iso2": "IQ",
    "iso3": "IRQ",
    "admin_name": "Şalāḩ ad Dīn",
    "capital": "minor",
    "population": 140400,
    "id": 1368265789
  },
  {
    "city": "Koga",
    "city_ascii": "Koga",
    "lat": 36.1833,
    "lng": 139.7,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Ibaraki",
    "capital": "",
    "population": 139274,
    "id": 1392720735
  },
  {
    "city": "Sale",
    "city_ascii": "Sale",
    "lat": 53.424,
    "lng": -2.322,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Trafford",
    "capital": "",
    "population": 134022,
    "id": 1826016049
  },
  {
    "city": "Carolina",
    "city_ascii": "Carolina",
    "lat": 18.4054,
    "lng": -65.9792,
    "country": "Puerto Rico",
    "iso2": "PR",
    "iso3": "PRI",
    "admin_name": "Puerto Rico",
    "capital": "",
    "population": 140072,
    "id": 1630035637
  },
  {
    "city": "Al ‘Aqabah",
    "city_ascii": "Al `Aqabah",
    "lat": 29.5167,
    "lng": 35,
    "country": "Jordan",
    "iso2": "JO",
    "iso3": "JOR",
    "admin_name": "Al ‘Aqabah",
    "capital": "admin",
    "population": 140000,
    "id": 1400618058
  },
  {
    "city": "Torbat-e Ḩeydarīyeh",
    "city_ascii": "Torbat-e Heydariyeh",
    "lat": 35.2739,
    "lng": 59.2194,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Khorāsān-e Raẕavī",
    "capital": "minor",
    "population": 140019,
    "id": 1364398113
  },
  {
    "city": "Dawei",
    "city_ascii": "Dawei",
    "lat": 14.0367,
    "lng": 98.1867,
    "country": "Burma",
    "iso2": "MM",
    "iso3": "MMR",
    "admin_name": "Taninthayi",
    "capital": "admin",
    "population": 139900,
    "id": 1104373474
  },
  {
    "city": "Boulogne-Billancourt",
    "city_ascii": "Boulogne-Billancourt",
    "lat": 48.8352,
    "lng": 2.2409,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "minor",
    "population": 120071,
    "id": 1250978859
  },
  {
    "city": "Zielona Góra",
    "city_ascii": "Zielona Gora",
    "lat": 51.9397,
    "lng": 15.505,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Lubuskie",
    "capital": "admin",
    "population": 139330,
    "id": 1616050904
  },
  {
    "city": "Atbara",
    "city_ascii": "Atbara",
    "lat": 17.7167,
    "lng": 33.9833,
    "country": "Sudan",
    "iso2": "SD",
    "iso3": "SDN",
    "admin_name": "River Nile",
    "capital": "",
    "population": 139768,
    "id": 1729042522
  },
  {
    "city": "Tours",
    "city_ascii": "Tours",
    "lat": 47.2436,
    "lng": 0.6892,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Centre-Val de Loire",
    "capital": "minor",
    "population": 135787,
    "id": 1250635972
  },
  {
    "city": "Pakpattan",
    "city_ascii": "Pakpattan",
    "lat": 30.35,
    "lng": 73.4,
    "country": "Pakistan",
    "iso2": "PK",
    "iso3": "PAK",
    "admin_name": "Punjab",
    "capital": "minor",
    "population": 139525,
    "id": 1586409848
  },
  {
    "city": "Shizuishan",
    "city_ascii": "Shizuishan",
    "lat": 39.2333,
    "lng": 106.769,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Ningxia",
    "capital": "",
    "population": 136570,
    "id": 1156361664
  },
  {
    "city": "Tsuchiura",
    "city_ascii": "Tsuchiura",
    "lat": 36.0667,
    "lng": 140.2,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Ibaraki",
    "capital": "",
    "population": 138517,
    "id": 1392962266
  },
  {
    "city": "Chicacole",
    "city_ascii": "Chicacole",
    "lat": 18.3,
    "lng": 83.9,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Andhra Pradesh",
    "capital": "",
    "population": 137944,
    "id": 1356141471
  },
  {
    "city": "Botucatu",
    "city_ascii": "Botucatu",
    "lat": -22.8858,
    "lng": -48.445,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 139483,
    "id": 1076113865
  },
  {
    "city": "Dhahran",
    "city_ascii": "Dhahran",
    "lat": 26.2667,
    "lng": 50.15,
    "country": "Saudi Arabia",
    "iso2": "SA",
    "iso3": "SAU",
    "admin_name": "Ash Sharqīyah",
    "capital": "",
    "population": 138135,
    "id": 1682374155
  },
  {
    "city": "Pābna",
    "city_ascii": "Pabna",
    "lat": 24.0004,
    "lng": 89.25,
    "country": "Bangladesh",
    "iso2": "BD",
    "iso3": "BGD",
    "admin_name": "Rājshāhi",
    "capital": "",
    "population": 137888,
    "id": 1050424286
  },
  {
    "city": "Coquitlam",
    "city_ascii": "Coquitlam",
    "lat": 49.2839,
    "lng": -122.7919,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "British Columbia",
    "capital": "",
    "population": 139284,
    "id": 1124000500
  },
  {
    "city": "Carrollton",
    "city_ascii": "Carrollton",
    "lat": 32.989,
    "lng": -96.8999,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 139248,
    "id": 1840019436
  },
  {
    "city": "Tân An",
    "city_ascii": "Tan An",
    "lat": 10.5322,
    "lng": 106.4042,
    "country": "Vietnam",
    "iso2": "VN",
    "iso3": "VNM",
    "admin_name": "Long An",
    "capital": "admin",
    "population": 137498,
    "id": 1704199290
  },
  {
    "city": "Souk Ahras",
    "city_ascii": "Souk Ahras",
    "lat": 36.2904,
    "lng": 7.95,
    "country": "Algeria",
    "iso2": "DZ",
    "iso3": "DZA",
    "admin_name": "Souk Ahras",
    "capital": "admin",
    "population": 134947,
    "id": 1012419619
  },
  {
    "city": "Caxias",
    "city_ascii": "Caxias",
    "lat": -4.833,
    "lng": -43.35,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Maranhão",
    "capital": "",
    "population": 134640,
    "id": 1076325693
  },
  {
    "city": "Grand Junction",
    "city_ascii": "Grand Junction",
    "lat": 39.0876,
    "lng": -108.5673,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 139143,
    "id": 1840020230
  },
  {
    "city": "Zacatecas",
    "city_ascii": "Zacatecas",
    "lat": 22.7736,
    "lng": -102.5736,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Zacatecas",
    "capital": "admin",
    "population": 138152,
    "id": 1484118312
  },
  {
    "city": "Barahona",
    "city_ascii": "Barahona",
    "lat": 18.2079,
    "lng": -71.0996,
    "country": "Dominican Republic",
    "iso2": "DO",
    "iso3": "DOM",
    "admin_name": "Enriquillo",
    "capital": "admin",
    "population": 138159,
    "id": 1214900129
  },
  {
    "city": "Kokubunji",
    "city_ascii": "Kokubunji",
    "lat": 35.7108,
    "lng": 139.4622,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Tōkyō",
    "capital": "",
    "population": 127790,
    "id": 1392193917
  },
  {
    "city": "Pīrānshahr",
    "city_ascii": "Piranshahr",
    "lat": 36.6944,
    "lng": 45.1417,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Āz̄arbāyjān-e Gharbī",
    "capital": "minor",
    "population": 138864,
    "id": 1364699746
  },
  {
    "city": "Tiruvannāmalai",
    "city_ascii": "Tiruvannamalai",
    "lat": 12.2604,
    "lng": 79.1,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Tamil Nādu",
    "capital": "",
    "population": 138243,
    "id": 1356561184
  },
  {
    "city": "Amiens",
    "city_ascii": "Amiens",
    "lat": 49.892,
    "lng": 2.299,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Hauts-de-France",
    "capital": "minor",
    "population": 136105,
    "id": 1250794759
  },
  {
    "city": "Umtata",
    "city_ascii": "Umtata",
    "lat": -31.58,
    "lng": 28.79,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "Eastern Cape",
    "capital": "",
    "population": 137772,
    "id": 1710651314
  },
  {
    "city": "Charleston",
    "city_ascii": "Charleston",
    "lat": 38.3484,
    "lng": -81.6323,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "West Virginia",
    "capital": "admin",
    "population": 138706,
    "id": 1840006196
  },
  {
    "city": "Orange",
    "city_ascii": "Orange",
    "lat": 33.8038,
    "lng": -117.8218,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 138669,
    "id": 1840020582
  },
  {
    "city": "Fullerton",
    "city_ascii": "Fullerton",
    "lat": 33.8841,
    "lng": -117.9279,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 138632,
    "id": 1840020576
  },
  {
    "city": "Sancti Spíritus",
    "city_ascii": "Sancti Spiritus",
    "lat": 21.9339,
    "lng": -79.4439,
    "country": "Cuba",
    "iso2": "CU",
    "iso3": "CUB",
    "admin_name": "Sancti Spíritus",
    "capital": "admin",
    "population": 138504,
    "id": 1192600260
  },
  {
    "city": "Boulogne-sur-Mer",
    "city_ascii": "Boulogne-sur-Mer",
    "lat": 50.7264,
    "lng": 1.6147,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Hauts-de-France",
    "capital": "minor",
    "population": 133062,
    "id": 1250693185
  },
  {
    "city": "Jequié",
    "city_ascii": "Jequie",
    "lat": -13.85,
    "lng": -40.08,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Bahia",
    "capital": "",
    "population": 135574,
    "id": 1076558785
  },
  {
    "city": "Bat Yam",
    "city_ascii": "Bat Yam",
    "lat": 32.0231,
    "lng": 34.7503,
    "country": "Israel",
    "iso2": "IL",
    "iso3": "ISR",
    "admin_name": "Tel Aviv",
    "capital": "",
    "population": 128800,
    "id": 1376837517
  },
  {
    "city": "Boca del Rio",
    "city_ascii": "Boca del Rio",
    "lat": 19.1056,
    "lng": -96.1078,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Veracruz",
    "capital": "minor",
    "population": 138058,
    "id": 1484658980
  },
  {
    "city": "Mojokerto",
    "city_ascii": "Mojokerto",
    "lat": -7.4722,
    "lng": 112.4336,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Jawa Timur",
    "capital": "",
    "population": 130196,
    "id": 1360053101
  },
  {
    "city": "Darwin",
    "city_ascii": "Darwin",
    "lat": -12.4381,
    "lng": 130.8411,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Northern Territory",
    "capital": "admin",
    "population": 136828,
    "id": 1036497565
  },
  {
    "city": "Zama",
    "city_ascii": "Zama",
    "lat": 35.4886,
    "lng": 139.4075,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Kanagawa",
    "capital": "",
    "population": 130608,
    "id": 1392313741
  },
  {
    "city": "Ingolstadt",
    "city_ascii": "Ingolstadt",
    "lat": 48.7636,
    "lng": 11.4261,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "minor",
    "population": 136981,
    "id": 1276803150
  },
  {
    "city": "Mandya",
    "city_ascii": "Mandya",
    "lat": 12.5242,
    "lng": 76.8958,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Karnātaka",
    "capital": "",
    "population": 137358,
    "id": 1356039212
  },
  {
    "city": "Greeley",
    "city_ascii": "Greeley",
    "lat": 40.4151,
    "lng": -104.7706,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 137810,
    "id": 1840020149
  },
  {
    "city": "Ch’ungmu",
    "city_ascii": "Ch'ungmu",
    "lat": 34.8458,
    "lng": 128.4236,
    "country": "Korea, South",
    "iso2": "KR",
    "iso3": "KOR",
    "admin_name": "Gyeongnam",
    "capital": "",
    "population": 137208,
    "id": 1410257662
  },
  {
    "city": "Al Khmissat",
    "city_ascii": "Al Khmissat",
    "lat": 33.81,
    "lng": -6.06,
    "country": "Morocco",
    "iso2": "MA",
    "iso3": "MAR",
    "admin_name": "Rabat-Salé-Kénitra",
    "capital": "",
    "population": 131542,
    "id": 1504079907
  },
  {
    "city": "Birgañj",
    "city_ascii": "Birganj",
    "lat": 27,
    "lng": 84.8667,
    "country": "Nepal",
    "iso2": "NP",
    "iso3": "NPL",
    "admin_name": "Nārāyanī",
    "capital": "",
    "population": 135904,
    "id": 1524676691
  },
  {
    "city": "Iğdır",
    "city_ascii": "Igdir",
    "lat": 39.9167,
    "lng": 44.0333,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Iğdır",
    "capital": "admin",
    "population": 137613,
    "id": 1792813007
  },
  {
    "city": "Lira",
    "city_ascii": "Lira",
    "lat": 2.2604,
    "lng": 32.89,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Lira",
    "capital": "admin",
    "population": 135445,
    "id": 1800877735
  },
  {
    "city": "Jicheon",
    "city_ascii": "Jicheon",
    "lat": 36.1192,
    "lng": 128.1153,
    "country": "Korea, South",
    "iso2": "KR",
    "iso3": "KOR",
    "admin_name": "Gyeongbuk",
    "capital": "",
    "population": 137540,
    "id": 1410269252
  },
  {
    "city": "Yaizu",
    "city_ascii": "Yaizu",
    "lat": 34.8667,
    "lng": 138.3167,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Shizuoka",
    "capital": "",
    "population": 136807,
    "id": 1392569740
  },
  {
    "city": "Gunungsitoli",
    "city_ascii": "Gunungsitoli",
    "lat": 1.1167,
    "lng": 97.5667,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Sumatera Utara",
    "capital": "minor",
    "population": 137104,
    "id": 1360175198
  },
  {
    "city": "Danao",
    "city_ascii": "Danao",
    "lat": 10.5333,
    "lng": 123.9333,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Cebu",
    "capital": "",
    "population": 136471,
    "id": 1608547808
  },
  {
    "city": "Yunxian Chengguanzhen",
    "city_ascii": "Yunxian Chengguanzhen",
    "lat": 32.8082,
    "lng": 110.8136,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hubei",
    "capital": "minor",
    "population": 133558,
    "id": 1156455844
  },
  {
    "city": "Atibaia",
    "city_ascii": "Atibaia",
    "lat": -23.1172,
    "lng": -46.5506,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 137187,
    "id": 1076042485
  },
  {
    "city": "Jyväskylä",
    "city_ascii": "Jyvaskyla",
    "lat": 62.2333,
    "lng": 25.7333,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Keski-Suomi",
    "capital": "admin",
    "population": 137368,
    "id": 1246938345
  },
  {
    "city": "Bānkura",
    "city_ascii": "Bankura",
    "lat": 23.25,
    "lng": 87.0667,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "West Bengal",
    "capital": "",
    "population": 137386,
    "id": 1356582158
  },
  {
    "city": "Inazawa",
    "city_ascii": "Inazawa",
    "lat": 35.25,
    "lng": 136.7833,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Aichi",
    "capital": "",
    "population": 135676,
    "id": 1392019493
  },
  {
    "city": "Salerno",
    "city_ascii": "Salerno",
    "lat": 40.6806,
    "lng": 14.7597,
    "country": "Italy",
    "iso2": "IT",
    "iso3": "ITA",
    "admin_name": "Campania",
    "capital": "minor",
    "population": 135066,
    "id": 1380863448
  },
  {
    "city": "Vila Franca de Xira",
    "city_ascii": "Vila Franca de Xira",
    "lat": 38.95,
    "lng": -8.9833,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Lisboa",
    "capital": "minor",
    "population": 136886,
    "id": 1620859041
  },
  {
    "city": "Quillacollo",
    "city_ascii": "Quillacollo",
    "lat": -17.3975,
    "lng": -66.2817,
    "country": "Bolivia",
    "iso2": "BO",
    "iso3": "BOL",
    "admin_name": "Cochabamba",
    "capital": "",
    "population": 137182,
    "id": 1068265899
  },
  {
    "city": "Santa María Texmelucan",
    "city_ascii": "Santa Maria Texmelucan",
    "lat": 19.2833,
    "lng": -98.4333,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Puebla",
    "capital": "",
    "population": 135500,
    "id": 1484127851
  },
  {
    "city": "Livingstone",
    "city_ascii": "Livingstone",
    "lat": -17.85,
    "lng": 25.8667,
    "country": "Zambia",
    "iso2": "ZM",
    "iso3": "ZMB",
    "admin_name": "Southern",
    "capital": "",
    "population": 136897,
    "id": 1894634581
  },
  {
    "city": "Toowoomba",
    "city_ascii": "Toowoomba",
    "lat": -27.5667,
    "lng": 151.95,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Queensland",
    "capital": "",
    "population": 136861,
    "id": 1036765315
  },
  {
    "city": "Bima",
    "city_ascii": "Bima",
    "lat": -8.4667,
    "lng": 118.717,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Nusa Tenggara Barat",
    "capital": "",
    "population": 136492,
    "id": 1360521866
  },
  {
    "city": "Porto Amboim",
    "city_ascii": "Porto Amboim",
    "lat": -10.7183,
    "lng": 13.75,
    "country": "Angola",
    "iso2": "AO",
    "iso3": "AGO",
    "admin_name": "Kwanza Sul",
    "capital": "",
    "population": 137000,
    "id": 1024818756
  },
  {
    "city": "Maia",
    "city_ascii": "Maia",
    "lat": 41.2333,
    "lng": -8.6167,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Porto",
    "capital": "minor",
    "population": 135306,
    "id": 1620000072
  },
  {
    "city": "Tarragona",
    "city_ascii": "Tarragona",
    "lat": 41.1187,
    "lng": 1.2453,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Catalonia",
    "capital": "minor",
    "population": 134515,
    "id": 1724296961
  },
  {
    "city": "Encarnación",
    "city_ascii": "Encarnacion",
    "lat": -27.3333,
    "lng": -55.8667,
    "country": "Paraguay",
    "iso2": "PY",
    "iso3": "PRY",
    "admin_name": "Itapúa",
    "capital": "admin",
    "population": 136308,
    "id": 1600377819
  },
  {
    "city": "Kisarazu",
    "city_ascii": "Kisarazu",
    "lat": 35.3761,
    "lng": 139.9169,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Chiba",
    "capital": "",
    "population": 135765,
    "id": 1392821453
  },
  {
    "city": "Marīvān",
    "city_ascii": "Marivan",
    "lat": 35.5269,
    "lng": 46.1761,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Kordestān",
    "capital": "minor",
    "population": 136654,
    "id": 1364680490
  },
  {
    "city": "Uppsala",
    "city_ascii": "Uppsala",
    "lat": 59.8601,
    "lng": 17.64,
    "country": "Sweden",
    "iso2": "SE",
    "iso3": "SWE",
    "admin_name": "Stockholm",
    "capital": "",
    "population": 133117,
    "id": 1752330831
  },
  {
    "city": "Chech’ŏn",
    "city_ascii": "Chech'on",
    "lat": 37.1361,
    "lng": 128.2119,
    "country": "Korea, South",
    "iso2": "KR",
    "iso3": "KOR",
    "admin_name": "Chungbuk",
    "capital": "",
    "population": 136350,
    "id": 1410470747
  },
  {
    "city": "Jinotega",
    "city_ascii": "Jinotega",
    "lat": 13.0833,
    "lng": -86,
    "country": "Nicaragua",
    "iso2": "NI",
    "iso3": "NIC",
    "admin_name": "Jinotega",
    "capital": "admin",
    "population": 136342,
    "id": 1558697688
  },
  {
    "city": "Pageralam",
    "city_ascii": "Pageralam",
    "lat": -4.0217,
    "lng": 103.2522,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Sumatera Selatan",
    "capital": "minor",
    "population": 136244,
    "id": 1360027360
  },
  {
    "city": "Giresun",
    "city_ascii": "Giresun",
    "lat": 40.9,
    "lng": 38.4167,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Giresun",
    "capital": "admin",
    "population": 135920,
    "id": 1792486859
  },
  {
    "city": "Termiz",
    "city_ascii": "Termiz",
    "lat": 37.2242,
    "lng": 67.2783,
    "country": "Uzbekistan",
    "iso2": "UZ",
    "iso3": "UZB",
    "admin_name": "Surxondaryo",
    "capital": "admin",
    "population": 136200,
    "id": 1860617243
  },
  {
    "city": "Zinacantepec",
    "city_ascii": "Zinacantepec",
    "lat": 19.2833,
    "lng": -99.7333,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "México",
    "capital": "minor",
    "population": 136167,
    "id": 1484976252
  },
  {
    "city": "Târgu-Mureş",
    "city_ascii": "Targu-Mures",
    "lat": 46.5497,
    "lng": 24.5597,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Mureş",
    "capital": "admin",
    "population": 134290,
    "id": 1642875714
  },
  {
    "city": "Las Cruces",
    "city_ascii": "Las Cruces",
    "lat": 32.3265,
    "lng": -106.7892,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Mexico",
    "capital": "",
    "population": 136254,
    "id": 1840020683
  },
  {
    "city": "’s-Hertogenbosch",
    "city_ascii": "'s-Hertogenbosch",
    "lat": 51.6833,
    "lng": 5.3167,
    "country": "Netherlands",
    "iso2": "NL",
    "iso3": "NLD",
    "admin_name": "Noord-Brabant",
    "capital": "admin",
    "population": 134520,
    "id": 1528012333
  },
  {
    "city": "Panama City",
    "city_ascii": "Panama City",
    "lat": 30.1995,
    "lng": -85.6004,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 136133,
    "id": 1840015034
  },
  {
    "city": "Blitar",
    "city_ascii": "Blitar",
    "lat": -8.1,
    "lng": 112.15,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Jawa Timur",
    "capital": "",
    "population": 132018,
    "id": 1360011677
  },
  {
    "city": "Harlingen",
    "city_ascii": "Harlingen",
    "lat": 26.1917,
    "lng": -97.6976,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 136037,
    "id": 1840021036
  },
  {
    "city": "Chiang Mai",
    "city_ascii": "Chiang Mai",
    "lat": 18.7889,
    "lng": 98.9833,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Chiang Mai",
    "capital": "admin",
    "population": 132634,
    "id": 1764662886
  },
  {
    "city": "Brighton",
    "city_ascii": "Brighton",
    "lat": 50.8429,
    "lng": -0.1313,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Brighton and Hove",
    "capital": "",
    "population": 134293,
    "id": 1826609057
  },
  {
    "city": "Tobruk",
    "city_ascii": "Tobruk",
    "lat": 32.0833,
    "lng": 23.95,
    "country": "Libya",
    "iso2": "LY",
    "iso3": "LBY",
    "admin_name": "Al Buţnān",
    "capital": "admin",
    "population": 135832,
    "id": 1434068493
  },
  {
    "city": "Tauranga",
    "city_ascii": "Tauranga",
    "lat": -37.6858,
    "lng": 176.1667,
    "country": "New Zealand",
    "iso2": "NZ",
    "iso3": "NZL",
    "admin_name": "Bay of Plenty",
    "capital": "",
    "population": 135000,
    "id": 1554759867
  },
  {
    "city": "Pyay",
    "city_ascii": "Pyay",
    "lat": 18.8165,
    "lng": 95.2114,
    "country": "Burma",
    "iso2": "MM",
    "iso3": "MMR",
    "admin_name": "Bago",
    "capital": "",
    "population": 135308,
    "id": 1104978348
  },
  {
    "city": "Cartago",
    "city_ascii": "Cartago",
    "lat": 4.75,
    "lng": -75.91,
    "country": "Colombia",
    "iso2": "CO",
    "iso3": "COL",
    "admin_name": "Valle del Cauca",
    "capital": "minor",
    "population": 134827,
    "id": 1170225267
  },
  {
    "city": "Santiago",
    "city_ascii": "Santiago",
    "lat": 16.6833,
    "lng": 121.55,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Santiago",
    "capital": "admin",
    "population": 134830,
    "id": 1608477470
  },
  {
    "city": "May Pen",
    "city_ascii": "May Pen",
    "lat": 17.95,
    "lng": -77.25,
    "country": "Jamaica",
    "iso2": "JM",
    "iso3": "JAM",
    "admin_name": "Clarendon",
    "capital": "admin",
    "population": 135142,
    "id": 1388671677
  },
  {
    "city": "Urganch",
    "city_ascii": "Urganch",
    "lat": 41.5345,
    "lng": 60.6249,
    "country": "Uzbekistan",
    "iso2": "UZ",
    "iso3": "UZB",
    "admin_name": "Xorazm",
    "capital": "admin",
    "population": 135100,
    "id": 1860546433
  },
  {
    "city": "Zākhū",
    "city_ascii": "Zakhu",
    "lat": 37.1436,
    "lng": 42.6819,
    "country": "Iraq",
    "iso2": "IQ",
    "iso3": "IRQ",
    "admin_name": "Dahūk",
    "capital": "minor",
    "population": 134863,
    "id": 1368905231
  },
  {
    "city": "Dumaguete City",
    "city_ascii": "Dumaguete City",
    "lat": 9.3103,
    "lng": 123.3081,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Negros Oriental",
    "capital": "admin",
    "population": 131377,
    "id": 1608682936
  },
  {
    "city": "West Valley City",
    "city_ascii": "West Valley City",
    "lat": 40.6889,
    "lng": -112.0115,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Utah",
    "capital": "",
    "population": 135248,
    "id": 1840021389
  },
  {
    "city": "Shuangcheng",
    "city_ascii": "Shuangcheng",
    "lat": 45.3503,
    "lng": 126.28,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Heilongjiang",
    "capital": "",
    "population": 130710,
    "id": 1156257611
  },
  {
    "city": "Andīmeshk",
    "city_ascii": "Andimeshk",
    "lat": 32.45,
    "lng": 48.35,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Khūzestān",
    "capital": "minor",
    "population": 135116,
    "id": 1364453657
  },
  {
    "city": "Ōme",
    "city_ascii": "Ome",
    "lat": 35.7883,
    "lng": 139.275,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Tōkyō",
    "capital": "",
    "population": 133824,
    "id": 1392575345
  },
  {
    "city": "Tabaco",
    "city_ascii": "Tabaco",
    "lat": 13.35,
    "lng": 123.7333,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Albay",
    "capital": "",
    "population": 133868,
    "id": 1608252596
  },
  {
    "city": "Nakhon Ratchasima",
    "city_ascii": "Nakhon Ratchasima",
    "lat": 14.9736,
    "lng": 102.0831,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Nakhon Ratchasima",
    "capital": "admin",
    "population": 134440,
    "id": 1764752197
  },
  {
    "city": "Isahaya",
    "city_ascii": "Isahaya",
    "lat": 32.8442,
    "lng": 130.0536,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Nagasaki",
    "capital": "",
    "population": 134503,
    "id": 1392443480
  },
  {
    "city": "Ashqelon",
    "city_ascii": "Ashqelon",
    "lat": 31.6658,
    "lng": 34.5664,
    "country": "Israel",
    "iso2": "IL",
    "iso3": "ISR",
    "admin_name": "Southern",
    "capital": "",
    "population": 134454,
    "id": 1376324059
  },
  {
    "city": "Shahreẕā",
    "city_ascii": "Shahreza",
    "lat": 32.0089,
    "lng": 51.8667,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Eşfahān",
    "capital": "minor",
    "population": 134592,
    "id": 1364242660
  },
  {
    "city": "Zābol",
    "city_ascii": "Zabol",
    "lat": 31.0308,
    "lng": 61.4972,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Sīstān va Balūchestān",
    "capital": "minor",
    "population": 134590,
    "id": 1364485195
  },
  {
    "city": "Ramapo",
    "city_ascii": "Ramapo",
    "lat": 41.1404,
    "lng": -74.1072,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 134571,
    "id": 1840058431
  },
  {
    "city": "Hampton",
    "city_ascii": "Hampton",
    "lat": 37.0551,
    "lng": -76.363,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 134510,
    "id": 1840003866
  },
  {
    "city": "Calapan",
    "city_ascii": "Calapan",
    "lat": 13.3667,
    "lng": 121.2,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Oriental Mindoro",
    "capital": "admin",
    "population": 133893,
    "id": 1608578259
  },
  {
    "city": "Trois-Rivières",
    "city_ascii": "Trois-Rivieres",
    "lat": 46.35,
    "lng": -72.55,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 134413,
    "id": 1124407487
  },
  {
    "city": "Urdaneta",
    "city_ascii": "Urdaneta",
    "lat": 15.9761,
    "lng": 120.5711,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Pangasinan",
    "capital": "",
    "population": 132940,
    "id": 1608117383
  },
  {
    "city": "Milagro",
    "city_ascii": "Milagro",
    "lat": -2.1347,
    "lng": -79.5872,
    "country": "Ecuador",
    "iso2": "EC",
    "iso3": "ECU",
    "admin_name": "Guayas",
    "capital": "",
    "population": 133508,
    "id": 1218568564
  },
  {
    "city": "Heroica Guaymas",
    "city_ascii": "Heroica Guaymas",
    "lat": 27.9183,
    "lng": -110.8989,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Sonora",
    "capital": "minor",
    "population": 134153,
    "id": 1484689704
  },
  {
    "city": "Apatzingan de la Constitucion",
    "city_ascii": "Apatzingan de la Constitucion",
    "lat": 19.0886,
    "lng": -102.3508,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Michoacán de Ocampo",
    "capital": "",
    "population": 128250,
    "id": 1484693242
  },
  {
    "city": "Batticaloa",
    "city_ascii": "Batticaloa",
    "lat": 7.717,
    "lng": 81.7,
    "country": "Sri Lanka",
    "iso2": "LK",
    "iso3": "LKA",
    "admin_name": "Eastern",
    "capital": "",
    "population": 129222,
    "id": 1144464659
  },
  {
    "city": "Reẖovot",
    "city_ascii": "Rehovot",
    "lat": 31.8914,
    "lng": 34.8078,
    "country": "Israel",
    "iso2": "IL",
    "iso3": "ISR",
    "admin_name": "Central",
    "capital": "",
    "population": 132671,
    "id": 1376684821
  },
  {
    "city": "Abiko",
    "city_ascii": "Abiko",
    "lat": 35.8642,
    "lng": 140.0282,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Chiba",
    "capital": "",
    "population": 131026,
    "id": 1392000342
  },
  {
    "city": "Idfū",
    "city_ascii": "Idfu",
    "lat": 24.9781,
    "lng": 32.8789,
    "country": "Egypt",
    "iso2": "EG",
    "iso3": "EGY",
    "admin_name": "Aswān",
    "capital": "",
    "population": 133772,
    "id": 1818755693
  },
  {
    "city": "Tando Allahyar",
    "city_ascii": "Tando Allahyar",
    "lat": 25.4667,
    "lng": 68.7167,
    "country": "Pakistan",
    "iso2": "PK",
    "iso3": "PAK",
    "admin_name": "Sindh",
    "capital": "minor",
    "population": 133487,
    "id": 1586001015
  },
  {
    "city": "Warren",
    "city_ascii": "Warren",
    "lat": 42.4934,
    "lng": -83.027,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 133943,
    "id": 1840002418
  },
  {
    "city": "Mauldin",
    "city_ascii": "Mauldin",
    "lat": 34.7849,
    "lng": -82.3005,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 133872,
    "id": 1840015476
  },
  {
    "city": "Bloomington",
    "city_ascii": "Bloomington",
    "lat": 40.4757,
    "lng": -88.9703,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 133846,
    "id": 1840007207
  },
  {
    "city": "Enfield",
    "city_ascii": "Enfield",
    "lat": 51.6522,
    "lng": -0.0808,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Enfield",
    "capital": "",
    "population": 132640,
    "id": 1826362409
  },
  {
    "city": "Ségou",
    "city_ascii": "Segou",
    "lat": 13.45,
    "lng": -6.2667,
    "country": "Mali",
    "iso2": "ML",
    "iso3": "MLI",
    "admin_name": "Ségou",
    "capital": "admin",
    "population": 133501,
    "id": 1466684950
  },
  {
    "city": "Coral Springs",
    "city_ascii": "Coral Springs",
    "lat": 26.2702,
    "lng": -80.2591,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 133759,
    "id": 1840015134
  },
  {
    "city": "Innsbruck",
    "city_ascii": "Innsbruck",
    "lat": 47.2683,
    "lng": 11.3933,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Tirol",
    "capital": "admin",
    "population": 132493,
    "id": 1040038924
  },
  {
    "city": "Battambang",
    "city_ascii": "Battambang",
    "lat": 13.1,
    "lng": 103.2,
    "country": "Cambodia",
    "iso2": "KH",
    "iso3": "KHM",
    "admin_name": "Battambang",
    "capital": "admin",
    "population": 130000,
    "id": 1116106237
  },
  {
    "city": "Hassan",
    "city_ascii": "Hassan",
    "lat": 13.005,
    "lng": 76.1028,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Karnātaka",
    "capital": "",
    "population": 133436,
    "id": 1356007455
  },
  {
    "city": "Round Rock",
    "city_ascii": "Round Rock",
    "lat": 30.5254,
    "lng": -97.666,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 133372,
    "id": 1840020879
  },
  {
    "city": "Yakima",
    "city_ascii": "Yakima",
    "lat": 46.5923,
    "lng": -120.5496,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 133191,
    "id": 1840021154
  },
  {
    "city": "Khorramshahr",
    "city_ascii": "Khorramshahr",
    "lat": 30.4333,
    "lng": 48.1833,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Khūzestān",
    "capital": "minor",
    "population": 133097,
    "id": 1364111149
  },
  {
    "city": "Onomichi",
    "city_ascii": "Onomichi",
    "lat": 34.4089,
    "lng": 133.205,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hiroshima",
    "capital": "",
    "population": 132675,
    "id": 1392886423
  },
  {
    "city": "Papeete",
    "city_ascii": "Papeete",
    "lat": -17.5334,
    "lng": -149.5667,
    "country": "French Polynesia",
    "iso2": "PF",
    "iso3": "PYF",
    "admin_name": "Îles du Vent",
    "capital": "",
    "population": 131695,
    "id": 1258907380
  },
  {
    "city": "Ouargla",
    "city_ascii": "Ouargla",
    "lat": 31.95,
    "lng": 5.3167,
    "country": "Algeria",
    "iso2": "DZ",
    "iso3": "DZA",
    "admin_name": "Ouargla",
    "capital": "admin",
    "population": 133024,
    "id": 1012425687
  },
  {
    "city": "Trà Vinh",
    "city_ascii": "Tra Vinh",
    "lat": 9.9369,
    "lng": 106.3411,
    "country": "Vietnam",
    "iso2": "VN",
    "iso3": "VNM",
    "admin_name": "Trà Vinh",
    "capital": "admin",
    "population": 131360,
    "id": 1704929966
  },
  {
    "city": "Narita",
    "city_ascii": "Narita",
    "lat": 35.7833,
    "lng": 140.3167,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Chiba",
    "capital": "",
    "population": 132293,
    "id": 1392216588
  },
  {
    "city": "Ninh Bình",
    "city_ascii": "Ninh Binh",
    "lat": 20.2539,
    "lng": 105.975,
    "country": "Vietnam",
    "iso2": "VN",
    "iso3": "VNM",
    "admin_name": "Ninh Bình",
    "capital": "admin",
    "population": 130517,
    "id": 1704442748
  },
  {
    "city": "San Carlos",
    "city_ascii": "San Carlos",
    "lat": 10.4929,
    "lng": 123.4095,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Negros Occidental",
    "capital": "",
    "population": 132536,
    "id": 1608048026
  },
  {
    "city": "Győr",
    "city_ascii": "Gyor",
    "lat": 47.6842,
    "lng": 17.6344,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Győr-Moson-Sopron",
    "capital": "admin",
    "population": 132034,
    "id": 1348507878
  },
  {
    "city": "Ödemiş",
    "city_ascii": "Odemis",
    "lat": 38.2311,
    "lng": 27.9719,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "İzmir",
    "capital": "minor",
    "population": 132511,
    "id": 1792140925
  },
  {
    "city": "Kamëz",
    "city_ascii": "Kamez",
    "lat": 41.3833,
    "lng": 19.7667,
    "country": "Albania",
    "iso2": "AL",
    "iso3": "ALB",
    "admin_name": "Tiranë",
    "capital": "",
    "population": 126777,
    "id": 1008148968
  },
  {
    "city": "San Luis",
    "city_ascii": "San Luis",
    "lat": 16.2,
    "lng": -89.44,
    "country": "Guatemala",
    "iso2": "GT",
    "iso3": "GTM",
    "admin_name": "Petén",
    "capital": "minor",
    "population": 132470,
    "id": 1320642245
  },
  {
    "city": "Ibarra",
    "city_ascii": "Ibarra",
    "lat": 0.3628,
    "lng": -78.13,
    "country": "Ecuador",
    "iso2": "EC",
    "iso3": "ECU",
    "admin_name": "Imbabura",
    "capital": "admin",
    "population": 131856,
    "id": 1218479054
  },
  {
    "city": "Sterling Heights",
    "city_ascii": "Sterling Heights",
    "lat": 42.5809,
    "lng": -83.0305,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 132438,
    "id": 1840003094
  },
  {
    "city": "Stavanger",
    "city_ascii": "Stavanger",
    "lat": 58.9701,
    "lng": 5.7333,
    "country": "Norway",
    "iso2": "NO",
    "iso3": "NOR",
    "admin_name": "Rogaland",
    "capital": "admin",
    "population": 129300,
    "id": 1578801684
  },
  {
    "city": "Yavatmāl",
    "city_ascii": "Yavatmal",
    "lat": 20.4,
    "lng": 78.1333,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Mahārāshtra",
    "capital": "",
    "population": 132000,
    "id": 1356201218
  },
  {
    "city": "Ferrara",
    "city_ascii": "Ferrara",
    "lat": 44.8353,
    "lng": 11.6199,
    "country": "Italy",
    "iso2": "IT",
    "iso3": "ITA",
    "admin_name": "Emilia-Romagna",
    "capital": "minor",
    "population": 132009,
    "id": 1380682411
  },
  {
    "city": "Kent",
    "city_ascii": "Kent",
    "lat": 47.3887,
    "lng": -122.2129,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 132319,
    "id": 1840018424
  },
  {
    "city": "Karabük",
    "city_ascii": "Karabuk",
    "lat": 41.2,
    "lng": 32.6333,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Karabük",
    "capital": "admin",
    "population": 131989,
    "id": 1792834766
  },
  {
    "city": "High Wycombe",
    "city_ascii": "High Wycombe",
    "lat": 51.6287,
    "lng": -0.7482,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Buckinghamshire",
    "capital": "",
    "population": 125257,
    "id": 1826609342
  },
  {
    "city": "Jaraguá do Sul",
    "city_ascii": "Jaragua do Sul",
    "lat": -26.48,
    "lng": -49.1,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Santa Catarina",
    "capital": "",
    "population": 130130,
    "id": 1076588128
  },
  {
    "city": "Calabozo",
    "city_ascii": "Calabozo",
    "lat": 8.9219,
    "lng": -67.4283,
    "country": "Venezuela",
    "iso2": "VE",
    "iso3": "VEN",
    "admin_name": "Guárico",
    "capital": "minor",
    "population": 131989,
    "id": 1862623441
  },
  {
    "city": "Lae",
    "city_ascii": "Lae",
    "lat": -6.7333,
    "lng": 146.9833,
    "country": "Papua New Guinea",
    "iso2": "PG",
    "iso3": "PNG",
    "admin_name": "Morobe",
    "capital": "admin",
    "population": 131052,
    "id": 1598466091
  },
  {
    "city": "Kigoma",
    "city_ascii": "Kigoma",
    "lat": -4.8833,
    "lng": 29.6333,
    "country": "Tanzania",
    "iso2": "TZ",
    "iso3": "TZA",
    "admin_name": "Kigoma",
    "capital": "admin",
    "population": 130142,
    "id": 1834583327
  },
  {
    "city": "Pīlibhīt",
    "city_ascii": "Pilibhit",
    "lat": 28.64,
    "lng": 79.81,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Uttar Pradesh",
    "capital": "",
    "population": 131008,
    "id": 1356147005
  },
  {
    "city": "Guelph",
    "city_ascii": "Guelph",
    "lat": 43.55,
    "lng": -80.25,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 131794,
    "id": 1124968815
  },
  {
    "city": "Pālghāt",
    "city_ascii": "Palghat",
    "lat": 10.7792,
    "lng": 76.6547,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Kerala",
    "capital": "",
    "population": 130955,
    "id": 1356115164
  },
  {
    "city": "Buea",
    "city_ascii": "Buea",
    "lat": 4.1667,
    "lng": 9.2333,
    "country": "Cameroon",
    "iso2": "CM",
    "iso3": "CMR",
    "admin_name": "South-West",
    "capital": "admin",
    "population": 131325,
    "id": 1120497849
  },
  {
    "city": "Los Guayos",
    "city_ascii": "Los Guayos",
    "lat": 10.1833,
    "lng": -67.9333,
    "country": "Venezuela",
    "iso2": "VE",
    "iso3": "VEN",
    "admin_name": "Carabobo",
    "capital": "minor",
    "population": 130345,
    "id": 1862264170
  },
  {
    "city": "Rijeka",
    "city_ascii": "Rijeka",
    "lat": 45.3297,
    "lng": 14.4322,
    "country": "Croatia",
    "iso2": "HR",
    "iso3": "HRV",
    "admin_name": "Primorsko-Goranska Županija",
    "capital": "admin",
    "population": 128624,
    "id": 1191949063
  },
  {
    "city": "Spanish Town",
    "city_ascii": "Spanish Town",
    "lat": 17.9961,
    "lng": -76.9547,
    "country": "Jamaica",
    "iso2": "JM",
    "iso3": "JAM",
    "admin_name": "Saint Catherine",
    "capital": "admin",
    "population": 131056,
    "id": 1388689196
  },
  {
    "city": "Pālakollu",
    "city_ascii": "Palakollu",
    "lat": 16.5333,
    "lng": 81.7333,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Andhra Pradesh",
    "capital": "",
    "population": 129717,
    "id": 1356365783
  },
  {
    "city": "Kislovodsk",
    "city_ascii": "Kislovodsk",
    "lat": 43.91,
    "lng": 42.72,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Stavropol’skiy Kray",
    "capital": "",
    "population": 129593,
    "id": 1643705192
  },
  {
    "city": "Dipolog",
    "city_ascii": "Dipolog",
    "lat": 8.5667,
    "lng": 123.3333,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Zamboanga del Norte",
    "capital": "admin",
    "population": 130759,
    "id": 1608664300
  },
  {
    "city": "Surat Thani",
    "city_ascii": "Surat Thani",
    "lat": 9.1397,
    "lng": 99.3306,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Surat Thani",
    "capital": "admin",
    "population": 130703,
    "id": 1764338230
  },
  {
    "city": "Ciénaga",
    "city_ascii": "Cienaga",
    "lat": 11.0104,
    "lng": -74.25,
    "country": "Colombia",
    "iso2": "CO",
    "iso3": "COL",
    "admin_name": "Magdalena",
    "capital": "minor",
    "population": 131171,
    "id": 1170825751
  },
  {
    "city": "Parepare",
    "city_ascii": "Parepare",
    "lat": -4.0167,
    "lng": 119.6236,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Sulawesi Selatan",
    "capital": "",
    "population": 129542,
    "id": 1360021476
  },
  {
    "city": "Girardot",
    "city_ascii": "Girardot",
    "lat": 4.31,
    "lng": -74.81,
    "country": "Colombia",
    "iso2": "CO",
    "iso3": "COL",
    "admin_name": "Cundinamarca",
    "capital": "minor",
    "population": 130289,
    "id": 1170838212
  },
  {
    "city": "Burlington",
    "city_ascii": "Burlington",
    "lat": 36.0758,
    "lng": -79.4686,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 131043,
    "id": 1840013362
  },
  {
    "city": "Abohar",
    "city_ascii": "Abohar",
    "lat": 30.1204,
    "lng": 74.29,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Punjab",
    "capital": "",
    "population": 130603,
    "id": 1356846795
  },
  {
    "city": "Tychy",
    "city_ascii": "Tychy",
    "lat": 50.1667,
    "lng": 19,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Śląskie",
    "capital": "minor",
    "population": 129322,
    "id": 1616396493
  },
  {
    "city": "Marand",
    "city_ascii": "Marand",
    "lat": 38.4167,
    "lng": 45.7667,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Āz̄arbāyjān-e Sharqī",
    "capital": "minor",
    "population": 130825,
    "id": 1364567639
  },
  {
    "city": "Quibdó",
    "city_ascii": "Quibdo",
    "lat": 5.6923,
    "lng": -76.6582,
    "country": "Colombia",
    "iso2": "CO",
    "iso3": "COL",
    "admin_name": "Chocó",
    "capital": "admin",
    "population": 130825,
    "id": 1170186734
  },
  {
    "city": "Kānchrāpāra",
    "city_ascii": "Kanchrapara",
    "lat": 22.97,
    "lng": 88.43,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "West Bengal",
    "capital": "",
    "population": 129576,
    "id": 1356022687
  },
  {
    "city": "Annecy",
    "city_ascii": "Annecy",
    "lat": 45.916,
    "lng": 6.133,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Auvergne-Rhône-Alpes",
    "capital": "minor",
    "population": 126924,
    "id": 1250551848
  },
  {
    "city": "Bellingham",
    "city_ascii": "Bellingham",
    "lat": 48.7543,
    "lng": -122.4687,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 130641,
    "id": 1840018346
  },
  {
    "city": "Kozan",
    "city_ascii": "Kozan",
    "lat": 37.4507,
    "lng": 35.8123,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Adana",
    "capital": "minor",
    "population": 130495,
    "id": 1792567030
  },
  {
    "city": "Udon Thani",
    "city_ascii": "Udon Thani",
    "lat": 17.4164,
    "lng": 102.75,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Udon Thani",
    "capital": "admin",
    "population": 130274,
    "id": 1764676047
  },
  {
    "city": "Iwakuni",
    "city_ascii": "Iwakuni",
    "lat": 34.1669,
    "lng": 132.2197,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Yamaguchi",
    "capital": "",
    "population": 130372,
    "id": 1392032573
  },
  {
    "city": "Jīroft",
    "city_ascii": "Jiroft",
    "lat": 28.6781,
    "lng": 57.7406,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Kermān",
    "capital": "minor",
    "population": 130429,
    "id": 1364826721
  },
  {
    "city": "Tabora",
    "city_ascii": "Tabora",
    "lat": -5.0167,
    "lng": 32.8,
    "country": "Tanzania",
    "iso2": "TZ",
    "iso3": "TZA",
    "admin_name": "Tabora",
    "capital": "admin",
    "population": 127880,
    "id": 1834712235
  },
  {
    "city": "Santa Clara",
    "city_ascii": "Santa Clara",
    "lat": 37.3645,
    "lng": -121.968,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 130365,
    "id": 1840021571
  },
  {
    "city": "Västerås",
    "city_ascii": "Vasteras",
    "lat": 59.6173,
    "lng": 16.5422,
    "country": "Sweden",
    "iso2": "SE",
    "iso3": "SWE",
    "admin_name": "Västmanland",
    "capital": "admin",
    "population": 127799,
    "id": 1752826955
  },
  {
    "city": "Çayırova",
    "city_ascii": "Cayirova",
    "lat": 40.8265,
    "lng": 29.3745,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Kocaeli",
    "capital": "minor",
    "population": 129655,
    "id": 1792546631
  },
  {
    "city": "Sannār",
    "city_ascii": "Sannar",
    "lat": 13.55,
    "lng": 33.6,
    "country": "Sudan",
    "iso2": "SD",
    "iso3": "SDN",
    "admin_name": "Sennar",
    "capital": "",
    "population": 130122,
    "id": 1729986512
  },
  {
    "city": "Lucheng",
    "city_ascii": "Lucheng",
    "lat": 30.05,
    "lng": 101.9667,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Sichuan",
    "capital": "minor",
    "population": 130142,
    "id": 1156314517
  },
  {
    "city": "Racine",
    "city_ascii": "Racine",
    "lat": 42.7274,
    "lng": -87.8135,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 130142,
    "id": 1840002493
  },
  {
    "city": "Greenville",
    "city_ascii": "Greenville",
    "lat": 35.5956,
    "lng": -77.3768,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 130118,
    "id": 1840013407
  },
  {
    "city": "Estelí",
    "city_ascii": "Esteli",
    "lat": 13.0833,
    "lng": -86.35,
    "country": "Nicaragua",
    "iso2": "NI",
    "iso3": "NIC",
    "admin_name": "Estelí",
    "capital": "admin",
    "population": 129924,
    "id": 1558801854
  },
  {
    "city": "Taldyqorghan",
    "city_ascii": "Taldyqorghan",
    "lat": 45.0167,
    "lng": 78.3667,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Almaty",
    "capital": "admin",
    "population": 129960,
    "id": 1398112507
  },
  {
    "city": "Cambridge",
    "city_ascii": "Cambridge",
    "lat": 43.3972,
    "lng": -80.3114,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 129920,
    "id": 1124113576
  },
  {
    "city": "Calabayan",
    "city_ascii": "Calabayan",
    "lat": 16.7667,
    "lng": 121.7833,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Isabela",
    "capital": "",
    "population": 129523,
    "id": 1608955576
  },
  {
    "city": "Baidoa",
    "city_ascii": "Baidoa",
    "lat": 3.1167,
    "lng": 43.65,
    "country": "Somalia",
    "iso2": "SO",
    "iso3": "SOM",
    "admin_name": "Bay",
    "capital": "admin",
    "population": 129839,
    "id": 1706563100
  },
  {
    "city": "Jaramānā",
    "city_ascii": "Jaramana",
    "lat": 33.4833,
    "lng": 36.35,
    "country": "Syria",
    "iso2": "SY",
    "iso3": "SYR",
    "admin_name": "Rīf Dimashq",
    "capital": "minor",
    "population": 114363,
    "id": 1760709370
  },
  {
    "city": "Fürth",
    "city_ascii": "Furth",
    "lat": 49.4783,
    "lng": 10.9903,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "minor",
    "population": 127748,
    "id": 1276580165
  },
  {
    "city": "Serpukhov",
    "city_ascii": "Serpukhov",
    "lat": 54.9167,
    "lng": 37.4,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Moskovskaya Oblast’",
    "capital": "",
    "population": 125817,
    "id": 1643395657
  },
  {
    "city": "Bolton",
    "city_ascii": "Bolton",
    "lat": 53.578,
    "lng": -2.429,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Bolton",
    "capital": "",
    "population": 128139,
    "id": 1826628353
  },
  {
    "city": "Tukuyu",
    "city_ascii": "Tukuyu",
    "lat": -9.2583,
    "lng": 33.6417,
    "country": "Tanzania",
    "iso2": "TZ",
    "iso3": "TZA",
    "admin_name": "Mbeya",
    "capital": "",
    "population": 127570,
    "id": 1834960117
  },
  {
    "city": "Stamford",
    "city_ascii": "Stamford",
    "lat": 41.1035,
    "lng": -73.5583,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 129638,
    "id": 1840004841
  },
  {
    "city": "Villa Alemana",
    "city_ascii": "Villa Alemana",
    "lat": -33.0428,
    "lng": -71.3744,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Valparaíso",
    "capital": "",
    "population": 125140,
    "id": 1152839066
  },
  {
    "city": "Kadoma",
    "city_ascii": "Kadoma",
    "lat": 34.7333,
    "lng": 135.5833,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Ōsaka",
    "capital": "",
    "population": 119692,
    "id": 1392702415
  },
  {
    "city": "Táriba",
    "city_ascii": "Tariba",
    "lat": 7.8167,
    "lng": -72.2167,
    "country": "Venezuela",
    "iso2": "VE",
    "iso3": "VEN",
    "admin_name": "Táchira",
    "capital": "minor",
    "population": 128590,
    "id": 1862780259
  },
  {
    "city": "Würzburg",
    "city_ascii": "Wurzburg",
    "lat": 49.7944,
    "lng": 9.9294,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "minor",
    "population": 127880,
    "id": 1276233514
  },
  {
    "city": "Songnim",
    "city_ascii": "Songnim",
    "lat": 38.7333,
    "lng": 125.6333,
    "country": "Korea, North",
    "iso2": "KP",
    "iso3": "PRK",
    "admin_name": "Hwangbuk",
    "capital": "",
    "population": 128831,
    "id": 1408214405
  },
  {
    "city": "Mansa",
    "city_ascii": "Mansa",
    "lat": -11.1822,
    "lng": 28.8833,
    "country": "Zambia",
    "iso2": "ZM",
    "iso3": "ZMB",
    "admin_name": "Luapula",
    "capital": "admin",
    "population": 129185,
    "id": 1894038366
  },
  {
    "city": "Elizabeth",
    "city_ascii": "Elizabeth",
    "lat": 40.6657,
    "lng": -74.1912,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 129216,
    "id": 1840003613
  },
  {
    "city": "Opole",
    "city_ascii": "Opole",
    "lat": 50.6722,
    "lng": 17.9253,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Opolskie",
    "capital": "admin",
    "population": 127792,
    "id": 1616096837
  },
  {
    "city": "Novocheboksarsk",
    "city_ascii": "Novocheboksarsk",
    "lat": 56.1167,
    "lng": 47.5,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Chuvashiya",
    "capital": "",
    "population": 126626,
    "id": 1643839131
  },
  {
    "city": "Araras",
    "city_ascii": "Araras",
    "lat": -22.3569,
    "lng": -47.3839,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 128895,
    "id": 1076656148
  },
  {
    "city": "Romford",
    "city_ascii": "Romford",
    "lat": 51.5768,
    "lng": 0.1801,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Havering",
    "capital": "",
    "population": 122854,
    "id": 1826695421
  },
  {
    "city": "Mīt Ghamr",
    "city_ascii": "Mit Ghamr",
    "lat": 30.7192,
    "lng": 31.2628,
    "country": "Egypt",
    "iso2": "EG",
    "iso3": "EGY",
    "admin_name": "Ad Daqahlīyah",
    "capital": "",
    "population": 121039,
    "id": 1818400389
  },
  {
    "city": "Puno",
    "city_ascii": "Puno",
    "lat": -15.8433,
    "lng": -70.0236,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "Puno",
    "capital": "admin",
    "population": 128637,
    "id": 1604227042
  },
  {
    "city": "Jaranwala",
    "city_ascii": "Jaranwala",
    "lat": 31.3342,
    "lng": 73.4194,
    "country": "Pakistan",
    "iso2": "PK",
    "iso3": "PAK",
    "admin_name": "Punjab",
    "capital": "",
    "population": 127973,
    "id": 1586829520
  },
  {
    "city": "Salto",
    "city_ascii": "Salto",
    "lat": -31.3883,
    "lng": -57.9606,
    "country": "Uruguay",
    "iso2": "UY",
    "iso3": "URY",
    "admin_name": "Salto",
    "capital": "admin",
    "population": 124878,
    "id": 1858575950
  },
  {
    "city": "Newport",
    "city_ascii": "Newport",
    "lat": 51.5833,
    "lng": -3,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Newport",
    "capital": "",
    "population": 128060,
    "id": 1826018889
  },
  {
    "city": "Alagoinhas",
    "city_ascii": "Alagoinhas",
    "lat": -12.1356,
    "lng": -38.4192,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Bahia",
    "capital": "",
    "population": 124070,
    "id": 1076155953
  },
  {
    "city": "Ōmiyachō",
    "city_ascii": "Omiyacho",
    "lat": 35.2222,
    "lng": 138.6214,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Shizuoka",
    "capital": "",
    "population": 128501,
    "id": 1392347988
  },
  {
    "city": "Kamina",
    "city_ascii": "Kamina",
    "lat": -8.73,
    "lng": 25.01,
    "country": "Congo (Kinshasa)",
    "iso2": "CD",
    "iso3": "COD",
    "admin_name": "Haut-Lomami",
    "capital": "admin",
    "population": 128803,
    "id": 1180363197
  },
  {
    "city": "Seto",
    "city_ascii": "Seto",
    "lat": 35.2167,
    "lng": 137.0833,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Aichi",
    "capital": "",
    "population": 127653,
    "id": 1392028874
  },
  {
    "city": "Pinsk",
    "city_ascii": "Pinsk",
    "lat": 52.1228,
    "lng": 26.095,
    "country": "Belarus",
    "iso2": "BY",
    "iso3": "BLR",
    "admin_name": "Brestskaya Voblasts’",
    "capital": "minor",
    "population": 126300,
    "id": 1112209279
  },
  {
    "city": "Vihari",
    "city_ascii": "Vihari",
    "lat": 30.0419,
    "lng": 72.3528,
    "country": "Pakistan",
    "iso2": "PK",
    "iso3": "PAK",
    "admin_name": "Punjab",
    "capital": "minor",
    "population": 128034,
    "id": 1586888846
  },
  {
    "city": "Pôrto Seguro",
    "city_ascii": "Porto Seguro",
    "lat": -16.4296,
    "lng": -39.08,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Bahia",
    "capital": "",
    "population": 123173,
    "id": 1076932180
  },
  {
    "city": "Koforidua",
    "city_ascii": "Koforidua",
    "lat": 6.1,
    "lng": -0.2667,
    "country": "Ghana",
    "iso2": "GH",
    "iso3": "GHA",
    "admin_name": "Eastern",
    "capital": "admin",
    "population": 127334,
    "id": 1288961962
  },
  {
    "city": "Ixtlahuaca",
    "city_ascii": "Ixtlahuaca",
    "lat": 19.5689,
    "lng": -99.7669,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "México",
    "capital": "minor",
    "population": 126505,
    "id": 1484837068
  },
  {
    "city": "Temperley",
    "city_ascii": "Temperley",
    "lat": -34.7667,
    "lng": -58.3833,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires",
    "capital": "",
    "population": 121451,
    "id": 1032183944
  },
  {
    "city": "Bataysk",
    "city_ascii": "Bataysk",
    "lat": 47.1333,
    "lng": 39.75,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Rostovskaya Oblast’",
    "capital": "",
    "population": 126769,
    "id": 1643522051
  },
  {
    "city": "Whitby",
    "city_ascii": "Whitby",
    "lat": 43.8833,
    "lng": -78.9417,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 128377,
    "id": 1124112077
  },
  {
    "city": "Sakākā",
    "city_ascii": "Sakaka",
    "lat": 30,
    "lng": 40.1333,
    "country": "Saudi Arabia",
    "iso2": "SA",
    "iso3": "SAU",
    "admin_name": "Al Jawf",
    "capital": "admin",
    "population": 128332,
    "id": 1682232132
  },
  {
    "city": "Tumen",
    "city_ascii": "Tumen",
    "lat": 42.9661,
    "lng": 129.8425,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Jilin",
    "capital": "",
    "population": 128212,
    "id": 1156872358
  },
  {
    "city": "Örebro",
    "city_ascii": "Orebro",
    "lat": 59.2669,
    "lng": 15.1965,
    "country": "Sweden",
    "iso2": "SE",
    "iso3": "SWE",
    "admin_name": "Örebro",
    "capital": "admin",
    "population": 125817,
    "id": 1752223019
  },
  {
    "city": "Mỹ Tho",
    "city_ascii": "My Tho",
    "lat": 10.35,
    "lng": 106.35,
    "country": "Vietnam",
    "iso2": "VN",
    "iso3": "VNM",
    "admin_name": "Tiền Giang",
    "capital": "admin",
    "population": 124143,
    "id": 1704000376
  },
  {
    "city": "Hāthras",
    "city_ascii": "Hathras",
    "lat": 27.6,
    "lng": 78.05,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Uttar Pradesh",
    "capital": "",
    "population": 126882,
    "id": 1356072147
  },
  {
    "city": "Divo",
    "city_ascii": "Divo",
    "lat": 5.8372,
    "lng": -5.3572,
    "country": "Côte D’Ivoire",
    "iso2": "CI",
    "iso3": "CIV",
    "admin_name": "Gôh-Djiboua",
    "capital": "minor",
    "population": 127867,
    "id": 1384831436
  },
  {
    "city": "Guasdualito",
    "city_ascii": "Guasdualito",
    "lat": 7.2467,
    "lng": -70.7292,
    "country": "Venezuela",
    "iso2": "VE",
    "iso3": "VEN",
    "admin_name": "Apure",
    "capital": "minor",
    "population": 128025,
    "id": 1862834503
  },
  {
    "city": "Domodedovo",
    "city_ascii": "Domodedovo",
    "lat": 55.4333,
    "lng": 37.75,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Moskovskaya Oblast’",
    "capital": "",
    "population": 127850,
    "id": 1643918019
  },
  {
    "city": "Darnah",
    "city_ascii": "Darnah",
    "lat": 32.7648,
    "lng": 22.6391,
    "country": "Libya",
    "iso2": "LY",
    "iso3": "LBY",
    "admin_name": "Darnah",
    "capital": "admin",
    "population": 127974,
    "id": 1434920152
  },
  {
    "city": "Port Blair",
    "city_ascii": "Port Blair",
    "lat": 11.6667,
    "lng": 92.75,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Andaman and Nicobar Islands",
    "capital": "admin",
    "population": 127562,
    "id": 1356161713
  },
  {
    "city": "Cubatão",
    "city_ascii": "Cubatao",
    "lat": -23.8953,
    "lng": -46.4256,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 127006,
    "id": 1076384228
  },
  {
    "city": "Jāzān",
    "city_ascii": "Jazan",
    "lat": 16.8892,
    "lng": 42.5611,
    "country": "Saudi Arabia",
    "iso2": "SA",
    "iso3": "SAU",
    "admin_name": "Jāzān",
    "capital": "admin",
    "population": 127743,
    "id": 1682515652
  },
  {
    "city": "Anderlecht",
    "city_ascii": "Anderlecht",
    "lat": 50.8392,
    "lng": 4.3297,
    "country": "Belgium",
    "iso2": "BE",
    "iso3": "BEL",
    "admin_name": "Brussels-Capital Region",
    "capital": "",
    "population": 120887,
    "id": 1056297471
  },
  {
    "city": "Alīpur Duār",
    "city_ascii": "Alipur Duar",
    "lat": 26.4837,
    "lng": 89.5667,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "West Bengal",
    "capital": "",
    "population": 127342,
    "id": 1356472105
  },
  {
    "city": "Neftekamsk",
    "city_ascii": "Neftekamsk",
    "lat": 56.0833,
    "lng": 54.25,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Bashkortostan",
    "capital": "",
    "population": 126805,
    "id": 1643718660
  },
  {
    "city": "Johnson City",
    "city_ascii": "Johnson City",
    "lat": 36.3406,
    "lng": -82.3803,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 127571,
    "id": 1840014462
  },
  {
    "city": "Silay",
    "city_ascii": "Silay",
    "lat": 10.8,
    "lng": 122.9667,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Negros Occidental",
    "capital": "",
    "population": 126930,
    "id": 1608362038
  },
  {
    "city": "Chilapa de Álvarez",
    "city_ascii": "Chilapa de Alvarez",
    "lat": 17.5944,
    "lng": -99.1778,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Guerrero",
    "capital": "minor",
    "population": 120790,
    "id": 1484562441
  },
  {
    "city": "León",
    "city_ascii": "Leon",
    "lat": 42.5989,
    "lng": -5.5669,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Castille-Leon",
    "capital": "minor",
    "population": 124303,
    "id": 1724907542
  },
  {
    "city": "Bam",
    "city_ascii": "Bam",
    "lat": 29.1083,
    "lng": 58.3583,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Kermān",
    "capital": "minor",
    "population": 127396,
    "id": 1364697653
  },
  {
    "city": "Ulm",
    "city_ascii": "Ulm",
    "lat": 48.3984,
    "lng": 9.9916,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "minor",
    "population": 126329,
    "id": 1276002212
  },
  {
    "city": "Lafia",
    "city_ascii": "Lafia",
    "lat": 8.4904,
    "lng": 8.52,
    "country": "Nigeria",
    "iso2": "NG",
    "iso3": "NGA",
    "admin_name": "Nasarawa",
    "capital": "admin",
    "population": 127236,
    "id": 1566042542
  },
  {
    "city": "Tây Ninh",
    "city_ascii": "Tay Ninh",
    "lat": 11.3131,
    "lng": 106.0963,
    "country": "Vietnam",
    "iso2": "VN",
    "iso3": "VNM",
    "admin_name": "Tây Ninh",
    "capital": "admin",
    "population": 126370,
    "id": 1704250951
  },
  {
    "city": "Iizuka",
    "city_ascii": "Iizuka",
    "lat": 33.6458,
    "lng": 130.6914,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Fukuoka",
    "capital": "",
    "population": 126715,
    "id": 1392365784
  },
  {
    "city": "Santana de Parnaíba",
    "city_ascii": "Santana de Parnaiba",
    "lat": -23.4439,
    "lng": -46.9178,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 126574,
    "id": 1076401391
  },
  {
    "city": "Heilbronn",
    "city_ascii": "Heilbronn",
    "lat": 49.1404,
    "lng": 9.218,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "minor",
    "population": 125960,
    "id": 1276377359
  },
  {
    "city": "Rustavi",
    "city_ascii": "Rustavi",
    "lat": 41.5333,
    "lng": 45,
    "country": "Georgia",
    "iso2": "GE",
    "iso3": "GEO",
    "admin_name": "Kvemo Kartli",
    "capital": "admin",
    "population": 125103,
    "id": 1268562807
  },
  {
    "city": "Pakokku",
    "city_ascii": "Pakokku",
    "lat": 21.332,
    "lng": 95.0866,
    "country": "Burma",
    "iso2": "MM",
    "iso3": "MMR",
    "admin_name": "Magway",
    "capital": "",
    "population": 126938,
    "id": 1104161315
  },
  {
    "city": "Payakumbuh",
    "city_ascii": "Payakumbuh",
    "lat": -0.2244,
    "lng": 100.6325,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Sumatera Barat",
    "capital": "",
    "population": 125608,
    "id": 1360091636
  },
  {
    "city": "Angren",
    "city_ascii": "Angren",
    "lat": 41.0167,
    "lng": 70.1333,
    "country": "Uzbekistan",
    "iso2": "UZ",
    "iso3": "UZB",
    "admin_name": "Toshkent",
    "capital": "",
    "population": 126962,
    "id": 1860045958
  },
  {
    "city": "Sogamoso",
    "city_ascii": "Sogamoso",
    "lat": 5.72,
    "lng": -72.94,
    "country": "Colombia",
    "iso2": "CO",
    "iso3": "COL",
    "admin_name": "Boyacá",
    "capital": "minor",
    "population": 126551,
    "id": 1170000109
  },
  {
    "city": "Leiria",
    "city_ascii": "Leiria",
    "lat": 39.7431,
    "lng": -8.8069,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Leiria",
    "capital": "admin",
    "population": 126897,
    "id": 1620304777
  },
  {
    "city": "Basīrhat",
    "city_ascii": "Basirhat",
    "lat": 22.6572,
    "lng": 88.8942,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "West Bengal",
    "capital": "",
    "population": 125254,
    "id": 1356109985
  },
  {
    "city": "Miramar",
    "city_ascii": "Miramar",
    "lat": 22.3375,
    "lng": -97.8694,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Tamaulipas",
    "capital": "",
    "population": 118614,
    "id": 1484353090
  },
  {
    "city": "Leeuwarden",
    "city_ascii": "Leeuwarden",
    "lat": 53.2504,
    "lng": 5.7834,
    "country": "Netherlands",
    "iso2": "NL",
    "iso3": "NLD",
    "admin_name": "Fryslân",
    "capital": "admin",
    "population": 125778,
    "id": 1528992603
  },
  {
    "city": "Gafsa",
    "city_ascii": "Gafsa",
    "lat": 34.4204,
    "lng": 8.78,
    "country": "Tunisia",
    "iso2": "TN",
    "iso3": "TUN",
    "admin_name": "Gafsa",
    "capital": "admin",
    "population": 126803,
    "id": 1788454569
  },
  {
    "city": "Ünye",
    "city_ascii": "Unye",
    "lat": 41.1271,
    "lng": 37.2882,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Ordu",
    "capital": "minor",
    "population": 126702,
    "id": 1792053028
  },
  {
    "city": "Ramos Mejía",
    "city_ascii": "Ramos Mejia",
    "lat": -34.65,
    "lng": -58.5667,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires",
    "capital": "",
    "population": 120346,
    "id": 1032425368
  },
  {
    "city": "Navadwīp",
    "city_ascii": "Navadwip",
    "lat": 23.4088,
    "lng": 88.3657,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "West Bengal",
    "capital": "",
    "population": 125543,
    "id": 1356449184
  },
  {
    "city": "Pforzheim",
    "city_ascii": "Pforzheim",
    "lat": 48.895,
    "lng": 8.705,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "minor",
    "population": 125542,
    "id": 1276732622
  },
  {
    "city": "Exeter",
    "city_ascii": "Exeter",
    "lat": 50.7167,
    "lng": -3.5333,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Devon",
    "capital": "",
    "population": 124180,
    "id": 1826840903
  },
  {
    "city": "Lynchburg",
    "city_ascii": "Lynchburg",
    "lat": 37.4003,
    "lng": -79.1909,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 126814,
    "id": 1840003855
  },
  {
    "city": "Guntakal",
    "city_ascii": "Guntakal",
    "lat": 15.17,
    "lng": 77.38,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Andhra Pradesh",
    "capital": "",
    "population": 126270,
    "id": 1356140724
  },
  {
    "city": "Pangkalpinang",
    "city_ascii": "Pangkalpinang",
    "lat": -2.1,
    "lng": 106.1,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Kepulauan Bangka Belitung",
    "capital": "admin",
    "population": 125933,
    "id": 1360901919
  },
  {
    "city": "Latina",
    "city_ascii": "Latina",
    "lat": 41.4676,
    "lng": 12.9037,
    "country": "Italy",
    "iso2": "IT",
    "iso3": "ITA",
    "admin_name": "Lazio",
    "capital": "minor",
    "population": 126181,
    "id": 1380947157
  },
  {
    "city": "Simi Valley",
    "city_ascii": "Simi Valley",
    "lat": 34.2663,
    "lng": -118.749,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 126589,
    "id": 1840021843
  },
  {
    "city": "Daitōchō",
    "city_ascii": "Daitocho",
    "lat": 34.7119,
    "lng": 135.6233,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Ōsaka",
    "capital": "",
    "population": 119986,
    "id": 1392727709
  },
  {
    "city": "Aalborg",
    "city_ascii": "Aalborg",
    "lat": 57.0337,
    "lng": 9.9166,
    "country": "Denmark",
    "iso2": "DK",
    "iso3": "DNK",
    "admin_name": "Nordjylland",
    "capital": "admin",
    "population": 122219,
    "id": 1208789278
  },
  {
    "city": "Guelma",
    "city_ascii": "Guelma",
    "lat": 36.466,
    "lng": 7.428,
    "country": "Algeria",
    "iso2": "DZ",
    "iso3": "DZA",
    "admin_name": "Guelma",
    "capital": "admin",
    "population": 123590,
    "id": 1012266321
  },
  {
    "city": "Barbacena",
    "city_ascii": "Barbacena",
    "lat": -21.2258,
    "lng": -43.7739,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Minas Gerais",
    "capital": "",
    "population": 126284,
    "id": 1076877003
  },
  {
    "city": "Monza",
    "city_ascii": "Monza",
    "lat": 45.5836,
    "lng": 9.2736,
    "country": "Italy",
    "iso2": "IT",
    "iso3": "ITA",
    "admin_name": "Lombardy",
    "capital": "minor",
    "population": 122728,
    "id": 1380622727
  },
  {
    "city": "Tengyue",
    "city_ascii": "Tengyue",
    "lat": 25.0333,
    "lng": 98.4666,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Yunnan",
    "capital": "minor",
    "population": 126058,
    "id": 1156832866
  },
  {
    "city": "Solihull",
    "city_ascii": "Solihull",
    "lat": 52.413,
    "lng": -1.778,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Solihull",
    "capital": "",
    "population": 123187,
    "id": 1826720500
  },
  {
    "city": "Dunedin",
    "city_ascii": "Dunedin",
    "lat": -45.8667,
    "lng": 170.5,
    "country": "New Zealand",
    "iso2": "NZ",
    "iso3": "NZL",
    "admin_name": "Otago",
    "capital": "admin",
    "population": 126255,
    "id": 1554475997
  },
  {
    "city": "Hālīsahar",
    "city_ascii": "Halisahar",
    "lat": 22.95,
    "lng": 88.42,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "West Bengal",
    "capital": "",
    "population": 124939,
    "id": 1356242644
  },
  {
    "city": "Nefteyugansk",
    "city_ascii": "Nefteyugansk",
    "lat": 61.1,
    "lng": 72.6,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Khanty-Mansiyskiy Avtonomnyy Okrug-Yugra",
    "capital": "",
    "population": 126157,
    "id": 1643009656
  },
  {
    "city": "Magway",
    "city_ascii": "Magway",
    "lat": 20.15,
    "lng": 94.95,
    "country": "Burma",
    "iso2": "MM",
    "iso3": "MMR",
    "admin_name": "Magway",
    "capital": "admin",
    "population": 125973,
    "id": 1104246517
  },
  {
    "city": "Shashemenē",
    "city_ascii": "Shashemene",
    "lat": 7.2004,
    "lng": 38.59,
    "country": "Ethiopia",
    "iso2": "ET",
    "iso3": "ETH",
    "admin_name": "Oromīya",
    "capital": "",
    "population": 114350,
    "id": 1231110370
  },
  {
    "city": "Rishra",
    "city_ascii": "Rishra",
    "lat": 22.71,
    "lng": 88.35,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "West Bengal",
    "capital": "",
    "population": 124577,
    "id": 1356339732
  },
  {
    "city": "Malanje",
    "city_ascii": "Malanje",
    "lat": -9.54,
    "lng": 16.34,
    "country": "Angola",
    "iso2": "AO",
    "iso3": "AGO",
    "admin_name": "Malanje",
    "capital": "admin",
    "population": 125856,
    "id": 1024774947
  },
  {
    "city": "Magelang",
    "city_ascii": "Magelang",
    "lat": -7.4706,
    "lng": 110.2178,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Jawa Tengah",
    "capital": "",
    "population": 124912,
    "id": 1360065728
  },
  {
    "city": "Gashua",
    "city_ascii": "Gashua",
    "lat": 12.8705,
    "lng": 11.04,
    "country": "Nigeria",
    "iso2": "NG",
    "iso3": "NGA",
    "admin_name": "Yobe",
    "capital": "minor",
    "population": 125817,
    "id": 1566925429
  },
  {
    "city": "Shchelkovo",
    "city_ascii": "Shchelkovo",
    "lat": 55.9167,
    "lng": 38,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Moskovskaya Oblast’",
    "capital": "",
    "population": 125634,
    "id": 1643006516
  },
  {
    "city": "Kampong Cham",
    "city_ascii": "Kampong Cham",
    "lat": 12,
    "lng": 105.45,
    "country": "Cambodia",
    "iso2": "KH",
    "iso3": "KHM",
    "admin_name": "Kampong Cham",
    "capital": "admin",
    "population": 118242,
    "id": 1116115849
  },
  {
    "city": "Santa Tecla",
    "city_ascii": "Santa Tecla",
    "lat": 13.674,
    "lng": -89.29,
    "country": "El Salvador",
    "iso2": "SV",
    "iso3": "SLV",
    "admin_name": "La Libertad",
    "capital": "admin",
    "population": 124694,
    "id": 1222613306
  },
  {
    "city": "Novomoskovsk",
    "city_ascii": "Novomoskovsk",
    "lat": 54.0333,
    "lng": 38.2667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Tul’skaya Oblast’",
    "capital": "",
    "population": 125647,
    "id": 1643119962
  },
  {
    "city": "Gorzów Wielkopolski",
    "city_ascii": "Gorzow Wielkopolski",
    "lat": 52.7333,
    "lng": 15.25,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Lubuskie",
    "capital": "admin",
    "population": 124295,
    "id": 1616788025
  },
  {
    "city": "Cam Ranh",
    "city_ascii": "Cam Ranh",
    "lat": 11.9136,
    "lng": 109.1369,
    "country": "Vietnam",
    "iso2": "VN",
    "iso3": "VNM",
    "admin_name": "Khánh Hòa",
    "capital": "minor",
    "population": 125311,
    "id": 1704516746
  },
  {
    "city": "Giugliano in Campania",
    "city_ascii": "Giugliano in Campania",
    "lat": 40.9319,
    "lng": 14.1956,
    "country": "Italy",
    "iso2": "IT",
    "iso3": "ITA",
    "admin_name": "Campania",
    "capital": "",
    "population": 124361,
    "id": 1380386690
  },
  {
    "city": "Kumbo",
    "city_ascii": "Kumbo",
    "lat": 6.2,
    "lng": 10.66,
    "country": "Cameroon",
    "iso2": "CM",
    "iso3": "CMR",
    "admin_name": "North-West",
    "capital": "",
    "population": 125486,
    "id": 1120679288
  },
  {
    "city": "Cagua",
    "city_ascii": "Cagua",
    "lat": 10.1831,
    "lng": -67.45,
    "country": "Venezuela",
    "iso2": "VE",
    "iso3": "VEN",
    "admin_name": "Aragua",
    "capital": "minor",
    "population": 122571,
    "id": 1862805374
  },
  {
    "city": "Cádiz",
    "city_ascii": "Cadiz",
    "lat": 36.535,
    "lng": -6.2975,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Andalusia",
    "capital": "minor",
    "population": 116027,
    "id": 1724502822
  },
  {
    "city": "Jandira",
    "city_ascii": "Jandira",
    "lat": -23.5278,
    "lng": -46.9028,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 118832,
    "id": 1076873964
  },
  {
    "city": "Ghardaïa",
    "city_ascii": "Ghardaia",
    "lat": 32.49,
    "lng": 3.67,
    "country": "Algeria",
    "iso2": "DZ",
    "iso3": "DZA",
    "admin_name": "Ghardaïa",
    "capital": "admin",
    "population": 125480,
    "id": 1012074690
  },
  {
    "city": "Gateshead",
    "city_ascii": "Gateshead",
    "lat": 54.95,
    "lng": -1.6,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Gateshead",
    "capital": "",
    "population": 120046,
    "id": 1826635849
  },
  {
    "city": "Honchō",
    "city_ascii": "Honcho",
    "lat": 35.7581,
    "lng": 139.5297,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Tōkyō",
    "capital": "",
    "population": 116482,
    "id": 1392994259
  },
  {
    "city": "Uruguaiana",
    "city_ascii": "Uruguaiana",
    "lat": -29.755,
    "lng": -57.0878,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Rio Grande do Sul",
    "capital": "",
    "population": 125435,
    "id": 1076680267
  },
  {
    "city": "Curicó",
    "city_ascii": "Curico",
    "lat": -34.9854,
    "lng": -71.2394,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Maule",
    "capital": "minor",
    "population": 125275,
    "id": 1152564798
  },
  {
    "city": "Orizaba",
    "city_ascii": "Orizaba",
    "lat": 18.85,
    "lng": -97.1,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Veracruz",
    "capital": "minor",
    "population": 117273,
    "id": 1484766767
  },
  {
    "city": "Ciudad de Atlixco",
    "city_ascii": "Ciudad de Atlixco",
    "lat": 18.9,
    "lng": -98.45,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Puebla",
    "capital": "minor",
    "population": 125000,
    "id": 1484268457
  },
  {
    "city": "Fort Smith",
    "city_ascii": "Fort Smith",
    "lat": 35.3493,
    "lng": -94.3695,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arkansas",
    "capital": "",
    "population": 125354,
    "id": 1840013456
  },
  {
    "city": "Resende",
    "city_ascii": "Resende",
    "lat": -22.4689,
    "lng": -44.4469,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Rio de Janeiro",
    "capital": "",
    "population": 125214,
    "id": 1076678781
  },
  {
    "city": "Polatlı",
    "city_ascii": "Polatli",
    "lat": 39.5842,
    "lng": 32.1472,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Ankara",
    "capital": "minor",
    "population": 125075,
    "id": 1792124981
  },
  {
    "city": "Porlamar",
    "city_ascii": "Porlamar",
    "lat": 10.9556,
    "lng": -63.8478,
    "country": "Venezuela",
    "iso2": "VE",
    "iso3": "VEN",
    "admin_name": "Nueva Esparta",
    "capital": "minor",
    "population": 120924,
    "id": 1862693736
  },
  {
    "city": "Kenosha",
    "city_ascii": "Kenosha",
    "lat": 42.5864,
    "lng": -87.8765,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 124972,
    "id": 1840003160
  },
  {
    "city": "Amherst",
    "city_ascii": "Amherst",
    "lat": 43.0117,
    "lng": -78.7569,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 124952,
    "id": 1840057194
  },
  {
    "city": "Gingoog",
    "city_ascii": "Gingoog",
    "lat": 8.8167,
    "lng": 125.1,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Misamis Oriental",
    "capital": "",
    "population": 124648,
    "id": 1608630986
  },
  {
    "city": "Dūmā",
    "city_ascii": "Duma",
    "lat": 33.5722,
    "lng": 36.4019,
    "country": "Syria",
    "iso2": "SY",
    "iso3": "SYR",
    "admin_name": "Rīf Dimashq",
    "capital": "minor",
    "population": 123494,
    "id": 1760821818
  },
  {
    "city": "Wolfsburg",
    "city_ascii": "Wolfsburg",
    "lat": 52.4231,
    "lng": 10.7872,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "minor",
    "population": 124151,
    "id": 1276033981
  },
  {
    "city": "South Lyon",
    "city_ascii": "South Lyon",
    "lat": 42.4614,
    "lng": -83.6526,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 124694,
    "id": 1840002432
  },
  {
    "city": "Pouso Alegre",
    "city_ascii": "Pouso Alegre",
    "lat": -22.22,
    "lng": -45.94,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Minas Gerais",
    "capital": "",
    "population": 115201,
    "id": 1076082333
  },
  {
    "city": "Maastricht",
    "city_ascii": "Maastricht",
    "lat": 50.853,
    "lng": 5.677,
    "country": "Netherlands",
    "iso2": "NL",
    "iso3": "NLD",
    "admin_name": "Limburg",
    "capital": "admin",
    "population": 122378,
    "id": 1528563623
  },
  {
    "city": "Pervouralsk",
    "city_ascii": "Pervouralsk",
    "lat": 56.9167,
    "lng": 59.95,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sverdlovskaya Oblast’",
    "capital": "",
    "population": 124447,
    "id": 1643845220
  },
  {
    "city": "Hosan",
    "city_ascii": "Hosan",
    "lat": 36.2039,
    "lng": 127.0847,
    "country": "Korea, South",
    "iso2": "KR",
    "iso3": "KOR",
    "admin_name": "Chungnam",
    "capital": "",
    "population": 124246,
    "id": 1410009966
  },
  {
    "city": "Ise",
    "city_ascii": "Ise",
    "lat": 34.4833,
    "lng": 136.7167,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Mie",
    "capital": "",
    "population": 123783,
    "id": 1392000398
  },
  {
    "city": "Boulder",
    "city_ascii": "Boulder",
    "lat": 40.0249,
    "lng": -105.2523,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 124345,
    "id": 1840018774
  },
  {
    "city": "Bimbo",
    "city_ascii": "Bimbo",
    "lat": 4.3313,
    "lng": 18.5163,
    "country": "Central African Republic",
    "iso2": "CF",
    "iso3": "CAF",
    "admin_name": "Ombella-Mpoko",
    "capital": "admin",
    "population": 124176,
    "id": 1140257478
  },
  {
    "city": "Baia Mare",
    "city_ascii": "Baia Mare",
    "lat": 47.6597,
    "lng": 23.5819,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Maramureş",
    "capital": "admin",
    "population": 123738,
    "id": 1642459769
  },
  {
    "city": "Savannakhet",
    "city_ascii": "Savannakhet",
    "lat": 16.55,
    "lng": 104.75,
    "country": "Laos",
    "iso2": "LA",
    "iso3": "LAO",
    "admin_name": "Savannakhét",
    "capital": "admin",
    "population": 124000,
    "id": 1418179232
  },
  {
    "city": "Al Ḩajar al Aswad",
    "city_ascii": "Al Hajar al Aswad",
    "lat": 33.4581,
    "lng": 36.3053,
    "country": "Syria",
    "iso2": "SY",
    "iso3": "SYR",
    "admin_name": "Rīf Dimashq",
    "capital": "minor",
    "population": 101827,
    "id": 1760587307
  },
  {
    "city": "Kadirli",
    "city_ascii": "Kadirli",
    "lat": 37.3739,
    "lng": 36.0961,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Osmaniye",
    "capital": "minor",
    "population": 124053,
    "id": 1792113133
  },
  {
    "city": "Brits",
    "city_ascii": "Brits",
    "lat": -25.6167,
    "lng": 27.7667,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "North West",
    "capital": "",
    "population": 122497,
    "id": 1710468143
  },
  {
    "city": "Cherkessk",
    "city_ascii": "Cherkessk",
    "lat": 44.2222,
    "lng": 42.0575,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Karachayevo-Cherkesiya",
    "capital": "admin",
    "population": 122395,
    "id": 1643955713
  },
  {
    "city": "Indramayu",
    "city_ascii": "Indramayu",
    "lat": -6.3356,
    "lng": 108.319,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Jawa Barat",
    "capital": "minor",
    "population": 123263,
    "id": 1360004807
  },
  {
    "city": "Higüey",
    "city_ascii": "Higuey",
    "lat": 18.616,
    "lng": -68.708,
    "country": "Dominican Republic",
    "iso2": "DO",
    "iso3": "DOM",
    "admin_name": "Yuma",
    "capital": "minor",
    "population": 123787,
    "id": 1214313712
  },
  {
    "city": "Rudnyy",
    "city_ascii": "Rudnyy",
    "lat": 52.9527,
    "lng": 63.13,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Qostanay",
    "capital": "",
    "population": 124000,
    "id": 1398701954
  },
  {
    "city": "Khrustalnyi",
    "city_ascii": "Khrustalnyi",
    "lat": 48.1214,
    "lng": 38.9453,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Luhans’ka Oblast’",
    "capital": "",
    "population": 124000,
    "id": 1804368412
  },
  {
    "city": "Honmachi",
    "city_ascii": "Honmachi",
    "lat": 32.5178,
    "lng": 130.6181,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Kumamoto",
    "capital": "",
    "population": 123758,
    "id": 1392126782
  },
  {
    "city": "Pescara",
    "city_ascii": "Pescara",
    "lat": 42.4643,
    "lng": 14.2142,
    "country": "Italy",
    "iso2": "IT",
    "iso3": "ITA",
    "admin_name": "Abruzzo",
    "capital": "minor",
    "population": 120286,
    "id": 1380000966
  },
  {
    "city": "San Pedro Garza García",
    "city_ascii": "San Pedro Garza Garcia",
    "lat": 25.6667,
    "lng": -100.3,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Nuevo León",
    "capital": "minor",
    "population": 122009,
    "id": 1484228590
  },
  {
    "city": "Pleven",
    "city_ascii": "Pleven",
    "lat": 43.4078,
    "lng": 24.6203,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Pleven",
    "capital": "admin",
    "population": 123588,
    "id": 1100973719
  },
  {
    "city": "Hội An",
    "city_ascii": "Hoi An",
    "lat": 15.8733,
    "lng": 108.3327,
    "country": "Vietnam",
    "iso2": "VN",
    "iso3": "VNM",
    "admin_name": "Quảng Nam",
    "capital": "",
    "population": 121716,
    "id": 1704897050
  },
  {
    "city": "Derbent",
    "city_ascii": "Derbent",
    "lat": 42.0692,
    "lng": 48.2958,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Dagestan",
    "capital": "minor",
    "population": 123720,
    "id": 1643003556
  },
  {
    "city": "Preston",
    "city_ascii": "Preston",
    "lat": 53.759,
    "lng": -2.699,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Lancashire",
    "capital": "",
    "population": 122719,
    "id": 1826018478
  },
  {
    "city": "Villa de Álvarez",
    "city_ascii": "Villa de Alvarez",
    "lat": 19.25,
    "lng": -103.7333,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Colima",
    "capital": "minor",
    "population": 117600,
    "id": 1484653806
  },
  {
    "city": "Pati",
    "city_ascii": "Pati",
    "lat": -6.7415,
    "lng": 111.0347,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Jawa Tengah",
    "capital": "minor",
    "population": 122785,
    "id": 1360407556
  },
  {
    "city": "Tsuruoka",
    "city_ascii": "Tsuruoka",
    "lat": 38.7217,
    "lng": 139.8217,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Yamagata",
    "capital": "",
    "population": 123437,
    "id": 1392935450
  },
  {
    "city": "Munūf",
    "city_ascii": "Munuf",
    "lat": 30.4667,
    "lng": 30.9333,
    "country": "Egypt",
    "iso2": "EG",
    "iso3": "EGY",
    "admin_name": "Al Minūfīyah",
    "capital": "",
    "population": 117240,
    "id": 1818310821
  },
  {
    "city": "Gagnoa",
    "city_ascii": "Gagnoa",
    "lat": 6.1333,
    "lng": -5.9333,
    "country": "Côte D’Ivoire",
    "iso2": "CI",
    "iso3": "CIV",
    "admin_name": "Gôh-Djiboua",
    "capital": "admin",
    "population": 123184,
    "id": 1384138010
  },
  {
    "city": "Punta Arenas",
    "city_ascii": "Punta Arenas",
    "lat": -53.1627,
    "lng": -70.9081,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Magallanes y de la Antártica Chilena",
    "capital": "admin",
    "population": 123403,
    "id": 1152262933
  },
  {
    "city": "Dąbrowa Górnicza",
    "city_ascii": "Dabrowa Gornicza",
    "lat": 50.3239,
    "lng": 19.1947,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Śląskie",
    "capital": "minor",
    "population": 122712,
    "id": 1616312023
  },
  {
    "city": "Munch’ŏn",
    "city_ascii": "Munch'on",
    "lat": 39.259,
    "lng": 127.356,
    "country": "Korea, North",
    "iso2": "KP",
    "iso3": "PRK",
    "admin_name": "Kangwŏn",
    "capital": "",
    "population": 122934,
    "id": 1408134393
  },
  {
    "city": "Khénifra",
    "city_ascii": "Khenifra",
    "lat": 32.93,
    "lng": -5.66,
    "country": "Morocco",
    "iso2": "MA",
    "iso3": "MAR",
    "admin_name": "Béni Mellal-Khénifra",
    "capital": "",
    "population": 117510,
    "id": 1504143530
  },
  {
    "city": "Alberton",
    "city_ascii": "Alberton",
    "lat": -26.2672,
    "lng": 28.1219,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "Gauteng",
    "capital": "",
    "population": 121536,
    "id": 1710738396
  },
  {
    "city": "Lianhe",
    "city_ascii": "Lianhe",
    "lat": 47.1364,
    "lng": 129.2859,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Heilongjiang",
    "capital": "minor",
    "population": 121367,
    "id": 1156101860
  },
  {
    "city": "San Fernando",
    "city_ascii": "San Fernando",
    "lat": 16.6167,
    "lng": 120.3167,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "La Union",
    "capital": "admin",
    "population": 121812,
    "id": 1608635527
  },
  {
    "city": "Jirjā",
    "city_ascii": "Jirja",
    "lat": 26.3419,
    "lng": 31.8894,
    "country": "Egypt",
    "iso2": "EG",
    "iso3": "EGY",
    "admin_name": "Sūhāj",
    "capital": "",
    "population": 102597,
    "id": 1818327836
  },
  {
    "city": "Patnos",
    "city_ascii": "Patnos",
    "lat": 39.2358,
    "lng": 42.8686,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Ağrı",
    "capital": "minor",
    "population": 122833,
    "id": 1792386113
  },
  {
    "city": "Bergamo",
    "city_ascii": "Bergamo",
    "lat": 45.695,
    "lng": 9.67,
    "country": "Italy",
    "iso2": "IT",
    "iso3": "ITA",
    "admin_name": "Lombardy",
    "capital": "minor",
    "population": 119806,
    "id": 1380253521
  },
  {
    "city": "Blackburn",
    "city_ascii": "Blackburn",
    "lat": 53.748,
    "lng": -2.482,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Blackburn with Darwen",
    "capital": "",
    "population": 117963,
    "id": 1826802533
  },
  {
    "city": "Barretos",
    "city_ascii": "Barretos",
    "lat": -20.5569,
    "lng": -48.5678,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 119243,
    "id": 1076042195
  },
  {
    "city": "Machiques",
    "city_ascii": "Machiques",
    "lat": 10.0667,
    "lng": -72.5667,
    "country": "Venezuela",
    "iso2": "VE",
    "iso3": "VEN",
    "admin_name": "Zulia",
    "capital": "minor",
    "population": 122734,
    "id": 1862003182
  },
  {
    "city": "Ciudad Hidalgo",
    "city_ascii": "Ciudad Hidalgo",
    "lat": 19.6923,
    "lng": -100.5574,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Michoacán de Ocampo",
    "capital": "minor",
    "population": 122619,
    "id": 1484580926
  },
  {
    "city": "Jalapa",
    "city_ascii": "Jalapa",
    "lat": 14.6379,
    "lng": -89.9904,
    "country": "Guatemala",
    "iso2": "GT",
    "iso3": "GTM",
    "admin_name": "Jalapa",
    "capital": "admin",
    "population": 122483,
    "id": 1320108089
  },
  {
    "city": "Yonashiro-teruma",
    "city_ascii": "Yonashiro-teruma",
    "lat": 26.3792,
    "lng": 127.8575,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Okinawa",
    "capital": "",
    "population": 121294,
    "id": 1392716881
  },
  {
    "city": "Behbahān",
    "city_ascii": "Behbahan",
    "lat": 30.5958,
    "lng": 50.2417,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Khūzestān",
    "capital": "minor",
    "population": 122604,
    "id": 1364393434
  },
  {
    "city": "Elbląg",
    "city_ascii": "Elblag",
    "lat": 54.1667,
    "lng": 19.4,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Warmińsko-Mazurskie",
    "capital": "minor",
    "population": 122568,
    "id": 1616395994
  },
  {
    "city": "Baidyabāti",
    "city_ascii": "Baidyabati",
    "lat": 22.79,
    "lng": 88.32,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "West Bengal",
    "capital": "",
    "population": 121110,
    "id": 1356079081
  },
  {
    "city": "Pearland",
    "city_ascii": "Pearland",
    "lat": 29.5585,
    "lng": -95.3215,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 122460,
    "id": 1840020979
  },
  {
    "city": "Mufulira",
    "city_ascii": "Mufulira",
    "lat": -12.5546,
    "lng": 28.2604,
    "country": "Zambia",
    "iso2": "ZM",
    "iso3": "ZMB",
    "admin_name": "Copperbelt",
    "capital": "",
    "population": 122336,
    "id": 1894907880
  },
  {
    "city": "Maina",
    "city_ascii": "Maina",
    "lat": 13.4692,
    "lng": 144.7332,
    "country": "Guam",
    "iso2": "GU",
    "iso3": "GUM",
    "admin_name": "",
    "capital": "",
    "population": 122411,
    "id": 1316874021
  },
  {
    "city": "Kashiwara",
    "city_ascii": "Kashiwara",
    "lat": 34.5094,
    "lng": 135.7925,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Nara",
    "capital": "",
    "population": 122003,
    "id": 1392686221
  },
  {
    "city": "Kotamobagu",
    "city_ascii": "Kotamobagu",
    "lat": 0.7333,
    "lng": 124.3167,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Sulawesi Utara",
    "capital": "",
    "population": 120597,
    "id": 1360015626
  },
  {
    "city": "Dharmavaram",
    "city_ascii": "Dharmavaram",
    "lat": 14.4142,
    "lng": 77.715,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Andhra Pradesh",
    "capital": "",
    "population": 121874,
    "id": 1356879569
  },
  {
    "city": "Edéa",
    "city_ascii": "Edea",
    "lat": 3.8,
    "lng": 10.1333,
    "country": "Cameroon",
    "iso2": "CM",
    "iso3": "CMR",
    "admin_name": "Littoral",
    "capital": "",
    "population": 122300,
    "id": 1120546542
  },
  {
    "city": "Setúbal",
    "city_ascii": "Setubal",
    "lat": 38.5243,
    "lng": -8.8926,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Setúbal",
    "capital": "admin",
    "population": 121185,
    "id": 1620174557
  },
  {
    "city": "Montreuil",
    "city_ascii": "Montreuil",
    "lat": 48.8611,
    "lng": 2.4436,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 109897,
    "id": 1250689747
  },
  {
    "city": "Ciudad Ojeda",
    "city_ascii": "Ciudad Ojeda",
    "lat": 10.2,
    "lng": -71.3,
    "country": "Venezuela",
    "iso2": "VE",
    "iso3": "VEN",
    "admin_name": "Zulia",
    "capital": "minor",
    "population": 122124,
    "id": 1862967909
  },
  {
    "city": "Pedro Juan Caballero",
    "city_ascii": "Pedro Juan Caballero",
    "lat": -22.5667,
    "lng": -55.7167,
    "country": "Paraguay",
    "iso2": "PY",
    "iso3": "PRY",
    "admin_name": "Amambay",
    "capital": "admin",
    "population": 122190,
    "id": 1600089732
  },
  {
    "city": "Fier",
    "city_ascii": "Fier",
    "lat": 40.7167,
    "lng": 19.55,
    "country": "Albania",
    "iso2": "AL",
    "iso3": "ALB",
    "admin_name": "Fier",
    "capital": "admin",
    "population": 120655,
    "id": 1008293317
  },
  {
    "city": "Orekhovo-Zuyevo",
    "city_ascii": "Orekhovo-Zuyevo",
    "lat": 55.8,
    "lng": 38.9667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Moskovskaya Oblast’",
    "capital": "",
    "population": 118822,
    "id": 1643381342
  },
  {
    "city": "Kamalia",
    "city_ascii": "Kamalia",
    "lat": 30.7258,
    "lng": 72.6447,
    "country": "Pakistan",
    "iso2": "PK",
    "iso3": "PAK",
    "admin_name": "Punjab",
    "capital": "",
    "population": 121401,
    "id": 1586927428
  },
  {
    "city": "Gyumri",
    "city_ascii": "Gyumri",
    "lat": 40.7833,
    "lng": 43.8333,
    "country": "Armenia",
    "iso2": "AM",
    "iso3": "ARM",
    "admin_name": "Shirak",
    "capital": "admin",
    "population": 121976,
    "id": 1051341374
  },
  {
    "city": "Hòa Bình",
    "city_ascii": "Hoa Binh",
    "lat": 20.8172,
    "lng": 105.3375,
    "country": "Vietnam",
    "iso2": "VN",
    "iso3": "VNM",
    "admin_name": "Hòa Bình",
    "capital": "admin",
    "population": 121309,
    "id": 1704237072
  },
  {
    "city": "Perpignan",
    "city_ascii": "Perpignan",
    "lat": 42.6986,
    "lng": 2.8956,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Occitanie",
    "capital": "minor",
    "population": 120158,
    "id": 1250102680
  },
  {
    "city": "Larache",
    "city_ascii": "Larache",
    "lat": 35.2004,
    "lng": -6.16,
    "country": "Morocco",
    "iso2": "MA",
    "iso3": "MAR",
    "admin_name": "Tanger-Tétouan-Al Hoceïma",
    "capital": "",
    "population": 120082,
    "id": 1504637172
  },
  {
    "city": "Siracusa",
    "city_ascii": "Siracusa",
    "lat": 37.0692,
    "lng": 15.2875,
    "country": "Italy",
    "iso2": "IT",
    "iso3": "ITA",
    "admin_name": "Sicilia",
    "capital": "minor",
    "population": 121171,
    "id": 1380071816
  },
  {
    "city": "Dorūd",
    "city_ascii": "Dorud",
    "lat": 33.4933,
    "lng": 49.075,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Lorestān",
    "capital": "minor",
    "population": 121608,
    "id": 1364662084
  },
  {
    "city": "Puerto Barrios",
    "city_ascii": "Puerto Barrios",
    "lat": 15.73,
    "lng": -88.6,
    "country": "Guatemala",
    "iso2": "GT",
    "iso3": "GTM",
    "admin_name": "Izabal",
    "capital": "admin",
    "population": 121562,
    "id": 1320517532
  },
  {
    "city": "Ribeirão Pires",
    "city_ascii": "Ribeirao Pires",
    "lat": -23.7108,
    "lng": -46.4128,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 120396,
    "id": 1076050419
  },
  {
    "city": "Samandağ",
    "city_ascii": "Samandag",
    "lat": 36.085,
    "lng": 35.9806,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Hatay",
    "capital": "minor",
    "population": 121109,
    "id": 1792813864
  },
  {
    "city": "Upington",
    "city_ascii": "Upington",
    "lat": -28.4572,
    "lng": 21.2425,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "Northern Cape",
    "capital": "",
    "population": 121189,
    "id": 1710210449
  },
  {
    "city": "Walthamstow",
    "city_ascii": "Walthamstow",
    "lat": 51.59,
    "lng": 0,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Waltham Forest",
    "capital": "",
    "population": 109424,
    "id": 1826009890
  },
  {
    "city": "Berkeley",
    "city_ascii": "Berkeley",
    "lat": 37.8723,
    "lng": -122.276,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 121363,
    "id": 1840018914
  },
  {
    "city": "Płock",
    "city_ascii": "Plock",
    "lat": 52.55,
    "lng": 19.7,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Mazowieckie",
    "capital": "minor",
    "population": 120000,
    "id": 1616954507
  },
  {
    "city": "Richardson",
    "city_ascii": "Richardson",
    "lat": 32.9717,
    "lng": -96.7093,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 121323,
    "id": 1840020710
  },
  {
    "city": "Doğubayazıt",
    "city_ascii": "Dogubayazit",
    "lat": 39.55,
    "lng": 44.0833,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Ağrı",
    "capital": "minor",
    "population": 121263,
    "id": 1792572929
  },
  {
    "city": "Redding",
    "city_ascii": "Redding",
    "lat": 40.5698,
    "lng": -122.365,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 121313,
    "id": 1840020141
  },
  {
    "city": "Arvada",
    "city_ascii": "Arvada",
    "lat": 39.8321,
    "lng": -105.1511,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 121272,
    "id": 1840018788
  },
  {
    "city": "Valinhos",
    "city_ascii": "Valinhos",
    "lat": -22.9708,
    "lng": -46.9958,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 120258,
    "id": 1076809155
  },
  {
    "city": "St. George",
    "city_ascii": "St. George",
    "lat": 37.077,
    "lng": -113.577,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Utah",
    "capital": "",
    "population": 120899,
    "id": 1840021559
  },
  {
    "city": "Kot Addu",
    "city_ascii": "Kot Addu",
    "lat": 30.47,
    "lng": 70.9644,
    "country": "Pakistan",
    "iso2": "PK",
    "iso3": "PAK",
    "admin_name": "Punjab",
    "capital": "",
    "population": 120479,
    "id": 1586130042
  },
  {
    "city": "Kallithéa",
    "city_ascii": "Kallithea",
    "lat": 37.95,
    "lng": 23.7,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Attikí",
    "capital": "minor",
    "population": 100641,
    "id": 1300675902
  },
  {
    "city": "Göttingen",
    "city_ascii": "Gottingen",
    "lat": 51.5339,
    "lng": 9.9356,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "minor",
    "population": 119801,
    "id": 1276211234
  },
  {
    "city": "Pakxé",
    "city_ascii": "Pakxe",
    "lat": 15.1167,
    "lng": 105.7833,
    "country": "Laos",
    "iso2": "LA",
    "iso3": "LAO",
    "admin_name": "Champasak",
    "capital": "admin",
    "population": 119848,
    "id": 1418863956
  },
  {
    "city": "Billings",
    "city_ascii": "Billings",
    "lat": 45.7891,
    "lng": -108.5524,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Montana",
    "capital": "",
    "population": 120728,
    "id": 1840018496
  },
  {
    "city": "Carcar",
    "city_ascii": "Carcar",
    "lat": 10.1167,
    "lng": 123.6333,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Cebu",
    "capital": "",
    "population": 119664,
    "id": 1608778175
  },
  {
    "city": "Handa",
    "city_ascii": "Handa",
    "lat": 34.8992,
    "lng": 136.9267,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Aichi",
    "capital": "",
    "population": 118145,
    "id": 1392363594
  },
  {
    "city": "Gao",
    "city_ascii": "Gao",
    "lat": 16.2666,
    "lng": -0.05,
    "country": "Mali",
    "iso2": "ML",
    "iso3": "MLI",
    "admin_name": "Gao",
    "capital": "admin",
    "population": 116967,
    "id": 1466571211
  },
  {
    "city": "Guelmim",
    "city_ascii": "Guelmim",
    "lat": 28.9833,
    "lng": -10.0667,
    "country": "Morocco",
    "iso2": "MA",
    "iso3": "MAR",
    "admin_name": "Guelmim-Oued Noun",
    "capital": "admin",
    "population": 118318,
    "id": 1504110861
  },
  {
    "city": "Puerto Plata",
    "city_ascii": "Puerto Plata",
    "lat": 19.7902,
    "lng": -70.6902,
    "country": "Dominican Republic",
    "iso2": "DO",
    "iso3": "DOM",
    "admin_name": "Cibao Norte",
    "capital": "minor",
    "population": 119897,
    "id": 1214279422
  },
  {
    "city": "Darjeeling",
    "city_ascii": "Darjeeling",
    "lat": 27.0417,
    "lng": 88.2631,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "West Bengal",
    "capital": "",
    "population": 118805,
    "id": 1356267176
  },
  {
    "city": "Yuba City",
    "city_ascii": "Yuba City",
    "lat": 39.1357,
    "lng": -121.6381,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 120456,
    "id": 1840021463
  },
  {
    "city": "Moḩammad Shahr",
    "city_ascii": "Mohammad Shahr",
    "lat": 35.7631,
    "lng": 50.9172,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Alborz",
    "capital": "",
    "population": 119418,
    "id": 1364247384
  },
  {
    "city": "Sertãozinho",
    "city_ascii": "Sertaozinho",
    "lat": -21.1378,
    "lng": -47.99,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 120152,
    "id": 1076989194
  },
  {
    "city": "Fujimino",
    "city_ascii": "Fujimino",
    "lat": 35.8794,
    "lng": 139.5197,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Saitama",
    "capital": "",
    "population": 112689,
    "id": 1392078110
  },
  {
    "city": "Poá",
    "city_ascii": "Poa",
    "lat": -23.5286,
    "lng": -46.345,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 113793,
    "id": 1076905631
  },
  {
    "city": "Ebetsu",
    "city_ascii": "Ebetsu",
    "lat": 43.1039,
    "lng": 141.5361,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hokkaidō",
    "capital": "",
    "population": 119509,
    "id": 1392987572
  },
  {
    "city": "Saint-Denis",
    "city_ascii": "Saint-Denis",
    "lat": 48.9356,
    "lng": 2.3539,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "minor",
    "population": 111135,
    "id": 1250935115
  },
  {
    "city": "San Carlos",
    "city_ascii": "San Carlos",
    "lat": 9.65,
    "lng": -68.5833,
    "country": "Venezuela",
    "iso2": "VE",
    "iso3": "VEN",
    "admin_name": "Cojedes",
    "capital": "admin",
    "population": 120000,
    "id": 1862109303
  },
  {
    "city": "Ciudad Choluteca",
    "city_ascii": "Ciudad Choluteca",
    "lat": 13.3167,
    "lng": -87.2167,
    "country": "Honduras",
    "iso2": "HN",
    "iso3": "HND",
    "admin_name": "Choluteca",
    "capital": "admin",
    "population": 120000,
    "id": 1340256619
  },
  {
    "city": "Várzea Paulista",
    "city_ascii": "Varzea Paulista",
    "lat": -23.2108,
    "lng": -46.8278,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 116601,
    "id": 1076046083
  },
  {
    "city": "Rochester",
    "city_ascii": "Rochester",
    "lat": 44.0151,
    "lng": -92.4778,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 119942,
    "id": 1840008987
  },
  {
    "city": "Kaspiysk",
    "city_ascii": "Kaspiysk",
    "lat": 42.8833,
    "lng": 47.6333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Dagestan",
    "capital": "",
    "population": 116340,
    "id": 1643020514
  },
  {
    "city": "Zahlé",
    "city_ascii": "Zahle",
    "lat": 33.8439,
    "lng": 35.9072,
    "country": "Lebanon",
    "iso2": "LB",
    "iso3": "LBN",
    "admin_name": "Béqaa",
    "capital": "admin",
    "population": 100000,
    "id": 1422031285
  },
  {
    "city": "East Los Angeles",
    "city_ascii": "East Los Angeles",
    "lat": 34.0326,
    "lng": -118.1691,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 119827,
    "id": 1840019206
  },
  {
    "city": "Gudivāda",
    "city_ascii": "Gudivada",
    "lat": 16.43,
    "lng": 80.99,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Andhra Pradesh",
    "capital": "",
    "population": 118167,
    "id": 1356068699
  },
  {
    "city": "Naz̧arābād",
    "city_ascii": "Nazarabad",
    "lat": 35.9522,
    "lng": 50.6075,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Alborz",
    "capital": "minor",
    "population": 119512,
    "id": 1364796337
  },
  {
    "city": "Leominster",
    "city_ascii": "Leominster",
    "lat": 42.5209,
    "lng": -71.7717,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 119690,
    "id": 1840000437
  },
  {
    "city": "Ajax",
    "city_ascii": "Ajax",
    "lat": 43.8583,
    "lng": -79.0364,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 119677,
    "id": 1124382916
  },
  {
    "city": "Kingsport",
    "city_ascii": "Kingsport",
    "lat": 36.5224,
    "lng": -82.5453,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 119666,
    "id": 1840014425
  },
  {
    "city": "Butwāl",
    "city_ascii": "Butwal",
    "lat": 27.7,
    "lng": 83.45,
    "country": "Nepal",
    "iso2": "NP",
    "iso3": "NPL",
    "admin_name": "Lumbinī",
    "capital": "admin",
    "population": 118462,
    "id": 1524843250
  },
  {
    "city": "Nouadhibou",
    "city_ascii": "Nouadhibou",
    "lat": 20.95,
    "lng": -17.0333,
    "country": "Mauritania",
    "iso2": "MR",
    "iso3": "MRT",
    "admin_name": "Dakhlet Nouadhibou",
    "capital": "admin",
    "population": 118000,
    "id": 1478176283
  },
  {
    "city": "Duluth",
    "city_ascii": "Duluth",
    "lat": 46.7756,
    "lng": -92.1392,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 119476,
    "id": 1840007685
  },
  {
    "city": "Nobeoka",
    "city_ascii": "Nobeoka",
    "lat": 32.5822,
    "lng": 131.665,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Miyazaki",
    "capital": "",
    "population": 119309,
    "id": 1392666259
  },
  {
    "city": "Beppu",
    "city_ascii": "Beppu",
    "lat": 33.2847,
    "lng": 131.4911,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Ōita",
    "capital": "",
    "population": 118499,
    "id": 1392115605
  },
  {
    "city": "Nazran",
    "city_ascii": "Nazran",
    "lat": 43.2167,
    "lng": 44.7667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Ingushetiya",
    "capital": "",
    "population": 117936,
    "id": 1643123780
  },
  {
    "city": "Sopur",
    "city_ascii": "Sopur",
    "lat": 34.3,
    "lng": 74.47,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Jammu and Kashmīr",
    "capital": "",
    "population": 118608,
    "id": 1356978065
  },
  {
    "city": "Iguala de la Independencia",
    "city_ascii": "Iguala de la Independencia",
    "lat": 18.35,
    "lng": -99.5333,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Guerrero",
    "capital": "minor",
    "population": 110390,
    "id": 1484280781
  },
  {
    "city": "Guaratinguetá",
    "city_ascii": "Guaratingueta",
    "lat": -22.8167,
    "lng": -45.2278,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 119073,
    "id": 1076125544
  },
  {
    "city": "Lehigh Acres",
    "city_ascii": "Lehigh Acres",
    "lat": 26.612,
    "lng": -81.6388,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 119205,
    "id": 1840014220
  },
  {
    "city": "Huaraz",
    "city_ascii": "Huaraz",
    "lat": -9.5333,
    "lng": -77.5333,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "Ancash",
    "capital": "admin",
    "population": 118836,
    "id": 1604026500
  },
  {
    "city": "Kōnosu",
    "city_ascii": "Konosu",
    "lat": 36.0658,
    "lng": 139.5222,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Saitama",
    "capital": "",
    "population": 117373,
    "id": 1392326410
  },
  {
    "city": "Rock Hill",
    "city_ascii": "Rock Hill",
    "lat": 34.9416,
    "lng": -81.0244,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 119105,
    "id": 1840014618
  },
  {
    "city": "Ikoma",
    "city_ascii": "Ikoma",
    "lat": 34.6919,
    "lng": 135.7006,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Nara",
    "capital": "",
    "population": 116816,
    "id": 1392390581
  },
  {
    "city": "Gilroy",
    "city_ascii": "Gilroy",
    "lat": 37.0047,
    "lng": -121.5855,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 118993,
    "id": 1840020327
  },
  {
    "city": "Cheltenham",
    "city_ascii": "Cheltenham",
    "lat": 51.9,
    "lng": -2.0667,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Gloucestershire",
    "capital": "",
    "population": 116447,
    "id": 1826524974
  },
  {
    "city": "Cambridge",
    "city_ascii": "Cambridge",
    "lat": 42.3759,
    "lng": -71.1185,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 118927,
    "id": 1840000429
  },
  {
    "city": "Nevinnomyssk",
    "city_ascii": "Nevinnomyssk",
    "lat": 44.6333,
    "lng": 41.9333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Stavropol’skiy Kray",
    "capital": "",
    "population": 117446,
    "id": 1643869152
  },
  {
    "city": "Bruges",
    "city_ascii": "Bruges",
    "lat": 51.2089,
    "lng": 3.2242,
    "country": "Belgium",
    "iso2": "BE",
    "iso3": "BEL",
    "admin_name": "Flanders",
    "capital": "minor",
    "population": 118053,
    "id": 1056953902
  },
  {
    "city": "Lahti",
    "city_ascii": "Lahti",
    "lat": 60.9833,
    "lng": 25.6556,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Päijät-Häme",
    "capital": "admin",
    "population": 118119,
    "id": 1246538847
  },
  {
    "city": "Reutov",
    "city_ascii": "Reutov",
    "lat": 55.7667,
    "lng": 37.8667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Moskovskaya Oblast’",
    "capital": "",
    "population": 106990,
    "id": 1643138327
  },
  {
    "city": "Bandundu",
    "city_ascii": "Bandundu",
    "lat": -3.31,
    "lng": 17.38,
    "country": "Congo (Kinshasa)",
    "iso2": "CD",
    "iso3": "COD",
    "admin_name": "Kwilu",
    "capital": "",
    "population": 118211,
    "id": 1180219776
  },
  {
    "city": "Charallave",
    "city_ascii": "Charallave",
    "lat": 10.2431,
    "lng": -66.8622,
    "country": "Venezuela",
    "iso2": "VE",
    "iso3": "VEN",
    "admin_name": "Miranda",
    "capital": "minor",
    "population": 117707,
    "id": 1862753487
  },
  {
    "city": "Nowshera",
    "city_ascii": "Nowshera",
    "lat": 34.0153,
    "lng": 71.9747,
    "country": "Pakistan",
    "iso2": "PK",
    "iso3": "PAK",
    "admin_name": "Khyber Pakhtunkhwa",
    "capital": "minor",
    "population": 118594,
    "id": 1586290729
  },
  {
    "city": "Dharān",
    "city_ascii": "Dharan",
    "lat": 26.8167,
    "lng": 87.2667,
    "country": "Nepal",
    "iso2": "NP",
    "iso3": "NPL",
    "admin_name": "Kosī",
    "capital": "",
    "population": 116181,
    "id": 1524888375
  },
  {
    "city": "Anuradhapura",
    "city_ascii": "Anuradhapura",
    "lat": 8.335,
    "lng": 80.4108,
    "country": "Sri Lanka",
    "iso2": "LK",
    "iso3": "LKA",
    "admin_name": "North Central",
    "capital": "admin",
    "population": 118302,
    "id": 1144764835
  },
  {
    "city": "Birigui",
    "city_ascii": "Birigui",
    "lat": -21.2889,
    "lng": -50.34,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 118352,
    "id": 1076546798
  },
  {
    "city": "Bottrop",
    "city_ascii": "Bottrop",
    "lat": 51.5232,
    "lng": 6.9253,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "minor",
    "population": 117383,
    "id": 1276473363
  },
  {
    "city": "Santa Cruz do Sul",
    "city_ascii": "Santa Cruz do Sul",
    "lat": -29.7178,
    "lng": -52.4258,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Rio Grande do Sul",
    "capital": "",
    "population": 118374,
    "id": 1076862896
  },
  {
    "city": "Sugar Land",
    "city_ascii": "Sugar Land",
    "lat": 29.5935,
    "lng": -95.6357,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 118488,
    "id": 1840022217
  },
  {
    "city": "Akishima",
    "city_ascii": "Akishima",
    "lat": 35.7056,
    "lng": 139.3536,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Tōkyō",
    "capital": "",
    "population": 112005,
    "id": 1392041019
  },
  {
    "city": "Karatepe",
    "city_ascii": "Karatepe",
    "lat": 40.6883,
    "lng": 30.0144,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Kocaeli",
    "capital": "",
    "population": 118066,
    "id": 1792291745
  },
  {
    "city": "Forlì",
    "city_ascii": "Forli",
    "lat": 44.2225,
    "lng": 12.0408,
    "country": "Italy",
    "iso2": "IT",
    "iso3": "ITA",
    "admin_name": "Emilia-Romagna",
    "capital": "minor",
    "population": 117859,
    "id": 1380594352
  },
  {
    "city": "Cuautitlán",
    "city_ascii": "Cuautitlan",
    "lat": 19.6833,
    "lng": -99.1833,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "México",
    "capital": "minor",
    "population": 110385,
    "id": 1484256075
  },
  {
    "city": "Tecomán",
    "city_ascii": "Tecoman",
    "lat": 18.9089,
    "lng": -103.8747,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Colima",
    "capital": "minor",
    "population": 112726,
    "id": 1484348795
  },
  {
    "city": "Giyon",
    "city_ascii": "Giyon",
    "lat": 8.5304,
    "lng": 37.97,
    "country": "Ethiopia",
    "iso2": "ET",
    "iso3": "ETH",
    "admin_name": "Oromīya",
    "capital": "",
    "population": 114534,
    "id": 1231961383
  },
  {
    "city": "Texas City",
    "city_ascii": "Texas City",
    "lat": 29.4128,
    "lng": -94.9658,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 118169,
    "id": 1840022234
  },
  {
    "city": "Bama",
    "city_ascii": "Bama",
    "lat": 11.5204,
    "lng": 13.69,
    "country": "Nigeria",
    "iso2": "NG",
    "iso3": "NGA",
    "admin_name": "Borno",
    "capital": "minor",
    "population": 118121,
    "id": 1566590565
  },
  {
    "city": "Bayawan",
    "city_ascii": "Bayawan",
    "lat": 9.3667,
    "lng": 122.8,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Negros Oriental",
    "capital": "",
    "population": 117900,
    "id": 1608189345
  },
  {
    "city": "Iowa City",
    "city_ascii": "Iowa City",
    "lat": 41.6559,
    "lng": -91.5303,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Iowa",
    "capital": "",
    "population": 118041,
    "id": 1840000532
  },
  {
    "city": "Saginaw",
    "city_ascii": "Saginaw",
    "lat": 43.4199,
    "lng": -83.9501,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 117958,
    "id": 1840002813
  },
  {
    "city": "Trento",
    "city_ascii": "Trento",
    "lat": 46.0667,
    "lng": 11.1167,
    "country": "Italy",
    "iso2": "IT",
    "iso3": "ITA",
    "admin_name": "Trentino-Alto Adige",
    "capital": "admin",
    "population": 117185,
    "id": 1380953307
  },
  {
    "city": "Kabwe",
    "city_ascii": "Kabwe",
    "lat": -14.4333,
    "lng": 28.45,
    "country": "Zambia",
    "iso2": "ZM",
    "iso3": "ZMB",
    "admin_name": "Central",
    "capital": "admin",
    "population": 117517,
    "id": 1894711746
  },
  {
    "city": "Jalingo",
    "city_ascii": "Jalingo",
    "lat": 8.9004,
    "lng": 11.36,
    "country": "Nigeria",
    "iso2": "NG",
    "iso3": "NGA",
    "admin_name": "Taraba",
    "capital": "admin",
    "population": 117757,
    "id": 1566732241
  },
  {
    "city": "Luanshya",
    "city_ascii": "Luanshya",
    "lat": -13.1333,
    "lng": 28.4,
    "country": "Zambia",
    "iso2": "ZM",
    "iso3": "ZMB",
    "admin_name": "Copperbelt",
    "capital": "",
    "population": 117579,
    "id": 1894410334
  },
  {
    "city": "Agadez",
    "city_ascii": "Agadez",
    "lat": 16.9959,
    "lng": 7.9828,
    "country": "Niger",
    "iso2": "NE",
    "iso3": "NER",
    "admin_name": "Agadez",
    "capital": "admin",
    "population": 117770,
    "id": 1562789067
  },
  {
    "city": "Uzhhorod",
    "city_ascii": "Uzhhorod",
    "lat": 48.6239,
    "lng": 22.295,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Zakarpats’ka Oblast’",
    "capital": "admin",
    "population": 114897,
    "id": 1804851697
  },
  {
    "city": "Besançon",
    "city_ascii": "Besancon",
    "lat": 47.24,
    "lng": 6.02,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Bourgogne-Franche-Comté",
    "capital": "minor",
    "population": 115934,
    "id": 1250182013
  },
  {
    "city": "Adilābād",
    "city_ascii": "Adilabad",
    "lat": 19.6667,
    "lng": 78.5333,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Telangana",
    "capital": "",
    "population": 117167,
    "id": 1356085840
  },
  {
    "city": "Obninsk",
    "city_ascii": "Obninsk",
    "lat": 55.1,
    "lng": 36.6167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kaluzhskaya Oblast’",
    "capital": "",
    "population": 115029,
    "id": 1643426054
  },
  {
    "city": "Chico",
    "city_ascii": "Chico",
    "lat": 39.7575,
    "lng": -121.8152,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 117668,
    "id": 1840018778
  },
  {
    "city": "San Martín",
    "city_ascii": "San Martin",
    "lat": -33.0806,
    "lng": -68.4706,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Mendoza",
    "capital": "minor",
    "population": 117399,
    "id": 1032297325
  },
  {
    "city": "Kapaklı",
    "city_ascii": "Kapakli",
    "lat": 41.3333,
    "lng": 27.9667,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Tekirdağ",
    "capital": "minor",
    "population": 116882,
    "id": 1792061507
  },
  {
    "city": "Basingstoke",
    "city_ascii": "Basingstoke",
    "lat": 51.2667,
    "lng": -1.0876,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hampshire",
    "capital": "",
    "population": 113776,
    "id": 1826924980
  },
  {
    "city": "Wałbrzych",
    "city_ascii": "Walbrzych",
    "lat": 50.7667,
    "lng": 16.2833,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Dolnośląskie",
    "capital": "minor",
    "population": 116069,
    "id": 1616162263
  },
  {
    "city": "Maidstone",
    "city_ascii": "Maidstone",
    "lat": 51.272,
    "lng": 0.529,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Kent",
    "capital": "",
    "population": 113137,
    "id": 1826000009
  },
  {
    "city": "Xai-Xai",
    "city_ascii": "Xai-Xai",
    "lat": -25.05,
    "lng": 33.65,
    "country": "Mozambique",
    "iso2": "MZ",
    "iso3": "MOZ",
    "admin_name": "Gaza",
    "capital": "admin",
    "population": 116343,
    "id": 1508689070
  },
  {
    "city": "Linköping",
    "city_ascii": "Linkoping",
    "lat": 58.4094,
    "lng": 15.6257,
    "country": "Sweden",
    "iso2": "SE",
    "iso3": "SWE",
    "admin_name": "Östergötland",
    "capital": "admin",
    "population": 114582,
    "id": 1752963378
  },
  {
    "city": "Reutlingen",
    "city_ascii": "Reutlingen",
    "lat": 48.4833,
    "lng": 9.2167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "minor",
    "population": 115966,
    "id": 1276256795
  },
  {
    "city": "Langley",
    "city_ascii": "Langley",
    "lat": 49.1044,
    "lng": -122.5827,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "British Columbia",
    "capital": "",
    "population": 117285,
    "id": 1124000480
  },
  {
    "city": "Dover",
    "city_ascii": "Dover",
    "lat": 39.161,
    "lng": -75.5203,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Delaware",
    "capital": "",
    "population": 117282,
    "id": 1840005810
  },
  {
    "city": "Temixco",
    "city_ascii": "Temixco",
    "lat": 18.85,
    "lng": -99.2333,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Morelos",
    "capital": "minor",
    "population": 116143,
    "id": 1484273506
  },
  {
    "city": "Belfort",
    "city_ascii": "Belfort",
    "lat": 47.64,
    "lng": 6.85,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Bourgogne-Franche-Comté",
    "capital": "minor",
    "population": 114445,
    "id": 1250108238
  },
  {
    "city": "Kindia",
    "city_ascii": "Kindia",
    "lat": 10.06,
    "lng": -12.87,
    "country": "Guinea",
    "iso2": "GN",
    "iso3": "GIN",
    "admin_name": "Kindia",
    "capital": "admin",
    "population": 117062,
    "id": 1324348910
  },
  {
    "city": "Narasaraopet",
    "city_ascii": "Narasaraopet",
    "lat": 16.236,
    "lng": 80.054,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Andhra Pradesh",
    "capital": "",
    "population": 116250,
    "id": 1356923354
  },
  {
    "city": "Nyíregyháza",
    "city_ascii": "Nyiregyhaza",
    "lat": 47.95,
    "lng": 21.7167,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Szabolcs-Szatmár-Bereg",
    "capital": "admin",
    "population": 116799,
    "id": 1348653343
  },
  {
    "city": "Kyzyl",
    "city_ascii": "Kyzyl",
    "lat": 51.7167,
    "lng": 94.45,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Tyva",
    "capital": "admin",
    "population": 116015,
    "id": 1643780385
  },
  {
    "city": "Conselheiro Lafaiete",
    "city_ascii": "Conselheiro Lafaiete",
    "lat": -20.67,
    "lng": -43.79,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Minas Gerais",
    "capital": "",
    "population": 111596,
    "id": 1076456009
  },
  {
    "city": "Machakos",
    "city_ascii": "Machakos",
    "lat": -1.5167,
    "lng": 37.2667,
    "country": "Kenya",
    "iso2": "KE",
    "iso3": "KEN",
    "admin_name": "Machakos",
    "capital": "admin",
    "population": 114109,
    "id": 1404746781
  },
  {
    "city": "Nkongsamba",
    "city_ascii": "Nkongsamba",
    "lat": 4.95,
    "lng": 9.9167,
    "country": "Cameroon",
    "iso2": "CM",
    "iso3": "CMR",
    "admin_name": "Littoral",
    "capital": "",
    "population": 117063,
    "id": 1120972098
  },
  {
    "city": "Smithtown",
    "city_ascii": "Smithtown",
    "lat": 40.8662,
    "lng": -73.2164,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 117031,
    "id": 1840058498
  },
  {
    "city": "Norman",
    "city_ascii": "Norman",
    "lat": 35.2335,
    "lng": -97.3471,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oklahoma",
    "capital": "",
    "population": 116973,
    "id": 1840020451
  },
  {
    "city": "Mendoza",
    "city_ascii": "Mendoza",
    "lat": -32.8833,
    "lng": -68.8333,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Mendoza",
    "capital": "admin",
    "population": 114822,
    "id": 1032433516
  },
  {
    "city": "Clearwater",
    "city_ascii": "Clearwater",
    "lat": 27.9789,
    "lng": -82.7622,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 116946,
    "id": 1840015110
  },
  {
    "city": "Tandil",
    "city_ascii": "Tandil",
    "lat": -37.3167,
    "lng": -59.1333,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires",
    "capital": "minor",
    "population": 116917,
    "id": 1032075057
  },
  {
    "city": "Manp’o",
    "city_ascii": "Manp'o",
    "lat": 41.157,
    "lng": 126.29,
    "country": "Korea, North",
    "iso2": "KP",
    "iso3": "PRK",
    "admin_name": "Chagang",
    "capital": "",
    "population": 116760,
    "id": 1408334845
  },
  {
    "city": "Kōenchō",
    "city_ascii": "Koencho",
    "lat": 43.8081,
    "lng": 143.8942,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hokkaidō",
    "capital": "",
    "population": 116848,
    "id": 1392099278
  },
  {
    "city": "Buzău",
    "city_ascii": "Buzau",
    "lat": 45.1517,
    "lng": 26.8167,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Buzău",
    "capital": "admin",
    "population": 115494,
    "id": 1642794918
  },
  {
    "city": "Araguari",
    "city_ascii": "Araguari",
    "lat": -18.6489,
    "lng": -48.1869,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Minas Gerais",
    "capital": "",
    "population": 116871,
    "id": 1076402865
  },
  {
    "city": "Tatuí",
    "city_ascii": "Tatui",
    "lat": -23.3556,
    "lng": -47.8569,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 116682,
    "id": 1076587962
  },
  {
    "city": "Niihama",
    "city_ascii": "Niihama",
    "lat": 33.9603,
    "lng": 133.2834,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Ehime",
    "capital": "",
    "population": 116392,
    "id": 1392682431
  },
  {
    "city": "Naga",
    "city_ascii": "Naga",
    "lat": 10.2167,
    "lng": 123.75,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Cebu",
    "capital": "",
    "population": 115750,
    "id": 1608928299
  },
  {
    "city": "Dimitrovgrad",
    "city_ascii": "Dimitrovgrad",
    "lat": 54.2333,
    "lng": 49.5833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Ul’yanovskaya Oblast’",
    "capital": "",
    "population": 116055,
    "id": 1643039639
  },
  {
    "city": "Port-Gentil",
    "city_ascii": "Port-Gentil",
    "lat": -0.7167,
    "lng": 8.7833,
    "country": "Gabon",
    "iso2": "GA",
    "iso3": "GAB",
    "admin_name": "Ogooué-Maritime",
    "capital": "admin",
    "population": 116836,
    "id": 1266383028
  },
  {
    "city": "Sassari",
    "city_ascii": "Sassari",
    "lat": 40.7267,
    "lng": 8.5592,
    "country": "Italy",
    "iso2": "IT",
    "iso3": "ITA",
    "admin_name": "Sardegna",
    "capital": "minor",
    "population": 116641,
    "id": 1380371433
  },
  {
    "city": "Coeur d'Alene",
    "city_ascii": "Coeur d'Alene",
    "lat": 47.7041,
    "lng": -116.7935,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Idaho",
    "capital": "",
    "population": 116834,
    "id": 1840018402
  },
  {
    "city": "Ponce",
    "city_ascii": "Ponce",
    "lat": 18.012,
    "lng": -66.6198,
    "country": "Puerto Rico",
    "iso2": "PR",
    "iso3": "PRI",
    "admin_name": "Puerto Rico",
    "capital": "",
    "population": 116796,
    "id": 1630023669
  },
  {
    "city": "Chittaurgarh",
    "city_ascii": "Chittaurgarh",
    "lat": 24.8894,
    "lng": 74.6239,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Rājasthān",
    "capital": "",
    "population": 116406,
    "id": 1356110002
  },
  {
    "city": "Seaside",
    "city_ascii": "Seaside",
    "lat": 36.6224,
    "lng": -121.8191,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 116720,
    "id": 1840021634
  },
  {
    "city": "Independence",
    "city_ascii": "Independence",
    "lat": 39.0871,
    "lng": -94.3501,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 116672,
    "id": 1840008534
  },
  {
    "city": "Argenteuil",
    "city_ascii": "Argenteuil",
    "lat": 48.95,
    "lng": 2.25,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "minor",
    "population": 110210,
    "id": 1250226887
  },
  {
    "city": "Boké",
    "city_ascii": "Boke",
    "lat": 10.94,
    "lng": -14.3,
    "country": "Guinea",
    "iso2": "GN",
    "iso3": "GIN",
    "admin_name": "Boké",
    "capital": "admin",
    "population": 116270,
    "id": 1324037897
  },
  {
    "city": "West Jordan",
    "city_ascii": "West Jordan",
    "lat": 40.6024,
    "lng": -112.0008,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Utah",
    "capital": "",
    "population": 116480,
    "id": 1840021388
  },
  {
    "city": "Abilene",
    "city_ascii": "Abilene",
    "lat": 32.4543,
    "lng": -99.7384,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 116412,
    "id": 1840019476
  },
  {
    "city": "Phatthaya",
    "city_ascii": "Phatthaya",
    "lat": 12.9496,
    "lng": 100.893,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Chon Buri",
    "capital": "",
    "population": 115840,
    "id": 1764492695
  },
  {
    "city": "Khon Kaen",
    "city_ascii": "Khon Kaen",
    "lat": 16.4297,
    "lng": 102.8297,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Khon Kaen",
    "capital": "admin",
    "population": 115928,
    "id": 1764003406
  },
  {
    "city": "Ramenskoye",
    "city_ascii": "Ramenskoye",
    "lat": 55.5669,
    "lng": 38.2303,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Moskovskaya Oblast’",
    "capital": "",
    "population": 116077,
    "id": 1643438526
  },
  {
    "city": "Nasushiobara",
    "city_ascii": "Nasushiobara",
    "lat": 36.9617,
    "lng": 140.0461,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Tochigi",
    "capital": "",
    "population": 116043,
    "id": 1392841957
  },
  {
    "city": "Kilis",
    "city_ascii": "Kilis",
    "lat": 36.7167,
    "lng": 37.1167,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Kilis",
    "capital": "admin",
    "population": 116034,
    "id": 1792866362
  },
  {
    "city": "Shibuya",
    "city_ascii": "Shibuya",
    "lat": 35.6536,
    "lng": 139.7092,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Tōkyō",
    "capital": "",
    "population": 102056,
    "id": 1392000470
  },
  {
    "city": "Nabeul",
    "city_ascii": "Nabeul",
    "lat": 36.4603,
    "lng": 10.73,
    "country": "Tunisia",
    "iso2": "TN",
    "iso3": "TUN",
    "admin_name": "Nabeul",
    "capital": "admin",
    "population": 115149,
    "id": 1788902612
  },
  {
    "city": "Parral",
    "city_ascii": "Parral",
    "lat": 26.9333,
    "lng": -105.6667,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Chihuahua",
    "capital": "minor",
    "population": 109510,
    "id": 1484607603
  },
  {
    "city": "Swabi",
    "city_ascii": "Swabi",
    "lat": 34.1167,
    "lng": 72.4667,
    "country": "Pakistan",
    "iso2": "PK",
    "iso3": "PAK",
    "admin_name": "Khyber Pakhtunkhwa",
    "capital": "minor",
    "population": 115018,
    "id": 1586628764
  },
  {
    "city": "Sano",
    "city_ascii": "Sano",
    "lat": 36.3144,
    "lng": 139.5783,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Tochigi",
    "capital": "",
    "population": 115784,
    "id": 1392791927
  },
  {
    "city": "Huejutla de Reyes",
    "city_ascii": "Huejutla de Reyes",
    "lat": 21.1333,
    "lng": -98.4167,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Hidalgo",
    "capital": "",
    "population": 115786,
    "id": 1484746079
  },
  {
    "city": "Sabhā",
    "city_ascii": "Sabha",
    "lat": 27.0333,
    "lng": 14.4333,
    "country": "Libya",
    "iso2": "LY",
    "iso3": "LBY",
    "admin_name": "Sabhā",
    "capital": "admin",
    "population": 116016,
    "id": 1434044820
  },
  {
    "city": "Tahoua",
    "city_ascii": "Tahoua",
    "lat": 14.9,
    "lng": 5.2599,
    "country": "Niger",
    "iso2": "NE",
    "iso3": "NER",
    "admin_name": "Tahoua",
    "capital": "admin",
    "population": 115956,
    "id": 1562214493
  },
  {
    "city": "Chelmsford",
    "city_ascii": "Chelmsford",
    "lat": 51.7361,
    "lng": 0.4798,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Essex",
    "capital": "",
    "population": 111511,
    "id": 1826876670
  },
  {
    "city": "Doncaster",
    "city_ascii": "Doncaster",
    "lat": 53.515,
    "lng": -1.133,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Doncaster",
    "capital": "",
    "population": 109805,
    "id": 1826979494
  },
  {
    "city": "Valle de La Pascua",
    "city_ascii": "Valle de La Pascua",
    "lat": 9.2033,
    "lng": -66.0103,
    "country": "Venezuela",
    "iso2": "VE",
    "iso3": "VEN",
    "admin_name": "Guárico",
    "capital": "minor",
    "population": 115902,
    "id": 1862904072
  },
  {
    "city": "Bloomington",
    "city_ascii": "Bloomington",
    "lat": 39.1637,
    "lng": -86.5257,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 115884,
    "id": 1840007376
  },
  {
    "city": "Toliara",
    "city_ascii": "Toliara",
    "lat": -23.35,
    "lng": 43.6667,
    "country": "Madagascar",
    "iso2": "MG",
    "iso3": "MDG",
    "admin_name": "Toliara",
    "capital": "admin",
    "population": 115319,
    "id": 1450593748
  },
  {
    "city": "Garanhuns",
    "city_ascii": "Garanhuns",
    "lat": -8.89,
    "lng": -36.5,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Pernambuco",
    "capital": "",
    "population": 110085,
    "id": 1076673653
  },
  {
    "city": "Berdyans’k",
    "city_ascii": "Berdyans'k",
    "lat": 46.7598,
    "lng": 36.7845,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Zaporiz’ka Oblast’",
    "capital": "minor",
    "population": 114205,
    "id": 1804599785
  },
  {
    "city": "El Monte",
    "city_ascii": "El Monte",
    "lat": 34.0739,
    "lng": -118.0291,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 115487,
    "id": 1840020480
  },
  {
    "city": "Otaru",
    "city_ascii": "Otaru",
    "lat": 43.1833,
    "lng": 141,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hokkaidō",
    "capital": "",
    "population": 114947,
    "id": 1392240421
  },
  {
    "city": "Carlsbad",
    "city_ascii": "Carlsbad",
    "lat": 33.1247,
    "lng": -117.2837,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 115382,
    "id": 1840019352
  },
  {
    "city": "North Charleston",
    "city_ascii": "North Charleston",
    "lat": 32.9066,
    "lng": -80.0722,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 115382,
    "id": 1840015164
  },
  {
    "city": "Bānsbāria",
    "city_ascii": "Bansbaria",
    "lat": 22.97,
    "lng": 88.4,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "West Bengal",
    "capital": "",
    "population": 103920,
    "id": 1356897672
  },
  {
    "city": "Puerto Madryn",
    "city_ascii": "Puerto Madryn",
    "lat": -42.773,
    "lng": -65.0366,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Chubut",
    "capital": "minor",
    "population": 115353,
    "id": 1032351657
  },
  {
    "city": "Helsingborg",
    "city_ascii": "Helsingborg",
    "lat": 56.0424,
    "lng": 12.721,
    "country": "Sweden",
    "iso2": "SE",
    "iso3": "SWE",
    "admin_name": "Skåne",
    "capital": "minor",
    "population": 112496,
    "id": 1752789933
  },
  {
    "city": "Nagahama",
    "city_ascii": "Nagahama",
    "lat": 35.3833,
    "lng": 136.2833,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Shiga",
    "capital": "",
    "population": 115069,
    "id": 1392190380
  },
  {
    "city": "Hatsukaichi",
    "city_ascii": "Hatsukaichi",
    "lat": 34.3486,
    "lng": 132.3317,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hiroshima",
    "capital": "",
    "population": 114975,
    "id": 1392723053
  },
  {
    "city": "Sloviansk",
    "city_ascii": "Sloviansk",
    "lat": 48.87,
    "lng": 37.625,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Donets’ka Oblast’",
    "capital": "minor",
    "population": 113196,
    "id": 1804877044
  },
  {
    "city": "Koblenz",
    "city_ascii": "Koblenz",
    "lat": 50.3597,
    "lng": 7.5978,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Rhineland-Palatinate",
    "capital": "minor",
    "population": 114024,
    "id": 1276605495
  },
  {
    "city": "Oktyabr’skiy",
    "city_ascii": "Oktyabr'skiy",
    "lat": 54.4833,
    "lng": 53.4833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Bashkortostan",
    "capital": "",
    "population": 113929,
    "id": 1643301615
  },
  {
    "city": "Maribor",
    "city_ascii": "Maribor",
    "lat": 46.55,
    "lng": 15.6333,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Maribor",
    "capital": "admin",
    "population": 112325,
    "id": 1705454394
  },
  {
    "city": "St. Cloud",
    "city_ascii": "St. Cloud",
    "lat": 45.5339,
    "lng": -94.1718,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 115028,
    "id": 1840008881
  },
  {
    "city": "Salto",
    "city_ascii": "Salto",
    "lat": -23.2008,
    "lng": -47.2869,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 114171,
    "id": 1076427264
  },
  {
    "city": "Zhijiang",
    "city_ascii": "Zhijiang",
    "lat": 27.4409,
    "lng": 109.678,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hunan",
    "capital": "minor",
    "population": 113907,
    "id": 1156128936
  },
  {
    "city": "Chorzów",
    "city_ascii": "Chorzow",
    "lat": 50.3,
    "lng": 18.95,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Śląskie",
    "capital": "minor",
    "population": 111536,
    "id": 1616517871
  },
  {
    "city": "Hōfu",
    "city_ascii": "Hofu",
    "lat": 34.05,
    "lng": 131.5667,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Yamaguchi",
    "capital": "",
    "population": 114256,
    "id": 1392985430
  },
  {
    "city": "Las Delicias",
    "city_ascii": "Las Delicias",
    "lat": 28.2,
    "lng": -105.5,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Chihuahua",
    "capital": "",
    "population": 114783,
    "id": 1484513329
  },
  {
    "city": "Lilan",
    "city_ascii": "Lilan",
    "lat": 23.8163,
    "lng": 108.8847,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Guangxi",
    "capital": "",
    "population": 114496,
    "id": 1156060534
  },
  {
    "city": "Bijeljina",
    "city_ascii": "Bijeljina",
    "lat": 44.75,
    "lng": 19.2167,
    "country": "Bosnia And Herzegovina",
    "iso2": "BA",
    "iso3": "BIH",
    "admin_name": "Srpska, Republika",
    "capital": "minor",
    "population": 114663,
    "id": 1070522751
  },
  {
    "city": "Temecula",
    "city_ascii": "Temecula",
    "lat": 33.4928,
    "lng": -117.1315,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 114761,
    "id": 1840021937
  },
  {
    "city": "Bremerhaven",
    "city_ascii": "Bremerhaven",
    "lat": 53.55,
    "lng": 8.5833,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bremen",
    "capital": "",
    "population": 113634,
    "id": 1276991229
  },
  {
    "city": "Kamagaya",
    "city_ascii": "Kamagaya",
    "lat": 35.7769,
    "lng": 140.0008,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Chiba",
    "capital": "",
    "population": 109525,
    "id": 1392541992
  },
  {
    "city": "Bet Shemesh",
    "city_ascii": "Bet Shemesh",
    "lat": 31.7514,
    "lng": 34.9886,
    "country": "Israel",
    "iso2": "IL",
    "iso3": "ISR",
    "admin_name": "Jerusalem",
    "capital": "",
    "population": 114371,
    "id": 1376846832
  },
  {
    "city": "Ciudad de la Costa",
    "city_ascii": "Ciudad de la Costa",
    "lat": -34.8167,
    "lng": -55.95,
    "country": "Uruguay",
    "iso2": "UY",
    "iso3": "URY",
    "admin_name": "Canelones",
    "capital": "",
    "population": 112449,
    "id": 1858042396
  },
  {
    "city": "Clovis",
    "city_ascii": "Clovis",
    "lat": 36.8278,
    "lng": -119.6831,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 114584,
    "id": 1840018944
  },
  {
    "city": "Bernal",
    "city_ascii": "Bernal",
    "lat": -34.7,
    "lng": -58.2833,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires",
    "capital": "",
    "population": 109914,
    "id": 1032030395
  },
  {
    "city": "Vālpārai",
    "city_ascii": "Valparai",
    "lat": 10.3204,
    "lng": 76.97,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Tamil Nādu",
    "capital": "",
    "population": 114308,
    "id": 1356065507
  },
  {
    "city": "Iringa",
    "city_ascii": "Iringa",
    "lat": -7.77,
    "lng": 35.69,
    "country": "Tanzania",
    "iso2": "TZ",
    "iso3": "TZA",
    "admin_name": "Iringa",
    "capital": "admin",
    "population": 111820,
    "id": 1834453478
  },
  {
    "city": "Kandy",
    "city_ascii": "Kandy",
    "lat": 7.297,
    "lng": 80.6385,
    "country": "Sri Lanka",
    "iso2": "LK",
    "iso3": "LKA",
    "admin_name": "Central",
    "capital": "admin",
    "population": 111701,
    "id": 1144308408
  },
  {
    "city": "Hikone",
    "city_ascii": "Hikone",
    "lat": 35.2744,
    "lng": 136.2597,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Shiga",
    "capital": "",
    "population": 113818,
    "id": 1392717059
  },
  {
    "city": "Cuito",
    "city_ascii": "Cuito",
    "lat": -12.38,
    "lng": 16.94,
    "country": "Angola",
    "iso2": "AO",
    "iso3": "AGO",
    "admin_name": "Bié",
    "capital": "admin",
    "population": 114286,
    "id": 1024939858
  },
  {
    "city": "Ōshū",
    "city_ascii": "Oshu",
    "lat": 39.1444,
    "lng": 141.1389,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Iwate",
    "capital": "",
    "population": 114246,
    "id": 1392826391
  },
  {
    "city": "Nikopol’",
    "city_ascii": "Nikopol'",
    "lat": 47.5772,
    "lng": 34.3575,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Dnipropetrovs’ka Oblast’",
    "capital": "minor",
    "population": 112102,
    "id": 1804000135
  },
  {
    "city": "Laghouat",
    "city_ascii": "Laghouat",
    "lat": 33.81,
    "lng": 2.88,
    "country": "Algeria",
    "iso2": "DZ",
    "iso3": "DZA",
    "admin_name": "Laghouat",
    "capital": "admin",
    "population": 113872,
    "id": 1012233848
  },
  {
    "city": "Meridian",
    "city_ascii": "Meridian",
    "lat": 43.6112,
    "lng": -116.3968,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Idaho",
    "capital": "",
    "population": 114161,
    "id": 1840020046
  },
  {
    "city": "Saanich",
    "city_ascii": "Saanich",
    "lat": 48.484,
    "lng": -123.381,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "British Columbia",
    "capital": "",
    "population": 114148,
    "id": 1124000949
  },
  {
    "city": "Āsela",
    "city_ascii": "Asela",
    "lat": 7.95,
    "lng": 39.1167,
    "country": "Ethiopia",
    "iso2": "ET",
    "iso3": "ETH",
    "admin_name": "Oromīya",
    "capital": "",
    "population": 110088,
    "id": 1231347519
  },
  {
    "city": "Kankan",
    "city_ascii": "Kankan",
    "lat": 10.39,
    "lng": -9.31,
    "country": "Guinea",
    "iso2": "GN",
    "iso3": "GIN",
    "admin_name": "Kankan",
    "capital": "admin",
    "population": 114009,
    "id": 1324495325
  },
  {
    "city": "Kuşadası",
    "city_ascii": "Kusadasi",
    "lat": 37.8586,
    "lng": 27.2594,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "İzmir",
    "capital": "minor",
    "population": 113580,
    "id": 1792759260
  },
  {
    "city": "Joünié",
    "city_ascii": "Jounie",
    "lat": 33.9697,
    "lng": 35.6156,
    "country": "Lebanon",
    "iso2": "LB",
    "iso3": "LBN",
    "admin_name": "Mont-Liban",
    "capital": "minor",
    "population": 102221,
    "id": 1422032780
  },
  {
    "city": "Colchester",
    "city_ascii": "Colchester",
    "lat": 51.8917,
    "lng": 0.903,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Essex",
    "capital": "",
    "population": 111921,
    "id": 1826545433
  },
  {
    "city": "Recklinghausen",
    "city_ascii": "Recklinghausen",
    "lat": 51.6167,
    "lng": 7.2,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "minor",
    "population": 112267,
    "id": 1276179876
  },
  {
    "city": "Pul-e Khumrī",
    "city_ascii": "Pul-e Khumri",
    "lat": 35.95,
    "lng": 68.7,
    "country": "Afghanistan",
    "iso2": "AF",
    "iso3": "AFG",
    "admin_name": "Baghlān",
    "capital": "admin",
    "population": 113500,
    "id": 1004123527
  },
  {
    "city": "Tawau",
    "city_ascii": "Tawau",
    "lat": 4.2981,
    "lng": 117.8831,
    "country": "Malaysia",
    "iso2": "MY",
    "iso3": "MYS",
    "admin_name": "Sabah",
    "capital": "",
    "population": 113809,
    "id": 1458745722
  },
  {
    "city": "San Francisco del Rincón",
    "city_ascii": "San Francisco del Rincon",
    "lat": 21.0228,
    "lng": -101.86,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Guanajuato",
    "capital": "minor",
    "population": 113570,
    "id": 1484674455
  },
  {
    "city": "Vicenza",
    "city_ascii": "Vicenza",
    "lat": 45.55,
    "lng": 11.55,
    "country": "Italy",
    "iso2": "IT",
    "iso3": "ITA",
    "admin_name": "Veneto",
    "capital": "minor",
    "population": 112408,
    "id": 1380072199
  },
  {
    "city": "Paarl",
    "city_ascii": "Paarl",
    "lat": -33.7242,
    "lng": 18.9558,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "Western Cape",
    "capital": "",
    "population": 112045,
    "id": 1710440011
  },
  {
    "city": "Īrānshahr",
    "city_ascii": "Iranshahr",
    "lat": 27.2025,
    "lng": 60.6847,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Sīstān va Balūchestān",
    "capital": "minor",
    "population": 113750,
    "id": 1364413748
  },
  {
    "city": "Sørum",
    "city_ascii": "Sorum",
    "lat": 59.9135,
    "lng": 11.3472,
    "country": "Norway",
    "iso2": "NO",
    "iso3": "NOR",
    "admin_name": "Akershus",
    "capital": "",
    "population": 113659,
    "id": 1578429477
  },
  {
    "city": "Habikino",
    "city_ascii": "Habikino",
    "lat": 34.5578,
    "lng": 135.6061,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Ōsaka",
    "capital": "",
    "population": 109553,
    "id": 1392918777
  },
  {
    "city": "Itatiba",
    "city_ascii": "Itatiba",
    "lat": -23.0058,
    "lng": -46.8389,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 113284,
    "id": 1076747401
  },
  {
    "city": "Ubá",
    "city_ascii": "Uba",
    "lat": -21.12,
    "lng": -42.9428,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Minas Gerais",
    "capital": "",
    "population": 113300,
    "id": 1076389529
  },
  {
    "city": "Caraguatatuba",
    "city_ascii": "Caraguatatuba",
    "lat": -23.62,
    "lng": -45.4128,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 113317,
    "id": 1076107372
  },
  {
    "city": "Bukittinggi",
    "city_ascii": "Bukittinggi",
    "lat": -0.3097,
    "lng": 100.3753,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Sumatera Barat",
    "capital": "",
    "population": 113326,
    "id": 1360552560
  },
  {
    "city": "Higashiōmi",
    "city_ascii": "Higashiomi",
    "lat": 35.1128,
    "lng": 136.2078,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Shiga",
    "capital": "",
    "population": 113165,
    "id": 1392117204
  },
  {
    "city": "Wythenshawe",
    "city_ascii": "Wythenshawe",
    "lat": 53.392,
    "lng": -2.264,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Manchester",
    "capital": "",
    "population": 110000,
    "id": 1826781713
  },
  {
    "city": "Erlangen",
    "city_ascii": "Erlangen",
    "lat": 49.5964,
    "lng": 11.0044,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "minor",
    "population": 111962,
    "id": 1276339482
  },
  {
    "city": "Sa-ch’on",
    "city_ascii": "Sa-ch'on",
    "lat": 35.2347,
    "lng": 128.3575,
    "country": "Korea, South",
    "iso2": "KR",
    "iso3": "KOR",
    "admin_name": "Gyeongnam",
    "capital": "",
    "population": 113335,
    "id": 1410369367
  },
  {
    "city": "Kipushi",
    "city_ascii": "Kipushi",
    "lat": -11.76,
    "lng": 27.25,
    "country": "Congo (Kinshasa)",
    "iso2": "CD",
    "iso3": "COD",
    "admin_name": "Haut-Katanga",
    "capital": "",
    "population": 113347,
    "id": 1180810333
  },
  {
    "city": "Linhares",
    "city_ascii": "Linhares",
    "lat": -19.39,
    "lng": -40.05,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Espírito Santo",
    "capital": "",
    "population": 105075,
    "id": 1076355611
  },
  {
    "city": "Catanduva",
    "city_ascii": "Catanduva",
    "lat": -21.14,
    "lng": -48.98,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 109612,
    "id": 1076772178
  },
  {
    "city": "Akçakale",
    "city_ascii": "Akcakale",
    "lat": 36.7108,
    "lng": 38.9478,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Şanlıurfa",
    "capital": "minor",
    "population": 113194,
    "id": 1792050087
  },
  {
    "city": "Bergisch Gladbach",
    "city_ascii": "Bergisch Gladbach",
    "lat": 50.9917,
    "lng": 7.1367,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "minor",
    "population": 111966,
    "id": 1276074373
  },
  {
    "city": "City of Isabela",
    "city_ascii": "City of Isabela",
    "lat": 6.7,
    "lng": 121.9667,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Basilan",
    "capital": "admin",
    "population": 112788,
    "id": 1608621038
  },
  {
    "city": "Brandon",
    "city_ascii": "Brandon",
    "lat": 27.9367,
    "lng": -82.3,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 113279,
    "id": 1840014151
  },
  {
    "city": "Jaén",
    "city_ascii": "Jaen",
    "lat": 37.7697,
    "lng": -3.7889,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Andalusia",
    "capital": "minor",
    "population": 112999,
    "id": 1724699771
  },
  {
    "city": "Novyy Urengoy",
    "city_ascii": "Novyy Urengoy",
    "lat": 66.0847,
    "lng": 76.6789,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Yamalo-Nenetskiy Avtonomnyy Okrug",
    "capital": "",
    "population": 113254,
    "id": 1643427847
  },
  {
    "city": "Montero",
    "city_ascii": "Montero",
    "lat": -17.3333,
    "lng": -63.3833,
    "country": "Bolivia",
    "iso2": "BO",
    "iso3": "BOL",
    "admin_name": "Santa Cruz",
    "capital": "",
    "population": 112837,
    "id": 1068836323
  },
  {
    "city": "Burdur",
    "city_ascii": "Burdur",
    "lat": 37.7167,
    "lng": 30.2833,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Burdur",
    "capital": "admin",
    "population": 113077,
    "id": 1792413556
  },
  {
    "city": "Westminster",
    "city_ascii": "Westminster",
    "lat": 39.8837,
    "lng": -105.0624,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 113166,
    "id": 1840021423
  },
  {
    "city": "Castelar",
    "city_ascii": "Castelar",
    "lat": -34.65,
    "lng": -58.65,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires",
    "capital": "",
    "population": 107786,
    "id": 1032123891
  },
  {
    "city": "Sokodé",
    "city_ascii": "Sokode",
    "lat": 8.9833,
    "lng": 1.1333,
    "country": "Togo",
    "iso2": "TG",
    "iso3": "TGO",
    "admin_name": "Centrale",
    "capital": "admin",
    "population": 113000,
    "id": 1768875145
  },
  {
    "city": "Costa Mesa",
    "city_ascii": "Costa Mesa",
    "lat": 33.6667,
    "lng": -117.9135,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 113003,
    "id": 1840019326
  },
  {
    "city": "Rotherham",
    "city_ascii": "Rotherham",
    "lat": 53.43,
    "lng": -1.357,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Rotherham",
    "capital": "",
    "population": 109691,
    "id": 1826763937
  },
  {
    "city": "Hualien",
    "city_ascii": "Hualien",
    "lat": 23.9769,
    "lng": 121.6044,
    "country": "Taiwan",
    "iso2": "TW",
    "iso3": "TWN",
    "admin_name": "Hualien",
    "capital": "admin",
    "population": 109251,
    "id": 1158174293
  },
  {
    "city": "San Carlos de Bariloche",
    "city_ascii": "San Carlos de Bariloche",
    "lat": -41.15,
    "lng": -71.3,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Río Negro",
    "capital": "minor",
    "population": 112887,
    "id": 1032111842
  },
  {
    "city": "Tondabayashichō",
    "city_ascii": "Tondabayashicho",
    "lat": 34.4992,
    "lng": 135.5972,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Ōsaka",
    "capital": "",
    "population": 110132,
    "id": 1392173163
  },
  {
    "city": "Monroe",
    "city_ascii": "Monroe",
    "lat": 32.5183,
    "lng": -92.0774,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 112891,
    "id": 1840014881
  },
  {
    "city": "Zwolle",
    "city_ascii": "Zwolle",
    "lat": 52.524,
    "lng": 6.097,
    "country": "Netherlands",
    "iso2": "NL",
    "iso3": "NLD",
    "admin_name": "Overijssel",
    "capital": "admin",
    "population": 111805,
    "id": 1528690076
  },
  {
    "city": "Utica",
    "city_ascii": "Utica",
    "lat": 43.0961,
    "lng": -75.226,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 112650,
    "id": 1840002746
  },
  {
    "city": "Santa Lucía",
    "city_ascii": "Santa Lucia",
    "lat": 10.2606,
    "lng": -66.6639,
    "country": "Venezuela",
    "iso2": "VE",
    "iso3": "VEN",
    "admin_name": "Miranda",
    "capital": "minor",
    "population": 112357,
    "id": 1862614387
  },
  {
    "city": "Carora",
    "city_ascii": "Carora",
    "lat": 10.1755,
    "lng": -70.0828,
    "country": "Venezuela",
    "iso2": "VE",
    "iso3": "VEN",
    "admin_name": "Lara",
    "capital": "minor",
    "population": 112600,
    "id": 1862485109
  },
  {
    "city": "Khenchela",
    "city_ascii": "Khenchela",
    "lat": 35.4167,
    "lng": 7.1333,
    "country": "Algeria",
    "iso2": "DZ",
    "iso3": "DZA",
    "admin_name": "Khenchela",
    "capital": "admin",
    "population": 108580,
    "id": 1012495148
  },
  {
    "city": "Iriga City",
    "city_ascii": "Iriga City",
    "lat": 13.4167,
    "lng": 123.4167,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Camarines Sur",
    "capital": "",
    "population": 111757,
    "id": 1608112592
  },
  {
    "city": "Champdani",
    "city_ascii": "Champdani",
    "lat": 22.8,
    "lng": 88.37,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "West Bengal",
    "capital": "",
    "population": 111251,
    "id": 1356132208
  },
  {
    "city": "Remscheid",
    "city_ascii": "Remscheid",
    "lat": 51.1906,
    "lng": 7.2247,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "minor",
    "population": 110994,
    "id": 1276043920
  },
  {
    "city": "Kamyshin",
    "city_ascii": "Kamyshin",
    "lat": 50.0833,
    "lng": 45.4,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Volgogradskaya Oblast’",
    "capital": "",
    "population": 111100,
    "id": 1643117920
  },
  {
    "city": "Osmānābād",
    "city_ascii": "Osmanabad",
    "lat": 18.1667,
    "lng": 76.05,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Mahārāshtra",
    "capital": "",
    "population": 112085,
    "id": 1356005329
  },
  {
    "city": "Algeciras",
    "city_ascii": "Algeciras",
    "lat": 36.1267,
    "lng": -5.4665,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Andalusia",
    "capital": "",
    "population": 111027,
    "id": 1724355681
  },
  {
    "city": "Esteban Echeverría",
    "city_ascii": "Esteban Echeverria",
    "lat": -34.8167,
    "lng": -58.4667,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires",
    "capital": "",
    "population": 109644,
    "id": 1032069640
  },
  {
    "city": "Dolgoprudnyy",
    "city_ascii": "Dolgoprudnyy",
    "lat": 55.9333,
    "lng": 37.5,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Moskovskaya Oblast’",
    "capital": "",
    "population": 108861,
    "id": 1643283176
  },
  {
    "city": "Shkodër",
    "city_ascii": "Shkoder",
    "lat": 42.0667,
    "lng": 19.5,
    "country": "Albania",
    "iso2": "AL",
    "iso3": "ALB",
    "admin_name": "Shkodër",
    "capital": "admin",
    "population": 112276,
    "id": 1008977725
  },
  {
    "city": "Jena",
    "city_ascii": "Jena",
    "lat": 50.9272,
    "lng": 11.5864,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Thuringia",
    "capital": "minor",
    "population": 111407,
    "id": 1276659978
  },
  {
    "city": "Mopti",
    "city_ascii": "Mopti",
    "lat": 14.49,
    "lng": -4.18,
    "country": "Mali",
    "iso2": "ML",
    "iso3": "MLI",
    "admin_name": "Mopti",
    "capital": "admin",
    "population": 108456,
    "id": 1466352252
  },
  {
    "city": "Kars",
    "city_ascii": "Kars",
    "lat": 40.6069,
    "lng": 43.0931,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Kars",
    "capital": "admin",
    "population": 112260,
    "id": 1792000464
  },
  {
    "city": "Olmaliq",
    "city_ascii": "Olmaliq",
    "lat": 40.85,
    "lng": 69.6,
    "country": "Uzbekistan",
    "iso2": "UZ",
    "iso3": "UZB",
    "admin_name": "Toshkent",
    "capital": "",
    "population": 112078,
    "id": 1860946502
  },
  {
    "city": "Cavite City",
    "city_ascii": "Cavite City",
    "lat": 14.4833,
    "lng": 120.9,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Cavite",
    "capital": "",
    "population": 102806,
    "id": 1608639398
  },
  {
    "city": "Kuopio",
    "city_ascii": "Kuopio",
    "lat": 62.8925,
    "lng": 27.6783,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Pohjois-Savo",
    "capital": "admin",
    "population": 112117,
    "id": 1246334067
  },
  {
    "city": "Ciudad Mante",
    "city_ascii": "Ciudad Mante",
    "lat": 22.7333,
    "lng": -98.95,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Tamaulipas",
    "capital": "minor",
    "population": 112061,
    "id": 1484536173
  },
  {
    "city": "Pompano Beach",
    "city_ascii": "Pompano Beach",
    "lat": 26.2428,
    "lng": -80.1312,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 112118,
    "id": 1840015143
  },
  {
    "city": "Tablada",
    "city_ascii": "Tablada",
    "lat": -34.6833,
    "lng": -58.5333,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires",
    "capital": "",
    "population": 102542,
    "id": 1032988297
  },
  {
    "city": "Laoag",
    "city_ascii": "Laoag",
    "lat": 18.2,
    "lng": 120.6,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Ilocos Norte",
    "capital": "admin",
    "population": 111125,
    "id": 1608043510
  },
  {
    "city": "Zhukovskiy",
    "city_ascii": "Zhukovskiy",
    "lat": 55.6011,
    "lng": 38.1161,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Moskovskaya Oblast’",
    "capital": "",
    "population": 107994,
    "id": 1643012071
  },
  {
    "city": "West Palm Beach",
    "city_ascii": "West Palm Beach",
    "lat": 26.7468,
    "lng": -80.1316,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 111955,
    "id": 1840015993
  },
  {
    "city": "Funchal",
    "city_ascii": "Funchal",
    "lat": 32.65,
    "lng": -16.9167,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Madeira",
    "capital": "admin",
    "population": 111892,
    "id": 1620995356
  },
  {
    "city": "Ligao",
    "city_ascii": "Ligao",
    "lat": 13.2167,
    "lng": 123.5167,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Albay",
    "capital": "",
    "population": 111399,
    "id": 1608907106
  },
  {
    "city": "Kuqa",
    "city_ascii": "Kuqa",
    "lat": 41.7277,
    "lng": 82.9364,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Xinjiang",
    "capital": "minor",
    "population": 111499,
    "id": 1156894895
  },
  {
    "city": "Nsukka",
    "city_ascii": "Nsukka",
    "lat": 6.8567,
    "lng": 7.3958,
    "country": "Nigeria",
    "iso2": "NG",
    "iso3": "NGA",
    "admin_name": "Enugu",
    "capital": "minor",
    "population": 111017,
    "id": 1566882360
  },
  {
    "city": "Escuintla",
    "city_ascii": "Escuintla",
    "lat": 15.3194,
    "lng": -92.6583,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Chiapas",
    "capital": "minor",
    "population": 107638,
    "id": 1484508510
  },
  {
    "city": "Kazo",
    "city_ascii": "Kazo",
    "lat": 36.1314,
    "lng": 139.6019,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Saitama",
    "capital": "",
    "population": 110921,
    "id": 1392384497
  },
  {
    "city": "Hounslow",
    "city_ascii": "Hounslow",
    "lat": 51.4668,
    "lng": -0.375,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hounslow",
    "capital": "",
    "population": 103337,
    "id": 1826943293
  },
  {
    "city": "Terni",
    "city_ascii": "Terni",
    "lat": 42.5667,
    "lng": 12.65,
    "country": "Italy",
    "iso2": "IT",
    "iso3": "ITA",
    "admin_name": "Umbria",
    "capital": "minor",
    "population": 111189,
    "id": 1380323915
  },
  {
    "city": "Waterloo",
    "city_ascii": "Waterloo",
    "lat": 42.492,
    "lng": -92.3522,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Iowa",
    "capital": "",
    "population": 111631,
    "id": 1840000440
  },
  {
    "city": "Trier",
    "city_ascii": "Trier",
    "lat": 49.7567,
    "lng": 6.6414,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Rhineland-Palatinate",
    "capital": "minor",
    "population": 110636,
    "id": 1276211430
  },
  {
    "city": "Terrebonne",
    "city_ascii": "Terrebonne",
    "lat": 45.7,
    "lng": -73.6333,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 111575,
    "id": 1124993674
  },
  {
    "city": "Namur",
    "city_ascii": "Namur",
    "lat": 50.4667,
    "lng": 4.8667,
    "country": "Belgium",
    "iso2": "BE",
    "iso3": "BEL",
    "admin_name": "Wallonia",
    "capital": "admin",
    "population": 110939,
    "id": 1056954156
  },
  {
    "city": "Rochdale",
    "city_ascii": "Rochdale",
    "lat": 53.6136,
    "lng": -2.161,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Rochdale",
    "capital": "",
    "population": 107926,
    "id": 1826578106
  },
  {
    "city": "Murom",
    "city_ascii": "Murom",
    "lat": 55.5725,
    "lng": 42.0514,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Vladimirskaya Oblast’",
    "capital": "",
    "population": 109072,
    "id": 1643769134
  },
  {
    "city": "Bedford",
    "city_ascii": "Bedford",
    "lat": 52.135,
    "lng": -0.47,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Bedford",
    "capital": "",
    "population": 106940,
    "id": 1826771105
  },
  {
    "city": "Everett",
    "city_ascii": "Everett",
    "lat": 47.9525,
    "lng": -122.1669,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 111475,
    "id": 1840019785
  },
  {
    "city": "Manzini",
    "city_ascii": "Manzini",
    "lat": -26.4833,
    "lng": 31.3667,
    "country": "Swaziland",
    "iso2": "SZ",
    "iso3": "SWZ",
    "admin_name": "Manzini",
    "capital": "admin",
    "population": 110508,
    "id": 1748177431
  },
  {
    "city": "El Centro",
    "city_ascii": "El Centro",
    "lat": 32.7867,
    "lng": -115.5586,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 111425,
    "id": 1840020630
  },
  {
    "city": "Villa Mercedes",
    "city_ascii": "Villa Mercedes",
    "lat": -33.6667,
    "lng": -65.4667,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "San Luis",
    "capital": "minor",
    "population": 111391,
    "id": 1032858021
  },
  {
    "city": "Winterthur",
    "city_ascii": "Winterthur",
    "lat": 47.4992,
    "lng": 8.7267,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Zürich",
    "capital": "minor",
    "population": 109775,
    "id": 1756362130
  },
  {
    "city": "Jawhar",
    "city_ascii": "Jawhar",
    "lat": 2.767,
    "lng": 45.5166,
    "country": "Somalia",
    "iso2": "SO",
    "iso3": "SOM",
    "admin_name": "Shabeellaha Dhexe",
    "capital": "admin",
    "population": 111308,
    "id": 1706737655
  },
  {
    "city": "Sandachō",
    "city_ascii": "Sandacho",
    "lat": 34.8894,
    "lng": 135.2253,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hyōgo",
    "capital": "",
    "population": 110820,
    "id": 1392493731
  },
  {
    "city": "Tuzla",
    "city_ascii": "Tuzla",
    "lat": 44.5381,
    "lng": 18.6761,
    "country": "Bosnia And Herzegovina",
    "iso2": "BA",
    "iso3": "BIH",
    "admin_name": "Bosnia and Herzegovina, Federation of",
    "capital": "minor",
    "population": 110979,
    "id": 1070117835
  },
  {
    "city": "Tarnów",
    "city_ascii": "Tarnow",
    "lat": 50.0125,
    "lng": 20.9883,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Małopolskie",
    "capital": "minor",
    "population": 110956,
    "id": 1616375879
  },
  {
    "city": "Santa Fe",
    "city_ascii": "Santa Fe",
    "lat": 35.6619,
    "lng": -105.9819,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Mexico",
    "capital": "admin",
    "population": 111269,
    "id": 1840021707
  },
  {
    "city": "Khardah",
    "city_ascii": "Khardah",
    "lat": 22.72,
    "lng": 88.38,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "West Bengal",
    "capital": "",
    "population": 108496,
    "id": 1356765227
  },
  {
    "city": "Gapan",
    "city_ascii": "Gapan",
    "lat": 15.3075,
    "lng": 120.9453,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Nueva Ecija",
    "capital": "",
    "population": 110303,
    "id": 1608386821
  },
  {
    "city": "Nguru",
    "city_ascii": "Nguru",
    "lat": 12.8804,
    "lng": 10.45,
    "country": "Nigeria",
    "iso2": "NG",
    "iso3": "NGA",
    "admin_name": "Yobe",
    "capital": "minor",
    "population": 111014,
    "id": 1566737779
  },
  {
    "city": "Downey",
    "city_ascii": "Downey",
    "lat": 33.9379,
    "lng": -118.1312,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 111126,
    "id": 1840019245
  },
  {
    "city": "Stockport",
    "city_ascii": "Stockport",
    "lat": 53.4083,
    "lng": -2.1494,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Stockport",
    "capital": "",
    "population": 105878,
    "id": 1826228303
  },
  {
    "city": "Kecskemét",
    "city_ascii": "Kecskemet",
    "lat": 46.9074,
    "lng": 19.6917,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Bács-Kiskun",
    "capital": "admin",
    "population": 110687,
    "id": 1348900171
  },
  {
    "city": "Lowell",
    "city_ascii": "Lowell",
    "lat": 42.6389,
    "lng": -71.3217,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 110997,
    "id": 1840000426
  },
  {
    "city": "Ahuachapán",
    "city_ascii": "Ahuachapan",
    "lat": 13.9214,
    "lng": -89.845,
    "country": "El Salvador",
    "iso2": "SV",
    "iso3": "SLV",
    "admin_name": "Ahuachapán",
    "capital": "admin",
    "population": 110511,
    "id": 1222480039
  },
  {
    "city": "Fresnillo",
    "city_ascii": "Fresnillo",
    "lat": 23.175,
    "lng": -102.8675,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Zacatecas",
    "capital": "minor",
    "population": 110892,
    "id": 1484014035
  },
  {
    "city": "Centennial",
    "city_ascii": "Centennial",
    "lat": 39.5926,
    "lng": -104.8674,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 110937,
    "id": 1840018792
  },
  {
    "city": "Włocławek",
    "city_ascii": "Wloclawek",
    "lat": 52.65,
    "lng": 19.05,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Kujawsko-Pomorskie",
    "capital": "minor",
    "population": 110802,
    "id": 1616143097
  },
  {
    "city": "Fasā",
    "city_ascii": "Fasa",
    "lat": 28.9381,
    "lng": 53.6481,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Fārs",
    "capital": "minor",
    "population": 110825,
    "id": 1364549592
  },
  {
    "city": "As Salamīyah",
    "city_ascii": "As Salamiyah",
    "lat": 35.0111,
    "lng": 37.0531,
    "country": "Syria",
    "iso2": "SY",
    "iso3": "SYR",
    "admin_name": "Ḩamāh",
    "capital": "minor",
    "population": 110683,
    "id": 1760391067
  },
  {
    "city": "Yessentuki",
    "city_ascii": "Yessentuki",
    "lat": 44.0431,
    "lng": 42.8644,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Stavropol’skiy Kray",
    "capital": "",
    "population": 108679,
    "id": 1643737725
  },
  {
    "city": "Elgin",
    "city_ascii": "Elgin",
    "lat": 42.0385,
    "lng": -88.3229,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 110849,
    "id": 1840008134
  },
  {
    "city": "Tabuk",
    "city_ascii": "Tabuk",
    "lat": 17.45,
    "lng": 121.4583,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Kalinga",
    "capital": "",
    "population": 110642,
    "id": 1608831439
  },
  {
    "city": "Kiffa",
    "city_ascii": "Kiffa",
    "lat": 16.6164,
    "lng": -11.4044,
    "country": "Mauritania",
    "iso2": "MR",
    "iso3": "MRT",
    "admin_name": "Assaba",
    "capital": "admin",
    "population": 110714,
    "id": 1478465771
  },
  {
    "city": "Dali",
    "city_ascii": "Dali",
    "lat": 34.7953,
    "lng": 109.9378,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Shaanxi",
    "capital": "minor",
    "population": 109696,
    "id": 1156708054
  },
  {
    "city": "Yenangyaung",
    "city_ascii": "Yenangyaung",
    "lat": 20.4583,
    "lng": 94.8732,
    "country": "Burma",
    "iso2": "MM",
    "iso3": "MMR",
    "admin_name": "Magway",
    "capital": "",
    "population": 110553,
    "id": 1104118838
  },
  {
    "city": "Ourinhos",
    "city_ascii": "Ourinhos",
    "lat": -22.9789,
    "lng": -49.8708,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 110282,
    "id": 1076551408
  },
  {
    "city": "Quíbor",
    "city_ascii": "Quibor",
    "lat": 9.9281,
    "lng": -69.5778,
    "country": "Venezuela",
    "iso2": "VE",
    "iso3": "VEN",
    "admin_name": "Lara",
    "capital": "minor",
    "population": 110536,
    "id": 1862178539
  },
  {
    "city": "Borāzjān",
    "city_ascii": "Borazjan",
    "lat": 29.2667,
    "lng": 51.2158,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Būshehr",
    "capital": "minor",
    "population": 110567,
    "id": 1364720199
  },
  {
    "city": "Richmond",
    "city_ascii": "Richmond",
    "lat": 37.9477,
    "lng": -122.339,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 110567,
    "id": 1840020277
  },
  {
    "city": "Shinyanga",
    "city_ascii": "Shinyanga",
    "lat": -3.6619,
    "lng": 33.4231,
    "country": "Tanzania",
    "iso2": "TZ",
    "iso3": "TZA",
    "admin_name": "Shinyanga",
    "capital": "admin",
    "population": 107362,
    "id": 1834972707
  },
  {
    "city": "Ash Shaykh ‘Uthmān",
    "city_ascii": "Ash Shaykh `Uthman",
    "lat": 12.8866,
    "lng": 45.0279,
    "country": "Yemen",
    "iso2": "YE",
    "iso3": "YEM",
    "admin_name": "‘Adan",
    "capital": "minor",
    "population": 105248,
    "id": 1887195126
  },
  {
    "city": "Genhe",
    "city_ascii": "Genhe",
    "lat": 50.7833,
    "lng": 121.5167,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Inner Mongolia",
    "capital": "minor",
    "population": 110438,
    "id": 1156133257
  },
  {
    "city": "Montego Bay",
    "city_ascii": "Montego Bay",
    "lat": 18.4667,
    "lng": -77.9167,
    "country": "Jamaica",
    "iso2": "JM",
    "iso3": "JAM",
    "admin_name": "Saint James",
    "capital": "admin",
    "population": 110115,
    "id": 1388955058
  },
  {
    "city": "Jeonghae",
    "city_ascii": "Jeonghae",
    "lat": 35.5653,
    "lng": 126.8561,
    "country": "Korea, South",
    "iso2": "KR",
    "iso3": "KOR",
    "admin_name": "Jeonbuk",
    "capital": "",
    "population": 110194,
    "id": 1410005963
  },
  {
    "city": "Shirayamamachi",
    "city_ascii": "Shirayamamachi",
    "lat": 36.5144,
    "lng": 136.5656,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Ishikawa",
    "capital": "",
    "population": 110188,
    "id": 1392840575
  },
  {
    "city": "Bāneh",
    "city_ascii": "Baneh",
    "lat": 35.9975,
    "lng": 45.8853,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Kordestān",
    "capital": "minor",
    "population": 110218,
    "id": 1364786972
  },
  {
    "city": "Mascara",
    "city_ascii": "Mascara",
    "lat": 35.4,
    "lng": 0.1333,
    "country": "Algeria",
    "iso2": "DZ",
    "iso3": "DZA",
    "admin_name": "Mascara",
    "capital": "admin",
    "population": 108587,
    "id": 1012738269
  },
  {
    "city": "Darlington",
    "city_ascii": "Darlington",
    "lat": 54.527,
    "lng": -1.5526,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Darlington",
    "capital": "",
    "population": 105564,
    "id": 1826453946
  },
  {
    "city": "Broken Arrow",
    "city_ascii": "Broken Arrow",
    "lat": 36.0365,
    "lng": -95.7809,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oklahoma",
    "capital": "",
    "population": 110198,
    "id": 1840019059
  },
  {
    "city": "Tinaquillo",
    "city_ascii": "Tinaquillo",
    "lat": 9.9167,
    "lng": -68.3,
    "country": "Venezuela",
    "iso2": "VE",
    "iso3": "VEN",
    "admin_name": "Cojedes",
    "capital": "minor",
    "population": 110000,
    "id": 1862118872
  },
  {
    "city": "Milton",
    "city_ascii": "Milton",
    "lat": 43.5083,
    "lng": -79.8833,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 110128,
    "id": 1124001426
  },
  {
    "city": "Yŏju",
    "city_ascii": "Yoju",
    "lat": 37.2939,
    "lng": 127.6383,
    "country": "Korea, South",
    "iso2": "KR",
    "iso3": "KOR",
    "admin_name": "Gyeonggi",
    "capital": "",
    "population": 109937,
    "id": 1410007012
  },
  {
    "city": "Bangaon",
    "city_ascii": "Bangaon",
    "lat": 23.07,
    "lng": 88.82,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "West Bengal",
    "capital": "",
    "population": 108864,
    "id": 1356624441
  },
  {
    "city": "Miami Gardens",
    "city_ascii": "Miami Gardens",
    "lat": 25.9433,
    "lng": -80.2426,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 110001,
    "id": 1840015151
  },
  {
    "city": "Bagé",
    "city_ascii": "Bage",
    "lat": -31.32,
    "lng": -54.1,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Rio Grande do Sul",
    "capital": "",
    "population": 106098,
    "id": 1076515015
  },
  {
    "city": "Pisco",
    "city_ascii": "Pisco",
    "lat": -13.71,
    "lng": -76.2032,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "Ica",
    "capital": "",
    "population": 109965,
    "id": 1604438126
  },
  {
    "city": "Dera Ismail Khan",
    "city_ascii": "Dera Ismail Khan",
    "lat": 31.8167,
    "lng": 70.9167,
    "country": "Pakistan",
    "iso2": "PK",
    "iso3": "PAK",
    "admin_name": "Khyber Pakhtunkhwa",
    "capital": "minor",
    "population": 109686,
    "id": 1586008360
  },
  {
    "city": "Taitung",
    "city_ascii": "Taitung",
    "lat": 22.7583,
    "lng": 121.1444,
    "country": "Taiwan",
    "iso2": "TW",
    "iso3": "TWN",
    "admin_name": "Taitung",
    "capital": "admin",
    "population": 108905,
    "id": 1158804050
  },
  {
    "city": "Mineshita",
    "city_ascii": "Mineshita",
    "lat": 35.1186,
    "lng": 138.9186,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Shizuoka",
    "capital": "",
    "population": 108118,
    "id": 1392924607
  },
  {
    "city": "The Woodlands",
    "city_ascii": "The Woodlands",
    "lat": 30.1738,
    "lng": -95.5134,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 109843,
    "id": 1840019585
  },
  {
    "city": "Bend",
    "city_ascii": "Bend",
    "lat": 44.0562,
    "lng": -121.3087,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 109802,
    "id": 1840018618
  },
  {
    "city": "Burlington",
    "city_ascii": "Burlington",
    "lat": 44.4877,
    "lng": -73.2314,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Vermont",
    "capital": "",
    "population": 109763,
    "id": 1840002310
  },
  {
    "city": "Kandi",
    "city_ascii": "Kandi",
    "lat": 11.1304,
    "lng": 2.94,
    "country": "Benin",
    "iso2": "BJ",
    "iso3": "BEN",
    "admin_name": "Alibori",
    "capital": "admin",
    "population": 109701,
    "id": 1204875413
  },
  {
    "city": "Taourirt",
    "city_ascii": "Taourirt",
    "lat": 34.41,
    "lng": -2.89,
    "country": "Morocco",
    "iso2": "MA",
    "iso3": "MAR",
    "admin_name": "Oriental",
    "capital": "",
    "population": 103398,
    "id": 1504648957
  },
  {
    "city": "Apucarana",
    "city_ascii": "Apucarana",
    "lat": -23.55,
    "lng": -51.47,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Paraná",
    "capital": "",
    "population": 107085,
    "id": 1076256387
  },
  {
    "city": "Koszalin",
    "city_ascii": "Koszalin",
    "lat": 54.1903,
    "lng": 16.1817,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Zachodniopomorskie",
    "capital": "minor",
    "population": 108576,
    "id": 1616258002
  },
  {
    "city": "Baybay",
    "city_ascii": "Baybay",
    "lat": 10.6833,
    "lng": 124.8,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Leyte",
    "capital": "",
    "population": 109432,
    "id": 1608960920
  },
  {
    "city": "Labé",
    "city_ascii": "Labe",
    "lat": 11.3167,
    "lng": -12.2833,
    "country": "Guinea",
    "iso2": "GN",
    "iso3": "GIN",
    "admin_name": "Labé",
    "capital": "admin",
    "population": 107695,
    "id": 1324900784
  },
  {
    "city": "Jurupa Valley",
    "city_ascii": "Jurupa Valley",
    "lat": 34.001,
    "lng": -117.4705,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 109527,
    "id": 1840043419
  },
  {
    "city": "Gualeguaychú",
    "city_ascii": "Gualeguaychu",
    "lat": -33.0078,
    "lng": -58.5111,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Entre Ríos",
    "capital": "minor",
    "population": 109461,
    "id": 1032523704
  },
  {
    "city": "Caen",
    "city_ascii": "Caen",
    "lat": 49.18,
    "lng": -0.37,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Normandie",
    "capital": "minor",
    "population": 105354,
    "id": 1250001272
  },
  {
    "city": "Botoşani",
    "city_ascii": "Botosani",
    "lat": 47.7486,
    "lng": 26.6694,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Botoşani",
    "capital": "admin",
    "population": 106847,
    "id": 1642183791
  },
  {
    "city": "Sandy Springs",
    "city_ascii": "Sandy Springs",
    "lat": 33.9366,
    "lng": -84.3703,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 109452,
    "id": 1840015609
  },
  {
    "city": "Yŏngju",
    "city_ascii": "Yongju",
    "lat": 36.8217,
    "lng": 128.6308,
    "country": "Korea, South",
    "iso2": "KR",
    "iso3": "KOR",
    "admin_name": "Gyeongbuk",
    "capital": "",
    "population": 109266,
    "id": 1410990908
  },
  {
    "city": "Gresham",
    "city_ascii": "Gresham",
    "lat": 45.5023,
    "lng": -122.4413,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 109381,
    "id": 1840019939
  },
  {
    "city": "Uitenhage",
    "city_ascii": "Uitenhage",
    "lat": -33.75,
    "lng": 25.4,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "Eastern Cape",
    "capital": "",
    "population": 103639,
    "id": 1710276157
  },
  {
    "city": "Bukoba",
    "city_ascii": "Bukoba",
    "lat": -1.3333,
    "lng": 31.8167,
    "country": "Tanzania",
    "iso2": "TZ",
    "iso3": "TZA",
    "admin_name": "Kagera",
    "capital": "admin",
    "population": 100504,
    "id": 1834433264
  },
  {
    "city": "Corumbá",
    "city_ascii": "Corumba",
    "lat": -19.0089,
    "lng": -57.6528,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Mato Grosso do Sul",
    "capital": "",
    "population": 109294,
    "id": 1076131775
  },
  {
    "city": "Maxixe",
    "city_ascii": "Maxixe",
    "lat": -23.8597,
    "lng": 35.3472,
    "country": "Mozambique",
    "iso2": "MZ",
    "iso3": "MOZ",
    "admin_name": "Inhambane",
    "capital": "",
    "population": 108824,
    "id": 1508002342
  },
  {
    "city": "Lewisville",
    "city_ascii": "Lewisville",
    "lat": 33.0452,
    "lng": -96.9823,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 109212,
    "id": 1840020639
  },
  {
    "city": "Ipiales",
    "city_ascii": "Ipiales",
    "lat": 0.8289,
    "lng": -77.6406,
    "country": "Colombia",
    "iso2": "CO",
    "iso3": "COL",
    "admin_name": "Nariño",
    "capital": "minor",
    "population": 109116,
    "id": 1170309931
  },
  {
    "city": "Hillsboro",
    "city_ascii": "Hillsboro",
    "lat": 45.5272,
    "lng": -122.9361,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 109128,
    "id": 1840019932
  },
  {
    "city": "Soma",
    "city_ascii": "Soma",
    "lat": 39.1833,
    "lng": 27.6056,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Manisa",
    "capital": "minor",
    "population": 108981,
    "id": 1792461536
  },
  {
    "city": "San Buenaventura",
    "city_ascii": "San Buenaventura",
    "lat": 34.2741,
    "lng": -119.2314,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 109106,
    "id": 1840037414
  },
  {
    "city": "Musoma",
    "city_ascii": "Musoma",
    "lat": -1.5069,
    "lng": 33.8042,
    "country": "Tanzania",
    "iso2": "TZ",
    "iso3": "TZA",
    "admin_name": "Mara",
    "capital": "admin",
    "population": 103497,
    "id": 1834100025
  },
  {
    "city": "Novoshakhtinsk",
    "city_ascii": "Novoshakhtinsk",
    "lat": 47.7667,
    "lng": 39.9167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Rostovskaya Oblast’",
    "capital": "",
    "population": 108345,
    "id": 1643535469
  },
  {
    "city": "Crawley",
    "city_ascii": "Crawley",
    "lat": 51.1092,
    "lng": -0.1872,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "West Sussex",
    "capital": "",
    "population": 106597,
    "id": 1826470693
  },
  {
    "city": "Ferizaj",
    "city_ascii": "Ferizaj",
    "lat": 42.3667,
    "lng": 21.1667,
    "country": "Kosovo",
    "iso2": "XK",
    "iso3": "XKS",
    "admin_name": "Ferizaj",
    "capital": "admin",
    "population": 108610,
    "id": 1901875540
  },
  {
    "city": "Ikeda",
    "city_ascii": "Ikeda",
    "lat": 34.8167,
    "lng": 135.4333,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Ōsaka",
    "capital": "",
    "population": 104169,
    "id": 1392188564
  },
  {
    "city": "St. John's",
    "city_ascii": "St. John's",
    "lat": 47.4817,
    "lng": -52.7971,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Newfoundland and Labrador",
    "capital": "",
    "population": 108860,
    "id": 1124741456
  },
  {
    "city": "Jacksonville",
    "city_ascii": "Jacksonville",
    "lat": 34.7288,
    "lng": -77.3941,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 108856,
    "id": 1840014653
  },
  {
    "city": "Salford",
    "city_ascii": "Salford",
    "lat": 53.483,
    "lng": -2.2931,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Salford",
    "capital": "",
    "population": 103886,
    "id": 1826868550
  },
  {
    "city": "Wembley",
    "city_ascii": "Wembley",
    "lat": 51.5528,
    "lng": -0.2979,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Brent",
    "capital": "",
    "population": 102856,
    "id": 1826635683
  },
  {
    "city": "Pottstown",
    "city_ascii": "Pottstown",
    "lat": 40.2507,
    "lng": -75.6444,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 108758,
    "id": 1840001373
  },
  {
    "city": "Tajimi",
    "city_ascii": "Tajimi",
    "lat": 35.3328,
    "lng": 137.1322,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Gifu",
    "capital": "",
    "population": 107535,
    "id": 1392899702
  },
  {
    "city": "Moncton",
    "city_ascii": "Moncton",
    "lat": 46.1328,
    "lng": -64.7714,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "New Brunswick",
    "capital": "",
    "population": 108620,
    "id": 1124521303
  },
  {
    "city": "Tādpatri",
    "city_ascii": "Tadpatri",
    "lat": 14.92,
    "lng": 78.02,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Andhra Pradesh",
    "capital": "",
    "population": 108171,
    "id": 1356318314
  },
  {
    "city": "Huauchinango",
    "city_ascii": "Huauchinango",
    "lat": 20.1767,
    "lng": -98.0528,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Puebla",
    "capital": "minor",
    "population": 97753,
    "id": 1484856743
  },
  {
    "city": "Yāsūj",
    "city_ascii": "Yasuj",
    "lat": 30.6667,
    "lng": 51.5833,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Kohgīlūyeh va Bowyer Aḩmad",
    "capital": "admin",
    "population": 108505,
    "id": 1364652740
  },
  {
    "city": "Jalpāiguri",
    "city_ascii": "Jalpaiguri",
    "lat": 26.5167,
    "lng": 88.7333,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "West Bengal",
    "capital": "",
    "population": 107341,
    "id": 1356763479
  },
  {
    "city": "Dagenham",
    "city_ascii": "Dagenham",
    "lat": 51.5397,
    "lng": 0.1422,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Barking and Dagenham",
    "capital": "",
    "population": 106247,
    "id": 1826363543
  },
  {
    "city": "Barletta",
    "city_ascii": "Barletta",
    "lat": 41.32,
    "lng": 16.27,
    "country": "Italy",
    "iso2": "IT",
    "iso3": "ITA",
    "admin_name": "Puglia",
    "capital": "minor",
    "population": 107830,
    "id": 1380940717
  },
  {
    "city": "Birnin Kebbi",
    "city_ascii": "Birnin Kebbi",
    "lat": 12.4504,
    "lng": 4.1999,
    "country": "Nigeria",
    "iso2": "NG",
    "iso3": "NGA",
    "admin_name": "Kebbi",
    "capital": "admin",
    "population": 108164,
    "id": 1566191812
  },
  {
    "city": "Šiauliai",
    "city_ascii": "Siauliai",
    "lat": 55.9281,
    "lng": 23.3167,
    "country": "Lithuania",
    "iso2": "LT",
    "iso3": "LTU",
    "admin_name": "Šiaulių Miestas",
    "capital": "admin",
    "population": 107086,
    "id": 1440593416
  },
  {
    "city": "Seversk",
    "city_ascii": "Seversk",
    "lat": 56.6,
    "lng": 84.85,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Tomskaya Oblast’",
    "capital": "",
    "population": 107922,
    "id": 1643818456
  },
  {
    "city": "Teófilo Otoni",
    "city_ascii": "Teofilo Otoni",
    "lat": -17.87,
    "lng": -41.5,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Minas Gerais",
    "capital": "",
    "population": 101170,
    "id": 1076465745
  },
  {
    "city": "El Limón",
    "city_ascii": "El Limon",
    "lat": 10.3059,
    "lng": -67.6321,
    "country": "Venezuela",
    "iso2": "VE",
    "iso3": "VEN",
    "admin_name": "Aragua",
    "capital": "minor",
    "population": 106206,
    "id": 1862210475
  },
  {
    "city": "Inglewood",
    "city_ascii": "Inglewood",
    "lat": 33.9566,
    "lng": -118.3444,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 108151,
    "id": 1840019237
  },
  {
    "city": "Orsha",
    "city_ascii": "Orsha",
    "lat": 54.5092,
    "lng": 30.4258,
    "country": "Belarus",
    "iso2": "BY",
    "iso3": "BLR",
    "admin_name": "Vitsyebskaya Voblasts’",
    "capital": "minor",
    "population": 108100,
    "id": 1112188007
  },
  {
    "city": "An Nuhūd",
    "city_ascii": "An Nuhud",
    "lat": 12.6904,
    "lng": 28.42,
    "country": "Sudan",
    "iso2": "SD",
    "iso3": "SDN",
    "admin_name": "Western Kordofan",
    "capital": "",
    "population": 108008,
    "id": 1729223651
  },
  {
    "city": "San Rafael",
    "city_ascii": "San Rafael",
    "lat": -34.6175,
    "lng": -68.3356,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Mendoza",
    "capital": "minor",
    "population": 107997,
    "id": 1032221516
  },
  {
    "city": "Tagbilaran City",
    "city_ascii": "Tagbilaran City",
    "lat": 9.65,
    "lng": 123.85,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Bohol",
    "capital": "minor",
    "population": 105051,
    "id": 1608573250
  },
  {
    "city": "Thunder Bay",
    "city_ascii": "Thunder Bay",
    "lat": 48.3822,
    "lng": -89.2461,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 107909,
    "id": 1124398712
  },
  {
    "city": "Pavlohrad",
    "city_ascii": "Pavlohrad",
    "lat": 48.52,
    "lng": 35.87,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Dnipropetrovs’ka Oblast’",
    "capital": "minor",
    "population": 106082,
    "id": 1804162854
  },
  {
    "city": "Spring Hill",
    "city_ascii": "Spring Hill",
    "lat": 28.4797,
    "lng": -82.53,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 107855,
    "id": 1840014105
  },
  {
    "city": "Yevpatoriya",
    "city_ascii": "Yevpatoriya",
    "lat": 45.2,
    "lng": 33.3583,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Krym, Avtonomna Respublika",
    "capital": "",
    "population": 106158,
    "id": 1804279364
  },
  {
    "city": "Douliu",
    "city_ascii": "Douliu",
    "lat": 23.7075,
    "lng": 120.5439,
    "country": "Taiwan",
    "iso2": "TW",
    "iso3": "TWN",
    "admin_name": "Yunlin",
    "capital": "admin",
    "population": 106653,
    "id": 1158732750
  },
  {
    "city": "Juazeiro",
    "city_ascii": "Juazeiro",
    "lat": -9.42,
    "lng": -40.5,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Bahia",
    "capital": "",
    "population": 95132,
    "id": 1076632040
  },
  {
    "city": "Chaman",
    "city_ascii": "Chaman",
    "lat": 30.921,
    "lng": 66.4597,
    "country": "Pakistan",
    "iso2": "PK",
    "iso3": "PAK",
    "admin_name": "Balochistān",
    "capital": "",
    "population": 107660,
    "id": 1586640695
  },
  {
    "city": "Queenstown",
    "city_ascii": "Queenstown",
    "lat": -31.9,
    "lng": 26.8833,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "Eastern Cape",
    "capital": "",
    "population": 105309,
    "id": 1710110028
  },
  {
    "city": "Umm Qaşr",
    "city_ascii": "Umm Qasr",
    "lat": 30.0342,
    "lng": 47.9294,
    "country": "Iraq",
    "iso2": "IQ",
    "iso3": "IRQ",
    "admin_name": "Al Başrah",
    "capital": "",
    "population": 107620,
    "id": 1368478549
  },
  {
    "city": "Sliven",
    "city_ascii": "Sliven",
    "lat": 42.6808,
    "lng": 26.3164,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Sliven",
    "capital": "admin",
    "population": 107570,
    "id": 1100110215
  },
  {
    "city": "Suriāpet",
    "city_ascii": "Suriapet",
    "lat": 17.15,
    "lng": 79.6167,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Telangana",
    "capital": "",
    "population": 106805,
    "id": 1356067301
  },
  {
    "city": "Kogon Shahri",
    "city_ascii": "Kogon Shahri",
    "lat": 39.7211,
    "lng": 64.5458,
    "country": "Uzbekistan",
    "iso2": "UZ",
    "iso3": "UZB",
    "admin_name": "Buxoro",
    "capital": "minor",
    "population": 107566,
    "id": 1860234026
  },
  {
    "city": "League City",
    "city_ascii": "League City",
    "lat": 29.4873,
    "lng": -95.1087,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 107536,
    "id": 1840020973
  },
  {
    "city": "Kefar Sava",
    "city_ascii": "Kefar Sava",
    "lat": 32.1858,
    "lng": 34.9077,
    "country": "Israel",
    "iso2": "IL",
    "iso3": "ISR",
    "admin_name": "Central",
    "capital": "",
    "population": 100800,
    "id": 1376008883
  },
  {
    "city": "Alchevsk",
    "city_ascii": "Alchevsk",
    "lat": 48.4672,
    "lng": 38.7983,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Luhans’ka Oblast’",
    "capital": "",
    "population": 105398,
    "id": 1804025187
  },
  {
    "city": "Gillingham",
    "city_ascii": "Gillingham",
    "lat": 51.385,
    "lng": 0.55,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Medway",
    "capital": "",
    "population": 104157,
    "id": 1826642243
  },
  {
    "city": "Eau Claire",
    "city_ascii": "Eau Claire",
    "lat": 44.8199,
    "lng": -91.4949,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 107404,
    "id": 1840002296
  },
  {
    "city": "Bolzano",
    "city_ascii": "Bolzano",
    "lat": 46.5,
    "lng": 11.35,
    "country": "Italy",
    "iso2": "IT",
    "iso3": "ITA",
    "admin_name": "Trentino-Alto Adige",
    "capital": "minor",
    "population": 107317,
    "id": 1380677819
  },
  {
    "city": "Charsadda",
    "city_ascii": "Charsadda",
    "lat": 34.1453,
    "lng": 71.7308,
    "country": "Pakistan",
    "iso2": "PK",
    "iso3": "PAK",
    "admin_name": "Khyber Pakhtunkhwa",
    "capital": "minor",
    "population": 105414,
    "id": 1586928614
  },
  {
    "city": "Roubaix",
    "city_ascii": "Roubaix",
    "lat": 50.6901,
    "lng": 3.1817,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Hauts-de-France",
    "capital": "",
    "population": 100000,
    "id": 1250000779
  },
  {
    "city": "Turlock",
    "city_ascii": "Turlock",
    "lat": 37.5053,
    "lng": -120.8588,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 107317,
    "id": 1840021533
  },
  {
    "city": "Temple",
    "city_ascii": "Temple",
    "lat": 31.1076,
    "lng": -97.3894,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 107258,
    "id": 1840022154
  },
  {
    "city": "La Piedad",
    "city_ascii": "La Piedad",
    "lat": 20.3333,
    "lng": -102.0167,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Michoacán de Ocampo",
    "capital": "minor",
    "population": 99837,
    "id": 1484779296
  },
  {
    "city": "Himamaylan",
    "city_ascii": "Himamaylan",
    "lat": 10.1,
    "lng": 122.8667,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Negros Occidental",
    "capital": "",
    "population": 106880,
    "id": 1608765297
  },
  {
    "city": "Mansfield",
    "city_ascii": "Mansfield",
    "lat": 53.15,
    "lng": -1.2,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Nottinghamshire",
    "capital": "",
    "population": 106556,
    "id": 1826359576
  },
  {
    "city": "Mmabatho",
    "city_ascii": "Mmabatho",
    "lat": -25.85,
    "lng": 25.6333,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "North West",
    "capital": "",
    "population": 104428,
    "id": 1710524815
  },
  {
    "city": "Ilebo",
    "city_ascii": "Ilebo",
    "lat": -4.3167,
    "lng": 20.6,
    "country": "Congo (Kinshasa)",
    "iso2": "CD",
    "iso3": "COD",
    "admin_name": "Kasaï",
    "capital": "",
    "population": 107093,
    "id": 1180788280
  },
  {
    "city": "Taungoo",
    "city_ascii": "Taungoo",
    "lat": 18.9483,
    "lng": 96.4179,
    "country": "Burma",
    "iso2": "MM",
    "iso3": "MMR",
    "admin_name": "Bago",
    "capital": "",
    "population": 106945,
    "id": 1104269989
  },
  {
    "city": "Dieppe",
    "city_ascii": "Dieppe",
    "lat": 46.0989,
    "lng": -64.7242,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "New Brunswick",
    "capital": "",
    "population": 107068,
    "id": 1124195431
  },
  {
    "city": "Arzamas",
    "city_ascii": "Arzamas",
    "lat": 55.3833,
    "lng": 43.8,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Nizhegorodskaya Oblast’",
    "capital": "",
    "population": 104547,
    "id": 1643088735
  },
  {
    "city": "Morón",
    "city_ascii": "Moron",
    "lat": -34.65,
    "lng": -58.6167,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires",
    "capital": "minor",
    "population": 99066,
    "id": 1032773254
  },
  {
    "city": "Longjiang",
    "city_ascii": "Longjiang",
    "lat": 47.3404,
    "lng": 123.18,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Heilongjiang",
    "capital": "minor",
    "population": 106384,
    "id": 1156263974
  },
  {
    "city": "San Felipe",
    "city_ascii": "San Felipe",
    "lat": 21.4833,
    "lng": -101.2167,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Guanajuato",
    "capital": "minor",
    "population": 106952,
    "id": 1484279509
  },
  {
    "city": "Songea",
    "city_ascii": "Songea",
    "lat": -10.6833,
    "lng": 35.65,
    "country": "Tanzania",
    "iso2": "TZ",
    "iso3": "TZA",
    "admin_name": "Ruvuma",
    "capital": "admin",
    "population": 99961,
    "id": 1834461509
  },
  {
    "city": "Artëm",
    "city_ascii": "Artem",
    "lat": 43.35,
    "lng": 132.1833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Primorskiy Kray",
    "capital": "",
    "population": 106692,
    "id": 1643978240
  },
  {
    "city": "Noyabrsk",
    "city_ascii": "Noyabrsk",
    "lat": 63.2017,
    "lng": 75.4517,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Yamalo-Nenetskiy Avtonomnyy Okrug",
    "capital": "",
    "population": 106879,
    "id": 1643340196
  },
  {
    "city": "Nakhon Si Thammarat",
    "city_ascii": "Nakhon Si Thammarat",
    "lat": 8.4333,
    "lng": 99.9667,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Nakhon Si Thammarat",
    "capital": "admin",
    "population": 106322,
    "id": 1764409111
  },
  {
    "city": "Petržalka",
    "city_ascii": "Petrzalka",
    "lat": 48.1333,
    "lng": 17.1167,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Bratislavský",
    "capital": "",
    "population": 103190,
    "id": 1703430611
  },
  {
    "city": "Chābahār",
    "city_ascii": "Chabahar",
    "lat": 25.2836,
    "lng": 60.6236,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Sīstān va Balūchestān",
    "capital": "minor",
    "population": 106739,
    "id": 1364525124
  },
  {
    "city": "Raba",
    "city_ascii": "Raba",
    "lat": -8.4614,
    "lng": 118.747,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Nusa Tenggara Barat",
    "capital": "minor",
    "population": 106101,
    "id": 1360014952
  },
  {
    "city": "La Banda",
    "city_ascii": "La Banda",
    "lat": -27.7333,
    "lng": -64.25,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Santiago del Estero",
    "capital": "minor",
    "population": 106441,
    "id": 1032317566
  },
  {
    "city": "Ourense",
    "city_ascii": "Ourense",
    "lat": 42.3364,
    "lng": -7.8633,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Galicia",
    "capital": "minor",
    "population": 105233,
    "id": 1724571895
  },
  {
    "city": "Sioux City",
    "city_ascii": "Sioux City",
    "lat": 42.4959,
    "lng": -96.3901,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Iowa",
    "capital": "",
    "population": 106455,
    "id": 1840000447
  },
  {
    "city": "Kemalpaşa",
    "city_ascii": "Kemalpasa",
    "lat": 38.4261,
    "lng": 27.4172,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "İzmir",
    "capital": "minor",
    "population": 106298,
    "id": 1792611875
  },
  {
    "city": "Salisbury",
    "city_ascii": "Salisbury",
    "lat": 38.3756,
    "lng": -75.5867,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 106447,
    "id": 1840006219
  },
  {
    "city": "Vĩnh Long",
    "city_ascii": "Vinh Long",
    "lat": 10.255,
    "lng": 105.9753,
    "country": "Vietnam",
    "iso2": "VN",
    "iso3": "VNM",
    "admin_name": "Vĩnh Long",
    "capital": "admin",
    "population": 103314,
    "id": 1704262419
  },
  {
    "city": "Ballarat",
    "city_ascii": "Ballarat",
    "lat": -37.55,
    "lng": 143.85,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Victoria",
    "capital": "",
    "population": 105471,
    "id": 1036567186
  },
  {
    "city": "Davie",
    "city_ascii": "Davie",
    "lat": 26.0789,
    "lng": -80.287,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 106306,
    "id": 1840017272
  },
  {
    "city": "Achinsk",
    "city_ascii": "Achinsk",
    "lat": 56.2817,
    "lng": 90.5039,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Krasnoyarskiy Kray",
    "capital": "",
    "population": 105264,
    "id": 1643890929
  },
  {
    "city": "Daly City",
    "city_ascii": "Daly City",
    "lat": 37.6862,
    "lng": -122.4685,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 106280,
    "id": 1840018926
  },
  {
    "city": "Toride",
    "city_ascii": "Toride",
    "lat": 35.9114,
    "lng": 140.0503,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Ibaraki",
    "capital": "",
    "population": 104667,
    "id": 1392922950
  },
  {
    "city": "Subotica",
    "city_ascii": "Subotica",
    "lat": 46.0983,
    "lng": 19.67,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Subotica",
    "capital": "admin",
    "population": 105681,
    "id": 1688081964
  },
  {
    "city": "Nusaybin",
    "city_ascii": "Nusaybin",
    "lat": 37.0833,
    "lng": 41.2167,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Mardin",
    "capital": "minor",
    "population": 105856,
    "id": 1792327949
  },
  {
    "city": "Trincomalee",
    "city_ascii": "Trincomalee",
    "lat": 8.5667,
    "lng": 81.2333,
    "country": "Sri Lanka",
    "iso2": "LK",
    "iso3": "LKA",
    "admin_name": "Eastern",
    "capital": "admin",
    "population": 99135,
    "id": 1144417074
  },
  {
    "city": "Akhmīm",
    "city_ascii": "Akhmim",
    "lat": 26.5642,
    "lng": 31.7461,
    "country": "Egypt",
    "iso2": "EG",
    "iso3": "EGY",
    "admin_name": "Sūhāj",
    "capital": "",
    "population": 101509,
    "id": 1818295057
  },
  {
    "city": "Azare",
    "city_ascii": "Azare",
    "lat": 11.6804,
    "lng": 10.19,
    "country": "Nigeria",
    "iso2": "NG",
    "iso3": "NGA",
    "admin_name": "Bauchi",
    "capital": "minor",
    "population": 105687,
    "id": 1566322480
  },
  {
    "city": "Brovary",
    "city_ascii": "Brovary",
    "lat": 50.5114,
    "lng": 30.7903,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Kyyivs’ka Oblast’",
    "capital": "minor",
    "population": 102856,
    "id": 1804386597
  },
  {
    "city": "Yelets",
    "city_ascii": "Yelets",
    "lat": 52.6167,
    "lng": 38.4667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Lipetskaya Oblast’",
    "capital": "",
    "population": 104349,
    "id": 1643014014
  },
  {
    "city": "Pushkino",
    "city_ascii": "Pushkino",
    "lat": 56.0167,
    "lng": 37.85,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Moskovskaya Oblast’",
    "capital": "",
    "population": 105479,
    "id": 1643799484
  },
  {
    "city": "Liberec",
    "city_ascii": "Liberec",
    "lat": 50.77,
    "lng": 15.0584,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Liberecký Kraj",
    "capital": "admin",
    "population": 104802,
    "id": 1203577727
  },
  {
    "city": "Mazyr",
    "city_ascii": "Mazyr",
    "lat": 52.05,
    "lng": 29.2333,
    "country": "Belarus",
    "iso2": "BY",
    "iso3": "BLR",
    "admin_name": "Homyel’skaya Voblasts’",
    "capital": "minor",
    "population": 105700,
    "id": 1112704173
  },
  {
    "city": "Tikrīt",
    "city_ascii": "Tikrit",
    "lat": 34.6,
    "lng": 43.6833,
    "country": "Iraq",
    "iso2": "IQ",
    "iso3": "IRQ",
    "admin_name": "Şalāḩ ad Dīn",
    "capital": "admin",
    "population": 105700,
    "id": 1368199624
  },
  {
    "city": "Robāţ Karīm",
    "city_ascii": "Robat Karim",
    "lat": 35.4847,
    "lng": 51.0828,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Tehrān",
    "capital": "minor",
    "population": 105393,
    "id": 1364282464
  },
  {
    "city": "Koutiala",
    "city_ascii": "Koutiala",
    "lat": 12.3904,
    "lng": -5.47,
    "country": "Mali",
    "iso2": "ML",
    "iso3": "MLI",
    "admin_name": "Sikasso",
    "capital": "minor",
    "population": 104927,
    "id": 1466253368
  },
  {
    "city": "Passos",
    "city_ascii": "Passos",
    "lat": -20.71,
    "lng": -46.61,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Minas Gerais",
    "capital": "",
    "population": 98752,
    "id": 1076782492
  },
  {
    "city": "Lerma",
    "city_ascii": "Lerma",
    "lat": 19.2847,
    "lng": -99.5119,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "México",
    "capital": "minor",
    "population": 105578,
    "id": 1484402392
  },
  {
    "city": "Ciudad Río Bravo",
    "city_ascii": "Ciudad Rio Bravo",
    "lat": 25.9861,
    "lng": -98.0889,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Tamaulipas",
    "capital": "minor",
    "population": 101481,
    "id": 1484549731
  },
  {
    "city": "Allen",
    "city_ascii": "Allen",
    "lat": 33.1088,
    "lng": -96.6735,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 105623,
    "id": 1840019396
  },
  {
    "city": "Tarīm",
    "city_ascii": "Tarim",
    "lat": 16.05,
    "lng": 49,
    "country": "Yemen",
    "iso2": "YE",
    "iso3": "YEM",
    "admin_name": "Ḩaḑramawt",
    "capital": "minor",
    "population": 105552,
    "id": 1887801013
  },
  {
    "city": "Kroonstad",
    "city_ascii": "Kroonstad",
    "lat": -27.6456,
    "lng": 27.2317,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "Free State",
    "capital": "",
    "population": 103992,
    "id": 1710508288
  },
  {
    "city": "Kandhkot",
    "city_ascii": "Kandhkot",
    "lat": 28.4,
    "lng": 69.3,
    "country": "Pakistan",
    "iso2": "PK",
    "iso3": "PAK",
    "admin_name": "Sindh",
    "capital": "",
    "population": 105011,
    "id": 1586311788
  },
  {
    "city": "Granada",
    "city_ascii": "Granada",
    "lat": 11.93,
    "lng": -85.9561,
    "country": "Nicaragua",
    "iso2": "NI",
    "iso3": "NIC",
    "admin_name": "Granada",
    "capital": "admin",
    "population": 105171,
    "id": 1558101678
  },
  {
    "city": "Novara",
    "city_ascii": "Novara",
    "lat": 45.45,
    "lng": 8.6167,
    "country": "Italy",
    "iso2": "IT",
    "iso3": "ITA",
    "admin_name": "Piedmont",
    "capital": "minor",
    "population": 104284,
    "id": 1380911437
  },
  {
    "city": "Kongolo",
    "city_ascii": "Kongolo",
    "lat": -5.3795,
    "lng": 26.98,
    "country": "Congo (Kinshasa)",
    "iso2": "CD",
    "iso3": "COD",
    "admin_name": "Tanganyika",
    "capital": "",
    "population": 105202,
    "id": 1180814807
  },
  {
    "city": "Moers",
    "city_ascii": "Moers",
    "lat": 51.4592,
    "lng": 6.6197,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 103725,
    "id": 1276257182
  },
  {
    "city": "Kalisz",
    "city_ascii": "Kalisz",
    "lat": 51.757,
    "lng": 18.083,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Wielkopolskie",
    "capital": "minor",
    "population": 103738,
    "id": 1616861321
  },
  {
    "city": "Saijō",
    "city_ascii": "Saijo",
    "lat": 33.9196,
    "lng": 133.1812,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Ehime",
    "capital": "",
    "population": 105020,
    "id": 1392903707
  },
  {
    "city": "Yozgat",
    "city_ascii": "Yozgat",
    "lat": 39.8208,
    "lng": 34.8083,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Yozgat",
    "capital": "admin",
    "population": 105167,
    "id": 1792862455
  },
  {
    "city": "Highlands Ranch",
    "city_ascii": "Highlands Ranch",
    "lat": 39.5419,
    "lng": -104.9708,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 105147,
    "id": 1840028579
  },
  {
    "city": "Al Fāw",
    "city_ascii": "Al Faw",
    "lat": 29.98,
    "lng": 48.47,
    "country": "Iraq",
    "iso2": "IQ",
    "iso3": "IRQ",
    "admin_name": "Al Başrah",
    "capital": "minor",
    "population": 105080,
    "id": 1368130668
  },
  {
    "city": "West Covina",
    "city_ascii": "West Covina",
    "lat": 34.0555,
    "lng": -117.9112,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 105101,
    "id": 1840021876
  },
  {
    "city": "Salzgitter",
    "city_ascii": "Salzgitter",
    "lat": 52.1503,
    "lng": 10.3593,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "minor",
    "population": 104948,
    "id": 1276613066
  },
  {
    "city": "Berdsk",
    "city_ascii": "Berdsk",
    "lat": 54.75,
    "lng": 83.1,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Novosibirskaya Oblast’",
    "capital": "",
    "population": 103578,
    "id": 1643751718
  },
  {
    "city": "Sparks",
    "city_ascii": "Sparks",
    "lat": 39.5729,
    "lng": -119.7157,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Nevada",
    "capital": "",
    "population": 105006,
    "id": 1840021337
  },
  {
    "city": "Waterloo",
    "city_ascii": "Waterloo",
    "lat": 43.4667,
    "lng": -80.5167,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 104986,
    "id": 1124321390
  },
  {
    "city": "Galle",
    "city_ascii": "Galle",
    "lat": 6.0395,
    "lng": 80.2194,
    "country": "Sri Lanka",
    "iso2": "LK",
    "iso3": "LKA",
    "admin_name": "Southern",
    "capital": "admin",
    "population": 99478,
    "id": 1144191486
  },
  {
    "city": "Tādepallegūdem",
    "city_ascii": "Tadepallegudem",
    "lat": 16.8333,
    "lng": 81.5,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Andhra Pradesh",
    "capital": "",
    "population": 104032,
    "id": 1356576541
  },
  {
    "city": "Zomba",
    "city_ascii": "Zomba",
    "lat": -15.3833,
    "lng": 35.3333,
    "country": "Malawi",
    "iso2": "MW",
    "iso3": "MWI",
    "admin_name": "Zomba",
    "capital": "admin",
    "population": 101140,
    "id": 1454072947
  },
  {
    "city": "Dobrich",
    "city_ascii": "Dobrich",
    "lat": 43.5667,
    "lng": 27.8333,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Dobrich",
    "capital": "admin",
    "population": 104530,
    "id": 1100364404
  },
  {
    "city": "Sergiyev Posad",
    "city_ascii": "Sergiyev Posad",
    "lat": 56.3,
    "lng": 38.1333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Moskovskaya Oblast’",
    "capital": "",
    "population": 104579,
    "id": 1643900929
  },
  {
    "city": "Konotop",
    "city_ascii": "Konotop",
    "lat": 51.2369,
    "lng": 33.2027,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Sums’ka Oblast’",
    "capital": "minor",
    "population": 103547,
    "id": 1804110853
  },
  {
    "city": "Courbevoic",
    "city_ascii": "Courbevoic",
    "lat": 48.8978,
    "lng": 2.2531,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 81719,
    "id": 1250060303
  },
  {
    "city": "Zihuatanejo",
    "city_ascii": "Zihuatanejo",
    "lat": 17.6444,
    "lng": -101.5511,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Guerrero",
    "capital": "minor",
    "population": 104609,
    "id": 1484489217
  },
  {
    "city": "Bouskoura",
    "city_ascii": "Bouskoura",
    "lat": 33.4489,
    "lng": -7.6486,
    "country": "Morocco",
    "iso2": "MA",
    "iso3": "MAR",
    "admin_name": "Casablanca-Settat",
    "capital": "",
    "population": 103026,
    "id": 1504503306
  },
  {
    "city": "Lysychansk",
    "city_ascii": "Lysychansk",
    "lat": 48.9169,
    "lng": 38.4306,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Luhans’ka Oblast’",
    "capital": "",
    "population": 103459,
    "id": 1804673535
  },
  {
    "city": "Khāk-e ‘Alī",
    "city_ascii": "Khak-e `Ali",
    "lat": 36.1283,
    "lng": 50.1764,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Qazvīn",
    "capital": "",
    "population": 104417,
    "id": 1364217752
  },
  {
    "city": "Maumere",
    "city_ascii": "Maumere",
    "lat": -8.6189,
    "lng": 122.2123,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Nusa Tenggara Timur",
    "capital": "minor",
    "population": 104285,
    "id": 1360776014
  },
  {
    "city": "Samal",
    "city_ascii": "Samal",
    "lat": 7.05,
    "lng": 125.7333,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Davao del Norte",
    "capital": "",
    "population": 104123,
    "id": 1608425228
  },
  {
    "city": "Girona",
    "city_ascii": "Girona",
    "lat": 41.9833,
    "lng": 2.8167,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Catalonia",
    "capital": "minor",
    "population": 101852,
    "id": 1724386661
  },
  {
    "city": "San Mateo",
    "city_ascii": "San Mateo",
    "lat": 37.5521,
    "lng": -122.3122,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 104430,
    "id": 1840021551
  },
  {
    "city": "Villa Luzuriaga",
    "city_ascii": "Villa Luzuriaga",
    "lat": -34.6667,
    "lng": -58.5833,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires",
    "capital": "",
    "population": 94403,
    "id": 1032092344
  },
  {
    "city": "Eastbourne",
    "city_ascii": "Eastbourne",
    "lat": 50.77,
    "lng": 0.28,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "East Sussex",
    "capital": "",
    "population": 103745,
    "id": 1826356320
  },
  {
    "city": "Worcester",
    "city_ascii": "Worcester",
    "lat": 52.192,
    "lng": -2.22,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Worcestershire",
    "capital": "",
    "population": 101328,
    "id": 1826393854
  },
  {
    "city": "Caseros",
    "city_ascii": "Caseros",
    "lat": -34.6167,
    "lng": -58.5333,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires",
    "capital": "minor",
    "population": 95785,
    "id": 1032500630
  },
  {
    "city": "Fyfield",
    "city_ascii": "Fyfield",
    "lat": 51.739,
    "lng": 0.2722,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Essex",
    "capital": "",
    "population": 104234,
    "id": 1826399602
  },
  {
    "city": "Tula de Allende",
    "city_ascii": "Tula de Allende",
    "lat": 20.05,
    "lng": -99.35,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Hidalgo",
    "capital": "",
    "population": 103919,
    "id": 1484845374
  },
  {
    "city": "Catbalogan",
    "city_ascii": "Catbalogan",
    "lat": 11.7833,
    "lng": 124.8833,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Samar",
    "capital": "admin",
    "population": 103879,
    "id": 1608878798
  },
  {
    "city": "Chongshan",
    "city_ascii": "Chongshan",
    "lat": 18.7787,
    "lng": 109.5117,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hainan",
    "capital": "minor",
    "population": 104119,
    "id": 1156147494
  },
  {
    "city": "Abengourou",
    "city_ascii": "Abengourou",
    "lat": 6.7297,
    "lng": -3.4964,
    "country": "Côte D’Ivoire",
    "iso2": "CI",
    "iso3": "CIV",
    "admin_name": "Comoé",
    "capital": "admin",
    "population": 104020,
    "id": 1384295898
  },
  {
    "city": "Lower Hutt",
    "city_ascii": "Lower Hutt",
    "lat": -41.2167,
    "lng": 174.9167,
    "country": "New Zealand",
    "iso2": "NZ",
    "iso3": "NZL",
    "admin_name": "Wellington",
    "capital": "",
    "population": 103400,
    "id": 1554623632
  },
  {
    "city": "Kalyani",
    "city_ascii": "Kalyani",
    "lat": 22.975,
    "lng": 88.4344,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "West Bengal",
    "capital": "",
    "population": 100575,
    "id": 1356108133
  },
  {
    "city": "Kilinochchi",
    "city_ascii": "Kilinochchi",
    "lat": 9.4004,
    "lng": 80.3999,
    "country": "Sri Lanka",
    "iso2": "LK",
    "iso3": "LKA",
    "admin_name": "Northern",
    "capital": "",
    "population": 103717,
    "id": 1144468936
  },
  {
    "city": "Sakado",
    "city_ascii": "Sakado",
    "lat": 35.9572,
    "lng": 139.4031,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Saitama",
    "capital": "",
    "population": 101531,
    "id": 1392771396
  },
  {
    "city": "Isehara",
    "city_ascii": "Isehara",
    "lat": 35.4028,
    "lng": 139.315,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Kanagawa",
    "capital": "",
    "population": 102137,
    "id": 1392928065
  },
  {
    "city": "Piacenza",
    "city_ascii": "Piacenza",
    "lat": 45.05,
    "lng": 9.7,
    "country": "Italy",
    "iso2": "IT",
    "iso3": "ITA",
    "admin_name": "Emilia-Romagna",
    "capital": "minor",
    "population": 103082,
    "id": 1380821832
  },
  {
    "city": "Ed Damer",
    "city_ascii": "Ed Damer",
    "lat": 17.59,
    "lng": 33.96,
    "country": "Sudan",
    "iso2": "SD",
    "iso3": "SDN",
    "admin_name": "River Nile",
    "capital": "admin",
    "population": 103941,
    "id": 1729791168
  },
  {
    "city": "Norwalk",
    "city_ascii": "Norwalk",
    "lat": 33.9069,
    "lng": -118.0829,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 103949,
    "id": 1840020501
  },
  {
    "city": "Santa Rosa",
    "city_ascii": "Santa Rosa",
    "lat": -36.6203,
    "lng": -64.2906,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "La Pampa",
    "capital": "admin",
    "population": 103860,
    "id": 1032215828
  },
  {
    "city": "Dolisie",
    "city_ascii": "Dolisie",
    "lat": -4.1961,
    "lng": 12.6731,
    "country": "Congo (Brazzaville)",
    "iso2": "CG",
    "iso3": "COG",
    "admin_name": "Niari",
    "capital": "admin",
    "population": 103894,
    "id": 1178045241
  },
  {
    "city": "Negapatam",
    "city_ascii": "Negapatam",
    "lat": 10.7667,
    "lng": 79.8333,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Tamil Nādu",
    "capital": "",
    "population": 102905,
    "id": 1356879770
  },
  {
    "city": "Wigan",
    "city_ascii": "Wigan",
    "lat": 53.5448,
    "lng": -2.6318,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Wigan",
    "capital": "",
    "population": 103608,
    "id": 1826902223
  },
  {
    "city": "Tourcoing",
    "city_ascii": "Tourcoing",
    "lat": 50.7239,
    "lng": 3.1612,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Hauts-de-France",
    "capital": "",
    "population": 97368,
    "id": 1250711716
  },
  {
    "city": "Osijek",
    "city_ascii": "Osijek",
    "lat": 45.5603,
    "lng": 18.6703,
    "country": "Croatia",
    "iso2": "HR",
    "iso3": "HRV",
    "admin_name": "Osječko-Baranjska Županija",
    "capital": "admin",
    "population": 103162,
    "id": 1191917921
  },
  {
    "city": "Ariana",
    "city_ascii": "Ariana",
    "lat": 36.8667,
    "lng": 10.2,
    "country": "Tunisia",
    "iso2": "TN",
    "iso3": "TUN",
    "admin_name": "L’Ariana",
    "capital": "admin",
    "population": 97687,
    "id": 1788682546
  },
  {
    "city": "Siegen",
    "city_ascii": "Siegen",
    "lat": 50.8756,
    "lng": 8.0167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "minor",
    "population": 102836,
    "id": 1276268768
  },
  {
    "city": "Gardēz",
    "city_ascii": "Gardez",
    "lat": 33.5931,
    "lng": 69.2297,
    "country": "Afghanistan",
    "iso2": "AF",
    "iso3": "AFG",
    "admin_name": "Paktiyā",
    "capital": "admin",
    "population": 103601,
    "id": 1004468894
  },
  {
    "city": "Biak",
    "city_ascii": "Biak",
    "lat": -1.1615,
    "lng": 136.0485,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Papua",
    "capital": "minor",
    "population": 103610,
    "id": 1360911386
  },
  {
    "city": "Columbia",
    "city_ascii": "Columbia",
    "lat": 39.2004,
    "lng": -76.859,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 103663,
    "id": 1840005803
  },
  {
    "city": "Elista",
    "city_ascii": "Elista",
    "lat": 46.3167,
    "lng": 44.2667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kalmykiya",
    "capital": "admin",
    "population": 103132,
    "id": 1643581064
  },
  {
    "city": "Libertad",
    "city_ascii": "Libertad",
    "lat": -34.6833,
    "lng": -58.6833,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires",
    "capital": "",
    "population": 100324,
    "id": 1032241314
  },
  {
    "city": "Rialto",
    "city_ascii": "Rialto",
    "lat": 34.1174,
    "lng": -117.3894,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 103526,
    "id": 1840020407
  },
  {
    "city": "Thika",
    "city_ascii": "Thika",
    "lat": -1.0396,
    "lng": 37.09,
    "country": "Kenya",
    "iso2": "KE",
    "iso3": "KEN",
    "admin_name": "Kiambu",
    "capital": "",
    "population": 99322,
    "id": 1404482650
  },
  {
    "city": "Manteca",
    "city_ascii": "Manteca",
    "lat": 37.7927,
    "lng": -121.2264,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 103420,
    "id": 1840020265
  },
  {
    "city": "Araxá",
    "city_ascii": "Araxa",
    "lat": -19.5928,
    "lng": -46.9408,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Minas Gerais",
    "capital": "",
    "population": 103287,
    "id": 1076259402
  },
  {
    "city": "Bumba",
    "city_ascii": "Bumba",
    "lat": 2.19,
    "lng": 22.46,
    "country": "Congo (Kinshasa)",
    "iso2": "CD",
    "iso3": "COD",
    "admin_name": "Mongala",
    "capital": "",
    "population": 103328,
    "id": 1180549980
  },
  {
    "city": "Bayugan",
    "city_ascii": "Bayugan",
    "lat": 8.7143,
    "lng": 125.7481,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Agusan del Sur",
    "capital": "",
    "population": 103202,
    "id": 1608483872
  },
  {
    "city": "Burzaco",
    "city_ascii": "Burzaco",
    "lat": -34.8167,
    "lng": -58.3667,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires",
    "capital": "",
    "population": 98859,
    "id": 1032073218
  },
  {
    "city": "Talara",
    "city_ascii": "Talara",
    "lat": -4.5833,
    "lng": -81.2667,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "Piura",
    "capital": "",
    "population": 103200,
    "id": 1604790735
  },
  {
    "city": "Novokuybyshevsk",
    "city_ascii": "Novokuybyshevsk",
    "lat": 53.1,
    "lng": 49.9167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Samarskaya Oblast’",
    "capital": "",
    "population": 102075,
    "id": 1643467643
  },
  {
    "city": "Bergama",
    "city_ascii": "Bergama",
    "lat": 39.1228,
    "lng": 27.1783,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "İzmir",
    "capital": "minor",
    "population": 103185,
    "id": 1792434888
  },
  {
    "city": "Miryang",
    "city_ascii": "Miryang",
    "lat": 35.4933,
    "lng": 128.7489,
    "country": "Korea, South",
    "iso2": "KR",
    "iso3": "KOR",
    "admin_name": "Gyeongnam",
    "capital": "",
    "population": 103069,
    "id": 1410001039
  },
  {
    "city": "Nyeri",
    "city_ascii": "Nyeri",
    "lat": -0.4167,
    "lng": 36.95,
    "country": "Kenya",
    "iso2": "KE",
    "iso3": "KEN",
    "admin_name": "Nyeri",
    "capital": "admin",
    "population": 98908,
    "id": 1404924901
  },
  {
    "city": "Satu Mare",
    "city_ascii": "Satu Mare",
    "lat": 47.79,
    "lng": 22.89,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Satu Mare",
    "capital": "admin",
    "population": 102411,
    "id": 1642046798
  },
  {
    "city": "Houzhuang",
    "city_ascii": "Houzhuang",
    "lat": 35.62,
    "lng": 111.21,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Shanxi",
    "capital": "",
    "population": 102400,
    "id": 1156080869
  },
  {
    "city": "Hildesheim",
    "city_ascii": "Hildesheim",
    "lat": 52.15,
    "lng": 9.95,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "minor",
    "population": 101990,
    "id": 1276244190
  },
  {
    "city": "Ginowan",
    "city_ascii": "Ginowan",
    "lat": 26.2817,
    "lng": 127.7783,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Okinawa",
    "capital": "",
    "population": 98093,
    "id": 1392613742
  },
  {
    "city": "Sangju",
    "city_ascii": "Sangju",
    "lat": 36.4153,
    "lng": 128.1606,
    "country": "Korea, South",
    "iso2": "KR",
    "iso3": "KOR",
    "admin_name": "Gyeongbuk",
    "capital": "",
    "population": 102892,
    "id": 1410127055
  },
  {
    "city": "Kırklareli",
    "city_ascii": "Kirklareli",
    "lat": 41.7347,
    "lng": 27.2253,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Kırklareli",
    "capital": "admin",
    "population": 102909,
    "id": 1792182918
  },
  {
    "city": "Hove",
    "city_ascii": "Hove",
    "lat": 50.8352,
    "lng": -0.1758,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Brighton and Hove",
    "capital": "",
    "population": 91900,
    "id": 1826419818
  },
  {
    "city": "Legnica",
    "city_ascii": "Legnica",
    "lat": 51.2101,
    "lng": 16.1619,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Dolnośląskie",
    "capital": "minor",
    "population": 101133,
    "id": 1616578356
  },
  {
    "city": "Nanterre",
    "city_ascii": "Nanterre",
    "lat": 48.8988,
    "lng": 2.1969,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "minor",
    "population": 95105,
    "id": 1250886409
  },
  {
    "city": "Bhadreswar",
    "city_ascii": "Bhadreswar",
    "lat": 22.82,
    "lng": 88.35,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "West Bengal",
    "capital": "",
    "population": 101477,
    "id": 1356320019
  },
  {
    "city": "Noginsk",
    "city_ascii": "Noginsk",
    "lat": 55.85,
    "lng": 38.4333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Moskovskaya Oblast’",
    "capital": "",
    "population": 102592,
    "id": 1643001706
  },
  {
    "city": "Níkaia",
    "city_ascii": "Nikaia",
    "lat": 37.9667,
    "lng": 23.6333,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Attikí",
    "capital": "minor",
    "population": 89380,
    "id": 1300155901
  },
  {
    "city": "Lida",
    "city_ascii": "Lida",
    "lat": 53.8872,
    "lng": 25.3028,
    "country": "Belarus",
    "iso2": "BY",
    "iso3": "BLR",
    "admin_name": "Hrodzyenskaya Voblasts’",
    "capital": "",
    "population": 102700,
    "id": 1112110662
  },
  {
    "city": "Saint Helens",
    "city_ascii": "Saint Helens",
    "lat": 53.4541,
    "lng": -2.7461,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "St. Helens",
    "capital": "",
    "population": 102629,
    "id": 1826775771
  },
  {
    "city": "Talisay",
    "city_ascii": "Talisay",
    "lat": 10.7333,
    "lng": 122.9667,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Negros Occidental",
    "capital": "",
    "population": 102214,
    "id": 1608509888
  },
  {
    "city": "El Cajon",
    "city_ascii": "El Cajon",
    "lat": 32.8017,
    "lng": -116.9605,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 102708,
    "id": 1840020618
  },
  {
    "city": "Bethal",
    "city_ascii": "Bethal",
    "lat": -26.45,
    "lng": 29.45,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "Mpumalanga",
    "capital": "",
    "population": 101919,
    "id": 1710328253
  },
  {
    "city": "Mumias",
    "city_ascii": "Mumias",
    "lat": 0.3333,
    "lng": 34.4833,
    "country": "Kenya",
    "iso2": "KE",
    "iso3": "KEN",
    "admin_name": "Kakamega",
    "capital": "",
    "population": 99987,
    "id": 1404232773
  },
  {
    "city": "Burbank",
    "city_ascii": "Burbank",
    "lat": 34.1879,
    "lng": -118.3235,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 102511,
    "id": 1840019233
  },
  {
    "city": "Longmont",
    "city_ascii": "Longmont",
    "lat": 40.1686,
    "lng": -105.1005,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 102474,
    "id": 1840020178
  },
  {
    "city": "Yunfu",
    "city_ascii": "Yunfu",
    "lat": 28.6331,
    "lng": 104.4181,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Yunnan",
    "capital": "",
    "population": 102143,
    "id": 1156353293
  },
  {
    "city": "Mayarí",
    "city_ascii": "Mayari",
    "lat": 20.6592,
    "lng": -75.6781,
    "country": "Cuba",
    "iso2": "CU",
    "iso3": "CUB",
    "admin_name": "Holguín",
    "capital": "minor",
    "population": 102354,
    "id": 1192527369
  },
  {
    "city": "Mositai",
    "city_ascii": "Mositai",
    "lat": 45.5266,
    "lng": 119.6506,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Inner Mongolia",
    "capital": "minor",
    "population": 102214,
    "id": 1156252271
  },
  {
    "city": "Dhangaḍhi̇̄",
    "city_ascii": "Dhangadhi",
    "lat": 28.6833,
    "lng": 80.6,
    "country": "Nepal",
    "iso2": "NP",
    "iso3": "NPL",
    "admin_name": "Setī",
    "capital": "",
    "population": 101970,
    "id": 1524561456
  },
  {
    "city": "Kāshmar",
    "city_ascii": "Kashmar",
    "lat": 35.2383,
    "lng": 58.4656,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Khorāsān-e Raẕavī",
    "capital": "minor",
    "population": 102282,
    "id": 1364144807
  },
  {
    "city": "San Felipe del Progreso",
    "city_ascii": "San Felipe del Progreso",
    "lat": 19.7125,
    "lng": -99.9511,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "México",
    "capital": "minor",
    "population": 100201,
    "id": 1484781559
  },
  {
    "city": "Klagenfurt",
    "city_ascii": "Klagenfurt",
    "lat": 46.6167,
    "lng": 14.3,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Kärnten",
    "capital": "admin",
    "population": 101403,
    "id": 1040149651
  },
  {
    "city": "Delta",
    "city_ascii": "Delta",
    "lat": 49.0847,
    "lng": -123.0586,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "British Columbia",
    "capital": "",
    "population": 102238,
    "id": 1124001200
  },
  {
    "city": "Chilakalūrupet",
    "city_ascii": "Chilakalurupet",
    "lat": 16.0892,
    "lng": 80.1672,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Andhra Pradesh",
    "capital": "",
    "population": 101398,
    "id": 1356065865
  },
  {
    "city": "Coatepec",
    "city_ascii": "Coatepec",
    "lat": 19.4553,
    "lng": -96.9586,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Veracruz",
    "capital": "minor",
    "population": 92127,
    "id": 1484934939
  },
  {
    "city": "Şabrātah",
    "city_ascii": "Sabratah",
    "lat": 32.7922,
    "lng": 12.4842,
    "country": "Libya",
    "iso2": "LY",
    "iso3": "LBY",
    "admin_name": "An Nuqāţ al Khams",
    "capital": "",
    "population": 102038,
    "id": 1434908655
  },
  {
    "city": "Trindade",
    "city_ascii": "Trindade",
    "lat": -16.65,
    "lng": -49.5,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Goiás",
    "capital": "",
    "population": 97521,
    "id": 1076815881
  },
  {
    "city": "Boryeong",
    "city_ascii": "Boryeong",
    "lat": 36.3333,
    "lng": 126.6167,
    "country": "Korea, South",
    "iso2": "KR",
    "iso3": "KOR",
    "admin_name": "Chungnam",
    "capital": "minor",
    "population": 101852,
    "id": 1410204364
  },
  {
    "city": "Shūshtar",
    "city_ascii": "Shushtar",
    "lat": 32.0436,
    "lng": 48.8569,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Khūzestān",
    "capital": "minor",
    "population": 101878,
    "id": 1364856875
  },
  {
    "city": "Santo Antônio de Jesus",
    "city_ascii": "Santo Antonio de Jesus",
    "lat": -12.9692,
    "lng": -39.2611,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Bahia",
    "capital": "",
    "population": 101548,
    "id": 1076231231
  },
  {
    "city": "Teluk Intan",
    "city_ascii": "Teluk Intan",
    "lat": 4.0333,
    "lng": 101.0167,
    "country": "Malaysia",
    "iso2": "MY",
    "iso3": "MYS",
    "admin_name": "Perak",
    "capital": "",
    "population": 101659,
    "id": 1458126062
  },
  {
    "city": "Hemel Hempstead",
    "city_ascii": "Hemel Hempstead",
    "lat": 51.7526,
    "lng": -0.4692,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hertfordshire",
    "capital": "",
    "population": 97500,
    "id": 1826374836
  },
  {
    "city": "Assis",
    "city_ascii": "Assis",
    "lat": -22.6619,
    "lng": -50.4119,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 101597,
    "id": 1076782502
  },
  {
    "city": "Berisso",
    "city_ascii": "Berisso",
    "lat": -34.8667,
    "lng": -57.8667,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires",
    "capital": "minor",
    "population": 101123,
    "id": 1032298172
  },
  {
    "city": "Renton",
    "city_ascii": "Renton",
    "lat": 47.4784,
    "lng": -122.1919,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 101751,
    "id": 1840019827
  },
  {
    "city": "Inzai",
    "city_ascii": "Inzai",
    "lat": 35.8322,
    "lng": 140.1458,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Chiba",
    "capital": "",
    "population": 100907,
    "id": 1392272573
  },
  {
    "city": "Duitama",
    "city_ascii": "Duitama",
    "lat": 5.8305,
    "lng": -73.02,
    "country": "Colombia",
    "iso2": "CO",
    "iso3": "COL",
    "admin_name": "Boyacá",
    "capital": "minor",
    "population": 101156,
    "id": 1170695532
  },
  {
    "city": "Chatham",
    "city_ascii": "Chatham",
    "lat": 42.4229,
    "lng": -82.1324,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 101647,
    "id": 1124393373
  },
  {
    "city": "‘Ibrī",
    "city_ascii": "`Ibri",
    "lat": 23.2254,
    "lng": 56.517,
    "country": "Oman",
    "iso2": "OM",
    "iso3": "OMN",
    "admin_name": "Ad Dākhilīyah",
    "capital": "admin",
    "population": 101640,
    "id": 1512077267
  },
  {
    "city": "Vista",
    "city_ascii": "Vista",
    "lat": 33.1896,
    "lng": -117.2386,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 101638,
    "id": 1840021994
  },
  {
    "city": "Olomouc",
    "city_ascii": "Olomouc",
    "lat": 49.5939,
    "lng": 17.2508,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Olomoucký Kraj",
    "capital": "admin",
    "population": 100663,
    "id": 1203328061
  },
  {
    "city": "Zheleznogorsk",
    "city_ascii": "Zheleznogorsk",
    "lat": 52.3333,
    "lng": 35.3667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kurskaya Oblast’",
    "capital": "",
    "population": 100740,
    "id": 1643006066
  },
  {
    "city": "Logan",
    "city_ascii": "Logan",
    "lat": 41.74,
    "lng": -111.8419,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Utah",
    "capital": "",
    "population": 101616,
    "id": 1840020108
  },
  {
    "city": "Patos",
    "city_ascii": "Patos",
    "lat": -7.0196,
    "lng": -37.29,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Paraíba",
    "capital": "",
    "population": 92575,
    "id": 1076232522
  },
  {
    "city": "Sungai Penuh",
    "city_ascii": "Sungai Penuh",
    "lat": -2.0636,
    "lng": 101.3961,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Jambi",
    "capital": "",
    "population": 101325,
    "id": 1360337679
  },
  {
    "city": "Vitry-sur-Seine",
    "city_ascii": "Vitry-sur-Seine",
    "lat": 48.7875,
    "lng": 2.3928,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 93557,
    "id": 1250115605
  },
  {
    "city": "Tizayuca",
    "city_ascii": "Tizayuca",
    "lat": 19.8333,
    "lng": -98.9833,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Morelos",
    "capital": "minor",
    "population": 100563,
    "id": 1484244950
  },
  {
    "city": "Socopó",
    "city_ascii": "Socopo",
    "lat": 8.2322,
    "lng": -70.8206,
    "country": "Venezuela",
    "iso2": "VE",
    "iso3": "VEN",
    "admin_name": "Barinas",
    "capital": "minor",
    "population": 101512,
    "id": 1862619610
  },
  {
    "city": "Prescott Valley",
    "city_ascii": "Prescott Valley",
    "lat": 34.5983,
    "lng": -112.3176,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 101539,
    "id": 1840021772
  },
  {
    "city": "Ancona",
    "city_ascii": "Ancona",
    "lat": 43.6167,
    "lng": 13.5167,
    "country": "Italy",
    "iso2": "IT",
    "iso3": "ITA",
    "admin_name": "Marche",
    "capital": "admin",
    "population": 100721,
    "id": 1380597324
  },
  {
    "city": "Trinidad",
    "city_ascii": "Trinidad",
    "lat": -14.8333,
    "lng": -64.9,
    "country": "Bolivia",
    "iso2": "BO",
    "iso3": "BOL",
    "admin_name": "El Beni",
    "capital": "admin",
    "population": 101454,
    "id": 1068929509
  },
  {
    "city": "Salihorsk",
    "city_ascii": "Salihorsk",
    "lat": 52.7842,
    "lng": 27.5425,
    "country": "Belarus",
    "iso2": "BY",
    "iso3": "BLR",
    "admin_name": "Minskaya Voblasts’",
    "capital": "minor",
    "population": 101400,
    "id": 1112250470
  },
  {
    "city": "Vacaville",
    "city_ascii": "Vacaville",
    "lat": 38.359,
    "lng": -121.968,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 101444,
    "id": 1840021498
  },
  {
    "city": "San Luis de la Paz",
    "city_ascii": "San Luis de la Paz",
    "lat": 21.3,
    "lng": -100.5167,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Guanajuato",
    "capital": "minor",
    "population": 101370,
    "id": 1484137795
  },
  {
    "city": "Wakefield",
    "city_ascii": "Wakefield",
    "lat": 53.68,
    "lng": -1.49,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Wakefield",
    "capital": "",
    "population": 99251,
    "id": 1826013112
  },
  {
    "city": "Chikusei",
    "city_ascii": "Chikusei",
    "lat": 36.3072,
    "lng": 139.9831,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Ibaraki",
    "capital": "",
    "population": 100816,
    "id": 1392759497
  },
  {
    "city": "Oued Zem",
    "city_ascii": "Oued Zem",
    "lat": 32.86,
    "lng": -6.56,
    "country": "Morocco",
    "iso2": "MA",
    "iso3": "MAR",
    "admin_name": "Béni Mellal-Khénifra",
    "capital": "",
    "population": 95267,
    "id": 1504098746
  },
  {
    "city": "Kani",
    "city_ascii": "Kani",
    "lat": 35.4258,
    "lng": 137.0611,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Gifu",
    "capital": "",
    "population": 100031,
    "id": 1392198191
  },
  {
    "city": "Edinburg",
    "city_ascii": "Edinburg",
    "lat": 26.3197,
    "lng": -98.1596,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 101170,
    "id": 1840021020
  },
  {
    "city": "Kōnan",
    "city_ascii": "Konan",
    "lat": 35.3333,
    "lng": 136.8667,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Aichi",
    "capital": "",
    "population": 97893,
    "id": 1392978695
  },
  {
    "city": "Mishan",
    "city_ascii": "Mishan",
    "lat": 45.5504,
    "lng": 131.88,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Heilongjiang",
    "capital": "",
    "population": 87257,
    "id": 1156267144
  },
  {
    "city": "Gütersloh",
    "city_ascii": "Gutersloh",
    "lat": 51.9,
    "lng": 8.3833,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "minor",
    "population": 100194,
    "id": 1276587494
  },
  {
    "city": "Sakata",
    "city_ascii": "Sakata",
    "lat": 38.9144,
    "lng": 139.8364,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Yamagata",
    "capital": "",
    "population": 100916,
    "id": 1392418961
  },
  {
    "city": "Carmel",
    "city_ascii": "Carmel",
    "lat": 39.965,
    "lng": -86.146,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 101068,
    "id": 1840007260
  },
  {
    "city": "Spokane Valley",
    "city_ascii": "Spokane Valley",
    "lat": 47.6626,
    "lng": -117.2346,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 101060,
    "id": 1840021094
  },
  {
    "city": "Oum el Bouaghi",
    "city_ascii": "Oum el Bouaghi",
    "lat": 35.8706,
    "lng": 7.115,
    "country": "Algeria",
    "iso2": "DZ",
    "iso3": "DZA",
    "admin_name": "Oum el Bouaghi",
    "capital": "admin",
    "population": 100821,
    "id": 1012958880
  },
  {
    "city": "Watford",
    "city_ascii": "Watford",
    "lat": 51.655,
    "lng": -0.3957,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hertfordshire",
    "capital": "",
    "population": 96800,
    "id": 1826570072
  },
  {
    "city": "Jilotepec",
    "city_ascii": "Jilotepec",
    "lat": 19.9519,
    "lng": -99.5328,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "México",
    "capital": "minor",
    "population": 100808,
    "id": 1484789785
  },
  {
    "city": "Navapolatsk",
    "city_ascii": "Navapolatsk",
    "lat": 55.5333,
    "lng": 28.6667,
    "country": "Belarus",
    "iso2": "BY",
    "iso3": "BLR",
    "admin_name": "Vitsyebskaya Voblasts’",
    "capital": "",
    "population": 98800,
    "id": 1112965860
  },
  {
    "city": "Cottbus",
    "city_ascii": "Cottbus",
    "lat": 51.7606,
    "lng": 14.3342,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Brandenburg",
    "capital": "minor",
    "population": 100219,
    "id": 1276436655
  },
  {
    "city": "Edison",
    "city_ascii": "Edison",
    "lat": 40.536,
    "lng": -74.3697,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 100809,
    "id": 1840081696
  },
  {
    "city": "Kpalimé",
    "city_ascii": "Kpalime",
    "lat": 6.91,
    "lng": 0.6281,
    "country": "Togo",
    "iso2": "TG",
    "iso3": "TGO",
    "admin_name": "Plateaux",
    "capital": "",
    "population": 100479,
    "id": 1768253626
  },
  {
    "city": "San Angelo",
    "city_ascii": "San Angelo",
    "lat": 31.4424,
    "lng": -100.4506,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 100773,
    "id": 1840022146
  },
  {
    "city": "Lakewood",
    "city_ascii": "Lakewood",
    "lat": 40.0763,
    "lng": -74.2031,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 100763,
    "id": 1840081607
  },
  {
    "city": "Kaiserslautern",
    "city_ascii": "Kaiserslautern",
    "lat": 49.4447,
    "lng": 7.7689,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Rhineland-Palatinate",
    "capital": "minor",
    "population": 100030,
    "id": 1276674534
  },
  {
    "city": "Gangtok",
    "city_ascii": "Gangtok",
    "lat": 27.33,
    "lng": 88.62,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Sikkim",
    "capital": "admin",
    "population": 100286,
    "id": 1356689564
  },
  {
    "city": "La Crosse",
    "city_ascii": "La Crosse",
    "lat": 43.824,
    "lng": -91.2268,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 100693,
    "id": 1840002577
  },
  {
    "city": "Ahar",
    "city_ascii": "Ahar",
    "lat": 38.475,
    "lng": 47.0603,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Āz̄arbāyjān-e Sharqī",
    "capital": "minor",
    "population": 100641,
    "id": 1364602437
  },
  {
    "city": "Diourbel",
    "city_ascii": "Diourbel",
    "lat": 14.655,
    "lng": -16.2314,
    "country": "Senegal",
    "iso2": "SN",
    "iso3": "SEN",
    "admin_name": "Diourbel",
    "capital": "admin",
    "population": 100445,
    "id": 1686087214
  },
  {
    "city": "Andria",
    "city_ascii": "Andria",
    "lat": 41.2317,
    "lng": 16.3083,
    "country": "Italy",
    "iso2": "IT",
    "iso3": "ITA",
    "admin_name": "Puglia",
    "capital": "minor",
    "population": 100365,
    "id": 1380463498
  },
  {
    "city": "Tsuyama",
    "city_ascii": "Tsuyama",
    "lat": 35.1406,
    "lng": 134.0319,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Okayama",
    "capital": "",
    "population": 100387,
    "id": 1392820005
  },
  {
    "city": "Arden-Arcade",
    "city_ascii": "Arden-Arcade",
    "lat": 38.6034,
    "lng": -121.381,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 100548,
    "id": 1840036690
  },
  {
    "city": "Masjed Soleymān",
    "city_ascii": "Masjed Soleyman",
    "lat": 31.9333,
    "lng": 49.3,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Khūzestān",
    "capital": "minor",
    "population": 100497,
    "id": 1364152430
  },
  {
    "city": "Mairiporã",
    "city_ascii": "Mairipora",
    "lat": -23.3189,
    "lng": -46.5869,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 100179,
    "id": 1076161359
  },
  {
    "city": "Torbat-e Jām",
    "city_ascii": "Torbat-e Jam",
    "lat": 35.2439,
    "lng": 60.6225,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Khorāsān-e Raẕavī",
    "capital": "minor",
    "population": 100449,
    "id": 1364064948
  },
  {
    "city": "Magangué",
    "city_ascii": "Magangue",
    "lat": 9.23,
    "lng": -74.74,
    "country": "Colombia",
    "iso2": "CO",
    "iso3": "COL",
    "admin_name": "Bolívar",
    "capital": "minor",
    "population": 100313,
    "id": 1170903510
  },
  {
    "city": "Kam”yanets’-Podil’s’kyy",
    "city_ascii": "Kam'yanets'-Podil's'kyy",
    "lat": 48.6806,
    "lng": 26.5806,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Khmel’nyts’ka Oblast’",
    "capital": "minor",
    "population": 100462,
    "id": 1804779341
  },
  {
    "city": "Idaho Falls",
    "city_ascii": "Idaho Falls",
    "lat": 43.4872,
    "lng": -112.0362,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Idaho",
    "capital": "",
    "population": 100442,
    "id": 1840018644
  },
  {
    "city": "Holland",
    "city_ascii": "Holland",
    "lat": 42.7677,
    "lng": -86.0985,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 100432,
    "id": 1840002996
  },
  {
    "city": "Red Deer",
    "city_ascii": "Red Deer",
    "lat": 52.2681,
    "lng": -113.8111,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Alberta",
    "capital": "",
    "population": 100418,
    "id": 1124404130
  },
  {
    "city": "Charlottesville",
    "city_ascii": "Charlottesville",
    "lat": 38.0375,
    "lng": -78.4855,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 100416,
    "id": 1840001678
  },
  {
    "city": "Drohobych",
    "city_ascii": "Drohobych",
    "lat": 49.35,
    "lng": 23.5,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "L’vivs’ka Oblast’",
    "capital": "minor",
    "population": 98015,
    "id": 1804782563
  },
  {
    "city": "Shahrisabz",
    "city_ascii": "Shahrisabz",
    "lat": 39.05,
    "lng": 66.8333,
    "country": "Uzbekistan",
    "iso2": "UZ",
    "iso3": "UZB",
    "admin_name": "Qashqadaryo",
    "capital": "",
    "population": 100300,
    "id": 1860513278
  },
  {
    "city": "Lincoln",
    "city_ascii": "Lincoln",
    "lat": 53.2344,
    "lng": -0.5386,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Lincolnshire",
    "capital": "",
    "population": 97541,
    "id": 1826312785
  },
  {
    "city": "Longview",
    "city_ascii": "Longview",
    "lat": 32.5193,
    "lng": -94.7621,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 100330,
    "id": 1840020746
  },
  {
    "city": "Woodbridge",
    "city_ascii": "Woodbridge",
    "lat": 40.5611,
    "lng": -74.2943,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 100187,
    "id": 1840081695
  },
  {
    "city": "Tracy",
    "city_ascii": "Tracy",
    "lat": 37.7269,
    "lng": -121.4522,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 100049,
    "id": 1840021518
  },
  {
    "city": "Kamloops",
    "city_ascii": "Kamloops",
    "lat": 50.6761,
    "lng": -120.3408,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "British Columbia",
    "capital": "",
    "population": 100046,
    "id": 1124735582
  },
  {
    "city": "Bento Gonçalves",
    "city_ascii": "Bento Goncalves",
    "lat": -29.1695,
    "lng": -51.52,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Rio Grande do Sul",
    "capital": "",
    "population": 94271,
    "id": 1076492877
  },
  {
    "city": "Râmnicu Vâlcea",
    "city_ascii": "Ramnicu Valcea",
    "lat": 45.1047,
    "lng": 24.3756,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Vâlcea",
    "capital": "admin",
    "population": 98776,
    "id": 1642348361
  },
  {
    "city": "Erechim",
    "city_ascii": "Erechim",
    "lat": -27.63,
    "lng": -52.27,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Rio Grande do Sul",
    "capital": "",
    "population": 96087,
    "id": 1076359548
  },
  {
    "city": "Kettering",
    "city_ascii": "Kettering",
    "lat": 52.3931,
    "lng": -0.7229,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Northamptonshire",
    "capital": "",
    "population": 93475,
    "id": 1826968679
  },
  {
    "city": "Viseu",
    "city_ascii": "Viseu",
    "lat": 40.6667,
    "lng": -7.9167,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Viseu",
    "capital": "admin",
    "population": 99274,
    "id": 1620291266
  },
  {
    "city": "Kohīma",
    "city_ascii": "Kohima",
    "lat": 25.6667,
    "lng": 94.1194,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Nāgāland",
    "capital": "admin",
    "population": 99039,
    "id": 1356332206
  },
  {
    "city": "Mingəçevir",
    "city_ascii": "Mingacevir",
    "lat": 40.77,
    "lng": 47.0489,
    "country": "Azerbaijan",
    "iso2": "AZ",
    "iso3": "AZE",
    "admin_name": "Mingəçevir",
    "capital": "admin",
    "population": 98800,
    "id": 1031509213
  },
  {
    "city": "Janakpur",
    "city_ascii": "Janakpur",
    "lat": 26.7122,
    "lng": 85.9217,
    "country": "Nepal",
    "iso2": "NP",
    "iso3": "NPL",
    "admin_name": "Janakpur",
    "capital": "admin",
    "population": 97776,
    "id": 1524563587
  },
  {
    "city": "Chipata",
    "city_ascii": "Chipata",
    "lat": -13.6386,
    "lng": 32.6453,
    "country": "Zambia",
    "iso2": "ZM",
    "iso3": "ZMB",
    "admin_name": "Eastern",
    "capital": "admin",
    "population": 98416,
    "id": 1894816262
  },
  {
    "city": "Tultepec",
    "city_ascii": "Tultepec",
    "lat": 19.685,
    "lng": -99.1281,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "México",
    "capital": "minor",
    "population": 91808,
    "id": 1484818856
  },
  {
    "city": "Ciudad General Belgrano",
    "city_ascii": "Ciudad General Belgrano",
    "lat": -34.7261,
    "lng": -58.5289,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires",
    "capital": "",
    "population": 92957,
    "id": 1032336348
  },
  {
    "city": "Khanty-Mansiysk",
    "city_ascii": "Khanty-Mansiysk",
    "lat": 61,
    "lng": 69,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Khanty-Mansiyskiy Avtonomnyy Okrug-Yugra",
    "capital": "admin",
    "population": 98692,
    "id": 1643001221
  },
  {
    "city": "Créteil",
    "city_ascii": "Creteil",
    "lat": 48.7911,
    "lng": 2.4628,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "minor",
    "population": 90605,
    "id": 1250710438
  },
  {
    "city": "Bismarck",
    "city_ascii": "Bismarck",
    "lat": 46.8144,
    "lng": -100.7694,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Dakota",
    "capital": "admin",
    "population": 98345,
    "id": 1840001926
  },
  {
    "city": "Herẕliyya",
    "city_ascii": "Herzliyya",
    "lat": 32.1556,
    "lng": 34.8422,
    "country": "Israel",
    "iso2": "IL",
    "iso3": "ISR",
    "admin_name": "Tel Aviv",
    "capital": "",
    "population": 93989,
    "id": 1376303805
  },
  {
    "city": "Yên Bái",
    "city_ascii": "Yen Bai",
    "lat": 21.7,
    "lng": 104.8667,
    "country": "Vietnam",
    "iso2": "VN",
    "iso3": "VNM",
    "admin_name": "Yên Bái",
    "capital": "admin",
    "population": 96540,
    "id": 1704249231
  },
  {
    "city": "Shumen",
    "city_ascii": "Shumen",
    "lat": 43.27,
    "lng": 26.9244,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Shumen",
    "capital": "admin",
    "population": 97719,
    "id": 1100000004
  },
  {
    "city": "Orem",
    "city_ascii": "Orem",
    "lat": 40.2983,
    "lng": -111.6992,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Utah",
    "capital": "",
    "population": 97828,
    "id": 1840020171
  },
  {
    "city": "Yashio",
    "city_ascii": "Yashio",
    "lat": 35.8228,
    "lng": 139.8392,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Saitama",
    "capital": "",
    "population": 92672,
    "id": 1392563124
  },
  {
    "city": "Half Way Tree",
    "city_ascii": "Half Way Tree",
    "lat": 18.0106,
    "lng": -76.7975,
    "country": "Jamaica",
    "iso2": "JM",
    "iso3": "JAM",
    "admin_name": "Saint Andrew",
    "capital": "admin",
    "population": 96494,
    "id": 1388715163
  },
  {
    "city": "Vị Thanh",
    "city_ascii": "Vi Thanh",
    "lat": 9.7833,
    "lng": 105.4708,
    "country": "Vietnam",
    "iso2": "VN",
    "iso3": "VNM",
    "admin_name": "Hậu Giang",
    "capital": "admin",
    "population": 97200,
    "id": 1704210026
  },
  {
    "city": "Tumbes",
    "city_ascii": "Tumbes",
    "lat": -3.5667,
    "lng": -80.45,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "Tumbes",
    "capital": "admin",
    "population": 96946,
    "id": 1604505281
  },
  {
    "city": "Malindi",
    "city_ascii": "Malindi",
    "lat": -3.21,
    "lng": 40.1,
    "country": "Kenya",
    "iso2": "KE",
    "iso3": "KEN",
    "admin_name": "Kilifi",
    "capital": "",
    "population": 94016,
    "id": 1404380224
  },
  {
    "city": "Székesfehérvár",
    "city_ascii": "Szekesfehervar",
    "lat": 47.2,
    "lng": 18.4167,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Fejér",
    "capital": "admin",
    "population": 96940,
    "id": 1348968301
  },
  {
    "city": "Xırdalan",
    "city_ascii": "Xirdalan",
    "lat": 40.4486,
    "lng": 49.7564,
    "country": "Azerbaijan",
    "iso2": "AZ",
    "iso3": "AZE",
    "admin_name": "Abşeron",
    "capital": "admin",
    "population": 95200,
    "id": 1031145251
  },
  {
    "city": "Tenancingo",
    "city_ascii": "Tenancingo",
    "lat": 18.9608,
    "lng": -99.5906,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "México",
    "capital": "minor",
    "population": 90946,
    "id": 1484006247
  },
  {
    "city": "Ponta Porã",
    "city_ascii": "Ponta Pora",
    "lat": -22.5361,
    "lng": -55.7256,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Mato Grosso do Sul",
    "capital": "",
    "population": 94331,
    "id": 1076647935
  },
  {
    "city": "Wangqing",
    "city_ascii": "Wangqing",
    "lat": 43.3248,
    "lng": 129.7343,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Jilin",
    "capital": "minor",
    "population": 88732,
    "id": 1156184335
  },
  {
    "city": "Sibolga",
    "city_ascii": "Sibolga",
    "lat": 1.7425,
    "lng": 98.7792,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Sumatera Utara",
    "capital": "",
    "population": 94971,
    "id": 1360021193
  },
  {
    "city": "Aubervilliers",
    "city_ascii": "Aubervilliers",
    "lat": 48.9131,
    "lng": 2.3831,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 86375,
    "id": 1250470371
  },
  {
    "city": "Balanga",
    "city_ascii": "Balanga",
    "lat": 14.6833,
    "lng": 120.5333,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Bataan",
    "capital": "admin",
    "population": 96061,
    "id": 1608467104
  },
  {
    "city": "Bunia",
    "city_ascii": "Bunia",
    "lat": 1.5604,
    "lng": 30.24,
    "country": "Congo (Kinshasa)",
    "iso2": "CD",
    "iso3": "COD",
    "admin_name": "Ituri",
    "capital": "admin",
    "population": 96764,
    "id": 1180006418
  },
  {
    "city": "Arauca",
    "city_ascii": "Arauca",
    "lat": 7.0903,
    "lng": -70.7617,
    "country": "Colombia",
    "iso2": "CO",
    "iso3": "COL",
    "admin_name": "Arauca",
    "capital": "admin",
    "population": 96814,
    "id": 1170322340
  },
  {
    "city": "Totonicapán",
    "city_ascii": "Totonicapan",
    "lat": 14.9108,
    "lng": -91.3606,
    "country": "Guatemala",
    "iso2": "GT",
    "iso3": "GTM",
    "admin_name": "Totonicapán",
    "capital": "admin",
    "population": 96392,
    "id": 1320223386
  },
  {
    "city": "Sidi Slimane",
    "city_ascii": "Sidi Slimane",
    "lat": 34.26,
    "lng": -5.93,
    "country": "Morocco",
    "iso2": "MA",
    "iso3": "MAR",
    "admin_name": "Rabat-Salé-Kénitra",
    "capital": "",
    "population": 92989,
    "id": 1504101128
  },
  {
    "city": "Pejë",
    "city_ascii": "Peje",
    "lat": 42.65,
    "lng": 20.3,
    "country": "Kosovo",
    "iso2": "XK",
    "iso3": "XKS",
    "admin_name": "Pejë",
    "capital": "admin",
    "population": 96450,
    "id": 1901339694
  },
  {
    "city": "Yishui",
    "city_ascii": "Yishui",
    "lat": 35.7904,
    "lng": 118.62,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Shandong",
    "capital": "",
    "population": 94115,
    "id": 1156736933
  },
  {
    "city": "Fulham",
    "city_ascii": "Fulham",
    "lat": 51.4828,
    "lng": -0.195,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hammersmith and Fulham",
    "capital": "",
    "population": 87161,
    "id": 1826877782
  },
  {
    "city": "Muriaé",
    "city_ascii": "Muriae",
    "lat": -21.13,
    "lng": -42.39,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Minas Gerais",
    "capital": "",
    "population": 91173,
    "id": 1076003733
  },
  {
    "city": "Schwerin",
    "city_ascii": "Schwerin",
    "lat": 53.6333,
    "lng": 11.4167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Mecklenburg-Western Pomerania",
    "capital": "admin",
    "population": 95818,
    "id": 1276221707
  },
  {
    "city": "San Francisco Solano",
    "city_ascii": "San Francisco Solano",
    "lat": -34.7667,
    "lng": -58.3167,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires",
    "capital": "",
    "population": 91322,
    "id": 1032776804
  },
  {
    "city": "Tartu",
    "city_ascii": "Tartu",
    "lat": 58.38,
    "lng": 26.7225,
    "country": "Estonia",
    "iso2": "EE",
    "iso3": "EST",
    "admin_name": "Tartumaa",
    "capital": "admin",
    "population": 93865,
    "id": 1233809410
  },
  {
    "city": "Ziftá",
    "city_ascii": "Zifta",
    "lat": 30.7119,
    "lng": 31.2394,
    "country": "Egypt",
    "iso2": "EG",
    "iso3": "EGY",
    "admin_name": "Al Gharbīyah",
    "capital": "",
    "population": 93740,
    "id": 1818759149
  },
  {
    "city": "České Budějovice",
    "city_ascii": "Ceske Budejovice",
    "lat": 48.9747,
    "lng": 14.4747,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Jihočeský Kraj",
    "capital": "admin",
    "population": 94463,
    "id": 1203398046
  },
  {
    "city": "Colombes",
    "city_ascii": "Colombes",
    "lat": 48.9236,
    "lng": 2.2522,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 85177,
    "id": 1250390247
  },
  {
    "city": "Çankırı",
    "city_ascii": "Cankiri",
    "lat": 40.6,
    "lng": 33.6167,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Çankırı",
    "capital": "admin",
    "population": 96025,
    "id": 1792584248
  },
  {
    "city": "East Ham",
    "city_ascii": "East Ham",
    "lat": 51.5323,
    "lng": 0.0554,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Newham",
    "capital": "",
    "population": 76186,
    "id": 1826587417
  },
  {
    "city": "Masbate",
    "city_ascii": "Masbate",
    "lat": 12.3667,
    "lng": 123.6167,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Masbate",
    "capital": "admin",
    "population": 95389,
    "id": 1608802236
  },
  {
    "city": "Kakamega",
    "city_ascii": "Kakamega",
    "lat": 0.2833,
    "lng": 34.75,
    "country": "Kenya",
    "iso2": "KE",
    "iso3": "KEN",
    "admin_name": "Kakamega",
    "capital": "admin",
    "population": 91778,
    "id": 1404614327
  },
  {
    "city": "Río Gallegos",
    "city_ascii": "Rio Gallegos",
    "lat": -51.6333,
    "lng": -69.2333,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Santa Cruz",
    "capital": "admin",
    "population": 95796,
    "id": 1032471024
  },
  {
    "city": "Ludwigsburg",
    "city_ascii": "Ludwigsburg",
    "lat": 48.8975,
    "lng": 9.1919,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "minor",
    "population": 93499,
    "id": 1276803720
  },
  {
    "city": "Compton",
    "city_ascii": "Compton",
    "lat": 33.893,
    "lng": -118.2275,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 95605,
    "id": 1840019240
  },
  {
    "city": "Errachidia",
    "city_ascii": "Errachidia",
    "lat": 31.9319,
    "lng": -4.4244,
    "country": "Morocco",
    "iso2": "MA",
    "iso3": "MAR",
    "admin_name": "Drâa-Tafilalet",
    "capital": "admin",
    "population": 92374,
    "id": 1504005759
  },
  {
    "city": "Esslingen",
    "city_ascii": "Esslingen",
    "lat": 48.7406,
    "lng": 9.3108,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "minor",
    "population": 93542,
    "id": 1276510955
  },
  {
    "city": "Al Bāḩah",
    "city_ascii": "Al Bahah",
    "lat": 20.0129,
    "lng": 41.4677,
    "country": "Saudi Arabia",
    "iso2": "SA",
    "iso3": "SAU",
    "admin_name": "Al Bāḩah",
    "capital": "admin",
    "population": 95089,
    "id": 1682121855
  },
  {
    "city": "Yotsukaidō",
    "city_ascii": "Yotsukaido",
    "lat": 35.67,
    "lng": 140.1683,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Chiba",
    "capital": "",
    "population": 92668,
    "id": 1392512633
  },
  {
    "city": "Sunrise",
    "city_ascii": "Sunrise",
    "lat": 26.1547,
    "lng": -80.2997,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 95166,
    "id": 1840016000
  },
  {
    "city": "Nouméa",
    "city_ascii": "Noumea",
    "lat": -22.2625,
    "lng": 166.4443,
    "country": "New Caledonia",
    "iso2": "NC",
    "iso3": "NCL",
    "admin_name": "Province Sud",
    "capital": "",
    "population": 93060,
    "id": 1540958092
  },
  {
    "city": "Barnsley",
    "city_ascii": "Barnsley",
    "lat": 53.5537,
    "lng": -1.4791,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Barnsley",
    "capital": "",
    "population": 91297,
    "id": 1826597621
  },
  {
    "city": "Hartlepool",
    "city_ascii": "Hartlepool",
    "lat": 54.69,
    "lng": -1.21,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hartlepool",
    "capital": "",
    "population": 92000,
    "id": 1826069842
  },
  {
    "city": "Ōbu",
    "city_ascii": "Obu",
    "lat": 35.0167,
    "lng": 136.9667,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Aichi",
    "capital": "",
    "population": 92101,
    "id": 1392870664
  },
  {
    "city": "Gjakovë",
    "city_ascii": "Gjakove",
    "lat": 42.3833,
    "lng": 20.4333,
    "country": "Kosovo",
    "iso2": "XK",
    "iso3": "XKS",
    "admin_name": "Gjakovë",
    "capital": "admin",
    "population": 94556,
    "id": 1901089874
  },
  {
    "city": "Mtwara",
    "city_ascii": "Mtwara",
    "lat": -10.2736,
    "lng": 40.1828,
    "country": "Tanzania",
    "iso2": "TZ",
    "iso3": "TZA",
    "admin_name": "Mtwara",
    "capital": "admin",
    "population": 92602,
    "id": 1834044142
  },
  {
    "city": "Ealing",
    "city_ascii": "Ealing",
    "lat": 51.5175,
    "lng": -0.2988,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Ealing",
    "capital": "",
    "population": 85014,
    "id": 1826730060
  },
  {
    "city": "Birkenhead",
    "city_ascii": "Birkenhead",
    "lat": 53.393,
    "lng": -3.014,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Wirral",
    "capital": "",
    "population": 88818,
    "id": 1826080662
  },
  {
    "city": "Guercif",
    "city_ascii": "Guercif",
    "lat": 34.23,
    "lng": -3.36,
    "country": "Morocco",
    "iso2": "MA",
    "iso3": "MAR",
    "admin_name": "Oriental",
    "capital": "",
    "population": 90880,
    "id": 1504842310
  },
  {
    "city": "Quincy",
    "city_ascii": "Quincy",
    "lat": 42.2516,
    "lng": -71.0183,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 94470,
    "id": 1840000461
  },
  {
    "city": "Lynn",
    "city_ascii": "Lynn",
    "lat": 42.4779,
    "lng": -70.9663,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 94299,
    "id": 1840000409
  },
  {
    "city": "Monte Chingolo",
    "city_ascii": "Monte Chingolo",
    "lat": -34.7333,
    "lng": -58.35,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires",
    "capital": "",
    "population": 85060,
    "id": 1032641177
  },
  {
    "city": "Drobeta-Turnu Severin",
    "city_ascii": "Drobeta-Turnu Severin",
    "lat": 44.6361,
    "lng": 22.6556,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Mehedinţi",
    "capital": "admin",
    "population": 92617,
    "id": 1642729144
  },
  {
    "city": "Nisshin",
    "city_ascii": "Nisshin",
    "lat": 35.1333,
    "lng": 137.0333,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Aichi",
    "capital": "",
    "population": 91514,
    "id": 1392999336
  },
  {
    "city": "Haskovo",
    "city_ascii": "Haskovo",
    "lat": 41.9311,
    "lng": 25.5575,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Haskovo",
    "capital": "admin",
    "population": 93821,
    "id": 1100186247
  },
  {
    "city": "Suceava",
    "city_ascii": "Suceava",
    "lat": 47.6514,
    "lng": 26.2556,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Suceava",
    "capital": "admin",
    "population": 92121,
    "id": 1642721443
  },
  {
    "city": "Hradec Králové",
    "city_ascii": "Hradec Kralove",
    "lat": 50.2092,
    "lng": 15.8319,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Královéhradecký Kraj",
    "capital": "admin",
    "population": 92939,
    "id": 1203430618
  },
  {
    "city": "Al Fujayrah",
    "city_ascii": "Al Fujayrah",
    "lat": 25.1308,
    "lng": 56.3347,
    "country": "United Arab Emirates",
    "iso2": "AE",
    "iso3": "ARE",
    "admin_name": "Al Fujayrah",
    "capital": "admin",
    "population": 93673,
    "id": 1784909196
  },
  {
    "city": "Ústí nad Labem",
    "city_ascii": "Usti nad Labem",
    "lat": 50.6592,
    "lng": 14.0417,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Ústecký Kraj",
    "capital": "admin",
    "population": 92716,
    "id": 1203734193
  },
  {
    "city": "Ciudad Lázaro Cárdenas",
    "city_ascii": "Ciudad Lazaro Cardenas",
    "lat": 17.9561,
    "lng": -102.1922,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Michoacán de Ocampo",
    "capital": "",
    "population": 79200,
    "id": 1484804575
  },
  {
    "city": "Şırnak",
    "city_ascii": "Sirnak",
    "lat": 37.5164,
    "lng": 42.4611,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Şırnak",
    "capital": "admin",
    "population": 93431,
    "id": 1792867633
  },
  {
    "city": "South Gate",
    "city_ascii": "South Gate",
    "lat": 33.9448,
    "lng": -118.1926,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 93444,
    "id": 1840021870
  },
  {
    "city": "Miaoli",
    "city_ascii": "Miaoli",
    "lat": 24.57,
    "lng": 120.82,
    "country": "Taiwan",
    "iso2": "TW",
    "iso3": "TWN",
    "admin_name": "Miaoli",
    "capital": "admin",
    "population": 90963,
    "id": 1158446494
  },
  {
    "city": "Makeni",
    "city_ascii": "Makeni",
    "lat": 8.8817,
    "lng": -12.0442,
    "country": "Sierra Leone",
    "iso2": "SL",
    "iso3": "SLE",
    "admin_name": "Northern",
    "capital": "admin",
    "population": 87679,
    "id": 1694028641
  },
  {
    "city": "Nuneaton",
    "city_ascii": "Nuneaton",
    "lat": 52.523,
    "lng": -1.468,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Warwickshire",
    "capital": "",
    "population": 86552,
    "id": 1826240370
  },
  {
    "city": "Itanhaém",
    "city_ascii": "Itanhaem",
    "lat": -24.1831,
    "lng": -46.7889,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 90385,
    "id": 1076274068
  },
  {
    "city": "Brixton",
    "city_ascii": "Brixton",
    "lat": 51.463,
    "lng": -0.106,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Lambeth",
    "capital": "",
    "population": 78536,
    "id": 1826058068
  },
  {
    "city": "Kirkland",
    "city_ascii": "Kirkland",
    "lat": 47.6974,
    "lng": -122.2054,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 93010,
    "id": 1840019836
  },
  {
    "city": "Levallois-Perret",
    "city_ascii": "Levallois-Perret",
    "lat": 48.895,
    "lng": 2.2872,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 64379,
    "id": 1250809881
  },
  {
    "city": "Brusque",
    "city_ascii": "Brusque",
    "lat": -27.13,
    "lng": -48.93,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Santa Catarina",
    "capital": "",
    "population": 88284,
    "id": 1076418225
  },
  {
    "city": "Santiago de Compostela",
    "city_ascii": "Santiago de Compostela",
    "lat": 42.8805,
    "lng": -8.5457,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Galicia",
    "capital": "admin",
    "population": 92430,
    "id": 1724547778
  },
  {
    "city": "Magadan",
    "city_ascii": "Magadan",
    "lat": 59.5667,
    "lng": 150.8,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Magadanskaya Oblast’",
    "capital": "admin",
    "population": 92782,
    "id": 1643216966
  },
  {
    "city": "Pardubice",
    "city_ascii": "Pardubice",
    "lat": 50.0386,
    "lng": 15.7792,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Pardubický Kraj",
    "capital": "admin",
    "population": 91727,
    "id": 1203857442
  },
  {
    "city": "Bender",
    "city_ascii": "Bender",
    "lat": 46.8333,
    "lng": 29.4833,
    "country": "Moldova",
    "iso2": "MD",
    "iso3": "MDA",
    "admin_name": "Bender",
    "capital": "admin",
    "population": 91882,
    "id": 1498257678
  },
  {
    "city": "Panevėžys",
    "city_ascii": "Panevezys",
    "lat": 55.725,
    "lng": 24.3639,
    "country": "Lithuania",
    "iso2": "LT",
    "iso3": "LTU",
    "admin_name": "Panevėžio Miestas",
    "capital": "admin",
    "population": 91054,
    "id": 1440120065
  },
  {
    "city": "Jaffna",
    "city_ascii": "Jaffna",
    "lat": 9.6647,
    "lng": 80.0167,
    "country": "Sri Lanka",
    "iso2": "LK",
    "iso3": "LKA",
    "admin_name": "Northern",
    "capital": "admin",
    "population": 88138,
    "id": 1144005506
  },
  {
    "city": "Bromley",
    "city_ascii": "Bromley",
    "lat": 51.407,
    "lng": 0.021,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Bromley",
    "capital": "",
    "population": 87889,
    "id": 1826385764
  },
  {
    "city": "Abaetetuba",
    "city_ascii": "Abaetetuba",
    "lat": -1.7245,
    "lng": -48.8849,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Pará",
    "capital": "",
    "population": 79420,
    "id": 1076974632
  },
  {
    "city": "Queluz",
    "city_ascii": "Queluz",
    "lat": 38.7566,
    "lng": -9.2545,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Lisboa",
    "capital": "",
    "population": 78273,
    "id": 1620896457
  },
  {
    "city": "Bath",
    "city_ascii": "Bath",
    "lat": 51.38,
    "lng": -2.36,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Bath and North East Somerset",
    "capital": "",
    "population": 88859,
    "id": 1826611282
  },
  {
    "city": "Santana",
    "city_ascii": "Santana",
    "lat": -0.0583,
    "lng": -51.1817,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Amapá",
    "capital": "",
    "population": 83927,
    "id": 1076974952
  },
  {
    "city": "‘Amrān",
    "city_ascii": "`Amran",
    "lat": 15.65,
    "lng": 43.9333,
    "country": "Yemen",
    "iso2": "YE",
    "iso3": "YEM",
    "admin_name": "‘Amrān",
    "capital": "admin",
    "population": 90792,
    "id": 1887433410
  },
  {
    "city": "Edmonton",
    "city_ascii": "Edmonton",
    "lat": 51.6154,
    "lng": -0.0708,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Enfield",
    "capital": "",
    "population": 82472,
    "id": 1826905865
  },
  {
    "city": "Lqoliaa",
    "city_ascii": "Lqoliaa",
    "lat": 30.2942,
    "lng": -9.4544,
    "country": "Morocco",
    "iso2": "MA",
    "iso3": "MAR",
    "admin_name": "Souss-Massa",
    "capital": "",
    "population": 83235,
    "id": 1504675410
  },
  {
    "city": "Hayes",
    "city_ascii": "Hayes",
    "lat": 51.5127,
    "lng": -0.4211,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hillingdon",
    "capital": "",
    "population": 83564,
    "id": 1826539158
  },
  {
    "city": "Lobnya",
    "city_ascii": "Lobnya",
    "lat": 56.0167,
    "lng": 37.4833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Moskovskaya Oblast’",
    "capital": "",
    "population": 88220,
    "id": 1643040713
  },
  {
    "city": "Francistown",
    "city_ascii": "Francistown",
    "lat": -21.1736,
    "lng": 27.5125,
    "country": "Botswana",
    "iso2": "BW",
    "iso3": "BWA",
    "admin_name": "Francistown",
    "capital": "admin",
    "population": 89979,
    "id": 1072124168
  },
  {
    "city": "Catanzaro",
    "city_ascii": "Catanzaro",
    "lat": 38.91,
    "lng": 16.5875,
    "country": "Italy",
    "iso2": "IT",
    "iso3": "ITA",
    "admin_name": "Calabria",
    "capital": "admin",
    "population": 90240,
    "id": 1380650072
  },
  {
    "city": "Aulnay-sous-Bois",
    "city_ascii": "Aulnay-sous-Bois",
    "lat": 48.9386,
    "lng": 2.4906,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 85740,
    "id": 1250420439
  },
  {
    "city": "Jönköping",
    "city_ascii": "Jonkoping",
    "lat": 57.7713,
    "lng": 14.165,
    "country": "Sweden",
    "iso2": "SE",
    "iso3": "SWE",
    "admin_name": "Jönköping",
    "capital": "admin",
    "population": 89780,
    "id": 1752079041
  },
  {
    "city": "Germantown",
    "city_ascii": "Germantown",
    "lat": 39.1755,
    "lng": -77.2643,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 90844,
    "id": 1840005840
  },
  {
    "city": "Babahoyo",
    "city_ascii": "Babahoyo",
    "lat": -1.8167,
    "lng": -79.5167,
    "country": "Ecuador",
    "iso2": "EC",
    "iso3": "ECU",
    "admin_name": "Los Ríos",
    "capital": "admin",
    "population": 90191,
    "id": 1218156392
  },
  {
    "city": "Takasagochō-takasemachi",
    "city_ascii": "Takasagocho-takasemachi",
    "lat": 34.7658,
    "lng": 134.7906,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hyōgo",
    "capital": "",
    "population": 88223,
    "id": 1392922621
  },
  {
    "city": "Glyfáda",
    "city_ascii": "Glyfada",
    "lat": 37.88,
    "lng": 23.7533,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Attikí",
    "capital": "minor",
    "population": 87305,
    "id": 1300003373
  },
  {
    "city": "Cadereyta Jiménez",
    "city_ascii": "Cadereyta Jimenez",
    "lat": 25.6,
    "lng": -100,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Nuevo León",
    "capital": "minor",
    "population": 86445,
    "id": 1484839099
  },
  {
    "city": "Westminster",
    "city_ascii": "Westminster",
    "lat": 33.7523,
    "lng": -117.9938,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 90643,
    "id": 1840021969
  },
  {
    "city": "Remedios de Escalada",
    "city_ascii": "Remedios de Escalada",
    "lat": -34.7167,
    "lng": -58.4,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires",
    "capital": "",
    "population": 81465,
    "id": 1032150292
  },
  {
    "city": "Vanadzor",
    "city_ascii": "Vanadzor",
    "lat": 40.8128,
    "lng": 44.4883,
    "country": "Armenia",
    "iso2": "AM",
    "iso3": "ARM",
    "admin_name": "Lorri",
    "capital": "admin",
    "population": 90525,
    "id": 1051691929
  },
  {
    "city": "Kitanagoya",
    "city_ascii": "Kitanagoya",
    "lat": 35.25,
    "lng": 136.8667,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Aichi",
    "capital": "",
    "population": 85899,
    "id": 1392360419
  },
  {
    "city": "Higashiyamato",
    "city_ascii": "Higashiyamato",
    "lat": 35.7456,
    "lng": 139.4267,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Tōkyō",
    "capital": "",
    "population": 84223,
    "id": 1392233405
  },
  {
    "city": "Ţarţūs",
    "city_ascii": "Tartus",
    "lat": 34.8833,
    "lng": 35.8833,
    "country": "Syria",
    "iso2": "SY",
    "iso3": "SYR",
    "admin_name": "Ţarţūs",
    "capital": "admin",
    "population": 89457,
    "id": 1760695473
  },
  {
    "city": "Valjevo",
    "city_ascii": "Valjevo",
    "lat": 44.2667,
    "lng": 19.8833,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Valjevo",
    "capital": "admin",
    "population": 90312,
    "id": 1688926375
  },
  {
    "city": "Santa Monica",
    "city_ascii": "Santa Monica",
    "lat": 34.0232,
    "lng": -118.4813,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 90401,
    "id": 1840021866
  },
  {
    "city": "Poitiers",
    "city_ascii": "Poitiers",
    "lat": 46.58,
    "lng": 0.34,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Nouvelle-Aquitaine",
    "capital": "minor",
    "population": 88291,
    "id": 1250812174
  },
  {
    "city": "Acayucan",
    "city_ascii": "Acayucan",
    "lat": 17.9422,
    "lng": -94.9103,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Veracruz",
    "capital": "minor",
    "population": 83817,
    "id": 1484349924
  },
  {
    "city": "Gjilan",
    "city_ascii": "Gjilan",
    "lat": 42.47,
    "lng": 21.47,
    "country": "Kosovo",
    "iso2": "XK",
    "iso3": "XKS",
    "admin_name": "Gjilan",
    "capital": "admin",
    "population": 90178,
    "id": 1901235642
  },
  {
    "city": "Jalal-Abad",
    "city_ascii": "Jalal-Abad",
    "lat": 40.9375,
    "lng": 72.9861,
    "country": "Kyrgyzstan",
    "iso2": "KG",
    "iso3": "KGZ",
    "admin_name": "Jalal-Abad",
    "capital": "admin",
    "population": 89004,
    "id": 1417653652
  },
  {
    "city": "Umeå",
    "city_ascii": "Umea",
    "lat": 63.8285,
    "lng": 20.2706,
    "country": "Sweden",
    "iso2": "SE",
    "iso3": "SWE",
    "admin_name": "Västerbotten",
    "capital": "admin",
    "population": 89607,
    "id": 1752273881
  },
  {
    "city": "Prešov",
    "city_ascii": "Presov",
    "lat": 49,
    "lng": 21.2333,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Prešovský",
    "capital": "admin",
    "population": 88680,
    "id": 1703231427
  },
  {
    "city": "Balkanabat",
    "city_ascii": "Balkanabat",
    "lat": 39.5119,
    "lng": 54.365,
    "country": "Turkmenistan",
    "iso2": "TM",
    "iso3": "TKM",
    "admin_name": "Balkan",
    "capital": "admin",
    "population": 89785,
    "id": 1795885709
  },
  {
    "city": "Mechelen",
    "city_ascii": "Mechelen",
    "lat": 51.0281,
    "lng": 4.4803,
    "country": "Belgium",
    "iso2": "BE",
    "iso3": "BEL",
    "admin_name": "Flanders",
    "capital": "minor",
    "population": 86921,
    "id": 1056687706
  },
  {
    "city": "Mukacheve",
    "city_ascii": "Mukacheve",
    "lat": 48.4414,
    "lng": 22.7136,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Zakarpats’ka Oblast’",
    "capital": "minor",
    "population": 86257,
    "id": 1804401780
  },
  {
    "city": "As Salţ",
    "city_ascii": "As Salt",
    "lat": 32.0333,
    "lng": 35.7333,
    "country": "Jordan",
    "iso2": "JO",
    "iso3": "JOR",
    "admin_name": "Al Balqā’",
    "capital": "admin",
    "population": 88900,
    "id": 1400470384
  },
  {
    "city": "Ciudad de Ceuta",
    "city_ascii": "Ciudad de Ceuta",
    "lat": 35.8867,
    "lng": -5.3,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Ceuta",
    "capital": "",
    "population": 84777,
    "id": 1724324416
  },
  {
    "city": "Versailles",
    "city_ascii": "Versailles",
    "lat": 48.8053,
    "lng": 2.135,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "minor",
    "population": 85862,
    "id": 1250143827
  },
  {
    "city": "Cáceres",
    "city_ascii": "Caceres",
    "lat": -16.05,
    "lng": -57.51,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Mato Grosso",
    "capital": "",
    "population": 85274,
    "id": 1076422654
  },
  {
    "city": "Vólos",
    "city_ascii": "Volos",
    "lat": 39.3611,
    "lng": 22.9425,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Thessalía",
    "capital": "minor",
    "population": 86046,
    "id": 1300109851
  },
  {
    "city": "Carrières-sur-Seine",
    "city_ascii": "Carrieres-sur-Seine",
    "lat": 48.9108,
    "lng": 2.2889,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 85191,
    "id": 1250595706
  },
  {
    "city": "Paulo Afonso",
    "city_ascii": "Paulo Afonso",
    "lat": -9.3307,
    "lng": -38.2657,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Bahia",
    "capital": "",
    "population": 85350,
    "id": 1076084498
  },
  {
    "city": "Viana do Castelo",
    "city_ascii": "Viana do Castelo",
    "lat": 41.7,
    "lng": -8.8333,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Viana do Castelo",
    "capital": "admin",
    "population": 88725,
    "id": 1620680440
  },
  {
    "city": "Woolwich",
    "city_ascii": "Woolwich",
    "lat": 51.488,
    "lng": 0.063,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Greenwich",
    "capital": "",
    "population": 84959,
    "id": 1826479020
  },
  {
    "city": "Miami Beach",
    "city_ascii": "Miami Beach",
    "lat": 25.8171,
    "lng": -80.1396,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 88885,
    "id": 1840015150
  },
  {
    "city": "San Leandro",
    "city_ascii": "San Leandro",
    "lat": 37.7071,
    "lng": -122.1601,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 88815,
    "id": 1840021538
  },
  {
    "city": "Bracknell",
    "city_ascii": "Bracknell",
    "lat": 51.416,
    "lng": -0.749,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Bracknell Forest",
    "capital": "",
    "population": 84469,
    "id": 1826880896
  },
  {
    "city": "Podujevë",
    "city_ascii": "Podujeve",
    "lat": 42.9167,
    "lng": 21.2,
    "country": "Kosovo",
    "iso2": "XK",
    "iso3": "XKS",
    "admin_name": "Podujevë",
    "capital": "admin",
    "population": 88499,
    "id": 1901550082
  },
  {
    "city": "Apizaco",
    "city_ascii": "Apizaco",
    "lat": 19.4167,
    "lng": -98.1333,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Tlaxcala",
    "capital": "minor",
    "population": 81565,
    "id": 1484021647
  },
  {
    "city": "Coronel Oviedo",
    "city_ascii": "Coronel Oviedo",
    "lat": -25.45,
    "lng": -56.44,
    "country": "Paraguay",
    "iso2": "PY",
    "iso3": "PRY",
    "admin_name": "Caaguazú",
    "capital": "admin",
    "population": 88101,
    "id": 1600075989
  },
  {
    "city": "Codó",
    "city_ascii": "Codo",
    "lat": -4.4796,
    "lng": -43.88,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Maranhão",
    "capital": "",
    "population": 83288,
    "id": 1076537068
  },
  {
    "city": "Fuentes del Valle",
    "city_ascii": "Fuentes del Valle",
    "lat": 19.6325,
    "lng": -99.1386,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "México",
    "capital": "",
    "population": 74087,
    "id": 1484020604
  },
  {
    "city": "Ébolowa",
    "city_ascii": "Ebolowa",
    "lat": 2.9,
    "lng": 11.15,
    "country": "Cameroon",
    "iso2": "CM",
    "iso3": "CMR",
    "admin_name": "Sud",
    "capital": "admin",
    "population": 87875,
    "id": 1120924207
  },
  {
    "city": "San Juan de los Morros",
    "city_ascii": "San Juan de los Morros",
    "lat": 9.901,
    "lng": -67.354,
    "country": "Venezuela",
    "iso2": "VE",
    "iso3": "VEN",
    "admin_name": "Guárico",
    "capital": "admin",
    "population": 87739,
    "id": 1862865294
  },
  {
    "city": "Citrus Heights",
    "city_ascii": "Citrus Heights",
    "lat": 38.6948,
    "lng": -121.288,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 87796,
    "id": 1840018845
  },
  {
    "city": "State College",
    "city_ascii": "State College",
    "lat": 40.7909,
    "lng": -77.8568,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 87723,
    "id": 1840000830
  },
  {
    "city": "Koudougou",
    "city_ascii": "Koudougou",
    "lat": 12.25,
    "lng": -2.3667,
    "country": "Burkina Faso",
    "iso2": "BF",
    "iso3": "BFA",
    "admin_name": "Centre-Ouest",
    "capital": "admin",
    "population": 87347,
    "id": 1854839075
  },
  {
    "city": "Dārayyā",
    "city_ascii": "Darayya",
    "lat": 33.4589,
    "lng": 36.2372,
    "country": "Syria",
    "iso2": "SY",
    "iso3": "SYR",
    "admin_name": "Rīf Dimashq",
    "capital": "minor",
    "population": 84044,
    "id": 1760409750
  },
  {
    "city": "Lokossa",
    "city_ascii": "Lokossa",
    "lat": 6.615,
    "lng": 1.715,
    "country": "Benin",
    "iso2": "BJ",
    "iso3": "BEN",
    "admin_name": "Mono",
    "capital": "admin",
    "population": 86971,
    "id": 1204036358
  },
  {
    "city": "Salina Cruz",
    "city_ascii": "Salina Cruz",
    "lat": 16.1671,
    "lng": -95.2,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Oaxaca",
    "capital": "",
    "population": 81063,
    "id": 1484364230
  },
  {
    "city": "Concepción",
    "city_ascii": "Concepcion",
    "lat": -23.4025,
    "lng": -57.4414,
    "country": "Paraguay",
    "iso2": "PY",
    "iso3": "PRY",
    "admin_name": "Concepción",
    "capital": "admin",
    "population": 87215,
    "id": 1600557615
  },
  {
    "city": "Jalpa de Méndez",
    "city_ascii": "Jalpa de Mendez",
    "lat": 18.1764,
    "lng": -93.0631,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Tabasco",
    "capital": "minor",
    "population": 83356,
    "id": 1484768624
  },
  {
    "city": "Mardin",
    "city_ascii": "Mardin",
    "lat": 37.3167,
    "lng": 40.7378,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Mardin",
    "capital": "admin",
    "population": 86948,
    "id": 1792546179
  },
  {
    "city": "Nakhon Sawan",
    "city_ascii": "Nakhon Sawan",
    "lat": 15.7133,
    "lng": 100.1353,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Nakhon Sawan",
    "capital": "admin",
    "population": 86439,
    "id": 1764821078
  },
  {
    "city": "Como",
    "city_ascii": "Como",
    "lat": 45.8103,
    "lng": 9.0861,
    "country": "Italy",
    "iso2": "IT",
    "iso3": "ITA",
    "admin_name": "Lombardy",
    "capital": "minor",
    "population": 84378,
    "id": 1380994905
  },
  {
    "city": "Nacajuca",
    "city_ascii": "Nacajuca",
    "lat": 18.1692,
    "lng": -93.0197,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Tabasco",
    "capital": "minor",
    "population": 83356,
    "id": 1484698021
  },
  {
    "city": "Cárdenas",
    "city_ascii": "Cardenas",
    "lat": 17.983,
    "lng": -93.367,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Tabasco",
    "capital": "minor",
    "population": 79875,
    "id": 1484824578
  },
  {
    "city": "Loznica",
    "city_ascii": "Loznica",
    "lat": 44.5333,
    "lng": 19.2258,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Loznica",
    "capital": "admin",
    "population": 86413,
    "id": 1688071221
  },
  {
    "city": "Edgware",
    "city_ascii": "Edgware",
    "lat": 51.6185,
    "lng": -0.2729,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Barnet",
    "capital": "",
    "population": 76506,
    "id": 1826048876
  },
  {
    "city": "Tucupita",
    "city_ascii": "Tucupita",
    "lat": 9.0575,
    "lng": -62.0452,
    "country": "Venezuela",
    "iso2": "VE",
    "iso3": "VEN",
    "admin_name": "Delta Amacuro",
    "capital": "admin",
    "population": 86487,
    "id": 1862849758
  },
  {
    "city": "Nabatîyé",
    "city_ascii": "Nabatiye",
    "lat": 33.3833,
    "lng": 35.45,
    "country": "Lebanon",
    "iso2": "LB",
    "iso3": "LBN",
    "admin_name": "Nabatîyé",
    "capital": "admin",
    "population": 80000,
    "id": 1422269278
  },
  {
    "city": "Kanasín",
    "city_ascii": "Kanasin",
    "lat": 20.9344,
    "lng": -89.5578,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Yucatán",
    "capital": "minor",
    "population": 77240,
    "id": 1484390572
  },
  {
    "city": "Ezpeleta",
    "city_ascii": "Ezpeleta",
    "lat": -34.7517,
    "lng": -58.2344,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires",
    "capital": "",
    "population": 79557,
    "id": 1032462589
  },
  {
    "city": "Derry",
    "city_ascii": "Derry",
    "lat": 55.0004,
    "lng": -7.3333,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Derry and Strabane",
    "capital": "",
    "population": 83652,
    "id": 1826603241
  },
  {
    "city": "Piatra Neamţ",
    "city_ascii": "Piatra Neamt",
    "lat": 46.9275,
    "lng": 26.3708,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Neamţ",
    "capital": "admin",
    "population": 85055,
    "id": 1642444635
  },
  {
    "city": "Ath Thawrah",
    "city_ascii": "Ath Thawrah",
    "lat": 35.8344,
    "lng": 38.5464,
    "country": "Syria",
    "iso2": "SY",
    "iso3": "SYR",
    "admin_name": "Ar Raqqah",
    "capital": "minor",
    "population": 84000,
    "id": 1760605225
  },
  {
    "city": "Hawthorne",
    "city_ascii": "Hawthorne",
    "lat": 33.9146,
    "lng": -118.3476,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 86068,
    "id": 1840020486
  },
  {
    "city": "Lavras",
    "city_ascii": "Lavras",
    "lat": -21.2453,
    "lng": -44.9997,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Minas Gerais",
    "capital": "",
    "population": 81472,
    "id": 1076751259
  },
  {
    "city": "Avaré",
    "city_ascii": "Avare",
    "lat": -23.0986,
    "lng": -48.9258,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 81285,
    "id": 1076118860
  },
  {
    "city": "Busto Arsizio",
    "city_ascii": "Busto Arsizio",
    "lat": 45.612,
    "lng": 8.8518,
    "country": "Italy",
    "iso2": "IT",
    "iso3": "ITA",
    "admin_name": "Lombardy",
    "capital": "",
    "population": 83314,
    "id": 1380033378
  },
  {
    "city": "Maasin",
    "city_ascii": "Maasin",
    "lat": 10.1333,
    "lng": 124.85,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Southern Leyte",
    "capital": "admin",
    "population": 85560,
    "id": 1608244406
  },
  {
    "city": "Kouvola",
    "city_ascii": "Kouvola",
    "lat": 60.8681,
    "lng": 26.7042,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Kymenlaakso",
    "capital": "admin",
    "population": 85855,
    "id": 1246167296
  },
  {
    "city": "Kuznetsk",
    "city_ascii": "Kuznetsk",
    "lat": 53.1167,
    "lng": 46.6,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Penzenskaya Oblast’",
    "capital": "",
    "population": 82276,
    "id": 1643787798
  },
  {
    "city": "Owariasahi",
    "city_ascii": "Owariasahi",
    "lat": 35.2167,
    "lng": 137.0333,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Aichi",
    "capital": "",
    "population": 81914,
    "id": 1392703321
  },
  {
    "city": "Daugavpils",
    "city_ascii": "Daugavpils",
    "lat": 55.8714,
    "lng": 26.5161,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Daugavpils Novads",
    "capital": "admin",
    "population": 84592,
    "id": 1428924928
  },
  {
    "city": "Ciudad Lerdo",
    "city_ascii": "Ciudad Lerdo",
    "lat": 25.55,
    "lng": -103.5167,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Durango",
    "capital": "minor",
    "population": 79669,
    "id": 1484980436
  },
  {
    "city": "Issy-les-Moulineaux",
    "city_ascii": "Issy-les-Moulineaux",
    "lat": 48.8239,
    "lng": 2.27,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 68451,
    "id": 1250923546
  },
  {
    "city": "Pori",
    "city_ascii": "Pori",
    "lat": 61.4847,
    "lng": 21.7972,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Satakunta",
    "capital": "admin",
    "population": 85363,
    "id": 1246259412
  },
  {
    "city": "Scunthorpe",
    "city_ascii": "Scunthorpe",
    "lat": 53.5809,
    "lng": -0.6502,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "North Lincolnshire",
    "capital": "",
    "population": 82334,
    "id": 1826817883
  },
  {
    "city": "Yambol",
    "city_ascii": "Yambol",
    "lat": 42.4844,
    "lng": 26.5086,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Yambol",
    "capital": "admin",
    "population": 84232,
    "id": 1100748067
  },
  {
    "city": "Louga",
    "city_ascii": "Louga",
    "lat": 15.6167,
    "lng": -16.2167,
    "country": "Senegal",
    "iso2": "SN",
    "iso3": "SEN",
    "admin_name": "Louga",
    "capital": "admin",
    "population": 85075,
    "id": 1686269931
  },
  {
    "city": "Manfalūţ",
    "city_ascii": "Manfalut",
    "lat": 27.3128,
    "lng": 30.9703,
    "country": "Egypt",
    "iso2": "EG",
    "iso3": "EGY",
    "admin_name": "Asyūţ",
    "capital": "",
    "population": 82585,
    "id": 1818076217
  },
  {
    "city": "Whittier",
    "city_ascii": "Whittier",
    "lat": 33.9678,
    "lng": -118.0188,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 85098,
    "id": 1840021878
  },
  {
    "city": "Clifton",
    "city_ascii": "Clifton",
    "lat": 40.8631,
    "lng": -74.1575,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 85052,
    "id": 1840003511
  },
  {
    "city": "Puerto Maldonado",
    "city_ascii": "Puerto Maldonado",
    "lat": -12.6,
    "lng": -69.1833,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "Madre de Dios",
    "capital": "admin",
    "population": 85024,
    "id": 1604871130
  },
  {
    "city": "Heṭauḍā",
    "city_ascii": "Hetauda",
    "lat": 27.4167,
    "lng": 85.0333,
    "country": "Nepal",
    "iso2": "NP",
    "iso3": "NPL",
    "admin_name": "Nārāyanī",
    "capital": "admin",
    "population": 84671,
    "id": 1524551914
  },
  {
    "city": "Formosa",
    "city_ascii": "Formosa",
    "lat": -15.5395,
    "lng": -47.34,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Goiás",
    "capital": "",
    "population": 81232,
    "id": 1076601172
  },
  {
    "city": "Chaguanas",
    "city_ascii": "Chaguanas",
    "lat": 10.5144,
    "lng": -61.4075,
    "country": "Trinidad And Tobago",
    "iso2": "TT",
    "iso3": "TTO",
    "admin_name": "Chaguanas",
    "capital": "admin",
    "population": 83489,
    "id": 1780795054
  },
  {
    "city": "Cunduacán",
    "city_ascii": "Cunduacan",
    "lat": 18.0667,
    "lng": -93.1667,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Tabasco",
    "capital": "minor",
    "population": 81392,
    "id": 1484076708
  },
  {
    "city": "Chimaltenango",
    "city_ascii": "Chimaltenango",
    "lat": 14.662,
    "lng": -90.82,
    "country": "Guatemala",
    "iso2": "GT",
    "iso3": "GTM",
    "admin_name": "Chimaltenango",
    "capital": "admin",
    "population": 82370,
    "id": 1320147012
  },
  {
    "city": "Pazardzhik",
    "city_ascii": "Pazardzhik",
    "lat": 42.1892,
    "lng": 24.3317,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Pazardzhik",
    "capital": "admin",
    "population": 84619,
    "id": 1100273248
  },
  {
    "city": "Uman’",
    "city_ascii": "Uman'",
    "lat": 48.75,
    "lng": 30.2167,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Cherkas’ka Oblast’",
    "capital": "minor",
    "population": 82762,
    "id": 1804670442
  },
  {
    "city": "Nagaoka",
    "city_ascii": "Nagaoka",
    "lat": 34.9267,
    "lng": 135.6956,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Kyōto",
    "capital": "",
    "population": 80514,
    "id": 1392219533
  },
  {
    "city": "Nazareth",
    "city_ascii": "Nazareth",
    "lat": 32.7021,
    "lng": 35.2978,
    "country": "Israel",
    "iso2": "IL",
    "iso3": "ISR",
    "admin_name": "Northern",
    "capital": "admin",
    "population": 83400,
    "id": 1376505625
  },
  {
    "city": "Ivanteyevka",
    "city_ascii": "Ivanteyevka",
    "lat": 55.97,
    "lng": 37.92,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Moskovskaya Oblast’",
    "capital": "",
    "population": 79346,
    "id": 1643986494
  },
  {
    "city": "Tucuruí",
    "city_ascii": "Tucurui",
    "lat": -3.68,
    "lng": -49.72,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Pará",
    "capital": "",
    "population": 76337,
    "id": 1076000076
  },
  {
    "city": "Chervonohrad",
    "city_ascii": "Chervonohrad",
    "lat": 50.3822,
    "lng": 24.2275,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "L’vivs’ka Oblast’",
    "capital": "",
    "population": 82395,
    "id": 1804958186
  },
  {
    "city": "Cozumel",
    "city_ascii": "Cozumel",
    "lat": 20.5104,
    "lng": -86.9493,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Quintana Roo",
    "capital": "minor",
    "population": 77236,
    "id": 1484935305
  },
  {
    "city": "Cukai",
    "city_ascii": "Cukai",
    "lat": 4.2332,
    "lng": 103.4479,
    "country": "Malaysia",
    "iso2": "MY",
    "iso3": "MYS",
    "admin_name": "Terengganu",
    "capital": "",
    "population": 82425,
    "id": 1458033606
  },
  {
    "city": "Mbarara",
    "city_ascii": "Mbarara",
    "lat": -0.5996,
    "lng": 30.65,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Mbarara",
    "capital": "admin",
    "population": 83700,
    "id": 1800554483
  },
  {
    "city": "Champigny-sur-Marne",
    "city_ascii": "Champigny-sur-Marne",
    "lat": 48.8172,
    "lng": 2.5156,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 77630,
    "id": 1250631569
  },
  {
    "city": "Lucerne",
    "city_ascii": "Lucerne",
    "lat": 47.0523,
    "lng": 8.3059,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Luzern",
    "capital": "admin",
    "population": 81691,
    "id": 1756544536
  },
  {
    "city": "Mitrovicë",
    "city_ascii": "Mitrovice",
    "lat": 42.8833,
    "lng": 20.8667,
    "country": "Kosovo",
    "iso2": "XK",
    "iso3": "XKS",
    "admin_name": "",
    "capital": "admin",
    "population": 84235,
    "id": 1901138430
  },
  {
    "city": "Cabo San Lucas",
    "city_ascii": "Cabo San Lucas",
    "lat": 22.8897,
    "lng": -109.9156,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Baja California Sur",
    "capital": "",
    "population": 81111,
    "id": 1484694325
  },
  {
    "city": "Rivera",
    "city_ascii": "Rivera",
    "lat": -30.9025,
    "lng": -55.5506,
    "country": "Uruguay",
    "iso2": "UY",
    "iso3": "URY",
    "admin_name": "Rivera",
    "capital": "admin",
    "population": 78900,
    "id": 1858819782
  },
  {
    "city": "Kunitachi",
    "city_ascii": "Kunitachi",
    "lat": 35.6839,
    "lng": 139.4414,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Tōkyō",
    "capital": "",
    "population": 75156,
    "id": 1392309515
  },
  {
    "city": "Saint-Ouen",
    "city_ascii": "Saint-Ouen",
    "lat": 48.9123,
    "lng": 2.3342,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 51108,
    "id": 1250000037
  },
  {
    "city": "Ouidah",
    "city_ascii": "Ouidah",
    "lat": 6.3604,
    "lng": 2.09,
    "country": "Benin",
    "iso2": "BJ",
    "iso3": "BEN",
    "admin_name": "Atlantique",
    "capital": "admin",
    "population": 83503,
    "id": 1204541753
  },
  {
    "city": "Newmarket",
    "city_ascii": "Newmarket",
    "lat": 44.05,
    "lng": -79.4667,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 84224,
    "id": 1124400266
  },
  {
    "city": "Bitola",
    "city_ascii": "Bitola",
    "lat": 41.0319,
    "lng": 21.3347,
    "country": "Macedonia",
    "iso2": "MK",
    "iso3": "MKD",
    "admin_name": "Bitola",
    "capital": "admin",
    "population": 84002,
    "id": 1807488567
  },
  {
    "city": "Itumbiara",
    "city_ascii": "Itumbiara",
    "lat": -18.3996,
    "lng": -49.21,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Goiás",
    "capital": "",
    "population": 79582,
    "id": 1076693747
  },
  {
    "city": "Milpitas",
    "city_ascii": "Milpitas",
    "lat": 37.4339,
    "lng": -121.8921,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 84196,
    "id": 1840020329
  },
  {
    "city": "Pernik",
    "city_ascii": "Pernik",
    "lat": 42.6097,
    "lng": 23.0308,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Pernik",
    "capital": "admin",
    "population": 83973,
    "id": 1100616332
  },
  {
    "city": "Clichy",
    "city_ascii": "Clichy",
    "lat": 48.9044,
    "lng": 2.3064,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 61070,
    "id": 1250104726
  },
  {
    "city": "Phuket",
    "city_ascii": "Phuket",
    "lat": 7.8881,
    "lng": 98.3975,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Phuket",
    "capital": "admin",
    "population": 77610,
    "id": 1764327831
  },
  {
    "city": "Ho",
    "city_ascii": "Ho",
    "lat": 6.6114,
    "lng": 0.4703,
    "country": "Ghana",
    "iso2": "GH",
    "iso3": "GHA",
    "admin_name": "Volta",
    "capital": "admin",
    "population": 83715,
    "id": 1288938914
  },
  {
    "city": "West Bromwich",
    "city_ascii": "West Bromwich",
    "lat": 52.519,
    "lng": -1.995,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Sandwell",
    "capital": "",
    "population": 77997,
    "id": 1826231773
  },
  {
    "city": "Alhambra",
    "city_ascii": "Alhambra",
    "lat": 34.084,
    "lng": -118.1355,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 83750,
    "id": 1840019222
  },
  {
    "city": "Žilina",
    "city_ascii": "Zilina",
    "lat": 49.2167,
    "lng": 18.7333,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Žilinský",
    "capital": "admin",
    "population": 82704,
    "id": 1703289183
  },
  {
    "city": "Reşiţa",
    "city_ascii": "Resita",
    "lat": 45.297,
    "lng": 21.8865,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Caraş-Severin",
    "capital": "admin",
    "population": 83324,
    "id": 1642047399
  },
  {
    "city": "Shinkai",
    "city_ascii": "Shinkai",
    "lat": 35.8367,
    "lng": 139.5803,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Saitama",
    "capital": "",
    "population": 75265,
    "id": 1392023081
  },
  {
    "city": "Chatham",
    "city_ascii": "Chatham",
    "lat": 51.37,
    "lng": 0.52,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Medway",
    "capital": "",
    "population": 76792,
    "id": 1826765672
  },
  {
    "city": "Rueil-Malmaison",
    "city_ascii": "Rueil-Malmaison",
    "lat": 48.876,
    "lng": 2.181,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 78152,
    "id": 1250924511
  },
  {
    "city": "Antsiran̈ana",
    "city_ascii": "Antsiranana",
    "lat": -12.2765,
    "lng": 49.3115,
    "country": "Madagascar",
    "iso2": "MG",
    "iso3": "MDG",
    "admin_name": "Antsiranana",
    "capital": "admin",
    "population": 82937,
    "id": 1450106928
  },
  {
    "city": "Dudley",
    "city_ascii": "Dudley",
    "lat": 52.508,
    "lng": -2.089,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Dudley",
    "capital": "",
    "population": 79379,
    "id": 1826052828
  },
  {
    "city": "Itaúna",
    "city_ascii": "Itauna",
    "lat": -20.0753,
    "lng": -44.5764,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Minas Gerais",
    "capital": "",
    "population": 77400,
    "id": 1076321811
  },
  {
    "city": "Ban Talat Rangsit",
    "city_ascii": "Ban Talat Rangsit",
    "lat": 13.987,
    "lng": 100.6095,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Pathum Thani",
    "capital": "",
    "population": 79319,
    "id": 1764039814
  },
  {
    "city": "Lodwar",
    "city_ascii": "Lodwar",
    "lat": 3.1167,
    "lng": 35.6,
    "country": "Kenya",
    "iso2": "KE",
    "iso3": "KEN",
    "admin_name": "Turkana",
    "capital": "admin",
    "population": 82970,
    "id": 1404993573
  },
  {
    "city": "Târgu Jiu",
    "city_ascii": "Targu Jiu",
    "lat": 45.0342,
    "lng": 23.2747,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Gorj",
    "capital": "admin",
    "population": 82504,
    "id": 1642174891
  },
  {
    "city": "Zacatlán",
    "city_ascii": "Zacatlan",
    "lat": 19.9319,
    "lng": -97.96,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Puebla",
    "capital": "minor",
    "population": 76296,
    "id": 1484132726
  },
  {
    "city": "Upper Darby",
    "city_ascii": "Upper Darby",
    "lat": 39.949,
    "lng": -75.2892,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 82846,
    "id": 1840035234
  },
  {
    "city": "Abomey",
    "city_ascii": "Abomey",
    "lat": 7.1853,
    "lng": 1.9914,
    "country": "Benin",
    "iso2": "BJ",
    "iso3": "BEN",
    "admin_name": "Zou",
    "capital": "admin",
    "population": 82154,
    "id": 1204597697
  },
  {
    "city": "Mountain View",
    "city_ascii": "Mountain View",
    "lat": 37.4,
    "lng": -122.0796,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 82739,
    "id": 1840020332
  },
  {
    "city": "Blagoevgrad",
    "city_ascii": "Blagoevgrad",
    "lat": 42.0219,
    "lng": 23.0975,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Blagoevgrad",
    "capital": "admin",
    "population": 82606,
    "id": 1100214416
  },
  {
    "city": "Kasserine",
    "city_ascii": "Kasserine",
    "lat": 35.1804,
    "lng": 8.83,
    "country": "Tunisia",
    "iso2": "TN",
    "iso3": "TUN",
    "admin_name": "Kasserine",
    "capital": "admin",
    "population": 81987,
    "id": 1788047794
  },
  {
    "city": "Elbasan",
    "city_ascii": "Elbasan",
    "lat": 41.1125,
    "lng": 20.0822,
    "country": "Albania",
    "iso2": "AL",
    "iso3": "ALB",
    "admin_name": "Elbasan",
    "capital": "admin",
    "population": 78703,
    "id": 1008409270
  },
  {
    "city": "Al Muḩarraq",
    "city_ascii": "Al Muharraq",
    "lat": 26.25,
    "lng": 50.6167,
    "country": "Bahrain",
    "iso2": "BH",
    "iso3": "BHR",
    "admin_name": "Al Muḩarraq",
    "capital": "",
    "population": 75000,
    "id": 1048992336
  },
  {
    "city": "White Rock",
    "city_ascii": "White Rock",
    "lat": 49.025,
    "lng": -122.8028,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "British Columbia",
    "capital": "",
    "population": 82368,
    "id": 1124260555
  },
  {
    "city": "Néa Ionía",
    "city_ascii": "Nea Ionia",
    "lat": 38.0333,
    "lng": 23.75,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Attikí",
    "capital": "minor",
    "population": 67134,
    "id": 1300842760
  },
  {
    "city": "Kayes",
    "city_ascii": "Kayes",
    "lat": 14.45,
    "lng": -11.4167,
    "country": "Mali",
    "iso2": "ML",
    "iso3": "MLI",
    "admin_name": "Kayes",
    "capital": "admin",
    "population": 77207,
    "id": 1466546429
  },
  {
    "city": "São Mateus",
    "city_ascii": "Sao Mateus",
    "lat": -18.7201,
    "lng": -39.8589,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Espírito Santo",
    "capital": "",
    "population": 77117,
    "id": 1076829621
  },
  {
    "city": "South Shields",
    "city_ascii": "South Shields",
    "lat": 54.995,
    "lng": -1.43,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "South Tyneside",
    "capital": "",
    "population": 75337,
    "id": 1826396004
  },
  {
    "city": "Paisley",
    "city_ascii": "Paisley",
    "lat": 55.8466,
    "lng": -4.4236,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Renfrewshire",
    "capital": "",
    "population": 77310,
    "id": 1826549531
  },
  {
    "city": "Weston-super-Mare",
    "city_ascii": "Weston-super-Mare",
    "lat": 51.346,
    "lng": -2.977,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "North Somerset",
    "capital": "",
    "population": 76143,
    "id": 1826189795
  },
  {
    "city": "Goiana",
    "city_ascii": "Goiana",
    "lat": -7.5606,
    "lng": -35.0025,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Pernambuco",
    "capital": "",
    "population": 71549,
    "id": 1076692421
  },
  {
    "city": "Bilecik",
    "city_ascii": "Bilecik",
    "lat": 40.1431,
    "lng": 29.9792,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Bilecik",
    "capital": "admin",
    "population": 81723,
    "id": 1792408667
  },
  {
    "city": "Juchitán de Zaragoza",
    "city_ascii": "Juchitan de Zaragoza",
    "lat": 16.4333,
    "lng": -95.0194,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Oaxaca",
    "capital": "minor",
    "population": 74825,
    "id": 1484386317
  },
  {
    "city": "Buena Park",
    "city_ascii": "Buena Park",
    "lat": 33.8572,
    "lng": -118.0046,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 81788,
    "id": 1840019324
  },
  {
    "city": "Saint-Maur-des-Fossés",
    "city_ascii": "Saint-Maur-des-Fosses",
    "lat": 48.7994,
    "lng": 2.4997,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 74859,
    "id": 1250687119
  },
  {
    "city": "Hakkari",
    "city_ascii": "Hakkari",
    "lat": 37.5744,
    "lng": 43.7408,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Hakkâri",
    "capital": "admin",
    "population": 81424,
    "id": 1792629133
  },
  {
    "city": "Somerville",
    "city_ascii": "Somerville",
    "lat": 42.3908,
    "lng": -71.1013,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 81360,
    "id": 1840003123
  },
  {
    "city": "Chalándri",
    "city_ascii": "Chalandri",
    "lat": 38.0167,
    "lng": 23.8,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Attikí",
    "capital": "minor",
    "population": 74192,
    "id": 1300443208
  },
  {
    "city": "Bury",
    "city_ascii": "Bury",
    "lat": 53.593,
    "lng": -2.298,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Bury",
    "capital": "",
    "population": 78723,
    "id": 1826242195
  },
  {
    "city": "Três Lagoas",
    "city_ascii": "Tres Lagoas",
    "lat": -20.7876,
    "lng": -51.7034,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Mato Grosso do Sul",
    "capital": "",
    "population": 78712,
    "id": 1076281782
  },
  {
    "city": "Jataí",
    "city_ascii": "Jatai",
    "lat": -17.8794,
    "lng": -51.7217,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Goiás",
    "capital": "",
    "population": 76547,
    "id": 1076908960
  },
  {
    "city": "Gravesend",
    "city_ascii": "Gravesend",
    "lat": 51.4415,
    "lng": 0.3685,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Kent",
    "capital": "",
    "population": 74000,
    "id": 1826609378
  },
  {
    "city": "Avilés",
    "city_ascii": "Aviles",
    "lat": 43.5561,
    "lng": -5.9222,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Asturias",
    "capital": "",
    "population": 78182,
    "id": 1724815050
  },
  {
    "city": "Deerfield Beach",
    "city_ascii": "Deerfield Beach",
    "lat": 26.305,
    "lng": -80.1277,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 81066,
    "id": 1840015136
  },
  {
    "city": "Târgovişte",
    "city_ascii": "Targoviste",
    "lat": 44.9244,
    "lng": 25.4572,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Dâmboviţa",
    "capital": "admin",
    "population": 79610,
    "id": 1642534646
  },
  {
    "city": "Altamira",
    "city_ascii": "Altamira",
    "lat": -3.1996,
    "lng": -52.21,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Pará",
    "capital": "",
    "population": 70888,
    "id": 1076710878
  },
  {
    "city": "Galway",
    "city_ascii": "Galway",
    "lat": 53.2729,
    "lng": -9.0418,
    "country": "Ireland",
    "iso2": "IE",
    "iso3": "IRL",
    "admin_name": "Galway",
    "capital": "admin",
    "population": 79504,
    "id": 1372499968
  },
  {
    "city": "Natitingou",
    "city_ascii": "Natitingou",
    "lat": 10.3204,
    "lng": 1.39,
    "country": "Benin",
    "iso2": "BJ",
    "iso3": "BEN",
    "admin_name": "Atacora",
    "capital": "admin",
    "population": 80892,
    "id": 1204752595
  },
  {
    "city": "São João del Rei",
    "city_ascii": "Sao Joao del Rei",
    "lat": -21.13,
    "lng": -44.25,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Minas Gerais",
    "capital": "",
    "population": 78592,
    "id": 1076559413
  },
  {
    "city": "Barreiro",
    "city_ascii": "Barreiro",
    "lat": 38.6609,
    "lng": -9.0733,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Setúbal",
    "capital": "minor",
    "population": 78764,
    "id": 1620760195
  },
  {
    "city": "Paysandú",
    "city_ascii": "Paysandu",
    "lat": -32.32,
    "lng": -58.0756,
    "country": "Uruguay",
    "iso2": "UY",
    "iso3": "URY",
    "admin_name": "Paysandú",
    "capital": "admin",
    "population": 76429,
    "id": 1858756515
  },
  {
    "city": "Cicero",
    "city_ascii": "Cicero",
    "lat": 41.8445,
    "lng": -87.7593,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 80796,
    "id": 1840009186
  },
  {
    "city": "Atakpamé",
    "city_ascii": "Atakpame",
    "lat": 7.53,
    "lng": 1.12,
    "country": "Togo",
    "iso2": "TG",
    "iso3": "TGO",
    "admin_name": "Plateaux",
    "capital": "admin",
    "population": 80683,
    "id": 1768070717
  },
  {
    "city": "Focşani",
    "city_ascii": "Focsani",
    "lat": 45.6997,
    "lng": 27.1797,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Vrancea",
    "capital": "admin",
    "population": 79315,
    "id": 1642736232
  },
  {
    "city": "Palmerston North",
    "city_ascii": "Palmerston North",
    "lat": -40.3549,
    "lng": 175.6095,
    "country": "New Zealand",
    "iso2": "NZ",
    "iso3": "NZL",
    "admin_name": "Manawatu-Wanganui",
    "capital": "admin",
    "population": 80300,
    "id": 1554138183
  },
  {
    "city": "Iguatu",
    "city_ascii": "Iguatu",
    "lat": -6.3594,
    "lng": -39.2986,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Ceará",
    "capital": "",
    "population": 70380,
    "id": 1076734026
  },
  {
    "city": "Drancy",
    "city_ascii": "Drancy",
    "lat": 48.93,
    "lng": 2.45,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 71318,
    "id": 1250144343
  },
  {
    "city": "Ādīgrat",
    "city_ascii": "Adigrat",
    "lat": 14.2667,
    "lng": 39.45,
    "country": "Ethiopia",
    "iso2": "ET",
    "iso3": "ETH",
    "admin_name": "Tigray",
    "capital": "",
    "population": 76400,
    "id": 1231492773
  },
  {
    "city": "Zuwārah",
    "city_ascii": "Zuwarah",
    "lat": 32.9333,
    "lng": 12.0833,
    "country": "Libya",
    "iso2": "LY",
    "iso3": "LBY",
    "admin_name": "An Nuqāţ al Khams",
    "capital": "admin",
    "population": 80310,
    "id": 1434206265
  },
  {
    "city": "Shancheng",
    "city_ascii": "Shancheng",
    "lat": 34.7904,
    "lng": 116.08,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Shandong",
    "capital": "",
    "population": 74459,
    "id": 1156514157
  },
  {
    "city": "Sidi Qacem",
    "city_ascii": "Sidi Qacem",
    "lat": 34.21,
    "lng": -5.7,
    "country": "Morocco",
    "iso2": "MA",
    "iso3": "MAR",
    "admin_name": "Rabat-Salé-Kénitra",
    "capital": "",
    "population": 75672,
    "id": 1504300626
  },
  {
    "city": "Puerto Ayacucho",
    "city_ascii": "Puerto Ayacucho",
    "lat": 5.6631,
    "lng": -67.6264,
    "country": "Venezuela",
    "iso2": "VE",
    "iso3": "VEN",
    "admin_name": "Amazonas",
    "capital": "admin",
    "population": 80000,
    "id": 1862027409
  },
  {
    "city": "Lawrence",
    "city_ascii": "Lawrence",
    "lat": 42.7003,
    "lng": -71.1626,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 80028,
    "id": 1840000408
  },
  {
    "city": "Erdenet",
    "city_ascii": "Erdenet",
    "lat": 49.0278,
    "lng": 104.0444,
    "country": "Mongolia",
    "iso2": "MN",
    "iso3": "MNG",
    "admin_name": "Orhon",
    "capital": "admin",
    "population": 79647,
    "id": 1496374417
  },
  {
    "city": "Rubí",
    "city_ascii": "Rubi",
    "lat": 41.4933,
    "lng": 2.0325,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Catalonia",
    "capital": "",
    "population": 77464,
    "id": 1724552367
  },
  {
    "city": "Temoaya",
    "city_ascii": "Temoaya",
    "lat": 19.4686,
    "lng": -99.5933,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Morelos",
    "capital": "minor",
    "population": 77714,
    "id": 1484890875
  },
  {
    "city": "Ouahigouya",
    "city_ascii": "Ouahigouya",
    "lat": 13.5833,
    "lng": -2.4167,
    "country": "Burkina Faso",
    "iso2": "BF",
    "iso3": "BFA",
    "admin_name": "Nord",
    "capital": "admin",
    "population": 79504,
    "id": 1854011699
  },
  {
    "city": "Silver Spring",
    "city_ascii": "Silver Spring",
    "lat": 39.0028,
    "lng": -77.0207,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 79750,
    "id": 1840005845
  },
  {
    "city": "Nakhon Pathom",
    "city_ascii": "Nakhon Pathom",
    "lat": 13.8194,
    "lng": 100.0581,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Nakhon Pathom",
    "capital": "admin",
    "population": 78780,
    "id": 1764251992
  },
  {
    "city": "Lowestoft",
    "city_ascii": "Lowestoft",
    "lat": 52.48,
    "lng": 1.75,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Suffolk",
    "capital": "",
    "population": 71010,
    "id": 1826419858
  },
  {
    "city": "Pau",
    "city_ascii": "Pau",
    "lat": 43.3,
    "lng": -0.37,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Nouvelle-Aquitaine",
    "capital": "minor",
    "population": 77130,
    "id": 1250041150
  },
  {
    "city": "Tejupilco",
    "city_ascii": "Tejupilco",
    "lat": 18.9058,
    "lng": -100.1528,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "México",
    "capital": "minor",
    "population": 71077,
    "id": 1484506998
  },
  {
    "city": "Cheyenne",
    "city_ascii": "Cheyenne",
    "lat": 41.1351,
    "lng": -104.79,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wyoming",
    "capital": "admin",
    "population": 79484,
    "id": 1840018713
  },
  {
    "city": "Ubon Ratchathani",
    "city_ascii": "Ubon Ratchathani",
    "lat": 15.2281,
    "lng": 104.8594,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Ubon Ratchathani",
    "capital": "admin",
    "population": 79023,
    "id": 1764611048
  },
  {
    "city": "Tustin",
    "city_ascii": "Tustin",
    "lat": 33.7309,
    "lng": -117.8106,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 79348,
    "id": 1840021967
  },
  {
    "city": "Lakewood",
    "city_ascii": "Lakewood",
    "lat": 33.8471,
    "lng": -118.1221,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 79307,
    "id": 1840020515
  },
  {
    "city": "Carlisle",
    "city_ascii": "Carlisle",
    "lat": 54.891,
    "lng": -2.944,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Cumbria",
    "capital": "",
    "population": 75306,
    "id": 1826843688
  },
  {
    "city": "Lisala",
    "city_ascii": "Lisala",
    "lat": 2.14,
    "lng": 21.51,
    "country": "Congo (Kinshasa)",
    "iso2": "CD",
    "iso3": "COD",
    "admin_name": "Mongala",
    "capital": "admin",
    "population": 79235,
    "id": 1180477995
  },
  {
    "city": "Szombathely",
    "city_ascii": "Szombathely",
    "lat": 47.2333,
    "lng": 16.6333,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Vas",
    "capital": "admin",
    "population": 78407,
    "id": 1348565183
  },
  {
    "city": "Banská Bystrica",
    "city_ascii": "Banska Bystrica",
    "lat": 48.7353,
    "lng": 19.1453,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Banskobystrický",
    "capital": "admin",
    "population": 78327,
    "id": 1703015198
  },
  {
    "city": "Ra‘ananna",
    "city_ascii": "Ra`ananna",
    "lat": 32.1833,
    "lng": 34.8667,
    "country": "Israel",
    "iso2": "IL",
    "iso3": "ISR",
    "admin_name": "Central",
    "capital": "",
    "population": 74000,
    "id": 1376791991
  },
  {
    "city": "Harrogate",
    "city_ascii": "Harrogate",
    "lat": 53.9919,
    "lng": -1.5378,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "North Yorkshire",
    "capital": "",
    "population": 75070,
    "id": 1826765513
  },
  {
    "city": "Aveiro",
    "city_ascii": "Aveiro",
    "lat": 40.6389,
    "lng": -8.6553,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Aveiro",
    "capital": "admin",
    "population": 78450,
    "id": 1620555869
  },
  {
    "city": "Tambacounda",
    "city_ascii": "Tambacounda",
    "lat": 13.7689,
    "lng": -13.6672,
    "country": "Senegal",
    "iso2": "SN",
    "iso3": "SEN",
    "admin_name": "Tambacounda",
    "capital": "admin",
    "population": 78800,
    "id": 1686195160
  },
  {
    "city": "Newcastle under Lyme",
    "city_ascii": "Newcastle under Lyme",
    "lat": 53.0109,
    "lng": -2.2278,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Staffordshire",
    "capital": "",
    "population": 75082,
    "id": 1826124765
  },
  {
    "city": "Inowrocław",
    "city_ascii": "Inowroclaw",
    "lat": 52.7958,
    "lng": 18.2611,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Kujawsko-Pomorskie",
    "capital": "minor",
    "population": 76086,
    "id": 1616606592
  },
  {
    "city": "New Rochelle",
    "city_ascii": "New Rochelle",
    "lat": 40.9305,
    "lng": -73.7836,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 78557,
    "id": 1840000782
  },
  {
    "city": "Kensington",
    "city_ascii": "Kensington",
    "lat": 51.5,
    "lng": -0.19,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Kensington and Chelsea",
    "capital": "",
    "population": 64681,
    "id": 1826583042
  },
  {
    "city": "La Rochelle",
    "city_ascii": "La Rochelle",
    "lat": 46.1591,
    "lng": -1.1517,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Nouvelle-Aquitaine",
    "capital": "minor",
    "population": 75735,
    "id": 1250623782
  },
  {
    "city": "Lebanon",
    "city_ascii": "Lebanon",
    "lat": 40.3412,
    "lng": -76.4227,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 78302,
    "id": 1840001347
  },
  {
    "city": "East Kilbride",
    "city_ascii": "East Kilbride",
    "lat": 55.7645,
    "lng": -4.1771,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "South Lanarkshire",
    "capital": "",
    "population": 75390,
    "id": 1826488452
  },
  {
    "city": "Wa",
    "city_ascii": "Wa",
    "lat": 10.0667,
    "lng": -2.5,
    "country": "Ghana",
    "iso2": "GH",
    "iso3": "GHA",
    "admin_name": "Upper West",
    "capital": "admin",
    "population": 78107,
    "id": 1288765586
  },
  {
    "city": "Nitra",
    "city_ascii": "Nitra",
    "lat": 48.3147,
    "lng": 18.0875,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Nitriansky",
    "capital": "admin",
    "population": 77374,
    "id": 1703890031
  },
  {
    "city": "Lomas del Mirador",
    "city_ascii": "Lomas del Mirador",
    "lat": -34.6667,
    "lng": -58.5297,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires",
    "capital": "",
    "population": 71479,
    "id": 1032639381
  },
  {
    "city": "Maghāghah",
    "city_ascii": "Maghaghah",
    "lat": 28.6483,
    "lng": 30.8422,
    "country": "Egypt",
    "iso2": "EG",
    "iso3": "EGY",
    "admin_name": "Al Minyā",
    "capital": "",
    "population": 75657,
    "id": 1818762526
  },
  {
    "city": "Mpanda",
    "city_ascii": "Mpanda",
    "lat": -6.3436,
    "lng": 31.0694,
    "country": "Tanzania",
    "iso2": "TZ",
    "iso3": "TZA",
    "admin_name": "Katavi",
    "capital": "admin",
    "population": 73338,
    "id": 1834127573
  },
  {
    "city": "Bacabal",
    "city_ascii": "Bacabal",
    "lat": -4.23,
    "lng": -44.8,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Maranhão",
    "capital": "",
    "population": 72372,
    "id": 1076125117
  },
  {
    "city": "Gladbeck",
    "city_ascii": "Gladbeck",
    "lat": 51.5713,
    "lng": 6.9827,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 75687,
    "id": 1276216238
  },
  {
    "city": "Sankt Gallen",
    "city_ascii": "Sankt Gallen",
    "lat": 47.4233,
    "lng": 9.3772,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Sankt Gallen",
    "capital": "admin",
    "population": 75833,
    "id": 1756343098
  },
  {
    "city": "Cachoeira do Sul",
    "city_ascii": "Cachoeira do Sul",
    "lat": -30.03,
    "lng": -52.91,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Rio Grande do Sul",
    "capital": "",
    "population": 74694,
    "id": 1076336795
  },
  {
    "city": "Campo Mourão",
    "city_ascii": "Campo Mourao",
    "lat": -24.0496,
    "lng": -52.42,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Paraná",
    "capital": "",
    "population": 75401,
    "id": 1076799533
  },
  {
    "city": "Cannes",
    "city_ascii": "Cannes",
    "lat": 43.5513,
    "lng": 7.0128,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Provence-Alpes-Côte d’Azur",
    "capital": "",
    "population": 73868,
    "id": 1250487828
  },
  {
    "city": "Alameda",
    "city_ascii": "Alameda",
    "lat": 37.767,
    "lng": -122.2672,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 77624,
    "id": 1840018913
  },
  {
    "city": "Thornton Heath",
    "city_ascii": "Thornton Heath",
    "lat": 51.4002,
    "lng": -0.1086,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Croydon",
    "capital": "",
    "population": 65812,
    "id": 1826002522
  },
  {
    "city": "Watsonville",
    "city_ascii": "Watsonville",
    "lat": 36.9206,
    "lng": -121.7706,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 77352,
    "id": 1840021581
  },
  {
    "city": "Tāluqān",
    "city_ascii": "Taluqan",
    "lat": 36.7167,
    "lng": 69.5167,
    "country": "Afghanistan",
    "iso2": "AF",
    "iso3": "AFG",
    "admin_name": "Takhār",
    "capital": "admin",
    "population": 77000,
    "id": 1004687194
  },
  {
    "city": "Juventino Rosas",
    "city_ascii": "Juventino Rosas",
    "lat": 20.65,
    "lng": -101,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Guanajuato",
    "capital": "minor",
    "population": 65479,
    "id": 1484008596
  },
  {
    "city": "Jinja",
    "city_ascii": "Jinja",
    "lat": 0.4431,
    "lng": 33.2128,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Jinja",
    "capital": "admin",
    "population": 72931,
    "id": 1800632405
  },
  {
    "city": "Davis",
    "city_ascii": "Davis",
    "lat": 38.5552,
    "lng": -121.7365,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 76997,
    "id": 1840018833
  },
  {
    "city": "Berbérati",
    "city_ascii": "Berberati",
    "lat": 4.2667,
    "lng": 15.7833,
    "country": "Central African Republic",
    "iso2": "CF",
    "iso3": "CAF",
    "admin_name": "Mambéré-Kadéï",
    "capital": "admin",
    "population": 76918,
    "id": 1140239733
  },
  {
    "city": "Esbjerg",
    "city_ascii": "Esbjerg",
    "lat": 55.467,
    "lng": 8.45,
    "country": "Denmark",
    "iso2": "DK",
    "iso3": "DNK",
    "admin_name": "Syddanmark",
    "capital": "minor",
    "population": 72205,
    "id": 1208757169
  },
  {
    "city": "Zrenjanin",
    "city_ascii": "Zrenjanin",
    "lat": 45.3778,
    "lng": 20.3861,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Zrenjanin",
    "capital": "admin",
    "population": 76511,
    "id": 1688827840
  },
  {
    "city": "Las Piedras",
    "city_ascii": "Las Piedras",
    "lat": -34.7167,
    "lng": -56.2167,
    "country": "Uruguay",
    "iso2": "UY",
    "iso3": "URY",
    "admin_name": "Canelones",
    "capital": "",
    "population": 71268,
    "id": 1858563314
  },
  {
    "city": "Surt",
    "city_ascii": "Surt",
    "lat": 31.2,
    "lng": 16.6,
    "country": "Libya",
    "iso2": "LY",
    "iso3": "LBY",
    "admin_name": "Surt",
    "capital": "admin",
    "population": 76788,
    "id": 1434563640
  },
  {
    "city": "Tamazunchale",
    "city_ascii": "Tamazunchale",
    "lat": 21.2667,
    "lng": -98.7833,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "San Luis Potosí",
    "capital": "minor",
    "population": 72685,
    "id": 1484802864
  },
  {
    "city": "Pančevo",
    "city_ascii": "Pancevo",
    "lat": 44.8739,
    "lng": 20.6519,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Pančevo",
    "capital": "admin",
    "population": 76203,
    "id": 1688356974
  },
  {
    "city": "Chiryū",
    "city_ascii": "Chiryu",
    "lat": 35,
    "lng": 137.1167,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Aichi",
    "capital": "",
    "population": 72166,
    "id": 1392192403
  },
  {
    "city": "Abéché",
    "city_ascii": "Abeche",
    "lat": 13.8331,
    "lng": 20.8347,
    "country": "Chad",
    "iso2": "TD",
    "iso3": "TCD",
    "admin_name": "Ouaddaï",
    "capital": "admin",
    "population": 76492,
    "id": 1148461151
  },
  {
    "city": "Bellflower",
    "city_ascii": "Bellflower",
    "lat": 33.888,
    "lng": -118.1271,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 76435,
    "id": 1840019230
  },
  {
    "city": "Ashford",
    "city_ascii": "Ashford",
    "lat": 51.1465,
    "lng": 0.8676,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Kent",
    "capital": "",
    "population": 74204,
    "id": 1826045748
  },
  {
    "city": "Bokhtar",
    "city_ascii": "Bokhtar",
    "lat": 37.8364,
    "lng": 68.7803,
    "country": "Tajikistan",
    "iso2": "TJ",
    "iso3": "TJK",
    "admin_name": "Khatlon",
    "capital": "admin",
    "population": 75450,
    "id": 1762998971
  },
  {
    "city": "Musashimurayama",
    "city_ascii": "Musashimurayama",
    "lat": 35.7547,
    "lng": 139.3875,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Tōkyō",
    "capital": "",
    "population": 71579,
    "id": 1392092262
  },
  {
    "city": "Nek’emtē",
    "city_ascii": "Nek'emte",
    "lat": 9.0905,
    "lng": 36.53,
    "country": "Ethiopia",
    "iso2": "ET",
    "iso3": "ETH",
    "admin_name": "Oromīya",
    "capital": "",
    "population": 73018,
    "id": 1231437227
  },
  {
    "city": "Calais",
    "city_ascii": "Calais",
    "lat": 50.9481,
    "lng": 1.8564,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Hauts-de-France",
    "capital": "minor",
    "population": 73911,
    "id": 1250081128
  },
  {
    "city": "Tamanrasset",
    "city_ascii": "Tamanrasset",
    "lat": 22.785,
    "lng": 5.5228,
    "country": "Algeria",
    "iso2": "DZ",
    "iso3": "DZA",
    "admin_name": "Tamanrasset",
    "capital": "admin",
    "population": 76000,
    "id": 1012765474
  },
  {
    "city": "Ramla",
    "city_ascii": "Ramla",
    "lat": 31.9318,
    "lng": 34.8736,
    "country": "Israel",
    "iso2": "IL",
    "iso3": "ISR",
    "admin_name": "Central",
    "capital": "admin",
    "population": 75500,
    "id": 1376321361
  },
  {
    "city": "Montrouge",
    "city_ascii": "Montrouge",
    "lat": 48.8172,
    "lng": 2.3219,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 50260,
    "id": 1250189603
  },
  {
    "city": "Zadar",
    "city_ascii": "Zadar",
    "lat": 44.1167,
    "lng": 15.2167,
    "country": "Croatia",
    "iso2": "HR",
    "iso3": "HRV",
    "admin_name": "Zadarska Županija",
    "capital": "admin",
    "population": 75082,
    "id": 1191160875
  },
  {
    "city": "Southall",
    "city_ascii": "Southall",
    "lat": 51.5121,
    "lng": -0.3779,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Ealing",
    "capital": "",
    "population": 69857,
    "id": 1826336436
  },
  {
    "city": "Guamúchil",
    "city_ascii": "Guamuchil",
    "lat": 25.4639,
    "lng": -108.0794,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Sinaloa",
    "capital": "minor",
    "population": 72500,
    "id": 1484709566
  },
  {
    "city": "Quảng Trị",
    "city_ascii": "Quang Tri",
    "lat": 16.7504,
    "lng": 107.2,
    "country": "Vietnam",
    "iso2": "VN",
    "iso3": "VNM",
    "admin_name": "Quảng Trị",
    "capital": "",
    "population": 72722,
    "id": 1704089491
  },
  {
    "city": "Antibes",
    "city_ascii": "Antibes",
    "lat": 43.5808,
    "lng": 7.1239,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Provence-Alpes-Côte d’Azur",
    "capital": "",
    "population": 72999,
    "id": 1250213494
  },
  {
    "city": "Zlín",
    "city_ascii": "Zlin",
    "lat": 49.2331,
    "lng": 17.6669,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Zlínský Kraj",
    "capital": "admin",
    "population": 74935,
    "id": 1203582141
  },
  {
    "city": "Paracatu",
    "city_ascii": "Paracatu",
    "lat": -17.2217,
    "lng": -46.875,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Minas Gerais",
    "capital": "",
    "population": 70753,
    "id": 1076347421
  },
  {
    "city": "Bistriţa",
    "city_ascii": "Bistrita",
    "lat": 47.1333,
    "lng": 24.4833,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Bistriţa-Năsăud",
    "capital": "admin",
    "population": 75076,
    "id": 1642633889
  },
  {
    "city": "Tissemsilt",
    "city_ascii": "Tissemsilt",
    "lat": 35.6072,
    "lng": 1.8106,
    "country": "Algeria",
    "iso2": "DZ",
    "iso3": "DZA",
    "admin_name": "Tissemsilt",
    "capital": "admin",
    "population": 75197,
    "id": 1012743339
  },
  {
    "city": "Pine Hills",
    "city_ascii": "Pine Hills",
    "lat": 28.5818,
    "lng": -81.4693,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 75575,
    "id": 1840014098
  },
  {
    "city": "Joensuu",
    "city_ascii": "Joensuu",
    "lat": 62.6,
    "lng": 29.7639,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Pohjois-Karjala",
    "capital": "admin",
    "population": 75514,
    "id": 1246489056
  },
  {
    "city": "Bella Vista",
    "city_ascii": "Bella Vista",
    "lat": -34.5333,
    "lng": -58.6667,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires",
    "capital": "",
    "population": 72000,
    "id": 1032720412
  },
  {
    "city": "Rugby",
    "city_ascii": "Rugby",
    "lat": 52.37,
    "lng": -1.26,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Warwickshire",
    "capital": "",
    "population": 70628,
    "id": 1826969205
  },
  {
    "city": "Darhan",
    "city_ascii": "Darhan",
    "lat": 49.4689,
    "lng": 105.9575,
    "country": "Mongolia",
    "iso2": "MN",
    "iso3": "MNG",
    "admin_name": "Darhan-Uul",
    "capital": "admin",
    "population": 74738,
    "id": 1496235602
  },
  {
    "city": "Centreville",
    "city_ascii": "Centreville",
    "lat": 38.8391,
    "lng": -77.4388,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 75452,
    "id": 1840006015
  },
  {
    "city": "Guasave",
    "city_ascii": "Guasave",
    "lat": 25.5744,
    "lng": -108.4706,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Sinaloa",
    "capital": "minor",
    "population": 71196,
    "id": 1484450749
  },
  {
    "city": "Teyateyaneng",
    "city_ascii": "Teyateyaneng",
    "lat": -29.15,
    "lng": 27.7333,
    "country": "Lesotho",
    "iso2": "LS",
    "iso3": "LSO",
    "admin_name": "Berea",
    "capital": "admin",
    "population": 75115,
    "id": 1426159118
  },
  {
    "city": "Burton upon Trent",
    "city_ascii": "Burton upon Trent",
    "lat": 52.8019,
    "lng": -1.6367,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Staffordshire",
    "capital": "",
    "population": 72299,
    "id": 1826585602
  },
  {
    "city": "Karlstad",
    "city_ascii": "Karlstad",
    "lat": 59.3671,
    "lng": 13.4999,
    "country": "Sweden",
    "iso2": "SE",
    "iso3": "SWE",
    "admin_name": "Värmland",
    "capital": "admin",
    "population": 74141,
    "id": 1752502445
  },
  {
    "city": "Uxbridge",
    "city_ascii": "Uxbridge",
    "lat": 51.5404,
    "lng": -0.4778,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hillingdon",
    "capital": "",
    "population": 70560,
    "id": 1826505308
  },
  {
    "city": "Baldwin Park",
    "city_ascii": "Baldwin Park",
    "lat": 34.0829,
    "lng": -117.9721,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 75251,
    "id": 1840019227
  },
  {
    "city": "Rioverde",
    "city_ascii": "Rioverde",
    "lat": 21.93,
    "lng": -99.98,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "San Luis Potosí",
    "capital": "minor",
    "population": 69613,
    "id": 1484341437
  },
  {
    "city": "Toledo",
    "city_ascii": "Toledo",
    "lat": 39.867,
    "lng": -4.0167,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Castille-La Mancha",
    "capital": "admin",
    "population": 74632,
    "id": 1724171411
  },
  {
    "city": "Prey Veng",
    "city_ascii": "Prey Veng",
    "lat": 11.484,
    "lng": 105.324,
    "country": "Cambodia",
    "iso2": "KH",
    "iso3": "KHM",
    "admin_name": "Prey Veng",
    "capital": "admin",
    "population": 74000,
    "id": 1116378797
  },
  {
    "city": "Caguas",
    "city_ascii": "Caguas",
    "lat": 18.2319,
    "lng": -66.0388,
    "country": "Puerto Rico",
    "iso2": "PR",
    "iso3": "PRI",
    "admin_name": "Puerto Rico",
    "capital": "",
    "population": 74980,
    "id": 1630023540
  },
  {
    "city": "Parintins",
    "city_ascii": "Parintins",
    "lat": -2.61,
    "lng": -56.74,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Amazonas",
    "capital": "",
    "population": 64428,
    "id": 1076090375
  },
  {
    "city": "Kiyosu",
    "city_ascii": "Kiyosu",
    "lat": 35.2,
    "lng": 136.85,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Aichi",
    "capital": "",
    "population": 69680,
    "id": 1392439011
  },
  {
    "city": "Hekinan",
    "city_ascii": "Hekinan",
    "lat": 34.8833,
    "lng": 137,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Aichi",
    "capital": "",
    "population": 72875,
    "id": 1392819275
  },
  {
    "city": "Crewe",
    "city_ascii": "Crewe",
    "lat": 53.099,
    "lng": -2.44,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Cheshire East",
    "capital": "",
    "population": 71722,
    "id": 1826086541
  },
  {
    "city": "Matehuala",
    "city_ascii": "Matehuala",
    "lat": 23.6528,
    "lng": -100.6444,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "San Luis Potosí",
    "capital": "minor",
    "population": 67717,
    "id": 1484682498
  },
  {
    "city": "San Andrés",
    "city_ascii": "San Andres",
    "lat": 12.5847,
    "lng": -81.7006,
    "country": "Colombia",
    "iso2": "CO",
    "iso3": "COL",
    "admin_name": "San Andrés y Providencia",
    "capital": "admin",
    "population": 71946,
    "id": 1170563968
  },
  {
    "city": "Ijuí",
    "city_ascii": "Ijui",
    "lat": -28.3878,
    "lng": -53.9147,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Rio Grande do Sul",
    "capital": "",
    "population": 71202,
    "id": 1076683915
  },
  {
    "city": "Naxçıvan",
    "city_ascii": "Naxcivan",
    "lat": 39.2089,
    "lng": 45.4122,
    "country": "Azerbaijan",
    "iso2": "AZ",
    "iso3": "AZE",
    "admin_name": "Naxçıvan",
    "capital": "admin",
    "population": 74500,
    "id": 1031943755
  },
  {
    "city": "Garissa",
    "city_ascii": "Garissa",
    "lat": -0.4569,
    "lng": 39.6583,
    "country": "Kenya",
    "iso2": "KE",
    "iso3": "KEN",
    "admin_name": "Garissa",
    "capital": "admin",
    "population": 65881,
    "id": 1404464692
  },
  {
    "city": "Manokwari",
    "city_ascii": "Manokwari",
    "lat": -0.8711,
    "lng": 134.0693,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Papua Barat",
    "capital": "admin",
    "population": 74504,
    "id": 1360451991
  },
  {
    "city": "Masvingo",
    "city_ascii": "Masvingo",
    "lat": -20.0744,
    "lng": 30.8328,
    "country": "Zimbabwe",
    "iso2": "ZW",
    "iso3": "ZWE",
    "admin_name": "Masvingo",
    "capital": "admin",
    "population": 72527,
    "id": 1716049475
  },
  {
    "city": "Vincennes",
    "city_ascii": "Vincennes",
    "lat": 48.8478,
    "lng": 2.4392,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 49891,
    "id": 1250087809
  },
  {
    "city": "Assab",
    "city_ascii": "Assab",
    "lat": 13.0078,
    "lng": 42.7411,
    "country": "Eritrea",
    "iso2": "ER",
    "iso3": "ERI",
    "admin_name": "Debubawī K’eyih Bahrī",
    "capital": "admin",
    "population": 74405,
    "id": 1232396238
  },
  {
    "city": "Gosport",
    "city_ascii": "Gosport",
    "lat": 50.7948,
    "lng": -1.1243,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hampshire",
    "capital": "",
    "population": 71529,
    "id": 1826330438
  },
  {
    "city": "Wandsworth",
    "city_ascii": "Wandsworth",
    "lat": 51.455,
    "lng": -0.192,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Wandsworth",
    "capital": "",
    "population": 61594,
    "id": 1826844924
  },
  {
    "city": "Pôrto União",
    "city_ascii": "Porto Uniao",
    "lat": -26.2396,
    "lng": -51.08,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Santa Catarina",
    "capital": "",
    "population": 71578,
    "id": 1076057988
  },
  {
    "city": "Salima",
    "city_ascii": "Salima",
    "lat": -13.7829,
    "lng": 34.4333,
    "country": "Malawi",
    "iso2": "MW",
    "iso3": "MWI",
    "admin_name": "Salima",
    "capital": "admin",
    "population": 71181,
    "id": 1454747771
  },
  {
    "city": "Tulcea",
    "city_ascii": "Tulcea",
    "lat": 45.19,
    "lng": 28.8,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Tulcea",
    "capital": "admin",
    "population": 73707,
    "id": 1642854000
  },
  {
    "city": "Birobidzhan",
    "city_ascii": "Birobidzhan",
    "lat": 48.7833,
    "lng": 132.9333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Yevreyskaya Avtonomnaya Oblast’",
    "capital": "admin",
    "population": 73623,
    "id": 1643548797
  },
  {
    "city": "Tsurugashima",
    "city_ascii": "Tsurugashima",
    "lat": 35.9344,
    "lng": 139.3931,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Saitama",
    "capital": "",
    "population": 70076,
    "id": 1392196288
  },
  {
    "city": "Yoshikawa",
    "city_ascii": "Yoshikawa",
    "lat": 35.8911,
    "lng": 139.8414,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Saitama",
    "capital": "",
    "population": 71763,
    "id": 1392319161
  },
  {
    "city": "Cabadbaran",
    "city_ascii": "Cabadbaran",
    "lat": 9.1228,
    "lng": 125.5346,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Agusan del Norte",
    "capital": "admin",
    "population": 73639,
    "id": 1608889253
  },
  {
    "city": "Kirtipur",
    "city_ascii": "Kirtipur",
    "lat": 27.6667,
    "lng": 85.2833,
    "country": "Nepal",
    "iso2": "NP",
    "iso3": "NPL",
    "admin_name": "Bāgmatī",
    "capital": "",
    "population": 65602,
    "id": 1524402867
  },
  {
    "city": "Irecê",
    "city_ascii": "Irece",
    "lat": -11.3042,
    "lng": -41.8558,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Bahia",
    "capital": "",
    "population": 63626,
    "id": 1076348562
  },
  {
    "city": "Yawata-shimizui",
    "city_ascii": "Yawata-shimizui",
    "lat": 34.8756,
    "lng": 135.7078,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Kyōto",
    "capital": "",
    "population": 70931,
    "id": 1392797096
  },
  {
    "city": "Frontera",
    "city_ascii": "Frontera",
    "lat": 26.926,
    "lng": -101.449,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Coahuila de Zaragoza",
    "capital": "minor",
    "population": 70160,
    "id": 1484811972
  },
  {
    "city": "Neuilly-sur-Seine",
    "city_ascii": "Neuilly-sur-Seine",
    "lat": 48.8881,
    "lng": 2.2686,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 60361,
    "id": 1250765188
  },
  {
    "city": "Farāh",
    "city_ascii": "Farah",
    "lat": 32.3436,
    "lng": 62.1194,
    "country": "Afghanistan",
    "iso2": "AF",
    "iso3": "AFG",
    "admin_name": "Farāh",
    "capital": "admin",
    "population": 73647,
    "id": 1004523791
  },
  {
    "city": "Warzat",
    "city_ascii": "Warzat",
    "lat": 30.9167,
    "lng": -6.9167,
    "country": "Morocco",
    "iso2": "MA",
    "iso3": "MAR",
    "admin_name": "Drâa-Tafilalet",
    "capital": "",
    "population": 71067,
    "id": 1504381507
  },
  {
    "city": "Finchley",
    "city_ascii": "Finchley",
    "lat": 51.599,
    "lng": -0.187,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Barnet",
    "capital": "",
    "population": 65812,
    "id": 1826882560
  },
  {
    "city": "Monastir",
    "city_ascii": "Monastir",
    "lat": 35.7307,
    "lng": 10.7673,
    "country": "Tunisia",
    "iso2": "TN",
    "iso3": "TUN",
    "admin_name": "Monastir",
    "capital": "admin",
    "population": 71546,
    "id": 1788553334
  },
  {
    "city": "Camden",
    "city_ascii": "Camden",
    "lat": 39.9362,
    "lng": -75.1073,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 73562,
    "id": 1840001507
  },
  {
    "city": "Evanston",
    "city_ascii": "Evanston",
    "lat": 42.0463,
    "lng": -87.6942,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 73473,
    "id": 1840008129
  },
  {
    "city": "Havířov",
    "city_ascii": "Havirov",
    "lat": 49.7778,
    "lng": 18.4228,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Moravskoslezský Kraj",
    "capital": "",
    "population": 71200,
    "id": 1203173875
  },
  {
    "city": "Skien",
    "city_ascii": "Skien",
    "lat": 59.2096,
    "lng": 9.609,
    "country": "Norway",
    "iso2": "NO",
    "iso3": "NOR",
    "admin_name": "Telemark",
    "capital": "admin",
    "population": 73330,
    "id": 1578807356
  },
  {
    "city": "Noisy-le-Grand",
    "city_ascii": "Noisy-le-Grand",
    "lat": 48.8478,
    "lng": 2.5528,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 68183,
    "id": 1250182208
  },
  {
    "city": "Kismaayo",
    "city_ascii": "Kismaayo",
    "lat": -0.3603,
    "lng": 42.5489,
    "country": "Somalia",
    "iso2": "SO",
    "iso3": "SOM",
    "admin_name": "Jubbada Hoose",
    "capital": "admin",
    "population": 73300,
    "id": 1706091701
  },
  {
    "city": "San Juan",
    "city_ascii": "San Juan",
    "lat": 18.807,
    "lng": -71.229,
    "country": "Dominican Republic",
    "iso2": "DO",
    "iso3": "DOM",
    "admin_name": "El Valle",
    "capital": "admin",
    "population": 72950,
    "id": 1214608846
  },
  {
    "city": "Debre Mark’os",
    "city_ascii": "Debre Mark'os",
    "lat": 10.34,
    "lng": 37.72,
    "country": "Ethiopia",
    "iso2": "ET",
    "iso3": "ETH",
    "admin_name": "Āmara",
    "capital": "",
    "population": 70758,
    "id": 1231943444
  },
  {
    "city": "Dabou",
    "city_ascii": "Dabou",
    "lat": 5.3256,
    "lng": -4.3767,
    "country": "Côte D’Ivoire",
    "iso2": "CI",
    "iso3": "CIV",
    "admin_name": "Lagunes",
    "capital": "admin",
    "population": 72913,
    "id": 1384274793
  },
  {
    "city": "Şa‘dah",
    "city_ascii": "Sa`dah",
    "lat": 16.9358,
    "lng": 43.7644,
    "country": "Yemen",
    "iso2": "YE",
    "iso3": "YEM",
    "admin_name": "Şa‘dah",
    "capital": "admin",
    "population": 70203,
    "id": 1887513055
  },
  {
    "city": "Inverness",
    "city_ascii": "Inverness",
    "lat": 57.4781,
    "lng": -4.2233,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Highland",
    "capital": "",
    "population": 70000,
    "id": 1826161019
  },
  {
    "city": "Feltham",
    "city_ascii": "Feltham",
    "lat": 51.4496,
    "lng": -0.4089,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hounslow",
    "capital": "",
    "population": 63368,
    "id": 1826165560
  },
  {
    "city": "Lappeenranta",
    "city_ascii": "Lappeenranta",
    "lat": 61.0583,
    "lng": 28.1861,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Etelä-Karjala",
    "capital": "admin",
    "population": 72875,
    "id": 1246492910
  },
  {
    "city": "Thohoyandou",
    "city_ascii": "Thohoyandou",
    "lat": -22.95,
    "lng": 30.4833,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "Limpopo",
    "capital": "",
    "population": 69453,
    "id": 1710219075
  },
  {
    "city": "Chingford",
    "city_ascii": "Chingford",
    "lat": 51.631,
    "lng": 0.016,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Waltham Forest",
    "capital": "",
    "population": 66211,
    "id": 1826861021
  },
  {
    "city": "New Britain",
    "city_ascii": "New Britain",
    "lat": 41.6758,
    "lng": -72.7862,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 72495,
    "id": 1840004774
  },
  {
    "city": "Ārba Minch’",
    "city_ascii": "Arba Minch'",
    "lat": 6.04,
    "lng": 37.55,
    "country": "Ethiopia",
    "iso2": "ET",
    "iso3": "ETH",
    "admin_name": "YeDebub Bihēroch Bihēreseboch na Hizboch",
    "capital": "",
    "population": 69622,
    "id": 1231302134
  },
  {
    "city": "Abancay",
    "city_ascii": "Abancay",
    "lat": -13.6333,
    "lng": -72.8833,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "Apurímac",
    "capital": "admin",
    "population": 72277,
    "id": 1604782074
  },
  {
    "city": "Toyoake",
    "city_ascii": "Toyoake",
    "lat": 35.0625,
    "lng": 137.0089,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Aichi",
    "capital": "",
    "population": 69448,
    "id": 1392203165
  },
  {
    "city": "Veliko Tarnovo",
    "city_ascii": "Veliko Tarnovo",
    "lat": 43.0822,
    "lng": 25.6317,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Veliko Tarnovo",
    "capital": "admin",
    "population": 72207,
    "id": 1100134696
  },
  {
    "city": "Ivry-sur-Seine",
    "city_ascii": "Ivry-sur-Seine",
    "lat": 48.8078,
    "lng": 2.3747,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 62052,
    "id": 1250904640
  },
  {
    "city": "Wilde",
    "city_ascii": "Wilde",
    "lat": -34.7,
    "lng": -58.3167,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires",
    "capital": "",
    "population": 65881,
    "id": 1032256650
  },
  {
    "city": "Pawtucket",
    "city_ascii": "Pawtucket",
    "lat": 41.8744,
    "lng": -71.3743,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Rhode Island",
    "capital": "",
    "population": 72117,
    "id": 1840003285
  },
  {
    "city": "Čačak",
    "city_ascii": "Cacak",
    "lat": 43.8914,
    "lng": 20.3497,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Čačak",
    "capital": "admin",
    "population": 71883,
    "id": 1688216463
  },
  {
    "city": "La Chorrera",
    "city_ascii": "La Chorrera",
    "lat": 8.8792,
    "lng": -79.7822,
    "country": "Panama",
    "iso2": "PA",
    "iso3": "PAN",
    "admin_name": "Panamá Oeste",
    "capital": "admin",
    "population": 68896,
    "id": 1591982720
  },
  {
    "city": "Washington",
    "city_ascii": "Washington",
    "lat": 54.9,
    "lng": -1.52,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Sunderland",
    "capital": "",
    "population": 67085,
    "id": 1826149081
  },
  {
    "city": "Lauderhill",
    "city_ascii": "Lauderhill",
    "lat": 26.1605,
    "lng": -80.2242,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 71868,
    "id": 1840015146
  },
  {
    "city": "Balsas",
    "city_ascii": "Balsas",
    "lat": -7.5325,
    "lng": -46.0356,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Maranhão",
    "capital": "",
    "population": 68056,
    "id": 1076067021
  },
  {
    "city": "Matara",
    "city_ascii": "Matara",
    "lat": 5.949,
    "lng": 80.5428,
    "country": "Sri Lanka",
    "iso2": "LK",
    "iso3": "LKA",
    "admin_name": "Southern",
    "capital": "",
    "population": 68244,
    "id": 1144127163
  },
  {
    "city": "Al Qūşīyah",
    "city_ascii": "Al Qusiyah",
    "lat": 27.4403,
    "lng": 30.8183,
    "country": "Egypt",
    "iso2": "EG",
    "iso3": "EGY",
    "admin_name": "Asyūţ",
    "capital": "",
    "population": 69388,
    "id": 1818093562
  },
  {
    "city": "Szolnok",
    "city_ascii": "Szolnok",
    "lat": 47.1747,
    "lng": 20.1764,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Jász-Nagykun-Szolnok",
    "capital": "admin",
    "population": 71285,
    "id": 1348287339
  },
  {
    "city": "Mamou",
    "city_ascii": "Mamou",
    "lat": 10.3736,
    "lng": -12.0847,
    "country": "Guinea",
    "iso2": "GN",
    "iso3": "GIN",
    "admin_name": "Mamou",
    "capital": "admin",
    "population": 71153,
    "id": 1324990909
  },
  {
    "city": "Slatina",
    "city_ascii": "Slatina",
    "lat": 44.4297,
    "lng": 24.3642,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Olt",
    "capital": "admin",
    "population": 70293,
    "id": 1642577360
  },
  {
    "city": "Youssoufia",
    "city_ascii": "Youssoufia",
    "lat": 32.25,
    "lng": -8.53,
    "country": "Morocco",
    "iso2": "MA",
    "iso3": "MAR",
    "admin_name": "Marrakech-Safi",
    "capital": "",
    "population": 67628,
    "id": 1504459034
  },
  {
    "city": "Bitlis",
    "city_ascii": "Bitlis",
    "lat": 38.4,
    "lng": 42.1167,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Bitlis",
    "capital": "admin",
    "population": 71501,
    "id": 1792490436
  },
  {
    "city": "Ajaccio",
    "city_ascii": "Ajaccio",
    "lat": 41.9267,
    "lng": 8.7369,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Corsica",
    "capital": "admin",
    "population": 70659,
    "id": 1250622666
  },
  {
    "city": "Maidenhead",
    "city_ascii": "Maidenhead",
    "lat": 51.5217,
    "lng": -0.7177,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Windsor and Maidenhead",
    "capital": "",
    "population": 69309,
    "id": 1826979451
  },
  {
    "city": "Kasese",
    "city_ascii": "Kasese",
    "lat": 0.23,
    "lng": 29.9883,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Kasese",
    "capital": "admin",
    "population": 67269,
    "id": 1800924936
  },
  {
    "city": "Phitsanulok",
    "city_ascii": "Phitsanulok",
    "lat": 16.8158,
    "lng": 100.2636,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Phitsanulok",
    "capital": "admin",
    "population": 70871,
    "id": 1764140981
  },
  {
    "city": "Comonfort",
    "city_ascii": "Comonfort",
    "lat": 20.7167,
    "lng": -100.7667,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Guanajuato",
    "capital": "minor",
    "population": 67642,
    "id": 1484359235
  },
  {
    "city": "Misantla",
    "city_ascii": "Misantla",
    "lat": 19.9333,
    "lng": -96.85,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Veracruz",
    "capital": "minor",
    "population": 64249,
    "id": 1484744150
  },
  {
    "city": "Zinjibār",
    "city_ascii": "Zinjibar",
    "lat": 13.1283,
    "lng": 45.3803,
    "country": "Yemen",
    "iso2": "YE",
    "iso3": "YEM",
    "admin_name": "Abyan",
    "capital": "admin",
    "population": 70801,
    "id": 1887645956
  },
  {
    "city": "Oroquieta",
    "city_ascii": "Oroquieta",
    "lat": 8.4833,
    "lng": 123.8,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Misamis Occidental",
    "capital": "admin",
    "population": 70757,
    "id": 1608239509
  },
  {
    "city": "New Westminster",
    "city_ascii": "New Westminster",
    "lat": 49.2069,
    "lng": -122.9111,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "British Columbia",
    "capital": "",
    "population": 70996,
    "id": 1124196524
  },
  {
    "city": "Fujiidera",
    "city_ascii": "Fujiidera",
    "lat": 34.5747,
    "lng": 135.5975,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Ōsaka",
    "capital": "",
    "population": 63814,
    "id": 1392763646
  },
  {
    "city": "Kumanovo",
    "city_ascii": "Kumanovo",
    "lat": 42.1403,
    "lng": 21.7136,
    "country": "Macedonia",
    "iso2": "MK",
    "iso3": "MKD",
    "admin_name": "Kumanovo",
    "capital": "admin",
    "population": 70842,
    "id": 1807301219
  },
  {
    "city": "Giv‘atayim",
    "city_ascii": "Giv`atayim",
    "lat": 32.0697,
    "lng": 34.8117,
    "country": "Israel",
    "iso2": "IL",
    "iso3": "ISR",
    "admin_name": "Tel Aviv",
    "capital": "",
    "population": 59518,
    "id": 1376739486
  },
  {
    "city": "Valle Hermoso",
    "city_ascii": "Valle Hermoso",
    "lat": 25.6736,
    "lng": -97.8144,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Tamaulipas",
    "capital": "minor",
    "population": 68573,
    "id": 1484479299
  },
  {
    "city": "Mindelo",
    "city_ascii": "Mindelo",
    "lat": 16.8914,
    "lng": -24.988,
    "country": "Cabo Verde",
    "iso2": "CV",
    "iso3": "CPV",
    "admin_name": "São Vicente",
    "capital": "admin",
    "population": 70468,
    "id": 1132180389
  },
  {
    "city": "Chiang Rai",
    "city_ascii": "Chiang Rai",
    "lat": 19.9094,
    "lng": 99.8275,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Chiang Rai",
    "capital": "admin",
    "population": 70610,
    "id": 1764748484
  },
  {
    "city": "Āksum",
    "city_ascii": "Aksum",
    "lat": 14.1284,
    "lng": 38.7173,
    "country": "Ethiopia",
    "iso2": "ET",
    "iso3": "ETH",
    "admin_name": "Tigray",
    "capital": "",
    "population": 66800,
    "id": 1231573651
  },
  {
    "city": "Benalmádena",
    "city_ascii": "Benalmadena",
    "lat": 36.5969,
    "lng": -4.5535,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Andalusia",
    "capital": "",
    "population": 68128,
    "id": 1724108022
  },
  {
    "city": "Wimbledon",
    "city_ascii": "Wimbledon",
    "lat": 51.422,
    "lng": -0.208,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Merton",
    "capital": "",
    "population": 68187,
    "id": 1826386301
  },
  {
    "city": "Sunyani",
    "city_ascii": "Sunyani",
    "lat": 7.336,
    "lng": -2.336,
    "country": "Ghana",
    "iso2": "GH",
    "iso3": "GHA",
    "admin_name": "Brong-Ahafo",
    "capital": "admin",
    "population": 70299,
    "id": 1288679588
  },
  {
    "city": "Liepāja",
    "city_ascii": "Liepaja",
    "lat": 56.5083,
    "lng": 21.0111,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Liepāja",
    "capital": "admin",
    "population": 69443,
    "id": 1428511842
  },
  {
    "city": "Loughborough",
    "city_ascii": "Loughborough",
    "lat": 52.7705,
    "lng": -1.2046,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Leicestershire",
    "capital": "",
    "population": 66611,
    "id": 1826194258
  },
  {
    "city": "Colonia del Sol",
    "city_ascii": "Colonia del Sol",
    "lat": 22.9125,
    "lng": -109.9208,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Baja California Sur",
    "capital": "",
    "population": 64055,
    "id": 1484445555
  },
  {
    "city": "Courcouronnes",
    "city_ascii": "Courcouronnes",
    "lat": 48.6239,
    "lng": 2.4294,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 67967,
    "id": 1250579473
  },
  {
    "city": "Sagaing",
    "city_ascii": "Sagaing",
    "lat": 21.8822,
    "lng": 95.9786,
    "country": "Burma",
    "iso2": "MM",
    "iso3": "MMR",
    "admin_name": "Sagaing",
    "capital": "admin",
    "population": 69917,
    "id": 1104151403
  },
  {
    "city": "Wilmington",
    "city_ascii": "Wilmington",
    "lat": 39.7415,
    "lng": -75.5413,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Delaware",
    "capital": "",
    "population": 70166,
    "id": 1840005579
  },
  {
    "city": "Thakhèk",
    "city_ascii": "Thakhek",
    "lat": 17.4,
    "lng": 104.8,
    "country": "Laos",
    "iso2": "LA",
    "iso3": "LAO",
    "admin_name": "Khammouan",
    "capital": "admin",
    "population": 70000,
    "id": 1418367677
  },
  {
    "city": "Nizwá",
    "city_ascii": "Nizwa",
    "lat": 22.9333,
    "lng": 57.5333,
    "country": "Oman",
    "iso2": "OM",
    "iso3": "OMN",
    "admin_name": "Ad Dākhilīyah",
    "capital": "admin",
    "population": 70000,
    "id": 1512180300
  },
  {
    "city": "Tynemouth",
    "city_ascii": "Tynemouth",
    "lat": 55.017,
    "lng": -1.423,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "North Tyneside",
    "capital": "",
    "population": 67519,
    "id": 1826445971
  },
  {
    "city": "Vushtrri",
    "city_ascii": "Vushtrri",
    "lat": 42.8222,
    "lng": 20.9694,
    "country": "Kosovo",
    "iso2": "XK",
    "iso3": "XKS",
    "admin_name": "Vushtrri",
    "capital": "admin",
    "population": 69870,
    "id": 1901107642
  },
  {
    "city": "Walsall",
    "city_ascii": "Walsall",
    "lat": 52.58,
    "lng": -1.98,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Walsall",
    "capital": "",
    "population": 67594,
    "id": 1826655696
  },
  {
    "city": "Moquegua",
    "city_ascii": "Moquegua",
    "lat": -17.2,
    "lng": -70.9333,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "Moquegua",
    "capital": "admin",
    "population": 69882,
    "id": 1604230427
  },
  {
    "city": "Itapeva",
    "city_ascii": "Itapeva",
    "lat": -23.9822,
    "lng": -48.8756,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 62957,
    "id": 1076767110
  },
  {
    "city": "Lynwood",
    "city_ascii": "Lynwood",
    "lat": 33.924,
    "lng": -118.2017,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 69887,
    "id": 1840020492
  },
  {
    "city": "Castellammare di Stabia",
    "city_ascii": "Castellammare di Stabia",
    "lat": 40.7,
    "lng": 14.4833,
    "country": "Italy",
    "iso2": "IT",
    "iso3": "ITA",
    "admin_name": "Campania",
    "capital": "",
    "population": 66164,
    "id": 1380725803
  },
  {
    "city": "Rochester",
    "city_ascii": "Rochester",
    "lat": 51.375,
    "lng": 0.5,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Medway",
    "capital": "",
    "population": 62982,
    "id": 1826559634
  },
  {
    "city": "Georgiyevsk",
    "city_ascii": "Georgiyevsk",
    "lat": 44.15,
    "lng": 43.4667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Stavropol’skiy Kray",
    "capital": "",
    "population": 67054,
    "id": 1643279802
  },
  {
    "city": "Vénissieux",
    "city_ascii": "Venissieux",
    "lat": 45.6978,
    "lng": 4.8867,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Auvergne-Rhône-Alpes",
    "capital": "",
    "population": 65894,
    "id": 1250755963
  },
  {
    "city": "Passaic",
    "city_ascii": "Passaic",
    "lat": 40.8574,
    "lng": -74.1282,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 69703,
    "id": 1840000837
  },
  {
    "city": "Guaynabo",
    "city_ascii": "Guaynabo",
    "lat": 18.3839,
    "lng": -66.1134,
    "country": "Puerto Rico",
    "iso2": "PR",
    "iso3": "PRI",
    "admin_name": "Puerto Rico",
    "capital": "",
    "population": 69697,
    "id": 1630023575
  },
  {
    "city": "Valença",
    "city_ascii": "Valenca",
    "lat": -13.3703,
    "lng": -39.0731,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Bahia",
    "capital": "",
    "population": 63231,
    "id": 1076455406
  },
  {
    "city": "Santa Inês",
    "city_ascii": "Santa Ines",
    "lat": -3.6667,
    "lng": -45.38,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Maranhão",
    "capital": "",
    "population": 67424,
    "id": 1076053036
  },
  {
    "city": "Vratsa",
    "city_ascii": "Vratsa",
    "lat": 43.2,
    "lng": 23.55,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Vratsa",
    "capital": "admin",
    "population": 69493,
    "id": 1100261281
  },
  {
    "city": "Putrajaya",
    "city_ascii": "Putrajaya",
    "lat": 2.914,
    "lng": 101.7019,
    "country": "Malaysia",
    "iso2": "MY",
    "iso3": "MYS",
    "admin_name": "Putrajaya",
    "capital": "admin",
    "population": 67964,
    "id": 1458000206
  },
  {
    "city": "Santo Ângelo",
    "city_ascii": "Santo Angelo",
    "lat": -28.3,
    "lng": -54.28,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Rio Grande do Sul",
    "capital": "",
    "population": 65420,
    "id": 1076113692
  },
  {
    "city": "Cergy",
    "city_ascii": "Cergy",
    "lat": 49.0361,
    "lng": 2.0631,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "minor",
    "population": 65177,
    "id": 1250553439
  },
  {
    "city": "Debre Birhan",
    "city_ascii": "Debre Birhan",
    "lat": 9.6804,
    "lng": 39.53,
    "country": "Ethiopia",
    "iso2": "ET",
    "iso3": "ETH",
    "admin_name": "Āmara",
    "capital": "",
    "population": 65231,
    "id": 1231777741
  },
  {
    "city": "Borongan",
    "city_ascii": "Borongan",
    "lat": 11.6,
    "lng": 125.4333,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Eastern Samar",
    "capital": "admin",
    "population": 69297,
    "id": 1608990192
  },
  {
    "city": "Itapetinga",
    "city_ascii": "Itapetinga",
    "lat": -15.2489,
    "lng": -40.2478,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Bahia",
    "capital": "",
    "population": 59721,
    "id": 1076005350
  },
  {
    "city": "Playas de Rosarito",
    "city_ascii": "Playas de Rosarito",
    "lat": 32.3422,
    "lng": -117.0561,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Baja California",
    "capital": "minor",
    "population": 65278,
    "id": 1484522993
  },
  {
    "city": "Acton",
    "city_ascii": "Acton",
    "lat": 51.5135,
    "lng": -0.2707,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Ealing",
    "capital": "",
    "population": 62480,
    "id": 1826793320
  },
  {
    "city": "Bouira",
    "city_ascii": "Bouira",
    "lat": 36.3783,
    "lng": 3.8925,
    "country": "Algeria",
    "iso2": "DZ",
    "iso3": "DZA",
    "admin_name": "Bouira",
    "capital": "admin",
    "population": 68545,
    "id": 1012006499
  },
  {
    "city": "Antony",
    "city_ascii": "Antony",
    "lat": 48.7539,
    "lng": 2.2975,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "minor",
    "population": 62570,
    "id": 1250570123
  },
  {
    "city": "Mitcham",
    "city_ascii": "Mitcham",
    "lat": 51.4009,
    "lng": -0.1517,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Merton",
    "capital": "",
    "population": 63393,
    "id": 1826947069
  },
  {
    "city": "San José del Guaviare",
    "city_ascii": "San Jose del Guaviare",
    "lat": 2.5653,
    "lng": -72.6386,
    "country": "Colombia",
    "iso2": "CO",
    "iso3": "COL",
    "admin_name": "Guaviare",
    "capital": "admin",
    "population": 68878,
    "id": 1170839384
  },
  {
    "city": "Ponta Delgada",
    "city_ascii": "Ponta Delgada",
    "lat": 37.74,
    "lng": -25.67,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Azores",
    "capital": "admin",
    "population": 68809,
    "id": 1620456537
  },
  {
    "city": "Kitamoto",
    "city_ascii": "Kitamoto",
    "lat": 36.0269,
    "lng": 139.53,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Saitama",
    "capital": "",
    "population": 65478,
    "id": 1392754858
  },
  {
    "city": "Gävle",
    "city_ascii": "Gavle",
    "lat": 60.667,
    "lng": 17.1666,
    "country": "Sweden",
    "iso2": "SE",
    "iso3": "SWE",
    "admin_name": "Gävleborg",
    "capital": "admin",
    "population": 68635,
    "id": 1752586931
  },
  {
    "city": "Pantin",
    "city_ascii": "Pantin",
    "lat": 48.8966,
    "lng": 2.4017,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 57482,
    "id": 1250209071
  },
  {
    "city": "L’Aquila",
    "city_ascii": "L'Aquila",
    "lat": 42.3504,
    "lng": 13.39,
    "country": "Italy",
    "iso2": "IT",
    "iso3": "ITA",
    "admin_name": "Abruzzo",
    "capital": "admin",
    "population": 68503,
    "id": 1380218474
  },
  {
    "city": "Wrecsam",
    "city_ascii": "Wrecsam",
    "lat": 53.046,
    "lng": -2.993,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Wrexham",
    "capital": "",
    "population": 65692,
    "id": 1826879983
  },
  {
    "city": "Gurupi",
    "city_ascii": "Gurupi",
    "lat": -11.7292,
    "lng": -49.0686,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Tocantins",
    "capital": "",
    "population": 64789,
    "id": 1076649238
  },
  {
    "city": "Villa Domínico",
    "city_ascii": "Villa Dominico",
    "lat": -34.6917,
    "lng": -58.3333,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires",
    "capital": "",
    "population": 62315,
    "id": 1032866743
  },
  {
    "city": "Şəki",
    "city_ascii": "Saki",
    "lat": 41.1919,
    "lng": 47.1706,
    "country": "Azerbaijan",
    "iso2": "AZ",
    "iso3": "AZE",
    "admin_name": "Şəki",
    "capital": "admin",
    "population": 68360,
    "id": 1031619846
  },
  {
    "city": "Marsá Maţrūḩ",
    "city_ascii": "Marsa Matruh",
    "lat": 31.3333,
    "lng": 27.2333,
    "country": "Egypt",
    "iso2": "EG",
    "iso3": "EGY",
    "admin_name": "Maţrūḩ",
    "capital": "admin",
    "population": 68339,
    "id": 1818661611
  },
  {
    "city": "Waterlooville",
    "city_ascii": "Waterlooville",
    "lat": 50.88,
    "lng": -1.03,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hampshire",
    "capital": "",
    "population": 64350,
    "id": 1826290265
  },
  {
    "city": "Buynaksk",
    "city_ascii": "Buynaksk",
    "lat": 42.8167,
    "lng": 47.1167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Dagestan",
    "capital": "minor",
    "population": 65080,
    "id": 1643980922
  },
  {
    "city": "Hämeenlinna",
    "city_ascii": "Hameenlinna",
    "lat": 61,
    "lng": 24.4414,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Kanta-Häme",
    "capital": "admin",
    "population": 68011,
    "id": 1246026891
  },
  {
    "city": "Gaithersburg",
    "city_ascii": "Gaithersburg",
    "lat": 39.1346,
    "lng": -77.2132,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 67985,
    "id": 1840005848
  },
  {
    "city": "Union City",
    "city_ascii": "Union City",
    "lat": 40.7674,
    "lng": -74.0323,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 67982,
    "id": 1840001034
  },
  {
    "city": "Picos",
    "city_ascii": "Picos",
    "lat": -7.0769,
    "lng": -41.4669,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Piauí",
    "capital": "",
    "population": 57495,
    "id": 1076752928
  },
  {
    "city": "Scarborough",
    "city_ascii": "Scarborough",
    "lat": 54.2773,
    "lng": -0.4017,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "North Yorkshire",
    "capital": "",
    "population": 61749,
    "id": 1826723231
  },
  {
    "city": "Caçador",
    "city_ascii": "Cacador",
    "lat": -26.7753,
    "lng": -51.015,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Santa Catarina",
    "capital": "",
    "population": 63726,
    "id": 1076703793
  },
  {
    "city": "South San Francisco",
    "city_ascii": "South San Francisco",
    "lat": 37.6536,
    "lng": -122.4197,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 67789,
    "id": 1840021552
  },
  {
    "city": "Timbaúba",
    "city_ascii": "Timbauba",
    "lat": -7.5053,
    "lng": -35.3183,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Pernambuco",
    "capital": "",
    "population": 57534,
    "id": 1076430214
  },
  {
    "city": "Curvelo",
    "city_ascii": "Curvelo",
    "lat": -18.7564,
    "lng": -44.4308,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Minas Gerais",
    "capital": "",
    "population": 63954,
    "id": 1076253887
  },
  {
    "city": "Manacapuru",
    "city_ascii": "Manacapuru",
    "lat": -3.2997,
    "lng": -60.6206,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Amazonas",
    "capital": "",
    "population": 59102,
    "id": 1076484341
  },
  {
    "city": "Tecate",
    "city_ascii": "Tecate",
    "lat": 32.5667,
    "lng": -116.6331,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Baja California",
    "capital": "minor",
    "population": 64764,
    "id": 1484547809
  },
  {
    "city": "Lào Cai",
    "city_ascii": "Lao Cai",
    "lat": 22.4194,
    "lng": 103.995,
    "country": "Vietnam",
    "iso2": "VN",
    "iso3": "VNM",
    "admin_name": "Lào Cai",
    "capital": "admin",
    "population": 67206,
    "id": 1704290986
  },
  {
    "city": "Farnborough",
    "city_ascii": "Farnborough",
    "lat": 51.29,
    "lng": -0.75,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hampshire",
    "capital": "",
    "population": 65034,
    "id": 1826383788
  },
  {
    "city": "Vaasa",
    "city_ascii": "Vaasa",
    "lat": 63.1,
    "lng": 21.6167,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Pohjanmaa",
    "capital": "admin",
    "population": 67619,
    "id": 1246734710
  },
  {
    "city": "Molepolole",
    "city_ascii": "Molepolole",
    "lat": -24.4066,
    "lng": 25.4951,
    "country": "Botswana",
    "iso2": "BW",
    "iso3": "BWA",
    "admin_name": "Kweneng",
    "capital": "admin",
    "population": 67598,
    "id": 1072560529
  },
  {
    "city": "Potenza",
    "city_ascii": "Potenza",
    "lat": 40.6333,
    "lng": 15.8,
    "country": "Italy",
    "iso2": "IT",
    "iso3": "ITA",
    "admin_name": "Basilicata",
    "capital": "admin",
    "population": 67211,
    "id": 1380649430
  },
  {
    "city": "Dimbokro",
    "city_ascii": "Dimbokro",
    "lat": 6.6505,
    "lng": -4.71,
    "country": "Côte D’Ivoire",
    "iso2": "CI",
    "iso3": "CIV",
    "admin_name": "Lacs",
    "capital": "admin",
    "population": 67349,
    "id": 1384235566
  },
  {
    "city": "El Bayadh",
    "city_ascii": "El Bayadh",
    "lat": 33.6904,
    "lng": 1.01,
    "country": "Algeria",
    "iso2": "DZ",
    "iso3": "DZA",
    "admin_name": "El Bayadh",
    "capital": "admin",
    "population": 67413,
    "id": 1012906892
  },
  {
    "city": "Valle de Bravo",
    "city_ascii": "Valle de Bravo",
    "lat": 19.1925,
    "lng": -100.1306,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "México",
    "capital": "minor",
    "population": 61559,
    "id": 1484105173
  },
  {
    "city": "Mount Vernon",
    "city_ascii": "Mount Vernon",
    "lat": 40.9136,
    "lng": -73.8291,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 67345,
    "id": 1840000781
  },
  {
    "city": "Amozoc",
    "city_ascii": "Amozoc",
    "lat": 19.0333,
    "lng": -98.05,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Puebla",
    "capital": "minor",
    "population": 60517,
    "id": 1484015487
  },
  {
    "city": "Twickenham",
    "city_ascii": "Twickenham",
    "lat": 51.449,
    "lng": -0.337,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Richmond upon Thames",
    "capital": "",
    "population": 62148,
    "id": 1826827915
  },
  {
    "city": "Stourbridge",
    "city_ascii": "Stourbridge",
    "lat": 52.4575,
    "lng": -2.1479,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Dudley",
    "capital": "",
    "population": 63298,
    "id": 1826879996
  },
  {
    "city": "Fryazino",
    "city_ascii": "Fryazino",
    "lat": 55.95,
    "lng": 38.05,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Moskovskaya Oblast’",
    "capital": "",
    "population": 60437,
    "id": 1643988572
  },
  {
    "city": "Linares",
    "city_ascii": "Linares",
    "lat": 24.8597,
    "lng": -99.5679,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Nuevo León",
    "capital": "minor",
    "population": 63104,
    "id": 1484504671
  },
  {
    "city": "Streatham",
    "city_ascii": "Streatham",
    "lat": 51.4279,
    "lng": -0.1235,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Lambeth",
    "capital": "",
    "population": 58055,
    "id": 1826845701
  },
  {
    "city": "Barking",
    "city_ascii": "Barking",
    "lat": 51.54,
    "lng": 0.08,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Barking and Dagenham",
    "capital": "",
    "population": 59068,
    "id": 1826566262
  },
  {
    "city": "Épinay-sur-Seine",
    "city_ascii": "Epinay-sur-Seine",
    "lat": 48.9553,
    "lng": 2.3092,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 55084,
    "id": 1250904959
  },
  {
    "city": "Bolgatanga",
    "city_ascii": "Bolgatanga",
    "lat": 10.7856,
    "lng": -0.8514,
    "country": "Ghana",
    "iso2": "GH",
    "iso3": "GHA",
    "admin_name": "Upper East",
    "capital": "admin",
    "population": 66685,
    "id": 1288943371
  },
  {
    "city": "Tomigusuku",
    "city_ascii": "Tomigusuku",
    "lat": 26.1772,
    "lng": 127.6811,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Okinawa",
    "capital": "",
    "population": 63700,
    "id": 1392091635
  },
  {
    "city": "Sabinas",
    "city_ascii": "Sabinas",
    "lat": 27.86,
    "lng": -101.12,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Coahuila de Zaragoza",
    "capital": "minor",
    "population": 63522,
    "id": 1484526560
  },
  {
    "city": "Prilep",
    "city_ascii": "Prilep",
    "lat": 41.3464,
    "lng": 21.5542,
    "country": "Macedonia",
    "iso2": "MK",
    "iso3": "MKD",
    "admin_name": "Prilep",
    "capital": "admin",
    "population": 66817,
    "id": 1807551778
  },
  {
    "city": "Sokhumi",
    "city_ascii": "Sokhumi",
    "lat": 43.0033,
    "lng": 41.0153,
    "country": "Georgia",
    "iso2": "GE",
    "iso3": "GEO",
    "admin_name": "Abkhazia",
    "capital": "admin",
    "population": 64441,
    "id": 1268394475
  },
  {
    "city": "Redondo Beach",
    "city_ascii": "Redondo Beach",
    "lat": 33.8574,
    "lng": -118.3766,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 66749,
    "id": 1840020509
  },
  {
    "city": "Kraljevo",
    "city_ascii": "Kraljevo",
    "lat": 43.7234,
    "lng": 20.687,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Kraljevo",
    "capital": "admin",
    "population": 66688,
    "id": 1688625234
  },
  {
    "city": "Tamarac",
    "city_ascii": "Tamarac",
    "lat": 26.2056,
    "lng": -80.2542,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 66721,
    "id": 1840016001
  },
  {
    "city": "Novi Pazar",
    "city_ascii": "Novi Pazar",
    "lat": 43.15,
    "lng": 20.5167,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Novi Pazar",
    "capital": "admin",
    "population": 66527,
    "id": 1688435541
  },
  {
    "city": "Bayburt",
    "city_ascii": "Bayburt",
    "lat": 40.2546,
    "lng": 40.226,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Bayburt",
    "capital": "admin",
    "population": 66633,
    "id": 1792400872
  },
  {
    "city": "Nālūt",
    "city_ascii": "Nalut",
    "lat": 31.8804,
    "lng": 10.97,
    "country": "Libya",
    "iso2": "LY",
    "iso3": "LBY",
    "admin_name": "Nālūt",
    "capital": "admin",
    "population": 66609,
    "id": 1434550258
  },
  {
    "city": "Tatabánya",
    "city_ascii": "Tatabanya",
    "lat": 47.5862,
    "lng": 18.3949,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Komárom-Esztergom",
    "capital": "admin",
    "population": 65845,
    "id": 1348450358
  },
  {
    "city": "La Seyne-sur-Mer",
    "city_ascii": "La Seyne-sur-Mer",
    "lat": 43.1,
    "lng": 5.883,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Provence-Alpes-Côte d’Azur",
    "capital": "",
    "population": 63936,
    "id": 1250392648
  },
  {
    "city": "Acámbaro",
    "city_ascii": "Acambaro",
    "lat": 20.0361,
    "lng": -100.7314,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Guanajuato",
    "capital": "minor",
    "population": 57972,
    "id": 1484524073
  },
  {
    "city": "Mazatenango",
    "city_ascii": "Mazatenango",
    "lat": 14.5304,
    "lng": -91.51,
    "country": "Guatemala",
    "iso2": "GT",
    "iso3": "GTM",
    "admin_name": "Suchitepéquez",
    "capital": "admin",
    "population": 64652,
    "id": 1320123250
  },
  {
    "city": "Trnava",
    "city_ascii": "Trnava",
    "lat": 48.3777,
    "lng": 17.5862,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Trnavský",
    "capital": "admin",
    "population": 65382,
    "id": 1703497997
  },
  {
    "city": "Caratinga",
    "city_ascii": "Caratinga",
    "lat": -19.79,
    "lng": -42.14,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Minas Gerais",
    "capital": "",
    "population": 60066,
    "id": 1076504581
  },
  {
    "city": "Los Polvorines",
    "city_ascii": "Los Polvorines",
    "lat": -34.5,
    "lng": -58.7,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires",
    "capital": "minor",
    "population": 60867,
    "id": 1032236823
  },
  {
    "city": "Kardzhali",
    "city_ascii": "Kardzhali",
    "lat": 41.64,
    "lng": 25.3708,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Kardzhali",
    "capital": "admin",
    "population": 66128,
    "id": 1100473094
  },
  {
    "city": "Troyes",
    "city_ascii": "Troyes",
    "lat": 48.2997,
    "lng": 4.0792,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Grand Est",
    "capital": "minor",
    "population": 61652,
    "id": 1250800856
  },
  {
    "city": "Villejuif",
    "city_ascii": "Villejuif",
    "lat": 48.7919,
    "lng": 2.3636,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 54753,
    "id": 1250637012
  },
  {
    "city": "Goz-Beida",
    "city_ascii": "Goz-Beida",
    "lat": 12.2236,
    "lng": 21.4144,
    "country": "Chad",
    "iso2": "TD",
    "iso3": "TCD",
    "admin_name": "Sila",
    "capital": "admin",
    "population": 66107,
    "id": 1148101824
  },
  {
    "city": "Masaka",
    "city_ascii": "Masaka",
    "lat": -0.3296,
    "lng": 31.73,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Masaka",
    "capital": "admin",
    "population": 65373,
    "id": 1800731885
  },
  {
    "city": "Singida",
    "city_ascii": "Singida",
    "lat": -4.8186,
    "lng": 34.7506,
    "country": "Tanzania",
    "iso2": "TZ",
    "iso3": "TZA",
    "admin_name": "Singida",
    "capital": "admin",
    "population": 62432,
    "id": 1834418526
  },
  {
    "city": "Dewsbury",
    "city_ascii": "Dewsbury",
    "lat": 53.691,
    "lng": -1.633,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Kirklees",
    "capital": "",
    "population": 62945,
    "id": 1826354283
  },
  {
    "city": "Arrecife",
    "city_ascii": "Arrecife",
    "lat": 28.9625,
    "lng": -13.5506,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Canary Islands",
    "capital": "",
    "population": 62988,
    "id": 1724630905
  },
  {
    "city": "Ioánnina",
    "city_ascii": "Ioannina",
    "lat": 39.6647,
    "lng": 20.8519,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Ípeiros",
    "capital": "admin",
    "population": 65574,
    "id": 1300317204
  },
  {
    "city": "Călăraşi",
    "city_ascii": "Calarasi",
    "lat": 44.2,
    "lng": 27.3333,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Călăraşi",
    "capital": "admin",
    "population": 65181,
    "id": 1642445982
  },
  {
    "city": "Maisons-Alfort",
    "city_ascii": "Maisons-Alfort",
    "lat": 48.8058,
    "lng": 2.4378,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 55655,
    "id": 1250000462
  },
  {
    "city": "La Línea de la Concepción",
    "city_ascii": "La Linea de la Concepcion",
    "lat": 36.1611,
    "lng": -5.3486,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Andalusia",
    "capital": "",
    "population": 63147,
    "id": 1724170346
  },
  {
    "city": "Sarcelles",
    "city_ascii": "Sarcelles",
    "lat": 48.9956,
    "lng": 2.3808,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "minor",
    "population": 58587,
    "id": 1250985562
  },
  {
    "city": "Vilhena",
    "city_ascii": "Vilhena",
    "lat": -12.7166,
    "lng": -60.1166,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Rondônia",
    "capital": "",
    "population": 63231,
    "id": 1076196581
  },
  {
    "city": "Altamira",
    "city_ascii": "Altamira",
    "lat": 22.3375,
    "lng": -97.8694,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Tamaulipas",
    "capital": "minor",
    "population": 59536,
    "id": 1484764524
  },
  {
    "city": "Widnes",
    "city_ascii": "Widnes",
    "lat": 53.363,
    "lng": -2.728,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Halton",
    "capital": "",
    "population": 61464,
    "id": 1826947686
  },
  {
    "city": "Granollers",
    "city_ascii": "Granollers",
    "lat": 41.6083,
    "lng": 2.2889,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Catalonia",
    "capital": "",
    "population": 61275,
    "id": 1724011647
  },
  {
    "city": "Cobán",
    "city_ascii": "Coban",
    "lat": 15.47,
    "lng": -90.38,
    "country": "Guatemala",
    "iso2": "GT",
    "iso3": "GTM",
    "admin_name": "Alta Verapaz",
    "capital": "admin",
    "population": 65194,
    "id": 1320020813
  },
  {
    "city": "Schenectady",
    "city_ascii": "Schenectady",
    "lat": 42.8025,
    "lng": -73.9276,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 65273,
    "id": 1840000395
  },
  {
    "city": "Cortazar",
    "city_ascii": "Cortazar",
    "lat": 20.483,
    "lng": -100.933,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Guanajuato",
    "capital": "minor",
    "population": 57748,
    "id": 1484238779
  },
  {
    "city": "Rundu",
    "city_ascii": "Rundu",
    "lat": -17.9167,
    "lng": 19.7667,
    "country": "Namibia",
    "iso2": "NA",
    "iso3": "NAM",
    "admin_name": "Kavango East",
    "capital": "admin",
    "population": 63431,
    "id": 1516885937
  },
  {
    "city": "Songkhla",
    "city_ascii": "Songkhla",
    "lat": 7.2061,
    "lng": 100.5967,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Songkhla",
    "capital": "admin",
    "population": 63834,
    "id": 1764543650
  },
  {
    "city": "Solwezi",
    "city_ascii": "Solwezi",
    "lat": -12.1833,
    "lng": 26.4,
    "country": "Zambia",
    "iso2": "ZM",
    "iso3": "ZMB",
    "admin_name": "North-Western",
    "capital": "admin",
    "population": 65000,
    "id": 1894777820
  },
  {
    "city": "Kitale",
    "city_ascii": "Kitale",
    "lat": 1.0167,
    "lng": 35,
    "country": "Kenya",
    "iso2": "KE",
    "iso3": "KEN",
    "admin_name": "Trans Nzoia",
    "capital": "admin",
    "population": 63245,
    "id": 1404890081
  },
  {
    "city": "As Suwaydā’",
    "city_ascii": "As Suwayda'",
    "lat": 32.7,
    "lng": 36.5667,
    "country": "Syria",
    "iso2": "SY",
    "iso3": "SYR",
    "admin_name": "As Suwaydā’",
    "capital": "admin",
    "population": 64730,
    "id": 1760680757
  },
  {
    "city": "Ouezzane",
    "city_ascii": "Ouezzane",
    "lat": 34.8,
    "lng": -5.6,
    "country": "Morocco",
    "iso2": "MA",
    "iso3": "MAR",
    "admin_name": "Tanger-Tétouan-Al Hoceïma",
    "capital": "",
    "population": 59606,
    "id": 1504736892
  },
  {
    "city": "Bayonne",
    "city_ascii": "Bayonne",
    "lat": 40.6661,
    "lng": -74.1141,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 64897,
    "id": 1840003595
  },
  {
    "city": "Faro",
    "city_ascii": "Faro",
    "lat": 37.0167,
    "lng": -7.9333,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Faro",
    "capital": "admin",
    "population": 64560,
    "id": 1620330407
  },
  {
    "city": "Faīẕābād",
    "city_ascii": "Faizabad",
    "lat": 37.1298,
    "lng": 70.5792,
    "country": "Afghanistan",
    "iso2": "AF",
    "iso3": "AFG",
    "admin_name": "Badakhshān",
    "capital": "admin",
    "population": 64704,
    "id": 1004452653
  },
  {
    "city": "Sinop",
    "city_ascii": "Sinop",
    "lat": 42.0267,
    "lng": 35.1511,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Sinop",
    "capital": "admin",
    "population": 64544,
    "id": 1792497865
  },
  {
    "city": "Yoro",
    "city_ascii": "Yoro",
    "lat": 15.1333,
    "lng": -87.1333,
    "country": "Honduras",
    "iso2": "HN",
    "iso3": "HND",
    "admin_name": "Yoro",
    "capital": "admin",
    "population": 64425,
    "id": 1340638121
  },
  {
    "city": "Pruszków",
    "city_ascii": "Pruszkow",
    "lat": 52.1667,
    "lng": 20.8,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Mazowieckie",
    "capital": "minor",
    "population": 61237,
    "id": 1616154673
  },
  {
    "city": "East Orange",
    "city_ascii": "East Orange",
    "lat": 40.7651,
    "lng": -74.2117,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 64367,
    "id": 1840002792
  },
  {
    "city": "Nagari",
    "city_ascii": "Nagari",
    "lat": 13.33,
    "lng": 79.58,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Andhra Pradesh",
    "capital": "",
    "population": 62253,
    "id": 1356993418
  },
  {
    "city": "Latacunga",
    "city_ascii": "Latacunga",
    "lat": -0.9333,
    "lng": -78.6167,
    "country": "Ecuador",
    "iso2": "EC",
    "iso3": "ECU",
    "admin_name": "Cotopaxi",
    "capital": "admin",
    "population": 63842,
    "id": 1218947988
  },
  {
    "city": "Inhambane",
    "city_ascii": "Inhambane",
    "lat": -23.865,
    "lng": 35.3833,
    "country": "Mozambique",
    "iso2": "MZ",
    "iso3": "MOZ",
    "admin_name": "Inhambane",
    "capital": "admin",
    "population": 63837,
    "id": 1508945412
  },
  {
    "city": "Tsushima",
    "city_ascii": "Tsushima",
    "lat": 35.1833,
    "lng": 136.7333,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Aichi",
    "capital": "",
    "population": 61732,
    "id": 1392244952
  },
  {
    "city": "Taunton",
    "city_ascii": "Taunton",
    "lat": 51.019,
    "lng": -3.1,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Somerset",
    "capital": "",
    "population": 60479,
    "id": 1826959489
  },
  {
    "city": "Bangor",
    "city_ascii": "Bangor",
    "lat": 54.66,
    "lng": -5.67,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Ards and North Down",
    "capital": "",
    "population": 61011,
    "id": 1826282262
  },
  {
    "city": "Florence-Graham",
    "city_ascii": "Florence-Graham",
    "lat": 33.9682,
    "lng": -118.2447,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 64162,
    "id": 1840037057
  },
  {
    "city": "Alba Iulia",
    "city_ascii": "Alba Iulia",
    "lat": 46.0764,
    "lng": 23.5728,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Alba",
    "capital": "admin",
    "population": 63536,
    "id": 1642449522
  },
  {
    "city": "Pirapora",
    "city_ascii": "Pirapora",
    "lat": -17.345,
    "lng": -44.9419,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Minas Gerais",
    "capital": "",
    "population": 60164,
    "id": 1076124834
  },
  {
    "city": "Aylesbury",
    "city_ascii": "Aylesbury",
    "lat": 51.8168,
    "lng": -0.8124,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Buckinghamshire",
    "capital": "",
    "population": 58740,
    "id": 1826391298
  },
  {
    "city": "Sombrerete",
    "city_ascii": "Sombrerete",
    "lat": 23.6333,
    "lng": -103.6397,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Zacatecas",
    "capital": "minor",
    "population": 58201,
    "id": 1484007324
  },
  {
    "city": "Cayenne",
    "city_ascii": "Cayenne",
    "lat": 4.933,
    "lng": -52.33,
    "country": "French Guiana",
    "iso2": "GF",
    "iso3": "GUF",
    "admin_name": "",
    "capital": "admin",
    "population": 61550,
    "id": 1254304969
  },
  {
    "city": "Bondy",
    "city_ascii": "Bondy",
    "lat": 48.9022,
    "lng": 2.4828,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 53353,
    "id": 1250274671
  },
  {
    "city": "Gorno-Altaysk",
    "city_ascii": "Gorno-Altaysk",
    "lat": 51.96,
    "lng": 85.96,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Altay",
    "capital": "admin",
    "population": 63214,
    "id": 1643549691
  },
  {
    "city": "Bragança",
    "city_ascii": "Braganca",
    "lat": -1.0536,
    "lng": -46.7656,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Pará",
    "capital": "",
    "population": 57163,
    "id": 1076855072
  },
  {
    "city": "Mukōchō",
    "city_ascii": "Mukocho",
    "lat": 34.9486,
    "lng": 135.6983,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Kyōto",
    "capital": "",
    "population": 56415,
    "id": 1392146541
  },
  {
    "city": "Hasuda",
    "city_ascii": "Hasuda",
    "lat": 35.9942,
    "lng": 139.6622,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Saitama",
    "capital": "",
    "population": 61507,
    "id": 1392294890
  },
  {
    "city": "Le Blanc-Mesnil",
    "city_ascii": "Le Blanc-Mesnil",
    "lat": 48.9387,
    "lng": 2.4614,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 56783,
    "id": 1250383006
  },
  {
    "city": "São Borja",
    "city_ascii": "Sao Borja",
    "lat": -28.6606,
    "lng": -56.0044,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Rio Grande do Sul",
    "capital": "",
    "population": 59613,
    "id": 1076315333
  },
  {
    "city": "Sayama",
    "city_ascii": "Sayama",
    "lat": 34.5036,
    "lng": 135.5556,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Ōsaka",
    "capital": "",
    "population": 58746,
    "id": 1392873836
  },
  {
    "city": "Halesowen",
    "city_ascii": "Halesowen",
    "lat": 52.4502,
    "lng": -2.0509,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Dudley",
    "capital": "",
    "population": 58135,
    "id": 1826152884
  },
  {
    "city": "Azua",
    "city_ascii": "Azua",
    "lat": 18.454,
    "lng": -70.729,
    "country": "Dominican Republic",
    "iso2": "DO",
    "iso3": "DOM",
    "admin_name": "Valdesia",
    "capital": "minor",
    "population": 59139,
    "id": 1214004418
  },
  {
    "city": "Ełk",
    "city_ascii": "Elk",
    "lat": 53.8167,
    "lng": 22.35,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Warmińsko-Mazurskie",
    "capital": "minor",
    "population": 60621,
    "id": 1616783003
  },
  {
    "city": "Nkhotakota",
    "city_ascii": "Nkhotakota",
    "lat": -12.9163,
    "lng": 34.3,
    "country": "Malawi",
    "iso2": "MW",
    "iso3": "MWI",
    "admin_name": "Nkhotakota",
    "capital": "admin",
    "population": 59854,
    "id": 1454281420
  },
  {
    "city": "Kyustendil",
    "city_ascii": "Kyustendil",
    "lat": 42.2811,
    "lng": 22.6889,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Kyustendil",
    "capital": "admin",
    "population": 63359,
    "id": 1100543134
  },
  {
    "city": "Karakol",
    "city_ascii": "Karakol",
    "lat": 42.5,
    "lng": 78.3833,
    "country": "Kyrgyzstan",
    "iso2": "KG",
    "iso3": "KGZ",
    "admin_name": "Ysyk-Köl",
    "capital": "admin",
    "population": 63377,
    "id": 1417269527
  },
  {
    "city": "Canela",
    "city_ascii": "Canela",
    "lat": -29.36,
    "lng": -50.81,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Rio Grande do Sul",
    "capital": "",
    "population": 60806,
    "id": 1076049094
  },
  {
    "city": "Cherbourg",
    "city_ascii": "Cherbourg",
    "lat": 49.6504,
    "lng": -1.65,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Normandie",
    "capital": "minor",
    "population": 60991,
    "id": 1250026751
  },
  {
    "city": "Ruislip",
    "city_ascii": "Ruislip",
    "lat": 51.576,
    "lng": -0.433,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hillingdon",
    "capital": "",
    "population": 58217,
    "id": 1826571723
  },
  {
    "city": "Gabrovo",
    "city_ascii": "Gabrovo",
    "lat": 42.8711,
    "lng": 25.315,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Gabrovo",
    "capital": "admin",
    "population": 62938,
    "id": 1100696135
  },
  {
    "city": "Fussa",
    "city_ascii": "Fussa",
    "lat": 35.7386,
    "lng": 139.3267,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Tōkyō",
    "capital": "",
    "population": 57527,
    "id": 1392749897
  },
  {
    "city": "Kratie",
    "city_ascii": "Kratie",
    "lat": 12.4881,
    "lng": 106.0188,
    "country": "Cambodia",
    "iso2": "KH",
    "iso3": "KHM",
    "admin_name": "Kratie",
    "capital": "admin",
    "population": 60000,
    "id": 1116453792
  },
  {
    "city": "Assen",
    "city_ascii": "Assen",
    "lat": 53,
    "lng": 6.55,
    "country": "Netherlands",
    "iso2": "NL",
    "iso3": "NLD",
    "admin_name": "Drenthe",
    "capital": "admin",
    "population": 62237,
    "id": 1528917714
  },
  {
    "city": "Beledweyne",
    "city_ascii": "Beledweyne",
    "lat": 4.736,
    "lng": 45.204,
    "country": "Somalia",
    "iso2": "SO",
    "iso3": "SOM",
    "admin_name": "Hiiraan",
    "capital": "admin",
    "population": 62945,
    "id": 1706018197
  },
  {
    "city": "Royal Tunbridge Wells",
    "city_ascii": "Royal Tunbridge Wells",
    "lat": 51.132,
    "lng": 0.263,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Kent",
    "capital": "",
    "population": 59947,
    "id": 1826011948
  },
  {
    "city": "Brentwood",
    "city_ascii": "Brentwood",
    "lat": 40.7839,
    "lng": -73.2522,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 62942,
    "id": 1840005021
  },
  {
    "city": "Maldonado",
    "city_ascii": "Maldonado",
    "lat": -34.9088,
    "lng": -54.9581,
    "country": "Uruguay",
    "iso2": "UY",
    "iso3": "URY",
    "admin_name": "Maldonado",
    "capital": "admin",
    "population": 62592,
    "id": 1858958595
  },
  {
    "city": "Khartsyzk",
    "city_ascii": "Khartsyzk",
    "lat": 48.0333,
    "lng": 38.15,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Donets’ka Oblast’",
    "capital": "",
    "population": 59763,
    "id": 1804780082
  },
  {
    "city": "Wallasey",
    "city_ascii": "Wallasey",
    "lat": 53.4158,
    "lng": -3.0233,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Wirral",
    "capital": "",
    "population": 60284,
    "id": 1826741418
  },
  {
    "city": "Barrow in Furness",
    "city_ascii": "Barrow in Furness",
    "lat": 54.1108,
    "lng": -3.2261,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Cumbria",
    "capital": "",
    "population": 56745,
    "id": 1826352599
  },
  {
    "city": "Napier",
    "city_ascii": "Napier",
    "lat": -39.4833,
    "lng": 176.9167,
    "country": "New Zealand",
    "iso2": "NZ",
    "iso3": "NZL",
    "admin_name": "Hawke’s Bay",
    "capital": "admin",
    "population": 62800,
    "id": 1554064936
  },
  {
    "city": "Stryy",
    "city_ascii": "Stryy",
    "lat": 49.25,
    "lng": 23.85,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "L’vivs’ka Oblast’",
    "capital": "minor",
    "population": 59325,
    "id": 1804862811
  },
  {
    "city": "Sopron",
    "city_ascii": "Sopron",
    "lat": 47.6833,
    "lng": 16.5833,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Sopron",
    "capital": "admin",
    "population": 62454,
    "id": 1348988570
  },
  {
    "city": "North Miami",
    "city_ascii": "North Miami",
    "lat": 25.9007,
    "lng": -80.1686,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 62822,
    "id": 1840015154
  },
  {
    "city": "Skokie",
    "city_ascii": "Skokie",
    "lat": 42.0359,
    "lng": -87.74,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 62700,
    "id": 1840011321
  },
  {
    "city": "Telêmaco Borba",
    "city_ascii": "Telemaco Borba",
    "lat": -24.3239,
    "lng": -50.6156,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Paraná",
    "capital": "",
    "population": 58880,
    "id": 1076867165
  },
  {
    "city": "Leskovac",
    "city_ascii": "Leskovac",
    "lat": 42.9981,
    "lng": 21.9461,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Leskovac",
    "capital": "admin",
    "population": 60288,
    "id": 1688752541
  },
  {
    "city": "Santa Rosa",
    "city_ascii": "Santa Rosa",
    "lat": -27.8708,
    "lng": -54.4814,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Rio Grande do Sul",
    "capital": "",
    "population": 59281,
    "id": 1076071027
  },
  {
    "city": "Tataouine",
    "city_ascii": "Tataouine",
    "lat": 33,
    "lng": 10.4667,
    "country": "Tunisia",
    "iso2": "TN",
    "iso3": "TUN",
    "admin_name": "Tataouine",
    "capital": "admin",
    "population": 62577,
    "id": 1788300169
  },
  {
    "city": "Saraburi",
    "city_ascii": "Saraburi",
    "lat": 14.5286,
    "lng": 100.9114,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Sara Buri",
    "capital": "admin",
    "population": 61750,
    "id": 1764111243
  },
  {
    "city": "Fontenay-sous-Bois",
    "city_ascii": "Fontenay-sous-Bois",
    "lat": 48.8517,
    "lng": 2.4772,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 52939,
    "id": 1250384578
  },
  {
    "city": "Rayong",
    "city_ascii": "Rayong",
    "lat": 12.6742,
    "lng": 101.2789,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Rayong",
    "capital": "admin",
    "population": 61902,
    "id": 1764920057
  },
  {
    "city": "Kolda",
    "city_ascii": "Kolda",
    "lat": 12.8958,
    "lng": -14.9408,
    "country": "Senegal",
    "iso2": "SN",
    "iso3": "SEN",
    "admin_name": "Kolda",
    "capital": "admin",
    "population": 62258,
    "id": 1686219536
  },
  {
    "city": "Santarém",
    "city_ascii": "Santarem",
    "lat": 39.2369,
    "lng": -8.685,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Santarém",
    "capital": "admin",
    "population": 62200,
    "id": 1620315932
  },
  {
    "city": "Suresnes",
    "city_ascii": "Suresnes",
    "lat": 48.87,
    "lng": 2.22,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 49145,
    "id": 1250405027
  },
  {
    "city": "Chornomors’k",
    "city_ascii": "Chornomors'k",
    "lat": 46.3017,
    "lng": 30.6569,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Odes’ka Oblast’",
    "capital": "",
    "population": 59800,
    "id": 1804199117
  },
  {
    "city": "Bobigny",
    "city_ascii": "Bobigny",
    "lat": 48.9106,
    "lng": 2.4397,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "minor",
    "population": 53640,
    "id": 1250306149
  },
  {
    "city": "Puerto Limón",
    "city_ascii": "Puerto Limon",
    "lat": 10.0022,
    "lng": -83.084,
    "country": "Costa Rica",
    "iso2": "CR",
    "iso3": "CRI",
    "admin_name": "Limón",
    "capital": "admin",
    "population": 61072,
    "id": 1188214393
  },
  {
    "city": "Madang",
    "city_ascii": "Madang",
    "lat": -5.2248,
    "lng": 145.7853,
    "country": "Papua New Guinea",
    "iso2": "PG",
    "iso3": "PNG",
    "admin_name": "Madang",
    "capital": "admin",
    "population": 62023,
    "id": 1598116558
  },
  {
    "city": "Lytkarino",
    "city_ascii": "Lytkarino",
    "lat": 55.5833,
    "lng": 37.8833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Moskovskaya Oblast’",
    "capital": "",
    "population": 58606,
    "id": 1643669152
  },
  {
    "city": "Rafael Calzada",
    "city_ascii": "Rafael Calzada",
    "lat": -34.7833,
    "lng": -58.3667,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires",
    "capital": "",
    "population": 57351,
    "id": 1032898708
  },
  {
    "city": "Pico Rivera",
    "city_ascii": "Pico Rivera",
    "lat": 33.9902,
    "lng": -118.0888,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 62027,
    "id": 1840020506
  },
  {
    "city": "Kaposvár",
    "city_ascii": "Kaposvar",
    "lat": 46.3667,
    "lng": 17.7833,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Somogy",
    "capital": "admin",
    "population": 61441,
    "id": 1348114190
  },
  {
    "city": "North Bergen",
    "city_ascii": "North Bergen",
    "lat": 40.7938,
    "lng": -74.0242,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 61970,
    "id": 1840081753
  },
  {
    "city": "Montebello",
    "city_ascii": "Montebello",
    "lat": 34.0155,
    "lng": -118.1108,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 61954,
    "id": 1840020499
  },
  {
    "city": "Caicó",
    "city_ascii": "Caico",
    "lat": -6.4596,
    "lng": -37.1,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Rio Grande do Norte",
    "capital": "",
    "population": 54934,
    "id": 1076251820
  },
  {
    "city": "Bāmyān",
    "city_ascii": "Bamyan",
    "lat": 34.8167,
    "lng": 67.8167,
    "country": "Afghanistan",
    "iso2": "AF",
    "iso3": "AFG",
    "admin_name": "Bāmyān",
    "capital": "admin",
    "population": 61863,
    "id": 1004274041
  },
  {
    "city": "Medenine",
    "city_ascii": "Medenine",
    "lat": 33.4,
    "lng": 10.4167,
    "country": "Tunisia",
    "iso2": "TN",
    "iso3": "TUN",
    "admin_name": "Médenine",
    "capital": "admin",
    "population": 61705,
    "id": 1788240639
  },
  {
    "city": "Portugalete",
    "city_ascii": "Portugalete",
    "lat": 43.3194,
    "lng": -3.0196,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Basque Country",
    "capital": "",
    "population": 45766,
    "id": 1724017694
  },
  {
    "city": "Rovaniemi",
    "city_ascii": "Rovaniemi",
    "lat": 66.5014,
    "lng": 25.7347,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Lappi",
    "capital": "admin",
    "population": 61763,
    "id": 1246718173
  },
  {
    "city": "Yala",
    "city_ascii": "Yala",
    "lat": 6.5397,
    "lng": 101.2811,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Yala",
    "capital": "admin",
    "population": 61250,
    "id": 1764430323
  },
  {
    "city": "Giurgiu",
    "city_ascii": "Giurgiu",
    "lat": 43.9,
    "lng": 25.9667,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Giurgiu",
    "capital": "admin",
    "population": 61353,
    "id": 1642984994
  },
  {
    "city": "Chambéry",
    "city_ascii": "Chambery",
    "lat": 45.57,
    "lng": 5.9118,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Auvergne-Rhône-Alpes",
    "capital": "minor",
    "population": 58919,
    "id": 1250283861
  },
  {
    "city": "Samut Sakhon",
    "city_ascii": "Samut Sakhon",
    "lat": 13.5486,
    "lng": 100.2775,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Samut Sakhon",
    "capital": "admin",
    "population": 60103,
    "id": 1764502057
  },
  {
    "city": "Janaúba",
    "city_ascii": "Janauba",
    "lat": -15.8025,
    "lng": -43.3089,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Minas Gerais",
    "capital": "",
    "population": 56572,
    "id": 1076293809
  },
  {
    "city": "Seinäjoki",
    "city_ascii": "Seinajoki",
    "lat": 62.7903,
    "lng": 22.8403,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Etelä-Pohjanmaa",
    "capital": "admin",
    "population": 61530,
    "id": 1246118146
  },
  {
    "city": "Ruhengeri",
    "city_ascii": "Ruhengeri",
    "lat": -1.4944,
    "lng": 29.6417,
    "country": "Rwanda",
    "iso2": "RW",
    "iso3": "RWA",
    "admin_name": "Northern Province",
    "capital": "",
    "population": 59333,
    "id": 1646793543
  },
  {
    "city": "Suileng",
    "city_ascii": "Suileng",
    "lat": 47.246,
    "lng": 127.106,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Heilongjiang",
    "capital": "minor",
    "population": 57789,
    "id": 1156128173
  },
  {
    "city": "National City",
    "city_ascii": "National City",
    "lat": 32.6654,
    "lng": -117.0983,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 61394,
    "id": 1840020622
  },
  {
    "city": "Guanambi",
    "city_ascii": "Guanambi",
    "lat": -14.2233,
    "lng": -42.7814,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Bahia",
    "capital": "",
    "population": 58877,
    "id": 1076791780
  },
  {
    "city": "Benslimane",
    "city_ascii": "Benslimane",
    "lat": 33.6122,
    "lng": -7.1211,
    "country": "Morocco",
    "iso2": "MA",
    "iso3": "MAR",
    "admin_name": "Casablanca-Settat",
    "capital": "",
    "population": 57101,
    "id": 1504790296
  },
  {
    "city": "Koja",
    "city_ascii": "Koja",
    "lat": 26.3643,
    "lng": 127.8328,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Okinawa",
    "capital": "",
    "population": 58658,
    "id": 1392299085
  },
  {
    "city": "Ariquemes",
    "city_ascii": "Ariquemes",
    "lat": -9.9396,
    "lng": -63.08,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Rondônia",
    "capital": "",
    "population": 58096,
    "id": 1076442639
  },
  {
    "city": "Coconut Creek",
    "city_ascii": "Coconut Creek",
    "lat": 26.2803,
    "lng": -80.1842,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 61248,
    "id": 1840015132
  },
  {
    "city": "Bến Tre",
    "city_ascii": "Ben Tre",
    "lat": 10.2333,
    "lng": 106.3833,
    "country": "Vietnam",
    "iso2": "VN",
    "iso3": "VNM",
    "admin_name": "Bến Tre",
    "capital": "admin",
    "population": 59442,
    "id": 1704108909
  },
  {
    "city": "Gaalkacyo",
    "city_ascii": "Gaalkacyo",
    "lat": 6.7697,
    "lng": 47.4308,
    "country": "Somalia",
    "iso2": "SO",
    "iso3": "SOM",
    "admin_name": "Mudug",
    "capital": "admin",
    "population": 61200,
    "id": 1706067930
  },
  {
    "city": "Limerick",
    "city_ascii": "Limerick",
    "lat": 52.6653,
    "lng": -8.6238,
    "country": "Ireland",
    "iso2": "IE",
    "iso3": "IRL",
    "admin_name": "Limerick",
    "capital": "admin",
    "population": 58319,
    "id": 1372126011
  },
  {
    "city": "Yopal",
    "city_ascii": "Yopal",
    "lat": 5.347,
    "lng": -72.406,
    "country": "Colombia",
    "iso2": "CO",
    "iso3": "COL",
    "admin_name": "Casanare",
    "capital": "admin",
    "population": 61029,
    "id": 1170295059
  },
  {
    "city": "Lorient",
    "city_ascii": "Lorient",
    "lat": 47.75,
    "lng": -3.36,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Bretagne",
    "capital": "minor",
    "population": 57149,
    "id": 1250615711
  },
  {
    "city": "Trang",
    "city_ascii": "Trang",
    "lat": 7.5561,
    "lng": 99.6114,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Trang",
    "capital": "admin",
    "population": 60591,
    "id": 1764343817
  },
  {
    "city": "Vacaria",
    "city_ascii": "Vacaria",
    "lat": -28.5122,
    "lng": -50.9339,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Rio Grande do Sul",
    "capital": "",
    "population": 56765,
    "id": 1076001152
  },
  {
    "city": "Melo",
    "city_ascii": "Melo",
    "lat": -32.3595,
    "lng": -54.18,
    "country": "Uruguay",
    "iso2": "UY",
    "iso3": "URY",
    "admin_name": "Cerro Largo",
    "capital": "admin",
    "population": 55494,
    "id": 1858600801
  },
  {
    "city": "São Gabriel",
    "city_ascii": "Sao Gabriel",
    "lat": -30.3364,
    "lng": -54.32,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Rio Grande do Sul",
    "capital": "",
    "population": 55434,
    "id": 1076674208
  },
  {
    "city": "Oyem",
    "city_ascii": "Oyem",
    "lat": 1.6,
    "lng": 11.5667,
    "country": "Gabon",
    "iso2": "GA",
    "iso3": "GAB",
    "admin_name": "Woleu-Ntem",
    "capital": "admin",
    "population": 60685,
    "id": 1266867613
  },
  {
    "city": "Lokoja",
    "city_ascii": "Lokoja",
    "lat": 7.8004,
    "lng": 6.7399,
    "country": "Nigeria",
    "iso2": "NG",
    "iso3": "NGA",
    "admin_name": "Kogi",
    "capital": "admin",
    "population": 60579,
    "id": 1566772925
  },
  {
    "city": "Juigalpa",
    "city_ascii": "Juigalpa",
    "lat": 12.0833,
    "lng": -85.4,
    "country": "Nicaragua",
    "iso2": "NI",
    "iso3": "NIC",
    "admin_name": "Chontales",
    "capital": "admin",
    "population": 60583,
    "id": 1558986603
  },
  {
    "city": "São José de Ribamar",
    "city_ascii": "Sao Jose de Ribamar",
    "lat": -2.55,
    "lng": -44.07,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Maranhão",
    "capital": "",
    "population": 55265,
    "id": 1076976057
  },
  {
    "city": "Växjö",
    "city_ascii": "Vaxjo",
    "lat": 56.8837,
    "lng": 14.8167,
    "country": "Sweden",
    "iso2": "SE",
    "iso3": "SWE",
    "admin_name": "Kronoberg",
    "capital": "admin",
    "population": 59600,
    "id": 1752263370
  },
  {
    "city": "La Habra",
    "city_ascii": "La Habra",
    "lat": 33.9278,
    "lng": -117.9513,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 60513,
    "id": 1840020585
  },
  {
    "city": "The Hammocks",
    "city_ascii": "The Hammocks",
    "lat": 25.67,
    "lng": -80.4483,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 60473,
    "id": 1840029009
  },
  {
    "city": "Malden",
    "city_ascii": "Malden",
    "lat": 42.4305,
    "lng": -71.0576,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 60470,
    "id": 1840000430
  },
  {
    "city": "Banfora",
    "city_ascii": "Banfora",
    "lat": 10.6308,
    "lng": -4.7589,
    "country": "Burkina Faso",
    "iso2": "BF",
    "iso3": "BFA",
    "admin_name": "Cascades",
    "capital": "admin",
    "population": 60288,
    "id": 1854763822
  },
  {
    "city": "San Pedro",
    "city_ascii": "San Pedro",
    "lat": 25.7589,
    "lng": -102.9844,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Coahuila de Zaragoza",
    "capital": "minor",
    "population": 53688,
    "id": 1484673086
  },
  {
    "city": "Hamura",
    "city_ascii": "Hamura",
    "lat": 35.7672,
    "lng": 139.3111,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Tōkyō",
    "capital": "",
    "population": 54874,
    "id": 1392153436
  },
  {
    "city": "Świdnica",
    "city_ascii": "Swidnica",
    "lat": 50.8438,
    "lng": 16.4886,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Dolnośląskie",
    "capital": "minor",
    "population": 57761,
    "id": 1616002338
  },
  {
    "city": "Uíge",
    "city_ascii": "Uige",
    "lat": -7.62,
    "lng": 15.05,
    "country": "Angola",
    "iso2": "AO",
    "iso3": "AGO",
    "admin_name": "Uíge",
    "capital": "admin",
    "population": 60008,
    "id": 1024666275
  },
  {
    "city": "Estância",
    "city_ascii": "Estancia",
    "lat": -11.2683,
    "lng": -37.4383,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Sergipe",
    "capital": "",
    "population": 55654,
    "id": 1076069381
  },
  {
    "city": "Arcoverde",
    "city_ascii": "Arcoverde",
    "lat": -8.42,
    "lng": -37.07,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Pernambuco",
    "capital": "",
    "population": 57163,
    "id": 1076499713
  },
  {
    "city": "Zushi",
    "city_ascii": "Zushi",
    "lat": 35.2956,
    "lng": 139.5803,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Kanagawa",
    "capital": "",
    "population": 56936,
    "id": 1392442008
  },
  {
    "city": "Temascalcingo",
    "city_ascii": "Temascalcingo",
    "lat": 19.9147,
    "lng": -100.0036,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "México",
    "capital": "minor",
    "population": 58169,
    "id": 1484038182
  },
  {
    "city": "Hereford",
    "city_ascii": "Hereford",
    "lat": 52.0565,
    "lng": -2.716,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Herefordshire",
    "capital": "",
    "population": 55955,
    "id": 1826996583
  },
  {
    "city": "Mbanza Kongo",
    "city_ascii": "Mbanza Kongo",
    "lat": -6.2667,
    "lng": 14.25,
    "country": "Angola",
    "iso2": "AO",
    "iso3": "AGO",
    "admin_name": "Zaire",
    "capital": "admin",
    "population": 60182,
    "id": 1024532464
  },
  {
    "city": "Veszprém",
    "city_ascii": "Veszprem",
    "lat": 47.1,
    "lng": 17.9167,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Veszprém",
    "capital": "admin",
    "population": 59738,
    "id": 1348279762
  },
  {
    "city": "Itapipoca",
    "city_ascii": "Itapipoca",
    "lat": -3.4995,
    "lng": -39.58,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Ceará",
    "capital": "",
    "population": 55784,
    "id": 1076980375
  },
  {
    "city": "Ellesmere Port",
    "city_ascii": "Ellesmere Port",
    "lat": 53.279,
    "lng": -2.897,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Cheshire West and Chester",
    "capital": "",
    "population": 55715,
    "id": 1826938401
  },
  {
    "city": "Serrinha",
    "city_ascii": "Serrinha",
    "lat": -11.6642,
    "lng": -39.0075,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Bahia",
    "capital": "",
    "population": 56829,
    "id": 1076832200
  },
  {
    "city": "South Whittier",
    "city_ascii": "South Whittier",
    "lat": 33.9336,
    "lng": -118.0311,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 60096,
    "id": 1840019209
  },
  {
    "city": "Etchojoa",
    "city_ascii": "Etchojoa",
    "lat": 26.8667,
    "lng": -109.65,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Sonora",
    "capital": "minor",
    "population": 56129,
    "id": 1484867357
  },
  {
    "city": "West Allis",
    "city_ascii": "West Allis",
    "lat": 43.0068,
    "lng": -88.0296,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 59890,
    "id": 1840003034
  },
  {
    "city": "Ban Bang Kaeo",
    "city_ascii": "Ban Bang Kaeo",
    "lat": 13.6371,
    "lng": 100.6636,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Samut Prakan",
    "capital": "",
    "population": 56949,
    "id": 1764813354
  },
  {
    "city": "Užice",
    "city_ascii": "Uzice",
    "lat": 43.85,
    "lng": 19.85,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Užice",
    "capital": "admin",
    "population": 59747,
    "id": 1688591624
  },
  {
    "city": "Fountainebleau",
    "city_ascii": "Fountainebleau",
    "lat": 25.7723,
    "lng": -80.3458,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 59833,
    "id": 1840028983
  },
  {
    "city": "Taylorsville",
    "city_ascii": "Taylorsville",
    "lat": 40.657,
    "lng": -111.9493,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Utah",
    "capital": "",
    "population": 59805,
    "id": 1840021387
  },
  {
    "city": "Itānagar",
    "city_ascii": "Itanagar",
    "lat": 27.1,
    "lng": 93.62,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Arunāchal Pradesh",
    "capital": "admin",
    "population": 59490,
    "id": 1356023817
  },
  {
    "city": "Romita",
    "city_ascii": "Romita",
    "lat": 20.8708,
    "lng": -101.5169,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Guanajuato",
    "capital": "minor",
    "population": 51825,
    "id": 1484697777
  },
  {
    "city": "Suharekë",
    "city_ascii": "Suhareke",
    "lat": 42.38,
    "lng": 20.8219,
    "country": "Kosovo",
    "iso2": "XK",
    "iso3": "XKS",
    "admin_name": "Suharekë",
    "capital": "admin",
    "population": 59722,
    "id": 1901918563
  },
  {
    "city": "Béja",
    "city_ascii": "Beja",
    "lat": 36.7304,
    "lng": 9.19,
    "country": "Tunisia",
    "iso2": "TN",
    "iso3": "TUN",
    "admin_name": "Béja",
    "capital": "admin",
    "population": 59567,
    "id": 1788225711
  },
  {
    "city": "Klimovsk",
    "city_ascii": "Klimovsk",
    "lat": 55.3667,
    "lng": 37.5333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Moskovskaya Oblast’",
    "capital": "",
    "population": 56239,
    "id": 1643385248
  },
  {
    "city": "Bungoma",
    "city_ascii": "Bungoma",
    "lat": 0.5666,
    "lng": 34.5666,
    "country": "Kenya",
    "iso2": "KE",
    "iso3": "KEN",
    "admin_name": "Bungoma",
    "capital": "admin",
    "population": 55857,
    "id": 1404689847
  },
  {
    "city": "San José Iturbide",
    "city_ascii": "San Jose Iturbide",
    "lat": 21,
    "lng": -100.3833,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Guanajuato",
    "capital": "minor",
    "population": 54661,
    "id": 1484954448
  },
  {
    "city": "Monterey Park",
    "city_ascii": "Monterey Park",
    "lat": 34.0497,
    "lng": -118.1326,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 59669,
    "id": 1840020500
  },
  {
    "city": "Concórdia",
    "city_ascii": "Concordia",
    "lat": -27.2342,
    "lng": -52.0278,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Santa Catarina",
    "capital": "",
    "population": 55367,
    "id": 1076922195
  },
  {
    "city": "Hod HaSharon",
    "city_ascii": "Hod HaSharon",
    "lat": 32.15,
    "lng": 34.8833,
    "country": "Israel",
    "iso2": "IL",
    "iso3": "ISR",
    "admin_name": "Central",
    "capital": "",
    "population": 56659,
    "id": 1376159880
  },
  {
    "city": "Puteaux",
    "city_ascii": "Puteaux",
    "lat": 48.885,
    "lng": 2.2389,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 44645,
    "id": 1250407425
  },
  {
    "city": "Ródos",
    "city_ascii": "Rodos",
    "lat": 36.4412,
    "lng": 28.2225,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Notío Aigaío",
    "capital": "minor",
    "population": 56969,
    "id": 1300944675
  },
  {
    "city": "Formiga",
    "city_ascii": "Formiga",
    "lat": -20.4644,
    "lng": -45.4264,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Minas Gerais",
    "capital": "",
    "population": 56404,
    "id": 1076994550
  },
  {
    "city": "Samannūd",
    "city_ascii": "Samannud",
    "lat": 30.9667,
    "lng": 31.25,
    "country": "Egypt",
    "iso2": "EG",
    "iso3": "EGY",
    "admin_name": "Al Gharbīyah",
    "capital": "",
    "population": 57177,
    "id": 1818038230
  },
  {
    "city": "Mérida",
    "city_ascii": "Merida",
    "lat": 38.9158,
    "lng": -6.3333,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Extremadura",
    "capital": "admin",
    "population": 59335,
    "id": 1724941889
  },
  {
    "city": "Gardena",
    "city_ascii": "Gardena",
    "lat": 33.8944,
    "lng": -118.3073,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 59329,
    "id": 1840020482
  },
  {
    "city": "Békéscsaba",
    "city_ascii": "Bekescsaba",
    "lat": 46.6833,
    "lng": 21.0833,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Békés",
    "capital": "admin",
    "population": 58996,
    "id": 1348329493
  },
  {
    "city": "Cupertino",
    "city_ascii": "Cupertino",
    "lat": 37.3168,
    "lng": -122.0465,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 59276,
    "id": 1840018950
  },
  {
    "city": "La Mesa",
    "city_ascii": "La Mesa",
    "lat": 32.7703,
    "lng": -117.0204,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 59249,
    "id": 1840020625
  },
  {
    "city": "Brookline",
    "city_ascii": "Brookline",
    "lat": 42.3243,
    "lng": -71.1408,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 59234,
    "id": 1840053672
  },
  {
    "city": "Dzerzhinskiy",
    "city_ascii": "Dzerzhinskiy",
    "lat": 55.6333,
    "lng": 37.85,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Moskovskaya Oblast’",
    "capital": "",
    "population": 55669,
    "id": 1643369563
  },
  {
    "city": "Artemisa",
    "city_ascii": "Artemisa",
    "lat": 22.8136,
    "lng": -82.7633,
    "country": "Cuba",
    "iso2": "CU",
    "iso3": "CUB",
    "admin_name": "Artemisa",
    "capital": "admin",
    "population": 59130,
    "id": 1192325408
  },
  {
    "city": "San Buenaventura",
    "city_ascii": "San Buenaventura",
    "lat": 19.2641,
    "lng": -99.6746,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "México",
    "capital": "",
    "population": 48037,
    "id": 1484313229
  },
  {
    "city": "Actopan",
    "city_ascii": "Actopan",
    "lat": 20.267,
    "lng": -98.933,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Hidalgo",
    "capital": "",
    "population": 56429,
    "id": 1484903562
  },
  {
    "city": "Kitgum",
    "city_ascii": "Kitgum",
    "lat": 3.3004,
    "lng": 32.87,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Kitgum",
    "capital": "admin",
    "population": 56891,
    "id": 1800380883
  },
  {
    "city": "Yevlax",
    "city_ascii": "Yevlax",
    "lat": 40.6172,
    "lng": 47.15,
    "country": "Azerbaijan",
    "iso2": "AZ",
    "iso3": "AZE",
    "admin_name": "Yevlax",
    "capital": "admin",
    "population": 59036,
    "id": 1031581655
  },
  {
    "city": "Viedma",
    "city_ascii": "Viedma",
    "lat": -40.8,
    "lng": -63,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Río Negro",
    "capital": "admin",
    "population": 59122,
    "id": 1032613063
  },
  {
    "city": "Cerro de Pasco",
    "city_ascii": "Cerro de Pasco",
    "lat": -10.6864,
    "lng": -76.2625,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "Pasco",
    "capital": "admin",
    "population": 58899,
    "id": 1604446908
  },
  {
    "city": "Kabinda",
    "city_ascii": "Kabinda",
    "lat": -6.1296,
    "lng": 24.48,
    "country": "Congo (Kinshasa)",
    "iso2": "CD",
    "iso3": "COD",
    "admin_name": "Lomami",
    "capital": "admin",
    "population": 59004,
    "id": 1180751831
  },
  {
    "city": "Braintree",
    "city_ascii": "Braintree",
    "lat": 51.878,
    "lng": 0.55,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Essex",
    "capital": "",
    "population": 53477,
    "id": 1826745611
  },
  {
    "city": "Tacuarembó",
    "city_ascii": "Tacuarembo",
    "lat": -31.71,
    "lng": -55.98,
    "country": "Uruguay",
    "iso2": "UY",
    "iso3": "URY",
    "admin_name": "Tacuarembó",
    "capital": "admin",
    "population": 54277,
    "id": 1858952595
  },
  {
    "city": "Clamart",
    "city_ascii": "Clamart",
    "lat": 48.8014,
    "lng": 2.2628,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 52971,
    "id": 1250130113
  },
  {
    "city": "Coari",
    "city_ascii": "Coari",
    "lat": -4.085,
    "lng": -63.1414,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Amazonas",
    "capital": "",
    "population": 53305,
    "id": 1076899406
  },
  {
    "city": "Crateús",
    "city_ascii": "Crateus",
    "lat": -5.1783,
    "lng": -40.6775,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Ceará",
    "capital": "",
    "population": 52933,
    "id": 1076897989
  },
  {
    "city": "Kruševac",
    "city_ascii": "Krusevac",
    "lat": 43.5833,
    "lng": 21.3267,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Kruševac",
    "capital": "admin",
    "population": 58745,
    "id": 1688595236
  },
  {
    "city": "Margate",
    "city_ascii": "Margate",
    "lat": 26.2466,
    "lng": -80.2119,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 58796,
    "id": 1840015999
  },
  {
    "city": "Qiryat Ata",
    "city_ascii": "Qiryat Ata",
    "lat": 32.8,
    "lng": 35.1,
    "country": "Israel",
    "iso2": "IL",
    "iso3": "ISR",
    "admin_name": "Haifa",
    "capital": "",
    "population": 55464,
    "id": 1376676929
  },
  {
    "city": "Carson City",
    "city_ascii": "Carson City",
    "lat": 39.1512,
    "lng": -119.7474,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Nevada",
    "capital": "admin",
    "population": 58756,
    "id": 1840003826
  },
  {
    "city": "Sevran",
    "city_ascii": "Sevran",
    "lat": 48.9333,
    "lng": 2.5333,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 51016,
    "id": 1250179512
  },
  {
    "city": "Mandapeta",
    "city_ascii": "Mandapeta",
    "lat": 16.87,
    "lng": 81.93,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Andhra Pradesh",
    "capital": "",
    "population": 56063,
    "id": 1356648879
  },
  {
    "city": "Évora",
    "city_ascii": "Evora",
    "lat": 38.5667,
    "lng": -7.9,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Évora",
    "capital": "admin",
    "population": 55620,
    "id": 1620456226
  },
  {
    "city": "Port Coquitlam",
    "city_ascii": "Port Coquitlam",
    "lat": 49.2625,
    "lng": -122.7811,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "British Columbia",
    "capital": "",
    "population": 58612,
    "id": 1124473757
  },
  {
    "city": "Rosh Ha‘Ayin",
    "city_ascii": "Rosh Ha`Ayin",
    "lat": 32.0833,
    "lng": 34.95,
    "country": "Israel",
    "iso2": "IL",
    "iso3": "ISR",
    "admin_name": "Central",
    "capital": "",
    "population": 56300,
    "id": 1376619380
  },
  {
    "city": "Middletown",
    "city_ascii": "Middletown",
    "lat": 41.4459,
    "lng": -74.4236,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 58592,
    "id": 1840000585
  },
  {
    "city": "Chelles",
    "city_ascii": "Chelles",
    "lat": 48.8833,
    "lng": 2.6,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 54917,
    "id": 1250001485
  },
  {
    "city": "Bondoukou",
    "city_ascii": "Bondoukou",
    "lat": 8.04,
    "lng": -2.8,
    "country": "Côte D’Ivoire",
    "iso2": "CI",
    "iso3": "CIV",
    "admin_name": "Zanzan",
    "capital": "admin",
    "population": 58297,
    "id": 1384649653
  },
  {
    "city": "Kendale Lakes",
    "city_ascii": "Kendale Lakes",
    "lat": 25.7081,
    "lng": -80.4078,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 58395,
    "id": 1840029045
  },
  {
    "city": "San Fernando",
    "city_ascii": "San Fernando",
    "lat": 10.2833,
    "lng": -61.4667,
    "country": "Trinidad And Tobago",
    "iso2": "TT",
    "iso3": "TTO",
    "admin_name": "San Fernando",
    "capital": "admin",
    "population": 55419,
    "id": 1780361077
  },
  {
    "city": "Meaux",
    "city_ascii": "Meaux",
    "lat": 48.9603,
    "lng": 2.8883,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "minor",
    "population": 54991,
    "id": 1250827686
  },
  {
    "city": "Novohrad-Volynskyi",
    "city_ascii": "Novohrad-Volynskyi",
    "lat": 50.5833,
    "lng": 27.6333,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Zhytomyrs’ka Oblast’",
    "capital": "minor",
    "population": 56288,
    "id": 1804059567
  },
  {
    "city": "Huamantla",
    "city_ascii": "Huamantla",
    "lat": 19.3133,
    "lng": -97.9228,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Tlaxcala",
    "capital": "minor",
    "population": 51996,
    "id": 1484180362
  },
  {
    "city": "Chaniá",
    "city_ascii": "Chania",
    "lat": 35.5167,
    "lng": 24.0167,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Kríti",
    "capital": "minor",
    "population": 53910,
    "id": 1300233408
  },
  {
    "city": "Union",
    "city_ascii": "Union",
    "lat": 40.6953,
    "lng": -74.2697,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 58158,
    "id": 1840056367
  },
  {
    "city": "Vidin",
    "city_ascii": "Vidin",
    "lat": 43.9853,
    "lng": 22.8764,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Vidin",
    "capital": "admin",
    "population": 58018,
    "id": 1100463536
  },
  {
    "city": "White Plains",
    "city_ascii": "White Plains",
    "lat": 41.022,
    "lng": -73.7549,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 58109,
    "id": 1840003477
  },
  {
    "city": "Huehuetenango",
    "city_ascii": "Huehuetenango",
    "lat": 15.3147,
    "lng": -91.4761,
    "country": "Guatemala",
    "iso2": "GT",
    "iso3": "GTM",
    "admin_name": "Huehuetenango",
    "capital": "admin",
    "population": 58000,
    "id": 1320742269
  },
  {
    "city": "Sartrouville",
    "city_ascii": "Sartrouville",
    "lat": 48.9372,
    "lng": 2.1644,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 51967,
    "id": 1250494022
  },
  {
    "city": "Jefferson City",
    "city_ascii": "Jefferson City",
    "lat": 38.5676,
    "lng": -92.1759,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "admin",
    "population": 58025,
    "id": 1840008615
  },
  {
    "city": "Zalaegerszeg",
    "city_ascii": "Zalaegerszeg",
    "lat": 46.8392,
    "lng": 16.8511,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Zala",
    "capital": "admin",
    "population": 57403,
    "id": 1348293073
  },
  {
    "city": "Hendon",
    "city_ascii": "Hendon",
    "lat": 51.5837,
    "lng": -0.2252,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Barnet",
    "capital": "",
    "population": 52972,
    "id": 1826052476
  },
  {
    "city": "Arcadia",
    "city_ascii": "Arcadia",
    "lat": 34.1342,
    "lng": -118.0373,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 57939,
    "id": 1840019223
  },
  {
    "city": "Hilden",
    "city_ascii": "Hilden",
    "lat": 51.1714,
    "lng": 6.9394,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 55764,
    "id": 1276577152
  },
  {
    "city": "Slavonski Brod",
    "city_ascii": "Slavonski Brod",
    "lat": 45.1553,
    "lng": 18.0144,
    "country": "Croatia",
    "iso2": "HR",
    "iso3": "HRV",
    "admin_name": "Brodsko-Posavska Županija",
    "capital": "admin",
    "population": 56769,
    "id": 1191097030
  },
  {
    "city": "Magong",
    "city_ascii": "Magong",
    "lat": 23.5667,
    "lng": 119.5833,
    "country": "Taiwan",
    "iso2": "TW",
    "iso3": "TWN",
    "admin_name": "Penghu",
    "capital": "admin",
    "population": 56435,
    "id": 1158297836
  },
  {
    "city": "Jocotitlán",
    "city_ascii": "Jocotitlan",
    "lat": 19.7072,
    "lng": -99.7867,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "México",
    "capital": "minor",
    "population": 55403,
    "id": 1484167307
  },
  {
    "city": "Umm el Faḥm",
    "city_ascii": "Umm el Fahm",
    "lat": 32.5158,
    "lng": 35.1525,
    "country": "Israel",
    "iso2": "IL",
    "iso3": "ISR",
    "admin_name": "Haifa",
    "capital": "",
    "population": 55300,
    "id": 1376814378
  },
  {
    "city": "Alfortville",
    "city_ascii": "Alfortville",
    "lat": 48.805,
    "lng": 2.4239,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 43881,
    "id": 1250857642
  },
  {
    "city": "Samut Prakan",
    "city_ascii": "Samut Prakan",
    "lat": 13.6004,
    "lng": 100.5964,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Samut Prakan",
    "capital": "admin",
    "population": 51309,
    "id": 1764159228
  },
  {
    "city": "Nuevo Casas Grandes",
    "city_ascii": "Nuevo Casas Grandes",
    "lat": 30.4167,
    "lng": -107.9167,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Chihuahua",
    "capital": "minor",
    "population": 55553,
    "id": 1484004002
  },
  {
    "city": "Kidderminster",
    "city_ascii": "Kidderminster",
    "lat": 52.3885,
    "lng": -2.249,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Worcestershire",
    "capital": "",
    "population": 55530,
    "id": 1826052030
  },
  {
    "city": "Lipjan",
    "city_ascii": "Lipjan",
    "lat": 42.53,
    "lng": 21.1386,
    "country": "Kosovo",
    "iso2": "XK",
    "iso3": "XKS",
    "admin_name": "Lipjan",
    "capital": "admin",
    "population": 57605,
    "id": 1901682048
  },
  {
    "city": "Zumpango",
    "city_ascii": "Zumpango",
    "lat": 19.7969,
    "lng": -99.0992,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "México",
    "capital": "minor",
    "population": 50742,
    "id": 1484625824
  },
  {
    "city": "Crosby",
    "city_ascii": "Crosby",
    "lat": 53.4872,
    "lng": -3.0343,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Sefton",
    "capital": "",
    "population": 50044,
    "id": 1826899809
  },
  {
    "city": "Al Mafraq",
    "city_ascii": "Al Mafraq",
    "lat": 32.2833,
    "lng": 36.2333,
    "country": "Jordan",
    "iso2": "JO",
    "iso3": "JOR",
    "admin_name": "Al Mafraq",
    "capital": "admin",
    "population": 57118,
    "id": 1400040712
  },
  {
    "city": "Hamilton",
    "city_ascii": "Hamilton",
    "lat": 55.777,
    "lng": -4.039,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "South Lanarkshire",
    "capital": "",
    "population": 54230,
    "id": 1826692620
  },
  {
    "city": "Altrincham",
    "city_ascii": "Altrincham",
    "lat": 53.3838,
    "lng": -2.3547,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Trafford",
    "capital": "",
    "population": 52419,
    "id": 1826280426
  },
  {
    "city": "Tamiami",
    "city_ascii": "Tamiami",
    "lat": 25.7556,
    "lng": -80.4016,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 57514,
    "id": 1840029085
  },
  {
    "city": "Huntington Park",
    "city_ascii": "Huntington Park",
    "lat": 33.98,
    "lng": -118.2167,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 57509,
    "id": 1840019235
  },
  {
    "city": "Mafeteng",
    "city_ascii": "Mafeteng",
    "lat": -29.8167,
    "lng": 27.25,
    "country": "Lesotho",
    "iso2": "LS",
    "iso3": "LSO",
    "admin_name": "Mafeteng",
    "capital": "admin",
    "population": 57059,
    "id": 1426506047
  },
  {
    "city": "Medford",
    "city_ascii": "Medford",
    "lat": 42.4234,
    "lng": -71.1087,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 57341,
    "id": 1840000431
  },
  {
    "city": "Chinhoyi",
    "city_ascii": "Chinhoyi",
    "lat": -17.35,
    "lng": 30.2,
    "country": "Zimbabwe",
    "iso2": "ZW",
    "iso3": "ZWE",
    "admin_name": "Mashonaland West",
    "capital": "admin",
    "population": 56794,
    "id": 1716523245
  },
  {
    "city": "Gümüşhane",
    "city_ascii": "Gumushane",
    "lat": 40.4597,
    "lng": 39.4778,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Gümüşhane",
    "capital": "admin",
    "population": 57269,
    "id": 1792559945
  },
  {
    "city": "Pithāpuram",
    "city_ascii": "Pithapuram",
    "lat": 17.1167,
    "lng": 82.2667,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Andhra Pradesh",
    "capital": "",
    "population": 54859,
    "id": 1356440541
  },
  {
    "city": "Araguaína",
    "city_ascii": "Araguaina",
    "lat": -7.19,
    "lng": -48.21,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Tocantins",
    "capital": "",
    "population": 50444,
    "id": 1076632868
  },
  {
    "city": "Belize City",
    "city_ascii": "Belize City",
    "lat": 17.4986,
    "lng": -88.1886,
    "country": "Belize",
    "iso2": "BZ",
    "iso3": "BLZ",
    "admin_name": "Belize",
    "capital": "admin",
    "population": 57169,
    "id": 1084150679
  },
  {
    "city": "Acatzingo",
    "city_ascii": "Acatzingo",
    "lat": 18.9817,
    "lng": -97.7822,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Puebla",
    "capital": "minor",
    "population": 52078,
    "id": 1484022961
  },
  {
    "city": "Barri",
    "city_ascii": "Barri",
    "lat": 51.405,
    "lng": -3.27,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Vale of Glamorgan, The",
    "capital": "",
    "population": 51502,
    "id": 1826605492
  },
  {
    "city": "Adrar",
    "city_ascii": "Adrar",
    "lat": 27.8742,
    "lng": -0.2939,
    "country": "Algeria",
    "iso2": "DZ",
    "iso3": "DZA",
    "admin_name": "Adrar",
    "capital": "admin",
    "population": 56910,
    "id": 1012462113
  },
  {
    "city": "Biel/Bienne",
    "city_ascii": "Biel/Bienne",
    "lat": 47.1372,
    "lng": 7.2472,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Bern",
    "capital": "",
    "population": 54456,
    "id": 1756649723
  },
  {
    "city": "Bắc Giang",
    "city_ascii": "Bac Giang",
    "lat": 21.2731,
    "lng": 106.1947,
    "country": "Vietnam",
    "iso2": "VN",
    "iso3": "VNM",
    "admin_name": "Bắc Giang",
    "capital": "admin",
    "population": 53728,
    "id": 1704256754
  },
  {
    "city": "Ushuaia",
    "city_ascii": "Ushuaia",
    "lat": -54.8072,
    "lng": -68.3044,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Tierra del Fuego, Antártida e Islas del Atlántico Sur",
    "capital": "admin",
    "population": 56956,
    "id": 1032847885
  },
  {
    "city": "Royal Leamington Spa",
    "city_ascii": "Royal Leamington Spa",
    "lat": 52.292,
    "lng": -1.537,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Warwickshire",
    "capital": "",
    "population": 49662,
    "id": 1826633006
  },
  {
    "city": "Jelgava",
    "city_ascii": "Jelgava",
    "lat": 56.6522,
    "lng": 23.7244,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Jelgavas Novads",
    "capital": "admin",
    "population": 55972,
    "id": 1428370571
  },
  {
    "city": "Zalău",
    "city_ascii": "Zalau",
    "lat": 47.1911,
    "lng": 23.0572,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Sălaj",
    "capital": "admin",
    "population": 56202,
    "id": 1642791988
  },
  {
    "city": "Dunfermline",
    "city_ascii": "Dunfermline",
    "lat": 56.0719,
    "lng": -3.4393,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Fife",
    "capital": "",
    "population": 54050,
    "id": 1826424354
  },
  {
    "city": "Itacoatiara",
    "city_ascii": "Itacoatiara",
    "lat": -3.1431,
    "lng": -58.4442,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Amazonas",
    "capital": "",
    "population": 51509,
    "id": 1076759384
  },
  {
    "city": "Sfântu-Gheorghe",
    "city_ascii": "Sfantu-Gheorghe",
    "lat": 45.8636,
    "lng": 25.7875,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Covasna",
    "capital": "admin",
    "population": 56006,
    "id": 1642189178
  },
  {
    "city": "Muğla",
    "city_ascii": "Mugla",
    "lat": 37.2167,
    "lng": 28.3667,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Muğla",
    "capital": "admin",
    "population": 56619,
    "id": 1792992104
  },
  {
    "city": "Llanelli",
    "city_ascii": "Llanelli",
    "lat": 51.684,
    "lng": -4.163,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Carmarthenshire",
    "capital": "",
    "population": 49591,
    "id": 1826934854
  },
  {
    "city": "Tandag",
    "city_ascii": "Tandag",
    "lat": 9.0667,
    "lng": 126.1833,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Surigao del Sur",
    "capital": "admin",
    "population": 56364,
    "id": 1608155856
  },
  {
    "city": "Phra Nakhon Si Ayutthaya",
    "city_ascii": "Phra Nakhon Si Ayutthaya",
    "lat": 14.355,
    "lng": 100.565,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Phra Nakhon Si Ayutthaya",
    "capital": "admin",
    "population": 52952,
    "id": 1764002329
  },
  {
    "city": "Kranj",
    "city_ascii": "Kranj",
    "lat": 46.2389,
    "lng": 14.3556,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Kranj",
    "capital": "admin",
    "population": 56081,
    "id": 1705842003
  },
  {
    "city": "Salamá",
    "city_ascii": "Salama",
    "lat": 15.1052,
    "lng": -90.316,
    "country": "Guatemala",
    "iso2": "GT",
    "iso3": "GTM",
    "admin_name": "Baja Verapaz",
    "capital": "admin",
    "population": 56359,
    "id": 1320223205
  },
  {
    "city": "Zouerate",
    "city_ascii": "Zouerate",
    "lat": 22.7344,
    "lng": -12.4725,
    "country": "Mauritania",
    "iso2": "MR",
    "iso3": "MRT",
    "admin_name": "Tiris Zemmour",
    "capital": "admin",
    "population": 56345,
    "id": 1478799785
  },
  {
    "city": "Trenčín",
    "city_ascii": "Trencin",
    "lat": 48.8942,
    "lng": 18.0406,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Trenčiansky",
    "capital": "admin",
    "population": 55593,
    "id": 1703029695
  },
  {
    "city": "Rahovec",
    "city_ascii": "Rahovec",
    "lat": 42.3994,
    "lng": 20.6547,
    "country": "Kosovo",
    "iso2": "XK",
    "iso3": "XKS",
    "admin_name": "Rahovec",
    "capital": "admin",
    "population": 56208,
    "id": 1901336358
  },
  {
    "city": "Vaslui",
    "city_ascii": "Vaslui",
    "lat": 46.6383,
    "lng": 27.7292,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Vaslui",
    "capital": "admin",
    "population": 55407,
    "id": 1642644428
  },
  {
    "city": "Saint-Quentin",
    "city_ascii": "Saint-Quentin",
    "lat": 49.8486,
    "lng": 3.2864,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Hauts-de-France",
    "capital": "minor",
    "population": 53816,
    "id": 1250806002
  },
  {
    "city": "Castelo Branco",
    "city_ascii": "Castelo Branco",
    "lat": 39.823,
    "lng": -7.4931,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Castelo Branco",
    "capital": "admin",
    "population": 56109,
    "id": 1620277400
  },
  {
    "city": "Rio Tinto",
    "city_ascii": "Rio Tinto",
    "lat": 41.1833,
    "lng": -8.5667,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Porto",
    "capital": "",
    "population": 50713,
    "id": 1620258719
  },
  {
    "city": "Rio Verde",
    "city_ascii": "Rio Verde",
    "lat": -17.8196,
    "lng": -50.93,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Goiás",
    "capital": "",
    "population": 48318,
    "id": 1076844683
  },
  {
    "city": "Sankt Pölten",
    "city_ascii": "Sankt Polten",
    "lat": 48.2,
    "lng": 15.6167,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Niederösterreich",
    "capital": "admin",
    "population": 55514,
    "id": 1040313109
  },
  {
    "city": "Vigan",
    "city_ascii": "Vigan",
    "lat": 17.5747,
    "lng": 120.3869,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Ilocos Sur",
    "capital": "admin",
    "population": 53879,
    "id": 1608368724
  },
  {
    "city": "Weymouth",
    "city_ascii": "Weymouth",
    "lat": 50.613,
    "lng": -2.457,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Dorset",
    "capital": "",
    "population": 53068,
    "id": 1826316742
  },
  {
    "city": "Santa Rosa de Copán",
    "city_ascii": "Santa Rosa de Copan",
    "lat": 14.7667,
    "lng": -88.7833,
    "country": "Honduras",
    "iso2": "HN",
    "iso3": "HND",
    "admin_name": "Copán",
    "capital": "admin",
    "population": 56000,
    "id": 1340050563
  },
  {
    "city": "Halmstad",
    "city_ascii": "Halmstad",
    "lat": 56.6718,
    "lng": 12.8556,
    "country": "Sweden",
    "iso2": "SE",
    "iso3": "SWE",
    "admin_name": "Halland",
    "capital": "admin",
    "population": 55657,
    "id": 1752392511
  },
  {
    "city": "Barra do Garças",
    "city_ascii": "Barra do Garcas",
    "lat": -15.8796,
    "lng": -52.26,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Mato Grosso",
    "capital": "",
    "population": 52398,
    "id": 1076425403
  },
  {
    "city": "Florida",
    "city_ascii": "Florida",
    "lat": -34.5167,
    "lng": -58.5,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires",
    "capital": "",
    "population": 48158,
    "id": 1032721967
  },
  {
    "city": "Ohrid",
    "city_ascii": "Ohrid",
    "lat": 41.1169,
    "lng": 20.8019,
    "country": "Macedonia",
    "iso2": "MK",
    "iso3": "MKD",
    "admin_name": "Ohrid",
    "capital": "admin",
    "population": 55749,
    "id": 1807586875
  },
  {
    "city": "Greenford",
    "city_ascii": "Greenford",
    "lat": 51.5299,
    "lng": -0.3488,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Ealing",
    "capital": "",
    "population": 46787,
    "id": 1826685341
  },
  {
    "city": "Ponte Nova",
    "city_ascii": "Ponte Nova",
    "lat": -20.4164,
    "lng": -42.9086,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Minas Gerais",
    "capital": "",
    "population": 48187,
    "id": 1076567178
  },
  {
    "city": "Matamoros",
    "city_ascii": "Matamoros",
    "lat": 25.533,
    "lng": -103.25,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Coahuila de Zaragoza",
    "capital": "minor",
    "population": 48511,
    "id": 1484930279
  },
  {
    "city": "Guliston",
    "city_ascii": "Guliston",
    "lat": 40.4833,
    "lng": 68.7833,
    "country": "Uzbekistan",
    "iso2": "UZ",
    "iso3": "UZB",
    "admin_name": "Sirdaryo",
    "capital": "admin",
    "population": 55600,
    "id": 1860505287
  },
  {
    "city": "Rowley Regis",
    "city_ascii": "Rowley Regis",
    "lat": 52.48,
    "lng": -2.06,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Sandwell",
    "capital": "",
    "population": 50257,
    "id": 1826180390
  },
  {
    "city": "Bootle",
    "city_ascii": "Bootle",
    "lat": 53.4457,
    "lng": -2.9891,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Sefton",
    "capital": "",
    "population": 51394,
    "id": 1826320670
  },
  {
    "city": "Francisco I. Madero",
    "city_ascii": "Francisco I. Madero",
    "lat": 25.7753,
    "lng": -103.2731,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Coahuila de Zaragoza",
    "capital": "minor",
    "population": 50084,
    "id": 1484764993
  },
  {
    "city": "New Brunswick",
    "city_ascii": "New Brunswick",
    "lat": 40.487,
    "lng": -74.445,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 55676,
    "id": 1840001339
  },
  {
    "city": "Aloha",
    "city_ascii": "Aloha",
    "lat": 45.492,
    "lng": -122.8726,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 55492,
    "id": 1840017414
  },
  {
    "city": "Lautoka",
    "city_ascii": "Lautoka",
    "lat": -17.6242,
    "lng": 177.4528,
    "country": "Fiji",
    "iso2": "FJ",
    "iso3": "FJI",
    "admin_name": "Ba",
    "capital": "",
    "population": 52220,
    "id": 1242457077
  },
  {
    "city": "Senhor do Bonfim",
    "city_ascii": "Senhor do Bonfim",
    "lat": -10.4614,
    "lng": -40.1894,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Bahia",
    "capital": "",
    "population": 48471,
    "id": 1076513309
  },
  {
    "city": "Itaberaba",
    "city_ascii": "Itaberaba",
    "lat": -12.5275,
    "lng": -40.3069,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Bahia",
    "capital": "",
    "population": 47301,
    "id": 1076160849
  },
  {
    "city": "Fountain Valley",
    "city_ascii": "Fountain Valley",
    "lat": 33.7105,
    "lng": -117.9514,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 55357,
    "id": 1840020575
  },
  {
    "city": "Villagrán",
    "city_ascii": "Villagran",
    "lat": 20.517,
    "lng": -100.983,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Guanajuato",
    "capital": "minor",
    "population": 45941,
    "id": 1484237393
  },
  {
    "city": "Massy",
    "city_ascii": "Massy",
    "lat": 48.7309,
    "lng": 2.2713,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 50833,
    "id": 1250651220
  },
  {
    "city": "Paignton",
    "city_ascii": "Paignton",
    "lat": 50.4353,
    "lng": -3.5625,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Devon",
    "capital": "",
    "population": 49021,
    "id": 1826935894
  },
  {
    "city": "Lampang",
    "city_ascii": "Lampang",
    "lat": 18.3,
    "lng": 99.5,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Lampang",
    "capital": "admin",
    "population": 55044,
    "id": 1764081847
  },
  {
    "city": "Lancaster",
    "city_ascii": "Lancaster",
    "lat": 54.047,
    "lng": -2.801,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Lancashire",
    "capital": "",
    "population": 52234,
    "id": 1826197341
  },
  {
    "city": "Vranje",
    "city_ascii": "Vranje",
    "lat": 42.5542,
    "lng": 21.8972,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Vranje",
    "capital": "admin",
    "population": 55138,
    "id": 1688474456
  },
  {
    "city": "Abū Qīr",
    "city_ascii": "Abu Qir",
    "lat": 31.3167,
    "lng": 30.0667,
    "country": "Egypt",
    "iso2": "EG",
    "iso3": "EGY",
    "admin_name": "Al Iskandarīyah",
    "capital": "",
    "population": 37997,
    "id": 1818093442
  },
  {
    "city": "Ithaca",
    "city_ascii": "Ithaca",
    "lat": 42.4442,
    "lng": -76.5032,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 55137,
    "id": 1840000442
  },
  {
    "city": "Chiapa de Corzo",
    "city_ascii": "Chiapa de Corzo",
    "lat": 16.7083,
    "lng": -93.0169,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Chiapas",
    "capital": "minor",
    "population": 45077,
    "id": 1484314420
  },
  {
    "city": "Madīnat Ḩamad",
    "city_ascii": "Madinat Hamad",
    "lat": 26.1128,
    "lng": 50.5139,
    "country": "Bahrain",
    "iso2": "BH",
    "iso3": "BHR",
    "admin_name": "Ash Shamālīyah",
    "capital": "",
    "population": 52700,
    "id": 1048909146
  },
  {
    "city": "Oak Lawn",
    "city_ascii": "Oak Lawn",
    "lat": 41.7139,
    "lng": -87.7528,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 55022,
    "id": 1840011306
  },
  {
    "city": "Quixadá",
    "city_ascii": "Quixada",
    "lat": -4.9714,
    "lng": -39.0153,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Ceará",
    "capital": "",
    "population": 49328,
    "id": 1076173437
  },
  {
    "city": "Padangpanjang",
    "city_ascii": "Padangpanjang",
    "lat": -0.45,
    "lng": 100.4167,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Sumatera Barat",
    "capital": "",
    "population": 52422,
    "id": 1360231049
  },
  {
    "city": "Mikkeli",
    "city_ascii": "Mikkeli",
    "lat": 61.6875,
    "lng": 27.2736,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Etelä-Savo",
    "capital": "admin",
    "population": 54665,
    "id": 1246875185
  },
  {
    "city": "Corbeil-Essonnes",
    "city_ascii": "Corbeil-Essonnes",
    "lat": 48.6139,
    "lng": 2.482,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 51292,
    "id": 1250742467
  },
  {
    "city": "Malishevë",
    "city_ascii": "Malisheve",
    "lat": 42.4828,
    "lng": 20.7458,
    "country": "Kosovo",
    "iso2": "XK",
    "iso3": "XKS",
    "admin_name": "Malishevë",
    "capital": "admin",
    "population": 54613,
    "id": 1901597212
  },
  {
    "city": "Breves",
    "city_ascii": "Breves",
    "lat": -1.6822,
    "lng": -50.4803,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Pará",
    "capital": "",
    "population": 47164,
    "id": 1076061751
  },
  {
    "city": "Busia",
    "city_ascii": "Busia",
    "lat": 0.4669,
    "lng": 34.09,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Busia",
    "capital": "admin",
    "population": 47100,
    "id": 1800460306
  },
  {
    "city": "Morden",
    "city_ascii": "Morden",
    "lat": 51.4015,
    "lng": -0.1949,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Merton",
    "capital": "",
    "population": 48233,
    "id": 1826667903
  },
  {
    "city": "Catford",
    "city_ascii": "Catford",
    "lat": 51.4452,
    "lng": -0.0207,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Lewisham",
    "capital": "",
    "population": 44905,
    "id": 1826259011
  },
  {
    "city": "Folkestone",
    "city_ascii": "Folkestone",
    "lat": 51.081,
    "lng": 1.166,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Kent",
    "capital": "",
    "population": 46698,
    "id": 1826257230
  },
  {
    "city": "Berwyn",
    "city_ascii": "Berwyn",
    "lat": 41.8433,
    "lng": -87.7909,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 54391,
    "id": 1840007027
  },
  {
    "city": "Chārīkār",
    "city_ascii": "Charikar",
    "lat": 35.0183,
    "lng": 69.1679,
    "country": "Afghanistan",
    "iso2": "AF",
    "iso3": "AFG",
    "admin_name": "Parwān",
    "capital": "admin",
    "population": 53676,
    "id": 1004817775
  },
  {
    "city": "Ibiza",
    "city_ascii": "Ibiza",
    "lat": 38.9089,
    "lng": 1.4328,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Balearic Islands",
    "capital": "",
    "population": 49783,
    "id": 1724123244
  },
  {
    "city": "Shiraoka",
    "city_ascii": "Shiraoka",
    "lat": 36.0189,
    "lng": 139.6769,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Saitama",
    "capital": "",
    "population": 52094,
    "id": 1392337140
  },
  {
    "city": "Prachuap Khiri Khan",
    "city_ascii": "Prachuap Khiri Khan",
    "lat": 11.8067,
    "lng": 99.7956,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Prachuap Khiri Khan",
    "capital": "admin",
    "population": 54018,
    "id": 1764877766
  },
  {
    "city": "Korçë",
    "city_ascii": "Korce",
    "lat": 40.6167,
    "lng": 20.7667,
    "country": "Albania",
    "iso2": "AL",
    "iso3": "ALB",
    "admin_name": "Korçë",
    "capital": "admin",
    "population": 51152,
    "id": 1008361921
  },
  {
    "city": "Rosemead",
    "city_ascii": "Rosemead",
    "lat": 34.0689,
    "lng": -118.0823,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 54058,
    "id": 1840020495
  },
  {
    "city": "Tulcán",
    "city_ascii": "Tulcan",
    "lat": 0.8117,
    "lng": -77.7186,
    "country": "Ecuador",
    "iso2": "EC",
    "iso3": "ECU",
    "admin_name": "Carchi",
    "capital": "admin",
    "population": 53558,
    "id": 1218976878
  },
  {
    "city": "Irvington",
    "city_ascii": "Irvington",
    "lat": 40.7242,
    "lng": -74.2318,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 54035,
    "id": 1840080906
  },
  {
    "city": "Paramount",
    "city_ascii": "Paramount",
    "lat": 33.8976,
    "lng": -118.1652,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 53955,
    "id": 1840020504
  },
  {
    "city": "Louangphabang",
    "city_ascii": "Louangphabang",
    "lat": 19.8931,
    "lng": 102.1381,
    "country": "Laos",
    "iso2": "LA",
    "iso3": "LAO",
    "admin_name": "Louangphabang",
    "capital": "admin",
    "population": 53792,
    "id": 1418530759
  },
  {
    "city": "Stratford",
    "city_ascii": "Stratford",
    "lat": 51.5423,
    "lng": -0.0026,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Newham",
    "capital": "",
    "population": 51387,
    "id": 1826465421
  },
  {
    "city": "Iganga",
    "city_ascii": "Iganga",
    "lat": 0.6092,
    "lng": 33.4686,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Iganga",
    "capital": "admin",
    "population": 45024,
    "id": 1800987671
  },
  {
    "city": "Sakon Nakhon",
    "city_ascii": "Sakon Nakhon",
    "lat": 17.1564,
    "lng": 104.1456,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Sakon Nakhon",
    "capital": "admin",
    "population": 53618,
    "id": 1764476362
  },
  {
    "city": "Cagnes-sur-Mer",
    "city_ascii": "Cagnes-sur-Mer",
    "lat": 43.6644,
    "lng": 7.1489,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Provence-Alpes-Côte d’Azur",
    "capital": "",
    "population": 50928,
    "id": 1250261503
  },
  {
    "city": "Sutton in Ashfield",
    "city_ascii": "Sutton in Ashfield",
    "lat": 53.125,
    "lng": -1.261,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Nottinghamshire",
    "capital": "",
    "population": 49023,
    "id": 1826247882
  },
  {
    "city": "Pursat",
    "city_ascii": "Pursat",
    "lat": 12.5337,
    "lng": 103.9167,
    "country": "Cambodia",
    "iso2": "KH",
    "iso3": "KHM",
    "admin_name": "Pursat",
    "capital": "admin",
    "population": 52476,
    "id": 1116566865
  },
  {
    "city": "Macclesfield",
    "city_ascii": "Macclesfield",
    "lat": 53.2581,
    "lng": -2.1274,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Cheshire East",
    "capital": "",
    "population": 51482,
    "id": 1826522277
  },
  {
    "city": "Whangarei",
    "city_ascii": "Whangarei",
    "lat": -35.725,
    "lng": 174.3236,
    "country": "New Zealand",
    "iso2": "NZ",
    "iso3": "NZL",
    "admin_name": "Northland",
    "capital": "admin",
    "population": 52600,
    "id": 1554000636
  },
  {
    "city": "Eger",
    "city_ascii": "Eger",
    "lat": 47.8989,
    "lng": 20.3747,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Heves",
    "capital": "admin",
    "population": 52898,
    "id": 1348559499
  },
  {
    "city": "Nes Ẕiyyona",
    "city_ascii": "Nes Ziyyona",
    "lat": 31.9333,
    "lng": 34.8,
    "country": "Israel",
    "iso2": "IL",
    "iso3": "ISR",
    "admin_name": "Central",
    "capital": "",
    "population": 50200,
    "id": 1376745785
  },
  {
    "city": "Santiago Teyahualco",
    "city_ascii": "Santiago Teyahualco",
    "lat": 19.6667,
    "lng": -99.1333,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "México",
    "capital": "",
    "population": 47547,
    "id": 1484381311
  },
  {
    "city": "Marondera",
    "city_ascii": "Marondera",
    "lat": -18.1833,
    "lng": 31.55,
    "country": "Zimbabwe",
    "iso2": "ZW",
    "iso3": "ZWE",
    "admin_name": "Mashonaland East",
    "capital": "admin",
    "population": 52283,
    "id": 1716375676
  },
  {
    "city": "Wellingborough",
    "city_ascii": "Wellingborough",
    "lat": 52.2939,
    "lng": -0.6964,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Northamptonshire",
    "capital": "",
    "population": 49128,
    "id": 1826752476
  },
  {
    "city": "Moskovskiy",
    "city_ascii": "Moskovskiy",
    "lat": 55.5991,
    "lng": 37.355,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Moskovskaya Oblast’",
    "capital": "",
    "population": 50167,
    "id": 1643344934
  },
  {
    "city": "Rosny-sous-Bois",
    "city_ascii": "Rosny-sous-Bois",
    "lat": 48.8667,
    "lng": 2.4833,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 46207,
    "id": 1250643229
  },
  {
    "city": "Maha Sarakham",
    "city_ascii": "Maha Sarakham",
    "lat": 16.1772,
    "lng": 103.3008,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Maha Sarakham",
    "capital": "admin",
    "population": 52866,
    "id": 1764568961
  },
  {
    "city": "Revere",
    "city_ascii": "Revere",
    "lat": 42.4192,
    "lng": -71.0036,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 53073,
    "id": 1840000457
  },
  {
    "city": "Barra do Corda",
    "city_ascii": "Barra do Corda",
    "lat": -5.5031,
    "lng": -45.2369,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Maranhão",
    "capital": "",
    "population": 48901,
    "id": 1076745135
  },
  {
    "city": "Helena",
    "city_ascii": "Helena",
    "lat": 46.5965,
    "lng": -112.0202,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Montana",
    "capital": "admin",
    "population": 52936,
    "id": 1840019820
  },
  {
    "city": "Takeo",
    "city_ascii": "Takeo",
    "lat": 10.9833,
    "lng": 104.7833,
    "country": "Cambodia",
    "iso2": "KH",
    "iso3": "KHM",
    "admin_name": "Takeo",
    "capital": "admin",
    "population": 52000,
    "id": 1116674518
  },
  {
    "city": "Barnet",
    "city_ascii": "Barnet",
    "lat": 51.6444,
    "lng": -0.1997,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Barnet",
    "capital": "",
    "population": 47359,
    "id": 1826246190
  },
  {
    "city": "Vejle",
    "city_ascii": "Vejle",
    "lat": 55.709,
    "lng": 9.535,
    "country": "Denmark",
    "iso2": "DK",
    "iso3": "DNK",
    "admin_name": "Syddanmark",
    "capital": "admin",
    "population": 51177,
    "id": 1208585023
  },
  {
    "city": "Kirkcaldy",
    "city_ascii": "Kirkcaldy",
    "lat": 56.1107,
    "lng": -3.1674,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Fife",
    "capital": "",
    "population": 50180,
    "id": 1826174957
  },
  {
    "city": "Aspen Hill",
    "city_ascii": "Aspen Hill",
    "lat": 39.0928,
    "lng": -77.0823,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 52807,
    "id": 1840005825
  },
  {
    "city": "Leopoldina",
    "city_ascii": "Leopoldina",
    "lat": -21.5319,
    "lng": -42.6431,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Minas Gerais",
    "capital": "",
    "population": 46742,
    "id": 1076352077
  },
  {
    "city": "Cumbernauld",
    "city_ascii": "Cumbernauld",
    "lat": 55.945,
    "lng": -3.994,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "North Lanarkshire",
    "capital": "",
    "population": 50430,
    "id": 1826862515
  },
  {
    "city": "Valladolid",
    "city_ascii": "Valladolid",
    "lat": 20.6894,
    "lng": -88.2017,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Yucatán",
    "capital": "",
    "population": 48973,
    "id": 1484332013
  },
  {
    "city": "West New York",
    "city_ascii": "West New York",
    "lat": 40.7856,
    "lng": -74.0093,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 52723,
    "id": 1840001035
  },
  {
    "city": "Mangochi",
    "city_ascii": "Mangochi",
    "lat": -14.4781,
    "lng": 35.2645,
    "country": "Malawi",
    "iso2": "MW",
    "iso3": "MWI",
    "admin_name": "Mangochi",
    "capital": "admin",
    "population": 51429,
    "id": 1454766726
  },
  {
    "city": "Hoboken",
    "city_ascii": "Hoboken",
    "lat": 40.7453,
    "lng": -74.0279,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 52677,
    "id": 1840003599
  },
  {
    "city": "Mongu",
    "city_ascii": "Mongu",
    "lat": -15.2796,
    "lng": 23.12,
    "country": "Zambia",
    "iso2": "ZM",
    "iso3": "ZMB",
    "admin_name": "Western",
    "capital": "admin",
    "population": 52534,
    "id": 1894761291
  },
  {
    "city": "Alytus",
    "city_ascii": "Alytus",
    "lat": 54.4014,
    "lng": 24.0492,
    "country": "Lithuania",
    "iso2": "LT",
    "iso3": "LTU",
    "admin_name": "Alytaus Miestas",
    "capital": "admin",
    "population": 51534,
    "id": 1440273296
  },
  {
    "city": "Choisy-le-Roi",
    "city_ascii": "Choisy-le-Roi",
    "lat": 48.763,
    "lng": 2.409,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 45331,
    "id": 1250504037
  },
  {
    "city": "Myrnohrad",
    "city_ascii": "Myrnohrad",
    "lat": 48.3022,
    "lng": 37.2614,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Donets’ka Oblast’",
    "capital": "",
    "population": 50360,
    "id": 1804762140
  },
  {
    "city": "Aracati",
    "city_ascii": "Aracati",
    "lat": -4.5617,
    "lng": -37.7697,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Ceará",
    "capital": "",
    "population": 44293,
    "id": 1076708817
  },
  {
    "city": "Iwakura",
    "city_ascii": "Iwakura",
    "lat": 35.2833,
    "lng": 136.8667,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Aichi",
    "capital": "",
    "population": 47839,
    "id": 1392765544
  },
  {
    "city": "Boujad",
    "city_ascii": "Boujad",
    "lat": 32.76,
    "lng": -6.4,
    "country": "Morocco",
    "iso2": "MA",
    "iso3": "MAR",
    "admin_name": "Béni Mellal-Khénifra",
    "capital": "",
    "population": 46893,
    "id": 1504259447
  },
  {
    "city": "Oak Park",
    "city_ascii": "Oak Park",
    "lat": 41.8872,
    "lng": -87.7899,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 52381,
    "id": 1840011307
  },
  {
    "city": "Batley",
    "city_ascii": "Batley",
    "lat": 53.716,
    "lng": -1.627,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Kirklees",
    "capital": "",
    "population": 49448,
    "id": 1826037459
  },
  {
    "city": "Jinotepe",
    "city_ascii": "Jinotepe",
    "lat": 11.85,
    "lng": -86.2,
    "country": "Nicaragua",
    "iso2": "NI",
    "iso3": "NIC",
    "admin_name": "Carazo",
    "capital": "admin",
    "population": 52175,
    "id": 1558246417
  },
  {
    "city": "Ain El Aouda",
    "city_ascii": "Ain El Aouda",
    "lat": 33.8111,
    "lng": -6.7922,
    "country": "Morocco",
    "iso2": "MA",
    "iso3": "MAR",
    "admin_name": "Rabat-Salé-Kénitra",
    "capital": "",
    "population": 49816,
    "id": 1504400263
  },
  {
    "city": "Takahama",
    "city_ascii": "Takahama",
    "lat": 34.9276,
    "lng": 136.9877,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Aichi",
    "capital": "",
    "population": 48561,
    "id": 1392860759
  },
  {
    "city": "Elmshorn",
    "city_ascii": "Elmshorn",
    "lat": 53.7547,
    "lng": 9.6536,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Schleswig-Holstein",
    "capital": "",
    "population": 49883,
    "id": 1276091723
  },
  {
    "city": "Floriano",
    "city_ascii": "Floriano",
    "lat": -6.7669,
    "lng": -43.0225,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Piauí",
    "capital": "",
    "population": 48587,
    "id": 1076813660
  },
  {
    "city": "Bang Bua Thong",
    "city_ascii": "Bang Bua Thong",
    "lat": 13.9099,
    "lng": 100.4263,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Nonthaburi",
    "capital": "minor",
    "population": 50141,
    "id": 1764694888
  },
  {
    "city": "Lamía",
    "city_ascii": "Lamia",
    "lat": 38.8972,
    "lng": 22.4311,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Stereá Elláda",
    "capital": "admin",
    "population": 52006,
    "id": 1300400935
  },
  {
    "city": "Noisy-le-Sec",
    "city_ascii": "Noisy-le-Sec",
    "lat": 48.8894,
    "lng": 2.4503,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 44136,
    "id": 1250727880
  },
  {
    "city": "Chake Chake",
    "city_ascii": "Chake Chake",
    "lat": -5.2395,
    "lng": 39.77,
    "country": "Tanzania",
    "iso2": "TZ",
    "iso3": "TZA",
    "admin_name": "Pemba South",
    "capital": "admin",
    "population": 49959,
    "id": 1834409018
  },
  {
    "city": "Lənkəran",
    "city_ascii": "Lankaran",
    "lat": 38.7536,
    "lng": 48.8511,
    "country": "Azerbaijan",
    "iso2": "AZ",
    "iso3": "AZE",
    "admin_name": "Lənkəran",
    "capital": "admin",
    "population": 51300,
    "id": 1031218031
  },
  {
    "city": "Suzukawa",
    "city_ascii": "Suzukawa",
    "lat": 35.3731,
    "lng": 139.3842,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Kanagawa",
    "capital": "",
    "population": 48379,
    "id": 1392676251
  },
  {
    "city": "Vila Real",
    "city_ascii": "Vila Real",
    "lat": 41.3002,
    "lng": -7.7398,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Vila Real",
    "capital": "admin",
    "population": 51850,
    "id": 1620292123
  },
  {
    "city": "Ocosingo",
    "city_ascii": "Ocosingo",
    "lat": 16.9072,
    "lng": -92.0961,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Chiapas",
    "capital": "minor",
    "population": 41878,
    "id": 1484937914
  },
  {
    "city": "Campo Belo",
    "city_ascii": "Campo Belo",
    "lat": -20.8896,
    "lng": -45.28,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Minas Gerais",
    "capital": "",
    "population": 47491,
    "id": 1076477943
  },
  {
    "city": "Cametá",
    "city_ascii": "Cameta",
    "lat": -2.2396,
    "lng": -49.51,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Pará",
    "capital": "",
    "population": 45200,
    "id": 1076010069
  },
  {
    "city": "Vaulx-en-Velin",
    "city_ascii": "Vaulx-en-Velin",
    "lat": 45.7768,
    "lng": 4.9186,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Auvergne-Rhône-Alpes",
    "capital": "",
    "population": 49658,
    "id": 1250814472
  },
  {
    "city": "Itamaraju",
    "city_ascii": "Itamaraju",
    "lat": -17.0392,
    "lng": -39.5311,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Bahia",
    "capital": "",
    "population": 47628,
    "id": 1076241282
  },
  {
    "city": "Pen-y-Bont ar Ogwr",
    "city_ascii": "Pen-y-Bont ar Ogwr",
    "lat": 51.5072,
    "lng": -3.5784,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Bridgend",
    "capital": "",
    "population": 49404,
    "id": 1826587515
  },
  {
    "city": "Teplice",
    "city_ascii": "Teplice",
    "lat": 50.6333,
    "lng": 13.8167,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Ústecký Kraj",
    "capital": "",
    "population": 49731,
    "id": 1203801370
  },
  {
    "city": "Ramos Arizpe",
    "city_ascii": "Ramos Arizpe",
    "lat": 25.55,
    "lng": -100.9667,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Coahuila de Zaragoza",
    "capital": "minor",
    "population": 48228,
    "id": 1484685820
  },
  {
    "city": "Jihlava",
    "city_ascii": "Jihlava",
    "lat": 49.4003,
    "lng": 15.5906,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Vysočina",
    "capital": "admin",
    "population": 51216,
    "id": 1203810604
  },
  {
    "city": "Ratnapura",
    "city_ascii": "Ratnapura",
    "lat": 6.693,
    "lng": 80.386,
    "country": "Sri Lanka",
    "iso2": "LK",
    "iso3": "LKA",
    "admin_name": "Sabaragamuwa",
    "capital": "admin",
    "population": 47832,
    "id": 1144739208
  },
  {
    "city": "Jendouba",
    "city_ascii": "Jendouba",
    "lat": 36.5,
    "lng": 8.75,
    "country": "Tunisia",
    "iso2": "TN",
    "iso3": "TUN",
    "admin_name": "Jendouba",
    "capital": "admin",
    "population": 51408,
    "id": 1788952846
  },
  {
    "city": "Sombor",
    "city_ascii": "Sombor",
    "lat": 45.78,
    "lng": 19.12,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Sombor",
    "capital": "admin",
    "population": 51471,
    "id": 1688575779
  },
  {
    "city": "Campobasso",
    "city_ascii": "Campobasso",
    "lat": 41.563,
    "lng": 14.656,
    "country": "Italy",
    "iso2": "IT",
    "iso3": "ITA",
    "admin_name": "Molise",
    "capital": "admin",
    "population": 50762,
    "id": 1380959273
  },
  {
    "city": "Vryburg",
    "city_ascii": "Vryburg",
    "lat": -26.95,
    "lng": 24.7333,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "North West",
    "capital": "",
    "population": 49588,
    "id": 1710969597
  },
  {
    "city": "Eltham",
    "city_ascii": "Eltham",
    "lat": 51.451,
    "lng": 0.052,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Greenwich",
    "capital": "",
    "population": 48964,
    "id": 1826707596
  },
  {
    "city": "Levittown",
    "city_ascii": "Levittown",
    "lat": 40.7241,
    "lng": -73.5125,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 51539,
    "id": 1840005270
  },
  {
    "city": "Surbiton",
    "city_ascii": "Surbiton",
    "lat": 51.394,
    "lng": -0.307,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Kingston upon Thames",
    "capital": "",
    "population": 45132,
    "id": 1826418537
  },
  {
    "city": "Perth Amboy",
    "city_ascii": "Perth Amboy",
    "lat": 40.5203,
    "lng": -74.2724,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 51390,
    "id": 1840001340
  },
  {
    "city": "Ashton",
    "city_ascii": "Ashton",
    "lat": 53.4897,
    "lng": -2.0952,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Tameside",
    "capital": "",
    "population": 45198,
    "id": 1826027715
  },
  {
    "city": "Tizimín",
    "city_ascii": "Tizimin",
    "lat": 21.1425,
    "lng": -88.1647,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Yucatán",
    "capital": "minor",
    "population": 46971,
    "id": 1484254957
  },
  {
    "city": "Séguéla",
    "city_ascii": "Seguela",
    "lat": 7.9611,
    "lng": -6.6731,
    "country": "Côte D’Ivoire",
    "iso2": "CI",
    "iso3": "CIV",
    "admin_name": "Woroba",
    "capital": "admin",
    "population": 51157,
    "id": 1384639807
  },
  {
    "city": "Placentia",
    "city_ascii": "Placentia",
    "lat": 33.8807,
    "lng": -117.8553,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 51233,
    "id": 1840020583
  },
  {
    "city": "Stretford",
    "city_ascii": "Stretford",
    "lat": 53.4466,
    "lng": -2.3086,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Trafford",
    "capital": "",
    "population": 46910,
    "id": 1826645692
  },
  {
    "city": "Sar-e Pul",
    "city_ascii": "Sar-e Pul",
    "lat": 36.2214,
    "lng": 65.9278,
    "country": "Afghanistan",
    "iso2": "AF",
    "iso3": "AFG",
    "admin_name": "Sar-e Pul",
    "capital": "admin",
    "population": 51075,
    "id": 1004047427
  },
  {
    "city": "Komotiní",
    "city_ascii": "Komotini",
    "lat": 41.1167,
    "lng": 25.4,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Anatolikí Makedonía kai Thráki",
    "capital": "admin",
    "population": 50990,
    "id": 1300373048
  },
  {
    "city": "Camocim",
    "city_ascii": "Camocim",
    "lat": -2.9022,
    "lng": -40.8411,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Ceará",
    "capital": "",
    "population": 44481,
    "id": 1076563942
  },
  {
    "city": "Loreto",
    "city_ascii": "Loreto",
    "lat": 22.2667,
    "lng": -101.9833,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Zacatecas",
    "capital": "minor",
    "population": 43411,
    "id": 1484384276
  },
  {
    "city": "Larnaca",
    "city_ascii": "Larnaca",
    "lat": 34.9233,
    "lng": 33.6305,
    "country": "Cyprus",
    "iso2": "CY",
    "iso3": "CYP",
    "admin_name": "Lárnaka",
    "capital": "admin",
    "population": 48947,
    "id": 1196804795
  },
  {
    "city": "Capanema",
    "city_ascii": "Capanema",
    "lat": -1.1958,
    "lng": -47.1808,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Pará",
    "capital": "",
    "population": 47766,
    "id": 1076799309
  },
  {
    "city": "Bodupāl",
    "city_ascii": "Bodupal",
    "lat": 17.4139,
    "lng": 78.5783,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Telangana",
    "capital": "",
    "population": 43692,
    "id": 1356074103
  },
  {
    "city": "Longchamps",
    "city_ascii": "Longchamps",
    "lat": -34.8596,
    "lng": -58.387,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires",
    "capital": "",
    "population": 47622,
    "id": 1032203390
  },
  {
    "city": "Jutiapa",
    "city_ascii": "Jutiapa",
    "lat": 14.29,
    "lng": -89.9,
    "country": "Guatemala",
    "iso2": "GT",
    "iso3": "GTM",
    "admin_name": "Jutiapa",
    "capital": "admin",
    "population": 50681,
    "id": 1320264119
  },
  {
    "city": "Kasuga",
    "city_ascii": "Kasuga",
    "lat": 33.6108,
    "lng": 130.4806,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Fukuoka",
    "capital": "",
    "population": 47554,
    "id": 1392971052
  },
  {
    "city": "Sisophon",
    "city_ascii": "Sisophon",
    "lat": 13.5833,
    "lng": 102.9833,
    "country": "Cambodia",
    "iso2": "KH",
    "iso3": "KHM",
    "admin_name": "Banteay Meanchey",
    "capital": "admin",
    "population": 50302,
    "id": 1116276411
  },
  {
    "city": "Aliso Viejo",
    "city_ascii": "Aliso Viejo",
    "lat": 33.5792,
    "lng": -117.7289,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 50887,
    "id": 1840019321
  },
  {
    "city": "Skenderaj",
    "city_ascii": "Skenderaj",
    "lat": 42.7467,
    "lng": 20.7886,
    "country": "Kosovo",
    "iso2": "XK",
    "iso3": "XKS",
    "admin_name": "Skenderaj",
    "capital": "admin",
    "population": 50858,
    "id": 1901259570
  },
  {
    "city": "Jarash",
    "city_ascii": "Jarash",
    "lat": 32.2723,
    "lng": 35.8914,
    "country": "Jordan",
    "iso2": "JO",
    "iso3": "JOR",
    "admin_name": "Jarash",
    "capital": "admin",
    "population": 50745,
    "id": 1400505859
  },
  {
    "city": "Ḩarastā",
    "city_ascii": "Harasta",
    "lat": 33.5586,
    "lng": 36.365,
    "country": "Syria",
    "iso2": "SY",
    "iso3": "SYR",
    "admin_name": "Rīf Dimashq",
    "capital": "minor",
    "population": 45974,
    "id": 1760273732
  },
  {
    "city": "Puttalam",
    "city_ascii": "Puttalam",
    "lat": 8.033,
    "lng": 79.826,
    "country": "Sri Lanka",
    "iso2": "LK",
    "iso3": "LKA",
    "admin_name": "North Western",
    "capital": "",
    "population": 45661,
    "id": 1144219740
  },
  {
    "city": "Welwyn Garden City",
    "city_ascii": "Welwyn Garden City",
    "lat": 51.8062,
    "lng": -0.1932,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hertfordshire",
    "capital": "",
    "population": 46619,
    "id": 1826345498
  },
  {
    "city": "Guaxupé",
    "city_ascii": "Guaxupe",
    "lat": -21.3053,
    "lng": -46.7128,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Minas Gerais",
    "capital": "",
    "population": 47889,
    "id": 1076985230
  },
  {
    "city": "Gbadolite",
    "city_ascii": "Gbadolite",
    "lat": 4.275,
    "lng": 21,
    "country": "Congo (Kinshasa)",
    "iso2": "CD",
    "iso3": "COD",
    "admin_name": "Nord-Ubangi",
    "capital": "admin",
    "population": 50493,
    "id": 1180674224
  },
  {
    "city": "La Reja",
    "city_ascii": "La Reja",
    "lat": -34.6394,
    "lng": -58.8283,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires",
    "capital": "",
    "population": 47839,
    "id": 1032013959
  },
  {
    "city": "West Bridgford",
    "city_ascii": "West Bridgford",
    "lat": 52.932,
    "lng": -1.127,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Nottinghamshire",
    "capital": "",
    "population": 47109,
    "id": 1826779157
  },
  {
    "city": "Gennevilliers",
    "city_ascii": "Gennevilliers",
    "lat": 48.9333,
    "lng": 2.3,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 46907,
    "id": 1250529722
  },
  {
    "city": "Wheaton",
    "city_ascii": "Wheaton",
    "lat": 39.0492,
    "lng": -77.0572,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 50459,
    "id": 1840031295
  },
  {
    "city": "Goianésia",
    "city_ascii": "Goianesia",
    "lat": -15.3175,
    "lng": -49.1175,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Goiás",
    "capital": "",
    "population": 47883,
    "id": 1076636505
  },
  {
    "city": "Yeovil",
    "city_ascii": "Yeovil",
    "lat": 50.9452,
    "lng": -2.637,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Somerset",
    "capital": "",
    "population": 45000,
    "id": 1826177078
  },
  {
    "city": "Beckenham",
    "city_ascii": "Beckenham",
    "lat": 51.408,
    "lng": -0.022,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Bromley",
    "capital": "",
    "population": 46844,
    "id": 1826520605
  },
  {
    "city": "Gracias",
    "city_ascii": "Gracias",
    "lat": 14.5833,
    "lng": -88.5833,
    "country": "Honduras",
    "iso2": "HN",
    "iso3": "HND",
    "admin_name": "Lempira",
    "capital": "admin",
    "population": 50256,
    "id": 1340141391
  },
  {
    "city": "Ma‘ān",
    "city_ascii": "Ma`an",
    "lat": 30.192,
    "lng": 35.736,
    "country": "Jordan",
    "iso2": "JO",
    "iso3": "JOR",
    "admin_name": "Ma‘ān",
    "capital": "admin",
    "population": 50350,
    "id": 1400670443
  },
  {
    "city": "Cojutepeque",
    "city_ascii": "Cojutepeque",
    "lat": 13.7167,
    "lng": -88.9333,
    "country": "El Salvador",
    "iso2": "SV",
    "iso3": "SLV",
    "admin_name": "Cuscatlán",
    "capital": "admin",
    "population": 48411,
    "id": 1222979186
  },
  {
    "city": "Selibe Phikwe",
    "city_ascii": "Selibe Phikwe",
    "lat": -21.9667,
    "lng": 27.9167,
    "country": "Botswana",
    "iso2": "BW",
    "iso3": "BWA",
    "admin_name": "Selibe Phikwe",
    "capital": "admin",
    "population": 49724,
    "id": 1072308184
  },
  {
    "city": "Villa Alsina",
    "city_ascii": "Villa Alsina",
    "lat": -34.6667,
    "lng": -58.4167,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires",
    "capital": "",
    "population": 41155,
    "id": 1032506308
  },
  {
    "city": "Bom Jesus da Lapa",
    "city_ascii": "Bom Jesus da Lapa",
    "lat": -13.2495,
    "lng": -43.44,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Bahia",
    "capital": "",
    "population": 40691,
    "id": 1076439517
  },
  {
    "city": "Plainfield",
    "city_ascii": "Plainfield",
    "lat": 40.6154,
    "lng": -74.4157,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 50317,
    "id": 1840001090
  },
  {
    "city": "Garges-lès-Gonesse",
    "city_ascii": "Garges-les-Gonesse",
    "lat": 48.9728,
    "lng": 2.4008,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 42728,
    "id": 1250181473
  },
  {
    "city": "North Bethesda",
    "city_ascii": "North Bethesda",
    "lat": 39.0393,
    "lng": -77.1191,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 50262,
    "id": 1840031494
  },
  {
    "city": "Livry-Gargan",
    "city_ascii": "Livry-Gargan",
    "lat": 48.9192,
    "lng": 2.5361,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 44437,
    "id": 1250653520
  },
  {
    "city": "Bagneux",
    "city_ascii": "Bagneux",
    "lat": 48.7983,
    "lng": 2.3137,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 40918,
    "id": 1250888157
  },
  {
    "city": "Tlalmanalco",
    "city_ascii": "Tlalmanalco",
    "lat": 19.2044,
    "lng": -98.8025,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "México",
    "capital": "minor",
    "population": 46130,
    "id": 1484054902
  },
  {
    "city": "Moyobamba",
    "city_ascii": "Moyobamba",
    "lat": -6.0333,
    "lng": -76.9667,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "San Martín",
    "capital": "admin",
    "population": 50073,
    "id": 1604635600
  },
  {
    "city": "Al Khārijah",
    "city_ascii": "Al Kharijah",
    "lat": 25.44,
    "lng": 30.55,
    "country": "Egypt",
    "iso2": "EG",
    "iso3": "EGY",
    "admin_name": "Al Wādī al Jadīd",
    "capital": "admin",
    "population": 49991,
    "id": 1818872500
  },
  {
    "city": "Kotelniki",
    "city_ascii": "Kotelniki",
    "lat": 55.65,
    "lng": 37.8667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Moskovskaya Oblast’",
    "capital": "",
    "population": 46763,
    "id": 1643514399
  },
  {
    "city": "Al Balyanā",
    "city_ascii": "Al Balyana",
    "lat": 26.2329,
    "lng": 31.9993,
    "country": "Egypt",
    "iso2": "EG",
    "iso3": "EGY",
    "admin_name": "Sūhāj",
    "capital": "",
    "population": 46997,
    "id": 1818610123
  },
  {
    "city": "Maun",
    "city_ascii": "Maun",
    "lat": -19.9833,
    "lng": 23.4167,
    "country": "Botswana",
    "iso2": "BW",
    "iso3": "BWA",
    "admin_name": "North West",
    "capital": "admin",
    "population": 49945,
    "id": 1072985345
  },
  {
    "city": "Zaranj",
    "city_ascii": "Zaranj",
    "lat": 30.96,
    "lng": 61.86,
    "country": "Afghanistan",
    "iso2": "AF",
    "iso3": "AFG",
    "admin_name": "Nīmrōz",
    "capital": "admin",
    "population": 49851,
    "id": 1004504986
  },
  {
    "city": "La Garenne-Colombes",
    "city_ascii": "La Garenne-Colombes",
    "lat": 48.9056,
    "lng": 2.2445,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 29169,
    "id": 1250897232
  },
  {
    "city": "Odienné",
    "city_ascii": "Odienne",
    "lat": 9.5,
    "lng": -7.5667,
    "country": "Côte D’Ivoire",
    "iso2": "CI",
    "iso3": "CIV",
    "admin_name": "Denguélé",
    "capital": "admin",
    "population": 49857,
    "id": 1384967993
  },
  {
    "city": "Cheshunt",
    "city_ascii": "Cheshunt",
    "lat": 51.702,
    "lng": -0.035,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hertfordshire",
    "capital": "",
    "population": 44000,
    "id": 1826870758
  },
  {
    "city": "Nausori",
    "city_ascii": "Nausori",
    "lat": -18.0244,
    "lng": 178.5454,
    "country": "Fiji",
    "iso2": "FJ",
    "iso3": "FJI",
    "admin_name": "Tailevu",
    "capital": "",
    "population": 47604,
    "id": 1242640119
  },
  {
    "city": "Hāgere Hiywet",
    "city_ascii": "Hagere Hiywet",
    "lat": 8.9833,
    "lng": 37.85,
    "country": "Ethiopia",
    "iso2": "ET",
    "iso3": "ETH",
    "admin_name": "Oromīya",
    "capital": "",
    "population": 43920,
    "id": 1231809487
  },
  {
    "city": "Cerritos",
    "city_ascii": "Cerritos",
    "lat": 33.8677,
    "lng": -118.0686,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 49859,
    "id": 1840019215
  },
  {
    "city": "Meudon",
    "city_ascii": "Meudon",
    "lat": 48.8123,
    "lng": 2.2382,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 45352,
    "id": 1250863022
  },
  {
    "city": "Dosso",
    "city_ascii": "Dosso",
    "lat": 13.05,
    "lng": 3.2,
    "country": "Niger",
    "iso2": "NE",
    "iso3": "NER",
    "admin_name": "Dosso",
    "capital": "admin",
    "population": 49750,
    "id": 1562574611
  },
  {
    "city": "Kangar",
    "city_ascii": "Kangar",
    "lat": 6.4414,
    "lng": 100.1986,
    "country": "Malaysia",
    "iso2": "MY",
    "iso3": "MYS",
    "admin_name": "Perlis",
    "capital": "admin",
    "population": 48898,
    "id": 1458939333
  },
  {
    "city": "Al Aaroui",
    "city_ascii": "Al Aaroui",
    "lat": 35.0104,
    "lng": -3.0073,
    "country": "Morocco",
    "iso2": "MA",
    "iso3": "MAR",
    "admin_name": "Oriental",
    "capital": "",
    "population": 47599,
    "id": 1504779155
  },
  {
    "city": "Country Club",
    "city_ascii": "Country Club",
    "lat": 25.9407,
    "lng": -80.3102,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 49684,
    "id": 1840029029
  },
  {
    "city": "Lakewood",
    "city_ascii": "Lakewood",
    "lat": 41.4824,
    "lng": -81.8008,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 49678,
    "id": 1840000608
  },
  {
    "city": "Coyhaique",
    "city_ascii": "Coyhaique",
    "lat": -45.5712,
    "lng": -72.0685,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Aysén",
    "capital": "admin",
    "population": 49667,
    "id": 1152541137
  },
  {
    "city": "Montana",
    "city_ascii": "Montana",
    "lat": 43.4078,
    "lng": 23.225,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Montana",
    "capital": "admin",
    "population": 49597,
    "id": 1100946651
  },
  {
    "city": "Huancavelica",
    "city_ascii": "Huancavelica",
    "lat": -12.785,
    "lng": -74.9714,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "Huancavelica",
    "capital": "admin",
    "population": 49570,
    "id": 1604876709
  },
  {
    "city": "Perth",
    "city_ascii": "Perth",
    "lat": 56.397,
    "lng": -3.437,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Perth and Kinross",
    "capital": "",
    "population": 46960,
    "id": 1826081674
  },
  {
    "city": "Brumado",
    "city_ascii": "Brumado",
    "lat": -14.2036,
    "lng": -41.6653,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Bahia",
    "capital": "",
    "population": 41989,
    "id": 1076669684
  },
  {
    "city": "Dollard-des-Ormeaux",
    "city_ascii": "Dollard-des-Ormeaux",
    "lat": 45.4833,
    "lng": -73.8167,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 49637,
    "id": 1124902278
  },
  {
    "city": "Couva",
    "city_ascii": "Couva",
    "lat": 10.4167,
    "lng": -61.45,
    "country": "Trinidad And Tobago",
    "iso2": "TT",
    "iso3": "TTO",
    "admin_name": "Couva/Tabaquite/Talparo",
    "capital": "admin",
    "population": 48858,
    "id": 1780835692
  },
  {
    "city": "Waterford",
    "city_ascii": "Waterford",
    "lat": 52.2583,
    "lng": -7.119,
    "country": "Ireland",
    "iso2": "IE",
    "iso3": "IRL",
    "admin_name": "Waterford",
    "capital": "admin",
    "population": 48369,
    "id": 1372267114
  },
  {
    "city": "Bagnolet",
    "city_ascii": "Bagnolet",
    "lat": 48.8692,
    "lng": 2.4181,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 35674,
    "id": 1250335183
  },
  {
    "city": "Ramat HaSharon",
    "city_ascii": "Ramat HaSharon",
    "lat": 32.1461,
    "lng": 34.8394,
    "country": "Israel",
    "iso2": "IL",
    "iso3": "ISR",
    "admin_name": "Tel Aviv",
    "capital": "",
    "population": 46700,
    "id": 1376215427
  },
  {
    "city": "Nelson",
    "city_ascii": "Nelson",
    "lat": -41.2931,
    "lng": 173.2381,
    "country": "New Zealand",
    "iso2": "NZ",
    "iso3": "NZL",
    "admin_name": "Nelson",
    "capital": "admin",
    "population": 49300,
    "id": 1554017097
  },
  {
    "city": "Mantes-la-Jolie",
    "city_ascii": "Mantes-la-Jolie",
    "lat": 48.9908,
    "lng": 1.7172,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "minor",
    "population": 44299,
    "id": 1250666731
  },
  {
    "city": "Wajir",
    "city_ascii": "Wajir",
    "lat": 1.75,
    "lng": 40.0667,
    "country": "Kenya",
    "iso2": "KE",
    "iso3": "KEN",
    "admin_name": "Wajir",
    "capital": "admin",
    "population": 45771,
    "id": 1404067343
  },
  {
    "city": "Canindé",
    "city_ascii": "Caninde",
    "lat": -4.3589,
    "lng": -39.3117,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Ceará",
    "capital": "",
    "population": 42720,
    "id": 1076519156
  },
  {
    "city": "Karlovy Vary",
    "city_ascii": "Karlovy Vary",
    "lat": 50.2306,
    "lng": 12.8725,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Karlovarský Kraj",
    "capital": "admin",
    "population": 48479,
    "id": 1203518899
  },
  {
    "city": "San Isidro",
    "city_ascii": "San Isidro",
    "lat": -34.4667,
    "lng": -58.5167,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires",
    "capital": "minor",
    "population": 43595,
    "id": 1032862666
  },
  {
    "city": "Luleå",
    "city_ascii": "Lulea",
    "lat": 65.5838,
    "lng": 22.1915,
    "country": "Sweden",
    "iso2": "SE",
    "iso3": "SWE",
    "admin_name": "Norrbotten",
    "capital": "admin",
    "population": 48749,
    "id": 1752765449
  },
  {
    "city": "Florin",
    "city_ascii": "Florin",
    "lat": 38.4832,
    "lng": -121.4042,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 49132,
    "id": 1840017581
  },
  {
    "city": "Piripiri",
    "city_ascii": "Piripiri",
    "lat": -4.2733,
    "lng": -41.7769,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Piauí",
    "capital": "",
    "population": 44864,
    "id": 1076867493
  },
  {
    "city": "Ayr",
    "city_ascii": "Ayr",
    "lat": 55.458,
    "lng": -4.629,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "South Ayrshire",
    "capital": "",
    "population": 46490,
    "id": 1826430700
  },
  {
    "city": "José Mármol",
    "city_ascii": "Jose Marmol",
    "lat": -34.7833,
    "lng": -58.3667,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires",
    "capital": "",
    "population": 41109,
    "id": 1032126414
  },
  {
    "city": "Jūrmala",
    "city_ascii": "Jurmala",
    "lat": 56.968,
    "lng": 23.7704,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Jūrmala",
    "capital": "admin",
    "population": 48606,
    "id": 1428952300
  },
  {
    "city": "Banbury",
    "city_ascii": "Banbury",
    "lat": 52.061,
    "lng": -1.336,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Oxfordshire",
    "capital": "",
    "population": 46853,
    "id": 1826594194
  },
  {
    "city": "Wokingham",
    "city_ascii": "Wokingham",
    "lat": 51.41,
    "lng": -0.84,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Wokingham",
    "capital": "",
    "population": 46745,
    "id": 1826878333
  },
  {
    "city": "Moroleón",
    "city_ascii": "Moroleon",
    "lat": 20.1333,
    "lng": -101.2,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Guanajuato",
    "capital": "minor",
    "population": 41909,
    "id": 1484802402
  },
  {
    "city": "Cypress",
    "city_ascii": "Cypress",
    "lat": 33.8171,
    "lng": -118.0386,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 49006,
    "id": 1840019327
  },
  {
    "city": "Hollister",
    "city_ascii": "Hollister",
    "lat": 36.8563,
    "lng": -121.3981,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 48992,
    "id": 1840020354
  },
  {
    "city": "Mao",
    "city_ascii": "Mao",
    "lat": 19.552,
    "lng": -71.075,
    "country": "Dominican Republic",
    "iso2": "DO",
    "iso3": "DOM",
    "admin_name": "Cibao Noroeste",
    "capital": "admin",
    "population": 48297,
    "id": 1214217168
  },
  {
    "city": "North Highlands",
    "city_ascii": "North Highlands",
    "lat": 38.6713,
    "lng": -121.3721,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 48968,
    "id": 1840018841
  },
  {
    "city": "Inhumas",
    "city_ascii": "Inhumas",
    "lat": -16.3578,
    "lng": -49.4961,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Goiás",
    "capital": "",
    "population": 44058,
    "id": 1076130930
  },
  {
    "city": "Rovenky",
    "city_ascii": "Rovenky",
    "lat": 48.0833,
    "lng": 39.3667,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Luhans’ka Oblast’",
    "capital": "",
    "population": 46734,
    "id": 1804938726
  },
  {
    "city": "Asadābād",
    "city_ascii": "Asadabad",
    "lat": 34.8742,
    "lng": 71.1528,
    "country": "Afghanistan",
    "iso2": "AF",
    "iso3": "AFG",
    "admin_name": "Kunaṟ",
    "capital": "admin",
    "population": 48400,
    "id": 1004251962
  },
  {
    "city": "Bloomfield",
    "city_ascii": "Bloomfield",
    "lat": 40.8098,
    "lng": -74.1868,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 48810,
    "id": 1840131592
  },
  {
    "city": "Tlapa de Comonfort",
    "city_ascii": "Tlapa de Comonfort",
    "lat": 17.5461,
    "lng": -98.5764,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Guerrero",
    "capital": "minor",
    "population": 37975,
    "id": 1484110723
  },
  {
    "city": "Invercargill",
    "city_ascii": "Invercargill",
    "lat": -46.429,
    "lng": 168.362,
    "country": "New Zealand",
    "iso2": "NZ",
    "iso3": "NZL",
    "admin_name": "Southland",
    "capital": "admin",
    "population": 48700,
    "id": 1554148942
  },
  {
    "city": "Châtillon",
    "city_ascii": "Chatillon",
    "lat": 48.8,
    "lng": 2.29,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 37355,
    "id": 1250813874
  },
  {
    "city": "Birendranagar",
    "city_ascii": "Birendranagar",
    "lat": 28.6,
    "lng": 81.6333,
    "country": "Nepal",
    "iso2": "NP",
    "iso3": "NPL",
    "admin_name": "Bherī",
    "capital": "admin",
    "population": 47914,
    "id": 1524574121
  },
  {
    "city": "Nueva Loja",
    "city_ascii": "Nueva Loja",
    "lat": 0.0844,
    "lng": -76.8911,
    "country": "Ecuador",
    "iso2": "EC",
    "iso3": "ECU",
    "admin_name": "Sucumbíos",
    "capital": "admin",
    "population": 48562,
    "id": 1218895755
  },
  {
    "city": "Krasnoznamensk",
    "city_ascii": "Krasnoznamensk",
    "lat": 55.6,
    "lng": 37.0333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Moskovskaya Oblast’",
    "capital": "",
    "population": 41769,
    "id": 1643985194
  },
  {
    "city": "Ceres",
    "city_ascii": "Ceres",
    "lat": 37.5953,
    "lng": -120.9625,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 48706,
    "id": 1840018910
  },
  {
    "city": "Puerto Escondido",
    "city_ascii": "Puerto Escondido",
    "lat": 15.8619,
    "lng": -97.0672,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Oaxaca",
    "capital": "",
    "population": 45000,
    "id": 1484644846
  },
  {
    "city": "Serowe",
    "city_ascii": "Serowe",
    "lat": -22.3833,
    "lng": 26.7167,
    "country": "Botswana",
    "iso2": "BW",
    "iso3": "BWA",
    "admin_name": "Central",
    "capital": "admin",
    "population": 48573,
    "id": 1072708388
  },
  {
    "city": "Kingston upon Thames",
    "city_ascii": "Kingston upon Thames",
    "lat": 51.4103,
    "lng": -0.2995,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Kingston upon Thames",
    "capital": "",
    "population": 43013,
    "id": 1826345299
  },
  {
    "city": "Mandeville",
    "city_ascii": "Mandeville",
    "lat": 18.0417,
    "lng": -77.5072,
    "country": "Jamaica",
    "iso2": "JM",
    "iso3": "JAM",
    "admin_name": "Manchester",
    "capital": "admin",
    "population": 48317,
    "id": 1388493847
  },
  {
    "city": "Nong Khai",
    "city_ascii": "Nong Khai",
    "lat": 17.8842,
    "lng": 102.7467,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Nong Khai",
    "capital": "admin",
    "population": 48274,
    "id": 1764279113
  },
  {
    "city": "Salekhard",
    "city_ascii": "Salekhard",
    "lat": 66.5333,
    "lng": 66.6333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Yamalo-Nenetskiy Avtonomnyy Okrug",
    "capital": "admin",
    "population": 48507,
    "id": 1643083395
  },
  {
    "city": "Mercedes",
    "city_ascii": "Mercedes",
    "lat": -33.25,
    "lng": -58.0319,
    "country": "Uruguay",
    "iso2": "UY",
    "iso3": "URY",
    "admin_name": "Soriano",
    "capital": "admin",
    "population": 41974,
    "id": 1858182538
  },
  {
    "city": "Parras de la Fuente",
    "city_ascii": "Parras de la Fuente",
    "lat": 25.4403,
    "lng": -102.1792,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Coahuila de Zaragoza",
    "capital": "minor",
    "population": 45423,
    "id": 1484273682
  },
  {
    "city": "Emiliano Zapata",
    "city_ascii": "Emiliano Zapata",
    "lat": 18.8667,
    "lng": -99.15,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Morelos",
    "capital": "minor",
    "population": 39702,
    "id": 1484666092
  },
  {
    "city": "Bindura",
    "city_ascii": "Bindura",
    "lat": -17.3,
    "lng": 31.3333,
    "country": "Zimbabwe",
    "iso2": "ZW",
    "iso3": "ZWE",
    "admin_name": "Mashonaland Central",
    "capital": "admin",
    "population": 46275,
    "id": 1716331687
  },
  {
    "city": "La Courneuve",
    "city_ascii": "La Courneuve",
    "lat": 48.9322,
    "lng": 2.3967,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 43054,
    "id": 1250362239
  },
  {
    "city": "Gori",
    "city_ascii": "Gori",
    "lat": 41.9817,
    "lng": 44.1124,
    "country": "Georgia",
    "iso2": "GE",
    "iso3": "GEO",
    "admin_name": "Shida Kartli",
    "capital": "admin",
    "population": 48143,
    "id": 1268442763
  },
  {
    "city": "Isiolo",
    "city_ascii": "Isiolo",
    "lat": 0.35,
    "lng": 37.5833,
    "country": "Kenya",
    "iso2": "KE",
    "iso3": "KEN",
    "admin_name": "Isiolo",
    "capital": "admin",
    "population": 45989,
    "id": 1404771203
  },
  {
    "city": "La Mirada",
    "city_ascii": "La Mirada",
    "lat": 33.9025,
    "lng": -118.0093,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 48183,
    "id": 1840020512
  },
  {
    "city": "Bastia",
    "city_ascii": "Bastia",
    "lat": 42.7008,
    "lng": 9.4503,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Corsica",
    "capital": "",
    "population": 45715,
    "id": 1250664815
  },
  {
    "city": "El Kef",
    "city_ascii": "El Kef",
    "lat": 36.1826,
    "lng": 8.7148,
    "country": "Tunisia",
    "iso2": "TN",
    "iso3": "TUN",
    "admin_name": "Kef",
    "capital": "admin",
    "population": 47979,
    "id": 1788314833
  },
  {
    "city": "Havant",
    "city_ascii": "Havant",
    "lat": 50.85,
    "lng": -0.98,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hampshire",
    "capital": "",
    "population": 45826,
    "id": 1826912407
  },
  {
    "city": "Talence",
    "city_ascii": "Talence",
    "lat": 44.8,
    "lng": -0.584,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Nouvelle-Aquitaine",
    "capital": "",
    "population": 42606,
    "id": 1250490677
  },
  {
    "city": "Inuma",
    "city_ascii": "Inuma",
    "lat": 36,
    "lng": 139.6239,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Saitama",
    "capital": "",
    "population": 44977,
    "id": 1392163993
  },
  {
    "city": "Leribe",
    "city_ascii": "Leribe",
    "lat": -28.8734,
    "lng": 28.0416,
    "country": "Lesotho",
    "iso2": "LS",
    "iso3": "LSO",
    "admin_name": "Leribe",
    "capital": "admin",
    "population": 47675,
    "id": 1426721246
  },
  {
    "city": "Chalon-sur-Saône",
    "city_ascii": "Chalon-sur-Saone",
    "lat": 46.7806,
    "lng": 4.8528,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Bourgogne-Franche-Comté",
    "capital": "minor",
    "population": 45096,
    "id": 1250630825
  },
  {
    "city": "Kanash",
    "city_ascii": "Kanash",
    "lat": 55.5167,
    "lng": 47.5,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Chuvashiya",
    "capital": "",
    "population": 45482,
    "id": 1643340791
  },
  {
    "city": "Hinckley",
    "city_ascii": "Hinckley",
    "lat": 52.5413,
    "lng": -1.3725,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Leicestershire",
    "capital": "",
    "population": 45249,
    "id": 1826014873
  },
  {
    "city": "Njombe",
    "city_ascii": "Njombe",
    "lat": -9.3296,
    "lng": 34.77,
    "country": "Tanzania",
    "iso2": "TZ",
    "iso3": "TZA",
    "admin_name": "Njombe",
    "capital": "admin",
    "population": 46724,
    "id": 1834328362
  },
  {
    "city": "Erith",
    "city_ascii": "Erith",
    "lat": 51.48,
    "lng": 0.1778,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Bexley",
    "capital": "",
    "population": 45345,
    "id": 1826991631
  },
  {
    "city": "New Amsterdam",
    "city_ascii": "New Amsterdam",
    "lat": 6.25,
    "lng": -57.5167,
    "country": "Guyana",
    "iso2": "GY",
    "iso3": "GUY",
    "admin_name": "East Berbice-Corentyne",
    "capital": "admin",
    "population": 46874,
    "id": 1328195392
  },
  {
    "city": "Karmi’el",
    "city_ascii": "Karmi'el",
    "lat": 32.9,
    "lng": 35.2833,
    "country": "Israel",
    "iso2": "IL",
    "iso3": "ISR",
    "admin_name": "Northern",
    "capital": "",
    "population": 45300,
    "id": 1376615005
  },
  {
    "city": "Morley",
    "city_ascii": "Morley",
    "lat": 53.7492,
    "lng": -1.6023,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Leeds",
    "capital": "",
    "population": 44440,
    "id": 1826138121
  },
  {
    "city": "Ḩajjah",
    "city_ascii": "Hajjah",
    "lat": 15.695,
    "lng": 43.5975,
    "country": "Yemen",
    "iso2": "YE",
    "iso3": "YEM",
    "admin_name": "Ḩajjah",
    "capital": "admin",
    "population": 46568,
    "id": 1887522445
  },
  {
    "city": "Empalme",
    "city_ascii": "Empalme",
    "lat": 27.9617,
    "lng": -110.8125,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Sonora",
    "capital": "minor",
    "population": 42516,
    "id": 1484366250
  },
  {
    "city": "Kokkola",
    "city_ascii": "Kokkola",
    "lat": 63.8333,
    "lng": 23.1333,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Keski-Pohjanmaa",
    "capital": "admin",
    "population": 47570,
    "id": 1246041998
  },
  {
    "city": "Middelburg",
    "city_ascii": "Middelburg",
    "lat": 51.502,
    "lng": 3.61,
    "country": "Netherlands",
    "iso2": "NL",
    "iso3": "NLD",
    "admin_name": "Zeeland",
    "capital": "admin",
    "population": 46485,
    "id": 1528241984
  },
  {
    "city": "Covina",
    "city_ascii": "Covina",
    "lat": 34.0903,
    "lng": -117.8817,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 47450,
    "id": 1840019241
  },
  {
    "city": "Bang Kruai",
    "city_ascii": "Bang Kruai",
    "lat": 13.8042,
    "lng": 100.4755,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Nonthaburi",
    "capital": "minor",
    "population": 42390,
    "id": 1764357378
  },
  {
    "city": "Tepalcatepec",
    "city_ascii": "Tepalcatepec",
    "lat": 19.1833,
    "lng": -102.85,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Michoacán de Ocampo",
    "capital": "minor",
    "population": 42879,
    "id": 1484817788
  },
  {
    "city": "Caluire-et-Cuire",
    "city_ascii": "Caluire-et-Cuire",
    "lat": 45.7953,
    "lng": 4.8472,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Auvergne-Rhône-Alpes",
    "capital": "",
    "population": 43187,
    "id": 1250073067
  },
  {
    "city": "Antelope",
    "city_ascii": "Antelope",
    "lat": 38.7153,
    "lng": -121.361,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 47326,
    "id": 1840022490
  },
  {
    "city": "Murzuq",
    "city_ascii": "Murzuq",
    "lat": 25.9136,
    "lng": 13.9336,
    "country": "Libya",
    "iso2": "LY",
    "iso3": "LBY",
    "admin_name": "Murzuq",
    "capital": "admin",
    "population": 47324,
    "id": 1434910333
  },
  {
    "city": "Rosso",
    "city_ascii": "Rosso",
    "lat": 16.5128,
    "lng": -15.805,
    "country": "Mauritania",
    "iso2": "MR",
    "iso3": "MRT",
    "admin_name": "Trarza",
    "capital": "admin",
    "population": 47203,
    "id": 1478524903
  },
  {
    "city": "Naj‘ Ḩammādī",
    "city_ascii": "Naj` Hammadi",
    "lat": 26.05,
    "lng": 32.25,
    "country": "Egypt",
    "iso2": "EG",
    "iso3": "EGY",
    "admin_name": "Qinā",
    "capital": "",
    "population": 45038,
    "id": 1818262231
  },
  {
    "city": "Charenton-le-Pont",
    "city_ascii": "Charenton-le-Pont",
    "lat": 48.8265,
    "lng": 2.405,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 30374,
    "id": 1250389807
  },
  {
    "city": "Chitré",
    "city_ascii": "Chitre",
    "lat": 7.9667,
    "lng": -80.4333,
    "country": "Panama",
    "iso2": "PA",
    "iso3": "PAN",
    "admin_name": "Herrera",
    "capital": "admin",
    "population": 46191,
    "id": 1591257375
  },
  {
    "city": "Salgueiro",
    "city_ascii": "Salgueiro",
    "lat": -8.0742,
    "lng": -39.1192,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Pernambuco",
    "capital": "",
    "population": 42152,
    "id": 1076679973
  },
  {
    "city": "Badulla",
    "city_ascii": "Badulla",
    "lat": 6.9847,
    "lng": 81.0564,
    "country": "Sri Lanka",
    "iso2": "LK",
    "iso3": "LKA",
    "admin_name": "Uva",
    "capital": "admin",
    "population": 42923,
    "id": 1144962750
  },
  {
    "city": "Nagykanizsa",
    "city_ascii": "Nagykanizsa",
    "lat": 46.4558,
    "lng": 16.9975,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Nagykanizsa",
    "capital": "admin",
    "population": 46866,
    "id": 1348509592
  },
  {
    "city": "Malakoff",
    "city_ascii": "Malakoff",
    "lat": 48.8169,
    "lng": 2.2944,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 30286,
    "id": 1250358877
  },
  {
    "city": "Welling",
    "city_ascii": "Welling",
    "lat": 51.4594,
    "lng": 0.1097,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Bexley",
    "capital": "",
    "population": 41000,
    "id": 1826992580
  },
  {
    "city": "Viti",
    "city_ascii": "Viti",
    "lat": 42.3167,
    "lng": 21.35,
    "country": "Kosovo",
    "iso2": "XK",
    "iso3": "XKS",
    "admin_name": "Viti",
    "capital": "admin",
    "population": 46987,
    "id": 1901328795
  },
  {
    "city": "Boosaaso",
    "city_ascii": "Boosaaso",
    "lat": 11.28,
    "lng": 49.18,
    "country": "Somalia",
    "iso2": "SO",
    "iso3": "SOM",
    "admin_name": "Bari",
    "capital": "admin",
    "population": 46969,
    "id": 1706054548
  },
  {
    "city": "Izúcar de Matamoros",
    "city_ascii": "Izucar de Matamoros",
    "lat": 18.6,
    "lng": -98.4667,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Puebla",
    "capital": "minor",
    "population": 41042,
    "id": 1484858520
  },
  {
    "city": "Qiryat Ono",
    "city_ascii": "Qiryat Ono",
    "lat": 32.0636,
    "lng": 34.8553,
    "country": "Israel",
    "iso2": "IL",
    "iso3": "ISR",
    "admin_name": "Tel Aviv",
    "capital": "",
    "population": 37791,
    "id": 1376929399
  },
  {
    "city": "Castleford",
    "city_ascii": "Castleford",
    "lat": 53.716,
    "lng": -1.356,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Wakefield",
    "capital": "",
    "population": 40210,
    "id": 1826827675
  },
  {
    "city": "Santiago",
    "city_ascii": "Santiago",
    "lat": 8.1004,
    "lng": -80.9833,
    "country": "Panama",
    "iso2": "PA",
    "iso3": "PAN",
    "admin_name": "Veraguas",
    "capital": "admin",
    "population": 45955,
    "id": 1591231138
  },
  {
    "city": "Le Cannet",
    "city_ascii": "Le Cannet",
    "lat": 43.5769,
    "lng": 7.0194,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Provence-Alpes-Côte d’Azur",
    "capital": "",
    "population": 41471,
    "id": 1250292202
  },
  {
    "city": "Fareham",
    "city_ascii": "Fareham",
    "lat": 50.85,
    "lng": -1.18,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hampshire",
    "capital": "",
    "population": 42210,
    "id": 1826345366
  },
  {
    "city": "Kanye",
    "city_ascii": "Kanye",
    "lat": -24.9833,
    "lng": 25.35,
    "country": "Botswana",
    "iso2": "BW",
    "iso3": "BWA",
    "admin_name": "Southern",
    "capital": "admin",
    "population": 46831,
    "id": 1072506645
  },
  {
    "city": "Embu",
    "city_ascii": "Embu",
    "lat": -0.5333,
    "lng": 37.45,
    "country": "Kenya",
    "iso2": "KE",
    "iso3": "KEN",
    "admin_name": "Embu",
    "capital": "admin",
    "population": 41092,
    "id": 1404376456
  },
  {
    "city": "Choma",
    "city_ascii": "Choma",
    "lat": -16.8095,
    "lng": 26.97,
    "country": "Zambia",
    "iso2": "ZM",
    "iso3": "ZMB",
    "admin_name": "Southern",
    "capital": "admin",
    "population": 46746,
    "id": 1894419971
  },
  {
    "city": "Saint-Brieuc",
    "city_ascii": "Saint-Brieuc",
    "lat": 48.5136,
    "lng": -2.7653,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Bretagne",
    "capital": "minor",
    "population": 44372,
    "id": 1250574796
  },
  {
    "city": "Aweil",
    "city_ascii": "Aweil",
    "lat": 8.7666,
    "lng": 27.4,
    "country": "South Sudan",
    "iso2": "SS",
    "iso3": "SSD",
    "admin_name": "Northern Bahr el Ghazal",
    "capital": "admin",
    "population": 46705,
    "id": 1728097802
  },
  {
    "city": "Östersund",
    "city_ascii": "Ostersund",
    "lat": 63.1833,
    "lng": 14.65,
    "country": "Sweden",
    "iso2": "SE",
    "iso3": "SWE",
    "admin_name": "Jämtland",
    "capital": "admin",
    "population": 46178,
    "id": 1752026711
  },
  {
    "city": "Merthyr Tudful",
    "city_ascii": "Merthyr Tudful",
    "lat": 51.743,
    "lng": -3.378,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Merthyr Tydfil",
    "capital": "",
    "population": 43820,
    "id": 1826737367
  },
  {
    "city": "Stains",
    "city_ascii": "Stains",
    "lat": 48.95,
    "lng": 2.3833,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 38720,
    "id": 1250114874
  },
  {
    "city": "Rye",
    "city_ascii": "Rye",
    "lat": 41.0076,
    "lng": -73.6872,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 46636,
    "id": 1840000784
  },
  {
    "city": "Trollhättan",
    "city_ascii": "Trollhattan",
    "lat": 58.2671,
    "lng": 12.3,
    "country": "Sweden",
    "iso2": "SE",
    "iso3": "SWE",
    "admin_name": "Västra Götaland",
    "capital": "minor",
    "population": 44543,
    "id": 1752863731
  },
  {
    "city": "Valenciennes",
    "city_ascii": "Valenciennes",
    "lat": 50.358,
    "lng": 3.5233,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Hauts-de-France",
    "capital": "minor",
    "population": 43336,
    "id": 1250025032
  },
  {
    "city": "Mahdia",
    "city_ascii": "Mahdia",
    "lat": 35.4839,
    "lng": 11.0409,
    "country": "Tunisia",
    "iso2": "TN",
    "iso3": "TUN",
    "admin_name": "Mahdia",
    "capital": "admin",
    "population": 45977,
    "id": 1788440786
  },
  {
    "city": "Everett",
    "city_ascii": "Everett",
    "lat": 42.4064,
    "lng": -71.0545,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 46451,
    "id": 1840000425
  },
  {
    "city": "Šibenik",
    "city_ascii": "Sibenik",
    "lat": 43.7339,
    "lng": 15.8956,
    "country": "Croatia",
    "iso2": "HR",
    "iso3": "HRV",
    "admin_name": "Šibensko-Kninska Županija",
    "capital": "admin",
    "population": 46332,
    "id": 1191444936
  },
  {
    "city": "Cobija",
    "city_ascii": "Cobija",
    "lat": -11.0183,
    "lng": -68.7537,
    "country": "Bolivia",
    "iso2": "BO",
    "iso3": "BOL",
    "admin_name": "Pando",
    "capital": "admin",
    "population": 46267,
    "id": 1068395023
  },
  {
    "city": "University",
    "city_ascii": "University",
    "lat": 28.0771,
    "lng": -82.4335,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 46359,
    "id": 1840073852
  },
  {
    "city": "Urmston",
    "city_ascii": "Urmston",
    "lat": 53.4487,
    "lng": -2.3747,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Trafford",
    "capital": "",
    "population": 41825,
    "id": 1826636761
  },
  {
    "city": "Antigua Guatemala",
    "city_ascii": "Antigua Guatemala",
    "lat": 14.5567,
    "lng": -90.7337,
    "country": "Guatemala",
    "iso2": "GT",
    "iso3": "GTM",
    "admin_name": "Sacatepéquez",
    "capital": "admin",
    "population": 45669,
    "id": 1320020808
  },
  {
    "city": "Nueva Gerona",
    "city_ascii": "Nueva Gerona",
    "lat": 21.8847,
    "lng": -82.8011,
    "country": "Cuba",
    "iso2": "CU",
    "iso3": "CUB",
    "admin_name": "Isla de la Juventud",
    "capital": "admin",
    "population": 46264,
    "id": 1192344354
  },
  {
    "city": "Sololá",
    "city_ascii": "Solola",
    "lat": 14.773,
    "lng": -91.183,
    "country": "Guatemala",
    "iso2": "GT",
    "iso3": "GTM",
    "admin_name": "Sololá",
    "capital": "admin",
    "population": 45373,
    "id": 1320747500
  },
  {
    "city": "Slobozia",
    "city_ascii": "Slobozia",
    "lat": 44.5639,
    "lng": 27.3661,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Ialomiţa",
    "capital": "admin",
    "population": 45891,
    "id": 1642971618
  },
  {
    "city": "Vilvoorde",
    "city_ascii": "Vilvoorde",
    "lat": 50.9281,
    "lng": 4.4245,
    "country": "Belgium",
    "iso2": "BE",
    "iso3": "BEL",
    "admin_name": "Flanders",
    "capital": "minor",
    "population": 44015,
    "id": 1056838834
  },
  {
    "city": "Penedo",
    "city_ascii": "Penedo",
    "lat": -10.2903,
    "lng": -36.5864,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Alagoas",
    "capital": "",
    "population": 42097,
    "id": 1076906021
  },
  {
    "city": "Kingswood",
    "city_ascii": "Kingswood",
    "lat": 51.46,
    "lng": -2.505,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "South Gloucestershire",
    "capital": "",
    "population": 40734,
    "id": 1826616515
  },
  {
    "city": "Araranguá",
    "city_ascii": "Ararangua",
    "lat": -28.9357,
    "lng": -49.4954,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Santa Catarina",
    "capital": "",
    "population": 43444,
    "id": 1076532332
  },
  {
    "city": "Jerada",
    "city_ascii": "Jerada",
    "lat": 34.31,
    "lng": -2.16,
    "country": "Morocco",
    "iso2": "MA",
    "iso3": "MAR",
    "admin_name": "Oriental",
    "capital": "",
    "population": 43506,
    "id": 1504230609
  },
  {
    "city": "Gbarnga",
    "city_ascii": "Gbarnga",
    "lat": 7.0104,
    "lng": -9.49,
    "country": "Liberia",
    "iso2": "LR",
    "iso3": "LBR",
    "admin_name": "Bong",
    "capital": "admin",
    "population": 45835,
    "id": 1430269323
  },
  {
    "city": "Alexandria",
    "city_ascii": "Alexandria",
    "lat": 43.9686,
    "lng": 25.3333,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Teleorman",
    "capital": "admin",
    "population": 45434,
    "id": 1642953190
  },
  {
    "city": "Madīnat ‘Īsá",
    "city_ascii": "Madinat `Isa",
    "lat": 26.1736,
    "lng": 50.5478,
    "country": "Bahrain",
    "iso2": "BH",
    "iso3": "BHR",
    "admin_name": "Al Janūbīyah",
    "capital": "",
    "population": 40000,
    "id": 1048219300
  },
  {
    "city": "Frutal",
    "city_ascii": "Frutal",
    "lat": -20.0247,
    "lng": -48.9406,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Minas Gerais",
    "capital": "",
    "population": 40931,
    "id": 1076112857
  },
  {
    "city": "Thun",
    "city_ascii": "Thun",
    "lat": 46.759,
    "lng": 7.63,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Bern",
    "capital": "minor",
    "population": 43743,
    "id": 1756018756
  },
  {
    "city": "Vanves",
    "city_ascii": "Vanves",
    "lat": 48.8208,
    "lng": 2.2897,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 27729,
    "id": 1250321790
  },
  {
    "city": "Buta",
    "city_ascii": "Buta",
    "lat": 2.82,
    "lng": 24.74,
    "country": "Congo (Kinshasa)",
    "iso2": "CD",
    "iso3": "COD",
    "admin_name": "Bas-Uélé",
    "capital": "admin",
    "population": 45677,
    "id": 1180712204
  },
  {
    "city": "Targovishte",
    "city_ascii": "Targovishte",
    "lat": 43.2467,
    "lng": 26.5719,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Targovishte",
    "capital": "admin",
    "population": 45535,
    "id": 1100987486
  },
  {
    "city": "Jáltipan de Morelos",
    "city_ascii": "Jaltipan de Morelos",
    "lat": 17.9703,
    "lng": -94.7144,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Veracruz",
    "capital": "minor",
    "population": 41644,
    "id": 1484054405
  },
  {
    "city": "Bluefields",
    "city_ascii": "Bluefields",
    "lat": 12,
    "lng": -83.75,
    "country": "Nicaragua",
    "iso2": "NI",
    "iso3": "NIC",
    "admin_name": "Caribe Sur",
    "capital": "admin",
    "population": 45547,
    "id": 1558301368
  },
  {
    "city": "Bron",
    "city_ascii": "Bron",
    "lat": 45.7394,
    "lng": 4.9139,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Auvergne-Rhône-Alpes",
    "capital": "",
    "population": 41543,
    "id": 1250002616
  },
  {
    "city": "Strumica",
    "city_ascii": "Strumica",
    "lat": 41.4375,
    "lng": 22.6431,
    "country": "Macedonia",
    "iso2": "MK",
    "iso3": "MKD",
    "admin_name": "Strumica",
    "capital": "admin",
    "population": 45087,
    "id": 1807799132
  },
  {
    "city": "Liberia",
    "city_ascii": "Liberia",
    "lat": 10.6338,
    "lng": -85.4333,
    "country": "Costa Rica",
    "iso2": "CR",
    "iso3": "CRI",
    "admin_name": "Guanacaste",
    "capital": "admin",
    "population": 45380,
    "id": 1188743090
  },
  {
    "city": "Bellinzona",
    "city_ascii": "Bellinzona",
    "lat": 46.1956,
    "lng": 9.0238,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Ticino",
    "capital": "",
    "population": 43220,
    "id": 1756130026
  },
  {
    "city": "Pattani",
    "city_ascii": "Pattani",
    "lat": 6.8664,
    "lng": 101.2508,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Pattani",
    "capital": "admin",
    "population": 44234,
    "id": 1764277891
  },
  {
    "city": "Artigas",
    "city_ascii": "Artigas",
    "lat": -30.4667,
    "lng": -56.4667,
    "country": "Uruguay",
    "iso2": "UY",
    "iso3": "URY",
    "admin_name": "Artigas",
    "capital": "admin",
    "population": 40658,
    "id": 1858646839
  },
  {
    "city": "Pénjamo",
    "city_ascii": "Penjamo",
    "lat": 20.4311,
    "lng": -101.7222,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Guanajuato",
    "capital": "minor",
    "population": 40076,
    "id": 1484131107
  },
  {
    "city": "Pinneberg",
    "city_ascii": "Pinneberg",
    "lat": 53.6591,
    "lng": 9.8009,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Schleswig-Holstein",
    "capital": "minor",
    "population": 43280,
    "id": 1276865707
  },
  {
    "city": "Palmeira dos Índios",
    "city_ascii": "Palmeira dos Indios",
    "lat": -9.4166,
    "lng": -36.6166,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Alagoas",
    "capital": "",
    "population": 41095,
    "id": 1076723276
  },
  {
    "city": "Gagny",
    "city_ascii": "Gagny",
    "lat": 48.8833,
    "lng": 2.5333,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 39358,
    "id": 1250000251
  },
  {
    "city": "Newbury",
    "city_ascii": "Newbury",
    "lat": 51.401,
    "lng": -1.323,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "West Berkshire",
    "capital": "",
    "population": 41075,
    "id": 1826103829
  },
  {
    "city": "Oakland Park",
    "city_ascii": "Oakland Park",
    "lat": 26.178,
    "lng": -80.1528,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 45202,
    "id": 1840015139
  },
  {
    "city": "Veles",
    "city_ascii": "Veles",
    "lat": 41.7153,
    "lng": 21.7753,
    "country": "Macedonia",
    "iso2": "MK",
    "iso3": "MKD",
    "admin_name": "Veles",
    "capital": "admin",
    "population": 45037,
    "id": 1807468155
  },
  {
    "city": "Arlington",
    "city_ascii": "Arlington",
    "lat": 42.4187,
    "lng": -71.164,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 45147,
    "id": 1840053631
  },
  {
    "city": "Altadena",
    "city_ascii": "Altadena",
    "lat": 34.1928,
    "lng": -118.1345,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 45146,
    "id": 1840017918
  },
  {
    "city": "Melun",
    "city_ascii": "Melun",
    "lat": 48.5406,
    "lng": 2.66,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "minor",
    "population": 40032,
    "id": 1250913458
  },
  {
    "city": "Dunaújváros",
    "city_ascii": "Dunaujvaros",
    "lat": 46.9833,
    "lng": 18.9167,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Dunaújváros",
    "capital": "admin",
    "population": 44200,
    "id": 1348928583
  },
  {
    "city": "Sutton",
    "city_ascii": "Sutton",
    "lat": 51.3656,
    "lng": -0.1963,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Sutton",
    "capital": "",
    "population": 41483,
    "id": 1826827095
  },
  {
    "city": "Jaguaquara",
    "city_ascii": "Jaguaquara",
    "lat": -13.53,
    "lng": -39.97,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Bahia",
    "capital": "",
    "population": 41498,
    "id": 1076420862
  },
  {
    "city": "Bridgwater",
    "city_ascii": "Bridgwater",
    "lat": 51.128,
    "lng": -2.993,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Somerset",
    "capital": "",
    "population": 41276,
    "id": 1826346861
  },
  {
    "city": "Kabale",
    "city_ascii": "Kabale",
    "lat": -1.2496,
    "lng": 29.98,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Kabale",
    "capital": "admin",
    "population": 44600,
    "id": 1800408751
  },
  {
    "city": "Świdnik",
    "city_ascii": "Swidnik",
    "lat": 51.2333,
    "lng": 22.7,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Lubelskie",
    "capital": "minor",
    "population": 42797,
    "id": 1616873846
  },
  {
    "city": "Pinheiro",
    "city_ascii": "Pinheiro",
    "lat": -2.5196,
    "lng": -45.09,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Maranhão",
    "capital": "",
    "population": 38893,
    "id": 1076349995
  },
  {
    "city": "Russas",
    "city_ascii": "Russas",
    "lat": -4.9403,
    "lng": -37.9758,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Ceará",
    "capital": "",
    "population": 39465,
    "id": 1076843360
  },
  {
    "city": "Qiryat Bialik",
    "city_ascii": "Qiryat Bialik",
    "lat": 32.8331,
    "lng": 35.0664,
    "country": "Israel",
    "iso2": "IL",
    "iso3": "ISR",
    "admin_name": "Haifa",
    "capital": "",
    "population": 39900,
    "id": 1376833983
  },
  {
    "city": "Bishops Stortford",
    "city_ascii": "Bishops Stortford",
    "lat": 51.872,
    "lng": 0.1725,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hertfordshire",
    "capital": "",
    "population": 40089,
    "id": 1826440705
  },
  {
    "city": "Trujillo Alto",
    "city_ascii": "Trujillo Alto",
    "lat": 18.3601,
    "lng": -66.0103,
    "country": "Puerto Rico",
    "iso2": "PR",
    "iso3": "PRI",
    "admin_name": "Puerto Rico",
    "capital": "",
    "population": 44689,
    "id": 1630023656
  },
  {
    "city": "Bois-Colombes",
    "city_ascii": "Bois-Colombes",
    "lat": 48.9175,
    "lng": 2.2683,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 28239,
    "id": 1250698978
  },
  {
    "city": "Lecherías",
    "city_ascii": "Lecherias",
    "lat": 10.1889,
    "lng": -64.6951,
    "country": "Venezuela",
    "iso2": "VE",
    "iso3": "VEN",
    "admin_name": "Anzoátegui",
    "capital": "minor",
    "population": 37829,
    "id": 1862961933
  },
  {
    "city": "Umm al Qaywayn",
    "city_ascii": "Umm al Qaywayn",
    "lat": 25.5653,
    "lng": 55.5533,
    "country": "United Arab Emirates",
    "iso2": "AE",
    "iso3": "ARE",
    "admin_name": "Umm al Qaywayn",
    "capital": "admin",
    "population": 44411,
    "id": 1784525230
  },
  {
    "city": "Duncan",
    "city_ascii": "Duncan",
    "lat": 48.7787,
    "lng": -123.7079,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "British Columbia",
    "capital": "",
    "population": 44451,
    "id": 1124316061
  },
  {
    "city": "El Aïoun",
    "city_ascii": "El Aioun",
    "lat": 34.5853,
    "lng": -2.5056,
    "country": "Morocco",
    "iso2": "MA",
    "iso3": "MAR",
    "admin_name": "Oriental",
    "capital": "",
    "population": 41832,
    "id": 1504312724
  },
  {
    "city": "Kirkby",
    "city_ascii": "Kirkby",
    "lat": 53.4826,
    "lng": -2.892,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Knowsley",
    "capital": "",
    "population": 40472,
    "id": 1826773530
  },
  {
    "city": "Salisbury",
    "city_ascii": "Salisbury",
    "lat": 51.074,
    "lng": -1.7936,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Wiltshire",
    "capital": "",
    "population": 40302,
    "id": 1826304328
  },
  {
    "city": "Boumerdes",
    "city_ascii": "Boumerdes",
    "lat": 36.7594,
    "lng": 3.4728,
    "country": "Algeria",
    "iso2": "DZ",
    "iso3": "DZA",
    "admin_name": "Boumerdes",
    "capital": "admin",
    "population": 41685,
    "id": 1012627486
  },
  {
    "city": "North Lauderdale",
    "city_ascii": "North Lauderdale",
    "lat": 26.2113,
    "lng": -80.2209,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 44262,
    "id": 1840015138
  },
  {
    "city": "Caerphilly",
    "city_ascii": "Caerphilly",
    "lat": 51.578,
    "lng": -3.218,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Caerphilly",
    "capital": "",
    "population": 41402,
    "id": 1826322639
  },
  {
    "city": "Tarbes",
    "city_ascii": "Tarbes",
    "lat": 43.23,
    "lng": 0.07,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Occitanie",
    "capital": "minor",
    "population": 41518,
    "id": 1250001722
  },
  {
    "city": "Hackensack",
    "city_ascii": "Hackensack",
    "lat": 40.889,
    "lng": -74.0461,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 44188,
    "id": 1840003556
  },
  {
    "city": "Arras",
    "city_ascii": "Arras",
    "lat": 50.292,
    "lng": 2.78,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Hauts-de-France",
    "capital": "minor",
    "population": 41019,
    "id": 1250447540
  },
  {
    "city": "Caloundra",
    "city_ascii": "Caloundra",
    "lat": -26.7986,
    "lng": 153.1289,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Queensland",
    "capital": "",
    "population": 41293,
    "id": 1036266942
  },
  {
    "city": "Rezé",
    "city_ascii": "Reze",
    "lat": 47.1833,
    "lng": -1.55,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Pays de la Loire",
    "capital": "",
    "population": 41411,
    "id": 1250886805
  },
  {
    "city": "Wattrelos",
    "city_ascii": "Wattrelos",
    "lat": 50.7,
    "lng": 3.217,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Hauts-de-France",
    "capital": "",
    "population": 40958,
    "id": 1250832962
  },
  {
    "city": "Kampong Chhnang",
    "city_ascii": "Kampong Chhnang",
    "lat": 12.25,
    "lng": 104.6667,
    "country": "Cambodia",
    "iso2": "KH",
    "iso3": "KHM",
    "admin_name": "Kampong Chhnang",
    "capital": "admin",
    "population": 41700,
    "id": 1116735270
  },
  {
    "city": "Cleveland Heights",
    "city_ascii": "Cleveland Heights",
    "lat": 41.5112,
    "lng": -81.5636,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 43992,
    "id": 1840000597
  },
  {
    "city": "Fort Portal",
    "city_ascii": "Fort Portal",
    "lat": 0.671,
    "lng": 30.275,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Kabarole",
    "capital": "admin",
    "population": 42670,
    "id": 1800139086
  },
  {
    "city": "Ermezinde",
    "city_ascii": "Ermezinde",
    "lat": 41.2133,
    "lng": -8.5472,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Porto",
    "capital": "",
    "population": 38798,
    "id": 1620357803
  },
  {
    "city": "Haedo",
    "city_ascii": "Haedo",
    "lat": -34.65,
    "lng": -58.6,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires",
    "capital": "",
    "population": 37745,
    "id": 1032803323
  },
  {
    "city": "Paracho de Verduzco",
    "city_ascii": "Paracho de Verduzco",
    "lat": 19.65,
    "lng": -102.0667,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Michoacán de Ocampo",
    "capital": "",
    "population": 37464,
    "id": 1484536308
  },
  {
    "city": "Štip",
    "city_ascii": "Stip",
    "lat": 41.7358,
    "lng": 22.1914,
    "country": "Macedonia",
    "iso2": "MK",
    "iso3": "MKD",
    "admin_name": "Štip",
    "capital": "admin",
    "population": 43652,
    "id": 1807614061
  },
  {
    "city": "Annandale",
    "city_ascii": "Annandale",
    "lat": 38.8324,
    "lng": -77.196,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 43729,
    "id": 1840006011
  },
  {
    "city": "Drogheda",
    "city_ascii": "Drogheda",
    "lat": 53.7139,
    "lng": -6.3503,
    "country": "Ireland",
    "iso2": "IE",
    "iso3": "IRL",
    "admin_name": "Louth",
    "capital": "",
    "population": 40956,
    "id": 1372750233
  },
  {
    "city": "Shefar‘am",
    "city_ascii": "Shefar`am",
    "lat": 32.8056,
    "lng": 35.1694,
    "country": "Israel",
    "iso2": "IL",
    "iso3": "ISR",
    "admin_name": "Northern",
    "capital": "",
    "population": 41600,
    "id": 1376191471
  },
  {
    "city": "Munro",
    "city_ascii": "Munro",
    "lat": -34.5333,
    "lng": -58.5167,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires",
    "capital": "",
    "population": 35844,
    "id": 1032318811
  },
  {
    "city": "Mineiros",
    "city_ascii": "Mineiros",
    "lat": -17.5694,
    "lng": -52.5511,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Goiás",
    "capital": "",
    "population": 38951,
    "id": 1076000916
  },
  {
    "city": "Concord",
    "city_ascii": "Concord",
    "lat": 43.2305,
    "lng": -71.5595,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Hampshire",
    "capital": "admin",
    "population": 43542,
    "id": 1840002747
  },
  {
    "city": "Uman",
    "city_ascii": "Uman",
    "lat": 20.8833,
    "lng": -89.75,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Yucatán",
    "capital": "",
    "population": 39611,
    "id": 1484009408
  },
  {
    "city": "Grays",
    "city_ascii": "Grays",
    "lat": 51.475,
    "lng": 0.33,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Thurrock",
    "capital": "",
    "population": 36601,
    "id": 1826003493
  },
  {
    "city": "Remanso",
    "city_ascii": "Remanso",
    "lat": -9.5996,
    "lng": -42.11,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Bahia",
    "capital": "",
    "population": 37945,
    "id": 1076051035
  },
  {
    "city": "Abingdon",
    "city_ascii": "Abingdon",
    "lat": 51.667,
    "lng": -1.283,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Oxfordshire",
    "capital": "",
    "population": 39809,
    "id": 1826918722
  },
  {
    "city": "Nanuque",
    "city_ascii": "Nanuque",
    "lat": -17.8392,
    "lng": -40.3539,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Minas Gerais",
    "capital": "",
    "population": 37824,
    "id": 1076083471
  },
  {
    "city": "San Diego de la Unión",
    "city_ascii": "San Diego de la Union",
    "lat": 21.4656,
    "lng": -100.8736,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Guanajuato",
    "capital": "minor",
    "population": 39663,
    "id": 1484000829
  },
  {
    "city": "Hódmezővásárhely",
    "city_ascii": "Hodmezovasarhely",
    "lat": 46.4303,
    "lng": 20.3189,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Hódmezővásárhely",
    "capital": "admin",
    "population": 43311,
    "id": 1348676241
  },
  {
    "city": "Ramsgate",
    "city_ascii": "Ramsgate",
    "lat": 51.336,
    "lng": 1.416,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Kent",
    "capital": "",
    "population": 40408,
    "id": 1826278899
  },
  {
    "city": "Kalmar",
    "city_ascii": "Kalmar",
    "lat": 56.6694,
    "lng": 16.3218,
    "country": "Sweden",
    "iso2": "SE",
    "iso3": "SWE",
    "admin_name": "Kalmar",
    "capital": "admin",
    "population": 41110,
    "id": 1752640618
  },
  {
    "city": "Nogent-sur-Marne",
    "city_ascii": "Nogent-sur-Marne",
    "lat": 48.8375,
    "lng": 2.4833,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "minor",
    "population": 32851,
    "id": 1250793808
  },
  {
    "city": "Rohnert Park",
    "city_ascii": "Rohnert Park",
    "lat": 38.348,
    "lng": -122.6964,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 43291,
    "id": 1840020243
  },
  {
    "city": "Zugdidi",
    "city_ascii": "Zugdidi",
    "lat": 42.5083,
    "lng": 41.8667,
    "country": "Georgia",
    "iso2": "GE",
    "iso3": "GEO",
    "admin_name": "Samegrelo-Zemo Svaneti",
    "capital": "admin",
    "population": 42998,
    "id": 1268300325
  },
  {
    "city": "Mityana",
    "city_ascii": "Mityana",
    "lat": 0.4004,
    "lng": 32.05,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Mityana",
    "capital": "admin",
    "population": 41131,
    "id": 1800284151
  },
  {
    "city": "Cacém",
    "city_ascii": "Cacem",
    "lat": 38.7704,
    "lng": -9.3081,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Lisboa",
    "capital": "",
    "population": 21289,
    "id": 1620147105
  },
  {
    "city": "Le Kremlin-Bicêtre",
    "city_ascii": "Le Kremlin-Bicetre",
    "lat": 48.81,
    "lng": 2.3581,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 25334,
    "id": 1250286015
  },
  {
    "city": "Diamantina",
    "city_ascii": "Diamantina",
    "lat": -18.24,
    "lng": -43.61,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Minas Gerais",
    "capital": "",
    "population": 37343,
    "id": 1076865092
  },
  {
    "city": "Salem",
    "city_ascii": "Salem",
    "lat": 42.5129,
    "lng": -70.902,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 43226,
    "id": 1840000410
  },
  {
    "city": "Ilkeston",
    "city_ascii": "Ilkeston",
    "lat": 52.971,
    "lng": -1.309,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Derbyshire",
    "capital": "",
    "population": 38640,
    "id": 1826289943
  },
  {
    "city": "Kapan",
    "city_ascii": "Kapan",
    "lat": 39.2011,
    "lng": 46.415,
    "country": "Armenia",
    "iso2": "AM",
    "iso3": "ARM",
    "admin_name": "Syunik’",
    "capital": "admin",
    "population": 43190,
    "id": 1051527958
  },
  {
    "city": "Aldershot",
    "city_ascii": "Aldershot",
    "lat": 51.248,
    "lng": -0.758,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hampshire",
    "capital": "",
    "population": 36322,
    "id": 1826766548
  },
  {
    "city": "Cleethorpes",
    "city_ascii": "Cleethorpes",
    "lat": 53.5533,
    "lng": -0.0215,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "North East Lincolnshire",
    "capital": "",
    "population": 38996,
    "id": 1826584652
  },
  {
    "city": "Minas",
    "city_ascii": "Minas",
    "lat": -34.3667,
    "lng": -55.2333,
    "country": "Uruguay",
    "iso2": "UY",
    "iso3": "URY",
    "admin_name": "Lavalleja",
    "capital": "admin",
    "population": 38446,
    "id": 1858724823
  },
  {
    "city": "Leighton Buzzard",
    "city_ascii": "Leighton Buzzard",
    "lat": 51.9165,
    "lng": -0.6617,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Central Bedfordshire",
    "capital": "",
    "population": 37000,
    "id": 1826486102
  },
  {
    "city": "Alajuela",
    "city_ascii": "Alajuela",
    "lat": 10.0278,
    "lng": -84.2041,
    "country": "Costa Rica",
    "iso2": "CR",
    "iso3": "CRI",
    "admin_name": "Alajuela",
    "capital": "admin",
    "population": 42975,
    "id": 1188394508
  },
  {
    "city": "Whitney",
    "city_ascii": "Whitney",
    "lat": 36.1005,
    "lng": -115.038,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Nevada",
    "capital": "",
    "population": 43072,
    "id": 1840033834
  },
  {
    "city": "North Miami Beach",
    "city_ascii": "North Miami Beach",
    "lat": 25.9302,
    "lng": -80.166,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 43041,
    "id": 1840015155
  },
  {
    "city": "Franceville",
    "city_ascii": "Franceville",
    "lat": -1.6333,
    "lng": 13.5833,
    "country": "Gabon",
    "iso2": "GA",
    "iso3": "GAB",
    "admin_name": "Haut-Ogooué",
    "capital": "admin",
    "population": 42967,
    "id": 1266537692
  },
  {
    "city": "Garbahaarrey",
    "city_ascii": "Garbahaarrey",
    "lat": 3.35,
    "lng": 42.2667,
    "country": "Somalia",
    "iso2": "SO",
    "iso3": "SOM",
    "admin_name": "Gedo",
    "capital": "admin",
    "population": 43000,
    "id": 1706488487
  },
  {
    "city": "Perote",
    "city_ascii": "Perote",
    "lat": 19.562,
    "lng": -97.242,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Veracruz",
    "capital": "minor",
    "population": 37516,
    "id": 1484668149
  },
  {
    "city": "Xique-Xique",
    "city_ascii": "Xique-Xique",
    "lat": -10.8229,
    "lng": -42.7281,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Bahia",
    "capital": "",
    "population": 35433,
    "id": 1076763009
  },
  {
    "city": "Freeport",
    "city_ascii": "Freeport",
    "lat": 40.6515,
    "lng": -73.585,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 42956,
    "id": 1840005288
  },
  {
    "city": "West Babylon",
    "city_ascii": "West Babylon",
    "lat": 40.7112,
    "lng": -73.3567,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 42918,
    "id": 1840005109
  },
  {
    "city": "Dubrovnik",
    "city_ascii": "Dubrovnik",
    "lat": 42.6403,
    "lng": 18.1083,
    "country": "Croatia",
    "iso2": "HR",
    "iso3": "HRV",
    "admin_name": "Dubrovačko-Neretvanska Županija",
    "capital": "admin",
    "population": 42615,
    "id": 1191004286
  },
  {
    "city": "Blyth",
    "city_ascii": "Blyth",
    "lat": 55.126,
    "lng": -1.514,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Northumberland",
    "capital": "",
    "population": 37339,
    "id": 1826333350
  },
  {
    "city": "Ciudad Manuel Doblado",
    "city_ascii": "Ciudad Manuel Doblado",
    "lat": 20.7303,
    "lng": -101.9533,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Guanajuato",
    "capital": "minor",
    "population": 38309,
    "id": 1484254805
  },
  {
    "city": "San Bruno",
    "city_ascii": "San Bruno",
    "lat": 37.6254,
    "lng": -122.4313,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 42807,
    "id": 1840021549
  },
  {
    "city": "Quinhámel",
    "city_ascii": "Quinhamel",
    "lat": 11.8869,
    "lng": -15.8556,
    "country": "Guinea-Bissau",
    "iso2": "GW",
    "iso3": "GNB",
    "admin_name": "Biombo",
    "capital": "admin",
    "population": 42659,
    "id": 1624877378
  },
  {
    "city": "Le Perreux-Sur-Marne",
    "city_ascii": "Le Perreux-Sur-Marne",
    "lat": 48.8422,
    "lng": 2.5036,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 33879,
    "id": 1250033970
  },
  {
    "city": "Silistra",
    "city_ascii": "Silistra",
    "lat": 44.1186,
    "lng": 27.2603,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Silistra",
    "capital": "admin",
    "population": 42651,
    "id": 1100458181
  },
  {
    "city": "Hicksville",
    "city_ascii": "Hicksville",
    "lat": 40.7637,
    "lng": -73.5245,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 42649,
    "id": 1840005266
  },
  {
    "city": "Guarda",
    "city_ascii": "Guarda",
    "lat": 40.5364,
    "lng": -7.2683,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Guarda",
    "capital": "admin",
    "population": 42541,
    "id": 1620174984
  },
  {
    "city": "Si Sa Ket",
    "city_ascii": "Si Sa Ket",
    "lat": 15.1069,
    "lng": 104.3294,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Sisaket",
    "capital": "admin",
    "population": 42405,
    "id": 1764002764
  },
  {
    "city": "Aldridge",
    "city_ascii": "Aldridge",
    "lat": 52.606,
    "lng": -1.9179,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Walsall",
    "capital": "",
    "population": 39348,
    "id": 1826685347
  },
  {
    "city": "Nakama",
    "city_ascii": "Nakama",
    "lat": 33.8167,
    "lng": 130.709,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Fukuoka",
    "capital": "",
    "population": 40003,
    "id": 1392968664
  },
  {
    "city": "Kampot",
    "city_ascii": "Kampot",
    "lat": 10.6,
    "lng": 104.1667,
    "country": "Cambodia",
    "iso2": "KH",
    "iso3": "KHM",
    "admin_name": "Kampot",
    "capital": "admin",
    "population": 39186,
    "id": 1116966757
  },
  {
    "city": "Fribourg",
    "city_ascii": "Fribourg",
    "lat": 46.8,
    "lng": 7.15,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Fribourg",
    "capital": "admin",
    "population": 38365,
    "id": 1756145667
  },
  {
    "city": "Long Eaton",
    "city_ascii": "Long Eaton",
    "lat": 52.898,
    "lng": -1.271,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Derbyshire",
    "capital": "",
    "population": 37760,
    "id": 1826989609
  },
  {
    "city": "Annemasse",
    "city_ascii": "Annemasse",
    "lat": 46.1958,
    "lng": 6.2364,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Auvergne-Rhône-Alpes",
    "capital": "",
    "population": 35712,
    "id": 1250122238
  },
  {
    "city": "La Barca",
    "city_ascii": "La Barca",
    "lat": 20.2903,
    "lng": -102.5456,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Jalisco",
    "capital": "minor",
    "population": 35345,
    "id": 1484900173
  },
  {
    "city": "Arnold",
    "city_ascii": "Arnold",
    "lat": 53.005,
    "lng": -1.127,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Nottinghamshire",
    "capital": "",
    "population": 37768,
    "id": 1826647385
  },
  {
    "city": "Lovech",
    "city_ascii": "Lovech",
    "lat": 43.1342,
    "lng": 24.7172,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Lovech",
    "capital": "admin",
    "population": 42343,
    "id": 1100374163
  },
  {
    "city": "Itapecuru Mirim",
    "city_ascii": "Itapecuru Mirim",
    "lat": -3.3925,
    "lng": -44.3586,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Maranhão",
    "capital": "",
    "population": 34943,
    "id": 1076422017
  },
  {
    "city": "Berat",
    "city_ascii": "Berat",
    "lat": 40.7049,
    "lng": 19.9497,
    "country": "Albania",
    "iso2": "AL",
    "iso3": "ALB",
    "admin_name": "Berat",
    "capital": "admin",
    "population": 36496,
    "id": 1008955153
  },
  {
    "city": "Small Heath",
    "city_ascii": "Small Heath",
    "lat": 52.4629,
    "lng": -1.8542,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Birmingham",
    "capital": "",
    "population": 36898,
    "id": 1826945156
  },
  {
    "city": "Myrhorod",
    "city_ascii": "Myrhorod",
    "lat": 49.964,
    "lng": 33.6124,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Poltavs’ka Oblast’",
    "capital": "minor",
    "population": 40160,
    "id": 1804138671
  },
  {
    "city": "Sidi Bouzid",
    "city_ascii": "Sidi Bouzid",
    "lat": 35.0167,
    "lng": 9.5,
    "country": "Tunisia",
    "iso2": "TN",
    "iso3": "TUN",
    "admin_name": "Sidi Bouzid",
    "capital": "admin",
    "population": 42098,
    "id": 1788196439
  },
  {
    "city": "Ardahan",
    "city_ascii": "Ardahan",
    "lat": 41.1167,
    "lng": 42.7,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Ardahan",
    "capital": "admin",
    "population": 42226,
    "id": 1792379425
  },
  {
    "city": "Saint-Martin-d’Hères",
    "city_ascii": "Saint-Martin-d'Heres",
    "lat": 45.1672,
    "lng": 5.7653,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Auvergne-Rhône-Alpes",
    "capital": "",
    "population": 38487,
    "id": 1250862617
  },
  {
    "city": "Douai",
    "city_ascii": "Douai",
    "lat": 50.3714,
    "lng": 3.08,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Hauts-de-France",
    "capital": "minor",
    "population": 39700,
    "id": 1250026390
  },
  {
    "city": "Dock Sur",
    "city_ascii": "Dock Sur",
    "lat": -34.6417,
    "lng": -58.3478,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires",
    "capital": "",
    "population": 35897,
    "id": 1032579921
  },
  {
    "city": "Bambari",
    "city_ascii": "Bambari",
    "lat": 5.7667,
    "lng": 20.6833,
    "country": "Central African Republic",
    "iso2": "CF",
    "iso3": "CAF",
    "admin_name": "Ouaka",
    "capital": "admin",
    "population": 41356,
    "id": 1140304277
  },
  {
    "city": "Herne Bay",
    "city_ascii": "Herne Bay",
    "lat": 51.37,
    "lng": 1.13,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Kent",
    "capital": "",
    "population": 38563,
    "id": 1826454069
  },
  {
    "city": "Tonalá",
    "city_ascii": "Tonala",
    "lat": 16.0894,
    "lng": -93.7514,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Chiapas",
    "capital": "minor",
    "population": 35322,
    "id": 1484944309
  },
  {
    "city": "Joaçaba",
    "city_ascii": "Joacaba",
    "lat": -27.17,
    "lng": -51.5,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Santa Catarina",
    "capital": "",
    "population": 38876,
    "id": 1076899882
  },
  {
    "city": "Cao Bằng",
    "city_ascii": "Cao Bang",
    "lat": 22.6731,
    "lng": 106.25,
    "country": "Vietnam",
    "iso2": "VN",
    "iso3": "VNM",
    "admin_name": "Cao Bằng",
    "capital": "admin",
    "population": 41112,
    "id": 1704552735
  },
  {
    "city": "San José",
    "city_ascii": "San Jose",
    "lat": -34.3333,
    "lng": -56.7167,
    "country": "Uruguay",
    "iso2": "UY",
    "iso3": "URY",
    "admin_name": "San José",
    "capital": "admin",
    "population": 36747,
    "id": 1858927597
  },
  {
    "city": "Aberdare",
    "city_ascii": "Aberdare",
    "lat": 51.713,
    "lng": -3.445,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Rhondda Cynon Taff",
    "capital": "",
    "population": 39550,
    "id": 1826427395
  },
  {
    "city": "Franconville",
    "city_ascii": "Franconville",
    "lat": 48.9889,
    "lng": 2.2314,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 36762,
    "id": 1250716454
  },
  {
    "city": "Damān",
    "city_ascii": "Daman",
    "lat": 20.417,
    "lng": 72.85,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Gujarāt",
    "capital": "admin",
    "population": 39737,
    "id": 1356008028
  },
  {
    "city": "Bell Gardens",
    "city_ascii": "Bell Gardens",
    "lat": 33.9663,
    "lng": -118.155,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 42012,
    "id": 1840019229
  },
  {
    "city": "Laguna",
    "city_ascii": "Laguna",
    "lat": -28.48,
    "lng": -48.78,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Santa Catarina",
    "capital": "",
    "population": 39711,
    "id": 1076025747
  },
  {
    "city": "Razgrad",
    "city_ascii": "Razgrad",
    "lat": 43.5267,
    "lng": 26.5242,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Razgrad",
    "capital": "admin",
    "population": 41909,
    "id": 1100143131
  },
  {
    "city": "Glenrothes",
    "city_ascii": "Glenrothes",
    "lat": 56.198,
    "lng": -3.178,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Fife",
    "capital": "",
    "population": 38240,
    "id": 1826270044
  },
  {
    "city": "Savigny-sur-Orge",
    "city_ascii": "Savigny-sur-Orge",
    "lat": 48.6797,
    "lng": 2.3457,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 36533,
    "id": 1250549576
  },
  {
    "city": "Kahama",
    "city_ascii": "Kahama",
    "lat": -3.84,
    "lng": 32.6,
    "country": "Tanzania",
    "iso2": "TZ",
    "iso3": "TZA",
    "admin_name": "Shinyanga",
    "capital": "",
    "population": 36014,
    "id": 1834043034
  },
  {
    "city": "Amudālavalasa",
    "city_ascii": "Amudalavalasa",
    "lat": 18.4167,
    "lng": 83.9,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Andhra Pradesh",
    "capital": "",
    "population": 39799,
    "id": 1356850881
  },
  {
    "city": "Lindi",
    "city_ascii": "Lindi",
    "lat": -9.9969,
    "lng": 39.7144,
    "country": "Tanzania",
    "iso2": "TZ",
    "iso3": "TZA",
    "admin_name": "Lindi",
    "capital": "admin",
    "population": 41549,
    "id": 1834550750
  },
  {
    "city": "Chiquimula",
    "city_ascii": "Chiquimula",
    "lat": 14.797,
    "lng": -89.544,
    "country": "Guatemala",
    "iso2": "GT",
    "iso3": "GTM",
    "admin_name": "Chiquimula",
    "capital": "admin",
    "population": 41521,
    "id": 1320041079
  },
  {
    "city": "Campbell",
    "city_ascii": "Campbell",
    "lat": 37.2802,
    "lng": -121.9538,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 41793,
    "id": 1840018949
  },
  {
    "city": "Port Talbot",
    "city_ascii": "Port Talbot",
    "lat": 51.5906,
    "lng": -3.7986,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Neath Port Talbot",
    "capital": "",
    "population": 37276,
    "id": 1826981147
  },
  {
    "city": "Bodø",
    "city_ascii": "Bodo",
    "lat": 67.2827,
    "lng": 14.3751,
    "country": "Norway",
    "iso2": "NO",
    "iso3": "NOR",
    "admin_name": "Nordland",
    "capital": "admin",
    "population": 41720,
    "id": 1578023501
  },
  {
    "city": "Puntarenas",
    "city_ascii": "Puntarenas",
    "lat": 9.9764,
    "lng": -84.8339,
    "country": "Costa Rica",
    "iso2": "CR",
    "iso3": "CRI",
    "admin_name": "Puntarenas",
    "capital": "admin",
    "population": 41528,
    "id": 1188639267
  },
  {
    "city": "Woonsocket",
    "city_ascii": "Woonsocket",
    "lat": 42.001,
    "lng": -71.4993,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Rhode Island",
    "capital": "",
    "population": 41751,
    "id": 1840003290
  },
  {
    "city": "Xico",
    "city_ascii": "Xico",
    "lat": 19.417,
    "lng": -97,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Veracruz",
    "capital": "minor",
    "population": 38198,
    "id": 1484011085
  },
  {
    "city": "Narathiwat",
    "city_ascii": "Narathiwat",
    "lat": 6.4264,
    "lng": 101.8231,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Narathiwat",
    "capital": "admin",
    "population": 41342,
    "id": 1764748532
  },
  {
    "city": "Nueva Rosita",
    "city_ascii": "Nueva Rosita",
    "lat": 27.939,
    "lng": -101.218,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Coahuila de Zaragoza",
    "capital": "",
    "population": 36642,
    "id": 1484540557
  },
  {
    "city": "Coroatá",
    "city_ascii": "Coroata",
    "lat": -4.13,
    "lng": -44.1242,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Maranhão",
    "capital": "",
    "population": 34129,
    "id": 1076890180
  },
  {
    "city": "La Paz",
    "city_ascii": "La Paz",
    "lat": 14.3169,
    "lng": -87.6831,
    "country": "Honduras",
    "iso2": "HN",
    "iso3": "HND",
    "admin_name": "La Paz",
    "capital": "admin",
    "population": 41250,
    "id": 1340290947
  },
  {
    "city": "Villa Adelina",
    "city_ascii": "Villa Adelina",
    "lat": -34.5175,
    "lng": -58.5475,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires",
    "capital": "",
    "population": 35307,
    "id": 1032929712
  },
  {
    "city": "Morshansk",
    "city_ascii": "Morshansk",
    "lat": 53.45,
    "lng": 41.8,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Tambovskaya Oblast’",
    "capital": "",
    "population": 39362,
    "id": 1643010235
  },
  {
    "city": "Échirolles",
    "city_ascii": "Echirolles",
    "lat": 45.1436,
    "lng": 5.7183,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Auvergne-Rhône-Alpes",
    "capital": "",
    "population": 36840,
    "id": 1250068443
  },
  {
    "city": "Palayan City",
    "city_ascii": "Palayan City",
    "lat": 15.5333,
    "lng": 121.0833,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Nueva Ecija",
    "capital": "admin",
    "population": 41041,
    "id": 1608192136
  },
  {
    "city": "Lusambo",
    "city_ascii": "Lusambo",
    "lat": -4.9696,
    "lng": 23.43,
    "country": "Congo (Kinshasa)",
    "iso2": "CD",
    "iso3": "COD",
    "admin_name": "Sankuru",
    "capital": "admin",
    "population": 41416,
    "id": 1180127290
  },
  {
    "city": "Salvatierra",
    "city_ascii": "Salvatierra",
    "lat": 20.2156,
    "lng": -100.8961,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Guanajuato",
    "capital": "minor",
    "population": 34066,
    "id": 1484878589
  },
  {
    "city": "Marcq-en-Baroeul",
    "city_ascii": "Marcq-en-Baroeul",
    "lat": 50.6711,
    "lng": 3.0972,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Hauts-de-France",
    "capital": "",
    "population": 38617,
    "id": 1250045997
  },
  {
    "city": "Villarrica",
    "city_ascii": "Villarrica",
    "lat": -25.75,
    "lng": -56.4333,
    "country": "Paraguay",
    "iso2": "PY",
    "iso3": "PRY",
    "admin_name": "Guairá",
    "capital": "admin",
    "population": 41157,
    "id": 1600446518
  },
  {
    "city": "Saurimo",
    "city_ascii": "Saurimo",
    "lat": -9.6596,
    "lng": 20.39,
    "country": "Angola",
    "iso2": "AO",
    "iso3": "AGO",
    "admin_name": "Lunda Sul",
    "capital": "admin",
    "population": 41316,
    "id": 1024326839
  },
  {
    "city": "Kisii",
    "city_ascii": "Kisii",
    "lat": -0.6817,
    "lng": 34.7667,
    "country": "Kenya",
    "iso2": "KE",
    "iso3": "KEN",
    "admin_name": "Kisii",
    "capital": "admin",
    "population": 37531,
    "id": 1404617601
  },
  {
    "city": "Oriximiná",
    "city_ascii": "Oriximina",
    "lat": -1.7656,
    "lng": -55.8661,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Pará",
    "capital": "",
    "population": 35581,
    "id": 1076567469
  },
  {
    "city": "Kozáni",
    "city_ascii": "Kozani",
    "lat": 40.3007,
    "lng": 21.789,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Dytikí Makedonía",
    "capital": "admin",
    "population": 41066,
    "id": 1300407295
  },
  {
    "city": "Puerto Francisco de Orellana",
    "city_ascii": "Puerto Francisco de Orellana",
    "lat": -0.4625,
    "lng": -76.9842,
    "country": "Ecuador",
    "iso2": "EC",
    "iso3": "ECU",
    "admin_name": "Orellana",
    "capital": "admin",
    "population": 40730,
    "id": 1218387446
  },
  {
    "city": "Vredenburg",
    "city_ascii": "Vredenburg",
    "lat": -32.9,
    "lng": 17.9833,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "Western Cape",
    "capital": "",
    "population": 38382,
    "id": 1710121734
  },
  {
    "city": "Malbork",
    "city_ascii": "Malbork",
    "lat": 54.0285,
    "lng": 19.0444,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Pomorskie",
    "capital": "minor",
    "population": 38895,
    "id": 1616964352
  },
  {
    "city": "Panaji",
    "city_ascii": "Panaji",
    "lat": 15.48,
    "lng": 73.83,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Goa",
    "capital": "admin",
    "population": 40017,
    "id": 1356909212
  },
  {
    "city": "Greenacres",
    "city_ascii": "Greenacres",
    "lat": 26.6272,
    "lng": -80.1371,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 41117,
    "id": 1840036057
  },
  {
    "city": "Villefranche-sur-Saône",
    "city_ascii": "Villefranche-sur-Saone",
    "lat": 45.9833,
    "lng": 4.7167,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Auvergne-Rhône-Alpes",
    "capital": "minor",
    "population": 36857,
    "id": 1250969422
  },
  {
    "city": "Hyde",
    "city_ascii": "Hyde",
    "lat": 53.4474,
    "lng": -2.082,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Tameside",
    "capital": "",
    "population": 34003,
    "id": 1826480324
  },
  {
    "city": "Famagusta",
    "city_ascii": "Famagusta",
    "lat": 35.1167,
    "lng": 33.95,
    "country": "Cyprus",
    "iso2": "CY",
    "iso3": "CYP",
    "admin_name": "Ammóchostos",
    "capital": "admin",
    "population": 40920,
    "id": 1196128852
  },
  {
    "city": "Cachan",
    "city_ascii": "Cachan",
    "lat": 48.7919,
    "lng": 2.3319,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 31069,
    "id": 1250259069
  },
  {
    "city": "Ngozi",
    "city_ascii": "Ngozi",
    "lat": -2.9,
    "lng": 29.8167,
    "country": "Burundi",
    "iso2": "BI",
    "iso3": "BDI",
    "admin_name": "Ngozi",
    "capital": "admin",
    "population": 39884,
    "id": 1108753930
  },
  {
    "city": "Miyoshidai",
    "city_ascii": "Miyoshidai",
    "lat": 35.8283,
    "lng": 139.5267,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Saitama",
    "capital": "",
    "population": 38393,
    "id": 1392859111
  },
  {
    "city": "Barreiros",
    "city_ascii": "Barreiros",
    "lat": -8.8183,
    "lng": -35.1864,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Pernambuco",
    "capital": "",
    "population": 35565,
    "id": 1076661261
  },
  {
    "city": "Chartres",
    "city_ascii": "Chartres",
    "lat": 48.456,
    "lng": 1.484,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Centre-Val de Loire",
    "capital": "minor",
    "population": 38578,
    "id": 1250325561
  },
  {
    "city": "Wilkes-Barre",
    "city_ascii": "Wilkes-Barre",
    "lat": 41.2468,
    "lng": -75.8759,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 40766,
    "id": 1840000777
  },
  {
    "city": "Fleet",
    "city_ascii": "Fleet",
    "lat": 51.2834,
    "lng": -0.8456,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hampshire",
    "capital": "",
    "population": 37000,
    "id": 1826039041
  },
  {
    "city": "Şəmkir",
    "city_ascii": "Samkir",
    "lat": 40.8564,
    "lng": 46.0172,
    "country": "Azerbaijan",
    "iso2": "AZ",
    "iso3": "AZE",
    "admin_name": "Şəmkir",
    "capital": "admin",
    "population": 40600,
    "id": 1031776445
  },
  {
    "city": "Pärnu",
    "city_ascii": "Parnu",
    "lat": 58.3844,
    "lng": 24.4989,
    "country": "Estonia",
    "iso2": "EE",
    "iso3": "EST",
    "admin_name": "Pärnumaa",
    "capital": "admin",
    "population": 39438,
    "id": 1233955054
  },
  {
    "city": "Whitley Bay",
    "city_ascii": "Whitley Bay",
    "lat": 55.0456,
    "lng": -1.4443,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "North Tyneside",
    "capital": "",
    "population": 36623,
    "id": 1826742465
  },
  {
    "city": "Dún Dealgan",
    "city_ascii": "Dun Dealgan",
    "lat": 54.009,
    "lng": -6.4049,
    "country": "Ireland",
    "iso2": "IE",
    "iso3": "IRL",
    "admin_name": "Louth",
    "capital": "admin",
    "population": 39004,
    "id": 1372735852
  },
  {
    "city": "Al Karnak",
    "city_ascii": "Al Karnak",
    "lat": 25.7184,
    "lng": 32.6581,
    "country": "Egypt",
    "iso2": "EG",
    "iso3": "EGY",
    "admin_name": "Al Uqşur",
    "capital": "",
    "population": 26488,
    "id": 1818862945
  },
  {
    "city": "Rosário do Sul",
    "city_ascii": "Rosario do Sul",
    "lat": -30.2583,
    "lng": -54.9142,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Rio Grande do Sul",
    "capital": "",
    "population": 36721,
    "id": 1076900712
  },
  {
    "city": "Teaneck",
    "city_ascii": "Teaneck",
    "lat": 40.89,
    "lng": -74.0107,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 40543,
    "id": 1840081757
  },
  {
    "city": "Poissy",
    "city_ascii": "Poissy",
    "lat": 48.9294,
    "lng": 2.0456,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 37388,
    "id": 1250776265
  },
  {
    "city": "Bjelovar",
    "city_ascii": "Bjelovar",
    "lat": 45.8989,
    "lng": 16.8422,
    "country": "Croatia",
    "iso2": "HR",
    "iso3": "HRV",
    "admin_name": "Bjelovarsko-Bilogorska Županija",
    "capital": "admin",
    "population": 40276,
    "id": 1191340640
  },
  {
    "city": "Al Bayḑā’",
    "city_ascii": "Al Bayda'",
    "lat": 13.979,
    "lng": 45.574,
    "country": "Yemen",
    "iso2": "YE",
    "iso3": "YEM",
    "admin_name": "Al Bayḑā’",
    "capital": "admin",
    "population": 37821,
    "id": 1887788283
  },
  {
    "city": "Waipahu",
    "city_ascii": "Waipahu",
    "lat": 21.3858,
    "lng": -158.0103,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Hawaii",
    "capital": "",
    "population": 40427,
    "id": 1840023258
  },
  {
    "city": "Yambio",
    "city_ascii": "Yambio",
    "lat": 4.5705,
    "lng": 28.4163,
    "country": "South Sudan",
    "iso2": "SS",
    "iso3": "SSD",
    "admin_name": "Western Equatoria",
    "capital": "admin",
    "population": 40382,
    "id": 1728463035
  },
  {
    "city": "Bouar",
    "city_ascii": "Bouar",
    "lat": 5.95,
    "lng": 15.6,
    "country": "Central African Republic",
    "iso2": "CF",
    "iso3": "CAF",
    "admin_name": "Nana-Mambéré",
    "capital": "admin",
    "population": 40353,
    "id": 1140144321
  },
  {
    "city": "Beeston",
    "city_ascii": "Beeston",
    "lat": 52.927,
    "lng": -1.215,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Nottinghamshire",
    "capital": "",
    "population": 37000,
    "id": 1826483601
  },
  {
    "city": "Kanie",
    "city_ascii": "Kanie",
    "lat": 35.1322,
    "lng": 136.7869,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Aichi",
    "capital": "",
    "population": 37048,
    "id": 1392146454
  },
  {
    "city": "Villepinte",
    "city_ascii": "Villepinte",
    "lat": 48.955,
    "lng": 2.541,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 36830,
    "id": 1250274181
  },
  {
    "city": "Ban Sai Ma Tai",
    "city_ascii": "Ban Sai Ma Tai",
    "lat": 13.8444,
    "lng": 100.4829,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Nonthaburi",
    "capital": "",
    "population": 30750,
    "id": 1764698508
  },
  {
    "city": "Camberley",
    "city_ascii": "Camberley",
    "lat": 51.335,
    "lng": -0.742,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Surrey",
    "capital": "",
    "population": 38038,
    "id": 1826909512
  },
  {
    "city": "Agualva",
    "city_ascii": "Agualva",
    "lat": 38.77,
    "lng": -9.2988,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Lisboa",
    "capital": "",
    "population": 35824,
    "id": 1620936507
  },
  {
    "city": "Kaya",
    "city_ascii": "Kaya",
    "lat": 13.0904,
    "lng": -1.09,
    "country": "Burkina Faso",
    "iso2": "BF",
    "iso3": "BFA",
    "admin_name": "Centre-Nord",
    "capital": "admin",
    "population": 40017,
    "id": 1854170035
  },
  {
    "city": "Al Wāsiţah",
    "city_ascii": "Al Wasitah",
    "lat": 29.3372,
    "lng": 31.2036,
    "country": "Egypt",
    "iso2": "EG",
    "iso3": "EGY",
    "admin_name": "Al Fayyūm",
    "capital": "",
    "population": 37916,
    "id": 1818633360
  },
  {
    "city": "Inongo",
    "city_ascii": "Inongo",
    "lat": -1.94,
    "lng": 18.28,
    "country": "Congo (Kinshasa)",
    "iso2": "CD",
    "iso3": "COD",
    "admin_name": "Mai-Ndombe",
    "capital": "admin",
    "population": 40113,
    "id": 1180194164
  },
  {
    "city": "Montclair",
    "city_ascii": "Montclair",
    "lat": 34.0714,
    "lng": -117.698,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 40083,
    "id": 1840020408
  },
  {
    "city": "Retalhuleu",
    "city_ascii": "Retalhuleu",
    "lat": 14.5333,
    "lng": -91.6833,
    "country": "Guatemala",
    "iso2": "GT",
    "iso3": "GTM",
    "admin_name": "Retalhuleu",
    "capital": "admin",
    "population": 40000,
    "id": 1320999503
  },
  {
    "city": "Maués",
    "city_ascii": "Maues",
    "lat": -3.3896,
    "lng": -57.72,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Amazonas",
    "capital": "",
    "population": 32561,
    "id": 1076490155
  },
  {
    "city": "Deçan",
    "city_ascii": "Decan",
    "lat": 42.5333,
    "lng": 20.2833,
    "country": "Kosovo",
    "iso2": "XK",
    "iso3": "XKS",
    "admin_name": "Deçan",
    "capital": "admin",
    "population": 40019,
    "id": 1901189818
  },
  {
    "city": "Dunstable",
    "city_ascii": "Dunstable",
    "lat": 51.886,
    "lng": -0.521,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Central Bedfordshire",
    "capital": "",
    "population": 35000,
    "id": 1826601477
  },
  {
    "city": "Xaçmaz",
    "city_ascii": "Xacmaz",
    "lat": 41.4681,
    "lng": 48.8028,
    "country": "Azerbaijan",
    "iso2": "AZ",
    "iso3": "AZE",
    "admin_name": "Xaçmaz",
    "capital": "admin",
    "population": 39900,
    "id": 1031335433
  },
  {
    "city": "Chachoengsao",
    "city_ascii": "Chachoengsao",
    "lat": 13.6903,
    "lng": 101.0703,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Chachoengsao",
    "capital": "admin",
    "population": 39570,
    "id": 1764632382
  },
  {
    "city": "Bletchley",
    "city_ascii": "Bletchley",
    "lat": 51.994,
    "lng": -0.732,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Milton Keynes",
    "capital": "",
    "population": 37114,
    "id": 1826878705
  },
  {
    "city": "Sainte-Geneviève-des-Bois",
    "city_ascii": "Sainte-Genevieve-des-Bois",
    "lat": 48.6369,
    "lng": 2.3403,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 36207,
    "id": 1250428869
  },
  {
    "city": "Denton",
    "city_ascii": "Denton",
    "lat": 53.4554,
    "lng": -2.1122,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Tameside",
    "capital": "",
    "population": 36591,
    "id": 1826948278
  },
  {
    "city": "Surin",
    "city_ascii": "Surin",
    "lat": 14.8833,
    "lng": 103.4931,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Surin",
    "capital": "admin",
    "population": 39728,
    "id": 1764813179
  },
  {
    "city": "San Gabriel",
    "city_ascii": "San Gabriel",
    "lat": 34.0948,
    "lng": -118.099,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 39899,
    "id": 1840021862
  },
  {
    "city": "Neuilly-sur-Marne",
    "city_ascii": "Neuilly-sur-Marne",
    "lat": 48.8537,
    "lng": 2.549,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 34993,
    "id": 1250377296
  },
  {
    "city": "Koumra",
    "city_ascii": "Koumra",
    "lat": 8.9,
    "lng": 17.55,
    "country": "Chad",
    "iso2": "TD",
    "iso3": "TCD",
    "admin_name": "Mandoul",
    "capital": "admin",
    "population": 39852,
    "id": 1148599298
  },
  {
    "city": "North Shields",
    "city_ascii": "North Shields",
    "lat": 55.0097,
    "lng": -1.4448,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "North Tyneside",
    "capital": "",
    "population": 34423,
    "id": 1826426225
  },
  {
    "city": "Zacatecoluca",
    "city_ascii": "Zacatecoluca",
    "lat": 13.508,
    "lng": -88.868,
    "country": "El Salvador",
    "iso2": "SV",
    "iso3": "SLV",
    "admin_name": "La Paz",
    "capital": "admin",
    "population": 39613,
    "id": 1222647953
  },
  {
    "city": "Hallandale Beach",
    "city_ascii": "Hallandale Beach",
    "lat": 25.9854,
    "lng": -80.1423,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 39847,
    "id": 1840014237
  },
  {
    "city": "Massawa",
    "city_ascii": "Massawa",
    "lat": 15.6,
    "lng": 39.4333,
    "country": "Eritrea",
    "iso2": "ER",
    "iso3": "ERI",
    "admin_name": "Semēnawī K’eyih Bahrī",
    "capital": "admin",
    "population": 39758,
    "id": 1232344222
  },
  {
    "city": "Pont-y-pŵl",
    "city_ascii": "Pont-y-pwl",
    "lat": 51.703,
    "lng": -3.041,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Torfaen",
    "capital": "",
    "population": 35447,
    "id": 1826931206
  },
  {
    "city": "Santa Elena",
    "city_ascii": "Santa Elena",
    "lat": -2.2267,
    "lng": -80.8583,
    "country": "Ecuador",
    "iso2": "EC",
    "iso3": "ECU",
    "admin_name": "Santa Elena",
    "capital": "admin",
    "population": 39681,
    "id": 1218296446
  },
  {
    "city": "Kenton",
    "city_ascii": "Kenton",
    "lat": 51.5878,
    "lng": -0.3086,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Brent",
    "capital": "",
    "population": 35600,
    "id": 1826411445
  },
  {
    "city": "Keizer",
    "city_ascii": "Keizer",
    "lat": 45.0029,
    "lng": -123.0241,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 39713,
    "id": 1840018581
  },
  {
    "city": "Mochudi",
    "city_ascii": "Mochudi",
    "lat": -24.377,
    "lng": 26.152,
    "country": "Botswana",
    "iso2": "BW",
    "iso3": "BWA",
    "admin_name": "Kgatleng",
    "capital": "admin",
    "population": 39700,
    "id": 1072876960
  },
  {
    "city": "Vernier",
    "city_ascii": "Vernier",
    "lat": 46.2,
    "lng": 6.1,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Genève",
    "capital": "",
    "population": 35132,
    "id": 1756335917
  },
  {
    "city": "Chelsea",
    "city_ascii": "Chelsea",
    "lat": 42.3959,
    "lng": -71.0325,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 39690,
    "id": 1840000456
  },
  {
    "city": "Bridlington",
    "city_ascii": "Bridlington",
    "lat": 54.0819,
    "lng": -0.1923,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "East Riding of Yorkshire",
    "capital": "",
    "population": 35369,
    "id": 1826640623
  },
  {
    "city": "Sopot",
    "city_ascii": "Sopot",
    "lat": 54.4419,
    "lng": 18.5478,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Pomorskie",
    "capital": "minor",
    "population": 37457,
    "id": 1616375300
  },
  {
    "city": "La Puente",
    "city_ascii": "La Puente",
    "lat": 34.0323,
    "lng": -117.9533,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 39614,
    "id": 1840020513
  },
  {
    "city": "Conflans-Sainte-Honorine",
    "city_ascii": "Conflans-Sainte-Honorine",
    "lat": 48.9992,
    "lng": 2.0983,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 35846,
    "id": 1250363572
  },
  {
    "city": "Tozeur",
    "city_ascii": "Tozeur",
    "lat": 33.9304,
    "lng": 8.13,
    "country": "Tunisia",
    "iso2": "TN",
    "iso3": "TUN",
    "admin_name": "Tozeur",
    "capital": "admin",
    "population": 39504,
    "id": 1788636202
  },
  {
    "city": "Celje",
    "city_ascii": "Celje",
    "lat": 46.2291,
    "lng": 15.2641,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Celje",
    "capital": "admin",
    "population": 37875,
    "id": 1705563326
  },
  {
    "city": "Kendall West",
    "city_ascii": "Kendall West",
    "lat": 25.7065,
    "lng": -80.4388,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 39491,
    "id": 1840035805
  },
  {
    "city": "Cristalina",
    "city_ascii": "Cristalina",
    "lat": -16.7677,
    "lng": -47.6153,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Goiás",
    "capital": "",
    "population": 36531,
    "id": 1076014539
  },
  {
    "city": "Florida",
    "city_ascii": "Florida",
    "lat": -34.1,
    "lng": -56.2167,
    "country": "Uruguay",
    "iso2": "UY",
    "iso3": "URY",
    "admin_name": "Florida",
    "capital": "admin",
    "population": 33640,
    "id": 1858077099
  },
  {
    "city": "Port-de-Paix",
    "city_ascii": "Port-de-Paix",
    "lat": 19.9318,
    "lng": -72.8295,
    "country": "Haiti",
    "iso2": "HT",
    "iso3": "HTI",
    "admin_name": "Nord-Ouest",
    "capital": "admin",
    "population": 34657,
    "id": 1332536650
  },
  {
    "city": "Istog",
    "city_ascii": "Istog",
    "lat": 42.7833,
    "lng": 20.4833,
    "country": "Kosovo",
    "iso2": "XK",
    "iso3": "XKS",
    "admin_name": "Istog",
    "capital": "admin",
    "population": 39289,
    "id": 1901248770
  },
  {
    "city": "Miercurea-Ciuc",
    "city_ascii": "Miercurea-Ciuc",
    "lat": 46.3594,
    "lng": 25.8039,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Harghita",
    "capital": "admin",
    "population": 38966,
    "id": 1642603145
  },
  {
    "city": "Mission Bend",
    "city_ascii": "Mission Bend",
    "lat": 29.6948,
    "lng": -95.6657,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 39291,
    "id": 1840019643
  },
  {
    "city": "Ağcabədi",
    "city_ascii": "Agcabadi",
    "lat": 40.0489,
    "lng": 47.4502,
    "country": "Azerbaijan",
    "iso2": "AZ",
    "iso3": "AZE",
    "admin_name": "Ağcabədi",
    "capital": "admin",
    "population": 39200,
    "id": 1031860893
  },
  {
    "city": "Annapolis",
    "city_ascii": "Annapolis",
    "lat": 38.9706,
    "lng": -76.5047,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "admin",
    "population": 39223,
    "id": 1840005928
  },
  {
    "city": "Culver City",
    "city_ascii": "Culver City",
    "lat": 34.0058,
    "lng": -118.3968,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 39185,
    "id": 1840019243
  },
  {
    "city": "Walkden",
    "city_ascii": "Walkden",
    "lat": 53.5239,
    "lng": -2.3991,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Salford",
    "capital": "",
    "population": 35616,
    "id": 1826067450
  },
  {
    "city": "Durazno",
    "city_ascii": "Durazno",
    "lat": -33.3833,
    "lng": -56.5167,
    "country": "Uruguay",
    "iso2": "UY",
    "iso3": "URY",
    "admin_name": "Durazno",
    "capital": "admin",
    "population": 34372,
    "id": 1858006236
  },
  {
    "city": "Houilles",
    "city_ascii": "Houilles",
    "lat": 48.9261,
    "lng": 2.1892,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 32151,
    "id": 1250321161
  },
  {
    "city": "São Francisco do Sul",
    "city_ascii": "Sao Francisco do Sul",
    "lat": -26.2433,
    "lng": -48.6381,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Santa Catarina",
    "capital": "",
    "population": 36224,
    "id": 1076452788
  },
  {
    "city": "Pánuco",
    "city_ascii": "Panuco",
    "lat": 22.05,
    "lng": -98.1833,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Veracruz",
    "capital": "minor",
    "population": 33122,
    "id": 1484059135
  },
  {
    "city": "Creil",
    "city_ascii": "Creil",
    "lat": 49.2583,
    "lng": 2.4833,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Hauts-de-France",
    "capital": "",
    "population": 35657,
    "id": 1250367571
  },
  {
    "city": "Nueva Italia de Ruiz",
    "city_ascii": "Nueva Italia de Ruiz",
    "lat": 19.0194,
    "lng": -102.1089,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Michoacán de Ocampo",
    "capital": "",
    "population": 32467,
    "id": 1484876293
  },
  {
    "city": "Xam Nua",
    "city_ascii": "Xam Nua",
    "lat": 20.4133,
    "lng": 104.048,
    "country": "Laos",
    "iso2": "LA",
    "iso3": "LAO",
    "admin_name": "Houaphan",
    "capital": "admin",
    "population": 38992,
    "id": 1418975079
  },
  {
    "city": "Tromsø",
    "city_ascii": "Tromso",
    "lat": 69.6489,
    "lng": 18.9551,
    "country": "Norway",
    "iso2": "NO",
    "iso3": "NOR",
    "admin_name": "Troms",
    "capital": "admin",
    "population": 38980,
    "id": 1578188822
  },
  {
    "city": "Januária",
    "city_ascii": "Januaria",
    "lat": -15.4886,
    "lng": -44.36,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Minas Gerais",
    "capital": "",
    "population": 34811,
    "id": 1076924780
  },
  {
    "city": "Northolt",
    "city_ascii": "Northolt",
    "lat": 51.547,
    "lng": -0.362,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Ealing",
    "capital": "",
    "population": 30304,
    "id": 1826456422
  },
  {
    "city": "Dédougou",
    "city_ascii": "Dedougou",
    "lat": 12.4667,
    "lng": -3.4667,
    "country": "Burkina Faso",
    "iso2": "BF",
    "iso3": "BFA",
    "admin_name": "Boucle du Mouhoun",
    "capital": "admin",
    "population": 38862,
    "id": 1854743311
  },
  {
    "city": "Frankfort",
    "city_ascii": "Frankfort",
    "lat": 38.1924,
    "lng": -84.8643,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kentucky",
    "capital": "admin",
    "population": 38852,
    "id": 1840013210
  },
  {
    "city": "Brzeg",
    "city_ascii": "Brzeg",
    "lat": 50.8667,
    "lng": 17.4833,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Opolskie",
    "capital": "minor",
    "population": 36541,
    "id": 1616000502
  },
  {
    "city": "Northglenn",
    "city_ascii": "Northglenn",
    "lat": 39.9108,
    "lng": -104.9783,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 38819,
    "id": 1840020192
  },
  {
    "city": "Kavadarci",
    "city_ascii": "Kavadarci",
    "lat": 41.4328,
    "lng": 22.0117,
    "country": "Macedonia",
    "iso2": "MK",
    "iso3": "MKD",
    "admin_name": "Kavadarci",
    "capital": "admin",
    "population": 38741,
    "id": 1807183974
  },
  {
    "city": "Morrinhos",
    "city_ascii": "Morrinhos",
    "lat": -17.7311,
    "lng": -49.0994,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Goiás",
    "capital": "",
    "population": 34143,
    "id": 1076391164
  },
  {
    "city": "Bərdə",
    "city_ascii": "Barda",
    "lat": 40.3831,
    "lng": 47.1186,
    "country": "Azerbaijan",
    "iso2": "AZ",
    "iso3": "AZE",
    "admin_name": "Bərdə",
    "capital": "admin",
    "population": 38500,
    "id": 1031495701
  },
  {
    "city": "Hà Giang",
    "city_ascii": "Ha Giang",
    "lat": 22.8233,
    "lng": 104.9836,
    "country": "Vietnam",
    "iso2": "VN",
    "iso3": "VNM",
    "admin_name": "Hà Giang",
    "capital": "admin",
    "population": 38362,
    "id": 1704495953
  },
  {
    "city": "Tuyên Quang",
    "city_ascii": "Tuyen Quang",
    "lat": 21.8281,
    "lng": 105.2156,
    "country": "Vietnam",
    "iso2": "VN",
    "iso3": "VNM",
    "admin_name": "Tuyên Quang",
    "capital": "admin",
    "population": 36430,
    "id": 1704662313
  },
  {
    "city": "Karonga",
    "city_ascii": "Karonga",
    "lat": -9.9329,
    "lng": 33.9333,
    "country": "Malawi",
    "iso2": "MW",
    "iso3": "MWI",
    "admin_name": "Karonga",
    "capital": "admin",
    "population": 34207,
    "id": 1454358935
  },
  {
    "city": "Fort Lee",
    "city_ascii": "Fort Lee",
    "lat": 40.8509,
    "lng": -73.9712,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 38605,
    "id": 1840003552
  },
  {
    "city": "Highbury",
    "city_ascii": "Highbury",
    "lat": 51.552,
    "lng": -0.097,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Islington",
    "capital": "",
    "population": 26664,
    "id": 1826640859
  },
  {
    "city": "Zinacantán",
    "city_ascii": "Zinacantan",
    "lat": 16.7597,
    "lng": -92.7222,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Chiapas",
    "capital": "minor",
    "population": 36489,
    "id": 1484308293
  },
  {
    "city": "Le Plessis-Robinson",
    "city_ascii": "Le Plessis-Robinson",
    "lat": 48.7811,
    "lng": 2.2633,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 29100,
    "id": 1250572876
  },
  {
    "city": "Ewell",
    "city_ascii": "Ewell",
    "lat": 51.35,
    "lng": -0.249,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Surrey",
    "capital": "",
    "population": 34872,
    "id": 1826318947
  },
  {
    "city": "Tunceli",
    "city_ascii": "Tunceli",
    "lat": 39.1061,
    "lng": 39.5481,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Tunceli",
    "capital": "admin",
    "population": 38504,
    "id": 1792507574
  },
  {
    "city": "Les Lilas",
    "city_ascii": "Les Lilas",
    "lat": 48.88,
    "lng": 2.42,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 23045,
    "id": 1250804499
  },
  {
    "city": "Lake Worth",
    "city_ascii": "Lake Worth",
    "lat": 26.6196,
    "lng": -80.0591,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 38526,
    "id": 1840015127
  },
  {
    "city": "Wisbech",
    "city_ascii": "Wisbech",
    "lat": 52.664,
    "lng": 0.16,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Cambridgeshire",
    "capital": "",
    "population": 33933,
    "id": 1826161684
  },
  {
    "city": "Bossangoa",
    "city_ascii": "Bossangoa",
    "lat": 6.4833,
    "lng": 17.45,
    "country": "Central African Republic",
    "iso2": "CF",
    "iso3": "CAF",
    "admin_name": "Ouham",
    "capital": "admin",
    "population": 37780,
    "id": 1140523604
  },
  {
    "city": "Klinë",
    "city_ascii": "Kline",
    "lat": 42.6217,
    "lng": 20.5778,
    "country": "Kosovo",
    "iso2": "XK",
    "iso3": "XKS",
    "admin_name": "Klinë",
    "capital": "admin",
    "population": 38496,
    "id": 1901230162
  },
  {
    "city": "Bucha",
    "city_ascii": "Bucha",
    "lat": 50.5464,
    "lng": 30.235,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Kyyivs’ka Oblast’",
    "capital": "",
    "population": 35162,
    "id": 1804587935
  },
  {
    "city": "Chorley",
    "city_ascii": "Chorley",
    "lat": 53.653,
    "lng": -2.632,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Lancashire",
    "capital": "",
    "population": 34667,
    "id": 1826477913
  },
  {
    "city": "Zaïo",
    "city_ascii": "Zaio",
    "lat": 34.9396,
    "lng": -2.7334,
    "country": "Morocco",
    "iso2": "MA",
    "iso3": "MAR",
    "admin_name": "Oriental",
    "capital": "",
    "population": 35806,
    "id": 1504831853
  },
  {
    "city": "Jacarèzinho",
    "city_ascii": "Jacarezinho",
    "lat": -23.1606,
    "lng": -49.9694,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Paraná",
    "capital": "",
    "population": 35090,
    "id": 1076955757
  },
  {
    "city": "Zeghanghane",
    "city_ascii": "Zeghanghane",
    "lat": 35.1575,
    "lng": -3.0017,
    "country": "Morocco",
    "iso2": "MA",
    "iso3": "MAR",
    "admin_name": "Oriental",
    "capital": "",
    "population": 34025,
    "id": 1504260154
  },
  {
    "city": "Hanwell",
    "city_ascii": "Hanwell",
    "lat": 51.5189,
    "lng": -0.3376,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Ealing",
    "capital": "",
    "population": 28768,
    "id": 1826742619
  },
  {
    "city": "L’Haÿ-les-Roses",
    "city_ascii": "L'Hay-les-Roses",
    "lat": 48.78,
    "lng": 2.3374,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "minor",
    "population": 31204,
    "id": 1250707197
  },
  {
    "city": "Jiménez",
    "city_ascii": "Jimenez",
    "lat": 27.13,
    "lng": -104.9067,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Chihuahua",
    "capital": "minor",
    "population": 34281,
    "id": 1484452730
  },
  {
    "city": "Tuxpan",
    "city_ascii": "Tuxpan",
    "lat": 21.8667,
    "lng": -105.45,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Nayarit",
    "capital": "minor",
    "population": 34079,
    "id": 1484274950
  },
  {
    "city": "Falkirk",
    "city_ascii": "Falkirk",
    "lat": 56.0011,
    "lng": -3.7835,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Falkirk",
    "capital": "",
    "population": 35168,
    "id": 1826502864
  },
  {
    "city": "Montclair",
    "city_ascii": "Montclair",
    "lat": 40.8254,
    "lng": -74.211,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 38296,
    "id": 1840131597
  },
  {
    "city": "Choybalsan",
    "city_ascii": "Choybalsan",
    "lat": 48.0706,
    "lng": 114.5228,
    "country": "Mongolia",
    "iso2": "MN",
    "iso3": "MNG",
    "admin_name": "Dornod",
    "capital": "admin",
    "population": 38150,
    "id": 1496921469
  },
  {
    "city": "Am-Timan",
    "city_ascii": "Am-Timan",
    "lat": 11.0333,
    "lng": 20.2833,
    "country": "Chad",
    "iso2": "TD",
    "iso3": "TCD",
    "admin_name": "Salamat",
    "capital": "admin",
    "population": 38261,
    "id": 1148823427
  },
  {
    "city": "Tenkodogo",
    "city_ascii": "Tenkodogo",
    "lat": 11.7804,
    "lng": -0.3697,
    "country": "Burkina Faso",
    "iso2": "BF",
    "iso3": "BFA",
    "admin_name": "Centre-Est",
    "capital": "admin",
    "population": 38108,
    "id": 1854646157
  },
  {
    "city": "Massamá",
    "city_ascii": "Massama",
    "lat": 38.7568,
    "lng": -9.2748,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Lisboa",
    "capital": "",
    "population": 28112,
    "id": 1620579552
  },
  {
    "city": "Trujillo",
    "city_ascii": "Trujillo",
    "lat": 9.3667,
    "lng": -70.4331,
    "country": "Venezuela",
    "iso2": "VE",
    "iso3": "VEN",
    "admin_name": "Trujillo",
    "capital": "admin",
    "population": 38110,
    "id": 1862212549
  },
  {
    "city": "Macuspana",
    "city_ascii": "Macuspana",
    "lat": 17.7667,
    "lng": -92.6,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Tabasco",
    "capital": "minor",
    "population": 30661,
    "id": 1484086926
  },
  {
    "city": "Palaiseau",
    "city_ascii": "Palaiseau",
    "lat": 48.7145,
    "lng": 2.2457,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "minor",
    "population": 35514,
    "id": 1250731865
  },
  {
    "city": "Stanton",
    "city_ascii": "Stanton",
    "lat": 33.8002,
    "lng": -117.9935,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 38139,
    "id": 1840021966
  },
  {
    "city": "Redcar",
    "city_ascii": "Redcar",
    "lat": 54.618,
    "lng": -1.069,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Redcar and Cleveland",
    "capital": "",
    "population": 35692,
    "id": 1826929580
  },
  {
    "city": "Kikinda",
    "city_ascii": "Kikinda",
    "lat": 45.8244,
    "lng": 20.4592,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Kikinda",
    "capital": "admin",
    "population": 38065,
    "id": 1688358284
  },
  {
    "city": "Athis-Mons",
    "city_ascii": "Athis-Mons",
    "lat": 48.7074,
    "lng": 2.3889,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 34347,
    "id": 1250955386
  },
  {
    "city": "Flores",
    "city_ascii": "Flores",
    "lat": 16.9337,
    "lng": -89.8833,
    "country": "Guatemala",
    "iso2": "GT",
    "iso3": "GTM",
    "admin_name": "Petén",
    "capital": "admin",
    "population": 38035,
    "id": 1320651129
  },
  {
    "city": "Paphos",
    "city_ascii": "Paphos",
    "lat": 34.7761,
    "lng": 32.4265,
    "country": "Cyprus",
    "iso2": "CY",
    "iso3": "CYP",
    "admin_name": "Páfos",
    "capital": "admin",
    "population": 35961,
    "id": 1196607408
  },
  {
    "city": "Ali Sabieh",
    "city_ascii": "Ali Sabieh",
    "lat": 11.1558,
    "lng": 42.7125,
    "country": "Djibouti",
    "iso2": "DJ",
    "iso3": "DJI",
    "admin_name": "Ali Sabieh",
    "capital": "admin",
    "population": 37939,
    "id": 1262104228
  },
  {
    "city": "Adjumani",
    "city_ascii": "Adjumani",
    "lat": 3.3614,
    "lng": 31.8097,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Adjumani",
    "capital": "admin",
    "population": 34700,
    "id": 1800855823
  },
  {
    "city": "Richmond West",
    "city_ascii": "Richmond West",
    "lat": 25.6105,
    "lng": -80.4297,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 37965,
    "id": 1840073270
  },
  {
    "city": "Spalding",
    "city_ascii": "Spalding",
    "lat": 52.7858,
    "lng": -0.1529,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Lincolnshire",
    "capital": "",
    "population": 34613,
    "id": 1826335951
  },
  {
    "city": "South Miami Heights",
    "city_ascii": "South Miami Heights",
    "lat": 25.5886,
    "lng": -80.3862,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 37861,
    "id": 1840014240
  },
  {
    "city": "Dori",
    "city_ascii": "Dori",
    "lat": 14.05,
    "lng": 0.05,
    "country": "Burkina Faso",
    "iso2": "BF",
    "iso3": "BFA",
    "admin_name": "Sahel",
    "capital": "admin",
    "population": 37806,
    "id": 1854096946
  },
  {
    "city": "Capitão Poço",
    "city_ascii": "Capitao Poco",
    "lat": -1.7464,
    "lng": -47.0594,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Pará",
    "capital": "",
    "population": 32704,
    "id": 1076870027
  },
  {
    "city": "Esch-sur-Alzette",
    "city_ascii": "Esch-sur-Alzette",
    "lat": 49.4969,
    "lng": 5.9806,
    "country": "Luxembourg",
    "iso2": "LU",
    "iso3": "LUX",
    "admin_name": "Esch-sur-Alzette",
    "capital": "admin",
    "population": 36218,
    "id": 1442192663
  },
  {
    "city": "Escuinapa",
    "city_ascii": "Escuinapa",
    "lat": 22.9822,
    "lng": -105.7031,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Sinaloa",
    "capital": "minor",
    "population": 30790,
    "id": 1484295544
  },
  {
    "city": "Kajaani",
    "city_ascii": "Kajaani",
    "lat": 64.225,
    "lng": 27.7333,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Kainuu",
    "capital": "admin",
    "population": 37622,
    "id": 1246377765
  },
  {
    "city": "Salto del Guairá",
    "city_ascii": "Salto del Guaira",
    "lat": -24.02,
    "lng": -54.34,
    "country": "Paraguay",
    "iso2": "PY",
    "iso3": "PRY",
    "admin_name": "Canindeyú",
    "capital": "admin",
    "population": 37600,
    "id": 1600283545
  },
  {
    "city": "Pierrefitte-sur-Seine",
    "city_ascii": "Pierrefitte-sur-Seine",
    "lat": 48.9656,
    "lng": 2.3614,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 30306,
    "id": 1250320498
  },
  {
    "city": "San Vicente",
    "city_ascii": "San Vicente",
    "lat": 13.641,
    "lng": -88.785,
    "country": "El Salvador",
    "iso2": "SV",
    "iso3": "SLV",
    "admin_name": "San Vicente",
    "capital": "admin",
    "population": 37326,
    "id": 1222913273
  },
  {
    "city": "Schaffhausen",
    "city_ascii": "Schaffhausen",
    "lat": 47.6965,
    "lng": 8.6339,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Schaffhausen",
    "capital": "admin",
    "population": 36587,
    "id": 1756849233
  },
  {
    "city": "La Presa",
    "city_ascii": "La Presa",
    "lat": 32.711,
    "lng": -117.0027,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 37437,
    "id": 1840018023
  },
  {
    "city": "Valley Stream",
    "city_ascii": "Valley Stream",
    "lat": 40.6647,
    "lng": -73.7044,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 37431,
    "id": 1840005331
  },
  {
    "city": "Hanover Park",
    "city_ascii": "Hanover Park",
    "lat": 41.9819,
    "lng": -88.1447,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 37426,
    "id": 1840011251
  },
  {
    "city": "Ojuelos de Jalisco",
    "city_ascii": "Ojuelos de Jalisco",
    "lat": 21.8642,
    "lng": -101.5933,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Jalisco",
    "capital": "minor",
    "population": 32357,
    "id": 1484896796
  },
  {
    "city": "Hitchin",
    "city_ascii": "Hitchin",
    "lat": 51.947,
    "lng": -0.283,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hertfordshire",
    "capital": "",
    "population": 33352,
    "id": 1826093917
  },
  {
    "city": "East Meadow",
    "city_ascii": "East Meadow",
    "lat": 40.7197,
    "lng": -73.5604,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 37358,
    "id": 1840005236
  },
  {
    "city": "‘Ataq",
    "city_ascii": "`Ataq",
    "lat": 14.55,
    "lng": 46.8,
    "country": "Yemen",
    "iso2": "YE",
    "iso3": "YEM",
    "admin_name": "Shabwah",
    "capital": "admin",
    "population": 37315,
    "id": 1887172893
  },
  {
    "city": "Villeneuve-Saint-Georges",
    "city_ascii": "Villeneuve-Saint-Georges",
    "lat": 48.7325,
    "lng": 2.4497,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 33545,
    "id": 1250985976
  },
  {
    "city": "Châtenay-Malabry",
    "city_ascii": "Chatenay-Malabry",
    "lat": 48.7653,
    "lng": 2.2781,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 33286,
    "id": 1250512323
  },
  {
    "city": "Strood",
    "city_ascii": "Strood",
    "lat": 51.393,
    "lng": 0.478,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Medway",
    "capital": "",
    "population": 33182,
    "id": 1826719630
  },
  {
    "city": "Clichy-sous-Bois",
    "city_ascii": "Clichy-sous-Bois",
    "lat": 48.9102,
    "lng": 2.5532,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 29348,
    "id": 1250123655
  },
  {
    "city": "Oranjestad",
    "city_ascii": "Oranjestad",
    "lat": 12.5186,
    "lng": -70.0358,
    "country": "Aruba",
    "iso2": "AW",
    "iso3": "ABW",
    "admin_name": "",
    "capital": "",
    "population": 34980,
    "id": 1533513057
  },
  {
    "city": "Prijepolje",
    "city_ascii": "Prijepolje",
    "lat": 43.5439,
    "lng": 19.6514,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Prijepolje",
    "capital": "admin",
    "population": 37059,
    "id": 1688287067
  },
  {
    "city": "Bocaiúva",
    "city_ascii": "Bocaiuva",
    "lat": -17.1078,
    "lng": -43.815,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Minas Gerais",
    "capital": "",
    "population": 32114,
    "id": 1076639320
  },
  {
    "city": "Al Qunayţirah",
    "city_ascii": "Al Qunaytirah",
    "lat": 33.1256,
    "lng": 35.8239,
    "country": "Syria",
    "iso2": "SY",
    "iso3": "SYR",
    "admin_name": "Al Qunayţirah",
    "capital": "admin",
    "population": 37022,
    "id": 1760132692
  },
  {
    "city": "Chaiyaphum",
    "city_ascii": "Chaiyaphum",
    "lat": 15.8056,
    "lng": 102.0311,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Chaiyaphum",
    "capital": "admin",
    "population": 36923,
    "id": 1764784757
  },
  {
    "city": "Villemomble",
    "city_ascii": "Villemomble",
    "lat": 48.8833,
    "lng": 2.5,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 29964,
    "id": 1250831662
  },
  {
    "city": "Egypt Lake-Leto",
    "city_ascii": "Egypt Lake-Leto",
    "lat": 28.0177,
    "lng": -82.5062,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 37039,
    "id": 1840073269
  },
  {
    "city": "Swords",
    "city_ascii": "Swords",
    "lat": 53.4597,
    "lng": -6.2181,
    "country": "Ireland",
    "iso2": "IE",
    "iso3": "IRL",
    "admin_name": "Fingal",
    "capital": "admin",
    "population": 33998,
    "id": 1372755688
  },
  {
    "city": "Aventura",
    "city_ascii": "Aventura",
    "lat": 25.9565,
    "lng": -80.1372,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 36987,
    "id": 1840014243
  },
  {
    "city": "Nishihara",
    "city_ascii": "Nishihara",
    "lat": 26.2261,
    "lng": 127.7656,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Okinawa",
    "capital": "",
    "population": 34741,
    "id": 1392591354
  },
  {
    "city": "Osório",
    "city_ascii": "Osorio",
    "lat": -29.8867,
    "lng": -50.2697,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Rio Grande do Sul",
    "capital": "",
    "population": 34628,
    "id": 1076367566
  },
  {
    "city": "Park Ridge",
    "city_ascii": "Park Ridge",
    "lat": 42.0125,
    "lng": -87.8436,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 36950,
    "id": 1840009183
  },
  {
    "city": "Tenosique",
    "city_ascii": "Tenosique",
    "lat": 17.4756,
    "lng": -91.4225,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Tabasco",
    "capital": "minor",
    "population": 31392,
    "id": 1484303350
  },
  {
    "city": "Thonon-les-Bains",
    "city_ascii": "Thonon-les-Bains",
    "lat": 46.3627,
    "lng": 6.475,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Auvergne-Rhône-Alpes",
    "capital": "minor",
    "population": 34756,
    "id": 1250907646
  },
  {
    "city": "Saint-Mandé",
    "city_ascii": "Saint-Mande",
    "lat": 48.8422,
    "lng": 2.4186,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 22619,
    "id": 1250000869
  },
  {
    "city": "Falun",
    "city_ascii": "Falun",
    "lat": 60.613,
    "lng": 15.647,
    "country": "Sweden",
    "iso2": "SE",
    "iso3": "SWE",
    "admin_name": "Dalarna",
    "capital": "admin",
    "population": 36477,
    "id": 1752939220
  },
  {
    "city": "Letchworth",
    "city_ascii": "Letchworth",
    "lat": 51.978,
    "lng": -0.23,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hertfordshire",
    "capital": "",
    "population": 33249,
    "id": 1826971219
  },
  {
    "city": "Agen",
    "city_ascii": "Agen",
    "lat": 44.2049,
    "lng": 0.6212,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Nouvelle-Aquitaine",
    "capital": "minor",
    "population": 33576,
    "id": 1250059626
  },
  {
    "city": "Santa Isabel",
    "city_ascii": "Santa Isabel",
    "lat": 32.6314,
    "lng": -115.5756,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Baja California",
    "capital": "",
    "population": 33604,
    "id": 1484050315
  },
  {
    "city": "Ratchaburi",
    "city_ascii": "Ratchaburi",
    "lat": 13.5367,
    "lng": 99.8169,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Ratchaburi",
    "capital": "admin",
    "population": 36339,
    "id": 1764773366
  },
  {
    "city": "Wigston Magna",
    "city_ascii": "Wigston Magna",
    "lat": 52.5812,
    "lng": -1.093,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Leicestershire",
    "capital": "",
    "population": 32321,
    "id": 1826846225
  },
  {
    "city": "Saint Helier",
    "city_ascii": "Saint Helier",
    "lat": 49.1833,
    "lng": -2.1167,
    "country": "Jersey",
    "iso2": "JE",
    "iso3": "JEY",
    "admin_name": "",
    "capital": "",
    "population": 33522,
    "id": 1832490253
  },
  {
    "city": "Marijampolė",
    "city_ascii": "Marijampole",
    "lat": 54.5567,
    "lng": 23.3544,
    "country": "Lithuania",
    "iso2": "LT",
    "iso3": "LTU",
    "admin_name": "Marijampolė",
    "capital": "admin",
    "population": 36602,
    "id": 1440180524
  },
  {
    "city": "Roanne",
    "city_ascii": "Roanne",
    "lat": 46.0367,
    "lng": 4.0689,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Auvergne-Rhône-Alpes",
    "capital": "minor",
    "population": 34366,
    "id": 1250674978
  },
  {
    "city": "Pinner",
    "city_ascii": "Pinner",
    "lat": 51.5932,
    "lng": -0.3894,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Harrow",
    "capital": "",
    "population": 31130,
    "id": 1826466478
  },
  {
    "city": "Bezons",
    "city_ascii": "Bezons",
    "lat": 48.9261,
    "lng": 2.2178,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 29383,
    "id": 1250139521
  },
  {
    "city": "Karlskrona",
    "city_ascii": "Karlskrona",
    "lat": 56.203,
    "lng": 15.296,
    "country": "Sweden",
    "iso2": "SE",
    "iso3": "SWE",
    "admin_name": "Blekinge",
    "capital": "admin",
    "population": 35212,
    "id": 1752316086
  },
  {
    "city": "Ticul",
    "city_ascii": "Ticul",
    "lat": 20.3953,
    "lng": -89.5339,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Yucatán",
    "capital": "",
    "population": 32796,
    "id": 1484056090
  },
  {
    "city": "Calpulalpan",
    "city_ascii": "Calpulalpan",
    "lat": 19.5869,
    "lng": -98.5683,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Hidalgo",
    "capital": "minor",
    "population": 33263,
    "id": 1484814346
  },
  {
    "city": "Ebebiyín",
    "city_ascii": "Ebebiyin",
    "lat": 2.15,
    "lng": 11.3167,
    "country": "Equatorial Guinea",
    "iso2": "GQ",
    "iso3": "GNQ",
    "admin_name": "Kié-Ntem",
    "capital": "admin",
    "population": 36565,
    "id": 1226449195
  },
  {
    "city": "Trowbridge",
    "city_ascii": "Trowbridge",
    "lat": 51.32,
    "lng": -2.21,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Wiltshire",
    "capital": "",
    "population": 33108,
    "id": 1826965204
  },
  {
    "city": "Czeladź",
    "city_ascii": "Czeladz",
    "lat": 50.3333,
    "lng": 19.0833,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Śląskie",
    "capital": "",
    "population": 34402,
    "id": 1616073267
  },
  {
    "city": "Rayleigh",
    "city_ascii": "Rayleigh",
    "lat": 51.5864,
    "lng": 0.6049,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Essex",
    "capital": "",
    "population": 32150,
    "id": 1826491250
  },
  {
    "city": "West Hollywood",
    "city_ascii": "West Hollywood",
    "lat": 34.0882,
    "lng": -118.3718,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 36475,
    "id": 1840028098
  },
  {
    "city": "Earley",
    "city_ascii": "Earley",
    "lat": 51.433,
    "lng": -0.933,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Wokingham",
    "capital": "",
    "population": 32036,
    "id": 1826312620
  },
  {
    "city": "Viry-Châtillon",
    "city_ascii": "Viry-Chatillon",
    "lat": 48.6713,
    "lng": 2.375,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 31093,
    "id": 1250498232
  },
  {
    "city": "Paso de Ovejas",
    "city_ascii": "Paso de Ovejas",
    "lat": 19.285,
    "lng": -96.44,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Veracruz",
    "capital": "minor",
    "population": 33392,
    "id": 1484005936
  },
  {
    "city": "Bicester",
    "city_ascii": "Bicester",
    "lat": 51.9,
    "lng": -1.15,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Oxfordshire",
    "capital": "",
    "population": 32642,
    "id": 1826236942
  },
  {
    "city": "Abasolo",
    "city_ascii": "Abasolo",
    "lat": 20.4511,
    "lng": -101.5289,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Guanajuato",
    "capital": "minor",
    "population": 27389,
    "id": 1484064616
  },
  {
    "city": "Mörön",
    "city_ascii": "Moron",
    "lat": 49.6375,
    "lng": 100.1614,
    "country": "Mongolia",
    "iso2": "MN",
    "iso3": "MNG",
    "admin_name": "Hövsgöl",
    "capital": "admin",
    "population": 36082,
    "id": 1496482400
  },
  {
    "city": "Gisborne",
    "city_ascii": "Gisborne",
    "lat": -38.6625,
    "lng": 178.0178,
    "country": "New Zealand",
    "iso2": "NZ",
    "iso3": "NZL",
    "admin_name": "Gisborne",
    "capital": "admin",
    "population": 35500,
    "id": 1554359840
  },
  {
    "city": "Goba",
    "city_ascii": "Goba",
    "lat": 7.01,
    "lng": 39.97,
    "country": "Ethiopia",
    "iso2": "ET",
    "iso3": "ETH",
    "admin_name": "Oromīya",
    "capital": "",
    "population": 34369,
    "id": 1231837780
  },
  {
    "city": "Schiltigheim",
    "city_ascii": "Schiltigheim",
    "lat": 48.6078,
    "lng": 7.75,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Grand Est",
    "capital": "",
    "population": 31894,
    "id": 1250916117
  },
  {
    "city": "Göyçay",
    "city_ascii": "Goycay",
    "lat": 40.6553,
    "lng": 47.7389,
    "country": "Azerbaijan",
    "iso2": "AZ",
    "iso3": "AZE",
    "admin_name": "Göyçay",
    "capital": "admin",
    "population": 36200,
    "id": 1031227222
  },
  {
    "city": "Richfield",
    "city_ascii": "Richfield",
    "lat": 44.8762,
    "lng": -93.2833,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 36354,
    "id": 1840008930
  },
  {
    "city": "Rutherglen",
    "city_ascii": "Rutherglen",
    "lat": 55.8286,
    "lng": -4.2139,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Glasgow City",
    "capital": "",
    "population": 31120,
    "id": 1826453586
  },
  {
    "city": "Campo Maior",
    "city_ascii": "Campo Maior",
    "lat": -4.8278,
    "lng": -42.1686,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Piauí",
    "capital": "",
    "population": 31739,
    "id": 1076002814
  },
  {
    "city": "Kearns",
    "city_ascii": "Kearns",
    "lat": 40.652,
    "lng": -112.0093,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Utah",
    "capital": "",
    "population": 36330,
    "id": 1840017500
  },
  {
    "city": "Lincoln Park",
    "city_ascii": "Lincoln Park",
    "lat": 42.2432,
    "lng": -83.1811,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 36321,
    "id": 1840003984
  },
  {
    "city": "Chur",
    "city_ascii": "Chur",
    "lat": 46.8521,
    "lng": 9.5297,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Graubünden",
    "capital": "admin",
    "population": 35038,
    "id": 1756471395
  },
  {
    "city": "Minamishiro",
    "city_ascii": "Minamishiro",
    "lat": 36.0225,
    "lng": 139.7228,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Saitama",
    "capital": "",
    "population": 34133,
    "id": 1392934140
  },
  {
    "city": "Ryde",
    "city_ascii": "Ryde",
    "lat": 50.7271,
    "lng": -1.1618,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Isle of Wight",
    "capital": "",
    "population": 32072,
    "id": 1826600816
  },
  {
    "city": "Amecameca de Juárez",
    "city_ascii": "Amecameca de Juarez",
    "lat": 19.1238,
    "lng": -98.7665,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "México",
    "capital": "minor",
    "population": 31687,
    "id": 1484353804
  },
  {
    "city": "Zacapa",
    "city_ascii": "Zacapa",
    "lat": 14.972,
    "lng": -89.529,
    "country": "Guatemala",
    "iso2": "GT",
    "iso3": "GTM",
    "admin_name": "Zacapa",
    "capital": "admin",
    "population": 36088,
    "id": 1320977472
  },
  {
    "city": "Lichfield",
    "city_ascii": "Lichfield",
    "lat": 52.6835,
    "lng": -1.8265,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Staffordshire",
    "capital": "",
    "population": 33816,
    "id": 1826803495
  },
  {
    "city": "Ecclesfield",
    "city_ascii": "Ecclesfield",
    "lat": 53.4429,
    "lng": -1.4698,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Sheffield",
    "capital": "",
    "population": 32073,
    "id": 1826382276
  },
  {
    "city": "Ometepec",
    "city_ascii": "Ometepec",
    "lat": 16.6833,
    "lng": -98.4167,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Guerrero",
    "capital": "minor",
    "population": 30271,
    "id": 1484936909
  },
  {
    "city": "Lauderdale Lakes",
    "city_ascii": "Lauderdale Lakes",
    "lat": 26.1682,
    "lng": -80.2017,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 36194,
    "id": 1840015145
  },
  {
    "city": "Montigny-le-Bretonneux",
    "city_ascii": "Montigny-le-Bretonneux",
    "lat": 48.7711,
    "lng": 2.0333,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 32929,
    "id": 1250533450
  },
  {
    "city": "Chatou",
    "city_ascii": "Chatou",
    "lat": 48.8897,
    "lng": 2.1573,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 30253,
    "id": 1250855196
  },
  {
    "city": "Pontypridd",
    "city_ascii": "Pontypridd",
    "lat": 51.602,
    "lng": -3.342,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Rhondda Cynon Taff",
    "capital": "",
    "population": 32694,
    "id": 1826842541
  },
  {
    "city": "Krong Kep",
    "city_ascii": "Krong Kep",
    "lat": 10.4828,
    "lng": 104.3167,
    "country": "Cambodia",
    "iso2": "KH",
    "iso3": "KHM",
    "admin_name": "Kep",
    "capital": "admin",
    "population": 35990,
    "id": 1116392544
  },
  {
    "city": "Kornwestheim",
    "city_ascii": "Kornwestheim",
    "lat": 48.8597,
    "lng": 9.185,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 33803,
    "id": 1276918130
  },
  {
    "city": "Prestwich",
    "city_ascii": "Prestwich",
    "lat": 53.5333,
    "lng": -2.2833,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Bury",
    "capital": "",
    "population": 31693,
    "id": 1826921530
  },
  {
    "city": "Kamenicë",
    "city_ascii": "Kamenice",
    "lat": 42.5839,
    "lng": 21.575,
    "country": "Kosovo",
    "iso2": "XK",
    "iso3": "XKS",
    "admin_name": "Kamenicë",
    "capital": "admin",
    "population": 36085,
    "id": 1901851592
  },
  {
    "city": "Viborg",
    "city_ascii": "Viborg",
    "lat": 56.4333,
    "lng": 9.4,
    "country": "Denmark",
    "iso2": "DK",
    "iso3": "DNK",
    "admin_name": "Midtjylland",
    "capital": "admin",
    "population": 34831,
    "id": 1208606810
  },
  {
    "city": "Belleville",
    "city_ascii": "Belleville",
    "lat": 40.795,
    "lng": -74.1616,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 36069,
    "id": 1840131591
  },
  {
    "city": "Gostivar",
    "city_ascii": "Gostivar",
    "lat": 41.8,
    "lng": 20.9167,
    "country": "Macedonia",
    "iso2": "MK",
    "iso3": "MKD",
    "admin_name": "Gostivar",
    "capital": "admin",
    "population": 35847,
    "id": 1807901060
  },
  {
    "city": "Elmont",
    "city_ascii": "Elmont",
    "lat": 40.7033,
    "lng": -73.7078,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 36028,
    "id": 1840005226
  },
  {
    "city": "Ventspils",
    "city_ascii": "Ventspils",
    "lat": 57.3897,
    "lng": 21.5644,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Ventspils",
    "capital": "admin",
    "population": 35362,
    "id": 1428112098
  },
  {
    "city": "Watertown Town",
    "city_ascii": "Watertown Town",
    "lat": 42.37,
    "lng": -71.1774,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 35939,
    "id": 1840031198
  },
  {
    "city": "Beja",
    "city_ascii": "Beja",
    "lat": 38.0333,
    "lng": -7.8833,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Beja",
    "capital": "admin",
    "population": 35854,
    "id": 1620050458
  },
  {
    "city": "Vršac",
    "city_ascii": "Vrsac",
    "lat": 45.1206,
    "lng": 21.2986,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Vršac",
    "capital": "admin",
    "population": 35701,
    "id": 1688560040
  },
  {
    "city": "Paranaíba",
    "city_ascii": "Paranaiba",
    "lat": -19.6772,
    "lng": -51.1908,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Mato Grosso do Sul",
    "capital": "",
    "population": 32217,
    "id": 1076355277
  },
  {
    "city": "Brighouse",
    "city_ascii": "Brighouse",
    "lat": 53.707,
    "lng": -1.794,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Calderdale",
    "capital": "",
    "population": 32360,
    "id": 1826688359
  },
  {
    "city": "Temple City",
    "city_ascii": "Temple City",
    "lat": 34.1021,
    "lng": -118.0579,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 35811,
    "id": 1840021872
  },
  {
    "city": "Farnworth",
    "city_ascii": "Farnworth",
    "lat": 53.5452,
    "lng": -2.3999,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Bolton",
    "capital": "",
    "population": 30271,
    "id": 1826257074
  },
  {
    "city": "Chillum",
    "city_ascii": "Chillum",
    "lat": 38.9667,
    "lng": -76.979,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 35798,
    "id": 1840005948
  },
  {
    "city": "Ermont",
    "city_ascii": "Ermont",
    "lat": 48.9922,
    "lng": 2.2603,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 29108,
    "id": 1250549360
  },
  {
    "city": "La Asunción",
    "city_ascii": "La Asuncion",
    "lat": 11.0333,
    "lng": -63.8833,
    "country": "Venezuela",
    "iso2": "VE",
    "iso3": "VEN",
    "admin_name": "Nueva Esparta",
    "capital": "admin",
    "population": 35084,
    "id": 1862490314
  },
  {
    "city": "Cradock",
    "city_ascii": "Cradock",
    "lat": -32.1833,
    "lng": 25.6167,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "Eastern Cape",
    "capital": "",
    "population": 32898,
    "id": 1710933404
  },
  {
    "city": "Stroud",
    "city_ascii": "Stroud",
    "lat": 51.744,
    "lng": -2.215,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Gloucestershire",
    "capital": "",
    "population": 32670,
    "id": 1826302127
  },
  {
    "city": "Darwen",
    "city_ascii": "Darwen",
    "lat": 53.698,
    "lng": -2.461,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Blackburn with Darwen",
    "capital": "",
    "population": 31570,
    "id": 1826810811
  },
  {
    "city": "Chuhuiv",
    "city_ascii": "Chuhuiv",
    "lat": 49.8353,
    "lng": 36.6756,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Kharkivs’ka Oblast’",
    "capital": "minor",
    "population": 33144,
    "id": 1804354882
  },
  {
    "city": "Ixtapan de la Sal",
    "city_ascii": "Ixtapan de la Sal",
    "lat": 18.8333,
    "lng": -99.6833,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "México",
    "capital": "minor",
    "population": 30073,
    "id": 1484821151
  },
  {
    "city": "Trappes",
    "city_ascii": "Trappes",
    "lat": 48.7775,
    "lng": 2.0025,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 32584,
    "id": 1250762117
  },
  {
    "city": "Dover",
    "city_ascii": "Dover",
    "lat": 51.1295,
    "lng": 1.3089,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Kent",
    "capital": "",
    "population": 31022,
    "id": 1826963129
  },
  {
    "city": "Timbuktu",
    "city_ascii": "Timbuktu",
    "lat": 16.7733,
    "lng": -2.9994,
    "country": "Mali",
    "iso2": "ML",
    "iso3": "MLI",
    "admin_name": "Tombouctou",
    "capital": "admin",
    "population": 35330,
    "id": 1466595315
  },
  {
    "city": "Bexleyheath",
    "city_ascii": "Bexleyheath",
    "lat": 51.459,
    "lng": 0.138,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Bexley",
    "capital": "",
    "population": 31929,
    "id": 1826858601
  },
  {
    "city": "Byumba",
    "city_ascii": "Byumba",
    "lat": -1.5794,
    "lng": 30.0694,
    "country": "Rwanda",
    "iso2": "RW",
    "iso3": "RWA",
    "admin_name": "Northern Province",
    "capital": "admin",
    "population": 34544,
    "id": 1646868980
  },
  {
    "city": "Bell",
    "city_ascii": "Bell",
    "lat": 33.9801,
    "lng": -118.1798,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 35521,
    "id": 1840019228
  },
  {
    "city": "Taibao",
    "city_ascii": "Taibao",
    "lat": 23.45,
    "lng": 120.3333,
    "country": "Taiwan",
    "iso2": "TW",
    "iso3": "TWN",
    "admin_name": "Chiayi",
    "capital": "admin",
    "population": 35000,
    "id": 1158657404
  },
  {
    "city": "Roi Et",
    "city_ascii": "Roi Et",
    "lat": 16.0578,
    "lng": 103.6514,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Roi Et",
    "capital": "admin",
    "population": 35219,
    "id": 1764216668
  },
  {
    "city": "Villanueva",
    "city_ascii": "Villanueva",
    "lat": 22.3536,
    "lng": -102.8831,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Zacatecas",
    "capital": "minor",
    "population": 32835,
    "id": 1484244113
  },
  {
    "city": "Talas",
    "city_ascii": "Talas",
    "lat": 42.5184,
    "lng": 72.2429,
    "country": "Kyrgyzstan",
    "iso2": "KG",
    "iso3": "KGZ",
    "admin_name": "Talas",
    "capital": "admin",
    "population": 35172,
    "id": 1417698287
  },
  {
    "city": "Champotón",
    "city_ascii": "Champoton",
    "lat": 19.35,
    "lng": -90.7167,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Campeche",
    "capital": "minor",
    "population": 30881,
    "id": 1484959684
  },
  {
    "city": "Nelson",
    "city_ascii": "Nelson",
    "lat": 53.8346,
    "lng": -2.218,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Lancashire",
    "capital": "",
    "population": 29135,
    "id": 1826818117
  },
  {
    "city": "Bragança",
    "city_ascii": "Braganca",
    "lat": 41.8,
    "lng": -6.75,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Bragança",
    "capital": "admin",
    "population": 35341,
    "id": 1620281131
  },
  {
    "city": "Phatthalung",
    "city_ascii": "Phatthalung",
    "lat": 7.6178,
    "lng": 100.0778,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Phatthalung",
    "capital": "admin",
    "population": 35039,
    "id": 1764240863
  },
  {
    "city": "Les Mureaux",
    "city_ascii": "Les Mureaux",
    "lat": 48.9875,
    "lng": 1.9172,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 32792,
    "id": 1250306722
  },
  {
    "city": "Neuchâtel",
    "city_ascii": "Neuchatel",
    "lat": 46.9903,
    "lng": 6.9306,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Neuchâtel",
    "capital": "admin",
    "population": 33475,
    "id": 1756726571
  },
  {
    "city": "Romainville",
    "city_ascii": "Romainville",
    "lat": 48.884,
    "lng": 2.435,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 27567,
    "id": 1250311855
  },
  {
    "city": "Huyton",
    "city_ascii": "Huyton",
    "lat": 53.41,
    "lng": -2.843,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Knowsley",
    "capital": "",
    "population": 33193,
    "id": 1826682901
  },
  {
    "city": "Santa Cruz del Quiché",
    "city_ascii": "Santa Cruz del Quiche",
    "lat": 15.05,
    "lng": -91.25,
    "country": "Guatemala",
    "iso2": "GT",
    "iso3": "GTM",
    "admin_name": "Quiché",
    "capital": "admin",
    "population": 35000,
    "id": 1320642159
  },
  {
    "city": "Luebo",
    "city_ascii": "Luebo",
    "lat": -5.3495,
    "lng": 21.41,
    "country": "Congo (Kinshasa)",
    "iso2": "CD",
    "iso3": "COD",
    "admin_name": "Kasaï",
    "capital": "admin",
    "population": 35183,
    "id": 1180012250
  },
  {
    "city": "Bria",
    "city_ascii": "Bria",
    "lat": 6.5369,
    "lng": 21.9919,
    "country": "Central African Republic",
    "iso2": "CF",
    "iso3": "CAF",
    "admin_name": "Haute-Kotto",
    "capital": "admin",
    "population": 35204,
    "id": 1140582053
  },
  {
    "city": "Néa Filadélfeia",
    "city_ascii": "Nea Filadelfeia",
    "lat": 38.035,
    "lng": 23.7381,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Attikí",
    "capital": "minor",
    "population": 25734,
    "id": 1300810075
  },
  {
    "city": "Manhattan Beach",
    "city_ascii": "Manhattan Beach",
    "lat": 33.8895,
    "lng": -118.3972,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 35183,
    "id": 1840020496
  },
  {
    "city": "Coatepec Harinas",
    "city_ascii": "Coatepec Harinas",
    "lat": 18.9,
    "lng": -99.7167,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "México",
    "capital": "minor",
    "population": 31860,
    "id": 1484641903
  },
  {
    "city": "Fresnes",
    "city_ascii": "Fresnes",
    "lat": 48.755,
    "lng": 2.3221,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 27802,
    "id": 1250376205
  },
  {
    "city": "Artvin",
    "city_ascii": "Artvin",
    "lat": 41.1822,
    "lng": 41.8194,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Artvin",
    "capital": "admin",
    "population": 35081,
    "id": 1792320984
  },
  {
    "city": "Maīdān Shahr",
    "city_ascii": "Maidan Shahr",
    "lat": 34.3972,
    "lng": 68.8697,
    "country": "Afghanistan",
    "iso2": "AF",
    "iso3": "AFG",
    "admin_name": "Wardak",
    "capital": "admin",
    "population": 35008,
    "id": 1004798735
  },
  {
    "city": "Nevers",
    "city_ascii": "Nevers",
    "lat": 46.9933,
    "lng": 3.1572,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Bourgogne-Franche-Comté",
    "capital": "minor",
    "population": 32990,
    "id": 1250147982
  },
  {
    "city": "Villiers-sur-Marne",
    "city_ascii": "Villiers-sur-Marne",
    "lat": 48.8275,
    "lng": 2.5447,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 28456,
    "id": 1250966526
  },
  {
    "city": "Catemaco",
    "city_ascii": "Catemaco",
    "lat": 18.4167,
    "lng": -95.1167,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Veracruz",
    "capital": "minor",
    "population": 27615,
    "id": 1484469922
  },
  {
    "city": "Pabellón de Arteaga",
    "city_ascii": "Pabellon de Arteaga",
    "lat": 22.15,
    "lng": -102.2667,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Aguascalientes",
    "capital": "minor",
    "population": 28633,
    "id": 1484799033
  },
  {
    "city": "San Pedro de Ycuamandiyú",
    "city_ascii": "San Pedro de Ycuamandiyu",
    "lat": -24.1,
    "lng": -57.0833,
    "country": "Paraguay",
    "iso2": "PY",
    "iso3": "PRY",
    "admin_name": "San Pedro",
    "capital": "admin",
    "population": 35021,
    "id": 1600189153
  },
  {
    "city": "Dikhil",
    "city_ascii": "Dikhil",
    "lat": 11.1086,
    "lng": 42.3667,
    "country": "Djibouti",
    "iso2": "DJ",
    "iso3": "DJI",
    "admin_name": "Dikhil",
    "capital": "admin",
    "population": 35000,
    "id": 1262950337
  },
  {
    "city": "Ocotal",
    "city_ascii": "Ocotal",
    "lat": 13.6333,
    "lng": -86.4833,
    "country": "Nicaragua",
    "iso2": "NI",
    "iso3": "NIC",
    "admin_name": "Nueva Segovia",
    "capital": "admin",
    "population": 34580,
    "id": 1558006000
  },
  {
    "city": "Pontoise",
    "city_ascii": "Pontoise",
    "lat": 49.0516,
    "lng": 2.1017,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 30767,
    "id": 1250247527
  },
  {
    "city": "Grigny",
    "city_ascii": "Grigny",
    "lat": 48.6562,
    "lng": 2.3849,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 28737,
    "id": 1250191688
  },
  {
    "city": "Englewood",
    "city_ascii": "Englewood",
    "lat": 39.6468,
    "lng": -104.9942,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 34917,
    "id": 1840020211
  },
  {
    "city": "Kampong Thom",
    "city_ascii": "Kampong Thom",
    "lat": 12.7111,
    "lng": 104.8886,
    "country": "Cambodia",
    "iso2": "KH",
    "iso3": "KHM",
    "admin_name": "Kampong Thom",
    "capital": "admin",
    "population": 31871,
    "id": 1116471297
  },
  {
    "city": "Tucano",
    "city_ascii": "Tucano",
    "lat": -10.9582,
    "lng": -38.7908,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Bahia",
    "capital": "",
    "population": 31203,
    "id": 1076227914
  },
  {
    "city": "Golden Glades",
    "city_ascii": "Golden Glades",
    "lat": 25.9129,
    "lng": -80.2013,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 34836,
    "id": 1840029039
  },
  {
    "city": "Dragash",
    "city_ascii": "Dragash",
    "lat": 42.0611,
    "lng": 20.6528,
    "country": "Kosovo",
    "iso2": "XK",
    "iso3": "XKS",
    "admin_name": "Dragash",
    "capital": "admin",
    "population": 34827,
    "id": 1901112530
  },
  {
    "city": "Naryn",
    "city_ascii": "Naryn",
    "lat": 41.4333,
    "lng": 76,
    "country": "Kyrgyzstan",
    "iso2": "KG",
    "iso3": "KGZ",
    "admin_name": "Naryn",
    "capital": "admin",
    "population": 34822,
    "id": 1417466970
  },
  {
    "city": "Laḩij",
    "city_ascii": "Lahij",
    "lat": 13.05,
    "lng": 44.8833,
    "country": "Yemen",
    "iso2": "YE",
    "iso3": "YEM",
    "admin_name": "Laḩij",
    "capital": "admin",
    "population": 30661,
    "id": 1887871834
  },
  {
    "city": "Vigneux-sur-Seine",
    "city_ascii": "Vigneux-sur-Seine",
    "lat": 48.7001,
    "lng": 2.417,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 31574,
    "id": 1250005644
  },
  {
    "city": "Aosta",
    "city_ascii": "Aosta",
    "lat": 45.737,
    "lng": 7.315,
    "country": "Italy",
    "iso2": "IT",
    "iso3": "ITA",
    "admin_name": "Valle d’Aosta",
    "capital": "admin",
    "population": 34062,
    "id": 1380291310
  },
  {
    "city": "Oildale",
    "city_ascii": "Oildale",
    "lat": 35.4249,
    "lng": -119.0279,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 34723,
    "id": 1840019137
  },
  {
    "city": "Ciudad Sabinas Hidalgo",
    "city_ascii": "Ciudad Sabinas Hidalgo",
    "lat": 26.5,
    "lng": -100.1833,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Nuevo León",
    "capital": "minor",
    "population": 32047,
    "id": 1484159329
  },
  {
    "city": "Almenara",
    "city_ascii": "Almenara",
    "lat": -16.17,
    "lng": -40.7,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Minas Gerais",
    "capital": "",
    "population": 29333,
    "id": 1076953924
  },
  {
    "city": "Kalasin",
    "city_ascii": "Kalasin",
    "lat": 16.4333,
    "lng": 103.5,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Kalasin",
    "capital": "admin",
    "population": 34437,
    "id": 1764060973
  },
  {
    "city": "Saint Neots",
    "city_ascii": "Saint Neots",
    "lat": 52.228,
    "lng": -0.27,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Cambridgeshire",
    "capital": "",
    "population": 30811,
    "id": 1826441495
  },
  {
    "city": "Villa Celina",
    "city_ascii": "Villa Celina",
    "lat": -34.7006,
    "lng": -58.4825,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires, Ciudad Autónoma de",
    "capital": "",
    "population": 30000,
    "id": 1032320584
  },
  {
    "city": "Fontenay-aux-Roses",
    "city_ascii": "Fontenay-aux-Roses",
    "lat": 48.7893,
    "lng": 2.2888,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 24564,
    "id": 1250066096
  },
  {
    "city": "Jacmel",
    "city_ascii": "Jacmel",
    "lat": 18.235,
    "lng": -72.537,
    "country": "Haiti",
    "iso2": "HT",
    "iso3": "HTI",
    "admin_name": "Sud-Est",
    "capital": "admin",
    "population": 33563,
    "id": 1332314393
  },
  {
    "city": "Santa Cruz",
    "city_ascii": "Santa Cruz",
    "lat": -6.22,
    "lng": -36.03,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Rio Grande do Norte",
    "capital": "",
    "population": 27775,
    "id": 1076706078
  },
  {
    "city": "Swakopmund",
    "city_ascii": "Swakopmund",
    "lat": -22.6667,
    "lng": 14.5333,
    "country": "Namibia",
    "iso2": "NA",
    "iso3": "NAM",
    "admin_name": "Erongo",
    "capital": "admin",
    "population": 34300,
    "id": 1516214290
  },
  {
    "city": "Barra do Bugres",
    "city_ascii": "Barra do Bugres",
    "lat": -15.0725,
    "lng": -57.1811,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Mato Grosso",
    "capital": "",
    "population": 31311,
    "id": 1076717726
  },
  {
    "city": "Lens",
    "city_ascii": "Lens",
    "lat": 50.4322,
    "lng": 2.8333,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Hauts-de-France",
    "capital": "minor",
    "population": 31415,
    "id": 1250108513
  },
  {
    "city": "Azogues",
    "city_ascii": "Azogues",
    "lat": -2.7333,
    "lng": -78.8333,
    "country": "Ecuador",
    "iso2": "EC",
    "iso3": "ECU",
    "admin_name": "Cañar",
    "capital": "admin",
    "population": 33848,
    "id": 1218651012
  },
  {
    "city": "Huatabampo",
    "city_ascii": "Huatabampo",
    "lat": 26.8304,
    "lng": -109.63,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Sonora",
    "capital": "minor",
    "population": 30426,
    "id": 1484335657
  },
  {
    "city": "Escárcega",
    "city_ascii": "Escarcega",
    "lat": 18.6067,
    "lng": -90.7344,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Campeche",
    "capital": "minor",
    "population": 29477,
    "id": 1484800970
  },
  {
    "city": "Norristown",
    "city_ascii": "Norristown",
    "lat": 40.1224,
    "lng": -75.3398,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 34341,
    "id": 1840003705
  },
  {
    "city": "Swadlincote",
    "city_ascii": "Swadlincote",
    "lat": 52.774,
    "lng": -1.557,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Derbyshire",
    "capital": "",
    "population": 32219,
    "id": 1826055646
  },
  {
    "city": "Saint-Cloud",
    "city_ascii": "Saint-Cloud",
    "lat": 48.84,
    "lng": 2.22,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 29973,
    "id": 1250160957
  },
  {
    "city": "Wishaw",
    "city_ascii": "Wishaw",
    "lat": 55.7739,
    "lng": -3.9189,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "North Lanarkshire",
    "capital": "",
    "population": 30250,
    "id": 1826026639
  },
  {
    "city": "Icó",
    "city_ascii": "Ico",
    "lat": -6.4,
    "lng": -38.85,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Ceará",
    "capital": "",
    "population": 28323,
    "id": 1076569458
  },
  {
    "city": "Ozumba",
    "city_ascii": "Ozumba",
    "lat": 19.0392,
    "lng": -98.7936,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "México",
    "capital": "minor",
    "population": 27207,
    "id": 1484458348
  },
  {
    "city": "Deal",
    "city_ascii": "Deal",
    "lat": 51.2226,
    "lng": 1.4006,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Kent",
    "capital": "",
    "population": 30085,
    "id": 1826000065
  },
  {
    "city": "Cananea",
    "city_ascii": "Cananea",
    "lat": 30.9819,
    "lng": -110.3006,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Sonora",
    "capital": "minor",
    "population": 31560,
    "id": 1484752107
  },
  {
    "city": "Homa Bay",
    "city_ascii": "Homa Bay",
    "lat": -0.5167,
    "lng": 34.45,
    "country": "Kenya",
    "iso2": "KE",
    "iso3": "KEN",
    "admin_name": "Homa Bay",
    "capital": "admin",
    "population": 32174,
    "id": 1404321159
  },
  {
    "city": "Bor",
    "city_ascii": "Bor",
    "lat": 44.1303,
    "lng": 22.1036,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Bor",
    "capital": "admin",
    "population": 34159,
    "id": 1688966590
  },
  {
    "city": "Mažeikiai",
    "city_ascii": "Mazeikiai",
    "lat": 56.3111,
    "lng": 22.3361,
    "country": "Lithuania",
    "iso2": "LT",
    "iso3": "LTU",
    "admin_name": "Mažeikiai",
    "capital": "admin",
    "population": 34152,
    "id": 1440746512
  },
  {
    "city": "Busia",
    "city_ascii": "Busia",
    "lat": 0.4608,
    "lng": 34.1108,
    "country": "Kenya",
    "iso2": "KE",
    "iso3": "KEN",
    "admin_name": "Busia",
    "capital": "admin",
    "population": 30777,
    "id": 1404250734
  },
  {
    "city": "São Luís Gonzaga",
    "city_ascii": "Sao Luis Gonzaga",
    "lat": -28.4083,
    "lng": -54.9608,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Rio Grande do Sul",
    "capital": "",
    "population": 31798,
    "id": 1076320357
  },
  {
    "city": "University City",
    "city_ascii": "University City",
    "lat": 38.6657,
    "lng": -90.3315,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 34165,
    "id": 1840010739
  },
  {
    "city": "Borehamwood",
    "city_ascii": "Borehamwood",
    "lat": 51.6578,
    "lng": -0.2723,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hertfordshire",
    "capital": "",
    "population": 31955,
    "id": 1826433403
  },
  {
    "city": "Ubaitaba",
    "city_ascii": "Ubaitaba",
    "lat": -14.3,
    "lng": -39.33,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Bahia",
    "capital": "",
    "population": 28375,
    "id": 1076532739
  },
  {
    "city": "Fushë Kosovë",
    "city_ascii": "Fushe Kosove",
    "lat": 42.63,
    "lng": 21.12,
    "country": "Kosovo",
    "iso2": "XK",
    "iso3": "XKS",
    "admin_name": "Fushë Kosovë",
    "capital": "admin",
    "population": 33997,
    "id": 1901134407
  },
  {
    "city": "Midvale",
    "city_ascii": "Midvale",
    "lat": 40.6148,
    "lng": -111.8928,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Utah",
    "capital": "",
    "population": 34124,
    "id": 1840020158
  },
  {
    "city": "Uttaradit",
    "city_ascii": "Uttaradit",
    "lat": 17.6256,
    "lng": 100.0942,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Uttaradit",
    "capital": "admin",
    "population": 33930,
    "id": 1764146253
  },
  {
    "city": "Buenaventura Lakes",
    "city_ascii": "Buenaventura Lakes",
    "lat": 28.3349,
    "lng": -81.3539,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 34085,
    "id": 1840028974
  },
  {
    "city": "Owando",
    "city_ascii": "Owando",
    "lat": -0.4833,
    "lng": 15.9,
    "country": "Congo (Brazzaville)",
    "iso2": "CG",
    "iso3": "COG",
    "admin_name": "Cuvette",
    "capital": "admin",
    "population": 34070,
    "id": 1178298042
  },
  {
    "city": "Kakata",
    "city_ascii": "Kakata",
    "lat": 6.53,
    "lng": -10.3517,
    "country": "Liberia",
    "iso2": "LR",
    "iso3": "LBR",
    "admin_name": "Margibi",
    "capital": "admin",
    "population": 33945,
    "id": 1430656307
  },
  {
    "city": "Chester",
    "city_ascii": "Chester",
    "lat": 39.8456,
    "lng": -75.3718,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 34000,
    "id": 1840000690
  },
  {
    "city": "Kampong Speu",
    "city_ascii": "Kampong Speu",
    "lat": 11.45,
    "lng": 104.5333,
    "country": "Cambodia",
    "iso2": "KH",
    "iso3": "KHM",
    "admin_name": "Kampong Speu",
    "capital": "admin",
    "population": 33231,
    "id": 1116446492
  },
  {
    "city": "Fada Ngourma",
    "city_ascii": "Fada Ngourma",
    "lat": 12.05,
    "lng": 0.3667,
    "country": "Burkina Faso",
    "iso2": "BF",
    "iso3": "BFA",
    "admin_name": "Est",
    "capital": "admin",
    "population": 33910,
    "id": 1854057074
  },
  {
    "city": "Barros Blancos",
    "city_ascii": "Barros Blancos",
    "lat": -34.7542,
    "lng": -56.0042,
    "country": "Uruguay",
    "iso2": "UY",
    "iso3": "URY",
    "admin_name": "Canelones",
    "capital": "",
    "population": 31650,
    "id": 1858612587
  },
  {
    "city": "Salgótarján",
    "city_ascii": "Salgotarjan",
    "lat": 48.0853,
    "lng": 19.7867,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Nógrád",
    "capital": "admin",
    "population": 33579,
    "id": 1348290189
  },
  {
    "city": "Foster City",
    "city_ascii": "Foster City",
    "lat": 37.5553,
    "lng": -122.2659,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 33901,
    "id": 1840020302
  },
  {
    "city": "Pontefract",
    "city_ascii": "Pontefract",
    "lat": 53.691,
    "lng": -1.312,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Wakefield",
    "capital": "",
    "population": 30881,
    "id": 1826127611
  },
  {
    "city": "Montgomery Village",
    "city_ascii": "Montgomery Village",
    "lat": 39.1783,
    "lng": -77.1957,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 33798,
    "id": 1840005834
  },
  {
    "city": "Beverly Hills",
    "city_ascii": "Beverly Hills",
    "lat": 34.0786,
    "lng": -118.4021,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 33792,
    "id": 1840019231
  },
  {
    "city": "Ciudad Sahagun",
    "city_ascii": "Ciudad Sahagun",
    "lat": 19.7714,
    "lng": -98.5803,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Hidalgo",
    "capital": "",
    "population": 28609,
    "id": 1484112463
  },
  {
    "city": "Thiais",
    "city_ascii": "Thiais",
    "lat": 48.765,
    "lng": 2.3923,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 29017,
    "id": 1250126108
  },
  {
    "city": "Foothill Farms",
    "city_ascii": "Foothill Farms",
    "lat": 38.6867,
    "lng": -121.3475,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 33749,
    "id": 1840017582
  },
  {
    "city": "Westmont",
    "city_ascii": "Westmont",
    "lat": 33.9417,
    "lng": -118.3018,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 33723,
    "id": 1840028408
  },
  {
    "city": "Willenhall",
    "city_ascii": "Willenhall",
    "lat": 52.5798,
    "lng": -2.0605,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Walsall",
    "capital": "",
    "population": 28480,
    "id": 1826751733
  },
  {
    "city": "Chumphon",
    "city_ascii": "Chumphon",
    "lat": 10.4939,
    "lng": 99.18,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Chumphon",
    "capital": "admin",
    "population": 33516,
    "id": 1764012939
  },
  {
    "city": "Fair Oaks",
    "city_ascii": "Fair Oaks",
    "lat": 38.8653,
    "lng": -77.3586,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 33700,
    "id": 1840024568
  },
  {
    "city": "Nyköping",
    "city_ascii": "Nykoping",
    "lat": 58.7582,
    "lng": 17.0185,
    "country": "Sweden",
    "iso2": "SE",
    "iso3": "SWE",
    "admin_name": "Södermanland",
    "capital": "admin",
    "population": 33546,
    "id": 1752377083
  },
  {
    "city": "Masindi",
    "city_ascii": "Masindi",
    "lat": 1.6744,
    "lng": 31.715,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Masindi",
    "capital": "admin",
    "population": 31486,
    "id": 1800409287
  },
  {
    "city": "Glendale Heights",
    "city_ascii": "Glendale Heights",
    "lat": 41.9196,
    "lng": -88.0785,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 33617,
    "id": 1840011406
  },
  {
    "city": "Bilwi",
    "city_ascii": "Bilwi",
    "lat": 14.05,
    "lng": -83.3833,
    "country": "Nicaragua",
    "iso2": "NI",
    "iso3": "NIC",
    "admin_name": "Caribe Norte",
    "capital": "admin",
    "population": 33600,
    "id": 1558656214
  },
  {
    "city": "Iturama",
    "city_ascii": "Iturama",
    "lat": -19.73,
    "lng": -50.2,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Minas Gerais",
    "capital": "",
    "population": 29805,
    "id": 1076049375
  },
  {
    "city": "Kaçanik",
    "city_ascii": "Kacanik",
    "lat": 42.2467,
    "lng": 21.2553,
    "country": "Kosovo",
    "iso2": "XK",
    "iso3": "XKS",
    "admin_name": "Kaçanik",
    "capital": "admin",
    "population": 33409,
    "id": 1901200321
  },
  {
    "city": "Long Beach",
    "city_ascii": "Long Beach",
    "lat": 40.5887,
    "lng": -73.666,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 33454,
    "id": 1840000988
  },
  {
    "city": "Longton",
    "city_ascii": "Longton",
    "lat": 52.9877,
    "lng": -2.1327,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Stoke-on-Trent",
    "capital": "",
    "population": 27214,
    "id": 1826288054
  },
  {
    "city": "Mamoudzou",
    "city_ascii": "Mamoudzou",
    "lat": -12.7871,
    "lng": 45.275,
    "country": "Mayotte",
    "iso2": "YT",
    "iso3": "MYT",
    "admin_name": "",
    "capital": "admin",
    "population": 32057,
    "id": 1175099654
  },
  {
    "city": "Plympton",
    "city_ascii": "Plympton",
    "lat": 50.386,
    "lng": -4.051,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Plymouth",
    "capital": "",
    "population": 29899,
    "id": 1826807244
  },
  {
    "city": "Sisak",
    "city_ascii": "Sisak",
    "lat": 45.4878,
    "lng": 16.3742,
    "country": "Croatia",
    "iso2": "HR",
    "iso3": "HRV",
    "admin_name": "Sisačko-Moslavačka Županija",
    "capital": "admin",
    "population": 33322,
    "id": 1191526652
  },
  {
    "city": "West Little River",
    "city_ascii": "West Little River",
    "lat": 25.857,
    "lng": -80.2367,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 33396,
    "id": 1840029096
  },
  {
    "city": "Birkirkara",
    "city_ascii": "Birkirkara",
    "lat": 35.9,
    "lng": 14.4667,
    "country": "Malta",
    "iso2": "MT",
    "iso3": "MLT",
    "admin_name": "Birkirkara",
    "capital": "admin",
    "population": 24356,
    "id": 1470754290
  },
  {
    "city": "Koh Kong",
    "city_ascii": "Koh Kong",
    "lat": 11.6167,
    "lng": 102.9833,
    "country": "Cambodia",
    "iso2": "KH",
    "iso3": "KHM",
    "admin_name": "Koh Kong",
    "capital": "admin",
    "population": 33134,
    "id": 1116924669
  },
  {
    "city": "Kyrenia",
    "city_ascii": "Kyrenia",
    "lat": 35.3403,
    "lng": 33.3192,
    "country": "Cyprus",
    "iso2": "CY",
    "iso3": "CYP",
    "admin_name": "Kerýneia",
    "capital": "admin",
    "population": 33207,
    "id": 1196334499
  },
  {
    "city": "Vandœuvre-lès-Nancy",
    "city_ascii": "Vandoeuvre-les-Nancy",
    "lat": 48.6567,
    "lng": 6.1683,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Grand Est",
    "capital": "",
    "population": 30002,
    "id": 1250981193
  },
  {
    "city": "Juticalpa",
    "city_ascii": "Juticalpa",
    "lat": 14.6664,
    "lng": -86.2186,
    "country": "Honduras",
    "iso2": "HN",
    "iso3": "HND",
    "admin_name": "Olancho",
    "capital": "admin",
    "population": 33260,
    "id": 1340411203
  },
  {
    "city": "Huntington Station",
    "city_ascii": "Huntington Station",
    "lat": 40.8446,
    "lng": -73.405,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 33259,
    "id": 1840005081
  },
  {
    "city": "Bromsgrove",
    "city_ascii": "Bromsgrove",
    "lat": 52.3353,
    "lng": -2.0579,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Worcestershire",
    "capital": "",
    "population": 29237,
    "id": 1826356095
  },
  {
    "city": "Goussainville",
    "city_ascii": "Goussainville",
    "lat": 49.0325,
    "lng": 2.4747,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 30637,
    "id": 1250333047
  },
  {
    "city": "Mukdahan",
    "city_ascii": "Mukdahan",
    "lat": 16.5431,
    "lng": 104.7228,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Mukdahan",
    "capital": "admin",
    "population": 33102,
    "id": 1764994534
  },
  {
    "city": "Óbidos",
    "city_ascii": "Obidos",
    "lat": -1.91,
    "lng": -55.52,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Pará",
    "capital": "",
    "population": 27587,
    "id": 1076197849
  },
  {
    "city": "Adrogue",
    "city_ascii": "Adrogue",
    "lat": -34.8,
    "lng": -58.3833,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires",
    "capital": "",
    "population": 28265,
    "id": 1032827397
  },
  {
    "city": "Liévin",
    "city_ascii": "Lievin",
    "lat": 50.4228,
    "lng": 2.7786,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Hauts-de-France",
    "capital": "",
    "population": 30785,
    "id": 1250072003
  },
  {
    "city": "Bulwell",
    "city_ascii": "Bulwell",
    "lat": 53.001,
    "lng": -1.197,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Nottingham",
    "capital": "",
    "population": 29771,
    "id": 1826933370
  },
  {
    "city": "Rincón de Romos",
    "city_ascii": "Rincon de Romos",
    "lat": 22.2333,
    "lng": -102.3167,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Aguascalientes",
    "capital": "minor",
    "population": 27988,
    "id": 1484371427
  },
  {
    "city": "Presidente Dutra",
    "city_ascii": "Presidente Dutra",
    "lat": -5.29,
    "lng": -44.49,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Alagoas",
    "capital": "",
    "population": 30330,
    "id": 1076660845
  },
  {
    "city": "Kericho",
    "city_ascii": "Kericho",
    "lat": -0.3692,
    "lng": 35.2839,
    "country": "Kenya",
    "iso2": "KE",
    "iso3": "KEN",
    "admin_name": "Kericho",
    "capital": "admin",
    "population": 30023,
    "id": 1404338562
  },
  {
    "city": "Sotteville-lès-Rouen",
    "city_ascii": "Sotteville-les-Rouen",
    "lat": 49.4092,
    "lng": 1.09,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Normandie",
    "capital": "",
    "population": 28965,
    "id": 1250313503
  },
  {
    "city": "Eastchester",
    "city_ascii": "Eastchester",
    "lat": 40.9536,
    "lng": -73.8134,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 32964,
    "id": 1840058058
  },
  {
    "city": "Pilar",
    "city_ascii": "Pilar",
    "lat": -26.8569,
    "lng": -58.3039,
    "country": "Paraguay",
    "iso2": "PY",
    "iso3": "PRY",
    "admin_name": "Ñeembucú",
    "capital": "admin",
    "population": 32810,
    "id": 1600377954
  },
  {
    "city": "Kokhma",
    "city_ascii": "Kokhma",
    "lat": 56.9333,
    "lng": 41.0833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Ivanovskaya Oblast’",
    "capital": "",
    "population": 30500,
    "id": 1643786528
  },
  {
    "city": "Reddish",
    "city_ascii": "Reddish",
    "lat": 53.4383,
    "lng": -2.1613,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Stockport",
    "capital": "",
    "population": 28052,
    "id": 1826969307
  },
  {
    "city": "Fair Lawn",
    "city_ascii": "Fair Lawn",
    "lat": 40.9359,
    "lng": -74.1177,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 32896,
    "id": 1840003548
  },
  {
    "city": "Zumpango del Río",
    "city_ascii": "Zumpango del Rio",
    "lat": 17.65,
    "lng": -99.5,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Guerrero",
    "capital": "minor",
    "population": 24719,
    "id": 1484750477
  },
  {
    "city": "Chkalov",
    "city_ascii": "Chkalov",
    "lat": 40.2386,
    "lng": 69.6967,
    "country": "Tajikistan",
    "iso2": "TJ",
    "iso3": "TJK",
    "admin_name": "Sughd",
    "capital": "",
    "population": 29800,
    "id": 1762754874
  },
  {
    "city": "Un’goofaaru",
    "city_ascii": "Un'goofaaru",
    "lat": 5.6681,
    "lng": 73.0302,
    "country": "Maldives",
    "iso2": "MV",
    "iso3": "MDV",
    "admin_name": "Maalhosmadulu Uthuruburi",
    "capital": "admin",
    "population": null,
    "id": 1462941721
  },
  {
    "city": "Harper",
    "city_ascii": "Harper",
    "lat": 4.3754,
    "lng": -7.717,
    "country": "Liberia",
    "iso2": "LR",
    "iso3": "LBR",
    "admin_name": "Maryland",
    "capital": "admin",
    "population": 32661,
    "id": 1430540258
  },
  {
    "city": "Lambersart",
    "city_ascii": "Lambersart",
    "lat": 50.65,
    "lng": 3.025,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Hauts-de-France",
    "capital": "",
    "population": 27649,
    "id": 1250198806
  },
  {
    "city": "Périgueux",
    "city_ascii": "Perigueux",
    "lat": 45.1929,
    "lng": 0.7217,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Nouvelle-Aquitaine",
    "capital": "minor",
    "population": 29966,
    "id": 1250864403
  },
  {
    "city": "Cramlington",
    "city_ascii": "Cramlington",
    "lat": 55.082,
    "lng": -1.585,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Northumberland",
    "capital": "",
    "population": 29405,
    "id": 1826152039
  },
  {
    "city": "Ris-Orangis",
    "city_ascii": "Ris-Orangis",
    "lat": 48.6537,
    "lng": 2.4161,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 29225,
    "id": 1250798582
  },
  {
    "city": "Savigny-le-Temple",
    "city_ascii": "Savigny-le-Temple",
    "lat": 48.5841,
    "lng": 2.5832,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 29984,
    "id": 1250790534
  },
  {
    "city": "Ban Bang Krang",
    "city_ascii": "Ban Bang Krang",
    "lat": 13.8442,
    "lng": 100.4612,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Nonthaburi",
    "capital": "",
    "population": 23010,
    "id": 1764797592
  },
  {
    "city": "Dagestanskiye Ogni",
    "city_ascii": "Dagestanskiye Ogni",
    "lat": 42.1167,
    "lng": 48.2,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Dagestan",
    "capital": "",
    "population": 29401,
    "id": 1643307601
  },
  {
    "city": "Mount Lebanon",
    "city_ascii": "Mount Lebanon",
    "lat": 40.3752,
    "lng": -80.0493,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 32540,
    "id": 1840133089
  },
  {
    "city": "Soledad de Doblado",
    "city_ascii": "Soledad de Doblado",
    "lat": 19.0447,
    "lng": -96.4233,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Veracruz",
    "capital": "minor",
    "population": 27770,
    "id": 1484007805
  },
  {
    "city": "Kendal",
    "city_ascii": "Kendal",
    "lat": 54.326,
    "lng": -2.745,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Cumbria",
    "capital": "",
    "population": 28586,
    "id": 1826195534
  },
  {
    "city": "North Providence",
    "city_ascii": "North Providence",
    "lat": 41.8616,
    "lng": -71.4575,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Rhode Island",
    "capital": "",
    "population": 32459,
    "id": 1840106198
  },
  {
    "city": "Leticia",
    "city_ascii": "Leticia",
    "lat": -4.215,
    "lng": -69.9411,
    "country": "Colombia",
    "iso2": "CO",
    "iso3": "COL",
    "admin_name": "Amazonas",
    "capital": "admin",
    "population": 32450,
    "id": 1170548641
  },
  {
    "city": "Côte-Saint-Luc",
    "city_ascii": "Cote-Saint-Luc",
    "lat": 45.4687,
    "lng": -73.6673,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 32448,
    "id": 1124563603
  },
  {
    "city": "Perry Barr",
    "city_ascii": "Perry Barr",
    "lat": 52.5249,
    "lng": -1.9042,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Birmingham",
    "capital": "",
    "population": 23652,
    "id": 1826464543
  },
  {
    "city": "Mandera",
    "city_ascii": "Mandera",
    "lat": 3.9167,
    "lng": 41.8333,
    "country": "Kenya",
    "iso2": "KE",
    "iso3": "KEN",
    "admin_name": "Mandera",
    "capital": "admin",
    "population": 30433,
    "id": 1404192737
  },
  {
    "city": "Lawndale",
    "city_ascii": "Lawndale",
    "lat": 33.8884,
    "lng": -118.3531,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 32389,
    "id": 1840020517
  },
  {
    "city": "Yverdon-les-Bains",
    "city_ascii": "Yverdon-les-Bains",
    "lat": 46.7785,
    "lng": 6.6408,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Vaud",
    "capital": "minor",
    "population": 30157,
    "id": 1756916105
  },
  {
    "city": "Franklin Square",
    "city_ascii": "Franklin Square",
    "lat": 40.7002,
    "lng": -73.6775,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 32329,
    "id": 1840005256
  },
  {
    "city": "Spring Valley",
    "city_ascii": "Spring Valley",
    "lat": 41.1151,
    "lng": -74.0486,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 32261,
    "id": 1840004986
  },
  {
    "city": "Otumba",
    "city_ascii": "Otumba",
    "lat": 19.6969,
    "lng": -98.7547,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "México",
    "capital": "minor",
    "population": 29873,
    "id": 1484001484
  },
  {
    "city": "Bangassou",
    "city_ascii": "Bangassou",
    "lat": 4.737,
    "lng": 22.819,
    "country": "Central African Republic",
    "iso2": "CF",
    "iso3": "CAF",
    "admin_name": "Mbomou",
    "capital": "admin",
    "population": 31453,
    "id": 1140539356
  },
  {
    "city": "Cambuslang",
    "city_ascii": "Cambuslang",
    "lat": 55.819,
    "lng": -4.1671,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "South Lanarkshire",
    "capital": "",
    "population": 29690,
    "id": 1826755066
  },
  {
    "city": "Chachapoyas",
    "city_ascii": "Chachapoyas",
    "lat": -6.2167,
    "lng": -77.85,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "Amazonas",
    "capital": "admin",
    "population": 32026,
    "id": 1604650674
  },
  {
    "city": "Darlaston",
    "city_ascii": "Darlaston",
    "lat": 52.5708,
    "lng": -2.0457,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Walsall",
    "capital": "",
    "population": 27821,
    "id": 1826133238
  },
  {
    "city": "West Ham",
    "city_ascii": "West Ham",
    "lat": 51.5347,
    "lng": 0.0077,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Newham",
    "capital": "",
    "population": 15551,
    "id": 1826369701
  },
  {
    "city": "College Park",
    "city_ascii": "College Park",
    "lat": 38.996,
    "lng": -76.9337,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 32163,
    "id": 1840005971
  },
  {
    "city": "Rumbek",
    "city_ascii": "Rumbek",
    "lat": 6.8,
    "lng": 29.6833,
    "country": "South Sudan",
    "iso2": "SS",
    "iso3": "SSD",
    "admin_name": "Lakes",
    "capital": "admin",
    "population": 32083,
    "id": 1728842249
  },
  {
    "city": "Szekszárd",
    "city_ascii": "Szekszard",
    "lat": 46.3558,
    "lng": 18.7039,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Tolna",
    "capital": "admin",
    "population": 31795,
    "id": 1348026284
  },
  {
    "city": "Boende",
    "city_ascii": "Boende",
    "lat": -0.2196,
    "lng": 20.86,
    "country": "Congo (Kinshasa)",
    "iso2": "CD",
    "iso3": "COD",
    "admin_name": "Tshuapa",
    "capital": "admin",
    "population": 32091,
    "id": 1180688906
  },
  {
    "city": "Álamo",
    "city_ascii": "Alamo",
    "lat": 20.9167,
    "lng": -97.675,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Veracruz",
    "capital": "minor",
    "population": 25159,
    "id": 1484161565
  },
  {
    "city": "Niquelândia",
    "city_ascii": "Niquelandia",
    "lat": -14.4696,
    "lng": -48.47,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Goiás",
    "capital": "",
    "population": 27541,
    "id": 1076682103
  },
  {
    "city": "Rivas",
    "city_ascii": "Rivas",
    "lat": 11.4404,
    "lng": -85.82,
    "country": "Nicaragua",
    "iso2": "NI",
    "iso3": "NIC",
    "admin_name": "Rivas",
    "capital": "admin",
    "population": 31941,
    "id": 1558146594
  },
  {
    "city": "Rillieux-la-Pape",
    "city_ascii": "Rillieux-la-Pape",
    "lat": 45.8214,
    "lng": 4.8983,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Auvergne-Rhône-Alpes",
    "capital": "",
    "population": 30012,
    "id": 1250128916
  },
  {
    "city": "Eastpointe",
    "city_ascii": "Eastpointe",
    "lat": 42.4657,
    "lng": -82.9461,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 32081,
    "id": 1840002420
  },
  {
    "city": "Acaraú",
    "city_ascii": "Acarau",
    "lat": -2.8896,
    "lng": -40.12,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Ceará",
    "capital": "",
    "population": 28389,
    "id": 1076016462
  },
  {
    "city": "Yerres",
    "city_ascii": "Yerres",
    "lat": 48.7171,
    "lng": 2.4881,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 29029,
    "id": 1250114629
  },
  {
    "city": "Bourg-la-Reine",
    "city_ascii": "Bourg-la-Reine",
    "lat": 48.7796,
    "lng": 2.3151,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 20667,
    "id": 1250693512
  },
  {
    "city": "Ulundi",
    "city_ascii": "Ulundi",
    "lat": -28.335,
    "lng": 31.4161,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "KwaZulu-Natal",
    "capital": "",
    "population": 29132,
    "id": 1710823263
  },
  {
    "city": "Zug",
    "city_ascii": "Zug",
    "lat": 47.1692,
    "lng": 8.5164,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Zug",
    "capital": "admin",
    "population": 30542,
    "id": 1756703208
  },
  {
    "city": "San Juan",
    "city_ascii": "San Juan",
    "lat": 9.9609,
    "lng": -84.0731,
    "country": "Costa Rica",
    "iso2": "CR",
    "iso3": "CRI",
    "admin_name": "San José",
    "capital": "",
    "population": 24944,
    "id": 1188421709
  },
  {
    "city": "Cuitzeo del Porvenir",
    "city_ascii": "Cuitzeo del Porvenir",
    "lat": 19.9686,
    "lng": -101.1394,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Michoacán de Ocampo",
    "capital": "minor",
    "population": 28227,
    "id": 1484718007
  },
  {
    "city": "Aix-les-Bains",
    "city_ascii": "Aix-les-Bains",
    "lat": 45.6885,
    "lng": 5.9153,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Auvergne-Rhône-Alpes",
    "capital": "",
    "population": 29794,
    "id": 1250456565
  },
  {
    "city": "Navolato",
    "city_ascii": "Navolato",
    "lat": 24.7656,
    "lng": -107.7019,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Sinaloa",
    "capital": "minor",
    "population": 28676,
    "id": 1484515879
  },
  {
    "city": "Uniondale",
    "city_ascii": "Uniondale",
    "lat": 40.7176,
    "lng": -73.5947,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 31828,
    "id": 1840005279
  },
  {
    "city": "Jérémie",
    "city_ascii": "Jeremie",
    "lat": 18.6339,
    "lng": -74.1184,
    "country": "Haiti",
    "iso2": "HT",
    "iso3": "HTI",
    "admin_name": "Grand’Anse",
    "capital": "admin",
    "population": 30917,
    "id": 1332612037
  },
  {
    "city": "Rawson",
    "city_ascii": "Rawson",
    "lat": -43.3,
    "lng": -65.1,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Chubut",
    "capital": "admin",
    "population": 31787,
    "id": 1032302344
  },
  {
    "city": "Garfield",
    "city_ascii": "Garfield",
    "lat": 40.8791,
    "lng": -74.1085,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 31802,
    "id": 1840003554
  },
  {
    "city": "Sannois",
    "city_ascii": "Sannois",
    "lat": 48.9722,
    "lng": 2.2578,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 26662,
    "id": 1250650651
  },
  {
    "city": "Oullins",
    "city_ascii": "Oullins",
    "lat": 45.715,
    "lng": 4.8083,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Auvergne-Rhône-Alpes",
    "capital": "",
    "population": 26273,
    "id": 1250831015
  },
  {
    "city": "El Salto",
    "city_ascii": "El Salto",
    "lat": 23.7823,
    "lng": -105.3585,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Durango",
    "capital": "minor",
    "population": 24241,
    "id": 1484463833
  },
  {
    "city": "Shumerlya",
    "city_ascii": "Shumerlya",
    "lat": 55.5,
    "lng": 46.4167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Chuvashiya",
    "capital": "",
    "population": 29553,
    "id": 1643065597
  },
  {
    "city": "Clifton",
    "city_ascii": "Clifton",
    "lat": 52.904,
    "lng": -1.177,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Nottingham",
    "capital": "",
    "population": 26835,
    "id": 1826911348
  },
  {
    "city": "Dieppe",
    "city_ascii": "Dieppe",
    "lat": 49.92,
    "lng": 1.08,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Normandie",
    "capital": "minor",
    "population": 29080,
    "id": 1250006284
  },
  {
    "city": "Babati",
    "city_ascii": "Babati",
    "lat": -4.2117,
    "lng": 35.7475,
    "country": "Tanzania",
    "iso2": "TZ",
    "iso3": "TZA",
    "admin_name": "Manyara",
    "capital": "admin",
    "population": 30975,
    "id": 1834081851
  },
  {
    "city": "Saint-Laurent-du-Var",
    "city_ascii": "Saint-Laurent-du-Var",
    "lat": 43.668,
    "lng": 7.188,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Provence-Alpes-Côte d’Azur",
    "capital": "",
    "population": 28453,
    "id": 1250141244
  },
  {
    "city": "Wood Green",
    "city_ascii": "Wood Green",
    "lat": 51.5981,
    "lng": -0.1149,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Haringey",
    "capital": "",
    "population": 28453,
    "id": 1826902664
  },
  {
    "city": "Chon Buri",
    "city_ascii": "Chon Buri",
    "lat": 13.3611,
    "lng": 100.985,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Chon Buri",
    "capital": "admin",
    "population": 29961,
    "id": 1764202077
  },
  {
    "city": "Parkville",
    "city_ascii": "Parkville",
    "lat": 39.3832,
    "lng": -76.5519,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 31633,
    "id": 1840005685
  },
  {
    "city": "Frome",
    "city_ascii": "Frome",
    "lat": 51.2279,
    "lng": -2.3215,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Somerset",
    "capital": "",
    "population": 26203,
    "id": 1826730210
  },
  {
    "city": "Les Pavillons-sous-Bois",
    "city_ascii": "Les Pavillons-sous-Bois",
    "lat": 48.9,
    "lng": 2.5,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 23962,
    "id": 1250984996
  },
  {
    "city": "Arcueil",
    "city_ascii": "Arcueil",
    "lat": 48.8075,
    "lng": 2.3361,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 21501,
    "id": 1250317790
  },
  {
    "city": "Smolyan",
    "city_ascii": "Smolyan",
    "lat": 41.5753,
    "lng": 24.7128,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Smolyan",
    "capital": "admin",
    "population": 31548,
    "id": 1100429166
  },
  {
    "city": "Pallisa",
    "city_ascii": "Pallisa",
    "lat": 1.145,
    "lng": 33.7094,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Pallisa",
    "capital": "admin",
    "population": 30745,
    "id": 1800214906
  },
  {
    "city": "Kaita",
    "city_ascii": "Kaita",
    "lat": 34.3722,
    "lng": 132.5361,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hiroshima",
    "capital": "",
    "population": 29431,
    "id": 1392868298
  },
  {
    "city": "Alenquer",
    "city_ascii": "Alenquer",
    "lat": -1.9396,
    "lng": -54.79,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Pará",
    "capital": "",
    "population": 26290,
    "id": 1076529970
  },
  {
    "city": "Villiers-le-Bel",
    "city_ascii": "Villiers-le-Bel",
    "lat": 49.0094,
    "lng": 2.3911,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 27676,
    "id": 1250001397
  },
  {
    "city": "Ipu",
    "city_ascii": "Ipu",
    "lat": -4.3196,
    "lng": -40.7201,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Ceará",
    "capital": "",
    "population": 26678,
    "id": 1076972725
  },
  {
    "city": "Miracema",
    "city_ascii": "Miracema",
    "lat": -21.41,
    "lng": -42.2,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Rio de Janeiro",
    "capital": "",
    "population": 26684,
    "id": 1076359191
  },
  {
    "city": "Viana",
    "city_ascii": "Viana",
    "lat": -3.2096,
    "lng": -45,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Maranhão",
    "capital": "",
    "population": 26689,
    "id": 1076423280
  },
  {
    "city": "Zaqatala",
    "city_ascii": "Zaqatala",
    "lat": 41.6336,
    "lng": 46.6433,
    "country": "Azerbaijan",
    "iso2": "AZ",
    "iso3": "AZE",
    "admin_name": "Zaqatala",
    "capital": "admin",
    "population": 31300,
    "id": 1031500858
  },
  {
    "city": "Miami Lakes",
    "city_ascii": "Miami Lakes",
    "lat": 25.9125,
    "lng": -80.3214,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 31367,
    "id": 1840017275
  },
  {
    "city": "San Fernando",
    "city_ascii": "San Fernando",
    "lat": 24.8504,
    "lng": -98.16,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Tamaulipas",
    "capital": "minor",
    "population": 29171,
    "id": 1484830415
  },
  {
    "city": "İmişli",
    "city_ascii": "Imisli",
    "lat": 39.8697,
    "lng": 48.06,
    "country": "Azerbaijan",
    "iso2": "AZ",
    "iso3": "AZE",
    "admin_name": "İmişli",
    "capital": "admin",
    "population": 31310,
    "id": 1031686599
  },
  {
    "city": "Montfermeil",
    "city_ascii": "Montfermeil",
    "lat": 48.9,
    "lng": 2.5667,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 26783,
    "id": 1250655335
  },
  {
    "city": "Newton Abbot",
    "city_ascii": "Newton Abbot",
    "lat": 50.529,
    "lng": -3.61,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Devon",
    "capital": "",
    "population": 25556,
    "id": 1826126497
  },
  {
    "city": "Péfki",
    "city_ascii": "Pefki",
    "lat": 38.0667,
    "lng": 23.8,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Attikí",
    "capital": "minor",
    "population": 21415,
    "id": 1300609636
  },
  {
    "city": "Iporá",
    "city_ascii": "Ipora",
    "lat": -16.45,
    "lng": -51.13,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Goiás",
    "capital": "",
    "population": 28254,
    "id": 1076392298
  },
  {
    "city": "Contla",
    "city_ascii": "Contla",
    "lat": 19.3333,
    "lng": -98.1667,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Tlaxcala",
    "capital": "minor",
    "population": 27610,
    "id": 1484059318
  },
  {
    "city": "Hertford",
    "city_ascii": "Hertford",
    "lat": 51.795,
    "lng": -0.078,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hertfordshire",
    "capital": "",
    "population": 26783,
    "id": 1826912872
  },
  {
    "city": "Břevnov",
    "city_ascii": "Brevnov",
    "lat": 50.0833,
    "lng": 14.3579,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Středočeský Kraj",
    "capital": "",
    "population": 26275,
    "id": 1203462593
  },
  {
    "city": "Oceanside",
    "city_ascii": "Oceanside",
    "lat": 40.6328,
    "lng": -73.6364,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 31185,
    "id": 1840005246
  },
  {
    "city": "Pando",
    "city_ascii": "Pando",
    "lat": -34.7167,
    "lng": -55.9583,
    "country": "Uruguay",
    "iso2": "UY",
    "iso3": "URY",
    "admin_name": "Canelones",
    "capital": "",
    "population": 25949,
    "id": 1858276269
  },
  {
    "city": "Bègles",
    "city_ascii": "Begles",
    "lat": 44.8086,
    "lng": -0.5478,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Nouvelle-Aquitaine",
    "capital": "",
    "population": 28601,
    "id": 1250300357
  },
  {
    "city": "Ridley",
    "city_ascii": "Ridley",
    "lat": 39.8854,
    "lng": -75.328,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 31117,
    "id": 1840142587
  },
  {
    "city": "Wete",
    "city_ascii": "Wete",
    "lat": -5.0567,
    "lng": 39.7281,
    "country": "Tanzania",
    "iso2": "TZ",
    "iso3": "TZA",
    "admin_name": "Pemba North",
    "capital": "admin",
    "population": 26450,
    "id": 1834217491
  },
  {
    "city": "Mytilíni",
    "city_ascii": "Mytilini",
    "lat": 39.1114,
    "lng": 26.5621,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Voreío Aigaío",
    "capital": "admin",
    "population": 29328,
    "id": 1300101706
  },
  {
    "city": "Udomlya",
    "city_ascii": "Udomlya",
    "lat": 57.8833,
    "lng": 35.0167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Tverskaya Oblast’",
    "capital": "",
    "population": 28119,
    "id": 1643942215
  },
  {
    "city": "Arendal",
    "city_ascii": "Arendal",
    "lat": 58.4617,
    "lng": 8.7721,
    "country": "Norway",
    "iso2": "NO",
    "iso3": "NOR",
    "admin_name": "Aust-Agder",
    "capital": "admin",
    "population": 30916,
    "id": 1578533046
  },
  {
    "city": "Nebbi",
    "city_ascii": "Nebbi",
    "lat": 2.4758,
    "lng": 31.1025,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Nebbi",
    "capital": "admin",
    "population": 30354,
    "id": 1800598019
  },
  {
    "city": "Ashington",
    "city_ascii": "Ashington",
    "lat": 55.181,
    "lng": -1.568,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Northumberland",
    "capital": "",
    "population": 27764,
    "id": 1826789902
  },
  {
    "city": "Santa Vitória do Palmar",
    "city_ascii": "Santa Vitoria do Palmar",
    "lat": -33.52,
    "lng": -53.37,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Rio Grande do Sul",
    "capital": "",
    "population": 27775,
    "id": 1076114405
  },
  {
    "city": "Desnogorsk",
    "city_ascii": "Desnogorsk",
    "lat": 54.1531,
    "lng": 33.2903,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Smolenskaya Oblast’",
    "capital": "",
    "population": 27771,
    "id": 1643598979
  },
  {
    "city": "Menton",
    "city_ascii": "Menton",
    "lat": 43.775,
    "lng": 7.5,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Provence-Alpes-Côte d’Azur",
    "capital": "",
    "population": 28958,
    "id": 1250391575
  },
  {
    "city": "Diffa",
    "city_ascii": "Diffa",
    "lat": 13.3171,
    "lng": 12.6089,
    "country": "Niger",
    "iso2": "NE",
    "iso3": "NER",
    "admin_name": "Diffa",
    "capital": "admin",
    "population": 30988,
    "id": 1562050464
  },
  {
    "city": "San Pablo",
    "city_ascii": "San Pablo",
    "lat": 37.9628,
    "lng": -122.3425,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 30990,
    "id": 1840021529
  },
  {
    "city": "Rhyl",
    "city_ascii": "Rhyl",
    "lat": 53.321,
    "lng": -3.48,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Denbighshire",
    "capital": "",
    "population": 25149,
    "id": 1826387077
  },
  {
    "city": "Ungheni",
    "city_ascii": "Ungheni",
    "lat": 47.2042,
    "lng": 27.7958,
    "country": "Moldova",
    "iso2": "MD",
    "iso3": "MDA",
    "admin_name": "Ungheni",
    "capital": "admin",
    "population": 30804,
    "id": 1498687533
  },
  {
    "city": "Burlingame",
    "city_ascii": "Burlingame",
    "lat": 37.586,
    "lng": -122.3669,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 30889,
    "id": 1840018925
  },
  {
    "city": "Trípoli",
    "city_ascii": "Tripoli",
    "lat": 37.5083,
    "lng": 22.375,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Peloponnísos",
    "capital": "admin",
    "population": 30866,
    "id": 1300131630
  },
  {
    "city": "Čakovec",
    "city_ascii": "Cakovec",
    "lat": 46.3833,
    "lng": 16.4333,
    "country": "Croatia",
    "iso2": "HR",
    "iso3": "HRV",
    "admin_name": "Međimurska Županija",
    "capital": "admin",
    "population": 30455,
    "id": 1191424625
  },
  {
    "city": "Guyancourt",
    "city_ascii": "Guyancourt",
    "lat": 48.7714,
    "lng": 2.0739,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 28633,
    "id": 1250611079
  },
  {
    "city": "Soissons",
    "city_ascii": "Soissons",
    "lat": 49.3817,
    "lng": 3.3236,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Hauts-de-France",
    "capital": "minor",
    "population": 28530,
    "id": 1250109043
  },
  {
    "city": "Longbridge",
    "city_ascii": "Longbridge",
    "lat": 52.395,
    "lng": -1.979,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Birmingham",
    "capital": "",
    "population": 25410,
    "id": 1826461714
  },
  {
    "city": "Rainham",
    "city_ascii": "Rainham",
    "lat": 51.36,
    "lng": 0.61,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Medway",
    "capital": "",
    "population": 25000,
    "id": 1826039806
  },
  {
    "city": "Pljevlja",
    "city_ascii": "Pljevlja",
    "lat": 43.3566,
    "lng": 19.3502,
    "country": "Montenegro",
    "iso2": "ME",
    "iso3": "MNE",
    "admin_name": "Pljevlja",
    "capital": "admin",
    "population": 30786,
    "id": 1499255431
  },
  {
    "city": "Ninomiya",
    "city_ascii": "Ninomiya",
    "lat": 35.2994,
    "lng": 139.2553,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Kanagawa",
    "capital": "",
    "population": 27744,
    "id": 1392269744
  },
  {
    "city": "Kuala Belait",
    "city_ascii": "Kuala Belait",
    "lat": 4.5833,
    "lng": 114.1833,
    "country": "Brunei",
    "iso2": "BN",
    "iso3": "BRN",
    "admin_name": "Belait",
    "capital": "admin",
    "population": 30267,
    "id": 1096999548
  },
  {
    "city": "Lobatse",
    "city_ascii": "Lobatse",
    "lat": -25.2167,
    "lng": 25.6667,
    "country": "Botswana",
    "iso2": "BW",
    "iso3": "BWA",
    "admin_name": "Lobatse",
    "capital": "admin",
    "population": 29700,
    "id": 1072740062
  },
  {
    "city": "Conceição do Araguaia",
    "city_ascii": "Conceicao do Araguaia",
    "lat": -8.25,
    "lng": -49.29,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Pará",
    "capital": "",
    "population": 27115,
    "id": 1076224782
  },
  {
    "city": "Bay Shore",
    "city_ascii": "Bay Shore",
    "lat": 40.7317,
    "lng": -73.2505,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 30685,
    "id": 1840005017
  },
  {
    "city": "Yate",
    "city_ascii": "Yate",
    "lat": 51.5402,
    "lng": -2.411,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "South Gloucestershire",
    "capital": "",
    "population": 27603,
    "id": 1826350658
  },
  {
    "city": "Melton Mowbray",
    "city_ascii": "Melton Mowbray",
    "lat": 52.7661,
    "lng": -0.886,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Leicestershire",
    "capital": "",
    "population": 27158,
    "id": 1826081366
  },
  {
    "city": "Krabi",
    "city_ascii": "Krabi",
    "lat": 8.0592,
    "lng": 98.9189,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Krabi",
    "capital": "admin",
    "population": 30499,
    "id": 1764587855
  },
  {
    "city": "Eaubonne",
    "city_ascii": "Eaubonne",
    "lat": 48.9922,
    "lng": 2.2806,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 25097,
    "id": 1250854033
  },
  {
    "city": "Xalisco",
    "city_ascii": "Xalisco",
    "lat": 21.4481,
    "lng": -104.9,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Nayarit",
    "capital": "minor",
    "population": 21899,
    "id": 1484321343
  },
  {
    "city": "Carouge",
    "city_ascii": "Carouge",
    "lat": 46.1817,
    "lng": 6.1404,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Genève",
    "capital": "",
    "population": 22336,
    "id": 1756165206
  },
  {
    "city": "Avellaneda",
    "city_ascii": "Avellaneda",
    "lat": -34.6625,
    "lng": -58.3678,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires",
    "capital": "minor",
    "population": 24313,
    "id": 1032478683
  },
  {
    "city": "Kizuki",
    "city_ascii": "Kizuki",
    "lat": 33.85,
    "lng": 130.7,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Fukuoka",
    "capital": "",
    "population": 28002,
    "id": 1392671399
  },
  {
    "city": "Nova Cruz",
    "city_ascii": "Nova Cruz",
    "lat": -6.4696,
    "lng": -35.44,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Rio Grande do Norte",
    "capital": "",
    "population": 23166,
    "id": 1076169350
  },
  {
    "city": "Boscombe",
    "city_ascii": "Boscombe",
    "lat": 50.725,
    "lng": -1.84,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Bournemouth",
    "capital": "",
    "population": 20719,
    "id": 1826070994
  },
  {
    "city": "Kętrzyn",
    "city_ascii": "Ketrzyn",
    "lat": 54.0833,
    "lng": 21.3833,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Warmińsko-Mazurskie",
    "capital": "minor",
    "population": 27827,
    "id": 1616659048
  },
  {
    "city": "City of Orange",
    "city_ascii": "City of Orange",
    "lat": 40.7682,
    "lng": -74.2348,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 30466,
    "id": 1840131615
  },
  {
    "city": "Salyan",
    "city_ascii": "Salyan",
    "lat": 39.5968,
    "lng": 48.9837,
    "country": "Azerbaijan",
    "iso2": "AZ",
    "iso3": "AZE",
    "admin_name": "Salyan",
    "capital": "admin",
    "population": 30396,
    "id": 1031301869
  },
  {
    "city": "Portel",
    "city_ascii": "Portel",
    "lat": -1.95,
    "lng": -50.82,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Pará",
    "capital": "",
    "population": 21315,
    "id": 1076977846
  },
  {
    "city": "Barnstaple",
    "city_ascii": "Barnstaple",
    "lat": 51.08,
    "lng": -4.06,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Devon",
    "capital": "",
    "population": 24033,
    "id": 1826606982
  },
  {
    "city": "Sterling",
    "city_ascii": "Sterling",
    "lat": 39.0052,
    "lng": -77.405,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 30403,
    "id": 1840024508
  },
  {
    "city": "Heredia",
    "city_ascii": "Heredia",
    "lat": 9.9985,
    "lng": -84.1169,
    "country": "Costa Rica",
    "iso2": "CR",
    "iso3": "CRI",
    "admin_name": "Heredia",
    "capital": "admin",
    "population": 22700,
    "id": 1188210837
  },
  {
    "city": "Chichester",
    "city_ascii": "Chichester",
    "lat": 50.8365,
    "lng": -0.7792,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "West Sussex",
    "capital": "",
    "population": 26795,
    "id": 1826696164
  },
  {
    "city": "Newton Mearns",
    "city_ascii": "Newton Mearns",
    "lat": 55.7716,
    "lng": -4.3347,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "East Renfrewshire",
    "capital": "",
    "population": 27660,
    "id": 1826713585
  },
  {
    "city": "Jaynagar-Majilpur",
    "city_ascii": "Jaynagar-Majilpur",
    "lat": 22.1772,
    "lng": 88.4258,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "West Bengal",
    "capital": "",
    "population": 25922,
    "id": 1356557030
  },
  {
    "city": "Chatan",
    "city_ascii": "Chatan",
    "lat": 26.32,
    "lng": 127.7639,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Okinawa",
    "capital": "",
    "population": 28270,
    "id": 1392411015
  },
  {
    "city": "Ladera Ranch",
    "city_ascii": "Ladera Ranch",
    "lat": 33.5491,
    "lng": -117.6416,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 30288,
    "id": 1840028333
  },
  {
    "city": "Jamay",
    "city_ascii": "Jamay",
    "lat": 20.2944,
    "lng": -102.7097,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Jalisco",
    "capital": "minor",
    "population": 24753,
    "id": 1484510036
  },
  {
    "city": "Long Branch",
    "city_ascii": "Long Branch",
    "lat": 40.2965,
    "lng": -73.9915,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 30241,
    "id": 1840003673
  },
  {
    "city": "Santa Paula",
    "city_ascii": "Santa Paula",
    "lat": 34.3521,
    "lng": -119.0698,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 30233,
    "id": 1840021842
  },
  {
    "city": "Colinas",
    "city_ascii": "Colinas",
    "lat": -6.0395,
    "lng": -44.24,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Maranhão",
    "capital": "",
    "population": 25158,
    "id": 1076657010
  },
  {
    "city": "Banqiao",
    "city_ascii": "Banqiao",
    "lat": 25.0143,
    "lng": 121.4672,
    "country": "Taiwan",
    "iso2": "TW",
    "iso3": "TWN",
    "admin_name": "New Taipei",
    "capital": "admin",
    "population": null,
    "id": 1158987347
  },
  {
    "city": "Westchester",
    "city_ascii": "Westchester",
    "lat": 25.7474,
    "lng": -80.3358,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 30210,
    "id": 1840014241
  },
  {
    "city": "Mount Hagen",
    "city_ascii": "Mount Hagen",
    "lat": -5.8667,
    "lng": 144.2167,
    "country": "Papua New Guinea",
    "iso2": "PG",
    "iso3": "PNG",
    "admin_name": "Western Highlands",
    "capital": "admin",
    "population": 29765,
    "id": 1598400611
  },
  {
    "city": "San Carlos",
    "city_ascii": "San Carlos",
    "lat": 37.4982,
    "lng": -122.268,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 30185,
    "id": 1840021550
  },
  {
    "city": "Savanna-la-Mar",
    "city_ascii": "Savanna-la-Mar",
    "lat": 18.2167,
    "lng": -78.1333,
    "country": "Jamaica",
    "iso2": "JM",
    "iso3": "JAM",
    "admin_name": "Westmoreland",
    "capital": "admin",
    "population": 30000,
    "id": 1388690384
  },
  {
    "city": "Vicente López",
    "city_ascii": "Vicente Lopez",
    "lat": -34.5333,
    "lng": -58.475,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires",
    "capital": "",
    "population": 22419,
    "id": 1032549688
  },
  {
    "city": "Bilston",
    "city_ascii": "Bilston",
    "lat": 52.566,
    "lng": -2.0728,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Wolverhampton",
    "capital": "",
    "population": 25576,
    "id": 1826253660
  },
  {
    "city": "Mutsamudu",
    "city_ascii": "Mutsamudu",
    "lat": -12.1667,
    "lng": 44.4,
    "country": "Comoros",
    "iso2": "KM",
    "iso3": "COM",
    "admin_name": "Anjouan",
    "capital": "admin",
    "population": 30000,
    "id": 1174036583
  },
  {
    "city": "Cahul",
    "city_ascii": "Cahul",
    "lat": 45.9075,
    "lng": 28.1944,
    "country": "Moldova",
    "iso2": "MD",
    "iso3": "MDA",
    "admin_name": "Cahul",
    "capital": "admin",
    "population": 30018,
    "id": 1498020356
  },
  {
    "city": "Felipe Carrillo Puerto",
    "city_ascii": "Felipe Carrillo Puerto",
    "lat": 19.5786,
    "lng": -88.0453,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Quintana Roo",
    "capital": "minor",
    "population": 25744,
    "id": 1484914299
  },
  {
    "city": "Golden Gate",
    "city_ascii": "Golden Gate",
    "lat": 26.1844,
    "lng": -81.7031,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 30104,
    "id": 1840014228
  },
  {
    "city": "Didcot",
    "city_ascii": "Didcot",
    "lat": 51.606,
    "lng": -1.241,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Oxfordshire",
    "capital": "",
    "population": 26920,
    "id": 1826515635
  },
  {
    "city": "Ojinaga",
    "city_ascii": "Ojinaga",
    "lat": 29.5644,
    "lng": -104.4164,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Chihuahua",
    "capital": "minor",
    "population": 28040,
    "id": 1484761843
  },
  {
    "city": "Shotley Bridge",
    "city_ascii": "Shotley Bridge",
    "lat": 54.87,
    "lng": -1.86,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Durham",
    "capital": "",
    "population": 27394,
    "id": 1826950423
  },
  {
    "city": "Stepney",
    "city_ascii": "Stepney",
    "lat": 51.5152,
    "lng": -0.0462,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Tower Hamlets",
    "capital": "",
    "population": 16238,
    "id": 1826642497
  },
  {
    "city": "Dübendorf",
    "city_ascii": "Dubendorf",
    "lat": 47.3981,
    "lng": 8.6194,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Zürich",
    "capital": "",
    "population": 28002,
    "id": 1756017706
  },
  {
    "city": "Granja",
    "city_ascii": "Granja",
    "lat": -3.1195,
    "lng": -40.84,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Ceará",
    "capital": "",
    "population": 25879,
    "id": 1076052166
  },
  {
    "city": "Billericay",
    "city_ascii": "Billericay",
    "lat": 51.628,
    "lng": 0.4184,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Essex",
    "capital": "",
    "population": 27998,
    "id": 1826245369
  },
  {
    "city": "Trujillo",
    "city_ascii": "Trujillo",
    "lat": 15.9167,
    "lng": -86,
    "country": "Honduras",
    "iso2": "HN",
    "iso3": "HND",
    "admin_name": "Colón",
    "capital": "admin",
    "population": 30000,
    "id": 1340756106
  },
  {
    "city": "Moyale",
    "city_ascii": "Moyale",
    "lat": 3.527,
    "lng": 39.056,
    "country": "Kenya",
    "iso2": "KE",
    "iso3": "KEN",
    "admin_name": "Marsabit",
    "capital": "",
    "population": 24837,
    "id": 1404689643
  },
  {
    "city": "Limeil-Brévannes",
    "city_ascii": "Limeil-Brevannes",
    "lat": 48.7464,
    "lng": 2.4883,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 27228,
    "id": 1250943881
  },
  {
    "city": "Treinta y Tres",
    "city_ascii": "Treinta y Tres",
    "lat": -33.2308,
    "lng": -54.3822,
    "country": "Uruguay",
    "iso2": "UY",
    "iso3": "URY",
    "admin_name": "Treinta y Tres",
    "capital": "admin",
    "population": 25477,
    "id": 1858879204
  },
  {
    "city": "Stung Treng",
    "city_ascii": "Stung Treng",
    "lat": 13.5167,
    "lng": 105.9667,
    "country": "Cambodia",
    "iso2": "KH",
    "iso3": "KHM",
    "admin_name": "Stung Treng",
    "capital": "admin",
    "population": 29665,
    "id": 1116286673
  },
  {
    "city": "Bournville",
    "city_ascii": "Bournville",
    "lat": 52.4299,
    "lng": -1.9355,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Birmingham",
    "capital": "",
    "population": 25938,
    "id": 1826130836
  },
  {
    "city": "Rahway",
    "city_ascii": "Rahway",
    "lat": 40.6077,
    "lng": -74.2807,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 29895,
    "id": 1840001091
  },
  {
    "city": "Dietikon",
    "city_ascii": "Dietikon",
    "lat": 47.4056,
    "lng": 8.4039,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Zürich",
    "capital": "",
    "population": 26936,
    "id": 1756546516
  },
  {
    "city": "Leyton",
    "city_ascii": "Leyton",
    "lat": 51.5606,
    "lng": -0.0155,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Waltham Forest",
    "capital": "",
    "population": 14184,
    "id": 1826843295
  },
  {
    "city": "Tralee",
    "city_ascii": "Tralee",
    "lat": 52.2675,
    "lng": -9.6962,
    "country": "Ireland",
    "iso2": "IE",
    "iso3": "IRL",
    "admin_name": "Kerry",
    "capital": "admin",
    "population": 26384,
    "id": 1372403494
  },
  {
    "city": "Newton Aycliffe",
    "city_ascii": "Newton Aycliffe",
    "lat": 54.62,
    "lng": -1.58,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Durham",
    "capital": "",
    "population": 26633,
    "id": 1826956989
  },
  {
    "city": "Rēzekne",
    "city_ascii": "Rezekne",
    "lat": 56.5067,
    "lng": 27.3308,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Rēzeknes Novads",
    "capital": "admin",
    "population": 28174,
    "id": 1428895373
  },
  {
    "city": "Ban Krathum Lom",
    "city_ascii": "Ban Krathum Lom",
    "lat": 13.7482,
    "lng": 100.3139,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Samut Sakhon",
    "capital": "",
    "population": 24237,
    "id": 1764120004
  },
  {
    "city": "Bussy-Saint-Georges",
    "city_ascii": "Bussy-Saint-Georges",
    "lat": 48.8422,
    "lng": 2.6983,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 27379,
    "id": 1250024212
  },
  {
    "city": "Walton upon Thames",
    "city_ascii": "Walton upon Thames",
    "lat": 51.3868,
    "lng": -0.4133,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Surrey",
    "capital": "",
    "population": 22834,
    "id": 1826528871
  },
  {
    "city": "Eastleigh",
    "city_ascii": "Eastleigh",
    "lat": 50.9667,
    "lng": -1.35,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hampshire",
    "capital": "",
    "population": 24011,
    "id": 1826595228
  },
  {
    "city": "Brunoy",
    "city_ascii": "Brunoy",
    "lat": 48.6979,
    "lng": 2.5044,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 25669,
    "id": 1250815725
  },
  {
    "city": "Kanchanaburi",
    "city_ascii": "Kanchanaburi",
    "lat": 14.0194,
    "lng": 99.5311,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Kanchanaburi",
    "capital": "admin",
    "population": 29581,
    "id": 1764455558
  },
  {
    "city": "Ağdaş",
    "city_ascii": "Agdas",
    "lat": 40.65,
    "lng": 47.4833,
    "country": "Azerbaijan",
    "iso2": "AZ",
    "iso3": "AZE",
    "admin_name": "Ağdaş",
    "capital": "admin",
    "population": 29600,
    "id": 1031916654
  },
  {
    "city": "Doba",
    "city_ascii": "Doba",
    "lat": 8.65,
    "lng": 16.85,
    "country": "Chad",
    "iso2": "TD",
    "iso3": "TCD",
    "admin_name": "Logone Oriental",
    "capital": "admin",
    "population": 29597,
    "id": 1148367091
  },
  {
    "city": "Bregenz",
    "city_ascii": "Bregenz",
    "lat": 47.505,
    "lng": 9.7492,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Vorarlberg",
    "capital": "admin",
    "population": 28697,
    "id": 1040174258
  },
  {
    "city": "Suisun City",
    "city_ascii": "Suisun City",
    "lat": 38.2473,
    "lng": -122.009,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 29663,
    "id": 1840028115
  },
  {
    "city": "Cheadle Hulme",
    "city_ascii": "Cheadle Hulme",
    "lat": 53.3761,
    "lng": -2.1897,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Stockport",
    "capital": "",
    "population": 26479,
    "id": 1826077491
  },
  {
    "city": "Nuwara Eliya",
    "city_ascii": "Nuwara Eliya",
    "lat": 6.9667,
    "lng": 80.7667,
    "country": "Sri Lanka",
    "iso2": "LK",
    "iso3": "LKA",
    "admin_name": "Central",
    "capital": "",
    "population": 27500,
    "id": 1144687416
  },
  {
    "city": "Frontera",
    "city_ascii": "Frontera",
    "lat": 18.5336,
    "lng": -92.6469,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Tabasco",
    "capital": "minor",
    "population": 22795,
    "id": 1484000527
  },
  {
    "city": "Katima Mulilo",
    "city_ascii": "Katima Mulilo",
    "lat": -17.5039,
    "lng": 24.275,
    "country": "Namibia",
    "iso2": "NA",
    "iso3": "NAM",
    "admin_name": "Zambezi",
    "capital": "admin",
    "population": 28699,
    "id": 1516068788
  },
  {
    "city": "Hindley",
    "city_ascii": "Hindley",
    "lat": 53.5355,
    "lng": -2.5658,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Wigan",
    "capital": "",
    "population": 25001,
    "id": 1826824753
  },
  {
    "city": "Chalatenango",
    "city_ascii": "Chalatenango",
    "lat": 14.072,
    "lng": -89.094,
    "country": "El Salvador",
    "iso2": "SV",
    "iso3": "SLV",
    "admin_name": "Chalatenango",
    "capital": "admin",
    "population": 29271,
    "id": 1222515741
  },
  {
    "city": "Mortsel",
    "city_ascii": "Mortsel",
    "lat": 51.1703,
    "lng": 4.4567,
    "country": "Belgium",
    "iso2": "BE",
    "iso3": "BEL",
    "admin_name": "Flanders",
    "capital": "",
    "population": 26157,
    "id": 1056283690
  },
  {
    "city": "Haverhill",
    "city_ascii": "Haverhill",
    "lat": 52.08,
    "lng": 0.44,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Suffolk",
    "capital": "",
    "population": 27041,
    "id": 1826015750
  },
  {
    "city": "Bắc Kạn",
    "city_ascii": "Bac Kan",
    "lat": 22.1333,
    "lng": 105.8333,
    "country": "Vietnam",
    "iso2": "VN",
    "iso3": "VNM",
    "admin_name": "Bắc Kạn",
    "capital": "admin",
    "population": 29227,
    "id": 1704000217
  },
  {
    "city": "Bačka Palanka",
    "city_ascii": "Backa Palanka",
    "lat": 45.2506,
    "lng": 19.3886,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Bačka Palanka",
    "capital": "admin",
    "population": 29449,
    "id": 1688918464
  },
  {
    "city": "Armentières",
    "city_ascii": "Armentieres",
    "lat": 50.6881,
    "lng": 2.8811,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Hauts-de-France",
    "capital": "",
    "population": 24882,
    "id": 1250737647
  },
  {
    "city": "Nogales",
    "city_ascii": "Nogales",
    "lat": 18.8167,
    "lng": -97.1667,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Veracruz",
    "capital": "minor",
    "population": 21113,
    "id": 1484829832
  },
  {
    "city": "Villeparisis",
    "city_ascii": "Villeparisis",
    "lat": 48.9503,
    "lng": 2.6025,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 26322,
    "id": 1250133123
  },
  {
    "city": "Northfield",
    "city_ascii": "Northfield",
    "lat": 52.408,
    "lng": -1.963,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Birmingham",
    "capital": "",
    "population": 25707,
    "id": 1826476476
  },
  {
    "city": "Burntwood",
    "city_ascii": "Burntwood",
    "lat": 52.6831,
    "lng": -1.92,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Staffordshire",
    "capital": "",
    "population": 26049,
    "id": 1826702051
  },
  {
    "city": "Westhoughton",
    "city_ascii": "Westhoughton",
    "lat": 53.549,
    "lng": -2.529,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Bolton",
    "capital": "",
    "population": 24974,
    "id": 1826134453
  },
  {
    "city": "Cenon",
    "city_ascii": "Cenon",
    "lat": 44.8578,
    "lng": -0.5317,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Nouvelle-Aquitaine",
    "capital": "",
    "population": 24729,
    "id": 1250260659
  },
  {
    "city": "Oak Park",
    "city_ascii": "Oak Park",
    "lat": 42.4649,
    "lng": -83.1824,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 29431,
    "id": 1840002424
  },
  {
    "city": "Linden",
    "city_ascii": "Linden",
    "lat": 6,
    "lng": -58.3,
    "country": "Guyana",
    "iso2": "GY",
    "iso3": "GUY",
    "admin_name": "Upper Demerara-Berbice",
    "capital": "admin",
    "population": 29298,
    "id": 1328531190
  },
  {
    "city": "Rocha",
    "city_ascii": "Rocha",
    "lat": -34.4833,
    "lng": -54.35,
    "country": "Uruguay",
    "iso2": "UY",
    "iso3": "URY",
    "admin_name": "Rocha",
    "capital": "admin",
    "population": 25422,
    "id": 1858936449
  },
  {
    "city": "Estoril",
    "city_ascii": "Estoril",
    "lat": 38.7057,
    "lng": -9.3977,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Lisboa",
    "capital": "",
    "population": 26399,
    "id": 1620528384
  },
  {
    "city": "Santa Bárbara",
    "city_ascii": "Santa Barbara",
    "lat": 14.9167,
    "lng": -88.2333,
    "country": "Honduras",
    "iso2": "HN",
    "iso3": "HND",
    "admin_name": "Santa Bárbara",
    "capital": "admin",
    "population": 29283,
    "id": 1340312026
  },
  {
    "city": "Khorugh",
    "city_ascii": "Khorugh",
    "lat": 37.4917,
    "lng": 71.5575,
    "country": "Tajikistan",
    "iso2": "TJ",
    "iso3": "TJK",
    "admin_name": "Kŭhistoni Badakhshon",
    "capital": "admin",
    "population": 28098,
    "id": 1762114045
  },
  {
    "city": "Colonia del Sacramento",
    "city_ascii": "Colonia del Sacramento",
    "lat": -34.4714,
    "lng": -57.8442,
    "country": "Uruguay",
    "iso2": "UY",
    "iso3": "URY",
    "admin_name": "Colonia",
    "capital": "admin",
    "population": 26231,
    "id": 1858338569
  },
  {
    "city": "Hillerød",
    "city_ascii": "Hillerod",
    "lat": 55.9333,
    "lng": 12.3167,
    "country": "Denmark",
    "iso2": "DK",
    "iso3": "DNK",
    "admin_name": "Hovedstaden",
    "capital": "admin",
    "population": 28313,
    "id": 1208278269
  },
  {
    "city": "Kamphaeng Phet",
    "city_ascii": "Kamphaeng Phet",
    "lat": 16.4811,
    "lng": 99.5222,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Kamphaeng Phet",
    "capital": "admin",
    "population": 29178,
    "id": 1764367755
  },
  {
    "city": "Tulum",
    "city_ascii": "Tulum",
    "lat": 20.2119,
    "lng": -87.4658,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Quintana Roo",
    "capital": "minor",
    "population": 18233,
    "id": 1484002889
  },
  {
    "city": "Cuerámaro",
    "city_ascii": "Cueramaro",
    "lat": 20.6258,
    "lng": -101.6739,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Guanajuato",
    "capital": "minor",
    "population": 25610,
    "id": 1484743958
  },
  {
    "city": "East Palo Alto",
    "city_ascii": "East Palo Alto",
    "lat": 37.4671,
    "lng": -122.1352,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 29314,
    "id": 1840020301
  },
  {
    "city": "Saint-Sébastien-sur-Loire",
    "city_ascii": "Saint-Sebastien-sur-Loire",
    "lat": 47.2081,
    "lng": -1.5014,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Pays de la Loire",
    "capital": "",
    "population": 26838,
    "id": 1250158180
  },
  {
    "city": "Port Chester",
    "city_ascii": "Port Chester",
    "lat": 41.0052,
    "lng": -73.668,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 29163,
    "id": 1840004941
  },
  {
    "city": "Samut Songkhram",
    "city_ascii": "Samut Songkhram",
    "lat": 13.4097,
    "lng": 100.0017,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Samut Songkhram",
    "capital": "admin",
    "population": 28493,
    "id": 1764305010
  },
  {
    "city": "West Falls Church",
    "city_ascii": "West Falls Church",
    "lat": 38.8648,
    "lng": -77.1878,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 29154,
    "id": 1840006117
  },
  {
    "city": "Leigh-on-Sea",
    "city_ascii": "Leigh-on-Sea",
    "lat": 51.5425,
    "lng": 0.6535,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Southend-on-Sea",
    "capital": "",
    "population": 22509,
    "id": 1826850305
  },
  {
    "city": "Penonomé",
    "city_ascii": "Penonome",
    "lat": 8.51,
    "lng": -80.36,
    "country": "Panama",
    "iso2": "PA",
    "iso3": "PAN",
    "admin_name": "Coclé",
    "capital": "admin",
    "population": 28766,
    "id": 1591602960
  },
  {
    "city": "Armavir",
    "city_ascii": "Armavir",
    "lat": 40.15,
    "lng": 44.04,
    "country": "Armenia",
    "iso2": "AM",
    "iso3": "ARM",
    "admin_name": "Armavir",
    "capital": "admin",
    "population": 28800,
    "id": 1051878002
  },
  {
    "city": "Qahā",
    "city_ascii": "Qaha",
    "lat": 30.2833,
    "lng": 31.2,
    "country": "Egypt",
    "iso2": "EG",
    "iso3": "EGY",
    "admin_name": "Al Qalyūbīyah",
    "capital": "",
    "population": 26694,
    "id": 1818255123
  },
  {
    "city": "Clydebank",
    "city_ascii": "Clydebank",
    "lat": 55.8997,
    "lng": -4.4006,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "West Dunbartonshire",
    "capital": "",
    "population": 25970,
    "id": 1826705684
  },
  {
    "city": "Taverny",
    "city_ascii": "Taverny",
    "lat": 49.0264,
    "lng": 2.2275,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 26441,
    "id": 1250852016
  },
  {
    "city": "Sèvres",
    "city_ascii": "Sevres",
    "lat": 48.8239,
    "lng": 2.2117,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 23507,
    "id": 1250240744
  },
  {
    "city": "Hunucmá",
    "city_ascii": "Hunucma",
    "lat": 21.0153,
    "lng": -89.8744,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Yucatán",
    "capital": "minor",
    "population": 24910,
    "id": 1484800428
  },
  {
    "city": "Vallauris",
    "city_ascii": "Vallauris",
    "lat": 43.5805,
    "lng": 7.0538,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Provence-Alpes-Côte d’Azur",
    "capital": "",
    "population": 26672,
    "id": 1250016351
  },
  {
    "city": "Villeneuve-la-Garenne",
    "city_ascii": "Villeneuve-la-Garenne",
    "lat": 48.9372,
    "lng": 2.3278,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 23771,
    "id": 1250937070
  },
  {
    "city": "Buri Ram",
    "city_ascii": "Buri Ram",
    "lat": 14.9942,
    "lng": 103.1022,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Buriram",
    "capital": "admin",
    "population": 28613,
    "id": 1764141031
  },
  {
    "city": "Brétigny-sur-Orge",
    "city_ascii": "Bretigny-sur-Orge",
    "lat": 48.6114,
    "lng": 2.3059,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 26528,
    "id": 1250662537
  },
  {
    "city": "Mons-en-Baroeul",
    "city_ascii": "Mons-en-Baroeul",
    "lat": 50.6369,
    "lng": 3.1103,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Hauts-de-France",
    "capital": "",
    "population": 20782,
    "id": 1250409231
  },
  {
    "city": "Ölgiy",
    "city_ascii": "Olgiy",
    "lat": 48.9656,
    "lng": 89.9632,
    "country": "Mongolia",
    "iso2": "MN",
    "iso3": "MNG",
    "admin_name": "Bayan-Ölgiy",
    "capital": "admin",
    "population": 28496,
    "id": 1496290857
  },
  {
    "city": "Dollis Hill",
    "city_ascii": "Dollis Hill",
    "lat": 51.5641,
    "lng": -0.2341,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Brent",
    "capital": "",
    "population": 14425,
    "id": 1826669520
  },
  {
    "city": "Wellington",
    "city_ascii": "Wellington",
    "lat": 52.7001,
    "lng": -2.5157,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Telford and Wrekin",
    "capital": "",
    "population": 25554,
    "id": 1826979862
  },
  {
    "city": "São Lourenço do Sul",
    "city_ascii": "Sao Lourenco do Sul",
    "lat": -31.37,
    "lng": -51.98,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Rio Grande do Sul",
    "capital": "",
    "population": 25340,
    "id": 1076758883
  },
  {
    "city": "Sucy-en-Brie",
    "city_ascii": "Sucy-en-Brie",
    "lat": 48.7697,
    "lng": 2.5228,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 26472,
    "id": 1250757045
  },
  {
    "city": "Pimenta Bueno",
    "city_ascii": "Pimenta Bueno",
    "lat": -11.64,
    "lng": -61.21,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Rondônia",
    "capital": "",
    "population": 25762,
    "id": 1076267246
  },
  {
    "city": "Saint-Gratien",
    "city_ascii": "Saint-Gratien",
    "lat": 48.9719,
    "lng": 2.2828,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 20682,
    "id": 1250437632
  },
  {
    "city": "Mendefera",
    "city_ascii": "Mendefera",
    "lat": 14.8833,
    "lng": 38.8167,
    "country": "Eritrea",
    "iso2": "ER",
    "iso3": "ERI",
    "admin_name": "Debub",
    "capital": "admin",
    "population": 28492,
    "id": 1232751882
  },
  {
    "city": "Consett",
    "city_ascii": "Consett",
    "lat": 54.85,
    "lng": -1.83,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Durham",
    "capital": "",
    "population": 24828,
    "id": 1826586872
  },
  {
    "city": "Droylsden",
    "city_ascii": "Droylsden",
    "lat": 53.4828,
    "lng": -2.1582,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Tameside",
    "capital": "",
    "population": 22689,
    "id": 1826178927
  },
  {
    "city": "Nutley",
    "city_ascii": "Nutley",
    "lat": 40.8192,
    "lng": -74.1571,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 28493,
    "id": 1840131593
  },
  {
    "city": "Otjiwarongo",
    "city_ascii": "Otjiwarongo",
    "lat": -20.4642,
    "lng": 16.6528,
    "country": "Namibia",
    "iso2": "NA",
    "iso3": "NAM",
    "admin_name": "Otjozondjupa",
    "capital": "admin",
    "population": 28249,
    "id": 1516719545
  },
  {
    "city": "Vichy",
    "city_ascii": "Vichy",
    "lat": 46.1278,
    "lng": 3.4267,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Auvergne-Rhône-Alpes",
    "capital": "minor",
    "population": 24166,
    "id": 1250084403
  },
  {
    "city": "Shamokin",
    "city_ascii": "Shamokin",
    "lat": 40.7883,
    "lng": -76.555,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 28457,
    "id": 1840000849
  },
  {
    "city": "Tapiales",
    "city_ascii": "Tapiales",
    "lat": -34.7058,
    "lng": -58.5047,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires",
    "capital": "",
    "population": 23586,
    "id": 1032831289
  },
  {
    "city": "Kočani",
    "city_ascii": "Kocani",
    "lat": 41.9167,
    "lng": 22.4125,
    "country": "Macedonia",
    "iso2": "MK",
    "iso3": "MKD",
    "admin_name": "Kočani",
    "capital": "admin",
    "population": 28330,
    "id": 1807422302
  },
  {
    "city": "Englewood",
    "city_ascii": "Englewood",
    "lat": 40.8917,
    "lng": -73.9736,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 28402,
    "id": 1840003546
  },
  {
    "city": "Mitú",
    "city_ascii": "Mitu",
    "lat": 1.2503,
    "lng": -70.235,
    "country": "Colombia",
    "iso2": "CO",
    "iso3": "COL",
    "admin_name": "Vaupés",
    "capital": "admin",
    "population": 28382,
    "id": 1170846096
  },
  {
    "city": "Pires do Rio",
    "city_ascii": "Pires do Rio",
    "lat": -17.2995,
    "lng": -48.28,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Goiás",
    "capital": "",
    "population": 24822,
    "id": 1076357135
  },
  {
    "city": "Lapa",
    "city_ascii": "Lapa",
    "lat": -25.76,
    "lng": -49.73,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Paraná",
    "capital": "",
    "population": 25621,
    "id": 1076904800
  },
  {
    "city": "Balham",
    "city_ascii": "Balham",
    "lat": 51.4434,
    "lng": -0.1525,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Wandsworth",
    "capital": "",
    "population": 14751,
    "id": 1826329398
  },
  {
    "city": "Harborne",
    "city_ascii": "Harborne",
    "lat": 52.46,
    "lng": -1.95,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Birmingham",
    "capital": "",
    "population": 23001,
    "id": 1826937093
  },
  {
    "city": "Shenley Brook End",
    "city_ascii": "Shenley Brook End",
    "lat": 52.009,
    "lng": -0.789,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Milton Keynes",
    "capital": "",
    "population": 25828,
    "id": 1826086234
  },
  {
    "city": "Winchester",
    "city_ascii": "Winchester",
    "lat": 36.1368,
    "lng": -115.1299,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Nevada",
    "capital": "",
    "population": 28331,
    "id": 1840033770
  },
  {
    "city": "Ouésso",
    "city_ascii": "Ouesso",
    "lat": 1.6167,
    "lng": 16.05,
    "country": "Congo (Brazzaville)",
    "iso2": "CG",
    "iso3": "COG",
    "admin_name": "Sangha",
    "capital": "admin",
    "population": 28320,
    "id": 1178364266
  },
  {
    "city": "Alençon",
    "city_ascii": "Alencon",
    "lat": 48.4306,
    "lng": 0.0931,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Normandie",
    "capital": "minor",
    "population": 25848,
    "id": 1250000395
  },
  {
    "city": "Burbank",
    "city_ascii": "Burbank",
    "lat": 41.7444,
    "lng": -87.7686,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 28289,
    "id": 1840007022
  },
  {
    "city": "Bloxwich",
    "city_ascii": "Bloxwich",
    "lat": 52.614,
    "lng": -2.004,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Walsall",
    "capital": "",
    "population": 25401,
    "id": 1826492004
  },
  {
    "city": "Caxito",
    "city_ascii": "Caxito",
    "lat": -8.6467,
    "lng": 13.6642,
    "country": "Angola",
    "iso2": "AO",
    "iso3": "AGO",
    "admin_name": "Bengo",
    "capital": "admin",
    "population": 28224,
    "id": 1024050171
  },
  {
    "city": "Upminster",
    "city_ascii": "Upminster",
    "lat": 51.5557,
    "lng": 0.2512,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Havering",
    "capital": "",
    "population": 25361,
    "id": 1826720044
  },
  {
    "city": "Vevey",
    "city_ascii": "Vevey",
    "lat": 46.4667,
    "lng": 6.85,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Vaud",
    "capital": "",
    "population": 19891,
    "id": 1756579162
  },
  {
    "city": "Itambé",
    "city_ascii": "Itambe",
    "lat": -15.2396,
    "lng": -40.63,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Bahia",
    "capital": "",
    "population": 23558,
    "id": 1076307665
  },
  {
    "city": "Tixtla de Guerrero",
    "city_ascii": "Tixtla de Guerrero",
    "lat": 17.5667,
    "lng": -99.4,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Guerrero",
    "capital": "minor",
    "population": 21720,
    "id": 1484176714
  },
  {
    "city": "Santa Maria da Vitória",
    "city_ascii": "Santa Maria da Vitoria",
    "lat": -13.39,
    "lng": -44.21,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Bahia",
    "capital": "",
    "population": 23488,
    "id": 1076607443
  },
  {
    "city": "Popondetta",
    "city_ascii": "Popondetta",
    "lat": -8.7656,
    "lng": 148.2347,
    "country": "Papua New Guinea",
    "iso2": "PG",
    "iso3": "PNG",
    "admin_name": "Northern",
    "capital": "admin",
    "population": 28198,
    "id": 1598887925
  },
  {
    "city": "Drexel Hill",
    "city_ascii": "Drexel Hill",
    "lat": 39.9495,
    "lng": -75.3039,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 28188,
    "id": 1840005550
  },
  {
    "city": "Littleover",
    "city_ascii": "Littleover",
    "lat": 52.906,
    "lng": -1.505,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Derby",
    "capital": "",
    "population": 23958,
    "id": 1826553059
  },
  {
    "city": "Le Bouscat",
    "city_ascii": "Le Bouscat",
    "lat": 44.8651,
    "lng": -0.5996,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Nouvelle-Aquitaine",
    "capital": "",
    "population": 23824,
    "id": 1250100380
  },
  {
    "city": "Fleetwood",
    "city_ascii": "Fleetwood",
    "lat": 53.923,
    "lng": -3.015,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Lancashire",
    "capital": "",
    "population": 25939,
    "id": 1826937482
  },
  {
    "city": "Highgate",
    "city_ascii": "Highgate",
    "lat": 51.5716,
    "lng": -0.1448,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Camden",
    "capital": "",
    "population": 10955,
    "id": 1826674604
  },
  {
    "city": "Market Harborough",
    "city_ascii": "Market Harborough",
    "lat": 52.4775,
    "lng": -0.9206,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Leicestershire",
    "capital": "",
    "population": 22911,
    "id": 1826292544
  },
  {
    "city": "Aldama",
    "city_ascii": "Aldama",
    "lat": 28.8386,
    "lng": -105.9111,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Chihuahua",
    "capital": "minor",
    "population": 24761,
    "id": 1484197521
  },
  {
    "city": "Champs-Sur-Marne",
    "city_ascii": "Champs-Sur-Marne",
    "lat": 48.8529,
    "lng": 2.6027,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 25041,
    "id": 1250001308
  },
  {
    "city": "Qazax",
    "city_ascii": "Qazax",
    "lat": 41.0933,
    "lng": 45.3661,
    "country": "Azerbaijan",
    "iso2": "AZ",
    "iso3": "AZE",
    "admin_name": "Qazax",
    "capital": "admin",
    "population": 28000,
    "id": 1031240282
  },
  {
    "city": "Deuil-la-Barre",
    "city_ascii": "Deuil-la-Barre",
    "lat": 48.9767,
    "lng": 2.3272,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 22306,
    "id": 1250595652
  },
  {
    "city": "Élancourt",
    "city_ascii": "Elancourt",
    "lat": 48.7847,
    "lng": 1.9589,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 25400,
    "id": 1250293935
  },
  {
    "city": "Losino-Petrovskiy",
    "city_ascii": "Losino-Petrovskiy",
    "lat": 55.8667,
    "lng": 38.2,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Moskovskaya Oblast’",
    "capital": "",
    "population": 25424,
    "id": 1643294187
  },
  {
    "city": "Oadby",
    "city_ascii": "Oadby",
    "lat": 52.5987,
    "lng": -1.0763,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Leicestershire",
    "capital": "",
    "population": 23849,
    "id": 1826422379
  },
  {
    "city": "Hamar",
    "city_ascii": "Hamar",
    "lat": 60.7945,
    "lng": 11.068,
    "country": "Norway",
    "iso2": "NO",
    "iso3": "NOR",
    "admin_name": "Hedmark",
    "capital": "admin",
    "population": 27947,
    "id": 1578111692
  },
  {
    "city": "Melrose",
    "city_ascii": "Melrose",
    "lat": 42.4556,
    "lng": -71.059,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 28016,
    "id": 1840000432
  },
  {
    "city": "Ocatlán",
    "city_ascii": "Ocatlan",
    "lat": 19.3167,
    "lng": -98.2283,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Tlaxcala",
    "capital": "",
    "population": 22082,
    "id": 1484715262
  },
  {
    "city": "Renens",
    "city_ascii": "Renens",
    "lat": 46.5353,
    "lng": 6.5897,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Vaud",
    "capital": "minor",
    "population": 20927,
    "id": 1756264043
  },
  {
    "city": "Erdington",
    "city_ascii": "Erdington",
    "lat": 52.5236,
    "lng": -1.8378,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Birmingham",
    "capital": "",
    "population": 22828,
    "id": 1826744205
  },
  {
    "city": "Mililani Town",
    "city_ascii": "Mililani Town",
    "lat": 21.4465,
    "lng": -158.0147,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Hawaii",
    "capital": "",
    "population": 27926,
    "id": 1840023249
  },
  {
    "city": "Le Grand-Quevilly",
    "city_ascii": "Le Grand-Quevilly",
    "lat": 49.4072,
    "lng": 1.0531,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Normandie",
    "capital": "",
    "population": 25698,
    "id": 1250398185
  },
  {
    "city": "Jonava",
    "city_ascii": "Jonava",
    "lat": 55.0722,
    "lng": 24.2806,
    "country": "Lithuania",
    "iso2": "LT",
    "iso3": "LTU",
    "admin_name": "Jonava",
    "capital": "admin",
    "population": 27804,
    "id": 1440040612
  },
  {
    "city": "Bongor",
    "city_ascii": "Bongor",
    "lat": 10.2806,
    "lng": 15.3722,
    "country": "Chad",
    "iso2": "TD",
    "iso3": "TCD",
    "admin_name": "Mayo-Kébbi Est",
    "capital": "admin",
    "population": 27770,
    "id": 1148833900
  },
  {
    "city": "Mongo",
    "city_ascii": "Mongo",
    "lat": 12.1837,
    "lng": 18.7,
    "country": "Chad",
    "iso2": "TD",
    "iso3": "TCD",
    "admin_name": "Guéra",
    "capital": "admin",
    "population": 27763,
    "id": 1148891648
  },
  {
    "city": "La Madeleine",
    "city_ascii": "La Madeleine",
    "lat": 50.6558,
    "lng": 3.071,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Hauts-de-France",
    "capital": "",
    "population": 21173,
    "id": 1250109731
  },
  {
    "city": "Penarth",
    "city_ascii": "Penarth",
    "lat": 51.43,
    "lng": -3.17,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Vale of Glamorgan, The",
    "capital": "",
    "population": 22083,
    "id": 1826447166
  },
  {
    "city": "Acomb",
    "city_ascii": "Acomb",
    "lat": 53.955,
    "lng": -1.126,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "York",
    "capital": "",
    "population": 22215,
    "id": 1826310349
  },
  {
    "city": "Dunleary",
    "city_ascii": "Dunleary",
    "lat": 53.3,
    "lng": -6.14,
    "country": "Ireland",
    "iso2": "IE",
    "iso3": "IRL",
    "admin_name": "Dún Laoghaire-Rathdown",
    "capital": "admin",
    "population": 23857,
    "id": 1372038958
  },
  {
    "city": "Koulikoro",
    "city_ascii": "Koulikoro",
    "lat": 12.8833,
    "lng": -7.55,
    "country": "Mali",
    "iso2": "ML",
    "iso3": "MLI",
    "admin_name": "Koulikoro",
    "capital": "admin",
    "population": 23919,
    "id": 1466411645
  },
  {
    "city": "Béthune",
    "city_ascii": "Bethune",
    "lat": 50.5303,
    "lng": 2.6408,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Hauts-de-France",
    "capital": "minor",
    "population": 24895,
    "id": 1250837552
  },
  {
    "city": "Neuilly-Plaisance",
    "city_ascii": "Neuilly-Plaisance",
    "lat": 48.8619,
    "lng": 2.5064,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 21150,
    "id": 1250086555
  },
  {
    "city": "Don Bosco",
    "city_ascii": "Don Bosco",
    "lat": -34.7,
    "lng": -58.2833,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires",
    "capital": "",
    "population": 20876,
    "id": 1032701626
  },
  {
    "city": "Tantoyuca",
    "city_ascii": "Tantoyuca",
    "lat": 21.35,
    "lng": -98.2333,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Veracruz",
    "capital": "minor",
    "population": 23893,
    "id": 1484205572
  },
  {
    "city": "Isla Vista",
    "city_ascii": "Isla Vista",
    "lat": 34.4144,
    "lng": -119.8581,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 27690,
    "id": 1840017887
  },
  {
    "city": "Portishead",
    "city_ascii": "Portishead",
    "lat": 51.484,
    "lng": -2.7626,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "North Somerset",
    "capital": "",
    "population": 25000,
    "id": 1826099661
  },
  {
    "city": "Tepetlaoxtoc",
    "city_ascii": "Tepetlaoxtoc",
    "lat": 19.5731,
    "lng": -98.8203,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "México",
    "capital": "minor",
    "population": 25523,
    "id": 1484140193
  },
  {
    "city": "Kičevo",
    "city_ascii": "Kicevo",
    "lat": 41.5142,
    "lng": 20.9631,
    "country": "Macedonia",
    "iso2": "MK",
    "iso3": "MKD",
    "admin_name": "Kičevo",
    "capital": "admin",
    "population": 27067,
    "id": 1807895120
  },
  {
    "city": "Koper",
    "city_ascii": "Koper",
    "lat": 45.5475,
    "lng": 13.7307,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Koper",
    "capital": "admin",
    "population": 25611,
    "id": 1705478862
  },
  {
    "city": "Rehoboth",
    "city_ascii": "Rehoboth",
    "lat": -23.3167,
    "lng": 17.0833,
    "country": "Namibia",
    "iso2": "NA",
    "iso3": "NAM",
    "admin_name": "Hardap",
    "capital": "",
    "population": 25219,
    "id": 1516928100
  },
  {
    "city": "Ciudad Altamirano",
    "city_ascii": "Ciudad Altamirano",
    "lat": 18.3583,
    "lng": -100.6667,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Guerrero",
    "capital": "minor",
    "population": 24533,
    "id": 1484274706
  },
  {
    "city": "Caversham",
    "city_ascii": "Caversham",
    "lat": 51.467,
    "lng": -0.973,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Reading",
    "capital": "",
    "population": 23885,
    "id": 1826445684
  },
  {
    "city": "Kings Norton",
    "city_ascii": "Kings Norton",
    "lat": 52.4072,
    "lng": -1.9272,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Birmingham",
    "capital": "",
    "population": 24380,
    "id": 1826743792
  },
  {
    "city": "Imperial Beach",
    "city_ascii": "Imperial Beach",
    "lat": 32.5689,
    "lng": -117.1184,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 27440,
    "id": 1840019353
  },
  {
    "city": "Magdalena de Kino",
    "city_ascii": "Magdalena de Kino",
    "lat": 30.6167,
    "lng": -111.05,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Sonora",
    "capital": "minor",
    "population": 23359,
    "id": 1484799240
  },
  {
    "city": "Al Ghayz̧ah",
    "city_ascii": "Al Ghayzah",
    "lat": 16.2394,
    "lng": 52.1638,
    "country": "Yemen",
    "iso2": "YE",
    "iso3": "YEM",
    "admin_name": "Al Mahrah",
    "capital": "admin",
    "population": 27404,
    "id": 1887000479
  },
  {
    "city": "Shtime",
    "city_ascii": "Shtime",
    "lat": 42.4333,
    "lng": 21.0333,
    "country": "Kosovo",
    "iso2": "XK",
    "iso3": "XKS",
    "admin_name": "Shtime",
    "capital": "admin",
    "population": 27324,
    "id": 1901598505
  },
  {
    "city": "Hackney",
    "city_ascii": "Hackney",
    "lat": 51.5414,
    "lng": -0.0266,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hackney",
    "capital": "",
    "population": 11734,
    "id": 1826413202
  },
  {
    "city": "Velenje",
    "city_ascii": "Velenje",
    "lat": 46.3667,
    "lng": 15.1167,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Velenje",
    "capital": "admin",
    "population": 25333,
    "id": 1705929589
  },
  {
    "city": "Bishopbriggs",
    "city_ascii": "Bishopbriggs",
    "lat": 55.9046,
    "lng": -4.225,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "East Dunbartonshire",
    "capital": "",
    "population": 23820,
    "id": 1826547421
  },
  {
    "city": "Piastów",
    "city_ascii": "Piastow",
    "lat": 52.1833,
    "lng": 20.85,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Mazowieckie",
    "capital": "",
    "population": 23332,
    "id": 1616118197
  },
  {
    "city": "Bergenfield",
    "city_ascii": "Bergenfield",
    "lat": 40.9236,
    "lng": -73.9983,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 27327,
    "id": 1840000893
  },
  {
    "city": "Thornaby on Tees",
    "city_ascii": "Thornaby on Tees",
    "lat": 54.5556,
    "lng": -1.3049,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Stockton-on-Tees",
    "capital": "",
    "population": 24741,
    "id": 1826706023
  },
  {
    "city": "Stalybridge",
    "city_ascii": "Stalybridge",
    "lat": 53.4834,
    "lng": -2.04,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Tameside",
    "capital": "",
    "population": 23731,
    "id": 1826524843
  },
  {
    "city": "Cerro Azul",
    "city_ascii": "Cerro Azul",
    "lat": 21.2,
    "lng": -97.7331,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Veracruz",
    "capital": "minor",
    "population": 23573,
    "id": 1484720981
  },
  {
    "city": "Kingswinford",
    "city_ascii": "Kingswinford",
    "lat": 52.4981,
    "lng": -2.1657,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Staffordshire",
    "capital": "",
    "population": 25191,
    "id": 1826547485
  },
  {
    "city": "Easton",
    "city_ascii": "Easton",
    "lat": 40.6858,
    "lng": -75.2209,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 27189,
    "id": 1840000970
  },
  {
    "city": "Priboj",
    "city_ascii": "Priboj",
    "lat": 43.5816,
    "lng": 19.5273,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Priboj",
    "capital": "admin",
    "population": 27133,
    "id": 1688522811
  },
  {
    "city": "Xoxocotla",
    "city_ascii": "Xoxocotla",
    "lat": 18.685,
    "lng": -99.2439,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Morelos",
    "capital": "",
    "population": 21074,
    "id": 1484012470
  },
  {
    "city": "West Whittier-Los Nietos",
    "city_ascii": "West Whittier-Los Nietos",
    "lat": 33.9759,
    "lng": -118.0689,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 27144,
    "id": 1840074745
  },
  {
    "city": "Indiana",
    "city_ascii": "Indiana",
    "lat": 40.622,
    "lng": -79.1552,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 27142,
    "id": 1840000997
  },
  {
    "city": "Farnley",
    "city_ascii": "Farnley",
    "lat": 53.7876,
    "lng": -1.6159,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Leeds",
    "capital": "",
    "population": 24213,
    "id": 1826992459
  },
  {
    "city": "Sensuntepeque",
    "city_ascii": "Sensuntepeque",
    "lat": 13.88,
    "lng": -88.63,
    "country": "El Salvador",
    "iso2": "SV",
    "iso3": "SLV",
    "admin_name": "Cabañas",
    "capital": "admin",
    "population": 26989,
    "id": 1222098918
  },
  {
    "city": "Orly",
    "city_ascii": "Orly",
    "lat": 48.7439,
    "lng": 2.3928,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 23801,
    "id": 1250660007
  },
  {
    "city": "Siliana",
    "city_ascii": "Siliana",
    "lat": 36.0833,
    "lng": 9.3833,
    "country": "Tunisia",
    "iso2": "TN",
    "iso3": "TUN",
    "admin_name": "Siliana",
    "capital": "admin",
    "population": 26960,
    "id": 1788057426
  },
  {
    "city": "Catió",
    "city_ascii": "Catio",
    "lat": 11.2833,
    "lng": -15.25,
    "country": "Guinea-Bissau",
    "iso2": "GW",
    "iso3": "GNB",
    "admin_name": "Tombali",
    "capital": "admin",
    "population": 26999,
    "id": 1624933572
  },
  {
    "city": "La Unión",
    "city_ascii": "La Union",
    "lat": 13.332,
    "lng": -87.839,
    "country": "El Salvador",
    "iso2": "SV",
    "iso3": "SLV",
    "admin_name": "La Unión",
    "capital": "admin",
    "population": 26807,
    "id": 1222307415
  },
  {
    "city": "Chapala",
    "city_ascii": "Chapala",
    "lat": 20.2933,
    "lng": -103.1897,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Jalisco",
    "capital": "minor",
    "population": 21596,
    "id": 1484011650
  },
  {
    "city": "Weingarten",
    "city_ascii": "Weingarten",
    "lat": 47.8078,
    "lng": 9.6417,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 24943,
    "id": 1276346018
  },
  {
    "city": "Maywood",
    "city_ascii": "Maywood",
    "lat": 33.9885,
    "lng": -118.1877,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 26973,
    "id": 1840020497
  },
  {
    "city": "Cormeilles-en-Parisis",
    "city_ascii": "Cormeilles-en-Parisis",
    "lat": 48.9739,
    "lng": 2.2014,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 24161,
    "id": 1250599649
  },
  {
    "city": "Belmont",
    "city_ascii": "Belmont",
    "lat": 37.5154,
    "lng": -122.2955,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 26941,
    "id": 1840018924
  },
  {
    "city": "Reisterstown",
    "city_ascii": "Reisterstown",
    "lat": 39.4552,
    "lng": -76.8144,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 26933,
    "id": 1840005689
  },
  {
    "city": "Alfreton",
    "city_ascii": "Alfreton",
    "lat": 53.097,
    "lng": -1.38,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Derbyshire",
    "capital": "",
    "population": 22302,
    "id": 1826000016
  },
  {
    "city": "Maisons-Laffitte",
    "city_ascii": "Maisons-Laffitte",
    "lat": 48.9469,
    "lng": 2.1456,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 23669,
    "id": 1250804380
  },
  {
    "city": "Atlatlahucan",
    "city_ascii": "Atlatlahucan",
    "lat": 18.935,
    "lng": -98.9,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Morelos",
    "capital": "minor",
    "population": 22079,
    "id": 1484784884
  },
  {
    "city": "Rolim de Moura",
    "city_ascii": "Rolim de Moura",
    "lat": -11.7302,
    "lng": -61.7806,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Rondônia",
    "capital": "",
    "population": 24516,
    "id": 1076556836
  },
  {
    "city": "Elgin",
    "city_ascii": "Elgin",
    "lat": 57.65,
    "lng": -3.315,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Moray",
    "capital": "",
    "population": 24320,
    "id": 1826386055
  },
  {
    "city": "Itaberaí",
    "city_ascii": "Itaberai",
    "lat": -16.02,
    "lng": -49.81,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Goiás",
    "capital": "",
    "population": 22129,
    "id": 1076044452
  },
  {
    "city": "Kościan",
    "city_ascii": "Koscian",
    "lat": 52.0833,
    "lng": 16.65,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Wielkopolskie",
    "capital": "minor",
    "population": 24086,
    "id": 1616047191
  },
  {
    "city": "Nola",
    "city_ascii": "Nola",
    "lat": 3.5337,
    "lng": 16.0666,
    "country": "Central African Republic",
    "iso2": "CF",
    "iso3": "CAF",
    "admin_name": "Sangha-Mbaéré",
    "capital": "admin",
    "population": 26809,
    "id": 1140236347
  },
  {
    "city": "La Valette-du-Var",
    "city_ascii": "La Valette-du-Var",
    "lat": 43.1383,
    "lng": 5.9831,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Provence-Alpes-Côte d’Azur",
    "capital": "",
    "population": 23884,
    "id": 1250438884
  },
  {
    "city": "Kiryas Joel",
    "city_ascii": "Kiryas Joel",
    "lat": 41.3406,
    "lng": -74.166,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 26813,
    "id": 1840004872
  },
  {
    "city": "Lemon Grove",
    "city_ascii": "Lemon Grove",
    "lat": 32.7331,
    "lng": -117.0344,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 26811,
    "id": 1840020621
  },
  {
    "city": "Lindenhurst",
    "city_ascii": "Lindenhurst",
    "lat": 40.6858,
    "lng": -73.371,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 26801,
    "id": 1840005122
  },
  {
    "city": "Meoqui",
    "city_ascii": "Meoqui",
    "lat": 28.2722,
    "lng": -105.4819,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Chihuahua",
    "capital": "minor",
    "population": 22574,
    "id": 1484867296
  },
  {
    "city": "Felixstowe",
    "city_ascii": "Felixstowe",
    "lat": 51.9639,
    "lng": 1.3515,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Suffolk",
    "capital": "",
    "population": 23689,
    "id": 1826193047
  },
  {
    "city": "Ocampo",
    "city_ascii": "Ocampo",
    "lat": 21.65,
    "lng": -101.5,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Guanajuato",
    "capital": "minor",
    "population": 23500,
    "id": 1484510581
  },
  {
    "city": "Bayanhongor",
    "city_ascii": "Bayanhongor",
    "lat": 46.1944,
    "lng": 100.7181,
    "country": "Mongolia",
    "iso2": "MN",
    "iso3": "MNG",
    "admin_name": "Bayanhongor",
    "capital": "admin",
    "population": 26252,
    "id": 1496815184
  },
  {
    "city": "Blenheim",
    "city_ascii": "Blenheim",
    "lat": -41.5167,
    "lng": 173.95,
    "country": "New Zealand",
    "iso2": "NZ",
    "iso3": "NZL",
    "admin_name": "Marlborough",
    "capital": "admin",
    "population": 26400,
    "id": 1554001426
  },
  {
    "city": "Moyo",
    "city_ascii": "Moyo",
    "lat": 3.6504,
    "lng": 31.72,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Moyo",
    "capital": "admin",
    "population": 22434,
    "id": 1800294178
  },
  {
    "city": "Camborne",
    "city_ascii": "Camborne",
    "lat": 50.213,
    "lng": -5.3,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Cornwall",
    "capital": "",
    "population": 21600,
    "id": 1826570775
  },
  {
    "city": "Voinjama",
    "city_ascii": "Voinjama",
    "lat": 8.4167,
    "lng": -9.75,
    "country": "Liberia",
    "iso2": "LR",
    "iso3": "LBR",
    "admin_name": "Lofa",
    "capital": "admin",
    "population": 26594,
    "id": 1430858471
  },
  {
    "city": "Nakhon Phanom",
    "city_ascii": "Nakhon Phanom",
    "lat": 17.4069,
    "lng": 104.7808,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Nakhon Phanom",
    "capital": "admin",
    "population": 26437,
    "id": 1764103547
  },
  {
    "city": "Atherton",
    "city_ascii": "Atherton",
    "lat": 53.523,
    "lng": -2.495,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Wigan",
    "capital": "",
    "population": 22000,
    "id": 1826022663
  },
  {
    "city": "Meyrin",
    "city_ascii": "Meyrin",
    "lat": 46.2322,
    "lng": 6.0791,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Genève",
    "capital": "",
    "population": 24144,
    "id": 1756962454
  },
  {
    "city": "Utena",
    "city_ascii": "Utena",
    "lat": 55.5,
    "lng": 25.6028,
    "country": "Lithuania",
    "iso2": "LT",
    "iso3": "LTU",
    "admin_name": "Utena",
    "capital": "admin",
    "population": 26491,
    "id": 1440298742
  },
  {
    "city": "Stanmore",
    "city_ascii": "Stanmore",
    "lat": 51.618,
    "lng": -0.314,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Harrow",
    "capital": "",
    "population": 23700,
    "id": 1826094735
  },
  {
    "city": "Valmiera",
    "city_ascii": "Valmiera",
    "lat": 57.5381,
    "lng": 25.4231,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Valmiera",
    "capital": "admin",
    "population": 25093,
    "id": 1428524787
  },
  {
    "city": "Suphan Buri",
    "city_ascii": "Suphan Buri",
    "lat": 14.4675,
    "lng": 100.1169,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Suphan Buri",
    "capital": "admin",
    "population": 26164,
    "id": 1764759549
  },
  {
    "city": "Failsworth",
    "city_ascii": "Failsworth",
    "lat": 53.5102,
    "lng": -2.1575,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Oldham",
    "capital": "",
    "population": 20680,
    "id": 1826332679
  },
  {
    "city": "Torcy",
    "city_ascii": "Torcy",
    "lat": 48.8502,
    "lng": 2.6508,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "minor",
    "population": 22568,
    "id": 1250780004
  },
  {
    "city": "Dongola",
    "city_ascii": "Dongola",
    "lat": 19.1769,
    "lng": 30.4839,
    "country": "Sudan",
    "iso2": "SD",
    "iso3": "SDN",
    "admin_name": "Northern",
    "capital": "admin",
    "population": 26404,
    "id": 1729300155
  },
  {
    "city": "Mendi",
    "city_ascii": "Mendi",
    "lat": -6.1478,
    "lng": 143.6572,
    "country": "Papua New Guinea",
    "iso2": "PG",
    "iso3": "PNG",
    "admin_name": "Southern Highlands",
    "capital": "admin",
    "population": 26252,
    "id": 1598467297
  },
  {
    "city": "Viseu",
    "city_ascii": "Viseu",
    "lat": -1.1965,
    "lng": -46.14,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Pará",
    "capital": "",
    "population": 18958,
    "id": 1076429547
  },
  {
    "city": "Ulaangom",
    "city_ascii": "Ulaangom",
    "lat": 49.9833,
    "lng": 92.0667,
    "country": "Mongolia",
    "iso2": "MN",
    "iso3": "MNG",
    "admin_name": "Uvs",
    "capital": "admin",
    "population": 26319,
    "id": 1496088351
  },
  {
    "city": "University Park",
    "city_ascii": "University Park",
    "lat": 25.7469,
    "lng": -80.3684,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 26317,
    "id": 1840029013
  },
  {
    "city": "Rodez",
    "city_ascii": "Rodez",
    "lat": 44.3506,
    "lng": 2.575,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Occitanie",
    "capital": "minor",
    "population": 24057,
    "id": 1250807687
  },
  {
    "city": "Whitefield",
    "city_ascii": "Whitefield",
    "lat": 53.5521,
    "lng": -2.2992,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Bury",
    "capital": "",
    "population": 23283,
    "id": 1826701535
  },
  {
    "city": "Apodi",
    "city_ascii": "Apodi",
    "lat": -5.65,
    "lng": -37.8,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Rio Grande do Norte",
    "capital": "",
    "population": 16757,
    "id": 1076092856
  },
  {
    "city": "Belper",
    "city_ascii": "Belper",
    "lat": 53.029,
    "lng": -1.475,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Derbyshire",
    "capital": "",
    "population": 21822,
    "id": 1826949815
  },
  {
    "city": "Oxkutzkab",
    "city_ascii": "Oxkutzkab",
    "lat": 20.3028,
    "lng": -89.4183,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Yucatán",
    "capital": "",
    "population": 23096,
    "id": 1484480343
  },
  {
    "city": "Soledad",
    "city_ascii": "Soledad",
    "lat": 36.4432,
    "lng": -121.3426,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 26207,
    "id": 1840021635
  },
  {
    "city": "Gjirokastër",
    "city_ascii": "Gjirokaster",
    "lat": 40.0758,
    "lng": 20.1389,
    "country": "Albania",
    "iso2": "AL",
    "iso3": "ALB",
    "admin_name": "Gjirokastër",
    "capital": "admin",
    "population": 25301,
    "id": 1008368062
  },
  {
    "city": "Lormont",
    "city_ascii": "Lormont",
    "lat": 44.8792,
    "lng": -0.5217,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Nouvelle-Aquitaine",
    "capital": "",
    "population": 23538,
    "id": 1250006251
  },
  {
    "city": "Chaville",
    "city_ascii": "Chaville",
    "lat": 48.8086,
    "lng": 2.1886,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 20520,
    "id": 1250122168
  },
  {
    "city": "Malinalco",
    "city_ascii": "Malinalco",
    "lat": 18.9483,
    "lng": -99.4947,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "México",
    "capital": "minor",
    "population": 22970,
    "id": 1484454332
  },
  {
    "city": "Cliffside Park",
    "city_ascii": "Cliffside Park",
    "lat": 40.8221,
    "lng": -73.988,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 26133,
    "id": 1840003537
  },
  {
    "city": "Eirunepé",
    "city_ascii": "Eirunepe",
    "lat": -6.66,
    "lng": -69.8738,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Amazonas",
    "capital": "",
    "population": 21750,
    "id": 1076884388
  },
  {
    "city": "Svay Rieng",
    "city_ascii": "Svay Rieng",
    "lat": 11.0878,
    "lng": 105.7994,
    "country": "Cambodia",
    "iso2": "KH",
    "iso3": "KHM",
    "admin_name": "Svay Rieng",
    "capital": "admin",
    "population": 23956,
    "id": 1116432743
  },
  {
    "city": "Leisure City",
    "city_ascii": "Leisure City",
    "lat": 25.4937,
    "lng": -80.4369,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 26080,
    "id": 1840014245
  },
  {
    "city": "Sumbe",
    "city_ascii": "Sumbe",
    "lat": -11.2052,
    "lng": 13.8417,
    "country": "Angola",
    "iso2": "AO",
    "iso3": "AGO",
    "admin_name": "Kwanza Sul",
    "capital": "admin",
    "population": 26000,
    "id": 1024297937
  },
  {
    "city": "Bajina Bašta",
    "city_ascii": "Bajina Basta",
    "lat": 43.9731,
    "lng": 19.5597,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Bajina Bašta",
    "capital": "admin",
    "population": 26022,
    "id": 1688643521
  },
  {
    "city": "Heroica Ciudad de Tlaxiaco",
    "city_ascii": "Heroica Ciudad de Tlaxiaco",
    "lat": 17.2704,
    "lng": -97.68,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Oaxaca",
    "capital": "minor",
    "population": 21391,
    "id": 1484201826
  },
  {
    "city": "Belmont",
    "city_ascii": "Belmont",
    "lat": 42.396,
    "lng": -71.1795,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 26043,
    "id": 1840053474
  },
  {
    "city": "Clevedon",
    "city_ascii": "Clevedon",
    "lat": 51.438,
    "lng": -2.854,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "North Somerset",
    "capital": "",
    "population": 21281,
    "id": 1826157550
  },
  {
    "city": "Sainte-Thérèse",
    "city_ascii": "Sainte-Therese",
    "lat": 45.6333,
    "lng": -73.85,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 26025,
    "id": 1124190411
  },
  {
    "city": "Hovd",
    "city_ascii": "Hovd",
    "lat": 48.0167,
    "lng": 91.5667,
    "country": "Mongolia",
    "iso2": "MN",
    "iso3": "MNG",
    "admin_name": "Hovd",
    "capital": "admin",
    "population": 26023,
    "id": 1496382421
  },
  {
    "city": "Le Petit-Quevilly",
    "city_ascii": "Le Petit-Quevilly",
    "lat": 49.4311,
    "lng": 1.0539,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Normandie",
    "capital": "",
    "population": 21995,
    "id": 1250526109
  },
  {
    "city": "Castaños",
    "city_ascii": "Castanos",
    "lat": 26.7833,
    "lng": -101.4167,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Coahuila de Zaragoza",
    "capital": "minor",
    "population": 22401,
    "id": 1484472740
  },
  {
    "city": "Fontaine",
    "city_ascii": "Fontaine",
    "lat": 45.1939,
    "lng": 5.6856,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Auvergne-Rhône-Alpes",
    "capital": "",
    "population": 22523,
    "id": 1250760335
  },
  {
    "city": "Cəlilabad",
    "city_ascii": "Calilabad",
    "lat": 39.2042,
    "lng": 48.4958,
    "country": "Azerbaijan",
    "iso2": "AZ",
    "iso3": "AZE",
    "admin_name": "Cəlilabad",
    "capital": "admin",
    "population": 25846,
    "id": 1031242117
  },
  {
    "city": "Litherland",
    "city_ascii": "Litherland",
    "lat": 53.4727,
    "lng": -2.999,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Sefton",
    "capital": "",
    "population": 22242,
    "id": 1826594706
  },
  {
    "city": "Chandlers Ford",
    "city_ascii": "Chandlers Ford",
    "lat": 50.984,
    "lng": -1.3792,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hampshire",
    "capital": "",
    "population": 21436,
    "id": 1826991193
  },
  {
    "city": "Nangō",
    "city_ascii": "Nango",
    "lat": 34.6003,
    "lng": 135.6956,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Nara",
    "capital": "",
    "population": 23315,
    "id": 1392067385
  },
  {
    "city": "Jardim",
    "city_ascii": "Jardim",
    "lat": -21.4799,
    "lng": -56.15,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Mato Grosso do Sul",
    "capital": "",
    "population": 23780,
    "id": 1076077588
  },
  {
    "city": "Amnat Charoen",
    "city_ascii": "Amnat Charoen",
    "lat": 15.85,
    "lng": 104.6333,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Amnat Charoen",
    "capital": "admin",
    "population": 25858,
    "id": 1764038011
  },
  {
    "city": "Morsang-sur-Orge",
    "city_ascii": "Morsang-sur-Orge",
    "lat": 48.6618,
    "lng": 2.3461,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 20909,
    "id": 1250633800
  },
  {
    "city": "Montgeron",
    "city_ascii": "Montgeron",
    "lat": 48.7039,
    "lng": 2.4605,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 23775,
    "id": 1250766213
  },
  {
    "city": "Itupiranga",
    "city_ascii": "Itupiranga",
    "lat": -5.12,
    "lng": -49.3,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Pará",
    "capital": "",
    "population": 21301,
    "id": 1076086545
  },
  {
    "city": "Croix",
    "city_ascii": "Croix",
    "lat": 50.6781,
    "lng": 3.1508,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Hauts-de-France",
    "capital": "",
    "population": 21041,
    "id": 1250218619
  },
  {
    "city": "Penwortham",
    "city_ascii": "Penwortham",
    "lat": 53.74,
    "lng": -2.72,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Lancashire",
    "capital": "",
    "population": 23047,
    "id": 1826104107
  },
  {
    "city": "Szczytno",
    "city_ascii": "Szczytno",
    "lat": 53.5628,
    "lng": 20.9853,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Warmińsko-Mazurskie",
    "capital": "minor",
    "population": 23500,
    "id": 1616017427
  },
  {
    "city": "El Tarf",
    "city_ascii": "El Tarf",
    "lat": 36.7669,
    "lng": 8.3136,
    "country": "Algeria",
    "iso2": "DZ",
    "iso3": "DZA",
    "admin_name": "El Tarf",
    "capital": "admin",
    "population": 25594,
    "id": 1012205687
  },
  {
    "city": "Atoyac de Álvarez",
    "city_ascii": "Atoyac de Alvarez",
    "lat": 17.2,
    "lng": -100.4333,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Guerrero",
    "capital": "minor",
    "population": 20515,
    "id": 1484657833
  },
  {
    "city": "Nar’yan-Mar",
    "city_ascii": "Nar'yan-Mar",
    "lat": 67.6378,
    "lng": 53.0067,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Nenetskiy Avtonomnyy Okrug",
    "capital": "admin",
    "population": 24775,
    "id": 1643685127
  },
  {
    "city": "Kidsgrove",
    "city_ascii": "Kidsgrove",
    "lat": 53.0874,
    "lng": -2.2478,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Staffordshire",
    "capital": "",
    "population": 23756,
    "id": 1826529951
  },
  {
    "city": "Ewa Gentry",
    "city_ascii": "Ewa Gentry",
    "lat": 21.3344,
    "lng": -158.0262,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Hawaii",
    "capital": "",
    "population": 25752,
    "id": 1840029577
  },
  {
    "city": "Onex",
    "city_ascii": "Onex",
    "lat": 46.1833,
    "lng": 6.1,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Genève",
    "capital": "",
    "population": 18977,
    "id": 1756617916
  },
  {
    "city": "San Juan Zitlaltepec",
    "city_ascii": "San Juan Zitlaltepec",
    "lat": 19.7167,
    "lng": -99.05,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Morelos",
    "capital": "",
    "population": 19600,
    "id": 1484882196
  },
  {
    "city": "San Marcos",
    "city_ascii": "San Marcos",
    "lat": 14.966,
    "lng": -91.8,
    "country": "Guatemala",
    "iso2": "GT",
    "iso3": "GTM",
    "admin_name": "San Marcos",
    "capital": "admin",
    "population": 25088,
    "id": 1320960166
  },
  {
    "city": "Baturité",
    "city_ascii": "Baturite",
    "lat": -4.3296,
    "lng": -38.88,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Ceará",
    "capital": "",
    "population": 22417,
    "id": 1076144455
  },
  {
    "city": "Zwedru",
    "city_ascii": "Zwedru",
    "lat": 6.0704,
    "lng": -8.13,
    "country": "Liberia",
    "iso2": "LR",
    "iso3": "LBR",
    "admin_name": "Grand Gedeh",
    "capital": "admin",
    "population": 25678,
    "id": 1430130578
  },
  {
    "city": "Decatur",
    "city_ascii": "Decatur",
    "lat": 33.7711,
    "lng": -84.2968,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 25696,
    "id": 1840014777
  },
  {
    "city": "Tipasa",
    "city_ascii": "Tipasa",
    "lat": 36.5942,
    "lng": 2.443,
    "country": "Algeria",
    "iso2": "DZ",
    "iso3": "DZA",
    "admin_name": "Tipaza",
    "capital": "admin",
    "population": 25225,
    "id": 1012866308
  },
  {
    "city": "Kafr Shukr",
    "city_ascii": "Kafr Shukr",
    "lat": 30.547,
    "lng": 31.2673,
    "country": "Egypt",
    "iso2": "EG",
    "iso3": "EGY",
    "admin_name": "Al Minūfīyah",
    "capital": "",
    "population": 23390,
    "id": 1818036288
  },
  {
    "city": "South Bradenton",
    "city_ascii": "South Bradenton",
    "lat": 27.4612,
    "lng": -82.5821,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 25646,
    "id": 1840029076
  },
  {
    "city": "Laurel",
    "city_ascii": "Laurel",
    "lat": 39.095,
    "lng": -76.8622,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 25631,
    "id": 1840005976
  },
  {
    "city": "Guiseley",
    "city_ascii": "Guiseley",
    "lat": 53.875,
    "lng": -1.706,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Leeds",
    "capital": "",
    "population": 22347,
    "id": 1826210408
  },
  {
    "city": "Fray Bentos",
    "city_ascii": "Fray Bentos",
    "lat": -33.1333,
    "lng": -58.3,
    "country": "Uruguay",
    "iso2": "UY",
    "iso3": "URY",
    "admin_name": "Río Negro",
    "capital": "admin",
    "population": 24406,
    "id": 1858727525
  },
  {
    "city": "Loos",
    "city_ascii": "Loos",
    "lat": 50.6128,
    "lng": 3.0144,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Hauts-de-France",
    "capital": "",
    "population": 22233,
    "id": 1250834480
  },
  {
    "city": "Northwood",
    "city_ascii": "Northwood",
    "lat": 51.601,
    "lng": -0.4176,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hertfordshire",
    "capital": "",
    "population": 22047,
    "id": 1826980965
  },
  {
    "city": "Longjumeau",
    "city_ascii": "Longjumeau",
    "lat": 48.6943,
    "lng": 2.2958,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 21221,
    "id": 1250686935
  },
  {
    "city": "Artashat",
    "city_ascii": "Artashat",
    "lat": 39.9539,
    "lng": 44.5506,
    "country": "Armenia",
    "iso2": "AM",
    "iso3": "ARM",
    "admin_name": "Ararat",
    "capital": "admin",
    "population": 25400,
    "id": 1051969157
  },
  {
    "city": "Swinton",
    "city_ascii": "Swinton",
    "lat": 53.5122,
    "lng": -2.3412,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Salford",
    "capital": "",
    "population": 22931,
    "id": 1826572295
  },
  {
    "city": "El Cerrito",
    "city_ascii": "El Cerrito",
    "lat": 37.9197,
    "lng": -122.3025,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 25508,
    "id": 1840020275
  },
  {
    "city": "Aţ Ţafīlah",
    "city_ascii": "At Tafilah",
    "lat": 30.8375,
    "lng": 35.6042,
    "country": "Jordan",
    "iso2": "JO",
    "iso3": "JOR",
    "admin_name": "Aţ Ţafīlah",
    "capital": "admin",
    "population": 25429,
    "id": 1400134505
  },
  {
    "city": "Penistone",
    "city_ascii": "Penistone",
    "lat": 53.525,
    "lng": -1.629,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Barnsley",
    "capital": "",
    "population": 22909,
    "id": 1826205951
  },
  {
    "city": "Kérkyra",
    "city_ascii": "Kerkyra",
    "lat": 39.6239,
    "lng": 19.9214,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Ionía Nísia",
    "capital": "admin",
    "population": 24838,
    "id": 1300093403
  },
  {
    "city": "Holborn",
    "city_ascii": "Holborn",
    "lat": 51.5172,
    "lng": -0.1182,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Camden",
    "capital": "",
    "population": 13023,
    "id": 1826657058
  },
  {
    "city": "Ossett",
    "city_ascii": "Ossett",
    "lat": 53.68,
    "lng": -1.58,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Wakefield",
    "capital": "",
    "population": 21231,
    "id": 1826407870
  },
  {
    "city": "Dronfield",
    "city_ascii": "Dronfield",
    "lat": 53.3024,
    "lng": -1.4664,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Derbyshire",
    "capital": "",
    "population": 21261,
    "id": 1826648427
  },
  {
    "city": "Sainte-Foy-lès-Lyon",
    "city_ascii": "Sainte-Foy-les-Lyon",
    "lat": 45.73,
    "lng": 4.8,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Auvergne-Rhône-Alpes",
    "capital": "",
    "population": 22012,
    "id": 1250537092
  },
  {
    "city": "San Lorenzo",
    "city_ascii": "San Lorenzo",
    "lat": 37.6737,
    "lng": -122.1349,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 25388,
    "id": 1840018912
  },
  {
    "city": "Canelones",
    "city_ascii": "Canelones",
    "lat": -34.538,
    "lng": -56.284,
    "country": "Uruguay",
    "iso2": "UY",
    "iso3": "URY",
    "admin_name": "Canelones",
    "capital": "admin",
    "population": 19698,
    "id": 1858741797
  },
  {
    "city": "Puyo",
    "city_ascii": "Puyo",
    "lat": -1.483,
    "lng": -77.987,
    "country": "Ecuador",
    "iso2": "EC",
    "iso3": "ECU",
    "admin_name": "Pastaza",
    "capital": "admin",
    "population": 24881,
    "id": 1218537461
  },
  {
    "city": "Friern Barnet",
    "city_ascii": "Friern Barnet",
    "lat": 51.6126,
    "lng": -0.1584,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Barnet",
    "capital": "",
    "population": 17250,
    "id": 1826718744
  },
  {
    "city": "Kafr Qāsim",
    "city_ascii": "Kafr Qasim",
    "lat": 32.1142,
    "lng": 34.9772,
    "country": "Israel",
    "iso2": "IL",
    "iso3": "ISR",
    "admin_name": "Central",
    "capital": "",
    "population": 22743,
    "id": 1376925215
  },
  {
    "city": "South Pasadena",
    "city_ascii": "South Pasadena",
    "lat": 34.1103,
    "lng": -118.1573,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 25329,
    "id": 1840021871
  },
  {
    "city": "Kenilworth",
    "city_ascii": "Kenilworth",
    "lat": 52.341,
    "lng": -1.566,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Warwickshire",
    "capital": "",
    "population": 22413,
    "id": 1826857178
  },
  {
    "city": "Lambaréné",
    "city_ascii": "Lambarene",
    "lat": -0.6883,
    "lng": 10.2319,
    "country": "Gabon",
    "iso2": "GA",
    "iso3": "GAB",
    "admin_name": "Moyen-Ogooué",
    "capital": "admin",
    "population": 25310,
    "id": 1266875472
  },
  {
    "city": "Maesteg",
    "city_ascii": "Maesteg",
    "lat": 51.61,
    "lng": -3.65,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Bridgend",
    "capital": "",
    "population": 21000,
    "id": 1826221040
  },
  {
    "city": "Valinda",
    "city_ascii": "Valinda",
    "lat": 34.0401,
    "lng": -117.93,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 25296,
    "id": 1840019213
  },
  {
    "city": "Visby",
    "city_ascii": "Visby",
    "lat": 57.629,
    "lng": 18.3071,
    "country": "Sweden",
    "iso2": "SE",
    "iso3": "SWE",
    "admin_name": "Gotland",
    "capital": "admin",
    "population": 24693,
    "id": 1752027172
  },
  {
    "city": "Montigny-lès-Metz",
    "city_ascii": "Montigny-les-Metz",
    "lat": 49.1006,
    "lng": 6.1539,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Grand Est",
    "capital": "",
    "population": 21819,
    "id": 1250863295
  },
  {
    "city": "Ban Khlong Ton Madua",
    "city_ascii": "Ban Khlong Ton Madua",
    "lat": 13.6372,
    "lng": 100.2967,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Samut Sakhon",
    "capital": "",
    "population": 22265,
    "id": 1764645463
  },
  {
    "city": "Coral Terrace",
    "city_ascii": "Coral Terrace",
    "lat": 25.7464,
    "lng": -80.3049,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 25234,
    "id": 1840029028
  },
  {
    "city": "Paracuru",
    "city_ascii": "Paracuru",
    "lat": -3.3995,
    "lng": -39.04,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Ceará",
    "capital": "",
    "population": 21196,
    "id": 1076591278
  },
  {
    "city": "Mohale’s Hoek",
    "city_ascii": "Mohale's Hoek",
    "lat": -30.159,
    "lng": 27.48,
    "country": "Lesotho",
    "iso2": "LS",
    "iso3": "LSO",
    "admin_name": "Mohale’s Hoek",
    "capital": "admin",
    "population": 24992,
    "id": 1426343192
  },
  {
    "city": "Levittown",
    "city_ascii": "Levittown",
    "lat": 18.4454,
    "lng": -66.1759,
    "country": "Puerto Rico",
    "iso2": "PR",
    "iso3": "PRI",
    "admin_name": "Puerto Rico",
    "capital": "",
    "population": 25225,
    "id": 1630035506
  },
  {
    "city": "Palm Springs",
    "city_ascii": "Palm Springs",
    "lat": 26.6349,
    "lng": -80.0968,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 25216,
    "id": 1840018308
  },
  {
    "city": "Vukovar",
    "city_ascii": "Vukovar",
    "lat": 45.35,
    "lng": 19.0033,
    "country": "Croatia",
    "iso2": "HR",
    "iso3": "HRV",
    "admin_name": "Vukovarsko-Srijemska Županija",
    "capital": "admin",
    "population": 24938,
    "id": 1191345427
  },
  {
    "city": "Wewak",
    "city_ascii": "Wewak",
    "lat": -3.55,
    "lng": 143.6333,
    "country": "Papua New Guinea",
    "iso2": "PG",
    "iso3": "PNG",
    "admin_name": "East Sepik",
    "capital": "admin",
    "population": 25143,
    "id": 1598405100
  },
  {
    "city": "Sheldon",
    "city_ascii": "Sheldon",
    "lat": 52.45,
    "lng": -1.7666,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Solihull",
    "capital": "",
    "population": 21817,
    "id": 1826299810
  },
  {
    "city": "Baldwin",
    "city_ascii": "Baldwin",
    "lat": 40.6634,
    "lng": -73.6104,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 25134,
    "id": 1840005228
  },
  {
    "city": "Hacı Zeynalabdin",
    "city_ascii": "Haci Zeynalabdin",
    "lat": 40.6242,
    "lng": 49.5575,
    "country": "Azerbaijan",
    "iso2": "AZ",
    "iso3": "AZE",
    "admin_name": "Sumqayıt",
    "capital": "",
    "population": 22000,
    "id": 1031256738
  },
  {
    "city": "Juneau",
    "city_ascii": "Juneau",
    "lat": 58.4546,
    "lng": -134.1739,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alaska",
    "capital": "admin",
    "population": 25085,
    "id": 1840023306
  },
  {
    "city": "Santiago Ixcuintla",
    "city_ascii": "Santiago Ixcuintla",
    "lat": 21.811,
    "lng": -105.2079,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Nayarit",
    "capital": "minor",
    "population": 18365,
    "id": 1484248235
  },
  {
    "city": "Normanton",
    "city_ascii": "Normanton",
    "lat": 53.697,
    "lng": -1.416,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Wakefield",
    "capital": "",
    "population": 20872,
    "id": 1826795957
  },
  {
    "city": "Kaga Bandoro",
    "city_ascii": "Kaga Bandoro",
    "lat": 7.0006,
    "lng": 19.1808,
    "country": "Central African Republic",
    "iso2": "CF",
    "iso3": "CAF",
    "admin_name": "Nana-Grébizi",
    "capital": "admin",
    "population": 24661,
    "id": 1140658026
  },
  {
    "city": "Tbeng Meanchey",
    "city_ascii": "Tbeng Meanchey",
    "lat": 13.8167,
    "lng": 104.9667,
    "country": "Cambodia",
    "iso2": "KH",
    "iso3": "KHM",
    "admin_name": "Preah Vihear",
    "capital": "admin",
    "population": 24380,
    "id": 1116207762
  },
  {
    "city": "Portalegre",
    "city_ascii": "Portalegre",
    "lat": 39.3167,
    "lng": -7.4167,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Portalegre",
    "capital": "admin",
    "population": 24930,
    "id": 1620475518
  },
  {
    "city": "University Park",
    "city_ascii": "University Park",
    "lat": 32.8506,
    "lng": -96.7937,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 24985,
    "id": 1840022055
  },
  {
    "city": "Bispham",
    "city_ascii": "Bispham",
    "lat": 53.852,
    "lng": -3.041,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Blackpool",
    "capital": "",
    "population": 20001,
    "id": 1826538665
  },
  {
    "city": "Cloverleaf",
    "city_ascii": "Cloverleaf",
    "lat": 29.7882,
    "lng": -95.1724,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 24953,
    "id": 1840018248
  },
  {
    "city": "Le Plessis-Trévise",
    "city_ascii": "Le Plessis-Trevise",
    "lat": 48.8111,
    "lng": 2.5717,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 20130,
    "id": 1250710876
  },
  {
    "city": "Ptuj",
    "city_ascii": "Ptuj",
    "lat": 46.4186,
    "lng": 15.8714,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Ptuj",
    "capital": "admin",
    "population": 23957,
    "id": 1705072535
  },
  {
    "city": "Krathum Baen",
    "city_ascii": "Krathum Baen",
    "lat": 13.6519,
    "lng": 100.2572,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Samut Sakhon",
    "capital": "minor",
    "population": 21904,
    "id": 1764425264
  },
  {
    "city": "Riverbank",
    "city_ascii": "Riverbank",
    "lat": 37.7268,
    "lng": -120.9402,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 24881,
    "id": 1840020286
  },
  {
    "city": "Tassin-la-Demi-Lune",
    "city_ascii": "Tassin-la-Demi-Lune",
    "lat": 45.764,
    "lng": 4.78,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Auvergne-Rhône-Alpes",
    "capital": "",
    "population": 22297,
    "id": 1250210852
  },
  {
    "city": "Stratton Saint Margaret",
    "city_ascii": "Stratton Saint Margaret",
    "lat": 51.586,
    "lng": -1.762,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Swindon",
    "capital": "",
    "population": 22698,
    "id": 1826478043
  },
  {
    "city": "Lincolnia",
    "city_ascii": "Lincolnia",
    "lat": 38.8158,
    "lng": -77.1543,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 24808,
    "id": 1840006034
  },
  {
    "city": "Kanmaki",
    "city_ascii": "Kanmaki",
    "lat": 34.5628,
    "lng": 135.7167,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Nara",
    "capital": "",
    "population": 21318,
    "id": 1392530261
  },
  {
    "city": "March",
    "city_ascii": "March",
    "lat": 52.551,
    "lng": 0.088,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Cambridgeshire",
    "capital": "",
    "population": 22298,
    "id": 1826775634
  },
  {
    "city": "Tequixquiac",
    "city_ascii": "Tequixquiac",
    "lat": 19.9097,
    "lng": -99.1417,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Morelos",
    "capital": "minor",
    "population": 22676,
    "id": 1484908675
  },
  {
    "city": "Melrose Park",
    "city_ascii": "Melrose Park",
    "lat": 41.9029,
    "lng": -87.8642,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 24703,
    "id": 1840011296
  },
  {
    "city": "Kasamatsuchō",
    "city_ascii": "Kasamatsucho",
    "lat": 35.3672,
    "lng": 136.7633,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Gifu",
    "capital": "",
    "population": 22513,
    "id": 1392337002
  },
  {
    "city": "Sühbaatar",
    "city_ascii": "Suhbaatar",
    "lat": 50.2364,
    "lng": 106.2064,
    "country": "Mongolia",
    "iso2": "MN",
    "iso3": "MNG",
    "admin_name": "Selenge",
    "capital": "admin",
    "population": 24235,
    "id": 1496145017
  },
  {
    "city": "Reyes Acozac",
    "city_ascii": "Reyes Acozac",
    "lat": 19.7667,
    "lng": -98.9833,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Morelos",
    "capital": "",
    "population": 20478,
    "id": 1484003541
  },
  {
    "city": "Baalbek",
    "city_ascii": "Baalbek",
    "lat": 34.0061,
    "lng": 36.2086,
    "country": "Lebanon",
    "iso2": "LB",
    "iso3": "LBN",
    "admin_name": "Baalbek-Hermel",
    "capital": "admin",
    "population": 24000,
    "id": 1422694451
  },
  {
    "city": "Palmers Green",
    "city_ascii": "Palmers Green",
    "lat": 51.6178,
    "lng": -0.1092,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Enfield",
    "capital": "",
    "population": 15162,
    "id": 1826526694
  },
  {
    "city": "Montmorency",
    "city_ascii": "Montmorency",
    "lat": 48.9906,
    "lng": 2.3228,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 21461,
    "id": 1250939076
  },
  {
    "city": "Hornsey",
    "city_ascii": "Hornsey",
    "lat": 51.587,
    "lng": -0.118,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Haringey",
    "capital": "",
    "population": 12659,
    "id": 1826443938
  },
  {
    "city": "Herndon",
    "city_ascii": "Herndon",
    "lat": 38.9699,
    "lng": -77.3867,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 24601,
    "id": 1840006037
  },
  {
    "city": "Maplewood",
    "city_ascii": "Maplewood",
    "lat": 40.733,
    "lng": -74.2711,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 24596,
    "id": 1840081750
  },
  {
    "city": "Falmouth",
    "city_ascii": "Falmouth",
    "lat": 50.15,
    "lng": -5.07,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Cornwall",
    "capital": "",
    "population": 21797,
    "id": 1826117124
  },
  {
    "city": "Royton",
    "city_ascii": "Royton",
    "lat": 53.566,
    "lng": -2.121,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Oldham",
    "capital": "",
    "population": 21284,
    "id": 1826073923
  },
  {
    "city": "Bailey's Crossroads",
    "city_ascii": "Bailey's Crossroads",
    "lat": 38.8477,
    "lng": -77.1305,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 24570,
    "id": 1840037327
  },
  {
    "city": "Chiconcuac",
    "city_ascii": "Chiconcuac",
    "lat": 19.55,
    "lng": -98.9,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "México",
    "capital": "minor",
    "population": 19656,
    "id": 1484345092
  },
  {
    "city": "Falagueira",
    "city_ascii": "Falagueira",
    "lat": 38.759,
    "lng": -9.2199,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Lisboa",
    "capital": "",
    "population": 14530,
    "id": 1620164302
  },
  {
    "city": "Shārūnah",
    "city_ascii": "Sharunah",
    "lat": 28.5667,
    "lng": 30.85,
    "country": "Egypt",
    "iso2": "EG",
    "iso3": "EGY",
    "admin_name": "Al Minyā",
    "capital": "",
    "population": 22221,
    "id": 1818302173
  },
  {
    "city": "Rockville Centre",
    "city_ascii": "Rockville Centre",
    "lat": 40.6644,
    "lng": -73.6383,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 24550,
    "id": 1840005320
  },
  {
    "city": "Linslade",
    "city_ascii": "Linslade",
    "lat": 51.9243,
    "lng": -0.6774,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Central Bedfordshire",
    "capital": "",
    "population": 21590,
    "id": 1826448969
  },
  {
    "city": "Lop Buri",
    "city_ascii": "Lop Buri",
    "lat": 14.8,
    "lng": 100.6269,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Lop Buri",
    "capital": "admin",
    "population": 24166,
    "id": 1764574750
  },
  {
    "city": "Newquay",
    "city_ascii": "Newquay",
    "lat": 50.412,
    "lng": -5.0757,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Cornwall",
    "capital": "",
    "population": 20342,
    "id": 1826417367
  },
  {
    "city": "Gan Yavne",
    "city_ascii": "Gan Yavne",
    "lat": 31.7886,
    "lng": 34.7053,
    "country": "Israel",
    "iso2": "IL",
    "iso3": "ISR",
    "admin_name": "Central",
    "capital": "",
    "population": 22453,
    "id": 1376546526
  },
  {
    "city": "Plumstead",
    "city_ascii": "Plumstead",
    "lat": 51.49,
    "lng": 0.09,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Greenwich",
    "capital": "",
    "population": 16736,
    "id": 1826276603
  },
  {
    "city": "Watauga",
    "city_ascii": "Watauga",
    "lat": 32.8718,
    "lng": -97.2515,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 24481,
    "id": 1840022049
  },
  {
    "city": "Cotija de la Paz",
    "city_ascii": "Cotija de la Paz",
    "lat": 19.81,
    "lng": -102.7047,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "México",
    "capital": "minor",
    "population": 19018,
    "id": 1484490839
  },
  {
    "city": "Carlow",
    "city_ascii": "Carlow",
    "lat": 52.8306,
    "lng": -6.9317,
    "country": "Ireland",
    "iso2": "IE",
    "iso3": "IRL",
    "admin_name": "Carlow",
    "capital": "admin",
    "population": 24272,
    "id": 1372096982
  },
  {
    "city": "La Celle-Saint-Cloud",
    "city_ascii": "La Celle-Saint-Cloud",
    "lat": 48.8411,
    "lng": 2.1344,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 20966,
    "id": 1250819039
  },
  {
    "city": "São João da Madeira",
    "city_ascii": "Sao Joao da Madeira",
    "lat": 40.9,
    "lng": -8.5,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Aveiro",
    "capital": "minor",
    "population": 21713,
    "id": 1620177884
  },
  {
    "city": "Montigny-lès-Cormeilles",
    "city_ascii": "Montigny-les-Cormeilles",
    "lat": 48.9944,
    "lng": 2.1958,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 21601,
    "id": 1250292359
  },
  {
    "city": "Suitland",
    "city_ascii": "Suitland",
    "lat": 38.8492,
    "lng": -76.9225,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 24444,
    "id": 1840031466
  },
  {
    "city": "Fatick",
    "city_ascii": "Fatick",
    "lat": 14.3167,
    "lng": -16.4167,
    "country": "Senegal",
    "iso2": "SN",
    "iso3": "SEN",
    "admin_name": "Fatick",
    "capital": "admin",
    "population": 24243,
    "id": 1686096548
  },
  {
    "city": "Chesham",
    "city_ascii": "Chesham",
    "lat": 51.712,
    "lng": -0.612,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Buckinghamshire",
    "capital": "",
    "population": 21483,
    "id": 1826549288
  },
  {
    "city": "Lodi",
    "city_ascii": "Lodi",
    "lat": 40.8784,
    "lng": -74.0814,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 24347,
    "id": 1840003535
  },
  {
    "city": "Villa Sarmiento",
    "city_ascii": "Villa Sarmiento",
    "lat": -34.6333,
    "lng": -58.5667,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires",
    "capital": "",
    "population": 17481,
    "id": 1032961945
  },
  {
    "city": "San Fernando",
    "city_ascii": "San Fernando",
    "lat": 34.2886,
    "lng": -118.4363,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 24322,
    "id": 1840021861
  },
  {
    "city": "Chanthaburi",
    "city_ascii": "Chanthaburi",
    "lat": 12.6086,
    "lng": 102.1039,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Chanthaburi",
    "capital": "admin",
    "population": 23835,
    "id": 1764151688
  },
  {
    "city": "Peekskill",
    "city_ascii": "Peekskill",
    "lat": 41.2883,
    "lng": -73.9227,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 24295,
    "id": 1840000783
  },
  {
    "city": "Barras",
    "city_ascii": "Barras",
    "lat": -4.25,
    "lng": -42.3,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Piauí",
    "capital": "",
    "population": 21597,
    "id": 1076766658
  },
  {
    "city": "Terrytown",
    "city_ascii": "Terrytown",
    "lat": 29.9014,
    "lng": -90.0279,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 24244,
    "id": 1840013990
  },
  {
    "city": "Vélizy-Villacoublay",
    "city_ascii": "Velizy-Villacoublay",
    "lat": 48.7834,
    "lng": 2.1834,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 22036,
    "id": 1250075648
  },
  {
    "city": "Kėdainiai",
    "city_ascii": "Kedainiai",
    "lat": 55.2833,
    "lng": 23.9667,
    "country": "Lithuania",
    "iso2": "LT",
    "iso3": "LTU",
    "admin_name": "Kėdainiai",
    "capital": "admin",
    "population": 24177,
    "id": 1440351434
  },
  {
    "city": "Newton in Makerfield",
    "city_ascii": "Newton in Makerfield",
    "lat": 53.45,
    "lng": -2.633,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "St. Helens",
    "capital": "",
    "population": 22114,
    "id": 1826213063
  },
  {
    "city": "Vrbas",
    "city_ascii": "Vrbas",
    "lat": 45.5697,
    "lng": 19.6378,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Vrbas",
    "capital": "admin",
    "population": 24112,
    "id": 1688145915
  },
  {
    "city": "Neath",
    "city_ascii": "Neath",
    "lat": 51.66,
    "lng": -3.81,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Neath Port Talbot",
    "capital": "",
    "population": 19258,
    "id": 1826679432
  },
  {
    "city": "Huskvarna",
    "city_ascii": "Huskvarna",
    "lat": 57.7919,
    "lng": 14.2756,
    "country": "Sweden",
    "iso2": "SE",
    "iso3": "SWE",
    "admin_name": "Jönköping",
    "capital": "",
    "population": 22000,
    "id": 1752552539
  },
  {
    "city": "Atar",
    "city_ascii": "Atar",
    "lat": 20.5167,
    "lng": -13.05,
    "country": "Mauritania",
    "iso2": "MR",
    "iso3": "MRT",
    "admin_name": "Adrar",
    "capital": "admin",
    "population": 24021,
    "id": 1478064510
  },
  {
    "city": "Motul",
    "city_ascii": "Motul",
    "lat": 21.1667,
    "lng": -89.4667,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Yucatán",
    "capital": "",
    "population": 21508,
    "id": 1484505595
  },
  {
    "city": "Elmwood Park",
    "city_ascii": "Elmwood Park",
    "lat": 41.9225,
    "lng": -87.8163,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 24098,
    "id": 1840011272
  },
  {
    "city": "Novo Mesto",
    "city_ascii": "Novo Mesto",
    "lat": 45.7981,
    "lng": 15.1628,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Novo Mesto",
    "capital": "admin",
    "population": 23341,
    "id": 1705849587
  },
  {
    "city": "Renfrew",
    "city_ascii": "Renfrew",
    "lat": 55.8791,
    "lng": -4.3868,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Renfrewshire",
    "capital": "",
    "population": 21854,
    "id": 1826588626
  },
  {
    "city": "Horwich",
    "city_ascii": "Horwich",
    "lat": 53.592,
    "lng": -2.54,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Bolton",
    "capital": "",
    "population": 20067,
    "id": 1826931583
  },
  {
    "city": "Cottingham",
    "city_ascii": "Cottingham",
    "lat": 53.7822,
    "lng": -0.4136,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Kingston upon Hull, City of",
    "capital": "",
    "population": 17164,
    "id": 1826515818
  },
  {
    "city": "Southgate",
    "city_ascii": "Southgate",
    "lat": 51.6316,
    "lng": -0.1265,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Enfield",
    "capital": "",
    "population": 14454,
    "id": 1826679961
  },
  {
    "city": "Guaranda",
    "city_ascii": "Guaranda",
    "lat": -1.6056,
    "lng": -79.0031,
    "country": "Ecuador",
    "iso2": "EC",
    "iso3": "ECU",
    "admin_name": "Bolívar",
    "capital": "admin",
    "population": 23874,
    "id": 1218581593
  },
  {
    "city": "Kibaha",
    "city_ascii": "Kibaha",
    "lat": -6.7586,
    "lng": 38.9289,
    "country": "Tanzania",
    "iso2": "TZ",
    "iso3": "TZA",
    "admin_name": "Coast",
    "capital": "admin",
    "population": 23050,
    "id": 1834065400
  },
  {
    "city": "Tysons",
    "city_ascii": "Tysons",
    "lat": 38.9215,
    "lng": -77.2273,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 23749,
    "id": 1840006040
  },
  {
    "city": "Tewkesbury",
    "city_ascii": "Tewkesbury",
    "lat": 51.99,
    "lng": -2.16,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Gloucestershire",
    "capital": "",
    "population": 19778,
    "id": 1826890799
  },
  {
    "city": "Palm Tree",
    "city_ascii": "Palm Tree",
    "lat": 41.3411,
    "lng": -74.1667,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 23744,
    "id": 1840141656
  },
  {
    "city": "Satun",
    "city_ascii": "Satun",
    "lat": 6.6147,
    "lng": 100.0681,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Satun",
    "capital": "admin",
    "population": 23612,
    "id": 1764836023
  },
  {
    "city": "Villeneuve-le-Roi",
    "city_ascii": "Villeneuve-le-Roi",
    "lat": 48.7333,
    "lng": 2.4167,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 21384,
    "id": 1250314073
  },
  {
    "city": "Phetchaburi",
    "city_ascii": "Phetchaburi",
    "lat": 13.1119,
    "lng": 99.9458,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Phetchaburi",
    "capital": "admin",
    "population": 23235,
    "id": 1764000534
  },
  {
    "city": "Copiague",
    "city_ascii": "Copiague",
    "lat": 40.6728,
    "lng": -73.3932,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 23716,
    "id": 1840005031
  },
  {
    "city": "Oak Ridge",
    "city_ascii": "Oak Ridge",
    "lat": 28.4727,
    "lng": -81.4169,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 23712,
    "id": 1840029062
  },
  {
    "city": "Chilly-Mazarin",
    "city_ascii": "Chilly-Mazarin",
    "lat": 48.7025,
    "lng": 2.3125,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 20181,
    "id": 1250986253
  },
  {
    "city": "Ashland",
    "city_ascii": "Ashland",
    "lat": 37.6942,
    "lng": -122.1159,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 23635,
    "id": 1840017619
  },
  {
    "city": "West Puente Valley",
    "city_ascii": "West Puente Valley",
    "lat": 34.0512,
    "lng": -117.9681,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 23629,
    "id": 1840028409
  },
  {
    "city": "Rosemont",
    "city_ascii": "Rosemont",
    "lat": 38.5477,
    "lng": -121.3553,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 23617,
    "id": 1840018844
  },
  {
    "city": "Southbourne",
    "city_ascii": "Southbourne",
    "lat": 50.722,
    "lng": -1.798,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Bournemouth",
    "capital": "",
    "population": 18960,
    "id": 1826138490
  },
  {
    "city": "Peterlee",
    "city_ascii": "Peterlee",
    "lat": 54.76,
    "lng": -1.33,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Durham",
    "capital": "",
    "population": 20164,
    "id": 1826513639
  },
  {
    "city": "Kirkstall",
    "city_ascii": "Kirkstall",
    "lat": 53.816,
    "lng": -1.602,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Leeds",
    "capital": "",
    "population": 20673,
    "id": 1826151628
  },
  {
    "city": "Penzance",
    "city_ascii": "Penzance",
    "lat": 50.119,
    "lng": -5.537,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Cornwall",
    "capital": "",
    "population": 21200,
    "id": 1826600240
  },
  {
    "city": "Ogre",
    "city_ascii": "Ogre",
    "lat": 56.8169,
    "lng": 24.6047,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Ogres Novads",
    "capital": "admin",
    "population": 23533,
    "id": 1428832851
  },
  {
    "city": "Cudahy",
    "city_ascii": "Cudahy",
    "lat": 33.9631,
    "lng": -118.183,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 23569,
    "id": 1840019242
  },
  {
    "city": "The Crossings",
    "city_ascii": "The Crossings",
    "lat": 25.6708,
    "lng": -80.4018,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 23558,
    "id": 1840029008
  },
  {
    "city": "Shoreham-by-Sea",
    "city_ascii": "Shoreham-by-Sea",
    "lat": 50.834,
    "lng": -0.273,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "West Sussex",
    "capital": "",
    "population": 20547,
    "id": 1826260470
  },
  {
    "city": "Seaham",
    "city_ascii": "Seaham",
    "lat": 54.84,
    "lng": -1.34,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Durham",
    "capital": "",
    "population": 20172,
    "id": 1826110918
  },
  {
    "city": "Allschwil",
    "city_ascii": "Allschwil",
    "lat": 47.5508,
    "lng": 7.5358,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Basel-Landschaft",
    "capital": "",
    "population": 21150,
    "id": 1756684864
  },
  {
    "city": "Coudekerque-Branche",
    "city_ascii": "Coudekerque-Branche",
    "lat": 51.0253,
    "lng": 2.3917,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Hauts-de-France",
    "capital": "",
    "population": 21134,
    "id": 1250526010
  },
  {
    "city": "Nkhata Bay",
    "city_ascii": "Nkhata Bay",
    "lat": -11.6333,
    "lng": 34.3,
    "country": "Malawi",
    "iso2": "MW",
    "iso3": "MWI",
    "admin_name": "Nkhata Bay",
    "capital": "admin",
    "population": 22108,
    "id": 1454517378
  },
  {
    "city": "Hialeah Gardens",
    "city_ascii": "Hialeah Gardens",
    "lat": 25.8878,
    "lng": -80.3569,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 23474,
    "id": 1840015158
  },
  {
    "city": "Sukhodilsk",
    "city_ascii": "Sukhodilsk",
    "lat": 48.35,
    "lng": 39.7167,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Luhans’ka Oblast’",
    "capital": "",
    "population": 21324,
    "id": 1804091595
  },
  {
    "city": "Mantes-la-Ville",
    "city_ascii": "Mantes-la-Ville",
    "lat": 48.975,
    "lng": 1.7117,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 20452,
    "id": 1250672481
  },
  {
    "city": "Carteret",
    "city_ascii": "Carteret",
    "lat": 40.5848,
    "lng": -74.2284,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 23408,
    "id": 1840001331
  },
  {
    "city": "Woodlawn",
    "city_ascii": "Woodlawn",
    "lat": 38.7332,
    "lng": -77.1149,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 23406,
    "id": 1840145210
  },
  {
    "city": "Opfikon",
    "city_ascii": "Opfikon",
    "lat": 47.4331,
    "lng": 8.5719,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Zürich",
    "capital": "",
    "population": 19864,
    "id": 1756222668
  },
  {
    "city": "Tena",
    "city_ascii": "Tena",
    "lat": -0.989,
    "lng": -77.8159,
    "country": "Ecuador",
    "iso2": "EC",
    "iso3": "ECU",
    "admin_name": "Napo",
    "capital": "admin",
    "population": 23307,
    "id": 1218759537
  },
  {
    "city": "Wasquehal",
    "city_ascii": "Wasquehal",
    "lat": 50.6694,
    "lng": 3.1308,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Hauts-de-France",
    "capital": "",
    "population": 20479,
    "id": 1250237697
  },
  {
    "city": "Peto",
    "city_ascii": "Peto",
    "lat": 20.1256,
    "lng": -88.9214,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Yucatán",
    "capital": "",
    "population": 19821,
    "id": 1484013411
  },
  {
    "city": "Biddulph",
    "city_ascii": "Biddulph",
    "lat": 53.12,
    "lng": -2.17,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Staffordshire",
    "capital": "",
    "population": 19892,
    "id": 1826603209
  },
  {
    "city": "Killingworth",
    "city_ascii": "Killingworth",
    "lat": 55.0318,
    "lng": -1.5557,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "North Tyneside",
    "capital": "",
    "population": 20079,
    "id": 1826551869
  },
  {
    "city": "Loreto",
    "city_ascii": "Loreto",
    "lat": 26.0128,
    "lng": -111.3433,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Baja California Sur",
    "capital": "minor",
    "population": 20385,
    "id": 1484876942
  },
  {
    "city": "Naas",
    "city_ascii": "Naas",
    "lat": 53.2158,
    "lng": -6.6669,
    "country": "Ireland",
    "iso2": "IE",
    "iso3": "IRL",
    "admin_name": "Kildare",
    "capital": "admin",
    "population": 21393,
    "id": 1372985945
  },
  {
    "city": "Sacavém",
    "city_ascii": "Sacavem",
    "lat": 38.7944,
    "lng": -9.1053,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Lisboa",
    "capital": "",
    "population": 18469,
    "id": 1620849212
  },
  {
    "city": "Jēkabpils",
    "city_ascii": "Jekabpils",
    "lat": 56.4975,
    "lng": 25.8664,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Jēkabpils Novads",
    "capital": "admin",
    "population": 22412,
    "id": 1428885452
  },
  {
    "city": "Caterham",
    "city_ascii": "Caterham",
    "lat": 51.2803,
    "lng": -0.0816,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Surrey",
    "capital": "",
    "population": 21030,
    "id": 1826811520
  },
  {
    "city": "Yonabaru",
    "city_ascii": "Yonabaru",
    "lat": 26.1994,
    "lng": 127.7547,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Okinawa",
    "capital": "",
    "population": 19494,
    "id": 1392279515
  },
  {
    "city": "Nyon",
    "city_ascii": "Nyon",
    "lat": 46.382,
    "lng": 6.2389,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Vaud",
    "capital": "",
    "population": 20272,
    "id": 1756289111
  },
  {
    "city": "Ciudad Miguel Alemán",
    "city_ascii": "Ciudad Miguel Aleman",
    "lat": 26.4003,
    "lng": -99.0253,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Tamaulipas",
    "capital": "minor",
    "population": 19997,
    "id": 1484636817
  },
  {
    "city": "Bellshill",
    "city_ascii": "Bellshill",
    "lat": 55.8165,
    "lng": -4.0262,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "North Lanarkshire",
    "capital": "",
    "population": 20650,
    "id": 1826995667
  },
  {
    "city": "Worcester Park",
    "city_ascii": "Worcester Park",
    "lat": 51.3752,
    "lng": -0.239,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Sutton",
    "capital": "",
    "population": 16031,
    "id": 1826854793
  },
  {
    "city": "Maywood",
    "city_ascii": "Maywood",
    "lat": 41.8798,
    "lng": -87.8442,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 23158,
    "id": 1840011294
  },
  {
    "city": "Davyhulme",
    "city_ascii": "Davyhulme",
    "lat": 53.4559,
    "lng": -2.3683,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Trafford",
    "capital": "",
    "population": 19634,
    "id": 1826976833
  },
  {
    "city": "Fleury-les-Aubrais",
    "city_ascii": "Fleury-les-Aubrais",
    "lat": 47.9512,
    "lng": 1.8745,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Centre-Val de Loire",
    "capital": "",
    "population": 21026,
    "id": 1250087554
  },
  {
    "city": "Hidalgotitlán",
    "city_ascii": "Hidalgotitlan",
    "lat": 17.7833,
    "lng": -94.6333,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Veracruz",
    "capital": "minor",
    "population": 19587,
    "id": 1484749313
  },
  {
    "city": "Winter Gardens",
    "city_ascii": "Winter Gardens",
    "lat": 32.8376,
    "lng": -116.9268,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 23088,
    "id": 1840019351
  },
  {
    "city": "Le Mée-sur-Seine",
    "city_ascii": "Le Mee-sur-Seine",
    "lat": 48.5333,
    "lng": 2.6289,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 20816,
    "id": 1250420888
  },
  {
    "city": "Achères",
    "city_ascii": "Acheres",
    "lat": 48.9602,
    "lng": 2.0684,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 21017,
    "id": 1250623309
  },
  {
    "city": "Kilkenny",
    "city_ascii": "Kilkenny",
    "lat": 52.6477,
    "lng": -7.2561,
    "country": "Ireland",
    "iso2": "IE",
    "iso3": "IRL",
    "admin_name": "Kilkenny",
    "capital": "admin",
    "population": 22179,
    "id": 1372926521
  },
  {
    "city": "Alloa",
    "city_ascii": "Alloa",
    "lat": 56.116,
    "lng": -3.793,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Clackmannanshire",
    "capital": "",
    "population": 20730,
    "id": 1826126712
  },
  {
    "city": "Aarau",
    "city_ascii": "Aarau",
    "lat": 47.3923,
    "lng": 8.0446,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Aargau",
    "capital": "admin",
    "population": 21268,
    "id": 1756722157
  },
  {
    "city": "West Rancho Dominguez",
    "city_ascii": "West Rancho Dominguez",
    "lat": 33.9057,
    "lng": -118.2682,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 22932,
    "id": 1840028406
  },
  {
    "city": "Blue Island",
    "city_ascii": "Blue Island",
    "lat": 41.6578,
    "lng": -87.6812,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 22899,
    "id": 1840007028
  },
  {
    "city": "Lamu",
    "city_ascii": "Lamu",
    "lat": -2.2686,
    "lng": 40.9003,
    "country": "Kenya",
    "iso2": "KE",
    "iso3": "KEN",
    "admin_name": "Lamu",
    "capital": "admin",
    "population": 18382,
    "id": 1404163131
  },
  {
    "city": "Biləsuvar",
    "city_ascii": "Bilasuvar",
    "lat": 39.4481,
    "lng": 48.5428,
    "country": "Azerbaijan",
    "iso2": "AZ",
    "iso3": "AZE",
    "admin_name": "Biləsuvar",
    "capital": "admin",
    "population": 22800,
    "id": 1031149283
  },
  {
    "city": "Kayanza",
    "city_ascii": "Kayanza",
    "lat": -2.9221,
    "lng": 29.6293,
    "country": "Burundi",
    "iso2": "BI",
    "iso3": "BDI",
    "admin_name": "Kayanza",
    "capital": "admin",
    "population": 21767,
    "id": 1108413426
  },
  {
    "city": "Sibiti",
    "city_ascii": "Sibiti",
    "lat": -3.6833,
    "lng": 13.35,
    "country": "Congo (Brazzaville)",
    "iso2": "CG",
    "iso3": "COG",
    "admin_name": "Lékoumou",
    "capital": "admin",
    "population": 22811,
    "id": 1178867178
  },
  {
    "city": "Llandudno",
    "city_ascii": "Llandudno",
    "lat": 53.325,
    "lng": -3.826,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Conwy",
    "capital": "",
    "population": 20701,
    "id": 1826130998
  },
  {
    "city": "Madingou",
    "city_ascii": "Madingou",
    "lat": -4.1536,
    "lng": 13.55,
    "country": "Congo (Brazzaville)",
    "iso2": "CG",
    "iso3": "COG",
    "admin_name": "Bouenza",
    "capital": "admin",
    "population": 22760,
    "id": 1178057279
  },
  {
    "city": "Bathgate",
    "city_ascii": "Bathgate",
    "lat": 55.9024,
    "lng": -3.6431,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "West Lothian",
    "capital": "",
    "population": 20363,
    "id": 1826881376
  },
  {
    "city": "Mzimba",
    "city_ascii": "Mzimba",
    "lat": -11.9,
    "lng": 33.6,
    "country": "Malawi",
    "iso2": "MW",
    "iso3": "MWI",
    "admin_name": "Mzimba",
    "capital": "admin",
    "population": 19308,
    "id": 1454007077
  },
  {
    "city": "Sibut",
    "city_ascii": "Sibut",
    "lat": 5.7378,
    "lng": 19.0867,
    "country": "Central African Republic",
    "iso2": "CF",
    "iso3": "CAF",
    "admin_name": "Kémo",
    "capital": "admin",
    "population": 22419,
    "id": 1140802055
  },
  {
    "city": "Rothwell",
    "city_ascii": "Rothwell",
    "lat": 53.7485,
    "lng": -1.478,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Leeds",
    "capital": "",
    "population": 20354,
    "id": 1826415325
  },
  {
    "city": "Goodmayes",
    "city_ascii": "Goodmayes",
    "lat": 51.5584,
    "lng": 0.1119,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Redbridge",
    "capital": "",
    "population": 13069,
    "id": 1826397253
  },
  {
    "city": "Uliastay",
    "city_ascii": "Uliastay",
    "lat": 47.7417,
    "lng": 96.8444,
    "country": "Mongolia",
    "iso2": "MN",
    "iso3": "MNG",
    "admin_name": "Dzavhan",
    "capital": "admin",
    "population": 22006,
    "id": 1496103841
  },
  {
    "city": "Ives Estates",
    "city_ascii": "Ives Estates",
    "lat": 25.9632,
    "lng": -80.183,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 22682,
    "id": 1840028705
  },
  {
    "city": "Burnham-on-Sea",
    "city_ascii": "Burnham-on-Sea",
    "lat": 51.2376,
    "lng": -2.9935,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Somerset",
    "capital": "",
    "population": 19576,
    "id": 1826774617
  },
  {
    "city": "Ahfir",
    "city_ascii": "Ahfir",
    "lat": 34.9514,
    "lng": -2.1025,
    "country": "Morocco",
    "iso2": "MA",
    "iso3": "MAR",
    "admin_name": "Oriental",
    "capital": "",
    "population": 19630,
    "id": 1504027251
  },
  {
    "city": "Nogent-sur-Oise",
    "city_ascii": "Nogent-sur-Oise",
    "lat": 49.2756,
    "lng": 2.4683,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Hauts-de-France",
    "capital": "",
    "population": 20033,
    "id": 1250139539
  },
  {
    "city": "Mödling",
    "city_ascii": "Modling",
    "lat": 48.0856,
    "lng": 16.2831,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Niederösterreich",
    "capital": "minor",
    "population": 20555,
    "id": 1040024396
  },
  {
    "city": "Quedgeley",
    "city_ascii": "Quedgeley",
    "lat": 51.825,
    "lng": -2.28,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Gloucestershire",
    "capital": "",
    "population": 17519,
    "id": 1826737414
  },
  {
    "city": "Kayunga",
    "city_ascii": "Kayunga",
    "lat": 0.7025,
    "lng": 32.8886,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Kayunga",
    "capital": "admin",
    "population": 21704,
    "id": 1800678001
  },
  {
    "city": "Dukinfield",
    "city_ascii": "Dukinfield",
    "lat": 53.4739,
    "lng": -2.0828,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Tameside",
    "capital": "",
    "population": 19306,
    "id": 1826493553
  },
  {
    "city": "Mulanje",
    "city_ascii": "Mulanje",
    "lat": -16.0316,
    "lng": 35.5,
    "country": "Malawi",
    "iso2": "MW",
    "iso3": "MWI",
    "admin_name": "Mulanje",
    "capital": "admin",
    "population": 20870,
    "id": 1454459215
  },
  {
    "city": "West Carson",
    "city_ascii": "West Carson",
    "lat": 33.8229,
    "lng": -118.2931,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 22537,
    "id": 1840028405
  },
  {
    "city": "Prestatyn",
    "city_ascii": "Prestatyn",
    "lat": 53.331,
    "lng": -3.405,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Denbighshire",
    "capital": "",
    "population": 18849,
    "id": 1826727642
  },
  {
    "city": "Bafatá",
    "city_ascii": "Bafata",
    "lat": 12.1719,
    "lng": -14.6575,
    "country": "Guinea-Bissau",
    "iso2": "GW",
    "iso3": "GNB",
    "admin_name": "Bafatá",
    "capital": "admin",
    "population": 22501,
    "id": 1624735310
  },
  {
    "city": "Dingle",
    "city_ascii": "Dingle",
    "lat": 53.3774,
    "lng": -2.9613,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Liverpool",
    "capital": "",
    "population": 13246,
    "id": 1826286712
  },
  {
    "city": "Phichit",
    "city_ascii": "Phichit",
    "lat": 16.4431,
    "lng": 100.3467,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Phichit",
    "capital": "admin",
    "population": 22299,
    "id": 1764024236
  },
  {
    "city": "Mouila",
    "city_ascii": "Mouila",
    "lat": -1.8667,
    "lng": 11.055,
    "country": "Gabon",
    "iso2": "GA",
    "iso3": "GAB",
    "admin_name": "Ngounié",
    "capital": "admin",
    "population": 22469,
    "id": 1266074474
  },
  {
    "city": "Huanímaro",
    "city_ascii": "Huanimaro",
    "lat": 20.3675,
    "lng": -101.4969,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Guanajuato",
    "capital": "minor",
    "population": 19693,
    "id": 1484325445
  },
  {
    "city": "Loei",
    "city_ascii": "Loei",
    "lat": 17.4903,
    "lng": 101.725,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Loei",
    "capital": "admin",
    "population": 22339,
    "id": 1764996883
  },
  {
    "city": "Willowbrook",
    "city_ascii": "Willowbrook",
    "lat": 33.9199,
    "lng": -118.2362,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 22402,
    "id": 1840019220
  },
  {
    "city": "Millbrae",
    "city_ascii": "Millbrae",
    "lat": 37.5994,
    "lng": -122.4023,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 22394,
    "id": 1840020306
  },
  {
    "city": "East Barnet",
    "city_ascii": "East Barnet",
    "lat": 51.643,
    "lng": -0.163,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Barnet",
    "capital": "",
    "population": 16137,
    "id": 1826125584
  },
  {
    "city": "Molesey",
    "city_ascii": "Molesey",
    "lat": 51.401,
    "lng": -0.363,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Surrey",
    "capital": "",
    "population": 19088,
    "id": 1826852866
  },
  {
    "city": "Mbaïki",
    "city_ascii": "Mbaiki",
    "lat": 3.8833,
    "lng": 18,
    "country": "Central African Republic",
    "iso2": "CF",
    "iso3": "CAF",
    "admin_name": "Lobaye",
    "capital": "admin",
    "population": 22166,
    "id": 1140040245
  },
  {
    "city": "Soroca",
    "city_ascii": "Soroca",
    "lat": 48.1558,
    "lng": 28.2975,
    "country": "Moldova",
    "iso2": "MD",
    "iso3": "MDA",
    "admin_name": "Soroca",
    "capital": "admin",
    "population": 22196,
    "id": 1498912060
  },
  {
    "city": "Hayesville",
    "city_ascii": "Hayesville",
    "lat": 44.9794,
    "lng": -122.9739,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 22272,
    "id": 1840034726
  },
  {
    "city": "Nsanje",
    "city_ascii": "Nsanje",
    "lat": -16.9167,
    "lng": 35.2667,
    "country": "Malawi",
    "iso2": "MW",
    "iso3": "MWI",
    "admin_name": "Nsanje",
    "capital": "admin",
    "population": 21774,
    "id": 1454428076
  },
  {
    "city": "Connahs Quay",
    "city_ascii": "Connahs Quay",
    "lat": 53.2179,
    "lng": -3.0573,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Flintshire",
    "capital": "",
    "population": 16774,
    "id": 1826159536
  },
  {
    "city": "Truro",
    "city_ascii": "Truro",
    "lat": 50.26,
    "lng": -5.051,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Cornwall",
    "capital": "",
    "population": 18766,
    "id": 1826586304
  },
  {
    "city": "Phetchabun",
    "city_ascii": "Phetchabun",
    "lat": 16.4169,
    "lng": 101.1533,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Phetchabun",
    "capital": "admin",
    "population": 22121,
    "id": 1764518331
  },
  {
    "city": "Liversedge",
    "city_ascii": "Liversedge",
    "lat": 53.7067,
    "lng": -1.69,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Kirklees",
    "capital": "",
    "population": 19420,
    "id": 1826334661
  },
  {
    "city": "Bella Unión",
    "city_ascii": "Bella Union",
    "lat": -30.26,
    "lng": -57.5992,
    "country": "Uruguay",
    "iso2": "UY",
    "iso3": "URY",
    "admin_name": "Artigas",
    "capital": "",
    "population": 19100,
    "id": 1858883224
  },
  {
    "city": "Caacupé",
    "city_ascii": "Caacupe",
    "lat": -25.387,
    "lng": -57.14,
    "country": "Paraguay",
    "iso2": "PY",
    "iso3": "PRY",
    "admin_name": "Cordillera",
    "capital": "admin",
    "population": 21696,
    "id": 1600095784
  },
  {
    "city": "Antrim",
    "city_ascii": "Antrim",
    "lat": 54.7173,
    "lng": -6.2055,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Antrim and Newtownabbey",
    "capital": "",
    "population": 19986,
    "id": 1826477610
  },
  {
    "city": "Fortín de las Flores",
    "city_ascii": "Fortin de las Flores",
    "lat": 18.9,
    "lng": -97,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Veracruz",
    "capital": "minor",
    "population": 18965,
    "id": 1484333240
  },
  {
    "city": "Mocoa",
    "city_ascii": "Mocoa",
    "lat": 1.15,
    "lng": -76.63,
    "country": "Colombia",
    "iso2": "CO",
    "iso3": "COL",
    "admin_name": "Putumayo",
    "capital": "admin",
    "population": 22035,
    "id": 1170970726
  },
  {
    "city": "Monsey",
    "city_ascii": "Monsey",
    "lat": 41.1181,
    "lng": -74.0681,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 22073,
    "id": 1840004961
  },
  {
    "city": "Telšiai",
    "city_ascii": "Telsiai",
    "lat": 55.9833,
    "lng": 22.25,
    "country": "Lithuania",
    "iso2": "LT",
    "iso3": "LTU",
    "admin_name": "Telšiai",
    "capital": "admin",
    "population": 22039,
    "id": 1440908000
  },
  {
    "city": "Guayama",
    "city_ascii": "Guayama",
    "lat": 17.9744,
    "lng": -66.1104,
    "country": "Puerto Rico",
    "iso2": "PR",
    "iso3": "PRI",
    "admin_name": "Puerto Rico",
    "capital": "",
    "population": 22063,
    "id": 1630035675
  },
  {
    "city": "Landover",
    "city_ascii": "Landover",
    "lat": 38.9241,
    "lng": -76.8875,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 22041,
    "id": 1840005956
  },
  {
    "city": "Linlithgow",
    "city_ascii": "Linlithgow",
    "lat": 55.9791,
    "lng": -3.6105,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "West Lothian",
    "capital": "",
    "population": 19000,
    "id": 1826000089
  },
  {
    "city": "Keynsham",
    "city_ascii": "Keynsham",
    "lat": 51.4135,
    "lng": -2.4968,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Bath and North East Somerset",
    "capital": "",
    "population": 16641,
    "id": 1826380949
  },
  {
    "city": "Tauragė",
    "city_ascii": "Taurage",
    "lat": 55.2514,
    "lng": 22.2903,
    "country": "Lithuania",
    "iso2": "LT",
    "iso3": "LTU",
    "admin_name": "Tauragė",
    "capital": "admin",
    "population": 22002,
    "id": 1440115384
  },
  {
    "city": "Kegalle",
    "city_ascii": "Kegalle",
    "lat": 7.2531,
    "lng": 80.3453,
    "country": "Sri Lanka",
    "iso2": "LK",
    "iso3": "LKA",
    "admin_name": "Sabaragamuwa",
    "capital": "",
    "population": 17962,
    "id": 1144224835
  },
  {
    "city": "Ghāt",
    "city_ascii": "Ghat",
    "lat": 24.95,
    "lng": 10.3167,
    "country": "Libya",
    "iso2": "LY",
    "iso3": "LBY",
    "admin_name": "Ghāt",
    "capital": "admin",
    "population": 22000,
    "id": 1434971028
  },
  {
    "city": "Moreton",
    "city_ascii": "Moreton",
    "lat": 53.401,
    "lng": -3.111,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Wirral",
    "capital": "",
    "population": 17670,
    "id": 1826866856
  },
  {
    "city": "Bedlington",
    "city_ascii": "Bedlington",
    "lat": 55.133,
    "lng": -1.583,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Northumberland",
    "capital": "",
    "population": 18470,
    "id": 1826713966
  },
  {
    "city": "Hillside",
    "city_ascii": "Hillside",
    "lat": 40.6961,
    "lng": -74.2286,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 21895,
    "id": 1840081741
  },
  {
    "city": "Reinach",
    "city_ascii": "Reinach",
    "lat": 47.4936,
    "lng": 7.5908,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Basel-Landschaft",
    "capital": "",
    "population": 19144,
    "id": 1756011617
  },
  {
    "city": "Al Karak",
    "city_ascii": "Al Karak",
    "lat": 31.1833,
    "lng": 35.7,
    "country": "Jordan",
    "iso2": "JO",
    "iso3": "JOR",
    "admin_name": "Al Karak",
    "capital": "admin",
    "population": 21678,
    "id": 1400073941
  },
  {
    "city": "Massapequa",
    "city_ascii": "Massapequa",
    "lat": 40.6676,
    "lng": -73.4706,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 21861,
    "id": 1840005274
  },
  {
    "city": "Saint-Lambert",
    "city_ascii": "Saint-Lambert",
    "lat": 45.5,
    "lng": -73.5167,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 21861,
    "id": 1124174363
  },
  {
    "city": "Woodhouse",
    "city_ascii": "Woodhouse",
    "lat": 53.358,
    "lng": -1.373,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Sheffield",
    "capital": "",
    "population": 17450,
    "id": 1826566992
  },
  {
    "city": "Aldo Bonzi",
    "city_ascii": "Aldo Bonzi",
    "lat": -34.7083,
    "lng": -58.5181,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires",
    "capital": "",
    "population": 18175,
    "id": 1032281187
  },
  {
    "city": "Westbury",
    "city_ascii": "Westbury",
    "lat": 51.26,
    "lng": -2.191,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Wiltshire",
    "capital": "",
    "population": 16989,
    "id": 1826000067
  },
  {
    "city": "Hadleigh",
    "city_ascii": "Hadleigh",
    "lat": 51.5535,
    "lng": 0.6095,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Essex",
    "capital": "",
    "population": 18300,
    "id": 1826599485
  },
  {
    "city": "Goroka",
    "city_ascii": "Goroka",
    "lat": -6.0833,
    "lng": 145.3833,
    "country": "Papua New Guinea",
    "iso2": "PG",
    "iso3": "PNG",
    "admin_name": "Eastern Highlands",
    "capital": "admin",
    "population": 21507,
    "id": 1598928169
  },
  {
    "city": "Kew Green",
    "city_ascii": "Kew Green",
    "lat": 51.5308,
    "lng": -0.2248,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Richmond upon Thames",
    "capital": "",
    "population": 14915,
    "id": 1826123345
  },
  {
    "city": "Roselle",
    "city_ascii": "Roselle",
    "lat": 40.6527,
    "lng": -74.2599,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 21811,
    "id": 1840001092
  },
  {
    "city": "Sunny Isles Beach",
    "city_ascii": "Sunny Isles Beach",
    "lat": 25.9385,
    "lng": -80.1246,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 21804,
    "id": 1840016007
  },
  {
    "city": "Kitagata",
    "city_ascii": "Kitagata",
    "lat": 35.4369,
    "lng": 136.6861,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Gifu",
    "capital": "",
    "population": 18271,
    "id": 1392622247
  },
  {
    "city": "Calne",
    "city_ascii": "Calne",
    "lat": 51.438,
    "lng": -2.005,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Wiltshire",
    "capital": "",
    "population": 17274,
    "id": 1826645118
  },
  {
    "city": "Jasmine Estates",
    "city_ascii": "Jasmine Estates",
    "lat": 28.293,
    "lng": -82.6907,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 21772,
    "id": 1840014116
  },
  {
    "city": "Carmelo",
    "city_ascii": "Carmelo",
    "lat": -33.9999,
    "lng": -58.2847,
    "country": "Uruguay",
    "iso2": "UY",
    "iso3": "URY",
    "admin_name": "Colonia",
    "capital": "",
    "population": 18041,
    "id": 1858670999
  },
  {
    "city": "Acatlán de Osorio",
    "city_ascii": "Acatlan de Osorio",
    "lat": 18.2086,
    "lng": -98.0575,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Puebla",
    "capital": "",
    "population": 18879,
    "id": 1484340141
  },
  {
    "city": "Nantwich",
    "city_ascii": "Nantwich",
    "lat": 53.067,
    "lng": -2.522,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Cheshire East",
    "capital": "",
    "population": 17424,
    "id": 1826640186
  },
  {
    "city": "Nong Bua Lamphu",
    "city_ascii": "Nong Bua Lamphu",
    "lat": 17.2042,
    "lng": 102.4444,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Nong Bua Lamphu",
    "capital": "admin",
    "population": 21528,
    "id": 1764333285
  },
  {
    "city": "Ashtarak",
    "city_ascii": "Ashtarak",
    "lat": 40.3,
    "lng": 44.4,
    "country": "Armenia",
    "iso2": "AM",
    "iso3": "ARM",
    "admin_name": "Aragatsotn",
    "capital": "admin",
    "population": 21600,
    "id": 1051714712
  },
  {
    "city": "Abbots Langley",
    "city_ascii": "Abbots Langley",
    "lat": 51.701,
    "lng": -0.416,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hertfordshire",
    "capital": "",
    "population": 19574,
    "id": 1826455551
  },
  {
    "city": "Obiliq",
    "city_ascii": "Obiliq",
    "lat": 42.69,
    "lng": 21.0778,
    "country": "Kosovo",
    "iso2": "XK",
    "iso3": "XKS",
    "admin_name": "Obiliq",
    "capital": "admin",
    "population": 21549,
    "id": 1901102771
  },
  {
    "city": "North Lynnwood",
    "city_ascii": "North Lynnwood",
    "lat": 47.8533,
    "lng": -122.2762,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 21608,
    "id": 1840037895
  },
  {
    "city": "Hamtramck",
    "city_ascii": "Hamtramck",
    "lat": 42.3954,
    "lng": -83.056,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 21599,
    "id": 1840003980
  },
  {
    "city": "Hampton",
    "city_ascii": "Hampton",
    "lat": 51.422,
    "lng": -0.367,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Richmond upon Thames",
    "capital": "",
    "population": 19372,
    "id": 1826380138
  },
  {
    "city": "Hacıqabul",
    "city_ascii": "Haciqabul",
    "lat": 40.0375,
    "lng": 48.935,
    "country": "Azerbaijan",
    "iso2": "AZ",
    "iso3": "AZE",
    "admin_name": "Hacıqabul",
    "capital": "admin",
    "population": 21504,
    "id": 1031015845
  },
  {
    "city": "Ayutla de los Libres",
    "city_ascii": "Ayutla de los Libres",
    "lat": 16.9,
    "lng": -99.2167,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Guerrero",
    "capital": "minor",
    "population": 15370,
    "id": 1484235149
  },
  {
    "city": "Schlieren",
    "city_ascii": "Schlieren",
    "lat": 47.3989,
    "lng": 8.4497,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Zürich",
    "capital": "",
    "population": 18666,
    "id": 1756013633
  },
  {
    "city": "Johnstone",
    "city_ascii": "Johnstone",
    "lat": 55.8346,
    "lng": -4.5027,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Renfrewshire",
    "capital": "",
    "population": 16625,
    "id": 1826315811
  },
  {
    "city": "Bozoum",
    "city_ascii": "Bozoum",
    "lat": 6.3172,
    "lng": 16.3783,
    "country": "Central African Republic",
    "iso2": "CF",
    "iso3": "CAF",
    "admin_name": "Ouham-Pendé",
    "capital": "admin",
    "population": 20665,
    "id": 1140416562
  },
  {
    "city": "Dover",
    "city_ascii": "Dover",
    "lat": 40.002,
    "lng": -76.8699,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "admin",
    "population": 21479,
    "id": 1840001442
  },
  {
    "city": "Kapolei",
    "city_ascii": "Kapolei",
    "lat": 21.3403,
    "lng": -158.0665,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Hawaii",
    "capital": "",
    "population": 21474,
    "id": 1840025211
  },
  {
    "city": "Mchinji",
    "city_ascii": "Mchinji",
    "lat": -13.8167,
    "lng": 32.9,
    "country": "Malawi",
    "iso2": "MW",
    "iso3": "MWI",
    "admin_name": "Mchinji",
    "capital": "admin",
    "population": 18305,
    "id": 1454607053
  },
  {
    "city": "Pully",
    "city_ascii": "Pully",
    "lat": 46.5167,
    "lng": 6.6667,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Vaud",
    "capital": "",
    "population": 18313,
    "id": 1756456471
  },
  {
    "city": "Patcham",
    "city_ascii": "Patcham",
    "lat": 50.864,
    "lng": -0.15,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Brighton and Hove",
    "capital": "",
    "population": 14277,
    "id": 1826835720
  },
  {
    "city": "Dawlish",
    "city_ascii": "Dawlish",
    "lat": 50.581,
    "lng": -3.466,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Devon",
    "capital": "",
    "population": 15954,
    "id": 1826164730
  },
  {
    "city": "Virovitica",
    "city_ascii": "Virovitica",
    "lat": 45.8333,
    "lng": 17.3833,
    "country": "Croatia",
    "iso2": "HR",
    "iso3": "HRV",
    "admin_name": "Virovitičko-Podravska Županija",
    "capital": "admin",
    "population": 21291,
    "id": 1191128636
  },
  {
    "city": "Mickleover",
    "city_ascii": "Mickleover",
    "lat": 52.901,
    "lng": -1.552,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Derby",
    "capital": "",
    "population": 18000,
    "id": 1826627574
  },
  {
    "city": "Redhill",
    "city_ascii": "Redhill",
    "lat": 51.2393,
    "lng": -0.1726,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Surrey",
    "capital": "",
    "population": 18163,
    "id": 1826029487
  },
  {
    "city": "Maralal",
    "city_ascii": "Maralal",
    "lat": 1.1,
    "lng": 36.7,
    "country": "Kenya",
    "iso2": "KE",
    "iso3": "KEN",
    "admin_name": "Samburu",
    "capital": "admin",
    "population": 20841,
    "id": 1404895664
  },
  {
    "city": "Mountlake Terrace",
    "city_ascii": "Mountlake Terrace",
    "lat": 47.7921,
    "lng": -122.3076,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 21338,
    "id": 1840019792
  },
  {
    "city": "Lennox",
    "city_ascii": "Lennox",
    "lat": 33.938,
    "lng": -118.3586,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 21329,
    "id": 1840017923
  },
  {
    "city": "Hebburn",
    "city_ascii": "Hebburn",
    "lat": 54.9718,
    "lng": -1.5128,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "South Tyneside",
    "capital": "",
    "population": 16492,
    "id": 1826146139
  },
  {
    "city": "North Plainfield",
    "city_ascii": "North Plainfield",
    "lat": 40.6209,
    "lng": -74.4386,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 21289,
    "id": 1840001059
  },
  {
    "city": "Lealman",
    "city_ascii": "Lealman",
    "lat": 27.8197,
    "lng": -82.6847,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 21281,
    "id": 1840025161
  },
  {
    "city": "Ukmergė",
    "city_ascii": "Ukmerge",
    "lat": 55.2667,
    "lng": 24.75,
    "country": "Lithuania",
    "iso2": "LT",
    "iso3": "LTU",
    "admin_name": "Ukmergė",
    "capital": "admin",
    "population": 21226,
    "id": 1440784663
  },
  {
    "city": "Santa Lucía",
    "city_ascii": "Santa Lucia",
    "lat": -34.4525,
    "lng": -56.3964,
    "country": "Uruguay",
    "iso2": "UY",
    "iso3": "URY",
    "admin_name": "Canelones",
    "capital": "",
    "population": 16742,
    "id": 1858287723
  },
  {
    "city": "Obock",
    "city_ascii": "Obock",
    "lat": 11.9667,
    "lng": 43.2833,
    "country": "Djibouti",
    "iso2": "DJ",
    "iso3": "DJI",
    "admin_name": "Obock",
    "capital": "admin",
    "population": 21200,
    "id": 1262002221
  },
  {
    "city": "Orhei",
    "city_ascii": "Orhei",
    "lat": 47.3831,
    "lng": 28.8231,
    "country": "Moldova",
    "iso2": "MD",
    "iso3": "MDA",
    "admin_name": "Orhei",
    "capital": "admin",
    "population": 21065,
    "id": 1498680500
  },
  {
    "city": "Rutana",
    "city_ascii": "Rutana",
    "lat": -3.931,
    "lng": 29.993,
    "country": "Burundi",
    "iso2": "BI",
    "iso3": "BDI",
    "admin_name": "Rutana",
    "capital": "admin",
    "population": 20893,
    "id": 1108441253
  },
  {
    "city": "Adliswil",
    "city_ascii": "Adliswil",
    "lat": 47.3122,
    "lng": 8.5256,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Zürich",
    "capital": "",
    "population": 18731,
    "id": 1756221733
  },
  {
    "city": "La Esperanza",
    "city_ascii": "La Esperanza",
    "lat": 14.3,
    "lng": -88.1833,
    "country": "Honduras",
    "iso2": "HN",
    "iso3": "HND",
    "admin_name": "Intibucá",
    "capital": "admin",
    "population": 21000,
    "id": 1340614286
  },
  {
    "city": "Luena",
    "city_ascii": "Luena",
    "lat": -11.79,
    "lng": 19.9,
    "country": "Angola",
    "iso2": "AO",
    "iso3": "AGO",
    "admin_name": "Moxico",
    "capital": "admin",
    "population": 21115,
    "id": 1024283760
  },
  {
    "city": "Tadaoka-higashi",
    "city_ascii": "Tadaoka-higashi",
    "lat": 34.4869,
    "lng": 135.4011,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Ōsaka",
    "capital": "",
    "population": 16840,
    "id": 1392366184
  },
  {
    "city": "North Bay Shore",
    "city_ascii": "North Bay Shore",
    "lat": 40.7601,
    "lng": -73.2618,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 21045,
    "id": 1840005055
  },
  {
    "city": "Thalwil",
    "city_ascii": "Thalwil",
    "lat": 47.2953,
    "lng": 8.5647,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Zürich",
    "capital": "",
    "population": 17784,
    "id": 1756199955
  },
  {
    "city": "Horsforth",
    "city_ascii": "Horsforth",
    "lat": 53.8341,
    "lng": -1.6429,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Leeds",
    "capital": "",
    "population": 18895,
    "id": 1826006259
  },
  {
    "city": "Sweetwater",
    "city_ascii": "Sweetwater",
    "lat": 25.7786,
    "lng": -80.376,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 20994,
    "id": 1840016008
  },
  {
    "city": "Telavi",
    "city_ascii": "Telavi",
    "lat": 41.9167,
    "lng": 45.4833,
    "country": "Georgia",
    "iso2": "GE",
    "iso3": "GEO",
    "admin_name": "K’akheti",
    "capital": "admin",
    "population": 20900,
    "id": 1268806987
  },
  {
    "city": "Ceres",
    "city_ascii": "Ceres",
    "lat": -15.3033,
    "lng": -49.6052,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Goiás",
    "capital": "",
    "population": 18759,
    "id": 1076087042
  },
  {
    "city": "Hednesford",
    "city_ascii": "Hednesford",
    "lat": 52.7115,
    "lng": -2.0006,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Staffordshire",
    "capital": "",
    "population": 17343,
    "id": 1826749949
  },
  {
    "city": "Makakilo",
    "city_ascii": "Makakilo",
    "lat": 21.3591,
    "lng": -158.0813,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Hawaii",
    "capital": "",
    "population": 20920,
    "id": 1840137080
  },
  {
    "city": "Wolfratshausen",
    "city_ascii": "Wolfratshausen",
    "lat": 47.9133,
    "lng": 11.4278,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 18836,
    "id": 1276706639
  },
  {
    "city": "Impfondo",
    "city_ascii": "Impfondo",
    "lat": 1.6333,
    "lng": 18.0667,
    "country": "Congo (Brazzaville)",
    "iso2": "CG",
    "iso3": "COG",
    "admin_name": "Likouala",
    "capital": "admin",
    "population": 20859,
    "id": 1178021926
  },
  {
    "city": "Quba",
    "city_ascii": "Quba",
    "lat": 41.3653,
    "lng": 48.5264,
    "country": "Azerbaijan",
    "iso2": "AZ",
    "iso3": "AZE",
    "admin_name": "Quba",
    "capital": "admin",
    "population": 20791,
    "id": 1031078072
  },
  {
    "city": "Birstall",
    "city_ascii": "Birstall",
    "lat": 53.7343,
    "lng": -1.6609,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Kirklees",
    "capital": "",
    "population": 16298,
    "id": 1826968702
  },
  {
    "city": "Moston",
    "city_ascii": "Moston",
    "lat": 53.5156,
    "lng": -2.1848,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Manchester",
    "capital": "",
    "population": 14518,
    "id": 1826000013
  },
  {
    "city": "Staines-upon-Thames",
    "city_ascii": "Staines-upon-Thames",
    "lat": 51.433,
    "lng": -0.517,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Surrey",
    "capital": "",
    "population": 18484,
    "id": 1826889783
  },
  {
    "city": "Dolores",
    "city_ascii": "Dolores",
    "lat": -33.5333,
    "lng": -58.2167,
    "country": "Uruguay",
    "iso2": "UY",
    "iso3": "URY",
    "admin_name": "Soriano",
    "capital": "",
    "population": 15753,
    "id": 1858369530
  },
  {
    "city": "Técpan de Galeana",
    "city_ascii": "Tecpan de Galeana",
    "lat": 17.25,
    "lng": -100.6833,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Guerrero",
    "capital": "minor",
    "population": 14638,
    "id": 1484708548
  },
  {
    "city": "Chemax",
    "city_ascii": "Chemax",
    "lat": 20.655,
    "lng": -87.9372,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Yucatán",
    "capital": "",
    "population": 14885,
    "id": 1484867017
  },
  {
    "city": "El Rosario",
    "city_ascii": "El Rosario",
    "lat": 22.9922,
    "lng": -105.8572,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Sinaloa",
    "capital": "minor",
    "population": 16002,
    "id": 1484373649
  },
  {
    "city": "Miahuatlán de Porfirio Díaz",
    "city_ascii": "Miahuatlan de Porfirio Diaz",
    "lat": 16.33,
    "lng": -96.6,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Oaxaca",
    "capital": "minor",
    "population": 17632,
    "id": 1484776246
  },
  {
    "city": "McNair",
    "city_ascii": "McNair",
    "lat": 38.9513,
    "lng": -77.4116,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 20771,
    "id": 1840041794
  },
  {
    "city": "Wibsey",
    "city_ascii": "Wibsey",
    "lat": 53.7672,
    "lng": -1.7728,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Bradford",
    "capital": "",
    "population": 14671,
    "id": 1826433552
  },
  {
    "city": "Palisades Park",
    "city_ascii": "Palisades Park",
    "lat": 40.8472,
    "lng": -73.9967,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 20715,
    "id": 1840000915
  },
  {
    "city": "İsmayıllı",
    "city_ascii": "Ismayilli",
    "lat": 40.7839,
    "lng": 48.1439,
    "country": "Azerbaijan",
    "iso2": "AZ",
    "iso3": "AZE",
    "admin_name": "İsmayıllı",
    "capital": "admin",
    "population": 20660,
    "id": 1031380246
  },
  {
    "city": "Ati",
    "city_ascii": "Ati",
    "lat": 13.2139,
    "lng": 18.3403,
    "country": "Chad",
    "iso2": "TD",
    "iso3": "TCD",
    "admin_name": "Batha",
    "capital": "admin",
    "population": 20695,
    "id": 1148642108
  },
  {
    "city": "El Dorado",
    "city_ascii": "El Dorado",
    "lat": 24.3228,
    "lng": -107.363,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Sinaloa",
    "capital": "",
    "population": 17365,
    "id": 1484719986
  },
  {
    "city": "Nan",
    "city_ascii": "Nan",
    "lat": 18.7833,
    "lng": 100.7833,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Nan",
    "capital": "admin",
    "population": 20595,
    "id": 1764061223
  },
  {
    "city": "Cowley",
    "city_ascii": "Cowley",
    "lat": 51.733,
    "lng": -1.215,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Oxfordshire",
    "capital": "",
    "population": 16500,
    "id": 1826013933
  },
  {
    "city": "Palau",
    "city_ascii": "Palau",
    "lat": 27.9167,
    "lng": -101.4167,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Coahuila de Zaragoza",
    "capital": "",
    "population": 16133,
    "id": 1484142037
  },
  {
    "city": "Porthcawl",
    "city_ascii": "Porthcawl",
    "lat": 51.48,
    "lng": -3.69,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Bridgend",
    "capital": "",
    "population": 16005,
    "id": 1826674732
  },
  {
    "city": "New Brighton",
    "city_ascii": "New Brighton",
    "lat": 53.432,
    "lng": -3.049,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Wirral",
    "capital": "",
    "population": 15149,
    "id": 1826841065
  },
  {
    "city": "Bingley",
    "city_ascii": "Bingley",
    "lat": 53.8509,
    "lng": -1.838,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Bradford",
    "capital": "",
    "population": 15925,
    "id": 1826460602
  },
  {
    "city": "South San Jose Hills",
    "city_ascii": "South San Jose Hills",
    "lat": 34.0123,
    "lng": -117.9041,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 20593,
    "id": 1840019208
  },
  {
    "city": "Yasothon",
    "city_ascii": "Yasothon",
    "lat": 15.7883,
    "lng": 104.1506,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Yasothon",
    "capital": "admin",
    "population": 20414,
    "id": 1764520637
  },
  {
    "city": "South El Monte",
    "city_ascii": "South El Monte",
    "lat": 34.0493,
    "lng": -118.0484,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 20574,
    "id": 1840021869
  },
  {
    "city": "Belgrave",
    "city_ascii": "Belgrave",
    "lat": 52.6566,
    "lng": -1.1262,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Leicester",
    "capital": "",
    "population": 11558,
    "id": 1826809657
  },
  {
    "city": "Baguley",
    "city_ascii": "Baguley",
    "lat": 53.399,
    "lng": -2.276,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Manchester",
    "capital": "",
    "population": 14794,
    "id": 1826354552
  },
  {
    "city": "Warminster",
    "city_ascii": "Warminster",
    "lat": 51.205,
    "lng": -2.181,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Wiltshire",
    "capital": "",
    "population": 17490,
    "id": 1826602244
  },
  {
    "city": "Ostermundigen",
    "city_ascii": "Ostermundigen",
    "lat": 46.9553,
    "lng": 7.4833,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Bern",
    "capital": "",
    "population": 17546,
    "id": 1756779389
  },
  {
    "city": "West Derby",
    "city_ascii": "West Derby",
    "lat": 53.4338,
    "lng": -2.907,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Liverpool",
    "capital": "",
    "population": 14382,
    "id": 1826890297
  },
  {
    "city": "Haslingden",
    "city_ascii": "Haslingden",
    "lat": 53.705,
    "lng": -2.328,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Lancashire",
    "capital": "",
    "population": 16849,
    "id": 1826295809
  },
  {
    "city": "Bubanza",
    "city_ascii": "Bubanza",
    "lat": -3.0833,
    "lng": 29.4,
    "country": "Burundi",
    "iso2": "BI",
    "iso3": "BDI",
    "admin_name": "Bubanza",
    "capital": "admin",
    "population": 20031,
    "id": 1108018348
  },
  {
    "city": "Columbia Heights",
    "city_ascii": "Columbia Heights",
    "lat": 45.0484,
    "lng": -93.2472,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 20427,
    "id": 1840006712
  },
  {
    "city": "Sunbury",
    "city_ascii": "Sunbury",
    "lat": 51.423,
    "lng": -0.424,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Surrey",
    "capital": "",
    "population": 18041,
    "id": 1826417394
  },
  {
    "city": "Stoke Gifford",
    "city_ascii": "Stoke Gifford",
    "lat": 51.517,
    "lng": -2.548,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "South Gloucestershire",
    "capital": "",
    "population": 15494,
    "id": 1826740275
  },
  {
    "city": "Nailsea",
    "city_ascii": "Nailsea",
    "lat": 51.43,
    "lng": -2.76,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "North Somerset",
    "capital": "",
    "population": 15630,
    "id": 1826659439
  },
  {
    "city": "Cleckheaton",
    "city_ascii": "Cleckheaton",
    "lat": 53.725,
    "lng": -1.719,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Kirklees",
    "capital": "",
    "population": 16622,
    "id": 1826679048
  },
  {
    "city": "Somoto",
    "city_ascii": "Somoto",
    "lat": 13.4833,
    "lng": -86.5833,
    "country": "Nicaragua",
    "iso2": "NI",
    "iso3": "NIC",
    "admin_name": "Madriz",
    "capital": "admin",
    "population": 20300,
    "id": 1558605178
  },
  {
    "city": "Senta",
    "city_ascii": "Senta",
    "lat": 45.9231,
    "lng": 20.0731,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Senta",
    "capital": "admin",
    "population": 20302,
    "id": 1688368364
  },
  {
    "city": "Shirley",
    "city_ascii": "Shirley",
    "lat": 51.3813,
    "lng": -0.0543,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Croydon",
    "capital": "",
    "population": 14296,
    "id": 1826702063
  },
  {
    "city": "Leagrave",
    "city_ascii": "Leagrave",
    "lat": 51.903,
    "lng": -0.466,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Luton",
    "capital": "",
    "population": 12910,
    "id": 1826319019
  },
  {
    "city": "Santiago Tuxtla",
    "city_ascii": "Santiago Tuxtla",
    "lat": 18.4704,
    "lng": -95.3,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Veracruz",
    "capital": "minor",
    "population": 15681,
    "id": 1484009696
  },
  {
    "city": "Lomita",
    "city_ascii": "Lomita",
    "lat": 33.7933,
    "lng": -118.3175,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 20320,
    "id": 1840020489
  },
  {
    "city": "Cosham",
    "city_ascii": "Cosham",
    "lat": 50.8424,
    "lng": -1.066,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Portsmouth",
    "capital": "",
    "population": 13830,
    "id": 1826628541
  },
  {
    "city": "Bayshore Gardens",
    "city_ascii": "Bayshore Gardens",
    "lat": 27.4345,
    "lng": -82.5794,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 20293,
    "id": 1840014168
  },
  {
    "city": "Enfield Lock",
    "city_ascii": "Enfield Lock",
    "lat": 51.6686,
    "lng": -0.026,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Enfield",
    "capital": "",
    "population": 16469,
    "id": 1826581952
  },
  {
    "city": "Acala",
    "city_ascii": "Acala",
    "lat": 16.5533,
    "lng": -92.8069,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Chiapas",
    "capital": "minor",
    "population": 13889,
    "id": 1484788928
  },
  {
    "city": "Ağsu",
    "city_ascii": "Agsu",
    "lat": 40.5708,
    "lng": 48.3928,
    "country": "Azerbaijan",
    "iso2": "AZ",
    "iso3": "AZE",
    "admin_name": "Ağsu",
    "capital": "admin",
    "population": 20200,
    "id": 1031748204
  },
  {
    "city": "Āsosa",
    "city_ascii": "Asosa",
    "lat": 10.0667,
    "lng": 34.5167,
    "country": "Ethiopia",
    "iso2": "ET",
    "iso3": "ETH",
    "admin_name": "Bīnshangul Gumuz",
    "capital": "admin",
    "population": 20226,
    "id": 1231491184
  },
  {
    "city": "North Bellmore",
    "city_ascii": "North Bellmore",
    "lat": 40.6904,
    "lng": -73.539,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 20269,
    "id": 1840005240
  },
  {
    "city": "Ağstafa",
    "city_ascii": "Agstafa",
    "lat": 41.1167,
    "lng": 45.45,
    "country": "Azerbaijan",
    "iso2": "AZ",
    "iso3": "AZE",
    "admin_name": "Ağstafa",
    "capital": "admin",
    "population": 20200,
    "id": 1031889683
  },
  {
    "city": "Harwich",
    "city_ascii": "Harwich",
    "lat": 51.934,
    "lng": 1.266,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Essex",
    "capital": "",
    "population": 17684,
    "id": 1826285169
  },
  {
    "city": "Saffron Walden",
    "city_ascii": "Saffron Walden",
    "lat": 52.022,
    "lng": 0.243,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Essex",
    "capital": "",
    "population": 15504,
    "id": 1826424344
  },
  {
    "city": "Basford",
    "city_ascii": "Basford",
    "lat": 52.978,
    "lng": -1.169,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Nottingham",
    "capital": "",
    "population": 16207,
    "id": 1826063569
  },
  {
    "city": "Comrat",
    "city_ascii": "Comrat",
    "lat": 46.3003,
    "lng": 28.6572,
    "country": "Moldova",
    "iso2": "MD",
    "iso3": "MDA",
    "admin_name": "Găgăuzia",
    "capital": "admin",
    "population": 20113,
    "id": 1498677351
  },
  {
    "city": "Guastatoya",
    "city_ascii": "Guastatoya",
    "lat": 14.8539,
    "lng": -90.0686,
    "country": "Guatemala",
    "iso2": "GT",
    "iso3": "GTM",
    "admin_name": "El Progreso",
    "capital": "admin",
    "population": 20050,
    "id": 1320849830
  },
  {
    "city": "Kirkland",
    "city_ascii": "Kirkland",
    "lat": 45.45,
    "lng": -73.8667,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 20151,
    "id": 1124000941
  },
  {
    "city": "Qormi",
    "city_ascii": "Qormi",
    "lat": 35.8794,
    "lng": 14.4722,
    "country": "Malta",
    "iso2": "MT",
    "iso3": "MLT",
    "admin_name": "Qormi",
    "capital": "admin",
    "population": 16779,
    "id": 1470879234
  },
  {
    "city": "West Hempstead",
    "city_ascii": "West Hempstead",
    "lat": 40.6959,
    "lng": -73.6507,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 20116,
    "id": 1840005282
  },
  {
    "city": "Arvayheer",
    "city_ascii": "Arvayheer",
    "lat": 46.2639,
    "lng": 102.775,
    "country": "Mongolia",
    "iso2": "MN",
    "iso3": "MNG",
    "admin_name": "Övörhangay",
    "capital": "admin",
    "population": 20000,
    "id": 1496580180
  },
  {
    "city": "Tyldesley",
    "city_ascii": "Tyldesley",
    "lat": 53.5166,
    "lng": -2.4667,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Wigan",
    "capital": "",
    "population": 16142,
    "id": 1826559568
  },
  {
    "city": "Sligo",
    "city_ascii": "Sligo",
    "lat": 54.2667,
    "lng": -8.4833,
    "country": "Ireland",
    "iso2": "IE",
    "iso3": "IRL",
    "admin_name": "Sligo",
    "capital": "admin",
    "population": 19452,
    "id": 1372185807
  },
  {
    "city": "Harrison",
    "city_ascii": "Harrison",
    "lat": 40.7431,
    "lng": -74.1531,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 20061,
    "id": 1840003598
  },
  {
    "city": "Hilsea",
    "city_ascii": "Hilsea",
    "lat": 50.83,
    "lng": -1.07,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Portsmouth",
    "capital": "",
    "population": 13552,
    "id": 1826746556
  },
  {
    "city": "West Chester",
    "city_ascii": "West Chester",
    "lat": 39.9601,
    "lng": -75.6058,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 20029,
    "id": 1840001436
  },
  {
    "city": "Hakha",
    "city_ascii": "Hakha",
    "lat": 22.65,
    "lng": 93.6167,
    "country": "Burma",
    "iso2": "MM",
    "iso3": "MMR",
    "admin_name": "Chin State",
    "capital": "admin",
    "population": 20000,
    "id": 1104739581
  },
  {
    "city": "Mubende",
    "city_ascii": "Mubende",
    "lat": 0.5904,
    "lng": 31.37,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Mubende",
    "capital": "admin",
    "population": 18936,
    "id": 1800105368
  },
  {
    "city": "Ulcinj",
    "city_ascii": "Ulcinj",
    "lat": 41.9236,
    "lng": 19.2056,
    "country": "Montenegro",
    "iso2": "ME",
    "iso3": "MNE",
    "admin_name": "Ulcinj",
    "capital": "admin",
    "population": 19921,
    "id": 1499867182
  },
  {
    "city": "Tidjikja",
    "city_ascii": "Tidjikja",
    "lat": 18.55,
    "lng": -11.4166,
    "country": "Mauritania",
    "iso2": "MR",
    "iso3": "MRT",
    "admin_name": "Tagant",
    "capital": "admin",
    "population": 19981,
    "id": 1478594780
  },
  {
    "city": "Elmwood Park",
    "city_ascii": "Elmwood Park",
    "lat": 40.905,
    "lng": -74.1201,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 19966,
    "id": 1840003544
  },
  {
    "city": "Stone",
    "city_ascii": "Stone",
    "lat": 52.9,
    "lng": -2.15,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Staffordshire",
    "capital": "",
    "population": 16385,
    "id": 1826761372
  },
  {
    "city": "Morges",
    "city_ascii": "Morges",
    "lat": 46.5094,
    "lng": 6.4986,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Vaud",
    "capital": "",
    "population": 15838,
    "id": 1756647288
  },
  {
    "city": "The Mumbles",
    "city_ascii": "The Mumbles",
    "lat": 51.573,
    "lng": -3.9992,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Swansea",
    "capital": "",
    "population": 16600,
    "id": 1826077083
  },
  {
    "city": "Westmount",
    "city_ascii": "Westmount",
    "lat": 45.4833,
    "lng": -73.6,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 19931,
    "id": 1124878078
  },
  {
    "city": "Fazakerley",
    "city_ascii": "Fazakerley",
    "lat": 53.4676,
    "lng": -2.9408,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Liverpool",
    "capital": "",
    "population": 16786,
    "id": 1826528845
  },
  {
    "city": "Maldon",
    "city_ascii": "Maldon",
    "lat": 51.7318,
    "lng": 0.6758,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Essex",
    "capital": "",
    "population": 14220,
    "id": 1826363463
  },
  {
    "city": "Kebili",
    "city_ascii": "Kebili",
    "lat": 33.69,
    "lng": 8.971,
    "country": "Tunisia",
    "iso2": "TN",
    "iso3": "TUN",
    "admin_name": "Kébili",
    "capital": "admin",
    "population": 19875,
    "id": 1788285135
  },
  {
    "city": "Wombwell",
    "city_ascii": "Wombwell",
    "lat": 53.516,
    "lng": -1.4,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Barnsley",
    "capital": "",
    "population": 15316,
    "id": 1826512301
  },
  {
    "city": "Eldama Ravine",
    "city_ascii": "Eldama Ravine",
    "lat": 0.0504,
    "lng": 35.72,
    "country": "Kenya",
    "iso2": "KE",
    "iso3": "KEN",
    "admin_name": "Baringo",
    "capital": "",
    "population": 17581,
    "id": 1404468256
  },
  {
    "city": "Tilbury",
    "city_ascii": "Tilbury",
    "lat": 51.4606,
    "lng": 0.3582,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Thurrock",
    "capital": "",
    "population": 12450,
    "id": 1826146136
  },
  {
    "city": "Ciudad de Huitzuco",
    "city_ascii": "Ciudad de Huitzuco",
    "lat": 18.3,
    "lng": -99.35,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Guerrero",
    "capital": "minor",
    "population": 16025,
    "id": 1484912284
  },
  {
    "city": "La Cruz",
    "city_ascii": "La Cruz",
    "lat": 23.9214,
    "lng": -106.8919,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Sinaloa",
    "capital": "minor",
    "population": 15657,
    "id": 1484243519
  },
  {
    "city": "Tlaxcala",
    "city_ascii": "Tlaxcala",
    "lat": 19.3191,
    "lng": -98.1998,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Tlaxcala",
    "capital": "admin",
    "population": 14692,
    "id": 1484359502
  },
  {
    "city": "Larkhall",
    "city_ascii": "Larkhall",
    "lat": 55.737,
    "lng": -3.972,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "South Lanarkshire",
    "capital": "",
    "population": 14951,
    "id": 1826423474
  },
  {
    "city": "Tecuala",
    "city_ascii": "Tecuala",
    "lat": 22.4004,
    "lng": -105.46,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Nayarit",
    "capital": "minor",
    "population": 14921,
    "id": 1484955369
  },
  {
    "city": "Schofield Barracks",
    "city_ascii": "Schofield Barracks",
    "lat": 21.4936,
    "lng": -158.0617,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Hawaii",
    "capital": "",
    "population": 19796,
    "id": 1840075052
  },
  {
    "city": "Norwood",
    "city_ascii": "Norwood",
    "lat": 39.1605,
    "lng": -84.4535,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 19776,
    "id": 1840001610
  },
  {
    "city": "Ciudad Guadalupe Victoria",
    "city_ascii": "Ciudad Guadalupe Victoria",
    "lat": 24.4497,
    "lng": -104.1225,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Durango",
    "capital": "minor",
    "population": 16506,
    "id": 1484654052
  },
  {
    "city": "Kavieng",
    "city_ascii": "Kavieng",
    "lat": -2.5667,
    "lng": 150.8,
    "country": "Papua New Guinea",
    "iso2": "PG",
    "iso3": "PNG",
    "admin_name": "New Ireland",
    "capital": "admin",
    "population": 19728,
    "id": 1598946326
  },
  {
    "city": "Franconia",
    "city_ascii": "Franconia",
    "lat": 38.7682,
    "lng": -77.1587,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 19725,
    "id": 1840006027
  },
  {
    "city": "Clayton",
    "city_ascii": "Clayton",
    "lat": 53.782,
    "lng": -1.8135,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Bradford",
    "capital": "",
    "population": 16982,
    "id": 1826432335
  },
  {
    "city": "Albany",
    "city_ascii": "Albany",
    "lat": 37.8898,
    "lng": -122.3018,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 19696,
    "id": 1840028140
  },
  {
    "city": "Faranah",
    "city_ascii": "Faranah",
    "lat": 10.0404,
    "lng": -10.75,
    "country": "Guinea",
    "iso2": "GN",
    "iso3": "GIN",
    "admin_name": "Faranah",
    "capital": "admin",
    "population": 19469,
    "id": 1324603099
  },
  {
    "city": "Phra Pradaeng",
    "city_ascii": "Phra Pradaeng",
    "lat": 13.659,
    "lng": 100.5329,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Samut Prakan",
    "capital": "minor",
    "population": 10190,
    "id": 1764849819
  },
  {
    "city": "Milton",
    "city_ascii": "Milton",
    "lat": 53.05,
    "lng": -2.142,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Stoke-on-Trent",
    "capital": "",
    "population": 17250,
    "id": 1826543368
  },
  {
    "city": "Santa Catarina Juquila",
    "city_ascii": "Santa Catarina Juquila",
    "lat": 16.2364,
    "lng": -97.2919,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Oaxaca",
    "capital": "",
    "population": 15987,
    "id": 1484108848
  },
  {
    "city": "Kidbrooke",
    "city_ascii": "Kidbrooke",
    "lat": 51.465,
    "lng": 0.033,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Greenwich",
    "capital": "",
    "population": 14300,
    "id": 1826659843
  },
  {
    "city": "Shipley",
    "city_ascii": "Shipley",
    "lat": 53.833,
    "lng": -1.777,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Bradford",
    "capital": "",
    "population": 15483,
    "id": 1826056604
  },
  {
    "city": "Lezhë",
    "city_ascii": "Lezhe",
    "lat": 41.7805,
    "lng": 19.6434,
    "country": "Albania",
    "iso2": "AL",
    "iso3": "ALB",
    "admin_name": "Lezhë",
    "capital": "admin",
    "population": 15510,
    "id": 1008889153
  },
  {
    "city": "Santiago Tulantepec",
    "city_ascii": "Santiago Tulantepec",
    "lat": 20.0397,
    "lng": -98.3575,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Hidalgo",
    "capital": "minor",
    "population": 16078,
    "id": 1484221851
  },
  {
    "city": "Central Falls",
    "city_ascii": "Central Falls",
    "lat": 41.89,
    "lng": -71.3934,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Rhode Island",
    "capital": "",
    "population": 19568,
    "id": 1840003286
  },
  {
    "city": "Whickham",
    "city_ascii": "Whickham",
    "lat": 54.9456,
    "lng": -1.6726,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Gateshead",
    "capital": "",
    "population": 16652,
    "id": 1826888408
  },
  {
    "city": "Rodolfo Sánchez Taboada",
    "city_ascii": "Rodolfo Sanchez Taboada",
    "lat": 31.7958,
    "lng": -116.5911,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Baja California",
    "capital": "",
    "population": 15038,
    "id": 1484409365
  },
  {
    "city": "Deysbrook",
    "city_ascii": "Deysbrook",
    "lat": 53.429,
    "lng": -2.934,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Liverpool",
    "capital": "",
    "population": 14490,
    "id": 1826441033
  },
  {
    "city": "Mont-Royal",
    "city_ascii": "Mont-Royal",
    "lat": 45.5161,
    "lng": -73.6431,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 19503,
    "id": 1124001920
  },
  {
    "city": "La Crescenta-Montrose",
    "city_ascii": "La Crescenta-Montrose",
    "lat": 34.2322,
    "lng": -118.2353,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 19500,
    "id": 1840074182
  },
  {
    "city": "Ciudad Hidalgo",
    "city_ascii": "Ciudad Hidalgo",
    "lat": 14.6792,
    "lng": -92.1497,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Chiapas",
    "capital": "minor",
    "population": 14606,
    "id": 1484360693
  },
  {
    "city": "Cárdenas",
    "city_ascii": "Cardenas",
    "lat": 22.0103,
    "lng": -99.6522,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "San Luis Potosí",
    "capital": "minor",
    "population": 15331,
    "id": 1484956696
  },
  {
    "city": "Barton upon Irwell",
    "city_ascii": "Barton upon Irwell",
    "lat": 53.476,
    "lng": -2.36,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Salford",
    "capital": "",
    "population": 12462,
    "id": 1826910370
  },
  {
    "city": "Solothurn",
    "city_ascii": "Solothurn",
    "lat": 47.2081,
    "lng": 7.5375,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Solothurn",
    "capital": "admin",
    "population": 16777,
    "id": 1756650126
  },
  {
    "city": "Lynbrook",
    "city_ascii": "Lynbrook",
    "lat": 40.6579,
    "lng": -73.6742,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 19448,
    "id": 1840005304
  },
  {
    "city": "Laï",
    "city_ascii": "Lai",
    "lat": 9.4,
    "lng": 16.3,
    "country": "Chad",
    "iso2": "TD",
    "iso3": "TCD",
    "admin_name": "Tandjilé",
    "capital": "admin",
    "population": 19382,
    "id": 1148360693
  },
  {
    "city": "Bonnyrigg",
    "city_ascii": "Bonnyrigg",
    "lat": 55.8747,
    "lng": -3.1031,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Midlothian",
    "capital": "",
    "population": 15677,
    "id": 1826693791
  },
  {
    "city": "Budva",
    "city_ascii": "Budva",
    "lat": 42.2847,
    "lng": 18.8453,
    "country": "Montenegro",
    "iso2": "ME",
    "iso3": "MNE",
    "admin_name": "Budva",
    "capital": "admin",
    "population": 19218,
    "id": 1499564238
  },
  {
    "city": "Sơn La",
    "city_ascii": "Son La",
    "lat": 21.327,
    "lng": 103.9141,
    "country": "Vietnam",
    "iso2": "VN",
    "iso3": "VNM",
    "admin_name": "Sơn La",
    "capital": "admin",
    "population": 19054,
    "id": 1704957010
  },
  {
    "city": "Tchibanga",
    "city_ascii": "Tchibanga",
    "lat": -2.9331,
    "lng": 10.9831,
    "country": "Gabon",
    "iso2": "GA",
    "iso3": "GAB",
    "admin_name": "Nyanga",
    "capital": "admin",
    "population": 19365,
    "id": 1266060335
  },
  {
    "city": "Macas",
    "city_ascii": "Macas",
    "lat": -2.3,
    "lng": -78.1167,
    "country": "Ecuador",
    "iso2": "EC",
    "iso3": "ECU",
    "admin_name": "Morona-Santiago",
    "capital": "admin",
    "population": 18984,
    "id": 1218176372
  },
  {
    "city": "Partick",
    "city_ascii": "Partick",
    "lat": 55.8699,
    "lng": -4.3125,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Glasgow City",
    "capital": "",
    "population": 8884,
    "id": 1826598697
  },
  {
    "city": "Netherton",
    "city_ascii": "Netherton",
    "lat": 52.4908,
    "lng": -2.0835,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Dudley",
    "capital": "",
    "population": 15017,
    "id": 1826114854
  },
  {
    "city": "Hermosa Beach",
    "city_ascii": "Hermosa Beach",
    "lat": 33.8654,
    "lng": -118.3966,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 19320,
    "id": 1840020487
  },
  {
    "city": "Tillabéri",
    "city_ascii": "Tillaberi",
    "lat": 14.212,
    "lng": 1.4531,
    "country": "Niger",
    "iso2": "NE",
    "iso3": "NER",
    "admin_name": "Tillabéri",
    "capital": "admin",
    "population": 19262,
    "id": 1562317955
  },
  {
    "city": "Seacroft",
    "city_ascii": "Seacroft",
    "lat": 53.8222,
    "lng": -1.4599,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Leeds",
    "capital": "",
    "population": 14246,
    "id": 1826045634
  },
  {
    "city": "Kawachichō",
    "city_ascii": "Kawachicho",
    "lat": 34.5783,
    "lng": 135.7367,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Ōsaka",
    "capital": "",
    "population": 17216,
    "id": 1392309686
  },
  {
    "city": "Tepoztlán",
    "city_ascii": "Tepoztlan",
    "lat": 18.9853,
    "lng": -99.0997,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Morelos",
    "capital": "minor",
    "population": 14130,
    "id": 1484948191
  },
  {
    "city": "Attapu",
    "city_ascii": "Attapu",
    "lat": 14.8,
    "lng": 106.8333,
    "country": "Laos",
    "iso2": "LA",
    "iso3": "LAO",
    "admin_name": "Attapu",
    "capital": "admin",
    "population": 19200,
    "id": 1418894454
  },
  {
    "city": "Langley Park",
    "city_ascii": "Langley Park",
    "lat": 38.9897,
    "lng": -76.9808,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 19278,
    "id": 1840005959
  },
  {
    "city": "Morristown",
    "city_ascii": "Morristown",
    "lat": 40.7966,
    "lng": -74.4772,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 19261,
    "id": 1840003577
  },
  {
    "city": "Baildon",
    "city_ascii": "Baildon",
    "lat": 53.851,
    "lng": -1.763,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Bradford",
    "capital": "",
    "population": 15360,
    "id": 1826493288
  },
  {
    "city": "Sherrelwood",
    "city_ascii": "Sherrelwood",
    "lat": 39.839,
    "lng": -105.0014,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 19243,
    "id": 1840028581
  },
  {
    "city": "Mineola",
    "city_ascii": "Mineola",
    "lat": 40.7469,
    "lng": -73.6392,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 19207,
    "id": 1840005310
  },
  {
    "city": "North Valley Stream",
    "city_ascii": "North Valley Stream",
    "lat": 40.684,
    "lng": -73.7077,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 19202,
    "id": 1840005244
  },
  {
    "city": "Harrow Weald",
    "city_ascii": "Harrow Weald",
    "lat": 51.604,
    "lng": -0.339,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Harrow",
    "capital": "",
    "population": 11376,
    "id": 1826880341
  },
  {
    "city": "Evergreen Park",
    "city_ascii": "Evergreen Park",
    "lat": 41.7213,
    "lng": -87.7013,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 19147,
    "id": 1840011273
  },
  {
    "city": "Addlestone",
    "city_ascii": "Addlestone",
    "lat": 51.3695,
    "lng": -0.4901,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Surrey",
    "capital": "",
    "population": 15883,
    "id": 1826253359
  },
  {
    "city": "Pemberton",
    "city_ascii": "Pemberton",
    "lat": 53.536,
    "lng": -2.6738,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Wigan",
    "capital": "",
    "population": 13982,
    "id": 1826584870
  },
  {
    "city": "Baabda",
    "city_ascii": "Baabda",
    "lat": 33.8333,
    "lng": 35.5333,
    "country": "Lebanon",
    "iso2": "LB",
    "iso3": "LBN",
    "admin_name": "Mont-Liban",
    "capital": "admin",
    "population": 9000,
    "id": 1422639397
  },
  {
    "city": "West Wickham",
    "city_ascii": "West Wickham",
    "lat": 51.3765,
    "lng": -0.0193,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Bromley",
    "capital": "",
    "population": 14276,
    "id": 1826669089
  },
  {
    "city": "Binningen",
    "city_ascii": "Binningen",
    "lat": 47.5333,
    "lng": 7.5667,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Basel-Landschaft",
    "capital": "",
    "population": 15580,
    "id": 1756488918
  },
  {
    "city": "Apaxco de Ocampo",
    "city_ascii": "Apaxco de Ocampo",
    "lat": 19.9733,
    "lng": -99.17,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Hidalgo",
    "capital": "minor",
    "population": 13836,
    "id": 1484505240
  },
  {
    "city": "Mao",
    "city_ascii": "Mao",
    "lat": 14.1194,
    "lng": 15.3133,
    "country": "Chad",
    "iso2": "TD",
    "iso3": "TCD",
    "admin_name": "Kanem",
    "capital": "admin",
    "population": 19004,
    "id": 1148740154
  },
  {
    "city": "Saltash",
    "city_ascii": "Saltash",
    "lat": 50.408,
    "lng": -4.212,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Cornwall",
    "capital": "",
    "population": 16184,
    "id": 1826455741
  },
  {
    "city": "Dzuunmod",
    "city_ascii": "Dzuunmod",
    "lat": 47.7069,
    "lng": 106.9494,
    "country": "Mongolia",
    "iso2": "MN",
    "iso3": "MNG",
    "admin_name": "Töv",
    "capital": "admin",
    "population": 18048,
    "id": 1496245822
  },
  {
    "city": "Wellington",
    "city_ascii": "Wellington",
    "lat": 50.9755,
    "lng": -3.2243,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Somerset",
    "capital": "",
    "population": 14549,
    "id": 1826413119
  },
  {
    "city": "Bellaire",
    "city_ascii": "Bellaire",
    "lat": 29.704,
    "lng": -95.4622,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 18971,
    "id": 1840019617
  },
  {
    "city": "Härnösand",
    "city_ascii": "Harnosand",
    "lat": 62.6323,
    "lng": 17.9379,
    "country": "Sweden",
    "iso2": "SE",
    "iso3": "SWE",
    "admin_name": "Västernorrland",
    "capital": "admin",
    "population": 18624,
    "id": 1752644953
  },
  {
    "city": "Keetmanshoop",
    "city_ascii": "Keetmanshoop",
    "lat": -26.5833,
    "lng": 18.1333,
    "country": "Namibia",
    "iso2": "NA",
    "iso3": "NAM",
    "admin_name": "//Karas",
    "capital": "admin",
    "population": 18900,
    "id": 1516607376
  },
  {
    "city": "Paraguarí",
    "city_ascii": "Paraguari",
    "lat": -25.62,
    "lng": -57.16,
    "country": "Paraguay",
    "iso2": "PY",
    "iso3": "PRY",
    "admin_name": "Paraguarí",
    "capital": "admin",
    "population": 18881,
    "id": 1600581132
  },
  {
    "city": "Hazel Grove",
    "city_ascii": "Hazel Grove",
    "lat": 53.375,
    "lng": -2.111,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Stockport",
    "capital": "",
    "population": 15265,
    "id": 1826167274
  },
  {
    "city": "Northenden",
    "city_ascii": "Northenden",
    "lat": 53.4075,
    "lng": -2.2583,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Manchester",
    "capital": "",
    "population": 15200,
    "id": 1826100514
  },
  {
    "city": "Hinche",
    "city_ascii": "Hinche",
    "lat": 19.143,
    "lng": -72.004,
    "country": "Haiti",
    "iso2": "HT",
    "iso3": "HTI",
    "admin_name": "Centre",
    "capital": "admin",
    "population": 18590,
    "id": 1332748356
  },
  {
    "city": "Hūn",
    "city_ascii": "Hun",
    "lat": 29.1167,
    "lng": 15.9333,
    "country": "Libya",
    "iso2": "LY",
    "iso3": "LBY",
    "admin_name": "Al Jufrah",
    "capital": "admin",
    "population": 18878,
    "id": 1434396501
  },
  {
    "city": "Kimbe",
    "city_ascii": "Kimbe",
    "lat": -5.55,
    "lng": 150.143,
    "country": "Papua New Guinea",
    "iso2": "PG",
    "iso3": "PNG",
    "admin_name": "West New Britain",
    "capital": "admin",
    "population": 18847,
    "id": 1598372456
  },
  {
    "city": "Đông Hà",
    "city_ascii": "Dong Ha",
    "lat": 16.8056,
    "lng": 107.0906,
    "country": "Vietnam",
    "iso2": "VN",
    "iso3": "VNM",
    "admin_name": "Quảng Trị",
    "capital": "admin",
    "population": 17662,
    "id": 1704264596
  },
  {
    "city": "Puréparo de Echaíz",
    "city_ascii": "Pureparo de Echaiz",
    "lat": 19.9,
    "lng": -102,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Michoacán de Ocampo",
    "capital": "",
    "population": 15255,
    "id": 1484222410
  },
  {
    "city": "Visaginas",
    "city_ascii": "Visaginas",
    "lat": 55.6,
    "lng": 26.4333,
    "country": "Lithuania",
    "iso2": "LT",
    "iso3": "LTU",
    "admin_name": "Visaginas",
    "capital": "admin",
    "population": 18514,
    "id": 1440984232
  },
  {
    "city": "Avenel",
    "city_ascii": "Avenel",
    "lat": 40.5839,
    "lng": -74.272,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 18831,
    "id": 1840005410
  },
  {
    "city": "North Amityville",
    "city_ascii": "North Amityville",
    "lat": 40.7005,
    "lng": -73.4118,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 18817,
    "id": 1840005053
  },
  {
    "city": "Mansa Konko",
    "city_ascii": "Mansa Konko",
    "lat": 13.3773,
    "lng": -15.6786,
    "country": "Gambia, The",
    "iso2": "GM",
    "iso3": "GMB",
    "admin_name": "Lower River",
    "capital": "admin",
    "population": 18672,
    "id": 1270071162
  },
  {
    "city": "Prestwick",
    "city_ascii": "Prestwick",
    "lat": 55.4956,
    "lng": -4.6142,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "South Ayrshire",
    "capital": "",
    "population": 14750,
    "id": 1826710421
  },
  {
    "city": "Point Pleasant",
    "city_ascii": "Point Pleasant",
    "lat": 40.0772,
    "lng": -74.0702,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 18772,
    "id": 1840001485
  },
  {
    "city": "Pakwach",
    "city_ascii": "Pakwach",
    "lat": 2.45,
    "lng": 31.5,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Nebbi",
    "capital": "admin",
    "population": 17541,
    "id": 1800204374
  },
  {
    "city": "Hawthorne",
    "city_ascii": "Hawthorne",
    "lat": 40.9579,
    "lng": -74.1581,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 18753,
    "id": 1840003513
  },
  {
    "city": "La Crucecita",
    "city_ascii": "La Crucecita",
    "lat": 15.7753,
    "lng": -96.1425,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Oaxaca",
    "capital": "",
    "population": 13044,
    "id": 1484030886
  },
  {
    "city": "Rwamagana",
    "city_ascii": "Rwamagana",
    "lat": -1.9525,
    "lng": 30.4378,
    "country": "Rwanda",
    "iso2": "RW",
    "iso3": "RWA",
    "admin_name": "Eastern Province",
    "capital": "admin",
    "population": 18009,
    "id": 1646996571
  },
  {
    "city": "Buckley",
    "city_ascii": "Buckley",
    "lat": 53.172,
    "lng": -3.086,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Flintshire",
    "capital": "",
    "population": 15665,
    "id": 1826585914
  },
  {
    "city": "Rock Ferry",
    "city_ascii": "Rock Ferry",
    "lat": 53.373,
    "lng": -3.008,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Wirral",
    "capital": "",
    "population": 14298,
    "id": 1826250027
  },
  {
    "city": "Banstead",
    "city_ascii": "Banstead",
    "lat": 51.322,
    "lng": -0.204,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Surrey",
    "capital": "",
    "population": 16666,
    "id": 1826738493
  },
  {
    "city": "Siyəzən",
    "city_ascii": "Siyazan",
    "lat": 41.0783,
    "lng": 49.1061,
    "country": "Azerbaijan",
    "iso2": "AZ",
    "iso3": "AZE",
    "admin_name": "Siyəzən",
    "capital": "admin",
    "population": 18655,
    "id": 1031277547
  },
  {
    "city": "Hythe",
    "city_ascii": "Hythe",
    "lat": 51.0716,
    "lng": 1.084,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Kent",
    "capital": "",
    "population": 14516,
    "id": 1826972409
  },
  {
    "city": "Bellwood",
    "city_ascii": "Bellwood",
    "lat": 41.8829,
    "lng": -87.8762,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 18672,
    "id": 1840011248
  },
  {
    "city": "Vicente Guerrero",
    "city_ascii": "Vicente Guerrero",
    "lat": 23.75,
    "lng": -103.9833,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Durango",
    "capital": "minor",
    "population": 15982,
    "id": 1484862775
  },
  {
    "city": "Leposaviq",
    "city_ascii": "Leposaviq",
    "lat": 43.1,
    "lng": 20.8,
    "country": "Kosovo",
    "iso2": "XK",
    "iso3": "XKS",
    "admin_name": "Leposaviq",
    "capital": "admin",
    "population": 18600,
    "id": 1901974597
  },
  {
    "city": "Goulmima",
    "city_ascii": "Goulmima",
    "lat": 31.6944,
    "lng": -4.9592,
    "country": "Morocco",
    "iso2": "MA",
    "iso3": "MAR",
    "admin_name": "Drâa-Tafilalet",
    "capital": "",
    "population": 16419,
    "id": 1504139417
  },
  {
    "city": "Sabirabad",
    "city_ascii": "Sabirabad",
    "lat": 40.0053,
    "lng": 48.4719,
    "country": "Azerbaijan",
    "iso2": "AZ",
    "iso3": "AZE",
    "admin_name": "Sabirabad",
    "capital": "admin",
    "population": 18612,
    "id": 1031302623
  },
  {
    "city": "Clitheroe",
    "city_ascii": "Clitheroe",
    "lat": 53.8711,
    "lng": -2.3916,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Lancashire",
    "capital": "",
    "population": 16279,
    "id": 1826660316
  },
  {
    "city": "Bromborough",
    "city_ascii": "Bromborough",
    "lat": 53.336,
    "lng": -2.978,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Wirral",
    "capital": "",
    "population": 14850,
    "id": 1826223984
  },
  {
    "city": "North Massapequa",
    "city_ascii": "North Massapequa",
    "lat": 40.7031,
    "lng": -73.4678,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 18609,
    "id": 1840005241
  },
  {
    "city": "Molde",
    "city_ascii": "Molde",
    "lat": 62.7483,
    "lng": 7.1833,
    "country": "Norway",
    "iso2": "NO",
    "iso3": "NOR",
    "admin_name": "Møre og Romsdal",
    "capital": "admin",
    "population": 18594,
    "id": 1578540727
  },
  {
    "city": "Viljandi",
    "city_ascii": "Viljandi",
    "lat": 58.3633,
    "lng": 25.5956,
    "country": "Estonia",
    "iso2": "EE",
    "iso3": "EST",
    "admin_name": "Viljandimaa",
    "capital": "admin",
    "population": 17407,
    "id": 1233067368
  },
  {
    "city": "Hendaye",
    "city_ascii": "Hendaye",
    "lat": 43.3586,
    "lng": -1.7744,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Nouvelle-Aquitaine",
    "capital": "",
    "population": 16484,
    "id": 1250942695
  },
  {
    "city": "Tsetserleg",
    "city_ascii": "Tsetserleg",
    "lat": 47.4769,
    "lng": 101.4503,
    "country": "Mongolia",
    "iso2": "MN",
    "iso3": "MNG",
    "admin_name": "Arhangay",
    "capital": "admin",
    "population": 18519,
    "id": 1496397451
  },
  {
    "city": "Winthrop",
    "city_ascii": "Winthrop",
    "lat": 42.3751,
    "lng": -70.9847,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 18544,
    "id": 1840132450
  },
  {
    "city": "Cerritos",
    "city_ascii": "Cerritos",
    "lat": 22.4275,
    "lng": -100.2783,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "San Luis Potosí",
    "capital": "minor",
    "population": 14804,
    "id": 1484001904
  },
  {
    "city": "Straşeni",
    "city_ascii": "Straseni",
    "lat": 47.1414,
    "lng": 28.6103,
    "country": "Moldova",
    "iso2": "MD",
    "iso3": "MDA",
    "admin_name": "Străşeni",
    "capital": "admin",
    "population": 18376,
    "id": 1498773145
  },
  {
    "city": "Beausoleil",
    "city_ascii": "Beausoleil",
    "lat": 43.7419,
    "lng": 7.4236,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Provence-Alpes-Côte d’Azur",
    "capital": "",
    "population": 13607,
    "id": 1250505648
  },
  {
    "city": "Pedreiras",
    "city_ascii": "Pedreiras",
    "lat": -4.5696,
    "lng": -44.67,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Maranhão",
    "capital": "",
    "population": 13638,
    "id": 1076527878
  },
  {
    "city": "Idylwood",
    "city_ascii": "Idylwood",
    "lat": 38.8896,
    "lng": -77.2055,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 18431,
    "id": 1840006032
  },
  {
    "city": "Izamal",
    "city_ascii": "Izamal",
    "lat": 20.9314,
    "lng": -89.0178,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Yucatán",
    "capital": "",
    "population": 15101,
    "id": 1484567751
  },
  {
    "city": "Melksham",
    "city_ascii": "Melksham",
    "lat": 51.371,
    "lng": -2.138,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Wiltshire",
    "capital": "",
    "population": 14677,
    "id": 1826642248
  },
  {
    "city": "Yiewsley",
    "city_ascii": "Yiewsley",
    "lat": 51.513,
    "lng": -0.471,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hillingdon",
    "capital": "",
    "population": 12979,
    "id": 1826369645
  },
  {
    "city": "Coquimatlán",
    "city_ascii": "Coquimatlan",
    "lat": 19.2038,
    "lng": -103.8086,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Colima",
    "capital": "minor",
    "population": 13358,
    "id": 1484193381
  },
  {
    "city": "Bidston",
    "city_ascii": "Bidston",
    "lat": 53.402,
    "lng": -3.078,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Wirral",
    "capital": "",
    "population": 15216,
    "id": 1826626467
  },
  {
    "city": "Bryn Mawr-Skyway",
    "city_ascii": "Bryn Mawr-Skyway",
    "lat": 47.4949,
    "lng": -122.2411,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 18347,
    "id": 1840037012
  },
  {
    "city": "Seabrook",
    "city_ascii": "Seabrook",
    "lat": 38.9802,
    "lng": -76.8502,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 18345,
    "id": 1840024547
  },
  {
    "city": "Yamazaki",
    "city_ascii": "Yamazaki",
    "lat": 34.9028,
    "lng": 135.6886,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Ōsaka",
    "capital": "",
    "population": 15704,
    "id": 1392292420
  },
  {
    "city": "East Massapequa",
    "city_ascii": "East Massapequa",
    "lat": 40.6743,
    "lng": -73.4358,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 18331,
    "id": 1840034047
  },
  {
    "city": "Forfar",
    "city_ascii": "Forfar",
    "lat": 56.6442,
    "lng": -2.8884,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Angus",
    "capital": "",
    "population": 14048,
    "id": 1826527188
  },
  {
    "city": "Wallisellen",
    "city_ascii": "Wallisellen",
    "lat": 47.4122,
    "lng": 8.5922,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Zürich",
    "capital": "",
    "population": 15855,
    "id": 1756960820
  },
  {
    "city": "São Gabriel da Cachoeira",
    "city_ascii": "Sao Gabriel da Cachoeira",
    "lat": -0.1332,
    "lng": -67.0833,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Amazonas",
    "capital": "",
    "population": 15231,
    "id": 1076863074
  },
  {
    "city": "Brookfield",
    "city_ascii": "Brookfield",
    "lat": 41.8245,
    "lng": -87.847,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 18310,
    "id": 1840010142
  },
  {
    "city": "Rutherford",
    "city_ascii": "Rutherford",
    "lat": 40.8203,
    "lng": -74.1057,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 18303,
    "id": 1840000902
  },
  {
    "city": "Bebington",
    "city_ascii": "Bebington",
    "lat": 53.35,
    "lng": -3.003,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Wirral",
    "capital": "",
    "population": 15768,
    "id": 1826464817
  },
  {
    "city": "Madera",
    "city_ascii": "Madera",
    "lat": 29.19,
    "lng": -108.1414,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Chihuahua",
    "capital": "minor",
    "population": 15447,
    "id": 1484958144
  },
  {
    "city": "Iselin",
    "city_ascii": "Iselin",
    "lat": 40.5702,
    "lng": -74.317,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 18274,
    "id": 1840005418
  },
  {
    "city": "Tindouf",
    "city_ascii": "Tindouf",
    "lat": 27.6742,
    "lng": -8.1478,
    "country": "Algeria",
    "iso2": "DZ",
    "iso3": "DZA",
    "admin_name": "Tindouf",
    "capital": "admin",
    "population": 18270,
    "id": 1012450434
  },
  {
    "city": "Kédougou",
    "city_ascii": "Kedougou",
    "lat": 12.5556,
    "lng": -12.1807,
    "country": "Senegal",
    "iso2": "SN",
    "iso3": "SEN",
    "admin_name": "Kédougou",
    "capital": "admin",
    "population": 18226,
    "id": 1686320341
  },
  {
    "city": "Burke Centre",
    "city_ascii": "Burke Centre",
    "lat": 38.7903,
    "lng": -77.2999,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 18236,
    "id": 1840041683
  },
  {
    "city": "Hyattsville",
    "city_ascii": "Hyattsville",
    "lat": 38.9612,
    "lng": -76.9548,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 18230,
    "id": 1840005975
  },
  {
    "city": "Pendlebury",
    "city_ascii": "Pendlebury",
    "lat": 53.5075,
    "lng": -2.3154,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Salford",
    "capital": "",
    "population": 13069,
    "id": 1826555461
  },
  {
    "city": "Dedza",
    "city_ascii": "Dedza",
    "lat": -14.3667,
    "lng": 34.3333,
    "country": "Malawi",
    "iso2": "MW",
    "iso3": "MWI",
    "admin_name": "Dedza",
    "capital": "admin",
    "population": 15608,
    "id": 1454412462
  },
  {
    "city": "Gornalwood",
    "city_ascii": "Gornalwood",
    "lat": 52.523,
    "lng": -2.124,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Dudley",
    "capital": "",
    "population": 12992,
    "id": 1826716353
  },
  {
    "city": "Llantrisant",
    "city_ascii": "Llantrisant",
    "lat": 51.5423,
    "lng": -3.3785,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Rhondda Cynon Taff",
    "capital": "",
    "population": 15313,
    "id": 1826229863
  },
  {
    "city": "Sing Buri",
    "city_ascii": "Sing Buri",
    "lat": 14.8911,
    "lng": 100.4031,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Sing Buri",
    "capital": "admin",
    "population": 17843,
    "id": 1764482111
  },
  {
    "city": "Hessle",
    "city_ascii": "Hessle",
    "lat": 53.7239,
    "lng": -0.4319,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "East Riding of Yorkshire",
    "capital": "",
    "population": 15000,
    "id": 1826433714
  },
  {
    "city": "Royston",
    "city_ascii": "Royston",
    "lat": 52.0471,
    "lng": -0.0202,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hertfordshire",
    "capital": "",
    "population": 15781,
    "id": 1826165038
  },
  {
    "city": "Prachin Buri",
    "city_ascii": "Prachin Buri",
    "lat": 14.0567,
    "lng": 101.3739,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Prachin Buri",
    "capital": "admin",
    "population": 17768,
    "id": 1764262472
  },
  {
    "city": "Amblecote",
    "city_ascii": "Amblecote",
    "lat": 52.46,
    "lng": -2.16,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Dudley",
    "capital": "",
    "population": 13393,
    "id": 1826685308
  },
  {
    "city": "Saint Budeaux",
    "city_ascii": "Saint Budeaux",
    "lat": 50.4033,
    "lng": -4.1856,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Plymouth",
    "capital": "",
    "population": 13369,
    "id": 1826218483
  },
  {
    "city": "Plungė",
    "city_ascii": "Plunge",
    "lat": 55.9167,
    "lng": 21.85,
    "country": "Lithuania",
    "iso2": "LT",
    "iso3": "LTU",
    "admin_name": "Plungė",
    "capital": "admin",
    "population": 18042,
    "id": 1440542185
  },
  {
    "city": "Sainte-Marthe-sur-le-Lac",
    "city_ascii": "Sainte-Marthe-sur-le-Lac",
    "lat": 45.53,
    "lng": -73.93,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 18074,
    "id": 1124001153
  },
  {
    "city": "Tunapuna",
    "city_ascii": "Tunapuna",
    "lat": 10.6333,
    "lng": -61.3833,
    "country": "Trinidad And Tobago",
    "iso2": "TT",
    "iso3": "TTO",
    "admin_name": "Tunapuna/Piarco",
    "capital": "admin",
    "population": 17650,
    "id": 1780508155
  },
  {
    "city": "Prilly",
    "city_ascii": "Prilly",
    "lat": 46.5333,
    "lng": 6.6,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Vaud",
    "capital": "",
    "population": 12399,
    "id": 1756590980
  },
  {
    "city": "Selby",
    "city_ascii": "Selby",
    "lat": 53.7818,
    "lng": -1.0703,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "North Yorkshire",
    "capital": "",
    "population": 14731,
    "id": 1826504320
  },
  {
    "city": "Orange Walk",
    "city_ascii": "Orange Walk",
    "lat": 18.075,
    "lng": -88.5583,
    "country": "Belize",
    "iso2": "BZ",
    "iso3": "BLZ",
    "admin_name": "Orange Walk",
    "capital": "admin",
    "population": 18000,
    "id": 1084547220
  },
  {
    "city": "Torit",
    "city_ascii": "Torit",
    "lat": 4.4167,
    "lng": 32.5667,
    "country": "South Sudan",
    "iso2": "SS",
    "iso3": "SSD",
    "admin_name": "Eastern Equatoria",
    "capital": "admin",
    "population": 17957,
    "id": 1728698486
  },
  {
    "city": "Saatlı",
    "city_ascii": "Saatli",
    "lat": 39.9311,
    "lng": 48.3697,
    "country": "Azerbaijan",
    "iso2": "AZ",
    "iso3": "AZE",
    "admin_name": "Saatlı",
    "capital": "admin",
    "population": 17900,
    "id": 1031713436
  },
  {
    "city": "Stapleford",
    "city_ascii": "Stapleford",
    "lat": 52.929,
    "lng": -1.274,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Nottinghamshire",
    "capital": "",
    "population": 15241,
    "id": 1826644047
  },
  {
    "city": "Akhaltsikhe",
    "city_ascii": "Akhaltsikhe",
    "lat": 41.6389,
    "lng": 42.9861,
    "country": "Georgia",
    "iso2": "GE",
    "iso3": "GEO",
    "admin_name": "Samtskhe-Javakheti",
    "capital": "admin",
    "population": 17903,
    "id": 1268352935
  },
  {
    "city": "Sa Kaeo",
    "city_ascii": "Sa Kaeo",
    "lat": 13.8206,
    "lng": 102.0589,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Sa Kaeo",
    "capital": "admin",
    "population": 17864,
    "id": 1764207048
  },
  {
    "city": "Bourne",
    "city_ascii": "Bourne",
    "lat": 52.7684,
    "lng": -0.3775,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Lincolnshire",
    "capital": "",
    "population": 13961,
    "id": 1826267550
  },
  {
    "city": "Cedar Mill",
    "city_ascii": "Cedar Mill",
    "lat": 45.5355,
    "lng": -122.8006,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 17897,
    "id": 1840034678
  },
  {
    "city": "Piran",
    "city_ascii": "Piran",
    "lat": 45.5271,
    "lng": 13.5685,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Piran",
    "capital": "admin",
    "population": 17491,
    "id": 1705085466
  },
  {
    "city": "Eppelheim",
    "city_ascii": "Eppelheim",
    "lat": 49.4011,
    "lng": 8.6297,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 15177,
    "id": 1276579726
  },
  {
    "city": "Kretinga",
    "city_ascii": "Kretinga",
    "lat": 55.8758,
    "lng": 21.2508,
    "country": "Lithuania",
    "iso2": "LT",
    "iso3": "LTU",
    "admin_name": "Kretinga",
    "capital": "admin",
    "population": 17787,
    "id": 1440720895
  },
  {
    "city": "Thônex",
    "city_ascii": "Thonex",
    "lat": 46.2,
    "lng": 6.2167,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Genève",
    "capital": "",
    "population": 14122,
    "id": 1756784451
  },
  {
    "city": "West Drayton",
    "city_ascii": "West Drayton",
    "lat": 51.5043,
    "lng": -0.4646,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hillingdon",
    "capital": "",
    "population": 14370,
    "id": 1826574232
  },
  {
    "city": "Perivale",
    "city_ascii": "Perivale",
    "lat": 51.5383,
    "lng": -0.3192,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Ealing",
    "capital": "",
    "population": 15339,
    "id": 1826877417
  },
  {
    "city": "Khaşab",
    "city_ascii": "Khasab",
    "lat": 26.1833,
    "lng": 56.25,
    "country": "Oman",
    "iso2": "OM",
    "iso3": "OMN",
    "admin_name": "Musandam",
    "capital": "admin",
    "population": 17777,
    "id": 1512236141
  },
  {
    "city": "Guerrero Negro",
    "city_ascii": "Guerrero Negro",
    "lat": 27.9589,
    "lng": -114.0561,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Baja California",
    "capital": "",
    "population": 14316,
    "id": 1484879768
  },
  {
    "city": "Merrifield",
    "city_ascii": "Merrifield",
    "lat": 38.8731,
    "lng": -77.2426,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 17789,
    "id": 1840006019
  },
  {
    "city": "Kidlington",
    "city_ascii": "Kidlington",
    "lat": 51.823,
    "lng": -1.291,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Oxfordshire",
    "capital": "",
    "population": 13723,
    "id": 1826182197
  },
  {
    "city": "Santa Rosalía",
    "city_ascii": "Santa Rosalia",
    "lat": 27.3389,
    "lng": -112.2669,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Baja California Sur",
    "capital": "minor",
    "population": 14160,
    "id": 1484950775
  },
  {
    "city": "Valparaíso",
    "city_ascii": "Valparaiso",
    "lat": 22.7667,
    "lng": -103.5667,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Zacatecas",
    "capital": "minor",
    "population": 12919,
    "id": 1484885454
  },
  {
    "city": "Rosyth",
    "city_ascii": "Rosyth",
    "lat": 56.0339,
    "lng": -3.4323,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Fife",
    "capital": "",
    "population": 13440,
    "id": 1826173931
  },
  {
    "city": "Ban Houayxay",
    "city_ascii": "Ban Houayxay",
    "lat": 20.2833,
    "lng": 100.4167,
    "country": "Laos",
    "iso2": "LA",
    "iso3": "LAO",
    "admin_name": "Bokèo",
    "capital": "admin",
    "population": 17687,
    "id": 1418944950
  },
  {
    "city": "Kukës",
    "city_ascii": "Kukes",
    "lat": 42.0758,
    "lng": 20.4231,
    "country": "Albania",
    "iso2": "AL",
    "iso3": "ALB",
    "admin_name": "Kukës",
    "capital": "admin",
    "population": 16719,
    "id": 1008649172
  },
  {
    "city": "Dover",
    "city_ascii": "Dover",
    "lat": 40.8859,
    "lng": -74.5597,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 17725,
    "id": 1840003581
  },
  {
    "city": "Takoma Park",
    "city_ascii": "Takoma Park",
    "lat": 38.981,
    "lng": -77.0028,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 17725,
    "id": 1840005850
  },
  {
    "city": "Puerto Ayora",
    "city_ascii": "Puerto Ayora",
    "lat": -0.7444,
    "lng": -90.3139,
    "country": "Ecuador",
    "iso2": "EC",
    "iso3": "ECU",
    "admin_name": "Galápagos",
    "capital": "",
    "population": 11822,
    "id": 1218585265
  },
  {
    "city": "Brierley Hill",
    "city_ascii": "Brierley Hill",
    "lat": 52.4795,
    "lng": -2.1245,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Dudley",
    "capital": "",
    "population": 13935,
    "id": 1826736582
  },
  {
    "city": "Swinton",
    "city_ascii": "Swinton",
    "lat": 53.4877,
    "lng": -1.3149,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Rotherham",
    "capital": "",
    "population": 15559,
    "id": 1826312183
  },
  {
    "city": "Little Hulton",
    "city_ascii": "Little Hulton",
    "lat": 53.53,
    "lng": -2.418,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Salford",
    "capital": "",
    "population": 13469,
    "id": 1826921143
  },
  {
    "city": "Marlow",
    "city_ascii": "Marlow",
    "lat": 51.57,
    "lng": -0.78,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Buckinghamshire",
    "capital": "",
    "population": 14004,
    "id": 1826308165
  },
  {
    "city": "Esquimalt",
    "city_ascii": "Esquimalt",
    "lat": 48.4306,
    "lng": -123.4147,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "British Columbia",
    "capital": "",
    "population": 17655,
    "id": 1124990218
  },
  {
    "city": "Greenfield",
    "city_ascii": "Greenfield",
    "lat": 36.3232,
    "lng": -121.2451,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 17646,
    "id": 1840020356
  },
  {
    "city": "Glassmanor",
    "city_ascii": "Glassmanor",
    "lat": 38.8181,
    "lng": -76.9836,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 17628,
    "id": 1840024545
  },
  {
    "city": "Tak",
    "city_ascii": "Tak",
    "lat": 16.8711,
    "lng": 99.125,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Tak",
    "capital": "admin",
    "population": 17565,
    "id": 1764055292
  },
  {
    "city": "Sudley",
    "city_ascii": "Sudley",
    "lat": 38.7878,
    "lng": -77.4961,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 17612,
    "id": 1840006104
  },
  {
    "city": "Phayao",
    "city_ascii": "Phayao",
    "lat": 19.1653,
    "lng": 99.9036,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Phayao",
    "capital": "admin",
    "population": 17467,
    "id": 1764291128
  },
  {
    "city": "Caerfyrddin",
    "city_ascii": "Caerfyrddin",
    "lat": 51.856,
    "lng": -4.316,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Carmarthenshire",
    "capital": "",
    "population": 14185,
    "id": 1826550370
  },
  {
    "city": "Deux-Montagnes",
    "city_ascii": "Deux-Montagnes",
    "lat": 45.5333,
    "lng": -73.8833,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 17553,
    "id": 1124980214
  },
  {
    "city": "Mehtar Lām",
    "city_ascii": "Mehtar Lam",
    "lat": 34.65,
    "lng": 70.1833,
    "country": "Afghanistan",
    "iso2": "AF",
    "iso3": "AFG",
    "admin_name": "Laghmān",
    "capital": "admin",
    "population": 17345,
    "id": 1004590769
  },
  {
    "city": "Villa Unión",
    "city_ascii": "Villa Union",
    "lat": 23.1883,
    "lng": -106.2158,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Sinaloa",
    "capital": "",
    "population": 13404,
    "id": 1484310975
  },
  {
    "city": "Chitipa",
    "city_ascii": "Chitipa",
    "lat": -9.7019,
    "lng": 33.27,
    "country": "Malawi",
    "iso2": "MW",
    "iso3": "MWI",
    "admin_name": "Chitipa",
    "capital": "admin",
    "population": 13412,
    "id": 1454611219
  },
  {
    "city": "Bostonia",
    "city_ascii": "Bostonia",
    "lat": 32.8189,
    "lng": -116.9479,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 17530,
    "id": 1840018018
  },
  {
    "city": "Dumont",
    "city_ascii": "Dumont",
    "lat": 40.9452,
    "lng": -73.9923,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 17516,
    "id": 1840003541
  },
  {
    "city": "Gobabis",
    "city_ascii": "Gobabis",
    "lat": -22.45,
    "lng": 18.9667,
    "country": "Namibia",
    "iso2": "NA",
    "iso3": "NAM",
    "admin_name": "Omaheke",
    "capital": "admin",
    "population": 16321,
    "id": 1516128715
  },
  {
    "city": "Manassas Park",
    "city_ascii": "Manassas Park",
    "lat": 38.7719,
    "lng": -77.445,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 17478,
    "id": 1840003838
  },
  {
    "city": "Rossington",
    "city_ascii": "Rossington",
    "lat": 53.4759,
    "lng": -1.0613,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Doncaster",
    "capital": "",
    "population": 13557,
    "id": 1826906333
  },
  {
    "city": "Woodmere",
    "city_ascii": "Woodmere",
    "lat": 40.6374,
    "lng": -73.7219,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 17467,
    "id": 1840005284
  },
  {
    "city": "Ojus",
    "city_ascii": "Ojus",
    "lat": 25.9563,
    "lng": -80.1606,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 17462,
    "id": 1840028742
  },
  {
    "city": "Dutse",
    "city_ascii": "Dutse",
    "lat": 11.7992,
    "lng": 9.3503,
    "country": "Nigeria",
    "iso2": "NG",
    "iso3": "NGA",
    "admin_name": "Jigawa",
    "capital": "admin",
    "population": 17129,
    "id": 1566855083
  },
  {
    "city": "Stanley",
    "city_ascii": "Stanley",
    "lat": 53.7145,
    "lng": -1.476,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Wakefield",
    "capital": "",
    "population": 15314,
    "id": 1826805412
  },
  {
    "city": "Lowton",
    "city_ascii": "Lowton",
    "lat": 53.471,
    "lng": -2.569,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Wigan",
    "capital": "",
    "population": 14605,
    "id": 1826374228
  },
  {
    "city": "South Houston",
    "city_ascii": "South Houston",
    "lat": 29.6611,
    "lng": -95.2285,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 17438,
    "id": 1840022199
  },
  {
    "city": "Ringwood",
    "city_ascii": "Ringwood",
    "lat": 50.85,
    "lng": -1.78,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hampshire",
    "capital": "",
    "population": 13943,
    "id": 1826578409
  },
  {
    "city": "Sudbury",
    "city_ascii": "Sudbury",
    "lat": 51.5537,
    "lng": -0.3199,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Ealing",
    "capital": "",
    "population": 14950,
    "id": 1826460632
  },
  {
    "city": "Wahiawa",
    "city_ascii": "Wahiawa",
    "lat": 21.5005,
    "lng": -158.0198,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Hawaii",
    "capital": "",
    "population": 17422,
    "id": 1840029550
  },
  {
    "city": "Ilkley",
    "city_ascii": "Ilkley",
    "lat": 53.925,
    "lng": -1.822,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Bradford",
    "capital": "",
    "population": 14809,
    "id": 1826221289
  },
  {
    "city": "Guachochi",
    "city_ascii": "Guachochi",
    "lat": 26.8194,
    "lng": -107.07,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Chihuahua",
    "capital": "minor",
    "population": 14513,
    "id": 1484533430
  },
  {
    "city": "Live Oak",
    "city_ascii": "Live Oak",
    "lat": 36.986,
    "lng": -121.9804,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 17390,
    "id": 1840144061
  },
  {
    "city": "Kamuli",
    "city_ascii": "Kamuli",
    "lat": 0.9472,
    "lng": 33.1197,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Kamuli",
    "capital": "admin",
    "population": 12764,
    "id": 1800849421
  },
  {
    "city": "Juan Aldama",
    "city_ascii": "Juan Aldama",
    "lat": 24.291,
    "lng": -103.394,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Zacatecas",
    "capital": "minor",
    "population": 14162,
    "id": 1484898373
  },
  {
    "city": "Ovenden",
    "city_ascii": "Ovenden",
    "lat": 53.7432,
    "lng": -1.8779,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Calderdale",
    "capital": "",
    "population": 12351,
    "id": 1826436931
  },
  {
    "city": "Childwall",
    "city_ascii": "Childwall",
    "lat": 53.395,
    "lng": -2.881,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Liverpool",
    "capital": "",
    "population": 14085,
    "id": 1826133113
  },
  {
    "city": "Verwood",
    "city_ascii": "Verwood",
    "lat": 50.8815,
    "lng": -1.8785,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Dorset",
    "capital": "",
    "population": 13969,
    "id": 1826274475
  },
  {
    "city": "Balancán",
    "city_ascii": "Balancan",
    "lat": 17.8,
    "lng": -91.53,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Tabasco",
    "capital": "minor",
    "population": 11895,
    "id": 1484783192
  },
  {
    "city": "Whitby",
    "city_ascii": "Whitby",
    "lat": 54.4858,
    "lng": -0.6206,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "North Yorkshire",
    "capital": "",
    "population": 13213,
    "id": 1826806363
  },
  {
    "city": "Hetton le Hole",
    "city_ascii": "Hetton le Hole",
    "lat": 54.8208,
    "lng": -1.4488,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Sunderland",
    "capital": "",
    "population": 14402,
    "id": 1826816233
  },
  {
    "city": "Marsabit",
    "city_ascii": "Marsabit",
    "lat": 2.3333,
    "lng": 37.9833,
    "country": "Kenya",
    "iso2": "KE",
    "iso3": "KEN",
    "admin_name": "Marsabit",
    "capital": "admin",
    "population": 17127,
    "id": 1404055253
  },
  {
    "city": "Egg Buckland",
    "city_ascii": "Egg Buckland",
    "lat": 50.4006,
    "lng": -4.1136,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Plymouth",
    "capital": "",
    "population": 13351,
    "id": 1826735321
  },
  {
    "city": "Trstenik",
    "city_ascii": "Trstenik",
    "lat": 43.6186,
    "lng": 20.9972,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Trstenik",
    "capital": "admin",
    "population": 17180,
    "id": 1688148503
  },
  {
    "city": "Hawick",
    "city_ascii": "Hawick",
    "lat": 55.422,
    "lng": -2.787,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Scottish Borders, The",
    "capital": "",
    "population": 14294,
    "id": 1826156666
  },
  {
    "city": "Wanstead",
    "city_ascii": "Wanstead",
    "lat": 51.5778,
    "lng": 0.0286,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Redbridge",
    "capital": "",
    "population": 11543,
    "id": 1826002020
  },
  {
    "city": "Newport Pagnell",
    "city_ascii": "Newport Pagnell",
    "lat": 52.087,
    "lng": -0.722,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Milton Keynes",
    "capital": "",
    "population": 15118,
    "id": 1826046510
  },
  {
    "city": "Kingstowne",
    "city_ascii": "Kingstowne",
    "lat": 38.7625,
    "lng": -77.1445,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 17172,
    "id": 1840041770
  },
  {
    "city": "Brownsville",
    "city_ascii": "Brownsville",
    "lat": 25.8216,
    "lng": -80.2417,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 17155,
    "id": 1840029024
  },
  {
    "city": "Tilehurst",
    "city_ascii": "Tilehurst",
    "lat": 51.4579,
    "lng": -1.0406,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Reading",
    "capital": "",
    "population": 14683,
    "id": 1826145556
  },
  {
    "city": "Massapequa Park",
    "city_ascii": "Massapequa Park",
    "lat": 40.6817,
    "lng": -73.4496,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 17143,
    "id": 1840005307
  },
  {
    "city": "Country Walk",
    "city_ascii": "Country Walk",
    "lat": 25.6331,
    "lng": -80.4353,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 17114,
    "id": 1840028979
  },
  {
    "city": "Ma’rib",
    "city_ascii": "Ma'rib",
    "lat": 15.4228,
    "lng": 45.3375,
    "country": "Yemen",
    "iso2": "YE",
    "iso3": "YEM",
    "admin_name": "Ma’rib",
    "capital": "admin",
    "population": 16794,
    "id": 1887153216
  },
  {
    "city": "Tukums",
    "city_ascii": "Tukums",
    "lat": 56.9669,
    "lng": 23.1531,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Tukuma Novads",
    "capital": "admin",
    "population": 17075,
    "id": 1428304262
  },
  {
    "city": "Lansdale",
    "city_ascii": "Lansdale",
    "lat": 40.2417,
    "lng": -75.2812,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 17083,
    "id": 1840003703
  },
  {
    "city": "Makokou",
    "city_ascii": "Makokou",
    "lat": 0.5667,
    "lng": 12.8667,
    "country": "Gabon",
    "iso2": "GA",
    "iso3": "GAB",
    "admin_name": "Ogooué-Ivindo",
    "capital": "admin",
    "population": 17070,
    "id": 1266939354
  },
  {
    "city": "Shenley Church End",
    "city_ascii": "Shenley Church End",
    "lat": 52.022,
    "lng": -0.788,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Milton Keynes",
    "capital": "",
    "population": 12961,
    "id": 1826650494
  },
  {
    "city": "Calkiní",
    "city_ascii": "Calkini",
    "lat": 20.3667,
    "lng": -90.05,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Campeche",
    "capital": "minor",
    "population": 14934,
    "id": 1484329915
  },
  {
    "city": "Pinewood",
    "city_ascii": "Pinewood",
    "lat": 25.8697,
    "lng": -80.2174,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 17027,
    "id": 1840029066
  },
  {
    "city": "Vincent",
    "city_ascii": "Vincent",
    "lat": 34.0982,
    "lng": -117.9238,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 17008,
    "id": 1840074687
  },
  {
    "city": "Zaghouan",
    "city_ascii": "Zaghouan",
    "lat": 36.4,
    "lng": 10.147,
    "country": "Tunisia",
    "iso2": "TN",
    "iso3": "TUN",
    "admin_name": "Zaghouan",
    "capital": "admin",
    "population": 16911,
    "id": 1788008309
  },
  {
    "city": "Chaddesden",
    "city_ascii": "Chaddesden",
    "lat": 52.9301,
    "lng": -1.4383,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Derby",
    "capital": "",
    "population": 13413,
    "id": 1826935490
  },
  {
    "city": "San Ignacio",
    "city_ascii": "San Ignacio",
    "lat": 17.1588,
    "lng": -89.0696,
    "country": "Belize",
    "iso2": "BZ",
    "iso3": "BLZ",
    "admin_name": "Cayo",
    "capital": "admin",
    "population": 16977,
    "id": 1084924884
  },
  {
    "city": "East Cleveland",
    "city_ascii": "East Cleveland",
    "lat": 41.5318,
    "lng": -81.5795,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 16964,
    "id": 1840000599
  },
  {
    "city": "Arbon",
    "city_ascii": "Arbon",
    "lat": 47.5167,
    "lng": 9.4333,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Thurgau",
    "capital": "",
    "population": 14537,
    "id": 1756486653
  },
  {
    "city": "Smethwick",
    "city_ascii": "Smethwick",
    "lat": 52.4928,
    "lng": -1.9682,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Sandwell",
    "capital": "",
    "population": 14146,
    "id": 1826953119
  },
  {
    "city": "Coseley",
    "city_ascii": "Coseley",
    "lat": 52.55,
    "lng": -2.083,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Dudley",
    "capital": "",
    "population": 12357,
    "id": 1826312383
  },
  {
    "city": "Brackley",
    "city_ascii": "Brackley",
    "lat": 52.032,
    "lng": -1.147,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Northamptonshire",
    "capital": "",
    "population": 13018,
    "id": 1826334139
  },
  {
    "city": "Tempoal de Sánchez",
    "city_ascii": "Tempoal de Sanchez",
    "lat": 21.5167,
    "lng": -98.3833,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Veracruz",
    "capital": "minor",
    "population": 12237,
    "id": 1484011037
  },
  {
    "city": "Salaspils",
    "city_ascii": "Salaspils",
    "lat": 56.8614,
    "lng": 24.35,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Salaspils Novads",
    "capital": "admin",
    "population": 16616,
    "id": 1428270898
  },
  {
    "city": "Armthorpe",
    "city_ascii": "Armthorpe",
    "lat": 53.5352,
    "lng": -1.0522,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Doncaster",
    "capital": "",
    "population": 14457,
    "id": 1826961585
  },
  {
    "city": "Astara",
    "city_ascii": "Astara",
    "lat": 38.44,
    "lng": 48.875,
    "country": "Azerbaijan",
    "iso2": "AZ",
    "iso3": "AZE",
    "admin_name": "Astara",
    "capital": "admin",
    "population": 16800,
    "id": 1031376971
  },
  {
    "city": "Phrae",
    "city_ascii": "Phrae",
    "lat": 18.1436,
    "lng": 100.1417,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Phrae",
    "capital": "admin",
    "population": 16668,
    "id": 1764209592
  },
  {
    "city": "Milngavie",
    "city_ascii": "Milngavie",
    "lat": 55.9421,
    "lng": -4.3137,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "East Dunbartonshire",
    "capital": "",
    "population": 13537,
    "id": 1826013201
  },
  {
    "city": "Yauco",
    "city_ascii": "Yauco",
    "lat": 18.0344,
    "lng": -66.8615,
    "country": "Puerto Rico",
    "iso2": "PR",
    "iso3": "PRI",
    "admin_name": "Puerto Rico",
    "capital": "",
    "population": 16802,
    "id": 1630035600
  },
  {
    "city": "Zveçan",
    "city_ascii": "Zvecan",
    "lat": 42.9,
    "lng": 20.8333,
    "country": "Kosovo",
    "iso2": "XK",
    "iso3": "XKS",
    "admin_name": "Zveçan",
    "capital": "admin",
    "population": 16650,
    "id": 1901208362
  },
  {
    "city": "Puerto Carreño",
    "city_ascii": "Puerto Carreno",
    "lat": 6.1889,
    "lng": -67.4858,
    "country": "Colombia",
    "iso2": "CO",
    "iso3": "COL",
    "admin_name": "Vichada",
    "capital": "admin",
    "population": 16763,
    "id": 1170291142
  },
  {
    "city": "Bodmin",
    "city_ascii": "Bodmin",
    "lat": 50.466,
    "lng": -4.718,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Cornwall",
    "capital": "",
    "population": 14736,
    "id": 1826850129
  },
  {
    "city": "Ntungamo",
    "city_ascii": "Ntungamo",
    "lat": -0.8794,
    "lng": 30.2642,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Ntungamo",
    "capital": "admin",
    "population": 16400,
    "id": 1800042819
  },
  {
    "city": "Clayton",
    "city_ascii": "Clayton",
    "lat": 38.6444,
    "lng": -90.3302,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 16747,
    "id": 1840007439
  },
  {
    "city": "L’Ancienne-Lorette",
    "city_ascii": "L'Ancienne-Lorette",
    "lat": 46.8,
    "lng": -71.35,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 16745,
    "id": 1124580674
  },
  {
    "city": "Redruth",
    "city_ascii": "Redruth",
    "lat": 50.233,
    "lng": -5.224,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Cornwall",
    "capital": "",
    "population": 14018,
    "id": 1826258602
  },
  {
    "city": "Walsall Wood",
    "city_ascii": "Walsall Wood",
    "lat": 52.6277,
    "lng": -1.9301,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Walsall",
    "capital": "",
    "population": 13207,
    "id": 1826891290
  },
  {
    "city": "East Rancho Dominguez",
    "city_ascii": "East Rancho Dominguez",
    "lat": 33.895,
    "lng": -118.1956,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 16734,
    "id": 1840028368
  },
  {
    "city": "Cuilapa",
    "city_ascii": "Cuilapa",
    "lat": 14.279,
    "lng": -90.298,
    "country": "Guatemala",
    "iso2": "GT",
    "iso3": "GTM",
    "admin_name": "Santa Rosa",
    "capital": "admin",
    "population": 16484,
    "id": 1320810416
  },
  {
    "city": "Hollinwood",
    "city_ascii": "Hollinwood",
    "lat": 53.5183,
    "lng": -2.144,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Oldham",
    "capital": "",
    "population": 10920,
    "id": 1826177835
  },
  {
    "city": "Struga",
    "city_ascii": "Struga",
    "lat": 41.1775,
    "lng": 20.6789,
    "country": "Macedonia",
    "iso2": "MK",
    "iso3": "MKD",
    "admin_name": "Struga",
    "capital": "admin",
    "population": 16559,
    "id": 1807746682
  },
  {
    "city": "Streetly",
    "city_ascii": "Streetly",
    "lat": 52.577,
    "lng": -1.884,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Walsall",
    "capital": "",
    "population": 13934,
    "id": 1826347383
  },
  {
    "city": "Fraserburgh",
    "city_ascii": "Fraserburgh",
    "lat": 57.693,
    "lng": -2.005,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Aberdeenshire",
    "capital": "",
    "population": 13100,
    "id": 1826713495
  },
  {
    "city": "Vicente Guerrero",
    "city_ascii": "Vicente Guerrero",
    "lat": 30.7264,
    "lng": -115.9903,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Baja California",
    "capital": "",
    "population": 12829,
    "id": 1484159343
  },
  {
    "city": "Pathum Thani",
    "city_ascii": "Pathum Thani",
    "lat": 13.9841,
    "lng": 100.5512,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Pathum Thani",
    "capital": "admin",
    "population": 13987,
    "id": 1764434288
  },
  {
    "city": "Conisbrough",
    "city_ascii": "Conisbrough",
    "lat": 53.479,
    "lng": -1.227,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Doncaster",
    "capital": "",
    "population": 14333,
    "id": 1826311843
  },
  {
    "city": "Thorpe Saint Andrew",
    "city_ascii": "Thorpe Saint Andrew",
    "lat": 52.6354,
    "lng": 1.3431,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Norfolk",
    "capital": "",
    "population": 14556,
    "id": 1826296791
  },
  {
    "city": "Rakvere",
    "city_ascii": "Rakvere",
    "lat": 59.3506,
    "lng": 26.3611,
    "country": "Estonia",
    "iso2": "EE",
    "iso3": "EST",
    "admin_name": "Lääne-Virumaa",
    "capital": "admin",
    "population": 15189,
    "id": 1233049348
  },
  {
    "city": "Artesia",
    "city_ascii": "Artesia",
    "lat": 33.8676,
    "lng": -118.0806,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 16601,
    "id": 1840019224
  },
  {
    "city": "Richmond",
    "city_ascii": "Richmond",
    "lat": -41.3333,
    "lng": 173.1833,
    "country": "New Zealand",
    "iso2": "NZ",
    "iso3": "NZL",
    "admin_name": "Tasman",
    "capital": "admin",
    "population": 15000,
    "id": 1554001427
  },
  {
    "city": "Blunsdon Saint Andrew",
    "city_ascii": "Blunsdon Saint Andrew",
    "lat": 51.61,
    "lng": -1.79,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Swindon",
    "capital": "",
    "population": 12414,
    "id": 1826285391
  },
  {
    "city": "Herceg Novi",
    "city_ascii": "Herceg Novi",
    "lat": 42.4531,
    "lng": 18.5375,
    "country": "Montenegro",
    "iso2": "ME",
    "iso3": "MNE",
    "admin_name": "Herceg Novi",
    "capital": "admin",
    "population": 16493,
    "id": 1499673791
  },
  {
    "city": "Evington",
    "city_ascii": "Evington",
    "lat": 52.621,
    "lng": -1.072,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Leicestershire",
    "capital": "",
    "population": 11133,
    "id": 1826108996
  },
  {
    "city": "Chojnów",
    "city_ascii": "Chojnow",
    "lat": 51.2667,
    "lng": 15.9333,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Dolnośląskie",
    "capital": "",
    "population": 13904,
    "id": 1616584715
  },
  {
    "city": "Broughty Ferry",
    "city_ascii": "Broughty Ferry",
    "lat": 56.4672,
    "lng": -2.8699,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Dundee City",
    "capital": "",
    "population": 13155,
    "id": 1826000803
  },
  {
    "city": "Kitui",
    "city_ascii": "Kitui",
    "lat": -1.3667,
    "lng": 38.0167,
    "country": "Kenya",
    "iso2": "KE",
    "iso3": "KEN",
    "admin_name": "Kitui",
    "capital": "admin",
    "population": 13244,
    "id": 1404548296
  },
  {
    "city": "Milford Haven",
    "city_ascii": "Milford Haven",
    "lat": 51.7142,
    "lng": -5.0427,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Pembrokeshire",
    "capital": "",
    "population": 13907,
    "id": 1826680472
  },
  {
    "city": "San Francisco",
    "city_ascii": "San Francisco",
    "lat": 13.7,
    "lng": -88.1,
    "country": "El Salvador",
    "iso2": "SV",
    "iso3": "SLV",
    "admin_name": "Morazán",
    "capital": "admin",
    "population": 16152,
    "id": 1222399599
  },
  {
    "city": "South Orange Village",
    "city_ascii": "South Orange Village",
    "lat": 40.7491,
    "lng": -74.2602,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 16434,
    "id": 1840081372
  },
  {
    "city": "Bredbury",
    "city_ascii": "Bredbury",
    "lat": 53.42,
    "lng": -2.113,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Stockport",
    "capital": "",
    "population": 13593,
    "id": 1826343069
  },
  {
    "city": "New Milford",
    "city_ascii": "New Milford",
    "lat": 40.9337,
    "lng": -74.0196,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 16429,
    "id": 1840000908
  },
  {
    "city": "Hall in Tirol",
    "city_ascii": "Hall in Tirol",
    "lat": 47.2833,
    "lng": 11.5,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Tirol",
    "capital": "",
    "population": 13897,
    "id": 1040200365
  },
  {
    "city": "Glenmont",
    "city_ascii": "Glenmont",
    "lat": 39.0698,
    "lng": -77.0467,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 16389,
    "id": 1840024490
  },
  {
    "city": "Cherryland",
    "city_ascii": "Cherryland",
    "lat": 37.6792,
    "lng": -122.1038,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 16387,
    "id": 1840028361
  },
  {
    "city": "Little Lever",
    "city_ascii": "Little Lever",
    "lat": 53.563,
    "lng": -2.369,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Bolton",
    "capital": "",
    "population": 12803,
    "id": 1826282057
  },
  {
    "city": "Moussoro",
    "city_ascii": "Moussoro",
    "lat": 13.6333,
    "lng": 16.4833,
    "country": "Chad",
    "iso2": "TD",
    "iso3": "TCD",
    "admin_name": "Barh el Gazel",
    "capital": "admin",
    "population": 16349,
    "id": 1148918270
  },
  {
    "city": "Hazel Park",
    "city_ascii": "Hazel Park",
    "lat": 42.4619,
    "lng": -83.0977,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 16347,
    "id": 1840002444
  },
  {
    "city": "Ranong",
    "city_ascii": "Ranong",
    "lat": 9.9619,
    "lng": 98.6389,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Ranong",
    "capital": "admin",
    "population": 16125,
    "id": 1764623795
  },
  {
    "city": "Hybla Valley",
    "city_ascii": "Hybla Valley",
    "lat": 38.7485,
    "lng": -77.0822,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 16325,
    "id": 1840006031
  },
  {
    "city": "Nakhon Nayok",
    "city_ascii": "Nakhon Nayok",
    "lat": 14.2031,
    "lng": 101.215,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Nakhon Nayok",
    "capital": "admin",
    "population": 16195,
    "id": 1764068248
  },
  {
    "city": "Thatto Heath",
    "city_ascii": "Thatto Heath",
    "lat": 53.4352,
    "lng": -2.7481,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "St. Helens",
    "capital": "",
    "population": 12280,
    "id": 1826530884
  },
  {
    "city": "Mahikeng",
    "city_ascii": "Mahikeng",
    "lat": -25.8653,
    "lng": 25.6442,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "North West",
    "capital": "admin",
    "population": 15117,
    "id": 1710965423
  },
  {
    "city": "Radoviš",
    "city_ascii": "Radovis",
    "lat": 41.6381,
    "lng": 22.4644,
    "country": "Macedonia",
    "iso2": "MK",
    "iso3": "MKD",
    "admin_name": "Radoviš",
    "capital": "admin",
    "population": 16223,
    "id": 1807341711
  },
  {
    "city": "Sedgley",
    "city_ascii": "Sedgley",
    "lat": 52.54,
    "lng": -2.123,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Dudley",
    "capital": "",
    "population": 12087,
    "id": 1826101730
  },
  {
    "city": "Minehead",
    "city_ascii": "Minehead",
    "lat": 51.2038,
    "lng": -3.4738,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Somerset",
    "capital": "",
    "population": 11981,
    "id": 1826790025
  },
  {
    "city": "Xaignabouli",
    "city_ascii": "Xaignabouli",
    "lat": 19.2576,
    "lng": 101.7103,
    "country": "Laos",
    "iso2": "LA",
    "iso3": "LAO",
    "admin_name": "Xaignabouli",
    "capital": "admin",
    "population": 16200,
    "id": 1418760593
  },
  {
    "city": "Roosevelt",
    "city_ascii": "Roosevelt",
    "lat": 40.6797,
    "lng": -73.5837,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 16229,
    "id": 1840005253
  },
  {
    "city": "Koulamoutou",
    "city_ascii": "Koulamoutou",
    "lat": -1.1333,
    "lng": 12.4833,
    "country": "Gabon",
    "iso2": "GA",
    "iso3": "GAB",
    "admin_name": "Ogooué-Lolo",
    "capital": "admin",
    "population": 16222,
    "id": 1266049565
  },
  {
    "city": "Villa Hayes",
    "city_ascii": "Villa Hayes",
    "lat": -25.09,
    "lng": -57.53,
    "country": "Paraguay",
    "iso2": "PY",
    "iso3": "PRY",
    "admin_name": "Presidente Hayes",
    "capital": "admin",
    "population": 15643,
    "id": 1600405064
  },
  {
    "city": "Wollaston",
    "city_ascii": "Wollaston",
    "lat": 52.4619,
    "lng": -2.1663,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Dudley",
    "capital": "",
    "population": 13092,
    "id": 1826737467
  },
  {
    "city": "Bačka Topola",
    "city_ascii": "Backa Topola",
    "lat": 45.8089,
    "lng": 19.635,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Bačka Topola",
    "capital": "admin",
    "population": 16171,
    "id": 1688533352
  },
  {
    "city": "Broadwater",
    "city_ascii": "Broadwater",
    "lat": 50.8282,
    "lng": -0.3742,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "West Sussex",
    "capital": "",
    "population": 9373,
    "id": 1826765618
  },
  {
    "city": "Aldama",
    "city_ascii": "Aldama",
    "lat": 22.9194,
    "lng": -98.0736,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Tamaulipas",
    "capital": "minor",
    "population": 13661,
    "id": 1484835121
  },
  {
    "city": "Hillcrest Heights",
    "city_ascii": "Hillcrest Heights",
    "lat": 38.8373,
    "lng": -76.9641,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 16185,
    "id": 1840005957
  },
  {
    "city": "Mexicaltzingo",
    "city_ascii": "Mexicaltzingo",
    "lat": 19.2092,
    "lng": -99.5858,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "México",
    "capital": "minor",
    "population": 10161,
    "id": 1484468778
  },
  {
    "city": "Buckingham",
    "city_ascii": "Buckingham",
    "lat": 51.995,
    "lng": -0.986,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Buckinghamshire",
    "capital": "",
    "population": 12890,
    "id": 1826027326
  },
  {
    "city": "Castle Bromwich",
    "city_ascii": "Castle Bromwich",
    "lat": 52.505,
    "lng": -1.7856,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Birmingham",
    "capital": "",
    "population": 11217,
    "id": 1826340302
  },
  {
    "city": "Aylestone",
    "city_ascii": "Aylestone",
    "lat": 52.604,
    "lng": -1.154,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Leicester",
    "capital": "",
    "population": 11151,
    "id": 1826967618
  },
  {
    "city": "Chard",
    "city_ascii": "Chard",
    "lat": 50.8728,
    "lng": -2.9587,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Somerset",
    "capital": "",
    "population": 13074,
    "id": 1826438736
  },
  {
    "city": "Spitalfields",
    "city_ascii": "Spitalfields",
    "lat": 51.5166,
    "lng": -0.075,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Tower Hamlets",
    "capital": "",
    "population": 10286,
    "id": 1826499077
  },
  {
    "city": "Four Corners",
    "city_ascii": "Four Corners",
    "lat": 44.9291,
    "lng": -122.9731,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 16045,
    "id": 1840034722
  },
  {
    "city": "Walnut Park",
    "city_ascii": "Walnut Park",
    "lat": 33.9683,
    "lng": -118.222,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 16034,
    "id": 1840019218
  },
  {
    "city": "Bryn",
    "city_ascii": "Bryn",
    "lat": 53.499,
    "lng": -2.657,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Wigan",
    "capital": "",
    "population": 11662,
    "id": 1826011571
  },
  {
    "city": "Căuşeni",
    "city_ascii": "Causeni",
    "lat": 46.6442,
    "lng": 29.4139,
    "country": "Moldova",
    "iso2": "MD",
    "iso3": "MDA",
    "admin_name": "Căuşeni",
    "capital": "admin",
    "population": 15939,
    "id": 1498826686
  },
  {
    "city": "Sutton on Hull",
    "city_ascii": "Sutton on Hull",
    "lat": 53.7806,
    "lng": -0.3047,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Kingston upon Hull, City of",
    "capital": "",
    "population": 12649,
    "id": 1826733547
  },
  {
    "city": "Midsomer Norton",
    "city_ascii": "Midsomer Norton",
    "lat": 51.2842,
    "lng": -2.4817,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Bath and North East Somerset",
    "capital": "",
    "population": 13000,
    "id": 1826227978
  },
  {
    "city": "Palmetto Estates",
    "city_ascii": "Palmetto Estates",
    "lat": 25.6211,
    "lng": -80.3616,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 15970,
    "id": 1840029063
  },
  {
    "city": "Šilutė",
    "city_ascii": "Silute",
    "lat": 55.35,
    "lng": 21.4833,
    "country": "Lithuania",
    "iso2": "LT",
    "iso3": "LTU",
    "admin_name": "Šilutė",
    "capital": "admin",
    "population": 15902,
    "id": 1440568024
  },
  {
    "city": "Ángel R. Cabada",
    "city_ascii": "Angel R. Cabada",
    "lat": 18.5969,
    "lng": -95.4453,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Veracruz",
    "capital": "minor",
    "population": 11689,
    "id": 1484667573
  },
  {
    "city": "East San Gabriel",
    "city_ascii": "East San Gabriel",
    "lat": 34.1198,
    "lng": -118.0807,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 15932,
    "id": 1840028311
  },
  {
    "city": "Southwick",
    "city_ascii": "Southwick",
    "lat": 50.836,
    "lng": -0.239,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "West Sussex",
    "capital": "",
    "population": 13195,
    "id": 1826708962
  },
  {
    "city": "Avocado Heights",
    "city_ascii": "Avocado Heights",
    "lat": 34.0381,
    "lng": -118.0026,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 15903,
    "id": 1840028318
  },
  {
    "city": "Anadyr",
    "city_ascii": "Anadyr",
    "lat": 64.7333,
    "lng": 177.5167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Chukotskiy Avtonomnyy Okrug",
    "capital": "admin",
    "population": 15604,
    "id": 1643453469
  },
  {
    "city": "Longbenton",
    "city_ascii": "Longbenton",
    "lat": 55,
    "lng": -1.57,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Newcastle upon Tyne",
    "capital": "",
    "population": 10617,
    "id": 1826725161
  },
  {
    "city": "Pelsall",
    "city_ascii": "Pelsall",
    "lat": 52.631,
    "lng": -1.972,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Walsall",
    "capital": "",
    "population": 11505,
    "id": 1826666565
  },
  {
    "city": "Parkway",
    "city_ascii": "Parkway",
    "lat": 38.4993,
    "lng": -121.452,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 15885,
    "id": 1840024628
  },
  {
    "city": "Maliana",
    "city_ascii": "Maliana",
    "lat": -8.9917,
    "lng": 125.2197,
    "country": "Timor-Leste",
    "iso2": "TL",
    "iso3": "TLS",
    "admin_name": "Bobonaro",
    "capital": "admin",
    "population": 15800,
    "id": 1626637884
  },
  {
    "city": "Whakatane",
    "city_ascii": "Whakatane",
    "lat": -37.964,
    "lng": 176.984,
    "country": "New Zealand",
    "iso2": "NZ",
    "iso3": "NZL",
    "admin_name": "Bay of Plenty",
    "capital": "admin",
    "population": 15850,
    "id": 1554253729
  },
  {
    "city": "White Center",
    "city_ascii": "White Center",
    "lat": 47.5086,
    "lng": -122.348,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 15852,
    "id": 1840037727
  },
  {
    "city": "Candelaria",
    "city_ascii": "Candelaria",
    "lat": 18.4043,
    "lng": -66.2175,
    "country": "Puerto Rico",
    "iso2": "PR",
    "iso3": "PRI",
    "admin_name": "Puerto Rico",
    "capital": "",
    "population": 15846,
    "id": 1630023689
  },
  {
    "city": "Ascensión",
    "city_ascii": "Ascension",
    "lat": 31.0928,
    "lng": -107.9964,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Chihuahua",
    "capital": "minor",
    "population": 13456,
    "id": 1484659928
  },
  {
    "city": "Floral Park",
    "city_ascii": "Floral Park",
    "lat": 40.7226,
    "lng": -73.7029,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 15844,
    "id": 1840005286
  },
  {
    "city": "Sukhothai",
    "city_ascii": "Sukhothai",
    "lat": 17.0142,
    "lng": 99.8219,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Sukhothai",
    "capital": "admin",
    "population": 15618,
    "id": 1764175777
  },
  {
    "city": "Altay",
    "city_ascii": "Altay",
    "lat": 46.3728,
    "lng": 96.2572,
    "country": "Mongolia",
    "iso2": "MN",
    "iso3": "MNG",
    "admin_name": "Govĭ-Altay",
    "capital": "admin",
    "population": 15800,
    "id": 1496574715
  },
  {
    "city": "Asperg",
    "city_ascii": "Asperg",
    "lat": 48.9064,
    "lng": 9.1414,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 13480,
    "id": 1276656917
  },
  {
    "city": "Thornbury",
    "city_ascii": "Thornbury",
    "lat": 51.6094,
    "lng": -2.5249,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "South Gloucestershire",
    "capital": "",
    "population": 12342,
    "id": 1826096107
  },
  {
    "city": "South River",
    "city_ascii": "South River",
    "lat": 40.4455,
    "lng": -74.3784,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 15779,
    "id": 1840001335
  },
  {
    "city": "Peshkopi",
    "city_ascii": "Peshkopi",
    "lat": 41.6831,
    "lng": 20.4289,
    "country": "Albania",
    "iso2": "AL",
    "iso3": "ALB",
    "admin_name": "Dibër",
    "capital": "admin",
    "population": 13251,
    "id": 1008412550
  },
  {
    "city": "Adelphi",
    "city_ascii": "Adelphi",
    "lat": 39.0017,
    "lng": -76.9649,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 15751,
    "id": 1840005944
  },
  {
    "city": "Gevgelija",
    "city_ascii": "Gevgelija",
    "lat": 41.1392,
    "lng": 22.5025,
    "country": "Macedonia",
    "iso2": "MK",
    "iso3": "MKD",
    "admin_name": "Gevgelija",
    "capital": "admin",
    "population": 15685,
    "id": 1807600399
  },
  {
    "city": "Bellmore",
    "city_ascii": "Bellmore",
    "lat": 40.6569,
    "lng": -73.5285,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 15730,
    "id": 1840005232
  },
  {
    "city": "North New Hyde Park",
    "city_ascii": "North New Hyde Park",
    "lat": 40.746,
    "lng": -73.6876,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 15726,
    "id": 1840005243
  },
  {
    "city": "Allestree",
    "city_ascii": "Allestree",
    "lat": 52.9519,
    "lng": -1.4856,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Derby",
    "capital": "",
    "population": 13622,
    "id": 1826396769
  },
  {
    "city": "Lye",
    "city_ascii": "Lye",
    "lat": 52.459,
    "lng": -2.116,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Dudley",
    "capital": "",
    "population": 12346,
    "id": 1826947385
  },
  {
    "city": "Mosgiel",
    "city_ascii": "Mosgiel",
    "lat": -45.875,
    "lng": 170.348,
    "country": "New Zealand",
    "iso2": "NZ",
    "iso3": "NZL",
    "admin_name": "Otago",
    "capital": "",
    "population": 13400,
    "id": 1554341062
  },
  {
    "city": "Brownhills",
    "city_ascii": "Brownhills",
    "lat": 52.647,
    "lng": -1.933,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Walsall",
    "capital": "",
    "population": 12676,
    "id": 1826680163
  },
  {
    "city": "North Arlington",
    "city_ascii": "North Arlington",
    "lat": 40.7875,
    "lng": -74.1273,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 15683,
    "id": 1840000909
  },
  {
    "city": "Bull Run",
    "city_ascii": "Bull Run",
    "lat": 38.7802,
    "lng": -77.5204,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 15683,
    "id": 1840006093
  },
  {
    "city": "Stranraer",
    "city_ascii": "Stranraer",
    "lat": 54.902,
    "lng": -5.027,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Dumfries and Galloway",
    "capital": "",
    "population": 10851,
    "id": 1826813563
  },
  {
    "city": "Stanford",
    "city_ascii": "Stanford",
    "lat": 37.4252,
    "lng": -122.1674,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 15668,
    "id": 1840028400
  },
  {
    "city": "McFarland",
    "city_ascii": "McFarland",
    "lat": 35.6781,
    "lng": -119.2414,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 15651,
    "id": 1840020419
  },
  {
    "city": "Uthai Thani",
    "city_ascii": "Uthai Thani",
    "lat": 15.38,
    "lng": 100.025,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Uthai Thani",
    "capital": "admin",
    "population": 15434,
    "id": 1764844835
  },
  {
    "city": "Fenton",
    "city_ascii": "Fenton",
    "lat": 52.9977,
    "lng": -2.1578,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Stoke-on-Trent",
    "capital": "",
    "population": 12070,
    "id": 1826789719
  },
  {
    "city": "Ainaro",
    "city_ascii": "Ainaro",
    "lat": -8.9833,
    "lng": 125.5,
    "country": "Timor-Leste",
    "iso2": "TL",
    "iso3": "TLS",
    "admin_name": "Ainaro",
    "capital": "admin",
    "population": 15558,
    "id": 1626118494
  },
  {
    "city": "Parlier",
    "city_ascii": "Parlier",
    "lat": 36.6087,
    "lng": -119.5434,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 15613,
    "id": 1840020323
  },
  {
    "city": "Edineţ",
    "city_ascii": "Edinet",
    "lat": 48.1681,
    "lng": 27.305,
    "country": "Moldova",
    "iso2": "MD",
    "iso3": "MDA",
    "admin_name": "Edineţ",
    "capital": "admin",
    "population": 15520,
    "id": 1498114952
  },
  {
    "city": "West University Place",
    "city_ascii": "West University Place",
    "lat": 29.7157,
    "lng": -95.4321,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 15585,
    "id": 1840022205
  },
  {
    "city": "Palanga",
    "city_ascii": "Palanga",
    "lat": 55.9167,
    "lng": 21.0639,
    "country": "Lithuania",
    "iso2": "LT",
    "iso3": "LTU",
    "admin_name": "Palangos Miestas",
    "capital": "admin",
    "population": 15381,
    "id": 1440868798
  },
  {
    "city": "Knutsford",
    "city_ascii": "Knutsford",
    "lat": 53.3025,
    "lng": -2.3708,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Cheshire East",
    "capital": "",
    "population": 13191,
    "id": 1826240471
  },
  {
    "city": "Chepstow",
    "city_ascii": "Chepstow",
    "lat": 51.642,
    "lng": -2.675,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Monmouthshire",
    "capital": "",
    "population": 12350,
    "id": 1826986519
  },
  {
    "city": "Kiboga",
    "city_ascii": "Kiboga",
    "lat": 0.9161,
    "lng": 31.7742,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Kiboga",
    "capital": "admin",
    "population": 14512,
    "id": 1800749650
  },
  {
    "city": "Eggertsville",
    "city_ascii": "Eggertsville",
    "lat": 42.9665,
    "lng": -78.8065,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 15546,
    "id": 1840024061
  },
  {
    "city": "Chilwell",
    "city_ascii": "Chilwell",
    "lat": 52.916,
    "lng": -1.235,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Nottinghamshire",
    "capital": "",
    "population": 12864,
    "id": 1826053843
  },
  {
    "city": "Corsham",
    "city_ascii": "Corsham",
    "lat": 51.43,
    "lng": -2.19,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Wiltshire",
    "capital": "",
    "population": 13000,
    "id": 1826346821
  },
  {
    "city": "East Riverdale",
    "city_ascii": "East Riverdale",
    "lat": 38.96,
    "lng": -76.9108,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 15467,
    "id": 1840031482
  },
  {
    "city": "Schwyz",
    "city_ascii": "Schwyz",
    "lat": 47.0205,
    "lng": 8.6583,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Schwyz",
    "capital": "admin",
    "population": 15181,
    "id": 1756270644
  },
  {
    "city": "Sonoita",
    "city_ascii": "Sonoita",
    "lat": 31.8614,
    "lng": -112.8544,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Sonora",
    "capital": "minor",
    "population": 12849,
    "id": 1484032951
  },
  {
    "city": "Kürdəmir",
    "city_ascii": "Kurdamir",
    "lat": 40.3383,
    "lng": 48.1608,
    "country": "Azerbaijan",
    "iso2": "AZ",
    "iso3": "AZE",
    "admin_name": "Kürdəmir",
    "capital": "admin",
    "population": 15385,
    "id": 1031160110
  },
  {
    "city": "La Palma",
    "city_ascii": "La Palma",
    "lat": 33.8504,
    "lng": -118.0406,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 15428,
    "id": 1840020586
  },
  {
    "city": "La Tour-de-Peilz",
    "city_ascii": "La Tour-de-Peilz",
    "lat": 46.45,
    "lng": 6.8667,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Vaud",
    "capital": "",
    "population": 11829,
    "id": 1756611497
  },
  {
    "city": "Pacific Grove",
    "city_ascii": "Pacific Grove",
    "lat": 36.6192,
    "lng": -121.9255,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 15413,
    "id": 1840020359
  },
  {
    "city": "Asbury Park",
    "city_ascii": "Asbury Park",
    "lat": 40.2226,
    "lng": -74.0119,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 15408,
    "id": 1840003684
  },
  {
    "city": "Cēsis",
    "city_ascii": "Cesis",
    "lat": 57.3131,
    "lng": 25.2747,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Cēsu Novads",
    "capital": "admin",
    "population": 15293,
    "id": 1428840269
  },
  {
    "city": "Göygöl",
    "city_ascii": "Goygol",
    "lat": 40.5905,
    "lng": 46.3239,
    "country": "Azerbaijan",
    "iso2": "AZ",
    "iso3": "AZE",
    "admin_name": "Göygöl",
    "capital": "admin",
    "population": 15338,
    "id": 1031823379
  },
  {
    "city": "Hamworthy",
    "city_ascii": "Hamworthy",
    "lat": 50.7207,
    "lng": -2.0109,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Poole",
    "capital": "",
    "population": 13141,
    "id": 1826152071
  },
  {
    "city": "Berkley",
    "city_ascii": "Berkley",
    "lat": 42.4986,
    "lng": -83.1853,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 15366,
    "id": 1840002437
  },
  {
    "city": "Anenecuilco",
    "city_ascii": "Anenecuilco",
    "lat": 18.7781,
    "lng": -98.9861,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Morelos",
    "capital": "",
    "population": 10773,
    "id": 1484003918
  },
  {
    "city": "Westbury",
    "city_ascii": "Westbury",
    "lat": 40.7599,
    "lng": -73.5891,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 15351,
    "id": 1840005332
  },
  {
    "city": "Manga",
    "city_ascii": "Manga",
    "lat": 11.6667,
    "lng": -1.0667,
    "country": "Burkina Faso",
    "iso2": "BF",
    "iso3": "BFA",
    "admin_name": "Centre-Sud",
    "capital": "admin",
    "population": 15173,
    "id": 1854485968
  },
  {
    "city": "La Grange",
    "city_ascii": "La Grange",
    "lat": 41.8072,
    "lng": -87.8741,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 15322,
    "id": 1840011255
  },
  {
    "city": "Devizes",
    "city_ascii": "Devizes",
    "lat": 51.353,
    "lng": -1.994,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Wiltshire",
    "capital": "",
    "population": 11715,
    "id": 1826715932
  },
  {
    "city": "Bartica",
    "city_ascii": "Bartica",
    "lat": 6.4,
    "lng": -58.6167,
    "country": "Guyana",
    "iso2": "GY",
    "iso3": "GUY",
    "admin_name": "Cuyuni-Mazaruni",
    "capital": "admin",
    "population": 8532,
    "id": 1328806426
  },
  {
    "city": "Radviliškis",
    "city_ascii": "Radviliskis",
    "lat": 55.8,
    "lng": 23.55,
    "country": "Lithuania",
    "iso2": "LT",
    "iso3": "LTU",
    "admin_name": "Radviliškis",
    "capital": "admin",
    "population": 15275,
    "id": 1440712589
  },
  {
    "city": "Wilkinsburg",
    "city_ascii": "Wilkinsburg",
    "lat": 40.4442,
    "lng": -79.8733,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 15292,
    "id": 1840001278
  },
  {
    "city": "El Fuerte",
    "city_ascii": "El Fuerte",
    "lat": 26.4214,
    "lng": -108.62,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Sinaloa",
    "capital": "minor",
    "population": 12566,
    "id": 1484950387
  },
  {
    "city": "Zubin Potok",
    "city_ascii": "Zubin Potok",
    "lat": 42.9167,
    "lng": 20.6833,
    "country": "Kosovo",
    "iso2": "XK",
    "iso3": "XKS",
    "admin_name": "Zubin Potok",
    "capital": "admin",
    "population": 15200,
    "id": 1901608808
  },
  {
    "city": "Isla Mujeres",
    "city_ascii": "Isla Mujeres",
    "lat": 21.2084,
    "lng": -86.7115,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Quintana Roo",
    "capital": "minor",
    "population": 12491,
    "id": 1484856745
  },
  {
    "city": "La Magdalena Chichicaspa",
    "city_ascii": "La Magdalena Chichicaspa",
    "lat": 19.4181,
    "lng": -99.3228,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "México",
    "capital": "",
    "population": 12193,
    "id": 1484790302
  },
  {
    "city": "Campbelltown",
    "city_ascii": "Campbelltown",
    "lat": -34.0733,
    "lng": 150.8261,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 12566,
    "id": 1036474450
  },
  {
    "city": "Liestal",
    "city_ascii": "Liestal",
    "lat": 47.4839,
    "lng": 7.735,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Basel-Landschaft",
    "capital": "admin",
    "population": 14390,
    "id": 1756371637
  },
  {
    "city": "Chêne-Bougeries",
    "city_ascii": "Chene-Bougeries",
    "lat": 46.1833,
    "lng": 6.1833,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Genève",
    "capital": "",
    "population": 12215,
    "id": 1756147685
  },
  {
    "city": "Wordsley",
    "city_ascii": "Wordsley",
    "lat": 52.483,
    "lng": -2.15,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Dudley",
    "capital": "",
    "population": 12582,
    "id": 1826751704
  },
  {
    "city": "Snaresbrook",
    "city_ascii": "Snaresbrook",
    "lat": 51.587,
    "lng": 0.0146,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Redbridge",
    "capital": "",
    "population": 11868,
    "id": 1826731920
  },
  {
    "city": "Santa María Huazolotitlán",
    "city_ascii": "Santa Maria Huazolotitlan",
    "lat": 16.3014,
    "lng": -97.9125,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Oaxaca",
    "capital": "minor",
    "population": 11400,
    "id": 1484964628
  },
  {
    "city": "Paro",
    "city_ascii": "Paro",
    "lat": 27.4333,
    "lng": 89.4167,
    "country": "Bhutan",
    "iso2": "BT",
    "iso3": "BTN",
    "admin_name": "Paro",
    "capital": "admin",
    "population": 15000,
    "id": 1064216277
  },
  {
    "city": "Broughton Astley",
    "city_ascii": "Broughton Astley",
    "lat": 52.5278,
    "lng": -1.2275,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Leicestershire",
    "capital": "",
    "population": 11940,
    "id": 1826146185
  },
  {
    "city": "Seaford",
    "city_ascii": "Seaford",
    "lat": 40.6678,
    "lng": -73.4922,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 15113,
    "id": 1840005275
  },
  {
    "city": "Ecublens",
    "city_ascii": "Ecublens",
    "lat": 46.5276,
    "lng": 6.5605,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Vaud",
    "capital": "",
    "population": 12854,
    "id": 1756001755
  },
  {
    "city": "Phôngsali",
    "city_ascii": "Phongsali",
    "lat": 21.6836,
    "lng": 102.1053,
    "country": "Laos",
    "iso2": "LA",
    "iso3": "LAO",
    "admin_name": "Phôngsali",
    "capital": "admin",
    "population": 15083,
    "id": 1418524729
  },
  {
    "city": "Lambeth",
    "city_ascii": "Lambeth",
    "lat": 51.4903,
    "lng": -0.1193,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Lambeth",
    "capital": "",
    "population": 9675,
    "id": 1826545364
  },
  {
    "city": "Cuencamé de Ceniceros",
    "city_ascii": "Cuencame de Ceniceros",
    "lat": 24.8667,
    "lng": -103.7,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Durango",
    "capital": "minor",
    "population": 9848,
    "id": 1484919654
  },
  {
    "city": "Quthing",
    "city_ascii": "Quthing",
    "lat": -30.4001,
    "lng": 27.7002,
    "country": "Lesotho",
    "iso2": "LS",
    "iso3": "LSO",
    "admin_name": "Quthing",
    "capital": "admin",
    "population": 15000,
    "id": 1426618526
  },
  {
    "city": "West Park",
    "city_ascii": "West Park",
    "lat": 25.984,
    "lng": -80.1923,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 15089,
    "id": 1840016002
  },
  {
    "city": "Wallingford",
    "city_ascii": "Wallingford",
    "lat": 51.599,
    "lng": -1.125,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Oxfordshire",
    "capital": "",
    "population": 11600,
    "id": 1826443803
  },
  {
    "city": "Timperley",
    "city_ascii": "Timperley",
    "lat": 53.387,
    "lng": -2.328,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Trafford",
    "capital": "",
    "population": 11061,
    "id": 1826838996
  },
  {
    "city": "Parkstone",
    "city_ascii": "Parkstone",
    "lat": 50.71,
    "lng": -1.95,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Poole",
    "capital": "",
    "population": 10779,
    "id": 1826119514
  },
  {
    "city": "San Isidro Buen Suceso",
    "city_ascii": "San Isidro Buen Suceso",
    "lat": 19.1528,
    "lng": -98.1069,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Tlaxcala",
    "capital": "",
    "population": 7688,
    "id": 1484003997
  },
  {
    "city": "Sandridge",
    "city_ascii": "Sandridge",
    "lat": 51.7808,
    "lng": -0.3038,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hertfordshire",
    "capital": "",
    "population": 11451,
    "id": 1826504633
  },
  {
    "city": "Gwanda",
    "city_ascii": "Gwanda",
    "lat": -20.945,
    "lng": 29.025,
    "country": "Zimbabwe",
    "iso2": "ZW",
    "iso3": "ZWE",
    "admin_name": "Matabeleland South",
    "capital": "admin",
    "population": 14450,
    "id": 1716612305
  },
  {
    "city": "Olton",
    "city_ascii": "Olton",
    "lat": 52.4377,
    "lng": -1.8058,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Solihull",
    "capital": "",
    "population": 12167,
    "id": 1826454094
  },
  {
    "city": "Masallı",
    "city_ascii": "Masalli",
    "lat": 39.0183,
    "lng": 48.6669,
    "country": "Azerbaijan",
    "iso2": "AZ",
    "iso3": "AZE",
    "admin_name": "Masallı",
    "capital": "admin",
    "population": 14746,
    "id": 1031126416
  },
  {
    "city": "Kenmore",
    "city_ascii": "Kenmore",
    "lat": 42.9646,
    "lng": -78.8713,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 15020,
    "id": 1840004386
  },
  {
    "city": "Fayrōz Kōh",
    "city_ascii": "Fayroz Koh",
    "lat": 34.5225,
    "lng": 65.2517,
    "country": "Afghanistan",
    "iso2": "AF",
    "iso3": "AFG",
    "admin_name": "Ghōr",
    "capital": "admin",
    "population": 15000,
    "id": 1004988038
  },
  {
    "city": "Leopold",
    "city_ascii": "Leopold",
    "lat": -38.1892,
    "lng": 144.4644,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Victoria",
    "capital": "",
    "population": 12814,
    "id": 1036263278
  },
  {
    "city": "Baucau",
    "city_ascii": "Baucau",
    "lat": -8.4667,
    "lng": 126.45,
    "country": "Timor-Leste",
    "iso2": "TL",
    "iso3": "TLS",
    "admin_name": "Baucau",
    "capital": "admin",
    "population": 14961,
    "id": 1626835210
  },
  {
    "city": "Saucillo",
    "city_ascii": "Saucillo",
    "lat": 28.0333,
    "lng": -105.2833,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Chihuahua",
    "capital": "minor",
    "population": 11004,
    "id": 1484590798
  },
  {
    "city": "José María Morelos",
    "city_ascii": "Jose Maria Morelos",
    "lat": 19.75,
    "lng": -88.7,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Quintana Roo",
    "capital": "minor",
    "population": 11750,
    "id": 1484872600
  },
  {
    "city": "South Farmingdale",
    "city_ascii": "South Farmingdale",
    "lat": 40.7175,
    "lng": -73.4471,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 14953,
    "id": 1840005276
  },
  {
    "city": "Woolton",
    "city_ascii": "Woolton",
    "lat": 53.374,
    "lng": -2.865,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Liverpool",
    "capital": "",
    "population": 12921,
    "id": 1826782144
  },
  {
    "city": "Fords",
    "city_ascii": "Fords",
    "lat": 40.5415,
    "lng": -74.3124,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 14948,
    "id": 1840005409
  },
  {
    "city": "Le Grand-Saconnex",
    "city_ascii": "Le Grand-Saconnex",
    "lat": 46.2333,
    "lng": 6.1167,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Genève",
    "capital": "",
    "population": 12162,
    "id": 1756885352
  },
  {
    "city": "Newport",
    "city_ascii": "Newport",
    "lat": 39.0855,
    "lng": -84.4868,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kentucky",
    "capital": "",
    "population": 14932,
    "id": 1840014261
  },
  {
    "city": "Killamarsh",
    "city_ascii": "Killamarsh",
    "lat": 53.3205,
    "lng": -1.3116,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Derbyshire",
    "capital": "",
    "population": 9445,
    "id": 1826000850
  },
  {
    "city": "Prescot",
    "city_ascii": "Prescot",
    "lat": 53.422,
    "lng": -2.814,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Knowsley",
    "capital": "",
    "population": 11184,
    "id": 1826329091
  },
  {
    "city": "Huntington",
    "city_ascii": "Huntington",
    "lat": 53.9926,
    "lng": -1.043,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "York",
    "capital": "",
    "population": 12108,
    "id": 1826116194
  },
  {
    "city": "Dzitbalché",
    "city_ascii": "Dzitbalche",
    "lat": 20.3167,
    "lng": -90.05,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Campeche",
    "capital": "",
    "population": 11686,
    "id": 1484603562
  },
  {
    "city": "Caldicot",
    "city_ascii": "Caldicot",
    "lat": 51.591,
    "lng": -2.7492,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Monmouthshire",
    "capital": "",
    "population": 12230,
    "id": 1826366288
  },
  {
    "city": "Tadjourah",
    "city_ascii": "Tadjourah",
    "lat": 11.7833,
    "lng": 42.8833,
    "country": "Djibouti",
    "iso2": "DJ",
    "iso3": "DJI",
    "admin_name": "Tadjourah",
    "capital": "admin",
    "population": 14820,
    "id": 1262504958
  },
  {
    "city": "Abergavenny",
    "city_ascii": "Abergavenny",
    "lat": 51.824,
    "lng": -3.0167,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Monmouthshire",
    "capital": "",
    "population": 12515,
    "id": 1826362404
  },
  {
    "city": "Eisenstadt",
    "city_ascii": "Eisenstadt",
    "lat": 47.8456,
    "lng": 16.5189,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Burgenland",
    "capital": "admin",
    "population": 14476,
    "id": 1040751740
  },
  {
    "city": "Rorschach",
    "city_ascii": "Rorschach",
    "lat": 47.4786,
    "lng": 9.4936,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Sankt Gallen",
    "capital": "minor",
    "population": 9439,
    "id": 1756630924
  },
  {
    "city": "Lykóvrysi",
    "city_ascii": "Lykovrysi",
    "lat": 38.0667,
    "lng": 23.7833,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Attikí",
    "capital": "",
    "population": 9738,
    "id": 1300868556
  },
  {
    "city": "Öndörhaan",
    "city_ascii": "Ondorhaan",
    "lat": 47.3167,
    "lng": 110.65,
    "country": "Mongolia",
    "iso2": "MN",
    "iso3": "MNG",
    "admin_name": "Hentiy",
    "capital": "admin",
    "population": 14723,
    "id": 1496719699
  },
  {
    "city": "Sironko",
    "city_ascii": "Sironko",
    "lat": 1.2294,
    "lng": 34.2481,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Sironko",
    "capital": "admin",
    "population": 14100,
    "id": 1800419144
  },
  {
    "city": "Debar",
    "city_ascii": "Debar",
    "lat": 41.525,
    "lng": 20.5272,
    "country": "Macedonia",
    "iso2": "MK",
    "iso3": "MKD",
    "admin_name": "Debar",
    "capital": "admin",
    "population": 14561,
    "id": 1807621851
  },
  {
    "city": "Ewa Beach",
    "city_ascii": "Ewa Beach",
    "lat": 21.3181,
    "lng": -158.0073,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Hawaii",
    "capital": "",
    "population": 14717,
    "id": 1840029470
  },
  {
    "city": "Hwlffordd",
    "city_ascii": "Hwlffordd",
    "lat": 51.8,
    "lng": -4.97,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Pembrokeshire",
    "capital": "",
    "population": 12042,
    "id": 1826514766
  },
  {
    "city": "Pierre",
    "city_ascii": "Pierre",
    "lat": 44.3748,
    "lng": -100.3205,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Dakota",
    "capital": "admin",
    "population": 14659,
    "id": 1840000313
  },
  {
    "city": "Abertillery",
    "city_ascii": "Abertillery",
    "lat": 51.73,
    "lng": -3.13,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Blaenau Gwent",
    "capital": "",
    "population": 11601,
    "id": 1826675845
  },
  {
    "city": "Hoyland Nether",
    "city_ascii": "Hoyland Nether",
    "lat": 53.4985,
    "lng": -1.4406,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Barnsley",
    "capital": "",
    "population": 11852,
    "id": 1826976360
  },
  {
    "city": "New Cassel",
    "city_ascii": "New Cassel",
    "lat": 40.76,
    "lng": -73.5649,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 14648,
    "id": 1840005239
  },
  {
    "city": "Halawa",
    "city_ascii": "Halawa",
    "lat": 21.3753,
    "lng": -157.9185,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Hawaii",
    "capital": "",
    "population": 14640,
    "id": 1840029579
  },
  {
    "city": "Bargoed",
    "city_ascii": "Bargoed",
    "lat": 51.69,
    "lng": -3.24,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Caerphilly",
    "capital": "",
    "population": 11900,
    "id": 1826865415
  },
  {
    "city": "Falls Church",
    "city_ascii": "Falls Church",
    "lat": 38.8847,
    "lng": -77.1751,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 14617,
    "id": 1840003835
  },
  {
    "city": "Franklin Park",
    "city_ascii": "Franklin Park",
    "lat": 40.4439,
    "lng": -74.5432,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 14612,
    "id": 1840039889
  },
  {
    "city": "Cayey",
    "city_ascii": "Cayey",
    "lat": 18.115,
    "lng": -66.163,
    "country": "Puerto Rico",
    "iso2": "PR",
    "iso3": "PRI",
    "admin_name": "Puerto Rico",
    "capital": "",
    "population": 14610,
    "id": 1630035640
  },
  {
    "city": "Luganville",
    "city_ascii": "Luganville",
    "lat": -15.5126,
    "lng": 167.1766,
    "country": "Vanuatu",
    "iso2": "VU",
    "iso3": "VUT",
    "admin_name": "Sanma",
    "capital": "admin",
    "population": 13397,
    "id": 1548569362
  },
  {
    "city": "Kriva Palanka",
    "city_ascii": "Kriva Palanka",
    "lat": 42.2017,
    "lng": 22.3317,
    "country": "Macedonia",
    "iso2": "MK",
    "iso3": "MKD",
    "admin_name": "Kriva Palanka",
    "capital": "admin",
    "population": 14558,
    "id": 1807058849
  },
  {
    "city": "Forécariah",
    "city_ascii": "Forecariah",
    "lat": 9.43,
    "lng": -13.098,
    "country": "Guinea",
    "iso2": "GN",
    "iso3": "GIN",
    "admin_name": "Kindia",
    "capital": "minor",
    "population": 12358,
    "id": 1324863095
  },
  {
    "city": "Jaltenango",
    "city_ascii": "Jaltenango",
    "lat": 15.8725,
    "lng": -92.725,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Chiapas",
    "capital": "minor",
    "population": 10427,
    "id": 1484260963
  },
  {
    "city": "Pincourt",
    "city_ascii": "Pincourt",
    "lat": 45.3833,
    "lng": -73.9833,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 14558,
    "id": 1124637966
  },
  {
    "city": "Tierra Colorada",
    "city_ascii": "Tierra Colorada",
    "lat": 17.1656,
    "lng": -99.5264,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Guerrero",
    "capital": "minor",
    "population": 10502,
    "id": 1484516715
  },
  {
    "city": "North Fair Oaks",
    "city_ascii": "North Fair Oaks",
    "lat": 37.4754,
    "lng": -122.2035,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 14547,
    "id": 1840028325
  },
  {
    "city": "Basse Santa Su",
    "city_ascii": "Basse Santa Su",
    "lat": 13.31,
    "lng": -14.223,
    "country": "Gambia, The",
    "iso2": "GM",
    "iso3": "GMB",
    "admin_name": "Upper River",
    "capital": "admin",
    "population": 14380,
    "id": 1270820759
  },
  {
    "city": "Ar Rommani",
    "city_ascii": "Ar Rommani",
    "lat": 33.5333,
    "lng": -6.6,
    "country": "Morocco",
    "iso2": "MA",
    "iso3": "MAR",
    "admin_name": "Rabat-Salé-Kénitra",
    "capital": "",
    "population": 12297,
    "id": 1504782261
  },
  {
    "city": "Baruun-Urt",
    "city_ascii": "Baruun-Urt",
    "lat": 46.6806,
    "lng": 113.2792,
    "country": "Mongolia",
    "iso2": "MN",
    "iso3": "MNG",
    "admin_name": "Sühbaatar",
    "capital": "admin",
    "population": 14297,
    "id": 1496627655
  },
  {
    "city": "Villa Unión",
    "city_ascii": "Villa Union",
    "lat": 23.9667,
    "lng": -104.0333,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Durango",
    "capital": "minor",
    "population": 10753,
    "id": 1484796365
  },
  {
    "city": "Olympia Heights",
    "city_ascii": "Olympia Heights",
    "lat": 25.7241,
    "lng": -80.339,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 14536,
    "id": 1840014247
  },
  {
    "city": "Haddon",
    "city_ascii": "Haddon",
    "lat": 39.9063,
    "lng": -75.0625,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 14521,
    "id": 1840081686
  },
  {
    "city": "Carnoustie",
    "city_ascii": "Carnoustie",
    "lat": 56.5005,
    "lng": -2.7147,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Angus",
    "capital": "",
    "population": 11394,
    "id": 1826799363
  },
  {
    "city": "Port Antonio",
    "city_ascii": "Port Antonio",
    "lat": 18.1667,
    "lng": -76.45,
    "country": "Jamaica",
    "iso2": "JM",
    "iso3": "JAM",
    "admin_name": "Portland",
    "capital": "admin",
    "population": 14400,
    "id": 1388024275
  },
  {
    "city": "Rayón",
    "city_ascii": "Rayon",
    "lat": 19.1481,
    "lng": -99.58,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "México",
    "capital": "minor",
    "population": 8590,
    "id": 1484649015
  },
  {
    "city": "Gabú",
    "city_ascii": "Gabu",
    "lat": 12.2833,
    "lng": -14.2167,
    "country": "Guinea-Bissau",
    "iso2": "GW",
    "iso3": "GNB",
    "admin_name": "Gabú",
    "capital": "admin",
    "population": 14430,
    "id": 1624988048
  },
  {
    "city": "Ashby de la Zouch",
    "city_ascii": "Ashby de la Zouch",
    "lat": 52.746,
    "lng": -1.476,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Leicestershire",
    "capital": "",
    "population": 12370,
    "id": 1826304398
  },
  {
    "city": "Tonyrefail",
    "city_ascii": "Tonyrefail",
    "lat": 51.584,
    "lng": -3.4306,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Rhondda Cynon Taff",
    "capital": "",
    "population": 12301,
    "id": 1826868989
  },
  {
    "city": "Wath upon Dearne",
    "city_ascii": "Wath upon Dearne",
    "lat": 53.5022,
    "lng": -1.3402,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Rotherham",
    "capital": "",
    "population": 11816,
    "id": 1826701184
  },
  {
    "city": "Alsager",
    "city_ascii": "Alsager",
    "lat": 53.0961,
    "lng": -2.3048,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Cheshire East",
    "capital": "",
    "population": 11775,
    "id": 1826797417
  },
  {
    "city": "Birsfelden",
    "city_ascii": "Birsfelden",
    "lat": 47.5531,
    "lng": 7.6231,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Basel-Landschaft",
    "capital": "",
    "population": 10299,
    "id": 1756780364
  },
  {
    "city": "Bacalar",
    "city_ascii": "Bacalar",
    "lat": 18.6769,
    "lng": -88.3953,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Quintana Roo",
    "capital": "",
    "population": 11084,
    "id": 1484206678
  },
  {
    "city": "Muna",
    "city_ascii": "Muna",
    "lat": 20.48,
    "lng": -89.72,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Yucatán",
    "capital": "",
    "population": 11469,
    "id": 1484692677
  },
  {
    "city": "Hambantota",
    "city_ascii": "Hambantota",
    "lat": 6.1241,
    "lng": 81.1185,
    "country": "Sri Lanka",
    "iso2": "LK",
    "iso3": "LKA",
    "admin_name": "Southern",
    "capital": "",
    "population": 11213,
    "id": 1144358863
  },
  {
    "city": "Weehawken",
    "city_ascii": "Weehawken",
    "lat": 40.7676,
    "lng": -74.0167,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 14327,
    "id": 1840081754
  },
  {
    "city": "Haydock",
    "city_ascii": "Haydock",
    "lat": 53.4678,
    "lng": -2.6609,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "St. Helens",
    "capital": "",
    "population": 11416,
    "id": 1826418141
  },
  {
    "city": "Neftçala",
    "city_ascii": "Neftcala",
    "lat": 39.3791,
    "lng": 49.2486,
    "country": "Azerbaijan",
    "iso2": "AZ",
    "iso3": "AZE",
    "admin_name": "Neftçala",
    "capital": "admin",
    "population": 14241,
    "id": 1031299249
  },
  {
    "city": "Cudworth",
    "city_ascii": "Cudworth",
    "lat": 53.5784,
    "lng": -1.4149,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Barnsley",
    "capital": "",
    "population": 10977,
    "id": 1826159228
  },
  {
    "city": "Zefýri",
    "city_ascii": "Zefyri",
    "lat": 38.0667,
    "lng": 23.7167,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Attikí",
    "capital": "",
    "population": 9454,
    "id": 1300050699
  },
  {
    "city": "Qusar",
    "city_ascii": "Qusar",
    "lat": 41.4219,
    "lng": 48.4214,
    "country": "Azerbaijan",
    "iso2": "AZ",
    "iso3": "AZE",
    "admin_name": "Qusar",
    "capital": "admin",
    "population": 14230,
    "id": 1031059559
  },
  {
    "city": "Villars-sur-Glâne",
    "city_ascii": "Villars-sur-Glane",
    "lat": 46.7833,
    "lng": 7.1167,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Fribourg",
    "capital": "",
    "population": 12092,
    "id": 1756439374
  },
  {
    "city": "Brislington",
    "city_ascii": "Brislington",
    "lat": 51.4316,
    "lng": -2.5439,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Bristol, City of",
    "capital": "",
    "population": 11511,
    "id": 1826515115
  },
  {
    "city": "Shoeburyness",
    "city_ascii": "Shoeburyness",
    "lat": 51.5316,
    "lng": 0.7978,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Southend-on-Sea",
    "capital": "",
    "population": 11159,
    "id": 1826043837
  },
  {
    "city": "Coalcomán de Vázquez Pallares",
    "city_ascii": "Coalcoman de Vazquez Pallares",
    "lat": 18.7771,
    "lng": -103.1596,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Michoacán de Ocampo",
    "capital": "minor",
    "population": 10715,
    "id": 1484699271
  },
  {
    "city": "Nuevo Progreso",
    "city_ascii": "Nuevo Progreso",
    "lat": 26.0561,
    "lng": -97.9522,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Tamaulipas",
    "capital": "",
    "population": 10178,
    "id": 1484755225
  },
  {
    "city": "Cowley",
    "city_ascii": "Cowley",
    "lat": 51.528,
    "lng": -0.481,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hillingdon",
    "capital": "",
    "population": 8776,
    "id": 1826903011
  },
  {
    "city": "Yehud",
    "city_ascii": "Yehud",
    "lat": 32.0333,
    "lng": 34.8833,
    "country": "Israel",
    "iso2": "IL",
    "iso3": "ISR",
    "admin_name": "Central",
    "capital": "",
    "population": 11100,
    "id": 1376760246
  },
  {
    "city": "Fairview",
    "city_ascii": "Fairview",
    "lat": 40.8182,
    "lng": -74.0022,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 14189,
    "id": 1840003549
  },
  {
    "city": "Halstead",
    "city_ascii": "Halstead",
    "lat": 51.9451,
    "lng": 0.6411,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Essex",
    "capital": "",
    "population": 11906,
    "id": 1826328876
  },
  {
    "city": "Hawaiian Gardens",
    "city_ascii": "Hawaiian Gardens",
    "lat": 33.8303,
    "lng": -118.0728,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 14159,
    "id": 1840020485
  },
  {
    "city": "Norridge",
    "city_ascii": "Norridge",
    "lat": 41.9637,
    "lng": -87.8231,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 14152,
    "id": 1840011302
  },
  {
    "city": "Wealdstone",
    "city_ascii": "Wealdstone",
    "lat": 51.5957,
    "lng": -0.3352,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Brent",
    "capital": "",
    "population": 11394,
    "id": 1826291904
  },
  {
    "city": "Gillingham",
    "city_ascii": "Gillingham",
    "lat": 51.0375,
    "lng": -2.2748,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Dorset",
    "capital": "",
    "population": 11756,
    "id": 1826398821
  },
  {
    "city": "Oakham",
    "city_ascii": "Oakham",
    "lat": 52.6705,
    "lng": -0.7333,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Rutland",
    "capital": "",
    "population": 10922,
    "id": 1826442570
  },
  {
    "city": "Hatch End",
    "city_ascii": "Hatch End",
    "lat": 51.601,
    "lng": -0.3743,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Harrow",
    "capital": "",
    "population": 10693,
    "id": 1826801668
  },
  {
    "city": "Lemington",
    "city_ascii": "Lemington",
    "lat": 54.972,
    "lng": -1.723,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Newcastle upon Tyne",
    "capital": "",
    "population": 10228,
    "id": 1826216570
  },
  {
    "city": "Street",
    "city_ascii": "Street",
    "lat": 51.1235,
    "lng": -2.7381,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Somerset",
    "capital": "",
    "population": 11805,
    "id": 1826840312
  },
  {
    "city": "Cowes",
    "city_ascii": "Cowes",
    "lat": 50.7595,
    "lng": -1.3002,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Isle of Wight",
    "capital": "",
    "population": 10405,
    "id": 1826109660
  },
  {
    "city": "Willowick",
    "city_ascii": "Willowick",
    "lat": 41.6343,
    "lng": -81.468,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 14105,
    "id": 1840000549
  },
  {
    "city": "Lemon Hill",
    "city_ascii": "Lemon Hill",
    "lat": 38.5172,
    "lng": -121.4573,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 14096,
    "id": 1840075922
  },
  {
    "city": "Sjenica",
    "city_ascii": "Sjenica",
    "lat": 43.2667,
    "lng": 20,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Sjenica",
    "capital": "admin",
    "population": 14060,
    "id": 1688303533
  },
  {
    "city": "Raynes Park",
    "city_ascii": "Raynes Park",
    "lat": 51.4033,
    "lng": -0.2321,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Merton",
    "capital": "",
    "population": 9738,
    "id": 1826000018
  },
  {
    "city": "Burslem",
    "city_ascii": "Burslem",
    "lat": 53.0426,
    "lng": -2.1879,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Stoke-on-Trent",
    "capital": "",
    "population": 11314,
    "id": 1826767635
  },
  {
    "city": "Ocean Pointe",
    "city_ascii": "Ocean Pointe",
    "lat": 21.3145,
    "lng": -158.0289,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Hawaii",
    "capital": "",
    "population": 14037,
    "id": 1840039242
  },
  {
    "city": "Chinsali",
    "city_ascii": "Chinsali",
    "lat": -10.5496,
    "lng": 32.06,
    "country": "Zambia",
    "iso2": "ZM",
    "iso3": "ZMB",
    "admin_name": "Muchinga",
    "capital": "admin",
    "population": 14015,
    "id": 1894041491
  },
  {
    "city": "Ryhope",
    "city_ascii": "Ryhope",
    "lat": 54.8679,
    "lng": -1.3698,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Sunderland",
    "capital": "",
    "population": 10484,
    "id": 1826951829
  },
  {
    "city": "Chichihualco",
    "city_ascii": "Chichihualco",
    "lat": 17.655,
    "lng": -99.674,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Guerrero",
    "capital": "minor",
    "population": 10690,
    "id": 1484534707
  },
  {
    "city": "Bishops Cleeve",
    "city_ascii": "Bishops Cleeve",
    "lat": 51.947,
    "lng": -2.061,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Gloucestershire",
    "capital": "",
    "population": 10612,
    "id": 1826876835
  },
  {
    "city": "Helston",
    "city_ascii": "Helston",
    "lat": 50.1,
    "lng": -5.27,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Cornwall",
    "capital": "",
    "population": 11178,
    "id": 1826301318
  },
  {
    "city": "Cetinje",
    "city_ascii": "Cetinje",
    "lat": 42.3933,
    "lng": 18.9219,
    "country": "Montenegro",
    "iso2": "ME",
    "iso3": "MNE",
    "admin_name": "Cetinje",
    "capital": "admin",
    "population": 13991,
    "id": 1499733751
  },
  {
    "city": "Kisoro",
    "city_ascii": "Kisoro",
    "lat": -1.3539,
    "lng": 29.6983,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Kisoro",
    "capital": "admin",
    "population": 12900,
    "id": 1800685460
  },
  {
    "city": "Nacaome",
    "city_ascii": "Nacaome",
    "lat": 13.5333,
    "lng": -87.4833,
    "country": "Honduras",
    "iso2": "HN",
    "iso3": "HND",
    "admin_name": "Valle",
    "capital": "admin",
    "population": 13931,
    "id": 1340964186
  },
  {
    "city": "Kuressaare",
    "city_ascii": "Kuressaare",
    "lat": 58.2533,
    "lng": 22.4861,
    "country": "Estonia",
    "iso2": "EE",
    "iso3": "EST",
    "admin_name": "Saaremaa",
    "capital": "admin",
    "population": 13097,
    "id": 1233341219
  },
  {
    "city": "Altamirano",
    "city_ascii": "Altamirano",
    "lat": 16.7361,
    "lng": -92.0389,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Chiapas",
    "capital": "minor",
    "population": 9200,
    "id": 1484175446
  },
  {
    "city": "Royston",
    "city_ascii": "Royston",
    "lat": 53.61,
    "lng": -1.45,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Barnsley",
    "capital": "",
    "population": 10728,
    "id": 1826977688
  },
  {
    "city": "Lerma",
    "city_ascii": "Lerma",
    "lat": 19.8,
    "lng": -90.6,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Campeche",
    "capital": "",
    "population": 8281,
    "id": 1484035396
  },
  {
    "city": "Chicago Ridge",
    "city_ascii": "Chicago Ridge",
    "lat": 41.7034,
    "lng": -87.7795,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 13928,
    "id": 1840010146
  },
  {
    "city": "Kippax",
    "city_ascii": "Kippax",
    "lat": 53.7669,
    "lng": -1.3705,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Leeds",
    "capital": "",
    "population": 9785,
    "id": 1826981453
  },
  {
    "city": "Bishopstoke",
    "city_ascii": "Bishopstoke",
    "lat": 50.9679,
    "lng": -1.3278,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hampshire",
    "capital": "",
    "population": 9974,
    "id": 1826495708
  },
  {
    "city": "Canatlán",
    "city_ascii": "Canatlan",
    "lat": 24.52,
    "lng": -104.78,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Durango",
    "capital": "minor",
    "population": 10342,
    "id": 1484221814
  },
  {
    "city": "Nova Gorica",
    "city_ascii": "Nova Gorica",
    "lat": 45.9667,
    "lng": 13.65,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Nova Gorica",
    "capital": "admin",
    "population": 13852,
    "id": 1705939355
  },
  {
    "city": "Collingswood",
    "city_ascii": "Collingswood",
    "lat": 39.916,
    "lng": -75.0759,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 13884,
    "id": 1840016611
  },
  {
    "city": "Kinkala",
    "city_ascii": "Kinkala",
    "lat": -4.35,
    "lng": 14.76,
    "country": "Congo (Brazzaville)",
    "iso2": "CG",
    "iso3": "COG",
    "admin_name": "Pool",
    "capital": "admin",
    "population": 13882,
    "id": 1178867565
  },
  {
    "city": "Concord",
    "city_ascii": "Concord",
    "lat": -33.8444,
    "lng": 151.0903,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 5914,
    "id": 1036807355
  },
  {
    "city": "Negēlē",
    "city_ascii": "Negele",
    "lat": 5.3166,
    "lng": 39.5833,
    "country": "Ethiopia",
    "iso2": "ET",
    "iso3": "ETH",
    "admin_name": "Oromīya",
    "capital": "",
    "population": 11772,
    "id": 1231215294
  },
  {
    "city": "Risca",
    "city_ascii": "Risca",
    "lat": 51.608,
    "lng": -3.091,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Caerphilly",
    "capital": "",
    "population": 11693,
    "id": 1826115137
  },
  {
    "city": "Kemp Mill",
    "city_ascii": "Kemp Mill",
    "lat": 39.0412,
    "lng": -77.0215,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 13838,
    "id": 1840005842
  },
  {
    "city": "Glascote",
    "city_ascii": "Glascote",
    "lat": 52.6245,
    "lng": -1.668,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Staffordshire",
    "capital": "",
    "population": 8344,
    "id": 1826962627
  },
  {
    "city": "Cheam",
    "city_ascii": "Cheam",
    "lat": 51.36,
    "lng": -0.21,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Sutton",
    "capital": "",
    "population": 10285,
    "id": 1826017216
  },
  {
    "city": "Plav",
    "city_ascii": "Plav",
    "lat": 42.5956,
    "lng": 19.945,
    "country": "Montenegro",
    "iso2": "ME",
    "iso3": "MNE",
    "admin_name": "Plav",
    "capital": "admin",
    "population": 13805,
    "id": 1499247875
  },
  {
    "city": "Saint Ann’s Bay",
    "city_ascii": "Saint Ann's Bay",
    "lat": 18.435,
    "lng": -77.2017,
    "country": "Jamaica",
    "iso2": "JM",
    "iso3": "JAM",
    "admin_name": "Saint Ann",
    "capital": "admin",
    "population": 13671,
    "id": 1388256334
  },
  {
    "city": "Sveti Nikole",
    "city_ascii": "Sveti Nikole",
    "lat": 41.865,
    "lng": 21.9425,
    "country": "Macedonia",
    "iso2": "MK",
    "iso3": "MKD",
    "admin_name": "Sveti Nikole",
    "capital": "admin",
    "population": 13746,
    "id": 1807880409
  },
  {
    "city": "Whitefish Bay",
    "city_ascii": "Whitefish Bay",
    "lat": 43.1132,
    "lng": -87.9004,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 13783,
    "id": 1840003036
  },
  {
    "city": "Bexley",
    "city_ascii": "Bexley",
    "lat": 39.965,
    "lng": -82.9343,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 13770,
    "id": 1840003759
  },
  {
    "city": "San Patricio",
    "city_ascii": "San Patricio",
    "lat": 19.2,
    "lng": -104.6833,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Jalisco",
    "capital": "",
    "population": 7569,
    "id": 1484499776
  },
  {
    "city": "Harper Woods",
    "city_ascii": "Harper Woods",
    "lat": 42.439,
    "lng": -82.9292,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 13746,
    "id": 1840003981
  },
  {
    "city": "Las Veredas",
    "city_ascii": "Las Veredas",
    "lat": 23.15,
    "lng": -109.7,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Baja California Sur",
    "capital": "",
    "population": 10478,
    "id": 1484096349
  },
  {
    "city": "González",
    "city_ascii": "Gonzalez",
    "lat": 22.8281,
    "lng": -98.4306,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Tamaulipas",
    "capital": "minor",
    "population": 11212,
    "id": 1484835501
  },
  {
    "city": "Naifaru",
    "city_ascii": "Naifaru",
    "lat": 5.4442,
    "lng": 73.3662,
    "country": "Maldives",
    "iso2": "MV",
    "iso3": "MDV",
    "admin_name": "Faadhippolhu",
    "capital": "admin",
    "population": null,
    "id": 1462186620
  },
  {
    "city": "Highland Park",
    "city_ascii": "Highland Park",
    "lat": 40.5006,
    "lng": -74.4283,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 13711,
    "id": 1840003636
  },
  {
    "city": "Tovuz",
    "city_ascii": "Tovuz",
    "lat": 40.9864,
    "lng": 45.6275,
    "country": "Azerbaijan",
    "iso2": "AZ",
    "iso3": "AZE",
    "admin_name": "Tovuz",
    "capital": "admin",
    "population": 13700,
    "id": 1031405942
  },
  {
    "city": "Forest Park",
    "city_ascii": "Forest Park",
    "lat": 41.8683,
    "lng": -87.8157,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 13704,
    "id": 1840011276
  },
  {
    "city": "Ang Thong",
    "city_ascii": "Ang Thong",
    "lat": 14.5925,
    "lng": 100.4572,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Ang Thong",
    "capital": "admin",
    "population": 13277,
    "id": 1764529940
  },
  {
    "city": "Henley on Thames",
    "city_ascii": "Henley on Thames",
    "lat": 51.536,
    "lng": -0.898,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Oxfordshire",
    "capital": "",
    "population": 11619,
    "id": 1826580742
  },
  {
    "city": "Weiz",
    "city_ascii": "Weiz",
    "lat": 47.2189,
    "lng": 15.6253,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Steiermark",
    "capital": "minor",
    "population": 11431,
    "id": 1040504116
  },
  {
    "city": "Shtërpcë",
    "city_ascii": "Shterpce",
    "lat": 42.2333,
    "lng": 21.0167,
    "country": "Kosovo",
    "iso2": "XK",
    "iso3": "XKS",
    "admin_name": "Shtërpcë",
    "capital": "admin",
    "population": 13630,
    "id": 1901828239
  },
  {
    "city": "Aberaman",
    "city_ascii": "Aberaman",
    "lat": 51.7,
    "lng": -3.4333,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Rhondda Cynon Taff",
    "capital": "",
    "population": 9964,
    "id": 1826702294
  },
  {
    "city": "Torpoint",
    "city_ascii": "Torpoint",
    "lat": 50.376,
    "lng": -4.205,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Cornwall",
    "capital": "",
    "population": 8364,
    "id": 1826843496
  },
  {
    "city": "Hirnyk",
    "city_ascii": "Hirnyk",
    "lat": 48.05,
    "lng": 37.3667,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Donets’ka Oblast’",
    "capital": "",
    "population": 11378,
    "id": 1804281966
  },
  {
    "city": "Roselle Park",
    "city_ascii": "Roselle Park",
    "lat": 40.6653,
    "lng": -74.2666,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 13588,
    "id": 1840001093
  },
  {
    "city": "San Luis de La Loma",
    "city_ascii": "San Luis de La Loma",
    "lat": 17.2714,
    "lng": -100.8911,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Guerrero",
    "capital": "",
    "population": 11090,
    "id": 1484748421
  },
  {
    "city": "Santa Ana",
    "city_ascii": "Santa Ana",
    "lat": 9.932,
    "lng": -84.176,
    "country": "Costa Rica",
    "iso2": "CR",
    "iso3": "CRI",
    "admin_name": "San José",
    "capital": "",
    "population": 11320,
    "id": 1188252548
  },
  {
    "city": "Akil",
    "city_ascii": "Akil",
    "lat": 20.2656,
    "lng": -89.3478,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Yucatán",
    "capital": "",
    "population": 10176,
    "id": 1484515773
  },
  {
    "city": "Tavistock",
    "city_ascii": "Tavistock",
    "lat": 50.545,
    "lng": -4.15,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Devon",
    "capital": "",
    "population": 11018,
    "id": 1826700583
  },
  {
    "city": "Cupar",
    "city_ascii": "Cupar",
    "lat": 56.32,
    "lng": -3.01,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Fife",
    "capital": "",
    "population": 9200,
    "id": 1826556120
  },
  {
    "city": "Marton",
    "city_ascii": "Marton",
    "lat": 54.5406,
    "lng": -1.2124,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Middlesbrough",
    "capital": "",
    "population": 9990,
    "id": 1826705697
  },
  {
    "city": "San Carlos",
    "city_ascii": "San Carlos",
    "lat": 11.2,
    "lng": -84.8333,
    "country": "Nicaragua",
    "iso2": "NI",
    "iso3": "NIC",
    "admin_name": "Río San Juan",
    "capital": "admin",
    "population": 13451,
    "id": 1558170572
  },
  {
    "city": "Stanwell",
    "city_ascii": "Stanwell",
    "lat": 51.457,
    "lng": -0.4715,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Surrey",
    "capital": "",
    "population": 11279,
    "id": 1826245987
  },
  {
    "city": "Ciudad Cuauhtémoc",
    "city_ascii": "Ciudad Cuauhtemoc",
    "lat": 22.1842,
    "lng": -97.8325,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Veracruz",
    "capital": "minor",
    "population": 9740,
    "id": 1484666501
  },
  {
    "city": "Grover Beach",
    "city_ascii": "Grover Beach",
    "lat": 35.1204,
    "lng": -120.6199,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 13459,
    "id": 1840020414
  },
  {
    "city": "Brierfield",
    "city_ascii": "Brierfield",
    "lat": 53.824,
    "lng": -2.235,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Lancashire",
    "capital": "",
    "population": 8193,
    "id": 1826685441
  },
  {
    "city": "Chatteris",
    "city_ascii": "Chatteris",
    "lat": 52.456,
    "lng": 0.055,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Cambridgeshire",
    "capital": "",
    "population": 10453,
    "id": 1826618368
  },
  {
    "city": "Princeton Meadows",
    "city_ascii": "Princeton Meadows",
    "lat": 40.3332,
    "lng": -74.5628,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 13438,
    "id": 1840033474
  },
  {
    "city": "Ifield",
    "city_ascii": "Ifield",
    "lat": 51.1234,
    "lng": -0.2073,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "West Sussex",
    "capital": "",
    "population": 8882,
    "id": 1826269195
  },
  {
    "city": "Buckhurst Hill",
    "city_ascii": "Buckhurst Hill",
    "lat": 51.632,
    "lng": 0.036,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Essex",
    "capital": "",
    "population": 11380,
    "id": 1826018401
  },
  {
    "city": "Newport",
    "city_ascii": "Newport",
    "lat": 52.7691,
    "lng": -2.3787,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Telford and Wrekin",
    "capital": "",
    "population": 11387,
    "id": 1826096263
  },
  {
    "city": "Nuevo Ideal",
    "city_ascii": "Nuevo Ideal",
    "lat": 24.8875,
    "lng": -105.0728,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Durango",
    "capital": "minor",
    "population": 10876,
    "id": 1484407989
  },
  {
    "city": "Faya",
    "city_ascii": "Faya",
    "lat": 17.93,
    "lng": 19.1031,
    "country": "Chad",
    "iso2": "TD",
    "iso3": "TCD",
    "admin_name": "Borkou",
    "capital": "admin",
    "population": 13400,
    "id": 1148095016
  },
  {
    "city": "Eaton Socon",
    "city_ascii": "Eaton Socon",
    "lat": 52.22,
    "lng": -0.29,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Cambridgeshire",
    "capital": "",
    "population": 9042,
    "id": 1826701436
  },
  {
    "city": "Negotino",
    "city_ascii": "Negotino",
    "lat": 41.4839,
    "lng": 22.0892,
    "country": "Macedonia",
    "iso2": "MK",
    "iso3": "MKD",
    "admin_name": "Negotino",
    "capital": "admin",
    "population": 13284,
    "id": 1807848381
  },
  {
    "city": "Waimalu",
    "city_ascii": "Waimalu",
    "lat": 21.3913,
    "lng": -157.9345,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Hawaii",
    "capital": "",
    "population": 13384,
    "id": 1840029588
  },
  {
    "city": "Horsell",
    "city_ascii": "Horsell",
    "lat": 51.3286,
    "lng": -0.5617,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Surrey",
    "capital": "",
    "population": 9384,
    "id": 1826085922
  },
  {
    "city": "Whitchurch",
    "city_ascii": "Whitchurch",
    "lat": 52.969,
    "lng": -2.682,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Shropshire",
    "capital": "",
    "population": 9781,
    "id": 1826717880
  },
  {
    "city": "Platón Sánchez",
    "city_ascii": "Platon Sanchez",
    "lat": 21.2833,
    "lng": -98.3667,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Veracruz",
    "capital": "minor",
    "population": 10009,
    "id": 1484193492
  },
  {
    "city": "Edgewater",
    "city_ascii": "Edgewater",
    "lat": 40.8237,
    "lng": -73.974,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 13364,
    "id": 1840003543
  },
  {
    "city": "Santa Bárbara",
    "city_ascii": "Santa Barbara",
    "lat": 26.8133,
    "lng": -105.8203,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Chihuahua",
    "capital": "minor",
    "population": 8765,
    "id": 1484698134
  },
  {
    "city": "Corinda",
    "city_ascii": "Corinda",
    "lat": -27.4833,
    "lng": 153.1,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Queensland",
    "capital": "",
    "population": 11019,
    "id": 1036501460
  },
  {
    "city": "Kumi",
    "city_ascii": "Kumi",
    "lat": 1.4608,
    "lng": 33.9361,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Kumi",
    "capital": "admin",
    "population": 13000,
    "id": 1800429770
  },
  {
    "city": "Stocksbridge",
    "city_ascii": "Stocksbridge",
    "lat": 53.478,
    "lng": -1.588,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Sheffield",
    "capital": "",
    "population": 9869,
    "id": 1826907758
  },
  {
    "city": "Gladeview",
    "city_ascii": "Gladeview",
    "lat": 25.8395,
    "lng": -80.2368,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 13296,
    "id": 1840029038
  },
  {
    "city": "Kilsyth",
    "city_ascii": "Kilsyth",
    "lat": 55.98,
    "lng": -4.06,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "North Lanarkshire",
    "capital": "",
    "population": 9860,
    "id": 1826765912
  },
  {
    "city": "Villa de Etla",
    "city_ascii": "Villa de Etla",
    "lat": 17.2062,
    "lng": -96.7989,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Oaxaca",
    "capital": "",
    "population": 10737,
    "id": 1484285081
  },
  {
    "city": "Drochia",
    "city_ascii": "Drochia",
    "lat": 48.0308,
    "lng": 27.8126,
    "country": "Moldova",
    "iso2": "MD",
    "iso3": "MDA",
    "admin_name": "Drochia",
    "capital": "admin",
    "population": 13150,
    "id": 1498968635
  },
  {
    "city": "Tlacotalpan",
    "city_ascii": "Tlacotalpan",
    "lat": 18.6167,
    "lng": -95.6667,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Veracruz",
    "capital": "minor",
    "population": 8853,
    "id": 1484457323
  },
  {
    "city": "Delémont",
    "city_ascii": "Delemont",
    "lat": 47.3653,
    "lng": 7.3472,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Jura",
    "capital": "admin",
    "population": 12682,
    "id": 1756748908
  },
  {
    "city": "Männedorf",
    "city_ascii": "Mannedorf",
    "lat": 47.2553,
    "lng": 8.6917,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Zürich",
    "capital": "",
    "population": 10957,
    "id": 1756961965
  },
  {
    "city": "Nieuw Nickerie",
    "city_ascii": "Nieuw Nickerie",
    "lat": 5.9261,
    "lng": -56.9731,
    "country": "Suriname",
    "iso2": "SR",
    "iso3": "SUR",
    "admin_name": "Nickerie",
    "capital": "admin",
    "population": 12818,
    "id": 1740480869
  },
  {
    "city": "Mwanza",
    "city_ascii": "Mwanza",
    "lat": -15.6167,
    "lng": 34.5167,
    "country": "Malawi",
    "iso2": "MW",
    "iso3": "MWI",
    "admin_name": "Mwanza",
    "capital": "admin",
    "population": 11379,
    "id": 1454451365
  },
  {
    "city": "Barnoldswick",
    "city_ascii": "Barnoldswick",
    "lat": 53.9147,
    "lng": -2.1895,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Lancashire",
    "capital": "",
    "population": 10752,
    "id": 1826332641
  },
  {
    "city": "Tixkokob",
    "city_ascii": "Tixkokob",
    "lat": 21.0022,
    "lng": -89.3936,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Yucatán",
    "capital": "",
    "population": 10968,
    "id": 1484306905
  },
  {
    "city": "Santa Ana",
    "city_ascii": "Santa Ana",
    "lat": 30.5406,
    "lng": -111.1205,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Sonora",
    "capital": "minor",
    "population": 10593,
    "id": 1484002576
  },
  {
    "city": "Magugpo Poblacion",
    "city_ascii": "Magugpo Poblacion",
    "lat": 7.3821,
    "lng": 125.8017,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Davao del Norte",
    "capital": "",
    "population": 6726,
    "id": 1608255342
  },
  {
    "city": "Acanceh",
    "city_ascii": "Acanceh",
    "lat": 20.8133,
    "lng": -89.4524,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Yucatán",
    "capital": "minor",
    "population": 10968,
    "id": 1484170180
  },
  {
    "city": "La Grange Park",
    "city_ascii": "La Grange Park",
    "lat": 41.8308,
    "lng": -87.8723,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 13178,
    "id": 1840011256
  },
  {
    "city": "Goldenrod",
    "city_ascii": "Goldenrod",
    "lat": 28.6114,
    "lng": -81.2916,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 13162,
    "id": 1840014080
  },
  {
    "city": "Shorewood",
    "city_ascii": "Shorewood",
    "lat": 43.0914,
    "lng": -87.8864,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 13145,
    "id": 1840003031
  },
  {
    "city": "Flixton",
    "city_ascii": "Flixton",
    "lat": 53.447,
    "lng": -2.391,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Trafford",
    "capital": "",
    "population": 10786,
    "id": 1826292876
  },
  {
    "city": "Huntington",
    "city_ascii": "Huntington",
    "lat": 38.7916,
    "lng": -77.074,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 13135,
    "id": 1840006030
  },
  {
    "city": "Bocas del Toro",
    "city_ascii": "Bocas del Toro",
    "lat": 9.3333,
    "lng": -82.25,
    "country": "Panama",
    "iso2": "PA",
    "iso3": "PAN",
    "admin_name": "Bocas del Toro",
    "capital": "admin",
    "population": 12996,
    "id": 1591037947
  },
  {
    "city": "Ormož",
    "city_ascii": "Ormoz",
    "lat": 46.4071,
    "lng": 16.1505,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Ormož",
    "capital": "admin",
    "population": 12798,
    "id": 1705413437
  },
  {
    "city": "Tetela del Volcán",
    "city_ascii": "Tetela del Volcan",
    "lat": 18.8931,
    "lng": -98.7297,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Morelos",
    "capital": "minor",
    "population": 10199,
    "id": 1484343325
  },
  {
    "city": "Steinbach am Taunus",
    "city_ascii": "Steinbach am Taunus",
    "lat": 50.1678,
    "lng": 8.5719,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 10682,
    "id": 1276335395
  },
  {
    "city": "Ardmore",
    "city_ascii": "Ardmore",
    "lat": 40.0033,
    "lng": -75.2947,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 13083,
    "id": 1840005455
  },
  {
    "city": "Handsworth",
    "city_ascii": "Handsworth",
    "lat": 53.37,
    "lng": -1.4,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Sheffield",
    "capital": "",
    "population": 9957,
    "id": 1826233814
  },
  {
    "city": "Coatesville",
    "city_ascii": "Coatesville",
    "lat": 39.9849,
    "lng": -75.8199,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 13069,
    "id": 1840001424
  },
  {
    "city": "Kibuye",
    "city_ascii": "Kibuye",
    "lat": -2.0594,
    "lng": 29.3481,
    "country": "Rwanda",
    "iso2": "RW",
    "iso3": "RWA",
    "admin_name": "Western Province",
    "capital": "admin",
    "population": 12325,
    "id": 1646264461
  },
  {
    "city": "Pajacuarán",
    "city_ascii": "Pajacuaran",
    "lat": 20.1178,
    "lng": -102.5667,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Michoacán de Ocampo",
    "capital": "minor",
    "population": 10014,
    "id": 1484044014
  },
  {
    "city": "Hellesdon",
    "city_ascii": "Hellesdon",
    "lat": 52.6485,
    "lng": 1.2509,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Norfolk",
    "capital": "",
    "population": 10957,
    "id": 1826464470
  },
  {
    "city": "Shepton Mallet",
    "city_ascii": "Shepton Mallet",
    "lat": 51.193,
    "lng": -2.546,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Somerset",
    "capital": "",
    "population": 10369,
    "id": 1826237809
  },
  {
    "city": "Menongue",
    "city_ascii": "Menongue",
    "lat": -14.6666,
    "lng": 17.7,
    "country": "Angola",
    "iso2": "AO",
    "iso3": "AGO",
    "admin_name": "Kuando Kubango",
    "capital": "admin",
    "population": 13030,
    "id": 1024725650
  },
  {
    "city": "Mariental",
    "city_ascii": "Mariental",
    "lat": -24.6333,
    "lng": 17.9667,
    "country": "Namibia",
    "iso2": "NA",
    "iso3": "NAM",
    "admin_name": "Hardap",
    "capital": "admin",
    "population": 12478,
    "id": 1516582394
  },
  {
    "city": "La Huacana",
    "city_ascii": "La Huacana",
    "lat": 18.9625,
    "lng": -101.8069,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Michoacán de Ocampo",
    "capital": "minor",
    "population": 9374,
    "id": 1484078900
  },
  {
    "city": "Inverurie",
    "city_ascii": "Inverurie",
    "lat": 57.28,
    "lng": -2.38,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Aberdeenshire",
    "capital": "",
    "population": 10885,
    "id": 1826552358
  },
  {
    "city": "Steinkjer",
    "city_ascii": "Steinkjer",
    "lat": 64.0147,
    "lng": 11.4942,
    "country": "Norway",
    "iso2": "NO",
    "iso3": "NOR",
    "admin_name": "Nord-Trøndelag",
    "capital": "admin",
    "population": 12985,
    "id": 1578642047
  },
  {
    "city": "Stone Ridge",
    "city_ascii": "Stone Ridge",
    "lat": 38.9295,
    "lng": -77.5553,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 12990,
    "id": 1840041868
  },
  {
    "city": "Novo Airão",
    "city_ascii": "Novo Airao",
    "lat": -2.6208,
    "lng": -60.9438,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Amazonas",
    "capital": "",
    "population": 9049,
    "id": 1076092136
  },
  {
    "city": "Río Grande",
    "city_ascii": "Rio Grande",
    "lat": 18.3789,
    "lng": -65.8388,
    "country": "Puerto Rico",
    "iso2": "PR",
    "iso3": "PRI",
    "admin_name": "Puerto Rico",
    "capital": "",
    "population": 12973,
    "id": 1630023701
  },
  {
    "city": "New Carrollton",
    "city_ascii": "New Carrollton",
    "lat": 38.9654,
    "lng": -76.8773,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 12928,
    "id": 1840005978
  },
  {
    "city": "San Ignacio Cohuirimpo",
    "city_ascii": "San Ignacio Cohuirimpo",
    "lat": 27.05,
    "lng": -109.4167,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Sonora",
    "capital": "",
    "population": 10606,
    "id": 1484235510
  },
  {
    "city": "Valga",
    "city_ascii": "Valga",
    "lat": 57.7769,
    "lng": 26.0311,
    "country": "Estonia",
    "iso2": "EE",
    "iso3": "EST",
    "admin_name": "Valgamaa",
    "capital": "admin",
    "population": 12182,
    "id": 1233406308
  },
  {
    "city": "Ialoveni",
    "city_ascii": "Ialoveni",
    "lat": 46.9431,
    "lng": 28.7778,
    "country": "Moldova",
    "iso2": "MD",
    "iso3": "MDA",
    "admin_name": "Ialoveni",
    "capital": "admin",
    "population": 12515,
    "id": 1498897259
  },
  {
    "city": "Ridgefield Park",
    "city_ascii": "Ridgefield Park",
    "lat": 40.8543,
    "lng": -74.0201,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 12901,
    "id": 1840000898
  },
  {
    "city": "Manatí",
    "city_ascii": "Manati",
    "lat": 18.4283,
    "lng": -66.4823,
    "country": "Puerto Rico",
    "iso2": "PR",
    "iso3": "PRI",
    "admin_name": "Puerto Rico",
    "capital": "",
    "population": 12899,
    "id": 1630023562
  },
  {
    "city": "Obo",
    "city_ascii": "Obo",
    "lat": 5.4,
    "lng": 26.5,
    "country": "Central African Republic",
    "iso2": "CF",
    "iso3": "CAF",
    "admin_name": "Haut-Mbomou",
    "capital": "admin",
    "population": 12887,
    "id": 1140533897
  },
  {
    "city": "Daru",
    "city_ascii": "Daru",
    "lat": -9.0833,
    "lng": 143.2,
    "country": "Papua New Guinea",
    "iso2": "PG",
    "iso3": "PNG",
    "admin_name": "Western",
    "capital": "admin",
    "population": 12879,
    "id": 1598923764
  },
  {
    "city": "Lamphun",
    "city_ascii": "Lamphun",
    "lat": 18.5803,
    "lng": 99.0072,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Lamphun",
    "capital": "admin",
    "population": 12595,
    "id": 1764104264
  },
  {
    "city": "Cuatro Ciénegas de Carranza",
    "city_ascii": "Cuatro Cienegas de Carranza",
    "lat": 26.9861,
    "lng": -102.0664,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Coahuila de Zaragoza",
    "capital": "minor",
    "population": 10309,
    "id": 1484012996
  },
  {
    "city": "Ziniaré",
    "city_ascii": "Ziniare",
    "lat": 12.577,
    "lng": -1.293,
    "country": "Burkina Faso",
    "iso2": "BF",
    "iso3": "BFA",
    "admin_name": "Plateau-Central",
    "capital": "admin",
    "population": 12703,
    "id": 1854052758
  },
  {
    "city": "Key Biscayne",
    "city_ascii": "Key Biscayne",
    "lat": 25.6908,
    "lng": -80.1653,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 12846,
    "id": 1840018333
  },
  {
    "city": "Federal Heights",
    "city_ascii": "Federal Heights",
    "lat": 39.8651,
    "lng": -105.0154,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 12827,
    "id": 1840028425
  },
  {
    "city": "Cornelius",
    "city_ascii": "Cornelius",
    "lat": 45.519,
    "lng": -123.0514,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 12822,
    "id": 1840018557
  },
  {
    "city": "Kingston",
    "city_ascii": "Kingston",
    "lat": 41.2652,
    "lng": -75.8875,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 12812,
    "id": 1840000756
  },
  {
    "city": "Tacotalpa",
    "city_ascii": "Tacotalpa",
    "lat": 17.5931,
    "lng": -92.8258,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Tabasco",
    "capital": "minor",
    "population": 8071,
    "id": 1484934308
  },
  {
    "city": "University Heights",
    "city_ascii": "University Heights",
    "lat": 41.4948,
    "lng": -81.5348,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 12797,
    "id": 1840003416
  },
  {
    "city": "Candelaria",
    "city_ascii": "Candelaria",
    "lat": 18.1844,
    "lng": -91.0461,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Campeche",
    "capital": "minor",
    "population": 9812,
    "id": 1484925669
  },
  {
    "city": "Chai Nat",
    "city_ascii": "Chai Nat",
    "lat": 15.1872,
    "lng": 100.1283,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Chai Nat",
    "capital": "admin",
    "population": 12541,
    "id": 1764848732
  },
  {
    "city": "Rokiškis",
    "city_ascii": "Rokiskis",
    "lat": 55.9616,
    "lng": 25.5807,
    "country": "Lithuania",
    "iso2": "LT",
    "iso3": "LTU",
    "admin_name": "Rokiškis",
    "capital": "admin",
    "population": 12738,
    "id": 1440918695
  },
  {
    "city": "Wilton Manors",
    "city_ascii": "Wilton Manors",
    "lat": 26.1593,
    "lng": -80.1395,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 12756,
    "id": 1840016004
  },
  {
    "city": "Valadares",
    "city_ascii": "Valadares",
    "lat": 41.0997,
    "lng": -8.6403,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Porto",
    "capital": "",
    "population": 10678,
    "id": 1620413494
  },
  {
    "city": "Aţ Ţūr",
    "city_ascii": "At Tur",
    "lat": 28.2333,
    "lng": 33.6167,
    "country": "Egypt",
    "iso2": "EG",
    "iso3": "EGY",
    "admin_name": "Janūb Sīnā’",
    "capital": "admin",
    "population": 12733,
    "id": 1818306172
  },
  {
    "city": "Blackwood",
    "city_ascii": "Blackwood",
    "lat": 51.67,
    "lng": -3.19,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Caerphilly",
    "capital": "",
    "population": 8496,
    "id": 1826521209
  },
  {
    "city": "Võru",
    "city_ascii": "Voru",
    "lat": 57.8486,
    "lng": 26.9928,
    "country": "Estonia",
    "iso2": "EE",
    "iso3": "EST",
    "admin_name": "Võrumaa",
    "capital": "admin",
    "population": 11859,
    "id": 1233754374
  },
  {
    "city": "Exhall",
    "city_ascii": "Exhall",
    "lat": 52.47,
    "lng": -1.48,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Coventry",
    "capital": "",
    "population": 8006,
    "id": 1826009826
  },
  {
    "city": "Tərtər",
    "city_ascii": "Tartar",
    "lat": 40.3353,
    "lng": 46.9303,
    "country": "Azerbaijan",
    "iso2": "AZ",
    "iso3": "AZE",
    "admin_name": "Tərtər",
    "capital": "admin",
    "population": 12563,
    "id": 1031622673
  },
  {
    "city": "Clarkston",
    "city_ascii": "Clarkston",
    "lat": 33.8117,
    "lng": -84.2404,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 12637,
    "id": 1840014776
  },
  {
    "city": "Glarus",
    "city_ascii": "Glarus",
    "lat": 47.0331,
    "lng": 9.0664,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Glarus",
    "capital": "admin",
    "population": 12515,
    "id": 1756095767
  },
  {
    "city": "Caernarfon",
    "city_ascii": "Caernarfon",
    "lat": 53.14,
    "lng": -4.27,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Gwynedd",
    "capital": "",
    "population": 9852,
    "id": 1826011275
  },
  {
    "city": "Shildon",
    "city_ascii": "Shildon",
    "lat": 54.63,
    "lng": -1.65,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Durham",
    "capital": "",
    "population": 9976,
    "id": 1826433267
  },
  {
    "city": "Tlahualilo de Zaragoza",
    "city_ascii": "Tlahualilo de Zaragoza",
    "lat": 26.1167,
    "lng": -103.45,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Durango",
    "capital": "minor",
    "population": 9517,
    "id": 1484368402
  },
  {
    "city": "Druskininkai",
    "city_ascii": "Druskininkai",
    "lat": 54.0206,
    "lng": 23.9725,
    "country": "Lithuania",
    "iso2": "LT",
    "iso3": "LTU",
    "admin_name": "Druskininkai",
    "capital": "admin",
    "population": 12576,
    "id": 1440489111
  },
  {
    "city": "Kralendijk",
    "city_ascii": "Kralendijk",
    "lat": 12.1508,
    "lng": -68.2767,
    "country": "Netherlands",
    "iso2": "NL",
    "iso3": "NLD",
    "admin_name": "",
    "capital": "admin",
    "population": 12531,
    "id": 1528693863
  },
  {
    "city": "Cherry Creek",
    "city_ascii": "Cherry Creek",
    "lat": 39.6094,
    "lng": -104.8645,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 12601,
    "id": 1840028588
  },
  {
    "city": "Hînceşti",
    "city_ascii": "Hincesti",
    "lat": 46.8258,
    "lng": 28.5936,
    "country": "Moldova",
    "iso2": "MD",
    "iso3": "MDA",
    "admin_name": "Hînceşti",
    "capital": "admin",
    "population": 12491,
    "id": 1498402212
  },
  {
    "city": "Nairn",
    "city_ascii": "Nairn",
    "lat": 57.586,
    "lng": -3.869,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Highland",
    "capital": "",
    "population": 9773,
    "id": 1826470405
  },
  {
    "city": "Ermoúpoli",
    "city_ascii": "Ermoupoli",
    "lat": 37.4504,
    "lng": 24.9333,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Notío Aigaío",
    "capital": "admin",
    "population": 12260,
    "id": 1300072209
  },
  {
    "city": "Lantana",
    "city_ascii": "Lantana",
    "lat": 26.5834,
    "lng": -80.0564,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 12581,
    "id": 1840017259
  },
  {
    "city": "Sugarland Run",
    "city_ascii": "Sugarland Run",
    "lat": 39.0309,
    "lng": -77.3762,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 12576,
    "id": 1840024510
  },
  {
    "city": "Ahuacatlán",
    "city_ascii": "Ahuacatlan",
    "lat": 21.0536,
    "lng": -104.4869,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Nayarit",
    "capital": "minor",
    "population": 8819,
    "id": 1484972990
  },
  {
    "city": "Roby",
    "city_ascii": "Roby",
    "lat": 53.413,
    "lng": -2.852,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Knowsley",
    "capital": "",
    "population": 7254,
    "id": 1826315331
  },
  {
    "city": "Ntcheu",
    "city_ascii": "Ntcheu",
    "lat": -14.8167,
    "lng": 34.6333,
    "country": "Malawi",
    "iso2": "MW",
    "iso3": "MWI",
    "admin_name": "Ntcheu",
    "capital": "admin",
    "population": 10445,
    "id": 1454008976
  },
  {
    "city": "Sîngerei",
    "city_ascii": "Singerei",
    "lat": 47.6406,
    "lng": 28.1419,
    "country": "Moldova",
    "iso2": "MD",
    "iso3": "MDA",
    "admin_name": "Sîngerei",
    "capital": "admin",
    "population": 12465,
    "id": 1498521430
  },
  {
    "city": "Pelham",
    "city_ascii": "Pelham",
    "lat": 40.9,
    "lng": -73.8064,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 12544,
    "id": 1840004938
  },
  {
    "city": "Nueva Palmira",
    "city_ascii": "Nueva Palmira",
    "lat": -33.87,
    "lng": -58.408,
    "country": "Uruguay",
    "iso2": "UY",
    "iso3": "URY",
    "admin_name": "Colonia",
    "capital": "",
    "population": 9857,
    "id": 1858193401
  },
  {
    "city": "Broadstone",
    "city_ascii": "Broadstone",
    "lat": 50.7605,
    "lng": -1.995,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Poole",
    "capital": "",
    "population": 10303,
    "id": 1826492540
  },
  {
    "city": "Murska Sobota",
    "city_ascii": "Murska Sobota",
    "lat": 46.6586,
    "lng": 16.1619,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Murska Sobota",
    "capital": "admin",
    "population": 11679,
    "id": 1705129726
  },
  {
    "city": "Bradford-on-Avon",
    "city_ascii": "Bradford-on-Avon",
    "lat": 51.347,
    "lng": -2.251,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Wiltshire",
    "capital": "",
    "population": 9402,
    "id": 1826919846
  },
  {
    "city": "Worsley",
    "city_ascii": "Worsley",
    "lat": 53.5093,
    "lng": -2.3845,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Salford",
    "capital": "",
    "population": 10090,
    "id": 1826999294
  },
  {
    "city": "Chapeltown",
    "city_ascii": "Chapeltown",
    "lat": 53.462,
    "lng": -1.466,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Sheffield",
    "capital": "",
    "population": 10043,
    "id": 1826155857
  },
  {
    "city": "Ciudad Tula",
    "city_ascii": "Ciudad Tula",
    "lat": 23,
    "lng": -99.72,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Tamaulipas",
    "capital": "minor",
    "population": 10043,
    "id": 1484516323
  },
  {
    "city": "Bulgan",
    "city_ascii": "Bulgan",
    "lat": 48.8103,
    "lng": 103.5408,
    "country": "Mongolia",
    "iso2": "MN",
    "iso3": "MNG",
    "admin_name": "Bulgan",
    "capital": "admin",
    "population": 12323,
    "id": 1496317433
  },
  {
    "city": "Mariehamn",
    "city_ascii": "Mariehamn",
    "lat": 60.0986,
    "lng": 19.9444,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Åland",
    "capital": "admin",
    "population": 11461,
    "id": 1246614476
  },
  {
    "city": "Taraclia",
    "city_ascii": "Taraclia",
    "lat": 45.9,
    "lng": 28.6689,
    "country": "Moldova",
    "iso2": "MD",
    "iso3": "MDA",
    "admin_name": "Taraclia",
    "capital": "admin",
    "population": 12355,
    "id": 1498129687
  },
  {
    "city": "Warfield",
    "city_ascii": "Warfield",
    "lat": 51.442,
    "lng": -0.737,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Bracknell Forest",
    "capital": "",
    "population": 10088,
    "id": 1826340559
  },
  {
    "city": "Zamora",
    "city_ascii": "Zamora",
    "lat": -4.0692,
    "lng": -78.9567,
    "country": "Ecuador",
    "iso2": "EC",
    "iso3": "ECU",
    "admin_name": "Zamora-Chinchipe",
    "capital": "admin",
    "population": 12386,
    "id": 1218873167
  },
  {
    "city": "Utley",
    "city_ascii": "Utley",
    "lat": 53.88,
    "lng": -1.91,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Bradford",
    "capital": "",
    "population": 5500,
    "id": 1826191797
  },
  {
    "city": "Larbert",
    "city_ascii": "Larbert",
    "lat": 56.0229,
    "lng": -3.826,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Falkirk",
    "capital": "",
    "population": 9143,
    "id": 1826937694
  },
  {
    "city": "Beyləqan",
    "city_ascii": "Beylaqan",
    "lat": 39.7692,
    "lng": 47.6156,
    "country": "Azerbaijan",
    "iso2": "AZ",
    "iso3": "AZE",
    "admin_name": "Beyləqan",
    "capital": "admin",
    "population": 12263,
    "id": 1031681050
  },
  {
    "city": "Patchogue",
    "city_ascii": "Patchogue",
    "lat": 40.7621,
    "lng": -73.0185,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 12321,
    "id": 1840005129
  },
  {
    "city": "Allington",
    "city_ascii": "Allington",
    "lat": 51.2903,
    "lng": 0.5019,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Kent",
    "capital": "",
    "population": 7100,
    "id": 1826198624
  },
  {
    "city": "Heybridge",
    "city_ascii": "Heybridge",
    "lat": 51.7417,
    "lng": 0.6897,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Essex",
    "capital": "",
    "population": 8175,
    "id": 1826355161
  },
  {
    "city": "Sinop",
    "city_ascii": "Sinop",
    "lat": -11.85,
    "lng": -55.46,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Mato Grosso",
    "capital": "",
    "population": 8961,
    "id": 1076876295
  },
  {
    "city": "North Merrick",
    "city_ascii": "North Merrick",
    "lat": 40.6871,
    "lng": -73.5615,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 12232,
    "id": 1840005242
  },
  {
    "city": "Qalāt",
    "city_ascii": "Qalat",
    "lat": 32.1061,
    "lng": 66.9069,
    "country": "Afghanistan",
    "iso2": "AF",
    "iso3": "AFG",
    "admin_name": "Zābul",
    "capital": "admin",
    "population": 12191,
    "id": 1004016690
  },
  {
    "city": "Chuy",
    "city_ascii": "Chuy",
    "lat": -33.6833,
    "lng": -53.45,
    "country": "Uruguay",
    "iso2": "UY",
    "iso3": "URY",
    "admin_name": "Rocha",
    "capital": "",
    "population": 9675,
    "id": 1858852364
  },
  {
    "city": "Batken",
    "city_ascii": "Batken",
    "lat": 40.0625,
    "lng": 70.8194,
    "country": "Kyrgyzstan",
    "iso2": "KG",
    "iso3": "KGZ",
    "admin_name": "Batken",
    "capital": "admin",
    "population": 12134,
    "id": 1417022726
  },
  {
    "city": "Ayapango",
    "city_ascii": "Ayapango",
    "lat": 19.1264,
    "lng": -98.8028,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "México",
    "capital": "minor",
    "population": 8864,
    "id": 1484009067
  },
  {
    "city": "Porto Nacional",
    "city_ascii": "Porto Nacional",
    "lat": -10.7,
    "lng": -48.4199,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Tocantins",
    "capital": "",
    "population": 9129,
    "id": 1076043090
  },
  {
    "city": "Făleşti",
    "city_ascii": "Falesti",
    "lat": 47.5736,
    "lng": 27.7092,
    "country": "Moldova",
    "iso2": "MD",
    "iso3": "MDA",
    "admin_name": "Făleşti",
    "capital": "admin",
    "population": 12074,
    "id": 1498180648
  },
  {
    "city": "Xicoténcatl",
    "city_ascii": "Xicotencatl",
    "lat": 22.9958,
    "lng": -98.9447,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Tamaulipas",
    "capital": "minor",
    "population": 9593,
    "id": 1484154416
  },
  {
    "city": "Westwood Lakes",
    "city_ascii": "Westwood Lakes",
    "lat": 25.7237,
    "lng": -80.3717,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 12121,
    "id": 1840028796
  },
  {
    "city": "Assomada",
    "city_ascii": "Assomada",
    "lat": 15.0949,
    "lng": -23.6654,
    "country": "Cabo Verde",
    "iso2": "CV",
    "iso3": "CPV",
    "admin_name": "Santa Catarina",
    "capital": "admin",
    "population": 12026,
    "id": 1132845019
  },
  {
    "city": "Tottington",
    "city_ascii": "Tottington",
    "lat": 53.613,
    "lng": -2.339,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Bury",
    "capital": "",
    "population": 9783,
    "id": 1826671512
  },
  {
    "city": "Minnehaha",
    "city_ascii": "Minnehaha",
    "lat": 45.6577,
    "lng": -122.6204,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 12092,
    "id": 1840018518
  },
  {
    "city": "Floreşti",
    "city_ascii": "Floresti",
    "lat": 47.8933,
    "lng": 28.3014,
    "country": "Moldova",
    "iso2": "MD",
    "iso3": "MDA",
    "admin_name": "Floreşti",
    "capital": "admin",
    "population": 11998,
    "id": 1498297203
  },
  {
    "city": "Emeryville",
    "city_ascii": "Emeryville",
    "lat": 37.8382,
    "lng": -122.2932,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 12086,
    "id": 1840020291
  },
  {
    "city": "Cimişlia",
    "city_ascii": "Cimislia",
    "lat": 46.52,
    "lng": 28.7842,
    "country": "Moldova",
    "iso2": "MD",
    "iso3": "MDA",
    "admin_name": "Cimişlia",
    "capital": "admin",
    "population": 11997,
    "id": 1498766980
  },
  {
    "city": "Choix",
    "city_ascii": "Choix",
    "lat": 26.7061,
    "lng": -108.3219,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Sinaloa",
    "capital": "minor",
    "population": 9306,
    "id": 1484806011
  },
  {
    "city": "Dinnington",
    "city_ascii": "Dinnington",
    "lat": 53.3667,
    "lng": -1.2,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Rotherham",
    "capital": "",
    "population": 9077,
    "id": 1826308364
  },
  {
    "city": "Tzucacab",
    "city_ascii": "Tzucacab",
    "lat": 20.0708,
    "lng": -89.0506,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Yucatán",
    "capital": "",
    "population": 9967,
    "id": 1484877569
  },
  {
    "city": "Anáhuac",
    "city_ascii": "Anahuac",
    "lat": 28.48,
    "lng": -106.7442,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Chihuahua",
    "capital": "",
    "population": 9952,
    "id": 1484106283
  },
  {
    "city": "Barwell",
    "city_ascii": "Barwell",
    "lat": 52.5682,
    "lng": -1.3462,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Leicestershire",
    "capital": "",
    "population": 9022,
    "id": 1826018837
  },
  {
    "city": "Tekit",
    "city_ascii": "Tekit",
    "lat": 20.5322,
    "lng": -89.3314,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Yucatán",
    "capital": "",
    "population": 9834,
    "id": 1484351882
  },
  {
    "city": "Mpigi",
    "city_ascii": "Mpigi",
    "lat": 0.225,
    "lng": 32.3136,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Mpigi",
    "capital": "admin",
    "population": 11082,
    "id": 1800370948
  },
  {
    "city": "Greenhill",
    "city_ascii": "Greenhill",
    "lat": 51.36,
    "lng": 1.103,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Kent",
    "capital": "",
    "population": 7339,
    "id": 1826652073
  },
  {
    "city": "Robertsport",
    "city_ascii": "Robertsport",
    "lat": 6.7533,
    "lng": -11.3686,
    "country": "Liberia",
    "iso2": "LR",
    "iso3": "LBR",
    "admin_name": "Grand Cape Mount",
    "capital": "admin",
    "population": 11969,
    "id": 1430885010
  },
  {
    "city": "Dundo",
    "city_ascii": "Dundo",
    "lat": -7.38,
    "lng": 20.83,
    "country": "Angola",
    "iso2": "AO",
    "iso3": "AGO",
    "admin_name": "Lunda Norte",
    "capital": "admin",
    "population": 11985,
    "id": 1024121799
  },
  {
    "city": "Hasbrouck Heights",
    "city_ascii": "Hasbrouck Heights",
    "lat": 40.8618,
    "lng": -74.0741,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 11992,
    "id": 1840003558
  },
  {
    "city": "Clayton le Moors",
    "city_ascii": "Clayton le Moors",
    "lat": 53.775,
    "lng": -2.384,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Lancashire",
    "capital": "",
    "population": 8522,
    "id": 1826882980
  },
  {
    "city": "Dowlais",
    "city_ascii": "Dowlais",
    "lat": 51.7585,
    "lng": -3.3416,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Merthyr Tydfil",
    "capital": "",
    "population": 6926,
    "id": 1826418856
  },
  {
    "city": "Red Bank",
    "city_ascii": "Red Bank",
    "lat": 40.3481,
    "lng": -74.0672,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 11966,
    "id": 1840001358
  },
  {
    "city": "Oak Hills",
    "city_ascii": "Oak Hills",
    "lat": 45.5403,
    "lng": -122.8413,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 11962,
    "id": 1840034830
  },
  {
    "city": "Saint Peters",
    "city_ascii": "Saint Peters",
    "lat": 51.3651,
    "lng": 1.4191,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Kent",
    "capital": "",
    "population": 7042,
    "id": 1826950028
  },
  {
    "city": "Amatlán de los Reyes",
    "city_ascii": "Amatlan de los Reyes",
    "lat": 18.8457,
    "lng": -96.9149,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Veracruz",
    "capital": "minor",
    "population": 9123,
    "id": 1484683104
  },
  {
    "city": "Lydney",
    "city_ascii": "Lydney",
    "lat": 51.7286,
    "lng": -2.5285,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Gloucestershire",
    "capital": "",
    "population": 8766,
    "id": 1826019580
  },
  {
    "city": "South Miami",
    "city_ascii": "South Miami",
    "lat": 25.7079,
    "lng": -80.2952,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 11911,
    "id": 1840016006
  },
  {
    "city": "Llantwit Major",
    "city_ascii": "Llantwit Major",
    "lat": 51.4062,
    "lng": -3.475,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Vale of Glamorgan, The",
    "capital": "",
    "population": 9486,
    "id": 1826528530
  },
  {
    "city": "Sežana",
    "city_ascii": "Sezana",
    "lat": 45.7,
    "lng": 13.85,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Sežana",
    "capital": "admin",
    "population": 11842,
    "id": 1705079549
  },
  {
    "city": "Rosario",
    "city_ascii": "Rosario",
    "lat": -34.3139,
    "lng": -57.3525,
    "country": "Uruguay",
    "iso2": "UY",
    "iso3": "URY",
    "admin_name": "Colonia",
    "capital": "",
    "population": 9311,
    "id": 1858160603
  },
  {
    "city": "Gedling",
    "city_ascii": "Gedling",
    "lat": 52.975,
    "lng": -1.081,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Nottinghamshire",
    "capital": "",
    "population": 6817,
    "id": 1826387928
  },
  {
    "city": "Đà Nẵng",
    "city_ascii": "Da Nang",
    "lat": 16.0748,
    "lng": 108.224,
    "country": "Vietnam",
    "iso2": "VN",
    "iso3": "VNM",
    "admin_name": "Đà Nẵng",
    "capital": "admin",
    "population": null,
    "id": 1704949870
  },
  {
    "city": "San José el Viejo",
    "city_ascii": "San Jose el Viejo",
    "lat": 23.1226,
    "lng": -109.712,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Baja California Sur",
    "capital": "",
    "population": 7222,
    "id": 1484016340
  },
  {
    "city": "Rosário",
    "city_ascii": "Rosario",
    "lat": -2.94,
    "lng": -44.26,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Maranhão",
    "capital": "",
    "population": 6798,
    "id": 1076804900
  },
  {
    "city": "Sigulda",
    "city_ascii": "Sigulda",
    "lat": 57.1539,
    "lng": 24.8544,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Siguldas Novads",
    "capital": "admin",
    "population": 11821,
    "id": 1428703594
  },
  {
    "city": "Catarman",
    "city_ascii": "Catarman",
    "lat": 12.4989,
    "lng": 124.6377,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Northern Samar",
    "capital": "admin",
    "population": null,
    "id": 1608432162
  },
  {
    "city": "Kirkwall",
    "city_ascii": "Kirkwall",
    "lat": 58.981,
    "lng": -2.96,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Orkney Islands",
    "capital": "",
    "population": 9293,
    "id": 1826208698
  },
  {
    "city": "Clawson",
    "city_ascii": "Clawson",
    "lat": 42.5367,
    "lng": -83.1504,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 11845,
    "id": 1840002440
  },
  {
    "city": "Ferney-Voltaire",
    "city_ascii": "Ferney-Voltaire",
    "lat": 46.2558,
    "lng": 6.1081,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Auvergne-Rhône-Alpes",
    "capital": "",
    "population": 9766,
    "id": 1250921305
  },
  {
    "city": "North Wantagh",
    "city_ascii": "North Wantagh",
    "lat": 40.6983,
    "lng": -73.5086,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 11802,
    "id": 1840005245
  },
  {
    "city": "Berane",
    "city_ascii": "Berane",
    "lat": 42.8473,
    "lng": 19.8694,
    "country": "Montenegro",
    "iso2": "ME",
    "iso3": "MNE",
    "admin_name": "Berane",
    "capital": "admin",
    "population": 11776,
    "id": 1499204492
  },
  {
    "city": "Alum Rock",
    "city_ascii": "Alum Rock",
    "lat": 37.3695,
    "lng": -121.8241,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 11791,
    "id": 1840017638
  },
  {
    "city": "Launceston",
    "city_ascii": "Launceston",
    "lat": 50.635,
    "lng": -4.354,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Cornwall",
    "capital": "",
    "population": 9216,
    "id": 1826388225
  },
  {
    "city": "Kuldīga",
    "city_ascii": "Kuldiga",
    "lat": 56.9672,
    "lng": 21.97,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Kuldīgas Novads",
    "capital": "admin",
    "population": 11768,
    "id": 1428739306
  },
  {
    "city": "Newman",
    "city_ascii": "Newman",
    "lat": 37.3157,
    "lng": -121.0211,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 11783,
    "id": 1840020288
  },
  {
    "city": "Birtley",
    "city_ascii": "Birtley",
    "lat": 54.9,
    "lng": -1.577,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Gateshead",
    "capital": "",
    "population": 8367,
    "id": 1826754275
  },
  {
    "city": "Tiptree",
    "city_ascii": "Tiptree",
    "lat": 51.81,
    "lng": 0.75,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Essex",
    "capital": "",
    "population": 9182,
    "id": 1826098302
  },
  {
    "city": "Ndélé",
    "city_ascii": "Ndele",
    "lat": 8.4091,
    "lng": 20.653,
    "country": "Central African Republic",
    "iso2": "CF",
    "iso3": "CAF",
    "admin_name": "Bamingui-Bangoran",
    "capital": "admin",
    "population": 11764,
    "id": 1140271951
  },
  {
    "city": "Woodbridge",
    "city_ascii": "Woodbridge",
    "lat": 52.094,
    "lng": 1.318,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Suffolk",
    "capital": "",
    "population": 7749,
    "id": 1826778722
  },
  {
    "city": "Fort Liberté",
    "city_ascii": "Fort Liberte",
    "lat": 19.6656,
    "lng": -71.8448,
    "country": "Haiti",
    "iso2": "HT",
    "iso3": "HTI",
    "admin_name": "Nord-Est",
    "capital": "admin",
    "population": 11465,
    "id": 1332603160
  },
  {
    "city": "Salisbury",
    "city_ascii": "Salisbury",
    "lat": 40.7454,
    "lng": -73.5604,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 11753,
    "id": 1840005255
  },
  {
    "city": "Boxley",
    "city_ascii": "Boxley",
    "lat": 51.3024,
    "lng": 0.5429,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Kent",
    "capital": "",
    "population": 9554,
    "id": 1826029798
  },
  {
    "city": "Bol",
    "city_ascii": "Bol",
    "lat": 13.4586,
    "lng": 14.7147,
    "country": "Chad",
    "iso2": "TD",
    "iso3": "TCD",
    "admin_name": "Lac",
    "capital": "admin",
    "population": 11700,
    "id": 1148232209
  },
  {
    "city": "Tocantinópolis",
    "city_ascii": "Tocantinopolis",
    "lat": -6.3196,
    "lng": -47.42,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Tocantins",
    "capital": "",
    "population": 8750,
    "id": 1076917053
  },
  {
    "city": "Sidney",
    "city_ascii": "Sidney",
    "lat": 48.6506,
    "lng": -123.3986,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "British Columbia",
    "capital": "",
    "population": 11672,
    "id": 1124421362
  },
  {
    "city": "Higuera de Zaragoza",
    "city_ascii": "Higuera de Zaragoza",
    "lat": 25.95,
    "lng": -109.2833,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Sinaloa",
    "capital": "",
    "population": 8976,
    "id": 1484471294
  },
  {
    "city": "Thornhill",
    "city_ascii": "Thornhill",
    "lat": 53.6622,
    "lng": -1.6124,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Kirklees",
    "capital": "",
    "population": 6875,
    "id": 1826942452
  },
  {
    "city": "Alotau",
    "city_ascii": "Alotau",
    "lat": -10.3167,
    "lng": 150.4333,
    "country": "Papua New Guinea",
    "iso2": "PG",
    "iso3": "PNG",
    "admin_name": "Milne Bay",
    "capital": "admin",
    "population": 11624,
    "id": 1598284215
  },
  {
    "city": "Brandon",
    "city_ascii": "Brandon",
    "lat": 52.4474,
    "lng": 0.6242,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Suffolk",
    "capital": "",
    "population": 9145,
    "id": 1826506200
  },
  {
    "city": "View Park-Windsor Hills",
    "city_ascii": "View Park-Windsor Hills",
    "lat": 33.9955,
    "lng": -118.3484,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 11580,
    "id": 1840074686
  },
  {
    "city": "Mountain Ash",
    "city_ascii": "Mountain Ash",
    "lat": 51.6814,
    "lng": -3.3792,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Rhondda Cynon Taff",
    "capital": "",
    "population": 7374,
    "id": 1826926732
  },
  {
    "city": "Tlatlauquitepec",
    "city_ascii": "Tlatlauquitepec",
    "lat": 19.8528,
    "lng": -97.4953,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Puebla",
    "capital": "",
    "population": 9047,
    "id": 1484482819
  },
  {
    "city": "Delčevo",
    "city_ascii": "Delcevo",
    "lat": 41.9661,
    "lng": 22.7747,
    "country": "Macedonia",
    "iso2": "MK",
    "iso3": "MKD",
    "admin_name": "Delčevo",
    "capital": "admin",
    "population": 11500,
    "id": 1807161783
  },
  {
    "city": "Saron",
    "city_ascii": "Saron",
    "lat": -33.181,
    "lng": 19.01,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "Western Cape",
    "capital": "",
    "population": 7843,
    "id": 1710632918
  },
  {
    "city": "La Junta",
    "city_ascii": "La Junta",
    "lat": 28.4778,
    "lng": -107.3317,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Chihuahua",
    "capital": "",
    "population": 8930,
    "id": 1484913185
  },
  {
    "city": "Waipio",
    "city_ascii": "Waipio",
    "lat": 21.4143,
    "lng": -157.9965,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Hawaii",
    "capital": "",
    "population": 11508,
    "id": 1840029589
  },
  {
    "city": "Cuauhtémoc",
    "city_ascii": "Cuauhtemoc",
    "lat": 19.3281,
    "lng": -103.6028,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Colima",
    "capital": "minor",
    "population": 7513,
    "id": 1484009228
  },
  {
    "city": "Yeadon",
    "city_ascii": "Yeadon",
    "lat": 39.9325,
    "lng": -75.2527,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 11496,
    "id": 1840000715
  },
  {
    "city": "Wallington",
    "city_ascii": "Wallington",
    "lat": 40.8535,
    "lng": -74.1069,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 11495,
    "id": 1840000922
  },
  {
    "city": "Sanniquellie",
    "city_ascii": "Sanniquellie",
    "lat": 7.371,
    "lng": -8.685,
    "country": "Liberia",
    "iso2": "LR",
    "iso3": "LBR",
    "admin_name": "Nimba",
    "capital": "admin",
    "population": 11415,
    "id": 1430949102
  },
  {
    "city": "Cam",
    "city_ascii": "Cam",
    "lat": 51.7,
    "lng": -2.3667,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Gloucestershire",
    "capital": "",
    "population": 8152,
    "id": 1826325556
  },
  {
    "city": "Qax",
    "city_ascii": "Qax",
    "lat": 41.4194,
    "lng": 46.9181,
    "country": "Azerbaijan",
    "iso2": "AZ",
    "iso3": "AZE",
    "admin_name": "Qax",
    "capital": "admin",
    "population": 11415,
    "id": 1031852971
  },
  {
    "city": "Home Gardens",
    "city_ascii": "Home Gardens",
    "lat": 33.8784,
    "lng": -117.5116,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 11442,
    "id": 1840017987
  },
  {
    "city": "Sandiacre",
    "city_ascii": "Sandiacre",
    "lat": 52.923,
    "lng": -1.291,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Nottinghamshire",
    "capital": "",
    "population": 8889,
    "id": 1826005601
  },
  {
    "city": "River Edge",
    "city_ascii": "River Edge",
    "lat": 40.9268,
    "lng": -74.0387,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 11435,
    "id": 1840000900
  },
  {
    "city": "Yuscarán",
    "city_ascii": "Yuscaran",
    "lat": 13.9433,
    "lng": -86.8667,
    "country": "Honduras",
    "iso2": "HN",
    "iso3": "HND",
    "admin_name": "El Paraíso",
    "capital": "admin",
    "population": 11396,
    "id": 1340315181
  },
  {
    "city": "Halachó",
    "city_ascii": "Halacho",
    "lat": 20.4764,
    "lng": -90.0819,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Yucatán",
    "capital": "minor",
    "population": 9412,
    "id": 1484161200
  },
  {
    "city": "Signal Hill",
    "city_ascii": "Signal Hill",
    "lat": 33.8029,
    "lng": -118.1681,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 11421,
    "id": 1840021868
  },
  {
    "city": "Ḩadībū",
    "city_ascii": "Hadibu",
    "lat": 12.6519,
    "lng": 54.0239,
    "country": "Yemen",
    "iso2": "YE",
    "iso3": "YEM",
    "admin_name": "Arkhabīl Suquţrá",
    "capital": "admin",
    "population": 11396,
    "id": 1887452377
  },
  {
    "city": "Richmond Heights",
    "city_ascii": "Richmond Heights",
    "lat": 25.6347,
    "lng": -80.372,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 11410,
    "id": 1840014249
  },
  {
    "city": "Weißenthurm",
    "city_ascii": "Weissenthurm",
    "lat": 50.4144,
    "lng": 7.4606,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Rhineland-Palatinate",
    "capital": "",
    "population": 9115,
    "id": 1276833572
  },
  {
    "city": "Leven",
    "city_ascii": "Leven",
    "lat": 56.195,
    "lng": -2.9942,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Fife",
    "capital": "",
    "population": 8850,
    "id": 1826961617
  },
  {
    "city": "Concordia",
    "city_ascii": "Concordia",
    "lat": 23.2883,
    "lng": -106.0675,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Sinaloa",
    "capital": "minor",
    "population": 8328,
    "id": 1484012803
  },
  {
    "city": "Leavesden Green",
    "city_ascii": "Leavesden Green",
    "lat": 51.6967,
    "lng": -0.3991,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hertfordshire",
    "capital": "",
    "population": 5612,
    "id": 1826090772
  },
  {
    "city": "Glastonbury",
    "city_ascii": "Glastonbury",
    "lat": 51.1485,
    "lng": -2.714,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Somerset",
    "capital": "",
    "population": 8932,
    "id": 1826365312
  },
  {
    "city": "Mahadipur",
    "city_ascii": "Mahadipur",
    "lat": 24.8566,
    "lng": 88.1248,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "West Bengal",
    "capital": "",
    "population": 8638,
    "id": 1356139009
  },
  {
    "city": "Dunbar",
    "city_ascii": "Dunbar",
    "lat": 56.0027,
    "lng": -2.5169,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "East Lothian",
    "capital": "",
    "population": 8486,
    "id": 1826158363
  },
  {
    "city": "Narborough",
    "city_ascii": "Narborough",
    "lat": 52.5727,
    "lng": -1.2023,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Leicestershire",
    "capital": "",
    "population": 8448,
    "id": 1826174568
  },
  {
    "city": "Bolton upon Dearne",
    "city_ascii": "Bolton upon Dearne",
    "lat": 53.5197,
    "lng": -1.3148,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Barnsley",
    "capital": "",
    "population": 6744,
    "id": 1826942280
  },
  {
    "city": "Zacapoaxtla",
    "city_ascii": "Zacapoaxtla",
    "lat": 19.8833,
    "lng": -97.5833,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Puebla",
    "capital": "",
    "population": 8062,
    "id": 1484761233
  },
  {
    "city": "Cullercoats",
    "city_ascii": "Cullercoats",
    "lat": 55.033,
    "lng": -1.433,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "North Tyneside",
    "capital": "",
    "population": 9202,
    "id": 1826802473
  },
  {
    "city": "Yarm",
    "city_ascii": "Yarm",
    "lat": 54.505,
    "lng": -1.348,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Stockton-on-Tees",
    "capital": "",
    "population": 8384,
    "id": 1826345215
  },
  {
    "city": "Elektrėnai",
    "city_ascii": "Elektrenai",
    "lat": 54.7886,
    "lng": 24.6612,
    "country": "Lithuania",
    "iso2": "LT",
    "iso3": "LTU",
    "admin_name": "Elektrénai",
    "capital": "admin",
    "population": 11156,
    "id": 1440272776
  },
  {
    "city": "Vanimo",
    "city_ascii": "Vanimo",
    "lat": -2.6817,
    "lng": 141.3014,
    "country": "Papua New Guinea",
    "iso2": "PG",
    "iso3": "PNG",
    "admin_name": "West Sepik",
    "capital": "admin",
    "population": 11204,
    "id": 1598892363
  },
  {
    "city": "Olaine",
    "city_ascii": "Olaine",
    "lat": 56.7844,
    "lng": 23.9369,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Olaines Novads",
    "capital": "admin",
    "population": 11132,
    "id": 1428220700
  },
  {
    "city": "Stopsley",
    "city_ascii": "Stopsley",
    "lat": 51.899,
    "lng": -0.396,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Luton",
    "capital": "",
    "population": 7760,
    "id": 1826317854
  },
  {
    "city": "Rye",
    "city_ascii": "Rye",
    "lat": 50.95,
    "lng": 0.73,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "East Sussex",
    "capital": "",
    "population": 9041,
    "id": 1826538056
  },
  {
    "city": "Rossmoor",
    "city_ascii": "Rossmoor",
    "lat": 33.7886,
    "lng": -118.0803,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 11160,
    "id": 1840019320
  },
  {
    "city": "Magas",
    "city_ascii": "Magas",
    "lat": 43.1667,
    "lng": 44.8167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Ingushetiya",
    "capital": "admin",
    "population": 10333,
    "id": 1643520048
  },
  {
    "city": "Coamo",
    "city_ascii": "Coamo",
    "lat": 18.0765,
    "lng": -66.3638,
    "country": "Puerto Rico",
    "iso2": "PR",
    "iso3": "PRI",
    "admin_name": "Puerto Rico",
    "capital": "",
    "population": 11148,
    "id": 1630035648
  },
  {
    "city": "Westgate on Sea",
    "city_ascii": "Westgate on Sea",
    "lat": 51.3814,
    "lng": 1.3381,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Kent",
    "capital": "",
    "population": 6996,
    "id": 1826595557
  },
  {
    "city": "Piedmont",
    "city_ascii": "Piedmont",
    "lat": 37.8226,
    "lng": -122.23,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 11135,
    "id": 1840020297
  },
  {
    "city": "Great Burstead",
    "city_ascii": "Great Burstead",
    "lat": 51.604,
    "lng": 0.445,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Essex",
    "capital": "",
    "population": 5968,
    "id": 1826018359
  },
  {
    "city": "Guttenberg",
    "city_ascii": "Guttenberg",
    "lat": 40.7927,
    "lng": -74.0048,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 11121,
    "id": 1840003597
  },
  {
    "city": "Summit",
    "city_ascii": "Summit",
    "lat": 41.7877,
    "lng": -87.8146,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 11116,
    "id": 1840011329
  },
  {
    "city": "Rezina",
    "city_ascii": "Rezina",
    "lat": 47.7492,
    "lng": 28.9622,
    "country": "Moldova",
    "iso2": "MD",
    "iso3": "MDA",
    "admin_name": "Rezina",
    "capital": "admin",
    "population": 11032,
    "id": 1498876065
  },
  {
    "city": "Loikaw",
    "city_ascii": "Loikaw",
    "lat": 19.6742,
    "lng": 97.2092,
    "country": "Burma",
    "iso2": "MM",
    "iso3": "MMR",
    "admin_name": "Kayah State",
    "capital": "admin",
    "population": 11000,
    "id": 1104542517
  },
  {
    "city": "Newbold",
    "city_ascii": "Newbold",
    "lat": 53.2519,
    "lng": -1.4461,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Derbyshire",
    "capital": "",
    "population": 7962,
    "id": 1826775307
  },
  {
    "city": "Orange Cove",
    "city_ascii": "Orange Cove",
    "lat": 36.6211,
    "lng": -119.3188,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 11059,
    "id": 1840020322
  },
  {
    "city": "Sundon",
    "city_ascii": "Sundon",
    "lat": 51.92,
    "lng": -0.47,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Luton",
    "capital": "",
    "population": 7585,
    "id": 1826167301
  },
  {
    "city": "Woodchurch",
    "city_ascii": "Woodchurch",
    "lat": 53.375,
    "lng": -3.084,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Wirral",
    "capital": "",
    "population": 8400,
    "id": 1826387480
  },
  {
    "city": "Biltine",
    "city_ascii": "Biltine",
    "lat": 14.5333,
    "lng": 20.9167,
    "country": "Chad",
    "iso2": "TD",
    "iso3": "TCD",
    "admin_name": "Wadi Fira",
    "capital": "admin",
    "population": 11000,
    "id": 1148086843
  },
  {
    "city": "Suffern",
    "city_ascii": "Suffern",
    "lat": 41.1138,
    "lng": -74.1421,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 11007,
    "id": 1840004987
  },
  {
    "city": "La Trinidad",
    "city_ascii": "La Trinidad",
    "lat": 16.4556,
    "lng": 120.5903,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Benguet",
    "capital": "admin",
    "population": null,
    "id": 1608000107
  },
  {
    "city": "Anenii Noi",
    "city_ascii": "Anenii Noi",
    "lat": 46.8817,
    "lng": 29.2308,
    "country": "Moldova",
    "iso2": "MD",
    "iso3": "MDA",
    "admin_name": "Anenii Noi",
    "capital": "admin",
    "population": 10872,
    "id": 1498650298
  },
  {
    "city": "Rothwell",
    "city_ascii": "Rothwell",
    "lat": 52.4254,
    "lng": -0.8029,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Northamptonshire",
    "capital": "",
    "population": 7694,
    "id": 1826859422
  },
  {
    "city": "Karuzi",
    "city_ascii": "Karuzi",
    "lat": -3.1,
    "lng": 30.163,
    "country": "Burundi",
    "iso2": "BI",
    "iso3": "BDI",
    "admin_name": "Karuzi",
    "capital": "admin",
    "population": 10705,
    "id": 1108619045
  },
  {
    "city": "Bedwas",
    "city_ascii": "Bedwas",
    "lat": 51.5926,
    "lng": -3.2061,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Caerphilly",
    "capital": "",
    "population": 8512,
    "id": 1826357082
  },
  {
    "city": "Stonehouse",
    "city_ascii": "Stonehouse",
    "lat": 51.7478,
    "lng": -2.2838,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Gloucestershire",
    "capital": "",
    "population": 7725,
    "id": 1826851075
  },
  {
    "city": "Bramley",
    "city_ascii": "Bramley",
    "lat": 53.4253,
    "lng": -1.2648,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Rotherham",
    "capital": "",
    "population": 8194,
    "id": 1826030000
  },
  {
    "city": "Cocorit",
    "city_ascii": "Cocorit",
    "lat": 27.5833,
    "lng": -109.9667,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Sonora",
    "capital": "",
    "population": 7953,
    "id": 1484738451
  },
  {
    "city": "Cross Gates",
    "city_ascii": "Cross Gates",
    "lat": 53.8063,
    "lng": -1.4506,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Leeds",
    "capital": "",
    "population": 7770,
    "id": 1826857997
  },
  {
    "city": "Călăraşi",
    "city_ascii": "Calarasi",
    "lat": 47.2544,
    "lng": 28.3081,
    "country": "Moldova",
    "iso2": "MD",
    "iso3": "MDA",
    "admin_name": "Călăraşi",
    "capital": "admin",
    "population": 10808,
    "id": 1498598747
  },
  {
    "city": "Vinica",
    "city_ascii": "Vinica",
    "lat": 41.8828,
    "lng": 22.5092,
    "country": "Macedonia",
    "iso2": "MK",
    "iso3": "MKD",
    "admin_name": "Vinica",
    "capital": "admin",
    "population": 10863,
    "id": 1807665568
  },
  {
    "city": "Pyle",
    "city_ascii": "Pyle",
    "lat": 51.5291,
    "lng": -3.6953,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Bridgend",
    "capital": "",
    "population": 7405,
    "id": 1826080391
  },
  {
    "city": "La Riviera",
    "city_ascii": "La Riviera",
    "lat": 38.5683,
    "lng": -121.3544,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 10851,
    "id": 1840028382
  },
  {
    "city": "Ahumada",
    "city_ascii": "Ahumada",
    "lat": 30.6186,
    "lng": -106.5122,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Chihuahua",
    "capital": "minor",
    "population": 8575,
    "id": 1484004336
  },
  {
    "city": "Northbrook",
    "city_ascii": "Northbrook",
    "lat": 39.2467,
    "lng": -84.5796,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 10832,
    "id": 1840034123
  },
  {
    "city": "Stainforth",
    "city_ascii": "Stainforth",
    "lat": 53.5958,
    "lng": -1.0253,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Doncaster",
    "capital": "",
    "population": 6282,
    "id": 1826296892
  },
  {
    "city": "Bucksburn",
    "city_ascii": "Bucksburn",
    "lat": 57.177,
    "lng": -2.175,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Aberdeen City",
    "capital": "",
    "population": 8131,
    "id": 1826325568
  },
  {
    "city": "West Auckland",
    "city_ascii": "West Auckland",
    "lat": 54.6318,
    "lng": -1.721,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Durham",
    "capital": "",
    "population": 8509,
    "id": 1826314267
  },
  {
    "city": "Marlborough",
    "city_ascii": "Marlborough",
    "lat": 51.42,
    "lng": -1.73,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Wiltshire",
    "capital": "",
    "population": 8395,
    "id": 1826128620
  },
  {
    "city": "Maili",
    "city_ascii": "Maili",
    "lat": 21.4134,
    "lng": -158.1702,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Hawaii",
    "capital": "",
    "population": 10792,
    "id": 1840029522
  },
  {
    "city": "Citrus",
    "city_ascii": "Citrus",
    "lat": 34.1161,
    "lng": -117.889,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 10771,
    "id": 1840017916
  },
  {
    "city": "Izola",
    "city_ascii": "Izola",
    "lat": 45.5395,
    "lng": 13.6604,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Izola",
    "capital": "admin",
    "population": 10381,
    "id": 1705476087
  },
  {
    "city": "Little Ferry",
    "city_ascii": "Little Ferry",
    "lat": 40.8463,
    "lng": -74.0388,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 10739,
    "id": 1840003534
  },
  {
    "city": "Gowerton",
    "city_ascii": "Gowerton",
    "lat": 51.648,
    "lng": -4.043,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Swansea",
    "capital": "",
    "population": 8183,
    "id": 1826948192
  },
  {
    "city": "Daşkəsən",
    "city_ascii": "Daskasan",
    "lat": 40.5181,
    "lng": 46.0828,
    "country": "Azerbaijan",
    "iso2": "AZ",
    "iso3": "AZE",
    "admin_name": "Daşkəsən",
    "capital": "admin",
    "population": 10700,
    "id": 1031479797
  },
  {
    "city": "Shifnal",
    "city_ascii": "Shifnal",
    "lat": 52.665,
    "lng": -2.373,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Shropshire",
    "capital": "",
    "population": 7009,
    "id": 1826323213
  },
  {
    "city": "Graçanicë",
    "city_ascii": "Gracanice",
    "lat": 42.6,
    "lng": 21.2,
    "country": "Kosovo",
    "iso2": "XK",
    "iso3": "XKS",
    "admin_name": "Graçanicë",
    "capital": "admin",
    "population": 10675,
    "id": 1901753443
  },
  {
    "city": "Bela Crkva",
    "city_ascii": "Bela Crkva",
    "lat": 44.8975,
    "lng": 21.4172,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Bela Crkva",
    "capital": "admin",
    "population": 10675,
    "id": 1688101335
  },
  {
    "city": "Naranja",
    "city_ascii": "Naranja",
    "lat": 25.5164,
    "lng": -80.4221,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 10702,
    "id": 1840014246
  },
  {
    "city": "Darby",
    "city_ascii": "Darby",
    "lat": 39.921,
    "lng": -75.2611,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 10702,
    "id": 1840000695
  },
  {
    "city": "Oban",
    "city_ascii": "Oban",
    "lat": 56.412,
    "lng": -5.472,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Argyll and Bute",
    "capital": "",
    "population": 8575,
    "id": 1826954935
  },
  {
    "city": "Kavaratti",
    "city_ascii": "Kavaratti",
    "lat": 10.5626,
    "lng": 72.6369,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Lakshadweep",
    "capital": "admin",
    "population": 10688,
    "id": 1356089869
  },
  {
    "city": "El Higo",
    "city_ascii": "El Higo",
    "lat": 21.7667,
    "lng": -98.45,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Veracruz",
    "capital": "minor",
    "population": 7844,
    "id": 1484087998
  },
  {
    "city": "Jõhvi",
    "city_ascii": "Johvi",
    "lat": 59.3575,
    "lng": 27.4122,
    "country": "Estonia",
    "iso2": "EE",
    "iso3": "EST",
    "admin_name": "Ida-Virumaa",
    "capital": "admin",
    "population": 10541,
    "id": 1233731045
  },
  {
    "city": "Latchford",
    "city_ascii": "Latchford",
    "lat": 53.3796,
    "lng": -2.5645,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Warrington",
    "capital": "",
    "population": 7856,
    "id": 1826427560
  },
  {
    "city": "Lansdowne",
    "city_ascii": "Lansdowne",
    "lat": 39.9408,
    "lng": -75.276,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 10647,
    "id": 1840000700
  },
  {
    "city": "Harworth",
    "city_ascii": "Harworth",
    "lat": 53.417,
    "lng": -1.075,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Nottinghamshire",
    "capital": "",
    "population": 7948,
    "id": 1826992188
  },
  {
    "city": "Guadalupe Yancuictlalpan",
    "city_ascii": "Guadalupe Yancuictlalpan",
    "lat": 19.1831,
    "lng": -99.4583,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Morelos",
    "capital": "",
    "population": 6385,
    "id": 1484186132
  },
  {
    "city": "Al Maḩwīt",
    "city_ascii": "Al Mahwit",
    "lat": 15.4694,
    "lng": 43.5453,
    "country": "Yemen",
    "iso2": "YE",
    "iso3": "YEM",
    "admin_name": "Al Maḩwīt",
    "capital": "admin",
    "population": 10593,
    "id": 1887764813
  },
  {
    "city": "Ada",
    "city_ascii": "Ada",
    "lat": 45.8014,
    "lng": 20.1222,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Ada",
    "capital": "admin",
    "population": 10547,
    "id": 1688687750
  },
  {
    "city": "Greenville",
    "city_ascii": "Greenville",
    "lat": 5.0111,
    "lng": -9.0388,
    "country": "Liberia",
    "iso2": "LR",
    "iso3": "LBR",
    "admin_name": "Sinoe",
    "capital": "admin",
    "population": 10374,
    "id": 1430362799
  },
  {
    "city": "Lake Stickney",
    "city_ascii": "Lake Stickney",
    "lat": 47.8709,
    "lng": -122.2596,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 10584,
    "id": 1840037894
  },
  {
    "city": "North Lindenhurst",
    "city_ascii": "North Lindenhurst",
    "lat": 40.7072,
    "lng": -73.3859,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 10584,
    "id": 1840005058
  },
  {
    "city": "Kantunilkín",
    "city_ascii": "Kantunilkin",
    "lat": 21.1,
    "lng": -87.4833,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Quintana Roo",
    "capital": "minor",
    "population": 7150,
    "id": 1484008816
  },
  {
    "city": "Biržai",
    "city_ascii": "Birzai",
    "lat": 56.2,
    "lng": 24.75,
    "country": "Lithuania",
    "iso2": "LT",
    "iso3": "LTU",
    "admin_name": "Biržai",
    "capital": "admin",
    "population": 10561,
    "id": 1440132489
  },
  {
    "city": "Kirundo",
    "city_ascii": "Kirundo",
    "lat": -2.5833,
    "lng": 30.1,
    "country": "Burundi",
    "iso2": "BI",
    "iso3": "BDI",
    "admin_name": "Kirundo",
    "capital": "admin",
    "population": 10024,
    "id": 1108527579
  },
  {
    "city": "Haapsalu",
    "city_ascii": "Haapsalu",
    "lat": 58.9469,
    "lng": 23.5369,
    "country": "Estonia",
    "iso2": "EE",
    "iso3": "EST",
    "admin_name": "Läänemaa",
    "capital": "admin",
    "population": 9675,
    "id": 1233967642
  },
  {
    "city": "Gorleston-on-Sea",
    "city_ascii": "Gorleston-on-Sea",
    "lat": 52.5757,
    "lng": 1.7235,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Norfolk",
    "capital": "",
    "population": 5548,
    "id": 1826692101
  },
  {
    "city": "Penryn",
    "city_ascii": "Penryn",
    "lat": 50.169,
    "lng": -5.107,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Cornwall",
    "capital": "",
    "population": 6812,
    "id": 1826209257
  },
  {
    "city": "Trat",
    "city_ascii": "Trat",
    "lat": 12.2417,
    "lng": 102.5125,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Trat",
    "capital": "admin",
    "population": 10413,
    "id": 1764126822
  },
  {
    "city": "Sarnen",
    "city_ascii": "Sarnen",
    "lat": 46.8969,
    "lng": 8.2469,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Obwalden",
    "capital": "admin",
    "population": 10368,
    "id": 1756451113
  },
  {
    "city": "Phangnga",
    "city_ascii": "Phangnga",
    "lat": 8.4644,
    "lng": 98.5317,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Phangnga",
    "capital": "admin",
    "population": 10412,
    "id": 1764855952
  },
  {
    "city": "West Perrine",
    "city_ascii": "West Perrine",
    "lat": 25.6061,
    "lng": -80.3639,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 10491,
    "id": 1840029018
  },
  {
    "city": "Filadelfia",
    "city_ascii": "Filadelfia",
    "lat": -22.34,
    "lng": -60.03,
    "country": "Paraguay",
    "iso2": "PY",
    "iso3": "PRY",
    "admin_name": "Boquerón",
    "capital": "admin",
    "population": 10470,
    "id": 1600948743
  },
  {
    "city": "Jacinto City",
    "city_ascii": "Jacinto City",
    "lat": 29.7663,
    "lng": -95.241,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 10466,
    "id": 1840019619
  },
  {
    "city": "Santa María Huatulco",
    "city_ascii": "Santa Maria Huatulco",
    "lat": 15.85,
    "lng": -96.3333,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Oaxaca",
    "capital": "minor",
    "population": 7409,
    "id": 1484007409
  },
  {
    "city": "Church",
    "city_ascii": "Church",
    "lat": 53.755,
    "lng": -2.386,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Lancashire",
    "capital": "",
    "population": 5186,
    "id": 1826321995
  },
  {
    "city": "Anstey",
    "city_ascii": "Anstey",
    "lat": 52.6715,
    "lng": -1.1955,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Leicestershire",
    "capital": "",
    "population": 6528,
    "id": 1826242065
  },
  {
    "city": "Ordubad",
    "city_ascii": "Ordubad",
    "lat": 38.9047,
    "lng": 46.0231,
    "country": "Azerbaijan",
    "iso2": "AZ",
    "iso3": "AZE",
    "admin_name": "Ordubad",
    "capital": "admin",
    "population": 10372,
    "id": 1031853196
  },
  {
    "city": "Lascano",
    "city_ascii": "Lascano",
    "lat": -33.6731,
    "lng": -54.2078,
    "country": "Uruguay",
    "iso2": "UY",
    "iso3": "URY",
    "admin_name": "Rocha",
    "capital": "",
    "population": 7645,
    "id": 1858593854
  },
  {
    "city": "Wickersley",
    "city_ascii": "Wickersley",
    "lat": 53.4199,
    "lng": -1.2792,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Rotherham",
    "capital": "",
    "population": 7392,
    "id": 1826056862
  },
  {
    "city": "Jurbarkas",
    "city_ascii": "Jurbarkas",
    "lat": 55.0825,
    "lng": 22.7714,
    "country": "Lithuania",
    "iso2": "LT",
    "iso3": "LTU",
    "admin_name": "Jurbarkas",
    "capital": "admin",
    "population": 10376,
    "id": 1440845969
  },
  {
    "city": "East Whittier",
    "city_ascii": "East Whittier",
    "lat": 33.9244,
    "lng": -117.9887,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 10391,
    "id": 1840019205
  },
  {
    "city": "Cromer",
    "city_ascii": "Cromer",
    "lat": 52.931,
    "lng": 1.302,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Norfolk",
    "capital": "",
    "population": 7683,
    "id": 1826263534
  },
  {
    "city": "Butha-Buthe",
    "city_ascii": "Butha-Buthe",
    "lat": -28.7833,
    "lng": 28.2333,
    "country": "Lesotho",
    "iso2": "LS",
    "iso3": "LSO",
    "admin_name": "Butha-Buthe",
    "capital": "admin",
    "population": 10000,
    "id": 1426574356
  },
  {
    "city": "Saldus",
    "city_ascii": "Saldus",
    "lat": 56.6667,
    "lng": 22.4936,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Saldus Novads",
    "capital": "admin",
    "population": 10311,
    "id": 1428774997
  },
  {
    "city": "Altdorf",
    "city_ascii": "Altdorf",
    "lat": 46.8806,
    "lng": 8.6394,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Uri",
    "capital": "admin",
    "population": 9401,
    "id": 1756957308
  },
  {
    "city": "Wistaston",
    "city_ascii": "Wistaston",
    "lat": 53.0821,
    "lng": -2.4747,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Cheshire East",
    "capital": "",
    "population": 8117,
    "id": 1826133084
  },
  {
    "city": "Cramond",
    "city_ascii": "Cramond",
    "lat": 55.9797,
    "lng": -3.3007,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Edinburgh, City of",
    "capital": "",
    "population": 7502,
    "id": 1826922874
  },
  {
    "city": "Roatán",
    "city_ascii": "Roatan",
    "lat": 16.33,
    "lng": -86.519,
    "country": "Honduras",
    "iso2": "HN",
    "iso3": "HND",
    "admin_name": "Islas de la Bahía",
    "capital": "admin",
    "population": 7514,
    "id": 1340163370
  },
  {
    "city": "Sliema",
    "city_ascii": "Sliema",
    "lat": 35.9125,
    "lng": 14.5019,
    "country": "Malta",
    "iso2": "MT",
    "iso3": "MLT",
    "admin_name": "Sliema",
    "capital": "admin",
    "population": null,
    "id": 1470229986
  },
  {
    "city": "Kanjiža",
    "city_ascii": "Kanjiza",
    "lat": 46.0667,
    "lng": 20.05,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Kanjiža",
    "capital": "admin",
    "population": 10200,
    "id": 1688731968
  },
  {
    "city": "Great Neck",
    "city_ascii": "Great Neck",
    "lat": 40.8028,
    "lng": -73.7332,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 10209,
    "id": 1840005290
  },
  {
    "city": "Timberlane",
    "city_ascii": "Timberlane",
    "lat": 29.8781,
    "lng": -90.0303,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 10192,
    "id": 1840031154
  },
  {
    "city": "West Haverstraw",
    "city_ascii": "West Haverstraw",
    "lat": 41.2063,
    "lng": -73.9883,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 10189,
    "id": 1840004990
  },
  {
    "city": "Ondjiva",
    "city_ascii": "Ondjiva",
    "lat": -17.0667,
    "lng": 15.7333,
    "country": "Angola",
    "iso2": "AO",
    "iso3": "AGO",
    "admin_name": "Cunene",
    "capital": "admin",
    "population": 10169,
    "id": 1024504085
  },
  {
    "city": "Bound Brook",
    "city_ascii": "Bound Brook",
    "lat": 40.5676,
    "lng": -74.5383,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 10180,
    "id": 1840001053
  },
  {
    "city": "Birao",
    "city_ascii": "Birao",
    "lat": 10.2837,
    "lng": 22.7833,
    "country": "Central African Republic",
    "iso2": "CF",
    "iso3": "CAF",
    "admin_name": "Vakaga",
    "capital": "admin",
    "population": 10178,
    "id": 1140392673
  },
  {
    "city": "Kirkham",
    "city_ascii": "Kirkham",
    "lat": 53.782,
    "lng": -2.87,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Lancashire",
    "capital": "",
    "population": 7194,
    "id": 1826899292
  },
  {
    "city": "Buctzotz",
    "city_ascii": "Buctzotz",
    "lat": 21.2017,
    "lng": -88.7928,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Yucatán",
    "capital": "",
    "population": 7515,
    "id": 1484723761
  },
  {
    "city": "Radlett",
    "city_ascii": "Radlett",
    "lat": 51.685,
    "lng": -0.318,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hertfordshire",
    "capital": "",
    "population": 8024,
    "id": 1826850069
  },
  {
    "city": "Nisporeni",
    "city_ascii": "Nisporeni",
    "lat": 47.0814,
    "lng": 28.1783,
    "country": "Moldova",
    "iso2": "MD",
    "iso3": "MDA",
    "admin_name": "Nisporeni",
    "capital": "admin",
    "population": 10063,
    "id": 1498953988
  },
  {
    "city": "Vrnjačka Banja",
    "city_ascii": "Vrnjacka Banja",
    "lat": 43.6167,
    "lng": 20.9,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Vrnjačka Banja",
    "capital": "admin",
    "population": 10065,
    "id": 1688549018
  },
  {
    "city": "Godmanchester",
    "city_ascii": "Godmanchester",
    "lat": 52.3176,
    "lng": -0.1725,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Cambridgeshire",
    "capital": "",
    "population": 6711,
    "id": 1826040598
  },
  {
    "city": "Clowne",
    "city_ascii": "Clowne",
    "lat": 53.276,
    "lng": -1.263,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Derbyshire",
    "capital": "",
    "population": 7447,
    "id": 1826956188
  },
  {
    "city": "Mojkovac",
    "city_ascii": "Mojkovac",
    "lat": 42.96,
    "lng": 19.58,
    "country": "Montenegro",
    "iso2": "ME",
    "iso3": "MNE",
    "admin_name": "Mojkovac",
    "capital": "admin",
    "population": 10066,
    "id": 1499103009
  },
  {
    "city": "Muyinga",
    "city_ascii": "Muyinga",
    "lat": -2.85,
    "lng": 30.3333,
    "country": "Burundi",
    "iso2": "BI",
    "iso3": "BDI",
    "admin_name": "Muyinga",
    "capital": "admin",
    "population": 9609,
    "id": 1108466781
  },
  {
    "city": "Lincoln Village",
    "city_ascii": "Lincoln Village",
    "lat": 39.9532,
    "lng": -83.1314,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 10071,
    "id": 1840034388
  },
  {
    "city": "Coppull",
    "city_ascii": "Coppull",
    "lat": 53.62,
    "lng": -2.661,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Lancashire",
    "capital": "",
    "population": 7959,
    "id": 1826322199
  },
  {
    "city": "Del Aire",
    "city_ascii": "Del Aire",
    "lat": 33.9168,
    "lng": -118.3693,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 10065,
    "id": 1840028367
  },
  {
    "city": "Crieff",
    "city_ascii": "Crieff",
    "lat": 56.3757,
    "lng": -3.8426,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Perth and Kinross",
    "capital": "",
    "population": 7368,
    "id": 1826923281
  },
  {
    "city": "San Francisco del Mar",
    "city_ascii": "San Francisco del Mar",
    "lat": 16.3394,
    "lng": -94.515,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Oaxaca",
    "capital": "",
    "population": 7650,
    "id": 1484190269
  },
  {
    "city": "Tarīn Kōṯ",
    "city_ascii": "Tarin Kot",
    "lat": 32.6333,
    "lng": 65.8667,
    "country": "Afghanistan",
    "iso2": "AF",
    "iso3": "AFG",
    "admin_name": "Uruzgān",
    "capital": "admin",
    "population": 10000,
    "id": 1004893931
  },
  {
    "city": "Hucknall under Huthwaite",
    "city_ascii": "Hucknall under Huthwaite",
    "lat": 53.125,
    "lng": -1.301,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Nottinghamshire",
    "capital": "",
    "population": 7500,
    "id": 1826158793
  },
  {
    "city": "Westgate",
    "city_ascii": "Westgate",
    "lat": 26.6994,
    "lng": -80.0989,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 10026,
    "id": 1840042909
  },
  {
    "city": "Altar",
    "city_ascii": "Altar",
    "lat": 30.7136,
    "lng": -111.8353,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Sonora",
    "capital": "minor",
    "population": 7927,
    "id": 1484018181
  },
  {
    "city": "Capitola",
    "city_ascii": "Capitola",
    "lat": 36.9772,
    "lng": -121.9538,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 10010,
    "id": 1840018959
  },
  {
    "city": "Castillos",
    "city_ascii": "Castillos",
    "lat": -34.1989,
    "lng": -53.8575,
    "country": "Uruguay",
    "iso2": "UY",
    "iso3": "URY",
    "admin_name": "Rocha",
    "capital": "",
    "population": 7541,
    "id": 1858885344
  },
  {
    "city": "Frampton Cotterell",
    "city_ascii": "Frampton Cotterell",
    "lat": 51.54,
    "lng": -2.48,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "South Gloucestershire",
    "capital": "",
    "population": 6520,
    "id": 1826478972
  },
  {
    "city": "Whetstone",
    "city_ascii": "Whetstone",
    "lat": 52.5724,
    "lng": -1.1799,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Leicestershire",
    "capital": "",
    "population": 6556,
    "id": 1826380790
  },
  {
    "city": "Thrapston",
    "city_ascii": "Thrapston",
    "lat": 52.397,
    "lng": -0.538,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Northamptonshire",
    "capital": "",
    "population": 6239,
    "id": 1826301791
  },
  {
    "city": "Cacheu",
    "city_ascii": "Cacheu",
    "lat": 12.278,
    "lng": -16.165,
    "country": "Guinea-Bissau",
    "iso2": "GW",
    "iso3": "GNB",
    "admin_name": "Cacheu",
    "capital": "admin",
    "population": 9882,
    "id": 1624294223
  },
  {
    "city": "Watervliet",
    "city_ascii": "Watervliet",
    "lat": 42.7243,
    "lng": -73.7068,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 9900,
    "id": 1840002495
  },
  {
    "city": "Vilkaviškis",
    "city_ascii": "Vilkaviskis",
    "lat": 54.6667,
    "lng": 23.0333,
    "country": "Lithuania",
    "iso2": "LT",
    "iso3": "LTU",
    "admin_name": "Vilkaviškis",
    "capital": "admin",
    "population": 9855,
    "id": 1440557692
  },
  {
    "city": "Celestún",
    "city_ascii": "Celestun",
    "lat": 20.8583,
    "lng": -90.4,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Yucatán",
    "capital": "minor",
    "population": 6269,
    "id": 1484819687
  },
  {
    "city": "Hopelchén",
    "city_ascii": "Hopelchen",
    "lat": 19.7444,
    "lng": -89.8453,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Campeche",
    "capital": "minor",
    "population": 7295,
    "id": 1484431471
  },
  {
    "city": "Duntocher",
    "city_ascii": "Duntocher",
    "lat": 55.924,
    "lng": -4.417,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "West Dunbartonshire",
    "capital": "",
    "population": 6850,
    "id": 1826692197
  },
  {
    "city": "Aintree",
    "city_ascii": "Aintree",
    "lat": 53.4793,
    "lng": -2.9373,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Liverpool",
    "capital": "",
    "population": 6689,
    "id": 1826876428
  },
  {
    "city": "East Rockaway",
    "city_ascii": "East Rockaway",
    "lat": 40.6432,
    "lng": -73.6672,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 9814,
    "id": 1840005343
  },
  {
    "city": "New Hyde Park",
    "city_ascii": "New Hyde Park",
    "lat": 40.7324,
    "lng": -73.6858,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 9807,
    "id": 1840005313
  },
  {
    "city": "Inwood",
    "city_ascii": "Inwood",
    "lat": 40.6218,
    "lng": -73.7507,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 9804,
    "id": 1840005267
  },
  {
    "city": "Nueva Ocotepeque",
    "city_ascii": "Nueva Ocotepeque",
    "lat": 14.4333,
    "lng": -89.1833,
    "country": "Honduras",
    "iso2": "HN",
    "iso3": "HND",
    "admin_name": "Ocotepeque",
    "capital": "admin",
    "population": 8780,
    "id": 1340380637
  },
  {
    "city": "Marina del Rey",
    "city_ascii": "Marina del Rey",
    "lat": 33.9765,
    "lng": -118.4486,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 9771,
    "id": 1840017920
  },
  {
    "city": "Huningue",
    "city_ascii": "Huningue",
    "lat": 47.5914,
    "lng": 7.5831,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Grand Est",
    "capital": "",
    "population": 7238,
    "id": 1250393244
  },
  {
    "city": "Charter Oak",
    "city_ascii": "Charter Oak",
    "lat": 34.1025,
    "lng": -117.8564,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 9760,
    "id": 1840017919
  },
  {
    "city": "Partington",
    "city_ascii": "Partington",
    "lat": 53.42,
    "lng": -2.43,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Trafford",
    "capital": "",
    "population": 7327,
    "id": 1826211416
  },
  {
    "city": "Novobërdë",
    "city_ascii": "Novoberde",
    "lat": 42.6,
    "lng": 21.4333,
    "country": "Kosovo",
    "iso2": "XK",
    "iso3": "XKS",
    "admin_name": "Novobërdë",
    "capital": "admin",
    "population": 9670,
    "id": 1901684676
  },
  {
    "city": "Makamba",
    "city_ascii": "Makamba",
    "lat": -4.1333,
    "lng": 29.8,
    "country": "Burundi",
    "iso2": "BI",
    "iso3": "BDI",
    "admin_name": "Makamba",
    "capital": "admin",
    "population": 9396,
    "id": 1108520771
  },
  {
    "city": "Highbridge",
    "city_ascii": "Highbridge",
    "lat": 51.2189,
    "lng": -2.973,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Somerset",
    "capital": "",
    "population": 5986,
    "id": 1826801598
  },
  {
    "city": "Pembroke",
    "city_ascii": "Pembroke",
    "lat": 51.676,
    "lng": -4.9158,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Pembrokeshire",
    "capital": "",
    "population": 7552,
    "id": 1826380894
  },
  {
    "city": "Tshabong",
    "city_ascii": "Tshabong",
    "lat": -26.02,
    "lng": 22.4056,
    "country": "Botswana",
    "iso2": "BW",
    "iso3": "BWA",
    "admin_name": "Kgalagadi",
    "capital": "admin",
    "population": 9679,
    "id": 1072830877
  },
  {
    "city": "Thames Ditton",
    "city_ascii": "Thames Ditton",
    "lat": 51.389,
    "lng": -0.331,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Surrey",
    "capital": "",
    "population": 6307,
    "id": 1826658204
  },
  {
    "city": "Brechin",
    "city_ascii": "Brechin",
    "lat": 56.7299,
    "lng": -2.6553,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Angus",
    "capital": "",
    "population": 7199,
    "id": 1826338747
  },
  {
    "city": "Djambala",
    "city_ascii": "Djambala",
    "lat": -2.55,
    "lng": 14.75,
    "country": "Congo (Brazzaville)",
    "iso2": "CG",
    "iso3": "COG",
    "admin_name": "Plateaux",
    "capital": "admin",
    "population": 9651,
    "id": 1178834249
  },
  {
    "city": "Keansburg",
    "city_ascii": "Keansburg",
    "lat": 40.4471,
    "lng": -74.1316,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 9632,
    "id": 1840003690
  },
  {
    "city": "Llantwit Fardre",
    "city_ascii": "Llantwit Fardre",
    "lat": 51.5578,
    "lng": -3.3341,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Rhondda Cynon Taff",
    "capital": "",
    "population": 6099,
    "id": 1826473796
  },
  {
    "city": "Maywood",
    "city_ascii": "Maywood",
    "lat": 40.9025,
    "lng": -74.0634,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 9614,
    "id": 1840003536
  },
  {
    "city": "Barrowford",
    "city_ascii": "Barrowford",
    "lat": 53.851,
    "lng": -2.221,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Lancashire",
    "capital": "",
    "population": 6171,
    "id": 1826437095
  },
  {
    "city": "Pedra Badejo",
    "city_ascii": "Pedra Badejo",
    "lat": 15.1375,
    "lng": -23.5333,
    "country": "Cabo Verde",
    "iso2": "CV",
    "iso3": "CPV",
    "admin_name": "Santa Cruz",
    "capital": "admin",
    "population": 9530,
    "id": 1132841923
  },
  {
    "city": "Littleborough",
    "city_ascii": "Littleborough",
    "lat": 53.644,
    "lng": -2.098,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Rochdale",
    "capital": "",
    "population": 7467,
    "id": 1826853866
  },
  {
    "city": "Sherburn in Elmet",
    "city_ascii": "Sherburn in Elmet",
    "lat": 53.7973,
    "lng": -1.2331,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "North Yorkshire",
    "capital": "",
    "population": 6657,
    "id": 1826096971
  },
  {
    "city": "Kundiawa",
    "city_ascii": "Kundiawa",
    "lat": -6.023,
    "lng": 144.96,
    "country": "Papua New Guinea",
    "iso2": "PG",
    "iso3": "PNG",
    "admin_name": "Chimbu",
    "capital": "admin",
    "population": 9383,
    "id": 1598730976
  },
  {
    "city": "Dinas Powys",
    "city_ascii": "Dinas Powys",
    "lat": 51.4331,
    "lng": -3.2134,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Vale of Glamorgan, The",
    "capital": "",
    "population": 7490,
    "id": 1826280606
  },
  {
    "city": "Ikeja",
    "city_ascii": "Ikeja",
    "lat": 6.5965,
    "lng": 3.3421,
    "country": "Nigeria",
    "iso2": "NG",
    "iso3": "NGA",
    "admin_name": "Lagos",
    "capital": "admin",
    "population": null,
    "id": 1566486388
  },
  {
    "city": "Pájaros",
    "city_ascii": "Pajaros",
    "lat": 18.3609,
    "lng": -66.2184,
    "country": "Puerto Rico",
    "iso2": "PR",
    "iso3": "PRI",
    "admin_name": "Puerto Rico",
    "capital": "",
    "population": 9554,
    "id": 1630035532
  },
  {
    "city": "Withernsea",
    "city_ascii": "Withernsea",
    "lat": 53.7285,
    "lng": 0.0382,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "East Riding of Yorkshire",
    "capital": "",
    "population": 6159,
    "id": 1826436345
  },
  {
    "city": "Brookdale",
    "city_ascii": "Brookdale",
    "lat": 40.8348,
    "lng": -74.1798,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 9539,
    "id": 1840024254
  },
  {
    "city": "Wadsley",
    "city_ascii": "Wadsley",
    "lat": 53.41,
    "lng": -1.52,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Sheffield",
    "capital": "",
    "population": 5631,
    "id": 1826626902
  },
  {
    "city": "Stonegate",
    "city_ascii": "Stonegate",
    "lat": 39.5356,
    "lng": -104.8033,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 9511,
    "id": 1840028570
  },
  {
    "city": "Wardle",
    "city_ascii": "Wardle",
    "lat": 53.647,
    "lng": -2.132,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Rochdale",
    "capital": "",
    "population": 7092,
    "id": 1826170888
  },
  {
    "city": "Bois-des-Filion",
    "city_ascii": "Bois-des-Filion",
    "lat": 45.6667,
    "lng": -73.75,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 9485,
    "id": 1124978470
  },
  {
    "city": "Coral Hills",
    "city_ascii": "Coral Hills",
    "lat": 38.8709,
    "lng": -76.9234,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 9460,
    "id": 1840005950
  },
  {
    "city": "Ojo Caliente",
    "city_ascii": "Ojo Caliente",
    "lat": 21.8606,
    "lng": -102.684,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Aguascalientes",
    "capital": "",
    "population": 6914,
    "id": 1484383643
  },
  {
    "city": "Govan",
    "city_ascii": "Govan",
    "lat": 55.8615,
    "lng": -4.3083,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Glasgow City",
    "capital": "",
    "population": 5860,
    "id": 1826443893
  },
  {
    "city": "Rožaje",
    "city_ascii": "Rozaje",
    "lat": 42.8442,
    "lng": 20.1679,
    "country": "Montenegro",
    "iso2": "ME",
    "iso3": "MNE",
    "admin_name": "Rožaje",
    "capital": "admin",
    "population": 9422,
    "id": 1499854307
  },
  {
    "city": "Aiea",
    "city_ascii": "Aiea",
    "lat": 21.3865,
    "lng": -157.9232,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Hawaii",
    "capital": "",
    "population": 9439,
    "id": 1840029469
  },
  {
    "city": "Hani i Elezit",
    "city_ascii": "Hani i Elezit",
    "lat": 42.1475,
    "lng": 21.2992,
    "country": "Kosovo",
    "iso2": "XK",
    "iso3": "XKS",
    "admin_name": "Hani i Elezit",
    "capital": "admin",
    "population": 9403,
    "id": 1901964789
  },
  {
    "city": "Bladensburg",
    "city_ascii": "Bladensburg",
    "lat": 38.9424,
    "lng": -76.9263,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 9408,
    "id": 1840005981
  },
  {
    "city": "Bauska",
    "city_ascii": "Bauska",
    "lat": 56.4067,
    "lng": 24.1875,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Bauskas Novads",
    "capital": "admin",
    "population": 9348,
    "id": 1428015452
  },
  {
    "city": "Thyolo",
    "city_ascii": "Thyolo",
    "lat": -16.0667,
    "lng": 35.1333,
    "country": "Malawi",
    "iso2": "MW",
    "iso3": "MWI",
    "admin_name": "Thyolo",
    "capital": "admin",
    "population": 7029,
    "id": 1454464861
  },
  {
    "city": "Temascal",
    "city_ascii": "Temascal",
    "lat": 18.2394,
    "lng": -96.4031,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Oaxaca",
    "capital": "",
    "population": 6566,
    "id": 1484505986
  },
  {
    "city": "Sabana Grande",
    "city_ascii": "Sabana Grande",
    "lat": 18.0821,
    "lng": -66.9673,
    "country": "Puerto Rico",
    "iso2": "PR",
    "iso3": "PRI",
    "admin_name": "Puerto Rico",
    "capital": "",
    "population": 9357,
    "id": 1630023550
  },
  {
    "city": "Kirriemuir",
    "city_ascii": "Kirriemuir",
    "lat": 56.6692,
    "lng": -3.0051,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Angus",
    "capital": "",
    "population": 6061,
    "id": 1826096014
  },
  {
    "city": "Porth",
    "city_ascii": "Porth",
    "lat": 51.6138,
    "lng": -3.4095,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Rhondda Cynon Taff",
    "capital": "",
    "population": 5970,
    "id": 1826101777
  },
  {
    "city": "Tettenhall",
    "city_ascii": "Tettenhall",
    "lat": 52.5983,
    "lng": -2.168,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Wolverhampton",
    "capital": "",
    "population": 5225,
    "id": 1826684783
  },
  {
    "city": "North Kensington",
    "city_ascii": "North Kensington",
    "lat": 39.0392,
    "lng": -77.0723,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 9347,
    "id": 1840031382
  },
  {
    "city": "Harlescott",
    "city_ascii": "Harlescott",
    "lat": 52.7365,
    "lng": -2.7226,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Shropshire",
    "capital": "",
    "population": 5060,
    "id": 1826238045
  },
  {
    "city": "Woodlyn",
    "city_ascii": "Woodlyn",
    "lat": 39.8774,
    "lng": -75.3445,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 9326,
    "id": 1840035266
  },
  {
    "city": "Leisure World",
    "city_ascii": "Leisure World",
    "lat": 39.1023,
    "lng": -77.0691,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 9321,
    "id": 1840073671
  },
  {
    "city": "Rîşcani",
    "city_ascii": "Riscani",
    "lat": 47.9561,
    "lng": 27.5536,
    "country": "Moldova",
    "iso2": "MD",
    "iso3": "MDA",
    "admin_name": "Rîşcani",
    "capital": "admin",
    "population": 9259,
    "id": 1498650340
  },
  {
    "city": "Long Ditton",
    "city_ascii": "Long Ditton",
    "lat": 51.385,
    "lng": -0.321,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Surrey",
    "capital": "",
    "population": 6343,
    "id": 1826840315
  },
  {
    "city": "Sawston",
    "city_ascii": "Sawston",
    "lat": 52.1247,
    "lng": 0.1731,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Cambridgeshire",
    "capital": "",
    "population": 7145,
    "id": 1826326745
  },
  {
    "city": "Dobele",
    "city_ascii": "Dobele",
    "lat": 56.6258,
    "lng": 23.2811,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Dobeles Novads",
    "capital": "admin",
    "population": 9272,
    "id": 1428641580
  },
  {
    "city": "Wood-Ridge",
    "city_ascii": "Wood-Ridge",
    "lat": 40.8507,
    "lng": -74.0878,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 9294,
    "id": 1840000925
  },
  {
    "city": "Talsi",
    "city_ascii": "Talsi",
    "lat": 57.2447,
    "lng": 22.5889,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Talsu Novads",
    "capital": "admin",
    "population": 9264,
    "id": 1428411666
  },
  {
    "city": "Ashbourne",
    "city_ascii": "Ashbourne",
    "lat": 53.016,
    "lng": -1.731,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Derbyshire",
    "capital": "",
    "population": 7112,
    "id": 1826277851
  },
  {
    "city": "Las Tablas",
    "city_ascii": "Las Tablas",
    "lat": 7.7667,
    "lng": -80.2833,
    "country": "Panama",
    "iso2": "PA",
    "iso3": "PAN",
    "admin_name": "Los Santos",
    "capital": "admin",
    "population": 9255,
    "id": 1591472114
  },
  {
    "city": "Brentwood",
    "city_ascii": "Brentwood",
    "lat": 40.3734,
    "lng": -79.9757,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 9268,
    "id": 1840001212
  },
  {
    "city": "Kasane",
    "city_ascii": "Kasane",
    "lat": -17.7983,
    "lng": 25.1536,
    "country": "Botswana",
    "iso2": "BW",
    "iso3": "BWA",
    "admin_name": "Chobe",
    "capital": "admin",
    "population": 9250,
    "id": 1072217316
  },
  {
    "city": "Katakwi",
    "city_ascii": "Katakwi",
    "lat": 1.8911,
    "lng": 33.9661,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Katakwi",
    "capital": "admin",
    "population": 8400,
    "id": 1800303827
  },
  {
    "city": "Alcester",
    "city_ascii": "Alcester",
    "lat": 52.215,
    "lng": -1.876,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Warwickshire",
    "capital": "",
    "population": 6273,
    "id": 1826906606
  },
  {
    "city": "Plainedge",
    "city_ascii": "Plainedge",
    "lat": 40.724,
    "lng": -73.477,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 9206,
    "id": 1840005249
  },
  {
    "city": "Anykščiai",
    "city_ascii": "Anyksciai",
    "lat": 55.5344,
    "lng": 25.1072,
    "country": "Lithuania",
    "iso2": "LT",
    "iso3": "LTU",
    "admin_name": "Anykščiai",
    "capital": "admin",
    "population": 9190,
    "id": 1440174702
  },
  {
    "city": "Evinayong",
    "city_ascii": "Evinayong",
    "lat": 1.45,
    "lng": 10.5667,
    "country": "Equatorial Guinea",
    "iso2": "GQ",
    "iso3": "GNQ",
    "admin_name": "Centro Sur",
    "capital": "admin",
    "population": 9155,
    "id": 1226336138
  },
  {
    "city": "Žabalj",
    "city_ascii": "Zabalj",
    "lat": 45.3667,
    "lng": 20.0667,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Žabalj",
    "capital": "admin",
    "population": 9161,
    "id": 1688662344
  },
  {
    "city": "Boulder Hill",
    "city_ascii": "Boulder Hill",
    "lat": 41.7112,
    "lng": -88.3353,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 9179,
    "id": 1840004821
  },
  {
    "city": "South Amboy",
    "city_ascii": "South Amboy",
    "lat": 40.4852,
    "lng": -74.2831,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 9176,
    "id": 1840001333
  },
  {
    "city": "Dursley",
    "city_ascii": "Dursley",
    "lat": 51.6811,
    "lng": -2.3543,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Gloucestershire",
    "capital": "",
    "population": 6697,
    "id": 1826743234
  },
  {
    "city": "Stepps",
    "city_ascii": "Stepps",
    "lat": 55.8908,
    "lng": -4.1522,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "North Lanarkshire",
    "capital": "",
    "population": 6730,
    "id": 1826560402
  },
  {
    "city": "Lerwick",
    "city_ascii": "Lerwick",
    "lat": 60.155,
    "lng": -1.145,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Shetland Islands",
    "capital": "",
    "population": 6958,
    "id": 1826896340
  },
  {
    "city": "Sauce",
    "city_ascii": "Sauce",
    "lat": -34.6469,
    "lng": -56.0628,
    "country": "Uruguay",
    "iso2": "UY",
    "iso3": "URY",
    "admin_name": "Canelones",
    "capital": "",
    "population": 6132,
    "id": 1858554370
  },
  {
    "city": "Stans",
    "city_ascii": "Stans",
    "lat": 46.9594,
    "lng": 8.3667,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Nidwalden",
    "capital": "admin",
    "population": 8393,
    "id": 1756019042
  },
  {
    "city": "Saltburn-by-the-Sea",
    "city_ascii": "Saltburn-by-the-Sea",
    "lat": 54.5828,
    "lng": -0.9732,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Redcar and Cleveland",
    "capital": "",
    "population": 5958,
    "id": 1826299389
  },
  {
    "city": "Billinge",
    "city_ascii": "Billinge",
    "lat": 53.4932,
    "lng": -2.7129,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "St. Helens",
    "capital": "",
    "population": 6554,
    "id": 1826747483
  },
  {
    "city": "Dangriga",
    "city_ascii": "Dangriga",
    "lat": 16.9667,
    "lng": -88.2167,
    "country": "Belize",
    "iso2": "BZ",
    "iso3": "BLZ",
    "admin_name": "Stann Creek",
    "capital": "admin",
    "population": 9096,
    "id": 1084718311
  },
  {
    "city": "Derby",
    "city_ascii": "Derby",
    "lat": 39.8401,
    "lng": -104.9171,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 9084,
    "id": 1840028576
  },
  {
    "city": "Broughton",
    "city_ascii": "Broughton",
    "lat": 53.5638,
    "lng": -0.5465,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "North Lincolnshire",
    "capital": "",
    "population": 5726,
    "id": 1826619680
  },
  {
    "city": "Inírida",
    "city_ascii": "Inirida",
    "lat": 3.854,
    "lng": -67.9061,
    "country": "Colombia",
    "iso2": "CO",
    "iso3": "COL",
    "admin_name": "Guainía",
    "capital": "admin",
    "population": 9065,
    "id": 1170563591
  },
  {
    "city": "Ewo",
    "city_ascii": "Ewo",
    "lat": -0.8667,
    "lng": 14.8167,
    "country": "Congo (Brazzaville)",
    "iso2": "CG",
    "iso3": "COG",
    "admin_name": "Cuvette-Ouest",
    "capital": "admin",
    "population": 9062,
    "id": 1178339407
  },
  {
    "city": "Filey",
    "city_ascii": "Filey",
    "lat": 54.2101,
    "lng": -0.2893,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "North Yorkshire",
    "capital": "",
    "population": 6981,
    "id": 1826806664
  },
  {
    "city": "Manatuto",
    "city_ascii": "Manatuto",
    "lat": -8.5167,
    "lng": 126.0167,
    "country": "Timor-Leste",
    "iso2": "TL",
    "iso3": "TLS",
    "admin_name": "Manatuto",
    "capital": "admin",
    "population": 9022,
    "id": 1626115337
  },
  {
    "city": "Odžaci",
    "city_ascii": "Odzaci",
    "lat": 45.5167,
    "lng": 19.2667,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Odžaci",
    "capital": "admin",
    "population": 9021,
    "id": 1688373834
  },
  {
    "city": "Cove",
    "city_ascii": "Cove",
    "lat": 51.2965,
    "lng": -0.7939,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hampshire",
    "capital": "",
    "population": 6548,
    "id": 1826177889
  },
  {
    "city": "Leonia",
    "city_ascii": "Leonia",
    "lat": 40.8638,
    "lng": -73.9899,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 9035,
    "id": 1840000904
  },
  {
    "city": "Gizo",
    "city_ascii": "Gizo",
    "lat": -8.1,
    "lng": 156.85,
    "country": "Solomon Islands",
    "iso2": "SB",
    "iso3": "SLB",
    "admin_name": "Western",
    "capital": "admin",
    "population": 6154,
    "id": 1090427067
  },
  {
    "city": "Stansted Mountfitchet",
    "city_ascii": "Stansted Mountfitchet",
    "lat": 51.898,
    "lng": 0.198,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Essex",
    "capital": "",
    "population": 6011,
    "id": 1826626668
  },
  {
    "city": "Farmingdale",
    "city_ascii": "Farmingdale",
    "lat": 40.7328,
    "lng": -73.4465,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 9002,
    "id": 1840005285
  },
  {
    "city": "Wentworthville",
    "city_ascii": "Wentworthville",
    "lat": -33.8233,
    "lng": 150.9678,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 6197,
    "id": 1036489931
  },
  {
    "city": "Stony Brook University",
    "city_ascii": "Stony Brook University",
    "lat": 40.9099,
    "lng": -73.1213,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 8938,
    "id": 1840042758
  },
  {
    "city": "Lexden",
    "city_ascii": "Lexden",
    "lat": 51.8833,
    "lng": 0.8667,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Essex",
    "capital": "",
    "population": 5549,
    "id": 1826001135
  },
  {
    "city": "Sremski Karlovci",
    "city_ascii": "Sremski Karlovci",
    "lat": 45.2,
    "lng": 19.9333,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Sremski Karlovci",
    "capital": "admin",
    "population": 8750,
    "id": 1688215764
  },
  {
    "city": "Temozon",
    "city_ascii": "Temozon",
    "lat": 20.8042,
    "lng": -88.2028,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Yucatán",
    "capital": "",
    "population": 6553,
    "id": 1484009522
  },
  {
    "city": "Gleno",
    "city_ascii": "Gleno",
    "lat": -8.7239,
    "lng": 125.4361,
    "country": "Timor-Leste",
    "iso2": "TL",
    "iso3": "TLS",
    "admin_name": "Ermera",
    "capital": "admin",
    "population": 8133,
    "id": 1626143485
  },
  {
    "city": "Budleigh Salterton",
    "city_ascii": "Budleigh Salterton",
    "lat": 50.6284,
    "lng": -3.3205,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Devon",
    "capital": "",
    "population": 6575,
    "id": 1826056520
  },
  {
    "city": "Long Ashton",
    "city_ascii": "Long Ashton",
    "lat": 51.43,
    "lng": -2.65,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "North Somerset",
    "capital": "",
    "population": 6044,
    "id": 1826120671
  },
  {
    "city": "Mokhotlong",
    "city_ascii": "Mokhotlong",
    "lat": -29.2885,
    "lng": 29.0656,
    "country": "Lesotho",
    "iso2": "LS",
    "iso3": "LSO",
    "admin_name": "Mokhotlong",
    "capital": "admin",
    "population": 8809,
    "id": 1426785819
  },
  {
    "city": "Cheddar",
    "city_ascii": "Cheddar",
    "lat": 51.279,
    "lng": -2.778,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Somerset",
    "capital": "",
    "population": 5755,
    "id": 1826363162
  },
  {
    "city": "Pontardawe",
    "city_ascii": "Pontardawe",
    "lat": 51.722,
    "lng": -3.851,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Neath Port Talbot",
    "capital": "",
    "population": 6832,
    "id": 1826219127
  },
  {
    "city": "Corozal",
    "city_ascii": "Corozal",
    "lat": 18.4,
    "lng": -88.4,
    "country": "Belize",
    "iso2": "BZ",
    "iso3": "BLZ",
    "admin_name": "Corozal",
    "capital": "admin",
    "population": 8800,
    "id": 1084938400
  },
  {
    "city": "Gavarr",
    "city_ascii": "Gavarr",
    "lat": 40.3667,
    "lng": 45.1333,
    "country": "Armenia",
    "iso2": "AM",
    "iso3": "ARM",
    "admin_name": "Geghark’unik’",
    "capital": "admin",
    "population": 8751,
    "id": 1051983153
  },
  {
    "city": "Dore",
    "city_ascii": "Dore",
    "lat": 53.3268,
    "lng": -1.5345,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Sheffield",
    "capital": "",
    "population": 5496,
    "id": 1826120781
  },
  {
    "city": "North Petherton",
    "city_ascii": "North Petherton",
    "lat": 51.091,
    "lng": -3.013,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Somerset",
    "capital": "",
    "population": 6730,
    "id": 1826303123
  },
  {
    "city": "Egham",
    "city_ascii": "Egham",
    "lat": 51.429,
    "lng": -0.548,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Surrey",
    "capital": "",
    "population": 6384,
    "id": 1826214236
  },
  {
    "city": "Collingdale",
    "city_ascii": "Collingdale",
    "lat": 39.9151,
    "lng": -75.2776,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 8794,
    "id": 1840000693
  },
  {
    "city": "Pontardulais",
    "city_ascii": "Pontardulais",
    "lat": 51.71,
    "lng": -4.04,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Swansea",
    "capital": "",
    "population": 6281,
    "id": 1826755520
  },
  {
    "city": "Saynshand",
    "city_ascii": "Saynshand",
    "lat": 44.8917,
    "lng": 110.1367,
    "country": "Mongolia",
    "iso2": "MN",
    "iso3": "MNG",
    "admin_name": "Dornogovĭ",
    "capital": "admin",
    "population": 8776,
    "id": 1496862229
  },
  {
    "city": "Joniškis",
    "city_ascii": "Joniskis",
    "lat": 56.2433,
    "lng": 23.6179,
    "country": "Lithuania",
    "iso2": "LT",
    "iso3": "LTU",
    "admin_name": "Joniškis",
    "capital": "admin",
    "population": 8757,
    "id": 1440592411
  },
  {
    "city": "Eichwalde",
    "city_ascii": "Eichwalde",
    "lat": 52.3667,
    "lng": 13.6167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Brandenburg",
    "capital": "",
    "population": 6468,
    "id": 1276665888
  },
  {
    "city": "Frimley",
    "city_ascii": "Frimley",
    "lat": 51.3143,
    "lng": -0.7387,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Surrey",
    "capital": "",
    "population": 6178,
    "id": 1826062895
  },
  {
    "city": "August",
    "city_ascii": "August",
    "lat": 37.9797,
    "lng": -121.2625,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 8774,
    "id": 1840028357
  },
  {
    "city": "Leona Vicario",
    "city_ascii": "Leona Vicario",
    "lat": 20.9922,
    "lng": -87.2028,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Quintana Roo",
    "capital": "",
    "population": 6517,
    "id": 1484013873
  },
  {
    "city": "West Miami",
    "city_ascii": "West Miami",
    "lat": 25.7578,
    "lng": -80.2969,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 8767,
    "id": 1840016009
  },
  {
    "city": "New Square",
    "city_ascii": "New Square",
    "lat": 41.141,
    "lng": -74.0294,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 8763,
    "id": 1840004980
  },
  {
    "city": "Glodeni",
    "city_ascii": "Glodeni",
    "lat": 47.7708,
    "lng": 27.5144,
    "country": "Moldova",
    "iso2": "MD",
    "iso3": "MDA",
    "admin_name": "Glodeni",
    "capital": "admin",
    "population": 8676,
    "id": 1498553213
  },
  {
    "city": "Folsom",
    "city_ascii": "Folsom",
    "lat": 39.8924,
    "lng": -75.3287,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 8707,
    "id": 1840034970
  },
  {
    "city": "Bécal",
    "city_ascii": "Becal",
    "lat": 20.4414,
    "lng": -90.0275,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Campeche",
    "capital": "",
    "population": 6511,
    "id": 1484400934
  },
  {
    "city": "Great Wakering",
    "city_ascii": "Great Wakering",
    "lat": 51.5516,
    "lng": 0.8165,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Southend-on-Sea",
    "capital": "",
    "population": 5587,
    "id": 1826267348
  },
  {
    "city": "Paide",
    "city_ascii": "Paide",
    "lat": 58.8833,
    "lng": 25.5667,
    "country": "Estonia",
    "iso2": "EE",
    "iso3": "EST",
    "admin_name": "Järvamaa",
    "capital": "admin",
    "population": 7905,
    "id": 1233393684
  },
  {
    "city": "Santo Domingo Este",
    "city_ascii": "Santo Domingo Este",
    "lat": 18.4885,
    "lng": -69.8571,
    "country": "Dominican Republic",
    "iso2": "DO",
    "iso3": "DOM",
    "admin_name": "Ozama",
    "capital": "admin",
    "population": null,
    "id": 1214664832
  },
  {
    "city": "Griffithstown",
    "city_ascii": "Griffithstown",
    "lat": 51.6853,
    "lng": -3.0284,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Torfaen",
    "capital": "",
    "population": 5450,
    "id": 1826252067
  },
  {
    "city": "Aleg",
    "city_ascii": "Aleg",
    "lat": 17.058,
    "lng": -13.909,
    "country": "Mauritania",
    "iso2": "MR",
    "iso3": "MRT",
    "admin_name": "Brakna",
    "capital": "admin",
    "population": 8388,
    "id": 1478173139
  },
  {
    "city": "Littlemore",
    "city_ascii": "Littlemore",
    "lat": 51.72,
    "lng": -1.227,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Oxfordshire",
    "capital": "",
    "population": 5646,
    "id": 1826684335
  },
  {
    "city": "Swissvale",
    "city_ascii": "Swissvale",
    "lat": 40.4207,
    "lng": -79.8858,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 8647,
    "id": 1840001264
  },
  {
    "city": "South San Gabriel",
    "city_ascii": "South San Gabriel",
    "lat": 34.0489,
    "lng": -118.0961,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 8643,
    "id": 1840019207
  },
  {
    "city": "Prienai",
    "city_ascii": "Prienai",
    "lat": 54.6333,
    "lng": 23.9417,
    "country": "Lithuania",
    "iso2": "LT",
    "iso3": "LTU",
    "admin_name": "Prienai",
    "capital": "admin",
    "population": 8610,
    "id": 1440412268
  },
  {
    "city": "Audubon",
    "city_ascii": "Audubon",
    "lat": 39.8906,
    "lng": -75.0722,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 8637,
    "id": 1840003775
  },
  {
    "city": "Heage",
    "city_ascii": "Heage",
    "lat": 53.05,
    "lng": -1.447,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Derbyshire",
    "capital": "",
    "population": 5013,
    "id": 1826178379
  },
  {
    "city": "Handforth",
    "city_ascii": "Handforth",
    "lat": 53.35,
    "lng": -2.216,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Cheshire East",
    "capital": "",
    "population": 6266,
    "id": 1826308689
  },
  {
    "city": "Galleywood",
    "city_ascii": "Galleywood",
    "lat": 51.697,
    "lng": 0.474,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Essex",
    "capital": "",
    "population": 5738,
    "id": 1826387868
  },
  {
    "city": "Seven Corners",
    "city_ascii": "Seven Corners",
    "lat": 38.8658,
    "lng": -77.1445,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 8576,
    "id": 1840006038
  },
  {
    "city": "Greenbriar",
    "city_ascii": "Greenbriar",
    "lat": 38.8717,
    "lng": -77.397,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 8575,
    "id": 1840024572
  },
  {
    "city": "West Athens",
    "city_ascii": "West Athens",
    "lat": 33.9235,
    "lng": -118.3033,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 8574,
    "id": 1840028403
  },
  {
    "city": "Four Corners",
    "city_ascii": "Four Corners",
    "lat": 39.0235,
    "lng": -77.0102,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 8566,
    "id": 1840024489
  },
  {
    "city": "Harleston",
    "city_ascii": "Harleston",
    "lat": 52.4024,
    "lng": 1.2996,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Norfolk",
    "capital": "",
    "population": 5067,
    "id": 1826624574
  },
  {
    "city": "San Antonino Castillo Velasco",
    "city_ascii": "San Antonino Castillo Velasco",
    "lat": 16.8031,
    "lng": -96.6839,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Oaxaca",
    "capital": "minor",
    "population": 6009,
    "id": 1484401368
  },
  {
    "city": "Basarabeasca",
    "city_ascii": "Basarabeasca",
    "lat": 46.3333,
    "lng": 28.9667,
    "country": "Moldova",
    "iso2": "MD",
    "iso3": "MDA",
    "admin_name": "Basarabeasca",
    "capital": "admin",
    "population": 8471,
    "id": 1498894118
  },
  {
    "city": "Teaoraereke",
    "city_ascii": "Teaoraereke",
    "lat": 1.3333,
    "lng": 173,
    "country": "Kiribati",
    "iso2": "KI",
    "iso3": "KIR",
    "admin_name": "",
    "capital": "",
    "population": 5105,
    "id": 1296060597
  },
  {
    "city": "Zərdab",
    "city_ascii": "Zardab",
    "lat": 40.2111,
    "lng": 47.7108,
    "country": "Azerbaijan",
    "iso2": "AZ",
    "iso3": "AZE",
    "admin_name": "Zərdab",
    "capital": "admin",
    "population": 8450,
    "id": 1031852579
  },
  {
    "city": "Tadcaster",
    "city_ascii": "Tadcaster",
    "lat": 53.8852,
    "lng": -1.262,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "North Yorkshire",
    "capital": "",
    "population": 6003,
    "id": 1826540974
  },
  {
    "city": "Great Meols",
    "city_ascii": "Great Meols",
    "lat": 53.4014,
    "lng": -3.1553,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Wirral",
    "capital": "",
    "population": 5110,
    "id": 1826236387
  },
  {
    "city": "Greymouth",
    "city_ascii": "Greymouth",
    "lat": -42.4667,
    "lng": 171.2,
    "country": "New Zealand",
    "iso2": "NZ",
    "iso3": "NZL",
    "admin_name": "West Coast",
    "capital": "admin",
    "population": 8160,
    "id": 1554984303
  },
  {
    "city": "Varėna",
    "city_ascii": "Varena",
    "lat": 54.2111,
    "lng": 24.5722,
    "country": "Lithuania",
    "iso2": "LT",
    "iso3": "LTU",
    "admin_name": "Varėna",
    "capital": "admin",
    "population": 8477,
    "id": 1440933251
  },
  {
    "city": "Malverne",
    "city_ascii": "Malverne",
    "lat": 40.6746,
    "lng": -73.6721,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 8485,
    "id": 1840005305
  },
  {
    "city": "Amble",
    "city_ascii": "Amble",
    "lat": 55.3306,
    "lng": -1.5783,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Northumberland",
    "capital": "",
    "population": 6025,
    "id": 1826166469
  },
  {
    "city": "Wotton-under-Edge",
    "city_ascii": "Wotton-under-Edge",
    "lat": 51.638,
    "lng": -2.349,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Gloucestershire",
    "capital": "",
    "population": 5627,
    "id": 1826917340
  },
  {
    "city": "Paulton",
    "city_ascii": "Paulton",
    "lat": 51.3069,
    "lng": -2.5027,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Bath and North East Somerset",
    "capital": "",
    "population": 5303,
    "id": 1826351087
  },
  {
    "city": "Stotfold",
    "city_ascii": "Stotfold",
    "lat": 52.018,
    "lng": -0.228,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hertfordshire",
    "capital": "",
    "population": 6190,
    "id": 1826846311
  },
  {
    "city": "Midway City",
    "city_ascii": "Midway City",
    "lat": 33.7451,
    "lng": -117.9849,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 8374,
    "id": 1840025037
  },
  {
    "city": "Brokopondo",
    "city_ascii": "Brokopondo",
    "lat": 5.0404,
    "lng": -55.02,
    "country": "Suriname",
    "iso2": "SR",
    "iso3": "SUR",
    "admin_name": "Brokopondo",
    "capital": "admin",
    "population": 8340,
    "id": 1740558611
  },
  {
    "city": "Aibonito",
    "city_ascii": "Aibonito",
    "lat": 18.1398,
    "lng": -66.2659,
    "country": "Puerto Rico",
    "iso2": "PR",
    "iso3": "PRI",
    "admin_name": "Puerto Rico",
    "capital": "",
    "population": 8352,
    "id": 1630035609
  },
  {
    "city": "Kalibo",
    "city_ascii": "Kalibo",
    "lat": 11.7086,
    "lng": 122.3648,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Aklan",
    "capital": "admin",
    "population": null,
    "id": 1608268539
  },
  {
    "city": "Hoylake",
    "city_ascii": "Hoylake",
    "lat": 53.39,
    "lng": -3.18,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Wirral",
    "capital": "",
    "population": 5710,
    "id": 1826110529
  },
  {
    "city": "Bogota",
    "city_ascii": "Bogota",
    "lat": 40.875,
    "lng": -74.0293,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 8335,
    "id": 1840000894
  },
  {
    "city": "Harwood Heights",
    "city_ascii": "Harwood Heights",
    "lat": 41.9663,
    "lng": -87.8057,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 8333,
    "id": 1840011252
  },
  {
    "city": "Grandview Heights",
    "city_ascii": "Grandview Heights",
    "lat": 39.9793,
    "lng": -83.0408,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 8333,
    "id": 1840003761
  },
  {
    "city": "Puerto San Carlos",
    "city_ascii": "Puerto San Carlos",
    "lat": 24.7894,
    "lng": -112.1083,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Baja California Sur",
    "capital": "",
    "population": 5538,
    "id": 1484585914
  },
  {
    "city": "Sorø",
    "city_ascii": "Soro",
    "lat": 55.433,
    "lng": 11.5667,
    "country": "Denmark",
    "iso2": "DK",
    "iso3": "DNK",
    "admin_name": "Sjælland",
    "capital": "admin",
    "population": 7167,
    "id": 1208841384
  },
  {
    "city": "Haledon",
    "city_ascii": "Haledon",
    "lat": 40.9363,
    "lng": -74.1887,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 8293,
    "id": 1840003512
  },
  {
    "city": "Dormont",
    "city_ascii": "Dormont",
    "lat": 40.3941,
    "lng": -80.0377,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 8282,
    "id": 1840001222
  },
  {
    "city": "Arlesey",
    "city_ascii": "Arlesey",
    "lat": 52.007,
    "lng": -0.2654,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Central Bedfordshire",
    "capital": "",
    "population": 5584,
    "id": 1826582609
  },
  {
    "city": "Wincanton",
    "city_ascii": "Wincanton",
    "lat": 51.0559,
    "lng": -2.4102,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Somerset",
    "capital": "",
    "population": 5272,
    "id": 1826436601
  },
  {
    "city": "Tayoltita",
    "city_ascii": "Tayoltita",
    "lat": 24.0833,
    "lng": -105.9333,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Durango",
    "capital": "minor",
    "population": 5124,
    "id": 1484925741
  },
  {
    "city": "Tetbury",
    "city_ascii": "Tetbury",
    "lat": 51.636,
    "lng": -2.158,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Gloucestershire",
    "capital": "",
    "population": 5472,
    "id": 1826531050
  },
  {
    "city": "Balakən",
    "city_ascii": "Balakan",
    "lat": 41.7233,
    "lng": 46.3919,
    "country": "Azerbaijan",
    "iso2": "AZ",
    "iso3": "AZE",
    "admin_name": "Balakən",
    "capital": "admin",
    "population": 8134,
    "id": 1031370082
  },
  {
    "city": "Teabo",
    "city_ascii": "Teabo",
    "lat": 20.3997,
    "lng": -89.2844,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Yucatán",
    "capital": "",
    "population": 6115,
    "id": 1484546249
  },
  {
    "city": "Kelmė",
    "city_ascii": "Kelme",
    "lat": 55.6333,
    "lng": 22.9333,
    "country": "Lithuania",
    "iso2": "LT",
    "iso3": "LTU",
    "admin_name": "Kelmė",
    "capital": "admin",
    "population": 8206,
    "id": 1440476751
  },
  {
    "city": "Yeghegnadzor",
    "city_ascii": "Yeghegnadzor",
    "lat": 39.7667,
    "lng": 45.35,
    "country": "Armenia",
    "iso2": "AM",
    "iso3": "ARM",
    "admin_name": "Vayots’ Dzor",
    "capital": "admin",
    "population": 8200,
    "id": 1051234787
  },
  {
    "city": "Ormesby",
    "city_ascii": "Ormesby",
    "lat": 54.5492,
    "lng": -1.1817,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Middlesbrough",
    "capital": "",
    "population": 5942,
    "id": 1826125130
  },
  {
    "city": "Hengoed",
    "city_ascii": "Hengoed",
    "lat": 51.65,
    "lng": -3.23,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Caerphilly",
    "capital": "",
    "population": 5548,
    "id": 1826000038
  },
  {
    "city": "Ndalatando",
    "city_ascii": "Ndalatando",
    "lat": -9.3,
    "lng": 14.9167,
    "country": "Angola",
    "iso2": "AO",
    "iso3": "AGO",
    "admin_name": "Kwanza Norte",
    "capital": "admin",
    "population": 8144,
    "id": 1024645515
  },
  {
    "city": "Cheviot",
    "city_ascii": "Cheviot",
    "lat": 39.1577,
    "lng": -84.6139,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 8209,
    "id": 1840003813
  },
  {
    "city": "Brynmawr",
    "city_ascii": "Brynmawr",
    "lat": 51.796,
    "lng": -3.183,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Blaenau Gwent",
    "capital": "",
    "population": 5530,
    "id": 1826577496
  },
  {
    "city": "Magor",
    "city_ascii": "Magor",
    "lat": 51.5798,
    "lng": -2.8312,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Monmouthshire",
    "capital": "",
    "population": 6140,
    "id": 1826057542
  },
  {
    "city": "Beočin",
    "city_ascii": "Beocin",
    "lat": 45.1922,
    "lng": 19.7203,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Beočin",
    "capital": "admin",
    "population": 8058,
    "id": 1688152997
  },
  {
    "city": "Qobustan",
    "city_ascii": "Qobustan",
    "lat": 40.5333,
    "lng": 48.9333,
    "country": "Azerbaijan",
    "iso2": "AZ",
    "iso3": "AZE",
    "admin_name": "Qobustan",
    "capital": "admin",
    "population": 8100,
    "id": 1031385198
  },
  {
    "city": "Soroti",
    "city_ascii": "Soroti",
    "lat": 1.6833,
    "lng": 33.6167,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Soroti",
    "capital": "admin",
    "population": 1038,
    "id": 1800434608
  },
  {
    "city": "São Filipe",
    "city_ascii": "Sao Filipe",
    "lat": 14.8966,
    "lng": -24.4955,
    "country": "Cabo Verde",
    "iso2": "CV",
    "iso3": "CPV",
    "admin_name": "São Filipe",
    "capital": "admin",
    "population": 8122,
    "id": 1132693257
  },
  {
    "city": "Piste",
    "city_ascii": "Piste",
    "lat": 20.6981,
    "lng": -88.5886,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Yucatán",
    "capital": "",
    "population": 5528,
    "id": 1484346814
  },
  {
    "city": "Alondra Park",
    "city_ascii": "Alondra Park",
    "lat": 33.8885,
    "lng": -118.335,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 8097,
    "id": 1840028356
  },
  {
    "city": "Mount Rainier",
    "city_ascii": "Mount Rainier",
    "lat": 38.9423,
    "lng": -76.9645,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 8093,
    "id": 1840005977
  },
  {
    "city": "Maplewood",
    "city_ascii": "Maplewood",
    "lat": 38.6121,
    "lng": -90.324,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 8092,
    "id": 1840009740
  },
  {
    "city": "Algodones",
    "city_ascii": "Algodones",
    "lat": 32.7153,
    "lng": -114.7289,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Baja California",
    "capital": "",
    "population": 5474,
    "id": 1484797126
  },
  {
    "city": "Port Maria",
    "city_ascii": "Port Maria",
    "lat": 18.3703,
    "lng": -76.8903,
    "country": "Jamaica",
    "iso2": "JM",
    "iso3": "JAM",
    "admin_name": "Saint Mary",
    "capital": "admin",
    "population": 7906,
    "id": 1388155945
  },
  {
    "city": "Ludza",
    "city_ascii": "Ludza",
    "lat": 56.5439,
    "lng": 27.7211,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Ludzas Novads",
    "capital": "admin",
    "population": 8071,
    "id": 1428976928
  },
  {
    "city": "Guadalupe y Calvo",
    "city_ascii": "Guadalupe y Calvo",
    "lat": 26.095,
    "lng": -106.9644,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Chihuahua",
    "capital": "minor",
    "population": 5816,
    "id": 1484245253
  },
  {
    "city": "Garden City Park",
    "city_ascii": "Garden City Park",
    "lat": 40.7436,
    "lng": -73.6639,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 8081,
    "id": 1840005257
  },
  {
    "city": "Nangan",
    "city_ascii": "Nangan",
    "lat": 26.1598,
    "lng": 119.9432,
    "country": "Taiwan",
    "iso2": "TW",
    "iso3": "TWN",
    "admin_name": "Lienchiang",
    "capital": "admin",
    "population": 7382,
    "id": 1158327079
  },
  {
    "city": "Broadview Park",
    "city_ascii": "Broadview Park",
    "lat": 26.0979,
    "lng": -80.2088,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 8068,
    "id": 1840028918
  },
  {
    "city": "Malmesbury",
    "city_ascii": "Malmesbury",
    "lat": 51.584,
    "lng": -2.098,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Wiltshire",
    "capital": "",
    "population": 5380,
    "id": 1826511945
  },
  {
    "city": "North Bay Village",
    "city_ascii": "North Bay Village",
    "lat": 25.8487,
    "lng": -80.1535,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 8057,
    "id": 1840015153
  },
  {
    "city": "Conshohocken",
    "city_ascii": "Conshohocken",
    "lat": 40.0772,
    "lng": -75.3035,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 8047,
    "id": 1840003697
  },
  {
    "city": "Newent",
    "city_ascii": "Newent",
    "lat": 51.9302,
    "lng": -2.4048,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Gloucestershire",
    "capital": "",
    "population": 5207,
    "id": 1826755285
  },
  {
    "city": "Bellevue",
    "city_ascii": "Bellevue",
    "lat": 40.4945,
    "lng": -80.055,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 8036,
    "id": 1840001203
  },
  {
    "city": "Calderitas",
    "city_ascii": "Calderitas",
    "lat": 18.5544,
    "lng": -88.2583,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Quintana Roo",
    "capital": "",
    "population": 5326,
    "id": 1484192062
  },
  {
    "city": "Panindícuaro",
    "city_ascii": "Panindicuaro",
    "lat": 19.9828,
    "lng": -101.7606,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Michoacán de Ocampo",
    "capital": "minor",
    "population": 5565,
    "id": 1484235185
  },
  {
    "city": "Mtskheta",
    "city_ascii": "Mtskheta",
    "lat": 41.85,
    "lng": 44.7167,
    "country": "Georgia",
    "iso2": "GE",
    "iso3": "GEO",
    "admin_name": "Mtskheta Mtianeti",
    "capital": "admin",
    "population": 7940,
    "id": 1268173120
  },
  {
    "city": "Sokobanja",
    "city_ascii": "Sokobanja",
    "lat": 43.6394,
    "lng": 21.8694,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Sokobanja",
    "capital": "admin",
    "population": 7982,
    "id": 1688282797
  },
  {
    "city": "Krāslava",
    "city_ascii": "Kraslava",
    "lat": 55.8956,
    "lng": 27.1644,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Krāslavas Novads",
    "capital": "admin",
    "population": 7978,
    "id": 1428739484
  },
  {
    "city": "Panabá",
    "city_ascii": "Panaba",
    "lat": 21.2964,
    "lng": -88.2706,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Yucatán",
    "capital": "minor",
    "population": 5232,
    "id": 1484205103
  },
  {
    "city": "Juncos",
    "city_ascii": "Juncos",
    "lat": 18.2264,
    "lng": -65.9163,
    "country": "Puerto Rico",
    "iso2": "PR",
    "iso3": "PRI",
    "admin_name": "Puerto Rico",
    "capital": "",
    "population": 7970,
    "id": 1630023721
  },
  {
    "city": "Illizi",
    "city_ascii": "Illizi",
    "lat": 26.4834,
    "lng": 8.4666,
    "country": "Algeria",
    "iso2": "DZ",
    "iso3": "DZA",
    "admin_name": "Illizi",
    "capital": "admin",
    "population": 7957,
    "id": 1012499058
  },
  {
    "city": "Elderslie",
    "city_ascii": "Elderslie",
    "lat": 55.8306,
    "lng": -4.4842,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Renfrewshire",
    "capital": "",
    "population": 5634,
    "id": 1826117345
  },
  {
    "city": "Caldwell",
    "city_ascii": "Caldwell",
    "lat": 40.839,
    "lng": -74.2776,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 7941,
    "id": 1840005354
  },
  {
    "city": "Fgura",
    "city_ascii": "Fgura",
    "lat": 35.8703,
    "lng": 14.5133,
    "country": "Malta",
    "iso2": "MT",
    "iso3": "MLT",
    "admin_name": "Fgura",
    "capital": "admin",
    "population": null,
    "id": 1470252842
  },
  {
    "city": "San Juan Bautista",
    "city_ascii": "San Juan Bautista",
    "lat": -26.68,
    "lng": -57.15,
    "country": "Paraguay",
    "iso2": "PY",
    "iso3": "PRY",
    "admin_name": "Misiones",
    "capital": "admin",
    "population": 7882,
    "id": 1600185723
  },
  {
    "city": "Riverside",
    "city_ascii": "Riverside",
    "lat": 40.0358,
    "lng": -74.9564,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 7884,
    "id": 1840081629
  },
  {
    "city": "Falmouth",
    "city_ascii": "Falmouth",
    "lat": 18.4936,
    "lng": -77.6558,
    "country": "Jamaica",
    "iso2": "JM",
    "iso3": "JAM",
    "admin_name": "Trelawny",
    "capital": "admin",
    "population": 7779,
    "id": 1388625979
  },
  {
    "city": "Temple Hills",
    "city_ascii": "Temple Hills",
    "lat": 38.8106,
    "lng": -76.9495,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 7864,
    "id": 1840005967
  },
  {
    "city": "Boyes Hot Springs",
    "city_ascii": "Boyes Hot Springs",
    "lat": 38.3126,
    "lng": -122.4888,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 7863,
    "id": 1840017572
  },
  {
    "city": "Maḩmūd-e Rāqī",
    "city_ascii": "Mahmud-e Raqi",
    "lat": 35.0167,
    "lng": 69.3333,
    "country": "Afghanistan",
    "iso2": "AF",
    "iso3": "AFG",
    "admin_name": "Kāpīsā",
    "capital": "admin",
    "population": 7407,
    "id": 1004151943
  },
  {
    "city": "Ħamrun",
    "city_ascii": "Hamrun",
    "lat": 35.8847,
    "lng": 14.4844,
    "country": "Malta",
    "iso2": "MT",
    "iso3": "MLT",
    "admin_name": "Ħamrun",
    "capital": "admin",
    "population": null,
    "id": 1470141385
  },
  {
    "city": "Aizkraukle",
    "city_ascii": "Aizkraukle",
    "lat": 56.6008,
    "lng": 25.255,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Aizkraukles Novads",
    "capital": "admin",
    "population": 7745,
    "id": 1428407271
  },
  {
    "city": "Koboko",
    "city_ascii": "Koboko",
    "lat": 3.4136,
    "lng": 30.9599,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Koboko",
    "capital": "admin",
    "population": null,
    "id": 1800307801
  },
  {
    "city": "Luquillo",
    "city_ascii": "Luquillo",
    "lat": 18.3735,
    "lng": -65.7218,
    "country": "Puerto Rico",
    "iso2": "PR",
    "iso3": "PRI",
    "admin_name": "Puerto Rico",
    "capital": "",
    "population": 7841,
    "id": 1630023692
  },
  {
    "city": "Naftalan",
    "city_ascii": "Naftalan",
    "lat": 40.5058,
    "lng": 46.8192,
    "country": "Azerbaijan",
    "iso2": "AZ",
    "iso3": "AZE",
    "admin_name": "Naftalan",
    "capital": "admin",
    "population": 7700,
    "id": 1031441683
  },
  {
    "city": "Bentiu",
    "city_ascii": "Bentiu",
    "lat": 9.25,
    "lng": 29.8,
    "country": "South Sudan",
    "iso2": "SS",
    "iso3": "SSD",
    "admin_name": "Unity",
    "capital": "admin",
    "population": 7781,
    "id": 1728684421
  },
  {
    "city": "Wilson",
    "city_ascii": "Wilson",
    "lat": 40.6844,
    "lng": -75.2407,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 7807,
    "id": 1840000984
  },
  {
    "city": "Palo Alto",
    "city_ascii": "Palo Alto",
    "lat": 21.9167,
    "lng": -101.9667,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Aguascalientes",
    "capital": "minor",
    "population": 5399,
    "id": 1484615349
  },
  {
    "city": "Rio Bravo",
    "city_ascii": "Rio Bravo",
    "lat": 27.3648,
    "lng": -99.482,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 7797,
    "id": 1840021001
  },
  {
    "city": "Luba",
    "city_ascii": "Luba",
    "lat": 3.45,
    "lng": 8.55,
    "country": "Equatorial Guinea",
    "iso2": "GQ",
    "iso3": "GNQ",
    "admin_name": "Bioko Sur",
    "capital": "admin",
    "population": 7739,
    "id": 1226278217
  },
  {
    "city": "Sheriff Hill",
    "city_ascii": "Sheriff Hill",
    "lat": 54.939,
    "lng": -1.586,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Gateshead",
    "capital": "",
    "population": 5051,
    "id": 1826878815
  },
  {
    "city": "Guadalupe",
    "city_ascii": "Guadalupe",
    "lat": 34.9608,
    "lng": -120.5735,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 7783,
    "id": 1840020467
  },
  {
    "city": "Pinchbeck",
    "city_ascii": "Pinchbeck",
    "lat": 52.8147,
    "lng": -0.1605,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Lincolnshire",
    "capital": "",
    "population": 5153,
    "id": 1826280450
  },
  {
    "city": "Chickerell",
    "city_ascii": "Chickerell",
    "lat": 50.624,
    "lng": -2.5047,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Dorset",
    "capital": "",
    "population": 5515,
    "id": 1826484672
  },
  {
    "city": "Sabana Seca",
    "city_ascii": "Sabana Seca",
    "lat": 18.4273,
    "lng": -66.1809,
    "country": "Puerto Rico",
    "iso2": "PR",
    "iso3": "PRI",
    "admin_name": "Puerto Rico",
    "capital": "",
    "population": 7731,
    "id": 1630035569
  },
  {
    "city": "West Samoset",
    "city_ascii": "West Samoset",
    "lat": 27.4702,
    "lng": -82.5552,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 7708,
    "id": 1840029097
  },
  {
    "city": "Fanwood",
    "city_ascii": "Fanwood",
    "lat": 40.6417,
    "lng": -74.3857,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 7697,
    "id": 1840003614
  },
  {
    "city": "Baldwin Harbor",
    "city_ascii": "Baldwin Harbor",
    "lat": 40.6296,
    "lng": -73.6025,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 7690,
    "id": 1840005229
  },
  {
    "city": "Xinguara",
    "city_ascii": "Xinguara",
    "lat": -7.1006,
    "lng": -49.948,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Pará",
    "capital": "",
    "population": 4047,
    "id": 1076290442
  },
  {
    "city": "Holtville",
    "city_ascii": "Holtville",
    "lat": 32.813,
    "lng": -115.378,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 7664,
    "id": 1840020631
  },
  {
    "city": "Radstock",
    "city_ascii": "Radstock",
    "lat": 51.293,
    "lng": -2.448,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Bath and North East Somerset",
    "capital": "",
    "population": 5620,
    "id": 1826100157
  },
  {
    "city": "San Lorenzo",
    "city_ascii": "San Lorenzo",
    "lat": 18.1894,
    "lng": -65.9674,
    "country": "Puerto Rico",
    "iso2": "PR",
    "iso3": "PRI",
    "admin_name": "Puerto Rico",
    "capital": "",
    "population": 7629,
    "id": 1630035578
  },
  {
    "city": "Arafat",
    "city_ascii": "Arafat",
    "lat": 18.0583,
    "lng": -15.9621,
    "country": "Mauritania",
    "iso2": "MR",
    "iso3": "MRT",
    "admin_name": "Nouakchott Sud",
    "capital": "admin",
    "population": null,
    "id": 1478174698
  },
  {
    "city": "Angostura",
    "city_ascii": "Angostura",
    "lat": 25.3653,
    "lng": -108.1622,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Sinaloa",
    "capital": "minor",
    "population": 5086,
    "id": 1484677856
  },
  {
    "city": "Calumet Park",
    "city_ascii": "Calumet Park",
    "lat": 41.665,
    "lng": -87.6578,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 7602,
    "id": 1840010145
  },
  {
    "city": "Glen Ridge",
    "city_ascii": "Glen Ridge",
    "lat": 40.8041,
    "lng": -74.2043,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 7574,
    "id": 1840005356
  },
  {
    "city": "Waterbeach",
    "city_ascii": "Waterbeach",
    "lat": 52.2664,
    "lng": 0.1908,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Cambridgeshire",
    "capital": "",
    "population": 5166,
    "id": 1826313241
  },
  {
    "city": "Glenside",
    "city_ascii": "Glenside",
    "lat": 40.1032,
    "lng": -75.1517,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 7558,
    "id": 1840005462
  },
  {
    "city": "Sen Monorom",
    "city_ascii": "Sen Monorom",
    "lat": 12.45,
    "lng": 107.2,
    "country": "Cambodia",
    "iso2": "KH",
    "iso3": "KHM",
    "admin_name": "Mondolkiri",
    "capital": "admin",
    "population": 7500,
    "id": 1116978997
  },
  {
    "city": "Brotton",
    "city_ascii": "Brotton",
    "lat": 54.568,
    "lng": -0.937,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Redcar and Cleveland",
    "capital": "",
    "population": 5394,
    "id": 1826306929
  },
  {
    "city": "Nombre de Dios",
    "city_ascii": "Nombre de Dios",
    "lat": 23.85,
    "lng": -104.2333,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Durango",
    "capital": "minor",
    "population": 5302,
    "id": 1484278010
  },
  {
    "city": "Gulbene",
    "city_ascii": "Gulbene",
    "lat": 57.175,
    "lng": 26.7564,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Gulbenes Novads",
    "capital": "admin",
    "population": 7507,
    "id": 1428888445
  },
  {
    "city": "Gurabo",
    "city_ascii": "Gurabo",
    "lat": 18.2529,
    "lng": -65.9786,
    "country": "Puerto Rico",
    "iso2": "PR",
    "iso3": "PRI",
    "admin_name": "Puerto Rico",
    "capital": "",
    "population": 7515,
    "id": 1630035678
  },
  {
    "city": "Leova",
    "city_ascii": "Leova",
    "lat": 46.4786,
    "lng": 28.2553,
    "country": "Moldova",
    "iso2": "MD",
    "iso3": "MDA",
    "admin_name": "Leova",
    "capital": "admin",
    "population": 7443,
    "id": 1498085636
  },
  {
    "city": "Hatboro",
    "city_ascii": "Hatboro",
    "lat": 40.1775,
    "lng": -75.1054,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 7501,
    "id": 1840003700
  },
  {
    "city": "Tala",
    "city_ascii": "Tala",
    "lat": -34.3436,
    "lng": -55.7617,
    "country": "Uruguay",
    "iso2": "UY",
    "iso3": "URY",
    "admin_name": "Canelones",
    "capital": "",
    "population": 5089,
    "id": 1858233151
  },
  {
    "city": "Esperanza",
    "city_ascii": "Esperanza",
    "lat": 27.58,
    "lng": -109.9298,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Sonora",
    "capital": "",
    "population": 3836,
    "id": 1484729183
  },
  {
    "city": "San Jose",
    "city_ascii": "San Jose",
    "lat": 10.745,
    "lng": 121.9415,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Antique",
    "capital": "admin",
    "population": null,
    "id": 1608138574
  },
  {
    "city": "Ruše",
    "city_ascii": "Ruse",
    "lat": 46.5383,
    "lng": 15.5153,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Ruše",
    "capital": "admin",
    "population": 7351,
    "id": 1705417281
  },
  {
    "city": "Park City",
    "city_ascii": "Park City",
    "lat": 42.3522,
    "lng": -87.8915,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 7438,
    "id": 1840009133
  },
  {
    "city": "Oğuz",
    "city_ascii": "Oguz",
    "lat": 41.0714,
    "lng": 47.4653,
    "country": "Azerbaijan",
    "iso2": "AZ",
    "iso3": "AZE",
    "admin_name": "Oğuz",
    "capital": "admin",
    "population": 7400,
    "id": 1031370337
  },
  {
    "city": "Hillcrest",
    "city_ascii": "Hillcrest",
    "lat": 41.1298,
    "lng": -74.035,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 7418,
    "id": 1840004967
  },
  {
    "city": "Brayton",
    "city_ascii": "Brayton",
    "lat": 53.7682,
    "lng": -1.0907,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "North Yorkshire",
    "capital": "",
    "population": 5299,
    "id": 1826840707
  },
  {
    "city": "Briceni",
    "city_ascii": "Briceni",
    "lat": 48.3628,
    "lng": 27.085,
    "country": "Moldova",
    "iso2": "MD",
    "iso3": "MDA",
    "admin_name": "Briceni",
    "capital": "admin",
    "population": 7314,
    "id": 1498970287
  },
  {
    "city": "Mongomo",
    "city_ascii": "Mongomo",
    "lat": 1.6287,
    "lng": 11.3168,
    "country": "Equatorial Guinea",
    "iso2": "GQ",
    "iso3": "GNQ",
    "admin_name": "Wele-Nzas",
    "capital": "admin",
    "population": 7251,
    "id": 1226565454
  },
  {
    "city": "Goranboy",
    "city_ascii": "Goranboy",
    "lat": 40.61,
    "lng": 46.7872,
    "country": "Azerbaijan",
    "iso2": "AZ",
    "iso3": "AZE",
    "admin_name": "Goranboy",
    "capital": "admin",
    "population": 7333,
    "id": 1031383465
  },
  {
    "city": "Ruyigi",
    "city_ascii": "Ruyigi",
    "lat": -3.4667,
    "lng": 30.25,
    "country": "Burundi",
    "iso2": "BI",
    "iso3": "BDI",
    "admin_name": "Ruyigi",
    "capital": "admin",
    "population": 7139,
    "id": 1108406902
  },
  {
    "city": "Lerik",
    "city_ascii": "Lerik",
    "lat": 38.7753,
    "lng": 48.4153,
    "country": "Azerbaijan",
    "iso2": "AZ",
    "iso3": "AZE",
    "admin_name": "Lerik",
    "capital": "admin",
    "population": 7301,
    "id": 1031887331
  },
  {
    "city": "Montpelier",
    "city_ascii": "Montpelier",
    "lat": 44.2658,
    "lng": -72.5717,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Vermont",
    "capital": "admin",
    "population": 7372,
    "id": 1840002187
  },
  {
    "city": "Monaghan",
    "city_ascii": "Monaghan",
    "lat": 54.2479,
    "lng": -6.9708,
    "country": "Ireland",
    "iso2": "IE",
    "iso3": "IRL",
    "admin_name": "Monaghan",
    "capital": "admin",
    "population": 5937,
    "id": 1372399438
  },
  {
    "city": "Līvāni",
    "city_ascii": "Livani",
    "lat": 56.3539,
    "lng": 26.1761,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Līvānu Novads",
    "capital": "admin",
    "population": 7343,
    "id": 1428769984
  },
  {
    "city": "Ocniţa",
    "city_ascii": "Ocnita",
    "lat": 48.4167,
    "lng": 27.4833,
    "country": "Moldova",
    "iso2": "MD",
    "iso3": "MDA",
    "admin_name": "Ocniţa",
    "capital": "admin",
    "population": 7254,
    "id": 1498334915
  },
  {
    "city": "Frontera Corozal",
    "city_ascii": "Frontera Corozal",
    "lat": 16.8211,
    "lng": -90.8903,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Chiapas",
    "capital": "",
    "population": 5184,
    "id": 1484185978
  },
  {
    "city": "Cwmbach",
    "city_ascii": "Cwmbach",
    "lat": 51.7072,
    "lng": -3.4128,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Rhondda Cynon Taff",
    "capital": "",
    "population": 5117,
    "id": 1826742278
  },
  {
    "city": "Mobaye",
    "city_ascii": "Mobaye",
    "lat": 4.3167,
    "lng": 21.1833,
    "country": "Central African Republic",
    "iso2": "CF",
    "iso3": "CAF",
    "admin_name": "Basse-Kotto",
    "capital": "admin",
    "population": 7176,
    "id": 1140935252
  },
  {
    "city": "X-Cán",
    "city_ascii": "X-Can",
    "lat": 20.8583,
    "lng": -87.6681,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Yucatán",
    "capital": "",
    "population": 5191,
    "id": 1484190103
  },
  {
    "city": "Williston Park",
    "city_ascii": "Williston Park",
    "lat": 40.7587,
    "lng": -73.6465,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 7253,
    "id": 1840005333
  },
  {
    "city": "Teleneşti",
    "city_ascii": "Telenesti",
    "lat": 47.4997,
    "lng": 28.3656,
    "country": "Moldova",
    "iso2": "MD",
    "iso3": "MDA",
    "admin_name": "Teleneşti",
    "capital": "admin",
    "population": 7227,
    "id": 1498811064
  },
  {
    "city": "Limbaži",
    "city_ascii": "Limbazi",
    "lat": 57.5147,
    "lng": 24.7131,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Limbažu Novads",
    "capital": "admin",
    "population": 7221,
    "id": 1428920312
  },
  {
    "city": "Hola",
    "city_ascii": "Hola",
    "lat": -1.5,
    "lng": 40.03,
    "country": "Kenya",
    "iso2": "KE",
    "iso3": "KEN",
    "admin_name": "Tana River",
    "capital": "admin",
    "population": 6931,
    "id": 1404396632
  },
  {
    "city": "Madona",
    "city_ascii": "Madona",
    "lat": 56.8542,
    "lng": 26.22,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Madonas Novads",
    "capital": "admin",
    "population": 7206,
    "id": 1428548117
  },
  {
    "city": "Chicxulub Puerto",
    "city_ascii": "Chicxulub Puerto",
    "lat": 21.2939,
    "lng": -89.6083,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Yucatán",
    "capital": "",
    "population": 5052,
    "id": 1484967062
  },
  {
    "city": "Dunellen",
    "city_ascii": "Dunellen",
    "lat": 40.5903,
    "lng": -74.4656,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 7202,
    "id": 1840003634
  },
  {
    "city": "South Monrovia Island",
    "city_ascii": "South Monrovia Island",
    "lat": 34.1234,
    "lng": -117.9958,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 7192,
    "id": 1840043043
  },
  {
    "city": "East Franklin",
    "city_ascii": "East Franklin",
    "lat": 40.4933,
    "lng": -74.4711,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 7170,
    "id": 1840039869
  },
  {
    "city": "Lapovo",
    "city_ascii": "Lapovo",
    "lat": 44.1842,
    "lng": 21.0973,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Lapovo",
    "capital": "admin",
    "population": 7143,
    "id": 1688267080
  },
  {
    "city": "Glenolden",
    "city_ascii": "Glenolden",
    "lat": 39.8996,
    "lng": -75.292,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 7164,
    "id": 1840000699
  },
  {
    "city": "Nyack",
    "city_ascii": "Nyack",
    "lat": 41.0919,
    "lng": -73.9143,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 7156,
    "id": 1840004981
  },
  {
    "city": "Hampstead",
    "city_ascii": "Hampstead",
    "lat": 45.4833,
    "lng": -73.6333,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 7153,
    "id": 1124000763
  },
  {
    "city": "Donduşeni",
    "city_ascii": "Donduseni",
    "lat": 48.2244,
    "lng": 27.5853,
    "country": "Moldova",
    "iso2": "MD",
    "iso3": "MDA",
    "admin_name": "Donduşeni",
    "capital": "admin",
    "population": 7101,
    "id": 1498280582
  },
  {
    "city": "Ştefan Vodă",
    "city_ascii": "Stefan Voda",
    "lat": 46.5153,
    "lng": 29.6631,
    "country": "Moldova",
    "iso2": "MD",
    "iso3": "MDA",
    "admin_name": "Ştefan-Vodă",
    "capital": "admin",
    "population": 7078,
    "id": 1498766864
  },
  {
    "city": "Ewa Villages",
    "city_ascii": "Ewa Villages",
    "lat": 21.3418,
    "lng": -158.039,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Hawaii",
    "capital": "",
    "population": 7118,
    "id": 1840029578
  },
  {
    "city": "Nakasongola",
    "city_ascii": "Nakasongola",
    "lat": 1.3089,
    "lng": 32.4564,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Nakasongola",
    "capital": "admin",
    "population": 6921,
    "id": 1800270160
  },
  {
    "city": "Daet",
    "city_ascii": "Daet",
    "lat": 14.1122,
    "lng": 122.9553,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Camarines Norte",
    "capital": "admin",
    "population": null,
    "id": 1608562606
  },
  {
    "city": "Souderton",
    "city_ascii": "Souderton",
    "lat": 40.311,
    "lng": -75.3224,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 7082,
    "id": 1840001378
  },
  {
    "city": "Twin Rivers",
    "city_ascii": "Twin Rivers",
    "lat": 40.263,
    "lng": -74.4917,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 7067,
    "id": 1840005474
  },
  {
    "city": "Ridley Park",
    "city_ascii": "Ridley Park",
    "lat": 39.8785,
    "lng": -75.3251,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 7065,
    "id": 1840000708
  },
  {
    "city": "Waikele",
    "city_ascii": "Waikele",
    "lat": 21.4025,
    "lng": -158.0058,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Hawaii",
    "capital": "",
    "population": 7062,
    "id": 1840039259
  },
  {
    "city": "Great Neck Plaza",
    "city_ascii": "Great Neck Plaza",
    "lat": 40.7869,
    "lng": -73.7261,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 7027,
    "id": 1840005292
  },
  {
    "city": "Berovo",
    "city_ascii": "Berovo",
    "lat": 41.7078,
    "lng": 22.8564,
    "country": "Macedonia",
    "iso2": "MK",
    "iso3": "MKD",
    "admin_name": "Berovo",
    "capital": "admin",
    "population": 7002,
    "id": 1807873177
  },
  {
    "city": "Trindade",
    "city_ascii": "Trindade",
    "lat": 0.3,
    "lng": 6.6667,
    "country": "Sao Tome And Principe",
    "iso2": "ST",
    "iso3": "STP",
    "admin_name": "São Tomé",
    "capital": "admin",
    "population": 6636,
    "id": 1678096936
  },
  {
    "city": "Orland Hills",
    "city_ascii": "Orland Hills",
    "lat": 41.5905,
    "lng": -87.8413,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 7023,
    "id": 1840011309
  },
  {
    "city": "Virac",
    "city_ascii": "Virac",
    "lat": 13.5848,
    "lng": 124.2374,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Catanduanes",
    "capital": "admin",
    "population": null,
    "id": 1608265120
  },
  {
    "city": "Kratovo",
    "city_ascii": "Kratovo",
    "lat": 42.0783,
    "lng": 22.175,
    "country": "Macedonia",
    "iso2": "MK",
    "iso3": "MKD",
    "admin_name": "Kratovo",
    "capital": "admin",
    "population": 6924,
    "id": 1807262234
  },
  {
    "city": "Alūksne",
    "city_ascii": "Aluksne",
    "lat": 57.4239,
    "lng": 27.0503,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Alūksnes Novads",
    "capital": "admin",
    "population": 6930,
    "id": 1428873095
  },
  {
    "city": "South Valley Stream",
    "city_ascii": "South Valley Stream",
    "lat": 40.6557,
    "lng": -73.7186,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 6930,
    "id": 1840034062
  },
  {
    "city": "San Isidro",
    "city_ascii": "San Isidro",
    "lat": 18.3919,
    "lng": -65.8853,
    "country": "Puerto Rico",
    "iso2": "PR",
    "iso3": "PRI",
    "admin_name": "Puerto Rico",
    "capital": "",
    "population": 6915,
    "id": 1630035575
  },
  {
    "city": "Senglea",
    "city_ascii": "Senglea",
    "lat": 35.8875,
    "lng": 14.5169,
    "country": "Malta",
    "iso2": "MT",
    "iso3": "MLT",
    "admin_name": "Isla",
    "capital": "admin",
    "population": null,
    "id": 1470598185
  },
  {
    "city": "Puerto Real",
    "city_ascii": "Puerto Real",
    "lat": 18.0762,
    "lng": -67.1861,
    "country": "Puerto Rico",
    "iso2": "PR",
    "iso3": "PRI",
    "admin_name": "Puerto Rico",
    "capital": "",
    "population": 6892,
    "id": 1630035555
  },
  {
    "city": "Mahibadhoo",
    "city_ascii": "Mahibadhoo",
    "lat": 3.7571,
    "lng": 72.9689,
    "country": "Maldives",
    "iso2": "MV",
    "iso3": "MDV",
    "admin_name": "Ari Atholhu Dhekunuburi",
    "capital": "admin",
    "population": null,
    "id": 1462235059
  },
  {
    "city": "Ajdovščina",
    "city_ascii": "Ajdovscina",
    "lat": 45.8884,
    "lng": 13.9052,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Ajdovščina",
    "capital": "admin",
    "population": 6596,
    "id": 1705580792
  },
  {
    "city": "Bački Petrovac",
    "city_ascii": "Backi Petrovac",
    "lat": 45.3564,
    "lng": 19.5883,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Bački Petrovac",
    "capital": "admin",
    "population": 6727,
    "id": 1688400393
  },
  {
    "city": "Yabucoa",
    "city_ascii": "Yabucoa",
    "lat": 18.0469,
    "lng": -65.8792,
    "country": "Puerto Rico",
    "iso2": "PR",
    "iso3": "PRI",
    "admin_name": "Puerto Rico",
    "capital": "",
    "population": 6829,
    "id": 1630023682
  },
  {
    "city": "Farim",
    "city_ascii": "Farim",
    "lat": 12.4833,
    "lng": -15.2167,
    "country": "Guinea-Bissau",
    "iso2": "GW",
    "iso3": "GNB",
    "admin_name": "Oio",
    "capital": "admin",
    "population": 6792,
    "id": 1624753622
  },
  {
    "city": "Criuleni",
    "city_ascii": "Criuleni",
    "lat": 47.2167,
    "lng": 29.1614,
    "country": "Moldova",
    "iso2": "MD",
    "iso3": "MDA",
    "admin_name": "Criuleni",
    "capital": "admin",
    "population": 6708,
    "id": 1498171854
  },
  {
    "city": "Pasvalys",
    "city_ascii": "Pasvalys",
    "lat": 56.0611,
    "lng": 24.4028,
    "country": "Lithuania",
    "iso2": "LT",
    "iso3": "LTU",
    "admin_name": "Pasvalys",
    "capital": "admin",
    "population": 6768,
    "id": 1440957197
  },
  {
    "city": "Eydhafushi",
    "city_ascii": "Eydhafushi",
    "lat": 5.1038,
    "lng": 73.0699,
    "country": "Maldives",
    "iso2": "MV",
    "iso3": "MDV",
    "admin_name": "Maalhosmadulu Dhekunuburi",
    "capital": "admin",
    "population": null,
    "id": 1462623341
  },
  {
    "city": "Diekirch",
    "city_ascii": "Diekirch",
    "lat": 49.8678,
    "lng": 6.1558,
    "country": "Luxembourg",
    "iso2": "LU",
    "iso3": "LUX",
    "admin_name": "Diekirch",
    "capital": "admin",
    "population": 6242,
    "id": 1442382721
  },
  {
    "city": "Naples Manor",
    "city_ascii": "Naples Manor",
    "lat": 26.0892,
    "lng": -81.7254,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 6735,
    "id": 1840014232
  },
  {
    "city": "Clifton Heights",
    "city_ascii": "Clifton Heights",
    "lat": 39.9301,
    "lng": -75.2958,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 6697,
    "id": 1840000692
  },
  {
    "city": "Castroville",
    "city_ascii": "Castroville",
    "lat": 36.765,
    "lng": -121.7535,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 6686,
    "id": 1840017682
  },
  {
    "city": "Šalčininkai",
    "city_ascii": "Salcininkai",
    "lat": 54.3111,
    "lng": 25.3806,
    "country": "Lithuania",
    "iso2": "LT",
    "iso3": "LTU",
    "admin_name": "Šalčininkai",
    "capital": "admin",
    "population": 6664,
    "id": 1440401402
  },
  {
    "city": "Ikšķile",
    "city_ascii": "Ikskile",
    "lat": 56.8367,
    "lng": 24.4964,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Ikšķiles Novads",
    "capital": "admin",
    "population": 6597,
    "id": 1428284061
  },
  {
    "city": "Lauderdale-by-the-Sea",
    "city_ascii": "Lauderdale-by-the-Sea",
    "lat": 26.199,
    "lng": -80.0972,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 6664,
    "id": 1840017271
  },
  {
    "city": "Stratford",
    "city_ascii": "Stratford",
    "lat": -39.341,
    "lng": 174.284,
    "country": "New Zealand",
    "iso2": "NZ",
    "iso3": "NZL",
    "admin_name": "Taranaki",
    "capital": "admin",
    "population": 5740,
    "id": 1554001293
  },
  {
    "city": "Cedarhurst",
    "city_ascii": "Cedarhurst",
    "lat": 40.6252,
    "lng": -73.7278,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 6633,
    "id": 1840005339
  },
  {
    "city": "Folcroft",
    "city_ascii": "Folcroft",
    "lat": 39.8891,
    "lng": -75.277,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 6632,
    "id": 1840000698
  },
  {
    "city": "Guadalupe",
    "city_ascii": "Guadalupe",
    "lat": 33.3664,
    "lng": -111.9633,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 6631,
    "id": 1840021948
  },
  {
    "city": "Manorhaven",
    "city_ascii": "Manorhaven",
    "lat": 40.8399,
    "lng": -73.7127,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 6627,
    "id": 1840005306
  },
  {
    "city": "Paloma Creek South",
    "city_ascii": "Paloma Creek South",
    "lat": 33.21,
    "lng": -96.9327,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 6624,
    "id": 1840041381
  },
  {
    "city": "Forest Glen",
    "city_ascii": "Forest Glen",
    "lat": 39.0191,
    "lng": -77.0445,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 6589,
    "id": 1840005824
  },
  {
    "city": "Bayombong",
    "city_ascii": "Bayombong",
    "lat": 16.4812,
    "lng": 121.1497,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Nueva Vizcaya",
    "capital": "admin",
    "population": null,
    "id": 1608066874
  },
  {
    "city": "Notre Dame",
    "city_ascii": "Notre Dame",
    "lat": 41.7014,
    "lng": -86.2378,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 6584,
    "id": 1840039286
  },
  {
    "city": "Tarxien",
    "city_ascii": "Tarxien",
    "lat": 35.8658,
    "lng": 14.515,
    "country": "Malta",
    "iso2": "MT",
    "iso3": "MLT",
    "admin_name": "Tarxien",
    "capital": "admin",
    "population": null,
    "id": 1470230507
  },
  {
    "city": "Preiļi",
    "city_ascii": "Preili",
    "lat": 56.2942,
    "lng": 26.7247,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Preiļu Novads",
    "capital": "admin",
    "population": 6522,
    "id": 1428460035
  },
  {
    "city": "Tuckahoe",
    "city_ascii": "Tuckahoe",
    "lat": 40.953,
    "lng": -73.823,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 6549,
    "id": 1840004946
  },
  {
    "city": "Mae Hong Son",
    "city_ascii": "Mae Hong Son",
    "lat": 19.3011,
    "lng": 97.97,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Mae Hong Son",
    "capital": "admin",
    "population": 6526,
    "id": 1764090874
  },
  {
    "city": "Jolo",
    "city_ascii": "Jolo",
    "lat": 6.0522,
    "lng": 121.0022,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Sulu",
    "capital": "admin",
    "population": null,
    "id": 1608685440
  },
  {
    "city": "Puerto Baquerizo Moreno",
    "city_ascii": "Puerto Baquerizo Moreno",
    "lat": -0.9025,
    "lng": -89.6092,
    "country": "Ecuador",
    "iso2": "EC",
    "iso3": "ECU",
    "admin_name": "Galápagos",
    "capital": "admin",
    "population": 6533,
    "id": 1218093882
  },
  {
    "city": "Bongao",
    "city_ascii": "Bongao",
    "lat": 5.0292,
    "lng": 119.7731,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Tawi-Tawi",
    "capital": "admin",
    "population": null,
    "id": 1608490585
  },
  {
    "city": "West View",
    "city_ascii": "West View",
    "lat": 40.5182,
    "lng": -80.0333,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 6516,
    "id": 1840001274
  },
  {
    "city": "Contra Costa Centre",
    "city_ascii": "Contra Costa Centre",
    "lat": 37.9261,
    "lng": -122.054,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 6497,
    "id": 1840028422
  },
  {
    "city": "Prospect Park",
    "city_ascii": "Prospect Park",
    "lat": 39.8858,
    "lng": -75.3074,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 6492,
    "id": 1840000707
  },
  {
    "city": "Saranap",
    "city_ascii": "Saranap",
    "lat": 37.8878,
    "lng": -122.076,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 6492,
    "id": 1840024710
  },
  {
    "city": "Ambler",
    "city_ascii": "Ambler",
    "lat": 40.1564,
    "lng": -75.2215,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 6491,
    "id": 1840003693
  },
  {
    "city": "Hewlett",
    "city_ascii": "Hewlett",
    "lat": 40.6422,
    "lng": -73.6942,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 6484,
    "id": 1840005265
  },
  {
    "city": "Zarasai",
    "city_ascii": "Zarasai",
    "lat": 55.7306,
    "lng": 26.2472,
    "country": "Lithuania",
    "iso2": "LT",
    "iso3": "LTU",
    "admin_name": "Zarasai",
    "capital": "admin",
    "population": 6458,
    "id": 1440450872
  },
  {
    "city": "Bronxville",
    "city_ascii": "Bronxville",
    "lat": 40.9394,
    "lng": -73.8263,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 6408,
    "id": 1840004949
  },
  {
    "city": "Las Piedras",
    "city_ascii": "Las Piedras",
    "lat": 18.1784,
    "lng": -65.8708,
    "country": "Puerto Rico",
    "iso2": "PR",
    "iso3": "PRI",
    "admin_name": "Puerto Rico",
    "capital": "",
    "population": 6402,
    "id": 1630023710
  },
  {
    "city": "Sal Rei",
    "city_ascii": "Sal Rei",
    "lat": 16.176,
    "lng": -22.9171,
    "country": "Cabo Verde",
    "iso2": "CV",
    "iso3": "CPV",
    "admin_name": "Boa Vista",
    "capital": "admin",
    "population": 6357,
    "id": 1132420232
  },
  {
    "city": "Jõgeva",
    "city_ascii": "Jogeva",
    "lat": 58.7461,
    "lng": 26.3956,
    "country": "Estonia",
    "iso2": "EE",
    "iso3": "EST",
    "admin_name": "Jõgevamaa",
    "capital": "admin",
    "population": 5073,
    "id": 1233192849
  },
  {
    "city": "Balvi",
    "city_ascii": "Balvi",
    "lat": 57.1292,
    "lng": 27.2667,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Balvu Novads",
    "capital": "admin",
    "population": 6334,
    "id": 1428606162
  },
  {
    "city": "Kingston Estates",
    "city_ascii": "Kingston Estates",
    "lat": 39.9188,
    "lng": -74.9898,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 6346,
    "id": 1840033314
  },
  {
    "city": "Kupiškis",
    "city_ascii": "Kupiskis",
    "lat": 55.8333,
    "lng": 24.9667,
    "country": "Lithuania",
    "iso2": "LT",
    "iso3": "LTU",
    "admin_name": "Kupiškis",
    "capital": "admin",
    "population": 6321,
    "id": 1440122297
  },
  {
    "city": "Lakeview",
    "city_ascii": "Lakeview",
    "lat": 40.6775,
    "lng": -73.6493,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 6317,
    "id": 1840005269
  },
  {
    "city": "Ghanzi",
    "city_ascii": "Ghanzi",
    "lat": -21.7,
    "lng": 21.65,
    "country": "Botswana",
    "iso2": "BW",
    "iso3": "BWA",
    "admin_name": "Ghanzi",
    "capital": "admin",
    "population": 6306,
    "id": 1072132620
  },
  {
    "city": "Põlva",
    "city_ascii": "Polva",
    "lat": 58.0536,
    "lng": 27.0556,
    "country": "Estonia",
    "iso2": "EE",
    "iso3": "EST",
    "admin_name": "Põlvamaa",
    "capital": "admin",
    "population": 5324,
    "id": 1233866730
  },
  {
    "city": "Pleasure Point",
    "city_ascii": "Pleasure Point",
    "lat": 36.9618,
    "lng": -121.9715,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 6277,
    "id": 1840074532
  },
  {
    "city": "Ciudad Guzmán",
    "city_ascii": "Ciudad Guzman",
    "lat": 19.7,
    "lng": -103.4667,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Jalisco",
    "capital": "minor",
    "population": 97,
    "id": 1484316056
  },
  {
    "city": "Cameron Park",
    "city_ascii": "Cameron Park",
    "lat": 25.9705,
    "lng": -97.4775,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 6226,
    "id": 1840037105
  },
  {
    "city": "Savannah",
    "city_ascii": "Savannah",
    "lat": 33.2257,
    "lng": -96.9082,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 6224,
    "id": 1840041547
  },
  {
    "city": "Fruitridge Pocket",
    "city_ascii": "Fruitridge Pocket",
    "lat": 38.5326,
    "lng": -121.4558,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 6215,
    "id": 1840075898
  },
  {
    "city": "Espargos",
    "city_ascii": "Espargos",
    "lat": 16.7546,
    "lng": -22.9453,
    "country": "Cabo Verde",
    "iso2": "CV",
    "iso3": "CPV",
    "admin_name": "Sal",
    "capital": "admin",
    "population": 6173,
    "id": 1132760152
  },
  {
    "city": "Kennett Square",
    "city_ascii": "Kennett Square",
    "lat": 39.8438,
    "lng": -75.7113,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 6202,
    "id": 1840001428
  },
  {
    "city": "Santa Isabel",
    "city_ascii": "Santa Isabel",
    "lat": 17.9687,
    "lng": -66.4049,
    "country": "Puerto Rico",
    "iso2": "PR",
    "iso3": "PRI",
    "admin_name": "Puerto Rico",
    "capital": "",
    "population": 6196,
    "id": 1630023545
  },
  {
    "city": "Junik",
    "city_ascii": "Junik",
    "lat": 42.4761,
    "lng": 20.2775,
    "country": "Kosovo",
    "iso2": "XK",
    "iso3": "XKS",
    "admin_name": "Junik",
    "capital": "admin",
    "population": 6084,
    "id": 1901930204
  },
  {
    "city": "Siteki",
    "city_ascii": "Siteki",
    "lat": -26.45,
    "lng": 31.95,
    "country": "Swaziland",
    "iso2": "SZ",
    "iso3": "SWZ",
    "admin_name": "Lubombo",
    "capital": "admin",
    "population": 6152,
    "id": 1748933140
  },
  {
    "city": "Schaan",
    "city_ascii": "Schaan",
    "lat": 47.1667,
    "lng": 9.5167,
    "country": "Liechtenstein",
    "iso2": "LI",
    "iso3": "LIE",
    "admin_name": "Schaan",
    "capital": "admin",
    "population": 5959,
    "id": 1438982330
  },
  {
    "city": "Viqueque",
    "city_ascii": "Viqueque",
    "lat": -8.85,
    "lng": 126.3667,
    "country": "Timor-Leste",
    "iso2": "TL",
    "iso3": "TLS",
    "admin_name": "Viqueque",
    "capital": "admin",
    "population": 6078,
    "id": 1626768340
  },
  {
    "city": "Lucea",
    "city_ascii": "Lucea",
    "lat": 18.4509,
    "lng": -78.1736,
    "country": "Jamaica",
    "iso2": "JM",
    "iso3": "JAM",
    "admin_name": "Hanover",
    "capital": "admin",
    "population": 6002,
    "id": 1388423676
  },
  {
    "city": "Rapla",
    "city_ascii": "Rapla",
    "lat": 58.9944,
    "lng": 24.8011,
    "country": "Estonia",
    "iso2": "EE",
    "iso3": "EST",
    "admin_name": "Raplamaa",
    "capital": "admin",
    "population": 5069,
    "id": 1233013333
  },
  {
    "city": "Binghamton University",
    "city_ascii": "Binghamton University",
    "lat": 42.0893,
    "lng": -75.9684,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 6149,
    "id": 1840043235
  },
  {
    "city": "Arroyo",
    "city_ascii": "Arroyo",
    "lat": 17.9706,
    "lng": -66.0609,
    "country": "Puerto Rico",
    "iso2": "PR",
    "iso3": "PRI",
    "admin_name": "Puerto Rico",
    "capital": "",
    "population": 6134,
    "id": 1630035613
  },
  {
    "city": "Bač",
    "city_ascii": "Bac",
    "lat": 45.3886,
    "lng": 19.2353,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Bač",
    "capital": "admin",
    "population": 6087,
    "id": 1688168992
  },
  {
    "city": "Ben Arous",
    "city_ascii": "Ben Arous",
    "lat": 36.7545,
    "lng": 10.2217,
    "country": "Tunisia",
    "iso2": "TN",
    "iso3": "TUN",
    "admin_name": "Ben Arous",
    "capital": "admin",
    "population": null,
    "id": 1788439087
  },
  {
    "city": "Larchmont",
    "city_ascii": "Larchmont",
    "lat": 40.9258,
    "lng": -73.7529,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 6087,
    "id": 1840004934
  },
  {
    "city": "Bogdanci",
    "city_ascii": "Bogdanci",
    "lat": 41.2031,
    "lng": 22.5728,
    "country": "Macedonia",
    "iso2": "MK",
    "iso3": "MKD",
    "admin_name": "Bogdanci",
    "capital": "admin",
    "population": 6031,
    "id": 1807565779
  },
  {
    "city": "Kazlų Rūda",
    "city_ascii": "Kazlu Ruda",
    "lat": 54.7492,
    "lng": 23.4865,
    "country": "Lithuania",
    "iso2": "LT",
    "iso3": "LTU",
    "admin_name": "Kazlų Rūdos",
    "capital": "admin",
    "population": 6018,
    "id": 1440371651
  },
  {
    "city": "Lielvārde",
    "city_ascii": "Lielvarde",
    "lat": 56.7175,
    "lng": 24.8106,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Lielvārdes Novads",
    "capital": "admin",
    "population": 5973,
    "id": 1428614074
  },
  {
    "city": "District Heights",
    "city_ascii": "District Heights",
    "lat": 38.8588,
    "lng": -76.8885,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 5984,
    "id": 1840005972
  },
  {
    "city": "Muramvya",
    "city_ascii": "Muramvya",
    "lat": -3.25,
    "lng": 29.6,
    "country": "Burundi",
    "iso2": "BI",
    "iso3": "BDI",
    "admin_name": "Muramvya",
    "capital": "admin",
    "population": 5458,
    "id": 1108007544
  },
  {
    "city": "Imsida",
    "city_ascii": "Imsida",
    "lat": 35.8925,
    "lng": 14.4828,
    "country": "Malta",
    "iso2": "MT",
    "iso3": "MLT",
    "admin_name": "Imsida",
    "capital": "admin",
    "population": null,
    "id": 1470395922
  },
  {
    "city": "Şoldăneşti",
    "city_ascii": "Soldanesti",
    "lat": 47.8153,
    "lng": 28.7967,
    "country": "Moldova",
    "iso2": "MD",
    "iso3": "MDA",
    "admin_name": "Şoldăneşti",
    "capital": "admin",
    "population": 5883,
    "id": 1498087659
  },
  {
    "city": "Appenzell",
    "city_ascii": "Appenzell",
    "lat": 47.3333,
    "lng": 9.4167,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Appenzell Innerrhoden",
    "capital": "admin",
    "population": 5649,
    "id": 1756770881
  },
  {
    "city": "Norwood",
    "city_ascii": "Norwood",
    "lat": 39.8865,
    "lng": -75.2964,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5897,
    "id": 1840000705
  },
  {
    "city": "Echternach",
    "city_ascii": "Echternach",
    "lat": 49.8117,
    "lng": 6.4217,
    "country": "Luxembourg",
    "iso2": "LU",
    "iso3": "LUX",
    "admin_name": "Echternach",
    "capital": "admin",
    "population": 5617,
    "id": 1442794725
  },
  {
    "city": "Jamesburg",
    "city_ascii": "Jamesburg",
    "lat": 40.3494,
    "lng": -74.44,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 5885,
    "id": 1840003637
  },
  {
    "city": "Ranillug",
    "city_ascii": "Ranillug",
    "lat": 42.492,
    "lng": 21.559,
    "country": "Kosovo",
    "iso2": "XK",
    "iso3": "XKS",
    "admin_name": "Ranillug",
    "capital": "admin",
    "population": 5800,
    "id": 1901029901
  },
  {
    "city": "Elsmere",
    "city_ascii": "Elsmere",
    "lat": 39.7385,
    "lng": -75.5946,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Delaware",
    "capital": "",
    "population": 5872,
    "id": 1840005574
  },
  {
    "city": "Idrija",
    "city_ascii": "Idrija",
    "lat": 46.0025,
    "lng": 14.0275,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Idrija",
    "capital": "admin",
    "population": 5843,
    "id": 1705472042
  },
  {
    "city": "Hrastnik",
    "city_ascii": "Hrastnik",
    "lat": 46.1461,
    "lng": 15.0814,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Hrastnik",
    "capital": "admin",
    "population": 4995,
    "id": 1705818009
  },
  {
    "city": "McKees Rocks",
    "city_ascii": "McKees Rocks",
    "lat": 40.4688,
    "lng": -80.063,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5855,
    "id": 1840001244
  },
  {
    "city": "Charlemagne",
    "city_ascii": "Charlemagne",
    "lat": 45.7167,
    "lng": -73.4833,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 5853,
    "id": 1124185024
  },
  {
    "city": "Prospect Park",
    "city_ascii": "Prospect Park",
    "lat": 40.938,
    "lng": -74.1736,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 5843,
    "id": 1840000834
  },
  {
    "city": "Naval Academy",
    "city_ascii": "Naval Academy",
    "lat": 38.9859,
    "lng": -76.488,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 5834,
    "id": 1840073633
  },
  {
    "city": "Lorengau",
    "city_ascii": "Lorengau",
    "lat": -2.0208,
    "lng": 147.2667,
    "country": "Papua New Guinea",
    "iso2": "PG",
    "iso3": "PNG",
    "admin_name": "Manus",
    "capital": "admin",
    "population": 5806,
    "id": 1598571197
  },
  {
    "city": "Mayflower Village",
    "city_ascii": "Mayflower Village",
    "lat": 34.116,
    "lng": -118.0096,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 5822,
    "id": 1840028387
  },
  {
    "city": "Plymouth",
    "city_ascii": "Plymouth",
    "lat": 41.2404,
    "lng": -75.9505,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5794,
    "id": 1840000767
  },
  {
    "city": "Bay Harbor Islands",
    "city_ascii": "Bay Harbor Islands",
    "lat": 25.8878,
    "lng": -80.1335,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 5793,
    "id": 1840016005
  },
  {
    "city": "Tevragh Zeina",
    "city_ascii": "Tevragh Zeina",
    "lat": 18.0989,
    "lng": -15.9885,
    "country": "Mauritania",
    "iso2": "MR",
    "iso3": "MRT",
    "admin_name": "Nouakchott Ouest",
    "capital": "admin",
    "population": null,
    "id": 1478902195
  },
  {
    "city": "Cutler",
    "city_ascii": "Cutler",
    "lat": 36.5263,
    "lng": -119.2884,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 5774,
    "id": 1840019030
  },
  {
    "city": "Santa Venera",
    "city_ascii": "Santa Venera",
    "lat": 35.8908,
    "lng": 14.4742,
    "country": "Malta",
    "iso2": "MT",
    "iso3": "MLT",
    "admin_name": "Santa Venera",
    "capital": "admin",
    "population": null,
    "id": 1470983296
  },
  {
    "city": "White City",
    "city_ascii": "White City",
    "lat": 40.5666,
    "lng": -111.8636,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Utah",
    "capital": "",
    "population": 5768,
    "id": 1840037177
  },
  {
    "city": "Širvintos",
    "city_ascii": "Sirvintos",
    "lat": 55.0361,
    "lng": 24.9694,
    "country": "Lithuania",
    "iso2": "LT",
    "iso3": "LTU",
    "admin_name": "Širvintos",
    "capital": "admin",
    "population": 5744,
    "id": 1440054141
  },
  {
    "city": "Penn Wynne",
    "city_ascii": "Penn Wynne",
    "lat": 39.9867,
    "lng": -75.2715,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5758,
    "id": 1840035305
  },
  {
    "city": "Mamushë",
    "city_ascii": "Mamushe",
    "lat": 42.3167,
    "lng": 20.7167,
    "country": "Kosovo",
    "iso2": "XK",
    "iso3": "XKS",
    "admin_name": "Mamushë",
    "capital": "admin",
    "population": 5507,
    "id": 1901042958
  },
  {
    "city": "Fulin",
    "city_ascii": "Fulin",
    "lat": 29.35,
    "lng": 102.7167,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Sichuan",
    "capital": "",
    "population": 1049,
    "id": 1156759821
  },
  {
    "city": "Mount Carmel",
    "city_ascii": "Mount Carmel",
    "lat": 40.7959,
    "lng": -76.4121,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5724,
    "id": 1840000846
  },
  {
    "city": "Bellevue",
    "city_ascii": "Bellevue",
    "lat": 39.1011,
    "lng": -84.4776,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kentucky",
    "capital": "",
    "population": 5721,
    "id": 1840013164
  },
  {
    "city": "South Tucson",
    "city_ascii": "South Tucson",
    "lat": 32.1955,
    "lng": -110.9692,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 5715,
    "id": 1840022100
  },
  {
    "city": "Lewisburg",
    "city_ascii": "Lewisburg",
    "lat": 40.9642,
    "lng": -76.8901,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5708,
    "id": 1840003531
  },
  {
    "city": "Gospić",
    "city_ascii": "Gospic",
    "lat": 44.5469,
    "lng": 15.3744,
    "country": "Croatia",
    "iso2": "HR",
    "iso3": "HRV",
    "admin_name": "Ličko-Senjska Županija",
    "capital": "admin",
    "population": 5695,
    "id": 1191887616
  },
  {
    "city": "Sisimiut",
    "city_ascii": "Sisimiut",
    "lat": 66.9389,
    "lng": -53.6722,
    "country": "Greenland",
    "iso2": "GL",
    "iso3": "GRL",
    "admin_name": "Qeqqata",
    "capital": "admin",
    "population": 5539,
    "id": 1304160154
  },
  {
    "city": "Sharon Hill",
    "city_ascii": "Sharon Hill",
    "lat": 39.9075,
    "lng": -75.2678,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5689,
    "id": 1840000711
  },
  {
    "city": "Media",
    "city_ascii": "Media",
    "lat": 39.9198,
    "lng": -75.3888,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5682,
    "id": 1840000702
  },
  {
    "city": "New Brighton",
    "city_ascii": "New Brighton",
    "lat": 40.7355,
    "lng": -80.3091,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5679,
    "id": 1840001026
  },
  {
    "city": "Kerema",
    "city_ascii": "Kerema",
    "lat": -7.927,
    "lng": 145.838,
    "country": "Papua New Guinea",
    "iso2": "PG",
    "iso3": "PNG",
    "admin_name": "Gulf",
    "capital": "admin",
    "population": 5646,
    "id": 1598925932
  },
  {
    "city": "Surfside",
    "city_ascii": "Surfside",
    "lat": 25.8787,
    "lng": -80.125,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 5651,
    "id": 1840017278
  },
  {
    "city": "Twin Lakes",
    "city_ascii": "Twin Lakes",
    "lat": 36.9646,
    "lng": -121.9896,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 5642,
    "id": 1840028200
  },
  {
    "city": "Skuodas",
    "city_ascii": "Skuodas",
    "lat": 56.2667,
    "lng": 21.5333,
    "country": "Lithuania",
    "iso2": "LT",
    "iso3": "LTU",
    "admin_name": "Skuodas",
    "capital": "admin",
    "population": 5610,
    "id": 1440264405
  },
  {
    "city": "Pietà",
    "city_ascii": "Pieta",
    "lat": 35.8947,
    "lng": 14.495,
    "country": "Malta",
    "iso2": "MT",
    "iso3": "MLT",
    "admin_name": "Pietà",
    "capital": "admin",
    "population": null,
    "id": 1470444069
  },
  {
    "city": "Ta’ Xbiex",
    "city_ascii": "Ta' Xbiex",
    "lat": 35.8992,
    "lng": 14.4944,
    "country": "Malta",
    "iso2": "MT",
    "iso3": "MLT",
    "admin_name": "Ta’ Xbiex",
    "capital": "admin",
    "population": null,
    "id": 1470129537
  },
  {
    "city": "Rochelle Park",
    "city_ascii": "Rochelle Park",
    "lat": 40.9069,
    "lng": -74.0791,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 5613,
    "id": 1840081801
  },
  {
    "city": "Molėtai",
    "city_ascii": "Moletai",
    "lat": 55.2333,
    "lng": 25.4167,
    "country": "Lithuania",
    "iso2": "LT",
    "iso3": "LTU",
    "admin_name": "Molėtai",
    "capital": "admin",
    "population": 5587,
    "id": 1440110307
  },
  {
    "city": "Partesh",
    "city_ascii": "Partesh",
    "lat": 42.4019,
    "lng": 21.4336,
    "country": "Kosovo",
    "iso2": "XK",
    "iso3": "XKS",
    "admin_name": "Partesh",
    "capital": "admin",
    "population": 5300,
    "id": 1901909986
  },
  {
    "city": "Salavan",
    "city_ascii": "Salavan",
    "lat": 15.7167,
    "lng": 106.4167,
    "country": "Laos",
    "iso2": "LA",
    "iso3": "LAO",
    "admin_name": "Salavan",
    "capital": "admin",
    "population": 5521,
    "id": 1418448631
  },
  {
    "city": "Deer Park",
    "city_ascii": "Deer Park",
    "lat": 39.2039,
    "lng": -84.3977,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 5566,
    "id": 1840003815
  },
  {
    "city": "Kruševo",
    "city_ascii": "Krusevo",
    "lat": 41.37,
    "lng": 21.2483,
    "country": "Macedonia",
    "iso2": "MK",
    "iso3": "MKD",
    "admin_name": "Kruševo",
    "capital": "admin",
    "population": 5507,
    "id": 1807741715
  },
  {
    "city": "Belmar",
    "city_ascii": "Belmar",
    "lat": 40.1798,
    "lng": -74.0255,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 5545,
    "id": 1840001355
  },
  {
    "city": "Caazapá",
    "city_ascii": "Caazapa",
    "lat": -26.2,
    "lng": -56.38,
    "country": "Paraguay",
    "iso2": "PY",
    "iso3": "PRY",
    "admin_name": "Caazapá",
    "capital": "admin",
    "population": 5504,
    "id": 1600103814
  },
  {
    "city": "Añasco",
    "city_ascii": "Anasco",
    "lat": 18.286,
    "lng": -67.1411,
    "country": "Puerto Rico",
    "iso2": "PR",
    "iso3": "PRI",
    "admin_name": "Puerto Rico",
    "capital": "",
    "population": 5526,
    "id": 1630023590
  },
  {
    "city": "Palm Springs North",
    "city_ascii": "Palm Springs North",
    "lat": 25.9351,
    "lng": -80.3339,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 5515,
    "id": 1840047821
  },
  {
    "city": "Thaba-Tseka",
    "city_ascii": "Thaba-Tseka",
    "lat": -29.5333,
    "lng": 28.6,
    "country": "Lesotho",
    "iso2": "LS",
    "iso3": "LSO",
    "admin_name": "Thaba-Tseka",
    "capital": "admin",
    "population": 5423,
    "id": 1426611266
  },
  {
    "city": "Shaw Heights",
    "city_ascii": "Shaw Heights",
    "lat": 39.8566,
    "lng": -105.039,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 5459,
    "id": 1840028586
  },
  {
    "city": "Waipio Acres",
    "city_ascii": "Waipio Acres",
    "lat": 21.4689,
    "lng": -158.0173,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Hawaii",
    "capital": "",
    "population": 5443,
    "id": 1840029562
  },
  {
    "city": "Qal‘ah-ye Now",
    "city_ascii": "Qal`ah-ye Now",
    "lat": 34.99,
    "lng": 63.12,
    "country": "Afghanistan",
    "iso2": "AF",
    "iso3": "AFG",
    "admin_name": "Bādghīs",
    "capital": "admin",
    "population": 5340,
    "id": 1004947440
  },
  {
    "city": "Kensington",
    "city_ascii": "Kensington",
    "lat": 37.9084,
    "lng": -122.2805,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 5415,
    "id": 1840017613
  },
  {
    "city": "Tara Hills",
    "city_ascii": "Tara Hills",
    "lat": 37.9939,
    "lng": -122.3188,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 5405,
    "id": 1840028315
  },
  {
    "city": "Šakiai",
    "city_ascii": "Sakiai",
    "lat": 54.9556,
    "lng": 23.0444,
    "country": "Lithuania",
    "iso2": "LT",
    "iso3": "LTU",
    "admin_name": "Šakiai",
    "capital": "admin",
    "population": 5383,
    "id": 1440362808
  },
  {
    "city": "Kaser",
    "city_ascii": "Kaser",
    "lat": 41.1214,
    "lng": -74.0686,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 5368,
    "id": 1840004977
  },
  {
    "city": "L’Epiphanie",
    "city_ascii": "L'Epiphanie",
    "lat": 45.85,
    "lng": -73.4833,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 5353,
    "id": 1124599436
  },
  {
    "city": "Kalangala",
    "city_ascii": "Kalangala",
    "lat": -0.3089,
    "lng": 32.225,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Kalangala",
    "capital": "admin",
    "population": 5200,
    "id": 1800446257
  },
  {
    "city": "Outapi",
    "city_ascii": "Outapi",
    "lat": -17.5028,
    "lng": 14.9856,
    "country": "Namibia",
    "iso2": "NA",
    "iso3": "NAM",
    "admin_name": "Omusati",
    "capital": "admin",
    "population": 4606,
    "id": 1516820698
  },
  {
    "city": "South Highpoint",
    "city_ascii": "South Highpoint",
    "lat": 27.9086,
    "lng": -82.7162,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 5335,
    "id": 1840029006
  },
  {
    "city": "Edgewater",
    "city_ascii": "Edgewater",
    "lat": 39.7507,
    "lng": -105.0626,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 5335,
    "id": 1840020196
  },
  {
    "city": "Palé",
    "city_ascii": "Pale",
    "lat": -1.4043,
    "lng": 5.6322,
    "country": "Equatorial Guinea",
    "iso2": "GQ",
    "iso3": "GNQ",
    "admin_name": "Annobón",
    "capital": "admin",
    "population": 5008,
    "id": 1226970205
  },
  {
    "city": "Smiltene",
    "city_ascii": "Smiltene",
    "lat": 57.4242,
    "lng": 25.9064,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Smiltenes Novads",
    "capital": "admin",
    "population": 5306,
    "id": 1428035157
  },
  {
    "city": "Shillington",
    "city_ascii": "Shillington",
    "lat": 40.3029,
    "lng": -75.967,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5319,
    "id": 1840001188
  },
  {
    "city": "Bret Harte",
    "city_ascii": "Bret Harte",
    "lat": 37.6021,
    "lng": -121.0045,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 5319,
    "id": 1840036997
  },
  {
    "city": "Ros Comáin",
    "city_ascii": "Ros Comain",
    "lat": 53.6333,
    "lng": -8.1833,
    "country": "Ireland",
    "iso2": "IE",
    "iso3": "IRL",
    "admin_name": "Roscommon",
    "capital": "admin",
    "population": 4860,
    "id": 1372870384
  },
  {
    "city": "Campanilla",
    "city_ascii": "Campanilla",
    "lat": 18.4229,
    "lng": -66.2382,
    "country": "Puerto Rico",
    "iso2": "PR",
    "iso3": "PRI",
    "admin_name": "Puerto Rico",
    "capital": "",
    "population": 5304,
    "id": 1630035630
  },
  {
    "city": "Broadmoor",
    "city_ascii": "Broadmoor",
    "lat": 37.6914,
    "lng": -122.4811,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 5286,
    "id": 1840017623
  },
  {
    "city": "Albina",
    "city_ascii": "Albina",
    "lat": 5.5,
    "lng": -54.05,
    "country": "Suriname",
    "iso2": "SR",
    "iso3": "SUR",
    "admin_name": "Marowijne",
    "capital": "admin",
    "population": 5247,
    "id": 1740921776
  },
  {
    "city": "Albertson",
    "city_ascii": "Albertson",
    "lat": 40.7715,
    "lng": -73.6482,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 5254,
    "id": 1840005227
  },
  {
    "city": "Peñuelas",
    "city_ascii": "Penuelas",
    "lat": 18.0595,
    "lng": -66.7206,
    "country": "Puerto Rico",
    "iso2": "PR",
    "iso3": "PRI",
    "admin_name": "Puerto Rico",
    "capital": "",
    "population": 5253,
    "id": 1630023556
  },
  {
    "city": "Gżira",
    "city_ascii": "Gzira",
    "lat": 35.9058,
    "lng": 14.4881,
    "country": "Malta",
    "iso2": "MT",
    "iso3": "MLT",
    "admin_name": "Gżira",
    "capital": "admin",
    "population": null,
    "id": 1470739045
  },
  {
    "city": "Wangdue Phodrang",
    "city_ascii": "Wangdue Phodrang",
    "lat": 27.4333,
    "lng": 89.9167,
    "country": "Bhutan",
    "iso2": "BT",
    "iso3": "BTN",
    "admin_name": "Wangdue Phodrang",
    "capital": "admin",
    "population": 5000,
    "id": 1064353953
  },
  {
    "city": "Pine Manor",
    "city_ascii": "Pine Manor",
    "lat": 26.5727,
    "lng": -81.8775,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 5248,
    "id": 1840028755
  },
  {
    "city": "Friendship Heights Village",
    "city_ascii": "Friendship Heights Village",
    "lat": 38.9633,
    "lng": -77.09,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 5225,
    "id": 1840073677
  },
  {
    "city": "Highwood",
    "city_ascii": "Highwood",
    "lat": 42.206,
    "lng": -87.8128,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 5224,
    "id": 1840006974
  },
  {
    "city": "Naguabo",
    "city_ascii": "Naguabo",
    "lat": 18.2116,
    "lng": -65.737,
    "country": "Puerto Rico",
    "iso2": "PR",
    "iso3": "PRI",
    "admin_name": "Puerto Rico",
    "capital": "",
    "population": 5220,
    "id": 1630035528
  },
  {
    "city": "Manouba",
    "city_ascii": "Manouba",
    "lat": 36.8101,
    "lng": 10.0956,
    "country": "Tunisia",
    "iso2": "TN",
    "iso3": "TUN",
    "admin_name": "Manouba",
    "capital": "admin",
    "population": null,
    "id": 1788608864
  },
  {
    "city": "West Loch Estate",
    "city_ascii": "West Loch Estate",
    "lat": 21.3617,
    "lng": -158.0246,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Hawaii",
    "capital": "",
    "population": 5177,
    "id": 1840039268
  },
  {
    "city": "Kanifing",
    "city_ascii": "Kanifing",
    "lat": 13.4464,
    "lng": -16.6744,
    "country": "Gambia, The",
    "iso2": "GM",
    "iso3": "GMB",
    "admin_name": "Banjul",
    "capital": "admin",
    "population": null,
    "id": 1270000000
  },
  {
    "city": "Liquiçá",
    "city_ascii": "Liquica",
    "lat": -8.5935,
    "lng": 125.3273,
    "country": "Timor-Leste",
    "iso2": "TL",
    "iso3": "TLS",
    "admin_name": "Liquiçá",
    "capital": "admin",
    "population": 5152,
    "id": 1626054379
  },
  {
    "city": "Glendale",
    "city_ascii": "Glendale",
    "lat": 39.7036,
    "lng": -104.9352,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 5141,
    "id": 1840028450
  },
  {
    "city": "Turtle Creek",
    "city_ascii": "Turtle Creek",
    "lat": 40.4085,
    "lng": -79.8214,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5138,
    "id": 1840001267
  },
  {
    "city": "Šilalė",
    "city_ascii": "Silale",
    "lat": 55.4917,
    "lng": 22.1778,
    "country": "Lithuania",
    "iso2": "LT",
    "iso3": "LTU",
    "admin_name": "Šilalė",
    "capital": "admin",
    "population": 5114,
    "id": 1440106904
  },
  {
    "city": "Gədəbəy",
    "city_ascii": "Gadabay",
    "lat": 40.5656,
    "lng": 45.8161,
    "country": "Azerbaijan",
    "iso2": "AZ",
    "iso3": "AZE",
    "admin_name": "Gədəbəy",
    "capital": "admin",
    "population": 5049,
    "id": 1031444480
  },
  {
    "city": "Kenneth City",
    "city_ascii": "Kenneth City",
    "lat": 27.8155,
    "lng": -82.7162,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 5121,
    "id": 1840017244
  },
  {
    "city": "South Pasadena",
    "city_ascii": "South Pasadena",
    "lat": 27.7526,
    "lng": -82.7394,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 5095,
    "id": 1840015979
  },
  {
    "city": "Mayfair",
    "city_ascii": "Mayfair",
    "lat": 36.7693,
    "lng": -119.7612,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 5091,
    "id": 1840075970
  },
  {
    "city": "Ilulissat",
    "city_ascii": "Ilulissat",
    "lat": 69.2167,
    "lng": -51.1,
    "country": "Greenland",
    "iso2": "GL",
    "iso3": "GRL",
    "admin_name": "Qaasuitsup",
    "capital": "admin",
    "population": 4670,
    "id": 1304656747
  },
  {
    "city": "Montréal-Ouest",
    "city_ascii": "Montreal-Ouest",
    "lat": 45.4536,
    "lng": -73.6472,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 5085,
    "id": 1124001742
  },
  {
    "city": "Mechanicville",
    "city_ascii": "Mechanicville",
    "lat": 42.9037,
    "lng": -73.6895,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 5037,
    "id": 1840000368
  },
  {
    "city": "Punta Gorda",
    "city_ascii": "Punta Gorda",
    "lat": 16.1,
    "lng": -88.8,
    "country": "Belize",
    "iso2": "BZ",
    "iso3": "BLZ",
    "admin_name": "Toledo",
    "capital": "admin",
    "population": 5026,
    "id": 1084536442
  },
  {
    "city": "Punakha",
    "city_ascii": "Punakha",
    "lat": 27.6167,
    "lng": 89.8667,
    "country": "Bhutan",
    "iso2": "BT",
    "iso3": "BTN",
    "admin_name": "Punakha",
    "capital": "admin",
    "population": 5000,
    "id": 1064171188
  },
  {
    "city": "Aībak",
    "city_ascii": "Aibak",
    "lat": 36.2534,
    "lng": 68.0394,
    "country": "Afghanistan",
    "iso2": "AF",
    "iso3": "AFG",
    "admin_name": "Samangān",
    "capital": "admin",
    "population": 4938,
    "id": 1004564619
  },
  {
    "city": "Bled",
    "city_ascii": "Bled",
    "lat": 46.3667,
    "lng": 14.1167,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Bled",
    "capital": "admin",
    "population": 4929,
    "id": 1705655016
  },
  {
    "city": "Opuwo",
    "city_ascii": "Opuwo",
    "lat": -18.0607,
    "lng": 13.84,
    "country": "Namibia",
    "iso2": "NA",
    "iso3": "NAM",
    "admin_name": "Kunene",
    "capital": "admin",
    "population": 4857,
    "id": 1516958242
  },
  {
    "city": "Kiambu",
    "city_ascii": "Kiambu",
    "lat": -1.1714,
    "lng": 36.8356,
    "country": "Kenya",
    "iso2": "KE",
    "iso3": "KEN",
    "admin_name": "Kiambu",
    "capital": "admin",
    "population": null,
    "id": 1404390868
  },
  {
    "city": "Nieuw Amsterdam",
    "city_ascii": "Nieuw Amsterdam",
    "lat": 5.91,
    "lng": -55.07,
    "country": "Suriname",
    "iso2": "SR",
    "iso3": "SUR",
    "admin_name": "Commewijne",
    "capital": "admin",
    "population": 4935,
    "id": 1740547119
  },
  {
    "city": "Bolama",
    "city_ascii": "Bolama",
    "lat": 11.5776,
    "lng": -15.4742,
    "country": "Guinea-Bissau",
    "iso2": "GW",
    "iso3": "GNB",
    "admin_name": "Bolama/Bijagós",
    "capital": "admin",
    "population": 4819,
    "id": 1624958412
  },
  {
    "city": "Irig",
    "city_ascii": "Irig",
    "lat": 45.1011,
    "lng": 19.8583,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Irig",
    "capital": "admin",
    "population": 4848,
    "id": 1688660485
  },
  {
    "city": "Puerto Lempira",
    "city_ascii": "Puerto Lempira",
    "lat": 15.2653,
    "lng": -83.7744,
    "country": "Honduras",
    "iso2": "HN",
    "iso3": "HND",
    "admin_name": "Gracias a Dios",
    "capital": "admin",
    "population": 4856,
    "id": 1340110055
  },
  {
    "city": "Mauren",
    "city_ascii": "Mauren",
    "lat": 47.2197,
    "lng": 9.5428,
    "country": "Liechtenstein",
    "iso2": "LI",
    "iso3": "LIE",
    "admin_name": "Mauren",
    "capital": "admin",
    "population": 4268,
    "id": 1438767966
  },
  {
    "city": "Klaksvík",
    "city_ascii": "Klaksvik",
    "lat": 62.2375,
    "lng": -6.539,
    "country": "Faroe Islands",
    "iso2": "FO",
    "iso3": "FRO",
    "admin_name": "",
    "capital": "admin",
    "population": 4664,
    "id": 1234149995
  },
  {
    "city": "Bururi",
    "city_ascii": "Bururi",
    "lat": -3.9333,
    "lng": 29.6167,
    "country": "Burundi",
    "iso2": "BI",
    "iso3": "BDI",
    "admin_name": "Bururi",
    "capital": "admin",
    "population": 4478,
    "id": 1108963889
  },
  {
    "city": "Ta Khmau",
    "city_ascii": "Ta Khmau",
    "lat": 11.4833,
    "lng": 104.95,
    "country": "Cambodia",
    "iso2": "KH",
    "iso3": "KHM",
    "admin_name": "Kandal",
    "capital": "admin",
    "population": null,
    "id": 1116571704
  },
  {
    "city": "Čoka",
    "city_ascii": "Coka",
    "lat": 45.9389,
    "lng": 20.1394,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Čoka",
    "capital": "admin",
    "population": 4707,
    "id": 1688200508
  },
  {
    "city": "Béni Mellal",
    "city_ascii": "Beni Mellal",
    "lat": 32.3373,
    "lng": -6.3498,
    "country": "Morocco",
    "iso2": "MA",
    "iso3": "MAR",
    "admin_name": "Béni Mellal-Khénifra",
    "capital": "admin",
    "population": null,
    "id": 1504131639
  },
  {
    "city": "Hưng Yên",
    "city_ascii": "Hung Yen",
    "lat": 20.6464,
    "lng": 106.0511,
    "country": "Vietnam",
    "iso2": "VN",
    "iso3": "VNM",
    "admin_name": "Hưng Yên",
    "capital": "admin",
    "population": null,
    "id": 1704000203
  },
  {
    "city": "Pakruojis",
    "city_ascii": "Pakruojis",
    "lat": 55.9809,
    "lng": 23.8551,
    "country": "Lithuania",
    "iso2": "LT",
    "iso3": "LTU",
    "admin_name": "Pakruojis",
    "capital": "admin",
    "population": 4640,
    "id": 1440570493
  },
  {
    "city": "Bradford",
    "city_ascii": "Bradford",
    "lat": 53.8,
    "lng": -1.75,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Bradford",
    "capital": "",
    "population": 67,
    "id": 1826687116
  },
  {
    "city": "Valka",
    "city_ascii": "Valka",
    "lat": 57.7753,
    "lng": 26.0214,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Valkas Novads",
    "capital": "admin",
    "population": 4573,
    "id": 1428763918
  },
  {
    "city": "Bueng Kan",
    "city_ascii": "Bueng Kan",
    "lat": 18.3669,
    "lng": 103.6552,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Bueng Kan",
    "capital": "admin",
    "population": 4494,
    "id": 1764944006
  },
  {
    "city": "San Ġwann",
    "city_ascii": "San Gwann",
    "lat": 35.9056,
    "lng": 14.4761,
    "country": "Malta",
    "iso2": "MT",
    "iso3": "MLT",
    "admin_name": "San Ġwann",
    "capital": "admin",
    "population": null,
    "id": 1470073144
  },
  {
    "city": "Rumphi",
    "city_ascii": "Rumphi",
    "lat": -11.0153,
    "lng": 33.7722,
    "country": "Malawi",
    "iso2": "MW",
    "iso3": "MWI",
    "admin_name": "Rumphi",
    "capital": "admin",
    "population": null,
    "id": 1454325221
  },
  {
    "city": "Dravograd",
    "city_ascii": "Dravograd",
    "lat": 46.59,
    "lng": 15.018,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Dravograd",
    "capital": "admin",
    "population": 3163,
    "id": 1705130450
  },
  {
    "city": "Valandovo",
    "city_ascii": "Valandovo",
    "lat": 41.3169,
    "lng": 22.5611,
    "country": "Macedonia",
    "iso2": "MK",
    "iso3": "MKD",
    "admin_name": "Valandovo",
    "capital": "admin",
    "population": 4442,
    "id": 1807423601
  },
  {
    "city": "Švenčionys",
    "city_ascii": "Svencionys",
    "lat": 55.1333,
    "lng": 26.1556,
    "country": "Lithuania",
    "iso2": "LT",
    "iso3": "LTU",
    "admin_name": "Švenčionys",
    "capital": "admin",
    "population": 4458,
    "id": 1440170338
  },
  {
    "city": "Trakai",
    "city_ascii": "Trakai",
    "lat": 54.6333,
    "lng": 24.9333,
    "country": "Lithuania",
    "iso2": "LT",
    "iso3": "LTU",
    "admin_name": "Trakai",
    "capital": "admin",
    "population": 4430,
    "id": 1440324561
  },
  {
    "city": "Remich",
    "city_ascii": "Remich",
    "lat": 49.5444,
    "lng": 6.3667,
    "country": "Luxembourg",
    "iso2": "LU",
    "iso3": "LUX",
    "admin_name": "Remich",
    "capital": "admin",
    "population": 3732,
    "id": 1442971283
  },
  {
    "city": "Isulan",
    "city_ascii": "Isulan",
    "lat": 6.6294,
    "lng": 124.6051,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Sultan Kudarat",
    "capital": "admin",
    "population": null,
    "id": 1608085930
  },
  {
    "city": "Black River",
    "city_ascii": "Black River",
    "lat": 18.0264,
    "lng": -77.8487,
    "country": "Jamaica",
    "iso2": "JM",
    "iso3": "JAM",
    "admin_name": "Saint Elizabeth",
    "capital": "admin",
    "population": 4261,
    "id": 1388022504
  },
  {
    "city": "Tecax",
    "city_ascii": "Tecax",
    "lat": 20.2019,
    "lng": -89.2881,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Yucatán",
    "capital": "",
    "population": 50.789,
    "id": 1484782521
  },
  {
    "city": "Aizpute",
    "city_ascii": "Aizpute",
    "lat": 56.7211,
    "lng": 21.6017,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Aizputes Novads",
    "capital": "admin",
    "population": 4237,
    "id": 1428860274
  },
  {
    "city": "Calheta de São Miguel",
    "city_ascii": "Calheta de Sao Miguel",
    "lat": 15.1875,
    "lng": -23.5917,
    "country": "Cabo Verde",
    "iso2": "CV",
    "iso3": "CPV",
    "admin_name": "São Miguel",
    "capital": "admin",
    "population": 4123,
    "id": 1132750609
  },
  {
    "city": "Bensonville",
    "city_ascii": "Bensonville",
    "lat": 6.4456,
    "lng": -10.6097,
    "country": "Liberia",
    "iso2": "LR",
    "iso3": "LBR",
    "admin_name": "Montserrado",
    "capital": "admin",
    "population": 4089,
    "id": 1430964557
  },
  {
    "city": "Al ‘Azīzīyah",
    "city_ascii": "Al `Aziziyah",
    "lat": 32.5308,
    "lng": 13.0211,
    "country": "Libya",
    "iso2": "LY",
    "iso3": "LBY",
    "admin_name": "Al Jafārah",
    "capital": "admin",
    "population": 4000,
    "id": 1434450111
  },
  {
    "city": "Boac",
    "city_ascii": "Boac",
    "lat": 13.4463,
    "lng": 121.84,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Marinduque",
    "capital": "admin",
    "population": null,
    "id": 1608972476
  },
  {
    "city": "Grevenmacher",
    "city_ascii": "Grevenmacher",
    "lat": 49.6747,
    "lng": 6.4419,
    "country": "Luxembourg",
    "iso2": "LU",
    "iso3": "LUX",
    "admin_name": "Grevenmacher",
    "capital": "admin",
    "population": 3958,
    "id": 1442392954
  },
  {
    "city": "Kalvarija",
    "city_ascii": "Kalvarija",
    "lat": 54.4147,
    "lng": 23.2281,
    "country": "Lithuania",
    "iso2": "LT",
    "iso3": "LTU",
    "admin_name": "Kalvarijos",
    "capital": "admin",
    "population": 4100,
    "id": 1440799248
  },
  {
    "city": "San Ġiljan",
    "city_ascii": "San Giljan",
    "lat": 35.9184,
    "lng": 14.4898,
    "country": "Malta",
    "iso2": "MT",
    "iso3": "MLT",
    "admin_name": "San Ġiljan",
    "capital": "admin",
    "population": null,
    "id": 1470707733
  },
  {
    "city": "Gornja Radgona",
    "city_ascii": "Gornja Radgona",
    "lat": 46.681,
    "lng": 15.9883,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Gornja Radgona",
    "capital": "admin",
    "population": 3052,
    "id": 1705643923
  },
  {
    "city": "Tubod",
    "city_ascii": "Tubod",
    "lat": 8.0555,
    "lng": 123.7904,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Lanao del Norte",
    "capital": "admin",
    "population": null,
    "id": 1608442374
  },
  {
    "city": "Mamburao",
    "city_ascii": "Mamburao",
    "lat": 13.2233,
    "lng": 120.596,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Occidental Mindoro",
    "capital": "admin",
    "population": null,
    "id": 1608912012
  },
  {
    "city": "Wabag",
    "city_ascii": "Wabag",
    "lat": -5.4833,
    "lng": 143.7,
    "country": "Papua New Guinea",
    "iso2": "PG",
    "iso3": "PNG",
    "admin_name": "Enga",
    "capital": "admin",
    "population": 3958,
    "id": 1598428400
  },
  {
    "city": "Baldone",
    "city_ascii": "Baldone",
    "lat": 56.7425,
    "lng": 24.3908,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Baldones Novads",
    "capital": "admin",
    "population": 3996,
    "id": 1428735750
  },
  {
    "city": "Lazdijai",
    "city_ascii": "Lazdijai",
    "lat": 54.2319,
    "lng": 23.517,
    "country": "Lithuania",
    "iso2": "LT",
    "iso3": "LTU",
    "admin_name": "Lazdijai",
    "capital": "admin",
    "population": 3985,
    "id": 1440584882
  },
  {
    "city": "Ağdam",
    "city_ascii": "Agdam",
    "lat": 39.9833,
    "lng": 46.9167,
    "country": "Azerbaijan",
    "iso2": "AZ",
    "iso3": "AZE",
    "admin_name": "Ağdam",
    "capital": "admin",
    "population": 3770,
    "id": 1031176858
  },
  {
    "city": "Kärdla",
    "city_ascii": "Kardla",
    "lat": 58.9944,
    "lng": 22.75,
    "country": "Estonia",
    "iso2": "EE",
    "iso3": "EST",
    "admin_name": "Hiiumaa",
    "capital": "admin",
    "population": 3230,
    "id": 1233977488
  },
  {
    "city": "Nabunturan",
    "city_ascii": "Nabunturan",
    "lat": 7.6029,
    "lng": 125.9629,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Compostela Valley",
    "capital": "admin",
    "population": null,
    "id": 1608879246
  },
  {
    "city": "Bugiri",
    "city_ascii": "Bugiri",
    "lat": 0.5714,
    "lng": 33.7417,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Bugiri",
    "capital": "admin",
    "population": null,
    "id": 1800917331
  },
  {
    "city": "Kaberamaido",
    "city_ascii": "Kaberamaido",
    "lat": 1.7389,
    "lng": 33.1594,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Kaberamaido",
    "capital": "admin",
    "population": 3400,
    "id": 1800845749
  },
  {
    "city": "Malita",
    "city_ascii": "Malita",
    "lat": 6.415,
    "lng": 125.6117,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Davao Occidental",
    "capital": "admin",
    "population": null,
    "id": 1608802375
  },
  {
    "city": "Makedonski Brod",
    "city_ascii": "Makedonski Brod",
    "lat": 41.5133,
    "lng": 21.2153,
    "country": "Macedonia",
    "iso2": "MK",
    "iso3": "MKD",
    "admin_name": "Makedonski Brod",
    "capital": "admin",
    "population": 3740,
    "id": 1807831162
  },
  {
    "city": "Janjanbureh",
    "city_ascii": "Janjanbureh",
    "lat": 13.551,
    "lng": -14.767,
    "country": "Gambia, The",
    "iso2": "GM",
    "iso3": "GMB",
    "admin_name": "Central River",
    "capital": "admin",
    "population": 3584,
    "id": 1270430701
  },
  {
    "city": "Cankuzo",
    "city_ascii": "Cankuzo",
    "lat": -3.2194,
    "lng": 30.5528,
    "country": "Burundi",
    "iso2": "BI",
    "iso3": "BDI",
    "admin_name": "Cankuzo",
    "capital": "admin",
    "population": 3624,
    "id": 1108631099
  },
  {
    "city": "Moroto",
    "city_ascii": "Moroto",
    "lat": 2.5403,
    "lng": 34.64,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Moroto",
    "capital": "admin",
    "population": 371,
    "id": 1800980026
  },
  {
    "city": "Gambēla",
    "city_ascii": "Gambela",
    "lat": 8.25,
    "lng": 34.5833,
    "country": "Ethiopia",
    "iso2": "ET",
    "iso3": "ETH",
    "admin_name": "Gambēla Hizboch",
    "capital": "admin",
    "population": null,
    "id": 1231698892
  },
  {
    "city": "Massenya",
    "city_ascii": "Massenya",
    "lat": 11.4028,
    "lng": 16.17,
    "country": "Chad",
    "iso2": "TD",
    "iso3": "TCD",
    "admin_name": "Chari-Baguirmi",
    "capital": "admin",
    "population": 3680,
    "id": 1148928302
  },
  {
    "city": "Kandava",
    "city_ascii": "Kandava",
    "lat": 57.0364,
    "lng": 22.7761,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Kandavas Novads",
    "capital": "admin",
    "population": 3651,
    "id": 1428964284
  },
  {
    "city": "Grobiņa",
    "city_ascii": "Grobina",
    "lat": 56.5358,
    "lng": 21.1656,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Grobiņas Novads",
    "capital": "admin",
    "population": 3631,
    "id": 1428780265
  },
  {
    "city": "Vittoriosa",
    "city_ascii": "Vittoriosa",
    "lat": 35.8922,
    "lng": 14.5183,
    "country": "Malta",
    "iso2": "MT",
    "iso3": "MLT",
    "admin_name": "Birgu",
    "capital": "admin",
    "population": null,
    "id": 1470805169
  },
  {
    "city": "Ankaran",
    "city_ascii": "Ankaran",
    "lat": 45.5793,
    "lng": 13.7379,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Ankaran",
    "capital": "admin",
    "population": 3209,
    "id": 1705521676
  },
  {
    "city": "Balzan",
    "city_ascii": "Balzan",
    "lat": 35.9003,
    "lng": 14.455,
    "country": "Malta",
    "iso2": "MT",
    "iso3": "MLT",
    "admin_name": "Balzan",
    "capital": "admin",
    "population": null,
    "id": 1470875655
  },
  {
    "city": "Thinadhoo",
    "city_ascii": "Thinadhoo",
    "lat": 0.5303,
    "lng": 72.9967,
    "country": "Maldives",
    "iso2": "MV",
    "iso3": "MDV",
    "admin_name": "Huvadhu Atholhu Dhekunuburi",
    "capital": "admin",
    "population": null,
    "id": 1462781201
  },
  {
    "city": "Funadhoo",
    "city_ascii": "Funadhoo",
    "lat": 6.1509,
    "lng": 73.2901,
    "country": "Maldives",
    "iso2": "MV",
    "iso3": "MDV",
    "admin_name": "Miladhunmadulu Uthuruburi",
    "capital": "admin",
    "population": null,
    "id": 1462657112
  },
  {
    "city": "Cantemir",
    "city_ascii": "Cantemir",
    "lat": 46.2781,
    "lng": 28.2008,
    "country": "Moldova",
    "iso2": "MD",
    "iso3": "MDA",
    "admin_name": "Cantemir",
    "capital": "admin",
    "population": 3429,
    "id": 1498973808
  },
  {
    "city": "Bangued",
    "city_ascii": "Bangued",
    "lat": 17.5982,
    "lng": 120.6155,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Abra",
    "capital": "admin",
    "population": null,
    "id": 1608442037
  },
  {
    "city": "Alibunar",
    "city_ascii": "Alibunar",
    "lat": 45.0808,
    "lng": 20.9658,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Alibunar",
    "capital": "admin",
    "population": 3431,
    "id": 1688248362
  },
  {
    "city": "Hartford",
    "city_ascii": "Hartford",
    "lat": 43.621,
    "lng": -96.9424,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Dakota",
    "capital": "admin",
    "population": 3354,
    "id": 1840002653
  },
  {
    "city": "Rietavas",
    "city_ascii": "Rietavas",
    "lat": 55.725,
    "lng": 21.9278,
    "country": "Lithuania",
    "iso2": "LT",
    "iso3": "LTU",
    "admin_name": "Rietavo",
    "capital": "admin",
    "population": 3308,
    "id": 1440780088
  },
  {
    "city": "Mambajao",
    "city_ascii": "Mambajao",
    "lat": 9.2504,
    "lng": 124.7156,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Camiguin",
    "capital": "admin",
    "population": null,
    "id": 1608281273
  },
  {
    "city": "Groningen",
    "city_ascii": "Groningen",
    "lat": 5.797,
    "lng": -55.481,
    "country": "Suriname",
    "iso2": "SR",
    "iso3": "SUR",
    "admin_name": "Saramacca",
    "capital": "admin",
    "population": 3216,
    "id": 1740072928
  },
  {
    "city": "Pehčevo",
    "city_ascii": "Pehcevo",
    "lat": 41.7592,
    "lng": 22.8906,
    "country": "Macedonia",
    "iso2": "MK",
    "iso3": "MKD",
    "admin_name": "Pehčevo",
    "capital": "admin",
    "population": 3237,
    "id": 1807666030
  },
  {
    "city": "Qaqortoq",
    "city_ascii": "Qaqortoq",
    "lat": 60.7167,
    "lng": -46.0333,
    "country": "Greenland",
    "iso2": "GL",
    "iso3": "GRL",
    "admin_name": "Kujalleq",
    "capital": "admin",
    "population": 3093,
    "id": 1304746917
  },
  {
    "city": "Louang Namtha",
    "city_ascii": "Louang Namtha",
    "lat": 20.95,
    "lng": 101.4167,
    "country": "Laos",
    "iso2": "LA",
    "iso3": "LAO",
    "admin_name": "Louangnamtha",
    "capital": "admin",
    "population": 3225,
    "id": 1418920136
  },
  {
    "city": "Kaabong",
    "city_ascii": "Kaabong",
    "lat": 3.5204,
    "lng": 34.12,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Kaabong",
    "capital": "admin",
    "population": 1137,
    "id": 1800299361
  },
  {
    "city": "Kulhudhuffushi",
    "city_ascii": "Kulhudhuffushi",
    "lat": 6.6223,
    "lng": 73.0702,
    "country": "Maldives",
    "iso2": "MV",
    "iso3": "MDV",
    "admin_name": "Thiladhunmathee Dhekunuburi",
    "capital": "admin",
    "population": null,
    "id": 1462969197
  },
  {
    "city": "Naval",
    "city_ascii": "Naval",
    "lat": 11.5612,
    "lng": 124.3952,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Biliran",
    "capital": "admin",
    "population": null,
    "id": 1608929611
  },
  {
    "city": "Pļaviņas",
    "city_ascii": "Plavinas",
    "lat": 56.6161,
    "lng": 25.7217,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Pļaviņu Novads",
    "capital": "admin",
    "population": 3151,
    "id": 1428245037
  },
  {
    "city": "Probištip",
    "city_ascii": "Probistip",
    "lat": 41.9936,
    "lng": 22.1767,
    "country": "Macedonia",
    "iso2": "MK",
    "iso3": "MKD",
    "admin_name": "Probištip",
    "capital": "admin",
    "population": 3110,
    "id": 1807592969
  },
  {
    "city": "Mullingar",
    "city_ascii": "Mullingar",
    "lat": 53.5333,
    "lng": -7.35,
    "country": "Ireland",
    "iso2": "IE",
    "iso3": "IRL",
    "admin_name": "Westmeath",
    "capital": "admin",
    "population": null,
    "id": 1372969205
  },
  {
    "city": "Aasiaat",
    "city_ascii": "Aasiaat",
    "lat": 68.7097,
    "lng": -52.8694,
    "country": "Greenland",
    "iso2": "GL",
    "iso3": "GRL",
    "admin_name": "Qaasuitsup",
    "capital": "admin",
    "population": 3134,
    "id": 1304194714
  },
  {
    "city": "Porto Inglês",
    "city_ascii": "Porto Ingles",
    "lat": 15.1375,
    "lng": -23.2083,
    "country": "Cabo Verde",
    "iso2": "CV",
    "iso3": "CPV",
    "admin_name": "Maio",
    "capital": "admin",
    "population": 3056,
    "id": 1132346974
  },
  {
    "city": "Cambridge",
    "city_ascii": "Cambridge",
    "lat": 52.2053,
    "lng": 0.1192,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Cambridgeshire",
    "capital": "",
    "population": 66,
    "id": 1826229713
  },
  {
    "city": "Lethem",
    "city_ascii": "Lethem",
    "lat": 3.3833,
    "lng": -59.8,
    "country": "Guyana",
    "iso2": "GY",
    "iso3": "GUY",
    "admin_name": "Upper Takutu-Upper Essequibo",
    "capital": "admin",
    "population": 3000,
    "id": 1328749920
  },
  {
    "city": "Dhihdhoo",
    "city_ascii": "Dhihdhoo",
    "lat": 6.8874,
    "lng": 73.114,
    "country": "Maldives",
    "iso2": "MV",
    "iso3": "MDV",
    "admin_name": "Thiladhunmathee Uthuruburi",
    "capital": "admin",
    "population": null,
    "id": 1462109783
  },
  {
    "city": "Balaka",
    "city_ascii": "Balaka",
    "lat": -14.9889,
    "lng": 34.9591,
    "country": "Malawi",
    "iso2": "MW",
    "iso3": "MWI",
    "admin_name": "Balaka",
    "capital": "admin",
    "population": null,
    "id": 1454732693
  },
  {
    "city": "Mosta",
    "city_ascii": "Mosta",
    "lat": 35.9092,
    "lng": 14.4256,
    "country": "Malta",
    "iso2": "MT",
    "iso3": "MLT",
    "admin_name": "Mosta",
    "capital": "admin",
    "population": null,
    "id": 1470176983
  },
  {
    "city": "Mabaruma",
    "city_ascii": "Mabaruma",
    "lat": 8.2,
    "lng": -59.7833,
    "country": "Guyana",
    "iso2": "GY",
    "iso3": "GUY",
    "admin_name": "Barima-Waini",
    "capital": "admin",
    "population": 2972,
    "id": 1328079873
  },
  {
    "city": "Mandalgovĭ",
    "city_ascii": "Mandalgovi",
    "lat": 45.7667,
    "lng": 106.2708,
    "country": "Mongolia",
    "iso2": "MN",
    "iso3": "MNG",
    "admin_name": "Dundgovĭ",
    "capital": "admin",
    "population": 2984,
    "id": 1496666560
  },
  {
    "city": "Viļāni",
    "city_ascii": "Vilani",
    "lat": 56.5528,
    "lng": 26.9253,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Viļānu Novads",
    "capital": "admin",
    "population": 2938,
    "id": 1428636034
  },
  {
    "city": "Saulkrasti",
    "city_ascii": "Saulkrasti",
    "lat": 57.2636,
    "lng": 24.4164,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Saulkrastu Novads",
    "capital": "admin",
    "population": 2813,
    "id": 1428934591
  },
  {
    "city": "Lendava",
    "city_ascii": "Lendava",
    "lat": 46.5662,
    "lng": 16.4499,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Lendava",
    "capital": "admin",
    "population": 2919,
    "id": 1705653525
  },
  {
    "city": "Kudahuvadhoo",
    "city_ascii": "Kudahuvadhoo",
    "lat": 2.6717,
    "lng": 72.8936,
    "country": "Maldives",
    "iso2": "MV",
    "iso3": "MDV",
    "admin_name": "Nilandhe Atholhu Dhekunuburi",
    "capital": "admin",
    "population": null,
    "id": 1462282241
  },
  {
    "city": "Kapchorwa",
    "city_ascii": "Kapchorwa",
    "lat": 1.3965,
    "lng": 34.4509,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Kapchorwa",
    "capital": "admin",
    "population": null,
    "id": 1800170407
  },
  {
    "city": "São Domingos",
    "city_ascii": "Sao Domingos",
    "lat": 15.025,
    "lng": -23.5625,
    "country": "Cabo Verde",
    "iso2": "CV",
    "iso3": "CPV",
    "admin_name": "São Domingos",
    "capital": "admin",
    "population": 2818,
    "id": 1132246964
  },
  {
    "city": "Rūjiena",
    "city_ascii": "Rujiena",
    "lat": 57.8969,
    "lng": 25.3261,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Rūjienas Novads",
    "capital": "admin",
    "population": 2887,
    "id": 1428387860
  },
  {
    "city": "Arima",
    "city_ascii": "Arima",
    "lat": 10.6374,
    "lng": -61.2823,
    "country": "Trinidad And Tobago",
    "iso2": "TT",
    "iso3": "TTO",
    "admin_name": "Arima",
    "capital": "admin",
    "population": null,
    "id": 1780753308
  },
  {
    "city": "Novo Selo",
    "city_ascii": "Novo Selo",
    "lat": 41.4128,
    "lng": 22.88,
    "country": "Macedonia",
    "iso2": "MK",
    "iso3": "MKD",
    "admin_name": "Novo Selo",
    "capital": "admin",
    "population": 2829,
    "id": 1807743098
  },
  {
    "city": "Rasdhoo",
    "city_ascii": "Rasdhoo",
    "lat": 4.2631,
    "lng": 72.9919,
    "country": "Maldives",
    "iso2": "MV",
    "iso3": "MDV",
    "admin_name": "Ari Atholhu Uthuruburi",
    "capital": "admin",
    "population": null,
    "id": 1462000003
  },
  {
    "city": "Kerewan",
    "city_ascii": "Kerewan",
    "lat": 13.494,
    "lng": -16.095,
    "country": "Gambia, The",
    "iso2": "GM",
    "iso3": "GMB",
    "admin_name": "North Bank",
    "capital": "admin",
    "population": 2751,
    "id": 1270976260
  },
  {
    "city": "Koknese",
    "city_ascii": "Koknese",
    "lat": 56.65,
    "lng": 25.4333,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Kokneses Novads",
    "capital": "admin",
    "population": 2818,
    "id": 1428876472
  },
  {
    "city": "Kolonia",
    "city_ascii": "Kolonia",
    "lat": 6.9639,
    "lng": 158.2083,
    "country": "Micronesia, Federated States Of",
    "iso2": "FM",
    "iso3": "FSM",
    "admin_name": "Pohnpei",
    "capital": "admin",
    "population": null,
    "id": 1583881416
  },
  {
    "city": "Żabbar",
    "city_ascii": "Zabbar",
    "lat": 35.8761,
    "lng": 14.535,
    "country": "Malta",
    "iso2": "MT",
    "iso3": "MLT",
    "admin_name": "Żabbar",
    "capital": "admin",
    "population": null,
    "id": 1470317969
  },
  {
    "city": "Brocēni",
    "city_ascii": "Broceni",
    "lat": 56.6833,
    "lng": 22.5664,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Brocēnu Novads",
    "capital": "admin",
    "population": 2779,
    "id": 1428026538
  },
  {
    "city": "Al Wakrah",
    "city_ascii": "Al Wakrah",
    "lat": 25.18,
    "lng": 51.61,
    "country": "Qatar",
    "iso2": "QA",
    "iso3": "QAT",
    "admin_name": "Al Wakrah",
    "capital": "admin",
    "population": null,
    "id": 1634261569
  },
  {
    "city": "Tallaght",
    "city_ascii": "Tallaght",
    "lat": 53.2878,
    "lng": -6.3411,
    "country": "Ireland",
    "iso2": "IE",
    "iso3": "IRL",
    "admin_name": "South Dublin",
    "capital": "admin",
    "population": null,
    "id": 1372547040
  },
  {
    "city": "Barclayville",
    "city_ascii": "Barclayville",
    "lat": 4.8,
    "lng": -8.1667,
    "country": "Liberia",
    "iso2": "LR",
    "iso3": "LBR",
    "admin_name": "Grand Kru",
    "capital": "admin",
    "population": 2733,
    "id": 1430129873
  },
  {
    "city": "Salacgrīva",
    "city_ascii": "Salacgriva",
    "lat": 57.7494,
    "lng": 24.3586,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Salacgrīvas Novads",
    "capital": "admin",
    "population": 2729,
    "id": 1428301933
  },
  {
    "city": "Floriana",
    "city_ascii": "Floriana",
    "lat": 35.8958,
    "lng": 14.5083,
    "country": "Malta",
    "iso2": "MT",
    "iso3": "MLT",
    "admin_name": "Floriana",
    "capital": "admin",
    "population": null,
    "id": 1470089344
  },
  {
    "city": "Swieqi",
    "city_ascii": "Swieqi",
    "lat": 35.9225,
    "lng": 14.48,
    "country": "Malta",
    "iso2": "MT",
    "iso3": "MLT",
    "admin_name": "Swieqi",
    "capital": "admin",
    "population": null,
    "id": 1470279940
  },
  {
    "city": "Škofja Loka",
    "city_ascii": "Skofja Loka",
    "lat": 46.1655,
    "lng": 14.3064,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Škofja Loka",
    "capital": "admin",
    "population": null,
    "id": 1705743622
  },
  {
    "city": "Iba",
    "city_ascii": "Iba",
    "lat": 15.3276,
    "lng": 119.978,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Zambales",
    "capital": "admin",
    "population": null,
    "id": 1608986957
  },
  {
    "city": "Kllokot",
    "city_ascii": "Kllokot",
    "lat": 42.3667,
    "lng": 21.3833,
    "country": "Kosovo",
    "iso2": "XK",
    "iso3": "XKS",
    "admin_name": "Kllokot",
    "capital": "admin",
    "population": 2556,
    "id": 1901445768
  },
  {
    "city": "Cestos City",
    "city_ascii": "Cestos City",
    "lat": 5.4697,
    "lng": -9.5817,
    "country": "Liberia",
    "iso2": "LR",
    "iso3": "LBR",
    "admin_name": "River Cess",
    "capital": "admin",
    "population": 2578,
    "id": 1430708587
  },
  {
    "city": "Bundibugyo",
    "city_ascii": "Bundibugyo",
    "lat": 0.7085,
    "lng": 30.0634,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Bundibugyo",
    "capital": "admin",
    "population": null,
    "id": 1800500326
  },
  {
    "city": "Demir Hisar",
    "city_ascii": "Demir Hisar",
    "lat": 41.2208,
    "lng": 21.2031,
    "country": "Macedonia",
    "iso2": "MK",
    "iso3": "MKD",
    "admin_name": "Demir Hisar",
    "capital": "admin",
    "population": 2593,
    "id": 1807908017
  },
  {
    "city": "Garoowe",
    "city_ascii": "Garoowe",
    "lat": 8.4,
    "lng": 48.4833,
    "country": "Somalia",
    "iso2": "SO",
    "iso3": "SOM",
    "admin_name": "Nugaal",
    "capital": "admin",
    "population": 2568,
    "id": 1706512367
  },
  {
    "city": "Demir Kapija",
    "city_ascii": "Demir Kapija",
    "lat": 41.4114,
    "lng": 22.2422,
    "country": "Macedonia",
    "iso2": "MK",
    "iso3": "MKD",
    "admin_name": "Demir Kapija",
    "capital": "admin",
    "population": 2547,
    "id": 1807182004
  },
  {
    "city": "Domžale",
    "city_ascii": "Domzale",
    "lat": 46.1333,
    "lng": 14.6,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Domžale",
    "capital": "admin",
    "population": null,
    "id": 1705822721
  },
  {
    "city": "Lingayen",
    "city_ascii": "Lingayen",
    "lat": 16.0218,
    "lng": 120.2319,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Pangasinan",
    "capital": "admin",
    "population": null,
    "id": 1608464071
  },
  {
    "city": "Fuerte Olimpo",
    "city_ascii": "Fuerte Olimpo",
    "lat": -21.0696,
    "lng": -57.9,
    "country": "Paraguay",
    "iso2": "PY",
    "iso3": "PRY",
    "admin_name": "Alto Paraguay",
    "capital": "admin",
    "population": 2475,
    "id": 1600444658
  },
  {
    "city": "Birštonas",
    "city_ascii": "Birstonas",
    "lat": 54.6056,
    "lng": 24.0292,
    "country": "Lithuania",
    "iso2": "LT",
    "iso3": "LTU",
    "admin_name": "Birštono",
    "capital": "admin",
    "population": 2422,
    "id": 1440134144
  },
  {
    "city": "Akmenė",
    "city_ascii": "Akmene",
    "lat": 56.3167,
    "lng": 22.9,
    "country": "Lithuania",
    "iso2": "LT",
    "iso3": "LTU",
    "admin_name": "Akmenė",
    "capital": "admin",
    "population": 2429,
    "id": 1440240618
  },
  {
    "city": "Banlung",
    "city_ascii": "Banlung",
    "lat": 13.7394,
    "lng": 106.9873,
    "country": "Cambodia",
    "iso2": "KH",
    "iso3": "KHM",
    "admin_name": "Ratanakiri",
    "capital": "admin",
    "population": null,
    "id": 1116605438
  },
  {
    "city": "Phủ Lý",
    "city_ascii": "Phu Ly",
    "lat": 20.5453,
    "lng": 105.9122,
    "country": "Vietnam",
    "iso2": "VN",
    "iso3": "VNM",
    "admin_name": "Hà Nam",
    "capital": "admin",
    "population": null,
    "id": 1704841896
  },
  {
    "city": "Basse-Terre",
    "city_ascii": "Basse-Terre",
    "lat": 16,
    "lng": -61.7167,
    "country": "Guadeloupe",
    "iso2": "GP",
    "iso3": "GLP",
    "admin_name": "",
    "capital": "admin",
    "population": null,
    "id": 1312938008
  },
  {
    "city": "Nida",
    "city_ascii": "Nida",
    "lat": 55.304,
    "lng": 21.0053,
    "country": "Lithuania",
    "iso2": "LT",
    "iso3": "LTU",
    "admin_name": "Neringa",
    "capital": "admin",
    "population": 2385,
    "id": 1440271703
  },
  {
    "city": "Wicklow",
    "city_ascii": "Wicklow",
    "lat": 52.975,
    "lng": -6.0494,
    "country": "Ireland",
    "iso2": "IE",
    "iso3": "IRL",
    "admin_name": "Wicklow",
    "capital": "admin",
    "population": null,
    "id": 1372662642
  },
  {
    "city": "Ilūkste",
    "city_ascii": "Ilukste",
    "lat": 55.9781,
    "lng": 26.2967,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Ilūkstes Novads",
    "capital": "admin",
    "population": 2365,
    "id": 1428832647
  },
  {
    "city": "Cospicua",
    "city_ascii": "Cospicua",
    "lat": 35.8856,
    "lng": 14.5275,
    "country": "Malta",
    "iso2": "MT",
    "iso3": "MLT",
    "admin_name": "Bormla",
    "capital": "admin",
    "population": null,
    "id": 1470541461
  },
  {
    "city": "Kalkara",
    "city_ascii": "Kalkara",
    "lat": 35.8892,
    "lng": 14.5328,
    "country": "Malta",
    "iso2": "MT",
    "iso3": "MLT",
    "admin_name": "Kalkara",
    "capital": "admin",
    "population": null,
    "id": 1470563392
  },
  {
    "city": "Auce",
    "city_ascii": "Auce",
    "lat": 56.4611,
    "lng": 22.8942,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Auces Novads",
    "capital": "admin",
    "population": 2343,
    "id": 1428962592
  },
  {
    "city": "Romblon",
    "city_ascii": "Romblon",
    "lat": 12.5751,
    "lng": 122.2708,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Romblon",
    "capital": "admin",
    "population": null,
    "id": 1608428749
  },
  {
    "city": "Ravne na Koroškem",
    "city_ascii": "Ravne na Koroskem",
    "lat": 46.5437,
    "lng": 14.9642,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Ravne na Koroškem",
    "capital": "admin",
    "population": null,
    "id": 1705757622
  },
  {
    "city": "Thulusdhoo",
    "city_ascii": "Thulusdhoo",
    "lat": 4.3742,
    "lng": 73.6517,
    "country": "Maldives",
    "iso2": "MV",
    "iso3": "MDV",
    "admin_name": "Maale Atholhu",
    "capital": "admin",
    "population": null,
    "id": 1462000004
  },
  {
    "city": "Clonmel",
    "city_ascii": "Clonmel",
    "lat": 52.355,
    "lng": -7.7039,
    "country": "Ireland",
    "iso2": "IE",
    "iso3": "IRL",
    "admin_name": "Tipperary",
    "capital": "admin",
    "population": null,
    "id": 1372974181
  },
  {
    "city": "Wexford",
    "city_ascii": "Wexford",
    "lat": 52.3342,
    "lng": -6.4575,
    "country": "Ireland",
    "iso2": "IE",
    "iso3": "IRL",
    "admin_name": "Wexford",
    "capital": "admin",
    "population": null,
    "id": 1372349451
  },
  {
    "city": "Naklo",
    "city_ascii": "Naklo",
    "lat": 46.2749,
    "lng": 14.3176,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Naklo",
    "capital": "admin",
    "population": 1776,
    "id": 1705637872
  },
  {
    "city": "Marsaskala",
    "city_ascii": "Marsaskala",
    "lat": 35.8669,
    "lng": 14.5619,
    "country": "Malta",
    "iso2": "MT",
    "iso3": "MLT",
    "admin_name": "Marsaskala",
    "capital": "admin",
    "population": null,
    "id": 1470808916
  },
  {
    "city": "Marka",
    "city_ascii": "Marka",
    "lat": 1.7156,
    "lng": 44.7703,
    "country": "Somalia",
    "iso2": "SO",
    "iso3": "SOM",
    "admin_name": "Shabeellaha Hoose",
    "capital": "admin",
    "population": 1958,
    "id": 1706165964
  },
  {
    "city": "Viligili",
    "city_ascii": "Viligili",
    "lat": 0.7539,
    "lng": 73.4353,
    "country": "Maldives",
    "iso2": "MV",
    "iso3": "MDV",
    "admin_name": "Huvadhu Atholhu Uthuruburi",
    "capital": "admin",
    "population": null,
    "id": 1462034411
  },
  {
    "city": "Cidade Velha",
    "city_ascii": "Cidade Velha",
    "lat": 14.9167,
    "lng": -23.6042,
    "country": "Cabo Verde",
    "iso2": "CV",
    "iso3": "CPV",
    "admin_name": "Ribeira Grande de Santiago",
    "capital": "admin",
    "population": 2148,
    "id": 1132073400
  },
  {
    "city": "Oshakati",
    "city_ascii": "Oshakati",
    "lat": -17.8,
    "lng": 15.6833,
    "country": "Namibia",
    "iso2": "NA",
    "iso3": "NAM",
    "admin_name": "Oshana",
    "capital": "admin",
    "population": null,
    "id": 1516866759
  },
  {
    "city": "Priekule",
    "city_ascii": "Priekule",
    "lat": 56.4472,
    "lng": 21.5933,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Priekules Novads",
    "capital": "admin",
    "population": 2167,
    "id": 1428340962
  },
  {
    "city": "Ķegums",
    "city_ascii": "Kegums",
    "lat": 56.7444,
    "lng": 24.7214,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Ķeguma Novads",
    "capital": "admin",
    "population": 2159,
    "id": 1428359846
  },
  {
    "city": "Alabel",
    "city_ascii": "Alabel",
    "lat": 6.1018,
    "lng": 125.2905,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Sarangani",
    "capital": "admin",
    "population": null,
    "id": 1608579431
  },
  {
    "city": "Chiradzulu",
    "city_ascii": "Chiradzulu",
    "lat": -15.7167,
    "lng": 35.2,
    "country": "Malawi",
    "iso2": "MW",
    "iso3": "MWI",
    "admin_name": "Chiradzulu",
    "capital": "admin",
    "population": 1580,
    "id": 1454413713
  },
  {
    "city": "Lija",
    "city_ascii": "Lija",
    "lat": 35.9006,
    "lng": 14.4464,
    "country": "Malta",
    "iso2": "MT",
    "iso3": "MLT",
    "admin_name": "Lija",
    "capital": "admin",
    "population": null,
    "id": 1470862409
  },
  {
    "city": "Dagda",
    "city_ascii": "Dagda",
    "lat": 56.0947,
    "lng": 27.5369,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Dagdas Novads",
    "capital": "admin",
    "population": 2122,
    "id": 1428145757
  },
  {
    "city": "Victoria",
    "city_ascii": "Victoria",
    "lat": 36.0444,
    "lng": 14.2397,
    "country": "Malta",
    "iso2": "MT",
    "iso3": "MLT",
    "admin_name": "Rabat (Ghawdex)",
    "capital": "admin",
    "population": null,
    "id": 1470128499
  },
  {
    "city": "Bariadi",
    "city_ascii": "Bariadi",
    "lat": -2.8,
    "lng": 33.9833,
    "country": "Tanzania",
    "iso2": "TZ",
    "iso3": "TZA",
    "admin_name": "Simiyu",
    "capital": "admin",
    "population": null,
    "id": 1834546025
  },
  {
    "city": "Onverwacht",
    "city_ascii": "Onverwacht",
    "lat": 5.6,
    "lng": -55.2,
    "country": "Suriname",
    "iso2": "SR",
    "iso3": "SUR",
    "admin_name": "Para",
    "capital": "admin",
    "population": 2105,
    "id": 1740007571
  },
  {
    "city": "Same",
    "city_ascii": "Same",
    "lat": -9,
    "lng": 125.65,
    "country": "Timor-Leste",
    "iso2": "TL",
    "iso3": "TLS",
    "admin_name": "Manufahi",
    "capital": "admin",
    "population": 2100,
    "id": 1626402267
  },
  {
    "city": "Hoima",
    "city_ascii": "Hoima",
    "lat": 1.4356,
    "lng": 31.3436,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Hoima",
    "capital": "admin",
    "population": null,
    "id": 1800806239
  },
  {
    "city": "Baler",
    "city_ascii": "Baler",
    "lat": 15.7589,
    "lng": 121.5607,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Aurora",
    "capital": "admin",
    "population": null,
    "id": 1608495530
  },
  {
    "city": "Żejtun",
    "city_ascii": "Zejtun",
    "lat": 35.8558,
    "lng": 14.5331,
    "country": "Malta",
    "iso2": "MT",
    "iso3": "MLT",
    "admin_name": "Żejtun",
    "capital": "admin",
    "population": null,
    "id": 1470320205
  },
  {
    "city": "Geita",
    "city_ascii": "Geita",
    "lat": -2.8714,
    "lng": 32.2294,
    "country": "Tanzania",
    "iso2": "TZ",
    "iso3": "TZA",
    "admin_name": "Geita",
    "capital": "admin",
    "population": 1536,
    "id": 1834469543
  },
  {
    "city": "Ennis",
    "city_ascii": "Ennis",
    "lat": 52.8463,
    "lng": -8.9807,
    "country": "Ireland",
    "iso2": "IE",
    "iso3": "IRL",
    "admin_name": "Clare",
    "capital": "admin",
    "population": null,
    "id": 1372255646
  },
  {
    "city": "Ambrolauri",
    "city_ascii": "Ambrolauri",
    "lat": 42.5167,
    "lng": 43.15,
    "country": "Georgia",
    "iso2": "GE",
    "iso3": "GEO",
    "admin_name": "Rach’a-Lechkhumi da Kvemo Svaneti",
    "capital": "admin",
    "population": 2047,
    "id": 1268370586
  },
  {
    "city": "Kabarnet",
    "city_ascii": "Kabarnet",
    "lat": 0.4919,
    "lng": 35.743,
    "country": "Kenya",
    "iso2": "KE",
    "iso3": "KEN",
    "admin_name": "Baringo",
    "capital": "admin",
    "population": null,
    "id": 1404620707
  },
  {
    "city": "Jagodina",
    "city_ascii": "Jagodina",
    "lat": 43.975,
    "lng": 21.2564,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Jagodina",
    "capital": "admin",
    "population": null,
    "id": 1688452613
  },
  {
    "city": "Mukono",
    "city_ascii": "Mukono",
    "lat": 0.3533,
    "lng": 32.7553,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Mukono",
    "capital": "admin",
    "population": null,
    "id": 1800864322
  },
  {
    "city": "Kārsava",
    "city_ascii": "Karsava",
    "lat": 56.7867,
    "lng": 27.6739,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Kārsavas Novads",
    "capital": "admin",
    "population": 2003,
    "id": 1428849607
  },
  {
    "city": "Skrunda",
    "city_ascii": "Skrunda",
    "lat": 56.6756,
    "lng": 22.0164,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Skrundas Novads",
    "capital": "admin",
    "population": 1970,
    "id": 1428371210
  },
  {
    "city": "Migori",
    "city_ascii": "Migori",
    "lat": -1.0634,
    "lng": 34.4731,
    "country": "Kenya",
    "iso2": "KE",
    "iso3": "KEN",
    "admin_name": "Migori",
    "capital": "admin",
    "population": null,
    "id": 1404478510
  },
  {
    "city": "Kerugoya",
    "city_ascii": "Kerugoya",
    "lat": -0.4989,
    "lng": 37.2803,
    "country": "Kenya",
    "iso2": "KE",
    "iso3": "KEN",
    "admin_name": "Kirinyaga",
    "capital": "admin",
    "population": null,
    "id": 1404597807
  },
  {
    "city": "Siquijor",
    "city_ascii": "Siquijor",
    "lat": 9.2142,
    "lng": 123.515,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Siquijor",
    "capital": "admin",
    "population": null,
    "id": 1608692949
  },
  {
    "city": "Yumbe",
    "city_ascii": "Yumbe",
    "lat": 3.4651,
    "lng": 31.2469,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Yumbe",
    "capital": "admin",
    "population": null,
    "id": 1800297508
  },
  {
    "city": "Fontana",
    "city_ascii": "Fontana",
    "lat": 36.0375,
    "lng": 14.2361,
    "country": "Malta",
    "iso2": "MT",
    "iso3": "MLT",
    "admin_name": "Fontana",
    "capital": "admin",
    "population": null,
    "id": 1470427419
  },
  {
    "city": "Jaunjelgava",
    "city_ascii": "Jaunjelgava",
    "lat": 56.6175,
    "lng": 25.0814,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Jaunjelgavas Novads",
    "capital": "admin",
    "population": 1890,
    "id": 1428755180
  },
  {
    "city": "Paola",
    "city_ascii": "Paola",
    "lat": 35.8731,
    "lng": 14.4989,
    "country": "Malta",
    "iso2": "MT",
    "iso3": "MLT",
    "admin_name": "Paola",
    "capital": "admin",
    "population": null,
    "id": 1470416994
  },
  {
    "city": "Sihanoukville",
    "city_ascii": "Sihanoukville",
    "lat": 10.6333,
    "lng": 103.5,
    "country": "Cambodia",
    "iso2": "KH",
    "iso3": "KHM",
    "admin_name": "Sihanoukville",
    "capital": "admin",
    "population": null,
    "id": 1116098692
  },
  {
    "city": "Buchanan",
    "city_ascii": "Buchanan",
    "lat": 5.8833,
    "lng": -10.05,
    "country": "Liberia",
    "iso2": "LR",
    "iso3": "LBR",
    "admin_name": "Grand Bassa",
    "capital": "admin",
    "population": null,
    "id": 1430478203
  },
  {
    "city": "Varakļāni",
    "city_ascii": "Varaklani",
    "lat": 56.6078,
    "lng": 26.755,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Varakļānu Novads",
    "capital": "admin",
    "population": 1853,
    "id": 1428435689
  },
  {
    "city": "Xgħajra",
    "city_ascii": "Xghajra",
    "lat": 35.8856,
    "lng": 14.5475,
    "country": "Malta",
    "iso2": "MT",
    "iso3": "MLT",
    "admin_name": "Xgħajra",
    "capital": "admin",
    "population": null,
    "id": 1470535936
  },
  {
    "city": "La Palma",
    "city_ascii": "La Palma",
    "lat": 8.3982,
    "lng": -78.1402,
    "country": "Panama",
    "iso2": "PA",
    "iso3": "PAN",
    "admin_name": "Darién",
    "capital": "admin",
    "population": 1845,
    "id": 1591177082
  },
  {
    "city": "Hithadhoo",
    "city_ascii": "Hithadhoo",
    "lat": -0.6,
    "lng": 73.0833,
    "country": "Maldives",
    "iso2": "MV",
    "iso3": "MDV",
    "admin_name": "Addu Atholhu",
    "capital": "admin",
    "population": null,
    "id": 1462406280
  },
  {
    "city": "Iklin",
    "city_ascii": "Iklin",
    "lat": 35.9092,
    "lng": 14.4522,
    "country": "Malta",
    "iso2": "MT",
    "iso3": "MLT",
    "admin_name": "Iklin",
    "capital": "admin",
    "population": null,
    "id": 1470330332
  },
  {
    "city": "Slovenske Konjice",
    "city_ascii": "Slovenske Konjice",
    "lat": 46.3362,
    "lng": 15.421,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Slovenske Konjice",
    "capital": "admin",
    "population": null,
    "id": 1705506460
  },
  {
    "city": "Vĩnh Yên",
    "city_ascii": "Vinh Yen",
    "lat": 21.31,
    "lng": 105.5967,
    "country": "Vietnam",
    "iso2": "VN",
    "iso3": "VNM",
    "admin_name": "Vĩnh Phúc",
    "capital": "admin",
    "population": null,
    "id": 1704905946
  },
  {
    "city": "Dar Naim",
    "city_ascii": "Dar Naim",
    "lat": 18.0973,
    "lng": -15.9327,
    "country": "Mauritania",
    "iso2": "MR",
    "iso3": "MRT",
    "admin_name": "Nouakchott Nord",
    "capital": "admin",
    "population": null,
    "id": 1478696248
  },
  {
    "city": "Iten",
    "city_ascii": "Iten",
    "lat": 0.6703,
    "lng": 35.5081,
    "country": "Kenya",
    "iso2": "KE",
    "iso3": "KEN",
    "admin_name": "Elgeyo/Marakwet",
    "capital": "admin",
    "population": null,
    "id": 1404000102
  },
  {
    "city": "Veymandoo",
    "city_ascii": "Veymandoo",
    "lat": 2.1878,
    "lng": 73.095,
    "country": "Maldives",
    "iso2": "MV",
    "iso3": "MDV",
    "admin_name": "Kolhumadulu",
    "capital": "admin",
    "population": null,
    "id": 1462464790
  },
  {
    "city": "Mežica",
    "city_ascii": "Mezica",
    "lat": 46.5206,
    "lng": 14.8536,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Mežica",
    "capital": "admin",
    "population": null,
    "id": 1705607524
  },
  {
    "city": "Phalombe",
    "city_ascii": "Phalombe",
    "lat": -15.8,
    "lng": 35.65,
    "country": "Malawi",
    "iso2": "MW",
    "iso3": "MWI",
    "admin_name": "Phalombe",
    "capital": "admin",
    "population": null,
    "id": 1454510180
  },
  {
    "city": "Nyamira",
    "city_ascii": "Nyamira",
    "lat": -0.5633,
    "lng": 34.9358,
    "country": "Kenya",
    "iso2": "KE",
    "iso3": "KEN",
    "admin_name": "Nyamira",
    "capital": "admin",
    "population": null,
    "id": 1404000104
  },
  {
    "city": "Machinga",
    "city_ascii": "Machinga",
    "lat": -14.9667,
    "lng": 35.5167,
    "country": "Malawi",
    "iso2": "MW",
    "iso3": "MWI",
    "admin_name": "Machinga",
    "capital": "admin",
    "population": 1418,
    "id": 1454566785
  },
  {
    "city": "Totness",
    "city_ascii": "Totness",
    "lat": 5.8904,
    "lng": -56.32,
    "country": "Suriname",
    "iso2": "SR",
    "iso3": "SUR",
    "admin_name": "Coronie",
    "capital": "admin",
    "population": 1685,
    "id": 1740738832
  },
  {
    "city": "Longford",
    "city_ascii": "Longford",
    "lat": 53.7333,
    "lng": -7.8,
    "country": "Ireland",
    "iso2": "IE",
    "iso3": "IRL",
    "admin_name": "Longford",
    "capital": "admin",
    "population": null,
    "id": 1372905339
  },
  {
    "city": "Auki",
    "city_ascii": "Auki",
    "lat": -8.7678,
    "lng": 160.6978,
    "country": "Solomon Islands",
    "iso2": "SB",
    "iso3": "SLB",
    "admin_name": "Malaita",
    "capital": "admin",
    "population": null,
    "id": 1090993143
  },
  {
    "city": "Kirkop",
    "city_ascii": "Kirkop",
    "lat": 35.8422,
    "lng": 14.4853,
    "country": "Malta",
    "iso2": "MT",
    "iso3": "MLT",
    "admin_name": "Kirkop",
    "capital": "admin",
    "population": null,
    "id": 1470075119
  },
  {
    "city": "Trim",
    "city_ascii": "Trim",
    "lat": 53.555,
    "lng": -6.7917,
    "country": "Ireland",
    "iso2": "IE",
    "iso3": "IRL",
    "admin_name": "Meath",
    "capital": "admin",
    "population": null,
    "id": 1372676556
  },
  {
    "city": "Felidhoo",
    "city_ascii": "Felidhoo",
    "lat": 3.4718,
    "lng": 73.547,
    "country": "Maldives",
    "iso2": "MV",
    "iso3": "MDV",
    "admin_name": "Felidhu Atholhu",
    "capital": "admin",
    "population": null,
    "id": 1462456773
  },
  {
    "city": "Aioun",
    "city_ascii": "Aioun",
    "lat": 16.6667,
    "lng": -9.6167,
    "country": "Mauritania",
    "iso2": "MR",
    "iso3": "MRT",
    "admin_name": "Hodh el Gharbi",
    "capital": "admin",
    "population": 1423,
    "id": 1478255260
  },
  {
    "city": "Xuddur",
    "city_ascii": "Xuddur",
    "lat": 4.12,
    "lng": 43.8878,
    "country": "Somalia",
    "iso2": "SO",
    "iso3": "SOM",
    "admin_name": "Bakool",
    "capital": "admin",
    "population": 1639,
    "id": 1706568226
  },
  {
    "city": "Kasaali",
    "city_ascii": "Kasaali",
    "lat": -0.6167,
    "lng": 31.55,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Rakai",
    "capital": "admin",
    "population": null,
    "id": 1800217765
  },
  {
    "city": "Kapsabet",
    "city_ascii": "Kapsabet",
    "lat": 0.2,
    "lng": 35.1,
    "country": "Kenya",
    "iso2": "KE",
    "iso3": "KEN",
    "admin_name": "Nandi",
    "capital": "admin",
    "population": null,
    "id": 1404814363
  },
  {
    "city": "Pembroke",
    "city_ascii": "Pembroke",
    "lat": 35.9306,
    "lng": 14.4764,
    "country": "Malta",
    "iso2": "MT",
    "iso3": "MLT",
    "admin_name": "Pembroke",
    "capital": "admin",
    "population": null,
    "id": 1470802229
  },
  {
    "city": "Lubāna",
    "city_ascii": "Lubana",
    "lat": 56.9017,
    "lng": 26.7228,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Lubānas Novads",
    "capital": "admin",
    "population": 1596,
    "id": 1428963436
  },
  {
    "city": "Šabac",
    "city_ascii": "Sabac",
    "lat": 44.7558,
    "lng": 19.6939,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Šabac",
    "capital": "admin",
    "population": null,
    "id": 1688640421
  },
  {
    "city": "Nilandhoo",
    "city_ascii": "Nilandhoo",
    "lat": 3.0567,
    "lng": 72.89,
    "country": "Maldives",
    "iso2": "MV",
    "iso3": "MDV",
    "admin_name": "Nilandhe Atholhu Uthuruburi",
    "capital": "admin",
    "population": null,
    "id": 1462000005
  },
  {
    "city": "Viesīte",
    "city_ascii": "Viesite",
    "lat": 56.3453,
    "lng": 25.5569,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Viesītes Novads",
    "capital": "admin",
    "population": 1580,
    "id": 1428998036
  },
  {
    "city": "Kirakira",
    "city_ascii": "Kirakira",
    "lat": -10.4544,
    "lng": 161.9205,
    "country": "Solomon Islands",
    "iso2": "SB",
    "iso3": "SLB",
    "admin_name": "Makira and Ulawa",
    "capital": "admin",
    "population": null,
    "id": 1090349580
  },
  {
    "city": "Kamnik",
    "city_ascii": "Kamnik",
    "lat": 46.225,
    "lng": 14.6097,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Kamnik",
    "capital": "admin",
    "population": null,
    "id": 1705864078
  },
  {
    "city": "Radlje ob Dravi",
    "city_ascii": "Radlje ob Dravi",
    "lat": 46.6149,
    "lng": 15.2226,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Radlje ob Dravi",
    "capital": "admin",
    "population": null,
    "id": 1705478193
  },
  {
    "city": "Carrick on Shannon",
    "city_ascii": "Carrick on Shannon",
    "lat": 53.9469,
    "lng": -8.09,
    "country": "Ireland",
    "iso2": "IE",
    "iso3": "IRL",
    "admin_name": "Leitrim",
    "capital": "admin",
    "population": null,
    "id": 1372960774
  },
  {
    "city": "Attard",
    "city_ascii": "Attard",
    "lat": 35.8897,
    "lng": 14.4425,
    "country": "Malta",
    "iso2": "MT",
    "iso3": "MLT",
    "admin_name": "Attard",
    "capital": "admin",
    "population": null,
    "id": 1470765022
  },
  {
    "city": "Marsa",
    "city_ascii": "Marsa",
    "lat": 35.8792,
    "lng": 14.4953,
    "country": "Malta",
    "iso2": "MT",
    "iso3": "MLT",
    "admin_name": "Marsa",
    "capital": "admin",
    "population": null,
    "id": 1470720892
  },
  {
    "city": "Foammulah",
    "city_ascii": "Foammulah",
    "lat": -0.3,
    "lng": 73.4256,
    "country": "Maldives",
    "iso2": "MV",
    "iso3": "MDV",
    "admin_name": "Fuvammulah",
    "capital": "admin",
    "population": null,
    "id": 1462000002
  },
  {
    "city": "Bu’aale",
    "city_ascii": "Bu'aale",
    "lat": 1.0833,
    "lng": 42.5833,
    "country": "Somalia",
    "iso2": "SO",
    "iso3": "SOM",
    "admin_name": "Jubbada Dhexe",
    "capital": "admin",
    "population": 1490,
    "id": 1706765820
  },
  {
    "city": "Tullamore",
    "city_ascii": "Tullamore",
    "lat": 53.2667,
    "lng": -7.5,
    "country": "Ireland",
    "iso2": "IE",
    "iso3": "IRL",
    "admin_name": "Offaly",
    "capital": "admin",
    "population": null,
    "id": 1372035774
  },
  {
    "city": "Zilupe",
    "city_ascii": "Zilupe",
    "lat": 56.3856,
    "lng": 28.1225,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Zilupes Novads",
    "capital": "admin",
    "population": 1469,
    "id": 1428917349
  },
  {
    "city": "Fomboni",
    "city_ascii": "Fomboni",
    "lat": -12.2822,
    "lng": 43.7419,
    "country": "Comoros",
    "iso2": "KM",
    "iso3": "COM",
    "admin_name": "Mohéli",
    "capital": "admin",
    "population": null,
    "id": 1174539766
  },
  {
    "city": "Grosuplje",
    "city_ascii": "Grosuplje",
    "lat": 45.9551,
    "lng": 14.6562,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Grosuplje",
    "capital": "admin",
    "population": null,
    "id": 1705847148
  },
  {
    "city": "Kurunegala",
    "city_ascii": "Kurunegala",
    "lat": 7.4833,
    "lng": 80.3667,
    "country": "Sri Lanka",
    "iso2": "LK",
    "iso3": "LKA",
    "admin_name": "North Western",
    "capital": "admin",
    "population": null,
    "id": 1144602660
  },
  {
    "city": "Cesvaine",
    "city_ascii": "Cesvaine",
    "lat": 56.9683,
    "lng": 26.3067,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Cesvaines Novads",
    "capital": "admin",
    "population": 1410,
    "id": 1428611306
  },
  {
    "city": "Xocalı",
    "city_ascii": "Xocali",
    "lat": 39.9111,
    "lng": 46.7892,
    "country": "Azerbaijan",
    "iso2": "AZ",
    "iso3": "AZE",
    "admin_name": "Xocalı",
    "capital": "admin",
    "population": 1397,
    "id": 1031237556
  },
  {
    "city": "Murang’a",
    "city_ascii": "Murang'a",
    "lat": -0.721,
    "lng": 37.1526,
    "country": "Kenya",
    "iso2": "KE",
    "iso3": "KEN",
    "admin_name": "Murang’a",
    "capital": "admin",
    "population": null,
    "id": 1404066738
  },
  {
    "city": "Bududa",
    "city_ascii": "Bududa",
    "lat": 1.0112,
    "lng": 34.3311,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Bududa",
    "capital": "admin",
    "population": null,
    "id": 1800970850
  },
  {
    "city": "Lagawe",
    "city_ascii": "Lagawe",
    "lat": 16.8,
    "lng": 121.1192,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Ifugao",
    "capital": "admin",
    "population": null,
    "id": 1608396110
  },
  {
    "city": "Għargħur",
    "city_ascii": "Gharghur",
    "lat": 35.9242,
    "lng": 14.4519,
    "country": "Malta",
    "iso2": "MT",
    "iso3": "MLT",
    "admin_name": "Għargħur",
    "capital": "admin",
    "population": null,
    "id": 1470917815
  },
  {
    "city": "Żebbuġ",
    "city_ascii": "Zebbug",
    "lat": 35.8719,
    "lng": 14.4411,
    "country": "Malta",
    "iso2": "MT",
    "iso3": "MLT",
    "admin_name": "Żebbuġ",
    "capital": "admin",
    "population": null,
    "id": 1470473600
  },
  {
    "city": "Zagorje",
    "city_ascii": "Zagorje",
    "lat": 46.1342,
    "lng": 14.9942,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Zagorje ob Savi",
    "capital": "admin",
    "population": null,
    "id": 1705942287
  },
  {
    "city": "Slovenj Gradec",
    "city_ascii": "Slovenj Gradec",
    "lat": 46.5093,
    "lng": 15.079,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Slovenj Gradec",
    "capital": "admin",
    "population": null,
    "id": 1705845238
  },
  {
    "city": "Sotik Post",
    "city_ascii": "Sotik Post",
    "lat": -0.7813,
    "lng": 35.3416,
    "country": "Kenya",
    "iso2": "KE",
    "iso3": "KEN",
    "admin_name": "Bomet",
    "capital": "admin",
    "population": null,
    "id": 1404087664
  },
  {
    "city": "Gudja",
    "city_ascii": "Gudja",
    "lat": 35.8492,
    "lng": 14.5031,
    "country": "Malta",
    "iso2": "MT",
    "iso3": "MLT",
    "admin_name": "Gudja",
    "capital": "admin",
    "population": null,
    "id": 1470798142
  },
  {
    "city": "Vreed-en-Hoop",
    "city_ascii": "Vreed-en-Hoop",
    "lat": 6.8,
    "lng": -58.1833,
    "country": "Guyana",
    "iso2": "GY",
    "iso3": "GUY",
    "admin_name": "Essequibo Islands-West Demerara",
    "capital": "admin",
    "population": null,
    "id": 1328178268
  },
  {
    "city": "Escaldes-Engordany",
    "city_ascii": "Escaldes-Engordany",
    "lat": 42.5085,
    "lng": 1.5385,
    "country": "Andorra",
    "iso2": "AD",
    "iso3": "AND",
    "admin_name": "Escaldes-Engordany",
    "capital": "admin",
    "population": null,
    "id": 1020008674
  },
  {
    "city": "Gornji Milanovac",
    "city_ascii": "Gornji Milanovac",
    "lat": 44.0212,
    "lng": 20.456,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Gornji Milanovac",
    "capital": "admin",
    "population": null,
    "id": 1688363978
  },
  {
    "city": "Relizane",
    "city_ascii": "Relizane",
    "lat": 35.7373,
    "lng": 0.556,
    "country": "Algeria",
    "iso2": "DZ",
    "iso3": "DZA",
    "admin_name": "Relizane",
    "capital": "admin",
    "population": null,
    "id": 1012939885
  },
  {
    "city": "Viļaka",
    "city_ascii": "Vilaka",
    "lat": 57.1842,
    "lng": 27.6722,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Viļakas Novads",
    "capital": "admin",
    "population": 1309,
    "id": 1428268339
  },
  {
    "city": "Žalec",
    "city_ascii": "Zalec",
    "lat": 46.2516,
    "lng": 15.1674,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Žalec",
    "capital": "admin",
    "population": null,
    "id": 1705340993
  },
  {
    "city": "Litija",
    "city_ascii": "Litija",
    "lat": 46.0565,
    "lng": 14.8303,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Litija",
    "capital": "admin",
    "population": null,
    "id": 1705902870
  },
  {
    "city": "Imqabba",
    "city_ascii": "Imqabba",
    "lat": 35.8461,
    "lng": 14.4672,
    "country": "Malta",
    "iso2": "MT",
    "iso3": "MLT",
    "admin_name": "Imqabba",
    "capital": "admin",
    "population": null,
    "id": 1470934982
  },
  {
    "city": "Tam Kỳ",
    "city_ascii": "Tam Ky",
    "lat": 15.5667,
    "lng": 108.4833,
    "country": "Vietnam",
    "iso2": "VN",
    "iso3": "VNM",
    "admin_name": "Quảng Nam",
    "capital": "admin",
    "population": null,
    "id": 1704410655
  },
  {
    "city": "Saint Paul’s Bay",
    "city_ascii": "Saint Paul's Bay",
    "lat": 35.9483,
    "lng": 14.4006,
    "country": "Malta",
    "iso2": "MT",
    "iso3": "MLT",
    "admin_name": "San Pawl il-Baħar",
    "capital": "admin",
    "population": null,
    "id": 1470879974
  },
  {
    "city": "Krško",
    "city_ascii": "Krsko",
    "lat": 45.959,
    "lng": 15.4922,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Krško",
    "capital": "admin",
    "population": null,
    "id": 1705286396
  },
  {
    "city": "Jesenice",
    "city_ascii": "Jesenice",
    "lat": 46.4366,
    "lng": 14.0602,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Jesenice",
    "capital": "admin",
    "population": null,
    "id": 1705471403
  },
  {
    "city": "Radovljica",
    "city_ascii": "Radovljica",
    "lat": 46.3444,
    "lng": 14.1744,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Radovljica",
    "capital": "admin",
    "population": null,
    "id": 1705488511
  },
  {
    "city": "Marsaxlokk",
    "city_ascii": "Marsaxlokk",
    "lat": 35.8419,
    "lng": 14.5431,
    "country": "Malta",
    "iso2": "MT",
    "iso3": "MLT",
    "admin_name": "Marsaxlokk",
    "capital": "admin",
    "population": null,
    "id": 1470596833
  },
  {
    "city": "Prevalje",
    "city_ascii": "Prevalje",
    "lat": 46.5467,
    "lng": 14.98,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Prevalje",
    "capital": "admin",
    "population": null,
    "id": 1705187949
  },
  {
    "city": "Weno",
    "city_ascii": "Weno",
    "lat": 7.4458,
    "lng": 151.85,
    "country": "Micronesia, Federated States Of",
    "iso2": "FM",
    "iso3": "FSM",
    "admin_name": "Chuuk",
    "capital": "admin",
    "population": null,
    "id": 1583964118
  },
  {
    "city": "Taro",
    "city_ascii": "Taro",
    "lat": -6.7111,
    "lng": 156.3972,
    "country": "Solomon Islands",
    "iso2": "SB",
    "iso3": "SLB",
    "admin_name": "Choiseul",
    "capital": "admin",
    "population": null,
    "id": 1090931394
  },
  {
    "city": "Għaxaq",
    "city_ascii": "Ghaxaq",
    "lat": 35.8489,
    "lng": 14.5167,
    "country": "Malta",
    "iso2": "MT",
    "iso3": "MLT",
    "admin_name": "Għaxaq",
    "capital": "admin",
    "population": null,
    "id": 1470648445
  },
  {
    "city": "Birżebbuġa",
    "city_ascii": "Birzebbuga",
    "lat": 35.8258,
    "lng": 14.5269,
    "country": "Malta",
    "iso2": "MT",
    "iso3": "MLT",
    "admin_name": "Birżebbuġa",
    "capital": "admin",
    "population": null,
    "id": 1470830646
  },
  {
    "city": "Żurrieq",
    "city_ascii": "Zurrieq",
    "lat": 35.8311,
    "lng": 14.4742,
    "country": "Malta",
    "iso2": "MT",
    "iso3": "MLT",
    "admin_name": "Żurrieq",
    "capital": "admin",
    "population": null,
    "id": 1470331348
  },
  {
    "city": "Naxxar",
    "city_ascii": "Naxxar",
    "lat": 35.9136,
    "lng": 14.4436,
    "country": "Malta",
    "iso2": "MT",
    "iso3": "MLT",
    "admin_name": "Naxxar",
    "capital": "admin",
    "population": null,
    "id": 1470470220
  },
  {
    "city": "Sopište",
    "city_ascii": "Sopiste",
    "lat": 41.9547,
    "lng": 21.4275,
    "country": "Macedonia",
    "iso2": "MK",
    "iso3": "MKD",
    "admin_name": "Sopište",
    "capital": "admin",
    "population": null,
    "id": 1807691189
  },
  {
    "city": "Trbovlje",
    "city_ascii": "Trbovlje",
    "lat": 46.155,
    "lng": 15.0533,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Trbovlje",
    "capital": "admin",
    "population": null,
    "id": 1705370010
  },
  {
    "city": "Muli",
    "city_ascii": "Muli",
    "lat": 2.9217,
    "lng": 73.5811,
    "country": "Maldives",
    "iso2": "MV",
    "iso3": "MDV",
    "admin_name": "Felidhu Atholhu",
    "capital": "admin",
    "population": null,
    "id": 1462436531
  },
  {
    "city": "Ar Rayyān",
    "city_ascii": "Ar Rayyan",
    "lat": 25.2919,
    "lng": 51.4244,
    "country": "Qatar",
    "iso2": "QA",
    "iso3": "QAT",
    "admin_name": "Ar Rayyān",
    "capital": "admin",
    "population": null,
    "id": 1634097551
  },
  {
    "city": "Mazsalaca",
    "city_ascii": "Mazsalaca",
    "lat": 57.8667,
    "lng": 25.05,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Mazsalacas Novads",
    "capital": "admin",
    "population": 1133,
    "id": 1428775090
  },
  {
    "city": "Wakiso",
    "city_ascii": "Wakiso",
    "lat": 0.4044,
    "lng": 32.4594,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Wakiso",
    "capital": "admin",
    "population": null,
    "id": 1800102352
  },
  {
    "city": "Ivančna Gorica",
    "city_ascii": "Ivancna Gorica",
    "lat": 45.9383,
    "lng": 14.8044,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Ivančna Gorica",
    "capital": "admin",
    "population": null,
    "id": 1705930549
  },
  {
    "city": "Aloja",
    "city_ascii": "Aloja",
    "lat": 57.7669,
    "lng": 24.8828,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Alojas Novads",
    "capital": "admin",
    "population": 1120,
    "id": 1428805342
  },
  {
    "city": "Strenči",
    "city_ascii": "Strenci",
    "lat": 57.6272,
    "lng": 25.685,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Strenču Novads",
    "capital": "admin",
    "population": 1107,
    "id": 1428746601
  },
  {
    "city": "Rogaška Slatina",
    "city_ascii": "Rogaska Slatina",
    "lat": 46.2375,
    "lng": 15.6397,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Rogaška Slatina",
    "capital": "admin",
    "population": null,
    "id": 1705772825
  },
  {
    "city": "Lyantonde",
    "city_ascii": "Lyantonde",
    "lat": -0.4031,
    "lng": 31.1572,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Lyantonde",
    "capital": "admin",
    "population": null,
    "id": 1800896564
  },
  {
    "city": "Ipil",
    "city_ascii": "Ipil",
    "lat": 7.7844,
    "lng": 122.5861,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Zamboanga Sibugay",
    "capital": "admin",
    "population": null,
    "id": 1608307222
  },
  {
    "city": "Aïn Temouchent",
    "city_ascii": "Ain Temouchent",
    "lat": 35.2975,
    "lng": -1.1404,
    "country": "Algeria",
    "iso2": "DZ",
    "iso3": "DZA",
    "admin_name": "Aïn Temouchent",
    "capital": "admin",
    "population": null,
    "id": 1012763655
  },
  {
    "city": "Areguá",
    "city_ascii": "Aregua",
    "lat": -25.3125,
    "lng": -57.3847,
    "country": "Paraguay",
    "iso2": "PY",
    "iso3": "PRY",
    "admin_name": "Central",
    "capital": "admin",
    "population": null,
    "id": 1600141362
  },
  {
    "city": "Serravalle",
    "city_ascii": "Serravalle",
    "lat": 43.9683,
    "lng": 12.4811,
    "country": "San Marino",
    "iso2": "SM",
    "iso3": "SMR",
    "admin_name": "Serravalle",
    "capital": "admin",
    "population": null,
    "id": 1674000000
  },
  {
    "city": "Halba",
    "city_ascii": "Halba",
    "lat": 34.5428,
    "lng": 36.0797,
    "country": "Lebanon",
    "iso2": "LB",
    "iso3": "LBN",
    "admin_name": "Aakkâr",
    "capital": "admin",
    "population": null,
    "id": 1422962727
  },
  {
    "city": "Nenagh",
    "city_ascii": "Nenagh",
    "lat": 52.8619,
    "lng": -8.1967,
    "country": "Ireland",
    "iso2": "IE",
    "iso3": "IRL",
    "admin_name": "Tipperary",
    "capital": "admin",
    "population": null,
    "id": 1372867922
  },
  {
    "city": "Aknīste",
    "city_ascii": "Akniste",
    "lat": 56.1622,
    "lng": 25.7411,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Aknīstes Novads",
    "capital": "admin",
    "population": 1036,
    "id": 1428363907
  },
  {
    "city": "Pāvilosta",
    "city_ascii": "Pavilosta",
    "lat": 56.8878,
    "lng": 21.1842,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Pāvilostas Novads",
    "capital": "admin",
    "population": 1035,
    "id": 1428955629
  },
  {
    "city": "Līgatne",
    "city_ascii": "Ligatne",
    "lat": 57.2336,
    "lng": 25.0397,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Līgatnes Novads",
    "capital": "admin",
    "population": 1015,
    "id": 1428643118
  },
  {
    "city": "Laško",
    "city_ascii": "Lasko",
    "lat": 46.1563,
    "lng": 15.2386,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Laško",
    "capital": "admin",
    "population": null,
    "id": 1705962698
  },
  {
    "city": "Chikwawa",
    "city_ascii": "Chikwawa",
    "lat": -16.035,
    "lng": 34.801,
    "country": "Malawi",
    "iso2": "MW",
    "iso3": "MWI",
    "admin_name": "Chikwawa",
    "capital": "admin",
    "population": null,
    "id": 1454343925
  },
  {
    "city": "Kasungu",
    "city_ascii": "Kasungu",
    "lat": -13.0364,
    "lng": 33.4822,
    "country": "Malawi",
    "iso2": "MW",
    "iso3": "MWI",
    "admin_name": "Kasungu",
    "capital": "admin",
    "population": null,
    "id": 1454117674
  },
  {
    "city": "Safi",
    "city_ascii": "Safi",
    "lat": 35.8331,
    "lng": 14.4847,
    "country": "Malta",
    "iso2": "MT",
    "iso3": "MLT",
    "admin_name": "Safi",
    "capital": "admin",
    "population": null,
    "id": 1470417421
  },
  {
    "city": "Labuan",
    "city_ascii": "Labuan",
    "lat": 5.2803,
    "lng": 115.2475,
    "country": "Malaysia",
    "iso2": "MY",
    "iso3": "MYS",
    "admin_name": "Labuan",
    "capital": "admin",
    "population": null,
    "id": 1458460967
  },
  {
    "city": "Lata",
    "city_ascii": "Lata",
    "lat": -10.738,
    "lng": 165.8567,
    "country": "Solomon Islands",
    "iso2": "SB",
    "iso3": "SLB",
    "admin_name": "Temotu",
    "capital": "admin",
    "population": 553,
    "id": 1090442496
  },
  {
    "city": "Požega",
    "city_ascii": "Pozega",
    "lat": 43.85,
    "lng": 20.05,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Požega",
    "capital": "admin",
    "population": null,
    "id": 1688211541
  },
  {
    "city": "Imtarfa",
    "city_ascii": "Imtarfa",
    "lat": 35.8933,
    "lng": 14.3989,
    "country": "Malta",
    "iso2": "MT",
    "iso3": "MLT",
    "admin_name": "Imtarfa",
    "capital": "admin",
    "population": null,
    "id": 1470946814
  },
  {
    "city": "Picos",
    "city_ascii": "Picos",
    "lat": 15.0833,
    "lng": -23.65,
    "country": "Cabo Verde",
    "iso2": "CV",
    "iso3": "CPV",
    "admin_name": "São Salvador do Mundo",
    "capital": "admin",
    "population": null,
    "id": 1132670996
  },
  {
    "city": "Kerċem",
    "city_ascii": "Kercem",
    "lat": 36.0419,
    "lng": 14.2267,
    "country": "Malta",
    "iso2": "MT",
    "iso3": "MLT",
    "admin_name": "Kerċem",
    "capital": "admin",
    "population": null,
    "id": 1470847062
  },
  {
    "city": "An Cabhán",
    "city_ascii": "An Cabhan",
    "lat": 53.9908,
    "lng": -7.3606,
    "country": "Ireland",
    "iso2": "IE",
    "iso3": "IRL",
    "admin_name": "Cavan",
    "capital": "admin",
    "population": null,
    "id": 1372007853
  },
  {
    "city": "Fonadhoo",
    "city_ascii": "Fonadhoo",
    "lat": 1.8342,
    "lng": 73.5031,
    "country": "Maldives",
    "iso2": "MV",
    "iso3": "MDV",
    "admin_name": "Hadhdhunmathi",
    "capital": "admin",
    "population": null,
    "id": 1462000001
  },
  {
    "city": "Žirovnica",
    "city_ascii": "Zirovnica",
    "lat": 46.4047,
    "lng": 14.14,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Žirovnica",
    "capital": "admin",
    "population": null,
    "id": 1705602636
  },
  {
    "city": "Manadhoo",
    "city_ascii": "Manadhoo",
    "lat": 5.7669,
    "lng": 73.4136,
    "country": "Maldives",
    "iso2": "MV",
    "iso3": "MDV",
    "admin_name": "Miladhunmadulu Dhekunuburi",
    "capital": "admin",
    "population": null,
    "id": 1462220750
  },
  {
    "city": "Ribeira Grande",
    "city_ascii": "Ribeira Grande",
    "lat": 17.1825,
    "lng": -25.065,
    "country": "Cabo Verde",
    "iso2": "CV",
    "iso3": "CPV",
    "admin_name": "Ribeira Grande",
    "capital": "admin",
    "population": null,
    "id": 1132061543
  },
  {
    "city": "Ponta do Sol",
    "city_ascii": "Ponta do Sol",
    "lat": 17.2014,
    "lng": -25.0917,
    "country": "Cabo Verde",
    "iso2": "CV",
    "iso3": "CPV",
    "admin_name": "Ribeira Grande",
    "capital": "admin",
    "population": null,
    "id": 1132137257
  },
  {
    "city": "Krupanj",
    "city_ascii": "Krupanj",
    "lat": 44.3656,
    "lng": 19.3619,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Krupanj",
    "capital": "admin",
    "population": null,
    "id": 1688954602
  },
  {
    "city": "Lebane",
    "city_ascii": "Lebane",
    "lat": 42.9167,
    "lng": 21.7333,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Lebane",
    "capital": "admin",
    "population": null,
    "id": 1688169172
  },
  {
    "city": "Štore",
    "city_ascii": "Store",
    "lat": 46.2208,
    "lng": 15.3139,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Štore",
    "capital": "admin",
    "population": null,
    "id": 1705775604
  },
  {
    "city": "Siaya",
    "city_ascii": "Siaya",
    "lat": 0.0607,
    "lng": 34.2881,
    "country": "Kenya",
    "iso2": "KE",
    "iso3": "KEN",
    "admin_name": "Siaya",
    "capital": "admin",
    "population": null,
    "id": 1404162576
  },
  {
    "city": "Ape",
    "city_ascii": "Ape",
    "lat": 57.5392,
    "lng": 26.6928,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Apes Novads",
    "capital": "admin",
    "population": 876,
    "id": 1428396055
  },
  {
    "city": "Cerklje na Gorenjskem",
    "city_ascii": "Cerklje na Gorenjskem",
    "lat": 46.2542,
    "lng": 14.4886,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Cerklje na Gorenjskem",
    "capital": "admin",
    "population": null,
    "id": 1705820033
  },
  {
    "city": "Isale",
    "city_ascii": "Isale",
    "lat": -3.3444,
    "lng": 29.4833,
    "country": "Burundi",
    "iso2": "BI",
    "iso3": "BDI",
    "admin_name": "Bujumbura Rural",
    "capital": "admin",
    "population": null,
    "id": 1108407022
  },
  {
    "city": "Šoštanj",
    "city_ascii": "Sostanj",
    "lat": 46.38,
    "lng": 15.0486,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Šoštanj",
    "capital": "admin",
    "population": null,
    "id": 1705376916
  },
  {
    "city": "Điện Biên Phủ",
    "city_ascii": "Dien Bien Phu",
    "lat": 21.3833,
    "lng": 103.0169,
    "country": "Vietnam",
    "iso2": "VN",
    "iso3": "VNM",
    "admin_name": "Điện Biên",
    "capital": "admin",
    "population": null,
    "id": 1704081536
  },
  {
    "city": "P’yŏngsŏng",
    "city_ascii": "P'yongsong",
    "lat": 39.2464,
    "lng": 125.8719,
    "country": "Korea, North",
    "iso2": "KP",
    "iso3": "PRK",
    "admin_name": "P’yŏngnam",
    "capital": "admin",
    "population": null,
    "id": 1408000381
  },
  {
    "city": "Luqa",
    "city_ascii": "Luqa",
    "lat": 35.8589,
    "lng": 14.4886,
    "country": "Malta",
    "iso2": "MT",
    "iso3": "MLT",
    "admin_name": "Luqa",
    "capital": "admin",
    "population": null,
    "id": 1470901810
  },
  {
    "city": "Scarborough",
    "city_ascii": "Scarborough",
    "lat": 11.1811,
    "lng": -60.7333,
    "country": "Trinidad And Tobago",
    "iso2": "TT",
    "iso3": "TTO",
    "admin_name": "Tobago",
    "capital": "admin",
    "population": null,
    "id": 1780875399
  },
  {
    "city": "Diego Martin",
    "city_ascii": "Diego Martin",
    "lat": 10.7208,
    "lng": -61.5662,
    "country": "Trinidad And Tobago",
    "iso2": "TT",
    "iso3": "TTO",
    "admin_name": "Diego Martin",
    "capital": "admin",
    "population": null,
    "id": 1780534918
  },
  {
    "city": "Vladičin Han",
    "city_ascii": "Vladicin Han",
    "lat": 42.7,
    "lng": 22.0667,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Vladičin Han",
    "capital": "admin",
    "population": null,
    "id": 1688664018
  },
  {
    "city": "Knjaževac",
    "city_ascii": "Knjazevac",
    "lat": 43.5,
    "lng": 22.4333,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Knjaževac",
    "capital": "admin",
    "population": null,
    "id": 1688849183
  },
  {
    "city": "Basco",
    "city_ascii": "Basco",
    "lat": 20.4486,
    "lng": 121.9702,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Batanes",
    "capital": "admin",
    "population": null,
    "id": 1608620601
  },
  {
    "city": "Ljubovija",
    "city_ascii": "Ljubovija",
    "lat": 44.1896,
    "lng": 19.3785,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Ljubovija",
    "capital": "admin",
    "population": null,
    "id": 1688274240
  },
  {
    "city": "Slovenska Bistrica",
    "city_ascii": "Slovenska Bistrica",
    "lat": 46.3941,
    "lng": 15.5707,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Slovenska Bistrica",
    "capital": "admin",
    "population": null,
    "id": 1705359875
  },
  {
    "city": "Smederevska Palanka",
    "city_ascii": "Smederevska Palanka",
    "lat": 44.3655,
    "lng": 20.9587,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Smederevska Palanka",
    "capital": "admin",
    "population": null,
    "id": 1688107292
  },
  {
    "city": "Mahonda",
    "city_ascii": "Mahonda",
    "lat": -5.9897,
    "lng": 39.2519,
    "country": "Tanzania",
    "iso2": "TZ",
    "iso3": "TZA",
    "admin_name": "Zanzibar North",
    "capital": "admin",
    "population": null,
    "id": 1834907448
  },
  {
    "city": "Mali Zvornik",
    "city_ascii": "Mali Zvornik",
    "lat": 44.3992,
    "lng": 19.1214,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Mali Zvornik",
    "capital": "admin",
    "population": null,
    "id": 1688513998
  },
  {
    "city": "Point Fortin",
    "city_ascii": "Point Fortin",
    "lat": 10.1741,
    "lng": -61.6841,
    "country": "Trinidad And Tobago",
    "iso2": "TT",
    "iso3": "TTO",
    "admin_name": "Point Fortin",
    "capital": "admin",
    "population": null,
    "id": 1780702349
  },
  {
    "city": "Port Laoise",
    "city_ascii": "Port Laoise",
    "lat": 53.0309,
    "lng": -7.3008,
    "country": "Ireland",
    "iso2": "IE",
    "iso3": "IRL",
    "admin_name": "Laois",
    "capital": "admin",
    "population": null,
    "id": 1372726111
  },
  {
    "city": "Surdulica",
    "city_ascii": "Surdulica",
    "lat": 42.695,
    "lng": 22.1672,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Surdulica",
    "capital": "admin",
    "population": null,
    "id": 1688920744
  },
  {
    "city": "Požarevac",
    "city_ascii": "Pozarevac",
    "lat": 44.62,
    "lng": 21.1897,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Požarevac",
    "capital": "admin",
    "population": null,
    "id": 1688653137
  },
  {
    "city": "Mayuge",
    "city_ascii": "Mayuge",
    "lat": 0.4578,
    "lng": 33.4806,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Mayuge",
    "capital": "admin",
    "population": null,
    "id": 1800300606
  },
  {
    "city": "Varaždin",
    "city_ascii": "Varazdin",
    "lat": 46.3,
    "lng": 16.3333,
    "country": "Croatia",
    "iso2": "HR",
    "iso3": "HRV",
    "admin_name": "Varaždinska Županija",
    "capital": "admin",
    "population": null,
    "id": 1191291148
  },
  {
    "city": "Shariff Aguak",
    "city_ascii": "Shariff Aguak",
    "lat": 6.8647,
    "lng": 124.4417,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Maguindanao",
    "capital": "admin",
    "population": null,
    "id": 1608346338
  },
  {
    "city": "Zaječar",
    "city_ascii": "Zajecar",
    "lat": 43.9042,
    "lng": 22.2847,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Zaječar",
    "capital": "admin",
    "population": null,
    "id": 1688564835
  },
  {
    "city": "Lakatoro",
    "city_ascii": "Lakatoro",
    "lat": -16.1069,
    "lng": 167.4208,
    "country": "Vanuatu",
    "iso2": "VU",
    "iso3": "VUT",
    "admin_name": "Malampa",
    "capital": "admin",
    "population": 705,
    "id": 1548761183
  },
  {
    "city": "Šentjur",
    "city_ascii": "Sentjur",
    "lat": 46.2225,
    "lng": 15.3973,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Šentjur",
    "capital": "admin",
    "population": null,
    "id": 1705076635
  },
  {
    "city": "Al Khawr",
    "city_ascii": "Al Khawr",
    "lat": 25.6839,
    "lng": 51.5058,
    "country": "Qatar",
    "iso2": "QA",
    "iso3": "QAT",
    "admin_name": "Al Khawr wa adh Dhakhīrah",
    "capital": "admin",
    "population": null,
    "id": 1634786937
  },
  {
    "city": "Dowa",
    "city_ascii": "Dowa",
    "lat": -13.6532,
    "lng": 33.9385,
    "country": "Malawi",
    "iso2": "MW",
    "iso3": "MWI",
    "admin_name": "Dowa",
    "capital": "admin",
    "population": null,
    "id": 1454644071
  },
  {
    "city": "Castlebar",
    "city_ascii": "Castlebar",
    "lat": 53.85,
    "lng": -9.3,
    "country": "Ireland",
    "iso2": "IE",
    "iso3": "IRL",
    "admin_name": "Mayo",
    "capital": "admin",
    "population": null,
    "id": 1372066739
  },
  {
    "city": "Aïn Defla",
    "city_ascii": "Ain Defla",
    "lat": 36.2641,
    "lng": 1.9679,
    "country": "Algeria",
    "iso2": "DZ",
    "iso3": "DZA",
    "admin_name": "Aïn Defla",
    "capital": "admin",
    "population": null,
    "id": 1012656497
  },
  {
    "city": "Mādabā",
    "city_ascii": "Madaba",
    "lat": 31.7167,
    "lng": 35.8,
    "country": "Jordan",
    "iso2": "JO",
    "iso3": "JOR",
    "admin_name": "Ma’dabā",
    "capital": "admin",
    "population": null,
    "id": 1400962992
  },
  {
    "city": "Sélibaby",
    "city_ascii": "Selibaby",
    "lat": 15.167,
    "lng": -12.1833,
    "country": "Mauritania",
    "iso2": "MR",
    "iso3": "MRT",
    "admin_name": "Guidimaka",
    "capital": "admin",
    "population": 460,
    "id": 1478575433
  },
  {
    "city": "Cabarroguis",
    "city_ascii": "Cabarroguis",
    "lat": 16.5111,
    "lng": 121.5212,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Quirino",
    "capital": "admin",
    "population": null,
    "id": 1608312278
  },
  {
    "city": "Asaba",
    "city_ascii": "Asaba",
    "lat": 6.1833,
    "lng": 6.75,
    "country": "Nigeria",
    "iso2": "NG",
    "iso3": "NGA",
    "admin_name": "Delta",
    "capital": "admin",
    "population": null,
    "id": 1566322425
  },
  {
    "city": "Prebold",
    "city_ascii": "Prebold",
    "lat": 46.2369,
    "lng": 15.0925,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Prebold",
    "capital": "admin",
    "population": null,
    "id": 1705746627
  },
  {
    "city": "Tulagi",
    "city_ascii": "Tulagi",
    "lat": -9.1031,
    "lng": 160.1506,
    "country": "Solomon Islands",
    "iso2": "SB",
    "iso3": "SLB",
    "admin_name": "Central",
    "capital": "admin",
    "population": null,
    "id": 1090844311
  },
  {
    "city": "Miklavž na Dravskem Polju",
    "city_ascii": "Miklavz na Dravskem Polju",
    "lat": 46.5058,
    "lng": 15.6972,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Miklavž na Dravskem Polju",
    "capital": "admin",
    "population": null,
    "id": 1705244140
  },
  {
    "city": "Jordan",
    "city_ascii": "Jordan",
    "lat": 10.6584,
    "lng": 122.5963,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Guimaras",
    "capital": "admin",
    "population": null,
    "id": 1608769914
  },
  {
    "city": "Aranguez",
    "city_ascii": "Aranguez",
    "lat": 10.6472,
    "lng": -61.4461,
    "country": "Trinidad And Tobago",
    "iso2": "TT",
    "iso3": "TTO",
    "admin_name": "San Juan/Laventille",
    "capital": "admin",
    "population": null,
    "id": 1780000001
  },
  {
    "city": "Šempeter pri Gorici",
    "city_ascii": "Sempeter pri Gorici",
    "lat": 45.9283,
    "lng": 13.6378,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Šempeter-Vrtojba",
    "capital": "admin",
    "population": null,
    "id": 1705397569
  },
  {
    "city": "Mozirje",
    "city_ascii": "Mozirje",
    "lat": 46.3394,
    "lng": 14.9633,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Mozirje",
    "capital": "admin",
    "population": null,
    "id": 1705239053
  },
  {
    "city": "Rumonge",
    "city_ascii": "Rumonge",
    "lat": -3.9736,
    "lng": 29.4386,
    "country": "Burundi",
    "iso2": "BI",
    "iso3": "BDI",
    "admin_name": "Rumonge",
    "capital": "admin",
    "population": null,
    "id": 1108641885
  },
  {
    "city": "Trebnje",
    "city_ascii": "Trebnje",
    "lat": 45.9104,
    "lng": 15.0086,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Trebnje",
    "capital": "admin",
    "population": null,
    "id": 1705570941
  },
  {
    "city": "Paraćin",
    "city_ascii": "Paracin",
    "lat": 43.8667,
    "lng": 21.4167,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Paraćin",
    "capital": "admin",
    "population": null,
    "id": 1688523885
  },
  {
    "city": "Bulambuli",
    "city_ascii": "Bulambuli",
    "lat": 1.1667,
    "lng": 34.3833,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Bulambuli",
    "capital": "admin",
    "population": null,
    "id": 1800071156
  },
  {
    "city": "Borgo Maggiore",
    "city_ascii": "Borgo Maggiore",
    "lat": 43.9408,
    "lng": 12.4475,
    "country": "San Marino",
    "iso2": "SM",
    "iso3": "SMR",
    "admin_name": "Borgo Maggiore",
    "capital": "admin",
    "population": null,
    "id": 1674314187
  },
  {
    "city": "Maracha",
    "city_ascii": "Maracha",
    "lat": 3.2704,
    "lng": 30.9553,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Maracha",
    "capital": "admin",
    "population": null,
    "id": 1800799569
  },
  {
    "city": "Luwero",
    "city_ascii": "Luwero",
    "lat": 0.8492,
    "lng": 32.4731,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Luwero",
    "capital": "admin",
    "population": null,
    "id": 1800067821
  },
  {
    "city": "Kapenguria",
    "city_ascii": "Kapenguria",
    "lat": 1.2389,
    "lng": 35.1119,
    "country": "Kenya",
    "iso2": "KE",
    "iso3": "KEN",
    "admin_name": "West Pokot",
    "capital": "admin",
    "population": null,
    "id": 1404228137
  },
  {
    "city": "Polzela",
    "city_ascii": "Polzela",
    "lat": 46.2833,
    "lng": 15.0667,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Polzela",
    "capital": "admin",
    "population": null,
    "id": 1705144123
  },
  {
    "city": "Yenagoa",
    "city_ascii": "Yenagoa",
    "lat": 4.9267,
    "lng": 6.2676,
    "country": "Nigeria",
    "iso2": "NG",
    "iso3": "NGA",
    "admin_name": "Bayelsa",
    "capital": "admin",
    "population": null,
    "id": 1566468984
  },
  {
    "city": "Qrendi",
    "city_ascii": "Qrendi",
    "lat": 35.8347,
    "lng": 14.4583,
    "country": "Malta",
    "iso2": "MT",
    "iso3": "MLT",
    "admin_name": "Qrendi",
    "capital": "admin",
    "population": null,
    "id": 1470797535
  },
  {
    "city": "Namayingo",
    "city_ascii": "Namayingo",
    "lat": 0.2398,
    "lng": 33.8849,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Namayingo",
    "capital": "admin",
    "population": null,
    "id": 1800633600
  },
  {
    "city": "Xewkija",
    "city_ascii": "Xewkija",
    "lat": 36.0328,
    "lng": 14.2581,
    "country": "Malta",
    "iso2": "MT",
    "iso3": "MLT",
    "admin_name": "Xewkija",
    "capital": "admin",
    "population": null,
    "id": 1470040289
  },
  {
    "city": "Lučani",
    "city_ascii": "Lucani",
    "lat": 43.8667,
    "lng": 20.1333,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Lučani",
    "capital": "admin",
    "population": null,
    "id": 1688437278
  },
  {
    "city": "Brežice",
    "city_ascii": "Brezice",
    "lat": 45.9044,
    "lng": 15.5925,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Brežice",
    "capital": "admin",
    "population": null,
    "id": 1705251132
  },
  {
    "city": "Suong",
    "city_ascii": "Suong",
    "lat": 11.9167,
    "lng": 105.65,
    "country": "Cambodia",
    "iso2": "KH",
    "iso3": "KHM",
    "admin_name": "Tbong Khmum",
    "capital": "admin",
    "population": null,
    "id": 1116319433
  },
  {
    "city": "Għasri",
    "city_ascii": "Ghasri",
    "lat": 36.0578,
    "lng": 14.2264,
    "country": "Malta",
    "iso2": "MT",
    "iso3": "MLT",
    "admin_name": "Għasri",
    "capital": "admin",
    "population": null,
    "id": 1470127050
  },
  {
    "city": "Gllogovc",
    "city_ascii": "Gllogovc",
    "lat": 42.5872,
    "lng": 20.8206,
    "country": "Kosovo",
    "iso2": "XK",
    "iso3": "XKS",
    "admin_name": "Gllogovc",
    "capital": "admin",
    "population": 369,
    "id": 1901031751
  },
  {
    "city": "Kočevje",
    "city_ascii": "Kocevje",
    "lat": 45.643,
    "lng": 14.8594,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Kočevje",
    "capital": "admin",
    "population": null,
    "id": 1705859832
  },
  {
    "city": "Smederevo",
    "city_ascii": "Smederevo",
    "lat": 44.6633,
    "lng": 20.9289,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Smederevo",
    "capital": "admin",
    "population": null,
    "id": 1688344019
  },
  {
    "city": "Đồng Xoài",
    "city_ascii": "Dong Xoai",
    "lat": 11.5349,
    "lng": 106.8832,
    "country": "Vietnam",
    "iso2": "VN",
    "iso3": "VNM",
    "admin_name": "Bình Phước",
    "capital": "admin",
    "population": null,
    "id": 1704845487
  },
  {
    "city": "Mila",
    "city_ascii": "Mila",
    "lat": 36.4503,
    "lng": 6.2644,
    "country": "Algeria",
    "iso2": "DZ",
    "iso3": "DZA",
    "admin_name": "Mila",
    "capital": "admin",
    "population": null,
    "id": 1012656063
  },
  {
    "city": "Al Buraymī",
    "city_ascii": "Al Buraymi",
    "lat": 24.2558,
    "lng": 55.8025,
    "country": "Oman",
    "iso2": "OM",
    "iso3": "OMN",
    "admin_name": "Al Buraymī",
    "capital": "admin",
    "population": null,
    "id": 1512959921
  },
  {
    "city": "Bontoc",
    "city_ascii": "Bontoc",
    "lat": 17.0899,
    "lng": 120.978,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Mountain",
    "capital": "admin",
    "population": null,
    "id": 1608526537
  },
  {
    "city": "Đà Lạt",
    "city_ascii": "Da Lat",
    "lat": 11.9359,
    "lng": 108.4429,
    "country": "Vietnam",
    "iso2": "VN",
    "iso3": "VNM",
    "admin_name": "Lâm Đồng",
    "capital": "admin",
    "population": null,
    "id": 1704933464
  },
  {
    "city": "Luuka Town",
    "city_ascii": "Luuka Town",
    "lat": 0.7008,
    "lng": 33.3002,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Luuka",
    "capital": "admin",
    "population": null,
    "id": 1800000051
  },
  {
    "city": "Dingli",
    "city_ascii": "Dingli",
    "lat": 35.8614,
    "lng": 14.3822,
    "country": "Malta",
    "iso2": "MT",
    "iso3": "MLT",
    "admin_name": "Dingli",
    "capital": "admin",
    "population": null,
    "id": 1470281352
  },
  {
    "city": "Kabugao",
    "city_ascii": "Kabugao",
    "lat": 18.0231,
    "lng": 121.184,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Apayao",
    "capital": "admin",
    "population": null,
    "id": 1608841504
  },
  {
    "city": "Hunedoara",
    "city_ascii": "Hunedoara",
    "lat": 45.75,
    "lng": 22.9,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Hunedoara",
    "capital": "admin",
    "population": null,
    "id": 1642078107
  },
  {
    "city": "Spodnje Hoče",
    "city_ascii": "Spodnje Hoce",
    "lat": 46.5,
    "lng": 15.65,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Hoče-Slivnica",
    "capital": "admin",
    "population": null,
    "id": 1705379997
  },
  {
    "city": "Selnica ob Dravi",
    "city_ascii": "Selnica ob Dravi",
    "lat": 46.55,
    "lng": 15.495,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Selnica ob Dravi",
    "capital": "admin",
    "population": null,
    "id": 1705864774
  },
  {
    "city": "Aleksinac",
    "city_ascii": "Aleksinac",
    "lat": 43.5383,
    "lng": 21.7047,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Aleksinac",
    "capital": "admin",
    "population": null,
    "id": 1688039133
  },
  {
    "city": "Despotovac",
    "city_ascii": "Despotovac",
    "lat": 44.0833,
    "lng": 21.4333,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Despotovac",
    "capital": "admin",
    "population": null,
    "id": 1688388893
  },
  {
    "city": "Jincheng",
    "city_ascii": "Jincheng",
    "lat": 24.4367,
    "lng": 118.3183,
    "country": "Taiwan",
    "iso2": "TW",
    "iso3": "TWN",
    "admin_name": "Kinmen",
    "capital": "admin",
    "population": null,
    "id": 1158376441
  },
  {
    "city": "Šmartno",
    "city_ascii": "Smartno",
    "lat": 46.0444,
    "lng": 14.8442,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Šmartno pri Litiji",
    "capital": "admin",
    "population": null,
    "id": 1705164835
  },
  {
    "city": "Cibitoke",
    "city_ascii": "Cibitoke",
    "lat": -2.8869,
    "lng": 29.1248,
    "country": "Burundi",
    "iso2": "BI",
    "iso3": "BDI",
    "admin_name": "Cibitoke",
    "capital": "admin",
    "population": null,
    "id": 1108176356
  },
  {
    "city": "Medvode",
    "city_ascii": "Medvode",
    "lat": 46.1333,
    "lng": 14.4333,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Medvode",
    "capital": "admin",
    "population": null,
    "id": 1705158152
  },
  {
    "city": "Kibuku",
    "city_ascii": "Kibuku",
    "lat": 1.0433,
    "lng": 33.7975,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Kibuku",
    "capital": "admin",
    "population": null,
    "id": 1800098358
  },
  {
    "city": "Bupoto",
    "city_ascii": "Bupoto",
    "lat": 0.9061,
    "lng": 34.3578,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Manafwa",
    "capital": "admin",
    "population": null,
    "id": 1800358976
  },
  {
    "city": "Xagħra",
    "city_ascii": "Xaghra",
    "lat": 36.05,
    "lng": 14.2644,
    "country": "Malta",
    "iso2": "MT",
    "iso3": "MLT",
    "admin_name": "Xagħra",
    "capital": "admin",
    "population": null,
    "id": 1470781874
  },
  {
    "city": "Lenart v Slovenskih Goricah",
    "city_ascii": "Lenart v Slovenskih Goricah",
    "lat": 46.5755,
    "lng": 15.8306,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Lenart",
    "capital": "admin",
    "population": null,
    "id": 1705719159
  },
  {
    "city": "Lai Châu",
    "city_ascii": "Lai Chau",
    "lat": 22.3991,
    "lng": 103.4393,
    "country": "Vietnam",
    "iso2": "VN",
    "iso3": "VNM",
    "admin_name": "Lai Châu",
    "capital": "admin",
    "population": null,
    "id": 1704983526
  },
  {
    "city": "Neno",
    "city_ascii": "Neno",
    "lat": -15.3981,
    "lng": 34.6534,
    "country": "Malawi",
    "iso2": "MW",
    "iso3": "MWI",
    "admin_name": "Neno",
    "capital": "admin",
    "population": null,
    "id": 1454089137
  },
  {
    "city": "Moravče",
    "city_ascii": "Moravce",
    "lat": 46.1369,
    "lng": 14.745,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Moravče",
    "capital": "admin",
    "population": null,
    "id": 1705291500
  },
  {
    "city": "Apac",
    "city_ascii": "Apac",
    "lat": 1.9845,
    "lng": 32.534,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Apac",
    "capital": "admin",
    "population": null,
    "id": 1800174030
  },
  {
    "city": "Imdina",
    "city_ascii": "Imdina",
    "lat": 35.8869,
    "lng": 14.4025,
    "country": "Malta",
    "iso2": "MT",
    "iso3": "MLT",
    "admin_name": "Imdina",
    "capital": "admin",
    "population": null,
    "id": 1470959100
  },
  {
    "city": "Kladovo",
    "city_ascii": "Kladovo",
    "lat": 44.6039,
    "lng": 22.6072,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Kladovo",
    "capital": "admin",
    "population": null,
    "id": 1688823592
  },
  {
    "city": "Kanal",
    "city_ascii": "Kanal",
    "lat": 46.0886,
    "lng": 13.6397,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Kanal",
    "capital": "admin",
    "population": null,
    "id": 1705955833
  },
  {
    "city": "Nadur",
    "city_ascii": "Nadur",
    "lat": 36.0378,
    "lng": 14.2942,
    "country": "Malta",
    "iso2": "MT",
    "iso3": "MLT",
    "admin_name": "Nadur",
    "capital": "admin",
    "population": null,
    "id": 1470985739
  },
  {
    "city": "Nazarje",
    "city_ascii": "Nazarje",
    "lat": 46.3176,
    "lng": 14.9467,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Nazarje",
    "capital": "admin",
    "population": null,
    "id": 1705598784
  },
  {
    "city": "Mengeš",
    "city_ascii": "Menges",
    "lat": 46.1669,
    "lng": 14.575,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Mengeš",
    "capital": "admin",
    "population": null,
    "id": 1705274788
  },
  {
    "city": "Zreče",
    "city_ascii": "Zrece",
    "lat": 46.3833,
    "lng": 15.3667,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Zreče",
    "capital": "admin",
    "population": null,
    "id": 1705764665
  },
  {
    "city": "Preddvor",
    "city_ascii": "Preddvor",
    "lat": 46.3025,
    "lng": 14.4231,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Preddvor",
    "capital": "admin",
    "population": null,
    "id": 1705403351
  },
  {
    "city": "Rubirizi",
    "city_ascii": "Rubirizi",
    "lat": -0.2989,
    "lng": 30.1336,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Rubirizi",
    "capital": "admin",
    "population": null,
    "id": 1800947890
  },
  {
    "city": "Vuzenica",
    "city_ascii": "Vuzenica",
    "lat": 46.5992,
    "lng": 15.1669,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Vuzenica",
    "capital": "admin",
    "population": null,
    "id": 1705639122
  },
  {
    "city": "Vwawa",
    "city_ascii": "Vwawa",
    "lat": -9.1081,
    "lng": 32.9347,
    "country": "Tanzania",
    "iso2": "TZ",
    "iso3": "TZA",
    "admin_name": "Mbeya",
    "capital": "admin",
    "population": null,
    "id": 1834695780
  },
  {
    "city": "Vrhnika",
    "city_ascii": "Vrhnika",
    "lat": 45.9622,
    "lng": 14.2936,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Vrhnika",
    "capital": "admin",
    "population": null,
    "id": 1705071756
  },
  {
    "city": "Pirot",
    "city_ascii": "Pirot",
    "lat": 43.1519,
    "lng": 22.585,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Pirot",
    "capital": "admin",
    "population": null,
    "id": 1688324137
  },
  {
    "city": "Domagnano",
    "city_ascii": "Domagnano",
    "lat": 43.9492,
    "lng": 12.4686,
    "country": "San Marino",
    "iso2": "SM",
    "iso3": "SMR",
    "admin_name": "Domagnano",
    "capital": "admin",
    "population": null,
    "id": 1674267133
  },
  {
    "city": "Busesa",
    "city_ascii": "Busesa",
    "lat": 0.6263,
    "lng": 33.6003,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Iganga",
    "capital": "admin",
    "population": null,
    "id": 1800175659
  },
  {
    "city": "Sevnica",
    "city_ascii": "Sevnica",
    "lat": 46.0092,
    "lng": 15.3039,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Sevnica",
    "capital": "admin",
    "population": null,
    "id": 1705218575
  },
  {
    "city": "Poljčane",
    "city_ascii": "Poljcane",
    "lat": 46.3119,
    "lng": 15.5792,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Poljčane",
    "capital": "admin",
    "population": null,
    "id": 1705286007
  },
  {
    "city": "Vlasotince",
    "city_ascii": "Vlasotince",
    "lat": 42.9667,
    "lng": 22.1333,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Vlasotince",
    "capital": "admin",
    "population": null,
    "id": 1688116678
  },
  {
    "city": "Prosperidad",
    "city_ascii": "Prosperidad",
    "lat": 8.58,
    "lng": 125.8964,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Agusan del Sur",
    "capital": "admin",
    "population": null,
    "id": 1608461552
  },
  {
    "city": "Koani",
    "city_ascii": "Koani",
    "lat": -6.1333,
    "lng": 39.2833,
    "country": "Tanzania",
    "iso2": "TZ",
    "iso3": "TZA",
    "admin_name": "Zanzibar Central/South",
    "capital": "admin",
    "population": null,
    "id": 1834376215
  },
  {
    "city": "Dhuusamarreeb",
    "city_ascii": "Dhuusamarreeb",
    "lat": 5.5375,
    "lng": 46.3875,
    "country": "Somalia",
    "iso2": "SO",
    "iso3": "SOM",
    "admin_name": "Galguduud",
    "capital": "admin",
    "population": 447,
    "id": 1706027824
  },
  {
    "city": "Žiri",
    "city_ascii": "Ziri",
    "lat": 46.046,
    "lng": 14.1083,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Žiri",
    "capital": "admin",
    "population": null,
    "id": 1705440220
  },
  {
    "city": "Šmarje",
    "city_ascii": "Smarje",
    "lat": 46.2272,
    "lng": 15.5192,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Šmarje pri Jelšah",
    "capital": "admin",
    "population": null,
    "id": 1705373506
  },
  {
    "city": "Komenda",
    "city_ascii": "Komenda",
    "lat": 46.2038,
    "lng": 14.5407,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Komenda",
    "capital": "admin",
    "population": null,
    "id": 1705295612
  },
  {
    "city": "Metlika",
    "city_ascii": "Metlika",
    "lat": 45.6472,
    "lng": 15.3142,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Metlika",
    "capital": "admin",
    "population": null,
    "id": 1705008169
  },
  {
    "city": "Sannat",
    "city_ascii": "Sannat",
    "lat": 36.0244,
    "lng": 14.2428,
    "country": "Malta",
    "iso2": "MT",
    "iso3": "MLT",
    "admin_name": "Sannat",
    "capital": "admin",
    "population": null,
    "id": 1470546177
  },
  {
    "city": "Fada",
    "city_ascii": "Fada",
    "lat": 17.1833,
    "lng": 21.5833,
    "country": "Chad",
    "iso2": "TD",
    "iso3": "TCD",
    "admin_name": "Ennedi-Ouest",
    "capital": "admin",
    "population": 448,
    "id": 1148787282
  },
  {
    "city": "Vojnik",
    "city_ascii": "Vojnik",
    "lat": 46.2931,
    "lng": 15.3028,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Vojnik",
    "capital": "admin",
    "population": null,
    "id": 1705662463
  },
  {
    "city": "Škofljica",
    "city_ascii": "Skofljica",
    "lat": 45.9833,
    "lng": 14.5767,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Škofljica",
    "capital": "admin",
    "population": null,
    "id": 1705067758
  },
  {
    "city": "Brezovica",
    "city_ascii": "Brezovica",
    "lat": 46.0333,
    "lng": 14.4,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Brezovica",
    "capital": "admin",
    "population": null,
    "id": 1705190624
  },
  {
    "city": "Buala",
    "city_ascii": "Buala",
    "lat": -8.1448,
    "lng": 159.5905,
    "country": "Solomon Islands",
    "iso2": "SB",
    "iso3": "SLB",
    "admin_name": "Isabel",
    "capital": "admin",
    "population": null,
    "id": 1090726690
  },
  {
    "city": "Buikwe",
    "city_ascii": "Buikwe",
    "lat": 0.3375,
    "lng": 33.0106,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Buikwe",
    "capital": "admin",
    "population": null,
    "id": 1800745891
  },
  {
    "city": "Kagadi",
    "city_ascii": "Kagadi",
    "lat": 0.9378,
    "lng": 30.8089,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Kibaale",
    "capital": "admin",
    "population": null,
    "id": 1800935733
  },
  {
    "city": "Muta",
    "city_ascii": "Muta",
    "lat": 46.6114,
    "lng": 15.1661,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Muta",
    "capital": "admin",
    "population": null,
    "id": 1705459961
  },
  {
    "city": "Radenci",
    "city_ascii": "Radenci",
    "lat": 46.6472,
    "lng": 16.0442,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Radenci",
    "capital": "admin",
    "population": null,
    "id": 1705609332
  },
  {
    "city": "Log",
    "city_ascii": "Log",
    "lat": 46.0167,
    "lng": 14.3667,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Log-Dragomer",
    "capital": "admin",
    "population": null,
    "id": 1705822369
  },
  {
    "city": "Eschen",
    "city_ascii": "Eschen",
    "lat": 47.2109,
    "lng": 9.5216,
    "country": "Liechtenstein",
    "iso2": "LI",
    "iso3": "LIE",
    "admin_name": "Eschen",
    "capital": "admin",
    "population": null,
    "id": 1438692471
  },
  {
    "city": "Colonia",
    "city_ascii": "Colonia",
    "lat": 9.5144,
    "lng": 138.1292,
    "country": "Micronesia, Federated States Of",
    "iso2": "FM",
    "iso3": "FSM",
    "admin_name": "Yap",
    "capital": "admin",
    "population": null,
    "id": 1583511563
  },
  {
    "city": "Spodnji Duplek",
    "city_ascii": "Spodnji Duplek",
    "lat": 46.5031,
    "lng": 15.7453,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Duplek",
    "capital": "admin",
    "population": null,
    "id": 1705953692
  },
  {
    "city": "Bushenyi",
    "city_ascii": "Bushenyi",
    "lat": -0.5853,
    "lng": 30.2114,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Buhweju",
    "capital": "admin",
    "population": null,
    "id": 1800740334
  },
  {
    "city": "Waitangi",
    "city_ascii": "Waitangi",
    "lat": -43.951,
    "lng": -176.561,
    "country": "New Zealand",
    "iso2": "NZ",
    "iso3": "NZL",
    "admin_name": "Chatham Islands",
    "capital": "admin",
    "population": 300,
    "id": 1554847194
  },
  {
    "city": "Schellenberg",
    "city_ascii": "Schellenberg",
    "lat": 47.2283,
    "lng": 9.5395,
    "country": "Liechtenstein",
    "iso2": "LI",
    "iso3": "LIE",
    "admin_name": "Schellenberg",
    "capital": "admin",
    "population": null,
    "id": 1438649917
  },
  {
    "city": "Eenhana",
    "city_ascii": "Eenhana",
    "lat": -17.4667,
    "lng": 16.3333,
    "country": "Namibia",
    "iso2": "NA",
    "iso3": "NAM",
    "admin_name": "Ohangwena",
    "capital": "admin",
    "population": null,
    "id": 1516685856
  },
  {
    "city": "Acquaviva",
    "city_ascii": "Acquaviva",
    "lat": 43.9453,
    "lng": 12.4183,
    "country": "San Marino",
    "iso2": "SM",
    "iso3": "SMR",
    "admin_name": "Acquaviva",
    "capital": "admin",
    "population": null,
    "id": 1674696011
  },
  {
    "city": "Siġġiewi",
    "city_ascii": "Siggiewi",
    "lat": 35.8556,
    "lng": 14.4364,
    "country": "Malta",
    "iso2": "MT",
    "iso3": "MLT",
    "admin_name": "Siġġiewi",
    "capital": "admin",
    "population": null,
    "id": 1470181462
  },
  {
    "city": "Kon Tum",
    "city_ascii": "Kon Tum",
    "lat": 14.3417,
    "lng": 107.9792,
    "country": "Vietnam",
    "iso2": "VN",
    "iso3": "VNM",
    "admin_name": "Kon Tum",
    "capital": "admin",
    "population": null,
    "id": 1704988146
  },
  {
    "city": "Rabat",
    "city_ascii": "Rabat",
    "lat": 35.8822,
    "lng": 14.3981,
    "country": "Malta",
    "iso2": "MT",
    "iso3": "MLT",
    "admin_name": "Rabat",
    "capital": "admin",
    "population": null,
    "id": 1470262687
  },
  {
    "city": "Ash Shīḩānīyah",
    "city_ascii": "Ash Shihaniyah",
    "lat": 25.3709,
    "lng": 51.2226,
    "country": "Qatar",
    "iso2": "QA",
    "iso3": "QAT",
    "admin_name": "Ar Rayyān",
    "capital": "admin",
    "population": null,
    "id": 1634539520
  },
  {
    "city": "Borovnica",
    "city_ascii": "Borovnica",
    "lat": 45.918,
    "lng": 14.3642,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Borovnica",
    "capital": "admin",
    "population": null,
    "id": 1705665714
  },
  {
    "city": "Kaliro",
    "city_ascii": "Kaliro",
    "lat": 0.8949,
    "lng": 33.5048,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Kaliro",
    "capital": "admin",
    "population": null,
    "id": 1800764757
  },
  {
    "city": "Ljutomer",
    "city_ascii": "Ljutomer",
    "lat": 46.5208,
    "lng": 16.1975,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Ljutomer",
    "capital": "admin",
    "population": null,
    "id": 1705303708
  },
  {
    "city": "Aračinovo",
    "city_ascii": "Aracinovo",
    "lat": 42.0264,
    "lng": 21.5619,
    "country": "Macedonia",
    "iso2": "MK",
    "iso3": "MKD",
    "admin_name": "Aračinovo",
    "capital": "admin",
    "population": null,
    "id": 1807619539
  },
  {
    "city": "Prokuplje",
    "city_ascii": "Prokuplje",
    "lat": 43.2339,
    "lng": 21.5861,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Prokuplje",
    "capital": "admin",
    "population": null,
    "id": 1688111468
  },
  {
    "city": "Batočina",
    "city_ascii": "Batocina",
    "lat": 44.15,
    "lng": 21.0833,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Batočina",
    "capital": "admin",
    "population": null,
    "id": 1688299986
  },
  {
    "city": "Afega",
    "city_ascii": "Afega",
    "lat": -13.7973,
    "lng": -171.8531,
    "country": "Samoa",
    "iso2": "WS",
    "iso3": "WSM",
    "admin_name": "Tuamasaga",
    "capital": "admin",
    "population": null,
    "id": 1882284139
  },
  {
    "city": "Mellieħa",
    "city_ascii": "Mellieha",
    "lat": 35.9564,
    "lng": 14.3622,
    "country": "Malta",
    "iso2": "MT",
    "iso3": "MLT",
    "admin_name": "Mellieħa",
    "capital": "admin",
    "population": null,
    "id": 1470660015
  },
  {
    "city": "Umm Şalāl ‘Alī",
    "city_ascii": "Umm Salal `Ali",
    "lat": 25.4697,
    "lng": 51.3975,
    "country": "Qatar",
    "iso2": "QA",
    "iso3": "QAT",
    "admin_name": "Umm Şalāl",
    "capital": "admin",
    "population": null,
    "id": 1634646507
  },
  {
    "city": "Aileu",
    "city_ascii": "Aileu",
    "lat": -8.7281,
    "lng": 125.5664,
    "country": "Timor-Leste",
    "iso2": "TL",
    "iso3": "TLS",
    "admin_name": "Aileu",
    "capital": "admin",
    "population": null,
    "id": 1626766880
  },
  {
    "city": "Oplotnica",
    "city_ascii": "Oplotnica",
    "lat": 46.3878,
    "lng": 15.4467,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Oplotnica",
    "capital": "admin",
    "population": null,
    "id": 1705182651
  },
  {
    "city": "Fiorentino",
    "city_ascii": "Fiorentino",
    "lat": 43.9092,
    "lng": 12.4581,
    "country": "San Marino",
    "iso2": "SM",
    "iso3": "SMR",
    "admin_name": "Fiorentino",
    "capital": "admin",
    "population": null,
    "id": 1674000001
  },
  {
    "city": "Butebo",
    "city_ascii": "Butebo",
    "lat": 1.1942,
    "lng": 33.9222,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Pallisa",
    "capital": "admin",
    "population": null,
    "id": 1800710436
  },
  {
    "city": "Akjoujt",
    "city_ascii": "Akjoujt",
    "lat": 19.747,
    "lng": -14.391,
    "country": "Mauritania",
    "iso2": "MR",
    "iso3": "MRT",
    "admin_name": "Inchiri",
    "capital": "admin",
    "population": 370,
    "id": 1478247793
  },
  {
    "city": "Namutumba",
    "city_ascii": "Namutumba",
    "lat": 0.8363,
    "lng": 33.6858,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Namutumba",
    "capital": "admin",
    "population": null,
    "id": 1800673440
  },
  {
    "city": "Debe",
    "city_ascii": "Debe",
    "lat": 10.2,
    "lng": -61.45,
    "country": "Trinidad And Tobago",
    "iso2": "TT",
    "iso3": "TTO",
    "admin_name": "Penal/Debe",
    "capital": "admin",
    "population": null,
    "id": 1780510715
  },
  {
    "city": "Miragoâne",
    "city_ascii": "Miragoane",
    "lat": 18.4411,
    "lng": -73.0883,
    "country": "Haiti",
    "iso2": "HT",
    "iso3": "HTI",
    "admin_name": "Nippes",
    "capital": "admin",
    "population": null,
    "id": 1332665283
  },
  {
    "city": "Lukovica",
    "city_ascii": "Lukovica",
    "lat": 46.1667,
    "lng": 14.7,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Lukovica",
    "capital": "admin",
    "population": null,
    "id": 1705829201
  },
  {
    "city": "Dogbo",
    "city_ascii": "Dogbo",
    "lat": 6.8167,
    "lng": 1.7833,
    "country": "Benin",
    "iso2": "BJ",
    "iso3": "BEN",
    "admin_name": "Couffo",
    "capital": "admin",
    "population": null,
    "id": 1204497206
  },
  {
    "city": "Šmartno",
    "city_ascii": "Smartno",
    "lat": 46.3333,
    "lng": 15.0333,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Šmartno ob Paki",
    "capital": "admin",
    "population": null,
    "id": 1705804130
  },
  {
    "city": "Abakaliki",
    "city_ascii": "Abakaliki",
    "lat": 6.3249,
    "lng": 8.1137,
    "country": "Nigeria",
    "iso2": "NG",
    "iso3": "NGA",
    "admin_name": "Ebonyi",
    "capital": "admin",
    "population": null,
    "id": 1566879878
  },
  {
    "city": "Manafwa",
    "city_ascii": "Manafwa",
    "lat": 0.9784,
    "lng": 34.3743,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Manafwa",
    "capital": "admin",
    "population": null,
    "id": 1800257696
  },
  {
    "city": "Dimitrovgrad",
    "city_ascii": "Dimitrovgrad",
    "lat": 43.0167,
    "lng": 22.7833,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Dimitrovgrad",
    "capital": "admin",
    "population": null,
    "id": 1688139023
  },
  {
    "city": "Šenčur",
    "city_ascii": "Sencur",
    "lat": 46.2456,
    "lng": 14.4197,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Šenčur",
    "capital": "admin",
    "population": null,
    "id": 1705968659
  },
  {
    "city": "Ibanda",
    "city_ascii": "Ibanda",
    "lat": -0.1539,
    "lng": 30.5319,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Ibanda",
    "capital": "admin",
    "population": null,
    "id": 1800636734
  },
  {
    "city": "Mirna",
    "city_ascii": "Mirna",
    "lat": 45.9553,
    "lng": 15.0619,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Mirna",
    "capital": "admin",
    "population": null,
    "id": 1705033855
  },
  {
    "city": "Rače",
    "city_ascii": "Race",
    "lat": 46.4519,
    "lng": 15.6814,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Rače-Fram",
    "capital": "admin",
    "population": null,
    "id": 1705643433
  },
  {
    "city": "Lelystad",
    "city_ascii": "Lelystad",
    "lat": 52.5176,
    "lng": 5.474,
    "country": "Netherlands",
    "iso2": "NL",
    "iso3": "NLD",
    "admin_name": "Flevoland",
    "capital": "admin",
    "population": null,
    "id": 1528990721
  },
  {
    "city": "Črnomelj",
    "city_ascii": "Crnomelj",
    "lat": 45.5711,
    "lng": 15.1889,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Črnomelj",
    "capital": "admin",
    "population": null,
    "id": 1705132468
  },
  {
    "city": "Wote",
    "city_ascii": "Wote",
    "lat": -1.7808,
    "lng": 37.6288,
    "country": "Kenya",
    "iso2": "KE",
    "iso3": "KEN",
    "admin_name": "Makueni",
    "capital": "admin",
    "population": null,
    "id": 1404304549
  },
  {
    "city": "Mwatate",
    "city_ascii": "Mwatate",
    "lat": -3.505,
    "lng": 38.3772,
    "country": "Kenya",
    "iso2": "KE",
    "iso3": "KEN",
    "admin_name": "Taita/Taveta",
    "capital": "admin",
    "population": null,
    "id": 1404648092
  },
  {
    "city": "Vitanje",
    "city_ascii": "Vitanje",
    "lat": 46.3817,
    "lng": 15.2958,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Vitanje",
    "capital": "admin",
    "population": null,
    "id": 1705925648
  },
  {
    "city": "Munxar",
    "city_ascii": "Munxar",
    "lat": 36.03,
    "lng": 14.2333,
    "country": "Malta",
    "iso2": "MT",
    "iso3": "MLT",
    "admin_name": "Munxar",
    "capital": "admin",
    "population": null,
    "id": 1470116785
  },
  {
    "city": "Doljevac",
    "city_ascii": "Doljevac",
    "lat": 43.1968,
    "lng": 21.8334,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Doljevac",
    "capital": "admin",
    "population": null,
    "id": 1688354392
  },
  {
    "city": "Rukungiri",
    "city_ascii": "Rukungiri",
    "lat": -0.8411,
    "lng": 29.9419,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Rukungiri",
    "capital": "admin",
    "population": null,
    "id": 1800982268
  },
  {
    "city": "Għajnsielem",
    "city_ascii": "Ghajnsielem",
    "lat": 36.0264,
    "lng": 14.285,
    "country": "Malta",
    "iso2": "MT",
    "iso3": "MLT",
    "admin_name": "Għajnsielem",
    "capital": "admin",
    "population": null,
    "id": 1470050658
  },
  {
    "city": "Lifford",
    "city_ascii": "Lifford",
    "lat": 54.8356,
    "lng": -7.4779,
    "country": "Ireland",
    "iso2": "IE",
    "iso3": "IRL",
    "admin_name": "Donegal",
    "capital": "admin",
    "population": null,
    "id": 1372149317
  },
  {
    "city": "Isangel",
    "city_ascii": "Isangel",
    "lat": -19.5417,
    "lng": 169.2817,
    "country": "Vanuatu",
    "iso2": "VU",
    "iso3": "VUT",
    "admin_name": "Tafea",
    "capital": "admin",
    "population": null,
    "id": 1548941443
  },
  {
    "city": "Koprivnica",
    "city_ascii": "Koprivnica",
    "lat": 46.15,
    "lng": 16.8167,
    "country": "Croatia",
    "iso2": "HR",
    "iso3": "HRV",
    "admin_name": "Koprivničko-Križevačka Županija",
    "capital": "admin",
    "population": null,
    "id": 1191924250
  },
  {
    "city": "Toftir",
    "city_ascii": "Toftir",
    "lat": 62.0978,
    "lng": -6.7369,
    "country": "Faroe Islands",
    "iso2": "FO",
    "iso3": "FRO",
    "admin_name": "",
    "capital": "admin",
    "population": null,
    "id": 1234200740
  },
  {
    "city": "Gombe",
    "city_ascii": "Gombe",
    "lat": 0.1818,
    "lng": 32.1158,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Butambala",
    "capital": "admin",
    "population": null,
    "id": 1800068643
  },
  {
    "city": "Šentjernej",
    "city_ascii": "Sentjernej",
    "lat": 45.84,
    "lng": 15.3361,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Šentjernej",
    "capital": "admin",
    "population": null,
    "id": 1705329272
  },
  {
    "city": "Montegiardino",
    "city_ascii": "Montegiardino",
    "lat": 43.9092,
    "lng": 12.4833,
    "country": "San Marino",
    "iso2": "SM",
    "iso3": "SMR",
    "admin_name": "Montegiardino",
    "capital": "admin",
    "population": null,
    "id": 1674000002
  },
  {
    "city": "Kyenjojo",
    "city_ascii": "Kyenjojo",
    "lat": 0.6328,
    "lng": 30.6214,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Kyenjojo",
    "capital": "admin",
    "population": null,
    "id": 1800420914
  },
  {
    "city": "‘Ajlūn",
    "city_ascii": "`Ajlun",
    "lat": 32.3325,
    "lng": 35.7517,
    "country": "Jordan",
    "iso2": "JO",
    "iso3": "JOR",
    "admin_name": "‘Ajlūn",
    "capital": "admin",
    "population": null,
    "id": 1400775371
  },
  {
    "city": "Velika Plana",
    "city_ascii": "Velika Plana",
    "lat": 44.3333,
    "lng": 21.0833,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Velika Plana",
    "capital": "admin",
    "population": null,
    "id": 1688536735
  },
  {
    "city": "Az̧ Z̧a‘āyin",
    "city_ascii": "Az Za`ayin",
    "lat": 25.5774,
    "lng": 51.4831,
    "country": "Qatar",
    "iso2": "QA",
    "iso3": "QAT",
    "admin_name": "Az̧ Z̧a‘āyin",
    "capital": "admin",
    "population": null,
    "id": 1634890537
  },
  {
    "city": "Qala",
    "city_ascii": "Qala",
    "lat": 36.0361,
    "lng": 14.3094,
    "country": "Malta",
    "iso2": "MT",
    "iso3": "MLT",
    "admin_name": "Qala",
    "capital": "admin",
    "population": null,
    "id": 1470520722
  },
  {
    "city": "Al Jabīn",
    "city_ascii": "Al Jabin",
    "lat": 14.704,
    "lng": 43.599,
    "country": "Yemen",
    "iso2": "YE",
    "iso3": "YEM",
    "admin_name": "Raymah",
    "capital": "admin",
    "population": null,
    "id": 1887910100
  },
  {
    "city": "Mitoma",
    "city_ascii": "Mitoma",
    "lat": -0.6842,
    "lng": 30.07,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Mitooma",
    "capital": "admin",
    "population": null,
    "id": 1800173507
  },
  {
    "city": "Kalungu",
    "city_ascii": "Kalungu",
    "lat": -0.1667,
    "lng": 31.7569,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Kalungu",
    "capital": "admin",
    "population": null,
    "id": 1800770335
  },
  {
    "city": "Brvenica",
    "city_ascii": "Brvenica",
    "lat": 41.9672,
    "lng": 20.9808,
    "country": "Macedonia",
    "iso2": "MK",
    "iso3": "MKD",
    "admin_name": "Brvenica",
    "capital": "admin",
    "population": null,
    "id": 1807009322
  },
  {
    "city": "Svilajnac",
    "city_ascii": "Svilajnac",
    "lat": 44.2167,
    "lng": 21.2,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Svilajnac",
    "capital": "admin",
    "population": null,
    "id": 1688007802
  },
  {
    "city": "Aḑ Ḑāli‘",
    "city_ascii": "Ad Dali`",
    "lat": 13.6957,
    "lng": 44.7314,
    "country": "Yemen",
    "iso2": "YE",
    "iso3": "YEM",
    "admin_name": "Aḑ Ḑāli‘",
    "capital": "admin",
    "population": null,
    "id": 1887607369
  },
  {
    "city": "Santa Cruz",
    "city_ascii": "Santa Cruz",
    "lat": 14.1167,
    "lng": 121.2833,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Laguna",
    "capital": "admin",
    "population": null,
    "id": 1608131481
  },
  {
    "city": "Santa Luċija",
    "city_ascii": "Santa Lucija",
    "lat": 36.0431,
    "lng": 14.2172,
    "country": "Malta",
    "iso2": "MT",
    "iso3": "MLT",
    "admin_name": "Santa Luċija",
    "capital": "admin",
    "population": null,
    "id": 1470880833
  },
  {
    "city": "Majšperk",
    "city_ascii": "Majsperk",
    "lat": 46.3517,
    "lng": 15.7336,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Majšperk",
    "capital": "admin",
    "population": null,
    "id": 1705147774
  },
  {
    "city": "Bukomansimbi",
    "city_ascii": "Bukomansimbi",
    "lat": -0.1578,
    "lng": 31.6042,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Bukomansimbi",
    "capital": "admin",
    "population": null,
    "id": 1800146127
  },
  {
    "city": "Svrljig",
    "city_ascii": "Svrljig",
    "lat": 43.4167,
    "lng": 22.1167,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Svrljig",
    "capital": "admin",
    "population": null,
    "id": 1688519603
  },
  {
    "city": "Horjul",
    "city_ascii": "Horjul",
    "lat": 46.0236,
    "lng": 14.2992,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Horjul",
    "capital": "admin",
    "population": null,
    "id": 1705243858
  },
  {
    "city": "Postojna",
    "city_ascii": "Postojna",
    "lat": 45.7743,
    "lng": 14.2153,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Postojna",
    "capital": "admin",
    "population": null,
    "id": 1705090259
  },
  {
    "city": "Luče",
    "city_ascii": "Luce",
    "lat": 46.3561,
    "lng": 14.7467,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Luče",
    "capital": "admin",
    "population": null,
    "id": 1705042629
  },
  {
    "city": "Spodnja Hajdina",
    "city_ascii": "Spodnja Hajdina",
    "lat": 46.4089,
    "lng": 15.8469,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Hajdina",
    "capital": "admin",
    "population": null,
    "id": 1705733801
  },
  {
    "city": "Nelspruit",
    "city_ascii": "Nelspruit",
    "lat": -25.4745,
    "lng": 30.9703,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "Mpumalanga",
    "capital": "admin",
    "population": null,
    "id": 1710114438
  },
  {
    "city": "Šid",
    "city_ascii": "Sid",
    "lat": 45.1283,
    "lng": 19.2264,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Šid",
    "capital": "admin",
    "population": null,
    "id": 1688899483
  },
  {
    "city": "Negotin",
    "city_ascii": "Negotin",
    "lat": 44.2167,
    "lng": 22.5167,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Negotin",
    "capital": "admin",
    "population": null,
    "id": 1688309283
  },
  {
    "city": "Kajiado",
    "city_ascii": "Kajiado",
    "lat": -1.85,
    "lng": 36.7833,
    "country": "Kenya",
    "iso2": "KE",
    "iso3": "KEN",
    "admin_name": "Kajiado",
    "capital": "admin",
    "population": null,
    "id": 1404265412
  },
  {
    "city": "Vodice",
    "city_ascii": "Vodice",
    "lat": 46.2,
    "lng": 14.5,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Vodice",
    "capital": "admin",
    "population": null,
    "id": 1705728443
  },
  {
    "city": "Rubanda",
    "city_ascii": "Rubanda",
    "lat": -1.1883,
    "lng": 29.8461,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Kabale",
    "capital": "admin",
    "population": null,
    "id": 1800066560
  },
  {
    "city": "Dapaong",
    "city_ascii": "Dapaong",
    "lat": 10.8623,
    "lng": 0.2076,
    "country": "Togo",
    "iso2": "TG",
    "iso3": "TGO",
    "admin_name": "Savanes",
    "capital": "admin",
    "population": null,
    "id": 1768366976
  },
  {
    "city": "Pivka",
    "city_ascii": "Pivka",
    "lat": 45.6794,
    "lng": 14.1967,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Pivka",
    "capital": "admin",
    "population": null,
    "id": 1705709650
  },
  {
    "city": "Braslovče",
    "city_ascii": "Braslovce",
    "lat": 46.2897,
    "lng": 15.0389,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Braslovče",
    "capital": "admin",
    "population": null,
    "id": 1705126731
  },
  {
    "city": "Butaleja",
    "city_ascii": "Butaleja",
    "lat": 0.9166,
    "lng": 33.9563,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Butaleja",
    "capital": "admin",
    "population": null,
    "id": 1800859767
  },
  {
    "city": "Sakété",
    "city_ascii": "Sakete",
    "lat": 6.7362,
    "lng": 2.6587,
    "country": "Benin",
    "iso2": "BJ",
    "iso3": "BEN",
    "admin_name": "Plateau",
    "capital": "admin",
    "population": null,
    "id": 1204601704
  },
  {
    "city": "Tivat",
    "city_ascii": "Tivat",
    "lat": 42.43,
    "lng": 18.7,
    "country": "Montenegro",
    "iso2": "ME",
    "iso3": "MNE",
    "admin_name": "Tivat",
    "capital": "admin",
    "population": null,
    "id": 1499952005
  },
  {
    "city": "Lovrenc na Pohorju",
    "city_ascii": "Lovrenc na Pohorju",
    "lat": 46.5406,
    "lng": 15.3931,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Lovrenc na Pohorju",
    "capital": "admin",
    "population": null,
    "id": 1705376318
  },
  {
    "city": "Ruggell",
    "city_ascii": "Ruggell",
    "lat": 47.245,
    "lng": 9.5332,
    "country": "Liechtenstein",
    "iso2": "LI",
    "iso3": "LIE",
    "admin_name": "Ruggell",
    "capital": "admin",
    "population": null,
    "id": 1438787609
  },
  {
    "city": "Črna na Koroškem",
    "city_ascii": "Crna na Koroskem",
    "lat": 46.4667,
    "lng": 14.85,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Črna na Koroškem",
    "capital": "admin",
    "population": null,
    "id": 1705127725
  },
  {
    "city": "Pesnica",
    "city_ascii": "Pesnica",
    "lat": 46.6069,
    "lng": 15.6767,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Pesnica",
    "capital": "admin",
    "population": null,
    "id": 1705389455
  },
  {
    "city": "Serere",
    "city_ascii": "Serere",
    "lat": 1.518,
    "lng": 33.4589,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Serere",
    "capital": "admin",
    "population": null,
    "id": 1800171092
  },
  {
    "city": "Cerknica",
    "city_ascii": "Cerknica",
    "lat": 45.7964,
    "lng": 14.3581,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Cerknica",
    "capital": "admin",
    "population": null,
    "id": 1705932096
  },
  {
    "city": "Amuria",
    "city_ascii": "Amuria",
    "lat": 2.0036,
    "lng": 33.6511,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Amuria",
    "capital": "admin",
    "population": null,
    "id": 1800838006
  },
  {
    "city": "Gamprin",
    "city_ascii": "Gamprin",
    "lat": 47.2199,
    "lng": 9.5099,
    "country": "Liechtenstein",
    "iso2": "LI",
    "iso3": "LIE",
    "admin_name": "Gamprin",
    "capital": "admin",
    "population": null,
    "id": 1438568521
  },
  {
    "city": "Kenge",
    "city_ascii": "Kenge",
    "lat": -4.8296,
    "lng": 16.8999,
    "country": "Congo (Kinshasa)",
    "iso2": "CD",
    "iso3": "COD",
    "admin_name": "Kwango",
    "capital": "admin",
    "population": 241,
    "id": 1180158365
  },
  {
    "city": "Nsiika",
    "city_ascii": "Nsiika",
    "lat": -0.3831,
    "lng": 30.465,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Buhweju",
    "capital": "admin",
    "population": null,
    "id": 1800519756
  },
  {
    "city": "Bojnik",
    "city_ascii": "Bojnik",
    "lat": 43.0142,
    "lng": 21.718,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Bojnik",
    "capital": "admin",
    "population": null,
    "id": 1688559807
  },
  {
    "city": "Kibiito",
    "city_ascii": "Kibiito",
    "lat": 0.4772,
    "lng": 30.1925,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Kabarole",
    "capital": "admin",
    "population": null,
    "id": 1800035606
  },
  {
    "city": "Kibingo",
    "city_ascii": "Kibingo",
    "lat": -0.626,
    "lng": 30.4359,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Sheema",
    "capital": "admin",
    "population": null,
    "id": 1800484853
  },
  {
    "city": "Capellen",
    "city_ascii": "Capellen",
    "lat": 49.6444,
    "lng": 5.9897,
    "country": "Luxembourg",
    "iso2": "LU",
    "iso3": "LUX",
    "admin_name": "Capellen",
    "capital": "admin",
    "population": null,
    "id": 1442533571
  },
  {
    "city": "Bukedea",
    "city_ascii": "Bukedea",
    "lat": 1.3169,
    "lng": 34.0506,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Bukedea",
    "capital": "admin",
    "population": null,
    "id": 1800687979
  },
  {
    "city": "Dobrna",
    "city_ascii": "Dobrna",
    "lat": 46.3375,
    "lng": 15.2264,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Dobrna",
    "capital": "admin",
    "population": null,
    "id": 1705353787
  },
  {
    "city": "Samraong",
    "city_ascii": "Samraong",
    "lat": 14.1817,
    "lng": 103.5176,
    "country": "Cambodia",
    "iso2": "KH",
    "iso3": "KHM",
    "admin_name": "Oddar Meanchey",
    "capital": "admin",
    "population": null,
    "id": 1116989175
  },
  {
    "city": "Ntara",
    "city_ascii": "Ntara",
    "lat": 0.0044,
    "lng": 30.3658,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Kamwenge",
    "capital": "admin",
    "population": null,
    "id": 1800001909
  },
  {
    "city": "Wiltz",
    "city_ascii": "Wiltz",
    "lat": 49.9689,
    "lng": 5.9319,
    "country": "Luxembourg",
    "iso2": "LU",
    "iso3": "LUX",
    "admin_name": "Wiltz",
    "capital": "admin",
    "population": null,
    "id": 1442345477
  },
  {
    "city": "Binyin",
    "city_ascii": "Binyin",
    "lat": 1.4167,
    "lng": 34.5333,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Kween",
    "capital": "admin",
    "population": null,
    "id": 1800440664
  },
  {
    "city": "Šentilj",
    "city_ascii": "Sentilj",
    "lat": 46.6817,
    "lng": 15.6481,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Šentilj",
    "capital": "admin",
    "population": null,
    "id": 1705840723
  },
  {
    "city": "Kaédi",
    "city_ascii": "Kaedi",
    "lat": 16.1503,
    "lng": -13.5037,
    "country": "Mauritania",
    "iso2": "MR",
    "iso3": "MRT",
    "admin_name": "Gorgol",
    "capital": "admin",
    "population": null,
    "id": 1478607377
  },
  {
    "city": "Vransko",
    "city_ascii": "Vransko",
    "lat": 46.2439,
    "lng": 14.9514,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Vransko",
    "capital": "admin",
    "population": null,
    "id": 1705616966
  },
  {
    "city": "Lubango",
    "city_ascii": "Lubango",
    "lat": -14.9167,
    "lng": 13.5,
    "country": "Angola",
    "iso2": "AO",
    "iso3": "AGO",
    "admin_name": "Huíla",
    "capital": "admin",
    "population": null,
    "id": 1024669127
  },
  {
    "city": "Šentrupert",
    "city_ascii": "Sentrupert",
    "lat": 45.9778,
    "lng": 15.0956,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Šentrupert",
    "capital": "admin",
    "population": null,
    "id": 1705083565
  },
  {
    "city": "Mparo",
    "city_ascii": "Mparo",
    "lat": -1.1647,
    "lng": 30.0378,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Kabale",
    "capital": "admin",
    "population": null,
    "id": 1800207938
  },
  {
    "city": "Odranci",
    "city_ascii": "Odranci",
    "lat": 46.5833,
    "lng": 16.2833,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Odranci",
    "capital": "admin",
    "population": null,
    "id": 1705912877
  },
  {
    "city": "Kinoni",
    "city_ascii": "Kinoni",
    "lat": -0.6583,
    "lng": 30.4581,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Sheema",
    "capital": "admin",
    "population": null,
    "id": 1800009674
  },
  {
    "city": "Ol Kalou",
    "city_ascii": "Ol Kalou",
    "lat": -0.2643,
    "lng": 36.3788,
    "country": "Kenya",
    "iso2": "KE",
    "iso3": "KEN",
    "admin_name": "Nyandarua",
    "capital": "admin",
    "population": null,
    "id": 1404930645
  },
  {
    "city": "Logatec",
    "city_ascii": "Logatec",
    "lat": 45.9144,
    "lng": 14.2258,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Logatec",
    "capital": "admin",
    "population": null,
    "id": 1705275257
  },
  {
    "city": "Kole",
    "city_ascii": "Kole",
    "lat": 2.4002,
    "lng": 32.8003,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Kole",
    "capital": "admin",
    "population": null,
    "id": 1800826218
  },
  {
    "city": "Tržič",
    "city_ascii": "Trzic",
    "lat": 46.3667,
    "lng": 14.3167,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Tržič",
    "capital": "admin",
    "population": null,
    "id": 1705554055
  },
  {
    "city": "Żebbuġ",
    "city_ascii": "Zebbug",
    "lat": 36.0722,
    "lng": 14.2358,
    "country": "Malta",
    "iso2": "MT",
    "iso3": "MLT",
    "admin_name": "Żebbuġ (Ghawdex)",
    "capital": "admin",
    "population": null,
    "id": 1470804129
  },
  {
    "city": "Bohinjska Bistrica",
    "city_ascii": "Bohinjska Bistrica",
    "lat": 46.2769,
    "lng": 13.955,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Bohinj",
    "capital": "admin",
    "population": null,
    "id": 1705094350
  },
  {
    "city": "Zombo",
    "city_ascii": "Zombo",
    "lat": 2.5135,
    "lng": 30.9091,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Zombo",
    "capital": "admin",
    "population": null,
    "id": 1800293674
  },
  {
    "city": "Narok",
    "city_ascii": "Narok",
    "lat": -1.0833,
    "lng": 35.8667,
    "country": "Kenya",
    "iso2": "KE",
    "iso3": "KEN",
    "admin_name": "Narok",
    "capital": "admin",
    "population": null,
    "id": 1404459814
  },
  {
    "city": "Rakai",
    "city_ascii": "Rakai",
    "lat": -0.72,
    "lng": 31.4839,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Rakai",
    "capital": "admin",
    "population": null,
    "id": 1800039440
  },
  {
    "city": "Encamp",
    "city_ascii": "Encamp",
    "lat": 42.5167,
    "lng": 1.5667,
    "country": "Andorra",
    "iso2": "AD",
    "iso3": "AND",
    "admin_name": "Encamp",
    "capital": "admin",
    "population": null,
    "id": 1020417470
  },
  {
    "city": "Lwengo",
    "city_ascii": "Lwengo",
    "lat": -0.4161,
    "lng": 31.4081,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Lwengo",
    "capital": "admin",
    "population": null,
    "id": 1800583650
  },
  {
    "city": "Trzin",
    "city_ascii": "Trzin",
    "lat": 46.1333,
    "lng": 14.5667,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Trzin",
    "capital": "admin",
    "population": null,
    "id": 1705707595
  },
  {
    "city": "Rečica",
    "city_ascii": "Recica",
    "lat": 46.3167,
    "lng": 14.9167,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Rečica ob Savinji",
    "capital": "admin",
    "population": null,
    "id": 1705082644
  },
  {
    "city": "Heydərabad",
    "city_ascii": "Heydarabad",
    "lat": 39.7229,
    "lng": 44.8485,
    "country": "Azerbaijan",
    "iso2": "AZ",
    "iso3": "AZE",
    "admin_name": "Sədərək",
    "capital": "admin",
    "population": null,
    "id": 1031540160
  },
  {
    "city": "Gorišnica",
    "city_ascii": "Gorisnica",
    "lat": 46.4147,
    "lng": 16.0139,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Gorišnica",
    "capital": "admin",
    "population": null,
    "id": 1705561619
  },
  {
    "city": "Imġarr",
    "city_ascii": "Imgarr",
    "lat": 35.9206,
    "lng": 14.3664,
    "country": "Malta",
    "iso2": "MT",
    "iso3": "MLT",
    "admin_name": "Imġarr",
    "capital": "admin",
    "population": null,
    "id": 1470038163
  },
  {
    "city": "Bukwo",
    "city_ascii": "Bukwo",
    "lat": 1.3,
    "lng": 34.75,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Bukwa",
    "capital": "admin",
    "population": null,
    "id": 1800276050
  },
  {
    "city": "Omuthiya",
    "city_ascii": "Omuthiya",
    "lat": -18.3592,
    "lng": 16.5795,
    "country": "Namibia",
    "iso2": "NA",
    "iso3": "NAM",
    "admin_name": "Oshikoto",
    "capital": "admin",
    "population": null,
    "id": 1516000004
  },
  {
    "city": "Volčja Draga",
    "city_ascii": "Volcja Draga",
    "lat": 45.9069,
    "lng": 13.6775,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Renče-Vogrsko",
    "capital": "admin",
    "population": null,
    "id": 1705547222
  },
  {
    "city": "Kasanda",
    "city_ascii": "Kasanda",
    "lat": 0.5567,
    "lng": 31.8022,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Mubende",
    "capital": "admin",
    "population": null,
    "id": 1800794590
  },
  {
    "city": "Dokolo",
    "city_ascii": "Dokolo",
    "lat": 1.9167,
    "lng": 33.172,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Dokolo",
    "capital": "admin",
    "population": null,
    "id": 1800478941
  },
  {
    "city": "Dornava",
    "city_ascii": "Dornava",
    "lat": 46.4367,
    "lng": 15.9536,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Dornava",
    "capital": "admin",
    "population": null,
    "id": 1705023699
  },
  {
    "city": "Leulumoega",
    "city_ascii": "Leulumoega",
    "lat": -13.823,
    "lng": -171.9613,
    "country": "Samoa",
    "iso2": "WS",
    "iso3": "WSM",
    "admin_name": "A‘ana",
    "capital": "admin",
    "population": null,
    "id": 1882782502
  },
  {
    "city": "Koronadal",
    "city_ascii": "Koronadal",
    "lat": 6.2541,
    "lng": 124.9922,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "South Cotabato",
    "capital": "admin",
    "population": null,
    "id": 1608108139
  },
  {
    "city": "Beltinci",
    "city_ascii": "Beltinci",
    "lat": 46.6053,
    "lng": 16.2406,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Beltinci",
    "capital": "admin",
    "population": null,
    "id": 1705238814
  },
  {
    "city": "Topola",
    "city_ascii": "Topola",
    "lat": 44.2525,
    "lng": 20.6761,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Topola",
    "capital": "admin",
    "population": null,
    "id": 1688000008
  },
  {
    "city": "Bogovinje",
    "city_ascii": "Bogovinje",
    "lat": 41.9236,
    "lng": 20.9136,
    "country": "Macedonia",
    "iso2": "MK",
    "iso3": "MKD",
    "admin_name": "Bogovinje",
    "capital": "admin",
    "population": null,
    "id": 1807279534
  },
  {
    "city": "Balzers",
    "city_ascii": "Balzers",
    "lat": 47.0667,
    "lng": 9.5025,
    "country": "Liechtenstein",
    "iso2": "LI",
    "iso3": "LIE",
    "admin_name": "Balzers",
    "capital": "admin",
    "population": null,
    "id": 1438263000
  },
  {
    "city": "Kamwenge",
    "city_ascii": "Kamwenge",
    "lat": 0.2111,
    "lng": 30.4208,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Kamwenge",
    "capital": "admin",
    "population": null,
    "id": 1800010095
  },
  {
    "city": "Železniki",
    "city_ascii": "Zelezniki",
    "lat": 46.2333,
    "lng": 14.1667,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Železniki",
    "capital": "admin",
    "population": null,
    "id": 1705885002
  },
  {
    "city": "Ngora",
    "city_ascii": "Ngora",
    "lat": 1.4314,
    "lng": 33.7772,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Ngora",
    "capital": "admin",
    "population": null,
    "id": 1800020551
  },
  {
    "city": "Kara",
    "city_ascii": "Kara",
    "lat": 9.5511,
    "lng": 1.1861,
    "country": "Togo",
    "iso2": "TG",
    "iso3": "TGO",
    "admin_name": "Kara",
    "capital": "admin",
    "population": null,
    "id": 1768768827
  },
  {
    "city": "Dol",
    "city_ascii": "Dol",
    "lat": 46.0886,
    "lng": 14.6008,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Dol pri Ljubljani",
    "capital": "admin",
    "population": null,
    "id": 1705717325
  },
  {
    "city": "Ig",
    "city_ascii": "Ig",
    "lat": 45.9603,
    "lng": 14.5289,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Ig",
    "capital": "admin",
    "population": null,
    "id": 1705409266
  },
  {
    "city": "Techiman",
    "city_ascii": "Techiman",
    "lat": 7.5905,
    "lng": -1.9395,
    "country": "Ghana",
    "iso2": "GH",
    "iso3": "GHA",
    "admin_name": "Brong-Ahafo",
    "capital": "admin",
    "population": null,
    "id": 1288222236
  },
  {
    "city": "Guadalupe",
    "city_ascii": "Guadalupe",
    "lat": 0.3792,
    "lng": 6.6375,
    "country": "Sao Tome And Principe",
    "iso2": "ST",
    "iso3": "STP",
    "admin_name": "São Tomé",
    "capital": "admin",
    "population": null,
    "id": 1678998597
  },
  {
    "city": "Alebtong",
    "city_ascii": "Alebtong",
    "lat": 2.2447,
    "lng": 33.2547,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Alebtong",
    "capital": "admin",
    "population": null,
    "id": 1800244501
  },
  {
    "city": "Brčko",
    "city_ascii": "Brcko",
    "lat": 44.8783,
    "lng": 18.8092,
    "country": "Bosnia And Herzegovina",
    "iso2": "BA",
    "iso3": "BIH",
    "admin_name": "Brcko District",
    "capital": "admin",
    "population": null,
    "id": 1070370826
  },
  {
    "city": "Gorenja Vas",
    "city_ascii": "Gorenja Vas",
    "lat": 46.1072,
    "lng": 14.1481,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Gorenja Vas-Poljane",
    "capital": "admin",
    "population": null,
    "id": 1705961189
  },
  {
    "city": "Pante Macassar",
    "city_ascii": "Pante Macassar",
    "lat": -9.2,
    "lng": 124.3833,
    "country": "Timor-Leste",
    "iso2": "TL",
    "iso3": "TLS",
    "admin_name": "Oecussi-Ambeno",
    "capital": "admin",
    "population": null,
    "id": 1626175417
  },
  {
    "city": "Għarb",
    "city_ascii": "Gharb",
    "lat": 36.06,
    "lng": 14.2089,
    "country": "Malta",
    "iso2": "MT",
    "iso3": "MLT",
    "admin_name": "Għarb",
    "capital": "admin",
    "population": null,
    "id": 1470113453
  },
  {
    "city": "Cerkno",
    "city_ascii": "Cerkno",
    "lat": 46.1256,
    "lng": 13.9817,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Cerkno",
    "capital": "admin",
    "population": null,
    "id": 1705759580
  },
  {
    "city": "Suai",
    "city_ascii": "Suai",
    "lat": -9.3129,
    "lng": 125.2565,
    "country": "Timor-Leste",
    "iso2": "TL",
    "iso3": "TLS",
    "admin_name": "Cova Lima",
    "capital": "admin",
    "population": null,
    "id": 1626458227
  },
  {
    "city": "Lospalos",
    "city_ascii": "Lospalos",
    "lat": -8.5167,
    "lng": 127.0333,
    "country": "Timor-Leste",
    "iso2": "TL",
    "iso3": "TLS",
    "admin_name": "Lautém",
    "capital": "admin",
    "population": null,
    "id": 1626878399
  },
  {
    "city": "Stari Trg",
    "city_ascii": "Stari Trg",
    "lat": 45.7128,
    "lng": 14.4694,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Loška Dolina",
    "capital": "admin",
    "population": null,
    "id": 1705849784
  },
  {
    "city": "Chiesanuova",
    "city_ascii": "Chiesanuova",
    "lat": 43.9061,
    "lng": 12.4214,
    "country": "San Marino",
    "iso2": "SM",
    "iso3": "SMR",
    "admin_name": "Chiesanuova",
    "capital": "admin",
    "population": null,
    "id": 1674546686
  },
  {
    "city": "Sveti Jurij",
    "city_ascii": "Sveti Jurij",
    "lat": 46.5695,
    "lng": 16.0235,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Sveti Jurij ob Ščavnici",
    "capital": "admin",
    "population": null,
    "id": 1705832384
  },
  {
    "city": "Pombas",
    "city_ascii": "Pombas",
    "lat": 17.1503,
    "lng": -25.0201,
    "country": "Cabo Verde",
    "iso2": "CV",
    "iso3": "CPV",
    "admin_name": "Paul",
    "capital": "admin",
    "population": null,
    "id": 1132720315
  },
  {
    "city": "Videm",
    "city_ascii": "Videm",
    "lat": 45.85,
    "lng": 14.6942,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Dobrepolje",
    "capital": "admin",
    "population": null,
    "id": 1705074870
  },
  {
    "city": "Kanungu",
    "city_ascii": "Kanungu",
    "lat": -0.9575,
    "lng": 29.7897,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Kanungu",
    "capital": "admin",
    "population": null,
    "id": 1800715667
  },
  {
    "city": "Ljubno",
    "city_ascii": "Ljubno",
    "lat": 46.3456,
    "lng": 14.835,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Ljubno",
    "capital": "admin",
    "population": null,
    "id": 1705083617
  },
  {
    "city": "Ulbroka",
    "city_ascii": "Ulbroka",
    "lat": 56.9363,
    "lng": 24.3039,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Stopiņu Novads",
    "capital": "admin",
    "population": null,
    "id": 1428511061
  },
  {
    "city": "Straža",
    "city_ascii": "Straza",
    "lat": 45.78,
    "lng": 15.0728,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Straža",
    "capital": "admin",
    "population": null,
    "id": 1705491164
  },
  {
    "city": "Kanoni",
    "city_ascii": "Kanoni",
    "lat": 0.1772,
    "lng": 31.8811,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Gomba",
    "capital": "admin",
    "population": null,
    "id": 1800973979
  },
  {
    "city": "Kokopo",
    "city_ascii": "Kokopo",
    "lat": -4.35,
    "lng": 152.2736,
    "country": "Papua New Guinea",
    "iso2": "PG",
    "iso3": "PNG",
    "admin_name": "East New Britain",
    "capital": "admin",
    "population": null,
    "id": 1598823962
  },
  {
    "city": "Oranjestad",
    "city_ascii": "Oranjestad",
    "lat": 17.4817,
    "lng": -62.9831,
    "country": "Netherlands",
    "iso2": "NL",
    "iso3": "NLD",
    "admin_name": "",
    "capital": "admin",
    "population": null,
    "id": 1528346417
  },
  {
    "city": "Sejong",
    "city_ascii": "Sejong",
    "lat": 36.6092,
    "lng": 127.2919,
    "country": "Korea, South",
    "iso2": "KR",
    "iso3": "KOR",
    "admin_name": "Sejong",
    "capital": "admin",
    "population": null,
    "id": 1410002592
  },
  {
    "city": "Tišina",
    "city_ascii": "Tisina",
    "lat": 46.6556,
    "lng": 16.0933,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Tišina",
    "capital": "admin",
    "population": null,
    "id": 1705811179
  },
  {
    "city": "Ramotswa",
    "city_ascii": "Ramotswa",
    "lat": -24.8667,
    "lng": 25.8167,
    "country": "Botswana",
    "iso2": "BW",
    "iso3": "BWA",
    "admin_name": "South East",
    "capital": "admin",
    "population": null,
    "id": 1072070629
  },
  {
    "city": "Mulifanua",
    "city_ascii": "Mulifanua",
    "lat": -13.8318,
    "lng": -172.036,
    "country": "Samoa",
    "iso2": "WS",
    "iso3": "WSM",
    "admin_name": "Aiga-i-le-Tai",
    "capital": "admin",
    "population": null,
    "id": 1882029963
  },
  {
    "city": "Šmarješke Toplice",
    "city_ascii": "Smarjeske Toplice",
    "lat": 45.862,
    "lng": 15.2231,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Šmarješke Toplice",
    "capital": "admin",
    "population": null,
    "id": 1705361649
  },
  {
    "city": "Vrapčište",
    "city_ascii": "Vrapciste",
    "lat": 41.8344,
    "lng": 20.8856,
    "country": "Macedonia",
    "iso2": "MK",
    "iso3": "MKD",
    "admin_name": "Vrapčište",
    "capital": "admin",
    "population": null,
    "id": 1807187187
  },
  {
    "city": "Kidričevo",
    "city_ascii": "Kidricevo",
    "lat": 46.4036,
    "lng": 15.7911,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Kidričevo",
    "capital": "admin",
    "population": null,
    "id": 1705321982
  },
  {
    "city": "Semič",
    "city_ascii": "Semic",
    "lat": 45.6461,
    "lng": 15.1822,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Semič",
    "capital": "admin",
    "population": null,
    "id": 1705653513
  },
  {
    "city": "Oyam",
    "city_ascii": "Oyam",
    "lat": 2.2141,
    "lng": 32.3703,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Oyam",
    "capital": "admin",
    "population": null,
    "id": 1800867016
  },
  {
    "city": "Požega",
    "city_ascii": "Pozega",
    "lat": 45.3314,
    "lng": 17.6744,
    "country": "Croatia",
    "iso2": "HR",
    "iso3": "HRV",
    "admin_name": "Požeško-Slavonska Županija",
    "capital": "admin",
    "population": null,
    "id": 1191353181
  },
  {
    "city": "Ilinden",
    "city_ascii": "Ilinden",
    "lat": 41.9945,
    "lng": 21.58,
    "country": "Macedonia",
    "iso2": "MK",
    "iso3": "MKD",
    "admin_name": "Ilinden",
    "capital": "admin",
    "population": null,
    "id": 1807767601
  },
  {
    "city": "Starše",
    "city_ascii": "Starse",
    "lat": 46.4658,
    "lng": 15.7672,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Starše",
    "capital": "admin",
    "population": null,
    "id": 1705557844
  },
  {
    "city": "Dobrovo",
    "city_ascii": "Dobrovo",
    "lat": 45.9964,
    "lng": 13.5264,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Brda",
    "capital": "admin",
    "population": null,
    "id": 1705226459
  },
  {
    "city": "Turnišče",
    "city_ascii": "Turnisce",
    "lat": 46.6278,
    "lng": 16.3203,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Turnišče",
    "capital": "admin",
    "population": null,
    "id": 1705851676
  },
  {
    "city": "Gia Nghĩa",
    "city_ascii": "Gia Nghia",
    "lat": 12.0042,
    "lng": 107.6907,
    "country": "Vietnam",
    "iso2": "VN",
    "iso3": "VNM",
    "admin_name": "Đắk Nông",
    "capital": "admin",
    "population": null,
    "id": 1704361005
  },
  {
    "city": "Blace",
    "city_ascii": "Blace",
    "lat": 43.2906,
    "lng": 21.2847,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Blace",
    "capital": "admin",
    "population": null,
    "id": 1688233004
  },
  {
    "city": "La Massana",
    "city_ascii": "La Massana",
    "lat": 42.5434,
    "lng": 1.5148,
    "country": "Andorra",
    "iso2": "AD",
    "iso3": "AND",
    "admin_name": "La Massana",
    "capital": "admin",
    "population": null,
    "id": 1020543453
  },
  {
    "city": "Tabor",
    "city_ascii": "Tabor",
    "lat": 46.2361,
    "lng": 15.0183,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Tabor",
    "capital": "admin",
    "population": null,
    "id": 1705756002
  },
  {
    "city": "Saltangará",
    "city_ascii": "Saltangara",
    "lat": 62.1156,
    "lng": -6.7206,
    "country": "Faroe Islands",
    "iso2": "FO",
    "iso3": "FRO",
    "admin_name": "",
    "capital": "admin",
    "population": null,
    "id": 1234473077
  },
  {
    "city": "Križevci",
    "city_ascii": "Krizevci",
    "lat": 46.5683,
    "lng": 16.1386,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Križevci",
    "capital": "admin",
    "population": null,
    "id": 1705138690
  },
  {
    "city": "Siparia",
    "city_ascii": "Siparia",
    "lat": 10.1453,
    "lng": -61.5074,
    "country": "Trinidad And Tobago",
    "iso2": "TT",
    "iso3": "TTO",
    "admin_name": "Siparia",
    "capital": "admin",
    "population": null,
    "id": 1780418671
  },
  {
    "city": "Tutong",
    "city_ascii": "Tutong",
    "lat": 4.8028,
    "lng": 114.6492,
    "country": "Brunei",
    "iso2": "BN",
    "iso3": "BRN",
    "admin_name": "Tutong",
    "capital": "admin",
    "population": null,
    "id": 1096181037
  },
  {
    "city": "Sembabule",
    "city_ascii": "Sembabule",
    "lat": -0.0772,
    "lng": 31.4567,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Sembabule",
    "capital": "admin",
    "population": null,
    "id": 1800497536
  },
  {
    "city": "Vianden",
    "city_ascii": "Vianden",
    "lat": 49.935,
    "lng": 6.2089,
    "country": "Luxembourg",
    "iso2": "LU",
    "iso3": "LUX",
    "admin_name": "Vianden",
    "capital": "admin",
    "population": null,
    "id": 1442979391
  },
  {
    "city": "Tsirang",
    "city_ascii": "Tsirang",
    "lat": 27.0219,
    "lng": 90.1229,
    "country": "Bhutan",
    "iso2": "BT",
    "iso3": "BTN",
    "admin_name": "Tsirang",
    "capital": "admin",
    "population": null,
    "id": 1064617460
  },
  {
    "city": "Kakumiro",
    "city_ascii": "Kakumiro",
    "lat": 0.7806,
    "lng": 31.3236,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Kibaale",
    "capital": "admin",
    "population": null,
    "id": 1800894285
  },
  {
    "city": "Pailin",
    "city_ascii": "Pailin",
    "lat": 12.8489,
    "lng": 102.6093,
    "country": "Cambodia",
    "iso2": "KH",
    "iso3": "KHM",
    "admin_name": "Pailin",
    "capital": "admin",
    "population": null,
    "id": 1116050327
  },
  {
    "city": "Petrovec",
    "city_ascii": "Petrovec",
    "lat": 41.9389,
    "lng": 21.615,
    "country": "Macedonia",
    "iso2": "MK",
    "iso3": "MKD",
    "admin_name": "Petrovec",
    "capital": "admin",
    "population": null,
    "id": 1807236473
  },
  {
    "city": "Sveta Trojica v Slovenskih Goricah",
    "city_ascii": "Sveta Trojica v Slovenskih Goricah",
    "lat": 46.5767,
    "lng": 15.8769,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Sveta Trojica v Slovenskih Goricah",
    "capital": "admin",
    "population": null,
    "id": 1705659496
  },
  {
    "city": "Sveta Ana",
    "city_ascii": "Sveta Ana",
    "lat": 46.6492,
    "lng": 15.8442,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Sveta Ana",
    "capital": "admin",
    "population": null,
    "id": 1705062986
  },
  {
    "city": "Tolmin",
    "city_ascii": "Tolmin",
    "lat": 46.1864,
    "lng": 13.7361,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Tolmin",
    "capital": "admin",
    "population": null,
    "id": 1705602247
  },
  {
    "city": "Rabak",
    "city_ascii": "Rabak",
    "lat": 13.188,
    "lng": 32.7437,
    "country": "Sudan",
    "iso2": "SD",
    "iso3": "SDN",
    "admin_name": "White Nile",
    "capital": "admin",
    "population": null,
    "id": 1729452233
  },
  {
    "city": "Princes Town",
    "city_ascii": "Princes Town",
    "lat": 10.2718,
    "lng": -61.371,
    "country": "Trinidad And Tobago",
    "iso2": "TT",
    "iso3": "TTO",
    "admin_name": "Princes Town",
    "capital": "admin",
    "population": null,
    "id": 1780714817
  },
  {
    "city": "Tarrafal",
    "city_ascii": "Tarrafal",
    "lat": 16.5667,
    "lng": -24.3556,
    "country": "Cabo Verde",
    "iso2": "CV",
    "iso3": "CPV",
    "admin_name": "Tarrafal de São Nicolau",
    "capital": "admin",
    "population": null,
    "id": 1132502171
  },
  {
    "city": "Triesen",
    "city_ascii": "Triesen",
    "lat": 47.11,
    "lng": 9.5239,
    "country": "Liechtenstein",
    "iso2": "LI",
    "iso3": "LIE",
    "admin_name": "Triesen",
    "capital": "admin",
    "population": null,
    "id": 1438826448
  },
  {
    "city": "Benedikt",
    "city_ascii": "Benedikt",
    "lat": 46.6086,
    "lng": 15.8883,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Benedikt",
    "capital": "admin",
    "population": null,
    "id": 1705523597
  },
  {
    "city": "Studeničani",
    "city_ascii": "Studenicani",
    "lat": 41.9158,
    "lng": 21.5306,
    "country": "Macedonia",
    "iso2": "MK",
    "iso3": "MKD",
    "admin_name": "Studeničani",
    "capital": "admin",
    "population": null,
    "id": 1807047160
  },
  {
    "city": "Sant Julià de Lòria",
    "city_ascii": "Sant Julia de Loria",
    "lat": 42.4664,
    "lng": 1.4933,
    "country": "Andorra",
    "iso2": "AD",
    "iso3": "AND",
    "admin_name": "Sant Julià de Lòria",
    "capital": "admin",
    "population": null,
    "id": 1020885733
  },
  {
    "city": "Faetano",
    "city_ascii": "Faetano",
    "lat": 43.9261,
    "lng": 12.4981,
    "country": "San Marino",
    "iso2": "SM",
    "iso3": "SMR",
    "admin_name": "Faetano",
    "capital": "admin",
    "population": null,
    "id": 1674994606
  },
  {
    "city": "Buyende",
    "city_ascii": "Buyende",
    "lat": 1.1517,
    "lng": 33.155,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Buyende",
    "capital": "admin",
    "population": null,
    "id": 1800567800
  },
  {
    "city": "João Teves",
    "city_ascii": "Joao Teves",
    "lat": 15.0669,
    "lng": -23.5892,
    "country": "Cabo Verde",
    "iso2": "CV",
    "iso3": "CPV",
    "admin_name": "São Lourenço dos Órgãos",
    "capital": "admin",
    "population": null,
    "id": 1132758433
  },
  {
    "city": "Santana",
    "city_ascii": "Santana",
    "lat": 0.26,
    "lng": 6.7414,
    "country": "Sao Tome And Principe",
    "iso2": "ST",
    "iso3": "STP",
    "admin_name": "São Tomé",
    "capital": "admin",
    "population": null,
    "id": 1678081464
  },
  {
    "city": "Kalaki",
    "city_ascii": "Kalaki",
    "lat": 1.816,
    "lng": 33.337,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Kaberamaido",
    "capital": "admin",
    "population": null,
    "id": 1800972565
  },
  {
    "city": "Kwale",
    "city_ascii": "Kwale",
    "lat": -4.1737,
    "lng": 39.4521,
    "country": "Kenya",
    "iso2": "KE",
    "iso3": "KEN",
    "admin_name": "Kwale",
    "capital": "admin",
    "population": null,
    "id": 1404769042
  },
  {
    "city": "Isingiro",
    "city_ascii": "Isingiro",
    "lat": -0.8686,
    "lng": 30.8302,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Isingiro",
    "capital": "admin",
    "population": null,
    "id": 1800843586
  },
  {
    "city": "Apatin",
    "city_ascii": "Apatin",
    "lat": 45.6667,
    "lng": 18.9833,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Apatin",
    "capital": "admin",
    "population": null,
    "id": 1688081748
  },
  {
    "city": "Mārupe",
    "city_ascii": "Marupe",
    "lat": 56.9032,
    "lng": 24.0467,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Mārupes Novads",
    "capital": "admin",
    "population": null,
    "id": 1428162714
  },
  {
    "city": "Kyegegwa",
    "city_ascii": "Kyegegwa",
    "lat": 0.5022,
    "lng": 31.0414,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Kyegegwa",
    "capital": "admin",
    "population": null,
    "id": 1800719333
  },
  {
    "city": "San Lawrenz",
    "city_ascii": "San Lawrenz",
    "lat": 36.0556,
    "lng": 14.2036,
    "country": "Malta",
    "iso2": "MT",
    "iso3": "MLT",
    "admin_name": "San Lawrenz",
    "capital": "admin",
    "population": null,
    "id": 1470602161
  },
  {
    "city": "Radeče",
    "city_ascii": "Radece",
    "lat": 46.0659,
    "lng": 15.1729,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Radeče",
    "capital": "admin",
    "population": null,
    "id": 1705420341
  },
  {
    "city": "Sefwi Wiawso",
    "city_ascii": "Sefwi Wiawso",
    "lat": 6.2058,
    "lng": -2.4894,
    "country": "Ghana",
    "iso2": "GH",
    "iso3": "GHA",
    "admin_name": "Western",
    "capital": "admin",
    "population": null,
    "id": 1288994228
  },
  {
    "city": "Mersch",
    "city_ascii": "Mersch",
    "lat": 49.7489,
    "lng": 6.1061,
    "country": "Luxembourg",
    "iso2": "LU",
    "iso3": "LUX",
    "admin_name": "Mersch",
    "capital": "admin",
    "population": null,
    "id": 1442283324
  },
  {
    "city": "Ucar",
    "city_ascii": "Ucar",
    "lat": 40.5121,
    "lng": 47.6519,
    "country": "Azerbaijan",
    "iso2": "AZ",
    "iso3": "AZE",
    "admin_name": "Ucar",
    "capital": "admin",
    "population": null,
    "id": 1031822434
  },
  {
    "city": "Dolenjske Toplice",
    "city_ascii": "Dolenjske Toplice",
    "lat": 45.7667,
    "lng": 15.0667,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Dolenjske Toplice",
    "capital": "admin",
    "population": null,
    "id": 1705395845
  },
  {
    "city": "Hongseong",
    "city_ascii": "Hongseong",
    "lat": 36.6009,
    "lng": 126.665,
    "country": "Korea, South",
    "iso2": "KR",
    "iso3": "KOR",
    "admin_name": "Chungnam",
    "capital": "admin",
    "population": null,
    "id": 1410822139
  },
  {
    "city": "Strendur",
    "city_ascii": "Strendur",
    "lat": 62.1096,
    "lng": -6.7617,
    "country": "Faroe Islands",
    "iso2": "FO",
    "iso3": "FRO",
    "admin_name": "",
    "capital": "admin",
    "population": null,
    "id": 1234814533
  },
  {
    "city": "Tearce",
    "city_ascii": "Tearce",
    "lat": 42.0767,
    "lng": 21.0531,
    "country": "Macedonia",
    "iso2": "MK",
    "iso3": "MKD",
    "admin_name": "Tearce",
    "capital": "admin",
    "population": null,
    "id": 1807139125
  },
  {
    "city": "Tuzi",
    "city_ascii": "Tuzi",
    "lat": 42.3656,
    "lng": 19.3314,
    "country": "Montenegro",
    "iso2": "ME",
    "iso3": "MNE",
    "admin_name": "Podgorica",
    "capital": "admin",
    "population": null,
    "id": 1499325970
  },
  {
    "city": "Veržej",
    "city_ascii": "Verzej",
    "lat": 46.5836,
    "lng": 16.1653,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Veržej",
    "capital": "admin",
    "population": null,
    "id": 1705529436
  },
  {
    "city": "Zgornja Kungota",
    "city_ascii": "Zgornja Kungota",
    "lat": 46.6392,
    "lng": 15.6156,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Kungota",
    "capital": "admin",
    "population": null,
    "id": 1705104301
  },
  {
    "city": "Ćuprija",
    "city_ascii": "Cuprija",
    "lat": 43.9231,
    "lng": 21.3686,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Ćuprija",
    "capital": "admin",
    "population": null,
    "id": 1688642855
  },
  {
    "city": "Muan",
    "city_ascii": "Muan",
    "lat": 34.9897,
    "lng": 126.4714,
    "country": "Korea, South",
    "iso2": "KR",
    "iso3": "KOR",
    "admin_name": "Jeonnam",
    "capital": "admin",
    "population": null,
    "id": 1410001061
  },
  {
    "city": "Jegunovce",
    "city_ascii": "Jegunovce",
    "lat": 42.0724,
    "lng": 21.1237,
    "country": "Macedonia",
    "iso2": "MK",
    "iso3": "MKD",
    "admin_name": "Jegunovce",
    "capital": "admin",
    "population": null,
    "id": 1807040114
  },
  {
    "city": "Črenšovci",
    "city_ascii": "Crensovci",
    "lat": 46.5744,
    "lng": 16.2906,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Črenšovci",
    "capital": "admin",
    "population": null,
    "id": 1705698578
  },
  {
    "city": "Želino",
    "city_ascii": "Zelino",
    "lat": 41.9825,
    "lng": 21.0624,
    "country": "Macedonia",
    "iso2": "MK",
    "iso3": "MKD",
    "admin_name": "Želino",
    "capital": "admin",
    "population": null,
    "id": 1807040075
  },
  {
    "city": "The Bottom",
    "city_ascii": "The Bottom",
    "lat": 17.6265,
    "lng": -63.2497,
    "country": "Netherlands",
    "iso2": "NL",
    "iso3": "NLD",
    "admin_name": "",
    "capital": "admin",
    "population": null,
    "id": 1528203421
  },
  {
    "city": "Mirna Peč",
    "city_ascii": "Mirna Pec",
    "lat": 45.8603,
    "lng": 15.0833,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Mirna Peč",
    "capital": "admin",
    "population": null,
    "id": 1705096910
  },
  {
    "city": "Saratamata",
    "city_ascii": "Saratamata",
    "lat": -15.2869,
    "lng": 167.9906,
    "country": "Vanuatu",
    "iso2": "VU",
    "iso3": "VUT",
    "admin_name": "Penama",
    "capital": "admin",
    "population": null,
    "id": 1548907051
  },
  {
    "city": "Rumuruti",
    "city_ascii": "Rumuruti",
    "lat": 0.2725,
    "lng": 36.5381,
    "country": "Kenya",
    "iso2": "KE",
    "iso3": "KEN",
    "admin_name": "Laikipia",
    "capital": "admin",
    "population": null,
    "id": 1404005234
  },
  {
    "city": "Arilje",
    "city_ascii": "Arilje",
    "lat": 43.7519,
    "lng": 20.0906,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Arilje",
    "capital": "admin",
    "population": null,
    "id": 1688798017
  },
  {
    "city": "Kovin",
    "city_ascii": "Kovin",
    "lat": 44.7475,
    "lng": 20.9761,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Kovin",
    "capital": "admin",
    "population": null,
    "id": 1688830508
  },
  {
    "city": "Butalangu",
    "city_ascii": "Butalangu",
    "lat": 0.7011,
    "lng": 32.2481,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Nakaseke",
    "capital": "admin",
    "population": null,
    "id": 1800001908
  },
  {
    "city": "Boljevac",
    "city_ascii": "Boljevac",
    "lat": 43.8247,
    "lng": 21.9519,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Boljevac",
    "capital": "admin",
    "population": null,
    "id": 1688076013
  },
  {
    "city": "San José de las Lajas",
    "city_ascii": "San Jose de las Lajas",
    "lat": 22.9614,
    "lng": -82.1511,
    "country": "Cuba",
    "iso2": "CU",
    "iso3": "CUB",
    "admin_name": "Mayabeque",
    "capital": "admin",
    "population": null,
    "id": 1192731332
  },
  {
    "city": "Vipava",
    "city_ascii": "Vipava",
    "lat": 45.8463,
    "lng": 13.9622,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Vipava",
    "capital": "admin",
    "population": null,
    "id": 1705864633
  },
  {
    "city": "Ilirska Bistrica",
    "city_ascii": "Ilirska Bistrica",
    "lat": 45.5651,
    "lng": 14.2493,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Ilirska Bistrica",
    "capital": "admin",
    "population": null,
    "id": 1705031705
  },
  {
    "city": "Markovci",
    "city_ascii": "Markovci",
    "lat": 46.3833,
    "lng": 15.95,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Markovci",
    "capital": "admin",
    "population": null,
    "id": 1705493301
  },
  {
    "city": "Žužemberk",
    "city_ascii": "Zuzemberk",
    "lat": 45.8339,
    "lng": 14.9292,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Žužemberk",
    "capital": "admin",
    "population": null,
    "id": 1705027920
  },
  {
    "city": "Phôn-Hông",
    "city_ascii": "Phon-Hong",
    "lat": 18.4953,
    "lng": 102.4153,
    "country": "Laos",
    "iso2": "LA",
    "iso3": "LAO",
    "admin_name": "Viangchan",
    "capital": "admin",
    "population": null,
    "id": 1418592644
  },
  {
    "city": "Kosjerić",
    "city_ascii": "Kosjeric",
    "lat": 44,
    "lng": 19.9167,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Kosjerić",
    "capital": "admin",
    "population": null,
    "id": 1688365764
  },
  {
    "city": "Tvøroyri",
    "city_ascii": "Tvoroyri",
    "lat": 61.5544,
    "lng": -6.8063,
    "country": "Faroe Islands",
    "iso2": "FO",
    "iso3": "FRO",
    "admin_name": "",
    "capital": "admin",
    "population": null,
    "id": 1234871801
  },
  {
    "city": "Dobrova",
    "city_ascii": "Dobrova",
    "lat": 46.055,
    "lng": 14.4172,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Dobrova-Polhov Gradec",
    "capital": "admin",
    "population": null,
    "id": 1705653264
  },
  {
    "city": "Amolatar",
    "city_ascii": "Amolatar",
    "lat": 1.6378,
    "lng": 32.8448,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Amolatar",
    "capital": "admin",
    "population": null,
    "id": 1800458737
  },
  {
    "city": "Lelydorp",
    "city_ascii": "Lelydorp",
    "lat": 5.7,
    "lng": -55.2333,
    "country": "Suriname",
    "iso2": "SR",
    "iso3": "SUR",
    "admin_name": "Wanica",
    "capital": "admin",
    "population": null,
    "id": 1740222585
  },
  {
    "city": "Velike Lašče",
    "city_ascii": "Velike Lasce",
    "lat": 45.8322,
    "lng": 14.6364,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Velike Lašče",
    "capital": "admin",
    "population": null,
    "id": 1705618557
  },
  {
    "city": "Puconci",
    "city_ascii": "Puconci",
    "lat": 46.7067,
    "lng": 16.1564,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Puconci",
    "capital": "admin",
    "population": null,
    "id": 1705677747
  },
  {
    "city": "Morant Bay",
    "city_ascii": "Morant Bay",
    "lat": 17.8814,
    "lng": -76.4092,
    "country": "Jamaica",
    "iso2": "JM",
    "iso3": "JAM",
    "admin_name": "Saint Thomas",
    "capital": "admin",
    "population": null,
    "id": 1388124588
  },
  {
    "city": "Škocjan",
    "city_ascii": "Skocjan",
    "lat": 45.9067,
    "lng": 15.2914,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Škocjan",
    "capital": "admin",
    "population": null,
    "id": 1705940381
  },
  {
    "city": "Qubadlı",
    "city_ascii": "Qubadli",
    "lat": 39.3439,
    "lng": 46.5797,
    "country": "Azerbaijan",
    "iso2": "AZ",
    "iso3": "AZE",
    "admin_name": "Qubadlı",
    "capital": "admin",
    "population": 73,
    "id": 1031526251
  },
  {
    "city": "Ozurgeti",
    "city_ascii": "Ozurgeti",
    "lat": 41.9333,
    "lng": 41.9833,
    "country": "Georgia",
    "iso2": "GE",
    "iso3": "GEO",
    "admin_name": "Guria",
    "capital": "admin",
    "population": null,
    "id": 1268276279
  },
  {
    "city": "Ćićevac",
    "city_ascii": "Cicevac",
    "lat": 43.7167,
    "lng": 21.45,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Ćićevac",
    "capital": "admin",
    "population": null,
    "id": 1688834584
  },
  {
    "city": "Mokronog",
    "city_ascii": "Mokronog",
    "lat": 45.9342,
    "lng": 15.1408,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Mokronog-Trebelno",
    "capital": "admin",
    "population": null,
    "id": 1705336506
  },
  {
    "city": "Bakjagol",
    "city_ascii": "Bakjagol",
    "lat": 36.043,
    "lng": 128.0484,
    "country": "Korea, South",
    "iso2": "KR",
    "iso3": "KOR",
    "admin_name": "Gyeongbuk",
    "capital": "admin",
    "population": null,
    "id": 1410011924
  },
  {
    "city": "Plandište",
    "city_ascii": "Plandiste",
    "lat": 45.2292,
    "lng": 21.1238,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Plandište",
    "capital": "admin",
    "population": null,
    "id": 1688176320
  },
  {
    "city": "Kozina",
    "city_ascii": "Kozina",
    "lat": 45.61,
    "lng": 13.9356,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Hrpelje-Kozina",
    "capital": "admin",
    "population": null,
    "id": 1705465646
  },
  {
    "city": "Velika Polana",
    "city_ascii": "Velika Polana",
    "lat": 46.5719,
    "lng": 16.3469,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Velika Polana",
    "capital": "admin",
    "population": null,
    "id": 1705627212
  },
  {
    "city": "Cankova",
    "city_ascii": "Cankova",
    "lat": 46.7208,
    "lng": 16.0225,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Cankova",
    "capital": "admin",
    "population": null,
    "id": 1705097170
  },
  {
    "city": "Središče ob Dravi",
    "city_ascii": "Sredisce ob Dravi",
    "lat": 46.3942,
    "lng": 16.2681,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Središče ob Dravi",
    "capital": "admin",
    "population": null,
    "id": 1705613407
  },
  {
    "city": "Lamwo",
    "city_ascii": "Lamwo",
    "lat": 3.5297,
    "lng": 32.8016,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Lamwo",
    "capital": "admin",
    "population": null,
    "id": 1800495740
  },
  {
    "city": "Hrazdan",
    "city_ascii": "Hrazdan",
    "lat": 40.5,
    "lng": 44.7667,
    "country": "Armenia",
    "iso2": "AM",
    "iso3": "ARM",
    "admin_name": "Kotayk’",
    "capital": "admin",
    "population": null,
    "id": 1051566733
  },
  {
    "city": "Bangolo",
    "city_ascii": "Bangolo",
    "lat": 7.0123,
    "lng": -7.4864,
    "country": "Côte D’Ivoire",
    "iso2": "CI",
    "iso3": "CIV",
    "admin_name": "Montagnes",
    "capital": "admin",
    "population": null,
    "id": 1384048438
  },
  {
    "city": "Rogašovci",
    "city_ascii": "Rogasovci",
    "lat": 46.8,
    "lng": 16.0333,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Rogašovci",
    "capital": "admin",
    "population": null,
    "id": 1705970248
  },
  {
    "city": "Bogatić",
    "city_ascii": "Bogatic",
    "lat": 44.8376,
    "lng": 19.4807,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Bogatić",
    "capital": "admin",
    "population": null,
    "id": 1688635508
  },
  {
    "city": "Lipkovo",
    "city_ascii": "Lipkovo",
    "lat": 42.1564,
    "lng": 21.5853,
    "country": "Macedonia",
    "iso2": "MK",
    "iso3": "MKD",
    "admin_name": "Lipkovo",
    "capital": "admin",
    "population": null,
    "id": 1807754347
  },
  {
    "city": "Varvarin",
    "city_ascii": "Varvarin",
    "lat": 43.7167,
    "lng": 21.3667,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Varvarin",
    "capital": "admin",
    "population": null,
    "id": 1688871979
  },
  {
    "city": "Obleševo",
    "city_ascii": "Oblesevo",
    "lat": 41.8833,
    "lng": 22.3339,
    "country": "Macedonia",
    "iso2": "MK",
    "iso3": "MKD",
    "admin_name": "Češinovo-Obleševo",
    "capital": "admin",
    "population": null,
    "id": 1807758244
  },
  {
    "city": "Ražanj",
    "city_ascii": "Razanj",
    "lat": 43.6744,
    "lng": 21.5492,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Ražanj",
    "capital": "admin",
    "population": null,
    "id": 1688323517
  },
  {
    "city": "San Jose",
    "city_ascii": "San Jose",
    "lat": 10.18,
    "lng": 125.5683,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Dinagat Islands",
    "capital": "admin",
    "population": null,
    "id": 1608435522
  },
  {
    "city": "Īlām",
    "city_ascii": "Ilam",
    "lat": 33.6374,
    "lng": 46.4227,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Īlām",
    "capital": "admin",
    "population": null,
    "id": 1364655025
  },
  {
    "city": "Mongar",
    "city_ascii": "Mongar",
    "lat": 27.275,
    "lng": 91.24,
    "country": "Bhutan",
    "iso2": "BT",
    "iso3": "BTN",
    "admin_name": "Mongar",
    "capital": "admin",
    "population": null,
    "id": 1064208677
  },
  {
    "city": "Godāwari̇̄",
    "city_ascii": "Godawari",
    "lat": 28.8755,
    "lng": 80.5776,
    "country": "Nepal",
    "iso2": "NP",
    "iso3": "NPL",
    "admin_name": "Setī",
    "capital": "admin",
    "population": null,
    "id": 1524020578
  },
  {
    "city": "Mionica",
    "city_ascii": "Mionica",
    "lat": 44.25,
    "lng": 20.0833,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Mionica",
    "capital": "admin",
    "population": null,
    "id": 1688862283
  },
  {
    "city": "Kurumul",
    "city_ascii": "Kurumul",
    "lat": -5.855,
    "lng": 144.6311,
    "country": "Papua New Guinea",
    "iso2": "PG",
    "iso3": "PNG",
    "admin_name": "Jiwaka",
    "capital": "admin",
    "population": null,
    "id": 1598000045
  },
  {
    "city": "Aranđelovac",
    "city_ascii": "Arandelovac",
    "lat": 44.3042,
    "lng": 20.5561,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Aranđelovac",
    "capital": "admin",
    "population": null,
    "id": 1688854200
  },
  {
    "city": "Kula",
    "city_ascii": "Kula",
    "lat": 45.6109,
    "lng": 19.5274,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Kula",
    "capital": "admin",
    "population": null,
    "id": 1688477275
  },
  {
    "city": "Nova Vas",
    "city_ascii": "Nova Vas",
    "lat": 45.7717,
    "lng": 14.5058,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Bloke",
    "capital": "admin",
    "population": null,
    "id": 1705701553
  },
  {
    "city": "Apače",
    "city_ascii": "Apace",
    "lat": 46.6972,
    "lng": 15.9106,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Apače",
    "capital": "admin",
    "population": null,
    "id": 1705285338
  },
  {
    "city": "Cerkvenjak",
    "city_ascii": "Cerkvenjak",
    "lat": 46.5706,
    "lng": 15.9436,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Cerkvenjak",
    "capital": "admin",
    "population": null,
    "id": 1705808302
  },
  {
    "city": "Haa",
    "city_ascii": "Haa",
    "lat": 27.3685,
    "lng": 89.2918,
    "country": "Bhutan",
    "iso2": "BT",
    "iso3": "BTN",
    "admin_name": "Haa",
    "capital": "admin",
    "population": null,
    "id": 1064717667
  },
  {
    "city": "Krapina",
    "city_ascii": "Krapina",
    "lat": 46.1589,
    "lng": 15.8744,
    "country": "Croatia",
    "iso2": "HR",
    "iso3": "HRV",
    "admin_name": "Krapinsko-Zagorska Županija",
    "capital": "admin",
    "population": null,
    "id": 1191389404
  },
  {
    "city": "Palenga",
    "city_ascii": "Palenga",
    "lat": 2.6131,
    "lng": 32.3369,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Gulu",
    "capital": "admin",
    "population": null,
    "id": 1800679810
  },
  {
    "city": "Singa",
    "city_ascii": "Singa",
    "lat": 13.1483,
    "lng": 33.9311,
    "country": "Sudan",
    "iso2": "SD",
    "iso3": "SDN",
    "admin_name": "Sennar",
    "capital": "admin",
    "population": null,
    "id": 1729951108
  },
  {
    "city": "Novi Bečej",
    "city_ascii": "Novi Becej",
    "lat": 45.6,
    "lng": 20.1167,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Novi Bečej",
    "capital": "admin",
    "population": null,
    "id": 1688588712
  },
  {
    "city": "Bečej",
    "city_ascii": "Becej",
    "lat": 45.6167,
    "lng": 20.0333,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Bečej",
    "capital": "admin",
    "population": null,
    "id": 1688492797
  },
  {
    "city": "Kuzma",
    "city_ascii": "Kuzma",
    "lat": 46.8369,
    "lng": 16.0833,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Kuzma",
    "capital": "admin",
    "population": null,
    "id": 1705411351
  },
  {
    "city": "Şərur",
    "city_ascii": "Sarur",
    "lat": 39.5544,
    "lng": 44.9826,
    "country": "Azerbaijan",
    "iso2": "AZ",
    "iso3": "AZE",
    "admin_name": "Şərur",
    "capital": "admin",
    "population": null,
    "id": 1031386825
  },
  {
    "city": "Juršinci",
    "city_ascii": "Jursinci",
    "lat": 46.4847,
    "lng": 15.9714,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Juršinci",
    "capital": "admin",
    "population": null,
    "id": 1705262198
  },
  {
    "city": "Port Loko",
    "city_ascii": "Port Loko",
    "lat": 8.7667,
    "lng": -12.7833,
    "country": "Sierra Leone",
    "iso2": "SL",
    "iso3": "SLE",
    "admin_name": "Northern",
    "capital": "admin",
    "population": null,
    "id": 1694778676
  },
  {
    "city": "Gornji Grad",
    "city_ascii": "Gornji Grad",
    "lat": 46.2953,
    "lng": 14.8083,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Gornji Grad",
    "capital": "admin",
    "population": null,
    "id": 1705879741
  },
  {
    "city": "Žitorađa",
    "city_ascii": "Zitorada",
    "lat": 43.1833,
    "lng": 21.7167,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Žitorađa",
    "capital": "admin",
    "population": null,
    "id": 1688262057
  },
  {
    "city": "Videm pri Ptuju",
    "city_ascii": "Videm pri Ptuju",
    "lat": 46.3686,
    "lng": 15.9064,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Videm",
    "capital": "admin",
    "population": null,
    "id": 1705681768
  },
  {
    "city": "Kotido",
    "city_ascii": "Kotido",
    "lat": 2.9806,
    "lng": 34.1331,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Kotido",
    "capital": "admin",
    "population": null,
    "id": 1800371285
  },
  {
    "city": "Titel",
    "city_ascii": "Titel",
    "lat": 45.2,
    "lng": 20.3,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Titel",
    "capital": "admin",
    "population": null,
    "id": 1688633486
  },
  {
    "city": "Pul-e ‘Alam",
    "city_ascii": "Pul-e `Alam",
    "lat": 33.9953,
    "lng": 69.0227,
    "country": "Afghanistan",
    "iso2": "AF",
    "iso3": "AFG",
    "admin_name": "Lōgar",
    "capital": "admin",
    "population": null,
    "id": 1004180853
  },
  {
    "city": "Laçın",
    "city_ascii": "Lacin",
    "lat": 39.6408,
    "lng": 46.5469,
    "country": "Azerbaijan",
    "iso2": "AZ",
    "iso3": "AZE",
    "admin_name": "Laçın",
    "capital": "admin",
    "population": null,
    "id": 1031245091
  },
  {
    "city": "Cirkulane",
    "city_ascii": "Cirkulane",
    "lat": 46.3453,
    "lng": 15.9952,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Cirkulane",
    "capital": "admin",
    "population": null,
    "id": 1705650900
  },
  {
    "city": "Bar",
    "city_ascii": "Bar",
    "lat": 42.1,
    "lng": 19.1,
    "country": "Montenegro",
    "iso2": "ME",
    "iso3": "MNE",
    "admin_name": "Bar",
    "capital": "admin",
    "population": null,
    "id": 1499197266
  },
  {
    "city": "Ribnica",
    "city_ascii": "Ribnica",
    "lat": 45.7386,
    "lng": 14.7275,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Ribnica",
    "capital": "admin",
    "population": null,
    "id": 1705675272
  },
  {
    "city": "Miren",
    "city_ascii": "Miren",
    "lat": 45.8956,
    "lng": 13.6075,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Miren-Kostanjevica",
    "capital": "admin",
    "population": null,
    "id": 1705608129
  },
  {
    "city": "Sharan",
    "city_ascii": "Sharan",
    "lat": 33.1757,
    "lng": 68.7304,
    "country": "Afghanistan",
    "iso2": "AF",
    "iso3": "AFG",
    "admin_name": "Paktīkā",
    "capital": "admin",
    "population": null,
    "id": 1004273142
  },
  {
    "city": "Boorama",
    "city_ascii": "Boorama",
    "lat": 9.9361,
    "lng": 43.1828,
    "country": "Somalia",
    "iso2": "SO",
    "iso3": "SOM",
    "admin_name": "Awdal",
    "capital": "admin",
    "population": null,
    "id": 1706767209
  },
  {
    "city": "Tari",
    "city_ascii": "Tari",
    "lat": -5.8489,
    "lng": 142.9506,
    "country": "Papua New Guinea",
    "iso2": "PG",
    "iso3": "PNG",
    "admin_name": "Hela",
    "capital": "admin",
    "population": null,
    "id": 1598669271
  },
  {
    "city": "Zavrč",
    "city_ascii": "Zavrc",
    "lat": 46.3917,
    "lng": 16.0497,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Zavrč",
    "capital": "admin",
    "population": null,
    "id": 1705477555
  },
  {
    "city": "Planken",
    "city_ascii": "Planken",
    "lat": 47.1844,
    "lng": 9.5436,
    "country": "Liechtenstein",
    "iso2": "LI",
    "iso3": "LIE",
    "admin_name": "Planken",
    "capital": "admin",
    "population": null,
    "id": 1438419168
  },
  {
    "city": "Cocieri",
    "city_ascii": "Cocieri",
    "lat": 47.3087,
    "lng": 29.1101,
    "country": "Moldova",
    "iso2": "MD",
    "iso3": "MDA",
    "admin_name": "Dubăsari",
    "capital": "admin",
    "population": null,
    "id": 1498580757
  },
  {
    "city": "Pader",
    "city_ascii": "Pader",
    "lat": 3.05,
    "lng": 33.2167,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Pader",
    "capital": "admin",
    "population": null,
    "id": 1800105199
  },
  {
    "city": "Merošina",
    "city_ascii": "Merosina",
    "lat": 43.2813,
    "lng": 21.7218,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Merošina",
    "capital": "admin",
    "population": null,
    "id": 1688733340
  },
  {
    "city": "Fuglafjørður",
    "city_ascii": "Fuglafjordhur",
    "lat": 62.2448,
    "lng": -6.815,
    "country": "Faroe Islands",
    "iso2": "FO",
    "iso3": "FRO",
    "admin_name": "",
    "capital": "admin",
    "population": null,
    "id": 1234308038
  },
  {
    "city": "Triesenberg",
    "city_ascii": "Triesenberg",
    "lat": 47.1182,
    "lng": 9.5444,
    "country": "Liechtenstein",
    "iso2": "LI",
    "iso3": "LIE",
    "admin_name": "Triesenberg",
    "capital": "admin",
    "population": null,
    "id": 1438813592
  },
  {
    "city": "Lufilufi",
    "city_ascii": "Lufilufi",
    "lat": -13.8745,
    "lng": -171.5986,
    "country": "Samoa",
    "iso2": "WS",
    "iso3": "WSM",
    "admin_name": "Atua",
    "capital": "admin",
    "population": null,
    "id": 1882092632
  },
  {
    "city": "Bovec",
    "city_ascii": "Bovec",
    "lat": 46.3378,
    "lng": 13.5522,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Bovec",
    "capital": "admin",
    "population": null,
    "id": 1705609781
  },
  {
    "city": "Podlehnik",
    "city_ascii": "Podlehnik",
    "lat": 46.3353,
    "lng": 15.88,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Podlehnik",
    "capital": "admin",
    "population": null,
    "id": 1705352359
  },
  {
    "city": "Kotor",
    "city_ascii": "Kotor",
    "lat": 42.4254,
    "lng": 18.7712,
    "country": "Montenegro",
    "iso2": "ME",
    "iso3": "MNE",
    "admin_name": "Kotor",
    "capital": "admin",
    "population": null,
    "id": 1499573612
  },
  {
    "city": "Stara Pazova",
    "city_ascii": "Stara Pazova",
    "lat": 44.9833,
    "lng": 20.1667,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Stara Pazova",
    "capital": "admin",
    "population": null,
    "id": 1688866909
  },
  {
    "city": "Carnikava",
    "city_ascii": "Carnikava",
    "lat": 57.1293,
    "lng": 24.2842,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Carnikavas Novads",
    "capital": "admin",
    "population": null,
    "id": 1428447982
  },
  {
    "city": "Clervaux",
    "city_ascii": "Clervaux",
    "lat": 50.0547,
    "lng": 6.0314,
    "country": "Luxembourg",
    "iso2": "LU",
    "iso3": "LUX",
    "admin_name": "Clervaux",
    "capital": "admin",
    "population": null,
    "id": 1442118762
  },
  {
    "city": "Redange-sur-Attert",
    "city_ascii": "Redange-sur-Attert",
    "lat": 49.7656,
    "lng": 5.8908,
    "country": "Luxembourg",
    "iso2": "LU",
    "iso3": "LUX",
    "admin_name": "Redange",
    "capital": "admin",
    "population": null,
    "id": 1442099691
  },
  {
    "city": "Bosilovo",
    "city_ascii": "Bosilovo",
    "lat": 41.4406,
    "lng": 22.7278,
    "country": "Macedonia",
    "iso2": "MK",
    "iso3": "MKD",
    "admin_name": "Bosilovo",
    "capital": "admin",
    "population": null,
    "id": 1807868218
  },
  {
    "city": "Goaso",
    "city_ascii": "Goaso",
    "lat": 6.8036,
    "lng": -2.5172,
    "country": "Ghana",
    "iso2": "GH",
    "iso3": "GHA",
    "admin_name": "Brong-Ahafo",
    "capital": "admin",
    "population": null,
    "id": 1288616024
  },
  {
    "city": "Vevčani",
    "city_ascii": "Vevcani",
    "lat": 41.2406,
    "lng": 20.5933,
    "country": "Macedonia",
    "iso2": "MK",
    "iso3": "MKD",
    "admin_name": "Vevčani",
    "capital": "admin",
    "population": null,
    "id": 1807108428
  },
  {
    "city": "Dambai",
    "city_ascii": "Dambai",
    "lat": 8.0662,
    "lng": 0.1795,
    "country": "Ghana",
    "iso2": "GH",
    "iso3": "GHA",
    "admin_name": "Volta",
    "capital": "admin",
    "population": null,
    "id": 1288521614
  },
  {
    "city": "Yardımlı",
    "city_ascii": "Yardimli",
    "lat": 38.9206,
    "lng": 48.2372,
    "country": "Azerbaijan",
    "iso2": "AZ",
    "iso3": "AZE",
    "admin_name": "Yardımlı",
    "capital": "admin",
    "population": null,
    "id": 1031745676
  },
  {
    "city": "Igreja",
    "city_ascii": "Igreja",
    "lat": 15.0339,
    "lng": -24.325,
    "country": "Cabo Verde",
    "iso2": "CV",
    "iso3": "CPV",
    "admin_name": "Mosteiros",
    "capital": "admin",
    "population": null,
    "id": 1132174768
  },
  {
    "city": "Kaffrine",
    "city_ascii": "Kaffrine",
    "lat": 14.1016,
    "lng": -15.5467,
    "country": "Senegal",
    "iso2": "SN",
    "iso3": "SEN",
    "admin_name": "Kaffrine",
    "capital": "admin",
    "population": null,
    "id": 1686807341
  },
  {
    "city": "Ribnica",
    "city_ascii": "Ribnica",
    "lat": 46.535,
    "lng": 15.2728,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Ribnica na Pohorju",
    "capital": "admin",
    "population": null,
    "id": 1705830999
  },
  {
    "city": "Zgornje Gorje",
    "city_ascii": "Zgornje Gorje",
    "lat": 46.3833,
    "lng": 14.0833,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Gorje",
    "capital": "admin",
    "population": null,
    "id": 1705342044
  },
  {
    "city": "Ķekava",
    "city_ascii": "Kekava",
    "lat": 56.8296,
    "lng": 24.2318,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Ķekavas Novads",
    "capital": "admin",
    "population": null,
    "id": 1428331173
  },
  {
    "city": "Tomaž pri Ormožu",
    "city_ascii": "Tomaz pri Ormozu",
    "lat": 46.4842,
    "lng": 16.0836,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Sveti Tomaž",
    "capital": "admin",
    "population": null,
    "id": 1705837424
  },
  {
    "city": "Sangre Grande",
    "city_ascii": "Sangre Grande",
    "lat": 10.5667,
    "lng": -61.1333,
    "country": "Trinidad And Tobago",
    "iso2": "TT",
    "iso3": "TTO",
    "admin_name": "Sangre Grande",
    "capital": "admin",
    "population": null,
    "id": 1780469686
  },
  {
    "city": "Gaoua",
    "city_ascii": "Gaoua",
    "lat": 10.2992,
    "lng": -3.2508,
    "country": "Burkina Faso",
    "iso2": "BF",
    "iso3": "BFA",
    "admin_name": "Sud-Ouest",
    "capital": "admin",
    "population": null,
    "id": 1854285570
  },
  {
    "city": "Nhlangano",
    "city_ascii": "Nhlangano",
    "lat": -27.1167,
    "lng": 31.2,
    "country": "Swaziland",
    "iso2": "SZ",
    "iso3": "SWZ",
    "admin_name": "Shiselweni",
    "capital": "admin",
    "population": null,
    "id": 1748301792
  },
  {
    "city": "Ordino",
    "city_ascii": "Ordino",
    "lat": 42.555,
    "lng": 1.5331,
    "country": "Andorra",
    "iso2": "AD",
    "iso3": "AND",
    "admin_name": "Ordino",
    "capital": "admin",
    "population": null,
    "id": 1020655238
  },
  {
    "city": "Bosilegrad",
    "city_ascii": "Bosilegrad",
    "lat": 42.5005,
    "lng": 22.4728,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Bosilegrad",
    "capital": "admin",
    "population": null,
    "id": 1688870763
  },
  {
    "city": "Knić",
    "city_ascii": "Knic",
    "lat": 43.9167,
    "lng": 20.7167,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Knić",
    "capital": "admin",
    "population": null,
    "id": 1688545619
  },
  {
    "city": "Kostanjevica na Krki",
    "city_ascii": "Kostanjevica na Krki",
    "lat": 45.8463,
    "lng": 15.4249,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Kostanjevica na Krki",
    "capital": "admin",
    "population": null,
    "id": 1705796422
  },
  {
    "city": "Canillo",
    "city_ascii": "Canillo",
    "lat": 42.567,
    "lng": 1.5981,
    "country": "Andorra",
    "iso2": "AD",
    "iso3": "AND",
    "admin_name": "Canillo",
    "capital": "admin",
    "population": null,
    "id": 1020594179
  },
  {
    "city": "Agago",
    "city_ascii": "Agago",
    "lat": 2.8338,
    "lng": 33.3336,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Agago",
    "capital": "admin",
    "population": null,
    "id": 1800321499
  },
  {
    "city": "Fort Wellington",
    "city_ascii": "Fort Wellington",
    "lat": 6.3909,
    "lng": -57.6038,
    "country": "Guyana",
    "iso2": "GY",
    "iso3": "GUY",
    "admin_name": "Mahaica-Berbice",
    "capital": "admin",
    "population": null,
    "id": 1328483798
  },
  {
    "city": "Vitomarci",
    "city_ascii": "Vitomarci",
    "lat": 46.5275,
    "lng": 15.9394,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Sveti Andraž v Slovenskih Goricah",
    "capital": "admin",
    "population": null,
    "id": 1705653481
  },
  {
    "city": "Nova Sintra",
    "city_ascii": "Nova Sintra",
    "lat": 14.8714,
    "lng": -24.6956,
    "country": "Cabo Verde",
    "iso2": "CV",
    "iso3": "CPV",
    "admin_name": "Brava",
    "capital": "admin",
    "population": null,
    "id": 1132634480
  },
  {
    "city": "Şuḩār",
    "city_ascii": "Suhar",
    "lat": 24.342,
    "lng": 56.7299,
    "country": "Oman",
    "iso2": "OM",
    "iso3": "OMN",
    "admin_name": "Shamāl al Bāţinah",
    "capital": "admin",
    "population": null,
    "id": 1512602960
  },
  {
    "city": "Komen",
    "city_ascii": "Komen",
    "lat": 45.8153,
    "lng": 13.7483,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Komen",
    "capital": "admin",
    "population": null,
    "id": 1705031068
  },
  {
    "city": "Sečanj",
    "city_ascii": "Secanj",
    "lat": 45.3667,
    "lng": 20.7725,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Sečanj",
    "capital": "admin",
    "population": null,
    "id": 1688905613
  },
  {
    "city": "Füzuli",
    "city_ascii": "Fuzuli",
    "lat": 39.6003,
    "lng": 47.1431,
    "country": "Azerbaijan",
    "iso2": "AZ",
    "iso3": "AZE",
    "admin_name": "Füzuli",
    "capital": "admin",
    "population": null,
    "id": 1031872322
  },
  {
    "city": "Nalerigu",
    "city_ascii": "Nalerigu",
    "lat": 10.5273,
    "lng": -0.3698,
    "country": "Ghana",
    "iso2": "GH",
    "iso3": "GHA",
    "admin_name": "Northern",
    "capital": "admin",
    "population": null,
    "id": 1288845503
  },
  {
    "city": "Divača",
    "city_ascii": "Divaca",
    "lat": 45.6847,
    "lng": 13.9703,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Divača",
    "capital": "admin",
    "population": null,
    "id": 1705065298
  },
  {
    "city": "Destrnik",
    "city_ascii": "Destrnik",
    "lat": 46.5006,
    "lng": 15.875,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Destrnik",
    "capital": "admin",
    "population": null,
    "id": 1705579309
  },
  {
    "city": "Inčukalns",
    "city_ascii": "Incukalns",
    "lat": 57.0987,
    "lng": 24.6863,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Inčukalna Novads",
    "capital": "admin",
    "population": null,
    "id": 1428675819
  },
  {
    "city": "Kozje",
    "city_ascii": "Kozje",
    "lat": 46.075,
    "lng": 15.5603,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Kozje",
    "capital": "admin",
    "population": null,
    "id": 1705548274
  },
  {
    "city": "Nīlī",
    "city_ascii": "Nili",
    "lat": 33.7218,
    "lng": 66.1302,
    "country": "Afghanistan",
    "iso2": "AF",
    "iso3": "AFG",
    "admin_name": "Dāykundī",
    "capital": "admin",
    "population": null,
    "id": 1004642532
  },
  {
    "city": "Bulisa",
    "city_ascii": "Bulisa",
    "lat": 2.1178,
    "lng": 31.4116,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Buliisa",
    "capital": "admin",
    "population": null,
    "id": 1800842426
  },
  {
    "city": "Phônsavan",
    "city_ascii": "Phonsavan",
    "lat": 19.45,
    "lng": 103.2,
    "country": "Laos",
    "iso2": "LA",
    "iso3": "LAO",
    "admin_name": "Xiangkhouang",
    "capital": "admin",
    "population": null,
    "id": 1418378289
  },
  {
    "city": "Plasnica",
    "city_ascii": "Plasnica",
    "lat": 41.4672,
    "lng": 21.1231,
    "country": "Macedonia",
    "iso2": "MK",
    "iso3": "MKD",
    "admin_name": "Plasnica",
    "capital": "admin",
    "population": null,
    "id": 1807030257
  },
  {
    "city": "Amudat",
    "city_ascii": "Amudat",
    "lat": 1.95,
    "lng": 34.95,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Amudat",
    "capital": "admin",
    "population": null,
    "id": 1800886754
  },
  {
    "city": "Jurovski Dol",
    "city_ascii": "Jurovski Dol",
    "lat": 46.6064,
    "lng": 15.7847,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Sveti Jurij v Slovenskih Goricah",
    "capital": "admin",
    "population": null,
    "id": 1705288408
  },
  {
    "city": "Kovačica",
    "city_ascii": "Kovacica",
    "lat": 45.1117,
    "lng": 20.6214,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Kovačica",
    "capital": "admin",
    "population": null,
    "id": 1688530993
  },
  {
    "city": "Nabilatuk",
    "city_ascii": "Nabilatuk",
    "lat": 2.0525,
    "lng": 34.5734,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Nakapiripirit",
    "capital": "admin",
    "population": null,
    "id": 1800577877
  },
  {
    "city": "Nkurenkuru",
    "city_ascii": "Nkurenkuru",
    "lat": -17.6167,
    "lng": 18.6,
    "country": "Namibia",
    "iso2": "NA",
    "iso3": "NAM",
    "admin_name": "Kavango West",
    "capital": "admin",
    "population": null,
    "id": 1516271813
  },
  {
    "city": "Pakxan",
    "city_ascii": "Pakxan",
    "lat": 18.3831,
    "lng": 103.6669,
    "country": "Laos",
    "iso2": "LA",
    "iso3": "LAO",
    "admin_name": "Bolikhamxai",
    "capital": "admin",
    "population": null,
    "id": 1418088090
  },
  {
    "city": "Dobje",
    "city_ascii": "Dobje",
    "lat": 46.1367,
    "lng": 15.4089,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Dobje",
    "capital": "admin",
    "population": null,
    "id": 1705204927
  },
  {
    "city": "Gornji Petrovci",
    "city_ascii": "Gornji Petrovci",
    "lat": 46.8053,
    "lng": 16.2225,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Gornji Petrovci",
    "capital": "admin",
    "population": null,
    "id": 1705170234
  },
  {
    "city": "Samdrup Jongkhar",
    "city_ascii": "Samdrup Jongkhar",
    "lat": 26.8007,
    "lng": 91.5052,
    "country": "Bhutan",
    "iso2": "BT",
    "iso3": "BTN",
    "admin_name": "Samdrup Jongkhar",
    "capital": "admin",
    "population": null,
    "id": 1064656050
  },
  {
    "city": "Moravske-Toplice",
    "city_ascii": "Moravske-Toplice",
    "lat": 46.6875,
    "lng": 16.2256,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Moravske Toplice",
    "capital": "admin",
    "population": null,
    "id": 1705126489
  },
  {
    "city": "Podvelka",
    "city_ascii": "Podvelka",
    "lat": 46.5869,
    "lng": 15.3306,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Podvelka",
    "capital": "admin",
    "population": null,
    "id": 1705523444
  },
  {
    "city": "Trnovska Vas",
    "city_ascii": "Trnovska Vas",
    "lat": 46.5167,
    "lng": 15.9,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Trnovska Vas",
    "capital": "admin",
    "population": null,
    "id": 1705654373
  },
  {
    "city": "Sarpang",
    "city_ascii": "Sarpang",
    "lat": 26.8639,
    "lng": 90.2674,
    "country": "Bhutan",
    "iso2": "BT",
    "iso3": "BTN",
    "admin_name": "Sarpang",
    "capital": "admin",
    "population": null,
    "id": 1064362339
  },
  {
    "city": "Makole",
    "city_ascii": "Makole",
    "lat": 46.3172,
    "lng": 15.6672,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Makole",
    "capital": "admin",
    "population": null,
    "id": 1705304446
  },
  {
    "city": "Grad",
    "city_ascii": "Grad",
    "lat": 46.8,
    "lng": 16.1,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Grad",
    "capital": "admin",
    "population": null,
    "id": 1705033390
  },
  {
    "city": "Trashigang",
    "city_ascii": "Trashigang",
    "lat": 27.3333,
    "lng": 91.5528,
    "country": "Bhutan",
    "iso2": "BT",
    "iso3": "BTN",
    "admin_name": "Trashigang",
    "capital": "admin",
    "population": null,
    "id": 1064123227
  },
  {
    "city": "Ādaži",
    "city_ascii": "Adazi",
    "lat": 57.0705,
    "lng": 24.3371,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Ādažu Novads",
    "capital": "admin",
    "population": null,
    "id": 1428843734
  },
  {
    "city": "Qəbələ",
    "city_ascii": "Qabala",
    "lat": 40.9825,
    "lng": 47.8491,
    "country": "Azerbaijan",
    "iso2": "AZ",
    "iso3": "AZE",
    "admin_name": "Qəbələ",
    "capital": "admin",
    "population": null,
    "id": 1031787201
  },
  {
    "city": "Ozolnieki",
    "city_ascii": "Ozolnieki",
    "lat": 56.6899,
    "lng": 23.7761,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Ozolnieku Novads",
    "capital": "admin",
    "population": null,
    "id": 1428973455
  },
  {
    "city": "Neves",
    "city_ascii": "Neves",
    "lat": 0.3586,
    "lng": 6.5525,
    "country": "Sao Tome And Principe",
    "iso2": "ST",
    "iso3": "STP",
    "admin_name": "São Tomé",
    "capital": "admin",
    "population": null,
    "id": 1678563060
  },
  {
    "city": "Medveđa",
    "city_ascii": "Medveda",
    "lat": 42.8333,
    "lng": 21.5833,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Medveđa",
    "capital": "admin",
    "population": null,
    "id": 1688014462
  },
  {
    "city": "Napak",
    "city_ascii": "Napak",
    "lat": 2.2514,
    "lng": 34.2501,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Napak",
    "capital": "admin",
    "population": null,
    "id": 1800784014
  },
  {
    "city": "Vágur",
    "city_ascii": "Vagur",
    "lat": 61.4733,
    "lng": -6.8175,
    "country": "Faroe Islands",
    "iso2": "FO",
    "iso3": "FRO",
    "admin_name": "",
    "capital": "admin",
    "population": null,
    "id": 1234642477
  },
  {
    "city": "Pemagatshel",
    "city_ascii": "Pemagatshel",
    "lat": 27.038,
    "lng": 91.4031,
    "country": "Bhutan",
    "iso2": "BT",
    "iso3": "BTN",
    "admin_name": "Pemagatshel",
    "capital": "admin",
    "population": null,
    "id": 1064215849
  },
  {
    "city": "Cova Figueira",
    "city_ascii": "Cova Figueira",
    "lat": 14.8833,
    "lng": -24.3,
    "country": "Cabo Verde",
    "iso2": "CV",
    "iso3": "CPV",
    "admin_name": "Santa Catarina do Fogo",
    "capital": "admin",
    "population": null,
    "id": 1132510430
  },
  {
    "city": "Žitište",
    "city_ascii": "Zitiste",
    "lat": 45.485,
    "lng": 20.5497,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Žitište",
    "capital": "admin",
    "population": null,
    "id": 1688363591
  },
  {
    "city": "Sofifi",
    "city_ascii": "Sofifi",
    "lat": 0.7373,
    "lng": 127.5588,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Maluku Utara",
    "capital": "admin",
    "population": null,
    "id": 1360431437
  },
  {
    "city": "Razkrižje",
    "city_ascii": "Razkrizje",
    "lat": 46.5217,
    "lng": 16.2811,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Razkrižje",
    "capital": "admin",
    "population": null,
    "id": 1705276725
  },
  {
    "city": "Rustavi",
    "city_ascii": "Rustavi",
    "lat": 42.2897,
    "lng": 43.8543,
    "country": "Georgia",
    "iso2": "GE",
    "iso3": "GEO",
    "admin_name": "Kvemo Kartli",
    "capital": "admin",
    "population": null,
    "id": 1268297743
  },
  {
    "city": "Čajetina",
    "city_ascii": "Cajetina",
    "lat": 43.75,
    "lng": 19.7167,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Čajetina",
    "capital": "admin",
    "population": null,
    "id": 1688018668
  },
  {
    "city": "Krivogaštani",
    "city_ascii": "Krivogastani",
    "lat": 41.3369,
    "lng": 21.3311,
    "country": "Macedonia",
    "iso2": "MK",
    "iso3": "MKD",
    "admin_name": "Krivogaštani",
    "capital": "admin",
    "population": null,
    "id": 1807293579
  },
  {
    "city": "Rogatec",
    "city_ascii": "Rogatec",
    "lat": 46.2294,
    "lng": 15.7003,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Rogatec",
    "capital": "admin",
    "population": null,
    "id": 1705579021
  },
  {
    "city": "Qacha’s Nek",
    "city_ascii": "Qacha's Nek",
    "lat": -30.1153,
    "lng": 28.6894,
    "country": "Lesotho",
    "iso2": "LS",
    "iso3": "LSO",
    "admin_name": "Qacha’s Nek",
    "capital": "admin",
    "population": null,
    "id": 1426361264
  },
  {
    "city": "Sremska Mitrovica",
    "city_ascii": "Sremska Mitrovica",
    "lat": 44.9833,
    "lng": 19.6167,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Sremska Mitrovica",
    "capital": "admin",
    "population": null,
    "id": 1688974283
  },
  {
    "city": "Safotulafai",
    "city_ascii": "Safotulafai",
    "lat": -13.6715,
    "lng": -172.1777,
    "country": "Samoa",
    "iso2": "WS",
    "iso3": "WSM",
    "admin_name": "Fa‘asaleleaga",
    "capital": "admin",
    "population": null,
    "id": 1882524892
  },
  {
    "city": "Ivanjica",
    "city_ascii": "Ivanjica",
    "lat": 43.5811,
    "lng": 20.2297,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Ivanjica",
    "capital": "admin",
    "population": null,
    "id": 1688450536
  },
  {
    "city": "Kuacjok",
    "city_ascii": "Kuacjok",
    "lat": 8.31,
    "lng": 27.99,
    "country": "South Sudan",
    "iso2": "SS",
    "iso3": "SSD",
    "admin_name": "Warrap",
    "capital": "admin",
    "population": null,
    "id": 1728713745
  },
  {
    "city": "Sédhiou",
    "city_ascii": "Sedhiou",
    "lat": 12.7081,
    "lng": -15.5569,
    "country": "Senegal",
    "iso2": "SN",
    "iso3": "SEN",
    "admin_name": "Sédhiou",
    "capital": "admin",
    "population": null,
    "id": 1686107428
  },
  {
    "city": "Tubmanburg",
    "city_ascii": "Tubmanburg",
    "lat": 6.8706,
    "lng": -10.8211,
    "country": "Liberia",
    "iso2": "LR",
    "iso3": "LBR",
    "admin_name": "Bomi",
    "capital": "admin",
    "population": null,
    "id": 1430503114
  },
  {
    "city": "Inđija",
    "city_ascii": "Indija",
    "lat": 45.0492,
    "lng": 20.0792,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Inđija",
    "capital": "admin",
    "population": null,
    "id": 1688349740
  },
  {
    "city": "Vasilevo",
    "city_ascii": "Vasilevo",
    "lat": 41.4741,
    "lng": 22.643,
    "country": "Macedonia",
    "iso2": "MK",
    "iso3": "MKD",
    "admin_name": "Vasilevo",
    "capital": "admin",
    "population": null,
    "id": 1807855424
  },
  {
    "city": "Žabari",
    "city_ascii": "Zabari",
    "lat": 44.3562,
    "lng": 21.2143,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Žabari",
    "capital": "admin",
    "population": null,
    "id": 1688661944
  },
  {
    "city": "Ed Daein",
    "city_ascii": "Ed Daein",
    "lat": 11.4672,
    "lng": 26.1317,
    "country": "Sudan",
    "iso2": "SD",
    "iso3": "SDN",
    "admin_name": "East Darfur",
    "capital": "admin",
    "population": null,
    "id": 1729512354
  },
  {
    "city": "Tabuk",
    "city_ascii": "Tabuk",
    "lat": 17.4084,
    "lng": 121.2785,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Kalinga",
    "capital": "admin",
    "population": null,
    "id": 1608980358
  },
  {
    "city": "Osilnica",
    "city_ascii": "Osilnica",
    "lat": 45.5292,
    "lng": 14.6979,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Osilnica",
    "capital": "admin",
    "population": null,
    "id": 1705839708
  },
  {
    "city": "Petrovac na Mlavi",
    "city_ascii": "Petrovac na Mlavi",
    "lat": 44.3783,
    "lng": 21.4194,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Petrovac na Mlavi",
    "capital": "admin",
    "population": null,
    "id": 1688674789
  },
  {
    "city": "Pazin",
    "city_ascii": "Pazin",
    "lat": 45.2392,
    "lng": 13.9386,
    "country": "Croatia",
    "iso2": "HR",
    "iso3": "HRV",
    "admin_name": "Istarska Županija",
    "capital": "admin",
    "population": null,
    "id": 1191654566
  },
  {
    "city": "Savalou",
    "city_ascii": "Savalou",
    "lat": 7.9281,
    "lng": 1.9756,
    "country": "Benin",
    "iso2": "BJ",
    "iso3": "BEN",
    "admin_name": "Collines",
    "capital": "admin",
    "population": null,
    "id": 1204628914
  },
  {
    "city": "Mali Iđoš",
    "city_ascii": "Mali Idos",
    "lat": 45.7092,
    "lng": 19.6663,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Mali Iđoš",
    "capital": "admin",
    "population": null,
    "id": 1688501809
  },
  {
    "city": "Centar Župa",
    "city_ascii": "Centar Zupa",
    "lat": 41.4788,
    "lng": 20.5586,
    "country": "Macedonia",
    "iso2": "MK",
    "iso3": "MKD",
    "admin_name": "Centar Župa",
    "capital": "admin",
    "population": null,
    "id": 1807323094
  },
  {
    "city": "Ub",
    "city_ascii": "Ub",
    "lat": 44.45,
    "lng": 20.0667,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Ub",
    "capital": "admin",
    "population": null,
    "id": 1688701954
  },
  {
    "city": "Rača",
    "city_ascii": "Raca",
    "lat": 44.2333,
    "lng": 20.9833,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Rača",
    "capital": "admin",
    "population": null,
    "id": 1688403125
  },
  {
    "city": "Srbobran",
    "city_ascii": "Srbobran",
    "lat": 45.5522,
    "lng": 19.8017,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Srbobran",
    "capital": "admin",
    "population": null,
    "id": 1688431448
  },
  {
    "city": "Ljig",
    "city_ascii": "Ljig",
    "lat": 44.2266,
    "lng": 20.2394,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Ljig",
    "capital": "admin",
    "population": null,
    "id": 1688555007
  },
  {
    "city": "Kiryandongo",
    "city_ascii": "Kiryandongo",
    "lat": 1.8763,
    "lng": 32.0622,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Kiryandongo",
    "capital": "admin",
    "population": null,
    "id": 1800154303
  },
  {
    "city": "Rekovac",
    "city_ascii": "Rekovac",
    "lat": 43.8667,
    "lng": 21.1333,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Rekovac",
    "capital": "admin",
    "population": null,
    "id": 1688955843
  },
  {
    "city": "Skopun",
    "city_ascii": "Skopun",
    "lat": 61.9028,
    "lng": -6.8823,
    "country": "Faroe Islands",
    "iso2": "FO",
    "iso3": "FRO",
    "admin_name": "",
    "capital": "admin",
    "population": null,
    "id": 1234929032
  },
  {
    "city": "Mamuju",
    "city_ascii": "Mamuju",
    "lat": -2.6806,
    "lng": 118.8861,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Sulawesi Barat",
    "capital": "admin",
    "population": null,
    "id": 1360687242
  },
  {
    "city": "Hargeysa",
    "city_ascii": "Hargeysa",
    "lat": 9.56,
    "lng": 44.065,
    "country": "Somalia",
    "iso2": "SO",
    "iso3": "SOM",
    "admin_name": "Woqooyi Galbeed",
    "capital": "admin",
    "population": null,
    "id": 1706832021
  },
  {
    "city": "Kiruhura",
    "city_ascii": "Kiruhura",
    "lat": -0.2356,
    "lng": 30.8725,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Kiruhura",
    "capital": "admin",
    "population": null,
    "id": 1800198933
  },
  {
    "city": "Leava",
    "city_ascii": "Leava",
    "lat": -14.2933,
    "lng": -178.1583,
    "country": "Wallis And Futuna",
    "iso2": "WF",
    "iso3": "WLF",
    "admin_name": "Sigave",
    "capital": "admin",
    "population": null,
    "id": 1876731744
  },
  {
    "city": "Garkalne",
    "city_ascii": "Garkalne",
    "lat": 57.0449,
    "lng": 24.4195,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Garkalnes Novads",
    "capital": "admin",
    "population": null,
    "id": 1428447019
  },
  {
    "city": "Santo António",
    "city_ascii": "Santo Antonio",
    "lat": 1.6386,
    "lng": 7.4206,
    "country": "Sao Tome And Principe",
    "iso2": "ST",
    "iso3": "STP",
    "admin_name": "Príncipe",
    "capital": "admin",
    "population": null,
    "id": 1678729743
  },
  {
    "city": "Zrnovci",
    "city_ascii": "Zrnovci",
    "lat": 41.8542,
    "lng": 22.4444,
    "country": "Macedonia",
    "iso2": "MK",
    "iso3": "MKD",
    "admin_name": "Zrnovci",
    "capital": "admin",
    "population": null,
    "id": 1807046032
  },
  {
    "city": "Novi Kneževac",
    "city_ascii": "Novi Knezevac",
    "lat": 46.05,
    "lng": 20.1,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Novi Kneževac",
    "capital": "admin",
    "population": null,
    "id": 1688706489
  },
  {
    "city": "Cəbrayıl",
    "city_ascii": "Cabrayil",
    "lat": 39.4,
    "lng": 47.0261,
    "country": "Azerbaijan",
    "iso2": "AZ",
    "iso3": "AZE",
    "admin_name": "Cəbrayıl",
    "capital": "admin",
    "population": null,
    "id": 1031379235
  },
  {
    "city": "Tsimasham",
    "city_ascii": "Tsimasham",
    "lat": 27.0989,
    "lng": 89.536,
    "country": "Bhutan",
    "iso2": "BT",
    "iso3": "BTN",
    "admin_name": "Chhukha",
    "capital": "admin",
    "population": null,
    "id": 1064167489
  },
  {
    "city": "Lajkovac",
    "city_ascii": "Lajkovac",
    "lat": 44.3667,
    "lng": 20.1667,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Lajkovac",
    "capital": "admin",
    "population": null,
    "id": 1688900219
  },
  {
    "city": "Ijevan",
    "city_ascii": "Ijevan",
    "lat": 40.8788,
    "lng": 45.1485,
    "country": "Armenia",
    "iso2": "AM",
    "iso3": "ARM",
    "admin_name": "Tavush",
    "capital": "admin",
    "population": null,
    "id": 1051007129
  },
  {
    "city": "Bijelo Polje",
    "city_ascii": "Bijelo Polje",
    "lat": 43.0292,
    "lng": 19.7456,
    "country": "Montenegro",
    "iso2": "ME",
    "iso3": "MNE",
    "admin_name": "Bijelo Polje",
    "capital": "admin",
    "population": null,
    "id": 1499452756
  },
  {
    "city": "Madīnat ash Shamāl",
    "city_ascii": "Madinat ash Shamal",
    "lat": 26.1293,
    "lng": 51.2009,
    "country": "Qatar",
    "iso2": "QA",
    "iso3": "QAT",
    "admin_name": "Ash Shamāl",
    "capital": "admin",
    "population": null,
    "id": 1634762661
  },
  {
    "city": "Bāzārak",
    "city_ascii": "Bazarak",
    "lat": 35.3129,
    "lng": 69.5152,
    "country": "Afghanistan",
    "iso2": "AF",
    "iso3": "AFG",
    "admin_name": "Panjshir",
    "capital": "admin",
    "population": null,
    "id": 1004374554
  },
  {
    "city": "Aleksandrovac",
    "city_ascii": "Aleksandrovac",
    "lat": 43.4553,
    "lng": 21.0514,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Aleksandrovac",
    "capital": "admin",
    "population": null,
    "id": 1688573505
  },
  {
    "city": "Petnjica",
    "city_ascii": "Petnjica",
    "lat": 42.9089,
    "lng": 19.9644,
    "country": "Montenegro",
    "iso2": "ME",
    "iso3": "MNE",
    "admin_name": "Petnjica",
    "capital": "admin",
    "population": null,
    "id": 1499532482
  },
  {
    "city": "Sodražica",
    "city_ascii": "Sodrazica",
    "lat": 45.7611,
    "lng": 14.6356,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Sodražica",
    "capital": "admin",
    "population": null,
    "id": 1705295579
  },
  {
    "city": "Samux",
    "city_ascii": "Samux",
    "lat": 40.7649,
    "lng": 46.4087,
    "country": "Azerbaijan",
    "iso2": "AZ",
    "iso3": "AZE",
    "admin_name": "Samux",
    "capital": "admin",
    "population": null,
    "id": 1031600567
  },
  {
    "city": "Dobrovnik",
    "city_ascii": "Dobrovnik",
    "lat": 46.6514,
    "lng": 16.3525,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Dobrovnik",
    "capital": "admin",
    "population": null,
    "id": 1705602796
  },
  {
    "city": "Pala",
    "city_ascii": "Pala",
    "lat": 9.3646,
    "lng": 14.9073,
    "country": "Chad",
    "iso2": "TD",
    "iso3": "TCD",
    "admin_name": "Mayo-Kébbi Ouest",
    "capital": "admin",
    "population": null,
    "id": 1148687410
  },
  {
    "city": "Matam",
    "city_ascii": "Matam",
    "lat": 15.6167,
    "lng": -13.3333,
    "country": "Senegal",
    "iso2": "SN",
    "iso3": "SEN",
    "admin_name": "Matam",
    "capital": "admin",
    "population": null,
    "id": 1686919750
  },
  {
    "city": "Abim",
    "city_ascii": "Abim",
    "lat": 2.7017,
    "lng": 33.6761,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Abim",
    "capital": "admin",
    "population": null,
    "id": 1800199589
  },
  {
    "city": "Dalandzadgad",
    "city_ascii": "Dalandzadgad",
    "lat": 43.5708,
    "lng": 104.425,
    "country": "Mongolia",
    "iso2": "MN",
    "iso3": "MNG",
    "admin_name": "Ömnögovĭ",
    "capital": "admin",
    "population": null,
    "id": 1496141284
  },
  {
    "city": "Rio Claro",
    "city_ascii": "Rio Claro",
    "lat": 10.3059,
    "lng": -61.1756,
    "country": "Trinidad And Tobago",
    "iso2": "TT",
    "iso3": "TTO",
    "admin_name": "Mayaro/Rio Claro",
    "capital": "admin",
    "population": null,
    "id": 1780883666
  },
  {
    "city": "Malo Crniće",
    "city_ascii": "Malo Crnice",
    "lat": 44.5603,
    "lng": 21.2906,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Malo Crniće",
    "capital": "admin",
    "population": null,
    "id": 1688544172
  },
  {
    "city": "Piņķi",
    "city_ascii": "Pinki",
    "lat": 56.9419,
    "lng": 23.9137,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Babītes Novads",
    "capital": "admin",
    "population": null,
    "id": 1428171019
  },
  {
    "city": "Kuršumlija",
    "city_ascii": "Kursumlija",
    "lat": 43.1408,
    "lng": 21.2678,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Kuršumlija",
    "capital": "admin",
    "population": null,
    "id": 1688343996
  },
  {
    "city": "Ntoroko",
    "city_ascii": "Ntoroko",
    "lat": 1.0411,
    "lng": 30.4811,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Ntoroko",
    "capital": "admin",
    "population": null,
    "id": 1800207074
  },
  {
    "city": "Dəvəçi",
    "city_ascii": "Davaci",
    "lat": 41.2012,
    "lng": 48.9871,
    "country": "Azerbaijan",
    "iso2": "AZ",
    "iso3": "AZE",
    "admin_name": "Şabran",
    "capital": "admin",
    "population": null,
    "id": 1031053363
  },
  {
    "city": "Čučer-Sandevo",
    "city_ascii": "Cucer-Sandevo",
    "lat": 42.102,
    "lng": 21.3846,
    "country": "Macedonia",
    "iso2": "MK",
    "iso3": "MKD",
    "admin_name": "Čučer-Sandevo",
    "capital": "admin",
    "population": null,
    "id": 1807969277
  },
  {
    "city": "Raška",
    "city_ascii": "Raska",
    "lat": 43.2856,
    "lng": 20.6092,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Raška",
    "capital": "admin",
    "population": null,
    "id": 1688129790
  },
  {
    "city": "Mislinja",
    "city_ascii": "Mislinja",
    "lat": 46.4411,
    "lng": 15.1956,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Mislinja",
    "capital": "admin",
    "population": null,
    "id": 1705898157
  },
  {
    "city": "Golubac",
    "city_ascii": "Golubac",
    "lat": 44.6529,
    "lng": 21.6308,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Golubac",
    "capital": "admin",
    "population": null,
    "id": 1688460377
  },
  {
    "city": "Norðragøta",
    "city_ascii": "Nordhragota",
    "lat": 62.199,
    "lng": -6.7432,
    "country": "Faroe Islands",
    "iso2": "FO",
    "iso3": "FRO",
    "admin_name": "",
    "capital": "admin",
    "population": null,
    "id": 1234729250
  },
  {
    "city": "Pećinci",
    "city_ascii": "Pecinci",
    "lat": 44.9089,
    "lng": 19.9664,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Pećinci",
    "capital": "admin",
    "population": null,
    "id": 1688856329
  },
  {
    "city": "Qıvraq",
    "city_ascii": "Qivraq",
    "lat": 39.3994,
    "lng": 45.1151,
    "country": "Azerbaijan",
    "iso2": "AZ",
    "iso3": "AZE",
    "admin_name": "Kəngərli",
    "capital": "admin",
    "population": null,
    "id": 1031050255
  },
  {
    "city": "Nova Crnja",
    "city_ascii": "Nova Crnja",
    "lat": 45.6685,
    "lng": 20.6042,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Nova Crnja",
    "capital": "admin",
    "population": null,
    "id": 1688294163
  },
  {
    "city": "Podčetrtek",
    "city_ascii": "Podcetrtek",
    "lat": 46.1569,
    "lng": 15.5986,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Podčetrtek",
    "capital": "admin",
    "population": null,
    "id": 1705261000
  },
  {
    "city": "Samamea",
    "city_ascii": "Samamea",
    "lat": -13.9338,
    "lng": -171.5312,
    "country": "Samoa",
    "iso2": "WS",
    "iso3": "WSM",
    "admin_name": "Va‘a-o-Fonoti",
    "capital": "admin",
    "population": null,
    "id": 1882468874
  },
  {
    "city": "Xai",
    "city_ascii": "Xai",
    "lat": 20.6922,
    "lng": 101.9847,
    "country": "Laos",
    "iso2": "LA",
    "iso3": "LAO",
    "admin_name": "Oudômxai",
    "capital": "admin",
    "population": null,
    "id": 1418805827
  },
  {
    "city": "Ar Rustāq",
    "city_ascii": "Ar Rustaq",
    "lat": 23.3908,
    "lng": 57.4244,
    "country": "Oman",
    "iso2": "OM",
    "iso3": "OMN",
    "admin_name": "Janūb al Bāţinah",
    "capital": "admin",
    "population": null,
    "id": 1512376854
  },
  {
    "city": "Majdanpek",
    "city_ascii": "Majdanpek",
    "lat": 44.4167,
    "lng": 21.9333,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Majdanpek",
    "capital": "admin",
    "population": null,
    "id": 1688109331
  },
  {
    "city": "Danilovgrad",
    "city_ascii": "Danilovgrad",
    "lat": 42.61,
    "lng": 19.05,
    "country": "Montenegro",
    "iso2": "ME",
    "iso3": "MNE",
    "admin_name": "Danilovgrad",
    "capital": "admin",
    "population": null,
    "id": 1499538261
  },
  {
    "city": "Buka",
    "city_ascii": "Buka",
    "lat": -5.42,
    "lng": 154.67,
    "country": "Papua New Guinea",
    "iso2": "PG",
    "iso3": "PNG",
    "admin_name": "Bougainville",
    "capital": "admin",
    "population": null,
    "id": 1598000066
  },
  {
    "city": "Makedonska Kamenica",
    "city_ascii": "Makedonska Kamenica",
    "lat": 42.0208,
    "lng": 22.5876,
    "country": "Macedonia",
    "iso2": "MK",
    "iso3": "MKD",
    "admin_name": "Makedonska Kamenica",
    "capital": "admin",
    "population": null,
    "id": 1807768534
  },
  {
    "city": "Satupa‘itea",
    "city_ascii": "Satupa`itea",
    "lat": -13.7659,
    "lng": -172.3269,
    "country": "Samoa",
    "iso2": "WS",
    "iso3": "WSM",
    "admin_name": "Satupa‘itea",
    "capital": "admin",
    "population": null,
    "id": 1882940042
  },
  {
    "city": "Otuke",
    "city_ascii": "Otuke",
    "lat": 2.5004,
    "lng": 33.5007,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Otuke",
    "capital": "admin",
    "population": null,
    "id": 1800657796
  },
  {
    "city": "Ruma",
    "city_ascii": "Ruma",
    "lat": 45.0031,
    "lng": 19.8289,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Ruma",
    "capital": "admin",
    "population": null,
    "id": 1688037789
  },
  {
    "city": "Samtse",
    "city_ascii": "Samtse",
    "lat": 26.8667,
    "lng": 89.1,
    "country": "Bhutan",
    "iso2": "BT",
    "iso3": "BTN",
    "admin_name": "Samtse",
    "capital": "admin",
    "population": null,
    "id": 1064763429
  },
  {
    "city": "Skrīveri",
    "city_ascii": "Skriveri",
    "lat": 56.6485,
    "lng": 25.1195,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Skrīveru Novads",
    "capital": "admin",
    "population": null,
    "id": 1428849429
  },
  {
    "city": "Asau",
    "city_ascii": "Asau",
    "lat": -13.5196,
    "lng": -172.6378,
    "country": "Samoa",
    "iso2": "WS",
    "iso3": "WSM",
    "admin_name": "Vaisigano",
    "capital": "admin",
    "population": null,
    "id": 1882504626
  },
  {
    "city": "Hrib-Loški Potok",
    "city_ascii": "Hrib-Loski Potok",
    "lat": 45.7011,
    "lng": 14.5911,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Loški Potok",
    "capital": "admin",
    "population": null,
    "id": 1705118688
  },
  {
    "city": "Nikšić",
    "city_ascii": "Niksic",
    "lat": 42.78,
    "lng": 18.94,
    "country": "Montenegro",
    "iso2": "ME",
    "iso3": "MNE",
    "admin_name": "Nikšić",
    "capital": "admin",
    "population": null,
    "id": 1499844133
  },
  {
    "city": "Şūr",
    "city_ascii": "Sur",
    "lat": 22.5667,
    "lng": 59.5289,
    "country": "Oman",
    "iso2": "OM",
    "iso3": "OMN",
    "admin_name": "Janūb ash Sharqīyah",
    "capital": "admin",
    "population": null,
    "id": 1512350600
  },
  {
    "city": "Dolneni",
    "city_ascii": "Dolneni",
    "lat": 41.4258,
    "lng": 21.454,
    "country": "Macedonia",
    "iso2": "MK",
    "iso3": "MKD",
    "admin_name": "Dolneni",
    "capital": "admin",
    "population": null,
    "id": 1807169470
  },
  {
    "city": "Safotu",
    "city_ascii": "Safotu",
    "lat": -13.4513,
    "lng": -172.4018,
    "country": "Samoa",
    "iso2": "WS",
    "iso3": "WSM",
    "admin_name": "Gagaifomauga",
    "capital": "admin",
    "population": null,
    "id": 1882879383
  },
  {
    "city": "Kəlbəcər",
    "city_ascii": "Kalbacar",
    "lat": 40.1098,
    "lng": 46.0445,
    "country": "Azerbaijan",
    "iso2": "AZ",
    "iso3": "AZE",
    "admin_name": "Kəlbəcər",
    "capital": "admin",
    "population": null,
    "id": 1031212922
  },
  {
    "city": "Kobilje",
    "city_ascii": "Kobilje",
    "lat": 46.6847,
    "lng": 16.3978,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Kobilje",
    "capital": "admin",
    "population": null,
    "id": 1705965317
  },
  {
    "city": "Vladimirci",
    "city_ascii": "Vladimirci",
    "lat": 44.6158,
    "lng": 19.7843,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Vladimirci",
    "capital": "admin",
    "population": null,
    "id": 1688070989
  },
  {
    "city": "Vailoa",
    "city_ascii": "Vailoa",
    "lat": -13.7555,
    "lng": -172.307,
    "country": "Samoa",
    "iso2": "WS",
    "iso3": "WSM",
    "admin_name": "Palauli",
    "capital": "admin",
    "population": null,
    "id": 1882047846
  },
  {
    "city": "Kranjska Gora",
    "city_ascii": "Kranjska Gora",
    "lat": 46.4839,
    "lng": 13.7894,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Kranjska Gora",
    "capital": "admin",
    "population": null,
    "id": 1705443136
  },
  {
    "city": "Amuru",
    "city_ascii": "Amuru",
    "lat": 2.8139,
    "lng": 31.9387,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Amuru",
    "capital": "admin",
    "population": null,
    "id": 1800642608
  },
  {
    "city": "Iecava",
    "city_ascii": "Iecava",
    "lat": 56.5977,
    "lng": 24.2076,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Iecavas Novads",
    "capital": "admin",
    "population": null,
    "id": 1428743931
  },
  {
    "city": "Rosoman",
    "city_ascii": "Rosoman",
    "lat": 41.5167,
    "lng": 21.9458,
    "country": "Macedonia",
    "iso2": "MK",
    "iso3": "MKD",
    "admin_name": "Rosoman",
    "capital": "admin",
    "population": null,
    "id": 1807663389
  },
  {
    "city": "Priekuļi",
    "city_ascii": "Priekuli",
    "lat": 57.3115,
    "lng": 25.3571,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Priekuļu Novads",
    "capital": "admin",
    "population": null,
    "id": 1428165145
  },
  {
    "city": "Buabidi",
    "city_ascii": "Buabidi",
    "lat": 8.4746,
    "lng": -81.6983,
    "country": "Panama",
    "iso2": "PA",
    "iso3": "PAN",
    "admin_name": "Ngöbe-Buglé",
    "capital": "admin",
    "population": null,
    "id": 1591495919
  },
  {
    "city": "Gusinje",
    "city_ascii": "Gusinje",
    "lat": 42.5619,
    "lng": 19.8339,
    "country": "Montenegro",
    "iso2": "ME",
    "iso3": "MNE",
    "admin_name": "Gusinje",
    "capital": "admin",
    "population": null,
    "id": 1499342041
  },
  {
    "city": "Ribeira Brava",
    "city_ascii": "Ribeira Brava",
    "lat": 16.6158,
    "lng": -24.2983,
    "country": "Cabo Verde",
    "iso2": "CV",
    "iso3": "CPV",
    "admin_name": "Ribeira Brava",
    "capital": "admin",
    "population": null,
    "id": 1132498214
  },
  {
    "city": "Ibrā’",
    "city_ascii": "Ibra'",
    "lat": 22.6906,
    "lng": 58.5334,
    "country": "Oman",
    "iso2": "OM",
    "iso3": "OMN",
    "admin_name": "Shamāl ash Sharqīyah",
    "capital": "admin",
    "population": null,
    "id": 1512341564
  },
  {
    "city": "Žetale",
    "city_ascii": "Zetale",
    "lat": 46.275,
    "lng": 15.7939,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Žetale",
    "capital": "admin",
    "population": null,
    "id": 1705941695
  },
  {
    "city": "Şuşa",
    "city_ascii": "Susa",
    "lat": 39.7601,
    "lng": 46.7499,
    "country": "Azerbaijan",
    "iso2": "AZ",
    "iso3": "AZE",
    "admin_name": "Şuşa",
    "capital": "admin",
    "population": null,
    "id": 1031808614
  },
  {
    "city": "Tutin",
    "city_ascii": "Tutin",
    "lat": 42.9875,
    "lng": 20.3256,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Tutin",
    "capital": "admin",
    "population": null,
    "id": 1688161411
  },
  {
    "city": "Zelenikovo",
    "city_ascii": "Zelenikovo",
    "lat": 41.8841,
    "lng": 21.5885,
    "country": "Macedonia",
    "iso2": "MK",
    "iso3": "MKD",
    "admin_name": "Zelenikovo",
    "capital": "admin",
    "population": null,
    "id": 1807765797
  },
  {
    "city": "Resen",
    "city_ascii": "Resen",
    "lat": 41.0893,
    "lng": 21.0109,
    "country": "Macedonia",
    "iso2": "MK",
    "iso3": "MKD",
    "admin_name": "Resen",
    "capital": "admin",
    "population": null,
    "id": 1807384915
  },
  {
    "city": "Babušnica",
    "city_ascii": "Babusnica",
    "lat": 43.068,
    "lng": 22.4115,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Babušnica",
    "capital": "admin",
    "population": null,
    "id": 1688685780
  },
  {
    "city": "Xocavənd",
    "city_ascii": "Xocavand",
    "lat": 39.795,
    "lng": 47.1117,
    "country": "Azerbaijan",
    "iso2": "AZ",
    "iso3": "AZE",
    "admin_name": "Xocavənd",
    "capital": "admin",
    "population": null,
    "id": 1031454735
  },
  {
    "city": "Sørvágur",
    "city_ascii": "Sorvagur",
    "lat": 62.0717,
    "lng": -7.3066,
    "country": "Faroe Islands",
    "iso2": "FO",
    "iso3": "FRO",
    "admin_name": "",
    "capital": "admin",
    "population": null,
    "id": 1234081530
  },
  {
    "city": "Kaišiadorys",
    "city_ascii": "Kaisiadorys",
    "lat": 54.8653,
    "lng": 24.4682,
    "country": "Lithuania",
    "iso2": "LT",
    "iso3": "LTU",
    "admin_name": "Kaišiadorys",
    "capital": "admin",
    "population": null,
    "id": 1440257941
  },
  {
    "city": "Brus",
    "city_ascii": "Brus",
    "lat": 43.3836,
    "lng": 21.0336,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Brus",
    "capital": "admin",
    "population": null,
    "id": 1688936225
  },
  {
    "city": "Şahbuz",
    "city_ascii": "Sahbuz",
    "lat": 39.4072,
    "lng": 45.5739,
    "country": "Azerbaijan",
    "iso2": "AZ",
    "iso3": "AZE",
    "admin_name": "Şahbuz",
    "capital": "admin",
    "population": null,
    "id": 1031323095
  },
  {
    "city": "Hvannasund",
    "city_ascii": "Hvannasund",
    "lat": 62.2962,
    "lng": -6.5204,
    "country": "Faroe Islands",
    "iso2": "FO",
    "iso3": "FRO",
    "admin_name": "",
    "capital": "admin",
    "population": null,
    "id": 1234436400
  },
  {
    "city": "Eiði",
    "city_ascii": "Eidhi",
    "lat": 62.2995,
    "lng": -7.0924,
    "country": "Faroe Islands",
    "iso2": "FO",
    "iso3": "FRO",
    "admin_name": "",
    "capital": "admin",
    "population": null,
    "id": 1234150922
  },
  {
    "city": "Massakory",
    "city_ascii": "Massakory",
    "lat": 13,
    "lng": 15.7333,
    "country": "Chad",
    "iso2": "TD",
    "iso3": "TCD",
    "admin_name": "Hadjer-Lamis",
    "capital": "admin",
    "population": null,
    "id": 1148908311
  },
  {
    "city": "Šalovci",
    "city_ascii": "Salovci",
    "lat": 46.825,
    "lng": 16.2981,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Šalovci",
    "capital": "admin",
    "population": null,
    "id": 1705296581
  },
  {
    "city": "Osečina",
    "city_ascii": "Osecina",
    "lat": 44.3667,
    "lng": 19.6,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Osečina",
    "capital": "admin",
    "population": null,
    "id": 1688862457
  },
  {
    "city": "Buba",
    "city_ascii": "Buba",
    "lat": 11.59,
    "lng": -14.99,
    "country": "Guinea-Bissau",
    "iso2": "GW",
    "iso3": "GNB",
    "admin_name": "Quinara",
    "capital": "admin",
    "population": null,
    "id": 1624903078
  },
  {
    "city": "Al Ḩazm",
    "city_ascii": "Al Hazm",
    "lat": 16.1641,
    "lng": 44.7769,
    "country": "Yemen",
    "iso2": "YE",
    "iso3": "YEM",
    "admin_name": "Al Jawf",
    "capital": "admin",
    "population": null,
    "id": 1887549671
  },
  {
    "city": "Mogila",
    "city_ascii": "Mogila",
    "lat": 41.1083,
    "lng": 21.3786,
    "country": "Macedonia",
    "iso2": "MK",
    "iso3": "MKD",
    "admin_name": "Mogila",
    "capital": "admin",
    "population": null,
    "id": 1807583335
  },
  {
    "city": "Nakapiripirit",
    "city_ascii": "Nakapiripirit",
    "lat": 1.9167,
    "lng": 34.7833,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Nakapiripirit",
    "capital": "admin",
    "population": null,
    "id": 1800663685
  },
  {
    "city": "Barentu",
    "city_ascii": "Barentu",
    "lat": 15.1058,
    "lng": 37.5907,
    "country": "Eritrea",
    "iso2": "ER",
    "iso3": "ERI",
    "admin_name": "Gash Barka",
    "capital": "admin",
    "population": null,
    "id": 1232695352
  },
  {
    "city": "Veliko Gradište",
    "city_ascii": "Veliko Gradiste",
    "lat": 44.75,
    "lng": 21.5167,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Veliko Gradište",
    "capital": "admin",
    "population": null,
    "id": 1688050059
  },
  {
    "city": "Bangar",
    "city_ascii": "Bangar",
    "lat": 4.7083,
    "lng": 115.0736,
    "country": "Brunei",
    "iso2": "BN",
    "iso3": "BRN",
    "admin_name": "Temburong",
    "capital": "admin",
    "population": null,
    "id": 1096321221
  },
  {
    "city": "Pili",
    "city_ascii": "Pili",
    "lat": 13.7177,
    "lng": 123.7448,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Camarines Sur",
    "capital": "admin",
    "population": null,
    "id": 1608825988
  },
  {
    "city": "Tofol",
    "city_ascii": "Tofol",
    "lat": 5.3261,
    "lng": 163.0083,
    "country": "Micronesia, Federated States Of",
    "iso2": "FM",
    "iso3": "FSM",
    "admin_name": "Kosrae",
    "capital": "admin",
    "population": null,
    "id": 1583000000
  },
  {
    "city": "Burco",
    "city_ascii": "Burco",
    "lat": 9.5221,
    "lng": 45.5336,
    "country": "Somalia",
    "iso2": "SO",
    "iso3": "SOM",
    "admin_name": "Togdheer",
    "capital": "admin",
    "population": null,
    "id": 1706322754
  },
  {
    "city": "Kučevo",
    "city_ascii": "Kucevo",
    "lat": 44.4833,
    "lng": 21.6667,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Kučevo",
    "capital": "admin",
    "population": null,
    "id": 1688495771
  },
  {
    "city": "Koceljeva",
    "city_ascii": "Koceljeva",
    "lat": 44.4687,
    "lng": 19.8214,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Koceljeva",
    "capital": "admin",
    "population": null,
    "id": 1688585119
  },
  {
    "city": "Oyrarbakki",
    "city_ascii": "Oyrarbakki",
    "lat": 62.2079,
    "lng": -6.9997,
    "country": "Faroe Islands",
    "iso2": "FO",
    "iso3": "FRO",
    "admin_name": "",
    "capital": "admin",
    "population": null,
    "id": 1234924029
  },
  {
    "city": "Arta",
    "city_ascii": "Arta",
    "lat": 11.5264,
    "lng": 42.8519,
    "country": "Djibouti",
    "iso2": "DJ",
    "iso3": "DJI",
    "admin_name": "Arta",
    "capital": "admin",
    "population": null,
    "id": 1262889858
  },
  {
    "city": "Temerin",
    "city_ascii": "Temerin",
    "lat": 45.4053,
    "lng": 19.8869,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Temerin",
    "capital": "admin",
    "population": null,
    "id": 1688171551
  },
  {
    "city": "Vestmanna",
    "city_ascii": "Vestmanna",
    "lat": 62.1548,
    "lng": -7.169,
    "country": "Faroe Islands",
    "iso2": "FO",
    "iso3": "FRO",
    "admin_name": "",
    "capital": "admin",
    "population": null,
    "id": 1234694837
  },
  {
    "city": "Porkeri",
    "city_ascii": "Porkeri",
    "lat": 61.4814,
    "lng": -6.745,
    "country": "Faroe Islands",
    "iso2": "FO",
    "iso3": "FRO",
    "admin_name": "",
    "capital": "admin",
    "population": null,
    "id": 1234757268
  },
  {
    "city": "Raseiniai",
    "city_ascii": "Raseiniai",
    "lat": 55.3797,
    "lng": 23.1239,
    "country": "Lithuania",
    "iso2": "LT",
    "iso3": "LTU",
    "admin_name": "Raseiniai",
    "capital": "admin",
    "population": null,
    "id": 1440481520
  },
  {
    "city": "Gadžin Han",
    "city_ascii": "Gadzin Han",
    "lat": 43.2223,
    "lng": 22.0333,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Gadžin Han",
    "capital": "admin",
    "population": null,
    "id": 1688569895
  },
  {
    "city": "Roja",
    "city_ascii": "Roja",
    "lat": 57.5015,
    "lng": 22.8088,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Rojas Novads",
    "capital": "admin",
    "population": null,
    "id": 1428577367
  },
  {
    "city": "Gaigirgordub",
    "city_ascii": "Gaigirgordub",
    "lat": 9.55,
    "lng": -78.9667,
    "country": "Panama",
    "iso2": "PA",
    "iso3": "PAN",
    "admin_name": "Kuna Yala",
    "capital": "admin",
    "population": 10,
    "id": 1591425685
  },
  {
    "city": "Opovo",
    "city_ascii": "Opovo",
    "lat": 45.0522,
    "lng": 20.4303,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Opovo",
    "capital": "admin",
    "population": null,
    "id": 1688371736
  },
  {
    "city": "Kyankwanzi",
    "city_ascii": "Kyankwanzi",
    "lat": 1.1987,
    "lng": 31.8062,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Kyankwanzi",
    "capital": "admin",
    "population": null,
    "id": 1800427588
  },
  {
    "city": "Ropaži",
    "city_ascii": "Ropazi",
    "lat": 56.9747,
    "lng": 24.6329,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Ropažu Novads",
    "capital": "admin",
    "population": null,
    "id": 1428220999
  },
  {
    "city": "São João dos Angolares",
    "city_ascii": "Sao Joao dos Angolares",
    "lat": 0.1342,
    "lng": 6.6494,
    "country": "Sao Tome And Principe",
    "iso2": "ST",
    "iso3": "STP",
    "admin_name": "São Tomé",
    "capital": "admin",
    "population": null,
    "id": 1678826533
  },
  {
    "city": "Hvalba",
    "city_ascii": "Hvalba",
    "lat": 61.6024,
    "lng": -6.9606,
    "country": "Faroe Islands",
    "iso2": "FO",
    "iso3": "FRO",
    "admin_name": "",
    "capital": "admin",
    "population": null,
    "id": 1234381324
  },
  {
    "city": "Smārde",
    "city_ascii": "Smarde",
    "lat": 56.9533,
    "lng": 23.3387,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Engures Novads",
    "capital": "admin",
    "population": null,
    "id": 1428580716
  },
  {
    "city": "Ntchisi",
    "city_ascii": "Ntchisi",
    "lat": -13.3753,
    "lng": 34.0036,
    "country": "Malawi",
    "iso2": "MW",
    "iso3": "MWI",
    "admin_name": "Ntchisi",
    "capital": "admin",
    "population": null,
    "id": 1454004377
  },
  {
    "city": "Xékong",
    "city_ascii": "Xekong",
    "lat": 15.3503,
    "lng": 106.7286,
    "country": "Laos",
    "iso2": "LA",
    "iso3": "LAO",
    "admin_name": "Xékong",
    "capital": "admin",
    "population": null,
    "id": 1418000082
  },
  {
    "city": "Trashi Yangtse",
    "city_ascii": "Trashi Yangtse",
    "lat": 27.6116,
    "lng": 91.498,
    "country": "Bhutan",
    "iso2": "BT",
    "iso3": "BTN",
    "admin_name": "Trashi Yangtse",
    "capital": "admin",
    "population": null,
    "id": 1064210713
  },
  {
    "city": "Bistrica ob Sotli",
    "city_ascii": "Bistrica ob Sotli",
    "lat": 46.0569,
    "lng": 15.6625,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Bistrica ob Sotli",
    "capital": "admin",
    "population": null,
    "id": 1705641346
  },
  {
    "city": "Pilsrundāle",
    "city_ascii": "Pilsrundale",
    "lat": 56.4181,
    "lng": 24.0162,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Rundāles Novads",
    "capital": "admin",
    "population": null,
    "id": 1428889985
  },
  {
    "city": "Sumba",
    "city_ascii": "Sumba",
    "lat": 61.4055,
    "lng": -6.709,
    "country": "Faroe Islands",
    "iso2": "FO",
    "iso3": "FRO",
    "admin_name": "",
    "capital": "admin",
    "population": null,
    "id": 1234926212
  },
  {
    "city": "Mērsrags",
    "city_ascii": "Mersrags",
    "lat": 57.3364,
    "lng": 23.1235,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Mērsraga Novads",
    "capital": "admin",
    "population": null,
    "id": 1428954276
  },
  {
    "city": "Kvívík",
    "city_ascii": "Kvivik",
    "lat": 62.1186,
    "lng": -7.0737,
    "country": "Faroe Islands",
    "iso2": "FO",
    "iso3": "FRO",
    "admin_name": "",
    "capital": "admin",
    "population": null,
    "id": 1234955585
  },
  {
    "city": "Tērvete",
    "city_ascii": "Tervete",
    "lat": 56.4798,
    "lng": 23.3902,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Tērvetes Novads",
    "capital": "admin",
    "population": null,
    "id": 1428176770
  },
  {
    "city": "Karbinci",
    "city_ascii": "Karbinci",
    "lat": 41.8176,
    "lng": 22.2353,
    "country": "Macedonia",
    "iso2": "MK",
    "iso3": "MKD",
    "admin_name": "Karbinci",
    "capital": "admin",
    "population": null,
    "id": 1807568669
  },
  {
    "city": "Mālpils",
    "city_ascii": "Malpils",
    "lat": 57.0101,
    "lng": 24.9578,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Mālpils Novads",
    "capital": "admin",
    "population": null,
    "id": 1428737728
  },
  {
    "city": "Solčava",
    "city_ascii": "Solcava",
    "lat": 46.4194,
    "lng": 14.6936,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Solčava",
    "capital": "admin",
    "population": null,
    "id": 1705581561
  },
  {
    "city": "Pagėgiai",
    "city_ascii": "Pagegiai",
    "lat": 55.1379,
    "lng": 21.9138,
    "country": "Lithuania",
    "iso2": "LT",
    "iso3": "LTU",
    "admin_name": "Pagégiai",
    "capital": "admin",
    "population": null,
    "id": 1440724133
  },
  {
    "city": "Ragana",
    "city_ascii": "Ragana",
    "lat": 57.1821,
    "lng": 24.7056,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Krimuldas Novads",
    "capital": "admin",
    "population": null,
    "id": 1428990628
  },
  {
    "city": "Koné",
    "city_ascii": "Kone",
    "lat": -21.0667,
    "lng": 164.8667,
    "country": "New Caledonia",
    "iso2": "NC",
    "iso3": "NCL",
    "admin_name": "Province Nord",
    "capital": "admin",
    "population": null,
    "id": 1540397627
  },
  {
    "city": "Zalingei",
    "city_ascii": "Zalingei",
    "lat": 12.9092,
    "lng": 23.4706,
    "country": "Sudan",
    "iso2": "SD",
    "iso3": "SDN",
    "admin_name": "Central Darfur",
    "capital": "admin",
    "population": null,
    "id": 1729805075
  },
  {
    "city": "Sandavágur",
    "city_ascii": "Sandavagur",
    "lat": 62.0537,
    "lng": -7.1498,
    "country": "Faroe Islands",
    "iso2": "FO",
    "iso3": "FRO",
    "admin_name": "",
    "capital": "admin",
    "population": null,
    "id": 1234210554
  },
  {
    "city": "Hodoš",
    "city_ascii": "Hodos",
    "lat": 46.8233,
    "lng": 16.3342,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Hodoš",
    "capital": "admin",
    "population": null,
    "id": 1705217643
  },
  {
    "city": "Konče",
    "city_ascii": "Konce",
    "lat": 41.4951,
    "lng": 22.3836,
    "country": "Macedonia",
    "iso2": "MK",
    "iso3": "MKD",
    "admin_name": "Konče",
    "capital": "admin",
    "population": null,
    "id": 1807382770
  },
  {
    "city": "Rankovce",
    "city_ascii": "Rankovce",
    "lat": 42.1686,
    "lng": 22.1166,
    "country": "Macedonia",
    "iso2": "MK",
    "iso3": "MKD",
    "admin_name": "Rankovce",
    "capital": "admin",
    "population": null,
    "id": 1807575395
  },
  {
    "city": "Lozovo",
    "city_ascii": "Lozovo",
    "lat": 41.7839,
    "lng": 21.9056,
    "country": "Macedonia",
    "iso2": "MK",
    "iso3": "MKD",
    "admin_name": "Lozovo",
    "capital": "admin",
    "population": null,
    "id": 1807681577
  },
  {
    "city": "Andrijevica",
    "city_ascii": "Andrijevica",
    "lat": 42.7339,
    "lng": 19.7919,
    "country": "Montenegro",
    "iso2": "ME",
    "iso3": "MNE",
    "admin_name": "Andrijevica",
    "capital": "admin",
    "population": null,
    "id": 1499379378
  },
  {
    "city": "Hov",
    "city_ascii": "Hov",
    "lat": 61.5068,
    "lng": -6.7599,
    "country": "Faroe Islands",
    "iso2": "FO",
    "iso3": "FRO",
    "admin_name": "",
    "capital": "admin",
    "population": null,
    "id": 1234279154
  },
  {
    "city": "Sala",
    "city_ascii": "Sala",
    "lat": 56.5047,
    "lng": 25.7618,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Salas Novads",
    "capital": "admin",
    "population": null,
    "id": 1428593161
  },
  {
    "city": "Nwoya",
    "city_ascii": "Nwoya",
    "lat": 2.6342,
    "lng": 32.0011,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Nwoya",
    "capital": "admin",
    "population": null,
    "id": 1800205431
  },
  {
    "city": "Saleaula",
    "city_ascii": "Saleaula",
    "lat": -13.4489,
    "lng": -172.3352,
    "country": "Samoa",
    "iso2": "WS",
    "iso3": "WSM",
    "admin_name": "Gaga‘emauga",
    "capital": "admin",
    "population": null,
    "id": 1882605280
  },
  {
    "city": "Žagubica",
    "city_ascii": "Zagubica",
    "lat": 44.1979,
    "lng": 21.7902,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Žagubica",
    "capital": "admin",
    "population": null,
    "id": 1688849472
  },
  {
    "city": "Kocēni",
    "city_ascii": "Koceni",
    "lat": 57.5198,
    "lng": 25.3404,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Kocēnu Novads",
    "capital": "admin",
    "population": null,
    "id": 1428151958
  },
  {
    "city": "Trgovište",
    "city_ascii": "Trgoviste",
    "lat": 42.3514,
    "lng": 22.0921,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Trgovište",
    "capital": "admin",
    "population": null,
    "id": 1688150657
  },
  {
    "city": "Sola",
    "city_ascii": "Sola",
    "lat": -13.8761,
    "lng": 167.5517,
    "country": "Vanuatu",
    "iso2": "VU",
    "iso3": "VUT",
    "admin_name": "Torba",
    "capital": "admin",
    "population": null,
    "id": 1548600754
  },
  {
    "city": "Jaunpils",
    "city_ascii": "Jaunpils",
    "lat": 56.7314,
    "lng": 23.0125,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Jaunpils Novads",
    "capital": "admin",
    "population": null,
    "id": 1428617492
  },
  {
    "city": "Rostuša",
    "city_ascii": "Rostusa",
    "lat": 41.61,
    "lng": 20.6,
    "country": "Macedonia",
    "iso2": "MK",
    "iso3": "MKD",
    "admin_name": "Mavrovo i Rostuša",
    "capital": "admin",
    "population": null,
    "id": 1807907483
  },
  {
    "city": "Rauna",
    "city_ascii": "Rauna",
    "lat": 57.3337,
    "lng": 25.6085,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Raunas Novads",
    "capital": "admin",
    "population": null,
    "id": 1428987270
  },
  {
    "city": "Semera",
    "city_ascii": "Semera",
    "lat": 11.7956,
    "lng": 41.0086,
    "country": "Ethiopia",
    "iso2": "ET",
    "iso3": "ETH",
    "admin_name": "Āfar",
    "capital": "admin",
    "population": null,
    "id": 1231617820
  },
  {
    "city": "Gradsko",
    "city_ascii": "Gradsko",
    "lat": 41.5775,
    "lng": 21.9428,
    "country": "Macedonia",
    "iso2": "MK",
    "iso3": "MKD",
    "admin_name": "Gradsko",
    "capital": "admin",
    "population": null,
    "id": 1807080471
  },
  {
    "city": "Ignalina",
    "city_ascii": "Ignalina",
    "lat": 55.3406,
    "lng": 26.1605,
    "country": "Lithuania",
    "iso2": "LT",
    "iso3": "LTU",
    "admin_name": "Ignalina",
    "capital": "admin",
    "population": null,
    "id": 1440789779
  },
  {
    "city": "Viðareiði",
    "city_ascii": "Vidhareidhi",
    "lat": 62.36,
    "lng": -6.5313,
    "country": "Faroe Islands",
    "iso2": "FO",
    "iso3": "FRO",
    "admin_name": "",
    "capital": "admin",
    "population": null,
    "id": 1234512915
  },
  {
    "city": "Burtnieki",
    "city_ascii": "Burtnieki",
    "lat": 57.6949,
    "lng": 25.275,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Burtnieku Novads",
    "capital": "admin",
    "population": null,
    "id": 1428423415
  },
  {
    "city": "Änew",
    "city_ascii": "Anew",
    "lat": 37.8875,
    "lng": 58.516,
    "country": "Turkmenistan",
    "iso2": "TM",
    "iso3": "TKM",
    "admin_name": "Ahal",
    "capital": "admin",
    "population": null,
    "id": 1795817680
  },
  {
    "city": "Masunga",
    "city_ascii": "Masunga",
    "lat": -20.6245,
    "lng": 27.4488,
    "country": "Botswana",
    "iso2": "BW",
    "iso3": "BWA",
    "admin_name": "North East",
    "capital": "admin",
    "population": null,
    "id": 1072609074
  },
  {
    "city": "Kostel",
    "city_ascii": "Kostel",
    "lat": 45.5084,
    "lng": 14.9101,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Kostel",
    "capital": "admin",
    "population": null,
    "id": 1705541759
  },
  {
    "city": "Xızı",
    "city_ascii": "Xizi",
    "lat": 40.9111,
    "lng": 49.0694,
    "country": "Azerbaijan",
    "iso2": "AZ",
    "iso3": "AZE",
    "admin_name": "Xızı",
    "capital": "admin",
    "population": null,
    "id": 1031409125
  },
  {
    "city": "Mūrmuiža",
    "city_ascii": "Murmuiza",
    "lat": 57.4731,
    "lng": 25.4917,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Beverīnas Novads",
    "capital": "admin",
    "population": null,
    "id": 1428958481
  },
  {
    "city": "Trongsa",
    "city_ascii": "Trongsa",
    "lat": 27.5168,
    "lng": 90.5,
    "country": "Bhutan",
    "iso2": "BT",
    "iso3": "BTN",
    "admin_name": "Trongsa",
    "capital": "admin",
    "population": null,
    "id": 1064630357
  },
  {
    "city": "Aglona",
    "city_ascii": "Aglona",
    "lat": 56.1327,
    "lng": 27.0068,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Aglonas Novads",
    "capital": "admin",
    "population": null,
    "id": 1428424524
  },
  {
    "city": "Sandur",
    "city_ascii": "Sandur",
    "lat": 61.8344,
    "lng": -6.8171,
    "country": "Faroe Islands",
    "iso2": "FO",
    "iso3": "FRO",
    "admin_name": "",
    "capital": "admin",
    "population": null,
    "id": 1234438421
  },
  {
    "city": "Nīca",
    "city_ascii": "Nica",
    "lat": 56.3456,
    "lng": 21.0646,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Nīcas Novads",
    "capital": "admin",
    "population": null,
    "id": 1428762389
  },
  {
    "city": "Vecumnieki",
    "city_ascii": "Vecumnieki",
    "lat": 56.6061,
    "lng": 24.5223,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Vecumnieku Novads",
    "capital": "admin",
    "population": null,
    "id": 1428888119
  },
  {
    "city": "El Fula",
    "city_ascii": "El Fula",
    "lat": 11.712,
    "lng": 28.3462,
    "country": "Sudan",
    "iso2": "SD",
    "iso3": "SDN",
    "admin_name": "Western Kordofan",
    "capital": "admin",
    "population": null,
    "id": 1729946051
  },
  {
    "city": "Zhemgang",
    "city_ascii": "Zhemgang",
    "lat": 27.2169,
    "lng": 90.6579,
    "country": "Bhutan",
    "iso2": "BT",
    "iso3": "BTN",
    "admin_name": "Zhemgang",
    "capital": "admin",
    "population": null,
    "id": 1064512651
  },
  {
    "city": "Porto Novo",
    "city_ascii": "Porto Novo",
    "lat": 17.0208,
    "lng": -25.0667,
    "country": "Cabo Verde",
    "iso2": "CV",
    "iso3": "CPV",
    "admin_name": "Porto Novo",
    "capital": "admin",
    "population": null,
    "id": 1132233840
  },
  {
    "city": "Damongo",
    "city_ascii": "Damongo",
    "lat": 9.083,
    "lng": -1.8188,
    "country": "Ghana",
    "iso2": "GH",
    "iso3": "GHA",
    "admin_name": "Northern",
    "capital": "admin",
    "population": null,
    "id": 1288827226
  },
  {
    "city": "Loja",
    "city_ascii": "Loja",
    "lat": 57.1534,
    "lng": 24.6433,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Sējas Novads",
    "capital": "admin",
    "population": null,
    "id": 1428831058
  },
  {
    "city": "Tanjung Selor",
    "city_ascii": "Tanjung Selor",
    "lat": 2.8375,
    "lng": 117.3653,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Kalimantan Utara",
    "capital": "admin",
    "population": null,
    "id": 1360029270
  },
  {
    "city": "Lieģi",
    "city_ascii": "Liegi",
    "lat": 56.5797,
    "lng": 21.3338,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Durbes Novads",
    "capital": "admin",
    "population": null,
    "id": 1428643853
  },
  {
    "city": "Jaunpiebalga",
    "city_ascii": "Jaunpiebalga",
    "lat": 57.1775,
    "lng": 26.0284,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Jaunpiebalgas Novads",
    "capital": "admin",
    "population": null,
    "id": 1428003359
  },
  {
    "city": "Belčišta",
    "city_ascii": "Belcista",
    "lat": 41.3028,
    "lng": 20.8303,
    "country": "Macedonia",
    "iso2": "MK",
    "iso3": "MKD",
    "admin_name": "Debarca",
    "capital": "admin",
    "population": null,
    "id": 1807953626
  },
  {
    "city": "Tigoa",
    "city_ascii": "Tigoa",
    "lat": -11.5531,
    "lng": 160.0647,
    "country": "Solomon Islands",
    "iso2": "SB",
    "iso3": "SLB",
    "admin_name": "Rennell and Bellona",
    "capital": "admin",
    "population": null,
    "id": 1090423471
  },
  {
    "city": "Naama",
    "city_ascii": "Naama",
    "lat": 33.2667,
    "lng": -0.3167,
    "country": "Algeria",
    "iso2": "DZ",
    "iso3": "DZA",
    "admin_name": "Naama",
    "capital": "admin",
    "population": null,
    "id": 1012850542
  },
  {
    "city": "Lupane",
    "city_ascii": "Lupane",
    "lat": -18.9315,
    "lng": 27.807,
    "country": "Zimbabwe",
    "iso2": "ZW",
    "iso3": "ZWE",
    "admin_name": "Matabeleland North",
    "capital": "admin",
    "population": null,
    "id": 1716206606
  },
  {
    "city": "Riebiņi",
    "city_ascii": "Riebini",
    "lat": 56.3403,
    "lng": 26.7999,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Riebiņu Novads",
    "capital": "admin",
    "population": null,
    "id": 1428663476
  },
  {
    "city": "Pārūn",
    "city_ascii": "Parun",
    "lat": 35.4206,
    "lng": 70.9226,
    "country": "Afghanistan",
    "iso2": "AF",
    "iso3": "AFG",
    "admin_name": "Nūristān",
    "capital": "admin",
    "population": null,
    "id": 1004795942
  },
  {
    "city": "Vaiņode",
    "city_ascii": "Vainode",
    "lat": 56.4185,
    "lng": 21.8541,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Vaiņodes Novads",
    "capital": "admin",
    "population": null,
    "id": 1428647067
  },
  {
    "city": "Staro Nagoričane",
    "city_ascii": "Staro Nagoricane",
    "lat": 42.1981,
    "lng": 21.8286,
    "country": "Macedonia",
    "iso2": "MK",
    "iso3": "MKD",
    "admin_name": "Staro Nagoričane",
    "capital": "admin",
    "population": null,
    "id": 1807782793
  },
  {
    "city": "Stalbe",
    "city_ascii": "Stalbe",
    "lat": 57.3708,
    "lng": 25.0324,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Pārgaujas Novads",
    "capital": "admin",
    "population": null,
    "id": 1428014091
  },
  {
    "city": "Ērgļi",
    "city_ascii": "Ergli",
    "lat": 56.8988,
    "lng": 25.6429,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Ērgļu Novads",
    "capital": "admin",
    "population": null,
    "id": 1428672513
  },
  {
    "city": "Kolašin",
    "city_ascii": "Kolasin",
    "lat": 42.825,
    "lng": 19.518,
    "country": "Montenegro",
    "iso2": "ME",
    "iso3": "MNE",
    "admin_name": "Kolašin",
    "capital": "admin",
    "population": null,
    "id": 1499083772
  },
  {
    "city": "Fish Town",
    "city_ascii": "Fish Town",
    "lat": 5.1974,
    "lng": -7.8758,
    "country": "Liberia",
    "iso2": "LR",
    "iso3": "LBR",
    "admin_name": "River Gee",
    "capital": "admin",
    "population": null,
    "id": 1430204172
  },
  {
    "city": "Bopolu",
    "city_ascii": "Bopolu",
    "lat": 7.0667,
    "lng": -10.4875,
    "country": "Liberia",
    "iso2": "LR",
    "iso3": "LBR",
    "admin_name": "Gbarpolu",
    "capital": "admin",
    "population": null,
    "id": 1430414456
  },
  {
    "city": "Vecpiebalga",
    "city_ascii": "Vecpiebalga",
    "lat": 57.0618,
    "lng": 25.8179,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Vecpiebalgas Novads",
    "capital": "admin",
    "population": null,
    "id": 1428633596
  },
  {
    "city": "Anouvông",
    "city_ascii": "Anouvong",
    "lat": 18.8989,
    "lng": 103.0919,
    "country": "Laos",
    "iso2": "LA",
    "iso3": "LAO",
    "admin_name": "Xaisômboun",
    "capital": "admin",
    "population": null,
    "id": 1418294155
  },
  {
    "city": "Loango",
    "city_ascii": "Loango",
    "lat": -4.6519,
    "lng": 11.8125,
    "country": "Congo (Brazzaville)",
    "iso2": "CG",
    "iso3": "COG",
    "admin_name": "Kouilou",
    "capital": "admin",
    "population": null,
    "id": 1178836764
  },
  {
    "city": "Drabeši",
    "city_ascii": "Drabesi",
    "lat": 57.2433,
    "lng": 25.2803,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Amatas Novads",
    "capital": "admin",
    "population": null,
    "id": 1428886151
  },
  {
    "city": "Alsunga",
    "city_ascii": "Alsunga",
    "lat": 56.9801,
    "lng": 21.564,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Alsungas Novads",
    "capital": "admin",
    "population": null,
    "id": 1428710208
  },
  {
    "city": "Laascaanood",
    "city_ascii": "Laascaanood",
    "lat": 8.4774,
    "lng": 47.3597,
    "country": "Somalia",
    "iso2": "SO",
    "iso3": "SOM",
    "admin_name": "Sool",
    "capital": "admin",
    "population": null,
    "id": 1706106404
  },
  {
    "city": "Vecvārkava",
    "city_ascii": "Vecvarkava",
    "lat": 56.1984,
    "lng": 26.5081,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Vārkavas Novads",
    "capital": "admin",
    "population": null,
    "id": 1428075811
  },
  {
    "city": "Navoiy",
    "city_ascii": "Navoiy",
    "lat": 40.1,
    "lng": 65.3667,
    "country": "Uzbekistan",
    "iso2": "UZ",
    "iso3": "UZB",
    "admin_name": "Navoiy",
    "capital": "admin",
    "population": null,
    "id": 1860291734
  },
  {
    "city": "Naukšēni",
    "city_ascii": "Naukseni",
    "lat": 57.8842,
    "lng": 25.458,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Naukšēnu Novads",
    "capital": "admin",
    "population": null,
    "id": 1428372673
  },
  {
    "city": "Fámjin",
    "city_ascii": "Famjin",
    "lat": 61.5248,
    "lng": -6.8794,
    "country": "Faroe Islands",
    "iso2": "FO",
    "iso3": "FRO",
    "admin_name": "",
    "capital": "admin",
    "population": null,
    "id": 1234221310
  },
  {
    "city": "Húsavík",
    "city_ascii": "Husavik",
    "lat": 61.8099,
    "lng": -6.6813,
    "country": "Faroe Islands",
    "iso2": "FO",
    "iso3": "FRO",
    "admin_name": "",
    "capital": "admin",
    "population": null,
    "id": 1234224518
  },
  {
    "city": "Baltinava",
    "city_ascii": "Baltinava",
    "lat": 56.9439,
    "lng": 27.644,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Baltinavas Novads",
    "capital": "admin",
    "population": null,
    "id": 1428769707
  },
  {
    "city": "Žabljak",
    "city_ascii": "Zabljak",
    "lat": 43.1547,
    "lng": 19.1219,
    "country": "Montenegro",
    "iso2": "ME",
    "iso3": "MNE",
    "admin_name": "Žabljak",
    "capital": "admin",
    "population": null,
    "id": 1499125825
  },
  {
    "city": "Dundaga",
    "city_ascii": "Dundaga",
    "lat": 57.505,
    "lng": 22.3504,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Dundagas Novads",
    "capital": "admin",
    "population": null,
    "id": 1428711022
  },
  {
    "city": "Georgetown",
    "city_ascii": "Georgetown",
    "lat": -7.9286,
    "lng": -14.4119,
    "country": "Saint Helena, Ascension, And Tristan Da Cunha",
    "iso2": "SH",
    "iso3": "SHN",
    "admin_name": "Ascension",
    "capital": "admin",
    "population": null,
    "id": 1654363572
  },
  {
    "city": "Nereta",
    "city_ascii": "Nereta",
    "lat": 56.2044,
    "lng": 25.3077,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Neretas Novads",
    "capital": "admin",
    "population": null,
    "id": 1428242925
  },
  {
    "city": "Cibla",
    "city_ascii": "Cibla",
    "lat": 56.5498,
    "lng": 27.8837,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Ciblas Novads",
    "capital": "admin",
    "population": null,
    "id": 1428931914
  },
  {
    "city": "Amdjarass",
    "city_ascii": "Amdjarass",
    "lat": 16.0667,
    "lng": 22.8354,
    "country": "Chad",
    "iso2": "TD",
    "iso3": "TCD",
    "admin_name": "Ennedi-Est",
    "capital": "admin",
    "population": null,
    "id": 1148268495
  },
  {
    "city": "Lhuentse",
    "city_ascii": "Lhuentse",
    "lat": 27.6679,
    "lng": 91.1839,
    "country": "Bhutan",
    "iso2": "BT",
    "iso3": "BTN",
    "admin_name": "Lhuentse",
    "capital": "admin",
    "population": null,
    "id": 1064227529
  },
  {
    "city": "Jakar",
    "city_ascii": "Jakar",
    "lat": 27.5492,
    "lng": 90.7525,
    "country": "Bhutan",
    "iso2": "BT",
    "iso3": "BTN",
    "admin_name": "Bumthang",
    "capital": "admin",
    "population": null,
    "id": 1064194738
  },
  {
    "city": "Nova Varoš",
    "city_ascii": "Nova Varos",
    "lat": 43.4667,
    "lng": 19.8203,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Nova Varoš",
    "capital": "admin",
    "population": null,
    "id": 1688051556
  },
  {
    "city": "Ceerigaabo",
    "city_ascii": "Ceerigaabo",
    "lat": 10.6162,
    "lng": 47.3679,
    "country": "Somalia",
    "iso2": "SO",
    "iso3": "SOM",
    "admin_name": "Sanaag",
    "capital": "admin",
    "population": null,
    "id": 1706173875
  },
  {
    "city": "Skálavík",
    "city_ascii": "Skalavik",
    "lat": 61.8314,
    "lng": -6.6623,
    "country": "Faroe Islands",
    "iso2": "FO",
    "iso3": "FRO",
    "admin_name": "",
    "capital": "admin",
    "population": null,
    "id": 1234815917
  },
  {
    "city": "Rugāji",
    "city_ascii": "Rugaji",
    "lat": 57.0033,
    "lng": 27.1337,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Rugāju Novads",
    "capital": "admin",
    "population": null,
    "id": 1428746884
  },
  {
    "city": "Kunoy",
    "city_ascii": "Kunoy",
    "lat": 62.2917,
    "lng": -6.6702,
    "country": "Faroe Islands",
    "iso2": "FO",
    "iso3": "FRO",
    "admin_name": "",
    "capital": "admin",
    "population": null,
    "id": 1234932703
  },
  {
    "city": "Daga",
    "city_ascii": "Daga",
    "lat": 27.0753,
    "lng": 89.8769,
    "country": "Bhutan",
    "iso2": "BT",
    "iso3": "BTN",
    "admin_name": "Dagana",
    "capital": "admin",
    "population": null,
    "id": 1064785735
  },
  {
    "city": "Bela Palanka",
    "city_ascii": "Bela Palanka",
    "lat": 43.2178,
    "lng": 22.3067,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Bela Palanka",
    "capital": "admin",
    "population": null,
    "id": 1688276422
  },
  {
    "city": "Novaci",
    "city_ascii": "Novaci",
    "lat": 41.042,
    "lng": 21.4587,
    "country": "Macedonia",
    "iso2": "MK",
    "iso3": "MKD",
    "admin_name": "Novaci",
    "capital": "admin",
    "population": null,
    "id": 1807936735
  },
  {
    "city": "Kobarid",
    "city_ascii": "Kobarid",
    "lat": 46.2471,
    "lng": 13.5796,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Kobarid",
    "capital": "admin",
    "population": 4.472,
    "id": 1705651680
  },
  {
    "city": "Rucava",
    "city_ascii": "Rucava",
    "lat": 56.164,
    "lng": 21.1627,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Rucavas Novads",
    "capital": "admin",
    "population": null,
    "id": 1428020890
  },
  {
    "city": "Kirkja",
    "city_ascii": "Kirkja",
    "lat": 62.3263,
    "lng": -6.3238,
    "country": "Faroe Islands",
    "iso2": "FO",
    "iso3": "FRO",
    "admin_name": "",
    "capital": "admin",
    "population": null,
    "id": 1234508245
  },
  {
    "city": "Sowa Town",
    "city_ascii": "Sowa Town",
    "lat": -20.5636,
    "lng": 26.2244,
    "country": "Botswana",
    "iso2": "BW",
    "iso3": "BWA",
    "admin_name": "Sowa Town",
    "capital": "admin",
    "population": null,
    "id": 1072487689
  },
  {
    "city": "Zgornje Jezersko",
    "city_ascii": "Zgornje Jezersko",
    "lat": 46.3833,
    "lng": 14.4667,
    "country": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "admin_name": "Jezersko",
    "capital": "admin",
    "population": null,
    "id": 1705991236
  },
  {
    "city": "Šavnik",
    "city_ascii": "Savnik",
    "lat": 42.9564,
    "lng": 19.0967,
    "country": "Montenegro",
    "iso2": "ME",
    "iso3": "MNE",
    "admin_name": "Šavnik",
    "capital": "admin",
    "population": null,
    "id": 1499140506
  },
  {
    "city": "Kidal",
    "city_ascii": "Kidal",
    "lat": 18.4411,
    "lng": 1.4078,
    "country": "Mali",
    "iso2": "ML",
    "iso3": "MLI",
    "admin_name": "Kidal",
    "capital": "admin",
    "population": null,
    "id": 1466444510
  },
  {
    "city": "Crna Trava",
    "city_ascii": "Crna Trava",
    "lat": 42.8071,
    "lng": 22.3247,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Crna Trava",
    "capital": "admin",
    "population": null,
    "id": 1688208441
  },
  {
    "city": "Skúvoy",
    "city_ascii": "Skuvoy",
    "lat": 61.771,
    "lng": -6.805,
    "country": "Faroe Islands",
    "iso2": "FO",
    "iso3": "FRO",
    "admin_name": "",
    "capital": "admin",
    "population": null,
    "id": 1234962897
  },
  {
    "city": "Plužine",
    "city_ascii": "Pluzine",
    "lat": 43.1528,
    "lng": 18.8394,
    "country": "Montenegro",
    "iso2": "ME",
    "iso3": "MNE",
    "admin_name": "Plužine",
    "capital": "admin",
    "population": null,
    "id": 1499719205
  },
  {
    "city": "Choyr",
    "city_ascii": "Choyr",
    "lat": 46.3611,
    "lng": 108.3611,
    "country": "Mongolia",
    "iso2": "MN",
    "iso3": "MNG",
    "admin_name": "Govĭsümber",
    "capital": "admin",
    "population": null,
    "id": 1496514583
  },
  {
    "city": "Edinburgh of the Seven Seas",
    "city_ascii": "Edinburgh of the Seven Seas",
    "lat": -37.0675,
    "lng": -12.3105,
    "country": "Saint Helena, Ascension, And Tristan Da Cunha",
    "iso2": "SH",
    "iso3": "SHN",
    "admin_name": "Tristan da Cunha",
    "capital": "admin",
    "population": null,
    "id": 1654661876
  },
  {
    "city": "Awbārī",
    "city_ascii": "Awbari",
    "lat": 26.5833,
    "lng": 12.7667,
    "country": "Libya",
    "iso2": "LY",
    "iso3": "LBY",
    "admin_name": "Wādī al Ḩayāt",
    "capital": "admin",
    "population": null,
    "id": 1434431378
  },
  {
    "city": "Gasa",
    "city_ascii": "Gasa",
    "lat": 27.9067,
    "lng": 89.7304,
    "country": "Bhutan",
    "iso2": "BT",
    "iso3": "BTN",
    "admin_name": "Gasa",
    "capital": "admin",
    "population": null,
    "id": 1064761583
  },
  {
    "city": "Idrī",
    "city_ascii": "Idri",
    "lat": 27.4471,
    "lng": 13.0517,
    "country": "Libya",
    "iso2": "LY",
    "iso3": "LBY",
    "admin_name": "Wādī ash Shāţi’",
    "capital": "admin",
    "population": null,
    "id": 1434443323
  },
  {
    "city": "Jwaneng",
    "city_ascii": "Jwaneng",
    "lat": -24.6017,
    "lng": 24.7281,
    "country": "Botswana",
    "iso2": "BW",
    "iso3": "BWA",
    "admin_name": "Jwaneng",
    "capital": "admin",
    "population": null,
    "id": 1072118187
  },
  {
    "city": "Mahdia",
    "city_ascii": "Mahdia",
    "lat": 5.2667,
    "lng": -59.15,
    "country": "Guyana",
    "iso2": "GY",
    "iso3": "GUY",
    "admin_name": "Potaro-Siparuni",
    "capital": "admin",
    "population": null,
    "id": 1328358422
  },
  {
    "city": "Haymā’",
    "city_ascii": "Hayma'",
    "lat": 19.9333,
    "lng": 56.3167,
    "country": "Oman",
    "iso2": "OM",
    "iso3": "OMN",
    "admin_name": "Al Wusţá",
    "capital": "admin",
    "population": null,
    "id": 1512927824
  },
  {
    "city": "Unión Chocó",
    "city_ascii": "Union Choco",
    "lat": 8.0778,
    "lng": -77.5583,
    "country": "Panama",
    "iso2": "PA",
    "iso3": "PAN",
    "admin_name": "Emberá-Wounaan",
    "capital": "admin",
    "population": null,
    "id": 1591671914
  },
  {
    "city": "Bardaï",
    "city_ascii": "Bardai",
    "lat": 21.3547,
    "lng": 17.0016,
    "country": "Chad",
    "iso2": "TD",
    "iso3": "TCD",
    "admin_name": "Tibesti",
    "capital": "admin",
    "population": null,
    "id": 1148071351
  },
  {
    "city": "Al Kufrah",
    "city_ascii": "Al Kufrah",
    "lat": 24.1667,
    "lng": 23.2696,
    "country": "Libya",
    "iso2": "LY",
    "iso3": "LBY",
    "admin_name": "Al Kufrah",
    "capital": "admin",
    "population": null,
    "id": 1434510625
  },
  {
    "city": "Star Dojran",
    "city_ascii": "Star Dojran",
    "lat": 41.1792,
    "lng": 22.7247,
    "country": "Macedonia",
    "iso2": "MK",
    "iso3": "MKD",
    "admin_name": "Dojran",
    "capital": "admin",
    "population": null,
    "id": 1807304153
  },
  {
    "city": "Wé",
    "city_ascii": "We",
    "lat": -20.9,
    "lng": 167.2667,
    "country": "New Caledonia",
    "iso2": "NC",
    "iso3": "NCL",
    "admin_name": "Province Îles",
    "capital": "admin",
    "population": null,
    "id": 1540672599
  },
  {
    "city": "Preševo",
    "city_ascii": "Presevo",
    "lat": 42.3067,
    "lng": 21.65,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Preševo",
    "capital": "admin",
    "population": null,
    "id": 1688970219
  },
  {
    "city": "Bujanovac",
    "city_ascii": "Bujanovac",
    "lat": 42.4667,
    "lng": 21.7667,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Bujanovac",
    "capital": "admin",
    "population": null,
    "id": 1688743477
  },
  {
    "city": "Kitamilo",
    "city_ascii": "Kitamilo",
    "lat": 0.2222,
    "lng": 33.2061,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Buvuma",
    "capital": "admin",
    "population": null,
    "id": 1800610172
  },
  {
    "city": "Tarrafal",
    "city_ascii": "Tarrafal",
    "lat": 15.2833,
    "lng": -23.7667,
    "country": "Cabo Verde",
    "iso2": "CV",
    "iso3": "CPV",
    "admin_name": "Tarrafal",
    "capital": "admin",
    "population": null,
    "id": 1132834308
  },
  {
    "city": "Udine",
    "city_ascii": "Udine",
    "lat": 46.0667,
    "lng": 13.2333,
    "country": "Italy",
    "iso2": "IT",
    "iso3": "ITA",
    "admin_name": "Friuli-Venezia Giulia",
    "capital": "minor",
    "population": 99341,
    "id": 1380396446
  },
  {
    "city": "Debre Zeyit",
    "city_ascii": "Debre Zeyit",
    "lat": 8.75,
    "lng": 38.9833,
    "country": "Ethiopia",
    "iso2": "ET",
    "iso3": "ETH",
    "admin_name": "Oromīya",
    "capital": "",
    "population": 99928,
    "id": 1231675489
  },
  {
    "city": "Tayabas",
    "city_ascii": "Tayabas",
    "lat": 14.0167,
    "lng": 121.5833,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Quezon",
    "capital": "",
    "population": 99779,
    "id": 1608195528
  },
  {
    "city": "Arlit",
    "city_ascii": "Arlit",
    "lat": 18.82,
    "lng": 7.33,
    "country": "Niger",
    "iso2": "NE",
    "iso3": "NER",
    "admin_name": "Agadez",
    "capital": "minor",
    "population": 100000,
    "id": 1562695011
  },
  {
    "city": "Urgut Shahri",
    "city_ascii": "Urgut Shahri",
    "lat": 39.4007,
    "lng": 67.2607,
    "country": "Uzbekistan",
    "iso2": "UZ",
    "iso3": "UZB",
    "admin_name": "Samarqand",
    "capital": "minor",
    "population": 99675,
    "id": 1860562788
  },
  {
    "city": "Boca Raton",
    "city_ascii": "Boca Raton",
    "lat": 26.3752,
    "lng": -80.108,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 99805,
    "id": 1840014207
  },
  {
    "city": "Bendigo",
    "city_ascii": "Bendigo",
    "lat": -36.75,
    "lng": 144.2667,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Victoria",
    "capital": "",
    "population": 99122,
    "id": 1036537913
  },
  {
    "city": "Lafayette",
    "city_ascii": "Lafayette",
    "lat": 39.9949,
    "lng": -105.0997,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 99645,
    "id": 1840020180
  },
  {
    "city": "Leme",
    "city_ascii": "Leme",
    "lat": -22.1858,
    "lng": -47.39,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 99388,
    "id": 1076542731
  },
  {
    "city": "Zelënodol’sk",
    "city_ascii": "Zelenodol'sk",
    "lat": 55.85,
    "lng": 48.5167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Tatarstan",
    "capital": "",
    "population": 99491,
    "id": 1643223486
  },
  {
    "city": "Hasilpur",
    "city_ascii": "Hasilpur",
    "lat": 29.6967,
    "lng": 72.5542,
    "country": "Pakistan",
    "iso2": "PK",
    "iso3": "PAK",
    "admin_name": "Punjab",
    "capital": "",
    "population": 99171,
    "id": 1586677874
  },
  {
    "city": "Wichita Falls",
    "city_ascii": "Wichita Falls",
    "lat": 33.9072,
    "lng": -98.5291,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 99557,
    "id": 1840021923
  },
  {
    "city": "Tobolsk",
    "city_ascii": "Tobolsk",
    "lat": 58.1953,
    "lng": 68.2581,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Tyumenskaya Oblast’",
    "capital": "",
    "population": 98886,
    "id": 1643201261
  },
  {
    "city": "Rio Rancho",
    "city_ascii": "Rio Rancho",
    "lat": 35.2872,
    "lng": -106.6981,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Mexico",
    "capital": "",
    "population": 99178,
    "id": 1840020387
  },
  {
    "city": "South Fulton",
    "city_ascii": "South Fulton",
    "lat": 33.6269,
    "lng": -84.5802,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 99155,
    "id": 1840029465
  },
  {
    "city": "Beaverton",
    "city_ascii": "Beaverton",
    "lat": 45.4779,
    "lng": -122.8168,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 99037,
    "id": 1840018556
  },
  {
    "city": "Sarapul",
    "city_ascii": "Sarapul",
    "lat": 56.4667,
    "lng": 53.8,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Udmurtiya",
    "capital": "",
    "population": 97910,
    "id": 1643320672
  },
  {
    "city": "Taxco de Alarcón",
    "city_ascii": "Taxco de Alarcon",
    "lat": 18.5564,
    "lng": -99.605,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Guerrero",
    "capital": "minor",
    "population": 98854,
    "id": 1484987082
  },
  {
    "city": "Tacurong",
    "city_ascii": "Tacurong",
    "lat": 6.6833,
    "lng": 124.6667,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Sultan Kudarat",
    "capital": "",
    "population": 98316,
    "id": 1608479806
  },
  {
    "city": "Kontagora",
    "city_ascii": "Kontagora",
    "lat": 10.4004,
    "lng": 5.4699,
    "country": "Nigeria",
    "iso2": "NG",
    "iso3": "NGA",
    "admin_name": "Niger",
    "capital": "minor",
    "population": 98754,
    "id": 1566599231
  },
  {
    "city": "Saku",
    "city_ascii": "Saku",
    "lat": 36.2489,
    "lng": 138.4769,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Nagano",
    "capital": "",
    "population": 98609,
    "id": 1392305904
  },
  {
    "city": "Khambhāt",
    "city_ascii": "Khambhat",
    "lat": 22.3131,
    "lng": 72.6194,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Gujarāt",
    "capital": "",
    "population": 98700,
    "id": 1356354348
  },
  {
    "city": "Lawrence",
    "city_ascii": "Lawrence",
    "lat": 38.9597,
    "lng": -95.2641,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kansas",
    "capital": "",
    "population": 98648,
    "id": 1840001632
  },
  {
    "city": "Zárate",
    "city_ascii": "Zarate",
    "lat": -34.0833,
    "lng": -59.0333,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires",
    "capital": "minor",
    "population": 98522,
    "id": 1032392383
  },
  {
    "city": "Reyhanlı",
    "city_ascii": "Reyhanli",
    "lat": 36.2692,
    "lng": 36.5672,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Hatay",
    "capital": "minor",
    "population": 98534,
    "id": 1792253206
  },
  {
    "city": "Lugo",
    "city_ascii": "Lugo",
    "lat": 43.0117,
    "lng": -7.5572,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Galicia",
    "capital": "minor",
    "population": 98276,
    "id": 1724547526
  },
  {
    "city": "Dongducheon",
    "city_ascii": "Dongducheon",
    "lat": 37.9133,
    "lng": 127.0633,
    "country": "Korea, South",
    "iso2": "KR",
    "iso3": "KOR",
    "admin_name": "Gyeonggi",
    "capital": "",
    "population": 97424,
    "id": 1410519938
  },
  {
    "city": "Worcester",
    "city_ascii": "Worcester",
    "lat": -33.645,
    "lng": 19.4436,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "Western Cape",
    "capital": "",
    "population": 97098,
    "id": 1710498245
  },
  {
    "city": "Votkinsk",
    "city_ascii": "Votkinsk",
    "lat": 57.05,
    "lng": 54,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Udmurtiya",
    "capital": "",
    "population": 97550,
    "id": 1643007991
  },
  {
    "city": "Paulínia",
    "city_ascii": "Paulinia",
    "lat": -22.7611,
    "lng": -47.1542,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 97702,
    "id": 1076327352
  },
  {
    "city": "Iseyin",
    "city_ascii": "Iseyin",
    "lat": 7.9667,
    "lng": 3.6,
    "country": "Nigeria",
    "iso2": "NG",
    "iso3": "NGA",
    "admin_name": "Oyo",
    "capital": "minor",
    "population": 98071,
    "id": 1566714777
  },
  {
    "city": "Iida",
    "city_ascii": "Iida",
    "lat": 35.5147,
    "lng": 137.8219,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Nagano",
    "capital": "",
    "population": 98129,
    "id": 1392226730
  },
  {
    "city": "Oldham",
    "city_ascii": "Oldham",
    "lat": 53.5444,
    "lng": -2.1169,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Oldham",
    "capital": "",
    "population": 96555,
    "id": 1826716070
  },
  {
    "city": "Witten",
    "city_ascii": "Witten",
    "lat": 51.4333,
    "lng": 7.3333,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 96563,
    "id": 1276004960
  },
  {
    "city": "Serov",
    "city_ascii": "Serov",
    "lat": 59.6,
    "lng": 60.5667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sverdlovskaya Oblast’",
    "capital": "",
    "population": 97762,
    "id": 1643278883
  },
  {
    "city": "Arapongas",
    "city_ascii": "Arapongas",
    "lat": -23.41,
    "lng": -51.43,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Paraná",
    "capital": "",
    "population": 97512,
    "id": 1076623771
  },
  {
    "city": "Middletown",
    "city_ascii": "Middletown",
    "lat": 39.5032,
    "lng": -84.366,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 97730,
    "id": 1840003804
  },
  {
    "city": "Fukata",
    "city_ascii": "Fukata",
    "lat": 33.8056,
    "lng": 130.5406,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Fukuoka",
    "capital": "",
    "population": 96886,
    "id": 1392000279
  },
  {
    "city": "Tubarão",
    "city_ascii": "Tubarao",
    "lat": -28.4669,
    "lng": -49.0069,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Santa Catarina",
    "capital": "",
    "population": 97235,
    "id": 1076663243
  },
  {
    "city": "Bafra",
    "city_ascii": "Bafra",
    "lat": 41.5682,
    "lng": 35.9069,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Samsun",
    "capital": "minor",
    "population": 97452,
    "id": 1792560571
  },
  {
    "city": "Brantford",
    "city_ascii": "Brantford",
    "lat": 43.1667,
    "lng": -80.25,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 97496,
    "id": 1124737006
  },
  {
    "city": "Yacuiba",
    "city_ascii": "Yacuiba",
    "lat": -22.0139,
    "lng": -63.6778,
    "country": "Bolivia",
    "iso2": "BO",
    "iso3": "BOL",
    "admin_name": "Tarija",
    "capital": "",
    "population": 97296,
    "id": 1068671079
  },
  {
    "city": "Hanau",
    "city_ascii": "Hanau",
    "lat": 50.1328,
    "lng": 8.9169,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "minor",
    "population": 96023,
    "id": 1276550409
  },
  {
    "city": "Ituiutaba",
    "city_ascii": "Ituiutaba",
    "lat": -19.0151,
    "lng": -49.5504,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Minas Gerais",
    "capital": "",
    "population": 97171,
    "id": 1076873111
  },
  {
    "city": "Ukhta",
    "city_ascii": "Ukhta",
    "lat": 63.5667,
    "lng": 53.7,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Komi",
    "capital": "",
    "population": 97087,
    "id": 1643005165
  },
  {
    "city": "Mezhdurechensk",
    "city_ascii": "Mezhdurechensk",
    "lat": 53.6833,
    "lng": 88.05,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kemerovskaya Oblast’",
    "capital": "",
    "population": 97060,
    "id": 1643201199
  },
  {
    "city": "Tomohon",
    "city_ascii": "Tomohon",
    "lat": 1.3244,
    "lng": 124.8225,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Sulawesi Utara",
    "capital": "minor",
    "population": 96411,
    "id": 1360014714
  },
  {
    "city": "Sutton Coldfield",
    "city_ascii": "Sutton Coldfield",
    "lat": 52.563,
    "lng": -1.822,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Birmingham",
    "capital": "",
    "population": 95107,
    "id": 1826034374
  },
  {
    "city": "Kāzerūn",
    "city_ascii": "Kazerun",
    "lat": 29.6167,
    "lng": 51.65,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Fārs",
    "capital": "minor",
    "population": 96683,
    "id": 1364311345
  },
  {
    "city": "San Marcos",
    "city_ascii": "San Marcos",
    "lat": 33.135,
    "lng": -117.1744,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 96664,
    "id": 1840021991
  },
  {
    "city": "Wujiaqu",
    "city_ascii": "Wujiaqu",
    "lat": 44.2,
    "lng": 87.55,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Xinjiang",
    "capital": "",
    "population": 96436,
    "id": 1156881466
  },
  {
    "city": "Aurangābād",
    "city_ascii": "Aurangabad",
    "lat": 24.7704,
    "lng": 84.38,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Bihār",
    "capital": "",
    "population": 95929,
    "id": 1356920493
  },
  {
    "city": "Sandy",
    "city_ascii": "Sandy",
    "lat": 40.571,
    "lng": -111.8505,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Utah",
    "capital": "",
    "population": 96380,
    "id": 1840037422
  },
  {
    "city": "Vanderbijlpark",
    "city_ascii": "Vanderbijlpark",
    "lat": -26.6992,
    "lng": 27.8356,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "Gauteng",
    "capital": "",
    "population": 95840,
    "id": 1710733212
  },
  {
    "city": "Leninsk-Kuznetskiy",
    "city_ascii": "Leninsk-Kuznetskiy",
    "lat": 54.65,
    "lng": 86.1667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kemerovskaya Oblast’",
    "capital": "",
    "population": 96139,
    "id": 1643591516
  },
  {
    "city": "Kelo",
    "city_ascii": "Kelo",
    "lat": 9.3171,
    "lng": 15.8,
    "country": "Chad",
    "iso2": "TD",
    "iso3": "TCD",
    "admin_name": "Tandjilé",
    "capital": "",
    "population": 96224,
    "id": 1148149571
  },
  {
    "city": "Federal Way",
    "city_ascii": "Federal Way",
    "lat": 47.3091,
    "lng": -122.3358,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 96289,
    "id": 1840019826
  },
  {
    "city": "Dovzhansk",
    "city_ascii": "Dovzhansk",
    "lat": 48.0846,
    "lng": 39.6516,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Luhans’ka Oblast’",
    "capital": "minor",
    "population": 95153,
    "id": 1804768565
  },
  {
    "city": "Guihulñgan",
    "city_ascii": "Guihulngan",
    "lat": 10.1167,
    "lng": 123.2667,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Negros Oriental",
    "capital": "",
    "population": 95969,
    "id": 1608628412
  },
  {
    "city": "Greece",
    "city_ascii": "Greece",
    "lat": 43.2461,
    "lng": -77.6989,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 96197,
    "id": 1840058136
  },
  {
    "city": "Grudziądz",
    "city_ascii": "Grudziadz",
    "lat": 53.4925,
    "lng": 18.7761,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Kujawsko-Pomorskie",
    "capital": "minor",
    "population": 95964,
    "id": 1616391533
  },
  {
    "city": "Cáceres",
    "city_ascii": "Caceres",
    "lat": 39.4731,
    "lng": -6.3711,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Extremadura",
    "capital": "minor",
    "population": 96126,
    "id": 1724532670
  },
  {
    "city": "Muzaffarabad",
    "city_ascii": "Muzaffarabad",
    "lat": 34.37,
    "lng": 73.4711,
    "country": "Pakistan",
    "iso2": "PK",
    "iso3": "PAK",
    "admin_name": "Azad Kashmir",
    "capital": "",
    "population": 96000,
    "id": 1586389158
  },
  {
    "city": "Kamisu",
    "city_ascii": "Kamisu",
    "lat": 35.89,
    "lng": 140.6647,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Ibaraki",
    "capital": "",
    "population": 95370,
    "id": 1392634524
  },
  {
    "city": "Kanuma",
    "city_ascii": "Kanuma",
    "lat": 36.5672,
    "lng": 139.745,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Tochigi",
    "capital": "",
    "population": 95812,
    "id": 1392653831
  },
  {
    "city": "Mandeville",
    "city_ascii": "Mandeville",
    "lat": 30.3751,
    "lng": -90.0904,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 95968,
    "id": 1840015909
  },
  {
    "city": "Mons",
    "city_ascii": "Mons",
    "lat": 50.455,
    "lng": 3.952,
    "country": "Belgium",
    "iso2": "BE",
    "iso3": "BEL",
    "admin_name": "Wallonia",
    "capital": "minor",
    "population": 95299,
    "id": 1056836704
  },
  {
    "city": "Ōmura",
    "city_ascii": "Omura",
    "lat": 32.9,
    "lng": 129.9583,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Nagasaki",
    "capital": "",
    "population": 95062,
    "id": 1392185480
  },
  {
    "city": "Hesperia",
    "city_ascii": "Hesperia",
    "lat": 34.3975,
    "lng": -117.3147,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 95750,
    "id": 1840020404
  },
  {
    "city": "Emmiganūr",
    "city_ascii": "Emmiganur",
    "lat": 15.7333,
    "lng": 77.4833,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Andhra Pradesh",
    "capital": "",
    "population": 95149,
    "id": 1356089591
  },
  {
    "city": "Brockton",
    "city_ascii": "Brockton",
    "lat": 42.0821,
    "lng": -71.0242,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 95708,
    "id": 1840000467
  },
  {
    "city": "Aliağa",
    "city_ascii": "Aliaga",
    "lat": 38.7998,
    "lng": 26.972,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "İzmir",
    "capital": "minor",
    "population": 95392,
    "id": 1792034311
  },
  {
    "city": "Boli",
    "city_ascii": "Boli",
    "lat": 45.7564,
    "lng": 130.5759,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Heilongjiang",
    "capital": "minor",
    "population": 95260,
    "id": 1156933394
  },
  {
    "city": "Bogo",
    "city_ascii": "Bogo",
    "lat": 10.7361,
    "lng": 14.6108,
    "country": "Cameroon",
    "iso2": "CM",
    "iso3": "CMR",
    "admin_name": "Extrême-Nord",
    "capital": "",
    "population": 95230,
    "id": 1120371347
  },
  {
    "city": "Sarov",
    "city_ascii": "Sarov",
    "lat": 54.9333,
    "lng": 43.3167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Nizhegorodskaya Oblast’",
    "capital": "",
    "population": 95388,
    "id": 1643199056
  },
  {
    "city": "Kŭlob",
    "city_ascii": "Kulob",
    "lat": 37.9119,
    "lng": 69.7808,
    "country": "Tajikistan",
    "iso2": "TJ",
    "iso3": "TJK",
    "admin_name": "Khatlon",
    "capital": "minor",
    "population": 95000,
    "id": 1762798596
  },
  {
    "city": "Rubio",
    "city_ascii": "Rubio",
    "lat": 7.7,
    "lng": -72.35,
    "country": "Venezuela",
    "iso2": "VE",
    "iso3": "VEN",
    "admin_name": "Táchira",
    "capital": "minor",
    "population": 95041,
    "id": 1862078206
  },
  {
    "city": "Fishers",
    "city_ascii": "Fishers",
    "lat": 39.9589,
    "lng": -85.9661,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 95310,
    "id": 1840010496
  },
  {
    "city": "Lecce",
    "city_ascii": "Lecce",
    "lat": 40.352,
    "lng": 18.1691,
    "country": "Italy",
    "iso2": "IT",
    "iso3": "ITA",
    "admin_name": "Puglia",
    "capital": "minor",
    "population": 94892,
    "id": 1380057273
  },
  {
    "city": "Mianwali",
    "city_ascii": "Mianwali",
    "lat": 32.5853,
    "lng": 71.5436,
    "country": "Pakistan",
    "iso2": "PK",
    "iso3": "PAK",
    "admin_name": "Punjab",
    "capital": "minor",
    "population": 95007,
    "id": 1586006907
  },
  {
    "city": "Solikamsk",
    "city_ascii": "Solikamsk",
    "lat": 59.6333,
    "lng": 56.7667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Permskiy Kray",
    "capital": "",
    "population": 94628,
    "id": 1643137756
  },
  {
    "city": "Nāḩiyat al Karmah",
    "city_ascii": "Nahiyat al Karmah",
    "lat": 33.3997,
    "lng": 43.9089,
    "country": "Iraq",
    "iso2": "IQ",
    "iso3": "IRQ",
    "admin_name": "Al Anbār",
    "capital": "",
    "population": 95000,
    "id": 1368548065
  },
  {
    "city": "Bowling Green",
    "city_ascii": "Bowling Green",
    "lat": 36.9719,
    "lng": -86.4373,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kentucky",
    "capital": "",
    "population": 95135,
    "id": 1840013265
  },
  {
    "city": "Valongo",
    "city_ascii": "Valongo",
    "lat": 41.1833,
    "lng": -8.5,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Porto",
    "capital": "minor",
    "population": 93858,
    "id": 1620725806
  },
  {
    "city": "Avignon",
    "city_ascii": "Avignon",
    "lat": 43.95,
    "lng": 4.8075,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Provence-Alpes-Côte d’Azur",
    "capital": "minor",
    "population": 93671,
    "id": 1250645342
  },
  {
    "city": "Biu",
    "city_ascii": "Biu",
    "lat": 10.6204,
    "lng": 12.19,
    "country": "Nigeria",
    "iso2": "NG",
    "iso3": "NGA",
    "admin_name": "Borno",
    "capital": "minor",
    "population": 95005,
    "id": 1566675681
  },
  {
    "city": "San José del Cabo",
    "city_ascii": "San Jose del Cabo",
    "lat": 23.0614,
    "lng": -109.7081,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Baja California Sur",
    "capital": "minor",
    "population": 93069,
    "id": 1484157742
  },
  {
    "city": "Jaworzno",
    "city_ascii": "Jaworzno",
    "lat": 50.2,
    "lng": 19.275,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Śląskie",
    "capital": "minor",
    "population": 94305,
    "id": 1616134743
  },
  {
    "city": "Bislig",
    "city_ascii": "Bislig",
    "lat": 8.1833,
    "lng": 126.35,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Surigao del Sur",
    "capital": "",
    "population": 94535,
    "id": 1608693408
  },
  {
    "city": "Gera",
    "city_ascii": "Gera",
    "lat": 50.8782,
    "lng": 12.0824,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Thuringia",
    "capital": "minor",
    "population": 94152,
    "id": 1276548165
  },
  {
    "city": "Roswell",
    "city_ascii": "Roswell",
    "lat": 34.0391,
    "lng": -84.3513,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 94763,
    "id": 1840014741
  },
  {
    "city": "Menifee",
    "city_ascii": "Menifee",
    "lat": 33.6909,
    "lng": -117.1849,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 94756,
    "id": 1840022903
  },
  {
    "city": "Azumino",
    "city_ascii": "Azumino",
    "lat": 36.3028,
    "lng": 137.8997,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Nagano",
    "capital": "",
    "population": 94463,
    "id": 1392101705
  },
  {
    "city": "Plantation",
    "city_ascii": "Plantation",
    "lat": 26.126,
    "lng": -80.2617,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 94580,
    "id": 1840015142
  },
  {
    "city": "Dover",
    "city_ascii": "Dover",
    "lat": 43.1887,
    "lng": -70.8845,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Hampshire",
    "capital": "",
    "population": 94563,
    "id": 1840002808
  },
  {
    "city": "Escalante",
    "city_ascii": "Escalante",
    "lat": 10.8333,
    "lng": 123.5,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Negros Occidental",
    "capital": "",
    "population": 94070,
    "id": 1608433743
  },
  {
    "city": "Gatchina",
    "city_ascii": "Gatchina",
    "lat": 59.5667,
    "lng": 30.1333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Leningradskaya Oblast’",
    "capital": "",
    "population": 94447,
    "id": 1643318154
  },
  {
    "city": "Michurinsk",
    "city_ascii": "Michurinsk",
    "lat": 52.8922,
    "lng": 40.4928,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Tambovskaya Oblast’",
    "capital": "",
    "population": 93330,
    "id": 1643419559
  },
  {
    "city": "Lorca",
    "city_ascii": "Lorca",
    "lat": 37.6833,
    "lng": -1.7,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Murcia",
    "capital": "",
    "population": 94404,
    "id": 1724565149
  },
  {
    "city": "Santa Cruz Xoxocotlán",
    "city_ascii": "Santa Cruz Xoxocotlan",
    "lat": 17.0264,
    "lng": -96.7333,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Oaxaca",
    "capital": "",
    "population": 93188,
    "id": 1484706021
  },
  {
    "city": "Glazov",
    "city_ascii": "Glazov",
    "lat": 58.1333,
    "lng": 52.65,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Udmurtiya",
    "capital": "",
    "population": 93056,
    "id": 1643380964
  },
  {
    "city": "Portsmouth",
    "city_ascii": "Portsmouth",
    "lat": 36.8468,
    "lng": -76.354,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 94398,
    "id": 1840003872
  },
  {
    "city": "Chino",
    "city_ascii": "Chino",
    "lat": 33.9836,
    "lng": -117.6654,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 94371,
    "id": 1840019114
  },
  {
    "city": "Marmaris",
    "city_ascii": "Marmaris",
    "lat": 36.8564,
    "lng": 28.2711,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Muğla",
    "capital": "minor",
    "population": 94247,
    "id": 1792982553
  },
  {
    "city": "Cape Breton",
    "city_ascii": "Cape Breton",
    "lat": 46.1389,
    "lng": -60.1931,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Nova Scotia",
    "capital": "",
    "population": 94285,
    "id": 1124000383
  },
  {
    "city": "Kwekwe",
    "city_ascii": "Kwekwe",
    "lat": -18.9167,
    "lng": 29.9833,
    "country": "Zimbabwe",
    "iso2": "ZW",
    "iso3": "ZWE",
    "admin_name": "Midlands",
    "capital": "",
    "population": 93072,
    "id": 1716327793
  },
  {
    "city": "Hanamaki Onsen",
    "city_ascii": "Hanamaki Onsen",
    "lat": 39.3886,
    "lng": 141.1167,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Iwate",
    "capital": "",
    "population": 94007,
    "id": 1392087821
  },
  {
    "city": "Edmond",
    "city_ascii": "Edmond",
    "lat": 35.6689,
    "lng": -97.4159,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oklahoma",
    "capital": "",
    "population": 94054,
    "id": 1840020423
  },
  {
    "city": "Hanford",
    "city_ascii": "Hanford",
    "lat": 36.3274,
    "lng": -119.6549,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 94028,
    "id": 1840020382
  },
  {
    "city": "Dearborn",
    "city_ascii": "Dearborn",
    "lat": 42.3127,
    "lng": -83.2129,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 93932,
    "id": 1840003969
  },
  {
    "city": "Menderes",
    "city_ascii": "Menderes",
    "lat": 38.254,
    "lng": 27.134,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "İzmir",
    "capital": "minor",
    "population": 93796,
    "id": 1792033881
  },
  {
    "city": "Voskresensk",
    "city_ascii": "Voskresensk",
    "lat": 55.3167,
    "lng": 38.6833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Moskovskaya Oblast’",
    "capital": "",
    "population": 93565,
    "id": 1643351541
  },
  {
    "city": "Higashi-Matsuyama",
    "city_ascii": "Higashi-Matsuyama",
    "lat": 36.0422,
    "lng": 139.4,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Saitama",
    "capital": "",
    "population": 92302,
    "id": 1392365792
  },
  {
    "city": "Mikhaylovsk",
    "city_ascii": "Mikhaylovsk",
    "lat": 45.1283,
    "lng": 42.0256,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Stavropol’skiy Kray",
    "capital": "",
    "population": 93658,
    "id": 1643773075
  },
  {
    "city": "Épinal",
    "city_ascii": "Epinal",
    "lat": 48.1744,
    "lng": 6.4512,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Grand Est",
    "capital": "minor",
    "population": 93184,
    "id": 1250137888
  },
  {
    "city": "Livonia",
    "city_ascii": "Livonia",
    "lat": 42.3972,
    "lng": -83.3733,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 93665,
    "id": 1840003985
  },
  {
    "city": "H̱adera",
    "city_ascii": "Hadera",
    "lat": 32.45,
    "lng": 34.9167,
    "country": "Israel",
    "iso2": "IL",
    "iso3": "ISR",
    "admin_name": "Haifa",
    "capital": "",
    "population": 91707,
    "id": 1376761209
  },
  {
    "city": "Trelew",
    "city_ascii": "Trelew",
    "lat": -43.2533,
    "lng": -65.3094,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Chubut",
    "capital": "",
    "population": 93442,
    "id": 1032440034
  },
  {
    "city": "Iserlohn",
    "city_ascii": "Iserlohn",
    "lat": 51.3833,
    "lng": 7.6667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 92666,
    "id": 1276837267
  },
  {
    "city": "Coronel",
    "city_ascii": "Coronel",
    "lat": -37.03,
    "lng": -73.16,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Biobío",
    "capital": "",
    "population": 92940,
    "id": 1152234834
  },
  {
    "city": "Bafang",
    "city_ascii": "Bafang",
    "lat": 5.1704,
    "lng": 10.18,
    "country": "Cameroon",
    "iso2": "CM",
    "iso3": "CMR",
    "admin_name": "Ouest",
    "capital": "",
    "population": 93145,
    "id": 1120889812
  },
  {
    "city": "Teziutlan",
    "city_ascii": "Teziutlan",
    "lat": 19.8178,
    "lng": -97.3667,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Puebla",
    "capital": "",
    "population": 92170,
    "id": 1484709570
  },
  {
    "city": "Vineland",
    "city_ascii": "Vineland",
    "lat": 39.4653,
    "lng": -74.9981,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 93239,
    "id": 1840001551
  },
  {
    "city": "San Tan Valley",
    "city_ascii": "San Tan Valley",
    "lat": 33.1879,
    "lng": -111.5472,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 93230,
    "id": 1840027990
  },
  {
    "city": "Florence",
    "city_ascii": "Florence",
    "lat": 34.1781,
    "lng": -79.7877,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 93131,
    "id": 1840013643
  },
  {
    "city": "Portsmouth",
    "city_ascii": "Portsmouth",
    "lat": 43.058,
    "lng": -70.7826,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Hampshire",
    "capital": "",
    "population": 93069,
    "id": 1840002932
  },
  {
    "city": "Slidell",
    "city_ascii": "Slidell",
    "lat": 30.2881,
    "lng": -89.7826,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 93040,
    "id": 1840015911
  },
  {
    "city": "Wukari",
    "city_ascii": "Wukari",
    "lat": 7.8704,
    "lng": 9.78,
    "country": "Nigeria",
    "iso2": "NG",
    "iso3": "NGA",
    "admin_name": "Taraba",
    "capital": "minor",
    "population": 92933,
    "id": 1566025302
  },
  {
    "city": "Samālūţ",
    "city_ascii": "Samalut",
    "lat": 28.3,
    "lng": 30.7167,
    "country": "Egypt",
    "iso2": "EG",
    "iso3": "EGY",
    "admin_name": "Al Minyā",
    "capital": "",
    "population": 91475,
    "id": 1818180512
  },
  {
    "city": "Rafaela",
    "city_ascii": "Rafaela",
    "lat": -31.2667,
    "lng": -61.4833,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Santa Fe",
    "capital": "minor",
    "population": 92945,
    "id": 1032181625
  },
  {
    "city": "Velikiye Luki",
    "city_ascii": "Velikiye Luki",
    "lat": 56.35,
    "lng": 30.5167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Pskovskaya Oblast’",
    "capital": "",
    "population": 91435,
    "id": 1643622811
  },
  {
    "city": "Missoula",
    "city_ascii": "Missoula",
    "lat": 46.875,
    "lng": -114.0214,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Montana",
    "capital": "",
    "population": 92895,
    "id": 1840019840
  },
  {
    "city": "Hastings",
    "city_ascii": "Hastings",
    "lat": 50.85,
    "lng": 0.57,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "East Sussex",
    "capital": "",
    "population": 92855,
    "id": 1826499763
  },
  {
    "city": "Kilosa",
    "city_ascii": "Kilosa",
    "lat": -6.8396,
    "lng": 36.99,
    "country": "Tanzania",
    "iso2": "TZ",
    "iso3": "TZA",
    "admin_name": "Morogoro",
    "capital": "",
    "population": 91889,
    "id": 1834242035
  },
  {
    "city": "Auburn",
    "city_ascii": "Auburn",
    "lat": 32.6087,
    "lng": -85.4899,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 92777,
    "id": 1840013810
  },
  {
    "city": "Foumban",
    "city_ascii": "Foumban",
    "lat": 5.7167,
    "lng": 10.9167,
    "country": "Cameroon",
    "iso2": "CM",
    "iso3": "CMR",
    "admin_name": "Ouest",
    "capital": "",
    "population": 92673,
    "id": 1120088650
  },
  {
    "city": "Rāyachoti",
    "city_ascii": "Rayachoti",
    "lat": 14.05,
    "lng": 78.75,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Andhra Pradesh",
    "capital": "",
    "population": 91234,
    "id": 1356147853
  },
  {
    "city": "Naju",
    "city_ascii": "Naju",
    "lat": 35.0283,
    "lng": 126.7175,
    "country": "Korea, South",
    "iso2": "KR",
    "iso3": "KOR",
    "admin_name": "Jeonnam",
    "capital": "minor",
    "population": 92582,
    "id": 1410651571
  },
  {
    "city": "Sampit",
    "city_ascii": "Sampit",
    "lat": -2.5329,
    "lng": 112.95,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Kalimantan Tengah",
    "capital": "minor",
    "population": 92710,
    "id": 1360020231
  },
  {
    "city": "Lethbridge",
    "city_ascii": "Lethbridge",
    "lat": 49.6942,
    "lng": -112.8328,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Alberta",
    "capital": "",
    "population": 92729,
    "id": 1124321200
  },
  {
    "city": "Rapid City",
    "city_ascii": "Rapid City",
    "lat": 44.0716,
    "lng": -103.2204,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Dakota",
    "capital": "",
    "population": 92664,
    "id": 1840002180
  },
  {
    "city": "Kitakami",
    "city_ascii": "Kitakami",
    "lat": 39.2867,
    "lng": 141.1131,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Iwate",
    "capital": "",
    "population": 92447,
    "id": 1392905802
  },
  {
    "city": "Rongwo",
    "city_ascii": "Rongwo",
    "lat": 35.5165,
    "lng": 102.0166,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Qinghai",
    "capital": "minor",
    "population": 92601,
    "id": 1156068157
  },
  {
    "city": "Grahamstown",
    "city_ascii": "Grahamstown",
    "lat": -33.2996,
    "lng": 26.52,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "Eastern Cape",
    "capital": "",
    "population": 91548,
    "id": 1710671857
  },
  {
    "city": "Itaituba",
    "city_ascii": "Itaituba",
    "lat": -4.2761,
    "lng": -55.9836,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Pará",
    "capital": "",
    "population": 92308,
    "id": 1076849673
  },
  {
    "city": "Lee's Summit",
    "city_ascii": "Lee's Summit",
    "lat": 38.9172,
    "lng": -94.3816,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 92522,
    "id": 1840032155
  },
  {
    "city": "Terre Haute",
    "city_ascii": "Terre Haute",
    "lat": 39.4654,
    "lng": -87.3763,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 92490,
    "id": 1840009671
  },
  {
    "city": "Saint-Jean-sur-Richelieu",
    "city_ascii": "Saint-Jean-sur-Richelieu",
    "lat": 45.3167,
    "lng": -73.2667,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 92394,
    "id": 1124278447
  },
  {
    "city": "Suffolk",
    "city_ascii": "Suffolk",
    "lat": 36.6953,
    "lng": -76.6398,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 92108,
    "id": 1840003873
  },
  {
    "city": "Clarington",
    "city_ascii": "Clarington",
    "lat": 43.935,
    "lng": -78.6083,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 92013,
    "id": 1124000882
  },
  {
    "city": "Kendu Bay",
    "city_ascii": "Kendu Bay",
    "lat": -0.3596,
    "lng": 34.64,
    "country": "Kenya",
    "iso2": "KE",
    "iso3": "KEN",
    "admin_name": "Homa Bay",
    "capital": "",
    "population": 91248,
    "id": 1404600696
  },
  {
    "city": "Maladzyechna",
    "city_ascii": "Maladzyechna",
    "lat": 54.3136,
    "lng": 26.8517,
    "country": "Belarus",
    "iso2": "BY",
    "iso3": "BLR",
    "admin_name": "Minskaya Voblasts’",
    "capital": "minor",
    "population": 91900,
    "id": 1112822882
  },
  {
    "city": "Blacksburg",
    "city_ascii": "Blacksburg",
    "lat": 37.23,
    "lng": -80.4279,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 91888,
    "id": 1840006446
  },
  {
    "city": "Arezzo",
    "city_ascii": "Arezzo",
    "lat": 43.4617,
    "lng": 11.875,
    "country": "Italy",
    "iso2": "IT",
    "iso3": "ITA",
    "admin_name": "Tuscany",
    "capital": "minor",
    "population": 91589,
    "id": 1380418355
  },
  {
    "city": "Modi‘in Makkabbim Re‘ut",
    "city_ascii": "Modi`in Makkabbim Re`ut",
    "lat": 31.9339,
    "lng": 34.9856,
    "country": "Israel",
    "iso2": "IL",
    "iso3": "ISR",
    "admin_name": "Central",
    "capital": "",
    "population": 90013,
    "id": 1376334230
  },
  {
    "city": "Düren",
    "city_ascii": "Duren",
    "lat": 50.8,
    "lng": 6.4833,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "minor",
    "population": 90733,
    "id": 1276181957
  },
  {
    "city": "Mihara",
    "city_ascii": "Mihara",
    "lat": 34.4581,
    "lng": 133.0039,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hiroshima",
    "capital": "",
    "population": 91604,
    "id": 1392428941
  },
  {
    "city": "Pickering",
    "city_ascii": "Pickering",
    "lat": 43.8354,
    "lng": -79.089,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 91771,
    "id": 1124781814
  },
  {
    "city": "Flensburg",
    "city_ascii": "Flensburg",
    "lat": 54.7819,
    "lng": 9.4367,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Schleswig-Holstein",
    "capital": "minor",
    "population": 90164,
    "id": 1276853315
  },
  {
    "city": "Imizuchō",
    "city_ascii": "Imizucho",
    "lat": 36.7125,
    "lng": 137.0994,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Toyama",
    "capital": "",
    "population": 90877,
    "id": 1392059150
  },
  {
    "city": "Toms River",
    "city_ascii": "Toms River",
    "lat": 39.9895,
    "lng": -74.1654,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 91706,
    "id": 1840081605
  },
  {
    "city": "Mount Pleasant",
    "city_ascii": "Mount Pleasant",
    "lat": 32.8538,
    "lng": -79.8204,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 91684,
    "id": 1840017286
  },
  {
    "city": "Mogi Mirim",
    "city_ascii": "Mogi Mirim",
    "lat": -22.4319,
    "lng": -46.9578,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 91483,
    "id": 1076719683
  },
  {
    "city": "Yao",
    "city_ascii": "Yao",
    "lat": 12.8508,
    "lng": 17.5608,
    "country": "Chad",
    "iso2": "TD",
    "iso3": "TCD",
    "admin_name": "Batha",
    "capital": "",
    "population": 91507,
    "id": 1148655909
  },
  {
    "city": "Votuporanga",
    "city_ascii": "Votuporanga",
    "lat": -20.4228,
    "lng": -49.9728,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 91278,
    "id": 1076423091
  },
  {
    "city": "Caçapava",
    "city_ascii": "Cacapava",
    "lat": -23.1008,
    "lng": -45.7069,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 91162,
    "id": 1076289420
  },
  {
    "city": "Carson",
    "city_ascii": "Carson",
    "lat": 33.8374,
    "lng": -118.2559,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 91394,
    "id": 1840019214
  },
  {
    "city": "Tübingen",
    "city_ascii": "Tubingen",
    "lat": 48.52,
    "lng": 9.0556,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "minor",
    "population": 90546,
    "id": 1276203240
  },
  {
    "city": "Greenburgh",
    "city_ascii": "Greenburgh",
    "lat": 41.033,
    "lng": -73.8413,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 91355,
    "id": 1840087485
  },
  {
    "city": "Jalalpur Jattan",
    "city_ascii": "Jalalpur Jattan",
    "lat": 32.7667,
    "lng": 74.2167,
    "country": "Pakistan",
    "iso2": "PK",
    "iso3": "PAK",
    "admin_name": "Punjab",
    "capital": "",
    "population": 90130,
    "id": 1586822268
  },
  {
    "city": "Riverview",
    "city_ascii": "Riverview",
    "lat": 27.8227,
    "lng": -82.3023,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 91190,
    "id": 1840014158
  },
  {
    "city": "Drammen",
    "city_ascii": "Drammen",
    "lat": 59.7572,
    "lng": 10.1907,
    "country": "Norway",
    "iso2": "NO",
    "iso3": "NOR",
    "admin_name": "Buskerud",
    "capital": "minor",
    "population": 90722,
    "id": 1578937195
  },
  {
    "city": "Conroe",
    "city_ascii": "Conroe",
    "lat": 30.3239,
    "lng": -95.4825,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 91079,
    "id": 1840019586
  },
  {
    "city": "Chauk",
    "city_ascii": "Chauk",
    "lat": 20.9085,
    "lng": 94.823,
    "country": "Burma",
    "iso2": "MM",
    "iso3": "MMR",
    "admin_name": "Magway",
    "capital": "",
    "population": 90870,
    "id": 1104898616
  },
  {
    "city": "Livermore",
    "city_ascii": "Livermore",
    "lat": 37.6862,
    "lng": -121.7608,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 90923,
    "id": 1840020294
  },
  {
    "city": "Quillota",
    "city_ascii": "Quillota",
    "lat": -32.8799,
    "lng": -71.2474,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Valparaíso",
    "capital": "minor",
    "population": 90517,
    "id": 1152185579
  },
  {
    "city": "Lawton",
    "city_ascii": "Lawton",
    "lat": 34.6176,
    "lng": -98.4203,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oklahoma",
    "capital": "",
    "population": 90712,
    "id": 1840020477
  },
  {
    "city": "Chililabombwe",
    "city_ascii": "Chililabombwe",
    "lat": -12.3667,
    "lng": 27.8333,
    "country": "Zambia",
    "iso2": "ZM",
    "iso3": "ZMB",
    "admin_name": "Copperbelt",
    "capital": "",
    "population": 90530,
    "id": 1894950283
  },
  {
    "city": "Kāvali",
    "city_ascii": "Kavali",
    "lat": 14.9123,
    "lng": 79.9944,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Andhra Pradesh",
    "capital": "",
    "population": 90099,
    "id": 1356816305
  },
  {
    "city": "Nanaimo",
    "city_ascii": "Nanaimo",
    "lat": 49.1642,
    "lng": -123.9364,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "British Columbia",
    "capital": "",
    "population": 90504,
    "id": 1124623893
  },
  {
    "city": "Ma‘arrat an Nu‘mān",
    "city_ascii": "Ma`arrat an Nu`man",
    "lat": 35.6433,
    "lng": 36.6683,
    "country": "Syria",
    "iso2": "SY",
    "iso3": "SYR",
    "admin_name": "Idlib",
    "capital": "minor",
    "population": 90000,
    "id": 1760482977
  },
  {
    "city": "Mancherāl",
    "city_ascii": "Mancheral",
    "lat": 18.8679,
    "lng": 79.4639,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Telangana",
    "capital": "",
    "population": 89935,
    "id": 1356990383
  },
  {
    "city": "Southport",
    "city_ascii": "Southport",
    "lat": 53.6475,
    "lng": -3.0053,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Sefton",
    "capital": "",
    "population": 90381,
    "id": 1826271387
  },
  {
    "city": "Kansk",
    "city_ascii": "Kansk",
    "lat": 56.2,
    "lng": 95.7,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Krasnoyarskiy Kray",
    "capital": "",
    "population": 89508,
    "id": 1643874419
  },
  {
    "city": "Kiselëvsk",
    "city_ascii": "Kiselevsk",
    "lat": 53.9833,
    "lng": 86.7,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kemerovskaya Oblast’",
    "capital": "",
    "population": 89867,
    "id": 1643153158
  },
  {
    "city": "Zwickau",
    "city_ascii": "Zwickau",
    "lat": 50.7189,
    "lng": 12.4961,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "minor",
    "population": 89540,
    "id": 1276683623
  },
  {
    "city": "Hezuo",
    "city_ascii": "Hezuo",
    "lat": 34.9984,
    "lng": 102.91,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Gansu",
    "capital": "minor",
    "population": 90290,
    "id": 1156739933
  },
  {
    "city": "Alaminos",
    "city_ascii": "Alaminos",
    "lat": 16.1553,
    "lng": 119.9808,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Pangasinan",
    "capital": "",
    "population": 89708,
    "id": 1608828653
  },
  {
    "city": "Pisa",
    "city_ascii": "Pisa",
    "lat": 43.7167,
    "lng": 10.4,
    "country": "Italy",
    "iso2": "IT",
    "iso3": "ITA",
    "admin_name": "Tuscany",
    "capital": "minor",
    "population": 89745,
    "id": 1380310225
  },
  {
    "city": "New Braunfels",
    "city_ascii": "New Braunfels",
    "lat": 29.6994,
    "lng": -98.1148,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 90209,
    "id": 1840021341
  },
  {
    "city": "Eraclea",
    "city_ascii": "Eraclea",
    "lat": 45.6381,
    "lng": 12.6583,
    "country": "Italy",
    "iso2": "IT",
    "iso3": "ITA",
    "admin_name": "Veneto",
    "capital": "",
    "population": 90000,
    "id": 1380571098
  },
  {
    "city": "Tômbua",
    "city_ascii": "Tombua",
    "lat": -15.8,
    "lng": 11.85,
    "country": "Angola",
    "iso2": "AO",
    "iso3": "AGO",
    "admin_name": "Namibe",
    "capital": "",
    "population": 90000,
    "id": 1024489244
  },
  {
    "city": "Kadiri",
    "city_ascii": "Kadiri",
    "lat": 14.12,
    "lng": 78.17,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Andhra Pradesh",
    "capital": "",
    "population": 89429,
    "id": 1356308559
  },
  {
    "city": "Norrköping",
    "city_ascii": "Norrkoping",
    "lat": 58.5954,
    "lng": 16.1787,
    "country": "Sweden",
    "iso2": "SE",
    "iso3": "SWE",
    "admin_name": "Östergötland",
    "capital": "minor",
    "population": 88639,
    "id": 1752803583
  },
  {
    "city": "Gießen",
    "city_ascii": "Giessen",
    "lat": 50.5833,
    "lng": 8.6667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "minor",
    "population": 88546,
    "id": 1276576770
  },
  {
    "city": "Hosa’ina",
    "city_ascii": "Hosa'ina",
    "lat": 7.5504,
    "lng": 37.85,
    "country": "Ethiopia",
    "iso2": "ET",
    "iso3": "ETH",
    "admin_name": "YeDebub Bihēroch Bihēreseboch na Hizboch",
    "capital": "",
    "population": 89300,
    "id": 1231400775
  },
  {
    "city": "Serdar",
    "city_ascii": "Serdar",
    "lat": 38.9764,
    "lng": 56.2758,
    "country": "Turkmenistan",
    "iso2": "TM",
    "iso3": "TKM",
    "admin_name": "Balkan",
    "capital": "",
    "population": 89582,
    "id": 1795429041
  },
  {
    "city": "Cantaura",
    "city_ascii": "Cantaura",
    "lat": 9.3005,
    "lng": -64.3564,
    "country": "Venezuela",
    "iso2": "VE",
    "iso3": "VEN",
    "admin_name": "Anzoátegui",
    "capital": "minor",
    "population": 89552,
    "id": 1862715472
  },
  {
    "city": "Kamensk-Shakhtinskiy",
    "city_ascii": "Kamensk-Shakhtinskiy",
    "lat": 48.3167,
    "lng": 40.2667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Rostovskaya Oblast’",
    "capital": "minor",
    "population": 88997,
    "id": 1643408575
  },
  {
    "city": "Fall River",
    "city_ascii": "Fall River",
    "lat": 41.7137,
    "lng": -71.1014,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 89541,
    "id": 1840000497
  },
  {
    "city": "Grimsby",
    "city_ascii": "Grimsby",
    "lat": 53.5595,
    "lng": -0.068,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "North East Lincolnshire",
    "capital": "",
    "population": 88243,
    "id": 1826700880
  },
  {
    "city": "Chosica",
    "city_ascii": "Chosica",
    "lat": -11.9431,
    "lng": -76.7094,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "Lima",
    "capital": "",
    "population": 88606,
    "id": 1604040866
  },
  {
    "city": "Prijedor",
    "city_ascii": "Prijedor",
    "lat": 44.9667,
    "lng": 16.7,
    "country": "Bosnia And Herzegovina",
    "iso2": "BA",
    "iso3": "BIH",
    "admin_name": "Srpska, Republika",
    "capital": "minor",
    "population": 89397,
    "id": 1070565353
  },
  {
    "city": "Dunkerque",
    "city_ascii": "Dunkerque",
    "lat": 51.0383,
    "lng": 2.3775,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Hauts-de-France",
    "capital": "minor",
    "population": 87353,
    "id": 1250542305
  },
  {
    "city": "Gitarama",
    "city_ascii": "Gitarama",
    "lat": -2.0696,
    "lng": 29.76,
    "country": "Rwanda",
    "iso2": "RW",
    "iso3": "RWA",
    "admin_name": "Southern Province",
    "capital": "",
    "population": 87613,
    "id": 1646038255
  },
  {
    "city": "Takagi",
    "city_ascii": "Takagi",
    "lat": 36.1669,
    "lng": 136.2317,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Fukui",
    "capital": "",
    "population": 88795,
    "id": 1392167205
  },
  {
    "city": "Albany",
    "city_ascii": "Albany",
    "lat": 31.5776,
    "lng": -84.1762,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 89217,
    "id": 1840013864
  },
  {
    "city": "São João da Boa Vista",
    "city_ascii": "Sao Joao da Boa Vista",
    "lat": -21.9689,
    "lng": -46.7978,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 89027,
    "id": 1076459561
  },
  {
    "city": "Shwebo",
    "city_ascii": "Shwebo",
    "lat": 22.5783,
    "lng": 95.6929,
    "country": "Burma",
    "iso2": "MM",
    "iso3": "MMR",
    "admin_name": "Sagaing",
    "capital": "",
    "population": 88914,
    "id": 1104118052
  },
  {
    "city": "Halifax",
    "city_ascii": "Halifax",
    "lat": 53.725,
    "lng": -1.863,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Calderdale",
    "capital": "",
    "population": 88134,
    "id": 1826413776
  },
  {
    "city": "El Puerto de Santa María",
    "city_ascii": "El Puerto de Santa Maria",
    "lat": 36.6015,
    "lng": -6.2381,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Andalusia",
    "capital": "",
    "population": 88405,
    "id": 1724087455
  },
  {
    "city": "Bhakkar",
    "city_ascii": "Bhakkar",
    "lat": 31.6333,
    "lng": 71.0667,
    "country": "Pakistan",
    "iso2": "PK",
    "iso3": "PAK",
    "admin_name": "Punjab",
    "capital": "minor",
    "population": 88472,
    "id": 1586655125
  },
  {
    "city": "Norwalk",
    "city_ascii": "Norwalk",
    "lat": 41.1144,
    "lng": -73.4215,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 88816,
    "id": 1840004839
  },
  {
    "city": "O'Fallon",
    "city_ascii": "O'Fallon",
    "lat": 38.785,
    "lng": -90.7175,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 88673,
    "id": 1840008571
  },
  {
    "city": "Ootacamund",
    "city_ascii": "Ootacamund",
    "lat": 11.4086,
    "lng": 76.6939,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Tamil Nādu",
    "capital": "",
    "population": 88430,
    "id": 1356809681
  },
  {
    "city": "Victorias",
    "city_ascii": "Victorias",
    "lat": 10.9,
    "lng": 123.0833,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Negros Occidental",
    "capital": "",
    "population": 87933,
    "id": 1608236440
  },
  {
    "city": "Alafaya",
    "city_ascii": "Alafaya",
    "lat": 28.5278,
    "lng": -81.1865,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 88542,
    "id": 1840029102
  },
  {
    "city": "La Grita",
    "city_ascii": "La Grita",
    "lat": 8.1333,
    "lng": -71.9833,
    "country": "Venezuela",
    "iso2": "VE",
    "iso3": "VEN",
    "admin_name": "Táchira",
    "capital": "minor",
    "population": 88450,
    "id": 1862682610
  },
  {
    "city": "Launceston",
    "city_ascii": "Launceston",
    "lat": -41.4419,
    "lng": 147.145,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Tasmania",
    "capital": "",
    "population": 87328,
    "id": 1036170383
  },
  {
    "city": "Newton",
    "city_ascii": "Newton",
    "lat": 42.3316,
    "lng": -71.2085,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 88414,
    "id": 1840000433
  },
  {
    "city": "Zhob",
    "city_ascii": "Zhob",
    "lat": 31.3417,
    "lng": 69.4486,
    "country": "Pakistan",
    "iso2": "PK",
    "iso3": "PAK",
    "admin_name": "Balochistān",
    "capital": "minor",
    "population": 88356,
    "id": 1586969046
  },
  {
    "city": "Mobara",
    "city_ascii": "Mobara",
    "lat": 35.4283,
    "lng": 140.2881,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Chiba",
    "capital": "",
    "population": 87450,
    "id": 1392225442
  },
  {
    "city": "Qal‘at Bīshah",
    "city_ascii": "Qal`at Bishah",
    "lat": 20.0087,
    "lng": 42.5987,
    "country": "Saudi Arabia",
    "iso2": "SA",
    "iso3": "SAU",
    "admin_name": "‘Asīr",
    "capital": "",
    "population": 88291,
    "id": 1682202412
  },
  {
    "city": "Maiquetía",
    "city_ascii": "Maiquetia",
    "lat": 10.5958,
    "lng": -66.9772,
    "country": "Venezuela",
    "iso2": "VE",
    "iso3": "VEN",
    "admin_name": "Vargas",
    "capital": "minor",
    "population": 87909,
    "id": 1862822831
  },
  {
    "city": "Ratingen",
    "city_ascii": "Ratingen",
    "lat": 51.3,
    "lng": 6.85,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 87297,
    "id": 1276005433
  },
  {
    "city": "Sinjār",
    "city_ascii": "Sinjar",
    "lat": 36.3208,
    "lng": 41.8764,
    "country": "Iraq",
    "iso2": "IQ",
    "iso3": "IRQ",
    "admin_name": "Nīnawá",
    "capital": "minor",
    "population": 88023,
    "id": 1368057870
  },
  {
    "city": "Sudbury",
    "city_ascii": "Sudbury",
    "lat": 46.49,
    "lng": -81.01,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 88054,
    "id": 1124539524
  },
  {
    "city": "Buzuluk",
    "city_ascii": "Buzuluk",
    "lat": 52.7667,
    "lng": 52.2667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Orenburgskaya Oblast’",
    "capital": "",
    "population": 86316,
    "id": 1643877351
  },
  {
    "city": "Lünen",
    "city_ascii": "Lunen",
    "lat": 51.6167,
    "lng": 7.5167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 86449,
    "id": 1276959723
  },
  {
    "city": "Changxing",
    "city_ascii": "Changxing",
    "lat": 25.867,
    "lng": 116.3167,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Fujian",
    "capital": "",
    "population": 87458,
    "id": 1156238244
  },
  {
    "city": "Muncie",
    "city_ascii": "Muncie",
    "lat": 40.1989,
    "lng": -85.395,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 87879,
    "id": 1840008365
  },
  {
    "city": "Jagüey Grande",
    "city_ascii": "Jaguey Grande",
    "lat": 22.5292,
    "lng": -81.1325,
    "country": "Cuba",
    "iso2": "CU",
    "iso3": "CUB",
    "admin_name": "Matanzas",
    "capital": "minor",
    "population": 87771,
    "id": 1192977963
  },
  {
    "city": "Fukuroi",
    "city_ascii": "Fukuroi",
    "lat": 34.7503,
    "lng": 137.925,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Shizuoka",
    "capital": "",
    "population": 87064,
    "id": 1392865065
  },
  {
    "city": "Hamilton",
    "city_ascii": "Hamilton",
    "lat": 40.2046,
    "lng": -74.6765,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 87799,
    "id": 1840056346
  },
  {
    "city": "Ceylanpınar",
    "city_ascii": "Ceylanpinar",
    "lat": 36.9194,
    "lng": 39.905,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Şanlıurfa",
    "capital": "minor",
    "population": 87684,
    "id": 1792768664
  },
  {
    "city": "Pergamino",
    "city_ascii": "Pergamino",
    "lat": -33.8836,
    "lng": -60.5669,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires",
    "capital": "minor",
    "population": 87652,
    "id": 1032214150
  },
  {
    "city": "Anakāpalle",
    "city_ascii": "Anakapalle",
    "lat": 17.68,
    "lng": 83.02,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Andhra Pradesh",
    "capital": "",
    "population": 86519,
    "id": 1356285975
  },
  {
    "city": "Koidu-Bulma",
    "city_ascii": "Koidu-Bulma",
    "lat": 8.4405,
    "lng": -10.85,
    "country": "Sierra Leone",
    "iso2": "SL",
    "iso3": "SLE",
    "admin_name": "Eastern",
    "capital": "",
    "population": 87539,
    "id": 1694104584
  },
  {
    "city": "Gotenba",
    "city_ascii": "Gotenba",
    "lat": 35.3086,
    "lng": 138.935,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Shizuoka",
    "capital": "",
    "population": 87078,
    "id": 1392876311
  },
  {
    "city": "Paredes",
    "city_ascii": "Paredes",
    "lat": 41.2,
    "lng": -8.3333,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Porto",
    "capital": "minor",
    "population": 86854,
    "id": 1620650523
  },
  {
    "city": "Brindisi",
    "city_ascii": "Brindisi",
    "lat": 40.6383,
    "lng": 17.9458,
    "country": "Italy",
    "iso2": "IT",
    "iso3": "ITA",
    "admin_name": "Puglia",
    "capital": "minor",
    "population": 87141,
    "id": 1380201254
  },
  {
    "city": "Tiflet",
    "city_ascii": "Tiflet",
    "lat": 33.9,
    "lng": -6.33,
    "country": "Morocco",
    "iso2": "MA",
    "iso3": "MAR",
    "admin_name": "Rabat-Salé-Kénitra",
    "capital": "",
    "population": 86709,
    "id": 1504661999
  },
  {
    "city": "Decatur",
    "city_ascii": "Decatur",
    "lat": 39.8556,
    "lng": -88.9337,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 87236,
    "id": 1840007269
  },
  {
    "city": "Nova Lima",
    "city_ascii": "Nova Lima",
    "lat": -19.9858,
    "lng": -43.8469,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Minas Gerais",
    "capital": "",
    "population": 87000,
    "id": 1076876220
  },
  {
    "city": "Ad Diwem",
    "city_ascii": "Ad Diwem",
    "lat": 13.9904,
    "lng": 32.3,
    "country": "Sudan",
    "iso2": "SD",
    "iso3": "SDN",
    "admin_name": "White Nile",
    "capital": "",
    "population": 87068,
    "id": 1729364292
  },
  {
    "city": "Jackson",
    "city_ascii": "Jackson",
    "lat": 42.2431,
    "lng": -84.4037,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 87110,
    "id": 1840003173
  },
  {
    "city": "Fort Myers",
    "city_ascii": "Fort Myers",
    "lat": 26.6195,
    "lng": -81.8303,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 87103,
    "id": 1840014226
  },
  {
    "city": "Qaraçuxur",
    "city_ascii": "Qaracuxur",
    "lat": 40.3969,
    "lng": 49.9733,
    "country": "Azerbaijan",
    "iso2": "AZ",
    "iso3": "AZE",
    "admin_name": "Bakı",
    "capital": "",
    "population": 85900,
    "id": 1031616606
  },
  {
    "city": "Campana",
    "city_ascii": "Campana",
    "lat": -34.1667,
    "lng": -58.9167,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires",
    "capital": "minor",
    "population": 86860,
    "id": 1032345885
  },
  {
    "city": "Sekimachi",
    "city_ascii": "Sekimachi",
    "lat": 35.4958,
    "lng": 136.9178,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Gifu",
    "capital": "",
    "population": 86698,
    "id": 1392926688
  },
  {
    "city": "Gubkin",
    "city_ascii": "Gubkin",
    "lat": 51.2833,
    "lng": 37.55,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Belgorodskaya Oblast’",
    "capital": "",
    "population": 86780,
    "id": 1643378602
  },
  {
    "city": "Cárdenas",
    "city_ascii": "Cardenas",
    "lat": 23.0428,
    "lng": -81.2036,
    "country": "Cuba",
    "iso2": "CU",
    "iso3": "CUB",
    "admin_name": "Matanzas",
    "capital": "minor",
    "population": 86700,
    "id": 1192582521
  },
  {
    "city": "Goodyear",
    "city_ascii": "Goodyear",
    "lat": 33.2613,
    "lng": -112.3622,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 86840,
    "id": 1840020564
  },
  {
    "city": "Cheektowaga",
    "city_ascii": "Cheektowaga",
    "lat": 42.9082,
    "lng": -78.7466,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 86813,
    "id": 1840057976
  },
  {
    "city": "Aalst",
    "city_ascii": "Aalst",
    "lat": 50.9333,
    "lng": 4.0333,
    "country": "Belgium",
    "iso2": "BE",
    "iso3": "BEL",
    "admin_name": "Flanders",
    "capital": "minor",
    "population": 85715,
    "id": 1056695813
  },
  {
    "city": "Kitakōriyamachō",
    "city_ascii": "Kitakoriyamacho",
    "lat": 34.6494,
    "lng": 135.7828,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Nara",
    "capital": "",
    "population": 84820,
    "id": 1392178653
  },
  {
    "city": "Kattagan",
    "city_ascii": "Kattagan",
    "lat": 40.2,
    "lng": 64.9167,
    "country": "Uzbekistan",
    "iso2": "UZ",
    "iso3": "UZB",
    "admin_name": "Navoiy",
    "capital": "",
    "population": 86745,
    "id": 1860782136
  },
  {
    "city": "Paraíso",
    "city_ascii": "Paraiso",
    "lat": 18.3961,
    "lng": -93.2128,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Tabasco",
    "capital": "minor",
    "population": 86632,
    "id": 1484393519
  },
  {
    "city": "Tumaco",
    "city_ascii": "Tumaco",
    "lat": 1.81,
    "lng": -78.81,
    "country": "Colombia",
    "iso2": "CO",
    "iso3": "COL",
    "admin_name": "Nariño",
    "capital": "minor",
    "population": 86713,
    "id": 1170504978
  },
  {
    "city": "Novotroitsk",
    "city_ascii": "Novotroitsk",
    "lat": 51.2067,
    "lng": 58.3281,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Orenburgskaya Oblast’",
    "capital": "",
    "population": 86474,
    "id": 1643186193
  },
  {
    "city": "Kharian",
    "city_ascii": "Kharian",
    "lat": 32.811,
    "lng": 73.865,
    "country": "Pakistan",
    "iso2": "PK",
    "iso3": "PAK",
    "admin_name": "Punjab",
    "capital": "",
    "population": 85765,
    "id": 1586004203
  },
  {
    "city": "Catalão",
    "city_ascii": "Catalao",
    "lat": -18.17,
    "lng": -47.9419,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Goiás",
    "capital": "",
    "population": 86647,
    "id": 1076169871
  },
  {
    "city": "Keffi",
    "city_ascii": "Keffi",
    "lat": 8.849,
    "lng": 7.8736,
    "country": "Nigeria",
    "iso2": "NG",
    "iso3": "NGA",
    "admin_name": "Nasarawa",
    "capital": "minor",
    "population": 85911,
    "id": 1566364417
  },
  {
    "city": "Pariaman",
    "city_ascii": "Pariaman",
    "lat": -0.6261,
    "lng": 100.1206,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Sumatera Barat",
    "capital": "minor",
    "population": 85485,
    "id": 1360811086
  },
  {
    "city": "Dalton",
    "city_ascii": "Dalton",
    "lat": 34.769,
    "lng": -84.9712,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 86628,
    "id": 1840014651
  },
  {
    "city": "Yokotemachi",
    "city_ascii": "Yokotemachi",
    "lat": 39.3106,
    "lng": 140.5533,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Akita",
    "capital": "",
    "population": 86499,
    "id": 1392195240
  },
  {
    "city": "Santa Rosa Jauregui",
    "city_ascii": "Santa Rosa Jauregui",
    "lat": 20.7418,
    "lng": -100.4473,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Querétaro",
    "capital": "",
    "population": 86376,
    "id": 1484681374
  },
  {
    "city": "Ubatuba",
    "city_ascii": "Ubatuba",
    "lat": -23.4339,
    "lng": -45.0708,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 86392,
    "id": 1076510903
  },
  {
    "city": "Clarkstown",
    "city_ascii": "Clarkstown",
    "lat": 41.1319,
    "lng": -73.966,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 86511,
    "id": 1840087311
  },
  {
    "city": "San Antonio",
    "city_ascii": "San Antonio",
    "lat": -33.5809,
    "lng": -71.6132,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Valparaíso",
    "capital": "minor",
    "population": 86239,
    "id": 1152830501
  },
  {
    "city": "Shaoshanzhan",
    "city_ascii": "Shaoshanzhan",
    "lat": 27.91,
    "lng": 112.48,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hunan",
    "capital": "",
    "population": 86036,
    "id": 1156109964
  },
  {
    "city": "Olavarría",
    "city_ascii": "Olavarria",
    "lat": -36.9,
    "lng": -60.3333,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires",
    "capital": "minor",
    "population": 86320,
    "id": 1032373353
  },
  {
    "city": "Konstanz",
    "city_ascii": "Konstanz",
    "lat": 47.6633,
    "lng": 9.1753,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 84760,
    "id": 1276601373
  },
  {
    "city": "Bryan",
    "city_ascii": "Bryan",
    "lat": 30.6657,
    "lng": -96.3668,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 86276,
    "id": 1840019569
  },
  {
    "city": "Zhezqazghan",
    "city_ascii": "Zhezqazghan",
    "lat": 47.7833,
    "lng": 67.7,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Qaraghandy",
    "capital": "",
    "population": 86227,
    "id": 1398121545
  },
  {
    "city": "Bugulma",
    "city_ascii": "Bugulma",
    "lat": 54.5333,
    "lng": 52.7833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Tatarstan",
    "capital": "",
    "population": 86085,
    "id": 1643987882
  },
  {
    "city": "Shchëkino",
    "city_ascii": "Shchekino",
    "lat": 54.0143,
    "lng": 37.5143,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Tul’skaya Oblast’",
    "capital": "",
    "population": 86088,
    "id": 1643062876
  },
  {
    "city": "Potiskum",
    "city_ascii": "Potiskum",
    "lat": 11.7104,
    "lng": 11.08,
    "country": "Nigeria",
    "iso2": "NG",
    "iso3": "NGA",
    "admin_name": "Yobe",
    "capital": "minor",
    "population": 86002,
    "id": 1566461523
  },
  {
    "city": "Waukegan",
    "city_ascii": "Waukegan",
    "lat": 42.3697,
    "lng": -87.8716,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 86075,
    "id": 1840010113
  },
  {
    "city": "Ushiku",
    "city_ascii": "Ushiku",
    "lat": 35.9833,
    "lng": 140.15,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Ibaraki",
    "capital": "",
    "population": 84637,
    "id": 1392965168
  },
  {
    "city": "Anderson",
    "city_ascii": "Anderson",
    "lat": 40.0891,
    "lng": -85.6893,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 85992,
    "id": 1840007242
  },
  {
    "city": "North Vancouver",
    "city_ascii": "North Vancouver",
    "lat": 49.3641,
    "lng": -123.0066,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "British Columbia",
    "capital": "",
    "population": 85935,
    "id": 1124000146
  },
  {
    "city": "Redwood City",
    "city_ascii": "Redwood City",
    "lat": 37.5026,
    "lng": -122.2252,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 85925,
    "id": 1840020304
  },
  {
    "city": "Yeysk",
    "city_ascii": "Yeysk",
    "lat": 46.7106,
    "lng": 38.2778,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Krasnodarskiy Kray",
    "capital": "minor",
    "population": 85760,
    "id": 1643587963
  },
  {
    "city": "Chita",
    "city_ascii": "Chita",
    "lat": 35,
    "lng": 136.8667,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Aichi",
    "capital": "",
    "population": 83946,
    "id": 1392292278
  },
  {
    "city": "Hoover",
    "city_ascii": "Hoover",
    "lat": 33.3754,
    "lng": -86.8064,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 85768,
    "id": 1840014795
  },
  {
    "city": "Brossard",
    "city_ascii": "Brossard",
    "lat": 45.4667,
    "lng": -73.45,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 85721,
    "id": 1124655166
  },
  {
    "city": "Villingen-Schwenningen",
    "city_ascii": "Villingen-Schwenningen",
    "lat": 48.0603,
    "lng": 8.4586,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "minor",
    "population": 85181,
    "id": 1276450523
  },
  {
    "city": "Town 'n' Country",
    "city_ascii": "Town 'n' Country",
    "lat": 28.0108,
    "lng": -82.576,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 85620,
    "id": 1840029089
  },
  {
    "city": "Kineshma",
    "city_ascii": "Kineshma",
    "lat": 57.45,
    "lng": 42.15,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Ivanovskaya Oblast’",
    "capital": "",
    "population": 83871,
    "id": 1643014487
  },
  {
    "city": "Lake Forest",
    "city_ascii": "Lake Forest",
    "lat": 33.6606,
    "lng": -117.6712,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 85531,
    "id": 1840020591
  },
  {
    "city": "Treviso",
    "city_ascii": "Treviso",
    "lat": 45.6722,
    "lng": 12.2422,
    "country": "Italy",
    "iso2": "IT",
    "iso3": "ITA",
    "admin_name": "Veneto",
    "capital": "minor",
    "population": 83950,
    "id": 1380900516
  },
  {
    "city": "Napa",
    "city_ascii": "Napa",
    "lat": 38.2976,
    "lng": -122.3011,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 85239,
    "id": 1840020241
  },
  {
    "city": "Sumenep",
    "city_ascii": "Sumenep",
    "lat": -7.0049,
    "lng": 113.8496,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Jawa Timur",
    "capital": "minor",
    "population": 84656,
    "id": 1360439354
  },
  {
    "city": "Jelenia Góra",
    "city_ascii": "Jelenia Gora",
    "lat": 50.9,
    "lng": 15.7333,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Dolnośląskie",
    "capital": "minor",
    "population": 84306,
    "id": 1616105652
  },
  {
    "city": "Walvisbaai",
    "city_ascii": "Walvisbaai",
    "lat": -22.9494,
    "lng": 14.5069,
    "country": "Namibia",
    "iso2": "NA",
    "iso3": "NAM",
    "admin_name": "Erongo",
    "capital": "",
    "population": 85000,
    "id": 1516267898
  },
  {
    "city": "Junín",
    "city_ascii": "Junin",
    "lat": -34.5939,
    "lng": -60.9464,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires",
    "capital": "minor",
    "population": 85007,
    "id": 1032976047
  },
  {
    "city": "Stockton-on-Tees",
    "city_ascii": "Stockton-on-Tees",
    "lat": 54.57,
    "lng": -1.32,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Stockton-on-Tees",
    "capital": "",
    "population": 85000,
    "id": 1826259802
  },
  {
    "city": "Korgas",
    "city_ascii": "Korgas",
    "lat": 44.1256,
    "lng": 80.4144,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Xinjiang",
    "capital": "",
    "population": 85000,
    "id": 1156221385
  },
  {
    "city": "Repentigny",
    "city_ascii": "Repentigny",
    "lat": 45.7333,
    "lng": -73.4667,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 84965,
    "id": 1124379778
  },
  {
    "city": "Largo",
    "city_ascii": "Largo",
    "lat": 27.9088,
    "lng": -82.7711,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 84948,
    "id": 1840015114
  },
  {
    "city": "Bloomington",
    "city_ascii": "Bloomington",
    "lat": 44.8306,
    "lng": -93.3151,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 84943,
    "id": 1840006747
  },
  {
    "city": "Marl",
    "city_ascii": "Marl",
    "lat": 51.6667,
    "lng": 7.1167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 83941,
    "id": 1276451667
  },
  {
    "city": "Arujá",
    "city_ascii": "Aruja",
    "lat": -23.3967,
    "lng": -46.3211,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 83939,
    "id": 1076436872
  },
  {
    "city": "Guider",
    "city_ascii": "Guider",
    "lat": 9.9342,
    "lng": 13.9486,
    "country": "Cameroon",
    "iso2": "CM",
    "iso3": "CMR",
    "admin_name": "Nord",
    "capital": "",
    "population": 84647,
    "id": 1120662630
  },
  {
    "city": "Johns Creek",
    "city_ascii": "Johns Creek",
    "lat": 34.0333,
    "lng": -84.2027,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 84579,
    "id": 1840014742
  },
  {
    "city": "Tire",
    "city_ascii": "Tire",
    "lat": 38.0833,
    "lng": 27.7333,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "İzmir",
    "capital": "minor",
    "population": 84457,
    "id": 1792346729
  },
  {
    "city": "Newport Beach",
    "city_ascii": "Newport Beach",
    "lat": 33.6151,
    "lng": -117.8669,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 84534,
    "id": 1840020581
  },
  {
    "city": "Kimje",
    "city_ascii": "Kimje",
    "lat": 35.8017,
    "lng": 126.8889,
    "country": "Korea, South",
    "iso2": "KR",
    "iso3": "KOR",
    "admin_name": "Jeonbuk",
    "capital": "",
    "population": 84269,
    "id": 1410051393
  },
  {
    "city": "Mission",
    "city_ascii": "Mission",
    "lat": 26.2041,
    "lng": -98.3251,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 84331,
    "id": 1840021026
  },
  {
    "city": "Muroran",
    "city_ascii": "Muroran",
    "lat": 42.3153,
    "lng": 140.9736,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hokkaidō",
    "capital": "",
    "population": 83287,
    "id": 1392259868
  },
  {
    "city": "Worms",
    "city_ascii": "Worms",
    "lat": 49.6319,
    "lng": 8.3653,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Rhineland-Palatinate",
    "capital": "minor",
    "population": 83330,
    "id": 1276944683
  },
  {
    "city": "Troy",
    "city_ascii": "Troy",
    "lat": 42.5818,
    "lng": -83.1457,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 84092,
    "id": 1840002434
  },
  {
    "city": "Madera",
    "city_ascii": "Madera",
    "lat": 36.9631,
    "lng": -120.0782,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 84086,
    "id": 1840020300
  },
  {
    "city": "Joplin",
    "city_ascii": "Joplin",
    "lat": 37.0758,
    "lng": -94.5018,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 84048,
    "id": 1840008771
  },
  {
    "city": "Zheleznogorsk",
    "city_ascii": "Zheleznogorsk",
    "lat": 56.25,
    "lng": 93.5333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Krasnoyarskiy Kray",
    "capital": "",
    "population": 83857,
    "id": 1643824002
  },
  {
    "city": "Moriyama",
    "city_ascii": "Moriyama",
    "lat": 35.0589,
    "lng": 135.9944,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Shiga",
    "capital": "",
    "population": 82526,
    "id": 1392937175
  },
  {
    "city": "Chino Hills",
    "city_ascii": "Chino Hills",
    "lat": 33.9508,
    "lng": -117.7253,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 83853,
    "id": 1840019115
  },
  {
    "city": "Sirsilla",
    "city_ascii": "Sirsilla",
    "lat": 18.38,
    "lng": 78.83,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Telangana",
    "capital": "",
    "population": 83186,
    "id": 1356360144
  },
  {
    "city": "Chilliwack",
    "city_ascii": "Chilliwack",
    "lat": 49.1577,
    "lng": -121.9509,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "British Columbia",
    "capital": "",
    "population": 83788,
    "id": 1124531262
  },
  {
    "city": "Pontevedra",
    "city_ascii": "Pontevedra",
    "lat": 42.4336,
    "lng": -8.6475,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Galicia",
    "capital": "minor",
    "population": 83029,
    "id": 1724789643
  },
  {
    "city": "Ocaña",
    "city_ascii": "Ocana",
    "lat": 8.2404,
    "lng": -73.35,
    "country": "Colombia",
    "iso2": "CO",
    "iso3": "COL",
    "admin_name": "Norte de Santander",
    "capital": "minor",
    "population": 83511,
    "id": 1170754105
  },
  {
    "city": "Yurga",
    "city_ascii": "Yurga",
    "lat": 55.7333,
    "lng": 84.9,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kemerovskaya Oblast’",
    "capital": "",
    "population": 81759,
    "id": 1643047994
  },
  {
    "city": "Redditch",
    "city_ascii": "Redditch",
    "lat": 52.3069,
    "lng": -1.9492,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Worcestershire",
    "capital": "",
    "population": 81919,
    "id": 1826205148
  },
  {
    "city": "Wiener Neustadt",
    "city_ascii": "Wiener Neustadt",
    "lat": 47.816,
    "lng": 16.25,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Niederösterreich",
    "capital": "minor",
    "population": 82762,
    "id": 1040389518
  },
  {
    "city": "Mian Channun",
    "city_ascii": "Mian Channun",
    "lat": 30.4397,
    "lng": 72.3544,
    "country": "Pakistan",
    "iso2": "PK",
    "iso3": "PAK",
    "admin_name": "Punjab",
    "capital": "",
    "population": 82586,
    "id": 1586864091
  },
  {
    "city": "Santana do Livramento",
    "city_ascii": "Santana do Livramento",
    "lat": -30.8908,
    "lng": -55.5328,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Rio Grande do Sul",
    "capital": "",
    "population": 83324,
    "id": 1076271841
  },
  {
    "city": "Mijas",
    "city_ascii": "Mijas",
    "lat": 36.5956,
    "lng": -4.6372,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Andalusia",
    "capital": "",
    "population": 82742,
    "id": 1724011980
  },
  {
    "city": "Tual",
    "city_ascii": "Tual",
    "lat": -5.6385,
    "lng": 132.745,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Maluku",
    "capital": "",
    "population": 82955,
    "id": 1360221504
  },
  {
    "city": "São Sebastião",
    "city_ascii": "Sao Sebastiao",
    "lat": -23.76,
    "lng": -45.41,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 83020,
    "id": 1076889310
  },
  {
    "city": "Chaykovskiy",
    "city_ascii": "Chaykovskiy",
    "lat": 56.7667,
    "lng": 54.1167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Permskiy Kray",
    "capital": "",
    "population": 83077,
    "id": 1643089795
  },
  {
    "city": "Franklin",
    "city_ascii": "Franklin",
    "lat": 35.9216,
    "lng": -86.8525,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 83097,
    "id": 1840013380
  },
  {
    "city": "Velbert",
    "city_ascii": "Velbert",
    "lat": 51.34,
    "lng": 7.0416,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 81984,
    "id": 1276896645
  },
  {
    "city": "Nakatsu",
    "city_ascii": "Nakatsu",
    "lat": 33.5983,
    "lng": 131.1883,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Fukuoka",
    "capital": "",
    "population": 82875,
    "id": 1392944075
  },
  {
    "city": "Melbourne",
    "city_ascii": "Melbourne",
    "lat": 28.1085,
    "lng": -80.6627,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 83029,
    "id": 1840015960
  },
  {
    "city": "Al Ḩayy",
    "city_ascii": "Al Hayy",
    "lat": 32.1742,
    "lng": 46.0433,
    "country": "Iraq",
    "iso2": "IQ",
    "iso3": "IRQ",
    "admin_name": "Wāsiţ",
    "capital": "minor",
    "population": 82900,
    "id": 1368988553
  },
  {
    "city": "Port Huron",
    "city_ascii": "Port Huron",
    "lat": 42.9822,
    "lng": -82.4387,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 82970,
    "id": 1840003953
  },
  {
    "city": "Colonie",
    "city_ascii": "Colonie",
    "lat": 42.7396,
    "lng": -73.7874,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 82882,
    "id": 1840004495
  },
  {
    "city": "Shīrvān",
    "city_ascii": "Shirvan",
    "lat": 37.3967,
    "lng": 57.9294,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Khorāsān-e Shomālī",
    "capital": "minor",
    "population": 82790,
    "id": 1364402242
  },
  {
    "city": "Springfield",
    "city_ascii": "Springfield",
    "lat": 39.9297,
    "lng": -83.7957,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 82820,
    "id": 1840003765
  },
  {
    "city": "Kimitsu",
    "city_ascii": "Kimitsu",
    "lat": 35.3306,
    "lng": 139.9025,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Chiba",
    "capital": "",
    "population": 82548,
    "id": 1392801760
  },
  {
    "city": "Guadalajara",
    "city_ascii": "Guadalajara",
    "lat": 40.6335,
    "lng": -3.1669,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Castille-La Mancha",
    "capital": "minor",
    "population": 82376,
    "id": 1724317859
  },
  {
    "city": "Dapitan",
    "city_ascii": "Dapitan",
    "lat": 8.6549,
    "lng": 123.4243,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Zamboanga del Norte",
    "capital": "",
    "population": 82418,
    "id": 1608014287
  },
  {
    "city": "Kaneyama",
    "city_ascii": "Kaneyama",
    "lat": 31.3833,
    "lng": 130.85,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Kagoshima",
    "capital": "",
    "population": 82335,
    "id": 1392748014
  },
  {
    "city": "Minden",
    "city_ascii": "Minden",
    "lat": 52.2883,
    "lng": 8.9167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "minor",
    "population": 81682,
    "id": 1276191164
  },
  {
    "city": "Zephyrhills",
    "city_ascii": "Zephyrhills",
    "lat": 28.2408,
    "lng": -82.1796,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 82476,
    "id": 1840015968
  },
  {
    "city": "Ōmihachiman",
    "city_ascii": "Omihachiman",
    "lat": 35.1283,
    "lng": 136.0981,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Shiga",
    "capital": "",
    "population": 81322,
    "id": 1392288149
  },
  {
    "city": "Bekobod",
    "city_ascii": "Bekobod",
    "lat": 40.2258,
    "lng": 69.2292,
    "country": "Uzbekistan",
    "iso2": "UZ",
    "iso3": "UZB",
    "admin_name": "Toshkent",
    "capital": "",
    "population": 82082,
    "id": 1860881451
  },
  {
    "city": "Maple Ridge",
    "city_ascii": "Maple Ridge",
    "lat": 49.2167,
    "lng": -122.6,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "British Columbia",
    "capital": "",
    "population": 82256,
    "id": 1124001699
  },
  {
    "city": "La Louvière",
    "city_ascii": "La Louviere",
    "lat": 50.4778,
    "lng": 4.1881,
    "country": "Belgium",
    "iso2": "BE",
    "iso3": "BEL",
    "admin_name": "Wallonia",
    "capital": "",
    "population": 80986,
    "id": 1056383929
  },
  {
    "city": "St. Augustine",
    "city_ascii": "St. Augustine",
    "lat": 29.8977,
    "lng": -81.31,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 82180,
    "id": 1840015935
  },
  {
    "city": "Varese",
    "city_ascii": "Varese",
    "lat": 45.8167,
    "lng": 8.8333,
    "country": "Italy",
    "iso2": "IT",
    "iso3": "ITA",
    "admin_name": "Lombardy",
    "capital": "minor",
    "population": 80629,
    "id": 1380137214
  },
  {
    "city": "Peterborough",
    "city_ascii": "Peterborough",
    "lat": 44.3,
    "lng": -78.3167,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 82094,
    "id": 1124440356
  },
  {
    "city": "Pilar",
    "city_ascii": "Pilar",
    "lat": -34.4588,
    "lng": -58.9142,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires",
    "capital": "minor",
    "population": 81120,
    "id": 1032712196
  },
  {
    "city": "Science City of Muñoz",
    "city_ascii": "Science City of Munoz",
    "lat": 15.7167,
    "lng": 120.9,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Nueva Ecija",
    "capital": "",
    "population": 81483,
    "id": 1608795106
  },
  {
    "city": "Ust’-Ilimsk",
    "city_ascii": "Ust'-Ilimsk",
    "lat": 58,
    "lng": 102.6667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Irkutskaya Oblast’",
    "capital": "",
    "population": 81976,
    "id": 1643673948
  },
  {
    "city": "Flagstaff",
    "city_ascii": "Flagstaff",
    "lat": 35.1872,
    "lng": -111.6195,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 81974,
    "id": 1840020335
  },
  {
    "city": "Presidencia Roque Sáenz Peña",
    "city_ascii": "Presidencia Roque Saenz Pena",
    "lat": -26.7833,
    "lng": -60.45,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Chaco",
    "capital": "minor",
    "population": 81879,
    "id": 1032464495
  },
  {
    "city": "Azov",
    "city_ascii": "Azov",
    "lat": 47.1,
    "lng": 39.4167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Rostovskaya Oblast’",
    "capital": "minor",
    "population": 80721,
    "id": 1643548776
  },
  {
    "city": "Luján",
    "city_ascii": "Lujan",
    "lat": -34.5796,
    "lng": -59.11,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires",
    "capital": "minor",
    "population": 81749,
    "id": 1032704827
  },
  {
    "city": "Agboville",
    "city_ascii": "Agboville",
    "lat": 5.9333,
    "lng": -4.2167,
    "country": "Côte D’Ivoire",
    "iso2": "CI",
    "iso3": "CIV",
    "admin_name": "Lagunes",
    "capital": "minor",
    "population": 81770,
    "id": 1384823872
  },
  {
    "city": "Campo Limpo",
    "city_ascii": "Campo Limpo",
    "lat": -23.2064,
    "lng": -46.7844,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 80847,
    "id": 1076459810
  },
  {
    "city": "Watampone",
    "city_ascii": "Watampone",
    "lat": -4.5328,
    "lng": 120.3334,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Sulawesi Selatan",
    "capital": "minor",
    "population": 81629,
    "id": 1360956480
  },
  {
    "city": "Pleasanton",
    "city_ascii": "Pleasanton",
    "lat": 37.6663,
    "lng": -121.8805,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 81777,
    "id": 1840020298
  },
  {
    "city": "Anapa",
    "city_ascii": "Anapa",
    "lat": 44.8944,
    "lng": 37.3167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Krasnodarskiy Kray",
    "capital": "minor",
    "population": 81447,
    "id": 1643287068
  },
  {
    "city": "Matão",
    "city_ascii": "Matao",
    "lat": -21.6033,
    "lng": -48.3658,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 81439,
    "id": 1076497431
  },
  {
    "city": "Dessau-Roßlau",
    "city_ascii": "Dessau-Rosslau",
    "lat": 51.8333,
    "lng": 12.2333,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony-Anhalt",
    "capital": "minor",
    "population": 81237,
    "id": 1276983641
  },
  {
    "city": "Banī Mazār",
    "city_ascii": "Bani Mazar",
    "lat": 28.5,
    "lng": 30.8,
    "country": "Egypt",
    "iso2": "EG",
    "iso3": "EGY",
    "admin_name": "Al Minyā",
    "capital": "",
    "population": 79553,
    "id": 1818729843
  },
  {
    "city": "Kentaū",
    "city_ascii": "Kentau",
    "lat": 43.5169,
    "lng": 68.5044,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Ongtüstik Qazaqstan",
    "capital": "",
    "population": 81484,
    "id": 1398997742
  },
  {
    "city": "Westland",
    "city_ascii": "Westland",
    "lat": 42.3192,
    "lng": -83.3805,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 81511,
    "id": 1840001847
  },
  {
    "city": "Gamagōri",
    "city_ascii": "Gamagori",
    "lat": 34.8264,
    "lng": 137.2196,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Aichi",
    "capital": "",
    "population": 80091,
    "id": 1392024893
  },
  {
    "city": "Auburn",
    "city_ascii": "Auburn",
    "lat": 47.3039,
    "lng": -122.2108,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 81464,
    "id": 1840018416
  },
  {
    "city": "Cranston",
    "city_ascii": "Cranston",
    "lat": 41.7658,
    "lng": -71.4857,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Rhode Island",
    "capital": "",
    "population": 81456,
    "id": 1840003287
  },
  {
    "city": "Gobernador Gálvez",
    "city_ascii": "Gobernador Galvez",
    "lat": -33.0251,
    "lng": -60.6337,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Santa Fe",
    "capital": "",
    "population": 80769,
    "id": 1032739359
  },
  {
    "city": "Kāmāreddipet",
    "city_ascii": "Kamareddipet",
    "lat": 18.3167,
    "lng": 78.35,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Telangana",
    "capital": "",
    "population": 80315,
    "id": 1356010343
  },
  {
    "city": "Athi River",
    "city_ascii": "Athi River",
    "lat": -1.45,
    "lng": 36.9833,
    "country": "Kenya",
    "iso2": "KE",
    "iso3": "KEN",
    "admin_name": "Machakos",
    "capital": "",
    "population": 81302,
    "id": 1404232217
  },
  {
    "city": "Novouralsk",
    "city_ascii": "Novouralsk",
    "lat": 57.25,
    "lng": 60.0833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sverdlovskaya Oblast’",
    "capital": "",
    "population": 81202,
    "id": 1643155652
  },
  {
    "city": "Folsom",
    "city_ascii": "Folsom",
    "lat": 38.6668,
    "lng": -121.1422,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 81328,
    "id": 1840020246
  },
  {
    "city": "Akiruno",
    "city_ascii": "Akiruno",
    "lat": 35.7289,
    "lng": 139.2942,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Tōkyō",
    "capital": "",
    "population": 80074,
    "id": 1392270849
  },
  {
    "city": "Springdale",
    "city_ascii": "Springdale",
    "lat": 36.1899,
    "lng": -94.1574,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arkansas",
    "capital": "",
    "population": 81125,
    "id": 1840015336
  },
  {
    "city": "Taroudannt",
    "city_ascii": "Taroudannt",
    "lat": 30.4711,
    "lng": -8.8778,
    "country": "Morocco",
    "iso2": "MA",
    "iso3": "MAR",
    "admin_name": "Souss-Massa",
    "capital": "",
    "population": 80149,
    "id": 1504415764
  },
  {
    "city": "Sefrou",
    "city_ascii": "Sefrou",
    "lat": 33.83,
    "lng": -4.83,
    "country": "Morocco",
    "iso2": "MA",
    "iso3": "MAR",
    "admin_name": "Fès-Meknès",
    "capital": "",
    "population": 79887,
    "id": 1504820285
  },
  {
    "city": "Polatsk",
    "city_ascii": "Polatsk",
    "lat": 55.4833,
    "lng": 28.8,
    "country": "Belarus",
    "iso2": "BY",
    "iso3": "BLR",
    "admin_name": "Vitsyebskaya Voblasts’",
    "capital": "minor",
    "population": 81000,
    "id": 1112068992
  },
  {
    "city": "Warwick",
    "city_ascii": "Warwick",
    "lat": 41.7062,
    "lng": -71.4334,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Rhode Island",
    "capital": "",
    "population": 81004,
    "id": 1840003344
  },
  {
    "city": "Iwamizawa",
    "city_ascii": "Iwamizawa",
    "lat": 43.1961,
    "lng": 141.7758,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hokkaidō",
    "capital": "",
    "population": 80753,
    "id": 1392277310
  },
  {
    "city": "Passi",
    "city_ascii": "Passi",
    "lat": 11.1,
    "lng": 122.6333,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Iloilo",
    "capital": "",
    "population": 80544,
    "id": 1608905309
  },
  {
    "city": "Ālbū Kamāl",
    "city_ascii": "Albu Kamal",
    "lat": 34.4504,
    "lng": 40.9186,
    "country": "Syria",
    "iso2": "SY",
    "iso3": "SYR",
    "admin_name": "Dayr az Zawr",
    "capital": "minor",
    "population": 80808,
    "id": 1760836213
  },
  {
    "city": "Tanjay",
    "city_ascii": "Tanjay",
    "lat": 9.5167,
    "lng": 123.1583,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Negros Oriental",
    "capital": "",
    "population": 80532,
    "id": 1608955515
  },
  {
    "city": "Tepeji del Río de Ocampo",
    "city_ascii": "Tepeji del Rio de Ocampo",
    "lat": 19.9039,
    "lng": -99.3414,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Hidalgo",
    "capital": "",
    "population": 80612,
    "id": 1484352351
  },
  {
    "city": "Svyetlahorsk",
    "city_ascii": "Svyetlahorsk",
    "lat": 52.6333,
    "lng": 29.7333,
    "country": "Belarus",
    "iso2": "BY",
    "iso3": "BLR",
    "admin_name": "Homyel’skaya Voblasts’",
    "capital": "minor",
    "population": 80700,
    "id": 1112741799
  },
  {
    "city": "Farmington Hills",
    "city_ascii": "Farmington Hills",
    "lat": 42.486,
    "lng": -83.3771,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 80612,
    "id": 1840002442
  },
  {
    "city": "Neumünster",
    "city_ascii": "Neumunster",
    "lat": 54.0714,
    "lng": 9.99,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Schleswig-Holstein",
    "capital": "minor",
    "population": 79487,
    "id": 1276757733
  },
  {
    "city": "Gyōda",
    "city_ascii": "Gyoda",
    "lat": 36.1389,
    "lng": 139.4558,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Saitama",
    "capital": "",
    "population": 79405,
    "id": 1392815268
  },
  {
    "city": "Mackay",
    "city_ascii": "Mackay",
    "lat": -21.1411,
    "lng": 149.1861,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Queensland",
    "capital": "",
    "population": 80148,
    "id": 1036507374
  },
  {
    "city": "Norderstedt",
    "city_ascii": "Norderstedt",
    "lat": 53.7064,
    "lng": 10.0103,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Schleswig-Holstein",
    "capital": "",
    "population": 79159,
    "id": 1276872953
  },
  {
    "city": "Necochea",
    "city_ascii": "Necochea",
    "lat": -38.56,
    "lng": -58.75,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires",
    "capital": "minor",
    "population": 80478,
    "id": 1032624527
  },
  {
    "city": "Pāloncha",
    "city_ascii": "Paloncha",
    "lat": 17.5815,
    "lng": 80.6765,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Telangana",
    "capital": "",
    "population": 80199,
    "id": 1356171257
  },
  {
    "city": "Newark",
    "city_ascii": "Newark",
    "lat": 40.0705,
    "lng": -82.4251,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 80451,
    "id": 1840001419
  },
  {
    "city": "Williamsburg",
    "city_ascii": "Williamsburg",
    "lat": 37.2692,
    "lng": -76.7076,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 80441,
    "id": 1840001720
  },
  {
    "city": "Brooklyn Park",
    "city_ascii": "Brooklyn Park",
    "lat": 45.1112,
    "lng": -93.3505,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 80389,
    "id": 1840004023
  },
  {
    "city": "Uvinza",
    "city_ascii": "Uvinza",
    "lat": -5.1036,
    "lng": 30.3911,
    "country": "Tanzania",
    "iso2": "TZ",
    "iso3": "TZA",
    "admin_name": "Kigoma",
    "capital": "",
    "population": 80350,
    "id": 1834561245
  },
  {
    "city": "Hannō",
    "city_ascii": "Hanno",
    "lat": 35.8558,
    "lng": 139.3278,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Saitama",
    "capital": "",
    "population": 79891,
    "id": 1392681016
  },
  {
    "city": "Kottagūdem",
    "city_ascii": "Kottagudem",
    "lat": 17.55,
    "lng": 80.63,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Telangana",
    "capital": "",
    "population": 79819,
    "id": 1356124457
  },
  {
    "city": "Mbalmayo",
    "city_ascii": "Mbalmayo",
    "lat": 3.52,
    "lng": 11.5122,
    "country": "Cameroon",
    "iso2": "CM",
    "iso3": "CMR",
    "admin_name": "Centre",
    "capital": "",
    "population": 80206,
    "id": 1120049681
  },
  {
    "city": "Namsan",
    "city_ascii": "Namsan",
    "lat": 42.2304,
    "lng": 129.2304,
    "country": "Korea, North",
    "iso2": "KP",
    "iso3": "PRK",
    "admin_name": "Hambuk",
    "capital": "minor",
    "population": 80146,
    "id": 1408000884
  },
  {
    "city": "Andahuaylas",
    "city_ascii": "Andahuaylas",
    "lat": -13.6575,
    "lng": -73.3833,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "Apurímac",
    "capital": "",
    "population": 80000,
    "id": 1604615351
  },
  {
    "city": "Hattiesburg",
    "city_ascii": "Hattiesburg",
    "lat": 31.3074,
    "lng": -89.317,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Mississippi",
    "capital": "",
    "population": 80138,
    "id": 1840013878
  },
  {
    "city": "Alexandria",
    "city_ascii": "Alexandria",
    "lat": 31.2923,
    "lng": -92.4702,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 80129,
    "id": 1840013873
  },
  {
    "city": "Jamshoro",
    "city_ascii": "Jamshoro",
    "lat": 25.4283,
    "lng": 68.2822,
    "country": "Pakistan",
    "iso2": "PK",
    "iso3": "PAK",
    "admin_name": "Sindh",
    "capital": "minor",
    "population": 80000,
    "id": 1586889028
  },
  {
    "city": "Maizuru",
    "city_ascii": "Maizuru",
    "lat": 35.45,
    "lng": 135.3167,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Kyōto",
    "capital": "",
    "population": 79886,
    "id": 1392353780
  },
  {
    "city": "Meïganga",
    "city_ascii": "Meiganga",
    "lat": 6.5172,
    "lng": 14.2947,
    "country": "Cameroon",
    "iso2": "CM",
    "iso3": "CMR",
    "admin_name": "Adamaoua",
    "capital": "",
    "population": 80100,
    "id": 1120789508
  },
  {
    "city": "Tall ‘Afar",
    "city_ascii": "Tall `Afar",
    "lat": 36.3792,
    "lng": 42.4497,
    "country": "Iraq",
    "iso2": "IQ",
    "iso3": "IRQ",
    "admin_name": "Nīnawá",
    "capital": "minor",
    "population": 80000,
    "id": 1368564375
  },
  {
    "city": "Balad",
    "city_ascii": "Balad",
    "lat": 34.0147,
    "lng": 44.1456,
    "country": "Iraq",
    "iso2": "IQ",
    "iso3": "IRQ",
    "admin_name": "Şalāḩ ad Dīn",
    "capital": "minor",
    "population": 80000,
    "id": 1368077707
  },
  {
    "city": "Vila do Conde",
    "city_ascii": "Vila do Conde",
    "lat": 41.35,
    "lng": -8.75,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Porto",
    "capital": "minor",
    "population": 79533,
    "id": 1620584469
  },
  {
    "city": "Sokcho",
    "city_ascii": "Sokcho",
    "lat": 38.2083,
    "lng": 128.5911,
    "country": "Korea, South",
    "iso2": "KR",
    "iso3": "KOR",
    "admin_name": "Gangwon",
    "capital": "minor",
    "population": 79846,
    "id": 1410921315
  },
  {
    "city": "San Carlos del Zulia",
    "city_ascii": "San Carlos del Zulia",
    "lat": 9,
    "lng": -71.95,
    "country": "Venezuela",
    "iso2": "VE",
    "iso3": "VEN",
    "admin_name": "Zulia",
    "capital": "minor",
    "population": 80000,
    "id": 1862219492
  },
  {
    "city": "Koktokay",
    "city_ascii": "Koktokay",
    "lat": 47.0004,
    "lng": 89.4666,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Xinjiang",
    "capital": "minor",
    "population": 80000,
    "id": 1156023872
  },
  {
    "city": "Capenda Camulemba",
    "city_ascii": "Capenda Camulemba",
    "lat": -9.4196,
    "lng": 18.43,
    "country": "Angola",
    "iso2": "AO",
    "iso3": "AGO",
    "admin_name": "Lunda Norte",
    "capital": "",
    "population": 80000,
    "id": 1024864144
  },
  {
    "city": "Pátzcuaro",
    "city_ascii": "Patzcuaro",
    "lat": 19.5164,
    "lng": -101.6097,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Michoacán de Ocampo",
    "capital": "minor",
    "population": 79868,
    "id": 1484039505
  },
  {
    "city": "Florence",
    "city_ascii": "Florence",
    "lat": 34.8303,
    "lng": -87.6654,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 79964,
    "id": 1840002083
  },
  {
    "city": "Chester",
    "city_ascii": "Chester",
    "lat": 53.19,
    "lng": -2.89,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Cheshire West and Chester",
    "capital": "",
    "population": 79645,
    "id": 1826441668
  },
  {
    "city": "Baracoa",
    "city_ascii": "Baracoa",
    "lat": 20.3444,
    "lng": -74.4956,
    "country": "Cuba",
    "iso2": "CU",
    "iso3": "CUB",
    "admin_name": "Guantánamo",
    "capital": "minor",
    "population": 79797,
    "id": 1192326052
  },
  {
    "city": "Valdosta",
    "city_ascii": "Valdosta",
    "lat": 30.8502,
    "lng": -83.2788,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 79820,
    "id": 1840015894
  },
  {
    "city": "Plymouth",
    "city_ascii": "Plymouth",
    "lat": 45.0225,
    "lng": -93.4618,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 79768,
    "id": 1840008929
  },
  {
    "city": "Yalta",
    "city_ascii": "Yalta",
    "lat": 44.4994,
    "lng": 34.1553,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Krym, Avtonomna Respublika",
    "capital": "",
    "population": 79458,
    "id": 1804569428
  },
  {
    "city": "Cotuí",
    "city_ascii": "Cotui",
    "lat": 19.06,
    "lng": -70.15,
    "country": "Dominican Republic",
    "iso2": "DO",
    "iso3": "DOM",
    "admin_name": "Cibao Sur",
    "capital": "minor",
    "population": 79596,
    "id": 1214299029
  },
  {
    "city": "Torres Vedras",
    "city_ascii": "Torres Vedras",
    "lat": 39.0833,
    "lng": -9.2667,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Lisboa",
    "capital": "minor",
    "population": 79465,
    "id": 1620003589
  },
  {
    "city": "Salmās",
    "city_ascii": "Salmas",
    "lat": 38.2,
    "lng": 44.7667,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Āz̄arbāyjān-e Gharbī",
    "capital": "minor",
    "population": 79560,
    "id": 1364204147
  },
  {
    "city": "Buckeye",
    "city_ascii": "Buckeye",
    "lat": 33.4314,
    "lng": -112.6429,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 79620,
    "id": 1840021939
  },
  {
    "city": "Georgetown",
    "city_ascii": "Georgetown",
    "lat": 30.6668,
    "lng": -97.6953,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 79604,
    "id": 1840020875
  },
  {
    "city": "Natori-shi",
    "city_ascii": "Natori-shi",
    "lat": 38.1717,
    "lng": 140.8919,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Miyagi",
    "capital": "",
    "population": 78796,
    "id": 1392168361
  },
  {
    "city": "Mooka",
    "city_ascii": "Mooka",
    "lat": 36.4403,
    "lng": 140.0131,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Tochigi",
    "capital": "",
    "population": 79109,
    "id": 1392952105
  },
  {
    "city": "Pingyi",
    "city_ascii": "Pingyi",
    "lat": 35.5104,
    "lng": 117.62,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Shandong",
    "capital": "",
    "population": 78254,
    "id": 1156542248
  },
  {
    "city": "Yenakiieve",
    "city_ascii": "Yenakiieve",
    "lat": 48.2311,
    "lng": 38.2053,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Donets’ka Oblast’",
    "capital": "",
    "population": 79348,
    "id": 1804228533
  },
  {
    "city": "Kadoma",
    "city_ascii": "Kadoma",
    "lat": -18.35,
    "lng": 29.9167,
    "country": "Zimbabwe",
    "iso2": "ZW",
    "iso3": "ZWE",
    "admin_name": "Mashonaland West",
    "capital": "",
    "population": 79174,
    "id": 1716398005
  },
  {
    "city": "Tanuku",
    "city_ascii": "Tanuku",
    "lat": 16.75,
    "lng": 81.7,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Andhra Pradesh",
    "capital": "",
    "population": 77962,
    "id": 1356546052
  },
  {
    "city": "Cedar Park",
    "city_ascii": "Cedar Park",
    "lat": 30.5106,
    "lng": -97.8196,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 79462,
    "id": 1840019572
  },
  {
    "city": "Klin",
    "city_ascii": "Klin",
    "lat": 56.3339,
    "lng": 36.7125,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Moskovskaya Oblast’",
    "capital": "",
    "population": 79387,
    "id": 1643730409
  },
  {
    "city": "Ozërsk",
    "city_ascii": "Ozersk",
    "lat": 55.7556,
    "lng": 60.7028,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Chelyabinskaya Oblast’",
    "capital": "",
    "population": 79265,
    "id": 1643881437
  },
  {
    "city": "Villa María",
    "city_ascii": "Villa Maria",
    "lat": -32.4103,
    "lng": -63.2314,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Córdoba",
    "capital": "minor",
    "population": 79356,
    "id": 1032987787
  },
  {
    "city": "Kokomo",
    "city_ascii": "Kokomo",
    "lat": 40.464,
    "lng": -86.1277,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 79351,
    "id": 1840013844
  },
  {
    "city": "Perris",
    "city_ascii": "Perris",
    "lat": 33.7899,
    "lng": -117.2233,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 79291,
    "id": 1840020557
  },
  {
    "city": "Huaral",
    "city_ascii": "Huaral",
    "lat": -11.5,
    "lng": -77.2167,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "Lima",
    "capital": "",
    "population": 79011,
    "id": 1604962520
  },
  {
    "city": "St. Joseph",
    "city_ascii": "St. Joseph",
    "lat": 39.7599,
    "lng": -94.821,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 79161,
    "id": 1840009549
  },
  {
    "city": "Agua Prieta",
    "city_ascii": "Agua Prieta",
    "lat": 31.3258,
    "lng": -109.5489,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Sonora",
    "capital": "minor",
    "population": 79138,
    "id": 1484381848
  },
  {
    "city": "Flower Mound",
    "city_ascii": "Flower Mound",
    "lat": 33.0344,
    "lng": -97.1146,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 79135,
    "id": 1840022010
  },
  {
    "city": "Pharr",
    "city_ascii": "Pharr",
    "lat": 26.1685,
    "lng": -98.1904,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 79112,
    "id": 1840021030
  },
  {
    "city": "Atascocita",
    "city_ascii": "Atascocita",
    "lat": 29.9777,
    "lng": -95.1953,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 79019,
    "id": 1840018251
  },
  {
    "city": "Alton",
    "city_ascii": "Alton",
    "lat": 38.9034,
    "lng": -90.1523,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 78905,
    "id": 1840007424
  },
  {
    "city": "Bamberg",
    "city_ascii": "Bamberg",
    "lat": 49.8917,
    "lng": 10.8917,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "minor",
    "population": 77592,
    "id": 1276824274
  },
  {
    "city": "Loveland",
    "city_ascii": "Loveland",
    "lat": 40.4166,
    "lng": -105.0623,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 78877,
    "id": 1840020152
  },
  {
    "city": "Bogo",
    "city_ascii": "Bogo",
    "lat": 11.0167,
    "lng": 124,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Cebu",
    "capital": "",
    "population": 78120,
    "id": 1608673076
  },
  {
    "city": "Subulussalam",
    "city_ascii": "Subulussalam",
    "lat": 2.6422,
    "lng": 98.0042,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Aceh",
    "capital": "",
    "population": 78801,
    "id": 1360306114
  },
  {
    "city": "Delmenhorst",
    "city_ascii": "Delmenhorst",
    "lat": 53.0506,
    "lng": 8.6317,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "minor",
    "population": 77607,
    "id": 1276098664
  },
  {
    "city": "Essaouira",
    "city_ascii": "Essaouira",
    "lat": 31.513,
    "lng": -9.7687,
    "country": "Morocco",
    "iso2": "MA",
    "iso3": "MAR",
    "admin_name": "Marrakech-Safi",
    "capital": "",
    "population": 77966,
    "id": 1504845522
  },
  {
    "city": "Nikkō",
    "city_ascii": "Nikko",
    "lat": 36.7198,
    "lng": 139.6982,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Tochigi",
    "capital": "",
    "population": 78768,
    "id": 1392782537
  },
  {
    "city": "Rockhampton",
    "city_ascii": "Rockhampton",
    "lat": -23.375,
    "lng": 150.5117,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Queensland",
    "capital": "",
    "population": 78592,
    "id": 1036768986
  },
  {
    "city": "Alīgūdarz",
    "city_ascii": "Aligudarz",
    "lat": 33.4006,
    "lng": 49.6947,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Lorestān",
    "capital": "minor",
    "population": 78690,
    "id": 1364252948
  },
  {
    "city": "Dondo",
    "city_ascii": "Dondo",
    "lat": -19.6167,
    "lng": 34.75,
    "country": "Mozambique",
    "iso2": "MZ",
    "iso3": "MOZ",
    "admin_name": "Sofala",
    "capital": "",
    "population": 78648,
    "id": 1508741848
  },
  {
    "city": "Boynton Beach",
    "city_ascii": "Boynton Beach",
    "lat": 26.5281,
    "lng": -80.0811,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 78679,
    "id": 1840014208
  },
  {
    "city": "Ban Laem Chabang",
    "city_ascii": "Ban Laem Chabang",
    "lat": 13.086,
    "lng": 100.9247,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Chon Buri",
    "capital": "",
    "population": 77798,
    "id": 1764108164
  },
  {
    "city": "Tela",
    "city_ascii": "Tela",
    "lat": 15.7833,
    "lng": -87.4667,
    "country": "Honduras",
    "iso2": "HN",
    "iso3": "HND",
    "admin_name": "Atlántida",
    "capital": "",
    "population": 78537,
    "id": 1340789148
  },
  {
    "city": "Anderson",
    "city_ascii": "Anderson",
    "lat": 34.5211,
    "lng": -82.6479,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 78512,
    "id": 1840013579
  },
  {
    "city": "Vyborg",
    "city_ascii": "Vyborg",
    "lat": 60.7092,
    "lng": 28.7442,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Leningradskaya Oblast’",
    "capital": "",
    "population": 78457,
    "id": 1643778472
  },
  {
    "city": "Manbij",
    "city_ascii": "Manbij",
    "lat": 36.5333,
    "lng": 37.95,
    "country": "Syria",
    "iso2": "SY",
    "iso3": "SYR",
    "admin_name": "Ḩalab",
    "capital": "minor",
    "population": 78255,
    "id": 1760355581
  },
  {
    "city": "Usol’ye-Sibirskoye",
    "city_ascii": "Usol'ye-Sibirskoye",
    "lat": 52.75,
    "lng": 103.65,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Irkutskaya Oblast’",
    "capital": "",
    "population": 77407,
    "id": 1643895073
  },
  {
    "city": "Toyooka",
    "city_ascii": "Toyooka",
    "lat": 35.5333,
    "lng": 134.8167,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hyōgo",
    "capital": "",
    "population": 78299,
    "id": 1392135908
  },
  {
    "city": "Kropotkin",
    "city_ascii": "Kropotkin",
    "lat": 45.4333,
    "lng": 40.5667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Krasnodarskiy Kray",
    "capital": "",
    "population": 78149,
    "id": 1643006243
  },
  {
    "city": "Maitland",
    "city_ascii": "Maitland",
    "lat": -32.7167,
    "lng": 151.55,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 78015,
    "id": 1036034945
  },
  {
    "city": "Marsala",
    "city_ascii": "Marsala",
    "lat": 37.8054,
    "lng": 12.4387,
    "country": "Italy",
    "iso2": "IT",
    "iso3": "ITA",
    "admin_name": "Sicilia",
    "capital": "",
    "population": 77784,
    "id": 1380770056
  },
  {
    "city": "Parma",
    "city_ascii": "Parma",
    "lat": 41.3843,
    "lng": -81.7286,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 78103,
    "id": 1840003406
  },
  {
    "city": "Bor",
    "city_ascii": "Bor",
    "lat": 56.3603,
    "lng": 44.0592,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Nizhegorodskaya Oblast’",
    "capital": "",
    "population": 78063,
    "id": 1643006538
  },
  {
    "city": "Bodhan",
    "city_ascii": "Bodhan",
    "lat": 18.67,
    "lng": 77.9,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Telangana",
    "capital": "",
    "population": 77573,
    "id": 1356506985
  },
  {
    "city": "Layton",
    "city_ascii": "Layton",
    "lat": 41.0769,
    "lng": -111.9621,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Utah",
    "capital": "",
    "population": 78014,
    "id": 1840020145
  },
  {
    "city": "Balqash",
    "city_ascii": "Balqash",
    "lat": 46.85,
    "lng": 75,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Qaraghandy",
    "capital": "",
    "population": 78002,
    "id": 1398134106
  },
  {
    "city": "Tsubame",
    "city_ascii": "Tsubame",
    "lat": 37.6667,
    "lng": 138.9667,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Niigata",
    "capital": "",
    "population": 77307,
    "id": 1392932108
  },
  {
    "city": "Villa Victoria",
    "city_ascii": "Villa Victoria",
    "lat": 19.4333,
    "lng": -100,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "México",
    "capital": "minor",
    "population": 77819,
    "id": 1484528358
  },
  {
    "city": "Elizabethtown",
    "city_ascii": "Elizabethtown",
    "lat": 37.703,
    "lng": -85.877,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kentucky",
    "capital": "",
    "population": 78002,
    "id": 1840013218
  },
  {
    "city": "Béziers",
    "city_ascii": "Beziers",
    "lat": 43.3476,
    "lng": 3.219,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Occitanie",
    "capital": "minor",
    "population": 77177,
    "id": 1250666824
  },
  {
    "city": "Daisen",
    "city_ascii": "Daisen",
    "lat": 39.4531,
    "lng": 140.4756,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Akita",
    "capital": "",
    "population": 77886,
    "id": 1392642502
  },
  {
    "city": "Texarkana",
    "city_ascii": "Texarkana",
    "lat": 33.4487,
    "lng": -94.0815,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 77964,
    "id": 1840021987
  },
  {
    "city": "Toyomamachi-teraike",
    "city_ascii": "Toyomamachi-teraike",
    "lat": 38.6919,
    "lng": 141.1878,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Miyagi",
    "capital": "",
    "population": 77795,
    "id": 1392470544
  },
  {
    "city": "Linkou",
    "city_ascii": "Linkou",
    "lat": 45.2819,
    "lng": 130.2519,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Heilongjiang",
    "capital": "minor",
    "population": 77754,
    "id": 1156383623
  },
  {
    "city": "Honjō",
    "city_ascii": "Honjo",
    "lat": 36.2439,
    "lng": 139.1903,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Saitama",
    "capital": "",
    "population": 77045,
    "id": 1392850139
  },
  {
    "city": "Numan",
    "city_ascii": "Numan",
    "lat": 9.4669,
    "lng": 12.0328,
    "country": "Nigeria",
    "iso2": "NG",
    "iso3": "NGA",
    "admin_name": "Adamawa",
    "capital": "minor",
    "population": 77617,
    "id": 1566815415
  },
  {
    "city": "Ciudad de Huajuapam de León",
    "city_ascii": "Ciudad de Huajuapam de Leon",
    "lat": 17.8039,
    "lng": -97.7758,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Oaxaca",
    "capital": "minor",
    "population": 77547,
    "id": 1484375272
  },
  {
    "city": "Viersen",
    "city_ascii": "Viersen",
    "lat": 51.2556,
    "lng": 6.3917,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "minor",
    "population": 76905,
    "id": 1276629059
  },
  {
    "city": "Bebedouro",
    "city_ascii": "Bebedouro",
    "lat": -20.9494,
    "lng": -48.4792,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 77627,
    "id": 1076045128
  },
  {
    "city": "Ryūgasaki",
    "city_ascii": "Ryugasaki",
    "lat": 35.9167,
    "lng": 140.1833,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Ibaraki",
    "capital": "",
    "population": 76741,
    "id": 1392150237
  },
  {
    "city": "Guildford",
    "city_ascii": "Guildford",
    "lat": 51.2365,
    "lng": -0.5703,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Surrey",
    "capital": "",
    "population": 77057,
    "id": 1826863533
  },
  {
    "city": "Sint-Niklaas",
    "city_ascii": "Sint-Niklaas",
    "lat": 51.1644,
    "lng": 4.1392,
    "country": "Belgium",
    "iso2": "BE",
    "iso3": "BEL",
    "admin_name": "Flanders",
    "capital": "minor",
    "population": 76756,
    "id": 1056028982
  },
  {
    "city": "Karūr",
    "city_ascii": "Karur",
    "lat": 10.9504,
    "lng": 78.0833,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Tamil Nādu",
    "capital": "",
    "population": 76915,
    "id": 1356837900
  },
  {
    "city": "Armant",
    "city_ascii": "Armant",
    "lat": 25.6167,
    "lng": 32.5333,
    "country": "Egypt",
    "iso2": "EG",
    "iso3": "EGY",
    "admin_name": "Qinā",
    "capital": "",
    "population": 76457,
    "id": 1818701870
  },
  {
    "city": "Zhlobin",
    "city_ascii": "Zhlobin",
    "lat": 52.9,
    "lng": 30.0333,
    "country": "Belarus",
    "iso2": "BY",
    "iso3": "BLR",
    "admin_name": "Homyel’skaya Voblasts’",
    "capital": "minor",
    "population": 77600,
    "id": 1112494264
  },
  {
    "city": "Bakhmut",
    "city_ascii": "Bakhmut",
    "lat": 48.6044,
    "lng": 38.0067,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Donets’ka Oblast’",
    "capital": "minor",
    "population": 75798,
    "id": 1804007616
  },
  {
    "city": "The Villages",
    "city_ascii": "The Villages",
    "lat": 28.9021,
    "lng": -81.9888,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 77609,
    "id": 1840014065
  },
  {
    "city": "Pattoki",
    "city_ascii": "Pattoki",
    "lat": 31.0214,
    "lng": 73.8528,
    "country": "Pakistan",
    "iso2": "PK",
    "iso3": "PAK",
    "admin_name": "Punjab",
    "capital": "",
    "population": 77210,
    "id": 1586235509
  },
  {
    "city": "Marburg",
    "city_ascii": "Marburg",
    "lat": 50.8167,
    "lng": 8.7667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "minor",
    "population": 76851,
    "id": 1276012023
  },
  {
    "city": "Söke",
    "city_ascii": "Soke",
    "lat": 37.7512,
    "lng": 27.4103,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Aydın",
    "capital": "minor",
    "population": 77341,
    "id": 1792512916
  },
  {
    "city": "Kankakee",
    "city_ascii": "Kankakee",
    "lat": 41.1019,
    "lng": -87.8643,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 77429,
    "id": 1840008260
  },
  {
    "city": "Ende",
    "city_ascii": "Ende",
    "lat": -8.8333,
    "lng": 121.65,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Nusa Tenggara Timur",
    "capital": "minor",
    "population": 77205,
    "id": 1360796329
  },
  {
    "city": "Porterville",
    "city_ascii": "Porterville",
    "lat": 36.0644,
    "lng": -119.0338,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 77318,
    "id": 1840020368
  },
  {
    "city": "Harunabad",
    "city_ascii": "Harunabad",
    "lat": 29.61,
    "lng": 73.1361,
    "country": "Pakistan",
    "iso2": "PK",
    "iso3": "PAK",
    "admin_name": "Punjab",
    "capital": "",
    "population": 77206,
    "id": 1586810051
  },
  {
    "city": "Gelendzhik",
    "city_ascii": "Gelendzhik",
    "lat": 44.5608,
    "lng": 38.0767,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Krasnodarskiy Kray",
    "capital": "",
    "population": 77212,
    "id": 1643756962
  },
  {
    "city": "Chernogorsk",
    "city_ascii": "Chernogorsk",
    "lat": 53.8167,
    "lng": 91.2833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Khakasiya",
    "capital": "",
    "population": 75419,
    "id": 1643871191
  },
  {
    "city": "Balkh",
    "city_ascii": "Balkh",
    "lat": 36.7581,
    "lng": 66.8989,
    "country": "Afghanistan",
    "iso2": "AF",
    "iso3": "AFG",
    "admin_name": "Balkh",
    "capital": "minor",
    "population": 77000,
    "id": 1004654094
  },
  {
    "city": "Baytown",
    "city_ascii": "Baytown",
    "lat": 29.7586,
    "lng": -94.9669,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 77192,
    "id": 1840019616
  },
  {
    "city": "Upland",
    "city_ascii": "Upland",
    "lat": 34.1178,
    "lng": -117.6603,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 77140,
    "id": 1840021730
  },
  {
    "city": "Siedlce",
    "city_ascii": "Siedlce",
    "lat": 52.1833,
    "lng": 22.2833,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Mazowieckie",
    "capital": "minor",
    "population": 76585,
    "id": 1616939180
  },
  {
    "city": "Al Musayyib",
    "city_ascii": "Al Musayyib",
    "lat": 32.7786,
    "lng": 44.29,
    "country": "Iraq",
    "iso2": "IQ",
    "iso3": "IRQ",
    "admin_name": "Bābil",
    "capital": "minor",
    "population": 76454,
    "id": 1368000434
  },
  {
    "city": "Pyapon",
    "city_ascii": "Pyapon",
    "lat": 16.2853,
    "lng": 95.6786,
    "country": "Burma",
    "iso2": "MM",
    "iso3": "MMR",
    "admin_name": "Ayeyawady",
    "capital": "",
    "population": 76741,
    "id": 1104170402
  },
  {
    "city": "Caserta",
    "city_ascii": "Caserta",
    "lat": 41.0667,
    "lng": 14.3333,
    "country": "Italy",
    "iso2": "IT",
    "iso3": "ITA",
    "admin_name": "Campania",
    "capital": "minor",
    "population": 75561,
    "id": 1380614087
  },
  {
    "city": "Mafra",
    "city_ascii": "Mafra",
    "lat": 38.9333,
    "lng": -9.3333,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Lisboa",
    "capital": "minor",
    "population": 76685,
    "id": 1620095714
  },
  {
    "city": "Camarillo",
    "city_ascii": "Camarillo",
    "lat": 34.223,
    "lng": -119.0322,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 76931,
    "id": 1840019201
  },
  {
    "city": "Sarqan",
    "city_ascii": "Sarqan",
    "lat": 45.4203,
    "lng": 79.9149,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Almaty",
    "capital": "",
    "population": 76919,
    "id": 1398159801
  },
  {
    "city": "Dschang",
    "city_ascii": "Dschang",
    "lat": 5.45,
    "lng": 10.05,
    "country": "Cameroon",
    "iso2": "CM",
    "iso3": "CMR",
    "admin_name": "Ouest",
    "capital": "",
    "population": 76524,
    "id": 1120569504
  },
  {
    "city": "Tuban",
    "city_ascii": "Tuban",
    "lat": -6.8995,
    "lng": 112.05,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Jawa Timur",
    "capital": "minor",
    "population": 76242,
    "id": 1360482232
  },
  {
    "city": "Fukuchiyama",
    "city_ascii": "Fukuchiyama",
    "lat": 35.2967,
    "lng": 135.1264,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Kyōto",
    "capital": "",
    "population": 76677,
    "id": 1392295524
  },
  {
    "city": "Balashov",
    "city_ascii": "Balashov",
    "lat": 51.55,
    "lng": 43.1667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Saratovskaya Oblast’",
    "capital": "",
    "population": 76776,
    "id": 1643009402
  },
  {
    "city": "Wilhelmshaven",
    "city_ascii": "Wilhelmshaven",
    "lat": 53.5167,
    "lng": 8.1333,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "minor",
    "population": 76097,
    "id": 1276324633
  },
  {
    "city": "Shostka",
    "city_ascii": "Shostka",
    "lat": 51.8657,
    "lng": 33.4766,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Sums’ka Oblast’",
    "capital": "minor",
    "population": 76788,
    "id": 1804764606
  },
  {
    "city": "Jablah",
    "city_ascii": "Jablah",
    "lat": 35.3597,
    "lng": 35.9214,
    "country": "Syria",
    "iso2": "SY",
    "iso3": "SYR",
    "admin_name": "Al Lādhiqīyah",
    "capital": "minor",
    "population": 75505,
    "id": 1760685267
  },
  {
    "city": "Asti",
    "city_ascii": "Asti",
    "lat": 44.9,
    "lng": 8.2069,
    "country": "Italy",
    "iso2": "IT",
    "iso3": "ITA",
    "admin_name": "Piedmont",
    "capital": "minor",
    "population": 76173,
    "id": 1380024585
  },
  {
    "city": "E’erguna",
    "city_ascii": "E'erguna",
    "lat": 50.2411,
    "lng": 120.172,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Inner Mongolia",
    "capital": "minor",
    "population": 76667,
    "id": 1156538056
  },
  {
    "city": "Rheine",
    "city_ascii": "Rheine",
    "lat": 52.2833,
    "lng": 7.4333,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 76107,
    "id": 1276882978
  },
  {
    "city": "South Jordan",
    "city_ascii": "South Jordan",
    "lat": 40.557,
    "lng": -111.9782,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Utah",
    "capital": "",
    "population": 76598,
    "id": 1840021385
  },
  {
    "city": "Palma Soriano",
    "city_ascii": "Palma Soriano",
    "lat": 20.2139,
    "lng": -75.9919,
    "country": "Cuba",
    "iso2": "CU",
    "iso3": "CUB",
    "admin_name": "Santiago de Cuba",
    "capital": "minor",
    "population": 76500,
    "id": 1192323011
  },
  {
    "city": "Stakhanov",
    "city_ascii": "Stakhanov",
    "lat": 48.5472,
    "lng": 38.6361,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Luhans’ka Oblast’",
    "capital": "",
    "population": 75745,
    "id": 1804324480
  },
  {
    "city": "Nakatsugawa",
    "city_ascii": "Nakatsugawa",
    "lat": 35.4875,
    "lng": 137.5006,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Gifu",
    "capital": "",
    "population": 76442,
    "id": 1392764709
  },
  {
    "city": "Bais",
    "city_ascii": "Bais",
    "lat": 9.5907,
    "lng": 123.1213,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Negros Oriental",
    "capital": "",
    "population": 76291,
    "id": 1608298426
  },
  {
    "city": "Battle Creek",
    "city_ascii": "Battle Creek",
    "lat": 42.2986,
    "lng": -85.2296,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 76514,
    "id": 1840003176
  },
  {
    "city": "Ibiúna",
    "city_ascii": "Ibiuna",
    "lat": -23.6564,
    "lng": -47.2225,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 76432,
    "id": 1076173752
  },
  {
    "city": "Venado Tuerto",
    "city_ascii": "Venado Tuerto",
    "lat": -33.75,
    "lng": -61.9667,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Santa Fe",
    "capital": "",
    "population": 76432,
    "id": 1032284763
  },
  {
    "city": "Sangolquí",
    "city_ascii": "Sangolqui",
    "lat": -0.3344,
    "lng": -78.4475,
    "country": "Ecuador",
    "iso2": "EC",
    "iso3": "ECU",
    "admin_name": "Pichincha",
    "capital": "",
    "population": 75080,
    "id": 1218715616
  },
  {
    "city": "Lívingston",
    "city_ascii": "Livingston",
    "lat": 15.83,
    "lng": -88.75,
    "country": "Guatemala",
    "iso2": "GT",
    "iso3": "GTM",
    "admin_name": "Izabal",
    "capital": "minor",
    "population": 76310,
    "id": 1320970605
  },
  {
    "city": "Mysłowice",
    "city_ascii": "Myslowice",
    "lat": 50.2333,
    "lng": 19.1333,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Śląskie",
    "capital": "minor",
    "population": 75183,
    "id": 1616757185
  },
  {
    "city": "Berdychiv",
    "city_ascii": "Berdychiv",
    "lat": 49.8919,
    "lng": 28.6,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Zhytomyrs’ka Oblast’",
    "capital": "minor",
    "population": 76229,
    "id": 1804788404
  },
  {
    "city": "Jonesboro",
    "city_ascii": "Jonesboro",
    "lat": 35.8212,
    "lng": -90.6791,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arkansas",
    "capital": "",
    "population": 76247,
    "id": 1840014507
  },
  {
    "city": "Lins",
    "city_ascii": "Lins",
    "lat": -21.6786,
    "lng": -49.7425,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 76092,
    "id": 1076798294
  },
  {
    "city": "San Marcos",
    "city_ascii": "San Marcos",
    "lat": 29.8736,
    "lng": -97.9381,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 76218,
    "id": 1840022188
  },
  {
    "city": "Lod",
    "city_ascii": "Lod",
    "lat": 31.95,
    "lng": 34.9,
    "country": "Israel",
    "iso2": "IL",
    "iso3": "ISR",
    "admin_name": "Central",
    "capital": "",
    "population": 75700,
    "id": 1376929543
  },
  {
    "city": "Troisdorf",
    "city_ascii": "Troisdorf",
    "lat": 50.8161,
    "lng": 7.1556,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 74903,
    "id": 1276488227
  },
  {
    "city": "Sasagawa",
    "city_ascii": "Sasagawa",
    "lat": 37.2867,
    "lng": 140.3728,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Fukushima",
    "capital": "",
    "population": 75822,
    "id": 1392742964
  },
  {
    "city": "Piotrków Trybunalski",
    "city_ascii": "Piotrkow Trybunalski",
    "lat": 51.4,
    "lng": 19.6833,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Łódzkie",
    "capital": "minor",
    "population": 74905,
    "id": 1616450789
  },
  {
    "city": "Tailai",
    "city_ascii": "Tailai",
    "lat": 46.3904,
    "lng": 123.41,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Heilongjiang",
    "capital": "minor",
    "population": 75992,
    "id": 1156294977
  },
  {
    "city": "San Ramon",
    "city_ascii": "San Ramon",
    "lat": 37.7625,
    "lng": -121.9365,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 75995,
    "id": 1840021530
  },
  {
    "city": "Toba Tek Singh",
    "city_ascii": "Toba Tek Singh",
    "lat": 30.9667,
    "lng": 72.4833,
    "country": "Pakistan",
    "iso2": "PK",
    "iso3": "PAK",
    "admin_name": "Punjab",
    "capital": "minor",
    "population": 75943,
    "id": 1586875835
  },
  {
    "city": "Jabuticabal",
    "city_ascii": "Jabuticabal",
    "lat": -21.255,
    "lng": -48.3219,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 75820,
    "id": 1076368201
  },
  {
    "city": "Ovalle",
    "city_ascii": "Ovalle",
    "lat": -30.6031,
    "lng": -71.203,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Coquimbo",
    "capital": "minor",
    "population": 75864,
    "id": 1152767334
  },
  {
    "city": "Kengtung",
    "city_ascii": "Kengtung",
    "lat": 21.2914,
    "lng": 99.6039,
    "country": "Burma",
    "iso2": "MM",
    "iso3": "MMR",
    "admin_name": "Shan State",
    "capital": "",
    "population": 75865,
    "id": 1104395820
  },
  {
    "city": "Lake Jackson",
    "city_ascii": "Lake Jackson",
    "lat": 29.0516,
    "lng": -95.4522,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 75860,
    "id": 1840020980
  },
  {
    "city": "Bethlehem",
    "city_ascii": "Bethlehem",
    "lat": 40.6266,
    "lng": -75.3679,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 75815,
    "id": 1840000986
  },
  {
    "city": "Gandía",
    "city_ascii": "Gandia",
    "lat": 38.9667,
    "lng": -0.1822,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Valencia",
    "capital": "",
    "population": 74562,
    "id": 1724131747
  },
  {
    "city": "Shadrinsk",
    "city_ascii": "Shadrinsk",
    "lat": 56.0833,
    "lng": 63.6333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kurganskaya Oblast’",
    "capital": "",
    "population": 75348,
    "id": 1643030252
  },
  {
    "city": "Bayreuth",
    "city_ascii": "Bayreuth",
    "lat": 49.9481,
    "lng": 11.5783,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "minor",
    "population": 74657,
    "id": 1276275069
  },
  {
    "city": "Tatebayashi",
    "city_ascii": "Tatebayashi",
    "lat": 36.245,
    "lng": 139.5422,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Gunma",
    "capital": "",
    "population": 74520,
    "id": 1392730385
  },
  {
    "city": "Wyoming",
    "city_ascii": "Wyoming",
    "lat": 42.8909,
    "lng": -85.7066,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 75667,
    "id": 1840000374
  },
  {
    "city": "Dubna",
    "city_ascii": "Dubna",
    "lat": 56.75,
    "lng": 37.15,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Moskovskaya Oblast’",
    "capital": "",
    "population": 75144,
    "id": 1643266302
  },
  {
    "city": "Oshkosh",
    "city_ascii": "Oshkosh",
    "lat": 44.0228,
    "lng": -88.5619,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 75536,
    "id": 1840002246
  },
  {
    "city": "Bonāb",
    "city_ascii": "Bonab",
    "lat": 37.3403,
    "lng": 46.0561,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Āz̄arbāyjān-e Sharqī",
    "capital": "minor",
    "population": 75332,
    "id": 1364781552
  },
  {
    "city": "Hammond",
    "city_ascii": "Hammond",
    "lat": 41.6169,
    "lng": -87.491,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 75522,
    "id": 1840007092
  },
  {
    "city": "Yurihonjō",
    "city_ascii": "Yurihonjo",
    "lat": 39.3858,
    "lng": 140.0489,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Akita",
    "capital": "",
    "population": 75417,
    "id": 1392820312
  },
  {
    "city": "Missouri City",
    "city_ascii": "Missouri City",
    "lat": 29.563,
    "lng": -95.5365,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 75457,
    "id": 1840020950
  },
  {
    "city": "Lüneburg",
    "city_ascii": "Luneburg",
    "lat": 53.2525,
    "lng": 10.4144,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "minor",
    "population": 75351,
    "id": 1276001423
  },
  {
    "city": "Pasco",
    "city_ascii": "Pasco",
    "lat": 46.2506,
    "lng": -119.1304,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 75432,
    "id": 1840019888
  },
  {
    "city": "Mangalagiri",
    "city_ascii": "Mangalagiri",
    "lat": 16.43,
    "lng": 80.55,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Andhra Pradesh",
    "capital": "",
    "population": 73613,
    "id": 1356861607
  },
  {
    "city": "Kawartha Lakes",
    "city_ascii": "Kawartha Lakes",
    "lat": 44.35,
    "lng": -78.75,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 75423,
    "id": 1124000852
  },
  {
    "city": "Bombo",
    "city_ascii": "Bombo",
    "lat": 0.5833,
    "lng": 32.5333,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Luwero",
    "capital": "",
    "population": 75000,
    "id": 1800926068
  },
  {
    "city": "Wheeling",
    "city_ascii": "Wheeling",
    "lat": 40.0752,
    "lng": -80.6951,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "West Virginia",
    "capital": "",
    "population": 75392,
    "id": 1840005510
  },
  {
    "city": "Īṭahari̇̄",
    "city_ascii": "Itahari",
    "lat": 26.6667,
    "lng": 87.2833,
    "country": "Nepal",
    "iso2": "NP",
    "iso3": "NPL",
    "admin_name": "Kosī",
    "capital": "",
    "population": 74501,
    "id": 1524696654
  },
  {
    "city": "Tatsunochō-tominaga",
    "city_ascii": "Tatsunocho-tominaga",
    "lat": 34.8581,
    "lng": 134.5456,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hyōgo",
    "capital": "",
    "population": 74892,
    "id": 1392582566
  },
  {
    "city": "Valle Alto",
    "city_ascii": "Valle Alto",
    "lat": 25.5667,
    "lng": -100.25,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Nuevo León",
    "capital": "",
    "population": 75000,
    "id": 1484080034
  },
  {
    "city": "Standerton",
    "city_ascii": "Standerton",
    "lat": -26.95,
    "lng": 29.25,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "Mpumalanga",
    "capital": "",
    "population": 74021,
    "id": 1710222465
  },
  {
    "city": "Idah",
    "city_ascii": "Idah",
    "lat": 7.1104,
    "lng": 6.7399,
    "country": "Nigeria",
    "iso2": "NG",
    "iso3": "NGA",
    "admin_name": "Kogi",
    "capital": "minor",
    "population": 75087,
    "id": 1566566689
  },
  {
    "city": "Dorsten",
    "city_ascii": "Dorsten",
    "lat": 51.66,
    "lng": 6.9642,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 74736,
    "id": 1276397774
  },
  {
    "city": "Rancho Cordova",
    "city_ascii": "Rancho Cordova",
    "lat": 38.574,
    "lng": -121.2523,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 75087,
    "id": 1840020248
  },
  {
    "city": "Brick",
    "city_ascii": "Brick",
    "lat": 40.06,
    "lng": -74.1099,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 75072,
    "id": 1840081606
  },
  {
    "city": "Godē",
    "city_ascii": "Gode",
    "lat": 5.95,
    "lng": 43.45,
    "country": "Ethiopia",
    "iso2": "ET",
    "iso3": "ETH",
    "admin_name": "Sumalē",
    "capital": "",
    "population": 75000,
    "id": 1231128743
  },
  {
    "city": "Lamitan",
    "city_ascii": "Lamitan",
    "lat": 6.65,
    "lng": 122.1333,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Basilan",
    "capital": "",
    "population": 74782,
    "id": 1608647250
  },
  {
    "city": "Conway",
    "city_ascii": "Conway",
    "lat": 35.0754,
    "lng": -92.4694,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arkansas",
    "capital": "",
    "population": 74951,
    "id": 1840014598
  },
  {
    "city": "Gary",
    "city_ascii": "Gary",
    "lat": 41.5906,
    "lng": -87.3472,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 74879,
    "id": 1840008194
  },
  {
    "city": "Bunbury",
    "city_ascii": "Bunbury",
    "lat": -33.3333,
    "lng": 115.6333,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Western Australia",
    "capital": "",
    "population": 74363,
    "id": 1036561011
  },
  {
    "city": "Castrop-Rauxel",
    "city_ascii": "Castrop-Rauxel",
    "lat": 51.55,
    "lng": 7.3167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 73425,
    "id": 1276873073
  },
  {
    "city": "Altoona",
    "city_ascii": "Altoona",
    "lat": 40.5082,
    "lng": -78.4007,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 74829,
    "id": 1840001080
  },
  {
    "city": "Lodi",
    "city_ascii": "Lodi",
    "lat": 38.1218,
    "lng": -121.2932,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 74773,
    "id": 1840020263
  },
  {
    "city": "Arlington Heights",
    "city_ascii": "Arlington Heights",
    "lat": 42.0955,
    "lng": -87.9825,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 74760,
    "id": 1840011243
  },
  {
    "city": "Darhan",
    "city_ascii": "Darhan",
    "lat": 49.6167,
    "lng": 106.35,
    "country": "Mongolia",
    "iso2": "MN",
    "iso3": "MNG",
    "admin_name": "Selenge",
    "capital": "",
    "population": 74738,
    "id": 1496510995
  },
  {
    "city": "Shibukawa",
    "city_ascii": "Shibukawa",
    "lat": 36.4833,
    "lng": 139,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Gunma",
    "capital": "",
    "population": 74425,
    "id": 1392987761
  },
  {
    "city": "Nizhyn",
    "city_ascii": "Nizhyn",
    "lat": 51.05,
    "lng": 31.9,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Chernihivs’ka Oblast’",
    "capital": "minor",
    "population": 73283,
    "id": 1804714087
  },
  {
    "city": "Piraçununga",
    "city_ascii": "Piracununga",
    "lat": -21.9961,
    "lng": -47.4258,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 74587,
    "id": 1076467647
  },
  {
    "city": "Burnley",
    "city_ascii": "Burnley",
    "lat": 53.789,
    "lng": -2.248,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Lancashire",
    "capital": "",
    "population": 73021,
    "id": 1826565522
  },
  {
    "city": "Kasama",
    "city_ascii": "Kasama",
    "lat": 36.345,
    "lng": 140.3042,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Ibaraki",
    "capital": "",
    "population": 74334,
    "id": 1392308767
  },
  {
    "city": "Kendall",
    "city_ascii": "Kendall",
    "lat": 25.6697,
    "lng": -80.3556,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 74631,
    "id": 1840014244
  },
  {
    "city": "Grand-Bassam",
    "city_ascii": "Grand-Bassam",
    "lat": 5.2,
    "lng": -3.7333,
    "country": "Côte D’Ivoire",
    "iso2": "CI",
    "iso3": "CIV",
    "admin_name": "Comoé",
    "capital": "minor",
    "population": 73772,
    "id": 1384167408
  },
  {
    "city": "Waldorf",
    "city_ascii": "Waldorf",
    "lat": 38.6085,
    "lng": -76.9195,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 74587,
    "id": 1840006175
  },
  {
    "city": "Bolingbrook",
    "city_ascii": "Bolingbrook",
    "lat": 41.6903,
    "lng": -88.1019,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 74545,
    "id": 1840011482
  },
  {
    "city": "Inuyama",
    "city_ascii": "Inuyama",
    "lat": 35.3833,
    "lng": 136.95,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Aichi",
    "capital": "",
    "population": 73541,
    "id": 1392393169
  },
  {
    "city": "Rochester Hills",
    "city_ascii": "Rochester Hills",
    "lat": 42.6645,
    "lng": -83.1563,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 74516,
    "id": 1840002429
  },
  {
    "city": "Novoaltaysk",
    "city_ascii": "Novoaltaysk",
    "lat": 53.4,
    "lng": 83.9333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Altayskiy Kray",
    "capital": "",
    "population": 73439,
    "id": 1643896344
  },
  {
    "city": "Winchester",
    "city_ascii": "Winchester",
    "lat": 39.1735,
    "lng": -78.1746,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 74417,
    "id": 1840001623
  },
  {
    "city": "Framingham",
    "city_ascii": "Framingham",
    "lat": 42.3085,
    "lng": -71.4368,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 74416,
    "id": 1840003131
  },
  {
    "city": "Detmold",
    "city_ascii": "Detmold",
    "lat": 51.9378,
    "lng": 8.8833,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "minor",
    "population": 74388,
    "id": 1276802316
  },
  {
    "city": "Çınar",
    "city_ascii": "Cinar",
    "lat": 37.7256,
    "lng": 40.4147,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Diyarbakır",
    "capital": "minor",
    "population": 74207,
    "id": 1792038099
  },
  {
    "city": "Mineral’nyye Vody",
    "city_ascii": "Mineral'nyye Vody",
    "lat": 44.2008,
    "lng": 43.1125,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Stavropol’skiy Kray",
    "capital": "",
    "population": 74141,
    "id": 1643357328
  },
  {
    "city": "Kara-Balta",
    "city_ascii": "Kara-Balta",
    "lat": 42.8306,
    "lng": 73.8857,
    "country": "Kyrgyzstan",
    "iso2": "KG",
    "iso3": "KGZ",
    "admin_name": "Chüy",
    "capital": "minor",
    "population": 74133,
    "id": 1417201811
  },
  {
    "city": "Hilton Head Island",
    "city_ascii": "Hilton Head Island",
    "lat": 32.1896,
    "lng": -80.7499,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 74139,
    "id": 1840016974
  },
  {
    "city": "Sundsvall",
    "city_ascii": "Sundsvall",
    "lat": 62.4001,
    "lng": 17.3167,
    "country": "Sweden",
    "iso2": "SE",
    "iso3": "SWE",
    "admin_name": "Västernorrland",
    "capital": "minor",
    "population": 73389,
    "id": 1752024232
  },
  {
    "city": "Union City",
    "city_ascii": "Union City",
    "lat": 37.603,
    "lng": -122.0187,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 74107,
    "id": 1840021539
  },
  {
    "city": "Yelabuga",
    "city_ascii": "Yelabuga",
    "lat": 55.7667,
    "lng": 52.0333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Tatarstan",
    "capital": "",
    "population": 74031,
    "id": 1643438030
  },
  {
    "city": "San Ramón de la Nueva Orán",
    "city_ascii": "San Ramon de la Nueva Oran",
    "lat": -23.1361,
    "lng": -64.3222,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Salta",
    "capital": "minor",
    "population": 74059,
    "id": 1032020207
  },
  {
    "city": "Troitsk",
    "city_ascii": "Troitsk",
    "lat": 54.0833,
    "lng": 61.5667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Chelyabinskaya Oblast’",
    "capital": "",
    "population": 73911,
    "id": 1643374193
  },
  {
    "city": "Owensboro",
    "city_ascii": "Owensboro",
    "lat": 37.7574,
    "lng": -87.1173,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kentucky",
    "capital": "",
    "population": 74068,
    "id": 1840014340
  },
  {
    "city": "Jackson",
    "city_ascii": "Jackson",
    "lat": 35.6535,
    "lng": -88.8353,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 74065,
    "id": 1840014528
  },
  {
    "city": "Kairāna",
    "city_ascii": "Kairana",
    "lat": 29.4,
    "lng": 77.2,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Uttar Pradesh",
    "capital": "",
    "population": 73046,
    "id": 1356768694
  },
  {
    "city": "Riberalta",
    "city_ascii": "Riberalta",
    "lat": -10.983,
    "lng": -66.1,
    "country": "Bolivia",
    "iso2": "BO",
    "iso3": "BOL",
    "admin_name": "El Beni",
    "capital": "",
    "population": 74014,
    "id": 1068951603
  },
  {
    "city": "Arnsberg",
    "city_ascii": "Arnsberg",
    "lat": 51.3967,
    "lng": 8.0644,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 73628,
    "id": 1276285824
  },
  {
    "city": "Prince George",
    "city_ascii": "Prince George",
    "lat": 53.9169,
    "lng": -122.7494,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "British Columbia",
    "capital": "",
    "population": 74003,
    "id": 1124733292
  },
  {
    "city": "Kostiantynivka",
    "city_ascii": "Kostiantynivka",
    "lat": 48.5333,
    "lng": 37.7167,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Donets’ka Oblast’",
    "capital": "minor",
    "population": 72888,
    "id": 1804423855
  },
  {
    "city": "Ixmiquilpan",
    "city_ascii": "Ixmiquilpan",
    "lat": 20.4861,
    "lng": -99.2589,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Hidalgo",
    "capital": "minor",
    "population": 73903,
    "id": 1484683374
  },
  {
    "city": "Yegoryevsk",
    "city_ascii": "Yegoryevsk",
    "lat": 55.3667,
    "lng": 39.0167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Moskovskaya Oblast’",
    "capital": "",
    "population": 73766,
    "id": 1643266841
  },
  {
    "city": "Bawku",
    "city_ascii": "Bawku",
    "lat": 11.06,
    "lng": -0.2422,
    "country": "Ghana",
    "iso2": "GH",
    "iso3": "GHA",
    "admin_name": "Upper East",
    "capital": "",
    "population": 73594,
    "id": 1288067377
  },
  {
    "city": "Bonao",
    "city_ascii": "Bonao",
    "lat": 18.942,
    "lng": -70.409,
    "country": "Dominican Republic",
    "iso2": "DO",
    "iso3": "DOM",
    "admin_name": "Cibao Sur",
    "capital": "minor",
    "population": 73269,
    "id": 1214793175
  },
  {
    "city": "Ōtawara",
    "city_ascii": "Otawara",
    "lat": 36.8667,
    "lng": 140.0167,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Tochigi",
    "capital": "",
    "population": 73508,
    "id": 1392953593
  },
  {
    "city": "Tākestān",
    "city_ascii": "Takestan",
    "lat": 36.0694,
    "lng": 49.6958,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Qazvīn",
    "capital": "minor",
    "population": 73625,
    "id": 1364488107
  },
  {
    "city": "Linares",
    "city_ascii": "Linares",
    "lat": -35.8454,
    "lng": -71.5979,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Maule",
    "capital": "minor",
    "population": 73602,
    "id": 1152783281
  },
  {
    "city": "Shrewsbury",
    "city_ascii": "Shrewsbury",
    "lat": 52.708,
    "lng": -2.754,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Shropshire",
    "capital": "",
    "population": 71715,
    "id": 1826283137
  },
  {
    "city": "Cleveland",
    "city_ascii": "Cleveland",
    "lat": 35.1817,
    "lng": -84.8707,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 73596,
    "id": 1840014603
  },
  {
    "city": "Wausau",
    "city_ascii": "Wausau",
    "lat": 44.962,
    "lng": -89.6459,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 73593,
    "id": 1840002163
  },
  {
    "city": "Ostrowiec Świętokrzyski",
    "city_ascii": "Ostrowiec Swietokrzyski",
    "lat": 50.9333,
    "lng": 21.4,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Świętokrzyskie",
    "capital": "minor",
    "population": 72001,
    "id": 1616984527
  },
  {
    "city": "Dale City",
    "city_ascii": "Dale City",
    "lat": 38.6473,
    "lng": -77.3459,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 73545,
    "id": 1840006094
  },
  {
    "city": "Anniston",
    "city_ascii": "Anniston",
    "lat": 33.6712,
    "lng": -85.8136,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 73501,
    "id": 1840006563
  },
  {
    "city": "Lanxi",
    "city_ascii": "Lanxi",
    "lat": 46.2664,
    "lng": 126.276,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Heilongjiang",
    "capital": "minor",
    "population": 72528,
    "id": 1156269575
  },
  {
    "city": "Brakpan",
    "city_ascii": "Brakpan",
    "lat": -26.2353,
    "lng": 28.37,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "Gauteng",
    "capital": "",
    "population": 73080,
    "id": 1710291121
  },
  {
    "city": "Kirovo-Chepetsk",
    "city_ascii": "Kirovo-Chepetsk",
    "lat": 58.55,
    "lng": 50.0167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kirovskaya Oblast’",
    "capital": "",
    "population": 72071,
    "id": 1643630912
  },
  {
    "city": "Apple Valley",
    "city_ascii": "Apple Valley",
    "lat": 34.5328,
    "lng": -117.2104,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 73453,
    "id": 1840021733
  },
  {
    "city": "Ishioka",
    "city_ascii": "Ishioka",
    "lat": 36.1833,
    "lng": 140.2833,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Ibaraki",
    "capital": "",
    "population": 73110,
    "id": 1392188897
  },
  {
    "city": "Lüdenscheid",
    "city_ascii": "Ludenscheid",
    "lat": 51.2198,
    "lng": 7.6273,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "minor",
    "population": 72611,
    "id": 1276839426
  },
  {
    "city": "Vinhedo",
    "city_ascii": "Vinhedo",
    "lat": -23.03,
    "lng": -46.975,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 72550,
    "id": 1076383405
  },
  {
    "city": "Landshut",
    "city_ascii": "Landshut",
    "lat": 48.5397,
    "lng": 12.1508,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "minor",
    "population": 72404,
    "id": 1276485277
  },
  {
    "city": "Katori",
    "city_ascii": "Katori",
    "lat": 35.8978,
    "lng": 140.4992,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Chiba",
    "capital": "",
    "population": 73110,
    "id": 1392702737
  },
  {
    "city": "Sault Ste. Marie",
    "city_ascii": "Sault Ste. Marie",
    "lat": 46.5333,
    "lng": -84.35,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 73368,
    "id": 1124810690
  },
  {
    "city": "Nepālgañj",
    "city_ascii": "Nepalganj",
    "lat": 28.05,
    "lng": 81.6167,
    "country": "Nepal",
    "iso2": "NP",
    "iso3": "NPL",
    "admin_name": "Bherī",
    "capital": "",
    "population": 72503,
    "id": 1524207917
  },
  {
    "city": "Mansfield",
    "city_ascii": "Mansfield",
    "lat": 40.7656,
    "lng": -82.5275,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 73326,
    "id": 1840000960
  },
  {
    "city": "Tatvan",
    "city_ascii": "Tatvan",
    "lat": 38.5066,
    "lng": 42.2816,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Bitlis",
    "capital": "minor",
    "population": 73222,
    "id": 1792530559
  },
  {
    "city": "Cuamba",
    "city_ascii": "Cuamba",
    "lat": -14.82,
    "lng": 36.5486,
    "country": "Mozambique",
    "iso2": "MZ",
    "iso3": "MOZ",
    "admin_name": "Niassa",
    "capital": "",
    "population": 73268,
    "id": 1508862081
  },
  {
    "city": "Tokār",
    "city_ascii": "Tokar",
    "lat": 18.4333,
    "lng": 37.7333,
    "country": "Sudan",
    "iso2": "SD",
    "iso3": "SDN",
    "admin_name": "Red Sea",
    "capital": "",
    "population": 73202,
    "id": 1729512860
  },
  {
    "city": "Shakargarh",
    "city_ascii": "Shakargarh",
    "lat": 32.2628,
    "lng": 75.1583,
    "country": "Pakistan",
    "iso2": "PK",
    "iso3": "PAK",
    "admin_name": "Punjab",
    "capital": "",
    "population": 73160,
    "id": 1586086639
  },
  {
    "city": "Tan-Tan",
    "city_ascii": "Tan-Tan",
    "lat": 28.4333,
    "lng": -11.1,
    "country": "Morocco",
    "iso2": "MA",
    "iso3": "MAR",
    "admin_name": "Guelmim-Oued Noun",
    "capital": "",
    "population": 73209,
    "id": 1504164174
  },
  {
    "city": "Chapayevsk",
    "city_ascii": "Chapayevsk",
    "lat": 52.9833,
    "lng": 49.7167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Samarskaya Oblast’",
    "capital": "",
    "population": 72778,
    "id": 1643027172
  },
  {
    "city": "Itapira",
    "city_ascii": "Itapira",
    "lat": -22.4361,
    "lng": -46.8217,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 72967,
    "id": 1076706166
  },
  {
    "city": "Kalamáta",
    "city_ascii": "Kalamata",
    "lat": 37.0389,
    "lng": 22.1142,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Peloponnísos",
    "capital": "minor",
    "population": 71823,
    "id": 1300638148
  },
  {
    "city": "Vsevolozhsk",
    "city_ascii": "Vsevolozhsk",
    "lat": 60.016,
    "lng": 30.6663,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Leningradskaya Oblast’",
    "capital": "",
    "population": 72864,
    "id": 1643253701
  },
  {
    "city": "Montepuez",
    "city_ascii": "Montepuez",
    "lat": -13.1167,
    "lng": 39,
    "country": "Mozambique",
    "iso2": "MZ",
    "iso3": "MOZ",
    "admin_name": "Cabo Delgado",
    "capital": "",
    "population": 72279,
    "id": 1508552201
  },
  {
    "city": "Keshan",
    "city_ascii": "Keshan",
    "lat": 48.0263,
    "lng": 125.866,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Heilongjiang",
    "capital": "",
    "population": 72403,
    "id": 1156775094
  },
  {
    "city": "Belovo",
    "city_ascii": "Belovo",
    "lat": 54.4167,
    "lng": 86.3,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kemerovskaya Oblast’",
    "capital": "",
    "population": 72519,
    "id": 1643699718
  },
  {
    "city": "Schaumburg",
    "city_ascii": "Schaumburg",
    "lat": 42.0307,
    "lng": -88.0838,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 72887,
    "id": 1840011319
  },
  {
    "city": "Pocatello",
    "city_ascii": "Pocatello",
    "lat": 42.8724,
    "lng": -112.4646,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Idaho",
    "capital": "",
    "population": 72878,
    "id": 1840013376
  },
  {
    "city": "Três Corações",
    "city_ascii": "Tres Coracoes",
    "lat": -21.6947,
    "lng": -45.2553,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Minas Gerais",
    "capital": "",
    "population": 72765,
    "id": 1076131585
  },
  {
    "city": "Woodbury",
    "city_ascii": "Woodbury",
    "lat": 44.9056,
    "lng": -92.923,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 72828,
    "id": 1840009998
  },
  {
    "city": "Sotik",
    "city_ascii": "Sotik",
    "lat": -0.6796,
    "lng": 35.12,
    "country": "Kenya",
    "iso2": "KE",
    "iso3": "KEN",
    "admin_name": "Bomet",
    "capital": "",
    "population": 71285,
    "id": 1404807525
  },
  {
    "city": "Southfield",
    "city_ascii": "Southfield",
    "lat": 42.4765,
    "lng": -83.2605,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 72689,
    "id": 1840002431
  },
  {
    "city": "Yafran",
    "city_ascii": "Yafran",
    "lat": 32.0628,
    "lng": 12.5267,
    "country": "Libya",
    "iso2": "LY",
    "iso3": "LBY",
    "admin_name": "Al Jabal al Gharbī",
    "capital": "",
    "population": 72662,
    "id": 1434967497
  },
  {
    "city": "Maple Grove",
    "city_ascii": "Maple Grove",
    "lat": 45.1089,
    "lng": -93.4626,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 72622,
    "id": 1840008923
  },
  {
    "city": "Penafiel",
    "city_ascii": "Penafiel",
    "lat": 41.2,
    "lng": -8.2833,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Porto",
    "capital": "minor",
    "population": 72265,
    "id": 1620996272
  },
  {
    "city": "Pittsburg",
    "city_ascii": "Pittsburg",
    "lat": 38.0182,
    "lng": -121.8964,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 72588,
    "id": 1840020282
  },
  {
    "city": "Concepción del Uruguay",
    "city_ascii": "Concepcion del Uruguay",
    "lat": -32.4833,
    "lng": -58.2333,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Entre Ríos",
    "capital": "minor",
    "population": 72528,
    "id": 1032999059
  },
  {
    "city": "Morgantown",
    "city_ascii": "Morgantown",
    "lat": 39.638,
    "lng": -79.9468,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "West Virginia",
    "capital": "",
    "population": 72458,
    "id": 1840005647
  },
  {
    "city": "Brandenburg",
    "city_ascii": "Brandenburg",
    "lat": 52.4117,
    "lng": 12.5561,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Brandenburg",
    "capital": "minor",
    "population": 72124,
    "id": 1276372220
  },
  {
    "city": "Mansfield",
    "city_ascii": "Mansfield",
    "lat": 32.569,
    "lng": -97.1211,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 72419,
    "id": 1840020702
  },
  {
    "city": "Hammond",
    "city_ascii": "Hammond",
    "lat": 30.5061,
    "lng": -90.4563,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 72413,
    "id": 1840013908
  },
  {
    "city": "Dothan",
    "city_ascii": "Dothan",
    "lat": 31.2335,
    "lng": -85.4069,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 72396,
    "id": 1840001518
  },
  {
    "city": "Harrisonburg",
    "city_ascii": "Harrisonburg",
    "lat": 38.4361,
    "lng": -78.8735,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 72386,
    "id": 1840003844
  },
  {
    "city": "Wenatchee",
    "city_ascii": "Wenatchee",
    "lat": 47.4356,
    "lng": -120.3283,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 72350,
    "id": 1840021074
  },
  {
    "city": "Goya",
    "city_ascii": "Goya",
    "lat": -29.1333,
    "lng": -59.25,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Corrientes",
    "capital": "minor",
    "population": 72304,
    "id": 1032251925
  },
  {
    "city": "Waukesha",
    "city_ascii": "Waukesha",
    "lat": 43.0087,
    "lng": -88.2464,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 72299,
    "id": 1840003008
  },
  {
    "city": "Tagaytay",
    "city_ascii": "Tagaytay",
    "lat": 14.1,
    "lng": 120.9333,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Cavite",
    "capital": "",
    "population": 71181,
    "id": 1608593949
  },
  {
    "city": "Ellicott City",
    "city_ascii": "Ellicott City",
    "lat": 39.2773,
    "lng": -76.8344,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 72247,
    "id": 1840005802
  },
  {
    "city": "Colón",
    "city_ascii": "Colon",
    "lat": 22.7225,
    "lng": -80.9067,
    "country": "Cuba",
    "iso2": "CU",
    "iso3": "CUB",
    "admin_name": "Matanzas",
    "capital": "minor",
    "population": 72000,
    "id": 1192866771
  },
  {
    "city": "Tibati",
    "city_ascii": "Tibati",
    "lat": 6.4669,
    "lng": 12.6158,
    "country": "Cameroon",
    "iso2": "CM",
    "iso3": "CMR",
    "admin_name": "Adamaoua",
    "capital": "",
    "population": 72081,
    "id": 1120299131
  },
  {
    "city": "Coffs Harbour",
    "city_ascii": "Coffs Harbour",
    "lat": -30.3022,
    "lng": 153.1189,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 71822,
    "id": 1036320442
  },
  {
    "city": "Mun’gyŏng",
    "city_ascii": "Mun'gyong",
    "lat": 36.5939,
    "lng": 128.2014,
    "country": "Korea, South",
    "iso2": "KR",
    "iso3": "KOR",
    "admin_name": "Gyeongbuk",
    "capital": "",
    "population": 71863,
    "id": 1410822576
  },
  {
    "city": "Redmond",
    "city_ascii": "Redmond",
    "lat": 47.6762,
    "lng": -122.1169,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 71929,
    "id": 1840019835
  },
  {
    "city": "Kawm Umbū",
    "city_ascii": "Kawm Umbu",
    "lat": 24.4667,
    "lng": 32.95,
    "country": "Egypt",
    "iso2": "EG",
    "iso3": "EGY",
    "admin_name": "Aswān",
    "capital": "",
    "population": 71596,
    "id": 1818527731
  },
  {
    "city": "Uwajima",
    "city_ascii": "Uwajima",
    "lat": 33.2233,
    "lng": 132.5606,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Ehime",
    "capital": "",
    "population": 71586,
    "id": 1392682766
  },
  {
    "city": "Florida",
    "city_ascii": "Florida",
    "lat": 21.5294,
    "lng": -78.2228,
    "country": "Cuba",
    "iso2": "CU",
    "iso3": "CUB",
    "admin_name": "Camagüey",
    "capital": "minor",
    "population": 71676,
    "id": 1192766774
  },
  {
    "city": "Izmayil",
    "city_ascii": "Izmayil",
    "lat": 45.35,
    "lng": 28.8333,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Odes’ka Oblast’",
    "capital": "minor",
    "population": 71594,
    "id": 1804652754
  },
  {
    "city": "Bocholt",
    "city_ascii": "Bocholt",
    "lat": 51.8333,
    "lng": 6.6167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 71099,
    "id": 1276001655
  },
  {
    "city": "Aschaffenburg",
    "city_ascii": "Aschaffenburg",
    "lat": 49.9757,
    "lng": 9.1478,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "minor",
    "population": 70527,
    "id": 1276069907
  },
  {
    "city": "Lisburn",
    "city_ascii": "Lisburn",
    "lat": 54.5167,
    "lng": -6.0333,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Lisburn and Castlereagh",
    "capital": "",
    "population": 71465,
    "id": 1826667481
  },
  {
    "city": "Sarnia",
    "city_ascii": "Sarnia",
    "lat": 42.9994,
    "lng": -82.3089,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 71594,
    "id": 1124509835
  },
  {
    "city": "Keighley",
    "city_ascii": "Keighley",
    "lat": 53.867,
    "lng": -1.911,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Bradford",
    "capital": "",
    "population": 70000,
    "id": 1826638204
  },
  {
    "city": "Wood Buffalo",
    "city_ascii": "Wood Buffalo",
    "lat": 57.6042,
    "lng": -111.3284,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Alberta",
    "capital": "",
    "population": 71589,
    "id": 1124001123
  },
  {
    "city": "Mérignac",
    "city_ascii": "Merignac",
    "lat": 44.8386,
    "lng": -0.6436,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Nouvelle-Aquitaine",
    "capital": "",
    "population": 70105,
    "id": 1250010536
  },
  {
    "city": "Sumter",
    "city_ascii": "Sumter",
    "lat": 33.9392,
    "lng": -80.393,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 71518,
    "id": 1840015612
  },
  {
    "city": "Gniezno",
    "city_ascii": "Gniezno",
    "lat": 52.5333,
    "lng": 17.6,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Wielkopolskie",
    "capital": "minor",
    "population": 70083,
    "id": 1616984350
  },
  {
    "city": "Redlands",
    "city_ascii": "Redlands",
    "lat": 34.0511,
    "lng": -117.1712,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 71513,
    "id": 1840020412
  },
  {
    "city": "Bāpatla",
    "city_ascii": "Bapatla",
    "lat": 15.8889,
    "lng": 80.47,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Andhra Pradesh",
    "capital": "",
    "population": 70777,
    "id": 1356828422
  },
  {
    "city": "Daphne",
    "city_ascii": "Daphne",
    "lat": 30.6286,
    "lng": -87.8866,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 71484,
    "id": 1840006178
  },
  {
    "city": "Mārkāpur",
    "city_ascii": "Markapur",
    "lat": 15.735,
    "lng": 79.27,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Andhra Pradesh",
    "capital": "",
    "population": 71092,
    "id": 1356101278
  },
  {
    "city": "Mount Vernon",
    "city_ascii": "Mount Vernon",
    "lat": 48.4202,
    "lng": -122.3115,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 71422,
    "id": 1840019774
  },
  {
    "city": "Villa Tunari",
    "city_ascii": "Villa Tunari",
    "lat": -16.9725,
    "lng": -65.42,
    "country": "Bolivia",
    "iso2": "BO",
    "iso3": "BOL",
    "admin_name": "Cochabamba",
    "capital": "",
    "population": 71386,
    "id": 1068987267
  },
  {
    "city": "Saint-Nazaire",
    "city_ascii": "Saint-Nazaire",
    "lat": 47.2806,
    "lng": -2.2086,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Pays de la Loire",
    "capital": "minor",
    "population": 69993,
    "id": 1250133864
  },
  {
    "city": "Malaut",
    "city_ascii": "Malaut",
    "lat": 30.19,
    "lng": 74.499,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Punjab",
    "capital": "",
    "population": 70958,
    "id": 1356603442
  },
  {
    "city": "Guasavito",
    "city_ascii": "Guasavito",
    "lat": 25.5744,
    "lng": -108.4706,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Sinaloa",
    "capital": "",
    "population": 71196,
    "id": 1484662260
  },
  {
    "city": "Verkhnyaya Pyshma",
    "city_ascii": "Verkhnyaya Pyshma",
    "lat": 56.9667,
    "lng": 60.5833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sverdlovskaya Oblast’",
    "capital": "",
    "population": 71241,
    "id": 1643491878
  },
  {
    "city": "Placetas",
    "city_ascii": "Placetas",
    "lat": 22.3158,
    "lng": -79.6556,
    "country": "Cuba",
    "iso2": "CU",
    "iso3": "CUB",
    "admin_name": "Villa Clara",
    "capital": "minor",
    "population": 71208,
    "id": 1192546991
  },
  {
    "city": "Gibara",
    "city_ascii": "Gibara",
    "lat": 21.1072,
    "lng": -76.1367,
    "country": "Cuba",
    "iso2": "CU",
    "iso3": "CUB",
    "admin_name": "Holguín",
    "capital": "minor",
    "population": 71126,
    "id": 1192391794
  },
  {
    "city": "Kladno",
    "city_ascii": "Kladno",
    "lat": 50.1431,
    "lng": 14.1053,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Středočeský Kraj",
    "capital": "",
    "population": 69337,
    "id": 1203557079
  },
  {
    "city": "Cabudare",
    "city_ascii": "Cabudare",
    "lat": 10.0331,
    "lng": -69.2633,
    "country": "Venezuela",
    "iso2": "VE",
    "iso3": "VEN",
    "admin_name": "Lara",
    "capital": "minor",
    "population": 70578,
    "id": 1862114226
  },
  {
    "city": "Entebbe",
    "city_ascii": "Entebbe",
    "lat": 0.05,
    "lng": 32.46,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Wakiso",
    "capital": "",
    "population": 69958,
    "id": 1800535915
  },
  {
    "city": "Baiquan",
    "city_ascii": "Baiquan",
    "lat": 47.6018,
    "lng": 126.0819,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Heilongjiang",
    "capital": "minor",
    "population": 70472,
    "id": 1156009118
  },
  {
    "city": "Weston",
    "city_ascii": "Weston",
    "lat": 26.1006,
    "lng": -80.4054,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 71166,
    "id": 1840016003
  },
  {
    "city": "Ban Nong Prue",
    "city_ascii": "Ban Nong Prue",
    "lat": 12.9227,
    "lng": 100.9375,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Chon Buri",
    "capital": "",
    "population": 70594,
    "id": 1764743967
  },
  {
    "city": "Bundaberg",
    "city_ascii": "Bundaberg",
    "lat": -24.85,
    "lng": 152.35,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Queensland",
    "capital": "",
    "population": 70921,
    "id": 1036377333
  },
  {
    "city": "Anzhero-Sudzhensk",
    "city_ascii": "Anzhero-Sudzhensk",
    "lat": 56.0833,
    "lng": 86.0333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kemerovskaya Oblast’",
    "capital": "",
    "population": 70476,
    "id": 1643779858
  },
  {
    "city": "St. Charles",
    "city_ascii": "St. Charles",
    "lat": 38.7954,
    "lng": -90.5157,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 71028,
    "id": 1840009719
  },
  {
    "city": "Cherry Hill",
    "city_ascii": "Cherry Hill",
    "lat": 39.9034,
    "lng": -74.9947,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 70859,
    "id": 1840081685
  },
  {
    "city": "Badvel",
    "city_ascii": "Badvel",
    "lat": 14.75,
    "lng": 79.05,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Andhra Pradesh",
    "capital": "",
    "population": 70626,
    "id": 1356145368
  },
  {
    "city": "Hujra Shah Muqim",
    "city_ascii": "Hujra Shah Muqim",
    "lat": 30.7333,
    "lng": 73.8167,
    "country": "Pakistan",
    "iso2": "PK",
    "iso3": "PAK",
    "admin_name": "Punjab",
    "capital": "",
    "population": 70204,
    "id": 1586402344
  },
  {
    "city": "Châteauguay",
    "city_ascii": "Chateauguay",
    "lat": 45.38,
    "lng": -73.75,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 70812,
    "id": 1124437897
  },
  {
    "city": "Novomoskovs’k",
    "city_ascii": "Novomoskovs'k",
    "lat": 48.6328,
    "lng": 35.2239,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Dnipropetrovs’ka Oblast’",
    "capital": "minor",
    "population": 70749,
    "id": 1804952905
  },
  {
    "city": "Zenica",
    "city_ascii": "Zenica",
    "lat": 44.2039,
    "lng": 17.9078,
    "country": "Bosnia And Herzegovina",
    "iso2": "BA",
    "iso3": "BIH",
    "admin_name": "Bosnia and Herzegovina, Federation of",
    "capital": "minor",
    "population": 70553,
    "id": 1070047832
  },
  {
    "city": "Janesville",
    "city_ascii": "Janesville",
    "lat": 42.6855,
    "lng": -89.0136,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 70755,
    "id": 1840002467
  },
  {
    "city": "Kabirwala",
    "city_ascii": "Kabirwala",
    "lat": 30.4068,
    "lng": 71.8667,
    "country": "Pakistan",
    "iso2": "PK",
    "iso3": "PAK",
    "admin_name": "Punjab",
    "capital": "",
    "population": 70123,
    "id": 1586254267
  },
  {
    "city": "Loulé",
    "city_ascii": "Loule",
    "lat": 37.144,
    "lng": -8.0235,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Faro",
    "capital": "minor",
    "population": 70622,
    "id": 1620028270
  },
  {
    "city": "Sherman",
    "city_ascii": "Sherman",
    "lat": 33.6274,
    "lng": -96.6218,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 70704,
    "id": 1840021953
  },
  {
    "city": "North Richland Hills",
    "city_ascii": "North Richland Hills",
    "lat": 32.8605,
    "lng": -97.218,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 70670,
    "id": 1840020703
  },
  {
    "city": "Chekhov",
    "city_ascii": "Chekhov",
    "lat": 55.1481,
    "lng": 37.4769,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Moskovskaya Oblast’",
    "capital": "",
    "population": 70548,
    "id": 1643988618
  },
  {
    "city": "Rittō",
    "city_ascii": "Ritto",
    "lat": 35.0217,
    "lng": 135.9981,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Shiga",
    "capital": "",
    "population": 69294,
    "id": 1392932001
  },
  {
    "city": "Reconquista",
    "city_ascii": "Reconquista",
    "lat": -29.1443,
    "lng": -59.6438,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Santa Fe",
    "capital": "minor",
    "population": 70549,
    "id": 1032927391
  },
  {
    "city": "Būr Fu’ād",
    "city_ascii": "Bur Fu'ad",
    "lat": 31.2314,
    "lng": 32.3253,
    "country": "Egypt",
    "iso2": "EG",
    "iso3": "EGY",
    "admin_name": "Būr Sa‘īd",
    "capital": "",
    "population": 70417,
    "id": 1818849497
  },
  {
    "city": "Benidorm",
    "city_ascii": "Benidorm",
    "lat": 38.5342,
    "lng": -0.1314,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Valencia",
    "capital": "",
    "population": 68721,
    "id": 1724178356
  },
  {
    "city": "Tanabe",
    "city_ascii": "Tanabe",
    "lat": 33.7333,
    "lng": 135.3833,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Wakayama",
    "capital": "",
    "population": 70410,
    "id": 1392214761
  },
  {
    "city": "Kovel’",
    "city_ascii": "Kovel'",
    "lat": 51.2167,
    "lng": 24.7167,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Volyns’ka Oblast’",
    "capital": "minor",
    "population": 69089,
    "id": 1804093604
  },
  {
    "city": "Broomfield",
    "city_ascii": "Broomfield",
    "lat": 39.9541,
    "lng": -105.0527,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 70465,
    "id": 1840018781
  },
  {
    "city": "Zhanggu",
    "city_ascii": "Zhanggu",
    "lat": 30.9504,
    "lng": 101.9167,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Sichuan",
    "capital": "minor",
    "population": 70000,
    "id": 1156031323
  },
  {
    "city": "Smila",
    "city_ascii": "Smila",
    "lat": 49.2167,
    "lng": 31.8667,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Cherkas’ka Oblast’",
    "capital": "minor",
    "population": 68536,
    "id": 1804490050
  },
  {
    "city": "Sipalay",
    "city_ascii": "Sipalay",
    "lat": 9.75,
    "lng": 122.4,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Negros Occidental",
    "capital": "",
    "population": 70070,
    "id": 1608396492
  },
  {
    "city": "El Paso de Robles",
    "city_ascii": "El Paso de Robles",
    "lat": 35.6394,
    "lng": -120.656,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 70248,
    "id": 1840074847
  },
  {
    "city": "Bristol",
    "city_ascii": "Bristol",
    "lat": 36.5572,
    "lng": -82.2154,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 70243,
    "id": 1840013301
  },
  {
    "city": "Rosetta",
    "city_ascii": "Rosetta",
    "lat": 31.4014,
    "lng": 30.4194,
    "country": "Egypt",
    "iso2": "EG",
    "iso3": "EGY",
    "admin_name": "Al Buḩayrah",
    "capital": "",
    "population": 68947,
    "id": 1818868679
  },
  {
    "city": "Walnut Creek",
    "city_ascii": "Walnut Creek",
    "lat": 37.9024,
    "lng": -122.0398,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 70166,
    "id": 1840021531
  },
  {
    "city": "Ōdate",
    "city_ascii": "Odate",
    "lat": 40.2717,
    "lng": 140.5647,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Akita",
    "capital": "",
    "population": 70085,
    "id": 1392257761
  },
  {
    "city": "Villa del Rosario",
    "city_ascii": "Villa del Rosario",
    "lat": 7.8353,
    "lng": -72.4736,
    "country": "Colombia",
    "iso2": "CO",
    "iso3": "COL",
    "admin_name": "Norte de Santander",
    "capital": "minor",
    "population": 69833,
    "id": 1170700232
  },
  {
    "city": "Colmar",
    "city_ascii": "Colmar",
    "lat": 48.0817,
    "lng": 7.3556,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Grand Est",
    "capital": "minor",
    "population": 69105,
    "id": 1250950647
  },
  {
    "city": "Phú Quốc",
    "city_ascii": "Phu Quoc",
    "lat": 10.2289,
    "lng": 103.9572,
    "country": "Vietnam",
    "iso2": "VN",
    "iso3": "VNM",
    "admin_name": "Kiến Giang",
    "capital": "minor",
    "population": 70000,
    "id": 1704992616
  },
  {
    "city": "Qo‘ng‘irot Shahri",
    "city_ascii": "Qo`ng`irot Shahri",
    "lat": 43.0758,
    "lng": 58.9067,
    "country": "Uzbekistan",
    "iso2": "UZ",
    "iso3": "UZB",
    "admin_name": "Qoraqalpog‘iston",
    "capital": "minor",
    "population": 70100,
    "id": 1860555381
  },
  {
    "city": "Az Zubaydīyah",
    "city_ascii": "Az Zubaydiyah",
    "lat": 32.7588,
    "lng": 45.1773,
    "country": "Iraq",
    "iso2": "IQ",
    "iso3": "IRQ",
    "admin_name": "Wāsiţ",
    "capital": "",
    "population": 70000,
    "id": 1368931413
  },
  {
    "city": "Oshnavīyeh",
    "city_ascii": "Oshnaviyeh",
    "lat": 37.0397,
    "lng": 45.0983,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Āz̄arbāyjān-e Gharbī",
    "capital": "minor",
    "population": 70030,
    "id": 1364910470
  },
  {
    "city": "Jorhāt",
    "city_ascii": "Jorhat",
    "lat": 26.75,
    "lng": 94.2167,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Assam",
    "capital": "",
    "population": 69033,
    "id": 1356638741
  },
  {
    "city": "Saldanha",
    "city_ascii": "Saldanha",
    "lat": -32.9978,
    "lng": 17.9456,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "Western Cape",
    "capital": "",
    "population": 68284,
    "id": 1710078862
  },
  {
    "city": "Celle",
    "city_ascii": "Celle",
    "lat": 52.6256,
    "lng": 10.0825,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "minor",
    "population": 69602,
    "id": 1276282135
  },
  {
    "city": "Kempten",
    "city_ascii": "Kempten",
    "lat": 47.7333,
    "lng": 10.3167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "minor",
    "population": 68907,
    "id": 1276008842
  },
  {
    "city": "Isnā",
    "city_ascii": "Isna",
    "lat": 25.2933,
    "lng": 32.5564,
    "country": "Egypt",
    "iso2": "EG",
    "iso3": "EGY",
    "admin_name": "Qinā",
    "capital": "",
    "population": 68656,
    "id": 1818782058
  },
  {
    "city": "Hasselt",
    "city_ascii": "Hasselt",
    "lat": 50.9304,
    "lng": 5.3383,
    "country": "Belgium",
    "iso2": "BE",
    "iso3": "BEL",
    "admin_name": "Flanders",
    "capital": "minor",
    "population": 69222,
    "id": 1056617022
  },
  {
    "city": "Glen Burnie",
    "city_ascii": "Glen Burnie",
    "lat": 39.1559,
    "lng": -76.6072,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 69813,
    "id": 1840005917
  },
  {
    "city": "Solok",
    "city_ascii": "Solok",
    "lat": -0.7883,
    "lng": 100.6542,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Sumatera Barat",
    "capital": "",
    "population": 68602,
    "id": 1360178406
  },
  {
    "city": "Mansehra",
    "city_ascii": "Mansehra",
    "lat": 34.3333,
    "lng": 73.2,
    "country": "Pakistan",
    "iso2": "PK",
    "iso3": "PAK",
    "admin_name": "Khyber Pakhtunkhwa",
    "capital": "minor",
    "population": 69085,
    "id": 1586902336
  },
  {
    "city": "Suwałki",
    "city_ascii": "Suwalki",
    "lat": 54.0833,
    "lng": 22.9333,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Podlaskie",
    "capital": "minor",
    "population": 69626,
    "id": 1616523627
  },
  {
    "city": "Lehi",
    "city_ascii": "Lehi",
    "lat": 40.4136,
    "lng": -111.8726,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Utah",
    "capital": "",
    "population": 69724,
    "id": 1840020168
  },
  {
    "city": "Casas Adobes",
    "city_ascii": "Casas Adobes",
    "lat": 32.3423,
    "lng": -111.0114,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 69615,
    "id": 1840018108
  },
  {
    "city": "Saint-Jérôme",
    "city_ascii": "Saint-Jerome",
    "lat": 45.7833,
    "lng": -74,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 69598,
    "id": 1124268324
  },
  {
    "city": "Guajará-Mirim",
    "city_ascii": "Guajara-Mirim",
    "lat": -10.8,
    "lng": -65.3499,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Rondônia",
    "capital": "",
    "population": 69586,
    "id": 1076692558
  },
  {
    "city": "Samch’ŏk",
    "city_ascii": "Samch'ok",
    "lat": 37.4406,
    "lng": 129.1708,
    "country": "Korea, South",
    "iso2": "KR",
    "iso3": "KOR",
    "admin_name": "Gangwon",
    "capital": "",
    "population": 69509,
    "id": 1410438648
  },
  {
    "city": "Homestead",
    "city_ascii": "Homestead",
    "lat": 25.4665,
    "lng": -80.4472,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 69523,
    "id": 1840015159
  },
  {
    "city": "West Bend",
    "city_ascii": "West Bend",
    "lat": 43.4184,
    "lng": -88.1824,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 69504,
    "id": 1840002829
  },
  {
    "city": "Delray Beach",
    "city_ascii": "Delray Beach",
    "lat": 26.455,
    "lng": -80.0905,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 69451,
    "id": 1840015123
  },
  {
    "city": "Ongjang",
    "city_ascii": "Ongjang",
    "lat": 37.9371,
    "lng": 125.3571,
    "country": "Korea, North",
    "iso2": "KP",
    "iso3": "PRK",
    "admin_name": "Hwangnam",
    "capital": "",
    "population": 69195,
    "id": 1408848418
  },
  {
    "city": "Sheboygan",
    "city_ascii": "Sheboygan",
    "lat": 43.7403,
    "lng": -87.7316,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 69393,
    "id": 1840002628
  },
  {
    "city": "Aqsū",
    "city_ascii": "Aqsu",
    "lat": 52.0333,
    "lng": 76.9167,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Pavlodar",
    "capital": "",
    "population": 69354,
    "id": 1398272761
  },
  {
    "city": "Sabae",
    "city_ascii": "Sabae",
    "lat": 35.9567,
    "lng": 136.1844,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Fukui",
    "capital": "",
    "population": 68533,
    "id": 1392929907
  },
  {
    "city": "Nova Kakhovka",
    "city_ascii": "Nova Kakhovka",
    "lat": 46.7667,
    "lng": 33.3667,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Khersons’ka Oblast’",
    "capital": "",
    "population": 69280,
    "id": 1804166107
  },
  {
    "city": "Bafia",
    "city_ascii": "Bafia",
    "lat": 4.7425,
    "lng": 11.2247,
    "country": "Cameroon",
    "iso2": "CM",
    "iso3": "CMR",
    "admin_name": "Centre",
    "capital": "",
    "population": 69270,
    "id": 1120310806
  },
  {
    "city": "Lake Elsinore",
    "city_ascii": "Lake Elsinore",
    "lat": 33.6847,
    "lng": -117.3344,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 69283,
    "id": 1840020560
  },
  {
    "city": "Liuhe",
    "city_ascii": "Liuhe",
    "lat": 42.2789,
    "lng": 125.7173,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Jilin",
    "capital": "minor",
    "population": 68938,
    "id": 1156459313
  },
  {
    "city": "Fulda",
    "city_ascii": "Fulda",
    "lat": 50.5528,
    "lng": 9.6775,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "minor",
    "population": 68586,
    "id": 1276669051
  },
  {
    "city": "Daytona Beach",
    "city_ascii": "Daytona Beach",
    "lat": 29.1994,
    "lng": -81.0982,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 69186,
    "id": 1840015068
  },
  {
    "city": "Ragusa",
    "city_ascii": "Ragusa",
    "lat": 36.93,
    "lng": 14.73,
    "country": "Italy",
    "iso2": "IT",
    "iso3": "ITA",
    "admin_name": "Sicilia",
    "capital": "minor",
    "population": 68956,
    "id": 1380117871
  },
  {
    "city": "Minusinsk",
    "city_ascii": "Minusinsk",
    "lat": 53.7,
    "lng": 91.6833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Krasnoyarskiy Kray",
    "capital": "",
    "population": 68007,
    "id": 1643003309
  },
  {
    "city": "Mocuba",
    "city_ascii": "Mocuba",
    "lat": -16.8391,
    "lng": 36.9855,
    "country": "Mozambique",
    "iso2": "MZ",
    "iso3": "MOZ",
    "admin_name": "Zambézia",
    "capital": "",
    "population": 69045,
    "id": 1508922450
  },
  {
    "city": "Villanueva y Geltrú",
    "city_ascii": "Villanueva y Geltru",
    "lat": 41.2243,
    "lng": 1.7259,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Catalonia",
    "capital": "",
    "population": 67086,
    "id": 1724482160
  },
  {
    "city": "Los Lunas",
    "city_ascii": "Los Lunas",
    "lat": 34.8115,
    "lng": -106.7803,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Mexico",
    "capital": "",
    "population": 69020,
    "id": 1840022814
  },
  {
    "city": "Mocuba",
    "city_ascii": "Mocuba",
    "lat": -16.8496,
    "lng": 38.26,
    "country": "Mozambique",
    "iso2": "MZ",
    "iso3": "MOZ",
    "admin_name": "Zambézia",
    "capital": "",
    "population": 68984,
    "id": 1508699932
  },
  {
    "city": "Sosnovyy Bor",
    "city_ascii": "Sosnovyy Bor",
    "lat": 59.9,
    "lng": 29.1,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Leningradskaya Oblast’",
    "capital": "",
    "population": 68013,
    "id": 1643552609
  },
  {
    "city": "Dinslaken",
    "city_ascii": "Dinslaken",
    "lat": 51.5667,
    "lng": 6.7333,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 67525,
    "id": 1276055868
  },
  {
    "city": "Chulucanas",
    "city_ascii": "Chulucanas",
    "lat": -5.1092,
    "lng": -80.1625,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "Piura",
    "capital": "",
    "population": 68835,
    "id": 1604692561
  },
  {
    "city": "Aalen",
    "city_ascii": "Aalen",
    "lat": 48.8372,
    "lng": 10.0936,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "minor",
    "population": 68456,
    "id": 1276757787
  },
  {
    "city": "Say’ūn",
    "city_ascii": "Say'un",
    "lat": 15.943,
    "lng": 48.7873,
    "country": "Yemen",
    "iso2": "YE",
    "iso3": "YEM",
    "admin_name": "Ḩaḑramawt",
    "capital": "minor",
    "population": 68747,
    "id": 1887142998
  },
  {
    "city": "Al Aḩmadī",
    "city_ascii": "Al Ahmadi",
    "lat": 29.0769,
    "lng": 48.0838,
    "country": "Kuwait",
    "iso2": "KW",
    "iso3": "KWT",
    "admin_name": "Al Aḩmadī",
    "capital": "",
    "population": 68763,
    "id": 1414024487
  },
  {
    "city": "Wum",
    "city_ascii": "Wum",
    "lat": 6.3833,
    "lng": 10.0667,
    "country": "Cameroon",
    "iso2": "CM",
    "iso3": "CMR",
    "admin_name": "North-West",
    "capital": "",
    "population": 68836,
    "id": 1120557987
  },
  {
    "city": "Lima",
    "city_ascii": "Lima",
    "lat": 40.741,
    "lng": -84.1121,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 68878,
    "id": 1840002785
  },
  {
    "city": "Mococa",
    "city_ascii": "Mococa",
    "lat": -21.4678,
    "lng": -47.0047,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 68797,
    "id": 1076000865
  },
  {
    "city": "Decatur",
    "city_ascii": "Decatur",
    "lat": 34.573,
    "lng": -86.9906,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 68867,
    "id": 1840001403
  },
  {
    "city": "Dmitrov",
    "city_ascii": "Dmitrov",
    "lat": 56.35,
    "lng": 37.5333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Moskovskaya Oblast’",
    "capital": "",
    "population": 68792,
    "id": 1643642162
  },
  {
    "city": "Rocklin",
    "city_ascii": "Rocklin",
    "lat": 38.8075,
    "lng": -121.2488,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 68823,
    "id": 1840020232
  },
  {
    "city": "Sabanalarga",
    "city_ascii": "Sabanalarga",
    "lat": 10.64,
    "lng": -74.92,
    "country": "Colombia",
    "iso2": "CO",
    "iso3": "COL",
    "admin_name": "Atlántico",
    "capital": "minor",
    "population": 68535,
    "id": 1170724527
  },
  {
    "city": "Kati",
    "city_ascii": "Kati",
    "lat": 12.7504,
    "lng": -8.08,
    "country": "Mali",
    "iso2": "ML",
    "iso3": "MLI",
    "admin_name": "Koulikoro",
    "capital": "minor",
    "population": 66895,
    "id": 1466006801
  },
  {
    "city": "Rogers",
    "city_ascii": "Rogers",
    "lat": 36.3172,
    "lng": -94.1526,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arkansas",
    "capital": "",
    "population": 68669,
    "id": 1840014444
  },
  {
    "city": "Doboj",
    "city_ascii": "Doboj",
    "lat": 44.7333,
    "lng": 18.1333,
    "country": "Bosnia And Herzegovina",
    "iso2": "BA",
    "iso3": "BIH",
    "admin_name": "Srpska, Republika",
    "capital": "minor",
    "population": 68514,
    "id": 1070396173
  },
  {
    "city": "Hihyā",
    "city_ascii": "Hihya",
    "lat": 30.6687,
    "lng": 31.5904,
    "country": "Egypt",
    "iso2": "EG",
    "iso3": "EGY",
    "admin_name": "Ash Sharqīyah",
    "capital": "",
    "population": 66702,
    "id": 1818905246
  },
  {
    "city": "Drummondville",
    "city_ascii": "Drummondville",
    "lat": 45.8833,
    "lng": -72.4833,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 68601,
    "id": 1124624283
  },
  {
    "city": "Stafford",
    "city_ascii": "Stafford",
    "lat": 52.8066,
    "lng": -2.1171,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Staffordshire",
    "capital": "",
    "population": 68472,
    "id": 1826088347
  },
  {
    "city": "Lippstadt",
    "city_ascii": "Lippstadt",
    "lat": 51.6667,
    "lng": 8.35,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 67901,
    "id": 1276820778
  },
  {
    "city": "Sōja",
    "city_ascii": "Soja",
    "lat": 34.6728,
    "lng": 133.7467,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Okayama",
    "capital": "",
    "population": 68172,
    "id": 1392665556
  },
  {
    "city": "Castle Rock",
    "city_ascii": "Castle Rock",
    "lat": 39.3763,
    "lng": -104.8535,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 68484,
    "id": 1840022455
  },
  {
    "city": "Otukpo",
    "city_ascii": "Otukpo",
    "lat": 7.1904,
    "lng": 8.13,
    "country": "Nigeria",
    "iso2": "NG",
    "iso3": "NGA",
    "admin_name": "Benue",
    "capital": "minor",
    "population": 68220,
    "id": 1566465153
  },
  {
    "city": "Yachimata",
    "city_ascii": "Yachimata",
    "lat": 35.6658,
    "lng": 140.3183,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Chiba",
    "capital": "",
    "population": 67522,
    "id": 1392651190
  },
  {
    "city": "Feodosiya",
    "city_ascii": "Feodosiya",
    "lat": 45.0489,
    "lng": 35.3792,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Krym, Avtonomna Respublika",
    "capital": "",
    "population": 67993,
    "id": 1804677570
  },
  {
    "city": "Ash Shiḩr",
    "city_ascii": "Ash Shihr",
    "lat": 14.7608,
    "lng": 49.6069,
    "country": "Yemen",
    "iso2": "YE",
    "iso3": "YEM",
    "admin_name": "Ḩaḑramawt",
    "capital": "minor",
    "population": 68313,
    "id": 1887657562
  },
  {
    "city": "Chengjiao Chengguanzhen",
    "city_ascii": "Chengjiao Chengguanzhen",
    "lat": 34.4362,
    "lng": 104.0306,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Gansu",
    "capital": "minor",
    "population": 67826,
    "id": 1156556377
  },
  {
    "city": "Tuymazy",
    "city_ascii": "Tuymazy",
    "lat": 54.6,
    "lng": 53.7,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Bashkortostan",
    "capital": "",
    "population": 68256,
    "id": 1643065980
  },
  {
    "city": "Türkmenbaşy",
    "city_ascii": "Turkmenbasy",
    "lat": 40.023,
    "lng": 52.9697,
    "country": "Turkmenistan",
    "iso2": "TM",
    "iso3": "TKM",
    "admin_name": "Balkan",
    "capital": "",
    "population": 68292,
    "id": 1795987646
  },
  {
    "city": "Lodja",
    "city_ascii": "Lodja",
    "lat": -3.49,
    "lng": 23.42,
    "country": "Congo (Kinshasa)",
    "iso2": "CD",
    "iso3": "COD",
    "admin_name": "Sankuru",
    "capital": "",
    "population": 68244,
    "id": 1180001261
  },
  {
    "city": "Fernandópolis",
    "city_ascii": "Fernandopolis",
    "lat": -20.2839,
    "lng": -50.2458,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 68120,
    "id": 1076205721
  },
  {
    "city": "Lala Musa",
    "city_ascii": "Lala Musa",
    "lat": 32.7012,
    "lng": 73.9605,
    "country": "Pakistan",
    "iso2": "PK",
    "iso3": "PAK",
    "admin_name": "Punjab",
    "capital": "",
    "population": 67283,
    "id": 1586484801
  },
  {
    "city": "Hanover",
    "city_ascii": "Hanover",
    "lat": 39.8117,
    "lng": -76.9835,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 68165,
    "id": 1840001450
  },
  {
    "city": "Los Andes",
    "city_ascii": "Los Andes",
    "lat": -32.8337,
    "lng": -70.5982,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Valparaíso",
    "capital": "minor",
    "population": 68093,
    "id": 1152833598
  },
  {
    "city": "Bossier City",
    "city_ascii": "Bossier City",
    "lat": 32.5227,
    "lng": -93.6666,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 68159,
    "id": 1840013790
  },
  {
    "city": "Dubuque",
    "city_ascii": "Dubuque",
    "lat": 42.5008,
    "lng": -90.7067,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Iowa",
    "capital": "",
    "population": 68109,
    "id": 1840000438
  },
  {
    "city": "Kashima",
    "city_ascii": "Kashima",
    "lat": 35.9658,
    "lng": 140.645,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Ibaraki",
    "capital": "",
    "population": 67448,
    "id": 1392346235
  },
  {
    "city": "Przemyśl",
    "city_ascii": "Przemysl",
    "lat": 49.785,
    "lng": 22.7673,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Podkarpackie",
    "capital": "minor",
    "population": 67847,
    "id": 1616506094
  },
  {
    "city": "Rockville",
    "city_ascii": "Rockville",
    "lat": 39.0834,
    "lng": -77.1552,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 68079,
    "id": 1840005849
  },
  {
    "city": "Victoria",
    "city_ascii": "Victoria",
    "lat": 28.8285,
    "lng": -96.985,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 68078,
    "id": 1840023044
  },
  {
    "city": "Saratoga Springs",
    "city_ascii": "Saratoga Springs",
    "lat": 43.0674,
    "lng": -73.7775,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 68028,
    "id": 1840000369
  },
  {
    "city": "Saiki",
    "city_ascii": "Saiki",
    "lat": 32.9603,
    "lng": 131.8994,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Ōita",
    "capital": "",
    "population": 67931,
    "id": 1392471298
  },
  {
    "city": "Érd",
    "city_ascii": "Erd",
    "lat": 47.3833,
    "lng": 18.9167,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Pest",
    "capital": "minor",
    "population": 66892,
    "id": 1348575496
  },
  {
    "city": "Kstovo",
    "city_ascii": "Kstovo",
    "lat": 56.1517,
    "lng": 44.1956,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Nizhegorodskaya Oblast’",
    "capital": "",
    "population": 67874,
    "id": 1643713056
  },
  {
    "city": "Tepotzotlán",
    "city_ascii": "Tepotzotlan",
    "lat": 19.7161,
    "lng": -99.2236,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "México",
    "capital": "minor",
    "population": 67724,
    "id": 1484890297
  },
  {
    "city": "Tzaneen",
    "city_ascii": "Tzaneen",
    "lat": -23.8333,
    "lng": 30.1667,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "Limpopo",
    "capital": "",
    "population": 67245,
    "id": 1710120942
  },
  {
    "city": "Ames",
    "city_ascii": "Ames",
    "lat": 42.0259,
    "lng": -93.6215,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Iowa",
    "capital": "",
    "population": 67910,
    "id": 1840007019
  },
  {
    "city": "West Des Moines",
    "city_ascii": "West Des Moines",
    "lat": 41.5522,
    "lng": -93.7805,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Iowa",
    "capital": "",
    "population": 67899,
    "id": 1840010185
  },
  {
    "city": "Hashima",
    "city_ascii": "Hashima",
    "lat": 35.3167,
    "lng": 136.7,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Gifu",
    "capital": "",
    "population": 66573,
    "id": 1392769362
  },
  {
    "city": "Ban Suan",
    "city_ascii": "Ban Suan",
    "lat": 13.3616,
    "lng": 100.9795,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Chon Buri",
    "capital": "",
    "population": 66092,
    "id": 1764305340
  },
  {
    "city": "İslahiye",
    "city_ascii": "Islahiye",
    "lat": 37.0264,
    "lng": 36.6322,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Gaziantep",
    "capital": "minor",
    "population": 67674,
    "id": 1792369096
  },
  {
    "city": "Embu-Guaçu",
    "city_ascii": "Embu-Guacu",
    "lat": -23.8322,
    "lng": -46.8117,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 67296,
    "id": 1076814441
  },
  {
    "city": "Yorba Linda",
    "city_ascii": "Yorba Linda",
    "lat": 33.889,
    "lng": -117.7714,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 67644,
    "id": 1840021970
  },
  {
    "city": "Santa Catarina Otzolotepec",
    "city_ascii": "Santa Catarina Otzolotepec",
    "lat": 18.5667,
    "lng": -97.25,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Puebla",
    "capital": "",
    "population": 67611,
    "id": 1484006128
  },
  {
    "city": "Casper",
    "city_ascii": "Casper",
    "lat": 42.842,
    "lng": -106.3207,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wyoming",
    "capital": "",
    "population": 67598,
    "id": 1840038324
  },
  {
    "city": "Saint John",
    "city_ascii": "Saint John",
    "lat": 45.2806,
    "lng": -66.0761,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "New Brunswick",
    "capital": "",
    "population": 67575,
    "id": 1124631364
  },
  {
    "city": "Ko Samui",
    "city_ascii": "Ko Samui",
    "lat": 9.5157,
    "lng": 99.9436,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Surat Thani",
    "capital": "minor",
    "population": 67265,
    "id": 1764771934
  },
  {
    "city": "Soio",
    "city_ascii": "Soio",
    "lat": -6.1296,
    "lng": 12.37,
    "country": "Angola",
    "iso2": "AO",
    "iso3": "AGO",
    "admin_name": "Zaire",
    "capital": "",
    "population": 67491,
    "id": 1024903782
  },
  {
    "city": "Palatine",
    "city_ascii": "Palatine",
    "lat": 42.1181,
    "lng": -88.043,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 67482,
    "id": 1840011311
  },
  {
    "city": "Corvallis",
    "city_ascii": "Corvallis",
    "lat": 44.5697,
    "lng": -123.278,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 67467,
    "id": 1840018611
  },
  {
    "city": "Herford",
    "city_ascii": "Herford",
    "lat": 52.1146,
    "lng": 8.6734,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "minor",
    "population": 66608,
    "id": 1276588147
  },
  {
    "city": "Oktyabrskiy",
    "city_ascii": "Oktyabrskiy",
    "lat": 52.6636,
    "lng": 156.2387,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kamchatskiy Kray",
    "capital": "",
    "population": 67386,
    "id": 1643010084
  },
  {
    "city": "Ankeny",
    "city_ascii": "Ankeny",
    "lat": 41.7285,
    "lng": -93.6033,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Iowa",
    "capital": "",
    "population": 67355,
    "id": 1840007066
  },
  {
    "city": "Koratla",
    "city_ascii": "Koratla",
    "lat": 18.82,
    "lng": 78.72,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Telangana",
    "capital": "",
    "population": 66504,
    "id": 1356581279
  },
  {
    "city": "Rowlett",
    "city_ascii": "Rowlett",
    "lat": 32.9157,
    "lng": -96.5488,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 67339,
    "id": 1840020711
  },
  {
    "city": "Kissidougou",
    "city_ascii": "Kissidougou",
    "lat": 9.1905,
    "lng": -10.12,
    "country": "Guinea",
    "iso2": "GN",
    "iso3": "GIN",
    "admin_name": "Faranah",
    "capital": "minor",
    "population": 66815,
    "id": 1324750427
  },
  {
    "city": "Lakeville",
    "city_ascii": "Lakeville",
    "lat": 44.6774,
    "lng": -93.252,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 67317,
    "id": 1840007859
  },
  {
    "city": "Tejen",
    "city_ascii": "Tejen",
    "lat": 37.3786,
    "lng": 60.496,
    "country": "Turkmenistan",
    "iso2": "TM",
    "iso3": "TKM",
    "admin_name": "Ahal",
    "capital": "",
    "population": 67294,
    "id": 1795500486
  },
  {
    "city": "Pelileo",
    "city_ascii": "Pelileo",
    "lat": -1.3306,
    "lng": -78.5428,
    "country": "Ecuador",
    "iso2": "EC",
    "iso3": "ECU",
    "admin_name": "Tungurahua",
    "capital": "",
    "population": 66836,
    "id": 1218236950
  },
  {
    "city": "Cosmópolis",
    "city_ascii": "Cosmopolis",
    "lat": -22.6458,
    "lng": -47.1961,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 66807,
    "id": 1076281064
  },
  {
    "city": "Moita",
    "city_ascii": "Moita",
    "lat": 38.65,
    "lng": -8.9833,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Setúbal",
    "capital": "minor",
    "population": 66029,
    "id": 1620000010
  },
  {
    "city": "Alpharetta",
    "city_ascii": "Alpharetta",
    "lat": 34.0704,
    "lng": -84.2739,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 67213,
    "id": 1840013659
  },
  {
    "city": "Rio Negro",
    "city_ascii": "Rio Negro",
    "lat": -26.1,
    "lng": -49.79,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Paraná",
    "capital": "",
    "population": 65597,
    "id": 1076653242
  },
  {
    "city": "Poinciana",
    "city_ascii": "Poinciana",
    "lat": 28.1217,
    "lng": -81.482,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 67169,
    "id": 1840014132
  },
  {
    "city": "Aflao",
    "city_ascii": "Aflao",
    "lat": 6.1188,
    "lng": 1.1946,
    "country": "Ghana",
    "iso2": "GH",
    "iso3": "GHA",
    "admin_name": "Volta",
    "capital": "",
    "population": 66546,
    "id": 1288563374
  },
  {
    "city": "Longview",
    "city_ascii": "Longview",
    "lat": 46.1461,
    "lng": -122.9629,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 67081,
    "id": 1840019904
  },
  {
    "city": "Vidnoye",
    "city_ascii": "Vidnoye",
    "lat": 55.55,
    "lng": 37.7,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Moskovskaya Oblast’",
    "capital": "",
    "population": 66158,
    "id": 1643052393
  },
  {
    "city": "Güines",
    "city_ascii": "Guines",
    "lat": 22.8475,
    "lng": -82.0236,
    "country": "Cuba",
    "iso2": "CU",
    "iso3": "CUB",
    "admin_name": "Mayabeque",
    "capital": "minor",
    "population": 66892,
    "id": 1192190364
  },
  {
    "city": "Baní",
    "city_ascii": "Bani",
    "lat": 18.28,
    "lng": -70.331,
    "country": "Dominican Republic",
    "iso2": "DO",
    "iso3": "DOM",
    "admin_name": "Valdesia",
    "capital": "minor",
    "population": 66709,
    "id": 1214720035
  },
  {
    "city": "Shiojiri",
    "city_ascii": "Shiojiri",
    "lat": 36.1167,
    "lng": 137.95,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Nagano",
    "capital": "",
    "population": 66737,
    "id": 1392508735
  },
  {
    "city": "DeKalb",
    "city_ascii": "DeKalb",
    "lat": 41.9313,
    "lng": -88.7482,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 66959,
    "id": 1840007033
  },
  {
    "city": "Pabianice",
    "city_ascii": "Pabianice",
    "lat": 51.6642,
    "lng": 19.35,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Łódzkie",
    "capital": "minor",
    "population": 64988,
    "id": 1616294006
  },
  {
    "city": "Petaluma",
    "city_ascii": "Petaluma",
    "lat": 38.2423,
    "lng": -122.6267,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 66929,
    "id": 1840020244
  },
  {
    "city": "Ferrol",
    "city_ascii": "Ferrol",
    "lat": 43.4667,
    "lng": -8.25,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Galicia",
    "capital": "",
    "population": 66065,
    "id": 1724806861
  },
  {
    "city": "Pulivendla",
    "city_ascii": "Pulivendla",
    "lat": 14.4167,
    "lng": 78.2333,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Andhra Pradesh",
    "capital": "",
    "population": 65706,
    "id": 1356099701
  },
  {
    "city": "Most",
    "city_ascii": "Most",
    "lat": 50.5031,
    "lng": 13.6367,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Ústecký Kraj",
    "capital": "",
    "population": 66034,
    "id": 1203813984
  },
  {
    "city": "Kerpen",
    "city_ascii": "Kerpen",
    "lat": 50.8719,
    "lng": 6.6961,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 66206,
    "id": 1276675138
  },
  {
    "city": "Ina",
    "city_ascii": "Ina",
    "lat": 35.8275,
    "lng": 137.9539,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Nagano",
    "capital": "",
    "population": 66641,
    "id": 1392422546
  },
  {
    "city": "Las Heras",
    "city_ascii": "Las Heras",
    "lat": -32.825,
    "lng": -68.8017,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Mendoza",
    "capital": "minor",
    "population": 66663,
    "id": 1032781204
  },
  {
    "city": "Belogorsk",
    "city_ascii": "Belogorsk",
    "lat": 50.9167,
    "lng": 128.4833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Amurskaya Oblast’",
    "capital": "",
    "population": 66183,
    "id": 1643671200
  },
  {
    "city": "Gwacheon",
    "city_ascii": "Gwacheon",
    "lat": 37.4289,
    "lng": 126.9892,
    "country": "Korea, South",
    "iso2": "KR",
    "iso3": "KOR",
    "admin_name": "Gyeonggi",
    "capital": "",
    "population": 64817,
    "id": 1410788896
  },
  {
    "city": "Huinan",
    "city_ascii": "Huinan",
    "lat": 42.6229,
    "lng": 126.2614,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Jilin",
    "capital": "",
    "population": 66315,
    "id": 1156136342
  },
  {
    "city": "Ishim",
    "city_ascii": "Ishim",
    "lat": 56.1167,
    "lng": 69.5,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Tyumenskaya Oblast’",
    "capital": "",
    "population": 65142,
    "id": 1643798395
  },
  {
    "city": "Caledon",
    "city_ascii": "Caledon",
    "lat": 43.8667,
    "lng": -79.8667,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 66502,
    "id": 1124070007
  },
  {
    "city": "Gukovo",
    "city_ascii": "Gukovo",
    "lat": 48.05,
    "lng": 39.9333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Rostovskaya Oblast’",
    "capital": "",
    "population": 64869,
    "id": 1643040094
  },
  {
    "city": "P’yŏngsan",
    "city_ascii": "P'yongsan",
    "lat": 38.3367,
    "lng": 126.3866,
    "country": "Korea, North",
    "iso2": "KP",
    "iso3": "PRK",
    "admin_name": "Hwangbuk",
    "capital": "",
    "population": 66260,
    "id": 1408433221
  },
  {
    "city": "Urla",
    "city_ascii": "Urla",
    "lat": 38.3222,
    "lng": 26.7647,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "İzmir",
    "capital": "minor",
    "population": 66360,
    "id": 1792891509
  },
  {
    "city": "Kalush",
    "city_ascii": "Kalush",
    "lat": 49.0442,
    "lng": 24.3597,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Ivano-Frankivs’ka Oblast’",
    "capital": "minor",
    "population": 66406,
    "id": 1804375295
  },
  {
    "city": "Stupino",
    "city_ascii": "Stupino",
    "lat": 54.8869,
    "lng": 38.0772,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Moskovskaya Oblast’",
    "capital": "",
    "population": 66341,
    "id": 1643209318
  },
  {
    "city": "Laguna Niguel",
    "city_ascii": "Laguna Niguel",
    "lat": 33.5275,
    "lng": -117.705,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 66385,
    "id": 1840020589
  },
  {
    "city": "Eagan",
    "city_ascii": "Eagan",
    "lat": 44.8169,
    "lng": -93.1638,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 66372,
    "id": 1840007852
  },
  {
    "city": "Slavyansk-na-Kubani",
    "city_ascii": "Slavyansk-na-Kubani",
    "lat": 45.2586,
    "lng": 38.1247,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Krasnodarskiy Kray",
    "capital": "minor",
    "population": 66285,
    "id": 1643757173
  },
  {
    "city": "Ilo",
    "city_ascii": "Ilo",
    "lat": -17.6486,
    "lng": -71.3306,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "Moquegua",
    "capital": "",
    "population": 66118,
    "id": 1604886582
  },
  {
    "city": "Kenner",
    "city_ascii": "Kenner",
    "lat": 30.0109,
    "lng": -90.2549,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 66340,
    "id": 1840015047
  },
  {
    "city": "Kungur",
    "city_ascii": "Kungur",
    "lat": 57.4333,
    "lng": 56.9333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Permskiy Kray",
    "capital": "minor",
    "population": 65284,
    "id": 1643194296
  },
  {
    "city": "Lençóis Paulista",
    "city_ascii": "Lencois Paulista",
    "lat": -22.5986,
    "lng": -48.8003,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 66131,
    "id": 1076632312
  },
  {
    "city": "Sodo",
    "city_ascii": "Sodo",
    "lat": 6.9,
    "lng": 37.75,
    "country": "Ethiopia",
    "iso2": "ET",
    "iso3": "ETH",
    "admin_name": "YeDebub Bihēroch Bihēreseboch na Hizboch",
    "capital": "",
    "population": 65737,
    "id": 1231130720
  },
  {
    "city": "Oberá",
    "city_ascii": "Obera",
    "lat": -27.4833,
    "lng": -55.1333,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Misiones",
    "capital": "minor",
    "population": 66112,
    "id": 1032099071
  },
  {
    "city": "Sindelfingen",
    "city_ascii": "Sindelfingen",
    "lat": 48.7133,
    "lng": 9.0028,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 64858,
    "id": 1276001469
  },
  {
    "city": "Bay City",
    "city_ascii": "Bay City",
    "lat": 43.5903,
    "lng": -83.8886,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 66107,
    "id": 1840002583
  },
  {
    "city": "Seraing",
    "city_ascii": "Seraing",
    "lat": 50.5986,
    "lng": 5.5122,
    "country": "Belgium",
    "iso2": "BE",
    "iso3": "BEL",
    "admin_name": "Wallonia",
    "capital": "",
    "population": 64270,
    "id": 1056627579
  },
  {
    "city": "Lahat",
    "city_ascii": "Lahat",
    "lat": -3.8,
    "lng": 103.5333,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Sumatera Selatan",
    "capital": "minor",
    "population": 65906,
    "id": 1360094721
  },
  {
    "city": "Armūr",
    "city_ascii": "Armur",
    "lat": 18.79,
    "lng": 78.29,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Telangana",
    "capital": "",
    "population": 64023,
    "id": 1356043277
  },
  {
    "city": "Old Bridge",
    "city_ascii": "Old Bridge",
    "lat": 40.4004,
    "lng": -74.3126,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 65935,
    "id": 1840081688
  },
  {
    "city": "North Little Rock",
    "city_ascii": "North Little Rock",
    "lat": 34.7808,
    "lng": -92.2371,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arkansas",
    "capital": "",
    "population": 65903,
    "id": 1840014632
  },
  {
    "city": "Sammamish",
    "city_ascii": "Sammamish",
    "lat": 47.6017,
    "lng": -122.0416,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 65892,
    "id": 1840021115
  },
  {
    "city": "Weimar",
    "city_ascii": "Weimar",
    "lat": 50.9833,
    "lng": 11.3167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Thuringia",
    "capital": "minor",
    "population": 65090,
    "id": 1276053525
  },
  {
    "city": "Shawnee",
    "city_ascii": "Shawnee",
    "lat": 39.0158,
    "lng": -94.8076,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kansas",
    "capital": "",
    "population": 65807,
    "id": 1840001641
  },
  {
    "city": "Jupiter",
    "city_ascii": "Jupiter",
    "lat": 26.9199,
    "lng": -80.1128,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 65791,
    "id": 1840017255
  },
  {
    "city": "Tupã",
    "city_ascii": "Tupa",
    "lat": -21.935,
    "lng": -50.5139,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 65651,
    "id": 1076738637
  },
  {
    "city": "Doral",
    "city_ascii": "Doral",
    "lat": 25.8151,
    "lng": -80.3565,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 65741,
    "id": 1840015148
  },
  {
    "city": "Zarechnyy",
    "city_ascii": "Zarechnyy",
    "lat": 53.2,
    "lng": 45.1667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Penzenskaya Oblast’",
    "capital": "",
    "population": 65156,
    "id": 1643027966
  },
  {
    "city": "Carbondale",
    "city_ascii": "Carbondale",
    "lat": 37.7221,
    "lng": -89.2238,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 65671,
    "id": 1840007560
  },
  {
    "city": "Daule",
    "city_ascii": "Daule",
    "lat": -1.8667,
    "lng": -79.9833,
    "country": "Ecuador",
    "iso2": "EC",
    "iso3": "ECU",
    "admin_name": "Guayas",
    "capital": "",
    "population": 65145,
    "id": 1218253183
  },
  {
    "city": "Blaine",
    "city_ascii": "Blaine",
    "lat": 45.1696,
    "lng": -93.2077,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 65607,
    "id": 1840006719
  },
  {
    "city": "St. Albert",
    "city_ascii": "St. Albert",
    "lat": 53.6303,
    "lng": -113.6258,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Alberta",
    "capital": "",
    "population": 65589,
    "id": 1124850754
  },
  {
    "city": "Pavlovskiy Posad",
    "city_ascii": "Pavlovskiy Posad",
    "lat": 55.7833,
    "lng": 38.65,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Moskovskaya Oblast’",
    "capital": "",
    "population": 65432,
    "id": 1643881333
  },
  {
    "city": "Weirton",
    "city_ascii": "Weirton",
    "lat": 40.406,
    "lng": -80.5671,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "West Virginia",
    "capital": "",
    "population": 65578,
    "id": 1840005405
  },
  {
    "city": "Plauen",
    "city_ascii": "Plauen",
    "lat": 50.495,
    "lng": 12.1383,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "minor",
    "population": 64931,
    "id": 1276134198
  },
  {
    "city": "Chełm",
    "city_ascii": "Chelm",
    "lat": 51.1333,
    "lng": 23.4833,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Lubelskie",
    "capital": "minor",
    "population": 63734,
    "id": 1616688578
  },
  {
    "city": "Tulare",
    "city_ascii": "Tulare",
    "lat": 36.1996,
    "lng": -119.34,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 65496,
    "id": 1840021638
  },
  {
    "city": "Valence",
    "city_ascii": "Valence",
    "lat": 44.9333,
    "lng": 4.8917,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Auvergne-Rhône-Alpes",
    "capital": "minor",
    "population": 63714,
    "id": 1250486068
  },
  {
    "city": "Beloretsk",
    "city_ascii": "Beloretsk",
    "lat": 53.9667,
    "lng": 58.4,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Bashkortostan",
    "capital": "",
    "population": 65477,
    "id": 1643447458
  },
  {
    "city": "Jaisalmer",
    "city_ascii": "Jaisalmer",
    "lat": 26.9167,
    "lng": 70.9167,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Rājasthān",
    "capital": "",
    "population": 65471,
    "id": 1356575402
  },
  {
    "city": "Bourges",
    "city_ascii": "Bourges",
    "lat": 47.0844,
    "lng": 2.3964,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Centre-Val de Loire",
    "capital": "minor",
    "population": 64551,
    "id": 1250043242
  },
  {
    "city": "Borås",
    "city_ascii": "Boras",
    "lat": 57.7304,
    "lng": 12.92,
    "country": "Sweden",
    "iso2": "SE",
    "iso3": "SWE",
    "admin_name": "Västra Götaland",
    "capital": "minor",
    "population": 65008,
    "id": 1752555835
  },
  {
    "city": "Middletown",
    "city_ascii": "Middletown",
    "lat": 40.3893,
    "lng": -74.082,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 65475,
    "id": 1840081835
  },
  {
    "city": "Franklin Township",
    "city_ascii": "Franklin Township",
    "lat": 40.4759,
    "lng": -74.5515,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 65452,
    "id": 1840143542
  },
  {
    "city": "Ishimbay",
    "city_ascii": "Ishimbay",
    "lat": 53.45,
    "lng": 56.0333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Bashkortostan",
    "capital": "",
    "population": 65422,
    "id": 1643130656
  },
  {
    "city": "Korosten",
    "city_ascii": "Korosten",
    "lat": 50.95,
    "lng": 28.65,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Zhytomyrs’ka Oblast’",
    "capital": "minor",
    "population": 63525,
    "id": 1804648682
  },
  {
    "city": "Wellington",
    "city_ascii": "Wellington",
    "lat": 26.6461,
    "lng": -80.2699,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 65398,
    "id": 1840018311
  },
  {
    "city": "Kurihara",
    "city_ascii": "Kurihara",
    "lat": 38.7303,
    "lng": 141.0214,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Miyagi",
    "capital": "",
    "population": 65313,
    "id": 1392698850
  },
  {
    "city": "Tamana",
    "city_ascii": "Tamana",
    "lat": 32.9281,
    "lng": 130.5597,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Kumamoto",
    "capital": "",
    "population": 64966,
    "id": 1392407764
  },
  {
    "city": "Pflugerville",
    "city_ascii": "Pflugerville",
    "lat": 30.452,
    "lng": -97.6022,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 65380,
    "id": 1840020892
  },
  {
    "city": "Birnin Konni",
    "city_ascii": "Birnin Konni",
    "lat": 13.7904,
    "lng": 5.2599,
    "country": "Niger",
    "iso2": "NE",
    "iso3": "NER",
    "admin_name": "Tahoua",
    "capital": "minor",
    "population": 65252,
    "id": 1562868655
  },
  {
    "city": "Palo Alto",
    "city_ascii": "Palo Alto",
    "lat": 37.3913,
    "lng": -122.1468,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 65364,
    "id": 1840020333
  },
  {
    "city": "Pessac",
    "city_ascii": "Pessac",
    "lat": 44.8067,
    "lng": -0.6311,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Nouvelle-Aquitaine",
    "capital": "",
    "population": 63808,
    "id": 1250292604
  },
  {
    "city": "Neuwied",
    "city_ascii": "Neuwied",
    "lat": 50.4286,
    "lng": 7.4614,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Rhineland-Palatinate",
    "capital": "minor",
    "population": 64574,
    "id": 1276935443
  },
  {
    "city": "Torquay",
    "city_ascii": "Torquay",
    "lat": 50.47,
    "lng": -3.53,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Torbay",
    "capital": "",
    "population": 65245,
    "id": 1826982921
  },
  {
    "city": "Bhairāhawā",
    "city_ascii": "Bhairahawa",
    "lat": 27.5,
    "lng": 83.45,
    "country": "Nepal",
    "iso2": "NP",
    "iso3": "NPL",
    "admin_name": "Lumbinī",
    "capital": "",
    "population": 63483,
    "id": 1524444140
  },
  {
    "city": "Ḩalabjah",
    "city_ascii": "Halabjah",
    "lat": 35.1833,
    "lng": 45.9833,
    "country": "Iraq",
    "iso2": "IQ",
    "iso3": "IRQ",
    "admin_name": "As Sulaymānīyah",
    "capital": "minor",
    "population": 65200,
    "id": 1368595428
  },
  {
    "city": "Great Falls",
    "city_ascii": "Great Falls",
    "lat": 47.5022,
    "lng": -111.2995,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Montana",
    "capital": "",
    "population": 65131,
    "id": 1840019839
  },
  {
    "city": "Esquipulas",
    "city_ascii": "Esquipulas",
    "lat": 14.6167,
    "lng": -89.2,
    "country": "Guatemala",
    "iso2": "GT",
    "iso3": "GTM",
    "admin_name": "Chiquimula",
    "capital": "minor",
    "population": 65000,
    "id": 1320011938
  },
  {
    "city": "Dormagen",
    "city_ascii": "Dormagen",
    "lat": 51.0964,
    "lng": 6.84,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 64335,
    "id": 1276688551
  },
  {
    "city": "Caripito",
    "city_ascii": "Caripito",
    "lat": 10.111,
    "lng": -63.1048,
    "country": "Venezuela",
    "iso2": "VE",
    "iso3": "VEN",
    "admin_name": "Monagas",
    "capital": "minor",
    "population": 64955,
    "id": 1862107520
  },
  {
    "city": "Michigan City",
    "city_ascii": "Michigan City",
    "lat": 41.7099,
    "lng": -86.8705,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 65058,
    "id": 1840013869
  },
  {
    "city": "Pokrovsk",
    "city_ascii": "Pokrovsk",
    "lat": 48.2833,
    "lng": 37.1833,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Donets’ka Oblast’",
    "capital": "minor",
    "population": 63437,
    "id": 1804910861
  },
  {
    "city": "Ji-Paraná",
    "city_ascii": "Ji-Parana",
    "lat": -10.8333,
    "lng": -61.967,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Rondônia",
    "capital": "",
    "population": 65016,
    "id": 1076313540
  },
  {
    "city": "Rosenheim",
    "city_ascii": "Rosenheim",
    "lat": 47.8561,
    "lng": 12.1289,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "minor",
    "population": 63324,
    "id": 1276301675
  },
  {
    "city": "Bucak",
    "city_ascii": "Bucak",
    "lat": 37.4592,
    "lng": 30.595,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Burdur",
    "capital": "minor",
    "population": 64943,
    "id": 1792073114
  },
  {
    "city": "Hornchurch",
    "city_ascii": "Hornchurch",
    "lat": 51.5565,
    "lng": 0.2128,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Havering",
    "capital": "",
    "population": 63583,
    "id": 1826813082
  },
  {
    "city": "Donskoy",
    "city_ascii": "Donskoy",
    "lat": 53.9667,
    "lng": 38.3167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Tul’skaya Oblast’",
    "capital": "",
    "population": 63631,
    "id": 1643224911
  },
  {
    "city": "Tādepalle",
    "city_ascii": "Tadepalle",
    "lat": 16.4667,
    "lng": 80.6,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Andhra Pradesh",
    "capital": "",
    "population": 64149,
    "id": 1356511543
  },
  {
    "city": "La Carlota",
    "city_ascii": "La Carlota",
    "lat": 10.4167,
    "lng": 122.9167,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Negros Occidental",
    "capital": "",
    "population": 64469,
    "id": 1608234277
  },
  {
    "city": "Eden Prairie",
    "city_ascii": "Eden Prairie",
    "lat": 44.8488,
    "lng": -93.4595,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 64893,
    "id": 1840007821
  },
  {
    "city": "Port Orange",
    "city_ascii": "Port Orange",
    "lat": 29.1084,
    "lng": -81.0137,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 64842,
    "id": 1840015077
  },
  {
    "city": "Teoloyucan",
    "city_ascii": "Teoloyucan",
    "lat": 19.7442,
    "lng": -99.1811,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "México",
    "capital": "minor",
    "population": 63115,
    "id": 1484003827
  },
  {
    "city": "Neubrandenburg",
    "city_ascii": "Neubrandenburg",
    "lat": 53.5569,
    "lng": 13.2608,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Mecklenburg-Western Pomerania",
    "capital": "minor",
    "population": 64086,
    "id": 1276954105
  },
  {
    "city": "Dublin",
    "city_ascii": "Dublin",
    "lat": 37.7161,
    "lng": -121.8963,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 64826,
    "id": 1840018916
  },
  {
    "city": "Asahi",
    "city_ascii": "Asahi",
    "lat": 35.7203,
    "lng": 140.6467,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Chiba",
    "capital": "",
    "population": 64302,
    "id": 1392803608
  },
  {
    "city": "Carmichael",
    "city_ascii": "Carmichael",
    "lat": 38.6337,
    "lng": -121.323,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 64785,
    "id": 1840017579
  },
  {
    "city": "Gadwāl",
    "city_ascii": "Gadwal",
    "lat": 16.23,
    "lng": 77.8,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Telangana",
    "capital": "",
    "population": 63177,
    "id": 1356741221
  },
  {
    "city": "Rafḩā",
    "city_ascii": "Rafha",
    "lat": 29.6202,
    "lng": 43.4948,
    "country": "Saudi Arabia",
    "iso2": "SA",
    "iso3": "SAU",
    "admin_name": "Al Ḩudūd ash Shamālīyah",
    "capital": "",
    "population": 64755,
    "id": 1682612877
  },
  {
    "city": "Grand Forks",
    "city_ascii": "Grand Forks",
    "lat": 47.9218,
    "lng": -97.0886,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Dakota",
    "capital": "",
    "population": 64748,
    "id": 1840000101
  },
  {
    "city": "Tsuruga",
    "city_ascii": "Tsuruga",
    "lat": 35.6372,
    "lng": 136.0806,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Fukui",
    "capital": "",
    "population": 64474,
    "id": 1392056382
  },
  {
    "city": "Santo Domingo Tehuantepec",
    "city_ascii": "Santo Domingo Tehuantepec",
    "lat": 16.3184,
    "lng": -95.2478,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Oaxaca",
    "capital": "minor",
    "population": 64639,
    "id": 1484904308
  },
  {
    "city": "Binga",
    "city_ascii": "Binga",
    "lat": 2.3834,
    "lng": 20.42,
    "country": "Congo (Kinshasa)",
    "iso2": "CD",
    "iso3": "COD",
    "admin_name": "Mongala",
    "capital": "",
    "population": 64639,
    "id": 1180236070
  },
  {
    "city": "Noblesville",
    "city_ascii": "Noblesville",
    "lat": 40.0354,
    "lng": -86.0042,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 64668,
    "id": 1840013891
  },
  {
    "city": "Jatani",
    "city_ascii": "Jatani",
    "lat": 20.17,
    "lng": 85.7,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Odisha",
    "capital": "",
    "population": 63697,
    "id": 1356087327
  },
  {
    "city": "Rahat",
    "city_ascii": "Rahat",
    "lat": 31.3925,
    "lng": 34.7544,
    "country": "Israel",
    "iso2": "IL",
    "iso3": "ISR",
    "admin_name": "Southern",
    "capital": "",
    "population": 64462,
    "id": 1376207828
  },
  {
    "city": "Apaseo el Alto",
    "city_ascii": "Apaseo el Alto",
    "lat": 20.45,
    "lng": -100.6167,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Guanajuato",
    "capital": "minor",
    "population": 64443,
    "id": 1484638153
  },
  {
    "city": "Qinggang",
    "city_ascii": "Qinggang",
    "lat": 46.69,
    "lng": 126.1,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Heilongjiang",
    "capital": "minor",
    "population": 64182,
    "id": 1156115921
  },
  {
    "city": "San Felipe",
    "city_ascii": "San Felipe",
    "lat": -32.7507,
    "lng": -70.7251,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Valparaíso",
    "capital": "minor",
    "population": 64120,
    "id": 1152329463
  },
  {
    "city": "San Clemente",
    "city_ascii": "San Clemente",
    "lat": 33.4498,
    "lng": -117.6103,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 64558,
    "id": 1840021962
  },
  {
    "city": "Ban Lam Sam Kaeo",
    "city_ascii": "Ban Lam Sam Kaeo",
    "lat": 13.9726,
    "lng": 100.6506,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Pathum Thani",
    "capital": "",
    "population": 63271,
    "id": 1764012428
  },
  {
    "city": "Santiago Tianguistenco",
    "city_ascii": "Santiago Tianguistenco",
    "lat": 19.1797,
    "lng": -99.4706,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "México",
    "capital": "minor",
    "population": 64365,
    "id": 1484113134
  },
  {
    "city": "Brentwood",
    "city_ascii": "Brentwood",
    "lat": 37.9355,
    "lng": -121.7191,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 64474,
    "id": 1840018904
  },
  {
    "city": "Kristiansand",
    "city_ascii": "Kristiansand",
    "lat": 58.1467,
    "lng": 7.9956,
    "country": "Norway",
    "iso2": "NO",
    "iso3": "NOR",
    "admin_name": "Vest-Agder",
    "capital": "minor",
    "population": 64057,
    "id": 1578642977
  },
  {
    "city": "Sittingbourne",
    "city_ascii": "Sittingbourne",
    "lat": 51.34,
    "lng": 0.74,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Kent",
    "capital": "",
    "population": 62500,
    "id": 1826787936
  },
  {
    "city": "Zvornik",
    "city_ascii": "Zvornik",
    "lat": 44.3833,
    "lng": 19.1,
    "country": "Bosnia And Herzegovina",
    "iso2": "BA",
    "iso3": "BIH",
    "admin_name": "Srpska, Republika",
    "capital": "minor",
    "population": 63686,
    "id": 1070289722
  },
  {
    "city": "Grevenbroich",
    "city_ascii": "Grevenbroich",
    "lat": 51.0883,
    "lng": 6.5875,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 63620,
    "id": 1276215861
  },
  {
    "city": "Asbest",
    "city_ascii": "Asbest",
    "lat": 57,
    "lng": 61.4667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sverdlovskaya Oblast’",
    "capital": "",
    "population": 64091,
    "id": 1643561210
  },
  {
    "city": "Póvoa de Varzim",
    "city_ascii": "Povoa de Varzim",
    "lat": 41.3916,
    "lng": -8.7571,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Porto",
    "capital": "minor",
    "population": 63408,
    "id": 1620617429
  },
  {
    "city": "Bandar-e Genāveh",
    "city_ascii": "Bandar-e Genaveh",
    "lat": 29.5833,
    "lng": 50.5167,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Būshehr",
    "capital": "minor",
    "population": 64110,
    "id": 1364268734
  },
  {
    "city": "Eastvale",
    "city_ascii": "Eastvale",
    "lat": 33.9617,
    "lng": -117.5802,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 64157,
    "id": 1840028421
  },
  {
    "city": "Nawá",
    "city_ascii": "Nawa",
    "lat": 32.8833,
    "lng": 36.05,
    "country": "Syria",
    "iso2": "SY",
    "iso3": "SYR",
    "admin_name": "Dar‘ā",
    "capital": "minor",
    "population": 63676,
    "id": 1760963983
  },
  {
    "city": "At Tall",
    "city_ascii": "At Tall",
    "lat": 33.6103,
    "lng": 36.3106,
    "country": "Syria",
    "iso2": "SY",
    "iso3": "SYR",
    "admin_name": "Rīf Dimashq",
    "capital": "minor",
    "population": 63554,
    "id": 1760718626
  },
  {
    "city": "Mazabuka",
    "city_ascii": "Mazabuka",
    "lat": -15.86,
    "lng": 27.76,
    "country": "Zambia",
    "iso2": "ZM",
    "iso3": "ZMB",
    "admin_name": "Southern",
    "capital": "",
    "population": 64006,
    "id": 1894390715
  },
  {
    "city": "Chapel Hill",
    "city_ascii": "Chapel Hill",
    "lat": 35.927,
    "lng": -79.0391,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 64051,
    "id": 1840016152
  },
  {
    "city": "Rocky Mount",
    "city_ascii": "Rocky Mount",
    "lat": 35.9676,
    "lng": -77.8048,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 64018,
    "id": 1840014490
  },
  {
    "city": "Lugano",
    "city_ascii": "Lugano",
    "lat": 46.0103,
    "lng": 8.9625,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Ticino",
    "capital": "",
    "population": 63185,
    "id": 1756503816
  },
  {
    "city": "Haverhill",
    "city_ascii": "Haverhill",
    "lat": 42.7838,
    "lng": -71.0871,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 64014,
    "id": 1840002450
  },
  {
    "city": "Gaya",
    "city_ascii": "Gaya",
    "lat": 11.8878,
    "lng": 3.4467,
    "country": "Niger",
    "iso2": "NE",
    "iso3": "NER",
    "admin_name": "Dosso",
    "capital": "minor",
    "population": 63815,
    "id": 1562298335
  },
  {
    "city": "Klintsy",
    "city_ascii": "Klintsy",
    "lat": 52.7528,
    "lng": 32.2361,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Bryanskaya Oblast’",
    "capital": "",
    "population": 62936,
    "id": 1643620360
  },
  {
    "city": "Fujioka",
    "city_ascii": "Fujioka",
    "lat": 36.2667,
    "lng": 139.0667,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Gunma",
    "capital": "",
    "population": 63556,
    "id": 1392000076
  },
  {
    "city": "Woking",
    "city_ascii": "Woking",
    "lat": 51.3162,
    "lng": -0.561,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Surrey",
    "capital": "",
    "population": 62796,
    "id": 1826205427
  },
  {
    "city": "Zarand",
    "city_ascii": "Zarand",
    "lat": 30.8128,
    "lng": 56.5639,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Kermān",
    "capital": "minor",
    "population": 63744,
    "id": 1364870175
  },
  {
    "city": "Beloit",
    "city_ascii": "Beloit",
    "lat": 42.523,
    "lng": -89.0184,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 63762,
    "id": 1840002471
  },
  {
    "city": "Quimper",
    "city_ascii": "Quimper",
    "lat": 47.9967,
    "lng": -4.0964,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Bretagne",
    "capital": "minor",
    "population": 62985,
    "id": 1250490127
  },
  {
    "city": "Nankana Sahib",
    "city_ascii": "Nankana Sahib",
    "lat": 31.4492,
    "lng": 73.7124,
    "country": "Pakistan",
    "iso2": "PK",
    "iso3": "PAK",
    "admin_name": "Punjab",
    "capital": "",
    "population": 63073,
    "id": 1586529540
  },
  {
    "city": "Velika Gorica",
    "city_ascii": "Velika Gorica",
    "lat": 45.7,
    "lng": 16.0667,
    "country": "Croatia",
    "iso2": "HR",
    "iso3": "HRV",
    "admin_name": "Zagrebačka Županija",
    "capital": "minor",
    "population": 63517,
    "id": 1191113594
  },
  {
    "city": "Gloucester",
    "city_ascii": "Gloucester",
    "lat": 39.7924,
    "lng": -75.0363,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 63700,
    "id": 1840081684
  },
  {
    "city": "Chincha Alta",
    "city_ascii": "Chincha Alta",
    "lat": -13.45,
    "lng": -76.1333,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "Ica",
    "capital": "",
    "population": 63671,
    "id": 1604035626
  },
  {
    "city": "Shiroi",
    "city_ascii": "Shiroi",
    "lat": 35.7917,
    "lng": 140.0564,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Chiba",
    "capital": "",
    "population": 61931,
    "id": 1392006213
  },
  {
    "city": "San Juan de Sabinas",
    "city_ascii": "San Juan de Sabinas",
    "lat": 27.9167,
    "lng": -101.3,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Coahuila de Zaragoza",
    "capital": "minor",
    "population": 63522,
    "id": 1484962977
  },
  {
    "city": "Budënnovsk",
    "city_ascii": "Budennovsk",
    "lat": 44.7833,
    "lng": 44.15,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Stavropol’skiy Kray",
    "capital": "",
    "population": 62495,
    "id": 1643010057
  },
  {
    "city": "Glens Falls",
    "city_ascii": "Glens Falls",
    "lat": 43.3109,
    "lng": -73.6459,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 63499,
    "id": 1840000345
  },
  {
    "city": "Graaff-Reinet",
    "city_ascii": "Graaff-Reinet",
    "lat": -32.2522,
    "lng": 24.5406,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "Eastern Cape",
    "capital": "",
    "population": 62896,
    "id": 1710616617
  },
  {
    "city": "Aïn Sefra",
    "city_ascii": "Ain Sefra",
    "lat": 32.7604,
    "lng": -0.5799,
    "country": "Algeria",
    "iso2": "DZ",
    "iso3": "DZA",
    "admin_name": "Naama",
    "capital": "",
    "population": 63420,
    "id": 1012884513
  },
  {
    "city": "Herten",
    "city_ascii": "Herten",
    "lat": 51.6,
    "lng": 7.1333,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 61791,
    "id": 1276440470
  },
  {
    "city": "Granby",
    "city_ascii": "Granby",
    "lat": 45.4,
    "lng": -72.7333,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 63433,
    "id": 1124502071
  },
  {
    "city": "Tangub",
    "city_ascii": "Tangub",
    "lat": 8.0667,
    "lng": 123.75,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Misamis Occidental",
    "capital": "",
    "population": 63011,
    "id": 1608158776
  },
  {
    "city": "Roeselare",
    "city_ascii": "Roeselare",
    "lat": 50.9447,
    "lng": 3.1233,
    "country": "Belgium",
    "iso2": "BE",
    "iso3": "BEL",
    "admin_name": "Flanders",
    "capital": "minor",
    "population": 62301,
    "id": 1056334112
  },
  {
    "city": "Moca",
    "city_ascii": "Moca",
    "lat": 19.397,
    "lng": -70.523,
    "country": "Dominican Republic",
    "iso2": "DO",
    "iso3": "DOM",
    "admin_name": "Cibao Norte",
    "capital": "minor",
    "population": 61834,
    "id": 1214863528
  },
  {
    "city": "Castro Valley",
    "city_ascii": "Castro Valley",
    "lat": 37.7091,
    "lng": -122.0631,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 63288,
    "id": 1840017620
  },
  {
    "city": "Medicine Hat",
    "city_ascii": "Medicine Hat",
    "lat": 50.0417,
    "lng": -110.6775,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Alberta",
    "capital": "",
    "population": 63260,
    "id": 1124303972
  },
  {
    "city": "Fairbanks",
    "city_ascii": "Fairbanks",
    "lat": 64.8353,
    "lng": -147.6534,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alaska",
    "capital": "",
    "population": 63245,
    "id": 1840023463
  },
  {
    "city": "Volsk",
    "city_ascii": "Volsk",
    "lat": 52.05,
    "lng": 47.3833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Saratovskaya Oblast’",
    "capital": "",
    "population": 63212,
    "id": 1643293120
  },
  {
    "city": "Springfield",
    "city_ascii": "Springfield",
    "lat": 44.0538,
    "lng": -122.981,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 63230,
    "id": 1840021263
  },
  {
    "city": "Balboa",
    "city_ascii": "Balboa",
    "lat": 8.9571,
    "lng": -79.5593,
    "country": "Panama",
    "iso2": "PA",
    "iso3": "PAN",
    "admin_name": "Panamá",
    "capital": "",
    "population": 62882,
    "id": 1591922184
  },
  {
    "city": "Rhondda",
    "city_ascii": "Rhondda",
    "lat": 51.6159,
    "lng": -3.4175,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Rhondda Cynon Taff",
    "capital": "",
    "population": 62545,
    "id": 1826659053
  },
  {
    "city": "Novaya Balakhna",
    "city_ascii": "Novaya Balakhna",
    "lat": 56.4943,
    "lng": 43.5944,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Nizhegorodskaya Oblast’",
    "capital": "",
    "population": 63083,
    "id": 1643658083
  },
  {
    "city": "Grande Prairie",
    "city_ascii": "Grande Prairie",
    "lat": 55.1708,
    "lng": -118.7947,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Alberta",
    "capital": "",
    "population": 63166,
    "id": 1124505481
  },
  {
    "city": "Oudtshoorn",
    "city_ascii": "Oudtshoorn",
    "lat": -33.5833,
    "lng": 22.2,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "Western Cape",
    "capital": "",
    "population": 61507,
    "id": 1710502855
  },
  {
    "city": "Johnstown",
    "city_ascii": "Johnstown",
    "lat": 40.3258,
    "lng": -78.9193,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 63141,
    "id": 1840001116
  },
  {
    "city": "West Hartford",
    "city_ascii": "West Hartford",
    "lat": 41.7669,
    "lng": -72.7536,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 63127,
    "id": 1840035652
  },
  {
    "city": "Bannu",
    "city_ascii": "Bannu",
    "lat": 32.9889,
    "lng": 70.6056,
    "country": "Pakistan",
    "iso2": "PK",
    "iso3": "PAK",
    "admin_name": "Khyber Pakhtunkhwa",
    "capital": "minor",
    "population": 62191,
    "id": 1586858934
  },
  {
    "city": "Łomża",
    "city_ascii": "Lomza",
    "lat": 53.1833,
    "lng": 22.0833,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Podlaskie",
    "capital": "minor",
    "population": 62700,
    "id": 1616678952
  },
  {
    "city": "Çaldıran",
    "city_ascii": "Caldiran",
    "lat": 39.1419,
    "lng": 43.9139,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Van",
    "capital": "minor",
    "population": 63013,
    "id": 1792259607
  },
  {
    "city": "Kasongo",
    "city_ascii": "Kasongo",
    "lat": -4.45,
    "lng": 26.66,
    "country": "Congo (Kinshasa)",
    "iso2": "CD",
    "iso3": "COD",
    "admin_name": "Maniema",
    "capital": "",
    "population": 63000,
    "id": 1180000473
  },
  {
    "city": "Nago",
    "city_ascii": "Nago",
    "lat": 26.5917,
    "lng": 127.9775,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Okinawa",
    "capital": "",
    "population": 62725,
    "id": 1392102097
  },
  {
    "city": "Coon Rapids",
    "city_ascii": "Coon Rapids",
    "lat": 45.1755,
    "lng": -93.3095,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 62998,
    "id": 1840006714
  },
  {
    "city": "Elmira",
    "city_ascii": "Elmira",
    "lat": 42.0938,
    "lng": -76.8097,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 62989,
    "id": 1840000472
  },
  {
    "city": "Palmela",
    "city_ascii": "Palmela",
    "lat": 38.5667,
    "lng": -8.9,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Setúbal",
    "capital": "minor",
    "population": 62831,
    "id": 1620934052
  },
  {
    "city": "Yurimaguas",
    "city_ascii": "Yurimaguas",
    "lat": -5.9,
    "lng": -76.0833,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "Loreto",
    "capital": "",
    "population": 62903,
    "id": 1604248295
  },
  {
    "city": "Deva",
    "city_ascii": "Deva",
    "lat": 45.8719,
    "lng": 22.9117,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Hunedoara",
    "capital": "",
    "population": 61123,
    "id": 1642728479
  },
  {
    "city": "Mankato",
    "city_ascii": "Mankato",
    "lat": 44.1712,
    "lng": -93.9773,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 62889,
    "id": 1840008975
  },
  {
    "city": "Nkawkaw",
    "city_ascii": "Nkawkaw",
    "lat": 6.5505,
    "lng": -0.78,
    "country": "Ghana",
    "iso2": "GH",
    "iso3": "GHA",
    "admin_name": "Eastern",
    "capital": "",
    "population": 62667,
    "id": 1288350107
  },
  {
    "city": "Albany",
    "city_ascii": "Albany",
    "lat": 44.6274,
    "lng": -123.0966,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 62880,
    "id": 1840018605
  },
  {
    "city": "Mut",
    "city_ascii": "Mut",
    "lat": 36.6458,
    "lng": 33.4375,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Mersin",
    "capital": "minor",
    "population": 62853,
    "id": 1792759065
  },
  {
    "city": "Cruz Alta",
    "city_ascii": "Cruz Alta",
    "lat": -28.6386,
    "lng": -53.6064,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Rio Grande do Sul",
    "capital": "",
    "population": 62821,
    "id": 1076542905
  },
  {
    "city": "Rossosh",
    "city_ascii": "Rossosh",
    "lat": 50.1983,
    "lng": 39.5672,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Voronezhskaya Oblast’",
    "capital": "minor",
    "population": 62827,
    "id": 1643115757
  },
  {
    "city": "Kolomyya",
    "city_ascii": "Kolomyya",
    "lat": 48.5167,
    "lng": 25.0333,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Ivano-Frankivs’ka Oblast’",
    "capital": "minor",
    "population": 61269,
    "id": 1804285171
  },
  {
    "city": "Dundalk",
    "city_ascii": "Dundalk",
    "lat": 39.2703,
    "lng": -76.4942,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 62768,
    "id": 1840005681
  },
  {
    "city": "Margate",
    "city_ascii": "Margate",
    "lat": 51.385,
    "lng": 1.3838,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Kent",
    "capital": "",
    "population": 61223,
    "id": 1826808237
  },
  {
    "city": "Revda",
    "city_ascii": "Revda",
    "lat": 56.8,
    "lng": 59.9167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sverdlovskaya Oblast’",
    "capital": "",
    "population": 62687,
    "id": 1643633975
  },
  {
    "city": "Borisoglebsk",
    "city_ascii": "Borisoglebsk",
    "lat": 51.3667,
    "lng": 42.0833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Voronezhskaya Oblast’",
    "capital": "",
    "population": 62669,
    "id": 1643002462
  },
  {
    "city": "Kotlas",
    "city_ascii": "Kotlas",
    "lat": 61.25,
    "lng": 46.65,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Arkhangel’skaya Oblast’",
    "capital": "",
    "population": 61805,
    "id": 1643953441
  },
  {
    "city": "Encinitas",
    "city_ascii": "Encinitas",
    "lat": 33.049,
    "lng": -117.2613,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 62709,
    "id": 1840020619
  },
  {
    "city": "Montélimar",
    "city_ascii": "Montelimar",
    "lat": 44.5581,
    "lng": 4.7508,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Auvergne-Rhône-Alpes",
    "capital": "",
    "population": 61861,
    "id": 1250664551
  },
  {
    "city": "Runcorn",
    "city_ascii": "Runcorn",
    "lat": 53.341,
    "lng": -2.729,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Halton",
    "capital": "",
    "population": 61789,
    "id": 1826349439
  },
  {
    "city": "Ogōshi",
    "city_ascii": "Ogoshi",
    "lat": 32.8858,
    "lng": 130.7897,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Kumamoto",
    "capital": "",
    "population": 61531,
    "id": 1392448942
  },
  {
    "city": "Zelenogorsk",
    "city_ascii": "Zelenogorsk",
    "lat": 56.1,
    "lng": 94.5833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Krasnoyarskiy Kray",
    "capital": "",
    "population": 62245,
    "id": 1643010181
  },
  {
    "city": "Leander",
    "city_ascii": "Leander",
    "lat": 30.5728,
    "lng": -97.8618,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 62608,
    "id": 1840020877
  },
  {
    "city": "Mobārakeh",
    "city_ascii": "Mobarakeh",
    "lat": 32.3464,
    "lng": 51.5044,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Eşfahān",
    "capital": "minor",
    "population": 62454,
    "id": 1364110841
  },
  {
    "city": "Tendō",
    "city_ascii": "Tendo",
    "lat": 38.3622,
    "lng": 140.3783,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Yamagata",
    "capital": "",
    "population": 62032,
    "id": 1392389398
  },
  {
    "city": "Mayagüez",
    "city_ascii": "Mayaguez",
    "lat": 18.2003,
    "lng": -67.1397,
    "country": "Puerto Rico",
    "iso2": "PR",
    "iso3": "PRI",
    "admin_name": "Puerto Rico",
    "capital": "",
    "population": 62579,
    "id": 1630035520
  },
  {
    "city": "Leninogorsk",
    "city_ascii": "Leninogorsk",
    "lat": 54.5989,
    "lng": 52.4423,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Tatarstan",
    "capital": "",
    "population": 62531,
    "id": 1643010350
  },
  {
    "city": "Greenwich",
    "city_ascii": "Greenwich",
    "lat": 41.0665,
    "lng": -73.6368,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 62574,
    "id": 1840034747
  },
  {
    "city": "Wels",
    "city_ascii": "Wels",
    "lat": 48.15,
    "lng": 14.0167,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Oberösterreich",
    "capital": "minor",
    "population": 61233,
    "id": 1040833210
  },
  {
    "city": "Parkersburg",
    "city_ascii": "Parkersburg",
    "lat": 39.2624,
    "lng": -81.542,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "West Virginia",
    "capital": "",
    "population": 62566,
    "id": 1840005786
  },
  {
    "city": "Kayes",
    "city_ascii": "Kayes",
    "lat": -4.1806,
    "lng": 13.2889,
    "country": "Congo (Brazzaville)",
    "iso2": "CG",
    "iso3": "COG",
    "admin_name": "Bouenza",
    "capital": "",
    "population": 62521,
    "id": 1178012015
  },
  {
    "city": "Villa Carlos Paz",
    "city_ascii": "Villa Carlos Paz",
    "lat": -31.4,
    "lng": -64.5167,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Córdoba",
    "capital": "",
    "population": 62423,
    "id": 1032854975
  },
  {
    "city": "Waltham",
    "city_ascii": "Waltham",
    "lat": 42.3889,
    "lng": -71.2423,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 62495,
    "id": 1840000428
  },
  {
    "city": "Tanjungpandan",
    "city_ascii": "Tanjungpandan",
    "lat": -2.75,
    "lng": 107.65,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Kepulauan Bangka Belitung",
    "capital": "minor",
    "population": 62374,
    "id": 1360732632
  },
  {
    "city": "Bethesda",
    "city_ascii": "Bethesda",
    "lat": 38.9866,
    "lng": -77.1188,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 62448,
    "id": 1840005826
  },
  {
    "city": "Morecambe",
    "city_ascii": "Morecambe",
    "lat": 54.073,
    "lng": -2.87,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Lancashire",
    "capital": "",
    "population": 60768,
    "id": 1826651603
  },
  {
    "city": "Boryspil’",
    "city_ascii": "Boryspil'",
    "lat": 50.35,
    "lng": 30.95,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Kyyivs’ka Oblast’",
    "capital": "minor",
    "population": 62281,
    "id": 1804757407
  },
  {
    "city": "Villach",
    "city_ascii": "Villach",
    "lat": 46.6167,
    "lng": 13.8333,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Kärnten",
    "capital": "minor",
    "population": 61879,
    "id": 1040202364
  },
  {
    "city": "Tuapse",
    "city_ascii": "Tuapse",
    "lat": 44.1,
    "lng": 39.0833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Krasnodarskiy Kray",
    "capital": "minor",
    "population": 62269,
    "id": 1643910130
  },
  {
    "city": "Dajabón",
    "city_ascii": "Dajabon",
    "lat": 19.5667,
    "lng": -71.71,
    "country": "Dominican Republic",
    "iso2": "DO",
    "iso3": "DOM",
    "admin_name": "Cibao Noroeste",
    "capital": "minor",
    "population": 62046,
    "id": 1214367800
  },
  {
    "city": "San Luis Obispo",
    "city_ascii": "San Luis Obispo",
    "lat": 35.2669,
    "lng": -120.6691,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 62290,
    "id": 1840021734
  },
  {
    "city": "Figueira da Foz",
    "city_ascii": "Figueira da Foz",
    "lat": 40.1508,
    "lng": -8.8618,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Coimbra",
    "capital": "minor",
    "population": 62125,
    "id": 1620826473
  },
  {
    "city": "Benevento",
    "city_ascii": "Benevento",
    "lat": 41.1256,
    "lng": 14.7811,
    "country": "Italy",
    "iso2": "IT",
    "iso3": "ITA",
    "admin_name": "Campania",
    "capital": "minor",
    "population": 61791,
    "id": 1380723198
  },
  {
    "city": "Bergheim",
    "city_ascii": "Bergheim",
    "lat": 50.9667,
    "lng": 6.65,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "minor",
    "population": 61612,
    "id": 1276980188
  },
  {
    "city": "Sebring",
    "city_ascii": "Sebring",
    "lat": 27.477,
    "lng": -81.453,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 62250,
    "id": 1840015986
  },
  {
    "city": "Chistopol",
    "city_ascii": "Chistopol",
    "lat": 55.3648,
    "lng": 50.6407,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Tatarstan",
    "capital": "",
    "population": 62200,
    "id": 1643992511
  },
  {
    "city": "Puerto Peñasco",
    "city_ascii": "Puerto Penasco",
    "lat": 31.3167,
    "lng": -113.5369,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Sonora",
    "capital": "minor",
    "population": 62177,
    "id": 1484567937
  },
  {
    "city": "Council Bluffs",
    "city_ascii": "Council Bluffs",
    "lat": 41.2369,
    "lng": -95.8517,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Iowa",
    "capital": "",
    "population": 62166,
    "id": 1840007117
  },
  {
    "city": "Ocoyoacac",
    "city_ascii": "Ocoyoacac",
    "lat": 19.2739,
    "lng": -99.4606,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Morelos",
    "capital": "minor",
    "population": 61805,
    "id": 1484479100
  },
  {
    "city": "Penápolis",
    "city_ascii": "Penapolis",
    "lat": -21.42,
    "lng": -50.0778,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 62071,
    "id": 1076587749
  },
  {
    "city": "Aisai",
    "city_ascii": "Aisai",
    "lat": 35.15,
    "lng": 136.7333,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Aichi",
    "capital": "",
    "population": 61234,
    "id": 1392254584
  },
  {
    "city": "Naro-Fominsk",
    "city_ascii": "Naro-Fominsk",
    "lat": 55.3833,
    "lng": 36.7333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Moskovskaya Oblast’",
    "capital": "",
    "population": 62002,
    "id": 1643037888
  },
  {
    "city": "Hamilton",
    "city_ascii": "Hamilton",
    "lat": 39.3938,
    "lng": -84.5653,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 62082,
    "id": 1840003803
  },
  {
    "city": "Ferkessédougou",
    "city_ascii": "Ferkessedougou",
    "lat": 9.5928,
    "lng": -5.1944,
    "country": "Côte D’Ivoire",
    "iso2": "CI",
    "iso3": "CIV",
    "admin_name": "Savanes",
    "capital": "minor",
    "population": 62008,
    "id": 1384974005
  },
  {
    "city": "Moore",
    "city_ascii": "Moore",
    "lat": 35.3293,
    "lng": -97.4758,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oklahoma",
    "capital": "",
    "population": 62055,
    "id": 1840020449
  },
  {
    "city": "Zaraza",
    "city_ascii": "Zaraza",
    "lat": 9.3394,
    "lng": -65.3167,
    "country": "Venezuela",
    "iso2": "VE",
    "iso3": "VEN",
    "admin_name": "Guárico",
    "capital": "minor",
    "population": 62027,
    "id": 1862643668
  },
  {
    "city": "San Francisco",
    "city_ascii": "San Francisco",
    "lat": -31.4356,
    "lng": -62.0714,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Córdoba",
    "capital": "minor",
    "population": 62000,
    "id": 1032491643
  },
  {
    "city": "Civitavecchia",
    "city_ascii": "Civitavecchia",
    "lat": 42.1004,
    "lng": 11.8,
    "country": "Italy",
    "iso2": "IT",
    "iso3": "ITA",
    "admin_name": "Lazio",
    "capital": "",
    "population": 61316,
    "id": 1380282443
  },
  {
    "city": "Palm Harbor",
    "city_ascii": "Palm Harbor",
    "lat": 28.0847,
    "lng": -82.7481,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 61995,
    "id": 1840014143
  },
  {
    "city": "Casa Grande",
    "city_ascii": "Casa Grande",
    "lat": 32.9068,
    "lng": -111.7624,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 61966,
    "id": 1840019364
  },
  {
    "city": "Monessen",
    "city_ascii": "Monessen",
    "lat": 40.1519,
    "lng": -79.8828,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 61960,
    "id": 1840001146
  },
  {
    "city": "San Antonio del Táchira",
    "city_ascii": "San Antonio del Tachira",
    "lat": 7.8145,
    "lng": -72.4431,
    "country": "Venezuela",
    "iso2": "VE",
    "iso3": "VEN",
    "admin_name": "Táchira",
    "capital": "minor",
    "population": 61630,
    "id": 1862415614
  },
  {
    "city": "Mielec",
    "city_ascii": "Mielec",
    "lat": 50.2833,
    "lng": 21.4333,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Podkarpackie",
    "capital": "minor",
    "population": 60628,
    "id": 1616825607
  },
  {
    "city": "Polevskoy",
    "city_ascii": "Polevskoy",
    "lat": 56.45,
    "lng": 60.1833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sverdlovskaya Oblast’",
    "capital": "",
    "population": 61853,
    "id": 1643717197
  },
  {
    "city": "Coburg",
    "city_ascii": "Coburg",
    "lat": 50.2666,
    "lng": 10.9666,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "minor",
    "population": 61054,
    "id": 1276428862
  },
  {
    "city": "Sätbaev",
    "city_ascii": "Satbaev",
    "lat": 47.9,
    "lng": 67.5333,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Qaraghandy",
    "capital": "",
    "population": 61883,
    "id": 1398506074
  },
  {
    "city": "Sarandí",
    "city_ascii": "Sarandi",
    "lat": -34.6833,
    "lng": -58.3333,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires",
    "capital": "",
    "population": 60752,
    "id": 1032993271
  },
  {
    "city": "Derik",
    "city_ascii": "Derik",
    "lat": 37.3644,
    "lng": 40.2689,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Mardin",
    "capital": "minor",
    "population": 61830,
    "id": 1792566919
  },
  {
    "city": "Heroica Caborca",
    "city_ascii": "Heroica Caborca",
    "lat": 30.7167,
    "lng": -112.15,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Sonora",
    "capital": "minor",
    "population": 59922,
    "id": 1484993432
  },
  {
    "city": "Ar Rastan",
    "city_ascii": "Ar Rastan",
    "lat": 34.9167,
    "lng": 36.7333,
    "country": "Syria",
    "iso2": "SY",
    "iso3": "SYR",
    "admin_name": "Ḩimş",
    "capital": "minor",
    "population": 61176,
    "id": 1760205423
  },
  {
    "city": "Itoman",
    "city_ascii": "Itoman",
    "lat": 26.1236,
    "lng": 127.6658,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Okinawa",
    "capital": "",
    "population": 60546,
    "id": 1392029438
  },
  {
    "city": "Zamora",
    "city_ascii": "Zamora",
    "lat": 41.5033,
    "lng": -5.7556,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Castille-Leon",
    "capital": "minor",
    "population": 61406,
    "id": 1724611317
  },
  {
    "city": "Lysva",
    "city_ascii": "Lysva",
    "lat": 58.1086,
    "lng": 57.8053,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Permskiy Kray",
    "capital": "",
    "population": 61752,
    "id": 1643566094
  },
  {
    "city": "Tríkala",
    "city_ascii": "Trikala",
    "lat": 39.55,
    "lng": 21.7667,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Thessalía",
    "capital": "minor",
    "population": 61653,
    "id": 1300446578
  },
  {
    "city": "Slutsk",
    "city_ascii": "Slutsk",
    "lat": 53.0167,
    "lng": 27.55,
    "country": "Belarus",
    "iso2": "BY",
    "iso3": "BLR",
    "admin_name": "Minskaya Voblasts’",
    "capital": "minor",
    "population": 61700,
    "id": 1112178336
  },
  {
    "city": "Friedrichshafen",
    "city_ascii": "Friedrichshafen",
    "lat": 47.6542,
    "lng": 9.4792,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 60865,
    "id": 1276002705
  },
  {
    "city": "Schwäbisch Gmünd",
    "city_ascii": "Schwabisch Gmund",
    "lat": 48.8,
    "lng": 9.8,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 61186,
    "id": 1276897251
  },
  {
    "city": "Rubizhne",
    "city_ascii": "Rubizhne",
    "lat": 49.0167,
    "lng": 38.3667,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Luhans’ka Oblast’",
    "capital": "",
    "population": 59951,
    "id": 1804246584
  },
  {
    "city": "St. Thomas",
    "city_ascii": "St. Thomas",
    "lat": 42.775,
    "lng": -81.1833,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 61707,
    "id": 1124790209
  },
  {
    "city": "Sibay",
    "city_ascii": "Sibay",
    "lat": 52.7,
    "lng": 58.65,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Bashkortostan",
    "capital": "",
    "population": 61266,
    "id": 1643012532
  },
  {
    "city": "Sawahlunto",
    "city_ascii": "Sawahlunto",
    "lat": -0.6828,
    "lng": 100.7783,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Sumatera Barat",
    "capital": "",
    "population": 61427,
    "id": 1360188622
  },
  {
    "city": "Tarnowskie Góry",
    "city_ascii": "Tarnowskie Gory",
    "lat": 50.45,
    "lng": 18.8667,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Śląskie",
    "capital": "minor",
    "population": 60879,
    "id": 1616868201
  },
  {
    "city": "Fairfield",
    "city_ascii": "Fairfield",
    "lat": 41.1775,
    "lng": -73.2733,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 61598,
    "id": 1840034686
  },
  {
    "city": "Airdrie",
    "city_ascii": "Airdrie",
    "lat": 51.2917,
    "lng": -114.0144,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Alberta",
    "capital": "",
    "population": 61581,
    "id": 1124990202
  },
  {
    "city": "Wanparti",
    "city_ascii": "Wanparti",
    "lat": 16.361,
    "lng": 78.0627,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Telangana",
    "capital": "",
    "population": 60949,
    "id": 1356934970
  },
  {
    "city": "Uzunköprü",
    "city_ascii": "Uzunkopru",
    "lat": 41.2669,
    "lng": 26.6875,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Edirne",
    "capital": "minor",
    "population": 61485,
    "id": 1792592620
  },
  {
    "city": "Rome",
    "city_ascii": "Rome",
    "lat": 34.2661,
    "lng": -85.1862,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 61537,
    "id": 1840014681
  },
  {
    "city": "Garbsen",
    "city_ascii": "Garbsen",
    "lat": 52.4183,
    "lng": 9.5981,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 60754,
    "id": 1276318243
  },
  {
    "city": "Ban Map Ta Phut",
    "city_ascii": "Ban Map Ta Phut",
    "lat": 12.7258,
    "lng": 101.1722,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Rayong",
    "capital": "",
    "population": 60928,
    "id": 1764852224
  },
  {
    "city": "Millcreek",
    "city_ascii": "Millcreek",
    "lat": 40.6892,
    "lng": -111.8291,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Utah",
    "capital": "",
    "population": 61450,
    "id": 1840018744
  },
  {
    "city": "Sanford",
    "city_ascii": "Sanford",
    "lat": 28.7893,
    "lng": -81.276,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 61448,
    "id": 1840015958
  },
  {
    "city": "Caràzinho",
    "city_ascii": "Carazinho",
    "lat": -28.2839,
    "lng": -52.7864,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Rio Grande do Sul",
    "capital": "",
    "population": 59417,
    "id": 1076906274
  },
  {
    "city": "Hürth",
    "city_ascii": "Hurth",
    "lat": 50.8775,
    "lng": 6.8761,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 60189,
    "id": 1276025332
  },
  {
    "city": "Hashimoto",
    "city_ascii": "Hashimoto",
    "lat": 34.3167,
    "lng": 135.6,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Wakayama",
    "capital": "",
    "population": 60885,
    "id": 1392655829
  },
  {
    "city": "Burnsville",
    "city_ascii": "Burnsville",
    "lat": 44.7648,
    "lng": -93.2795,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 61339,
    "id": 1840006766
  },
  {
    "city": "Montauban",
    "city_ascii": "Montauban",
    "lat": 44.0181,
    "lng": 1.3558,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Occitanie",
    "capital": "minor",
    "population": 60810,
    "id": 1250412579
  },
  {
    "city": "Candon",
    "city_ascii": "Candon",
    "lat": 17.2,
    "lng": 120.45,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Ilocos Sur",
    "capital": "",
    "population": 60623,
    "id": 1608761578
  },
  {
    "city": "Hamden",
    "city_ascii": "Hamden",
    "lat": 41.3961,
    "lng": -72.9215,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 61206,
    "id": 1840034823
  },
  {
    "city": "Port Charlotte",
    "city_ascii": "Port Charlotte",
    "lat": 26.9918,
    "lng": -82.114,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 61204,
    "id": 1840014200
  },
  {
    "city": "Morristown",
    "city_ascii": "Morristown",
    "lat": 36.2046,
    "lng": -83.3006,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 61171,
    "id": 1840014467
  },
  {
    "city": "Halton Hills",
    "city_ascii": "Halton Hills",
    "lat": 43.63,
    "lng": -79.95,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 61161,
    "id": 1124000788
  },
  {
    "city": "Ōwada",
    "city_ascii": "Owada",
    "lat": 40.6128,
    "lng": 141.2058,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Aomori",
    "capital": "",
    "population": 61058,
    "id": 1392976895
  },
  {
    "city": "Idiofa",
    "city_ascii": "Idiofa",
    "lat": -4.9596,
    "lng": 19.5986,
    "country": "Congo (Kinshasa)",
    "iso2": "CD",
    "iso3": "COD",
    "admin_name": "Kwilu",
    "capital": "",
    "population": 61056,
    "id": 1180986517
  },
  {
    "city": "Sŏnbong",
    "city_ascii": "Sonbong",
    "lat": 42.3377,
    "lng": 130.4027,
    "country": "Korea, North",
    "iso2": "KP",
    "iso3": "PRK",
    "admin_name": "Nasŏn",
    "capital": "minor",
    "population": 60864,
    "id": 1408519732
  },
  {
    "city": "Skhirate",
    "city_ascii": "Skhirate",
    "lat": 33.85,
    "lng": -7.03,
    "country": "Morocco",
    "iso2": "MA",
    "iso3": "MAR",
    "admin_name": "Rabat-Salé-Kénitra",
    "capital": "",
    "population": 59775,
    "id": 1504664899
  },
  {
    "city": "Bouaflé",
    "city_ascii": "Bouafle",
    "lat": 6.9903,
    "lng": -5.7442,
    "country": "Côte D’Ivoire",
    "iso2": "CI",
    "iso3": "CIV",
    "admin_name": "Sassandra-Marahoué",
    "capital": "minor",
    "population": 60962,
    "id": 1384274339
  },
  {
    "city": "El Carmen de Bolívar",
    "city_ascii": "El Carmen de Bolivar",
    "lat": 9.7204,
    "lng": -75.13,
    "country": "Colombia",
    "iso2": "CO",
    "iso3": "COL",
    "admin_name": "Bolívar",
    "capital": "minor",
    "population": 60980,
    "id": 1170394868
  },
  {
    "city": "Savona",
    "city_ascii": "Savona",
    "lat": 44.308,
    "lng": 8.481,
    "country": "Italy",
    "iso2": "IT",
    "iso3": "ITA",
    "admin_name": "Liguria",
    "capital": "minor",
    "population": 61057,
    "id": 1380314552
  },
  {
    "city": "Lakewood",
    "city_ascii": "Lakewood",
    "lat": 47.1628,
    "lng": -122.5299,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 61037,
    "id": 1840019860
  },
  {
    "city": "Nahariyya",
    "city_ascii": "Nahariyya",
    "lat": 33.0036,
    "lng": 35.0925,
    "country": "Israel",
    "iso2": "IL",
    "iso3": "ISR",
    "admin_name": "Northern",
    "capital": "",
    "population": 60000,
    "id": 1376378013
  },
  {
    "city": "Stralsund",
    "city_ascii": "Stralsund",
    "lat": 54.3,
    "lng": 13.0833,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Mecklenburg-Western Pomerania",
    "capital": "minor",
    "population": 59421,
    "id": 1276640152
  },
  {
    "city": "Gadsden",
    "city_ascii": "Gadsden",
    "lat": 34.009,
    "lng": -86.0156,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 60944,
    "id": 1840006332
  },
  {
    "city": "Chalkída",
    "city_ascii": "Chalkida",
    "lat": 38.4625,
    "lng": 23.595,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Stereá Elláda",
    "capital": "minor",
    "population": 59125,
    "id": 1300585149
  },
  {
    "city": "Taylor",
    "city_ascii": "Taylor",
    "lat": 42.226,
    "lng": -83.2688,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 60922,
    "id": 1840003965
  },
  {
    "city": "Novi",
    "city_ascii": "Novi",
    "lat": 42.4786,
    "lng": -83.4893,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 60896,
    "id": 1840002423
  },
  {
    "city": "Marietta",
    "city_ascii": "Marietta",
    "lat": 33.9533,
    "lng": -84.5422,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 60867,
    "id": 1840015633
  },
  {
    "city": "Montemorelos",
    "city_ascii": "Montemorelos",
    "lat": 25.1872,
    "lng": -99.8267,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Nuevo León",
    "capital": "minor",
    "population": 60829,
    "id": 1484813948
  },
  {
    "city": "Wesel",
    "city_ascii": "Wesel",
    "lat": 51.6586,
    "lng": 6.6178,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "minor",
    "population": 60357,
    "id": 1276261527
  },
  {
    "city": "Tartagal",
    "city_ascii": "Tartagal",
    "lat": -22.5,
    "lng": -63.8333,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Salta",
    "capital": "minor",
    "population": 60819,
    "id": 1032317391
  },
  {
    "city": "Hot Springs",
    "city_ascii": "Hot Springs",
    "lat": 34.4892,
    "lng": -93.0501,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arkansas",
    "capital": "",
    "population": 60767,
    "id": 1840014670
  },
  {
    "city": "Bełchatów",
    "city_ascii": "Belchatow",
    "lat": 51.3667,
    "lng": 19.3667,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Łódzkie",
    "capital": "minor",
    "population": 59025,
    "id": 1616973390
  },
  {
    "city": "Tahara",
    "city_ascii": "Tahara",
    "lat": 34.6414,
    "lng": 137.1831,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Aichi",
    "capital": "",
    "population": 60378,
    "id": 1392243074
  },
  {
    "city": "Druzhkivka",
    "city_ascii": "Druzhkivka",
    "lat": 48.6203,
    "lng": 37.5278,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Donets’ka Oblast’",
    "capital": "",
    "population": 59400,
    "id": 1804169216
  },
  {
    "city": "Soasio",
    "city_ascii": "Soasio",
    "lat": 0.6964,
    "lng": 127.436,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Maluku Utara",
    "capital": "",
    "population": 60611,
    "id": 1360827216
  },
  {
    "city": "Batatais",
    "city_ascii": "Batatais",
    "lat": -20.8911,
    "lng": -47.585,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 60589,
    "id": 1076581214
  },
  {
    "city": "Autlán de Navarro",
    "city_ascii": "Autlan de Navarro",
    "lat": 19.7667,
    "lng": -104.3667,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Jalisco",
    "capital": "minor",
    "population": 60572,
    "id": 1484240559
  },
  {
    "city": "Woodland",
    "city_ascii": "Woodland",
    "lat": 38.6712,
    "lng": -121.75,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 60598,
    "id": 1840021485
  },
  {
    "city": "Greifswald",
    "city_ascii": "Greifswald",
    "lat": 54.0833,
    "lng": 13.3833,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Mecklenburg-Western Pomerania",
    "capital": "minor",
    "population": 59382,
    "id": 1276867765
  },
  {
    "city": "Kumertau",
    "city_ascii": "Kumertau",
    "lat": 52.7667,
    "lng": 55.7833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Bashkortostan",
    "capital": "",
    "population": 60164,
    "id": 1643237908
  },
  {
    "city": "Koga",
    "city_ascii": "Koga",
    "lat": 33.7333,
    "lng": 130.4667,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Fukuoka",
    "capital": "",
    "population": 59111,
    "id": 1392503917
  },
  {
    "city": "Rzhev",
    "city_ascii": "Rzhev",
    "lat": 56.2656,
    "lng": 34.3276,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Tverskaya Oblast’",
    "capital": "",
    "population": 59422,
    "id": 1643967736
  },
  {
    "city": "Molfetta",
    "city_ascii": "Molfetta",
    "lat": 41.2,
    "lng": 16.6,
    "country": "Italy",
    "iso2": "IT",
    "iso3": "ITA",
    "admin_name": "Puglia",
    "capital": "",
    "population": 59470,
    "id": 1380210810
  },
  {
    "city": "Ponnūru",
    "city_ascii": "Ponnuru",
    "lat": 16.0667,
    "lng": 80.5667,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Andhra Pradesh",
    "capital": "",
    "population": 59913,
    "id": 1356219968
  },
  {
    "city": "Belladère",
    "city_ascii": "Belladere",
    "lat": 18.85,
    "lng": -71.7833,
    "country": "Haiti",
    "iso2": "HT",
    "iso3": "HTI",
    "admin_name": "Centre",
    "capital": "",
    "population": 60239,
    "id": 1332473025
  },
  {
    "city": "Mossel Bay",
    "city_ascii": "Mossel Bay",
    "lat": -34.1833,
    "lng": 22.1333,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "Western Cape",
    "capital": "",
    "population": 59031,
    "id": 1710597106
  },
  {
    "city": "Xo‘jayli Shahri",
    "city_ascii": "Xo`jayli Shahri",
    "lat": 42.4047,
    "lng": 59.4517,
    "country": "Uzbekistan",
    "iso2": "UZ",
    "iso3": "UZB",
    "admin_name": "Qoraqalpog‘iston",
    "capital": "minor",
    "population": 60401,
    "id": 1860783170
  },
  {
    "city": "Chichibu",
    "city_ascii": "Chichibu",
    "lat": 35.9917,
    "lng": 139.0856,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Saitama",
    "capital": "",
    "population": 60303,
    "id": 1392105662
  },
  {
    "city": "Offenburg",
    "city_ascii": "Offenburg",
    "lat": 48.4708,
    "lng": 7.9408,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "minor",
    "population": 59646,
    "id": 1276214401
  },
  {
    "city": "Bor",
    "city_ascii": "Bor",
    "lat": 37.8833,
    "lng": 34.5667,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Niğde",
    "capital": "minor",
    "population": 60335,
    "id": 1792531194
  },
  {
    "city": "Bensalem",
    "city_ascii": "Bensalem",
    "lat": 40.1086,
    "lng": -74.9431,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 60370,
    "id": 1840152153
  },
  {
    "city": "Langenfeld",
    "city_ascii": "Langenfeld",
    "lat": 51.1167,
    "lng": 6.95,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 58927,
    "id": 1276016003
  },
  {
    "city": "Crotone",
    "city_ascii": "Crotone",
    "lat": 39.0833,
    "lng": 17.1233,
    "country": "Italy",
    "iso2": "IT",
    "iso3": "ITA",
    "admin_name": "Calabria",
    "capital": "minor",
    "population": 60010,
    "id": 1380444441
  },
  {
    "city": "Commerce City",
    "city_ascii": "Commerce City",
    "lat": 39.8642,
    "lng": -104.8434,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 60336,
    "id": 1840018785
  },
  {
    "city": "Reston",
    "city_ascii": "Reston",
    "lat": 38.9497,
    "lng": -77.3461,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 60335,
    "id": 1840006025
  },
  {
    "city": "Belebey",
    "city_ascii": "Belebey",
    "lat": 54.1,
    "lng": 54.1333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Bashkortostan",
    "capital": "",
    "population": 60188,
    "id": 1643040403
  },
  {
    "city": "Manhiça",
    "city_ascii": "Manhica",
    "lat": -25.4,
    "lng": 32.8,
    "country": "Mozambique",
    "iso2": "MZ",
    "iso3": "MOZ",
    "admin_name": "Maputo",
    "capital": "",
    "population": 60031,
    "id": 1508278730
  },
  {
    "city": "Labinsk",
    "city_ascii": "Labinsk",
    "lat": 44.6333,
    "lng": 40.7333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Krasnodarskiy Kray",
    "capital": "minor",
    "population": 60164,
    "id": 1643209545
  },
  {
    "city": "Shimotsuke",
    "city_ascii": "Shimotsuke",
    "lat": 36.3872,
    "lng": 139.8422,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Tochigi",
    "capital": "",
    "population": 59370,
    "id": 1392034271
  },
  {
    "city": "Urus-Martan",
    "city_ascii": "Urus-Martan",
    "lat": 43.1224,
    "lng": 45.5366,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Chechnya",
    "capital": "minor",
    "population": 59954,
    "id": 1643013764
  },
  {
    "city": "Kapchagay",
    "city_ascii": "Kapchagay",
    "lat": 43.8833,
    "lng": 77.0833,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Almaty",
    "capital": "",
    "population": 60100,
    "id": 1398649394
  },
  {
    "city": "Suifenhe",
    "city_ascii": "Suifenhe",
    "lat": 44.3945,
    "lng": 131.1563,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Heilongjiang",
    "capital": "",
    "population": 60000,
    "id": 1156783152
  },
  {
    "city": "Jōsō",
    "city_ascii": "Joso",
    "lat": 36.0236,
    "lng": 139.9939,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Ibaraki",
    "capital": "",
    "population": 59647,
    "id": 1392553484
  },
  {
    "city": "Vinukonda",
    "city_ascii": "Vinukonda",
    "lat": 16.05,
    "lng": 79.75,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Andhra Pradesh",
    "capital": "",
    "population": 59725,
    "id": 1356819642
  },
  {
    "city": "Linares",
    "city_ascii": "Linares",
    "lat": 38.0833,
    "lng": -3.6334,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Andalusia",
    "capital": "",
    "population": 59761,
    "id": 1724047784
  },
  {
    "city": "Kribi",
    "city_ascii": "Kribi",
    "lat": 2.95,
    "lng": 9.9167,
    "country": "Cameroon",
    "iso2": "CM",
    "iso3": "CMR",
    "admin_name": "Sud",
    "capital": "",
    "population": 60000,
    "id": 1120360100
  },
  {
    "city": "Chajarí",
    "city_ascii": "Chajari",
    "lat": -30.7667,
    "lng": -57.9833,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Entre Ríos",
    "capital": "",
    "population": 60000,
    "id": 1032147348
  },
  {
    "city": "Bristol",
    "city_ascii": "Bristol",
    "lat": 41.6812,
    "lng": -72.9407,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 59947,
    "id": 1840004772
  },
  {
    "city": "Shirakawa",
    "city_ascii": "Shirakawa",
    "lat": 37.1264,
    "lng": 140.2108,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Fukushima",
    "capital": "",
    "population": 59730,
    "id": 1392881381
  },
  {
    "city": "Columbus",
    "city_ascii": "Columbus",
    "lat": 39.2093,
    "lng": -85.9183,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 59897,
    "id": 1840013714
  },
  {
    "city": "Asenovgrad",
    "city_ascii": "Asenovgrad",
    "lat": 42.0069,
    "lng": 24.8756,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Plovdiv",
    "capital": "",
    "population": 59793,
    "id": 1100200929
  },
  {
    "city": "Choshi",
    "city_ascii": "Choshi",
    "lat": 35.7347,
    "lng": 140.8267,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Chiba",
    "capital": "",
    "population": 59165,
    "id": 1392509915
  },
  {
    "city": "Shuya",
    "city_ascii": "Shuya",
    "lat": 56.85,
    "lng": 41.3667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Ivanovskaya Oblast’",
    "capital": "",
    "population": 58114,
    "id": 1643360424
  },
  {
    "city": "Uriangato",
    "city_ascii": "Uriangato",
    "lat": 20.1333,
    "lng": -101.1667,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Guanajuato",
    "capital": "minor",
    "population": 59305,
    "id": 1484679933
  },
  {
    "city": "Huehuetoca",
    "city_ascii": "Huehuetoca",
    "lat": 19.8342,
    "lng": -99.2033,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "México",
    "capital": "minor",
    "population": 59721,
    "id": 1484000089
  },
  {
    "city": "Bangor",
    "city_ascii": "Bangor",
    "lat": 44.8322,
    "lng": -68.7906,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maine",
    "capital": "",
    "population": 59772,
    "id": 1840000238
  },
  {
    "city": "Lesosibirsk",
    "city_ascii": "Lesosibirsk",
    "lat": 58.2333,
    "lng": 92.4833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Krasnoyarskiy Kray",
    "capital": "",
    "population": 59525,
    "id": 1643351184
  },
  {
    "city": "Tezpur",
    "city_ascii": "Tezpur",
    "lat": 26.6338,
    "lng": 92.8,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Assam",
    "capital": "",
    "population": 58851,
    "id": 1356299437
  },
  {
    "city": "Date",
    "city_ascii": "Date",
    "lat": 37.8189,
    "lng": 140.5631,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Fukushima",
    "capital": "",
    "population": 59441,
    "id": 1392089091
  },
  {
    "city": "Saint-Hyacinthe",
    "city_ascii": "Saint-Hyacinthe",
    "lat": 45.6167,
    "lng": -72.95,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 59614,
    "id": 1124010116
  },
  {
    "city": "Narasapur",
    "city_ascii": "Narasapur",
    "lat": 16.4333,
    "lng": 81.6833,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Andhra Pradesh",
    "capital": "",
    "population": 58770,
    "id": 1356412895
  },
  {
    "city": "Agrigento",
    "city_ascii": "Agrigento",
    "lat": 37.3111,
    "lng": 13.5765,
    "country": "Italy",
    "iso2": "IT",
    "iso3": "ITA",
    "admin_name": "Sicilia",
    "capital": "minor",
    "population": 59329,
    "id": 1380721319
  },
  {
    "city": "Chibuto",
    "city_ascii": "Chibuto",
    "lat": -24.6867,
    "lng": 33.5306,
    "country": "Mozambique",
    "iso2": "MZ",
    "iso3": "MOZ",
    "admin_name": "Gaza",
    "capital": "",
    "population": 58012,
    "id": 1508468789
  },
  {
    "city": "Prokhladnyy",
    "city_ascii": "Prokhladnyy",
    "lat": 43.7575,
    "lng": 44.0297,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kabardino-Balkariya",
    "capital": "minor",
    "population": 57883,
    "id": 1643315117
  },
  {
    "city": "Niort",
    "city_ascii": "Niort",
    "lat": 46.3258,
    "lng": -0.4606,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Nouvelle-Aquitaine",
    "capital": "minor",
    "population": 58707,
    "id": 1250833218
  },
  {
    "city": "Yara",
    "city_ascii": "Yara",
    "lat": 20.2767,
    "lng": -76.9469,
    "country": "Cuba",
    "iso2": "CU",
    "iso3": "CUB",
    "admin_name": "Granma",
    "capital": "minor",
    "population": 59415,
    "id": 1192644994
  },
  {
    "city": "Morón",
    "city_ascii": "Moron",
    "lat": 22.1108,
    "lng": -78.6278,
    "country": "Cuba",
    "iso2": "CU",
    "iso3": "CUB",
    "admin_name": "Ciego de Ávila",
    "capital": "minor",
    "population": 59371,
    "id": 1192161102
  },
  {
    "city": "Kokawa",
    "city_ascii": "Kokawa",
    "lat": 34.2697,
    "lng": 135.3625,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Wakayama",
    "capital": "",
    "population": 59208,
    "id": 1392193996
  },
  {
    "city": "Greenwood",
    "city_ascii": "Greenwood",
    "lat": 39.6019,
    "lng": -86.1073,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 59458,
    "id": 1840007332
  },
  {
    "city": "Hua Hin",
    "city_ascii": "Hua Hin",
    "lat": 12.5686,
    "lng": 99.9578,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Prachuap Khiri Khan",
    "capital": "minor",
    "population": 59369,
    "id": 1764352388
  },
  {
    "city": "Bartlett",
    "city_ascii": "Bartlett",
    "lat": 35.2337,
    "lng": -89.8195,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 59440,
    "id": 1840013472
  },
  {
    "city": "Bradenton",
    "city_ascii": "Bradenton",
    "lat": 27.49,
    "lng": -82.5739,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 59439,
    "id": 1840014173
  },
  {
    "city": "Pontiac",
    "city_ascii": "Pontiac",
    "lat": 42.6493,
    "lng": -83.2878,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 59438,
    "id": 1840002427
  },
  {
    "city": "Staunton",
    "city_ascii": "Staunton",
    "lat": 38.1593,
    "lng": -79.0611,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 59433,
    "id": 1840001670
  },
  {
    "city": "Neu-Ulm",
    "city_ascii": "Neu-Ulm",
    "lat": 48.3833,
    "lng": 10,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "minor",
    "population": 58707,
    "id": 1276071813
  },
  {
    "city": "Gannan",
    "city_ascii": "Gannan",
    "lat": 47.9204,
    "lng": 123.51,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Heilongjiang",
    "capital": "minor",
    "population": 59239,
    "id": 1156081008
  },
  {
    "city": "Clay",
    "city_ascii": "Clay",
    "lat": 43.1809,
    "lng": -76.1955,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 59423,
    "id": 1840057989
  },
  {
    "city": "Meriden",
    "city_ascii": "Meriden",
    "lat": 41.5367,
    "lng": -72.7944,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 59395,
    "id": 1840004848
  },
  {
    "city": "Aleksandrov",
    "city_ascii": "Aleksandrov",
    "lat": 56.3936,
    "lng": 38.715,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Vladimirskaya Oblast’",
    "capital": "",
    "population": 59328,
    "id": 1643014960
  },
  {
    "city": "Tōgane",
    "city_ascii": "Togane",
    "lat": 35.56,
    "lng": 140.3661,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Chiba",
    "capital": "",
    "population": 58686,
    "id": 1392535901
  },
  {
    "city": "Plymouth",
    "city_ascii": "Plymouth",
    "lat": 41.8783,
    "lng": -70.6309,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 59331,
    "id": 1840053574
  },
  {
    "city": "Apex",
    "city_ascii": "Apex",
    "lat": 35.7239,
    "lng": -78.8728,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 59300,
    "id": 1840015355
  },
  {
    "city": "Unna",
    "city_ascii": "Unna",
    "lat": 51.5347,
    "lng": 7.6889,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "minor",
    "population": 58633,
    "id": 1276028114
  },
  {
    "city": "Royal Oak",
    "city_ascii": "Royal Oak",
    "lat": 42.5084,
    "lng": -83.1539,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 59277,
    "id": 1840002430
  },
  {
    "city": "Benton Harbor",
    "city_ascii": "Benton Harbor",
    "lat": 42.1159,
    "lng": -86.4488,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 59216,
    "id": 1840003215
  },
  {
    "city": "Kattaqo’rg’on Shahri",
    "city_ascii": "Kattaqo'rg'on Shahri",
    "lat": 39.8958,
    "lng": 66.2656,
    "country": "Uzbekistan",
    "iso2": "UZ",
    "iso3": "UZB",
    "admin_name": "Samarqand",
    "capital": "minor",
    "population": 58950,
    "id": 1860871064
  },
  {
    "city": "Kosai",
    "city_ascii": "Kosai",
    "lat": 34.7186,
    "lng": 137.5317,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Shizuoka",
    "capital": "",
    "population": 58423,
    "id": 1392881893
  },
  {
    "city": "Bilhorod-Dnistrovs’kyy",
    "city_ascii": "Bilhorod-Dnistrovs'kyy",
    "lat": 46.1833,
    "lng": 30.3333,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Odes’ka Oblast’",
    "capital": "minor",
    "population": 57210,
    "id": 1804836080
  },
  {
    "city": "St. Clair Shores",
    "city_ascii": "St. Clair Shores",
    "lat": 42.4921,
    "lng": -82.8957,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 58984,
    "id": 1840003093
  },
  {
    "city": "Sandnes",
    "city_ascii": "Sandnes",
    "lat": 58.85,
    "lng": 5.7333,
    "country": "Norway",
    "iso2": "NO",
    "iso3": "NOR",
    "admin_name": "Rogaland",
    "capital": "minor",
    "population": 58694,
    "id": 1578525190
  },
  {
    "city": "Des Plaines",
    "city_ascii": "Des Plaines",
    "lat": 42.0345,
    "lng": -87.9009,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 58899,
    "id": 1840007032
  },
  {
    "city": "Lac-Brome",
    "city_ascii": "Lac-Brome",
    "lat": 45.2167,
    "lng": -72.5167,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 58889,
    "id": 1124000579
  },
  {
    "city": "Tokoname",
    "city_ascii": "Tokoname",
    "lat": 34.8833,
    "lng": 136.8333,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Aichi",
    "capital": "",
    "population": 57814,
    "id": 1392719168
  },
  {
    "city": "Lewiston",
    "city_ascii": "Lewiston",
    "lat": 44.0915,
    "lng": -70.1681,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maine",
    "capital": "",
    "population": 58802,
    "id": 1840000320
  },
  {
    "city": "Jovellanos",
    "city_ascii": "Jovellanos",
    "lat": 22.8106,
    "lng": -81.1981,
    "country": "Cuba",
    "iso2": "CU",
    "iso3": "CUB",
    "admin_name": "Matanzas",
    "capital": "minor",
    "population": 58685,
    "id": 1192643668
  },
  {
    "city": "Midland",
    "city_ascii": "Midland",
    "lat": 43.6241,
    "lng": -84.2319,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 58787,
    "id": 1840002660
  },
  {
    "city": "Timargara",
    "city_ascii": "Timargara",
    "lat": 34.8281,
    "lng": 71.8408,
    "country": "Pakistan",
    "iso2": "PK",
    "iso3": "PAK",
    "admin_name": "Khyber Pakhtunkhwa",
    "capital": "minor",
    "population": 58050,
    "id": 1586843763
  },
  {
    "city": "Mikhaylovka",
    "city_ascii": "Mikhaylovka",
    "lat": 50.0667,
    "lng": 43.2333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Volgogradskaya Oblast’",
    "capital": "",
    "population": 57829,
    "id": 1643239180
  },
  {
    "city": "Spring",
    "city_ascii": "Spring",
    "lat": 30.0613,
    "lng": -95.383,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 58756,
    "id": 1840019615
  },
  {
    "city": "Lower Merion",
    "city_ascii": "Lower Merion",
    "lat": 40.0282,
    "lng": -75.2807,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 58735,
    "id": 1840142131
  },
  {
    "city": "Izberbash",
    "city_ascii": "Izberbash",
    "lat": 42.5667,
    "lng": 47.8667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Dagestan",
    "capital": "",
    "population": 58690,
    "id": 1643417777
  },
  {
    "city": "Bowie",
    "city_ascii": "Bowie",
    "lat": 38.9569,
    "lng": -76.7409,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 58643,
    "id": 1840005970
  },
  {
    "city": "Kaş",
    "city_ascii": "Kas",
    "lat": 36.2018,
    "lng": 29.6377,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Antalya",
    "capital": "minor",
    "population": 58600,
    "id": 1792585060
  },
  {
    "city": "Tlaltenango de Sánchez Román",
    "city_ascii": "Tlaltenango de Sanchez Roman",
    "lat": 21.7815,
    "lng": -103.3059,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Zacatecas",
    "capital": "minor",
    "population": 58560,
    "id": 1484361545
  },
  {
    "city": "Aketi",
    "city_ascii": "Aketi",
    "lat": 2.7405,
    "lng": 23.78,
    "country": "Congo (Kinshasa)",
    "iso2": "CD",
    "iso3": "COD",
    "admin_name": "Bas-Uélé",
    "capital": "",
    "population": 58601,
    "id": 1180273302
  },
  {
    "city": "Qaskeleng",
    "city_ascii": "Qaskeleng",
    "lat": 43.1983,
    "lng": 76.6311,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Almaty",
    "capital": "",
    "population": 58418,
    "id": 1398296961
  },
  {
    "city": "Felgueiras",
    "city_ascii": "Felgueiras",
    "lat": 41.3667,
    "lng": -8.1833,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Porto",
    "capital": "minor",
    "population": 58065,
    "id": 1620928002
  },
  {
    "city": "Motril",
    "city_ascii": "Motril",
    "lat": 36.7453,
    "lng": -3.5206,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Andalusia",
    "capital": "",
    "population": 58020,
    "id": 1724209936
  },
  {
    "city": "Göppingen",
    "city_ascii": "Goppingen",
    "lat": 48.7025,
    "lng": 9.6528,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "minor",
    "population": 57558,
    "id": 1276730715
  },
  {
    "city": "Lambayeque",
    "city_ascii": "Lambayeque",
    "lat": -6.7,
    "lng": -79.9,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "Lambayeque",
    "capital": "",
    "population": 58276,
    "id": 1604253597
  },
  {
    "city": "Tiznit",
    "city_ascii": "Tiznit",
    "lat": 29.7104,
    "lng": -9.74,
    "country": "Morocco",
    "iso2": "MA",
    "iso3": "MAR",
    "admin_name": "Souss-Massa",
    "capital": "",
    "population": 57705,
    "id": 1504399838
  },
  {
    "city": "Iskitim",
    "city_ascii": "Iskitim",
    "lat": 54.6333,
    "lng": 83.3,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Novosibirskaya Oblast’",
    "capital": "",
    "population": 56602,
    "id": 1643924276
  },
  {
    "city": "Caldwell",
    "city_ascii": "Caldwell",
    "lat": 43.6453,
    "lng": -116.6591,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Idaho",
    "capital": "",
    "population": 58481,
    "id": 1840018641
  },
  {
    "city": "San Pedro",
    "city_ascii": "San Pedro",
    "lat": -24.2196,
    "lng": -64.87,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Jujuy",
    "capital": "minor",
    "population": 58430,
    "id": 1032823743
  },
  {
    "city": "Ma’erkang",
    "city_ascii": "Ma'erkang",
    "lat": 31.9046,
    "lng": 102.2167,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Sichuan",
    "capital": "minor",
    "population": 58437,
    "id": 1156085323
  },
  {
    "city": "San Rafael",
    "city_ascii": "San Rafael",
    "lat": 37.9905,
    "lng": -122.5222,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 58440,
    "id": 1840021513
  },
  {
    "city": "San Fernando",
    "city_ascii": "San Fernando",
    "lat": -34.5839,
    "lng": -70.9891,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Libertador General Bernardo O’Higgins",
    "capital": "minor",
    "population": 58367,
    "id": 1152802349
  },
  {
    "city": "Euskirchen",
    "city_ascii": "Euskirchen",
    "lat": 50.6597,
    "lng": 6.7917,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "minor",
    "population": 57975,
    "id": 1276782162
  },
  {
    "city": "Sérres",
    "city_ascii": "Serres",
    "lat": 41.0833,
    "lng": 23.55,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Kentrikí Makedonía",
    "capital": "minor",
    "population": 58287,
    "id": 1300726987
  },
  {
    "city": "Ishikari",
    "city_ascii": "Ishikari",
    "lat": 43.1783,
    "lng": 141.3067,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hokkaidō",
    "capital": "",
    "population": 58277,
    "id": 1392382929
  },
  {
    "city": "Towson",
    "city_ascii": "Towson",
    "lat": 39.3944,
    "lng": -76.619,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 58347,
    "id": 1840005695
  },
  {
    "city": "Nyagan",
    "city_ascii": "Nyagan",
    "lat": 62.1333,
    "lng": 65.3833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Khanty-Mansiyskiy Avtonomnyy Okrug-Yugra",
    "capital": "",
    "population": 58335,
    "id": 1643802074
  },
  {
    "city": "Şirvan",
    "city_ascii": "Sirvan",
    "lat": 39.9319,
    "lng": 48.9203,
    "country": "Azerbaijan",
    "iso2": "AZ",
    "iso3": "AZE",
    "admin_name": "Şirvan",
    "capital": "",
    "population": 58253,
    "id": 1031478213
  },
  {
    "city": "Frankfurt (Oder)",
    "city_ascii": "Frankfurt (Oder)",
    "lat": 52.35,
    "lng": 14.55,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Brandenburg",
    "capital": "minor",
    "population": 57873,
    "id": 1276397323
  },
  {
    "city": "Richland",
    "city_ascii": "Richland",
    "lat": 46.2824,
    "lng": -119.2938,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 58225,
    "id": 1840019889
  },
  {
    "city": "Fredericton",
    "city_ascii": "Fredericton",
    "lat": 45.9636,
    "lng": -66.6431,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "New Brunswick",
    "capital": "",
    "population": 58220,
    "id": 1124061289
  },
  {
    "city": "St. Peters",
    "city_ascii": "St. Peters",
    "lat": 38.7824,
    "lng": -90.6061,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 58212,
    "id": 1840009721
  },
  {
    "city": "Tama",
    "city_ascii": "Tama",
    "lat": 34.4919,
    "lng": 133.9458,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Okayama",
    "capital": "",
    "population": 57631,
    "id": 1392920586
  },
  {
    "city": "South Hill",
    "city_ascii": "South Hill",
    "lat": 47.1203,
    "lng": -122.2848,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 58164,
    "id": 1840037886
  },
  {
    "city": "Pula",
    "city_ascii": "Pula",
    "lat": 44.8667,
    "lng": 13.8333,
    "country": "Croatia",
    "iso2": "HR",
    "iso3": "HRV",
    "admin_name": "Istarska Županija",
    "capital": "minor",
    "population": 57053,
    "id": 1191143009
  },
  {
    "city": "Tikhvin",
    "city_ascii": "Tikhvin",
    "lat": 59.6333,
    "lng": 33.5,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Leningradskaya Oblast’",
    "capital": "",
    "population": 58136,
    "id": 1643955290
  },
  {
    "city": "Murakami",
    "city_ascii": "Murakami",
    "lat": 38.2239,
    "lng": 139.4801,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Niigata",
    "capital": "",
    "population": 58096,
    "id": 1392955865
  },
  {
    "city": "Hendersonville",
    "city_ascii": "Hendersonville",
    "lat": 36.3063,
    "lng": -86.5998,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 58113,
    "id": 1840014419
  },
  {
    "city": "Hamburg",
    "city_ascii": "Hamburg",
    "lat": 42.7394,
    "lng": -78.8581,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 58105,
    "id": 1840004385
  },
  {
    "city": "Huntersville",
    "city_ascii": "Huntersville",
    "lat": 35.4058,
    "lng": -80.8727,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 58098,
    "id": 1840016349
  },
  {
    "city": "Mirassol",
    "city_ascii": "Mirassol",
    "lat": -20.8189,
    "lng": -49.5208,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 57857,
    "id": 1076772486
  },
  {
    "city": "Santee",
    "city_ascii": "Santee",
    "lat": 32.8554,
    "lng": -116.9851,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 58081,
    "id": 1840021992
  },
  {
    "city": "Hameln",
    "city_ascii": "Hameln",
    "lat": 52.1031,
    "lng": 9.36,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "minor",
    "population": 57510,
    "id": 1276101153
  },
  {
    "city": "Wagga Wagga",
    "city_ascii": "Wagga Wagga",
    "lat": -35.1189,
    "lng": 147.3689,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 56442,
    "id": 1036273188
  },
  {
    "city": "Meleuz",
    "city_ascii": "Meleuz",
    "lat": 52.9647,
    "lng": 55.9328,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Bashkortostan",
    "capital": "",
    "population": 58004,
    "id": 1643373593
  },
  {
    "city": "Livingston",
    "city_ascii": "Livingston",
    "lat": 55.8834,
    "lng": -3.5157,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "West Lothian",
    "capital": "",
    "population": 57030,
    "id": 1826286558
  },
  {
    "city": "Ávila",
    "city_ascii": "Avila",
    "lat": 40.6543,
    "lng": -4.6962,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Castille-Leon",
    "capital": "minor",
    "population": 57744,
    "id": 1724392183
  },
  {
    "city": "Allanmyo",
    "city_ascii": "Allanmyo",
    "lat": 19.3783,
    "lng": 95.2279,
    "country": "Burma",
    "iso2": "MM",
    "iso3": "MMR",
    "admin_name": "Magway",
    "capital": "",
    "population": 57897,
    "id": 1104582279
  },
  {
    "city": "Southampton",
    "city_ascii": "Southampton",
    "lat": 40.8996,
    "lng": -72.4937,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 57975,
    "id": 1840005137
  },
  {
    "city": "Aleksin",
    "city_ascii": "Aleksin",
    "lat": 54.5,
    "lng": 37.0667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Tul’skaya Oblast’",
    "capital": "",
    "population": 57892,
    "id": 1643013998
  },
  {
    "city": "Narva",
    "city_ascii": "Narva",
    "lat": 59.3792,
    "lng": 28.2006,
    "country": "Estonia",
    "iso2": "EE",
    "iso3": "EST",
    "admin_name": "Ida-Virumaa",
    "capital": "minor",
    "population": 57130,
    "id": 1233751159
  },
  {
    "city": "António Enes",
    "city_ascii": "Antonio Enes",
    "lat": -16.2308,
    "lng": 39.9105,
    "country": "Mozambique",
    "iso2": "MZ",
    "iso3": "MOZ",
    "admin_name": "Nampula",
    "capital": "",
    "population": 57835,
    "id": 1508591443
  },
  {
    "city": "Manchester",
    "city_ascii": "Manchester",
    "lat": 41.7753,
    "lng": -72.5242,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 57955,
    "id": 1840035273
  },
  {
    "city": "Berëzovskiy",
    "city_ascii": "Berezovskiy",
    "lat": 56.9,
    "lng": 60.8,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sverdlovskaya Oblast’",
    "capital": "",
    "population": 57892,
    "id": 1643702962
  },
  {
    "city": "Beckley",
    "city_ascii": "Beckley",
    "lat": 37.7878,
    "lng": -81.1841,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "West Virginia",
    "capital": "",
    "population": 57942,
    "id": 1840006350
  },
  {
    "city": "Sāmalkot",
    "city_ascii": "Samalkot",
    "lat": 17.0531,
    "lng": 82.1695,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Andhra Pradesh",
    "capital": "",
    "population": 56864,
    "id": 1356044530
  },
  {
    "city": "Alexandroúpoli",
    "city_ascii": "Alexandroupoli",
    "lat": 40.85,
    "lng": 25.8667,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Anatolikí Makedonía kai Thráki",
    "capital": "minor",
    "population": 57812,
    "id": 1300949419
  },
  {
    "city": "Titusville",
    "city_ascii": "Titusville",
    "lat": 28.5727,
    "lng": -80.8193,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 57891,
    "id": 1840015962
  },
  {
    "city": "Cartersville",
    "city_ascii": "Cartersville",
    "lat": 34.1639,
    "lng": -84.8007,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 57873,
    "id": 1840014701
  },
  {
    "city": "Walla Walla",
    "city_ascii": "Walla Walla",
    "lat": 46.067,
    "lng": -118.3366,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 57860,
    "id": 1840021174
  },
  {
    "city": "Orland Park",
    "city_ascii": "Orland Park",
    "lat": 41.6074,
    "lng": -87.8619,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 57857,
    "id": 1840011310
  },
  {
    "city": "Tikhoretsk",
    "city_ascii": "Tikhoretsk",
    "lat": 45.85,
    "lng": 40.1167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Krasnodarskiy Kray",
    "capital": "minor",
    "population": 57771,
    "id": 1643615827
  },
  {
    "city": "Kothāpet",
    "city_ascii": "Kothapet",
    "lat": 19.3333,
    "lng": 79.4833,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Telangana",
    "capital": "",
    "population": 57583,
    "id": 1356072921
  },
  {
    "city": "Kan’onjichō",
    "city_ascii": "Kan'onjicho",
    "lat": 34.1283,
    "lng": 133.6628,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Kagawa",
    "capital": "",
    "population": 57333,
    "id": 1392669247
  },
  {
    "city": "Biała Podlaska",
    "city_ascii": "Biala Podlaska",
    "lat": 52.0333,
    "lng": 23.1167,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Lubelskie",
    "capital": "minor",
    "population": 57498,
    "id": 1616025020
  },
  {
    "city": "Qŭnghirot",
    "city_ascii": "Qunghirot",
    "lat": 43.0704,
    "lng": 58.9,
    "country": "Uzbekistan",
    "iso2": "UZ",
    "iso3": "UZB",
    "admin_name": "Qoraqalpog‘iston",
    "capital": "",
    "population": 57758,
    "id": 1860586828
  },
  {
    "city": "Weymouth",
    "city_ascii": "Weymouth",
    "lat": 42.1984,
    "lng": -70.9466,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 57746,
    "id": 1840132442
  },
  {
    "city": "Ibitinga",
    "city_ascii": "Ibitinga",
    "lat": -21.7578,
    "lng": -48.8289,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 57649,
    "id": 1076462788
  },
  {
    "city": "Moa",
    "city_ascii": "Moa",
    "lat": 20.6397,
    "lng": -74.9175,
    "country": "Cuba",
    "iso2": "CU",
    "iso3": "CUB",
    "admin_name": "Holguín",
    "capital": "minor",
    "population": 57652,
    "id": 1192288426
  },
  {
    "city": "Mācherla",
    "city_ascii": "Macherla",
    "lat": 16.48,
    "lng": 79.43,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Andhra Pradesh",
    "capital": "",
    "population": 57290,
    "id": 1356348515
  },
  {
    "city": "Pavlovo",
    "city_ascii": "Pavlovo",
    "lat": 55.9619,
    "lng": 43.09,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Nizhegorodskaya Oblast’",
    "capital": "",
    "population": 57630,
    "id": 1643644827
  },
  {
    "city": "Jerez de García Salinas",
    "city_ascii": "Jerez de Garcia Salinas",
    "lat": 22.6481,
    "lng": -102.9903,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Zacatecas",
    "capital": "minor",
    "population": 57610,
    "id": 1484361423
  },
  {
    "city": "Parker",
    "city_ascii": "Parker",
    "lat": 39.5084,
    "lng": -104.7753,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 57706,
    "id": 1840022454
  },
  {
    "city": "Palm Beach Gardens",
    "city_ascii": "Palm Beach Gardens",
    "lat": 26.8466,
    "lng": -80.1679,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 57704,
    "id": 1840015125
  },
  {
    "city": "Ejmiatsin",
    "city_ascii": "Ejmiatsin",
    "lat": 40.1728,
    "lng": 44.2925,
    "country": "Armenia",
    "iso2": "AM",
    "iso3": "ARM",
    "admin_name": "Armavir",
    "capital": "",
    "population": 57500,
    "id": 1051214432
  },
  {
    "city": "Krasnotur’insk",
    "city_ascii": "Krasnotur'insk",
    "lat": 59.7733,
    "lng": 60.1853,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sverdlovskaya Oblast’",
    "capital": "",
    "population": 57514,
    "id": 1643475253
  },
  {
    "city": "Bozeman",
    "city_ascii": "Bozeman",
    "lat": 45.6832,
    "lng": -111.0552,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Montana",
    "capital": "",
    "population": 57695,
    "id": 1840018509
  },
  {
    "city": "Kandukūr",
    "city_ascii": "Kandukur",
    "lat": 15.2165,
    "lng": 79.9042,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Andhra Pradesh",
    "capital": "",
    "population": 57246,
    "id": 1356179414
  },
  {
    "city": "Diriamba",
    "city_ascii": "Diriamba",
    "lat": 11.85,
    "lng": -86.2333,
    "country": "Nicaragua",
    "iso2": "NI",
    "iso3": "NIC",
    "admin_name": "Carazo",
    "capital": "minor",
    "population": 57512,
    "id": 1558411169
  },
  {
    "city": "Beni Enzar",
    "city_ascii": "Beni Enzar",
    "lat": 35.2569,
    "lng": -2.9342,
    "country": "Morocco",
    "iso2": "MA",
    "iso3": "MAR",
    "admin_name": "Oriental",
    "capital": "",
    "population": 56582,
    "id": 1504616684
  },
  {
    "city": "Beauvais",
    "city_ascii": "Beauvais",
    "lat": 49.4303,
    "lng": 2.0952,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Hauts-de-France",
    "capital": "minor",
    "population": 56020,
    "id": 1250473996
  },
  {
    "city": "Salsk",
    "city_ascii": "Salsk",
    "lat": 46.4833,
    "lng": 41.5333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Rostovskaya Oblast’",
    "capital": "minor",
    "population": 57622,
    "id": 1643005627
  },
  {
    "city": "Sicuani",
    "city_ascii": "Sicuani",
    "lat": -14.2694,
    "lng": -71.2261,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "Cusco",
    "capital": "",
    "population": 57551,
    "id": 1604929096
  },
  {
    "city": "Kojskë",
    "city_ascii": "Kojske",
    "lat": 42.53,
    "lng": 21.1386,
    "country": "Kosovo",
    "iso2": "XK",
    "iso3": "XKS",
    "admin_name": "Lipjan",
    "capital": "",
    "population": 57605,
    "id": 1901791413
  },
  {
    "city": "East Stroudsburg",
    "city_ascii": "East Stroudsburg",
    "lat": 41.0023,
    "lng": -75.1779,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 57577,
    "id": 1840003507
  },
  {
    "city": "Minokamo",
    "city_ascii": "Minokamo",
    "lat": 35.4333,
    "lng": 137.0167,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Gifu",
    "capital": "",
    "population": 56804,
    "id": 1392923149
  },
  {
    "city": "Nova Odessa",
    "city_ascii": "Nova Odessa",
    "lat": -22.7797,
    "lng": -47.2962,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 56764,
    "id": 1076061503
  },
  {
    "city": "Taunton",
    "city_ascii": "Taunton",
    "lat": 41.9036,
    "lng": -71.0943,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 57464,
    "id": 1840003220
  },
  {
    "city": "Istaravshan",
    "city_ascii": "Istaravshan",
    "lat": 39.9108,
    "lng": 69.0064,
    "country": "Tajikistan",
    "iso2": "TJ",
    "iso3": "TJK",
    "admin_name": "Sughd",
    "capital": "minor",
    "population": 57400,
    "id": 1762380072
  },
  {
    "city": "Khlong Luang",
    "city_ascii": "Khlong Luang",
    "lat": 14.0649,
    "lng": 100.6447,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Pathum Thani",
    "capital": "minor",
    "population": 56128,
    "id": 1764560763
  },
  {
    "city": "Midwest City",
    "city_ascii": "Midwest City",
    "lat": 35.463,
    "lng": -97.3709,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oklahoma",
    "capital": "",
    "population": 57407,
    "id": 1840020425
  },
  {
    "city": "Sankt Augustin",
    "city_ascii": "Sankt Augustin",
    "lat": 50.77,
    "lng": 7.1867,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 55767,
    "id": 1276196312
  },
  {
    "city": "Stolberg",
    "city_ascii": "Stolberg",
    "lat": 50.7667,
    "lng": 6.2333,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 56792,
    "id": 1276202704
  },
  {
    "city": "Dāmghān",
    "city_ascii": "Damghan",
    "lat": 36.1681,
    "lng": 54.3481,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Semnān",
    "capital": "minor",
    "population": 57331,
    "id": 1364099760
  },
  {
    "city": "Bobbili",
    "city_ascii": "Bobbili",
    "lat": 18.5667,
    "lng": 83.4167,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Andhra Pradesh",
    "capital": "",
    "population": 56819,
    "id": 1356820046
  },
  {
    "city": "Irpin’",
    "city_ascii": "Irpin'",
    "lat": 50.5167,
    "lng": 30.25,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Kyyivs’ka Oblast’",
    "capital": "",
    "population": 56806,
    "id": 1804919448
  },
  {
    "city": "Sattenapalle",
    "city_ascii": "Sattenapalle",
    "lat": 16.3962,
    "lng": 80.1497,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Andhra Pradesh",
    "capital": "",
    "population": 56721,
    "id": 1356153330
  },
  {
    "city": "Krymsk",
    "city_ascii": "Krymsk",
    "lat": 44.9333,
    "lng": 38,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Krasnodarskiy Kray",
    "capital": "minor",
    "population": 57229,
    "id": 1643118471
  },
  {
    "city": "Andradina",
    "city_ascii": "Andradina",
    "lat": -20.8654,
    "lng": -51.3157,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 57250,
    "id": 1076417183
  },
  {
    "city": "Goldsboro",
    "city_ascii": "Goldsboro",
    "lat": 35.3778,
    "lng": -77.972,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 57278,
    "id": 1840013432
  },
  {
    "city": "Kudamatsu",
    "city_ascii": "Kudamatsu",
    "lat": 34.015,
    "lng": 131.8703,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Yamaguchi",
    "capital": "",
    "population": 56634,
    "id": 1392936646
  },
  {
    "city": "Punta Alta",
    "city_ascii": "Punta Alta",
    "lat": -38.88,
    "lng": -62.08,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires",
    "capital": "minor",
    "population": 57209,
    "id": 1032629961
  },
  {
    "city": "Ṭikāpur",
    "city_ascii": "Tikapur",
    "lat": 28.5,
    "lng": 81.1333,
    "country": "Nepal",
    "iso2": "NP",
    "iso3": "NPL",
    "admin_name": "Setī",
    "capital": "",
    "population": 56127,
    "id": 1524682413
  },
  {
    "city": "Euless",
    "city_ascii": "Euless",
    "lat": 32.8508,
    "lng": -97.0799,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 57197,
    "id": 1840020693
  },
  {
    "city": "Görlitz",
    "city_ascii": "Gorlitz",
    "lat": 51.1528,
    "lng": 14.9872,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "minor",
    "population": 56324,
    "id": 1276000121
  },
  {
    "city": "Paços de Ferreira",
    "city_ascii": "Pacos de Ferreira",
    "lat": 41.2833,
    "lng": -8.3833,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Porto",
    "capital": "minor",
    "population": 56340,
    "id": 1620222597
  },
  {
    "city": "Eschweiler",
    "city_ascii": "Eschweiler",
    "lat": 50.8167,
    "lng": 6.2833,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 56385,
    "id": 1276835632
  },
  {
    "city": "Vrindāvan",
    "city_ascii": "Vrindavan",
    "lat": 27.5806,
    "lng": 77.7006,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Uttar Pradesh",
    "capital": "",
    "population": 56618,
    "id": 1356168243
  },
  {
    "city": "Rîbniţa",
    "city_ascii": "Ribnita",
    "lat": 47.7664,
    "lng": 29.0006,
    "country": "Moldova",
    "iso2": "MD",
    "iso3": "MDA",
    "admin_name": "Stînga Nistrului",
    "capital": "",
    "population": 56988,
    "id": 1498197530
  },
  {
    "city": "Opava",
    "city_ascii": "Opava",
    "lat": 49.9381,
    "lng": 17.9044,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Moravskoslezský Kraj",
    "capital": "",
    "population": 56450,
    "id": 1203142483
  },
  {
    "city": "Meerbusch",
    "city_ascii": "Meerbusch",
    "lat": 51.2667,
    "lng": 6.6667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 56189,
    "id": 1276882223
  },
  {
    "city": "General Pico",
    "city_ascii": "General Pico",
    "lat": -35.6667,
    "lng": -63.7333,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "La Pampa",
    "capital": "minor",
    "population": 57029,
    "id": 1032081531
  },
  {
    "city": "Manhattan",
    "city_ascii": "Manhattan",
    "lat": 39.1886,
    "lng": -96.6046,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kansas",
    "capital": "",
    "population": 57046,
    "id": 1840001589
  },
  {
    "city": "Shoreline",
    "city_ascii": "Shoreline",
    "lat": 47.7564,
    "lng": -122.3426,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 57027,
    "id": 1840021118
  },
  {
    "city": "Neryungri",
    "city_ascii": "Neryungri",
    "lat": 56.6583,
    "lng": 124.725,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sakha (Yakutiya)",
    "capital": "",
    "population": 57009,
    "id": 1643755298
  },
  {
    "city": "Piscataway",
    "city_ascii": "Piscataway",
    "lat": 40.5467,
    "lng": -74.4636,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 56931,
    "id": 1840081697
  },
  {
    "city": "Nova Viçosa",
    "city_ascii": "Nova Vicosa",
    "lat": -17.8919,
    "lng": -39.3719,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Bahia",
    "capital": "",
    "population": 55980,
    "id": 1076116919
  },
  {
    "city": "Brentwood",
    "city_ascii": "Brentwood",
    "lat": 51.6204,
    "lng": 0.305,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Essex",
    "capital": "",
    "population": 54885,
    "id": 1826743524
  },
  {
    "city": "Clacton-on-Sea",
    "city_ascii": "Clacton-on-Sea",
    "lat": 51.7918,
    "lng": 1.1457,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Essex",
    "capital": "",
    "population": 56874,
    "id": 1826044595
  },
  {
    "city": "Cruzeiro do Sul",
    "city_ascii": "Cruzeiro do Sul",
    "lat": -7.63,
    "lng": -72.67,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Acre",
    "capital": "",
    "population": 56862,
    "id": 1076881427
  },
  {
    "city": "Acambay",
    "city_ascii": "Acambay",
    "lat": 19.9539,
    "lng": -99.8431,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "México",
    "capital": "minor",
    "population": 56847,
    "id": 1484667611
  },
  {
    "city": "Nōgata",
    "city_ascii": "Nogata",
    "lat": 33.7439,
    "lng": 130.7297,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Fukuoka",
    "capital": "",
    "population": 55937,
    "id": 1392996522
  },
  {
    "city": "Lake Havasu City",
    "city_ascii": "Lake Havasu City",
    "lat": 34.5006,
    "lng": -114.3113,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 56820,
    "id": 1840021044
  },
  {
    "city": "Garzón",
    "city_ascii": "Garzon",
    "lat": 2.2104,
    "lng": -75.65,
    "country": "Colombia",
    "iso2": "CO",
    "iso3": "COL",
    "admin_name": "Huila",
    "capital": "minor",
    "population": 56603,
    "id": 1170149755
  },
  {
    "city": "Waiblingen",
    "city_ascii": "Waiblingen",
    "lat": 48.8303,
    "lng": 9.3169,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "minor",
    "population": 55449,
    "id": 1276678986
  },
  {
    "city": "Taquaritinga",
    "city_ascii": "Taquaritinga",
    "lat": -21.4058,
    "lng": -48.505,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 56587,
    "id": 1076141127
  },
  {
    "city": "Bertioga",
    "city_ascii": "Bertioga",
    "lat": -23.8539,
    "lng": -46.1389,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 56555,
    "id": 1076752421
  },
  {
    "city": "Smyrna",
    "city_ascii": "Smyrna",
    "lat": 33.8633,
    "lng": -84.5168,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 56666,
    "id": 1840015634
  },
  {
    "city": "Tønsberg",
    "city_ascii": "Tonsberg",
    "lat": 59.2981,
    "lng": 10.4236,
    "country": "Norway",
    "iso2": "NO",
    "iso3": "NOR",
    "admin_name": "Vestfold",
    "capital": "minor",
    "population": 56293,
    "id": 1578658467
  },
  {
    "city": "Frýdek-Místek",
    "city_ascii": "Frydek-Mistek",
    "lat": 49.6881,
    "lng": 18.3536,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Moravskoslezský Kraj",
    "capital": "",
    "population": 55557,
    "id": 1203111584
  },
  {
    "city": "Toki",
    "city_ascii": "Toki",
    "lat": 35.3525,
    "lng": 137.1833,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Gifu",
    "capital": "",
    "population": 56133,
    "id": 1392224930
  },
  {
    "city": "Jackson",
    "city_ascii": "Jackson",
    "lat": 40.098,
    "lng": -74.3579,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 56614,
    "id": 1840081610
  },
  {
    "city": "Tāybād",
    "city_ascii": "Taybad",
    "lat": 34.74,
    "lng": 60.7756,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Khorāsān-e Raẕavī",
    "capital": "minor",
    "population": 56562,
    "id": 1364220425
  },
  {
    "city": "Cape Girardeau",
    "city_ascii": "Cape Girardeau",
    "lat": 37.3108,
    "lng": -89.5597,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 56551,
    "id": 1840007598
  },
  {
    "city": "Amealco",
    "city_ascii": "Amealco",
    "lat": 20.1881,
    "lng": -100.1439,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Querétaro",
    "capital": "minor",
    "population": 56457,
    "id": 1484505085
  },
  {
    "city": "Hassa",
    "city_ascii": "Hassa",
    "lat": 36.8,
    "lng": 36.53,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Hatay",
    "capital": "minor",
    "population": 56409,
    "id": 1792468657
  },
  {
    "city": "Hohoe",
    "city_ascii": "Hohoe",
    "lat": 7.149,
    "lng": 0.4746,
    "country": "Ghana",
    "iso2": "GH",
    "iso3": "GHA",
    "admin_name": "Volta",
    "capital": "",
    "population": 56202,
    "id": 1288897773
  },
  {
    "city": "Amarante",
    "city_ascii": "Amarante",
    "lat": 41.2667,
    "lng": -8.0833,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Porto",
    "capital": "minor",
    "population": 56264,
    "id": 1620688725
  },
  {
    "city": "Belampalli",
    "city_ascii": "Belampalli",
    "lat": 19.0558,
    "lng": 79.4931,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Telangana",
    "capital": "",
    "population": 55841,
    "id": 1356972240
  },
  {
    "city": "Bayan Hot",
    "city_ascii": "Bayan Hot",
    "lat": 38.839,
    "lng": 105.6686,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Inner Mongolia",
    "capital": "minor",
    "population": 56387,
    "id": 1156186483
  },
  {
    "city": "Narutochō-mitsuishi",
    "city_ascii": "Narutocho-mitsuishi",
    "lat": 34.1667,
    "lng": 134.6167,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Tokushima",
    "capital": "",
    "population": 56007,
    "id": 1392492030
  },
  {
    "city": "Hidaka",
    "city_ascii": "Hidaka",
    "lat": 35.9078,
    "lng": 139.3392,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Saitama",
    "capital": "",
    "population": 55241,
    "id": 1392984149
  },
  {
    "city": "Xinqing",
    "city_ascii": "Xinqing",
    "lat": 48.2363,
    "lng": 129.5059,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Heilongjiang",
    "capital": "",
    "population": 55415,
    "id": 1156618293
  },
  {
    "city": "Blainville",
    "city_ascii": "Blainville",
    "lat": 45.67,
    "lng": -73.88,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 56363,
    "id": 1124000623
  },
  {
    "city": "Gulariyā",
    "city_ascii": "Gulariya",
    "lat": 28.2331,
    "lng": 81.3331,
    "country": "Nepal",
    "iso2": "NP",
    "iso3": "NPL",
    "admin_name": "Bherī",
    "capital": "",
    "population": 55747,
    "id": 1524608654
  },
  {
    "city": "Registro",
    "city_ascii": "Registro",
    "lat": -24.4878,
    "lng": -47.8439,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 56280,
    "id": 1076533111
  },
  {
    "city": "Antratsyt",
    "city_ascii": "Antratsyt",
    "lat": 48.1167,
    "lng": 39.0833,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Luhans’ka Oblast’",
    "capital": "minor",
    "population": 54640,
    "id": 1804312048
  },
  {
    "city": "Vranjak",
    "city_ascii": "Vranjak",
    "lat": 42.3994,
    "lng": 20.6547,
    "country": "Kosovo",
    "iso2": "XK",
    "iso3": "XKS",
    "admin_name": "Rahovec",
    "capital": "",
    "population": 56208,
    "id": 1901861427
  },
  {
    "city": "Xánthi",
    "city_ascii": "Xanthi",
    "lat": 41.1333,
    "lng": 24.8833,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Anatolikí Makedonía kai Thráki",
    "capital": "minor",
    "population": 56122,
    "id": 1300051191
  },
  {
    "city": "Zarrīn Shahr",
    "city_ascii": "Zarrin Shahr",
    "lat": 32.3894,
    "lng": 51.3764,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Eşfahān",
    "capital": "minor",
    "population": 55984,
    "id": 1364248651
  },
  {
    "city": "Lozova",
    "city_ascii": "Lozova",
    "lat": 48.8892,
    "lng": 36.3161,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Kharkivs’ka Oblast’",
    "capital": "minor",
    "population": 55827,
    "id": 1804128755
  },
  {
    "city": "Khān Shaykhūn",
    "city_ascii": "Khan Shaykhun",
    "lat": 35.4419,
    "lng": 36.6508,
    "country": "Syria",
    "iso2": "SY",
    "iso3": "SYR",
    "admin_name": "Idlib",
    "capital": "minor",
    "population": 55843,
    "id": 1760049407
  },
  {
    "city": "Kazanlak",
    "city_ascii": "Kazanlak",
    "lat": 42.6167,
    "lng": 25.4,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Stara Zagora",
    "capital": "",
    "population": 56047,
    "id": 1100673552
  },
  {
    "city": "Vorkuta",
    "city_ascii": "Vorkuta",
    "lat": 67.5,
    "lng": 64.0333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Komi",
    "capital": "",
    "population": 56088,
    "id": 1643002916
  },
  {
    "city": "Kateríni",
    "city_ascii": "Katerini",
    "lat": 40.2667,
    "lng": 22.5,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Kentrikí Makedonía",
    "capital": "minor",
    "population": 55997,
    "id": 1300524464
  },
  {
    "city": "Parachinar",
    "city_ascii": "Parachinar",
    "lat": 33.8992,
    "lng": 70.1008,
    "country": "Pakistan",
    "iso2": "PK",
    "iso3": "PAK",
    "admin_name": "Khyber Pakhtunkhwa",
    "capital": "",
    "population": 55685,
    "id": 1586337795
  },
  {
    "city": "Bhīmunipatnam",
    "city_ascii": "Bhimunipatnam",
    "lat": 17.8864,
    "lng": 83.4471,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Andhra Pradesh",
    "capital": "",
    "population": 55082,
    "id": 1356889289
  },
  {
    "city": "Hyères",
    "city_ascii": "Hyeres",
    "lat": 43.1199,
    "lng": 6.1316,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Provence-Alpes-Côte d’Azur",
    "capital": "",
    "population": 55588,
    "id": 1250566198
  },
  {
    "city": "Canterbury",
    "city_ascii": "Canterbury",
    "lat": 51.28,
    "lng": 1.08,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Kent",
    "capital": "",
    "population": 55240,
    "id": 1826094061
  },
  {
    "city": "Boituva",
    "city_ascii": "Boituva",
    "lat": -23.2833,
    "lng": -47.6722,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 55725,
    "id": 1076277699
  },
  {
    "city": "Portimão",
    "city_ascii": "Portimao",
    "lat": 37.1333,
    "lng": -8.5333,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Faro",
    "capital": "minor",
    "population": 55614,
    "id": 1620859274
  },
  {
    "city": "Volzhsk",
    "city_ascii": "Volzhsk",
    "lat": 55.8667,
    "lng": 48.35,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Mariy-El",
    "capital": "",
    "population": 54519,
    "id": 1643457067
  },
  {
    "city": "Changling",
    "city_ascii": "Changling",
    "lat": 44.27,
    "lng": 123.99,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Jilin",
    "capital": "minor",
    "population": 55841,
    "id": 1156262573
  },
  {
    "city": "Grants Pass",
    "city_ascii": "Grants Pass",
    "lat": 42.4333,
    "lng": -123.3317,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 55837,
    "id": 1840020087
  },
  {
    "city": "Blue Springs",
    "city_ascii": "Blue Springs",
    "lat": 39.0124,
    "lng": -94.2721,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 55829,
    "id": 1840007396
  },
  {
    "city": "Xiva",
    "city_ascii": "Xiva",
    "lat": 41.3783,
    "lng": 60.3639,
    "country": "Uzbekistan",
    "iso2": "UZ",
    "iso3": "UZB",
    "admin_name": "Xorazm",
    "capital": "minor",
    "population": 55568,
    "id": 1860013300
  },
  {
    "city": "Porirua",
    "city_ascii": "Porirua",
    "lat": -41.1333,
    "lng": 174.85,
    "country": "New Zealand",
    "iso2": "NZ",
    "iso3": "NZL",
    "admin_name": "Wellington",
    "capital": "",
    "population": 55500,
    "id": 1554561880
  },
  {
    "city": "Gus’-Khrustal’nyy",
    "city_ascii": "Gus'-Khrustal'nyy",
    "lat": 55.6167,
    "lng": 40.65,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Vladimirskaya Oblast’",
    "capital": "",
    "population": 54533,
    "id": 1643398222
  },
  {
    "city": "Martin",
    "city_ascii": "Martin",
    "lat": 49.0636,
    "lng": 18.9214,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Žilinský",
    "capital": "minor",
    "population": 54978,
    "id": 1703554366
  },
  {
    "city": "Southaven",
    "city_ascii": "Southaven",
    "lat": 34.9514,
    "lng": -89.9787,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Mississippi",
    "capital": "",
    "population": 55780,
    "id": 1840015519
  },
  {
    "city": "Annaka",
    "city_ascii": "Annaka",
    "lat": 36.3264,
    "lng": 138.8872,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Gunma",
    "capital": "",
    "population": 55578,
    "id": 1392614505
  },
  {
    "city": "Umm Ruwaba",
    "city_ascii": "Umm Ruwaba",
    "lat": 12.9058,
    "lng": 31.2156,
    "country": "Sudan",
    "iso2": "SD",
    "iso3": "SDN",
    "admin_name": "North Kordofan",
    "capital": "",
    "population": 55742,
    "id": 1729566926
  },
  {
    "city": "Tinley Park",
    "city_ascii": "Tinley Park",
    "lat": 41.567,
    "lng": -87.805,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 55773,
    "id": 1840011331
  },
  {
    "city": "Apatity",
    "city_ascii": "Apatity",
    "lat": 67.5675,
    "lng": 33.3933,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Murmanskaya Oblast’",
    "capital": "",
    "population": 55713,
    "id": 1643183303
  },
  {
    "city": "Chino",
    "city_ascii": "Chino",
    "lat": 35.9956,
    "lng": 138.1589,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Nagano",
    "capital": "",
    "population": 55531,
    "id": 1392143893
  },
  {
    "city": "Azul",
    "city_ascii": "Azul",
    "lat": -36.7833,
    "lng": -59.85,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires",
    "capital": "minor",
    "population": 55728,
    "id": 1032652005
  },
  {
    "city": "Diamond Bar",
    "city_ascii": "Diamond Bar",
    "lat": 33.9991,
    "lng": -117.8161,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 55720,
    "id": 1840019244
  },
  {
    "city": "Pittsfield",
    "city_ascii": "Pittsfield",
    "lat": 42.4517,
    "lng": -73.2605,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 55697,
    "id": 1840003116
  },
  {
    "city": "Eagle Pass",
    "city_ascii": "Eagle Pass",
    "lat": 28.7125,
    "lng": -100.484,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 55686,
    "id": 1840019690
  },
  {
    "city": "Ārān Bīdgol",
    "city_ascii": "Aran Bidgol",
    "lat": 34.0578,
    "lng": 51.4842,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Eşfahān",
    "capital": "minor",
    "population": 55651,
    "id": 1364946839
  },
  {
    "city": "Monte Mor",
    "city_ascii": "Monte Mor",
    "lat": -22.9467,
    "lng": -47.315,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 55409,
    "id": 1076728241
  },
  {
    "city": "Lenexa",
    "city_ascii": "Lenexa",
    "lat": 38.9609,
    "lng": -94.8018,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kansas",
    "capital": "",
    "population": 55625,
    "id": 1840001639
  },
  {
    "city": "Corby",
    "city_ascii": "Corby",
    "lat": 52.4914,
    "lng": -0.6965,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Northamptonshire",
    "capital": "",
    "population": 54927,
    "id": 1826582778
  },
  {
    "city": "Hazleton",
    "city_ascii": "Hazleton",
    "lat": 40.9504,
    "lng": -75.9724,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 55604,
    "id": 1840000753
  },
  {
    "city": "Abington",
    "city_ascii": "Abington",
    "lat": 40.1108,
    "lng": -75.1146,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 55573,
    "id": 1840035369
  },
  {
    "city": "Sakurai",
    "city_ascii": "Sakurai",
    "lat": 34.5189,
    "lng": 135.8519,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Nara",
    "capital": "",
    "population": 55005,
    "id": 1392404351
  },
  {
    "city": "Twin Falls",
    "city_ascii": "Twin Falls",
    "lat": 42.5645,
    "lng": -114.4615,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Idaho",
    "capital": "",
    "population": 55556,
    "id": 1840021301
  },
  {
    "city": "Brookhaven",
    "city_ascii": "Brookhaven",
    "lat": 33.8744,
    "lng": -84.3314,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 55554,
    "id": 1840029224
  },
  {
    "city": "Schweinfurt",
    "city_ascii": "Schweinfurt",
    "lat": 50.05,
    "lng": 10.2333,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 54032,
    "id": 1276316912
  },
  {
    "city": "Batac",
    "city_ascii": "Batac",
    "lat": 18.0554,
    "lng": 120.5649,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Ilocos Norte",
    "capital": "",
    "population": 55201,
    "id": 1608314719
  },
  {
    "city": "Baden-Baden",
    "city_ascii": "Baden-Baden",
    "lat": 48.7619,
    "lng": 8.2408,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "minor",
    "population": 55123,
    "id": 1276150269
  },
  {
    "city": "Novato",
    "city_ascii": "Novato",
    "lat": 38.092,
    "lng": -122.5576,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 55516,
    "id": 1840020260
  },
  {
    "city": "Tigard",
    "city_ascii": "Tigard",
    "lat": 45.4237,
    "lng": -122.7845,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 55514,
    "id": 1840021206
  },
  {
    "city": "Aurora",
    "city_ascii": "Aurora",
    "lat": 44,
    "lng": -79.4667,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 55445,
    "id": 1124085034
  },
  {
    "city": "Highland",
    "city_ascii": "Highland",
    "lat": 34.1113,
    "lng": -117.1654,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 55417,
    "id": 1840020405
  },
  {
    "city": "Langenhagen",
    "city_ascii": "Langenhagen",
    "lat": 52.4394,
    "lng": 9.74,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 54631,
    "id": 1276031765
  },
  {
    "city": "Dearborn Heights",
    "city_ascii": "Dearborn Heights",
    "lat": 42.3164,
    "lng": -83.2769,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 55353,
    "id": 1840003970
  },
  {
    "city": "Puttūr",
    "city_ascii": "Puttur",
    "lat": 13.45,
    "lng": 79.55,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Andhra Pradesh",
    "capital": "",
    "population": 54092,
    "id": 1356396930
  },
  {
    "city": "New Plymouth",
    "city_ascii": "New Plymouth",
    "lat": -39.0578,
    "lng": 174.0742,
    "country": "New Zealand",
    "iso2": "NZ",
    "iso3": "NZL",
    "admin_name": "Taranaki",
    "capital": "",
    "population": 55300,
    "id": 1554939405
  },
  {
    "city": "Hattingen",
    "city_ascii": "Hattingen",
    "lat": 51.3992,
    "lng": 7.1858,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 54562,
    "id": 1276698647
  },
  {
    "city": "Bad Homburg",
    "city_ascii": "Bad Homburg",
    "lat": 50.2292,
    "lng": 8.6105,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "minor",
    "population": 54248,
    "id": 1276019348
  },
  {
    "city": "Pombal",
    "city_ascii": "Pombal",
    "lat": 39.9162,
    "lng": -8.6279,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Leiria",
    "capital": "minor",
    "population": 55217,
    "id": 1620430100
  },
  {
    "city": "Grapevine",
    "city_ascii": "Grapevine",
    "lat": 32.9343,
    "lng": -97.0744,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 55281,
    "id": 1840020697
  },
  {
    "city": "Hervey Bay",
    "city_ascii": "Hervey Bay",
    "lat": -25.2853,
    "lng": 152.8728,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Queensland",
    "capital": "",
    "population": 54674,
    "id": 1036833938
  },
  {
    "city": "Amalāpuram",
    "city_ascii": "Amalapuram",
    "lat": 16.5833,
    "lng": 82.0167,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Andhra Pradesh",
    "capital": "",
    "population": 53231,
    "id": 1356997622
  },
  {
    "city": "Fond du Lac",
    "city_ascii": "Fond du Lac",
    "lat": 43.7718,
    "lng": -88.4396,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 55209,
    "id": 1840002603
  },
  {
    "city": "Punganūru",
    "city_ascii": "Punganuru",
    "lat": 13.3667,
    "lng": 78.5833,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Andhra Pradesh",
    "capital": "",
    "population": 54746,
    "id": 1356796945
  },
  {
    "city": "Nāndod",
    "city_ascii": "Nandod",
    "lat": 21.8704,
    "lng": 73.5026,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Gujarāt",
    "capital": "",
    "population": 54923,
    "id": 1356800561
  },
  {
    "city": "Apple Valley",
    "city_ascii": "Apple Valley",
    "lat": 44.7457,
    "lng": -93.2006,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 55135,
    "id": 1840006768
  },
  {
    "city": "Qiryat Gat",
    "city_ascii": "Qiryat Gat",
    "lat": 31.6061,
    "lng": 34.7717,
    "country": "Israel",
    "iso2": "IL",
    "iso3": "ISR",
    "admin_name": "Southern",
    "capital": "",
    "population": 55000,
    "id": 1376511466
  },
  {
    "city": "Chicopee",
    "city_ascii": "Chicopee",
    "lat": 42.1764,
    "lng": -72.5719,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 55126,
    "id": 1840000462
  },
  {
    "city": "Zhushan",
    "city_ascii": "Zhushan",
    "lat": 23.6889,
    "lng": 120.7289,
    "country": "Taiwan",
    "iso2": "TW",
    "iso3": "TWN",
    "admin_name": "Nantou",
    "capital": "",
    "population": 54870,
    "id": 1158343390
  },
  {
    "city": "Camaquã",
    "city_ascii": "Camaqua",
    "lat": -30.8511,
    "lng": -51.8122,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Rio Grande do Sul",
    "capital": "",
    "population": 53169,
    "id": 1076449009
  },
  {
    "city": "Wesley Chapel",
    "city_ascii": "Wesley Chapel",
    "lat": 28.2106,
    "lng": -82.3238,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 55046,
    "id": 1840014118
  },
  {
    "city": "Narbonne",
    "city_ascii": "Narbonne",
    "lat": 43.1836,
    "lng": 3.0042,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Occitanie",
    "capital": "minor",
    "population": 54700,
    "id": 1250476648
  },
  {
    "city": "Tequisquiapan",
    "city_ascii": "Tequisquiapan",
    "lat": 20.5206,
    "lng": -99.8958,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Querétaro",
    "capital": "minor",
    "population": 54929,
    "id": 1484303338
  },
  {
    "city": "Cathedral City",
    "city_ascii": "Cathedral City",
    "lat": 33.8363,
    "lng": -116.4642,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 55007,
    "id": 1840019293
  },
  {
    "city": "La Roche-sur-Yon",
    "city_ascii": "La Roche-sur-Yon",
    "lat": 46.6705,
    "lng": -1.426,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Pays de la Loire",
    "capital": "minor",
    "population": 54372,
    "id": 1250977109
  },
  {
    "city": "San Cristóbal Verapaz",
    "city_ascii": "San Cristobal Verapaz",
    "lat": 15.365,
    "lng": -90.4792,
    "country": "Guatemala",
    "iso2": "GT",
    "iso3": "GTM",
    "admin_name": "Alta Verapaz",
    "capital": "minor",
    "population": 54704,
    "id": 1320384406
  },
  {
    "city": "Pôrto Ferreira",
    "city_ascii": "Porto Ferreira",
    "lat": -21.8539,
    "lng": -47.4789,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 54761,
    "id": 1076743280
  },
  {
    "city": "Hacienda Heights",
    "city_ascii": "Hacienda Heights",
    "lat": 33.997,
    "lng": -117.9728,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 54970,
    "id": 1840017921
  },
  {
    "city": "Funato",
    "city_ascii": "Funato",
    "lat": 34.2564,
    "lng": 135.3111,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Wakayama",
    "capital": "",
    "population": 53566,
    "id": 1392061989
  },
  {
    "city": "Vannes",
    "city_ascii": "Vannes",
    "lat": 47.6559,
    "lng": -2.7603,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Bretagne",
    "capital": "minor",
    "population": 53352,
    "id": 1250939385
  },
  {
    "city": "Delano",
    "city_ascii": "Delano",
    "lat": 35.767,
    "lng": -119.2637,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 54917,
    "id": 1840019151
  },
  {
    "city": "Bentonville",
    "city_ascii": "Bentonville",
    "lat": 36.3546,
    "lng": -94.2306,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arkansas",
    "capital": "",
    "population": 54909,
    "id": 1840013319
  },
  {
    "city": "Stonecrest",
    "city_ascii": "Stonecrest",
    "lat": 33.6842,
    "lng": -84.1372,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 54903,
    "id": 1840029466
  },
  {
    "city": "Kettering",
    "city_ascii": "Kettering",
    "lat": 39.6957,
    "lng": -84.1495,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 54855,
    "id": 1840003783
  },
  {
    "city": "Canlaon",
    "city_ascii": "Canlaon",
    "lat": 10.3833,
    "lng": 123.2,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Negros Oriental",
    "capital": "",
    "population": 54509,
    "id": 1608924591
  },
  {
    "city": "Sarpsborg",
    "city_ascii": "Sarpsborg",
    "lat": 59.2839,
    "lng": 11.1096,
    "country": "Norway",
    "iso2": "NO",
    "iso3": "NOR",
    "admin_name": "Østfold",
    "capital": "minor",
    "population": 54678,
    "id": 1578097769
  },
  {
    "city": "Colton",
    "city_ascii": "Colton",
    "lat": 34.0538,
    "lng": -117.3254,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 54824,
    "id": 1840019120
  },
  {
    "city": "Pulheim",
    "city_ascii": "Pulheim",
    "lat": 51,
    "lng": 6.8,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 54071,
    "id": 1276728926
  },
  {
    "city": "Kingston",
    "city_ascii": "Kingston",
    "lat": 41.9295,
    "lng": -73.9968,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 54791,
    "id": 1840000493
  },
  {
    "city": "Ostrołęka",
    "city_ascii": "Ostroleka",
    "lat": 53.0833,
    "lng": 21.5667,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Mazowieckie",
    "capital": "minor",
    "population": 52900,
    "id": 1616360926
  },
  {
    "city": "Hanyū",
    "city_ascii": "Hanyu",
    "lat": 36.1728,
    "lng": 139.5486,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Saitama",
    "capital": "",
    "population": 53870,
    "id": 1392690560
  },
  {
    "city": "Mutsu",
    "city_ascii": "Mutsu",
    "lat": 41.2931,
    "lng": 141.1831,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Aomori",
    "capital": "",
    "population": 54721,
    "id": 1392177200
  },
  {
    "city": "Chota",
    "city_ascii": "Chota",
    "lat": -6.5614,
    "lng": -78.6486,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "Cajamarca",
    "capital": "",
    "population": 54611,
    "id": 1604928947
  },
  {
    "city": "Lingen",
    "city_ascii": "Lingen",
    "lat": 52.5233,
    "lng": 7.3172,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 54422,
    "id": 1276978218
  },
  {
    "city": "Bad Salzuflen",
    "city_ascii": "Bad Salzuflen",
    "lat": 52.0875,
    "lng": 8.7506,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 54127,
    "id": 1276526163
  },
  {
    "city": "West Haven",
    "city_ascii": "West Haven",
    "lat": 41.2739,
    "lng": -72.9672,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 54620,
    "id": 1840004852
  },
  {
    "city": "El Banco",
    "city_ascii": "El Banco",
    "lat": 9.0003,
    "lng": -73.98,
    "country": "Colombia",
    "iso2": "CO",
    "iso3": "COL",
    "admin_name": "Magdalena",
    "capital": "minor",
    "population": 54522,
    "id": 1170111607
  },
  {
    "city": "St. Cloud",
    "city_ascii": "St. Cloud",
    "lat": 28.2294,
    "lng": -81.2829,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 54579,
    "id": 1840015971
  },
  {
    "city": "Sertolovo",
    "city_ascii": "Sertolovo",
    "lat": 60.15,
    "lng": 30.2,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Leningradskaya Oblast’",
    "capital": "",
    "population": 54497,
    "id": 1643009710
  },
  {
    "city": "Chivilcoy",
    "city_ascii": "Chivilcoy",
    "lat": -34.9,
    "lng": -60.0167,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires",
    "capital": "minor",
    "population": 54514,
    "id": 1032269915
  },
  {
    "city": "Cholet",
    "city_ascii": "Cholet",
    "lat": 47.06,
    "lng": -0.8783,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Pays de la Loire",
    "capital": "minor",
    "population": 53917,
    "id": 1250540016
  },
  {
    "city": "Rotorua",
    "city_ascii": "Rotorua",
    "lat": -38.1378,
    "lng": 176.2514,
    "country": "New Zealand",
    "iso2": "NZ",
    "iso3": "NZL",
    "admin_name": "Bay of Plenty",
    "capital": "",
    "population": 54500,
    "id": 1554000379
  },
  {
    "city": "São José do Rio Pardo",
    "city_ascii": "Sao Jose do Rio Pardo",
    "lat": -21.5958,
    "lng": -46.8889,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 54388,
    "id": 1076381687
  },
  {
    "city": "Kotka",
    "city_ascii": "Kotka",
    "lat": 60.4667,
    "lng": 26.9458,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Kymenlaakso",
    "capital": "minor",
    "population": 54319,
    "id": 1246200320
  },
  {
    "city": "Kavála",
    "city_ascii": "Kavala",
    "lat": 40.9396,
    "lng": 24.4069,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Anatolikí Makedonía kai Thráki",
    "capital": "minor",
    "population": 54027,
    "id": 1300398756
  },
  {
    "city": "Normal",
    "city_ascii": "Normal",
    "lat": 40.5218,
    "lng": -88.9881,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 54469,
    "id": 1840009401
  },
  {
    "city": "Palmaner",
    "city_ascii": "Palmaner",
    "lat": 13.2,
    "lng": 78.75,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Andhra Pradesh",
    "capital": "",
    "population": 54035,
    "id": 1356813856
  },
  {
    "city": "Qing’an",
    "city_ascii": "Qing'an",
    "lat": 46.8719,
    "lng": 127.5118,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Heilongjiang",
    "capital": "minor",
    "population": 53206,
    "id": 1156233891
  },
  {
    "city": "Chiquinquirá",
    "city_ascii": "Chiquinquira",
    "lat": 5.6204,
    "lng": -73.8199,
    "country": "Colombia",
    "iso2": "CO",
    "iso3": "COL",
    "admin_name": "Boyacá",
    "capital": "minor",
    "population": 53975,
    "id": 1170668478
  },
  {
    "city": "Itupeva",
    "city_ascii": "Itupeva",
    "lat": -23.1531,
    "lng": -47.0578,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 54128,
    "id": 1076387390
  },
  {
    "city": "Tarma",
    "city_ascii": "Tarma",
    "lat": -11.4167,
    "lng": -75.6833,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "Junín",
    "capital": "",
    "population": 54078,
    "id": 1604810508
  },
  {
    "city": "Jalor",
    "city_ascii": "Jalor",
    "lat": 25.35,
    "lng": 72.6167,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Rājasthān",
    "capital": "",
    "population": 54081,
    "id": 1356488284
  },
  {
    "city": "Zarafshon Shahri",
    "city_ascii": "Zarafshon Shahri",
    "lat": 41.5667,
    "lng": 64.2,
    "country": "Uzbekistan",
    "iso2": "UZ",
    "iso3": "UZB",
    "admin_name": "Navoiy",
    "capital": "",
    "population": 54300,
    "id": 1860278868
  },
  {
    "city": "Vikārābād",
    "city_ascii": "Vikarabad",
    "lat": 17.33,
    "lng": 77.9,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Telangana",
    "capital": "",
    "population": 53143,
    "id": 1356904767
  },
  {
    "city": "Guillermo E. Hudson",
    "city_ascii": "Guillermo E. Hudson",
    "lat": -34.7903,
    "lng": -58.1486,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires",
    "capital": "",
    "population": 52697,
    "id": 1032658135
  },
  {
    "city": "Hashtpar",
    "city_ascii": "Hashtpar",
    "lat": 37.7992,
    "lng": 48.9053,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Gīlān",
    "capital": "minor",
    "population": 54178,
    "id": 1364496739
  },
  {
    "city": "Union",
    "city_ascii": "Union",
    "lat": 42.1258,
    "lng": -76.0329,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 54250,
    "id": 1840058557
  },
  {
    "city": "Siena",
    "city_ascii": "Siena",
    "lat": 43.3183,
    "lng": 11.3314,
    "country": "Italy",
    "iso2": "IT",
    "iso3": "ITA",
    "admin_name": "Tuscany",
    "capital": "minor",
    "population": 53772,
    "id": 1380088439
  },
  {
    "city": "Ruteng",
    "city_ascii": "Ruteng",
    "lat": -8.6118,
    "lng": 120.4698,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Nusa Tenggara Timur",
    "capital": "minor",
    "population": 53976,
    "id": 1360084520
  },
  {
    "city": "Tokmok",
    "city_ascii": "Tokmok",
    "lat": 42.839,
    "lng": 75.291,
    "country": "Kyrgyzstan",
    "iso2": "KG",
    "iso3": "KGZ",
    "admin_name": "Chüy",
    "capital": "",
    "population": 53231,
    "id": 1417875875
  },
  {
    "city": "Wayne",
    "city_ascii": "Wayne",
    "lat": 40.9481,
    "lng": -74.2453,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 54190,
    "id": 1840081808
  },
  {
    "city": "Ban Om Noi",
    "city_ascii": "Ban Om Noi",
    "lat": 13.7001,
    "lng": 100.3241,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Samut Sakhon",
    "capital": "",
    "population": 52457,
    "id": 1764298817
  },
  {
    "city": "Zhigulevsk",
    "city_ascii": "Zhigulevsk",
    "lat": 53.4,
    "lng": 49.5,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Samarskaya Oblast’",
    "capital": "",
    "population": 53407,
    "id": 1643351936
  },
  {
    "city": "Arroyo Grande",
    "city_ascii": "Arroyo Grande",
    "lat": 35.1241,
    "lng": -120.5845,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 54177,
    "id": 1840019125
  },
  {
    "city": "Progreso",
    "city_ascii": "Progreso",
    "lat": 21.28,
    "lng": -89.67,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Yucatán",
    "capital": "minor",
    "population": 53958,
    "id": 1484340626
  },
  {
    "city": "Minnetonka",
    "city_ascii": "Minnetonka",
    "lat": 44.9322,
    "lng": -93.4598,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 54064,
    "id": 1840007831
  },
  {
    "city": "Jagtiāl",
    "city_ascii": "Jagtial",
    "lat": 18.8,
    "lng": 78.93,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Telangana",
    "capital": "",
    "population": 53213,
    "id": 1356225238
  },
  {
    "city": "Dholka",
    "city_ascii": "Dholka",
    "lat": 22.72,
    "lng": 72.47,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Gujarāt",
    "capital": "",
    "population": 53792,
    "id": 1356709185
  },
  {
    "city": "Milford",
    "city_ascii": "Milford",
    "lat": 41.2256,
    "lng": -73.0616,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 54047,
    "id": 1840028594
  },
  {
    "city": "Shali",
    "city_ascii": "Shali",
    "lat": 43.15,
    "lng": 45.9,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Chechnya",
    "capital": "minor",
    "population": 53807,
    "id": 1643096920
  },
  {
    "city": "La Lima",
    "city_ascii": "La Lima",
    "lat": 15.433,
    "lng": -87.917,
    "country": "Honduras",
    "iso2": "HN",
    "iso3": "HND",
    "admin_name": "Cortés",
    "capital": "",
    "population": 53492,
    "id": 1340766612
  },
  {
    "city": "Liski",
    "city_ascii": "Liski",
    "lat": 50.9822,
    "lng": 39.4994,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Voronezhskaya Oblast’",
    "capital": "",
    "population": 53897,
    "id": 1643945171
  },
  {
    "city": "Tuni",
    "city_ascii": "Tuni",
    "lat": 17.35,
    "lng": 82.55,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Andhra Pradesh",
    "capital": "",
    "population": 53425,
    "id": 1356073049
  },
  {
    "city": "Yucaipa",
    "city_ascii": "Yucaipa",
    "lat": 34.0336,
    "lng": -117.0429,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 53921,
    "id": 1840021732
  },
  {
    "city": "Markala",
    "city_ascii": "Markala",
    "lat": 13.6704,
    "lng": -6.07,
    "country": "Mali",
    "iso2": "ML",
    "iso3": "MLI",
    "admin_name": "Ségou",
    "capital": "",
    "population": 53738,
    "id": 1466526342
  },
  {
    "city": "Svobodnyy",
    "city_ascii": "Svobodnyy",
    "lat": 51.3833,
    "lng": 128.1333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Amurskaya Oblast’",
    "capital": "",
    "population": 53678,
    "id": 1643016250
  },
  {
    "city": "Jaggayyapeta",
    "city_ascii": "Jaggayyapeta",
    "lat": 16.892,
    "lng": 80.0976,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Telangana",
    "capital": "",
    "population": 53530,
    "id": 1356537421
  },
  {
    "city": "Salo",
    "city_ascii": "Salo",
    "lat": 60.3831,
    "lng": 23.1331,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Varsinais-Suomi",
    "capital": "minor",
    "population": 53890,
    "id": 1246750581
  },
  {
    "city": "Brunswick",
    "city_ascii": "Brunswick",
    "lat": 31.145,
    "lng": -81.474,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 53914,
    "id": 1840013875
  },
  {
    "city": "Bījār",
    "city_ascii": "Bijar",
    "lat": 35.8741,
    "lng": 47.5937,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Kordestān",
    "capital": "minor",
    "population": 53871,
    "id": 1364131066
  },
  {
    "city": "Williamsport",
    "city_ascii": "Williamsport",
    "lat": 41.2398,
    "lng": -77.0371,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 53861,
    "id": 1840000637
  },
  {
    "city": "Bristol",
    "city_ascii": "Bristol",
    "lat": 40.1216,
    "lng": -74.8667,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 53803,
    "id": 1840001318
  },
  {
    "city": "Pamplona",
    "city_ascii": "Pamplona",
    "lat": 7.3904,
    "lng": -72.66,
    "country": "Colombia",
    "iso2": "CO",
    "iso3": "COL",
    "admin_name": "Norte de Santander",
    "capital": "minor",
    "population": 53587,
    "id": 1170275020
  },
  {
    "city": "Minami-Sōma",
    "city_ascii": "Minami-Soma",
    "lat": 37.6422,
    "lng": 140.9572,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Fukushima",
    "capital": "",
    "population": 53643,
    "id": 1392151264
  },
  {
    "city": "Nordhorn",
    "city_ascii": "Nordhorn",
    "lat": 52.4333,
    "lng": 7.0667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "minor",
    "population": 53403,
    "id": 1276078036
  },
  {
    "city": "Elyria",
    "city_ascii": "Elyria",
    "lat": 41.3761,
    "lng": -82.1063,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 53757,
    "id": 1840000643
  },
  {
    "city": "Leesburg",
    "city_ascii": "Leesburg",
    "lat": 39.1058,
    "lng": -77.5544,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 53727,
    "id": 1840005872
  },
  {
    "city": "Millcreek Township",
    "city_ascii": "Millcreek Township",
    "lat": 42.0861,
    "lng": -80.1193,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 53722,
    "id": 1840143176
  },
  {
    "city": "Mount Prospect",
    "city_ascii": "Mount Prospect",
    "lat": 42.0641,
    "lng": -87.9375,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 53719,
    "id": 1840011300
  },
  {
    "city": "Marco de Canavezes",
    "city_ascii": "Marco de Canavezes",
    "lat": 41.1833,
    "lng": -8.15,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Porto",
    "capital": "minor",
    "population": 53450,
    "id": 1620534118
  },
  {
    "city": "Krasnokamsk",
    "city_ascii": "Krasnokamsk",
    "lat": 58.0833,
    "lng": 55.75,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Permskiy Kray",
    "capital": "",
    "population": 53631,
    "id": 1643177240
  },
  {
    "city": "Imarichō-kō",
    "city_ascii": "Imaricho-ko",
    "lat": 33.2647,
    "lng": 129.8808,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Saga",
    "capital": "",
    "population": 53483,
    "id": 1392532933
  },
  {
    "city": "Upata",
    "city_ascii": "Upata",
    "lat": 8.0204,
    "lng": -62.41,
    "country": "Venezuela",
    "iso2": "VE",
    "iso3": "VEN",
    "admin_name": "Bolívar",
    "capital": "minor",
    "population": 53685,
    "id": 1862794619
  },
  {
    "city": "Port Shepstone",
    "city_ascii": "Port Shepstone",
    "lat": -30.7411,
    "lng": 30.4547,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "KwaZulu-Natal",
    "capital": "",
    "population": 52793,
    "id": 1710443227
  },
  {
    "city": "Wetzlar",
    "city_ascii": "Wetzlar",
    "lat": 50.5667,
    "lng": 8.5,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "minor",
    "population": 52954,
    "id": 1276020868
  },
  {
    "city": "Enerhodar",
    "city_ascii": "Enerhodar",
    "lat": 47.4989,
    "lng": 34.6558,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Zaporiz’ka Oblast’",
    "capital": "minor",
    "population": 53567,
    "id": 1804819053
  },
  {
    "city": "Pinellas Park",
    "city_ascii": "Pinellas Park",
    "lat": 27.8589,
    "lng": -82.7078,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 53637,
    "id": 1840015112
  },
  {
    "city": "Frechen",
    "city_ascii": "Frechen",
    "lat": 50.9167,
    "lng": 6.8167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 52473,
    "id": 1276255319
  },
  {
    "city": "Hamilton",
    "city_ascii": "Hamilton",
    "lat": 32.2942,
    "lng": -64.7839,
    "country": "Bermuda",
    "iso2": "BM",
    "iso3": "BMU",
    "admin_name": "Hamilton",
    "capital": "",
    "population": 52320,
    "id": 1060000000
  },
  {
    "city": "Neustadt",
    "city_ascii": "Neustadt",
    "lat": 49.35,
    "lng": 8.15,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Rhineland-Palatinate",
    "capital": "minor",
    "population": 53148,
    "id": 1276053769
  },
  {
    "city": "Pijijiapan",
    "city_ascii": "Pijijiapan",
    "lat": 15.6867,
    "lng": -93.2092,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Chiapas",
    "capital": "minor",
    "population": 53525,
    "id": 1484994799
  },
  {
    "city": "Bellevue",
    "city_ascii": "Bellevue",
    "lat": 41.1535,
    "lng": -95.9357,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Nebraska",
    "capital": "",
    "population": 53544,
    "id": 1840007142
  },
  {
    "city": "West Sacramento",
    "city_ascii": "West Sacramento",
    "lat": 38.5557,
    "lng": -121.5504,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 53519,
    "id": 1840021483
  },
  {
    "city": "Gudermes",
    "city_ascii": "Gudermes",
    "lat": 43.35,
    "lng": 46.1,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Chechnya",
    "capital": "minor",
    "population": 52908,
    "id": 1643035629
  },
  {
    "city": "Apopka",
    "city_ascii": "Apopka",
    "lat": 28.7014,
    "lng": -81.5304,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 53447,
    "id": 1840014093
  },
  {
    "city": "Olímpia",
    "city_ascii": "Olimpia",
    "lat": -20.7369,
    "lng": -48.915,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 53360,
    "id": 1076866347
  },
  {
    "city": "Lewiston",
    "city_ascii": "Lewiston",
    "lat": 46.3934,
    "lng": -116.9934,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Idaho",
    "capital": "",
    "population": 53384,
    "id": 1840019897
  },
  {
    "city": "Capivari",
    "city_ascii": "Capivari",
    "lat": -22.995,
    "lng": -47.5078,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 53152,
    "id": 1076000098
  },
  {
    "city": "Angol",
    "city_ascii": "Angol",
    "lat": -37.7988,
    "lng": -72.7086,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Araucanía",
    "capital": "minor",
    "population": 53262,
    "id": 1152404762
  },
  {
    "city": "Grand Island",
    "city_ascii": "Grand Island",
    "lat": 40.9214,
    "lng": -98.3584,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Nebraska",
    "capital": "",
    "population": 53296,
    "id": 1840000956
  },
  {
    "city": "Palm Desert",
    "city_ascii": "Palm Desert",
    "lat": 33.7378,
    "lng": -116.3695,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 53275,
    "id": 1840020555
  },
  {
    "city": "Karlovac",
    "city_ascii": "Karlovac",
    "lat": 45.4931,
    "lng": 15.5558,
    "country": "Croatia",
    "iso2": "HR",
    "iso3": "HRV",
    "admin_name": "Karlovačka Županija",
    "capital": "",
    "population": 53134,
    "id": 1191000040
  },
  {
    "city": "Vyksa",
    "city_ascii": "Vyksa",
    "lat": 55.3194,
    "lng": 42.1731,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Nizhegorodskaya Oblast’",
    "capital": "",
    "population": 53219,
    "id": 1643006557
  },
  {
    "city": "Passau",
    "city_ascii": "Passau",
    "lat": 48.5667,
    "lng": 13.4667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "minor",
    "population": 52469,
    "id": 1276123699
  },
  {
    "city": "Fréjus",
    "city_ascii": "Frejus",
    "lat": 43.433,
    "lng": 6.737,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Provence-Alpes-Côte d’Azur",
    "capital": "",
    "population": 52672,
    "id": 1250083981
  },
  {
    "city": "Milford city",
    "city_ascii": "Milford city",
    "lat": 41.2256,
    "lng": -73.0625,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 53195,
    "id": 1840028605
  },
  {
    "city": "Bayonne",
    "city_ascii": "Bayonne",
    "lat": 43.49,
    "lng": -1.48,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Nouvelle-Aquitaine",
    "capital": "minor",
    "population": 51228,
    "id": 1250223048
  },
  {
    "city": "Watertown",
    "city_ascii": "Watertown",
    "lat": 43.9734,
    "lng": -75.9094,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 53163,
    "id": 1840002200
  },
  {
    "city": "Sagua la Grande",
    "city_ascii": "Sagua la Grande",
    "lat": 22.8086,
    "lng": -80.0711,
    "country": "Cuba",
    "iso2": "CU",
    "iso3": "CUB",
    "admin_name": "Villa Clara",
    "capital": "minor",
    "population": 53077,
    "id": 1192446410
  },
  {
    "city": "Little Elm",
    "city_ascii": "Little Elm",
    "lat": 33.1856,
    "lng": -96.929,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 53126,
    "id": 1840020640
  },
  {
    "city": "Pasaje",
    "city_ascii": "Pasaje",
    "lat": -3.3269,
    "lng": -79.8049,
    "country": "Ecuador",
    "iso2": "EC",
    "iso3": "ECU",
    "admin_name": "El Oro",
    "capital": "",
    "population": 52673,
    "id": 1218997966
  },
  {
    "city": "Chambersburg",
    "city_ascii": "Chambersburg",
    "lat": 39.9315,
    "lng": -77.6556,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 53083,
    "id": 1840001410
  },
  {
    "city": "Peabody",
    "city_ascii": "Peabody",
    "lat": 42.5335,
    "lng": -70.9724,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 53070,
    "id": 1840000414
  },
  {
    "city": "Wylie",
    "city_ascii": "Wylie",
    "lat": 33.0362,
    "lng": -96.5161,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 53067,
    "id": 1840022018
  },
  {
    "city": "Beaufort",
    "city_ascii": "Beaufort",
    "lat": 32.4597,
    "lng": -80.7235,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 53051,
    "id": 1840013818
  },
  {
    "city": "Arsenyev",
    "city_ascii": "Arsenyev",
    "lat": 44.1667,
    "lng": 133.2667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Primorskiy Kray",
    "capital": "",
    "population": 52471,
    "id": 1643534912
  },
  {
    "city": "Karviná",
    "city_ascii": "Karvina",
    "lat": 49.8542,
    "lng": 18.5428,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Moravskoslezský Kraj",
    "capital": "",
    "population": 52128,
    "id": 1203738904
  },
  {
    "city": "Mercedes",
    "city_ascii": "Mercedes",
    "lat": -34.65,
    "lng": -59.4333,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires",
    "capital": "minor",
    "population": 52949,
    "id": 1032994289
  },
  {
    "city": "Ahlen",
    "city_ascii": "Ahlen",
    "lat": 51.7633,
    "lng": 7.8911,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 52582,
    "id": 1276843072
  },
  {
    "city": "DeSoto",
    "city_ascii": "DeSoto",
    "lat": 32.5992,
    "lng": -96.8633,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 52988,
    "id": 1840019441
  },
  {
    "city": "Venkatagiri",
    "city_ascii": "Venkatagiri",
    "lat": 13.9667,
    "lng": 79.5833,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Andhra Pradesh",
    "capital": "",
    "population": 52688,
    "id": 1356644043
  },
  {
    "city": "Tomiya",
    "city_ascii": "Tomiya",
    "lat": 38.4,
    "lng": 140.8953,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Miyagi",
    "capital": "",
    "population": 51889,
    "id": 1392996342
  },
  {
    "city": "Torrelavega",
    "city_ascii": "Torrelavega",
    "lat": 43.3531,
    "lng": -4.0458,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Cantabria",
    "capital": "",
    "population": 51494,
    "id": 1724275022
  },
  {
    "city": "Sihor",
    "city_ascii": "Sihor",
    "lat": 21.7,
    "lng": 71.97,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Gujarāt",
    "capital": "",
    "population": 52603,
    "id": 1356675162
  },
  {
    "city": "Lorica",
    "city_ascii": "Lorica",
    "lat": 9.2419,
    "lng": -75.816,
    "country": "Colombia",
    "iso2": "CO",
    "iso3": "COL",
    "admin_name": "Córdoba",
    "capital": "minor",
    "population": 52771,
    "id": 1170094504
  },
  {
    "city": "Chone",
    "city_ascii": "Chone",
    "lat": -0.6833,
    "lng": -80.1,
    "country": "Ecuador",
    "iso2": "EC",
    "iso3": "ECU",
    "admin_name": "Manabí",
    "capital": "",
    "population": 52810,
    "id": 1218083347
  },
  {
    "city": "Jangaon",
    "city_ascii": "Jangaon",
    "lat": 17.72,
    "lng": 79.18,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Telangana",
    "capital": "",
    "population": 52394,
    "id": 1356403400
  },
  {
    "city": "Poprad",
    "city_ascii": "Poprad",
    "lat": 49.05,
    "lng": 20.3,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Prešovský",
    "capital": "minor",
    "population": 52037,
    "id": 1703451549
  },
  {
    "city": "Mongaguá",
    "city_ascii": "Mongagua",
    "lat": -24.0869,
    "lng": -46.6289,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 52492,
    "id": 1076393935
  },
  {
    "city": "Edina",
    "city_ascii": "Edina",
    "lat": 44.8914,
    "lng": -93.3602,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 52857,
    "id": 1840007822
  },
  {
    "city": "Wolfenbüttel",
    "city_ascii": "Wolfenbuttel",
    "lat": 52.1622,
    "lng": 10.5369,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "minor",
    "population": 52174,
    "id": 1276388010
  },
  {
    "city": "Krasnokamensk",
    "city_ascii": "Krasnokamensk",
    "lat": 50.1,
    "lng": 118.0333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Zabaykal’skiy Kray",
    "capital": "",
    "population": 52811,
    "id": 1643383674
  },
  {
    "city": "San José Villa de Allende",
    "city_ascii": "San Jose Villa de Allende",
    "lat": 19.3747,
    "lng": -100.1475,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "México",
    "capital": "minor",
    "population": 52641,
    "id": 1484492033
  },
  {
    "city": "Parsippany-Troy Hills",
    "city_ascii": "Parsippany-Troy Hills",
    "lat": 40.8601,
    "lng": -74.4238,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 52768,
    "id": 1840081736
  },
  {
    "city": "Wheaton",
    "city_ascii": "Wheaton",
    "lat": 41.8561,
    "lng": -88.1083,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 52745,
    "id": 1840010166
  },
  {
    "city": "Thongwa",
    "city_ascii": "Thongwa",
    "lat": 16.7547,
    "lng": 96.5193,
    "country": "Burma",
    "iso2": "MM",
    "iso3": "MMR",
    "admin_name": "Yangon",
    "capital": "",
    "population": 52496,
    "id": 1104339441
  },
  {
    "city": "Jaén",
    "city_ascii": "Jaen",
    "lat": -5.6992,
    "lng": -78.8009,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "Cajamarca",
    "capital": "",
    "population": 52493,
    "id": 1604707495
  },
  {
    "city": "Ålesund",
    "city_ascii": "Alesund",
    "lat": 62.4723,
    "lng": 6.1549,
    "country": "Norway",
    "iso2": "NO",
    "iso3": "NOR",
    "admin_name": "Møre og Romsdal",
    "capital": "minor",
    "population": 52163,
    "id": 1578050516
  },
  {
    "city": "Kobryn",
    "city_ascii": "Kobryn",
    "lat": 52.2167,
    "lng": 24.3667,
    "country": "Belarus",
    "iso2": "BY",
    "iso3": "BLR",
    "admin_name": "Brestskaya Voblasts’",
    "capital": "minor",
    "population": 52600,
    "id": 1112806145
  },
  {
    "city": "Pacora",
    "city_ascii": "Pacora",
    "lat": 9.08,
    "lng": -79.28,
    "country": "Panama",
    "iso2": "PA",
    "iso3": "PAN",
    "admin_name": "Panamá",
    "capital": "minor",
    "population": 52494,
    "id": 1591023850
  },
  {
    "city": "Horishni Plavni",
    "city_ascii": "Horishni Plavni",
    "lat": 49.0107,
    "lng": 33.6562,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Poltavs’ka Oblast’",
    "capital": "",
    "population": 52167,
    "id": 1804671151
  },
  {
    "city": "An Nabk",
    "city_ascii": "An Nabk",
    "lat": 34.025,
    "lng": 36.7344,
    "country": "Syria",
    "iso2": "SY",
    "iso3": "SYR",
    "admin_name": "Rīf Dimashq",
    "capital": "minor",
    "population": 52502,
    "id": 1760474665
  },
  {
    "city": "Arles",
    "city_ascii": "Arles",
    "lat": 43.6767,
    "lng": 4.6278,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Provence-Alpes-Côte d’Azur",
    "capital": "minor",
    "population": 52548,
    "id": 1250682294
  },
  {
    "city": "Mandamāri",
    "city_ascii": "Mandamari",
    "lat": 18.9822,
    "lng": 79.4811,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Telangana",
    "capital": "",
    "population": 52352,
    "id": 1356193430
  },
  {
    "city": "Lacey",
    "city_ascii": "Lacey",
    "lat": 47.046,
    "lng": -122.7934,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 52592,
    "id": 1840019866
  },
  {
    "city": "Summerville",
    "city_ascii": "Summerville",
    "lat": 33.0015,
    "lng": -80.1799,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 52549,
    "id": 1840016886
  },
  {
    "city": "Vyazma",
    "city_ascii": "Vyazma",
    "lat": 55.2103,
    "lng": 34.285,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Smolenskaya Oblast’",
    "capital": "",
    "population": 52506,
    "id": 1643507171
  },
  {
    "city": "Miyako",
    "city_ascii": "Miyako",
    "lat": 39.6414,
    "lng": 141.9572,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Iwate",
    "capital": "",
    "population": 52471,
    "id": 1392075695
  },
  {
    "city": "Ibbenbüren",
    "city_ascii": "Ibbenburen",
    "lat": 52.2778,
    "lng": 7.7167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 51904,
    "id": 1276476330
  },
  {
    "city": "Kleve",
    "city_ascii": "Kleve",
    "lat": 51.79,
    "lng": 6.14,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "minor",
    "population": 51845,
    "id": 1276674243
  },
  {
    "city": "Bandō",
    "city_ascii": "Bando",
    "lat": 36.0483,
    "lng": 139.8889,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Ibaraki",
    "capital": "",
    "population": 51903,
    "id": 1392946585
  },
  {
    "city": "Welland",
    "city_ascii": "Welland",
    "lat": 42.9833,
    "lng": -79.2333,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 52293,
    "id": 1124745616
  },
  {
    "city": "Stratford",
    "city_ascii": "Stratford",
    "lat": 41.207,
    "lng": -73.1305,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 52279,
    "id": 1840035621
  },
  {
    "city": "Jaguariúna",
    "city_ascii": "Jaguariuna",
    "lat": -22.68,
    "lng": -46.99,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 51907,
    "id": 1076822145
  },
  {
    "city": "Satte",
    "city_ascii": "Satte",
    "lat": 36.0781,
    "lng": 139.7258,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Saitama",
    "capital": "",
    "population": 50767,
    "id": 1392499681
  },
  {
    "city": "Tefé",
    "city_ascii": "Tefe",
    "lat": -3.3684,
    "lng": -64.7205,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Amazonas",
    "capital": "",
    "population": 51437,
    "id": 1076049089
  },
  {
    "city": "Novovolynsk",
    "city_ascii": "Novovolynsk",
    "lat": 50.7333,
    "lng": 24.1667,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Volyns’ka Oblast’",
    "capital": "",
    "population": 52188,
    "id": 1804052073
  },
  {
    "city": "Basankusu",
    "city_ascii": "Basankusu",
    "lat": 1.2222,
    "lng": 19.8028,
    "country": "Congo (Kinshasa)",
    "iso2": "CD",
    "iso3": "COD",
    "admin_name": "Équateur",
    "capital": "",
    "population": 52216,
    "id": 1180515335
  },
  {
    "city": "Arao",
    "city_ascii": "Arao",
    "lat": 32.9867,
    "lng": 130.4333,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Kumamoto",
    "capital": "",
    "population": 51325,
    "id": 1392439718
  },
  {
    "city": "Cruz del Eje",
    "city_ascii": "Cruz del Eje",
    "lat": -30.7333,
    "lng": -64.8,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Córdoba",
    "capital": "minor",
    "population": 52172,
    "id": 1032976495
  },
  {
    "city": "Belorechensk",
    "city_ascii": "Belorechensk",
    "lat": 44.7667,
    "lng": 39.8667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Krasnodarskiy Kray",
    "capital": "minor",
    "population": 52082,
    "id": 1643229263
  },
  {
    "city": "Abdul Hakim",
    "city_ascii": "Abdul Hakim",
    "lat": 30.5522,
    "lng": 72.1278,
    "country": "Pakistan",
    "iso2": "PK",
    "iso3": "PAK",
    "admin_name": "Punjab",
    "capital": "",
    "population": 51494,
    "id": 1586991157
  },
  {
    "city": "Porto Feliz",
    "city_ascii": "Porto Feliz",
    "lat": -23.215,
    "lng": -47.5239,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 51928,
    "id": 1076231459
  },
  {
    "city": "Lompoc",
    "city_ascii": "Lompoc",
    "lat": 34.6618,
    "lng": -120.4714,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 52020,
    "id": 1840020468
  },
  {
    "city": "Timashevsk",
    "city_ascii": "Timashevsk",
    "lat": 45.6167,
    "lng": 38.9333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Krasnodarskiy Kray",
    "capital": "minor",
    "population": 51925,
    "id": 1643049653
  },
  {
    "city": "Metpalli",
    "city_ascii": "Metpalli",
    "lat": 18.8297,
    "lng": 78.5878,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Telangana",
    "capital": "",
    "population": 50902,
    "id": 1356583037
  },
  {
    "city": "Howell",
    "city_ascii": "Howell",
    "lat": 40.1819,
    "lng": -74.1977,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 51958,
    "id": 1840081644
  },
  {
    "city": "Caldas da Rainha",
    "city_ascii": "Caldas da Rainha",
    "lat": 39.4069,
    "lng": -9.1363,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Leiria",
    "capital": "minor",
    "population": 51729,
    "id": 1620164622
  },
  {
    "city": "Gwadar",
    "city_ascii": "Gwadar",
    "lat": 25.1264,
    "lng": 62.3225,
    "country": "Pakistan",
    "iso2": "PK",
    "iso3": "PAK",
    "admin_name": "Balochistān",
    "capital": "minor",
    "population": 51901,
    "id": 1586857142
  },
  {
    "city": "Kentwood",
    "city_ascii": "Kentwood",
    "lat": 42.8852,
    "lng": -85.5925,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 51898,
    "id": 1840002930
  },
  {
    "city": "Berriozábal",
    "city_ascii": "Berriozabal",
    "lat": 16.7993,
    "lng": -93.2725,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Chiapas",
    "capital": "minor",
    "population": 51722,
    "id": 1484051098
  },
  {
    "city": "Covilhã",
    "city_ascii": "Covilha",
    "lat": 40.2833,
    "lng": -7.5,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Castelo Branco",
    "capital": "minor",
    "population": 51797,
    "id": 1620284248
  },
  {
    "city": "Bad Kreuznach",
    "city_ascii": "Bad Kreuznach",
    "lat": 49.8469,
    "lng": 7.8669,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Rhineland-Palatinate",
    "capital": "minor",
    "population": 50948,
    "id": 1276733459
  },
  {
    "city": "Shepparton",
    "city_ascii": "Shepparton",
    "lat": -36.3833,
    "lng": 145.4,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Victoria",
    "capital": "",
    "population": 51631,
    "id": 1036030548
  },
  {
    "city": "Villareal",
    "city_ascii": "Villareal",
    "lat": 39.9378,
    "lng": -0.1014,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Valencia",
    "capital": "",
    "population": 50893,
    "id": 1724206895
  },
  {
    "city": "Sakaidechō",
    "city_ascii": "Sakaidecho",
    "lat": 34.3164,
    "lng": 133.8606,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Kagawa",
    "capital": "",
    "population": 51196,
    "id": 1392517231
  },
  {
    "city": "Solnechnogorsk",
    "city_ascii": "Solnechnogorsk",
    "lat": 56.1844,
    "lng": 36.995,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Moskovskaya Oblast’",
    "capital": "",
    "population": 51612,
    "id": 1643958876
  },
  {
    "city": "Caleta Olivia",
    "city_ascii": "Caleta Olivia",
    "lat": -46.4333,
    "lng": -67.5333,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Santa Cruz",
    "capital": "",
    "population": 51733,
    "id": 1032563209
  },
  {
    "city": "Sierra Vista",
    "city_ascii": "Sierra Vista",
    "lat": 31.563,
    "lng": -110.3153,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 51731,
    "id": 1840022103
  },
  {
    "city": "Levittown",
    "city_ascii": "Levittown",
    "lat": 40.1537,
    "lng": -74.853,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 51726,
    "id": 1840005408
  },
  {
    "city": "Grasse",
    "city_ascii": "Grasse",
    "lat": 43.6667,
    "lng": 6.9167,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Provence-Alpes-Côte d’Azur",
    "capital": "minor",
    "population": 50396,
    "id": 1250763117
  },
  {
    "city": "Tulsīpur",
    "city_ascii": "Tulsipur",
    "lat": 28.1278,
    "lng": 82.2956,
    "country": "Nepal",
    "iso2": "NP",
    "iso3": "NPL",
    "admin_name": "Rāptī",
    "capital": "",
    "population": 51537,
    "id": 1524921574
  },
  {
    "city": "Madison",
    "city_ascii": "Madison",
    "lat": 34.7114,
    "lng": -86.7626,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 51593,
    "id": 1840015524
  },
  {
    "city": "Yūki",
    "city_ascii": "Yuki",
    "lat": 36.3053,
    "lng": 139.8775,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Ibaraki",
    "capital": "",
    "population": 50817,
    "id": 1392722230
  },
  {
    "city": "Smyrna",
    "city_ascii": "Smyrna",
    "lat": 35.9722,
    "lng": -86.5253,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 51586,
    "id": 1840017758
  },
  {
    "city": "Speyer",
    "city_ascii": "Speyer",
    "lat": 49.3194,
    "lng": 8.4311,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Rhineland-Palatinate",
    "capital": "minor",
    "population": 50378,
    "id": 1276684984
  },
  {
    "city": "North Bay",
    "city_ascii": "North Bay",
    "lat": 46.3,
    "lng": -79.45,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 51553,
    "id": 1124058496
  },
  {
    "city": "Knysna",
    "city_ascii": "Knysna",
    "lat": -34.0356,
    "lng": 23.0489,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "Western Cape",
    "capital": "",
    "population": 51078,
    "id": 1710311704
  },
  {
    "city": "Glendora",
    "city_ascii": "Glendora",
    "lat": 34.1449,
    "lng": -117.8468,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 51544,
    "id": 1840020484
  },
  {
    "city": "Butare",
    "city_ascii": "Butare",
    "lat": -2.6,
    "lng": 29.75,
    "country": "Rwanda",
    "iso2": "RW",
    "iso3": "RWA",
    "admin_name": "Southern Province",
    "capital": "",
    "population": 50220,
    "id": 1646348441
  },
  {
    "city": "El Talar de Pacheco",
    "city_ascii": "El Talar de Pacheco",
    "lat": -34.4719,
    "lng": -58.655,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires",
    "capital": "",
    "population": 50426,
    "id": 1032391611
  },
  {
    "city": "Tsukubamirai",
    "city_ascii": "Tsukubamirai",
    "lat": 35.9631,
    "lng": 140.0372,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Ibaraki",
    "capital": "",
    "population": 50870,
    "id": 1392787574
  },
  {
    "city": "Burien",
    "city_ascii": "Burien",
    "lat": 47.4762,
    "lng": -122.3394,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 51500,
    "id": 1840018420
  },
  {
    "city": "Uzlovaya",
    "city_ascii": "Uzlovaya",
    "lat": 53.9791,
    "lng": 38.1601,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Tul’skaya Oblast’",
    "capital": "",
    "population": 51358,
    "id": 1643849363
  },
  {
    "city": "Böblingen",
    "city_ascii": "Boblingen",
    "lat": 48.6833,
    "lng": 9,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "minor",
    "population": 50155,
    "id": 1276001231
  },
  {
    "city": "Montijo",
    "city_ascii": "Montijo",
    "lat": 38.7049,
    "lng": -8.9757,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Setúbal",
    "capital": "minor",
    "population": 51222,
    "id": 1620226078
  },
  {
    "city": "Herriman",
    "city_ascii": "Herriman",
    "lat": 40.4899,
    "lng": -112.0171,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Utah",
    "capital": "",
    "population": 51348,
    "id": 1840020155
  },
  {
    "city": "Willich",
    "city_ascii": "Willich",
    "lat": 51.2631,
    "lng": 6.5492,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 50592,
    "id": 1276849706
  },
  {
    "city": "Severomorsk",
    "city_ascii": "Severomorsk",
    "lat": 69.0692,
    "lng": 33.4167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Murmanskaya Oblast’",
    "capital": "",
    "population": 51209,
    "id": 1643295687
  },
  {
    "city": "Tadmur",
    "city_ascii": "Tadmur",
    "lat": 34.56,
    "lng": 38.2672,
    "country": "Syria",
    "iso2": "SY",
    "iso3": "SYR",
    "admin_name": "Ḩimş",
    "capital": "minor",
    "population": 51323,
    "id": 1760484707
  },
  {
    "city": "Farmington",
    "city_ascii": "Farmington",
    "lat": 36.7555,
    "lng": -108.1823,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Mexico",
    "capital": "",
    "population": 51309,
    "id": 1840020349
  },
  {
    "city": "New Bern",
    "city_ascii": "New Bern",
    "lat": 35.0955,
    "lng": -77.0723,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 51308,
    "id": 1840014591
  },
  {
    "city": "Cheremkhovo",
    "city_ascii": "Cheremkhovo",
    "lat": 53.15,
    "lng": 103.0667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Irkutskaya Oblast’",
    "capital": "",
    "population": 50819,
    "id": 1643007385
  },
  {
    "city": "Gummersbach",
    "city_ascii": "Gummersbach",
    "lat": 51.0333,
    "lng": 7.5667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "minor",
    "population": 50688,
    "id": 1276576027
  },
  {
    "city": "Nichinan",
    "city_ascii": "Nichinan",
    "lat": 31.6019,
    "lng": 131.3789,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Miyazaki",
    "capital": "",
    "population": 51106,
    "id": 1392942618
  },
  {
    "city": "Hinesville",
    "city_ascii": "Hinesville",
    "lat": 31.8247,
    "lng": -81.6135,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 51206,
    "id": 1840014931
  },
  {
    "city": "Repalle",
    "city_ascii": "Repalle",
    "lat": 16.02,
    "lng": 80.85,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Andhra Pradesh",
    "capital": "",
    "population": 50866,
    "id": 1356083696
  },
  {
    "city": "Snezhinsk",
    "city_ascii": "Snezhinsk",
    "lat": 56.0833,
    "lng": 60.7333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Chelyabinskaya Oblast’",
    "capital": "",
    "population": 51048,
    "id": 1643306230
  },
  {
    "city": "Ravensburg",
    "city_ascii": "Ravensburg",
    "lat": 47.7831,
    "lng": 9.6114,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "minor",
    "population": 50623,
    "id": 1276088263
  },
  {
    "city": "Hassan Abdal",
    "city_ascii": "Hassan Abdal",
    "lat": 33.8195,
    "lng": 72.689,
    "country": "Pakistan",
    "iso2": "PK",
    "iso3": "PAK",
    "admin_name": "Punjab",
    "capital": "",
    "population": 50044,
    "id": 1586993764
  },
  {
    "city": "Konibodom",
    "city_ascii": "Konibodom",
    "lat": 40.2833,
    "lng": 70.4167,
    "country": "Tajikistan",
    "iso2": "TJ",
    "iso3": "TJK",
    "admin_name": "Sughd",
    "capital": "minor",
    "population": 50400,
    "id": 1762140294
  },
  {
    "city": "Miyoshi",
    "city_ascii": "Miyoshi",
    "lat": 34.8058,
    "lng": 132.8517,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hiroshima",
    "capital": "",
    "population": 51049,
    "id": 1392714419
  },
  {
    "city": "Beaumont",
    "city_ascii": "Beaumont",
    "lat": 33.9076,
    "lng": -116.9766,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 51063,
    "id": 1840019298
  },
  {
    "city": "Goslar",
    "city_ascii": "Goslar",
    "lat": 51.906,
    "lng": 10.4292,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "minor",
    "population": 50753,
    "id": 1276608610
  },
  {
    "city": "Yasu",
    "city_ascii": "Yasu",
    "lat": 35.0675,
    "lng": 136.0258,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Shiga",
    "capital": "",
    "population": 50432,
    "id": 1392205770
  },
  {
    "city": "Horsham",
    "city_ascii": "Horsham",
    "lat": 51.062,
    "lng": -0.325,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "West Sussex",
    "capital": "",
    "population": 50934,
    "id": 1826018789
  },
  {
    "city": "Collierville",
    "city_ascii": "Collierville",
    "lat": 35.0471,
    "lng": -89.6988,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 51040,
    "id": 1840016403
  },
  {
    "city": "Campos do Jordão",
    "city_ascii": "Campos do Jordao",
    "lat": -22.7394,
    "lng": -45.5914,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 50852,
    "id": 1076353308
  },
  {
    "city": "Nazarovo",
    "city_ascii": "Nazarovo",
    "lat": 56.0064,
    "lng": 90.3914,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Krasnoyarskiy Kray",
    "capital": "",
    "population": 50397,
    "id": 1643743440
  },
  {
    "city": "West Lafayette",
    "city_ascii": "West Lafayette",
    "lat": 40.4432,
    "lng": -86.9239,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 50996,
    "id": 1840010430
  },
  {
    "city": "Laval",
    "city_ascii": "Laval",
    "lat": 48.0733,
    "lng": -0.7689,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Pays de la Loire",
    "capital": "minor",
    "population": 49492,
    "id": 1250720617
  },
  {
    "city": "Noshiromachi",
    "city_ascii": "Noshiromachi",
    "lat": 40.2122,
    "lng": 140.0267,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Akita",
    "capital": "",
    "population": 50842,
    "id": 1392645142
  },
  {
    "city": "Florissant",
    "city_ascii": "Florissant",
    "lat": 38.7996,
    "lng": -90.3269,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 50952,
    "id": 1840008586
  },
  {
    "city": "Hoffman Estates",
    "city_ascii": "Hoffman Estates",
    "lat": 42.0639,
    "lng": -88.1468,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 50932,
    "id": 1840011263
  },
  {
    "city": "Mozhga",
    "city_ascii": "Mozhga",
    "lat": 56.45,
    "lng": 52.2167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Udmurtiya",
    "capital": "",
    "population": 49328,
    "id": 1643022471
  },
  {
    "city": "Kirishi",
    "city_ascii": "Kirishi",
    "lat": 59.45,
    "lng": 32.0167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Leningradskaya Oblast’",
    "capital": "",
    "population": 50885,
    "id": 1643837695
  },
  {
    "city": "Ye",
    "city_ascii": "Ye",
    "lat": 15.2533,
    "lng": 97.8679,
    "country": "Burma",
    "iso2": "MM",
    "iso3": "MMR",
    "admin_name": "Mon State",
    "capital": "",
    "population": 50798,
    "id": 1104513802
  },
  {
    "city": "Queen Creek",
    "city_ascii": "Queen Creek",
    "lat": 33.2386,
    "lng": -111.6343,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 50890,
    "id": 1840021944
  },
  {
    "city": "Kizlyar",
    "city_ascii": "Kizlyar",
    "lat": 43.85,
    "lng": 46.7167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Dagestan",
    "capital": "minor",
    "population": 49247,
    "id": 1643912106
  },
  {
    "city": "Fafe",
    "city_ascii": "Fafe",
    "lat": 41.45,
    "lng": -8.1667,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Braga",
    "capital": "minor",
    "population": 50633,
    "id": 1620607091
  },
  {
    "city": "Banī Walīd",
    "city_ascii": "Bani Walid",
    "lat": 31.7455,
    "lng": 13.9835,
    "country": "Libya",
    "iso2": "LY",
    "iso3": "LBY",
    "admin_name": "Mişrātah",
    "capital": "",
    "population": 50824,
    "id": 1434398590
  },
  {
    "city": "Kannapolis",
    "city_ascii": "Kannapolis",
    "lat": 35.4764,
    "lng": -80.6403,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 50841,
    "id": 1840014560
  },
  {
    "city": "Tōkamachi",
    "city_ascii": "Tokamachi",
    "lat": 37.1275,
    "lng": 138.7557,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Niigata",
    "capital": "",
    "population": 50754,
    "id": 1392044124
  },
  {
    "city": "Puławy",
    "city_ascii": "Pulawy",
    "lat": 51.4166,
    "lng": 21.9694,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Lubelskie",
    "capital": "minor",
    "population": 49839,
    "id": 1616098904
  },
  {
    "city": "To‘rtko‘l Shahri",
    "city_ascii": "To`rtko`l Shahri",
    "lat": 41.55,
    "lng": 61,
    "country": "Uzbekistan",
    "iso2": "UZ",
    "iso3": "UZB",
    "admin_name": "Qoraqalpog‘iston",
    "capital": "minor",
    "population": 50800,
    "id": 1860966455
  },
  {
    "city": "Beloeil",
    "city_ascii": "Beloeil",
    "lat": 45.5667,
    "lng": -73.2,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 50796,
    "id": 1124469084
  },
  {
    "city": "Maricopa",
    "city_ascii": "Maricopa",
    "lat": 33.0404,
    "lng": -112.0006,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 50752,
    "id": 1840020629
  },
  {
    "city": "Mahalapye",
    "city_ascii": "Mahalapye",
    "lat": -23.1041,
    "lng": 26.8142,
    "country": "Botswana",
    "iso2": "BW",
    "iso3": "BWA",
    "admin_name": "Central",
    "capital": "",
    "population": 50744,
    "id": 1072304817
  },
  {
    "city": "Mweka",
    "city_ascii": "Mweka",
    "lat": -4.8519,
    "lng": 21.5594,
    "country": "Congo (Kinshasa)",
    "iso2": "CD",
    "iso3": "COD",
    "admin_name": "Kasaï",
    "capital": "",
    "population": 50675,
    "id": 1180140663
  },
  {
    "city": "Belleville",
    "city_ascii": "Belleville",
    "lat": 44.1667,
    "lng": -77.3833,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 50716,
    "id": 1124786959
  },
  {
    "city": "Beruniy",
    "city_ascii": "Beruniy",
    "lat": 41.6833,
    "lng": 60.75,
    "country": "Uzbekistan",
    "iso2": "UZ",
    "iso3": "UZB",
    "admin_name": "Qoraqalpog‘iston",
    "capital": "",
    "population": 50700,
    "id": 1860882371
  },
  {
    "city": "Methuen Town",
    "city_ascii": "Methuen Town",
    "lat": 42.734,
    "lng": -71.1889,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 50706,
    "id": 1840031195
  },
  {
    "city": "Kulu",
    "city_ascii": "Kulu",
    "lat": 39.0901,
    "lng": 33.0807,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Konya",
    "capital": "minor",
    "population": 50667,
    "id": 1792739957
  },
  {
    "city": "El Salvador",
    "city_ascii": "El Salvador",
    "lat": 8.5667,
    "lng": 124.5167,
    "country": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "admin_name": "Misamis Oriental",
    "capital": "",
    "population": 50204,
    "id": 1608813941
  },
  {
    "city": "Shakhtarsk",
    "city_ascii": "Shakhtarsk",
    "lat": 48.0333,
    "lng": 38.4833,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Donets’ka Oblast’",
    "capital": "minor",
    "population": 49482,
    "id": 1804275296
  },
  {
    "city": "Rastatt",
    "city_ascii": "Rastatt",
    "lat": 48.8572,
    "lng": 8.2031,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "minor",
    "population": 49783,
    "id": 1276684629
  },
  {
    "city": "Starogard Gdański",
    "city_ascii": "Starogard Gdanski",
    "lat": 53.9667,
    "lng": 18.5333,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Pomorskie",
    "capital": "minor",
    "population": 48690,
    "id": 1616735469
  },
  {
    "city": "Sahuarita",
    "city_ascii": "Sahuarita",
    "lat": 31.9323,
    "lng": -110.9654,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 50616,
    "id": 1840022102
  },
  {
    "city": "Rowland Heights",
    "city_ascii": "Rowland Heights",
    "lat": 33.9716,
    "lng": -117.8911,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 50602,
    "id": 1840019211
  },
  {
    "city": "Lörrach",
    "city_ascii": "Lorrach",
    "lat": 47.6156,
    "lng": 7.6614,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "minor",
    "population": 49347,
    "id": 1276104619
  },
  {
    "city": "Turbo",
    "city_ascii": "Turbo",
    "lat": 8.1004,
    "lng": -76.74,
    "country": "Colombia",
    "iso2": "CO",
    "iso3": "COL",
    "admin_name": "Antioquia",
    "capital": "minor",
    "population": 50508,
    "id": 1170426476
  },
  {
    "city": "Arjona",
    "city_ascii": "Arjona",
    "lat": 10.26,
    "lng": -75.35,
    "country": "Colombia",
    "iso2": "CO",
    "iso3": "COL",
    "admin_name": "Bolívar",
    "capital": "minor",
    "population": 50405,
    "id": 1170602407
  },
  {
    "city": "Traverse City",
    "city_ascii": "Traverse City",
    "lat": 44.7547,
    "lng": -85.6035,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 50522,
    "id": 1840003946
  },
  {
    "city": "Mirabel",
    "city_ascii": "Mirabel",
    "lat": 45.65,
    "lng": -74.0833,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 50513,
    "id": 1124182375
  },
  {
    "city": "East Hartford",
    "city_ascii": "East Hartford",
    "lat": 41.7634,
    "lng": -72.6152,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 50453,
    "id": 1840034618
  },
  {
    "city": "Irondequoit",
    "city_ascii": "Irondequoit",
    "lat": 43.2096,
    "lng": -77.5705,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 50427,
    "id": 1840058201
  },
  {
    "city": "Catalina Foothills",
    "city_ascii": "Catalina Foothills",
    "lat": 32.3041,
    "lng": -110.8835,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 50426,
    "id": 1840018110
  },
  {
    "city": "Pozi",
    "city_ascii": "Pozi",
    "lat": 23.4611,
    "lng": 120.242,
    "country": "Taiwan",
    "iso2": "TW",
    "iso3": "TWN",
    "admin_name": "Chiayi",
    "capital": "",
    "population": 50000,
    "id": 1158227287
  },
  {
    "city": "Amora",
    "city_ascii": "Amora",
    "lat": 38.6265,
    "lng": -9.1189,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Setúbal",
    "capital": "",
    "population": 48629,
    "id": 1620947611
  },
  {
    "city": "Cookeville",
    "city_ascii": "Cookeville",
    "lat": 36.1484,
    "lng": -85.5114,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 50408,
    "id": 1840014470
  },
  {
    "city": "Buguruslan",
    "city_ascii": "Buguruslan",
    "lat": 53.6167,
    "lng": 52.4167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Orenburgskaya Oblast’",
    "capital": "",
    "population": 49737,
    "id": 1643675272
  },
  {
    "city": "Siguiri",
    "city_ascii": "Siguiri",
    "lat": 11.4171,
    "lng": -9.1666,
    "country": "Guinea",
    "iso2": "GN",
    "iso3": "GIN",
    "admin_name": "Kankan",
    "capital": "minor",
    "population": 50159,
    "id": 1324494896
  },
  {
    "city": "Leith",
    "city_ascii": "Leith",
    "lat": 55.98,
    "lng": -3.17,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Edinburgh, City of",
    "capital": "",
    "population": 50030,
    "id": 1826964931
  },
  {
    "city": "Peine",
    "city_ascii": "Peine",
    "lat": 52.3203,
    "lng": 10.2336,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "minor",
    "population": 49952,
    "id": 1276331439
  },
  {
    "city": "Mishawaka",
    "city_ascii": "Mishawaka",
    "lat": 41.6736,
    "lng": -86.1669,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 50363,
    "id": 1840014695
  },
  {
    "city": "Emden",
    "city_ascii": "Emden",
    "lat": 53.3669,
    "lng": 7.2061,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "minor",
    "population": 49913,
    "id": 1276309938
  },
  {
    "city": "Bullhead City",
    "city_ascii": "Bullhead City",
    "lat": 35.1205,
    "lng": -114.546,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 50309,
    "id": 1840019748
  },
  {
    "city": "Chomutov",
    "city_ascii": "Chomutov",
    "lat": 50.4628,
    "lng": 13.4111,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Ústecký Kraj",
    "capital": "",
    "population": 48635,
    "id": 1203523082
  },
  {
    "city": "Ashburn",
    "city_ascii": "Ashburn",
    "lat": 39.03,
    "lng": -77.4711,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 50290,
    "id": 1840024495
  },
  {
    "city": "Tājūrā’",
    "city_ascii": "Tajura'",
    "lat": 32.8819,
    "lng": 13.34,
    "country": "Libya",
    "iso2": "LY",
    "iso3": "LBY",
    "admin_name": "Ţarābulus",
    "capital": "",
    "population": 48836,
    "id": 1434120461
  },
  {
    "city": "Forney",
    "city_ascii": "Forney",
    "lat": 32.744,
    "lng": -96.4529,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 50251,
    "id": 1840020734
  },
  {
    "city": "São Joaquim da Barra",
    "city_ascii": "Sao Joaquim da Barra",
    "lat": -20.5808,
    "lng": -47.855,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 50110,
    "id": 1076321926
  },
  {
    "city": "Hikari",
    "city_ascii": "Hikari",
    "lat": 33.9617,
    "lng": 131.9422,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Yamaguchi",
    "capital": "",
    "population": 49686,
    "id": 1392832835
  },
  {
    "city": "Erftstadt",
    "city_ascii": "Erftstadt",
    "lat": 50.8167,
    "lng": 6.7667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 49801,
    "id": 1276479094
  },
  {
    "city": "Lindong",
    "city_ascii": "Lindong",
    "lat": 43.9837,
    "lng": 119.1834,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Inner Mongolia",
    "capital": "minor",
    "population": 50000,
    "id": 1156448425
  },
  {
    "city": "Borovichi",
    "city_ascii": "Borovichi",
    "lat": 58.4,
    "lng": 33.9167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Novgorodskaya Oblast’",
    "capital": "",
    "population": 50144,
    "id": 1643059892
  },
  {
    "city": "Itararé",
    "city_ascii": "Itarare",
    "lat": -24.1125,
    "lng": -49.3317,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 50105,
    "id": 1076167358
  },
  {
    "city": "Bhainsa",
    "city_ascii": "Bhainsa",
    "lat": 19.1,
    "lng": 77.9667,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Telangana",
    "capital": "",
    "population": 49764,
    "id": 1356798697
  },
  {
    "city": "Slonim",
    "city_ascii": "Slonim",
    "lat": 53.0833,
    "lng": 25.3167,
    "country": "Belarus",
    "iso2": "BY",
    "iso3": "BLR",
    "admin_name": "Hrodzyenskaya Voblasts’",
    "capital": "minor",
    "population": 50100,
    "id": 1112619287
  },
  {
    "city": "Roslavl",
    "city_ascii": "Roslavl",
    "lat": 53.9492,
    "lng": 32.8569,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Smolenskaya Oblast’",
    "capital": "",
    "population": 50112,
    "id": 1643356591
  },
  {
    "city": "Gogrial",
    "city_ascii": "Gogrial",
    "lat": 8.5337,
    "lng": 28.1167,
    "country": "South Sudan",
    "iso2": "SS",
    "iso3": "SSD",
    "admin_name": "Warrap",
    "capital": "",
    "population": 50065,
    "id": 1728342889
  },
  {
    "city": "Tomisato",
    "city_ascii": "Tomisato",
    "lat": 35.7267,
    "lng": 140.3431,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Chiba",
    "capital": "",
    "population": 49868,
    "id": 1392179230
  },
  {
    "city": "Sardasht",
    "city_ascii": "Sardasht",
    "lat": 36.1553,
    "lng": 45.4789,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Āz̄arbāyjān-e Gharbī",
    "capital": "minor",
    "population": 50000,
    "id": 1364996546
  },
  {
    "city": "Albi",
    "city_ascii": "Albi",
    "lat": 43.9289,
    "lng": 2.1464,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Occitanie",
    "capital": "minor",
    "population": 48970,
    "id": 1250988425
  },
  {
    "city": "Suzaka",
    "city_ascii": "Suzaka",
    "lat": 36.6511,
    "lng": 138.3069,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Nagano",
    "capital": "",
    "population": 49734,
    "id": 1392723213
  },
  {
    "city": "Shawinigan",
    "city_ascii": "Shawinigan",
    "lat": 46.5667,
    "lng": -72.75,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 50060,
    "id": 1124441118
  },
  {
    "city": "Tepeapulco",
    "city_ascii": "Tepeapulco",
    "lat": 19.7856,
    "lng": -98.5517,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Hidalgo",
    "capital": "minor",
    "population": 49850,
    "id": 1484457950
  },
  {
    "city": "Severn",
    "city_ascii": "Severn",
    "lat": 39.1355,
    "lng": -76.6958,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 50052,
    "id": 1840005925
  },
  {
    "city": "Orangetown",
    "city_ascii": "Orangetown",
    "lat": 41.0526,
    "lng": -73.9475,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 50046,
    "id": 1840087755
  },
  {
    "city": "Kahemba",
    "city_ascii": "Kahemba",
    "lat": -7.2829,
    "lng": 19,
    "country": "Congo (Kinshasa)",
    "iso2": "CD",
    "iso3": "COD",
    "admin_name": "Kwango",
    "capital": "",
    "population": 50000,
    "id": 1180689417
  },
  {
    "city": "Porvoo",
    "city_ascii": "Porvoo",
    "lat": 60.3931,
    "lng": 25.6639,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Uusimaa",
    "capital": "minor",
    "population": 49928,
    "id": 1246277728
  },
  {
    "city": "Nenjiang",
    "city_ascii": "Nenjiang",
    "lat": 49.1745,
    "lng": 125.216,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Heilongjiang",
    "capital": "minor",
    "population": 50000,
    "id": 1156694308
  },
  {
    "city": "Heidenheim",
    "city_ascii": "Heidenheim",
    "lat": 48.6761,
    "lng": 10.1544,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "minor",
    "population": 49526,
    "id": 1276168895
  },
  {
    "city": "Azusa",
    "city_ascii": "Azusa",
    "lat": 34.1386,
    "lng": -117.9124,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 49974,
    "id": 1840019226
  },
  {
    "city": "Upper Buchanan",
    "city_ascii": "Upper Buchanan",
    "lat": 5.9161,
    "lng": -10.0525,
    "country": "Liberia",
    "iso2": "LR",
    "iso3": "LBR",
    "admin_name": "Grand Bassa",
    "capital": "",
    "population": 48315,
    "id": 1430177860
  },
  {
    "city": "Yamaga",
    "city_ascii": "Yamaga",
    "lat": 33.0167,
    "lng": 130.6914,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Kumamoto",
    "capital": "",
    "population": 49783,
    "id": 1392023870
  },
  {
    "city": "Xankəndi",
    "city_ascii": "Xankandi",
    "lat": 39.8153,
    "lng": 46.7519,
    "country": "Azerbaijan",
    "iso2": "AZ",
    "iso3": "AZE",
    "admin_name": "Xankəndi",
    "capital": "",
    "population": 49848,
    "id": 1031053512
  },
  {
    "city": "Rāmhormoz",
    "city_ascii": "Ramhormoz",
    "lat": 31.2783,
    "lng": 49.6064,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Khūzestān",
    "capital": "minor",
    "population": 49822,
    "id": 1364637975
  },
  {
    "city": "Bloomsburg",
    "city_ascii": "Bloomsburg",
    "lat": 41.0027,
    "lng": -76.4561,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 49840,
    "id": 1840000811
  },
  {
    "city": "Bergkamen",
    "city_ascii": "Bergkamen",
    "lat": 51.6167,
    "lng": 7.6333,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 48725,
    "id": 1276603807
  },
  {
    "city": "Nomimachi",
    "city_ascii": "Nomimachi",
    "lat": 36.4375,
    "lng": 136.4961,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Ishikawa",
    "capital": "",
    "population": 49200,
    "id": 1392180005
  },
  {
    "city": "Morehead City",
    "city_ascii": "Morehead City",
    "lat": 34.7308,
    "lng": -76.7387,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 49760,
    "id": 1840016482
  },
  {
    "city": "Sesimbra",
    "city_ascii": "Sesimbra",
    "lat": 38.4437,
    "lng": -9.0996,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Setúbal",
    "capital": "minor",
    "population": 49500,
    "id": 1620008532
  },
  {
    "city": "Leonberg",
    "city_ascii": "Leonberg",
    "lat": 48.8014,
    "lng": 9.0131,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 48733,
    "id": 1276002040
  },
  {
    "city": "Ridder",
    "city_ascii": "Ridder",
    "lat": 50.35,
    "lng": 83.5167,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Shyghys Qazaqstan",
    "capital": "",
    "population": 49695,
    "id": 1398556139
  },
  {
    "city": "Coral Gables",
    "city_ascii": "Coral Gables",
    "lat": 25.7037,
    "lng": -80.2715,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 49700,
    "id": 1840015147
  },
  {
    "city": "Ifakara",
    "city_ascii": "Ifakara",
    "lat": -8.1296,
    "lng": 36.68,
    "country": "Tanzania",
    "iso2": "TZ",
    "iso3": "TZA",
    "admin_name": "Morogoro",
    "capital": "",
    "population": 49528,
    "id": 1834456222
  },
  {
    "city": "Frankenthal",
    "city_ascii": "Frankenthal",
    "lat": 49.5333,
    "lng": 8.35,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Rhineland-Palatinate",
    "capital": "minor",
    "population": 48561,
    "id": 1276888667
  },
  {
    "city": "Zyryanovsk",
    "city_ascii": "Zyryanovsk",
    "lat": 49.7453,
    "lng": 84.2548,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Shyghys Qazaqstan",
    "capital": "",
    "population": 49658,
    "id": 1398361284
  },
  {
    "city": "Évreux",
    "city_ascii": "Evreux",
    "lat": 49.02,
    "lng": 1.15,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Normandie",
    "capital": "minor",
    "population": 47733,
    "id": 1250149673
  },
  {
    "city": "Sanmu",
    "city_ascii": "Sanmu",
    "lat": 35.6028,
    "lng": 140.4136,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Chiba",
    "capital": "",
    "population": 49300,
    "id": 1392513684
  },
  {
    "city": "Berber",
    "city_ascii": "Berber",
    "lat": 18.017,
    "lng": 33.9833,
    "country": "Sudan",
    "iso2": "SD",
    "iso3": "SDN",
    "admin_name": "River Nile",
    "capital": "",
    "population": 49556,
    "id": 1729960469
  },
  {
    "city": "Omitama",
    "city_ascii": "Omitama",
    "lat": 36.2394,
    "lng": 140.3525,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Ibaraki",
    "capital": "",
    "population": 49200,
    "id": 1392354218
  },
  {
    "city": "Oroville",
    "city_ascii": "Oroville",
    "lat": 39.4999,
    "lng": -121.5634,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 49513,
    "id": 1840020182
  },
  {
    "city": "Özgön",
    "city_ascii": "Ozgon",
    "lat": 40.7667,
    "lng": 73.3,
    "country": "Kyrgyzstan",
    "iso2": "KG",
    "iso3": "KGZ",
    "admin_name": "Osh",
    "capital": "",
    "population": 49410,
    "id": 1417087485
  },
  {
    "city": "Wilson",
    "city_ascii": "Wilson",
    "lat": 35.7312,
    "lng": -77.9284,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 49483,
    "id": 1840015383
  },
  {
    "city": "Okhtyrka",
    "city_ascii": "Okhtyrka",
    "lat": 50.3074,
    "lng": 34.9016,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Sums’ka Oblast’",
    "capital": "minor",
    "population": 47971,
    "id": 1804074587
  },
  {
    "city": "Lawrence",
    "city_ascii": "Lawrence",
    "lat": 39.8674,
    "lng": -85.9905,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 49462,
    "id": 1840008434
  },
  {
    "city": "Bad Oeynhausen",
    "city_ascii": "Bad Oeynhausen",
    "lat": 52.2,
    "lng": 8.8,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 48702,
    "id": 1276503412
  },
  {
    "city": "Villaguay",
    "city_ascii": "Villaguay",
    "lat": -31.85,
    "lng": -59.0167,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Entre Ríos",
    "capital": "minor",
    "population": 49445,
    "id": 1032112502
  },
  {
    "city": "Muhanga",
    "city_ascii": "Muhanga",
    "lat": -2.0783,
    "lng": 29.7581,
    "country": "Rwanda",
    "iso2": "RW",
    "iso3": "RWA",
    "admin_name": "Southern Province",
    "capital": "",
    "population": 49038,
    "id": 1646187395
  },
  {
    "city": "Portage",
    "city_ascii": "Portage",
    "lat": 42.2,
    "lng": -85.5906,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 49445,
    "id": 1840003183
  },
  {
    "city": "Christchurch",
    "city_ascii": "Christchurch",
    "lat": 50.73,
    "lng": -1.78,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Dorset",
    "capital": "",
    "population": 48368,
    "id": 1826669534
  },
  {
    "city": "Albury",
    "city_ascii": "Albury",
    "lat": -36.0806,
    "lng": 146.9158,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 47974,
    "id": 1036076542
  },
  {
    "city": "Minot",
    "city_ascii": "Minot",
    "lat": 48.2375,
    "lng": -101.2781,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Dakota",
    "capital": "",
    "population": 49425,
    "id": 1840000053
  },
  {
    "city": "Cwmbran",
    "city_ascii": "Cwmbran",
    "lat": 51.653,
    "lng": -3.021,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Torfaen",
    "capital": "",
    "population": 48535,
    "id": 1826475602
  },
  {
    "city": "Yong’an",
    "city_ascii": "Yong'an",
    "lat": 31.0504,
    "lng": 109.5167,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Chongqing",
    "capital": "minor",
    "population": 49168,
    "id": 1156185323
  },
  {
    "city": "Changyŏn",
    "city_ascii": "Changyon",
    "lat": 38.2517,
    "lng": 125.1021,
    "country": "Korea, North",
    "iso2": "KP",
    "iso3": "PRK",
    "admin_name": "Hwangnam",
    "capital": "",
    "population": 48984,
    "id": 1408492703
  },
  {
    "city": "Dunwoody",
    "city_ascii": "Dunwoody",
    "lat": 33.9418,
    "lng": -84.3122,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 49356,
    "id": 1840022929
  },
  {
    "city": "Shimotsuchō-kominami",
    "city_ascii": "Shimotsucho-kominami",
    "lat": 34.1556,
    "lng": 135.2092,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Wakayama",
    "capital": "",
    "population": 48846,
    "id": 1392564881
  },
  {
    "city": "Poway",
    "city_ascii": "Poway",
    "lat": 32.9871,
    "lng": -117.0201,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 49323,
    "id": 1840020624
  },
  {
    "city": "Libertador General San Martín",
    "city_ascii": "Libertador General San Martin",
    "lat": -23.8,
    "lng": -64.7833,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Jujuy",
    "capital": "minor",
    "population": 49267,
    "id": 1032649273
  },
  {
    "city": "Santa Rosa",
    "city_ascii": "Santa Rosa",
    "lat": -3.4522,
    "lng": -79.9617,
    "country": "Ecuador",
    "iso2": "EC",
    "iso3": "ECU",
    "admin_name": "El Oro",
    "capital": "",
    "population": 48929,
    "id": 1218626394
  },
  {
    "city": "Prievidza",
    "city_ascii": "Prievidza",
    "lat": 48.7714,
    "lng": 18.6242,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Nitriansky",
    "capital": "minor",
    "population": 48134,
    "id": 1703497240
  },
  {
    "city": "Timimoun",
    "city_ascii": "Timimoun",
    "lat": 29.2365,
    "lng": 0.27,
    "country": "Algeria",
    "iso2": "DZ",
    "iso3": "DZA",
    "admin_name": "Adrar",
    "capital": "",
    "population": 49237,
    "id": 1012351010
  },
  {
    "city": "I-n-Salah",
    "city_ascii": "I-n-Salah",
    "lat": 27.2166,
    "lng": 2.4666,
    "country": "Algeria",
    "iso2": "DZ",
    "iso3": "DZA",
    "admin_name": "Tamanrasset",
    "capital": "",
    "population": 49223,
    "id": 1012201146
  },
  {
    "city": "San Jacinto",
    "city_ascii": "San Jacinto",
    "lat": 33.797,
    "lng": -116.9916,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 49215,
    "id": 1840021936
  },
  {
    "city": "Lesnoy",
    "city_ascii": "Lesnoy",
    "lat": 58.6333,
    "lng": 59.7833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sverdlovskaya Oblast’",
    "capital": "",
    "population": 49056,
    "id": 1643751945
  },
  {
    "city": "Freising",
    "city_ascii": "Freising",
    "lat": 48.4028,
    "lng": 11.7489,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "minor",
    "population": 48634,
    "id": 1276519005
  },
  {
    "city": "Haverford",
    "city_ascii": "Haverford",
    "lat": 39.9868,
    "lng": -75.3164,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 49162,
    "id": 1840152844
  },
  {
    "city": "Troy",
    "city_ascii": "Troy",
    "lat": 42.7354,
    "lng": -73.6751,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 49154,
    "id": 1840003087
  },
  {
    "city": "Newark",
    "city_ascii": "Newark",
    "lat": 37.5204,
    "lng": -122.0312,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 49149,
    "id": 1840020295
  },
  {
    "city": "Suwa",
    "city_ascii": "Suwa",
    "lat": 36.0392,
    "lng": 138.1142,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Nagano",
    "capital": "",
    "population": 48695,
    "id": 1392189773
  },
  {
    "city": "Cuyahoga Falls",
    "city_ascii": "Cuyahoga Falls",
    "lat": 41.1641,
    "lng": -81.5207,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 49106,
    "id": 1840000795
  },
  {
    "city": "Rheda-Wiedenbrück",
    "city_ascii": "Rheda-Wiedenbruck",
    "lat": 51.8417,
    "lng": 8.3,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 48505,
    "id": 1276045371
  },
  {
    "city": "Downers Grove",
    "city_ascii": "Downers Grove",
    "lat": 41.7949,
    "lng": -88.017,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 49057,
    "id": 1840011404
  },
  {
    "city": "Jales",
    "city_ascii": "Jales",
    "lat": -20.2689,
    "lng": -50.5458,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 48922,
    "id": 1076132660
  },
  {
    "city": "Bedford",
    "city_ascii": "Bedford",
    "lat": 32.8464,
    "lng": -97.135,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 49049,
    "id": 1840019424
  },
  {
    "city": "Reedley",
    "city_ascii": "Reedley",
    "lat": 36.5987,
    "lng": -119.4473,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 49042,
    "id": 1840020320
  },
  {
    "city": "Dublin",
    "city_ascii": "Dublin",
    "lat": 40.1112,
    "lng": -83.1454,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 49037,
    "id": 1840008389
  },
  {
    "city": "Marana",
    "city_ascii": "Marana",
    "lat": 32.4355,
    "lng": -111.1558,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 49030,
    "id": 1840023057
  },
  {
    "city": "Stillwater",
    "city_ascii": "Stillwater",
    "lat": 36.1316,
    "lng": -97.074,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oklahoma",
    "capital": "",
    "population": 49008,
    "id": 1840021675
  },
  {
    "city": "Děčín",
    "city_ascii": "Decin",
    "lat": 50.7736,
    "lng": 14.1961,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Ústecký Kraj",
    "capital": "",
    "population": 48594,
    "id": 1203262893
  },
  {
    "city": "Al Mayādīn",
    "city_ascii": "Al Mayadin",
    "lat": 35.0208,
    "lng": 40.4533,
    "country": "Syria",
    "iso2": "SY",
    "iso3": "SYR",
    "admin_name": "Dayr az Zawr",
    "capital": "minor",
    "population": 48922,
    "id": 1760808069
  },
  {
    "city": "Huaquillas",
    "city_ascii": "Huaquillas",
    "lat": -3.4803,
    "lng": -80.2317,
    "country": "Ecuador",
    "iso2": "EC",
    "iso3": "ECU",
    "admin_name": "El Oro",
    "capital": "",
    "population": 47706,
    "id": 1218443042
  },
  {
    "city": "Murray",
    "city_ascii": "Murray",
    "lat": 40.6498,
    "lng": -111.8874,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Utah",
    "capital": "",
    "population": 48917,
    "id": 1840020159
  },
  {
    "city": "Bornheim",
    "city_ascii": "Bornheim",
    "lat": 50.7592,
    "lng": 7.005,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 48326,
    "id": 1276585020
  },
  {
    "city": "Hitachi-ota",
    "city_ascii": "Hitachi-ota",
    "lat": 36.5383,
    "lng": 140.5311,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Ibaraki",
    "capital": "",
    "population": 48758,
    "id": 1392794380
  },
  {
    "city": "Roswell",
    "city_ascii": "Roswell",
    "lat": 33.373,
    "lng": -104.5294,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Mexico",
    "capital": "",
    "population": 48889,
    "id": 1840020549
  },
  {
    "city": "Ena",
    "city_ascii": "Ena",
    "lat": 35.4494,
    "lng": 137.4128,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Gifu",
    "capital": "",
    "population": 48777,
    "id": 1392686294
  },
  {
    "city": "Pascagoula",
    "city_ascii": "Pascagoula",
    "lat": 30.3665,
    "lng": -88.5507,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Mississippi",
    "capital": "",
    "population": 48869,
    "id": 1840015018
  },
  {
    "city": "Waingapu",
    "city_ascii": "Waingapu",
    "lat": -9.6582,
    "lng": 120.253,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Nusa Tenggara Timur",
    "capital": "minor",
    "population": 48828,
    "id": 1360882365
  },
  {
    "city": "Brandon",
    "city_ascii": "Brandon",
    "lat": 49.8483,
    "lng": -99.95,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Manitoba",
    "capital": "",
    "population": 48859,
    "id": 1124239939
  },
  {
    "city": "Martigues",
    "city_ascii": "Martigues",
    "lat": 43.4053,
    "lng": 5.0475,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Provence-Alpes-Côte d’Azur",
    "capital": "",
    "population": 48188,
    "id": 1250522318
  },
  {
    "city": "Sanare",
    "city_ascii": "Sanare",
    "lat": 9.7822,
    "lng": -69.7931,
    "country": "Venezuela",
    "iso2": "VE",
    "iso3": "VEN",
    "admin_name": "Lara",
    "capital": "minor",
    "population": 48764,
    "id": 1862592324
  },
  {
    "city": "Dachau",
    "city_ascii": "Dachau",
    "lat": 48.2603,
    "lng": 11.4342,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "minor",
    "population": 47400,
    "id": 1276549079
  },
  {
    "city": "Lower Paxton",
    "city_ascii": "Lower Paxton",
    "lat": 40.3183,
    "lng": -76.7983,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 48739,
    "id": 1840149541
  },
  {
    "city": "Jasdan",
    "city_ascii": "Jasdan",
    "lat": 22.03,
    "lng": 71.2,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Gujarāt",
    "capital": "",
    "population": 48483,
    "id": 1356284058
  },
  {
    "city": "Ladysmith",
    "city_ascii": "Ladysmith",
    "lat": -28.5539,
    "lng": 29.7825,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "KwaZulu-Natal",
    "capital": "",
    "population": 47375,
    "id": 1710998347
  },
  {
    "city": "Kozlu",
    "city_ascii": "Kozlu",
    "lat": 41.4333,
    "lng": 31.75,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Zonguldak",
    "capital": "minor",
    "population": 48381,
    "id": 1792656929
  },
  {
    "city": "Megion",
    "city_ascii": "Megion",
    "lat": 61.0331,
    "lng": 76.1097,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Khanty-Mansiyskiy Avtonomnyy Okrug-Yugra",
    "capital": "",
    "population": 48691,
    "id": 1643111689
  },
  {
    "city": "Gronau",
    "city_ascii": "Gronau",
    "lat": 52.2125,
    "lng": 7.0417,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 48072,
    "id": 1276262054
  },
  {
    "city": "Wakema",
    "city_ascii": "Wakema",
    "lat": 16.6133,
    "lng": 95.1829,
    "country": "Burma",
    "iso2": "MM",
    "iso3": "MMR",
    "admin_name": "Ayeyawady",
    "capital": "",
    "population": 48405,
    "id": 1104266529
  },
  {
    "city": "Port Macquarie",
    "city_ascii": "Port Macquarie",
    "lat": -31.4333,
    "lng": 152.9,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 47973,
    "id": 1036051032
  },
  {
    "city": "Rimouski",
    "city_ascii": "Rimouski",
    "lat": 48.45,
    "lng": -68.53,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 48664,
    "id": 1124433645
  },
  {
    "city": "St. Louis Park",
    "city_ascii": "St. Louis Park",
    "lat": 44.9488,
    "lng": -93.3649,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 48662,
    "id": 1840008935
  },
  {
    "city": "Cuxhaven",
    "city_ascii": "Cuxhaven",
    "lat": 53.8667,
    "lng": 8.7,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "minor",
    "population": 48358,
    "id": 1276511789
  },
  {
    "city": "Namtu",
    "city_ascii": "Namtu",
    "lat": 23.0837,
    "lng": 97.4,
    "country": "Burma",
    "iso2": "MM",
    "iso3": "MMR",
    "admin_name": "Shan State",
    "capital": "",
    "population": 48591,
    "id": 1104043404
  },
  {
    "city": "Rio Grande City",
    "city_ascii": "Rio Grande City",
    "lat": 26.3808,
    "lng": -98.8215,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 48610,
    "id": 1840021016
  },
  {
    "city": "Maţāy",
    "city_ascii": "Matay",
    "lat": 28.4167,
    "lng": 30.7833,
    "country": "Egypt",
    "iso2": "EG",
    "iso3": "EGY",
    "admin_name": "Al Minyā",
    "capital": "",
    "population": 46903,
    "id": 1818679215
  },
  {
    "city": "Draper",
    "city_ascii": "Draper",
    "lat": 40.4957,
    "lng": -111.8605,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Utah",
    "capital": "",
    "population": 48587,
    "id": 1840018747
  },
  {
    "city": "Rumia",
    "city_ascii": "Rumia",
    "lat": 54.5667,
    "lng": 18.4,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Pomorskie",
    "capital": "",
    "population": 47148,
    "id": 1616655416
  },
  {
    "city": "Kołobrzeg",
    "city_ascii": "Kolobrzeg",
    "lat": 54.1667,
    "lng": 15.5667,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Zachodniopomorskie",
    "capital": "minor",
    "population": 46716,
    "id": 1616732501
  },
  {
    "city": "Ţubarjal",
    "city_ascii": "Tubarjal",
    "lat": 30.5,
    "lng": 38.2167,
    "country": "Saudi Arabia",
    "iso2": "SA",
    "iso3": "SAU",
    "admin_name": "Al Jawf",
    "capital": "",
    "population": 48525,
    "id": 1682061960
  },
  {
    "city": "Palm Springs",
    "city_ascii": "Palm Springs",
    "lat": 33.8017,
    "lng": -116.5382,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 48518,
    "id": 1840020556
  },
  {
    "city": "Kurganinsk",
    "city_ascii": "Kurganinsk",
    "lat": 44.8833,
    "lng": 40.6,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Krasnodarskiy Kray",
    "capital": "minor",
    "population": 48439,
    "id": 1643002399
  },
  {
    "city": "Alsdorf",
    "city_ascii": "Alsdorf",
    "lat": 50.8744,
    "lng": 6.1615,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 47018,
    "id": 1276261156
  },
  {
    "city": "Straubing",
    "city_ascii": "Straubing",
    "lat": 48.8772,
    "lng": 12.5758,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "minor",
    "population": 47794,
    "id": 1276278805
  },
  {
    "city": "Gosen",
    "city_ascii": "Gosen",
    "lat": 37.75,
    "lng": 139.1833,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Niigata",
    "capital": "",
    "population": 48360,
    "id": 1392762928
  },
  {
    "city": "Tonami",
    "city_ascii": "Tonami",
    "lat": 36.65,
    "lng": 136.9667,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Toyama",
    "capital": "",
    "population": 48116,
    "id": 1392414671
  },
  {
    "city": "Paducah",
    "city_ascii": "Paducah",
    "lat": 37.0711,
    "lng": -88.6436,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kentucky",
    "capital": "",
    "population": 48481,
    "id": 1840014388
  },
  {
    "city": "‘Akko",
    "city_ascii": "`Akko",
    "lat": 32.9261,
    "lng": 35.0839,
    "country": "Israel",
    "iso2": "IL",
    "iso3": "ISR",
    "admin_name": "Northern",
    "capital": "",
    "population": 47675,
    "id": 1376781950
  },
  {
    "city": "Findlay",
    "city_ascii": "Findlay",
    "lat": 41.0467,
    "lng": -83.6379,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 48469,
    "id": 1840000886
  },
  {
    "city": "Dornbirn",
    "city_ascii": "Dornbirn",
    "lat": 47.4167,
    "lng": 9.75,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Vorarlberg",
    "capital": "minor",
    "population": 48067,
    "id": 1040508998
  },
  {
    "city": "Ami",
    "city_ascii": "Ami",
    "lat": 36.0308,
    "lng": 140.215,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Ibaraki",
    "capital": "",
    "population": 47755,
    "id": 1392730678
  },
  {
    "city": "Marhanets’",
    "city_ascii": "Marhanets'",
    "lat": 47.648,
    "lng": 34.6167,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Dnipropetrovs’ka Oblast’",
    "capital": "minor",
    "population": 47141,
    "id": 1804303283
  },
  {
    "city": "Bulungu",
    "city_ascii": "Bulungu",
    "lat": -4.5496,
    "lng": 18.6,
    "country": "Congo (Kinshasa)",
    "iso2": "CD",
    "iso3": "COD",
    "admin_name": "Kwilu",
    "capital": "",
    "population": 48344,
    "id": 1180342743
  },
  {
    "city": "Kyle",
    "city_ascii": "Kyle",
    "lat": 29.9937,
    "lng": -97.8859,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 48393,
    "id": 1840020907
  },
  {
    "city": "Tarnobrzeg",
    "city_ascii": "Tarnobrzeg",
    "lat": 50.5833,
    "lng": 21.6833,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Podkarpackie",
    "capital": "minor",
    "population": 47816,
    "id": 1616631454
  },
  {
    "city": "Ishigaki",
    "city_ascii": "Ishigaki",
    "lat": 24.3333,
    "lng": 124.15,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Okinawa",
    "capital": "",
    "population": 48132,
    "id": 1392462580
  },
  {
    "city": "Durham",
    "city_ascii": "Durham",
    "lat": 54.7761,
    "lng": -1.5733,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Durham",
    "capital": "",
    "population": 48069,
    "id": 1826031748
  },
  {
    "city": "Monroe",
    "city_ascii": "Monroe",
    "lat": 41.9154,
    "lng": -83.385,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 48317,
    "id": 1840003987
  },
  {
    "city": "Rio Grande da Serra",
    "city_ascii": "Rio Grande da Serra",
    "lat": -23.7439,
    "lng": -46.3978,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 48302,
    "id": 1076473006
  },
  {
    "city": "Beypazarı",
    "city_ascii": "Beypazari",
    "lat": 40.1703,
    "lng": 31.9211,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Ankara",
    "capital": "minor",
    "population": 48274,
    "id": 1792374799
  },
  {
    "city": "Tuckahoe",
    "city_ascii": "Tuckahoe",
    "lat": 37.5878,
    "lng": -77.5858,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 48282,
    "id": 1840006395
  },
  {
    "city": "Lincoln",
    "city_ascii": "Lincoln",
    "lat": 38.8759,
    "lng": -121.2916,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 48275,
    "id": 1840020231
  },
  {
    "city": "Bel Air South",
    "city_ascii": "Bel Air South",
    "lat": 39.5051,
    "lng": -76.3197,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 48269,
    "id": 1840073592
  },
  {
    "city": "Ocoee",
    "city_ascii": "Ocoee",
    "lat": 28.5787,
    "lng": -81.5338,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 48263,
    "id": 1840015098
  },
  {
    "city": "Brianka",
    "city_ascii": "Brianka",
    "lat": 48.5133,
    "lng": 38.6431,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Luhans’ka Oblast’",
    "capital": "",
    "population": 47512,
    "id": 1804124686
  },
  {
    "city": "Takeochō-takeo",
    "city_ascii": "Takeocho-takeo",
    "lat": 33.1939,
    "lng": 130.0192,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Saga",
    "capital": "",
    "population": 48011,
    "id": 1392009926
  },
  {
    "city": "Burleson",
    "city_ascii": "Burleson",
    "lat": 32.517,
    "lng": -97.3343,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 48225,
    "id": 1840019467
  },
  {
    "city": "Kita",
    "city_ascii": "Kita",
    "lat": 13.0504,
    "lng": -9.4833,
    "country": "Mali",
    "iso2": "ML",
    "iso3": "MLI",
    "admin_name": "Kayes",
    "capital": "minor",
    "population": 46435,
    "id": 1466078415
  },
  {
    "city": "Finike",
    "city_ascii": "Finike",
    "lat": 36.3,
    "lng": 30.15,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Antalya",
    "capital": "minor",
    "population": 48131,
    "id": 1792503494
  },
  {
    "city": "Donetsk",
    "city_ascii": "Donetsk",
    "lat": 48.3369,
    "lng": 39.9449,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Rostovskaya Oblast’",
    "capital": "",
    "population": 47770,
    "id": 1643627132
  },
  {
    "city": "Alta Gracia",
    "city_ascii": "Alta Gracia",
    "lat": -31.6667,
    "lng": -64.4333,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Córdoba",
    "capital": "minor",
    "population": 48140,
    "id": 1032411567
  },
  {
    "city": "Farmers Branch",
    "city_ascii": "Farmers Branch",
    "lat": 32.9272,
    "lng": -96.8804,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 48158,
    "id": 1840020706
  },
  {
    "city": "Higashine",
    "city_ascii": "Higashine",
    "lat": 38.4314,
    "lng": 140.3911,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Yamagata",
    "capital": "",
    "population": 47920,
    "id": 1392717466
  },
  {
    "city": "East Lansing",
    "city_ascii": "East Lansing",
    "lat": 42.748,
    "lng": -84.4835,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 48145,
    "id": 1840003101
  },
  {
    "city": "Uniontown",
    "city_ascii": "Uniontown",
    "lat": 39.8994,
    "lng": -79.7244,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 48137,
    "id": 1840000671
  },
  {
    "city": "Kashira",
    "city_ascii": "Kashira",
    "lat": 54.8333,
    "lng": 38.15,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Moskovskaya Oblast’",
    "capital": "",
    "population": 48016,
    "id": 1643003266
  },
  {
    "city": "Shelekhov",
    "city_ascii": "Shelekhov",
    "lat": 52.2,
    "lng": 104.1,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Irkutskaya Oblast’",
    "capital": "",
    "population": 48098,
    "id": 1643613779
  },
  {
    "city": "Jeffersonville",
    "city_ascii": "Jeffersonville",
    "lat": 38.3376,
    "lng": -85.7026,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 48126,
    "id": 1840008642
  },
  {
    "city": "Winder",
    "city_ascii": "Winder",
    "lat": 33.9917,
    "lng": -83.7218,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 48125,
    "id": 1840015623
  },
  {
    "city": "Wauwatosa",
    "city_ascii": "Wauwatosa",
    "lat": 43.0615,
    "lng": -88.0347,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 48118,
    "id": 1840003033
  },
  {
    "city": "Vila Verde",
    "city_ascii": "Vila Verde",
    "lat": 41.65,
    "lng": -8.4333,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Braga",
    "capital": "minor",
    "population": 47888,
    "id": 1620759791
  },
  {
    "city": "Skierniewice",
    "city_ascii": "Skierniewice",
    "lat": 51.9528,
    "lng": 20.1417,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Łódzkie",
    "capital": "minor",
    "population": 47837,
    "id": 1616077823
  },
  {
    "city": "Littleton",
    "city_ascii": "Littleton",
    "lat": 39.5915,
    "lng": -105.0188,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 48065,
    "id": 1840020213
  },
  {
    "city": "Otradnyy",
    "city_ascii": "Otradnyy",
    "lat": 53.3667,
    "lng": 51.35,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Samarskaya Oblast’",
    "capital": "",
    "population": 47180,
    "id": 1643670563
  },
  {
    "city": "Kikugawa",
    "city_ascii": "Kikugawa",
    "lat": 34.7578,
    "lng": 138.0842,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Shizuoka",
    "capital": "",
    "population": 47547,
    "id": 1392787606
  },
  {
    "city": "Tomioka",
    "city_ascii": "Tomioka",
    "lat": 36.26,
    "lng": 138.89,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Gunma",
    "capital": "",
    "population": 47648,
    "id": 1392639316
  },
  {
    "city": "Noboribetsu",
    "city_ascii": "Noboribetsu",
    "lat": 42.4128,
    "lng": 141.1067,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hokkaidō",
    "capital": "",
    "population": 47795,
    "id": 1392722896
  },
  {
    "city": "Soest",
    "city_ascii": "Soest",
    "lat": 51.5711,
    "lng": 8.1092,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "minor",
    "population": 47460,
    "id": 1276851034
  },
  {
    "city": "East Brunswick",
    "city_ascii": "East Brunswick",
    "lat": 40.4281,
    "lng": -74.4179,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 47981,
    "id": 1840081693
  },
  {
    "city": "Cumberland",
    "city_ascii": "Cumberland",
    "lat": 39.6515,
    "lng": -78.7585,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 47980,
    "id": 1840005621
  },
  {
    "city": "Stade",
    "city_ascii": "Stade",
    "lat": 53.6008,
    "lng": 9.4764,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "minor",
    "population": 47533,
    "id": 1276472720
  },
  {
    "city": "Cedar Hill",
    "city_ascii": "Cedar Hill",
    "lat": 32.581,
    "lng": -96.9591,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 47930,
    "id": 1840019433
  },
  {
    "city": "Brive-la-Gaillarde",
    "city_ascii": "Brive-la-Gaillarde",
    "lat": 45.1583,
    "lng": 1.5321,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Nouvelle-Aquitaine",
    "capital": "minor",
    "population": 46916,
    "id": 1250549859
  },
  {
    "city": "Carrollton",
    "city_ascii": "Carrollton",
    "lat": 33.5817,
    "lng": -85.0837,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 47920,
    "id": 1840014801
  },
  {
    "city": "Enid",
    "city_ascii": "Enid",
    "lat": 36.4061,
    "lng": -97.8701,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oklahoma",
    "capital": "",
    "population": 47907,
    "id": 1840020373
  },
  {
    "city": "Golpāyegān",
    "city_ascii": "Golpayegan",
    "lat": 33.4536,
    "lng": 50.2883,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Eşfahān",
    "capital": "minor",
    "population": 47849,
    "id": 1364867790
  },
  {
    "city": "Dīla",
    "city_ascii": "Dila",
    "lat": 6.4104,
    "lng": 38.31,
    "country": "Ethiopia",
    "iso2": "ET",
    "iso3": "ETH",
    "admin_name": "YeDebub Bihēroch Bihēreseboch na Hizboch",
    "capital": "",
    "population": 47021,
    "id": 1231393325
  },
  {
    "city": "Rancho Santa Margarita",
    "city_ascii": "Rancho Santa Margarita",
    "lat": 33.6318,
    "lng": -117.5989,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 47896,
    "id": 1840020584
  },
  {
    "city": "Pakenham",
    "city_ascii": "Pakenham",
    "lat": -38.0712,
    "lng": 145.4878,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Victoria",
    "capital": "",
    "population": 46421,
    "id": 1036063031
  },
  {
    "city": "Lousada",
    "city_ascii": "Lousada",
    "lat": 41.2833,
    "lng": -8.2833,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Porto",
    "capital": "minor",
    "population": 47387,
    "id": 1620988419
  },
  {
    "city": "Charleville-Mézières",
    "city_ascii": "Charleville-Mezieres",
    "lat": 49.7719,
    "lng": 4.7161,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Grand Est",
    "capital": "minor",
    "population": 46428,
    "id": 1250492188
  },
  {
    "city": "Poti",
    "city_ascii": "Poti",
    "lat": 42.15,
    "lng": 41.6667,
    "country": "Georgia",
    "iso2": "GE",
    "iso3": "GEO",
    "admin_name": "Samegrelo-Zemo Svaneti",
    "capital": "minor",
    "population": 47149,
    "id": 1268764439
  },
  {
    "city": "Luwuk",
    "city_ascii": "Luwuk",
    "lat": -0.9396,
    "lng": 122.79,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Sulawesi Tengah",
    "capital": "minor",
    "population": 47778,
    "id": 1360727028
  },
  {
    "city": "Washington Township",
    "city_ascii": "Washington Township",
    "lat": 39.747,
    "lng": -75.0724,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 47809,
    "id": 1840144649
  },
  {
    "city": "Herzogenrath",
    "city_ascii": "Herzogenrath",
    "lat": 50.8667,
    "lng": 6.1,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 46402,
    "id": 1276873875
  },
  {
    "city": "Vyshniy Volochëk",
    "city_ascii": "Vyshniy Volochek",
    "lat": 57.5913,
    "lng": 34.5645,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Tverskaya Oblast’",
    "capital": "",
    "population": 46908,
    "id": 1643616804
  },
  {
    "city": "East Honolulu",
    "city_ascii": "East Honolulu",
    "lat": 21.2975,
    "lng": -157.7211,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Hawaii",
    "capital": "",
    "population": 47770,
    "id": 1840039181
  },
  {
    "city": "Al Madrah Samā’il",
    "city_ascii": "Al Madrah Sama'il",
    "lat": 23.3032,
    "lng": 57.9782,
    "country": "Oman",
    "iso2": "OM",
    "iso3": "OMN",
    "admin_name": "Ad Dākhilīyah",
    "capital": "",
    "population": 47718,
    "id": 1512000205
  },
  {
    "city": "Al Majma‘ah",
    "city_ascii": "Al Majma`ah",
    "lat": 25.9039,
    "lng": 45.3456,
    "country": "Saudi Arabia",
    "iso2": "SA",
    "iso3": "SAU",
    "admin_name": "Ar Riyāḑ",
    "capital": "",
    "population": 47743,
    "id": 1682506946
  },
  {
    "city": "Beavercreek",
    "city_ascii": "Beavercreek",
    "lat": 39.731,
    "lng": -84.0624,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 47741,
    "id": 1840007312
  },
  {
    "city": "Saint-Herblain",
    "city_ascii": "Saint-Herblain",
    "lat": 47.2122,
    "lng": -1.6497,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Pays de la Loire",
    "capital": "",
    "population": 46268,
    "id": 1250679034
  },
  {
    "city": "Niagara Falls",
    "city_ascii": "Niagara Falls",
    "lat": 43.0921,
    "lng": -79.0147,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 47720,
    "id": 1840000371
  },
  {
    "city": "Snizhne",
    "city_ascii": "Snizhne",
    "lat": 48.0167,
    "lng": 38.7667,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Donets’ka Oblast’",
    "capital": "",
    "population": 47259,
    "id": 1804966231
  },
  {
    "city": "Amahai",
    "city_ascii": "Amahai",
    "lat": -3.3281,
    "lng": 128.9405,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Maluku",
    "capital": "minor",
    "population": 47653,
    "id": 1360767250
  },
  {
    "city": "‘Āmūdā",
    "city_ascii": "`Amuda",
    "lat": 37.1042,
    "lng": 40.93,
    "country": "Syria",
    "iso2": "SY",
    "iso3": "SYR",
    "admin_name": "Al Ḩasakah",
    "capital": "minor",
    "population": 47580,
    "id": 1760247135
  },
  {
    "city": "Saint-Priest",
    "city_ascii": "Saint-Priest",
    "lat": 45.6972,
    "lng": 4.9447,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Auvergne-Rhône-Alpes",
    "capital": "",
    "population": 46207,
    "id": 1250000042
  },
  {
    "city": "San Lorenzo",
    "city_ascii": "San Lorenzo",
    "lat": -28.12,
    "lng": -58.77,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Corrientes",
    "capital": "",
    "population": 47626,
    "id": 1032610376
  },
  {
    "city": "East Providence",
    "city_ascii": "East Providence",
    "lat": 41.8065,
    "lng": -71.3565,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Rhode Island",
    "capital": "",
    "population": 47618,
    "id": 1840003288
  },
  {
    "city": "Kamiyoshida",
    "city_ascii": "Kamiyoshida",
    "lat": 35.4875,
    "lng": 138.8081,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Yamanashi",
    "capital": "",
    "population": 47211,
    "id": 1392697533
  },
  {
    "city": "Kafue",
    "city_ascii": "Kafue",
    "lat": -15.78,
    "lng": 28.18,
    "country": "Zambia",
    "iso2": "ZM",
    "iso3": "ZMB",
    "admin_name": "Lusaka",
    "capital": "",
    "population": 47554,
    "id": 1894930695
  },
  {
    "city": "Kutno",
    "city_ascii": "Kutno",
    "lat": 52.2333,
    "lng": 19.3667,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Łódzkie",
    "capital": "minor",
    "population": 47557,
    "id": 1616586955
  },
  {
    "city": "Livny",
    "city_ascii": "Livny",
    "lat": 52.4253,
    "lng": 37.6083,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Orlovskaya Oblast’",
    "capital": "",
    "population": 47221,
    "id": 1643284653
  },
  {
    "city": "Aznā",
    "city_ascii": "Azna",
    "lat": 33.4539,
    "lng": 49.455,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Lorestān",
    "capital": "minor",
    "population": 47489,
    "id": 1364854218
  },
  {
    "city": "Chesterfield",
    "city_ascii": "Chesterfield",
    "lat": 38.6588,
    "lng": -90.5804,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 47538,
    "id": 1840007446
  },
  {
    "city": "Irati",
    "city_ascii": "Irati",
    "lat": -25.4672,
    "lng": -50.6511,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Paraná",
    "capital": "",
    "population": 45830,
    "id": 1076276918
  },
  {
    "city": "Capão Bonito",
    "city_ascii": "Capao Bonito",
    "lat": -24.0058,
    "lng": -48.3494,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 47486,
    "id": 1076334908
  },
  {
    "city": "Ban Plai Bua Phatthana",
    "city_ascii": "Ban Plai Bua Phatthana",
    "lat": 13.9032,
    "lng": 100.3989,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Pathum Thani",
    "capital": "",
    "population": 46274,
    "id": 1764928382
  },
  {
    "city": "Santa Ana Chiautempan",
    "city_ascii": "Santa Ana Chiautempan",
    "lat": 19.3167,
    "lng": -98.1833,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Tlaxcala",
    "capital": "minor",
    "population": 46776,
    "id": 1484567035
  },
  {
    "city": "Tamagawa",
    "city_ascii": "Tamagawa",
    "lat": 33.6317,
    "lng": 130.7997,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Fukuoka",
    "capital": "",
    "population": 46611,
    "id": 1392705807
  },
  {
    "city": "Lohja",
    "city_ascii": "Lohja",
    "lat": 60.25,
    "lng": 24.0667,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Uusimaa",
    "capital": "minor",
    "population": 47353,
    "id": 1246687472
  },
  {
    "city": "Qingyuan",
    "city_ascii": "Qingyuan",
    "lat": 24.5004,
    "lng": 108.6667,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Guangxi",
    "capital": "",
    "population": 47062,
    "id": 1156899793
  },
  {
    "city": "Kasaoka",
    "city_ascii": "Kasaoka",
    "lat": 34.5072,
    "lng": 133.5072,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Okayama",
    "capital": "",
    "population": 47097,
    "id": 1392085500
  },
  {
    "city": "Sayanogorsk",
    "city_ascii": "Sayanogorsk",
    "lat": 53.1,
    "lng": 91.4,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Khakasiya",
    "capital": "",
    "population": 47358,
    "id": 1643565856
  },
  {
    "city": "Sanski Most",
    "city_ascii": "Sanski Most",
    "lat": 44.7667,
    "lng": 16.6667,
    "country": "Bosnia And Herzegovina",
    "iso2": "BA",
    "iso3": "BIH",
    "admin_name": "Bosnia and Herzegovina, Federation of",
    "capital": "minor",
    "population": 47359,
    "id": 1070675252
  },
  {
    "city": "Bothell",
    "city_ascii": "Bothell",
    "lat": 47.7735,
    "lng": -122.2044,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 47415,
    "id": 1840018419
  },
  {
    "city": "Jobabo",
    "city_ascii": "Jobabo",
    "lat": 20.9078,
    "lng": -77.2831,
    "country": "Cuba",
    "iso2": "CU",
    "iso3": "CUB",
    "admin_name": "Las Tunas",
    "capital": "minor",
    "population": 47350,
    "id": 1192342239
  },
  {
    "city": "Villa Constitución",
    "city_ascii": "Villa Constitucion",
    "lat": -33.2333,
    "lng": -60.3333,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Santa Fe",
    "capital": "minor",
    "population": 47374,
    "id": 1032793574
  },
  {
    "city": "Tangdukou",
    "city_ascii": "Tangdukou",
    "lat": 27,
    "lng": 111.2001,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Hunan",
    "capital": "minor",
    "population": 45617,
    "id": 1156473609
  },
  {
    "city": "Kingisepp",
    "city_ascii": "Kingisepp",
    "lat": 59.3667,
    "lng": 28.6,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Leningradskaya Oblast’",
    "capital": "minor",
    "population": 47313,
    "id": 1643712718
  },
  {
    "city": "Talghar",
    "city_ascii": "Talghar",
    "lat": 43.3,
    "lng": 77.2333,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Almaty",
    "capital": "",
    "population": 47301,
    "id": 1398444969
  },
  {
    "city": "Nakai",
    "city_ascii": "Nakai",
    "lat": 33.5756,
    "lng": 133.6414,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Kōchi",
    "capital": "",
    "population": 46951,
    "id": 1392020842
  },
  {
    "city": "Fellbach",
    "city_ascii": "Fellbach",
    "lat": 48.8086,
    "lng": 9.2758,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 45671,
    "id": 1276083210
  },
  {
    "city": "Blois",
    "city_ascii": "Blois",
    "lat": 47.5939,
    "lng": 1.3281,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Centre-Val de Loire",
    "capital": "minor",
    "population": 46086,
    "id": 1250729037
  },
  {
    "city": "Yabrūd",
    "city_ascii": "Yabrud",
    "lat": 33.9672,
    "lng": 36.6572,
    "country": "Syria",
    "iso2": "SY",
    "iso3": "SYR",
    "admin_name": "Rīf Dimashq",
    "capital": "minor",
    "population": 47136,
    "id": 1760885103
  },
  {
    "city": "Glenview",
    "city_ascii": "Glenview",
    "lat": 42.0825,
    "lng": -87.8216,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 47308,
    "id": 1840011280
  },
  {
    "city": "Ban Bang Mae Nang",
    "city_ascii": "Ban Bang Mae Nang",
    "lat": 13.8815,
    "lng": 100.3759,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Nonthaburi",
    "capital": "",
    "population": 45974,
    "id": 1764607798
  },
  {
    "city": "Tursunzoda",
    "city_ascii": "Tursunzoda",
    "lat": 38.5108,
    "lng": 68.2303,
    "country": "Tajikistan",
    "iso2": "TJ",
    "iso3": "TJK",
    "admin_name": "Nohiyahoi Tobei Jumhurí",
    "capital": "minor",
    "population": 46700,
    "id": 1762277771
  },
  {
    "city": "Saint-Malo",
    "city_ascii": "Saint-Malo",
    "lat": 48.6481,
    "lng": -2.0075,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Bretagne",
    "capital": "minor",
    "population": 46097,
    "id": 1250296332
  },
  {
    "city": "Oberursel",
    "city_ascii": "Oberursel",
    "lat": 50.2028,
    "lng": 8.5769,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 46248,
    "id": 1276288354
  },
  {
    "city": "Mentor",
    "city_ascii": "Mentor",
    "lat": 41.6895,
    "lng": -81.3361,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 47262,
    "id": 1840000539
  },
  {
    "city": "Antalaha",
    "city_ascii": "Antalaha",
    "lat": -14.8833,
    "lng": 50.2833,
    "country": "Madagascar",
    "iso2": "MG",
    "iso3": "MDG",
    "admin_name": "Antsiranana",
    "capital": "",
    "population": 47224,
    "id": 1450649394
  },
  {
    "city": "Landau",
    "city_ascii": "Landau",
    "lat": 49.1994,
    "lng": 8.1231,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Rhineland-Palatinate",
    "capital": "minor",
    "population": 46677,
    "id": 1276000837
  },
  {
    "city": "Jablonec nad Nisou",
    "city_ascii": "Jablonec nad Nisou",
    "lat": 50.7278,
    "lng": 15.17,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Liberecký Kraj",
    "capital": "",
    "population": 45773,
    "id": 1203127047
  },
  {
    "city": "Keller",
    "city_ascii": "Keller",
    "lat": 32.9337,
    "lng": -97.2255,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 47213,
    "id": 1840019428
  },
  {
    "city": "West Orange",
    "city_ascii": "West Orange",
    "lat": 40.7893,
    "lng": -74.2627,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 47210,
    "id": 1840131596
  },
  {
    "city": "Shima",
    "city_ascii": "Shima",
    "lat": 34.3283,
    "lng": 136.8306,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Mie",
    "capital": "",
    "population": 46937,
    "id": 1392981052
  },
  {
    "city": "Takashima",
    "city_ascii": "Takashima",
    "lat": 35.3528,
    "lng": 136.0356,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Shiga",
    "capital": "",
    "population": 47124,
    "id": 1392048667
  },
  {
    "city": "Zarinsk",
    "city_ascii": "Zarinsk",
    "lat": 53.7,
    "lng": 84.9167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Altayskiy Kray",
    "capital": "",
    "population": 46597,
    "id": 1643009932
  },
  {
    "city": "Huatan",
    "city_ascii": "Huatan",
    "lat": 24.0316,
    "lng": 120.558,
    "country": "Taiwan",
    "iso2": "TW",
    "iso3": "TWN",
    "admin_name": "Changhua",
    "capital": "",
    "population": 45907,
    "id": 1158596327
  },
  {
    "city": "Schwerte",
    "city_ascii": "Schwerte",
    "lat": 51.4458,
    "lng": 7.5653,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 46340,
    "id": 1276720376
  },
  {
    "city": "Aubagne",
    "city_ascii": "Aubagne",
    "lat": 43.2908,
    "lng": 5.5708,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Provence-Alpes-Côte d’Azur",
    "capital": "",
    "population": 46268,
    "id": 1250602671
  },
  {
    "city": "Poso",
    "city_ascii": "Poso",
    "lat": -1.3896,
    "lng": 120.76,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Sulawesi Tengah",
    "capital": "minor",
    "population": 47110,
    "id": 1360727615
  },
  {
    "city": "Tres Arroyos",
    "city_ascii": "Tres Arroyos",
    "lat": -38.3667,
    "lng": -60.2667,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires",
    "capital": "minor",
    "population": 47136,
    "id": 1032291724
  },
  {
    "city": "Nysa",
    "city_ascii": "Nysa",
    "lat": 50.4738,
    "lng": 17.3344,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Opolskie",
    "capital": "minor",
    "population": 46841,
    "id": 1616093801
  },
  {
    "city": "Kurayoshi",
    "city_ascii": "Kurayoshi",
    "lat": 35.43,
    "lng": 133.8256,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Tottori",
    "capital": "",
    "population": 46943,
    "id": 1392359185
  },
  {
    "city": "Calimaya",
    "city_ascii": "Calimaya",
    "lat": 19.1608,
    "lng": -99.6172,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "México",
    "capital": "minor",
    "population": 47033,
    "id": 1484137559
  },
  {
    "city": "Neunkirchen",
    "city_ascii": "Neunkirchen",
    "lat": 49.3448,
    "lng": 7.1799,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saarland",
    "capital": "minor",
    "population": 46469,
    "id": 1276274217
  },
  {
    "city": "McLean",
    "city_ascii": "McLean",
    "lat": 38.9436,
    "lng": -77.1943,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 47075,
    "id": 1840006018
  },
  {
    "city": "Danville",
    "city_ascii": "Danville",
    "lat": 40.1423,
    "lng": -87.6114,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 47059,
    "id": 1840007235
  },
  {
    "city": "Spring Hill",
    "city_ascii": "Spring Hill",
    "lat": 35.7438,
    "lng": -86.9116,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 47042,
    "id": 1840015386
  },
  {
    "city": "Al Quşayr",
    "city_ascii": "Al Qusayr",
    "lat": 34.5089,
    "lng": 36.5797,
    "country": "Syria",
    "iso2": "SY",
    "iso3": "SYR",
    "admin_name": "Ḩimş",
    "capital": "minor",
    "population": 46772,
    "id": 1760157542
  },
  {
    "city": "Roseville",
    "city_ascii": "Roseville",
    "lat": 42.5074,
    "lng": -82.9369,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 47018,
    "id": 1840003092
  },
  {
    "city": "Filderstadt",
    "city_ascii": "Filderstadt",
    "lat": 48.6667,
    "lng": 9.2167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 45813,
    "id": 1276952389
  },
  {
    "city": "Fastiv",
    "city_ascii": "Fastiv",
    "lat": 50.0747,
    "lng": 29.9181,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Kyyivs’ka Oblast’",
    "capital": "minor",
    "population": 45907,
    "id": 1804739399
  },
  {
    "city": "Tocoa",
    "city_ascii": "Tocoa",
    "lat": 15.6833,
    "lng": -86,
    "country": "Honduras",
    "iso2": "HN",
    "iso3": "HND",
    "admin_name": "Colón",
    "capital": "",
    "population": 46900,
    "id": 1340024758
  },
  {
    "city": "Agrínio",
    "city_ascii": "Agrinio",
    "lat": 38.6167,
    "lng": 21.4,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Dytikí Elláda",
    "capital": "minor",
    "population": 46899,
    "id": 1300351677
  },
  {
    "city": "Lukavac",
    "city_ascii": "Lukavac",
    "lat": 44.5333,
    "lng": 18.5333,
    "country": "Bosnia And Herzegovina",
    "iso2": "BA",
    "iso3": "BIH",
    "admin_name": "Bosnia and Herzegovina, Federation of",
    "capital": "minor",
    "population": 46731,
    "id": 1070273413
  },
  {
    "city": "T’aebaek",
    "city_ascii": "T'aebaek",
    "lat": 37.1731,
    "lng": 128.9861,
    "country": "Korea, South",
    "iso2": "KR",
    "iso3": "KOR",
    "admin_name": "Gangwon",
    "capital": "",
    "population": 46715,
    "id": 1410565787
  },
  {
    "city": "Dülmen",
    "city_ascii": "Dulmen",
    "lat": 51.8308,
    "lng": 7.2783,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 46590,
    "id": 1276703649
  },
  {
    "city": "Ban Saen Suk",
    "city_ascii": "Ban Saen Suk",
    "lat": 13.2882,
    "lng": 100.9144,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Chon Buri",
    "capital": "",
    "population": 45142,
    "id": 1764664241
  },
  {
    "city": "Lubny",
    "city_ascii": "Lubny",
    "lat": 50.0186,
    "lng": 32.9869,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Poltavs’ka Oblast’",
    "capital": "minor",
    "population": 45786,
    "id": 1804740843
  },
  {
    "city": "Carcassonne",
    "city_ascii": "Carcassonne",
    "lat": 43.21,
    "lng": 2.35,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Occitanie",
    "capital": "minor",
    "population": 46031,
    "id": 1250306687
  },
  {
    "city": "San Francisco de los Romo",
    "city_ascii": "San Francisco de los Romo",
    "lat": 22.0833,
    "lng": -102.2667,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Aguascalientes",
    "capital": "minor",
    "population": 46454,
    "id": 1484352122
  },
  {
    "city": "Berëzovskiy",
    "city_ascii": "Berezovskiy",
    "lat": 55.6667,
    "lng": 86.25,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kemerovskaya Oblast’",
    "capital": "",
    "population": 46215,
    "id": 1643773270
  },
  {
    "city": "Elmhurst",
    "city_ascii": "Elmhurst",
    "lat": 41.8973,
    "lng": -87.9432,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 46746,
    "id": 1840008142
  },
  {
    "city": "Hof",
    "city_ascii": "Hof",
    "lat": 50.3167,
    "lng": 11.9167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "minor",
    "population": 45930,
    "id": 1276561363
  },
  {
    "city": "Kariya",
    "city_ascii": "Kariya",
    "lat": 34.7889,
    "lng": 134.3724,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hyōgo",
    "capital": "",
    "population": 46347,
    "id": 1392896537
  },
  {
    "city": "Cabreúva",
    "city_ascii": "Cabreuva",
    "lat": -23.3075,
    "lng": -47.1331,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 46528,
    "id": 1076162837
  },
  {
    "city": "New Philadelphia",
    "city_ascii": "New Philadelphia",
    "lat": 40.486,
    "lng": -81.4402,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 46698,
    "id": 1840001281
  },
  {
    "city": "Melle",
    "city_ascii": "Melle",
    "lat": 52.2031,
    "lng": 8.3361,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 46493,
    "id": 1276489872
  },
  {
    "city": "Santa Cruz do Rio Pardo",
    "city_ascii": "Santa Cruz do Rio Pardo",
    "lat": -22.8989,
    "lng": -49.6328,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 46633,
    "id": 1076453060
  },
  {
    "city": "Sandusky",
    "city_ascii": "Sandusky",
    "lat": 41.4437,
    "lng": -82.7149,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 46655,
    "id": 1840003433
  },
  {
    "city": "Hokota",
    "city_ascii": "Hokota",
    "lat": 36.1586,
    "lng": 140.5164,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Ibaraki",
    "capital": "",
    "population": 46415,
    "id": 1392374973
  },
  {
    "city": "Hyvinkää",
    "city_ascii": "Hyvinkaa",
    "lat": 60.6306,
    "lng": 24.8597,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Uusimaa",
    "capital": "minor",
    "population": 46463,
    "id": 1246789945
  },
  {
    "city": "Cornwall",
    "city_ascii": "Cornwall",
    "lat": 45.0275,
    "lng": -74.74,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 46589,
    "id": 1124938303
  },
  {
    "city": "Perinton",
    "city_ascii": "Perinton",
    "lat": 43.0781,
    "lng": -77.4283,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 46573,
    "id": 1840087790
  },
  {
    "city": "Euclid",
    "city_ascii": "Euclid",
    "lat": 41.5904,
    "lng": -81.5188,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 46550,
    "id": 1840000600
  },
  {
    "city": "Gryazi",
    "city_ascii": "Gryazi",
    "lat": 52.5,
    "lng": 39.9333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Lipetskaya Oblast’",
    "capital": "",
    "population": 46492,
    "id": 1643963705
  },
  {
    "city": "Shimeo",
    "city_ascii": "Shimeo",
    "lat": 33.5914,
    "lng": 130.4797,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Fukuoka",
    "capital": "",
    "population": 45918,
    "id": 1392938801
  },
  {
    "city": "Kingman",
    "city_ascii": "Kingman",
    "lat": 35.217,
    "lng": -114.0105,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 46524,
    "id": 1840021043
  },
  {
    "city": "Galveston",
    "city_ascii": "Galveston",
    "lat": 29.2487,
    "lng": -94.891,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 46514,
    "id": 1840020970
  },
  {
    "city": "Dendermonde",
    "city_ascii": "Dendermonde",
    "lat": 51.0167,
    "lng": 4.1,
    "country": "Belgium",
    "iso2": "BE",
    "iso3": "BEL",
    "admin_name": "Flanders",
    "capital": "minor",
    "population": 45673,
    "id": 1056277590
  },
  {
    "city": "Kilmarnock",
    "city_ascii": "Kilmarnock",
    "lat": 55.6111,
    "lng": -4.4957,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "East Ayrshire",
    "capital": "",
    "population": 46450,
    "id": 1826595229
  },
  {
    "city": "Châlons-en-Champagne",
    "city_ascii": "Chalons-en-Champagne",
    "lat": 48.9575,
    "lng": 4.365,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Grand Est",
    "capital": "minor",
    "population": 44753,
    "id": 1250720943
  },
  {
    "city": "Berekum",
    "city_ascii": "Berekum",
    "lat": 7.4504,
    "lng": -2.59,
    "country": "Ghana",
    "iso2": "GH",
    "iso3": "GHA",
    "admin_name": "Brong-Ahafo",
    "capital": "",
    "population": 46287,
    "id": 1288257467
  },
  {
    "city": "Río Tercero",
    "city_ascii": "Rio Tercero",
    "lat": -32.1768,
    "lng": -64.113,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Córdoba",
    "capital": "",
    "population": 46421,
    "id": 1032103221
  },
  {
    "city": "Montecristi",
    "city_ascii": "Montecristi",
    "lat": -1.05,
    "lng": -80.6667,
    "country": "Ecuador",
    "iso2": "EC",
    "iso3": "ECU",
    "admin_name": "Manabí",
    "capital": "",
    "population": 46312,
    "id": 1218883853
  },
  {
    "city": "Torzhok",
    "city_ascii": "Torzhok",
    "lat": 57.0333,
    "lng": 34.9667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Tverskaya Oblast’",
    "capital": "",
    "population": 45641,
    "id": 1643710105
  },
  {
    "city": "Gotha",
    "city_ascii": "Gotha",
    "lat": 50.9489,
    "lng": 10.7183,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Thuringia",
    "capital": "minor",
    "population": 45733,
    "id": 1276679639
  },
  {
    "city": "Tingo María",
    "city_ascii": "Tingo Maria",
    "lat": -9.3015,
    "lng": -76.0361,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "Huánuco",
    "capital": "",
    "population": 46191,
    "id": 1604736000
  },
  {
    "city": "Jammalamadugu",
    "city_ascii": "Jammalamadugu",
    "lat": 14.85,
    "lng": 78.38,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Andhra Pradesh",
    "capital": "",
    "population": 46069,
    "id": 1356030028
  },
  {
    "city": "Salina",
    "city_ascii": "Salina",
    "lat": 38.8137,
    "lng": -97.6143,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kansas",
    "capital": "",
    "population": 46340,
    "id": 1840001647
  },
  {
    "city": "Farmington",
    "city_ascii": "Farmington",
    "lat": 37.7821,
    "lng": -90.4288,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 46331,
    "id": 1840008686
  },
  {
    "city": "Ciechanów",
    "city_ascii": "Ciechanow",
    "lat": 52.8817,
    "lng": 20.6106,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Mazowieckie",
    "capital": "minor",
    "population": 46112,
    "id": 1616466171
  },
  {
    "city": "Bünde",
    "city_ascii": "Bunde",
    "lat": 52.2,
    "lng": 8.6,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 45521,
    "id": 1276719683
  },
  {
    "city": "Mladá Boleslav",
    "city_ascii": "Mlada Boleslav",
    "lat": 50.4125,
    "lng": 14.9064,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Středočeský Kraj",
    "capital": "",
    "population": 44740,
    "id": 1203616853
  },
  {
    "city": "Hilo",
    "city_ascii": "Hilo",
    "lat": 19.6886,
    "lng": -155.0864,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Hawaii",
    "capital": "",
    "population": 46284,
    "id": 1840023193
  },
  {
    "city": "Weatherford",
    "city_ascii": "Weatherford",
    "lat": 32.7536,
    "lng": -97.7723,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 46268,
    "id": 1840022041
  },
  {
    "city": "Middletown",
    "city_ascii": "Middletown",
    "lat": 41.5476,
    "lng": -72.6549,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 46258,
    "id": 1840004844
  },
  {
    "city": "Mairinque",
    "city_ascii": "Mairinque",
    "lat": -23.5464,
    "lng": -47.1836,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 46015,
    "id": 1076990000
  },
  {
    "city": "Roseburg",
    "city_ascii": "Roseburg",
    "lat": 43.2232,
    "lng": -123.3518,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 46228,
    "id": 1840020034
  },
  {
    "city": "Biloxi",
    "city_ascii": "Biloxi",
    "lat": 30.4422,
    "lng": -88.9512,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Mississippi",
    "capital": "",
    "population": 46212,
    "id": 1840013948
  },
  {
    "city": "Wittenberg",
    "city_ascii": "Wittenberg",
    "lat": 51.8671,
    "lng": 12.6484,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony-Anhalt",
    "capital": "minor",
    "population": 46008,
    "id": 1276533652
  },
  {
    "city": "Roskilde",
    "city_ascii": "Roskilde",
    "lat": 55.65,
    "lng": 12.0833,
    "country": "Denmark",
    "iso2": "DK",
    "iso3": "DNK",
    "admin_name": "Sjælland",
    "capital": "minor",
    "population": 44285,
    "id": 1208722269
  },
  {
    "city": "Leavenworth",
    "city_ascii": "Leavenworth",
    "lat": 39.3239,
    "lng": -94.924,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kansas",
    "capital": "",
    "population": 46192,
    "id": 1840001591
  },
  {
    "city": "Salon-de-Provence",
    "city_ascii": "Salon-de-Provence",
    "lat": 43.6406,
    "lng": 5.0972,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Provence-Alpes-Côte d’Azur",
    "capital": "",
    "population": 45528,
    "id": 1250380072
  },
  {
    "city": "Hokuto",
    "city_ascii": "Hokuto",
    "lat": 41.8242,
    "lng": 140.6531,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hokkaidō",
    "capital": "",
    "population": 46054,
    "id": 1392000277
  },
  {
    "city": "Bājil",
    "city_ascii": "Bajil",
    "lat": 15.0583,
    "lng": 43.285,
    "country": "Yemen",
    "iso2": "YE",
    "iso3": "YEM",
    "admin_name": "Al Ḩudaydah",
    "capital": "minor",
    "population": 46005,
    "id": 1887795650
  },
  {
    "city": "Manono",
    "city_ascii": "Manono",
    "lat": -7.2947,
    "lng": 27.4545,
    "country": "Congo (Kinshasa)",
    "iso2": "CD",
    "iso3": "COD",
    "admin_name": "Tanganyika",
    "capital": "",
    "population": 46111,
    "id": 1180968800
  },
  {
    "city": "Pearl City",
    "city_ascii": "Pearl City",
    "lat": 21.4031,
    "lng": -157.9566,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Hawaii",
    "capital": "",
    "population": 46129,
    "id": 1840023251
  },
  {
    "city": "Pontal",
    "city_ascii": "Pontal",
    "lat": -21.0225,
    "lng": -48.0372,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 45978,
    "id": 1076651635
  },
  {
    "city": "Aksay",
    "city_ascii": "Aksay",
    "lat": 47.2667,
    "lng": 39.8667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Rostovskaya Oblast’",
    "capital": "minor",
    "population": 46018,
    "id": 1643659025
  },
  {
    "city": "Fort Pierce",
    "city_ascii": "Fort Pierce",
    "lat": 27.4256,
    "lng": -80.3431,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 46103,
    "id": 1840014178
  },
  {
    "city": "Weinheim",
    "city_ascii": "Weinheim",
    "lat": 49.5561,
    "lng": 8.6697,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 45284,
    "id": 1276001742
  },
  {
    "city": "Winter Garden",
    "city_ascii": "Winter Garden",
    "lat": 28.5421,
    "lng": -81.5966,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 46051,
    "id": 1840015965
  },
  {
    "city": "Oro Valley",
    "city_ascii": "Oro Valley",
    "lat": 32.4208,
    "lng": -110.9768,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 46044,
    "id": 1840023058
  },
  {
    "city": "Ourém",
    "city_ascii": "Ourem",
    "lat": 39.65,
    "lng": -8.5833,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Santarém",
    "capital": "minor",
    "population": 45932,
    "id": 1620510353
  },
  {
    "city": "Erkrath",
    "city_ascii": "Erkrath",
    "lat": 51.2239,
    "lng": 6.9147,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 44384,
    "id": 1276917229
  },
  {
    "city": "Morgan Hill",
    "city_ascii": "Morgan Hill",
    "lat": 37.1326,
    "lng": -121.6417,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 45952,
    "id": 1840020331
  },
  {
    "city": "Ciudad Constitución",
    "city_ascii": "Ciudad Constitucion",
    "lat": 25.0322,
    "lng": -111.6703,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Baja California Sur",
    "capital": "minor",
    "population": 45888,
    "id": 1484811681
  },
  {
    "city": "Haymana",
    "city_ascii": "Haymana",
    "lat": 39.4311,
    "lng": 32.4956,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Ankara",
    "capital": "minor",
    "population": 45931,
    "id": 1792299517
  },
  {
    "city": "Pinehurst",
    "city_ascii": "Pinehurst",
    "lat": 35.1922,
    "lng": -79.4684,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 45935,
    "id": 1840017852
  },
  {
    "city": "Chippenham",
    "city_ascii": "Chippenham",
    "lat": 51.4585,
    "lng": -2.1158,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Wiltshire",
    "capital": "",
    "population": 45620,
    "id": 1826463257
  },
  {
    "city": "Rodgau",
    "city_ascii": "Rodgau",
    "lat": 50.0256,
    "lng": 8.8841,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 45202,
    "id": 1276173536
  },
  {
    "city": "Winchester",
    "city_ascii": "Winchester",
    "lat": 51.0632,
    "lng": -1.308,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hampshire",
    "capital": "",
    "population": 45184,
    "id": 1826967267
  },
  {
    "city": "Danville",
    "city_ascii": "Danville",
    "lat": 36.5831,
    "lng": -79.4087,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 45890,
    "id": 1840003878
  },
  {
    "city": "Rockwall",
    "city_ascii": "Rockwall",
    "lat": 32.9169,
    "lng": -96.4377,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 45888,
    "id": 1840020717
  },
  {
    "city": "Dracena",
    "city_ascii": "Dracena",
    "lat": -21.4825,
    "lng": -51.5328,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 45847,
    "id": 1076313481
  },
  {
    "city": "Salvador",
    "city_ascii": "Salvador",
    "lat": 20.2094,
    "lng": -75.2231,
    "country": "Cuba",
    "iso2": "CU",
    "iso3": "CUB",
    "admin_name": "Guantánamo",
    "capital": "",
    "population": 45773,
    "id": 1192289644
  },
  {
    "city": "Stouffville",
    "city_ascii": "Stouffville",
    "lat": 43.9667,
    "lng": -79.25,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 45837,
    "id": 1124207594
  },
  {
    "city": "Potomac",
    "city_ascii": "Potomac",
    "lat": 39.0141,
    "lng": -77.1943,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 45824,
    "id": 1840005837
  },
  {
    "city": "Coachella",
    "city_ascii": "Coachella",
    "lat": 33.6905,
    "lng": -116.143,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 45743,
    "id": 1840019294
  },
  {
    "city": "Olhão",
    "city_ascii": "Olhao",
    "lat": 37.0278,
    "lng": -7.8389,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Faro",
    "capital": "minor",
    "population": 45396,
    "id": 1620090779
  },
  {
    "city": "Salcedo",
    "city_ascii": "Salcedo",
    "lat": 19.383,
    "lng": -70.4167,
    "country": "Dominican Republic",
    "iso2": "DO",
    "iso3": "DOM",
    "admin_name": "Cibao Nordeste",
    "capital": "minor",
    "population": 45299,
    "id": 1214901060
  },
  {
    "city": "Tateyama",
    "city_ascii": "Tateyama",
    "lat": 34.9967,
    "lng": 139.87,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Chiba",
    "capital": "",
    "population": 45300,
    "id": 1392021641
  },
  {
    "city": "Les Sables-d’Olonne",
    "city_ascii": "Les Sables-d'Olonne",
    "lat": 46.4972,
    "lng": -1.7833,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Pays de la Loire",
    "capital": "minor",
    "population": 44017,
    "id": 1250216385
  },
  {
    "city": "Sugito",
    "city_ascii": "Sugito",
    "lat": 36.0256,
    "lng": 139.7367,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Saitama",
    "capital": "",
    "population": 44202,
    "id": 1392687302
  },
  {
    "city": "Rāmeswaram",
    "city_ascii": "Rameswaram",
    "lat": 9.28,
    "lng": 79.3,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Tamil Nādu",
    "capital": "",
    "population": 44856,
    "id": 1356465756
  },
  {
    "city": "Albstadt",
    "city_ascii": "Albstadt",
    "lat": 48.2119,
    "lng": 9.0239,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 45327,
    "id": 1276969750
  },
  {
    "city": "Inabe",
    "city_ascii": "Inabe",
    "lat": 35.1158,
    "lng": 136.5614,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Mie",
    "capital": "",
    "population": 45454,
    "id": 1392110591
  },
  {
    "city": "Cuautepec de Hinojosa",
    "city_ascii": "Cuautepec de Hinojosa",
    "lat": 20.15,
    "lng": -98.4333,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Hidalgo",
    "capital": "minor",
    "population": 45527,
    "id": 1484413126
  },
  {
    "city": "Wake Forest",
    "city_ascii": "Wake Forest",
    "lat": 35.9629,
    "lng": -78.5141,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 45629,
    "id": 1840016199
  },
  {
    "city": "Saint-Germain-en-Laye",
    "city_ascii": "Saint-Germain-en-Laye",
    "lat": 48.8989,
    "lng": 2.0938,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "minor",
    "population": 44753,
    "id": 1250764383
  },
  {
    "city": "Brühl",
    "city_ascii": "Bruhl",
    "lat": 50.8333,
    "lng": 6.9,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 44397,
    "id": 1276307796
  },
  {
    "city": "El Dorado Hills",
    "city_ascii": "El Dorado Hills",
    "lat": 38.675,
    "lng": -121.049,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 45599,
    "id": 1840017565
  },
  {
    "city": "Beaufort West",
    "city_ascii": "Beaufort West",
    "lat": -32.35,
    "lng": 22.5833,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "Western Cape",
    "capital": "",
    "population": 44737,
    "id": 1710940488
  },
  {
    "city": "Biella",
    "city_ascii": "Biella",
    "lat": 45.5664,
    "lng": 8.0533,
    "country": "Italy",
    "iso2": "IT",
    "iso3": "ITA",
    "admin_name": "Piedmont",
    "capital": "minor",
    "population": 44616,
    "id": 1380214136
  },
  {
    "city": "Radā‘",
    "city_ascii": "Rada`",
    "lat": 14.4295,
    "lng": 44.8341,
    "country": "Yemen",
    "iso2": "YE",
    "iso3": "YEM",
    "admin_name": "Al Bayḑā’",
    "capital": "minor",
    "population": 45233,
    "id": 1887090855
  },
  {
    "city": "Aliwal North",
    "city_ascii": "Aliwal North",
    "lat": -30.7,
    "lng": 26.7,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "Eastern Cape",
    "capital": "",
    "population": 44436,
    "id": 1710445896
  },
  {
    "city": "Châteauroux",
    "city_ascii": "Chateauroux",
    "lat": 46.8103,
    "lng": 1.6911,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Centre-Val de Loire",
    "capital": "minor",
    "population": 43741,
    "id": 1250603472
  },
  {
    "city": "Wallsend",
    "city_ascii": "Wallsend",
    "lat": 54.991,
    "lng": -1.534,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "North Tyneside",
    "capital": "",
    "population": 43826,
    "id": 1826171317
  },
  {
    "city": "Olbia",
    "city_ascii": "Olbia",
    "lat": 40.9143,
    "lng": 9.5151,
    "country": "Italy",
    "iso2": "IT",
    "iso3": "ITA",
    "admin_name": "Sardegna",
    "capital": "minor",
    "population": 45366,
    "id": 1380424474
  },
  {
    "city": "Hagi",
    "city_ascii": "Hagi",
    "lat": 34.4078,
    "lng": 131.3989,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Yamaguchi",
    "capital": "",
    "population": 45435,
    "id": 1392685567
  },
  {
    "city": "Cerquilho Velho",
    "city_ascii": "Cerquilho Velho",
    "lat": -23.165,
    "lng": -47.7436,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 45142,
    "id": 1076117670
  },
  {
    "city": "Kamen’-na-Obi",
    "city_ascii": "Kamen'-na-Obi",
    "lat": 53.7919,
    "lng": 81.3486,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Altayskiy Kray",
    "capital": "",
    "population": 44564,
    "id": 1643009870
  },
  {
    "city": "Tupelo",
    "city_ascii": "Tupelo",
    "lat": 34.2692,
    "lng": -88.7318,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Mississippi",
    "capital": "",
    "population": 45466,
    "id": 1840015561
  },
  {
    "city": "Haugesund",
    "city_ascii": "Haugesund",
    "lat": 59.4164,
    "lng": 5.27,
    "country": "Norway",
    "iso2": "NO",
    "iso3": "NOR",
    "admin_name": "Rogaland",
    "capital": "minor",
    "population": 45040,
    "id": 1578805813
  },
  {
    "city": "Carnot",
    "city_ascii": "Carnot",
    "lat": 4.9333,
    "lng": 15.8667,
    "country": "Central African Republic",
    "iso2": "CF",
    "iso3": "CAF",
    "admin_name": "Mambéré-Kadéï",
    "capital": "",
    "population": 45421,
    "id": 1140319605
  },
  {
    "city": "Birāk",
    "city_ascii": "Birak",
    "lat": 27.5333,
    "lng": 14.2834,
    "country": "Libya",
    "iso2": "LY",
    "iso3": "LBY",
    "admin_name": "Wādī ash Shāţi’",
    "capital": "",
    "population": 45421,
    "id": 1434095842
  },
  {
    "city": "Pinamar",
    "city_ascii": "Pinamar",
    "lat": -37.1,
    "lng": -56.85,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires",
    "capital": "minor",
    "population": 45000,
    "id": 1032950633
  },
  {
    "city": "Georgina",
    "city_ascii": "Georgina",
    "lat": 44.3,
    "lng": -79.4333,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 45418,
    "id": 1124000048
  },
  {
    "city": "Imbituba",
    "city_ascii": "Imbituba",
    "lat": -28.2296,
    "lng": -48.66,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Santa Catarina",
    "capital": "",
    "population": 43450,
    "id": 1076391605
  },
  {
    "city": "Masuda",
    "city_ascii": "Masuda",
    "lat": 34.675,
    "lng": 131.8428,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Shimane",
    "capital": "",
    "population": 45291,
    "id": 1392671725
  },
  {
    "city": "Coatbridge",
    "city_ascii": "Coatbridge",
    "lat": 55.8625,
    "lng": -4.0266,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "North Lanarkshire",
    "capital": "",
    "population": 43920,
    "id": 1826462023
  },
  {
    "city": "West Seneca",
    "city_ascii": "West Seneca",
    "lat": 42.8374,
    "lng": -78.7509,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 45332,
    "id": 1840058604
  },
  {
    "city": "Svitlovods’k",
    "city_ascii": "Svitlovods'k",
    "lat": 49.0833,
    "lng": 33.25,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Kirovohrads’ka Oblast’",
    "capital": "minor",
    "population": 45312,
    "id": 1804885566
  },
  {
    "city": "Victoriaville",
    "city_ascii": "Victoriaville",
    "lat": 46.05,
    "lng": -71.9667,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 45309,
    "id": 1124149787
  },
  {
    "city": "Ruzayevka",
    "city_ascii": "Ruzayevka",
    "lat": 54.0667,
    "lng": 44.95,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Mordoviya",
    "capital": "",
    "population": 45248,
    "id": 1643836574
  },
  {
    "city": "Actopan",
    "city_ascii": "Actopan",
    "lat": 19.5036,
    "lng": -96.6192,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Veracruz",
    "capital": "minor",
    "population": 43388,
    "id": 1484594634
  },
  {
    "city": "South Brunswick",
    "city_ascii": "South Brunswick",
    "lat": 40.384,
    "lng": -74.5256,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 45255,
    "id": 1840081692
  },
  {
    "city": "Attleboro",
    "city_ascii": "Attleboro",
    "lat": 41.9311,
    "lng": -71.295,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 45237,
    "id": 1840000496
  },
  {
    "city": "Tank",
    "city_ascii": "Tank",
    "lat": 32.2167,
    "lng": 70.3833,
    "country": "Pakistan",
    "iso2": "PK",
    "iso3": "PAK",
    "admin_name": "Khyber Pakhtunkhwa",
    "capital": "minor",
    "population": 44996,
    "id": 1586200762
  },
  {
    "city": "Pine Bluff",
    "city_ascii": "Pine Bluff",
    "lat": 34.2116,
    "lng": -92.0178,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arkansas",
    "capital": "",
    "population": 45204,
    "id": 1840014699
  },
  {
    "city": "Evesham",
    "city_ascii": "Evesham",
    "lat": 39.8605,
    "lng": -74.8947,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 45193,
    "id": 1840081613
  },
  {
    "city": "Dimitrovgrad",
    "city_ascii": "Dimitrovgrad",
    "lat": 42.0611,
    "lng": 25.5933,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Haskovo",
    "capital": "",
    "population": 45098,
    "id": 1100570821
  },
  {
    "city": "Marion",
    "city_ascii": "Marion",
    "lat": 40.5933,
    "lng": -83.1237,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 45181,
    "id": 1840008332
  },
  {
    "city": "‘Afula",
    "city_ascii": "`Afula",
    "lat": 32.6078,
    "lng": 35.2897,
    "country": "Israel",
    "iso2": "IL",
    "iso3": "ISR",
    "admin_name": "Northern",
    "capital": "",
    "population": 44930,
    "id": 1376077681
  },
  {
    "city": "Winneba",
    "city_ascii": "Winneba",
    "lat": 5.35,
    "lng": -0.6333,
    "country": "Ghana",
    "iso2": "GH",
    "iso3": "GHA",
    "admin_name": "Central",
    "capital": "",
    "population": 44254,
    "id": 1288886612
  },
  {
    "city": "Middletown Township",
    "city_ascii": "Middletown Township",
    "lat": 40.179,
    "lng": -74.9059,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 45156,
    "id": 1840152880
  },
  {
    "city": "Zhovti Vody",
    "city_ascii": "Zhovti Vody",
    "lat": 48.35,
    "lng": 33.5167,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Dnipropetrovs’ka Oblast’",
    "capital": "",
    "population": 44766,
    "id": 1804788479
  },
  {
    "city": "Sète",
    "city_ascii": "Sete",
    "lat": 43.4053,
    "lng": 3.6975,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Occitanie",
    "capital": "",
    "population": 43229,
    "id": 1250599370
  },
  {
    "city": "Bruchsal",
    "city_ascii": "Bruchsal",
    "lat": 49.1333,
    "lng": 8.6,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 44644,
    "id": 1276034362
  },
  {
    "city": "Grantham",
    "city_ascii": "Grantham",
    "lat": 52.918,
    "lng": -0.638,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Lincolnshire",
    "capital": "",
    "population": 44580,
    "id": 1826040909
  },
  {
    "city": "Buenos Aires",
    "city_ascii": "Buenos Aires",
    "lat": 9.1497,
    "lng": -83.3334,
    "country": "Costa Rica",
    "iso2": "CR",
    "iso3": "CRI",
    "admin_name": "Puntarenas",
    "capital": "",
    "population": 45000,
    "id": 1188382290
  },
  {
    "city": "Himi",
    "city_ascii": "Himi",
    "lat": 36.85,
    "lng": 136.9833,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Toyama",
    "capital": "",
    "population": 44874,
    "id": 1392258347
  },
  {
    "city": "Kimry",
    "city_ascii": "Kimry",
    "lat": 56.8667,
    "lng": 37.35,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Tverskaya Oblast’",
    "capital": "",
    "population": 44743,
    "id": 1643056082
  },
  {
    "city": "Shaḩḩāt",
    "city_ascii": "Shahhat",
    "lat": 32.8281,
    "lng": 21.8622,
    "country": "Libya",
    "iso2": "LY",
    "iso3": "LBY",
    "admin_name": "Al Jabal al Akhḑar",
    "capital": "",
    "population": 45000,
    "id": 1434402597
  },
  {
    "city": "Shchūchīnsk",
    "city_ascii": "Shchuchinsk",
    "lat": 52.9333,
    "lng": 70.2,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Aqmola",
    "capital": "",
    "population": 45004,
    "id": 1398291807
  },
  {
    "city": "Parma",
    "city_ascii": "Parma",
    "lat": 65.923,
    "lng": 57.403,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Komi",
    "capital": "",
    "population": 45000,
    "id": 1643982067
  },
  {
    "city": "Sidcup",
    "city_ascii": "Sidcup",
    "lat": 51.4263,
    "lng": 0.1024,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Bexley",
    "capital": "",
    "population": 43109,
    "id": 1826363364
  },
  {
    "city": "Yatağan",
    "city_ascii": "Yatagan",
    "lat": 37.3425,
    "lng": 28.1393,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Muğla",
    "capital": "minor",
    "population": 44940,
    "id": 1792053956
  },
  {
    "city": "Kaufbeuren",
    "city_ascii": "Kaufbeuren",
    "lat": 47.88,
    "lng": 10.6225,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "minor",
    "population": 43893,
    "id": 1276496301
  },
  {
    "city": "Pederneiras",
    "city_ascii": "Pederneiras",
    "lat": -22.3517,
    "lng": -48.775,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 44910,
    "id": 1076724688
  },
  {
    "city": "Rasskazovo",
    "city_ascii": "Rasskazovo",
    "lat": 52.6667,
    "lng": 41.8833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Tambovskaya Oblast’",
    "capital": "",
    "population": 43758,
    "id": 1643014647
  },
  {
    "city": "Nadym",
    "city_ascii": "Nadym",
    "lat": 65.5333,
    "lng": 72.5167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Yamalo-Nenetskiy Avtonomnyy Okrug",
    "capital": "",
    "population": 44940,
    "id": 1643709121
  },
  {
    "city": "Dráma",
    "city_ascii": "Drama",
    "lat": 41.1514,
    "lng": 24.1392,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Anatolikí Makedonía kai Thráki",
    "capital": "minor",
    "population": 44823,
    "id": 1300779088
  },
  {
    "city": "Vallenar",
    "city_ascii": "Vallenar",
    "lat": -28.57,
    "lng": -70.76,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Atacama",
    "capital": "minor",
    "population": 44895,
    "id": 1152000009
  },
  {
    "city": "Dunakeszi",
    "city_ascii": "Dunakeszi",
    "lat": 47.6297,
    "lng": 19.1381,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Pest",
    "capital": "minor",
    "population": 43490,
    "id": 1348178190
  },
  {
    "city": "Pemangkat",
    "city_ascii": "Pemangkat",
    "lat": 1.1727,
    "lng": 108.9808,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Kalimantan Barat",
    "capital": "",
    "population": 44783,
    "id": 1360279205
  },
  {
    "city": "Volkhov",
    "city_ascii": "Volkhov",
    "lat": 59.9167,
    "lng": 32.35,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Leningradskaya Oblast’",
    "capital": "",
    "population": 44868,
    "id": 1643547178
  },
  {
    "city": "Mount Pleasant",
    "city_ascii": "Mount Pleasant",
    "lat": 41.112,
    "lng": -73.8122,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 44873,
    "id": 1840058325
  },
  {
    "city": "Lufkin",
    "city_ascii": "Lufkin",
    "lat": 31.3217,
    "lng": -94.7277,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 44867,
    "id": 1840020840
  },
  {
    "city": "Darcheh",
    "city_ascii": "Darcheh",
    "lat": 32.6153,
    "lng": 51.5556,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Eşfahān",
    "capital": "",
    "population": 43183,
    "id": 1364123646
  },
  {
    "city": "Middleton",
    "city_ascii": "Middleton",
    "lat": 53.555,
    "lng": -2.187,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Rochdale",
    "capital": "",
    "population": 42972,
    "id": 1826160201
  },
  {
    "city": "Falkensee",
    "city_ascii": "Falkensee",
    "lat": 52.5583,
    "lng": 13.0917,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Brandenburg",
    "capital": "",
    "population": 43838,
    "id": 1276126886
  },
  {
    "city": "Paraguaçu Paulista",
    "city_ascii": "Paraguacu Paulista",
    "lat": -22.4197,
    "lng": -50.5797,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 44794,
    "id": 1076783045
  },
  {
    "city": "Bexhill",
    "city_ascii": "Bexhill",
    "lat": 50.8424,
    "lng": 0.4676,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "East Sussex",
    "capital": "",
    "population": 43478,
    "id": 1826536144
  },
  {
    "city": "Shinshiro",
    "city_ascii": "Shinshiro",
    "lat": 34.9,
    "lng": 137.5,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Aichi",
    "capital": "",
    "population": 44713,
    "id": 1392488920
  },
  {
    "city": "Chusovoy",
    "city_ascii": "Chusovoy",
    "lat": 58.2833,
    "lng": 57.8167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Permskiy Kray",
    "capital": "",
    "population": 44779,
    "id": 1643341477
  },
  {
    "city": "Oranienburg",
    "city_ascii": "Oranienburg",
    "lat": 52.7544,
    "lng": 13.2369,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Brandenburg",
    "capital": "minor",
    "population": 44512,
    "id": 1276677137
  },
  {
    "city": "Jisr ash Shughūr",
    "city_ascii": "Jisr ash Shughur",
    "lat": 35.8,
    "lng": 36.3167,
    "country": "Syria",
    "iso2": "SY",
    "iso3": "SYR",
    "admin_name": "Idlib",
    "capital": "minor",
    "population": 44322,
    "id": 1760389710
  },
  {
    "city": "Wallingford",
    "city_ascii": "Wallingford",
    "lat": 41.4587,
    "lng": -72.8039,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 44771,
    "id": 1840045095
  },
  {
    "city": "Manitowoc",
    "city_ascii": "Manitowoc",
    "lat": 44.0991,
    "lng": -87.6811,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 44770,
    "id": 1840002213
  },
  {
    "city": "Prostějov",
    "city_ascii": "Prostejov",
    "lat": 49.4722,
    "lng": 17.1106,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Olomoucký Kraj",
    "capital": "",
    "population": 43651,
    "id": 1203172907
  },
  {
    "city": "Nidadavole",
    "city_ascii": "Nidadavole",
    "lat": 16.92,
    "lng": 81.67,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Andhra Pradesh",
    "capital": "",
    "population": 43809,
    "id": 1356862958
  },
  {
    "city": "Sandefjord",
    "city_ascii": "Sandefjord",
    "lat": 59.1312,
    "lng": 10.2166,
    "country": "Norway",
    "iso2": "NO",
    "iso3": "NOR",
    "admin_name": "Vestfold",
    "capital": "",
    "population": 44368,
    "id": 1578389701
  },
  {
    "city": "Taketoyo",
    "city_ascii": "Taketoyo",
    "lat": 34.8514,
    "lng": 136.915,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Aichi",
    "capital": "",
    "population": 43113,
    "id": 1392393815
  },
  {
    "city": "Bridgewater",
    "city_ascii": "Bridgewater",
    "lat": 40.5934,
    "lng": -74.6076,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 44726,
    "id": 1840081701
  },
  {
    "city": "Selma",
    "city_ascii": "Selma",
    "lat": 36.5715,
    "lng": -119.6143,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 44703,
    "id": 1840021567
  },
  {
    "city": "Strongsville",
    "city_ascii": "Strongsville",
    "lat": 41.3128,
    "lng": -81.8313,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 44660,
    "id": 1840003415
  },
  {
    "city": "Louveira",
    "city_ascii": "Louveira",
    "lat": -23.0864,
    "lng": -46.9506,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 43862,
    "id": 1076393293
  },
  {
    "city": "Seferhisar",
    "city_ascii": "Seferhisar",
    "lat": 38.1975,
    "lng": 26.8388,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "İzmir",
    "capital": "minor",
    "population": 44526,
    "id": 1792680946
  },
  {
    "city": "Garça",
    "city_ascii": "Garca",
    "lat": -22.2153,
    "lng": -49.6511,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 44532,
    "id": 1076270928
  },
  {
    "city": "Vernon",
    "city_ascii": "Vernon",
    "lat": 50.267,
    "lng": -119.272,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "British Columbia",
    "capital": "",
    "population": 44600,
    "id": 1124553338
  },
  {
    "city": "Kaarst",
    "city_ascii": "Kaarst",
    "lat": 51.2278,
    "lng": 6.6273,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 43433,
    "id": 1276322915
  },
  {
    "city": "Tiberias",
    "city_ascii": "Tiberias",
    "lat": 32.7897,
    "lng": 35.5247,
    "country": "Israel",
    "iso2": "IL",
    "iso3": "ISR",
    "admin_name": "Northern",
    "capital": "",
    "population": 44200,
    "id": 1376017086
  },
  {
    "city": "Abovyan",
    "city_ascii": "Abovyan",
    "lat": 40.2739,
    "lng": 44.6256,
    "country": "Armenia",
    "iso2": "AM",
    "iso3": "ARM",
    "admin_name": "Kotayk’",
    "capital": "",
    "population": 44400,
    "id": 1051509043
  },
  {
    "city": "Danville",
    "city_ascii": "Danville",
    "lat": 37.8121,
    "lng": -121.9698,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 44510,
    "id": 1840021532
  },
  {
    "city": "Makinohara",
    "city_ascii": "Makinohara",
    "lat": 34.74,
    "lng": 138.2247,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Shizuoka",
    "capital": "",
    "population": 44111,
    "id": 1392331994
  },
  {
    "city": "Petatlán",
    "city_ascii": "Petatlan",
    "lat": 17.5383,
    "lng": -101.2739,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Guerrero",
    "capital": "minor",
    "population": 44485,
    "id": 1484714363
  },
  {
    "city": "Bietigheim-Bissingen",
    "city_ascii": "Bietigheim-Bissingen",
    "lat": 48.9667,
    "lng": 9.1333,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 43093,
    "id": 1276916733
  },
  {
    "city": "Otofuke",
    "city_ascii": "Otofuke",
    "lat": 42.9942,
    "lng": 143.1981,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hokkaidō",
    "capital": "",
    "population": 44377,
    "id": 1392138807
  },
  {
    "city": "Quincy",
    "city_ascii": "Quincy",
    "lat": 39.9335,
    "lng": -91.3799,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 44466,
    "id": 1840009466
  },
  {
    "city": "Memmingen",
    "city_ascii": "Memmingen",
    "lat": 47.9878,
    "lng": 10.1811,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "minor",
    "population": 43837,
    "id": 1276229376
  },
  {
    "city": "Enfield",
    "city_ascii": "Enfield",
    "lat": 41.9839,
    "lng": -72.5548,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 44455,
    "id": 1840033630
  },
  {
    "city": "Lancaster",
    "city_ascii": "Lancaster",
    "lat": 39.7249,
    "lng": -82.6049,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 44447,
    "id": 1840003781
  },
  {
    "city": "Riverton",
    "city_ascii": "Riverton",
    "lat": 40.5177,
    "lng": -111.9635,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Utah",
    "capital": "",
    "population": 44440,
    "id": 1840020157
  },
  {
    "city": "The Colony",
    "city_ascii": "The Colony",
    "lat": 33.0925,
    "lng": -96.8977,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 44438,
    "id": 1840022005
  },
  {
    "city": "Lake Ridge",
    "city_ascii": "Lake Ridge",
    "lat": 38.6843,
    "lng": -77.3059,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 44430,
    "id": 1840006099
  },
  {
    "city": "Neustadt am Rübenberge",
    "city_ascii": "Neustadt am Rubenberge",
    "lat": 52.5055,
    "lng": 9.4636,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 44282,
    "id": 1276887904
  },
  {
    "city": "Urbandale",
    "city_ascii": "Urbandale",
    "lat": 41.639,
    "lng": -93.7813,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Iowa",
    "capital": "",
    "population": 44379,
    "id": 1840010184
  },
  {
    "city": "Lehrte",
    "city_ascii": "Lehrte",
    "lat": 52.3725,
    "lng": 9.9769,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 43999,
    "id": 1276716840
  },
  {
    "city": "Volksrust",
    "city_ascii": "Volksrust",
    "lat": -27.3667,
    "lng": 29.8833,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "Mpumalanga",
    "capital": "",
    "population": 43378,
    "id": 1710664906
  },
  {
    "city": "Plainfield",
    "city_ascii": "Plainfield",
    "lat": 41.6206,
    "lng": -88.2252,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 44308,
    "id": 1840011495
  },
  {
    "city": "Lombard",
    "city_ascii": "Lombard",
    "lat": 41.8742,
    "lng": -88.0157,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 44303,
    "id": 1840011402
  },
  {
    "city": "Prescott",
    "city_ascii": "Prescott",
    "lat": 34.585,
    "lng": -112.4475,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 44299,
    "id": 1840020436
  },
  {
    "city": "Dikili",
    "city_ascii": "Dikili",
    "lat": 39.071,
    "lng": 26.8902,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "İzmir",
    "capital": "minor",
    "population": 44172,
    "id": 1792392506
  },
  {
    "city": "Korogwe",
    "city_ascii": "Korogwe",
    "lat": -5.1558,
    "lng": 38.4503,
    "country": "Tanzania",
    "iso2": "TZ",
    "iso3": "TZA",
    "admin_name": "Tanga",
    "capital": "",
    "population": 44000,
    "id": 1834605335
  },
  {
    "city": "Krasnodon",
    "city_ascii": "Krasnodon",
    "lat": 48.3,
    "lng": 39.7333,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Luhans’ka Oblast’",
    "capital": "minor",
    "population": 43683,
    "id": 1804346804
  },
  {
    "city": "Webster",
    "city_ascii": "Webster",
    "lat": 43.2294,
    "lng": -77.4454,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 44247,
    "id": 1840004286
  },
  {
    "city": "Eureka",
    "city_ascii": "Eureka",
    "lat": 40.7942,
    "lng": -124.1568,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 44236,
    "id": 1840009558
  },
  {
    "city": "Addanki",
    "city_ascii": "Addanki",
    "lat": 15.811,
    "lng": 79.9738,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Andhra Pradesh",
    "capital": "",
    "population": 43850,
    "id": 1356837121
  },
  {
    "city": "Catacamas",
    "city_ascii": "Catacamas",
    "lat": 14.8,
    "lng": -85.9,
    "country": "Honduras",
    "iso2": "HN",
    "iso3": "HND",
    "admin_name": "Olancho",
    "capital": "",
    "population": 44198,
    "id": 1340898308
  },
  {
    "city": "Kuybyshev",
    "city_ascii": "Kuybyshev",
    "lat": 55.4503,
    "lng": 78.3075,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Novosibirskaya Oblast’",
    "capital": "",
    "population": 44170,
    "id": 1643017442
  },
  {
    "city": "Sayreville",
    "city_ascii": "Sayreville",
    "lat": 40.4656,
    "lng": -74.3237,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 44173,
    "id": 1840001332
  },
  {
    "city": "Saint-Eustache",
    "city_ascii": "Saint-Eustache",
    "lat": 45.57,
    "lng": -73.9,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 44154,
    "id": 1124758162
  },
  {
    "city": "Altamonte Springs",
    "city_ascii": "Altamonte Springs",
    "lat": 28.6615,
    "lng": -81.3953,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 44143,
    "id": 1840014072
  },
  {
    "city": "Yartsevo",
    "city_ascii": "Yartsevo",
    "lat": 55.0667,
    "lng": 32.6833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Smolenskaya Oblast’",
    "capital": "",
    "population": 44097,
    "id": 1643013101
  },
  {
    "city": "Del Rio",
    "city_ascii": "Del Rio",
    "lat": 29.3708,
    "lng": -100.88,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 44121,
    "id": 1840019607
  },
  {
    "city": "Kobayashi",
    "city_ascii": "Kobayashi",
    "lat": 31.9967,
    "lng": 130.9728,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Miyazaki",
    "capital": "",
    "population": 44034,
    "id": 1392148091
  },
  {
    "city": "Voluntari",
    "city_ascii": "Voluntari",
    "lat": 44.4925,
    "lng": 26.1914,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Bucureşti",
    "capital": "",
    "population": 42944,
    "id": 1642832091
  },
  {
    "city": "Kaštel Stari",
    "city_ascii": "Kastel Stari",
    "lat": 43.5483,
    "lng": 16.3383,
    "country": "Croatia",
    "iso2": "HR",
    "iso3": "HRV",
    "admin_name": "Splitsko-Dalmatinska Županija",
    "capital": "",
    "population": 43349,
    "id": 1191355987
  },
  {
    "city": "Shimabara",
    "city_ascii": "Shimabara",
    "lat": 32.7756,
    "lng": 130.3381,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Nagasaki",
    "capital": "",
    "population": 43553,
    "id": 1392371558
  },
  {
    "city": "Pantanal",
    "city_ascii": "Pantanal",
    "lat": 18.5103,
    "lng": -68.3694,
    "country": "Dominican Republic",
    "iso2": "DO",
    "iso3": "DOM",
    "admin_name": "Yuma",
    "capital": "",
    "population": 43982,
    "id": 1214144437
  },
  {
    "city": "Ogimachi",
    "city_ascii": "Ogimachi",
    "lat": 33.2506,
    "lng": 130.2017,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Saga",
    "capital": "",
    "population": 43617,
    "id": 1392963312
  },
  {
    "city": "Comendador",
    "city_ascii": "Comendador",
    "lat": 18.876,
    "lng": -71.707,
    "country": "Dominican Republic",
    "iso2": "DO",
    "iso3": "DOM",
    "admin_name": "El Valle",
    "capital": "minor",
    "population": 43894,
    "id": 1214626724
  },
  {
    "city": "Kamen",
    "city_ascii": "Kamen",
    "lat": 51.5917,
    "lng": 7.6653,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 42971,
    "id": 1276465116
  },
  {
    "city": "Naţanz",
    "city_ascii": "Natanz",
    "lat": 33.5,
    "lng": 51.9,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Eşfahān",
    "capital": "minor",
    "population": 44000,
    "id": 1364002460
  },
  {
    "city": "Pinhal",
    "city_ascii": "Pinhal",
    "lat": -22.1908,
    "lng": -46.7408,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 43897,
    "id": 1076552956
  },
  {
    "city": "Zongolica",
    "city_ascii": "Zongolica",
    "lat": 18.6642,
    "lng": -97.0011,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Veracruz",
    "capital": "minor",
    "population": 43871,
    "id": 1484604711
  },
  {
    "city": "Bountiful",
    "city_ascii": "Bountiful",
    "lat": 40.8722,
    "lng": -111.8647,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Utah",
    "capital": "",
    "population": 43981,
    "id": 1840018737
  },
  {
    "city": "Desert Hot Springs",
    "city_ascii": "Desert Hot Springs",
    "lat": 33.9551,
    "lng": -116.543,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 43914,
    "id": 1840019306
  },
  {
    "city": "Nabire",
    "city_ascii": "Nabire",
    "lat": -3.3515,
    "lng": 135.5134,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Papua",
    "capital": "minor",
    "population": 43898,
    "id": 1360322221
  },
  {
    "city": "Peachtree Corners",
    "city_ascii": "Peachtree Corners",
    "lat": 33.967,
    "lng": -84.2319,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 43905,
    "id": 1840029447
  },
  {
    "city": "Haltom City",
    "city_ascii": "Haltom City",
    "lat": 32.8176,
    "lng": -97.2707,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 43874,
    "id": 1840020698
  },
  {
    "city": "Castro",
    "city_ascii": "Castro",
    "lat": -24.7893,
    "lng": -50.0123,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Paraná",
    "capital": "",
    "population": 42091,
    "id": 1076233349
  },
  {
    "city": "Bāniyās",
    "city_ascii": "Baniyas",
    "lat": 35.1822,
    "lng": 35.9403,
    "country": "Syria",
    "iso2": "SY",
    "iso3": "SYR",
    "admin_name": "Ţarţūs",
    "capital": "minor",
    "population": 43151,
    "id": 1760780974
  },
  {
    "city": "Batouri",
    "city_ascii": "Batouri",
    "lat": 4.4333,
    "lng": 14.3667,
    "country": "Cameroon",
    "iso2": "CM",
    "iso3": "CMR",
    "admin_name": "Est",
    "capital": "",
    "population": 43821,
    "id": 1120380999
  },
  {
    "city": "Monroe",
    "city_ascii": "Monroe",
    "lat": 40.3191,
    "lng": -74.4286,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 43828,
    "id": 1840056354
  },
  {
    "city": "Ad Dir‘īyah",
    "city_ascii": "Ad Dir`iyah",
    "lat": 24.7333,
    "lng": 46.5756,
    "country": "Saudi Arabia",
    "iso2": "SA",
    "iso3": "SAU",
    "admin_name": "Ar Riyāḑ",
    "capital": "",
    "population": 43269,
    "id": 1682421969
  },
  {
    "city": "Southington",
    "city_ascii": "Southington",
    "lat": 41.605,
    "lng": -72.8802,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 43763,
    "id": 1840035601
  },
  {
    "city": "Worksop",
    "city_ascii": "Worksop",
    "lat": 53.3042,
    "lng": -1.1244,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Nottinghamshire",
    "capital": "",
    "population": 41820,
    "id": 1826731609
  },
  {
    "city": "Ormond Beach",
    "city_ascii": "Ormond Beach",
    "lat": 29.296,
    "lng": -81.1003,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 43759,
    "id": 1840015076
  },
  {
    "city": "Sambava",
    "city_ascii": "Sambava",
    "lat": -14.2662,
    "lng": 50.1666,
    "country": "Madagascar",
    "iso2": "MG",
    "iso3": "MDG",
    "admin_name": "Antsiranana",
    "capital": "minor",
    "population": 43465,
    "id": 1450004590
  },
  {
    "city": "Takāb",
    "city_ascii": "Takab",
    "lat": 36.4008,
    "lng": 47.1131,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Āz̄arbāyjān-e Gharbī",
    "capital": "minor",
    "population": 43702,
    "id": 1364000025
  },
  {
    "city": "Erkelenz",
    "city_ascii": "Erkelenz",
    "lat": 51.08,
    "lng": 6.3156,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 43364,
    "id": 1276067473
  },
  {
    "city": "Salto de Pirapora",
    "city_ascii": "Salto de Pirapora",
    "lat": -23.6489,
    "lng": -47.5728,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 43574,
    "id": 1076664356
  },
  {
    "city": "Basoko",
    "city_ascii": "Basoko",
    "lat": 1.2333,
    "lng": 23.6,
    "country": "Congo (Kinshasa)",
    "iso2": "CD",
    "iso3": "COD",
    "admin_name": "Tshopo",
    "capital": "",
    "population": 43709,
    "id": 1180509801
  },
  {
    "city": "Cutler Bay",
    "city_ascii": "Cutler Bay",
    "lat": 25.5765,
    "lng": -80.3356,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 43718,
    "id": 1840017276
  },
  {
    "city": "Of",
    "city_ascii": "Of",
    "lat": 40.945,
    "lng": 40.2644,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Trabzon",
    "capital": "minor",
    "population": 43499,
    "id": 1792121137
  },
  {
    "city": "Macenta",
    "city_ascii": "Macenta",
    "lat": 8.5504,
    "lng": -9.48,
    "country": "Guinea",
    "iso2": "GN",
    "iso3": "GIN",
    "admin_name": "N’Zérékoré",
    "capital": "minor",
    "population": 43102,
    "id": 1324817479
  },
  {
    "city": "Chefchaouene",
    "city_ascii": "Chefchaouene",
    "lat": 35.1714,
    "lng": -5.2697,
    "country": "Morocco",
    "iso2": "MA",
    "iso3": "MAR",
    "admin_name": "Tanger-Tétouan-Al Hoceïma",
    "capital": "",
    "population": 42786,
    "id": 1504471687
  },
  {
    "city": "Adrian",
    "city_ascii": "Adrian",
    "lat": 41.8994,
    "lng": -84.0446,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 43689,
    "id": 1840003226
  },
  {
    "city": "Nuevo México",
    "city_ascii": "Nuevo Mexico",
    "lat": 20.75,
    "lng": -103.433,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Jalisco",
    "capital": "",
    "population": 42246,
    "id": 1484386312
  },
  {
    "city": "Lota",
    "city_ascii": "Lota",
    "lat": -37.0944,
    "lng": -73.1563,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Biobío",
    "capital": "",
    "population": 43272,
    "id": 1152978276
  },
  {
    "city": "Angoulême",
    "city_ascii": "Angouleme",
    "lat": 45.65,
    "lng": 0.16,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Nouvelle-Aquitaine",
    "capital": "minor",
    "population": 41740,
    "id": 1250150112
  },
  {
    "city": "Otwock",
    "city_ascii": "Otwock",
    "lat": 52.1167,
    "lng": 21.2667,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Mazowieckie",
    "capital": "minor",
    "population": 42765,
    "id": 1616596983
  },
  {
    "city": "Goose Creek",
    "city_ascii": "Goose Creek",
    "lat": 32.9925,
    "lng": -80.0054,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 43665,
    "id": 1840013761
  },
  {
    "city": "Eṭ Ṭaiyiba",
    "city_ascii": "Et Taiyiba",
    "lat": 32.2667,
    "lng": 35,
    "country": "Israel",
    "iso2": "IL",
    "iso3": "ISR",
    "admin_name": "Central",
    "capital": "",
    "population": 43100,
    "id": 1376597784
  },
  {
    "city": "Moorhead",
    "city_ascii": "Moorhead",
    "lat": 46.8673,
    "lng": -96.7461,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 43652,
    "id": 1840007731
  },
  {
    "city": "Westfield",
    "city_ascii": "Westfield",
    "lat": 40.0333,
    "lng": -86.1532,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 43649,
    "id": 1840010500
  },
  {
    "city": "Santa Cruz",
    "city_ascii": "Santa Cruz",
    "lat": 32.675,
    "lng": -16.8309,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Madeira",
    "capital": "",
    "population": 43005,
    "id": 1620409939
  },
  {
    "city": "Çeşme",
    "city_ascii": "Cesme",
    "lat": 38.32,
    "lng": 26.3053,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "İzmir",
    "capital": "minor",
    "population": 43489,
    "id": 1792570179
  },
  {
    "city": "Ponte de Lima",
    "city_ascii": "Ponte de Lima",
    "lat": 41.7667,
    "lng": -8.5833,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Viana do Castelo",
    "capital": "minor",
    "population": 43498,
    "id": 1620557012
  },
  {
    "city": "Musina",
    "city_ascii": "Musina",
    "lat": -22.3381,
    "lng": 30.0417,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "Limpopo",
    "capital": "",
    "population": 42678,
    "id": 1710956370
  },
  {
    "city": "Birsk",
    "city_ascii": "Birsk",
    "lat": 55.4167,
    "lng": 55.5333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Bashkortostan",
    "capital": "",
    "population": 43572,
    "id": 1643348438
  },
  {
    "city": "Přerov",
    "city_ascii": "Prerov",
    "lat": 49.4556,
    "lng": 17.4511,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Olomoucký Kraj",
    "capital": "",
    "population": 42871,
    "id": 1203137734
  },
  {
    "city": "Quinte West",
    "city_ascii": "Quinte West",
    "lat": 44.1833,
    "lng": -77.5667,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 43577,
    "id": 1124001037
  },
  {
    "city": "Wismar",
    "city_ascii": "Wismar",
    "lat": 53.8925,
    "lng": 11.465,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Mecklenburg-Western Pomerania",
    "capital": "minor",
    "population": 42550,
    "id": 1276660084
  },
  {
    "city": "Presidente Epitácio",
    "city_ascii": "Presidente Epitacio",
    "lat": -21.7633,
    "lng": -52.1156,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 43535,
    "id": 1076557618
  },
  {
    "city": "Bahía Honda",
    "city_ascii": "Bahia Honda",
    "lat": 22.9064,
    "lng": -83.1639,
    "country": "Cuba",
    "iso2": "CU",
    "iso3": "CUB",
    "admin_name": "Artemisa",
    "capital": "minor",
    "population": 43483,
    "id": 1192503832
  },
  {
    "city": "Istres",
    "city_ascii": "Istres",
    "lat": 43.5151,
    "lng": 4.9895,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Provence-Alpes-Côte d’Azur",
    "capital": "minor",
    "population": 43133,
    "id": 1250932353
  },
  {
    "city": "Tamworth",
    "city_ascii": "Tamworth",
    "lat": -31.0833,
    "lng": 150.9167,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 42872,
    "id": 1036233388
  },
  {
    "city": "Pedro Celestino Negrete",
    "city_ascii": "Pedro Celestino Negrete",
    "lat": 24.726,
    "lng": -102.984,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Durango",
    "capital": "",
    "population": 43447,
    "id": 1484247270
  },
  {
    "city": "Zharkent",
    "city_ascii": "Zharkent",
    "lat": 44.1667,
    "lng": 80.0067,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Almaty",
    "capital": "",
    "population": 43430,
    "id": 1398507500
  },
  {
    "city": "Futtsu",
    "city_ascii": "Futtsu",
    "lat": 35.3042,
    "lng": 139.8569,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Chiba",
    "capital": "",
    "population": 43213,
    "id": 1392321461
  },
  {
    "city": "Canoinhas",
    "city_ascii": "Canoinhas",
    "lat": -26.1772,
    "lng": -50.39,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Santa Catarina",
    "capital": "",
    "population": 41498,
    "id": 1076606234
  },
  {
    "city": "Sawākin",
    "city_ascii": "Sawakin",
    "lat": 19.1,
    "lng": 37.3333,
    "country": "Sudan",
    "iso2": "SD",
    "iso3": "SDN",
    "admin_name": "Red Sea",
    "capital": "",
    "population": 43337,
    "id": 1729959085
  },
  {
    "city": "Manchester",
    "city_ascii": "Manchester",
    "lat": 39.9651,
    "lng": -74.3738,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 43373,
    "id": 1840081608
  },
  {
    "city": "Henrietta",
    "city_ascii": "Henrietta",
    "lat": 43.0555,
    "lng": -77.6413,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 43327,
    "id": 1840058179
  },
  {
    "city": "Esperanza",
    "city_ascii": "Esperanza",
    "lat": -31.4167,
    "lng": -60.9333,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Santa Fe",
    "capital": "minor",
    "population": 43300,
    "id": 1032763447
  },
  {
    "city": "Kemer",
    "city_ascii": "Kemer",
    "lat": 36.6,
    "lng": 30.55,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Antalya",
    "capital": "minor",
    "population": 43226,
    "id": 1792680664
  },
  {
    "city": "Rantepao",
    "city_ascii": "Rantepao",
    "lat": -2.969,
    "lng": 119.9,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Sulawesi Selatan",
    "capital": "minor",
    "population": 43123,
    "id": 1360797389
  },
  {
    "city": "Brea",
    "city_ascii": "Brea",
    "lat": 33.9254,
    "lng": -117.8656,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 43255,
    "id": 1840019323
  },
  {
    "city": "Klamath Falls",
    "city_ascii": "Klamath Falls",
    "lat": 42.2191,
    "lng": -121.7754,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 43246,
    "id": 1840020054
  },
  {
    "city": "Al Wajh",
    "city_ascii": "Al Wajh",
    "lat": 26.2324,
    "lng": 36.4636,
    "country": "Saudi Arabia",
    "iso2": "SA",
    "iso3": "SAU",
    "admin_name": "Tabūk",
    "capital": "",
    "population": 43237,
    "id": 1682508460
  },
  {
    "city": "King’s Lynn",
    "city_ascii": "King's Lynn",
    "lat": 52.7543,
    "lng": 0.3976,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Norfolk",
    "capital": "",
    "population": 42800,
    "id": 1826126366
  },
  {
    "city": "Siegburg",
    "city_ascii": "Siegburg",
    "lat": 50.8014,
    "lng": 7.2044,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "minor",
    "population": 41463,
    "id": 1276838674
  },
  {
    "city": "San Lorenzo",
    "city_ascii": "San Lorenzo",
    "lat": 13.3667,
    "lng": -87.2667,
    "country": "Honduras",
    "iso2": "HN",
    "iso3": "HND",
    "admin_name": "Valle",
    "capital": "",
    "population": 43042,
    "id": 1340596273
  },
  {
    "city": "Ambatondrazaka",
    "city_ascii": "Ambatondrazaka",
    "lat": -17.8329,
    "lng": 48.4167,
    "country": "Madagascar",
    "iso2": "MG",
    "iso3": "MDG",
    "admin_name": "Toamasina",
    "capital": "minor",
    "population": 43134,
    "id": 1450068349
  },
  {
    "city": "Véroia",
    "city_ascii": "Veroia",
    "lat": 40.5203,
    "lng": 22.2019,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Kentrikí Makedonía",
    "capital": "minor",
    "population": 43158,
    "id": 1300354522
  },
  {
    "city": "Nadi",
    "city_ascii": "Nadi",
    "lat": -17.8,
    "lng": 177.4167,
    "country": "Fiji",
    "iso2": "FJ",
    "iso3": "FJI",
    "admin_name": "Ba",
    "capital": "",
    "population": 42284,
    "id": 1242357822
  },
  {
    "city": "Bourg-en-Bresse",
    "city_ascii": "Bourg-en-Bresse",
    "lat": 46.2056,
    "lng": 5.2289,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Auvergne-Rhône-Alpes",
    "capital": "minor",
    "population": 41527,
    "id": 1250397174
  },
  {
    "city": "Kolchugino",
    "city_ascii": "Kolchugino",
    "lat": 56.2992,
    "lng": 39.3831,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Vladimirskaya Oblast’",
    "capital": "",
    "population": 43089,
    "id": 1643216490
  },
  {
    "city": "Jesús María",
    "city_ascii": "Jesus Maria",
    "lat": 21.9667,
    "lng": -102.35,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Aguascalientes",
    "capital": "minor",
    "population": 43012,
    "id": 1484096692
  },
  {
    "city": "Lubao",
    "city_ascii": "Lubao",
    "lat": -5.3896,
    "lng": 25.75,
    "country": "Congo (Kinshasa)",
    "iso2": "CD",
    "iso3": "COD",
    "admin_name": "Lomami",
    "capital": "",
    "population": 43068,
    "id": 1180891619
  },
  {
    "city": "Singhanakhon",
    "city_ascii": "Singhanakhon",
    "lat": 7.2389,
    "lng": 100.5506,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Songkhla",
    "capital": "minor",
    "population": 42423,
    "id": 1764839884
  },
  {
    "city": "Bihać",
    "city_ascii": "Bihac",
    "lat": 44.8167,
    "lng": 15.8667,
    "country": "Bosnia And Herzegovina",
    "iso2": "BA",
    "iso3": "BIH",
    "admin_name": "Bosnia and Herzegovina, Federation of",
    "capital": "minor",
    "population": 43007,
    "id": 1070788265
  },
  {
    "city": "Obita",
    "city_ascii": "Obita",
    "lat": 32.8253,
    "lng": 129.875,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Nagasaki",
    "capital": "",
    "population": 41630,
    "id": 1392972610
  },
  {
    "city": "Billerica",
    "city_ascii": "Billerica",
    "lat": 42.5587,
    "lng": -71.2673,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 43044,
    "id": 1840053475
  },
  {
    "city": "Yendi",
    "city_ascii": "Yendi",
    "lat": 9.4337,
    "lng": -0.0167,
    "country": "Ghana",
    "iso2": "GH",
    "iso3": "GHA",
    "admin_name": "Northern",
    "capital": "",
    "population": 42972,
    "id": 1288631491
  },
  {
    "city": "Gualeguay",
    "city_ascii": "Gualeguay",
    "lat": -33.15,
    "lng": -59.3167,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Entre Ríos",
    "capital": "minor",
    "population": 43009,
    "id": 1032077195
  },
  {
    "city": "Egg Harbor",
    "city_ascii": "Egg Harbor",
    "lat": 39.3787,
    "lng": -74.6102,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 43002,
    "id": 1840081582
  },
  {
    "city": "Nettetal",
    "city_ascii": "Nettetal",
    "lat": 51.3167,
    "lng": 6.2833,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 42493,
    "id": 1276049262
  },
  {
    "city": "Osinniki",
    "city_ascii": "Osinniki",
    "lat": 53.6167,
    "lng": 87.3333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kemerovskaya Oblast’",
    "capital": "",
    "population": 42454,
    "id": 1643107016
  },
  {
    "city": "Lancaster",
    "city_ascii": "Lancaster",
    "lat": 42.9099,
    "lng": -78.6378,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 42948,
    "id": 1840004387
  },
  {
    "city": "Sieradz",
    "city_ascii": "Sieradz",
    "lat": 51.6,
    "lng": 18.75,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Łódzkie",
    "capital": "minor",
    "population": 42120,
    "id": 1616121526
  },
  {
    "city": "Yuzawa",
    "city_ascii": "Yuzawa",
    "lat": 39.1644,
    "lng": 140.4958,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Akita",
    "capital": "",
    "population": 42887,
    "id": 1392884972
  },
  {
    "city": "Gifhorn",
    "city_ascii": "Gifhorn",
    "lat": 52.4886,
    "lng": 10.5464,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "minor",
    "population": 42519,
    "id": 1276793894
  },
  {
    "city": "Centralia",
    "city_ascii": "Centralia",
    "lat": 46.7226,
    "lng": -122.9696,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 42922,
    "id": 1840018471
  },
  {
    "city": "Gallatin",
    "city_ascii": "Gallatin",
    "lat": 36.3782,
    "lng": -86.4696,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 42918,
    "id": 1840013295
  },
  {
    "city": "Atenco",
    "city_ascii": "Atenco",
    "lat": 19.5167,
    "lng": -98.9167,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "México",
    "capital": "minor",
    "population": 42739,
    "id": 1484838122
  },
  {
    "city": "Zvolen",
    "city_ascii": "Zvolen",
    "lat": 48.5831,
    "lng": 19.1331,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Banskobystrický",
    "capital": "minor",
    "population": 42476,
    "id": 1703102070
  },
  {
    "city": "Samborondón",
    "city_ascii": "Samborondon",
    "lat": -1.9611,
    "lng": -79.7256,
    "country": "Ecuador",
    "iso2": "EC",
    "iso3": "ECU",
    "admin_name": "Guayas",
    "capital": "",
    "population": 42637,
    "id": 1218370377
  },
  {
    "city": "Dreieich",
    "city_ascii": "Dreieich",
    "lat": 50.0189,
    "lng": 8.6961,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 42091,
    "id": 1276481123
  },
  {
    "city": "Stevens Point",
    "city_ascii": "Stevens Point",
    "lat": 44.5241,
    "lng": -89.5508,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 42837,
    "id": 1840002320
  },
  {
    "city": "Nurmijärvi",
    "city_ascii": "Nurmijarvi",
    "lat": 60.4667,
    "lng": 24.8083,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Uusimaa",
    "capital": "minor",
    "population": 42709,
    "id": 1246678771
  },
  {
    "city": "Orlândia",
    "city_ascii": "Orlandia",
    "lat": -20.7203,
    "lng": -47.8867,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 42678,
    "id": 1076690245
  },
  {
    "city": "Borken",
    "city_ascii": "Borken",
    "lat": 51.8439,
    "lng": 6.8583,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "minor",
    "population": 42530,
    "id": 1276778327
  },
  {
    "city": "Brentwood",
    "city_ascii": "Brentwood",
    "lat": 35.9918,
    "lng": -86.7758,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 42783,
    "id": 1840013381
  },
  {
    "city": "Amberg",
    "city_ascii": "Amberg",
    "lat": 49.4444,
    "lng": 11.8483,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "minor",
    "population": 41970,
    "id": 1276551955
  },
  {
    "city": "Eisenach",
    "city_ascii": "Eisenach",
    "lat": 50.9747,
    "lng": 10.3244,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Thuringia",
    "capital": "minor",
    "population": 42370,
    "id": 1276000880
  },
  {
    "city": "Nakano",
    "city_ascii": "Nakano",
    "lat": 36.7419,
    "lng": 138.3694,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Nagano",
    "capital": "",
    "population": 42390,
    "id": 1392139023
  },
  {
    "city": "Temsia",
    "city_ascii": "Temsia",
    "lat": 30.3633,
    "lng": -9.4144,
    "country": "Morocco",
    "iso2": "MA",
    "iso3": "MAR",
    "admin_name": "Souss-Massa",
    "capital": "",
    "population": 40780,
    "id": 1504419242
  },
  {
    "city": "Burke",
    "city_ascii": "Burke",
    "lat": 38.7773,
    "lng": -77.2633,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 42722,
    "id": 1840006014
  },
  {
    "city": "Fianga",
    "city_ascii": "Fianga",
    "lat": 9.9153,
    "lng": 15.1375,
    "country": "Chad",
    "iso2": "TD",
    "iso3": "TCD",
    "admin_name": "Mayo-Kébbi Est",
    "capital": "",
    "population": 42606,
    "id": 1148228151
  },
  {
    "city": "Hwange",
    "city_ascii": "Hwange",
    "lat": -18.3647,
    "lng": 26.5,
    "country": "Zimbabwe",
    "iso2": "ZW",
    "iso3": "ZWE",
    "admin_name": "Matabeleland North",
    "capital": "",
    "population": 42581,
    "id": 1716416748
  },
  {
    "city": "Sansanné-Mango",
    "city_ascii": "Sansanne-Mango",
    "lat": 10.3556,
    "lng": 0.4756,
    "country": "Togo",
    "iso2": "TG",
    "iso3": "TGO",
    "admin_name": "Savanes",
    "capital": "",
    "population": 42626,
    "id": 1768815814
  },
  {
    "city": "Leigh",
    "city_ascii": "Leigh",
    "lat": 53.4975,
    "lng": -2.515,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Wigan",
    "capital": "",
    "population": 41275,
    "id": 1826341448
  },
  {
    "city": "Macerata",
    "city_ascii": "Macerata",
    "lat": 43.3003,
    "lng": 13.4533,
    "country": "Italy",
    "iso2": "IT",
    "iso3": "ITA",
    "admin_name": "Marche",
    "capital": "minor",
    "population": 42209,
    "id": 1380412139
  },
  {
    "city": "Vawkavysk",
    "city_ascii": "Vawkavysk",
    "lat": 53.1667,
    "lng": 24.4667,
    "country": "Belarus",
    "iso2": "BY",
    "iso3": "BLR",
    "admin_name": "Hrodzyenskaya Voblasts’",
    "capital": "minor",
    "population": 42600,
    "id": 1112164201
  },
  {
    "city": "Mariel",
    "city_ascii": "Mariel",
    "lat": 22.9936,
    "lng": -82.7539,
    "country": "Cuba",
    "iso2": "CU",
    "iso3": "CUB",
    "admin_name": "Artemisa",
    "capital": "minor",
    "population": 42500,
    "id": 1192227631
  },
  {
    "city": "Laatzen",
    "city_ascii": "Laatzen",
    "lat": 52.3077,
    "lng": 9.8133,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 41422,
    "id": 1276954154
  },
  {
    "city": "Qiryat Yam",
    "city_ascii": "Qiryat Yam",
    "lat": 32.8331,
    "lng": 35.0664,
    "country": "Israel",
    "iso2": "IL",
    "iso3": "ISR",
    "admin_name": "Haifa",
    "capital": "",
    "population": 40700,
    "id": 1376460777
  },
  {
    "city": "Edmonds",
    "city_ascii": "Edmonds",
    "lat": 47.8115,
    "lng": -122.3533,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 42605,
    "id": 1840037645
  },
  {
    "city": "Charlottetown",
    "city_ascii": "Charlottetown",
    "lat": 46.2403,
    "lng": -63.1347,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Prince Edward Island",
    "capital": "",
    "population": 42602,
    "id": 1124897699
  },
  {
    "city": "Mikołów",
    "city_ascii": "Mikolow",
    "lat": 50.171,
    "lng": 18.9041,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Śląskie",
    "capital": "minor",
    "population": 42053,
    "id": 1616187671
  },
  {
    "city": "Apache Junction",
    "city_ascii": "Apache Junction",
    "lat": 33.3985,
    "lng": -111.5351,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 42571,
    "id": 1840019365
  },
  {
    "city": "Fairfield",
    "city_ascii": "Fairfield",
    "lat": 39.3301,
    "lng": -84.5409,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 42558,
    "id": 1840003802
  },
  {
    "city": "Bury Saint Edmunds",
    "city_ascii": "Bury Saint Edmunds",
    "lat": 52.2474,
    "lng": 0.7183,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Suffolk",
    "capital": "",
    "population": 40664,
    "id": 1826257341
  },
  {
    "city": "Oakley",
    "city_ascii": "Oakley",
    "lat": 37.9929,
    "lng": -121.6951,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 42543,
    "id": 1840020279
  },
  {
    "city": "Hutchinson",
    "city_ascii": "Hutchinson",
    "lat": 38.0671,
    "lng": -97.9081,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kansas",
    "capital": "",
    "population": 42531,
    "id": 1840001673
  },
  {
    "city": "Shebekino",
    "city_ascii": "Shebekino",
    "lat": 50.4167,
    "lng": 36.9,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Belgorodskaya Oblast’",
    "capital": "",
    "population": 42465,
    "id": 1643443268
  },
  {
    "city": "Mascouche",
    "city_ascii": "Mascouche",
    "lat": 45.75,
    "lng": -73.6,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 42491,
    "id": 1124001580
  },
  {
    "city": "Shimo-tsuma",
    "city_ascii": "Shimo-tsuma",
    "lat": 36.1844,
    "lng": 139.9675,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Ibaraki",
    "capital": "",
    "population": 41964,
    "id": 1392100062
  },
  {
    "city": "Dayr Mawās",
    "city_ascii": "Dayr Mawas",
    "lat": 27.6414,
    "lng": 30.8494,
    "country": "Egypt",
    "iso2": "EG",
    "iso3": "EGY",
    "admin_name": "Al Minyā",
    "capital": "",
    "population": 40640,
    "id": 1818973684
  },
  {
    "city": "West Vancouver",
    "city_ascii": "West Vancouver",
    "lat": 49.3667,
    "lng": -123.1667,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "British Columbia",
    "capital": "",
    "population": 42473,
    "id": 1124001824
  },
  {
    "city": "Shepetivka",
    "city_ascii": "Shepetivka",
    "lat": 50.1833,
    "lng": 27.0667,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Khmel’nyts’ka Oblast’",
    "capital": "minor",
    "population": 41415,
    "id": 1804965832
  },
  {
    "city": "Shū",
    "city_ascii": "Shu",
    "lat": 43.5953,
    "lng": 73.7448,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Zhambyl",
    "capital": "",
    "population": 42439,
    "id": 1398834769
  },
  {
    "city": "Cortlandt",
    "city_ascii": "Cortlandt",
    "lat": 41.2553,
    "lng": -73.9019,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 42446,
    "id": 1840087347
  },
  {
    "city": "Alerce",
    "city_ascii": "Alerce",
    "lat": -41.3969,
    "lng": -72.9037,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Los Lagos",
    "capital": "",
    "population": 42267,
    "id": 1152663651
  },
  {
    "city": "Greenwood",
    "city_ascii": "Greenwood",
    "lat": 34.1945,
    "lng": -82.1542,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 42429,
    "id": 1840013626
  },
  {
    "city": "Tomé",
    "city_ascii": "Tome",
    "lat": -36.6171,
    "lng": -72.9575,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Biobío",
    "capital": "",
    "population": 42312,
    "id": 1152166653
  },
  {
    "city": "Salaberry-de-Valleyfield",
    "city_ascii": "Salaberry-de-Valleyfield",
    "lat": 45.25,
    "lng": -74.13,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 42410,
    "id": 1124638758
  },
  {
    "city": "Balykchy",
    "city_ascii": "Balykchy",
    "lat": 42.4667,
    "lng": 76.1833,
    "country": "Kyrgyzstan",
    "iso2": "KG",
    "iso3": "KGZ",
    "admin_name": "Ysyk-Köl",
    "capital": "",
    "population": 42380,
    "id": 1417967917
  },
  {
    "city": "Heinsberg",
    "city_ascii": "Heinsberg",
    "lat": 51.0631,
    "lng": 6.0964,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "minor",
    "population": 41946,
    "id": 1276000099
  },
  {
    "city": "Itápolis",
    "city_ascii": "Itapolis",
    "lat": -21.5958,
    "lng": -48.8128,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 42343,
    "id": 1076174719
  },
  {
    "city": "Richmond",
    "city_ascii": "Richmond",
    "lat": 39.8318,
    "lng": -84.8905,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 42373,
    "id": 1840014176
  },
  {
    "city": "Puyallup",
    "city_ascii": "Puyallup",
    "lat": 47.1794,
    "lng": -122.2902,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 42361,
    "id": 1840019859
  },
  {
    "city": "Linden",
    "city_ascii": "Linden",
    "lat": 40.6251,
    "lng": -74.2381,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 42361,
    "id": 1840001094
  },
  {
    "city": "Nanbei",
    "city_ascii": "Nanbei",
    "lat": 35.3206,
    "lng": 139.1,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Kanagawa",
    "capital": "",
    "population": 41814,
    "id": 1392514585
  },
  {
    "city": "Kitaibaraki",
    "city_ascii": "Kitaibaraki",
    "lat": 36.8019,
    "lng": 140.7511,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Ibaraki",
    "capital": "",
    "population": 42122,
    "id": 1392092741
  },
  {
    "city": "Qəzyan",
    "city_ascii": "Qazyan",
    "lat": 39.2015,
    "lng": 46.415,
    "country": "Azerbaijan",
    "iso2": "AZ",
    "iso3": "AZE",
    "admin_name": "Qubadlı",
    "capital": "",
    "population": 42288,
    "id": 1031867232
  },
  {
    "city": "Rājgīr",
    "city_ascii": "Rajgir",
    "lat": 25.03,
    "lng": 85.42,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Bihār",
    "capital": "",
    "population": 41587,
    "id": 1356823690
  },
  {
    "city": "Rouyn-Noranda",
    "city_ascii": "Rouyn-Noranda",
    "lat": 48.2333,
    "lng": -79.0167,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 42334,
    "id": 1124267752
  },
  {
    "city": "Köniz",
    "city_ascii": "Koniz",
    "lat": 46.925,
    "lng": 7.4153,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Bern",
    "capital": "",
    "population": 41507,
    "id": 1756022220
  },
  {
    "city": "Homburg",
    "city_ascii": "Homburg",
    "lat": 49.3167,
    "lng": 7.3333,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saarland",
    "capital": "minor",
    "population": 41811,
    "id": 1276520056
  },
  {
    "city": "Beitbridge",
    "city_ascii": "Beitbridge",
    "lat": -22.2167,
    "lng": 30,
    "country": "Zimbabwe",
    "iso2": "ZW",
    "iso3": "ZWE",
    "admin_name": "Matabeleland South",
    "capital": "",
    "population": 41767,
    "id": 1716929295
  },
  {
    "city": "Ansbach",
    "city_ascii": "Ansbach",
    "lat": 49.3,
    "lng": 10.5833,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "minor",
    "population": 41847,
    "id": 1276572551
  },
  {
    "city": "Germering",
    "city_ascii": "Germering",
    "lat": 48.1333,
    "lng": 11.3667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 40389,
    "id": 1276002965
  },
  {
    "city": "Gay",
    "city_ascii": "Gay",
    "lat": 51.4747,
    "lng": 58.4543,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Orenburgskaya Oblast’",
    "capital": "",
    "population": 41619,
    "id": 1643216197
  },
  {
    "city": "Urbana",
    "city_ascii": "Urbana",
    "lat": 40.1106,
    "lng": -88.1972,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 42214,
    "id": 1840010451
  },
  {
    "city": "Aurich",
    "city_ascii": "Aurich",
    "lat": 53.4714,
    "lng": 7.4836,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "minor",
    "population": 41991,
    "id": 1276424784
  },
  {
    "city": "Qapshaghay",
    "city_ascii": "Qapshaghay",
    "lat": 43.8844,
    "lng": 77.0687,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Almaty",
    "capital": "",
    "population": 42167,
    "id": 1398594098
  },
  {
    "city": "Nordhausen",
    "city_ascii": "Nordhausen",
    "lat": 51.505,
    "lng": 10.7911,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Thuringia",
    "capital": "minor",
    "population": 41791,
    "id": 1276946322
  },
  {
    "city": "Beverly",
    "city_ascii": "Beverly",
    "lat": 42.5681,
    "lng": -70.8627,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 42174,
    "id": 1840000411
  },
  {
    "city": "Safonovo",
    "city_ascii": "Safonovo",
    "lat": 55.1,
    "lng": 33.25,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Smolenskaya Oblast’",
    "capital": "",
    "population": 42147,
    "id": 1643281098
  },
  {
    "city": "Clovis",
    "city_ascii": "Clovis",
    "lat": 34.4376,
    "lng": -103.1923,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Mexico",
    "capital": "",
    "population": 42168,
    "id": 1840019195
  },
  {
    "city": "Monchegorsk",
    "city_ascii": "Monchegorsk",
    "lat": 67.9394,
    "lng": 32.9156,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Murmanskaya Oblast’",
    "capital": "",
    "population": 42099,
    "id": 1643634027
  },
  {
    "city": "Soran",
    "city_ascii": "Soran",
    "lat": 49.7908,
    "lng": 72.8374,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Qaraghandy",
    "capital": "",
    "population": 42058,
    "id": 1398259748
  },
  {
    "city": "Statesboro",
    "city_ascii": "Statesboro",
    "lat": 32.4376,
    "lng": -81.775,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 42102,
    "id": 1840015801
  },
  {
    "city": "Hobbs",
    "city_ascii": "Hobbs",
    "lat": 32.7282,
    "lng": -103.16,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Mexico",
    "capital": "",
    "population": 42095,
    "id": 1840020616
  },
  {
    "city": "Sarāb",
    "city_ascii": "Sarab",
    "lat": 37.9408,
    "lng": 47.5367,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Āz̄arbāyjān-e Sharqī",
    "capital": "minor",
    "population": 42057,
    "id": 1364335741
  },
  {
    "city": "Su-ngai Kolok",
    "city_ascii": "Su-ngai Kolok",
    "lat": 6.0297,
    "lng": 101.9668,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Narathiwat",
    "capital": "minor",
    "population": 41590,
    "id": 1764527716
  },
  {
    "city": "Korenovsk",
    "city_ascii": "Korenovsk",
    "lat": 45.4667,
    "lng": 39.45,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Krasnodarskiy Kray",
    "capital": "minor",
    "population": 42019,
    "id": 1643474762
  },
  {
    "city": "Qiryat Moẕqin",
    "city_ascii": "Qiryat Mozqin",
    "lat": 32.8381,
    "lng": 35.0794,
    "country": "Israel",
    "iso2": "IL",
    "iso3": "ISR",
    "admin_name": "Haifa",
    "capital": "",
    "population": 40160,
    "id": 1376435231
  },
  {
    "city": "McMinnville",
    "city_ascii": "McMinnville",
    "lat": 45.211,
    "lng": -123.1918,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 42062,
    "id": 1840019956
  },
  {
    "city": "Castres",
    "city_ascii": "Castres",
    "lat": 43.6,
    "lng": 2.25,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Occitanie",
    "capital": "minor",
    "population": 41636,
    "id": 1250134275
  },
  {
    "city": "Alès",
    "city_ascii": "Ales",
    "lat": 44.1281,
    "lng": 4.0817,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Occitanie",
    "capital": "minor",
    "population": 40219,
    "id": 1250908381
  },
  {
    "city": "Schertz",
    "city_ascii": "Schertz",
    "lat": 29.5649,
    "lng": -98.2537,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 42042,
    "id": 1840022213
  },
  {
    "city": "Tequila",
    "city_ascii": "Tequila",
    "lat": 20.8794,
    "lng": -103.8356,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Jalisco",
    "capital": "minor",
    "population": 42009,
    "id": 1484763049
  },
  {
    "city": "Moatize",
    "city_ascii": "Moatize",
    "lat": -16.1167,
    "lng": 33.75,
    "country": "Mozambique",
    "iso2": "MZ",
    "iso3": "MOZ",
    "admin_name": "Tete",
    "capital": "",
    "population": 42000,
    "id": 1508074512
  },
  {
    "city": "Alta Floresta",
    "city_ascii": "Alta Floresta",
    "lat": -9.9,
    "lng": -55.91,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Mato Grosso",
    "capital": "",
    "population": 40466,
    "id": 1076069666
  },
  {
    "city": "Greenock",
    "city_ascii": "Greenock",
    "lat": 55.95,
    "lng": -4.765,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Inverclyde",
    "capital": "",
    "population": 41990,
    "id": 1826864377
  },
  {
    "city": "Chapadinha",
    "city_ascii": "Chapadinha",
    "lat": -3.7417,
    "lng": -43.3603,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Maranhão",
    "capital": "",
    "population": 40804,
    "id": 1076901443
  },
  {
    "city": "Nürtingen",
    "city_ascii": "Nurtingen",
    "lat": 48.6267,
    "lng": 9.3353,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 41093,
    "id": 1276408465
  },
  {
    "city": "Spassk-Dal’niy",
    "city_ascii": "Spassk-Dal'niy",
    "lat": 44.6,
    "lng": 132.8167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Primorskiy Kray",
    "capital": "",
    "population": 41127,
    "id": 1643943494
  },
  {
    "city": "Tulun",
    "city_ascii": "Tulun",
    "lat": 54.5667,
    "lng": 100.5667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Irkutskaya Oblast’",
    "capital": "",
    "population": 41640,
    "id": 1643545822
  },
  {
    "city": "North Brunswick",
    "city_ascii": "North Brunswick",
    "lat": 40.4505,
    "lng": -74.4798,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 41948,
    "id": 1840081694
  },
  {
    "city": "Channelview",
    "city_ascii": "Channelview",
    "lat": 29.7914,
    "lng": -95.1145,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 41930,
    "id": 1840018253
  },
  {
    "city": "Rajaori",
    "city_ascii": "Rajaori",
    "lat": 33.38,
    "lng": 74.3,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Jammu and Kashmīr",
    "capital": "",
    "population": 41552,
    "id": 1356109150
  },
  {
    "city": "Wunstorf",
    "city_ascii": "Wunstorf",
    "lat": 52.4238,
    "lng": 9.4359,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 41594,
    "id": 1276079483
  },
  {
    "city": "Muban Phimon Rat",
    "city_ascii": "Muban Phimon Rat",
    "lat": 13.9189,
    "lng": 100.3922,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Nonthaburi",
    "capital": "",
    "population": 41498,
    "id": 1764300431
  },
  {
    "city": "Jardinópolis",
    "city_ascii": "Jardinopolis",
    "lat": -21.0178,
    "lng": -47.7639,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 41799,
    "id": 1076497660
  },
  {
    "city": "Bayt al Faqīh",
    "city_ascii": "Bayt al Faqih",
    "lat": 14.5167,
    "lng": 43.3167,
    "country": "Yemen",
    "iso2": "YE",
    "iso3": "YEM",
    "admin_name": "Al Ḩudaydah",
    "capital": "minor",
    "population": 41652,
    "id": 1887465006
  },
  {
    "city": "San",
    "city_ascii": "San",
    "lat": 13.3004,
    "lng": -4.9,
    "country": "Mali",
    "iso2": "ML",
    "iso3": "MLI",
    "admin_name": "Ségou",
    "capital": "minor",
    "population": 41386,
    "id": 1466198810
  },
  {
    "city": "Oviedo",
    "city_ascii": "Oviedo",
    "lat": 28.658,
    "lng": -81.1872,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 41860,
    "id": 1840015089
  },
  {
    "city": "Odenton",
    "city_ascii": "Odenton",
    "lat": 39.0661,
    "lng": -76.6939,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 41846,
    "id": 1840005912
  },
  {
    "city": "Kwidzyn",
    "city_ascii": "Kwidzyn",
    "lat": 53.7358,
    "lng": 18.9308,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Pomorskie",
    "capital": "minor",
    "population": 40008,
    "id": 1616851475
  },
  {
    "city": "Satka",
    "city_ascii": "Satka",
    "lat": 55.05,
    "lng": 59.05,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Chelyabinskaya Oblast’",
    "capital": "",
    "population": 41798,
    "id": 1643644802
  },
  {
    "city": "Grove City",
    "city_ascii": "Grove City",
    "lat": 39.8659,
    "lng": -83.0694,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 41820,
    "id": 1840007264
  },
  {
    "city": "Schwabach",
    "city_ascii": "Schwabach",
    "lat": 49.3292,
    "lng": 11.0208,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "minor",
    "population": 40792,
    "id": 1276017987
  },
  {
    "city": "Mozdok",
    "city_ascii": "Mozdok",
    "lat": 43.75,
    "lng": 44.65,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "North Ossetia",
    "capital": "",
    "population": 41728,
    "id": 1643138287
  },
  {
    "city": "Timmins",
    "city_ascii": "Timmins",
    "lat": 48.4667,
    "lng": -81.3333,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 41788,
    "id": 1124760634
  },
  {
    "city": "Königswinter",
    "city_ascii": "Konigswinter",
    "lat": 50.6833,
    "lng": 7.1833,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 41243,
    "id": 1276951419
  },
  {
    "city": "Wentzville",
    "city_ascii": "Wentzville",
    "lat": 38.8152,
    "lng": -90.8667,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 41784,
    "id": 1840010717
  },
  {
    "city": "Itá",
    "city_ascii": "Ita",
    "lat": -25.5096,
    "lng": -57.36,
    "country": "Paraguay",
    "iso2": "PY",
    "iso3": "PRY",
    "admin_name": "Central",
    "capital": "",
    "population": 41090,
    "id": 1600969782
  },
  {
    "city": "Myski",
    "city_ascii": "Myski",
    "lat": 53.7,
    "lng": 87.8167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kemerovskaya Oblast’",
    "capital": "",
    "population": 41379,
    "id": 1643972343
  },
  {
    "city": "La Quinta",
    "city_ascii": "La Quinta",
    "lat": 33.6536,
    "lng": -116.2785,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 41748,
    "id": 1840020559
  },
  {
    "city": "Strezhevoy",
    "city_ascii": "Strezhevoy",
    "lat": 60.7333,
    "lng": 77.5833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Tomskaya Oblast’",
    "capital": "",
    "population": 41541,
    "id": 1643011459
  },
  {
    "city": "Podilsk",
    "city_ascii": "Podilsk",
    "lat": 47.7419,
    "lng": 29.535,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Odes’ka Oblast’",
    "capital": "minor",
    "population": 40155,
    "id": 1804566477
  },
  {
    "city": "Freiberg",
    "city_ascii": "Freiberg",
    "lat": 50.9119,
    "lng": 13.3428,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "minor",
    "population": 40885,
    "id": 1276914988
  },
  {
    "city": "Sarandë",
    "city_ascii": "Sarande",
    "lat": 39.8833,
    "lng": 20.0167,
    "country": "Albania",
    "iso2": "AL",
    "iso3": "ALB",
    "admin_name": "Vlorë",
    "capital": "",
    "population": 41000,
    "id": 1008879697
  },
  {
    "city": "Ust’-Kut",
    "city_ascii": "Ust'-Kut",
    "lat": 56.8,
    "lng": 105.8333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Irkutskaya Oblast’",
    "capital": "",
    "population": 41689,
    "id": 1643157794
  },
  {
    "city": "North Fort Myers",
    "city_ascii": "North Fort Myers",
    "lat": 26.7243,
    "lng": -81.8491,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 41690,
    "id": 1840014222
  },
  {
    "city": "Świnoujście",
    "city_ascii": "Swinoujscie",
    "lat": 53.9167,
    "lng": 14.25,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Zachodniopomorskie",
    "capital": "minor",
    "population": 41479,
    "id": 1616487084
  },
  {
    "city": "Yakacık",
    "city_ascii": "Yakacik",
    "lat": 36.75,
    "lng": 36.2167,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Hatay",
    "capital": "minor",
    "population": 41409,
    "id": 1792903398
  },
  {
    "city": "Ihnāsyā al Madīnah",
    "city_ascii": "Ihnasya al Madinah",
    "lat": 29.0856,
    "lng": 30.9344,
    "country": "Egypt",
    "iso2": "EG",
    "iso3": "EGY",
    "admin_name": "Banī Suwayf",
    "capital": "",
    "population": 40001,
    "id": 1818108314
  },
  {
    "city": "Lokeren",
    "city_ascii": "Lokeren",
    "lat": 51.1,
    "lng": 3.9833,
    "country": "Belgium",
    "iso2": "BE",
    "iso3": "BEL",
    "admin_name": "Flanders",
    "capital": "",
    "population": 41057,
    "id": 1056040417
  },
  {
    "city": "Berkeley",
    "city_ascii": "Berkeley",
    "lat": 39.9156,
    "lng": -74.1923,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 41630,
    "id": 1840081604
  },
  {
    "city": "Weslaco",
    "city_ascii": "Weslaco",
    "lat": 26.1648,
    "lng": -97.9898,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 41629,
    "id": 1840022258
  },
  {
    "city": "Sorel-Tracy",
    "city_ascii": "Sorel-Tracy",
    "lat": 46.0333,
    "lng": -73.1167,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 41629,
    "id": 1124000182
  },
  {
    "city": "Karabulak",
    "city_ascii": "Karabulak",
    "lat": 43.3,
    "lng": 44.9,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Ingushetiya",
    "capital": "",
    "population": 41469,
    "id": 1643010318
  },
  {
    "city": "Naini Tal",
    "city_ascii": "Naini Tal",
    "lat": 29.3919,
    "lng": 79.4542,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Uttarakhand",
    "capital": "",
    "population": 41377,
    "id": 1356070793
  },
  {
    "city": "Ayagöz",
    "city_ascii": "Ayagoz",
    "lat": 47.9645,
    "lng": 80.4344,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Shyghys Qazaqstan",
    "capital": "",
    "population": 41593,
    "id": 1398040009
  },
  {
    "city": "Zanesville",
    "city_ascii": "Zanesville",
    "lat": 39.9565,
    "lng": -82.0132,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 41589,
    "id": 1840001496
  },
  {
    "city": "Newnan",
    "city_ascii": "Newnan",
    "lat": 33.3766,
    "lng": -84.7761,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 41581,
    "id": 1840014833
  },
  {
    "city": "Shakopee",
    "city_ascii": "Shakopee",
    "lat": 44.7744,
    "lng": -93.4764,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 41570,
    "id": 1840008955
  },
  {
    "city": "Nowa Sól",
    "city_ascii": "Nowa Sol",
    "lat": 51.8,
    "lng": 15.7167,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Lubuskie",
    "capital": "minor",
    "population": 39721,
    "id": 1616040847
  },
  {
    "city": "Brighton",
    "city_ascii": "Brighton",
    "lat": 39.9716,
    "lng": -104.7964,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 41554,
    "id": 1840018784
  },
  {
    "city": "Rancho Palos Verdes",
    "city_ascii": "Rancho Palos Verdes",
    "lat": 33.7554,
    "lng": -118.3637,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 41530,
    "id": 1840020508
  },
  {
    "city": "Thionville",
    "city_ascii": "Thionville",
    "lat": 49.3589,
    "lng": 6.1692,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Grand Est",
    "capital": "minor",
    "population": 40701,
    "id": 1250564736
  },
  {
    "city": "Kirchheim unter Teck",
    "city_ascii": "Kirchheim unter Teck",
    "lat": 48.6483,
    "lng": 9.4511,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 40523,
    "id": 1276389368
  },
  {
    "city": "Penn Hills",
    "city_ascii": "Penn Hills",
    "lat": 40.4762,
    "lng": -79.8255,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 41519,
    "id": 1840133217
  },
  {
    "city": "Mount Laurel",
    "city_ascii": "Mount Laurel",
    "lat": 39.9484,
    "lng": -74.9047,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 41518,
    "id": 1840081624
  },
  {
    "city": "Catonsville",
    "city_ascii": "Catonsville",
    "lat": 39.2646,
    "lng": -76.7424,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 41515,
    "id": 1840005679
  },
  {
    "city": "Verkhnyaya Salda",
    "city_ascii": "Verkhnyaya Salda",
    "lat": 58.05,
    "lng": 60.55,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sverdlovskaya Oblast’",
    "capital": "",
    "population": 41484,
    "id": 1643609151
  },
  {
    "city": "Erzin",
    "city_ascii": "Erzin",
    "lat": 36.9539,
    "lng": 36.2022,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Hatay",
    "capital": "minor",
    "population": 41368,
    "id": 1792613172
  },
  {
    "city": "New Tecumseth",
    "city_ascii": "New Tecumseth",
    "lat": 44.0833,
    "lng": -79.75,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 41439,
    "id": 1124001571
  },
  {
    "city": "Leinfelden-Echterdingen",
    "city_ascii": "Leinfelden-Echterdingen",
    "lat": 48.6928,
    "lng": 9.1428,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 40092,
    "id": 1276447188
  },
  {
    "city": "Coppell",
    "city_ascii": "Coppell",
    "lat": 32.9638,
    "lng": -96.9905,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 41421,
    "id": 1840019439
  },
  {
    "city": "Sovetsk",
    "city_ascii": "Sovetsk",
    "lat": 55.0833,
    "lng": 21.8833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kaliningradskaya Oblast’",
    "capital": "minor",
    "population": 40486,
    "id": 1643001581
  },
  {
    "city": "Itoigawa",
    "city_ascii": "Itoigawa",
    "lat": 37.0333,
    "lng": 137.85,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Niigata",
    "capital": "",
    "population": 41325,
    "id": 1392314083
  },
  {
    "city": "Moline",
    "city_ascii": "Moline",
    "lat": 41.4821,
    "lng": -90.4921,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 41356,
    "id": 1840008181
  },
  {
    "city": "El Tocuyo",
    "city_ascii": "El Tocuyo",
    "lat": 9.7822,
    "lng": -69.7931,
    "country": "Venezuela",
    "iso2": "VE",
    "iso3": "VEN",
    "admin_name": "Lara",
    "capital": "minor",
    "population": 41327,
    "id": 1862715800
  },
  {
    "city": "Aquidauana",
    "city_ascii": "Aquidauana",
    "lat": -20.4711,
    "lng": -55.7872,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Mato Grosso do Sul",
    "capital": "",
    "population": 40803,
    "id": 1076120417
  },
  {
    "city": "Chaves",
    "city_ascii": "Chaves",
    "lat": 41.7399,
    "lng": -7.4707,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Vila Real",
    "capital": "minor",
    "population": 41243,
    "id": 1620516049
  },
  {
    "city": "Ōzu",
    "city_ascii": "Ozu",
    "lat": 33.5064,
    "lng": 132.5447,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Ehime",
    "capital": "",
    "population": 41201,
    "id": 1392217388
  },
  {
    "city": "Antequera",
    "city_ascii": "Antequera",
    "lat": 37.0184,
    "lng": -4.5597,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Andalusia",
    "capital": "",
    "population": 41239,
    "id": 1724357550
  },
  {
    "city": "Delaware",
    "city_ascii": "Delaware",
    "lat": 40.2866,
    "lng": -83.0747,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 41283,
    "id": 1840007237
  },
  {
    "city": "Kovvūr",
    "city_ascii": "Kovvur",
    "lat": 17.0167,
    "lng": 81.7333,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Andhra Pradesh",
    "capital": "",
    "population": 39667,
    "id": 1356652394
  },
  {
    "city": "Gap",
    "city_ascii": "Gap",
    "lat": 44.5594,
    "lng": 6.0786,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Provence-Alpes-Côte d’Azur",
    "capital": "minor",
    "population": 40895,
    "id": 1250717816
  },
  {
    "city": "Temryuk",
    "city_ascii": "Temryuk",
    "lat": 45.2667,
    "lng": 37.3833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Krasnodarskiy Kray",
    "capital": "minor",
    "population": 41133,
    "id": 1643588256
  },
  {
    "city": "Westfield",
    "city_ascii": "Westfield",
    "lat": 42.1382,
    "lng": -72.7561,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 41204,
    "id": 1840000463
  },
  {
    "city": "Järvenpää",
    "city_ascii": "Jarvenpaa",
    "lat": 60.4722,
    "lng": 25.0889,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Uusimaa",
    "capital": "minor",
    "population": 40106,
    "id": 1246825863
  },
  {
    "city": "Vyshneve",
    "city_ascii": "Vyshneve",
    "lat": 50.3833,
    "lng": 30.3667,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Kyyivs’ka Oblast’",
    "capital": "",
    "population": 40919,
    "id": 1804783746
  },
  {
    "city": "Bensheim",
    "city_ascii": "Bensheim",
    "lat": 49.6811,
    "lng": 8.6228,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 40456,
    "id": 1276256416
  },
  {
    "city": "Hempfield",
    "city_ascii": "Hempfield",
    "lat": 40.2847,
    "lng": -79.5841,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 41154,
    "id": 1840151151
  },
  {
    "city": "Bolesławiec",
    "city_ascii": "Boleslawiec",
    "lat": 51.2667,
    "lng": 15.5667,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Dolnośląskie",
    "capital": "minor",
    "population": 39412,
    "id": 1616593724
  },
  {
    "city": "Shelton",
    "city_ascii": "Shelton",
    "lat": 41.306,
    "lng": -73.1383,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 41129,
    "id": 1840004840
  },
  {
    "city": "Bāft",
    "city_ascii": "Baft",
    "lat": 29.2331,
    "lng": 56.6022,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Kermān",
    "capital": "minor",
    "population": 41105,
    "id": 1364013464
  },
  {
    "city": "Westerville",
    "city_ascii": "Westerville",
    "lat": 40.1241,
    "lng": -82.9209,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 41103,
    "id": 1840003763
  },
  {
    "city": "Lemgo",
    "city_ascii": "Lemgo",
    "lat": 52.0277,
    "lng": 8.9043,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 40696,
    "id": 1276164957
  },
  {
    "city": "Kasumigaura",
    "city_ascii": "Kasumigaura",
    "lat": 36.1519,
    "lng": 140.2372,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Ibaraki",
    "capital": "",
    "population": 40833,
    "id": 1392054909
  },
  {
    "city": "Linton Hall",
    "city_ascii": "Linton Hall",
    "lat": 38.7551,
    "lng": -77.575,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 41088,
    "id": 1840006100
  },
  {
    "city": "Manassas",
    "city_ascii": "Manassas",
    "lat": 38.7479,
    "lng": -77.4839,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 41085,
    "id": 1840003839
  },
  {
    "city": "Eberswalde",
    "city_ascii": "Eberswalde",
    "lat": 52.8331,
    "lng": 13.8331,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Brandenburg",
    "capital": "minor",
    "population": 40387,
    "id": 1276311453
  },
  {
    "city": "Tonbridge",
    "city_ascii": "Tonbridge",
    "lat": 51.1987,
    "lng": 0.2764,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Kent",
    "capital": "",
    "population": 40356,
    "id": 1826806502
  },
  {
    "city": "Upper Hutt",
    "city_ascii": "Upper Hutt",
    "lat": -41.1333,
    "lng": 175.05,
    "country": "New Zealand",
    "iso2": "NZ",
    "iso3": "NZL",
    "admin_name": "Wellington",
    "capital": "",
    "population": 41000,
    "id": 1554000042
  },
  {
    "city": "Salinópolis",
    "city_ascii": "Salinopolis",
    "lat": -0.6136,
    "lng": -47.3561,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Pará",
    "capital": "",
    "population": 40998,
    "id": 1076811443
  },
  {
    "city": "Pirmasens",
    "city_ascii": "Pirmasens",
    "lat": 49.2,
    "lng": 7.6,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Rhineland-Palatinate",
    "capital": "minor",
    "population": 40403,
    "id": 1276000134
  },
  {
    "city": "Kearny",
    "city_ascii": "Kearny",
    "lat": 40.7528,
    "lng": -74.1202,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 41058,
    "id": 1840003601
  },
  {
    "city": "Chambas",
    "city_ascii": "Chambas",
    "lat": 22.1967,
    "lng": -78.9133,
    "country": "Cuba",
    "iso2": "CU",
    "iso3": "CUB",
    "admin_name": "Ciego de Ávila",
    "capital": "minor",
    "population": 41000,
    "id": 1192803412
  },
  {
    "city": "Ostfildern",
    "city_ascii": "Ostfildern",
    "lat": 48.7228,
    "lng": 9.2631,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 39321,
    "id": 1276855000
  },
  {
    "city": "Siguatepeque",
    "city_ascii": "Siguatepeque",
    "lat": 14.6167,
    "lng": -87.8333,
    "country": "Honduras",
    "iso2": "HN",
    "iso3": "HND",
    "admin_name": "Comayagua",
    "capital": "",
    "population": 39070,
    "id": 1340354793
  },
  {
    "city": "Bagua Grande",
    "city_ascii": "Bagua Grande",
    "lat": -5.7572,
    "lng": -78.4453,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "Amazonas",
    "capital": "",
    "population": 40947,
    "id": 1604518174
  },
  {
    "city": "South Valley",
    "city_ascii": "South Valley",
    "lat": 35.0092,
    "lng": -106.6819,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Mexico",
    "capital": "",
    "population": 41011,
    "id": 1840033724
  },
  {
    "city": "Pyt’-Yakh",
    "city_ascii": "Pyt'-Yakh",
    "lat": 60.75,
    "lng": 72.7833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Khanty-Mansiyskiy Avtonomnyy Okrug-Yugra",
    "capital": "",
    "population": 41005,
    "id": 1643096120
  },
  {
    "city": "Novozybkov",
    "city_ascii": "Novozybkov",
    "lat": 52.5333,
    "lng": 31.9333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Bryanskaya Oblast’",
    "capital": "",
    "population": 39725,
    "id": 1643876625
  },
  {
    "city": "Los Banos",
    "city_ascii": "Los Banos",
    "lat": 37.063,
    "lng": -120.8406,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 40972,
    "id": 1840020312
  },
  {
    "city": "Richmond",
    "city_ascii": "Richmond",
    "lat": 37.7306,
    "lng": -84.2926,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kentucky",
    "capital": "",
    "population": 40972,
    "id": 1840014341
  },
  {
    "city": "Compiègne",
    "city_ascii": "Compiegne",
    "lat": 49.4149,
    "lng": 2.8231,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Hauts-de-France",
    "capital": "minor",
    "population": 40199,
    "id": 1250876040
  },
  {
    "city": "Kabuga",
    "city_ascii": "Kabuga",
    "lat": -1.9765,
    "lng": 30.2247,
    "country": "Rwanda",
    "iso2": "RW",
    "iso3": "RWA",
    "admin_name": "Kigali",
    "capital": "",
    "population": 40202,
    "id": 1646271073
  },
  {
    "city": "Chebarkul",
    "city_ascii": "Chebarkul",
    "lat": 54.9778,
    "lng": 60.37,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Chelyabinskaya Oblast’",
    "capital": "",
    "population": 40378,
    "id": 1643618231
  },
  {
    "city": "Romny",
    "city_ascii": "Romny",
    "lat": 50.7428,
    "lng": 33.4879,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Sums’ka Oblast’",
    "capital": "minor",
    "population": 40316,
    "id": 1804386942
  },
  {
    "city": "Spanish Fork",
    "city_ascii": "Spanish Fork",
    "lat": 40.1101,
    "lng": -111.6406,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Utah",
    "capital": "",
    "population": 40913,
    "id": 1840021398
  },
  {
    "city": "Woodstock",
    "city_ascii": "Woodstock",
    "lat": 43.1306,
    "lng": -80.7467,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 40902,
    "id": 1124758374
  },
  {
    "city": "Belleville",
    "city_ascii": "Belleville",
    "lat": 38.5165,
    "lng": -89.99,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 40897,
    "id": 1840007486
  },
  {
    "city": "Ílhavo",
    "city_ascii": "Ilhavo",
    "lat": 40.6,
    "lng": -8.6667,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Aveiro",
    "capital": "minor",
    "population": 40349,
    "id": 1620545975
  },
  {
    "city": "Maplewood",
    "city_ascii": "Maplewood",
    "lat": 44.9842,
    "lng": -93.0247,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 40885,
    "id": 1840008937
  },
  {
    "city": "Komono",
    "city_ascii": "Komono",
    "lat": 35.02,
    "lng": 136.5075,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Mie",
    "capital": "",
    "population": 40487,
    "id": 1392136117
  },
  {
    "city": "Gishi",
    "city_ascii": "Gishi",
    "lat": 26.1911,
    "lng": 127.7286,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Okinawa",
    "capital": "",
    "population": 39835,
    "id": 1392090372
  },
  {
    "city": "Schwäbisch Hall",
    "city_ascii": "Schwabisch Hall",
    "lat": 49.1122,
    "lng": 9.7375,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "minor",
    "population": 40440,
    "id": 1276556451
  },
  {
    "city": "Abadan",
    "city_ascii": "Abadan",
    "lat": 38.0517,
    "lng": 58.21,
    "country": "Turkmenistan",
    "iso2": "TM",
    "iso3": "TKM",
    "admin_name": "Ahal",
    "capital": "",
    "population": 40813,
    "id": 1795394170
  },
  {
    "city": "Sasayama",
    "city_ascii": "Sasayama",
    "lat": 35.0758,
    "lng": 135.2192,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hyōgo",
    "capital": "",
    "population": 40708,
    "id": 1392079615
  },
  {
    "city": "Pereslavl’-Zalesskiy",
    "city_ascii": "Pereslavl'-Zalesskiy",
    "lat": 56.7381,
    "lng": 38.8562,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Yaroslavskaya Oblast’",
    "capital": "",
    "population": 39105,
    "id": 1643256841
  },
  {
    "city": "Petrich",
    "city_ascii": "Petrich",
    "lat": 41.3953,
    "lng": 23.2069,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Blagoevgrad",
    "capital": "",
    "population": 40728,
    "id": 1100407427
  },
  {
    "city": "Tomar",
    "city_ascii": "Tomar",
    "lat": 39.6,
    "lng": -8.4167,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Santarém",
    "capital": "minor",
    "population": 40677,
    "id": 1620168091
  },
  {
    "city": "Colomiers",
    "city_ascii": "Colomiers",
    "lat": 43.6139,
    "lng": 1.3367,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Occitanie",
    "capital": "",
    "population": 38951,
    "id": 1250882748
  },
  {
    "city": "Weißenfels",
    "city_ascii": "Weissenfels",
    "lat": 51.2,
    "lng": 11.9667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony-Anhalt",
    "capital": "",
    "population": 40409,
    "id": 1276292106
  },
  {
    "city": "Boucherville",
    "city_ascii": "Boucherville",
    "lat": 45.6,
    "lng": -73.45,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 40753,
    "id": 1124000296
  },
  {
    "city": "Yalutorovsk",
    "city_ascii": "Yalutorovsk",
    "lat": 56.65,
    "lng": 66.3,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Tyumenskaya Oblast’",
    "capital": "",
    "population": 39967,
    "id": 1643158086
  },
  {
    "city": "Arys",
    "city_ascii": "Arys",
    "lat": 42.4333,
    "lng": 68.8,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Ongtüstik Qazaqstan",
    "capital": "",
    "population": 40707,
    "id": 1398483570
  },
  {
    "city": "Freital",
    "city_ascii": "Freital",
    "lat": 51.0167,
    "lng": 13.65,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 39709,
    "id": 1276672229
  },
  {
    "city": "Vahdat",
    "city_ascii": "Vahdat",
    "lat": 38.5531,
    "lng": 69.0197,
    "country": "Tajikistan",
    "iso2": "TJ",
    "iso3": "TJK",
    "admin_name": "Nohiyahoi Tobei Jumhurí",
    "capital": "minor",
    "population": 40600,
    "id": 1762043986
  },
  {
    "city": "Channarāyapatna",
    "city_ascii": "Channarayapatna",
    "lat": 12.9,
    "lng": 76.39,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Karnātaka",
    "capital": "",
    "population": 40400,
    "id": 1356209573
  },
  {
    "city": "Pottsville",
    "city_ascii": "Pottsville",
    "lat": 40.6798,
    "lng": -76.2092,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 40685,
    "id": 1840002775
  },
  {
    "city": "Buxtehude",
    "city_ascii": "Buxtehude",
    "lat": 53.4769,
    "lng": 9.7011,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 40150,
    "id": 1276767530
  },
  {
    "city": "Columbia",
    "city_ascii": "Columbia",
    "lat": 35.6235,
    "lng": -87.0486,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 40663,
    "id": 1840014518
  },
  {
    "city": "Bayındır",
    "city_ascii": "Bayindir",
    "lat": 38.2192,
    "lng": 27.6481,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "İzmir",
    "capital": "minor",
    "population": 40584,
    "id": 1792970598
  },
  {
    "city": "Pechora",
    "city_ascii": "Pechora",
    "lat": 65.1442,
    "lng": 57.2197,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Komi",
    "capital": "",
    "population": 40653,
    "id": 1643523866
  },
  {
    "city": "Bartlett",
    "city_ascii": "Bartlett",
    "lat": 41.9803,
    "lng": -88.2069,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 40647,
    "id": 1840011246
  },
  {
    "city": "Bluefield",
    "city_ascii": "Bluefield",
    "lat": 37.2608,
    "lng": -81.2143,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "West Virginia",
    "capital": "",
    "population": 40640,
    "id": 1840006401
  },
  {
    "city": "Fitchburg",
    "city_ascii": "Fitchburg",
    "lat": 42.5912,
    "lng": -71.8156,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 40638,
    "id": 1840000435
  },
  {
    "city": "Orange",
    "city_ascii": "Orange",
    "lat": -33.2833,
    "lng": 149.1,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 40493,
    "id": 1036760396
  },
  {
    "city": "Marion",
    "city_ascii": "Marion",
    "lat": 40.5496,
    "lng": -85.66,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 40588,
    "id": 1840013868
  },
  {
    "city": "Hückelhoven",
    "city_ascii": "Huckelhoven",
    "lat": 51.0608,
    "lng": 6.2197,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 39931,
    "id": 1276391695
  },
  {
    "city": "Huaura",
    "city_ascii": "Huaura",
    "lat": -11.1,
    "lng": -77.6,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "Lima",
    "capital": "",
    "population": 40400,
    "id": 1604503366
  },
  {
    "city": "Farnham",
    "city_ascii": "Farnham",
    "lat": 51.215,
    "lng": -0.799,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Surrey",
    "capital": "",
    "population": 39488,
    "id": 1826338893
  },
  {
    "city": "Uray",
    "city_ascii": "Uray",
    "lat": 60.1333,
    "lng": 64.7833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Khanty-Mansiyskiy Avtonomnyy Okrug-Yugra",
    "capital": "",
    "population": 40559,
    "id": 1643009149
  },
  {
    "city": "Maihar",
    "city_ascii": "Maihar",
    "lat": 24.27,
    "lng": 80.75,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Madhya Pradesh",
    "capital": "",
    "population": 40192,
    "id": 1356415420
  },
  {
    "city": "Halberstadt",
    "city_ascii": "Halberstadt",
    "lat": 51.8958,
    "lng": 11.0467,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony-Anhalt",
    "capital": "minor",
    "population": 40256,
    "id": 1276900818
  },
  {
    "city": "Cedar Falls",
    "city_ascii": "Cedar Falls",
    "lat": 42.5195,
    "lng": -92.4534,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Iowa",
    "capital": "",
    "population": 40536,
    "id": 1840006946
  },
  {
    "city": "Aizawa",
    "city_ascii": "Aizawa",
    "lat": 35.5289,
    "lng": 139.3217,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Kanagawa",
    "capital": "",
    "population": 39381,
    "id": 1392673347
  },
  {
    "city": "Yi’an",
    "city_ascii": "Yi'an",
    "lat": 47.8804,
    "lng": 125.3,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Heilongjiang",
    "capital": "minor",
    "population": 39924,
    "id": 1156612993
  },
  {
    "city": "Maintal",
    "city_ascii": "Maintal",
    "lat": 50.15,
    "lng": 8.8333,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 39298,
    "id": 1276616960
  },
  {
    "city": "Neumarkt",
    "city_ascii": "Neumarkt",
    "lat": 49.2833,
    "lng": 11.4667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "minor",
    "population": 40002,
    "id": 1276002746
  },
  {
    "city": "Bình Long",
    "city_ascii": "Binh Long",
    "lat": 11.6504,
    "lng": 106.6,
    "country": "Vietnam",
    "iso2": "VN",
    "iso3": "VNM",
    "admin_name": "Bình Phước",
    "capital": "",
    "population": 40279,
    "id": 1704953411
  },
  {
    "city": "Pyu",
    "city_ascii": "Pyu",
    "lat": 18.4779,
    "lng": 96.4379,
    "country": "Burma",
    "iso2": "MM",
    "iso3": "MMR",
    "admin_name": "Bago",
    "capital": "",
    "population": 40386,
    "id": 1104518645
  },
  {
    "city": "Buffalo Grove",
    "city_ascii": "Buffalo Grove",
    "lat": 42.1673,
    "lng": -87.9616,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 40494,
    "id": 1840010143
  },
  {
    "city": "Jamestown",
    "city_ascii": "Jamestown",
    "lat": 42.0975,
    "lng": -79.2366,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 40493,
    "id": 1840000446
  },
  {
    "city": "Essex",
    "city_ascii": "Essex",
    "lat": 39.3024,
    "lng": -76.4457,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 40480,
    "id": 1840005675
  },
  {
    "city": "Sagae",
    "city_ascii": "Sagae",
    "lat": 38.3811,
    "lng": 140.2761,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Yamagata",
    "capital": "",
    "population": 40185,
    "id": 1392838148
  },
  {
    "city": "Huntsville",
    "city_ascii": "Huntsville",
    "lat": 30.705,
    "lng": -95.5544,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 40466,
    "id": 1840019565
  },
  {
    "city": "Mableton",
    "city_ascii": "Mableton",
    "lat": 33.8132,
    "lng": -84.5656,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 40464,
    "id": 1840013690
  },
  {
    "city": "Hofheim",
    "city_ascii": "Hofheim",
    "lat": 50.0876,
    "lng": 8.4447,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "minor",
    "population": 39766,
    "id": 1276364825
  },
  {
    "city": "Anglet",
    "city_ascii": "Anglet",
    "lat": 43.485,
    "lng": -1.5183,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Nouvelle-Aquitaine",
    "capital": "",
    "population": 38929,
    "id": 1250237596
  },
  {
    "city": "São Manuel",
    "city_ascii": "Sao Manuel",
    "lat": -22.7308,
    "lng": -48.5708,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 40367,
    "id": 1076604538
  },
  {
    "city": "Clarksburg",
    "city_ascii": "Clarksburg",
    "lat": 39.2863,
    "lng": -80.323,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "West Virginia",
    "capital": "",
    "population": 40403,
    "id": 1840005767
  },
  {
    "city": "Zainsk",
    "city_ascii": "Zainsk",
    "lat": 55.3,
    "lng": 52.0167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Tatarstan",
    "capital": "",
    "population": 40366,
    "id": 1643336213
  },
  {
    "city": "Royal Palm Beach",
    "city_ascii": "Royal Palm Beach",
    "lat": 26.7038,
    "lng": -80.2241,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 40396,
    "id": 1840018309
  },
  {
    "city": "Löhne",
    "city_ascii": "Lohne",
    "lat": 52.2,
    "lng": 8.7,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 39697,
    "id": 1276788847
  },
  {
    "city": "Jipijapa",
    "city_ascii": "Jipijapa",
    "lat": -1.3486,
    "lng": -80.5786,
    "country": "Ecuador",
    "iso2": "EC",
    "iso3": "ECU",
    "admin_name": "Manabí",
    "capital": "",
    "population": 40232,
    "id": 1218962184
  },
  {
    "city": "Al Qā‘idah",
    "city_ascii": "Al Qa`idah",
    "lat": 13.7569,
    "lng": 44.1392,
    "country": "Yemen",
    "iso2": "YE",
    "iso3": "YEM",
    "admin_name": "Ibb",
    "capital": "",
    "population": 39254,
    "id": 1887959673
  },
  {
    "city": "Marion",
    "city_ascii": "Marion",
    "lat": 42.045,
    "lng": -91.5846,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Iowa",
    "capital": "",
    "population": 40359,
    "id": 1840008099
  },
  {
    "city": "Oświęcim",
    "city_ascii": "Oswiecim",
    "lat": 50.0333,
    "lng": 19.2333,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Małopolskie",
    "capital": "minor",
    "population": 39057,
    "id": 1616528804
  },
  {
    "city": "Covington",
    "city_ascii": "Covington",
    "lat": 39.0334,
    "lng": -84.5166,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kentucky",
    "capital": "",
    "population": 40341,
    "id": 1840014263
  },
  {
    "city": "Junnar",
    "city_ascii": "Junnar",
    "lat": 19.2,
    "lng": 73.88,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Mahārāshtra",
    "capital": "",
    "population": 40000,
    "id": 1356521815
  },
  {
    "city": "Schorndorf",
    "city_ascii": "Schorndorf",
    "lat": 48.8,
    "lng": 9.5333,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 39634,
    "id": 1276068626
  },
  {
    "city": "Kurobeshin",
    "city_ascii": "Kurobeshin",
    "lat": 36.8667,
    "lng": 137.45,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Toyama",
    "capital": "",
    "population": 40231,
    "id": 1392968736
  },
  {
    "city": "Belaya Kalitva",
    "city_ascii": "Belaya Kalitva",
    "lat": 48.1667,
    "lng": 40.7833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Rostovskaya Oblast’",
    "capital": "minor",
    "population": 40275,
    "id": 1643376629
  },
  {
    "city": "Whanganui",
    "city_ascii": "Whanganui",
    "lat": -39.9333,
    "lng": 175.05,
    "country": "New Zealand",
    "iso2": "NZ",
    "iso3": "NZL",
    "admin_name": "Manawatu-Wanganui",
    "capital": "",
    "population": 39400,
    "id": 1554827998
  },
  {
    "city": "Takikawa",
    "city_ascii": "Takikawa",
    "lat": 43.5578,
    "lng": 141.9106,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hokkaidō",
    "capital": "",
    "population": 39960,
    "id": 1392063126
  },
  {
    "city": "Tietê",
    "city_ascii": "Tiete",
    "lat": -23.1019,
    "lng": -47.715,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 40194,
    "id": 1076154000
  },
  {
    "city": "Friendswood",
    "city_ascii": "Friendswood",
    "lat": 29.5111,
    "lng": -95.1979,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 40290,
    "id": 1840020969
  },
  {
    "city": "Arawa",
    "city_ascii": "Arawa",
    "lat": -6.228,
    "lng": 155.566,
    "country": "Papua New Guinea",
    "iso2": "PG",
    "iso3": "PNG",
    "admin_name": "Bougainville",
    "capital": "",
    "population": 40266,
    "id": 1598413091
  },
  {
    "city": "Považská Bystrica",
    "city_ascii": "Povazska Bystrica",
    "lat": 49.1167,
    "lng": 18.45,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Nitriansky",
    "capital": "minor",
    "population": 39837,
    "id": 1703729563
  },
  {
    "city": "Indian Trail",
    "city_ascii": "Indian Trail",
    "lat": 35.0698,
    "lng": -80.6457,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 40252,
    "id": 1840016453
  },
  {
    "city": "Woburn",
    "city_ascii": "Woburn",
    "lat": 42.4869,
    "lng": -71.1543,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 40228,
    "id": 1840003124
  },
  {
    "city": "Camargo",
    "city_ascii": "Camargo",
    "lat": 27.667,
    "lng": -105.167,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Chihuahua",
    "capital": "minor",
    "population": 40221,
    "id": 1484013592
  },
  {
    "city": "Mtsensk",
    "city_ascii": "Mtsensk",
    "lat": 53.2833,
    "lng": 36.5667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Orlovskaya Oblast’",
    "capital": "",
    "population": 38350,
    "id": 1643200546
  },
  {
    "city": "Tutayev",
    "city_ascii": "Tutayev",
    "lat": 57.8833,
    "lng": 39.5333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Yaroslavskaya Oblast’",
    "capital": "",
    "population": 40154,
    "id": 1643941523
  },
  {
    "city": "Tipton",
    "city_ascii": "Tipton",
    "lat": 52.5259,
    "lng": -2.0751,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Dudley",
    "capital": "",
    "population": 38777,
    "id": 1826072169
  },
  {
    "city": "Ban Bang Khu Lat",
    "city_ascii": "Ban Bang Khu Lat",
    "lat": 13.9134,
    "lng": 100.369,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Nonthaburi",
    "capital": "",
    "population": 38758,
    "id": 1764924013
  },
  {
    "city": "Oroqen Zizhiqi",
    "city_ascii": "Oroqen Zizhiqi",
    "lat": 50.5667,
    "lng": 123.7167,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Inner Mongolia",
    "capital": "minor",
    "population": 40128,
    "id": 1156444032
  },
  {
    "city": "Ad Dilam",
    "city_ascii": "Ad Dilam",
    "lat": 23.9934,
    "lng": 47.1643,
    "country": "Saudi Arabia",
    "iso2": "SA",
    "iso3": "SAU",
    "admin_name": "Ar Riyāḑ",
    "capital": "",
    "population": 40114,
    "id": 1682822109
  },
  {
    "city": "Marlboro",
    "city_ascii": "Marlboro",
    "lat": 40.3427,
    "lng": -74.2567,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 40133,
    "id": 1840081649
  },
  {
    "city": "Hangu",
    "city_ascii": "Hangu",
    "lat": 33.5281,
    "lng": 71.0572,
    "country": "Pakistan",
    "iso2": "PK",
    "iso3": "PAK",
    "admin_name": "Khyber Pakhtunkhwa",
    "capital": "minor",
    "population": 39766,
    "id": 1586769539
  },
  {
    "city": "Raub",
    "city_ascii": "Raub",
    "lat": 3.7931,
    "lng": 101.8569,
    "country": "Malaysia",
    "iso2": "MY",
    "iso3": "MYS",
    "admin_name": "Pahang",
    "capital": "",
    "population": 40024,
    "id": 1458881822
  },
  {
    "city": "Holyoke",
    "city_ascii": "Holyoke",
    "lat": 42.2125,
    "lng": -72.6411,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 40117,
    "id": 1840000465
  },
  {
    "city": "Amursk",
    "city_ascii": "Amursk",
    "lat": 50.2167,
    "lng": 136.9,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Khabarovskiy Kray",
    "capital": "",
    "population": 40106,
    "id": 1643243118
  },
  {
    "city": "Arecibo",
    "city_ascii": "Arecibo",
    "lat": 18.4491,
    "lng": -66.7387,
    "country": "Puerto Rico",
    "iso2": "PR",
    "iso3": "PRI",
    "admin_name": "Puerto Rico",
    "capital": "",
    "population": 40108,
    "id": 1630023566
  },
  {
    "city": "Baksan",
    "city_ascii": "Baksan",
    "lat": 43.6833,
    "lng": 43.5333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kabardino-Balkariya",
    "capital": "",
    "population": 38192,
    "id": 1643894887
  },
  {
    "city": "Mibu",
    "city_ascii": "Mibu",
    "lat": 36.4272,
    "lng": 139.8039,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Tochigi",
    "capital": "",
    "population": 39454,
    "id": 1392551265
  },
  {
    "city": "Plasencia",
    "city_ascii": "Plasencia",
    "lat": 40.0275,
    "lng": -6.0908,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Extremadura",
    "capital": "",
    "population": 39913,
    "id": 1724951100
  },
  {
    "city": "Altagracia de Orituco",
    "city_ascii": "Altagracia de Orituco",
    "lat": 9.8504,
    "lng": -66.38,
    "country": "Venezuela",
    "iso2": "VE",
    "iso3": "VEN",
    "admin_name": "Guárico",
    "capital": "minor",
    "population": 40052,
    "id": 1862533029
  },
  {
    "city": "Draguignan",
    "city_ascii": "Draguignan",
    "lat": 43.5403,
    "lng": 6.4667,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Provence-Alpes-Côte d’Azur",
    "capital": "minor",
    "population": 39340,
    "id": 1250434593
  },
  {
    "city": "Isfara",
    "city_ascii": "Isfara",
    "lat": 40.1167,
    "lng": 70.6333,
    "country": "Tajikistan",
    "iso2": "TJ",
    "iso3": "TJK",
    "admin_name": "Sughd",
    "capital": "minor",
    "population": 40000,
    "id": 1762121684
  },
  {
    "city": "Inashiki",
    "city_ascii": "Inashiki",
    "lat": 35.9567,
    "lng": 140.3239,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Ibaraki",
    "capital": "",
    "population": 39854,
    "id": 1392230730
  },
  {
    "city": "Otavalo",
    "city_ascii": "Otavalo",
    "lat": 0.2333,
    "lng": -78.2667,
    "country": "Ecuador",
    "iso2": "EC",
    "iso3": "ECU",
    "admin_name": "Imbabura",
    "capital": "",
    "population": 39354,
    "id": 1218541444
  },
  {
    "city": "Ettlingen",
    "city_ascii": "Ettlingen",
    "lat": 48.9333,
    "lng": 8.4,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 39339,
    "id": 1276126657
  },
  {
    "city": "Alashankou",
    "city_ascii": "Alashankou",
    "lat": 45.1714,
    "lng": 82.5731,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Xinjiang",
    "capital": "minor",
    "population": 40000,
    "id": 1156292896
  },
  {
    "city": "Clinton",
    "city_ascii": "Clinton",
    "lat": 38.7499,
    "lng": -76.9063,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 40027,
    "id": 1840005949
  },
  {
    "city": "Jayrūd",
    "city_ascii": "Jayrud",
    "lat": 33.8072,
    "lng": 36.7403,
    "country": "Syria",
    "iso2": "SY",
    "iso3": "SYR",
    "admin_name": "Rīf Dimashq",
    "capital": "minor",
    "population": 39903,
    "id": 1760101368
  },
  {
    "city": "Hitachiomiya",
    "city_ascii": "Hitachiomiya",
    "lat": 36.5517,
    "lng": 140.4119,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Ibaraki",
    "capital": "",
    "population": 39904,
    "id": 1392000308
  },
  {
    "city": "Chrzanów",
    "city_ascii": "Chrzanow",
    "lat": 50.1333,
    "lng": 19.4,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Małopolskie",
    "capital": "minor",
    "population": 38989,
    "id": 1616227522
  },
  {
    "city": "Torrington",
    "city_ascii": "Torrington",
    "lat": 41.8349,
    "lng": -73.1281,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 40003,
    "id": 1840004770
  },
  {
    "city": "Cedar City",
    "city_ascii": "Cedar City",
    "lat": 37.6834,
    "lng": -113.0956,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Utah",
    "capital": "",
    "population": 39995,
    "id": 1840018895
  },
  {
    "city": "Coram",
    "city_ascii": "Coram",
    "lat": 40.8813,
    "lng": -73.0059,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 39977,
    "id": 1840005032
  },
  {
    "city": "Halle",
    "city_ascii": "Halle",
    "lat": 50.7361,
    "lng": 4.2372,
    "country": "Belgium",
    "iso2": "BE",
    "iso3": "BEL",
    "admin_name": "Flanders",
    "capital": "",
    "population": 39096,
    "id": 1056061836
  },
  {
    "city": "Rauma",
    "city_ascii": "Rauma",
    "lat": 61.1167,
    "lng": 21.5,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Satakunta",
    "capital": "minor",
    "population": 39809,
    "id": 1246262958
  },
  {
    "city": "Völklingen",
    "city_ascii": "Volklingen",
    "lat": 49.25,
    "lng": 6.8333,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saarland",
    "capital": "",
    "population": 39374,
    "id": 1276052749
  },
  {
    "city": "Yecapixtla",
    "city_ascii": "Yecapixtla",
    "lat": 18.8833,
    "lng": -98.865,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Morelos",
    "capital": "minor",
    "population": 39859,
    "id": 1484433908
  },
  {
    "city": "Jarosław",
    "city_ascii": "Jaroslaw",
    "lat": 50.0162,
    "lng": 22.6778,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Podkarpackie",
    "capital": "minor",
    "population": 38796,
    "id": 1616590558
  },
  {
    "city": "Écija",
    "city_ascii": "Ecija",
    "lat": 37.5411,
    "lng": -5.0792,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Andalusia",
    "capital": "",
    "population": 39873,
    "id": 1724919208
  },
  {
    "city": "Sakuragawa",
    "city_ascii": "Sakuragawa",
    "lat": 36.3272,
    "lng": 140.0906,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Ibaraki",
    "capital": "",
    "population": 39692,
    "id": 1392000708
  },
  {
    "city": "Michalovce",
    "city_ascii": "Michalovce",
    "lat": 48.7575,
    "lng": 21.9183,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Košický",
    "capital": "minor",
    "population": 39151,
    "id": 1703150232
  },
  {
    "city": "Matías Romero",
    "city_ascii": "Matias Romero",
    "lat": 16.8798,
    "lng": -95.0391,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Oaxaca",
    "capital": "",
    "population": 39828,
    "id": 1484296757
  },
  {
    "city": "Manalapan",
    "city_ascii": "Manalapan",
    "lat": 40.28,
    "lng": -74.3436,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 39853,
    "id": 1840081648
  },
  {
    "city": "Würselen",
    "city_ascii": "Wurselen",
    "lat": 50.8247,
    "lng": 6.1275,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 38712,
    "id": 1276620771
  },
  {
    "city": "Crystal Lake",
    "city_ascii": "Crystal Lake",
    "lat": 42.2333,
    "lng": -88.3351,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 39829,
    "id": 1840006969
  },
  {
    "city": "Calexico",
    "city_ascii": "Calexico",
    "lat": 32.6849,
    "lng": -115.4944,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 39825,
    "id": 1840019379
  },
  {
    "city": "Lake Oswego",
    "city_ascii": "Lake Oswego",
    "lat": 45.413,
    "lng": -122.7003,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 39822,
    "id": 1840019955
  },
  {
    "city": "Villamontes",
    "city_ascii": "Villamontes",
    "lat": -21.2647,
    "lng": -63.4586,
    "country": "Bolivia",
    "iso2": "BO",
    "iso3": "BOL",
    "admin_name": "Tarija",
    "capital": "",
    "population": 39800,
    "id": 1068626988
  },
  {
    "city": "Apsheronsk",
    "city_ascii": "Apsheronsk",
    "lat": 44.4608,
    "lng": 39.7406,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Krasnodarskiy Kray",
    "capital": "minor",
    "population": 39762,
    "id": 1643014988
  },
  {
    "city": "Muskogee",
    "city_ascii": "Muskogee",
    "lat": 35.7431,
    "lng": -95.3568,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oklahoma",
    "capital": "",
    "population": 39789,
    "id": 1840020401
  },
  {
    "city": "Steyr",
    "city_ascii": "Steyr",
    "lat": 48.05,
    "lng": 14.4167,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Oberösterreich",
    "capital": "minor",
    "population": 38331,
    "id": 1040132113
  },
  {
    "city": "Romeoville",
    "city_ascii": "Romeoville",
    "lat": 41.6318,
    "lng": -88.0996,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 39746,
    "id": 1840011489
  },
  {
    "city": "Plant City",
    "city_ascii": "Plant City",
    "lat": 28.014,
    "lng": -82.1201,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 39744,
    "id": 1840015115
  },
  {
    "city": "Mettmann",
    "city_ascii": "Mettmann",
    "lat": 51.25,
    "lng": 6.9667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "minor",
    "population": 38829,
    "id": 1276000749
  },
  {
    "city": "Amherst",
    "city_ascii": "Amherst",
    "lat": 42.3645,
    "lng": -72.5069,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 39741,
    "id": 1840053454
  },
  {
    "city": "Solan",
    "city_ascii": "Solan",
    "lat": 30.92,
    "lng": 77.12,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Himāchal Pradesh",
    "capital": "",
    "population": 39256,
    "id": 1356105721
  },
  {
    "city": "Novodvinsk",
    "city_ascii": "Novodvinsk",
    "lat": 64.4167,
    "lng": 40.8167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Arkhangel’skaya Oblast’",
    "capital": "",
    "population": 38434,
    "id": 1643592717
  },
  {
    "city": "Zacatelco",
    "city_ascii": "Zacatelco",
    "lat": 19.2167,
    "lng": -98.2333,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Tlaxcala",
    "capital": "minor",
    "population": 38466,
    "id": 1484460521
  },
  {
    "city": "New Berlin",
    "city_ascii": "New Berlin",
    "lat": 42.9726,
    "lng": -88.1291,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 39691,
    "id": 1840003025
  },
  {
    "city": "La Chaux-de-Fonds",
    "city_ascii": "La Chaux-de-Fonds",
    "lat": 47.0996,
    "lng": 6.8296,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Neuchâtel",
    "capital": "minor",
    "population": 38965,
    "id": 1756289727
  },
  {
    "city": "The Acreage",
    "city_ascii": "The Acreage",
    "lat": 26.7741,
    "lng": -80.2779,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 39656,
    "id": 1840026061
  },
  {
    "city": "Socorro",
    "city_ascii": "Socorro",
    "lat": -22.5908,
    "lng": -46.5289,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 39565,
    "id": 1076716761
  },
  {
    "city": "Gujō",
    "city_ascii": "Gujo",
    "lat": 35.7486,
    "lng": 136.9644,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Gifu",
    "capital": "",
    "population": 39582,
    "id": 1392159452
  },
  {
    "city": "Roy",
    "city_ascii": "Roy",
    "lat": 41.1715,
    "lng": -112.0485,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Utah",
    "capital": "",
    "population": 39613,
    "id": 1840020132
  },
  {
    "city": "Villa del Carbón",
    "city_ascii": "Villa del Carbon",
    "lat": 19.7272,
    "lng": -99.5417,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "México",
    "capital": "minor",
    "population": 39587,
    "id": 1484004677
  },
  {
    "city": "Dupnitsa",
    "city_ascii": "Dupnitsa",
    "lat": 42.265,
    "lng": 23.1178,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Kyustendil",
    "capital": "",
    "population": 39467,
    "id": 1100440807
  },
  {
    "city": "Marlborough",
    "city_ascii": "Marlborough",
    "lat": 42.3494,
    "lng": -71.5468,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 39597,
    "id": 1840000427
  },
  {
    "city": "Milton",
    "city_ascii": "Milton",
    "lat": 34.1353,
    "lng": -84.3138,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 39587,
    "id": 1840014738
  },
  {
    "city": "Stendal",
    "city_ascii": "Stendal",
    "lat": 52.6,
    "lng": 11.85,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony-Anhalt",
    "capital": "minor",
    "population": 39439,
    "id": 1276016814
  },
  {
    "city": "Novo Horizonte",
    "city_ascii": "Novo Horizonte",
    "lat": -21.4678,
    "lng": -49.2208,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 39543,
    "id": 1076842025
  },
  {
    "city": "Trofa",
    "city_ascii": "Trofa",
    "lat": 41.3374,
    "lng": -8.5596,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Porto",
    "capital": "minor",
    "population": 38999,
    "id": 1620889916
  },
  {
    "city": "Pokrov",
    "city_ascii": "Pokrov",
    "lat": 47.6632,
    "lng": 34.0811,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Dnipropetrovs’ka Oblast’",
    "capital": "minor",
    "population": 39497,
    "id": 1804743879
  },
  {
    "city": "Higashimatsushima",
    "city_ascii": "Higashimatsushima",
    "lat": 38.4264,
    "lng": 141.2106,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Miyagi",
    "capital": "",
    "population": 39138,
    "id": 1392705600
  },
  {
    "city": "Andover",
    "city_ascii": "Andover",
    "lat": 51.208,
    "lng": -1.48,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hampshire",
    "capital": "",
    "population": 38290,
    "id": 1826277987
  },
  {
    "city": "Borlänge",
    "city_ascii": "Borlange",
    "lat": 60.4833,
    "lng": 15.4167,
    "country": "Sweden",
    "iso2": "SE",
    "iso3": "SWE",
    "admin_name": "Dalarna",
    "capital": "minor",
    "population": 39422,
    "id": 1752944924
  },
  {
    "city": "Issaquah",
    "city_ascii": "Issaquah",
    "lat": 47.544,
    "lng": -122.0471,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 39509,
    "id": 1840018422
  },
  {
    "city": "Ahaus",
    "city_ascii": "Ahaus",
    "lat": 52.0794,
    "lng": 7.0134,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 39223,
    "id": 1276411347
  },
  {
    "city": "Presidente Venceslau",
    "city_ascii": "Presidente Venceslau",
    "lat": -21.8761,
    "lng": -51.8439,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 39407,
    "id": 1076627824
  },
  {
    "city": "Nishiwaki",
    "city_ascii": "Nishiwaki",
    "lat": 34.9933,
    "lng": 134.9694,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hyōgo",
    "capital": "",
    "population": 39147,
    "id": 1392948535
  },
  {
    "city": "Sayansk",
    "city_ascii": "Sayansk",
    "lat": 54.1167,
    "lng": 102.1667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Irkutskaya Oblast’",
    "capital": "",
    "population": 38968,
    "id": 1643517823
  },
  {
    "city": "Szigetszentmiklós",
    "city_ascii": "Szigetszentmiklos",
    "lat": 47.3453,
    "lng": 19.0483,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Pest",
    "capital": "minor",
    "population": 38591,
    "id": 1348768623
  },
  {
    "city": "Hillsborough",
    "city_ascii": "Hillsborough",
    "lat": 40.4985,
    "lng": -74.674,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 39397,
    "id": 1840081699
  },
  {
    "city": "Eccles",
    "city_ascii": "Eccles",
    "lat": 53.4824,
    "lng": -2.339,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Salford",
    "capital": "",
    "population": 38756,
    "id": 1826711263
  },
  {
    "city": "Apan",
    "city_ascii": "Apan",
    "lat": 19.7,
    "lng": -98.4333,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Hidalgo",
    "capital": "minor",
    "population": 39247,
    "id": 1484086106
  },
  {
    "city": "Sun City",
    "city_ascii": "Sun City",
    "lat": 33.6165,
    "lng": -112.2819,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 39348,
    "id": 1840019311
  },
  {
    "city": "Palmas",
    "city_ascii": "Palmas",
    "lat": -26.4842,
    "lng": -51.9906,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Paraná",
    "capital": "",
    "population": 39150,
    "id": 1076528204
  },
  {
    "city": "Ibara",
    "city_ascii": "Ibara",
    "lat": 34.5978,
    "lng": 133.4639,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Okayama",
    "capital": "",
    "population": 39172,
    "id": 1392000586
  },
  {
    "city": "Northampton Township",
    "city_ascii": "Northampton Township",
    "lat": 40.2104,
    "lng": -75.0014,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 39312,
    "id": 1840151117
  },
  {
    "city": "Bartlesville",
    "city_ascii": "Bartlesville",
    "lat": 36.7357,
    "lng": -95.9453,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oklahoma",
    "capital": "",
    "population": 39301,
    "id": 1840018977
  },
  {
    "city": "Baghlān",
    "city_ascii": "Baghlan",
    "lat": 36.1328,
    "lng": 68.7,
    "country": "Afghanistan",
    "iso2": "AF",
    "iso3": "AFG",
    "admin_name": "Baghlān",
    "capital": "minor",
    "population": 39228,
    "id": 1004943705
  },
  {
    "city": "Niederkassel",
    "city_ascii": "Niederkassel",
    "lat": 50.8167,
    "lng": 7.0333,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 38218,
    "id": 1276316077
  },
  {
    "city": "Volodymyr-Volyns’kyy",
    "city_ascii": "Volodymyr-Volyns'kyy",
    "lat": 50.8481,
    "lng": 24.3222,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Volyns’ka Oblast’",
    "capital": "minor",
    "population": 38901,
    "id": 1804155195
  },
  {
    "city": "Ilmenau",
    "city_ascii": "Ilmenau",
    "lat": 50.6872,
    "lng": 10.9142,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Thuringia",
    "capital": "",
    "population": 39017,
    "id": 1276541405
  },
  {
    "city": "Hatfield",
    "city_ascii": "Hatfield",
    "lat": 51.762,
    "lng": -0.228,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hertfordshire",
    "capital": "",
    "population": 39201,
    "id": 1826844690
  },
  {
    "city": "Lancaster",
    "city_ascii": "Lancaster",
    "lat": 32.5922,
    "lng": -96.7737,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 39228,
    "id": 1840020714
  },
  {
    "city": "Streamwood",
    "city_ascii": "Streamwood",
    "lat": 42.0209,
    "lng": -88.1778,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 39228,
    "id": 1840011328
  },
  {
    "city": "Germantown",
    "city_ascii": "Germantown",
    "lat": 35.0829,
    "lng": -89.7825,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 39225,
    "id": 1840013473
  },
  {
    "city": "Great Yarmouth",
    "city_ascii": "Great Yarmouth",
    "lat": 52.606,
    "lng": 1.729,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Norfolk",
    "capital": "",
    "population": 38693,
    "id": 1826653059
  },
  {
    "city": "Neu Isenburg",
    "city_ascii": "Neu Isenburg",
    "lat": 50.0558,
    "lng": 8.6971,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 37668,
    "id": 1276467901
  },
  {
    "city": "Yelizovo",
    "city_ascii": "Yelizovo",
    "lat": 53.1833,
    "lng": 158.3833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kamchatskiy Kray",
    "capital": "",
    "population": 39216,
    "id": 1643784414
  },
  {
    "city": "Langen",
    "city_ascii": "Langen",
    "lat": 49.9893,
    "lng": 8.6803,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 37902,
    "id": 1276365275
  },
  {
    "city": "Carol Stream",
    "city_ascii": "Carol Stream",
    "lat": 41.9182,
    "lng": -88.1308,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 39203,
    "id": 1840010169
  },
  {
    "city": "Misawa",
    "city_ascii": "Misawa",
    "lat": 40.6833,
    "lng": 141.3689,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Aomori",
    "capital": "",
    "population": 38877,
    "id": 1392268760
  },
  {
    "city": "Guiglo",
    "city_ascii": "Guiglo",
    "lat": 6.5436,
    "lng": -7.4933,
    "country": "Côte D’Ivoire",
    "iso2": "CI",
    "iso3": "CIV",
    "admin_name": "Montagnes",
    "capital": "minor",
    "population": 39134,
    "id": 1384483732
  },
  {
    "city": "Asheboro",
    "city_ascii": "Asheboro",
    "lat": 35.7158,
    "lng": -79.8129,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 39192,
    "id": 1840013400
  },
  {
    "city": "Kalispell",
    "city_ascii": "Kalispell",
    "lat": 48.2156,
    "lng": -114.3262,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Montana",
    "capital": "",
    "population": 39182,
    "id": 1840018350
  },
  {
    "city": "Cayambe",
    "city_ascii": "Cayambe",
    "lat": 0.0439,
    "lng": -78.1561,
    "country": "Ecuador",
    "iso2": "EC",
    "iso3": "ECU",
    "admin_name": "Pichincha",
    "capital": "",
    "population": 39028,
    "id": 1218475970
  },
  {
    "city": "Baikonur",
    "city_ascii": "Baikonur",
    "lat": 45.63,
    "lng": 63.314,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Qyzylorda",
    "capital": "",
    "population": 39161,
    "id": 1398535530
  },
  {
    "city": "Dwārka",
    "city_ascii": "Dwarka",
    "lat": 22.2403,
    "lng": 68.9686,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Gujarāt",
    "capital": "",
    "population": 38873,
    "id": 1356276872
  },
  {
    "city": "Lynnwood",
    "city_ascii": "Lynnwood",
    "lat": 47.8284,
    "lng": -122.3033,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 39141,
    "id": 1840019788
  },
  {
    "city": "Mooresville",
    "city_ascii": "Mooresville",
    "lat": 35.5849,
    "lng": -80.8265,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 39132,
    "id": 1840016215
  },
  {
    "city": "Kachkanar",
    "city_ascii": "Kachkanar",
    "lat": 58.7,
    "lng": 59.4833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sverdlovskaya Oblast’",
    "capital": "",
    "population": 38996,
    "id": 1643014299
  },
  {
    "city": "Al Mālikīyah",
    "city_ascii": "Al Malikiyah",
    "lat": 37.1781,
    "lng": 42.1403,
    "country": "Syria",
    "iso2": "SY",
    "iso3": "SYR",
    "admin_name": "Al Ḩasakah",
    "capital": "minor",
    "population": 39000,
    "id": 1760784945
  },
  {
    "city": "Brookfield",
    "city_ascii": "Brookfield",
    "lat": 43.064,
    "lng": -88.1231,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 39115,
    "id": 1840003012
  },
  {
    "city": "Groton",
    "city_ascii": "Groton",
    "lat": 41.3597,
    "lng": -72.0293,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 39105,
    "id": 1840004824
  },
  {
    "city": "Nuevitas",
    "city_ascii": "Nuevitas",
    "lat": 21.5403,
    "lng": -77.2644,
    "country": "Cuba",
    "iso2": "CU",
    "iso3": "CUB",
    "admin_name": "Camagüey",
    "capital": "",
    "population": 38995,
    "id": 1192448182
  },
  {
    "city": "Crestview",
    "city_ascii": "Crestview",
    "lat": 30.748,
    "lng": -86.5784,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 39072,
    "id": 1840015007
  },
  {
    "city": "Toumodi",
    "city_ascii": "Toumodi",
    "lat": 6.552,
    "lng": -5.019,
    "country": "Côte D’Ivoire",
    "iso2": "CI",
    "iso3": "CIV",
    "admin_name": "Lacs",
    "capital": "minor",
    "population": 39005,
    "id": 1384416676
  },
  {
    "city": "Kurchatov",
    "city_ascii": "Kurchatov",
    "lat": 51.6667,
    "lng": 35.65,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kurskaya Oblast’",
    "capital": "",
    "population": 38344,
    "id": 1643764083
  },
  {
    "city": "Pirna",
    "city_ascii": "Pirna",
    "lat": 50.9622,
    "lng": 13.9403,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "minor",
    "population": 38316,
    "id": 1276247543
  },
  {
    "city": "Rtishchevo",
    "city_ascii": "Rtishchevo",
    "lat": 52.25,
    "lng": 43.7833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Saratovskaya Oblast’",
    "capital": "",
    "population": 39005,
    "id": 1643692612
  },
  {
    "city": "Pala Oua",
    "city_ascii": "Pala Oua",
    "lat": 9.35,
    "lng": 14.9667,
    "country": "Chad",
    "iso2": "TD",
    "iso3": "TCD",
    "admin_name": "Mayo-Kébbi Ouest",
    "capital": "",
    "population": 38975,
    "id": 1148631807
  },
  {
    "city": "Moses Lake",
    "city_ascii": "Moses Lake",
    "lat": 47.128,
    "lng": -119.2761,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 39002,
    "id": 1840019817
  },
  {
    "city": "Joué-lés-Tours",
    "city_ascii": "Joue-les-Tours",
    "lat": 47.3514,
    "lng": 0.6625,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Centre-Val de Loire",
    "capital": "",
    "population": 37893,
    "id": 1250289965
  },
  {
    "city": "Vasyl’kiv",
    "city_ascii": "Vasyl'kiv",
    "lat": 50.1775,
    "lng": 30.3217,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Kyyivs’ka Oblast’",
    "capital": "minor",
    "population": 37696,
    "id": 1804342314
  },
  {
    "city": "Woodlawn",
    "city_ascii": "Woodlawn",
    "lat": 39.3054,
    "lng": -76.7489,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 38951,
    "id": 1840005697
  },
  {
    "city": "Tshela",
    "city_ascii": "Tshela",
    "lat": -4.9696,
    "lng": 12.93,
    "country": "Congo (Kinshasa)",
    "iso2": "CD",
    "iso3": "COD",
    "admin_name": "Kongo Central",
    "capital": "",
    "population": 38845,
    "id": 1180377922
  },
  {
    "city": "Dubno",
    "city_ascii": "Dubno",
    "lat": 50.3931,
    "lng": 25.735,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Rivnens’ka Oblast’",
    "capital": "minor",
    "population": 37545,
    "id": 1804759407
  },
  {
    "city": "Galeana",
    "city_ascii": "Galeana",
    "lat": 24.8333,
    "lng": -100.0667,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Nuevo León",
    "capital": "minor",
    "population": 38930,
    "id": 1484336701
  },
  {
    "city": "New Castle",
    "city_ascii": "New Castle",
    "lat": 40.9956,
    "lng": -80.3459,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 38930,
    "id": 1840000938
  },
  {
    "city": "Olive Branch",
    "city_ascii": "Olive Branch",
    "lat": 34.961,
    "lng": -89.8469,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Mississippi",
    "capital": "",
    "population": 38924,
    "id": 1840014641
  },
  {
    "city": "Winslow",
    "city_ascii": "Winslow",
    "lat": 39.7027,
    "lng": -74.9029,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 38923,
    "id": 1840081680
  },
  {
    "city": "Bitterfeld",
    "city_ascii": "Bitterfeld",
    "lat": 51.6167,
    "lng": 12.3167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 38475,
    "id": 1276169908
  },
  {
    "city": "Enterprise",
    "city_ascii": "Enterprise",
    "lat": 31.3276,
    "lng": -85.8459,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 38914,
    "id": 1840001548
  },
  {
    "city": "Bautzen",
    "city_ascii": "Bautzen",
    "lat": 51.1814,
    "lng": 14.4239,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "minor",
    "population": 38329,
    "id": 1276088190
  },
  {
    "city": "Marinha Grande",
    "city_ascii": "Marinha Grande",
    "lat": 39.75,
    "lng": -8.9333,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Leiria",
    "capital": "minor",
    "population": 38681,
    "id": 1620186271
  },
  {
    "city": "Melchor Ocampo",
    "city_ascii": "Melchor Ocampo",
    "lat": 19.7083,
    "lng": -99.1444,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "México",
    "capital": "minor",
    "population": 37706,
    "id": 1484243108
  },
  {
    "city": "Umi",
    "city_ascii": "Umi",
    "lat": 33.5678,
    "lng": 130.5111,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Fukuoka",
    "capital": "",
    "population": 37609,
    "id": 1392401859
  },
  {
    "city": "Skelmersdale",
    "city_ascii": "Skelmersdale",
    "lat": 53.55,
    "lng": -2.776,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Lancashire",
    "capital": "",
    "population": 38813,
    "id": 1826978666
  },
  {
    "city": "Fürstenfeldbruck",
    "city_ascii": "Furstenfeldbruck",
    "lat": 48.1778,
    "lng": 11.2556,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "minor",
    "population": 37677,
    "id": 1276347995
  },
  {
    "city": "Balcarce",
    "city_ascii": "Balcarce",
    "lat": -37.8464,
    "lng": -58.2556,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires",
    "capital": "minor",
    "population": 38823,
    "id": 1032847478
  },
  {
    "city": "Mission",
    "city_ascii": "Mission",
    "lat": 49.1337,
    "lng": -122.3112,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "British Columbia",
    "capital": "",
    "population": 38833,
    "id": 1124502601
  },
  {
    "city": "Pervomaisk",
    "city_ascii": "Pervomaisk",
    "lat": 48.6333,
    "lng": 38.5167,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Luhans’ka Oblast’",
    "capital": "",
    "population": 38801,
    "id": 1804078779
  },
  {
    "city": "Bukama",
    "city_ascii": "Bukama",
    "lat": -9.2,
    "lng": 25.8333,
    "country": "Congo (Kinshasa)",
    "iso2": "CD",
    "iso3": "COD",
    "admin_name": "Haut-Lomami",
    "capital": "",
    "population": 38770,
    "id": 1180047354
  },
  {
    "city": "Promissão",
    "city_ascii": "Promissao",
    "lat": -21.5369,
    "lng": -49.8583,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 38764,
    "id": 1076199684
  },
  {
    "city": "Fāraskūr",
    "city_ascii": "Faraskur",
    "lat": 31.3298,
    "lng": 31.7151,
    "country": "Egypt",
    "iso2": "EG",
    "iso3": "EGY",
    "admin_name": "Dumyāţ",
    "capital": "",
    "population": 36850,
    "id": 1818275538
  },
  {
    "city": "Dabola",
    "city_ascii": "Dabola",
    "lat": 10.75,
    "lng": -11.1167,
    "country": "Guinea",
    "iso2": "GN",
    "iso3": "GIN",
    "admin_name": "Faranah",
    "capital": "minor",
    "population": 38617,
    "id": 1324368075
  },
  {
    "city": "Warren",
    "city_ascii": "Warren",
    "lat": 41.239,
    "lng": -80.8174,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 38752,
    "id": 1840000652
  },
  {
    "city": "Duncanville",
    "city_ascii": "Duncanville",
    "lat": 32.646,
    "lng": -96.9127,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 38751,
    "id": 1840019442
  },
  {
    "city": "Dzhankoy",
    "city_ascii": "Dzhankoy",
    "lat": 45.7086,
    "lng": 34.3933,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Krym, Avtonomna Respublika",
    "capital": "minor",
    "population": 38714,
    "id": 1804654537
  },
  {
    "city": "Nové Zámky",
    "city_ascii": "Nove Zamky",
    "lat": 47.9831,
    "lng": 18.1728,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Nitriansky",
    "capital": "minor",
    "population": 38172,
    "id": 1703734556
  },
  {
    "city": "Hurst",
    "city_ascii": "Hurst",
    "lat": 32.8353,
    "lng": -97.1808,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 38655,
    "id": 1840019427
  },
  {
    "city": "Clermont",
    "city_ascii": "Clermont",
    "lat": 28.5402,
    "lng": -81.7259,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 38654,
    "id": 1840015080
  },
  {
    "city": "Mariinsk",
    "city_ascii": "Mariinsk",
    "lat": 56.2167,
    "lng": 87.75,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kemerovskaya Oblast’",
    "capital": "",
    "population": 38637,
    "id": 1643015007
  },
  {
    "city": "Wheeling",
    "city_ascii": "Wheeling",
    "lat": 42.1308,
    "lng": -87.924,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 38646,
    "id": 1840011334
  },
  {
    "city": "Guariba",
    "city_ascii": "Guariba",
    "lat": -21.36,
    "lng": -48.2283,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 38499,
    "id": 1076624279
  },
  {
    "city": "Krasnoufimsk",
    "city_ascii": "Krasnoufimsk",
    "lat": 56.6167,
    "lng": 57.7667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sverdlovskaya Oblast’",
    "capital": "",
    "population": 38395,
    "id": 1643806300
  },
  {
    "city": "Żary",
    "city_ascii": "Zary",
    "lat": 51.6333,
    "lng": 15.1333,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Lubuskie",
    "capital": "minor",
    "population": 37502,
    "id": 1616092414
  },
  {
    "city": "Abinsk",
    "city_ascii": "Abinsk",
    "lat": 44.8667,
    "lng": 38.1667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Krasnodarskiy Kray",
    "capital": "minor",
    "population": 38547,
    "id": 1643216828
  },
  {
    "city": "Dubbo",
    "city_ascii": "Dubbo",
    "lat": -32.2569,
    "lng": 148.6011,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 38392,
    "id": 1036855042
  },
  {
    "city": "Darāw",
    "city_ascii": "Daraw",
    "lat": 24.4003,
    "lng": 32.9306,
    "country": "Egypt",
    "iso2": "EG",
    "iso3": "EGY",
    "admin_name": "Aswān",
    "capital": "",
    "population": 38400,
    "id": 1818705709
  },
  {
    "city": "San Felipe Orizatlán",
    "city_ascii": "San Felipe Orizatlan",
    "lat": 21.1719,
    "lng": -98.6064,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Hidalgo",
    "capital": "",
    "population": 38472,
    "id": 1484942420
  },
  {
    "city": "Uryupinsk",
    "city_ascii": "Uryupinsk",
    "lat": 50.8,
    "lng": 42.0167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Volgogradskaya Oblast’",
    "capital": "",
    "population": 37620,
    "id": 1643866080
  },
  {
    "city": "Severna Park",
    "city_ascii": "Severna Park",
    "lat": 39.087,
    "lng": -76.5687,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 38576,
    "id": 1840005926
  },
  {
    "city": "Kardítsa",
    "city_ascii": "Karditsa",
    "lat": 39.3647,
    "lng": 21.9219,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Thessalía",
    "capital": "minor",
    "population": 38554,
    "id": 1300471729
  },
  {
    "city": "Khalkhāl",
    "city_ascii": "Khalkhal",
    "lat": 37.6189,
    "lng": 48.5258,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Ardabīl",
    "capital": "minor",
    "population": 38521,
    "id": 1364932128
  },
  {
    "city": "Pacifica",
    "city_ascii": "Pacifica",
    "lat": 37.6112,
    "lng": -122.4781,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 38546,
    "id": 1840020307
  },
  {
    "city": "Caibarién",
    "city_ascii": "Caibarien",
    "lat": 22.5158,
    "lng": -79.4722,
    "country": "Cuba",
    "iso2": "CU",
    "iso3": "CUB",
    "admin_name": "Villa Clara",
    "capital": "minor",
    "population": 38479,
    "id": 1192208164
  },
  {
    "city": "Konakovo",
    "city_ascii": "Konakovo",
    "lat": 56.7129,
    "lng": 36.7783,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Tverskaya Oblast’",
    "capital": "",
    "population": 38486,
    "id": 1643330779
  },
  {
    "city": "Lebanon",
    "city_ascii": "Lebanon",
    "lat": 36.204,
    "lng": -86.3481,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 38518,
    "id": 1840014466
  },
  {
    "city": "Falāvarjān",
    "city_ascii": "Falavarjan",
    "lat": 32.5553,
    "lng": 51.5097,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Eşfahān",
    "capital": "minor",
    "population": 37740,
    "id": 1364269609
  },
  {
    "city": "Américo Brasiliense",
    "city_ascii": "Americo Brasiliense",
    "lat": -21.7361,
    "lng": -48.1114,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 38202,
    "id": 1076027153
  },
  {
    "city": "Sassandra",
    "city_ascii": "Sassandra",
    "lat": 4.9504,
    "lng": -6.0833,
    "country": "Côte D’Ivoire",
    "iso2": "CI",
    "iso3": "CIV",
    "admin_name": "Bas-Sassandra",
    "capital": "minor",
    "population": 38411,
    "id": 1384107875
  },
  {
    "city": "Ventanas",
    "city_ascii": "Ventanas",
    "lat": -1.45,
    "lng": -79.47,
    "country": "Ecuador",
    "iso2": "EC",
    "iso3": "ECU",
    "admin_name": "Los Ríos",
    "capital": "",
    "population": 38168,
    "id": 1218067203
  },
  {
    "city": "Hanumānnagar",
    "city_ascii": "Hanumannagar",
    "lat": 26.5394,
    "lng": 86.7483,
    "country": "Nepal",
    "iso2": "NP",
    "iso3": "NPL",
    "admin_name": "Sagarmāthā",
    "capital": "",
    "population": 37738,
    "id": 1524363655
  },
  {
    "city": "San Jacinto de Buena Fe",
    "city_ascii": "San Jacinto de Buena Fe",
    "lat": -0.8932,
    "lng": -79.4907,
    "country": "Ecuador",
    "iso2": "EC",
    "iso3": "ECU",
    "admin_name": "Los Ríos",
    "capital": "",
    "population": 38263,
    "id": 1218724130
  },
  {
    "city": "Kaka",
    "city_ascii": "Kaka",
    "lat": 37.3503,
    "lng": 59.6,
    "country": "Turkmenistan",
    "iso2": "TM",
    "iso3": "TKM",
    "admin_name": "Ahal",
    "capital": "",
    "population": 38381,
    "id": 1795223342
  },
  {
    "city": "Eagle Mountain",
    "city_ascii": "Eagle Mountain",
    "lat": 40.3137,
    "lng": -112.0114,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Utah",
    "capital": "",
    "population": 38391,
    "id": 1840018757
  },
  {
    "city": "Usinsk",
    "city_ascii": "Usinsk",
    "lat": 65.9998,
    "lng": 57.5414,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Komi",
    "capital": "",
    "population": 38376,
    "id": 1643395315
  },
  {
    "city": "Sharypovo",
    "city_ascii": "Sharypovo",
    "lat": 55.525,
    "lng": 89.2,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Krasnoyarskiy Kray",
    "capital": "",
    "population": 37136,
    "id": 1643451385
  },
  {
    "city": "Atamyrat",
    "city_ascii": "Atamyrat",
    "lat": 37.8167,
    "lng": 65.2,
    "country": "Turkmenistan",
    "iso2": "TM",
    "iso3": "TKM",
    "admin_name": "Lebap",
    "capital": "",
    "population": 38350,
    "id": 1795595010
  },
  {
    "city": "Alekseyevka",
    "city_ascii": "Alekseyevka",
    "lat": 50.6333,
    "lng": 38.6833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Belgorodskaya Oblast’",
    "capital": "",
    "population": 38329,
    "id": 1643033169
  },
  {
    "city": "Bol’shoy Kamen’",
    "city_ascii": "Bol'shoy Kamen'",
    "lat": 43.1167,
    "lng": 132.35,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Primorskiy Kray",
    "capital": "",
    "population": 38042,
    "id": 1643747678
  },
  {
    "city": "Reynoldsburg",
    "city_ascii": "Reynoldsburg",
    "lat": 39.9587,
    "lng": -82.7944,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 38327,
    "id": 1840009473
  },
  {
    "city": "Sabang",
    "city_ascii": "Sabang",
    "lat": 5.8931,
    "lng": 95.32,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Aceh",
    "capital": "",
    "population": 38077,
    "id": 1360742506
  },
  {
    "city": "Pathanāmthitta",
    "city_ascii": "Pathanamthitta",
    "lat": 9.2647,
    "lng": 76.7872,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Kerala",
    "capital": "",
    "population": 37802,
    "id": 1356059485
  },
  {
    "city": "Krasnyy Sulin",
    "city_ascii": "Krasnyy Sulin",
    "lat": 47.8833,
    "lng": 40.0667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Rostovskaya Oblast’",
    "capital": "minor",
    "population": 38284,
    "id": 1643848888
  },
  {
    "city": "Lyudinovo",
    "city_ascii": "Lyudinovo",
    "lat": 53.8667,
    "lng": 34.4667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kaluzhskaya Oblast’",
    "capital": "",
    "population": 38267,
    "id": 1643598604
  },
  {
    "city": "Oxford",
    "city_ascii": "Oxford",
    "lat": 34.3627,
    "lng": -89.5336,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Mississippi",
    "capital": "",
    "population": 38310,
    "id": 1840014688
  },
  {
    "city": "Rosenberg",
    "city_ascii": "Rosenberg",
    "lat": 29.546,
    "lng": -95.822,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 38307,
    "id": 1840020948
  },
  {
    "city": "Pitangueiras",
    "city_ascii": "Pitangueiras",
    "lat": -21.0094,
    "lng": -48.2217,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 38211,
    "id": 1076000181
  },
  {
    "city": "Martinez",
    "city_ascii": "Martinez",
    "lat": 37.9985,
    "lng": -122.116,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 38297,
    "id": 1840020278
  },
  {
    "city": "Zavolzhye",
    "city_ascii": "Zavolzhye",
    "lat": 56.6425,
    "lng": 43.3928,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Nizhegorodskaya Oblast’",
    "capital": "",
    "population": 38230,
    "id": 1643954070
  },
  {
    "city": "Pleasant Grove",
    "city_ascii": "Pleasant Grove",
    "lat": 40.3716,
    "lng": -111.7412,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Utah",
    "capital": "",
    "population": 38258,
    "id": 1840020173
  },
  {
    "city": "Backnang",
    "city_ascii": "Backnang",
    "lat": 48.9464,
    "lng": 9.4306,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 37253,
    "id": 1276031423
  },
  {
    "city": "Valrico",
    "city_ascii": "Valrico",
    "lat": 27.9193,
    "lng": -82.2293,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 38169,
    "id": 1840028790
  },
  {
    "city": "Houghton le Spring",
    "city_ascii": "Houghton le Spring",
    "lat": 54.841,
    "lng": -1.468,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Sunderland",
    "capital": "",
    "population": 36746,
    "id": 1826945189
  },
  {
    "city": "Taounate",
    "city_ascii": "Taounate",
    "lat": 34.5358,
    "lng": -4.64,
    "country": "Morocco",
    "iso2": "MA",
    "iso3": "MAR",
    "admin_name": "Fès-Meknès",
    "capital": "",
    "population": 37616,
    "id": 1504565730
  },
  {
    "city": "Izobil’nyy",
    "city_ascii": "Izobil'nyy",
    "lat": 45.3667,
    "lng": 41.7167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Stavropol’skiy Kray",
    "capital": "",
    "population": 38100,
    "id": 1643012152
  },
  {
    "city": "Huber Heights",
    "city_ascii": "Huber Heights",
    "lat": 39.8595,
    "lng": -84.113,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 38154,
    "id": 1840003782
  },
  {
    "city": "Security-Widefield",
    "city_ascii": "Security-Widefield",
    "lat": 38.7489,
    "lng": -104.7142,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 38134,
    "id": 1840074635
  },
  {
    "city": "Vaudreuil-Dorion",
    "city_ascii": "Vaudreuil-Dorion",
    "lat": 45.4,
    "lng": -74.0333,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 38117,
    "id": 1124618618
  },
  {
    "city": "Maroantsetra",
    "city_ascii": "Maroantsetra",
    "lat": -15.4333,
    "lng": 49.7333,
    "country": "Madagascar",
    "iso2": "MG",
    "iso3": "MDG",
    "admin_name": "Toamasina",
    "capital": "",
    "population": 38046,
    "id": 1450485257
  },
  {
    "city": "Katsuragi",
    "city_ascii": "Katsuragi",
    "lat": 34.4892,
    "lng": 135.7267,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Nara",
    "capital": "",
    "population": 37004,
    "id": 1392516369
  },
  {
    "city": "Visconde do Rio Branco",
    "city_ascii": "Visconde do Rio Branco",
    "lat": -21.0103,
    "lng": -42.8406,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Minas Gerais",
    "capital": "",
    "population": 37942,
    "id": 1076854756
  },
  {
    "city": "Boryslav",
    "city_ascii": "Boryslav",
    "lat": 49.2881,
    "lng": 23.4267,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "L’vivs’ka Oblast’",
    "capital": "",
    "population": 37093,
    "id": 1804511652
  },
  {
    "city": "Česká Lípa",
    "city_ascii": "Ceska Lipa",
    "lat": 50.6856,
    "lng": 14.5377,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Liberecký Kraj",
    "capital": "",
    "population": 37525,
    "id": 1203109242
  },
  {
    "city": "Yuzhnouralsk",
    "city_ascii": "Yuzhnouralsk",
    "lat": 54.45,
    "lng": 61.25,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Chelyabinskaya Oblast’",
    "capital": "",
    "population": 37728,
    "id": 1643319860
  },
  {
    "city": "Fuchūchō",
    "city_ascii": "Fuchucho",
    "lat": 34.5683,
    "lng": 133.2364,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hiroshima",
    "capital": "",
    "population": 37874,
    "id": 1392822358
  },
  {
    "city": "Açu",
    "city_ascii": "Acu",
    "lat": -5.5796,
    "lng": -36.9101,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Rio Grande do Norte",
    "capital": "",
    "population": 36125,
    "id": 1076000524
  },
  {
    "city": "Güira de Melena",
    "city_ascii": "Guira de Melena",
    "lat": 22.8019,
    "lng": -82.5047,
    "country": "Cuba",
    "iso2": "CU",
    "iso3": "CUB",
    "admin_name": "Artemisa",
    "capital": "minor",
    "population": 37838,
    "id": 1192663168
  },
  {
    "city": "Aristóbulo del Valle",
    "city_ascii": "Aristobulo del Valle",
    "lat": -27.0952,
    "lng": -54.897,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Misiones",
    "capital": "",
    "population": 38000,
    "id": 1032611762
  },
  {
    "city": "Mullaittivu",
    "city_ascii": "Mullaittivu",
    "lat": 9.2833,
    "lng": 80.8,
    "country": "Sri Lanka",
    "iso2": "LK",
    "iso3": "LKA",
    "admin_name": "Northern",
    "capital": "",
    "population": 37339,
    "id": 1144339427
  },
  {
    "city": "Mixquiahuala de Juarez",
    "city_ascii": "Mixquiahuala de Juarez",
    "lat": 20.2311,
    "lng": -99.2131,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Hidalgo",
    "capital": "",
    "population": 37747,
    "id": 1484223912
  },
  {
    "city": "Menomonee Falls",
    "city_ascii": "Menomonee Falls",
    "lat": 43.1487,
    "lng": -88.1227,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 38014,
    "id": 1840003020
  },
  {
    "city": "Bathurst",
    "city_ascii": "Bathurst",
    "lat": -33.42,
    "lng": 149.5778,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 36801,
    "id": 1036446465
  },
  {
    "city": "Maibara",
    "city_ascii": "Maibara",
    "lat": 35.3153,
    "lng": 136.2839,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Shiga",
    "capital": "",
    "population": 37850,
    "id": 1392812033
  },
  {
    "city": "Granbury",
    "city_ascii": "Granbury",
    "lat": 32.4484,
    "lng": -97.7685,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 37996,
    "id": 1840020749
  },
  {
    "city": "Waxahachie",
    "city_ascii": "Waxahachie",
    "lat": 32.4032,
    "lng": -96.8444,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 37988,
    "id": 1840022083
  },
  {
    "city": "Kamp-Lintfort",
    "city_ascii": "Kamp-Lintfort",
    "lat": 51.5,
    "lng": 6.5333,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 37391,
    "id": 1276081102
  },
  {
    "city": "Sighetu Marmaţiei",
    "city_ascii": "Sighetu Marmatiei",
    "lat": 47.9309,
    "lng": 23.8947,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Maramureş",
    "capital": "",
    "population": 37640,
    "id": 1642377306
  },
  {
    "city": "Argun",
    "city_ascii": "Argun",
    "lat": 43.3,
    "lng": 45.8667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Chechnya",
    "capital": "",
    "population": 37373,
    "id": 1643005496
  },
  {
    "city": "Constitución",
    "city_ascii": "Constitucion",
    "lat": -35.3296,
    "lng": -72.42,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Maule",
    "capital": "",
    "population": 37917,
    "id": 1152065066
  },
  {
    "city": "Greven",
    "city_ascii": "Greven",
    "lat": 52.0917,
    "lng": 7.6083,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 37692,
    "id": 1276669554
  },
  {
    "city": "Kapiri Mposhi",
    "city_ascii": "Kapiri Mposhi",
    "lat": -13.9696,
    "lng": 28.66,
    "country": "Zambia",
    "iso2": "ZM",
    "iso3": "ZMB",
    "admin_name": "Central",
    "capital": "",
    "population": 37942,
    "id": 1894676779
  },
  {
    "city": "Santiago",
    "city_ascii": "Santiago",
    "lat": 25.4333,
    "lng": -100.1333,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Nuevo León",
    "capital": "minor",
    "population": 37886,
    "id": 1484043921
  },
  {
    "city": "Shakhtīnsk",
    "city_ascii": "Shakhtinsk",
    "lat": 49.71,
    "lng": 72.5872,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Qaraghandy",
    "capital": "",
    "population": 37899,
    "id": 1398239589
  },
  {
    "city": "Papenburg",
    "city_ascii": "Papenburg",
    "lat": 53.0667,
    "lng": 7.4,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 37579,
    "id": 1276460693
  },
  {
    "city": "Akhtubinsk",
    "city_ascii": "Akhtubinsk",
    "lat": 48.2833,
    "lng": 46.1667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Astrakhanskaya Oblast’",
    "capital": "",
    "population": 37883,
    "id": 1643207712
  },
  {
    "city": "Spišská Nová Ves",
    "city_ascii": "Spisska Nova Ves",
    "lat": 48.95,
    "lng": 20.5667,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Košický",
    "capital": "minor",
    "population": 37326,
    "id": 1703217196
  },
  {
    "city": "Stirling",
    "city_ascii": "Stirling",
    "lat": 56.1166,
    "lng": -3.9369,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Stirling",
    "capital": "",
    "population": 37810,
    "id": 1826517007
  },
  {
    "city": "Emirdağ",
    "city_ascii": "Emirdag",
    "lat": 39.0197,
    "lng": 31.15,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Afyonkarahisar",
    "capital": "minor",
    "population": 37817,
    "id": 1792515671
  },
  {
    "city": "Al Qaryatayn",
    "city_ascii": "Al Qaryatayn",
    "lat": 34.2294,
    "lng": 37.2406,
    "country": "Syria",
    "iso2": "SY",
    "iso3": "SYR",
    "admin_name": "Ḩimş",
    "capital": "minor",
    "population": 37820,
    "id": 1760172806
  },
  {
    "city": "Ma‘arratmişrīn",
    "city_ascii": "Ma`arratmisrin",
    "lat": 36.0114,
    "lng": 36.6717,
    "country": "Syria",
    "iso2": "SY",
    "iso3": "SYR",
    "admin_name": "Idlib",
    "capital": "minor",
    "population": 37490,
    "id": 1760812215
  },
  {
    "city": "Holly Springs",
    "city_ascii": "Holly Springs",
    "lat": 35.653,
    "lng": -78.8397,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 37812,
    "id": 1840016193
  },
  {
    "city": "Malmesbury",
    "city_ascii": "Malmesbury",
    "lat": -33.45,
    "lng": 18.7333,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "Western Cape",
    "capital": "",
    "population": 35897,
    "id": 1710868778
  },
  {
    "city": "Butler",
    "city_ascii": "Butler",
    "lat": 40.8616,
    "lng": -79.8962,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 37796,
    "id": 1840000857
  },
  {
    "city": "Juanjuí",
    "city_ascii": "Juanjui",
    "lat": -7.1802,
    "lng": -76.7265,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "San Martín",
    "capital": "",
    "population": 37715,
    "id": 1604229756
  },
  {
    "city": "Geraldton",
    "city_ascii": "Geraldton",
    "lat": -28.7744,
    "lng": 114.6089,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Western Australia",
    "capital": "",
    "population": 37648,
    "id": 1036811875
  },
  {
    "city": "Lampa",
    "city_ascii": "Lampa",
    "lat": -33.2864,
    "lng": -70.8729,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Región Metropolitana",
    "capital": "",
    "population": 37599,
    "id": 1152360918
  },
  {
    "city": "Albertville",
    "city_ascii": "Albertville",
    "lat": 34.2634,
    "lng": -86.2107,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 37775,
    "id": 1840013599
  },
  {
    "city": "Aboisso",
    "city_ascii": "Aboisso",
    "lat": 5.4667,
    "lng": -3.2,
    "country": "Côte D’Ivoire",
    "iso2": "CI",
    "iso3": "CIV",
    "admin_name": "Comoé",
    "capital": "minor",
    "population": 37654,
    "id": 1384385857
  },
  {
    "city": "Ossining",
    "city_ascii": "Ossining",
    "lat": 41.1559,
    "lng": -73.8565,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 37741,
    "id": 1840004937
  },
  {
    "city": "Uchaly",
    "city_ascii": "Uchaly",
    "lat": 54.3167,
    "lng": 59.3833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Bashkortostan",
    "capital": "",
    "population": 37710,
    "id": 1643696748
  },
  {
    "city": "Tunduma",
    "city_ascii": "Tunduma",
    "lat": -9.3,
    "lng": 32.7667,
    "country": "Tanzania",
    "iso2": "TZ",
    "iso3": "TZA",
    "admin_name": "Mbeya",
    "capital": "",
    "population": 36556,
    "id": 1834094894
  },
  {
    "city": "Kalinkavichy",
    "city_ascii": "Kalinkavichy",
    "lat": 52.1333,
    "lng": 29.3333,
    "country": "Belarus",
    "iso2": "BY",
    "iso3": "BLR",
    "admin_name": "Homyel’skaya Voblasts’",
    "capital": "minor",
    "population": 37700,
    "id": 1112264242
  },
  {
    "city": "Sunbury",
    "city_ascii": "Sunbury",
    "lat": -37.5811,
    "lng": 144.7139,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Victoria",
    "capital": "",
    "population": 36084,
    "id": 1036519781
  },
  {
    "city": "London",
    "city_ascii": "London",
    "lat": 37.1175,
    "lng": -84.0767,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kentucky",
    "capital": "",
    "population": 37714,
    "id": 1840015258
  },
  {
    "city": "Réo",
    "city_ascii": "Reo",
    "lat": 12.3335,
    "lng": -2.4669,
    "country": "Burkina Faso",
    "iso2": "BF",
    "iso3": "BFA",
    "admin_name": "Centre-Ouest",
    "capital": "minor",
    "population": 37535,
    "id": 1854684997
  },
  {
    "city": "Wesseling",
    "city_ascii": "Wesseling",
    "lat": 50.8207,
    "lng": 6.9786,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 36146,
    "id": 1276392642
  },
  {
    "city": "Yasugichō",
    "city_ascii": "Yasugicho",
    "lat": 35.4317,
    "lng": 133.2508,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Shimane",
    "capital": "",
    "population": 37581,
    "id": 1392750514
  },
  {
    "city": "Nowra",
    "city_ascii": "Nowra",
    "lat": -34.8833,
    "lng": 150.6,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 37420,
    "id": 1036984536
  },
  {
    "city": "Cottage Grove",
    "city_ascii": "Cottage Grove",
    "lat": 44.8161,
    "lng": -92.9274,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 37604,
    "id": 1840006735
  },
  {
    "city": "Beckum",
    "city_ascii": "Beckum",
    "lat": 51.7558,
    "lng": 8.0408,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 36646,
    "id": 1276000534
  },
  {
    "city": "Königs Wusterhausen",
    "city_ascii": "Konigs Wusterhausen",
    "lat": 52.2917,
    "lng": 13.625,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Brandenburg",
    "capital": "",
    "population": 37190,
    "id": 1276871907
  },
  {
    "city": "Frolovo",
    "city_ascii": "Frolovo",
    "lat": 49.7714,
    "lng": 43.6622,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Volgogradskaya Oblast’",
    "capital": "",
    "population": 36951,
    "id": 1643011047
  },
  {
    "city": "Alapayevsk",
    "city_ascii": "Alapayevsk",
    "lat": 57.85,
    "lng": 61.7,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sverdlovskaya Oblast’",
    "capital": "",
    "population": 37526,
    "id": 1643648315
  },
  {
    "city": "St. Marys",
    "city_ascii": "St. Marys",
    "lat": 30.7567,
    "lng": -81.5722,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 37556,
    "id": 1840015888
  },
  {
    "city": "Tosno",
    "city_ascii": "Tosno",
    "lat": 59.54,
    "lng": 30.8775,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Leningradskaya Oblast’",
    "capital": "",
    "population": 37509,
    "id": 1643457782
  },
  {
    "city": "Dalnegorsk",
    "city_ascii": "Dalnegorsk",
    "lat": 44.5539,
    "lng": 135.5661,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Primorskiy Kray",
    "capital": "",
    "population": 37519,
    "id": 1643147456
  },
  {
    "city": "Goryachiy Klyuch",
    "city_ascii": "Goryachiy Klyuch",
    "lat": 44.6308,
    "lng": 39.13,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Krasnodarskiy Kray",
    "capital": "",
    "population": 37475,
    "id": 1643630815
  },
  {
    "city": "Malgobek",
    "city_ascii": "Malgobek",
    "lat": 43.5167,
    "lng": 44.5833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Ingushetiya",
    "capital": "",
    "population": 37442,
    "id": 1643015431
  },
  {
    "city": "Mechanicsville",
    "city_ascii": "Mechanicsville",
    "lat": 37.6262,
    "lng": -77.3561,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 37501,
    "id": 1840006340
  },
  {
    "city": "Komatsushimachō",
    "city_ascii": "Komatsushimacho",
    "lat": 34.0047,
    "lng": 134.5906,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Tokushima",
    "capital": "",
    "population": 36689,
    "id": 1392858240
  },
  {
    "city": "San Blas",
    "city_ascii": "San Blas",
    "lat": 21.5397,
    "lng": -105.2856,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Nayarit",
    "capital": "minor",
    "population": 37478,
    "id": 1484142814
  },
  {
    "city": "Compostela",
    "city_ascii": "Compostela",
    "lat": 21.2389,
    "lng": -104.9,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Nayarit",
    "capital": "minor",
    "population": 37478,
    "id": 1484966833
  },
  {
    "city": "Mölndal",
    "city_ascii": "Molndal",
    "lat": 57.6542,
    "lng": 12.0139,
    "country": "Sweden",
    "iso2": "SE",
    "iso3": "SWE",
    "admin_name": "Västra Götaland",
    "capital": "minor",
    "population": 37233,
    "id": 1752307790
  },
  {
    "city": "Kannan",
    "city_ascii": "Kannan",
    "lat": 35.0894,
    "lng": 138.9453,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Shizuoka",
    "capital": "",
    "population": 36908,
    "id": 1392155353
  },
  {
    "city": "Risalpur Cantonment",
    "city_ascii": "Risalpur Cantonment",
    "lat": 34.0049,
    "lng": 71.9989,
    "country": "Pakistan",
    "iso2": "PK",
    "iso3": "PAK",
    "admin_name": "Khyber Pakhtunkhwa",
    "capital": "",
    "population": 36653,
    "id": 1586844436
  },
  {
    "city": "Cleburne",
    "city_ascii": "Cleburne",
    "lat": 32.357,
    "lng": -97.4152,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 37468,
    "id": 1840019464
  },
  {
    "city": "Moss",
    "city_ascii": "Moss",
    "lat": 59.437,
    "lng": 10.6692,
    "country": "Norway",
    "iso2": "NO",
    "iso3": "NOR",
    "admin_name": "Østfold",
    "capital": "minor",
    "population": 36901,
    "id": 1578386435
  },
  {
    "city": "Yirga ‘Alem",
    "city_ascii": "Yirga `Alem",
    "lat": 6.7504,
    "lng": 38.41,
    "country": "Ethiopia",
    "iso2": "ET",
    "iso3": "ETH",
    "admin_name": "YeDebub Bihēroch Bihēreseboch na Hizboch",
    "capital": "",
    "population": 36292,
    "id": 1231817240
  },
  {
    "city": "Warendorf",
    "city_ascii": "Warendorf",
    "lat": 51.9539,
    "lng": 7.9933,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "minor",
    "population": 37226,
    "id": 1276005733
  },
  {
    "city": "Montluçon",
    "city_ascii": "Montlucon",
    "lat": 46.3408,
    "lng": 2.6033,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Auvergne-Rhône-Alpes",
    "capital": "minor",
    "population": 35653,
    "id": 1250269997
  },
  {
    "city": "Holubivske",
    "city_ascii": "Holubivske",
    "lat": 48.6375,
    "lng": 38.6433,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Luhans’ka Oblast’",
    "capital": "",
    "population": 36388,
    "id": 1804938302
  },
  {
    "city": "Yugorsk",
    "city_ascii": "Yugorsk",
    "lat": 61.3167,
    "lng": 63.35,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Khanty-Mansiyskiy Avtonomnyy Okrug-Yugra",
    "capital": "",
    "population": 37422,
    "id": 1643279109
  },
  {
    "city": "Oswego",
    "city_ascii": "Oswego",
    "lat": 43.4516,
    "lng": -76.5005,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 37420,
    "id": 1840000348
  },
  {
    "city": "Lierre",
    "city_ascii": "Lierre",
    "lat": 51.1311,
    "lng": 4.5697,
    "country": "Belgium",
    "iso2": "BE",
    "iso3": "BEL",
    "admin_name": "Flanders",
    "capital": "",
    "population": 36646,
    "id": 1056505135
  },
  {
    "city": "Mizunami",
    "city_ascii": "Mizunami",
    "lat": 35.3619,
    "lng": 137.2544,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Gifu",
    "capital": "",
    "population": 37164,
    "id": 1392283682
  },
  {
    "city": "Lębork",
    "city_ascii": "Lebork",
    "lat": 54.55,
    "lng": 17.75,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Pomorskie",
    "capital": "minor",
    "population": 35374,
    "id": 1616424238
  },
  {
    "city": "Koryazhma",
    "city_ascii": "Koryazhma",
    "lat": 61.3,
    "lng": 47.1667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Arkhangel’skaya Oblast’",
    "capital": "",
    "population": 36607,
    "id": 1643316632
  },
  {
    "city": "Oregon City",
    "city_ascii": "Oregon City",
    "lat": 45.3415,
    "lng": -122.5927,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 37339,
    "id": 1840019954
  },
  {
    "city": "Maşyāf",
    "city_ascii": "Masyaf",
    "lat": 35.0653,
    "lng": 36.3421,
    "country": "Syria",
    "iso2": "SY",
    "iso3": "SYR",
    "admin_name": "Ḩamāh",
    "capital": "minor",
    "population": 37109,
    "id": 1760933504
  },
  {
    "city": "Guaíra",
    "city_ascii": "Guaira",
    "lat": -24.09,
    "lng": -54.27,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Paraná",
    "capital": "",
    "population": 36426,
    "id": 1076687981
  },
  {
    "city": "Winter Springs",
    "city_ascii": "Winter Springs",
    "lat": 28.6889,
    "lng": -81.2704,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 37312,
    "id": 1840015959
  },
  {
    "city": "Morondava",
    "city_ascii": "Morondava",
    "lat": -20.2833,
    "lng": 44.2833,
    "country": "Madagascar",
    "iso2": "MG",
    "iso3": "MDG",
    "admin_name": "Toliara",
    "capital": "minor",
    "population": 36803,
    "id": 1450721607
  },
  {
    "city": "Kizilyurt",
    "city_ascii": "Kizilyurt",
    "lat": 43.2,
    "lng": 46.8667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Dagestan",
    "capital": "minor",
    "population": 37171,
    "id": 1643796605
  },
  {
    "city": "Carpentersville",
    "city_ascii": "Carpentersville",
    "lat": 42.1227,
    "lng": -88.2895,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 37254,
    "id": 1840010149
  },
  {
    "city": "Mangai",
    "city_ascii": "Mangai",
    "lat": -4.0396,
    "lng": 19.53,
    "country": "Congo (Kinshasa)",
    "iso2": "CD",
    "iso3": "COD",
    "admin_name": "Kwilu",
    "capital": "",
    "population": 37188,
    "id": 1180224936
  },
  {
    "city": "Irbit",
    "city_ascii": "Irbit",
    "lat": 57.6667,
    "lng": 63.0667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sverdlovskaya Oblast’",
    "capital": "",
    "population": 36668,
    "id": 1643949941
  },
  {
    "city": "Frankston",
    "city_ascii": "Frankston",
    "lat": -38.158,
    "lng": 145.135,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Victoria",
    "capital": "",
    "population": 36097,
    "id": 1036126828
  },
  {
    "city": "Wildomar",
    "city_ascii": "Wildomar",
    "lat": 33.6173,
    "lng": -117.2582,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 37229,
    "id": 1840022902
  },
  {
    "city": "Greenfield",
    "city_ascii": "Greenfield",
    "lat": 42.9619,
    "lng": -88.0051,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 37221,
    "id": 1840003039
  },
  {
    "city": "Suhl",
    "city_ascii": "Suhl",
    "lat": 50.6106,
    "lng": 10.6931,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Thuringia",
    "capital": "minor",
    "population": 36955,
    "id": 1276059886
  },
  {
    "city": "Braintree",
    "city_ascii": "Braintree",
    "lat": 42.2039,
    "lng": -71.0022,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 37190,
    "id": 1840132398
  },
  {
    "city": "Parkland",
    "city_ascii": "Parkland",
    "lat": 47.1417,
    "lng": -122.4376,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 37185,
    "id": 1840018453
  },
  {
    "city": "Oakton",
    "city_ascii": "Oakton",
    "lat": 38.8887,
    "lng": -77.3016,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 37185,
    "id": 1840006023
  },
  {
    "city": "Protvino",
    "city_ascii": "Protvino",
    "lat": 54.8667,
    "lng": 37.2167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Moskovskaya Oblast’",
    "capital": "",
    "population": 35807,
    "id": 1643021953
  },
  {
    "city": "Silves",
    "city_ascii": "Silves",
    "lat": 37.1833,
    "lng": -8.4333,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Faro",
    "capital": "minor",
    "population": 37126,
    "id": 1620801770
  },
  {
    "city": "Penfield",
    "city_ascii": "Penfield",
    "lat": 43.1602,
    "lng": -77.4483,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 37180,
    "id": 1840058391
  },
  {
    "city": "Rezh",
    "city_ascii": "Rezh",
    "lat": 57.3667,
    "lng": 61.4,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sverdlovskaya Oblast’",
    "capital": "",
    "population": 37152,
    "id": 1643497489
  },
  {
    "city": "Rock Island",
    "city_ascii": "Rock Island",
    "lat": 41.4699,
    "lng": -90.5827,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 37176,
    "id": 1840009233
  },
  {
    "city": "Haverstraw",
    "city_ascii": "Haverstraw",
    "lat": 41.2055,
    "lng": -74.0384,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 37165,
    "id": 1840004975
  },
  {
    "city": "Yasynuvata",
    "city_ascii": "Yasynuvata",
    "lat": 48.1167,
    "lng": 37.8333,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Donets’ka Oblast’",
    "capital": "minor",
    "population": 35303,
    "id": 1804948935
  },
  {
    "city": "Cheltenham",
    "city_ascii": "Cheltenham",
    "lat": 40.0781,
    "lng": -75.1382,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 37149,
    "id": 1840143117
  },
  {
    "city": "Beslan",
    "city_ascii": "Beslan",
    "lat": 43.1833,
    "lng": 44.5333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "North Ossetia",
    "capital": "",
    "population": 37029,
    "id": 1643201517
  },
  {
    "city": "Erding",
    "city_ascii": "Erding",
    "lat": 48.3001,
    "lng": 11.9082,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "minor",
    "population": 36469,
    "id": 1276458355
  },
  {
    "city": "Tsubata",
    "city_ascii": "Tsubata",
    "lat": 36.6686,
    "lng": 136.7283,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Ishikawa",
    "capital": "",
    "population": 36774,
    "id": 1392195926
  },
  {
    "city": "Qulsary",
    "city_ascii": "Qulsary",
    "lat": 46.9833,
    "lng": 54.0165,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Atyraū",
    "capital": "",
    "population": 37103,
    "id": 1398141603
  },
  {
    "city": "Partizansk",
    "city_ascii": "Partizansk",
    "lat": 43.1333,
    "lng": 133.1333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Primorskiy Kray",
    "capital": "",
    "population": 37059,
    "id": 1643537193
  },
  {
    "city": "Kampene",
    "city_ascii": "Kampene",
    "lat": -3.5968,
    "lng": 26.6671,
    "country": "Congo (Kinshasa)",
    "iso2": "CD",
    "iso3": "COD",
    "admin_name": "Maniema",
    "capital": "",
    "population": 37034,
    "id": 1180064621
  },
  {
    "city": "Kanada",
    "city_ascii": "Kanada",
    "lat": 33.7761,
    "lng": 130.9806,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Fukuoka",
    "capital": "",
    "population": 36280,
    "id": 1392483747
  },
  {
    "city": "West Fargo",
    "city_ascii": "West Fargo",
    "lat": 46.8573,
    "lng": -96.9057,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Dakota",
    "capital": "",
    "population": 37058,
    "id": 1840001935
  },
  {
    "city": "Kyshtym",
    "city_ascii": "Kyshtym",
    "lat": 55.7139,
    "lng": 60.5528,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Chelyabinskaya Oblast’",
    "capital": "",
    "population": 36997,
    "id": 1643212206
  },
  {
    "city": "Lesozavodsk",
    "city_ascii": "Lesozavodsk",
    "lat": 45.4667,
    "lng": 133.4,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Primorskiy Kray",
    "capital": "",
    "population": 37034,
    "id": 1643722124
  },
  {
    "city": "Shrewsbury",
    "city_ascii": "Shrewsbury",
    "lat": 42.2842,
    "lng": -71.7154,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 37037,
    "id": 1840053699
  },
  {
    "city": "Boston",
    "city_ascii": "Boston",
    "lat": 52.974,
    "lng": -0.0214,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Lincolnshire",
    "capital": "",
    "population": 35124,
    "id": 1826233090
  },
  {
    "city": "Mount Juliet",
    "city_ascii": "Mount Juliet",
    "lat": 36.1991,
    "lng": -86.5114,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 37029,
    "id": 1840014465
  },
  {
    "city": "San Juan",
    "city_ascii": "San Juan",
    "lat": 26.1903,
    "lng": -98.152,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 37008,
    "id": 1840022256
  },
  {
    "city": "Land O' Lakes",
    "city_ascii": "Land O' Lakes",
    "lat": 28.2075,
    "lng": -82.4476,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 37007,
    "id": 1840014120
  },
  {
    "city": "Georgetown",
    "city_ascii": "Georgetown",
    "lat": 38.2249,
    "lng": -84.5482,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kentucky",
    "capital": "",
    "population": 36994,
    "id": 1840013197
  },
  {
    "city": "Portage",
    "city_ascii": "Portage",
    "lat": 41.5866,
    "lng": -87.1792,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 36988,
    "id": 1840009264
  },
  {
    "city": "Netishyn",
    "city_ascii": "Netishyn",
    "lat": 50.33,
    "lng": 26.64,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Khmel’nyts’ka Oblast’",
    "capital": "",
    "population": 36921,
    "id": 1804202140
  },
  {
    "city": "Caimito",
    "city_ascii": "Caimito",
    "lat": 22.9575,
    "lng": -82.5964,
    "country": "Cuba",
    "iso2": "CU",
    "iso3": "CUB",
    "admin_name": "Artemisa",
    "capital": "minor",
    "population": 36813,
    "id": 1192437074
  },
  {
    "city": "Owasso",
    "city_ascii": "Owasso",
    "lat": 36.2882,
    "lng": -95.8328,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oklahoma",
    "capital": "",
    "population": 36957,
    "id": 1840020385
  },
  {
    "city": "Komárno",
    "city_ascii": "Komarno",
    "lat": 47.7633,
    "lng": 18.1283,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Nitriansky",
    "capital": "",
    "population": 36596,
    "id": 1703927453
  },
  {
    "city": "Sokol",
    "city_ascii": "Sokol",
    "lat": 59.4667,
    "lng": 40.1167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Vologodskaya Oblast’",
    "capital": "",
    "population": 36924,
    "id": 1643586879
  },
  {
    "city": "Commack",
    "city_ascii": "Commack",
    "lat": 40.8443,
    "lng": -73.2834,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 36936,
    "id": 1840005030
  },
  {
    "city": "Morohongō",
    "city_ascii": "Morohongo",
    "lat": 35.9417,
    "lng": 139.3161,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Saitama",
    "capital": "",
    "population": 35877,
    "id": 1392302813
  },
  {
    "city": "Chimboy Shahri",
    "city_ascii": "Chimboy Shahri",
    "lat": 42.9311,
    "lng": 59.7708,
    "country": "Uzbekistan",
    "iso2": "UZ",
    "iso3": "UZB",
    "admin_name": "Qoraqalpog‘iston",
    "capital": "minor",
    "population": 36917,
    "id": 1860167547
  },
  {
    "city": "Foley",
    "city_ascii": "Foley",
    "lat": 30.3983,
    "lng": -87.6649,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 36921,
    "id": 1840002084
  },
  {
    "city": "Billingham",
    "city_ascii": "Billingham",
    "lat": 54.61,
    "lng": -1.27,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Stockton-on-Tees",
    "capital": "",
    "population": 35165,
    "id": 1826138343
  },
  {
    "city": "Torres Novas",
    "city_ascii": "Torres Novas",
    "lat": 39.4833,
    "lng": -8.5333,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Santarém",
    "capital": "minor",
    "population": 36717,
    "id": 1620693421
  },
  {
    "city": "New Albany",
    "city_ascii": "New Albany",
    "lat": 38.3089,
    "lng": -85.8234,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 36843,
    "id": 1840008662
  },
  {
    "city": "Kohtla-Järve",
    "city_ascii": "Kohtla-Jarve",
    "lat": 59.4,
    "lng": 27.2833,
    "country": "Estonia",
    "iso2": "EE",
    "iso3": "EST",
    "admin_name": "Ida-Virumaa",
    "capital": "minor",
    "population": 35928,
    "id": 1233016348
  },
  {
    "city": "Oakville",
    "city_ascii": "Oakville",
    "lat": 38.4472,
    "lng": -90.3199,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 36827,
    "id": 1840006121
  },
  {
    "city": "Benton",
    "city_ascii": "Benton",
    "lat": 34.5775,
    "lng": -92.5712,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arkansas",
    "capital": "",
    "population": 36820,
    "id": 1840013572
  },
  {
    "city": "Voi",
    "city_ascii": "Voi",
    "lat": -3.3696,
    "lng": 38.57,
    "country": "Kenya",
    "iso2": "KE",
    "iso3": "KEN",
    "admin_name": "Taita/Taveta",
    "capital": "",
    "population": 36487,
    "id": 1404504656
  },
  {
    "city": "Merritt Island",
    "city_ascii": "Merritt Island",
    "lat": 28.3146,
    "lng": -80.6713,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 36797,
    "id": 1840014076
  },
  {
    "city": "Köneürgench",
    "city_ascii": "Koneurgench",
    "lat": 42.3167,
    "lng": 59.1586,
    "country": "Turkmenistan",
    "iso2": "TM",
    "iso3": "TKM",
    "admin_name": "Daşoguz",
    "capital": "",
    "population": 36754,
    "id": 1795588005
  },
  {
    "city": "‘Afrīn",
    "city_ascii": "`Afrin",
    "lat": 36.5119,
    "lng": 36.8694,
    "country": "Syria",
    "iso2": "SY",
    "iso3": "SYR",
    "admin_name": "Ḩalab",
    "capital": "minor",
    "population": 36562,
    "id": 1760410858
  },
  {
    "city": "Kambove",
    "city_ascii": "Kambove",
    "lat": -10.87,
    "lng": 26.6,
    "country": "Congo (Kinshasa)",
    "iso2": "CD",
    "iso3": "COD",
    "admin_name": "Haut-Katanga",
    "capital": "",
    "population": 36702,
    "id": 1180986023
  },
  {
    "city": "Esik",
    "city_ascii": "Esik",
    "lat": 43.3552,
    "lng": 77.4524,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Almaty",
    "capital": "",
    "population": 36715,
    "id": 1398166418
  },
  {
    "city": "Clifton Park",
    "city_ascii": "Clifton Park",
    "lat": 42.8587,
    "lng": -73.8242,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 36728,
    "id": 1840057994
  },
  {
    "city": "Airdrie",
    "city_ascii": "Airdrie",
    "lat": 55.86,
    "lng": -3.98,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "North Lanarkshire",
    "capital": "",
    "population": 36620,
    "id": 1826889162
  },
  {
    "city": "Brant",
    "city_ascii": "Brant",
    "lat": 43.1167,
    "lng": -80.3667,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 36707,
    "id": 1124000682
  },
  {
    "city": "Esquel",
    "city_ascii": "Esquel",
    "lat": -42.9,
    "lng": -71.3167,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires, Ciudad Autónoma de",
    "capital": "minor",
    "population": 36687,
    "id": 1032489067
  },
  {
    "city": "Meridian",
    "city_ascii": "Meridian",
    "lat": 32.3846,
    "lng": -88.6896,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Mississippi",
    "capital": "",
    "population": 36686,
    "id": 1840014891
  },
  {
    "city": "Atami",
    "city_ascii": "Atami",
    "lat": 35.0961,
    "lng": 139.0717,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Shizuoka",
    "capital": "",
    "population": 36089,
    "id": 1392990970
  },
  {
    "city": "Kailua",
    "city_ascii": "Kailua",
    "lat": 21.392,
    "lng": -157.7396,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Hawaii",
    "capital": "",
    "population": 36662,
    "id": 1840023244
  },
  {
    "city": "Usuki",
    "city_ascii": "Usuki",
    "lat": 33.1258,
    "lng": 131.8047,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Ōita",
    "capital": "",
    "population": 36536,
    "id": 1392521875
  },
  {
    "city": "Zvishavane",
    "city_ascii": "Zvishavane",
    "lat": -20.3296,
    "lng": 30.05,
    "country": "Zimbabwe",
    "iso2": "ZW",
    "iso3": "ZWE",
    "admin_name": "Masvingo",
    "capital": "",
    "population": 35896,
    "id": 1716895095
  },
  {
    "city": "Zhetisay",
    "city_ascii": "Zhetisay",
    "lat": 40.7753,
    "lng": 68.3272,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Ongtüstik Qazaqstan",
    "capital": "",
    "population": 36494,
    "id": 1398994827
  },
  {
    "city": "Kinel",
    "city_ascii": "Kinel",
    "lat": 53.2333,
    "lng": 50.6167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Samarskaya Oblast’",
    "capital": "",
    "population": 35675,
    "id": 1643467753
  },
  {
    "city": "Kodaikānal",
    "city_ascii": "Kodaikanal",
    "lat": 10.23,
    "lng": 77.48,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Tamil Nādu",
    "capital": "",
    "population": 36501,
    "id": 1356350123
  },
  {
    "city": "Yorktown",
    "city_ascii": "Yorktown",
    "lat": 41.2727,
    "lng": -73.8092,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 36618,
    "id": 1840088070
  },
  {
    "city": "Lakeshore",
    "city_ascii": "Lakeshore",
    "lat": 42.2399,
    "lng": -82.6511,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 36611,
    "id": 1124001501
  },
  {
    "city": "Masasi",
    "city_ascii": "Masasi",
    "lat": -10.7296,
    "lng": 38.7999,
    "country": "Tanzania",
    "iso2": "TZ",
    "iso3": "TZA",
    "admin_name": "Mtwara",
    "capital": "",
    "population": 36032,
    "id": 1834414126
  },
  {
    "city": "Minami-Bōsō",
    "city_ascii": "Minami-Boso",
    "lat": 35.0433,
    "lng": 139.8403,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Chiba",
    "capital": "",
    "population": 36412,
    "id": 1392515382
  },
  {
    "city": "Kehl",
    "city_ascii": "Kehl",
    "lat": 48.5711,
    "lng": 7.8089,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 36089,
    "id": 1276615908
  },
  {
    "city": "Innisfil",
    "city_ascii": "Innisfil",
    "lat": 44.3,
    "lng": -79.5833,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 36566,
    "id": 1124001408
  },
  {
    "city": "Barra Bonita",
    "city_ascii": "Barra Bonita",
    "lat": -22.4947,
    "lng": -48.5581,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 36321,
    "id": 1076780548
  },
  {
    "city": "Agudos",
    "city_ascii": "Agudos",
    "lat": -22.4739,
    "lng": -48.9836,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 36524,
    "id": 1076000779
  },
  {
    "city": "Miyajima",
    "city_ascii": "Miyajima",
    "lat": 33.1525,
    "lng": 130.4747,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Kumamoto",
    "capital": "",
    "population": 36214,
    "id": 1392131326
  },
  {
    "city": "Bettendorf",
    "city_ascii": "Bettendorf",
    "lat": 41.5656,
    "lng": -90.4764,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Iowa",
    "capital": "",
    "population": 36543,
    "id": 1840007079
  },
  {
    "city": "Dunedin",
    "city_ascii": "Dunedin",
    "lat": 28.0329,
    "lng": -82.7863,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 36537,
    "id": 1840014144
  },
  {
    "city": "Hilliard",
    "city_ascii": "Hilliard",
    "lat": 40.0353,
    "lng": -83.1578,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 36534,
    "id": 1840007265
  },
  {
    "city": "Warrnambool",
    "city_ascii": "Warrnambool",
    "lat": -38.3833,
    "lng": 142.4833,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Victoria",
    "capital": "",
    "population": 35214,
    "id": 1036291219
  },
  {
    "city": "Emsdetten",
    "city_ascii": "Emsdetten",
    "lat": 52.1728,
    "lng": 7.5344,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 36012,
    "id": 1276439566
  },
  {
    "city": "Kaniama",
    "city_ascii": "Kaniama",
    "lat": -7.5696,
    "lng": 24.17,
    "country": "Congo (Kinshasa)",
    "iso2": "CD",
    "iso3": "COD",
    "admin_name": "Haut-Lomami",
    "capital": "",
    "population": 36481,
    "id": 1180770775
  },
  {
    "city": "Nueve de Julio",
    "city_ascii": "Nueve de Julio",
    "lat": -35.45,
    "lng": -60.8833,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires",
    "capital": "minor",
    "population": 36494,
    "id": 1032287957
  },
  {
    "city": "Phenix City",
    "city_ascii": "Phenix City",
    "lat": 32.4588,
    "lng": -85.0251,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 36487,
    "id": 1840006012
  },
  {
    "city": "Sambir",
    "city_ascii": "Sambir",
    "lat": 49.5167,
    "lng": 23.2,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "L’vivs’ka Oblast’",
    "capital": "minor",
    "population": 35026,
    "id": 1804333942
  },
  {
    "city": "Addison",
    "city_ascii": "Addison",
    "lat": 41.9314,
    "lng": -88.0085,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 36482,
    "id": 1840010171
  },
  {
    "city": "Mühlhausen",
    "city_ascii": "Muhlhausen",
    "lat": 51.2167,
    "lng": 10.45,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Thuringia",
    "capital": "minor",
    "population": 36200,
    "id": 1276181580
  },
  {
    "city": "Selçuk",
    "city_ascii": "Selcuk",
    "lat": 37.95,
    "lng": 27.3667,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "İzmir",
    "capital": "minor",
    "population": 36360,
    "id": 1792359915
  },
  {
    "city": "Coesfeld",
    "city_ascii": "Coesfeld",
    "lat": 51.9458,
    "lng": 7.1675,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "minor",
    "population": 36217,
    "id": 1276053897
  },
  {
    "city": "Roseville",
    "city_ascii": "Roseville",
    "lat": 45.0155,
    "lng": -93.1545,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 36457,
    "id": 1840008939
  },
  {
    "city": "Brighton Township",
    "city_ascii": "Brighton Township",
    "lat": 43.1175,
    "lng": -77.5835,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 36447,
    "id": 1840057402
  },
  {
    "city": "Galloway",
    "city_ascii": "Galloway",
    "lat": 39.4914,
    "lng": -74.4803,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 36437,
    "id": 1840081583
  },
  {
    "city": "Ma‘alot Tarshīḥā",
    "city_ascii": "Ma`alot Tarshiha",
    "lat": 33.0167,
    "lng": 35.2708,
    "country": "Israel",
    "iso2": "IL",
    "iso3": "ISR",
    "admin_name": "Northern",
    "capital": "",
    "population": 36000,
    "id": 1376992708
  },
  {
    "city": "Sankt Ingbert",
    "city_ascii": "Sankt Ingbert",
    "lat": 49.3,
    "lng": 7.1167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saarland",
    "capital": "",
    "population": 35714,
    "id": 1276174089
  },
  {
    "city": "Zhmerynka",
    "city_ascii": "Zhmerynka",
    "lat": 49.0425,
    "lng": 28.0992,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Vinnyts’ka Oblast’",
    "capital": "minor",
    "population": 34537,
    "id": 1804771070
  },
  {
    "city": "Tudela",
    "city_ascii": "Tudela",
    "lat": 42.0653,
    "lng": -1.6067,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Navarre",
    "capital": "",
    "population": 36258,
    "id": 1724338927
  },
  {
    "city": "Nyaungdon",
    "city_ascii": "Nyaungdon",
    "lat": 17.0433,
    "lng": 95.6429,
    "country": "Burma",
    "iso2": "MM",
    "iso3": "MMR",
    "admin_name": "Ayeyawady",
    "capital": "",
    "population": 36172,
    "id": 1104318813
  },
  {
    "city": "Udhampur",
    "city_ascii": "Udhampur",
    "lat": 32.93,
    "lng": 75.13,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Jammu and Kashmīr",
    "capital": "",
    "population": 35507,
    "id": 1356335272
  },
  {
    "city": "Kerava",
    "city_ascii": "Kerava",
    "lat": 60.4028,
    "lng": 25.1,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Uusimaa",
    "capital": "minor",
    "population": 35293,
    "id": 1246221274
  },
  {
    "city": "Tucker",
    "city_ascii": "Tucker",
    "lat": 33.8436,
    "lng": -84.2021,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 36395,
    "id": 1840013701
  },
  {
    "city": "Rifu",
    "city_ascii": "Rifu",
    "lat": 38.3303,
    "lng": 140.9756,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Miyagi",
    "capital": "",
    "population": 35597,
    "id": 1392894446
  },
  {
    "city": "Queanbeyan",
    "city_ascii": "Queanbeyan",
    "lat": -35.3533,
    "lng": 149.2342,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 36348,
    "id": 1036846465
  },
  {
    "city": "Moorpark",
    "city_ascii": "Moorpark",
    "lat": 34.2855,
    "lng": -118.877,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 36375,
    "id": 1840020472
  },
  {
    "city": "Smarhon’",
    "city_ascii": "Smarhon'",
    "lat": 54.4836,
    "lng": 26.4,
    "country": "Belarus",
    "iso2": "BY",
    "iso3": "BLR",
    "admin_name": "Hrodzyenskaya Voblasts’",
    "capital": "minor",
    "population": 36300,
    "id": 1112716074
  },
  {
    "city": "Monrovia",
    "city_ascii": "Monrovia",
    "lat": 34.165,
    "lng": -117.9921,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 36331,
    "id": 1840020498
  },
  {
    "city": "Oak Creek",
    "city_ascii": "Oak Creek",
    "lat": 42.8803,
    "lng": -87.9009,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 36325,
    "id": 1840003047
  },
  {
    "city": "Claremont",
    "city_ascii": "Claremont",
    "lat": 34.1259,
    "lng": -117.7153,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 36266,
    "id": 1840019216
  },
  {
    "city": "Oswego",
    "city_ascii": "Oswego",
    "lat": 41.6834,
    "lng": -88.3372,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 36252,
    "id": 1840011508
  },
  {
    "city": "Post Falls",
    "city_ascii": "Post Falls",
    "lat": 47.7213,
    "lng": -116.9385,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Idaho",
    "capital": "",
    "population": 36250,
    "id": 1840019811
  },
  {
    "city": "La Ciotat",
    "city_ascii": "La Ciotat",
    "lat": 43.1769,
    "lng": 5.6086,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Provence-Alpes-Côte d’Azur",
    "capital": "",
    "population": 35174,
    "id": 1250000896
  },
  {
    "city": "Peachtree City",
    "city_ascii": "Peachtree City",
    "lat": 33.3943,
    "lng": -84.5712,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 36223,
    "id": 1840014822
  },
  {
    "city": "Palapye",
    "city_ascii": "Palapye",
    "lat": -22.55,
    "lng": 27.1333,
    "country": "Botswana",
    "iso2": "BW",
    "iso3": "BWA",
    "admin_name": "Central",
    "capital": "",
    "population": 36211,
    "id": 1072264514
  },
  {
    "city": "Aţ Ţurrah",
    "city_ascii": "At Turrah",
    "lat": 32.6368,
    "lng": 35.99,
    "country": "Jordan",
    "iso2": "JO",
    "iso3": "JOR",
    "admin_name": "Irbid",
    "capital": "",
    "population": 34948,
    "id": 1400078308
  },
  {
    "city": "Kamata",
    "city_ascii": "Kamata",
    "lat": 33.5633,
    "lng": 130.7117,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Fukuoka",
    "capital": "",
    "population": 35929,
    "id": 1392357723
  },
  {
    "city": "Meadow Woods",
    "city_ascii": "Meadow Woods",
    "lat": 28.3698,
    "lng": -81.3467,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 36190,
    "id": 1840029057
  },
  {
    "city": "Pahrump",
    "city_ascii": "Pahrump",
    "lat": 36.2411,
    "lng": -116.0176,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Nevada",
    "capital": "",
    "population": 36174,
    "id": 1840018821
  },
  {
    "city": "Trumbull",
    "city_ascii": "Trumbull",
    "lat": 41.2602,
    "lng": -73.2083,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 36174,
    "id": 1840035628
  },
  {
    "city": "Zacatepec",
    "city_ascii": "Zacatepec",
    "lat": 18.6833,
    "lng": -99.1833,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Morelos",
    "capital": "minor",
    "population": 36159,
    "id": 1484549065
  },
  {
    "city": "Avdiivka",
    "city_ascii": "Avdiivka",
    "lat": 48.1333,
    "lng": 37.75,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Donets’ka Oblast’",
    "capital": "",
    "population": 34938,
    "id": 1804444433
  },
  {
    "city": "Tuttlingen",
    "city_ascii": "Tuttlingen",
    "lat": 47.985,
    "lng": 8.8233,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "minor",
    "population": 35730,
    "id": 1276649477
  },
  {
    "city": "Farafenni",
    "city_ascii": "Farafenni",
    "lat": 13.5667,
    "lng": -15.6,
    "country": "Gambia, The",
    "iso2": "GM",
    "iso3": "GMB",
    "admin_name": "North Bank",
    "capital": "",
    "population": 36000,
    "id": 1270512503
  },
  {
    "city": "Portsmouth",
    "city_ascii": "Portsmouth",
    "lat": 38.7539,
    "lng": -82.9446,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 36111,
    "id": 1840001646
  },
  {
    "city": "Virú",
    "city_ascii": "Viru",
    "lat": -8.4143,
    "lng": -78.7524,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "La Libertad",
    "capital": "",
    "population": 36029,
    "id": 1604901046
  },
  {
    "city": "Natick",
    "city_ascii": "Natick",
    "lat": 42.2847,
    "lng": -71.3497,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 36083,
    "id": 1840053640
  },
  {
    "city": "Martinez",
    "city_ascii": "Martinez",
    "lat": 33.5209,
    "lng": -82.0985,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 36068,
    "id": 1840013747
  },
  {
    "city": "Shawnee",
    "city_ascii": "Shawnee",
    "lat": 35.3529,
    "lng": -96.965,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oklahoma",
    "capital": "",
    "population": 36067,
    "id": 1840021785
  },
  {
    "city": "Třebíč",
    "city_ascii": "Trebic",
    "lat": 49.215,
    "lng": 15.8817,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Vysočina",
    "capital": "",
    "population": 35451,
    "id": 1203437113
  },
  {
    "city": "Ewing",
    "city_ascii": "Ewing",
    "lat": 40.265,
    "lng": -74.8005,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 36057,
    "id": 1840081658
  },
  {
    "city": "Gillette",
    "city_ascii": "Gillette",
    "lat": 44.2752,
    "lng": -105.4983,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wyoming",
    "capital": "",
    "population": 36032,
    "id": 1840019978
  },
  {
    "city": "Limburg",
    "city_ascii": "Limburg",
    "lat": 50.3833,
    "lng": 8.0667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "minor",
    "population": 35243,
    "id": 1276188257
  },
  {
    "city": "Hato Mayor",
    "city_ascii": "Hato Mayor",
    "lat": 18.764,
    "lng": -69.257,
    "country": "Dominican Republic",
    "iso2": "DO",
    "iso3": "DOM",
    "admin_name": "Higuamo",
    "capital": "minor",
    "population": 35999,
    "id": 1214232592
  },
  {
    "city": "Guayaramerín",
    "city_ascii": "Guayaramerin",
    "lat": -10.8267,
    "lng": -65.3567,
    "country": "Bolivia",
    "iso2": "BO",
    "iso3": "BOL",
    "admin_name": "El Beni",
    "capital": "",
    "population": 36008,
    "id": 1068744206
  },
  {
    "city": "Porta Westfalica",
    "city_ascii": "Porta Westfalica",
    "lat": 52.2167,
    "lng": 8.9333,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 35671,
    "id": 1276426167
  },
  {
    "city": "Salinas",
    "city_ascii": "Salinas",
    "lat": -2.2083,
    "lng": -80.9681,
    "country": "Ecuador",
    "iso2": "EC",
    "iso3": "ECU",
    "admin_name": "Guayas",
    "capital": "",
    "population": 34719,
    "id": 1218838548
  },
  {
    "city": "Accrington",
    "city_ascii": "Accrington",
    "lat": 53.7534,
    "lng": -2.3638,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Lancashire",
    "capital": "",
    "population": 35456,
    "id": 1826709919
  },
  {
    "city": "Velasco Ibarra",
    "city_ascii": "Velasco Ibarra",
    "lat": -1.0439,
    "lng": -79.6383,
    "country": "Ecuador",
    "iso2": "EC",
    "iso3": "ECU",
    "admin_name": "Guayas",
    "capital": "",
    "population": 35686,
    "id": 1218000005
  },
  {
    "city": "Boyarka",
    "city_ascii": "Boyarka",
    "lat": 70.767,
    "lng": 97.5,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Krasnoyarskiy Kray",
    "capital": "",
    "population": 35968,
    "id": 1643010185
  },
  {
    "city": "Prattville",
    "city_ascii": "Prattville",
    "lat": 32.4605,
    "lng": -86.4588,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 35957,
    "id": 1840004086
  },
  {
    "city": "Kamenka",
    "city_ascii": "Kamenka",
    "lat": 53.1833,
    "lng": 44.05,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Penzenskaya Oblast’",
    "capital": "",
    "population": 35929,
    "id": 1643795205
  },
  {
    "city": "Chortoq",
    "city_ascii": "Chortoq",
    "lat": 41.0692,
    "lng": 71.8237,
    "country": "Uzbekistan",
    "iso2": "UZ",
    "iso3": "UZB",
    "admin_name": "Namangan",
    "capital": "minor",
    "population": 35615,
    "id": 1860436436
  },
  {
    "city": "Woodburn",
    "city_ascii": "Woodburn",
    "lat": 45.1473,
    "lng": -122.8603,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 35941,
    "id": 1840021230
  },
  {
    "city": "Chernyakhovsk",
    "city_ascii": "Chernyakhovsk",
    "lat": 54.6333,
    "lng": 21.8167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kaliningradskaya Oblast’",
    "capital": "minor",
    "population": 35888,
    "id": 1643016647
  },
  {
    "city": "Prince Albert",
    "city_ascii": "Prince Albert",
    "lat": 53.2,
    "lng": -105.75,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Saskatchewan",
    "capital": "",
    "population": 35926,
    "id": 1124158154
  },
  {
    "city": "Calumet City",
    "city_ascii": "Calumet City",
    "lat": 41.6134,
    "lng": -87.5505,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 35913,
    "id": 1840007023
  },
  {
    "city": "Praya",
    "city_ascii": "Praya",
    "lat": -8.7223,
    "lng": 116.2923,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Nusa Tenggara Barat",
    "capital": "minor",
    "population": 35183,
    "id": 1360664646
  },
  {
    "city": "San Juan Capistrano",
    "city_ascii": "San Juan Capistrano",
    "lat": 33.5009,
    "lng": -117.6544,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 35911,
    "id": 1840021963
  },
  {
    "city": "Vyazniki",
    "city_ascii": "Vyazniki",
    "lat": 56.2433,
    "lng": 42.1292,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Vladimirskaya Oblast’",
    "capital": "",
    "population": 35865,
    "id": 1643016775
  },
  {
    "city": "Toretsk",
    "city_ascii": "Toretsk",
    "lat": 48.4,
    "lng": 37.8333,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Donets’ka Oblast’",
    "capital": "",
    "population": 34378,
    "id": 1804980684
  },
  {
    "city": "Sion",
    "city_ascii": "Sion",
    "lat": 46.2304,
    "lng": 7.3661,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Valais",
    "capital": "",
    "population": 34708,
    "id": 1756457392
  },
  {
    "city": "Kumo",
    "city_ascii": "Kumo",
    "lat": 10.0431,
    "lng": 11.2183,
    "country": "Nigeria",
    "iso2": "NG",
    "iso3": "NGA",
    "admin_name": "Gombe",
    "capital": "minor",
    "population": 35712,
    "id": 1566501095
  },
  {
    "city": "Xonobod",
    "city_ascii": "Xonobod",
    "lat": 40.8127,
    "lng": 72.9731,
    "country": "Uzbekistan",
    "iso2": "UZ",
    "iso3": "UZB",
    "admin_name": "Andijon",
    "capital": "",
    "population": 35181,
    "id": 1860608604
  },
  {
    "city": "Ẕefat",
    "city_ascii": "Zefat",
    "lat": 32.9658,
    "lng": 35.4983,
    "country": "Israel",
    "iso2": "IL",
    "iso3": "ISR",
    "admin_name": "Northern",
    "capital": "",
    "population": 35700,
    "id": 1376611460
  },
  {
    "city": "Karak",
    "city_ascii": "Karak",
    "lat": 33.1167,
    "lng": 71.0833,
    "country": "Pakistan",
    "iso2": "PK",
    "iso3": "PAK",
    "admin_name": "Khyber Pakhtunkhwa",
    "capital": "minor",
    "population": 35844,
    "id": 1586491871
  },
  {
    "city": "Ingelheim",
    "city_ascii": "Ingelheim",
    "lat": 49.9747,
    "lng": 8.0564,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Rhineland-Palatinate",
    "capital": "minor",
    "population": 35146,
    "id": 1276699130
  },
  {
    "city": "Tallkalakh",
    "city_ascii": "Tallkalakh",
    "lat": 34.6683,
    "lng": 36.2597,
    "country": "Syria",
    "iso2": "SY",
    "iso3": "SYR",
    "admin_name": "Ḩimş",
    "capital": "minor",
    "population": 35445,
    "id": 1760254463
  },
  {
    "city": "Pak Chong",
    "city_ascii": "Pak Chong",
    "lat": 14.6796,
    "lng": 101.3976,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Nakhon Ratchasima",
    "capital": "minor",
    "population": 35736,
    "id": 1764898547
  },
  {
    "city": "Atbasar",
    "city_ascii": "Atbasar",
    "lat": 51.8219,
    "lng": 68.3477,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Aqmola",
    "capital": "",
    "population": 35819,
    "id": 1398900162
  },
  {
    "city": "Dayr Ḩāfir",
    "city_ascii": "Dayr Hafir",
    "lat": 36.1592,
    "lng": 37.704,
    "country": "Syria",
    "iso2": "SY",
    "iso3": "SYR",
    "admin_name": "Ḩalab",
    "capital": "minor",
    "population": 35409,
    "id": 1760046071
  },
  {
    "city": "Franklin",
    "city_ascii": "Franklin",
    "lat": 42.8854,
    "lng": -88.0104,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 35811,
    "id": 1840003043
  },
  {
    "city": "Cooper City",
    "city_ascii": "Cooper City",
    "lat": 26.0463,
    "lng": -80.2862,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 35800,
    "id": 1840015133
  },
  {
    "city": "Leyland",
    "city_ascii": "Leyland",
    "lat": 53.692,
    "lng": -2.697,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Lancashire",
    "capital": "",
    "population": 35578,
    "id": 1826726004
  },
  {
    "city": "Kundian",
    "city_ascii": "Kundian",
    "lat": 32.4522,
    "lng": 71.4718,
    "country": "Pakistan",
    "iso2": "PK",
    "iso3": "PAK",
    "admin_name": "Punjab",
    "capital": "",
    "population": 35406,
    "id": 1586180022
  },
  {
    "city": "Svetlograd",
    "city_ascii": "Svetlograd",
    "lat": 45.3308,
    "lng": 42.8511,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Stavropol’skiy Kray",
    "capital": "",
    "population": 35745,
    "id": 1643409997
  },
  {
    "city": "El Mirage",
    "city_ascii": "El Mirage",
    "lat": 33.5905,
    "lng": -112.3271,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 35753,
    "id": 1840020562
  },
  {
    "city": "Woodley",
    "city_ascii": "Woodley",
    "lat": 51.453,
    "lng": -0.902,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Wokingham",
    "capital": "",
    "population": 35470,
    "id": 1826133609
  },
  {
    "city": "Sinsheim",
    "city_ascii": "Sinsheim",
    "lat": 49.25,
    "lng": 8.8833,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 35442,
    "id": 1276820270
  },
  {
    "city": "LaGrange",
    "city_ascii": "LaGrange",
    "lat": 33.0274,
    "lng": -85.0384,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 35721,
    "id": 1840029147
  },
  {
    "city": "Cieszyn",
    "city_ascii": "Cieszyn",
    "lat": 49.75,
    "lng": 18.6333,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Śląskie",
    "capital": "minor",
    "population": 34513,
    "id": 1616089679
  },
  {
    "city": "La Vergne",
    "city_ascii": "La Vergne",
    "lat": 36.0203,
    "lng": -86.5582,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 35716,
    "id": 1840014496
  },
  {
    "city": "Middletown",
    "city_ascii": "Middletown",
    "lat": 39.4453,
    "lng": -75.7166,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Delaware",
    "capital": "",
    "population": 35695,
    "id": 1840005581
  },
  {
    "city": "Mount Pleasant",
    "city_ascii": "Mount Pleasant",
    "lat": 43.5966,
    "lng": -84.7759,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 35694,
    "id": 1840002663
  },
  {
    "city": "Iyo",
    "city_ascii": "Iyo",
    "lat": 33.7575,
    "lng": 132.7039,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Ehime",
    "capital": "",
    "population": 35508,
    "id": 1392849626
  },
  {
    "city": "Boyarka",
    "city_ascii": "Boyarka",
    "lat": 50.3292,
    "lng": 30.2886,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Kyyivs’ka Oblast’",
    "capital": "",
    "population": 35411,
    "id": 1804000125
  },
  {
    "city": "Junction City",
    "city_ascii": "Junction City",
    "lat": 39.0271,
    "lng": -96.8496,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kansas",
    "capital": "",
    "population": 35677,
    "id": 1840001620
  },
  {
    "city": "Inver Grove Heights",
    "city_ascii": "Inver Grove Heights",
    "lat": 44.8247,
    "lng": -93.0596,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 35672,
    "id": 1840007858
  },
  {
    "city": "Gürpınar",
    "city_ascii": "Gurpinar",
    "lat": 38.3269,
    "lng": 43.4133,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Van",
    "capital": "minor",
    "population": 35663,
    "id": 1792954694
  },
  {
    "city": "Cegléd",
    "city_ascii": "Cegled",
    "lat": 47.1772,
    "lng": 19.7981,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Pest",
    "capital": "minor",
    "population": 35523,
    "id": 1348930347
  },
  {
    "city": "Câmara de Lobos",
    "city_ascii": "Camara de Lobos",
    "lat": 32.65,
    "lng": -16.9667,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Madeira",
    "capital": "",
    "population": 35666,
    "id": 1620560549
  },
  {
    "city": "Kilimli",
    "city_ascii": "Kilimli",
    "lat": 41.4833,
    "lng": 31.8333,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Zonguldak",
    "capital": "minor",
    "population": 35323,
    "id": 1792892281
  },
  {
    "city": "Guilderland",
    "city_ascii": "Guilderland",
    "lat": 42.708,
    "lng": -73.9631,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 35655,
    "id": 1840058149
  },
  {
    "city": "Kuala Kapuas",
    "city_ascii": "Kuala Kapuas",
    "lat": -3.0996,
    "lng": 114.35,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Kalimantan Tengah",
    "capital": "minor",
    "population": 35632,
    "id": 1360858401
  },
  {
    "city": "Uster",
    "city_ascii": "Uster",
    "lat": 47.3492,
    "lng": 8.7192,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Zürich",
    "capital": "minor",
    "population": 34442,
    "id": 1756180752
  },
  {
    "city": "Ōfunato",
    "city_ascii": "Ofunato",
    "lat": 39.0822,
    "lng": 141.7083,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Iwate",
    "capital": "",
    "population": 35535,
    "id": 1392009967
  },
  {
    "city": "Pennsauken",
    "city_ascii": "Pennsauken",
    "lat": 39.9649,
    "lng": -75.0563,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 35638,
    "id": 1840081687
  },
  {
    "city": "Cha-am",
    "city_ascii": "Cha-am",
    "lat": 12.7997,
    "lng": 99.9669,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Phetchaburi",
    "capital": "minor",
    "population": 35581,
    "id": 1764087780
  },
  {
    "city": "Onteniente",
    "city_ascii": "Onteniente",
    "lat": 38.8222,
    "lng": -0.6072,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Valencia",
    "capital": "",
    "population": 35347,
    "id": 1724051475
  },
  {
    "city": "La Troncal",
    "city_ascii": "La Troncal",
    "lat": -2.4278,
    "lng": -79.3389,
    "country": "Ecuador",
    "iso2": "EC",
    "iso3": "ECU",
    "admin_name": "Cañar",
    "capital": "",
    "population": 35259,
    "id": 1218086252
  },
  {
    "city": "Bougouni",
    "city_ascii": "Bougouni",
    "lat": 11.4204,
    "lng": -7.49,
    "country": "Mali",
    "iso2": "ML",
    "iso3": "MLI",
    "admin_name": "Sikasso",
    "capital": "minor",
    "population": 35450,
    "id": 1466902797
  },
  {
    "city": "Mörfelden-Walldorf",
    "city_ascii": "Morfelden-Walldorf",
    "lat": 49.9896,
    "lng": 8.5661,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 34828,
    "id": 1276425407
  },
  {
    "city": "Andover",
    "city_ascii": "Andover",
    "lat": 42.6466,
    "lng": -71.1651,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 35609,
    "id": 1840053666
  },
  {
    "city": "Saint-Chamond",
    "city_ascii": "Saint-Chamond",
    "lat": 45.4775,
    "lng": 4.5153,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Auvergne-Rhône-Alpes",
    "capital": "",
    "population": 34967,
    "id": 1250831814
  },
  {
    "city": "Dietzenbach",
    "city_ascii": "Dietzenbach",
    "lat": 50.0086,
    "lng": 8.7756,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 34019,
    "id": 1276741284
  },
  {
    "city": "José Bonifácio",
    "city_ascii": "Jose Bonifacio",
    "lat": -21.0528,
    "lng": -49.6878,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 35538,
    "id": 1076809600
  },
  {
    "city": "Yangambi",
    "city_ascii": "Yangambi",
    "lat": 0.7675,
    "lng": 24.4414,
    "country": "Congo (Kinshasa)",
    "iso2": "CD",
    "iso3": "COD",
    "admin_name": "Tshopo",
    "capital": "",
    "population": 35531,
    "id": 1180077706
  },
  {
    "city": "Meppen",
    "city_ascii": "Meppen",
    "lat": 52.6906,
    "lng": 7.291,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "minor",
    "population": 35373,
    "id": 1276180771
  },
  {
    "city": "Honda",
    "city_ascii": "Honda",
    "lat": 5.1903,
    "lng": -74.75,
    "country": "Colombia",
    "iso2": "CO",
    "iso3": "COL",
    "admin_name": "Tolima",
    "capital": "minor",
    "population": 35469,
    "id": 1170848069
  },
  {
    "city": "Navarre",
    "city_ascii": "Navarre",
    "lat": 30.4174,
    "lng": -86.8907,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 35540,
    "id": 1840027017
  },
  {
    "city": "Monroe",
    "city_ascii": "Monroe",
    "lat": 35.0061,
    "lng": -80.5595,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 35540,
    "id": 1840014613
  },
  {
    "city": "Savonlinna",
    "city_ascii": "Savonlinna",
    "lat": 61.8667,
    "lng": 28.8831,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Etelä-Savo",
    "capital": "minor",
    "population": 35523,
    "id": 1246948647
  },
  {
    "city": "Sōsa",
    "city_ascii": "Sosa",
    "lat": 35.7078,
    "lng": 140.5644,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Chiba",
    "capital": "",
    "population": 35186,
    "id": 1392454467
  },
  {
    "city": "Yayladağı",
    "city_ascii": "Yayladagi",
    "lat": 35.9025,
    "lng": 36.0603,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Hatay",
    "capital": "minor",
    "population": 35460,
    "id": 1792355233
  },
  {
    "city": "Cutral-Có",
    "city_ascii": "Cutral-Co",
    "lat": -38.9333,
    "lng": -69.2333,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Neuquén",
    "capital": "",
    "population": 35465,
    "id": 1032070013
  },
  {
    "city": "Lage",
    "city_ascii": "Lage",
    "lat": 51.9833,
    "lng": 8.8,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 35047,
    "id": 1276243855
  },
  {
    "city": "Vichuga",
    "city_ascii": "Vichuga",
    "lat": 57.2,
    "lng": 41.9167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Ivanovskaya Oblast’",
    "capital": "",
    "population": 34394,
    "id": 1643107407
  },
  {
    "city": "Villenave-d’Ornon",
    "city_ascii": "Villenave-d'Ornon",
    "lat": 44.7806,
    "lng": -0.5658,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Nouvelle-Aquitaine",
    "capital": "",
    "population": 34090,
    "id": 1250296432
  },
  {
    "city": "Gahanna",
    "city_ascii": "Gahanna",
    "lat": 40.0251,
    "lng": -82.8637,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 35483,
    "id": 1840008390
  },
  {
    "city": "Saint-Benoît",
    "city_ascii": "Saint-Benoit",
    "lat": -21.0335,
    "lng": 55.7128,
    "country": "Reunion",
    "iso2": "RE",
    "iso3": "REU",
    "admin_name": "",
    "capital": "",
    "population": 35310,
    "id": 1638823449
  },
  {
    "city": "Sanford",
    "city_ascii": "Sanford",
    "lat": 35.4874,
    "lng": -79.1772,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 35467,
    "id": 1840015421
  },
  {
    "city": "Riviera Beach",
    "city_ascii": "Riviera Beach",
    "lat": 26.7812,
    "lng": -80.0742,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 35463,
    "id": 1840015126
  },
  {
    "city": "Tamuín",
    "city_ascii": "Tamuin",
    "lat": 22,
    "lng": -98.7833,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "San Luis Potosí",
    "capital": "minor",
    "population": 35446,
    "id": 1484269723
  },
  {
    "city": "Ikot Abasi",
    "city_ascii": "Ikot Abasi",
    "lat": 4.5704,
    "lng": 7.56,
    "country": "Nigeria",
    "iso2": "NG",
    "iso3": "NGA",
    "admin_name": "Akwa Ibom",
    "capital": "minor",
    "population": 34911,
    "id": 1566812706
  },
  {
    "city": "Saint-Raphaël",
    "city_ascii": "Saint-Raphael",
    "lat": 43.4252,
    "lng": 6.7684,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Provence-Alpes-Côte d’Azur",
    "capital": "",
    "population": 35042,
    "id": 1250046595
  },
  {
    "city": "Wildwood",
    "city_ascii": "Wildwood",
    "lat": 38.5799,
    "lng": -90.6699,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 35432,
    "id": 1840010746
  },
  {
    "city": "Burriana",
    "city_ascii": "Burriana",
    "lat": 39.8894,
    "lng": -0.0925,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Valencia",
    "capital": "",
    "population": 34683,
    "id": 1724445446
  },
  {
    "city": "Angra do Heroísmo",
    "city_ascii": "Angra do Heroismo",
    "lat": 38.6558,
    "lng": -27.2153,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Azores",
    "capital": "",
    "population": 35402,
    "id": 1620152939
  },
  {
    "city": "Třinec",
    "city_ascii": "Trinec",
    "lat": 49.6776,
    "lng": 18.6708,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Moravskoslezský Kraj",
    "capital": "",
    "population": 35002,
    "id": 1203430167
  },
  {
    "city": "Cloppenburg",
    "city_ascii": "Cloppenburg",
    "lat": 52.8478,
    "lng": 8.0439,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "minor",
    "population": 34913,
    "id": 1276769960
  },
  {
    "city": "Millerovo",
    "city_ascii": "Millerovo",
    "lat": 48.9167,
    "lng": 40.4,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Rostovskaya Oblast’",
    "capital": "minor",
    "population": 35384,
    "id": 1643830496
  },
  {
    "city": "La Palma",
    "city_ascii": "La Palma",
    "lat": 22.7472,
    "lng": -83.5525,
    "country": "Cuba",
    "iso2": "CU",
    "iso3": "CUB",
    "admin_name": "Pinar del Río",
    "capital": "minor",
    "population": 35346,
    "id": 1192218029
  },
  {
    "city": "Tooele",
    "city_ascii": "Tooele",
    "lat": 40.5393,
    "lng": -112.3082,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Utah",
    "capital": "",
    "population": 35392,
    "id": 1840021356
  },
  {
    "city": "Upper Arlington",
    "city_ascii": "Upper Arlington",
    "lat": 40.0272,
    "lng": -83.0704,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 35366,
    "id": 1840003762
  },
  {
    "city": "Yarumal",
    "city_ascii": "Yarumal",
    "lat": 7.0306,
    "lng": -75.5905,
    "country": "Colombia",
    "iso2": "CO",
    "iso3": "COL",
    "admin_name": "Antioquia",
    "capital": "",
    "population": 35315,
    "id": 1170196021
  },
  {
    "city": "Auxerre",
    "city_ascii": "Auxerre",
    "lat": 47.7986,
    "lng": 3.5672,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Bourgogne-Franche-Comté",
    "capital": "minor",
    "population": 34634,
    "id": 1250482884
  },
  {
    "city": "Saarlouis",
    "city_ascii": "Saarlouis",
    "lat": 49.3167,
    "lng": 6.75,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saarland",
    "capital": "minor",
    "population": 34552,
    "id": 1276663502
  },
  {
    "city": "Langford Station",
    "city_ascii": "Langford Station",
    "lat": 48.4506,
    "lng": -123.5058,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "British Columbia",
    "capital": "",
    "population": 35342,
    "id": 1124095065
  },
  {
    "city": "Kahoku",
    "city_ascii": "Kahoku",
    "lat": 36.72,
    "lng": 136.7067,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Ishikawa",
    "capital": "",
    "population": 34792,
    "id": 1392426434
  },
  {
    "city": "Bradford West Gwillimbury",
    "city_ascii": "Bradford West Gwillimbury",
    "lat": 44.1333,
    "lng": -79.6333,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 35325,
    "id": 1124001093
  },
  {
    "city": "Radebeul",
    "city_ascii": "Radebeul",
    "lat": 51.1033,
    "lng": 13.67,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 34008,
    "id": 1276277969
  },
  {
    "city": "Auburn",
    "city_ascii": "Auburn",
    "lat": 38.895,
    "lng": -121.0778,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 35311,
    "id": 1840010231
  },
  {
    "city": "Yellandu",
    "city_ascii": "Yellandu",
    "lat": 17.6,
    "lng": 80.33,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Telangana",
    "capital": "",
    "population": 35056,
    "id": 1356029729
  },
  {
    "city": "Bad Vilbel",
    "city_ascii": "Bad Vilbel",
    "lat": 50.1781,
    "lng": 8.7361,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 33990,
    "id": 1276387851
  },
  {
    "city": "Plainfield",
    "city_ascii": "Plainfield",
    "lat": 39.6953,
    "lng": -86.3717,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 35287,
    "id": 1840010579
  },
  {
    "city": "Olney",
    "city_ascii": "Olney",
    "lat": 39.1465,
    "lng": -77.0715,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 35280,
    "id": 1840005836
  },
  {
    "city": "Abashiri",
    "city_ascii": "Abashiri",
    "lat": 44.0206,
    "lng": 144.2736,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hokkaidō",
    "capital": "",
    "population": 35205,
    "id": 1392204790
  },
  {
    "city": "Luga",
    "city_ascii": "Luga",
    "lat": 58.7333,
    "lng": 29.85,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Leningradskaya Oblast’",
    "capital": "",
    "population": 35262,
    "id": 1643463120
  },
  {
    "city": "Giddalūr",
    "city_ascii": "Giddalur",
    "lat": 15.3784,
    "lng": 78.9265,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Andhra Pradesh",
    "capital": "",
    "population": 35150,
    "id": 1356420019
  },
  {
    "city": "Colwyn Bay",
    "city_ascii": "Colwyn Bay",
    "lat": 53.29,
    "lng": -3.7,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Conwy",
    "capital": "",
    "population": 34284,
    "id": 1826306536
  },
  {
    "city": "Voznesens’k",
    "city_ascii": "Voznesens'k",
    "lat": 47.5725,
    "lng": 31.3119,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Mykolayivs’ka Oblast’",
    "capital": "minor",
    "population": 35243,
    "id": 1804117559
  },
  {
    "city": "Gorizia",
    "city_ascii": "Gorizia",
    "lat": 45.9352,
    "lng": 13.6193,
    "country": "Italy",
    "iso2": "IT",
    "iso3": "ITA",
    "admin_name": "Friuli-Venezia Giulia",
    "capital": "minor",
    "population": 34411,
    "id": 1380874405
  },
  {
    "city": "Ieper",
    "city_ascii": "Ieper",
    "lat": 50.85,
    "lng": 2.8833,
    "country": "Belgium",
    "iso2": "BE",
    "iso3": "BEL",
    "admin_name": "Flanders",
    "capital": "minor",
    "population": 34964,
    "id": 1056744519
  },
  {
    "city": "Wermelskirchen",
    "city_ascii": "Wermelskirchen",
    "lat": 51.1392,
    "lng": 7.2051,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 34765,
    "id": 1276444512
  },
  {
    "city": "Oak Harbor",
    "city_ascii": "Oak Harbor",
    "lat": 48.2965,
    "lng": -122.6333,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 35228,
    "id": 1840019779
  },
  {
    "city": "Yarīm",
    "city_ascii": "Yarim",
    "lat": 14.2978,
    "lng": 44.3778,
    "country": "Yemen",
    "iso2": "YE",
    "iso3": "YEM",
    "admin_name": "Ibb",
    "capital": "minor",
    "population": 34805,
    "id": 1887851991
  },
  {
    "city": "Mirnyy",
    "city_ascii": "Mirnyy",
    "lat": 62.5333,
    "lng": 113.95,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sakha (Yakutiya)",
    "capital": "",
    "population": 35223,
    "id": 1643007406
  },
  {
    "city": "Winsen",
    "city_ascii": "Winsen",
    "lat": 53.3667,
    "lng": 10.2167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "minor",
    "population": 34896,
    "id": 1276491512
  },
  {
    "city": "Yefremov",
    "city_ascii": "Yefremov",
    "lat": 53.1492,
    "lng": 38.0826,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Tul’skaya Oblast’",
    "capital": "",
    "population": 35168,
    "id": 1643358029
  },
  {
    "city": "Kamsar",
    "city_ascii": "Kamsar",
    "lat": 10.65,
    "lng": -14.6167,
    "country": "Guinea",
    "iso2": "GN",
    "iso3": "GIN",
    "admin_name": "Boké",
    "capital": "",
    "population": 34973,
    "id": 1324075393
  },
  {
    "city": "Umarkot",
    "city_ascii": "Umarkot",
    "lat": 25.3614,
    "lng": 69.7361,
    "country": "Pakistan",
    "iso2": "PK",
    "iso3": "PAK",
    "admin_name": "Sindh",
    "capital": "minor",
    "population": 35059,
    "id": 1586494172
  },
  {
    "city": "Kōṯah-ye ‘As̲h̲rō",
    "city_ascii": "Kotah-ye `Ashro",
    "lat": 34.45,
    "lng": 68.8,
    "country": "Afghanistan",
    "iso2": "AF",
    "iso3": "AFG",
    "admin_name": "Wardak",
    "capital": "",
    "population": 35008,
    "id": 1004450357
  },
  {
    "city": "Wodonga",
    "city_ascii": "Wodonga",
    "lat": -36.1214,
    "lng": 146.8881,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Victoria",
    "capital": "",
    "population": 35130,
    "id": 1036788540
  },
  {
    "city": "Gorna Oryahovitsa",
    "city_ascii": "Gorna Oryahovitsa",
    "lat": 43.1222,
    "lng": 25.6897,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Veliko Tarnovo",
    "capital": "",
    "population": 35031,
    "id": 1100589506
  },
  {
    "city": "Tamra",
    "city_ascii": "Tamra",
    "lat": 32.8511,
    "lng": 35.2071,
    "country": "Israel",
    "iso2": "IL",
    "iso3": "ISR",
    "admin_name": "Northern",
    "capital": "",
    "population": 34000,
    "id": 1376012882
  },
  {
    "city": "Campbell River",
    "city_ascii": "Campbell River",
    "lat": 50.0244,
    "lng": -125.2475,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "British Columbia",
    "capital": "",
    "population": 35138,
    "id": 1124851971
  },
  {
    "city": "Datteln",
    "city_ascii": "Datteln",
    "lat": 51.6539,
    "lng": 7.3417,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 34614,
    "id": 1276259793
  },
  {
    "city": "Adamantina",
    "city_ascii": "Adamantina",
    "lat": -21.6847,
    "lng": -51.0733,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 35048,
    "id": 1076252044
  },
  {
    "city": "Freehold",
    "city_ascii": "Freehold",
    "lat": 40.2233,
    "lng": -74.2986,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 35116,
    "id": 1840003687
  },
  {
    "city": "Kempen",
    "city_ascii": "Kempen",
    "lat": 51.3658,
    "lng": 6.4194,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 34597,
    "id": 1276967592
  },
  {
    "city": "Carrollwood",
    "city_ascii": "Carrollwood",
    "lat": 28.0577,
    "lng": -82.5149,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 35095,
    "id": 1840029026
  },
  {
    "city": "Qadsayyā",
    "city_ascii": "Qadsayya",
    "lat": 33.5333,
    "lng": 36.2167,
    "country": "Syria",
    "iso2": "SY",
    "iso3": "SYR",
    "admin_name": "Rīf Dimashq",
    "capital": "minor",
    "population": 33571,
    "id": 1760188872
  },
  {
    "city": "Chelmsford",
    "city_ascii": "Chelmsford",
    "lat": 42.6,
    "lng": -71.3631,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 35086,
    "id": 1840070185
  },
  {
    "city": "Casilda",
    "city_ascii": "Casilda",
    "lat": -33.0442,
    "lng": -61.1681,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Santa Fe",
    "capital": "minor",
    "population": 35058,
    "id": 1032000005
  },
  {
    "city": "Seelze",
    "city_ascii": "Seelze",
    "lat": 52.3961,
    "lng": 9.5981,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 34442,
    "id": 1276781689
  },
  {
    "city": "Bagamoyo",
    "city_ascii": "Bagamoyo",
    "lat": -6.4333,
    "lng": 38.9,
    "country": "Tanzania",
    "iso2": "TZ",
    "iso3": "TZA",
    "admin_name": "Coast",
    "capital": "",
    "population": 35000,
    "id": 1834546518
  },
  {
    "city": "Acaxochitlán",
    "city_ascii": "Acaxochitlan",
    "lat": 20.1667,
    "lng": -98.2,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Hidalgo",
    "capital": "minor",
    "population": 34892,
    "id": 1484005465
  },
  {
    "city": "Blagoveshchensk",
    "city_ascii": "Blagoveshchensk",
    "lat": 55.0333,
    "lng": 55.9833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Bashkortostan",
    "capital": "",
    "population": 35008,
    "id": 1643322992
  },
  {
    "city": "Korkino",
    "city_ascii": "Korkino",
    "lat": 54.8833,
    "lng": 61.4,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Chelyabinskaya Oblast’",
    "capital": "",
    "population": 34672,
    "id": 1643383415
  },
  {
    "city": "Baja",
    "city_ascii": "Baja",
    "lat": 46.1833,
    "lng": 18.9536,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Bács-Kiskun",
    "capital": "minor",
    "population": 34788,
    "id": 1348727226
  },
  {
    "city": "Leer",
    "city_ascii": "Leer",
    "lat": 53.2308,
    "lng": 7.4528,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "minor",
    "population": 34486,
    "id": 1276392695
  },
  {
    "city": "La Porte",
    "city_ascii": "La Porte",
    "lat": 29.6689,
    "lng": -95.0484,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 34976,
    "id": 1840020932
  },
  {
    "city": "Aguaí",
    "city_ascii": "Aguai",
    "lat": -22.0603,
    "lng": -46.9736,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 34863,
    "id": 1076299565
  },
  {
    "city": "Uonuma",
    "city_ascii": "Uonuma",
    "lat": 37.2333,
    "lng": 138.9667,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Niigata",
    "capital": "",
    "population": 34895,
    "id": 1392915660
  },
  {
    "city": "Mâcon",
    "city_ascii": "Macon",
    "lat": 46.3063,
    "lng": 4.8313,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Bourgogne-Franche-Comté",
    "capital": "minor",
    "population": 33638,
    "id": 1250992809
  },
  {
    "city": "Aznakayevo",
    "city_ascii": "Aznakayevo",
    "lat": 54.85,
    "lng": 53.0667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Tatarstan",
    "capital": "",
    "population": 34896,
    "id": 1643955098
  },
  {
    "city": "Ōzu",
    "city_ascii": "Ozu",
    "lat": 32.8789,
    "lng": 130.8683,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Kumamoto",
    "capital": "",
    "population": 34571,
    "id": 1392068009
  },
  {
    "city": "Randallstown",
    "city_ascii": "Randallstown",
    "lat": 39.3723,
    "lng": -76.8024,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 34897,
    "id": 1840005688
  },
  {
    "city": "Bethlehem",
    "city_ascii": "Bethlehem",
    "lat": 42.5856,
    "lng": -73.8219,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 34888,
    "id": 1840087216
  },
  {
    "city": "Brunswick",
    "city_ascii": "Brunswick",
    "lat": 41.2464,
    "lng": -81.8198,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 34880,
    "id": 1840003485
  },
  {
    "city": "East Point",
    "city_ascii": "East Point",
    "lat": 33.6696,
    "lng": -84.4701,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 34875,
    "id": 1840013661
  },
  {
    "city": "DeLand",
    "city_ascii": "DeLand",
    "lat": 29.0224,
    "lng": -81.2873,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 34851,
    "id": 1840015071
  },
  {
    "city": "Meyzieu",
    "city_ascii": "Meyzieu",
    "lat": 45.7667,
    "lng": 5.0036,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Auvergne-Rhône-Alpes",
    "capital": "",
    "population": 33477,
    "id": 1250340917
  },
  {
    "city": "Pleasant Hill",
    "city_ascii": "Pleasant Hill",
    "lat": 37.954,
    "lng": -122.0759,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 34839,
    "id": 1840020283
  },
  {
    "city": "Angamāli",
    "city_ascii": "Angamali",
    "lat": 10.196,
    "lng": 76.386,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Kerala",
    "capital": "",
    "population": 33424,
    "id": 1356561428
  },
  {
    "city": "Tábor",
    "city_ascii": "Tabor",
    "lat": 49.4145,
    "lng": 14.6578,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Jihočeský Kraj",
    "capital": "",
    "population": 34277,
    "id": 1203712399
  },
  {
    "city": "Aksay",
    "city_ascii": "Aksay",
    "lat": 51.1714,
    "lng": 53.0349,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Batys Qazaqstan",
    "capital": "",
    "population": 34799,
    "id": 1398621920
  },
  {
    "city": "Merrillville",
    "city_ascii": "Merrillville",
    "lat": 41.4728,
    "lng": -87.3197,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 34792,
    "id": 1840009261
  },
  {
    "city": "Stow",
    "city_ascii": "Stow",
    "lat": 41.1765,
    "lng": -81.4344,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 34785,
    "id": 1840000803
  },
  {
    "city": "Orangevale",
    "city_ascii": "Orangevale",
    "lat": 38.6881,
    "lng": -121.2209,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 34757,
    "id": 1840018842
  },
  {
    "city": "Calhoun",
    "city_ascii": "Calhoun",
    "lat": 34.4918,
    "lng": -84.9391,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 34754,
    "id": 1840013596
  },
  {
    "city": "Nagua",
    "city_ascii": "Nagua",
    "lat": 19.376,
    "lng": -69.847,
    "country": "Dominican Republic",
    "iso2": "DO",
    "iso3": "DOM",
    "admin_name": "Cibao Nordeste",
    "capital": "minor",
    "population": 33862,
    "id": 1214449469
  },
  {
    "city": "Ban Ang Sila",
    "city_ascii": "Ban Ang Sila",
    "lat": 13.3364,
    "lng": 100.9278,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Chon Buri",
    "capital": "",
    "population": 33053,
    "id": 1764931523
  },
  {
    "city": "Sarpol-e Z̄ahāb",
    "city_ascii": "Sarpol-e Zahab",
    "lat": 34.4614,
    "lng": 45.8647,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Kermānshāh",
    "capital": "minor",
    "population": 34632,
    "id": 1364372222
  },
  {
    "city": "Crailsheim",
    "city_ascii": "Crailsheim",
    "lat": 49.1347,
    "lng": 10.0706,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 34400,
    "id": 1276001596
  },
  {
    "city": "Leawood",
    "city_ascii": "Leawood",
    "lat": 38.9076,
    "lng": -94.6258,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kansas",
    "capital": "",
    "population": 34727,
    "id": 1840001638
  },
  {
    "city": "Zelenokumsk",
    "city_ascii": "Zelenokumsk",
    "lat": 44.4,
    "lng": 43.867,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Stavropol’skiy Kray",
    "capital": "",
    "population": 34690,
    "id": 1643031364
  },
  {
    "city": "Merseburg",
    "city_ascii": "Merseburg",
    "lat": 51.3544,
    "lng": 11.9928,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony-Anhalt",
    "capital": "minor",
    "population": 34080,
    "id": 1276735436
  },
  {
    "city": "Haguenau",
    "city_ascii": "Haguenau",
    "lat": 48.82,
    "lng": 7.79,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Grand Est",
    "capital": "minor",
    "population": 34504,
    "id": 1250576545
  },
  {
    "city": "Phra Phutthabat",
    "city_ascii": "Phra Phutthabat",
    "lat": 14.7212,
    "lng": 100.8047,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Sara Buri",
    "capital": "minor",
    "population": 34322,
    "id": 1764815202
  },
  {
    "city": "Menlo Park",
    "city_ascii": "Menlo Park",
    "lat": 37.4686,
    "lng": -122.1672,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 34698,
    "id": 1840020305
  },
  {
    "city": "Zweibrücken",
    "city_ascii": "Zweibrucken",
    "lat": 49.2494,
    "lng": 7.3608,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Rhineland-Palatinate",
    "capital": "minor",
    "population": 34209,
    "id": 1276900149
  },
  {
    "city": "Vibo Valentia",
    "city_ascii": "Vibo Valentia",
    "lat": 38.6666,
    "lng": 16.1,
    "country": "Italy",
    "iso2": "IT",
    "iso3": "ITA",
    "admin_name": "Calabria",
    "capital": "minor",
    "population": 33957,
    "id": 1380359147
  },
  {
    "city": "Biharamulo",
    "city_ascii": "Biharamulo",
    "lat": -2.6296,
    "lng": 31.31,
    "country": "Tanzania",
    "iso2": "TZ",
    "iso3": "TZA",
    "admin_name": "Kagera",
    "capital": "",
    "population": 34038,
    "id": 1834491817
  },
  {
    "city": "Sun Prairie",
    "city_ascii": "Sun Prairie",
    "lat": 43.1827,
    "lng": -89.2358,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 34661,
    "id": 1840002902
  },
  {
    "city": "Bugojno",
    "city_ascii": "Bugojno",
    "lat": 44.0572,
    "lng": 17.4508,
    "country": "Bosnia And Herzegovina",
    "iso2": "BA",
    "iso3": "BIH",
    "admin_name": "Bosnia and Herzegovina, Federation of",
    "capital": "minor",
    "population": 34559,
    "id": 1070311249
  },
  {
    "city": "New City",
    "city_ascii": "New City",
    "lat": 41.1543,
    "lng": -73.9909,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 34652,
    "id": 1840004964
  },
  {
    "city": "Cumberland",
    "city_ascii": "Cumberland",
    "lat": 41.9703,
    "lng": -71.4198,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Rhode Island",
    "capital": "",
    "population": 34652,
    "id": 1840066127
  },
  {
    "city": "Arcata",
    "city_ascii": "Arcata",
    "lat": 40.8615,
    "lng": -124.0757,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 34616,
    "id": 1840009422
  },
  {
    "city": "Esposende",
    "city_ascii": "Esposende",
    "lat": 41.5333,
    "lng": -8.7833,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Braga",
    "capital": "minor",
    "population": 34254,
    "id": 1620944667
  },
  {
    "city": "Chacabuco",
    "city_ascii": "Chacabuco",
    "lat": -34.65,
    "lng": -60.49,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires",
    "capital": "minor",
    "population": 34587,
    "id": 1032210547
  },
  {
    "city": "Shrīrangapattana",
    "city_ascii": "Shrirangapattana",
    "lat": 12.4181,
    "lng": 76.6947,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Karnātaka",
    "capital": "",
    "population": 34135,
    "id": 1356689943
  },
  {
    "city": "Balingen",
    "city_ascii": "Balingen",
    "lat": 48.2731,
    "lng": 8.8506,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "minor",
    "population": 34217,
    "id": 1276586382
  },
  {
    "city": "Hemer",
    "city_ascii": "Hemer",
    "lat": 51.3833,
    "lng": 7.7667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 34080,
    "id": 1276327126
  },
  {
    "city": "Glastonbury",
    "city_ascii": "Glastonbury",
    "lat": 41.6922,
    "lng": -72.5472,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 34578,
    "id": 1840034707
  },
  {
    "city": "Coventry",
    "city_ascii": "Coventry",
    "lat": 41.6933,
    "lng": -71.6611,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Rhode Island",
    "capital": "",
    "population": 34575,
    "id": 1840066132
  },
  {
    "city": "Barsinghausen",
    "city_ascii": "Barsinghausen",
    "lat": 52.3031,
    "lng": 9.4606,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 34234,
    "id": 1276183527
  },
  {
    "city": "Lockport",
    "city_ascii": "Lockport",
    "lat": 43.1698,
    "lng": -78.6956,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 34561,
    "id": 1840000370
  },
  {
    "city": "Boardman",
    "city_ascii": "Boardman",
    "lat": 41.0334,
    "lng": -80.6671,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 34557,
    "id": 1840005186
  },
  {
    "city": "Kópavogur",
    "city_ascii": "Kopavogur",
    "lat": 64.1167,
    "lng": -21.7667,
    "country": "Iceland",
    "iso2": "IS",
    "iso3": "ISL",
    "admin_name": "",
    "capital": "",
    "population": 34140,
    "id": 1352411842
  },
  {
    "city": "Wedel",
    "city_ascii": "Wedel",
    "lat": 53.5833,
    "lng": 9.7,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Schleswig-Holstein",
    "capital": "",
    "population": 33547,
    "id": 1276016066
  },
  {
    "city": "Albany",
    "city_ascii": "Albany",
    "lat": -35.0228,
    "lng": 117.8814,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Western Australia",
    "capital": "",
    "population": 34205,
    "id": 1036047365
  },
  {
    "city": "Marignane",
    "city_ascii": "Marignane",
    "lat": 43.416,
    "lng": 5.2145,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Provence-Alpes-Côte d’Azur",
    "capital": "",
    "population": 32920,
    "id": 1250764669
  },
  {
    "city": "Zimapan",
    "city_ascii": "Zimapan",
    "lat": 20.7333,
    "lng": -99.3833,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Hidalgo",
    "capital": "",
    "population": 34476,
    "id": 1484785009
  },
  {
    "city": "Banes",
    "city_ascii": "Banes",
    "lat": 20.9697,
    "lng": -75.7117,
    "country": "Cuba",
    "iso2": "CU",
    "iso3": "CUB",
    "admin_name": "Holguín",
    "capital": "minor",
    "population": 34452,
    "id": 1192981466
  },
  {
    "city": "Owings Mills",
    "city_ascii": "Owings Mills",
    "lat": 39.4115,
    "lng": -76.7913,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 34477,
    "id": 1840005684
  },
  {
    "city": "Shōbara",
    "city_ascii": "Shobara",
    "lat": 34.8578,
    "lng": 133.0167,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hiroshima",
    "capital": "",
    "population": 34432,
    "id": 1392341787
  },
  {
    "city": "Exmouth",
    "city_ascii": "Exmouth",
    "lat": 50.62,
    "lng": -3.413,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Devon",
    "capital": "",
    "population": 34432,
    "id": 1826008044
  },
  {
    "city": "Pullman",
    "city_ascii": "Pullman",
    "lat": 46.734,
    "lng": -117.1669,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 34425,
    "id": 1840019864
  },
  {
    "city": "Ahrensburg",
    "city_ascii": "Ahrensburg",
    "lat": 53.6747,
    "lng": 10.2411,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Schleswig-Holstein",
    "capital": "",
    "population": 33472,
    "id": 1276744963
  },
  {
    "city": "Nacogdoches",
    "city_ascii": "Nacogdoches",
    "lat": 31.6134,
    "lng": -94.6528,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 34420,
    "id": 1840020820
  },
  {
    "city": "Merauke",
    "city_ascii": "Merauke",
    "lat": -8.4932,
    "lng": 140.4018,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Papua",
    "capital": "minor",
    "population": 34412,
    "id": 1360904439
  },
  {
    "city": "Kearney",
    "city_ascii": "Kearney",
    "lat": 40.701,
    "lng": -99.0834,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Nebraska",
    "capital": "",
    "population": 34418,
    "id": 1840000957
  },
  {
    "city": "Shingū",
    "city_ascii": "Shingu",
    "lat": 33.7153,
    "lng": 130.4467,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Fukuoka",
    "capital": "",
    "population": 32691,
    "id": 1392392982
  },
  {
    "city": "Ferry Pass",
    "city_ascii": "Ferry Pass",
    "lat": 30.5203,
    "lng": -87.1903,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 34416,
    "id": 1840013909
  },
  {
    "city": "Vestavia Hills",
    "city_ascii": "Vestavia Hills",
    "lat": 33.4518,
    "lng": -86.7438,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 34413,
    "id": 1840015673
  },
  {
    "city": "North Ridgeville",
    "city_ascii": "North Ridgeville",
    "lat": 41.3851,
    "lng": -82.0194,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 34392,
    "id": 1840000645
  },
  {
    "city": "Steinfurt",
    "city_ascii": "Steinfurt",
    "lat": 52.1504,
    "lng": 7.3366,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "minor",
    "population": 34084,
    "id": 1276842137
  },
  {
    "city": "Windsor",
    "city_ascii": "Windsor",
    "lat": 51.4791,
    "lng": -0.6095,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Windsor and Maidenhead",
    "capital": "",
    "population": 32608,
    "id": 1826792931
  },
  {
    "city": "Evans",
    "city_ascii": "Evans",
    "lat": 33.5619,
    "lng": -82.1351,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 34379,
    "id": 1840013095
  },
  {
    "city": "Socorro",
    "city_ascii": "Socorro",
    "lat": 31.6384,
    "lng": -106.2601,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 34370,
    "id": 1840022136
  },
  {
    "city": "Randolph",
    "city_ascii": "Randolph",
    "lat": 42.1778,
    "lng": -71.0539,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 34362,
    "id": 1840004675
  },
  {
    "city": "Gladstone",
    "city_ascii": "Gladstone",
    "lat": -23.8489,
    "lng": 151.2625,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Queensland",
    "capital": "",
    "population": 33418,
    "id": 1036070755
  },
  {
    "city": "Gulkevichi",
    "city_ascii": "Gulkevichi",
    "lat": 45.3603,
    "lng": 40.6945,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Krasnodarskiy Kray",
    "capital": "minor",
    "population": 34215,
    "id": 1643733778
  },
  {
    "city": "Dartmouth",
    "city_ascii": "Dartmouth",
    "lat": 41.6138,
    "lng": -70.9973,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 34286,
    "id": 1840070232
  },
  {
    "city": "Vitrolles",
    "city_ascii": "Vitrolles",
    "lat": 43.46,
    "lng": 5.2486,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Provence-Alpes-Côte d’Azur",
    "capital": "",
    "population": 33310,
    "id": 1250199358
  },
  {
    "city": "Bogorodsk",
    "city_ascii": "Bogorodsk",
    "lat": 56.0997,
    "lng": 43.5072,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Nizhegorodskaya Oblast’",
    "capital": "",
    "population": 34240,
    "id": 1643424718
  },
  {
    "city": "Znojmo",
    "city_ascii": "Znojmo",
    "lat": 48.8555,
    "lng": 16.0488,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Jihomoravský Kraj",
    "capital": "",
    "population": 33765,
    "id": 1203362507
  },
  {
    "city": "Businga",
    "city_ascii": "Businga",
    "lat": 3.3404,
    "lng": 20.87,
    "country": "Congo (Kinshasa)",
    "iso2": "CD",
    "iso3": "COD",
    "admin_name": "Nord-Ubangi",
    "capital": "",
    "population": 34247,
    "id": 1180719970
  },
  {
    "city": "Cambrai",
    "city_ascii": "Cambrai",
    "lat": 50.1767,
    "lng": 3.2356,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Hauts-de-France",
    "capital": "minor",
    "population": 32558,
    "id": 1250084532
  },
  {
    "city": "Dakota Ridge",
    "city_ascii": "Dakota Ridge",
    "lat": 39.6192,
    "lng": -105.1344,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 34250,
    "id": 1840038683
  },
  {
    "city": "Shāhīn Dezh",
    "city_ascii": "Shahin Dezh",
    "lat": 36.6792,
    "lng": 46.5667,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Āz̄arbāyjān-e Gharbī",
    "capital": "minor",
    "population": 34204,
    "id": 1364208028
  },
  {
    "city": "Redmond",
    "city_ascii": "Redmond",
    "lat": 44.2631,
    "lng": -121.1798,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 34240,
    "id": 1840020003
  },
  {
    "city": "Valuyki",
    "city_ascii": "Valuyki",
    "lat": 50.1833,
    "lng": 38.1167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Belgorodskaya Oblast’",
    "capital": "",
    "population": 34193,
    "id": 1643337970
  },
  {
    "city": "Firestone",
    "city_ascii": "Firestone",
    "lat": 40.1563,
    "lng": -104.9494,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 34230,
    "id": 1840021363
  },
  {
    "city": "Carmel",
    "city_ascii": "Carmel",
    "lat": 41.3898,
    "lng": -73.7239,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 34227,
    "id": 1840057833
  },
  {
    "city": "Cravinhos",
    "city_ascii": "Cravinhos",
    "lat": -21.3403,
    "lng": -47.7294,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 34110,
    "id": 1076465069
  },
  {
    "city": "Goshen",
    "city_ascii": "Goshen",
    "lat": 41.5743,
    "lng": -85.8308,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 34217,
    "id": 1840013733
  },
  {
    "city": "Russellville",
    "city_ascii": "Russellville",
    "lat": 35.2763,
    "lng": -93.1383,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arkansas",
    "capital": "",
    "population": 34205,
    "id": 1840014541
  },
  {
    "city": "Nowy Targ",
    "city_ascii": "Nowy Targ",
    "lat": 49.4833,
    "lng": 20.0333,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Małopolskie",
    "capital": "minor",
    "population": 33545,
    "id": 1616880514
  },
  {
    "city": "Romans-sur-Isère",
    "city_ascii": "Romans-sur-Isere",
    "lat": 45.0464,
    "lng": 5.0517,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Auvergne-Rhône-Alpes",
    "capital": "",
    "population": 33160,
    "id": 1250668637
  },
  {
    "city": "Grass Valley",
    "city_ascii": "Grass Valley",
    "lat": 39.2237,
    "lng": -121.0521,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 34196,
    "id": 1840020225
  },
  {
    "city": "Kamaishi",
    "city_ascii": "Kamaishi",
    "lat": 39.2758,
    "lng": 141.8856,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Iwate",
    "capital": "",
    "population": 34118,
    "id": 1392589485
  },
  {
    "city": "Menen",
    "city_ascii": "Menen",
    "lat": 50.7956,
    "lng": 3.1217,
    "country": "Belgium",
    "iso2": "BE",
    "iso3": "BEL",
    "admin_name": "Flanders",
    "capital": "",
    "population": 33190,
    "id": 1056841514
  },
  {
    "city": "Túquerres",
    "city_ascii": "Tuquerres",
    "lat": 1.09,
    "lng": -77.6199,
    "country": "Colombia",
    "iso2": "CO",
    "iso3": "COL",
    "admin_name": "Nariño",
    "capital": "minor",
    "population": 33979,
    "id": 1170724789
  },
  {
    "city": "Geldern",
    "city_ascii": "Geldern",
    "lat": 51.5197,
    "lng": 6.3325,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 33836,
    "id": 1276273584
  },
  {
    "city": "Viernheim",
    "city_ascii": "Viernheim",
    "lat": 49.538,
    "lng": 8.5792,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 34175,
    "id": 1276924320
  },
  {
    "city": "Parkland",
    "city_ascii": "Parkland",
    "lat": 26.3218,
    "lng": -80.2533,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 34170,
    "id": 1840015140
  },
  {
    "city": "Wickford",
    "city_ascii": "Wickford",
    "lat": 51.6114,
    "lng": 0.5207,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Essex",
    "capital": "",
    "population": 33486,
    "id": 1826313893
  },
  {
    "city": "Six-Fours-les-Plages",
    "city_ascii": "Six-Fours-les-Plages",
    "lat": 43.1009,
    "lng": 5.82,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Provence-Alpes-Côte d’Azur",
    "capital": "",
    "population": 32829,
    "id": 1250162436
  },
  {
    "city": "Binche",
    "city_ascii": "Binche",
    "lat": 50.4103,
    "lng": 4.1652,
    "country": "Belgium",
    "iso2": "BE",
    "iso3": "BEL",
    "admin_name": "Wallonia",
    "capital": "",
    "population": 33598,
    "id": 1056520553
  },
  {
    "city": "Bariri",
    "city_ascii": "Bariri",
    "lat": -22.0744,
    "lng": -48.7403,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 34048,
    "id": 1076167446
  },
  {
    "city": "Goch",
    "city_ascii": "Goch",
    "lat": 51.6839,
    "lng": 6.1619,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 33825,
    "id": 1276890926
  },
  {
    "city": "Franklin",
    "city_ascii": "Franklin",
    "lat": 42.0862,
    "lng": -71.4113,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 34087,
    "id": 1840031178
  },
  {
    "city": "San Antonio de los Baños",
    "city_ascii": "San Antonio de los Banos",
    "lat": 22.8889,
    "lng": -82.4989,
    "country": "Cuba",
    "iso2": "CU",
    "iso3": "CUB",
    "admin_name": "Artemisa",
    "capital": "minor",
    "population": 33811,
    "id": 1192374010
  },
  {
    "city": "Kennesaw",
    "city_ascii": "Kennesaw",
    "lat": 34.026,
    "lng": -84.6177,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 34077,
    "id": 1840014756
  },
  {
    "city": "Victoria Falls",
    "city_ascii": "Victoria Falls",
    "lat": -17.9333,
    "lng": 25.8333,
    "country": "Zimbabwe",
    "iso2": "ZW",
    "iso3": "ZWE",
    "admin_name": "Matabeleland North",
    "capital": "",
    "population": 33060,
    "id": 1716149447
  },
  {
    "city": "Spruce Grove",
    "city_ascii": "Spruce Grove",
    "lat": 53.545,
    "lng": -113.9008,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Alberta",
    "capital": "",
    "population": 34066,
    "id": 1124943146
  },
  {
    "city": "Villena",
    "city_ascii": "Villena",
    "lat": 38.635,
    "lng": -0.8658,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Valencia",
    "capital": "",
    "population": 33964,
    "id": 1724638080
  },
  {
    "city": "Ōsawa",
    "city_ascii": "Osawa",
    "lat": 33.2067,
    "lng": 130.3839,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Fukuoka",
    "capital": "",
    "population": 33072,
    "id": 1392646983
  },
  {
    "city": "Adelanto",
    "city_ascii": "Adelanto",
    "lat": 34.5815,
    "lng": -117.4397,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 34049,
    "id": 1840019117
  },
  {
    "city": "Irvine",
    "city_ascii": "Irvine",
    "lat": 55.6201,
    "lng": -4.6614,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "North Ayrshire",
    "capital": "",
    "population": 34010,
    "id": 1826965925
  },
  {
    "city": "Carlsbad",
    "city_ascii": "Carlsbad",
    "lat": 32.4011,
    "lng": -104.2389,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Mexico",
    "capital": "",
    "population": 34031,
    "id": 1840019444
  },
  {
    "city": "Puerto Berrío",
    "city_ascii": "Puerto Berrio",
    "lat": 6.4804,
    "lng": -74.42,
    "country": "Colombia",
    "iso2": "CO",
    "iso3": "COL",
    "admin_name": "Antioquia",
    "capital": "minor",
    "population": 33983,
    "id": 1170372171
  },
  {
    "city": "Deggendorf",
    "city_ascii": "Deggendorf",
    "lat": 48.8353,
    "lng": 12.9644,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "minor",
    "population": 33585,
    "id": 1276512590
  },
  {
    "city": "University Place",
    "city_ascii": "University Place",
    "lat": 47.2147,
    "lng": -122.5461,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 34001,
    "id": 1840021130
  },
  {
    "city": "Baltiysk",
    "city_ascii": "Baltiysk",
    "lat": 54.65,
    "lng": 19.9167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kaliningradskaya Oblast’",
    "capital": "minor",
    "population": 33317,
    "id": 1643620016
  },
  {
    "city": "Douglasville",
    "city_ascii": "Douglasville",
    "lat": 33.7384,
    "lng": -84.7065,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 33992,
    "id": 1840014804
  },
  {
    "city": "Salisbury",
    "city_ascii": "Salisbury",
    "lat": 35.6656,
    "lng": -80.4909,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 33988,
    "id": 1840015384
  },
  {
    "city": "Sakaiminato",
    "city_ascii": "Sakaiminato",
    "lat": 35.5397,
    "lng": 133.2317,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Shimane",
    "capital": "",
    "population": 32837,
    "id": 1392354466
  },
  {
    "city": "Ronda",
    "city_ascii": "Ronda",
    "lat": 36.7372,
    "lng": -5.1647,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Andalusia",
    "capital": "",
    "population": 33877,
    "id": 1724795472
  },
  {
    "city": "Furmanov",
    "city_ascii": "Furmanov",
    "lat": 57.25,
    "lng": 41.1,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Ivanovskaya Oblast’",
    "capital": "",
    "population": 33905,
    "id": 1643283283
  },
  {
    "city": "Laramie",
    "city_ascii": "Laramie",
    "lat": 41.3099,
    "lng": -105.6085,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wyoming",
    "capital": "",
    "population": 33929,
    "id": 1840020096
  },
  {
    "city": "Nizhneudinsk",
    "city_ascii": "Nizhneudinsk",
    "lat": 54.9,
    "lng": 99.0167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Irkutskaya Oblast’",
    "capital": "",
    "population": 33918,
    "id": 1643007260
  },
  {
    "city": "Lake Stevens",
    "city_ascii": "Lake Stevens",
    "lat": 48.0028,
    "lng": -122.096,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 33911,
    "id": 1840019794
  },
  {
    "city": "Valparaiso",
    "city_ascii": "Valparaiso",
    "lat": 41.4782,
    "lng": -87.0507,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 33897,
    "id": 1840014674
  },
  {
    "city": "Moose Jaw",
    "city_ascii": "Moose Jaw",
    "lat": 50.3933,
    "lng": -105.5519,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Saskatchewan",
    "capital": "",
    "population": 33890,
    "id": 1124806868
  },
  {
    "city": "Fairborn",
    "city_ascii": "Fairborn",
    "lat": 39.801,
    "lng": -84.0093,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 33876,
    "id": 1840008440
  },
  {
    "city": "Ban Pak Phun",
    "city_ascii": "Ban Pak Phun",
    "lat": 8.5391,
    "lng": 99.9702,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Nakhon Si Thammarat",
    "capital": "",
    "population": 33407,
    "id": 1764808518
  },
  {
    "city": "Estero",
    "city_ascii": "Estero",
    "lat": 26.4277,
    "lng": -81.7951,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 33871,
    "id": 1840013129
  },
  {
    "city": "Mason",
    "city_ascii": "Mason",
    "lat": 39.3571,
    "lng": -84.3023,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 33870,
    "id": 1840008467
  },
  {
    "city": "Uelzen",
    "city_ascii": "Uelzen",
    "lat": 52.9647,
    "lng": 10.5658,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "minor",
    "population": 33614,
    "id": 1276042012
  },
  {
    "city": "Bell Ville",
    "city_ascii": "Bell Ville",
    "lat": -32.6333,
    "lng": -62.6833,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Córdoba",
    "capital": "minor",
    "population": 33835,
    "id": 1032028928
  },
  {
    "city": "Cottonwood Heights",
    "city_ascii": "Cottonwood Heights",
    "lat": 40.6137,
    "lng": -111.8144,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Utah",
    "capital": "",
    "population": 33843,
    "id": 1840018746
  },
  {
    "city": "Sonoma",
    "city_ascii": "Sonoma",
    "lat": 38.2902,
    "lng": -122.4598,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 33831,
    "id": 1840021490
  },
  {
    "city": "Luuq",
    "city_ascii": "Luuq",
    "lat": 3.8005,
    "lng": 42.55,
    "country": "Somalia",
    "iso2": "SO",
    "iso3": "SOM",
    "admin_name": "Gedo",
    "capital": "",
    "population": 33820,
    "id": 1706505283
  },
  {
    "city": "Falls",
    "city_ascii": "Falls",
    "lat": 40.1686,
    "lng": -74.7915,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 33826,
    "id": 1840152152
  },
  {
    "city": "Geraardsbergen",
    "city_ascii": "Geraardsbergen",
    "lat": 50.7667,
    "lng": 3.8667,
    "country": "Belgium",
    "iso2": "BE",
    "iso3": "BEL",
    "admin_name": "Flanders",
    "capital": "",
    "population": 33403,
    "id": 1056572611
  },
  {
    "city": "French Valley",
    "city_ascii": "French Valley",
    "lat": 33.5998,
    "lng": -117.1069,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 33818,
    "id": 1840075867
  },
  {
    "city": "Ken Caryl",
    "city_ascii": "Ken Caryl",
    "lat": 39.577,
    "lng": -105.1144,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 33804,
    "id": 1840028580
  },
  {
    "city": "Galesburg",
    "city_ascii": "Galesburg",
    "lat": 40.9506,
    "lng": -90.3763,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 33767,
    "id": 1840008277
  },
  {
    "city": "Penticton",
    "city_ascii": "Penticton",
    "lat": 49.4911,
    "lng": -119.5886,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "British Columbia",
    "capital": "",
    "population": 33761,
    "id": 1124613898
  },
  {
    "city": "Gīmbī",
    "city_ascii": "Gimbi",
    "lat": 9.1667,
    "lng": 35.8333,
    "country": "Ethiopia",
    "iso2": "ET",
    "iso3": "ETH",
    "admin_name": "Oromīya",
    "capital": "",
    "population": 31809,
    "id": 1231550284
  },
  {
    "city": "Belen",
    "city_ascii": "Belen",
    "lat": 36.4917,
    "lng": 36.1917,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Hatay",
    "capital": "minor",
    "population": 33540,
    "id": 1792296962
  },
  {
    "city": "Kaneohe",
    "city_ascii": "Kaneohe",
    "lat": 21.4062,
    "lng": -157.7904,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Hawaii",
    "capital": "",
    "population": 33739,
    "id": 1840029498
  },
  {
    "city": "Chilecito",
    "city_ascii": "Chilecito",
    "lat": -29.1667,
    "lng": -67.5,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "La Rioja",
    "capital": "minor",
    "population": 33724,
    "id": 1032078195
  },
  {
    "city": "Wakabadai",
    "city_ascii": "Wakabadai",
    "lat": 45.4,
    "lng": 141.7,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hokkaidō",
    "capital": "",
    "population": 33689,
    "id": 1392002486
  },
  {
    "city": "Teykovo",
    "city_ascii": "Teykovo",
    "lat": 56.85,
    "lng": 40.55,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Ivanovskaya Oblast’",
    "capital": "",
    "population": 32511,
    "id": 1643732572
  },
  {
    "city": "Sukhoy Log",
    "city_ascii": "Sukhoy Log",
    "lat": 56.9167,
    "lng": 62.05,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sverdlovskaya Oblast’",
    "capital": "",
    "population": 33689,
    "id": 1643890809
  },
  {
    "city": "Vyatskiye Polyany",
    "city_ascii": "Vyatskiye Polyany",
    "lat": 56.2184,
    "lng": 51.0686,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kirovskaya Oblast’",
    "capital": "",
    "population": 32562,
    "id": 1643642329
  },
  {
    "city": "Mosonmagyaróvár",
    "city_ascii": "Mosonmagyarovar",
    "lat": 47.8667,
    "lng": 17.2667,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Győr-Moson-Sopron",
    "capital": "minor",
    "population": 33318,
    "id": 1348666755
  },
  {
    "city": "Warsaw",
    "city_ascii": "Warsaw",
    "lat": 41.2448,
    "lng": -85.8464,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 33708,
    "id": 1840010277
  },
  {
    "city": "Fatehpur Sīkri",
    "city_ascii": "Fatehpur Sikri",
    "lat": 27.0911,
    "lng": 77.6611,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Uttar Pradesh",
    "capital": "",
    "population": 32905,
    "id": 1356099769
  },
  {
    "city": "Korschenbroich",
    "city_ascii": "Korschenbroich",
    "lat": 51.1833,
    "lng": 6.5167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 33066,
    "id": 1276206387
  },
  {
    "city": "Korsakov",
    "city_ascii": "Korsakov",
    "lat": 46.6333,
    "lng": 142.7667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sakhalinskaya Oblast’",
    "capital": "",
    "population": 33645,
    "id": 1643017767
  },
  {
    "city": "Ibaté",
    "city_ascii": "Ibate",
    "lat": -21.955,
    "lng": -47.9969,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 33535,
    "id": 1076395966
  },
  {
    "city": "Lebowakgomo",
    "city_ascii": "Lebowakgomo",
    "lat": -24.305,
    "lng": 29.565,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "Limpopo",
    "capital": "",
    "population": 33308,
    "id": 1710999076
  },
  {
    "city": "Mildura",
    "city_ascii": "Mildura",
    "lat": -34.1889,
    "lng": 142.1583,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Victoria",
    "capital": "",
    "population": 32738,
    "id": 1036624348
  },
  {
    "city": "Riverhead",
    "city_ascii": "Riverhead",
    "lat": 40.9409,
    "lng": -72.7095,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 33625,
    "id": 1840058445
  },
  {
    "city": "San Dimas",
    "city_ascii": "San Dimas",
    "lat": 34.1082,
    "lng": -117.809,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 33621,
    "id": 1840021860
  },
  {
    "city": "Kuji",
    "city_ascii": "Kuji",
    "lat": 40.1903,
    "lng": 141.7753,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Iwate",
    "capital": "",
    "population": 33556,
    "id": 1392882660
  },
  {
    "city": "Rheinfelden (Baden)",
    "city_ascii": "Rheinfelden (Baden)",
    "lat": 47.5611,
    "lng": 7.7917,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 33074,
    "id": 1276547516
  },
  {
    "city": "Ayvacık",
    "city_ascii": "Ayvacik",
    "lat": 39.6011,
    "lng": 26.4047,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Çanakkale",
    "capital": "minor",
    "population": 33568,
    "id": 1792135489
  },
  {
    "city": "Zhitiqara",
    "city_ascii": "Zhitiqara",
    "lat": 52.1902,
    "lng": 61.1989,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Qostanay",
    "capital": "",
    "population": 33587,
    "id": 1398276170
  },
  {
    "city": "Ciudad Tecún Umán",
    "city_ascii": "Ciudad Tecun Uman",
    "lat": 14.6833,
    "lng": -92.1333,
    "country": "Guatemala",
    "iso2": "GT",
    "iso3": "GTM",
    "admin_name": "San Marcos",
    "capital": "minor",
    "population": 33426,
    "id": 1320502351
  },
  {
    "city": "Dana Point",
    "city_ascii": "Dana Point",
    "lat": 33.4733,
    "lng": -117.6968,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 33577,
    "id": 1840019328
  },
  {
    "city": "Iperó",
    "city_ascii": "Ipero",
    "lat": -23.3503,
    "lng": -47.6886,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 33367,
    "id": 1076747973
  },
  {
    "city": "Panjakent",
    "city_ascii": "Panjakent",
    "lat": 39.5031,
    "lng": 67.615,
    "country": "Tajikistan",
    "iso2": "TJ",
    "iso3": "TJK",
    "admin_name": "Sughd",
    "capital": "minor",
    "population": 33000,
    "id": 1762088946
  },
  {
    "city": "Port Moody",
    "city_ascii": "Port Moody",
    "lat": 49.2831,
    "lng": -122.8317,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "British Columbia",
    "capital": "",
    "population": 33551,
    "id": 1124252668
  },
  {
    "city": "Castro",
    "city_ascii": "Castro",
    "lat": -42.4824,
    "lng": -73.7643,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Los Lagos",
    "capital": "minor",
    "population": 33417,
    "id": 1152445107
  },
  {
    "city": "Ōyama",
    "city_ascii": "Oyama",
    "lat": 35.2719,
    "lng": 139.5864,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Kanagawa",
    "capital": "",
    "population": 31683,
    "id": 1392179278
  },
  {
    "city": "Slobodskoy",
    "city_ascii": "Slobodskoy",
    "lat": 58.7242,
    "lng": 50.1612,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kirovskaya Oblast’",
    "capital": "",
    "population": 32793,
    "id": 1643784759
  },
  {
    "city": "Midlothian",
    "city_ascii": "Midlothian",
    "lat": 32.4668,
    "lng": -96.9892,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 33532,
    "id": 1840020757
  },
  {
    "city": "Newark",
    "city_ascii": "Newark",
    "lat": 39.6776,
    "lng": -75.7573,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Delaware",
    "capital": "",
    "population": 33515,
    "id": 1840005578
  },
  {
    "city": "Derry",
    "city_ascii": "Derry",
    "lat": 42.8888,
    "lng": -71.2804,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Hampshire",
    "capital": "",
    "population": 33515,
    "id": 1840054793
  },
  {
    "city": "Alamogordo",
    "city_ascii": "Alamogordo",
    "lat": 32.8837,
    "lng": -105.9624,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Mexico",
    "capital": "",
    "population": 33513,
    "id": 1840019403
  },
  {
    "city": "Shiroishi",
    "city_ascii": "Shiroishi",
    "lat": 38.0022,
    "lng": 140.6197,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Miyagi",
    "capital": "",
    "population": 33380,
    "id": 1392860584
  },
  {
    "city": "Kasulu",
    "city_ascii": "Kasulu",
    "lat": -4.5767,
    "lng": 30.1025,
    "country": "Tanzania",
    "iso2": "TZ",
    "iso3": "TZA",
    "admin_name": "Kigoma",
    "capital": "",
    "population": 33452,
    "id": 1834918111
  },
  {
    "city": "Alabaster",
    "city_ascii": "Alabaster",
    "lat": 33.2198,
    "lng": -86.8225,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 33487,
    "id": 1840013756
  },
  {
    "city": "Hlukhiv",
    "city_ascii": "Hlukhiv",
    "lat": 51.6765,
    "lng": 33.9078,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Sums’ka Oblast’",
    "capital": "minor",
    "population": 33478,
    "id": 1804181345
  },
  {
    "city": "Qarqan",
    "city_ascii": "Qarqan",
    "lat": 38.1338,
    "lng": 85.5333,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Xinjiang",
    "capital": "minor",
    "population": 32494,
    "id": 1156279285
  },
  {
    "city": "Lexington",
    "city_ascii": "Lexington",
    "lat": 42.4456,
    "lng": -71.2307,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 33480,
    "id": 1840053638
  },
  {
    "city": "Příbram",
    "city_ascii": "Pribram",
    "lat": 49.69,
    "lng": 14.0105,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Středočeský Kraj",
    "capital": "",
    "population": 32503,
    "id": 1203262749
  },
  {
    "city": "Kaizu",
    "city_ascii": "Kaizu",
    "lat": 35.2206,
    "lng": 136.6367,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Gifu",
    "capital": "",
    "population": 33178,
    "id": 1392269681
  },
  {
    "city": "Deer Park",
    "city_ascii": "Deer Park",
    "lat": 29.6898,
    "lng": -95.1151,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 33474,
    "id": 1840019621
  },
  {
    "city": "Dumfries",
    "city_ascii": "Dumfries",
    "lat": 55.07,
    "lng": -3.603,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Dumfries and Galloway",
    "capital": "",
    "population": 33320,
    "id": 1826801547
  },
  {
    "city": "Năvodari",
    "city_ascii": "Navodari",
    "lat": 44.3208,
    "lng": 28.6125,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Constanţa",
    "capital": "",
    "population": 32981,
    "id": 1642234655
  },
  {
    "city": "Nokia",
    "city_ascii": "Nokia",
    "lat": 61.4778,
    "lng": 23.5097,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Pirkanmaa",
    "capital": "minor",
    "population": 33322,
    "id": 1246560498
  },
  {
    "city": "Woodridge",
    "city_ascii": "Woodridge",
    "lat": 41.7368,
    "lng": -88.0408,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 33432,
    "id": 1840011411
  },
  {
    "city": "Biberach",
    "city_ascii": "Biberach",
    "lat": 48.0981,
    "lng": 9.7886,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "minor",
    "population": 32938,
    "id": 1276241010
  },
  {
    "city": "Southport",
    "city_ascii": "Southport",
    "lat": -27.9667,
    "lng": 153.4,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Queensland",
    "capital": "",
    "population": 31908,
    "id": 1036417513
  },
  {
    "city": "Pikesville",
    "city_ascii": "Pikesville",
    "lat": 39.3893,
    "lng": -76.702,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 33387,
    "id": 1840005687
  },
  {
    "city": "Bridgeton",
    "city_ascii": "Bridgeton",
    "lat": 39.4286,
    "lng": -75.2281,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 33379,
    "id": 1840016610
  },
  {
    "city": "Greer",
    "city_ascii": "Greer",
    "lat": 34.9313,
    "lng": -82.2315,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 33373,
    "id": 1840013502
  },
  {
    "city": "Tayshet",
    "city_ascii": "Tayshet",
    "lat": 55.9333,
    "lng": 98.0167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Irkutskaya Oblast’",
    "capital": "",
    "population": 33364,
    "id": 1643635211
  },
  {
    "city": "Umaria",
    "city_ascii": "Umaria",
    "lat": 23.5245,
    "lng": 80.8365,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Madhya Pradesh",
    "capital": "",
    "population": 33114,
    "id": 1356133627
  },
  {
    "city": "Tavda",
    "city_ascii": "Tavda",
    "lat": 58.05,
    "lng": 65.2667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sverdlovskaya Oblast’",
    "capital": "",
    "population": 33356,
    "id": 1643627997
  },
  {
    "city": "Vác",
    "city_ascii": "Vac",
    "lat": 47.7753,
    "lng": 19.1311,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Pest",
    "capital": "minor",
    "population": 32828,
    "id": 1348810997
  },
  {
    "city": "Police",
    "city_ascii": "Police",
    "lat": 53.55,
    "lng": 14.5708,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Zachodniopomorskie",
    "capital": "minor",
    "population": 33152,
    "id": 1616953743
  },
  {
    "city": "Zgorzelec",
    "city_ascii": "Zgorzelec",
    "lat": 51.1528,
    "lng": 15,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Dolnośląskie",
    "capital": "minor",
    "population": 31359,
    "id": 1616207310
  },
  {
    "city": "Tynda",
    "city_ascii": "Tynda",
    "lat": 55.15,
    "lng": 124.7167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Amurskaya Oblast’",
    "capital": "",
    "population": 33061,
    "id": 1643364583
  },
  {
    "city": "Springville",
    "city_ascii": "Springville",
    "lat": 40.1638,
    "lng": -111.6206,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Utah",
    "capital": "",
    "population": 33310,
    "id": 1840021399
  },
  {
    "city": "Obukhiv",
    "city_ascii": "Obukhiv",
    "lat": 50.1,
    "lng": 30.6167,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Kyyivs’ka Oblast’",
    "capital": "minor",
    "population": 33204,
    "id": 1804079919
  },
  {
    "city": "Espinho",
    "city_ascii": "Espinho",
    "lat": 41.01,
    "lng": -8.64,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Aveiro",
    "capital": "minor",
    "population": 31786,
    "id": 1620002917
  },
  {
    "city": "Canavieiras",
    "city_ascii": "Canavieiras",
    "lat": -15.675,
    "lng": -38.9472,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Bahia",
    "capital": "",
    "population": 33268,
    "id": 1076258532
  },
  {
    "city": "Saratoga Springs",
    "city_ascii": "Saratoga Springs",
    "lat": 40.3449,
    "lng": -111.9152,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Utah",
    "capital": "",
    "population": 33282,
    "id": 1840021397
  },
  {
    "city": "Levice",
    "city_ascii": "Levice",
    "lat": 48.2136,
    "lng": 18.6069,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Nitriansky",
    "capital": "minor",
    "population": 32735,
    "id": 1703699284
  },
  {
    "city": "Dimona",
    "city_ascii": "Dimona",
    "lat": 31.07,
    "lng": 35.03,
    "country": "Israel",
    "iso2": "IL",
    "iso3": "ISR",
    "admin_name": "Southern",
    "capital": "",
    "population": 33258,
    "id": 1376956540
  },
  {
    "city": "Temascalapa",
    "city_ascii": "Temascalapa",
    "lat": 19.8,
    "lng": -98.9,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Hidalgo",
    "capital": "minor",
    "population": 33063,
    "id": 1484792221
  },
  {
    "city": "Shatura",
    "city_ascii": "Shatura",
    "lat": 55.5667,
    "lng": 39.5333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Moskovskaya Oblast’",
    "capital": "",
    "population": 33222,
    "id": 1643976065
  },
  {
    "city": "Bragado",
    "city_ascii": "Bragado",
    "lat": -35.1167,
    "lng": -60.5,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires",
    "capital": "minor",
    "population": 33222,
    "id": 1032509795
  },
  {
    "city": "Copperas Cove",
    "city_ascii": "Copperas Cove",
    "lat": 31.1192,
    "lng": -97.914,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 33235,
    "id": 1840019538
  },
  {
    "city": "Epsom",
    "city_ascii": "Epsom",
    "lat": 51.336,
    "lng": -0.267,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Surrey",
    "capital": "",
    "population": 31489,
    "id": 1826078283
  },
  {
    "city": "Bou Ahmed",
    "city_ascii": "Bou Ahmed",
    "lat": 33.1119,
    "lng": -7.4058,
    "country": "Morocco",
    "iso2": "MA",
    "iso3": "MAR",
    "admin_name": "Casablanca-Settat",
    "capital": "",
    "population": 33105,
    "id": 1504251428
  },
  {
    "city": "Motherwell",
    "city_ascii": "Motherwell",
    "lat": 55.7839,
    "lng": -3.9852,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "North Lanarkshire",
    "capital": "",
    "population": 32480,
    "id": 1826854355
  },
  {
    "city": "American Fork",
    "city_ascii": "American Fork",
    "lat": 40.3792,
    "lng": -111.7952,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Utah",
    "capital": "",
    "population": 33161,
    "id": 1840018756
  },
  {
    "city": "Franklin",
    "city_ascii": "Franklin",
    "lat": 41.1101,
    "lng": -74.5886,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 33153,
    "id": 1840003480
  },
  {
    "city": "Bad Nauheim",
    "city_ascii": "Bad Nauheim",
    "lat": 50.3667,
    "lng": 8.75,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 32163,
    "id": 1276664999
  },
  {
    "city": "Motosu",
    "city_ascii": "Motosu",
    "lat": 35.4831,
    "lng": 136.6786,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Gifu",
    "capital": "",
    "population": 33054,
    "id": 1392598380
  },
  {
    "city": "Andover",
    "city_ascii": "Andover",
    "lat": 45.2571,
    "lng": -93.3265,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 33140,
    "id": 1840006716
  },
  {
    "city": "Matthews",
    "city_ascii": "Matthews",
    "lat": 35.1195,
    "lng": -80.7101,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 33138,
    "id": 1840016350
  },
  {
    "city": "Plaisir",
    "city_ascii": "Plaisir",
    "lat": 48.8183,
    "lng": 1.9472,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 31419,
    "id": 1250415771
  },
  {
    "city": "De Aar",
    "city_ascii": "De Aar",
    "lat": -30.65,
    "lng": 24.0167,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "Northern Cape",
    "capital": "",
    "population": 32318,
    "id": 1710551900
  },
  {
    "city": "Djenné",
    "city_ascii": "Djenne",
    "lat": 13.9,
    "lng": -4.55,
    "country": "Mali",
    "iso2": "ML",
    "iso3": "MLI",
    "admin_name": "Mopti",
    "capital": "minor",
    "population": 32944,
    "id": 1466017388
  },
  {
    "city": "Ōiso",
    "city_ascii": "Oiso",
    "lat": 35.3069,
    "lng": 139.3114,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Kanagawa",
    "capital": "",
    "population": 31284,
    "id": 1392342585
  },
  {
    "city": "Yorii",
    "city_ascii": "Yorii",
    "lat": 36.1183,
    "lng": 139.1931,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Saitama",
    "capital": "",
    "population": 32576,
    "id": 1392564179
  },
  {
    "city": "Mushie",
    "city_ascii": "Mushie",
    "lat": -3.0196,
    "lng": 16.92,
    "country": "Congo (Kinshasa)",
    "iso2": "CD",
    "iso3": "COD",
    "admin_name": "Mai-Ndombe",
    "capital": "",
    "population": 33062,
    "id": 1180240809
  },
  {
    "city": "Liptovský Mikuláš",
    "city_ascii": "Liptovsky Mikulas",
    "lat": 49.0811,
    "lng": 19.6181,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Žilinský",
    "capital": "minor",
    "population": 32593,
    "id": 1703155005
  },
  {
    "city": "Sarıkaya",
    "city_ascii": "Sarikaya",
    "lat": 39.4936,
    "lng": 35.3769,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Yozgat",
    "capital": "minor",
    "population": 33010,
    "id": 1792208662
  },
  {
    "city": "Woodstock",
    "city_ascii": "Woodstock",
    "lat": 34.1026,
    "lng": -84.509,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 33039,
    "id": 1840015574
  },
  {
    "city": "Forbe Oroya",
    "city_ascii": "Forbe Oroya",
    "lat": -11.5333,
    "lng": -75.9,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "Junín",
    "capital": "",
    "population": 33000,
    "id": 1604666304
  },
  {
    "city": "Bardejov",
    "city_ascii": "Bardejov",
    "lat": 49.295,
    "lng": 21.2758,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Prešovský",
    "capital": "minor",
    "population": 32587,
    "id": 1703663141
  },
  {
    "city": "Descalvado",
    "city_ascii": "Descalvado",
    "lat": -21.9039,
    "lng": -47.6189,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 32980,
    "id": 1076021755
  },
  {
    "city": "Fallbrook",
    "city_ascii": "Fallbrook",
    "lat": 33.3693,
    "lng": -117.2259,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 33021,
    "id": 1840018019
  },
  {
    "city": "Itzehoe",
    "city_ascii": "Itzehoe",
    "lat": 53.925,
    "lng": 9.5164,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Schleswig-Holstein",
    "capital": "minor",
    "population": 31879,
    "id": 1276277285
  },
  {
    "city": "Florence",
    "city_ascii": "Florence",
    "lat": 38.99,
    "lng": -84.647,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kentucky",
    "capital": "",
    "population": 33004,
    "id": 1840013161
  },
  {
    "city": "Glossop",
    "city_ascii": "Glossop",
    "lat": 53.443,
    "lng": -1.949,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Derbyshire",
    "capital": "",
    "population": 33000,
    "id": 1826787990
  },
  {
    "city": "Fruit Cove",
    "city_ascii": "Fruit Cove",
    "lat": 30.0972,
    "lng": -81.6176,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 32994,
    "id": 1840013970
  },
  {
    "city": "Jülich",
    "city_ascii": "Julich",
    "lat": 50.9222,
    "lng": 6.3583,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 32632,
    "id": 1276891190
  },
  {
    "city": "Leamington",
    "city_ascii": "Leamington",
    "lat": 42.0667,
    "lng": -82.5833,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 32991,
    "id": 1124258797
  },
  {
    "city": "Lampertheim",
    "city_ascii": "Lampertheim",
    "lat": 49.5942,
    "lng": 8.4671,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 32537,
    "id": 1276406891
  },
  {
    "city": "Chernushka",
    "city_ascii": "Chernushka",
    "lat": 56.5,
    "lng": 56.0833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Permskiy Kray",
    "capital": "",
    "population": 32952,
    "id": 1643534428
  },
  {
    "city": "Reggane",
    "city_ascii": "Reggane",
    "lat": 26.7,
    "lng": 0.1666,
    "country": "Algeria",
    "iso2": "DZ",
    "iso3": "DZA",
    "admin_name": "Adrar",
    "capital": "",
    "population": 32974,
    "id": 1012709576
  },
  {
    "city": "Winsford",
    "city_ascii": "Winsford",
    "lat": 53.194,
    "lng": -2.52,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Cheshire West and Chester",
    "capital": "",
    "population": 32610,
    "id": 1826615523
  },
  {
    "city": "Bernburg",
    "city_ascii": "Bernburg",
    "lat": 51.8,
    "lng": 11.7333,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony-Anhalt",
    "capital": "minor",
    "population": 32674,
    "id": 1276331294
  },
  {
    "city": "Northbrook",
    "city_ascii": "Northbrook",
    "lat": 42.1292,
    "lng": -87.8353,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 32958,
    "id": 1840011304
  },
  {
    "city": "Baraguá",
    "city_ascii": "Baragua",
    "lat": 21.6819,
    "lng": -78.6244,
    "country": "Cuba",
    "iso2": "CU",
    "iso3": "CUB",
    "admin_name": "Ciego de Ávila",
    "capital": "",
    "population": 32909,
    "id": 1192061113
  },
  {
    "city": "Wernigerode",
    "city_ascii": "Wernigerode",
    "lat": 51.835,
    "lng": 10.7853,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony-Anhalt",
    "capital": "",
    "population": 32733,
    "id": 1276322228
  },
  {
    "city": "Ōdachō-ōda",
    "city_ascii": "Odacho-oda",
    "lat": 35.1922,
    "lng": 132.4997,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Shimane",
    "capital": "",
    "population": 32848,
    "id": 1392184583
  },
  {
    "city": "Ózd",
    "city_ascii": "Ozd",
    "lat": 48.2192,
    "lng": 20.2869,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Borsod-Abaúj-Zemplén",
    "capital": "minor",
    "population": 32564,
    "id": 1348082095
  },
  {
    "city": "Salina",
    "city_ascii": "Salina",
    "lat": 43.1031,
    "lng": -76.1758,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 32919,
    "id": 1840087878
  },
  {
    "city": "Trëkhgornyy",
    "city_ascii": "Trekhgornyy",
    "lat": 54.8,
    "lng": 58.45,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Chelyabinskaya Oblast’",
    "capital": "",
    "population": 32715,
    "id": 1643017912
  },
  {
    "city": "Nagato",
    "city_ascii": "Nagato",
    "lat": 34.3711,
    "lng": 131.1822,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Yamaguchi",
    "capital": "",
    "population": 32817,
    "id": 1392904724
  },
  {
    "city": "Forchheim",
    "city_ascii": "Forchheim",
    "lat": 49.7197,
    "lng": 11.0581,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "minor",
    "population": 32171,
    "id": 1276030665
  },
  {
    "city": "St. Charles",
    "city_ascii": "St. Charles",
    "lat": 41.9193,
    "lng": -88.3109,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 32887,
    "id": 1840009190
  },
  {
    "city": "Kolín",
    "city_ascii": "Kolin",
    "lat": 50.0282,
    "lng": 15.2006,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Středočeský Kraj",
    "capital": "",
    "population": 31973,
    "id": 1203413479
  },
  {
    "city": "Zentsujichó",
    "city_ascii": "Zentsujicho",
    "lat": 34.2283,
    "lng": 133.7872,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Kagawa",
    "capital": "",
    "population": 32073,
    "id": 1392897802
  },
  {
    "city": "Santa Cruz das Palmeiras",
    "city_ascii": "Santa Cruz das Palmeiras",
    "lat": -21.8269,
    "lng": -47.2489,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 32749,
    "id": 1076200565
  },
  {
    "city": "San Luis",
    "city_ascii": "San Luis",
    "lat": 32.4911,
    "lng": -114.7089,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 32850,
    "id": 1840021996
  },
  {
    "city": "Fairmont",
    "city_ascii": "Fairmont",
    "lat": 39.4768,
    "lng": -80.1491,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "West Virginia",
    "capital": "",
    "population": 32847,
    "id": 1840005732
  },
  {
    "city": "Naumburg",
    "city_ascii": "Naumburg",
    "lat": 51.1521,
    "lng": 11.8098,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony-Anhalt",
    "capital": "minor",
    "population": 32402,
    "id": 1276335227
  },
  {
    "city": "Baraawe",
    "city_ascii": "Baraawe",
    "lat": 1.1133,
    "lng": 44.0303,
    "country": "Somalia",
    "iso2": "SO",
    "iso3": "SOM",
    "admin_name": "Shabeellaha Hoose",
    "capital": "",
    "population": 32800,
    "id": 1706566007
  },
  {
    "city": "Şāfītā",
    "city_ascii": "Safita",
    "lat": 34.8208,
    "lng": 36.1173,
    "country": "Syria",
    "iso2": "SY",
    "iso3": "SYR",
    "admin_name": "Ţarţūs",
    "capital": "minor",
    "population": 32213,
    "id": 1760592493
  },
  {
    "city": "Kaarina",
    "city_ascii": "Kaarina",
    "lat": 60.4069,
    "lng": 22.3722,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Varsinais-Suomi",
    "capital": "minor",
    "population": 32590,
    "id": 1246381646
  },
  {
    "city": "São Miguel Arcanjo",
    "city_ascii": "Sao Miguel Arcanjo",
    "lat": -23.8778,
    "lng": -47.9969,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 32769,
    "id": 1076595585
  },
  {
    "city": "Ylöjärvi",
    "city_ascii": "Ylojarvi",
    "lat": 61.55,
    "lng": 23.5833,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Pirkanmaa",
    "capital": "minor",
    "population": 32738,
    "id": 1246651024
  },
  {
    "city": "Vechta",
    "city_ascii": "Vechta",
    "lat": 52.7306,
    "lng": 8.2886,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "minor",
    "population": 32433,
    "id": 1276147280
  },
  {
    "city": "Lawrence",
    "city_ascii": "Lawrence",
    "lat": 40.2954,
    "lng": -74.7205,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 32794,
    "id": 1840056345
  },
  {
    "city": "Altenburg",
    "city_ascii": "Altenburg",
    "lat": 50.985,
    "lng": 12.4333,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Thuringia",
    "capital": "minor",
    "population": 32074,
    "id": 1276224301
  },
  {
    "city": "Namegata",
    "city_ascii": "Namegata",
    "lat": 35.9903,
    "lng": 140.4892,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Ibaraki",
    "capital": "",
    "population": 32604,
    "id": 1392906263
  },
  {
    "city": "Nurlat",
    "city_ascii": "Nurlat",
    "lat": 54.4333,
    "lng": 50.8,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Tatarstan",
    "capital": "",
    "population": 32703,
    "id": 1643326238
  },
  {
    "city": "Yashiki",
    "city_ascii": "Yashiki",
    "lat": 32.7914,
    "lng": 130.8164,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Kumamoto",
    "capital": "",
    "population": 32218,
    "id": 1392971999
  },
  {
    "city": "Chancay",
    "city_ascii": "Chancay",
    "lat": -11.5653,
    "lng": -77.2714,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "Lima",
    "capital": "",
    "population": 32312,
    "id": 1604882367
  },
  {
    "city": "Dobryanka",
    "city_ascii": "Dobryanka",
    "lat": 58.45,
    "lng": 56.4167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Permskiy Kray",
    "capital": "",
    "population": 32676,
    "id": 1643202553
  },
  {
    "city": "Ridgecrest",
    "city_ascii": "Ridgecrest",
    "lat": 35.6308,
    "lng": -117.6621,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 32684,
    "id": 1840020417
  },
  {
    "city": "East Kelowna",
    "city_ascii": "East Kelowna",
    "lat": 49.8625,
    "lng": -119.5833,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "British Columbia",
    "capital": "",
    "population": 32655,
    "id": 1124070905
  },
  {
    "city": "Gödöllő",
    "city_ascii": "Godollo",
    "lat": 47.6,
    "lng": 19.3667,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Pest",
    "capital": "minor",
    "population": 32131,
    "id": 1348039473
  },
  {
    "city": "Redcliff",
    "city_ascii": "Redcliff",
    "lat": -19.0333,
    "lng": 29.7833,
    "country": "Zimbabwe",
    "iso2": "ZW",
    "iso3": "ZWE",
    "admin_name": "Midlands",
    "capital": "",
    "population": 32346,
    "id": 1716966753
  },
  {
    "city": "Ostrogozhsk",
    "city_ascii": "Ostrogozhsk",
    "lat": 50.8667,
    "lng": 39.0667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Voronezhskaya Oblast’",
    "capital": "",
    "population": 32601,
    "id": 1643004403
  },
  {
    "city": "Shimotoba",
    "city_ascii": "Shimotoba",
    "lat": 34.8839,
    "lng": 135.6628,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Kyōto",
    "capital": "",
    "population": 30797,
    "id": 1392825221
  },
  {
    "city": "Mioveni",
    "city_ascii": "Mioveni",
    "lat": 44.9553,
    "lng": 24.9356,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Argeş",
    "capital": "",
    "population": 31998,
    "id": 1642961342
  },
  {
    "city": "Lower Makefield",
    "city_ascii": "Lower Makefield",
    "lat": 40.2309,
    "lng": -74.855,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 32622,
    "id": 1840151114
  },
  {
    "city": "Shimizuchō",
    "city_ascii": "Shimizucho",
    "lat": 35.0992,
    "lng": 138.9028,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Shizuoka",
    "capital": "",
    "population": 31974,
    "id": 1392909115
  },
  {
    "city": "Massillon",
    "city_ascii": "Massillon",
    "lat": 40.7838,
    "lng": -81.5254,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 32584,
    "id": 1840000965
  },
  {
    "city": "Kulebaki",
    "city_ascii": "Kulebaki",
    "lat": 55.4167,
    "lng": 42.5167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Nizhegorodskaya Oblast’",
    "capital": "",
    "population": 32518,
    "id": 1643223914
  },
  {
    "city": "Réthymno",
    "city_ascii": "Rethymno",
    "lat": 35.3667,
    "lng": 24.4667,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Kríti",
    "capital": "minor",
    "population": 32468,
    "id": 1300036161
  },
  {
    "city": "Starkville",
    "city_ascii": "Starkville",
    "lat": 33.4608,
    "lng": -88.8297,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Mississippi",
    "capital": "",
    "population": 32546,
    "id": 1840015713
  },
  {
    "city": "Horizon West",
    "city_ascii": "Horizon West",
    "lat": 28.4417,
    "lng": -81.6145,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 32530,
    "id": 1840038926
  },
  {
    "city": "Slantsy",
    "city_ascii": "Slantsy",
    "lat": 59.1167,
    "lng": 28.0833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Leningradskaya Oblast’",
    "capital": "",
    "population": 32508,
    "id": 1643315242
  },
  {
    "city": "Kuroishi",
    "city_ascii": "Kuroishi",
    "lat": 40.6428,
    "lng": 140.5944,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Aomori",
    "capital": "",
    "population": 32376,
    "id": 1392880554
  },
  {
    "city": "Louvain-la-Neuve",
    "city_ascii": "Louvain-la-Neuve",
    "lat": 50.6696,
    "lng": 4.6112,
    "country": "Belgium",
    "iso2": "BE",
    "iso3": "BEL",
    "admin_name": "Wallonia",
    "capital": "",
    "population": 31551,
    "id": 1056082103
  },
  {
    "city": "Tawaramoto",
    "city_ascii": "Tawaramoto",
    "lat": 34.5567,
    "lng": 135.795,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Nara",
    "capital": "",
    "population": 31028,
    "id": 1392169878
  },
  {
    "city": "Osvaldo Cruz",
    "city_ascii": "Osvaldo Cruz",
    "lat": -21.7967,
    "lng": -50.8786,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 32475,
    "id": 1076438430
  },
  {
    "city": "Yawatahama-shi",
    "city_ascii": "Yawatahama-shi",
    "lat": 33.4631,
    "lng": 132.4233,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Ehime",
    "capital": "minor",
    "population": 32238,
    "id": 1392674556
  },
  {
    "city": "La Calera",
    "city_ascii": "La Calera",
    "lat": -31.3439,
    "lng": -64.3353,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Córdoba",
    "capital": "",
    "population": 32227,
    "id": 1032927400
  },
  {
    "city": "Hoyerswerda",
    "city_ascii": "Hoyerswerda",
    "lat": 51.4331,
    "lng": 14.25,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 32123,
    "id": 1276992982
  },
  {
    "city": "Warminster",
    "city_ascii": "Warminster",
    "lat": 40.2043,
    "lng": -75.0915,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 32454,
    "id": 1840035370
  },
  {
    "city": "Staryy Beyneu",
    "city_ascii": "Staryy Beyneu",
    "lat": 45.1834,
    "lng": 55.1,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Mangghystaū",
    "capital": "",
    "population": 32452,
    "id": 1398655445
  },
  {
    "city": "Châtellerault",
    "city_ascii": "Chatellerault",
    "lat": 46.8178,
    "lng": 0.5461,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Nouvelle-Aquitaine",
    "capital": "minor",
    "population": 31840,
    "id": 1250127354
  },
  {
    "city": "Maputsoe",
    "city_ascii": "Maputsoe",
    "lat": -28.8833,
    "lng": 27.9,
    "country": "Lesotho",
    "iso2": "LS",
    "iso3": "LSO",
    "admin_name": "Leribe",
    "capital": "",
    "population": 32117,
    "id": 1426999579
  },
  {
    "city": "Landecy",
    "city_ascii": "Landecy",
    "lat": 46.1897,
    "lng": 6.1158,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Genève",
    "capital": "",
    "population": 31942,
    "id": 1756407034
  },
  {
    "city": "Harker Heights",
    "city_ascii": "Harker Heights",
    "lat": 31.0572,
    "lng": -97.6445,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 32421,
    "id": 1840020851
  },
  {
    "city": "Mohyliv-Podil’s’kyy",
    "city_ascii": "Mohyliv-Podil's'kyy",
    "lat": 48.45,
    "lng": 27.7833,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Vinnyts’ka Oblast’",
    "capital": "minor",
    "population": 30982,
    "id": 1804578556
  },
  {
    "city": "Cachoeira Paulista",
    "city_ascii": "Cachoeira Paulista",
    "lat": -22.665,
    "lng": -45.0094,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 32294,
    "id": 1076563996
  },
  {
    "city": "Georgsmarienhütte",
    "city_ascii": "Georgsmarienhutte",
    "lat": 52.2031,
    "lng": 8.0447,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 31827,
    "id": 1276001308
  },
  {
    "city": "Castro-Urdiales",
    "city_ascii": "Castro-Urdiales",
    "lat": 43.3844,
    "lng": -3.215,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Cantabria",
    "capital": "",
    "population": 32069,
    "id": 1724390704
  },
  {
    "city": "Elk Grove Village",
    "city_ascii": "Elk Grove Village",
    "lat": 42.0063,
    "lng": -87.9921,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 32400,
    "id": 1840011271
  },
  {
    "city": "Fürstenwalde",
    "city_ascii": "Furstenwalde",
    "lat": 52.3667,
    "lng": 14.0667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Brandenburg",
    "capital": "",
    "population": 31941,
    "id": 1276767308
  },
  {
    "city": "Komagane",
    "city_ascii": "Komagane",
    "lat": 35.7289,
    "lng": 137.9339,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Nagano",
    "capital": "",
    "population": 32197,
    "id": 1392438911
  },
  {
    "city": "Kaysville",
    "city_ascii": "Kaysville",
    "lat": 41.029,
    "lng": -111.9456,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Utah",
    "capital": "",
    "population": 32390,
    "id": 1840018738
  },
  {
    "city": "Guararapes",
    "city_ascii": "Guararapes",
    "lat": -21.2608,
    "lng": -50.6428,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 32346,
    "id": 1076646816
  },
  {
    "city": "Achim",
    "city_ascii": "Achim",
    "lat": 53.013,
    "lng": 9.033,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 31911,
    "id": 1276242263
  },
  {
    "city": "Southlake",
    "city_ascii": "Southlake",
    "lat": 32.9545,
    "lng": -97.1503,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 32376,
    "id": 1840022048
  },
  {
    "city": "Kristianstad",
    "city_ascii": "Kristianstad",
    "lat": 56.0337,
    "lng": 14.1333,
    "country": "Sweden",
    "iso2": "SE",
    "iso3": "SWE",
    "admin_name": "Skåne",
    "capital": "minor",
    "population": 32188,
    "id": 1752606470
  },
  {
    "city": "Maebara",
    "city_ascii": "Maebara",
    "lat": 35.1142,
    "lng": 140.0989,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Chiba",
    "capital": "",
    "population": 32196,
    "id": 1392895244
  },
  {
    "city": "Savage",
    "city_ascii": "Savage",
    "lat": 44.7544,
    "lng": -93.3631,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 32362,
    "id": 1840008957
  },
  {
    "city": "Redan",
    "city_ascii": "Redan",
    "lat": 33.7394,
    "lng": -84.1644,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 32357,
    "id": 1840013706
  },
  {
    "city": "Des Moines",
    "city_ascii": "Des Moines",
    "lat": 47.3915,
    "lng": -122.3157,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 32348,
    "id": 1840018426
  },
  {
    "city": "Ferreñafe",
    "city_ascii": "Ferrenafe",
    "lat": -6.6391,
    "lng": -79.788,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "Lambayeque",
    "capital": "",
    "population": 31777,
    "id": 1604452796
  },
  {
    "city": "Bethel Park",
    "city_ascii": "Bethel Park",
    "lat": 40.3239,
    "lng": -80.0364,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 32345,
    "id": 1840001206
  },
  {
    "city": "Ra’s Ghārib",
    "city_ascii": "Ra's Gharib",
    "lat": 28.3597,
    "lng": 33.0775,
    "country": "Egypt",
    "iso2": "EG",
    "iso3": "EGY",
    "admin_name": "Al Baḩr al Aḩmar",
    "capital": "",
    "population": 32318,
    "id": 1818526915
  },
  {
    "city": "East Lake",
    "city_ascii": "East Lake",
    "lat": 28.1205,
    "lng": -82.6868,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 32315,
    "id": 1840028980
  },
  {
    "city": "Kahului",
    "city_ascii": "Kahului",
    "lat": 20.8715,
    "lng": -156.4603,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Hawaii",
    "capital": "",
    "population": 32312,
    "id": 1840023232
  },
  {
    "city": "Cheb",
    "city_ascii": "Cheb",
    "lat": 50.0798,
    "lng": 12.374,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Karlovarský Kraj",
    "capital": "",
    "population": 31977,
    "id": 1203676556
  },
  {
    "city": "Dreux",
    "city_ascii": "Dreux",
    "lat": 48.7372,
    "lng": 1.3664,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Centre-Val de Loire",
    "capital": "minor",
    "population": 31044,
    "id": 1250111227
  },
  {
    "city": "Warwick",
    "city_ascii": "Warwick",
    "lat": 52.28,
    "lng": -1.59,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Warwickshire",
    "capital": "",
    "population": 31345,
    "id": 1826383937
  },
  {
    "city": "Mangūr",
    "city_ascii": "Mangur",
    "lat": 17.9373,
    "lng": 80.8185,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Telangana",
    "capital": "",
    "population": 32091,
    "id": 1356904043
  },
  {
    "city": "Ribeira Grande",
    "city_ascii": "Ribeira Grande",
    "lat": 37.8167,
    "lng": -25.5167,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Azores",
    "capital": "",
    "population": 32112,
    "id": 1620024564
  },
  {
    "city": "Ilhabela",
    "city_ascii": "Ilhabela",
    "lat": -23.7778,
    "lng": -45.3578,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 32197,
    "id": 1076323267
  },
  {
    "city": "Koupéla",
    "city_ascii": "Koupela",
    "lat": 12.177,
    "lng": -0.356,
    "country": "Burkina Faso",
    "iso2": "BF",
    "iso3": "BFA",
    "admin_name": "Centre-Est",
    "capital": "minor",
    "population": 32052,
    "id": 1854841246
  },
  {
    "city": "Capulhuac",
    "city_ascii": "Capulhuac",
    "lat": 19.2,
    "lng": -99.4667,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "México",
    "capital": "minor",
    "population": 30838,
    "id": 1484829703
  },
  {
    "city": "Dania Beach",
    "city_ascii": "Dania Beach",
    "lat": 26.0594,
    "lng": -80.1637,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 32271,
    "id": 1840015135
  },
  {
    "city": "Ayabe",
    "city_ascii": "Ayabe",
    "lat": 35.2989,
    "lng": 135.2586,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Kyōto",
    "capital": "",
    "population": 32164,
    "id": 1392801118
  },
  {
    "city": "Oer-Erkenschwick",
    "city_ascii": "Oer-Erkenschwick",
    "lat": 51.6422,
    "lng": 7.2508,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 31442,
    "id": 1276269090
  },
  {
    "city": "Katagami",
    "city_ascii": "Katagami",
    "lat": 39.8833,
    "lng": 139.9889,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Akita",
    "capital": "",
    "population": 31923,
    "id": 1392973238
  },
  {
    "city": "Bowling Green",
    "city_ascii": "Bowling Green",
    "lat": 41.3773,
    "lng": -83.65,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 32249,
    "id": 1840000621
  },
  {
    "city": "Primorsko-Akhtarsk",
    "city_ascii": "Primorsko-Akhtarsk",
    "lat": 46.05,
    "lng": 38.1833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Krasnodarskiy Kray",
    "capital": "minor",
    "population": 32180,
    "id": 1643397467
  },
  {
    "city": "Pedana",
    "city_ascii": "Pedana",
    "lat": 16.2667,
    "lng": 81.1667,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Andhra Pradesh",
    "capital": "",
    "population": 30721,
    "id": 1356417725
  },
  {
    "city": "Mirnyy",
    "city_ascii": "Mirnyy",
    "lat": 62.7603,
    "lng": 40.3353,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Arkhangel’skaya Oblast’",
    "capital": "",
    "population": 32175,
    "id": 1643687005
  },
  {
    "city": "Novovoronezh",
    "city_ascii": "Novovoronezh",
    "lat": 51.3167,
    "lng": 39.2167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Voronezhskaya Oblast’",
    "capital": "",
    "population": 31503,
    "id": 1643532936
  },
  {
    "city": "Whitstable",
    "city_ascii": "Whitstable",
    "lat": 51.361,
    "lng": 1.026,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Kent",
    "capital": "",
    "population": 32100,
    "id": 1826750836
  },
  {
    "city": "Uglich",
    "city_ascii": "Uglich",
    "lat": 57.5333,
    "lng": 38.3333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Yaroslavskaya Oblast’",
    "capital": "",
    "population": 32146,
    "id": 1643786573
  },
  {
    "city": "Coos Bay",
    "city_ascii": "Coos Bay",
    "lat": 43.3789,
    "lng": -124.233,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 32159,
    "id": 1840018653
  },
  {
    "city": "Smithfield",
    "city_ascii": "Smithfield",
    "lat": 35.5131,
    "lng": -78.3497,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 32156,
    "id": 1840017795
  },
  {
    "city": "Delbrück",
    "city_ascii": "Delbruck",
    "lat": 51.7667,
    "lng": 8.5667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 31949,
    "id": 1276424035
  },
  {
    "city": "Sechura",
    "city_ascii": "Sechura",
    "lat": -5.5576,
    "lng": -80.8223,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "Piura",
    "capital": "",
    "population": 32144,
    "id": 1604728914
  },
  {
    "city": "Spanaway",
    "city_ascii": "Spanaway",
    "lat": 47.0979,
    "lng": -122.4233,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 32150,
    "id": 1840018451
  },
  {
    "city": "Yaguarón",
    "city_ascii": "Yaguaron",
    "lat": -25.5621,
    "lng": -57.284,
    "country": "Paraguay",
    "iso2": "PY",
    "iso3": "PRY",
    "admin_name": "Paraguarí",
    "capital": "",
    "population": 32000,
    "id": 1600386044
  },
  {
    "city": "Clarence",
    "city_ascii": "Clarence",
    "lat": 43.0196,
    "lng": -78.6375,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 32137,
    "id": 1840057985
  },
  {
    "city": "Yuzhne",
    "city_ascii": "Yuzhne",
    "lat": 46.6222,
    "lng": 31.1008,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Odes’ka Oblast’",
    "capital": "",
    "population": 32100,
    "id": 1804000461
  },
  {
    "city": "Clearfield",
    "city_ascii": "Clearfield",
    "lat": 41.103,
    "lng": -112.0237,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Utah",
    "capital": "",
    "population": 32118,
    "id": 1840018735
  },
  {
    "city": "Serdobsk",
    "city_ascii": "Serdobsk",
    "lat": 52.4667,
    "lng": 44.2167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Penzenskaya Oblast’",
    "capital": "",
    "population": 32087,
    "id": 1643300629
  },
  {
    "city": "Zapala",
    "city_ascii": "Zapala",
    "lat": -38.9028,
    "lng": -70.065,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Neuquén",
    "capital": "minor",
    "population": 32097,
    "id": 1032365659
  },
  {
    "city": "Shima",
    "city_ascii": "Shima",
    "lat": 39.5547,
    "lng": 141.1678,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Iwate",
    "capital": "",
    "population": 31969,
    "id": 1392952545
  },
  {
    "city": "Liberty",
    "city_ascii": "Liberty",
    "lat": 39.2394,
    "lng": -94.4191,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 32100,
    "id": 1840008502
  },
  {
    "city": "Marrero",
    "city_ascii": "Marrero",
    "lat": 29.8871,
    "lng": -90.1127,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 32088,
    "id": 1840013993
  },
  {
    "city": "Atotonilco de Tula",
    "city_ascii": "Atotonilco de Tula",
    "lat": 20.05,
    "lng": -99.1833,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Hidalgo",
    "capital": "",
    "population": 31078,
    "id": 1484176739
  },
  {
    "city": "El Golea",
    "city_ascii": "El Golea",
    "lat": 30.5666,
    "lng": 2.8833,
    "country": "Algeria",
    "iso2": "DZ",
    "iso3": "DZA",
    "admin_name": "Ghardaïa",
    "capital": "",
    "population": 32049,
    "id": 1012834851
  },
  {
    "city": "Pekin",
    "city_ascii": "Pekin",
    "lat": 40.5679,
    "lng": -89.6262,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 32045,
    "id": 1840009402
  },
  {
    "city": "Westlake",
    "city_ascii": "Westlake",
    "lat": 41.4524,
    "lng": -81.9295,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 32032,
    "id": 1840003420
  },
  {
    "city": "Centereach",
    "city_ascii": "Centereach",
    "lat": 40.8696,
    "lng": -73.0808,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 32028,
    "id": 1840005026
  },
  {
    "city": "Herrenberg",
    "city_ascii": "Herrenberg",
    "lat": 48.5967,
    "lng": 8.8708,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 31545,
    "id": 1276549799
  },
  {
    "city": "Manlius",
    "city_ascii": "Manlius",
    "lat": 43.049,
    "lng": -75.9793,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 32002,
    "id": 1840004323
  },
  {
    "city": "Chillicothe",
    "city_ascii": "Chillicothe",
    "lat": 39.3393,
    "lng": -82.9937,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 31999,
    "id": 1840007369
  },
  {
    "city": "Pativilca",
    "city_ascii": "Pativilca",
    "lat": -10.6996,
    "lng": -77.8,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "Lima",
    "capital": "",
    "population": 31905,
    "id": 1604804321
  },
  {
    "city": "La Verne",
    "city_ascii": "La Verne",
    "lat": 34.1208,
    "lng": -117.7702,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 31974,
    "id": 1840020514
  },
  {
    "city": "Lower Macungie",
    "city_ascii": "Lower Macungie",
    "lat": 40.5303,
    "lng": -75.57,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 31972,
    "id": 1840149645
  },
  {
    "city": "Jaral del Progreso",
    "city_ascii": "Jaral del Progreso",
    "lat": 20.3667,
    "lng": -101.0667,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Guanajuato",
    "capital": "minor",
    "population": 31780,
    "id": 1484182390
  },
  {
    "city": "Santa Rita",
    "city_ascii": "Santa Rita",
    "lat": 10.5367,
    "lng": -71.5108,
    "country": "Venezuela",
    "iso2": "VE",
    "iso3": "VEN",
    "admin_name": "Zulia",
    "capital": "minor",
    "population": 31810,
    "id": 1862676200
  },
  {
    "city": "Placerville",
    "city_ascii": "Placerville",
    "lat": 38.7308,
    "lng": -120.7978,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 31943,
    "id": 1840020238
  },
  {
    "city": "Nanyuki",
    "city_ascii": "Nanyuki",
    "lat": 0.0167,
    "lng": 37.0667,
    "country": "Kenya",
    "iso2": "KE",
    "iso3": "KEN",
    "admin_name": "Laikipia",
    "capital": "",
    "population": 31577,
    "id": 1404481993
  },
  {
    "city": "Sakhnīn",
    "city_ascii": "Sakhnin",
    "lat": 32.8667,
    "lng": 35.3,
    "country": "Israel",
    "iso2": "IL",
    "iso3": "ISR",
    "admin_name": "Northern",
    "capital": "",
    "population": 31100,
    "id": 1376646130
  },
  {
    "city": "Turicato",
    "city_ascii": "Turicato",
    "lat": 19.05,
    "lng": -101.4167,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Michoacán de Ocampo",
    "capital": "minor",
    "population": 31877,
    "id": 1484580353
  },
  {
    "city": "Grajaú",
    "city_ascii": "Grajau",
    "lat": -5.8194,
    "lng": -46.1386,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Maranhão",
    "capital": "",
    "population": 30217,
    "id": 1076344134
  },
  {
    "city": "El Oro de Hidalgo",
    "city_ascii": "El Oro de Hidalgo",
    "lat": 19.8008,
    "lng": -100.1314,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Morelos",
    "capital": "minor",
    "population": 31847,
    "id": 1484677445
  },
  {
    "city": "I‘zāz",
    "city_ascii": "I`zaz",
    "lat": 36.5888,
    "lng": 37.0441,
    "country": "Syria",
    "iso2": "SY",
    "iso3": "SYR",
    "admin_name": "Ḩalab",
    "capital": "minor",
    "population": 31534,
    "id": 1760669569
  },
  {
    "city": "Touba",
    "city_ascii": "Touba",
    "lat": 8.2833,
    "lng": -7.6833,
    "country": "Côte D’Ivoire",
    "iso2": "CI",
    "iso3": "CIV",
    "admin_name": "Woroba",
    "capital": "minor",
    "population": 31844,
    "id": 1384485647
  },
  {
    "city": "Dandenong",
    "city_ascii": "Dandenong",
    "lat": -37.981,
    "lng": 145.215,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Victoria",
    "capital": "",
    "population": 29906,
    "id": 1036214023
  },
  {
    "city": "Martinsville",
    "city_ascii": "Martinsville",
    "lat": 36.6827,
    "lng": -79.8636,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 31871,
    "id": 1840001727
  },
  {
    "city": "Gevelsberg",
    "city_ascii": "Gevelsberg",
    "lat": 51.3265,
    "lng": 7.3559,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 30695,
    "id": 1276634761
  },
  {
    "city": "Rome",
    "city_ascii": "Rome",
    "lat": 43.226,
    "lng": -75.4909,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 31863,
    "id": 1840000349
  },
  {
    "city": "Val-d’Or",
    "city_ascii": "Val-d'Or",
    "lat": 48.1,
    "lng": -77.7833,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 31862,
    "id": 1124239138
  },
  {
    "city": "Pueblo West",
    "city_ascii": "Pueblo West",
    "lat": 38.3551,
    "lng": -104.7266,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 31860,
    "id": 1840018860
  },
  {
    "city": "Central Islip",
    "city_ascii": "Central Islip",
    "lat": 40.7837,
    "lng": -73.1945,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 31846,
    "id": 1840005028
  },
  {
    "city": "Buzovna",
    "city_ascii": "Buzovna",
    "lat": 40.5167,
    "lng": 50.1167,
    "country": "Azerbaijan",
    "iso2": "AZ",
    "iso3": "AZE",
    "admin_name": "Bakı",
    "capital": "",
    "population": 31500,
    "id": 1031797928
  },
  {
    "city": "Owen Sound",
    "city_ascii": "Owen Sound",
    "lat": 44.5667,
    "lng": -80.9333,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 31820,
    "id": 1124623613
  },
  {
    "city": "Velikiy Ustyug",
    "city_ascii": "Velikiy Ustyug",
    "lat": 60.7589,
    "lng": 46.3039,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Vologodskaya Oblast’",
    "capital": "",
    "population": 31806,
    "id": 1643173653
  },
  {
    "city": "Yaita",
    "city_ascii": "Yaita",
    "lat": 36.8067,
    "lng": 139.9242,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Tochigi",
    "capital": "",
    "population": 31617,
    "id": 1392031620
  },
  {
    "city": "Chum Phae",
    "city_ascii": "Chum Phae",
    "lat": 16.5431,
    "lng": 102.1104,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Khon Kaen",
    "capital": "minor",
    "population": 31597,
    "id": 1764511790
  },
  {
    "city": "Temascaltepec de González",
    "city_ascii": "Temascaltepec de Gonzalez",
    "lat": 19.0433,
    "lng": -100.0414,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "México",
    "capital": "minor",
    "population": 30336,
    "id": 1484114407
  },
  {
    "city": "Kyzyl-Kyya",
    "city_ascii": "Kyzyl-Kyya",
    "lat": 40.25,
    "lng": 72.1167,
    "country": "Kyrgyzstan",
    "iso2": "KG",
    "iso3": "KGZ",
    "admin_name": "Batken",
    "capital": "",
    "population": 31727,
    "id": 1417902733
  },
  {
    "city": "Kasimov",
    "city_ascii": "Kasimov",
    "lat": 54.9583,
    "lng": 41.3972,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Ryazanskaya Oblast’",
    "capital": "",
    "population": 30243,
    "id": 1643283044
  },
  {
    "city": "Radnor",
    "city_ascii": "Radnor",
    "lat": 40.0287,
    "lng": -75.3675,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 31743,
    "id": 1840152846
  },
  {
    "city": "Haan",
    "city_ascii": "Haan",
    "lat": 51.1931,
    "lng": 7.0131,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 30484,
    "id": 1276168837
  },
  {
    "city": "Omaezaki",
    "city_ascii": "Omaezaki",
    "lat": 34.6381,
    "lng": 138.1281,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Shizuoka",
    "capital": "",
    "population": 31262,
    "id": 1392319177
  },
  {
    "city": "Kulat",
    "city_ascii": "Kulat",
    "lat": -8.7151,
    "lng": 115.1841,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Bali",
    "capital": "",
    "population": 30012,
    "id": 1360003468
  },
  {
    "city": "Radolfzell am Bodensee",
    "city_ascii": "Radolfzell am Bodensee",
    "lat": 47.7369,
    "lng": 8.9697,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 31203,
    "id": 1276934854
  },
  {
    "city": "Kotovsk",
    "city_ascii": "Kotovsk",
    "lat": 52.5833,
    "lng": 41.5,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Tambovskaya Oblast’",
    "capital": "",
    "population": 29999,
    "id": 1643608746
  },
  {
    "city": "Weil am Rhein",
    "city_ascii": "Weil am Rhein",
    "lat": 47.5947,
    "lng": 7.6108,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 30175,
    "id": 1276439955
  },
  {
    "city": "Prairieville",
    "city_ascii": "Prairieville",
    "lat": 30.3151,
    "lng": -90.9571,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 31721,
    "id": 1840026029
  },
  {
    "city": "Balyqshy",
    "city_ascii": "Balyqshy",
    "lat": 47.0666,
    "lng": 51.8666,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Atyraū",
    "capital": "",
    "population": 31624,
    "id": 1398354265
  },
  {
    "city": "Jesús María",
    "city_ascii": "Jesus Maria",
    "lat": -30.9817,
    "lng": -64.0942,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Córdoba",
    "capital": "minor",
    "population": 31602,
    "id": 1032875104
  },
  {
    "city": "Žepče",
    "city_ascii": "Zepce",
    "lat": 44.4333,
    "lng": 18.0333,
    "country": "Bosnia And Herzegovina",
    "iso2": "BA",
    "iso3": "BIH",
    "admin_name": "Bosnia and Herzegovina, Federation of",
    "capital": "minor",
    "population": 31582,
    "id": 1070466847
  },
  {
    "city": "Bedworth",
    "city_ascii": "Bedworth",
    "lat": 52.475,
    "lng": -1.477,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Warwickshire",
    "capital": "",
    "population": 30648,
    "id": 1826435844
  },
  {
    "city": "Sasaguri",
    "city_ascii": "Sasaguri",
    "lat": 33.6156,
    "lng": 130.5511,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Fukuoka",
    "capital": "",
    "population": 30886,
    "id": 1392528017
  },
  {
    "city": "Tecozautla",
    "city_ascii": "Tecozautla",
    "lat": 20.5333,
    "lng": -99.6333,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Hidalgo",
    "capital": "",
    "population": 31609,
    "id": 1484766704
  },
  {
    "city": "Jacareacanga",
    "city_ascii": "Jacareacanga",
    "lat": -6.2222,
    "lng": -57.7528,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Pará",
    "capital": "",
    "population": 31661,
    "id": 1076980493
  },
  {
    "city": "Villarrica",
    "city_ascii": "Villarrica",
    "lat": -39.28,
    "lng": -72.23,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Araucanía",
    "capital": "",
    "population": 31602,
    "id": 1152280288
  },
  {
    "city": "Morro Agudo",
    "city_ascii": "Morro Agudo",
    "lat": -20.7314,
    "lng": -48.0578,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 31620,
    "id": 1076136085
  },
  {
    "city": "Kreuztal",
    "city_ascii": "Kreuztal",
    "lat": 50.962,
    "lng": 8.0064,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 31187,
    "id": 1276001601
  },
  {
    "city": "Kanzakimachi-kanzaki",
    "city_ascii": "Kanzakimachi-kanzaki",
    "lat": 33.3108,
    "lng": 130.3731,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Saga",
    "capital": "",
    "population": 31365,
    "id": 1392684338
  },
  {
    "city": "Mae Sot",
    "city_ascii": "Mae Sot",
    "lat": 16.7101,
    "lng": 98.5707,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Tak",
    "capital": "minor",
    "population": 31530,
    "id": 1764198529
  },
  {
    "city": "Spring Valley",
    "city_ascii": "Spring Valley",
    "lat": 32.7316,
    "lng": -116.9766,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 31591,
    "id": 1840019343
  },
  {
    "city": "Oudenaarde",
    "city_ascii": "Oudenaarde",
    "lat": 50.85,
    "lng": 3.6,
    "country": "Belgium",
    "iso2": "BE",
    "iso3": "BEL",
    "admin_name": "Flanders",
    "capital": "minor",
    "population": 31132,
    "id": 1056780523
  },
  {
    "city": "Bel Air North",
    "city_ascii": "Bel Air North",
    "lat": 39.5544,
    "lng": -76.3733,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 31582,
    "id": 1840073591
  },
  {
    "city": "Maubeuge",
    "city_ascii": "Maubeuge",
    "lat": 50.2775,
    "lng": 3.9734,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Hauts-de-France",
    "capital": "",
    "population": 29944,
    "id": 1250099699
  },
  {
    "city": "Hopkinsville",
    "city_ascii": "Hopkinsville",
    "lat": 36.8386,
    "lng": -87.4776,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kentucky",
    "capital": "",
    "population": 31569,
    "id": 1840014399
  },
  {
    "city": "Zima",
    "city_ascii": "Zima",
    "lat": 53.9167,
    "lng": 102.05,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Irkutskaya Oblast’",
    "capital": "",
    "population": 30988,
    "id": 1643510813
  },
  {
    "city": "Kosonsoy",
    "city_ascii": "Kosonsoy",
    "lat": 41.2492,
    "lng": 71.5458,
    "country": "Uzbekistan",
    "iso2": "UZ",
    "iso3": "UZB",
    "admin_name": "Namangan",
    "capital": "minor",
    "population": 31217,
    "id": 1860234389
  },
  {
    "city": "Willingboro",
    "city_ascii": "Willingboro",
    "lat": 40.028,
    "lng": -74.8882,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 31545,
    "id": 1840081630
  },
  {
    "city": "Kaminokawa",
    "city_ascii": "Kaminokawa",
    "lat": 36.4392,
    "lng": 139.91,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Tochigi",
    "capital": "",
    "population": 30964,
    "id": 1392906156
  },
  {
    "city": "Rochester",
    "city_ascii": "Rochester",
    "lat": 43.299,
    "lng": -70.9787,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Hampshire",
    "capital": "",
    "population": 31526,
    "id": 1840002809
  },
  {
    "city": "Marks",
    "city_ascii": "Marks",
    "lat": 51.7,
    "lng": 46.75,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Saratovskaya Oblast’",
    "capital": "",
    "population": 31488,
    "id": 1643304163
  },
  {
    "city": "Rheinberg",
    "city_ascii": "Rheinberg",
    "lat": 51.5467,
    "lng": 6.6006,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 31097,
    "id": 1276286236
  },
  {
    "city": "Kostopil’",
    "city_ascii": "Kostopil'",
    "lat": 50.8833,
    "lng": 26.4431,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Rivnens’ka Oblast’",
    "capital": "minor",
    "population": 31463,
    "id": 1804484929
  },
  {
    "city": "Santa Fé do Sul",
    "city_ascii": "Santa Fe do Sul",
    "lat": -20.2108,
    "lng": -50.9258,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 31348,
    "id": 1076177037
  },
  {
    "city": "Deinze",
    "city_ascii": "Deinze",
    "lat": 50.9833,
    "lng": 3.5272,
    "country": "Belgium",
    "iso2": "BE",
    "iso3": "BEL",
    "admin_name": "Flanders",
    "capital": "",
    "population": 31085,
    "id": 1056715271
  },
  {
    "city": "Savé",
    "city_ascii": "Save",
    "lat": 8.0342,
    "lng": 2.4866,
    "country": "Benin",
    "iso2": "BJ",
    "iso3": "BEN",
    "admin_name": "Collines",
    "capital": "",
    "population": 31444,
    "id": 1204120625
  },
  {
    "city": "Saint-Médard-en-Jalles",
    "city_ascii": "Saint-Medard-en-Jalles",
    "lat": 44.8964,
    "lng": -0.7164,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Nouvelle-Aquitaine",
    "capital": "",
    "population": 31145,
    "id": 1250115670
  },
  {
    "city": "Geesthacht",
    "city_ascii": "Geesthacht",
    "lat": 53.4381,
    "lng": 10.3747,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Schleswig-Holstein",
    "capital": "",
    "population": 30551,
    "id": 1276117326
  },
  {
    "city": "Milledgeville",
    "city_ascii": "Milledgeville",
    "lat": 33.0874,
    "lng": -83.2414,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 31471,
    "id": 1840014857
  },
  {
    "city": "Stratford",
    "city_ascii": "Stratford",
    "lat": 43.3708,
    "lng": -80.9819,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 31465,
    "id": 1124676255
  },
  {
    "city": "Yingyangcun",
    "city_ascii": "Yingyangcun",
    "lat": 22.0974,
    "lng": 106.7567,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Guangxi",
    "capital": "",
    "population": 31109,
    "id": 1156849392
  },
  {
    "city": "Syracuse",
    "city_ascii": "Syracuse",
    "lat": 41.086,
    "lng": -112.0698,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Utah",
    "capital": "",
    "population": 31458,
    "id": 1840021352
  },
  {
    "city": "Netivot",
    "city_ascii": "Netivot",
    "lat": 31.4167,
    "lng": 34.5833,
    "country": "Israel",
    "iso2": "IL",
    "iso3": "ISR",
    "admin_name": "Southern",
    "capital": "",
    "population": 31314,
    "id": 1376200656
  },
  {
    "city": "Eldersburg",
    "city_ascii": "Eldersburg",
    "lat": 39.4041,
    "lng": -76.9529,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 31454,
    "id": 1840005698
  },
  {
    "city": "Araçoiaba da Serra",
    "city_ascii": "Aracoiaba da Serra",
    "lat": -23.5053,
    "lng": -47.6142,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 31321,
    "id": 1076732508
  },
  {
    "city": "Barrinha",
    "city_ascii": "Barrinha",
    "lat": -21.1936,
    "lng": -48.1639,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 31230,
    "id": 1076492454
  },
  {
    "city": "Trebinje",
    "city_ascii": "Trebinje",
    "lat": 42.7089,
    "lng": 18.3217,
    "country": "Bosnia And Herzegovina",
    "iso2": "BA",
    "iso3": "BIH",
    "admin_name": "Srpska, Republika",
    "capital": "minor",
    "population": 31433,
    "id": 1070826374
  },
  {
    "city": "Tiquipaya",
    "city_ascii": "Tiquipaya",
    "lat": -17.3381,
    "lng": -66.2189,
    "country": "Bolivia",
    "iso2": "BO",
    "iso3": "BOL",
    "admin_name": "Cochabamba",
    "capital": "",
    "population": 31264,
    "id": 1068863881
  },
  {
    "city": "Sherwood",
    "city_ascii": "Sherwood",
    "lat": 34.8508,
    "lng": -92.2028,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arkansas",
    "capital": "",
    "population": 31436,
    "id": 1840015511
  },
  {
    "city": "Quesada",
    "city_ascii": "Quesada",
    "lat": 10.3305,
    "lng": -84.44,
    "country": "Costa Rica",
    "iso2": "CR",
    "iso3": "CRI",
    "admin_name": "Alajuela",
    "capital": "",
    "population": 31106,
    "id": 1188199888
  },
  {
    "city": "Lloydminster",
    "city_ascii": "Lloydminster",
    "lat": 53.2783,
    "lng": -110.005,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Saskatchewan",
    "capital": "",
    "population": 31410,
    "id": 1124051728
  },
  {
    "city": "Garner",
    "city_ascii": "Garner",
    "lat": 35.6934,
    "lng": -78.6196,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 31407,
    "id": 1840016198
  },
  {
    "city": "Key West",
    "city_ascii": "Key West",
    "lat": 24.5637,
    "lng": -81.7768,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 31401,
    "id": 1840015161
  },
  {
    "city": "Cauquenes",
    "city_ascii": "Cauquenes",
    "lat": -35.9671,
    "lng": -72.3154,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Maule",
    "capital": "minor",
    "population": 31362,
    "id": 1152787999
  },
  {
    "city": "Pointe-Claire",
    "city_ascii": "Pointe-Claire",
    "lat": 45.45,
    "lng": -73.8167,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 31380,
    "id": 1124470650
  },
  {
    "city": "Kudymkar",
    "city_ascii": "Kudymkar",
    "lat": 59.0167,
    "lng": 54.6667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Permskiy Kray",
    "capital": "minor",
    "population": 31370,
    "id": 1643283582
  },
  {
    "city": "Skellefteå",
    "city_ascii": "Skelleftea",
    "lat": 64.7721,
    "lng": 20.95,
    "country": "Sweden",
    "iso2": "SE",
    "iso3": "SWE",
    "admin_name": "Västerbotten",
    "capital": "minor",
    "population": 31311,
    "id": 1752233025
  },
  {
    "city": "Yunoshima",
    "city_ascii": "Yunoshima",
    "lat": 35.8058,
    "lng": 137.2442,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Gifu",
    "capital": "",
    "population": 31316,
    "id": 1392006188
  },
  {
    "city": "Petersburg",
    "city_ascii": "Petersburg",
    "lat": 37.2043,
    "lng": -77.3913,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 31346,
    "id": 1840003861
  },
  {
    "city": "Al Ḩusaynīyah",
    "city_ascii": "Al Husayniyah",
    "lat": 30.8611,
    "lng": 31.9198,
    "country": "Egypt",
    "iso2": "EG",
    "iso3": "EGY",
    "admin_name": "Ash Sharqīyah",
    "capital": "",
    "population": 30825,
    "id": 1818070991
  },
  {
    "city": "North Olmsted",
    "city_ascii": "North Olmsted",
    "lat": 41.415,
    "lng": -81.919,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 31341,
    "id": 1840003400
  },
  {
    "city": "Kandalaksha",
    "city_ascii": "Kandalaksha",
    "lat": 67.1569,
    "lng": 32.4117,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Murmanskaya Oblast’",
    "capital": "",
    "population": 31329,
    "id": 1643555433
  },
  {
    "city": "Wheat Ridge",
    "city_ascii": "Wheat Ridge",
    "lat": 39.7728,
    "lng": -105.1029,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 31324,
    "id": 1840021431
  },
  {
    "city": "Pontes e Lacerda",
    "city_ascii": "Pontes e Lacerda",
    "lat": -15.2196,
    "lng": -59.35,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Mato Grosso",
    "capital": "",
    "population": 29381,
    "id": 1076490162
  },
  {
    "city": "Ōno",
    "city_ascii": "Ono",
    "lat": 35.9806,
    "lng": 136.4875,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Fukui",
    "capital": "",
    "population": 31264,
    "id": 1392027459
  },
  {
    "city": "Masaki",
    "city_ascii": "Masaki",
    "lat": 33.7875,
    "lng": 132.7114,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Ehime",
    "capital": "",
    "population": 29832,
    "id": 1392691084
  },
  {
    "city": "Cibolo",
    "city_ascii": "Cibolo",
    "lat": 29.5639,
    "lng": -98.2123,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 31281,
    "id": 1840019639
  },
  {
    "city": "Buurhakaba",
    "city_ascii": "Buurhakaba",
    "lat": 2.7837,
    "lng": 44.0833,
    "country": "Somalia",
    "iso2": "SO",
    "iso3": "SOM",
    "admin_name": "Bay",
    "capital": "minor",
    "population": 31267,
    "id": 1706176225
  },
  {
    "city": "Marovoay",
    "city_ascii": "Marovoay",
    "lat": -16.0995,
    "lng": 46.6333,
    "country": "Madagascar",
    "iso2": "MG",
    "iso3": "MDG",
    "admin_name": "Mahajanga",
    "capital": "",
    "population": 31253,
    "id": 1450283819
  },
  {
    "city": "Dracut",
    "city_ascii": "Dracut",
    "lat": 42.6832,
    "lng": -71.301,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 31266,
    "id": 1840053477
  },
  {
    "city": "Uran",
    "city_ascii": "Uran",
    "lat": 18.89,
    "lng": 72.95,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Mahārāshtra",
    "capital": "",
    "population": 30439,
    "id": 1356923031
  },
  {
    "city": "Biritiba-Mirim",
    "city_ascii": "Biritiba-Mirim",
    "lat": -23.5728,
    "lng": -46.0389,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 31158,
    "id": 1076510731
  },
  {
    "city": "Alliance",
    "city_ascii": "Alliance",
    "lat": 40.9107,
    "lng": -81.1189,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 31255,
    "id": 1840000962
  },
  {
    "city": "Łuków",
    "city_ascii": "Lukow",
    "lat": 51.9167,
    "lng": 22.3833,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Lubelskie",
    "capital": "minor",
    "population": 30381,
    "id": 1616162700
  },
  {
    "city": "Banning",
    "city_ascii": "Banning",
    "lat": 33.946,
    "lng": -116.8992,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 31221,
    "id": 1840019297
  },
  {
    "city": "Burgess Hill",
    "city_ascii": "Burgess Hill",
    "lat": 50.9535,
    "lng": -0.1262,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "West Sussex",
    "capital": "",
    "population": 30635,
    "id": 1826867983
  },
  {
    "city": "Tsuruno",
    "city_ascii": "Tsuruno",
    "lat": 40.8089,
    "lng": 140.38,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Aomori",
    "capital": "",
    "population": 31089,
    "id": 1392798246
  },
  {
    "city": "Laguna Hills",
    "city_ascii": "Laguna Hills",
    "lat": 33.592,
    "lng": -117.6992,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 31207,
    "id": 1840020588
  },
  {
    "city": "Lagos",
    "city_ascii": "Lagos",
    "lat": 37.1,
    "lng": -8.6667,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Faro",
    "capital": "minor",
    "population": 31049,
    "id": 1620635530
  },
  {
    "city": "Bogoroditsk",
    "city_ascii": "Bogoroditsk",
    "lat": 53.7667,
    "lng": 38.1333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Tul’skaya Oblast’",
    "capital": "",
    "population": 31139,
    "id": 1643215151
  },
  {
    "city": "Lushnjë",
    "city_ascii": "Lushnje",
    "lat": 40.9333,
    "lng": 19.7,
    "country": "Albania",
    "iso2": "AL",
    "iso3": "ALB",
    "admin_name": "Fier",
    "capital": "",
    "population": 31105,
    "id": 1008790888
  },
  {
    "city": "Princeton",
    "city_ascii": "Princeton",
    "lat": 40.3563,
    "lng": -74.6693,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 31187,
    "id": 1840001381
  },
  {
    "city": "Athens",
    "city_ascii": "Athens",
    "lat": 34.7847,
    "lng": -86.951,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 31187,
    "id": 1840000935
  },
  {
    "city": "Falmouth",
    "city_ascii": "Falmouth",
    "lat": 41.5915,
    "lng": -70.5914,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 31177,
    "id": 1840053501
  },
  {
    "city": "Werl",
    "city_ascii": "Werl",
    "lat": 51.5528,
    "lng": 7.9139,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 30772,
    "id": 1276291604
  },
  {
    "city": "Warwick",
    "city_ascii": "Warwick",
    "lat": 41.2598,
    "lng": -74.3615,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 31174,
    "id": 1840004881
  },
  {
    "city": "Loughton",
    "city_ascii": "Loughton",
    "lat": 51.6494,
    "lng": 0.0735,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Essex",
    "capital": "",
    "population": 31106,
    "id": 1826436728
  },
  {
    "city": "Orillia",
    "city_ascii": "Orillia",
    "lat": 44.6,
    "lng": -79.4167,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 31166,
    "id": 1124049830
  },
  {
    "city": "Tokmak",
    "city_ascii": "Tokmak",
    "lat": 47.2514,
    "lng": 35.7058,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Zaporiz’ka Oblast’",
    "capital": "minor",
    "population": 31016,
    "id": 1804494597
  },
  {
    "city": "Chitral",
    "city_ascii": "Chitral",
    "lat": 35.8511,
    "lng": 71.7889,
    "country": "Pakistan",
    "iso2": "PK",
    "iso3": "PAK",
    "admin_name": "Khyber Pakhtunkhwa",
    "capital": "minor",
    "population": 31100,
    "id": 1586861716
  },
  {
    "city": "Cândido Mota",
    "city_ascii": "Candido Mota",
    "lat": -22.7464,
    "lng": -50.3869,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 31131,
    "id": 1076335584
  },
  {
    "city": "Bramsche",
    "city_ascii": "Bramsche",
    "lat": 52.4089,
    "lng": 7.9728,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 30952,
    "id": 1276405075
  },
  {
    "city": "Springfield",
    "city_ascii": "Springfield",
    "lat": 38.7809,
    "lng": -77.1839,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 31117,
    "id": 1840006039
  },
  {
    "city": "Naugatuck",
    "city_ascii": "Naugatuck",
    "lat": 41.489,
    "lng": -73.0518,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 31108,
    "id": 1840004849
  },
  {
    "city": "Río Grande",
    "city_ascii": "Rio Grande",
    "lat": -53.7914,
    "lng": -67.699,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Tierra del Fuego, Antártida e Islas del Atlántico Sur",
    "capital": "minor",
    "population": 31095,
    "id": 1032824067
  },
  {
    "city": "Columbus",
    "city_ascii": "Columbus",
    "lat": 33.5088,
    "lng": -88.4096,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Mississippi",
    "capital": "",
    "population": 31084,
    "id": 1840014807
  },
  {
    "city": "Bochnia",
    "city_ascii": "Bochnia",
    "lat": 49.9833,
    "lng": 20.4333,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Małopolskie",
    "capital": "minor",
    "population": 30075,
    "id": 1616833825
  },
  {
    "city": "Schönebeck",
    "city_ascii": "Schonebeck",
    "lat": 52.0167,
    "lng": 11.75,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony-Anhalt",
    "capital": "",
    "population": 30720,
    "id": 1276217785
  },
  {
    "city": "South Riding",
    "city_ascii": "South Riding",
    "lat": 38.912,
    "lng": -77.5132,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 31071,
    "id": 1840024507
  },
  {
    "city": "Mundelein",
    "city_ascii": "Mundelein",
    "lat": 42.2691,
    "lng": -88.0101,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 31051,
    "id": 1840011175
  },
  {
    "city": "Lucapa",
    "city_ascii": "Lucapa",
    "lat": -8.4196,
    "lng": 20.74,
    "country": "Angola",
    "iso2": "AO",
    "iso3": "AGO",
    "admin_name": "Lunda Norte",
    "capital": "",
    "population": 31041,
    "id": 1024056286
  },
  {
    "city": "Bielawa",
    "city_ascii": "Bielawa",
    "lat": 50.6908,
    "lng": 16.623,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Dolnośląskie",
    "capital": "",
    "population": 30207,
    "id": 1616721687
  },
  {
    "city": "Cicero",
    "city_ascii": "Cicero",
    "lat": 43.1664,
    "lng": -76.0661,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 31021,
    "id": 1840057983
  },
  {
    "city": "Dyurtyuli",
    "city_ascii": "Dyurtyuli",
    "lat": 55.4833,
    "lng": 54.8667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Bashkortostan",
    "capital": "",
    "population": 30969,
    "id": 1643865529
  },
  {
    "city": "Harpenden",
    "city_ascii": "Harpenden",
    "lat": 51.8175,
    "lng": -0.3524,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hertfordshire",
    "capital": "",
    "population": 30240,
    "id": 1826679950
  },
  {
    "city": "Fair Oaks",
    "city_ascii": "Fair Oaks",
    "lat": 38.6504,
    "lng": -121.2497,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 31002,
    "id": 1840017580
  },
  {
    "city": "Tewksbury",
    "city_ascii": "Tewksbury",
    "lat": 42.612,
    "lng": -71.2278,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 31002,
    "id": 1840053490
  },
  {
    "city": "Mocímboa da Praia",
    "city_ascii": "Mocimboa da Praia",
    "lat": -11.35,
    "lng": 40.3333,
    "country": "Mozambique",
    "iso2": "MZ",
    "iso3": "MOZ",
    "admin_name": "Cabo Delgado",
    "capital": "",
    "population": 30950,
    "id": 1508896362
  },
  {
    "city": "Huamachuco",
    "city_ascii": "Huamachuco",
    "lat": -7.8121,
    "lng": -78.0487,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "La Libertad",
    "capital": "",
    "population": 30835,
    "id": 1604864093
  },
  {
    "city": "Nartkala",
    "city_ascii": "Nartkala",
    "lat": 43.55,
    "lng": 43.85,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kabardino-Balkariya",
    "capital": "",
    "population": 30832,
    "id": 1643632227
  },
  {
    "city": "Əmircan",
    "city_ascii": "Amircan",
    "lat": 40.4236,
    "lng": 49.9886,
    "country": "Azerbaijan",
    "iso2": "AZ",
    "iso3": "AZE",
    "admin_name": "Bakı",
    "capital": "",
    "population": 29800,
    "id": 1031561618
  },
  {
    "city": "Rexburg",
    "city_ascii": "Rexburg",
    "lat": 43.8224,
    "lng": -111.792,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Idaho",
    "capital": "",
    "population": 30979,
    "id": 1840020037
  },
  {
    "city": "Burgdorf",
    "city_ascii": "Burgdorf",
    "lat": 52.45,
    "lng": 10.0083,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 30699,
    "id": 1276623419
  },
  {
    "city": "Rostov",
    "city_ascii": "Rostov",
    "lat": 57.1833,
    "lng": 39.4167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Yaroslavskaya Oblast’",
    "capital": "",
    "population": 30943,
    "id": 1643848937
  },
  {
    "city": "Ambanja",
    "city_ascii": "Ambanja",
    "lat": -13.6786,
    "lng": 48.4522,
    "country": "Madagascar",
    "iso2": "MG",
    "iso3": "MDG",
    "admin_name": "Antsiranana",
    "capital": "",
    "population": 30621,
    "id": 1450987919
  },
  {
    "city": "Einbeck",
    "city_ascii": "Einbeck",
    "lat": 51.8167,
    "lng": 9.8667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 30826,
    "id": 1276253461
  },
  {
    "city": "Neuruppin",
    "city_ascii": "Neuruppin",
    "lat": 52.9222,
    "lng": 12.8,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Brandenburg",
    "capital": "minor",
    "population": 30846,
    "id": 1276820903
  },
  {
    "city": "Mossendjo",
    "city_ascii": "Mossendjo",
    "lat": -2.9458,
    "lng": 12.7147,
    "country": "Congo (Brazzaville)",
    "iso2": "CG",
    "iso3": "COG",
    "admin_name": "Niari",
    "capital": "",
    "population": 30936,
    "id": 1178679314
  },
  {
    "city": "Nogales",
    "city_ascii": "Nogales",
    "lat": 31.3624,
    "lng": -110.9336,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 30917,
    "id": 1840020823
  },
  {
    "city": "Nicholasville",
    "city_ascii": "Nicholasville",
    "lat": 37.8902,
    "lng": -84.5669,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kentucky",
    "capital": "",
    "population": 30912,
    "id": 1840014330
  },
  {
    "city": "Hirakawachō",
    "city_ascii": "Hirakawacho",
    "lat": 40.5842,
    "lng": 140.5664,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Aomori",
    "capital": "",
    "population": 30822,
    "id": 1392839223
  },
  {
    "city": "Goleta",
    "city_ascii": "Goleta",
    "lat": 34.436,
    "lng": -119.8596,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 30911,
    "id": 1840001783
  },
  {
    "city": "Opelika",
    "city_ascii": "Opelika",
    "lat": 32.6612,
    "lng": -85.3769,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 30908,
    "id": 1840005210
  },
  {
    "city": "Laylá",
    "city_ascii": "Layla",
    "lat": 22.2833,
    "lng": 46.7333,
    "country": "Saudi Arabia",
    "iso2": "SA",
    "iso3": "SAU",
    "admin_name": "Ar Riyāḑ",
    "capital": "",
    "population": 30906,
    "id": 1682438079
  },
  {
    "city": "Butte",
    "city_ascii": "Butte",
    "lat": 45.902,
    "lng": -112.6571,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Montana",
    "capital": "",
    "population": 30904,
    "id": 1840032850
  },
  {
    "city": "Alma",
    "city_ascii": "Alma",
    "lat": 48.55,
    "lng": -71.65,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 30904,
    "id": 1124138438
  },
  {
    "city": "Algonquin",
    "city_ascii": "Algonquin",
    "lat": 42.1629,
    "lng": -88.3159,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 30897,
    "id": 1840010110
  },
  {
    "city": "Písek",
    "city_ascii": "Pisek",
    "lat": 49.3089,
    "lng": 14.1475,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Jihočeský Kraj",
    "capital": "",
    "population": 30415,
    "id": 1203813820
  },
  {
    "city": "Motomiya",
    "city_ascii": "Motomiya",
    "lat": 37.5133,
    "lng": 140.3939,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Fukushima",
    "capital": "",
    "population": 30546,
    "id": 1392717520
  },
  {
    "city": "Lakeside",
    "city_ascii": "Lakeside",
    "lat": 30.1356,
    "lng": -81.7674,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 30890,
    "id": 1840029051
  },
  {
    "city": "Radcliffe",
    "city_ascii": "Radcliffe",
    "lat": 53.5615,
    "lng": -2.3268,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Bury",
    "capital": "",
    "population": 29950,
    "id": 1826622636
  },
  {
    "city": "Aiken",
    "city_ascii": "Aiken",
    "lat": 33.531,
    "lng": -81.7268,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 30869,
    "id": 1840013722
  },
  {
    "city": "Princeton",
    "city_ascii": "Princeton",
    "lat": 25.5396,
    "lng": -80.3971,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 30852,
    "id": 1840014248
  },
  {
    "city": "Unterschleißheim",
    "city_ascii": "Unterschleissheim",
    "lat": 48.2833,
    "lng": 11.5667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 28907,
    "id": 1276071648
  },
  {
    "city": "Lawrenceville",
    "city_ascii": "Lawrenceville",
    "lat": 33.9523,
    "lng": -83.9931,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 30834,
    "id": 1840014745
  },
  {
    "city": "Navahrudak",
    "city_ascii": "Navahrudak",
    "lat": 53.5833,
    "lng": 25.8167,
    "country": "Belarus",
    "iso2": "BY",
    "iso3": "BLR",
    "admin_name": "Hrodzyenskaya Voblasts’",
    "capital": "",
    "population": 30800,
    "id": 1112500125
  },
  {
    "city": "Lohmar",
    "city_ascii": "Lohmar",
    "lat": 50.8415,
    "lng": 7.2166,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 30363,
    "id": 1276535520
  },
  {
    "city": "Winter Park",
    "city_ascii": "Winter Park",
    "lat": 28.5987,
    "lng": -81.3438,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 30825,
    "id": 1840015966
  },
  {
    "city": "Granger",
    "city_ascii": "Granger",
    "lat": 41.7373,
    "lng": -86.135,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 30815,
    "id": 1840030079
  },
  {
    "city": "Novoyavorovskoye",
    "city_ascii": "Novoyavorovskoye",
    "lat": 49.9311,
    "lng": 23.5731,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "L’vivs’ka Oblast’",
    "capital": "",
    "population": 30723,
    "id": 1804412448
  },
  {
    "city": "Artëmovskiy",
    "city_ascii": "Artemovskiy",
    "lat": 57.3667,
    "lng": 61.8667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sverdlovskaya Oblast’",
    "capital": "",
    "population": 30778,
    "id": 1643010530
  },
  {
    "city": "Carshalton",
    "city_ascii": "Carshalton",
    "lat": 51.3652,
    "lng": -0.1676,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Sutton",
    "capital": "",
    "population": 29917,
    "id": 1826112944
  },
  {
    "city": "Fitchburg",
    "city_ascii": "Fitchburg",
    "lat": 42.9859,
    "lng": -89.4255,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 30792,
    "id": 1840002905
  },
  {
    "city": "Togitsu",
    "city_ascii": "Togitsu",
    "lat": 32.8289,
    "lng": 129.8486,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Nagasaki",
    "capital": "",
    "population": 29375,
    "id": 1392037686
  },
  {
    "city": "Qahderījān",
    "city_ascii": "Qahderijan",
    "lat": 32.5767,
    "lng": 51.455,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Eşfahān",
    "capital": "",
    "population": 30002,
    "id": 1364015114
  },
  {
    "city": "Arcos de la Frontera",
    "city_ascii": "Arcos de la Frontera",
    "lat": 36.7483,
    "lng": -5.8063,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Andalusia",
    "capital": "",
    "population": 30700,
    "id": 1724851482
  },
  {
    "city": "Tekeli",
    "city_ascii": "Tekeli",
    "lat": 44.8333,
    "lng": 78.8167,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Almaty",
    "capital": "",
    "population": 30707,
    "id": 1398667260
  },
  {
    "city": "Wooster",
    "city_ascii": "Wooster",
    "lat": 40.8171,
    "lng": -81.9335,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 30750,
    "id": 1840000961
  },
  {
    "city": "Synel’nykove",
    "city_ascii": "Synel'nykove",
    "lat": 48.3178,
    "lng": 35.5119,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Dnipropetrovs’ka Oblast’",
    "capital": "minor",
    "population": 30724,
    "id": 1804535574
  },
  {
    "city": "Matsubushi",
    "city_ascii": "Matsubushi",
    "lat": 35.9258,
    "lng": 139.8153,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Saitama",
    "capital": "",
    "population": 28953,
    "id": 1392883192
  },
  {
    "city": "South Kingstown",
    "city_ascii": "South Kingstown",
    "lat": 41.4457,
    "lng": -71.544,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Rhode Island",
    "capital": "",
    "population": 30735,
    "id": 1840106244
  },
  {
    "city": "Fountain",
    "city_ascii": "Fountain",
    "lat": 38.6886,
    "lng": -104.6829,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 30735,
    "id": 1840020236
  },
  {
    "city": "Needham",
    "city_ascii": "Needham",
    "lat": 42.2814,
    "lng": -71.2411,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 30735,
    "id": 1840053553
  },
  {
    "city": "Sandanski",
    "city_ascii": "Sandanski",
    "lat": 41.5589,
    "lng": 23.2744,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Blagoevgrad",
    "capital": "",
    "population": 30695,
    "id": 1100580276
  },
  {
    "city": "Orangeville",
    "city_ascii": "Orangeville",
    "lat": 43.9167,
    "lng": -80.1167,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 30734,
    "id": 1124566061
  },
  {
    "city": "Emmen",
    "city_ascii": "Emmen",
    "lat": 47.0772,
    "lng": 8.3,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Luzern",
    "capital": "",
    "population": 29292,
    "id": 1756487266
  },
  {
    "city": "Ross Township",
    "city_ascii": "Ross Township",
    "lat": 40.5256,
    "lng": -80.0243,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 30728,
    "id": 1840149299
  },
  {
    "city": "Pápa",
    "city_ascii": "Papa",
    "lat": 47.3306,
    "lng": 17.4658,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Veszprém",
    "capital": "minor",
    "population": 30382,
    "id": 1348169908
  },
  {
    "city": "Fort Erie",
    "city_ascii": "Fort Erie",
    "lat": 42.9167,
    "lng": -79.0167,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 30710,
    "id": 1124516852
  },
  {
    "city": "Madruga",
    "city_ascii": "Madruga",
    "lat": 22.9164,
    "lng": -81.9239,
    "country": "Cuba",
    "iso2": "CU",
    "iso3": "CUB",
    "admin_name": "Mayabeque",
    "capital": "minor",
    "population": 30640,
    "id": 1192001508
  },
  {
    "city": "Newburgh",
    "city_ascii": "Newburgh",
    "lat": 41.553,
    "lng": -74.0599,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 30702,
    "id": 1840000586
  },
  {
    "city": "Juquitiba",
    "city_ascii": "Juquitiba",
    "lat": -23.9319,
    "lng": -47.0686,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 30642,
    "id": 1076231410
  },
  {
    "city": "Draveil",
    "city_ascii": "Draveil",
    "lat": 48.6852,
    "lng": 2.408,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 28833,
    "id": 1250259151
  },
  {
    "city": "Ar Raḩmānīyah",
    "city_ascii": "Ar Rahmaniyah",
    "lat": 31.1062,
    "lng": 30.6337,
    "country": "Egypt",
    "iso2": "EG",
    "iso3": "EGY",
    "admin_name": "Al Buḩayrah",
    "capital": "",
    "population": 29393,
    "id": 1818669069
  },
  {
    "city": "Brooklyn Center",
    "city_ascii": "Brooklyn Center",
    "lat": 45.0681,
    "lng": -93.3162,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 30690,
    "id": 1840004022
  },
  {
    "city": "Puerto de la Cruz",
    "city_ascii": "Puerto de la Cruz",
    "lat": 28.4167,
    "lng": -16.55,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Canary Islands",
    "capital": "",
    "population": 30468,
    "id": 1724298488
  },
  {
    "city": "Mława",
    "city_ascii": "Mlawa",
    "lat": 53.1167,
    "lng": 20.3667,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Mazowieckie",
    "capital": "minor",
    "population": 29836,
    "id": 1616236136
  },
  {
    "city": "Banamba",
    "city_ascii": "Banamba",
    "lat": 13.5504,
    "lng": -7.45,
    "country": "Mali",
    "iso2": "ML",
    "iso3": "MLI",
    "admin_name": "Koulikoro",
    "capital": "minor",
    "population": 30591,
    "id": 1466760822
  },
  {
    "city": "Mercedes",
    "city_ascii": "Mercedes",
    "lat": -29.2,
    "lng": -58.0833,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Corrientes",
    "capital": "minor",
    "population": 30649,
    "id": 1032972413
  },
  {
    "city": "Thung Song",
    "city_ascii": "Thung Song",
    "lat": 8.1645,
    "lng": 99.6804,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Nakhon Si Thammarat",
    "capital": "minor",
    "population": 30500,
    "id": 1764422992
  },
  {
    "city": "Curanilahue",
    "city_ascii": "Curanilahue",
    "lat": -37.48,
    "lng": -73.34,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Biobío",
    "capital": "",
    "population": 30611,
    "id": 1152226096
  },
  {
    "city": "Piešťany",
    "city_ascii": "Piest'any",
    "lat": 48.5833,
    "lng": 17.8333,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Trnavský",
    "capital": "minor",
    "population": 29957,
    "id": 1703636029
  },
  {
    "city": "Victoria",
    "city_ascii": "Victoria",
    "lat": -32.6167,
    "lng": -60.1667,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Entre Ríos",
    "capital": "minor",
    "population": 30623,
    "id": 1032557014
  },
  {
    "city": "Kerrville",
    "city_ascii": "Kerrville",
    "lat": 30.0398,
    "lng": -99.132,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 30623,
    "id": 1840020908
  },
  {
    "city": "Wuyuan",
    "city_ascii": "Wuyuan",
    "lat": 41.0896,
    "lng": 108.2722,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Inner Mongolia",
    "capital": "",
    "population": 30057,
    "id": 1156146594
  },
  {
    "city": "Barstow",
    "city_ascii": "Barstow",
    "lat": 34.8661,
    "lng": -117.0472,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 30605,
    "id": 1840019118
  },
  {
    "city": "Ennepetal",
    "city_ascii": "Ennepetal",
    "lat": 51.3021,
    "lng": 7.3425,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 30075,
    "id": 1276394678
  },
  {
    "city": "Ypacaraí",
    "city_ascii": "Ypacarai",
    "lat": -25.41,
    "lng": -57.28,
    "country": "Paraguay",
    "iso2": "PY",
    "iso3": "PRY",
    "admin_name": "Central",
    "capital": "",
    "population": 30150,
    "id": 1600844536
  },
  {
    "city": "Chimākurti",
    "city_ascii": "Chimakurti",
    "lat": 15.5819,
    "lng": 79.868,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Andhra Pradesh",
    "capital": "",
    "population": 30279,
    "id": 1356627585
  },
  {
    "city": "North Andover",
    "city_ascii": "North Andover",
    "lat": 42.6713,
    "lng": -71.0865,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 30589,
    "id": 1840053536
  },
  {
    "city": "Riesa",
    "city_ascii": "Riesa",
    "lat": 51.3081,
    "lng": 13.2939,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 30054,
    "id": 1276381687
  },
  {
    "city": "Mount Gambier",
    "city_ascii": "Mount Gambier",
    "lat": -37.8294,
    "lng": 140.7828,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "South Australia",
    "capital": "",
    "population": 29639,
    "id": 1036932780
  },
  {
    "city": "Kirov",
    "city_ascii": "Kirov",
    "lat": 54.0833,
    "lng": 34.3,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kaluzhskaya Oblast’",
    "capital": "",
    "population": 30520,
    "id": 1643587873
  },
  {
    "city": "Blagodarnyy",
    "city_ascii": "Blagodarnyy",
    "lat": 45.1029,
    "lng": 43.4252,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Stavropol’skiy Kray",
    "capital": "",
    "population": 30530,
    "id": 1643447136
  },
  {
    "city": "Cranberry",
    "city_ascii": "Cranberry",
    "lat": 40.7104,
    "lng": -80.1059,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 30552,
    "id": 1840147947
  },
  {
    "city": "G’ijduvon Shahri",
    "city_ascii": "G'ijduvon Shahri",
    "lat": 40.1,
    "lng": 64.6667,
    "country": "Uzbekistan",
    "iso2": "UZ",
    "iso3": "UZB",
    "admin_name": "Buxoro",
    "capital": "minor",
    "population": 30486,
    "id": 1860839337
  },
  {
    "city": "Horki",
    "city_ascii": "Horki",
    "lat": 54.2833,
    "lng": 30.9833,
    "country": "Belarus",
    "iso2": "BY",
    "iso3": "BLR",
    "admin_name": "Mahilyowskaya Voblasts’",
    "capital": "minor",
    "population": 30500,
    "id": 1112565326
  },
  {
    "city": "Canton",
    "city_ascii": "Canton",
    "lat": 34.2467,
    "lng": -84.49,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 30528,
    "id": 1840014703
  },
  {
    "city": "Andernach",
    "city_ascii": "Andernach",
    "lat": 50.4397,
    "lng": 7.4017,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Rhineland-Palatinate",
    "capital": "",
    "population": 29966,
    "id": 1276142194
  },
  {
    "city": "Trutnov",
    "city_ascii": "Trutnov",
    "lat": 50.561,
    "lng": 15.9128,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Královéhradecký Kraj",
    "capital": "",
    "population": 30234,
    "id": 1203787150
  },
  {
    "city": "Villa Donato Guerra",
    "city_ascii": "Villa Donato Guerra",
    "lat": 19.3083,
    "lng": -100.1422,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "México",
    "capital": "minor",
    "population": 29621,
    "id": 1484701900
  },
  {
    "city": "Ob",
    "city_ascii": "Ob",
    "lat": 54.9917,
    "lng": 82.7125,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Novosibirskaya Oblast’",
    "capital": "",
    "population": 29194,
    "id": 1643161360
  },
  {
    "city": "Hajdúböszörmény",
    "city_ascii": "Hajduboszormeny",
    "lat": 47.6667,
    "lng": 21.5167,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Hajdú-Bihar",
    "capital": "minor",
    "population": 30437,
    "id": 1348357188
  },
  {
    "city": "Sevenoaks",
    "city_ascii": "Sevenoaks",
    "lat": 51.2781,
    "lng": 0.1874,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Kent",
    "capital": "",
    "population": 29506,
    "id": 1826815474
  },
  {
    "city": "Auburn",
    "city_ascii": "Auburn",
    "lat": 42.9338,
    "lng": -76.5684,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 30507,
    "id": 1840000367
  },
  {
    "city": "Osterholz-Scharmbeck",
    "city_ascii": "Osterholz-Scharmbeck",
    "lat": 53.2269,
    "lng": 8.7947,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "minor",
    "population": 30300,
    "id": 1276987274
  },
  {
    "city": "North Huntingdon",
    "city_ascii": "North Huntingdon",
    "lat": 40.3293,
    "lng": -79.7335,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 30502,
    "id": 1840144809
  },
  {
    "city": "Ts’khinvali",
    "city_ascii": "Ts'khinvali",
    "lat": 42.2257,
    "lng": 43.9701,
    "country": "Georgia",
    "iso2": "GE",
    "iso3": "GEO",
    "admin_name": "Shida Kartli",
    "capital": "",
    "population": 30432,
    "id": 1268142653
  },
  {
    "city": "Orcutt",
    "city_ascii": "Orcutt",
    "lat": 34.8668,
    "lng": -120.4269,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 30493,
    "id": 1840019183
  },
  {
    "city": "Crown Point",
    "city_ascii": "Crown Point",
    "lat": 41.4141,
    "lng": -87.3457,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 30488,
    "id": 1840007091
  },
  {
    "city": "Slavgorod",
    "city_ascii": "Slavgorod",
    "lat": 53,
    "lng": 78.65,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Altayskiy Kray",
    "capital": "",
    "population": 29916,
    "id": 1643239330
  },
  {
    "city": "Windsor",
    "city_ascii": "Windsor",
    "lat": 40.4693,
    "lng": -104.9213,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 30477,
    "id": 1840022401
  },
  {
    "city": "Kórinthos",
    "city_ascii": "Korinthos",
    "lat": 37.9333,
    "lng": 22.9333,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Peloponnísos",
    "capital": "minor",
    "population": 30176,
    "id": 1300348183
  },
  {
    "city": "Deptford",
    "city_ascii": "Deptford",
    "lat": 39.8157,
    "lng": -75.1181,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 30454,
    "id": 1840081679
  },
  {
    "city": "Taunusstein",
    "city_ascii": "Taunusstein",
    "lat": 50.1435,
    "lng": 8.1606,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 30005,
    "id": 1276571090
  },
  {
    "city": "Gloucester",
    "city_ascii": "Gloucester",
    "lat": 42.626,
    "lng": -70.6897,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 30430,
    "id": 1840000412
  },
  {
    "city": "Ust’-Dzheguta",
    "city_ascii": "Ust'-Dzheguta",
    "lat": 44.0928,
    "lng": 41.9822,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Karachayevo-Cherkesiya",
    "capital": "",
    "population": 30369,
    "id": 1643267187
  },
  {
    "city": "Iguape",
    "city_ascii": "Iguape",
    "lat": -24.7081,
    "lng": -47.5553,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 30390,
    "id": 1076144584
  },
  {
    "city": "Mont-de-Marsan",
    "city_ascii": "Mont-de-Marsan",
    "lat": 43.89,
    "lng": -0.5,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Nouvelle-Aquitaine",
    "capital": "minor",
    "population": 29554,
    "id": 1250242566
  },
  {
    "city": "Télimélé",
    "city_ascii": "Telimele",
    "lat": 10.905,
    "lng": -13.043,
    "country": "Guinea",
    "iso2": "GN",
    "iso3": "GIN",
    "admin_name": "Kindia",
    "capital": "minor",
    "population": 30311,
    "id": 1324315862
  },
  {
    "city": "Huanta",
    "city_ascii": "Huanta",
    "lat": -12.9397,
    "lng": -74.2475,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "Ayacucho",
    "capital": "",
    "population": 30234,
    "id": 1604179024
  },
  {
    "city": "Gurnee",
    "city_ascii": "Gurnee",
    "lat": 42.3708,
    "lng": -87.9392,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 30378,
    "id": 1840011152
  },
  {
    "city": "Orangeburg",
    "city_ascii": "Orangeburg",
    "lat": 33.4928,
    "lng": -80.867,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 30365,
    "id": 1840014812
  },
  {
    "city": "Vinces",
    "city_ascii": "Vinces",
    "lat": -1.55,
    "lng": -79.7333,
    "country": "Ecuador",
    "iso2": "EC",
    "iso3": "ECU",
    "admin_name": "Los Ríos",
    "capital": "",
    "population": 30241,
    "id": 1218211679
  },
  {
    "city": "Holladay",
    "city_ascii": "Holladay",
    "lat": 40.6599,
    "lng": -111.8226,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Utah",
    "capital": "",
    "population": 30325,
    "id": 1840020156
  },
  {
    "city": "Fuquay-Varina",
    "city_ascii": "Fuquay-Varina",
    "lat": 35.5958,
    "lng": -78.7794,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 30324,
    "id": 1840016197
  },
  {
    "city": "Newington",
    "city_ascii": "Newington",
    "lat": 41.687,
    "lng": -72.7308,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 30323,
    "id": 1840035505
  },
  {
    "city": "Chamblee",
    "city_ascii": "Chamblee",
    "lat": 33.8842,
    "lng": -84.3008,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 30307,
    "id": 1840014775
  },
  {
    "city": "Kondopoga",
    "city_ascii": "Kondopoga",
    "lat": 62.2,
    "lng": 34.2667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kareliya",
    "capital": "",
    "population": 30299,
    "id": 1643955723
  },
  {
    "city": "Décines-Charpieu",
    "city_ascii": "Decines-Charpieu",
    "lat": 45.7694,
    "lng": 4.9594,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Auvergne-Rhône-Alpes",
    "capital": "",
    "population": 28602,
    "id": 1250944644
  },
  {
    "city": "Yoshinaga",
    "city_ascii": "Yoshinaga",
    "lat": 34.7708,
    "lng": 138.2519,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Shizuoka",
    "capital": "",
    "population": 28899,
    "id": 1392098374
  },
  {
    "city": "Peru",
    "city_ascii": "Peru",
    "lat": 41.3484,
    "lng": -89.137,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 30287,
    "id": 1840009273
  },
  {
    "city": "Kibiti",
    "city_ascii": "Kibiti",
    "lat": -7.7296,
    "lng": 38.95,
    "country": "Tanzania",
    "iso2": "TZ",
    "iso3": "TZA",
    "admin_name": "Coast",
    "capital": "",
    "population": 30163,
    "id": 1834522080
  },
  {
    "city": "Cihuatlán",
    "city_ascii": "Cihuatlan",
    "lat": 19.25,
    "lng": -104.5667,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Jalisco",
    "capital": "minor",
    "population": 30241,
    "id": 1484019808
  },
  {
    "city": "Rushden",
    "city_ascii": "Rushden",
    "lat": 52.288,
    "lng": -0.601,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Northamptonshire",
    "capital": "",
    "population": 29272,
    "id": 1826167520
  },
  {
    "city": "Kombissiri",
    "city_ascii": "Kombissiri",
    "lat": 12.064,
    "lng": -1.334,
    "country": "Burkina Faso",
    "iso2": "BF",
    "iso3": "BFA",
    "admin_name": "Centre-Sud",
    "capital": "minor",
    "population": 30137,
    "id": 1854637161
  },
  {
    "city": "Cornelius",
    "city_ascii": "Cornelius",
    "lat": 35.4725,
    "lng": -80.8801,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 30257,
    "id": 1840016352
  },
  {
    "city": "Gorodets",
    "city_ascii": "Gorodets",
    "lat": 56.6503,
    "lng": 43.4703,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Nizhegorodskaya Oblast’",
    "capital": "",
    "population": 30188,
    "id": 1643700852
  },
  {
    "city": "Ban Aranyik",
    "city_ascii": "Ban Aranyik",
    "lat": 16.8146,
    "lng": 100.3051,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Phitsanulok",
    "capital": "",
    "population": 29825,
    "id": 1764420565
  },
  {
    "city": "North Tonawanda",
    "city_ascii": "North Tonawanda",
    "lat": 43.0457,
    "lng": -78.8659,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 30245,
    "id": 1840000372
  },
  {
    "city": "Gaggenau",
    "city_ascii": "Gaggenau",
    "lat": 48.8039,
    "lng": 8.3194,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 29777,
    "id": 1276552610
  },
  {
    "city": "Los Gatos",
    "city_ascii": "Los Gatos",
    "lat": 37.2303,
    "lng": -121.956,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 30222,
    "id": 1840021575
  },
  {
    "city": "Mozhaysk",
    "city_ascii": "Mozhaysk",
    "lat": 55.5,
    "lng": 36.0333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Moskovskaya Oblast’",
    "capital": "",
    "population": 30190,
    "id": 1643344419
  },
  {
    "city": "Moanda",
    "city_ascii": "Moanda",
    "lat": -1.5655,
    "lng": 13.2,
    "country": "Gabon",
    "iso2": "GA",
    "iso3": "GAB",
    "admin_name": "Haut-Ogooué",
    "capital": "",
    "population": 30151,
    "id": 1266295581
  },
  {
    "city": "Bad Hersfeld",
    "city_ascii": "Bad Hersfeld",
    "lat": 50.8683,
    "lng": 9.7067,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "minor",
    "population": 29800,
    "id": 1276923242
  },
  {
    "city": "Saint-Étienne-du-Rouvray",
    "city_ascii": "Saint-Etienne-du-Rouvray",
    "lat": 49.3786,
    "lng": 1.105,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Normandie",
    "capital": "",
    "population": 28641,
    "id": 1250043757
  },
  {
    "city": "South Lake Tahoe",
    "city_ascii": "South Lake Tahoe",
    "lat": 38.9393,
    "lng": -119.9828,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 30188,
    "id": 1840021471
  },
  {
    "city": "LaSalle",
    "city_ascii": "LaSalle",
    "lat": 42.2167,
    "lng": -83.0667,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 30180,
    "id": 1124000988
  },
  {
    "city": "Friedberg",
    "city_ascii": "Friedberg",
    "lat": 48.35,
    "lng": 10.9833,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 29810,
    "id": 1276408320
  },
  {
    "city": "Clinton",
    "city_ascii": "Clinton",
    "lat": 41.8434,
    "lng": -90.2408,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Iowa",
    "capital": "",
    "population": 30177,
    "id": 1840000507
  },
  {
    "city": "Northfleet",
    "city_ascii": "Northfleet",
    "lat": 51.44,
    "lng": 0.34,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Kent",
    "capital": "",
    "population": 29474,
    "id": 1826633103
  },
  {
    "city": "Saratoga",
    "city_ascii": "Saratoga",
    "lat": 37.2684,
    "lng": -122.0263,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 30153,
    "id": 1840021572
  },
  {
    "city": "Kalgoorlie",
    "city_ascii": "Kalgoorlie",
    "lat": -30.7489,
    "lng": 121.4658,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Western Australia",
    "capital": "",
    "population": 29849,
    "id": 1036087904
  },
  {
    "city": "Lismore",
    "city_ascii": "Lismore",
    "lat": -28.8167,
    "lng": 153.2833,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 28720,
    "id": 1036024174
  },
  {
    "city": "Sue",
    "city_ascii": "Sue",
    "lat": 33.5872,
    "lng": 130.5072,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Fukuoka",
    "capital": "",
    "population": 28377,
    "id": 1392341153
  },
  {
    "city": "Sainte-Julie",
    "city_ascii": "Sainte-Julie",
    "lat": 45.5833,
    "lng": -73.3333,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 30104,
    "id": 1124000521
  },
  {
    "city": "Los Altos",
    "city_ascii": "Los Altos",
    "lat": 37.3685,
    "lng": -122.0966,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 30089,
    "id": 1840020328
  },
  {
    "city": "Pervomaiskyi",
    "city_ascii": "Pervomaiskyi",
    "lat": 49.3869,
    "lng": 36.2142,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Kharkivs’ka Oblast’",
    "capital": "minor",
    "population": 30048,
    "id": 1804863173
  },
  {
    "city": "Vienne",
    "city_ascii": "Vienne",
    "lat": 45.5242,
    "lng": 4.8781,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Auvergne-Rhône-Alpes",
    "capital": "minor",
    "population": 29306,
    "id": 1250052124
  },
  {
    "city": "Ballwin",
    "city_ascii": "Ballwin",
    "lat": 38.5951,
    "lng": -90.55,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 30082,
    "id": 1840007447
  },
  {
    "city": "Atascadero",
    "city_ascii": "Atascadero",
    "lat": 35.4827,
    "lng": -120.6858,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 30075,
    "id": 1840019126
  },
  {
    "city": "Soledade",
    "city_ascii": "Soledade",
    "lat": -28.8178,
    "lng": -52.51,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Rio Grande do Sul",
    "capital": "",
    "population": 30044,
    "id": 1076105389
  },
  {
    "city": "North Royalton",
    "city_ascii": "North Royalton",
    "lat": 41.3138,
    "lng": -81.7452,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 30068,
    "id": 1840003402
  },
  {
    "city": "Budaörs",
    "city_ascii": "Budaors",
    "lat": 47.4606,
    "lng": 18.9578,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Pest",
    "capital": "",
    "population": 28844,
    "id": 1348705618
  },
  {
    "city": "Villa Ángela",
    "city_ascii": "Villa Angela",
    "lat": -27.5833,
    "lng": -60.7167,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Chaco",
    "capital": "minor",
    "population": 30051,
    "id": 1032039944
  },
  {
    "city": "Schwedt (Oder)",
    "city_ascii": "Schwedt (Oder)",
    "lat": 53.0631,
    "lng": 14.2831,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Brandenburg",
    "capital": "",
    "population": 29920,
    "id": 1276552729
  },
  {
    "city": "Saalfeld",
    "city_ascii": "Saalfeld",
    "lat": 50.6506,
    "lng": 11.3542,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Thuringia",
    "capital": "minor",
    "population": 29457,
    "id": 1276907261
  },
  {
    "city": "Meschede",
    "city_ascii": "Meschede",
    "lat": 51.3503,
    "lng": 8.2836,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "minor",
    "population": 29921,
    "id": 1276139753
  },
  {
    "city": "Somerset",
    "city_ascii": "Somerset",
    "lat": 37.0815,
    "lng": -84.6091,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kentucky",
    "capital": "",
    "population": 30053,
    "id": 1840015257
  },
  {
    "city": "Villa Regina",
    "city_ascii": "Villa Regina",
    "lat": -39.1,
    "lng": -67.0667,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Río Negro",
    "capital": "",
    "population": 30028,
    "id": 1032583314
  },
  {
    "city": "Derne",
    "city_ascii": "Derne",
    "lat": 51.6667,
    "lng": 7.6167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 29662,
    "id": 1276021883
  },
  {
    "city": "Siraha",
    "city_ascii": "Siraha",
    "lat": 26.6528,
    "lng": 86.2069,
    "country": "Nepal",
    "iso2": "NP",
    "iso3": "NPL",
    "admin_name": "Sagarmāthā",
    "capital": "",
    "population": 28442,
    "id": 1524369856
  },
  {
    "city": "Neuburg",
    "city_ascii": "Neuburg",
    "lat": 48.7333,
    "lng": 11.1833,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "minor",
    "population": 29682,
    "id": 1276560116
  },
  {
    "city": "Casma",
    "city_ascii": "Casma",
    "lat": -9.4742,
    "lng": -78.3106,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "Ancash",
    "capital": "",
    "population": 30000,
    "id": 1604136455
  },
  {
    "city": "Devarkonda",
    "city_ascii": "Devarkonda",
    "lat": 16.7,
    "lng": 78.9333,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Telangana",
    "capital": "",
    "population": 29731,
    "id": 1356060197
  },
  {
    "city": "Merzig",
    "city_ascii": "Merzig",
    "lat": 49.4471,
    "lng": 6.6312,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saarland",
    "capital": "minor",
    "population": 29745,
    "id": 1276524998
  },
  {
    "city": "Bourèm Guindou",
    "city_ascii": "Bourem Guindou",
    "lat": 16.9004,
    "lng": -0.35,
    "country": "Mali",
    "iso2": "ML",
    "iso3": "MLI",
    "admin_name": "Gao",
    "capital": "",
    "population": 30000,
    "id": 1466124401
  },
  {
    "city": "Kelkheim (Taunus)",
    "city_ascii": "Kelkheim (Taunus)",
    "lat": 50.138,
    "lng": 8.4525,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 29055,
    "id": 1276386467
  },
  {
    "city": "Mavinga",
    "city_ascii": "Mavinga",
    "lat": -15.7895,
    "lng": 20.36,
    "country": "Angola",
    "iso2": "AO",
    "iso3": "AGO",
    "admin_name": "Kuando Kubango",
    "capital": "",
    "population": 30000,
    "id": 1024585059
  },
  {
    "city": "Leduc",
    "city_ascii": "Leduc",
    "lat": 53.2594,
    "lng": -113.5492,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Alberta",
    "capital": "",
    "population": 29993,
    "id": 1124170853
  },
  {
    "city": "Arlon",
    "city_ascii": "Arlon",
    "lat": 49.6836,
    "lng": 5.8167,
    "country": "Belgium",
    "iso2": "BE",
    "iso3": "BEL",
    "admin_name": "Wallonia",
    "capital": "minor",
    "population": 29733,
    "id": 1056746428
  },
  {
    "city": "Milford Mill",
    "city_ascii": "Milford Mill",
    "lat": 39.3444,
    "lng": -76.7668,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 29974,
    "id": 1840031493
  },
  {
    "city": "Waltrop",
    "city_ascii": "Waltrop",
    "lat": 51.6167,
    "lng": 7.3833,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 29345,
    "id": 1276333081
  },
  {
    "city": "Tönisvorst",
    "city_ascii": "Tonisvorst",
    "lat": 51.3208,
    "lng": 6.4931,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 29306,
    "id": 1276126819
  },
  {
    "city": "Svendborg",
    "city_ascii": "Svendborg",
    "lat": 55.0704,
    "lng": 10.6167,
    "country": "Denmark",
    "iso2": "DK",
    "iso3": "DNK",
    "admin_name": "Syddanmark",
    "capital": "minor",
    "population": 29180,
    "id": 1208924964
  },
  {
    "city": "Giannitsá",
    "city_ascii": "Giannitsa",
    "lat": 40.7962,
    "lng": 22.4145,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Kentrikí Makedonía",
    "capital": "minor",
    "population": 29789,
    "id": 1300157319
  },
  {
    "city": "Schwelm",
    "city_ascii": "Schwelm",
    "lat": 51.2904,
    "lng": 7.2972,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "minor",
    "population": 28542,
    "id": 1276363762
  },
  {
    "city": "Asipovichy",
    "city_ascii": "Asipovichy",
    "lat": 53.3,
    "lng": 28.65,
    "country": "Belarus",
    "iso2": "BY",
    "iso3": "BLR",
    "admin_name": "Mahilyowskaya Voblasts’",
    "capital": "minor",
    "population": 29900,
    "id": 1112583247
  },
  {
    "city": "Cannock",
    "city_ascii": "Cannock",
    "lat": 52.691,
    "lng": -2.027,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Staffordshire",
    "capital": "",
    "population": 29018,
    "id": 1826709192
  },
  {
    "city": "Casa Branca",
    "city_ascii": "Casa Branca",
    "lat": -21.7739,
    "lng": -47.0858,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 29877,
    "id": 1076748418
  },
  {
    "city": "Paraparaumu",
    "city_ascii": "Paraparaumu",
    "lat": -40.9167,
    "lng": 175.0167,
    "country": "New Zealand",
    "iso2": "NZ",
    "iso3": "NZL",
    "admin_name": "Wellington",
    "capital": "",
    "population": 28900,
    "id": 1554166459
  },
  {
    "city": "Stockbridge",
    "city_ascii": "Stockbridge",
    "lat": 33.5253,
    "lng": -84.2294,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 29904,
    "id": 1840015707
  },
  {
    "city": "Orlová",
    "city_ascii": "Orlova",
    "lat": 49.8452,
    "lng": 18.4302,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Moravskoslezský Kraj",
    "capital": "",
    "population": 28735,
    "id": 1203799110
  },
  {
    "city": "East Liverpool",
    "city_ascii": "East Liverpool",
    "lat": 40.6333,
    "lng": -80.5677,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 29898,
    "id": 1840002783
  },
  {
    "city": "Leonding",
    "city_ascii": "Leonding",
    "lat": 48.2792,
    "lng": 14.2528,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Oberösterreich",
    "capital": "",
    "population": 28698,
    "id": 1040991026
  },
  {
    "city": "Aral",
    "city_ascii": "Aral",
    "lat": 46.8,
    "lng": 61.6667,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Qyzylorda",
    "capital": "",
    "population": 29887,
    "id": 1398109811
  },
  {
    "city": "Madison Heights",
    "city_ascii": "Madison Heights",
    "lat": 42.5073,
    "lng": -83.1034,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 29886,
    "id": 1840002449
  },
  {
    "city": "Gyöngyös",
    "city_ascii": "Gyongyos",
    "lat": 47.7833,
    "lng": 19.9333,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Heves",
    "capital": "minor",
    "population": 29337,
    "id": 1348619810
  },
  {
    "city": "Vaihingen an der Enz",
    "city_ascii": "Vaihingen an der Enz",
    "lat": 48.9328,
    "lng": 8.9564,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 29467,
    "id": 1276306973
  },
  {
    "city": "Kınık",
    "city_ascii": "Kinik",
    "lat": 39.0872,
    "lng": 27.3833,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "İzmir",
    "capital": "minor",
    "population": 29803,
    "id": 1792776515
  },
  {
    "city": "Kabalo",
    "city_ascii": "Kabalo",
    "lat": -6.05,
    "lng": 26.91,
    "country": "Congo (Kinshasa)",
    "iso2": "CD",
    "iso3": "COD",
    "admin_name": "Tanganyika",
    "capital": "",
    "population": 29833,
    "id": 1180905835
  },
  {
    "city": "Williston",
    "city_ascii": "Williston",
    "lat": 48.1814,
    "lng": -103.6364,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Dakota",
    "capital": "",
    "population": 29844,
    "id": 1840001871
  },
  {
    "city": "Labasa",
    "city_ascii": "Labasa",
    "lat": -16.4333,
    "lng": 179.3667,
    "country": "Fiji",
    "iso2": "FJ",
    "iso3": "FJI",
    "admin_name": "Macuata",
    "capital": "",
    "population": 27949,
    "id": 1242740801
  },
  {
    "city": "Dedovsk",
    "city_ascii": "Dedovsk",
    "lat": 55.8667,
    "lng": 37.1333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Moskovskaya Oblast’",
    "capital": "",
    "population": 29738,
    "id": 1643962207
  },
  {
    "city": "Heywood",
    "city_ascii": "Heywood",
    "lat": 53.59,
    "lng": -2.219,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Rochdale",
    "capital": "",
    "population": 28205,
    "id": 1826591970
  },
  {
    "city": "Shiyeli",
    "city_ascii": "Shiyeli",
    "lat": 44.167,
    "lng": 66.75,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Qyzylorda",
    "capital": "",
    "population": 29832,
    "id": 1398206798
  },
  {
    "city": "Bretten",
    "city_ascii": "Bretten",
    "lat": 49.0364,
    "lng": 8.7061,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 29412,
    "id": 1276638008
  },
  {
    "city": "Mandalī",
    "city_ascii": "Mandali",
    "lat": 33.7436,
    "lng": 45.5464,
    "country": "Iraq",
    "iso2": "IQ",
    "iso3": "IRQ",
    "admin_name": "Diyālá",
    "capital": "",
    "population": 29785,
    "id": 1368763045
  },
  {
    "city": "Eagle",
    "city_ascii": "Eagle",
    "lat": 43.7223,
    "lng": -116.3862,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Idaho",
    "capital": "",
    "population": 29796,
    "id": 1840018642
  },
  {
    "city": "Lake Magdalene",
    "city_ascii": "Lake Magdalene",
    "lat": 28.0875,
    "lng": -82.4791,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 29794,
    "id": 1840029049
  },
  {
    "city": "Igarapava",
    "city_ascii": "Igarapava",
    "lat": -20.0383,
    "lng": -47.7469,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 29727,
    "id": 1076928991
  },
  {
    "city": "Alushta",
    "city_ascii": "Alushta",
    "lat": 44.6672,
    "lng": 34.3978,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Krym, Avtonomna Respublika",
    "capital": "",
    "population": 29668,
    "id": 1804773543
  },
  {
    "city": "Nirasaki",
    "city_ascii": "Nirasaki",
    "lat": 35.7089,
    "lng": 138.4464,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Yamanashi",
    "capital": "",
    "population": 29576,
    "id": 1392409307
  },
  {
    "city": "Rancharia",
    "city_ascii": "Rancharia",
    "lat": -22.2289,
    "lng": -50.8928,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 29778,
    "id": 1076007180
  },
  {
    "city": "Steinheim am Main",
    "city_ascii": "Steinheim am Main",
    "lat": 50.1257,
    "lng": 8.83,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 28403,
    "id": 1276179059
  },
  {
    "city": "Alquízar",
    "city_ascii": "Alquizar",
    "lat": 22.8067,
    "lng": -82.5828,
    "country": "Cuba",
    "iso2": "CU",
    "iso3": "CUB",
    "admin_name": "Artemisa",
    "capital": "minor",
    "population": 29616,
    "id": 1192960992
  },
  {
    "city": "Hanawa",
    "city_ascii": "Hanawa",
    "lat": 40.2158,
    "lng": 140.7883,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Akita",
    "capital": "",
    "population": 29727,
    "id": 1392488669
  },
  {
    "city": "Friedberg",
    "city_ascii": "Friedberg",
    "lat": 50.3353,
    "lng": 8.755,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "minor",
    "population": 29180,
    "id": 1276800921
  },
  {
    "city": "Carney",
    "city_ascii": "Carney",
    "lat": 39.405,
    "lng": -76.5236,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 29743,
    "id": 1840005678
  },
  {
    "city": "Maryville",
    "city_ascii": "Maryville",
    "lat": 35.7468,
    "lng": -83.9789,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 29742,
    "id": 1840015380
  },
  {
    "city": "Rietberg",
    "city_ascii": "Rietberg",
    "lat": 51.8,
    "lng": 8.4333,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 29466,
    "id": 1276651074
  },
  {
    "city": "Piraju",
    "city_ascii": "Piraju",
    "lat": -23.1936,
    "lng": -49.3839,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 29664,
    "id": 1076217227
  },
  {
    "city": "Mamaroneck",
    "city_ascii": "Mamaroneck",
    "lat": 40.9443,
    "lng": -73.7487,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 29721,
    "id": 1840004935
  },
  {
    "city": "Asha",
    "city_ascii": "Asha",
    "lat": 55,
    "lng": 57.25,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Chelyabinskaya Oblast’",
    "capital": "",
    "population": 29692,
    "id": 1643056456
  },
  {
    "city": "Bosanska Krupa",
    "city_ascii": "Bosanska Krupa",
    "lat": 44.8833,
    "lng": 16.15,
    "country": "Bosnia And Herzegovina",
    "iso2": "BA",
    "iso3": "BIH",
    "admin_name": "Bosnia and Herzegovina, Federation of",
    "capital": "minor",
    "population": 29659,
    "id": 1070449050
  },
  {
    "city": "Hirado",
    "city_ascii": "Hirado",
    "lat": 33.3681,
    "lng": 129.5539,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Nagasaki",
    "capital": "minor",
    "population": 29577,
    "id": 1392812578
  },
  {
    "city": "Livingston",
    "city_ascii": "Livingston",
    "lat": 40.7855,
    "lng": -74.3291,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 29698,
    "id": 1840081749
  },
  {
    "city": "Walnut",
    "city_ascii": "Walnut",
    "lat": 34.0334,
    "lng": -117.8593,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 29685,
    "id": 1840021875
  },
  {
    "city": "North Cowichan",
    "city_ascii": "North Cowichan",
    "lat": 48.8236,
    "lng": -123.7192,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "British Columbia",
    "capital": "",
    "population": 29676,
    "id": 1124000052
  },
  {
    "city": "Rendsburg",
    "city_ascii": "Rendsburg",
    "lat": 54.3044,
    "lng": 9.6644,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Schleswig-Holstein",
    "capital": "minor",
    "population": 28470,
    "id": 1276159889
  },
  {
    "city": "Garden City",
    "city_ascii": "Garden City",
    "lat": 37.9754,
    "lng": -100.8529,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kansas",
    "capital": "",
    "population": 29659,
    "id": 1840001669
  },
  {
    "city": "Texarkana",
    "city_ascii": "Texarkana",
    "lat": 33.4361,
    "lng": -93.996,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arkansas",
    "capital": "",
    "population": 29657,
    "id": 1840015708
  },
  {
    "city": "Güstrow",
    "city_ascii": "Gustrow",
    "lat": 53.7939,
    "lng": 12.1764,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Mecklenburg-Western Pomerania",
    "capital": "minor",
    "population": 29241,
    "id": 1276582967
  },
  {
    "city": "Kent",
    "city_ascii": "Kent",
    "lat": 41.149,
    "lng": -81.361,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 29646,
    "id": 1840008256
  },
  {
    "city": "Hampden",
    "city_ascii": "Hampden",
    "lat": 40.2602,
    "lng": -76.9809,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 29642,
    "id": 1840143338
  },
  {
    "city": "Landsberg",
    "city_ascii": "Landsberg",
    "lat": 48.0528,
    "lng": 10.8689,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "minor",
    "population": 29132,
    "id": 1276208782
  },
  {
    "city": "Ogawa",
    "city_ascii": "Ogawa",
    "lat": 36.0567,
    "lng": 139.2619,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Saitama",
    "capital": "",
    "population": 29137,
    "id": 1392897605
  },
  {
    "city": "North Chicago",
    "city_ascii": "North Chicago",
    "lat": 42.3172,
    "lng": -87.8596,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 29615,
    "id": 1840008086
  },
  {
    "city": "Greenville",
    "city_ascii": "Greenville",
    "lat": 33.385,
    "lng": -91.0514,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Mississippi",
    "capital": "",
    "population": 29613,
    "id": 1840013759
  },
  {
    "city": "Oelde",
    "city_ascii": "Oelde",
    "lat": 51.8258,
    "lng": 8.1436,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 29326,
    "id": 1276826099
  },
  {
    "city": "Aberdeen",
    "city_ascii": "Aberdeen",
    "lat": 46.9757,
    "lng": -123.8094,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 29609,
    "id": 1840018446
  },
  {
    "city": "Duluth",
    "city_ascii": "Duluth",
    "lat": 34.0053,
    "lng": -84.1492,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 29609,
    "id": 1840013675
  },
  {
    "city": "Königsbrunn",
    "city_ascii": "Konigsbrunn",
    "lat": 48.2689,
    "lng": 10.8908,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 28076,
    "id": 1276917723
  },
  {
    "city": "Winona",
    "city_ascii": "Winona",
    "lat": 44.0505,
    "lng": -91.6684,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 29601,
    "id": 1840010034
  },
  {
    "city": "O'Fallon",
    "city_ascii": "O'Fallon",
    "lat": 38.5976,
    "lng": -89.9155,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 29583,
    "id": 1840008632
  },
  {
    "city": "Ukiah",
    "city_ascii": "Ukiah",
    "lat": 39.1463,
    "lng": -123.2105,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 29561,
    "id": 1840021420
  },
  {
    "city": "Atwater",
    "city_ascii": "Atwater",
    "lat": 37.3543,
    "lng": -120.5981,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 29559,
    "id": 1840018934
  },
  {
    "city": "Krotoszyn",
    "city_ascii": "Krotoszyn",
    "lat": 51.697,
    "lng": 17.4357,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Wielkopolskie",
    "capital": "minor",
    "population": 29379,
    "id": 1616105817
  },
  {
    "city": "Sanford",
    "city_ascii": "Sanford",
    "lat": 43.4244,
    "lng": -70.7573,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maine",
    "capital": "",
    "population": 29535,
    "id": 1840002672
  },
  {
    "city": "Ja‘ār",
    "city_ascii": "Ja`ar",
    "lat": 13.2167,
    "lng": 45.3,
    "country": "Yemen",
    "iso2": "YE",
    "iso3": "YEM",
    "admin_name": "Abyan",
    "capital": "minor",
    "population": 29495,
    "id": 1887726452
  },
  {
    "city": "Highland Park",
    "city_ascii": "Highland Park",
    "lat": 42.1823,
    "lng": -87.8105,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 29515,
    "id": 1840006973
  },
  {
    "city": "Drexel Heights",
    "city_ascii": "Drexel Heights",
    "lat": 32.1457,
    "lng": -111.0478,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 29514,
    "id": 1840027928
  },
  {
    "city": "Westfield",
    "city_ascii": "Westfield",
    "lat": 40.6515,
    "lng": -74.3433,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 29512,
    "id": 1840001096
  },
  {
    "city": "Hermiston",
    "city_ascii": "Hermiston",
    "lat": 45.8323,
    "lng": -119.2858,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 29512,
    "id": 1840019915
  },
  {
    "city": "Riihimäki",
    "city_ascii": "Riihimaki",
    "lat": 60.7333,
    "lng": 24.7667,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Kanta-Häme",
    "capital": "minor",
    "population": 29269,
    "id": 1246263977
  },
  {
    "city": "Fortuna Foothills",
    "city_ascii": "Fortuna Foothills",
    "lat": 32.6616,
    "lng": -114.3973,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 29485,
    "id": 1840027989
  },
  {
    "city": "Tredyffrin",
    "city_ascii": "Tredyffrin",
    "lat": 40.0663,
    "lng": -75.454,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 29481,
    "id": 1840148599
  },
  {
    "city": "Bourgoin-Jallieu",
    "city_ascii": "Bourgoin-Jallieu",
    "lat": 45.5861,
    "lng": 5.2736,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Auvergne-Rhône-Alpes",
    "capital": "",
    "population": 28387,
    "id": 1250584408
  },
  {
    "city": "Orchard Park",
    "city_ascii": "Orchard Park",
    "lat": 42.7517,
    "lng": -78.7455,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 29463,
    "id": 1840004389
  },
  {
    "city": "Rotterdam",
    "city_ascii": "Rotterdam",
    "lat": 42.8133,
    "lng": -74.0129,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 29448,
    "id": 1840058454
  },
  {
    "city": "Likino-Dulevo",
    "city_ascii": "Likino-Dulevo",
    "lat": 55.7,
    "lng": 38.95,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Moskovskaya Oblast’",
    "capital": "",
    "population": 29376,
    "id": 1643014243
  },
  {
    "city": "Rösrath",
    "city_ascii": "Rosrath",
    "lat": 50.9,
    "lng": 7.1833,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 28693,
    "id": 1276492557
  },
  {
    "city": "Kavajë",
    "city_ascii": "Kavaje",
    "lat": 41.1833,
    "lng": 19.55,
    "country": "Albania",
    "iso2": "AL",
    "iso3": "ALB",
    "admin_name": "Tiranë",
    "capital": "",
    "population": 29354,
    "id": 1008475617
  },
  {
    "city": "Gyula",
    "city_ascii": "Gyula",
    "lat": 46.65,
    "lng": 21.2828,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Békés",
    "capital": "minor",
    "population": 29308,
    "id": 1348624339
  },
  {
    "city": "Kiskunfélegyháza",
    "city_ascii": "Kiskunfelegyhaza",
    "lat": 46.705,
    "lng": 19.85,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Bács-Kiskun",
    "capital": "minor",
    "population": 29306,
    "id": 1348638728
  },
  {
    "city": "Upper Merion",
    "city_ascii": "Upper Merion",
    "lat": 40.0902,
    "lng": -75.3791,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 29400,
    "id": 1840152386
  },
  {
    "city": "Kostomuksha",
    "city_ascii": "Kostomuksha",
    "lat": 64.5833,
    "lng": 30.6,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kareliya",
    "capital": "",
    "population": 29381,
    "id": 1643774276
  },
  {
    "city": "Ra’s al ‘Ayn",
    "city_ascii": "Ra's al `Ayn",
    "lat": 36.8503,
    "lng": 40.0706,
    "country": "Syria",
    "iso2": "SY",
    "iso3": "SYR",
    "admin_name": "Al Ḩasakah",
    "capital": "minor",
    "population": 29347,
    "id": 1760030804
  },
  {
    "city": "Glenville",
    "city_ascii": "Glenville",
    "lat": 42.8869,
    "lng": -73.9925,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 29371,
    "id": 1840058129
  },
  {
    "city": "Central",
    "city_ascii": "Central",
    "lat": 30.5593,
    "lng": -91.0369,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 29357,
    "id": 1840023139
  },
  {
    "city": "Quivicán",
    "city_ascii": "Quivican",
    "lat": 22.8247,
    "lng": -82.3558,
    "country": "Cuba",
    "iso2": "CU",
    "iso3": "CUB",
    "admin_name": "Mayabeque",
    "capital": "minor",
    "population": 29253,
    "id": 1192492613
  },
  {
    "city": "Pittsford",
    "city_ascii": "Pittsford",
    "lat": 43.0732,
    "lng": -77.5268,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 29349,
    "id": 1840004283
  },
  {
    "city": "Winnenden",
    "city_ascii": "Winnenden",
    "lat": 48.8764,
    "lng": 9.3978,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 28339,
    "id": 1276828910
  },
  {
    "city": "Izu",
    "city_ascii": "Izu",
    "lat": 34.9767,
    "lng": 138.9469,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Shizuoka",
    "capital": "",
    "population": 29262,
    "id": 1392453323
  },
  {
    "city": "Chicago Heights",
    "city_ascii": "Chicago Heights",
    "lat": 41.5101,
    "lng": -87.6345,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 29322,
    "id": 1840007024
  },
  {
    "city": "Divnogorsk",
    "city_ascii": "Divnogorsk",
    "lat": 55.95,
    "lng": 92.3833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Krasnoyarskiy Kray",
    "capital": "",
    "population": 29246,
    "id": 1643520779
  },
  {
    "city": "Orange",
    "city_ascii": "Orange",
    "lat": 44.1383,
    "lng": 4.8097,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Provence-Alpes-Côte d’Azur",
    "capital": "",
    "population": 28919,
    "id": 1250367364
  },
  {
    "city": "Northeim",
    "city_ascii": "Northeim",
    "lat": 51.7067,
    "lng": 10.0011,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "minor",
    "population": 29107,
    "id": 1276938853
  },
  {
    "city": "Khmil’nyk",
    "city_ascii": "Khmil'nyk",
    "lat": 49.55,
    "lng": 27.9667,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Vinnyts’ka Oblast’",
    "capital": "minor",
    "population": 27941,
    "id": 1804424011
  },
  {
    "city": "Namaacha",
    "city_ascii": "Namaacha",
    "lat": -25.9667,
    "lng": 32.0333,
    "country": "Mozambique",
    "iso2": "MZ",
    "iso3": "MOZ",
    "admin_name": "Maputo",
    "capital": "",
    "population": 29272,
    "id": 1508141495
  },
  {
    "city": "Oyabe",
    "city_ascii": "Oyabe",
    "lat": 36.6833,
    "lng": 136.8667,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Toyama",
    "capital": "",
    "population": 29081,
    "id": 1392185523
  },
  {
    "city": "Bühl",
    "city_ascii": "Buhl",
    "lat": 48.6953,
    "lng": 8.135,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 28900,
    "id": 1276639756
  },
  {
    "city": "Aioi",
    "city_ascii": "Aioi",
    "lat": 34.8036,
    "lng": 134.4681,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hyōgo",
    "capital": "",
    "population": 28971,
    "id": 1392132001
  },
  {
    "city": "Mahón",
    "city_ascii": "Mahon",
    "lat": 39.8894,
    "lng": 4.2642,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Balearic Islands",
    "capital": "",
    "population": 29040,
    "id": 1724771449
  },
  {
    "city": "Anadia",
    "city_ascii": "Anadia",
    "lat": 40.4333,
    "lng": -8.4333,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Aveiro",
    "capital": "minor",
    "population": 29150,
    "id": 1620362655
  },
  {
    "city": "Truskavets’",
    "city_ascii": "Truskavets'",
    "lat": 49.2806,
    "lng": 23.505,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "L’vivs’ka Oblast’",
    "capital": "",
    "population": 28867,
    "id": 1804322399
  },
  {
    "city": "Bogdanovich",
    "city_ascii": "Bogdanovich",
    "lat": 56.7833,
    "lng": 62.05,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sverdlovskaya Oblast’",
    "capital": "",
    "population": 29241,
    "id": 1643075894
  },
  {
    "city": "Cañada de Gómez",
    "city_ascii": "Canada de Gomez",
    "lat": -32.8167,
    "lng": -61.4,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Santa Fe",
    "capital": "minor",
    "population": 29205,
    "id": 1032264958
  },
  {
    "city": "Chortkiv",
    "city_ascii": "Chortkiv",
    "lat": 49.0167,
    "lng": 25.8,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Ternopil’s’ka Oblast’",
    "capital": "minor",
    "population": 29169,
    "id": 1804015197
  },
  {
    "city": "Pālpā",
    "city_ascii": "Palpa",
    "lat": 27.8667,
    "lng": 83.55,
    "country": "Nepal",
    "iso2": "NP",
    "iso3": "NPL",
    "admin_name": "Lumbinī",
    "capital": "",
    "population": 29095,
    "id": 1524400922
  },
  {
    "city": "Cyangugu",
    "city_ascii": "Cyangugu",
    "lat": -2.4833,
    "lng": 28.8967,
    "country": "Rwanda",
    "iso2": "RW",
    "iso3": "RWA",
    "admin_name": "Western Province",
    "capital": "",
    "population": 27416,
    "id": 1646073091
  },
  {
    "city": "Johnston",
    "city_ascii": "Johnston",
    "lat": 41.8274,
    "lng": -71.5202,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Rhode Island",
    "capital": "",
    "population": 29235,
    "id": 1840106232
  },
  {
    "city": "East Fishkill",
    "city_ascii": "East Fishkill",
    "lat": 41.5567,
    "lng": -73.7824,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 29227,
    "id": 1840058059
  },
  {
    "city": "Newberg",
    "city_ascii": "Newberg",
    "lat": 45.3075,
    "lng": -122.9601,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 29225,
    "id": 1840019957
  },
  {
    "city": "Viacha",
    "city_ascii": "Viacha",
    "lat": -16.6333,
    "lng": -68.2833,
    "country": "Bolivia",
    "iso2": "BO",
    "iso3": "BOL",
    "admin_name": "La Paz",
    "capital": "",
    "population": 29108,
    "id": 1068833754
  },
  {
    "city": "Voorhees",
    "city_ascii": "Voorhees",
    "lat": 39.845,
    "lng": -74.955,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 29217,
    "id": 1840081683
  },
  {
    "city": "Cottica",
    "city_ascii": "Cottica",
    "lat": 3.8547,
    "lng": -54.2289,
    "country": "Suriname",
    "iso2": "SR",
    "iso3": "SUR",
    "admin_name": "Sipaliwini",
    "capital": "",
    "population": 29210,
    "id": 1740371814
  },
  {
    "city": "Cheshire",
    "city_ascii": "Cheshire",
    "lat": 41.5114,
    "lng": -72.9036,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 29208,
    "id": 1840044994
  },
  {
    "city": "Norwood",
    "city_ascii": "Norwood",
    "lat": 42.1861,
    "lng": -71.1948,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 29201,
    "id": 1840053676
  },
  {
    "city": "Wellesley",
    "city_ascii": "Wellesley",
    "lat": 42.3043,
    "lng": -71.2855,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 29201,
    "id": 1840053560
  },
  {
    "city": "Örnsköldsvik",
    "city_ascii": "Ornskoldsvik",
    "lat": 63.318,
    "lng": 18.7167,
    "country": "Sweden",
    "iso2": "SE",
    "iso3": "SWE",
    "admin_name": "Västernorrland",
    "capital": "minor",
    "population": 27749,
    "id": 1752250773
  },
  {
    "city": "Lewes",
    "city_ascii": "Lewes",
    "lat": 38.7777,
    "lng": -75.1448,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Delaware",
    "capital": "",
    "population": 29187,
    "id": 1840006068
  },
  {
    "city": "Kroměříž",
    "city_ascii": "Kromeriz",
    "lat": 49.2979,
    "lng": 17.3931,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Zlínský Kraj",
    "capital": "",
    "population": 28620,
    "id": 1203995171
  },
  {
    "city": "Aleysk",
    "city_ascii": "Aleysk",
    "lat": 52.5,
    "lng": 82.7833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Altayskiy Kray",
    "capital": "",
    "population": 28528,
    "id": 1643212700
  },
  {
    "city": "Washington",
    "city_ascii": "Washington",
    "lat": 37.1304,
    "lng": -113.4877,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Utah",
    "capital": "",
    "population": 29174,
    "id": 1840021561
  },
  {
    "city": "Primero de Enero",
    "city_ascii": "Primero de Enero",
    "lat": 21.9453,
    "lng": -78.4189,
    "country": "Cuba",
    "iso2": "CU",
    "iso3": "CUB",
    "admin_name": "Ciego de Ávila",
    "capital": "minor",
    "population": 29117,
    "id": 1192846441
  },
  {
    "city": "Vernon",
    "city_ascii": "Vernon",
    "lat": 41.8364,
    "lng": -72.4606,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 29157,
    "id": 1840035637
  },
  {
    "city": "Oak Ridge",
    "city_ascii": "Oak Ridge",
    "lat": 35.9639,
    "lng": -84.2938,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 29156,
    "id": 1840014473
  },
  {
    "city": "La Concordia",
    "city_ascii": "La Concordia",
    "lat": 0.0069,
    "lng": -79.3958,
    "country": "Ecuador",
    "iso2": "EC",
    "iso3": "ECU",
    "admin_name": "Esmeraldas",
    "capital": "",
    "population": 29003,
    "id": 1218975936
  },
  {
    "city": "San Cristóbal",
    "city_ascii": "San Cristobal",
    "lat": 22.7169,
    "lng": -83.0511,
    "country": "Cuba",
    "iso2": "CU",
    "iso3": "CUB",
    "admin_name": "Artemisa",
    "capital": "minor",
    "population": 29119,
    "id": 1192301736
  },
  {
    "city": "Agde",
    "city_ascii": "Agde",
    "lat": 43.3108,
    "lng": 3.4758,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Occitanie",
    "capital": "",
    "population": 28609,
    "id": 1250539086
  },
  {
    "city": "Laplace",
    "city_ascii": "Laplace",
    "lat": 30.0731,
    "lng": -90.4758,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 29147,
    "id": 1840013968
  },
  {
    "city": "Beverley",
    "city_ascii": "Beverley",
    "lat": 53.845,
    "lng": -0.427,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "East Riding of Yorkshire",
    "capital": "",
    "population": 29110,
    "id": 1826700096
  },
  {
    "city": "Salem",
    "city_ascii": "Salem",
    "lat": 42.7902,
    "lng": -71.2202,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Hampshire",
    "capital": "",
    "population": 29133,
    "id": 1840055510
  },
  {
    "city": "Carpentras",
    "city_ascii": "Carpentras",
    "lat": 44.0558,
    "lng": 5.0489,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Provence-Alpes-Côte d’Azur",
    "capital": "minor",
    "population": 28309,
    "id": 1250619476
  },
  {
    "city": "Springe",
    "city_ascii": "Springe",
    "lat": 52.2167,
    "lng": 9.55,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 28951,
    "id": 1276257894
  },
  {
    "city": "Samokov",
    "city_ascii": "Samokov",
    "lat": 42.3381,
    "lng": 23.56,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Sofia",
    "capital": "",
    "population": 29094,
    "id": 1100464208
  },
  {
    "city": "Madīnat Zāyid",
    "city_ascii": "Madinat Zayid",
    "lat": 23.6522,
    "lng": 53.6536,
    "country": "United Arab Emirates",
    "iso2": "AE",
    "iso3": "ARE",
    "admin_name": "Abū Z̧aby",
    "capital": "",
    "population": 29095,
    "id": 1784810704
  },
  {
    "city": "Ban Phai",
    "city_ascii": "Ban Phai",
    "lat": 16.073,
    "lng": 102.7362,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Khon Kaen",
    "capital": "minor",
    "population": 28913,
    "id": 1764026556
  },
  {
    "city": "Athens",
    "city_ascii": "Athens",
    "lat": 39.3269,
    "lng": -82.0988,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 29122,
    "id": 1840007355
  },
  {
    "city": "Chambly",
    "city_ascii": "Chambly",
    "lat": 45.4311,
    "lng": -73.2873,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 29120,
    "id": 1124345124
  },
  {
    "city": "North Attleborough",
    "city_ascii": "North Attleborough",
    "lat": 41.9699,
    "lng": -71.3345,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 29116,
    "id": 1840070236
  },
  {
    "city": "Tame",
    "city_ascii": "Tame",
    "lat": 6.4603,
    "lng": -71.74,
    "country": "Colombia",
    "iso2": "CO",
    "iso3": "COL",
    "admin_name": "Arauca",
    "capital": "minor",
    "population": 29099,
    "id": 1170459811
  },
  {
    "city": "Old Harbour",
    "city_ascii": "Old Harbour",
    "lat": 17.9414,
    "lng": -77.109,
    "country": "Jamaica",
    "iso2": "JM",
    "iso3": "JAM",
    "admin_name": "Saint Catherine",
    "capital": "",
    "population": 28665,
    "id": 1388632984
  },
  {
    "city": "Nouna",
    "city_ascii": "Nouna",
    "lat": 12.7329,
    "lng": -3.8622,
    "country": "Burkina Faso",
    "iso2": "BF",
    "iso3": "BFA",
    "admin_name": "Boucle du Mouhoun",
    "capital": "minor",
    "population": 29048,
    "id": 1854190171
  },
  {
    "city": "Benavente",
    "city_ascii": "Benavente",
    "lat": 38.9833,
    "lng": -8.8167,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Santarém",
    "capital": "minor",
    "population": 29019,
    "id": 1620484545
  },
  {
    "city": "Mirandópolis",
    "city_ascii": "Mirandopolis",
    "lat": -21.1336,
    "lng": -51.1017,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 29043,
    "id": 1076193094
  },
  {
    "city": "Schwandorf",
    "city_ascii": "Schwandorf",
    "lat": 49.3236,
    "lng": 12.0993,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "minor",
    "population": 28828,
    "id": 1276268287
  },
  {
    "city": "Gagarin",
    "city_ascii": "Gagarin",
    "lat": 55.55,
    "lng": 35,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Smolenskaya Oblast’",
    "capital": "",
    "population": 29041,
    "id": 1643316239
  },
  {
    "city": "Tocache Nuevo",
    "city_ascii": "Tocache Nuevo",
    "lat": -8.1886,
    "lng": -76.5103,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "San Martín",
    "capital": "",
    "population": 29029,
    "id": 1604060187
  },
  {
    "city": "Dzyarzhynsk",
    "city_ascii": "Dzyarzhynsk",
    "lat": 53.6833,
    "lng": 27.1333,
    "country": "Belarus",
    "iso2": "BY",
    "iso3": "BLR",
    "admin_name": "Minskaya Voblasts’",
    "capital": "",
    "population": 29000,
    "id": 1112600874
  },
  {
    "city": "San Javier",
    "city_ascii": "San Javier",
    "lat": -35.5924,
    "lng": -71.7353,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Maule",
    "capital": "",
    "population": 29017,
    "id": 1152644283
  },
  {
    "city": "SeaTac",
    "city_ascii": "SeaTac",
    "lat": 47.4444,
    "lng": -122.2986,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 29044,
    "id": 1840021116
  },
  {
    "city": "Staraya Russa",
    "city_ascii": "Staraya Russa",
    "lat": 57.9833,
    "lng": 31.35,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Novgorodskaya Oblast’",
    "capital": "",
    "population": 29019,
    "id": 1643015974
  },
  {
    "city": "Cajati",
    "city_ascii": "Cajati",
    "lat": -24.7361,
    "lng": -48.1228,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 28962,
    "id": 1076157800
  },
  {
    "city": "Höxter",
    "city_ascii": "Hoxter",
    "lat": 51.7667,
    "lng": 9.3667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "minor",
    "population": 28824,
    "id": 1276004976
  },
  {
    "city": "Luboń",
    "city_ascii": "Lubon",
    "lat": 52.3333,
    "lng": 16.8833,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Wielkopolskie",
    "capital": "",
    "population": 27000,
    "id": 1616642403
  },
  {
    "city": "Raytown",
    "city_ascii": "Raytown",
    "lat": 38.9944,
    "lng": -94.4641,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 28991,
    "id": 1840009681
  },
  {
    "city": "Rosa Zarate",
    "city_ascii": "Rosa Zarate",
    "lat": 0.3272,
    "lng": -79.4689,
    "country": "Ecuador",
    "iso2": "EC",
    "iso3": "ECU",
    "admin_name": "Esmeraldas",
    "capital": "",
    "population": 28928,
    "id": 1218721040
  },
  {
    "city": "Yemanzhelinsk",
    "city_ascii": "Yemanzhelinsk",
    "lat": 54.75,
    "lng": 61.3167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Chelyabinskaya Oblast’",
    "capital": "",
    "population": 28742,
    "id": 1643916431
  },
  {
    "city": "Southgate",
    "city_ascii": "Southgate",
    "lat": 42.2047,
    "lng": -83.2057,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 28959,
    "id": 1840003964
  },
  {
    "city": "West Warwick",
    "city_ascii": "West Warwick",
    "lat": 41.6986,
    "lng": -71.5156,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Rhode Island",
    "capital": "",
    "population": 28955,
    "id": 1840106223
  },
  {
    "city": "Meißen",
    "city_ascii": "Meissen",
    "lat": 51.1636,
    "lng": 13.4775,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "minor",
    "population": 28044,
    "id": 1276054483
  },
  {
    "city": "Barabinsk",
    "city_ascii": "Barabinsk",
    "lat": 55.35,
    "lng": 78.35,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Novosibirskaya Oblast’",
    "capital": "",
    "population": 28941,
    "id": 1643008352
  },
  {
    "city": "Harrison",
    "city_ascii": "Harrison",
    "lat": 41.0233,
    "lng": -73.7192,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 28943,
    "id": 1840004931
  },
  {
    "city": "Niles",
    "city_ascii": "Niles",
    "lat": 42.0278,
    "lng": -87.8099,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 28938,
    "id": 1840011301
  },
  {
    "city": "Fernandina Beach",
    "city_ascii": "Fernandina Beach",
    "lat": 30.6579,
    "lng": -81.4504,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 28931,
    "id": 1840013929
  },
  {
    "city": "Windsor",
    "city_ascii": "Windsor",
    "lat": 41.871,
    "lng": -72.6736,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 28917,
    "id": 1840033738
  },
  {
    "city": "Big Spring",
    "city_ascii": "Big Spring",
    "lat": 32.2387,
    "lng": -101.4802,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 28913,
    "id": 1840019471
  },
  {
    "city": "Petrovsk",
    "city_ascii": "Petrovsk",
    "lat": 52.3167,
    "lng": 45.3833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Saratovskaya Oblast’",
    "capital": "",
    "population": 28893,
    "id": 1643935134
  },
  {
    "city": "Balakliia",
    "city_ascii": "Balakliia",
    "lat": 49.4564,
    "lng": 36.8389,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Kharkivs’ka Oblast’",
    "capital": "minor",
    "population": 28868,
    "id": 1804852516
  },
  {
    "city": "Hafnarfjörður",
    "city_ascii": "Hafnarfjordhur",
    "lat": 64.0667,
    "lng": -21.95,
    "country": "Iceland",
    "iso2": "IS",
    "iso3": "ISL",
    "admin_name": "",
    "capital": "",
    "population": 28703,
    "id": 1352845977
  },
  {
    "city": "Quakers Hill",
    "city_ascii": "Quakers Hill",
    "lat": -33.7344,
    "lng": 150.8789,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 27080,
    "id": 1036046868
  },
  {
    "city": "Chiredzi",
    "city_ascii": "Chiredzi",
    "lat": -21.0496,
    "lng": 31.66,
    "country": "Zimbabwe",
    "iso2": "ZW",
    "iso3": "ZWE",
    "admin_name": "Masvingo",
    "capital": "",
    "population": 28205,
    "id": 1716636761
  },
  {
    "city": "Stanford le Hope",
    "city_ascii": "Stanford le Hope",
    "lat": 51.514,
    "lng": 0.4244,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Thurrock",
    "capital": "",
    "population": 28765,
    "id": 1826848098
  },
  {
    "city": "Borzya",
    "city_ascii": "Borzya",
    "lat": 50.3833,
    "lng": 116.5333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Zabaykal’skiy Kray",
    "capital": "",
    "population": 28888,
    "id": 1643309577
  },
  {
    "city": "Novyy Rozdil",
    "city_ascii": "Novyy Rozdil",
    "lat": 49.4703,
    "lng": 24.13,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "L’vivs’ka Oblast’",
    "capital": "",
    "population": 28745,
    "id": 1804473027
  },
  {
    "city": "Okotoks",
    "city_ascii": "Okotoks",
    "lat": 50.725,
    "lng": -113.975,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Alberta",
    "capital": "",
    "population": 28881,
    "id": 1124521470
  },
  {
    "city": "Bella Vista",
    "city_ascii": "Bella Vista",
    "lat": 36.4667,
    "lng": -94.2707,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arkansas",
    "capital": "",
    "population": 28872,
    "id": 1840015287
  },
  {
    "city": "Mount Olive",
    "city_ascii": "Mount Olive",
    "lat": 40.8662,
    "lng": -74.7426,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 28869,
    "id": 1840081727
  },
  {
    "city": "East Haven",
    "city_ascii": "East Haven",
    "lat": 41.2984,
    "lng": -72.8577,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 28860,
    "id": 1840034619
  },
  {
    "city": "Herentals",
    "city_ascii": "Herentals",
    "lat": 51.1767,
    "lng": 4.8364,
    "country": "Belgium",
    "iso2": "BE",
    "iso3": "BEL",
    "admin_name": "Flanders",
    "capital": "",
    "population": 28272,
    "id": 1056125768
  },
  {
    "city": "Milton",
    "city_ascii": "Milton",
    "lat": 41.009,
    "lng": -76.8507,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 28851,
    "id": 1840000845
  },
  {
    "city": "Morrisville",
    "city_ascii": "Morrisville",
    "lat": 35.8367,
    "lng": -78.8348,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 28846,
    "id": 1840016195
  },
  {
    "city": "Balzar",
    "city_ascii": "Balzar",
    "lat": -1.36,
    "lng": -79.9,
    "country": "Ecuador",
    "iso2": "EC",
    "iso3": "ECU",
    "admin_name": "Guayas",
    "capital": "",
    "population": 28794,
    "id": 1218257587
  },
  {
    "city": "Harelbeke",
    "city_ascii": "Harelbeke",
    "lat": 50.8567,
    "lng": 3.3131,
    "country": "Belgium",
    "iso2": "BE",
    "iso3": "BEL",
    "admin_name": "Flanders",
    "capital": "",
    "population": 27886,
    "id": 1056312961
  },
  {
    "city": "Kakuda",
    "city_ascii": "Kakuda",
    "lat": 37.9772,
    "lng": 140.7819,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Miyagi",
    "capital": "",
    "population": 28623,
    "id": 1392278934
  },
  {
    "city": "Qarataū",
    "city_ascii": "Qaratau",
    "lat": 43.1667,
    "lng": 70.4667,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Zhambyl",
    "capital": "",
    "population": 28804,
    "id": 1398932329
  },
  {
    "city": "Milford",
    "city_ascii": "Milford",
    "lat": 42.1565,
    "lng": -71.5188,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 28789,
    "id": 1840053594
  },
  {
    "city": "Zeitz",
    "city_ascii": "Zeitz",
    "lat": 51.0478,
    "lng": 12.1383,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony-Anhalt",
    "capital": "",
    "population": 27955,
    "id": 1276390204
  },
  {
    "city": "Leichlingen",
    "city_ascii": "Leichlingen",
    "lat": 51.1167,
    "lng": 7.0167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 28031,
    "id": 1276227302
  },
  {
    "city": "San Salvador",
    "city_ascii": "San Salvador",
    "lat": 20.2833,
    "lng": -99.0153,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Hidalgo",
    "capital": "minor",
    "population": 28637,
    "id": 1484680072
  },
  {
    "city": "Gorlice",
    "city_ascii": "Gorlice",
    "lat": 49.6556,
    "lng": 21.1604,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Małopolskie",
    "capital": "minor",
    "population": 27597,
    "id": 1616148150
  },
  {
    "city": "Yangiyer",
    "city_ascii": "Yangiyer",
    "lat": 40.2667,
    "lng": 68.8167,
    "country": "Uzbekistan",
    "iso2": "UZ",
    "iso3": "UZB",
    "admin_name": "Sirdaryo",
    "capital": "",
    "population": 28593,
    "id": 1860492176
  },
  {
    "city": "Casselberry",
    "city_ascii": "Casselberry",
    "lat": 28.6625,
    "lng": -81.3218,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 28757,
    "id": 1840015088
  },
  {
    "city": "Plattsburgh",
    "city_ascii": "Plattsburgh",
    "lat": 44.6951,
    "lng": -73.4563,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 28740,
    "id": 1840000296
  },
  {
    "city": "Seguin",
    "city_ascii": "Seguin",
    "lat": 29.5889,
    "lng": -97.9671,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 28735,
    "id": 1840022214
  },
  {
    "city": "Naranjito",
    "city_ascii": "Naranjito",
    "lat": -2.1667,
    "lng": -79.4653,
    "country": "Ecuador",
    "iso2": "EC",
    "iso3": "ECU",
    "admin_name": "Guayas",
    "capital": "",
    "population": 28546,
    "id": 1218386613
  },
  {
    "city": "Tainai",
    "city_ascii": "Tainai",
    "lat": 38.0667,
    "lng": 139.4167,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Niigata",
    "capital": "",
    "population": 28610,
    "id": 1392439704
  },
  {
    "city": "Griesheim",
    "city_ascii": "Griesheim",
    "lat": 49.8594,
    "lng": 8.5525,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 27435,
    "id": 1276867372
  },
  {
    "city": "Emmendingen",
    "city_ascii": "Emmendingen",
    "lat": 48.1214,
    "lng": 7.8492,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "minor",
    "population": 27882,
    "id": 1276708263
  },
  {
    "city": "Shirley",
    "city_ascii": "Shirley",
    "lat": 40.7949,
    "lng": -72.8743,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 28698,
    "id": 1840005097
  },
  {
    "city": "Ōuda-yamaguchi",
    "city_ascii": "Ouda-yamaguchi",
    "lat": 34.5278,
    "lng": 135.9525,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Nara",
    "capital": "",
    "population": 28568,
    "id": 1392926898
  },
  {
    "city": "Olching",
    "city_ascii": "Olching",
    "lat": 48.2,
    "lng": 11.3167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 27741,
    "id": 1276458023
  },
  {
    "city": "Belle Glade",
    "city_ascii": "Belle Glade",
    "lat": 26.692,
    "lng": -80.6672,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 28665,
    "id": 1840014206
  },
  {
    "city": "Obama",
    "city_ascii": "Obama",
    "lat": 35.4956,
    "lng": 135.7467,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Fukui",
    "capital": "",
    "population": 28538,
    "id": 1392050824
  },
  {
    "city": "George Town",
    "city_ascii": "George Town",
    "lat": 19.2866,
    "lng": -81.3744,
    "country": "Cayman Islands",
    "iso2": "KY",
    "iso3": "CYM",
    "admin_name": "",
    "capital": "",
    "population": 27704,
    "id": 1136159124
  },
  {
    "city": "Wasco",
    "city_ascii": "Wasco",
    "lat": 35.5938,
    "lng": -119.3671,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 28647,
    "id": 1840021738
  },
  {
    "city": "Lake in the Hills",
    "city_ascii": "Lake in the Hills",
    "lat": 42.1913,
    "lng": -88.3476,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 28634,
    "id": 1840011129
  },
  {
    "city": "Éibar",
    "city_ascii": "Eibar",
    "lat": 43.1843,
    "lng": -2.4733,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Basque Country",
    "capital": "",
    "population": 27522,
    "id": 1724606376
  },
  {
    "city": "Witney",
    "city_ascii": "Witney",
    "lat": 51.78,
    "lng": -1.49,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Oxfordshire",
    "capital": "",
    "population": 27522,
    "id": 1826136644
  },
  {
    "city": "Idar-Oberstein",
    "city_ascii": "Idar-Oberstein",
    "lat": 49.7019,
    "lng": 7.3253,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Rhineland-Palatinate",
    "capital": "",
    "population": 28323,
    "id": 1276902522
  },
  {
    "city": "Wallkill",
    "city_ascii": "Wallkill",
    "lat": 41.4854,
    "lng": -74.3939,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 28622,
    "id": 1840024229
  },
  {
    "city": "Kartaly",
    "city_ascii": "Kartaly",
    "lat": 53.05,
    "lng": 60.65,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Chelyabinskaya Oblast’",
    "capital": "",
    "population": 28607,
    "id": 1643181186
  },
  {
    "city": "Gojō",
    "city_ascii": "Gojo",
    "lat": 34.3528,
    "lng": 135.6989,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Nara",
    "capital": "",
    "population": 28519,
    "id": 1392081561
  },
  {
    "city": "Agawam",
    "city_ascii": "Agawam",
    "lat": 42.0657,
    "lng": -72.6526,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 28613,
    "id": 1840031157
  },
  {
    "city": "Pokhvistnevo",
    "city_ascii": "Pokhvistnevo",
    "lat": 53.65,
    "lng": 52.1333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Samarskaya Oblast’",
    "capital": "",
    "population": 28177,
    "id": 1643843387
  },
  {
    "city": "East Lake-Orient Park",
    "city_ascii": "East Lake-Orient Park",
    "lat": 27.9971,
    "lng": -82.3653,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 28602,
    "id": 1840073826
  },
  {
    "city": "Khashuri",
    "city_ascii": "Khashuri",
    "lat": 41.9897,
    "lng": 43.59,
    "country": "Georgia",
    "iso2": "GE",
    "iso3": "GEO",
    "admin_name": "Shida Kartli",
    "capital": "minor",
    "population": 28500,
    "id": 1268805813
  },
  {
    "city": "Dubăsari",
    "city_ascii": "Dubasari",
    "lat": 47.2667,
    "lng": 29.1667,
    "country": "Moldova",
    "iso2": "MD",
    "iso3": "MDA",
    "admin_name": "Stînga Nistrului",
    "capital": "",
    "population": 28500,
    "id": 1498583082
  },
  {
    "city": "Biłgoraj",
    "city_ascii": "Bilgoraj",
    "lat": 50.55,
    "lng": 22.7333,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Lubelskie",
    "capital": "minor",
    "population": 27290,
    "id": 1616554572
  },
  {
    "city": "McCandless",
    "city_ascii": "McCandless",
    "lat": 40.5836,
    "lng": -80.0283,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 28582,
    "id": 1840148208
  },
  {
    "city": "Tournefeuille",
    "city_ascii": "Tournefeuille",
    "lat": 43.5853,
    "lng": 1.3442,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Occitanie",
    "capital": "",
    "population": 26962,
    "id": 1250628309
  },
  {
    "city": "Nzega",
    "city_ascii": "Nzega",
    "lat": -4.2096,
    "lng": 33.18,
    "country": "Tanzania",
    "iso2": "TZ",
    "iso3": "TZA",
    "admin_name": "Tabora",
    "capital": "",
    "population": 26584,
    "id": 1834034762
  },
  {
    "city": "Lacey",
    "city_ascii": "Lacey",
    "lat": 39.8564,
    "lng": -74.2624,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 28580,
    "id": 1840081603
  },
  {
    "city": "Naranjal",
    "city_ascii": "Naranjal",
    "lat": -2.6736,
    "lng": -79.6183,
    "country": "Ecuador",
    "iso2": "EC",
    "iso3": "ECU",
    "admin_name": "Guayas",
    "capital": "",
    "population": 28487,
    "id": 1218900978
  },
  {
    "city": "Kitsuki",
    "city_ascii": "Kitsuki",
    "lat": 33.4169,
    "lng": 131.6161,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Ōita",
    "capital": "",
    "population": 28476,
    "id": 1392339287
  },
  {
    "city": "Lučenec",
    "city_ascii": "Lucenec",
    "lat": 48.3314,
    "lng": 19.6708,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Banskobystrický",
    "capital": "minor",
    "population": 27991,
    "id": 1703222138
  },
  {
    "city": "Burton",
    "city_ascii": "Burton",
    "lat": 42.9974,
    "lng": -83.6175,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 28574,
    "id": 1840002945
  },
  {
    "city": "Chili",
    "city_ascii": "Chili",
    "lat": 43.0845,
    "lng": -77.7541,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 28568,
    "id": 1840087304
  },
  {
    "city": "Geneva",
    "city_ascii": "Geneva",
    "lat": 42.8644,
    "lng": -76.9827,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 28563,
    "id": 1840000393
  },
  {
    "city": "Baradero",
    "city_ascii": "Baradero",
    "lat": -33.8,
    "lng": -59.5167,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires",
    "capital": "minor",
    "population": 28537,
    "id": 1032310302
  },
  {
    "city": "Khust",
    "city_ascii": "Khust",
    "lat": 48.1814,
    "lng": 23.2978,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Zakarpats’ka Oblast’",
    "capital": "minor",
    "population": 28424,
    "id": 1804836391
  },
  {
    "city": "Byaroza",
    "city_ascii": "Byaroza",
    "lat": 52.55,
    "lng": 24.9667,
    "country": "Belarus",
    "iso2": "BY",
    "iso3": "BLR",
    "admin_name": "Brestskaya Voblasts’",
    "capital": "minor",
    "population": 28500,
    "id": 1112705431
  },
  {
    "city": "Gusev",
    "city_ascii": "Gusev",
    "lat": 54.5922,
    "lng": 22.1997,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kaliningradskaya Oblast’",
    "capital": "minor",
    "population": 28484,
    "id": 1643116529
  },
  {
    "city": "Reinbek",
    "city_ascii": "Reinbek",
    "lat": 53.5089,
    "lng": 10.2483,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Schleswig-Holstein",
    "capital": "",
    "population": 27649,
    "id": 1276939131
  },
  {
    "city": "Stoughton",
    "city_ascii": "Stoughton",
    "lat": 42.1192,
    "lng": -71.1019,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 28534,
    "id": 1840053558
  },
  {
    "city": "Sept-Îles",
    "city_ascii": "Sept-Iles",
    "lat": 50.2167,
    "lng": -66.3833,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 28534,
    "id": 1124406431
  },
  {
    "city": "Fleming Island",
    "city_ascii": "Fleming Island",
    "lat": 30.0988,
    "lng": -81.7124,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 28531,
    "id": 1840038864
  },
  {
    "city": "Schererville",
    "city_ascii": "Schererville",
    "lat": 41.486,
    "lng": -87.444,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 28527,
    "id": 1840010222
  },
  {
    "city": "Independence",
    "city_ascii": "Independence",
    "lat": 38.951,
    "lng": -84.5492,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kentucky",
    "capital": "",
    "population": 28521,
    "id": 1840014267
  },
  {
    "city": "West Springfield",
    "city_ascii": "West Springfield",
    "lat": 42.1253,
    "lng": -72.6503,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 28517,
    "id": 1840031158
  },
  {
    "city": "Timaru",
    "city_ascii": "Timaru",
    "lat": -44.4,
    "lng": 171.25,
    "country": "New Zealand",
    "iso2": "NZ",
    "iso3": "NZL",
    "admin_name": "Canterbury",
    "capital": "",
    "population": 28300,
    "id": 1554243304
  },
  {
    "city": "Wegberg",
    "city_ascii": "Wegberg",
    "lat": 51.1333,
    "lng": 6.2667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 28175,
    "id": 1276843809
  },
  {
    "city": "Geislingen an der Steige",
    "city_ascii": "Geislingen an der Steige",
    "lat": 48.6244,
    "lng": 9.8306,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 28122,
    "id": 1276183243
  },
  {
    "city": "Gates",
    "city_ascii": "Gates",
    "lat": 43.1514,
    "lng": -77.713,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 28476,
    "id": 1840058119
  },
  {
    "city": "Baunatal",
    "city_ascii": "Baunatal",
    "lat": 51.2589,
    "lng": 9.4183,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 27750,
    "id": 1276339266
  },
  {
    "city": "Burlington",
    "city_ascii": "Burlington",
    "lat": 40.8072,
    "lng": -91.1247,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Iowa",
    "capital": "",
    "population": 28474,
    "id": 1840007164
  },
  {
    "city": "Mpika",
    "city_ascii": "Mpika",
    "lat": -11.83,
    "lng": 31.46,
    "country": "Zambia",
    "iso2": "ZM",
    "iso3": "ZMB",
    "admin_name": "Muchinga",
    "capital": "",
    "population": 28445,
    "id": 1894224372
  },
  {
    "city": "Serra Negra",
    "city_ascii": "Serra Negra",
    "lat": -22.6119,
    "lng": -46.7008,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 28321,
    "id": 1076394760
  },
  {
    "city": "New Iberia",
    "city_ascii": "New Iberia",
    "lat": 30.0049,
    "lng": -91.8202,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 28454,
    "id": 1840015053
  },
  {
    "city": "Silvassa",
    "city_ascii": "Silvassa",
    "lat": 20.2666,
    "lng": 73.0166,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Dādra and Nagar Haveli",
    "capital": "",
    "population": 27359,
    "id": 1356217846
  },
  {
    "city": "Northampton",
    "city_ascii": "Northampton",
    "lat": 42.3266,
    "lng": -72.6745,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 28451,
    "id": 1840000451
  },
  {
    "city": "Guararema",
    "city_ascii": "Guararema",
    "lat": -23.415,
    "lng": -46.035,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 28344,
    "id": 1076938810
  },
  {
    "city": "Esztergom",
    "city_ascii": "Esztergom",
    "lat": 47.7856,
    "lng": 18.7403,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Komárom-Esztergom",
    "capital": "minor",
    "population": 28144,
    "id": 1348527410
  },
  {
    "city": "Ciro Redondo",
    "city_ascii": "Ciro Redondo",
    "lat": 22.0189,
    "lng": -78.7031,
    "country": "Cuba",
    "iso2": "CU",
    "iso3": "CUB",
    "admin_name": "Ciego de Ávila",
    "capital": "minor",
    "population": 28370,
    "id": 1192204579
  },
  {
    "city": "Jeffrey’s Bay",
    "city_ascii": "Jeffrey's Bay",
    "lat": -34.0333,
    "lng": 24.9167,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "Eastern Cape",
    "capital": "",
    "population": 27107,
    "id": 1710277177
  },
  {
    "city": "Pershotravens’k",
    "city_ascii": "Pershotravens'k",
    "lat": 48.3464,
    "lng": 36.4044,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Dnipropetrovs’ka Oblast’",
    "capital": "",
    "population": 28363,
    "id": 1804847334
  },
  {
    "city": "Warin Chamrap",
    "city_ascii": "Warin Chamrap",
    "lat": 15.2008,
    "lng": 104.8612,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Ubon Ratchathani",
    "capital": "minor",
    "population": 28154,
    "id": 1764105770
  },
  {
    "city": "Austintown",
    "city_ascii": "Austintown",
    "lat": 41.0932,
    "lng": -80.7405,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 28386,
    "id": 1840005185
  },
  {
    "city": "Crofton",
    "city_ascii": "Crofton",
    "lat": 39.0144,
    "lng": -76.68,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 28379,
    "id": 1840005909
  },
  {
    "city": "Mehlville",
    "city_ascii": "Mehlville",
    "lat": 38.5018,
    "lng": -90.3149,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 28376,
    "id": 1840006120
  },
  {
    "city": "Bermejo",
    "city_ascii": "Bermejo",
    "lat": -22.7322,
    "lng": -64.3425,
    "country": "Bolivia",
    "iso2": "BO",
    "iso3": "BOL",
    "admin_name": "Tarija",
    "capital": "",
    "population": 28285,
    "id": 1068060614
  },
  {
    "city": "Niimi",
    "city_ascii": "Niimi",
    "lat": 34.9772,
    "lng": 133.4703,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Okayama",
    "capital": "",
    "population": 28327,
    "id": 1392904313
  },
  {
    "city": "Zionsville",
    "city_ascii": "Zionsville",
    "lat": 39.9897,
    "lng": -86.3182,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 28357,
    "id": 1840010515
  },
  {
    "city": "Pilar do Sul",
    "city_ascii": "Pilar do Sul",
    "lat": -23.8128,
    "lng": -47.7158,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 28309,
    "id": 1076254637
  },
  {
    "city": "Ternivka",
    "city_ascii": "Ternivka",
    "lat": 48.5231,
    "lng": 36.0826,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Dnipropetrovs’ka Oblast’",
    "capital": "",
    "population": 28298,
    "id": 1804507506
  },
  {
    "city": "Pasadena",
    "city_ascii": "Pasadena",
    "lat": 39.1552,
    "lng": -76.5537,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 28338,
    "id": 1840005914
  },
  {
    "city": "Mollendo",
    "city_ascii": "Mollendo",
    "lat": -17.0167,
    "lng": -72.0167,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "Arequipa",
    "capital": "",
    "population": 28305,
    "id": 1604134214
  },
  {
    "city": "Hiji",
    "city_ascii": "Hiji",
    "lat": 33.3694,
    "lng": 131.5325,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Ōita",
    "capital": "",
    "population": 27947,
    "id": 1392251373
  },
  {
    "city": "Shaler",
    "city_ascii": "Shaler",
    "lat": 40.5229,
    "lng": -79.9632,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 28324,
    "id": 1840150314
  },
  {
    "city": "Vineyard",
    "city_ascii": "Vineyard",
    "lat": 38.4744,
    "lng": -121.319,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 28315,
    "id": 1840018846
  },
  {
    "city": "Wetter (Ruhr)",
    "city_ascii": "Wetter (Ruhr)",
    "lat": 51.3881,
    "lng": 7.395,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 27441,
    "id": 1276406634
  },
  {
    "city": "Grimma",
    "city_ascii": "Grimma",
    "lat": 51.2386,
    "lng": 12.7253,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 28180,
    "id": 1276049939
  },
  {
    "city": "Kevelaer",
    "city_ascii": "Kevelaer",
    "lat": 51.5833,
    "lng": 6.25,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 28021,
    "id": 1276753170
  },
  {
    "city": "Ajka",
    "city_ascii": "Ajka",
    "lat": 47.1006,
    "lng": 17.5522,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Veszprém",
    "capital": "minor",
    "population": 27995,
    "id": 1348671384
  },
  {
    "city": "Kłodzko",
    "city_ascii": "Klodzko",
    "lat": 50.4378,
    "lng": 16.6528,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Dolnośląskie",
    "capital": "minor",
    "population": 27193,
    "id": 1616824014
  },
  {
    "city": "Lumberton",
    "city_ascii": "Lumberton",
    "lat": 34.6312,
    "lng": -79.0186,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 28279,
    "id": 1840015531
  },
  {
    "city": "Leimen",
    "city_ascii": "Leimen",
    "lat": 49.3481,
    "lng": 8.6911,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 26968,
    "id": 1276081089
  },
  {
    "city": "Alga",
    "city_ascii": "Alga",
    "lat": 49.9032,
    "lng": 57.335,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Aqtöbe",
    "capital": "",
    "population": 28267,
    "id": 1398920371
  },
  {
    "city": "Safājā",
    "city_ascii": "Safaja",
    "lat": 26.7517,
    "lng": 33.9344,
    "country": "Egypt",
    "iso2": "EG",
    "iso3": "EGY",
    "admin_name": "Al Baḩr al Aḩmar",
    "capital": "",
    "population": 28261,
    "id": 1818038126
  },
  {
    "city": "Bad Neuenahr-Ahrweiler",
    "city_ascii": "Bad Neuenahr-Ahrweiler",
    "lat": 50.5447,
    "lng": 7.1133,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Rhineland-Palatinate",
    "capital": "minor",
    "population": 28251,
    "id": 1276768738
  },
  {
    "city": "Benicia",
    "city_ascii": "Benicia",
    "lat": 38.0725,
    "lng": -122.1525,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 28240,
    "id": 1840018858
  },
  {
    "city": "Ban Chang",
    "city_ascii": "Ban Chang",
    "lat": 12.7209,
    "lng": 101.0669,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Rayong",
    "capital": "minor",
    "population": 27668,
    "id": 1764733644
  },
  {
    "city": "Jacksonville",
    "city_ascii": "Jacksonville",
    "lat": 34.8807,
    "lng": -92.1302,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arkansas",
    "capital": "",
    "population": 28235,
    "id": 1840014633
  },
  {
    "city": "Čapljina",
    "city_ascii": "Capljina",
    "lat": 43.11,
    "lng": 17.7,
    "country": "Bosnia And Herzegovina",
    "iso2": "BA",
    "iso3": "BIH",
    "admin_name": "Bosnia and Herzegovina, Federation of",
    "capital": "minor",
    "population": 28122,
    "id": 1070896558
  },
  {
    "city": "Villa Luvianos",
    "city_ascii": "Villa Luvianos",
    "lat": 18.92,
    "lng": -100.2983,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "México",
    "capital": "minor",
    "population": 28215,
    "id": 1484500757
  },
  {
    "city": "Rockledge",
    "city_ascii": "Rockledge",
    "lat": 28.3201,
    "lng": -80.732,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 28227,
    "id": 1840015095
  },
  {
    "city": "Honchō",
    "city_ascii": "Honcho",
    "lat": 41.8958,
    "lng": 140.6944,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hokkaidō",
    "capital": "",
    "population": 28080,
    "id": 1392772650
  },
  {
    "city": "Henderson",
    "city_ascii": "Henderson",
    "lat": 37.8397,
    "lng": -87.5798,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kentucky",
    "capital": "",
    "population": 28207,
    "id": 1840014337
  },
  {
    "city": "Rapperswil-Jona",
    "city_ascii": "Rapperswil-Jona",
    "lat": 47.2286,
    "lng": 8.8317,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Sankt Gallen",
    "capital": "minor",
    "population": 26989,
    "id": 1756219797
  },
  {
    "city": "Oktyabr’sk",
    "city_ascii": "Oktyabr'sk",
    "lat": 49.4731,
    "lng": 57.4449,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Aqtöbe",
    "capital": "",
    "population": 28196,
    "id": 1398394723
  },
  {
    "city": "Itako",
    "city_ascii": "Itako",
    "lat": 35.9472,
    "lng": 140.5553,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Ibaraki",
    "capital": "",
    "population": 27807,
    "id": 1392908453
  },
  {
    "city": "Graham",
    "city_ascii": "Graham",
    "lat": 47.0407,
    "lng": -122.2756,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 28194,
    "id": 1840017365
  },
  {
    "city": "Centre Wellington",
    "city_ascii": "Centre Wellington",
    "lat": 43.7,
    "lng": -80.3667,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 28191,
    "id": 1124000849
  },
  {
    "city": "Ancud",
    "city_ascii": "Ancud",
    "lat": -41.8682,
    "lng": -73.8287,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Los Lagos",
    "capital": "",
    "population": 28162,
    "id": 1152760614
  },
  {
    "city": "Bo‘ka",
    "city_ascii": "Bo`ka",
    "lat": 40.8111,
    "lng": 69.1942,
    "country": "Uzbekistan",
    "iso2": "UZ",
    "iso3": "UZB",
    "admin_name": "Toshkent",
    "capital": "",
    "population": 28000,
    "id": 1860263265
  },
  {
    "city": "Monterey",
    "city_ascii": "Monterey",
    "lat": 36.5922,
    "lng": -121.8807,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 28178,
    "id": 1840020358
  },
  {
    "city": "Arqalyq",
    "city_ascii": "Arqalyq",
    "lat": 50.2486,
    "lng": 66.9114,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Qostanay",
    "capital": "",
    "population": 28169,
    "id": 1398980293
  },
  {
    "city": "Converse",
    "city_ascii": "Converse",
    "lat": 29.5091,
    "lng": -98.3084,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 28171,
    "id": 1840019655
  },
  {
    "city": "Khrestivka",
    "city_ascii": "Khrestivka",
    "lat": 48.15,
    "lng": 38.3667,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Donets’ka Oblast’",
    "capital": "",
    "population": 28149,
    "id": 1804952819
  },
  {
    "city": "Vestal",
    "city_ascii": "Vestal",
    "lat": 42.0492,
    "lng": -76.026,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 28162,
    "id": 1840058562
  },
  {
    "city": "Granite City",
    "city_ascii": "Granite City",
    "lat": 38.7292,
    "lng": -90.1266,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 28158,
    "id": 1840008565
  },
  {
    "city": "Saugus",
    "city_ascii": "Saugus",
    "lat": 42.4681,
    "lng": -71.0145,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 28158,
    "id": 1840053670
  },
  {
    "city": "Llallagua",
    "city_ascii": "Llallagua",
    "lat": -18.4231,
    "lng": -66.5856,
    "country": "Bolivia",
    "iso2": "BO",
    "iso3": "BOL",
    "admin_name": "Potosí",
    "capital": "",
    "population": 28069,
    "id": 1068159509
  },
  {
    "city": "Aberdeen",
    "city_ascii": "Aberdeen",
    "lat": 45.4646,
    "lng": -98.468,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Dakota",
    "capital": "",
    "population": 28134,
    "id": 1840002032
  },
  {
    "city": "Perry Hall",
    "city_ascii": "Perry Hall",
    "lat": 39.4067,
    "lng": -76.4781,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 28129,
    "id": 1840005686
  },
  {
    "city": "Illkirch-Graffenstaden",
    "city_ascii": "Illkirch-Graffenstaden",
    "lat": 48.53,
    "lng": 7.72,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Grand Est",
    "capital": "",
    "population": 26780,
    "id": 1250689150
  },
  {
    "city": "Gaoua",
    "city_ascii": "Gaoua",
    "lat": 10.325,
    "lng": -3.174,
    "country": "Burkina Faso",
    "iso2": "BF",
    "iso3": "BFA",
    "admin_name": "Sud-Ouest",
    "capital": "",
    "population": 28023,
    "id": 1854142068
  },
  {
    "city": "Nallıhan",
    "city_ascii": "Nallihan",
    "lat": 40.1859,
    "lng": 31.3508,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Ankara",
    "capital": "minor",
    "population": 28091,
    "id": 1792689244
  },
  {
    "city": "Tara",
    "city_ascii": "Tara",
    "lat": 56.8753,
    "lng": 74.4136,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Omskaya Oblast’",
    "capital": "",
    "population": 28099,
    "id": 1643015750
  },
  {
    "city": "Khulm",
    "city_ascii": "Khulm",
    "lat": 36.6833,
    "lng": 67.6833,
    "country": "Afghanistan",
    "iso2": "AF",
    "iso3": "AFG",
    "admin_name": "Balkh",
    "capital": "minor",
    "population": 28078,
    "id": 1004486677
  },
  {
    "city": "Xochistlahuaca",
    "city_ascii": "Xochistlahuaca",
    "lat": 16.7914,
    "lng": -98.2419,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Guerrero",
    "capital": "minor",
    "population": 28089,
    "id": 1484003499
  },
  {
    "city": "Branford",
    "city_ascii": "Branford",
    "lat": 41.2841,
    "lng": -72.7981,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 28094,
    "id": 1840044985
  },
  {
    "city": "Yukon",
    "city_ascii": "Yukon",
    "lat": 35.5201,
    "lng": -97.7639,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oklahoma",
    "capital": "",
    "population": 28084,
    "id": 1840021740
  },
  {
    "city": "Kushva",
    "city_ascii": "Kushva",
    "lat": 58.2833,
    "lng": 59.7333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sverdlovskaya Oblast’",
    "capital": "",
    "population": 28060,
    "id": 1643697197
  },
  {
    "city": "Villazón",
    "city_ascii": "Villazon",
    "lat": -22.091,
    "lng": -65.596,
    "country": "Bolivia",
    "iso2": "BO",
    "iso3": "BOL",
    "admin_name": "Potosí",
    "capital": "",
    "population": 28045,
    "id": 1068163517
  },
  {
    "city": "South Laurel",
    "city_ascii": "South Laurel",
    "lat": 39.0603,
    "lng": -76.8456,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 28048,
    "id": 1840031498
  },
  {
    "city": "Ballina",
    "city_ascii": "Ballina",
    "lat": -28.8333,
    "lng": 153.5333,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 26381,
    "id": 1036040102
  },
  {
    "city": "Taree",
    "city_ascii": "Taree",
    "lat": -31.9,
    "lng": 152.45,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 26381,
    "id": 1036855717
  },
  {
    "city": "Sorochinsk",
    "city_ascii": "Sorochinsk",
    "lat": 52.4333,
    "lng": 53.15,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Orenburgskaya Oblast’",
    "capital": "",
    "population": 27547,
    "id": 1643278533
  },
  {
    "city": "Sonora",
    "city_ascii": "Sonora",
    "lat": 37.9819,
    "lng": -120.3828,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 28035,
    "id": 1840021511
  },
  {
    "city": "Kuaidamao",
    "city_ascii": "Kuaidamao",
    "lat": 41.68,
    "lng": 125.75,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Jilin",
    "capital": "minor",
    "population": 27227,
    "id": 1156106507
  },
  {
    "city": "Kasangulu",
    "city_ascii": "Kasangulu",
    "lat": -4.5796,
    "lng": 15.18,
    "country": "Congo (Kinshasa)",
    "iso2": "CD",
    "iso3": "COD",
    "admin_name": "Kongo Central",
    "capital": "",
    "population": 27961,
    "id": 1180582533
  },
  {
    "city": "West Windsor",
    "city_ascii": "West Windsor",
    "lat": 40.2897,
    "lng": -74.6267,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 28007,
    "id": 1840081655
  },
  {
    "city": "Takahagi",
    "city_ascii": "Takahagi",
    "lat": 36.7136,
    "lng": 140.7097,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Ibaraki",
    "capital": "",
    "population": 27863,
    "id": 1392592116
  },
  {
    "city": "Baesweiler",
    "city_ascii": "Baesweiler",
    "lat": 50.9,
    "lng": 6.1833,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 27033,
    "id": 1276109783
  },
  {
    "city": "Chíos",
    "city_ascii": "Chios",
    "lat": 38.3725,
    "lng": 26.1375,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Voreío Aigaío",
    "capital": "minor",
    "population": 26850,
    "id": 1300169896
  },
  {
    "city": "Camiri",
    "city_ascii": "Camiri",
    "lat": -20.05,
    "lng": -63.52,
    "country": "Bolivia",
    "iso2": "BO",
    "iso3": "BOL",
    "admin_name": "Santa Cruz",
    "capital": "",
    "population": 27961,
    "id": 1068205873
  },
  {
    "city": "Kriens",
    "city_ascii": "Kriens",
    "lat": 47.0344,
    "lng": 8.28,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Luzern",
    "capital": "",
    "population": 26997,
    "id": 1756901807
  },
  {
    "city": "Imatra",
    "city_ascii": "Imatra",
    "lat": 61.1931,
    "lng": 28.7764,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Etelä-Karjala",
    "capital": "minor",
    "population": 27835,
    "id": 1246114111
  },
  {
    "city": "Kholmsk",
    "city_ascii": "Kholmsk",
    "lat": 47.0403,
    "lng": 142.0431,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sakhalinskaya Oblast’",
    "capital": "",
    "population": 27954,
    "id": 1643364292
  },
  {
    "city": "Wisconsin Rapids",
    "city_ascii": "Wisconsin Rapids",
    "lat": 44.3927,
    "lng": -89.8265,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 27966,
    "id": 1840002333
  },
  {
    "city": "Nochistlán de Mejía",
    "city_ascii": "Nochistlan de Mejia",
    "lat": 21.3642,
    "lng": -102.8464,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Zacatecas",
    "capital": "minor",
    "population": 27932,
    "id": 1484761642
  },
  {
    "city": "San Martín de los Andes",
    "city_ascii": "San Martin de los Andes",
    "lat": -40.1667,
    "lng": -71.35,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Neuquén",
    "capital": "minor",
    "population": 27956,
    "id": 1032807074
  },
  {
    "city": "Dembī Dolo",
    "city_ascii": "Dembi Dolo",
    "lat": 8.5333,
    "lng": 34.8,
    "country": "Ethiopia",
    "iso2": "ET",
    "iso3": "ETH",
    "admin_name": "Oromīya",
    "capital": "",
    "population": 27780,
    "id": 1231620291
  },
  {
    "city": "Galt",
    "city_ascii": "Galt",
    "lat": 38.2693,
    "lng": -121.3,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 27954,
    "id": 1840020247
  },
  {
    "city": "Mocímboa",
    "city_ascii": "Mocimboa",
    "lat": -11.3196,
    "lng": 40.35,
    "country": "Mozambique",
    "iso2": "MZ",
    "iso3": "MOZ",
    "admin_name": "Cabo Delgado",
    "capital": "",
    "population": 27909,
    "id": 1508815299
  },
  {
    "city": "Hutto",
    "city_ascii": "Hutto",
    "lat": 30.5373,
    "lng": -97.5516,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 27947,
    "id": 1840019573
  },
  {
    "city": "Forest Hills",
    "city_ascii": "Forest Hills",
    "lat": 42.9577,
    "lng": -85.4895,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 27947,
    "id": 1840004304
  },
  {
    "city": "Sundern",
    "city_ascii": "Sundern",
    "lat": 51.3167,
    "lng": 8,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 27802,
    "id": 1276362137
  },
  {
    "city": "Jaguarão",
    "city_ascii": "Jaguarao",
    "lat": -32.5658,
    "lng": -53.3758,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Rio Grande do Sul",
    "capital": "",
    "population": 27931,
    "id": 1076878261
  },
  {
    "city": "Bixby",
    "city_ascii": "Bixby",
    "lat": 35.9454,
    "lng": -95.8773,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oklahoma",
    "capital": "",
    "population": 27944,
    "id": 1840019058
  },
  {
    "city": "Hobart",
    "city_ascii": "Hobart",
    "lat": 41.5139,
    "lng": -87.2729,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 27939,
    "id": 1840007093
  },
  {
    "city": "Tlalpujahua de Rayón",
    "city_ascii": "Tlalpujahua de Rayon",
    "lat": 19.805,
    "lng": -100.1744,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "México",
    "capital": "minor",
    "population": 27788,
    "id": 1484026388
  },
  {
    "city": "Oakdale",
    "city_ascii": "Oakdale",
    "lat": 44.9876,
    "lng": -92.9641,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 27933,
    "id": 1840007813
  },
  {
    "city": "Sabaneta",
    "city_ascii": "Sabaneta",
    "lat": 8.7522,
    "lng": -69.9325,
    "country": "Venezuela",
    "iso2": "VE",
    "iso3": "VEN",
    "admin_name": "Barinas",
    "capital": "minor",
    "population": 27850,
    "id": 1862775956
  },
  {
    "city": "Sunbury",
    "city_ascii": "Sunbury",
    "lat": 40.8617,
    "lng": -76.7874,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 27921,
    "id": 1840000851
  },
  {
    "city": "Ðakovo",
    "city_ascii": "Dakovo",
    "lat": 45.3,
    "lng": 18.4,
    "country": "Croatia",
    "iso2": "HR",
    "iso3": "HRV",
    "admin_name": "Osječko-Baranjska Županija",
    "capital": "minor",
    "population": 27745,
    "id": 1191845338
  },
  {
    "city": "Bolobo",
    "city_ascii": "Bolobo",
    "lat": -2.16,
    "lng": 16.24,
    "country": "Congo (Kinshasa)",
    "iso2": "CD",
    "iso3": "COD",
    "admin_name": "Mai-Ndombe",
    "capital": "",
    "population": 27862,
    "id": 1180723089
  },
  {
    "city": "Hādīshahr",
    "city_ascii": "Hadishahr",
    "lat": 38.8478,
    "lng": 45.6622,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Āz̄arbāyjān-e Sharqī",
    "capital": "",
    "population": 27842,
    "id": 1364264141
  },
  {
    "city": "Topki",
    "city_ascii": "Topki",
    "lat": 55.2833,
    "lng": 85.6167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kemerovskaya Oblast’",
    "capital": "",
    "population": 27860,
    "id": 1643475451
  },
  {
    "city": "Vyshhorod",
    "city_ascii": "Vyshhorod",
    "lat": 50.5833,
    "lng": 30.5,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Kyyivs’ka Oblast’",
    "capital": "minor",
    "population": 27825,
    "id": 1804392856
  },
  {
    "city": "Salinas Victoria",
    "city_ascii": "Salinas Victoria",
    "lat": 25.9667,
    "lng": -100.3,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Nuevo León",
    "capital": "minor",
    "population": 27848,
    "id": 1484014927
  },
  {
    "city": "Atlautla",
    "city_ascii": "Atlautla",
    "lat": 19,
    "lng": -98.7167,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "México",
    "capital": "minor",
    "population": 27663,
    "id": 1484312852
  },
  {
    "city": "Zolotonosha",
    "city_ascii": "Zolotonosha",
    "lat": 49.6833,
    "lng": 32.0333,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Cherkas’ka Oblast’",
    "capital": "minor",
    "population": 27847,
    "id": 1804210857
  },
  {
    "city": "Ormskirk",
    "city_ascii": "Ormskirk",
    "lat": 53.5665,
    "lng": -2.8869,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Lancashire",
    "capital": "",
    "population": 27343,
    "id": 1826066553
  },
  {
    "city": "Newtown",
    "city_ascii": "Newtown",
    "lat": 41.3988,
    "lng": -73.2927,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 27853,
    "id": 1840004838
  },
  {
    "city": "Fūman",
    "city_ascii": "Fuman",
    "lat": 37.2239,
    "lng": 49.3125,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Gīlān",
    "capital": "minor",
    "population": 27763,
    "id": 1364180869
  },
  {
    "city": "New Smyrna Beach",
    "city_ascii": "New Smyrna Beach",
    "lat": 29.0249,
    "lng": -80.9651,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 27843,
    "id": 1840015073
  },
  {
    "city": "Westport",
    "city_ascii": "Westport",
    "lat": 41.1428,
    "lng": -73.3475,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 27840,
    "id": 1840035669
  },
  {
    "city": "Tualatin",
    "city_ascii": "Tualatin",
    "lat": 45.3772,
    "lng": -122.7746,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 27837,
    "id": 1840021207
  },
  {
    "city": "Neustadt",
    "city_ascii": "Neustadt",
    "lat": 48.8111,
    "lng": 9.3656,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 26987,
    "id": 1276729265
  },
  {
    "city": "Fridley",
    "city_ascii": "Fridley",
    "lat": 45.0841,
    "lng": -93.2595,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 27826,
    "id": 1840007798
  },
  {
    "city": "Maloyaroslavets",
    "city_ascii": "Maloyaroslavets",
    "lat": 55,
    "lng": 36.4667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kaluzhskaya Oblast’",
    "capital": "",
    "population": 27791,
    "id": 1643014206
  },
  {
    "city": "Zakopane",
    "city_ascii": "Zakopane",
    "lat": 49.2994,
    "lng": 19.9519,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Małopolskie",
    "capital": "minor",
    "population": 27490,
    "id": 1616298051
  },
  {
    "city": "East Chicago",
    "city_ascii": "East Chicago",
    "lat": 41.6484,
    "lng": -87.4536,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 27817,
    "id": 1840008193
  },
  {
    "city": "Ofaqim",
    "city_ascii": "Ofaqim",
    "lat": 31.2833,
    "lng": 34.6167,
    "country": "Israel",
    "iso2": "IL",
    "iso3": "ISR",
    "admin_name": "Southern",
    "capital": "",
    "population": 27771,
    "id": 1376992458
  },
  {
    "city": "Awara",
    "city_ascii": "Awara",
    "lat": 36.2114,
    "lng": 136.2289,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Fukui",
    "capital": "",
    "population": 27577,
    "id": 1392157496
  },
  {
    "city": "Kirkwood",
    "city_ascii": "Kirkwood",
    "lat": 38.5788,
    "lng": -90.4203,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 27807,
    "id": 1840008598
  },
  {
    "city": "San Martín Hidalgo",
    "city_ascii": "San Martin Hidalgo",
    "lat": 20.435,
    "lng": -103.9286,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Jalisco",
    "capital": "minor",
    "population": 27777,
    "id": 1484808441
  },
  {
    "city": "Mechernich",
    "city_ascii": "Mechernich",
    "lat": 50.6,
    "lng": 6.65,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 27598,
    "id": 1276281325
  },
  {
    "city": "’Arāba",
    "city_ascii": "'Araba",
    "lat": 32.8511,
    "lng": 35.3386,
    "country": "Israel",
    "iso2": "IL",
    "iso3": "ISR",
    "admin_name": "Northern",
    "capital": "",
    "population": 27000,
    "id": 1376617057
  },
  {
    "city": "Arnstadt",
    "city_ascii": "Arnstadt",
    "lat": 50.8342,
    "lng": 10.9464,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Thuringia",
    "capital": "minor",
    "population": 27304,
    "id": 1276728389
  },
  {
    "city": "Littlehampton",
    "city_ascii": "Littlehampton",
    "lat": 50.8094,
    "lng": -0.5409,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "West Sussex",
    "capital": "",
    "population": 27795,
    "id": 1826096073
  },
  {
    "city": "Havran",
    "city_ascii": "Havran",
    "lat": 39.5583,
    "lng": 27.0983,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Balıkesir",
    "capital": "minor",
    "population": 27741,
    "id": 1792883078
  },
  {
    "city": "Sanger",
    "city_ascii": "Sanger",
    "lat": 36.699,
    "lng": -119.5575,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 27777,
    "id": 1840021566
  },
  {
    "city": "Mananjary",
    "city_ascii": "Mananjary",
    "lat": -21.2167,
    "lng": 48.3333,
    "country": "Madagascar",
    "iso2": "MG",
    "iso3": "MDG",
    "admin_name": "Fianarantsoa",
    "capital": "",
    "population": 27686,
    "id": 1450681595
  },
  {
    "city": "Jarrow",
    "city_ascii": "Jarrow",
    "lat": 54.9797,
    "lng": -1.4804,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "South Tyneside",
    "capital": "",
    "population": 27526,
    "id": 1826615056
  },
  {
    "city": "Morgan City",
    "city_ascii": "Morgan City",
    "lat": 29.7041,
    "lng": -91.192,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 27728,
    "id": 1840015056
  },
  {
    "city": "Ramsey",
    "city_ascii": "Ramsey",
    "lat": 45.2617,
    "lng": -93.4494,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 27721,
    "id": 1840008904
  },
  {
    "city": "Ruston",
    "city_ascii": "Ruston",
    "lat": 32.5329,
    "lng": -92.6363,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 27717,
    "id": 1840014878
  },
  {
    "city": "Jeffersontown",
    "city_ascii": "Jeffersontown",
    "lat": 38.2048,
    "lng": -85.5701,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kentucky",
    "capital": "",
    "population": 27715,
    "id": 1840014311
  },
  {
    "city": "Glen Ellyn",
    "city_ascii": "Glen Ellyn",
    "lat": 41.8667,
    "lng": -88.0629,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 27714,
    "id": 1840011405
  },
  {
    "city": "Zarechnyy",
    "city_ascii": "Zarechnyy",
    "lat": 56.8167,
    "lng": 61.3167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sverdlovskaya Oblast’",
    "capital": "",
    "population": 27617,
    "id": 1643142776
  },
  {
    "city": "Bandipura",
    "city_ascii": "Bandipura",
    "lat": 34.4225,
    "lng": 74.6375,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Jammu and Kashmīr",
    "capital": "",
    "population": 27482,
    "id": 1356796191
  },
  {
    "city": "Borşa",
    "city_ascii": "Borsa",
    "lat": 47.6553,
    "lng": 24.6631,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Maramureş",
    "capital": "",
    "population": 27611,
    "id": 1642172332
  },
  {
    "city": "Cañon City",
    "city_ascii": "Canon City",
    "lat": 38.443,
    "lng": -105.2203,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 27676,
    "id": 1840018855
  },
  {
    "city": "Lichtenburg",
    "city_ascii": "Lichtenburg",
    "lat": -26.15,
    "lng": 26.1667,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "North West",
    "capital": "",
    "population": 26338,
    "id": 1710444843
  },
  {
    "city": "Monticello",
    "city_ascii": "Monticello",
    "lat": 45.298,
    "lng": -93.7984,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 27665,
    "id": 1840007795
  },
  {
    "city": "Oktyabrsk",
    "city_ascii": "Oktyabrsk",
    "lat": 53.1667,
    "lng": 48.6667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Samarskaya Oblast’",
    "capital": "",
    "population": 26438,
    "id": 1643865779
  },
  {
    "city": "Ashtabula",
    "city_ascii": "Ashtabula",
    "lat": 41.8806,
    "lng": -80.7984,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 27645,
    "id": 1840007046
  },
  {
    "city": "Wiesloch",
    "city_ascii": "Wiesloch",
    "lat": 49.2942,
    "lng": 8.6983,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 26758,
    "id": 1276473184
  },
  {
    "city": "Ban Nong Sam Rong",
    "city_ascii": "Ban Nong Sam Rong",
    "lat": 17.4514,
    "lng": 102.765,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Udon Thani",
    "capital": "",
    "population": 27354,
    "id": 1764827236
  },
  {
    "city": "Danvers",
    "city_ascii": "Danvers",
    "lat": 42.574,
    "lng": -70.9494,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 27631,
    "id": 1840053528
  },
  {
    "city": "Orosháza",
    "city_ascii": "Oroshaza",
    "lat": 46.5678,
    "lng": 20.6428,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Békés",
    "capital": "minor",
    "population": 27492,
    "id": 1348070535
  },
  {
    "city": "Bridgewater",
    "city_ascii": "Bridgewater",
    "lat": 41.9728,
    "lng": -70.9749,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 27619,
    "id": 1840003199
  },
  {
    "city": "Mint Hill",
    "city_ascii": "Mint Hill",
    "lat": 35.1782,
    "lng": -80.6533,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 27617,
    "id": 1840016351
  },
  {
    "city": "Jarinu",
    "city_ascii": "Jarinu",
    "lat": -23.1014,
    "lng": -46.7283,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 27473,
    "id": 1076915802
  },
  {
    "city": "Neptune",
    "city_ascii": "Neptune",
    "lat": 40.2105,
    "lng": -74.0539,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 27591,
    "id": 1840081642
  },
  {
    "city": "Panauti̇̄",
    "city_ascii": "Panauti",
    "lat": 27.5833,
    "lng": 85.5167,
    "country": "Nepal",
    "iso2": "NP",
    "iso3": "NPL",
    "admin_name": "Bāgmatī",
    "capital": "",
    "population": 27358,
    "id": 1524203755
  },
  {
    "city": "Stratford-upon-Avon",
    "city_ascii": "Stratford-upon-Avon",
    "lat": 52.1928,
    "lng": -1.7064,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Warwickshire",
    "capital": "",
    "population": 27445,
    "id": 1826756514
  },
  {
    "city": "Tongyangdao",
    "city_ascii": "Tongyangdao",
    "lat": 41.7676,
    "lng": 109.9711,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Inner Mongolia",
    "capital": "minor",
    "population": 27476,
    "id": 1156031592
  },
  {
    "city": "Milton",
    "city_ascii": "Milton",
    "lat": 42.2413,
    "lng": -71.0844,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 27568,
    "id": 1840053675
  },
  {
    "city": "Jászberény",
    "city_ascii": "Jaszbereny",
    "lat": 47.5,
    "lng": 19.9167,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Jász-Nagykun-Szolnok",
    "capital": "minor",
    "population": 27439,
    "id": 1348347095
  },
  {
    "city": "Neckarsulm",
    "city_ascii": "Neckarsulm",
    "lat": 49.1917,
    "lng": 9.2244,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 26492,
    "id": 1276644905
  },
  {
    "city": "Geilenkirchen",
    "city_ascii": "Geilenkirchen",
    "lat": 50.9653,
    "lng": 6.1194,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 27214,
    "id": 1276421191
  },
  {
    "city": "Bidur",
    "city_ascii": "Bidur",
    "lat": 27.89,
    "lng": 85.1597,
    "country": "Nepal",
    "iso2": "NP",
    "iso3": "NPL",
    "admin_name": "Bāgmatī",
    "capital": "",
    "population": 26750,
    "id": 1524355084
  },
  {
    "city": "İpsala",
    "city_ascii": "Ipsala",
    "lat": 40.9181,
    "lng": 26.3831,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Edirne",
    "capital": "minor",
    "population": 27498,
    "id": 1792000511
  },
  {
    "city": "Santa Rita do Passa Quatro",
    "city_ascii": "Santa Rita do Passa Quatro",
    "lat": -21.71,
    "lng": -47.4778,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 27502,
    "id": 1076791348
  },
  {
    "city": "Ashford",
    "city_ascii": "Ashford",
    "lat": 51.434,
    "lng": -0.464,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Surrey",
    "capital": "",
    "population": 27382,
    "id": 1826203695
  },
  {
    "city": "Miramas",
    "city_ascii": "Miramas",
    "lat": 43.5822,
    "lng": 5.0019,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Provence-Alpes-Côte d’Azur",
    "capital": "",
    "population": 26470,
    "id": 1250908076
  },
  {
    "city": "Gawler",
    "city_ascii": "Gawler",
    "lat": -34.5981,
    "lng": 138.745,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "South Australia",
    "capital": "",
    "population": 26472,
    "id": 1036309493
  },
  {
    "city": "Statesville",
    "city_ascii": "Statesville",
    "lat": 35.7842,
    "lng": -80.8714,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 27528,
    "id": 1840015358
  },
  {
    "city": "Kathu",
    "city_ascii": "Kathu",
    "lat": 7.9112,
    "lng": 98.3475,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Phuket",
    "capital": "minor",
    "population": 26078,
    "id": 1764733770
  },
  {
    "city": "Short Pump",
    "city_ascii": "Short Pump",
    "lat": 37.6549,
    "lng": -77.6201,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 27526,
    "id": 1840006394
  },
  {
    "city": "Queensbury",
    "city_ascii": "Queensbury",
    "lat": 43.3568,
    "lng": -73.6765,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 27525,
    "id": 1840058430
  },
  {
    "city": "Verkhniy Ufaley",
    "city_ascii": "Verkhniy Ufaley",
    "lat": 56.05,
    "lng": 60.2333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Chelyabinskaya Oblast’",
    "capital": "",
    "population": 27498,
    "id": 1643894211
  },
  {
    "city": "Sena Madureira",
    "city_ascii": "Sena Madureira",
    "lat": -9.07,
    "lng": -68.67,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Acre",
    "capital": "",
    "population": 26192,
    "id": 1076899075
  },
  {
    "city": "Gloversville",
    "city_ascii": "Gloversville",
    "lat": 43.0491,
    "lng": -74.3465,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 27512,
    "id": 1840000375
  },
  {
    "city": "Santiago de Tolú",
    "city_ascii": "Santiago de Tolu",
    "lat": 9.5358,
    "lng": -75.572,
    "country": "Colombia",
    "iso2": "CO",
    "iso3": "COL",
    "admin_name": "Sucre",
    "capital": "minor",
    "population": 27390,
    "id": 1170666994
  },
  {
    "city": "Summerlin South",
    "city_ascii": "Summerlin South",
    "lat": 36.1242,
    "lng": -115.3324,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Nevada",
    "capital": "",
    "population": 27506,
    "id": 1840074790
  },
  {
    "city": "Chillán Viejo",
    "city_ascii": "Chillan Viejo",
    "lat": -36.6229,
    "lng": -72.1317,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Biobío",
    "capital": "",
    "population": 27359,
    "id": 1152645198
  },
  {
    "city": "Gladstone",
    "city_ascii": "Gladstone",
    "lat": 39.2133,
    "lng": -94.5593,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 27489,
    "id": 1840008496
  },
  {
    "city": "Rambouillet",
    "city_ascii": "Rambouillet",
    "lat": 48.6444,
    "lng": 1.8308,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "minor",
    "population": 26736,
    "id": 1250752872
  },
  {
    "city": "Bielsk Podlaski",
    "city_ascii": "Bielsk Podlaski",
    "lat": 52.7667,
    "lng": 23.2,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Podlaskie",
    "capital": "minor",
    "population": 26493,
    "id": 1616037323
  },
  {
    "city": "Spring Township",
    "city_ascii": "Spring Township",
    "lat": 40.3037,
    "lng": -76.0263,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 27463,
    "id": 1840142060
  },
  {
    "city": "East Grinstead",
    "city_ascii": "East Grinstead",
    "lat": 51.129,
    "lng": -0.007,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "West Sussex",
    "capital": "",
    "population": 26383,
    "id": 1826278903
  },
  {
    "city": "Qorday",
    "city_ascii": "Qorday",
    "lat": 43.0369,
    "lng": 74.7114,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Zhambyl",
    "capital": "",
    "population": 27443,
    "id": 1398717423
  },
  {
    "city": "Fremont",
    "city_ascii": "Fremont",
    "lat": 41.4396,
    "lng": -96.4879,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Nebraska",
    "capital": "",
    "population": 27456,
    "id": 1840003346
  },
  {
    "city": "Laranjal Paulista",
    "city_ascii": "Laranjal Paulista",
    "lat": -23.0506,
    "lng": -47.8376,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 27384,
    "id": 1076357656
  },
  {
    "city": "Rheinbach",
    "city_ascii": "Rheinbach",
    "lat": 50.6256,
    "lng": 6.9491,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 27063,
    "id": 1276000657
  },
  {
    "city": "Garfield Heights",
    "city_ascii": "Garfield Heights",
    "lat": 41.4199,
    "lng": -81.6038,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 27448,
    "id": 1840000602
  },
  {
    "city": "Maryborough",
    "city_ascii": "Maryborough",
    "lat": -25.5375,
    "lng": 152.7019,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Queensland",
    "capital": "",
    "population": 27282,
    "id": 1036205623
  },
  {
    "city": "Seneca",
    "city_ascii": "Seneca",
    "lat": 34.6815,
    "lng": -82.9609,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 27439,
    "id": 1840015506
  },
  {
    "city": "Overath",
    "city_ascii": "Overath",
    "lat": 50.95,
    "lng": 7.3,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 27040,
    "id": 1276053339
  },
  {
    "city": "Zempoala",
    "city_ascii": "Zempoala",
    "lat": 19.9167,
    "lng": -98.6667,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Hidalgo",
    "capital": "minor",
    "population": 27333,
    "id": 1484524155
  },
  {
    "city": "Thatcham",
    "city_ascii": "Thatcham",
    "lat": 51.405,
    "lng": -1.264,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "West Berkshire",
    "capital": "",
    "population": 26217,
    "id": 1826803857
  },
  {
    "city": "Vilyeyka",
    "city_ascii": "Vilyeyka",
    "lat": 54.4833,
    "lng": 26.9167,
    "country": "Belarus",
    "iso2": "BY",
    "iso3": "BLR",
    "admin_name": "Minskaya Voblasts’",
    "capital": "minor",
    "population": 27400,
    "id": 1112405572
  },
  {
    "city": "Klosterneuburg",
    "city_ascii": "Klosterneuburg",
    "lat": 48.3042,
    "lng": 16.3167,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Niederösterreich",
    "capital": "minor",
    "population": 27058,
    "id": 1040735965
  },
  {
    "city": "Whitehall",
    "city_ascii": "Whitehall",
    "lat": 40.6571,
    "lng": -75.5046,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 27404,
    "id": 1840147555
  },
  {
    "city": "Bakhchysaray",
    "city_ascii": "Bakhchysaray",
    "lat": 44.7528,
    "lng": 33.8608,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Krym, Avtonomna Respublika",
    "capital": "minor",
    "population": 27351,
    "id": 1804779344
  },
  {
    "city": "Lansing",
    "city_ascii": "Lansing",
    "lat": 41.5648,
    "lng": -87.5462,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 27402,
    "id": 1840011257
  },
  {
    "city": "Aschersleben",
    "city_ascii": "Aschersleben",
    "lat": 51.75,
    "lng": 11.4667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony-Anhalt",
    "capital": "",
    "population": 27220,
    "id": 1276084397
  },
  {
    "city": "Huaquechula",
    "city_ascii": "Huaquechula",
    "lat": 18.7667,
    "lng": -98.55,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Puebla",
    "capital": "minor",
    "population": 26114,
    "id": 1484461466
  },
  {
    "city": "Millville",
    "city_ascii": "Millville",
    "lat": 39.3903,
    "lng": -75.0561,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 27391,
    "id": 1840001550
  },
  {
    "city": "Monroeville",
    "city_ascii": "Monroeville",
    "lat": 40.4262,
    "lng": -79.7605,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 27380,
    "id": 1840003631
  },
  {
    "city": "Miyanaga",
    "city_ascii": "Miyanaga",
    "lat": 33.7236,
    "lng": 130.6667,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Fukuoka",
    "capital": "",
    "population": 27182,
    "id": 1392770083
  },
  {
    "city": "Tarui",
    "city_ascii": "Tarui",
    "lat": 35.3703,
    "lng": 136.5269,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Gifu",
    "capital": "",
    "population": 26896,
    "id": 1392301201
  },
  {
    "city": "Saint-Constant",
    "city_ascii": "Saint-Constant",
    "lat": 45.37,
    "lng": -73.57,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 27359,
    "id": 1124000054
  },
  {
    "city": "St. Helens",
    "city_ascii": "St. Helens",
    "lat": 45.8571,
    "lng": -122.8164,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 27353,
    "id": 1840021185
  },
  {
    "city": "Lunel",
    "city_ascii": "Lunel",
    "lat": 43.6778,
    "lng": 4.1361,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Occitanie",
    "capital": "",
    "population": 26239,
    "id": 1250801871
  },
  {
    "city": "Biarritz",
    "city_ascii": "Biarritz",
    "lat": 43.48,
    "lng": -1.56,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Nouvelle-Aquitaine",
    "capital": "",
    "population": 25404,
    "id": 1250324713
  },
  {
    "city": "Bergerac",
    "city_ascii": "Bergerac",
    "lat": 44.85,
    "lng": 0.48,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Nouvelle-Aquitaine",
    "capital": "minor",
    "population": 26833,
    "id": 1250057635
  },
  {
    "city": "Andenne",
    "city_ascii": "Andenne",
    "lat": 50.4833,
    "lng": 5.1,
    "country": "Belgium",
    "iso2": "BE",
    "iso3": "BEL",
    "admin_name": "Wallonia",
    "capital": "",
    "population": 27017,
    "id": 1056666375
  },
  {
    "city": "Paragould",
    "city_ascii": "Paragould",
    "lat": 36.0555,
    "lng": -90.5149,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arkansas",
    "capital": "",
    "population": 27328,
    "id": 1840014474
  },
  {
    "city": "Pemberton",
    "city_ascii": "Pemberton",
    "lat": 39.9562,
    "lng": -74.6,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 27327,
    "id": 1840001480
  },
  {
    "city": "Gonesse",
    "city_ascii": "Gonesse",
    "lat": 48.9875,
    "lng": 2.4494,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 25999,
    "id": 1250682314
  },
  {
    "city": "Atitalaquia",
    "city_ascii": "Atitalaquia",
    "lat": 20.0583,
    "lng": -99.2208,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Hidalgo",
    "capital": "",
    "population": 26904,
    "id": 1484827281
  },
  {
    "city": "Shingū",
    "city_ascii": "Shingu",
    "lat": 33.7167,
    "lng": 136,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Wakayama",
    "capital": "",
    "population": 27214,
    "id": 1392301953
  },
  {
    "city": "Grimsby",
    "city_ascii": "Grimsby",
    "lat": 43.2,
    "lng": -79.55,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 27314,
    "id": 1124989517
  },
  {
    "city": "Tepehuacán de Guerrero",
    "city_ascii": "Tepehuacan de Guerrero",
    "lat": 21.0131,
    "lng": -98.8442,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Hidalgo",
    "capital": "",
    "population": 27240,
    "id": 1484592101
  },
  {
    "city": "Grodzisk Mazowiecki",
    "city_ascii": "Grodzisk Mazowiecki",
    "lat": 52.1039,
    "lng": 20.6337,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Mazowieckie",
    "capital": "minor",
    "population": 26881,
    "id": 1616204400
  },
  {
    "city": "East Windsor",
    "city_ascii": "East Windsor",
    "lat": 40.2606,
    "lng": -74.5295,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 27294,
    "id": 1840081654
  },
  {
    "city": "Heiligenhaus",
    "city_ascii": "Heiligenhaus",
    "lat": 51.3265,
    "lng": 6.971,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 26335,
    "id": 1276630818
  },
  {
    "city": "Hénin-Beaumont",
    "city_ascii": "Henin-Beaumont",
    "lat": 50.4217,
    "lng": 2.9508,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Hauts-de-France",
    "capital": "",
    "population": 26022,
    "id": 1250614137
  },
  {
    "city": "Conchal",
    "city_ascii": "Conchal",
    "lat": -22.33,
    "lng": -47.1728,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 27132,
    "id": 1076034589
  },
  {
    "city": "Deer Park",
    "city_ascii": "Deer Park",
    "lat": 40.7623,
    "lng": -73.3219,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 27273,
    "id": 1840005034
  },
  {
    "city": "Horn Lake",
    "city_ascii": "Horn Lake",
    "lat": 34.9512,
    "lng": -90.0501,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Mississippi",
    "capital": "",
    "population": 27272,
    "id": 1840014643
  },
  {
    "city": "Pereyaslav-Khmel’nyts’kyy",
    "city_ascii": "Pereyaslav-Khmel'nyts'kyy",
    "lat": 50.0661,
    "lng": 31.4422,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Kyyivs’ka Oblast’",
    "capital": "minor",
    "population": 27088,
    "id": 1804106305
  },
  {
    "city": "Orvault",
    "city_ascii": "Orvault",
    "lat": 47.2717,
    "lng": -1.6225,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Pays de la Loire",
    "capital": "",
    "population": 26355,
    "id": 1250301385
  },
  {
    "city": "Oakleaf Plantation",
    "city_ascii": "Oakleaf Plantation",
    "lat": 30.1689,
    "lng": -81.8337,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 27252,
    "id": 1840039045
  },
  {
    "city": "Estarreja",
    "city_ascii": "Estarreja",
    "lat": 40.75,
    "lng": -8.5667,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Aveiro",
    "capital": "minor",
    "population": 26997,
    "id": 1620391989
  },
  {
    "city": "Prior Lake",
    "city_ascii": "Prior Lake",
    "lat": 44.7251,
    "lng": -93.4409,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 27241,
    "id": 1840008956
  },
  {
    "city": "Gubkinskiy",
    "city_ascii": "Gubkinskiy",
    "lat": 64.4333,
    "lng": 76.5,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Yamalo-Nenetskiy Avtonomnyy Okrug",
    "capital": "",
    "population": 27238,
    "id": 1643018972
  },
  {
    "city": "Caboolture",
    "city_ascii": "Caboolture",
    "lat": -27.0667,
    "lng": 152.967,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Queensland",
    "capital": "",
    "population": 26433,
    "id": 1036273434
  },
  {
    "city": "Huntley",
    "city_ascii": "Huntley",
    "lat": 42.1599,
    "lng": -88.433,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 27228,
    "id": 1840011134
  },
  {
    "city": "Maple Valley",
    "city_ascii": "Maple Valley",
    "lat": 47.3659,
    "lng": -122.0368,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 27202,
    "id": 1840019828
  },
  {
    "city": "New Milford",
    "city_ascii": "New Milford",
    "lat": 41.6043,
    "lng": -73.4213,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 27196,
    "id": 1840035508
  },
  {
    "city": "San Agustín Tlaxiaca",
    "city_ascii": "San Agustin Tlaxiaca",
    "lat": 20.1144,
    "lng": -98.8867,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Hidalgo",
    "capital": "",
    "population": 27118,
    "id": 1484185265
  },
  {
    "city": "Cavaillon",
    "city_ascii": "Cavaillon",
    "lat": 43.8375,
    "lng": 5.0381,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Provence-Alpes-Côte d’Azur",
    "capital": "",
    "population": 26641,
    "id": 1250337002
  },
  {
    "city": "Oak Forest",
    "city_ascii": "Oak Forest",
    "lat": 41.6054,
    "lng": -87.7527,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 27173,
    "id": 1840008132
  },
  {
    "city": "Schloß Holte-Stukenbrock",
    "city_ascii": "Schloss Holte-Stukenbrock",
    "lat": 51.8833,
    "lng": 8.6167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 26776,
    "id": 1276497165
  },
  {
    "city": "Mayskiy",
    "city_ascii": "Mayskiy",
    "lat": 43.6333,
    "lng": 44.0667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kabardino-Balkariya",
    "capital": "minor",
    "population": 27074,
    "id": 1643405638
  },
  {
    "city": "Glen Cove",
    "city_ascii": "Glen Cove",
    "lat": 40.8709,
    "lng": -73.6287,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 27166,
    "id": 1840000987
  },
  {
    "city": "Saumur",
    "city_ascii": "Saumur",
    "lat": 47.26,
    "lng": -0.0769,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Pays de la Loire",
    "capital": "minor",
    "population": 26734,
    "id": 1250940567
  },
  {
    "city": "Wangen im Allgäu",
    "city_ascii": "Wangen im Allgau",
    "lat": 47.6858,
    "lng": 9.8342,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 26905,
    "id": 1276638340
  },
  {
    "city": "Szentes",
    "city_ascii": "Szentes",
    "lat": 46.6519,
    "lng": 20.2572,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Csongrád",
    "capital": "minor",
    "population": 27080,
    "id": 1348319560
  },
  {
    "city": "Colíder",
    "city_ascii": "Colider",
    "lat": -10.8173,
    "lng": -55.4506,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Mato Grosso",
    "capital": "",
    "population": 27139,
    "id": 1076780919
  },
  {
    "city": "West Islip",
    "city_ascii": "West Islip",
    "lat": 40.7097,
    "lng": -73.2971,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 27149,
    "id": 1840005111
  },
  {
    "city": "Sikonge",
    "city_ascii": "Sikonge",
    "lat": -5.6295,
    "lng": 32.77,
    "country": "Tanzania",
    "iso2": "TZ",
    "iso3": "TZA",
    "admin_name": "Tabora",
    "capital": "",
    "population": 27113,
    "id": 1834313836
  },
  {
    "city": "Ōtake",
    "city_ascii": "Otake",
    "lat": 34.2381,
    "lng": 132.2222,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Yamaguchi",
    "capital": "",
    "population": 26796,
    "id": 1392671781
  },
  {
    "city": "Kiskunhalas",
    "city_ascii": "Kiskunhalas",
    "lat": 46.4319,
    "lng": 19.4883,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Bács-Kiskun",
    "capital": "minor",
    "population": 27017,
    "id": 1348184980
  },
  {
    "city": "Shoreview",
    "city_ascii": "Shoreview",
    "lat": 45.0842,
    "lng": -93.1358,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 27130,
    "id": 1840008938
  },
  {
    "city": "Windsor",
    "city_ascii": "Windsor",
    "lat": 38.5417,
    "lng": -122.8086,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 27128,
    "id": 1840022484
  },
  {
    "city": "Sterling",
    "city_ascii": "Sterling",
    "lat": 41.7995,
    "lng": -89.6956,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 27127,
    "id": 1840009200
  },
  {
    "city": "Bernards",
    "city_ascii": "Bernards",
    "lat": 40.6761,
    "lng": -74.5678,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 27114,
    "id": 1840081704
  },
  {
    "city": "Hennigsdorf",
    "city_ascii": "Hennigsdorf",
    "lat": 52.6378,
    "lng": 13.2036,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Brandenburg",
    "capital": "",
    "population": 26272,
    "id": 1276509399
  },
  {
    "city": "Sens",
    "city_ascii": "Sens",
    "lat": 48.1975,
    "lng": 3.2877,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Bourgogne-Franche-Comté",
    "capital": "minor",
    "population": 25935,
    "id": 1250989326
  },
  {
    "city": "Ronse",
    "city_ascii": "Ronse",
    "lat": 50.75,
    "lng": 3.6,
    "country": "Belgium",
    "iso2": "BE",
    "iso3": "BEL",
    "admin_name": "Flanders",
    "capital": "",
    "population": 26337,
    "id": 1056897548
  },
  {
    "city": "Tlaxcoapan",
    "city_ascii": "Tlaxcoapan",
    "lat": 20.0953,
    "lng": -99.22,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Hidalgo",
    "capital": "",
    "population": 26758,
    "id": 1484883241
  },
  {
    "city": "Sol’-Iletsk",
    "city_ascii": "Sol'-Iletsk",
    "lat": 51.1667,
    "lng": 54.9833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Orenburgskaya Oblast’",
    "capital": "",
    "population": 27085,
    "id": 1643001542
  },
  {
    "city": "Colleyville",
    "city_ascii": "Colleyville",
    "lat": 32.8913,
    "lng": -97.1486,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 27091,
    "id": 1840019430
  },
  {
    "city": "Wilmette",
    "city_ascii": "Wilmette",
    "lat": 42.0771,
    "lng": -87.7282,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 27089,
    "id": 1840011316
  },
  {
    "city": "Plum",
    "city_ascii": "Plum",
    "lat": 40.5024,
    "lng": -79.7496,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 27087,
    "id": 1840003632
  },
  {
    "city": "Mount Pleasant",
    "city_ascii": "Mount Pleasant",
    "lat": 42.7129,
    "lng": -87.8875,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 27082,
    "id": 1840038102
  },
  {
    "city": "Libenge",
    "city_ascii": "Libenge",
    "lat": 3.6604,
    "lng": 18.62,
    "country": "Congo (Kinshasa)",
    "iso2": "CD",
    "iso3": "COD",
    "admin_name": "Sud-Ubangi",
    "capital": "",
    "population": 27053,
    "id": 1180325896
  },
  {
    "city": "Dodge City",
    "city_ascii": "Dodge City",
    "lat": 37.761,
    "lng": -100.0183,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kansas",
    "capital": "",
    "population": 27080,
    "id": 1840001684
  },
  {
    "city": "Stafford",
    "city_ascii": "Stafford",
    "lat": 39.7049,
    "lng": -74.2643,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 27073,
    "id": 1840081600
  },
  {
    "city": "Pāmidi",
    "city_ascii": "Pamidi",
    "lat": 14.95,
    "lng": 77.5833,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Andhra Pradesh",
    "capital": "",
    "population": 26886,
    "id": 1356151467
  },
  {
    "city": "Amatepec",
    "city_ascii": "Amatepec",
    "lat": 18.65,
    "lng": -100.15,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "México",
    "capital": "minor",
    "population": 27026,
    "id": 1484058749
  },
  {
    "city": "Ružomberok",
    "city_ascii": "Ruzomberok",
    "lat": 49.0786,
    "lng": 19.3083,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Žilinský",
    "capital": "minor",
    "population": 26854,
    "id": 1703510980
  },
  {
    "city": "McHenry",
    "city_ascii": "McHenry",
    "lat": 42.3388,
    "lng": -88.2931,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 27061,
    "id": 1840008085
  },
  {
    "city": "Burlington",
    "city_ascii": "Burlington",
    "lat": 42.5022,
    "lng": -71.2027,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 27059,
    "id": 1840053476
  },
  {
    "city": "Lohne",
    "city_ascii": "Lohne",
    "lat": 52.6667,
    "lng": 8.2386,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 26762,
    "id": 1276514079
  },
  {
    "city": "Kazincbarcika",
    "city_ascii": "Kazincbarcika",
    "lat": 48.2531,
    "lng": 20.6456,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Borsod-Abaúj-Zemplén",
    "capital": "minor",
    "population": 26337,
    "id": 1348628122
  },
  {
    "city": "Waterville",
    "city_ascii": "Waterville",
    "lat": 44.5441,
    "lng": -69.6624,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maine",
    "capital": "",
    "population": 27054,
    "id": 1840000305
  },
  {
    "city": "Gradignan",
    "city_ascii": "Gradignan",
    "lat": 44.7725,
    "lng": -0.6156,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Nouvelle-Aquitaine",
    "capital": "",
    "population": 25552,
    "id": 1250206083
  },
  {
    "city": "Wałcz",
    "city_ascii": "Walcz",
    "lat": 53.2667,
    "lng": 16.4667,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Zachodniopomorskie",
    "capital": "minor",
    "population": 26354,
    "id": 1616129049
  },
  {
    "city": "Montbéliard",
    "city_ascii": "Montbeliard",
    "lat": 47.51,
    "lng": 6.8,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Bourgogne-Franche-Comté",
    "capital": "minor",
    "population": 25395,
    "id": 1250390358
  },
  {
    "city": "Norfolk",
    "city_ascii": "Norfolk",
    "lat": 42.0327,
    "lng": -97.4208,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Nebraska",
    "capital": "",
    "population": 27032,
    "id": 1840008136
  },
  {
    "city": "Shaker Heights",
    "city_ascii": "Shaker Heights",
    "lat": 41.4744,
    "lng": -81.5496,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 27027,
    "id": 1840003412
  },
  {
    "city": "Xalatlaco",
    "city_ascii": "Xalatlaco",
    "lat": 19.1811,
    "lng": -99.4164,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "México",
    "capital": "minor",
    "population": 26865,
    "id": 1484277337
  },
  {
    "city": "Teltow",
    "city_ascii": "Teltow",
    "lat": 52.4022,
    "lng": 13.2706,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Brandenburg",
    "capital": "",
    "population": 25825,
    "id": 1276388501
  },
  {
    "city": "Marcos Juárez",
    "city_ascii": "Marcos Juarez",
    "lat": -32.7,
    "lng": -62.1,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Córdoba",
    "capital": "minor",
    "population": 27004,
    "id": 1032677394
  },
  {
    "city": "New Windsor",
    "city_ascii": "New Windsor",
    "lat": 41.4742,
    "lng": -74.1089,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 27012,
    "id": 1840058348
  },
  {
    "city": "Erie",
    "city_ascii": "Erie",
    "lat": 40.0403,
    "lng": -105.0398,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 27003,
    "id": 1840021362
  },
  {
    "city": "Brownsburg",
    "city_ascii": "Brownsburg",
    "lat": 39.833,
    "lng": -86.3824,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 27001,
    "id": 1840009536
  },
  {
    "city": "Chaska",
    "city_ascii": "Chaska",
    "lat": 44.8164,
    "lng": -93.6092,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 26989,
    "id": 1840006760
  },
  {
    "city": "Aripuanã",
    "city_ascii": "Aripuana",
    "lat": -9.17,
    "lng": -60.64,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Mato Grosso",
    "capital": "",
    "population": 26983,
    "id": 1076078271
  },
  {
    "city": "Strausberg",
    "city_ascii": "Strausberg",
    "lat": 52.5808,
    "lng": 13.8814,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Brandenburg",
    "capital": "",
    "population": 26587,
    "id": 1276019110
  },
  {
    "city": "Shelby",
    "city_ascii": "Shelby",
    "lat": 35.2904,
    "lng": -81.5451,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 26973,
    "id": 1840015426
  },
  {
    "city": "Karpinsk",
    "city_ascii": "Karpinsk",
    "lat": 59.7667,
    "lng": 60,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sverdlovskaya Oblast’",
    "capital": "",
    "population": 26957,
    "id": 1643966398
  },
  {
    "city": "Uyuni",
    "city_ascii": "Uyuni",
    "lat": -20.4627,
    "lng": -66.824,
    "country": "Bolivia",
    "iso2": "BO",
    "iso3": "BOL",
    "admin_name": "Potosí",
    "capital": "",
    "population": 26958,
    "id": 1068695031
  },
  {
    "city": "Wakefield",
    "city_ascii": "Wakefield",
    "lat": 42.5035,
    "lng": -71.0656,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 26960,
    "id": 1840053641
  },
  {
    "city": "Lauf",
    "city_ascii": "Lauf",
    "lat": 49.5103,
    "lng": 11.2772,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "minor",
    "population": 26515,
    "id": 1276202337
  },
  {
    "city": "Léo",
    "city_ascii": "Leo",
    "lat": 11.094,
    "lng": -2.098,
    "country": "Burkina Faso",
    "iso2": "BF",
    "iso3": "BFA",
    "admin_name": "Centre-Ouest",
    "capital": "minor",
    "population": 26884,
    "id": 1854225723
  },
  {
    "city": "Maryland Heights",
    "city_ascii": "Maryland Heights",
    "lat": 38.7189,
    "lng": -90.4749,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 26956,
    "id": 1840008591
  },
  {
    "city": "Kamyshlov",
    "city_ascii": "Kamyshlov",
    "lat": 56.85,
    "lng": 62.7167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sverdlovskaya Oblast’",
    "capital": "",
    "population": 26444,
    "id": 1643094029
  },
  {
    "city": "Magna",
    "city_ascii": "Magna",
    "lat": 40.7634,
    "lng": -112.1599,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Utah",
    "capital": "",
    "population": 26949,
    "id": 1840017499
  },
  {
    "city": "Xenia",
    "city_ascii": "Xenia",
    "lat": 39.6828,
    "lng": -83.9414,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 26947,
    "id": 1840003790
  },
  {
    "city": "Allen Park",
    "city_ascii": "Allen Park",
    "lat": 42.2595,
    "lng": -83.2107,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 26940,
    "id": 1840003967
  },
  {
    "city": "Khānābād",
    "city_ascii": "Khanabad",
    "lat": 36.6831,
    "lng": 69.1636,
    "country": "Afghanistan",
    "iso2": "AF",
    "iso3": "AFG",
    "admin_name": "Kunduz",
    "capital": "minor",
    "population": 26803,
    "id": 1004931539
  },
  {
    "city": "New Lenox",
    "city_ascii": "New Lenox",
    "lat": 41.5097,
    "lng": -87.97,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 26926,
    "id": 1840011493
  },
  {
    "city": "Jaltenco",
    "city_ascii": "Jaltenco",
    "lat": 19.7511,
    "lng": -99.0931,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "México",
    "capital": "minor",
    "population": 26359,
    "id": 1484504076
  },
  {
    "city": "Pınarbaşı",
    "city_ascii": "Pinarbasi",
    "lat": 38.722,
    "lng": 36.391,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Kayseri",
    "capital": "minor",
    "population": 26911,
    "id": 1792740700
  },
  {
    "city": "Hanahan",
    "city_ascii": "Hanahan",
    "lat": 32.9302,
    "lng": -80.0027,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 26917,
    "id": 1840014256
  },
  {
    "city": "Karasuk",
    "city_ascii": "Karasuk",
    "lat": 53.7333,
    "lng": 78.0333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Novosibirskaya Oblast’",
    "capital": "",
    "population": 26902,
    "id": 1643416504
  },
  {
    "city": "Douglas",
    "city_ascii": "Douglas",
    "lat": 54.15,
    "lng": -4.4819,
    "country": "Isle Of Man",
    "iso2": "IM",
    "iso3": "IMN",
    "admin_name": "",
    "capital": "",
    "population": 25000,
    "id": 1833688345
  },
  {
    "city": "Hamminkeln",
    "city_ascii": "Hamminkeln",
    "lat": 51.7319,
    "lng": 6.5908,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 26739,
    "id": 1276465671
  },
  {
    "city": "Kinston",
    "city_ascii": "Kinston",
    "lat": 35.2748,
    "lng": -77.5936,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 26889,
    "id": 1840014585
  },
  {
    "city": "Searcy",
    "city_ascii": "Searcy",
    "lat": 35.2418,
    "lng": -91.7351,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arkansas",
    "capital": "",
    "population": 26889,
    "id": 1840015429
  },
  {
    "city": "Saikaichō-kobagō",
    "city_ascii": "Saikaicho-kobago",
    "lat": 32.9331,
    "lng": 129.6431,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Nagasaki",
    "capital": "",
    "population": 26777,
    "id": 1392114454
  },
  {
    "city": "Fern Down",
    "city_ascii": "Fern Down",
    "lat": 50.81,
    "lng": -1.9,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Dorset",
    "capital": "",
    "population": 26559,
    "id": 1826033048
  },
  {
    "city": "Boisbriand",
    "city_ascii": "Boisbriand",
    "lat": 45.62,
    "lng": -73.83,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 26884,
    "id": 1124001940
  },
  {
    "city": "Brzozów",
    "city_ascii": "Brzozow",
    "lat": 49.6953,
    "lng": 22.0194,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Podkarpackie",
    "capital": "minor",
    "population": 26615,
    "id": 1616772088
  },
  {
    "city": "Pacasmayo",
    "city_ascii": "Pacasmayo",
    "lat": -7.4003,
    "lng": -79.57,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "La Libertad",
    "capital": "",
    "population": 26125,
    "id": 1604459740
  },
  {
    "city": "Doctor Mora",
    "city_ascii": "Doctor Mora",
    "lat": 21.1425,
    "lng": -100.3192,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Guanajuato",
    "capital": "minor",
    "population": 24976,
    "id": 1484329164
  },
  {
    "city": "New London",
    "city_ascii": "New London",
    "lat": 41.3502,
    "lng": -72.1023,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 26858,
    "id": 1840004827
  },
  {
    "city": "Lamego",
    "city_ascii": "Lamego",
    "lat": 41.0833,
    "lng": -7.8667,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Viseu",
    "capital": "minor",
    "population": 26691,
    "id": 1620221228
  },
  {
    "city": "Bafoulabé",
    "city_ascii": "Bafoulabe",
    "lat": 13.8064,
    "lng": -10.8322,
    "country": "Mali",
    "iso2": "ML",
    "iso3": "MLI",
    "admin_name": "Kayes",
    "capital": "minor",
    "population": 26823,
    "id": 1466740370
  },
  {
    "city": "Ocean",
    "city_ascii": "Ocean",
    "lat": 40.2519,
    "lng": -74.0392,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 26821,
    "id": 1840056382
  },
  {
    "city": "Dix Hills",
    "city_ascii": "Dix Hills",
    "lat": 40.8035,
    "lng": -73.337,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 26819,
    "id": 1840005035
  },
  {
    "city": "West Chicago",
    "city_ascii": "West Chicago",
    "lat": 41.896,
    "lng": -88.2253,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 26816,
    "id": 1840010165
  },
  {
    "city": "Dyatkovo",
    "city_ascii": "Dyatkovo",
    "lat": 53.6,
    "lng": 34.3333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Bryanskaya Oblast’",
    "capital": "",
    "population": 26775,
    "id": 1643789328
  },
  {
    "city": "Springettsbury",
    "city_ascii": "Springettsbury",
    "lat": 39.9907,
    "lng": -76.6736,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 26815,
    "id": 1840147114
  },
  {
    "city": "Novoaleksandrovsk",
    "city_ascii": "Novoaleksandrovsk",
    "lat": 45.4933,
    "lng": 41.2183,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Stavropol’skiy Kray",
    "capital": "",
    "population": 26761,
    "id": 1643490548
  },
  {
    "city": "Saint-Dizier",
    "city_ascii": "Saint-Dizier",
    "lat": 48.6383,
    "lng": 4.9497,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Grand Est",
    "capital": "minor",
    "population": 26300,
    "id": 1250642464
  },
  {
    "city": "La Garde",
    "city_ascii": "La Garde",
    "lat": 43.1256,
    "lng": 6.0108,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Provence-Alpes-Côte d’Azur",
    "capital": "",
    "population": 25126,
    "id": 1250097779
  },
  {
    "city": "Obertshausen",
    "city_ascii": "Obertshausen",
    "lat": 50.0715,
    "lng": 8.8482,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 24943,
    "id": 1276455006
  },
  {
    "city": "Semiluki",
    "city_ascii": "Semiluki",
    "lat": 51.6833,
    "lng": 39.0333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Voronezhskaya Oblast’",
    "capital": "",
    "population": 26732,
    "id": 1643299650
  },
  {
    "city": "Immokalee",
    "city_ascii": "Immokalee",
    "lat": 26.4253,
    "lng": -81.4251,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 26773,
    "id": 1840014230
  },
  {
    "city": "McDonough",
    "city_ascii": "McDonough",
    "lat": 33.4399,
    "lng": -84.1509,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 26768,
    "id": 1840015706
  },
  {
    "city": "Šumperk",
    "city_ascii": "Sumperk",
    "lat": 49.9653,
    "lng": 16.9707,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Olomoucký Kraj",
    "capital": "",
    "population": 25836,
    "id": 1203170809
  },
  {
    "city": "Montreux",
    "city_ascii": "Montreux",
    "lat": 46.4333,
    "lng": 6.9167,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Vaud",
    "capital": "",
    "population": 25984,
    "id": 1756041272
  },
  {
    "city": "Piracaia",
    "city_ascii": "Piracaia",
    "lat": -23.0539,
    "lng": -46.3581,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 26688,
    "id": 1076072528
  },
  {
    "city": "Narasannapeta",
    "city_ascii": "Narasannapeta",
    "lat": 18.4151,
    "lng": 84.0447,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Andhra Pradesh",
    "capital": "",
    "population": 26280,
    "id": 1356526261
  },
  {
    "city": "Polysayevo",
    "city_ascii": "Polysayevo",
    "lat": 54.6,
    "lng": 86.2833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kemerovskaya Oblast’",
    "capital": "",
    "population": 26212,
    "id": 1643007659
  },
  {
    "city": "Bayonet Point",
    "city_ascii": "Bayonet Point",
    "lat": 28.3254,
    "lng": -82.6834,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 26740,
    "id": 1840014117
  },
  {
    "city": "West Linn",
    "city_ascii": "West Linn",
    "lat": 45.3669,
    "lng": -122.6399,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 26736,
    "id": 1840021218
  },
  {
    "city": "Weiterstadt",
    "city_ascii": "Weiterstadt",
    "lat": 49.9,
    "lng": 8.6,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 25975,
    "id": 1276959186
  },
  {
    "city": "Cortland",
    "city_ascii": "Cortland",
    "lat": 42.6004,
    "lng": -76.1784,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 26729,
    "id": 1840000419
  },
  {
    "city": "Lemoore",
    "city_ascii": "Lemoore",
    "lat": 36.2949,
    "lng": -119.7983,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 26725,
    "id": 1840020383
  },
  {
    "city": "Alvin",
    "city_ascii": "Alvin",
    "lat": 29.3871,
    "lng": -95.2933,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 26723,
    "id": 1840019671
  },
  {
    "city": "Bushey",
    "city_ascii": "Bushey",
    "lat": 51.6429,
    "lng": -0.3604,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hertfordshire",
    "capital": "",
    "population": 25763,
    "id": 1826690246
  },
  {
    "city": "Sarubetsu",
    "city_ascii": "Sarubetsu",
    "lat": 42.9083,
    "lng": 143.3561,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hokkaidō",
    "capital": "",
    "population": 26637,
    "id": 1392645682
  },
  {
    "city": "Kirzhach",
    "city_ascii": "Kirzhach",
    "lat": 56.15,
    "lng": 38.8667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Vladimirskaya Oblast’",
    "capital": "",
    "population": 26676,
    "id": 1643096892
  },
  {
    "city": "Temescal Valley",
    "city_ascii": "Temescal Valley",
    "lat": 33.7581,
    "lng": -117.4677,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 26709,
    "id": 1840043023
  },
  {
    "city": "Tubize",
    "city_ascii": "Tubize",
    "lat": 50.6928,
    "lng": 4.205,
    "country": "Belgium",
    "iso2": "BE",
    "iso3": "BEL",
    "admin_name": "Wallonia",
    "capital": "",
    "population": 25914,
    "id": 1056180721
  },
  {
    "city": "Nova Zagora",
    "city_ascii": "Nova Zagora",
    "lat": 42.4903,
    "lng": 26.0122,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Sliven",
    "capital": "",
    "population": 26660,
    "id": 1100577244
  },
  {
    "city": "Wappinger",
    "city_ascii": "Wappinger",
    "lat": 41.59,
    "lng": -73.8918,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 26699,
    "id": 1840088006
  },
  {
    "city": "Hohen Neuendorf",
    "city_ascii": "Hohen Neuendorf",
    "lat": 52.6667,
    "lng": 13.2831,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Brandenburg",
    "capital": "",
    "population": 26159,
    "id": 1276577032
  },
  {
    "city": "Waynesboro",
    "city_ascii": "Waynesboro",
    "lat": 39.7525,
    "lng": -77.5822,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 26694,
    "id": 1840001415
  },
  {
    "city": "Krasnoarmeysk",
    "city_ascii": "Krasnoarmeysk",
    "lat": 56.1,
    "lng": 38.1333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Moskovskaya Oblast’",
    "capital": "",
    "population": 26519,
    "id": 1643779487
  },
  {
    "city": "Betong",
    "city_ascii": "Betong",
    "lat": 5.7731,
    "lng": 101.0725,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Yala",
    "capital": "minor",
    "population": 26640,
    "id": 1764484131
  },
  {
    "city": "Sosnogorsk",
    "city_ascii": "Sosnogorsk",
    "lat": 63.5833,
    "lng": 53.9333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Komi",
    "capital": "",
    "population": 26670,
    "id": 1643361228
  },
  {
    "city": "Segezha",
    "city_ascii": "Segezha",
    "lat": 63.7415,
    "lng": 34.3222,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kareliya",
    "capital": "",
    "population": 26666,
    "id": 1643971245
  },
  {
    "city": "Lebanon",
    "city_ascii": "Lebanon",
    "lat": 43.6353,
    "lng": -72.2531,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Hampshire",
    "capital": "",
    "population": 26666,
    "id": 1840002192
  },
  {
    "city": "Thomasville",
    "city_ascii": "Thomasville",
    "lat": 35.8813,
    "lng": -80.0807,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 26649,
    "id": 1840015361
  },
  {
    "city": "Ilchester",
    "city_ascii": "Ilchester",
    "lat": 39.2187,
    "lng": -76.7684,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 26647,
    "id": 1840024482
  },
  {
    "city": "Maçka",
    "city_ascii": "Macka",
    "lat": 40.8186,
    "lng": 39.6136,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Trabzon",
    "capital": "minor",
    "population": 26626,
    "id": 1792354178
  },
  {
    "city": "Temple Terrace",
    "city_ascii": "Temple Terrace",
    "lat": 28.0437,
    "lng": -82.3774,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 26639,
    "id": 1840015983
  },
  {
    "city": "Lafayette",
    "city_ascii": "Lafayette",
    "lat": 37.8919,
    "lng": -122.1189,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 26638,
    "id": 1840020284
  },
  {
    "city": "Kunisakimachi-tsurugawa",
    "city_ascii": "Kunisakimachi-tsurugawa",
    "lat": 33.5653,
    "lng": 131.7317,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Ōita",
    "capital": "",
    "population": 26549,
    "id": 1392098659
  },
  {
    "city": "Coulsdon",
    "city_ascii": "Coulsdon",
    "lat": 51.3211,
    "lng": -0.1386,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Croydon",
    "capital": "",
    "population": 25695,
    "id": 1826373748
  },
  {
    "city": "Az Zabadānī",
    "city_ascii": "Az Zabadani",
    "lat": 33.7247,
    "lng": 36.1003,
    "country": "Syria",
    "iso2": "SY",
    "iso3": "SYR",
    "admin_name": "Rīf Dimashq",
    "capital": "minor",
    "population": 26285,
    "id": 1760989247
  },
  {
    "city": "Dalnerechensk",
    "city_ascii": "Dalnerechensk",
    "lat": 45.9333,
    "lng": 133.7333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Primorskiy Kray",
    "capital": "",
    "population": 26378,
    "id": 1643306858
  },
  {
    "city": "Norco",
    "city_ascii": "Norco",
    "lat": 33.9252,
    "lng": -117.5499,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 26604,
    "id": 1840020554
  },
  {
    "city": "Pirapòzinho",
    "city_ascii": "Pirapozinho",
    "lat": -22.2753,
    "lng": -51.5,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 26594,
    "id": 1076099500
  },
  {
    "city": "Kirovsk",
    "city_ascii": "Kirovsk",
    "lat": 67.6142,
    "lng": 33.6717,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Murmanskaya Oblast’",
    "capital": "",
    "population": 26581,
    "id": 1643433043
  },
  {
    "city": "Khā̃dbāri̇̄",
    "city_ascii": "Khadbari",
    "lat": 27.3667,
    "lng": 87.2167,
    "country": "Nepal",
    "iso2": "NP",
    "iso3": "NPL",
    "admin_name": "Kosī",
    "capital": "",
    "population": 26301,
    "id": 1524504106
  },
  {
    "city": "Bolkhov",
    "city_ascii": "Bolkhov",
    "lat": 53.45,
    "lng": 36,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Orlovskaya Oblast’",
    "capital": "",
    "population": 26570,
    "id": 1643585692
  },
  {
    "city": "Aş Şanamayn",
    "city_ascii": "As Sanamayn",
    "lat": 33.0711,
    "lng": 36.1842,
    "country": "Syria",
    "iso2": "SY",
    "iso3": "SYR",
    "admin_name": "Dar‘ā",
    "capital": "minor",
    "population": 26268,
    "id": 1760865788
  },
  {
    "city": "Shalqar",
    "city_ascii": "Shalqar",
    "lat": 47.8333,
    "lng": 59.6,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Aqtöbe",
    "capital": "",
    "population": 26574,
    "id": 1398440872
  },
  {
    "city": "Hermanus",
    "city_ascii": "Hermanus",
    "lat": -34.4167,
    "lng": 19.3,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "Western Cape",
    "capital": "",
    "population": 25153,
    "id": 1710409701
  },
  {
    "city": "Dois Córregos",
    "city_ascii": "Dois Corregos",
    "lat": -22.3661,
    "lng": -48.3803,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 26517,
    "id": 1076640457
  },
  {
    "city": "Ureshinomachi-shimojuku",
    "city_ascii": "Ureshinomachi-shimojuku",
    "lat": 33.1278,
    "lng": 130.06,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Saga",
    "capital": "",
    "population": 26349,
    "id": 1392832096
  },
  {
    "city": "Mühlacker",
    "city_ascii": "Muhlacker",
    "lat": 48.95,
    "lng": 8.8392,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 26076,
    "id": 1276456440
  },
  {
    "city": "Severouralsk",
    "city_ascii": "Severouralsk",
    "lat": 60.15,
    "lng": 59.9333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sverdlovskaya Oblast’",
    "capital": "",
    "population": 26543,
    "id": 1643560617
  },
  {
    "city": "San Antonio del Sur",
    "city_ascii": "San Antonio del Sur",
    "lat": 20.0569,
    "lng": -74.8078,
    "country": "Cuba",
    "iso2": "CU",
    "iso3": "CUB",
    "admin_name": "Guantánamo",
    "capital": "minor",
    "population": 26509,
    "id": 1192849948
  },
  {
    "city": "Congleton",
    "city_ascii": "Congleton",
    "lat": 53.162,
    "lng": -2.217,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Cheshire East",
    "capital": "",
    "population": 26482,
    "id": 1826471602
  },
  {
    "city": "La Unión",
    "city_ascii": "La Union",
    "lat": -40.2952,
    "lng": -73.0822,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Los Ríos",
    "capital": "minor",
    "population": 26517,
    "id": 1152649834
  },
  {
    "city": "Traralgon",
    "city_ascii": "Traralgon",
    "lat": -38.1958,
    "lng": 146.5403,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Victoria",
    "capital": "",
    "population": 25485,
    "id": 1036938861
  },
  {
    "city": "Heppenheim",
    "city_ascii": "Heppenheim",
    "lat": 49.6415,
    "lng": 8.645,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "minor",
    "population": 26023,
    "id": 1276763901
  },
  {
    "city": "Vernon Hills",
    "city_ascii": "Vernon Hills",
    "lat": 42.234,
    "lng": -87.9608,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 26521,
    "id": 1840011181
  },
  {
    "city": "Pearl",
    "city_ascii": "Pearl",
    "lat": 32.273,
    "lng": -90.0918,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Mississippi",
    "capital": "",
    "population": 26510,
    "id": 1840014888
  },
  {
    "city": "Horsham",
    "city_ascii": "Horsham",
    "lat": 40.1993,
    "lng": -75.1665,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 26495,
    "id": 1840035022
  },
  {
    "city": "Nordenham",
    "city_ascii": "Nordenham",
    "lat": 53.5,
    "lng": 8.4667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 26193,
    "id": 1276525140
  },
  {
    "city": "Marquette",
    "city_ascii": "Marquette",
    "lat": 46.544,
    "lng": -87.4082,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 26491,
    "id": 1840003923
  },
  {
    "city": "Zirndorf",
    "city_ascii": "Zirndorf",
    "lat": 49.45,
    "lng": 10.95,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 25596,
    "id": 1276906291
  },
  {
    "city": "Ōmachi",
    "city_ascii": "Omachi",
    "lat": 36.5,
    "lng": 137.85,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Nagano",
    "capital": "",
    "population": 26435,
    "id": 1392979595
  },
  {
    "city": "Olteniţa",
    "city_ascii": "Oltenita",
    "lat": 44.0864,
    "lng": 26.6364,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Călăraşi",
    "capital": "",
    "population": 24822,
    "id": 1642326202
  },
  {
    "city": "Citrus Park",
    "city_ascii": "Citrus Park",
    "lat": 28.073,
    "lng": -82.5628,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 26473,
    "id": 1840014152
  },
  {
    "city": "Sedalia",
    "city_ascii": "Sedalia",
    "lat": 38.7042,
    "lng": -93.2351,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 26473,
    "id": 1840009724
  },
  {
    "city": "Bessemer",
    "city_ascii": "Bessemer",
    "lat": 33.3709,
    "lng": -86.9713,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 26472,
    "id": 1840013732
  },
  {
    "city": "Migdal Ha‘Emeq",
    "city_ascii": "Migdal Ha`Emeq",
    "lat": 32.6786,
    "lng": 35.2444,
    "country": "Israel",
    "iso2": "IL",
    "iso3": "ISR",
    "admin_name": "Northern",
    "capital": "",
    "population": 25600,
    "id": 1376279434
  },
  {
    "city": "Butzbach",
    "city_ascii": "Butzbach",
    "lat": 50.4367,
    "lng": 8.6622,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 26197,
    "id": 1276386116
  },
  {
    "city": "Selm",
    "city_ascii": "Selm",
    "lat": 51.6833,
    "lng": 7.4833,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 26011,
    "id": 1276238006
  },
  {
    "city": "Uherské Hradiště",
    "city_ascii": "Uherske Hradiste",
    "lat": 49.0698,
    "lng": 17.4597,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Zlínský Kraj",
    "capital": "",
    "population": 25247,
    "id": 1203165664
  },
  {
    "city": "Oldbury",
    "city_ascii": "Oldbury",
    "lat": 52.505,
    "lng": -2.0159,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Sandwell",
    "capital": "",
    "population": 25488,
    "id": 1826772887
  },
  {
    "city": "Aurillac",
    "city_ascii": "Aurillac",
    "lat": 44.9261,
    "lng": 2.4406,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Auvergne-Rhône-Alpes",
    "capital": "minor",
    "population": 25499,
    "id": 1250648062
  },
  {
    "city": "Vsetín",
    "city_ascii": "Vsetin",
    "lat": 49.3387,
    "lng": 17.9962,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Zlínský Kraj",
    "capital": "",
    "population": 25974,
    "id": 1203549413
  },
  {
    "city": "Sangerhausen",
    "city_ascii": "Sangerhausen",
    "lat": 51.4667,
    "lng": 11.3,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony-Anhalt",
    "capital": "minor",
    "population": 26297,
    "id": 1276588418
  },
  {
    "city": "New Milton",
    "city_ascii": "New Milton",
    "lat": 50.76,
    "lng": -1.65,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hampshire",
    "capital": "",
    "population": 25717,
    "id": 1826998764
  },
  {
    "city": "Batavia",
    "city_ascii": "Batavia",
    "lat": 41.8479,
    "lng": -88.311,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 26420,
    "id": 1840007035
  },
  {
    "city": "Lower Providence",
    "city_ascii": "Lower Providence",
    "lat": 40.1485,
    "lng": -75.4267,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 26418,
    "id": 1840142132
  },
  {
    "city": "Topoľčany",
    "city_ascii": "Topol'cany",
    "lat": 48.55,
    "lng": 18.1833,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Nitriansky",
    "capital": "minor",
    "population": 25492,
    "id": 1703972339
  },
  {
    "city": "Kirovsk",
    "city_ascii": "Kirovsk",
    "lat": 59.8667,
    "lng": 30.9833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Leningradskaya Oblast’",
    "capital": "",
    "population": 26387,
    "id": 1643936101
  },
  {
    "city": "West Milford",
    "city_ascii": "West Milford",
    "lat": 41.1062,
    "lng": -74.3913,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 26409,
    "id": 1840081809
  },
  {
    "city": "Ocuilan de Arteaga",
    "city_ascii": "Ocuilan de Arteaga",
    "lat": 19,
    "lng": -99.4,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "México",
    "capital": "minor",
    "population": 26332,
    "id": 1484570544
  },
  {
    "city": "Garden City",
    "city_ascii": "Garden City",
    "lat": 42.3244,
    "lng": -83.3412,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 26408,
    "id": 1840003974
  },
  {
    "city": "Ehingen an der Donau",
    "city_ascii": "Ehingen an der Donau",
    "lat": 48.2833,
    "lng": 9.7236,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 26259,
    "id": 1276432627
  },
  {
    "city": "Chanhassen",
    "city_ascii": "Chanhassen",
    "lat": 44.8544,
    "lng": -93.5621,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 26389,
    "id": 1840006759
  },
  {
    "city": "Ilha Solteira",
    "city_ascii": "Ilha Solteira",
    "lat": -20.4272,
    "lng": -51.3436,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 26344,
    "id": 1076272168
  },
  {
    "city": "Daventry",
    "city_ascii": "Daventry",
    "lat": 52.26,
    "lng": -1.16,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Northamptonshire",
    "capital": "",
    "population": 25026,
    "id": 1826794956
  },
  {
    "city": "Frauenfeld",
    "city_ascii": "Frauenfeld",
    "lat": 47.5558,
    "lng": 8.8964,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Thurgau",
    "capital": "",
    "population": 25442,
    "id": 1756399413
  },
  {
    "city": "Cabot",
    "city_ascii": "Cabot",
    "lat": 34.9768,
    "lng": -92.0274,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arkansas",
    "capital": "",
    "population": 26352,
    "id": 1840013530
  },
  {
    "city": "Bad Honnef am Rhein",
    "city_ascii": "Bad Honnef am Rhein",
    "lat": 50.645,
    "lng": 7.2269,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 25816,
    "id": 1276512885
  },
  {
    "city": "Pukekohe East",
    "city_ascii": "Pukekohe East",
    "lat": -37.195,
    "lng": 174.9481,
    "country": "New Zealand",
    "iso2": "NZ",
    "iso3": "NZL",
    "admin_name": "Auckland",
    "capital": "",
    "population": 26300,
    "id": 1554599644
  },
  {
    "city": "Ludwigsfelde",
    "city_ascii": "Ludwigsfelde",
    "lat": 52.2997,
    "lng": 13.2667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Brandenburg",
    "capital": "",
    "population": 26112,
    "id": 1276940684
  },
  {
    "city": "Kalfou",
    "city_ascii": "Kalfou",
    "lat": 10.284,
    "lng": 14.9298,
    "country": "Cameroon",
    "iso2": "CM",
    "iso3": "CMR",
    "admin_name": "Extrême-Nord",
    "capital": "",
    "population": 26223,
    "id": 1120297396
  },
  {
    "city": "Vicksburg",
    "city_ascii": "Vicksburg",
    "lat": 32.3173,
    "lng": -90.8868,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Mississippi",
    "capital": "",
    "population": 26340,
    "id": 1840015802
  },
  {
    "city": "Karoi",
    "city_ascii": "Karoi",
    "lat": -16.8196,
    "lng": 29.68,
    "country": "Zimbabwe",
    "iso2": "ZW",
    "iso3": "ZWE",
    "admin_name": "Mashonaland West",
    "capital": "",
    "population": 25030,
    "id": 1716627056
  },
  {
    "city": "Bingen am Rhein",
    "city_ascii": "Bingen am Rhein",
    "lat": 49.9669,
    "lng": 7.895,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Rhineland-Palatinate",
    "capital": "",
    "population": 25659,
    "id": 1276879617
  },
  {
    "city": "Kluczbork",
    "city_ascii": "Kluczbork",
    "lat": 50.9833,
    "lng": 18.2167,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Opolskie",
    "capital": "minor",
    "population": 26164,
    "id": 1616259508
  },
  {
    "city": "Mahwah",
    "city_ascii": "Mahwah",
    "lat": 41.0816,
    "lng": -74.1856,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 26325,
    "id": 1840081806
  },
  {
    "city": "Schleswig",
    "city_ascii": "Schleswig",
    "lat": 54.5153,
    "lng": 9.5697,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Schleswig-Holstein",
    "capital": "minor",
    "population": 25276,
    "id": 1276884755
  },
  {
    "city": "Ayorou",
    "city_ascii": "Ayorou",
    "lat": 14.7318,
    "lng": 0.9195,
    "country": "Niger",
    "iso2": "NE",
    "iso3": "NER",
    "admin_name": "Tillabéri",
    "capital": "",
    "population": 26290,
    "id": 1562787330
  },
  {
    "city": "Farafangana",
    "city_ascii": "Farafangana",
    "lat": -22.8166,
    "lng": 47.8332,
    "country": "Madagascar",
    "iso2": "MG",
    "iso3": "MDG",
    "admin_name": "Fianarantsoa",
    "capital": "minor",
    "population": 24764,
    "id": 1450306255
  },
  {
    "city": "Brawley",
    "city_ascii": "Brawley",
    "lat": 32.9783,
    "lng": -115.5287,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 26309,
    "id": 1840019378
  },
  {
    "city": "Bouknadel",
    "city_ascii": "Bouknadel",
    "lat": 34.1245,
    "lng": -6.748,
    "country": "Morocco",
    "iso2": "MA",
    "iso3": "MAR",
    "admin_name": "Rabat-Salé-Kénitra",
    "capital": "",
    "population": 25255,
    "id": 1504368806
  },
  {
    "city": "Geretsried",
    "city_ascii": "Geretsried",
    "lat": 47.8667,
    "lng": 11.4667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 25275,
    "id": 1276737058
  },
  {
    "city": "Neenah",
    "city_ascii": "Neenah",
    "lat": 44.167,
    "lng": -88.4764,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 26300,
    "id": 1840002244
  },
  {
    "city": "Perevalsk",
    "city_ascii": "Perevalsk",
    "lat": 48.4333,
    "lng": 38.8167,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Luhans’ka Oblast’",
    "capital": "minor",
    "population": 26132,
    "id": 1804106401
  },
  {
    "city": "Snoqualmie",
    "city_ascii": "Snoqualmie",
    "lat": 47.5293,
    "lng": -121.8412,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 26289,
    "id": 1840021119
  },
  {
    "city": "Holbrook",
    "city_ascii": "Holbrook",
    "lat": 40.7944,
    "lng": -73.0707,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 26286,
    "id": 1840005078
  },
  {
    "city": "Montemor-o-Velho",
    "city_ascii": "Montemor-o-Velho",
    "lat": 40.1667,
    "lng": -8.6833,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Coimbra",
    "capital": "minor",
    "population": 26171,
    "id": 1620287392
  },
  {
    "city": "Labytnangi",
    "city_ascii": "Labytnangi",
    "lat": 66.65,
    "lng": 66.4,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Yamalo-Nenetskiy Avtonomnyy Okrug",
    "capital": "",
    "population": 26281,
    "id": 1643017504
  },
  {
    "city": "Troy",
    "city_ascii": "Troy",
    "lat": 40.0436,
    "lng": -84.2191,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 26281,
    "id": 1840010508
  },
  {
    "city": "West Odessa",
    "city_ascii": "West Odessa",
    "lat": 31.8388,
    "lng": -102.4996,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 26278,
    "id": 1840037100
  },
  {
    "city": "Hercules",
    "city_ascii": "Hercules",
    "lat": 38.0064,
    "lng": -122.2564,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 26276,
    "id": 1840020276
  },
  {
    "city": "Semīrom",
    "city_ascii": "Semirom",
    "lat": 31.4142,
    "lng": 51.5694,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Eşfahān",
    "capital": "minor",
    "population": 26260,
    "id": 1364089259
  },
  {
    "city": "Lake City",
    "city_ascii": "Lake City",
    "lat": 30.1901,
    "lng": -82.647,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 26271,
    "id": 1840015029
  },
  {
    "city": "Lochearn",
    "city_ascii": "Lochearn",
    "lat": 39.346,
    "lng": -76.7307,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 26271,
    "id": 1840005694
  },
  {
    "city": "Upper Dublin",
    "city_ascii": "Upper Dublin",
    "lat": 40.1502,
    "lng": -75.1813,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 26269,
    "id": 1840152392
  },
  {
    "city": "Bishop Auckland",
    "city_ascii": "Bishop Auckland",
    "lat": 54.6566,
    "lng": -1.6768,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Durham",
    "capital": "",
    "population": 25455,
    "id": 1826422730
  },
  {
    "city": "Wethersfield",
    "city_ascii": "Wethersfield",
    "lat": 41.7013,
    "lng": -72.6703,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 26267,
    "id": 1840035670
  },
  {
    "city": "Paramus",
    "city_ascii": "Paramus",
    "lat": 40.9455,
    "lng": -74.0712,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 26264,
    "id": 1840000916
  },
  {
    "city": "Lindau",
    "city_ascii": "Lindau",
    "lat": 47.5458,
    "lng": 9.6839,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 25490,
    "id": 1276918139
  },
  {
    "city": "Greenville",
    "city_ascii": "Greenville",
    "lat": 33.1116,
    "lng": -96.1098,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 26261,
    "id": 1840020649
  },
  {
    "city": "Elefsína",
    "city_ascii": "Elefsina",
    "lat": 38.0414,
    "lng": 23.5453,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Attikí",
    "capital": "minor",
    "population": 24910,
    "id": 1300591853
  },
  {
    "city": "Okeechobee",
    "city_ascii": "Okeechobee",
    "lat": 27.2414,
    "lng": -80.8298,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 26256,
    "id": 1840015118
  },
  {
    "city": "La Teste-de-Buch",
    "city_ascii": "La Teste-de-Buch",
    "lat": 44.62,
    "lng": -1.1457,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Nouvelle-Aquitaine",
    "capital": "",
    "population": 26078,
    "id": 1250666362
  },
  {
    "city": "Vierzon",
    "city_ascii": "Vierzon",
    "lat": 47.2225,
    "lng": 2.0694,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Centre-Val de Loire",
    "capital": "minor",
    "population": 25903,
    "id": 1250535432
  },
  {
    "city": "Malyn",
    "city_ascii": "Malyn",
    "lat": 50.7689,
    "lng": 29.27,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Zhytomyrs’ka Oblast’",
    "capital": "minor",
    "population": 26204,
    "id": 1804889971
  },
  {
    "city": "Sasovo",
    "city_ascii": "Sasovo",
    "lat": 54.35,
    "lng": 41.9167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Ryazanskaya Oblast’",
    "capital": "",
    "population": 25177,
    "id": 1643401119
  },
  {
    "city": "Świecie",
    "city_ascii": "Swiecie",
    "lat": 53.4167,
    "lng": 18.4333,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Kujawsko-Pomorskie",
    "capital": "minor",
    "population": 26026,
    "id": 1616982457
  },
  {
    "city": "Edgewood",
    "city_ascii": "Edgewood",
    "lat": 39.421,
    "lng": -76.2968,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 26219,
    "id": 1840005661
  },
  {
    "city": "Wilmslow",
    "city_ascii": "Wilmslow",
    "lat": 53.325,
    "lng": -2.239,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Cheshire East",
    "capital": "",
    "population": 24497,
    "id": 1826883084
  },
  {
    "city": "Tavira",
    "city_ascii": "Tavira",
    "lat": 37.1309,
    "lng": -7.6506,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Faro",
    "capital": "minor",
    "population": 26167,
    "id": 1620756467
  },
  {
    "city": "North Kingstown",
    "city_ascii": "North Kingstown",
    "lat": 41.5687,
    "lng": -71.4629,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Rhode Island",
    "capital": "",
    "population": 26207,
    "id": 1840066135
  },
  {
    "city": "Yuanchang",
    "city_ascii": "Yuanchang",
    "lat": 23.642,
    "lng": 120.323,
    "country": "Taiwan",
    "iso2": "TW",
    "iso3": "TWN",
    "admin_name": "Yunlin",
    "capital": "",
    "population": 25840,
    "id": 1158471732
  },
  {
    "city": "Conception Bay South",
    "city_ascii": "Conception Bay South",
    "lat": 47.5167,
    "lng": -52.9833,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Newfoundland and Labrador",
    "capital": "",
    "population": 26199,
    "id": 1124000563
  },
  {
    "city": "Pfaffenhofen",
    "city_ascii": "Pfaffenhofen",
    "lat": 48.5333,
    "lng": 11.5167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "minor",
    "population": 25917,
    "id": 1276259181
  },
  {
    "city": "Kulmbach",
    "city_ascii": "Kulmbach",
    "lat": 50.1,
    "lng": 11.4333,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "minor",
    "population": 25915,
    "id": 1276728110
  },
  {
    "city": "Lice",
    "city_ascii": "Lice",
    "lat": 38.4549,
    "lng": 40.6519,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Diyarbakır",
    "capital": "minor",
    "population": 26163,
    "id": 1792106480
  },
  {
    "city": "Puerto Varas",
    "city_ascii": "Puerto Varas",
    "lat": -41.3178,
    "lng": -72.9827,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Los Lagos",
    "capital": "",
    "population": 26172,
    "id": 1152560692
  },
  {
    "city": "Muscatine",
    "city_ascii": "Muscatine",
    "lat": 41.4196,
    "lng": -91.068,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Iowa",
    "capital": "",
    "population": 26168,
    "id": 1840008202
  },
  {
    "city": "Novopavlovsk",
    "city_ascii": "Novopavlovsk",
    "lat": 43.9636,
    "lng": 43.6394,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Stavropol’skiy Kray",
    "capital": "",
    "population": 26106,
    "id": 1643845073
  },
  {
    "city": "Boone",
    "city_ascii": "Boone",
    "lat": 36.2111,
    "lng": -81.6668,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 26146,
    "id": 1840015310
  },
  {
    "city": "Moscow",
    "city_ascii": "Moscow",
    "lat": 46.7307,
    "lng": -116.9986,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Idaho",
    "capital": "",
    "population": 26145,
    "id": 1840019868
  },
  {
    "city": "Oum Hadjer",
    "city_ascii": "Oum Hadjer",
    "lat": 13.2833,
    "lng": 19.6833,
    "country": "Chad",
    "iso2": "TD",
    "iso3": "TCD",
    "admin_name": "Batha",
    "capital": "",
    "population": 26136,
    "id": 1148641107
  },
  {
    "city": "Libourne",
    "city_ascii": "Libourne",
    "lat": 44.92,
    "lng": -0.24,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Nouvelle-Aquitaine",
    "capital": "minor",
    "population": 24845,
    "id": 1250403589
  },
  {
    "city": "Toktogul",
    "city_ascii": "Toktogul",
    "lat": 41.8826,
    "lng": 72.9372,
    "country": "Kyrgyzstan",
    "iso2": "KG",
    "iso3": "KGZ",
    "admin_name": "Jalal-Abad",
    "capital": "minor",
    "population": 26115,
    "id": 1417791878
  },
  {
    "city": "Santiago Papasquiaro",
    "city_ascii": "Santiago Papasquiaro",
    "lat": 25.0439,
    "lng": -105.4192,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Durango",
    "capital": "minor",
    "population": 26121,
    "id": 1484176278
  },
  {
    "city": "Sebastian",
    "city_ascii": "Sebastian",
    "lat": 27.7882,
    "lng": -80.4813,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 26118,
    "id": 1840015984
  },
  {
    "city": "Northport",
    "city_ascii": "Northport",
    "lat": 33.2589,
    "lng": -87.5984,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 26115,
    "id": 1840014819
  },
  {
    "city": "Helmstedt",
    "city_ascii": "Helmstedt",
    "lat": 52.2281,
    "lng": 11.0106,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "minor",
    "population": 25728,
    "id": 1276360393
  },
  {
    "city": "Mizdah",
    "city_ascii": "Mizdah",
    "lat": 31.4337,
    "lng": 12.9833,
    "country": "Libya",
    "iso2": "LY",
    "iso3": "LBY",
    "admin_name": "Al Jabal al Gharbī",
    "capital": "",
    "population": 26107,
    "id": 1434622990
  },
  {
    "city": "Saint-Bruno-de-Montarville",
    "city_ascii": "Saint-Bruno-de-Montarville",
    "lat": 45.5333,
    "lng": -73.35,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 26107,
    "id": 1124286783
  },
  {
    "city": "Natchez",
    "city_ascii": "Natchez",
    "lat": 31.5437,
    "lng": -91.3867,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Mississippi",
    "capital": "",
    "population": 26095,
    "id": 1840014951
  },
  {
    "city": "Zacualtipán",
    "city_ascii": "Zacualtipan",
    "lat": 20.65,
    "lng": -98.65,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Hidalgo",
    "capital": "",
    "population": 25987,
    "id": 1484806928
  },
  {
    "city": "Sankt Wendel",
    "city_ascii": "Sankt Wendel",
    "lat": 49.4667,
    "lng": 7.1667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saarland",
    "capital": "minor",
    "population": 25862,
    "id": 1276025539
  },
  {
    "city": "Winchester",
    "city_ascii": "Winchester",
    "lat": 38.0017,
    "lng": -84.1907,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kentucky",
    "capital": "",
    "population": 26083,
    "id": 1840015216
  },
  {
    "city": "Odemira",
    "city_ascii": "Odemira",
    "lat": 37.5833,
    "lng": -8.6333,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Beja",
    "capital": "minor",
    "population": 26066,
    "id": 1620717332
  },
  {
    "city": "Itaí",
    "city_ascii": "Itai",
    "lat": -23.4178,
    "lng": -49.0906,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 26042,
    "id": 1076144804
  },
  {
    "city": "Zavodoukovsk",
    "city_ascii": "Zavodoukovsk",
    "lat": 56.5,
    "lng": 66.55,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Tyumenskaya Oblast’",
    "capital": "",
    "population": 26048,
    "id": 1643833832
  },
  {
    "city": "Traun",
    "city_ascii": "Traun",
    "lat": 48.2217,
    "lng": 14.2397,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Oberösterreich",
    "capital": "",
    "population": 24477,
    "id": 1040684469
  },
  {
    "city": "Saintes",
    "city_ascii": "Saintes",
    "lat": 45.7464,
    "lng": -0.6333,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Nouvelle-Aquitaine",
    "capital": "minor",
    "population": 25470,
    "id": 1250181820
  },
  {
    "city": "Kamo",
    "city_ascii": "Kamo",
    "lat": 37.6667,
    "lng": 139.0333,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Niigata",
    "capital": "",
    "population": 25855,
    "id": 1392480203
  },
  {
    "city": "Puerto Píritu",
    "city_ascii": "Puerto Piritu",
    "lat": 10.0667,
    "lng": -65.05,
    "country": "Venezuela",
    "iso2": "VE",
    "iso3": "VEN",
    "admin_name": "Anzoátegui",
    "capital": "minor",
    "population": 25981,
    "id": 1862843143
  },
  {
    "city": "East St. Louis",
    "city_ascii": "East St. Louis",
    "lat": 38.6156,
    "lng": -90.1304,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 26047,
    "id": 1840008629
  },
  {
    "city": "Sachse",
    "city_ascii": "Sachse",
    "lat": 32.9726,
    "lng": -96.5793,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 26046,
    "id": 1840020712
  },
  {
    "city": "Tinipuka",
    "city_ascii": "Tinipuka",
    "lat": -4.5496,
    "lng": 136.89,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Papua",
    "capital": "",
    "population": 26021,
    "id": 1360525797
  },
  {
    "city": "Yamagata",
    "city_ascii": "Yamagata",
    "lat": 35.5061,
    "lng": 136.7814,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Gifu",
    "capital": "",
    "population": 25919,
    "id": 1392860202
  },
  {
    "city": "Friedrichsdorf",
    "city_ascii": "Friedrichsdorf",
    "lat": 50.2569,
    "lng": 8.6418,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 25194,
    "id": 1276577829
  },
  {
    "city": "Diapaga",
    "city_ascii": "Diapaga",
    "lat": 12.077,
    "lng": 1.796,
    "country": "Burkina Faso",
    "iso2": "BF",
    "iso3": "BFA",
    "admin_name": "Est",
    "capital": "minor",
    "population": 26013,
    "id": 1854687079
  },
  {
    "city": "Achern",
    "city_ascii": "Achern",
    "lat": 48.6314,
    "lng": 8.0739,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 25630,
    "id": 1276045216
  },
  {
    "city": "Paris",
    "city_ascii": "Paris",
    "lat": 33.6688,
    "lng": -95.546,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 26010,
    "id": 1840020594
  },
  {
    "city": "Haines City",
    "city_ascii": "Haines City",
    "lat": 28.11,
    "lng": -81.6157,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 26009,
    "id": 1840014136
  },
  {
    "city": "Tōno",
    "city_ascii": "Tono",
    "lat": 39.3275,
    "lng": 141.5336,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Iwate",
    "capital": "",
    "population": 25974,
    "id": 1392078638
  },
  {
    "city": "Mason City",
    "city_ascii": "Mason City",
    "lat": 43.1487,
    "lng": -93.1998,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Iowa",
    "capital": "",
    "population": 26005,
    "id": 1840000380
  },
  {
    "city": "Salamína",
    "city_ascii": "Salamina",
    "lat": 37.9333,
    "lng": 23.5,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Attikí",
    "capital": "minor",
    "population": 25370,
    "id": 1300019459
  },
  {
    "city": "Marshalltown",
    "city_ascii": "Marshalltown",
    "lat": 42.0341,
    "lng": -92.9067,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Iowa",
    "capital": "",
    "population": 25994,
    "id": 1840008112
  },
  {
    "city": "Kurchaloy",
    "city_ascii": "Kurchaloy",
    "lat": 43.2019,
    "lng": 46.0881,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Chechnya",
    "capital": "",
    "population": 25672,
    "id": 1643001462
  },
  {
    "city": "Kimovsk",
    "city_ascii": "Kimovsk",
    "lat": 53.9667,
    "lng": 38.5333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Tul’skaya Oblast’",
    "capital": "",
    "population": 25951,
    "id": 1643483377
  },
  {
    "city": "Montgomery Township",
    "city_ascii": "Montgomery Township",
    "lat": 40.2411,
    "lng": -75.2319,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 25984,
    "id": 1840142129
  },
  {
    "city": "Superior",
    "city_ascii": "Superior",
    "lat": 46.6941,
    "lng": -92.0823,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 25977,
    "id": 1840038034
  },
  {
    "city": "Mansfield",
    "city_ascii": "Mansfield",
    "lat": 41.7892,
    "lng": -72.2287,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 25977,
    "id": 1840045040
  },
  {
    "city": "Timberwood Park",
    "city_ascii": "Timberwood Park",
    "lat": 29.6994,
    "lng": -98.4838,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 25976,
    "id": 1840019651
  },
  {
    "city": "Karlovo",
    "city_ascii": "Karlovo",
    "lat": 42.643,
    "lng": 24.8053,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Plovdiv",
    "capital": "",
    "population": 25919,
    "id": 1100780442
  },
  {
    "city": "Marmara Ereğlisi",
    "city_ascii": "Marmara Ereglisi",
    "lat": 40.9697,
    "lng": 27.9553,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Tekirdağ",
    "capital": "minor",
    "population": 25873,
    "id": 1792522233
  },
  {
    "city": "Pantelimon",
    "city_ascii": "Pantelimon",
    "lat": 44.4528,
    "lng": 26.2036,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Ilfov",
    "capital": "",
    "population": 25596,
    "id": 1642982115
  },
  {
    "city": "Álamos",
    "city_ascii": "Alamos",
    "lat": 27.0275,
    "lng": -108.94,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Sonora",
    "capital": "minor",
    "population": 24493,
    "id": 1484052656
  },
  {
    "city": "Medina",
    "city_ascii": "Medina",
    "lat": 41.1358,
    "lng": -81.8694,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 25956,
    "id": 1840003486
  },
  {
    "city": "Conway",
    "city_ascii": "Conway",
    "lat": 33.8399,
    "lng": -79.0424,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 25956,
    "id": 1840014716
  },
  {
    "city": "Barberton",
    "city_ascii": "Barberton",
    "lat": 41.0094,
    "lng": -81.6038,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 25953,
    "id": 1840000792
  },
  {
    "city": "Española",
    "city_ascii": "Espanola",
    "lat": 36.0041,
    "lng": -106.0669,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Mexico",
    "capital": "",
    "population": 25952,
    "id": 1840020348
  },
  {
    "city": "Koko",
    "city_ascii": "Koko",
    "lat": 11.4232,
    "lng": 4.517,
    "country": "Nigeria",
    "iso2": "NG",
    "iso3": "NGA",
    "admin_name": "Kebbi",
    "capital": "",
    "population": 25792,
    "id": 1566646210
  },
  {
    "city": "Armidale",
    "city_ascii": "Armidale",
    "lat": -30.5,
    "lng": 151.65,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 24504,
    "id": 1036975829
  },
  {
    "city": "San Vicente de Cañete",
    "city_ascii": "San Vicente de Canete",
    "lat": -13.0833,
    "lng": -76.4,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "Lima",
    "capital": "",
    "population": 25829,
    "id": 1604901496
  },
  {
    "city": "Cuajinicuilapa",
    "city_ascii": "Cuajinicuilapa",
    "lat": 16.4717,
    "lng": -98.4153,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Guerrero",
    "capital": "minor",
    "population": 25922,
    "id": 1484450188
  },
  {
    "city": "Newport",
    "city_ascii": "Newport",
    "lat": 50.701,
    "lng": -1.2883,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Isle of Wight",
    "capital": "",
    "population": 25496,
    "id": 1826718687
  },
  {
    "city": "Ishii",
    "city_ascii": "Ishii",
    "lat": 34.0747,
    "lng": 134.4406,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Tokushima",
    "capital": "",
    "population": 25066,
    "id": 1392141128
  },
  {
    "city": "Malaṅgawā",
    "city_ascii": "Malangawa",
    "lat": 26.8667,
    "lng": 85.5667,
    "country": "Nepal",
    "iso2": "NP",
    "iso3": "NPL",
    "admin_name": "Janakpur",
    "capital": "",
    "population": 25102,
    "id": 1524985987
  },
  {
    "city": "Wetzikon",
    "city_ascii": "Wetzikon",
    "lat": 47.3208,
    "lng": 8.7931,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Zürich",
    "capital": "",
    "population": 24452,
    "id": 1756078126
  },
  {
    "city": "Rock Springs",
    "city_ascii": "Rock Springs",
    "lat": 41.5951,
    "lng": -109.2238,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wyoming",
    "capital": "",
    "population": 25913,
    "id": 1840020101
  },
  {
    "city": "Wągrowiec",
    "city_ascii": "Wagrowiec",
    "lat": 52.8,
    "lng": 17.2,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Wielkopolskie",
    "capital": "minor",
    "population": 24529,
    "id": 1616366641
  },
  {
    "city": "Plainview",
    "city_ascii": "Plainview",
    "lat": 40.7832,
    "lng": -73.4732,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 25902,
    "id": 1840005250
  },
  {
    "city": "Neiba",
    "city_ascii": "Neiba",
    "lat": 18.4666,
    "lng": -71.4166,
    "country": "Dominican Republic",
    "iso2": "DO",
    "iso3": "DOM",
    "admin_name": "Enriquillo",
    "capital": "minor",
    "population": 25731,
    "id": 1214562502
  },
  {
    "city": "Mercer Island",
    "city_ascii": "Mercer Island",
    "lat": 47.5661,
    "lng": -122.232,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 25894,
    "id": 1840019830
  },
  {
    "city": "Lübbecke",
    "city_ascii": "Lubbecke",
    "lat": 52.3081,
    "lng": 8.6231,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 25490,
    "id": 1276934438
  },
  {
    "city": "Heber",
    "city_ascii": "Heber",
    "lat": 40.5068,
    "lng": -111.3984,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Utah",
    "capital": "",
    "population": 25877,
    "id": 1840074861
  },
  {
    "city": "White Bear Lake",
    "city_ascii": "White Bear Lake",
    "lat": 45.0657,
    "lng": -93.015,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 25875,
    "id": 1840010005
  },
  {
    "city": "Roth",
    "city_ascii": "Roth",
    "lat": 49.2461,
    "lng": 11.0911,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 25593,
    "id": 1276856142
  },
  {
    "city": "Chester-le-Street",
    "city_ascii": "Chester-le-Street",
    "lat": 54.8594,
    "lng": -1.5699,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Durham",
    "capital": "",
    "population": 24227,
    "id": 1826470602
  },
  {
    "city": "Verl",
    "city_ascii": "Verl",
    "lat": 51.8831,
    "lng": 8.5167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 25498,
    "id": 1276182306
  },
  {
    "city": "Cochrane",
    "city_ascii": "Cochrane",
    "lat": 51.189,
    "lng": -114.467,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Alberta",
    "capital": "",
    "population": 25853,
    "id": 1124952542
  },
  {
    "city": "Blagnac",
    "city_ascii": "Blagnac",
    "lat": 43.6364,
    "lng": 1.3906,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Occitanie",
    "capital": "",
    "population": 24517,
    "id": 1250134186
  },
  {
    "city": "Laurel",
    "city_ascii": "Laurel",
    "lat": 31.6956,
    "lng": -89.1448,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Mississippi",
    "capital": "",
    "population": 25844,
    "id": 1840014945
  },
  {
    "city": "Wall",
    "city_ascii": "Wall",
    "lat": 40.1674,
    "lng": -74.0974,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 25836,
    "id": 1840081643
  },
  {
    "city": "Villanueva de la Serena",
    "city_ascii": "Villanueva de la Serena",
    "lat": 38.9739,
    "lng": -5.8003,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Extremadura",
    "capital": "",
    "population": 25667,
    "id": 1724363816
  },
  {
    "city": "Yany Kapu",
    "city_ascii": "Yany Kapu",
    "lat": 45.9675,
    "lng": 33.8003,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Krym, Avtonomna Respublika",
    "capital": "minor",
    "population": 25769,
    "id": 1804718701
  },
  {
    "city": "Broadstairs",
    "city_ascii": "Broadstairs",
    "lat": 51.3589,
    "lng": 1.4394,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Kent",
    "capital": "",
    "population": 24903,
    "id": 1826167465
  },
  {
    "city": "South Windsor",
    "city_ascii": "South Windsor",
    "lat": 41.8353,
    "lng": -72.5733,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 25823,
    "id": 1840035602
  },
  {
    "city": "Huejotzingo",
    "city_ascii": "Huejotzingo",
    "lat": 19.1594,
    "lng": -98.4073,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Puebla",
    "capital": "minor",
    "population": 25684,
    "id": 1484474577
  },
  {
    "city": "Kireyevsk",
    "city_ascii": "Kireyevsk",
    "lat": 53.9333,
    "lng": 37.9333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Tul’skaya Oblast’",
    "capital": "",
    "population": 25741,
    "id": 1643694623
  },
  {
    "city": "Merrimack",
    "city_ascii": "Merrimack",
    "lat": 42.8547,
    "lng": -71.5188,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Hampshire",
    "capital": "",
    "population": 25815,
    "id": 1840080351
  },
  {
    "city": "Hasköy",
    "city_ascii": "Haskoy",
    "lat": 38.6864,
    "lng": 41.6936,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Muş",
    "capital": "minor",
    "population": 25749,
    "id": 1792439039
  },
  {
    "city": "Velingrad",
    "city_ascii": "Velingrad",
    "lat": 42.0267,
    "lng": 23.991,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Pazardzhik",
    "capital": "minor",
    "population": 25764,
    "id": 1100145292
  },
  {
    "city": "Surgidero de Batabanó",
    "city_ascii": "Surgidero de Batabano",
    "lat": 22.6986,
    "lng": -82.2939,
    "country": "Cuba",
    "iso2": "CU",
    "iso3": "CUB",
    "admin_name": "Mayabeque",
    "capital": "",
    "population": 25664,
    "id": 1192354427
  },
  {
    "city": "Pereira Barreto",
    "city_ascii": "Pereira Barreto",
    "lat": -20.6383,
    "lng": -51.1092,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 25767,
    "id": 1076105088
  },
  {
    "city": "Cave Spring",
    "city_ascii": "Cave Spring",
    "lat": 37.2254,
    "lng": -80.0073,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 25793,
    "id": 1840006440
  },
  {
    "city": "Exeter Township",
    "city_ascii": "Exeter Township",
    "lat": 40.3139,
    "lng": -75.834,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 25788,
    "id": 1840144085
  },
  {
    "city": "Woodstock",
    "city_ascii": "Woodstock",
    "lat": 42.3096,
    "lng": -88.4352,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 25786,
    "id": 1840010107
  },
  {
    "city": "Groß-Gerau",
    "city_ascii": "Gross-Gerau",
    "lat": 49.9214,
    "lng": 8.4818,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "minor",
    "population": 25302,
    "id": 1276722779
  },
  {
    "city": "Zittau",
    "city_ascii": "Zittau",
    "lat": 50.8961,
    "lng": 14.8072,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 25381,
    "id": 1276061456
  },
  {
    "city": "Santa Rosa de Viterbo",
    "city_ascii": "Santa Rosa de Viterbo",
    "lat": -21.4728,
    "lng": -47.3628,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 25666,
    "id": 1076895582
  },
  {
    "city": "Marshfield",
    "city_ascii": "Marshfield",
    "lat": 42.114,
    "lng": -70.715,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 25754,
    "id": 1840053681
  },
  {
    "city": "Green",
    "city_ascii": "Green",
    "lat": 40.9483,
    "lng": -81.4757,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 25752,
    "id": 1840008254
  },
  {
    "city": "Saky",
    "city_ascii": "Saky",
    "lat": 45.1336,
    "lng": 33.5772,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Krym, Avtonomna Respublika",
    "capital": "minor",
    "population": 24885,
    "id": 1804485367
  },
  {
    "city": "Pfungstadt",
    "city_ascii": "Pfungstadt",
    "lat": 49.8056,
    "lng": 8.6044,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 25151,
    "id": 1276568312
  },
  {
    "city": "Oga",
    "city_ascii": "Oga",
    "lat": 39.8867,
    "lng": 139.8478,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Akita",
    "capital": "",
    "population": 25634,
    "id": 1392185256
  },
  {
    "city": "Rinteln",
    "city_ascii": "Rinteln",
    "lat": 52.1906,
    "lng": 9.0814,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 25484,
    "id": 1276137941
  },
  {
    "city": "Golborne",
    "city_ascii": "Golborne",
    "lat": 53.4758,
    "lng": -2.5943,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Wigan",
    "capital": "",
    "population": 24169,
    "id": 1826407882
  },
  {
    "city": "Thetford Mines",
    "city_ascii": "Thetford Mines",
    "lat": 46.1,
    "lng": -71.3,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 25709,
    "id": 1124032181
  },
  {
    "city": "Ditzingen",
    "city_ascii": "Ditzingen",
    "lat": 48.8264,
    "lng": 9.0667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 24883,
    "id": 1276272726
  },
  {
    "city": "Pooler",
    "city_ascii": "Pooler",
    "lat": 32.1043,
    "lng": -81.2568,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 25694,
    "id": 1840014914
  },
  {
    "city": "Lennestadt",
    "city_ascii": "Lennestadt",
    "lat": 51.1236,
    "lng": 8.0681,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 25503,
    "id": 1276165009
  },
  {
    "city": "Balabanovo",
    "city_ascii": "Balabanovo",
    "lat": 55.1833,
    "lng": 36.65,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kaluzhskaya Oblast’",
    "capital": "",
    "population": 25608,
    "id": 1643544515
  },
  {
    "city": "Haysyn",
    "city_ascii": "Haysyn",
    "lat": 48.8094,
    "lng": 29.3906,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Vinnyts’ka Oblast’",
    "capital": "minor",
    "population": 25672,
    "id": 1804988265
  },
  {
    "city": "Siófok",
    "city_ascii": "Siofok",
    "lat": 46.9,
    "lng": 18.05,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Somogy",
    "capital": "minor",
    "population": 25461,
    "id": 1348589938
  },
  {
    "city": "Madison",
    "city_ascii": "Madison",
    "lat": 32.4738,
    "lng": -90.13,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Mississippi",
    "capital": "",
    "population": 25661,
    "id": 1840015782
  },
  {
    "city": "Nesher",
    "city_ascii": "Nesher",
    "lat": 32.7667,
    "lng": 35.05,
    "country": "Israel",
    "iso2": "IL",
    "iso3": "ISR",
    "admin_name": "Haifa",
    "capital": "",
    "population": 23700,
    "id": 1376735055
  },
  {
    "city": "Randolph",
    "city_ascii": "Randolph",
    "lat": 40.8434,
    "lng": -74.5818,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 25651,
    "id": 1840081731
  },
  {
    "city": "Lancaster",
    "city_ascii": "Lancaster",
    "lat": 34.7247,
    "lng": -80.7801,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 25649,
    "id": 1840014630
  },
  {
    "city": "Otradnoye",
    "city_ascii": "Otradnoye",
    "lat": 59.7833,
    "lng": 30.8167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Leningradskaya Oblast’",
    "capital": "",
    "population": 25623,
    "id": 1643443234
  },
  {
    "city": "Jumilla",
    "city_ascii": "Jumilla",
    "lat": 38.4792,
    "lng": -1.325,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Murcia",
    "capital": "",
    "population": 25600,
    "id": 1724151562
  },
  {
    "city": "Rottweil",
    "city_ascii": "Rottweil",
    "lat": 48.1681,
    "lng": 8.6247,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "minor",
    "population": 25274,
    "id": 1276117708
  },
  {
    "city": "Austin",
    "city_ascii": "Austin",
    "lat": 43.6718,
    "lng": -92.9783,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 25626,
    "id": 1840006830
  },
  {
    "city": "Lockport",
    "city_ascii": "Lockport",
    "lat": 41.5904,
    "lng": -88.0292,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 25615,
    "id": 1840009256
  },
  {
    "city": "Franklin",
    "city_ascii": "Franklin",
    "lat": 39.4938,
    "lng": -86.0546,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 25608,
    "id": 1840008460
  },
  {
    "city": "Wiehl",
    "city_ascii": "Wiehl",
    "lat": 50.95,
    "lng": 7.5333,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 25135,
    "id": 1276813101
  },
  {
    "city": "Courtenay",
    "city_ascii": "Courtenay",
    "lat": 49.6878,
    "lng": -124.9944,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "British Columbia",
    "capital": "",
    "population": 25599,
    "id": 1124324905
  },
  {
    "city": "Vynohradiv",
    "city_ascii": "Vynohradiv",
    "lat": 48.1397,
    "lng": 23.0331,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Zakarpats’ka Oblast’",
    "capital": "minor",
    "population": 25403,
    "id": 1804673048
  },
  {
    "city": "Gosforth",
    "city_ascii": "Gosforth",
    "lat": 55.007,
    "lng": -1.623,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Newcastle upon Tyne",
    "capital": "",
    "population": 23620,
    "id": 1826878501
  },
  {
    "city": "South Salt Lake",
    "city_ascii": "South Salt Lake",
    "lat": 40.7056,
    "lng": -111.8986,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Utah",
    "capital": "",
    "population": 25582,
    "id": 1840021386
  },
  {
    "city": "Saint-Ouen-l’Aumône",
    "city_ascii": "Saint-Ouen-l'Aumone",
    "lat": 49.0447,
    "lng": 2.1111,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 23741,
    "id": 1250836014
  },
  {
    "city": "Plettenberg",
    "city_ascii": "Plettenberg",
    "lat": 51.2128,
    "lng": 7.8715,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 25318,
    "id": 1276414700
  },
  {
    "city": "Tarpon Springs",
    "city_ascii": "Tarpon Springs",
    "lat": 28.1493,
    "lng": -82.7623,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 25577,
    "id": 1840015980
  },
  {
    "city": "Étampes",
    "city_ascii": "Etampes",
    "lat": 48.4343,
    "lng": 2.1615,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "minor",
    "population": 25092,
    "id": 1250906889
  },
  {
    "city": "Busselton",
    "city_ascii": "Busselton",
    "lat": -33.6478,
    "lng": 115.3458,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Western Australia",
    "capital": "",
    "population": 25329,
    "id": 1036911902
  },
  {
    "city": "Rudolstadt",
    "city_ascii": "Rudolstadt",
    "lat": 50.7169,
    "lng": 11.3275,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Thuringia",
    "capital": "",
    "population": 25115,
    "id": 1276466405
  },
  {
    "city": "Tougué",
    "city_ascii": "Tougue",
    "lat": 11.44,
    "lng": -11.67,
    "country": "Guinea",
    "iso2": "GN",
    "iso3": "GIN",
    "admin_name": "Labé",
    "capital": "minor",
    "population": 25531,
    "id": 1324920258
  },
  {
    "city": "Staßfurt",
    "city_ascii": "Stassfurt",
    "lat": 51.8667,
    "lng": 11.5667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony-Anhalt",
    "capital": "",
    "population": 25385,
    "id": 1276438491
  },
  {
    "city": "Sevierville",
    "city_ascii": "Sevierville",
    "lat": 35.8873,
    "lng": -83.5677,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 25557,
    "id": 1840015359
  },
  {
    "city": "Bluffton",
    "city_ascii": "Bluffton",
    "lat": 32.2135,
    "lng": -80.9316,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 25557,
    "id": 1840015799
  },
  {
    "city": "Forest Grove",
    "city_ascii": "Forest Grove",
    "lat": 45.5244,
    "lng": -123.1101,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 25553,
    "id": 1840019930
  },
  {
    "city": "Farmington",
    "city_ascii": "Farmington",
    "lat": 41.7288,
    "lng": -72.8407,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 25546,
    "id": 1840034695
  },
  {
    "city": "Holt",
    "city_ascii": "Holt",
    "lat": 42.6416,
    "lng": -84.5307,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 25536,
    "id": 1840004501
  },
  {
    "city": "Yanaul",
    "city_ascii": "Yanaul",
    "lat": 56.2667,
    "lng": 54.9333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Bashkortostan",
    "capital": "",
    "population": 25511,
    "id": 1643998426
  },
  {
    "city": "South Portland",
    "city_ascii": "South Portland",
    "lat": 43.631,
    "lng": -70.2895,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maine",
    "capital": "",
    "population": 25532,
    "id": 1840000328
  },
  {
    "city": "Londonderry",
    "city_ascii": "Londonderry",
    "lat": 42.8796,
    "lng": -71.3873,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Hampshire",
    "capital": "",
    "population": 25529,
    "id": 1840055138
  },
  {
    "city": "Denison",
    "city_ascii": "Denison",
    "lat": 33.7672,
    "lng": -96.5807,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 25529,
    "id": 1840019317
  },
  {
    "city": "Maaseik",
    "city_ascii": "Maaseik",
    "lat": 51.1019,
    "lng": 5.7856,
    "country": "Belgium",
    "iso2": "BE",
    "iso3": "BEL",
    "admin_name": "Flanders",
    "capital": "minor",
    "population": 25201,
    "id": 1056459643
  },
  {
    "city": "Brilon",
    "city_ascii": "Brilon",
    "lat": 51.3956,
    "lng": 8.5678,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 25417,
    "id": 1276723318
  },
  {
    "city": "Białogard",
    "city_ascii": "Bialogard",
    "lat": 54.007,
    "lng": 15.9875,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Zachodniopomorskie",
    "capital": "minor",
    "population": 24570,
    "id": 1616159820
  },
  {
    "city": "Tanabi",
    "city_ascii": "Tanabi",
    "lat": -20.6258,
    "lng": -49.6489,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 25467,
    "id": 1076368132
  },
  {
    "city": "Owatonna",
    "city_ascii": "Owatonna",
    "lat": 44.0914,
    "lng": -93.2304,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 25498,
    "id": 1840008983
  },
  {
    "city": "Korostyshiv",
    "city_ascii": "Korostyshiv",
    "lat": 50.3186,
    "lng": 29.0592,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Zhytomyrs’ka Oblast’",
    "capital": "minor",
    "population": 25445,
    "id": 1804003590
  },
  {
    "city": "Workington",
    "city_ascii": "Workington",
    "lat": 54.6365,
    "lng": -3.5549,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Cumbria",
    "capital": "",
    "population": 25207,
    "id": 1826107196
  },
  {
    "city": "Witham",
    "city_ascii": "Witham",
    "lat": 51.7978,
    "lng": 0.6373,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Essex",
    "capital": "",
    "population": 25353,
    "id": 1826336424
  },
  {
    "city": "Moon",
    "city_ascii": "Moon",
    "lat": 40.5082,
    "lng": -80.2073,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 25464,
    "id": 1840035106
  },
  {
    "city": "Markkleeberg",
    "city_ascii": "Markkleeberg",
    "lat": 51.2778,
    "lng": 12.3833,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 24679,
    "id": 1276805845
  },
  {
    "city": "Laon",
    "city_ascii": "Laon",
    "lat": 49.5639,
    "lng": 3.6244,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Hauts-de-France",
    "capital": "minor",
    "population": 24876,
    "id": 1250552164
  },
  {
    "city": "Inta",
    "city_ascii": "Inta",
    "lat": 66.0398,
    "lng": 60.1315,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Komi",
    "capital": "",
    "population": 25444,
    "id": 1643587482
  },
  {
    "city": "Sun City West",
    "city_ascii": "Sun City West",
    "lat": 33.6693,
    "lng": -112.3575,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 25444,
    "id": 1840019312
  },
  {
    "city": "Freeport City",
    "city_ascii": "Freeport City",
    "lat": 26.5333,
    "lng": -78.7,
    "country": "Bahamas, The",
    "iso2": "BS",
    "iso3": "BHS",
    "admin_name": "City of Freeport",
    "capital": "",
    "population": 25383,
    "id": 1044291614
  },
  {
    "city": "Ozëry",
    "city_ascii": "Ozery",
    "lat": 54.85,
    "lng": 38.55,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Moskovskaya Oblast’",
    "capital": "",
    "population": 25359,
    "id": 1643008708
  },
  {
    "city": "Albergaria-a-Velha",
    "city_ascii": "Albergaria-a-Velha",
    "lat": 40.6936,
    "lng": -8.4806,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Aveiro",
    "capital": "minor",
    "population": 25252,
    "id": 1620890833
  },
  {
    "city": "Sevlievo",
    "city_ascii": "Sevlievo",
    "lat": 43.0258,
    "lng": 25.1039,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Gabrovo",
    "capital": "",
    "population": 25372,
    "id": 1100820915
  },
  {
    "city": "Harsewinkel",
    "city_ascii": "Harsewinkel",
    "lat": 51.9667,
    "lng": 8.2331,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 25147,
    "id": 1276882516
  },
  {
    "city": "Meckenheim",
    "city_ascii": "Meckenheim",
    "lat": 50.6333,
    "lng": 7.0167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 24684,
    "id": 1276114705
  },
  {
    "city": "Claremore",
    "city_ascii": "Claremore",
    "lat": 36.3143,
    "lng": -95.6099,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oklahoma",
    "capital": "",
    "population": 25384,
    "id": 1840019049
  },
  {
    "city": "Whitehaven",
    "city_ascii": "Whitehaven",
    "lat": 54.548,
    "lng": -3.5855,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Cumbria",
    "capital": "",
    "population": 23986,
    "id": 1826916205
  },
  {
    "city": "Kose",
    "city_ascii": "Kose",
    "lat": 34.4633,
    "lng": 135.7403,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Nara",
    "capital": "",
    "population": 24965,
    "id": 1392839743
  },
  {
    "city": "Homewood",
    "city_ascii": "Homewood",
    "lat": 33.4617,
    "lng": -86.8092,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 25377,
    "id": 1840014794
  },
  {
    "city": "Dedham",
    "city_ascii": "Dedham",
    "lat": 42.2466,
    "lng": -71.1777,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 25377,
    "id": 1840053547
  },
  {
    "city": "Muret",
    "city_ascii": "Muret",
    "lat": 43.4611,
    "lng": 1.3267,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Occitanie",
    "capital": "minor",
    "population": 24945,
    "id": 1250231707
  },
  {
    "city": "Chiromo",
    "city_ascii": "Chiromo",
    "lat": -16.55,
    "lng": 35.1332,
    "country": "Malawi",
    "iso2": "MW",
    "iso3": "MWI",
    "admin_name": "Nsanje",
    "capital": "",
    "population": 25235,
    "id": 1454972056
  },
  {
    "city": "Fort Hood",
    "city_ascii": "Fort Hood",
    "lat": 31.1345,
    "lng": -97.7797,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 25365,
    "id": 1840073932
  },
  {
    "city": "De Witt",
    "city_ascii": "De Witt",
    "lat": 43.0503,
    "lng": -76.0712,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 25363,
    "id": 1840087377
  },
  {
    "city": "Magog",
    "city_ascii": "Magog",
    "lat": 45.2667,
    "lng": -72.15,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 25358,
    "id": 1124404849
  },
  {
    "city": "Mednogorsk",
    "city_ascii": "Mednogorsk",
    "lat": 51.4221,
    "lng": 57.5953,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Orenburgskaya Oblast’",
    "capital": "",
    "population": 25272,
    "id": 1643650403
  },
  {
    "city": "Jawor",
    "city_ascii": "Jawor",
    "lat": 51.05,
    "lng": 16.1833,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Dolnośląskie",
    "capital": "minor",
    "population": 24070,
    "id": 1616434559
  },
  {
    "city": "Middle River",
    "city_ascii": "Middle River",
    "lat": 39.3345,
    "lng": -76.4318,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 25346,
    "id": 1840005682
  },
  {
    "city": "Guanhães",
    "city_ascii": "Guanhaes",
    "lat": -18.78,
    "lng": -42.95,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Minas Gerais",
    "capital": "",
    "population": 23727,
    "id": 1076235295
  },
  {
    "city": "Horb am Neckar",
    "city_ascii": "Horb am Neckar",
    "lat": 48.445,
    "lng": 8.6911,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 25135,
    "id": 1276353248
  },
  {
    "city": "Farmington",
    "city_ascii": "Farmington",
    "lat": 40.9846,
    "lng": -111.9065,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Utah",
    "capital": "",
    "population": 25339,
    "id": 1840020142
  },
  {
    "city": "Zheleznovodsk",
    "city_ascii": "Zheleznovodsk",
    "lat": 44.1333,
    "lng": 43.0333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Stavropol’skiy Kray",
    "capital": "",
    "population": 24768,
    "id": 1643181159
  },
  {
    "city": "Senboku",
    "city_ascii": "Senboku",
    "lat": 39.7,
    "lng": 140.7306,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Akita",
    "capital": "",
    "population": 25305,
    "id": 1392802025
  },
  {
    "city": "Pinal de Amoles",
    "city_ascii": "Pinal de Amoles",
    "lat": 21.1342,
    "lng": -99.4586,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Querétaro",
    "capital": "minor",
    "population": 25325,
    "id": 1484630305
  },
  {
    "city": "Forest City",
    "city_ascii": "Forest City",
    "lat": 35.3338,
    "lng": -81.8702,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 25311,
    "id": 1840016320
  },
  {
    "city": "Rochefort",
    "city_ascii": "Rochefort",
    "lat": 45.9421,
    "lng": -0.9588,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Nouvelle-Aquitaine",
    "capital": "minor",
    "population": 24151,
    "id": 1250215806
  },
  {
    "city": "Salem",
    "city_ascii": "Salem",
    "lat": 37.2864,
    "lng": -80.0555,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 25301,
    "id": 1840001719
  },
  {
    "city": "Baar",
    "city_ascii": "Baar",
    "lat": 47.1956,
    "lng": 8.5264,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Zug",
    "capital": "",
    "population": 24322,
    "id": 1756858308
  },
  {
    "city": "Bainbridge Island",
    "city_ascii": "Bainbridge Island",
    "lat": 47.6439,
    "lng": -122.5434,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 25298,
    "id": 1840018409
  },
  {
    "city": "Norden",
    "city_ascii": "Norden",
    "lat": 53.5967,
    "lng": 7.2056,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 25060,
    "id": 1276766299
  },
  {
    "city": "Salzkotten",
    "city_ascii": "Salzkotten",
    "lat": 51.6708,
    "lng": 8.6047,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 25062,
    "id": 1276941056
  },
  {
    "city": "Nasukarasuyama",
    "city_ascii": "Nasukarasuyama",
    "lat": 36.6569,
    "lng": 140.1517,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Tochigi",
    "capital": "",
    "population": 25144,
    "id": 1392480551
  },
  {
    "city": "Petershagen",
    "city_ascii": "Petershagen",
    "lat": 52.3833,
    "lng": 8.9667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 25168,
    "id": 1276300392
  },
  {
    "city": "Khed Brahma",
    "city_ascii": "Khed Brahma",
    "lat": 24.0299,
    "lng": 73.0463,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Gujarāt",
    "capital": "",
    "population": 25001,
    "id": 1356775137
  },
  {
    "city": "Caledonia",
    "city_ascii": "Caledonia",
    "lat": 42.7986,
    "lng": -87.8762,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 25277,
    "id": 1840010091
  },
  {
    "city": "Cajuru",
    "city_ascii": "Cajuru",
    "lat": -21.2753,
    "lng": -47.3042,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 25230,
    "id": 1076846644
  },
  {
    "city": "Champlin",
    "city_ascii": "Champlin",
    "lat": 45.1702,
    "lng": -93.3903,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 25268,
    "id": 1840006746
  },
  {
    "city": "Sprockhövel",
    "city_ascii": "Sprockhovel",
    "lat": 51.3614,
    "lng": 7.244,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 24747,
    "id": 1276006803
  },
  {
    "city": "Lom",
    "city_ascii": "Lom",
    "lat": 43.8272,
    "lng": 23.2364,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Montana",
    "capital": "minor",
    "population": 25172,
    "id": 1100440040
  },
  {
    "city": "Thetford",
    "city_ascii": "Thetford",
    "lat": 52.41,
    "lng": 0.74,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Norfolk",
    "capital": "",
    "population": 24340,
    "id": 1826591780
  },
  {
    "city": "Kariba",
    "city_ascii": "Kariba",
    "lat": -16.5333,
    "lng": 28.8,
    "country": "Zimbabwe",
    "iso2": "ZW",
    "iso3": "ZWE",
    "admin_name": "Mashonaland West",
    "capital": "",
    "population": 24210,
    "id": 1716563536
  },
  {
    "city": "Frederikshavn",
    "city_ascii": "Frederikshavn",
    "lat": 57.4337,
    "lng": 10.5333,
    "country": "Denmark",
    "iso2": "DK",
    "iso3": "DNK",
    "admin_name": "Nordjylland",
    "capital": "minor",
    "population": 24103,
    "id": 1208910213
  },
  {
    "city": "Sastamala",
    "city_ascii": "Sastamala",
    "lat": 61.3417,
    "lng": 22.9083,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Pirkanmaa",
    "capital": "minor",
    "population": 25220,
    "id": 1246526086
  },
  {
    "city": "Apiaí",
    "city_ascii": "Apiai",
    "lat": -24.5097,
    "lng": -48.8428,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 25211,
    "id": 1076258648
  },
  {
    "city": "Edwardsville",
    "city_ascii": "Edwardsville",
    "lat": 38.7923,
    "lng": -89.9877,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 25233,
    "id": 1840008564
  },
  {
    "city": "Fajardo",
    "city_ascii": "Fajardo",
    "lat": 18.333,
    "lng": -65.6592,
    "country": "Puerto Rico",
    "iso2": "PR",
    "iso3": "PRI",
    "admin_name": "Puerto Rico",
    "capital": "",
    "population": 25229,
    "id": 1630035668
  },
  {
    "city": "Palm City",
    "city_ascii": "Palm City",
    "lat": 27.1736,
    "lng": -80.2861,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 25225,
    "id": 1840014192
  },
  {
    "city": "Bad Oldesloe",
    "city_ascii": "Bad Oldesloe",
    "lat": 53.8117,
    "lng": 10.3742,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Schleswig-Holstein",
    "capital": "minor",
    "population": 24744,
    "id": 1276683854
  },
  {
    "city": "Morozovsk",
    "city_ascii": "Morozovsk",
    "lat": 48.35,
    "lng": 41.8333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Rostovskaya Oblast’",
    "capital": "minor",
    "population": 25198,
    "id": 1643038040
  },
  {
    "city": "Villarrobledo",
    "city_ascii": "Villarrobledo",
    "lat": 39.2667,
    "lng": -2.6,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Castille-La Mancha",
    "capital": "",
    "population": 25184,
    "id": 1724704168
  },
  {
    "city": "Elk River",
    "city_ascii": "Elk River",
    "lat": 45.3314,
    "lng": -93.567,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 25213,
    "id": 1840007794
  },
  {
    "city": "Idstein",
    "city_ascii": "Idstein",
    "lat": 50.2206,
    "lng": 8.2692,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 24897,
    "id": 1276320507
  },
  {
    "city": "Rosemount",
    "city_ascii": "Rosemount",
    "lat": 44.7465,
    "lng": -93.0662,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 25207,
    "id": 1840008951
  },
  {
    "city": "Dolores",
    "city_ascii": "Dolores",
    "lat": -36.33,
    "lng": -57.69,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires",
    "capital": "minor",
    "population": 25190,
    "id": 1032650497
  },
  {
    "city": "Fountain Hills",
    "city_ascii": "Fountain Hills",
    "lat": 33.6073,
    "lng": -111.7398,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 25200,
    "id": 1840021945
  },
  {
    "city": "Droitwich",
    "city_ascii": "Droitwich",
    "lat": 52.267,
    "lng": -2.153,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Worcestershire",
    "capital": "",
    "population": 23504,
    "id": 1826540486
  },
  {
    "city": "Otuzco",
    "city_ascii": "Otuzco",
    "lat": -7.9025,
    "lng": -78.5657,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "La Libertad",
    "capital": "",
    "population": 25134,
    "id": 1604161129
  },
  {
    "city": "Shaqlāwah",
    "city_ascii": "Shaqlawah",
    "lat": 36.3964,
    "lng": 44.3436,
    "country": "Iraq",
    "iso2": "IQ",
    "iso3": "IRQ",
    "admin_name": "Arbīl",
    "capital": "minor",
    "population": 25000,
    "id": 1368884610
  },
  {
    "city": "Santa María Tonameca",
    "city_ascii": "Santa Maria Tonameca",
    "lat": 15.7458,
    "lng": -96.5472,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Oaxaca",
    "capital": "minor",
    "population": 25130,
    "id": 1484998049
  },
  {
    "city": "Hudson",
    "city_ascii": "Hudson",
    "lat": 42.7639,
    "lng": -71.4072,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Hampshire",
    "capital": "",
    "population": 25185,
    "id": 1840055034
  },
  {
    "city": "Raahe",
    "city_ascii": "Raahe",
    "lat": 64.6847,
    "lng": 24.4792,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Pohjois-Pohjanmaa",
    "capital": "minor",
    "population": 25165,
    "id": 1246712185
  },
  {
    "city": "Litoměřice",
    "city_ascii": "Litomerice",
    "lat": 50.5336,
    "lng": 14.1319,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Ústecký Kraj",
    "capital": "",
    "population": 23849,
    "id": 1203149283
  },
  {
    "city": "Socorro",
    "city_ascii": "Socorro",
    "lat": 6.4603,
    "lng": -73.27,
    "country": "Colombia",
    "iso2": "CO",
    "iso3": "COL",
    "admin_name": "Santander",
    "capital": "minor",
    "population": 24895,
    "id": 1170793746
  },
  {
    "city": "Bay Point",
    "city_ascii": "Bay Point",
    "lat": 38.0329,
    "lng": -121.9614,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 25165,
    "id": 1840028316
  },
  {
    "city": "Great Sankey",
    "city_ascii": "Great Sankey",
    "lat": 53.3918,
    "lng": -2.6383,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Warrington",
    "capital": "",
    "population": 24211,
    "id": 1826102019
  },
  {
    "city": "Ch’arents’avan",
    "city_ascii": "Ch'arents'avan",
    "lat": 40.4097,
    "lng": 44.6431,
    "country": "Armenia",
    "iso2": "AM",
    "iso3": "ARM",
    "admin_name": "Kotayk’",
    "capital": "",
    "population": 25039,
    "id": 1051304472
  },
  {
    "city": "Delitzsch",
    "city_ascii": "Delitzsch",
    "lat": 51.5264,
    "lng": 12.3425,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 24868,
    "id": 1276928962
  },
  {
    "city": "Atkarsk",
    "city_ascii": "Atkarsk",
    "lat": 51.8667,
    "lng": 45,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Saratovskaya Oblast’",
    "capital": "",
    "population": 25140,
    "id": 1643598170
  },
  {
    "city": "Sovetskaya Gavan’",
    "city_ascii": "Sovetskaya Gavan'",
    "lat": 48.9667,
    "lng": 140.2833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Khabarovskiy Kray",
    "capital": "",
    "population": 25147,
    "id": 1643037289
  },
  {
    "city": "Belvidere",
    "city_ascii": "Belvidere",
    "lat": 42.2544,
    "lng": -88.8649,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 25143,
    "id": 1840006971
  },
  {
    "city": "Ereymentaū",
    "city_ascii": "Ereymentau",
    "lat": 51.6303,
    "lng": 73.1049,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Aqmola",
    "capital": "",
    "population": 25141,
    "id": 1398103059
  },
  {
    "city": "Eysines",
    "city_ascii": "Eysines",
    "lat": 44.8853,
    "lng": -0.65,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Nouvelle-Aquitaine",
    "capital": "",
    "population": 23462,
    "id": 1250067454
  },
  {
    "city": "Sakai",
    "city_ascii": "Sakai",
    "lat": 36.1086,
    "lng": 139.795,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Ibaraki",
    "capital": "",
    "population": 24098,
    "id": 1392827163
  },
  {
    "city": "Riverside",
    "city_ascii": "Riverside",
    "lat": 39.7836,
    "lng": -84.1219,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 25133,
    "id": 1840012490
  },
  {
    "city": "Muskego",
    "city_ascii": "Muskego",
    "lat": 42.886,
    "lng": -88.1291,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 25127,
    "id": 1840003023
  },
  {
    "city": "Jaleshwar",
    "city_ascii": "Jaleshwar",
    "lat": 26.6333,
    "lng": 85.8,
    "country": "Nepal",
    "iso2": "NP",
    "iso3": "NPL",
    "admin_name": "Janakpur",
    "capital": "",
    "population": 23533,
    "id": 1524945108
  },
  {
    "city": "Wolfsberg",
    "city_ascii": "Wolfsberg",
    "lat": 46.8419,
    "lng": 14.8408,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Kärnten",
    "capital": "minor",
    "population": 25035,
    "id": 1040360550
  },
  {
    "city": "Kingsville",
    "city_ascii": "Kingsville",
    "lat": 27.5094,
    "lng": -97.8611,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 25120,
    "id": 1840021013
  },
  {
    "city": "Hazelwood",
    "city_ascii": "Hazelwood",
    "lat": 38.7931,
    "lng": -90.3899,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 25117,
    "id": 1840007453
  },
  {
    "city": "Scottsbluff",
    "city_ascii": "Scottsbluff",
    "lat": 41.8684,
    "lng": -103.6616,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Nebraska",
    "capital": "",
    "population": 25114,
    "id": 1840009197
  },
  {
    "city": "Pitsea",
    "city_ascii": "Pitsea",
    "lat": 51.569,
    "lng": 0.504,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Essex",
    "capital": "",
    "population": 25000,
    "id": 1826099748
  },
  {
    "city": "Canyon Lake",
    "city_ascii": "Canyon Lake",
    "lat": 29.8761,
    "lng": -98.2611,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 25104,
    "id": 1840018260
  },
  {
    "city": "Santo Tomás",
    "city_ascii": "Santo Tomas",
    "lat": -14.45,
    "lng": -72.082,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "Cusco",
    "capital": "",
    "population": 25087,
    "id": 1604893836
  },
  {
    "city": "Reading",
    "city_ascii": "Reading",
    "lat": 42.5351,
    "lng": -71.1056,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 25100,
    "id": 1840053484
  },
  {
    "city": "Columbine",
    "city_ascii": "Columbine",
    "lat": 39.5879,
    "lng": -105.0694,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 25094,
    "id": 1840135257
  },
  {
    "city": "Krems an der Donau",
    "city_ascii": "Krems an der Donau",
    "lat": 48.4167,
    "lng": 15.6167,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Niederösterreich",
    "capital": "minor",
    "population": 24610,
    "id": 1040577668
  },
  {
    "city": "Whitehorse",
    "city_ascii": "Whitehorse",
    "lat": 60.7029,
    "lng": -135.0691,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Yukon",
    "capital": "",
    "population": 25085,
    "id": 1124348186
  },
  {
    "city": "Walpole",
    "city_ascii": "Walpole",
    "lat": 42.1465,
    "lng": -71.2555,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 25075,
    "id": 1840053559
  },
  {
    "city": "Ridgefield",
    "city_ascii": "Ridgefield",
    "lat": 41.3065,
    "lng": -73.5023,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 25070,
    "id": 1840035559
  },
  {
    "city": "Břeclav",
    "city_ascii": "Breclav",
    "lat": 48.759,
    "lng": 16.882,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Jihomoravský Kraj",
    "capital": "",
    "population": 24743,
    "id": 1203778686
  },
  {
    "city": "Turnu Măgurele",
    "city_ascii": "Turnu Magurele",
    "lat": 43.7517,
    "lng": 24.8708,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Teleorman",
    "capital": "",
    "population": 24772,
    "id": 1642918101
  },
  {
    "city": "Ridgewood",
    "city_ascii": "Ridgewood",
    "lat": 40.9822,
    "lng": -74.1128,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 25056,
    "id": 1840000899
  },
  {
    "city": "Slavutych",
    "city_ascii": "Slavutych",
    "lat": 51.5206,
    "lng": 30.7569,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Kyyivs’ka Oblast’",
    "capital": "",
    "population": 25030,
    "id": 1804529063
  },
  {
    "city": "Yucca Valley",
    "city_ascii": "Yucca Valley",
    "lat": 34.1234,
    "lng": -116.4216,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 25042,
    "id": 1840022726
  },
  {
    "city": "Derry Township",
    "city_ascii": "Derry Township",
    "lat": 40.2709,
    "lng": -76.6561,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 25036,
    "id": 1840152635
  },
  {
    "city": "Branson",
    "city_ascii": "Branson",
    "lat": 36.651,
    "lng": -93.2635,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 25033,
    "id": 1840007659
  },
  {
    "city": "Sultepec",
    "city_ascii": "Sultepec",
    "lat": 18.8667,
    "lng": -99.95,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "México",
    "capital": "minor",
    "population": 24986,
    "id": 1484381977
  },
  {
    "city": "Dainyor",
    "city_ascii": "Dainyor",
    "lat": 35.9206,
    "lng": 74.3783,
    "country": "Pakistan",
    "iso2": "PK",
    "iso3": "PAK",
    "admin_name": "Gilgit-Baltistan",
    "capital": "",
    "population": 25000,
    "id": 1586862411
  },
  {
    "city": "Seaford",
    "city_ascii": "Seaford",
    "lat": 50.77,
    "lng": 0.1,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "East Sussex",
    "capital": "",
    "population": 23463,
    "id": 1826426946
  },
  {
    "city": "Uvarovo",
    "city_ascii": "Uvarovo",
    "lat": 51.9833,
    "lng": 42.2667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Tambovskaya Oblast’",
    "capital": "",
    "population": 23987,
    "id": 1643354757
  },
  {
    "city": "Sandomierz",
    "city_ascii": "Sandomierz",
    "lat": 50.6833,
    "lng": 21.75,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Świętokrzyskie",
    "capital": "minor",
    "population": 24187,
    "id": 1616893663
  },
  {
    "city": "Ma‘bar",
    "city_ascii": "Ma`bar",
    "lat": 14.7939,
    "lng": 44.2936,
    "country": "Yemen",
    "iso2": "YE",
    "iso3": "YEM",
    "admin_name": "Dhamār",
    "capital": "minor",
    "population": 24707,
    "id": 1887814519
  },
  {
    "city": "Eisenhüttenstadt",
    "city_ascii": "Eisenhuttenstadt",
    "lat": 52.145,
    "lng": 14.6728,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Brandenburg",
    "capital": "",
    "population": 24633,
    "id": 1276902561
  },
  {
    "city": "Český Těšín",
    "city_ascii": "Cesky Tesin",
    "lat": 49.7461,
    "lng": 18.6262,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Moravskoslezský Kraj",
    "capital": "",
    "population": 24297,
    "id": 1203518530
  },
  {
    "city": "Balch Springs",
    "city_ascii": "Balch Springs",
    "lat": 32.7194,
    "lng": -96.6151,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 25007,
    "id": 1840019435
  },
  {
    "city": "Woolwich",
    "city_ascii": "Woolwich",
    "lat": 43.5667,
    "lng": -80.4833,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 25006,
    "id": 1124000096
  },
  {
    "city": "Übach-Palenberg",
    "city_ascii": "Ubach-Palenberg",
    "lat": 50.9197,
    "lng": 6.1194,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 24081,
    "id": 1276712019
  },
  {
    "city": "Warstein",
    "city_ascii": "Warstein",
    "lat": 51.45,
    "lng": 8.3667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 24842,
    "id": 1276854914
  },
  {
    "city": "Ronnenberg",
    "city_ascii": "Ronnenberg",
    "lat": 52.3194,
    "lng": 9.6556,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 24347,
    "id": 1276210070
  },
  {
    "city": "Santa Gertrudes",
    "city_ascii": "Santa Gertrudes",
    "lat": -22.4569,
    "lng": -47.53,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 24737,
    "id": 1076580877
  },
  {
    "city": "Espelkamp",
    "city_ascii": "Espelkamp",
    "lat": 52.3772,
    "lng": 8.6328,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 24685,
    "id": 1276996942
  },
  {
    "city": "Olpe",
    "city_ascii": "Olpe",
    "lat": 51.0289,
    "lng": 7.8514,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "minor",
    "population": 24688,
    "id": 1276501027
  },
  {
    "city": "De Pere",
    "city_ascii": "De Pere",
    "lat": 44.4309,
    "lng": -88.0785,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 24970,
    "id": 1840002348
  },
  {
    "city": "Schmallenberg",
    "city_ascii": "Schmallenberg",
    "lat": 51.1333,
    "lng": 8.3,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 24869,
    "id": 1276002699
  },
  {
    "city": "Marysville",
    "city_ascii": "Marysville",
    "lat": 40.2278,
    "lng": -83.3595,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 24949,
    "id": 1840008355
  },
  {
    "city": "Madaoua",
    "city_ascii": "Madaoua",
    "lat": 14.0762,
    "lng": 5.9586,
    "country": "Niger",
    "iso2": "NE",
    "iso3": "NER",
    "admin_name": "Tahoua",
    "capital": "minor",
    "population": 24804,
    "id": 1562237869
  },
  {
    "city": "Derby",
    "city_ascii": "Derby",
    "lat": 37.5571,
    "lng": -97.2552,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kansas",
    "capital": "",
    "population": 24943,
    "id": 1840007569
  },
  {
    "city": "Trebišov",
    "city_ascii": "Trebisov",
    "lat": 48.6333,
    "lng": 21.7167,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Košický",
    "capital": "minor",
    "population": 24587,
    "id": 1703917090
  },
  {
    "city": "Plymstock",
    "city_ascii": "Plymstock",
    "lat": 50.3569,
    "lng": -4.09,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Plymouth",
    "capital": "",
    "population": 24103,
    "id": 1826596908
  },
  {
    "city": "Wilsonville",
    "city_ascii": "Wilsonville",
    "lat": 45.3107,
    "lng": -122.7705,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 24918,
    "id": 1840021219
  },
  {
    "city": "Wil",
    "city_ascii": "Wil",
    "lat": 47.4664,
    "lng": 9.0497,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Sankt Gallen",
    "capital": "minor",
    "population": 23768,
    "id": 1756263917
  },
  {
    "city": "Wajimazakimachi",
    "city_ascii": "Wajimazakimachi",
    "lat": 37.3906,
    "lng": 136.8992,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Ishikawa",
    "capital": "",
    "population": 24851,
    "id": 1392449990
  },
  {
    "city": "Hodonín",
    "city_ascii": "Hodonin",
    "lat": 48.849,
    "lng": 17.1324,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Jihomoravský Kraj",
    "capital": "",
    "population": 24512,
    "id": 1203626632
  },
  {
    "city": "Sharya",
    "city_ascii": "Sharya",
    "lat": 58.3667,
    "lng": 45.5,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kostromskaya Oblast’",
    "capital": "",
    "population": 23976,
    "id": 1643446883
  },
  {
    "city": "Cataño",
    "city_ascii": "Catano",
    "lat": 18.4415,
    "lng": -66.1388,
    "country": "Puerto Rico",
    "iso2": "PR",
    "iso3": "PRI",
    "admin_name": "Puerto Rico",
    "capital": "",
    "population": 24888,
    "id": 1630023554
  },
  {
    "city": "Clayton",
    "city_ascii": "Clayton",
    "lat": 35.6591,
    "lng": -78.4499,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 24887,
    "id": 1840016290
  },
  {
    "city": "Brookings",
    "city_ascii": "Brookings",
    "lat": 44.3022,
    "lng": -96.7859,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Dakota",
    "capital": "",
    "population": 24887,
    "id": 1840002506
  },
  {
    "city": "Pavlovsk",
    "city_ascii": "Pavlovsk",
    "lat": 50.45,
    "lng": 40.0667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Voronezhskaya Oblast’",
    "capital": "minor",
    "population": 24858,
    "id": 1643562859
  },
  {
    "city": "Evesham",
    "city_ascii": "Evesham",
    "lat": 52.092,
    "lng": -1.947,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Worcestershire",
    "capital": "",
    "population": 23428,
    "id": 1826763416
  },
  {
    "city": "Leoben",
    "city_ascii": "Leoben",
    "lat": 47.3817,
    "lng": 15.0972,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Steiermark",
    "capital": "minor",
    "population": 24645,
    "id": 1040591429
  },
  {
    "city": "Walker",
    "city_ascii": "Walker",
    "lat": 42.9853,
    "lng": -85.7446,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 24869,
    "id": 1840002925
  },
  {
    "city": "Colón",
    "city_ascii": "Colon",
    "lat": -32.2241,
    "lng": -58.1419,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Entre Ríos",
    "capital": "minor",
    "population": 24835,
    "id": 1032656120
  },
  {
    "city": "Wyandotte",
    "city_ascii": "Wyandotte",
    "lat": 42.2113,
    "lng": -83.1558,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 24859,
    "id": 1840001849
  },
  {
    "city": "Susquehanna Township",
    "city_ascii": "Susquehanna Township",
    "lat": 40.3111,
    "lng": -76.8699,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 24857,
    "id": 1840149537
  },
  {
    "city": "Grandview",
    "city_ascii": "Grandview",
    "lat": 38.8802,
    "lng": -94.5227,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 24856,
    "id": 1840008532
  },
  {
    "city": "Forest Lake",
    "city_ascii": "Forest Lake",
    "lat": 45.2536,
    "lng": -92.9583,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 24853,
    "id": 1840007808
  },
  {
    "city": "Chesapeake Beach",
    "city_ascii": "Chesapeake Beach",
    "lat": 38.6881,
    "lng": -76.5448,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 24845,
    "id": 1840006151
  },
  {
    "city": "Hudson",
    "city_ascii": "Hudson",
    "lat": 44.9637,
    "lng": -92.7316,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 24832,
    "id": 1840002135
  },
  {
    "city": "Fairland",
    "city_ascii": "Fairland",
    "lat": 39.0804,
    "lng": -76.9527,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 24831,
    "id": 1840001593
  },
  {
    "city": "Kulachi",
    "city_ascii": "Kulachi",
    "lat": 31.9286,
    "lng": 70.4592,
    "country": "Pakistan",
    "iso2": "PK",
    "iso3": "PAK",
    "admin_name": "Khyber Pakhtunkhwa",
    "capital": "",
    "population": 24738,
    "id": 1586015711
  },
  {
    "city": "Aytos",
    "city_ascii": "Aytos",
    "lat": 42.7,
    "lng": 27.25,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Burgas",
    "capital": "",
    "population": 24750,
    "id": 1100109481
  },
  {
    "city": "Lincoln",
    "city_ascii": "Lincoln",
    "lat": -34.85,
    "lng": -61.5167,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires",
    "capital": "minor",
    "population": 24798,
    "id": 1032332688
  },
  {
    "city": "Rockaway",
    "city_ascii": "Rockaway",
    "lat": 40.9602,
    "lng": -74.4988,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 24792,
    "id": 1840000951
  },
  {
    "city": "Vertou",
    "city_ascii": "Vertou",
    "lat": 47.1689,
    "lng": -1.4697,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Pays de la Loire",
    "capital": "",
    "population": 24219,
    "id": 1250277528
  },
  {
    "city": "Raisio",
    "city_ascii": "Raisio",
    "lat": 60.4861,
    "lng": 22.1694,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Varsinais-Suomi",
    "capital": "minor",
    "population": 24290,
    "id": 1246206941
  },
  {
    "city": "Brasiléia",
    "city_ascii": "Brasileia",
    "lat": -11.01,
    "lng": -68.7478,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Acre",
    "capital": "",
    "population": 24765,
    "id": 1076430930
  },
  {
    "city": "Lüdinghausen",
    "city_ascii": "Ludinghausen",
    "lat": 51.7681,
    "lng": 7.4444,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 24590,
    "id": 1276526583
  },
  {
    "city": "Kakonko",
    "city_ascii": "Kakonko",
    "lat": -3.2796,
    "lng": 30.96,
    "country": "Tanzania",
    "iso2": "TZ",
    "iso3": "TZA",
    "admin_name": "Kigoma",
    "capital": "",
    "population": 24688,
    "id": 1834778373
  },
  {
    "city": "Roissy-en-Brie",
    "city_ascii": "Roissy-en-Brie",
    "lat": 48.7906,
    "lng": 2.6519,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 22994,
    "id": 1250233189
  },
  {
    "city": "Buy",
    "city_ascii": "Buy",
    "lat": 58.4833,
    "lng": 41.5167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kostromskaya Oblast’",
    "capital": "",
    "population": 23809,
    "id": 1643121343
  },
  {
    "city": "South Elgin",
    "city_ascii": "South Elgin",
    "lat": 41.9906,
    "lng": -88.3134,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 24755,
    "id": 1840013564
  },
  {
    "city": "Şamaxı",
    "city_ascii": "Samaxi",
    "lat": 40.6339,
    "lng": 48.6392,
    "country": "Azerbaijan",
    "iso2": "AZ",
    "iso3": "AZE",
    "admin_name": "Şamaxı",
    "capital": "",
    "population": 24681,
    "id": 1031286384
  },
  {
    "city": "Auburn Hills",
    "city_ascii": "Auburn Hills",
    "lat": 42.6735,
    "lng": -83.2447,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 24748,
    "id": 1840002436
  },
  {
    "city": "Čadca",
    "city_ascii": "Cadca",
    "lat": 49.4386,
    "lng": 18.7883,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Žilinský",
    "capital": "minor",
    "population": 24315,
    "id": 1703091898
  },
  {
    "city": "North Tustin",
    "city_ascii": "North Tustin",
    "lat": 33.7644,
    "lng": -117.7945,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 24736,
    "id": 1840019319
  },
  {
    "city": "Öhringen",
    "city_ascii": "Ohringen",
    "lat": 49.2,
    "lng": 9.5,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 24374,
    "id": 1276641265
  },
  {
    "city": "Larvik",
    "city_ascii": "Larvik",
    "lat": 59.0532,
    "lng": 10.0271,
    "country": "Norway",
    "iso2": "NO",
    "iso3": "NOR",
    "admin_name": "Vestfold",
    "capital": "",
    "population": 24647,
    "id": 1578938550
  },
  {
    "city": "Igaraçu do Tietê",
    "city_ascii": "Igaracu do Tiete",
    "lat": -22.5092,
    "lng": -48.5578,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 24451,
    "id": 1076166440
  },
  {
    "city": "Ontario",
    "city_ascii": "Ontario",
    "lat": 44.0259,
    "lng": -116.9759,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 24704,
    "id": 1840020002
  },
  {
    "city": "Takehara",
    "city_ascii": "Takehara",
    "lat": 34.3417,
    "lng": 132.9069,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hiroshima",
    "capital": "",
    "population": 24496,
    "id": 1392241539
  },
  {
    "city": "Takhli",
    "city_ascii": "Takhli",
    "lat": 15.2667,
    "lng": 100.35,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Nakhon Sawan",
    "capital": "minor",
    "population": 24563,
    "id": 1764978785
  },
  {
    "city": "‘Arad",
    "city_ascii": "`Arad",
    "lat": 31.2603,
    "lng": 35.2147,
    "country": "Israel",
    "iso2": "IL",
    "iso3": "ISR",
    "admin_name": "Southern",
    "capital": "",
    "population": 24436,
    "id": 1376674296
  },
  {
    "city": "Vizela",
    "city_ascii": "Vizela",
    "lat": 41.3833,
    "lng": -8.3,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Porto",
    "capital": "",
    "population": 23736,
    "id": 1620166206
  },
  {
    "city": "Burlington",
    "city_ascii": "Burlington",
    "lat": 42.6746,
    "lng": -88.2723,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 24691,
    "id": 1840002491
  },
  {
    "city": "Windham",
    "city_ascii": "Windham",
    "lat": 41.7102,
    "lng": -72.1671,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 24688,
    "id": 1840035671
  },
  {
    "city": "Swatara",
    "city_ascii": "Swatara",
    "lat": 40.2463,
    "lng": -76.803,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 24685,
    "id": 1840149538
  },
  {
    "city": "Hannoversch Münden",
    "city_ascii": "Hannoversch Munden",
    "lat": 51.4167,
    "lng": 9.65,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 24467,
    "id": 1276002967
  },
  {
    "city": "Álvares Machado",
    "city_ascii": "Alvares Machado",
    "lat": -22.0789,
    "lng": -51.4719,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 24651,
    "id": 1076095632
  },
  {
    "city": "Norton Shores",
    "city_ascii": "Norton Shores",
    "lat": 43.1621,
    "lng": -86.2519,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 24664,
    "id": 1840002873
  },
  {
    "city": "Kernersville",
    "city_ascii": "Kernersville",
    "lat": 36.1066,
    "lng": -80.0844,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 24660,
    "id": 1840016123
  },
  {
    "city": "Rickmansworth",
    "city_ascii": "Rickmansworth",
    "lat": 51.6383,
    "lng": -0.4659,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hertfordshire",
    "capital": "",
    "population": 23973,
    "id": 1826881978
  },
  {
    "city": "Khajurāho",
    "city_ascii": "Khajuraho",
    "lat": 24.85,
    "lng": 79.9333,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Madhya Pradesh",
    "capital": "",
    "population": 24481,
    "id": 1356993147
  },
  {
    "city": "Meiningen",
    "city_ascii": "Meiningen",
    "lat": 50.55,
    "lng": 10.4167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Thuringia",
    "capital": "minor",
    "population": 24233,
    "id": 1276941916
  },
  {
    "city": "Keystone",
    "city_ascii": "Keystone",
    "lat": 28.1312,
    "lng": -82.5999,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 24632,
    "id": 1840028914
  },
  {
    "city": "North Laurel",
    "city_ascii": "North Laurel",
    "lat": 39.1286,
    "lng": -76.8476,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 24621,
    "id": 1840031495
  },
  {
    "city": "Sugar Hill",
    "city_ascii": "Sugar Hill",
    "lat": 34.1081,
    "lng": -84.056,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 24617,
    "id": 1840015615
  },
  {
    "city": "Attendorn",
    "city_ascii": "Attendorn",
    "lat": 51.1264,
    "lng": 7.9033,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 24367,
    "id": 1276325506
  },
  {
    "city": "San Borja",
    "city_ascii": "San Borja",
    "lat": -14.8583,
    "lng": -66.7475,
    "country": "Bolivia",
    "iso2": "BO",
    "iso3": "BOL",
    "admin_name": "El Beni",
    "capital": "",
    "population": 24610,
    "id": 1068118050
  },
  {
    "city": "Fresno",
    "city_ascii": "Fresno",
    "lat": 29.5358,
    "lng": -95.4696,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 24607,
    "id": 1840018266
  },
  {
    "city": "Zernograd",
    "city_ascii": "Zernograd",
    "lat": 46.85,
    "lng": 40.3,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Rostovskaya Oblast’",
    "capital": "minor",
    "population": 24561,
    "id": 1643009335
  },
  {
    "city": "Victoria",
    "city_ascii": "Victoria",
    "lat": -38.2395,
    "lng": -72.34,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Araucanía",
    "capital": "",
    "population": 24555,
    "id": 1152078114
  },
  {
    "city": "Prosper",
    "city_ascii": "Prosper",
    "lat": 33.2394,
    "lng": -96.8087,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 24579,
    "id": 1840022019
  },
  {
    "city": "Pirajuí",
    "city_ascii": "Pirajui",
    "lat": -21.9989,
    "lng": -49.4569,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 24546,
    "id": 1076016134
  },
  {
    "city": "Gyál",
    "city_ascii": "Gyal",
    "lat": 47.3822,
    "lng": 19.2136,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Pest",
    "capital": "minor",
    "population": 23628,
    "id": 1348670361
  },
  {
    "city": "Chantilly",
    "city_ascii": "Chantilly",
    "lat": 38.8868,
    "lng": -77.4453,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 24559,
    "id": 1840006016
  },
  {
    "city": "Dubnica nad Váhom",
    "city_ascii": "Dubnica nad Vahom",
    "lat": 48.9575,
    "lng": 18.1658,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Nitriansky",
    "capital": "",
    "population": 24068,
    "id": 1703468923
  },
  {
    "city": "Kaniv",
    "city_ascii": "Kaniv",
    "lat": 49.7447,
    "lng": 31.4558,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Cherkas’ka Oblast’",
    "capital": "minor",
    "population": 24532,
    "id": 1804910839
  },
  {
    "city": "Waverly",
    "city_ascii": "Waverly",
    "lat": 42.7401,
    "lng": -84.6353,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 24546,
    "id": 1840006924
  },
  {
    "city": "Syke",
    "city_ascii": "Syke",
    "lat": 52.9161,
    "lng": 8.8186,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 24355,
    "id": 1276951328
  },
  {
    "city": "Krychaw",
    "city_ascii": "Krychaw",
    "lat": 53.7194,
    "lng": 31.7139,
    "country": "Belarus",
    "iso2": "BY",
    "iso3": "BLR",
    "admin_name": "Mahilyowskaya Voblasts’",
    "capital": "minor",
    "population": 24500,
    "id": 1112203208
  },
  {
    "city": "Waldshut-Tiengen",
    "city_ascii": "Waldshut-Tiengen",
    "lat": 47.6231,
    "lng": 8.2144,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "minor",
    "population": 24226,
    "id": 1276011078
  },
  {
    "city": "Watsa",
    "city_ascii": "Watsa",
    "lat": 3.04,
    "lng": 29.53,
    "country": "Congo (Kinshasa)",
    "iso2": "CD",
    "iso3": "COD",
    "admin_name": "Haut-Uélé",
    "capital": "",
    "population": 24516,
    "id": 1180367451
  },
  {
    "city": "Waldkraiburg",
    "city_ascii": "Waldkraiburg",
    "lat": 48.2167,
    "lng": 12.4,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 23442,
    "id": 1276784836
  },
  {
    "city": "Somerset",
    "city_ascii": "Somerset",
    "lat": 40.5083,
    "lng": -74.501,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 24527,
    "id": 1840005375
  },
  {
    "city": "Rathenow",
    "city_ascii": "Rathenow",
    "lat": 52.6,
    "lng": 12.3333,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Brandenburg",
    "capital": "minor",
    "population": 24309,
    "id": 1276833446
  },
  {
    "city": "Palmetto Bay",
    "city_ascii": "Palmetto Bay",
    "lat": 25.622,
    "lng": -80.3221,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 24523,
    "id": 1840018335
  },
  {
    "city": "Svishtov",
    "city_ascii": "Svishtov",
    "lat": 43.6186,
    "lng": 25.3439,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Veliko Tarnovo",
    "capital": "minor",
    "population": 24459,
    "id": 1100115119
  },
  {
    "city": "Simsbury",
    "city_ascii": "Simsbury",
    "lat": 41.8729,
    "lng": -72.8256,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 24519,
    "id": 1840045081
  },
  {
    "city": "Emporia",
    "city_ascii": "Emporia",
    "lat": 38.4028,
    "lng": -96.1924,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kansas",
    "capital": "",
    "population": 24513,
    "id": 1840001652
  },
  {
    "city": "Clarence-Rockland",
    "city_ascii": "Clarence-Rockland",
    "lat": 45.4833,
    "lng": -75.2,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 24512,
    "id": 1124000639
  },
  {
    "city": "Limbach-Oberfrohna",
    "city_ascii": "Limbach-Oberfrohna",
    "lat": 50.8667,
    "lng": 12.75,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 24029,
    "id": 1276772007
  },
  {
    "city": "Middleborough",
    "city_ascii": "Middleborough",
    "lat": 41.8803,
    "lng": -70.8745,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 24505,
    "id": 1840070288
  },
  {
    "city": "Avon Lake",
    "city_ascii": "Avon Lake",
    "lat": 41.4945,
    "lng": -82.016,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 24504,
    "id": 1840000642
  },
  {
    "city": "Pýrgos",
    "city_ascii": "Pyrgos",
    "lat": 37.6667,
    "lng": 21.4333,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Dytikí Elláda",
    "capital": "minor",
    "population": 24359,
    "id": 1300188949
  },
  {
    "city": "Vernon",
    "city_ascii": "Vernon",
    "lat": 49.09,
    "lng": 1.49,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Normandie",
    "capital": "",
    "population": 23872,
    "id": 1250890405
  },
  {
    "city": "Nyūzen",
    "city_ascii": "Nyuzen",
    "lat": 36.9333,
    "lng": 137.5,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Toyama",
    "capital": "",
    "population": 24160,
    "id": 1392959152
  },
  {
    "city": "Neftekumsk",
    "city_ascii": "Neftekumsk",
    "lat": 44.7506,
    "lng": 44.9797,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Stavropol’skiy Kray",
    "capital": "",
    "population": 24472,
    "id": 1643496065
  },
  {
    "city": "Xinxing",
    "city_ascii": "Xinxing",
    "lat": 47.1601,
    "lng": 123.8,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Heilongjiang",
    "capital": "minor",
    "population": 24317,
    "id": 1156085832
  },
  {
    "city": "Lathrop",
    "city_ascii": "Lathrop",
    "lat": 37.809,
    "lng": -121.3131,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 24483,
    "id": 1840020266
  },
  {
    "city": "Loma Linda",
    "city_ascii": "Loma Linda",
    "lat": 34.0451,
    "lng": -117.2498,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 24482,
    "id": 1840020406
  },
  {
    "city": "Homer Glen",
    "city_ascii": "Homer Glen",
    "lat": 41.6044,
    "lng": -87.9497,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 24472,
    "id": 1840011483
  },
  {
    "city": "Tifton",
    "city_ascii": "Tifton",
    "lat": 31.4625,
    "lng": -83.5205,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 24466,
    "id": 1840015863
  },
  {
    "city": "Senftenberg",
    "city_ascii": "Senftenberg",
    "lat": 51.5167,
    "lng": 14.0167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Brandenburg",
    "capital": "minor",
    "population": 24275,
    "id": 1276241839
  },
  {
    "city": "Tocopilla",
    "city_ascii": "Tocopilla",
    "lat": -22.0941,
    "lng": -70.201,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Antofagasta",
    "capital": "minor",
    "population": 24460,
    "id": 1152050254
  },
  {
    "city": "Dinuba",
    "city_ascii": "Dinuba",
    "lat": 36.5453,
    "lng": -119.3987,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 24461,
    "id": 1840019044
  },
  {
    "city": "Bad Soden am Taunus",
    "city_ascii": "Bad Soden am Taunus",
    "lat": 50.1424,
    "lng": 8.4997,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 22645,
    "id": 1276847418
  },
  {
    "city": "Stroitel",
    "city_ascii": "Stroitel",
    "lat": 50.7833,
    "lng": 36.4833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Belgorodskaya Oblast’",
    "capital": "",
    "population": 24392,
    "id": 1643765660
  },
  {
    "city": "Amarapura",
    "city_ascii": "Amarapura",
    "lat": 21.9,
    "lng": 96.0458,
    "country": "Burma",
    "iso2": "MM",
    "iso3": "MMR",
    "admin_name": "Mandalay",
    "capital": "",
    "population": 23200,
    "id": 1104567036
  },
  {
    "city": "Selydove",
    "city_ascii": "Selydove",
    "lat": 48.15,
    "lng": 37.3,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Donets’ka Oblast’",
    "capital": "",
    "population": 23793,
    "id": 1804092732
  },
  {
    "city": "Westmont",
    "city_ascii": "Westmont",
    "lat": 41.7948,
    "lng": -87.9742,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 24443,
    "id": 1840011414
  },
  {
    "city": "Hachimantai",
    "city_ascii": "Hachimantai",
    "lat": 39.9264,
    "lng": 141.0953,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Iwate",
    "capital": "",
    "population": 24412,
    "id": 1392427072
  },
  {
    "city": "Clinton",
    "city_ascii": "Clinton",
    "lat": 32.354,
    "lng": -90.3403,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Mississippi",
    "capital": "",
    "population": 24440,
    "id": 1840014893
  },
  {
    "city": "Porto de Mós",
    "city_ascii": "Porto de Mos",
    "lat": 39.6,
    "lng": -8.8167,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Leiria",
    "capital": "minor",
    "population": 24342,
    "id": 1620575221
  },
  {
    "city": "Opelousas",
    "city_ascii": "Opelousas",
    "lat": 30.528,
    "lng": -92.0851,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 24423,
    "id": 1840015014
  },
  {
    "city": "Kotelnich",
    "city_ascii": "Kotelnich",
    "lat": 58.3089,
    "lng": 48.3481,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kirovskaya Oblast’",
    "capital": "",
    "population": 23682,
    "id": 1643742016
  },
  {
    "city": "Camas",
    "city_ascii": "Camas",
    "lat": 45.6005,
    "lng": -122.4305,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 24418,
    "id": 1840018524
  },
  {
    "city": "Semënov",
    "city_ascii": "Semenov",
    "lat": 56.8,
    "lng": 44.5,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Nizhegorodskaya Oblast’",
    "capital": "",
    "population": 24400,
    "id": 1643525669
  },
  {
    "city": "Harvey",
    "city_ascii": "Harvey",
    "lat": 41.6076,
    "lng": -87.6521,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 24408,
    "id": 1840007029
  },
  {
    "city": "Trotwood",
    "city_ascii": "Trotwood",
    "lat": 39.7927,
    "lng": -84.3165,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 24403,
    "id": 1840010582
  },
  {
    "city": "West Memphis",
    "city_ascii": "West Memphis",
    "lat": 35.1531,
    "lng": -90.1995,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arkansas",
    "capital": "",
    "population": 24402,
    "id": 1840015448
  },
  {
    "city": "Bethany",
    "city_ascii": "Bethany",
    "lat": 45.5613,
    "lng": -122.8369,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 24396,
    "id": 1840034671
  },
  {
    "city": "Collinsville",
    "city_ascii": "Collinsville",
    "lat": 38.6769,
    "lng": -90.0059,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 24395,
    "id": 1840007423
  },
  {
    "city": "Mequon",
    "city_ascii": "Mequon",
    "lat": 43.2352,
    "lng": -87.9838,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 24382,
    "id": 1840037989
  },
  {
    "city": "Ellensburg",
    "city_ascii": "Ellensburg",
    "lat": 46.9994,
    "lng": -120.5477,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 24377,
    "id": 1840019841
  },
  {
    "city": "Rodniki",
    "city_ascii": "Rodniki",
    "lat": 57.1,
    "lng": 41.7333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Ivanovskaya Oblast’",
    "capital": "",
    "population": 24335,
    "id": 1643416659
  },
  {
    "city": "Marple",
    "city_ascii": "Marple",
    "lat": 53.397,
    "lng": -2.061,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Stockport",
    "capital": "",
    "population": 23687,
    "id": 1826000495
  },
  {
    "city": "Asino",
    "city_ascii": "Asino",
    "lat": 57,
    "lng": 86.15,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Tomskaya Oblast’",
    "capital": "",
    "population": 24354,
    "id": 1643361400
  },
  {
    "city": "Al Quşayr",
    "city_ascii": "Al Qusayr",
    "lat": 26.1091,
    "lng": 34.276,
    "country": "Egypt",
    "iso2": "EG",
    "iso3": "EGY",
    "admin_name": "Al Baḩr al Aḩmar",
    "capital": "",
    "population": 24344,
    "id": 1818907954
  },
  {
    "city": "Shelbyville",
    "city_ascii": "Shelbyville",
    "lat": 38.2069,
    "lng": -85.2293,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kentucky",
    "capital": "",
    "population": 24351,
    "id": 1840015205
  },
  {
    "city": "Dole",
    "city_ascii": "Dole",
    "lat": 47.0931,
    "lng": 5.4906,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Bourgogne-Franche-Comté",
    "capital": "minor",
    "population": 23708,
    "id": 1250000209
  },
  {
    "city": "Buturlinovka",
    "city_ascii": "Buturlinovka",
    "lat": 50.825,
    "lng": 40.5889,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Voronezhskaya Oblast’",
    "capital": "",
    "population": 24319,
    "id": 1643165289
  },
  {
    "city": "Goulburn",
    "city_ascii": "Goulburn",
    "lat": -34.7547,
    "lng": 149.6186,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 23835,
    "id": 1036347537
  },
  {
    "city": "Newport",
    "city_ascii": "Newport",
    "lat": 41.4803,
    "lng": -71.3205,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Rhode Island",
    "capital": "",
    "population": 24334,
    "id": 1840003355
  },
  {
    "city": "East Hempfield",
    "city_ascii": "East Hempfield",
    "lat": 40.0825,
    "lng": -76.3831,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 24329,
    "id": 1840148811
  },
  {
    "city": "Nueva Paz",
    "city_ascii": "Nueva Paz",
    "lat": 22.7667,
    "lng": -81.75,
    "country": "Cuba",
    "iso2": "CU",
    "iso3": "CUB",
    "admin_name": "Mayabeque",
    "capital": "minor",
    "population": 24277,
    "id": 1192235992
  },
  {
    "city": "Rimavská Sobota",
    "city_ascii": "Rimavska Sobota",
    "lat": 48.3814,
    "lng": 20.02,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Banskobystrický",
    "capital": "minor",
    "population": 24010,
    "id": 1703980891
  },
  {
    "city": "Saginaw",
    "city_ascii": "Saginaw",
    "lat": 32.8657,
    "lng": -97.3654,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 24310,
    "id": 1840022046
  },
  {
    "city": "Easton",
    "city_ascii": "Easton",
    "lat": 42.0362,
    "lng": -71.1103,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 24306,
    "id": 1840053660
  },
  {
    "city": "Saint-Laurent-du-Maroni",
    "city_ascii": "Saint-Laurent-du-Maroni",
    "lat": 5.4976,
    "lng": -54.0325,
    "country": "French Guiana",
    "iso2": "GF",
    "iso3": "GUF",
    "admin_name": "",
    "capital": "minor",
    "population": 24287,
    "id": 1254543638
  },
  {
    "city": "Port Angeles",
    "city_ascii": "Port Angeles",
    "lat": 48.1142,
    "lng": -123.4565,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 24293,
    "id": 1840019782
  },
  {
    "city": "Brandon",
    "city_ascii": "Brandon",
    "lat": 32.2778,
    "lng": -89.9896,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Mississippi",
    "capital": "",
    "population": 24289,
    "id": 1840013825
  },
  {
    "city": "Inkster",
    "city_ascii": "Inkster",
    "lat": 42.2935,
    "lng": -83.3148,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 24284,
    "id": 1840003983
  },
  {
    "city": "Wednesfield",
    "city_ascii": "Wednesfield",
    "lat": 52.5998,
    "lng": -2.0817,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Wolverhampton",
    "capital": "",
    "population": 22646,
    "id": 1826477782
  },
  {
    "city": "Thomasville",
    "city_ascii": "Thomasville",
    "lat": 30.8394,
    "lng": -83.9782,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 24275,
    "id": 1840015893
  },
  {
    "city": "Ponca City",
    "city_ascii": "Ponca City",
    "lat": 36.7235,
    "lng": -97.0679,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oklahoma",
    "capital": "",
    "population": 24274,
    "id": 1840020345
  },
  {
    "city": "Camillus",
    "city_ascii": "Camillus",
    "lat": 43.0539,
    "lng": -76.3057,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 24270,
    "id": 1840004331
  },
  {
    "city": "Gazojak",
    "city_ascii": "Gazojak",
    "lat": 41.1833,
    "lng": 61.4,
    "country": "Turkmenistan",
    "iso2": "TM",
    "iso3": "TKM",
    "admin_name": "Lebap",
    "capital": "",
    "population": 24251,
    "id": 1795672645
  },
  {
    "city": "Tecolutla",
    "city_ascii": "Tecolutla",
    "lat": 20.4797,
    "lng": -97.01,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Veracruz",
    "capital": "minor",
    "population": 24258,
    "id": 1484719991
  },
  {
    "city": "Oiapoque",
    "city_ascii": "Oiapoque",
    "lat": 3.8428,
    "lng": -51.835,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Amapá",
    "capital": "",
    "population": 24263,
    "id": 1076048629
  },
  {
    "city": "West Melbourne",
    "city_ascii": "West Melbourne",
    "lat": 28.0693,
    "lng": -80.6734,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 24259,
    "id": 1840015963
  },
  {
    "city": "Faribault",
    "city_ascii": "Faribault",
    "lat": 44.2985,
    "lng": -93.2786,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 24250,
    "id": 1840007889
  },
  {
    "city": "Medford",
    "city_ascii": "Medford",
    "lat": 40.822,
    "lng": -72.9859,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 24247,
    "id": 1840005043
  },
  {
    "city": "San Benito",
    "city_ascii": "San Benito",
    "lat": 26.1298,
    "lng": -97.644,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 24243,
    "id": 1840022260
  },
  {
    "city": "Litvínov",
    "city_ascii": "Litvinov",
    "lat": 50.6008,
    "lng": 13.6112,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Ústecký Kraj",
    "capital": "",
    "population": 23661,
    "id": 1203716170
  },
  {
    "city": "Diest",
    "city_ascii": "Diest",
    "lat": 50.9847,
    "lng": 5.0514,
    "country": "Belgium",
    "iso2": "BE",
    "iso3": "BEL",
    "admin_name": "Flanders",
    "capital": "",
    "population": 23824,
    "id": 1056065349
  },
  {
    "city": "Springfield Township",
    "city_ascii": "Springfield Township",
    "lat": 39.928,
    "lng": -75.3362,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 24232,
    "id": 1840147258
  },
  {
    "city": "Lincolnton",
    "city_ascii": "Lincolnton",
    "lat": 35.4747,
    "lng": -81.2385,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 24229,
    "id": 1840015427
  },
  {
    "city": "Bieruń Stary",
    "city_ascii": "Bierun Stary",
    "lat": 50.0897,
    "lng": 19.0928,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Śląskie",
    "capital": "",
    "population": 23640,
    "id": 1616995218
  },
  {
    "city": "Simpsonville",
    "city_ascii": "Simpsonville",
    "lat": 34.7287,
    "lng": -82.2573,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 24221,
    "id": 1840015477
  },
  {
    "city": "Almonte",
    "city_ascii": "Almonte",
    "lat": 37.2612,
    "lng": -6.5176,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Andalusia",
    "capital": "",
    "population": 24191,
    "id": 1724955448
  },
  {
    "city": "Varel",
    "city_ascii": "Varel",
    "lat": 53.3969,
    "lng": 8.1361,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 24001,
    "id": 1276834869
  },
  {
    "city": "Uchquduq Shahri",
    "city_ascii": "Uchquduq Shahri",
    "lat": 42.1567,
    "lng": 63.5556,
    "country": "Uzbekistan",
    "iso2": "UZ",
    "iso3": "UZB",
    "admin_name": "Navoiy",
    "capital": "minor",
    "population": 24200,
    "id": 1860459881
  },
  {
    "city": "Westford",
    "city_ascii": "Westford",
    "lat": 42.5864,
    "lng": -71.4401,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 24194,
    "id": 1840053493
  },
  {
    "city": "Rugeley",
    "city_ascii": "Rugeley",
    "lat": 52.7599,
    "lng": -1.9388,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Staffordshire",
    "capital": "",
    "population": 24033,
    "id": 1826009668
  },
  {
    "city": "Angatuba",
    "city_ascii": "Angatuba",
    "lat": -23.4745,
    "lng": -48.4256,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 24161,
    "id": 1076790515
  },
  {
    "city": "Scotch Plains",
    "city_ascii": "Scotch Plains",
    "lat": 40.6332,
    "lng": -74.3739,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 24183,
    "id": 1840081747
  },
  {
    "city": "Fort Washington",
    "city_ascii": "Fort Washington",
    "lat": 38.7339,
    "lng": -77.0069,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 24183,
    "id": 1840005953
  },
  {
    "city": "Diu",
    "city_ascii": "Diu",
    "lat": 20.7197,
    "lng": 70.9904,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Damān and Diu",
    "capital": "",
    "population": 23779,
    "id": 1356923516
  },
  {
    "city": "Grootfontein",
    "city_ascii": "Grootfontein",
    "lat": -19.5658,
    "lng": 18.1036,
    "country": "Namibia",
    "iso2": "NA",
    "iso3": "NAM",
    "admin_name": "Otjozondjupa",
    "capital": "",
    "population": 24099,
    "id": 1516286878
  },
  {
    "city": "Gauripur",
    "city_ascii": "Gauripur",
    "lat": 26.08,
    "lng": 89.97,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Assam",
    "capital": "",
    "population": 23477,
    "id": 1356178626
  },
  {
    "city": "Ashton in Makerfield",
    "city_ascii": "Ashton in Makerfield",
    "lat": 53.487,
    "lng": -2.641,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Wigan",
    "capital": "",
    "population": 24000,
    "id": 1826919005
  },
  {
    "city": "Quedlinburg",
    "city_ascii": "Quedlinburg",
    "lat": 51.7917,
    "lng": 11.1472,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony-Anhalt",
    "capital": "",
    "population": 23989,
    "id": 1276672547
  },
  {
    "city": "Fort Saskatchewan",
    "city_ascii": "Fort Saskatchewan",
    "lat": 53.7128,
    "lng": -113.2133,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Alberta",
    "capital": "",
    "population": 24149,
    "id": 1124769097
  },
  {
    "city": "Upper Moreland",
    "city_ascii": "Upper Moreland",
    "lat": 40.1572,
    "lng": -75.1021,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 24148,
    "id": 1840152387
  },
  {
    "city": "North Potomac",
    "city_ascii": "North Potomac",
    "lat": 39.0955,
    "lng": -77.2372,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 24148,
    "id": 1840005835
  },
  {
    "city": "Al Jawf",
    "city_ascii": "Al Jawf",
    "lat": 24.2167,
    "lng": 23.3,
    "country": "Libya",
    "iso2": "LY",
    "iso3": "LBY",
    "admin_name": "Al Kufrah",
    "capital": "",
    "population": 24132,
    "id": 1434996940
  },
  {
    "city": "Sattahip",
    "city_ascii": "Sattahip",
    "lat": 12.6636,
    "lng": 100.9036,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Chon Buri",
    "capital": "minor",
    "population": 23418,
    "id": 1764518101
  },
  {
    "city": "Zolochiv",
    "city_ascii": "Zolochiv",
    "lat": 49.8075,
    "lng": 24.9031,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "L’vivs’ka Oblast’",
    "capital": "minor",
    "population": 24059,
    "id": 1804914979
  },
  {
    "city": "Ottumwa",
    "city_ascii": "Ottumwa",
    "lat": 41.0196,
    "lng": -92.4186,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Iowa",
    "capital": "",
    "population": 24121,
    "id": 1840009339
  },
  {
    "city": "Formby",
    "city_ascii": "Formby",
    "lat": 53.5586,
    "lng": -3.0666,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Sefton",
    "capital": "",
    "population": 22419,
    "id": 1826350182
  },
  {
    "city": "Sonneberg",
    "city_ascii": "Sonneberg",
    "lat": 50.35,
    "lng": 11.1667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Thuringia",
    "capital": "minor",
    "population": 23830,
    "id": 1276304773
  },
  {
    "city": "Döbeln",
    "city_ascii": "Dobeln",
    "lat": 51.1194,
    "lng": 13.1128,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 23829,
    "id": 1276060973
  },
  {
    "city": "Amstetten",
    "city_ascii": "Amstetten",
    "lat": 48.1167,
    "lng": 14.8667,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Niederösterreich",
    "capital": "minor",
    "population": 23656,
    "id": 1040466200
  },
  {
    "city": "Koilkuntla",
    "city_ascii": "Koilkuntla",
    "lat": 15.2333,
    "lng": 78.3167,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Andhra Pradesh",
    "capital": "",
    "population": 23859,
    "id": 1356024299
  },
  {
    "city": "Ridgeland",
    "city_ascii": "Ridgeland",
    "lat": 32.4236,
    "lng": -90.1481,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Mississippi",
    "capital": "",
    "population": 24104,
    "id": 1840014873
  },
  {
    "city": "Hastings",
    "city_ascii": "Hastings",
    "lat": 40.5963,
    "lng": -98.3914,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Nebraska",
    "capital": "",
    "population": 24101,
    "id": 1840001130
  },
  {
    "city": "North Platte",
    "city_ascii": "North Platte",
    "lat": 41.1266,
    "lng": -100.764,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Nebraska",
    "capital": "",
    "population": 24098,
    "id": 1840008251
  },
  {
    "city": "As Sulayyil",
    "city_ascii": "As Sulayyil",
    "lat": 20.4623,
    "lng": 45.5722,
    "country": "Saudi Arabia",
    "iso2": "SA",
    "iso3": "SAU",
    "admin_name": "Ar Riyāḑ",
    "capital": "",
    "population": 24097,
    "id": 1682960661
  },
  {
    "city": "Charles Town",
    "city_ascii": "Charles Town",
    "lat": 39.2745,
    "lng": -77.8632,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "West Virginia",
    "capital": "",
    "population": 24094,
    "id": 1840005759
  },
  {
    "city": "Freeport",
    "city_ascii": "Freeport",
    "lat": 42.2893,
    "lng": -89.6347,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 24092,
    "id": 1840008083
  },
  {
    "city": "Waukee",
    "city_ascii": "Waukee",
    "lat": 41.5984,
    "lng": -93.8872,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Iowa",
    "capital": "",
    "population": 24089,
    "id": 1840010181
  },
  {
    "city": "Warrington",
    "city_ascii": "Warrington",
    "lat": 40.2489,
    "lng": -75.158,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 24088,
    "id": 1840035400
  },
  {
    "city": "Vero Beach South",
    "city_ascii": "Vero Beach South",
    "lat": 27.6164,
    "lng": -80.413,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 24082,
    "id": 1840073853
  },
  {
    "city": "Lanester",
    "city_ascii": "Lanester",
    "lat": 47.7647,
    "lng": -3.3422,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Bretagne",
    "capital": "",
    "population": 22728,
    "id": 1250476491
  },
  {
    "city": "Ban Phru",
    "city_ascii": "Ban Phru",
    "lat": 6.948,
    "lng": 100.4794,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Songkhla",
    "capital": "",
    "population": 23518,
    "id": 1764608441
  },
  {
    "city": "Ventimiglia",
    "city_ascii": "Ventimiglia",
    "lat": 43.7903,
    "lng": 7.6083,
    "country": "Italy",
    "iso2": "IT",
    "iso3": "ITA",
    "admin_name": "Liguria",
    "capital": "",
    "population": 24065,
    "id": 1380716377
  },
  {
    "city": "Bognor Regis",
    "city_ascii": "Bognor Regis",
    "lat": 50.7824,
    "lng": -0.6764,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "West Sussex",
    "capital": "",
    "population": 24064,
    "id": 1826873593
  },
  {
    "city": "Zawyat an Nwaçer",
    "city_ascii": "Zawyat an Nwacer",
    "lat": 33.3611,
    "lng": -7.6114,
    "country": "Morocco",
    "iso2": "MA",
    "iso3": "MAR",
    "admin_name": "Casablanca-Settat",
    "capital": "",
    "population": 23802,
    "id": 1504451401
  },
  {
    "city": "Tayga",
    "city_ascii": "Tayga",
    "lat": 56.0667,
    "lng": 85.6167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kemerovskaya Oblast’",
    "capital": "",
    "population": 23565,
    "id": 1643905675
  },
  {
    "city": "Socastee",
    "city_ascii": "Socastee",
    "lat": 33.6871,
    "lng": -79.0086,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 24056,
    "id": 1840013647
  },
  {
    "city": "Husum",
    "city_ascii": "Husum",
    "lat": 54.4769,
    "lng": 9.0511,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Schleswig-Holstein",
    "capital": "minor",
    "population": 23158,
    "id": 1276973778
  },
  {
    "city": "South Plainfield",
    "city_ascii": "South Plainfield",
    "lat": 40.5748,
    "lng": -74.4153,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 24052,
    "id": 1840001334
  },
  {
    "city": "Konstancin-Jeziorna",
    "city_ascii": "Konstancin-Jeziorna",
    "lat": 52.0938,
    "lng": 21.1176,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Mazowieckie",
    "capital": "",
    "population": 23694,
    "id": 1616018861
  },
  {
    "city": "Wadsworth",
    "city_ascii": "Wadsworth",
    "lat": 41.0279,
    "lng": -81.7324,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 24046,
    "id": 1840010294
  },
  {
    "city": "Kourou",
    "city_ascii": "Kourou",
    "lat": 5.16,
    "lng": -52.6499,
    "country": "French Guiana",
    "iso2": "GF",
    "iso3": "GUF",
    "admin_name": "",
    "capital": "minor",
    "population": 24029,
    "id": 1254317561
  },
  {
    "city": "Horley",
    "city_ascii": "Horley",
    "lat": 51.174,
    "lng": -0.172,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Surrey",
    "capital": "",
    "population": 22076,
    "id": 1826232822
  },
  {
    "city": "Puerto San José",
    "city_ascii": "Puerto San Jose",
    "lat": 13.9333,
    "lng": -90.8167,
    "country": "Guatemala",
    "iso2": "GT",
    "iso3": "GTM",
    "admin_name": "Escuintla",
    "capital": "",
    "population": 23887,
    "id": 1320016478
  },
  {
    "city": "Sokolov",
    "city_ascii": "Sokolov",
    "lat": 50.1814,
    "lng": 12.6402,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Karlovarský Kraj",
    "capital": "",
    "population": 23033,
    "id": 1203662180
  },
  {
    "city": "Zorgo",
    "city_ascii": "Zorgo",
    "lat": 12.243,
    "lng": -0.611,
    "country": "Burkina Faso",
    "iso2": "BF",
    "iso3": "BFA",
    "admin_name": "Plateau-Central",
    "capital": "minor",
    "population": 23892,
    "id": 1854077108
  },
  {
    "city": "Nava",
    "city_ascii": "Nava",
    "lat": 28.4214,
    "lng": -100.7675,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Coahuila de Zaragoza",
    "capital": "minor",
    "population": 22192,
    "id": 1484120104
  },
  {
    "city": "Luninyets",
    "city_ascii": "Luninyets",
    "lat": 52.25,
    "lng": 26.8,
    "country": "Belarus",
    "iso2": "BY",
    "iso3": "BLR",
    "admin_name": "Brestskaya Voblasts’",
    "capital": "minor",
    "population": 24000,
    "id": 1112500648
  },
  {
    "city": "Tumwater",
    "city_ascii": "Tumwater",
    "lat": 46.989,
    "lng": -122.9173,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 24024,
    "id": 1840021145
  },
  {
    "city": "Candler-McAfee",
    "city_ascii": "Candler-McAfee",
    "lat": 33.7267,
    "lng": -84.2723,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 24022,
    "id": 1840073846
  },
  {
    "city": "Hérouville-Saint-Clair",
    "city_ascii": "Herouville-Saint-Clair",
    "lat": 49.2044,
    "lng": -0.3253,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Normandie",
    "capital": "",
    "population": 22954,
    "id": 1250401082
  },
  {
    "city": "Fairfax",
    "city_ascii": "Fairfax",
    "lat": 38.8531,
    "lng": -77.2998,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 24019,
    "id": 1840001650
  },
  {
    "city": "Hoh Ereg",
    "city_ascii": "Hoh Ereg",
    "lat": 41.0955,
    "lng": 111.4408,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Inner Mongolia",
    "capital": "minor",
    "population": 23776,
    "id": 1156060937
  },
  {
    "city": "East Gwillimbury",
    "city_ascii": "East Gwillimbury",
    "lat": 44.1333,
    "lng": -79.4167,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 23991,
    "id": 1124001370
  },
  {
    "city": "Venice",
    "city_ascii": "Venice",
    "lat": 27.1163,
    "lng": -82.4135,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 23985,
    "id": 1840015989
  },
  {
    "city": "Calw",
    "city_ascii": "Calw",
    "lat": 48.7144,
    "lng": 8.7375,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "minor",
    "population": 23590,
    "id": 1276416126
  },
  {
    "city": "Grande-Synthe",
    "city_ascii": "Grande-Synthe",
    "lat": 51.0139,
    "lng": 2.3028,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Hauts-de-France",
    "capital": "",
    "population": 22966,
    "id": 1250318038
  },
  {
    "city": "Kelīshād va Sūdarjān",
    "city_ascii": "Kelishad va Sudarjan",
    "lat": 32.5542,
    "lng": 51.525,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Eşfahān",
    "capital": "",
    "population": 23203,
    "id": 1364528827
  },
  {
    "city": "Hajdúszoboszló",
    "city_ascii": "Hajduszoboszlo",
    "lat": 47.45,
    "lng": 21.3833,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Hajdú-Bihar",
    "capital": "minor",
    "population": 23873,
    "id": 1348837635
  },
  {
    "city": "Harmanli",
    "city_ascii": "Harmanli",
    "lat": 41.9297,
    "lng": 25.9019,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Haskovo",
    "capital": "",
    "population": 23932,
    "id": 1100003361
  },
  {
    "city": "Halfmoon",
    "city_ascii": "Halfmoon",
    "lat": 42.864,
    "lng": -73.7275,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 23962,
    "id": 1840058153
  },
  {
    "city": "Kalach-na-Donu",
    "city_ascii": "Kalach-na-Donu",
    "lat": 48.6833,
    "lng": 43.5333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Volgogradskaya Oblast’",
    "capital": "",
    "population": 23947,
    "id": 1643990894
  },
  {
    "city": "Cottage Lake",
    "city_ascii": "Cottage Lake",
    "lat": 47.7466,
    "lng": -122.0755,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 23955,
    "id": 1840037848
  },
  {
    "city": "La Maná",
    "city_ascii": "La Mana",
    "lat": -0.9417,
    "lng": -79.2347,
    "country": "Ecuador",
    "iso2": "EC",
    "iso3": "ECU",
    "admin_name": "Cotopaxi",
    "capital": "",
    "population": 23775,
    "id": 1218200586
  },
  {
    "city": "Haren",
    "city_ascii": "Haren",
    "lat": 52.7667,
    "lng": 7.2167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 23829,
    "id": 1276278460
  },
  {
    "city": "Bogotol",
    "city_ascii": "Bogotol",
    "lat": 56.2,
    "lng": 89.5167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Krasnoyarskiy Kray",
    "capital": "",
    "population": 23622,
    "id": 1643208513
  },
  {
    "city": "Carás",
    "city_ascii": "Caras",
    "lat": -9.0483,
    "lng": -77.8108,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "Ancash",
    "capital": "",
    "population": 23841,
    "id": 1604139629
  },
  {
    "city": "Edgewater",
    "city_ascii": "Edgewater",
    "lat": 28.9594,
    "lng": -80.9407,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 23918,
    "id": 1840014043
  },
  {
    "city": "Tlacolula",
    "city_ascii": "Tlacolula",
    "lat": 20,
    "lng": -97,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Veracruz",
    "capital": "",
    "population": 23865,
    "id": 1484055571
  },
  {
    "city": "Okemos",
    "city_ascii": "Okemos",
    "lat": 42.7057,
    "lng": -84.4135,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 23912,
    "id": 1840004502
  },
  {
    "city": "Pelham",
    "city_ascii": "Pelham",
    "lat": 33.3114,
    "lng": -86.7573,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 23911,
    "id": 1840014826
  },
  {
    "city": "Si Racha",
    "city_ascii": "Si Racha",
    "lat": 13.1744,
    "lng": 100.9306,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Chon Buri",
    "capital": "minor",
    "population": 23400,
    "id": 1764003327
  },
  {
    "city": "Monte Aprazível",
    "city_ascii": "Monte Aprazivel",
    "lat": -20.7728,
    "lng": -49.7139,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 23857,
    "id": 1076150563
  },
  {
    "city": "Taupo",
    "city_ascii": "Taupo",
    "lat": -38.69,
    "lng": 176.08,
    "country": "New Zealand",
    "iso2": "NZ",
    "iso3": "NZL",
    "admin_name": "Waikato",
    "capital": "",
    "population": 23900,
    "id": 1554654784
  },
  {
    "city": "Nový Jičín",
    "city_ascii": "Novy Jicin",
    "lat": 49.5944,
    "lng": 18.0103,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Moravskoslezský Kraj",
    "capital": "",
    "population": 23260,
    "id": 1203475212
  },
  {
    "city": "Seal Beach",
    "city_ascii": "Seal Beach",
    "lat": 33.7542,
    "lng": -118.0714,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 23896,
    "id": 1840021965
  },
  {
    "city": "Ciudad Serdán",
    "city_ascii": "Ciudad Serdan",
    "lat": 18.9833,
    "lng": -97.45,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Puebla",
    "capital": "minor",
    "population": 23824,
    "id": 1484943496
  },
  {
    "city": "Bad Mergentheim",
    "city_ascii": "Bad Mergentheim",
    "lat": 49.5,
    "lng": 9.7667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 23704,
    "id": 1276714498
  },
  {
    "city": "Mirandela",
    "city_ascii": "Mirandela",
    "lat": 41.4833,
    "lng": -7.1833,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Bragança",
    "capital": "minor",
    "population": 23850,
    "id": 1620845288
  },
  {
    "city": "Alice Springs",
    "city_ascii": "Alice Springs",
    "lat": -23.7,
    "lng": 133.8667,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Northern Territory",
    "capital": "",
    "population": 23726,
    "id": 1036830397
  },
  {
    "city": "Combs-la-Ville",
    "city_ascii": "Combs-la-Ville",
    "lat": 48.67,
    "lng": 2.56,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 22383,
    "id": 1250661921
  },
  {
    "city": "Cranford",
    "city_ascii": "Cranford",
    "lat": 40.6564,
    "lng": -74.3036,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 23882,
    "id": 1840081744
  },
  {
    "city": "Qifţ",
    "city_ascii": "Qift",
    "lat": 26,
    "lng": 32.8167,
    "country": "Egypt",
    "iso2": "EG",
    "iso3": "EGY",
    "admin_name": "Qinā",
    "capital": "",
    "population": 22063,
    "id": 1818432841
  },
  {
    "city": "Starnberg",
    "city_ascii": "Starnberg",
    "lat": 47.9972,
    "lng": 11.3406,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "minor",
    "population": 23498,
    "id": 1276454637
  },
  {
    "city": "Dunkirk",
    "city_ascii": "Dunkirk",
    "lat": 42.4803,
    "lng": -79.3323,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 23874,
    "id": 1840000445
  },
  {
    "city": "Zeya",
    "city_ascii": "Zeya",
    "lat": 53.7333,
    "lng": 127.25,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Amurskaya Oblast’",
    "capital": "",
    "population": 23270,
    "id": 1643518761
  },
  {
    "city": "Chrudim",
    "city_ascii": "Chrudim",
    "lat": 49.9511,
    "lng": 15.7956,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Pardubický Kraj",
    "capital": "",
    "population": 23168,
    "id": 1203309920
  },
  {
    "city": "Calabasas",
    "city_ascii": "Calabasas",
    "lat": 34.1375,
    "lng": -118.6689,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 23853,
    "id": 1840019234
  },
  {
    "city": "Abbeville",
    "city_ascii": "Abbeville",
    "lat": 50.1058,
    "lng": 1.8358,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Hauts-de-France",
    "capital": "minor",
    "population": 22946,
    "id": 1250376759
  },
  {
    "city": "Lustenau",
    "city_ascii": "Lustenau",
    "lat": 47.4271,
    "lng": 9.6711,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Vorarlberg",
    "capital": "",
    "population": 22821,
    "id": 1040745157
  },
  {
    "city": "Berehove",
    "city_ascii": "Berehove",
    "lat": 48.2025,
    "lng": 22.6375,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Zakarpats’ka Oblast’",
    "capital": "minor",
    "population": 23732,
    "id": 1804040318
  },
  {
    "city": "North Augusta",
    "city_ascii": "North Augusta",
    "lat": 33.5214,
    "lng": -81.9547,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 23845,
    "id": 1840014788
  },
  {
    "city": "Step’anavan",
    "city_ascii": "Step'anavan",
    "lat": 41.0075,
    "lng": 44.3867,
    "country": "Armenia",
    "iso2": "AM",
    "iso3": "ARM",
    "admin_name": "Lorri",
    "capital": "",
    "population": 23782,
    "id": 1051221961
  },
  {
    "city": "Ostrów Mazowiecka",
    "city_ascii": "Ostrow Mazowiecka",
    "lat": 52.8,
    "lng": 21.9,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Mazowieckie",
    "capital": "minor",
    "population": 22800,
    "id": 1616194953
  },
  {
    "city": "Kafr Sa‘d",
    "city_ascii": "Kafr Sa`d",
    "lat": 31.3557,
    "lng": 31.6848,
    "country": "Egypt",
    "iso2": "EG",
    "iso3": "EGY",
    "admin_name": "Dumyāţ",
    "capital": "",
    "population": 22500,
    "id": 1818266119
  },
  {
    "city": "Bedburg",
    "city_ascii": "Bedburg",
    "lat": 51,
    "lng": 6.5625,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 23531,
    "id": 1276409548
  },
  {
    "city": "Mount Pocono",
    "city_ascii": "Mount Pocono",
    "lat": 41.1225,
    "lng": -75.3582,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 23820,
    "id": 1840003508
  },
  {
    "city": "Fort Dodge",
    "city_ascii": "Fort Dodge",
    "lat": 42.5098,
    "lng": -94.1751,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Iowa",
    "capital": "",
    "population": 23807,
    "id": 1840000439
  },
  {
    "city": "Havlíčkŭv Brod",
    "city_ascii": "Havlickuv Brod",
    "lat": 49.6079,
    "lng": 15.5807,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Vysočina",
    "capital": "",
    "population": 23442,
    "id": 1203272361
  },
  {
    "city": "Brody",
    "city_ascii": "Brody",
    "lat": 50.0781,
    "lng": 25.1542,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "L’vivs’ka Oblast’",
    "capital": "minor",
    "population": 23752,
    "id": 1804538798
  },
  {
    "city": "Quartier Militaire",
    "city_ascii": "Quartier Militaire",
    "lat": -20.25,
    "lng": 57.55,
    "country": "Mauritius",
    "iso2": "MU",
    "iso3": "MUS",
    "admin_name": "Moka",
    "capital": "",
    "population": 22745,
    "id": 1480030855
  },
  {
    "city": "Lincoln",
    "city_ascii": "Lincoln",
    "lat": 43.13,
    "lng": -79.43,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 23787,
    "id": 1124001767
  },
  {
    "city": "North Haven",
    "city_ascii": "North Haven",
    "lat": 41.3818,
    "lng": -72.8573,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 23786,
    "id": 1840035515
  },
  {
    "city": "Krnov",
    "city_ascii": "Krnov",
    "lat": 50.0897,
    "lng": 17.7039,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Moravskoslezský Kraj",
    "capital": "",
    "population": 23257,
    "id": 1203745289
  },
  {
    "city": "Farragut",
    "city_ascii": "Farragut",
    "lat": 35.8731,
    "lng": -84.1822,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 23778,
    "id": 1840016171
  },
  {
    "city": "Gobō",
    "city_ascii": "Gobo",
    "lat": 33.8914,
    "lng": 135.1525,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Wakayama",
    "capital": "",
    "population": 23246,
    "id": 1392651383
  },
  {
    "city": "Mosbach",
    "city_ascii": "Mosbach",
    "lat": 49.35,
    "lng": 9.1333,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "minor",
    "population": 23398,
    "id": 1276659317
  },
  {
    "city": "Korbach",
    "city_ascii": "Korbach",
    "lat": 51.2719,
    "lng": 8.8731,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "minor",
    "population": 23581,
    "id": 1276822678
  },
  {
    "city": "Kuvandyk",
    "city_ascii": "Kuvandyk",
    "lat": 51.4833,
    "lng": 57.35,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Orenburgskaya Oblast’",
    "capital": "",
    "population": 23763,
    "id": 1643197108
  },
  {
    "city": "Jenks",
    "city_ascii": "Jenks",
    "lat": 35.9981,
    "lng": -95.9737,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oklahoma",
    "capital": "",
    "population": 23767,
    "id": 1840019060
  },
  {
    "city": "Mansfield",
    "city_ascii": "Mansfield",
    "lat": 42.0163,
    "lng": -71.2187,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 23761,
    "id": 1840070235
  },
  {
    "city": "Fria",
    "city_ascii": "Fria",
    "lat": 10.3804,
    "lng": -13.55,
    "country": "Guinea",
    "iso2": "GN",
    "iso3": "GIN",
    "admin_name": "Boké",
    "capital": "minor",
    "population": 23729,
    "id": 1324583144
  },
  {
    "city": "Bloomingdale",
    "city_ascii": "Bloomingdale",
    "lat": 27.8784,
    "lng": -82.2624,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 23758,
    "id": 1840014150
  },
  {
    "city": "Huntingdon",
    "city_ascii": "Huntingdon",
    "lat": 52.3364,
    "lng": -0.1717,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Cambridgeshire",
    "capital": "",
    "population": 23732,
    "id": 1826796900
  },
  {
    "city": "Pace",
    "city_ascii": "Pace",
    "lat": 30.6187,
    "lng": -87.1667,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 23749,
    "id": 1840013919
  },
  {
    "city": "Herdecke",
    "city_ascii": "Herdecke",
    "lat": 51.4,
    "lng": 7.4333,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 22733,
    "id": 1276078079
  },
  {
    "city": "Ayancık",
    "city_ascii": "Ayancik",
    "lat": 41.95,
    "lng": 34.5833,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Sinop",
    "capital": "minor",
    "population": 23720,
    "id": 1792845166
  },
  {
    "city": "Palatka",
    "city_ascii": "Palatka",
    "lat": 29.6493,
    "lng": -81.6705,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 23738,
    "id": 1840015062
  },
  {
    "city": "Tatarsk",
    "city_ascii": "Tatarsk",
    "lat": 55.2167,
    "lng": 75.9667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Novosibirskaya Oblast’",
    "capital": "",
    "population": 23729,
    "id": 1643001589
  },
  {
    "city": "Salzwedel",
    "city_ascii": "Salzwedel",
    "lat": 52.85,
    "lng": 11.15,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony-Anhalt",
    "capital": "minor",
    "population": 23655,
    "id": 1276222011
  },
  {
    "city": "Coronado",
    "city_ascii": "Coronado",
    "lat": 32.664,
    "lng": -117.16,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 23731,
    "id": 1840019354
  },
  {
    "city": "Lubartów",
    "city_ascii": "Lubartow",
    "lat": 51.4667,
    "lng": 22.6,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Lubelskie",
    "capital": "minor",
    "population": 22138,
    "id": 1616620144
  },
  {
    "city": "Khromtaū",
    "city_ascii": "Khromtau",
    "lat": 50.2699,
    "lng": 58.45,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Aqtöbe",
    "capital": "",
    "population": 23715,
    "id": 1398766984
  },
  {
    "city": "Épernay",
    "city_ascii": "Epernay",
    "lat": 49.0403,
    "lng": 3.96,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Grand Est",
    "capital": "minor",
    "population": 22671,
    "id": 1250669330
  },
  {
    "city": "Freudenstadt",
    "city_ascii": "Freudenstadt",
    "lat": 48.4633,
    "lng": 8.4111,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "minor",
    "population": 23442,
    "id": 1276092007
  },
  {
    "city": "Montgomery",
    "city_ascii": "Montgomery",
    "lat": 41.5399,
    "lng": -74.2073,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 23709,
    "id": 1840004875
  },
  {
    "city": "Marple",
    "city_ascii": "Marple",
    "lat": 39.9654,
    "lng": -75.3658,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 23709,
    "id": 1840100266
  },
  {
    "city": "Nagykőrös",
    "city_ascii": "Nagykoros",
    "lat": 47.0331,
    "lng": 19.7839,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Pest",
    "capital": "minor",
    "population": 23605,
    "id": 1348967171
  },
  {
    "city": "Bauta",
    "city_ascii": "Bauta",
    "lat": 22.9919,
    "lng": -82.5492,
    "country": "Cuba",
    "iso2": "CU",
    "iso3": "CUB",
    "admin_name": "Artemisa",
    "capital": "minor",
    "population": 23557,
    "id": 1192335041
  },
  {
    "city": "Centerville",
    "city_ascii": "Centerville",
    "lat": 39.6339,
    "lng": -84.1449,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 23703,
    "id": 1840007308
  },
  {
    "city": "Araçuaí",
    "city_ascii": "Aracuai",
    "lat": -16.8596,
    "lng": -42.0699,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Minas Gerais",
    "capital": "",
    "population": 22005,
    "id": 1076501078
  },
  {
    "city": "Nikaho",
    "city_ascii": "Nikaho",
    "lat": 39.2031,
    "lng": 139.9078,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Akita",
    "capital": "",
    "population": 23602,
    "id": 1392175644
  },
  {
    "city": "Maracaju",
    "city_ascii": "Maracaju",
    "lat": -21.61,
    "lng": -55.18,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Mato Grosso do Sul",
    "capital": "",
    "population": 22690,
    "id": 1076548335
  },
  {
    "city": "Kreuzlingen",
    "city_ascii": "Kreuzlingen",
    "lat": 47.6458,
    "lng": 9.1783,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Thurgau",
    "capital": "minor",
    "population": 21801,
    "id": 1756463563
  },
  {
    "city": "Sredneuralsk",
    "city_ascii": "Sredneuralsk",
    "lat": 56.9833,
    "lng": 60.4667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sverdlovskaya Oblast’",
    "capital": "",
    "population": 23353,
    "id": 1643770147
  },
  {
    "city": "Greeneville",
    "city_ascii": "Greeneville",
    "lat": 36.168,
    "lng": -82.8197,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 23683,
    "id": 1840016099
  },
  {
    "city": "Van Buren",
    "city_ascii": "Van Buren",
    "lat": 35.448,
    "lng": -94.3528,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arkansas",
    "capital": "",
    "population": 23683,
    "id": 1840015397
  },
  {
    "city": "Tata",
    "city_ascii": "Tata",
    "lat": 47.65,
    "lng": 18.3167,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Komárom-Esztergom",
    "capital": "minor",
    "population": 23377,
    "id": 1348967161
  },
  {
    "city": "Waycross",
    "city_ascii": "Waycross",
    "lat": 31.2108,
    "lng": -82.3579,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 23671,
    "id": 1840015871
  },
  {
    "city": "Ayapel",
    "city_ascii": "Ayapel",
    "lat": 8.3304,
    "lng": -75.15,
    "country": "Colombia",
    "iso2": "CO",
    "iso3": "COL",
    "admin_name": "Córdoba",
    "capital": "minor",
    "population": 23639,
    "id": 1170146577
  },
  {
    "city": "Kozyatyn",
    "city_ascii": "Kozyatyn",
    "lat": 49.7167,
    "lng": 28.8333,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Vinnyts’ka Oblast’",
    "capital": "minor",
    "population": 23610,
    "id": 1804731678
  },
  {
    "city": "Partizánske",
    "city_ascii": "Partizanske",
    "lat": 48.6333,
    "lng": 18.3667,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Nitriansky",
    "capital": "minor",
    "population": 22653,
    "id": 1703749885
  },
  {
    "city": "Soledad Atzompa",
    "city_ascii": "Soledad Atzompa",
    "lat": 18.755,
    "lng": -97.1522,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Veracruz",
    "capital": "minor",
    "population": 23130,
    "id": 1484642759
  },
  {
    "city": "Jüchen",
    "city_ascii": "Juchen",
    "lat": 51.1011,
    "lng": 6.5017,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 23337,
    "id": 1276318248
  },
  {
    "city": "Bulle",
    "city_ascii": "Bulle",
    "lat": 46.6167,
    "lng": 7.05,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Fribourg",
    "capital": "",
    "population": 22709,
    "id": 1756347311
  },
  {
    "city": "Wilmington",
    "city_ascii": "Wilmington",
    "lat": 42.5609,
    "lng": -71.1654,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 23658,
    "id": 1840053495
  },
  {
    "city": "Carletonville",
    "city_ascii": "Carletonville",
    "lat": -26.3581,
    "lng": 27.3981,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "Gauteng",
    "capital": "",
    "population": 23000,
    "id": 1710257170
  },
  {
    "city": "Half Moon Bay",
    "city_ascii": "Half Moon Bay",
    "lat": 37.4688,
    "lng": -122.4383,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 23653,
    "id": 1840020303
  },
  {
    "city": "Juchitepec",
    "city_ascii": "Juchitepec",
    "lat": 19.0997,
    "lng": -98.8792,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "México",
    "capital": "minor",
    "population": 23497,
    "id": 1484007934
  },
  {
    "city": "Zheleznogorsk-Ilimskiy",
    "city_ascii": "Zheleznogorsk-Ilimskiy",
    "lat": 56.5833,
    "lng": 104.1167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Irkutskaya Oblast’",
    "capital": "",
    "population": 23643,
    "id": 1643429689
  },
  {
    "city": "Dillenburg",
    "city_ascii": "Dillenburg",
    "lat": 50.7333,
    "lng": 8.2833,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 23365,
    "id": 1276583007
  },
  {
    "city": "Gitega",
    "city_ascii": "Gitega",
    "lat": -3.426,
    "lng": 29.8436,
    "country": "Burundi",
    "iso2": "BI",
    "iso3": "BDI",
    "admin_name": "Mwaro",
    "capital": "",
    "population": 23167,
    "id": 1108962433
  },
  {
    "city": "Belton",
    "city_ascii": "Belton",
    "lat": 38.8193,
    "lng": -94.5334,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 23642,
    "id": 1840007463
  },
  {
    "city": "Westchase",
    "city_ascii": "Westchase",
    "lat": 28.0597,
    "lng": -82.611,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 23636,
    "id": 1840029017
  },
  {
    "city": "Bāglung",
    "city_ascii": "Baglung",
    "lat": 28.2667,
    "lng": 83.5833,
    "country": "Nepal",
    "iso2": "NP",
    "iso3": "NPL",
    "admin_name": "Dhawalāgiri",
    "capital": "",
    "population": 23296,
    "id": 1524266307
  },
  {
    "city": "Jacksonville Beach",
    "city_ascii": "Jacksonville Beach",
    "lat": 30.2782,
    "lng": -81.4045,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 23628,
    "id": 1840015032
  },
  {
    "city": "Coronel Suárez",
    "city_ascii": "Coronel Suarez",
    "lat": -37.4547,
    "lng": -61.9334,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires",
    "capital": "minor",
    "population": 23621,
    "id": 1032444981
  },
  {
    "city": "Palm River-Clair Mel",
    "city_ascii": "Palm River-Clair Mel",
    "lat": 27.9239,
    "lng": -82.3791,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 23627,
    "id": 1840073873
  },
  {
    "city": "Sehnde",
    "city_ascii": "Sehnde",
    "lat": 52.3161,
    "lng": 9.9642,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 23389,
    "id": 1276201782
  },
  {
    "city": "Herzogenaurach",
    "city_ascii": "Herzogenaurach",
    "lat": 49.5667,
    "lng": 10.8833,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 23126,
    "id": 1276809658
  },
  {
    "city": "Ilion",
    "city_ascii": "Ilion",
    "lat": 43.0114,
    "lng": -75.0396,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 23608,
    "id": 1840004149
  },
  {
    "city": "Kulgam",
    "city_ascii": "Kulgam",
    "lat": 33.64,
    "lng": 75.02,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Jammu and Kashmīr",
    "capital": "",
    "population": 23584,
    "id": 1356729904
  },
  {
    "city": "Macia",
    "city_ascii": "Macia",
    "lat": -25.0333,
    "lng": 33.1,
    "country": "Mozambique",
    "iso2": "MZ",
    "iso3": "MOZ",
    "admin_name": "Gaza",
    "capital": "",
    "population": 23156,
    "id": 1508558129
  },
  {
    "city": "Chalmette",
    "city_ascii": "Chalmette",
    "lat": 29.9437,
    "lng": -89.9659,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 23602,
    "id": 1840014001
  },
  {
    "city": "El Seibo",
    "city_ascii": "El Seibo",
    "lat": 18.764,
    "lng": -69.035,
    "country": "Dominican Republic",
    "iso2": "DO",
    "iso3": "DOM",
    "admin_name": "Yuma",
    "capital": "minor",
    "population": 23547,
    "id": 1214331828
  },
  {
    "city": "Seymour",
    "city_ascii": "Seymour",
    "lat": 38.9476,
    "lng": -85.891,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 23598,
    "id": 1840009708
  },
  {
    "city": "Upper Macungie",
    "city_ascii": "Upper Macungie",
    "lat": 40.5694,
    "lng": -75.6244,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 23597,
    "id": 1840146587
  },
  {
    "city": "Oakdale",
    "city_ascii": "Oakdale",
    "lat": 37.7616,
    "lng": -120.847,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 23596,
    "id": 1840020289
  },
  {
    "city": "Ipatovo",
    "city_ascii": "Ipatovo",
    "lat": 45.7167,
    "lng": 42.9,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Stavropol’skiy Kray",
    "capital": "",
    "population": 23579,
    "id": 1643097291
  },
  {
    "city": "Bronnitsy",
    "city_ascii": "Bronnitsy",
    "lat": 55.422,
    "lng": 38.2631,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Moskovskaya Oblast’",
    "capital": "",
    "population": 22567,
    "id": 1643641489
  },
  {
    "city": "Severobaykalsk",
    "city_ascii": "Severobaykalsk",
    "lat": 55.6333,
    "lng": 109.3167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Buryatiya",
    "capital": "",
    "population": 23365,
    "id": 1643130007
  },
  {
    "city": "Zărneşti",
    "city_ascii": "Zarnesti",
    "lat": 45.5725,
    "lng": 25.3431,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Braşov",
    "capital": "",
    "population": 23476,
    "id": 1642971107
  },
  {
    "city": "Upper Providence",
    "city_ascii": "Upper Providence",
    "lat": 40.1655,
    "lng": -75.4889,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 23589,
    "id": 1840145447
  },
  {
    "city": "Gelnhausen",
    "city_ascii": "Gelnhausen",
    "lat": 50.2027,
    "lng": 9.1905,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 23073,
    "id": 1276351264
  },
  {
    "city": "Unecha",
    "city_ascii": "Unecha",
    "lat": 52.85,
    "lng": 32.6833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Bryanskaya Oblast’",
    "capital": "",
    "population": 23550,
    "id": 1643789718
  },
  {
    "city": "Madisonville",
    "city_ascii": "Madisonville",
    "lat": 37.3408,
    "lng": -87.5034,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kentucky",
    "capital": "",
    "population": 23580,
    "id": 1840015244
  },
  {
    "city": "Romulus",
    "city_ascii": "Romulus",
    "lat": 42.2237,
    "lng": -83.366,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 23573,
    "id": 1840003963
  },
  {
    "city": "San Ignacio de Velasco",
    "city_ascii": "San Ignacio de Velasco",
    "lat": -16.3667,
    "lng": -60.95,
    "country": "Bolivia",
    "iso2": "BO",
    "iso3": "BOL",
    "admin_name": "Santa Cruz",
    "capital": "",
    "population": 23569,
    "id": 1068082737
  },
  {
    "city": "Frontignan",
    "city_ascii": "Frontignan",
    "lat": 43.4483,
    "lng": 3.7561,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Occitanie",
    "capital": "",
    "population": 22762,
    "id": 1250857388
  },
  {
    "city": "Acton",
    "city_ascii": "Acton",
    "lat": 42.4843,
    "lng": -71.4378,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 23561,
    "id": 1840053471
  },
  {
    "city": "Troyan",
    "city_ascii": "Troyan",
    "lat": 42.8844,
    "lng": 24.7094,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Lovech",
    "capital": "",
    "population": 23520,
    "id": 1100528745
  },
  {
    "city": "Hisor",
    "city_ascii": "Hisor",
    "lat": 38.5264,
    "lng": 68.5381,
    "country": "Tajikistan",
    "iso2": "TJ",
    "iso3": "TJK",
    "admin_name": "Nohiyahoi Tobei Jumhurí",
    "capital": "minor",
    "population": 23200,
    "id": 1762136882
  },
  {
    "city": "Clarksburg",
    "city_ascii": "Clarksburg",
    "lat": 39.2314,
    "lng": -77.2617,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 23548,
    "id": 1840005830
  },
  {
    "city": "Leland",
    "city_ascii": "Leland",
    "lat": 34.2044,
    "lng": -78.0277,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 23544,
    "id": 1840016683
  },
  {
    "city": "Brodósqui",
    "city_ascii": "Brodosqui",
    "lat": -20.9908,
    "lng": -47.6589,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 23460,
    "id": 1076955012
  },
  {
    "city": "Elektrogorsk",
    "city_ascii": "Elektrogorsk",
    "lat": 55.8833,
    "lng": 38.7833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Moskovskaya Oblast’",
    "capital": "",
    "population": 22950,
    "id": 1643526255
  },
  {
    "city": "Cessnock",
    "city_ascii": "Cessnock",
    "lat": -32.8342,
    "lng": 151.3555,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 21725,
    "id": 1036822046
  },
  {
    "city": "Rolling Meadows",
    "city_ascii": "Rolling Meadows",
    "lat": 42.0747,
    "lng": -88.0252,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 23532,
    "id": 1840009185
  },
  {
    "city": "Mégara",
    "city_ascii": "Megara",
    "lat": 38,
    "lng": 23.3333,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Attikí",
    "capital": "minor",
    "population": 23456,
    "id": 1300371579
  },
  {
    "city": "Bondo",
    "city_ascii": "Bondo",
    "lat": 3.81,
    "lng": 23.67,
    "country": "Congo (Kinshasa)",
    "iso2": "CD",
    "iso3": "COD",
    "admin_name": "Bas-Uélé",
    "capital": "",
    "population": 23517,
    "id": 1180786691
  },
  {
    "city": "Davlekanovo",
    "city_ascii": "Davlekanovo",
    "lat": 54.2167,
    "lng": 55.0333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Bashkortostan",
    "capital": "",
    "population": 23499,
    "id": 1643452201
  },
  {
    "city": "Arnold",
    "city_ascii": "Arnold",
    "lat": 39.0437,
    "lng": -76.4974,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 23518,
    "id": 1840005907
  },
  {
    "city": "Andoany",
    "city_ascii": "Andoany",
    "lat": -13.4,
    "lng": 48.2666,
    "country": "Madagascar",
    "iso2": "MG",
    "iso3": "MDG",
    "admin_name": "Antsiranana",
    "capital": "",
    "population": 22680,
    "id": 1450689047
  },
  {
    "city": "Laguna de Duero",
    "city_ascii": "Laguna de Duero",
    "lat": 41.5831,
    "lng": -4.7167,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Castille-Leon",
    "capital": "",
    "population": 22725,
    "id": 1724571345
  },
  {
    "city": "Benbrook",
    "city_ascii": "Benbrook",
    "lat": 32.6787,
    "lng": -97.4638,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 23502,
    "id": 1840019425
  },
  {
    "city": "Tirat Karmel",
    "city_ascii": "Tirat Karmel",
    "lat": 32.7667,
    "lng": 34.9667,
    "country": "Israel",
    "iso2": "IL",
    "iso3": "ISR",
    "admin_name": "Haifa",
    "capital": "",
    "population": 22200,
    "id": 1376894717
  },
  {
    "city": "Arbroath",
    "city_ascii": "Arbroath",
    "lat": 56.561,
    "lng": -2.586,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Angus",
    "capital": "",
    "population": 23490,
    "id": 1826263581
  },
  {
    "city": "Zion",
    "city_ascii": "Zion",
    "lat": 42.4598,
    "lng": -87.8509,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 23487,
    "id": 1840010114
  },
  {
    "city": "Almeirim",
    "city_ascii": "Almeirim",
    "lat": 39.2167,
    "lng": -8.6333,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Santarém",
    "capital": "minor",
    "population": 23376,
    "id": 1620738863
  },
  {
    "city": "Sun City Center",
    "city_ascii": "Sun City Center",
    "lat": 27.7149,
    "lng": -82.3569,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 23480,
    "id": 1840001841
  },
  {
    "city": "Lensk",
    "city_ascii": "Lensk",
    "lat": 60.7167,
    "lng": 114.9,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sakha (Yakutiya)",
    "capital": "",
    "population": 23479,
    "id": 1643009226
  },
  {
    "city": "Sederot",
    "city_ascii": "Sederot",
    "lat": 31.5261,
    "lng": 34.5939,
    "country": "Israel",
    "iso2": "IL",
    "iso3": "ISR",
    "admin_name": "Southern",
    "capital": "",
    "population": 23090,
    "id": 1376365217
  },
  {
    "city": "Montrose",
    "city_ascii": "Montrose",
    "lat": 38.469,
    "lng": -107.859,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 23478,
    "id": 1840020251
  },
  {
    "city": "Ruskin",
    "city_ascii": "Ruskin",
    "lat": 27.7065,
    "lng": -82.4209,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 23477,
    "id": 1840014159
  },
  {
    "city": "Ban Mae Cho",
    "city_ascii": "Ban Mae Cho",
    "lat": 18.9032,
    "lng": 99.0041,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Chiang Mai",
    "capital": "",
    "population": 23140,
    "id": 1764558376
  },
  {
    "city": "Columbus",
    "city_ascii": "Columbus",
    "lat": 41.4368,
    "lng": -97.3563,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Nebraska",
    "capital": "",
    "population": 23463,
    "id": 1840003345
  },
  {
    "city": "Gōtsuchō",
    "city_ascii": "Gotsucho",
    "lat": 34.9809,
    "lng": 132.2949,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Shimane",
    "capital": "",
    "population": 23372,
    "id": 1392030464
  },
  {
    "city": "Brotas",
    "city_ascii": "Brotas",
    "lat": -22.2839,
    "lng": -48.1269,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 23419,
    "id": 1076744263
  },
  {
    "city": "Plainview",
    "city_ascii": "Plainview",
    "lat": 34.1911,
    "lng": -101.7234,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 23424,
    "id": 1840020538
  },
  {
    "city": "Riehen",
    "city_ascii": "Riehen",
    "lat": 47.5794,
    "lng": 7.6512,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Basel-Stadt",
    "capital": "",
    "population": 21448,
    "id": 1756991792
  },
  {
    "city": "Ruwa",
    "city_ascii": "Ruwa",
    "lat": -17.8897,
    "lng": 31.2447,
    "country": "Zimbabwe",
    "iso2": "ZW",
    "iso3": "ZWE",
    "admin_name": "Mashonaland East",
    "capital": "",
    "population": 22038,
    "id": 1716489136
  },
  {
    "city": "Veinticinco de Mayo",
    "city_ascii": "Veinticinco de Mayo",
    "lat": -35.4167,
    "lng": -60.1667,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires",
    "capital": "minor",
    "population": 23408,
    "id": 1032801782
  },
  {
    "city": "Auburn",
    "city_ascii": "Auburn",
    "lat": 44.085,
    "lng": -70.2492,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maine",
    "capital": "",
    "population": 23414,
    "id": 1840000319
  },
  {
    "city": "Cordeirópolis",
    "city_ascii": "Cordeiropolis",
    "lat": -22.4819,
    "lng": -47.4569,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 23234,
    "id": 1076452499
  },
  {
    "city": "Avon",
    "city_ascii": "Avon",
    "lat": 41.4485,
    "lng": -82.0187,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 23399,
    "id": 1840000641
  },
  {
    "city": "Horgen",
    "city_ascii": "Horgen",
    "lat": 47.2608,
    "lng": 8.5975,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Zürich",
    "capital": "",
    "population": 22662,
    "id": 1756066280
  },
  {
    "city": "Nakashunbetsu",
    "city_ascii": "Nakashunbetsu",
    "lat": 43.5553,
    "lng": 144.9714,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hokkaidō",
    "capital": "",
    "population": 23353,
    "id": 1392557467
  },
  {
    "city": "San Lorenzo de Esmeraldas",
    "city_ascii": "San Lorenzo de Esmeraldas",
    "lat": 1.2864,
    "lng": -78.8353,
    "country": "Ecuador",
    "iso2": "EC",
    "iso3": "ECU",
    "admin_name": "Esmeraldas",
    "capital": "",
    "population": 23265,
    "id": 1218156660
  },
  {
    "city": "Yarmouth",
    "city_ascii": "Yarmouth",
    "lat": 41.6767,
    "lng": -70.2249,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 23381,
    "id": 1840053508
  },
  {
    "city": "Godalming",
    "city_ascii": "Godalming",
    "lat": 51.18,
    "lng": -0.61,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Surrey",
    "capital": "",
    "population": 21804,
    "id": 1826220347
  },
  {
    "city": "Tobatí",
    "city_ascii": "Tobati",
    "lat": -25.26,
    "lng": -57.0814,
    "country": "Paraguay",
    "iso2": "PY",
    "iso3": "PRY",
    "admin_name": "Cordillera",
    "capital": "",
    "population": 23295,
    "id": 1600471923
  },
  {
    "city": "Loves Park",
    "city_ascii": "Loves Park",
    "lat": 42.3364,
    "lng": -88.9975,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 23371,
    "id": 1840009130
  },
  {
    "city": "Fishkill",
    "city_ascii": "Fishkill",
    "lat": 41.5129,
    "lng": -73.9271,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 23370,
    "id": 1840004748
  },
  {
    "city": "Dunajská Streda",
    "city_ascii": "Dunajska Streda",
    "lat": 47.9961,
    "lng": 17.6147,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Trnavský",
    "capital": "minor",
    "population": 22643,
    "id": 1703872671
  },
  {
    "city": "Caniço",
    "city_ascii": "Canico",
    "lat": 32.6412,
    "lng": -16.8504,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Madeira",
    "capital": "",
    "population": 23361,
    "id": 1620433583
  },
  {
    "city": "Rawtenstall",
    "city_ascii": "Rawtenstall",
    "lat": 53.699,
    "lng": -2.291,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Lancashire",
    "capital": "",
    "population": 22000,
    "id": 1826204769
  },
  {
    "city": "La Prairie",
    "city_ascii": "La Prairie",
    "lat": 45.42,
    "lng": -73.5,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 23357,
    "id": 1124956496
  },
  {
    "city": "Lermontov",
    "city_ascii": "Lermontov",
    "lat": 44.1053,
    "lng": 42.9717,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Stavropol’skiy Kray",
    "capital": "",
    "population": 22601,
    "id": 1643313776
  },
  {
    "city": "Wekiwa Springs",
    "city_ascii": "Wekiwa Springs",
    "lat": 28.6984,
    "lng": -81.4251,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 23329,
    "id": 1840029016
  },
  {
    "city": "Vil’nohirs’k",
    "city_ascii": "Vil'nohirs'k",
    "lat": 48.4824,
    "lng": 34.0189,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Dnipropetrovs’ka Oblast’",
    "capital": "",
    "population": 23235,
    "id": 1804164194
  },
  {
    "city": "Strakonice",
    "city_ascii": "Strakonice",
    "lat": 49.2615,
    "lng": 13.9024,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Jihočeský Kraj",
    "capital": "",
    "population": 22646,
    "id": 1203755418
  },
  {
    "city": "Netphen",
    "city_ascii": "Netphen",
    "lat": 50.9147,
    "lng": 8.1,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 23130,
    "id": 1276020652
  },
  {
    "city": "Hingham",
    "city_ascii": "Hingham",
    "lat": 42.2176,
    "lng": -70.8854,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 23298,
    "id": 1840053569
  },
  {
    "city": "Medford",
    "city_ascii": "Medford",
    "lat": 39.8639,
    "lng": -74.8231,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 23293,
    "id": 1840081614
  },
  {
    "city": "Oliveira do Bairro",
    "city_ascii": "Oliveira do Bairro",
    "lat": 40.5167,
    "lng": -8.5,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Aveiro",
    "capital": "minor",
    "population": 23028,
    "id": 1620488479
  },
  {
    "city": "Puchheim",
    "city_ascii": "Puchheim",
    "lat": 48.15,
    "lng": 11.35,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 21531,
    "id": 1276452600
  },
  {
    "city": "Calverley",
    "city_ascii": "Calverley",
    "lat": 53.8266,
    "lng": -1.6908,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Leeds",
    "capital": "",
    "population": 22594,
    "id": 1826551571
  },
  {
    "city": "Sheybān",
    "city_ascii": "Sheyban",
    "lat": 31.4086,
    "lng": 48.7942,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Khūzestān",
    "capital": "",
    "population": 23211,
    "id": 1364776996
  },
  {
    "city": "Qiryat Mal’akhi",
    "city_ascii": "Qiryat Mal'akhi",
    "lat": 31.7333,
    "lng": 34.75,
    "country": "Israel",
    "iso2": "IL",
    "iso3": "ISR",
    "admin_name": "Southern",
    "capital": "",
    "population": 23100,
    "id": 1376662881
  },
  {
    "city": "Lisle",
    "city_ascii": "Lisle",
    "lat": 41.7918,
    "lng": -88.0888,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 23270,
    "id": 1840011401
  },
  {
    "city": "Qiryat Shemona",
    "city_ascii": "Qiryat Shemona",
    "lat": 33.2075,
    "lng": 35.5697,
    "country": "Israel",
    "iso2": "IL",
    "iso3": "ISR",
    "admin_name": "Northern",
    "capital": "",
    "population": 23076,
    "id": 1376248603
  },
  {
    "city": "Eschborn",
    "city_ascii": "Eschborn",
    "lat": 50.1437,
    "lng": 8.569,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 21488,
    "id": 1276712049
  },
  {
    "city": "Tambaú",
    "city_ascii": "Tambau",
    "lat": -21.705,
    "lng": -47.2739,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 23214,
    "id": 1076693852
  },
  {
    "city": "East Retford",
    "city_ascii": "East Retford",
    "lat": 53.3228,
    "lng": -0.9431,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Nottinghamshire",
    "capital": "",
    "population": 22013,
    "id": 1826529962
  },
  {
    "city": "Vranov nad Topľou",
    "city_ascii": "Vranov nad Topl'ou",
    "lat": 48.8806,
    "lng": 21.6731,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Prešovský",
    "capital": "minor",
    "population": 22589,
    "id": 1703513512
  },
  {
    "city": "Devonport",
    "city_ascii": "Devonport",
    "lat": -41.1806,
    "lng": 146.3464,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Tasmania",
    "capital": "",
    "population": 23046,
    "id": 1036033175
  },
  {
    "city": "Bom Jesus dos Perdões",
    "city_ascii": "Bom Jesus dos Perdoes",
    "lat": -23.135,
    "lng": -46.4653,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 23025,
    "id": 1076453674
  },
  {
    "city": "Tecumseh",
    "city_ascii": "Tecumseh",
    "lat": 42.2431,
    "lng": -82.9256,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 23229,
    "id": 1124720869
  },
  {
    "city": "Greenbelt",
    "city_ascii": "Greenbelt",
    "lat": 38.9953,
    "lng": -76.8885,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 23224,
    "id": 1840005974
  },
  {
    "city": "Nevyansk",
    "city_ascii": "Nevyansk",
    "lat": 57.4833,
    "lng": 60.2,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sverdlovskaya Oblast’",
    "capital": "",
    "population": 23200,
    "id": 1643592737
  },
  {
    "city": "Senden",
    "city_ascii": "Senden",
    "lat": 48.3167,
    "lng": 10.0667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 22336,
    "id": 1276555386
  },
  {
    "city": "Bassar",
    "city_ascii": "Bassar",
    "lat": 9.25,
    "lng": 0.7833,
    "country": "Togo",
    "iso2": "TG",
    "iso3": "TGO",
    "admin_name": "Kara",
    "capital": "",
    "population": 23181,
    "id": 1768050143
  },
  {
    "city": "Warburg",
    "city_ascii": "Warburg",
    "lat": 51.4881,
    "lng": 9.14,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 23079,
    "id": 1276612246
  },
  {
    "city": "Taquarituba",
    "city_ascii": "Taquarituba",
    "lat": -23.5328,
    "lng": -49.2439,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 23163,
    "id": 1076078451
  },
  {
    "city": "Molodohvardiisk",
    "city_ascii": "Molodohvardiisk",
    "lat": 48.3444,
    "lng": 39.6583,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Luhans’ka Oblast’",
    "capital": "",
    "population": 22937,
    "id": 1804689495
  },
  {
    "city": "Kolpashevo",
    "city_ascii": "Kolpashevo",
    "lat": 58.3167,
    "lng": 82.9167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Tomskaya Oblast’",
    "capital": "",
    "population": 23209,
    "id": 1643773170
  },
  {
    "city": "Ōtsuki",
    "city_ascii": "Otsuki",
    "lat": 35.6106,
    "lng": 138.94,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Yamanashi",
    "capital": "",
    "population": 23128,
    "id": 1392398648
  },
  {
    "city": "Mandelieu-la-Napoule",
    "city_ascii": "Mandelieu-la-Napoule",
    "lat": 43.5464,
    "lng": 6.9381,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Provence-Alpes-Côte d’Azur",
    "capital": "",
    "population": 22452,
    "id": 1250852551
  },
  {
    "city": "West Springfield",
    "city_ascii": "West Springfield",
    "lat": 38.7771,
    "lng": -77.2268,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 23202,
    "id": 1840006041
  },
  {
    "city": "Linstead",
    "city_ascii": "Linstead",
    "lat": 18.15,
    "lng": -77.0167,
    "country": "Jamaica",
    "iso2": "JM",
    "iso3": "JAM",
    "admin_name": "Saint Catherine",
    "capital": "",
    "population": 22757,
    "id": 1388611853
  },
  {
    "city": "Thorigny-sur-Marne",
    "city_ascii": "Thorigny-sur-Marne",
    "lat": 48.8788,
    "lng": 2.7075,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 21356,
    "id": 1250601682
  },
  {
    "city": "Uenohara",
    "city_ascii": "Uenohara",
    "lat": 35.6303,
    "lng": 139.1086,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Yamanashi",
    "capital": "",
    "population": 23052,
    "id": 1392602835
  },
  {
    "city": "Abreus",
    "city_ascii": "Abreus",
    "lat": 22.2806,
    "lng": -80.5678,
    "country": "Cuba",
    "iso2": "CU",
    "iso3": "CUB",
    "admin_name": "Cienfuegos",
    "capital": "",
    "population": 23144,
    "id": 1192567991
  },
  {
    "city": "Fokino",
    "city_ascii": "Fokino",
    "lat": 42.9667,
    "lng": 132.4,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Primorskiy Kray",
    "capital": "",
    "population": 23077,
    "id": 1643752363
  },
  {
    "city": "Pezinok",
    "city_ascii": "Pezinok",
    "lat": 48.2667,
    "lng": 17.2667,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Bratislavský",
    "capital": "minor",
    "population": 22861,
    "id": 1703456673
  },
  {
    "city": "Walsrode",
    "city_ascii": "Walsrode",
    "lat": 52.8592,
    "lng": 9.5853,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 23068,
    "id": 1276472764
  },
  {
    "city": "Abu",
    "city_ascii": "Abu",
    "lat": 24.5925,
    "lng": 72.7083,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Rājasthān",
    "capital": "",
    "population": 22943,
    "id": 1356598126
  },
  {
    "city": "Roxbury",
    "city_ascii": "Roxbury",
    "lat": 40.8822,
    "lng": -74.6522,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 23136,
    "id": 1840081733
  },
  {
    "city": "Gersthofen",
    "city_ascii": "Gersthofen",
    "lat": 48.4167,
    "lng": 10.8667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 22473,
    "id": 1276965949
  },
  {
    "city": "Kilkís",
    "city_ascii": "Kilkis",
    "lat": 40.9954,
    "lng": 22.8765,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Kentrikí Makedonía",
    "capital": "minor",
    "population": 22914,
    "id": 1300726797
  },
  {
    "city": "Canton",
    "city_ascii": "Canton",
    "lat": 42.175,
    "lng": -71.1264,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 23134,
    "id": 1840053673
  },
  {
    "city": "Komló",
    "city_ascii": "Komlo",
    "lat": 46.1911,
    "lng": 18.2611,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Baranya",
    "capital": "minor",
    "population": 22635,
    "id": 1348836400
  },
  {
    "city": "Plainsboro",
    "city_ascii": "Plainsboro",
    "lat": 40.3377,
    "lng": -74.5878,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 23120,
    "id": 1840081691
  },
  {
    "city": "Oxford",
    "city_ascii": "Oxford",
    "lat": 39.5061,
    "lng": -84.7434,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 23113,
    "id": 1840009572
  },
  {
    "city": "Tashtagol",
    "city_ascii": "Tashtagol",
    "lat": 52.7667,
    "lng": 87.8667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kemerovskaya Oblast’",
    "capital": "",
    "population": 23107,
    "id": 1643787144
  },
  {
    "city": "Dammarie-lè-Lys",
    "city_ascii": "Dammarie-le-Lys",
    "lat": 48.5177,
    "lng": 2.6402,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 21835,
    "id": 1250036813
  },
  {
    "city": "Tupiza",
    "city_ascii": "Tupiza",
    "lat": -21.4423,
    "lng": -65.719,
    "country": "Bolivia",
    "iso2": "BO",
    "iso3": "BOL",
    "admin_name": "Potosí",
    "capital": "",
    "population": 23100,
    "id": 1068328989
  },
  {
    "city": "Lengir",
    "city_ascii": "Lengir",
    "lat": 42.1899,
    "lng": 69.8799,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Ongtüstik Qazaqstan",
    "capital": "",
    "population": 23058,
    "id": 1398031838
  },
  {
    "city": "Kenmore",
    "city_ascii": "Kenmore",
    "lat": 47.7516,
    "lng": -122.2489,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 23097,
    "id": 1840018423
  },
  {
    "city": "Sīwah",
    "city_ascii": "Siwah",
    "lat": 29.2,
    "lng": 25.5167,
    "country": "Egypt",
    "iso2": "EG",
    "iso3": "EGY",
    "admin_name": "Maţrūḩ",
    "capital": "",
    "population": 23080,
    "id": 1818755237
  },
  {
    "city": "Farmington",
    "city_ascii": "Farmington",
    "lat": 44.6573,
    "lng": -93.1688,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 23091,
    "id": 1840007853
  },
  {
    "city": "Kilwinning",
    "city_ascii": "Kilwinning",
    "lat": 55.655,
    "lng": -4.703,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "North Ayrshire",
    "capital": "",
    "population": 21693,
    "id": 1826458707
  },
  {
    "city": "Gusinoozërsk",
    "city_ascii": "Gusinoozersk",
    "lat": 51.2833,
    "lng": 106.5167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Buryatiya",
    "capital": "",
    "population": 23082,
    "id": 1643386183
  },
  {
    "city": "Kapfenberg",
    "city_ascii": "Kapfenberg",
    "lat": 47.4394,
    "lng": 15.2894,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Steiermark",
    "capital": "",
    "population": 22798,
    "id": 1040864298
  },
  {
    "city": "Gainsborough",
    "city_ascii": "Gainsborough",
    "lat": 53.4016,
    "lng": -0.7732,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Lincolnshire",
    "capital": "",
    "population": 22841,
    "id": 1826888621
  },
  {
    "city": "Wichian Buri",
    "city_ascii": "Wichian Buri",
    "lat": 15.6565,
    "lng": 101.1072,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Phetchabun",
    "capital": "minor",
    "population": 22995,
    "id": 1764906431
  },
  {
    "city": "Whyalla",
    "city_ascii": "Whyalla",
    "lat": -33.0333,
    "lng": 137.5667,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "South Australia",
    "capital": "",
    "population": 21742,
    "id": 1036961366
  },
  {
    "city": "Furukawa",
    "city_ascii": "Furukawa",
    "lat": 36.2383,
    "lng": 137.1861,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Gifu",
    "capital": "",
    "population": 23030,
    "id": 1392467496
  },
  {
    "city": "Gyêgu",
    "city_ascii": "Gyegu",
    "lat": 33.0166,
    "lng": 96.7333,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Qinghai",
    "capital": "minor",
    "population": 23000,
    "id": 1156604060
  },
  {
    "city": "Hastings",
    "city_ascii": "Hastings",
    "lat": 44.7318,
    "lng": -92.8538,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 23047,
    "id": 1840006770
  },
  {
    "city": "Oyonnax",
    "city_ascii": "Oyonnax",
    "lat": 46.2561,
    "lng": 5.6556,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Auvergne-Rhône-Alpes",
    "capital": "",
    "population": 22427,
    "id": 1250935509
  },
  {
    "city": "Guanajay",
    "city_ascii": "Guanajay",
    "lat": 22.9306,
    "lng": -82.6881,
    "country": "Cuba",
    "iso2": "CU",
    "iso3": "CUB",
    "admin_name": "Artemisa",
    "capital": "minor",
    "population": 22839,
    "id": 1192568960
  },
  {
    "city": "Santa Ana Nextlalpan",
    "city_ascii": "Santa Ana Nextlalpan",
    "lat": 19.7167,
    "lng": -99.0667,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "México",
    "capital": "minor",
    "population": 22507,
    "id": 1484696225
  },
  {
    "city": "Makīnsk",
    "city_ascii": "Makinsk",
    "lat": 52.6404,
    "lng": 70.41,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Aqmola",
    "capital": "",
    "population": 23020,
    "id": 1398733675
  },
  {
    "city": "Quaraí",
    "city_ascii": "Quarai",
    "lat": -30.3878,
    "lng": -56.4508,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Rio Grande do Sul",
    "capital": "",
    "population": 23021,
    "id": 1076203994
  },
  {
    "city": "Yeadon",
    "city_ascii": "Yeadon",
    "lat": 53.869,
    "lng": -1.688,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Leeds",
    "capital": "",
    "population": 22233,
    "id": 1826214875
  },
  {
    "city": "Murayama",
    "city_ascii": "Murayama",
    "lat": 38.4833,
    "lng": 140.3833,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Yamagata",
    "capital": "",
    "population": 22905,
    "id": 1392537300
  },
  {
    "city": "Yako",
    "city_ascii": "Yako",
    "lat": 12.954,
    "lng": -2.263,
    "country": "Burkina Faso",
    "iso2": "BF",
    "iso3": "BFA",
    "admin_name": "Nord",
    "capital": "minor",
    "population": 22904,
    "id": 1854815200
  },
  {
    "city": "West Goshen",
    "city_ascii": "West Goshen",
    "lat": 39.9756,
    "lng": -75.5927,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 23021,
    "id": 1840133853
  },
  {
    "city": "Orchards",
    "city_ascii": "Orchards",
    "lat": 45.689,
    "lng": -122.5306,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 23020,
    "id": 1840018519
  },
  {
    "city": "Vale de Cambra",
    "city_ascii": "Vale de Cambra",
    "lat": 40.85,
    "lng": -8.4,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Aveiro",
    "capital": "minor",
    "population": 22864,
    "id": 1620033828
  },
  {
    "city": "Rockingham",
    "city_ascii": "Rockingham",
    "lat": 34.9386,
    "lng": -79.7608,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 23016,
    "id": 1840014617
  },
  {
    "city": "Krasnouralsk",
    "city_ascii": "Krasnouralsk",
    "lat": 58.35,
    "lng": 60.05,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sverdlovskaya Oblast’",
    "capital": "",
    "population": 22996,
    "id": 1643663207
  },
  {
    "city": "Krasnoarmeysk",
    "city_ascii": "Krasnoarmeysk",
    "lat": 51.0167,
    "lng": 45.7,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Saratovskaya Oblast’",
    "capital": "",
    "population": 22994,
    "id": 1643504367
  },
  {
    "city": "Greenfield",
    "city_ascii": "Greenfield",
    "lat": 39.7936,
    "lng": -85.7737,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 23006,
    "id": 1840014673
  },
  {
    "city": "Auburn",
    "city_ascii": "Auburn",
    "lat": 41.3666,
    "lng": -85.0559,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 22994,
    "id": 1840007100
  },
  {
    "city": "Zvenigorod",
    "city_ascii": "Zvenigorod",
    "lat": 55.7333,
    "lng": 36.85,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Moskovskaya Oblast’",
    "capital": "",
    "population": 22513,
    "id": 1643823915
  },
  {
    "city": "Wright",
    "city_ascii": "Wright",
    "lat": 30.4445,
    "lng": -86.6419,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 22991,
    "id": 1840013921
  },
  {
    "city": "Willoughby",
    "city_ascii": "Willoughby",
    "lat": 41.646,
    "lng": -81.4084,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 22977,
    "id": 1840000547
  },
  {
    "city": "Watertown",
    "city_ascii": "Watertown",
    "lat": 43.1893,
    "lng": -88.7285,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 22973,
    "id": 1840003000
  },
  {
    "city": "Wädenswil",
    "city_ascii": "Wadenswil",
    "lat": 47.2303,
    "lng": 8.6722,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Zürich",
    "capital": "",
    "population": 21716,
    "id": 1756533832
  },
  {
    "city": "Fāyīd",
    "city_ascii": "Fayid",
    "lat": 30.3261,
    "lng": 32.2986,
    "country": "Egypt",
    "iso2": "EG",
    "iso3": "EGY",
    "admin_name": "Al Ismā‘īlīyah",
    "capital": "",
    "population": 21808,
    "id": 1818188945
  },
  {
    "city": "Sidi Ifni",
    "city_ascii": "Sidi Ifni",
    "lat": 29.3833,
    "lng": -10.1667,
    "country": "Morocco",
    "iso2": "MA",
    "iso3": "MAR",
    "admin_name": "Guelmim-Oued Noun",
    "capital": "",
    "population": 21618,
    "id": 1504270532
  },
  {
    "city": "Manghit",
    "city_ascii": "Manghit",
    "lat": 42.1236,
    "lng": 60.0583,
    "country": "Uzbekistan",
    "iso2": "UZ",
    "iso3": "UZB",
    "admin_name": "Qoraqalpog‘iston",
    "capital": "",
    "population": 22949,
    "id": 1860400027
  },
  {
    "city": "Mustang",
    "city_ascii": "Mustang",
    "lat": 35.3917,
    "lng": -97.7246,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oklahoma",
    "capital": "",
    "population": 22959,
    "id": 1840020421
  },
  {
    "city": "Mount Pearl Park",
    "city_ascii": "Mount Pearl Park",
    "lat": 47.5189,
    "lng": -52.8058,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Newfoundland and Labrador",
    "capital": "",
    "population": 22957,
    "id": 1124869949
  },
  {
    "city": "Montgomery",
    "city_ascii": "Montgomery",
    "lat": 40.426,
    "lng": -74.6791,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 22955,
    "id": 1840081698
  },
  {
    "city": "Znam”yanka",
    "city_ascii": "Znam'yanka",
    "lat": 48.7136,
    "lng": 32.6733,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Kirovohrads’ka Oblast’",
    "capital": "minor",
    "population": 22936,
    "id": 1804158323
  },
  {
    "city": "Haywards Heath",
    "city_ascii": "Haywards Heath",
    "lat": 51.0048,
    "lng": -0.0979,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "West Sussex",
    "capital": "",
    "population": 22800,
    "id": 1826565038
  },
  {
    "city": "Wertheim",
    "city_ascii": "Wertheim",
    "lat": 49.75,
    "lng": 9.5167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 22780,
    "id": 1276150823
  },
  {
    "city": "Überlingen",
    "city_ascii": "Uberlingen",
    "lat": 47.7667,
    "lng": 9.1583,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 22554,
    "id": 1276778071
  },
  {
    "city": "Valašské Meziříčí",
    "city_ascii": "Valasske Mezirici",
    "lat": 49.4718,
    "lng": 17.9712,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Zlínský Kraj",
    "capital": "",
    "population": 22306,
    "id": 1203786401
  },
  {
    "city": "Neusäß",
    "city_ascii": "Neusass",
    "lat": 48.4,
    "lng": 10.8333,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 22058,
    "id": 1276057547
  },
  {
    "city": "Frederickson",
    "city_ascii": "Frederickson",
    "lat": 47.0914,
    "lng": -122.3594,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 22935,
    "id": 1840037551
  },
  {
    "city": "Leutkirch im Allgäu",
    "city_ascii": "Leutkirch im Allgau",
    "lat": 47.8256,
    "lng": 10.0222,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 22803,
    "id": 1276589635
  },
  {
    "city": "Patterson",
    "city_ascii": "Patterson",
    "lat": 37.4758,
    "lng": -121.1535,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 22930,
    "id": 1840020290
  },
  {
    "city": "Eckernförde",
    "city_ascii": "Eckernforde",
    "lat": 54.4742,
    "lng": 9.8378,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Schleswig-Holstein",
    "capital": "",
    "population": 21902,
    "id": 1276177935
  },
  {
    "city": "Espera Feliz",
    "city_ascii": "Espera Feliz",
    "lat": -20.65,
    "lng": -41.9069,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Minas Gerais",
    "capital": "",
    "population": 22856,
    "id": 1076630023
  },
  {
    "city": "Yokoshiba",
    "city_ascii": "Yokoshiba",
    "lat": 35.6656,
    "lng": 140.5042,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Chiba",
    "capital": "",
    "population": 22585,
    "id": 1392617948
  },
  {
    "city": "Isilkul",
    "city_ascii": "Isilkul",
    "lat": 54.9089,
    "lng": 71.2606,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Omskaya Oblast’",
    "capital": "",
    "population": 22903,
    "id": 1643502986
  },
  {
    "city": "Gallup",
    "city_ascii": "Gallup",
    "lat": 35.5183,
    "lng": -108.7423,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Mexico",
    "capital": "",
    "population": 22917,
    "id": 1840020395
  },
  {
    "city": "Radcliff",
    "city_ascii": "Radcliff",
    "lat": 37.8206,
    "lng": -85.9366,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kentucky",
    "capital": "",
    "population": 22914,
    "id": 1840014329
  },
  {
    "city": "Westerstede",
    "city_ascii": "Westerstede",
    "lat": 53.25,
    "lng": 7.9167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "minor",
    "population": 22778,
    "id": 1276738053
  },
  {
    "city": "Patacamaya",
    "city_ascii": "Patacamaya",
    "lat": -17.2333,
    "lng": -67.9167,
    "country": "Bolivia",
    "iso2": "BO",
    "iso3": "BOL",
    "admin_name": "La Paz",
    "capital": "",
    "population": 22858,
    "id": 1068827645
  },
  {
    "city": "Crystal",
    "city_ascii": "Crystal",
    "lat": 45.0377,
    "lng": -93.3599,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 22899,
    "id": 1840006745
  },
  {
    "city": "Guryevsk",
    "city_ascii": "Guryevsk",
    "lat": 54.2833,
    "lng": 85.9333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kemerovskaya Oblast’",
    "capital": "",
    "population": 22872,
    "id": 1643007187
  },
  {
    "city": "Lengerich",
    "city_ascii": "Lengerich",
    "lat": 52.175,
    "lng": 7.8667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 22641,
    "id": 1276617854
  },
  {
    "city": "Belton",
    "city_ascii": "Belton",
    "lat": 31.0525,
    "lng": -97.479,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 22885,
    "id": 1840019556
  },
  {
    "city": "Nazca",
    "city_ascii": "Nazca",
    "lat": -14.8289,
    "lng": -74.9436,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "Ica",
    "capital": "",
    "population": 22859,
    "id": 1604291738
  },
  {
    "city": "Allen",
    "city_ascii": "Allen",
    "lat": -38.9772,
    "lng": -67.8272,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Río Negro",
    "capital": "",
    "population": 22859,
    "id": 1032144191
  },
  {
    "city": "Weilheim",
    "city_ascii": "Weilheim",
    "lat": 47.8333,
    "lng": 11.1333,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "minor",
    "population": 22477,
    "id": 1276001381
  },
  {
    "city": "Glauchau",
    "city_ascii": "Glauchau",
    "lat": 50.8233,
    "lng": 12.5444,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 22440,
    "id": 1276610130
  },
  {
    "city": "Dickinson",
    "city_ascii": "Dickinson",
    "lat": 46.8917,
    "lng": -102.7924,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Dakota",
    "capital": "",
    "population": 22872,
    "id": 1840000192
  },
  {
    "city": "Catamayo",
    "city_ascii": "Catamayo",
    "lat": -3.9833,
    "lng": -79.35,
    "country": "Ecuador",
    "iso2": "EC",
    "iso3": "ECU",
    "admin_name": "Loja",
    "capital": "",
    "population": 22697,
    "id": 1218400594
  },
  {
    "city": "Naqādah",
    "city_ascii": "Naqadah",
    "lat": 25.9017,
    "lng": 32.7242,
    "country": "Egypt",
    "iso2": "EG",
    "iso3": "EGY",
    "admin_name": "Qinā",
    "capital": "",
    "population": 21530,
    "id": 1818662110
  },
  {
    "city": "Allison Park",
    "city_ascii": "Allison Park",
    "lat": 40.573,
    "lng": -79.9603,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 22855,
    "id": 1840026468
  },
  {
    "city": "Tunuyán",
    "city_ascii": "Tunuyan",
    "lat": -33.5667,
    "lng": -69.0167,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Mendoza",
    "capital": "minor",
    "population": 22834,
    "id": 1032526901
  },
  {
    "city": "Karachayevsk",
    "city_ascii": "Karachayevsk",
    "lat": 43.7667,
    "lng": 41.9,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Karachayevo-Cherkesiya",
    "capital": "",
    "population": 21067,
    "id": 1643606721
  },
  {
    "city": "Ensley",
    "city_ascii": "Ensley",
    "lat": 30.5261,
    "lng": -87.2735,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 22844,
    "id": 1840013104
  },
  {
    "city": "Takahata",
    "city_ascii": "Takahata",
    "lat": 38.0025,
    "lng": 140.1911,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Yamagata",
    "capital": "",
    "population": 22709,
    "id": 1392054677
  },
  {
    "city": "Olivet",
    "city_ascii": "Olivet",
    "lat": 47.8639,
    "lng": 1.9006,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Centre-Val de Loire",
    "capital": "",
    "population": 21951,
    "id": 1250759195
  },
  {
    "city": "Selouane",
    "city_ascii": "Selouane",
    "lat": 35.0708,
    "lng": -2.9392,
    "country": "Morocco",
    "iso2": "MA",
    "iso3": "MAR",
    "admin_name": "Oriental",
    "capital": "",
    "population": 21570,
    "id": 1504039594
  },
  {
    "city": "Le Creusot",
    "city_ascii": "Le Creusot",
    "lat": 46.8014,
    "lng": 4.4411,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Bourgogne-Franche-Comté",
    "capital": "",
    "population": 21630,
    "id": 1250722828
  },
  {
    "city": "Laguna Beach",
    "city_ascii": "Laguna Beach",
    "lat": 33.5448,
    "lng": -117.7612,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 22827,
    "id": 1840020587
  },
  {
    "city": "Goldasht",
    "city_ascii": "Goldasht",
    "lat": 32.6267,
    "lng": 51.4392,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Eşfahān",
    "capital": "",
    "population": 22693,
    "id": 1364824865
  },
  {
    "city": "Webster Groves",
    "city_ascii": "Webster Groves",
    "lat": 38.5866,
    "lng": -90.3544,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 22819,
    "id": 1840010744
  },
  {
    "city": "Acworth",
    "city_ascii": "Acworth",
    "lat": 34.0566,
    "lng": -84.6715,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 22818,
    "id": 1840013688
  },
  {
    "city": "Vega Baja",
    "city_ascii": "Vega Baja",
    "lat": 18.441,
    "lng": -66.3993,
    "country": "Puerto Rico",
    "iso2": "PR",
    "iso3": "PRI",
    "admin_name": "Puerto Rico",
    "capital": "",
    "population": 22815,
    "id": 1630023572
  },
  {
    "city": "Cinco Saltos",
    "city_ascii": "Cinco Saltos",
    "lat": -38.8167,
    "lng": -68.0667,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Río Negro",
    "capital": "",
    "population": 22790,
    "id": 1032470108
  },
  {
    "city": "Griffin",
    "city_ascii": "Griffin",
    "lat": 33.2418,
    "lng": -84.2747,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 22813,
    "id": 1840013776
  },
  {
    "city": "Curchorem",
    "city_ascii": "Curchorem",
    "lat": 15.25,
    "lng": 74.1,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Goa",
    "capital": "",
    "population": 22730,
    "id": 1356224201
  },
  {
    "city": "Vreden",
    "city_ascii": "Vreden",
    "lat": 52.0331,
    "lng": 6.8331,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 22641,
    "id": 1276296218
  },
  {
    "city": "Botevgrad",
    "city_ascii": "Botevgrad",
    "lat": 42.9083,
    "lng": 23.7919,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Sofia",
    "capital": "",
    "population": 22742,
    "id": 1100788799
  },
  {
    "city": "Saint-Genis-Laval",
    "city_ascii": "Saint-Genis-Laval",
    "lat": 45.696,
    "lng": 4.793,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Auvergne-Rhône-Alpes",
    "capital": "",
    "population": 21217,
    "id": 1250513560
  },
  {
    "city": "Onondaga",
    "city_ascii": "Onondaga",
    "lat": 42.9687,
    "lng": -76.2168,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 22797,
    "id": 1840087751
  },
  {
    "city": "Morton Grove",
    "city_ascii": "Morton Grove",
    "lat": 42.0423,
    "lng": -87.789,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 22796,
    "id": 1840011299
  },
  {
    "city": "Velsk",
    "city_ascii": "Velsk",
    "lat": 61.0667,
    "lng": 42.1,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Arkhangel’skaya Oblast’",
    "capital": "",
    "population": 22776,
    "id": 1643373817
  },
  {
    "city": "Marina",
    "city_ascii": "Marina",
    "lat": 36.6812,
    "lng": -121.7895,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 22781,
    "id": 1840020357
  },
  {
    "city": "Solon",
    "city_ascii": "Solon",
    "lat": 41.3864,
    "lng": -81.4401,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 22779,
    "id": 1840003413
  },
  {
    "city": "Forbach",
    "city_ascii": "Forbach",
    "lat": 49.19,
    "lng": 6.9,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Grand Est",
    "capital": "minor",
    "population": 21552,
    "id": 1250265526
  },
  {
    "city": "Rixensart",
    "city_ascii": "Rixensart",
    "lat": 50.7167,
    "lng": 4.5333,
    "country": "Belgium",
    "iso2": "BE",
    "iso3": "BEL",
    "admin_name": "Wallonia",
    "capital": "",
    "population": 22401,
    "id": 1056643005
  },
  {
    "city": "Svetlyy",
    "city_ascii": "Svetlyy",
    "lat": 54.6833,
    "lng": 20.1333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kaliningradskaya Oblast’",
    "capital": "minor",
    "population": 21928,
    "id": 1643742025
  },
  {
    "city": "Hunters Creek",
    "city_ascii": "Hunters Creek",
    "lat": 28.3611,
    "lng": -81.4357,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 22770,
    "id": 1840028986
  },
  {
    "city": "Petrila",
    "city_ascii": "Petrila",
    "lat": 45.4511,
    "lng": 23.3981,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Hunedoara",
    "capital": "",
    "population": 22692,
    "id": 1642937566
  },
  {
    "city": "Bad Kissingen",
    "city_ascii": "Bad Kissingen",
    "lat": 50.2,
    "lng": 10.0667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "minor",
    "population": 22444,
    "id": 1276216676
  },
  {
    "city": "Gjøvik",
    "city_ascii": "Gjovik",
    "lat": 60.8,
    "lng": 10.7,
    "country": "Norway",
    "iso2": "NO",
    "iso3": "NOR",
    "admin_name": "Oppland",
    "capital": "minor",
    "population": 22719,
    "id": 1578892271
  },
  {
    "city": "Manosque",
    "city_ascii": "Manosque",
    "lat": 43.8342,
    "lng": 5.7839,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Provence-Alpes-Côte d’Azur",
    "capital": "",
    "population": 22333,
    "id": 1250372631
  },
  {
    "city": "Kaltenkirchen",
    "city_ascii": "Kaltenkirchen",
    "lat": 53.8397,
    "lng": 9.9603,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Schleswig-Holstein",
    "capital": "",
    "population": 21813,
    "id": 1276286164
  },
  {
    "city": "San Miguel de Papasquiaro",
    "city_ascii": "San Miguel de Papasquiaro",
    "lat": 24.8304,
    "lng": -105.34,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Durango",
    "capital": "",
    "population": 22750,
    "id": 1484521716
  },
  {
    "city": "New Brighton",
    "city_ascii": "New Brighton",
    "lat": 45.0658,
    "lng": -93.206,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 22753,
    "id": 1840007841
  },
  {
    "city": "Iracemápolis",
    "city_ascii": "Iracemapolis",
    "lat": -22.5808,
    "lng": -47.5189,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 22557,
    "id": 1076095020
  },
  {
    "city": "Mandan",
    "city_ascii": "Mandan",
    "lat": 46.829,
    "lng": -100.887,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Dakota",
    "capital": "",
    "population": 22752,
    "id": 1840000199
  },
  {
    "city": "Kalachinsk",
    "city_ascii": "Kalachinsk",
    "lat": 55.05,
    "lng": 74.5833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Omskaya Oblast’",
    "capital": "",
    "population": 22737,
    "id": 1643894191
  },
  {
    "city": "Buxton",
    "city_ascii": "Buxton",
    "lat": 53.259,
    "lng": -1.911,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Derbyshire",
    "capital": "",
    "population": 22215,
    "id": 1826191966
  },
  {
    "city": "Ladyzhyn",
    "city_ascii": "Ladyzhyn",
    "lat": 48.6667,
    "lng": 29.2167,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Vinnyts’ka Oblast’",
    "capital": "",
    "population": 22682,
    "id": 1804829324
  },
  {
    "city": "Donaueschingen",
    "city_ascii": "Donaueschingen",
    "lat": 47.9531,
    "lng": 8.5033,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 22526,
    "id": 1276107502
  },
  {
    "city": "Corsicana",
    "city_ascii": "Corsicana",
    "lat": 32.0824,
    "lng": -96.4665,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 22728,
    "id": 1840019505
  },
  {
    "city": "Staraya Kupavna",
    "city_ascii": "Staraya Kupavna",
    "lat": 55.8,
    "lng": 38.1833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Moskovskaya Oblast’",
    "capital": "",
    "population": 22476,
    "id": 1643193878
  },
  {
    "city": "Šaľa",
    "city_ascii": "Sal'a",
    "lat": 48.15,
    "lng": 17.8833,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Nitriansky",
    "capital": "minor",
    "population": 22219,
    "id": 1703397102
  },
  {
    "city": "Elizabeth City",
    "city_ascii": "Elizabeth City",
    "lat": 36.2943,
    "lng": -76.2361,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 22711,
    "id": 1840013315
  },
  {
    "city": "Pittsburg",
    "city_ascii": "Pittsburg",
    "lat": 37.4129,
    "lng": -94.6984,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kansas",
    "capital": "",
    "population": 22711,
    "id": 1840001697
  },
  {
    "city": "Harriman",
    "city_ascii": "Harriman",
    "lat": 35.9307,
    "lng": -84.5602,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 22708,
    "id": 1840013385
  },
  {
    "city": "Greenfield",
    "city_ascii": "Greenfield",
    "lat": 42.614,
    "lng": -72.5985,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 22703,
    "id": 1840132426
  },
  {
    "city": "Torre del Mar",
    "city_ascii": "Torre del Mar",
    "lat": 36.75,
    "lng": -4.1,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Andalusia",
    "capital": "",
    "population": 22168,
    "id": 1724657999
  },
  {
    "city": "Oytal",
    "city_ascii": "Oytal",
    "lat": 42.9153,
    "lng": 73.2549,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Zhambyl",
    "capital": "",
    "population": 22673,
    "id": 1398816918
  },
  {
    "city": "Metzingen",
    "city_ascii": "Metzingen",
    "lat": 48.5367,
    "lng": 9.2858,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 22046,
    "id": 1276043043
  },
  {
    "city": "Fairhope",
    "city_ascii": "Fairhope",
    "lat": 30.5217,
    "lng": -87.8815,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 22677,
    "id": 1840002039
  },
  {
    "city": "Machesney Park",
    "city_ascii": "Machesney Park",
    "lat": 42.3666,
    "lng": -89.0266,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 22677,
    "id": 1840011123
  },
  {
    "city": "Winchester",
    "city_ascii": "Winchester",
    "lat": 42.4518,
    "lng": -71.1463,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 22677,
    "id": 1840053496
  },
  {
    "city": "Wettingen",
    "city_ascii": "Wettingen",
    "lat": 47.4659,
    "lng": 8.3267,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Aargau",
    "capital": "",
    "population": 20721,
    "id": 1756544966
  },
  {
    "city": "Braidwood",
    "city_ascii": "Braidwood",
    "lat": 41.2696,
    "lng": -88.2235,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 22670,
    "id": 1840007090
  },
  {
    "city": "Hurricane",
    "city_ascii": "Hurricane",
    "lat": 37.1487,
    "lng": -113.3517,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Utah",
    "capital": "",
    "population": 22666,
    "id": 1840018936
  },
  {
    "city": "Lysander",
    "city_ascii": "Lysander",
    "lat": 43.18,
    "lng": -76.3745,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 22661,
    "id": 1840058273
  },
  {
    "city": "Laupheim",
    "city_ascii": "Laupheim",
    "lat": 48.2289,
    "lng": 9.8797,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 22298,
    "id": 1276003982
  },
  {
    "city": "Pogradec",
    "city_ascii": "Pogradec",
    "lat": 40.9,
    "lng": 20.65,
    "country": "Albania",
    "iso2": "AL",
    "iso3": "ALB",
    "admin_name": "Korçë",
    "capital": "",
    "population": 20848,
    "id": 1008366566
  },
  {
    "city": "Omagh",
    "city_ascii": "Omagh",
    "lat": 54.59,
    "lng": -7.29,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Fermanagh and Omagh",
    "capital": "",
    "population": 21056,
    "id": 1826686995
  },
  {
    "city": "Lajas",
    "city_ascii": "Lajas",
    "lat": 22.4164,
    "lng": -80.2906,
    "country": "Cuba",
    "iso2": "CU",
    "iso3": "CUB",
    "admin_name": "Cienfuegos",
    "capital": "minor",
    "population": 22602,
    "id": 1192894974
  },
  {
    "city": "Campoalegre",
    "city_ascii": "Campoalegre",
    "lat": 2.69,
    "lng": -75.33,
    "country": "Colombia",
    "iso2": "CO",
    "iso3": "COL",
    "admin_name": "Huila",
    "capital": "minor",
    "population": 22568,
    "id": 1170655224
  },
  {
    "city": "Dunaharaszti",
    "city_ascii": "Dunaharaszti",
    "lat": 47.3553,
    "lng": 19.0844,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Pest",
    "capital": "",
    "population": 21901,
    "id": 1348702931
  },
  {
    "city": "Honmachi",
    "city_ascii": "Honmachi",
    "lat": 36.0608,
    "lng": 136.5006,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Fukui",
    "capital": "",
    "population": 22561,
    "id": 1392794270
  },
  {
    "city": "Nagold",
    "city_ascii": "Nagold",
    "lat": 48.5519,
    "lng": 8.7256,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 22294,
    "id": 1276622142
  },
  {
    "city": "Kopřivnice",
    "city_ascii": "Koprivnice",
    "lat": 49.5995,
    "lng": 18.1448,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Moravskoslezský Kraj",
    "capital": "",
    "population": 21851,
    "id": 1203847096
  },
  {
    "city": "Pudsey",
    "city_ascii": "Pudsey",
    "lat": 53.797,
    "lng": -1.663,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Leeds",
    "capital": "",
    "population": 22408,
    "id": 1826326804
  },
  {
    "city": "East Patchogue",
    "city_ascii": "East Patchogue",
    "lat": 40.7704,
    "lng": -72.9817,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 22637,
    "id": 1840005040
  },
  {
    "city": "Barakī Barak",
    "city_ascii": "Baraki Barak",
    "lat": 33.9667,
    "lng": 68.9667,
    "country": "Afghanistan",
    "iso2": "AF",
    "iso3": "AFG",
    "admin_name": "Lōgar",
    "capital": "minor",
    "population": 22305,
    "id": 1004707647
  },
  {
    "city": "Trussville",
    "city_ascii": "Trussville",
    "lat": 33.6404,
    "lng": -86.5819,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 22635,
    "id": 1840015672
  },
  {
    "city": "Karben",
    "city_ascii": "Karben",
    "lat": 50.2322,
    "lng": 8.7681,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 22127,
    "id": 1276723734
  },
  {
    "city": "Waynesboro",
    "city_ascii": "Waynesboro",
    "lat": 38.0674,
    "lng": -78.9014,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 22630,
    "id": 1840003848
  },
  {
    "city": "Manicoré",
    "city_ascii": "Manicore",
    "lat": -5.8122,
    "lng": -61.2975,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Amazonas",
    "capital": "",
    "population": 21549,
    "id": 1076657983
  },
  {
    "city": "Westerly",
    "city_ascii": "Westerly",
    "lat": 41.3635,
    "lng": -71.7899,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Rhode Island",
    "capital": "",
    "population": 22624,
    "id": 1840026106
  },
  {
    "city": "Stadthagen",
    "city_ascii": "Stadthagen",
    "lat": 52.3247,
    "lng": 9.2069,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "minor",
    "population": 22247,
    "id": 1276512771
  },
  {
    "city": "Darton",
    "city_ascii": "Darton",
    "lat": 53.585,
    "lng": -1.5325,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Barnsley",
    "capital": "",
    "population": 21345,
    "id": 1826106593
  },
  {
    "city": "Burg",
    "city_ascii": "Burg",
    "lat": 52.2725,
    "lng": 11.855,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony-Anhalt",
    "capital": "minor",
    "population": 22478,
    "id": 1276295040
  },
  {
    "city": "Makó",
    "city_ascii": "Mako",
    "lat": 46.22,
    "lng": 20.4789,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Csongrád",
    "capital": "minor",
    "population": 22514,
    "id": 1348716786
  },
  {
    "city": "Pushkar",
    "city_ascii": "Pushkar",
    "lat": 26.5,
    "lng": 74.55,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Rājasthān",
    "capital": "",
    "population": 21626,
    "id": 1356618005
  },
  {
    "city": "Huautla",
    "city_ascii": "Huautla",
    "lat": 21.0308,
    "lng": -98.285,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Hidalgo",
    "capital": "",
    "population": 22521,
    "id": 1484208664
  },
  {
    "city": "Puente Nacional",
    "city_ascii": "Puente Nacional",
    "lat": 19.3333,
    "lng": -96.4833,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Veracruz",
    "capital": "minor",
    "population": 22454,
    "id": 1484974086
  },
  {
    "city": "Dawmat al Jandal",
    "city_ascii": "Dawmat al Jandal",
    "lat": 29.8153,
    "lng": 39.8664,
    "country": "Saudi Arabia",
    "iso2": "SA",
    "iso3": "SAU",
    "admin_name": "Al Jawf",
    "capital": "",
    "population": 22583,
    "id": 1682116359
  },
  {
    "city": "Burlington",
    "city_ascii": "Burlington",
    "lat": 40.0641,
    "lng": -74.8393,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 22587,
    "id": 1840001477
  },
  {
    "city": "Buftea",
    "city_ascii": "Buftea",
    "lat": 44.57,
    "lng": 25.95,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Ilfov",
    "capital": "",
    "population": 22178,
    "id": 1642134624
  },
  {
    "city": "Johnston",
    "city_ascii": "Johnston",
    "lat": 41.6857,
    "lng": -93.7173,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Iowa",
    "capital": "",
    "population": 22582,
    "id": 1840008170
  },
  {
    "city": "Corcoran",
    "city_ascii": "Corcoran",
    "lat": 36.0839,
    "lng": -119.561,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 22581,
    "id": 1840019056
  },
  {
    "city": "Meadville",
    "city_ascii": "Meadville",
    "lat": 41.6476,
    "lng": -80.1468,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 22580,
    "id": 1840000558
  },
  {
    "city": "Wareham",
    "city_ascii": "Wareham",
    "lat": 41.7662,
    "lng": -70.6999,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 22574,
    "id": 1840053578
  },
  {
    "city": "Anthem",
    "city_ascii": "Anthem",
    "lat": 33.856,
    "lng": -112.1168,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 22570,
    "id": 1840022919
  },
  {
    "city": "Bélabo",
    "city_ascii": "Belabo",
    "lat": 4.9333,
    "lng": 13.3,
    "country": "Cameroon",
    "iso2": "CM",
    "iso3": "CMR",
    "admin_name": "Est",
    "capital": "",
    "population": 22553,
    "id": 1120106572
  },
  {
    "city": "Lakeside",
    "city_ascii": "Lakeside",
    "lat": 32.856,
    "lng": -116.904,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 22560,
    "id": 1840018025
  },
  {
    "city": "Happy Valley",
    "city_ascii": "Happy Valley",
    "lat": 45.4362,
    "lng": -122.5077,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 22553,
    "id": 1840019950
  },
  {
    "city": "Friesoythe",
    "city_ascii": "Friesoythe",
    "lat": 53.0206,
    "lng": 7.8586,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 22456,
    "id": 1276189033
  },
  {
    "city": "East Peoria",
    "city_ascii": "East Peoria",
    "lat": 40.6734,
    "lng": -89.5419,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 22546,
    "id": 1840008327
  },
  {
    "city": "Santo Antônio de Posse",
    "city_ascii": "Santo Antonio de Posse",
    "lat": -22.6058,
    "lng": -46.9189,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 22389,
    "id": 1076962973
  },
  {
    "city": "Klatovy",
    "city_ascii": "Klatovy",
    "lat": 49.3956,
    "lng": 13.2951,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Plzeňský Kraj",
    "capital": "",
    "population": 22257,
    "id": 1203400126
  },
  {
    "city": "Northdale",
    "city_ascii": "Northdale",
    "lat": 28.1058,
    "lng": -82.5263,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 22529,
    "id": 1840029107
  },
  {
    "city": "Hopewell",
    "city_ascii": "Hopewell",
    "lat": 37.2915,
    "lng": -77.2985,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 22529,
    "id": 1840003859
  },
  {
    "city": "Fort Walton Beach",
    "city_ascii": "Fort Walton Beach",
    "lat": 30.4249,
    "lng": -86.6194,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 22521,
    "id": 1840013923
  },
  {
    "city": "Radevormwald",
    "city_ascii": "Radevormwald",
    "lat": 51.2,
    "lng": 7.35,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 22107,
    "id": 1276941483
  },
  {
    "city": "Nixa",
    "city_ascii": "Nixa",
    "lat": 37.0453,
    "lng": -93.296,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 22515,
    "id": 1840008789
  },
  {
    "city": "Nadvirna",
    "city_ascii": "Nadvirna",
    "lat": 48.6333,
    "lng": 24.5833,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Ivano-Frankivs’ka Oblast’",
    "capital": "minor",
    "population": 22447,
    "id": 1804082259
  },
  {
    "city": "Olanchito",
    "city_ascii": "Olanchito",
    "lat": 15.4833,
    "lng": -86.5833,
    "country": "Honduras",
    "iso2": "HN",
    "iso3": "HND",
    "admin_name": "Yoro",
    "capital": "",
    "population": 22500,
    "id": 1340362964
  },
  {
    "city": "Flörsheim",
    "city_ascii": "Florsheim",
    "lat": 50.0117,
    "lng": 8.4281,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 21572,
    "id": 1276463362
  },
  {
    "city": "Khadyzhensk",
    "city_ascii": "Khadyzhensk",
    "lat": 44.4239,
    "lng": 39.5372,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Krasnodarskiy Kray",
    "capital": "",
    "population": 22468,
    "id": 1643592689
  },
  {
    "city": "Hythe",
    "city_ascii": "Hythe",
    "lat": 50.869,
    "lng": -1.399,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hampshire",
    "capital": "",
    "population": 20526,
    "id": 1826893817
  },
  {
    "city": "Clinton",
    "city_ascii": "Clinton",
    "lat": 41.1395,
    "lng": -112.0656,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Utah",
    "capital": "",
    "population": 22499,
    "id": 1840018736
  },
  {
    "city": "Apolda",
    "city_ascii": "Apolda",
    "lat": 51.0247,
    "lng": 11.5139,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Thuringia",
    "capital": "minor",
    "population": 22012,
    "id": 1276857102
  },
  {
    "city": "Munster",
    "city_ascii": "Munster",
    "lat": 41.5469,
    "lng": -87.504,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 22476,
    "id": 1840009262
  },
  {
    "city": "Christiansburg",
    "city_ascii": "Christiansburg",
    "lat": 37.1411,
    "lng": -80.4028,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 22473,
    "id": 1840006447
  },
  {
    "city": "Roselle",
    "city_ascii": "Roselle",
    "lat": 41.9807,
    "lng": -88.0861,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 22463,
    "id": 1840011407
  },
  {
    "city": "Garden City",
    "city_ascii": "Garden City",
    "lat": 40.7266,
    "lng": -73.6447,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 22454,
    "id": 1840005289
  },
  {
    "city": "Hajnówka",
    "city_ascii": "Hajnowka",
    "lat": 52.7333,
    "lng": 23.5667,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Podlaskie",
    "capital": "minor",
    "population": 21442,
    "id": 1616405290
  },
  {
    "city": "Granite Bay",
    "city_ascii": "Granite Bay",
    "lat": 38.7601,
    "lng": -121.1714,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 22439,
    "id": 1840017559
  },
  {
    "city": "Gardelegen",
    "city_ascii": "Gardelegen",
    "lat": 52.5264,
    "lng": 11.3925,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony-Anhalt",
    "capital": "",
    "population": 22402,
    "id": 1276557570
  },
  {
    "city": "Saint-Louis",
    "city_ascii": "Saint-Louis",
    "lat": 47.59,
    "lng": 7.57,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Grand Est",
    "capital": "",
    "population": 21177,
    "id": 1250664055
  },
  {
    "city": "Schwetzingen",
    "city_ascii": "Schwetzingen",
    "lat": 49.3833,
    "lng": 8.5667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 21433,
    "id": 1276516691
  },
  {
    "city": "Yabu",
    "city_ascii": "Yabu",
    "lat": 35.4047,
    "lng": 134.7675,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hyōgo",
    "capital": "",
    "population": 22358,
    "id": 1392817826
  },
  {
    "city": "Barnegat",
    "city_ascii": "Barnegat",
    "lat": 39.7665,
    "lng": -74.2773,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 22410,
    "id": 1840081601
  },
  {
    "city": "Warrensburg",
    "city_ascii": "Warrensburg",
    "lat": 38.7627,
    "lng": -93.726,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 22410,
    "id": 1840010721
  },
  {
    "city": "Hockenheim",
    "city_ascii": "Hockenheim",
    "lat": 49.3181,
    "lng": 8.5472,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 21779,
    "id": 1276940967
  },
  {
    "city": "Union City",
    "city_ascii": "Union City",
    "lat": 33.5941,
    "lng": -84.5629,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 22399,
    "id": 1840015610
  },
  {
    "city": "Lebu",
    "city_ascii": "Lebu",
    "lat": -37.6103,
    "lng": -73.6561,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Biobío",
    "capital": "minor",
    "population": 22345,
    "id": 1152064207
  },
  {
    "city": "Mendeleyevsk",
    "city_ascii": "Mendeleyevsk",
    "lat": 55.9,
    "lng": 52.3167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Tatarstan",
    "capital": "",
    "population": 22336,
    "id": 1643860674
  },
  {
    "city": "Bedford",
    "city_ascii": "Bedford",
    "lat": 42.9407,
    "lng": -71.5302,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Hampshire",
    "capital": "",
    "population": 22388,
    "id": 1840054623
  },
  {
    "city": "Corinto",
    "city_ascii": "Corinto",
    "lat": 12.4833,
    "lng": -87.1833,
    "country": "Nicaragua",
    "iso2": "NI",
    "iso3": "NIC",
    "admin_name": "Chinandega",
    "capital": "minor",
    "population": 22000,
    "id": 1558830378
  },
  {
    "city": "Ar Ruţbah",
    "city_ascii": "Ar Rutbah",
    "lat": 33.0333,
    "lng": 40.2833,
    "country": "Iraq",
    "iso2": "IQ",
    "iso3": "IRQ",
    "admin_name": "Al Anbār",
    "capital": "minor",
    "population": 22370,
    "id": 1368174661
  },
  {
    "city": "Vernon",
    "city_ascii": "Vernon",
    "lat": 41.1973,
    "lng": -74.4859,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 22369,
    "id": 1840081777
  },
  {
    "city": "Mashiko",
    "city_ascii": "Mashiko",
    "lat": 36.4675,
    "lng": 140.0931,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Tochigi",
    "capital": "",
    "population": 22116,
    "id": 1392280086
  },
  {
    "city": "Yahualica",
    "city_ascii": "Yahualica",
    "lat": 20.9531,
    "lng": -98.38,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Hidalgo",
    "capital": "",
    "population": 22228,
    "id": 1484980764
  },
  {
    "city": "Heide",
    "city_ascii": "Heide",
    "lat": 54.1961,
    "lng": 9.0933,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Schleswig-Holstein",
    "capital": "minor",
    "population": 21684,
    "id": 1276473220
  },
  {
    "city": "Demba",
    "city_ascii": "Demba",
    "lat": -5.5096,
    "lng": 22.26,
    "country": "Congo (Kinshasa)",
    "iso2": "CD",
    "iso3": "COD",
    "admin_name": "Kasaï Central",
    "capital": "",
    "population": 22263,
    "id": 1180021198
  },
  {
    "city": "Villeneuve-sur-Lot",
    "city_ascii": "Villeneuve-sur-Lot",
    "lat": 44.4081,
    "lng": 0.705,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Nouvelle-Aquitaine",
    "capital": "minor",
    "population": 22064,
    "id": 1250450976
  },
  {
    "city": "Alvarado",
    "city_ascii": "Alvarado",
    "lat": 18.7811,
    "lng": -95.7572,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Veracruz",
    "capital": "minor",
    "population": 22330,
    "id": 1484208918
  },
  {
    "city": "Colorado",
    "city_ascii": "Colorado",
    "lat": -22.8378,
    "lng": -51.9728,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Paraná",
    "capital": "",
    "population": 22345,
    "id": 1076860546
  },
  {
    "city": "Dolton",
    "city_ascii": "Dolton",
    "lat": 41.6284,
    "lng": -87.5979,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 22348,
    "id": 1840011269
  },
  {
    "city": "Chaumont",
    "city_ascii": "Chaumont",
    "lat": 48.1117,
    "lng": 5.1389,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Grand Est",
    "capital": "minor",
    "population": 21945,
    "id": 1250139461
  },
  {
    "city": "Bacchus Marsh",
    "city_ascii": "Bacchus Marsh",
    "lat": -37.675,
    "lng": 144.4389,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Victoria",
    "capital": "",
    "population": 22223,
    "id": 1036524751
  },
  {
    "city": "Camaná",
    "city_ascii": "Camana",
    "lat": -16.6233,
    "lng": -72.7092,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "Arequipa",
    "capital": "",
    "population": 20956,
    "id": 1604379091
  },
  {
    "city": "Lyman",
    "city_ascii": "Lyman",
    "lat": 48.9861,
    "lng": 37.8111,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Donets’ka Oblast’",
    "capital": "minor",
    "population": 22315,
    "id": 1804424570
  },
  {
    "city": "Northfield",
    "city_ascii": "Northfield",
    "lat": 44.455,
    "lng": -93.1698,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 22325,
    "id": 1840007891
  },
  {
    "city": "Nikolskoye",
    "city_ascii": "Nikolskoye",
    "lat": 59.7,
    "lng": 30.7833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Leningradskaya Oblast’",
    "capital": "",
    "population": 22286,
    "id": 1643810900
  },
  {
    "city": "Highland",
    "city_ascii": "Highland",
    "lat": 41.5485,
    "lng": -87.4587,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 22316,
    "id": 1840010226
  },
  {
    "city": "Musselburgh",
    "city_ascii": "Musselburgh",
    "lat": 55.942,
    "lng": -3.054,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "East Lothian",
    "capital": "",
    "population": 21900,
    "id": 1826974542
  },
  {
    "city": "Ḩārim",
    "city_ascii": "Harim",
    "lat": 36.2076,
    "lng": 36.5192,
    "country": "Syria",
    "iso2": "SY",
    "iso3": "SYR",
    "admin_name": "Idlib",
    "capital": "minor",
    "population": 21934,
    "id": 1760216782
  },
  {
    "city": "Sequim",
    "city_ascii": "Sequim",
    "lat": 48.0746,
    "lng": -123.0944,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 22304,
    "id": 1840021076
  },
  {
    "city": "Morris",
    "city_ascii": "Morris",
    "lat": 40.796,
    "lng": -74.4945,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 22302,
    "id": 1840081723
  },
  {
    "city": "Djibo",
    "city_ascii": "Djibo",
    "lat": 14.099,
    "lng": -1.627,
    "country": "Burkina Faso",
    "iso2": "BF",
    "iso3": "BFA",
    "admin_name": "Sahel",
    "capital": "minor",
    "population": 22223,
    "id": 1854364299
  },
  {
    "city": "Spremberg",
    "city_ascii": "Spremberg",
    "lat": 51.5717,
    "lng": 14.3794,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 22175,
    "id": 1276939967
  },
  {
    "city": "Prairie Village",
    "city_ascii": "Prairie Village",
    "lat": 38.9874,
    "lng": -94.6361,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kansas",
    "capital": "",
    "population": 22295,
    "id": 1840001644
  },
  {
    "city": "Atuntaqui",
    "city_ascii": "Atuntaqui",
    "lat": 0.3325,
    "lng": -78.2136,
    "country": "Ecuador",
    "iso2": "EC",
    "iso3": "ECU",
    "admin_name": "Imbabura",
    "capital": "",
    "population": 21286,
    "id": 1218840955
  },
  {
    "city": "Kuna",
    "city_ascii": "Kuna",
    "lat": 43.4869,
    "lng": -116.3986,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Idaho",
    "capital": "",
    "population": 22292,
    "id": 1840020047
  },
  {
    "city": "Coralville",
    "city_ascii": "Coralville",
    "lat": 41.699,
    "lng": -91.5967,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Iowa",
    "capital": "",
    "population": 22290,
    "id": 1840007076
  },
  {
    "city": "Waltham Abbey",
    "city_ascii": "Waltham Abbey",
    "lat": 51.6846,
    "lng": 0.0004,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Essex",
    "capital": "",
    "population": 21149,
    "id": 1826980531
  },
  {
    "city": "Popeşti-Leordeni",
    "city_ascii": "Popesti-Leordeni",
    "lat": 44.38,
    "lng": 26.17,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Ilfov",
    "capital": "",
    "population": 21895,
    "id": 1642953954
  },
  {
    "city": "Guilford",
    "city_ascii": "Guilford",
    "lat": 41.3345,
    "lng": -72.7004,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 22285,
    "id": 1840045023
  },
  {
    "city": "Fort Mill",
    "city_ascii": "Fort Mill",
    "lat": 35.0062,
    "lng": -80.9388,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 22284,
    "id": 1840016479
  },
  {
    "city": "Bad Harzburg",
    "city_ascii": "Bad Harzburg",
    "lat": 51.8828,
    "lng": 10.5617,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 21945,
    "id": 1276192309
  },
  {
    "city": "Waldkirch",
    "city_ascii": "Waldkirch",
    "lat": 48.0939,
    "lng": 7.9608,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 21809,
    "id": 1276926019
  },
  {
    "city": "Ban Piang Luang",
    "city_ascii": "Ban Piang Luang",
    "lat": 19.6493,
    "lng": 98.6352,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Chiang Mai",
    "capital": "",
    "population": 22215,
    "id": 1764002589
  },
  {
    "city": "Eséka",
    "city_ascii": "Eseka",
    "lat": 3.6504,
    "lng": 10.7666,
    "country": "Cameroon",
    "iso2": "CM",
    "iso3": "CMR",
    "admin_name": "Centre",
    "capital": "",
    "population": 22221,
    "id": 1120064220
  },
  {
    "city": "Millau",
    "city_ascii": "Millau",
    "lat": 44.0986,
    "lng": 3.0783,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Occitanie",
    "capital": "minor",
    "population": 22109,
    "id": 1250699654
  },
  {
    "city": "Hudson",
    "city_ascii": "Hudson",
    "lat": 41.2399,
    "lng": -81.4408,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 22237,
    "id": 1840007138
  },
  {
    "city": "Fougères",
    "city_ascii": "Fougeres",
    "lat": 48.3525,
    "lng": -1.1986,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Bretagne",
    "capital": "minor",
    "population": 20418,
    "id": 1250890838
  },
  {
    "city": "Bristol",
    "city_ascii": "Bristol",
    "lat": 41.6827,
    "lng": -71.2694,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Rhode Island",
    "capital": "",
    "population": 22234,
    "id": 1840106238
  },
  {
    "city": "Chapa de Mota",
    "city_ascii": "Chapa de Mota",
    "lat": 19.8144,
    "lng": -99.5269,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "México",
    "capital": "minor",
    "population": 21746,
    "id": 1484616953
  },
  {
    "city": "Auch",
    "city_ascii": "Auch",
    "lat": 43.6465,
    "lng": 0.5855,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Occitanie",
    "capital": "minor",
    "population": 21935,
    "id": 1250746513
  },
  {
    "city": "Roanoke Rapids",
    "city_ascii": "Roanoke Rapids",
    "lat": 36.4452,
    "lng": -77.6491,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 22225,
    "id": 1840014437
  },
  {
    "city": "Niskayuna",
    "city_ascii": "Niskayuna",
    "lat": 42.803,
    "lng": -73.873,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 22225,
    "id": 1840058350
  },
  {
    "city": "Hazebrouck",
    "city_ascii": "Hazebrouck",
    "lat": 50.725,
    "lng": 2.5392,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Hauts-de-France",
    "capital": "",
    "population": 21441,
    "id": 1250140504
  },
  {
    "city": "Saint-Jean-de-Braye",
    "city_ascii": "Saint-Jean-de-Braye",
    "lat": 47.9128,
    "lng": 1.9719,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Centre-Val de Loire",
    "capital": "",
    "population": 20751,
    "id": 1250168374
  },
  {
    "city": "Moguer",
    "city_ascii": "Moguer",
    "lat": 37.2747,
    "lng": -6.8386,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Andalusia",
    "capital": "",
    "population": 22088,
    "id": 1724821530
  },
  {
    "city": "Raymore",
    "city_ascii": "Raymore",
    "lat": 38.8029,
    "lng": -94.4583,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 22194,
    "id": 1840009787
  },
  {
    "city": "Fremont",
    "city_ascii": "Fremont",
    "lat": 41.3536,
    "lng": -83.1148,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 22191,
    "id": 1840000653
  },
  {
    "city": "Mukwonago",
    "city_ascii": "Mukwonago",
    "lat": 42.8566,
    "lng": -88.3269,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 22185,
    "id": 1840003022
  },
  {
    "city": "Laventille",
    "city_ascii": "Laventille",
    "lat": 10.65,
    "lng": -61.4833,
    "country": "Trinidad And Tobago",
    "iso2": "TT",
    "iso3": "TTO",
    "admin_name": "San Juan/Laventille",
    "capital": "",
    "population": 21454,
    "id": 1780201146
  },
  {
    "city": "Bavly",
    "city_ascii": "Bavly",
    "lat": 54.4,
    "lng": 53.25,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Tatarstan",
    "capital": "",
    "population": 22145,
    "id": 1643213721
  },
  {
    "city": "Qarazhal",
    "city_ascii": "Qarazhal",
    "lat": 48.0253,
    "lng": 70.7999,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Qaraghandy",
    "capital": "",
    "population": 22170,
    "id": 1398149188
  },
  {
    "city": "Halluin",
    "city_ascii": "Halluin",
    "lat": 50.7836,
    "lng": 3.1256,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Hauts-de-France",
    "capital": "",
    "population": 20800,
    "id": 1250891444
  },
  {
    "city": "Kitzingen",
    "city_ascii": "Kitzingen",
    "lat": 49.7333,
    "lng": 10.1667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "minor",
    "population": 21704,
    "id": 1276000007
  },
  {
    "city": "Lebanon",
    "city_ascii": "Lebanon",
    "lat": 44.5317,
    "lng": -122.9071,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 22160,
    "id": 1840019991
  },
  {
    "city": "Pickerington",
    "city_ascii": "Pickerington",
    "lat": 39.889,
    "lng": -82.7678,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 22158,
    "id": 1840009528
  },
  {
    "city": "Lexington",
    "city_ascii": "Lexington",
    "lat": 33.989,
    "lng": -81.2202,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 22157,
    "id": 1840016724
  },
  {
    "city": "Eislingen",
    "city_ascii": "Eislingen",
    "lat": 48.6933,
    "lng": 9.7067,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 20885,
    "id": 1276267974
  },
  {
    "city": "Unity",
    "city_ascii": "Unity",
    "lat": 40.2811,
    "lng": -79.4236,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 22156,
    "id": 1840144811
  },
  {
    "city": "Fukuyoshi",
    "city_ascii": "Fukuyoshi",
    "lat": 33.6833,
    "lng": 130.78,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Fukuoka",
    "capital": "",
    "population": 21641,
    "id": 1392624866
  },
  {
    "city": "Arvin",
    "city_ascii": "Arvin",
    "lat": 35.1944,
    "lng": -118.8306,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 22155,
    "id": 1840019147
  },
  {
    "city": "Southold",
    "city_ascii": "Southold",
    "lat": 41.0435,
    "lng": -72.4184,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 22147,
    "id": 1840058507
  },
  {
    "city": "Chester",
    "city_ascii": "Chester",
    "lat": 37.3531,
    "lng": -77.4342,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 22144,
    "id": 1840006410
  },
  {
    "city": "Stoneham",
    "city_ascii": "Stoneham",
    "lat": 42.4741,
    "lng": -71.0972,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 22144,
    "id": 1840053487
  },
  {
    "city": "Büdingen",
    "city_ascii": "Budingen",
    "lat": 50.2908,
    "lng": 9.1125,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 21959,
    "id": 1276951058
  },
  {
    "city": "Kommunar",
    "city_ascii": "Kommunar",
    "lat": 59.6206,
    "lng": 30.39,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Leningradskaya Oblast’",
    "capital": "",
    "population": 22055,
    "id": 1643122289
  },
  {
    "city": "Palmital",
    "city_ascii": "Palmital",
    "lat": -22.7889,
    "lng": -50.2175,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 22094,
    "id": 1076414022
  },
  {
    "city": "Łęczna",
    "city_ascii": "Leczna",
    "lat": 51.3,
    "lng": 22.8833,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Lubelskie",
    "capital": "minor",
    "population": 21802,
    "id": 1616600546
  },
  {
    "city": "Świedbodzin",
    "city_ascii": "Swiedbodzin",
    "lat": 52.25,
    "lng": 15.5333,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Lubuskie",
    "capital": "minor",
    "population": 21988,
    "id": 1616504526
  },
  {
    "city": "Lino Lakes",
    "city_ascii": "Lino Lakes",
    "lat": 45.1679,
    "lng": -93.083,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 22119,
    "id": 1840008902
  },
  {
    "city": "Augusta",
    "city_ascii": "Augusta",
    "lat": 44.3341,
    "lng": -69.7319,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maine",
    "capital": "",
    "population": 22116,
    "id": 1840000302
  },
  {
    "city": "Milford",
    "city_ascii": "Milford",
    "lat": 38.9091,
    "lng": -75.4224,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Delaware",
    "capital": "",
    "population": 22102,
    "id": 1840006069
  },
  {
    "city": "Cunha",
    "city_ascii": "Cunha",
    "lat": -23.0739,
    "lng": -44.96,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 22086,
    "id": 1076000535
  },
  {
    "city": "Corinth",
    "city_ascii": "Corinth",
    "lat": 33.1435,
    "lng": -97.0681,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 22099,
    "id": 1840019389
  },
  {
    "city": "Naples",
    "city_ascii": "Naples",
    "lat": 26.1504,
    "lng": -81.7936,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 22088,
    "id": 1840015131
  },
  {
    "city": "Maghull",
    "city_ascii": "Maghull",
    "lat": 53.5174,
    "lng": -2.9449,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Sefton",
    "capital": "",
    "population": 20444,
    "id": 1826082081
  },
  {
    "city": "Ust’-Katav",
    "city_ascii": "Ust'-Katav",
    "lat": 54.9333,
    "lng": 58.1667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Chelyabinskaya Oblast’",
    "capital": "",
    "population": 22042,
    "id": 1643326615
  },
  {
    "city": "Maple Heights",
    "city_ascii": "Maple Heights",
    "lat": 41.4094,
    "lng": -81.5625,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 22078,
    "id": 1840000611
  },
  {
    "city": "Vänersborg",
    "city_ascii": "Vanersborg",
    "lat": 58.363,
    "lng": 12.33,
    "country": "Sweden",
    "iso2": "SE",
    "iso3": "SWE",
    "admin_name": "Västra Götaland",
    "capital": "minor",
    "population": 21835,
    "id": 1752467593
  },
  {
    "city": "Cockeysville",
    "city_ascii": "Cockeysville",
    "lat": 39.4793,
    "lng": -76.63,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 22071,
    "id": 1840005680
  },
  {
    "city": "Semikarakorsk",
    "city_ascii": "Semikarakorsk",
    "lat": 47.5167,
    "lng": 40.8,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Rostovskaya Oblast’",
    "capital": "minor",
    "population": 22030,
    "id": 1643808895
  },
  {
    "city": "Łaziska Górne",
    "city_ascii": "Laziska Gorne",
    "lat": 50.15,
    "lng": 18.8333,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Śląskie",
    "capital": "",
    "population": 21958,
    "id": 1616701110
  },
  {
    "city": "Peshtera",
    "city_ascii": "Peshtera",
    "lat": 42.0322,
    "lng": 24.3022,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Pazardzhik",
    "capital": "minor",
    "population": 21824,
    "id": 1100868605
  },
  {
    "city": "Eppingen",
    "city_ascii": "Eppingen",
    "lat": 49.1333,
    "lng": 8.9167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 21819,
    "id": 1276844300
  },
  {
    "city": "Raritan",
    "city_ascii": "Raritan",
    "lat": 40.507,
    "lng": -74.8662,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 22063,
    "id": 1840143543
  },
  {
    "city": "Gotse Delchev",
    "city_ascii": "Gotse Delchev",
    "lat": 41.5728,
    "lng": 23.7294,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Blagoevgrad",
    "capital": "",
    "population": 21969,
    "id": 1100837214
  },
  {
    "city": "Brent",
    "city_ascii": "Brent",
    "lat": 30.4729,
    "lng": -87.2496,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 22056,
    "id": 1840013913
  },
  {
    "city": "Hlohovec",
    "city_ascii": "Hlohovec",
    "lat": 48.4311,
    "lng": 17.8031,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Trnavský",
    "capital": "minor",
    "population": 21715,
    "id": 1703788561
  },
  {
    "city": "Silver Firs",
    "city_ascii": "Silver Firs",
    "lat": 47.8635,
    "lng": -122.1497,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 22052,
    "id": 1840037898
  },
  {
    "city": "Póvoa de Lanhoso",
    "city_ascii": "Povoa de Lanhoso",
    "lat": 41.5667,
    "lng": -8.2667,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Braga",
    "capital": "minor",
    "population": 21886,
    "id": 1620452875
  },
  {
    "city": "Jalpan",
    "city_ascii": "Jalpan",
    "lat": 21.2167,
    "lng": -99.4725,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Querétaro",
    "capital": "minor",
    "population": 22025,
    "id": 1484214957
  },
  {
    "city": "Kozmodemyansk",
    "city_ascii": "Kozmodemyansk",
    "lat": 56.3419,
    "lng": 46.5636,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Mariy-El",
    "capital": "",
    "population": 20505,
    "id": 1643473458
  },
  {
    "city": "Union Hill-Novelty Hill",
    "city_ascii": "Union Hill-Novelty Hill",
    "lat": 47.6788,
    "lng": -122.0284,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 22034,
    "id": 1840074663
  },
  {
    "city": "Gardner",
    "city_ascii": "Gardner",
    "lat": 38.8158,
    "lng": -94.93,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kansas",
    "capital": "",
    "population": 22031,
    "id": 1840001636
  },
  {
    "city": "Lyndhurst",
    "city_ascii": "Lyndhurst",
    "lat": 40.7964,
    "lng": -74.1099,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 22030,
    "id": 1840081755
  },
  {
    "city": "Vilyuchinsk",
    "city_ascii": "Vilyuchinsk",
    "lat": 52.9333,
    "lng": 158.4,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kamchatskiy Kray",
    "capital": "",
    "population": 21973,
    "id": 1643675061
  },
  {
    "city": "Kemi",
    "city_ascii": "Kemi",
    "lat": 65.7361,
    "lng": 24.5639,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Lappi",
    "capital": "minor",
    "population": 21758,
    "id": 1246411843
  },
  {
    "city": "Omutninsk",
    "city_ascii": "Omutninsk",
    "lat": 58.6667,
    "lng": 52.1833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kirovskaya Oblast’",
    "capital": "",
    "population": 22009,
    "id": 1643987727
  },
  {
    "city": "Armyans’k",
    "city_ascii": "Armyans'k",
    "lat": 46.1072,
    "lng": 33.6933,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Krym, Avtonomna Respublika",
    "capital": "",
    "population": 21956,
    "id": 1804358362
  },
  {
    "city": "Rose Hill",
    "city_ascii": "Rose Hill",
    "lat": 38.7872,
    "lng": -77.1085,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 22011,
    "id": 1840133323
  },
  {
    "city": "Sonthofen",
    "city_ascii": "Sonthofen",
    "lat": 47.5142,
    "lng": 10.2817,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "minor",
    "population": 21541,
    "id": 1276823857
  },
  {
    "city": "Kotovo",
    "city_ascii": "Kotovo",
    "lat": 50.3167,
    "lng": 44.8,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Volgogradskaya Oblast’",
    "capital": "",
    "population": 21990,
    "id": 1643692011
  },
  {
    "city": "Puerto Suárez",
    "city_ascii": "Puerto Suarez",
    "lat": -18.9633,
    "lng": -57.7978,
    "country": "Bolivia",
    "iso2": "BO",
    "iso3": "BOL",
    "admin_name": "Santa Cruz",
    "capital": "",
    "population": 22000,
    "id": 1068099903
  },
  {
    "city": "Axapusco",
    "city_ascii": "Axapusco",
    "lat": 19.7194,
    "lng": -98.7972,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "México",
    "capital": "minor",
    "population": 21915,
    "id": 1484580584
  },
  {
    "city": "Vereshchagino",
    "city_ascii": "Vereshchagino",
    "lat": 58.0667,
    "lng": 54.65,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Permskiy Kray",
    "capital": "",
    "population": 21969,
    "id": 1643676991
  },
  {
    "city": "Elsdorf",
    "city_ascii": "Elsdorf",
    "lat": 50.9333,
    "lng": 6.5667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 21663,
    "id": 1276581538
  },
  {
    "city": "Xanten",
    "city_ascii": "Xanten",
    "lat": 51.6622,
    "lng": 6.4539,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 21690,
    "id": 1276917094
  },
  {
    "city": "Seligenstadt",
    "city_ascii": "Seligenstadt",
    "lat": 50.0441,
    "lng": 8.9753,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 21293,
    "id": 1276356108
  },
  {
    "city": "Kotli",
    "city_ascii": "Kotli",
    "lat": 33.5156,
    "lng": 73.9019,
    "country": "Pakistan",
    "iso2": "PK",
    "iso3": "PAK",
    "admin_name": "Azad Kashmir",
    "capital": "minor",
    "population": 21462,
    "id": 1586677063
  },
  {
    "city": "Dumbarton",
    "city_ascii": "Dumbarton",
    "lat": 55.95,
    "lng": -4.5667,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "West Dunbartonshire",
    "capital": "",
    "population": 20527,
    "id": 1826077098
  },
  {
    "city": "Siloam Springs",
    "city_ascii": "Siloam Springs",
    "lat": 36.1844,
    "lng": -94.5318,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arkansas",
    "capital": "",
    "population": 21975,
    "id": 1840015288
  },
  {
    "city": "Iisalmi",
    "city_ascii": "Iisalmi",
    "lat": 63.5611,
    "lng": 27.1889,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Pohjois-Savo",
    "capital": "minor",
    "population": 21945,
    "id": 1246230947
  },
  {
    "city": "Opoczno",
    "city_ascii": "Opoczno",
    "lat": 51.3833,
    "lng": 20.2833,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Łódzkie",
    "capital": "minor",
    "population": 21780,
    "id": 1616841485
  },
  {
    "city": "Reigate",
    "city_ascii": "Reigate",
    "lat": 51.237,
    "lng": -0.206,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Surrey",
    "capital": "",
    "population": 21820,
    "id": 1826422343
  },
  {
    "city": "Palafrugell",
    "city_ascii": "Palafrugell",
    "lat": 41.9174,
    "lng": 3.1631,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Catalonia",
    "capital": "",
    "population": 21049,
    "id": 1724222383
  },
  {
    "city": "Pedernales",
    "city_ascii": "Pedernales",
    "lat": 0.0719,
    "lng": -80.0508,
    "country": "Ecuador",
    "iso2": "EC",
    "iso3": "ECU",
    "admin_name": "Manabí",
    "capital": "",
    "population": 21910,
    "id": 1218670991
  },
  {
    "city": "Trinidad",
    "city_ascii": "Trinidad",
    "lat": -33.5333,
    "lng": -56.8833,
    "country": "Uruguay",
    "iso2": "UY",
    "iso3": "URY",
    "admin_name": "Flores",
    "capital": "",
    "population": 21429,
    "id": 1858903609
  },
  {
    "city": "Peters",
    "city_ascii": "Peters",
    "lat": 40.274,
    "lng": -80.0802,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 21954,
    "id": 1840147982
  },
  {
    "city": "Wieliczka",
    "city_ascii": "Wieliczka",
    "lat": 49.9894,
    "lng": 20.0661,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Małopolskie",
    "capital": "minor",
    "population": 21878,
    "id": 1616249410
  },
  {
    "city": "Halle",
    "city_ascii": "Halle",
    "lat": 52.0608,
    "lng": 8.3597,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 21640,
    "id": 1276770255
  },
  {
    "city": "Tornio",
    "city_ascii": "Tornio",
    "lat": 65.8486,
    "lng": 24.1472,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Lappi",
    "capital": "minor",
    "population": 21928,
    "id": 1246209112
  },
  {
    "city": "Osterode",
    "city_ascii": "Osterode",
    "lat": 51.7286,
    "lng": 10.2522,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "minor",
    "population": 21731,
    "id": 1276593815
  },
  {
    "city": "Gif-sur-Yvette",
    "city_ascii": "Gif-sur-Yvette",
    "lat": 48.7018,
    "lng": 2.1339,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 21379,
    "id": 1250169413
  },
  {
    "city": "Piqua",
    "city_ascii": "Piqua",
    "lat": 40.1505,
    "lng": -84.2441,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 21936,
    "id": 1840009467
  },
  {
    "city": "Amherstburg",
    "city_ascii": "Amherstburg",
    "lat": 42.1,
    "lng": -83.0833,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 21936,
    "id": 1124696938
  },
  {
    "city": "Chełmno",
    "city_ascii": "Chelmno",
    "lat": 53.3492,
    "lng": 18.4261,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Kujawsko-Pomorskie",
    "capital": "minor",
    "population": 20428,
    "id": 1616879030
  },
  {
    "city": "Hallein",
    "city_ascii": "Hallein",
    "lat": 47.6667,
    "lng": 13.0833,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Salzburg",
    "capital": "minor",
    "population": 21150,
    "id": 1040821146
  },
  {
    "city": "Yoqne‘am ‘Illit",
    "city_ascii": "Yoqne`am `Illit",
    "lat": 32.6594,
    "lng": 35.11,
    "country": "Israel",
    "iso2": "IL",
    "iso3": "ISR",
    "admin_name": "Northern",
    "capital": "",
    "population": 21383,
    "id": 1376262404
  },
  {
    "city": "Port Hueneme",
    "city_ascii": "Port Hueneme",
    "lat": 34.1617,
    "lng": -119.2036,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 21926,
    "id": 1840020475
  },
  {
    "city": "Taylors",
    "city_ascii": "Taylors",
    "lat": 34.9157,
    "lng": -82.3124,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 21923,
    "id": 1840013498
  },
  {
    "city": "Keene",
    "city_ascii": "Keene",
    "lat": 42.9494,
    "lng": -72.2997,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Hampshire",
    "capital": "",
    "population": 21911,
    "id": 1840003050
  },
  {
    "city": "Gympie",
    "city_ascii": "Gympie",
    "lat": -26.19,
    "lng": 152.6655,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Queensland",
    "capital": "",
    "population": 21599,
    "id": 1036391013
  },
  {
    "city": "East Hampton",
    "city_ascii": "East Hampton",
    "lat": 41.0117,
    "lng": -72.1277,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 21903,
    "id": 1840005147
  },
  {
    "city": "Azambuja",
    "city_ascii": "Azambuja",
    "lat": 39.0667,
    "lng": -8.8667,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Lisboa",
    "capital": "minor",
    "population": 21814,
    "id": 1620393026
  },
  {
    "city": "Casablanca",
    "city_ascii": "Casablanca",
    "lat": -33.3167,
    "lng": -71.4167,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Valparaíso",
    "capital": "",
    "population": 21874,
    "id": 1152471983
  },
  {
    "city": "Summit",
    "city_ascii": "Summit",
    "lat": 40.7154,
    "lng": -74.3647,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 21897,
    "id": 1840000999
  },
  {
    "city": "Halewood",
    "city_ascii": "Halewood",
    "lat": 53.3599,
    "lng": -2.84,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Knowsley",
    "capital": "",
    "population": 20116,
    "id": 1826807360
  },
  {
    "city": "Secaucus",
    "city_ascii": "Secaucus",
    "lat": 40.781,
    "lng": -74.0659,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 21893,
    "id": 1840001033
  },
  {
    "city": "Potters Bar",
    "city_ascii": "Potters Bar",
    "lat": 51.698,
    "lng": -0.183,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hertfordshire",
    "capital": "",
    "population": 21639,
    "id": 1826408007
  },
  {
    "city": "Lisieux",
    "city_ascii": "Lisieux",
    "lat": 49.15,
    "lng": 0.23,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Normandie",
    "capital": "minor",
    "population": 20318,
    "id": 1250048111
  },
  {
    "city": "Golden Valley",
    "city_ascii": "Golden Valley",
    "lat": 44.9901,
    "lng": -93.3591,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 21886,
    "id": 1840007824
  },
  {
    "city": "New Hartford",
    "city_ascii": "New Hartford",
    "lat": 43.0587,
    "lng": -75.2822,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 21880,
    "id": 1840004206
  },
  {
    "city": "Kihei",
    "city_ascii": "Kihei",
    "lat": 20.7653,
    "lng": -156.4454,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Hawaii",
    "capital": "",
    "population": 21879,
    "id": 1840029507
  },
  {
    "city": "Gros Islet",
    "city_ascii": "Gros Islet",
    "lat": 14.081,
    "lng": -60.953,
    "country": "Saint Lucia",
    "iso2": "LC",
    "iso3": "LCA",
    "admin_name": "Gros-Islet",
    "capital": "",
    "population": 21660,
    "id": 1662587230
  },
  {
    "city": "Castelnau-le-Lez",
    "city_ascii": "Castelnau-le-Lez",
    "lat": 43.6369,
    "lng": 3.9019,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Occitanie",
    "capital": "",
    "population": 20480,
    "id": 1250544217
  },
  {
    "city": "Mount Vernon",
    "city_ascii": "Mount Vernon",
    "lat": 40.3854,
    "lng": -82.4734,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 21864,
    "id": 1840001343
  },
  {
    "city": "Brockville",
    "city_ascii": "Brockville",
    "lat": 44.5833,
    "lng": -75.6833,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 21854,
    "id": 1124286131
  },
  {
    "city": "Lower",
    "city_ascii": "Lower",
    "lat": 38.9819,
    "lng": -74.9088,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 21838,
    "id": 1840081575
  },
  {
    "city": "Watertown",
    "city_ascii": "Watertown",
    "lat": 41.616,
    "lng": -73.1177,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 21832,
    "id": 1840035651
  },
  {
    "city": "San Martín de las Pirámides",
    "city_ascii": "San Martin de las Piramides",
    "lat": 19.7333,
    "lng": -98.8167,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "México",
    "capital": "minor",
    "population": 21511,
    "id": 1484000182
  },
  {
    "city": "Montereau-faut-Yonne",
    "city_ascii": "Montereau-faut-Yonne",
    "lat": 48.3853,
    "lng": 2.9508,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 20206,
    "id": 1250284749
  },
  {
    "city": "Ban Patong",
    "city_ascii": "Ban Patong",
    "lat": 7.8981,
    "lng": 98.3047,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Phuket",
    "capital": "",
    "population": 20363,
    "id": 1764195169
  },
  {
    "city": "Geneva",
    "city_ascii": "Geneva",
    "lat": 41.8832,
    "lng": -88.3242,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 21809,
    "id": 1840008135
  },
  {
    "city": "Collingwood",
    "city_ascii": "Collingwood",
    "lat": 44.5,
    "lng": -80.2167,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 21793,
    "id": 1124219884
  },
  {
    "city": "Watertown",
    "city_ascii": "Watertown",
    "lat": 44.9094,
    "lng": -97.1531,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Dakota",
    "capital": "",
    "population": 21790,
    "id": 1840002156
  },
  {
    "city": "Quickborn",
    "city_ascii": "Quickborn",
    "lat": 53.7333,
    "lng": 9.8972,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Schleswig-Holstein",
    "capital": "",
    "population": 21296,
    "id": 1276737039
  },
  {
    "city": "Dax",
    "city_ascii": "Dax",
    "lat": 43.71,
    "lng": -1.05,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Nouvelle-Aquitaine",
    "capital": "minor",
    "population": 20681,
    "id": 1250059757
  },
  {
    "city": "Bloomington",
    "city_ascii": "Bloomington",
    "lat": 34.0601,
    "lng": -117.4013,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 21783,
    "id": 1840017799
  },
  {
    "city": "Bloomingdale",
    "city_ascii": "Bloomingdale",
    "lat": 41.9497,
    "lng": -88.0895,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 21779,
    "id": 1840011399
  },
  {
    "city": "Saint Austell",
    "city_ascii": "Saint Austell",
    "lat": 50.3386,
    "lng": -4.7922,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Cornwall",
    "capital": "",
    "population": 19958,
    "id": 1826177227
  },
  {
    "city": "Couëron",
    "city_ascii": "Coueron",
    "lat": 47.2156,
    "lng": -1.7228,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Pays de la Loire",
    "capital": "",
    "population": 21372,
    "id": 1250732111
  },
  {
    "city": "Darien",
    "city_ascii": "Darien",
    "lat": 41.0786,
    "lng": -73.4819,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 21759,
    "id": 1840034409
  },
  {
    "city": "Beaune",
    "city_ascii": "Beaune",
    "lat": 47.025,
    "lng": 4.8397,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Bourgogne-Franche-Comté",
    "capital": "minor",
    "population": 21031,
    "id": 1250996619
  },
  {
    "city": "Miguelópolis",
    "city_ascii": "Miguelopolis",
    "lat": -20.1794,
    "lng": -48.0319,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 21728,
    "id": 1076327986
  },
  {
    "city": "Kurikka",
    "city_ascii": "Kurikka",
    "lat": 62.6167,
    "lng": 22.4,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Etelä-Pohjanmaa",
    "capital": "minor",
    "population": 21734,
    "id": 1246688374
  },
  {
    "city": "Shelbyville",
    "city_ascii": "Shelbyville",
    "lat": 35.4987,
    "lng": -86.4516,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 21741,
    "id": 1840015412
  },
  {
    "city": "Germersheim",
    "city_ascii": "Germersheim",
    "lat": 49.2167,
    "lng": 8.3667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Rhineland-Palatinate",
    "capital": "minor",
    "population": 20779,
    "id": 1276963829
  },
  {
    "city": "Gökçebey",
    "city_ascii": "Gokcebey",
    "lat": 41.3133,
    "lng": 32.1497,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Zonguldak",
    "capital": "minor",
    "population": 21655,
    "id": 1792723189
  },
  {
    "city": "Mobetsu",
    "city_ascii": "Mobetsu",
    "lat": 44.3564,
    "lng": 143.3542,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hokkaidō",
    "capital": "",
    "population": 21709,
    "id": 1392557863
  },
  {
    "city": "Shafter",
    "city_ascii": "Shafter",
    "lat": 35.4794,
    "lng": -119.2013,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 21734,
    "id": 1840021735
  },
  {
    "city": "Wallington",
    "city_ascii": "Wallington",
    "lat": 51.3647,
    "lng": -0.1403,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Sutton",
    "capital": "",
    "population": 20850,
    "id": 1826952560
  },
  {
    "city": "Stadtallendorf",
    "city_ascii": "Stadtallendorf",
    "lat": 50.8333,
    "lng": 9.0167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 21456,
    "id": 1276032217
  },
  {
    "city": "Brownwood",
    "city_ascii": "Brownwood",
    "lat": 31.7127,
    "lng": -98.9767,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 21729,
    "id": 1840019519
  },
  {
    "city": "Katy",
    "city_ascii": "Katy",
    "lat": 29.7904,
    "lng": -95.8353,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 21729,
    "id": 1840019648
  },
  {
    "city": "Chernogolovka",
    "city_ascii": "Chernogolovka",
    "lat": 56.0147,
    "lng": 38.3897,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Moskovskaya Oblast’",
    "capital": "",
    "population": 21342,
    "id": 1643604279
  },
  {
    "city": "Green Valley",
    "city_ascii": "Green Valley",
    "lat": 31.8436,
    "lng": -111.0174,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 21723,
    "id": 1840018111
  },
  {
    "city": "Versmold",
    "city_ascii": "Versmold",
    "lat": 52.0436,
    "lng": 8.15,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 21468,
    "id": 1276482608
  },
  {
    "city": "Del City",
    "city_ascii": "Del City",
    "lat": 35.4483,
    "lng": -97.4408,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oklahoma",
    "capital": "",
    "population": 21712,
    "id": 1840019154
  },
  {
    "city": "Woodlesford",
    "city_ascii": "Woodlesford",
    "lat": 53.7567,
    "lng": -1.453,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Leeds",
    "capital": "",
    "population": 21010,
    "id": 1826564728
  },
  {
    "city": "Kobilo",
    "city_ascii": "Kobilo",
    "lat": 16.15,
    "lng": -13.5,
    "country": "Senegal",
    "iso2": "SN",
    "iso3": "SEN",
    "admin_name": "Matam",
    "capital": "",
    "population": 21656,
    "id": 1686275678
  },
  {
    "city": "Jindřichŭv Hradec",
    "city_ascii": "Jindrichuv Hradec",
    "lat": 49.1441,
    "lng": 15.003,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Jihočeský Kraj",
    "capital": "",
    "population": 21419,
    "id": 1203413811
  },
  {
    "city": "Zerbst",
    "city_ascii": "Zerbst",
    "lat": 51.9681,
    "lng": 12.0844,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony-Anhalt",
    "capital": "",
    "population": 21657,
    "id": 1276007718
  },
  {
    "city": "Senak’i",
    "city_ascii": "Senak'i",
    "lat": 42.2689,
    "lng": 42.0678,
    "country": "Georgia",
    "iso2": "GE",
    "iso3": "GEO",
    "admin_name": "Samegrelo-Zemo Svaneti",
    "capital": "minor",
    "population": 21596,
    "id": 1268594062
  },
  {
    "city": "San Jacinto",
    "city_ascii": "San Jacinto",
    "lat": 9.8311,
    "lng": -75.1219,
    "country": "Colombia",
    "iso2": "CO",
    "iso3": "COL",
    "admin_name": "Bolívar",
    "capital": "minor",
    "population": 21644,
    "id": 1170354916
  },
  {
    "city": "Gryfino",
    "city_ascii": "Gryfino",
    "lat": 53.2524,
    "lng": 14.4883,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Zachodniopomorskie",
    "capital": "minor",
    "population": 21555,
    "id": 1616595491
  },
  {
    "city": "Bad Rappenau",
    "city_ascii": "Bad Rappenau",
    "lat": 49.2389,
    "lng": 9.1028,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 21398,
    "id": 1276726877
  },
  {
    "city": "Wülfrath",
    "city_ascii": "Wulfrath",
    "lat": 51.2833,
    "lng": 7.0333,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 21035,
    "id": 1276377418
  },
  {
    "city": "Eastmont",
    "city_ascii": "Eastmont",
    "lat": 47.8968,
    "lng": -122.1817,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 21686,
    "id": 1840139116
  },
  {
    "city": "Jacksonville",
    "city_ascii": "Jacksonville",
    "lat": 39.7292,
    "lng": -90.2318,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 21685,
    "id": 1840008438
  },
  {
    "city": "Yenice",
    "city_ascii": "Yenice",
    "lat": 41.2,
    "lng": 32.3333,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Karabük",
    "capital": "minor",
    "population": 21625,
    "id": 1792456391
  },
  {
    "city": "Entroncamento",
    "city_ascii": "Entroncamento",
    "lat": 39.4667,
    "lng": -8.4667,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Santarém",
    "capital": "minor",
    "population": 20206,
    "id": 1620318542
  },
  {
    "city": "Kant",
    "city_ascii": "Kant",
    "lat": 42.8833,
    "lng": 74.85,
    "country": "Kyrgyzstan",
    "iso2": "KG",
    "iso3": "KGZ",
    "admin_name": "Chüy",
    "capital": "minor",
    "population": 21589,
    "id": 1417254104
  },
  {
    "city": "Furano",
    "city_ascii": "Furano",
    "lat": 43.3419,
    "lng": 142.3833,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hokkaidō",
    "capital": "",
    "population": 21630,
    "id": 1392470642
  },
  {
    "city": "Le Ray",
    "city_ascii": "Le Ray",
    "lat": 44.0771,
    "lng": -75.7975,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 21665,
    "id": 1840087600
  },
  {
    "city": "Aichach",
    "city_ascii": "Aichach",
    "lat": 48.45,
    "lng": 11.1333,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "minor",
    "population": 21434,
    "id": 1276090074
  },
  {
    "city": "Kirkintilloch",
    "city_ascii": "Kirkintilloch",
    "lat": 55.9357,
    "lng": -4.1547,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "East Dunbartonshire",
    "capital": "",
    "population": 19700,
    "id": 1826571447
  },
  {
    "city": "San Nicolás",
    "city_ascii": "San Nicolas",
    "lat": 22.7819,
    "lng": -81.9069,
    "country": "Cuba",
    "iso2": "CU",
    "iso3": "CUB",
    "admin_name": "Mayabeque",
    "capital": "minor",
    "population": 21563,
    "id": 1192849250
  },
  {
    "city": "Lincoln",
    "city_ascii": "Lincoln",
    "lat": 41.9171,
    "lng": -71.4505,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Rhode Island",
    "capital": "",
    "population": 21644,
    "id": 1840106233
  },
  {
    "city": "Masterton",
    "city_ascii": "Masterton",
    "lat": -40.9667,
    "lng": 175.65,
    "country": "New Zealand",
    "iso2": "NZ",
    "iso3": "NZL",
    "admin_name": "Wellington",
    "capital": "",
    "population": 20100,
    "id": 1554641049
  },
  {
    "city": "Les Pennes-Mirabeau",
    "city_ascii": "Les Pennes-Mirabeau",
    "lat": 43.4106,
    "lng": 5.3103,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Provence-Alpes-Côte d’Azur",
    "capital": "",
    "population": 21046,
    "id": 1250976076
  },
  {
    "city": "Smithfield",
    "city_ascii": "Smithfield",
    "lat": 41.9015,
    "lng": -71.5308,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Rhode Island",
    "capital": "",
    "population": 21630,
    "id": 1840106200
  },
  {
    "city": "Darien",
    "city_ascii": "Darien",
    "lat": 41.7447,
    "lng": -87.9823,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 21628,
    "id": 1840007045
  },
  {
    "city": "Hoogstraten",
    "city_ascii": "Hoogstraten",
    "lat": 51.4008,
    "lng": 4.7611,
    "country": "Belgium",
    "iso2": "BE",
    "iso3": "BEL",
    "admin_name": "Flanders",
    "capital": "",
    "population": 21424,
    "id": 1056021828
  },
  {
    "city": "Perrysburg",
    "city_ascii": "Perrysburg",
    "lat": 41.5377,
    "lng": -83.6413,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 21626,
    "id": 1840000623
  },
  {
    "city": "Coswig",
    "city_ascii": "Coswig",
    "lat": 51.1333,
    "lng": 13.5833,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 20817,
    "id": 1276791212
  },
  {
    "city": "Sondershausen",
    "city_ascii": "Sondershausen",
    "lat": 51.3667,
    "lng": 10.8667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Thuringia",
    "capital": "minor",
    "population": 21513,
    "id": 1276468586
  },
  {
    "city": "Scugog",
    "city_ascii": "Scugog",
    "lat": 44.09,
    "lng": -78.936,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 21617,
    "id": 1124000741
  },
  {
    "city": "Ozoir-la-Ferrière",
    "city_ascii": "Ozoir-la-Ferriere",
    "lat": 48.778,
    "lng": 2.68,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Île-de-France",
    "capital": "",
    "population": 20331,
    "id": 1250806458
  },
  {
    "city": "Lewistown",
    "city_ascii": "Lewistown",
    "lat": 40.5964,
    "lng": -77.573,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 21614,
    "id": 1840003592
  },
  {
    "city": "Richmond",
    "city_ascii": "Richmond",
    "lat": 51.456,
    "lng": -0.301,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Richmond upon Thames",
    "capital": "",
    "population": 21469,
    "id": 1826875300
  },
  {
    "city": "Gardanne",
    "city_ascii": "Gardanne",
    "lat": 43.4553,
    "lng": 5.476,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Provence-Alpes-Côte d’Azur",
    "capital": "",
    "population": 20794,
    "id": 1250144210
  },
  {
    "city": "Camp Springs",
    "city_ascii": "Camp Springs",
    "lat": 38.8052,
    "lng": -76.9198,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 21602,
    "id": 1840005947
  },
  {
    "city": "Nang Rong",
    "city_ascii": "Nang Rong",
    "lat": 14.6363,
    "lng": 102.7746,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Buriram",
    "capital": "minor",
    "population": 21493,
    "id": 1764430858
  },
  {
    "city": "Eeklo",
    "city_ascii": "Eeklo",
    "lat": 51.1858,
    "lng": 3.5639,
    "country": "Belgium",
    "iso2": "BE",
    "iso3": "BEL",
    "admin_name": "Flanders",
    "capital": "minor",
    "population": 20890,
    "id": 1056979321
  },
  {
    "city": "Gujan-Mestras",
    "city_ascii": "Gujan-Mestras",
    "lat": 44.6364,
    "lng": -1.0667,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Nouvelle-Aquitaine",
    "capital": "",
    "population": 21152,
    "id": 1250633398
  },
  {
    "city": "La Porte",
    "city_ascii": "La Porte",
    "lat": 41.6077,
    "lng": -86.7136,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 21569,
    "id": 1840030063
  },
  {
    "city": "Palm Valley",
    "city_ascii": "Palm Valley",
    "lat": 30.2011,
    "lng": -81.3949,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 21562,
    "id": 1840013971
  },
  {
    "city": "Geseke",
    "city_ascii": "Geseke",
    "lat": 51.6497,
    "lng": 8.5167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 21343,
    "id": 1276815446
  },
  {
    "city": "Clarksville",
    "city_ascii": "Clarksville",
    "lat": 38.3221,
    "lng": -85.7673,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 21558,
    "id": 1840009665
  },
  {
    "city": "New Castle",
    "city_ascii": "New Castle",
    "lat": 39.919,
    "lng": -85.3697,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 21555,
    "id": 1840008396
  },
  {
    "city": "Jämsä",
    "city_ascii": "Jamsa",
    "lat": 61.8639,
    "lng": 25.1903,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Keski-Suomi",
    "capital": "minor",
    "population": 21542,
    "id": 1246902464
  },
  {
    "city": "Allauch",
    "city_ascii": "Allauch",
    "lat": 43.3369,
    "lng": 5.4828,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Provence-Alpes-Côte d’Azur",
    "capital": "",
    "population": 21187,
    "id": 1250339228
  },
  {
    "city": "Murree",
    "city_ascii": "Murree",
    "lat": 33.9042,
    "lng": 73.3903,
    "country": "Pakistan",
    "iso2": "PK",
    "iso3": "PAK",
    "admin_name": "Punjab",
    "capital": "",
    "population": 20869,
    "id": 1586573416
  },
  {
    "city": "Kingsville",
    "city_ascii": "Kingsville",
    "lat": 42.1,
    "lng": -82.7167,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 21552,
    "id": 1124616034
  },
  {
    "city": "Shisui",
    "city_ascii": "Shisui",
    "lat": 35.725,
    "lng": 140.2694,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Chiba",
    "capital": "",
    "population": 20470,
    "id": 1392687208
  },
  {
    "city": "Leek",
    "city_ascii": "Leek",
    "lat": 53.108,
    "lng": -2.0234,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Staffordshire",
    "capital": "",
    "population": 20768,
    "id": 1826058750
  },
  {
    "city": "Kara-Suu",
    "city_ascii": "Kara-Suu",
    "lat": 40.7,
    "lng": 72.8833,
    "country": "Kyrgyzstan",
    "iso2": "KG",
    "iso3": "KGZ",
    "admin_name": "Osh",
    "capital": "minor",
    "population": 20862,
    "id": 1417321617
  },
  {
    "city": "Baie-Comeau",
    "city_ascii": "Baie-Comeau",
    "lat": 49.2167,
    "lng": -68.15,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 21536,
    "id": 1124859576
  },
  {
    "city": "Deán Funes",
    "city_ascii": "Dean Funes",
    "lat": -30.4333,
    "lng": -64.35,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Córdoba",
    "capital": "minor",
    "population": 21518,
    "id": 1032778928
  },
  {
    "city": "Mill Creek East",
    "city_ascii": "Mill Creek East",
    "lat": 47.836,
    "lng": -122.1877,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 21523,
    "id": 1840041996
  },
  {
    "city": "Montville",
    "city_ascii": "Montville",
    "lat": 40.9135,
    "lng": -74.3594,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 21516,
    "id": 1840081737
  },
  {
    "city": "Vecsés",
    "city_ascii": "Vecses",
    "lat": 47.4108,
    "lng": 19.2722,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Pest",
    "capital": "minor",
    "population": 20935,
    "id": 1348714695
  },
  {
    "city": "Livadeiá",
    "city_ascii": "Livadeia",
    "lat": 38.4333,
    "lng": 22.8667,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Stereá Elláda",
    "capital": "minor",
    "population": 21379,
    "id": 1300871430
  },
  {
    "city": "Biddeford",
    "city_ascii": "Biddeford",
    "lat": 43.4674,
    "lng": -70.4512,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maine",
    "capital": "",
    "population": 21504,
    "id": 1840000342
  },
  {
    "city": "Wang Nam Yen",
    "city_ascii": "Wang Nam Yen",
    "lat": 13.5004,
    "lng": 102.1806,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Sa Kaeo",
    "capital": "minor",
    "population": 21301,
    "id": 1764123627
  },
  {
    "city": "St. Andrews",
    "city_ascii": "St. Andrews",
    "lat": 34.051,
    "lng": -81.1057,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 21494,
    "id": 1840135211
  },
  {
    "city": "Santa Cruz Cabrália",
    "city_ascii": "Santa Cruz Cabralia",
    "lat": -16.28,
    "lng": -39.03,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Bahia",
    "capital": "",
    "population": 19545,
    "id": 1076118882
  },
  {
    "city": "Juli",
    "city_ascii": "Juli",
    "lat": -16.2125,
    "lng": -69.4603,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "Puno",
    "capital": "",
    "population": 21462,
    "id": 1604693505
  },
  {
    "city": "Ryazhsk",
    "city_ascii": "Ryazhsk",
    "lat": 53.7167,
    "lng": 40.0667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Ryazanskaya Oblast’",
    "capital": "",
    "population": 21457,
    "id": 1643410159
  },
  {
    "city": "Sarreguemines",
    "city_ascii": "Sarreguemines",
    "lat": 49.11,
    "lng": 7.07,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Grand Est",
    "capital": "minor",
    "population": 20783,
    "id": 1250256927
  },
  {
    "city": "Villa Park",
    "city_ascii": "Villa Park",
    "lat": 41.8864,
    "lng": -87.9779,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 21483,
    "id": 1840011412
  },
  {
    "city": "Holiday",
    "city_ascii": "Holiday",
    "lat": 28.1864,
    "lng": -82.7429,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 21481,
    "id": 1840014114
  },
  {
    "city": "Prudnik",
    "city_ascii": "Prudnik",
    "lat": 50.3197,
    "lng": 17.5792,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Opolskie",
    "capital": "minor",
    "population": 21237,
    "id": 1616117977
  },
  {
    "city": "Schramberg",
    "city_ascii": "Schramberg",
    "lat": 48.2269,
    "lng": 8.3842,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 21189,
    "id": 1276492911
  },
  {
    "city": "Khotkovo",
    "city_ascii": "Khotkovo",
    "lat": 56.25,
    "lng": 37.9833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Moskovskaya Oblast’",
    "capital": "",
    "population": 21349,
    "id": 1643642751
  },
  {
    "city": "Lutz",
    "city_ascii": "Lutz",
    "lat": 28.1396,
    "lng": -82.4467,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 21456,
    "id": 1840014156
  },
  {
    "city": "Palatka",
    "city_ascii": "Palatka",
    "lat": 60.1,
    "lng": 150.9,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Magadanskaya Oblast’",
    "capital": "",
    "population": 21452,
    "id": 1643837733
  },
  {
    "city": "Antsohihy",
    "city_ascii": "Antsohihy",
    "lat": -14.8661,
    "lng": 47.9834,
    "country": "Madagascar",
    "iso2": "MG",
    "iso3": "MDG",
    "admin_name": "Mahajanga",
    "capital": "minor",
    "population": 21290,
    "id": 1450240411
  },
  {
    "city": "Tarko-Sale",
    "city_ascii": "Tarko-Sale",
    "lat": 64.9147,
    "lng": 77.7728,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Yamalo-Nenetskiy Avtonomnyy Okrug",
    "capital": "",
    "population": 21448,
    "id": 1643113714
  },
  {
    "city": "Traunreut",
    "city_ascii": "Traunreut",
    "lat": 47.9667,
    "lng": 12.5833,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 20977,
    "id": 1276958674
  },
  {
    "city": "Mukilteo",
    "city_ascii": "Mukilteo",
    "lat": 47.9096,
    "lng": -122.3035,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 21441,
    "id": 1840019793
  },
  {
    "city": "Cugir",
    "city_ascii": "Cugir",
    "lat": 45.8436,
    "lng": 23.3636,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Alba",
    "capital": "",
    "population": 21376,
    "id": 1642368746
  },
  {
    "city": "Atoka",
    "city_ascii": "Atoka",
    "lat": 35.4239,
    "lng": -89.7861,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 21428,
    "id": 1840015416
  },
  {
    "city": "Prichard",
    "city_ascii": "Prichard",
    "lat": 30.7735,
    "lng": -88.1301,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 21428,
    "id": 1840014990
  },
  {
    "city": "Orós",
    "city_ascii": "Oros",
    "lat": -6.2439,
    "lng": -38.9139,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Ceará",
    "capital": "",
    "population": 21389,
    "id": 1076430062
  },
  {
    "city": "Waghäusel",
    "city_ascii": "Waghausel",
    "lat": 49.25,
    "lng": 8.5169,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 20935,
    "id": 1276002094
  },
  {
    "city": "Soltau",
    "city_ascii": "Soltau",
    "lat": 52.9833,
    "lng": 9.8333,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 21317,
    "id": 1276678434
  },
  {
    "city": "Hays",
    "city_ascii": "Hays",
    "lat": 38.8815,
    "lng": -99.3218,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kansas",
    "capital": "",
    "population": 21421,
    "id": 1840001628
  },
  {
    "city": "Somers",
    "city_ascii": "Somers",
    "lat": 41.3058,
    "lng": -73.725,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 21408,
    "id": 1840058503
  },
  {
    "city": "Groß-Umstadt",
    "city_ascii": "Gross-Umstadt",
    "lat": 49.8683,
    "lng": 8.929,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 21162,
    "id": 1276232744
  },
  {
    "city": "East Pennsboro",
    "city_ascii": "East Pennsboro",
    "lat": 40.2886,
    "lng": -76.9394,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 21398,
    "id": 1840143336
  },
  {
    "city": "Valkeakoski",
    "city_ascii": "Valkeakoski",
    "lat": 61.2667,
    "lng": 24.0306,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Pirkanmaa",
    "capital": "minor",
    "population": 21332,
    "id": 1246028934
  },
  {
    "city": "Birmingham",
    "city_ascii": "Birmingham",
    "lat": 42.5447,
    "lng": -83.2166,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 21389,
    "id": 1840002438
  },
  {
    "city": "Paradise",
    "city_ascii": "Paradise",
    "lat": 47.5333,
    "lng": -52.8667,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Newfoundland and Labrador",
    "capital": "",
    "population": 21389,
    "id": 1124001159
  },
  {
    "city": "Pompéia",
    "city_ascii": "Pompeia",
    "lat": -22.1089,
    "lng": -50.1719,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 21375,
    "id": 1076663020
  },
  {
    "city": "Florida Ridge",
    "city_ascii": "Florida Ridge",
    "lat": 27.5805,
    "lng": -80.3848,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 21371,
    "id": 1840029035
  },
  {
    "city": "Easley",
    "city_ascii": "Easley",
    "lat": 34.819,
    "lng": -82.5828,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 21364,
    "id": 1840013526
  },
  {
    "city": "Bear",
    "city_ascii": "Bear",
    "lat": 39.6188,
    "lng": -75.6804,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Delaware",
    "capital": "",
    "population": 21362,
    "id": 1840005568
  },
  {
    "city": "Kaltan",
    "city_ascii": "Kaltan",
    "lat": 53.5167,
    "lng": 87.2667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kemerovskaya Oblast’",
    "capital": "",
    "population": 20841,
    "id": 1643027336
  },
  {
    "city": "Lyskovo",
    "city_ascii": "Lyskovo",
    "lat": 56.0128,
    "lng": 45.0253,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Nizhegorodskaya Oblast’",
    "capital": "",
    "population": 21321,
    "id": 1643597130
  },
  {
    "city": "Varadero",
    "city_ascii": "Varadero",
    "lat": 23.1394,
    "lng": -81.2861,
    "country": "Cuba",
    "iso2": "CU",
    "iso3": "CUB",
    "admin_name": "Matanzas",
    "capital": "",
    "population": 21170,
    "id": 1192203270
  },
  {
    "city": "Ludlow",
    "city_ascii": "Ludlow",
    "lat": 42.1921,
    "lng": -72.4581,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 21336,
    "id": 1840053449
  },
  {
    "city": "Marshall",
    "city_ascii": "Marshall",
    "lat": 32.537,
    "lng": -94.3515,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 21331,
    "id": 1840020739
  },
  {
    "city": "Asni",
    "city_ascii": "Asni",
    "lat": 31.25,
    "lng": -7.9792,
    "country": "Morocco",
    "iso2": "MA",
    "iso3": "MAR",
    "admin_name": "Marrakech-Safi",
    "capital": "",
    "population": 21244,
    "id": 1504093117
  },
  {
    "city": "Carei",
    "city_ascii": "Carei",
    "lat": 47.69,
    "lng": 22.47,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Satu Mare",
    "capital": "",
    "population": 21112,
    "id": 1642934242
  },
  {
    "city": "Owosso",
    "city_ascii": "Owosso",
    "lat": 42.9955,
    "lng": -84.176,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 21315,
    "id": 1840003068
  },
  {
    "city": "DeBary",
    "city_ascii": "DeBary",
    "lat": 28.8815,
    "lng": -81.324,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 21305,
    "id": 1840015070
  },
  {
    "city": "Garforth",
    "city_ascii": "Garforth",
    "lat": 53.792,
    "lng": -1.388,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Leeds",
    "capital": "",
    "population": 19811,
    "id": 1826216468
  },
  {
    "city": "Eustis",
    "city_ascii": "Eustis",
    "lat": 28.856,
    "lng": -81.6781,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 21303,
    "id": 1840014054
  },
  {
    "city": "Salmon Creek",
    "city_ascii": "Salmon Creek",
    "lat": 45.7099,
    "lng": -122.6632,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 21299,
    "id": 1840018520
  },
  {
    "city": "South Euclid",
    "city_ascii": "South Euclid",
    "lat": 41.5239,
    "lng": -81.5245,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 21297,
    "id": 1840003414
  },
  {
    "city": "Ādēt",
    "city_ascii": "Adet",
    "lat": 11.2667,
    "lng": 37.4833,
    "country": "Ethiopia",
    "iso2": "ET",
    "iso3": "ETH",
    "admin_name": "Āmara",
    "capital": "",
    "population": 21117,
    "id": 1231929103
  },
  {
    "city": "South Holland",
    "city_ascii": "South Holland",
    "lat": 41.5977,
    "lng": -87.6022,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 21296,
    "id": 1840011324
  },
  {
    "city": "Pushchino",
    "city_ascii": "Pushchino",
    "lat": 54.8333,
    "lng": 37.6167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Moskovskaya Oblast’",
    "capital": "",
    "population": 21281,
    "id": 1643679889
  },
  {
    "city": "Okahandja",
    "city_ascii": "Okahandja",
    "lat": -21.9796,
    "lng": 16.91,
    "country": "Namibia",
    "iso2": "NA",
    "iso3": "NAM",
    "admin_name": "Otjozondjupa",
    "capital": "",
    "population": 20879,
    "id": 1516123978
  },
  {
    "city": "Bülach",
    "city_ascii": "Bulach",
    "lat": 47.5189,
    "lng": 8.5422,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Zürich",
    "capital": "",
    "population": 20046,
    "id": 1756393445
  },
  {
    "city": "Nerekhta",
    "city_ascii": "Nerekhta",
    "lat": 57.45,
    "lng": 40.5833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kostromskaya Oblast’",
    "capital": "",
    "population": 21262,
    "id": 1643170330
  },
  {
    "city": "Rancho San Diego",
    "city_ascii": "Rancho San Diego",
    "lat": 32.7624,
    "lng": -116.9197,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 21289,
    "id": 1840019347
  },
  {
    "city": "Ashland",
    "city_ascii": "Ashland",
    "lat": 42.1905,
    "lng": -122.6992,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 21281,
    "id": 1840018674
  },
  {
    "city": "Sapulpa",
    "city_ascii": "Sapulpa",
    "lat": 36.0089,
    "lng": -96.1006,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oklahoma",
    "capital": "",
    "population": 21278,
    "id": 1840021691
  },
  {
    "city": "Kutná Hora",
    "city_ascii": "Kutna Hora",
    "lat": 49.9484,
    "lng": 15.2682,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Středočeský Kraj",
    "capital": "",
    "population": 20653,
    "id": 1203692740
  },
  {
    "city": "Manuel Tames",
    "city_ascii": "Manuel Tames",
    "lat": 20.1803,
    "lng": -75.0514,
    "country": "Cuba",
    "iso2": "CU",
    "iso3": "CUB",
    "admin_name": "Guantánamo",
    "capital": "",
    "population": 21237,
    "id": 1192473479
  },
  {
    "city": "Žďár nad Sázavou",
    "city_ascii": "Zd'ar nad Sazavou",
    "lat": 49.5627,
    "lng": 15.9393,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Vysočina",
    "capital": "",
    "population": 20717,
    "id": 1203204559
  },
  {
    "city": "Alfena",
    "city_ascii": "Alfena",
    "lat": 41.2395,
    "lng": -8.5244,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Porto",
    "capital": "",
    "population": 20000,
    "id": 1620416709
  },
  {
    "city": "Palmer",
    "city_ascii": "Palmer",
    "lat": 40.7007,
    "lng": -75.2628,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 21272,
    "id": 1840149341
  },
  {
    "city": "Duarte",
    "city_ascii": "Duarte",
    "lat": 34.161,
    "lng": -117.9504,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 21271,
    "id": 1840019246
  },
  {
    "city": "Rio Maior",
    "city_ascii": "Rio Maior",
    "lat": 39.3333,
    "lng": -8.9333,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Santarém",
    "capital": "minor",
    "population": 21192,
    "id": 1620036651
  },
  {
    "city": "Bellview",
    "city_ascii": "Bellview",
    "lat": 30.462,
    "lng": -87.312,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 21269,
    "id": 1840013912
  },
  {
    "city": "Ino",
    "city_ascii": "Ino",
    "lat": 33.5486,
    "lng": 133.4278,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Kōchi",
    "capital": "",
    "population": 21223,
    "id": 1392013071
  },
  {
    "city": "Marietta",
    "city_ascii": "Marietta",
    "lat": 39.4241,
    "lng": -81.4465,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 21267,
    "id": 1840001545
  },
  {
    "city": "Lucélia",
    "city_ascii": "Lucelia",
    "lat": -21.7203,
    "lng": -51.0189,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 21196,
    "id": 1076675967
  },
  {
    "city": "Great Linford",
    "city_ascii": "Great Linford",
    "lat": 52.068,
    "lng": -0.7637,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Milton Keynes",
    "capital": "",
    "population": 19350,
    "id": 1826297411
  },
  {
    "city": "Griffith",
    "city_ascii": "Griffith",
    "lat": -34.29,
    "lng": 146.04,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 20251,
    "id": 1036563313
  },
  {
    "city": "Brezno",
    "city_ascii": "Brezno",
    "lat": 48.8036,
    "lng": 19.6381,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Banskobystrický",
    "capital": "minor",
    "population": 21082,
    "id": 1703148175
  },
  {
    "city": "Battle Ground",
    "city_ascii": "Battle Ground",
    "lat": 45.7765,
    "lng": -122.5413,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 21252,
    "id": 1840018523
  },
  {
    "city": "Ardmore",
    "city_ascii": "Ardmore",
    "lat": 34.1944,
    "lng": -97.1253,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oklahoma",
    "capital": "",
    "population": 21245,
    "id": 1840019258
  },
  {
    "city": "Bibai",
    "city_ascii": "Bibai",
    "lat": 43.3329,
    "lng": 141.8538,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hokkaidō",
    "capital": "",
    "population": 21168,
    "id": 1392376631
  },
  {
    "city": "West Pensacola",
    "city_ascii": "West Pensacola",
    "lat": 30.4263,
    "lng": -87.2679,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 21243,
    "id": 1840013915
  },
  {
    "city": "Amsterdam",
    "city_ascii": "Amsterdam",
    "lat": 42.942,
    "lng": -74.1907,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 21241,
    "id": 1840000391
  },
  {
    "city": "Sandhurst",
    "city_ascii": "Sandhurst",
    "lat": 51.349,
    "lng": -0.8,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hampshire",
    "capital": "",
    "population": 20803,
    "id": 1826474175
  },
  {
    "city": "Oxford",
    "city_ascii": "Oxford",
    "lat": 33.5967,
    "lng": -85.8687,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 21225,
    "id": 1840014780
  },
  {
    "city": "Essex",
    "city_ascii": "Essex",
    "lat": 44.5195,
    "lng": -73.0654,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Vermont",
    "capital": "",
    "population": 21225,
    "id": 1840115363
  },
  {
    "city": "Reichenbach/Vogtland",
    "city_ascii": "Reichenbach/Vogtland",
    "lat": 50.6167,
    "lng": 12.3,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 20625,
    "id": 1276903490
  },
  {
    "city": "Vyškov",
    "city_ascii": "Vyskov",
    "lat": 49.2775,
    "lng": 16.999,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Jihomoravský Kraj",
    "capital": "",
    "population": 20807,
    "id": 1203012353
  },
  {
    "city": "Shimogamo",
    "city_ascii": "Shimogamo",
    "lat": 34.6794,
    "lng": 138.9453,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Shizuoka",
    "capital": "",
    "population": 21015,
    "id": 1392351063
  },
  {
    "city": "West Deptford",
    "city_ascii": "West Deptford",
    "lat": 39.8431,
    "lng": -75.1851,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 21214,
    "id": 1840081678
  },
  {
    "city": "Sadao",
    "city_ascii": "Sadao",
    "lat": 6.6386,
    "lng": 100.424,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Songkhla",
    "capital": "minor",
    "population": 21086,
    "id": 1764417199
  },
  {
    "city": "Park Forest",
    "city_ascii": "Park Forest",
    "lat": 41.4817,
    "lng": -87.6868,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 21210,
    "id": 1840011313
  },
  {
    "city": "Evans",
    "city_ascii": "Evans",
    "lat": 40.366,
    "lng": -104.7389,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 21205,
    "id": 1840020147
  },
  {
    "city": "Alexandria",
    "city_ascii": "Alexandria",
    "lat": 45.8777,
    "lng": -95.3766,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 21204,
    "id": 1840006680
  },
  {
    "city": "Schenefeld",
    "city_ascii": "Schenefeld",
    "lat": 53.6028,
    "lng": 9.8233,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Schleswig-Holstein",
    "capital": "",
    "population": 19271,
    "id": 1276791655
  },
  {
    "city": "Fort Atkinson",
    "city_ascii": "Fort Atkinson",
    "lat": 42.9253,
    "lng": -88.8441,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 21196,
    "id": 1840003004
  },
  {
    "city": "Varkaus",
    "city_ascii": "Varkaus",
    "lat": 62.3167,
    "lng": 27.8833,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Pohjois-Savo",
    "capital": "minor",
    "population": 21155,
    "id": 1246449573
  },
  {
    "city": "Jefferson",
    "city_ascii": "Jefferson",
    "lat": 41.0003,
    "lng": -74.5531,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 21191,
    "id": 1840081740
  },
  {
    "city": "Bastos",
    "city_ascii": "Bastos",
    "lat": -21.9167,
    "lng": -50.7333,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 21067,
    "id": 1076067823
  },
  {
    "city": "Carrboro",
    "city_ascii": "Carrboro",
    "lat": 35.926,
    "lng": -79.0878,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 21190,
    "id": 1840015334
  },
  {
    "city": "Seria",
    "city_ascii": "Seria",
    "lat": 4.6142,
    "lng": 114.3303,
    "country": "Brunei",
    "iso2": "BN",
    "iso3": "BRN",
    "admin_name": "Belait",
    "capital": "",
    "population": 21082,
    "id": 1096351415
  },
  {
    "city": "Bardstown",
    "city_ascii": "Bardstown",
    "lat": 37.8174,
    "lng": -85.4548,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kentucky",
    "capital": "",
    "population": 21184,
    "id": 1840013219
  },
  {
    "city": "East Ridge",
    "city_ascii": "East Ridge",
    "lat": 34.9973,
    "lng": -85.2284,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 21182,
    "id": 1840013452
  },
  {
    "city": "Wipperfürth",
    "city_ascii": "Wipperfurth",
    "lat": 51.1167,
    "lng": 7.4,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 21003,
    "id": 1276735297
  },
  {
    "city": "Susaki",
    "city_ascii": "Susaki",
    "lat": 33.4008,
    "lng": 133.2831,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Kōchi",
    "capital": "",
    "population": 21025,
    "id": 1392572991
  },
  {
    "city": "Bohumín",
    "city_ascii": "Bohumin",
    "lat": 49.9041,
    "lng": 18.3576,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Moravskoslezský Kraj",
    "capital": "",
    "population": 20518,
    "id": 1203275330
  },
  {
    "city": "Elko",
    "city_ascii": "Elko",
    "lat": 40.8387,
    "lng": -115.7674,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Nevada",
    "capital": "",
    "population": 21179,
    "id": 1840020120
  },
  {
    "city": "Uxbridge",
    "city_ascii": "Uxbridge",
    "lat": 44.1167,
    "lng": -79.1333,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 21176,
    "id": 1124829638
  },
  {
    "city": "Covington",
    "city_ascii": "Covington",
    "lat": 47.3668,
    "lng": -122.1044,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 21175,
    "id": 1840018425
  },
  {
    "city": "Florencia",
    "city_ascii": "Florencia",
    "lat": 22.1475,
    "lng": -78.9669,
    "country": "Cuba",
    "iso2": "CU",
    "iso3": "CUB",
    "admin_name": "Ciego de Ávila",
    "capital": "minor",
    "population": 21150,
    "id": 1192281767
  },
  {
    "city": "Bologoye",
    "city_ascii": "Bologoye",
    "lat": 57.8787,
    "lng": 34.0789,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Tverskaya Oblast’",
    "capital": "minor",
    "population": 21158,
    "id": 1643381656
  },
  {
    "city": "Gaffney",
    "city_ascii": "Gaffney",
    "lat": 35.0742,
    "lng": -81.6558,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 21169,
    "id": 1840013515
  },
  {
    "city": "Praia da Vitória",
    "city_ascii": "Praia da Vitoria",
    "lat": 38.7333,
    "lng": -27.0667,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Azores",
    "capital": "",
    "population": 21035,
    "id": 1620566017
  },
  {
    "city": "Rees",
    "city_ascii": "Rees",
    "lat": 51.7667,
    "lng": 6.4,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 20972,
    "id": 1276451097
  },
  {
    "city": "Boralday",
    "city_ascii": "Boralday",
    "lat": 43.3341,
    "lng": 76.8288,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Almaty",
    "capital": "",
    "population": 20996,
    "id": 1398737190
  },
  {
    "city": "Komatipoort",
    "city_ascii": "Komatipoort",
    "lat": -25.4333,
    "lng": 31.95,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "Mpumalanga",
    "capital": "",
    "population": 20508,
    "id": 1710137353
  },
  {
    "city": "Bonney Lake",
    "city_ascii": "Bonney Lake",
    "lat": 47.1791,
    "lng": -122.17,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 21148,
    "id": 1840018454
  },
  {
    "city": "Sidney",
    "city_ascii": "Sidney",
    "lat": 40.2891,
    "lng": -84.1667,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 21148,
    "id": 1840009429
  },
  {
    "city": "Ecclesall",
    "city_ascii": "Ecclesall",
    "lat": 53.362,
    "lng": -1.498,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Sheffield",
    "capital": "",
    "population": 19211,
    "id": 1826986185
  },
  {
    "city": "Fröndenberg",
    "city_ascii": "Frondenberg",
    "lat": 51.4719,
    "lng": 7.7658,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 20766,
    "id": 1276071190
  },
  {
    "city": "Harstad",
    "city_ascii": "Harstad",
    "lat": 68.7989,
    "lng": 16.5314,
    "country": "Norway",
    "iso2": "NO",
    "iso3": "NOR",
    "admin_name": "Troms",
    "capital": "minor",
    "population": 21070,
    "id": 1578844884
  },
  {
    "city": "El Calafate",
    "city_ascii": "El Calafate",
    "lat": -50.3395,
    "lng": -72.2649,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Santa Cruz",
    "capital": "minor",
    "population": 21132,
    "id": 1032210475
  },
  {
    "city": "Dickinson",
    "city_ascii": "Dickinson",
    "lat": 29.4546,
    "lng": -95.0583,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 21129,
    "id": 1840019667
  },
  {
    "city": "Bad Schwartau",
    "city_ascii": "Bad Schwartau",
    "lat": 53.9194,
    "lng": 10.6975,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Schleswig-Holstein",
    "capital": "",
    "population": 20036,
    "id": 1276687859
  },
  {
    "city": "Faversham",
    "city_ascii": "Faversham",
    "lat": 51.3177,
    "lng": 0.8928,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Kent",
    "capital": "",
    "population": 19316,
    "id": 1826000093
  },
  {
    "city": "Vikhorevka",
    "city_ascii": "Vikhorevka",
    "lat": 56.1167,
    "lng": 101.1667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Irkutskaya Oblast’",
    "capital": "",
    "population": 21118,
    "id": 1643023865
  },
  {
    "city": "Welench’ītī",
    "city_ascii": "Welench'iti",
    "lat": 8.6667,
    "lng": 39.4333,
    "country": "Ethiopia",
    "iso2": "ET",
    "iso3": "ETH",
    "admin_name": "Oromīya",
    "capital": "",
    "population": 20984,
    "id": 1231349696
  },
  {
    "city": "Bruchköbel",
    "city_ascii": "Bruchkobel",
    "lat": 50.1833,
    "lng": 8.9167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 20427,
    "id": 1276199010
  },
  {
    "city": "Cournon-d’Auvergne",
    "city_ascii": "Cournon-d'Auvergne",
    "lat": 45.7422,
    "lng": 3.1972,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Auvergne-Rhône-Alpes",
    "capital": "",
    "population": 20043,
    "id": 1250524743
  },
  {
    "city": "Werdau",
    "city_ascii": "Werdau",
    "lat": 50.7333,
    "lng": 12.3833,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 20793,
    "id": 1276717054
  },
  {
    "city": "Arnold",
    "city_ascii": "Arnold",
    "lat": 38.4296,
    "lng": -90.3733,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 21091,
    "id": 1840007506
  },
  {
    "city": "Essa",
    "city_ascii": "Essa",
    "lat": 44.25,
    "lng": -79.7833,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 21083,
    "id": 1124001569
  },
  {
    "city": "Santo Anastácio",
    "city_ascii": "Santo Anastacio",
    "lat": -21.9769,
    "lng": -51.6519,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 21044,
    "id": 1076929183
  },
  {
    "city": "Günzburg",
    "city_ascii": "Gunzburg",
    "lat": 48.4525,
    "lng": 10.2711,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "minor",
    "population": 20707,
    "id": 1276986738
  },
  {
    "city": "Bahía de Caráquez",
    "city_ascii": "Bahia de Caraquez",
    "lat": -0.6,
    "lng": -80.425,
    "country": "Ecuador",
    "iso2": "EC",
    "iso3": "ECU",
    "admin_name": "Manabí",
    "capital": "",
    "population": 20921,
    "id": 1218764402
  },
  {
    "city": "Sotouboua",
    "city_ascii": "Sotouboua",
    "lat": 8.5667,
    "lng": 0.9833,
    "country": "Togo",
    "iso2": "TG",
    "iso3": "TGO",
    "admin_name": "Centrale",
    "capital": "",
    "population": 21054,
    "id": 1768355631
  },
  {
    "city": "Crowborough",
    "city_ascii": "Crowborough",
    "lat": 51.06,
    "lng": 0.16,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "East Sussex",
    "capital": "",
    "population": 20607,
    "id": 1826550710
  },
  {
    "city": "Toguchin",
    "city_ascii": "Toguchin",
    "lat": 55.2333,
    "lng": 84.3833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Novosibirskaya Oblast’",
    "capital": "",
    "population": 21064,
    "id": 1643095919
  },
  {
    "city": "Ripley",
    "city_ascii": "Ripley",
    "lat": 53.05,
    "lng": -1.407,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Derbyshire",
    "capital": "",
    "population": 20807,
    "id": 1826520155
  },
  {
    "city": "Silverdale",
    "city_ascii": "Silverdale",
    "lat": 47.6663,
    "lng": -122.6828,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 21071,
    "id": 1840018406
  },
  {
    "city": "Hazel Dell",
    "city_ascii": "Hazel Dell",
    "lat": 45.6797,
    "lng": -122.6553,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 21066,
    "id": 1840037662
  },
  {
    "city": "Chascomús",
    "city_ascii": "Chascomus",
    "lat": -35.575,
    "lng": -58.0089,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires",
    "capital": "minor",
    "population": 21054,
    "id": 1032352363
  },
  {
    "city": "Nzeto",
    "city_ascii": "Nzeto",
    "lat": -7.2296,
    "lng": 12.86,
    "country": "Angola",
    "iso2": "AO",
    "iso3": "AGO",
    "admin_name": "Zaire",
    "capital": "",
    "population": 21059,
    "id": 1024025450
  },
  {
    "city": "Elektrougli",
    "city_ascii": "Elektrougli",
    "lat": 55.7167,
    "lng": 38.2167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Moskovskaya Oblast’",
    "capital": "",
    "population": 20823,
    "id": 1643003501
  },
  {
    "city": "Rolla",
    "city_ascii": "Rolla",
    "lat": 37.9458,
    "lng": -91.7608,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 21059,
    "id": 1840009849
  },
  {
    "city": "Sysert",
    "city_ascii": "Sysert",
    "lat": 56.5,
    "lng": 60.8167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sverdlovskaya Oblast’",
    "capital": "",
    "population": 21029,
    "id": 1643162244
  },
  {
    "city": "Johnstown",
    "city_ascii": "Johnstown",
    "lat": 40.3499,
    "lng": -104.9481,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 21048,
    "id": 1840021369
  },
  {
    "city": "Sokal’",
    "city_ascii": "Sokal'",
    "lat": 50.4833,
    "lng": 24.2833,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "L’vivs’ka Oblast’",
    "capital": "minor",
    "population": 20986,
    "id": 1804008451
  },
  {
    "city": "Candiac",
    "city_ascii": "Candiac",
    "lat": 45.38,
    "lng": -73.52,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 21047,
    "id": 1124457982
  },
  {
    "city": "Fernley",
    "city_ascii": "Fernley",
    "lat": 39.5611,
    "lng": -119.1926,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Nevada",
    "capital": "",
    "population": 21045,
    "id": 1840020214
  },
  {
    "city": "Hyde Park",
    "city_ascii": "Hyde Park",
    "lat": 41.8011,
    "lng": -73.906,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 21040,
    "id": 1840058196
  },
  {
    "city": "Voiron",
    "city_ascii": "Voiron",
    "lat": 45.3642,
    "lng": 5.5906,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Auvergne-Rhône-Alpes",
    "capital": "",
    "population": 20108,
    "id": 1250058509
  },
  {
    "city": "Oro-Medonte",
    "city_ascii": "Oro-Medonte",
    "lat": 44.5667,
    "lng": -79.5833,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 21036,
    "id": 1124001350
  },
  {
    "city": "Pleasant Prairie",
    "city_ascii": "Pleasant Prairie",
    "lat": 42.5266,
    "lng": -87.8895,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 21034,
    "id": 1840003163
  },
  {
    "city": "Liubotyn",
    "city_ascii": "Liubotyn",
    "lat": 49.9489,
    "lng": 35.9306,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Kharkivs’ka Oblast’",
    "capital": "",
    "population": 20887,
    "id": 1804620993
  },
  {
    "city": "Shelbyville",
    "city_ascii": "Shelbyville",
    "lat": 39.535,
    "lng": -85.7792,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 21028,
    "id": 1840009561
  },
  {
    "city": "Tepezalá",
    "city_ascii": "Tepezala",
    "lat": 22.2167,
    "lng": -102.1667,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Aguascalientes",
    "capital": "minor",
    "population": 20926,
    "id": 1484547139
  },
  {
    "city": "Dudinka",
    "city_ascii": "Dudinka",
    "lat": 69.4,
    "lng": 86.1833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Krasnoyarskiy Kray",
    "capital": "",
    "population": 21015,
    "id": 1643923236
  },
  {
    "city": "Mühldorf",
    "city_ascii": "Muhldorf",
    "lat": 48.2456,
    "lng": 12.5228,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "minor",
    "population": 20323,
    "id": 1276623949
  },
  {
    "city": "Nurota",
    "city_ascii": "Nurota",
    "lat": 40.565,
    "lng": 65.685,
    "country": "Uzbekistan",
    "iso2": "UZ",
    "iso3": "UZB",
    "admin_name": "Navoiy",
    "capital": "",
    "population": 21001,
    "id": 1860266981
  },
  {
    "city": "Roshal",
    "city_ascii": "Roshal",
    "lat": 55.6667,
    "lng": 39.8833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Moskovskaya Oblast’",
    "capital": "",
    "population": 20418,
    "id": 1643370665
  },
  {
    "city": "Varennes",
    "city_ascii": "Varennes",
    "lat": 45.6833,
    "lng": -73.4333,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 20994,
    "id": 1124232101
  },
  {
    "city": "Milwaukie",
    "city_ascii": "Milwaukie",
    "lat": 45.4447,
    "lng": -122.6221,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 20990,
    "id": 1840019952
  },
  {
    "city": "Challans",
    "city_ascii": "Challans",
    "lat": 46.8467,
    "lng": -1.8781,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Pays de la Loire",
    "capital": "",
    "population": 20662,
    "id": 1250531482
  },
  {
    "city": "Guará",
    "city_ascii": "Guara",
    "lat": -20.4283,
    "lng": -47.8242,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 20911,
    "id": 1076888362
  },
  {
    "city": "Bryant",
    "city_ascii": "Bryant",
    "lat": 34.6152,
    "lng": -92.4914,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arkansas",
    "capital": "",
    "population": 20968,
    "id": 1840013573
  },
  {
    "city": "Mae Sai",
    "city_ascii": "Mae Sai",
    "lat": 20.4266,
    "lng": 99.8841,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Chiang Rai",
    "capital": "minor",
    "population": 20699,
    "id": 1764624336
  },
  {
    "city": "Nizhniy Lomov",
    "city_ascii": "Nizhniy Lomov",
    "lat": 53.5333,
    "lng": 43.6833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Penzenskaya Oblast’",
    "capital": "",
    "population": 20943,
    "id": 1643956972
  },
  {
    "city": "Luckenwalde",
    "city_ascii": "Luckenwalde",
    "lat": 52.0831,
    "lng": 13.1667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Brandenburg",
    "capital": "minor",
    "population": 20522,
    "id": 1276376363
  },
  {
    "city": "Enger",
    "city_ascii": "Enger",
    "lat": 52.1333,
    "lng": 8.5667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 20461,
    "id": 1276241559
  },
  {
    "city": "Dillingen",
    "city_ascii": "Dillingen",
    "lat": 49.35,
    "lng": 6.7333,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saarland",
    "capital": "",
    "population": 20048,
    "id": 1276001038
  },
  {
    "city": "Bloomfield",
    "city_ascii": "Bloomfield",
    "lat": 41.8426,
    "lng": -72.7406,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 20952,
    "id": 1840004778
  },
  {
    "city": "Kill Devil Hills",
    "city_ascii": "Kill Devil Hills",
    "lat": 36.0167,
    "lng": -75.6699,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 20951,
    "id": 1840016154
  },
  {
    "city": "North Salt Lake",
    "city_ascii": "North Salt Lake",
    "lat": 40.8439,
    "lng": -111.9187,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Utah",
    "capital": "",
    "population": 20948,
    "id": 1840020144
  },
  {
    "city": "Traunstein",
    "city_ascii": "Traunstein",
    "lat": 47.8683,
    "lng": 12.6433,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "minor",
    "population": 20520,
    "id": 1276793310
  },
  {
    "city": "Blansko",
    "city_ascii": "Blansko",
    "lat": 49.3632,
    "lng": 16.6432,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Jihomoravský Kraj",
    "capital": "",
    "population": 20484,
    "id": 1203195651
  },
  {
    "city": "Herborn",
    "city_ascii": "Herborn",
    "lat": 50.6825,
    "lng": 8.3061,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 20603,
    "id": 1276002994
  },
  {
    "city": "Comala",
    "city_ascii": "Comala",
    "lat": 19.3208,
    "lng": -103.7603,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Colima",
    "capital": "minor",
    "population": 20888,
    "id": 1484287042
  },
  {
    "city": "Wade Hampton",
    "city_ascii": "Wade Hampton",
    "lat": 34.8821,
    "lng": -82.3336,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 20925,
    "id": 1840035800
  },
  {
    "city": "Osa",
    "city_ascii": "Osa",
    "lat": 57.2833,
    "lng": 55.45,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Permskiy Kray",
    "capital": "",
    "population": 20899,
    "id": 1643282459
  },
  {
    "city": "Kurovskoye",
    "city_ascii": "Kurovskoye",
    "lat": 55.5833,
    "lng": 38.9167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Moskovskaya Oblast’",
    "capital": "",
    "population": 20844,
    "id": 1643003596
  },
  {
    "city": "Kremenets’",
    "city_ascii": "Kremenets'",
    "lat": 50.1081,
    "lng": 25.7275,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Ternopil’s’ka Oblast’",
    "capital": "minor",
    "population": 20837,
    "id": 1804050857
  },
  {
    "city": "Cornelia",
    "city_ascii": "Cornelia",
    "lat": 34.517,
    "lng": -83.531,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 20913,
    "id": 1840014661
  },
  {
    "city": "Schifferstadt",
    "city_ascii": "Schifferstadt",
    "lat": 49.3861,
    "lng": 8.3761,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Rhineland-Palatinate",
    "capital": "",
    "population": 20193,
    "id": 1276776055
  },
  {
    "city": "Rumoi",
    "city_ascii": "Rumoi",
    "lat": 43.9411,
    "lng": 141.6369,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hokkaidō",
    "capital": "",
    "population": 20841,
    "id": 1392820591
  },
  {
    "city": "Suwanee",
    "city_ascii": "Suwanee",
    "lat": 34.0506,
    "lng": -84.0687,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 20907,
    "id": 1840015616
  },
  {
    "city": "New Hope",
    "city_ascii": "New Hope",
    "lat": 45.0374,
    "lng": -93.3869,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 20907,
    "id": 1840007829
  },
  {
    "city": "Gerlingen",
    "city_ascii": "Gerlingen",
    "lat": 48.8,
    "lng": 9.0653,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 19745,
    "id": 1276144443
  },
  {
    "city": "Mill Creek",
    "city_ascii": "Mill Creek",
    "lat": 47.8631,
    "lng": -122.2037,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 20897,
    "id": 1840019790
  },
  {
    "city": "Grand Island",
    "city_ascii": "Grand Island",
    "lat": 43.0198,
    "lng": -78.9619,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 20897,
    "id": 1840087480
  },
  {
    "city": "Pico Truncado",
    "city_ascii": "Pico Truncado",
    "lat": -46.8,
    "lng": -67.9667,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Santa Cruz",
    "capital": "",
    "population": 20889,
    "id": 1032509324
  },
  {
    "city": "Universal City",
    "city_ascii": "Universal City",
    "lat": 29.5521,
    "lng": -98.3074,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 20890,
    "id": 1840022225
  },
  {
    "city": "Mössingen",
    "city_ascii": "Mossingen",
    "lat": 48.4064,
    "lng": 9.0575,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 20480,
    "id": 1276236369
  },
  {
    "city": "Kirkby in Ashfield",
    "city_ascii": "Kirkby in Ashfield",
    "lat": 53.099,
    "lng": -1.245,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Nottinghamshire",
    "capital": "",
    "population": 20672,
    "id": 1826272364
  },
  {
    "city": "Buinsk",
    "city_ascii": "Buinsk",
    "lat": 54.9667,
    "lng": 48.2833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Tatarstan",
    "capital": "",
    "population": 20854,
    "id": 1643218865
  },
  {
    "city": "Gamboma",
    "city_ascii": "Gamboma",
    "lat": -1.8795,
    "lng": 15.85,
    "country": "Congo (Brazzaville)",
    "iso2": "CG",
    "iso3": "COG",
    "admin_name": "Plateaux",
    "capital": "",
    "population": 20877,
    "id": 1178991023
  },
  {
    "city": "Hamina",
    "city_ascii": "Hamina",
    "lat": 60.5697,
    "lng": 27.1981,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Kymenlaakso",
    "capital": "minor",
    "population": 20851,
    "id": 1246729258
  },
  {
    "city": "Clemmons",
    "city_ascii": "Clemmons",
    "lat": 36.0319,
    "lng": -80.3861,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 20867,
    "id": 1840017728
  },
  {
    "city": "Strathroy-Caradoc",
    "city_ascii": "Strathroy-Caradoc",
    "lat": 42.9575,
    "lng": -81.6167,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 20867,
    "id": 1124000831
  },
  {
    "city": "Olenegorsk",
    "city_ascii": "Olenegorsk",
    "lat": 68.15,
    "lng": 33.2833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Murmanskaya Oblast’",
    "capital": "",
    "population": 20847,
    "id": 1643381812
  },
  {
    "city": "Tobe",
    "city_ascii": "Tobe",
    "lat": 33.7492,
    "lng": 132.7922,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Ehime",
    "capital": "",
    "population": 20653,
    "id": 1392744694
  },
  {
    "city": "Viesca",
    "city_ascii": "Viesca",
    "lat": 25.35,
    "lng": -102.8,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Coahuila de Zaragoza",
    "capital": "minor",
    "population": 19328,
    "id": 1484294846
  },
  {
    "city": "Little Egg Harbor",
    "city_ascii": "Little Egg Harbor",
    "lat": 39.5969,
    "lng": -74.3454,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 20848,
    "id": 1840081598
  },
  {
    "city": "Blieskastel",
    "city_ascii": "Blieskastel",
    "lat": 49.2422,
    "lng": 7.2544,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saarland",
    "capital": "",
    "population": 20656,
    "id": 1276041906
  },
  {
    "city": "Stephenville",
    "city_ascii": "Stephenville",
    "lat": 32.2148,
    "lng": -98.2205,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 20841,
    "id": 1840022097
  },
  {
    "city": "Dyersburg",
    "city_ascii": "Dyersburg",
    "lat": 36.0465,
    "lng": -89.3777,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 20839,
    "id": 1840013372
  },
  {
    "city": "Bayou Cane",
    "city_ascii": "Bayou Cane",
    "lat": 29.6244,
    "lng": -90.751,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 20829,
    "id": 1840014031
  },
  {
    "city": "Oneida",
    "city_ascii": "Oneida",
    "lat": 43.0769,
    "lng": -75.6621,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 20828,
    "id": 1840000384
  },
  {
    "city": "Mar’’ina Horka",
    "city_ascii": "Mar''ina Horka",
    "lat": 53.5167,
    "lng": 28.1417,
    "country": "Belarus",
    "iso2": "BY",
    "iso3": "BLR",
    "admin_name": "Minskaya Voblasts’",
    "capital": "",
    "population": 20800,
    "id": 1112958819
  },
  {
    "city": "Louisville",
    "city_ascii": "Louisville",
    "lat": 39.9709,
    "lng": -105.1441,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 20816,
    "id": 1840020179
  },
  {
    "city": "Pacatu",
    "city_ascii": "Pacatu",
    "lat": -11.9578,
    "lng": -38.975,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Bahia",
    "capital": "",
    "population": 20754,
    "id": 1076255229
  },
  {
    "city": "Stamford",
    "city_ascii": "Stamford",
    "lat": 52.656,
    "lng": -0.484,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Lincolnshire",
    "capital": "",
    "population": 19701,
    "id": 1826765558
  },
  {
    "city": "Dixon",
    "city_ascii": "Dixon",
    "lat": 38.4468,
    "lng": -121.8249,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 20803,
    "id": 1840018859
  },
  {
    "city": "Allende",
    "city_ascii": "Allende",
    "lat": 28.3333,
    "lng": -100.8333,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Coahuila de Zaragoza",
    "capital": "minor",
    "population": 20694,
    "id": 1484737223
  },
  {
    "city": "Lake Ronkonkoma",
    "city_ascii": "Lake Ronkonkoma",
    "lat": 40.8308,
    "lng": -73.1112,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 20780,
    "id": 1840005086
  },
  {
    "city": "Allendale",
    "city_ascii": "Allendale",
    "lat": 42.9851,
    "lng": -85.9509,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 20776,
    "id": 1840004345
  },
  {
    "city": "Murray",
    "city_ascii": "Murray",
    "lat": 36.6146,
    "lng": -88.3206,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kentucky",
    "capital": "",
    "population": 20776,
    "id": 1840014410
  },
  {
    "city": "Pozzallo",
    "city_ascii": "Pozzallo",
    "lat": 36.7303,
    "lng": 14.8467,
    "country": "Italy",
    "iso2": "IT",
    "iso3": "ITA",
    "admin_name": "Sicilia",
    "capital": "",
    "population": 19466,
    "id": 1380784313
  },
  {
    "city": "Golden",
    "city_ascii": "Golden",
    "lat": 39.7406,
    "lng": -105.2118,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 20767,
    "id": 1840020197
  },
  {
    "city": "Nidderau",
    "city_ascii": "Nidderau",
    "lat": 50.25,
    "lng": 8.9,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 20333,
    "id": 1276730067
  },
  {
    "city": "Hakui",
    "city_ascii": "Hakui",
    "lat": 36.8936,
    "lng": 136.7789,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Ishikawa",
    "capital": "",
    "population": 20515,
    "id": 1392246998
  },
  {
    "city": "Illapel",
    "city_ascii": "Illapel",
    "lat": -31.6327,
    "lng": -71.1683,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Coquimbo",
    "capital": "minor",
    "population": 20751,
    "id": 1152446656
  },
  {
    "city": "Bad Salzungen",
    "city_ascii": "Bad Salzungen",
    "lat": 50.8117,
    "lng": 10.2333,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Thuringia",
    "capital": "minor",
    "population": 20244,
    "id": 1276249222
  },
  {
    "city": "Nova Granada",
    "city_ascii": "Nova Granada",
    "lat": -20.5339,
    "lng": -49.3139,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 20717,
    "id": 1076239207
  },
  {
    "city": "Olean",
    "city_ascii": "Olean",
    "lat": 42.0819,
    "lng": -78.4321,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 20755,
    "id": 1840000452
  },
  {
    "city": "Chachahuantla",
    "city_ascii": "Chachahuantla",
    "lat": 20.2756,
    "lng": -98.1503,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Hidalgo",
    "capital": "",
    "population": 20619,
    "id": 1484829941
  },
  {
    "city": "Hoddesdon",
    "city_ascii": "Hoddesdon",
    "lat": 51.759,
    "lng": -0.015,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hertfordshire",
    "capital": "",
    "population": 20250,
    "id": 1826405182
  },
  {
    "city": "Dolyna",
    "city_ascii": "Dolyna",
    "lat": 48.9703,
    "lng": 24.0108,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Ivano-Frankivs’ka Oblast’",
    "capital": "minor",
    "population": 20691,
    "id": 1804692275
  },
  {
    "city": "Senica",
    "city_ascii": "Senica",
    "lat": 48.6806,
    "lng": 17.3667,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Trnavský",
    "capital": "minor",
    "population": 20342,
    "id": 1703713928
  },
  {
    "city": "Elvas",
    "city_ascii": "Elvas",
    "lat": 38.88,
    "lng": -7.1628,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Portalegre",
    "capital": "minor",
    "population": 20706,
    "id": 1620193707
  },
  {
    "city": "Melena del Sur",
    "city_ascii": "Melena del Sur",
    "lat": 22.7814,
    "lng": -82.1486,
    "country": "Cuba",
    "iso2": "CU",
    "iso3": "CUB",
    "admin_name": "Mayabeque",
    "capital": "minor",
    "population": 20646,
    "id": 1192175478
  },
  {
    "city": "Grayslake",
    "city_ascii": "Grayslake",
    "lat": 42.3402,
    "lng": -88.0338,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 20725,
    "id": 1840011150
  },
  {
    "city": "Tielt",
    "city_ascii": "Tielt",
    "lat": 50.9989,
    "lng": 3.3258,
    "country": "Belgium",
    "iso2": "BE",
    "iso3": "BEL",
    "admin_name": "Flanders",
    "capital": "minor",
    "population": 20422,
    "id": 1056782112
  },
  {
    "city": "Pita",
    "city_ascii": "Pita",
    "lat": 11.08,
    "lng": -12.401,
    "country": "Guinea",
    "iso2": "GN",
    "iso3": "GIN",
    "admin_name": "Mamou",
    "capital": "minor",
    "population": 20052,
    "id": 1324788694
  },
  {
    "city": "Okha",
    "city_ascii": "Okha",
    "lat": 53.5833,
    "lng": 142.9333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sakhalinskaya Oblast’",
    "capital": "",
    "population": 20715,
    "id": 1643780649
  },
  {
    "city": "Harvey",
    "city_ascii": "Harvey",
    "lat": 29.8876,
    "lng": -90.0666,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 20712,
    "id": 1840013985
  },
  {
    "city": "Pertuis",
    "city_ascii": "Pertuis",
    "lat": 43.695,
    "lng": 5.5036,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Provence-Alpes-Côte d’Azur",
    "capital": "",
    "population": 20380,
    "id": 1250296528
  },
  {
    "city": "South Milwaukee",
    "city_ascii": "South Milwaukee",
    "lat": 42.912,
    "lng": -87.8627,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 20696,
    "id": 1840003032
  },
  {
    "city": "Lorton",
    "city_ascii": "Lorton",
    "lat": 38.6984,
    "lng": -77.2163,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 20691,
    "id": 1840006035
  },
  {
    "city": "Manor Township",
    "city_ascii": "Manor Township",
    "lat": 39.9849,
    "lng": -76.4216,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 20687,
    "id": 1840147751
  },
  {
    "city": "Sun Valley",
    "city_ascii": "Sun Valley",
    "lat": 39.6106,
    "lng": -119.7765,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Nevada",
    "capital": "",
    "population": 20683,
    "id": 1840018711
  },
  {
    "city": "Gardner",
    "city_ascii": "Gardner",
    "lat": 42.5845,
    "lng": -71.9867,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 20683,
    "id": 1840000436
  },
  {
    "city": "Nové Mesto nad Váhom",
    "city_ascii": "Nove Mesto nad Vahom",
    "lat": 48.7833,
    "lng": 17.8333,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Nitriansky",
    "capital": "minor",
    "population": 20066,
    "id": 1703781613
  },
  {
    "city": "Arhavi",
    "city_ascii": "Arhavi",
    "lat": 41.3531,
    "lng": 41.31,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Artvin",
    "capital": "minor",
    "population": 20565,
    "id": 1792000779
  },
  {
    "city": "Duderstadt",
    "city_ascii": "Duderstadt",
    "lat": 51.5125,
    "lng": 10.2597,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 20466,
    "id": 1276392919
  },
  {
    "city": "Hailsham",
    "city_ascii": "Hailsham",
    "lat": 50.8647,
    "lng": 0.2577,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "East Sussex",
    "capital": "",
    "population": 20476,
    "id": 1826151855
  },
  {
    "city": "East Hemet",
    "city_ascii": "East Hemet",
    "lat": 33.7301,
    "lng": -116.941,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 20679,
    "id": 1840028370
  },
  {
    "city": "Nördlingen",
    "city_ascii": "Nordlingen",
    "lat": 48.85,
    "lng": 10.5,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 20379,
    "id": 1276160287
  },
  {
    "city": "Havelock",
    "city_ascii": "Havelock",
    "lat": 34.9078,
    "lng": -76.8987,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 20677,
    "id": 1840014592
  },
  {
    "city": "Wasaga Beach",
    "city_ascii": "Wasaga Beach",
    "lat": 44.5206,
    "lng": -80.0167,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 20675,
    "id": 1124001919
  },
  {
    "city": "Cirencester",
    "city_ascii": "Cirencester",
    "lat": 51.719,
    "lng": -1.968,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Gloucestershire",
    "capital": "",
    "population": 19076,
    "id": 1826367738
  },
  {
    "city": "Chapulhuacán",
    "city_ascii": "Chapulhuacan",
    "lat": 21.1547,
    "lng": -98.9039,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Hidalgo",
    "capital": "",
    "population": 20577,
    "id": 1484747290
  },
  {
    "city": "Lebanon",
    "city_ascii": "Lebanon",
    "lat": 39.4251,
    "lng": -84.2135,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 20659,
    "id": 1840001546
  },
  {
    "city": "Batī",
    "city_ascii": "Bati",
    "lat": 11.1833,
    "lng": 40.0167,
    "country": "Ethiopia",
    "iso2": "ET",
    "iso3": "ETH",
    "admin_name": "Āmara",
    "capital": "",
    "population": 20536,
    "id": 1231243570
  },
  {
    "city": "East Moline",
    "city_ascii": "East Moline",
    "lat": 41.5199,
    "lng": -90.3879,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 20645,
    "id": 1840008180
  },
  {
    "city": "Takanabe",
    "city_ascii": "Takanabe",
    "lat": 32.1283,
    "lng": 131.5033,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Miyazaki",
    "capital": "",
    "population": 20185,
    "id": 1392293829
  },
  {
    "city": "Lillehammer",
    "city_ascii": "Lillehammer",
    "lat": 61.1161,
    "lng": 10.4578,
    "country": "Norway",
    "iso2": "NO",
    "iso3": "NOR",
    "admin_name": "Oppland",
    "capital": "minor",
    "population": 20580,
    "id": 1578724478
  },
  {
    "city": "Baião",
    "city_ascii": "Baiao",
    "lat": 41.1667,
    "lng": -8.0333,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Porto",
    "capital": "minor",
    "population": 20522,
    "id": 1620277576
  },
  {
    "city": "Miandrivazo",
    "city_ascii": "Miandrivazo",
    "lat": -19.5162,
    "lng": 45.4666,
    "country": "Madagascar",
    "iso2": "MG",
    "iso3": "MDG",
    "admin_name": "Toliara",
    "capital": "",
    "population": 20631,
    "id": 1450332104
  },
  {
    "city": "Korntal-Münchingen",
    "city_ascii": "Korntal-Munchingen",
    "lat": 48.8306,
    "lng": 9.1214,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 19679,
    "id": 1276087969
  },
  {
    "city": "Norwalk",
    "city_ascii": "Norwalk",
    "lat": 41.2443,
    "lng": -82.6088,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 20629,
    "id": 1840000817
  },
  {
    "city": "Bobrov",
    "city_ascii": "Bobrov",
    "lat": 51.0944,
    "lng": 40.0322,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Voronezhskaya Oblast’",
    "capital": "",
    "population": 20605,
    "id": 1643014225
  },
  {
    "city": "Schortens",
    "city_ascii": "Schortens",
    "lat": 53.5333,
    "lng": 7.95,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 20329,
    "id": 1276002315
  },
  {
    "city": "Goris",
    "city_ascii": "Goris",
    "lat": 39.5167,
    "lng": 46.3333,
    "country": "Armenia",
    "iso2": "AM",
    "iso3": "ARM",
    "admin_name": "Syunik’",
    "capital": "",
    "population": 20591,
    "id": 1051724931
  },
  {
    "city": "Pak Phanang",
    "city_ascii": "Pak Phanang",
    "lat": 8.3511,
    "lng": 100.2019,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Nakhon Si Thammarat",
    "capital": "minor",
    "population": 20425,
    "id": 1764714642
  },
  {
    "city": "Yorkville",
    "city_ascii": "Yorkville",
    "lat": 41.6562,
    "lng": -88.4507,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 20613,
    "id": 1840010216
  },
  {
    "city": "Mbala",
    "city_ascii": "Mbala",
    "lat": -8.84,
    "lng": 31.37,
    "country": "Zambia",
    "iso2": "ZM",
    "iso3": "ZMB",
    "admin_name": "Northern",
    "capital": "",
    "population": 20570,
    "id": 1894671312
  },
  {
    "city": "Kawambwa",
    "city_ascii": "Kawambwa",
    "lat": -9.7795,
    "lng": 29.08,
    "country": "Zambia",
    "iso2": "ZM",
    "iso3": "ZMB",
    "admin_name": "Luapula",
    "capital": "",
    "population": 20589,
    "id": 1894964184
  },
  {
    "city": "New Glasgow",
    "city_ascii": "New Glasgow",
    "lat": 45.5926,
    "lng": -62.6455,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Nova Scotia",
    "capital": "",
    "population": 20609,
    "id": 1124760188
  },
  {
    "city": "Logansport",
    "city_ascii": "Logansport",
    "lat": 40.7472,
    "lng": -86.3519,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 20608,
    "id": 1840013861
  },
  {
    "city": "San Felipe",
    "city_ascii": "San Felipe",
    "lat": 31.0275,
    "lng": -114.8353,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Baja California",
    "capital": "",
    "population": 19204,
    "id": 1484794463
  },
  {
    "city": "Aldan",
    "city_ascii": "Aldan",
    "lat": 58.6,
    "lng": 125.3833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sakha (Yakutiya)",
    "capital": "",
    "population": 20595,
    "id": 1643237106
  },
  {
    "city": "Barehra",
    "city_ascii": "Barehra",
    "lat": 27.75,
    "lng": 78.5667,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Uttar Pradesh",
    "capital": "",
    "population": 19542,
    "id": 1356242932
  },
  {
    "city": "Nikolsk",
    "city_ascii": "Nikolsk",
    "lat": 53.7167,
    "lng": 46.0833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Penzenskaya Oblast’",
    "capital": "",
    "population": 20572,
    "id": 1643760647
  },
  {
    "city": "Dublin",
    "city_ascii": "Dublin",
    "lat": 32.5359,
    "lng": -82.928,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 20583,
    "id": 1840013813
  },
  {
    "city": "North Ogden",
    "city_ascii": "North Ogden",
    "lat": 41.3123,
    "lng": -111.9585,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Utah",
    "capital": "",
    "population": 20582,
    "id": 1840020134
  },
  {
    "city": "Stadtlohn",
    "city_ascii": "Stadtlohn",
    "lat": 51.9925,
    "lng": 6.915,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 20322,
    "id": 1276897939
  },
  {
    "city": "Meinerzhagen",
    "city_ascii": "Meinerzhagen",
    "lat": 51.1061,
    "lng": 7.6403,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 20397,
    "id": 1276363054
  },
  {
    "city": "Baden",
    "city_ascii": "Baden",
    "lat": 47.4729,
    "lng": 8.308,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Aargau",
    "capital": "",
    "population": 19122,
    "id": 1756517451
  },
  {
    "city": "Clifton",
    "city_ascii": "Clifton",
    "lat": 39.0763,
    "lng": -108.4606,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 20572,
    "id": 1840017556
  },
  {
    "city": "Shirahama",
    "city_ascii": "Shirahama",
    "lat": 33.6781,
    "lng": 135.3481,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Wakayama",
    "capital": "",
    "population": 20470,
    "id": 1392728798
  },
  {
    "city": "Arsk",
    "city_ascii": "Arsk",
    "lat": 56.0906,
    "lng": 49.8764,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Tatarstan",
    "capital": "",
    "population": 20540,
    "id": 1643889493
  },
  {
    "city": "Sunchales",
    "city_ascii": "Sunchales",
    "lat": -30.9442,
    "lng": -61.5614,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Santa Fe",
    "capital": "",
    "population": 20537,
    "id": 1032630690
  },
  {
    "city": "Fukagawa",
    "city_ascii": "Fukagawa",
    "lat": 43.7233,
    "lng": 142.0536,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hokkaidō",
    "capital": "",
    "population": 20526,
    "id": 1392710666
  },
  {
    "city": "Miracatu",
    "city_ascii": "Miracatu",
    "lat": -24.2808,
    "lng": -47.46,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 20533,
    "id": 1076480292
  },
  {
    "city": "Wilmot",
    "city_ascii": "Wilmot",
    "lat": 43.4,
    "lng": -80.65,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 20545,
    "id": 1124001797
  },
  {
    "city": "Mundra",
    "city_ascii": "Mundra",
    "lat": 22.85,
    "lng": 69.73,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Gujarāt",
    "capital": "",
    "population": 20338,
    "id": 1356047064
  },
  {
    "city": "Laboulaye",
    "city_ascii": "Laboulaye",
    "lat": -34.1267,
    "lng": -63.3911,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Córdoba",
    "capital": "minor",
    "population": 20534,
    "id": 1032996866
  },
  {
    "city": "Tyrnyauz",
    "city_ascii": "Tyrnyauz",
    "lat": 43.4,
    "lng": 42.9167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kabardino-Balkariya",
    "capital": "minor",
    "population": 20513,
    "id": 1643073002
  },
  {
    "city": "Brunswick",
    "city_ascii": "Brunswick",
    "lat": 43.9007,
    "lng": -69.9761,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maine",
    "capital": "",
    "population": 20534,
    "id": 1840052591
  },
  {
    "city": "Pleszew",
    "city_ascii": "Pleszew",
    "lat": 51.8974,
    "lng": 17.7856,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Wielkopolskie",
    "capital": "minor",
    "population": 20365,
    "id": 1616452688
  },
  {
    "city": "Lynn Haven",
    "city_ascii": "Lynn Haven",
    "lat": 30.2337,
    "lng": -85.637,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 20525,
    "id": 1840015922
  },
  {
    "city": "Ferguson",
    "city_ascii": "Ferguson",
    "lat": 38.749,
    "lng": -90.295,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 20525,
    "id": 1840008584
  },
  {
    "city": "Khvānsār",
    "city_ascii": "Khvansar",
    "lat": 33.2206,
    "lng": 50.315,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Eşfahān",
    "capital": "minor",
    "population": 20490,
    "id": 1364250256
  },
  {
    "city": "Arlington",
    "city_ascii": "Arlington",
    "lat": 48.1698,
    "lng": -122.1443,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 20523,
    "id": 1840018378
  },
  {
    "city": "Berlin",
    "city_ascii": "Berlin",
    "lat": 41.6114,
    "lng": -72.7758,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 20519,
    "id": 1840034026
  },
  {
    "city": "Elkridge",
    "city_ascii": "Elkridge",
    "lat": 39.1941,
    "lng": -76.7427,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 20519,
    "id": 1840005801
  },
  {
    "city": "Cinfães",
    "city_ascii": "Cinfaes",
    "lat": 41.0667,
    "lng": -8.0833,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Viseu",
    "capital": "minor",
    "population": 20427,
    "id": 1620729048
  },
  {
    "city": "Alice",
    "city_ascii": "Alice",
    "lat": 27.7556,
    "lng": -98.0653,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 20510,
    "id": 1840019714
  },
  {
    "city": "Willmar",
    "city_ascii": "Willmar",
    "lat": 45.122,
    "lng": -95.0569,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 20510,
    "id": 1840009995
  },
  {
    "city": "Seydi",
    "city_ascii": "Seydi",
    "lat": 39.4817,
    "lng": 62.9136,
    "country": "Turkmenistan",
    "iso2": "TM",
    "iso3": "TKM",
    "admin_name": "Lebap",
    "capital": "",
    "population": 20491,
    "id": 1795186775
  },
  {
    "city": "South Ockendon",
    "city_ascii": "South Ockendon",
    "lat": 51.5207,
    "lng": 0.2956,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Thurrock",
    "capital": "",
    "population": 19460,
    "id": 1826422421
  },
  {
    "city": "Swansboro",
    "city_ascii": "Swansboro",
    "lat": 34.6958,
    "lng": -77.1368,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 20507,
    "id": 1840016532
  },
  {
    "city": "Großburgwedel",
    "city_ascii": "Grossburgwedel",
    "lat": 52.4933,
    "lng": 9.8586,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 20369,
    "id": 1276104367
  },
  {
    "city": "Murphy",
    "city_ascii": "Murphy",
    "lat": 33.0186,
    "lng": -96.6105,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 20500,
    "id": 1840020659
  },
  {
    "city": "Bad Waldsee",
    "city_ascii": "Bad Waldsee",
    "lat": 47.9211,
    "lng": 9.7519,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 20308,
    "id": 1276818086
  },
  {
    "city": "Üshtöbe",
    "city_ascii": "Ushtobe",
    "lat": 45.2653,
    "lng": 77.97,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Almaty",
    "capital": "",
    "population": 20492,
    "id": 1398940566
  },
  {
    "city": "Montclair",
    "city_ascii": "Montclair",
    "lat": 38.6111,
    "lng": -77.34,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 20494,
    "id": 1840006095
  },
  {
    "city": "Náchod",
    "city_ascii": "Nachod",
    "lat": 50.4167,
    "lng": 16.163,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Královéhradecký Kraj",
    "capital": "",
    "population": 19897,
    "id": 1203181897
  },
  {
    "city": "Marblehead",
    "city_ascii": "Marblehead",
    "lat": 42.4991,
    "lng": -70.8638,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 20488,
    "id": 1840053532
  },
  {
    "city": "Culpeper",
    "city_ascii": "Culpeper",
    "lat": 38.4705,
    "lng": -78.0001,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 20485,
    "id": 1840006169
  },
  {
    "city": "Ozark",
    "city_ascii": "Ozark",
    "lat": 37.0361,
    "lng": -93.2155,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 20482,
    "id": 1840009926
  },
  {
    "city": "Puerto Quito",
    "city_ascii": "Puerto Quito",
    "lat": 0.1272,
    "lng": -79.2531,
    "country": "Ecuador",
    "iso2": "EC",
    "iso3": "ECU",
    "admin_name": "Pichincha",
    "capital": "",
    "population": 20445,
    "id": 1218847413
  },
  {
    "city": "American Canyon",
    "city_ascii": "American Canyon",
    "lat": 38.1796,
    "lng": -122.2583,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 20475,
    "id": 1840018834
  },
  {
    "city": "Gaz",
    "city_ascii": "Gaz",
    "lat": 32.8022,
    "lng": 51.6208,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Eşfahān",
    "capital": "",
    "population": 20432,
    "id": 1364058076
  },
  {
    "city": "Annaberg-Buchholz",
    "city_ascii": "Annaberg-Buchholz",
    "lat": 50.58,
    "lng": 13.0022,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "minor",
    "population": 19769,
    "id": 1276866777
  },
  {
    "city": "Papillion",
    "city_ascii": "Papillion",
    "lat": 41.1516,
    "lng": -96.0679,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Nebraska",
    "capital": "",
    "population": 20471,
    "id": 1840009328
  },
  {
    "city": "Kampong Thum",
    "city_ascii": "Kampong Thum",
    "lat": 12.712,
    "lng": 104.889,
    "country": "Cambodia",
    "iso2": "KH",
    "iso3": "KHM",
    "admin_name": "Kampong Thom",
    "capital": "",
    "population": 19951,
    "id": 1116000326
  },
  {
    "city": "Ely",
    "city_ascii": "Ely",
    "lat": 52.3981,
    "lng": 0.2622,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Cambridgeshire",
    "capital": "",
    "population": 20112,
    "id": 1826009346
  },
  {
    "city": "Belūr",
    "city_ascii": "Belur",
    "lat": 13.1642,
    "lng": 75.8647,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Karnātaka",
    "capital": "",
    "population": 20225,
    "id": 1356747417
  },
  {
    "city": "The Dalles",
    "city_ascii": "The Dalles",
    "lat": 45.6053,
    "lng": -121.1818,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 20442,
    "id": 1840021212
  },
  {
    "city": "Karnobat",
    "city_ascii": "Karnobat",
    "lat": 42.65,
    "lng": 26.9833,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Burgas",
    "capital": "",
    "population": 20411,
    "id": 1100484520
  },
  {
    "city": "Adel",
    "city_ascii": "Adel",
    "lat": 53.8489,
    "lng": -1.5849,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Leeds",
    "capital": "",
    "population": 19430,
    "id": 1826917092
  },
  {
    "city": "Ostrov",
    "city_ascii": "Ostrov",
    "lat": 57.3333,
    "lng": 28.35,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Pskovskaya Oblast’",
    "capital": "",
    "population": 20427,
    "id": 1643602961
  },
  {
    "city": "Moorestown",
    "city_ascii": "Moorestown",
    "lat": 39.9784,
    "lng": -74.9413,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 20436,
    "id": 1840081626
  },
  {
    "city": "King of Prussia",
    "city_ascii": "King of Prussia",
    "lat": 40.0963,
    "lng": -75.382,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 20428,
    "id": 1840005464
  },
  {
    "city": "Essex",
    "city_ascii": "Essex",
    "lat": 42.0833,
    "lng": -82.9,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 20427,
    "id": 1124628052
  },
  {
    "city": "Svilengrad",
    "city_ascii": "Svilengrad",
    "lat": 41.7675,
    "lng": 26.1975,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Haskovo",
    "capital": "",
    "population": 20390,
    "id": 1100757621
  },
  {
    "city": "Barre",
    "city_ascii": "Barre",
    "lat": 44.1997,
    "lng": -72.5083,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Vermont",
    "capital": "",
    "population": 20421,
    "id": 1840002186
  },
  {
    "city": "Kubinka",
    "city_ascii": "Kubinka",
    "lat": 55.5764,
    "lng": 36.6947,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Moskovskaya Oblast’",
    "capital": "",
    "population": 20157,
    "id": 1643021922
  },
  {
    "city": "Wittmund",
    "city_ascii": "Wittmund",
    "lat": 53.5747,
    "lng": 7.7808,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "minor",
    "population": 20321,
    "id": 1276497812
  },
  {
    "city": "Cañas",
    "city_ascii": "Canas",
    "lat": 10.43,
    "lng": -85.1,
    "country": "Costa Rica",
    "iso2": "CR",
    "iso3": "CRI",
    "admin_name": "Guanacaste",
    "capital": "",
    "population": 20306,
    "id": 1188753368
  },
  {
    "city": "Hatvan",
    "city_ascii": "Hatvan",
    "lat": 47.6681,
    "lng": 19.6697,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Heves",
    "capital": "minor",
    "population": 20167,
    "id": 1348933364
  },
  {
    "city": "Loja",
    "city_ascii": "Loja",
    "lat": 37.1667,
    "lng": -4.15,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Andalusia",
    "capital": "",
    "population": 20342,
    "id": 1724466102
  },
  {
    "city": "Crest Hill",
    "city_ascii": "Crest Hill",
    "lat": 41.5723,
    "lng": -88.1124,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 20376,
    "id": 1840007088
  },
  {
    "city": "Zülpich",
    "city_ascii": "Zulpich",
    "lat": 50.7,
    "lng": 6.65,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 20174,
    "id": 1276675318
  },
  {
    "city": "Punta Gorda",
    "city_ascii": "Punta Gorda",
    "lat": 26.8933,
    "lng": -82.0516,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 20369,
    "id": 1840015122
  },
  {
    "city": "Mayluu-Suu",
    "city_ascii": "Mayluu-Suu",
    "lat": 41.2667,
    "lng": 72.45,
    "country": "Kyrgyzstan",
    "iso2": "KG",
    "iso3": "KGZ",
    "admin_name": "Jalal-Abad",
    "capital": "",
    "population": 20200,
    "id": 1417039122
  },
  {
    "city": "Kloten",
    "city_ascii": "Kloten",
    "lat": 47.4481,
    "lng": 8.5828,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Zürich",
    "capital": "",
    "population": 19362,
    "id": 1756844710
  },
  {
    "city": "Jirkov",
    "city_ascii": "Jirkov",
    "lat": 50.4999,
    "lng": 13.4478,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Ústecký Kraj",
    "capital": "",
    "population": 19241,
    "id": 1203484786
  },
  {
    "city": "Newton",
    "city_ascii": "Newton",
    "lat": 38.0368,
    "lng": -97.3451,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kansas",
    "capital": "",
    "population": 20361,
    "id": 1840003846
  },
  {
    "city": "Khowrzūq",
    "city_ascii": "Khowrzuq",
    "lat": 32.7781,
    "lng": 51.6458,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Eşfahān",
    "capital": "",
    "population": 20301,
    "id": 1364753903
  },
  {
    "city": "Poplar Bluff",
    "city_ascii": "Poplar Bluff",
    "lat": 36.7632,
    "lng": -90.4136,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 20359,
    "id": 1840009935
  },
  {
    "city": "Oberkirch",
    "city_ascii": "Oberkirch",
    "lat": 48.5322,
    "lng": 8.0786,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 20066,
    "id": 1276739886
  },
  {
    "city": "Tukwila",
    "city_ascii": "Tukwila",
    "lat": 47.4749,
    "lng": -122.2727,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 20347,
    "id": 1840021120
  },
  {
    "city": "Marneuli",
    "city_ascii": "Marneuli",
    "lat": 41.4969,
    "lng": 44.8108,
    "country": "Georgia",
    "iso2": "GE",
    "iso3": "GEO",
    "admin_name": "Kvemo Kartli",
    "capital": "minor",
    "population": 20211,
    "id": 1268246632
  },
  {
    "city": "Mělník",
    "city_ascii": "Melnik",
    "lat": 50.3506,
    "lng": 14.4742,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Středočeský Kraj",
    "capital": "",
    "population": 19559,
    "id": 1203735988
  },
  {
    "city": "Sandwich",
    "city_ascii": "Sandwich",
    "lat": 41.7138,
    "lng": -70.485,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 20342,
    "id": 1840053505
  },
  {
    "city": "Donauwörth",
    "city_ascii": "Donauworth",
    "lat": 48.7184,
    "lng": 10.777,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "minor",
    "population": 20080,
    "id": 1276878391
  },
  {
    "city": "Greiz",
    "city_ascii": "Greiz",
    "lat": 50.6547,
    "lng": 12.1997,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Thuringia",
    "capital": "minor",
    "population": 20103,
    "id": 1276236041
  },
  {
    "city": "Hörstel",
    "city_ascii": "Horstel",
    "lat": 52.2972,
    "lng": 7.5861,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 20141,
    "id": 1276546954
  },
  {
    "city": "Coolbaugh",
    "city_ascii": "Coolbaugh",
    "lat": 41.1837,
    "lng": -75.4386,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 20327,
    "id": 1840145213
  },
  {
    "city": "Duncan",
    "city_ascii": "Duncan",
    "lat": 34.5424,
    "lng": -97.919,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oklahoma",
    "capital": "",
    "population": 20312,
    "id": 1840019253
  },
  {
    "city": "Mirfield",
    "city_ascii": "Mirfield",
    "lat": 53.6807,
    "lng": -1.6888,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Kirklees",
    "capital": "",
    "population": 19563,
    "id": 1826835871
  },
  {
    "city": "Bothell West",
    "city_ascii": "Bothell West",
    "lat": 47.8056,
    "lng": -122.2401,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 20307,
    "id": 1840041906
  },
  {
    "city": "Leinefelde",
    "city_ascii": "Leinefelde",
    "lat": 51.3833,
    "lng": 10.3333,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Thuringia",
    "capital": "",
    "population": 20124,
    "id": 1276779963
  },
  {
    "city": "Payson",
    "city_ascii": "Payson",
    "lat": 40.036,
    "lng": -111.7395,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Utah",
    "capital": "",
    "population": 20303,
    "id": 1840020172
  },
  {
    "city": "Lichtenfels",
    "city_ascii": "Lichtenfels",
    "lat": 50.1333,
    "lng": 11.0333,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "minor",
    "population": 20133,
    "id": 1276573026
  },
  {
    "city": "Mountain Brook",
    "city_ascii": "Mountain Brook",
    "lat": 33.4871,
    "lng": -86.74,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 20297,
    "id": 1840014792
  },
  {
    "city": "Sergach",
    "city_ascii": "Sergach",
    "lat": 55.5333,
    "lng": 45.4667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Nizhegorodskaya Oblast’",
    "capital": "",
    "population": 20269,
    "id": 1643460838
  },
  {
    "city": "Dingolfing",
    "city_ascii": "Dingolfing",
    "lat": 48.6333,
    "lng": 12.5,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "minor",
    "population": 19839,
    "id": 1276764455
  },
  {
    "city": "Glassboro",
    "city_ascii": "Glassboro",
    "lat": 39.7014,
    "lng": -75.1113,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 20288,
    "id": 1840003789
  },
  {
    "city": "Pedro Carbo",
    "city_ascii": "Pedro Carbo",
    "lat": -1.8167,
    "lng": -80.2333,
    "country": "Ecuador",
    "iso2": "EC",
    "iso3": "ECU",
    "admin_name": "Guayas",
    "capital": "",
    "population": 20220,
    "id": 1218092556
  },
  {
    "city": "Neustrelitz",
    "city_ascii": "Neustrelitz",
    "lat": 53.3647,
    "lng": 13.0636,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Mecklenburg-Western Pomerania",
    "capital": "",
    "population": 20140,
    "id": 1276001058
  },
  {
    "city": "Ciudad Melchor de Mencos",
    "city_ascii": "Ciudad Melchor de Mencos",
    "lat": 17.0667,
    "lng": -89.15,
    "country": "Guatemala",
    "iso2": "GT",
    "iso3": "GTM",
    "admin_name": "Petén",
    "capital": "minor",
    "population": 20273,
    "id": 1320635102
  },
  {
    "city": "Requena",
    "city_ascii": "Requena",
    "lat": 39.4885,
    "lng": -1.1023,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Valencia",
    "capital": "",
    "population": 20254,
    "id": 1724453990
  },
  {
    "city": "New Canaan",
    "city_ascii": "New Canaan",
    "lat": 41.1593,
    "lng": -73.4992,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 20273,
    "id": 1840035495
  },
  {
    "city": "Buckingham",
    "city_ascii": "Buckingham",
    "lat": 40.3188,
    "lng": -75.058,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 20270,
    "id": 1840034882
  },
  {
    "city": "Beroun",
    "city_ascii": "Beroun",
    "lat": 49.9639,
    "lng": 14.0721,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Středočeský Kraj",
    "capital": "",
    "population": 19641,
    "id": 1203585066
  },
  {
    "city": "Kęty",
    "city_ascii": "Kety",
    "lat": 49.8821,
    "lng": 19.2233,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Małopolskie",
    "capital": "",
    "population": 19428,
    "id": 1616742375
  },
  {
    "city": "Shelton",
    "city_ascii": "Shelton",
    "lat": 47.2186,
    "lng": -123.112,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 20264,
    "id": 1840021123
  },
  {
    "city": "Torgau",
    "city_ascii": "Torgau",
    "lat": 51.5603,
    "lng": 13.0056,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "minor",
    "population": 20065,
    "id": 1276804920
  },
  {
    "city": "Ramona",
    "city_ascii": "Ramona",
    "lat": 33.0474,
    "lng": -116.8767,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 20260,
    "id": 1840019346
  },
  {
    "city": "Lake Shore",
    "city_ascii": "Lake Shore",
    "lat": 39.1029,
    "lng": -76.4876,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 20260,
    "id": 1840005920
  },
  {
    "city": "Arbutus",
    "city_ascii": "Arbutus",
    "lat": 39.2428,
    "lng": -76.6922,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 20259,
    "id": 1840005676
  },
  {
    "city": "Amherst Center",
    "city_ascii": "Amherst Center",
    "lat": 42.3757,
    "lng": -72.5188,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 20253,
    "id": 1840003166
  },
  {
    "city": "Araçariguama",
    "city_ascii": "Aracariguama",
    "lat": -23.4386,
    "lng": -47.0614,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 20114,
    "id": 1076653796
  },
  {
    "city": "Henderson",
    "city_ascii": "Henderson",
    "lat": 36.3259,
    "lng": -78.4155,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 20238,
    "id": 1840014433
  },
  {
    "city": "Kitatajima",
    "city_ascii": "Kitatajima",
    "lat": 35.9814,
    "lng": 139.4817,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Saitama",
    "capital": "",
    "population": 19758,
    "id": 1392425295
  },
  {
    "city": "Eastwood",
    "city_ascii": "Eastwood",
    "lat": 53.018,
    "lng": -1.304,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Nottinghamshire",
    "capital": "",
    "population": 18612,
    "id": 1826227657
  },
  {
    "city": "Yeniseysk",
    "city_ascii": "Yeniseysk",
    "lat": 58.4667,
    "lng": 92.1333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Krasnoyarskiy Kray",
    "capital": "",
    "population": 19920,
    "id": 1643588190
  },
  {
    "city": "Skegness",
    "city_ascii": "Skegness",
    "lat": 53.14,
    "lng": 0.34,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Lincolnshire",
    "capital": "",
    "population": 19579,
    "id": 1826662811
  },
  {
    "city": "Holzminden",
    "city_ascii": "Holzminden",
    "lat": 51.8297,
    "lng": 9.4483,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "minor",
    "population": 19998,
    "id": 1276491992
  },
  {
    "city": "Agoura Hills",
    "city_ascii": "Agoura Hills",
    "lat": 34.151,
    "lng": -118.7608,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 20222,
    "id": 1840019221
  },
  {
    "city": "Salamanca",
    "city_ascii": "Salamanca",
    "lat": -31.7796,
    "lng": -70.98,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Coquimbo",
    "capital": "",
    "population": 20214,
    "id": 1152804087
  },
  {
    "city": "Goole",
    "city_ascii": "Goole",
    "lat": 53.6992,
    "lng": -0.8692,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "East Riding of Yorkshire",
    "capital": "",
    "population": 19518,
    "id": 1826412985
  },
  {
    "city": "Celorico de Basto",
    "city_ascii": "Celorico de Basto",
    "lat": 41.3869,
    "lng": -8.0022,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Braga",
    "capital": "minor",
    "population": 20098,
    "id": 1620638780
  },
  {
    "city": "Libertyville",
    "city_ascii": "Libertyville",
    "lat": 42.287,
    "lng": -87.967,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 20205,
    "id": 1840011161
  },
  {
    "city": "Durango",
    "city_ascii": "Durango",
    "lat": 37.2659,
    "lng": -107.8782,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 20203,
    "id": 1840018929
  },
  {
    "city": "Fót",
    "city_ascii": "Fot",
    "lat": 47.6092,
    "lng": 19.1928,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Pest",
    "capital": "",
    "population": 19674,
    "id": 1348547694
  },
  {
    "city": "Bad Krozingen",
    "city_ascii": "Bad Krozingen",
    "lat": 47.9181,
    "lng": 7.7025,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 19644,
    "id": 1276964772
  },
  {
    "city": "Snina",
    "city_ascii": "Snina",
    "lat": 48.9881,
    "lng": 22.1567,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Prešovský",
    "capital": "minor",
    "population": 19855,
    "id": 1703799818
  },
  {
    "city": "Shenandoah",
    "city_ascii": "Shenandoah",
    "lat": 30.4019,
    "lng": -91.002,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 20192,
    "id": 1840031152
  },
  {
    "city": "Pikalëvo",
    "city_ascii": "Pikalevo",
    "lat": 59.5208,
    "lng": 34.1514,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Leningradskaya Oblast’",
    "capital": "",
    "population": 20183,
    "id": 1643572951
  },
  {
    "city": "Affton",
    "city_ascii": "Affton",
    "lat": 38.5499,
    "lng": -90.3264,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 20187,
    "id": 1840006124
  },
  {
    "city": "Kotelnikovo",
    "city_ascii": "Kotelnikovo",
    "lat": 47.6333,
    "lng": 43.1333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Volgogradskaya Oblast’",
    "capital": "",
    "population": 20172,
    "id": 1643615512
  },
  {
    "city": "Bichena",
    "city_ascii": "Bichena",
    "lat": 10.45,
    "lng": 38.2,
    "country": "Ethiopia",
    "iso2": "ET",
    "iso3": "ETH",
    "admin_name": "Āmara",
    "capital": "",
    "population": 19916,
    "id": 1231640166
  },
  {
    "city": "Millburn",
    "city_ascii": "Millburn",
    "lat": 40.7394,
    "lng": -74.324,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 20179,
    "id": 1840081751
  },
  {
    "city": "Abrīsham",
    "city_ascii": "Abrisham",
    "lat": 32.555,
    "lng": 51.5731,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Eşfahān",
    "capital": "",
    "population": 19406,
    "id": 1364088280
  },
  {
    "city": "Howard",
    "city_ascii": "Howard",
    "lat": 44.5703,
    "lng": -88.092,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 20177,
    "id": 1840002345
  },
  {
    "city": "Irlam",
    "city_ascii": "Irlam",
    "lat": 53.445,
    "lng": -2.422,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Salford",
    "capital": "",
    "population": 19933,
    "id": 1826561547
  },
  {
    "city": "Tamagawa",
    "city_ascii": "Tamagawa",
    "lat": 36.0661,
    "lng": 139.3608,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Saitama",
    "capital": "",
    "population": 19515,
    "id": 1392224035
  },
  {
    "city": "Monmouth",
    "city_ascii": "Monmouth",
    "lat": 44.8505,
    "lng": -123.2283,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 20172,
    "id": 1840019971
  },
  {
    "city": "Ypsilanti",
    "city_ascii": "Ypsilanti",
    "lat": 42.2437,
    "lng": -83.6205,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 20171,
    "id": 1840000459
  },
  {
    "city": "Toprakkale",
    "city_ascii": "Toprakkale",
    "lat": 37.0639,
    "lng": 36.1469,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Osmaniye",
    "capital": "minor",
    "population": 20127,
    "id": 1792333084
  },
  {
    "city": "Mokena",
    "city_ascii": "Mokena",
    "lat": 41.5327,
    "lng": -87.8779,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 20159,
    "id": 1840011491
  },
  {
    "city": "Fort St. John",
    "city_ascii": "Fort St. John",
    "lat": 56.2465,
    "lng": -120.8476,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "British Columbia",
    "capital": "",
    "population": 20155,
    "id": 1124517495
  },
  {
    "city": "Ebbw Vale",
    "city_ascii": "Ebbw Vale",
    "lat": 51.7779,
    "lng": -3.2117,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Blaenau Gwent",
    "capital": "",
    "population": 18558,
    "id": 1826821223
  },
  {
    "city": "Wildeshausen",
    "city_ascii": "Wildeshausen",
    "lat": 52.8931,
    "lng": 8.4314,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 19932,
    "id": 1276599018
  },
  {
    "city": "Sesheke",
    "city_ascii": "Sesheke",
    "lat": -17.4806,
    "lng": 24.3064,
    "country": "Zambia",
    "iso2": "ZM",
    "iso3": "ZMB",
    "admin_name": "Western",
    "capital": "",
    "population": 20149,
    "id": 1894753767
  },
  {
    "city": "Pleasantville",
    "city_ascii": "Pleasantville",
    "lat": 39.39,
    "lng": -74.5169,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 20149,
    "id": 1840001535
  },
  {
    "city": "Metztitlán",
    "city_ascii": "Metztitlan",
    "lat": 20.6,
    "lng": -98.7667,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Hidalgo",
    "capital": "",
    "population": 20123,
    "id": 1484604938
  },
  {
    "city": "Haka",
    "city_ascii": "Haka",
    "lat": 22.9833,
    "lng": 94.0167,
    "country": "Burma",
    "iso2": "MM",
    "iso3": "MMR",
    "admin_name": "Sagaing",
    "capital": "",
    "population": 20000,
    "id": 1104921389
  },
  {
    "city": "Bartow",
    "city_ascii": "Bartow",
    "lat": 27.8868,
    "lng": -81.8213,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 20147,
    "id": 1840014128
  },
  {
    "city": "Mithi",
    "city_ascii": "Mithi",
    "lat": 24.7333,
    "lng": 69.8,
    "country": "Pakistan",
    "iso2": "PK",
    "iso3": "PAK",
    "admin_name": "Sindh",
    "capital": "",
    "population": 20079,
    "id": 1586400830
  },
  {
    "city": "Ashland",
    "city_ascii": "Ashland",
    "lat": 38.4592,
    "lng": -82.6449,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kentucky",
    "capital": "",
    "population": 20146,
    "id": 1840013195
  },
  {
    "city": "Telgte",
    "city_ascii": "Telgte",
    "lat": 51.9819,
    "lng": 7.7856,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 19925,
    "id": 1276981111
  },
  {
    "city": "Miamisburg",
    "city_ascii": "Miamisburg",
    "lat": 39.6323,
    "lng": -84.2725,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 20143,
    "id": 1840008436
  },
  {
    "city": "Lancing",
    "city_ascii": "Lancing",
    "lat": 50.832,
    "lng": -0.319,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "West Sussex",
    "capital": "",
    "population": 18810,
    "id": 1826909253
  },
  {
    "city": "Ashland",
    "city_ascii": "Ashland",
    "lat": 40.8668,
    "lng": -82.3156,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 20140,
    "id": 1840002751
  },
  {
    "city": "Rocky Hill",
    "city_ascii": "Rocky Hill",
    "lat": 41.6572,
    "lng": -72.6632,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 20137,
    "id": 1840035560
  },
  {
    "city": "East Northport",
    "city_ascii": "East Northport",
    "lat": 40.8792,
    "lng": -73.3232,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 20136,
    "id": 1840005039
  },
  {
    "city": "Qorasuv",
    "city_ascii": "Qorasuv",
    "lat": 40.7222,
    "lng": 72.8872,
    "country": "Uzbekistan",
    "iso2": "UZ",
    "iso3": "UZB",
    "admin_name": "Andijon",
    "capital": "",
    "population": 19454,
    "id": 1860791531
  },
  {
    "city": "Grafton",
    "city_ascii": "Grafton",
    "lat": -29.6833,
    "lng": 152.9333,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 19078,
    "id": 1036615468
  },
  {
    "city": "Muhlenberg",
    "city_ascii": "Muhlenberg",
    "lat": 40.3955,
    "lng": -75.925,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 20123,
    "id": 1840100491
  },
  {
    "city": "Ogden",
    "city_ascii": "Ogden",
    "lat": 43.1646,
    "lng": -77.822,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 20120,
    "id": 1840087746
  },
  {
    "city": "Naranjos",
    "city_ascii": "Naranjos",
    "lat": 21.3472,
    "lng": -97.6833,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Veracruz",
    "capital": "minor",
    "population": 20073,
    "id": 1484227797
  },
  {
    "city": "Germantown",
    "city_ascii": "Germantown",
    "lat": 43.2343,
    "lng": -88.1217,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 20116,
    "id": 1840002830
  },
  {
    "city": "Uetersen",
    "city_ascii": "Uetersen",
    "lat": 53.6872,
    "lng": 9.6692,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Schleswig-Holstein",
    "capital": "",
    "population": 18496,
    "id": 1276507212
  },
  {
    "city": "Giengen an der Brenz",
    "city_ascii": "Giengen an der Brenz",
    "lat": 48.6217,
    "lng": 10.245,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 19666,
    "id": 1276782990
  },
  {
    "city": "Brandýs nad Labem-Stará Boleslav",
    "city_ascii": "Brandys nad Labem-Stara Boleslav",
    "lat": 50.1872,
    "lng": 14.6633,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Středočeský Kraj",
    "capital": "",
    "population": 19255,
    "id": 1203785584
  },
  {
    "city": "Guapiaçu",
    "city_ascii": "Guapiacu",
    "lat": -20.795,
    "lng": -49.22,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 20037,
    "id": 1076397497
  },
  {
    "city": "Escanaba",
    "city_ascii": "Escanaba",
    "lat": 45.7477,
    "lng": -87.09,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 20094,
    "id": 1840003925
  },
  {
    "city": "Northwich",
    "city_ascii": "Northwich",
    "lat": 53.259,
    "lng": -2.518,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Cheshire West and Chester",
    "capital": "",
    "population": 19924,
    "id": 1826122027
  },
  {
    "city": "Charleston",
    "city_ascii": "Charleston",
    "lat": 39.4842,
    "lng": -88.1781,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 20090,
    "id": 1840007324
  },
  {
    "city": "Glinde",
    "city_ascii": "Glinde",
    "lat": 53.5406,
    "lng": 10.2111,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Schleswig-Holstein",
    "capital": "",
    "population": 18443,
    "id": 1276316044
  },
  {
    "city": "Kwinana",
    "city_ascii": "Kwinana",
    "lat": -32.2394,
    "lng": 115.7702,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Western Australia",
    "capital": "",
    "population": 20086,
    "id": 1036753478
  },
  {
    "city": "Kasongo-Lunda",
    "city_ascii": "Kasongo-Lunda",
    "lat": -6.4796,
    "lng": 16.83,
    "country": "Congo (Kinshasa)",
    "iso2": "CD",
    "iso3": "COD",
    "admin_name": "Kwango",
    "capital": "",
    "population": 20060,
    "id": 1180706751
  },
  {
    "city": "Zverevo",
    "city_ascii": "Zverevo",
    "lat": 48.02,
    "lng": 40.12,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Rostovskaya Oblast’",
    "capital": "",
    "population": 19456,
    "id": 1643130170
  },
  {
    "city": "Brambleton",
    "city_ascii": "Brambleton",
    "lat": 38.9802,
    "lng": -77.5323,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 20081,
    "id": 1840024497
  },
  {
    "city": "Rayevskiy",
    "city_ascii": "Rayevskiy",
    "lat": 54.0674,
    "lng": 54.9269,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Bashkortostan",
    "capital": "",
    "population": 20060,
    "id": 1643613500
  },
  {
    "city": "Ennis",
    "city_ascii": "Ennis",
    "lat": 32.3255,
    "lng": -96.635,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 20077,
    "id": 1840020754
  },
  {
    "city": "Snellville",
    "city_ascii": "Snellville",
    "lat": 33.8563,
    "lng": -84.0038,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 20077,
    "id": 1840015614
  },
  {
    "city": "Junqueirópolis",
    "city_ascii": "Junqueiropolis",
    "lat": -21.5147,
    "lng": -51.4336,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 20066,
    "id": 1076673647
  },
  {
    "city": "Zhosaly",
    "city_ascii": "Zhosaly",
    "lat": 45.4877,
    "lng": 64.078,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Qyzylorda",
    "capital": "",
    "population": 20065,
    "id": 1398510696
  },
  {
    "city": "Kūysinjaq",
    "city_ascii": "Kuysinjaq",
    "lat": 36.0828,
    "lng": 44.6286,
    "country": "Iraq",
    "iso2": "IQ",
    "iso3": "IRQ",
    "admin_name": "Arbīl",
    "capital": "minor",
    "population": 19878,
    "id": 1368937180
  },
  {
    "city": "Sulphur",
    "city_ascii": "Sulphur",
    "lat": 30.2286,
    "lng": -93.3565,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 20065,
    "id": 1840015927
  },
  {
    "city": "L’Assomption",
    "city_ascii": "L'Assomption",
    "lat": 45.8333,
    "lng": -73.4167,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 20065,
    "id": 1124500862
  },
  {
    "city": "Alagir",
    "city_ascii": "Alagir",
    "lat": 43.0417,
    "lng": 44.2106,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "North Ossetia",
    "capital": "minor",
    "population": 20043,
    "id": 1643555747
  },
  {
    "city": "Shakhunya",
    "city_ascii": "Shakhunya",
    "lat": 57.6833,
    "lng": 46.6,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Nizhegorodskaya Oblast’",
    "capital": "",
    "population": 20049,
    "id": 1643622934
  },
  {
    "city": "South St. Paul",
    "city_ascii": "South St. Paul",
    "lat": 44.8876,
    "lng": -93.0411,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 20060,
    "id": 1840008948
  },
  {
    "city": "Oregon",
    "city_ascii": "Oregon",
    "lat": 41.6528,
    "lng": -83.4322,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 20055,
    "id": 1840000570
  },
  {
    "city": "Ban Tha Kham",
    "city_ascii": "Ban Tha Kham",
    "lat": 9.1059,
    "lng": 99.2326,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Surat Thani",
    "capital": "",
    "population": 19939,
    "id": 1764400647
  },
  {
    "city": "Fish Hawk",
    "city_ascii": "Fish Hawk",
    "lat": 27.8511,
    "lng": -82.2164,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 20054,
    "id": 1840028982
  },
  {
    "city": "Burnie",
    "city_ascii": "Burnie",
    "lat": -41.0636,
    "lng": 145.8753,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Tasmania",
    "capital": "",
    "population": 19385,
    "id": 1036394657
  },
  {
    "city": "Kyakhta",
    "city_ascii": "Kyakhta",
    "lat": 50.35,
    "lng": 106.45,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Buryatiya",
    "capital": "",
    "population": 20031,
    "id": 1643237220
  },
  {
    "city": "Middleton",
    "city_ascii": "Middleton",
    "lat": 43.1065,
    "lng": -89.5058,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 20034,
    "id": 1840002919
  },
  {
    "city": "Ferndale",
    "city_ascii": "Ferndale",
    "lat": 42.4592,
    "lng": -83.1314,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 20033,
    "id": 1840002443
  },
  {
    "city": "Rosamond",
    "city_ascii": "Rosamond",
    "lat": 34.8658,
    "lng": -118.2154,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 20028,
    "id": 1840019140
  },
  {
    "city": "Wetherby",
    "city_ascii": "Wetherby",
    "lat": 53.9276,
    "lng": -1.3839,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Leeds",
    "capital": "",
    "population": 19979,
    "id": 1826431619
  },
  {
    "city": "Brushy Creek",
    "city_ascii": "Brushy Creek",
    "lat": 30.5125,
    "lng": -97.7388,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 20024,
    "id": 1840135494
  },
  {
    "city": "Yeppoon",
    "city_ascii": "Yeppoon",
    "lat": -23.1333,
    "lng": 150.7333,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Queensland",
    "capital": "",
    "population": 19003,
    "id": 1036180170
  },
  {
    "city": "Forest Park",
    "city_ascii": "Forest Park",
    "lat": 33.6209,
    "lng": -84.359,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 20020,
    "id": 1840013750
  },
  {
    "city": "Dęblin",
    "city_ascii": "Deblin",
    "lat": 51.5667,
    "lng": 21.8614,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Lubelskie",
    "capital": "",
    "population": 19513,
    "id": 1616913108
  },
  {
    "city": "Gostynin",
    "city_ascii": "Gostynin",
    "lat": 52.4167,
    "lng": 19.4667,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Mazowieckie",
    "capital": "minor",
    "population": 19414,
    "id": 1616908435
  },
  {
    "city": "Haya",
    "city_ascii": "Haya",
    "lat": 18.3461,
    "lng": 36.3969,
    "country": "Sudan",
    "iso2": "SD",
    "iso3": "SDN",
    "admin_name": "Red Sea",
    "capital": "",
    "population": 20000,
    "id": 1729440799
  },
  {
    "city": "La Cañada Flintridge",
    "city_ascii": "La Canada Flintridge",
    "lat": 34.2097,
    "lng": -118.2002,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 20009,
    "id": 1840020510
  },
  {
    "city": "Al Mijlad",
    "city_ascii": "Al Mijlad",
    "lat": 11.0337,
    "lng": 27.7333,
    "country": "Sudan",
    "iso2": "SD",
    "iso3": "SDN",
    "admin_name": "Western Kordofan",
    "capital": "",
    "population": 19997,
    "id": 1729768355
  },
  {
    "city": "Zemio",
    "city_ascii": "Zemio",
    "lat": 5.0337,
    "lng": 25.1333,
    "country": "Central African Republic",
    "iso2": "CF",
    "iso3": "CAF",
    "admin_name": "Haut-Mbomou",
    "capital": "",
    "population": 20000,
    "id": 1140178462
  },
  {
    "city": "Törökszentmiklós",
    "city_ascii": "Torokszentmiklos",
    "lat": 47.1833,
    "lng": 20.4167,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Jász-Nagykun-Szolnok",
    "capital": "minor",
    "population": 19890,
    "id": 1348803732
  },
  {
    "city": "Neviges",
    "city_ascii": "Neviges",
    "lat": 51.3128,
    "lng": 7.0869,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 18937,
    "id": 1276164931
  },
  {
    "city": "Ennigerloh",
    "city_ascii": "Ennigerloh",
    "lat": 51.8367,
    "lng": 8.0256,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 19829,
    "id": 1276421778
  },
  {
    "city": "Safford",
    "city_ascii": "Safford",
    "lat": 32.8317,
    "lng": -109.7005,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 19987,
    "id": 1840021989
  },
  {
    "city": "Rocky River",
    "city_ascii": "Rocky River",
    "lat": 41.4703,
    "lng": -81.8526,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 19986,
    "id": 1840003410
  },
  {
    "city": "Usman",
    "city_ascii": "Usman",
    "lat": 52.05,
    "lng": 39.7333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Lipetskaya Oblast’",
    "capital": "",
    "population": 19956,
    "id": 1643486876
  },
  {
    "city": "Mpwapwa",
    "city_ascii": "Mpwapwa",
    "lat": -6.35,
    "lng": 36.4833,
    "country": "Tanzania",
    "iso2": "TZ",
    "iso3": "TZA",
    "admin_name": "Dodoma",
    "capital": "",
    "population": 19806,
    "id": 1834235223
  },
  {
    "city": "Heusenstamm",
    "city_ascii": "Heusenstamm",
    "lat": 50.0596,
    "lng": 8.8068,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 18973,
    "id": 1276666927
  },
  {
    "city": "Merrick",
    "city_ascii": "Merrick",
    "lat": 40.6515,
    "lng": -73.5535,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 19966,
    "id": 1840005238
  },
  {
    "city": "Saco",
    "city_ascii": "Saco",
    "lat": 43.539,
    "lng": -70.4624,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maine",
    "capital": "",
    "population": 19964,
    "id": 1840000343
  },
  {
    "city": "West St. Paul",
    "city_ascii": "West St. Paul",
    "lat": 44.9018,
    "lng": -93.0858,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 19961,
    "id": 1840010012
  },
  {
    "city": "Greenwood",
    "city_ascii": "Greenwood",
    "lat": 33.5126,
    "lng": -90.1993,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Mississippi",
    "capital": "",
    "population": 19961,
    "id": 1840013739
  },
  {
    "city": "Mililani Mauka",
    "city_ascii": "Mililani Mauka",
    "lat": 21.4756,
    "lng": -157.9947,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Hawaii",
    "capital": "",
    "population": 19955,
    "id": 1840039224
  },
  {
    "city": "Martha Lake",
    "city_ascii": "Martha Lake",
    "lat": 47.8479,
    "lng": -122.2327,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 19954,
    "id": 1840037875
  },
  {
    "city": "Blankenburg",
    "city_ascii": "Blankenburg",
    "lat": 51.7953,
    "lng": 10.9622,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony-Anhalt",
    "capital": "",
    "population": 19817,
    "id": 1276635033
  },
  {
    "city": "Spennymoor",
    "city_ascii": "Spennymoor",
    "lat": 54.7,
    "lng": -1.59,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Durham",
    "capital": "",
    "population": 19816,
    "id": 1826426455
  },
  {
    "city": "Äänekoski",
    "city_ascii": "Aanekoski",
    "lat": 62.6042,
    "lng": 25.7264,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Keski-Suomi",
    "capital": "minor",
    "population": 19919,
    "id": 1246710490
  },
  {
    "city": "Volketswil",
    "city_ascii": "Volketswil",
    "lat": 47.3906,
    "lng": 8.6953,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Zürich",
    "capital": "",
    "population": 18615,
    "id": 1756554999
  },
  {
    "city": "Mapai",
    "city_ascii": "Mapai",
    "lat": -22.7306,
    "lng": 32.0583,
    "country": "Mozambique",
    "iso2": "MZ",
    "iso3": "MOZ",
    "admin_name": "Gaza",
    "capital": "",
    "population": 19932,
    "id": 1508081096
  },
  {
    "city": "Schopfheim",
    "city_ascii": "Schopfheim",
    "lat": 47.6494,
    "lng": 7.8247,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 19645,
    "id": 1276002233
  },
  {
    "city": "Pradópolis",
    "city_ascii": "Pradopolis",
    "lat": -21.3594,
    "lng": -48.0656,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 19814,
    "id": 1076557864
  },
  {
    "city": "Orinda",
    "city_ascii": "Orinda",
    "lat": 37.8808,
    "lng": -122.1791,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 19926,
    "id": 1840020280
  },
  {
    "city": "Valley",
    "city_ascii": "Valley",
    "lat": 32.8088,
    "lng": -85.1809,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 19926,
    "id": 1840015760
  },
  {
    "city": "Mead Valley",
    "city_ascii": "Mead Valley",
    "lat": 33.8333,
    "lng": -117.2852,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 19925,
    "id": 1840028331
  },
  {
    "city": "Helena",
    "city_ascii": "Helena",
    "lat": 33.2845,
    "lng": -86.8756,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 19925,
    "id": 1840014827
  },
  {
    "city": "Heniches’k",
    "city_ascii": "Heniches'k",
    "lat": 46.1769,
    "lng": 34.7989,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Khersons’ka Oblast’",
    "capital": "minor",
    "population": 19869,
    "id": 1804703441
  },
  {
    "city": "Cullman",
    "city_ascii": "Cullman",
    "lat": 34.1777,
    "lng": -86.8407,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 19920,
    "id": 1840001354
  },
  {
    "city": "Schmalkalden",
    "city_ascii": "Schmalkalden",
    "lat": 50.7167,
    "lng": 10.45,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Thuringia",
    "capital": "",
    "population": 19732,
    "id": 1276313755
  },
  {
    "city": "Eschwege",
    "city_ascii": "Eschwege",
    "lat": 51.1881,
    "lng": 10.0528,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "minor",
    "population": 19606,
    "id": 1276348756
  },
  {
    "city": "Senec",
    "city_ascii": "Senec",
    "lat": 48.2189,
    "lng": 17.3997,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Bratislavský",
    "capital": "minor",
    "population": 19410,
    "id": 1703144516
  },
  {
    "city": "Ban Na San",
    "city_ascii": "Ban Na San",
    "lat": 8.7997,
    "lng": 99.3635,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Surat Thani",
    "capital": "minor",
    "population": 19801,
    "id": 1764021256
  },
  {
    "city": "Alamo",
    "city_ascii": "Alamo",
    "lat": 26.1811,
    "lng": -98.1177,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 19910,
    "id": 1840019732
  },
  {
    "city": "Hazlet",
    "city_ascii": "Hazlet",
    "lat": 40.4265,
    "lng": -74.1711,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 19908,
    "id": 1840081651
  },
  {
    "city": "Sand Springs",
    "city_ascii": "Sand Springs",
    "lat": 36.1342,
    "lng": -96.1284,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oklahoma",
    "capital": "",
    "population": 19905,
    "id": 1840021671
  },
  {
    "city": "Cameron Park",
    "city_ascii": "Cameron Park",
    "lat": 38.6737,
    "lng": -120.9872,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 19902,
    "id": 1840028322
  },
  {
    "city": "White Oak",
    "city_ascii": "White Oak",
    "lat": 39.2106,
    "lng": -84.6061,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 19900,
    "id": 1840034164
  },
  {
    "city": "Nizhnyaya Tura",
    "city_ascii": "Nizhnyaya Tura",
    "lat": 58.6208,
    "lng": 59.8478,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sverdlovskaya Oblast’",
    "capital": "",
    "population": 19883,
    "id": 1643124513
  },
  {
    "city": "Iiyama",
    "city_ascii": "Iiyama",
    "lat": 36.8517,
    "lng": 138.3656,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Nagano",
    "capital": "",
    "population": 19800,
    "id": 1392890981
  },
  {
    "city": "Tiverton",
    "city_ascii": "Tiverton",
    "lat": 50.903,
    "lng": -3.488,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Devon",
    "capital": "",
    "population": 19544,
    "id": 1826587697
  },
  {
    "city": "Vernal",
    "city_ascii": "Vernal",
    "lat": 40.4517,
    "lng": -109.5379,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Utah",
    "capital": "",
    "population": 19893,
    "id": 1840021391
  },
  {
    "city": "Castilho",
    "city_ascii": "Castilho",
    "lat": -20.8722,
    "lng": -51.4875,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 19873,
    "id": 1076553673
  },
  {
    "city": "Laurinburg",
    "city_ascii": "Laurinburg",
    "lat": 34.7597,
    "lng": -79.4781,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 19889,
    "id": 1840014631
  },
  {
    "city": "Ballenger Creek",
    "city_ascii": "Ballenger Creek",
    "lat": 39.3807,
    "lng": -77.4205,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 19889,
    "id": 1840031490
  },
  {
    "city": "Saint-Lazare",
    "city_ascii": "Saint-Lazare",
    "lat": 45.4,
    "lng": -74.1333,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 19889,
    "id": 1124000613
  },
  {
    "city": "Kamiichi",
    "city_ascii": "Kamiichi",
    "lat": 36.7,
    "lng": 137.3667,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Toyama",
    "capital": "",
    "population": 19805,
    "id": 1392722248
  },
  {
    "city": "Chestermere",
    "city_ascii": "Chestermere",
    "lat": 51.05,
    "lng": -113.8225,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Alberta",
    "capital": "",
    "population": 19887,
    "id": 1124000371
  },
  {
    "city": "Painesville",
    "city_ascii": "Painesville",
    "lat": 41.724,
    "lng": -81.2536,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 19886,
    "id": 1840000542
  },
  {
    "city": "Poperinge",
    "city_ascii": "Poperinge",
    "lat": 50.8547,
    "lng": 2.7256,
    "country": "Belgium",
    "iso2": "BE",
    "iso3": "BEL",
    "admin_name": "Flanders",
    "capital": "",
    "population": 19718,
    "id": 1056648990
  },
  {
    "city": "Sherwood",
    "city_ascii": "Sherwood",
    "lat": 45.3593,
    "lng": -122.8433,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 19879,
    "id": 1840021205
  },
  {
    "city": "Lake Zurich",
    "city_ascii": "Lake Zurich",
    "lat": 42.1955,
    "lng": -88.087,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 19877,
    "id": 1840011160
  },
  {
    "city": "Ba",
    "city_ascii": "Ba",
    "lat": -17.5333,
    "lng": 177.6833,
    "country": "Fiji",
    "iso2": "FJ",
    "iso3": "FJI",
    "admin_name": "Ba",
    "capital": "",
    "population": 18526,
    "id": 1242193764
  },
  {
    "city": "El Castillo de La Concepción",
    "city_ascii": "El Castillo de La Concepcion",
    "lat": 11.0178,
    "lng": -84.4011,
    "country": "Nicaragua",
    "iso2": "NI",
    "iso3": "NIC",
    "admin_name": "Río San Juan",
    "capital": "",
    "population": 19864,
    "id": 1558240788
  },
  {
    "city": "Comăneşti",
    "city_ascii": "Comanesti",
    "lat": 46.4132,
    "lng": 26.4362,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Bacău",
    "capital": "",
    "population": 19568,
    "id": 1642511009
  },
  {
    "city": "Gubakha",
    "city_ascii": "Gubakha",
    "lat": 58.8667,
    "lng": 57.5833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Permskiy Kray",
    "capital": "",
    "population": 19831,
    "id": 1643737905
  },
  {
    "city": "Hudson",
    "city_ascii": "Hudson",
    "lat": 42.3887,
    "lng": -71.5465,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 19868,
    "id": 1840053480
  },
  {
    "city": "Ben Gardane",
    "city_ascii": "Ben Gardane",
    "lat": 33.1404,
    "lng": 11.22,
    "country": "Tunisia",
    "iso2": "TN",
    "iso3": "TUN",
    "admin_name": "Médenine",
    "capital": "",
    "population": 19843,
    "id": 1788380441
  },
  {
    "city": "Volokolamsk",
    "city_ascii": "Volokolamsk",
    "lat": 56.0333,
    "lng": 35.95,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Moskovskaya Oblast’",
    "capital": "",
    "population": 19824,
    "id": 1643346099
  },
  {
    "city": "Puerto Armuelles",
    "city_ascii": "Puerto Armuelles",
    "lat": 8.2777,
    "lng": -82.8621,
    "country": "Panama",
    "iso2": "PA",
    "iso3": "PAN",
    "admin_name": "Chiriquí",
    "capital": "minor",
    "population": 19763,
    "id": 1591562054
  },
  {
    "city": "Waldbröl",
    "city_ascii": "Waldbrol",
    "lat": 50.8789,
    "lng": 7.615,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 19543,
    "id": 1276170251
  },
  {
    "city": "General Martín Miguel de Güemes",
    "city_ascii": "General Martin Miguel de Guemes",
    "lat": -24.6667,
    "lng": -65.05,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Salta",
    "capital": "minor",
    "population": 19828,
    "id": 1032716507
  },
  {
    "city": "Scarborough",
    "city_ascii": "Scarborough",
    "lat": 43.5911,
    "lng": -70.3682,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maine",
    "capital": "",
    "population": 19844,
    "id": 1840052990
  },
  {
    "city": "Camacupa",
    "city_ascii": "Camacupa",
    "lat": -12.0196,
    "lng": 17.47,
    "country": "Angola",
    "iso2": "AO",
    "iso3": "AGO",
    "admin_name": "Bié",
    "capital": "",
    "population": 19828,
    "id": 1024037086
  },
  {
    "city": "Ottawa",
    "city_ascii": "Ottawa",
    "lat": 41.3532,
    "lng": -88.8306,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 19839,
    "id": 1840009272
  },
  {
    "city": "Bourne",
    "city_ascii": "Bourne",
    "lat": 41.723,
    "lng": -70.5819,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 19839,
    "id": 1840053642
  },
  {
    "city": "Lerdo de Tejada",
    "city_ascii": "Lerdo de Tejada",
    "lat": 18.638,
    "lng": -95.5085,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Veracruz",
    "capital": "minor",
    "population": 19606,
    "id": 1484800186
  },
  {
    "city": "Sil-li",
    "city_ascii": "Sil-li",
    "lat": 39.488,
    "lng": 125.464,
    "country": "Korea, North",
    "iso2": "KP",
    "iso3": "PRK",
    "admin_name": "P’yŏngnam",
    "capital": "",
    "population": 19463,
    "id": 1408623890
  },
  {
    "city": "Itigi",
    "city_ascii": "Itigi",
    "lat": -5.6996,
    "lng": 34.48,
    "country": "Tanzania",
    "iso2": "TZ",
    "iso3": "TZA",
    "admin_name": "Singida",
    "capital": "",
    "population": 19711,
    "id": 1834524806
  },
  {
    "city": "Barrhead",
    "city_ascii": "Barrhead",
    "lat": 55.8016,
    "lng": -4.388,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "East Renfrewshire",
    "capital": "",
    "population": 19813,
    "id": 1826311398
  },
  {
    "city": "Ochtrup",
    "city_ascii": "Ochtrup",
    "lat": 52.2056,
    "lng": 7.1903,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 19636,
    "id": 1276195349
  },
  {
    "city": "Huntsville",
    "city_ascii": "Huntsville",
    "lat": 45.3333,
    "lng": -79.2167,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 19816,
    "id": 1124961145
  },
  {
    "city": "Sulzbach-Rosenberg",
    "city_ascii": "Sulzbach-Rosenberg",
    "lat": 49.5,
    "lng": 11.75,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 19414,
    "id": 1276541212
  },
  {
    "city": "Gōdo",
    "city_ascii": "Godo",
    "lat": 35.4175,
    "lng": 136.6086,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Gifu",
    "capital": "",
    "population": 18812,
    "id": 1392043863
  },
  {
    "city": "Sarstedt",
    "city_ascii": "Sarstedt",
    "lat": 52.2394,
    "lng": 9.8606,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 19359,
    "id": 1276490791
  },
  {
    "city": "Corner Brook",
    "city_ascii": "Corner Brook",
    "lat": 48.9287,
    "lng": -57.926,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Newfoundland and Labrador",
    "capital": "",
    "population": 19806,
    "id": 1124244792
  },
  {
    "city": "Mölln",
    "city_ascii": "Molln",
    "lat": 53.6269,
    "lng": 10.6847,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Schleswig-Holstein",
    "capital": "",
    "population": 19031,
    "id": 1276643502
  },
  {
    "city": "Parma Heights",
    "city_ascii": "Parma Heights",
    "lat": 41.3865,
    "lng": -81.7637,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 19790,
    "id": 1840003407
  },
  {
    "city": "Nyandoma",
    "city_ascii": "Nyandoma",
    "lat": 61.6667,
    "lng": 40.2,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Arkhangel’skaya Oblast’",
    "capital": "",
    "population": 19783,
    "id": 1643011175
  },
  {
    "city": "Karcag",
    "city_ascii": "Karcag",
    "lat": 47.3167,
    "lng": 20.9333,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Jász-Nagykun-Szolnok",
    "capital": "minor",
    "population": 19732,
    "id": 1348514059
  },
  {
    "city": "Monroe",
    "city_ascii": "Monroe",
    "lat": 41.3043,
    "lng": -74.1941,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 19781,
    "id": 1840004874
  },
  {
    "city": "Mino",
    "city_ascii": "Mino",
    "lat": 35.5447,
    "lng": 136.9075,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Gifu",
    "capital": "",
    "population": 19610,
    "id": 1392884993
  },
  {
    "city": "Maḩmūdābād Nemūneh",
    "city_ascii": "Mahmudabad Nemuneh",
    "lat": 36.2886,
    "lng": 49.9019,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Qazvīn",
    "capital": "",
    "population": 19669,
    "id": 1364599190
  },
  {
    "city": "Monroe",
    "city_ascii": "Monroe",
    "lat": 47.8595,
    "lng": -121.9852,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 19776,
    "id": 1840019791
  },
  {
    "city": "Selma",
    "city_ascii": "Selma",
    "lat": 32.4166,
    "lng": -87.0336,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 19775,
    "id": 1840008329
  },
  {
    "city": "Tahlequah",
    "city_ascii": "Tahlequah",
    "lat": 35.9116,
    "lng": -94.9773,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oklahoma",
    "capital": "",
    "population": 19773,
    "id": 1840021700
  },
  {
    "city": "Selden",
    "city_ascii": "Selden",
    "lat": 40.8699,
    "lng": -73.0462,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 19770,
    "id": 1840005093
  },
  {
    "city": "Mukaiengaru",
    "city_ascii": "Mukaiengaru",
    "lat": 44.0617,
    "lng": 143.5281,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hokkaidō",
    "capital": "",
    "population": 19751,
    "id": 1392295028
  },
  {
    "city": "Brockport",
    "city_ascii": "Brockport",
    "lat": 43.2137,
    "lng": -77.9404,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 19764,
    "id": 1840004287
  },
  {
    "city": "Calvillo",
    "city_ascii": "Calvillo",
    "lat": 21.85,
    "lng": -102.7167,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Aguascalientes",
    "capital": "minor",
    "population": 19742,
    "id": 1484468847
  },
  {
    "city": "Southbury",
    "city_ascii": "Southbury",
    "lat": 41.4745,
    "lng": -73.2329,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 19754,
    "id": 1840035599
  },
  {
    "city": "Marsberg",
    "city_ascii": "Marsberg",
    "lat": 51.45,
    "lng": 8.85,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 19640,
    "id": 1276309670
  },
  {
    "city": "Rockport",
    "city_ascii": "Rockport",
    "lat": 28.029,
    "lng": -97.0722,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 19743,
    "id": 1840020999
  },
  {
    "city": "Regente Feijó",
    "city_ascii": "Regente Feijo",
    "lat": -22.2208,
    "lng": -51.3028,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 19733,
    "id": 1076508194
  },
  {
    "city": "Várpalota",
    "city_ascii": "Varpalota",
    "lat": 47.1972,
    "lng": 18.1394,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Veszprém",
    "capital": "minor",
    "population": 19484,
    "id": 1348052599
  },
  {
    "city": "Río Segundo",
    "city_ascii": "Rio Segundo",
    "lat": -31.6501,
    "lng": -63.9116,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Córdoba",
    "capital": "",
    "population": 19713,
    "id": 1032477999
  },
  {
    "city": "Oschersleben",
    "city_ascii": "Oschersleben",
    "lat": 52.0167,
    "lng": 11.25,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony-Anhalt",
    "capital": "",
    "population": 19630,
    "id": 1276686337
  },
  {
    "city": "Cherry Hill",
    "city_ascii": "Cherry Hill",
    "lat": 38.5696,
    "lng": -77.2895,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 19731,
    "id": 1840026712
  },
  {
    "city": "Rybnoye",
    "city_ascii": "Rybnoye",
    "lat": 54.7333,
    "lng": 39.5167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Ryazanskaya Oblast’",
    "capital": "",
    "population": 19700,
    "id": 1643910725
  },
  {
    "city": "Haslett",
    "city_ascii": "Haslett",
    "lat": 42.7525,
    "lng": -84.402,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 19721,
    "id": 1840004500
  },
  {
    "city": "Tash-Kömür",
    "city_ascii": "Tash-Komur",
    "lat": 41.35,
    "lng": 72.2167,
    "country": "Kyrgyzstan",
    "iso2": "KG",
    "iso3": "KGZ",
    "admin_name": "Jalal-Abad",
    "capital": "",
    "population": 19310,
    "id": 1417242124
  },
  {
    "city": "Front Royal",
    "city_ascii": "Front Royal",
    "lat": 38.926,
    "lng": -78.1838,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 19719,
    "id": 1840006045
  },
  {
    "city": "Eupen",
    "city_ascii": "Eupen",
    "lat": 50.6333,
    "lng": 6.0333,
    "country": "Belgium",
    "iso2": "BE",
    "iso3": "BEL",
    "admin_name": "Wallonia",
    "capital": "",
    "population": 19526,
    "id": 1056584006
  },
  {
    "city": "Babanūsah",
    "city_ascii": "Babanusah",
    "lat": 11.3334,
    "lng": 27.8,
    "country": "Sudan",
    "iso2": "SD",
    "iso3": "SDN",
    "admin_name": "Western Kordofan",
    "capital": "",
    "population": 19700,
    "id": 1729601692
  },
  {
    "city": "Kufstein",
    "city_ascii": "Kufstein",
    "lat": 47.5833,
    "lng": 12.1667,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Tirol",
    "capital": "minor",
    "population": 19223,
    "id": 1040156374
  },
  {
    "city": "West Mifflin",
    "city_ascii": "West Mifflin",
    "lat": 40.3581,
    "lng": -79.9072,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 19699,
    "id": 1840001273
  },
  {
    "city": "Jakobstad",
    "city_ascii": "Jakobstad",
    "lat": 63.6667,
    "lng": 22.7,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Pohjanmaa",
    "capital": "minor",
    "population": 19436,
    "id": 1246775062
  },
  {
    "city": "South Whitehall",
    "city_ascii": "South Whitehall",
    "lat": 40.6154,
    "lng": -75.5503,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 19686,
    "id": 1840148586
  },
  {
    "city": "Somerton",
    "city_ascii": "Somerton",
    "lat": 32.6009,
    "lng": -114.699,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 19684,
    "id": 1840021997
  },
  {
    "city": "Nakło nad Notecią",
    "city_ascii": "Naklo nad Notecia",
    "lat": 53.1421,
    "lng": 17.6018,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Kujawsko-Pomorskie",
    "capital": "minor",
    "population": 19507,
    "id": 1616313608
  },
  {
    "city": "Dharmsāla",
    "city_ascii": "Dharmsala",
    "lat": 32.2167,
    "lng": 76.3167,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Himāchal Pradesh",
    "capital": "",
    "population": 19034,
    "id": 1356618034
  },
  {
    "city": "Žiar nad Hronom",
    "city_ascii": "Ziar nad Hronom",
    "lat": 48.5919,
    "lng": 18.8533,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Banskobystrický",
    "capital": "minor",
    "population": 19188,
    "id": 1703196559
  },
  {
    "city": "Riverview",
    "city_ascii": "Riverview",
    "lat": 46.0613,
    "lng": -64.8052,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "New Brunswick",
    "capital": "",
    "population": 19667,
    "id": 1124000112
  },
  {
    "city": "Sandbach",
    "city_ascii": "Sandbach",
    "lat": 53.146,
    "lng": -2.367,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Cheshire East",
    "capital": "",
    "population": 17976,
    "id": 1826911682
  },
  {
    "city": "Yahotyn",
    "city_ascii": "Yahotyn",
    "lat": 50.2569,
    "lng": 31.7817,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Kyyivs’ka Oblast’",
    "capital": "minor",
    "population": 19613,
    "id": 1804447594
  },
  {
    "city": "Wangaratta",
    "city_ascii": "Wangaratta",
    "lat": -36.3583,
    "lng": 146.3125,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Victoria",
    "capital": "",
    "population": 19318,
    "id": 1036395326
  },
  {
    "city": "Momchilgrad",
    "city_ascii": "Momchilgrad",
    "lat": 41.5272,
    "lng": 25.4075,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Kardzhali",
    "capital": "",
    "population": 19609,
    "id": 1100063207
  },
  {
    "city": "Weil der Stadt",
    "city_ascii": "Weil der Stadt",
    "lat": 48.7508,
    "lng": 8.8706,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 19205,
    "id": 1276534554
  },
  {
    "city": "Lloydminster",
    "city_ascii": "Lloydminster",
    "lat": 53.2807,
    "lng": -110.035,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Alberta",
    "capital": "",
    "population": 19645,
    "id": 1124000858
  },
  {
    "city": "Burghausen",
    "city_ascii": "Burghausen",
    "lat": 48.1667,
    "lng": 12.8333,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 18701,
    "id": 1276305755
  },
  {
    "city": "Keszthely",
    "city_ascii": "Keszthely",
    "lat": 46.7675,
    "lng": 17.2463,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Zala",
    "capital": "minor",
    "population": 19387,
    "id": 1348430852
  },
  {
    "city": "Horizon City",
    "city_ascii": "Horizon City",
    "lat": 31.6799,
    "lng": -106.1903,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 19642,
    "id": 1840020805
  },
  {
    "city": "Narni",
    "city_ascii": "Narni",
    "lat": 42.5167,
    "lng": 12.5167,
    "country": "Italy",
    "iso2": "IT",
    "iso3": "ITA",
    "admin_name": "Umbria",
    "capital": "",
    "population": 19543,
    "id": 1380161361
  },
  {
    "city": "Springfield Township",
    "city_ascii": "Springfield Township",
    "lat": 40.0986,
    "lng": -75.2016,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 19639,
    "id": 1840152388
  },
  {
    "city": "Montgomery",
    "city_ascii": "Montgomery",
    "lat": 41.7237,
    "lng": -88.3631,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 19638,
    "id": 1840011350
  },
  {
    "city": "Casa de Oro-Mount Helix",
    "city_ascii": "Casa de Oro-Mount Helix",
    "lat": 32.764,
    "lng": -116.9687,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 19635,
    "id": 1840073904
  },
  {
    "city": "Norton",
    "city_ascii": "Norton",
    "lat": 41.964,
    "lng": -71.1842,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 19634,
    "id": 1840070386
  },
  {
    "city": "Rāwah",
    "city_ascii": "Rawah",
    "lat": 34.4792,
    "lng": 41.9083,
    "country": "Iraq",
    "iso2": "IQ",
    "iso3": "IRQ",
    "admin_name": "Al Anbār",
    "capital": "minor",
    "population": 19629,
    "id": 1368442139
  },
  {
    "city": "Ban Bueng",
    "city_ascii": "Ban Bueng",
    "lat": 13.314,
    "lng": 101.1114,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Chon Buri",
    "capital": "minor",
    "population": 19346,
    "id": 1764134032
  },
  {
    "city": "Stillwater",
    "city_ascii": "Stillwater",
    "lat": 45.0573,
    "lng": -92.8313,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 19627,
    "id": 1840008914
  },
  {
    "city": "Cherepanovo",
    "city_ascii": "Cherepanovo",
    "lat": 54.2167,
    "lng": 83.3667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Novosibirskaya Oblast’",
    "capital": "",
    "population": 19610,
    "id": 1643646272
  },
  {
    "city": "Eloy",
    "city_ascii": "Eloy",
    "lat": 32.747,
    "lng": -111.5991,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 19625,
    "id": 1840020628
  },
  {
    "city": "Monroe",
    "city_ascii": "Monroe",
    "lat": 41.3379,
    "lng": -73.225,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 19621,
    "id": 1840035488
  },
  {
    "city": "Joliette",
    "city_ascii": "Joliette",
    "lat": 46.0167,
    "lng": -73.45,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 19621,
    "id": 1124841554
  },
  {
    "city": "Atasū",
    "city_ascii": "Atasu",
    "lat": 48.6903,
    "lng": 71.6499,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Qaraghandy",
    "capital": "",
    "population": 19616,
    "id": 1398926180
  },
  {
    "city": "Hechingen",
    "city_ascii": "Hechingen",
    "lat": 48.3517,
    "lng": 8.9633,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 19324,
    "id": 1276884131
  },
  {
    "city": "Rožňava",
    "city_ascii": "Roznava",
    "lat": 48.6586,
    "lng": 20.5314,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Košický",
    "capital": "minor",
    "population": 19190,
    "id": 1703307007
  },
  {
    "city": "Upper Allen",
    "city_ascii": "Upper Allen",
    "lat": 40.1801,
    "lng": -76.9807,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 19606,
    "id": 1840151634
  },
  {
    "city": "Rio Rico",
    "city_ascii": "Rio Rico",
    "lat": 31.4957,
    "lng": -110.9886,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 19601,
    "id": 1840027004
  },
  {
    "city": "Brigham City",
    "city_ascii": "Brigham City",
    "lat": 41.5034,
    "lng": -112.0454,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Utah",
    "capital": "",
    "population": 19601,
    "id": 1840018702
  },
  {
    "city": "Sukumo",
    "city_ascii": "Sukumo",
    "lat": 32.9389,
    "lng": 132.7261,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Kōchi",
    "capital": "",
    "population": 19532,
    "id": 1392912259
  },
  {
    "city": "Yershov",
    "city_ascii": "Yershov",
    "lat": 51.35,
    "lng": 48.2833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Saratovskaya Oblast’",
    "capital": "",
    "population": 19590,
    "id": 1643218689
  },
  {
    "city": "Hemmingen",
    "city_ascii": "Hemmingen",
    "lat": 52.3236,
    "lng": 9.7256,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 18998,
    "id": 1276579217
  },
  {
    "city": "Heinola",
    "city_ascii": "Heinola",
    "lat": 61.2028,
    "lng": 26.0319,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Päijät-Häme",
    "capital": "minor",
    "population": 19575,
    "id": 1246846423
  },
  {
    "city": "Capela do Alto",
    "city_ascii": "Capela do Alto",
    "lat": -23.4706,
    "lng": -47.7347,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 19482,
    "id": 1076545027
  },
  {
    "city": "Upper St. Clair",
    "city_ascii": "Upper St. Clair",
    "lat": 40.3336,
    "lng": -80.0842,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 19592,
    "id": 1840005397
  },
  {
    "city": "Murrysville",
    "city_ascii": "Murrysville",
    "lat": 40.4456,
    "lng": -79.6555,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 19590,
    "id": 1840001148
  },
  {
    "city": "Candelaria",
    "city_ascii": "Candelaria",
    "lat": 22.7439,
    "lng": -82.9581,
    "country": "Cuba",
    "iso2": "CU",
    "iso3": "CUB",
    "admin_name": "Artemisa",
    "capital": "minor",
    "population": 19523,
    "id": 1192962484
  },
  {
    "city": "Pidhorodne",
    "city_ascii": "Pidhorodne",
    "lat": 48.5737,
    "lng": 35.0966,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Dnipropetrovs’ka Oblast’",
    "capital": "",
    "population": 19524,
    "id": 1804891177
  },
  {
    "city": "Prunedale",
    "city_ascii": "Prunedale",
    "lat": 36.8064,
    "lng": -121.6555,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 19584,
    "id": 1840019020
  },
  {
    "city": "Brainerd",
    "city_ascii": "Brainerd",
    "lat": 46.3553,
    "lng": -94.1982,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 19583,
    "id": 1840006644
  },
  {
    "city": "Hauppauge",
    "city_ascii": "Hauppauge",
    "lat": 40.8217,
    "lng": -73.2119,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 19580,
    "id": 1840005077
  },
  {
    "city": "Rhede",
    "city_ascii": "Rhede",
    "lat": 51.8333,
    "lng": 6.7006,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 19328,
    "id": 1276000350
  },
  {
    "city": "Yellowknife",
    "city_ascii": "Yellowknife",
    "lat": 62.4709,
    "lng": -114.4053,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Northwest Territories",
    "capital": "",
    "population": 19569,
    "id": 1124208917
  },
  {
    "city": "Essen",
    "city_ascii": "Essen",
    "lat": 51.4678,
    "lng": 4.47,
    "country": "Belgium",
    "iso2": "BE",
    "iso3": "BEL",
    "admin_name": "Flanders",
    "capital": "",
    "population": 19162,
    "id": 1056694919
  },
  {
    "city": "Haiger",
    "city_ascii": "Haiger",
    "lat": 50.7422,
    "lng": 8.2039,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 19378,
    "id": 1276810840
  },
  {
    "city": "Töle Bī",
    "city_ascii": "Tole Bi",
    "lat": 43.6808,
    "lng": 73.7628,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Zhambyl",
    "capital": "",
    "population": 19551,
    "id": 1398035809
  },
  {
    "city": "Syosset",
    "city_ascii": "Syosset",
    "lat": 40.8156,
    "lng": -73.502,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 19559,
    "id": 1840005278
  },
  {
    "city": "Stowmarket",
    "city_ascii": "Stowmarket",
    "lat": 52.19,
    "lng": 1,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Suffolk",
    "capital": "",
    "population": 19280,
    "id": 1826085574
  },
  {
    "city": "Baldwin",
    "city_ascii": "Baldwin",
    "lat": 40.369,
    "lng": -79.9668,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 19554,
    "id": 1840001201
  },
  {
    "city": "Monkseaton",
    "city_ascii": "Monkseaton",
    "lat": 55.043,
    "lng": -1.459,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "North Tyneside",
    "capital": "",
    "population": 19044,
    "id": 1826239698
  },
  {
    "city": "Bethel",
    "city_ascii": "Bethel",
    "lat": 41.3747,
    "lng": -73.3928,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 19551,
    "id": 1840034129
  },
  {
    "city": "Tapalpa",
    "city_ascii": "Tapalpa",
    "lat": 19.9445,
    "lng": -103.7585,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Jalisco",
    "capital": "minor",
    "population": 19506,
    "id": 1484280338
  },
  {
    "city": "Terek",
    "city_ascii": "Terek",
    "lat": 43.4833,
    "lng": 44.1333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kabardino-Balkariya",
    "capital": "",
    "population": 19494,
    "id": 1643162208
  },
  {
    "city": "Beloyarskiy",
    "city_ascii": "Beloyarskiy",
    "lat": 63.7167,
    "lng": 66.6667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Khanty-Mansiyskiy Avtonomnyy Okrug-Yugra",
    "capital": "",
    "population": 19542,
    "id": 1643836632
  },
  {
    "city": "Norak",
    "city_ascii": "Norak",
    "lat": 38.3883,
    "lng": 69.325,
    "country": "Tajikistan",
    "iso2": "TJ",
    "iso3": "TJK",
    "admin_name": "Khatlon",
    "capital": "minor",
    "population": 18950,
    "id": 1762774503
  },
  {
    "city": "Anna",
    "city_ascii": "Anna",
    "lat": 33.347,
    "lng": -96.5503,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 19541,
    "id": 1840019397
  },
  {
    "city": "Kos",
    "city_ascii": "Kos",
    "lat": 36.8442,
    "lng": 27.1572,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Notío Aigaío",
    "capital": "minor",
    "population": 19244,
    "id": 1300683758
  },
  {
    "city": "Borna",
    "city_ascii": "Borna",
    "lat": 51.1167,
    "lng": 12.5,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 19229,
    "id": 1276020510
  },
  {
    "city": "Meadowbrook",
    "city_ascii": "Meadowbrook",
    "lat": 37.4301,
    "lng": -77.474,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 19532,
    "id": 1840024786
  },
  {
    "city": "Bad Aibling",
    "city_ascii": "Bad Aibling",
    "lat": 47.865,
    "lng": 12.0097,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 19100,
    "id": 1276844408
  },
  {
    "city": "Seesen",
    "city_ascii": "Seesen",
    "lat": 51.8931,
    "lng": 10.1783,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 19340,
    "id": 1276914120
  },
  {
    "city": "Danville",
    "city_ascii": "Danville",
    "lat": 37.6418,
    "lng": -84.7777,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kentucky",
    "capital": "",
    "population": 19529,
    "id": 1840014354
  },
  {
    "city": "Szigethalom",
    "city_ascii": "Szigethalom",
    "lat": 47.3228,
    "lng": 19.0128,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Pest",
    "capital": "",
    "population": 17596,
    "id": 1348568337
  },
  {
    "city": "Bückeburg",
    "city_ascii": "Buckeburg",
    "lat": 52.2608,
    "lng": 9.0492,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 19245,
    "id": 1276508515
  },
  {
    "city": "Romsey",
    "city_ascii": "Romsey",
    "lat": 50.989,
    "lng": -1.4966,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hampshire",
    "capital": "",
    "population": 19441,
    "id": 1826793384
  },
  {
    "city": "Bad Berleburg",
    "city_ascii": "Bad Berleburg",
    "lat": 51.0497,
    "lng": 8.4,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 19446,
    "id": 1276128056
  },
  {
    "city": "Squamish",
    "city_ascii": "Squamish",
    "lat": 49.7017,
    "lng": -123.1589,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "British Columbia",
    "capital": "",
    "population": 19512,
    "id": 1124005958
  },
  {
    "city": "Shimomura",
    "city_ascii": "Shimomura",
    "lat": 36.0697,
    "lng": 138.0803,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Nagano",
    "capital": "",
    "population": 19224,
    "id": 1392835805
  },
  {
    "city": "Zossen",
    "city_ascii": "Zossen",
    "lat": 52.2167,
    "lng": 13.4497,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Brandenburg",
    "capital": "",
    "population": 19403,
    "id": 1276905478
  },
  {
    "city": "South Burlington",
    "city_ascii": "South Burlington",
    "lat": 44.4622,
    "lng": -73.2203,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Vermont",
    "capital": "",
    "population": 19509,
    "id": 1840002311
  },
  {
    "city": "Kovylkino",
    "city_ascii": "Kovylkino",
    "lat": 54.0333,
    "lng": 43.9167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Mordoviya",
    "capital": "",
    "population": 19488,
    "id": 1643328958
  },
  {
    "city": "Kempston",
    "city_ascii": "Kempston",
    "lat": 52.114,
    "lng": -0.497,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Bedford",
    "capital": "",
    "population": 19440,
    "id": 1826007274
  },
  {
    "city": "Traiskirchen",
    "city_ascii": "Traiskirchen",
    "lat": 48.0189,
    "lng": 16.2922,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Niederösterreich",
    "capital": "",
    "population": 18858,
    "id": 1040581289
  },
  {
    "city": "Regensdorf",
    "city_ascii": "Regensdorf",
    "lat": 47.4383,
    "lng": 8.4744,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Zürich",
    "capital": "",
    "population": 18253,
    "id": 1756820070
  },
  {
    "city": "North Liberty",
    "city_ascii": "North Liberty",
    "lat": 41.7438,
    "lng": -91.611,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Iowa",
    "capital": "",
    "population": 19501,
    "id": 1840008179
  },
  {
    "city": "Vincennes",
    "city_ascii": "Vincennes",
    "lat": 38.676,
    "lng": -87.5102,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 19498,
    "id": 1840010730
  },
  {
    "city": "Ardon",
    "city_ascii": "Ardon",
    "lat": 43.1667,
    "lng": 44.2833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "North Ossetia",
    "capital": "",
    "population": 19412,
    "id": 1643992333
  },
  {
    "city": "Žatec",
    "city_ascii": "Zatec",
    "lat": 50.3273,
    "lng": 13.5459,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Ústecký Kraj",
    "capital": "",
    "population": 19047,
    "id": 1203368176
  },
  {
    "city": "Kantharalak",
    "city_ascii": "Kantharalak",
    "lat": 14.6536,
    "lng": 104.6278,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Sisaket",
    "capital": "minor",
    "population": 19392,
    "id": 1764009547
  },
  {
    "city": "East Dereham",
    "city_ascii": "East Dereham",
    "lat": 52.681,
    "lng": 0.94,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Norfolk",
    "capital": "",
    "population": 18609,
    "id": 1826674740
  },
  {
    "city": "Mayen",
    "city_ascii": "Mayen",
    "lat": 50.3333,
    "lng": 7.2167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Rhineland-Palatinate",
    "capital": "",
    "population": 19144,
    "id": 1276174509
  },
  {
    "city": "Abbeville",
    "city_ascii": "Abbeville",
    "lat": 29.975,
    "lng": -92.1265,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 19470,
    "id": 1840014005
  },
  {
    "city": "Vila Real de Santo António",
    "city_ascii": "Vila Real de Santo Antonio",
    "lat": 37.2,
    "lng": -7.4167,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Faro",
    "capital": "minor",
    "population": 19156,
    "id": 1620662816
  },
  {
    "city": "Tettnang",
    "city_ascii": "Tettnang",
    "lat": 47.6708,
    "lng": 9.5875,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 19198,
    "id": 1276775320
  },
  {
    "city": "Lodeynoye Pole",
    "city_ascii": "Lodeynoye Pole",
    "lat": 60.7333,
    "lng": 33.55,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Leningradskaya Oblast’",
    "capital": "",
    "population": 19458,
    "id": 1643012084
  },
  {
    "city": "Bourbonnais",
    "city_ascii": "Bourbonnais",
    "lat": 41.183,
    "lng": -87.878,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 19462,
    "id": 1840011692
  },
  {
    "city": "Starodub",
    "city_ascii": "Starodub",
    "lat": 52.5833,
    "lng": 32.7667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Bryanskaya Oblast’",
    "capital": "",
    "population": 18615,
    "id": 1643347111
  },
  {
    "city": "Müllheim",
    "city_ascii": "Mullheim",
    "lat": 47.8083,
    "lng": 7.6308,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 19127,
    "id": 1276925185
  },
  {
    "city": "Apollo Beach",
    "city_ascii": "Apollo Beach",
    "lat": 27.7618,
    "lng": -82.4002,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 19457,
    "id": 1840014149
  },
  {
    "city": "Ramsbottom",
    "city_ascii": "Ramsbottom",
    "lat": 53.6484,
    "lng": -2.3169,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Bury",
    "capital": "",
    "population": 17872,
    "id": 1826959328
  },
  {
    "city": "Łańcut",
    "city_ascii": "Lancut",
    "lat": 50.0687,
    "lng": 22.2291,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Podkarpackie",
    "capital": "minor",
    "population": 17749,
    "id": 1616335382
  },
  {
    "city": "Matteson",
    "city_ascii": "Matteson",
    "lat": 41.5095,
    "lng": -87.7468,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 19448,
    "id": 1840011293
  },
  {
    "city": "Rivière-du-Loup",
    "city_ascii": "Riviere-du-Loup",
    "lat": 47.8333,
    "lng": -69.5333,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 19447,
    "id": 1124662123
  },
  {
    "city": "Lake Forest",
    "city_ascii": "Lake Forest",
    "lat": 42.238,
    "lng": -87.8596,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 19446,
    "id": 1840008087
  },
  {
    "city": "Pariquera-Açu",
    "city_ascii": "Pariquera-Acu",
    "lat": -24.715,
    "lng": -47.8811,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 19391,
    "id": 1076959622
  },
  {
    "city": "Cobourg",
    "city_ascii": "Cobourg",
    "lat": 43.9667,
    "lng": -78.1667,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 19440,
    "id": 1124831257
  },
  {
    "city": "Old Jamestown",
    "city_ascii": "Old Jamestown",
    "lat": 38.8394,
    "lng": -90.2847,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 19439,
    "id": 1840039632
  },
  {
    "city": "Kolbermoor",
    "city_ascii": "Kolbermoor",
    "lat": 47.85,
    "lng": 12.0667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 18505,
    "id": 1276583234
  },
  {
    "city": "Dalanzadgad",
    "city_ascii": "Dalanzadgad",
    "lat": 43.57,
    "lng": 104.4258,
    "country": "Mongolia",
    "iso2": "MN",
    "iso3": "MNG",
    "admin_name": "Ömnögovĭ",
    "capital": "minor",
    "population": 19396,
    "id": 1496544358
  },
  {
    "city": "Vellmar",
    "city_ascii": "Vellmar",
    "lat": 51.3588,
    "lng": 9.4677,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 18134,
    "id": 1276293369
  },
  {
    "city": "Angleton",
    "city_ascii": "Angleton",
    "lat": 29.1718,
    "lng": -95.4291,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 19431,
    "id": 1840019672
  },
  {
    "city": "Fairwood",
    "city_ascii": "Fairwood",
    "lat": 47.4467,
    "lng": -122.143,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 19428,
    "id": 1840037517
  },
  {
    "city": "Bad Tölz",
    "city_ascii": "Bad Tolz",
    "lat": 47.7603,
    "lng": 11.5567,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "minor",
    "population": 18802,
    "id": 1276892151
  },
  {
    "city": "Pułtusk",
    "city_ascii": "Pultusk",
    "lat": 52.7025,
    "lng": 21.0828,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Mazowieckie",
    "capital": "minor",
    "population": 19229,
    "id": 1616411619
  },
  {
    "city": "Dorchester",
    "city_ascii": "Dorchester",
    "lat": 50.7154,
    "lng": -2.4367,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Dorset",
    "capital": "",
    "population": 19060,
    "id": 1826909037
  },
  {
    "city": "Bad Pyrmont",
    "city_ascii": "Bad Pyrmont",
    "lat": 51.9867,
    "lng": 9.2636,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 19090,
    "id": 1276800450
  },
  {
    "city": "Canby",
    "city_ascii": "Canby",
    "lat": 45.2653,
    "lng": -122.6866,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 19393,
    "id": 1840018569
  },
  {
    "city": "Balta",
    "city_ascii": "Balta",
    "lat": 47.9361,
    "lng": 29.6225,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Odes’ka Oblast’",
    "capital": "minor",
    "population": 19353,
    "id": 1804082783
  },
  {
    "city": "Juquiá",
    "city_ascii": "Juquia",
    "lat": -24.3208,
    "lng": -47.6347,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 19359,
    "id": 1076000755
  },
  {
    "city": "Wendell",
    "city_ascii": "Wendell",
    "lat": 35.7823,
    "lng": -78.3962,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 19382,
    "id": 1840016200
  },
  {
    "city": "San Vito dei Normanni",
    "city_ascii": "San Vito dei Normanni",
    "lat": 40.6556,
    "lng": 17.7036,
    "country": "Italy",
    "iso2": "IT",
    "iso3": "ITA",
    "admin_name": "Puglia",
    "capital": "",
    "population": 19095,
    "id": 1380148507
  },
  {
    "city": "Wittlich",
    "city_ascii": "Wittlich",
    "lat": 49.9869,
    "lng": 6.8897,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Rhineland-Palatinate",
    "capital": "minor",
    "population": 18995,
    "id": 1276069973
  },
  {
    "city": "Monte Azul Paulista",
    "city_ascii": "Monte Azul Paulista",
    "lat": -20.9072,
    "lng": -48.6414,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 19304,
    "id": 1076549080
  },
  {
    "city": "Paranapanema",
    "city_ascii": "Paranapanema",
    "lat": -23.3886,
    "lng": -48.7228,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 19357,
    "id": 1076413704
  },
  {
    "city": "Izra‘",
    "city_ascii": "Izra`",
    "lat": 32.8567,
    "lng": 36.2469,
    "country": "Syria",
    "iso2": "SY",
    "iso3": "SYR",
    "admin_name": "Dar‘ā",
    "capital": "minor",
    "population": 19158,
    "id": 1760987853
  },
  {
    "city": "Frankfort",
    "city_ascii": "Frankfort",
    "lat": 41.4887,
    "lng": -87.8361,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 19373,
    "id": 1840011486
  },
  {
    "city": "Laconia",
    "city_ascii": "Laconia",
    "lat": 43.5724,
    "lng": -71.4775,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Hampshire",
    "capital": "",
    "population": 19371,
    "id": 1840002683
  },
  {
    "city": "Haldensleben",
    "city_ascii": "Haldensleben",
    "lat": 52.2833,
    "lng": 11.4167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony-Anhalt",
    "capital": "minor",
    "population": 19247,
    "id": 1276371853
  },
  {
    "city": "Holtsville",
    "city_ascii": "Holtsville",
    "lat": 40.8124,
    "lng": -73.0447,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 19365,
    "id": 1840005079
  },
  {
    "city": "Stevenson Ranch",
    "city_ascii": "Stevenson Ranch",
    "lat": 34.3894,
    "lng": -118.5885,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 19364,
    "id": 1840076281
  },
  {
    "city": "Bergneustadt",
    "city_ascii": "Bergneustadt",
    "lat": 51.0333,
    "lng": 7.65,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 18865,
    "id": 1276283829
  },
  {
    "city": "Karuizawa",
    "city_ascii": "Karuizawa",
    "lat": 36.3483,
    "lng": 138.5969,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Gunma",
    "capital": "",
    "population": 19234,
    "id": 1392706783
  },
  {
    "city": "Motomachi",
    "city_ascii": "Motomachi",
    "lat": 43.8236,
    "lng": 144.1072,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hokkaidō",
    "capital": "",
    "population": 19300,
    "id": 1392132388
  },
  {
    "city": "Silverthorne",
    "city_ascii": "Silverthorne",
    "lat": 39.656,
    "lng": -106.0867,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 19344,
    "id": 1840022436
  },
  {
    "city": "Rosedale",
    "city_ascii": "Rosedale",
    "lat": 39.3266,
    "lng": -76.5084,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 19336,
    "id": 1840005690
  },
  {
    "city": "Ayaviri",
    "city_ascii": "Ayaviri",
    "lat": -14.8818,
    "lng": -70.5901,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "Puno",
    "capital": "",
    "population": 19310,
    "id": 1604922172
  },
  {
    "city": "Ban Mae Kha Tai",
    "city_ascii": "Ban Mae Kha Tai",
    "lat": 18.7433,
    "lng": 98.964,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Chiang Mai",
    "capital": "",
    "population": 18564,
    "id": 1764637838
  },
  {
    "city": "Concord",
    "city_ascii": "Concord",
    "lat": 42.462,
    "lng": -71.3639,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 19323,
    "id": 1840053635
  },
  {
    "city": "Monção",
    "city_ascii": "Moncao",
    "lat": 42.0733,
    "lng": -8.48,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Viana do Castelo",
    "capital": "minor",
    "population": 19230,
    "id": 1620375502
  },
  {
    "city": "Sheridan",
    "city_ascii": "Sheridan",
    "lat": 44.7962,
    "lng": -106.9643,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wyoming",
    "capital": "",
    "population": 19320,
    "id": 1840021243
  },
  {
    "city": "Kirovgrad",
    "city_ascii": "Kirovgrad",
    "lat": 57.4333,
    "lng": 60.0667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sverdlovskaya Oblast’",
    "capital": "",
    "population": 19277,
    "id": 1643253740
  },
  {
    "city": "Soure",
    "city_ascii": "Soure",
    "lat": 40.05,
    "lng": -8.6333,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Coimbra",
    "capital": "minor",
    "population": 19245,
    "id": 1620536643
  },
  {
    "city": "Lebedyan",
    "city_ascii": "Lebedyan",
    "lat": 53.0116,
    "lng": 39.1281,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Lipetskaya Oblast’",
    "capital": "",
    "population": 19288,
    "id": 1643241295
  },
  {
    "city": "Pastavy",
    "city_ascii": "Pastavy",
    "lat": 55.1167,
    "lng": 26.8333,
    "country": "Belarus",
    "iso2": "BY",
    "iso3": "BLR",
    "admin_name": "Vitsyebskaya Voblasts’",
    "capital": "minor",
    "population": 19300,
    "id": 1112856591
  },
  {
    "city": "Durant",
    "city_ascii": "Durant",
    "lat": 33.9957,
    "lng": -96.3938,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oklahoma",
    "capital": "",
    "population": 19315,
    "id": 1840019280
  },
  {
    "city": "Linda",
    "city_ascii": "Linda",
    "lat": 39.1241,
    "lng": -121.5421,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 19314,
    "id": 1840018800
  },
  {
    "city": "Sylvania",
    "city_ascii": "Sylvania",
    "lat": 41.71,
    "lng": -83.7091,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 19311,
    "id": 1840009255
  },
  {
    "city": "Veresegyház",
    "city_ascii": "Veresegyhaz",
    "lat": 47.6569,
    "lng": 19.2847,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Pest",
    "capital": "",
    "population": 18654,
    "id": 1348362703
  },
  {
    "city": "Dolyns’ka",
    "city_ascii": "Dolyns'ka",
    "lat": 48.1111,
    "lng": 32.7648,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Kirovohrads’ka Oblast’",
    "capital": "minor",
    "population": 19287,
    "id": 1804823842
  },
  {
    "city": "Lebach",
    "city_ascii": "Lebach",
    "lat": 49.41,
    "lng": 6.91,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saarland",
    "capital": "",
    "population": 19006,
    "id": 1276242576
  },
  {
    "city": "Bakal",
    "city_ascii": "Bakal",
    "lat": 54.9333,
    "lng": 58.8,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Chelyabinskaya Oblast’",
    "capital": "",
    "population": 19264,
    "id": 1643146722
  },
  {
    "city": "Kronberg",
    "city_ascii": "Kronberg",
    "lat": 50.1797,
    "lng": 8.5085,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 18311,
    "id": 1276558702
  },
  {
    "city": "Corning",
    "city_ascii": "Corning",
    "lat": 42.147,
    "lng": -77.0561,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 19296,
    "id": 1840000443
  },
  {
    "city": "Horseheads",
    "city_ascii": "Horseheads",
    "lat": 42.1625,
    "lng": -76.794,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 19293,
    "id": 1840004688
  },
  {
    "city": "Püttlingen",
    "city_ascii": "Puttlingen",
    "lat": 49.2833,
    "lng": 6.8833,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saarland",
    "capital": "",
    "population": 18510,
    "id": 1276328612
  },
  {
    "city": "Pfullingen",
    "city_ascii": "Pfullingen",
    "lat": 48.4656,
    "lng": 9.2261,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 18654,
    "id": 1276393885
  },
  {
    "city": "Sömmerda",
    "city_ascii": "Sommerda",
    "lat": 51.1617,
    "lng": 11.1169,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Thuringia",
    "capital": "minor",
    "population": 19034,
    "id": 1276806984
  },
  {
    "city": "Lgov",
    "city_ascii": "Lgov",
    "lat": 51.6667,
    "lng": 35.2667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kurskaya Oblast’",
    "capital": "",
    "population": 18774,
    "id": 1643991928
  },
  {
    "city": "Cranbrook",
    "city_ascii": "Cranbrook",
    "lat": 49.5097,
    "lng": -115.7667,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "British Columbia",
    "capital": "",
    "population": 19259,
    "id": 1124937794
  },
  {
    "city": "Kabūdarāhang",
    "city_ascii": "Kabudarahang",
    "lat": 35.2083,
    "lng": 48.7239,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Hamadān",
    "capital": "minor",
    "population": 19216,
    "id": 1364143684
  },
  {
    "city": "Pinole",
    "city_ascii": "Pinole",
    "lat": 37.9931,
    "lng": -122.2834,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 19250,
    "id": 1840020281
  },
  {
    "city": "Dolný Kubín",
    "city_ascii": "Dolny Kubin",
    "lat": 49.2117,
    "lng": 19.2992,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Žilinský",
    "capital": "minor",
    "population": 18905,
    "id": 1703836159
  },
  {
    "city": "Erlanger",
    "city_ascii": "Erlanger",
    "lat": 39.0109,
    "lng": -84.5864,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kentucky",
    "capital": "",
    "population": 19246,
    "id": 1840013168
  },
  {
    "city": "Abdulino",
    "city_ascii": "Abdulino",
    "lat": 53.6833,
    "lng": 53.65,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Orenburgskaya Oblast’",
    "capital": "",
    "population": 19222,
    "id": 1643685252
  },
  {
    "city": "Ware",
    "city_ascii": "Ware",
    "lat": 51.8109,
    "lng": -0.0314,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hertfordshire",
    "capital": "",
    "population": 18799,
    "id": 1826891949
  },
  {
    "city": "Dillingen",
    "city_ascii": "Dillingen",
    "lat": 48.5667,
    "lng": 10.4667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "minor",
    "population": 18978,
    "id": 1276506212
  },
  {
    "city": "Kralupy nad Vltavou",
    "city_ascii": "Kralupy nad Vltavou",
    "lat": 50.2411,
    "lng": 14.3116,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Středočeský Kraj",
    "capital": "",
    "population": 18388,
    "id": 1203317115
  },
  {
    "city": "Altoona",
    "city_ascii": "Altoona",
    "lat": 41.6481,
    "lng": -93.4784,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Iowa",
    "capital": "",
    "population": 19221,
    "id": 1840007065
  },
  {
    "city": "Bethany",
    "city_ascii": "Bethany",
    "lat": 35.5072,
    "lng": -97.6417,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oklahoma",
    "capital": "",
    "population": 19221,
    "id": 1840019153
  },
  {
    "city": "Uzhur",
    "city_ascii": "Uzhur",
    "lat": 55.3167,
    "lng": 89.8167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Krasnoyarskiy Kray",
    "capital": "",
    "population": 19212,
    "id": 1643237169
  },
  {
    "city": "Cambridge",
    "city_ascii": "Cambridge",
    "lat": -37.8833,
    "lng": 175.4667,
    "country": "New Zealand",
    "iso2": "NZ",
    "iso3": "NZL",
    "admin_name": "Waikato",
    "capital": "",
    "population": 19150,
    "id": 1554000848
  },
  {
    "city": "Lumphat",
    "city_ascii": "Lumphat",
    "lat": 13.507,
    "lng": 106.981,
    "country": "Cambodia",
    "iso2": "KH",
    "iso3": "KHM",
    "admin_name": "Ratanakiri",
    "capital": "minor",
    "population": 19205,
    "id": 1116149070
  },
  {
    "city": "Mount Eliza",
    "city_ascii": "Mount Eliza",
    "lat": -38.189,
    "lng": 145.092,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Victoria",
    "capital": "",
    "population": 17888,
    "id": 1036041769
  },
  {
    "city": "Port Alfred",
    "city_ascii": "Port Alfred",
    "lat": -33.6,
    "lng": 26.8833,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "Eastern Cape",
    "capital": "",
    "population": 17959,
    "id": 1710920478
  },
  {
    "city": "Carlisle",
    "city_ascii": "Carlisle",
    "lat": 40.1999,
    "lng": -77.2042,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 19198,
    "id": 1840001386
  },
  {
    "city": "Whitpain",
    "city_ascii": "Whitpain",
    "lat": 40.1578,
    "lng": -75.2769,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 19194,
    "id": 1840145445
  },
  {
    "city": "Staveley",
    "city_ascii": "Staveley",
    "lat": 53.2694,
    "lng": -1.3484,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Derbyshire",
    "capital": "",
    "population": 18247,
    "id": 1826136696
  },
  {
    "city": "Milton Township",
    "city_ascii": "Milton Township",
    "lat": 43.0406,
    "lng": -73.8998,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 19179,
    "id": 1840058308
  },
  {
    "city": "Dadeldhurā",
    "city_ascii": "Dadeldhura",
    "lat": 29.3,
    "lng": 80.6,
    "country": "Nepal",
    "iso2": "NP",
    "iso3": "NPL",
    "admin_name": "Mahākālī",
    "capital": "",
    "population": 19014,
    "id": 1524639464
  },
  {
    "city": "Highland",
    "city_ascii": "Highland",
    "lat": 40.4275,
    "lng": -111.7955,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Utah",
    "capital": "",
    "population": 19175,
    "id": 1840020167
  },
  {
    "city": "Tak Bai",
    "city_ascii": "Tak Bai",
    "lat": 6.2592,
    "lng": 102.053,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Narathiwat",
    "capital": "minor",
    "population": 18943,
    "id": 1764842940
  },
  {
    "city": "Bad Driburg",
    "city_ascii": "Bad Driburg",
    "lat": 51.7333,
    "lng": 9.0167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 19002,
    "id": 1276571920
  },
  {
    "city": "La Ligua",
    "city_ascii": "La Ligua",
    "lat": -32.4494,
    "lng": -71.2317,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Valparaíso",
    "capital": "minor",
    "population": 19127,
    "id": 1152826056
  },
  {
    "city": "Prenzlau",
    "city_ascii": "Prenzlau",
    "lat": 53.3167,
    "lng": 13.8667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Brandenburg",
    "capital": "minor",
    "population": 19024,
    "id": 1276456147
  },
  {
    "city": "Pinecrest",
    "city_ascii": "Pinecrest",
    "lat": 25.665,
    "lng": -80.3042,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 19155,
    "id": 1840018336
  },
  {
    "city": "Cerqueira César",
    "city_ascii": "Cerqueira Cesar",
    "lat": -23.0356,
    "lng": -49.1661,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 19109,
    "id": 1076534225
  },
  {
    "city": "Shemonaīkha",
    "city_ascii": "Shemonaikha",
    "lat": 50.6328,
    "lng": 81.9056,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Shyghys Qazaqstan",
    "capital": "",
    "population": 19127,
    "id": 1398507085
  },
  {
    "city": "Nikolayevsk-na-Amure",
    "city_ascii": "Nikolayevsk-na-Amure",
    "lat": 53.15,
    "lng": 140.7333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Khabarovskiy Kray",
    "capital": "",
    "population": 19135,
    "id": 1643026765
  },
  {
    "city": "Oberasbach",
    "city_ascii": "Oberasbach",
    "lat": 49.4219,
    "lng": 10.9583,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 17672,
    "id": 1276883001
  },
  {
    "city": "Tiffin",
    "city_ascii": "Tiffin",
    "lat": 41.1165,
    "lng": -83.1805,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 19130,
    "id": 1840000821
  },
  {
    "city": "Mount Airy",
    "city_ascii": "Mount Airy",
    "lat": 36.5083,
    "lng": -80.6154,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 19119,
    "id": 1840014430
  },
  {
    "city": "Kleinsachsenheim",
    "city_ascii": "Kleinsachsenheim",
    "lat": 48.96,
    "lng": 9.0647,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 18794,
    "id": 1276375444
  },
  {
    "city": "Ferguson",
    "city_ascii": "Ferguson",
    "lat": 40.7432,
    "lng": -77.9403,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 19118,
    "id": 1840149387
  },
  {
    "city": "Beaconsfield",
    "city_ascii": "Beaconsfield",
    "lat": 45.4333,
    "lng": -73.8667,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 19115,
    "id": 1124755118
  },
  {
    "city": "Saugerties",
    "city_ascii": "Saugerties",
    "lat": 42.0891,
    "lng": -73.9969,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 19109,
    "id": 1840004732
  },
  {
    "city": "Broadview Heights",
    "city_ascii": "Broadview Heights",
    "lat": 41.3195,
    "lng": -81.6782,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 19102,
    "id": 1840000591
  },
  {
    "city": "Lower Southampton",
    "city_ascii": "Lower Southampton",
    "lat": 40.1541,
    "lng": -74.9903,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 19101,
    "id": 1840151113
  },
  {
    "city": "Târgu Neamţ",
    "city_ascii": "Targu Neamt",
    "lat": 47.2025,
    "lng": 26.3586,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Neamţ",
    "capital": "",
    "population": 18695,
    "id": 1642788528
  },
  {
    "city": "Montville",
    "city_ascii": "Montville",
    "lat": 41.4636,
    "lng": -72.157,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 19094,
    "id": 1840045046
  },
  {
    "city": "Mumbwa",
    "city_ascii": "Mumbwa",
    "lat": -14.9796,
    "lng": 27.07,
    "country": "Zambia",
    "iso2": "ZM",
    "iso3": "ZMB",
    "admin_name": "Central",
    "capital": "",
    "population": 19086,
    "id": 1894568812
  },
  {
    "city": "Lower Allen",
    "city_ascii": "Lower Allen",
    "lat": 40.2083,
    "lng": -76.9287,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 19085,
    "id": 1840035428
  },
  {
    "city": "Radeberg",
    "city_ascii": "Radeberg",
    "lat": 51.1167,
    "lng": 13.9167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 18463,
    "id": 1276815602
  },
  {
    "city": "Westbrook",
    "city_ascii": "Westbrook",
    "lat": 43.6954,
    "lng": -70.3539,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maine",
    "capital": "",
    "population": 19074,
    "id": 1840000329
  },
  {
    "city": "Komárom",
    "city_ascii": "Komarom",
    "lat": 47.74,
    "lng": 18.1217,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Komárom-Esztergom",
    "capital": "minor",
    "population": 18805,
    "id": 1348857421
  },
  {
    "city": "Louny",
    "city_ascii": "Louny",
    "lat": 50.3571,
    "lng": 13.7968,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Ústecký Kraj",
    "capital": "",
    "population": 18313,
    "id": 1203988380
  },
  {
    "city": "Wervik",
    "city_ascii": "Wervik",
    "lat": 50.7797,
    "lng": 3.04,
    "country": "Belgium",
    "iso2": "BE",
    "iso3": "BEL",
    "admin_name": "Flanders",
    "capital": "",
    "population": 18643,
    "id": 1056938335
  },
  {
    "city": "Préveza",
    "city_ascii": "Preveza",
    "lat": 38.95,
    "lng": 20.75,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Ípeiros",
    "capital": "minor",
    "population": 19042,
    "id": 1300933723
  },
  {
    "city": "Rawa Mazowiecka",
    "city_ascii": "Rawa Mazowiecka",
    "lat": 51.7667,
    "lng": 20.25,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Łódzkie",
    "capital": "minor",
    "population": 17765,
    "id": 1616083640
  },
  {
    "city": "Alzey",
    "city_ascii": "Alzey",
    "lat": 49.7517,
    "lng": 8.1161,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Rhineland-Palatinate",
    "capital": "minor",
    "population": 18535,
    "id": 1276680173
  },
  {
    "city": "Akureyri",
    "city_ascii": "Akureyri",
    "lat": 65.6833,
    "lng": -18.1,
    "country": "Iceland",
    "iso2": "IS",
    "iso3": "ISL",
    "admin_name": "Norðurland Eystra",
    "capital": "",
    "population": 18925,
    "id": 1352098424
  },
  {
    "city": "Miyatoko",
    "city_ascii": "Miyatoko",
    "lat": 33.6992,
    "lng": 130.9206,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Fukuoka",
    "capital": "",
    "population": 18934,
    "id": 1392427893
  },
  {
    "city": "Springwater",
    "city_ascii": "Springwater",
    "lat": 44.4333,
    "lng": -79.7333,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 19059,
    "id": 1124001298
  },
  {
    "city": "Waterford",
    "city_ascii": "Waterford",
    "lat": 41.3692,
    "lng": -72.1483,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 19052,
    "id": 1840035649
  },
  {
    "city": "Proletarsk",
    "city_ascii": "Proletarsk",
    "lat": 46.7031,
    "lng": 41.7192,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Rostovskaya Oblast’",
    "capital": "minor",
    "population": 19032,
    "id": 1643862086
  },
  {
    "city": "Bánovce nad Bebravou",
    "city_ascii": "Banovce nad Bebravou",
    "lat": 48.7186,
    "lng": 18.2581,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Nitriansky",
    "capital": "minor",
    "population": 18350,
    "id": 1703803804
  },
  {
    "city": "Red Bluff",
    "city_ascii": "Red Bluff",
    "lat": 40.1735,
    "lng": -122.2413,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 19039,
    "id": 1840020176
  },
  {
    "city": "Arcadia",
    "city_ascii": "Arcadia",
    "lat": 27.2213,
    "lng": -81.8587,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 19038,
    "id": 1840014187
  },
  {
    "city": "Dankov",
    "city_ascii": "Dankov",
    "lat": 53.25,
    "lng": 39.15,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Lipetskaya Oblast’",
    "capital": "",
    "population": 19017,
    "id": 1643711821
  },
  {
    "city": "Göd",
    "city_ascii": "God",
    "lat": 47.6906,
    "lng": 19.1344,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Pest",
    "capital": "",
    "population": 18218,
    "id": 1348600580
  },
  {
    "city": "Onega",
    "city_ascii": "Onega",
    "lat": 63.9167,
    "lng": 38.0833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Arkhangel’skaya Oblast’",
    "capital": "",
    "population": 19030,
    "id": 1643716527
  },
  {
    "city": "Puerto Natales",
    "city_ascii": "Puerto Natales",
    "lat": -51.7263,
    "lng": -72.5062,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Magallanes y de la Antártica Chilena",
    "capital": "minor",
    "population": 19023,
    "id": 1152225106
  },
  {
    "city": "Caconde",
    "city_ascii": "Caconde",
    "lat": -21.5289,
    "lng": -46.6439,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 18976,
    "id": 1076573900
  },
  {
    "city": "Shimanovsk",
    "city_ascii": "Shimanovsk",
    "lat": 52,
    "lng": 127.6667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Amurskaya Oblast’",
    "capital": "",
    "population": 18643,
    "id": 1643749021
  },
  {
    "city": "Fox Crossing",
    "city_ascii": "Fox Crossing",
    "lat": 44.2229,
    "lng": -88.4763,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 19012,
    "id": 1840038105
  },
  {
    "city": "White Oak",
    "city_ascii": "White Oak",
    "lat": 39.0433,
    "lng": -76.9906,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 19010,
    "id": 1840005847
  },
  {
    "city": "McKeesport",
    "city_ascii": "McKeesport",
    "lat": 40.3419,
    "lng": -79.8439,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 19009,
    "id": 1840001243
  },
  {
    "city": "Mill Hill",
    "city_ascii": "Mill Hill",
    "lat": 51.62,
    "lng": -0.23,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Barnet",
    "capital": "",
    "population": 18451,
    "id": 1826875983
  },
  {
    "city": "Salcajá",
    "city_ascii": "Salcaja",
    "lat": 14.8833,
    "lng": -91.45,
    "country": "Guatemala",
    "iso2": "GT",
    "iso3": "GTM",
    "admin_name": "Quetzaltenango",
    "capital": "minor",
    "population": 17535,
    "id": 1320564253
  },
  {
    "city": "Gyömrő",
    "city_ascii": "Gyomro",
    "lat": 47.4353,
    "lng": 19.3986,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Pest",
    "capital": "",
    "population": 18301,
    "id": 1348769818
  },
  {
    "city": "Atlapexco",
    "city_ascii": "Atlapexco",
    "lat": 21.0042,
    "lng": -98.5056,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Hidalgo",
    "capital": "",
    "population": 18769,
    "id": 1484002302
  },
  {
    "city": "Liberal",
    "city_ascii": "Liberal",
    "lat": 37.0466,
    "lng": -100.9295,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kansas",
    "capital": "",
    "population": 18990,
    "id": 1840001704
  },
  {
    "city": "Tatsuno",
    "city_ascii": "Tatsuno",
    "lat": 35.9825,
    "lng": 137.9875,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Nagano",
    "capital": "",
    "population": 18878,
    "id": 1392044975
  },
  {
    "city": "Altamont",
    "city_ascii": "Altamont",
    "lat": 42.1981,
    "lng": -121.7249,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 18987,
    "id": 1840017440
  },
  {
    "city": "Bideford",
    "city_ascii": "Bideford",
    "lat": 51.016,
    "lng": -4.209,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Devon",
    "capital": "",
    "population": 17107,
    "id": 1826207226
  },
  {
    "city": "Owego",
    "city_ascii": "Owego",
    "lat": 42.0881,
    "lng": -76.1906,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 18985,
    "id": 1840004668
  },
  {
    "city": "Qitai",
    "city_ascii": "Qitai",
    "lat": 41.5494,
    "lng": 113.5339,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Inner Mongolia",
    "capital": "minor",
    "population": 18831,
    "id": 1156174797
  },
  {
    "city": "Westborough",
    "city_ascii": "Westborough",
    "lat": 42.2681,
    "lng": -71.614,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 18982,
    "id": 1840053611
  },
  {
    "city": "Dorval",
    "city_ascii": "Dorval",
    "lat": 45.45,
    "lng": -73.75,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 18980,
    "id": 1124933556
  },
  {
    "city": "Murray Bridge",
    "city_ascii": "Murray Bridge",
    "lat": -35.117,
    "lng": 139.267,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "South Australia",
    "capital": "",
    "population": 18779,
    "id": 1036345943
  },
  {
    "city": "Kalynivka",
    "city_ascii": "Kalynivka",
    "lat": 49.4472,
    "lng": 28.5231,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Vinnyts’ka Oblast’",
    "capital": "minor",
    "population": 18906,
    "id": 1804606611
  },
  {
    "city": "Appley Bridge",
    "city_ascii": "Appley Bridge",
    "lat": 53.579,
    "lng": -2.721,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Lancashire",
    "capital": "",
    "population": 18500,
    "id": 1826409679
  },
  {
    "city": "Stroud",
    "city_ascii": "Stroud",
    "lat": 41.0001,
    "lng": -75.2173,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 18952,
    "id": 1840144158
  },
  {
    "city": "Naantali",
    "city_ascii": "Naantali",
    "lat": 60.4681,
    "lng": 22.0264,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Varsinais-Suomi",
    "capital": "minor",
    "population": 18916,
    "id": 1246881232
  },
  {
    "city": "Onalaska",
    "city_ascii": "Onalaska",
    "lat": 43.8881,
    "lng": -91.2074,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 18943,
    "id": 1840002579
  },
  {
    "city": "Sudbury",
    "city_ascii": "Sudbury",
    "lat": 42.3847,
    "lng": -71.4234,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 18940,
    "id": 1840053489
  },
  {
    "city": "Lexington",
    "city_ascii": "Lexington",
    "lat": 35.8017,
    "lng": -80.2682,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 18933,
    "id": 1840015360
  },
  {
    "city": "Moosburg",
    "city_ascii": "Moosburg",
    "lat": 48.4667,
    "lng": 11.9333,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 18510,
    "id": 1276561909
  },
  {
    "city": "Springboro",
    "city_ascii": "Springboro",
    "lat": 39.5612,
    "lng": -84.2348,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 18931,
    "id": 1840009573
  },
  {
    "city": "Poulton le Fylde",
    "city_ascii": "Poulton le Fylde",
    "lat": 53.847,
    "lng": -2.995,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Lancashire",
    "capital": "",
    "population": 18495,
    "id": 1826051466
  },
  {
    "city": "Whitehall",
    "city_ascii": "Whitehall",
    "lat": 39.9682,
    "lng": -82.8833,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 18926,
    "id": 1840003764
  },
  {
    "city": "Sartell",
    "city_ascii": "Sartell",
    "lat": 45.6187,
    "lng": -94.2207,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 18926,
    "id": 1840008886
  },
  {
    "city": "Narangba",
    "city_ascii": "Narangba",
    "lat": -27.2015,
    "lng": 152.9655,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Queensland",
    "capital": "",
    "population": 18573,
    "id": 1036559054
  },
  {
    "city": "Contamana",
    "city_ascii": "Contamana",
    "lat": -7.3333,
    "lng": -75.0167,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "Loreto",
    "capital": "minor",
    "population": 18921,
    "id": 1604465551
  },
  {
    "city": "Castaic",
    "city_ascii": "Castaic",
    "lat": 34.4818,
    "lng": -118.6317,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 18921,
    "id": 1840022826
  },
  {
    "city": "Lake Butler",
    "city_ascii": "Lake Butler",
    "lat": 28.4868,
    "lng": -81.5453,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 18917,
    "id": 1840143771
  },
  {
    "city": "Pruzhany",
    "city_ascii": "Pruzhany",
    "lat": 52.5567,
    "lng": 24.4644,
    "country": "Belarus",
    "iso2": "BY",
    "iso3": "BLR",
    "admin_name": "Brestskaya Voblasts’",
    "capital": "minor",
    "population": 18900,
    "id": 1112269031
  },
  {
    "city": "Polohy",
    "city_ascii": "Polohy",
    "lat": 47.4796,
    "lng": 36.2611,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Zaporiz’ka Oblast’",
    "capital": "minor",
    "population": 18886,
    "id": 1804554214
  },
  {
    "city": "Paks",
    "city_ascii": "Paks",
    "lat": 46.6219,
    "lng": 18.8558,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Tolna",
    "capital": "minor",
    "population": 18788,
    "id": 1348932688
  },
  {
    "city": "Cofradía",
    "city_ascii": "Cofradia",
    "lat": 15.4168,
    "lng": -88.1603,
    "country": "Honduras",
    "iso2": "HN",
    "iso3": "HND",
    "admin_name": "Cortés",
    "capital": "",
    "population": 18100,
    "id": 1340899741
  },
  {
    "city": "Eyl",
    "city_ascii": "Eyl",
    "lat": 7.9667,
    "lng": 49.85,
    "country": "Somalia",
    "iso2": "SO",
    "iso3": "SOM",
    "admin_name": "Nugaal",
    "capital": "",
    "population": 18904,
    "id": 1706929381
  },
  {
    "city": "Landa de Matamoros",
    "city_ascii": "Landa de Matamoros",
    "lat": 21.1819,
    "lng": -99.3203,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Querétaro",
    "capital": "minor",
    "population": 18905,
    "id": 1484782726
  },
  {
    "city": "Mo i Rana",
    "city_ascii": "Mo i Rana",
    "lat": 66.3167,
    "lng": 14.1667,
    "country": "Norway",
    "iso2": "NO",
    "iso3": "NOR",
    "admin_name": "Nordland",
    "capital": "minor",
    "population": 18899,
    "id": 1578067925
  },
  {
    "city": "McAlester",
    "city_ascii": "McAlester",
    "lat": 34.9262,
    "lng": -95.7698,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oklahoma",
    "capital": "",
    "population": 18896,
    "id": 1840020457
  },
  {
    "city": "Nelidovo",
    "city_ascii": "Nelidovo",
    "lat": 56.2167,
    "lng": 32.7833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Tverskaya Oblast’",
    "capital": "",
    "population": 18883,
    "id": 1643775202
  },
  {
    "city": "Százhalombatta",
    "city_ascii": "Szazhalombatta",
    "lat": 47.3167,
    "lng": 18.9114,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Pest",
    "capital": "",
    "population": 18241,
    "id": 1348329361
  },
  {
    "city": "Mount Isa",
    "city_ascii": "Mount Isa",
    "lat": -20.7333,
    "lng": 139.5,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Queensland",
    "capital": "",
    "population": 18588,
    "id": 1036386100
  },
  {
    "city": "La Follette",
    "city_ascii": "La Follette",
    "lat": 36.3718,
    "lng": -84.1256,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 18884,
    "id": 1840036150
  },
  {
    "city": "Alfeld",
    "city_ascii": "Alfeld",
    "lat": 51.9886,
    "lng": 9.8269,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 18626,
    "id": 1276733070
  },
  {
    "city": "Aberystwyth",
    "city_ascii": "Aberystwyth",
    "lat": 52.414,
    "lng": -4.081,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Ceredigion",
    "capital": "",
    "population": 18093,
    "id": 1826420477
  },
  {
    "city": "Fushë-Krujë",
    "city_ascii": "Fushe-Kruje",
    "lat": 41.4833,
    "lng": 19.7167,
    "country": "Albania",
    "iso2": "AL",
    "iso3": "ALB",
    "admin_name": "Durrës",
    "capital": "",
    "population": 18477,
    "id": 1008652935
  },
  {
    "city": "Tessenderlo",
    "city_ascii": "Tessenderlo",
    "lat": 51.0697,
    "lng": 5.0897,
    "country": "Belgium",
    "iso2": "BE",
    "iso3": "BEL",
    "admin_name": "Flanders",
    "capital": "",
    "population": 18514,
    "id": 1056426796
  },
  {
    "city": "Limerick",
    "city_ascii": "Limerick",
    "lat": 40.2323,
    "lng": -75.5344,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 18874,
    "id": 1840035062
  },
  {
    "city": "Muttenz",
    "city_ascii": "Muttenz",
    "lat": 47.5228,
    "lng": 7.6452,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Basel-Landschaft",
    "capital": "",
    "population": 17805,
    "id": 1756008285
  },
  {
    "city": "P”yatykhatky",
    "city_ascii": "P'yatykhatky",
    "lat": 48.4126,
    "lng": 33.7034,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Dnipropetrovs’ka Oblast’",
    "capital": "minor",
    "population": 18845,
    "id": 1804098931
  },
  {
    "city": "Uwchlan",
    "city_ascii": "Uwchlan",
    "lat": 40.0522,
    "lng": -75.6679,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 18869,
    "id": 1840145647
  },
  {
    "city": "Heanor",
    "city_ascii": "Heanor",
    "lat": 53.014,
    "lng": -1.354,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Derbyshire",
    "capital": "",
    "population": 17251,
    "id": 1826825779
  },
  {
    "city": "Kara-Köl",
    "city_ascii": "Kara-Kol",
    "lat": 41.6283,
    "lng": 72.6703,
    "country": "Kyrgyzstan",
    "iso2": "KG",
    "iso3": "KGZ",
    "admin_name": "Jalal-Abad",
    "capital": "",
    "population": 18843,
    "id": 1417090900
  },
  {
    "city": "Kalach",
    "city_ascii": "Kalach",
    "lat": 50.4258,
    "lng": 41.0156,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Voronezhskaya Oblast’",
    "capital": "",
    "population": 18834,
    "id": 1643931782
  },
  {
    "city": "Natchitoches",
    "city_ascii": "Natchitoches",
    "lat": 31.7317,
    "lng": -93.0979,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 18859,
    "id": 1840014925
  },
  {
    "city": "Twinsburg",
    "city_ascii": "Twinsburg",
    "lat": 41.322,
    "lng": -81.4451,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 18856,
    "id": 1840000805
  },
  {
    "city": "Sonsón",
    "city_ascii": "Sonson",
    "lat": 5.7166,
    "lng": -75.3166,
    "country": "Colombia",
    "iso2": "CO",
    "iso3": "COL",
    "admin_name": "Antioquia",
    "capital": "minor",
    "population": 18817,
    "id": 1170000118
  },
  {
    "city": "Central Point",
    "city_ascii": "Central Point",
    "lat": 42.3765,
    "lng": -122.911,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 18848,
    "id": 1840018672
  },
  {
    "city": "Sparta",
    "city_ascii": "Sparta",
    "lat": 41.0486,
    "lng": -74.6264,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 18841,
    "id": 1840081784
  },
  {
    "city": "Crimmitschau",
    "city_ascii": "Crimmitschau",
    "lat": 50.8181,
    "lng": 12.3875,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 18536,
    "id": 1276188517
  },
  {
    "city": "Seminole",
    "city_ascii": "Seminole",
    "lat": 27.8435,
    "lng": -82.7839,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 18838,
    "id": 1840015978
  },
  {
    "city": "Burscheid",
    "city_ascii": "Burscheid",
    "lat": 51.1,
    "lng": 7.1167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 18172,
    "id": 1276397307
  },
  {
    "city": "Kilbirnie",
    "city_ascii": "Kilbirnie",
    "lat": 55.755,
    "lng": -4.686,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "North Ayrshire",
    "capital": "",
    "population": 18829,
    "id": 1826853483
  },
  {
    "city": "Coldwater",
    "city_ascii": "Coldwater",
    "lat": 41.9465,
    "lng": -84.9989,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 18832,
    "id": 1840003250
  },
  {
    "city": "Gossau",
    "city_ascii": "Gossau",
    "lat": 47.4164,
    "lng": 9.25,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Sankt Gallen",
    "capital": "",
    "population": 18171,
    "id": 1756392610
  },
  {
    "city": "Starobilsk",
    "city_ascii": "Starobilsk",
    "lat": 49.2667,
    "lng": 38.9167,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Luhans’ka Oblast’",
    "capital": "minor",
    "population": 18796,
    "id": 1804913112
  },
  {
    "city": "Nueva Imperial",
    "city_ascii": "Nueva Imperial",
    "lat": -38.74,
    "lng": -72.96,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Araucanía",
    "capital": "",
    "population": 18777,
    "id": 1152277064
  },
  {
    "city": "Holden",
    "city_ascii": "Holden",
    "lat": 42.3562,
    "lng": -71.8608,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 18821,
    "id": 1840053588
  },
  {
    "city": "West Manchester",
    "city_ascii": "West Manchester",
    "lat": 39.9456,
    "lng": -76.7952,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 18819,
    "id": 1840144165
  },
  {
    "city": "Peterhead",
    "city_ascii": "Peterhead",
    "lat": 57.5091,
    "lng": -1.7832,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Aberdeenshire",
    "capital": "",
    "population": 18537,
    "id": 1826812445
  },
  {
    "city": "Sortavala",
    "city_ascii": "Sortavala",
    "lat": 61.7,
    "lng": 30.6667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kareliya",
    "capital": "minor",
    "population": 18801,
    "id": 1643799487
  },
  {
    "city": "Victoria",
    "city_ascii": "Victoria",
    "lat": 21.2122,
    "lng": -100.2144,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Guanajuato",
    "capital": "minor",
    "population": 17764,
    "id": 1484434699
  },
  {
    "city": "Lessines",
    "city_ascii": "Lessines",
    "lat": 50.7117,
    "lng": 3.83,
    "country": "Belgium",
    "iso2": "BE",
    "iso3": "BEL",
    "admin_name": "Wallonia",
    "capital": "",
    "population": 18552,
    "id": 1056283764
  },
  {
    "city": "Thorold",
    "city_ascii": "Thorold",
    "lat": 43.1167,
    "lng": -79.2,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 18801,
    "id": 1124718251
  },
  {
    "city": "St. John",
    "city_ascii": "St. John",
    "lat": 41.4431,
    "lng": -87.47,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 18796,
    "id": 1840010221
  },
  {
    "city": "Novyy Oskol",
    "city_ascii": "Novyy Oskol",
    "lat": 50.7667,
    "lng": 37.8667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Belgorodskaya Oblast’",
    "capital": "",
    "population": 18763,
    "id": 1643972982
  },
  {
    "city": "Badger",
    "city_ascii": "Badger",
    "lat": 64.8006,
    "lng": -147.3877,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alaska",
    "capital": "",
    "population": 18792,
    "id": 1840023690
  },
  {
    "city": "Renningen",
    "city_ascii": "Renningen",
    "lat": 48.7661,
    "lng": 8.9347,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 18206,
    "id": 1276122703
  },
  {
    "city": "Otrokovice",
    "city_ascii": "Otrokovice",
    "lat": 49.2099,
    "lng": 17.5308,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Zlínský Kraj",
    "capital": "",
    "population": 17879,
    "id": 1203087231
  },
  {
    "city": "Engenheiro Coelho",
    "city_ascii": "Engenheiro Coelho",
    "lat": -22.4883,
    "lng": -47.215,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 18611,
    "id": 1076001478
  },
  {
    "city": "Alzenau in Unterfranken",
    "city_ascii": "Alzenau in Unterfranken",
    "lat": 50.0881,
    "lng": 9.0497,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 18469,
    "id": 1276942017
  },
  {
    "city": "Middelburg",
    "city_ascii": "Middelburg",
    "lat": -31.4939,
    "lng": 25.0172,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "Eastern Cape",
    "capital": "",
    "population": 18164,
    "id": 1710010705
  },
  {
    "city": "Rawmarsh",
    "city_ascii": "Rawmarsh",
    "lat": 53.4636,
    "lng": -1.3439,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Rotherham",
    "capital": "",
    "population": 18498,
    "id": 1826485638
  },
  {
    "city": "Grandview",
    "city_ascii": "Grandview",
    "lat": 46.2444,
    "lng": -119.9091,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 18772,
    "id": 1840019871
  },
  {
    "city": "Yoichi",
    "city_ascii": "Yoichi",
    "lat": 43.1953,
    "lng": 140.7836,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hokkaidō",
    "capital": "",
    "population": 18637,
    "id": 1392190850
  },
  {
    "city": "East Lyme",
    "city_ascii": "East Lyme",
    "lat": 41.3664,
    "lng": -72.2352,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 18766,
    "id": 1840045011
  },
  {
    "city": "Marshfield",
    "city_ascii": "Marshfield",
    "lat": 44.6627,
    "lng": -90.1728,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 18764,
    "id": 1840002336
  },
  {
    "city": "Emba",
    "city_ascii": "Emba",
    "lat": 48.8268,
    "lng": 58.1442,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Aqtöbe",
    "capital": "",
    "population": 18760,
    "id": 1398915734
  },
  {
    "city": "Konz",
    "city_ascii": "Konz",
    "lat": 49.7028,
    "lng": 6.5794,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Rhineland-Palatinate",
    "capital": "",
    "population": 18348,
    "id": 1276863750
  },
  {
    "city": "Rufino",
    "city_ascii": "Rufino",
    "lat": -34.2683,
    "lng": -62.7125,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Santa Fe",
    "capital": "",
    "population": 18727,
    "id": 1032127696
  },
  {
    "city": "Camrose",
    "city_ascii": "Camrose",
    "lat": 53.0167,
    "lng": -112.8333,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Alberta",
    "capital": "",
    "population": 18742,
    "id": 1124351657
  },
  {
    "city": "Schneverdingen",
    "city_ascii": "Schneverdingen",
    "lat": 53.1167,
    "lng": 9.8,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 18662,
    "id": 1276752778
  },
  {
    "city": "Rājmahal",
    "city_ascii": "Rajmahal",
    "lat": 25.05,
    "lng": 87.84,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "West Bengal",
    "capital": "",
    "population": 17974,
    "id": 1356144679
  },
  {
    "city": "Marktoberdorf",
    "city_ascii": "Marktoberdorf",
    "lat": 47.7667,
    "lng": 10.6167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "minor",
    "population": 18539,
    "id": 1276720955
  },
  {
    "city": "Warwick Township",
    "city_ascii": "Warwick Township",
    "lat": 40.1558,
    "lng": -76.2799,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 18731,
    "id": 1840142491
  },
  {
    "city": "Maple Shade",
    "city_ascii": "Maple Shade",
    "lat": 39.952,
    "lng": -74.995,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 18729,
    "id": 1840081625
  },
  {
    "city": "Franklin Farm",
    "city_ascii": "Franklin Farm",
    "lat": 38.9133,
    "lng": -77.3969,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 18727,
    "id": 1840041752
  },
  {
    "city": "Wassenberg",
    "city_ascii": "Wassenberg",
    "lat": 51.1,
    "lng": 6.15,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 18292,
    "id": 1276956199
  },
  {
    "city": "Tepetlixpa",
    "city_ascii": "Tepetlixpa",
    "lat": 19.0006,
    "lng": -98.8178,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "México",
    "capital": "minor",
    "population": 18327,
    "id": 1484018861
  },
  {
    "city": "Martigny",
    "city_ascii": "Martigny",
    "lat": 46.1,
    "lng": 7.0728,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Valais",
    "capital": "",
    "population": 17998,
    "id": 1756450627
  },
  {
    "city": "Bad Reichenhall",
    "city_ascii": "Bad Reichenhall",
    "lat": 47.7247,
    "lng": 12.8769,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "minor",
    "population": 18278,
    "id": 1276059039
  },
  {
    "city": "Alsip",
    "city_ascii": "Alsip",
    "lat": 41.6701,
    "lng": -87.7368,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 18709,
    "id": 1840010147
  },
  {
    "city": "Avon",
    "city_ascii": "Avon",
    "lat": 39.7603,
    "lng": -86.3917,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 18706,
    "id": 1840009535
  },
  {
    "city": "Homewood",
    "city_ascii": "Homewood",
    "lat": 41.5591,
    "lng": -87.661,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 18703,
    "id": 1840011264
  },
  {
    "city": "Surovikino",
    "city_ascii": "Surovikino",
    "lat": 48.6,
    "lng": 42.85,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Volgogradskaya Oblast’",
    "capital": "",
    "population": 18685,
    "id": 1643870086
  },
  {
    "city": "Or ‘Aqiva",
    "city_ascii": "Or `Aqiva",
    "lat": 32.5,
    "lng": 34.9167,
    "country": "Israel",
    "iso2": "IL",
    "iso3": "ISR",
    "admin_name": "Haifa",
    "capital": "",
    "population": 17759,
    "id": 1376081727
  },
  {
    "city": "Auerbach",
    "city_ascii": "Auerbach",
    "lat": 50.5094,
    "lng": 12.4,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 18357,
    "id": 1276679224
  },
  {
    "city": "Port Isabel",
    "city_ascii": "Port Isabel",
    "lat": 26.054,
    "lng": -97.2505,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 18686,
    "id": 1840021040
  },
  {
    "city": "Vovchansk",
    "city_ascii": "Vovchansk",
    "lat": 50.2881,
    "lng": 36.9461,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Kharkivs’ka Oblast’",
    "capital": "minor",
    "population": 18644,
    "id": 1804820380
  },
  {
    "city": "Marinette",
    "city_ascii": "Marinette",
    "lat": 45.087,
    "lng": -87.6328,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 18671,
    "id": 1840003930
  },
  {
    "city": "Minamishibetsuchō",
    "city_ascii": "Minamishibetsucho",
    "lat": 44.1153,
    "lng": 142.5406,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hokkaidō",
    "capital": "",
    "population": 18652,
    "id": 1392487129
  },
  {
    "city": "Vlotho",
    "city_ascii": "Vlotho",
    "lat": 52.1667,
    "lng": 8.8497,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 18429,
    "id": 1276438597
  },
  {
    "city": "Kavalerovo",
    "city_ascii": "Kavalerovo",
    "lat": 44.2702,
    "lng": 135.0498,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Primorskiy Kray",
    "capital": "",
    "population": 18657,
    "id": 1643093138
  },
  {
    "city": "Bay City",
    "city_ascii": "Bay City",
    "lat": 28.9838,
    "lng": -95.9601,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 18661,
    "id": 1840019682
  },
  {
    "city": "Bad Dürkheim",
    "city_ascii": "Bad Durkheim",
    "lat": 49.4618,
    "lng": 8.1724,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Rhineland-Palatinate",
    "capital": "minor",
    "population": 18476,
    "id": 1276602357
  },
  {
    "city": "Hochheim am Main",
    "city_ascii": "Hochheim am Main",
    "lat": 50.0108,
    "lng": 8.3508,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 17743,
    "id": 1276794905
  },
  {
    "city": "Balş",
    "city_ascii": "Bals",
    "lat": 44.3542,
    "lng": 24.0986,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Olt",
    "capital": "",
    "population": 18164,
    "id": 1642139572
  },
  {
    "city": "Ansonia",
    "city_ascii": "Ansonia",
    "lat": 41.3443,
    "lng": -73.0689,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 18654,
    "id": 1840004846
  },
  {
    "city": "Weißenburg",
    "city_ascii": "Weissenburg",
    "lat": 49.0306,
    "lng": 10.9719,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "minor",
    "population": 18464,
    "id": 1276099657
  },
  {
    "city": "Bremervörde",
    "city_ascii": "Bremervorde",
    "lat": 53.4833,
    "lng": 9.1333,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 18528,
    "id": 1276000575
  },
  {
    "city": "Deerfield",
    "city_ascii": "Deerfield",
    "lat": 42.1654,
    "lng": -87.8516,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 18646,
    "id": 1840011167
  },
  {
    "city": "South Frontenac",
    "city_ascii": "South Frontenac",
    "lat": 44.5081,
    "lng": -76.4939,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 18646,
    "id": 1124000063
  },
  {
    "city": "Westminster",
    "city_ascii": "Westminster",
    "lat": 39.5796,
    "lng": -77.0066,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 18640,
    "id": 1840005701
  },
  {
    "city": "Kulu",
    "city_ascii": "Kulu",
    "lat": 31.96,
    "lng": 77.1,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Himāchal Pradesh",
    "capital": "",
    "population": 18536,
    "id": 1356863384
  },
  {
    "city": "Nytva",
    "city_ascii": "Nytva",
    "lat": 57.9333,
    "lng": 55.3333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Permskiy Kray",
    "capital": "minor",
    "population": 18608,
    "id": 1643014234
  },
  {
    "city": "Oyama",
    "city_ascii": "Oyama",
    "lat": 35.3667,
    "lng": 138.9833,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Shizuoka",
    "capital": "",
    "population": 18491,
    "id": 1392334632
  },
  {
    "city": "Olten",
    "city_ascii": "Olten",
    "lat": 47.3531,
    "lng": 7.9078,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Solothurn",
    "capital": "",
    "population": 17133,
    "id": 1756558985
  },
  {
    "city": "Grafton",
    "city_ascii": "Grafton",
    "lat": 42.2085,
    "lng": -71.6838,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 18624,
    "id": 1840053690
  },
  {
    "city": "Creve Coeur",
    "city_ascii": "Creve Coeur",
    "lat": 38.6621,
    "lng": -90.443,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 18622,
    "id": 1840007443
  },
  {
    "city": "Hochelheim",
    "city_ascii": "Hochelheim",
    "lat": 50.5167,
    "lng": 8.7,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 18143,
    "id": 1276814959
  },
  {
    "city": "Vanino",
    "city_ascii": "Vanino",
    "lat": 49.0873,
    "lng": 140.2425,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Khabarovskiy Kray",
    "capital": "",
    "population": 18618,
    "id": 1643490098
  },
  {
    "city": "Águas de Lindóia",
    "city_ascii": "Aguas de Lindoia",
    "lat": -22.4758,
    "lng": -46.6328,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 18313,
    "id": 1076621560
  },
  {
    "city": "Skadovs’k",
    "city_ascii": "Skadovs'k",
    "lat": 46.1167,
    "lng": 32.9167,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Khersons’ka Oblast’",
    "capital": "minor",
    "population": 18482,
    "id": 1804206912
  },
  {
    "city": "Orestiáda",
    "city_ascii": "Orestiada",
    "lat": 41.5,
    "lng": 26.5333,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Anatolikí Makedonía kai Thráki",
    "capital": "minor",
    "population": 18426,
    "id": 1300874812
  },
  {
    "city": "Berea",
    "city_ascii": "Berea",
    "lat": 41.3696,
    "lng": -81.8642,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 18609,
    "id": 1840000588
  },
  {
    "city": "Cocoa",
    "city_ascii": "Cocoa",
    "lat": 28.382,
    "lng": -80.7674,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 18603,
    "id": 1840015092
  },
  {
    "city": "Moultrie",
    "city_ascii": "Moultrie",
    "lat": 31.1591,
    "lng": -83.7708,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 18602,
    "id": 1840014977
  },
  {
    "city": "Berkhampstead",
    "city_ascii": "Berkhampstead",
    "lat": 51.76,
    "lng": -0.56,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hertfordshire",
    "capital": "",
    "population": 18500,
    "id": 1826863985
  },
  {
    "city": "Houghton Regis",
    "city_ascii": "Houghton Regis",
    "lat": 51.9039,
    "lng": -0.5247,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Central Bedfordshire",
    "capital": "",
    "population": 17280,
    "id": 1826997690
  },
  {
    "city": "Scituate",
    "city_ascii": "Scituate",
    "lat": 42.1992,
    "lng": -70.759,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 18591,
    "id": 1840053577
  },
  {
    "city": "Pardés H̱anna Karkur",
    "city_ascii": "Pardes Hanna Karkur",
    "lat": 32.4711,
    "lng": 34.9675,
    "country": "Israel",
    "iso2": "IL",
    "iso3": "ISR",
    "admin_name": "Haifa",
    "capital": "",
    "population": 17800,
    "id": 1376163698
  },
  {
    "city": "Forest Park",
    "city_ascii": "Forest Park",
    "lat": 39.2861,
    "lng": -84.5259,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 18583,
    "id": 1840003818
  },
  {
    "city": "Rikuzen-Takata",
    "city_ascii": "Rikuzen-Takata",
    "lat": 39.0153,
    "lng": 141.6294,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Iwate",
    "capital": "",
    "population": 18500,
    "id": 1392211075
  },
  {
    "city": "Pitt Meadows",
    "city_ascii": "Pitt Meadows",
    "lat": 49.2333,
    "lng": -122.6833,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "British Columbia",
    "capital": "",
    "population": 18573,
    "id": 1124786902
  },
  {
    "city": "Kristiansund",
    "city_ascii": "Kristiansund",
    "lat": 63.1105,
    "lng": 7.7279,
    "country": "Norway",
    "iso2": "NO",
    "iso3": "NOR",
    "admin_name": "Møre og Romsdal",
    "capital": "minor",
    "population": 18273,
    "id": 1578717143
  },
  {
    "city": "Priozërsk",
    "city_ascii": "Priozersk",
    "lat": 61.0393,
    "lng": 30.1291,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Leningradskaya Oblast’",
    "capital": "",
    "population": 18552,
    "id": 1643755142
  },
  {
    "city": "Santa Cruz Zenzontepec",
    "city_ascii": "Santa Cruz Zenzontepec",
    "lat": 16.5333,
    "lng": -97.5,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Oaxaca",
    "capital": "",
    "population": 18512,
    "id": 1484403131
  },
  {
    "city": "Anacortes",
    "city_ascii": "Anacortes",
    "lat": 48.4878,
    "lng": -122.6292,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 18563,
    "id": 1840018366
  },
  {
    "city": "Five Corners",
    "city_ascii": "Five Corners",
    "lat": 45.6883,
    "lng": -122.5738,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 18559,
    "id": 1840037869
  },
  {
    "city": "Lahnstein",
    "city_ascii": "Lahnstein",
    "lat": 50.3011,
    "lng": 7.6056,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Rhineland-Palatinate",
    "capital": "",
    "population": 18067,
    "id": 1276335770
  },
  {
    "city": "Asunción Nochixtlán",
    "city_ascii": "Asuncion Nochixtlan",
    "lat": 17.4592,
    "lng": -97.2261,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Oaxaca",
    "capital": "minor",
    "population": 18525,
    "id": 1484302009
  },
  {
    "city": "Wilton",
    "city_ascii": "Wilton",
    "lat": 41.207,
    "lng": -73.4401,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 18542,
    "id": 1840045106
  },
  {
    "city": "Międzyrzecz",
    "city_ascii": "Miedzyrzecz",
    "lat": 52.4446,
    "lng": 15.578,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Lubuskie",
    "capital": "minor",
    "population": 18459,
    "id": 1616321580
  },
  {
    "city": "Tha Bo",
    "city_ascii": "Tha Bo",
    "lat": 17.8494,
    "lng": 102.5858,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Nong Khai",
    "capital": "minor",
    "population": 18320,
    "id": 1764956322
  },
  {
    "city": "Sam Phran",
    "city_ascii": "Sam Phran",
    "lat": 13.727,
    "lng": 100.2153,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Nakhon Pathom",
    "capital": "minor",
    "population": 17611,
    "id": 1764639144
  },
  {
    "city": "Memuro-minami",
    "city_ascii": "Memuro-minami",
    "lat": 42.9119,
    "lng": 143.0508,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hokkaidō",
    "capital": "",
    "population": 18497,
    "id": 1392837636
  },
  {
    "city": "Bramhall",
    "city_ascii": "Bramhall",
    "lat": 53.357,
    "lng": -2.164,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Stockport",
    "capital": "",
    "population": 17436,
    "id": 1826643172
  },
  {
    "city": "San Blas Atempa",
    "city_ascii": "San Blas Atempa",
    "lat": 16.3167,
    "lng": -95.2167,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Oaxaca",
    "capital": "minor",
    "population": 18406,
    "id": 1484548529
  },
  {
    "city": "Rancho Mirage",
    "city_ascii": "Rancho Mirage",
    "lat": 33.7634,
    "lng": -116.4271,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 18528,
    "id": 1840020558
  },
  {
    "city": "Kadaň",
    "city_ascii": "Kadan",
    "lat": 50.3761,
    "lng": 13.2714,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Ústecký Kraj",
    "capital": "",
    "population": 18246,
    "id": 1203388944
  },
  {
    "city": "Washington Township",
    "city_ascii": "Washington Township",
    "lat": 40.7877,
    "lng": -74.7918,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 18521,
    "id": 1840144404
  },
  {
    "city": "Kostrzyn nad Odrą",
    "city_ascii": "Kostrzyn nad Odra",
    "lat": 52.5883,
    "lng": 14.6667,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Lubuskie",
    "capital": "",
    "population": 18125,
    "id": 1616354192
  },
  {
    "city": "Dniprorudne",
    "city_ascii": "Dniprorudne",
    "lat": 47.3855,
    "lng": 34.9879,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Zaporiz’ka Oblast’",
    "capital": "",
    "population": 18468,
    "id": 1804975764
  },
  {
    "city": "Lynden",
    "city_ascii": "Lynden",
    "lat": 48.9502,
    "lng": -122.4545,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 18512,
    "id": 1840019752
  },
  {
    "city": "Braniewo",
    "city_ascii": "Braniewo",
    "lat": 54.3833,
    "lng": 19.8333,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Warmińsko-Mazurskie",
    "capital": "minor",
    "population": 17123,
    "id": 1616740656
  },
  {
    "city": "Swellendam",
    "city_ascii": "Swellendam",
    "lat": -34.0231,
    "lng": 20.44,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "Western Cape",
    "capital": "",
    "population": 17537,
    "id": 1710416218
  },
  {
    "city": "Beloozërskiy",
    "city_ascii": "Beloozerskiy",
    "lat": 55.4614,
    "lng": 38.4422,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Moskovskaya Oblast’",
    "capital": "",
    "population": 18297,
    "id": 1643491121
  },
  {
    "city": "Middle",
    "city_ascii": "Middle",
    "lat": 39.0851,
    "lng": -74.8336,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 18492,
    "id": 1840081576
  },
  {
    "city": "Gautier",
    "city_ascii": "Gautier",
    "lat": 30.4106,
    "lng": -88.6568,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Mississippi",
    "capital": "",
    "population": 18490,
    "id": 1840013937
  },
  {
    "city": "Luau",
    "city_ascii": "Luau",
    "lat": -10.7095,
    "lng": 22.23,
    "country": "Angola",
    "iso2": "AO",
    "iso3": "AGO",
    "admin_name": "Moxico",
    "capital": "",
    "population": 18465,
    "id": 1024699465
  },
  {
    "city": "Levin",
    "city_ascii": "Levin",
    "lat": -40.625,
    "lng": 175.2833,
    "country": "New Zealand",
    "iso2": "NZ",
    "iso3": "NZL",
    "admin_name": "Manawatu-Wanganui",
    "capital": "",
    "population": 17700,
    "id": 1554983635
  },
  {
    "city": "Nara",
    "city_ascii": "Nara",
    "lat": 15.18,
    "lng": -7.28,
    "country": "Mali",
    "iso2": "ML",
    "iso3": "MLI",
    "admin_name": "Koulikoro",
    "capital": "minor",
    "population": 18459,
    "id": 1466925477
  },
  {
    "city": "Humacao",
    "city_ascii": "Humacao",
    "lat": 18.1519,
    "lng": -65.8204,
    "country": "Puerto Rico",
    "iso2": "PR",
    "iso3": "PRI",
    "admin_name": "Puerto Rico",
    "capital": "",
    "population": 18487,
    "id": 1630035683
  },
  {
    "city": "Mayfield Heights",
    "city_ascii": "Mayfield Heights",
    "lat": 41.5175,
    "lng": -81.4534,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 18487,
    "id": 1840000613
  },
  {
    "city": "Breaux Bridge",
    "city_ascii": "Breaux Bridge",
    "lat": 30.2829,
    "lng": -91.9043,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 18484,
    "id": 1840013960
  },
  {
    "city": "Guadix",
    "city_ascii": "Guadix",
    "lat": 37.2996,
    "lng": -3.1371,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Andalusia",
    "capital": "",
    "population": 18422,
    "id": 1724022918
  },
  {
    "city": "Almondbury",
    "city_ascii": "Almondbury",
    "lat": 53.6344,
    "lng": -1.7489,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Kirklees",
    "capital": "",
    "population": 18346,
    "id": 1826676976
  },
  {
    "city": "Taft",
    "city_ascii": "Taft",
    "lat": 31.7475,
    "lng": 54.2042,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Yazd",
    "capital": "minor",
    "population": 18464,
    "id": 1364828948
  },
  {
    "city": "Hopkins",
    "city_ascii": "Hopkins",
    "lat": 44.9261,
    "lng": -93.4056,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 18468,
    "id": 1840006749
  },
  {
    "city": "Békés",
    "city_ascii": "Bekes",
    "lat": 46.7769,
    "lng": 21.125,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Békés",
    "capital": "minor",
    "population": 18322,
    "id": 1348964497
  },
  {
    "city": "Kobyłka",
    "city_ascii": "Kobylka",
    "lat": 52.3395,
    "lng": 21.1959,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Mazowieckie",
    "capital": "",
    "population": 17586,
    "id": 1616520686
  },
  {
    "city": "Defiance",
    "city_ascii": "Defiance",
    "lat": 41.281,
    "lng": -84.3661,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 18460,
    "id": 1840007133
  },
  {
    "city": "Monor",
    "city_ascii": "Monor",
    "lat": 47.3475,
    "lng": 19.4489,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Pest",
    "capital": "minor",
    "population": 18084,
    "id": 1348049694
  },
  {
    "city": "Yur’yev-Pol’skiy",
    "city_ascii": "Yur'yev-Pol'skiy",
    "lat": 56.5,
    "lng": 39.6833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Vladimirskaya Oblast’",
    "capital": "",
    "population": 18433,
    "id": 1643130630
  },
  {
    "city": "Korsun’-Shevchenkivs’kyy",
    "city_ascii": "Korsun'-Shevchenkivs'kyy",
    "lat": 49.4261,
    "lng": 31.2806,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Cherkas’ka Oblast’",
    "capital": "minor",
    "population": 18401,
    "id": 1804512301
  },
  {
    "city": "Mesquite",
    "city_ascii": "Mesquite",
    "lat": 36.8035,
    "lng": -114.1325,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Nevada",
    "capital": "",
    "population": 18445,
    "id": 1840020362
  },
  {
    "city": "Amvrosiivka",
    "city_ascii": "Amvrosiivka",
    "lat": 47.7958,
    "lng": 38.4801,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Donets’ka Oblast’",
    "capital": "minor",
    "population": 18413,
    "id": 1804821911
  },
  {
    "city": "Svatove",
    "city_ascii": "Svatove",
    "lat": 49.4092,
    "lng": 38.1619,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Luhans’ka Oblast’",
    "capital": "minor",
    "population": 18417,
    "id": 1804850971
  },
  {
    "city": "Stonington",
    "city_ascii": "Stonington",
    "lat": 41.3738,
    "lng": -71.9034,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 18436,
    "id": 1840004829
  },
  {
    "city": "Panagyurishte",
    "city_ascii": "Panagyurishte",
    "lat": 42.5047,
    "lng": 24.1892,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Pazardzhik",
    "capital": "minor",
    "population": 18350,
    "id": 1100223841
  },
  {
    "city": "Iron Mountain",
    "city_ascii": "Iron Mountain",
    "lat": 45.8275,
    "lng": -88.0599,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 18432,
    "id": 1840001991
  },
  {
    "city": "Durleşti",
    "city_ascii": "Durlesti",
    "lat": 47.0178,
    "lng": 28.7625,
    "country": "Moldova",
    "iso2": "MD",
    "iso3": "MDA",
    "admin_name": "Chişinău",
    "capital": "",
    "population": 17210,
    "id": 1498757203
  },
  {
    "city": "Schwechat",
    "city_ascii": "Schwechat",
    "lat": 48.1411,
    "lng": 16.4786,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Niederösterreich",
    "capital": "",
    "population": 18026,
    "id": 1040189871
  },
  {
    "city": "Golcar",
    "city_ascii": "Golcar",
    "lat": 53.6378,
    "lng": -1.8457,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Kirklees",
    "capital": "",
    "population": 17000,
    "id": 1826648142
  },
  {
    "city": "Berea",
    "city_ascii": "Berea",
    "lat": 37.5904,
    "lng": -84.2898,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kentucky",
    "capital": "",
    "population": 18423,
    "id": 1840013225
  },
  {
    "city": "Altus",
    "city_ascii": "Altus",
    "lat": 34.6566,
    "lng": -99.3051,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oklahoma",
    "capital": "",
    "population": 18418,
    "id": 1840019202
  },
  {
    "city": "Oroszlány",
    "city_ascii": "Oroszlany",
    "lat": 47.4892,
    "lng": 18.3164,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Komárom-Esztergom",
    "capital": "minor",
    "population": 18171,
    "id": 1348109196
  },
  {
    "city": "Uvalde",
    "city_ascii": "Uvalde",
    "lat": 29.2153,
    "lng": -99.7782,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 18406,
    "id": 1840022231
  },
  {
    "city": "West Whiteland",
    "city_ascii": "West Whiteland",
    "lat": 40.0227,
    "lng": -75.6239,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 18403,
    "id": 1840146650
  },
  {
    "city": "Sharon",
    "city_ascii": "Sharon",
    "lat": 42.1085,
    "lng": -71.183,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 18400,
    "id": 1840053557
  },
  {
    "city": "Acatlán",
    "city_ascii": "Acatlan",
    "lat": 20.1444,
    "lng": -98.4383,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Hidalgo",
    "capital": "minor",
    "population": 17914,
    "id": 1484652472
  },
  {
    "city": "Brook Park",
    "city_ascii": "Brook Park",
    "lat": 41.4036,
    "lng": -81.8219,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 18382,
    "id": 1840000594
  },
  {
    "city": "Whitestown",
    "city_ascii": "Whitestown",
    "lat": 43.135,
    "lng": -75.3404,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 18377,
    "id": 1840088046
  },
  {
    "city": "Hampton",
    "city_ascii": "Hampton",
    "lat": 40.5844,
    "lng": -79.9534,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 18365,
    "id": 1840147156
  },
  {
    "city": "Hranice",
    "city_ascii": "Hranice",
    "lat": 49.548,
    "lng": 17.7347,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Olomoucký Kraj",
    "capital": "",
    "population": 17999,
    "id": 1203110445
  },
  {
    "city": "Bas-Warneton",
    "city_ascii": "Bas-Warneton",
    "lat": 50.7667,
    "lng": 3,
    "country": "Belgium",
    "iso2": "BE",
    "iso3": "BEL",
    "admin_name": "Wallonia",
    "capital": "",
    "population": 18063,
    "id": 1056944578
  },
  {
    "city": "Großenhain",
    "city_ascii": "Grossenhain",
    "lat": 51.2833,
    "lng": 13.55,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 18218,
    "id": 1276062611
  },
  {
    "city": "Sunnyside",
    "city_ascii": "Sunnyside",
    "lat": 46.3158,
    "lng": -120.0059,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 18352,
    "id": 1840021150
  },
  {
    "city": "Garching bei München",
    "city_ascii": "Garching bei Munchen",
    "lat": 48.25,
    "lng": 11.65,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 17711,
    "id": 1276544473
  },
  {
    "city": "Bet She’an",
    "city_ascii": "Bet She'an",
    "lat": 32.4961,
    "lng": 35.4989,
    "country": "Israel",
    "iso2": "IL",
    "iso3": "ISR",
    "admin_name": "Northern",
    "capital": "",
    "population": 18200,
    "id": 1376100451
  },
  {
    "city": "Fulwood",
    "city_ascii": "Fulwood",
    "lat": 53.365,
    "lng": -1.544,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Sheffield",
    "capital": "",
    "population": 18233,
    "id": 1826986011
  },
  {
    "city": "Avon",
    "city_ascii": "Avon",
    "lat": 41.7907,
    "lng": -72.8538,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 18338,
    "id": 1840034005
  },
  {
    "city": "Schwalmstadt",
    "city_ascii": "Schwalmstadt",
    "lat": 50.9128,
    "lng": 9.1889,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 18122,
    "id": 1276496894
  },
  {
    "city": "Forst",
    "city_ascii": "Forst",
    "lat": 51.7333,
    "lng": 14.6333,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Brandenburg",
    "capital": "minor",
    "population": 18164,
    "id": 1276307453
  },
  {
    "city": "Nuuk",
    "city_ascii": "Nuuk",
    "lat": 64.175,
    "lng": -51.7333,
    "country": "Greenland",
    "iso2": "GL",
    "iso3": "GRL",
    "admin_name": "Sermersooq",
    "capital": "",
    "population": 18326,
    "id": 1304028354
  },
  {
    "city": "Dobrush",
    "city_ascii": "Dobrush",
    "lat": 52.4167,
    "lng": 31.3167,
    "country": "Belarus",
    "iso2": "BY",
    "iso3": "BLR",
    "admin_name": "Homyel’skaya Voblasts’",
    "capital": "minor",
    "population": 18300,
    "id": 1112814087
  },
  {
    "city": "Sycamore",
    "city_ascii": "Sycamore",
    "lat": 41.9951,
    "lng": -88.6812,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 18322,
    "id": 1840009187
  },
  {
    "city": "Wallingford Center",
    "city_ascii": "Wallingford Center",
    "lat": 41.4499,
    "lng": -72.8189,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 18322,
    "id": 1840073312
  },
  {
    "city": "Charata",
    "city_ascii": "Charata",
    "lat": -27.2163,
    "lng": -61.2,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Chaco",
    "capital": "minor",
    "population": 18297,
    "id": 1032241417
  },
  {
    "city": "Aberdeen",
    "city_ascii": "Aberdeen",
    "lat": 40.4165,
    "lng": -74.2249,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 18317,
    "id": 1840081652
  },
  {
    "city": "Sikhio",
    "city_ascii": "Sikhio",
    "lat": 14.9,
    "lng": 101.7167,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Nakhon Ratchasima",
    "capital": "minor",
    "population": 18209,
    "id": 1764681503
  },
  {
    "city": "Chegem Vtoroy",
    "city_ascii": "Chegem Vtoroy",
    "lat": 43.5633,
    "lng": 43.5864,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kabardino-Balkariya",
    "capital": "",
    "population": 18264,
    "id": 1643003953
  },
  {
    "city": "Port Colborne",
    "city_ascii": "Port Colborne",
    "lat": 42.8833,
    "lng": -79.25,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 18306,
    "id": 1124274319
  },
  {
    "city": "Pembroke",
    "city_ascii": "Pembroke",
    "lat": 42.0655,
    "lng": -70.8014,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 18304,
    "id": 1840053573
  },
  {
    "city": "Ronkonkoma",
    "city_ascii": "Ronkonkoma",
    "lat": 40.804,
    "lng": -73.1258,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 18302,
    "id": 1840005071
  },
  {
    "city": "Maltby",
    "city_ascii": "Maltby",
    "lat": 53.426,
    "lng": -1.21,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Rotherham",
    "capital": "",
    "population": 16688,
    "id": 1826798518
  },
  {
    "city": "Lobos",
    "city_ascii": "Lobos",
    "lat": -35.1864,
    "lng": -59.0961,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires",
    "capital": "minor",
    "population": 18278,
    "id": 1032314483
  },
  {
    "city": "Slyudyanka",
    "city_ascii": "Slyudyanka",
    "lat": 51.6594,
    "lng": 103.7061,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Irkutskaya Oblast’",
    "capital": "",
    "population": 18287,
    "id": 1643956172
  },
  {
    "city": "Buckhaven",
    "city_ascii": "Buckhaven",
    "lat": 56.1776,
    "lng": -3.0303,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Fife",
    "capital": "",
    "population": 16391,
    "id": 1826258031
  },
  {
    "city": "Newburyport",
    "city_ascii": "Newburyport",
    "lat": 42.8124,
    "lng": -70.8878,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 18289,
    "id": 1840000413
  },
  {
    "city": "Colina",
    "city_ascii": "Colina",
    "lat": -20.7136,
    "lng": -48.5411,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 18245,
    "id": 1076416101
  },
  {
    "city": "Schkeuditz",
    "city_ascii": "Schkeuditz",
    "lat": 51.4,
    "lng": 12.2167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 18066,
    "id": 1276228794
  },
  {
    "city": "Cimarron Hills",
    "city_ascii": "Cimarron Hills",
    "lat": 38.8597,
    "lng": -104.6995,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 18285,
    "id": 1840028574
  },
  {
    "city": "Hessisch Oldendorf",
    "city_ascii": "Hessisch Oldendorf",
    "lat": 52.1667,
    "lng": 9.25,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 18130,
    "id": 1276530384
  },
  {
    "city": "Xochiatipan de Castillo",
    "city_ascii": "Xochiatipan de Castillo",
    "lat": 20.8333,
    "lng": -98.285,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Hidalgo",
    "capital": "",
    "population": 18157,
    "id": 1484688256
  },
  {
    "city": "Towamencin",
    "city_ascii": "Towamencin",
    "lat": 40.2417,
    "lng": -75.3387,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 18273,
    "id": 1840152389
  },
  {
    "city": "Isfana",
    "city_ascii": "Isfana",
    "lat": 39.8333,
    "lng": 69.5167,
    "country": "Kyrgyzstan",
    "iso2": "KG",
    "iso3": "KGZ",
    "admin_name": "Batken",
    "capital": "minor",
    "population": 18244,
    "id": 1417781894
  },
  {
    "city": "Werdohl",
    "city_ascii": "Werdohl",
    "lat": 51.2563,
    "lng": 7.7562,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 17737,
    "id": 1276252108
  },
  {
    "city": "Kilgore",
    "city_ascii": "Kilgore",
    "lat": 32.3979,
    "lng": -94.8603,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 18265,
    "id": 1840020747
  },
  {
    "city": "Wörth am Rhein",
    "city_ascii": "Worth am Rhein",
    "lat": 49.0517,
    "lng": 8.2603,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Rhineland-Palatinate",
    "capital": "",
    "population": 18123,
    "id": 1276803392
  },
  {
    "city": "Édessa",
    "city_ascii": "Edessa",
    "lat": 40.8,
    "lng": 22.05,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Kentrikí Makedonía",
    "capital": "minor",
    "population": 18229,
    "id": 1300917524
  },
  {
    "city": "Băicoi",
    "city_ascii": "Baicoi",
    "lat": 45.0453,
    "lng": 25.8658,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Prahova",
    "capital": "",
    "population": 17981,
    "id": 1642646801
  },
  {
    "city": "Radford",
    "city_ascii": "Radford",
    "lat": 37.1229,
    "lng": -80.5587,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 18249,
    "id": 1840003864
  },
  {
    "city": "Quispamsis",
    "city_ascii": "Quispamsis",
    "lat": 45.4322,
    "lng": -65.9462,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "New Brunswick",
    "capital": "",
    "population": 18245,
    "id": 1124000379
  },
  {
    "city": "Palestine",
    "city_ascii": "Palestine",
    "lat": 31.7544,
    "lng": -95.6471,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 18243,
    "id": 1840020798
  },
  {
    "city": "Crawfordsville",
    "city_ascii": "Crawfordsville",
    "lat": 40.0428,
    "lng": -86.8975,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 18242,
    "id": 1840007261
  },
  {
    "city": "Springfield",
    "city_ascii": "Springfield",
    "lat": 36.4943,
    "lng": -86.8709,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 18241,
    "id": 1840015273
  },
  {
    "city": "Púchov",
    "city_ascii": "Puchov",
    "lat": 49.12,
    "lng": 18.3306,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Nitriansky",
    "capital": "minor",
    "population": 17810,
    "id": 1703321833
  },
  {
    "city": "Grangemouth",
    "city_ascii": "Grangemouth",
    "lat": 56.012,
    "lng": -3.717,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Falkirk",
    "capital": "",
    "population": 17906,
    "id": 1826973593
  },
  {
    "city": "Pieksämäki",
    "city_ascii": "Pieksamaki",
    "lat": 62.3,
    "lng": 27.1583,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Etelä-Savo",
    "capital": "minor",
    "population": 18220,
    "id": 1246171745
  },
  {
    "city": "Lake Placid",
    "city_ascii": "Lake Placid",
    "lat": 27.2977,
    "lng": -81.3715,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 18227,
    "id": 1840017248
  },
  {
    "city": "Boerne",
    "city_ascii": "Boerne",
    "lat": 29.7844,
    "lng": -98.7289,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 18225,
    "id": 1840019623
  },
  {
    "city": "Dombóvár",
    "city_ascii": "Dombovar",
    "lat": 46.3769,
    "lng": 18.131,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Tolna",
    "capital": "minor",
    "population": 17995,
    "id": 1348286136
  },
  {
    "city": "Southeast",
    "city_ascii": "Southeast",
    "lat": 41.4032,
    "lng": -73.5985,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 18218,
    "id": 1840087927
  },
  {
    "city": "Mŭynoq",
    "city_ascii": "Muynoq",
    "lat": 43.7667,
    "lng": 59.0333,
    "country": "Uzbekistan",
    "iso2": "UZ",
    "iso3": "UZB",
    "admin_name": "Qoraqalpog‘iston",
    "capital": "",
    "population": 18196,
    "id": 1860204414
  },
  {
    "city": "Somerset",
    "city_ascii": "Somerset",
    "lat": 41.7404,
    "lng": -71.1612,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 18207,
    "id": 1840053662
  },
  {
    "city": "St. Michael",
    "city_ascii": "St. Michael",
    "lat": 45.2014,
    "lng": -93.692,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 18204,
    "id": 1840008901
  },
  {
    "city": "Īlām",
    "city_ascii": "Ilam",
    "lat": 26.908,
    "lng": 87.926,
    "country": "Nepal",
    "iso2": "NP",
    "iso3": "NPL",
    "admin_name": "Mechī",
    "capital": "",
    "population": 17491,
    "id": 1524034598
  },
  {
    "city": "Mont-Saint-Hilaire",
    "city_ascii": "Mont-Saint-Hilaire",
    "lat": 45.5622,
    "lng": -73.1917,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 18200,
    "id": 1124333461
  },
  {
    "city": "East Goshen",
    "city_ascii": "East Goshen",
    "lat": 39.9934,
    "lng": -75.5478,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 18199,
    "id": 1840150925
  },
  {
    "city": "Maumelle",
    "city_ascii": "Maumelle",
    "lat": 34.8522,
    "lng": -92.4,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arkansas",
    "capital": "",
    "population": 18199,
    "id": 1840015510
  },
  {
    "city": "Madison",
    "city_ascii": "Madison",
    "lat": 41.3398,
    "lng": -72.6278,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 18183,
    "id": 1840045038
  },
  {
    "city": "Bad Segeberg",
    "city_ascii": "Bad Segeberg",
    "lat": 53.9356,
    "lng": 10.3089,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Schleswig-Holstein",
    "capital": "minor",
    "population": 17267,
    "id": 1276181220
  },
  {
    "city": "Parchim",
    "city_ascii": "Parchim",
    "lat": 53.4167,
    "lng": 11.8333,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Mecklenburg-Western Pomerania",
    "capital": "minor",
    "population": 18037,
    "id": 1276781173
  },
  {
    "city": "Wheatfield",
    "city_ascii": "Wheatfield",
    "lat": 43.0975,
    "lng": -78.8831,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 18179,
    "id": 1840088041
  },
  {
    "city": "Monthey",
    "city_ascii": "Monthey",
    "lat": 46.25,
    "lng": 6.95,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Valais",
    "capital": "",
    "population": 17563,
    "id": 1756628597
  },
  {
    "city": "Niles",
    "city_ascii": "Niles",
    "lat": 41.1878,
    "lng": -80.753,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 18176,
    "id": 1840008241
  },
  {
    "city": "Pratteln",
    "city_ascii": "Pratteln",
    "lat": 47.5185,
    "lng": 7.6928,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Basel-Landschaft",
    "capital": "",
    "population": 16621,
    "id": 1756235773
  },
  {
    "city": "Malacky",
    "city_ascii": "Malacky",
    "lat": 48.4381,
    "lng": 17.0236,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Bratislavský",
    "capital": "minor",
    "population": 17430,
    "id": 1703320479
  },
  {
    "city": "Lutuhyne",
    "city_ascii": "Lutuhyne",
    "lat": 48.4019,
    "lng": 39.2103,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Luhans’ka Oblast’",
    "capital": "minor",
    "population": 18107,
    "id": 1804746630
  },
  {
    "city": "Menomonie",
    "city_ascii": "Menomonie",
    "lat": 44.8893,
    "lng": -91.9084,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 18159,
    "id": 1840037988
  },
  {
    "city": "Trenton",
    "city_ascii": "Trenton",
    "lat": 42.1394,
    "lng": -83.1929,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 18157,
    "id": 1840003966
  },
  {
    "city": "Kiruna",
    "city_ascii": "Kiruna",
    "lat": 67.85,
    "lng": 20.2166,
    "country": "Sweden",
    "iso2": "SE",
    "iso3": "SWE",
    "admin_name": "Norrbotten",
    "capital": "minor",
    "population": 18154,
    "id": 1752001526
  },
  {
    "city": "Bathurst",
    "city_ascii": "Bathurst",
    "lat": 47.62,
    "lng": -65.65,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "New Brunswick",
    "capital": "",
    "population": 18154,
    "id": 1124816720
  },
  {
    "city": "Paraibuna",
    "city_ascii": "Paraibuna",
    "lat": -23.3861,
    "lng": -45.6622,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 18125,
    "id": 1076100986
  },
  {
    "city": "Bexbach",
    "city_ascii": "Bexbach",
    "lat": 49.3494,
    "lng": 7.2594,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saarland",
    "capital": "",
    "population": 17577,
    "id": 1276508289
  },
  {
    "city": "Saint-Augustin-de-Desmaures",
    "city_ascii": "Saint-Augustin-de-Desmaures",
    "lat": 46.7333,
    "lng": -71.4667,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 18141,
    "id": 1124000358
  },
  {
    "city": "Diego de Almagro",
    "city_ascii": "Diego de Almagro",
    "lat": -26.3696,
    "lng": -70.05,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Atacama",
    "capital": "",
    "population": 18137,
    "id": 1152585849
  },
  {
    "city": "Rutland",
    "city_ascii": "Rutland",
    "lat": 43.6091,
    "lng": -72.9782,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Vermont",
    "capital": "",
    "population": 18131,
    "id": 1840002632
  },
  {
    "city": "Bloemhof",
    "city_ascii": "Bloemhof",
    "lat": -27.65,
    "lng": 25.59,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "North West",
    "capital": "",
    "population": 17122,
    "id": 1710321800
  },
  {
    "city": "Hassi Messaoud",
    "city_ascii": "Hassi Messaoud",
    "lat": 31.7023,
    "lng": 6.0545,
    "country": "Algeria",
    "iso2": "DZ",
    "iso3": "DZA",
    "admin_name": "Ouargla",
    "capital": "",
    "population": 18124,
    "id": 1012339655
  },
  {
    "city": "Imperial",
    "city_ascii": "Imperial",
    "lat": 32.839,
    "lng": -115.5719,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 18120,
    "id": 1840019381
  },
  {
    "city": "Orange",
    "city_ascii": "Orange",
    "lat": 30.121,
    "lng": -93.7616,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 18118,
    "id": 1840020910
  },
  {
    "city": "Otsego",
    "city_ascii": "Otsego",
    "lat": 45.266,
    "lng": -93.62,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 18113,
    "id": 1840008899
  },
  {
    "city": "Madeley",
    "city_ascii": "Madeley",
    "lat": 52.637,
    "lng": -2.448,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Telford and Wrekin",
    "capital": "",
    "population": 17935,
    "id": 1826979637
  },
  {
    "city": "Madison",
    "city_ascii": "Madison",
    "lat": 38.7581,
    "lng": -85.3974,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 18109,
    "id": 1840009728
  },
  {
    "city": "Alton",
    "city_ascii": "Alton",
    "lat": 26.2884,
    "lng": -98.3098,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 18105,
    "id": 1840019733
  },
  {
    "city": "St. Matthews",
    "city_ascii": "St. Matthews",
    "lat": 38.2497,
    "lng": -85.6383,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kentucky",
    "capital": "",
    "population": 18105,
    "id": 1840015194
  },
  {
    "city": "Cudahy",
    "city_ascii": "Cudahy",
    "lat": 42.9467,
    "lng": -87.8641,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 18104,
    "id": 1840003045
  },
  {
    "city": "Dīzīcheh",
    "city_ascii": "Dizicheh",
    "lat": 32.3833,
    "lng": 51.5147,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Eşfahān",
    "capital": "",
    "population": 17966,
    "id": 1364315138
  },
  {
    "city": "Round Lake",
    "city_ascii": "Round Lake",
    "lat": 42.3435,
    "lng": -88.1058,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 18100,
    "id": 1840011170
  },
  {
    "city": "General Juan Madariaga",
    "city_ascii": "General Juan Madariaga",
    "lat": -37.0167,
    "lng": -57.1333,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires",
    "capital": "minor",
    "population": 18089,
    "id": 1032481870
  },
  {
    "city": "Oak Bay",
    "city_ascii": "Oak Bay",
    "lat": 48.4264,
    "lng": -123.3228,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "British Columbia",
    "capital": "",
    "population": 18094,
    "id": 1124761730
  },
  {
    "city": "Kamigotō",
    "city_ascii": "Kamigoto",
    "lat": 32.9844,
    "lng": 129.0733,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Nagasaki",
    "capital": "",
    "population": 18008,
    "id": 1392897224
  },
  {
    "city": "Heidenau",
    "city_ascii": "Heidenau",
    "lat": 50.9833,
    "lng": 13.8667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 16593,
    "id": 1276588672
  },
  {
    "city": "Freilassing",
    "city_ascii": "Freilassing",
    "lat": 47.8333,
    "lng": 12.9667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 16939,
    "id": 1276282147
  },
  {
    "city": "Tullahoma",
    "city_ascii": "Tullahoma",
    "lat": 35.3721,
    "lng": -86.2173,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 18082,
    "id": 1840015410
  },
  {
    "city": "Wantagh",
    "city_ascii": "Wantagh",
    "lat": 40.6686,
    "lng": -73.5104,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 18078,
    "id": 1840005281
  },
  {
    "city": "Calverton",
    "city_ascii": "Calverton",
    "lat": 39.0578,
    "lng": -76.9488,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 18076,
    "id": 1840031445
  },
  {
    "city": "Toba",
    "city_ascii": "Toba",
    "lat": 34.4813,
    "lng": 136.8434,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Mie",
    "capital": "",
    "population": 17907,
    "id": 1392068005
  },
  {
    "city": "Manchester",
    "city_ascii": "Manchester",
    "lat": 38.583,
    "lng": -90.5065,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 18073,
    "id": 1840009739
  },
  {
    "city": "Rypin",
    "city_ascii": "Rypin",
    "lat": 53.0667,
    "lng": 19.45,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Kujawsko-Pomorskie",
    "capital": "minor",
    "population": 16558,
    "id": 1616716547
  },
  {
    "city": "Beeville",
    "city_ascii": "Beeville",
    "lat": 28.4059,
    "lng": -97.7494,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 18068,
    "id": 1840019698
  },
  {
    "city": "Kishi",
    "city_ascii": "Kishi",
    "lat": 35.3364,
    "lng": 139.1233,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Kanagawa",
    "capital": "",
    "population": 18010,
    "id": 1392295769
  },
  {
    "city": "Cary",
    "city_ascii": "Cary",
    "lat": 42.2129,
    "lng": -88.2493,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 18067,
    "id": 1840010109
  },
  {
    "city": "North Wilkesboro",
    "city_ascii": "North Wilkesboro",
    "lat": 36.1728,
    "lng": -81.139,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 18066,
    "id": 1840016094
  },
  {
    "city": "Freudenberg",
    "city_ascii": "Freudenberg",
    "lat": 50.8997,
    "lng": 7.8667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 17739,
    "id": 1276203816
  },
  {
    "city": "Goffstown",
    "city_ascii": "Goffstown",
    "lat": 43.019,
    "lng": -71.5681,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Hampshire",
    "capital": "",
    "population": 18062,
    "id": 1840054900
  },
  {
    "city": "North Aurora",
    "city_ascii": "North Aurora",
    "lat": 41.8083,
    "lng": -88.3413,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 18057,
    "id": 1840011351
  },
  {
    "city": "Northallerton",
    "city_ascii": "Northallerton",
    "lat": 54.3378,
    "lng": -1.4285,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "North Yorkshire",
    "capital": "",
    "population": 16832,
    "id": 1826697671
  },
  {
    "city": "Spanish Lake",
    "city_ascii": "Spanish Lake",
    "lat": 38.7884,
    "lng": -90.2078,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 18048,
    "id": 1840006123
  },
  {
    "city": "Bensenville",
    "city_ascii": "Bensenville",
    "lat": 41.9593,
    "lng": -87.9433,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 18044,
    "id": 1840011398
  },
  {
    "city": "Sinzig",
    "city_ascii": "Sinzig",
    "lat": 50.5453,
    "lng": 7.2519,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Rhineland-Palatinate",
    "capital": "",
    "population": 17614,
    "id": 1276200986
  },
  {
    "city": "Eastlake",
    "city_ascii": "Eastlake",
    "lat": 41.6581,
    "lng": -81.4323,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 18042,
    "id": 1840000533
  },
  {
    "city": "Beaver Dam",
    "city_ascii": "Beaver Dam",
    "lat": 43.469,
    "lng": -88.8308,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 18041,
    "id": 1840002738
  },
  {
    "city": "Kelsterbach",
    "city_ascii": "Kelsterbach",
    "lat": 50.0688,
    "lng": 8.5301,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 16936,
    "id": 1276996005
  },
  {
    "city": "Nauen",
    "city_ascii": "Nauen",
    "lat": 52.6,
    "lng": 12.8831,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Brandenburg",
    "capital": "",
    "population": 17967,
    "id": 1276864648
  },
  {
    "city": "Qal‘ah-ye Zāl",
    "city_ascii": "Qal`ah-ye Zal",
    "lat": 37.015,
    "lng": 68.4672,
    "country": "Afghanistan",
    "iso2": "AF",
    "iso3": "AFG",
    "admin_name": "Kunduz",
    "capital": "",
    "population": 18000,
    "id": 1004481163
  },
  {
    "city": "Itaberá",
    "city_ascii": "Itabera",
    "lat": -23.8619,
    "lng": -49.1372,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 18015,
    "id": 1076280626
  },
  {
    "city": "Windham",
    "city_ascii": "Windham",
    "lat": 43.7981,
    "lng": -70.4056,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maine",
    "capital": "",
    "population": 18026,
    "id": 1840066537
  },
  {
    "city": "Safety Harbor",
    "city_ascii": "Safety Harbor",
    "lat": 28.008,
    "lng": -82.6964,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 18016,
    "id": 1840015975
  },
  {
    "city": "Mattoon",
    "city_ascii": "Mattoon",
    "lat": 39.4775,
    "lng": -88.3624,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 18016,
    "id": 1840008449
  },
  {
    "city": "Neftegorsk",
    "city_ascii": "Neftegorsk",
    "lat": 52.8,
    "lng": 51.1667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Samarskaya Oblast’",
    "capital": "",
    "population": 17990,
    "id": 1643711805
  },
  {
    "city": "Colne",
    "city_ascii": "Colne",
    "lat": 53.8554,
    "lng": -2.1756,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Lancashire",
    "capital": "",
    "population": 17855,
    "id": 1826230570
  },
  {
    "city": "Ginsheim-Gustavsburg",
    "city_ascii": "Ginsheim-Gustavsburg",
    "lat": 49.9646,
    "lng": 8.3464,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 16807,
    "id": 1276577011
  },
  {
    "city": "Requena",
    "city_ascii": "Requena",
    "lat": -5.0569,
    "lng": -73.8515,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "Loreto",
    "capital": "",
    "population": 18000,
    "id": 1604712979
  },
  {
    "city": "Dzüünharaa",
    "city_ascii": "Dzuunharaa",
    "lat": 48.853,
    "lng": 106.4594,
    "country": "Mongolia",
    "iso2": "MN",
    "iso3": "MNG",
    "admin_name": "Selenge",
    "capital": "",
    "population": 18002,
    "id": 1496200032
  },
  {
    "city": "Macomb",
    "city_ascii": "Macomb",
    "lat": 40.4721,
    "lng": -90.6816,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 18006,
    "id": 1840009411
  },
  {
    "city": "Five Forks",
    "city_ascii": "Five Forks",
    "lat": 34.8069,
    "lng": -82.2271,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 18004,
    "id": 1840013491
  },
  {
    "city": "West Columbia",
    "city_ascii": "West Columbia",
    "lat": 33.9931,
    "lng": -81.0932,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 17998,
    "id": 1840015605
  },
  {
    "city": "Oborniki",
    "city_ascii": "Oborniki",
    "lat": 52.65,
    "lng": 16.8167,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Wielkopolskie",
    "capital": "minor",
    "population": 17895,
    "id": 1616127510
  },
  {
    "city": "Mirante do Paranapanema",
    "city_ascii": "Mirante do Paranapanema",
    "lat": -22.2919,
    "lng": -51.9064,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 17979,
    "id": 1076182507
  },
  {
    "city": "Fayetteville",
    "city_ascii": "Fayetteville",
    "lat": 33.45,
    "lng": -84.4709,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 17991,
    "id": 1840013755
  },
  {
    "city": "Mosbrough",
    "city_ascii": "Mosbrough",
    "lat": 53.325,
    "lng": -1.362,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Sheffield",
    "capital": "",
    "population": 17097,
    "id": 1826344897
  },
  {
    "city": "Sunland Park",
    "city_ascii": "Sunland Park",
    "lat": 31.8194,
    "lng": -106.5943,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Mexico",
    "capital": "",
    "population": 17978,
    "id": 1840033664
  },
  {
    "city": "Kharabali",
    "city_ascii": "Kharabali",
    "lat": 47.4,
    "lng": 47.25,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Astrakhanskaya Oblast’",
    "capital": "",
    "population": 17968,
    "id": 1643134744
  },
  {
    "city": "Międzyrzec Podlaski",
    "city_ascii": "Miedzyrzec Podlaski",
    "lat": 51.9833,
    "lng": 22.8,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Lubelskie",
    "capital": "",
    "population": 17117,
    "id": 1616448418
  },
  {
    "city": "Pô",
    "city_ascii": "Po",
    "lat": 11.169,
    "lng": -1.135,
    "country": "Burkina Faso",
    "iso2": "BF",
    "iso3": "BFA",
    "admin_name": "Centre-Sud",
    "capital": "minor",
    "population": 17924,
    "id": 1854979912
  },
  {
    "city": "El Reno",
    "city_ascii": "El Reno",
    "lat": 35.543,
    "lng": -97.966,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oklahoma",
    "capital": "",
    "population": 17962,
    "id": 1840020420
  },
  {
    "city": "Illertissen",
    "city_ascii": "Illertissen",
    "lat": 48.2167,
    "lng": 10.0833,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 17473,
    "id": 1276593057
  },
  {
    "city": "Frankenberg",
    "city_ascii": "Frankenberg",
    "lat": 51.0589,
    "lng": 8.7967,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 17808,
    "id": 1276051534
  },
  {
    "city": "Zachary",
    "city_ascii": "Zachary",
    "lat": 30.6642,
    "lng": -91.1634,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 17949,
    "id": 1840015908
  },
  {
    "city": "Kingsland",
    "city_ascii": "Kingsland",
    "lat": 30.8194,
    "lng": -81.7217,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 17949,
    "id": 1840014991
  },
  {
    "city": "Marco Island",
    "city_ascii": "Marco Island",
    "lat": 25.933,
    "lng": -81.6993,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 17947,
    "id": 1840036141
  },
  {
    "city": "Huntington",
    "city_ascii": "Huntington",
    "lat": 40.8815,
    "lng": -85.5053,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 17942,
    "id": 1840007169
  },
  {
    "city": "Ingleside",
    "city_ascii": "Ingleside",
    "lat": 27.87,
    "lng": -97.2077,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 17940,
    "id": 1840019709
  },
  {
    "city": "New Castle",
    "city_ascii": "New Castle",
    "lat": 41.1842,
    "lng": -73.7726,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 17935,
    "id": 1840087710
  },
  {
    "city": "Ban Thum",
    "city_ascii": "Ban Thum",
    "lat": 16.4523,
    "lng": 102.7225,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Khon Kaen",
    "capital": "",
    "population": 17564,
    "id": 1764043980
  },
  {
    "city": "Al Bahnasā",
    "city_ascii": "Al Bahnasa",
    "lat": 28.5353,
    "lng": 30.6606,
    "country": "Egypt",
    "iso2": "EG",
    "iso3": "EGY",
    "admin_name": "Al Minyā",
    "capital": "",
    "population": 15948,
    "id": 1818431123
  },
  {
    "city": "Buchen in Odenwald",
    "city_ascii": "Buchen in Odenwald",
    "lat": 49.5217,
    "lng": 9.3233,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 17796,
    "id": 1276974738
  },
  {
    "city": "Guapiara",
    "city_ascii": "Guapiara",
    "lat": -24.185,
    "lng": -48.5328,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 17879,
    "id": 1076094869
  },
  {
    "city": "Mitake",
    "city_ascii": "Mitake",
    "lat": 35.4344,
    "lng": 137.1308,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Gifu",
    "capital": "",
    "population": 17608,
    "id": 1392599630
  },
  {
    "city": "Ustka",
    "city_ascii": "Ustka",
    "lat": 54.5805,
    "lng": 16.8619,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Pomorskie",
    "capital": "",
    "population": 16308,
    "id": 1616638026
  },
  {
    "city": "Kirsanov",
    "city_ascii": "Kirsanov",
    "lat": 52.65,
    "lng": 42.7333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Tambovskaya Oblast’",
    "capital": "",
    "population": 16409,
    "id": 1643643425
  },
  {
    "city": "Golitsyno",
    "city_ascii": "Golitsyno",
    "lat": 55.6147,
    "lng": 36.9872,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Moskovskaya Oblast’",
    "capital": "",
    "population": 17651,
    "id": 1643646490
  },
  {
    "city": "Lenoir",
    "city_ascii": "Lenoir",
    "lat": 35.9096,
    "lng": -81.5247,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 17913,
    "id": 1840014493
  },
  {
    "city": "Yaguachi Nuevo",
    "city_ascii": "Yaguachi Nuevo",
    "lat": -2.12,
    "lng": -79.69,
    "country": "Ecuador",
    "iso2": "EC",
    "iso3": "ECU",
    "admin_name": "Guayas",
    "capital": "",
    "population": 17806,
    "id": 1218680506
  },
  {
    "city": "Złotoryja",
    "city_ascii": "Zlotoryja",
    "lat": 51.1264,
    "lng": 15.9198,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Dolnośląskie",
    "capital": "minor",
    "population": 16479,
    "id": 1616742834
  },
  {
    "city": "Easton",
    "city_ascii": "Easton",
    "lat": 38.776,
    "lng": -76.0702,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 17911,
    "id": 1840006089
  },
  {
    "city": "Nguigmi",
    "city_ascii": "Nguigmi",
    "lat": 14.2532,
    "lng": 13.1108,
    "country": "Niger",
    "iso2": "NE",
    "iso3": "NER",
    "admin_name": "Diffa",
    "capital": "minor",
    "population": 17897,
    "id": 1562214324
  },
  {
    "city": "Rockland",
    "city_ascii": "Rockland",
    "lat": 42.1295,
    "lng": -70.91,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 17909,
    "id": 1840053576
  },
  {
    "city": "Galich",
    "city_ascii": "Galich",
    "lat": 58.3833,
    "lng": 42.35,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kostromskaya Oblast’",
    "capital": "",
    "population": 16911,
    "id": 1643147614
  },
  {
    "city": "Jauja",
    "city_ascii": "Jauja",
    "lat": -11.775,
    "lng": -75.5,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "Junín",
    "capital": "",
    "population": 16424,
    "id": 1604948232
  },
  {
    "city": "Yuanquan",
    "city_ascii": "Yuanquan",
    "lat": 40.5004,
    "lng": 95.8,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Gansu",
    "capital": "minor",
    "population": 17886,
    "id": 1156339161
  },
  {
    "city": "Upton",
    "city_ascii": "Upton",
    "lat": 53.385,
    "lng": -3.099,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Wirral",
    "capital": "",
    "population": 16130,
    "id": 1826951403
  },
  {
    "city": "San Bartolo Tutotepec",
    "city_ascii": "San Bartolo Tutotepec",
    "lat": 20.4,
    "lng": -98.2,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Hidalgo",
    "capital": "minor",
    "population": 17837,
    "id": 1484290508
  },
  {
    "city": "Perry",
    "city_ascii": "Perry",
    "lat": 32.4719,
    "lng": -83.7282,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 17894,
    "id": 1840014885
  },
  {
    "city": "Saint Ives",
    "city_ascii": "Saint Ives",
    "lat": 52.3344,
    "lng": -0.0761,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Cambridgeshire",
    "capital": "",
    "population": 16384,
    "id": 1826186113
  },
  {
    "city": "Hannibal",
    "city_ascii": "Hannibal",
    "lat": 39.7097,
    "lng": -91.3939,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 17884,
    "id": 1840007306
  },
  {
    "city": "Gikongoro",
    "city_ascii": "Gikongoro",
    "lat": -2.4697,
    "lng": 29.5814,
    "country": "Rwanda",
    "iso2": "RW",
    "iso3": "RWA",
    "admin_name": "Southern Province",
    "capital": "",
    "population": 16695,
    "id": 1646632562
  },
  {
    "city": "Jacupiranga",
    "city_ascii": "Jacupiranga",
    "lat": -24.6925,
    "lng": -48.0022,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 17851,
    "id": 1076127392
  },
  {
    "city": "Menasha",
    "city_ascii": "Menasha",
    "lat": 44.2124,
    "lng": -88.4272,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 17873,
    "id": 1840002243
  },
  {
    "city": "Gibsonton",
    "city_ascii": "Gibsonton",
    "lat": 27.826,
    "lng": -82.3761,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 17873,
    "id": 1840014148
  },
  {
    "city": "Maardu",
    "city_ascii": "Maardu",
    "lat": 59.4781,
    "lng": 25.0161,
    "country": "Estonia",
    "iso2": "EE",
    "iso3": "EST",
    "admin_name": "Harjumaa",
    "capital": "minor",
    "population": 17141,
    "id": 1233565021
  },
  {
    "city": "Melville",
    "city_ascii": "Melville",
    "lat": 40.7823,
    "lng": -73.4088,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 17872,
    "id": 1840005044
  },
  {
    "city": "Schwarzenbek",
    "city_ascii": "Schwarzenbek",
    "lat": 53.5042,
    "lng": 10.4792,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Schleswig-Holstein",
    "capital": "",
    "population": 16447,
    "id": 1276765796
  },
  {
    "city": "Scarsdale",
    "city_ascii": "Scarsdale",
    "lat": 40.9902,
    "lng": -73.7773,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 17871,
    "id": 1840004943
  },
  {
    "city": "Alton",
    "city_ascii": "Alton",
    "lat": 51.1498,
    "lng": -0.9769,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hampshire",
    "capital": "",
    "population": 17816,
    "id": 1826279601
  },
  {
    "city": "Braunstone",
    "city_ascii": "Braunstone",
    "lat": 52.616,
    "lng": -1.175,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Leicestershire",
    "capital": "",
    "population": 16850,
    "id": 1826381597
  },
  {
    "city": "Ocean Springs",
    "city_ascii": "Ocean Springs",
    "lat": 30.4082,
    "lng": -88.7861,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Mississippi",
    "capital": "",
    "population": 17862,
    "id": 1840015017
  },
  {
    "city": "Dallas",
    "city_ascii": "Dallas",
    "lat": 44.9222,
    "lng": -123.3131,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 17859,
    "id": 1840018590
  },
  {
    "city": "White Settlement",
    "city_ascii": "White Settlement",
    "lat": 32.7554,
    "lng": -97.4605,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 17851,
    "id": 1840022051
  },
  {
    "city": "High Blantyre",
    "city_ascii": "High Blantyre",
    "lat": 55.793,
    "lng": -4.097,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "South Lanarkshire",
    "capital": "",
    "population": 16900,
    "id": 1826928486
  },
  {
    "city": "Kukmor",
    "city_ascii": "Kukmor",
    "lat": 56.1825,
    "lng": 50.9064,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Tatarstan",
    "capital": "",
    "population": 17815,
    "id": 1643556564
  },
  {
    "city": "Nazaré Paulista",
    "city_ascii": "Nazare Paulista",
    "lat": -23.1808,
    "lng": -46.395,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 17794,
    "id": 1076428478
  },
  {
    "city": "Kongsvinger",
    "city_ascii": "Kongsvinger",
    "lat": 60.1912,
    "lng": 11.9992,
    "country": "Norway",
    "iso2": "NO",
    "iso3": "NOR",
    "admin_name": "Hedmark",
    "capital": "minor",
    "population": 17829,
    "id": 1578097529
  },
  {
    "city": "Knightdale",
    "city_ascii": "Knightdale",
    "lat": 35.7918,
    "lng": -78.4955,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 17843,
    "id": 1840016194
  },
  {
    "city": "Mori",
    "city_ascii": "Mori",
    "lat": 34.8356,
    "lng": 137.9272,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Shizuoka",
    "capital": "",
    "population": 17709,
    "id": 1392757253
  },
  {
    "city": "Shenandoah",
    "city_ascii": "Shenandoah",
    "lat": 40.8167,
    "lng": -76.2004,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 17840,
    "id": 1840002779
  },
  {
    "city": "Ada",
    "city_ascii": "Ada",
    "lat": 34.7683,
    "lng": -96.6689,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oklahoma",
    "capital": "",
    "population": 17840,
    "id": 1840019191
  },
  {
    "city": "Salonta",
    "city_ascii": "Salonta",
    "lat": 46.8,
    "lng": 21.65,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Bihor",
    "capital": "",
    "population": 17735,
    "id": 1642519468
  },
  {
    "city": "Broken Hill",
    "city_ascii": "Broken Hill",
    "lat": -31.9567,
    "lng": 141.4678,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 17734,
    "id": 1036216956
  },
  {
    "city": "Sobinka",
    "city_ascii": "Sobinka",
    "lat": 55.99,
    "lng": 40.0167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Vladimirskaya Oblast’",
    "capital": "",
    "population": 17799,
    "id": 1643819695
  },
  {
    "city": "Bedford",
    "city_ascii": "Bedford",
    "lat": 41.225,
    "lng": -73.6678,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 17831,
    "id": 1840057226
  },
  {
    "city": "Crossville",
    "city_ascii": "Crossville",
    "lat": 35.9527,
    "lng": -85.0294,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 17825,
    "id": 1840014489
  },
  {
    "city": "Las Heras",
    "city_ascii": "Las Heras",
    "lat": -46.55,
    "lng": -68.95,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Santa Cruz",
    "capital": "",
    "population": 17821,
    "id": 1032657206
  },
  {
    "city": "Brenham",
    "city_ascii": "Brenham",
    "lat": 30.1585,
    "lng": -96.3964,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 17823,
    "id": 1840019604
  },
  {
    "city": "Hlybokaye",
    "city_ascii": "Hlybokaye",
    "lat": 55.1333,
    "lng": 27.6833,
    "country": "Belarus",
    "iso2": "BY",
    "iso3": "BLR",
    "admin_name": "Vitsyebskaya Voblasts’",
    "capital": "minor",
    "population": 17800,
    "id": 1112510591
  },
  {
    "city": "Sleaford",
    "city_ascii": "Sleaford",
    "lat": 52.996,
    "lng": -0.413,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Lincolnshire",
    "capital": "",
    "population": 17671,
    "id": 1826743491
  },
  {
    "city": "Bad Säckingen",
    "city_ascii": "Bad Sackingen",
    "lat": 47.5533,
    "lng": 7.9472,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 17144,
    "id": 1276877057
  },
  {
    "city": "Oerlinghausen",
    "city_ascii": "Oerlinghausen",
    "lat": 51.9667,
    "lng": 8.6667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 17286,
    "id": 1276933659
  },
  {
    "city": "Tillmans Corner",
    "city_ascii": "Tillmans Corner",
    "lat": 30.5818,
    "lng": -88.2128,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 17814,
    "id": 1840013896
  },
  {
    "city": "Whitemarsh",
    "city_ascii": "Whitemarsh",
    "lat": 40.104,
    "lng": -75.2483,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 17811,
    "id": 1840102027
  },
  {
    "city": "Florence",
    "city_ascii": "Florence",
    "lat": 33.059,
    "lng": -111.4208,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 17811,
    "id": 1840021999
  },
  {
    "city": "Pirapora do Bom Jesus",
    "city_ascii": "Pirapora do Bom Jesus",
    "lat": -23.3972,
    "lng": -47.0028,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 17646,
    "id": 1076000099
  },
  {
    "city": "Laç",
    "city_ascii": "Lac",
    "lat": 41.6353,
    "lng": 19.7131,
    "country": "Albania",
    "iso2": "AL",
    "iso3": "ALB",
    "admin_name": "Lezhë",
    "capital": "",
    "population": 17086,
    "id": 1008762785
  },
  {
    "city": "Glenvar Heights",
    "city_ascii": "Glenvar Heights",
    "lat": 25.709,
    "lng": -80.3156,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 17802,
    "id": 1840014238
  },
  {
    "city": "Santa Elena",
    "city_ascii": "Santa Elena",
    "lat": -30.95,
    "lng": -59.8,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Entre Ríos",
    "capital": "",
    "population": 17791,
    "id": 1032467373
  },
  {
    "city": "Ruhango",
    "city_ascii": "Ruhango",
    "lat": -2.2325,
    "lng": 29.7803,
    "country": "Rwanda",
    "iso2": "RW",
    "iso3": "RWA",
    "admin_name": "Southern Province",
    "capital": "",
    "population": 17051,
    "id": 1646180341
  },
  {
    "city": "Grenchen",
    "city_ascii": "Grenchen",
    "lat": 47.1931,
    "lng": 7.3958,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Solothurn",
    "capital": "",
    "population": 17140,
    "id": 1756258549
  },
  {
    "city": "Guisborough",
    "city_ascii": "Guisborough",
    "lat": 54.535,
    "lng": -1.0563,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Redcar and Cleveland",
    "capital": "",
    "population": 17777,
    "id": 1826412979
  },
  {
    "city": "Jenison",
    "city_ascii": "Jenison",
    "lat": 42.9063,
    "lng": -85.8269,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 17794,
    "id": 1840004344
  },
  {
    "city": "Portchester",
    "city_ascii": "Portchester",
    "lat": 50.842,
    "lng": -1.12,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hampshire",
    "capital": "",
    "population": 17789,
    "id": 1826303470
  },
  {
    "city": "Moraga",
    "city_ascii": "Moraga",
    "lat": 37.8439,
    "lng": -122.1225,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 17783,
    "id": 1840022538
  },
  {
    "city": "San Carlos Park",
    "city_ascii": "San Carlos Park",
    "lat": 26.4765,
    "lng": -81.8193,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 17778,
    "id": 1840014218
  },
  {
    "city": "Nanuet",
    "city_ascii": "Nanuet",
    "lat": 41.0957,
    "lng": -74.0155,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 17775,
    "id": 1840004963
  },
  {
    "city": "Bon Air",
    "city_ascii": "Bon Air",
    "lat": 37.5187,
    "lng": -77.5713,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 17764,
    "id": 1840006409
  },
  {
    "city": "South Hadley",
    "city_ascii": "South Hadley",
    "lat": 42.2567,
    "lng": -72.5793,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 17763,
    "id": 1840053467
  },
  {
    "city": "Zongo",
    "city_ascii": "Zongo",
    "lat": 4.35,
    "lng": 18.6,
    "country": "Congo (Kinshasa)",
    "iso2": "CD",
    "iso3": "COD",
    "admin_name": "Sud-Ubangi",
    "capital": "",
    "population": 17667,
    "id": 1180165321
  },
  {
    "city": "Banora Point",
    "city_ascii": "Banora Point",
    "lat": -28.2225,
    "lng": 153.5386,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 16167,
    "id": 1036608972
  },
  {
    "city": "Steubenville",
    "city_ascii": "Steubenville",
    "lat": 40.3653,
    "lng": -80.652,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 17753,
    "id": 1840001341
  },
  {
    "city": "Glen Parva",
    "city_ascii": "Glen Parva",
    "lat": 52.5867,
    "lng": -1.1617,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Leicestershire",
    "capital": "",
    "population": 17189,
    "id": 1826454038
  },
  {
    "city": "Newmarket",
    "city_ascii": "Newmarket",
    "lat": 52.2459,
    "lng": 0.4105,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Suffolk",
    "capital": "",
    "population": 16615,
    "id": 1826537250
  },
  {
    "city": "Tavares",
    "city_ascii": "Tavares",
    "lat": 28.7921,
    "lng": -81.7353,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 17749,
    "id": 1840015953
  },
  {
    "city": "Ngara",
    "city_ascii": "Ngara",
    "lat": -2.4695,
    "lng": 30.65,
    "country": "Tanzania",
    "iso2": "TZ",
    "iso3": "TZA",
    "admin_name": "Kagera",
    "capital": "",
    "population": 16890,
    "id": 1834067520
  },
  {
    "city": "El Roble",
    "city_ascii": "El Roble",
    "lat": 9.9771,
    "lng": -84.7443,
    "country": "Costa Rica",
    "iso2": "CR",
    "iso3": "CRI",
    "admin_name": "Puntarenas",
    "capital": "",
    "population": 15759,
    "id": 1188437917
  },
  {
    "city": "Belgrade",
    "city_ascii": "Belgrade",
    "lat": 45.7796,
    "lng": -111.1751,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Montana",
    "capital": "",
    "population": 17743,
    "id": 1840018508
  },
  {
    "city": "Sierre",
    "city_ascii": "Sierre",
    "lat": 46.2918,
    "lng": 7.532,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Valais",
    "capital": "",
    "population": 16860,
    "id": 1756959511
  },
  {
    "city": "Popovo",
    "city_ascii": "Popovo",
    "lat": 43.3481,
    "lng": 26.2275,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Targovishte",
    "capital": "",
    "population": 17703,
    "id": 1100589134
  },
  {
    "city": "Maryland City",
    "city_ascii": "Maryland City",
    "lat": 39.1016,
    "lng": -76.8051,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 17736,
    "id": 1840005922
  },
  {
    "city": "Colonia",
    "city_ascii": "Colonia",
    "lat": 40.5929,
    "lng": -74.3151,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 17734,
    "id": 1840005411
  },
  {
    "city": "Ban Pong",
    "city_ascii": "Ban Pong",
    "lat": 13.8174,
    "lng": 99.883,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Ratchaburi",
    "capital": "minor",
    "population": 17298,
    "id": 1764963515
  },
  {
    "city": "Lousã",
    "city_ascii": "Lousa",
    "lat": 40.1125,
    "lng": -8.2469,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Coimbra",
    "capital": "minor",
    "population": 17604,
    "id": 1620586922
  },
  {
    "city": "Bílina",
    "city_ascii": "Bilina",
    "lat": 50.5486,
    "lng": 13.7754,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Ústecký Kraj",
    "capital": "",
    "population": 17200,
    "id": 1203669701
  },
  {
    "city": "Thornton",
    "city_ascii": "Thornton",
    "lat": 53.7898,
    "lng": -1.8504,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Bradford",
    "capital": "",
    "population": 17276,
    "id": 1826639956
  },
  {
    "city": "Lackawanna",
    "city_ascii": "Lackawanna",
    "lat": 42.8182,
    "lng": -78.8326,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 17720,
    "id": 1840000387
  },
  {
    "city": "Plainville",
    "city_ascii": "Plainville",
    "lat": 41.6741,
    "lng": -72.8575,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 17720,
    "id": 1840035535
  },
  {
    "city": "Oxon Hill",
    "city_ascii": "Oxon Hill",
    "lat": 38.7887,
    "lng": -76.9733,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 17720,
    "id": 1840026688
  },
  {
    "city": "El Dorado",
    "city_ascii": "El Dorado",
    "lat": 33.2184,
    "lng": -92.664,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arkansas",
    "capital": "",
    "population": 17706,
    "id": 1840013773
  },
  {
    "city": "Salmon Arm",
    "city_ascii": "Salmon Arm",
    "lat": 50.7022,
    "lng": -119.2722,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "British Columbia",
    "capital": "",
    "population": 17706,
    "id": 1124478865
  },
  {
    "city": "Alcochete",
    "city_ascii": "Alcochete",
    "lat": 38.7553,
    "lng": -8.9608,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Setúbal",
    "capital": "minor",
    "population": 17569,
    "id": 1620781537
  },
  {
    "city": "Midway",
    "city_ascii": "Midway",
    "lat": 30.4138,
    "lng": -87.0261,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 17704,
    "id": 1840143881
  },
  {
    "city": "Ashton",
    "city_ascii": "Ashton",
    "lat": -44.033,
    "lng": 171.772,
    "country": "New Zealand",
    "iso2": "NZ",
    "iso3": "NZL",
    "admin_name": "Canterbury",
    "capital": "",
    "population": 17700,
    "id": 1554784264
  },
  {
    "city": "Tlahuelilpan",
    "city_ascii": "Tlahuelilpan",
    "lat": 20.1297,
    "lng": -99.2286,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Hidalgo",
    "capital": "",
    "population": 17153,
    "id": 1484009996
  },
  {
    "city": "Terrell",
    "city_ascii": "Terrell",
    "lat": 32.7341,
    "lng": -96.2939,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 17696,
    "id": 1840022065
  },
  {
    "city": "Saulgau",
    "city_ascii": "Saulgau",
    "lat": 48.0175,
    "lng": 9.5003,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 17509,
    "id": 1276840359
  },
  {
    "city": "Port Alberni",
    "city_ascii": "Port Alberni",
    "lat": 49.2339,
    "lng": -124.805,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "British Columbia",
    "capital": "",
    "population": 17678,
    "id": 1124952808
  },
  {
    "city": "Kadogawa",
    "city_ascii": "Kadogawa",
    "lat": 32.4697,
    "lng": 131.6489,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Miyazaki",
    "capital": "",
    "population": 17526,
    "id": 1392290615
  },
  {
    "city": "Ribeirão Branco",
    "city_ascii": "Ribeirao Branco",
    "lat": -24.2208,
    "lng": -48.7658,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 17646,
    "id": 1076454991
  },
  {
    "city": "Zaslawye",
    "city_ascii": "Zaslawye",
    "lat": 54,
    "lng": 27.2833,
    "country": "Belarus",
    "iso2": "BY",
    "iso3": "BLR",
    "admin_name": "Minskaya Voblasts’",
    "capital": "",
    "population": 17600,
    "id": 1112333553
  },
  {
    "city": "Nerang",
    "city_ascii": "Nerang",
    "lat": -27.9956,
    "lng": 153.322,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Queensland",
    "capital": "",
    "population": 16864,
    "id": 1036082899
  },
  {
    "city": "Bitam",
    "city_ascii": "Bitam",
    "lat": 2.0833,
    "lng": 11.4833,
    "country": "Gabon",
    "iso2": "GA",
    "iso3": "GAB",
    "admin_name": "Woleu-Ntem",
    "capital": "",
    "population": 17637,
    "id": 1266921836
  },
  {
    "city": "Polyarnyy",
    "city_ascii": "Polyarnyy",
    "lat": 69.1983,
    "lng": 33.4561,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Murmanskaya Oblast’",
    "capital": "",
    "population": 17605,
    "id": 1643432811
  },
  {
    "city": "Madison",
    "city_ascii": "Madison",
    "lat": 40.7586,
    "lng": -74.417,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 17654,
    "id": 1840003574
  },
  {
    "city": "Maitland",
    "city_ascii": "Maitland",
    "lat": 28.6295,
    "lng": -81.3717,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 17652,
    "id": 1840015964
  },
  {
    "city": "Concord",
    "city_ascii": "Concord",
    "lat": 38.5117,
    "lng": -90.3574,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 17652,
    "id": 1840006126
  },
  {
    "city": "Guidan Roumdji",
    "city_ascii": "Guidan Roumdji",
    "lat": 13.6575,
    "lng": 6.6958,
    "country": "Niger",
    "iso2": "NE",
    "iso3": "NER",
    "admin_name": "Maradi",
    "capital": "minor",
    "population": 17525,
    "id": 1562532711
  },
  {
    "city": "Gretna",
    "city_ascii": "Gretna",
    "lat": 29.9101,
    "lng": -90.0515,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 17647,
    "id": 1840013996
  },
  {
    "city": "Inza",
    "city_ascii": "Inza",
    "lat": 53.85,
    "lng": 46.35,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Ul’yanovskaya Oblast’",
    "capital": "",
    "population": 17630,
    "id": 1643770166
  },
  {
    "city": "Arauco",
    "city_ascii": "Arauco",
    "lat": -37.2463,
    "lng": -73.3175,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Biobío",
    "capital": "",
    "population": 17597,
    "id": 1152468620
  },
  {
    "city": "Bendorf",
    "city_ascii": "Bendorf",
    "lat": 50.4297,
    "lng": 7.5703,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Rhineland-Palatinate",
    "capital": "",
    "population": 16940,
    "id": 1276205648
  },
  {
    "city": "Milanówek",
    "city_ascii": "Milanowek",
    "lat": 52.1243,
    "lng": 20.6654,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Mazowieckie",
    "capital": "",
    "population": 16419,
    "id": 1616814692
  },
  {
    "city": "Concord Township",
    "city_ascii": "Concord Township",
    "lat": 39.8741,
    "lng": -75.5135,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 17639,
    "id": 1840152841
  },
  {
    "city": "Hinsdale",
    "city_ascii": "Hinsdale",
    "lat": 41.8005,
    "lng": -87.9273,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 17637,
    "id": 1840011403
  },
  {
    "city": "Tougan",
    "city_ascii": "Tougan",
    "lat": 13.069,
    "lng": -3.07,
    "country": "Burkina Faso",
    "iso2": "BF",
    "iso3": "BFA",
    "admin_name": "Boucle du Mouhoun",
    "capital": "minor",
    "population": 17590,
    "id": 1854407539
  },
  {
    "city": "Santa Fe Springs",
    "city_ascii": "Santa Fe Springs",
    "lat": 33.933,
    "lng": -118.0625,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 17630,
    "id": 1840021865
  },
  {
    "city": "Bad Münder am Deister",
    "city_ascii": "Bad Munder am Deister",
    "lat": 52.1992,
    "lng": 9.4653,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 17465,
    "id": 1276351668
  },
  {
    "city": "Franklin Park",
    "city_ascii": "Franklin Park",
    "lat": 41.9361,
    "lng": -87.8794,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 17627,
    "id": 1840011278
  },
  {
    "city": "Blooming Grove",
    "city_ascii": "Blooming Grove",
    "lat": 41.3948,
    "lng": -74.184,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 17619,
    "id": 1840057241
  },
  {
    "city": "Same",
    "city_ascii": "Same",
    "lat": -4.0696,
    "lng": 37.72,
    "country": "Tanzania",
    "iso2": "TZ",
    "iso3": "TZA",
    "admin_name": "Kilimanjaro",
    "capital": "",
    "population": 17455,
    "id": 1834888088
  },
  {
    "city": "Catriel",
    "city_ascii": "Catriel",
    "lat": -37.8778,
    "lng": -67.7917,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Río Negro",
    "capital": "",
    "population": 17584,
    "id": 1032975217
  },
  {
    "city": "Wendlingen am Neckar",
    "city_ascii": "Wendlingen am Neckar",
    "lat": 48.6747,
    "lng": 9.3817,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 16268,
    "id": 1276408904
  },
  {
    "city": "Zaysan",
    "city_ascii": "Zaysan",
    "lat": 47.4688,
    "lng": 84.8646,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Shyghys Qazaqstan",
    "capital": "",
    "population": 17600,
    "id": 1398700526
  },
  {
    "city": "As Suqaylibīyah",
    "city_ascii": "As Suqaylibiyah",
    "lat": 35.3697,
    "lng": 36.38,
    "country": "Syria",
    "iso2": "SY",
    "iso3": "SYR",
    "admin_name": "Ḩamāh",
    "capital": "minor",
    "population": 17313,
    "id": 1760164203
  },
  {
    "city": "Effretikon",
    "city_ascii": "Effretikon",
    "lat": 47.4158,
    "lng": 8.7197,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Zürich",
    "capital": "",
    "population": 16928,
    "id": 1756605189
  },
  {
    "city": "Hatfield",
    "city_ascii": "Hatfield",
    "lat": 40.2758,
    "lng": -75.2895,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 17595,
    "id": 1840003701
  },
  {
    "city": "Beltsville",
    "city_ascii": "Beltsville",
    "lat": 39.0394,
    "lng": -76.9211,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 17589,
    "id": 1840005945
  },
  {
    "city": "Greene Township",
    "city_ascii": "Greene Township",
    "lat": 39.9543,
    "lng": -77.5668,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 17588,
    "id": 1840147454
  },
  {
    "city": "Centerville",
    "city_ascii": "Centerville",
    "lat": 40.9284,
    "lng": -111.8849,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Utah",
    "capital": "",
    "population": 17587,
    "id": 1840018734
  },
  {
    "city": "Bad Langensalza",
    "city_ascii": "Bad Langensalza",
    "lat": 51.1081,
    "lng": 10.6467,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Thuringia",
    "capital": "",
    "population": 17441,
    "id": 1276854476
  },
  {
    "city": "Raunheim",
    "city_ascii": "Raunheim",
    "lat": 50.0097,
    "lng": 8.45,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 16284,
    "id": 1276238709
  },
  {
    "city": "Ashland",
    "city_ascii": "Ashland",
    "lat": 42.2573,
    "lng": -71.4687,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 17576,
    "id": 1840053632
  },
  {
    "city": "Butner",
    "city_ascii": "Butner",
    "lat": 36.1285,
    "lng": -78.7502,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 17575,
    "id": 1840022665
  },
  {
    "city": "Boulsa",
    "city_ascii": "Boulsa",
    "lat": 12.657,
    "lng": -0.569,
    "country": "Burkina Faso",
    "iso2": "BF",
    "iso3": "BFA",
    "admin_name": "Centre-Nord",
    "capital": "minor",
    "population": 17489,
    "id": 1854535753
  },
  {
    "city": "Salem",
    "city_ascii": "Salem",
    "lat": 40.9049,
    "lng": -80.8491,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 17571,
    "id": 1840009374
  },
  {
    "city": "Thale",
    "city_ascii": "Thale",
    "lat": 51.7511,
    "lng": 11.0428,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony-Anhalt",
    "capital": "",
    "population": 17442,
    "id": 1276578169
  },
  {
    "city": "Marktredwitz",
    "city_ascii": "Marktredwitz",
    "lat": 50,
    "lng": 12.0667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 17217,
    "id": 1276442185
  },
  {
    "city": "Chirpan",
    "city_ascii": "Chirpan",
    "lat": 42.1994,
    "lng": 25.3242,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Stara Zagora",
    "capital": "",
    "population": 17522,
    "id": 1100767507
  },
  {
    "city": "Sedeh Lanjān",
    "city_ascii": "Sedeh Lanjan",
    "lat": 32.3778,
    "lng": 51.3181,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Eşfahān",
    "capital": "",
    "population": 17335,
    "id": 1364171855
  },
  {
    "city": "Fanipal’",
    "city_ascii": "Fanipal'",
    "lat": 53.75,
    "lng": 27.3333,
    "country": "Belarus",
    "iso2": "BY",
    "iso3": "BLR",
    "admin_name": "Minskaya Voblasts’",
    "capital": "",
    "population": 17500,
    "id": 1112544829
  },
  {
    "city": "Haslemere",
    "city_ascii": "Haslemere",
    "lat": 51.09,
    "lng": -0.712,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Surrey",
    "capital": "",
    "population": 16826,
    "id": 1826519948
  },
  {
    "city": "Anoka",
    "city_ascii": "Anoka",
    "lat": 45.2099,
    "lng": -93.3893,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 17549,
    "id": 1840006717
  },
  {
    "city": "Remagen",
    "city_ascii": "Remagen",
    "lat": 50.5786,
    "lng": 7.2306,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Rhineland-Palatinate",
    "capital": "",
    "population": 17032,
    "id": 1276287418
  },
  {
    "city": "Bobingen",
    "city_ascii": "Bobingen",
    "lat": 48.2667,
    "lng": 10.8167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 17199,
    "id": 1276277241
  },
  {
    "city": "Miramichi",
    "city_ascii": "Miramichi",
    "lat": 47.0196,
    "lng": -65.5072,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "New Brunswick",
    "capital": "",
    "population": 17537,
    "id": 1124714190
  },
  {
    "city": "Foxborough",
    "city_ascii": "Foxborough",
    "lat": 42.0627,
    "lng": -71.2461,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 17535,
    "id": 1840053549
  },
  {
    "city": "Amesbury",
    "city_ascii": "Amesbury",
    "lat": 42.853,
    "lng": -70.9446,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 17532,
    "id": 1840132383
  },
  {
    "city": "Plast",
    "city_ascii": "Plast",
    "lat": 54.3667,
    "lng": 60.8167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Chelyabinskaya Oblast’",
    "capital": "",
    "population": 17508,
    "id": 1643651635
  },
  {
    "city": "Trossingen",
    "city_ascii": "Trossingen",
    "lat": 48.0756,
    "lng": 8.6361,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 16829,
    "id": 1276343997
  },
  {
    "city": "Windlesham",
    "city_ascii": "Windlesham",
    "lat": 51.36,
    "lng": -0.66,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Surrey",
    "capital": "",
    "population": 16775,
    "id": 1826961751
  },
  {
    "city": "Marion",
    "city_ascii": "Marion",
    "lat": 37.7173,
    "lng": -88.9279,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 17520,
    "id": 1840008712
  },
  {
    "city": "Karukh",
    "city_ascii": "Karukh",
    "lat": 34.4868,
    "lng": 62.5918,
    "country": "Afghanistan",
    "iso2": "AF",
    "iso3": "AFG",
    "admin_name": "Herāt",
    "capital": "minor",
    "population": 17484,
    "id": 1004546127
  },
  {
    "city": "Tallmadge",
    "city_ascii": "Tallmadge",
    "lat": 41.1023,
    "lng": -81.4216,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 17519,
    "id": 1840000804
  },
  {
    "city": "Cafelândia",
    "city_ascii": "Cafelandia",
    "lat": -21.8025,
    "lng": -49.61,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 17499,
    "id": 1076575289
  },
  {
    "city": "Knik-Fairview",
    "city_ascii": "Knik-Fairview",
    "lat": 61.4964,
    "lng": -149.6535,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alaska",
    "capital": "",
    "population": 17513,
    "id": 1840075080
  },
  {
    "city": "Albert Lea",
    "city_ascii": "Albert Lea",
    "lat": 43.6547,
    "lng": -93.3642,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 17511,
    "id": 1840006824
  },
  {
    "city": "Niagara-on-the-Lake",
    "city_ascii": "Niagara-on-the-Lake",
    "lat": 43.2553,
    "lng": -79.0717,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 17511,
    "id": 1124366228
  },
  {
    "city": "Shorewood",
    "city_ascii": "Shorewood",
    "lat": 41.5175,
    "lng": -88.2149,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 17509,
    "id": 1840011496
  },
  {
    "city": "Katsuura",
    "city_ascii": "Katsuura",
    "lat": 35.1525,
    "lng": 140.3211,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Chiba",
    "capital": "",
    "population": 17324,
    "id": 1392044206
  },
  {
    "city": "Vero Beach",
    "city_ascii": "Vero Beach",
    "lat": 27.6463,
    "lng": -80.393,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 17503,
    "id": 1840015985
  },
  {
    "city": "Tsuiki",
    "city_ascii": "Tsuiki",
    "lat": 33.6561,
    "lng": 131.0561,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Fukuoka",
    "capital": "",
    "population": 17356,
    "id": 1392121731
  },
  {
    "city": "Clemson",
    "city_ascii": "Clemson",
    "lat": 34.6837,
    "lng": -82.8124,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 17501,
    "id": 1840014626
  },
  {
    "city": "Sulzbach",
    "city_ascii": "Sulzbach",
    "lat": 49.3,
    "lng": 7.0667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saarland",
    "capital": "",
    "population": 16468,
    "id": 1276579983
  },
  {
    "city": "Forssa",
    "city_ascii": "Forssa",
    "lat": 60.8167,
    "lng": 23.6167,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Kanta-Häme",
    "capital": "minor",
    "population": 17422,
    "id": 1246156595
  },
  {
    "city": "Karachev",
    "city_ascii": "Karachev",
    "lat": 53.1167,
    "lng": 34.9833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Bryanskaya Oblast’",
    "capital": "",
    "population": 17466,
    "id": 1643019084
  },
  {
    "city": "Pomáz",
    "city_ascii": "Pomaz",
    "lat": 47.6472,
    "lng": 19.0269,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Pest",
    "capital": "",
    "population": 17139,
    "id": 1348490155
  },
  {
    "city": "Suvorov",
    "city_ascii": "Suvorov",
    "lat": 54.1167,
    "lng": 36.5,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Tul’skaya Oblast’",
    "capital": "",
    "population": 17450,
    "id": 1643843521
  },
  {
    "city": "Lewes",
    "city_ascii": "Lewes",
    "lat": 50.8756,
    "lng": 0.0179,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "East Sussex",
    "capital": "",
    "population": 17297,
    "id": 1826774397
  },
  {
    "city": "Lara",
    "city_ascii": "Lara",
    "lat": -38.0167,
    "lng": 144.4167,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Victoria",
    "capital": "",
    "population": 16355,
    "id": 1036754750
  },
  {
    "city": "Lake Mary",
    "city_ascii": "Lake Mary",
    "lat": 28.7592,
    "lng": -81.3359,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 17479,
    "id": 1840015090
  },
  {
    "city": "Horsham",
    "city_ascii": "Horsham",
    "lat": -36.7167,
    "lng": 142.2,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Victoria",
    "capital": "",
    "population": 16514,
    "id": 1036654340
  },
  {
    "city": "Laurel",
    "city_ascii": "Laurel",
    "lat": 37.6375,
    "lng": -77.5062,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 17470,
    "id": 1840006393
  },
  {
    "city": "Calafat",
    "city_ascii": "Calafat",
    "lat": 43.9858,
    "lng": 22.9575,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Dolj",
    "capital": "",
    "population": 17336,
    "id": 1642467713
  },
  {
    "city": "Volgorechensk",
    "city_ascii": "Volgorechensk",
    "lat": 57.4333,
    "lng": 41.1667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kostromskaya Oblast’",
    "capital": "",
    "population": 16539,
    "id": 1643892465
  },
  {
    "city": "Harborcreek",
    "city_ascii": "Harborcreek",
    "lat": 42.1498,
    "lng": -79.9501,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 17466,
    "id": 1840099632
  },
  {
    "city": "Lakeport",
    "city_ascii": "Lakeport",
    "lat": 39.0392,
    "lng": -122.9218,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 17466,
    "id": 1840020220
  },
  {
    "city": "Sigmaringen",
    "city_ascii": "Sigmaringen",
    "lat": 48.0867,
    "lng": 9.2164,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "minor",
    "population": 17278,
    "id": 1276579491
  },
  {
    "city": "Doylestown",
    "city_ascii": "Doylestown",
    "lat": 40.2962,
    "lng": -75.1393,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 17464,
    "id": 1840001320
  },
  {
    "city": "Saint-Lin--Laurentides",
    "city_ascii": "Saint-Lin--Laurentides",
    "lat": 45.85,
    "lng": -73.7667,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 17463,
    "id": 1124906585
  },
  {
    "city": "Ferndale",
    "city_ascii": "Ferndale",
    "lat": 39.1869,
    "lng": -76.633,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 17455,
    "id": 1840005906
  },
  {
    "city": "Martinsburg",
    "city_ascii": "Martinsburg",
    "lat": 39.4582,
    "lng": -77.9776,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "West Virginia",
    "capital": "",
    "population": 17454,
    "id": 1840005742
  },
  {
    "city": "Bolhrad",
    "city_ascii": "Bolhrad",
    "lat": 45.6855,
    "lng": 28.6134,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Odes’ka Oblast’",
    "capital": "minor",
    "population": 17400,
    "id": 1804273550
  },
  {
    "city": "Montemor-o-Novo",
    "city_ascii": "Montemor-o-Novo",
    "lat": 38.65,
    "lng": -8.2167,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Évora",
    "capital": "minor",
    "population": 17437,
    "id": 1620010573
  },
  {
    "city": "Tinton Falls",
    "city_ascii": "Tinton Falls",
    "lat": 40.2709,
    "lng": -74.0948,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 17451,
    "id": 1840001368
  },
  {
    "city": "Freienbach",
    "city_ascii": "Freienbach",
    "lat": 47.2047,
    "lng": 8.7578,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Schwyz",
    "capital": "",
    "population": 16269,
    "id": 1756905696
  },
  {
    "city": "Nipomo",
    "city_ascii": "Nipomo",
    "lat": 35.0323,
    "lng": -120.4991,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 17445,
    "id": 1840019122
  },
  {
    "city": "Conchas",
    "city_ascii": "Conchas",
    "lat": -23.0134,
    "lng": -48.0078,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 17406,
    "id": 1076967858
  },
  {
    "city": "Puerto Aysén",
    "city_ascii": "Puerto Aysen",
    "lat": -45.4068,
    "lng": -72.6977,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Aysén",
    "capital": "minor",
    "population": 17441,
    "id": 1152158570
  },
  {
    "city": "Nidda",
    "city_ascii": "Nidda",
    "lat": 50.4128,
    "lng": 9.0092,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 17285,
    "id": 1276604046
  },
  {
    "city": "Veinticinco de Mayo",
    "city_ascii": "Veinticinco de Mayo",
    "lat": -37.8,
    "lng": -67.6833,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "La Pampa",
    "capital": "minor",
    "population": 17430,
    "id": 1032912712
  },
  {
    "city": "Kushima",
    "city_ascii": "Kushima",
    "lat": 31.4645,
    "lng": 131.2284,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Miyazaki",
    "capital": "",
    "population": 17363,
    "id": 1392989065
  },
  {
    "city": "Plymouth Township",
    "city_ascii": "Plymouth Township",
    "lat": 40.1115,
    "lng": -75.2977,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 17421,
    "id": 1840142125
  },
  {
    "city": "Gescher",
    "city_ascii": "Gescher",
    "lat": 51.9569,
    "lng": 7.0056,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 17205,
    "id": 1276129657
  },
  {
    "city": "Portsmouth",
    "city_ascii": "Portsmouth",
    "lat": 41.5922,
    "lng": -71.2745,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Rhode Island",
    "capital": "",
    "population": 17418,
    "id": 1840066124
  },
  {
    "city": "Raychikhinsk",
    "city_ascii": "Raychikhinsk",
    "lat": 49.8,
    "lng": 129.4,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Amurskaya Oblast’",
    "capital": "",
    "population": 17372,
    "id": 1643638607
  },
  {
    "city": "Bad Münstereifel",
    "city_ascii": "Bad Munstereifel",
    "lat": 50.5531,
    "lng": 6.7661,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 17299,
    "id": 1276172917
  },
  {
    "city": "Eldorado",
    "city_ascii": "Eldorado",
    "lat": -26.4,
    "lng": -54.6333,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Misiones",
    "capital": "minor",
    "population": 17365,
    "id": 1032056096
  },
  {
    "city": "Nizhnyaya Salda",
    "city_ascii": "Nizhnyaya Salda",
    "lat": 58.0833,
    "lng": 60.7167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sverdlovskaya Oblast’",
    "capital": "",
    "population": 17373,
    "id": 1643295393
  },
  {
    "city": "Godfrey",
    "city_ascii": "Godfrey",
    "lat": 38.9577,
    "lng": -90.2156,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 17400,
    "id": 1840012796
  },
  {
    "city": "Springfield",
    "city_ascii": "Springfield",
    "lat": 40.6994,
    "lng": -74.3253,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 17398,
    "id": 1840056368
  },
  {
    "city": "Pendleton",
    "city_ascii": "Pendleton",
    "lat": 45.6756,
    "lng": -118.8209,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 17398,
    "id": 1840019917
  },
  {
    "city": "Beaumont",
    "city_ascii": "Beaumont",
    "lat": 53.3572,
    "lng": -113.4147,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Alberta",
    "capital": "",
    "population": 17396,
    "id": 1124001210
  },
  {
    "city": "Jičín",
    "city_ascii": "Jicin",
    "lat": 50.4373,
    "lng": 15.3517,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Královéhradecký Kraj",
    "capital": "",
    "population": 16717,
    "id": 1203562586
  },
  {
    "city": "Eutin",
    "city_ascii": "Eutin",
    "lat": 54.1378,
    "lng": 10.6181,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Schleswig-Holstein",
    "capital": "minor",
    "population": 16971,
    "id": 1276288365
  },
  {
    "city": "Heilbad Heiligenstadt",
    "city_ascii": "Heilbad Heiligenstadt",
    "lat": 51.3775,
    "lng": 10.1344,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Thuringia",
    "capital": "minor",
    "population": 17105,
    "id": 1276037701
  },
  {
    "city": "Biggleswade",
    "city_ascii": "Biggleswade",
    "lat": 52.0855,
    "lng": -0.2557,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Central Bedfordshire",
    "capital": "",
    "population": 16551,
    "id": 1826800454
  },
  {
    "city": "Ripon",
    "city_ascii": "Ripon",
    "lat": 54.138,
    "lng": -1.524,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "North Yorkshire",
    "capital": "",
    "population": 16702,
    "id": 1826726048
  },
  {
    "city": "Nederland",
    "city_ascii": "Nederland",
    "lat": 29.9707,
    "lng": -94.0015,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 17371,
    "id": 1840020917
  },
  {
    "city": "Colonial Heights",
    "city_ascii": "Colonial Heights",
    "lat": 37.265,
    "lng": -77.3969,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 17370,
    "id": 1840003860
  },
  {
    "city": "Horn-Bad Meinberg",
    "city_ascii": "Horn-Bad Meinberg",
    "lat": 51.8833,
    "lng": 8.9667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 17178,
    "id": 1276648708
  },
  {
    "city": "Stockerau",
    "city_ascii": "Stockerau",
    "lat": 48.3858,
    "lng": 16.2108,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Niederösterreich",
    "capital": "",
    "population": 16916,
    "id": 1040718812
  },
  {
    "city": "Wohlen",
    "city_ascii": "Wohlen",
    "lat": 47.3506,
    "lng": 8.2778,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Aargau",
    "capital": "",
    "population": 16078,
    "id": 1756643512
  },
  {
    "city": "Morriston",
    "city_ascii": "Morriston",
    "lat": 51.6647,
    "lng": -3.9389,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Swansea",
    "capital": "",
    "population": 16928,
    "id": 1826268056
  },
  {
    "city": "Stafford",
    "city_ascii": "Stafford",
    "lat": 29.6271,
    "lng": -95.5653,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 17362,
    "id": 1840022216
  },
  {
    "city": "Stockach",
    "city_ascii": "Stockach",
    "lat": 47.8514,
    "lng": 9.0114,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 17114,
    "id": 1276190104
  },
  {
    "city": "Krasnoslobodsk",
    "city_ascii": "Krasnoslobodsk",
    "lat": 48.7,
    "lng": 44.5667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Volgogradskaya Oblast’",
    "capital": "",
    "population": 17327,
    "id": 1643897251
  },
  {
    "city": "Colchester",
    "city_ascii": "Colchester",
    "lat": 44.5545,
    "lng": -73.2167,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Vermont",
    "capital": "",
    "population": 17357,
    "id": 1840070411
  },
  {
    "city": "G‘uzor",
    "city_ascii": "G`uzor",
    "lat": 38.6208,
    "lng": 66.2481,
    "country": "Uzbekistan",
    "iso2": "UZ",
    "iso3": "UZB",
    "admin_name": "Qashqadaryo",
    "capital": "",
    "population": 17253,
    "id": 1860850796
  },
  {
    "city": "Guryevsk",
    "city_ascii": "Guryevsk",
    "lat": 54.7667,
    "lng": 20.6,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kaliningradskaya Oblast’",
    "capital": "minor",
    "population": 17295,
    "id": 1643078744
  },
  {
    "city": "Wailuku",
    "city_ascii": "Wailuku",
    "lat": 20.8834,
    "lng": -156.5059,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Hawaii",
    "capital": "",
    "population": 17354,
    "id": 1840023239
  },
  {
    "city": "Mount Barker",
    "city_ascii": "Mount Barker",
    "lat": -35.0667,
    "lng": 138.85,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "South Australia",
    "capital": "",
    "population": 16629,
    "id": 1036530325
  },
  {
    "city": "Wittenberge",
    "city_ascii": "Wittenberge",
    "lat": 52.9936,
    "lng": 11.7514,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Brandenburg",
    "capital": "",
    "population": 17015,
    "id": 1276040852
  },
  {
    "city": "Hatfield",
    "city_ascii": "Hatfield",
    "lat": 53.58,
    "lng": -1,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Doncaster",
    "capital": "",
    "population": 16776,
    "id": 1826723545
  },
  {
    "city": "Shumikha",
    "city_ascii": "Shumikha",
    "lat": 55.2333,
    "lng": 63.2833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kurganskaya Oblast’",
    "capital": "",
    "population": 17336,
    "id": 1643819566
  },
  {
    "city": "Schrobenhausen",
    "city_ascii": "Schrobenhausen",
    "lat": 48.5669,
    "lng": 11.2583,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 17106,
    "id": 1276307699
  },
  {
    "city": "Burgdorf",
    "city_ascii": "Burgdorf",
    "lat": 47.0567,
    "lng": 7.6264,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Bern",
    "capital": "",
    "population": 16280,
    "id": 1756999920
  },
  {
    "city": "Łęczyca",
    "city_ascii": "Leczyca",
    "lat": 52.0583,
    "lng": 19.2,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Łódzkie",
    "capital": "minor",
    "population": 15593,
    "id": 1616649790
  },
  {
    "city": "Pisaflores",
    "city_ascii": "Pisaflores",
    "lat": 21.1933,
    "lng": -99.005,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Hidalgo",
    "capital": "",
    "population": 17214,
    "id": 1484698691
  },
  {
    "city": "Guben",
    "city_ascii": "Guben",
    "lat": 51.9533,
    "lng": 14.7167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Brandenburg",
    "capital": "",
    "population": 16933,
    "id": 1276800396
  },
  {
    "city": "Höganäs",
    "city_ascii": "Hoganas",
    "lat": 56.196,
    "lng": 12.5769,
    "country": "Sweden",
    "iso2": "SE",
    "iso3": "SWE",
    "admin_name": "Skåne",
    "capital": "minor",
    "population": 15795,
    "id": 1752137413
  },
  {
    "city": "Handlová",
    "city_ascii": "Handlova",
    "lat": 48.7272,
    "lng": 18.7619,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Nitriansky",
    "capital": "",
    "population": 17119,
    "id": 1703292608
  },
  {
    "city": "La Marque",
    "city_ascii": "La Marque",
    "lat": 29.369,
    "lng": -94.9957,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 17319,
    "id": 1840020972
  },
  {
    "city": "Svalyava",
    "city_ascii": "Svalyava",
    "lat": 48.5472,
    "lng": 22.9861,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Zakarpats’ka Oblast’",
    "capital": "",
    "population": 17234,
    "id": 1804834428
  },
  {
    "city": "Peso da Régua",
    "city_ascii": "Peso da Regua",
    "lat": 41.1632,
    "lng": -7.789,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Vila Real",
    "capital": "minor",
    "population": 17131,
    "id": 1620264911
  },
  {
    "city": "Königstein im Taunus",
    "city_ascii": "Konigstein im Taunus",
    "lat": 50.1831,
    "lng": 8.4635,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 16648,
    "id": 1276481618
  },
  {
    "city": "Woodcrest",
    "city_ascii": "Woodcrest",
    "lat": 33.8789,
    "lng": -117.3686,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 17310,
    "id": 1840019296
  },
  {
    "city": "Sawankhalok",
    "city_ascii": "Sawankhalok",
    "lat": 17.3096,
    "lng": 99.8266,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Sukhothai",
    "capital": "minor",
    "population": 17148,
    "id": 1764710205
  },
  {
    "city": "Gorham",
    "city_ascii": "Gorham",
    "lat": 43.7034,
    "lng": -70.4581,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maine",
    "capital": "",
    "population": 17307,
    "id": 1840052834
  },
  {
    "city": "Braunau am Inn",
    "city_ascii": "Braunau am Inn",
    "lat": 48.2583,
    "lng": 13.0333,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Oberösterreich",
    "capital": "minor",
    "population": 17095,
    "id": 1040852989
  },
  {
    "city": "Fonte Boa",
    "city_ascii": "Fonte Boa",
    "lat": -2.5138,
    "lng": -66.0916,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Amazonas",
    "capital": "",
    "population": 16060,
    "id": 1076851330
  },
  {
    "city": "Altena",
    "city_ascii": "Altena",
    "lat": 51.3,
    "lng": 7.6667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 16922,
    "id": 1276575290
  },
  {
    "city": "Heckmondwike",
    "city_ascii": "Heckmondwike",
    "lat": 53.708,
    "lng": -1.67,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Kirklees",
    "capital": "",
    "population": 16986,
    "id": 1826586883
  },
  {
    "city": "Heysham",
    "city_ascii": "Heysham",
    "lat": 54.046,
    "lng": -2.894,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Lancashire",
    "capital": "",
    "population": 17016,
    "id": 1826178724
  },
  {
    "city": "Aubange",
    "city_ascii": "Aubange",
    "lat": 49.5667,
    "lng": 5.805,
    "country": "Belgium",
    "iso2": "BE",
    "iso3": "BEL",
    "admin_name": "Wallonia",
    "capital": "",
    "population": 16927,
    "id": 1056280555
  },
  {
    "city": "North Decatur",
    "city_ascii": "North Decatur",
    "lat": 33.8073,
    "lng": -84.2889,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 17298,
    "id": 1840013704
  },
  {
    "city": "Versailles",
    "city_ascii": "Versailles",
    "lat": 38.0487,
    "lng": -84.7259,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kentucky",
    "capital": "",
    "population": 17298,
    "id": 1840015212
  },
  {
    "city": "Morris",
    "city_ascii": "Morris",
    "lat": 41.3749,
    "lng": -88.4305,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 17296,
    "id": 1840008243
  },
  {
    "city": "Kamenz",
    "city_ascii": "Kamenz",
    "lat": 51.2667,
    "lng": 14.1,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 16975,
    "id": 1276558710
  },
  {
    "city": "Zarumilla",
    "city_ascii": "Zarumilla",
    "lat": -3.5014,
    "lng": -80.2722,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "Tumbes",
    "capital": "",
    "population": 16971,
    "id": 1604793858
  },
  {
    "city": "Fairview Township",
    "city_ascii": "Fairview Township",
    "lat": 40.1735,
    "lng": -76.8655,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 17293,
    "id": 1840150913
  },
  {
    "city": "Svitavy",
    "city_ascii": "Svitavy",
    "lat": 49.756,
    "lng": 16.4683,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Pardubický Kraj",
    "capital": "",
    "population": 16758,
    "id": 1203802204
  },
  {
    "city": "Damme",
    "city_ascii": "Damme",
    "lat": 52.5222,
    "lng": 8.1956,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 17127,
    "id": 1276165336
  },
  {
    "city": "Lemont",
    "city_ascii": "Lemont",
    "lat": 41.6695,
    "lng": -87.9836,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 17291,
    "id": 1840011258
  },
  {
    "city": "North Druid Hills",
    "city_ascii": "North Druid Hills",
    "lat": 33.8186,
    "lng": -84.3254,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 17287,
    "id": 1840029449
  },
  {
    "city": "Kangaba",
    "city_ascii": "Kangaba",
    "lat": 11.9333,
    "lng": -8.4167,
    "country": "Mali",
    "iso2": "ML",
    "iso3": "MLI",
    "admin_name": "Koulikoro",
    "capital": "minor",
    "population": 17232,
    "id": 1466770326
  },
  {
    "city": "Sayre",
    "city_ascii": "Sayre",
    "lat": 41.9855,
    "lng": -76.5207,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 17281,
    "id": 1840000517
  },
  {
    "city": "Giffnock",
    "city_ascii": "Giffnock",
    "lat": 55.8051,
    "lng": -4.2946,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "East Renfrewshire",
    "capital": "",
    "population": 16178,
    "id": 1826874548
  },
  {
    "city": "Bad Wildungen",
    "city_ascii": "Bad Wildungen",
    "lat": 51.1167,
    "lng": 9.1167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 17137,
    "id": 1276828659
  },
  {
    "city": "Podporozhye",
    "city_ascii": "Podporozhye",
    "lat": 60.9167,
    "lng": 34.1667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Leningradskaya Oblast’",
    "capital": "",
    "population": 17270,
    "id": 1643153235
  },
  {
    "city": "East Finchley",
    "city_ascii": "East Finchley",
    "lat": 51.5902,
    "lng": -0.1753,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Barnet",
    "capital": "",
    "population": 15989,
    "id": 1826180591
  },
  {
    "city": "Chertsey",
    "city_ascii": "Chertsey",
    "lat": 51.3902,
    "lng": -0.5074,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Surrey",
    "capital": "",
    "population": 15967,
    "id": 1826578278
  },
  {
    "city": "Brzesko",
    "city_ascii": "Brzesko",
    "lat": 49.9667,
    "lng": 20.6167,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Małopolskie",
    "capital": "minor",
    "population": 16912,
    "id": 1616496298
  },
  {
    "city": "Portland",
    "city_ascii": "Portland",
    "lat": 27.8911,
    "lng": -97.3284,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 17268,
    "id": 1840021006
  },
  {
    "city": "Baymak",
    "city_ascii": "Baymak",
    "lat": 52.5833,
    "lng": 58.3167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Bashkortostan",
    "capital": "",
    "population": 17254,
    "id": 1643017239
  },
  {
    "city": "Lindenwold",
    "city_ascii": "Lindenwold",
    "lat": 39.8172,
    "lng": -74.9898,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 17263,
    "id": 1840000730
  },
  {
    "city": "Middlesex Centre",
    "city_ascii": "Middlesex Centre",
    "lat": 43.05,
    "lng": -81.45,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 17262,
    "id": 1124001841
  },
  {
    "city": "Oswestry",
    "city_ascii": "Oswestry",
    "lat": 52.8598,
    "lng": -3.0538,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Shropshire",
    "capital": "",
    "population": 17105,
    "id": 1826246108
  },
  {
    "city": "Calnali",
    "city_ascii": "Calnali",
    "lat": 20.9,
    "lng": -98.5833,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Hidalgo",
    "capital": "",
    "population": 17163,
    "id": 1484014196
  },
  {
    "city": "Cesário Lange",
    "city_ascii": "Cesario Lange",
    "lat": -23.2267,
    "lng": -47.9531,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 17163,
    "id": 1076669066
  },
  {
    "city": "Yabuki",
    "city_ascii": "Yabuki",
    "lat": 37.2011,
    "lng": 140.3386,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Fukushima",
    "capital": "",
    "population": 16969,
    "id": 1392633195
  },
  {
    "city": "Clive",
    "city_ascii": "Clive",
    "lat": 41.6147,
    "lng": -93.798,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Iowa",
    "capital": "",
    "population": 17242,
    "id": 1840007063
  },
  {
    "city": "Mohács",
    "city_ascii": "Mohacs",
    "lat": 45.9958,
    "lng": 18.6797,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Baranya",
    "capital": "minor",
    "population": 17089,
    "id": 1348907014
  },
  {
    "city": "Navalmoral de la Mata",
    "city_ascii": "Navalmoral de la Mata",
    "lat": 39.8928,
    "lng": -5.5403,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Extremadura",
    "capital": "",
    "population": 17129,
    "id": 1724575983
  },
  {
    "city": "Buckhall",
    "city_ascii": "Buckhall",
    "lat": 38.725,
    "lng": -77.4472,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 17238,
    "id": 1840026707
  },
  {
    "city": "Oneonta",
    "city_ascii": "Oneonta",
    "lat": 42.4551,
    "lng": -75.0666,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 17236,
    "id": 1840000405
  },
  {
    "city": "Inverness",
    "city_ascii": "Inverness",
    "lat": 46.2,
    "lng": -61.1,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Nova Scotia",
    "capital": "",
    "population": 17235,
    "id": 1124840965
  },
  {
    "city": "Shippensburg",
    "city_ascii": "Shippensburg",
    "lat": 40.0484,
    "lng": -77.5227,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 17234,
    "id": 1840001395
  },
  {
    "city": "Penzberg",
    "city_ascii": "Penzberg",
    "lat": 47.75,
    "lng": 11.3667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 16586,
    "id": 1276589283
  },
  {
    "city": "Pampa",
    "city_ascii": "Pampa",
    "lat": 35.5479,
    "lng": -100.9651,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 17230,
    "id": 1840022939
  },
  {
    "city": "Lithia Springs",
    "city_ascii": "Lithia Springs",
    "lat": 33.781,
    "lng": -84.6485,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 17216,
    "id": 1840013740
  },
  {
    "city": "Bethpage",
    "city_ascii": "Bethpage",
    "lat": 40.7495,
    "lng": -73.4856,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 17215,
    "id": 1840005233
  },
  {
    "city": "Marienberg",
    "city_ascii": "Marienberg",
    "lat": 50.6508,
    "lng": 13.1647,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 17097,
    "id": 1276662047
  },
  {
    "city": "Condeixa-a-Nova",
    "city_ascii": "Condeixa-a-Nova",
    "lat": 40.1167,
    "lng": -8.5,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Coimbra",
    "capital": "minor",
    "population": 17078,
    "id": 1620361727
  },
  {
    "city": "South Ogden",
    "city_ascii": "South Ogden",
    "lat": 41.1722,
    "lng": -111.9577,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Utah",
    "capital": "",
    "population": 17199,
    "id": 1840021342
  },
  {
    "city": "Balmazújváros",
    "city_ascii": "Balmazujvaros",
    "lat": 47.6167,
    "lng": 21.35,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Hajdú-Bihar",
    "capital": "minor",
    "population": 17109,
    "id": 1348090687
  },
  {
    "city": "Itirapina",
    "city_ascii": "Itirapina",
    "lat": -22.2528,
    "lng": -47.8228,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 17160,
    "id": 1076964066
  },
  {
    "city": "Stony Plain",
    "city_ascii": "Stony Plain",
    "lat": 53.5264,
    "lng": -114.0069,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Alberta",
    "capital": "",
    "population": 17189,
    "id": 1124982081
  },
  {
    "city": "Petawawa",
    "city_ascii": "Petawawa",
    "lat": 45.9,
    "lng": -77.2833,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 17187,
    "id": 1124206291
  },
  {
    "city": "Albemarle",
    "city_ascii": "Albemarle",
    "lat": 35.3594,
    "lng": -80.1915,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 17185,
    "id": 1840013444
  },
  {
    "city": "Willimantic",
    "city_ascii": "Willimantic",
    "lat": 41.7153,
    "lng": -72.2173,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 17184,
    "id": 1840003284
  },
  {
    "city": "Oak Grove",
    "city_ascii": "Oak Grove",
    "lat": 45.4156,
    "lng": -122.6349,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 17183,
    "id": 1840018568
  },
  {
    "city": "Hopkinton",
    "city_ascii": "Hopkinton",
    "lat": 42.2255,
    "lng": -71.5378,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 17178,
    "id": 1840053637
  },
  {
    "city": "North Canton",
    "city_ascii": "North Canton",
    "lat": 40.8742,
    "lng": -81.3971,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 17176,
    "id": 1840008290
  },
  {
    "city": "Konstantinovsk",
    "city_ascii": "Konstantinovsk",
    "lat": 47.5833,
    "lng": 41.1,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Rostovskaya Oblast’",
    "capital": "minor",
    "population": 17160,
    "id": 1643188587
  },
  {
    "city": "La Vista",
    "city_ascii": "La Vista",
    "lat": 41.1816,
    "lng": -96.0666,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Nebraska",
    "capital": "",
    "population": 17170,
    "id": 1840008263
  },
  {
    "city": "Killingly",
    "city_ascii": "Killingly",
    "lat": 41.8311,
    "lng": -71.8499,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 17170,
    "id": 1840045031
  },
  {
    "city": "Elias Fausto",
    "city_ascii": "Elias Fausto",
    "lat": -23.0588,
    "lng": -47.3878,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 17085,
    "id": 1076412113
  },
  {
    "city": "Ashwaubenon",
    "city_ascii": "Ashwaubenon",
    "lat": 44.4796,
    "lng": -88.0889,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 17161,
    "id": 1840002342
  },
  {
    "city": "Freiberg am Neckar",
    "city_ascii": "Freiberg am Neckar",
    "lat": 48.9347,
    "lng": 9.1917,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 15968,
    "id": 1276056473
  },
  {
    "city": "Gubin",
    "city_ascii": "Gubin",
    "lat": 51.95,
    "lng": 14.7333,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Lubuskie",
    "capital": "",
    "population": 17150,
    "id": 1616164527
  },
  {
    "city": "Dickson",
    "city_ascii": "Dickson",
    "lat": 36.064,
    "lng": -87.3668,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 17157,
    "id": 1840014468
  },
  {
    "city": "Kusa",
    "city_ascii": "Kusa",
    "lat": 55.3333,
    "lng": 59.4333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Chelyabinskaya Oblast’",
    "capital": "",
    "population": 17136,
    "id": 1643204008
  },
  {
    "city": "Codru",
    "city_ascii": "Codru",
    "lat": 46.9753,
    "lng": 28.8194,
    "country": "Moldova",
    "iso2": "MD",
    "iso3": "MDA",
    "admin_name": "Chişinău",
    "capital": "",
    "population": 15934,
    "id": 1498544482
  },
  {
    "city": "North Adams",
    "city_ascii": "North Adams",
    "lat": 42.6844,
    "lng": -73.1166,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 17153,
    "id": 1840000423
  },
  {
    "city": "Waxhaw",
    "city_ascii": "Waxhaw",
    "lat": 34.9364,
    "lng": -80.7438,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 17147,
    "id": 1840016458
  },
  {
    "city": "Kežmarok",
    "city_ascii": "Kezmarok",
    "lat": 49.1383,
    "lng": 20.4292,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Prešovský",
    "capital": "minor",
    "population": 16481,
    "id": 1703214180
  },
  {
    "city": "Miyazu",
    "city_ascii": "Miyazu",
    "lat": 35.5356,
    "lng": 135.1956,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Kyōto",
    "capital": "",
    "population": 17044,
    "id": 1392216318
  },
  {
    "city": "Ajacuba",
    "city_ascii": "Ajacuba",
    "lat": 20.0833,
    "lng": -99.1167,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Hidalgo",
    "capital": "",
    "population": 17055,
    "id": 1484132188
  },
  {
    "city": "Saint Andrews",
    "city_ascii": "Saint Andrews",
    "lat": 56.3404,
    "lng": -2.7955,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Fife",
    "capital": "",
    "population": 16801,
    "id": 1826606142
  },
  {
    "city": "Zvenyhorodka",
    "city_ascii": "Zvenyhorodka",
    "lat": 49.0833,
    "lng": 30.9667,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Cherkas’ka Oblast’",
    "capital": "minor",
    "population": 17078,
    "id": 1804045250
  },
  {
    "city": "Piñas",
    "city_ascii": "Pinas",
    "lat": -3.6806,
    "lng": -79.6806,
    "country": "Ecuador",
    "iso2": "EC",
    "iso3": "ECU",
    "admin_name": "El Oro",
    "capital": "",
    "population": 16981,
    "id": 1218213967
  },
  {
    "city": "Bargteheide",
    "city_ascii": "Bargteheide",
    "lat": 53.7167,
    "lng": 10.2667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Schleswig-Holstein",
    "capital": "",
    "population": 16109,
    "id": 1276611028
  },
  {
    "city": "Kronach",
    "city_ascii": "Kronach",
    "lat": 50.2411,
    "lng": 11.3281,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "minor",
    "population": 16874,
    "id": 1276212761
  },
  {
    "city": "Dabas",
    "city_ascii": "Dabas",
    "lat": 47.1833,
    "lng": 19.3167,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Pest",
    "capital": "minor",
    "population": 17023,
    "id": 1348671815
  },
  {
    "city": "Bethlehem",
    "city_ascii": "Bethlehem",
    "lat": -28.2333,
    "lng": 28.3,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "Free State",
    "capital": "",
    "population": 16236,
    "id": 1710750307
  },
  {
    "city": "Wādī Ḩalfā’",
    "city_ascii": "Wadi Halfa'",
    "lat": 21.8,
    "lng": 31.35,
    "country": "Sudan",
    "iso2": "SD",
    "iso3": "SDN",
    "admin_name": "Northern",
    "capital": "",
    "population": 17121,
    "id": 1729435342
  },
  {
    "city": "Bastrop",
    "city_ascii": "Bastrop",
    "lat": 30.1118,
    "lng": -97.3257,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 17120,
    "id": 1840019603
  },
  {
    "city": "Cham",
    "city_ascii": "Cham",
    "lat": 49.2183,
    "lng": 12.6658,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "minor",
    "population": 16907,
    "id": 1276459468
  },
  {
    "city": "Zmiiv",
    "city_ascii": "Zmiiv",
    "lat": 49.6878,
    "lng": 36.3558,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Kharkivs’ka Oblast’",
    "capital": "minor",
    "population": 17063,
    "id": 1804969427
  },
  {
    "city": "Benešov",
    "city_ascii": "Benesov",
    "lat": 49.7817,
    "lng": 14.687,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Středočeský Kraj",
    "capital": "",
    "population": 16758,
    "id": 1203042729
  },
  {
    "city": "Ammon",
    "city_ascii": "Ammon",
    "lat": 43.4746,
    "lng": -111.9569,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Idaho",
    "capital": "",
    "population": 17115,
    "id": 1840018643
  },
  {
    "city": "Pelham",
    "city_ascii": "Pelham",
    "lat": 43.0333,
    "lng": -79.3333,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 17110,
    "id": 1124000042
  },
  {
    "city": "Yelm",
    "city_ascii": "Yelm",
    "lat": 46.9398,
    "lng": -122.6261,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 17103,
    "id": 1840021146
  },
  {
    "city": "Estelle",
    "city_ascii": "Estelle",
    "lat": 29.8447,
    "lng": -90.1021,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 17099,
    "id": 1840013111
  },
  {
    "city": "Toijala",
    "city_ascii": "Toijala",
    "lat": 61.1667,
    "lng": 23.8681,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Pirkanmaa",
    "capital": "minor",
    "population": 17043,
    "id": 1246702356
  },
  {
    "city": "Sunagawa",
    "city_ascii": "Sunagawa",
    "lat": 43.4948,
    "lng": 141.9035,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hokkaidō",
    "capital": "",
    "population": 16878,
    "id": 1392198315
  },
  {
    "city": "Tôlan̈aro",
    "city_ascii": "Tolanaro",
    "lat": -25.0333,
    "lng": 46.9833,
    "country": "Madagascar",
    "iso2": "MG",
    "iso3": "MDG",
    "admin_name": "Toliara",
    "capital": "minor",
    "population": 16818,
    "id": 1450217836
  },
  {
    "city": "Macatuba",
    "city_ascii": "Macatuba",
    "lat": -22.5022,
    "lng": -48.7114,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 17013,
    "id": 1076530289
  },
  {
    "city": "Babenhausen",
    "city_ascii": "Babenhausen",
    "lat": 49.9624,
    "lng": 8.9533,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 16834,
    "id": 1276006345
  },
  {
    "city": "Schwarzenberg",
    "city_ascii": "Schwarzenberg",
    "lat": 50.5453,
    "lng": 12.7792,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 16723,
    "id": 1276831560
  },
  {
    "city": "Gavrilov-Yam",
    "city_ascii": "Gavrilov-Yam",
    "lat": 57.3,
    "lng": 39.85,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Yaroslavskaya Oblast’",
    "capital": "",
    "population": 17057,
    "id": 1643185412
  },
  {
    "city": "Menzelinsk",
    "city_ascii": "Menzelinsk",
    "lat": 55.7167,
    "lng": 53.0833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Tatarstan",
    "capital": "",
    "population": 17055,
    "id": 1643750653
  },
  {
    "city": "Filiaşi",
    "city_ascii": "Filiasi",
    "lat": 44.5539,
    "lng": 23.529,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Dolj",
    "capital": "",
    "population": 16900,
    "id": 1642083363
  },
  {
    "city": "Burlington",
    "city_ascii": "Burlington",
    "lat": 39.0223,
    "lng": -84.7217,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kentucky",
    "capital": "",
    "population": 17069,
    "id": 1840013160
  },
  {
    "city": "Preetz",
    "city_ascii": "Preetz",
    "lat": 54.2367,
    "lng": 10.2822,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Schleswig-Holstein",
    "capital": "",
    "population": 15958,
    "id": 1276274661
  },
  {
    "city": "Pokrov",
    "city_ascii": "Pokrov",
    "lat": 55.9178,
    "lng": 39.175,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Vladimirskaya Oblast’",
    "capital": "",
    "population": 17025,
    "id": 1643390317
  },
  {
    "city": "Turinsk",
    "city_ascii": "Turinsk",
    "lat": 58.0333,
    "lng": 63.7,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sverdlovskaya Oblast’",
    "capital": "",
    "population": 17060,
    "id": 1643094071
  },
  {
    "city": "Palos Hills",
    "city_ascii": "Palos Hills",
    "lat": 41.6986,
    "lng": -87.8266,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 17060,
    "id": 1840009182
  },
  {
    "city": "Selwyn",
    "city_ascii": "Selwyn",
    "lat": 44.4167,
    "lng": -78.3333,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 17060,
    "id": 1124000937
  },
  {
    "city": "Povorino",
    "city_ascii": "Povorino",
    "lat": 51.2,
    "lng": 42.25,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Voronezhskaya Oblast’",
    "capital": "",
    "population": 17025,
    "id": 1643533014
  },
  {
    "city": "Seltso",
    "city_ascii": "Seltso",
    "lat": 53.3694,
    "lng": 34.1,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Bryanskaya Oblast’",
    "capital": "",
    "population": 16554,
    "id": 1643772327
  },
  {
    "city": "Khed",
    "city_ascii": "Khed",
    "lat": 17.72,
    "lng": 73.38,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Mahārāshtra",
    "capital": "",
    "population": 16892,
    "id": 1356073622
  },
  {
    "city": "Wyckoff",
    "city_ascii": "Wyckoff",
    "lat": 40.9989,
    "lng": -74.1676,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 17047,
    "id": 1840081803
  },
  {
    "city": "Monte Cristi",
    "city_ascii": "Monte Cristi",
    "lat": 19.867,
    "lng": -71.65,
    "country": "Dominican Republic",
    "iso2": "DO",
    "iso3": "DOM",
    "admin_name": "Cibao Noroeste",
    "capital": "minor",
    "population": 17001,
    "id": 1214970157
  },
  {
    "city": "Brixham",
    "city_ascii": "Brixham",
    "lat": 50.394,
    "lng": -3.516,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Devon",
    "capital": "",
    "population": 16693,
    "id": 1826371818
  },
  {
    "city": "Upper Grand Lagoon",
    "city_ascii": "Upper Grand Lagoon",
    "lat": 30.169,
    "lng": -85.7407,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 17045,
    "id": 1840029090
  },
  {
    "city": "Diepholz",
    "city_ascii": "Diepholz",
    "lat": 52.6072,
    "lng": 8.3711,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "minor",
    "population": 16882,
    "id": 1276476080
  },
  {
    "city": "Cernavodă",
    "city_ascii": "Cernavoda",
    "lat": 44.3381,
    "lng": 28.0336,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Ialomiţa",
    "capital": "",
    "population": 17022,
    "id": 1642042708
  },
  {
    "city": "Valencia",
    "city_ascii": "Valencia",
    "lat": -0.9525,
    "lng": -79.3531,
    "country": "Ecuador",
    "iso2": "EC",
    "iso3": "ECU",
    "admin_name": "Los Ríos",
    "capital": "",
    "population": 16983,
    "id": 1218923469
  },
  {
    "city": "Streator",
    "city_ascii": "Streator",
    "lat": 41.1244,
    "lng": -88.8296,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 17021,
    "id": 1840009271
  },
  {
    "city": "Bytów",
    "city_ascii": "Bytow",
    "lat": 54.1667,
    "lng": 17.5,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Pomorskie",
    "capital": "minor",
    "population": 16888,
    "id": 1616588946
  },
  {
    "city": "Bellingham",
    "city_ascii": "Bellingham",
    "lat": 42.0777,
    "lng": -71.4741,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 17016,
    "id": 1840053544
  },
  {
    "city": "Brookfield",
    "city_ascii": "Brookfield",
    "lat": 41.4674,
    "lng": -73.3923,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 17013,
    "id": 1840034171
  },
  {
    "city": "Ihosy",
    "city_ascii": "Ihosy",
    "lat": -22.3996,
    "lng": 46.1167,
    "country": "Madagascar",
    "iso2": "MG",
    "iso3": "MDG",
    "admin_name": "Fianarantsoa",
    "capital": "minor",
    "population": 16990,
    "id": 1450600411
  },
  {
    "city": "Peñamiller",
    "city_ascii": "Penamiller",
    "lat": 21.0519,
    "lng": -99.815,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Querétaro",
    "capital": "minor",
    "population": 17007,
    "id": 1484158047
  },
  {
    "city": "Ambriz",
    "city_ascii": "Ambriz",
    "lat": -7.855,
    "lng": 13.125,
    "country": "Angola",
    "iso2": "AO",
    "iso3": "AGO",
    "admin_name": "Bengo",
    "capital": "",
    "population": 17000,
    "id": 1024609563
  },
  {
    "city": "Steffisburg",
    "city_ascii": "Steffisburg",
    "lat": 46.7831,
    "lng": 7.6333,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Bern",
    "capital": "",
    "population": 15816,
    "id": 1756440418
  },
  {
    "city": "Neratovice",
    "city_ascii": "Neratovice",
    "lat": 50.2593,
    "lng": 14.5177,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Středočeský Kraj",
    "capital": "",
    "population": 16191,
    "id": 1203620692
  },
  {
    "city": "Sienna Plantation",
    "city_ascii": "Sienna Plantation",
    "lat": 29.4834,
    "lng": -95.5065,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 16996,
    "id": 1840019642
  },
  {
    "city": "Lincoln",
    "city_ascii": "Lincoln",
    "lat": 40.1508,
    "lng": -89.3721,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 16993,
    "id": 1840009447
  },
  {
    "city": "Buffalo",
    "city_ascii": "Buffalo",
    "lat": 45.1796,
    "lng": -93.8644,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 16993,
    "id": 1840006703
  },
  {
    "city": "Ostrov",
    "city_ascii": "Ostrov",
    "lat": 50.306,
    "lng": 12.9392,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Karlovarský Kraj",
    "capital": "",
    "population": 16658,
    "id": 1203022506
  },
  {
    "city": "Spárti",
    "city_ascii": "Sparti",
    "lat": 37.0819,
    "lng": 22.4236,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Peloponnísos",
    "capital": "minor",
    "population": 16187,
    "id": 1300989189
  },
  {
    "city": "Sylacauga",
    "city_ascii": "Sylacauga",
    "lat": 33.1778,
    "lng": -86.2606,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 16982,
    "id": 1840015700
  },
  {
    "city": "Oconomowoc",
    "city_ascii": "Oconomowoc",
    "lat": 43.0995,
    "lng": -88.495,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 16981,
    "id": 1840003027
  },
  {
    "city": "Redland",
    "city_ascii": "Redland",
    "lat": 39.1335,
    "lng": -77.1465,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 16978,
    "id": 1840005838
  },
  {
    "city": "Cinco Ranch",
    "city_ascii": "Cinco Ranch",
    "lat": 29.7395,
    "lng": -95.7607,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 16977,
    "id": 1840037039
  },
  {
    "city": "Feilding",
    "city_ascii": "Feilding",
    "lat": -40.225,
    "lng": 175.565,
    "country": "New Zealand",
    "iso2": "NZ",
    "iso3": "NZL",
    "admin_name": "Manawatu-Wanganui",
    "capital": "",
    "population": 16450,
    "id": 1554000257
  },
  {
    "city": "Mátészalka",
    "city_ascii": "Mateszalka",
    "lat": 47.9431,
    "lng": 22.3167,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Szabolcs-Szatmár-Bereg",
    "capital": "minor",
    "population": 16576,
    "id": 1348758097
  },
  {
    "city": "Webster",
    "city_ascii": "Webster",
    "lat": 42.0521,
    "lng": -71.8485,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 16971,
    "id": 1840053610
  },
  {
    "city": "Loyalist",
    "city_ascii": "Loyalist",
    "lat": 44.25,
    "lng": -76.75,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 16971,
    "id": 1124001145
  },
  {
    "city": "Phoenixville",
    "city_ascii": "Phoenixville",
    "lat": 40.1358,
    "lng": -75.5201,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 16968,
    "id": 1840001433
  },
  {
    "city": "Batuco",
    "city_ascii": "Batuco",
    "lat": -33.2299,
    "lng": -70.8085,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Región Metropolitana",
    "capital": "",
    "population": 16784,
    "id": 1152375054
  },
  {
    "city": "East Lampeter",
    "city_ascii": "East Lampeter",
    "lat": 40.0375,
    "lng": -76.2162,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 16960,
    "id": 1840148810
  },
  {
    "city": "Krapkowice",
    "city_ascii": "Krapkowice",
    "lat": 50.4751,
    "lng": 17.9654,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Opolskie",
    "capital": "minor",
    "population": 16721,
    "id": 1616664766
  },
  {
    "city": "Vatutine",
    "city_ascii": "Vatutine",
    "lat": 49.0167,
    "lng": 31.0667,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Cherkas’ka Oblast’",
    "capital": "",
    "population": 16888,
    "id": 1804033648
  },
  {
    "city": "Neabsco",
    "city_ascii": "Neabsco",
    "lat": 38.6083,
    "lng": -77.2847,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 16947,
    "id": 1840043136
  },
  {
    "city": "Mountain Home",
    "city_ascii": "Mountain Home",
    "lat": 43.1324,
    "lng": -115.6972,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Idaho",
    "capital": "",
    "population": 16945,
    "id": 1840020017
  },
  {
    "city": "San José de Chiquitos",
    "city_ascii": "San Jose de Chiquitos",
    "lat": -17.85,
    "lng": -60.75,
    "country": "Bolivia",
    "iso2": "BO",
    "iso3": "BOL",
    "admin_name": "Santa Cruz",
    "capital": "",
    "population": 16942,
    "id": 1068082794
  },
  {
    "city": "Fındıklı",
    "city_ascii": "Findikli",
    "lat": 41.1333,
    "lng": 41.0167,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Rize",
    "capital": "minor",
    "population": 16902,
    "id": 1792497318
  },
  {
    "city": "Americus",
    "city_ascii": "Americus",
    "lat": 32.0736,
    "lng": -84.2249,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 16939,
    "id": 1840013841
  },
  {
    "city": "Republic",
    "city_ascii": "Republic",
    "lat": 37.1452,
    "lng": -93.4446,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 16938,
    "id": 1840009906
  },
  {
    "city": "Izyaslav",
    "city_ascii": "Izyaslav",
    "lat": 50.1167,
    "lng": 26.8,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Khmel’nyts’ka Oblast’",
    "capital": "minor",
    "population": 16901,
    "id": 1804060657
  },
  {
    "city": "Reinheim",
    "city_ascii": "Reinheim",
    "lat": 49.8261,
    "lng": 8.8348,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 16346,
    "id": 1276510132
  },
  {
    "city": "Kelheim",
    "city_ascii": "Kelheim",
    "lat": 48.9167,
    "lng": 11.8667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "minor",
    "population": 16714,
    "id": 1276123640
  },
  {
    "city": "Severínia",
    "city_ascii": "Severinia",
    "lat": -20.8089,
    "lng": -48.8028,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 16806,
    "id": 1076968616
  },
  {
    "city": "Río Bueno",
    "city_ascii": "Rio Bueno",
    "lat": -40.3337,
    "lng": -72.9568,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Los Ríos",
    "capital": "",
    "population": 16909,
    "id": 1152938619
  },
  {
    "city": "Dehāqān",
    "city_ascii": "Dehaqan",
    "lat": 31.94,
    "lng": 51.6478,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Eşfahān",
    "capital": "minor",
    "population": 16899,
    "id": 1364614039
  },
  {
    "city": "Wolf Trap",
    "city_ascii": "Wolf Trap",
    "lat": 38.9395,
    "lng": -77.2842,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 16911,
    "id": 1840006042
  },
  {
    "city": "Zhukovka",
    "city_ascii": "Zhukovka",
    "lat": 53.5339,
    "lng": 33.7281,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Bryanskaya Oblast’",
    "capital": "",
    "population": 16878,
    "id": 1643737188
  },
  {
    "city": "Buda",
    "city_ascii": "Buda",
    "lat": 30.0856,
    "lng": -97.8472,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 16906,
    "id": 1840019605
  },
  {
    "city": "Rakovski",
    "city_ascii": "Rakovski",
    "lat": 42.2875,
    "lng": 24.9678,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Plovdiv",
    "capital": "",
    "population": 16731,
    "id": 1100163147
  },
  {
    "city": "Upper Chichester",
    "city_ascii": "Upper Chichester",
    "lat": 39.8414,
    "lng": -75.4421,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 16898,
    "id": 1840142584
  },
  {
    "city": "Guareí",
    "city_ascii": "Guarei",
    "lat": -23.3728,
    "lng": -48.1842,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 16867,
    "id": 1076364248
  },
  {
    "city": "Alfajayucan",
    "city_ascii": "Alfajayucan",
    "lat": 20.4,
    "lng": -99.35,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Hidalgo",
    "capital": "",
    "population": 16859,
    "id": 1484991912
  },
  {
    "city": "Marbach am Neckar",
    "city_ascii": "Marbach am Neckar",
    "lat": 48.9406,
    "lng": 9.2575,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 16008,
    "id": 1276176133
  },
  {
    "city": "Port Lincoln",
    "city_ascii": "Port Lincoln",
    "lat": -34.7322,
    "lng": 135.8586,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "South Australia",
    "capital": "",
    "population": 16326,
    "id": 1036558150
  },
  {
    "city": "Hajdúnánás",
    "city_ascii": "Hajdunanas",
    "lat": 47.85,
    "lng": 21.4333,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Hajdú-Bihar",
    "capital": "minor",
    "population": 16828,
    "id": 1348235609
  },
  {
    "city": "Ban Thung Tam Sao",
    "city_ascii": "Ban Thung Tam Sao",
    "lat": 6.9581,
    "lng": 100.3207,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Songkhla",
    "capital": "",
    "population": 16344,
    "id": 1764139945
  },
  {
    "city": "Myrtle Grove",
    "city_ascii": "Myrtle Grove",
    "lat": 30.4158,
    "lng": -87.3028,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 16886,
    "id": 1840013917
  },
  {
    "city": "Karratha",
    "city_ascii": "Karratha",
    "lat": -20.7364,
    "lng": 116.8464,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Western Australia",
    "capital": "",
    "population": 16708,
    "id": 1036951388
  },
  {
    "city": "Stará Ľubovňa",
    "city_ascii": "Stara L'ubovna",
    "lat": 49.3167,
    "lng": 20.6833,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Prešovský",
    "capital": "minor",
    "population": 16348,
    "id": 1703928081
  },
  {
    "city": "Valle Vista",
    "city_ascii": "Valle Vista",
    "lat": 33.7436,
    "lng": -116.8872,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 16879,
    "id": 1840019292
  },
  {
    "city": "Southbridge",
    "city_ascii": "Southbridge",
    "lat": 42.0604,
    "lng": -72.0338,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 16878,
    "id": 1840132611
  },
  {
    "city": "Hohenems",
    "city_ascii": "Hohenems",
    "lat": 47.3667,
    "lng": 9.6667,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Vorarlberg",
    "capital": "",
    "population": 16317,
    "id": 1040845678
  },
  {
    "city": "Bürstadt",
    "city_ascii": "Burstadt",
    "lat": 49.6414,
    "lng": 8.4546,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 16398,
    "id": 1276454378
  },
  {
    "city": "Montagu",
    "city_ascii": "Montagu",
    "lat": -33.7833,
    "lng": 20.1167,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "Western Cape",
    "capital": "",
    "population": 15176,
    "id": 1710489752
  },
  {
    "city": "Lake St. Louis",
    "city_ascii": "Lake St. Louis",
    "lat": 38.7846,
    "lng": -90.7886,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 16864,
    "id": 1840008572
  },
  {
    "city": "Midland",
    "city_ascii": "Midland",
    "lat": 44.75,
    "lng": -79.8833,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 16864,
    "id": 1124104490
  },
  {
    "city": "Seagoville",
    "city_ascii": "Seagoville",
    "lat": 32.653,
    "lng": -96.5455,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 16861,
    "id": 1840022054
  },
  {
    "city": "Colwood",
    "city_ascii": "Colwood",
    "lat": 48.4236,
    "lng": -123.4958,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "British Columbia",
    "capital": "",
    "population": 16859,
    "id": 1124000395
  },
  {
    "city": "Fort Hunt",
    "city_ascii": "Fort Hunt",
    "lat": 38.7361,
    "lng": -77.0589,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 16858,
    "id": 1840006010
  },
  {
    "city": "Bella Vista",
    "city_ascii": "Bella Vista",
    "lat": -22.1296,
    "lng": -56.52,
    "country": "Paraguay",
    "iso2": "PY",
    "iso3": "PRY",
    "admin_name": "Amambay",
    "capital": "",
    "population": 16852,
    "id": 1600430147
  },
  {
    "city": "Crescent City",
    "city_ascii": "Crescent City",
    "lat": 41.7727,
    "lng": -124.1902,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 16849,
    "id": 1840018707
  },
  {
    "city": "Wilton",
    "city_ascii": "Wilton",
    "lat": 43.1502,
    "lng": -73.7276,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 16843,
    "id": 1840058618
  },
  {
    "city": "Groveton",
    "city_ascii": "Groveton",
    "lat": 38.7605,
    "lng": -77.098,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 16843,
    "id": 1840006029
  },
  {
    "city": "Locarno",
    "city_ascii": "Locarno",
    "lat": 46.1664,
    "lng": 8.7997,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Ticino",
    "capital": "",
    "population": 16012,
    "id": 1756696211
  },
  {
    "city": "Vallegrande",
    "city_ascii": "Vallegrande",
    "lat": -18.4894,
    "lng": -64.1083,
    "country": "Bolivia",
    "iso2": "BO",
    "iso3": "BOL",
    "admin_name": "Santa Cruz",
    "capital": "",
    "population": 16837,
    "id": 1068162149
  },
  {
    "city": "Saalfelden am Steinernen Meer",
    "city_ascii": "Saalfelden am Steinernen Meer",
    "lat": 47.4269,
    "lng": 12.8483,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Salzburg",
    "capital": "",
    "population": 16700,
    "id": 1040716716
  },
  {
    "city": "Silver Spring",
    "city_ascii": "Silver Spring",
    "lat": 40.2503,
    "lng": -77.0566,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 16839,
    "id": 1840151631
  },
  {
    "city": "Strzegom",
    "city_ascii": "Strzegom",
    "lat": 51,
    "lng": 16.3333,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Dolnośląskie",
    "capital": "",
    "population": 16650,
    "id": 1616613189
  },
  {
    "city": "Picayune",
    "city_ascii": "Picayune",
    "lat": 30.5322,
    "lng": -89.6724,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Mississippi",
    "capital": "",
    "population": 16832,
    "id": 1840015002
  },
  {
    "city": "Itariri",
    "city_ascii": "Itariri",
    "lat": -24.2888,
    "lng": -47.1332,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 16759,
    "id": 1076020953
  },
  {
    "city": "North Myrtle Beach",
    "city_ascii": "North Myrtle Beach",
    "lat": 33.823,
    "lng": -78.7089,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 16819,
    "id": 1840014718
  },
  {
    "city": "Gunzenhausen",
    "city_ascii": "Gunzenhausen",
    "lat": 49.1147,
    "lng": 10.7542,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 16614,
    "id": 1276273217
  },
  {
    "city": "Lemay",
    "city_ascii": "Lemay",
    "lat": 38.5325,
    "lng": -90.2845,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 16814,
    "id": 1840006119
  },
  {
    "city": "Wayne",
    "city_ascii": "Wayne",
    "lat": 42.2774,
    "lng": -83.3877,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 16814,
    "id": 1840001846
  },
  {
    "city": "Central Saanich",
    "city_ascii": "Central Saanich",
    "lat": 48.5142,
    "lng": -123.3839,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "British Columbia",
    "capital": "",
    "population": 16814,
    "id": 1124000519
  },
  {
    "city": "Rožnov pod Radhoštěm",
    "city_ascii": "Roznov pod Radhostem",
    "lat": 49.4585,
    "lng": 18.143,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Zlínský Kraj",
    "capital": "",
    "population": 16398,
    "id": 1203287923
  },
  {
    "city": "Ejea de los Caballeros",
    "city_ascii": "Ejea de los Caballeros",
    "lat": 42.1273,
    "lng": -1.1384,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Aragon",
    "capital": "",
    "population": 16783,
    "id": 1724015222
  },
  {
    "city": "Bemidji",
    "city_ascii": "Bemidji",
    "lat": 47.4828,
    "lng": -94.8796,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 16807,
    "id": 1840006600
  },
  {
    "city": "Ocean Acres",
    "city_ascii": "Ocean Acres",
    "lat": 39.743,
    "lng": -74.2804,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 16803,
    "id": 1840033483
  },
  {
    "city": "Sudak",
    "city_ascii": "Sudak",
    "lat": 44.8514,
    "lng": 34.9725,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Krym, Avtonomna Respublika",
    "capital": "",
    "population": 16756,
    "id": 1804728325
  },
  {
    "city": "Goodlettsville",
    "city_ascii": "Goodlettsville",
    "lat": 36.3327,
    "lng": -86.7029,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 16798,
    "id": 1840013345
  },
  {
    "city": "Kauhava",
    "city_ascii": "Kauhava",
    "lat": 63.1014,
    "lng": 23.0639,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Etelä-Pohjanmaa",
    "capital": "minor",
    "population": 16784,
    "id": 1246180633
  },
  {
    "city": "Mikumi",
    "city_ascii": "Mikumi",
    "lat": -7.3996,
    "lng": 36.98,
    "country": "Tanzania",
    "iso2": "TZ",
    "iso3": "TZA",
    "admin_name": "Morogoro",
    "capital": "",
    "population": 16780,
    "id": 1834405632
  },
  {
    "city": "Ham Lake",
    "city_ascii": "Ham Lake",
    "lat": 45.2545,
    "lng": -93.2039,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 16783,
    "id": 1840006720
  },
  {
    "city": "Cabeceiras de Basto",
    "city_ascii": "Cabeceiras de Basto",
    "lat": 41.5167,
    "lng": -8,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Braga",
    "capital": "minor",
    "population": 16710,
    "id": 1620118213
  },
  {
    "city": "Aue",
    "city_ascii": "Aue",
    "lat": 50.5853,
    "lng": 12.7008,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 16012,
    "id": 1276459690
  },
  {
    "city": "Chestnuthill",
    "city_ascii": "Chestnuthill",
    "lat": 40.9568,
    "lng": -75.4183,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 16776,
    "id": 1840145216
  },
  {
    "city": "Fairburn",
    "city_ascii": "Fairburn",
    "lat": 33.5496,
    "lng": -84.5914,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 16768,
    "id": 1840013662
  },
  {
    "city": "Ceadîr-Lunga",
    "city_ascii": "Ceadir-Lunga",
    "lat": 46.055,
    "lng": 28.8303,
    "country": "Moldova",
    "iso2": "MD",
    "iso3": "MDA",
    "admin_name": "Găgăuzia",
    "capital": "",
    "population": 16605,
    "id": 1498057941
  },
  {
    "city": "Bristol",
    "city_ascii": "Bristol",
    "lat": 36.618,
    "lng": -82.1606,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 16762,
    "id": 1840003879
  },
  {
    "city": "Sainte-Catherine",
    "city_ascii": "Sainte-Catherine",
    "lat": 45.4,
    "lng": -73.58,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 16762,
    "id": 1124941451
  },
  {
    "city": "Neston",
    "city_ascii": "Neston",
    "lat": 53.289,
    "lng": -3.074,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Cheshire West and Chester",
    "capital": "",
    "population": 15221,
    "id": 1826478798
  },
  {
    "city": "Lake Wales",
    "city_ascii": "Lake Wales",
    "lat": 27.9195,
    "lng": -81.5961,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 16759,
    "id": 1840015107
  },
  {
    "city": "Potirendaba",
    "city_ascii": "Potirendaba",
    "lat": -21.0428,
    "lng": -49.3769,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 16709,
    "id": 1076838060
  },
  {
    "city": "Uherský Brod",
    "city_ascii": "Uhersky Brod",
    "lat": 49.0251,
    "lng": 17.6472,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Zlínský Kraj",
    "capital": "",
    "population": 16441,
    "id": 1203839661
  },
  {
    "city": "Port Hope",
    "city_ascii": "Port Hope",
    "lat": 43.95,
    "lng": -78.3,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 16753,
    "id": 1124105292
  },
  {
    "city": "Ponte de Sôr",
    "city_ascii": "Ponte de Sor",
    "lat": 39.25,
    "lng": -8.0167,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Portalegre",
    "capital": "minor",
    "population": 16722,
    "id": 1620028457
  },
  {
    "city": "Singleton",
    "city_ascii": "Singleton",
    "lat": -32.5667,
    "lng": 151.1697,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 16346,
    "id": 1036947514
  },
  {
    "city": "North Babylon",
    "city_ascii": "North Babylon",
    "lat": 40.7311,
    "lng": -73.3251,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 16739,
    "id": 1840005054
  },
  {
    "city": "Novomichurinsk",
    "city_ascii": "Novomichurinsk",
    "lat": 54.0333,
    "lng": 39.75,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Ryazanskaya Oblast’",
    "capital": "",
    "population": 16710,
    "id": 1643380709
  },
  {
    "city": "New Port Richey",
    "city_ascii": "New Port Richey",
    "lat": 28.2468,
    "lng": -82.717,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 16737,
    "id": 1840015102
  },
  {
    "city": "Rosedale",
    "city_ascii": "Rosedale",
    "lat": 35.3887,
    "lng": -119.2058,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 16737,
    "id": 1840019141
  },
  {
    "city": "Saint-Basile-le-Grand",
    "city_ascii": "Saint-Basile-le-Grand",
    "lat": 45.5333,
    "lng": -73.2833,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 16736,
    "id": 1124000968
  },
  {
    "city": "Morombe",
    "city_ascii": "Morombe",
    "lat": -21.7391,
    "lng": 43.3657,
    "country": "Madagascar",
    "iso2": "MG",
    "iso3": "MDG",
    "admin_name": "Toliara",
    "capital": "",
    "population": 16727,
    "id": 1450018559
  },
  {
    "city": "Shiraoi",
    "city_ascii": "Shiraoi",
    "lat": 42.5511,
    "lng": 141.3558,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hokkaidō",
    "capital": "",
    "population": 16694,
    "id": 1392327130
  },
  {
    "city": "Wimborne Minster",
    "city_ascii": "Wimborne Minster",
    "lat": 50.804,
    "lng": -1.978,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Dorset",
    "capital": "",
    "population": 15552,
    "id": 1826051938
  },
  {
    "city": "Scott Township",
    "city_ascii": "Scott Township",
    "lat": 40.3875,
    "lng": -80.0791,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 16720,
    "id": 1840141807
  },
  {
    "city": "Lindsay",
    "city_ascii": "Lindsay",
    "lat": 36.2082,
    "lng": -119.0897,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 16713,
    "id": 1840020367
  },
  {
    "city": "Illingen",
    "city_ascii": "Illingen",
    "lat": 49.3764,
    "lng": 7.0525,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saarland",
    "capital": "",
    "population": 16262,
    "id": 1276359675
  },
  {
    "city": "Seacombe",
    "city_ascii": "Seacombe",
    "lat": 53.409,
    "lng": -3.029,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Wirral",
    "capital": "",
    "population": 15387,
    "id": 1826216139
  },
  {
    "city": "Troy",
    "city_ascii": "Troy",
    "lat": 31.8021,
    "lng": -85.9665,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 16712,
    "id": 1840005558
  },
  {
    "city": "Louth",
    "city_ascii": "Louth",
    "lat": 53.3669,
    "lng": -0.0061,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Lincolnshire",
    "capital": "",
    "population": 16419,
    "id": 1826872959
  },
  {
    "city": "Ansfelden",
    "city_ascii": "Ansfelden",
    "lat": 48.2083,
    "lng": 14.2889,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Oberösterreich",
    "capital": "",
    "population": 16194,
    "id": 1040496902
  },
  {
    "city": "Pochëp",
    "city_ascii": "Pochep",
    "lat": 52.9333,
    "lng": 33.45,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Bryanskaya Oblast’",
    "capital": "",
    "population": 16687,
    "id": 1643674732
  },
  {
    "city": "Kings Park",
    "city_ascii": "Kings Park",
    "lat": 40.8887,
    "lng": -73.2452,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 16707,
    "id": 1840005085
  },
  {
    "city": "Salesópolis",
    "city_ascii": "Salesopolis",
    "lat": -23.5319,
    "lng": -45.8458,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 16688,
    "id": 1076843165
  },
  {
    "city": "Aston",
    "city_ascii": "Aston",
    "lat": 39.8718,
    "lng": -75.4348,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 16706,
    "id": 1840150553
  },
  {
    "city": "Chełmża",
    "city_ascii": "Chelmza",
    "lat": 53.2,
    "lng": 18.6,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Kujawsko-Pomorskie",
    "capital": "",
    "population": 14808,
    "id": 1616701343
  },
  {
    "city": "Ishinari",
    "city_ascii": "Ishinari",
    "lat": 33.3211,
    "lng": 130.3989,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Saga",
    "capital": "",
    "population": 16328,
    "id": 1392473342
  },
  {
    "city": "Bruntál",
    "city_ascii": "Bruntal",
    "lat": 49.9884,
    "lng": 17.4648,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Moravskoslezský Kraj",
    "capital": "",
    "population": 16138,
    "id": 1203666435
  },
  {
    "city": "Cohoes",
    "city_ascii": "Cohoes",
    "lat": 42.7732,
    "lng": -73.7077,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 16687,
    "id": 1840000418
  },
  {
    "city": "Puerto López",
    "city_ascii": "Puerto Lopez",
    "lat": 4.09,
    "lng": -72.96,
    "country": "Colombia",
    "iso2": "CO",
    "iso3": "COL",
    "admin_name": "Meta",
    "capital": "minor",
    "population": 16678,
    "id": 1170290556
  },
  {
    "city": "Zeulenroda",
    "city_ascii": "Zeulenroda",
    "lat": 50.6333,
    "lng": 11.9667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Thuringia",
    "capital": "",
    "population": 16487,
    "id": 1276933669
  },
  {
    "city": "Añisoc",
    "city_ascii": "Anisoc",
    "lat": 1.8656,
    "lng": 10.7689,
    "country": "Equatorial Guinea",
    "iso2": "GQ",
    "iso3": "GNQ",
    "admin_name": "Wele-Nzas",
    "capital": "",
    "population": 16626,
    "id": 1226309663
  },
  {
    "city": "McComb",
    "city_ascii": "McComb",
    "lat": 31.2442,
    "lng": -90.4717,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Mississippi",
    "capital": "",
    "population": 16672,
    "id": 1840015877
  },
  {
    "city": "Highland Village",
    "city_ascii": "Highland Village",
    "lat": 33.0897,
    "lng": -97.0615,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 16668,
    "id": 1840020638
  },
  {
    "city": "Denville",
    "city_ascii": "Denville",
    "lat": 40.8889,
    "lng": -74.4893,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 16667,
    "id": 1840081734
  },
  {
    "city": "Ōarai",
    "city_ascii": "Oarai",
    "lat": 36.3133,
    "lng": 140.575,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Ibaraki",
    "capital": "",
    "population": 15992,
    "id": 1392502635
  },
  {
    "city": "Wolcott",
    "city_ascii": "Wolcott",
    "lat": 41.6007,
    "lng": -72.9734,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 16652,
    "id": 1840035673
  },
  {
    "city": "Pecan Grove",
    "city_ascii": "Pecan Grove",
    "lat": 29.6235,
    "lng": -95.7331,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 16651,
    "id": 1840019645
  },
  {
    "city": "Athens",
    "city_ascii": "Athens",
    "lat": 35.4573,
    "lng": -84.6045,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 16651,
    "id": 1840013429
  },
  {
    "city": "Dilijan",
    "city_ascii": "Dilijan",
    "lat": 40.7408,
    "lng": 44.8631,
    "country": "Armenia",
    "iso2": "AM",
    "iso3": "ARM",
    "admin_name": "Tavush",
    "capital": "",
    "population": 16600,
    "id": 1051278149
  },
  {
    "city": "Auburndale",
    "city_ascii": "Auburndale",
    "lat": 28.0962,
    "lng": -81.8011,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 16650,
    "id": 1840014127
  },
  {
    "city": "Hünfeld",
    "city_ascii": "Hunfeld",
    "lat": 50.6667,
    "lng": 9.7667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 16512,
    "id": 1276813919
  },
  {
    "city": "Jiblah",
    "city_ascii": "Jiblah",
    "lat": 13.9167,
    "lng": 44.15,
    "country": "Yemen",
    "iso2": "YE",
    "iso3": "YEM",
    "admin_name": "Ibb",
    "capital": "minor",
    "population": 15431,
    "id": 1887050093
  },
  {
    "city": "Ad Dīs ash Sharqīyah",
    "city_ascii": "Ad Dis ash Sharqiyah",
    "lat": 14.8833,
    "lng": 49.8667,
    "country": "Yemen",
    "iso2": "YE",
    "iso3": "YEM",
    "admin_name": "Ḩaḑramawt",
    "capital": "minor",
    "population": 16614,
    "id": 1887711312
  },
  {
    "city": "Auburn",
    "city_ascii": "Auburn",
    "lat": 42.1972,
    "lng": -71.8453,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 16633,
    "id": 1840053684
  },
  {
    "city": "Delran",
    "city_ascii": "Delran",
    "lat": 40.017,
    "lng": -74.9495,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 16631,
    "id": 1840081628
  },
  {
    "city": "Banbridge",
    "city_ascii": "Banbridge",
    "lat": 54.343,
    "lng": -6.26,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Armagh, Banbridge and Craigavon",
    "capital": "",
    "population": 14744,
    "id": 1826331367
  },
  {
    "city": "Denby Dale",
    "city_ascii": "Denby Dale",
    "lat": 53.572,
    "lng": -1.655,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Kirklees",
    "capital": "",
    "population": 16365,
    "id": 1826175295
  },
  {
    "city": "Kovdor",
    "city_ascii": "Kovdor",
    "lat": 67.5594,
    "lng": 30.4667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Murmanskaya Oblast’",
    "capital": "",
    "population": 16623,
    "id": 1643366927
  },
  {
    "city": "Ivanava",
    "city_ascii": "Ivanava",
    "lat": 52.1333,
    "lng": 25.55,
    "country": "Belarus",
    "iso2": "BY",
    "iso3": "BLR",
    "admin_name": "Brestskaya Voblasts’",
    "capital": "minor",
    "population": 16600,
    "id": 1112507447
  },
  {
    "city": "Cutlerville",
    "city_ascii": "Cutlerville",
    "lat": 42.8405,
    "lng": -85.6739,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 16623,
    "id": 1840004308
  },
  {
    "city": "Zapotlán de Juárez",
    "city_ascii": "Zapotlan de Juarez",
    "lat": 19.9667,
    "lng": -98.85,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Hidalgo",
    "capital": "minor",
    "population": 16493,
    "id": 1484756389
  },
  {
    "city": "Corabia",
    "city_ascii": "Corabia",
    "lat": 43.7736,
    "lng": 24.5033,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Olt",
    "capital": "",
    "population": 16441,
    "id": 1642203673
  },
  {
    "city": "Gainesville",
    "city_ascii": "Gainesville",
    "lat": 33.6391,
    "lng": -97.1488,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 16615,
    "id": 1840020573
  },
  {
    "city": "McKinleyville",
    "city_ascii": "McKinleyville",
    "lat": 40.9488,
    "lng": -124.0857,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 16612,
    "id": 1840017485
  },
  {
    "city": "Geddes",
    "city_ascii": "Geddes",
    "lat": 43.0767,
    "lng": -76.2243,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 16610,
    "id": 1840087460
  },
  {
    "city": "El Segundo",
    "city_ascii": "El Segundo",
    "lat": 33.9169,
    "lng": -118.4021,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 16610,
    "id": 1840020481
  },
  {
    "city": "Mercedes",
    "city_ascii": "Mercedes",
    "lat": 26.1533,
    "lng": -97.9129,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 16604,
    "id": 1840021025
  },
  {
    "city": "Swift Current",
    "city_ascii": "Swift Current",
    "lat": 50.2881,
    "lng": -107.7939,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Saskatchewan",
    "capital": "",
    "population": 16604,
    "id": 1124460875
  },
  {
    "city": "Macetown",
    "city_ascii": "Macetown",
    "lat": -44.865,
    "lng": 168.819,
    "country": "New Zealand",
    "iso2": "NZ",
    "iso3": "NZL",
    "admin_name": "Otago",
    "capital": "",
    "population": 16600,
    "id": 1554582165
  },
  {
    "city": "Centre de Flacq",
    "city_ascii": "Centre de Flacq",
    "lat": -20.2002,
    "lng": 57.7177,
    "country": "Mauritius",
    "iso2": "MU",
    "iso3": "MUS",
    "admin_name": "Flacq",
    "capital": "",
    "population": 15791,
    "id": 1480176466
  },
  {
    "city": "Olecko",
    "city_ascii": "Olecko",
    "lat": 54.0333,
    "lng": 22.5,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Warmińsko-Mazurskie",
    "capital": "minor",
    "population": 16508,
    "id": 1616175752
  },
  {
    "city": "Norcross",
    "city_ascii": "Norcross",
    "lat": 33.9379,
    "lng": -84.2065,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 16592,
    "id": 1840014744
  },
  {
    "city": "Buritama",
    "city_ascii": "Buritama",
    "lat": -21.0667,
    "lng": -50.1475,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 16583,
    "id": 1076594548
  },
  {
    "city": "Kurtamysh",
    "city_ascii": "Kurtamysh",
    "lat": 54.9,
    "lng": 64.4333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kurganskaya Oblast’",
    "capital": "",
    "population": 16579,
    "id": 1643527477
  },
  {
    "city": "Mifune",
    "city_ascii": "Mifune",
    "lat": 32.7144,
    "lng": 130.8019,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Kumamoto",
    "capital": "",
    "population": 16417,
    "id": 1392426855
  },
  {
    "city": "Tredegar",
    "city_ascii": "Tredegar",
    "lat": 51.7776,
    "lng": -3.2407,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Blaenau Gwent",
    "capital": "",
    "population": 15103,
    "id": 1826084160
  },
  {
    "city": "Holmdel",
    "city_ascii": "Holmdel",
    "lat": 40.3768,
    "lng": -74.1725,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 16582,
    "id": 1840081650
  },
  {
    "city": "Batavia",
    "city_ascii": "Batavia",
    "lat": 42.9987,
    "lng": -78.1802,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 16581,
    "id": 1840000385
  },
  {
    "city": "Edmundston",
    "city_ascii": "Edmundston",
    "lat": 47.3765,
    "lng": -68.3253,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "New Brunswick",
    "capital": "",
    "population": 16580,
    "id": 1124274233
  },
  {
    "city": "Chinde",
    "city_ascii": "Chinde",
    "lat": -18.5833,
    "lng": 36.4667,
    "country": "Mozambique",
    "iso2": "MZ",
    "iso3": "MOZ",
    "admin_name": "Zambézia",
    "capital": "",
    "population": 16500,
    "id": 1508587868
  },
  {
    "city": "Morganton",
    "city_ascii": "Morganton",
    "lat": 35.7408,
    "lng": -81.7003,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 16577,
    "id": 1840014504
  },
  {
    "city": "Harrisburg",
    "city_ascii": "Harrisburg",
    "lat": 35.3124,
    "lng": -80.6486,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 16576,
    "id": 1840016364
  },
  {
    "city": "Weybridge",
    "city_ascii": "Weybridge",
    "lat": 51.362,
    "lng": -0.453,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Surrey",
    "capital": "",
    "population": 15449,
    "id": 1826438211
  },
  {
    "city": "Keflavík",
    "city_ascii": "Keflavik",
    "lat": 64,
    "lng": -22.5819,
    "country": "Iceland",
    "iso2": "IS",
    "iso3": "ISL",
    "admin_name": "Suðurnes",
    "capital": "",
    "population": 16463,
    "id": 1352901945
  },
  {
    "city": "Berezan’",
    "city_ascii": "Berezan'",
    "lat": 50.3197,
    "lng": 31.47,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Kyyivs’ka Oblast’",
    "capital": "",
    "population": 16506,
    "id": 1804009720
  },
  {
    "city": "Rakovník",
    "city_ascii": "Rakovnik",
    "lat": 50.1038,
    "lng": 13.7335,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Středočeský Kraj",
    "capital": "",
    "population": 15709,
    "id": 1203062806
  },
  {
    "city": "Quarteira",
    "city_ascii": "Quarteira",
    "lat": 37.0695,
    "lng": -8.1006,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Faro",
    "capital": "",
    "population": 16131,
    "id": 1620157848
  },
  {
    "city": "Langenthal",
    "city_ascii": "Langenthal",
    "lat": 47.2153,
    "lng": 7.7889,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Bern",
    "capital": "",
    "population": 15639,
    "id": 1756087578
  },
  {
    "city": "Bayat",
    "city_ascii": "Bayat",
    "lat": 40.646,
    "lng": 34.261,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Çorum",
    "capital": "minor",
    "population": 16525,
    "id": 1792851737
  },
  {
    "city": "Pedregulho",
    "city_ascii": "Pedregulho",
    "lat": -20.2569,
    "lng": -47.4767,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 16517,
    "id": 1076549433
  },
  {
    "city": "Donaldsonville",
    "city_ascii": "Donaldsonville",
    "lat": 30.0954,
    "lng": -90.9926,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 16540,
    "id": 1840015045
  },
  {
    "city": "Solvang",
    "city_ascii": "Solvang",
    "lat": 34.5936,
    "lng": -120.1401,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 16540,
    "id": 1840021831
  },
  {
    "city": "World Golf Village",
    "city_ascii": "World Golf Village",
    "lat": 29.9653,
    "lng": -81.4898,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 16538,
    "id": 1840039162
  },
  {
    "city": "Charqueada",
    "city_ascii": "Charqueada",
    "lat": -22.5097,
    "lng": -47.7781,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 16440,
    "id": 1076382769
  },
  {
    "city": "Kisvárda",
    "city_ascii": "Kisvarda",
    "lat": 48.2264,
    "lng": 22.0844,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Szabolcs-Szatmár-Bereg",
    "capital": "minor",
    "population": 16084,
    "id": 1348590811
  },
  {
    "city": "Mapiri",
    "city_ascii": "Mapiri",
    "lat": -15.3097,
    "lng": -68.2161,
    "country": "Bolivia",
    "iso2": "BO",
    "iso3": "BOL",
    "admin_name": "La Paz",
    "capital": "",
    "population": 16520,
    "id": 1068025834
  },
  {
    "city": "Horodok",
    "city_ascii": "Horodok",
    "lat": 49.1667,
    "lng": 26.5667,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Khmel’nyts’ka Oblast’",
    "capital": "minor",
    "population": 16516,
    "id": 1804176513
  },
  {
    "city": "Garðabær",
    "city_ascii": "Gardhabaer",
    "lat": 64.0833,
    "lng": -22,
    "country": "Iceland",
    "iso2": "IS",
    "iso3": "ISL",
    "admin_name": "",
    "capital": "",
    "population": 16299,
    "id": 1352650773
  },
  {
    "city": "Říčany",
    "city_ascii": "Ricany",
    "lat": 49.9917,
    "lng": 14.6543,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Středočeský Kraj",
    "capital": "",
    "population": 15908,
    "id": 1203639839
  },
  {
    "city": "Northbridge",
    "city_ascii": "Northbridge",
    "lat": 42.13,
    "lng": -71.6547,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 16522,
    "id": 1840053598
  },
  {
    "city": "Seymour",
    "city_ascii": "Seymour",
    "lat": 41.381,
    "lng": -73.0873,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 16522,
    "id": 1840035570
  },
  {
    "city": "Russell",
    "city_ascii": "Russell",
    "lat": 45.2569,
    "lng": -75.3583,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 16520,
    "id": 1124982538
  },
  {
    "city": "Taylor",
    "city_ascii": "Taylor",
    "lat": 30.5743,
    "lng": -97.4234,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 16519,
    "id": 1840022165
  },
  {
    "city": "El Harhoura",
    "city_ascii": "El Harhoura",
    "lat": 33.95,
    "lng": -6.93,
    "country": "Morocco",
    "iso2": "MA",
    "iso3": "MAR",
    "admin_name": "Rabat-Salé-Kénitra",
    "capital": "",
    "population": 15361,
    "id": 1504102801
  },
  {
    "city": "Washington",
    "city_ascii": "Washington",
    "lat": 40.7049,
    "lng": -89.4346,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 16516,
    "id": 1840010377
  },
  {
    "city": "Storrs",
    "city_ascii": "Storrs",
    "lat": 41.8045,
    "lng": -72.2552,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 16516,
    "id": 1840003276
  },
  {
    "city": "Sumiyama",
    "city_ascii": "Sumiyama",
    "lat": 34.8814,
    "lng": 135.7328,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Kyōto",
    "capital": "",
    "population": 15399,
    "id": 1392461116
  },
  {
    "city": "Bady Bassitt",
    "city_ascii": "Bady Bassitt",
    "lat": -20.9178,
    "lng": -49.445,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 16359,
    "id": 1076001081
  },
  {
    "city": "Franklin Township",
    "city_ascii": "Franklin Township",
    "lat": 39.5954,
    "lng": -75.019,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 16505,
    "id": 1840150769
  },
  {
    "city": "Yaypan",
    "city_ascii": "Yaypan",
    "lat": 40.3758,
    "lng": 70.8156,
    "country": "Uzbekistan",
    "iso2": "UZ",
    "iso3": "UZB",
    "admin_name": "Farg‘ona",
    "capital": "minor",
    "population": 15984,
    "id": 1860703672
  },
  {
    "city": "Live Oak",
    "city_ascii": "Live Oak",
    "lat": 29.5545,
    "lng": -98.3404,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 16499,
    "id": 1840020960
  },
  {
    "city": "Wyke",
    "city_ascii": "Wyke",
    "lat": 53.7369,
    "lng": -1.77,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Bradford",
    "capital": "",
    "population": 14950,
    "id": 1826505143
  },
  {
    "city": "Tsukumiura",
    "city_ascii": "Tsukumiura",
    "lat": 33.0725,
    "lng": 131.8611,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Ōita",
    "capital": "",
    "population": 16291,
    "id": 1392037074
  },
  {
    "city": "Karema",
    "city_ascii": "Karema",
    "lat": -6.8162,
    "lng": 30.4333,
    "country": "Tanzania",
    "iso2": "TZ",
    "iso3": "TZA",
    "admin_name": "Katavi",
    "capital": "",
    "population": 16385,
    "id": 1834711465
  },
  {
    "city": "Pearl River",
    "city_ascii": "Pearl River",
    "lat": 41.0615,
    "lng": -74.0047,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 16495,
    "id": 1840004966
  },
  {
    "city": "Cinnaminson",
    "city_ascii": "Cinnaminson",
    "lat": 40.0008,
    "lng": -74.993,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 16493,
    "id": 1840081627
  },
  {
    "city": "Lock Haven",
    "city_ascii": "Lock Haven",
    "lat": 41.1365,
    "lng": -77.4521,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 16490,
    "id": 1840003450
  },
  {
    "city": "Loimaa",
    "city_ascii": "Loimaa",
    "lat": 60.8514,
    "lng": 23.0583,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Varsinais-Suomi",
    "capital": "minor",
    "population": 16467,
    "id": 1246923432
  },
  {
    "city": "Vienna",
    "city_ascii": "Vienna",
    "lat": 38.8996,
    "lng": -77.2597,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 16485,
    "id": 1840003829
  },
  {
    "city": "Country Club Hills",
    "city_ascii": "Country Club Hills",
    "lat": 41.5637,
    "lng": -87.725,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 16482,
    "id": 1840007025
  },
  {
    "city": "Streetsboro",
    "city_ascii": "Streetsboro",
    "lat": 41.2396,
    "lng": -81.3456,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 16478,
    "id": 1840000808
  },
  {
    "city": "Kirchhain",
    "city_ascii": "Kirchhain",
    "lat": 50.8167,
    "lng": 8.9167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 16298,
    "id": 1276056692
  },
  {
    "city": "Eden",
    "city_ascii": "Eden",
    "lat": 36.5028,
    "lng": -79.7412,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 16477,
    "id": 1840013309
  },
  {
    "city": "Jasper",
    "city_ascii": "Jasper",
    "lat": 38.3933,
    "lng": -86.9402,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 16477,
    "id": 1840008646
  },
  {
    "city": "Aki",
    "city_ascii": "Aki",
    "lat": 33.5025,
    "lng": 133.9072,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Kōchi",
    "capital": "",
    "population": 16424,
    "id": 1392875978
  },
  {
    "city": "Kozelsk",
    "city_ascii": "Kozelsk",
    "lat": 54.0333,
    "lng": 35.7833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kaluzhskaya Oblast’",
    "capital": "",
    "population": 16443,
    "id": 1643293500
  },
  {
    "city": "Ḩammām al ‘Alīl",
    "city_ascii": "Hammam al `Alil",
    "lat": 36.1581,
    "lng": 43.2594,
    "country": "Iraq",
    "iso2": "IQ",
    "iso3": "IRQ",
    "admin_name": "Nīnawá",
    "capital": "",
    "population": 15985,
    "id": 1368957629
  },
  {
    "city": "Douglas",
    "city_ascii": "Douglas",
    "lat": 31.3602,
    "lng": -109.5394,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 16467,
    "id": 1840019496
  },
  {
    "city": "Bilohirs’k",
    "city_ascii": "Bilohirs'k",
    "lat": 45.0544,
    "lng": 34.6022,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Krym, Avtonomna Respublika",
    "capital": "minor",
    "population": 16428,
    "id": 1804571792
  },
  {
    "city": "Swansea",
    "city_ascii": "Swansea",
    "lat": 41.7571,
    "lng": -71.212,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 16462,
    "id": 1840053663
  },
  {
    "city": "Crowley",
    "city_ascii": "Crowley",
    "lat": 32.5781,
    "lng": -97.3585,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 16460,
    "id": 1840019431
  },
  {
    "city": "Brackenheim",
    "city_ascii": "Brackenheim",
    "lat": 49.0833,
    "lng": 9.0667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 16106,
    "id": 1276363194
  },
  {
    "city": "West Hempfield",
    "city_ascii": "West Hempfield",
    "lat": 40.0564,
    "lng": -76.4632,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 16454,
    "id": 1840152740
  },
  {
    "city": "Glen Allen",
    "city_ascii": "Glen Allen",
    "lat": 37.666,
    "lng": -77.4838,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 16454,
    "id": 1840006390
  },
  {
    "city": "Burrillville",
    "city_ascii": "Burrillville",
    "lat": 41.9706,
    "lng": -71.6984,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Rhode Island",
    "capital": "",
    "population": 16453,
    "id": 1840106236
  },
  {
    "city": "North Grenville",
    "city_ascii": "North Grenville",
    "lat": 44.9667,
    "lng": -75.65,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 16451,
    "id": 1124000746
  },
  {
    "city": "Hayes",
    "city_ascii": "Hayes",
    "lat": 51.378,
    "lng": 0.0192,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Bromley",
    "capital": "",
    "population": 15906,
    "id": 1826550930
  },
  {
    "city": "Durham",
    "city_ascii": "Durham",
    "lat": 43.1174,
    "lng": -70.9195,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Hampshire",
    "capital": "",
    "population": 16440,
    "id": 1840054805
  },
  {
    "city": "Bordj Mokhtar",
    "city_ascii": "Bordj Mokhtar",
    "lat": 21.3289,
    "lng": 0.9542,
    "country": "Algeria",
    "iso2": "DZ",
    "iso3": "DZA",
    "admin_name": "Adrar",
    "capital": "",
    "population": 16437,
    "id": 1012945794
  },
  {
    "city": "Rolleston",
    "city_ascii": "Rolleston",
    "lat": -43.596,
    "lng": 172.383,
    "country": "New Zealand",
    "iso2": "NZ",
    "iso3": "NZL",
    "admin_name": "Canterbury",
    "capital": "",
    "population": 16350,
    "id": 1554176166
  },
  {
    "city": "Lymington",
    "city_ascii": "Lymington",
    "lat": 50.75,
    "lng": -1.55,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hampshire",
    "capital": "",
    "population": 15726,
    "id": 1826325282
  },
  {
    "city": "Finsterwalde",
    "city_ascii": "Finsterwalde",
    "lat": 51.6331,
    "lng": 13.7167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Brandenburg",
    "capital": "",
    "population": 16220,
    "id": 1276493643
  },
  {
    "city": "Pindorama",
    "city_ascii": "Pindorama",
    "lat": -21.1858,
    "lng": -48.9069,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 16342,
    "id": 1076588629
  },
  {
    "city": "Upper Saucon",
    "city_ascii": "Upper Saucon",
    "lat": 40.5364,
    "lng": -75.4084,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 16429,
    "id": 1840146376
  },
  {
    "city": "Verkhivtseve",
    "city_ascii": "Verkhivtseve",
    "lat": 48.4812,
    "lng": 34.2488,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Dnipropetrovs’ka Oblast’",
    "capital": "",
    "population": 16364,
    "id": 1804209032
  },
  {
    "city": "Przeworsk",
    "city_ascii": "Przeworsk",
    "lat": 50.0591,
    "lng": 22.4941,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Podkarpackie",
    "capital": "minor",
    "population": 15713,
    "id": 1616040608
  },
  {
    "city": "Camano",
    "city_ascii": "Camano",
    "lat": 48.1865,
    "lng": -122.4708,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 16427,
    "id": 1840037492
  },
  {
    "city": "Al Ḩībah",
    "city_ascii": "Al Hibah",
    "lat": 28.8,
    "lng": 30.9167,
    "country": "Egypt",
    "iso2": "EG",
    "iso3": "EGY",
    "admin_name": "Al Baḩr al Aḩmar",
    "capital": "",
    "population": 16209,
    "id": 1818176638
  },
  {
    "city": "Uckfield",
    "city_ascii": "Uckfield",
    "lat": 50.97,
    "lng": 0.1,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "East Sussex",
    "capital": "",
    "population": 14493,
    "id": 1826320254
  },
  {
    "city": "Portachuelo",
    "city_ascii": "Portachuelo",
    "lat": -17.3547,
    "lng": -63.3947,
    "country": "Bolivia",
    "iso2": "BO",
    "iso3": "BOL",
    "admin_name": "Santa Cruz",
    "capital": "",
    "population": 16408,
    "id": 1068477207
  },
  {
    "city": "Groveland",
    "city_ascii": "Groveland",
    "lat": 28.6021,
    "lng": -81.8204,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 16423,
    "id": 1840014056
  },
  {
    "city": "Sabaneta",
    "city_ascii": "Sabaneta",
    "lat": 19.505,
    "lng": -71.345,
    "country": "Dominican Republic",
    "iso2": "DO",
    "iso3": "DOM",
    "admin_name": "Cibao Noroeste",
    "capital": "minor",
    "population": 16380,
    "id": 1214181927
  },
  {
    "city": "Muckendorf an der Donau",
    "city_ascii": "Muckendorf an der Donau",
    "lat": 48.3333,
    "lng": 16.05,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Niederösterreich",
    "capital": "",
    "population": 16197,
    "id": 1040106650
  },
  {
    "city": "Manturovo",
    "city_ascii": "Manturovo",
    "lat": 58.3333,
    "lng": 44.7667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kostromskaya Oblast’",
    "capital": "",
    "population": 15452,
    "id": 1643951602
  },
  {
    "city": "Bangor",
    "city_ascii": "Bangor",
    "lat": 53.228,
    "lng": -4.128,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Isle of Anglesey",
    "capital": "",
    "population": 16358,
    "id": 1826969137
  },
  {
    "city": "La Falda",
    "city_ascii": "La Falda",
    "lat": -31.0833,
    "lng": -64.5,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Córdoba",
    "capital": "",
    "population": 16335,
    "id": 1032413311
  },
  {
    "city": "Mount Pleasant",
    "city_ascii": "Mount Pleasant",
    "lat": 33.1613,
    "lng": -94.9717,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 16412,
    "id": 1840020666
  },
  {
    "city": "East Greenbush",
    "city_ascii": "East Greenbush",
    "lat": 42.6122,
    "lng": -73.6969,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 16411,
    "id": 1840058060
  },
  {
    "city": "Collipulli",
    "city_ascii": "Collipulli",
    "lat": -37.96,
    "lng": -72.43,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Araucanía",
    "capital": "",
    "population": 16392,
    "id": 1152908869
  },
  {
    "city": "Herisau",
    "city_ascii": "Herisau",
    "lat": 47.3851,
    "lng": 9.2786,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Appenzell Ausserrhoden",
    "capital": "",
    "population": 15780,
    "id": 1756581563
  },
  {
    "city": "Bad Lippspringe",
    "city_ascii": "Bad Lippspringe",
    "lat": 51.7833,
    "lng": 8.8167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 16089,
    "id": 1276944431
  },
  {
    "city": "River Falls",
    "city_ascii": "River Falls",
    "lat": 44.861,
    "lng": -92.6248,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 16402,
    "id": 1840002289
  },
  {
    "city": "Hernando",
    "city_ascii": "Hernando",
    "lat": 34.85,
    "lng": -89.9922,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Mississippi",
    "capital": "",
    "population": 16399,
    "id": 1840014642
  },
  {
    "city": "Wurzen",
    "city_ascii": "Wurzen",
    "lat": 51.3667,
    "lng": 12.7167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 16154,
    "id": 1276002805
  },
  {
    "city": "Ripon",
    "city_ascii": "Ripon",
    "lat": 37.7417,
    "lng": -121.131,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 16386,
    "id": 1840020264
  },
  {
    "city": "Weißwasser",
    "city_ascii": "Weisswasser",
    "lat": 51.5,
    "lng": 14.6331,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 16130,
    "id": 1276986089
  },
  {
    "city": "Penicuik",
    "city_ascii": "Penicuik",
    "lat": 55.826,
    "lng": -3.22,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Midlothian",
    "capital": "",
    "population": 15926,
    "id": 1826366579
  },
  {
    "city": "Divriği",
    "city_ascii": "Divrigi",
    "lat": 39.3667,
    "lng": 38.1167,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Sivas",
    "capital": "minor",
    "population": 16377,
    "id": 1792352411
  },
  {
    "city": "Monroe",
    "city_ascii": "Monroe",
    "lat": 33.799,
    "lng": -83.716,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 16380,
    "id": 1840014786
  },
  {
    "city": "Kasumi",
    "city_ascii": "Kasumi",
    "lat": 35.6322,
    "lng": 134.6292,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hyōgo",
    "capital": "",
    "population": 16321,
    "id": 1392645167
  },
  {
    "city": "Brakel",
    "city_ascii": "Brakel",
    "lat": 51.7167,
    "lng": 9.1833,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 16270,
    "id": 1276092395
  },
  {
    "city": "Kierspe",
    "city_ascii": "Kierspe",
    "lat": 51.1333,
    "lng": 7.5667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 16137,
    "id": 1276256169
  },
  {
    "city": "Bluffdale",
    "city_ascii": "Bluffdale",
    "lat": 40.4744,
    "lng": -111.9381,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Utah",
    "capital": "",
    "population": 16358,
    "id": 1840018745
  },
  {
    "city": "Dieburg",
    "city_ascii": "Dieburg",
    "lat": 49.8985,
    "lng": 8.8385,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 15679,
    "id": 1276337501
  },
  {
    "city": "Pécel",
    "city_ascii": "Pecel",
    "lat": 47.4911,
    "lng": 19.3419,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Pest",
    "capital": "",
    "population": 15987,
    "id": 1348260007
  },
  {
    "city": "Welby",
    "city_ascii": "Welby",
    "lat": 39.8403,
    "lng": -104.9655,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 16352,
    "id": 1840028449
  },
  {
    "city": "Orimattila",
    "city_ascii": "Orimattila",
    "lat": 60.8042,
    "lng": 25.7333,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Päijät-Häme",
    "capital": "minor",
    "population": 16326,
    "id": 1246385500
  },
  {
    "city": "Yorkton",
    "city_ascii": "Yorkton",
    "lat": 51.2139,
    "lng": -102.4628,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Saskatchewan",
    "capital": "",
    "population": 16343,
    "id": 1124108820
  },
  {
    "city": "Burley",
    "city_ascii": "Burley",
    "lat": 42.5379,
    "lng": -113.793,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Idaho",
    "capital": "",
    "population": 16342,
    "id": 1840018683
  },
  {
    "city": "Donna",
    "city_ascii": "Donna",
    "lat": 26.1467,
    "lng": -98.0559,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 16338,
    "id": 1840019734
  },
  {
    "city": "Aurora",
    "city_ascii": "Aurora",
    "lat": 41.3118,
    "lng": -81.345,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 16338,
    "id": 1840000806
  },
  {
    "city": "Ustroń",
    "city_ascii": "Ustron",
    "lat": 49.7167,
    "lng": 18.8167,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Śląskie",
    "capital": "",
    "population": 16065,
    "id": 1616758185
  },
  {
    "city": "Michelstadt",
    "city_ascii": "Michelstadt",
    "lat": 49.6786,
    "lng": 9.0042,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 16151,
    "id": 1276024719
  },
  {
    "city": "Straelen",
    "city_ascii": "Straelen",
    "lat": 51.45,
    "lng": 6.2667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 16114,
    "id": 1276805801
  },
  {
    "city": "Aranyaprathet",
    "city_ascii": "Aranyaprathet",
    "lat": 13.6928,
    "lng": 102.5017,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Sa Kaeo",
    "capital": "minor",
    "population": 16211,
    "id": 1764167237
  },
  {
    "city": "Abington",
    "city_ascii": "Abington",
    "lat": 42.118,
    "lng": -70.959,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 16330,
    "id": 1840053564
  },
  {
    "city": "Ostashkov",
    "city_ascii": "Ostashkov",
    "lat": 57.15,
    "lng": 33.1,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Tverskaya Oblast’",
    "capital": "",
    "population": 16318,
    "id": 1643804559
  },
  {
    "city": "Queensbury",
    "city_ascii": "Queensbury",
    "lat": 53.7683,
    "lng": -1.8453,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Calderdale",
    "capital": "",
    "population": 16273,
    "id": 1826557476
  },
  {
    "city": "Slaný",
    "city_ascii": "Slany",
    "lat": 50.2305,
    "lng": 14.087,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Středočeský Kraj",
    "capital": "",
    "population": 15864,
    "id": 1203367412
  },
  {
    "city": "Halver",
    "city_ascii": "Halver",
    "lat": 51.1834,
    "lng": 7.5027,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 16106,
    "id": 1276039349
  },
  {
    "city": "Walldorf",
    "city_ascii": "Walldorf",
    "lat": 49.3,
    "lng": 8.65,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 15534,
    "id": 1276002664
  },
  {
    "city": "Fallon",
    "city_ascii": "Fallon",
    "lat": 39.4738,
    "lng": -118.778,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Nevada",
    "capital": "",
    "population": 16313,
    "id": 1840020191
  },
  {
    "city": "Lyss",
    "city_ascii": "Lyss",
    "lat": 47.075,
    "lng": 7.3069,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Bern",
    "capital": "",
    "population": 15279,
    "id": 1756010328
  },
  {
    "city": "Chamgardān",
    "city_ascii": "Chamgardan",
    "lat": 32.3933,
    "lng": 51.3408,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Eşfahān",
    "capital": "",
    "population": 16086,
    "id": 1364112093
  },
  {
    "city": "Coalinga",
    "city_ascii": "Coalinga",
    "lat": 36.143,
    "lng": -120.3262,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 16307,
    "id": 1840018945
  },
  {
    "city": "Novoanninskiy",
    "city_ascii": "Novoanninskiy",
    "lat": 50.5167,
    "lng": 42.6667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Volgogradskaya Oblast’",
    "capital": "",
    "population": 16294,
    "id": 1643803707
  },
  {
    "city": "Fairview Heights",
    "city_ascii": "Fairview Heights",
    "lat": 38.5974,
    "lng": -90.0053,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 16303,
    "id": 1840008630
  },
  {
    "city": "Celina",
    "city_ascii": "Celina",
    "lat": 33.3188,
    "lng": -96.7865,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 16299,
    "id": 1840019395
  },
  {
    "city": "Gun Barrel City",
    "city_ascii": "Gun Barrel City",
    "lat": 32.3277,
    "lng": -96.1287,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 16292,
    "id": 1840020772
  },
  {
    "city": "Los Osos",
    "city_ascii": "Los Osos",
    "lat": 35.3068,
    "lng": -120.8249,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 16292,
    "id": 1840017805
  },
  {
    "city": "Thérmi",
    "city_ascii": "Thermi",
    "lat": 40.5472,
    "lng": 23.0197,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Kentrikí Makedonía",
    "capital": "minor",
    "population": 16004,
    "id": 1300794818
  },
  {
    "city": "Kabo",
    "city_ascii": "Kabo",
    "lat": 7.6994,
    "lng": 18.6292,
    "country": "Central African Republic",
    "iso2": "CF",
    "iso3": "CAF",
    "admin_name": "Ouham",
    "capital": "",
    "population": 16279,
    "id": 1140386370
  },
  {
    "city": "Nagasu",
    "city_ascii": "Nagasu",
    "lat": 32.9297,
    "lng": 130.4528,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Kumamoto",
    "capital": "",
    "population": 15487,
    "id": 1392244225
  },
  {
    "city": "San Martín",
    "city_ascii": "San Martin",
    "lat": 3.6904,
    "lng": -73.69,
    "country": "Colombia",
    "iso2": "CO",
    "iso3": "COL",
    "admin_name": "Meta",
    "capital": "minor",
    "population": 16273,
    "id": 1170677493
  },
  {
    "city": "Morton",
    "city_ascii": "Morton",
    "lat": 40.6136,
    "lng": -89.4669,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 16277,
    "id": 1840011991
  },
  {
    "city": "Port Jervis",
    "city_ascii": "Port Jervis",
    "lat": 41.3783,
    "lng": -74.6909,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 16273,
    "id": 1840000587
  },
  {
    "city": "Petrovsk-Zabaykal’skiy",
    "city_ascii": "Petrovsk-Zabaykal'skiy",
    "lat": 51.2833,
    "lng": 108.8333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Zabaykal’skiy Kray",
    "capital": "",
    "population": 16213,
    "id": 1643676561
  },
  {
    "city": "Kaukauna",
    "city_ascii": "Kaukauna",
    "lat": 44.2776,
    "lng": -88.2645,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 16270,
    "id": 1840002403
  },
  {
    "city": "San Antonio Oeste",
    "city_ascii": "San Antonio Oeste",
    "lat": -40.7333,
    "lng": -64.95,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Río Negro",
    "capital": "minor",
    "population": 16265,
    "id": 1032154473
  },
  {
    "city": "Mezőkövesd",
    "city_ascii": "Mezokovesd",
    "lat": 47.8067,
    "lng": 20.5647,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Borsod-Abaúj-Zemplén",
    "capital": "minor",
    "population": 16107,
    "id": 1348265393
  },
  {
    "city": "Adwick le Street",
    "city_ascii": "Adwick le Street",
    "lat": 53.5677,
    "lng": -1.1931,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Doncaster",
    "capital": "",
    "population": 15911,
    "id": 1826413996
  },
  {
    "city": "Addison",
    "city_ascii": "Addison",
    "lat": 32.959,
    "lng": -96.8355,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 16263,
    "id": 1840022057
  },
  {
    "city": "Fort Thomas",
    "city_ascii": "Fort Thomas",
    "lat": 39.0802,
    "lng": -84.4518,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kentucky",
    "capital": "",
    "population": 16263,
    "id": 1840013163
  },
  {
    "city": "Erwitte",
    "city_ascii": "Erwitte",
    "lat": 51.6167,
    "lng": 8.3497,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 16045,
    "id": 1276225862
  },
  {
    "city": "Mebane",
    "city_ascii": "Mebane",
    "lat": 36.0857,
    "lng": -79.2763,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 16262,
    "id": 1840015328
  },
  {
    "city": "Ishikida",
    "city_ascii": "Ishikida",
    "lat": 32.2989,
    "lng": 130.4931,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Kumamoto",
    "capital": "",
    "population": 16189,
    "id": 1392038092
  },
  {
    "city": "Mount Holly",
    "city_ascii": "Mount Holly",
    "lat": 35.3136,
    "lng": -81.0072,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 16257,
    "id": 1840014590
  },
  {
    "city": "Landen",
    "city_ascii": "Landen",
    "lat": 50.7547,
    "lng": 5.0814,
    "country": "Belgium",
    "iso2": "BE",
    "iso3": "BEL",
    "admin_name": "Flanders",
    "capital": "",
    "population": 15961,
    "id": 1056582232
  },
  {
    "city": "Conyers",
    "city_ascii": "Conyers",
    "lat": 33.6645,
    "lng": -83.9966,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 16256,
    "id": 1840014806
  },
  {
    "city": "Clausthal-Zellerfeld",
    "city_ascii": "Clausthal-Zellerfeld",
    "lat": 51.805,
    "lng": 10.3356,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 15888,
    "id": 1276565687
  },
  {
    "city": "Bruck an der Mur",
    "city_ascii": "Bruck an der Mur",
    "lat": 47.4106,
    "lng": 15.2686,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Steiermark",
    "capital": "minor",
    "population": 15837,
    "id": 1040863751
  },
  {
    "city": "Centerton",
    "city_ascii": "Centerton",
    "lat": 36.3567,
    "lng": -94.2971,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arkansas",
    "capital": "",
    "population": 16244,
    "id": 1840014441
  },
  {
    "city": "Sered’",
    "city_ascii": "Sered'",
    "lat": 48.2833,
    "lng": 17.7333,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Trnavský",
    "capital": "",
    "population": 15726,
    "id": 1703574353
  },
  {
    "city": "Spanish Springs",
    "city_ascii": "Spanish Springs",
    "lat": 39.6567,
    "lng": -119.6695,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Nevada",
    "capital": "",
    "population": 16241,
    "id": 1840033826
  },
  {
    "city": "Bad Wörishofen",
    "city_ascii": "Bad Worishofen",
    "lat": 48.0058,
    "lng": 10.5969,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 15963,
    "id": 1276068961
  },
  {
    "city": "Stuart",
    "city_ascii": "Stuart",
    "lat": 27.1958,
    "lng": -80.2438,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 16237,
    "id": 1840015990
  },
  {
    "city": "Sikeston",
    "city_ascii": "Sikeston",
    "lat": 36.8865,
    "lng": -89.587,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 16237,
    "id": 1840009921
  },
  {
    "city": "Parole",
    "city_ascii": "Parole",
    "lat": 38.9861,
    "lng": -76.5519,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 16232,
    "id": 1840005913
  },
  {
    "city": "Breaza",
    "city_ascii": "Breaza",
    "lat": 45.1872,
    "lng": 25.6622,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Prahova",
    "capital": "",
    "population": 15928,
    "id": 1642547235
  },
  {
    "city": "San Pedro de Lloc",
    "city_ascii": "San Pedro de Lloc",
    "lat": -7.4267,
    "lng": -79.5038,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "La Libertad",
    "capital": "",
    "population": 16198,
    "id": 1604981283
  },
  {
    "city": "Penn Township",
    "city_ascii": "Penn Township",
    "lat": 39.7994,
    "lng": -76.9642,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 16222,
    "id": 1840147116
  },
  {
    "city": "Pelhřimov",
    "city_ascii": "Pelhrimov",
    "lat": 49.4314,
    "lng": 15.2234,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Vysočina",
    "capital": "",
    "population": 16048,
    "id": 1203256095
  },
  {
    "city": "San Gabriel",
    "city_ascii": "San Gabriel",
    "lat": 0.61,
    "lng": -77.84,
    "country": "Ecuador",
    "iso2": "EC",
    "iso3": "ECU",
    "admin_name": "Carchi",
    "capital": "",
    "population": 16066,
    "id": 1218199820
  },
  {
    "city": "Centralia",
    "city_ascii": "Centralia",
    "lat": 38.5223,
    "lng": -89.1233,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 16204,
    "id": 1840007465
  },
  {
    "city": "Puerto Pimentel",
    "city_ascii": "Puerto Pimentel",
    "lat": -6.8382,
    "lng": -79.9359,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "Lambayeque",
    "capital": "",
    "population": 15552,
    "id": 1604924541
  },
  {
    "city": "East Longmeadow",
    "city_ascii": "East Longmeadow",
    "lat": 42.0597,
    "lng": -72.499,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 16200,
    "id": 1840053444
  },
  {
    "city": "Evans",
    "city_ascii": "Evans",
    "lat": 42.6529,
    "lng": -79.0063,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 16199,
    "id": 1840087420
  },
  {
    "city": "Pocking",
    "city_ascii": "Pocking",
    "lat": 48.3997,
    "lng": 13.3167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 15967,
    "id": 1276193940
  },
  {
    "city": "Altinópolis",
    "city_ascii": "Altinopolis",
    "lat": -21.0231,
    "lng": -47.3728,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 16179,
    "id": 1076728807
  },
  {
    "city": "Dixon",
    "city_ascii": "Dixon",
    "lat": 41.8439,
    "lng": -89.4794,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 16195,
    "id": 1840007051
  },
  {
    "city": "Horqueta",
    "city_ascii": "Horqueta",
    "lat": -23.3396,
    "lng": -57.05,
    "country": "Paraguay",
    "iso2": "PY",
    "iso3": "PRY",
    "admin_name": "Concepción",
    "capital": "",
    "population": 16171,
    "id": 1600363376
  },
  {
    "city": "Daigo",
    "city_ascii": "Daigo",
    "lat": 36.7667,
    "lng": 140.35,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Ibaraki",
    "capital": "",
    "population": 16142,
    "id": 1392539732
  },
  {
    "city": "North Whitehall",
    "city_ascii": "North Whitehall",
    "lat": 40.6797,
    "lng": -75.5788,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 16187,
    "id": 1840149202
  },
  {
    "city": "Bar",
    "city_ascii": "Bar",
    "lat": 49.0781,
    "lng": 27.6831,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Vinnyts’ka Oblast’",
    "capital": "minor",
    "population": 16136,
    "id": 1804657070
  },
  {
    "city": "Kumano",
    "city_ascii": "Kumano",
    "lat": 33.8833,
    "lng": 136.1,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Mie",
    "capital": "",
    "population": 16140,
    "id": 1392123261
  },
  {
    "city": "Troutdale",
    "city_ascii": "Troutdale",
    "lat": 45.5372,
    "lng": -122.3955,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 16183,
    "id": 1840021209
  },
  {
    "city": "Huarmey",
    "city_ascii": "Huarmey",
    "lat": -10.0686,
    "lng": -78.1603,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "Ancash",
    "capital": "",
    "population": 16172,
    "id": 1604582499
  },
  {
    "city": "Barrington",
    "city_ascii": "Barrington",
    "lat": 41.7443,
    "lng": -71.3145,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Rhode Island",
    "capital": "",
    "population": 16178,
    "id": 1840106239
  },
  {
    "city": "Rylsk",
    "city_ascii": "Rylsk",
    "lat": 51.5667,
    "lng": 34.6833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kurskaya Oblast’",
    "capital": "",
    "population": 16147,
    "id": 1643436531
  },
  {
    "city": "Conwy",
    "city_ascii": "Conwy",
    "lat": 53.28,
    "lng": -3.83,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Conwy",
    "capital": "",
    "population": 14723,
    "id": 1826011797
  },
  {
    "city": "Mount Clemens",
    "city_ascii": "Mount Clemens",
    "lat": 42.5976,
    "lng": -82.882,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 16163,
    "id": 1840003089
  },
  {
    "city": "Fairview Park",
    "city_ascii": "Fairview Park",
    "lat": 41.4419,
    "lng": -81.853,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 16161,
    "id": 1840000601
  },
  {
    "city": "Sukth",
    "city_ascii": "Sukth",
    "lat": 41.3833,
    "lng": 19.5333,
    "country": "Albania",
    "iso2": "AL",
    "iso3": "ALB",
    "admin_name": "Durrës",
    "capital": "",
    "population": 15966,
    "id": 1008647190
  },
  {
    "city": "Stallings",
    "city_ascii": "Stallings",
    "lat": 35.1088,
    "lng": -80.6597,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 16145,
    "id": 1840017878
  },
  {
    "city": "Cambridge",
    "city_ascii": "Cambridge",
    "lat": 45.5612,
    "lng": -93.2283,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 16143,
    "id": 1840006692
  },
  {
    "city": "Mezőtúr",
    "city_ascii": "Mezotur",
    "lat": 47.0042,
    "lng": 20.6181,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Jász-Nagykun-Szolnok",
    "capital": "minor",
    "population": 16086,
    "id": 1348445088
  },
  {
    "city": "Minster",
    "city_ascii": "Minster",
    "lat": 51.421,
    "lng": 0.809,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Kent",
    "capital": "",
    "population": 14789,
    "id": 1826548197
  },
  {
    "city": "Baléyara",
    "city_ascii": "Baleyara",
    "lat": 13.7841,
    "lng": 2.9515,
    "country": "Niger",
    "iso2": "NE",
    "iso3": "NER",
    "admin_name": "Tillabéri",
    "capital": "",
    "population": 16063,
    "id": 1562876747
  },
  {
    "city": "Taucha",
    "city_ascii": "Taucha",
    "lat": 51.38,
    "lng": 12.4936,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 15673,
    "id": 1276068319
  },
  {
    "city": "Lübbenau",
    "city_ascii": "Lubbenau",
    "lat": 51.8667,
    "lng": 13.9667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Brandenburg",
    "capital": "",
    "population": 16021,
    "id": 1276392829
  },
  {
    "city": "Frankfort",
    "city_ascii": "Frankfort",
    "lat": 40.281,
    "lng": -86.5213,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 16136,
    "id": 1840008358
  },
  {
    "city": "Ebersbach an der Fils",
    "city_ascii": "Ebersbach an der Fils",
    "lat": 48.7147,
    "lng": 9.5236,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 15535,
    "id": 1276554005
  },
  {
    "city": "Chickasha",
    "city_ascii": "Chickasha",
    "lat": 35.041,
    "lng": -97.9472,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oklahoma",
    "capital": "",
    "population": 16126,
    "id": 1840019169
  },
  {
    "city": "Csongrád",
    "city_ascii": "Csongrad",
    "lat": 46.7111,
    "lng": 20.1403,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Csongrád",
    "capital": "minor",
    "population": 16027,
    "id": 1348885069
  },
  {
    "city": "Westchester",
    "city_ascii": "Westchester",
    "lat": 41.8492,
    "lng": -87.8906,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 16117,
    "id": 1840011332
  },
  {
    "city": "Kodinsk",
    "city_ascii": "Kodinsk",
    "lat": 58.6,
    "lng": 99.1833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Krasnoyarskiy Kray",
    "capital": "",
    "population": 16116,
    "id": 1643232820
  },
  {
    "city": "Tracadie",
    "city_ascii": "Tracadie",
    "lat": 47.5124,
    "lng": -64.9101,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "New Brunswick",
    "capital": "",
    "population": 16114,
    "id": 1124362021
  },
  {
    "city": "Alsfeld",
    "city_ascii": "Alsfeld",
    "lat": 50.7511,
    "lng": 9.2711,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 15989,
    "id": 1276584676
  },
  {
    "city": "Whittlesey",
    "city_ascii": "Whittlesey",
    "lat": 52.558,
    "lng": -0.13,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Cambridgeshire",
    "capital": "",
    "population": 16058,
    "id": 1826174652
  },
  {
    "city": "Center Point",
    "city_ascii": "Center Point",
    "lat": 33.6446,
    "lng": -86.6851,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 16110,
    "id": 1840014789
  },
  {
    "city": "Canyon",
    "city_ascii": "Canyon",
    "lat": 34.9877,
    "lng": -101.9178,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 16109,
    "id": 1840019180
  },
  {
    "city": "West Haven",
    "city_ascii": "West Haven",
    "lat": 41.2082,
    "lng": -112.054,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Utah",
    "capital": "",
    "population": 16109,
    "id": 1840021344
  },
  {
    "city": "Washougal",
    "city_ascii": "Washougal",
    "lat": 45.5822,
    "lng": -122.3448,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 16107,
    "id": 1840021190
  },
  {
    "city": "Degtyarsk",
    "city_ascii": "Degtyarsk",
    "lat": 56.7,
    "lng": 60.1,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sverdlovskaya Oblast’",
    "capital": "",
    "population": 16001,
    "id": 1643565822
  },
  {
    "city": "Ilave",
    "city_ascii": "Ilave",
    "lat": -16.0866,
    "lng": -69.6354,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "Puno",
    "capital": "",
    "population": 16033,
    "id": 1604815838
  },
  {
    "city": "Bridgeview",
    "city_ascii": "Bridgeview",
    "lat": 41.7403,
    "lng": -87.8067,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 16096,
    "id": 1840010140
  },
  {
    "city": "Nyahanga",
    "city_ascii": "Nyahanga",
    "lat": -2.3829,
    "lng": 33.55,
    "country": "Tanzania",
    "iso2": "TZ",
    "iso3": "TZA",
    "admin_name": "Simiyu",
    "capital": "",
    "population": 16092,
    "id": 1834609339
  },
  {
    "city": "Troy",
    "city_ascii": "Troy",
    "lat": 38.9708,
    "lng": -90.9715,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 16092,
    "id": 1840010689
  },
  {
    "city": "Horodok",
    "city_ascii": "Horodok",
    "lat": 49.7822,
    "lng": 23.6436,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "L’vivs’ka Oblast’",
    "capital": "minor",
    "population": 15993,
    "id": 1804513657
  },
  {
    "city": "Middletown",
    "city_ascii": "Middletown",
    "lat": 41.5174,
    "lng": -71.2772,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Rhode Island",
    "capital": "",
    "population": 16078,
    "id": 1840066121
  },
  {
    "city": "Kasagi",
    "city_ascii": "Kasagi",
    "lat": 34.2964,
    "lng": 135.5042,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Wakayama",
    "capital": "",
    "population": 15961,
    "id": 1392507322
  },
  {
    "city": "Stäfa",
    "city_ascii": "Stafa",
    "lat": 47.2401,
    "lng": 8.7328,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Zürich",
    "capital": "",
    "population": 14389,
    "id": 1756312480
  },
  {
    "city": "Griffith",
    "city_ascii": "Griffith",
    "lat": 41.5279,
    "lng": -87.424,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 16060,
    "id": 1840010225
  },
  {
    "city": "Villa Rica",
    "city_ascii": "Villa Rica",
    "lat": 33.7305,
    "lng": -84.917,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 16058,
    "id": 1840015679
  },
  {
    "city": "Schlüchtern",
    "city_ascii": "Schluchtern",
    "lat": 50.35,
    "lng": 9.5167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 15914,
    "id": 1276713332
  },
  {
    "city": "Bassum",
    "city_ascii": "Bassum",
    "lat": 52.8494,
    "lng": 8.7267,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 15955,
    "id": 1276562113
  },
  {
    "city": "Three Lakes",
    "city_ascii": "Three Lakes",
    "lat": 25.6415,
    "lng": -80.4,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 16043,
    "id": 1840029010
  },
  {
    "city": "Ellington",
    "city_ascii": "Ellington",
    "lat": 41.9151,
    "lng": -72.4485,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 16041,
    "id": 1840004744
  },
  {
    "city": "Weener",
    "city_ascii": "Weener",
    "lat": 53.1692,
    "lng": 7.3564,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 15842,
    "id": 1276425095
  },
  {
    "city": "Alma",
    "city_ascii": "Alma",
    "lat": 43.38,
    "lng": -84.6556,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 16035,
    "id": 1840002879
  },
  {
    "city": "Bragadiru",
    "city_ascii": "Bragadiru",
    "lat": 44.3694,
    "lng": 25.9753,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Ilfov",
    "capital": "",
    "population": 15329,
    "id": 1642514395
  },
  {
    "city": "Fairhaven",
    "city_ascii": "Fairhaven",
    "lat": 41.6395,
    "lng": -70.8732,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 16026,
    "id": 1840053521
  },
  {
    "city": "Chislehurst",
    "city_ascii": "Chislehurst",
    "lat": 51.412,
    "lng": 0.075,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Bromley",
    "capital": "",
    "population": 14831,
    "id": 1826578404
  },
  {
    "city": "Kasli",
    "city_ascii": "Kasli",
    "lat": 55.8833,
    "lng": 60.75,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Chelyabinskaya Oblast’",
    "capital": "",
    "population": 16013,
    "id": 1643483987
  },
  {
    "city": "Shizukuishi",
    "city_ascii": "Shizukuishi",
    "lat": 39.6942,
    "lng": 140.9844,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Iwate",
    "capital": "",
    "population": 15998,
    "id": 1392537608
  },
  {
    "city": "Long Beach",
    "city_ascii": "Long Beach",
    "lat": 30.3608,
    "lng": -89.165,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Mississippi",
    "capital": "",
    "population": 16023,
    "id": 1840015914
  },
  {
    "city": "Sierra Vista Southeast",
    "city_ascii": "Sierra Vista Southeast",
    "lat": 31.4525,
    "lng": -110.216,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 16021,
    "id": 1840074401
  },
  {
    "city": "Shchigry",
    "city_ascii": "Shchigry",
    "lat": 51.8811,
    "lng": 36.9031,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kurskaya Oblast’",
    "capital": "",
    "population": 15292,
    "id": 1643401048
  },
  {
    "city": "Lady Lake",
    "city_ascii": "Lady Lake",
    "lat": 28.9241,
    "lng": -81.9299,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 16020,
    "id": 1840017229
  },
  {
    "city": "Todmorden",
    "city_ascii": "Todmorden",
    "lat": 53.713,
    "lng": -2.096,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Calderdale",
    "capital": "",
    "population": 15481,
    "id": 1826615743
  },
  {
    "city": "Aberdeen",
    "city_ascii": "Aberdeen",
    "lat": 39.5146,
    "lng": -76.173,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 16019,
    "id": 1840005666
  },
  {
    "city": "Washington",
    "city_ascii": "Washington",
    "lat": 35.5587,
    "lng": -77.0545,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 16014,
    "id": 1840015401
  },
  {
    "city": "Ban Dung",
    "city_ascii": "Ban Dung",
    "lat": 17.6986,
    "lng": 103.2613,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Udon Thani",
    "capital": "minor",
    "population": 15913,
    "id": 1764905558
  },
  {
    "city": "Bracebridge",
    "city_ascii": "Bracebridge",
    "lat": 45.0333,
    "lng": -79.3,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 16010,
    "id": 1124793645
  },
  {
    "city": "Sayō",
    "city_ascii": "Sayo",
    "lat": 35.0042,
    "lng": 134.3558,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hyōgo",
    "capital": "",
    "population": 15958,
    "id": 1392577247
  },
  {
    "city": "Seven Pagodas",
    "city_ascii": "Seven Pagodas",
    "lat": 12.6227,
    "lng": 80.1939,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Tamil Nādu",
    "capital": "",
    "population": 15172,
    "id": 1356071916
  },
  {
    "city": "Sayville",
    "city_ascii": "Sayville",
    "lat": 40.7478,
    "lng": -73.084,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 16007,
    "id": 1840005092
  },
  {
    "city": "Kirksville",
    "city_ascii": "Kirksville",
    "lat": 40.1986,
    "lng": -92.5753,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 16002,
    "id": 1840008372
  },
  {
    "city": "Kamyzyak",
    "city_ascii": "Kamyzyak",
    "lat": 46.1167,
    "lng": 48.0833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Astrakhanskaya Oblast’",
    "capital": "",
    "population": 15984,
    "id": 1643101212
  },
  {
    "city": "Fartura",
    "city_ascii": "Fartura",
    "lat": -23.3883,
    "lng": -49.51,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 15960,
    "id": 1076486558
  },
  {
    "city": "Yaransk",
    "city_ascii": "Yaransk",
    "lat": 57.3033,
    "lng": 47.8688,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kirovskaya Oblast’",
    "capital": "",
    "population": 15985,
    "id": 1643107810
  },
  {
    "city": "Barysh",
    "city_ascii": "Barysh",
    "lat": 53.65,
    "lng": 47.1167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Ul’yanovskaya Oblast’",
    "capital": "",
    "population": 15974,
    "id": 1643221892
  },
  {
    "city": "Bastogne",
    "city_ascii": "Bastogne",
    "lat": 50.0042,
    "lng": 5.72,
    "country": "Belgium",
    "iso2": "BE",
    "iso3": "BEL",
    "admin_name": "Wallonia",
    "capital": "minor",
    "population": 15894,
    "id": 1056747497
  },
  {
    "city": "Dvůr Králové nad Labem",
    "city_ascii": "Dvur Kralove nad Labem",
    "lat": 50.4318,
    "lng": 15.8141,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Královéhradecký Kraj",
    "capital": "",
    "population": 15550,
    "id": 1203492959
  },
  {
    "city": "Lakeway",
    "city_ascii": "Lakeway",
    "lat": 30.3547,
    "lng": -97.9854,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 15981,
    "id": 1840020894
  },
  {
    "city": "Discovery Bay",
    "city_ascii": "Discovery Bay",
    "lat": 37.9071,
    "lng": -121.6076,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 15981,
    "id": 1840018897
  },
  {
    "city": "Knaresborough",
    "city_ascii": "Knaresborough",
    "lat": 54.0084,
    "lng": -1.467,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "North Yorkshire",
    "capital": "",
    "population": 15441,
    "id": 1826000526
  },
  {
    "city": "Dyer",
    "city_ascii": "Dyer",
    "lat": 41.4976,
    "lng": -87.509,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 15976,
    "id": 1840010224
  },
  {
    "city": "Niceville",
    "city_ascii": "Niceville",
    "lat": 30.5291,
    "lng": -86.4754,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 15972,
    "id": 1840015009
  },
  {
    "city": "Archer Lodge",
    "city_ascii": "Archer Lodge",
    "lat": 35.6907,
    "lng": -78.3749,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 15972,
    "id": 1840025797
  },
  {
    "city": "Bocşa",
    "city_ascii": "Bocsa",
    "lat": 45.3747,
    "lng": 21.7106,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Caraş-Severin",
    "capital": "",
    "population": 15842,
    "id": 1642424364
  },
  {
    "city": "Calbuco",
    "city_ascii": "Calbuco",
    "lat": -41.7712,
    "lng": -73.1275,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Los Lagos",
    "capital": "",
    "population": 15903,
    "id": 1152875669
  },
  {
    "city": "Füssen",
    "city_ascii": "Fussen",
    "lat": 47.5667,
    "lng": 10.7,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 15608,
    "id": 1276059513
  },
  {
    "city": "Clearlake",
    "city_ascii": "Clearlake",
    "lat": 38.959,
    "lng": -122.633,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 15961,
    "id": 1840028139
  },
  {
    "city": "Thundersley",
    "city_ascii": "Thundersley",
    "lat": 51.57,
    "lng": 0.59,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Essex",
    "capital": "",
    "population": 15600,
    "id": 1826836917
  },
  {
    "city": "Batalha",
    "city_ascii": "Batalha",
    "lat": 39.65,
    "lng": -8.8167,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Leiria",
    "capital": "minor",
    "population": 15805,
    "id": 1620446432
  },
  {
    "city": "Fillmore",
    "city_ascii": "Fillmore",
    "lat": 34.3989,
    "lng": -118.9181,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 15954,
    "id": 1840020471
  },
  {
    "city": "Yorito",
    "city_ascii": "Yorito",
    "lat": 15.06,
    "lng": -87.29,
    "country": "Honduras",
    "iso2": "HN",
    "iso3": "HND",
    "admin_name": "Yoro",
    "capital": "",
    "population": 15774,
    "id": 1340550354
  },
  {
    "city": "Talitsa",
    "city_ascii": "Talitsa",
    "lat": 57.0125,
    "lng": 63.7292,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sverdlovskaya Oblast’",
    "capital": "",
    "population": 15941,
    "id": 1643272531
  },
  {
    "city": "Hood River",
    "city_ascii": "Hood River",
    "lat": 45.7092,
    "lng": -121.5258,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 15949,
    "id": 1840019942
  },
  {
    "city": "Middletown Township",
    "city_ascii": "Middletown Township",
    "lat": 39.9094,
    "lng": -75.4312,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 15947,
    "id": 1840152839
  },
  {
    "city": "Plavsk",
    "city_ascii": "Plavsk",
    "lat": 53.7,
    "lng": 37.3,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Tul’skaya Oblast’",
    "capital": "",
    "population": 15920,
    "id": 1643003936
  },
  {
    "city": "Bellevue",
    "city_ascii": "Bellevue",
    "lat": 44.4593,
    "lng": -87.9554,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 15944,
    "id": 1840000308
  },
  {
    "city": "Tachiarai",
    "city_ascii": "Tachiarai",
    "lat": 33.3725,
    "lng": 130.6225,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Fukuoka",
    "capital": "",
    "population": 15274,
    "id": 1392006046
  },
  {
    "city": "Tōbetsu",
    "city_ascii": "Tobetsu",
    "lat": 43.2239,
    "lng": 141.5172,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hokkaidō",
    "capital": "",
    "population": 15895,
    "id": 1392723776
  },
  {
    "city": "Bikin",
    "city_ascii": "Bikin",
    "lat": 46.8167,
    "lng": 134.25,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Khabarovskiy Kray",
    "capital": "",
    "population": 15928,
    "id": 1643608189
  },
  {
    "city": "Aramil",
    "city_ascii": "Aramil",
    "lat": 56.7,
    "lng": 60.8333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sverdlovskaya Oblast’",
    "capital": "",
    "population": 15236,
    "id": 1643755465
  },
  {
    "city": "Port Glasgow",
    "city_ascii": "Port Glasgow",
    "lat": 55.934,
    "lng": -4.6906,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Inverclyde",
    "capital": "",
    "population": 15414,
    "id": 1826330462
  },
  {
    "city": "Gainesville",
    "city_ascii": "Gainesville",
    "lat": 38.7931,
    "lng": -77.6347,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 15928,
    "id": 1840006098
  },
  {
    "city": "Colchester",
    "city_ascii": "Colchester",
    "lat": 41.5621,
    "lng": -72.3475,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 15927,
    "id": 1840034365
  },
  {
    "city": "Comarapa",
    "city_ascii": "Comarapa",
    "lat": -17.9136,
    "lng": -64.5297,
    "country": "Bolivia",
    "iso2": "BO",
    "iso3": "BOL",
    "admin_name": "Santa Cruz",
    "capital": "",
    "population": 15919,
    "id": 1068701913
  },
  {
    "city": "Tabatinga",
    "city_ascii": "Tabatinga",
    "lat": -21.7169,
    "lng": -48.6878,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 15881,
    "id": 1076705867
  },
  {
    "city": "New Haven",
    "city_ascii": "New Haven",
    "lat": 41.0675,
    "lng": -85.0175,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 15922,
    "id": 1840008262
  },
  {
    "city": "Eilenburg",
    "city_ascii": "Eilenburg",
    "lat": 51.4608,
    "lng": 12.6358,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 15583,
    "id": 1276166351
  },
  {
    "city": "Sertã",
    "city_ascii": "Serta",
    "lat": 39.8008,
    "lng": -8.1003,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Castelo Branco",
    "capital": "minor",
    "population": 15880,
    "id": 1620976292
  },
  {
    "city": "Tocantins",
    "city_ascii": "Tocantins",
    "lat": -21.175,
    "lng": -43.0178,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Minas Gerais",
    "capital": "",
    "population": 15823,
    "id": 1076558658
  },
  {
    "city": "Monte Plata",
    "city_ascii": "Monte Plata",
    "lat": 18.807,
    "lng": -69.784,
    "country": "Dominican Republic",
    "iso2": "DO",
    "iso3": "DOM",
    "admin_name": "Higuamo",
    "capital": "minor",
    "population": 15532,
    "id": 1214856228
  },
  {
    "city": "Cardonal",
    "city_ascii": "Cardonal",
    "lat": 20.6167,
    "lng": -99.1167,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Hidalgo",
    "capital": "",
    "population": 15876,
    "id": 1484936605
  },
  {
    "city": "Patos",
    "city_ascii": "Patos",
    "lat": 40.6841,
    "lng": 19.6194,
    "country": "Albania",
    "iso2": "AL",
    "iso3": "ALB",
    "admin_name": "Fier",
    "capital": "",
    "population": 15397,
    "id": 1008184635
  },
  {
    "city": "Patton",
    "city_ascii": "Patton",
    "lat": 40.8258,
    "lng": -77.9237,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 15905,
    "id": 1840144238
  },
  {
    "city": "Warwick",
    "city_ascii": "Warwick",
    "lat": -28.2152,
    "lng": 152.0352,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Queensland",
    "capital": "",
    "population": 15380,
    "id": 1036412726
  },
  {
    "city": "Lewiston",
    "city_ascii": "Lewiston",
    "lat": 43.1793,
    "lng": -78.9709,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 15897,
    "id": 1840004272
  },
  {
    "city": "Millbrook",
    "city_ascii": "Millbrook",
    "lat": 32.5028,
    "lng": -86.3737,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 15896,
    "id": 1840014877
  },
  {
    "city": "Carthage",
    "city_ascii": "Carthage",
    "lat": 37.1503,
    "lng": -94.3225,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 15892,
    "id": 1840007629
  },
  {
    "city": "Greater Napanee",
    "city_ascii": "Greater Napanee",
    "lat": 44.25,
    "lng": -76.95,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 15892,
    "id": 1124001319
  },
  {
    "city": "Breisach am Rhein",
    "city_ascii": "Breisach am Rhein",
    "lat": 48.0289,
    "lng": 7.58,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 15606,
    "id": 1276880866
  },
  {
    "city": "Skvyra",
    "city_ascii": "Skvyra",
    "lat": 49.7333,
    "lng": 29.6833,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Kyyivs’ka Oblast’",
    "capital": "minor",
    "population": 15889,
    "id": 1804833632
  },
  {
    "city": "Nymburk",
    "city_ascii": "Nymburk",
    "lat": 50.1861,
    "lng": 15.0417,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Středočeský Kraj",
    "capital": "",
    "population": 15154,
    "id": 1203273272
  },
  {
    "city": "Ivdel",
    "city_ascii": "Ivdel",
    "lat": 60.6833,
    "lng": 60.4333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sverdlovskaya Oblast’",
    "capital": "",
    "population": 15888,
    "id": 1643246085
  },
  {
    "city": "Puquio",
    "city_ascii": "Puquio",
    "lat": -14.6939,
    "lng": -74.1241,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "Ayacucho",
    "capital": "",
    "population": 15870,
    "id": 1604629997
  },
  {
    "city": "Opa-locka",
    "city_ascii": "Opa-locka",
    "lat": 25.8997,
    "lng": -80.2551,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 15887,
    "id": 1840015156
  },
  {
    "city": "King City",
    "city_ascii": "King City",
    "lat": 36.2166,
    "lng": -121.133,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 15887,
    "id": 1840020360
  },
  {
    "city": "Prospect Heights",
    "city_ascii": "Prospect Heights",
    "lat": 42.1039,
    "lng": -87.9267,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 15887,
    "id": 1840009184
  },
  {
    "city": "Markranstädt",
    "city_ascii": "Markranstadt",
    "lat": 51.3017,
    "lng": 12.2211,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 15619,
    "id": 1276832529
  },
  {
    "city": "Pataskala",
    "city_ascii": "Pataskala",
    "lat": 40.011,
    "lng": -82.7155,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 15883,
    "id": 1840001420
  },
  {
    "city": "Fort Leonard Wood",
    "city_ascii": "Fort Leonard Wood",
    "lat": 37.7562,
    "lng": -92.1274,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 15882,
    "id": 1840073756
  },
  {
    "city": "Katav-Ivanovsk",
    "city_ascii": "Katav-Ivanovsk",
    "lat": 54.75,
    "lng": 58.2,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Chelyabinskaya Oblast’",
    "capital": "",
    "population": 15872,
    "id": 1643848436
  },
  {
    "city": "Readington",
    "city_ascii": "Readington",
    "lat": 40.5822,
    "lng": -74.7796,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 15880,
    "id": 1840081708
  },
  {
    "city": "White Township",
    "city_ascii": "White Township",
    "lat": 40.621,
    "lng": -79.1513,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 15875,
    "id": 1840151488
  },
  {
    "city": "Tillsonburg",
    "city_ascii": "Tillsonburg",
    "lat": 42.8667,
    "lng": -80.7333,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 15872,
    "id": 1124817746
  },
  {
    "city": "Nikel",
    "city_ascii": "Nikel",
    "lat": 69.4126,
    "lng": 30.2188,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Murmanskaya Oblast’",
    "capital": "",
    "population": 15866,
    "id": 1643124017
  },
  {
    "city": "Wadern",
    "city_ascii": "Wadern",
    "lat": 49.5394,
    "lng": 6.89,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saarland",
    "capital": "",
    "population": 15727,
    "id": 1276516463
  },
  {
    "city": "Tenango de Doria",
    "city_ascii": "Tenango de Doria",
    "lat": 20.3356,
    "lng": -98.2267,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Hidalgo",
    "capital": "minor",
    "population": 15793,
    "id": 1484595420
  },
  {
    "city": "Wakuya",
    "city_ascii": "Wakuya",
    "lat": 38.5447,
    "lng": 141.1347,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Miyagi",
    "capital": "",
    "population": 15677,
    "id": 1392967427
  },
  {
    "city": "Ince-in-Makerfield",
    "city_ascii": "Ince-in-Makerfield",
    "lat": 53.5402,
    "lng": -2.599,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Wigan",
    "capital": "",
    "population": 15664,
    "id": 1826328021
  },
  {
    "city": "Westwood",
    "city_ascii": "Westwood",
    "lat": 42.2202,
    "lng": -71.2106,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 15863,
    "id": 1840053561
  },
  {
    "city": "Tanque Verde",
    "city_ascii": "Tanque Verde",
    "lat": 32.2687,
    "lng": -110.7437,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 15860,
    "id": 1840019489
  },
  {
    "city": "Neustadt in Holstein",
    "city_ascii": "Neustadt in Holstein",
    "lat": 54.1072,
    "lng": 10.8158,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Schleswig-Holstein",
    "capital": "",
    "population": 15093,
    "id": 1276780766
  },
  {
    "city": "Grandville",
    "city_ascii": "Grandville",
    "lat": 42.9003,
    "lng": -85.7564,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 15858,
    "id": 1840002929
  },
  {
    "city": "Highland Springs",
    "city_ascii": "Highland Springs",
    "lat": 37.5516,
    "lng": -77.3285,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 15855,
    "id": 1840006391
  },
  {
    "city": "Westport",
    "city_ascii": "Westport",
    "lat": 41.5886,
    "lng": -71.0837,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 15854,
    "id": 1840070239
  },
  {
    "city": "Laguna Woods",
    "city_ascii": "Laguna Woods",
    "lat": 33.6098,
    "lng": -117.7299,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 15850,
    "id": 1840020590
  },
  {
    "city": "Derzhavīnsk",
    "city_ascii": "Derzhavinsk",
    "lat": 51.1021,
    "lng": 66.3075,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Aqmola",
    "capital": "",
    "population": 15848,
    "id": 1398070336
  },
  {
    "city": "Hope Mills",
    "city_ascii": "Hope Mills",
    "lat": 34.969,
    "lng": -78.9559,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 15849,
    "id": 1840016438
  },
  {
    "city": "West Lampeter",
    "city_ascii": "West Lampeter",
    "lat": 39.9947,
    "lng": -76.256,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 15849,
    "id": 1840152738
  },
  {
    "city": "Vinnichki",
    "city_ascii": "Vinnichki",
    "lat": 49.8156,
    "lng": 24.1297,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "L’vivs’ka Oblast’",
    "capital": "",
    "population": 15723,
    "id": 1804573396
  },
  {
    "city": "Templin",
    "city_ascii": "Templin",
    "lat": 53.1167,
    "lng": 13.5,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Brandenburg",
    "capital": "",
    "population": 15798,
    "id": 1276089341
  },
  {
    "city": "Murraysville",
    "city_ascii": "Murraysville",
    "lat": 34.2919,
    "lng": -77.8429,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 15831,
    "id": 1840013631
  },
  {
    "city": "Kalininsk",
    "city_ascii": "Kalininsk",
    "lat": 51.5,
    "lng": 44.45,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Saratovskaya Oblast’",
    "capital": "",
    "population": 15821,
    "id": 1643016449
  },
  {
    "city": "Longmeadow",
    "city_ascii": "Longmeadow",
    "lat": 42.0475,
    "lng": -72.5718,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 15830,
    "id": 1840053448
  },
  {
    "city": "Easthampton",
    "city_ascii": "Easthampton",
    "lat": 42.2652,
    "lng": -72.672,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 15829,
    "id": 1840031156
  },
  {
    "city": "Steinbach",
    "city_ascii": "Steinbach",
    "lat": 49.5258,
    "lng": -96.6839,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Manitoba",
    "capital": "",
    "population": 15829,
    "id": 1124152692
  },
  {
    "city": "Bad Neustadt",
    "city_ascii": "Bad Neustadt",
    "lat": 50.3219,
    "lng": 10.2161,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "minor",
    "population": 15411,
    "id": 1276106311
  },
  {
    "city": "Naivasha",
    "city_ascii": "Naivasha",
    "lat": -0.7167,
    "lng": 36.4359,
    "country": "Kenya",
    "iso2": "KE",
    "iso3": "KEN",
    "admin_name": "Nakuru",
    "capital": "",
    "population": 14563,
    "id": 1404904927
  },
  {
    "city": "Upper Gwynedd",
    "city_ascii": "Upper Gwynedd",
    "lat": 40.2143,
    "lng": -75.289,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 15826,
    "id": 1840152390
  },
  {
    "city": "Humble",
    "city_ascii": "Humble",
    "lat": 29.9921,
    "lng": -95.2655,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 15824,
    "id": 1840020926
  },
  {
    "city": "Mamadysh",
    "city_ascii": "Mamadysh",
    "lat": 55.7,
    "lng": 51.4,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Tatarstan",
    "capital": "",
    "population": 15806,
    "id": 1643004821
  },
  {
    "city": "Königslutter am Elm",
    "city_ascii": "Konigslutter am Elm",
    "lat": 52.25,
    "lng": 10.8167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 15704,
    "id": 1276192101
  },
  {
    "city": "Echuca",
    "city_ascii": "Echuca",
    "lat": -36.1333,
    "lng": 144.75,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Victoria",
    "capital": "",
    "population": 14934,
    "id": 1036292989
  },
  {
    "city": "Aldine",
    "city_ascii": "Aldine",
    "lat": 29.9122,
    "lng": -95.3785,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 15822,
    "id": 1840018250
  },
  {
    "city": "Tiverton",
    "city_ascii": "Tiverton",
    "lat": 41.6091,
    "lng": -71.1741,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Rhode Island",
    "capital": "",
    "population": 15816,
    "id": 1840066125
  },
  {
    "city": "Ovruch",
    "city_ascii": "Ovruch",
    "lat": 51.3244,
    "lng": 28.8081,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Zhytomyrs’ka Oblast’",
    "capital": "minor",
    "population": 15795,
    "id": 1804670823
  },
  {
    "city": "Carterton",
    "city_ascii": "Carterton",
    "lat": 51.76,
    "lng": -1.59,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Oxfordshire",
    "capital": "",
    "population": 15769,
    "id": 1826750065
  },
  {
    "city": "Bredasdorp",
    "city_ascii": "Bredasdorp",
    "lat": -34.5333,
    "lng": 20.0417,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "Western Cape",
    "capital": "",
    "population": 14936,
    "id": 1710282172
  },
  {
    "city": "New River",
    "city_ascii": "New River",
    "lat": 33.8835,
    "lng": -112.0858,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 15808,
    "id": 1840019307
  },
  {
    "city": "Yasnyy",
    "city_ascii": "Yasnyy",
    "lat": 51.0333,
    "lng": 59.8667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Orenburgskaya Oblast’",
    "capital": "",
    "population": 15573,
    "id": 1643810405
  },
  {
    "city": "Putyvl’",
    "city_ascii": "Putyvl'",
    "lat": 51.3347,
    "lng": 33.8686,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Sums’ka Oblast’",
    "capital": "minor",
    "population": 15778,
    "id": 1804280760
  },
  {
    "city": "Shahr-e Jadīd-e Hashtgerd",
    "city_ascii": "Shahr-e Jadid-e Hashtgerd",
    "lat": 35.9825,
    "lng": 50.7353,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Alborz",
    "capital": "",
    "population": 15619,
    "id": 1364951130
  },
  {
    "city": "Macedo de Cavaleiros",
    "city_ascii": "Macedo de Cavaleiros",
    "lat": 41.5333,
    "lng": -6.95,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Bragança",
    "capital": "minor",
    "population": 15776,
    "id": 1620590215
  },
  {
    "city": "Człuchów",
    "city_ascii": "Czluchow",
    "lat": 53.65,
    "lng": 17.3667,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Pomorskie",
    "capital": "minor",
    "population": 14625,
    "id": 1616601877
  },
  {
    "city": "Kawasaki",
    "city_ascii": "Kawasaki",
    "lat": 33.6,
    "lng": 130.8153,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Fukuoka",
    "capital": "",
    "population": 15370,
    "id": 1392730705
  },
  {
    "city": "Circleville",
    "city_ascii": "Circleville",
    "lat": 39.6063,
    "lng": -82.9334,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 15792,
    "id": 1840007317
  },
  {
    "city": "Ulladulla",
    "city_ascii": "Ulladulla",
    "lat": -35.3486,
    "lng": 150.4678,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 15278,
    "id": 1036687220
  },
  {
    "city": "Plochingen",
    "city_ascii": "Plochingen",
    "lat": 48.7117,
    "lng": 9.4164,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 14433,
    "id": 1276921571
  },
  {
    "city": "Bengonbeyene",
    "city_ascii": "Bengonbeyene",
    "lat": 1.6931,
    "lng": 11.0342,
    "country": "Equatorial Guinea",
    "iso2": "GQ",
    "iso3": "GNQ",
    "admin_name": "Wele-Nzas",
    "capital": "",
    "population": 15644,
    "id": 1226996248
  },
  {
    "city": "Juma Shahri",
    "city_ascii": "Juma Shahri",
    "lat": 39.7094,
    "lng": 66.6614,
    "country": "Uzbekistan",
    "iso2": "UZ",
    "iso3": "UZB",
    "admin_name": "Samarqand",
    "capital": "minor",
    "population": 15571,
    "id": 1860861355
  },
  {
    "city": "Warren",
    "city_ascii": "Warren",
    "lat": 40.6323,
    "lng": -74.5146,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 15783,
    "id": 1840081703
  },
  {
    "city": "Totteridge",
    "city_ascii": "Totteridge",
    "lat": 51.6354,
    "lng": -0.2,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Barnet",
    "capital": "",
    "population": 15159,
    "id": 1826812709
  },
  {
    "city": "Kalocsa",
    "city_ascii": "Kalocsa",
    "lat": 46.5333,
    "lng": 18.9856,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Bács-Kiskun",
    "capital": "minor",
    "population": 15490,
    "id": 1348222203
  },
  {
    "city": "Sarasota Springs",
    "city_ascii": "Sarasota Springs",
    "lat": 27.3092,
    "lng": -82.4788,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 15781,
    "id": 1840029072
  },
  {
    "city": "Kempsey",
    "city_ascii": "Kempsey",
    "lat": -31.0833,
    "lng": 152.8333,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 15309,
    "id": 1036897003
  },
  {
    "city": "Sterling",
    "city_ascii": "Sterling",
    "lat": 40.6206,
    "lng": -103.1925,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 15780,
    "id": 1840021377
  },
  {
    "city": "Daisen",
    "city_ascii": "Daisen",
    "lat": 35.5108,
    "lng": 133.4961,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Tottori",
    "capital": "",
    "population": 15690,
    "id": 1392889589
  },
  {
    "city": "Varnsdorf",
    "city_ascii": "Varnsdorf",
    "lat": 50.9116,
    "lng": 14.6184,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Liberecký Kraj",
    "capital": "",
    "population": 15193,
    "id": 1203159316
  },
  {
    "city": "Sunset",
    "city_ascii": "Sunset",
    "lat": 25.7061,
    "lng": -80.353,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 15770,
    "id": 1840029084
  },
  {
    "city": "Česká Třebová",
    "city_ascii": "Ceska Trebova",
    "lat": 49.9019,
    "lng": 16.4473,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Pardubický Kraj",
    "capital": "",
    "population": 15384,
    "id": 1203262802
  },
  {
    "city": "Powder Springs",
    "city_ascii": "Powder Springs",
    "lat": 33.8659,
    "lng": -84.6838,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 15758,
    "id": 1840014755
  },
  {
    "city": "Ershui",
    "city_ascii": "Ershui",
    "lat": 23.8167,
    "lng": 120.6167,
    "country": "Taiwan",
    "iso2": "TW",
    "iso3": "TWN",
    "admin_name": "Changhua",
    "capital": "",
    "population": 15240,
    "id": 1158555502
  },
  {
    "city": "Middle Smithfield",
    "city_ascii": "Middle Smithfield",
    "lat": 41.0918,
    "lng": -75.1031,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 15753,
    "id": 1840150869
  },
  {
    "city": "Purcellville",
    "city_ascii": "Purcellville",
    "lat": 39.1378,
    "lng": -77.7109,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 15742,
    "id": 1840005875
  },
  {
    "city": "Si Satchanalai",
    "city_ascii": "Si Satchanalai",
    "lat": 17.4155,
    "lng": 99.8152,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Sukhothai",
    "capital": "minor",
    "population": 15691,
    "id": 1764174789
  },
  {
    "city": "Greenwood Village",
    "city_ascii": "Greenwood Village",
    "lat": 39.6152,
    "lng": -104.913,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 15735,
    "id": 1840020212
  },
  {
    "city": "Hanover",
    "city_ascii": "Hanover",
    "lat": 49.4433,
    "lng": -96.8492,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Manitoba",
    "capital": "",
    "population": 15733,
    "id": 1124001704
  },
  {
    "city": "Spittal an der Drau",
    "city_ascii": "Spittal an der Drau",
    "lat": 46.7917,
    "lng": 13.4958,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Kärnten",
    "capital": "minor",
    "population": 15413,
    "id": 1040131621
  },
  {
    "city": "Parma",
    "city_ascii": "Parma",
    "lat": 43.2651,
    "lng": -77.7968,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 15727,
    "id": 1840087782
  },
  {
    "city": "Ulverstone",
    "city_ascii": "Ulverstone",
    "lat": -41.1667,
    "lng": 146.1667,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Tasmania",
    "capital": "",
    "population": 14109,
    "id": 1036014544
  },
  {
    "city": "Terrace",
    "city_ascii": "Terrace",
    "lat": 54.5164,
    "lng": -128.5997,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "British Columbia",
    "capital": "",
    "population": 15723,
    "id": 1124878479
  },
  {
    "city": "Yasnogorsk",
    "city_ascii": "Yasnogorsk",
    "lat": 54.4794,
    "lng": 37.6934,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Tul’skaya Oblast’",
    "capital": "",
    "population": 15706,
    "id": 1643871801
  },
  {
    "city": "Tīrān",
    "city_ascii": "Tiran",
    "lat": 32.7025,
    "lng": 51.1536,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Eşfahān",
    "capital": "minor",
    "population": 15673,
    "id": 1364233124
  },
  {
    "city": "Andrews",
    "city_ascii": "Andrews",
    "lat": 32.3208,
    "lng": -102.552,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 15708,
    "id": 1840019474
  },
  {
    "city": "Kysucké Nové Mesto",
    "city_ascii": "Kysucke Nove Mesto",
    "lat": 49.3,
    "lng": 18.7833,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Žilinský",
    "capital": "minor",
    "population": 15132,
    "id": 1703666551
  },
  {
    "city": "Tiszaújváros",
    "city_ascii": "Tiszaujvaros",
    "lat": 47.9228,
    "lng": 21.0519,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Borsod-Abaúj-Zemplén",
    "capital": "minor",
    "population": 15371,
    "id": 1348143264
  },
  {
    "city": "West Norriton",
    "city_ascii": "West Norriton",
    "lat": 40.1308,
    "lng": -75.3795,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 15703,
    "id": 1840133862
  },
  {
    "city": "Duxbury",
    "city_ascii": "Duxbury",
    "lat": 42.0465,
    "lng": -70.7139,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 15703,
    "id": 1840053566
  },
  {
    "city": "Ostroh",
    "city_ascii": "Ostroh",
    "lat": 50.3333,
    "lng": 26.5167,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Rivnens’ka Oblast’",
    "capital": "minor",
    "population": 15642,
    "id": 1804135367
  },
  {
    "city": "Erlensee",
    "city_ascii": "Erlensee",
    "lat": 50.1635,
    "lng": 8.9808,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 14899,
    "id": 1276495268
  },
  {
    "city": "Zhirnovsk",
    "city_ascii": "Zhirnovsk",
    "lat": 50.9833,
    "lng": 44.7667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Volgogradskaya Oblast’",
    "capital": "",
    "population": 15680,
    "id": 1643137846
  },
  {
    "city": "Potsdam",
    "city_ascii": "Potsdam",
    "lat": 44.6774,
    "lng": -75.0396,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 15694,
    "id": 1840004061
  },
  {
    "city": "Blytheville",
    "city_ascii": "Blytheville",
    "lat": 35.9321,
    "lng": -89.9051,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arkansas",
    "capital": "",
    "population": 15693,
    "id": 1840013390
  },
  {
    "city": "Winkfield",
    "city_ascii": "Winkfield",
    "lat": 51.4318,
    "lng": -0.7096,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Bracknell Forest",
    "capital": "",
    "population": 14998,
    "id": 1826199391
  },
  {
    "city": "Einsiedeln",
    "city_ascii": "Einsiedeln",
    "lat": 47.1278,
    "lng": 8.7431,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Schwyz",
    "capital": "minor",
    "population": 15550,
    "id": 1756410538
  },
  {
    "city": "Kuusamo",
    "city_ascii": "Kuusamo",
    "lat": 65.9667,
    "lng": 29.1667,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Pohjois-Pohjanmaa",
    "capital": "minor",
    "population": 15688,
    "id": 1246292850
  },
  {
    "city": "Shively",
    "city_ascii": "Shively",
    "lat": 38.197,
    "lng": -85.8136,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kentucky",
    "capital": "",
    "population": 15689,
    "id": 1840015196
  },
  {
    "city": "Drensteinfurt",
    "city_ascii": "Drensteinfurt",
    "lat": 51.7944,
    "lng": 7.7392,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 15542,
    "id": 1276206694
  },
  {
    "city": "Featherstone",
    "city_ascii": "Featherstone",
    "lat": 53.7,
    "lng": -1.37,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Wakefield",
    "capital": "",
    "population": 15244,
    "id": 1826477772
  },
  {
    "city": "Lebanon",
    "city_ascii": "Lebanon",
    "lat": 40.0324,
    "lng": -86.4551,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 15680,
    "id": 1840008387
  },
  {
    "city": "Rakhiv",
    "city_ascii": "Rakhiv",
    "lat": 48.05,
    "lng": 24.2167,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Zakarpats’ka Oblast’",
    "capital": "minor",
    "population": 15621,
    "id": 1804590257
  },
  {
    "city": "Novo-Voznesenovka",
    "city_ascii": "Novo-Voznesenovka",
    "lat": 48.0731,
    "lng": 39.7972,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Luhans’ka Oblast’",
    "capital": "",
    "population": 15659,
    "id": 1804055076
  },
  {
    "city": "Suffield",
    "city_ascii": "Suffield",
    "lat": 41.9945,
    "lng": -72.6789,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 15662,
    "id": 1840033678
  },
  {
    "city": "Szarvas",
    "city_ascii": "Szarvas",
    "lat": 46.85,
    "lng": 20.6,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Békés",
    "capital": "minor",
    "population": 15565,
    "id": 1348371438
  },
  {
    "city": "Helensburgh",
    "city_ascii": "Helensburgh",
    "lat": 56.0166,
    "lng": -4.7333,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Argyll and Bute",
    "capital": "",
    "population": 15430,
    "id": 1826378273
  },
  {
    "city": "Atacames",
    "city_ascii": "Atacames",
    "lat": 0.8667,
    "lng": -79.8333,
    "country": "Ecuador",
    "iso2": "EC",
    "iso3": "ECU",
    "admin_name": "Esmeraldas",
    "capital": "",
    "population": 15463,
    "id": 1218813219
  },
  {
    "city": "Graham",
    "city_ascii": "Graham",
    "lat": 36.0596,
    "lng": -79.3892,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 15646,
    "id": 1840013361
  },
  {
    "city": "Mountain House",
    "city_ascii": "Mountain House",
    "lat": 37.774,
    "lng": -121.5452,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 15645,
    "id": 1840026768
  },
  {
    "city": "North Reading",
    "city_ascii": "North Reading",
    "lat": 42.5816,
    "lng": -71.0876,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 15642,
    "id": 1840053482
  },
  {
    "city": "Bad Bentheim",
    "city_ascii": "Bad Bentheim",
    "lat": 52.3031,
    "lng": 7.1597,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 15486,
    "id": 1276319622
  },
  {
    "city": "Bolotnoye",
    "city_ascii": "Bolotnoye",
    "lat": 55.6667,
    "lng": 84.4,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Novosibirskaya Oblast’",
    "capital": "",
    "population": 15629,
    "id": 1643828761
  },
  {
    "city": "Serpa",
    "city_ascii": "Serpa",
    "lat": 37.9333,
    "lng": -7.5833,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Beja",
    "capital": "minor",
    "population": 15623,
    "id": 1620464160
  },
  {
    "city": "Baia-Sprie",
    "city_ascii": "Baia-Sprie",
    "lat": 47.6608,
    "lng": 23.6886,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Maramureş",
    "capital": "",
    "population": 15476,
    "id": 1642427641
  },
  {
    "city": "Elkton",
    "city_ascii": "Elkton",
    "lat": 39.6067,
    "lng": -75.8208,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 15622,
    "id": 1840005635
  },
  {
    "city": "Nakanojōmachi",
    "city_ascii": "Nakanojomachi",
    "lat": 36.59,
    "lng": 138.8411,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Gunma",
    "capital": "",
    "population": 15585,
    "id": 1392957874
  },
  {
    "city": "Malta",
    "city_ascii": "Malta",
    "lat": 42.9853,
    "lng": -73.7879,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 15620,
    "id": 1840058280
  },
  {
    "city": "Uchiko",
    "city_ascii": "Uchiko",
    "lat": 33.5331,
    "lng": 132.6581,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Ehime",
    "capital": "",
    "population": 15565,
    "id": 1392976487
  },
  {
    "city": "Klimavichy",
    "city_ascii": "Klimavichy",
    "lat": 53.6167,
    "lng": 31.95,
    "country": "Belarus",
    "iso2": "BY",
    "iso3": "BLR",
    "admin_name": "Mahilyowskaya Voblasts’",
    "capital": "minor",
    "population": 15600,
    "id": 1112437772
  },
  {
    "city": "Clark",
    "city_ascii": "Clark",
    "lat": 40.6203,
    "lng": -74.3135,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 15614,
    "id": 1840081746
  },
  {
    "city": "Loncoche",
    "city_ascii": "Loncoche",
    "lat": -39.3669,
    "lng": -72.6308,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Araucanía",
    "capital": "",
    "population": 15590,
    "id": 1152864736
  },
  {
    "city": "Bohodukhiv",
    "city_ascii": "Bohodukhiv",
    "lat": 50.1608,
    "lng": 35.5164,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Kharkivs’ka Oblast’",
    "capital": "minor",
    "population": 15576,
    "id": 1804919140
  },
  {
    "city": "At-Bashy",
    "city_ascii": "At-Bashy",
    "lat": 41.1725,
    "lng": 75.7968,
    "country": "Kyrgyzstan",
    "iso2": "KG",
    "iso3": "KGZ",
    "admin_name": "Naryn",
    "capital": "minor",
    "population": 15601,
    "id": 1417188010
  },
  {
    "city": "Kuala Lipis",
    "city_ascii": "Kuala Lipis",
    "lat": 4.184,
    "lng": 102.042,
    "country": "Malaysia",
    "iso2": "MY",
    "iso3": "MYS",
    "admin_name": "Pahang",
    "capital": "",
    "population": 15448,
    "id": 1458124212
  },
  {
    "city": "Fate",
    "city_ascii": "Fate",
    "lat": 32.9429,
    "lng": -96.3858,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 15603,
    "id": 1840020715
  },
  {
    "city": "Ipuã",
    "city_ascii": "Ipua",
    "lat": -20.4381,
    "lng": -48.0122,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 15567,
    "id": 1076410935
  },
  {
    "city": "Port Washington",
    "city_ascii": "Port Washington",
    "lat": 40.8268,
    "lng": -73.6764,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 15600,
    "id": 1840005252
  },
  {
    "city": "Ban Na Kham",
    "city_ascii": "Ban Na Kham",
    "lat": 14.0681,
    "lng": 101.8125,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Prachin Buri",
    "capital": "",
    "population": 15465,
    "id": 1764834379
  },
  {
    "city": "Borborema",
    "city_ascii": "Borborema",
    "lat": -21.62,
    "lng": -49.0739,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 15569,
    "id": 1076625654
  },
  {
    "city": "Najasa",
    "city_ascii": "Najasa",
    "lat": 21.0836,
    "lng": -77.7472,
    "country": "Cuba",
    "iso2": "CU",
    "iso3": "CUB",
    "admin_name": "Camagüey",
    "capital": "minor",
    "population": 15579,
    "id": 1192325619
  },
  {
    "city": "Moritake",
    "city_ascii": "Moritake",
    "lat": 40.1017,
    "lng": 140.005,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Akita",
    "capital": "",
    "population": 15534,
    "id": 1392471359
  },
  {
    "city": "Künzelsau",
    "city_ascii": "Kunzelsau",
    "lat": 49.2833,
    "lng": 9.6833,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "minor",
    "population": 15391,
    "id": 1276563190
  },
  {
    "city": "Riverdale",
    "city_ascii": "Riverdale",
    "lat": 33.564,
    "lng": -84.4103,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 15594,
    "id": 1840014815
  },
  {
    "city": "Huasca de Ocampo",
    "city_ascii": "Huasca de Ocampo",
    "lat": 20.2028,
    "lng": -98.5758,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Hidalgo",
    "capital": "minor",
    "population": 15201,
    "id": 1484063344
  },
  {
    "city": "Arolsen",
    "city_ascii": "Arolsen",
    "lat": 51.3778,
    "lng": 9.0167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 15470,
    "id": 1276460820
  },
  {
    "city": "East Highland Park",
    "city_ascii": "East Highland Park",
    "lat": 37.577,
    "lng": -77.3865,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 15592,
    "id": 1840006388
  },
  {
    "city": "Newberry",
    "city_ascii": "Newberry",
    "lat": 40.1286,
    "lng": -76.792,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 15588,
    "id": 1840151961
  },
  {
    "city": "Chubbuck",
    "city_ascii": "Chubbuck",
    "lat": 42.9261,
    "lng": -112.4624,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Idaho",
    "capital": "",
    "population": 15588,
    "id": 1840018667
  },
  {
    "city": "Siemiatycze",
    "city_ascii": "Siemiatycze",
    "lat": 52.4272,
    "lng": 22.8625,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Podlaskie",
    "capital": "minor",
    "population": 15169,
    "id": 1616804060
  },
  {
    "city": "Dastgerd",
    "city_ascii": "Dastgerd",
    "lat": 32.8019,
    "lng": 51.6636,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Eşfahān",
    "capital": "",
    "population": 15524,
    "id": 1364996387
  },
  {
    "city": "Weston",
    "city_ascii": "Weston",
    "lat": 53.413,
    "lng": -2.798,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Halton",
    "capital": "",
    "population": 14263,
    "id": 1826832575
  },
  {
    "city": "Newton",
    "city_ascii": "Newton",
    "lat": 41.6964,
    "lng": -93.0402,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Iowa",
    "capital": "",
    "population": 15580,
    "id": 1840008171
  },
  {
    "city": "Fullerton",
    "city_ascii": "Fullerton",
    "lat": 40.6308,
    "lng": -75.4834,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 15578,
    "id": 1840034977
  },
  {
    "city": "Svetogorsk",
    "city_ascii": "Svetogorsk",
    "lat": 61.1083,
    "lng": 28.8583,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Leningradskaya Oblast’",
    "capital": "",
    "population": 15546,
    "id": 1643956298
  },
  {
    "city": "Hemsworth",
    "city_ascii": "Hemsworth",
    "lat": 53.61,
    "lng": -1.35,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Wakefield",
    "capital": "",
    "population": 15350,
    "id": 1826714452
  },
  {
    "city": "Burbage",
    "city_ascii": "Burbage",
    "lat": 52.5277,
    "lng": -1.3483,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Leicestershire",
    "capital": "",
    "population": 14568,
    "id": 1826305898
  },
  {
    "city": "La Grange",
    "city_ascii": "La Grange",
    "lat": 41.6787,
    "lng": -73.8028,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 15572,
    "id": 1840087585
  },
  {
    "city": "Privolzhsk",
    "city_ascii": "Privolzhsk",
    "lat": 57.3833,
    "lng": 41.2833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Ivanovskaya Oblast’",
    "capital": "",
    "population": 15530,
    "id": 1643261146
  },
  {
    "city": "Miami",
    "city_ascii": "Miami",
    "lat": 36.8877,
    "lng": -94.8718,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oklahoma",
    "capital": "",
    "population": 15571,
    "id": 1840020346
  },
  {
    "city": "McMinnville",
    "city_ascii": "McMinnville",
    "lat": 35.6863,
    "lng": -85.7812,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 15570,
    "id": 1840015385
  },
  {
    "city": "Milford",
    "city_ascii": "Milford",
    "lat": 42.8178,
    "lng": -71.6736,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Hampshire",
    "capital": "",
    "population": 15569,
    "id": 1840055182
  },
  {
    "city": "Khamir",
    "city_ascii": "Khamir",
    "lat": 15.99,
    "lng": 43.95,
    "country": "Yemen",
    "iso2": "YE",
    "iso3": "YEM",
    "admin_name": "‘Amrān",
    "capital": "minor",
    "population": 15333,
    "id": 1887000562
  },
  {
    "city": "Parvomay",
    "city_ascii": "Parvomay",
    "lat": 42.0994,
    "lng": 25.2242,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Plovdiv",
    "capital": "",
    "population": 15518,
    "id": 1100284082
  },
  {
    "city": "Longwood",
    "city_ascii": "Longwood",
    "lat": 28.7014,
    "lng": -81.3487,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 15561,
    "id": 1840015957
  },
  {
    "city": "Seven Oaks",
    "city_ascii": "Seven Oaks",
    "lat": 34.0474,
    "lng": -81.1434,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 15559,
    "id": 1840035850
  },
  {
    "city": "Schriesheim",
    "city_ascii": "Schriesheim",
    "lat": 49.4736,
    "lng": 8.6592,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 15081,
    "id": 1276514747
  },
  {
    "city": "Luduş",
    "city_ascii": "Ludus",
    "lat": 46.4778,
    "lng": 24.0961,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Mureş",
    "capital": "",
    "population": 15328,
    "id": 1642649798
  },
  {
    "city": "Ban Ton Pao",
    "city_ascii": "Ban Ton Pao",
    "lat": 18.7591,
    "lng": 99.0784,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Chiang Mai",
    "capital": "",
    "population": 15175,
    "id": 1764110544
  },
  {
    "city": "Rahden",
    "city_ascii": "Rahden",
    "lat": 52.4167,
    "lng": 8.6167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 15441,
    "id": 1276217000
  },
  {
    "city": "Overland",
    "city_ascii": "Overland",
    "lat": 38.6966,
    "lng": -90.3689,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 15551,
    "id": 1840009744
  },
  {
    "city": "Dunn",
    "city_ascii": "Dunn",
    "lat": 35.3113,
    "lng": -78.6129,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 15550,
    "id": 1840013434
  },
  {
    "city": "Narragansett",
    "city_ascii": "Narragansett",
    "lat": 41.4291,
    "lng": -71.4669,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Rhode Island",
    "capital": "",
    "population": 15550,
    "id": 1840106247
  },
  {
    "city": "Sovetsk",
    "city_ascii": "Sovetsk",
    "lat": 57.6013,
    "lng": 48.9386,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kirovskaya Oblast’",
    "capital": "",
    "population": 15538,
    "id": 1643054411
  },
  {
    "city": "Kinna",
    "city_ascii": "Kinna",
    "lat": 57.4954,
    "lng": 12.6805,
    "country": "Sweden",
    "iso2": "SE",
    "iso3": "SWE",
    "admin_name": "Västra Götaland",
    "capital": "minor",
    "population": 15373,
    "id": 1752953604
  },
  {
    "city": "Belvedere Park",
    "city_ascii": "Belvedere Park",
    "lat": 33.7488,
    "lng": -84.2598,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 15543,
    "id": 1840013699
  },
  {
    "city": "Houghton",
    "city_ascii": "Houghton",
    "lat": 47.1119,
    "lng": -88.5672,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 15543,
    "id": 1840003919
  },
  {
    "city": "Uusikaupunki",
    "city_ascii": "Uusikaupunki",
    "lat": 60.7833,
    "lng": 21.4167,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Varsinais-Suomi",
    "capital": "minor",
    "population": 15510,
    "id": 1246110587
  },
  {
    "city": "Kawaminami",
    "city_ascii": "Kawaminami",
    "lat": 32.1919,
    "lng": 131.5258,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Miyazaki",
    "capital": "",
    "population": 15372,
    "id": 1392313930
  },
  {
    "city": "Zelenogradsk",
    "city_ascii": "Zelenogradsk",
    "lat": 54.95,
    "lng": 20.4833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kaliningradskaya Oblast’",
    "capital": "minor",
    "population": 15493,
    "id": 1643896449
  },
  {
    "city": "Abaza",
    "city_ascii": "Abaza",
    "lat": 52.65,
    "lng": 90.0833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Khakasiya",
    "capital": "",
    "population": 15335,
    "id": 1643885049
  },
  {
    "city": "Phelan",
    "city_ascii": "Phelan",
    "lat": 34.4398,
    "lng": -117.5248,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 15532,
    "id": 1840025798
  },
  {
    "city": "Boppard",
    "city_ascii": "Boppard",
    "lat": 50.2314,
    "lng": 7.5908,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Rhineland-Palatinate",
    "capital": "",
    "population": 15325,
    "id": 1276667317
  },
  {
    "city": "Antrim",
    "city_ascii": "Antrim",
    "lat": 39.7863,
    "lng": -77.722,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 15528,
    "id": 1840148478
  },
  {
    "city": "Blackfoot",
    "city_ascii": "Blackfoot",
    "lat": 43.194,
    "lng": -112.3455,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Idaho",
    "capital": "",
    "population": 15526,
    "id": 1840018650
  },
  {
    "city": "Ifrane",
    "city_ascii": "Ifrane",
    "lat": 33.5333,
    "lng": -5.1167,
    "country": "Morocco",
    "iso2": "MA",
    "iso3": "MAR",
    "admin_name": "Fès-Meknès",
    "capital": "",
    "population": 14659,
    "id": 1504656119
  },
  {
    "city": "Buford",
    "city_ascii": "Buford",
    "lat": 34.1185,
    "lng": -83.9916,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 15522,
    "id": 1840013678
  },
  {
    "city": "Tasquillo",
    "city_ascii": "Tasquillo",
    "lat": 20.6167,
    "lng": -99.25,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Hidalgo",
    "capital": "",
    "population": 15429,
    "id": 1484659098
  },
  {
    "city": "Lake Geneva",
    "city_ascii": "Lake Geneva",
    "lat": 42.5824,
    "lng": -88.4281,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 15518,
    "id": 1840002479
  },
  {
    "city": "Harrison",
    "city_ascii": "Harrison",
    "lat": 39.2582,
    "lng": -84.7868,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 15518,
    "id": 1840007381
  },
  {
    "city": "La Grande",
    "city_ascii": "La Grande",
    "lat": 45.3243,
    "lng": -118.0865,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 15515,
    "id": 1840019924
  },
  {
    "city": "Genappe",
    "city_ascii": "Genappe",
    "lat": 50.6103,
    "lng": 4.4497,
    "country": "Belgium",
    "iso2": "BE",
    "iso3": "BEL",
    "admin_name": "Wallonia",
    "capital": "",
    "population": 15353,
    "id": 1056101881
  },
  {
    "city": "Balassagyarmat",
    "city_ascii": "Balassagyarmat",
    "lat": 48.0712,
    "lng": 19.2937,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Nógrád",
    "capital": "minor",
    "population": 14873,
    "id": 1348612236
  },
  {
    "city": "Barwon Heads",
    "city_ascii": "Barwon Heads",
    "lat": -38.25,
    "lng": 144.5167,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Victoria",
    "capital": "",
    "population": 14165,
    "id": 1036797778
  },
  {
    "city": "Neustadt bei Coburg",
    "city_ascii": "Neustadt bei Coburg",
    "lat": 50.3289,
    "lng": 11.1211,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 15257,
    "id": 1276116282
  },
  {
    "city": "South Fayette",
    "city_ascii": "South Fayette",
    "lat": 40.3556,
    "lng": -80.1618,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 15503,
    "id": 1840150313
  },
  {
    "city": "Broxbourne",
    "city_ascii": "Broxbourne",
    "lat": 51.7495,
    "lng": -0.0216,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Essex",
    "capital": "",
    "population": 15303,
    "id": 1826900136
  },
  {
    "city": "Linguère",
    "city_ascii": "Linguere",
    "lat": 15.3833,
    "lng": -15.2167,
    "country": "Senegal",
    "iso2": "SN",
    "iso3": "SEN",
    "admin_name": "Louga",
    "capital": "",
    "population": 15482,
    "id": 1686531921
  },
  {
    "city": "Kanegasaki",
    "city_ascii": "Kanegasaki",
    "lat": 39.1956,
    "lng": 141.1161,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Iwate",
    "capital": "",
    "population": 15410,
    "id": 1392073512
  },
  {
    "city": "Farmingville",
    "city_ascii": "Farmingville",
    "lat": 40.8389,
    "lng": -73.0401,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 15495,
    "id": 1840005009
  },
  {
    "city": "Ober-Ramstadt",
    "city_ascii": "Ober-Ramstadt",
    "lat": 49.8333,
    "lng": 8.75,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 15130,
    "id": 1276189736
  },
  {
    "city": "Australind",
    "city_ascii": "Australind",
    "lat": -33.2792,
    "lng": 115.715,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Western Australia",
    "capital": "",
    "population": 14539,
    "id": 1036182351
  },
  {
    "city": "Mainburg",
    "city_ascii": "Mainburg",
    "lat": 48.65,
    "lng": 11.7833,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 15241,
    "id": 1276991073
  },
  {
    "city": "Colonia Nicolich",
    "city_ascii": "Colonia Nicolich",
    "lat": -34.8161,
    "lng": -56.0244,
    "country": "Uruguay",
    "iso2": "UY",
    "iso3": "URY",
    "admin_name": "Canelones",
    "capital": "",
    "population": 14686,
    "id": 1858537250
  },
  {
    "city": "Taphan Hin",
    "city_ascii": "Taphan Hin",
    "lat": 16.2108,
    "lng": 100.4188,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Phichit",
    "capital": "minor",
    "population": 15329,
    "id": 1764640959
  },
  {
    "city": "Jelcz-Laskowice",
    "city_ascii": "Jelcz-Laskowice",
    "lat": 51.0333,
    "lng": 17.3333,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Dolnośląskie",
    "capital": "",
    "population": 15340,
    "id": 1616808157
  },
  {
    "city": "Ōshima",
    "city_ascii": "Oshima",
    "lat": 33.9275,
    "lng": 132.1953,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Yamaguchi",
    "capital": "",
    "population": 15369,
    "id": 1392956813
  },
  {
    "city": "Groves",
    "city_ascii": "Groves",
    "lat": 29.9457,
    "lng": -93.9164,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 15480,
    "id": 1840020916
  },
  {
    "city": "Pargas",
    "city_ascii": "Pargas",
    "lat": 60.3,
    "lng": 22.3,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Varsinais-Suomi",
    "capital": "minor",
    "population": 15457,
    "id": 1246990890
  },
  {
    "city": "Galanta",
    "city_ascii": "Galanta",
    "lat": 48.1914,
    "lng": 17.7308,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Trnavský",
    "capital": "minor",
    "population": 15029,
    "id": 1703120770
  },
  {
    "city": "Hofgeismar",
    "city_ascii": "Hofgeismar",
    "lat": 51.496,
    "lng": 9.3872,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 15294,
    "id": 1276882512
  },
  {
    "city": "Hermitage",
    "city_ascii": "Hermitage",
    "lat": 41.2305,
    "lng": -80.4414,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 15471,
    "id": 1840000732
  },
  {
    "city": "Indianola",
    "city_ascii": "Indianola",
    "lat": 41.3629,
    "lng": -93.5652,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Iowa",
    "capital": "",
    "population": 15471,
    "id": 1840000648
  },
  {
    "city": "Petersfield",
    "city_ascii": "Petersfield",
    "lat": 51.0038,
    "lng": -0.9345,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hampshire",
    "capital": "",
    "population": 14974,
    "id": 1826282977
  },
  {
    "city": "Sale",
    "city_ascii": "Sale",
    "lat": -38.1,
    "lng": 147.0667,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Victoria",
    "capital": "",
    "population": 15135,
    "id": 1036960753
  },
  {
    "city": "Rio Linda",
    "city_ascii": "Rio Linda",
    "lat": 38.6876,
    "lng": -121.4418,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 15460,
    "id": 1840018843
  },
  {
    "city": "Red Hill",
    "city_ascii": "Red Hill",
    "lat": 33.7777,
    "lng": -79.0111,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 15459,
    "id": 1840013649
  },
  {
    "city": "Hampton",
    "city_ascii": "Hampton",
    "lat": 42.9391,
    "lng": -70.837,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Hampshire",
    "capital": "",
    "population": 15452,
    "id": 1840054955
  },
  {
    "city": "Langenau",
    "city_ascii": "Langenau",
    "lat": 48.4994,
    "lng": 10.1211,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 15247,
    "id": 1276345648
  },
  {
    "city": "Hartford",
    "city_ascii": "Hartford",
    "lat": 43.3223,
    "lng": -88.3782,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 15445,
    "id": 1840002831
  },
  {
    "city": "Holly Springs",
    "city_ascii": "Holly Springs",
    "lat": 34.1685,
    "lng": -84.4845,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 15442,
    "id": 1840014704
  },
  {
    "city": "Głuchołazy",
    "city_ascii": "Glucholazy",
    "lat": 50.3131,
    "lng": 17.3742,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Opolskie",
    "capital": "",
    "population": 15293,
    "id": 1616458381
  },
  {
    "city": "Kérouané",
    "city_ascii": "Kerouane",
    "lat": 9.2704,
    "lng": -9.02,
    "country": "Guinea",
    "iso2": "GN",
    "iso3": "GIN",
    "admin_name": "Kankan",
    "capital": "minor",
    "population": 15406,
    "id": 1324580549
  },
  {
    "city": "Hayden",
    "city_ascii": "Hayden",
    "lat": 47.768,
    "lng": -116.8039,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Idaho",
    "capital": "",
    "population": 15434,
    "id": 1840019808
  },
  {
    "city": "Middleburg Heights",
    "city_ascii": "Middleburg Heights",
    "lat": 41.3695,
    "lng": -81.8151,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 15432,
    "id": 1840000614
  },
  {
    "city": "Mount Vernon",
    "city_ascii": "Mount Vernon",
    "lat": 38.314,
    "lng": -88.9174,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 15427,
    "id": 1840008654
  },
  {
    "city": "Konārka",
    "city_ascii": "Konarka",
    "lat": 19.8878,
    "lng": 86.0948,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Odisha",
    "capital": "",
    "population": 15015,
    "id": 1356415171
  },
  {
    "city": "Amatitán",
    "city_ascii": "Amatitan",
    "lat": 20.8333,
    "lng": -103.7167,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Jalisco",
    "capital": "minor",
    "population": 15344,
    "id": 1484634445
  },
  {
    "city": "Mendrisio",
    "city_ascii": "Mendrisio",
    "lat": 45.8667,
    "lng": 8.9833,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Ticino",
    "capital": "",
    "population": 14942,
    "id": 1756337416
  },
  {
    "city": "Nyunzu",
    "city_ascii": "Nyunzu",
    "lat": -5.95,
    "lng": 28.0167,
    "country": "Congo (Kinshasa)",
    "iso2": "CD",
    "iso3": "COD",
    "admin_name": "Tanganyika",
    "capital": "",
    "population": 15397,
    "id": 1180921995
  },
  {
    "city": "Fort Carson",
    "city_ascii": "Fort Carson",
    "lat": 38.7403,
    "lng": -104.784,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 15410,
    "id": 1840028557
  },
  {
    "city": "Pulaski",
    "city_ascii": "Pulaski",
    "lat": 37.0528,
    "lng": -80.7624,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 15407,
    "id": 1840006469
  },
  {
    "city": "Küsnacht",
    "city_ascii": "Kusnacht",
    "lat": 47.3181,
    "lng": 8.5825,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Zürich",
    "capital": "",
    "population": 14250,
    "id": 1756976671
  },
  {
    "city": "Cumru",
    "city_ascii": "Cumru",
    "lat": 40.2811,
    "lng": -75.9544,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 15397,
    "id": 1840145303
  },
  {
    "city": "Havelock North",
    "city_ascii": "Havelock North",
    "lat": -39.6667,
    "lng": 176.8833,
    "country": "New Zealand",
    "iso2": "NZ",
    "iso3": "NZL",
    "admin_name": "Hawke’s Bay",
    "capital": "",
    "population": 13950,
    "id": 1554193593
  },
  {
    "city": "Great Falls",
    "city_ascii": "Great Falls",
    "lat": 39.011,
    "lng": -77.3013,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 15394,
    "id": 1840006028
  },
  {
    "city": "Hohenstein-Ernstthal",
    "city_ascii": "Hohenstein-Ernstthal",
    "lat": 50.8,
    "lng": 12.7167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 14607,
    "id": 1276671628
  },
  {
    "city": "Stony Point",
    "city_ascii": "Stony Point",
    "lat": 41.2592,
    "lng": -74.0112,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 15393,
    "id": 1840058529
  },
  {
    "city": "Thoen",
    "city_ascii": "Thoen",
    "lat": 17.6118,
    "lng": 99.216,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Lampang",
    "capital": "minor",
    "population": 15360,
    "id": 1764582305
  },
  {
    "city": "Rochedale",
    "city_ascii": "Rochedale",
    "lat": -27.5952,
    "lng": 153.1233,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Queensland",
    "capital": "",
    "population": 15317,
    "id": 1036018437
  },
  {
    "city": "Rossville",
    "city_ascii": "Rossville",
    "lat": 39.3572,
    "lng": -76.4767,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 15392,
    "id": 1840031398
  },
  {
    "city": "Ashtead",
    "city_ascii": "Ashtead",
    "lat": 51.31,
    "lng": -0.299,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Surrey",
    "capital": "",
    "population": 14169,
    "id": 1826304464
  },
  {
    "city": "Wilmington Island",
    "city_ascii": "Wilmington Island",
    "lat": 32.0036,
    "lng": -80.9752,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 15391,
    "id": 1840029461
  },
  {
    "city": "Northview",
    "city_ascii": "Northview",
    "lat": 43.0427,
    "lng": -85.6016,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 15391,
    "id": 1840004305
  },
  {
    "city": "Agidel",
    "city_ascii": "Agidel",
    "lat": 55.9,
    "lng": 53.9333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Bashkortostan",
    "capital": "",
    "population": 14601,
    "id": 1643864231
  },
  {
    "city": "Ladson",
    "city_ascii": "Ladson",
    "lat": 33.0092,
    "lng": -80.1078,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 15383,
    "id": 1840014254
  },
  {
    "city": "Castro Daire",
    "city_ascii": "Castro Daire",
    "lat": 40.9,
    "lng": -7.9333,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Viseu",
    "capital": "minor",
    "population": 15339,
    "id": 1620316265
  },
  {
    "city": "Obanazawa",
    "city_ascii": "Obanazawa",
    "lat": 38.6008,
    "lng": 140.4058,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Yamagata",
    "capital": "",
    "population": 15332,
    "id": 1392411470
  },
  {
    "city": "Mitchell",
    "city_ascii": "Mitchell",
    "lat": 43.7296,
    "lng": -98.0337,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Dakota",
    "capital": "",
    "population": 15372,
    "id": 1840002642
  },
  {
    "city": "Blaydon",
    "city_ascii": "Blaydon",
    "lat": 54.963,
    "lng": -1.7187,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Gateshead",
    "capital": "",
    "population": 15155,
    "id": 1826275133
  },
  {
    "city": "Swallownest",
    "city_ascii": "Swallownest",
    "lat": 53.3623,
    "lng": -1.3251,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Rotherham",
    "capital": "",
    "population": 15022,
    "id": 1826951859
  },
  {
    "city": "Krasnovishersk",
    "city_ascii": "Krasnovishersk",
    "lat": 60.4,
    "lng": 57.0667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Permskiy Kray",
    "capital": "",
    "population": 15359,
    "id": 1643010731
  },
  {
    "city": "Hückeswagen",
    "city_ascii": "Huckeswagen",
    "lat": 51.145,
    "lng": 7.3417,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 15060,
    "id": 1276742746
  },
  {
    "city": "Washington",
    "city_ascii": "Washington",
    "lat": 40.7587,
    "lng": -74.9825,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 15357,
    "id": 1840000945
  },
  {
    "city": "Kulunda",
    "city_ascii": "Kulunda",
    "lat": 52.5827,
    "lng": 78.9473,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Altayskiy Kray",
    "capital": "",
    "population": 15345,
    "id": 1643649266
  },
  {
    "city": "Brake",
    "city_ascii": "Brake",
    "lat": 53.3333,
    "lng": 8.4833,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "minor",
    "population": 14965,
    "id": 1276055035
  },
  {
    "city": "Chilcuautla",
    "city_ascii": "Chilcuautla",
    "lat": 20.3333,
    "lng": -99.2333,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Hidalgo",
    "capital": "minor",
    "population": 15284,
    "id": 1484513510
  },
  {
    "city": "Poynton",
    "city_ascii": "Poynton",
    "lat": 53.3492,
    "lng": -2.1222,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Cheshire East",
    "capital": "",
    "population": 14260,
    "id": 1826399707
  },
  {
    "city": "Eldorado",
    "city_ascii": "Eldorado",
    "lat": -24.52,
    "lng": -48.1081,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 15339,
    "id": 1076129715
  },
  {
    "city": "Alta",
    "city_ascii": "Alta",
    "lat": 69.9686,
    "lng": 23.2714,
    "country": "Norway",
    "iso2": "NO",
    "iso3": "NOR",
    "admin_name": "Finnmark",
    "capital": "minor",
    "population": 15342,
    "id": 1578308187
  },
  {
    "city": "Springfield",
    "city_ascii": "Springfield",
    "lat": 49.9292,
    "lng": -96.6939,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Manitoba",
    "capital": "",
    "population": 15342,
    "id": 1124000696
  },
  {
    "city": "Nazaré",
    "city_ascii": "Nazare",
    "lat": 39.6,
    "lng": -9.0667,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Leiria",
    "capital": "minor",
    "population": 15158,
    "id": 1620000054
  },
  {
    "city": "Chīpurupalle",
    "city_ascii": "Chipurupalle",
    "lat": 18.3,
    "lng": 83.5667,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Andhra Pradesh",
    "capital": "",
    "population": 14847,
    "id": 1356643864
  },
  {
    "city": "Forks",
    "city_ascii": "Forks",
    "lat": 40.7358,
    "lng": -75.2211,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 15336,
    "id": 1840149342
  },
  {
    "city": "Grosse Pointe Woods",
    "city_ascii": "Grosse Pointe Woods",
    "lat": 42.4366,
    "lng": -82.8987,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 15332,
    "id": 1840003979
  },
  {
    "city": "Junín",
    "city_ascii": "Junin",
    "lat": -11.15,
    "lng": -75.9833,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "Junín",
    "capital": "",
    "population": 15320,
    "id": 1604997443
  },
  {
    "city": "Lovisa",
    "city_ascii": "Lovisa",
    "lat": 60.4569,
    "lng": 26.225,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Uusimaa",
    "capital": "minor",
    "population": 15311,
    "id": 1246944790
  },
  {
    "city": "Belokurikha",
    "city_ascii": "Belokurikha",
    "lat": 52,
    "lng": 84.9833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Altayskiy Kray",
    "capital": "",
    "population": 15160,
    "id": 1643613232
  },
  {
    "city": "Hueytown",
    "city_ascii": "Hueytown",
    "lat": 33.4237,
    "lng": -87.022,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 15322,
    "id": 1840014796
  },
  {
    "city": "Penacova",
    "city_ascii": "Penacova",
    "lat": 40.2667,
    "lng": -8.2667,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Coimbra",
    "capital": "minor",
    "population": 15251,
    "id": 1620105584
  },
  {
    "city": "Hilltown",
    "city_ascii": "Hilltown",
    "lat": 40.3416,
    "lng": -75.2534,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 15321,
    "id": 1840147878
  },
  {
    "city": "Port Pirie",
    "city_ascii": "Port Pirie",
    "lat": -33.1858,
    "lng": 138.0169,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "South Australia",
    "capital": "",
    "population": 14188,
    "id": 1036571606
  },
  {
    "city": "Lower Salford",
    "city_ascii": "Lower Salford",
    "lat": 40.2639,
    "lng": -75.3929,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 15318,
    "id": 1840142128
  },
  {
    "city": "Alamo",
    "city_ascii": "Alamo",
    "lat": 37.8546,
    "lng": -122.013,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 15317,
    "id": 1840017609
  },
  {
    "city": "Chitila",
    "city_ascii": "Chitila",
    "lat": 44.5172,
    "lng": 25.9753,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Ilfov",
    "capital": "",
    "population": 14184,
    "id": 1642960997
  },
  {
    "city": "Bradley",
    "city_ascii": "Bradley",
    "lat": 41.1641,
    "lng": -87.8452,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 15314,
    "id": 1840011693
  },
  {
    "city": "Poronaysk",
    "city_ascii": "Poronaysk",
    "lat": 49.2167,
    "lng": 143.1,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sakhalinskaya Oblast’",
    "capital": "",
    "population": 15311,
    "id": 1643094568
  },
  {
    "city": "Markgröningen",
    "city_ascii": "Markgroningen",
    "lat": 48.9047,
    "lng": 9.0808,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 14785,
    "id": 1276236194
  },
  {
    "city": "Tapolca",
    "city_ascii": "Tapolca",
    "lat": 46.8828,
    "lng": 17.4411,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Veszprém",
    "capital": "minor",
    "population": 15072,
    "id": 1348079279
  },
  {
    "city": "Blomberg",
    "city_ascii": "Blomberg",
    "lat": 51.9331,
    "lng": 9.0831,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 15154,
    "id": 1276943915
  },
  {
    "city": "Sárvár",
    "city_ascii": "Sarvar",
    "lat": 47.2539,
    "lng": 16.9353,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Vas",
    "capital": "minor",
    "population": 15072,
    "id": 1348516359
  },
  {
    "city": "Selb",
    "city_ascii": "Selb",
    "lat": 50.1667,
    "lng": 12.1333,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 15128,
    "id": 1276030522
  },
  {
    "city": "Mackworth",
    "city_ascii": "Mackworth",
    "lat": 52.9277,
    "lng": -1.5373,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Derby",
    "capital": "",
    "population": 14180,
    "id": 1826657031
  },
  {
    "city": "Ribnitz-Damgarten",
    "city_ascii": "Ribnitz-Damgarten",
    "lat": 54.25,
    "lng": 12.4667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Mecklenburg-Western Pomerania",
    "capital": "",
    "population": 15167,
    "id": 1276064199
  },
  {
    "city": "Flowing Wells",
    "city_ascii": "Flowing Wells",
    "lat": 32.2937,
    "lng": -111.011,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 15290,
    "id": 1840027988
  },
  {
    "city": "Witzenhausen",
    "city_ascii": "Witzenhausen",
    "lat": 51.3417,
    "lng": 9.8569,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 15167,
    "id": 1276417378
  },
  {
    "city": "Pequannock",
    "city_ascii": "Pequannock",
    "lat": 40.9627,
    "lng": -74.3041,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 15286,
    "id": 1840081738
  },
  {
    "city": "Mexborough",
    "city_ascii": "Mexborough",
    "lat": 53.4992,
    "lng": -1.283,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Doncaster",
    "capital": "",
    "population": 15244,
    "id": 1826750525
  },
  {
    "city": "Pestovo",
    "city_ascii": "Pestovo",
    "lat": 58.6,
    "lng": 35.8167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Novgorodskaya Oblast’",
    "capital": "",
    "population": 15269,
    "id": 1643460089
  },
  {
    "city": "Moreau",
    "city_ascii": "Moreau",
    "lat": 43.2469,
    "lng": -73.6659,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 15273,
    "id": 1840087689
  },
  {
    "city": "Mindelheim",
    "city_ascii": "Mindelheim",
    "lat": 48.0333,
    "lng": 10.4667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "minor",
    "population": 15002,
    "id": 1276322308
  },
  {
    "city": "Hugo",
    "city_ascii": "Hugo",
    "lat": 45.1671,
    "lng": -92.9588,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 15267,
    "id": 1840006739
  },
  {
    "city": "South Middleton",
    "city_ascii": "South Middleton",
    "lat": 40.1322,
    "lng": -77.1641,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 15264,
    "id": 1840152898
  },
  {
    "city": "São Lourenço da Serra",
    "city_ascii": "Sao Lourenco da Serra",
    "lat": -23.8528,
    "lng": -46.9428,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 15177,
    "id": 1076572943
  },
  {
    "city": "Boksitogorsk",
    "city_ascii": "Boksitogorsk",
    "lat": 59.4833,
    "lng": 33.85,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Leningradskaya Oblast’",
    "capital": "",
    "population": 15249,
    "id": 1643927094
  },
  {
    "city": "Englewood",
    "city_ascii": "Englewood",
    "lat": 26.9603,
    "lng": -82.3535,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 15252,
    "id": 1840013127
  },
  {
    "city": "Bilopillya",
    "city_ascii": "Bilopillya",
    "lat": 51.1532,
    "lng": 34.3025,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Sums’ka Oblast’",
    "capital": "minor",
    "population": 15215,
    "id": 1804185866
  },
  {
    "city": "Sullivan",
    "city_ascii": "Sullivan",
    "lat": 43.0922,
    "lng": -75.8794,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 15237,
    "id": 1840058535
  },
  {
    "city": "Salyan",
    "city_ascii": "Salyan",
    "lat": 28.35,
    "lng": 82.1833,
    "country": "Nepal",
    "iso2": "NP",
    "iso3": "NPL",
    "admin_name": "Rāptī",
    "capital": "",
    "population": 15000,
    "id": 1524828921
  },
  {
    "city": "Alpine",
    "city_ascii": "Alpine",
    "lat": 32.8439,
    "lng": -116.7585,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 15233,
    "id": 1840018014
  },
  {
    "city": "Fort Mohave",
    "city_ascii": "Fort Mohave",
    "lat": 35.0004,
    "lng": -114.5748,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 15228,
    "id": 1840042961
  },
  {
    "city": "Santa Adélia",
    "city_ascii": "Santa Adelia",
    "lat": -21.2428,
    "lng": -48.8039,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 15178,
    "id": 1076471467
  },
  {
    "city": "Masty",
    "city_ascii": "Masty",
    "lat": 53.4,
    "lng": 24.5333,
    "country": "Belarus",
    "iso2": "BY",
    "iso3": "BLR",
    "admin_name": "Hrodzyenskaya Voblasts’",
    "capital": "minor",
    "population": 15200,
    "id": 1112000626
  },
  {
    "city": "Lamont",
    "city_ascii": "Lamont",
    "lat": 35.2651,
    "lng": -118.9159,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 15222,
    "id": 1840017825
  },
  {
    "city": "Kerman",
    "city_ascii": "Kerman",
    "lat": 36.7249,
    "lng": -120.0625,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 15218,
    "id": 1840020324
  },
  {
    "city": "Bitburg",
    "city_ascii": "Bitburg",
    "lat": 49.9747,
    "lng": 6.5256,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Rhineland-Palatinate",
    "capital": "minor",
    "population": 14904,
    "id": 1276110282
  },
  {
    "city": "Skalica",
    "city_ascii": "Skalica",
    "lat": 48.85,
    "lng": 17.2333,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Trnavský",
    "capital": "minor",
    "population": 14967,
    "id": 1703467870
  },
  {
    "city": "Tupi Paulista",
    "city_ascii": "Tupi Paulista",
    "lat": -21.3808,
    "lng": -51.5708,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 15153,
    "id": 1076679633
  },
  {
    "city": "Penrith",
    "city_ascii": "Penrith",
    "lat": 54.6648,
    "lng": -2.7548,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Cumbria",
    "capital": "",
    "population": 15200,
    "id": 1826839302
  },
  {
    "city": "Neutraubling",
    "city_ascii": "Neutraubling",
    "lat": 48.9878,
    "lng": 12.1964,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 13796,
    "id": 1276485988
  },
  {
    "city": "Seekonk",
    "city_ascii": "Seekonk",
    "lat": 41.8379,
    "lng": -71.3174,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 15210,
    "id": 1840053523
  },
  {
    "city": "Gehrden",
    "city_ascii": "Gehrden",
    "lat": 52.3135,
    "lng": 9.6008,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 14864,
    "id": 1276000662
  },
  {
    "city": "Samobor",
    "city_ascii": "Samobor",
    "lat": 45.8011,
    "lng": 15.711,
    "country": "Croatia",
    "iso2": "HR",
    "iso3": "HRV",
    "admin_name": "Zagrebačka Županija",
    "capital": "minor",
    "population": 15147,
    "id": 1191807327
  },
  {
    "city": "Itajobi",
    "city_ascii": "Itajobi",
    "lat": -21.3178,
    "lng": -49.0539,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 15177,
    "id": 1076185056
  },
  {
    "city": "Kushimoto",
    "city_ascii": "Kushimoto",
    "lat": 33.4725,
    "lng": 135.7814,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Wakayama",
    "capital": "",
    "population": 15093,
    "id": 1392703742
  },
  {
    "city": "Horw",
    "city_ascii": "Horw",
    "lat": 47.0164,
    "lng": 8.3111,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Luzern",
    "capital": "",
    "population": 14107,
    "id": 1756927189
  },
  {
    "city": "Bay Village",
    "city_ascii": "Bay Village",
    "lat": 41.4851,
    "lng": -81.9315,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 15194,
    "id": 1840003394
  },
  {
    "city": "Kutchan",
    "city_ascii": "Kutchan",
    "lat": 42.9017,
    "lng": 140.7592,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hokkaidō",
    "capital": "",
    "population": 15132,
    "id": 1392245778
  },
  {
    "city": "Gardabani",
    "city_ascii": "Gardabani",
    "lat": 41.45,
    "lng": 45.1,
    "country": "Georgia",
    "iso2": "GE",
    "iso3": "GEO",
    "admin_name": "Kvemo Kartli",
    "capital": "minor",
    "population": 15100,
    "id": 1268317674
  },
  {
    "city": "Richterswil",
    "city_ascii": "Richterswil",
    "lat": 47.2081,
    "lng": 8.7058,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Zürich",
    "capital": "",
    "population": 13407,
    "id": 1756472939
  },
  {
    "city": "Moura",
    "city_ascii": "Moura",
    "lat": 38.1397,
    "lng": -7.4505,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Beja",
    "capital": "minor",
    "population": 15167,
    "id": 1620196948
  },
  {
    "city": "Bennington",
    "city_ascii": "Bennington",
    "lat": 42.8852,
    "lng": -73.2132,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Vermont",
    "capital": "",
    "population": 15179,
    "id": 1840070381
  },
  {
    "city": "Ilha de Moçambique",
    "city_ascii": "Ilha de Mocambique",
    "lat": -15.0367,
    "lng": 40.7328,
    "country": "Mozambique",
    "iso2": "MZ",
    "iso3": "MOZ",
    "admin_name": "Nampula",
    "capital": "",
    "population": 14000,
    "id": 1508063712
  },
  {
    "city": "Gantt",
    "city_ascii": "Gantt",
    "lat": 34.7837,
    "lng": -82.4027,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 15178,
    "id": 1840013492
  },
  {
    "city": "Landsberg",
    "city_ascii": "Landsberg",
    "lat": 51.5246,
    "lng": 12.1596,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony-Anhalt",
    "capital": "",
    "population": 15054,
    "id": 1276487466
  },
  {
    "city": "North Hykeham",
    "city_ascii": "North Hykeham",
    "lat": 53.1819,
    "lng": -0.5849,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Lincolnshire",
    "capital": "",
    "population": 13884,
    "id": 1826546566
  },
  {
    "city": "Meilen",
    "city_ascii": "Meilen",
    "lat": 47.2703,
    "lng": 8.6411,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Zürich",
    "capital": "",
    "population": 13999,
    "id": 1756411281
  },
  {
    "city": "Weston",
    "city_ascii": "Weston",
    "lat": 44.8905,
    "lng": -89.5487,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 15167,
    "id": 1840038093
  },
  {
    "city": "Cadereyta",
    "city_ascii": "Cadereyta",
    "lat": 20.7,
    "lng": -99.8167,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Querétaro",
    "capital": "minor",
    "population": 13347,
    "id": 1484014263
  },
  {
    "city": "Hereford",
    "city_ascii": "Hereford",
    "lat": 34.8232,
    "lng": -102.4001,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 15164,
    "id": 1840020462
  },
  {
    "city": "Gaspé",
    "city_ascii": "Gaspe",
    "lat": 48.8333,
    "lng": -64.4833,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 15163,
    "id": 1124212754
  },
  {
    "city": "Galion",
    "city_ascii": "Galion",
    "lat": 40.7385,
    "lng": -82.7792,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 15161,
    "id": 1840002752
  },
  {
    "city": "College Park",
    "city_ascii": "College Park",
    "lat": 33.6363,
    "lng": -84.464,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 15159,
    "id": 1840014737
  },
  {
    "city": "El Arenal",
    "city_ascii": "El Arenal",
    "lat": 20.2167,
    "lng": -98.9167,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Hidalgo",
    "capital": "",
    "population": 15037,
    "id": 1484425146
  },
  {
    "city": "Grovetown",
    "city_ascii": "Grovetown",
    "lat": 33.4504,
    "lng": -82.2073,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 15152,
    "id": 1840013748
  },
  {
    "city": "Azpeitia",
    "city_ascii": "Azpeitia",
    "lat": 43.1819,
    "lng": -2.2653,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Basque Country",
    "capital": "",
    "population": 14936,
    "id": 1724450790
  },
  {
    "city": "Amlash",
    "city_ascii": "Amlash",
    "lat": 37.0975,
    "lng": 50.1864,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Gīlān",
    "capital": "minor",
    "population": 15047,
    "id": 1364041120
  },
  {
    "city": "Pleasanton",
    "city_ascii": "Pleasanton",
    "lat": 28.9643,
    "lng": -98.4957,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 15148,
    "id": 1840020988
  },
  {
    "city": "Nopala de Villagran",
    "city_ascii": "Nopala de Villagran",
    "lat": 20.2528,
    "lng": -99.6433,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Hidalgo",
    "capital": "",
    "population": 15099,
    "id": 1484987808
  },
  {
    "city": "Great Bend",
    "city_ascii": "Great Bend",
    "lat": 38.3593,
    "lng": -98.8016,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kansas",
    "capital": "",
    "population": 15144,
    "id": 1840001656
  },
  {
    "city": "Provadia",
    "city_ascii": "Provadia",
    "lat": 43.1789,
    "lng": 27.4431,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Varna",
    "capital": "",
    "population": 15095,
    "id": 1100595849
  },
  {
    "city": "Sillamäe",
    "city_ascii": "Sillamae",
    "lat": 59.3931,
    "lng": 27.7742,
    "country": "Estonia",
    "iso2": "EE",
    "iso3": "EST",
    "admin_name": "Ida-Virumaa",
    "capital": "minor",
    "population": 13964,
    "id": 1233128320
  },
  {
    "city": "Fujisaki",
    "city_ascii": "Fujisaki",
    "lat": 40.6561,
    "lng": 140.5025,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Aomori",
    "capital": "",
    "population": 14742,
    "id": 1392919322
  },
  {
    "city": "Ratzeburg",
    "city_ascii": "Ratzeburg",
    "lat": 53.7017,
    "lng": 10.7567,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Schleswig-Holstein",
    "capital": "minor",
    "population": 14652,
    "id": 1276258916
  },
  {
    "city": "Elverum",
    "city_ascii": "Elverum",
    "lat": 60.8833,
    "lng": 11.5667,
    "country": "Norway",
    "iso2": "NO",
    "iso3": "NOR",
    "admin_name": "Hedmark",
    "capital": "minor",
    "population": 15117,
    "id": 1578144824
  },
  {
    "city": "Rāmechhāp",
    "city_ascii": "Ramechhap",
    "lat": 27.326,
    "lng": 86.087,
    "country": "Nepal",
    "iso2": "NP",
    "iso3": "NPL",
    "admin_name": "Janakpur",
    "capital": "",
    "population": 15000,
    "id": 1524628973
  },
  {
    "city": "Burshtyn",
    "city_ascii": "Burshtyn",
    "lat": 49.26,
    "lng": 24.635,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Ivano-Frankivs’ka Oblast’",
    "capital": "",
    "population": 15039,
    "id": 1804941166
  },
  {
    "city": "Teignmouth",
    "city_ascii": "Teignmouth",
    "lat": 50.5515,
    "lng": -3.4886,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Devon",
    "capital": "",
    "population": 15129,
    "id": 1826052083
  },
  {
    "city": "Bhimphedi̇̄",
    "city_ascii": "Bhimphedi",
    "lat": 27.551,
    "lng": 85.13,
    "country": "Nepal",
    "iso2": "NP",
    "iso3": "NPL",
    "admin_name": "Nārāyanī",
    "capital": "",
    "population": 15000,
    "id": 1524596923
  },
  {
    "city": "Bellair-Meadowbrook Terrace",
    "city_ascii": "Bellair-Meadowbrook Terrace",
    "lat": 30.1796,
    "lng": -81.7375,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 15117,
    "id": 1840073845
  },
  {
    "city": "Plainfield",
    "city_ascii": "Plainfield",
    "lat": 41.6992,
    "lng": -71.8987,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 15114,
    "id": 1840045063
  },
  {
    "city": "Râşnov",
    "city_ascii": "Rasnov",
    "lat": 45.5931,
    "lng": 25.4603,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Braşov",
    "capital": "",
    "population": 15022,
    "id": 1642579613
  },
  {
    "city": "Sultandağı",
    "city_ascii": "Sultandagi",
    "lat": 38.5333,
    "lng": 31.2333,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Afyonkarahisar",
    "capital": "minor",
    "population": 15076,
    "id": 1792743781
  },
  {
    "city": "Tul’chyn",
    "city_ascii": "Tul'chyn",
    "lat": 48.6744,
    "lng": 28.8497,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Vinnyts’ka Oblast’",
    "capital": "minor",
    "population": 15051,
    "id": 1804861116
  },
  {
    "city": "Kochkor-Ata",
    "city_ascii": "Kochkor-Ata",
    "lat": 41.0358,
    "lng": 72.4814,
    "country": "Kyrgyzstan",
    "iso2": "KG",
    "iso3": "KGZ",
    "admin_name": "Jalal-Abad",
    "capital": "",
    "population": 14814,
    "id": 1417211654
  },
  {
    "city": "Southchase",
    "city_ascii": "Southchase",
    "lat": 28.3793,
    "lng": -81.3903,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 15096,
    "id": 1840029005
  },
  {
    "city": "Kenora",
    "city_ascii": "Kenora",
    "lat": 49.7667,
    "lng": -94.4833,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 15096,
    "id": 1124844807
  },
  {
    "city": "Damascus",
    "city_ascii": "Damascus",
    "lat": 39.2737,
    "lng": -77.2006,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 15094,
    "id": 1840005832
  },
  {
    "city": "Kök-Janggak",
    "city_ascii": "Kok-Janggak",
    "lat": 41.0307,
    "lng": 73.2058,
    "country": "Kyrgyzstan",
    "iso2": "KG",
    "iso3": "KGZ",
    "admin_name": "Jalal-Abad",
    "capital": "",
    "population": 15000,
    "id": 1417546126
  },
  {
    "city": "Hilchenbach",
    "city_ascii": "Hilchenbach",
    "lat": 50.9983,
    "lng": 8.1094,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 14906,
    "id": 1276321870
  },
  {
    "city": "Punata",
    "city_ascii": "Punata",
    "lat": -17.5469,
    "lng": -65.8367,
    "country": "Bolivia",
    "iso2": "BO",
    "iso3": "BOL",
    "admin_name": "Cochabamba",
    "capital": "",
    "population": 14742,
    "id": 1068639665
  },
  {
    "city": "Skipton",
    "city_ascii": "Skipton",
    "lat": 53.962,
    "lng": -2.017,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "North Yorkshire",
    "capital": "",
    "population": 14623,
    "id": 1826200772
  },
  {
    "city": "Vil’nyans’k",
    "city_ascii": "Vil'nyans'k",
    "lat": 47.9445,
    "lng": 35.4331,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Zaporiz’ka Oblast’",
    "capital": "minor",
    "population": 15044,
    "id": 1804823848
  },
  {
    "city": "Triunfo",
    "city_ascii": "Triunfo",
    "lat": -7.8378,
    "lng": -38.1019,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Pernambuco",
    "capital": "",
    "population": 15006,
    "id": 1076950985
  },
  {
    "city": "Horta",
    "city_ascii": "Horta",
    "lat": 38.5333,
    "lng": -28.6333,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Azores",
    "capital": "",
    "population": 14994,
    "id": 1620589121
  },
  {
    "city": "West Lealman",
    "city_ascii": "West Lealman",
    "lat": 27.8191,
    "lng": -82.7385,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 15076,
    "id": 1840039134
  },
  {
    "city": "Cowdenbeath",
    "city_ascii": "Cowdenbeath",
    "lat": 56.11,
    "lng": -3.35,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Fife",
    "capital": "",
    "population": 14081,
    "id": 1826932190
  },
  {
    "city": "Leninsk",
    "city_ascii": "Leninsk",
    "lat": 48.7,
    "lng": 45.2,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Volgogradskaya Oblast’",
    "capital": "",
    "population": 15064,
    "id": 1643120939
  },
  {
    "city": "West Richland",
    "city_ascii": "West Richland",
    "lat": 46.3115,
    "lng": -119.3998,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 15075,
    "id": 1840021168
  },
  {
    "city": "Alexándreia",
    "city_ascii": "Alexandreia",
    "lat": 40.6283,
    "lng": 22.4454,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Kentrikí Makedonía",
    "capital": "minor",
    "population": 14821,
    "id": 1300981589
  },
  {
    "city": "Vişeu de Sus",
    "city_ascii": "Viseu de Sus",
    "lat": 47.7111,
    "lng": 24.4264,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Maramureş",
    "capital": "",
    "population": 15037,
    "id": 1642452297
  },
  {
    "city": "Ylivieska",
    "city_ascii": "Ylivieska",
    "lat": 64.0722,
    "lng": 24.5375,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Pohjois-Pohjanmaa",
    "capital": "minor",
    "population": 15039,
    "id": 1246367983
  },
  {
    "city": "Cloverly",
    "city_ascii": "Cloverly",
    "lat": 39.1065,
    "lng": -76.9993,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 15062,
    "id": 1840031267
  },
  {
    "city": "Oldsmar",
    "city_ascii": "Oldsmar",
    "lat": 28.0507,
    "lng": -82.6698,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 15061,
    "id": 1840015111
  },
  {
    "city": "Park City",
    "city_ascii": "Park City",
    "lat": 40.6505,
    "lng": -111.502,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Utah",
    "capital": "",
    "population": 15061,
    "id": 1840020140
  },
  {
    "city": "Spitak",
    "city_ascii": "Spitak",
    "lat": 40.8372,
    "lng": 44.2675,
    "country": "Armenia",
    "iso2": "AM",
    "iso3": "ARM",
    "admin_name": "Lorri",
    "capital": "",
    "population": 15000,
    "id": 1051088654
  },
  {
    "city": "Washington Court House",
    "city_ascii": "Washington Court House",
    "lat": 39.5383,
    "lng": -83.4279,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 15059,
    "id": 1840003801
  },
  {
    "city": "Turnov",
    "city_ascii": "Turnov",
    "lat": 50.5873,
    "lng": 15.1569,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Liberecký Kraj",
    "capital": "",
    "population": 14420,
    "id": 1203116739
  },
  {
    "city": "Clifton",
    "city_ascii": "Clifton",
    "lat": 53.9721,
    "lng": -1.0979,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "York",
    "capital": "",
    "population": 13548,
    "id": 1826512283
  },
  {
    "city": "Mizil",
    "city_ascii": "Mizil",
    "lat": 45,
    "lng": 26.4406,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Prahova",
    "capital": "",
    "population": 14312,
    "id": 1642044399
  },
  {
    "city": "Nishinoomote",
    "city_ascii": "Nishinoomote",
    "lat": 30.7325,
    "lng": 130.9975,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Kagoshima",
    "capital": "",
    "population": 14980,
    "id": 1392981409
  },
  {
    "city": "Tabuse",
    "city_ascii": "Tabuse",
    "lat": 33.9547,
    "lng": 132.0414,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Yamaguchi",
    "capital": "",
    "population": 14753,
    "id": 1392416444
  },
  {
    "city": "Upper Southampton",
    "city_ascii": "Upper Southampton",
    "lat": 40.1723,
    "lng": -75.0362,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 15042,
    "id": 1840150058
  },
  {
    "city": "Zapolyarnyy",
    "city_ascii": "Zapolyarnyy",
    "lat": 69.4167,
    "lng": 30.8,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Murmanskaya Oblast’",
    "capital": "",
    "population": 15037,
    "id": 1643432092
  },
  {
    "city": "Morwell",
    "city_ascii": "Morwell",
    "lat": -38.2333,
    "lng": 146.4,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Victoria",
    "capital": "",
    "population": 14026,
    "id": 1036750619
  },
  {
    "city": "Uhingen",
    "city_ascii": "Uhingen",
    "lat": 48.7058,
    "lng": 9.5919,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 14422,
    "id": 1276901108
  },
  {
    "city": "Brooklyn Park",
    "city_ascii": "Brooklyn Park",
    "lat": 39.217,
    "lng": -76.6174,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 15036,
    "id": 1840031312
  },
  {
    "city": "Tierra Blanca",
    "city_ascii": "Tierra Blanca",
    "lat": 21.1011,
    "lng": -100.1578,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Guanajuato",
    "capital": "minor",
    "population": 14515,
    "id": 1484181207
  },
  {
    "city": "Betsukai",
    "city_ascii": "Betsukai",
    "lat": 43.3942,
    "lng": 145.1175,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hokkaidō",
    "capital": "",
    "population": 15018,
    "id": 1392367898
  },
  {
    "city": "Berkovitsa",
    "city_ascii": "Berkovitsa",
    "lat": 43.2361,
    "lng": 23.1258,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Montana",
    "capital": "minor",
    "population": 14993,
    "id": 1100553391
  },
  {
    "city": "Bludenz",
    "city_ascii": "Bludenz",
    "lat": 47.1533,
    "lng": 9.8219,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Vorarlberg",
    "capital": "minor",
    "population": 14539,
    "id": 1040404083
  },
  {
    "city": "Neu-Anspach",
    "city_ascii": "Neu-Anspach",
    "lat": 50.3003,
    "lng": 8.5072,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 14618,
    "id": 1276155891
  },
  {
    "city": "Bad Bramstedt",
    "city_ascii": "Bad Bramstedt",
    "lat": 53.9186,
    "lng": 9.8844,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Schleswig-Holstein",
    "capital": "",
    "population": 14420,
    "id": 1276793883
  },
  {
    "city": "Ibikon",
    "city_ascii": "Ibikon",
    "lat": 47.0839,
    "lng": 8.3431,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Zug",
    "capital": "",
    "population": 13610,
    "id": 1756006757
  },
  {
    "city": "Lahoysk",
    "city_ascii": "Lahoysk",
    "lat": 54.2,
    "lng": 27.85,
    "country": "Belarus",
    "iso2": "BY",
    "iso3": "BLR",
    "admin_name": "Minskaya Voblasts’",
    "capital": "minor",
    "population": 15000,
    "id": 1112489566
  },
  {
    "city": "Depew",
    "city_ascii": "Depew",
    "lat": 42.9117,
    "lng": -78.7044,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 15011,
    "id": 1840004397
  },
  {
    "city": "Chaguarama",
    "city_ascii": "Chaguarama",
    "lat": 9.2159,
    "lng": -63.7523,
    "country": "Venezuela",
    "iso2": "VE",
    "iso3": "VEN",
    "admin_name": "Anzoátegui",
    "capital": "",
    "population": 15000,
    "id": 1862477708
  },
  {
    "city": "Mariestad",
    "city_ascii": "Mariestad",
    "lat": 58.705,
    "lng": 13.828,
    "country": "Sweden",
    "iso2": "SE",
    "iso3": "SWE",
    "admin_name": "Västra Götaland",
    "capital": "minor",
    "population": 14891,
    "id": 1752879298
  },
  {
    "city": "Aghbal",
    "city_ascii": "Aghbal",
    "lat": 34.9382,
    "lng": -2.1455,
    "country": "Morocco",
    "iso2": "MA",
    "iso3": "MAR",
    "admin_name": "Oriental",
    "capital": "",
    "population": 14908,
    "id": 1504000927
  },
  {
    "city": "Mittweida",
    "city_ascii": "Mittweida",
    "lat": 50.9856,
    "lng": 12.9811,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 14645,
    "id": 1276378266
  },
  {
    "city": "Iharan̈a",
    "city_ascii": "Iharana",
    "lat": -13.375,
    "lng": 50.01,
    "country": "Madagascar",
    "iso2": "MG",
    "iso3": "MDG",
    "admin_name": "Antsiranana",
    "capital": "",
    "population": 15000,
    "id": 1450849551
  },
  {
    "city": "Vandalia",
    "city_ascii": "Vandalia",
    "lat": 39.879,
    "lng": -84.193,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 14997,
    "id": 1840003786
  },
  {
    "city": "Auriflama",
    "city_ascii": "Auriflama",
    "lat": -20.6858,
    "lng": -50.555,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 14961,
    "id": 1076210906
  },
  {
    "city": "Mantua",
    "city_ascii": "Mantua",
    "lat": 39.7618,
    "lng": -75.1686,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 14992,
    "id": 1840081677
  },
  {
    "city": "Shlisselburg",
    "city_ascii": "Shlisselburg",
    "lat": 59.95,
    "lng": 31.0333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Leningradskaya Oblast’",
    "capital": "",
    "population": 14763,
    "id": 1643852770
  },
  {
    "city": "Northborough",
    "city_ascii": "Northborough",
    "lat": 42.3231,
    "lng": -71.6462,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 14985,
    "id": 1840053597
  },
  {
    "city": "Detva",
    "city_ascii": "Detva",
    "lat": 48.5572,
    "lng": 19.4208,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Banskobystrický",
    "capital": "minor",
    "population": 14751,
    "id": 1703135182
  },
  {
    "city": "Belchertown",
    "city_ascii": "Belchertown",
    "lat": 42.2787,
    "lng": -72.4004,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 14967,
    "id": 1840053455
  },
  {
    "city": "Ichikawadaimon",
    "city_ascii": "Ichikawadaimon",
    "lat": 35.5653,
    "lng": 138.5022,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Yamanashi",
    "capital": "",
    "population": 14770,
    "id": 1392952559
  },
  {
    "city": "Tsumeb",
    "city_ascii": "Tsumeb",
    "lat": -19.25,
    "lng": 17.7167,
    "country": "Namibia",
    "iso2": "NA",
    "iso3": "NAM",
    "admin_name": "Oshikoto",
    "capital": "",
    "population": 14959,
    "id": 1516619412
  },
  {
    "city": "Serhetabat",
    "city_ascii": "Serhetabat",
    "lat": 35.2833,
    "lng": 62.35,
    "country": "Turkmenistan",
    "iso2": "TM",
    "iso3": "TKM",
    "admin_name": "Mary",
    "capital": "",
    "population": 14940,
    "id": 1795236805
  },
  {
    "city": "Williamstown",
    "city_ascii": "Williamstown",
    "lat": 39.6874,
    "lng": -74.9786,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 14962,
    "id": 1840016785
  },
  {
    "city": "Whitman",
    "city_ascii": "Whitman",
    "lat": 42.08,
    "lng": -70.9399,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 14961,
    "id": 1840053580
  },
  {
    "city": "Cold Lake",
    "city_ascii": "Cold Lake",
    "lat": 54.4642,
    "lng": -110.1825,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Alberta",
    "capital": "",
    "population": 14961,
    "id": 1124089461
  },
  {
    "city": "Boulder City",
    "city_ascii": "Boulder City",
    "lat": 35.8407,
    "lng": -114.9257,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Nevada",
    "capital": "",
    "population": 14959,
    "id": 1840019028
  },
  {
    "city": "Novi Pazar",
    "city_ascii": "Novi Pazar",
    "lat": 43.35,
    "lng": 27.2,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Shumen",
    "capital": "",
    "population": 14931,
    "id": 1100036020
  },
  {
    "city": "Tall Abyaḑ",
    "city_ascii": "Tall Abyad",
    "lat": 36.6975,
    "lng": 38.9567,
    "country": "Syria",
    "iso2": "SY",
    "iso3": "SYR",
    "admin_name": "Ar Raqqah",
    "capital": "minor",
    "population": 14825,
    "id": 1760694879
  },
  {
    "city": "Thompson",
    "city_ascii": "Thompson",
    "lat": 41.6474,
    "lng": -74.6745,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 14946,
    "id": 1840058541
  },
  {
    "city": "Jamestown",
    "city_ascii": "Jamestown",
    "lat": 46.9063,
    "lng": -98.6936,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Dakota",
    "capital": "",
    "population": 14944,
    "id": 1840000153
  },
  {
    "city": "Pallasovka",
    "city_ascii": "Pallasovka",
    "lat": 50.05,
    "lng": 46.8833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Volgogradskaya Oblast’",
    "capital": "",
    "population": 14940,
    "id": 1643361305
  },
  {
    "city": "Hewitt",
    "city_ascii": "Hewitt",
    "lat": 31.452,
    "lng": -97.196,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 14937,
    "id": 1840020809
  },
  {
    "city": "Beech Grove",
    "city_ascii": "Beech Grove",
    "lat": 39.7157,
    "lng": -86.0871,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 14937,
    "id": 1840007307
  },
  {
    "city": "Levoča",
    "city_ascii": "Levoca",
    "lat": 49.0228,
    "lng": 20.5906,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Prešovský",
    "capital": "minor",
    "population": 14803,
    "id": 1703381498
  },
  {
    "city": "Coto de Caza",
    "city_ascii": "Coto de Caza",
    "lat": 33.5961,
    "lng": -117.5862,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 14931,
    "id": 1840028363
  },
  {
    "city": "Tehachapi",
    "city_ascii": "Tehachapi",
    "lat": 35.1274,
    "lng": -118.4749,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 14928,
    "id": 1840021737
  },
  {
    "city": "Buhuşi",
    "city_ascii": "Buhusi",
    "lat": 46.715,
    "lng": 26.7042,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Bacău",
    "capital": "",
    "population": 14562,
    "id": 1642879325
  },
  {
    "city": "Meerane",
    "city_ascii": "Meerane",
    "lat": 50.8519,
    "lng": 12.4636,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 14208,
    "id": 1276923420
  },
  {
    "city": "Sisian",
    "city_ascii": "Sisian",
    "lat": 39.5208,
    "lng": 46.0322,
    "country": "Armenia",
    "iso2": "AM",
    "iso3": "ARM",
    "admin_name": "Syunik’",
    "capital": "",
    "population": 14894,
    "id": 1051297901
  },
  {
    "city": "Nerchinsk",
    "city_ascii": "Nerchinsk",
    "lat": 51.9833,
    "lng": 116.5833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Zabaykal’skiy Kray",
    "capital": "",
    "population": 14919,
    "id": 1643896987
  },
  {
    "city": "Pugachev",
    "city_ascii": "Pugachev",
    "lat": 52.0133,
    "lng": 48.8025,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Saratovskaya Oblast’",
    "capital": "",
    "population": 14908,
    "id": 1643132953
  },
  {
    "city": "Berea",
    "city_ascii": "Berea",
    "lat": 34.8802,
    "lng": -82.4653,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 14923,
    "id": 1840013495
  },
  {
    "city": "Gryazovets",
    "city_ascii": "Gryazovets",
    "lat": 58.8833,
    "lng": 40.25,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Vologodskaya Oblast’",
    "capital": "",
    "population": 14916,
    "id": 1643659896
  },
  {
    "city": "Sukhinichi",
    "city_ascii": "Sukhinichi",
    "lat": 54.1,
    "lng": 35.35,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kaluzhskaya Oblast’",
    "capital": "",
    "population": 14901,
    "id": 1643650021
  },
  {
    "city": "Exeter",
    "city_ascii": "Exeter",
    "lat": 42.9901,
    "lng": -70.9646,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Hampshire",
    "capital": "",
    "population": 14921,
    "id": 1840054838
  },
  {
    "city": "Drahichyn",
    "city_ascii": "Drahichyn",
    "lat": 52.1833,
    "lng": 25.15,
    "country": "Belarus",
    "iso2": "BY",
    "iso3": "BLR",
    "admin_name": "Brestskaya Voblasts’",
    "capital": "",
    "population": 14900,
    "id": 1112244200
  },
  {
    "city": "Radomir",
    "city_ascii": "Radomir",
    "lat": 42.5467,
    "lng": 22.9642,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Pernik",
    "capital": "",
    "population": 14880,
    "id": 1100594345
  },
  {
    "city": "Sulphur Springs",
    "city_ascii": "Sulphur Springs",
    "lat": 33.1421,
    "lng": -95.6124,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 14916,
    "id": 1840022031
  },
  {
    "city": "Astoria",
    "city_ascii": "Astoria",
    "lat": 46.1856,
    "lng": -123.8053,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 14916,
    "id": 1840018504
  },
  {
    "city": "Fritzlar",
    "city_ascii": "Fritzlar",
    "lat": 51.1333,
    "lng": 9.2667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 14744,
    "id": 1276290750
  },
  {
    "city": "Paianía",
    "city_ascii": "Paiania",
    "lat": 37.95,
    "lng": 23.85,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Attikí",
    "capital": "minor",
    "population": 14595,
    "id": 1300888494
  },
  {
    "city": "Kondrovo",
    "city_ascii": "Kondrovo",
    "lat": 54.8064,
    "lng": 35.9278,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kaluzhskaya Oblast’",
    "capital": "",
    "population": 14857,
    "id": 1643237880
  },
  {
    "city": "Kizel",
    "city_ascii": "Kizel",
    "lat": 59.05,
    "lng": 57.65,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Permskiy Kray",
    "capital": "",
    "population": 14883,
    "id": 1643267536
  },
  {
    "city": "Jisr ez Zarqā",
    "city_ascii": "Jisr ez Zarqa",
    "lat": 32.5379,
    "lng": 34.9122,
    "country": "Israel",
    "iso2": "IL",
    "iso3": "ISR",
    "admin_name": "Haifa",
    "capital": "",
    "population": 13962,
    "id": 1376963985
  },
  {
    "city": "Taft",
    "city_ascii": "Taft",
    "lat": 35.1267,
    "lng": -119.4242,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 14898,
    "id": 1840021736
  },
  {
    "city": "Amersham",
    "city_ascii": "Amersham",
    "lat": 51.677,
    "lng": -0.603,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Buckinghamshire",
    "capital": "",
    "population": 14384,
    "id": 1826557635
  },
  {
    "city": "Ferndale",
    "city_ascii": "Ferndale",
    "lat": 48.8525,
    "lng": -122.5893,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 14897,
    "id": 1840019751
  },
  {
    "city": "Namanga",
    "city_ascii": "Namanga",
    "lat": -2.55,
    "lng": 36.7833,
    "country": "Kenya",
    "iso2": "KE",
    "iso3": "KEN",
    "admin_name": "",
    "capital": "",
    "population": 13193,
    "id": 1404000001
  },
  {
    "city": "Livingston",
    "city_ascii": "Livingston",
    "lat": 37.3875,
    "lng": -120.7248,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 14896,
    "id": 1840020311
  },
  {
    "city": "Vrbovec",
    "city_ascii": "Vrbovec",
    "lat": 45.8833,
    "lng": 16.4333,
    "country": "Croatia",
    "iso2": "HR",
    "iso3": "HRV",
    "admin_name": "Zagrebačka Županija",
    "capital": "minor",
    "population": 14797,
    "id": 1191484288
  },
  {
    "city": "Dentsville",
    "city_ascii": "Dentsville",
    "lat": 34.0754,
    "lng": -80.9546,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 14887,
    "id": 1840013089
  },
  {
    "city": "Warragul",
    "city_ascii": "Warragul",
    "lat": -38.15,
    "lng": 145.9333,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Victoria",
    "capital": "",
    "population": 14276,
    "id": 1036504084
  },
  {
    "city": "Franklin Park",
    "city_ascii": "Franklin Park",
    "lat": 40.5903,
    "lng": -80.0999,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 14885,
    "id": 1840001198
  },
  {
    "city": "Ramsey",
    "city_ascii": "Ramsey",
    "lat": 41.0595,
    "lng": -74.1454,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 14884,
    "id": 1840000896
  },
  {
    "city": "New Freedom",
    "city_ascii": "New Freedom",
    "lat": 39.7353,
    "lng": -76.6967,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 14882,
    "id": 1840001457
  },
  {
    "city": "Yankton",
    "city_ascii": "Yankton",
    "lat": 42.8901,
    "lng": -97.3926,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Dakota",
    "capital": "",
    "population": 14872,
    "id": 1840003058
  },
  {
    "city": "London",
    "city_ascii": "London",
    "lat": 39.8936,
    "lng": -83.4376,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 14870,
    "id": 1840000674
  },
  {
    "city": "Oftringen",
    "city_ascii": "Oftringen",
    "lat": 47.315,
    "lng": 7.9236,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Aargau",
    "capital": "",
    "population": 13791,
    "id": 1756569554
  },
  {
    "city": "Rotenburg an der Fulda",
    "city_ascii": "Rotenburg an der Fulda",
    "lat": 50.995,
    "lng": 9.7272,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 14676,
    "id": 1276092129
  },
  {
    "city": "Skippack",
    "city_ascii": "Skippack",
    "lat": 40.2165,
    "lng": -75.419,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 14858,
    "id": 1840005466
  },
  {
    "city": "Ternitz",
    "city_ascii": "Ternitz",
    "lat": 47.7167,
    "lng": 16.0333,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Niederösterreich",
    "capital": "",
    "population": 14632,
    "id": 1040196639
  },
  {
    "city": "Chegdomyn",
    "city_ascii": "Chegdomyn",
    "lat": 51.1178,
    "lng": 133.0241,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Khabarovskiy Kray",
    "capital": "",
    "population": 14855,
    "id": 1643328181
  },
  {
    "city": "Pattensen",
    "city_ascii": "Pattensen",
    "lat": 52.2645,
    "lng": 9.7644,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 14636,
    "id": 1276603378
  },
  {
    "city": "Nioro",
    "city_ascii": "Nioro",
    "lat": 15.2304,
    "lng": -9.59,
    "country": "Mali",
    "iso2": "ML",
    "iso3": "MLI",
    "admin_name": "Kayes",
    "capital": "minor",
    "population": 14421,
    "id": 1466532076
  },
  {
    "city": "Salem Lakes",
    "city_ascii": "Salem Lakes",
    "lat": 42.5366,
    "lng": -88.1307,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 14852,
    "id": 1840038106
  },
  {
    "city": "Rokycany",
    "city_ascii": "Rokycany",
    "lat": 49.7428,
    "lng": 13.5946,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Plzeňský Kraj",
    "capital": "",
    "population": 14383,
    "id": 1203614655
  },
  {
    "city": "Ledyard",
    "city_ascii": "Ledyard",
    "lat": 41.44,
    "lng": -72.0167,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 14850,
    "id": 1840045034
  },
  {
    "city": "Navashino",
    "city_ascii": "Navashino",
    "lat": 55.5333,
    "lng": 42.2,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Nizhegorodskaya Oblast’",
    "capital": "",
    "population": 14830,
    "id": 1643012933
  },
  {
    "city": "Spenge",
    "city_ascii": "Spenge",
    "lat": 52.1331,
    "lng": 8.4831,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 14487,
    "id": 1276387127
  },
  {
    "city": "Jackson",
    "city_ascii": "Jackson",
    "lat": 37.3792,
    "lng": -89.6521,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 14836,
    "id": 1840008735
  },
  {
    "city": "Uryzhar",
    "city_ascii": "Uryzhar",
    "lat": 47.1002,
    "lng": 81.6048,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Shyghys Qazaqstan",
    "capital": "",
    "population": 14826,
    "id": 1398461653
  },
  {
    "city": "Nevel",
    "city_ascii": "Nevel",
    "lat": 56.0167,
    "lng": 29.9333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Pskovskaya Oblast’",
    "capital": "",
    "population": 14819,
    "id": 1643498267
  },
  {
    "city": "Summerside",
    "city_ascii": "Summerside",
    "lat": 46.4,
    "lng": -63.7833,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Prince Edward Island",
    "capital": "",
    "population": 14829,
    "id": 1124487102
  },
  {
    "city": "Löbau",
    "city_ascii": "Lobau",
    "lat": 51.0944,
    "lng": 14.6667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 14643,
    "id": 1276786301
  },
  {
    "city": "Comox",
    "city_ascii": "Comox",
    "lat": 49.6733,
    "lng": -124.9022,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "British Columbia",
    "capital": "",
    "population": 14828,
    "id": 1124788300
  },
  {
    "city": "Karabanovo",
    "city_ascii": "Karabanovo",
    "lat": 56.3089,
    "lng": 38.7014,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Vladimirskaya Oblast’",
    "capital": "",
    "population": 14786,
    "id": 1643955513
  },
  {
    "city": "Wombourn",
    "city_ascii": "Wombourn",
    "lat": 52.5302,
    "lng": -2.1857,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Staffordshire",
    "capital": "",
    "population": 14157,
    "id": 1826116234
  },
  {
    "city": "Azalea Park",
    "city_ascii": "Azalea Park",
    "lat": 28.5473,
    "lng": -81.2956,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 14817,
    "id": 1840014082
  },
  {
    "city": "Yazoo City",
    "city_ascii": "Yazoo City",
    "lat": 32.8618,
    "lng": -90.4067,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Mississippi",
    "capital": "",
    "population": 14817,
    "id": 1840015766
  },
  {
    "city": "Mount Washington",
    "city_ascii": "Mount Washington",
    "lat": 38.043,
    "lng": -85.5549,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kentucky",
    "capital": "",
    "population": 14817,
    "id": 1840014322
  },
  {
    "city": "Sylvan Lake",
    "city_ascii": "Sylvan Lake",
    "lat": 52.3083,
    "lng": -114.0964,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Alberta",
    "capital": "",
    "population": 14816,
    "id": 1124397940
  },
  {
    "city": "Ivanić-Grad",
    "city_ascii": "Ivanic-Grad",
    "lat": 45.7081,
    "lng": 16.3947,
    "country": "Croatia",
    "iso2": "HR",
    "iso3": "HRV",
    "admin_name": "Zagrebačka Županija",
    "capital": "minor",
    "population": 14723,
    "id": 1191909415
  },
  {
    "city": "Grimes",
    "city_ascii": "Grimes",
    "lat": 41.6776,
    "lng": -93.7946,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Iowa",
    "capital": "",
    "population": 14804,
    "id": 1840007068
  },
  {
    "city": "Poděbrady",
    "city_ascii": "Podebrady",
    "lat": 50.1425,
    "lng": 15.1189,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Středočeský Kraj",
    "capital": "",
    "population": 14377,
    "id": 1203251007
  },
  {
    "city": "Willow Grove",
    "city_ascii": "Willow Grove",
    "lat": 40.1495,
    "lng": -75.1178,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 14800,
    "id": 1840005471
  },
  {
    "city": "Dorfen",
    "city_ascii": "Dorfen",
    "lat": 48.2667,
    "lng": 12.15,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 14650,
    "id": 1276442321
  },
  {
    "city": "Klášterec nad Ohří",
    "city_ascii": "Klasterec nad Ohri",
    "lat": 50.3846,
    "lng": 13.1714,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Ústecký Kraj",
    "capital": "",
    "population": 14526,
    "id": 1203315809
  },
  {
    "city": "Lalībela",
    "city_ascii": "Lalibela",
    "lat": 12.0325,
    "lng": 39.0433,
    "country": "Ethiopia",
    "iso2": "ET",
    "iso3": "ETH",
    "admin_name": "Āmara",
    "capital": "",
    "population": 14668,
    "id": 1231020267
  },
  {
    "city": "Morpeth",
    "city_ascii": "Morpeth",
    "lat": 55.1675,
    "lng": -1.6908,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Northumberland",
    "capital": "",
    "population": 14017,
    "id": 1826000022
  },
  {
    "city": "Chaparral",
    "city_ascii": "Chaparral",
    "lat": 32.0442,
    "lng": -106.4061,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Mexico",
    "capital": "",
    "population": 14793,
    "id": 1840018050
  },
  {
    "city": "Guilford",
    "city_ascii": "Guilford",
    "lat": 39.8812,
    "lng": -77.601,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 14793,
    "id": 1840073686
  },
  {
    "city": "Ekenäs",
    "city_ascii": "Ekenas",
    "lat": 59.975,
    "lng": 23.4361,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Uusimaa",
    "capital": "",
    "population": 14784,
    "id": 1246589382
  },
  {
    "city": "Ştefăneşti",
    "city_ascii": "Stefanesti",
    "lat": 44.8717,
    "lng": 24.9527,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Argeş",
    "capital": "",
    "population": 14541,
    "id": 1642962497
  },
  {
    "city": "Victor",
    "city_ascii": "Victor",
    "lat": 42.9894,
    "lng": -77.4277,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 14790,
    "id": 1840004414
  },
  {
    "city": "Lauchhammer",
    "city_ascii": "Lauchhammer",
    "lat": 51.5,
    "lng": 13.8,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Brandenburg",
    "capital": "",
    "population": 14622,
    "id": 1276576746
  },
  {
    "city": "Novi Iskar",
    "city_ascii": "Novi Iskar",
    "lat": 42.8031,
    "lng": 23.3436,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Sofia-Grad",
    "capital": "",
    "population": 13768,
    "id": 1100373780
  },
  {
    "city": "Berettyóújfalu",
    "city_ascii": "Berettyoujfalu",
    "lat": 47.2167,
    "lng": 21.55,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Hajdú-Bihar",
    "capital": "minor",
    "population": 14690,
    "id": 1348328817
  },
  {
    "city": "Lockhart",
    "city_ascii": "Lockhart",
    "lat": 28.6271,
    "lng": -81.4354,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 14775,
    "id": 1840014095
  },
  {
    "city": "Campbellsville",
    "city_ascii": "Campbellsville",
    "lat": 37.3446,
    "lng": -85.3511,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kentucky",
    "capital": "",
    "population": 14772,
    "id": 1840013242
  },
  {
    "city": "Muri",
    "city_ascii": "Muri",
    "lat": 46.9319,
    "lng": 7.4872,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Bern",
    "capital": "",
    "population": 13058,
    "id": 1756349869
  },
  {
    "city": "Tolland",
    "city_ascii": "Tolland",
    "lat": 41.8786,
    "lng": -72.3648,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 14766,
    "id": 1840004762
  },
  {
    "city": "Usingen",
    "city_ascii": "Usingen",
    "lat": 50.334,
    "lng": 8.5372,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 14505,
    "id": 1276509598
  },
  {
    "city": "Broome",
    "city_ascii": "Broome",
    "lat": -17.9619,
    "lng": 122.2361,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Western Australia",
    "capital": "",
    "population": 14445,
    "id": 1036047905
  },
  {
    "city": "Tsivilsk",
    "city_ascii": "Tsivilsk",
    "lat": 55.8667,
    "lng": 47.4833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Chuvashiya",
    "capital": "",
    "population": 14718,
    "id": 1643574991
  },
  {
    "city": "Maqat",
    "city_ascii": "Maqat",
    "lat": 47.65,
    "lng": 53.3167,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Atyraū",
    "capital": "",
    "population": 14756,
    "id": 1398887034
  },
  {
    "city": "Danilov",
    "city_ascii": "Danilov",
    "lat": 58.1833,
    "lng": 40.1833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Yaroslavskaya Oblast’",
    "capital": "",
    "population": 14746,
    "id": 1643198619
  },
  {
    "city": "Swampscott",
    "city_ascii": "Swampscott",
    "lat": 42.4757,
    "lng": -70.9068,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 14755,
    "id": 1840053540
  },
  {
    "city": "San Ramón",
    "city_ascii": "San Ramon",
    "lat": -11.1296,
    "lng": -75.34,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "Junín",
    "capital": "",
    "population": 14708,
    "id": 1604957373
  },
  {
    "city": "Çiçekdağı",
    "city_ascii": "Cicekdagi",
    "lat": 39.6036,
    "lng": 34.4158,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Kırşehir",
    "capital": "minor",
    "population": 14735,
    "id": 1792539032
  },
  {
    "city": "Bedford",
    "city_ascii": "Bedford",
    "lat": 38.8602,
    "lng": -86.4895,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 14752,
    "id": 1840007426
  },
  {
    "city": "Yamada",
    "city_ascii": "Yamada",
    "lat": 39.4675,
    "lng": 141.9489,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Iwate",
    "capital": "",
    "population": 14691,
    "id": 1392896319
  },
  {
    "city": "Tonawanda",
    "city_ascii": "Tonawanda",
    "lat": 43.0105,
    "lng": -78.8805,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 14745,
    "id": 1840004381
  },
  {
    "city": "Clarksdale",
    "city_ascii": "Clarksdale",
    "lat": 34.1971,
    "lng": -90.5729,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Mississippi",
    "capital": "",
    "population": 14745,
    "id": 1840014696
  },
  {
    "city": "Bang Racham",
    "city_ascii": "Bang Racham",
    "lat": 14.8864,
    "lng": 100.3235,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Sing Buri",
    "capital": "minor",
    "population": 14577,
    "id": 1764338966
  },
  {
    "city": "Cambridge",
    "city_ascii": "Cambridge",
    "lat": 40.0221,
    "lng": -81.5868,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 14736,
    "id": 1840007259
  },
  {
    "city": "Bad Wurzach",
    "city_ascii": "Bad Wurzach",
    "lat": 47.9094,
    "lng": 9.8994,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 14651,
    "id": 1276243830
  },
  {
    "city": "Pilisvörösvár",
    "city_ascii": "Pilisvorosvar",
    "lat": 47.6211,
    "lng": 18.9108,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Pest",
    "capital": "minor",
    "population": 14148,
    "id": 1348911831
  },
  {
    "city": "Wymondham",
    "city_ascii": "Wymondham",
    "lat": 52.57,
    "lng": 1.116,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Norfolk",
    "capital": "",
    "population": 14405,
    "id": 1826379714
  },
  {
    "city": "Whitewater",
    "city_ascii": "Whitewater",
    "lat": 42.8372,
    "lng": -88.7341,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 14725,
    "id": 1840002476
  },
  {
    "city": "Shichinohe",
    "city_ascii": "Shichinohe",
    "lat": 40.7447,
    "lng": 141.1578,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Aomori",
    "capital": "",
    "population": 14681,
    "id": 1392716252
  },
  {
    "city": "Tsukawaki",
    "city_ascii": "Tsukawaki",
    "lat": 33.2833,
    "lng": 131.1514,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Ōita",
    "capital": "",
    "population": 14673,
    "id": 1392760554
  },
  {
    "city": "Wschowa",
    "city_ascii": "Wschowa",
    "lat": 51.8,
    "lng": 16.3,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Lubuskie",
    "capital": "minor",
    "population": 14607,
    "id": 1616415919
  },
  {
    "city": "Great Baddow",
    "city_ascii": "Great Baddow",
    "lat": 51.719,
    "lng": 0.507,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Essex",
    "capital": "",
    "population": 14650,
    "id": 1826685396
  },
  {
    "city": "Calera",
    "city_ascii": "Calera",
    "lat": 33.1249,
    "lng": -86.745,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 14717,
    "id": 1840013757
  },
  {
    "city": "Ochakiv",
    "city_ascii": "Ochakiv",
    "lat": 46.6186,
    "lng": 31.5392,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Mykolayivs’ka Oblast’",
    "capital": "minor",
    "population": 14705,
    "id": 1804376560
  },
  {
    "city": "Budakeszi",
    "city_ascii": "Budakeszi",
    "lat": 47.5111,
    "lng": 18.93,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Pest",
    "capital": "minor",
    "population": 14330,
    "id": 1348195369
  },
  {
    "city": "Ashiya",
    "city_ascii": "Ashiya",
    "lat": 33.8939,
    "lng": 130.6639,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Fukuoka",
    "capital": "",
    "population": 13547,
    "id": 1392633502
  },
  {
    "city": "Stein bei Nürnberg",
    "city_ascii": "Stein bei Nurnberg",
    "lat": 49.4167,
    "lng": 11.0167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 13996,
    "id": 1276407895
  },
  {
    "city": "Port Augusta",
    "city_ascii": "Port Augusta",
    "lat": -32.4925,
    "lng": 137.7658,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "South Australia",
    "capital": "",
    "population": 13799,
    "id": 1036945368
  },
  {
    "city": "Youngsville",
    "city_ascii": "Youngsville",
    "lat": 30.0964,
    "lng": -91.9963,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 14704,
    "id": 1840015931
  },
  {
    "city": "Royse City",
    "city_ascii": "Royse City",
    "lat": 32.9762,
    "lng": -96.3175,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 14702,
    "id": 1840020718
  },
  {
    "city": "Ardestān",
    "city_ascii": "Ardestan",
    "lat": 33.3797,
    "lng": 52.3725,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Eşfahān",
    "capital": "minor",
    "population": 14698,
    "id": 1364684540
  },
  {
    "city": "Farnborough",
    "city_ascii": "Farnborough",
    "lat": 51.3591,
    "lng": 0.0741,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Bromley",
    "capital": "",
    "population": 14632,
    "id": 1826247244
  },
  {
    "city": "Versoix",
    "city_ascii": "Versoix",
    "lat": 46.2833,
    "lng": 6.1667,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Genève",
    "capital": "",
    "population": 13422,
    "id": 1756526224
  },
  {
    "city": "Hulyaypole",
    "city_ascii": "Hulyaypole",
    "lat": 47.6644,
    "lng": 36.2632,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Zaporiz’ka Oblast’",
    "capital": "minor",
    "population": 14678,
    "id": 1804759589
  },
  {
    "city": "Pomorie",
    "city_ascii": "Pomorie",
    "lat": 42.5586,
    "lng": 27.6417,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Burgas",
    "capital": "minor",
    "population": 14634,
    "id": 1100639674
  },
  {
    "city": "Lauda-Königshofen",
    "city_ascii": "Lauda-Konigshofen",
    "lat": 49.5686,
    "lng": 9.7039,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 14542,
    "id": 1276018769
  },
  {
    "city": "Hartsville",
    "city_ascii": "Hartsville",
    "lat": 34.3676,
    "lng": -80.0833,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 14693,
    "id": 1840013610
  },
  {
    "city": "Worthington",
    "city_ascii": "Worthington",
    "lat": 40.0949,
    "lng": -83.021,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 14692,
    "id": 1840010522
  },
  {
    "city": "Penrith",
    "city_ascii": "Penrith",
    "lat": -33.7511,
    "lng": 150.6942,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 13295,
    "id": 1036796452
  },
  {
    "city": "Gland",
    "city_ascii": "Gland",
    "lat": 46.4167,
    "lng": 6.2667,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Vaud",
    "capital": "",
    "population": 13106,
    "id": 1756505867
  },
  {
    "city": "Payson",
    "city_ascii": "Payson",
    "lat": 34.2434,
    "lng": -111.3195,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 14684,
    "id": 1840022857
  },
  {
    "city": "Ihtiman",
    "city_ascii": "Ihtiman",
    "lat": 42.4333,
    "lng": 23.8167,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Sofia",
    "capital": "",
    "population": 14656,
    "id": 1100416856
  },
  {
    "city": "Troon",
    "city_ascii": "Troon",
    "lat": 55.54,
    "lng": -4.66,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "South Ayrshire",
    "capital": "",
    "population": 14680,
    "id": 1826943206
  },
  {
    "city": "Nyagatare",
    "city_ascii": "Nyagatare",
    "lat": -1.2947,
    "lng": 30.3272,
    "country": "Rwanda",
    "iso2": "RW",
    "iso3": "RWA",
    "admin_name": "Eastern Province",
    "capital": "",
    "population": 14320,
    "id": 1646508165
  },
  {
    "city": "Mastic",
    "city_ascii": "Mastic",
    "lat": 40.8098,
    "lng": -72.8479,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 14678,
    "id": 1840005090
  },
  {
    "city": "Ottweiler",
    "city_ascii": "Ottweiler",
    "lat": 49.4014,
    "lng": 7.1634,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saarland",
    "capital": "",
    "population": 14358,
    "id": 1276806597
  },
  {
    "city": "Ústí nad Orlicí",
    "city_ascii": "Usti nad Orlici",
    "lat": 49.9739,
    "lng": 16.3937,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Pardubický Kraj",
    "capital": "",
    "population": 14280,
    "id": 1203823971
  },
  {
    "city": "Sanderstead",
    "city_ascii": "Sanderstead",
    "lat": 51.3358,
    "lng": -0.0818,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Croydon",
    "capital": "",
    "population": 12777,
    "id": 1826382700
  },
  {
    "city": "Kawaii",
    "city_ascii": "Kawaii",
    "lat": 38.0044,
    "lng": 140.0458,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Yamagata",
    "capital": "",
    "population": 14582,
    "id": 1392919858
  },
  {
    "city": "Şimleu Silvaniei",
    "city_ascii": "Simleu Silvaniei",
    "lat": 47.23,
    "lng": 22.8,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Sălaj",
    "capital": "",
    "population": 14436,
    "id": 1642211000
  },
  {
    "city": "Abong Mbang",
    "city_ascii": "Abong Mbang",
    "lat": 3.9833,
    "lng": 13.1667,
    "country": "Cameroon",
    "iso2": "CM",
    "iso3": "CMR",
    "admin_name": "Est",
    "capital": "",
    "population": 14661,
    "id": 1120726386
  },
  {
    "city": "Zollikon",
    "city_ascii": "Zollikon",
    "lat": 47.3422,
    "lng": 8.5783,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Zürich",
    "capital": "",
    "population": 13008,
    "id": 1756438023
  },
  {
    "city": "Las Vegas",
    "city_ascii": "Las Vegas",
    "lat": 35.6011,
    "lng": -105.2206,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Mexico",
    "capital": "",
    "population": 14662,
    "id": 1840020397
  },
  {
    "city": "Salida",
    "city_ascii": "Salida",
    "lat": 37.7145,
    "lng": -121.087,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 14658,
    "id": 1840018908
  },
  {
    "city": "Southern Pines",
    "city_ascii": "Southern Pines",
    "lat": 35.1927,
    "lng": -79.404,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 14657,
    "id": 1840017850
  },
  {
    "city": "Gouré",
    "city_ascii": "Goure",
    "lat": 13.9874,
    "lng": 10.27,
    "country": "Niger",
    "iso2": "NE",
    "iso3": "NER",
    "admin_name": "Zinder",
    "capital": "minor",
    "population": 14639,
    "id": 1562482102
  },
  {
    "city": "Djanet",
    "city_ascii": "Djanet",
    "lat": 24.555,
    "lng": 9.4853,
    "country": "Algeria",
    "iso2": "DZ",
    "iso3": "DZA",
    "admin_name": "Illizi",
    "capital": "",
    "population": 14655,
    "id": 1012827195
  },
  {
    "city": "Forster",
    "city_ascii": "Forster",
    "lat": -32.1806,
    "lng": 152.5117,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 13740,
    "id": 1036685795
  },
  {
    "city": "Saraland",
    "city_ascii": "Saraland",
    "lat": 30.8478,
    "lng": -88.1004,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 14649,
    "id": 1840015886
  },
  {
    "city": "Ipauçu",
    "city_ascii": "Ipaucu",
    "lat": -23.0569,
    "lng": -49.6267,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 14579,
    "id": 1076000796
  },
  {
    "city": "El Dorado",
    "city_ascii": "El Dorado",
    "lat": 37.8209,
    "lng": -96.8614,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kansas",
    "capital": "",
    "population": 14648,
    "id": 1840001676
  },
  {
    "city": "Noya",
    "city_ascii": "Noya",
    "lat": 42.785,
    "lng": -8.8878,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Galicia",
    "capital": "",
    "population": 14263,
    "id": 1724537566
  },
  {
    "city": "Dryden",
    "city_ascii": "Dryden",
    "lat": 42.4786,
    "lng": -76.3563,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 14644,
    "id": 1840004547
  },
  {
    "city": "Darłowo",
    "city_ascii": "Darlowo",
    "lat": 54.4167,
    "lng": 16.4167,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Zachodniopomorskie",
    "capital": "",
    "population": 13943,
    "id": 1616699844
  },
  {
    "city": "Manor",
    "city_ascii": "Manor",
    "lat": 30.3562,
    "lng": -97.5228,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 14642,
    "id": 1840020890
  },
  {
    "city": "Chelsfield",
    "city_ascii": "Chelsfield",
    "lat": 51.3582,
    "lng": 0.1278,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Bromley",
    "capital": "",
    "population": 14507,
    "id": 1826005730
  },
  {
    "city": "Jever",
    "city_ascii": "Jever",
    "lat": 53.5744,
    "lng": 7.9008,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "minor",
    "population": 14301,
    "id": 1276105413
  },
  {
    "city": "Eberbach",
    "city_ascii": "Eberbach",
    "lat": 49.4667,
    "lng": 8.9833,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 14460,
    "id": 1276456342
  },
  {
    "city": "Paradise Valley",
    "city_ascii": "Paradise Valley",
    "lat": 33.5434,
    "lng": -111.9595,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 14637,
    "id": 1840022913
  },
  {
    "city": "Radomyshl",
    "city_ascii": "Radomyshl",
    "lat": 50.4947,
    "lng": 29.2333,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Zhytomyrs’ka Oblast’",
    "capital": "minor",
    "population": 14607,
    "id": 1804242306
  },
  {
    "city": "Holliston",
    "city_ascii": "Holliston",
    "lat": 42.1977,
    "lng": -71.445,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 14634,
    "id": 1840053479
  },
  {
    "city": "Pewaukee",
    "city_ascii": "Pewaukee",
    "lat": 43.0701,
    "lng": -88.2411,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 14631,
    "id": 1840033459
  },
  {
    "city": "Lapua",
    "city_ascii": "Lapua",
    "lat": 62.9708,
    "lng": 23.0069,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Etelä-Pohjanmaa",
    "capital": "minor",
    "population": 14609,
    "id": 1246396654
  },
  {
    "city": "Náfplio",
    "city_ascii": "Nafplio",
    "lat": 37.5667,
    "lng": 22.8,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Peloponnísos",
    "capital": "minor",
    "population": 14203,
    "id": 1300744950
  },
  {
    "city": "Heswall",
    "city_ascii": "Heswall",
    "lat": 53.328,
    "lng": -3.099,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Wirral",
    "capital": "",
    "population": 13401,
    "id": 1826689320
  },
  {
    "city": "Urbino",
    "city_ascii": "Urbino",
    "lat": 43.7252,
    "lng": 12.6372,
    "country": "Italy",
    "iso2": "IT",
    "iso3": "ITA",
    "admin_name": "Marche",
    "capital": "",
    "population": 14558,
    "id": 1380375007
  },
  {
    "city": "Priverno",
    "city_ascii": "Priverno",
    "lat": 41.4667,
    "lng": 13.1833,
    "country": "Italy",
    "iso2": "IT",
    "iso3": "ITA",
    "admin_name": "Lazio",
    "capital": "",
    "population": 14365,
    "id": 1380371078
  },
  {
    "city": "North Fayette",
    "city_ascii": "North Fayette",
    "lat": 40.4204,
    "lng": -80.2246,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 14617,
    "id": 1840148209
  },
  {
    "city": "Mocha",
    "city_ascii": "Mocha",
    "lat": 13.3167,
    "lng": 43.25,
    "country": "Yemen",
    "iso2": "YE",
    "iso3": "YEM",
    "admin_name": "Ta‘izz",
    "capital": "minor",
    "population": 14562,
    "id": 1887487308
  },
  {
    "city": "Olsberg",
    "city_ascii": "Olsberg",
    "lat": 51.35,
    "lng": 8.4833,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 14489,
    "id": 1276829513
  },
  {
    "city": "Cadaval",
    "city_ascii": "Cadaval",
    "lat": 39.25,
    "lng": -9.1,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Lisboa",
    "capital": "minor",
    "population": 14525,
    "id": 1620009427
  },
  {
    "city": "Oschatz",
    "city_ascii": "Oschatz",
    "lat": 51.3003,
    "lng": 13.1072,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 14349,
    "id": 1276313153
  },
  {
    "city": "Villa Yapacaní",
    "city_ascii": "Villa Yapacani",
    "lat": -17.4028,
    "lng": -63.885,
    "country": "Bolivia",
    "iso2": "BO",
    "iso3": "BOL",
    "admin_name": "Santa Cruz",
    "capital": "",
    "population": 14589,
    "id": 1068932083
  },
  {
    "city": "Río Branco",
    "city_ascii": "Rio Branco",
    "lat": -32.5969,
    "lng": -53.385,
    "country": "Uruguay",
    "iso2": "UY",
    "iso3": "URY",
    "admin_name": "Cerro Largo",
    "capital": "",
    "population": 14604,
    "id": 1858289927
  },
  {
    "city": "Wilbraham",
    "city_ascii": "Wilbraham",
    "lat": 42.127,
    "lng": -72.4308,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 14604,
    "id": 1840053629
  },
  {
    "city": "Port Orchard",
    "city_ascii": "Port Orchard",
    "lat": 47.5163,
    "lng": -122.661,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 14597,
    "id": 1840019821
  },
  {
    "city": "Mineral Wells",
    "city_ascii": "Mineral Wells",
    "lat": 32.8169,
    "lng": -98.0776,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 14597,
    "id": 1840020689
  },
  {
    "city": "Hlučín",
    "city_ascii": "Hlucin",
    "lat": 49.8979,
    "lng": 18.192,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Moravskoslezský Kraj",
    "capital": "",
    "population": 13931,
    "id": 1203528969
  },
  {
    "city": "Pucioasa",
    "city_ascii": "Pucioasa",
    "lat": 45.0742,
    "lng": 25.4342,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Dâmboviţa",
    "capital": "",
    "population": 14254,
    "id": 1642731261
  },
  {
    "city": "Santa Branca",
    "city_ascii": "Santa Branca",
    "lat": -23.3969,
    "lng": -45.8839,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 14534,
    "id": 1076129261
  },
  {
    "city": "Breza",
    "city_ascii": "Breza",
    "lat": 44.021,
    "lng": 18.261,
    "country": "Bosnia And Herzegovina",
    "iso2": "BA",
    "iso3": "BIH",
    "admin_name": "Bosnia and Herzegovina, Federation of",
    "capital": "minor",
    "population": 14564,
    "id": 1070229008
  },
  {
    "city": "Warwick Township",
    "city_ascii": "Warwick Township",
    "lat": 40.2502,
    "lng": -75.0818,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 14583,
    "id": 1840150057
  },
  {
    "city": "Sun Lakes",
    "city_ascii": "Sun Lakes",
    "lat": 33.2172,
    "lng": -111.8695,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 14582,
    "id": 1840019313
  },
  {
    "city": "Bosobolo",
    "city_ascii": "Bosobolo",
    "lat": 4.1904,
    "lng": 19.88,
    "country": "Congo (Kinshasa)",
    "iso2": "CD",
    "iso3": "COD",
    "admin_name": "Nord-Ubangi",
    "capital": "",
    "population": 14553,
    "id": 1180318544
  },
  {
    "city": "Sakaki",
    "city_ascii": "Sakaki",
    "lat": 36.4619,
    "lng": 138.1803,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Nagano",
    "capital": "",
    "population": 14310,
    "id": 1392071838
  },
  {
    "city": "Muscle Shoals",
    "city_ascii": "Muscle Shoals",
    "lat": 34.7436,
    "lng": -87.6344,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 14575,
    "id": 1840014657
  },
  {
    "city": "Jennings",
    "city_ascii": "Jennings",
    "lat": 38.723,
    "lng": -90.2644,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 14575,
    "id": 1840008596
  },
  {
    "city": "Sandersdorf",
    "city_ascii": "Sandersdorf",
    "lat": 51.6167,
    "lng": 12.2333,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony-Anhalt",
    "capital": "",
    "population": 14398,
    "id": 1276386551
  },
  {
    "city": "Tlayacapan",
    "city_ascii": "Tlayacapan",
    "lat": 18.9556,
    "lng": -98.9811,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Morelos",
    "capital": "minor",
    "population": 14467,
    "id": 1484386110
  },
  {
    "city": "Henderson",
    "city_ascii": "Henderson",
    "lat": 32.1576,
    "lng": -94.796,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 14569,
    "id": 1840020767
  },
  {
    "city": "Boa Esperança do Sul",
    "city_ascii": "Boa Esperanca do Sul",
    "lat": -21.9925,
    "lng": -48.3908,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 14546,
    "id": 1076269444
  },
  {
    "city": "Connersville",
    "city_ascii": "Connersville",
    "lat": 39.6581,
    "lng": -85.141,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 14566,
    "id": 1840007318
  },
  {
    "city": "Neustadt an der Donau",
    "city_ascii": "Neustadt an der Donau",
    "lat": 48.8,
    "lng": 11.7667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 14409,
    "id": 1276348770
  },
  {
    "city": "Maxcanú",
    "city_ascii": "Maxcanu",
    "lat": 20.5833,
    "lng": -89.9833,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Yucatán",
    "capital": "minor",
    "population": 12621,
    "id": 1484052886
  },
  {
    "city": "Goonellabah",
    "city_ascii": "Goonellabah",
    "lat": -28.815,
    "lng": 153.3178,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 12900,
    "id": 1036897159
  },
  {
    "city": "Tsimlyansk",
    "city_ascii": "Tsimlyansk",
    "lat": 47.6467,
    "lng": 42.0947,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Rostovskaya Oblast’",
    "capital": "minor",
    "population": 14528,
    "id": 1643104941
  },
  {
    "city": "Ust’-Ordynskiy",
    "city_ascii": "Ust'-Ordynskiy",
    "lat": 52.833,
    "lng": 104.7,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Irkutskaya Oblast’",
    "capital": "",
    "population": 14538,
    "id": 1643007224
  },
  {
    "city": "Metuchen",
    "city_ascii": "Metuchen",
    "lat": 40.5424,
    "lng": -74.3628,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 14543,
    "id": 1840003633
  },
  {
    "city": "Kisanuki",
    "city_ascii": "Kisanuki",
    "lat": 31.3447,
    "lng": 130.9453,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Kagoshima",
    "capital": "",
    "population": 14495,
    "id": 1392204633
  },
  {
    "city": "Hershey",
    "city_ascii": "Hershey",
    "lat": 40.2806,
    "lng": -76.6458,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 14542,
    "id": 1840005400
  },
  {
    "city": "Washington Township",
    "city_ascii": "Washington Township",
    "lat": 39.7494,
    "lng": -77.558,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 14541,
    "id": 1840146370
  },
  {
    "city": "Kearsley",
    "city_ascii": "Kearsley",
    "lat": 53.53,
    "lng": -2.37,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Bolton",
    "capital": "",
    "population": 14212,
    "id": 1826981144
  },
  {
    "city": "Grain Valley",
    "city_ascii": "Grain Valley",
    "lat": 39.0168,
    "lng": -94.2085,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 14526,
    "id": 1840008531
  },
  {
    "city": "Milnrow",
    "city_ascii": "Milnrow",
    "lat": 53.6101,
    "lng": -2.1111,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Rochdale",
    "capital": "",
    "population": 13061,
    "id": 1826133583
  },
  {
    "city": "Bad Camberg",
    "city_ascii": "Bad Camberg",
    "lat": 50.3,
    "lng": 8.2667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 14263,
    "id": 1276097713
  },
  {
    "city": "Tokoroa",
    "city_ascii": "Tokoroa",
    "lat": -38.22,
    "lng": 175.872,
    "country": "New Zealand",
    "iso2": "NZ",
    "iso3": "NZL",
    "admin_name": "Waikato",
    "capital": "",
    "population": 13650,
    "id": 1554951529
  },
  {
    "city": "Pinhalzinho",
    "city_ascii": "Pinhalzinho",
    "lat": -22.78,
    "lng": -46.57,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 14423,
    "id": 1076934968
  },
  {
    "city": "Mount Dora",
    "city_ascii": "Mount Dora",
    "lat": 28.8143,
    "lng": -81.6344,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 14516,
    "id": 1840015082
  },
  {
    "city": "Langenselbold",
    "city_ascii": "Langenselbold",
    "lat": 50.1833,
    "lng": 9.0333,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 13979,
    "id": 1276686360
  },
  {
    "city": "Wörgl",
    "city_ascii": "Worgl",
    "lat": 47.4831,
    "lng": 12.0664,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Tirol",
    "capital": "",
    "population": 13811,
    "id": 1040228921
  },
  {
    "city": "San Juan",
    "city_ascii": "San Juan",
    "lat": 10.65,
    "lng": -61.45,
    "country": "Trinidad And Tobago",
    "iso2": "TT",
    "iso3": "TTO",
    "admin_name": "San Juan/Laventille",
    "capital": "",
    "population": 13797,
    "id": 1780554728
  },
  {
    "city": "Windham",
    "city_ascii": "Windham",
    "lat": 42.8076,
    "lng": -71.2994,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Hampshire",
    "capital": "",
    "population": 14508,
    "id": 1840055881
  },
  {
    "city": "Orikhiv",
    "city_ascii": "Orikhiv",
    "lat": 47.5677,
    "lng": 35.7849,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Zaporiz’ka Oblast’",
    "capital": "minor",
    "population": 14479,
    "id": 1804862588
  },
  {
    "city": "Abony",
    "city_ascii": "Abony",
    "lat": 47.1892,
    "lng": 20.0053,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Pest",
    "capital": "",
    "population": 14392,
    "id": 1348748527
  },
  {
    "city": "Branchburg",
    "city_ascii": "Branchburg",
    "lat": 40.5629,
    "lng": -74.714,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 14504,
    "id": 1840081705
  },
  {
    "city": "Ceres",
    "city_ascii": "Ceres",
    "lat": -29.8833,
    "lng": -61.95,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Santa Fe",
    "capital": "",
    "population": 14499,
    "id": 1032855291
  },
  {
    "city": "Hola Prystan’",
    "city_ascii": "Hola Prystan'",
    "lat": 46.5167,
    "lng": 32.5167,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Khersons’ka Oblast’",
    "capital": "minor",
    "population": 14479,
    "id": 1804132171
  },
  {
    "city": "Progreso",
    "city_ascii": "Progreso",
    "lat": 32.5842,
    "lng": -115.5842,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Baja California",
    "capital": "",
    "population": 12557,
    "id": 1484104363
  },
  {
    "city": "West Lincoln",
    "city_ascii": "West Lincoln",
    "lat": 43.0667,
    "lng": -79.5667,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 14500,
    "id": 1124001460
  },
  {
    "city": "Krasnyy Kut",
    "city_ascii": "Krasnyy Kut",
    "lat": 50.95,
    "lng": 46.9667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Saratovskaya Oblast’",
    "capital": "",
    "population": 14483,
    "id": 1643654009
  },
  {
    "city": "Alausí",
    "city_ascii": "Alausi",
    "lat": -2.19,
    "lng": -78.85,
    "country": "Ecuador",
    "iso2": "EC",
    "iso3": "ECU",
    "admin_name": "Chimborazo",
    "capital": "",
    "population": 14294,
    "id": 1218578121
  },
  {
    "city": "Bo’ness",
    "city_ascii": "Bo'ness",
    "lat": 56.0168,
    "lng": -3.6089,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Falkirk",
    "capital": "",
    "population": 14490,
    "id": 1826331223
  },
  {
    "city": "Schneeberg",
    "city_ascii": "Schneeberg",
    "lat": 50.5942,
    "lng": 12.6456,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 13894,
    "id": 1276764942
  },
  {
    "city": "Warrington",
    "city_ascii": "Warrington",
    "lat": 30.3821,
    "lng": -87.2944,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 14487,
    "id": 1840013914
  },
  {
    "city": "Fraser",
    "city_ascii": "Fraser",
    "lat": 42.5388,
    "lng": -82.9496,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 14480,
    "id": 1840002421
  },
  {
    "city": "Rovinj",
    "city_ascii": "Rovinj",
    "lat": 45.0833,
    "lng": 13.6333,
    "country": "Croatia",
    "iso2": "HR",
    "iso3": "HRV",
    "admin_name": "Istarska Županija",
    "capital": "minor",
    "population": 14294,
    "id": 1191599469
  },
  {
    "city": "Beekman",
    "city_ascii": "Beekman",
    "lat": 41.6042,
    "lng": -73.6945,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 14473,
    "id": 1840057227
  },
  {
    "city": "Borjomi",
    "city_ascii": "Borjomi",
    "lat": 41.8333,
    "lng": 43.3833,
    "country": "Georgia",
    "iso2": "GE",
    "iso3": "GEO",
    "admin_name": "Samtskhe-Javakheti",
    "capital": "",
    "population": 14445,
    "id": 1268485704
  },
  {
    "city": "Wilsdruff",
    "city_ascii": "Wilsdruff",
    "lat": 51.0522,
    "lng": 13.5383,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 14297,
    "id": 1276738335
  },
  {
    "city": "Ulchin",
    "city_ascii": "Ulchin",
    "lat": 37.002,
    "lng": 129.4003,
    "country": "Korea, South",
    "iso2": "KR",
    "iso3": "KOR",
    "admin_name": "Gyeongbuk",
    "capital": "",
    "population": 14426,
    "id": 1410704555
  },
  {
    "city": "Gernsbach",
    "city_ascii": "Gernsbach",
    "lat": 48.7633,
    "lng": 8.3342,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 14296,
    "id": 1276744650
  },
  {
    "city": "Hartselle",
    "city_ascii": "Hartselle",
    "lat": 34.4391,
    "lng": -86.94,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 14466,
    "id": 1840013593
  },
  {
    "city": "Hot Springs Village",
    "city_ascii": "Hot Springs Village",
    "lat": 34.6568,
    "lng": -92.9644,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arkansas",
    "capital": "",
    "population": 14466,
    "id": 1840013587
  },
  {
    "city": "Matane",
    "city_ascii": "Matane",
    "lat": 48.85,
    "lng": -67.5333,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 14462,
    "id": 1124528318
  },
  {
    "city": "Little Falls",
    "city_ascii": "Little Falls",
    "lat": 40.8762,
    "lng": -74.218,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 14459,
    "id": 1840081807
  },
  {
    "city": "Münsingen",
    "city_ascii": "Munsingen",
    "lat": 48.4128,
    "lng": 9.4953,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 14335,
    "id": 1276411655
  },
  {
    "city": "Fostoria",
    "city_ascii": "Fostoria",
    "lat": 41.1601,
    "lng": -83.412,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 14458,
    "id": 1840000820
  },
  {
    "city": "Tenafly",
    "city_ascii": "Tenafly",
    "lat": 40.9175,
    "lng": -73.9531,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 14453,
    "id": 1840000918
  },
  {
    "city": "Brooks",
    "city_ascii": "Brooks",
    "lat": 50.5642,
    "lng": -111.8989,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Alberta",
    "capital": "",
    "population": 14451,
    "id": 1124093123
  },
  {
    "city": "Royal Kunia",
    "city_ascii": "Royal Kunia",
    "lat": 21.4053,
    "lng": -158.0318,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Hawaii",
    "capital": "",
    "population": 14449,
    "id": 1840137108
  },
  {
    "city": "Immenstadt im Allgäu",
    "city_ascii": "Immenstadt im Allgau",
    "lat": 47.5667,
    "lng": 10.2167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 14271,
    "id": 1276627298
  },
  {
    "city": "Tornesch",
    "city_ascii": "Tornesch",
    "lat": 53.7,
    "lng": 9.7167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Schleswig-Holstein",
    "capital": "",
    "population": 13779,
    "id": 1276106363
  },
  {
    "city": "Sátoraljaújhely",
    "city_ascii": "Satoraljaujhely",
    "lat": 48.3941,
    "lng": 21.6561,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Borsod-Abaúj-Zemplén",
    "capital": "minor",
    "population": 14253,
    "id": 1348874769
  },
  {
    "city": "Sai Buri",
    "city_ascii": "Sai Buri",
    "lat": 6.7012,
    "lng": 101.6181,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Pattani",
    "capital": "minor",
    "population": 14137,
    "id": 1764406670
  },
  {
    "city": "Sassenberg",
    "city_ascii": "Sassenberg",
    "lat": 51.9897,
    "lng": 8.0408,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 14260,
    "id": 1276379002
  },
  {
    "city": "Jefferson Valley-Yorktown",
    "city_ascii": "Jefferson Valley-Yorktown",
    "lat": 41.3179,
    "lng": -73.8007,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 14441,
    "id": 1840073699
  },
  {
    "city": "Polyarnyye Zori",
    "city_ascii": "Polyarnyye Zori",
    "lat": 67.3658,
    "lng": 32.4981,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Murmanskaya Oblast’",
    "capital": "",
    "population": 14421,
    "id": 1643472022
  },
  {
    "city": "Cliftonville",
    "city_ascii": "Cliftonville",
    "lat": 51.3881,
    "lng": 1.4046,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Kent",
    "capital": "",
    "population": 12900,
    "id": 1826914470
  },
  {
    "city": "Ascensión",
    "city_ascii": "Ascension",
    "lat": -15.6996,
    "lng": -63.08,
    "country": "Bolivia",
    "iso2": "BO",
    "iso3": "BOL",
    "admin_name": "Santa Cruz",
    "capital": "",
    "population": 14429,
    "id": 1068811738
  },
  {
    "city": "Shirebrook",
    "city_ascii": "Shirebrook",
    "lat": 53.2048,
    "lng": -1.2197,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Derbyshire",
    "capital": "",
    "population": 13300,
    "id": 1826437553
  },
  {
    "city": "Dippoldiswalde",
    "city_ascii": "Dippoldiswalde",
    "lat": 50.8933,
    "lng": 13.6667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 14293,
    "id": 1276205316
  },
  {
    "city": "Middlewich",
    "city_ascii": "Middlewich",
    "lat": 53.192,
    "lng": -2.443,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Cheshire East",
    "capital": "",
    "population": 13595,
    "id": 1826609580
  },
  {
    "city": "Thibodaux",
    "city_ascii": "Thibodaux",
    "lat": 29.7941,
    "lng": -90.8163,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 14425,
    "id": 1840015946
  },
  {
    "city": "Brandermill",
    "city_ascii": "Brandermill",
    "lat": 37.434,
    "lng": -77.6522,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 14421,
    "id": 1840024784
  },
  {
    "city": "Sainte-Anne-des-Plaines",
    "city_ascii": "Sainte-Anne-des-Plaines",
    "lat": 45.7617,
    "lng": -73.8204,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 14421,
    "id": 1124304532
  },
  {
    "city": "South Venice",
    "city_ascii": "South Venice",
    "lat": 27.0434,
    "lng": -82.4152,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 14419,
    "id": 1840014180
  },
  {
    "city": "Bradley Gardens",
    "city_ascii": "Bradley Gardens",
    "lat": 40.5711,
    "lng": -74.6678,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 14417,
    "id": 1840033232
  },
  {
    "city": "Washington",
    "city_ascii": "Washington",
    "lat": 38.5514,
    "lng": -91.0151,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 14417,
    "id": 1840010770
  },
  {
    "city": "San Andrés Timilpan",
    "city_ascii": "San Andres Timilpan",
    "lat": 19.8667,
    "lng": -99.7333,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "México",
    "capital": "minor",
    "population": 14335,
    "id": 1484996853
  },
  {
    "city": "Port Hedland",
    "city_ascii": "Port Hedland",
    "lat": -20.31,
    "lng": 118.6011,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Western Australia",
    "capital": "",
    "population": 14320,
    "id": 1036509606
  },
  {
    "city": "Manorville",
    "city_ascii": "Manorville",
    "lat": 40.8575,
    "lng": -72.7915,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 14405,
    "id": 1840005088
  },
  {
    "city": "Chudovo",
    "city_ascii": "Chudovo",
    "lat": 59.1281,
    "lng": 31.6592,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Novgorodskaya Oblast’",
    "capital": "",
    "population": 14394,
    "id": 1643696989
  },
  {
    "city": "Hettstedt",
    "city_ascii": "Hettstedt",
    "lat": 51.6333,
    "lng": 11.5,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony-Anhalt",
    "capital": "",
    "population": 14023,
    "id": 1276039853
  },
  {
    "city": "Pazar",
    "city_ascii": "Pazar",
    "lat": 40.2759,
    "lng": 36.2832,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Tokat",
    "capital": "minor",
    "population": 14335,
    "id": 1792303628
  },
  {
    "city": "Det Udom",
    "city_ascii": "Det Udom",
    "lat": 14.906,
    "lng": 105.0784,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Ubon Ratchathani",
    "capital": "minor",
    "population": 14264,
    "id": 1764920064
  },
  {
    "city": "Hanover",
    "city_ascii": "Hanover",
    "lat": 42.1224,
    "lng": -70.8566,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 14397,
    "id": 1840053568
  },
  {
    "city": "Hanover",
    "city_ascii": "Hanover",
    "lat": 40.8197,
    "lng": -74.4287,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 14395,
    "id": 1840081717
  },
  {
    "city": "Robbinsdale",
    "city_ascii": "Robbinsdale",
    "lat": 45.0261,
    "lng": -93.3332,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 14389,
    "id": 1840008931
  },
  {
    "city": "Valday",
    "city_ascii": "Valday",
    "lat": 57.9667,
    "lng": 33.25,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Novgorodskaya Oblast’",
    "capital": "",
    "population": 14379,
    "id": 1643697222
  },
  {
    "city": "Pak Thong Chai",
    "city_ascii": "Pak Thong Chai",
    "lat": 14.7302,
    "lng": 102.0263,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Nakhon Ratchasima",
    "capital": "minor",
    "population": 14272,
    "id": 1764718877
  },
  {
    "city": "North Strabane",
    "city_ascii": "North Strabane",
    "lat": 40.2279,
    "lng": -80.1488,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 14388,
    "id": 1840147984
  },
  {
    "city": "East Bridgewater",
    "city_ascii": "East Bridgewater",
    "lat": 42.0352,
    "lng": -70.9424,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 14386,
    "id": 1840053567
  },
  {
    "city": "Iona",
    "city_ascii": "Iona",
    "lat": 26.516,
    "lng": -81.9601,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 14385,
    "id": 1840028702
  },
  {
    "city": "Clewiston",
    "city_ascii": "Clewiston",
    "lat": 26.7529,
    "lng": -80.9399,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 14383,
    "id": 1840015128
  },
  {
    "city": "Feldkirchen",
    "city_ascii": "Feldkirchen",
    "lat": 46.7236,
    "lng": 14.0919,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Kärnten",
    "capital": "minor",
    "population": 14198,
    "id": 1040318480
  },
  {
    "city": "Zolote",
    "city_ascii": "Zolote",
    "lat": 48.6833,
    "lng": 38.5167,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Luhans’ka Oblast’",
    "capital": "",
    "population": 14376,
    "id": 1804231300
  },
  {
    "city": "Markdorf",
    "city_ascii": "Markdorf",
    "lat": 47.7208,
    "lng": 9.3917,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 14031,
    "id": 1276086137
  },
  {
    "city": "Abadla",
    "city_ascii": "Abadla",
    "lat": 31.0171,
    "lng": -2.7333,
    "country": "Algeria",
    "iso2": "DZ",
    "iso3": "DZA",
    "admin_name": "Béchar",
    "capital": "",
    "population": 14364,
    "id": 1012262770
  },
  {
    "city": "Warren",
    "city_ascii": "Warren",
    "lat": 41.8433,
    "lng": -79.1445,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 14370,
    "id": 1840003334
  },
  {
    "city": "Chippewa Falls",
    "city_ascii": "Chippewa Falls",
    "lat": 44.9358,
    "lng": -91.3902,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 14366,
    "id": 1840002108
  },
  {
    "city": "Lakinsk",
    "city_ascii": "Lakinsk",
    "lat": 56.0169,
    "lng": 39.9494,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Vladimirskaya Oblast’",
    "capital": "",
    "population": 14330,
    "id": 1643547858
  },
  {
    "city": "West Nipissing / Nipissing Ouest",
    "city_ascii": "West Nipissing / Nipissing Ouest",
    "lat": 46.3667,
    "lng": -79.9167,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 14364,
    "id": 1124000026
  },
  {
    "city": "Kadena",
    "city_ascii": "Kadena",
    "lat": 26.3617,
    "lng": 127.7553,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Okinawa",
    "capital": "",
    "population": 13471,
    "id": 1392320952
  },
  {
    "city": "Uslar",
    "city_ascii": "Uslar",
    "lat": 51.6597,
    "lng": 9.6358,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 14236,
    "id": 1276673378
  },
  {
    "city": "Ocean View",
    "city_ascii": "Ocean View",
    "lat": 38.5355,
    "lng": -75.0984,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Delaware",
    "capital": "",
    "population": 14360,
    "id": 1840006082
  },
  {
    "city": "Lebanon",
    "city_ascii": "Lebanon",
    "lat": 37.6717,
    "lng": -92.6603,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 14360,
    "id": 1840008711
  },
  {
    "city": "Ciénega de Flores",
    "city_ascii": "Cienega de Flores",
    "lat": 25.95,
    "lng": -100.1833,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Nuevo León",
    "capital": "minor",
    "population": 14268,
    "id": 1484782533
  },
  {
    "city": "Forest City",
    "city_ascii": "Forest City",
    "lat": 28.6619,
    "lng": -81.4443,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 14357,
    "id": 1840028825
  },
  {
    "city": "Malone",
    "city_ascii": "Malone",
    "lat": 44.7957,
    "lng": -74.2857,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 14354,
    "id": 1840004067
  },
  {
    "city": "Yakhroma",
    "city_ascii": "Yakhroma",
    "lat": 56.2833,
    "lng": 37.4833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Moskovskaya Oblast’",
    "capital": "",
    "population": 14275,
    "id": 1643220847
  },
  {
    "city": "Midlothian",
    "city_ascii": "Midlothian",
    "lat": 41.6254,
    "lng": -87.7243,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 14346,
    "id": 1840011298
  },
  {
    "city": "Estremoz",
    "city_ascii": "Estremoz",
    "lat": 38.8422,
    "lng": -7.5881,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Évora",
    "capital": "minor",
    "population": 14318,
    "id": 1620467967
  },
  {
    "city": "Medvezhyegorsk",
    "city_ascii": "Medvezhyegorsk",
    "lat": 62.9171,
    "lng": 34.4569,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kareliya",
    "capital": "",
    "population": 14340,
    "id": 1643392697
  },
  {
    "city": "Glasgow",
    "city_ascii": "Glasgow",
    "lat": 39.6015,
    "lng": -75.7474,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Delaware",
    "capital": "",
    "population": 14342,
    "id": 1840005571
  },
  {
    "city": "Koundara",
    "city_ascii": "Koundara",
    "lat": 12.48,
    "lng": -13.296,
    "country": "Guinea",
    "iso2": "GN",
    "iso3": "GIN",
    "admin_name": "Boké",
    "capital": "minor",
    "population": 13990,
    "id": 1324267190
  },
  {
    "city": "Coronel Bogado",
    "city_ascii": "Coronel Bogado",
    "lat": -27.17,
    "lng": -56.25,
    "country": "Paraguay",
    "iso2": "PY",
    "iso3": "PRY",
    "admin_name": "Itapúa",
    "capital": "",
    "population": 14297,
    "id": 1600512741
  },
  {
    "city": "Jacksonville",
    "city_ascii": "Jacksonville",
    "lat": 31.9642,
    "lng": -95.2617,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 14337,
    "id": 1840019515
  },
  {
    "city": "Bua Yai",
    "city_ascii": "Bua Yai",
    "lat": 15.5858,
    "lng": 102.4337,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Nakhon Ratchasima",
    "capital": "minor",
    "population": 14168,
    "id": 1764992879
  },
  {
    "city": "Cisnădie",
    "city_ascii": "Cisnadie",
    "lat": 45.7128,
    "lng": 24.1508,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Sibiu",
    "capital": "",
    "population": 14282,
    "id": 1642003986
  },
  {
    "city": "Schwabmünchen",
    "city_ascii": "Schwabmunchen",
    "lat": 48.1789,
    "lng": 10.755,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 14075,
    "id": 1276593802
  },
  {
    "city": "Lebedyn",
    "city_ascii": "Lebedyn",
    "lat": 50.5831,
    "lng": 34.4823,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Sums’ka Oblast’",
    "capital": "minor",
    "population": 14301,
    "id": 1804576509
  },
  {
    "city": "Mountain Home",
    "city_ascii": "Mountain Home",
    "lat": 36.3348,
    "lng": -92.3846,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arkansas",
    "capital": "",
    "population": 14317,
    "id": 1840014452
  },
  {
    "city": "Korneuburg",
    "city_ascii": "Korneuburg",
    "lat": 48.3453,
    "lng": 16.3331,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Niederösterreich",
    "capital": "minor",
    "population": 12986,
    "id": 1040595988
  },
  {
    "city": "Svetlogorsk",
    "city_ascii": "Svetlogorsk",
    "lat": 54.9333,
    "lng": 20.15,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kaliningradskaya Oblast’",
    "capital": "minor",
    "population": 13663,
    "id": 1643734918
  },
  {
    "city": "Sines",
    "city_ascii": "Sines",
    "lat": 37.9536,
    "lng": -8.8712,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Setúbal",
    "capital": "minor",
    "population": 14238,
    "id": 1620898309
  },
  {
    "city": "Frankenberg",
    "city_ascii": "Frankenberg",
    "lat": 50.9108,
    "lng": 13.0378,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 14088,
    "id": 1276762731
  },
  {
    "city": "Camden",
    "city_ascii": "Camden",
    "lat": 34.2565,
    "lng": -80.6087,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 14303,
    "id": 1840013598
  },
  {
    "city": "St. Simons",
    "city_ascii": "St. Simons",
    "lat": 31.1774,
    "lng": -81.3857,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 14302,
    "id": 1840013874
  },
  {
    "city": "Kashin",
    "city_ascii": "Kashin",
    "lat": 57.36,
    "lng": 37.6039,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Tverskaya Oblast’",
    "capital": "",
    "population": 14287,
    "id": 1643719450
  },
  {
    "city": "California",
    "city_ascii": "California",
    "lat": 38.2969,
    "lng": -76.4949,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 14298,
    "id": 1840006228
  },
  {
    "city": "Matiçan",
    "city_ascii": "Matican",
    "lat": 42.6449,
    "lng": 21.1918,
    "country": "Kosovo",
    "iso2": "XK",
    "iso3": "XKS",
    "admin_name": "Prishtinë",
    "capital": "",
    "population": 13876,
    "id": 1901916938
  },
  {
    "city": "Otopeni",
    "city_ascii": "Otopeni",
    "lat": 44.55,
    "lng": 26.07,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Ilfov",
    "capital": "",
    "population": 13861,
    "id": 1642216985
  },
  {
    "city": "Rosemère",
    "city_ascii": "Rosemere",
    "lat": 45.6369,
    "lng": -73.8,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 14294,
    "id": 1124741055
  },
  {
    "city": "Lubsko",
    "city_ascii": "Lubsko",
    "lat": 51.7877,
    "lng": 14.9724,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Lubuskie",
    "capital": "",
    "population": 14182,
    "id": 1616174794
  },
  {
    "city": "Toppenish",
    "city_ascii": "Toppenish",
    "lat": 46.3806,
    "lng": -120.3123,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 14284,
    "id": 1840021151
  },
  {
    "city": "Kouroussa",
    "city_ascii": "Kouroussa",
    "lat": 10.653,
    "lng": -9.892,
    "country": "Guinea",
    "iso2": "GN",
    "iso3": "GIN",
    "admin_name": "Kankan",
    "capital": "minor",
    "population": 14223,
    "id": 1324187341
  },
  {
    "city": "Douglas",
    "city_ascii": "Douglas",
    "lat": 31.5065,
    "lng": -82.8543,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 14283,
    "id": 1840014946
  },
  {
    "city": "Raydah",
    "city_ascii": "Raydah",
    "lat": 15.8233,
    "lng": 44.0386,
    "country": "Yemen",
    "iso2": "YE",
    "iso3": "YEM",
    "admin_name": "‘Amrān",
    "capital": "minor",
    "population": 13971,
    "id": 1887086285
  },
  {
    "city": "Almoloya de Alquisiras",
    "city_ascii": "Almoloya de Alquisiras",
    "lat": 18.85,
    "lng": -99.85,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "México",
    "capital": "minor",
    "population": 14196,
    "id": 1484855096
  },
  {
    "city": "Hampton Bays",
    "city_ascii": "Hampton Bays",
    "lat": 40.8692,
    "lng": -72.5227,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 14280,
    "id": 1840005076
  },
  {
    "city": "Eastham",
    "city_ascii": "Eastham",
    "lat": 53.313,
    "lng": -2.962,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Liverpool",
    "capital": "",
    "population": 13882,
    "id": 1826374388
  },
  {
    "city": "Santiago Ixtayutla",
    "city_ascii": "Santiago Ixtayutla",
    "lat": 16.5667,
    "lng": -97.65,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Oaxaca",
    "capital": "",
    "population": 13041,
    "id": 1484640868
  },
  {
    "city": "Black Forest",
    "city_ascii": "Black Forest",
    "lat": 39.0608,
    "lng": -104.6752,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 14274,
    "id": 1840017562
  },
  {
    "city": "Vinto",
    "city_ascii": "Vinto",
    "lat": -17.3972,
    "lng": -66.3136,
    "country": "Bolivia",
    "iso2": "BO",
    "iso3": "BOL",
    "admin_name": "Cochabamba",
    "capital": "",
    "population": 14180,
    "id": 1068969654
  },
  {
    "city": "Mill Valley",
    "city_ascii": "Mill Valley",
    "lat": 37.9085,
    "lng": -122.5421,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 14259,
    "id": 1840020259
  },
  {
    "city": "Chalfont Saint Peter",
    "city_ascii": "Chalfont Saint Peter",
    "lat": 51.607,
    "lng": -0.556,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Buckinghamshire",
    "capital": "",
    "population": 12766,
    "id": 1826655909
  },
  {
    "city": "Pomfret",
    "city_ascii": "Pomfret",
    "lat": 42.4029,
    "lng": -79.3534,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 14257,
    "id": 1840087818
  },
  {
    "city": "Hollins",
    "city_ascii": "Hollins",
    "lat": 37.3434,
    "lng": -79.9535,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 14257,
    "id": 1840006441
  },
  {
    "city": "Furukawamen",
    "city_ascii": "Furukawamen",
    "lat": 33.2383,
    "lng": 129.6506,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Nagasaki",
    "capital": "",
    "population": 13826,
    "id": 1392778986
  },
  {
    "city": "Quakenbrück",
    "city_ascii": "Quakenbruck",
    "lat": 52.6772,
    "lng": 7.9575,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 13500,
    "id": 1276336549
  },
  {
    "city": "Tamaqua",
    "city_ascii": "Tamaqua",
    "lat": 40.8033,
    "lng": -75.9344,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 14252,
    "id": 1840002780
  },
  {
    "city": "Tarumã",
    "city_ascii": "Taruma",
    "lat": -22.7469,
    "lng": -50.5769,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 14205,
    "id": 1076499806
  },
  {
    "city": "Bickley",
    "city_ascii": "Bickley",
    "lat": 51.4003,
    "lng": 0.0466,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Bromley",
    "capital": "",
    "population": 13904,
    "id": 1826046416
  },
  {
    "city": "Mistassini",
    "city_ascii": "Mistassini",
    "lat": 48.8229,
    "lng": -72.2154,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 14250,
    "id": 1124980171
  },
  {
    "city": "Destin",
    "city_ascii": "Destin",
    "lat": 30.395,
    "lng": -86.4701,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 14247,
    "id": 1840015008
  },
  {
    "city": "Robbinsville",
    "city_ascii": "Robbinsville",
    "lat": 40.222,
    "lng": -74.591,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 14245,
    "id": 1840081653
  },
  {
    "city": "Red Wing",
    "city_ascii": "Red Wing",
    "lat": 44.5816,
    "lng": -92.6036,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 14245,
    "id": 1840008959
  },
  {
    "city": "Amriswil",
    "city_ascii": "Amriswil",
    "lat": 47.5497,
    "lng": 9.3,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Thurgau",
    "capital": "",
    "population": 13534,
    "id": 1756967253
  },
  {
    "city": "Ochër",
    "city_ascii": "Ocher",
    "lat": 57.8833,
    "lng": 54.7167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Permskiy Kray",
    "capital": "",
    "population": 14226,
    "id": 1643072449
  },
  {
    "city": "Kamigōri",
    "city_ascii": "Kamigori",
    "lat": 34.8736,
    "lng": 134.3561,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hyōgo",
    "capital": "",
    "population": 14146,
    "id": 1392000469
  },
  {
    "city": "Kivertsi",
    "city_ascii": "Kivertsi",
    "lat": 50.8331,
    "lng": 25.4614,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Volyns’ka Oblast’",
    "capital": "minor",
    "population": 14195,
    "id": 1804112302
  },
  {
    "city": "Maridi",
    "city_ascii": "Maridi",
    "lat": 4.9151,
    "lng": 29.4769,
    "country": "South Sudan",
    "iso2": "SS",
    "iso3": "SSD",
    "admin_name": "Western Equatoria",
    "capital": "",
    "population": 14224,
    "id": 1728685965
  },
  {
    "city": "Zherdevka",
    "city_ascii": "Zherdevka",
    "lat": 51.8333,
    "lng": 41.4667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Tambovskaya Oblast’",
    "capital": "",
    "population": 14214,
    "id": 1643603288
  },
  {
    "city": "Chodov",
    "city_ascii": "Chodov",
    "lat": 50.2415,
    "lng": 12.7534,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Karlovarský Kraj",
    "capital": "",
    "population": 13300,
    "id": 1203758078
  },
  {
    "city": "Wittstock",
    "city_ascii": "Wittstock",
    "lat": 53.1636,
    "lng": 12.4856,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Brandenburg",
    "capital": "",
    "population": 14198,
    "id": 1276000618
  },
  {
    "city": "Venëv",
    "city_ascii": "Venev",
    "lat": 54.35,
    "lng": 38.2667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Tul’skaya Oblast’",
    "capital": "",
    "population": 14211,
    "id": 1643092677
  },
  {
    "city": "Murrhardt",
    "city_ascii": "Murrhardt",
    "lat": 48.9794,
    "lng": 9.5783,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 14033,
    "id": 1276873434
  },
  {
    "city": "West Boldon",
    "city_ascii": "West Boldon",
    "lat": 54.9447,
    "lng": -1.4409,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "South Tyneside",
    "capital": "",
    "population": 13271,
    "id": 1826523613
  },
  {
    "city": "Aḑ Ḑab‘ah",
    "city_ascii": "Ad Dab`ah",
    "lat": 31.0338,
    "lng": 28.4333,
    "country": "Egypt",
    "iso2": "EG",
    "iso3": "EGY",
    "admin_name": "Maţrūḩ",
    "capital": "",
    "population": 14212,
    "id": 1818525598
  },
  {
    "city": "Püspökladány",
    "city_ascii": "Puspokladany",
    "lat": 47.3197,
    "lng": 21.1139,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Hajdú-Bihar",
    "capital": "minor",
    "population": 14154,
    "id": 1348038932
  },
  {
    "city": "Torquay",
    "city_ascii": "Torquay",
    "lat": -38.3333,
    "lng": 144.3167,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Victoria",
    "capital": "",
    "population": 13258,
    "id": 1036765457
  },
  {
    "city": "Lewisville",
    "city_ascii": "Lewisville",
    "lat": 36.103,
    "lng": -80.4164,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 14228,
    "id": 1840016124
  },
  {
    "city": "Kaoma",
    "city_ascii": "Kaoma",
    "lat": -14.7796,
    "lng": 24.8,
    "country": "Zambia",
    "iso2": "ZM",
    "iso3": "ZMB",
    "admin_name": "Western",
    "capital": "",
    "population": 14212,
    "id": 1894846267
  },
  {
    "city": "Hooksett",
    "city_ascii": "Hooksett",
    "lat": 43.0709,
    "lng": -71.4365,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Hampshire",
    "capital": "",
    "population": 14221,
    "id": 1840055022
  },
  {
    "city": "Bad Ischl",
    "city_ascii": "Bad Ischl",
    "lat": 47.7115,
    "lng": 13.6239,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Oberösterreich",
    "capital": "",
    "population": 14133,
    "id": 1040620508
  },
  {
    "city": "Eppstein",
    "city_ascii": "Eppstein",
    "lat": 50.1401,
    "lng": 8.392,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 13655,
    "id": 1276417820
  },
  {
    "city": "East Wenatchee",
    "city_ascii": "East Wenatchee",
    "lat": 47.4174,
    "lng": -120.2822,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 14219,
    "id": 1840019795
  },
  {
    "city": "Lindenhurst",
    "city_ascii": "Lindenhurst",
    "lat": 42.4175,
    "lng": -88.0257,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 14216,
    "id": 1840011163
  },
  {
    "city": "New Paltz",
    "city_ascii": "New Paltz",
    "lat": 41.7577,
    "lng": -74.0883,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 14214,
    "id": 1840004731
  },
  {
    "city": "Tako",
    "city_ascii": "Tako",
    "lat": 35.7356,
    "lng": 140.4678,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Chiba",
    "capital": "",
    "population": 14021,
    "id": 1392986792
  },
  {
    "city": "Phillipsburg",
    "city_ascii": "Phillipsburg",
    "lat": 40.6895,
    "lng": -75.1821,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 14212,
    "id": 1840000944
  },
  {
    "city": "North Branford",
    "city_ascii": "North Branford",
    "lat": 41.3645,
    "lng": -72.7769,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 14208,
    "id": 1840035511
  },
  {
    "city": "Biatorbágy",
    "city_ascii": "Biatorbagy",
    "lat": 47.4742,
    "lng": 18.8236,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Pest",
    "capital": "",
    "population": 13889,
    "id": 1348104935
  },
  {
    "city": "Covington",
    "city_ascii": "Covington",
    "lat": 33.6049,
    "lng": -83.8465,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 14206,
    "id": 1840014808
  },
  {
    "city": "Piquete",
    "city_ascii": "Piquete",
    "lat": -22.6136,
    "lng": -45.1761,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 14123,
    "id": 1076872108
  },
  {
    "city": "Rincon",
    "city_ascii": "Rincon",
    "lat": 32.2947,
    "lng": -81.2353,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 14201,
    "id": 1840018091
  },
  {
    "city": "Caln",
    "city_ascii": "Caln",
    "lat": 40.0014,
    "lng": -75.7618,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 14198,
    "id": 1840034888
  },
  {
    "city": "Taos",
    "city_ascii": "Taos",
    "lat": 36.3871,
    "lng": -105.5804,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Mexico",
    "capital": "",
    "population": 14196,
    "id": 1840022638
  },
  {
    "city": "Marchtrenk",
    "city_ascii": "Marchtrenk",
    "lat": 48.1917,
    "lng": 14.1106,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Oberösterreich",
    "capital": "",
    "population": 13603,
    "id": 1040791234
  },
  {
    "city": "Glenn Dale",
    "city_ascii": "Glenn Dale",
    "lat": 38.9833,
    "lng": -76.804,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 14191,
    "id": 1840005955
  },
  {
    "city": "Hopatcong",
    "city_ascii": "Hopatcong",
    "lat": 40.9541,
    "lng": -74.6593,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 14186,
    "id": 1840003482
  },
  {
    "city": "Puerto Deseado",
    "city_ascii": "Puerto Deseado",
    "lat": -47.75,
    "lng": -65.9167,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Santa Cruz",
    "capital": "minor",
    "population": 14183,
    "id": 1032537209
  },
  {
    "city": "Náfpaktos",
    "city_ascii": "Nafpaktos",
    "lat": 38.3917,
    "lng": 21.8275,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Dytikí Elláda",
    "capital": "minor",
    "population": 13415,
    "id": 1300883211
  },
  {
    "city": "Hidalgo",
    "city_ascii": "Hidalgo",
    "lat": 26.109,
    "lng": -98.2463,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 14183,
    "id": 1840021023
  },
  {
    "city": "Isny im Allgäu",
    "city_ascii": "Isny im Allgau",
    "lat": 47.6919,
    "lng": 10.0394,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 14018,
    "id": 1276817029
  },
  {
    "city": "Fokino",
    "city_ascii": "Fokino",
    "lat": 53.45,
    "lng": 34.4167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Bryanskaya Oblast’",
    "capital": "",
    "population": 12938,
    "id": 1643057736
  },
  {
    "city": "Cambridge",
    "city_ascii": "Cambridge",
    "lat": 38.5515,
    "lng": -76.0786,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 14178,
    "id": 1840006165
  },
  {
    "city": "Abensberg",
    "city_ascii": "Abensberg",
    "lat": 48.8109,
    "lng": 11.8595,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 13946,
    "id": 1276501504
  },
  {
    "city": "Gardendale",
    "city_ascii": "Gardendale",
    "lat": 33.6678,
    "lng": -86.8069,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 14177,
    "id": 1840002820
  },
  {
    "city": "Antioch",
    "city_ascii": "Antioch",
    "lat": 42.4742,
    "lng": -88.0721,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 14175,
    "id": 1840010115
  },
  {
    "city": "Grand Falls",
    "city_ascii": "Grand Falls",
    "lat": 48.9578,
    "lng": -55.6633,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Newfoundland and Labrador",
    "capital": "",
    "population": 14171,
    "id": 1124068277
  },
  {
    "city": "Kerben",
    "city_ascii": "Kerben",
    "lat": 41.5,
    "lng": 71.75,
    "country": "Kyrgyzstan",
    "iso2": "KG",
    "iso3": "KGZ",
    "admin_name": "Jalal-Abad",
    "capital": "minor",
    "population": 14141,
    "id": 1417321910
  },
  {
    "city": "Zimnicea",
    "city_ascii": "Zimnicea",
    "lat": 43.6539,
    "lng": 25.365,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Teleorman",
    "capital": "",
    "population": 14058,
    "id": 1642900319
  },
  {
    "city": "Grünstadt",
    "city_ascii": "Grunstadt",
    "lat": 49.5692,
    "lng": 8.1681,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Rhineland-Palatinate",
    "capital": "",
    "population": 13422,
    "id": 1276261667
  },
  {
    "city": "Knottingley",
    "city_ascii": "Knottingley",
    "lat": 53.705,
    "lng": -1.249,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Wakefield",
    "capital": "",
    "population": 13710,
    "id": 1826742978
  },
  {
    "city": "Johnson City",
    "city_ascii": "Johnson City",
    "lat": 42.123,
    "lng": -75.9624,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 14161,
    "id": 1840004658
  },
  {
    "city": "Novoulyanovsk",
    "city_ascii": "Novoulyanovsk",
    "lat": 54.15,
    "lng": 48.3833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Ul’yanovskaya Oblast’",
    "capital": "",
    "population": 13990,
    "id": 1643073136
  },
  {
    "city": "Narvik",
    "city_ascii": "Narvik",
    "lat": 68.4363,
    "lng": 17.3983,
    "country": "Norway",
    "iso2": "NO",
    "iso3": "NOR",
    "admin_name": "Nordland",
    "capital": "minor",
    "population": 14148,
    "id": 1578744025
  },
  {
    "city": "Hendersonville",
    "city_ascii": "Hendersonville",
    "lat": 35.3242,
    "lng": -82.4576,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 14157,
    "id": 1840014561
  },
  {
    "city": "Clearview",
    "city_ascii": "Clearview",
    "lat": 44.3981,
    "lng": -80.0742,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 14151,
    "id": 1124000053
  },
  {
    "city": "Seabrook",
    "city_ascii": "Seabrook",
    "lat": 29.5751,
    "lng": -95.0236,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 14149,
    "id": 1840022197
  },
  {
    "city": "Nelas",
    "city_ascii": "Nelas",
    "lat": 40.5167,
    "lng": -7.85,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Viseu",
    "capital": "minor",
    "population": 14037,
    "id": 1620011198
  },
  {
    "city": "Eynesil",
    "city_ascii": "Eynesil",
    "lat": 41.05,
    "lng": 39.1333,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Giresun",
    "capital": "minor",
    "population": 13955,
    "id": 1792625951
  },
  {
    "city": "Crowley",
    "city_ascii": "Crowley",
    "lat": 30.2175,
    "lng": -92.3752,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 14147,
    "id": 1840015041
  },
  {
    "city": "Sauk Rapids",
    "city_ascii": "Sauk Rapids",
    "lat": 45.5981,
    "lng": -94.1539,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 14146,
    "id": 1840008873
  },
  {
    "city": "Taishi",
    "city_ascii": "Taishi",
    "lat": 34.5186,
    "lng": 135.6481,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Ōsaka",
    "capital": "",
    "population": 13213,
    "id": 1392283998
  },
  {
    "city": "Boutilimit",
    "city_ascii": "Boutilimit",
    "lat": 17.5504,
    "lng": -14.7,
    "country": "Mauritania",
    "iso2": "MR",
    "iso3": "MRT",
    "admin_name": "Trarza",
    "capital": "",
    "population": 14142,
    "id": 1478235368
  },
  {
    "city": "Greendale",
    "city_ascii": "Greendale",
    "lat": 42.9371,
    "lng": -88.0018,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 14143,
    "id": 1840003038
  },
  {
    "city": "Lübben",
    "city_ascii": "Lubben",
    "lat": 51.95,
    "lng": 13.9,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Brandenburg",
    "capital": "minor",
    "population": 14024,
    "id": 1276964065
  },
  {
    "city": "Añatuya",
    "city_ascii": "Anatuya",
    "lat": -28.4667,
    "lng": -62.8333,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Santiago del Estero",
    "capital": "minor",
    "population": 14133,
    "id": 1032511298
  },
  {
    "city": "Emerald",
    "city_ascii": "Emerald",
    "lat": -23.5167,
    "lng": 148.15,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Queensland",
    "capital": "",
    "population": 14119,
    "id": 1036124660
  },
  {
    "city": "Cuevas del Almanzora",
    "city_ascii": "Cuevas del Almanzora",
    "lat": 37.2969,
    "lng": -1.8797,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Andalusia",
    "capital": "",
    "population": 14081,
    "id": 1724152622
  },
  {
    "city": "New Franklin",
    "city_ascii": "New Franklin",
    "lat": 40.9525,
    "lng": -81.5838,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 14133,
    "id": 1840008255
  },
  {
    "city": "Vöhringen",
    "city_ascii": "Vohringen",
    "lat": 48.2833,
    "lng": 10.0833,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 13557,
    "id": 1276392768
  },
  {
    "city": "Leuna",
    "city_ascii": "Leuna",
    "lat": 51.3167,
    "lng": 12.0167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony-Anhalt",
    "capital": "",
    "population": 13969,
    "id": 1276318458
  },
  {
    "city": "Chelsea",
    "city_ascii": "Chelsea",
    "lat": 33.3255,
    "lng": -86.6299,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 14126,
    "id": 1840014823
  },
  {
    "city": "Bedford",
    "city_ascii": "Bedford",
    "lat": 42.497,
    "lng": -71.2783,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 14126,
    "id": 1840053633
  },
  {
    "city": "Pineville",
    "city_ascii": "Pineville",
    "lat": 31.3414,
    "lng": -92.4096,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 14122,
    "id": 1840014963
  },
  {
    "city": "Ionia",
    "city_ascii": "Ionia",
    "lat": 42.9772,
    "lng": -85.0727,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 14122,
    "id": 1840003075
  },
  {
    "city": "Grafing bei München",
    "city_ascii": "Grafing bei Munchen",
    "lat": 48.05,
    "lng": 11.9667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 13660,
    "id": 1276799795
  },
  {
    "city": "Sülüktü",
    "city_ascii": "Suluktu",
    "lat": 39.9333,
    "lng": 69.5667,
    "country": "Kyrgyzstan",
    "iso2": "KG",
    "iso3": "KGZ",
    "admin_name": "Batken",
    "capital": "",
    "population": 13378,
    "id": 1417013156
  },
  {
    "city": "Lukoyanov",
    "city_ascii": "Lukoyanov",
    "lat": 55.0383,
    "lng": 44.4978,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Nizhegorodskaya Oblast’",
    "capital": "",
    "population": 14104,
    "id": 1643123561
  },
  {
    "city": "Törökbálint",
    "city_ascii": "Torokbalint",
    "lat": 47.4356,
    "lng": 18.9156,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Pest",
    "capital": "",
    "population": 13655,
    "id": 1348719921
  },
  {
    "city": "Cheadle",
    "city_ascii": "Cheadle",
    "lat": 52.9849,
    "lng": -1.9865,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Staffordshire",
    "capital": "",
    "population": 12165,
    "id": 1826424119
  },
  {
    "city": "Ystrad Mynach",
    "city_ascii": "Ystrad Mynach",
    "lat": 51.6419,
    "lng": -3.2405,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Caerphilly",
    "capital": "",
    "population": 13500,
    "id": 1826498228
  },
  {
    "city": "Patrocínio Paulista",
    "city_ascii": "Patrocinio Paulista",
    "lat": -20.6394,
    "lng": -47.2817,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 14093,
    "id": 1076390439
  },
  {
    "city": "Sweden",
    "city_ascii": "Sweden",
    "lat": 43.1791,
    "lng": -77.9406,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 14115,
    "id": 1840087957
  },
  {
    "city": "Cleveland",
    "city_ascii": "Cleveland",
    "lat": 33.744,
    "lng": -90.7285,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Mississippi",
    "capital": "",
    "population": 14114,
    "id": 1840014748
  },
  {
    "city": "Greensburg",
    "city_ascii": "Greensburg",
    "lat": 40.3113,
    "lng": -79.5444,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 14113,
    "id": 1840001137
  },
  {
    "city": "Bebra",
    "city_ascii": "Bebra",
    "lat": 50.9711,
    "lng": 9.7903,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 13962,
    "id": 1276742603
  },
  {
    "city": "Santiago de Anaya",
    "city_ascii": "Santiago de Anaya",
    "lat": 20.3844,
    "lng": -98.9647,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Hidalgo",
    "capital": "",
    "population": 14066,
    "id": 1484062040
  },
  {
    "city": "Mashpee",
    "city_ascii": "Mashpee",
    "lat": 41.6178,
    "lng": -70.4908,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 14101,
    "id": 1840053502
  },
  {
    "city": "Montabaur",
    "city_ascii": "Montabaur",
    "lat": 50.4375,
    "lng": 7.8258,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Rhineland-Palatinate",
    "capital": "minor",
    "population": 13691,
    "id": 1276982904
  },
  {
    "city": "Harrison",
    "city_ascii": "Harrison",
    "lat": 36.2438,
    "lng": -93.1198,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arkansas",
    "capital": "",
    "population": 14097,
    "id": 1840013327
  },
  {
    "city": "Gouveia",
    "city_ascii": "Gouveia",
    "lat": 40.5,
    "lng": -7.6,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Guarda",
    "capital": "minor",
    "population": 14046,
    "id": 1620922007
  },
  {
    "city": "Chesterton",
    "city_ascii": "Chesterton",
    "lat": 41.5997,
    "lng": -87.0548,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 14088,
    "id": 1840009267
  },
  {
    "city": "Steamboat Springs",
    "city_ascii": "Steamboat Springs",
    "lat": 40.4777,
    "lng": -106.8243,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 14087,
    "id": 1840021374
  },
  {
    "city": "St. Clair",
    "city_ascii": "St. Clair",
    "lat": 42.7833,
    "lng": -82.35,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 14086,
    "id": 1124000228
  },
  {
    "city": "Holzgerlingen",
    "city_ascii": "Holzgerlingen",
    "lat": 48.6392,
    "lng": 9.0108,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 13103,
    "id": 1276703274
  },
  {
    "city": "Mbini",
    "city_ascii": "Mbini",
    "lat": 1.5833,
    "lng": 9.6167,
    "country": "Equatorial Guinea",
    "iso2": "GQ",
    "iso3": "GNQ",
    "admin_name": "Litoral",
    "capital": "",
    "population": 14034,
    "id": 1226662822
  },
  {
    "city": "Kings Park West",
    "city_ascii": "Kings Park West",
    "lat": 38.8151,
    "lng": -77.296,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 14077,
    "id": 1840041769
  },
  {
    "city": "Lomas de Sargentillo",
    "city_ascii": "Lomas de Sargentillo",
    "lat": -1.8833,
    "lng": -80.0833,
    "country": "Ecuador",
    "iso2": "EC",
    "iso3": "ECU",
    "admin_name": "Guayas",
    "capital": "",
    "population": 13775,
    "id": 1218238536
  },
  {
    "city": "Schmölln",
    "city_ascii": "Schmolln",
    "lat": 50.895,
    "lng": 12.3564,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Thuringia",
    "capital": "",
    "population": 13741,
    "id": 1276498002
  },
  {
    "city": "Yamanobe",
    "city_ascii": "Yamanobe",
    "lat": 38.2892,
    "lng": 140.2625,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Yamagata",
    "capital": "",
    "population": 13840,
    "id": 1392439756
  },
  {
    "city": "Elk Plain",
    "city_ascii": "Elk Plain",
    "lat": 47.0448,
    "lng": -122.3671,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 14065,
    "id": 1840037546
  },
  {
    "city": "Mór",
    "city_ascii": "Mor",
    "lat": 47.3717,
    "lng": 18.2086,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Fejér",
    "capital": "minor",
    "population": 13936,
    "id": 1348505276
  },
  {
    "city": "Piney Green",
    "city_ascii": "Piney Green",
    "lat": 34.7498,
    "lng": -77.3208,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 14061,
    "id": 1840013558
  },
  {
    "city": "Callaway",
    "city_ascii": "Callaway",
    "lat": 30.1348,
    "lng": -85.5568,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 14060,
    "id": 1840013958
  },
  {
    "city": "Phalaborwa",
    "city_ascii": "Phalaborwa",
    "lat": -23.9333,
    "lng": 31.1167,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "Limpopo",
    "capital": "",
    "population": 13108,
    "id": 1710768568
  },
  {
    "city": "Kalkar",
    "city_ascii": "Kalkar",
    "lat": 51.7389,
    "lng": 6.2925,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 13902,
    "id": 1276383703
  },
  {
    "city": "Bēylul",
    "city_ascii": "Beylul",
    "lat": 13.2639,
    "lng": 42.3347,
    "country": "Eritrea",
    "iso2": "ER",
    "iso3": "ERI",
    "admin_name": "Debubawī K’eyih Bahrī",
    "capital": "",
    "population": 14055,
    "id": 1232983964
  },
  {
    "city": "Mjölby",
    "city_ascii": "Mjolby",
    "lat": 58.3321,
    "lng": 15.1312,
    "country": "Sweden",
    "iso2": "SE",
    "iso3": "SWE",
    "admin_name": "Östergötland",
    "capital": "minor",
    "population": 13914,
    "id": 1752185099
  },
  {
    "city": "Múggia",
    "city_ascii": "Muggia",
    "lat": 45.6,
    "lng": 13.7667,
    "country": "Italy",
    "iso2": "IT",
    "iso3": "ITA",
    "admin_name": "Friuli-Venezia Giulia",
    "capital": "",
    "population": 13111,
    "id": 1380254049
  },
  {
    "city": "Waianae",
    "city_ascii": "Waianae",
    "lat": 21.4569,
    "lng": -158.1759,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Hawaii",
    "capital": "",
    "population": 14054,
    "id": 1840029552
  },
  {
    "city": "Waunakee",
    "city_ascii": "Waunakee",
    "lat": 43.1829,
    "lng": -89.4445,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 14052,
    "id": 1840002904
  },
  {
    "city": "Fāmenīn",
    "city_ascii": "Famenin",
    "lat": 35.1139,
    "lng": 48.9717,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Hamadān",
    "capital": "minor",
    "population": 14019,
    "id": 1364228108
  },
  {
    "city": "Wixom",
    "city_ascii": "Wixom",
    "lat": 42.5244,
    "lng": -83.5346,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 14049,
    "id": 1840000407
  },
  {
    "city": "Canton",
    "city_ascii": "Canton",
    "lat": 32.5975,
    "lng": -90.0317,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Mississippi",
    "capital": "",
    "population": 14048,
    "id": 1840014872
  },
  {
    "city": "Eggenfelden",
    "city_ascii": "Eggenfelden",
    "lat": 48.4039,
    "lng": 12.7642,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 13736,
    "id": 1276543412
  },
  {
    "city": "Genthin",
    "city_ascii": "Genthin",
    "lat": 52.4,
    "lng": 12.1667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony-Anhalt",
    "capital": "",
    "population": 13985,
    "id": 1276284209
  },
  {
    "city": "Pecos",
    "city_ascii": "Pecos",
    "lat": 31.4046,
    "lng": -103.5057,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 14040,
    "id": 1840020806
  },
  {
    "city": "Hayashima",
    "city_ascii": "Hayashima",
    "lat": 34.6006,
    "lng": 133.8283,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Okayama",
    "capital": "",
    "population": 12409,
    "id": 1392761843
  },
  {
    "city": "Zhabinka",
    "city_ascii": "Zhabinka",
    "lat": 52.2006,
    "lng": 24.0233,
    "country": "Belarus",
    "iso2": "BY",
    "iso3": "BLR",
    "admin_name": "Brestskaya Voblasts’",
    "capital": "minor",
    "population": 14000,
    "id": 1112259690
  },
  {
    "city": "East Norriton",
    "city_ascii": "East Norriton",
    "lat": 40.1506,
    "lng": -75.3364,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 14034,
    "id": 1840133023
  },
  {
    "city": "Bay St. Louis",
    "city_ascii": "Bay St. Louis",
    "lat": 30.3281,
    "lng": -89.3774,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Mississippi",
    "capital": "",
    "population": 14034,
    "id": 1840013951
  },
  {
    "city": "Shawangunk",
    "city_ascii": "Shawangunk",
    "lat": 41.6335,
    "lng": -74.2654,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 14030,
    "id": 1840087908
  },
  {
    "city": "Gisborne",
    "city_ascii": "Gisborne",
    "lat": -37.49,
    "lng": 144.5889,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Victoria",
    "capital": "",
    "population": 13963,
    "id": 1036388639
  },
  {
    "city": "La Entrada",
    "city_ascii": "La Entrada",
    "lat": 15.05,
    "lng": -88.7333,
    "country": "Honduras",
    "iso2": "HN",
    "iso3": "HND",
    "admin_name": "Copán",
    "capital": "",
    "population": 13949,
    "id": 1340911070
  },
  {
    "city": "Trubchevsk",
    "city_ascii": "Trubchevsk",
    "lat": 52.5833,
    "lng": 33.7667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Bryanskaya Oblast’",
    "capital": "",
    "population": 14001,
    "id": 1643481460
  },
  {
    "city": "Havre de Grace",
    "city_ascii": "Havre de Grace",
    "lat": 39.5481,
    "lng": -76.1145,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 14018,
    "id": 1840005667
  },
  {
    "city": "Monroe",
    "city_ascii": "Monroe",
    "lat": 39.4461,
    "lng": -84.3666,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 14015,
    "id": 1840008465
  },
  {
    "city": "Ovidiu",
    "city_ascii": "Ovidiu",
    "lat": 44.27,
    "lng": 28.56,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Constanţa",
    "capital": "",
    "population": 13847,
    "id": 1642244371
  },
  {
    "city": "D'Iberville",
    "city_ascii": "D'Iberville",
    "lat": 30.4709,
    "lng": -88.9011,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Mississippi",
    "capital": "",
    "population": 14012,
    "id": 1840015023
  },
  {
    "city": "Raynham",
    "city_ascii": "Raynham",
    "lat": 41.9312,
    "lng": -71.0436,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 14010,
    "id": 1840053522
  },
  {
    "city": "Glasgow",
    "city_ascii": "Glasgow",
    "lat": 37.0047,
    "lng": -85.9263,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kentucky",
    "capital": "",
    "population": 14010,
    "id": 1840013268
  },
  {
    "city": "Cayce",
    "city_ascii": "Cayce",
    "lat": 33.9458,
    "lng": -81.0433,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 14009,
    "id": 1840014734
  },
  {
    "city": "Effingham",
    "city_ascii": "Effingham",
    "lat": 39.1207,
    "lng": -88.5508,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 14005,
    "id": 1840008545
  },
  {
    "city": "Santaquin",
    "city_ascii": "Santaquin",
    "lat": 39.9711,
    "lng": -111.7937,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Utah",
    "capital": "",
    "population": 14004,
    "id": 1840021396
  },
  {
    "city": "Dubovka",
    "city_ascii": "Dubovka",
    "lat": 49.05,
    "lng": 44.8333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Volgogradskaya Oblast’",
    "capital": "",
    "population": 13988,
    "id": 1643833054
  },
  {
    "city": "Zeven",
    "city_ascii": "Zeven",
    "lat": 53.2969,
    "lng": 9.2789,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 13809,
    "id": 1276616913
  },
  {
    "city": "Wauchula",
    "city_ascii": "Wauchula",
    "lat": 27.5469,
    "lng": -81.8101,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 13992,
    "id": 1840015987
  },
  {
    "city": "New Fairfield",
    "city_ascii": "New Fairfield",
    "lat": 41.488,
    "lng": -73.4882,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 13992,
    "id": 1840035500
  },
  {
    "city": "Canmore",
    "city_ascii": "Canmore",
    "lat": 51.089,
    "lng": -115.359,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Alberta",
    "capital": "",
    "population": 13992,
    "id": 1124688642
  },
  {
    "city": "Dayton",
    "city_ascii": "Dayton",
    "lat": 30.0292,
    "lng": -94.9038,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 13987,
    "id": 1840019599
  },
  {
    "city": "Dallas",
    "city_ascii": "Dallas",
    "lat": 33.9153,
    "lng": -84.8416,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 13981,
    "id": 1840014757
  },
  {
    "city": "Greenlawn",
    "city_ascii": "Greenlawn",
    "lat": 40.863,
    "lng": -73.3642,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 13978,
    "id": 1840005074
  },
  {
    "city": "Wanzleben",
    "city_ascii": "Wanzleben",
    "lat": 52.0667,
    "lng": 11.4333,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony-Anhalt",
    "capital": "",
    "population": 13903,
    "id": 1276538835
  },
  {
    "city": "Cromwell",
    "city_ascii": "Cromwell",
    "lat": 41.6122,
    "lng": -72.6638,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 13973,
    "id": 1840034391
  },
  {
    "city": "Pacaembu",
    "city_ascii": "Pacaembu",
    "lat": -21.5622,
    "lng": -51.2606,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 13961,
    "id": 1076580275
  },
  {
    "city": "Aßlar",
    "city_ascii": "Asslar",
    "lat": 50.5833,
    "lng": 8.4667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 13656,
    "id": 1276224828
  },
  {
    "city": "Kiskőrös",
    "city_ascii": "Kiskoros",
    "lat": 46.6203,
    "lng": 19.2836,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Bács-Kiskun",
    "capital": "minor",
    "population": 13833,
    "id": 1348641106
  },
  {
    "city": "Beacon",
    "city_ascii": "Beacon",
    "lat": 41.5036,
    "lng": -73.9655,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 13968,
    "id": 1840000499
  },
  {
    "city": "Thonotosassa",
    "city_ascii": "Thonotosassa",
    "lat": 28.0465,
    "lng": -82.291,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 13966,
    "id": 1840014154
  },
  {
    "city": "El Sobrante",
    "city_ascii": "El Sobrante",
    "lat": 33.8724,
    "lng": -117.4625,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 13966,
    "id": 1840075804
  },
  {
    "city": "Raymondville",
    "city_ascii": "Raymondville",
    "lat": 26.4759,
    "lng": -97.7769,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 13963,
    "id": 1840021035
  },
  {
    "city": "Dumas",
    "city_ascii": "Dumas",
    "lat": 35.8613,
    "lng": -101.9642,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 13963,
    "id": 1840019086
  },
  {
    "city": "Gonzalez",
    "city_ascii": "Gonzalez",
    "lat": 30.5822,
    "lng": -87.2906,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 13962,
    "id": 1840013910
  },
  {
    "city": "Dennis",
    "city_ascii": "Dennis",
    "lat": 41.7064,
    "lng": -70.1643,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 13962,
    "id": 1840053500
  },
  {
    "city": "Frederick",
    "city_ascii": "Frederick",
    "lat": 40.1089,
    "lng": -104.9694,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 13960,
    "id": 1840021364
  },
  {
    "city": "Artesia",
    "city_ascii": "Artesia",
    "lat": 32.8492,
    "lng": -104.4274,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Mexico",
    "capital": "",
    "population": 13956,
    "id": 1840019443
  },
  {
    "city": "Hobe Sound",
    "city_ascii": "Hobe Sound",
    "lat": 27.0729,
    "lng": -80.1425,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 13953,
    "id": 1840014188
  },
  {
    "city": "North Mankato",
    "city_ascii": "North Mankato",
    "lat": 44.1813,
    "lng": -94.0387,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 13948,
    "id": 1840007896
  },
  {
    "city": "Tucacas",
    "city_ascii": "Tucacas",
    "lat": 10.7978,
    "lng": -68.3175,
    "country": "Venezuela",
    "iso2": "VE",
    "iso3": "VEN",
    "admin_name": "Falcón",
    "capital": "minor",
    "population": 13901,
    "id": 1862663213
  },
  {
    "city": "Lithgow",
    "city_ascii": "Lithgow",
    "lat": -33.4833,
    "lng": 150.15,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 12973,
    "id": 1036591538
  },
  {
    "city": "Oxford",
    "city_ascii": "Oxford",
    "lat": 42.1286,
    "lng": -71.8665,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 13944,
    "id": 1840053601
  },
  {
    "city": "Mar de Ajó",
    "city_ascii": "Mar de Ajo",
    "lat": -36.7162,
    "lng": -56.6766,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires",
    "capital": "",
    "population": 13610,
    "id": 1032066406
  },
  {
    "city": "Maracaí",
    "city_ascii": "Maracai",
    "lat": -22.6106,
    "lng": -50.6672,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 13913,
    "id": 1076001252
  },
  {
    "city": "Orange",
    "city_ascii": "Orange",
    "lat": 41.2827,
    "lng": -73.0272,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 13937,
    "id": 1840035528
  },
  {
    "city": "Glenpool",
    "city_ascii": "Glenpool",
    "lat": 35.9485,
    "lng": -96.0052,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oklahoma",
    "capital": "",
    "population": 13936,
    "id": 1840020384
  },
  {
    "city": "Oil City",
    "city_ascii": "Oil City",
    "lat": 41.4282,
    "lng": -79.7035,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 13935,
    "id": 1840003426
  },
  {
    "city": "Brejo do Cruz",
    "city_ascii": "Brejo do Cruz",
    "lat": -6.3258,
    "lng": -37.4972,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Paraíba",
    "capital": "",
    "population": 13900,
    "id": 1076202028
  },
  {
    "city": "Newhaven",
    "city_ascii": "Newhaven",
    "lat": 50.8,
    "lng": 0.06,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "East Sussex",
    "capital": "",
    "population": 12232,
    "id": 1826390746
  },
  {
    "city": "Nidzica",
    "city_ascii": "Nidzica",
    "lat": 53.3583,
    "lng": 20.425,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Warmińsko-Mazurskie",
    "capital": "minor",
    "population": 13872,
    "id": 1616216829
  },
  {
    "city": "Dalkeith",
    "city_ascii": "Dalkeith",
    "lat": 55.8958,
    "lng": -3.0583,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Midlothian",
    "capital": "",
    "population": 12342,
    "id": 1826152382
  },
  {
    "city": "Deming",
    "city_ascii": "Deming",
    "lat": 32.2631,
    "lng": -107.7525,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Mexico",
    "capital": "",
    "population": 13924,
    "id": 1840019458
  },
  {
    "city": "Gargždai",
    "city_ascii": "Gargzdai",
    "lat": 55.7,
    "lng": 21.4,
    "country": "Lithuania",
    "iso2": "LT",
    "iso3": "LTU",
    "admin_name": "Klaipėda",
    "capital": "",
    "population": 13877,
    "id": 1440368270
  },
  {
    "city": "Big Rapids",
    "city_ascii": "Big Rapids",
    "lat": 43.6992,
    "lng": -85.4806,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 13921,
    "id": 1840002678
  },
  {
    "city": "Zhovkva",
    "city_ascii": "Zhovkva",
    "lat": 50.0667,
    "lng": 23.9667,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "L’vivs’ka Oblast’",
    "capital": "minor",
    "population": 13834,
    "id": 1804176070
  },
  {
    "city": "Saddle Brook",
    "city_ascii": "Saddle Brook",
    "lat": 40.9033,
    "lng": -74.0955,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 13918,
    "id": 1840081802
  },
  {
    "city": "Miami Springs",
    "city_ascii": "Miami Springs",
    "lat": 25.8195,
    "lng": -80.2894,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 13917,
    "id": 1840015152
  },
  {
    "city": "Fulshear",
    "city_ascii": "Fulshear",
    "lat": 29.693,
    "lng": -95.8792,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 13914,
    "id": 1840020946
  },
  {
    "city": "Uxbridge",
    "city_ascii": "Uxbridge",
    "lat": 42.0593,
    "lng": -71.638,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 13903,
    "id": 1840053609
  },
  {
    "city": "Găeşti",
    "city_ascii": "Gaesti",
    "lat": 44.7208,
    "lng": 25.3147,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Dâmboviţa",
    "capital": "",
    "population": 13317,
    "id": 1642744335
  },
  {
    "city": "Ipswich",
    "city_ascii": "Ipswich",
    "lat": 42.6857,
    "lng": -70.8399,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 13901,
    "id": 1840053668
  },
  {
    "city": "Clinton",
    "city_ascii": "Clinton",
    "lat": 42.4119,
    "lng": -71.6888,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 13899,
    "id": 1840053585
  },
  {
    "city": "Allouez",
    "city_ascii": "Allouez",
    "lat": 44.4721,
    "lng": -88.0261,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 13894,
    "id": 1840002346
  },
  {
    "city": "Princeton",
    "city_ascii": "Princeton",
    "lat": 33.1778,
    "lng": -96.5044,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 13894,
    "id": 1840020663
  },
  {
    "city": "Goshen",
    "city_ascii": "Goshen",
    "lat": 41.3817,
    "lng": -74.3498,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 13894,
    "id": 1840004868
  },
  {
    "city": "Colesville",
    "city_ascii": "Colesville",
    "lat": 39.073,
    "lng": -77.0009,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 13894,
    "id": 1840005831
  },
  {
    "city": "Baraboo",
    "city_ascii": "Baraboo",
    "lat": 43.4695,
    "lng": -89.7376,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 13893,
    "id": 1840002720
  },
  {
    "city": "Nesconset",
    "city_ascii": "Nesconset",
    "lat": 40.8467,
    "lng": -73.1522,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 13893,
    "id": 1840005051
  },
  {
    "city": "Gura Humorului",
    "city_ascii": "Gura Humorului",
    "lat": 47.5542,
    "lng": 25.8875,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Suceava",
    "capital": "",
    "population": 13667,
    "id": 1642117970
  },
  {
    "city": "Zhashkiv",
    "city_ascii": "Zhashkiv",
    "lat": 49.25,
    "lng": 30.1,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Cherkas’ka Oblast’",
    "capital": "minor",
    "population": 13853,
    "id": 1804691013
  },
  {
    "city": "North Battleford",
    "city_ascii": "North Battleford",
    "lat": 52.7575,
    "lng": -108.2861,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Saskatchewan",
    "capital": "",
    "population": 13888,
    "id": 1124789635
  },
  {
    "city": "Kauhajoki",
    "city_ascii": "Kauhajoki",
    "lat": 62.4319,
    "lng": 22.1794,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Etelä-Pohjanmaa",
    "capital": "minor",
    "population": 13875,
    "id": 1246101648
  },
  {
    "city": "Philippsburg",
    "city_ascii": "Philippsburg",
    "lat": 49.2369,
    "lng": 8.4547,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 13615,
    "id": 1276006660
  },
  {
    "city": "Erbach",
    "city_ascii": "Erbach",
    "lat": 49.6569,
    "lng": 8.9931,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "minor",
    "population": 13666,
    "id": 1276643067
  },
  {
    "city": "Rustington",
    "city_ascii": "Rustington",
    "lat": 50.8102,
    "lng": -0.5052,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "West Sussex",
    "capital": "",
    "population": 13883,
    "id": 1826971079
  },
  {
    "city": "Fort Campbell North",
    "city_ascii": "Fort Campbell North",
    "lat": 36.6554,
    "lng": -87.4658,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kentucky",
    "capital": "",
    "population": 13882,
    "id": 1840073832
  },
  {
    "city": "Pembroke",
    "city_ascii": "Pembroke",
    "lat": 45.8167,
    "lng": -77.1,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 13882,
    "id": 1124877940
  },
  {
    "city": "Schwaz",
    "city_ascii": "Schwaz",
    "lat": 47.35,
    "lng": 11.7,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Tirol",
    "capital": "minor",
    "population": 13728,
    "id": 1040919527
  },
  {
    "city": "Cahokia",
    "city_ascii": "Cahokia",
    "lat": 38.5649,
    "lng": -90.1792,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 13880,
    "id": 1840010781
  },
  {
    "city": "Oak Island",
    "city_ascii": "Oak Island",
    "lat": 33.9434,
    "lng": -78.1366,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 13878,
    "id": 1840016688
  },
  {
    "city": "Mountain Park",
    "city_ascii": "Mountain Park",
    "lat": 33.8458,
    "lng": -84.1313,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 13877,
    "id": 1840143063
  },
  {
    "city": "Melsungen",
    "city_ascii": "Melsungen",
    "lat": 51.1333,
    "lng": 9.55,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 13659,
    "id": 1276369572
  },
  {
    "city": "Gramercy",
    "city_ascii": "Gramercy",
    "lat": 30.061,
    "lng": -90.6928,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 13873,
    "id": 1840017209
  },
  {
    "city": "Atlantic Beach",
    "city_ascii": "Atlantic Beach",
    "lat": 30.3371,
    "lng": -81.4128,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 13872,
    "id": 1840013956
  },
  {
    "city": "Haßfurt",
    "city_ascii": "Hassfurt",
    "lat": 50.0353,
    "lng": 10.5123,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "minor",
    "population": 13609,
    "id": 1276364933
  },
  {
    "city": "Guapí",
    "city_ascii": "Guapi",
    "lat": 2.5604,
    "lng": -77.86,
    "country": "Colombia",
    "iso2": "CO",
    "iso3": "COL",
    "admin_name": "Cauca",
    "capital": "minor",
    "population": 13853,
    "id": 1170811574
  },
  {
    "city": "Ephrata",
    "city_ascii": "Ephrata",
    "lat": 40.1812,
    "lng": -76.1811,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 13862,
    "id": 1840003717
  },
  {
    "city": "Hutchinson",
    "city_ascii": "Hutchinson",
    "lat": 44.8856,
    "lng": -94.3768,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 13861,
    "id": 1840006756
  },
  {
    "city": "Arcozelo",
    "city_ascii": "Arcozelo",
    "lat": 41.0555,
    "lng": -8.6395,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Porto",
    "capital": "",
    "population": 12393,
    "id": 1620153472
  },
  {
    "city": "Lakeland South",
    "city_ascii": "Lakeland South",
    "lat": 47.2784,
    "lng": -122.283,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 13849,
    "id": 1840074270
  },
  {
    "city": "Couvin",
    "city_ascii": "Couvin",
    "lat": 50.0519,
    "lng": 4.4961,
    "country": "Belgium",
    "iso2": "BE",
    "iso3": "BEL",
    "admin_name": "Wallonia",
    "capital": "",
    "population": 13782,
    "id": 1056064147
  },
  {
    "city": "Waikanae",
    "city_ascii": "Waikanae",
    "lat": -40.876,
    "lng": 175.064,
    "country": "New Zealand",
    "iso2": "NZ",
    "iso3": "NZL",
    "admin_name": "Wellington",
    "capital": "",
    "population": 12100,
    "id": 1554624715
  },
  {
    "city": "Zacualpan",
    "city_ascii": "Zacualpan",
    "lat": 18.7197,
    "lng": -99.78,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "México",
    "capital": "minor",
    "population": 13800,
    "id": 1484694794
  },
  {
    "city": "Zábřeh",
    "city_ascii": "Zabreh",
    "lat": 49.8826,
    "lng": 16.8723,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Olomoucký Kraj",
    "capital": "",
    "population": 13456,
    "id": 1203721653
  },
  {
    "city": "Horodyshche",
    "city_ascii": "Horodyshche",
    "lat": 49.2925,
    "lng": 31.4581,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Cherkas’ka Oblast’",
    "capital": "minor",
    "population": 13799,
    "id": 1804360634
  },
  {
    "city": "Salisbury Township",
    "city_ascii": "Salisbury Township",
    "lat": 40.5768,
    "lng": -75.4535,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 13843,
    "id": 1840148585
  },
  {
    "city": "Apostolove",
    "city_ascii": "Apostolove",
    "lat": 47.6595,
    "lng": 33.717,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Dnipropetrovs’ka Oblast’",
    "capital": "minor",
    "population": 13792,
    "id": 1804423281
  },
  {
    "city": "Richmond Hill",
    "city_ascii": "Richmond Hill",
    "lat": 31.9013,
    "lng": -81.3125,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 13839,
    "id": 1840014913
  },
  {
    "city": "Jericho",
    "city_ascii": "Jericho",
    "lat": 40.7875,
    "lng": -73.5416,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 13827,
    "id": 1840005268
  },
  {
    "city": "Lamas",
    "city_ascii": "Lamas",
    "lat": -6.4167,
    "lng": -76.5333,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "San Martín",
    "capital": "",
    "population": 13693,
    "id": 1604035883
  },
  {
    "city": "Lich",
    "city_ascii": "Lich",
    "lat": 50.5217,
    "lng": 8.8208,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 13650,
    "id": 1276029431
  },
  {
    "city": "Ak-Dovurak",
    "city_ascii": "Ak-Dovurak",
    "lat": 51.1667,
    "lng": 90.6,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Tyva",
    "capital": "",
    "population": 13580,
    "id": 1643785448
  },
  {
    "city": "Great Driffield",
    "city_ascii": "Great Driffield",
    "lat": 54.005,
    "lng": -0.438,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "East Riding of Yorkshire",
    "capital": "",
    "population": 13080,
    "id": 1826244451
  },
  {
    "city": "Bridgetown",
    "city_ascii": "Bridgetown",
    "lat": 39.1551,
    "lng": -84.6359,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 13824,
    "id": 1840034100
  },
  {
    "city": "Rutherford",
    "city_ascii": "Rutherford",
    "lat": -32.715,
    "lng": 151.5317,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 11884,
    "id": 1036471917
  },
  {
    "city": "Teays Valley",
    "city_ascii": "Teays Valley",
    "lat": 38.4482,
    "lng": -81.924,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "West Virginia",
    "capital": "",
    "population": 13816,
    "id": 1840038312
  },
  {
    "city": "Eichstätt",
    "city_ascii": "Eichstatt",
    "lat": 48.8919,
    "lng": 11.1839,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "minor",
    "population": 13525,
    "id": 1276205721
  },
  {
    "city": "Melenki",
    "city_ascii": "Melenki",
    "lat": 55.3333,
    "lng": 41.6333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Vladimirskaya Oblast’",
    "capital": "",
    "population": 13789,
    "id": 1643886318
  },
  {
    "city": "Spring Creek",
    "city_ascii": "Spring Creek",
    "lat": 40.7386,
    "lng": -115.5971,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Nevada",
    "capital": "",
    "population": 13805,
    "id": 1840018704
  },
  {
    "city": "Mwinilunga",
    "city_ascii": "Mwinilunga",
    "lat": -11.7396,
    "lng": 24.44,
    "country": "Zambia",
    "iso2": "ZM",
    "iso3": "ZMB",
    "admin_name": "North-Western",
    "capital": "",
    "population": 13798,
    "id": 1894210586
  },
  {
    "city": "Neckargemünd",
    "city_ascii": "Neckargemund",
    "lat": 49.3939,
    "lng": 8.7975,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 13290,
    "id": 1276103353
  },
  {
    "city": "Zarghūn Shahr",
    "city_ascii": "Zarghun Shahr",
    "lat": 32.85,
    "lng": 68.4167,
    "country": "Afghanistan",
    "iso2": "AF",
    "iso3": "AFG",
    "admin_name": "Paktīkā",
    "capital": "",
    "population": 13737,
    "id": 1004965319
  },
  {
    "city": "Moorestown-Lenola",
    "city_ascii": "Moorestown-Lenola",
    "lat": 39.9659,
    "lng": -74.9643,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 13800,
    "id": 1840035113
  },
  {
    "city": "Jindayris",
    "city_ascii": "Jindayris",
    "lat": 36.3947,
    "lng": 36.6889,
    "country": "Syria",
    "iso2": "SY",
    "iso3": "SYR",
    "admin_name": "Ḩalab",
    "capital": "minor",
    "population": 13661,
    "id": 1760063267
  },
  {
    "city": "Lauterbach",
    "city_ascii": "Lauterbach",
    "lat": 50.6378,
    "lng": 9.3944,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "minor",
    "population": 13664,
    "id": 1276435243
  },
  {
    "city": "Spaichingen",
    "city_ascii": "Spaichingen",
    "lat": 48.0758,
    "lng": 8.7378,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 13084,
    "id": 1276488091
  },
  {
    "city": "Aurora",
    "city_ascii": "Aurora",
    "lat": 42.7382,
    "lng": -78.6373,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 13788,
    "id": 1840150816
  },
  {
    "city": "Angermünde",
    "city_ascii": "Angermunde",
    "lat": 53.0333,
    "lng": 14,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Brandenburg",
    "capital": "",
    "population": 13744,
    "id": 1276588063
  },
  {
    "city": "Ampthill",
    "city_ascii": "Ampthill",
    "lat": 52.0263,
    "lng": -0.4906,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Central Bedfordshire",
    "capital": "",
    "population": 13307,
    "id": 1826072891
  },
  {
    "city": "Wake",
    "city_ascii": "Wake",
    "lat": 34.8028,
    "lng": 134.1575,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Okayama",
    "capital": "",
    "population": 13690,
    "id": 1392389367
  },
  {
    "city": "Selston",
    "city_ascii": "Selston",
    "lat": 53.07,
    "lng": -1.3,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Nottinghamshire",
    "capital": "",
    "population": 12596,
    "id": 1826087105
  },
  {
    "city": "Kupino",
    "city_ascii": "Kupino",
    "lat": 54.3667,
    "lng": 77.3,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Novosibirskaya Oblast’",
    "capital": "",
    "population": 13777,
    "id": 1643059736
  },
  {
    "city": "Pondūru",
    "city_ascii": "Ponduru",
    "lat": 18.3508,
    "lng": 83.7567,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Andhra Pradesh",
    "capital": "",
    "population": 12640,
    "id": 1356187054
  },
  {
    "city": "Summerfield",
    "city_ascii": "Summerfield",
    "lat": 38.9042,
    "lng": -76.8678,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 13781,
    "id": 1840039531
  },
  {
    "city": "Fruitville",
    "city_ascii": "Fruitville",
    "lat": 27.3328,
    "lng": -82.4616,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 13780,
    "id": 1840014179
  },
  {
    "city": "Mont-Laurier",
    "city_ascii": "Mont-Laurier",
    "lat": 46.55,
    "lng": -75.5,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 13779,
    "id": 1124355399
  },
  {
    "city": "Münchenstein",
    "city_ascii": "Munchenstein",
    "lat": 47.5186,
    "lng": 7.6174,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Basel-Landschaft",
    "capital": "",
    "population": 12096,
    "id": 1756012839
  },
  {
    "city": "Forres",
    "city_ascii": "Forres",
    "lat": 57.608,
    "lng": -3.62,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Moray",
    "capital": "",
    "population": 12587,
    "id": 1826092519
  },
  {
    "city": "Uzwil",
    "city_ascii": "Uzwil",
    "lat": 47.45,
    "lng": 9.1333,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Sankt Gallen",
    "capital": "",
    "population": 12885,
    "id": 1756713626
  },
  {
    "city": "Hilpoltstein",
    "city_ascii": "Hilpoltstein",
    "lat": 49.1833,
    "lng": 11.1833,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 13624,
    "id": 1276036036
  },
  {
    "city": "La Quiaca",
    "city_ascii": "La Quiaca",
    "lat": -22.1056,
    "lng": -65.6,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Jujuy",
    "capital": "minor",
    "population": 13761,
    "id": 1032628832
  },
  {
    "city": "Conneaut",
    "city_ascii": "Conneaut",
    "lat": 41.9278,
    "lng": -80.5685,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 13767,
    "id": 1840003337
  },
  {
    "city": "Biedenkopf",
    "city_ascii": "Biedenkopf",
    "lat": 50.9128,
    "lng": 8.5322,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 13614,
    "id": 1276705045
  },
  {
    "city": "West Freehold",
    "city_ascii": "West Freehold",
    "lat": 40.2324,
    "lng": -74.2943,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 13759,
    "id": 1840005454
  },
  {
    "city": "Thomaston",
    "city_ascii": "Thomaston",
    "lat": 32.8908,
    "lng": -84.3271,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 13759,
    "id": 1840015776
  },
  {
    "city": "Otley",
    "city_ascii": "Otley",
    "lat": 53.905,
    "lng": -1.687,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Leeds",
    "capital": "",
    "population": 13668,
    "id": 1826254599
  },
  {
    "city": "Strathmore",
    "city_ascii": "Strathmore",
    "lat": 51.0378,
    "lng": -113.4003,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Alberta",
    "capital": "",
    "population": 13756,
    "id": 1124000881
  },
  {
    "city": "Levelland",
    "city_ascii": "Levelland",
    "lat": 33.5806,
    "lng": -102.3636,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 13755,
    "id": 1840020606
  },
  {
    "city": "Bandarbeyla",
    "city_ascii": "Bandarbeyla",
    "lat": 9.4833,
    "lng": 50.8167,
    "country": "Somalia",
    "iso2": "SO",
    "iso3": "SOM",
    "admin_name": "Bari",
    "capital": "",
    "population": 13753,
    "id": 1706279652
  },
  {
    "city": "Nether Providence",
    "city_ascii": "Nether Providence",
    "lat": 39.8971,
    "lng": -75.3697,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 13754,
    "id": 1840152840
  },
  {
    "city": "Cherry Hill Mall",
    "city_ascii": "Cherry Hill Mall",
    "lat": 39.9384,
    "lng": -75.0117,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 13752,
    "id": 1840033469
  },
  {
    "city": "Mbulu",
    "city_ascii": "Mbulu",
    "lat": -3.8496,
    "lng": 35.53,
    "country": "Tanzania",
    "iso2": "TZ",
    "iso3": "TZA",
    "admin_name": "Manyara",
    "capital": "",
    "population": 13430,
    "id": 1834497748
  },
  {
    "city": "Grünberg",
    "city_ascii": "Grunberg",
    "lat": 50.6,
    "lng": 8.95,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 13598,
    "id": 1276207612
  },
  {
    "city": "Palmetto",
    "city_ascii": "Palmetto",
    "lat": 27.5251,
    "lng": -82.575,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 13748,
    "id": 1840015116
  },
  {
    "city": "Caledon",
    "city_ascii": "Caledon",
    "lat": -34.23,
    "lng": 19.4283,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "Western Cape",
    "capital": "",
    "population": 13020,
    "id": 1710038887
  },
  {
    "city": "Barcelos",
    "city_ascii": "Barcelos",
    "lat": -0.9736,
    "lng": -62.9269,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Amazonas",
    "capital": "",
    "population": 12584,
    "id": 1076105080
  },
  {
    "city": "Lockhart",
    "city_ascii": "Lockhart",
    "lat": 29.8785,
    "lng": -97.6831,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 13731,
    "id": 1840020936
  },
  {
    "city": "Oak Park",
    "city_ascii": "Oak Park",
    "lat": 34.1849,
    "lng": -118.7669,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 13730,
    "id": 1840019198
  },
  {
    "city": "Motupe",
    "city_ascii": "Motupe",
    "lat": -6.1508,
    "lng": -79.7208,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "Lambayeque",
    "capital": "",
    "population": 13681,
    "id": 1604380129
  },
  {
    "city": "Cumnock",
    "city_ascii": "Cumnock",
    "lat": 55.4529,
    "lng": -4.2638,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "East Ayrshire",
    "capital": "",
    "population": 13000,
    "id": 1826053994
  },
  {
    "city": "Cobh",
    "city_ascii": "Cobh",
    "lat": 51.851,
    "lng": -8.2967,
    "country": "Ireland",
    "iso2": "IE",
    "iso3": "IRL",
    "admin_name": "Cork",
    "capital": "",
    "population": 12347,
    "id": 1372119751
  },
  {
    "city": "Muscoy",
    "city_ascii": "Muscoy",
    "lat": 34.155,
    "lng": -117.3477,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 13726,
    "id": 1840028097
  },
  {
    "city": "Jimbolia",
    "city_ascii": "Jimbolia",
    "lat": 45.7917,
    "lng": 20.7222,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Timiş",
    "capital": "",
    "population": 13597,
    "id": 1642376521
  },
  {
    "city": "Bergen",
    "city_ascii": "Bergen",
    "lat": 54.4167,
    "lng": 13.4333,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Mecklenburg-Western Pomerania",
    "capital": "",
    "population": 13460,
    "id": 1276083334
  },
  {
    "city": "Wayland",
    "city_ascii": "Wayland",
    "lat": 42.3585,
    "lng": -71.3594,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 13720,
    "id": 1840053492
  },
  {
    "city": "Matsushima",
    "city_ascii": "Matsushima",
    "lat": 38.3814,
    "lng": 141.0692,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Miyagi",
    "capital": "",
    "population": 13468,
    "id": 1392903558
  },
  {
    "city": "San Pedro Pochutla",
    "city_ascii": "San Pedro Pochutla",
    "lat": 15.7463,
    "lng": -96.4652,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Oaxaca",
    "capital": "minor",
    "population": 13685,
    "id": 1484815091
  },
  {
    "city": "Šternberk",
    "city_ascii": "Sternberk",
    "lat": 49.7305,
    "lng": 17.2989,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Olomoucký Kraj",
    "capital": "",
    "population": 13440,
    "id": 1203827138
  },
  {
    "city": "Saugeen Shores",
    "city_ascii": "Saugeen Shores",
    "lat": 44.4333,
    "lng": -81.3667,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 13715,
    "id": 1124000488
  },
  {
    "city": "Wells",
    "city_ascii": "Wells",
    "lat": 51.209,
    "lng": -2.647,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Somerset",
    "capital": "",
    "population": 12000,
    "id": 1826235732
  },
  {
    "city": "Hickory Hills",
    "city_ascii": "Hickory Hills",
    "lat": 41.7248,
    "lng": -87.8281,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 13710,
    "id": 1840007030
  },
  {
    "city": "College",
    "city_ascii": "College",
    "lat": 64.8694,
    "lng": -147.8217,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alaska",
    "capital": "",
    "population": 13709,
    "id": 1840023462
  },
  {
    "city": "North Auburn",
    "city_ascii": "North Auburn",
    "lat": 38.9306,
    "lng": -121.0821,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 13707,
    "id": 1840028390
  },
  {
    "city": "Khomām",
    "city_ascii": "Khomam",
    "lat": 37.4,
    "lng": 49.6667,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Gīlān",
    "capital": "",
    "population": 12901,
    "id": 1364567758
  },
  {
    "city": "Broadlands",
    "city_ascii": "Broadlands",
    "lat": 39.0168,
    "lng": -77.5167,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 13704,
    "id": 1840024498
  },
  {
    "city": "Quatá",
    "city_ascii": "Quata",
    "lat": -22.2475,
    "lng": -50.6983,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 13702,
    "id": 1076183598
  },
  {
    "city": "Beach Park",
    "city_ascii": "Beach Park",
    "lat": 42.4261,
    "lng": -87.8584,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 13701,
    "id": 1840011149
  },
  {
    "city": "Burglengenfeld",
    "city_ascii": "Burglengenfeld",
    "lat": 49.2061,
    "lng": 12.0409,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 13554,
    "id": 1276523136
  },
  {
    "city": "Arcadia",
    "city_ascii": "Arcadia",
    "lat": 43.0871,
    "lng": -77.0857,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 13699,
    "id": 1840087174
  },
  {
    "city": "Yakage",
    "city_ascii": "Yakage",
    "lat": 34.6278,
    "lng": 133.5872,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Okayama",
    "capital": "",
    "population": 13549,
    "id": 1392939800
  },
  {
    "city": "Plumstead",
    "city_ascii": "Plumstead",
    "lat": 40.3878,
    "lng": -75.1164,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 13694,
    "id": 1840152882
  },
  {
    "city": "Weißenhorn",
    "city_ascii": "Weissenhorn",
    "lat": 48.3044,
    "lng": 10.1593,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 13442,
    "id": 1276798632
  },
  {
    "city": "Sharonville",
    "city_ascii": "Sharonville",
    "lat": 39.2825,
    "lng": -84.4071,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 13684,
    "id": 1840001613
  },
  {
    "city": "Middlesex",
    "city_ascii": "Middlesex",
    "lat": 40.5744,
    "lng": -74.5011,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 13679,
    "id": 1840001337
  },
  {
    "city": "Thompson",
    "city_ascii": "Thompson",
    "lat": 55.7433,
    "lng": -97.8553,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Manitoba",
    "capital": "",
    "population": 13678,
    "id": 1124110693
  },
  {
    "city": "Nikolayevsk",
    "city_ascii": "Nikolayevsk",
    "lat": 50.0167,
    "lng": 45.45,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Volgogradskaya Oblast’",
    "capital": "",
    "population": 13660,
    "id": 1643965946
  },
  {
    "city": "Maumee",
    "city_ascii": "Maumee",
    "lat": 41.5696,
    "lng": -83.6636,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 13669,
    "id": 1840000569
  },
  {
    "city": "Brevard",
    "city_ascii": "Brevard",
    "lat": 35.2439,
    "lng": -82.7264,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 13666,
    "id": 1840013467
  },
  {
    "city": "Lawrenceburg",
    "city_ascii": "Lawrenceburg",
    "lat": 38.0332,
    "lng": -84.9032,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kentucky",
    "capital": "",
    "population": 13666,
    "id": 1840014321
  },
  {
    "city": "Erbach",
    "city_ascii": "Erbach",
    "lat": 48.3281,
    "lng": 9.8878,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 13453,
    "id": 1276634303
  },
  {
    "city": "Bad Laasphe",
    "city_ascii": "Bad Laasphe",
    "lat": 50.9303,
    "lng": 8.4167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 13565,
    "id": 1276330247
  },
  {
    "city": "Ad Duraykīsh",
    "city_ascii": "Ad Duraykish",
    "lat": 34.8969,
    "lng": 36.1346,
    "country": "Syria",
    "iso2": "SY",
    "iso3": "SYR",
    "admin_name": "Ţarţūs",
    "capital": "minor",
    "population": 13244,
    "id": 1760515031
  },
  {
    "city": "Lavaltrie",
    "city_ascii": "Lavaltrie",
    "lat": 45.8833,
    "lng": -73.2833,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 13657,
    "id": 1124818327
  },
  {
    "city": "Bowral",
    "city_ascii": "Bowral",
    "lat": -34.4792,
    "lng": 150.4181,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 12949,
    "id": 1036253752
  },
  {
    "city": "Rottingdean",
    "city_ascii": "Rottingdean",
    "lat": 50.815,
    "lng": -0.065,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Brighton and Hove",
    "capital": "",
    "population": 13651,
    "id": 1826363902
  },
  {
    "city": "Głubczyce",
    "city_ascii": "Glubczyce",
    "lat": 50.2009,
    "lng": 17.8286,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Opolskie",
    "capital": "minor",
    "population": 13572,
    "id": 1616541369
  },
  {
    "city": "Robinson Township",
    "city_ascii": "Robinson Township",
    "lat": 40.4578,
    "lng": -80.1334,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 13650,
    "id": 1840149298
  },
  {
    "city": "Lorsch",
    "city_ascii": "Lorsch",
    "lat": 49.6539,
    "lng": 8.5675,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 13643,
    "id": 1276723392
  },
  {
    "city": "Bridport",
    "city_ascii": "Bridport",
    "lat": 50.7336,
    "lng": -2.7584,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Dorset",
    "capital": "",
    "population": 13569,
    "id": 1826126753
  },
  {
    "city": "Tal’ne",
    "city_ascii": "Tal'ne",
    "lat": 48.8863,
    "lng": 30.7027,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Cherkas’ka Oblast’",
    "capital": "minor",
    "population": 13558,
    "id": 1804616798
  },
  {
    "city": "Cajabamba",
    "city_ascii": "Cajabamba",
    "lat": -7.6237,
    "lng": -78.046,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "Cajamarca",
    "capital": "",
    "population": 13486,
    "id": 1604780784
  },
  {
    "city": "Trogir",
    "city_ascii": "Trogir",
    "lat": 43.5167,
    "lng": 16.25,
    "country": "Croatia",
    "iso2": "HR",
    "iso3": "HRV",
    "admin_name": "Splitsko-Dalmatinska Županija",
    "capital": "minor",
    "population": 13260,
    "id": 1191315860
  },
  {
    "city": "Bergen",
    "city_ascii": "Bergen",
    "lat": 52.8103,
    "lng": 9.9611,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 13556,
    "id": 1276820204
  },
  {
    "city": "Kettering",
    "city_ascii": "Kettering",
    "lat": 38.8888,
    "lng": -76.789,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 13637,
    "id": 1840005958
  },
  {
    "city": "Tanagura",
    "city_ascii": "Tanagura",
    "lat": 37.0297,
    "lng": 140.3797,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Fukushima",
    "capital": "",
    "population": 13551,
    "id": 1392922842
  },
  {
    "city": "Millbury",
    "city_ascii": "Millbury",
    "lat": 42.1925,
    "lng": -71.7741,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 13630,
    "id": 1840053595
  },
  {
    "city": "Broxburn",
    "city_ascii": "Broxburn",
    "lat": 55.934,
    "lng": -3.471,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "West Lothian",
    "capital": "",
    "population": 12000,
    "id": 1826364673
  },
  {
    "city": "Vicovu de Sus",
    "city_ascii": "Vicovu de Sus",
    "lat": 47.9258,
    "lng": 25.68,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Suceava",
    "capital": "",
    "population": 13308,
    "id": 1642275762
  },
  {
    "city": "Šamorín",
    "city_ascii": "Samorin",
    "lat": 48.0267,
    "lng": 17.3117,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Trnavský",
    "capital": "",
    "population": 13324,
    "id": 1703883370
  },
  {
    "city": "Kastoriá",
    "city_ascii": "Kastoria",
    "lat": 40.5181,
    "lng": 21.2688,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Dytikí Makedonía",
    "capital": "minor",
    "population": 13387,
    "id": 1300839562
  },
  {
    "city": "Hersham",
    "city_ascii": "Hersham",
    "lat": 51.3681,
    "lng": -0.4008,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Surrey",
    "capital": "",
    "population": 12414,
    "id": 1826749539
  },
  {
    "city": "Sashinami",
    "city_ascii": "Sashinami",
    "lat": 40.4525,
    "lng": 141.6211,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Aomori",
    "capital": "",
    "population": 13476,
    "id": 1392226078
  },
  {
    "city": "Soddy-Daisy",
    "city_ascii": "Soddy-Daisy",
    "lat": 35.2571,
    "lng": -85.1739,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 13619,
    "id": 1840035990
  },
  {
    "city": "Roudnice nad Labem",
    "city_ascii": "Roudnice nad Labem",
    "lat": 50.4254,
    "lng": 14.2618,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Ústecký Kraj",
    "capital": "",
    "population": 12847,
    "id": 1203268404
  },
  {
    "city": "Rendon",
    "city_ascii": "Rendon",
    "lat": 32.5789,
    "lng": -97.235,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 13616,
    "id": 1840019421
  },
  {
    "city": "Bacup",
    "city_ascii": "Bacup",
    "lat": 53.704,
    "lng": -2.199,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Lancashire",
    "capital": "",
    "population": 13323,
    "id": 1826120221
  },
  {
    "city": "Luling",
    "city_ascii": "Luling",
    "lat": 29.9008,
    "lng": -90.3523,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 13614,
    "id": 1840013980
  },
  {
    "city": "Port Clinton",
    "city_ascii": "Port Clinton",
    "lat": 41.5096,
    "lng": -82.9385,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 13614,
    "id": 1840009257
  },
  {
    "city": "Zhukovo",
    "city_ascii": "Zhukovo",
    "lat": 55.0333,
    "lng": 36.75,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kaluzhskaya Oblast’",
    "capital": "",
    "population": 13576,
    "id": 1643886560
  },
  {
    "city": "Höchstadt an der Aisch",
    "city_ascii": "Hochstadt an der Aisch",
    "lat": 49.7056,
    "lng": 10.8058,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 13422,
    "id": 1276346402
  },
  {
    "city": "Canton",
    "city_ascii": "Canton",
    "lat": 40.5632,
    "lng": -90.0409,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 13611,
    "id": 1840007212
  },
  {
    "city": "Wood Dale",
    "city_ascii": "Wood Dale",
    "lat": 41.9666,
    "lng": -87.9807,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 13607,
    "id": 1840010167
  },
  {
    "city": "Vadnais Heights",
    "city_ascii": "Vadnais Heights",
    "lat": 45.057,
    "lng": -93.0748,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 13607,
    "id": 1840010004
  },
  {
    "city": "Brecksville",
    "city_ascii": "Brecksville",
    "lat": 41.3079,
    "lng": -81.6193,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 13604,
    "id": 1840000590
  },
  {
    "city": "Wolsztyn",
    "city_ascii": "Wolsztyn",
    "lat": 52.1167,
    "lng": 16.1167,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Wielkopolskie",
    "capital": "minor",
    "population": 13477,
    "id": 1616069343
  },
  {
    "city": "Frías",
    "city_ascii": "Frias",
    "lat": -28.6496,
    "lng": -65.15,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Santiago del Estero",
    "capital": "minor",
    "population": 13594,
    "id": 1032077058
  },
  {
    "city": "Bad Soden-Salmünster",
    "city_ascii": "Bad Soden-Salmunster",
    "lat": 50.2833,
    "lng": 9.3667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 13370,
    "id": 1276069295
  },
  {
    "city": "Pelham",
    "city_ascii": "Pelham",
    "lat": 42.7336,
    "lng": -71.324,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Hampshire",
    "capital": "",
    "population": 13596,
    "id": 1840055379
  },
  {
    "city": "New Providence",
    "city_ascii": "New Providence",
    "lat": 40.6996,
    "lng": -74.4034,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 13595,
    "id": 1840001095
  },
  {
    "city": "Radnevo",
    "city_ascii": "Radnevo",
    "lat": 42.2886,
    "lng": 25.9336,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Stara Zagora",
    "capital": "",
    "population": 13554,
    "id": 1100743312
  },
  {
    "city": "Krumbach",
    "city_ascii": "Krumbach",
    "lat": 48.2431,
    "lng": 10.3633,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 13293,
    "id": 1276275014
  },
  {
    "city": "Ettenheim",
    "city_ascii": "Ettenheim",
    "lat": 48.2556,
    "lng": 7.8119,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 13316,
    "id": 1276445594
  },
  {
    "city": "Kaharlyk",
    "city_ascii": "Kaharlyk",
    "lat": 49.8522,
    "lng": 30.8092,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Kyyivs’ka Oblast’",
    "capital": "minor",
    "population": 13544,
    "id": 1804596715
  },
  {
    "city": "Shiloh",
    "city_ascii": "Shiloh",
    "lat": 38.5534,
    "lng": -89.916,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 13586,
    "id": 1840012877
  },
  {
    "city": "Pfullendorf",
    "city_ascii": "Pfullendorf",
    "lat": 47.9242,
    "lng": 9.2567,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 13437,
    "id": 1276345734
  },
  {
    "city": "Rantoul",
    "city_ascii": "Rantoul",
    "lat": 40.3031,
    "lng": -88.1549,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 13584,
    "id": 1840012227
  },
  {
    "city": "High River",
    "city_ascii": "High River",
    "lat": 50.5808,
    "lng": -113.8744,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Alberta",
    "capital": "",
    "population": 13584,
    "id": 1124607825
  },
  {
    "city": "Drøbak",
    "city_ascii": "Drobak",
    "lat": 59.6667,
    "lng": 10.6333,
    "country": "Norway",
    "iso2": "NO",
    "iso3": "NOR",
    "admin_name": "Akershus",
    "capital": "",
    "population": 13393,
    "id": 1578855474
  },
  {
    "city": "Tianguistengo",
    "city_ascii": "Tianguistengo",
    "lat": 20.7278,
    "lng": -98.6289,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Hidalgo",
    "capital": "",
    "population": 13478,
    "id": 1484089008
  },
  {
    "city": "Spiez",
    "city_ascii": "Spiez",
    "lat": 46.6831,
    "lng": 7.6664,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Bern",
    "capital": "",
    "population": 12810,
    "id": 1756813024
  },
  {
    "city": "Shepshed",
    "city_ascii": "Shepshed",
    "lat": 52.7711,
    "lng": -1.2951,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Leicestershire",
    "capital": "",
    "population": 13505,
    "id": 1826737427
  },
  {
    "city": "Hamajō",
    "city_ascii": "Hamajo",
    "lat": 34.3414,
    "lng": 132.5139,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hiroshima",
    "capital": "",
    "population": 12762,
    "id": 1392696742
  },
  {
    "city": "Moberly",
    "city_ascii": "Moberly",
    "lat": 39.419,
    "lng": -92.4365,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 13574,
    "id": 1840008463
  },
  {
    "city": "Presidente Bernardes",
    "city_ascii": "Presidente Bernardes",
    "lat": -22.0061,
    "lng": -51.5531,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 13568,
    "id": 1076773912
  },
  {
    "city": "Feldbach",
    "city_ascii": "Feldbach",
    "lat": 46.955,
    "lng": 15.8883,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Steiermark",
    "capital": "minor",
    "population": 13369,
    "id": 1040342228
  },
  {
    "city": "Český Krumlov",
    "city_ascii": "Cesky Krumlov",
    "lat": 48.811,
    "lng": 14.3152,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Jihočeský Kraj",
    "capital": "",
    "population": 12981,
    "id": 1203973467
  },
  {
    "city": "Talanga",
    "city_ascii": "Talanga",
    "lat": 14.4,
    "lng": -87.0833,
    "country": "Honduras",
    "iso2": "HN",
    "iso3": "HND",
    "admin_name": "Francisco Morazán",
    "capital": "",
    "population": 13533,
    "id": 1340622146
  },
  {
    "city": "Hazel Crest",
    "city_ascii": "Hazel Crest",
    "lat": 41.5732,
    "lng": -87.6899,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 13565,
    "id": 1840011260
  },
  {
    "city": "Washington",
    "city_ascii": "Washington",
    "lat": 38.6587,
    "lng": -87.1591,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 13565,
    "id": 1840010735
  },
  {
    "city": "Price",
    "city_ascii": "Price",
    "lat": 39.604,
    "lng": -110.8004,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Utah",
    "capital": "",
    "population": 13564,
    "id": 1840020201
  },
  {
    "city": "LaBelle",
    "city_ascii": "LaBelle",
    "lat": 26.7219,
    "lng": -81.4506,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 13563,
    "id": 1840015129
  },
  {
    "city": "East Islip",
    "city_ascii": "East Islip",
    "lat": 40.7275,
    "lng": -73.1861,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 13563,
    "id": 1840005036
  },
  {
    "city": "Arruda dos Vinhos",
    "city_ascii": "Arruda dos Vinhos",
    "lat": 38.9833,
    "lng": -9.0667,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Lisboa",
    "capital": "minor",
    "population": 13391,
    "id": 1620150819
  },
  {
    "city": "Nesebar",
    "city_ascii": "Nesebar",
    "lat": 42.6608,
    "lng": 27.7139,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Burgas",
    "capital": "minor",
    "population": 13559,
    "id": 1100835570
  },
  {
    "city": "Pānchgani",
    "city_ascii": "Panchgani",
    "lat": 17.92,
    "lng": 73.82,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Mahārāshtra",
    "capital": "",
    "population": 13280,
    "id": 1356363420
  },
  {
    "city": "Reidsville",
    "city_ascii": "Reidsville",
    "lat": 36.3377,
    "lng": -79.6727,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 13551,
    "id": 1840014436
  },
  {
    "city": "Landau an der Isar",
    "city_ascii": "Landau an der Isar",
    "lat": 48.6749,
    "lng": 12.6913,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 13390,
    "id": 1276797945
  },
  {
    "city": "Conway",
    "city_ascii": "Conway",
    "lat": 28.4968,
    "lng": -81.3316,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 13548,
    "id": 1840013121
  },
  {
    "city": "Springdale",
    "city_ascii": "Springdale",
    "lat": 39.8769,
    "lng": -74.9723,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 13547,
    "id": 1840133731
  },
  {
    "city": "Carluke",
    "city_ascii": "Carluke",
    "lat": 55.7337,
    "lng": -3.8343,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "South Lanarkshire",
    "capital": "",
    "population": 13300,
    "id": 1826058388
  },
  {
    "city": "Chañaral",
    "city_ascii": "Chanaral",
    "lat": -29.0772,
    "lng": -71.4906,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Atacama",
    "capital": "",
    "population": 13543,
    "id": 1152323837
  },
  {
    "city": "Alpena",
    "city_ascii": "Alpena",
    "lat": 45.074,
    "lng": -83.4399,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 13541,
    "id": 1840003947
  },
  {
    "city": "Knittelfeld",
    "city_ascii": "Knittelfeld",
    "lat": 47.215,
    "lng": 14.8294,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Steiermark",
    "capital": "",
    "population": 12626,
    "id": 1040186382
  },
  {
    "city": "Flint",
    "city_ascii": "Flint",
    "lat": 53.2482,
    "lng": -3.1358,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Flintshire",
    "capital": "",
    "population": 12953,
    "id": 1826504781
  },
  {
    "city": "Löningen",
    "city_ascii": "Loningen",
    "lat": 52.7167,
    "lng": 7.7667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 13441,
    "id": 1276522371
  },
  {
    "city": "Oatfield",
    "city_ascii": "Oatfield",
    "lat": 45.4127,
    "lng": -122.5942,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 13531,
    "id": 1840034837
  },
  {
    "city": "Dok Kham Tai",
    "city_ascii": "Dok Kham Tai",
    "lat": 19.1612,
    "lng": 99.9946,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Phayao",
    "capital": "minor",
    "population": 13450,
    "id": 1764998774
  },
  {
    "city": "Loreto",
    "city_ascii": "Loreto",
    "lat": 43.4403,
    "lng": 13.6074,
    "country": "Italy",
    "iso2": "IT",
    "iso3": "ITA",
    "admin_name": "Marche",
    "capital": "",
    "population": 12810,
    "id": 1380440033
  },
  {
    "city": "Ainsdale",
    "city_ascii": "Ainsdale",
    "lat": 53.6021,
    "lng": -3.0405,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Sefton",
    "capital": "",
    "population": 12723,
    "id": 1826404186
  },
  {
    "city": "Sun Village",
    "city_ascii": "Sun Village",
    "lat": 34.5596,
    "lng": -117.9559,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 13516,
    "id": 1840076298
  },
  {
    "city": "Neunkirchen",
    "city_ascii": "Neunkirchen",
    "lat": 47.7269,
    "lng": 16.0817,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Niederösterreich",
    "capital": "minor",
    "population": 12879,
    "id": 1040203057
  },
  {
    "city": "Pervomaysk",
    "city_ascii": "Pervomaysk",
    "lat": 54.8667,
    "lng": 43.8,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Nizhegorodskaya Oblast’",
    "capital": "",
    "population": 13496,
    "id": 1643783903
  },
  {
    "city": "Ridge",
    "city_ascii": "Ridge",
    "lat": 40.9068,
    "lng": -72.8816,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 13511,
    "id": 1840005068
  },
  {
    "city": "Elizabethton",
    "city_ascii": "Elizabethton",
    "lat": 36.3367,
    "lng": -82.2369,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 13509,
    "id": 1840013314
  },
  {
    "city": "Esil",
    "city_ascii": "Esil",
    "lat": 51.957,
    "lng": 66.3775,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Aqmola",
    "capital": "",
    "population": 13504,
    "id": 1398546225
  },
  {
    "city": "Lake Forest Park",
    "city_ascii": "Lake Forest Park",
    "lat": 47.7574,
    "lng": -122.2865,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 13504,
    "id": 1840019837
  },
  {
    "city": "Wauconda",
    "city_ascii": "Wauconda",
    "lat": 42.2748,
    "lng": -88.1359,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 13504,
    "id": 1840011184
  },
  {
    "city": "Aconibe",
    "city_ascii": "Aconibe",
    "lat": 1.3,
    "lng": 10.9333,
    "country": "Equatorial Guinea",
    "iso2": "GQ",
    "iso3": "GNQ",
    "admin_name": "Wele-Nzas",
    "capital": "",
    "population": 13382,
    "id": 1226173790
  },
  {
    "city": "Vicuña",
    "city_ascii": "Vicuna",
    "lat": -30.0295,
    "lng": -70.74,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Coquimbo",
    "capital": "",
    "population": 13496,
    "id": 1152771899
  },
  {
    "city": "Schüttorf",
    "city_ascii": "Schuttorf",
    "lat": 52.3167,
    "lng": 7.2167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 12839,
    "id": 1276950675
  },
  {
    "city": "Zehdenick",
    "city_ascii": "Zehdenick",
    "lat": 52.9831,
    "lng": 13.3331,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Brandenburg",
    "capital": "",
    "population": 13437,
    "id": 1276607810
  },
  {
    "city": "Buchloe",
    "city_ascii": "Buchloe",
    "lat": 48.0375,
    "lng": 10.725,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 13132,
    "id": 1276184679
  },
  {
    "city": "Fereydūnshahr",
    "city_ascii": "Fereydunshahr",
    "lat": 32.9411,
    "lng": 50.1211,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Eşfahān",
    "capital": "minor",
    "population": 13475,
    "id": 1364461971
  },
  {
    "city": "Belle Chasse",
    "city_ascii": "Belle Chasse",
    "lat": 29.8472,
    "lng": -90.0069,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 13490,
    "id": 1840014028
  },
  {
    "city": "Rogers",
    "city_ascii": "Rogers",
    "lat": 45.1865,
    "lng": -93.5783,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 13490,
    "id": 1840008932
  },
  {
    "city": "K’asp’i",
    "city_ascii": "K'asp'i",
    "lat": 41.9167,
    "lng": 44.4167,
    "country": "Georgia",
    "iso2": "GE",
    "iso3": "GEO",
    "admin_name": "Shida Kartli",
    "capital": "minor",
    "population": 13423,
    "id": 1268997310
  },
  {
    "city": "Champasak",
    "city_ascii": "Champasak",
    "lat": 14.8833,
    "lng": 105.8667,
    "country": "Laos",
    "iso2": "LA",
    "iso3": "LAO",
    "admin_name": "Champasak",
    "capital": "",
    "population": 13400,
    "id": 1418902447
  },
  {
    "city": "Standish",
    "city_ascii": "Standish",
    "lat": 53.586,
    "lng": -2.664,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Wigan",
    "capital": "",
    "population": 13278,
    "id": 1826196984
  },
  {
    "city": "Charlton",
    "city_ascii": "Charlton",
    "lat": 42.1351,
    "lng": -71.968,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 13482,
    "id": 1840053584
  },
  {
    "city": "Avenal",
    "city_ascii": "Avenal",
    "lat": 36.0311,
    "lng": -120.1162,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 13479,
    "id": 1840019054
  },
  {
    "city": "Jimboomba",
    "city_ascii": "Jimboomba",
    "lat": -27.8333,
    "lng": 153.0333,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Queensland",
    "capital": "",
    "population": 13201,
    "id": 1036214142
  },
  {
    "city": "Uttoxeter",
    "city_ascii": "Uttoxeter",
    "lat": 52.898,
    "lng": -1.86,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Staffordshire",
    "capital": "",
    "population": 13089,
    "id": 1826375262
  },
  {
    "city": "Fruita",
    "city_ascii": "Fruita",
    "lat": 39.1549,
    "lng": -108.7307,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 13478,
    "id": 1840020229
  },
  {
    "city": "Jensen Beach",
    "city_ascii": "Jensen Beach",
    "lat": 27.2438,
    "lng": -80.2423,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 13477,
    "id": 1840014190
  },
  {
    "city": "Homosassa Springs",
    "city_ascii": "Homosassa Springs",
    "lat": 28.8118,
    "lng": -82.5392,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 13477,
    "id": 1840014060
  },
  {
    "city": "Severn",
    "city_ascii": "Severn",
    "lat": 44.75,
    "lng": -79.5167,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 13477,
    "id": 1124489890
  },
  {
    "city": "Middlesborough",
    "city_ascii": "Middlesborough",
    "lat": 36.6127,
    "lng": -83.7227,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kentucky",
    "capital": "",
    "population": 13475,
    "id": 1840036079
  },
  {
    "city": "Bad Dürrheim",
    "city_ascii": "Bad Durrheim",
    "lat": 48.0167,
    "lng": 8.5333,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 13260,
    "id": 1276778010
  },
  {
    "city": "Hockessin",
    "city_ascii": "Hockessin",
    "lat": 39.7837,
    "lng": -75.6815,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Delaware",
    "capital": "",
    "population": 13472,
    "id": 1840005573
  },
  {
    "city": "New Kingman-Butler",
    "city_ascii": "New Kingman-Butler",
    "lat": 35.2645,
    "lng": -114.0091,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 13471,
    "id": 1840037118
  },
  {
    "city": "Georgetown",
    "city_ascii": "Georgetown",
    "lat": 31.9849,
    "lng": -81.226,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 13469,
    "id": 1840134450
  },
  {
    "city": "Costessey",
    "city_ascii": "Costessey",
    "lat": 52.6602,
    "lng": 1.2161,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Norfolk",
    "capital": "",
    "population": 12463,
    "id": 1826359411
  },
  {
    "city": "Adams Township",
    "city_ascii": "Adams Township",
    "lat": 40.7092,
    "lng": -80.012,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 13467,
    "id": 1840149061
  },
  {
    "city": "Wehr",
    "city_ascii": "Wehr",
    "lat": 47.6331,
    "lng": 7.9042,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 13098,
    "id": 1276432205
  },
  {
    "city": "Red Oak",
    "city_ascii": "Red Oak",
    "lat": 32.5207,
    "lng": -96.7864,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 13464,
    "id": 1840020761
  },
  {
    "city": "Brig-Glis",
    "city_ascii": "Brig-Glis",
    "lat": 46.3159,
    "lng": 7.9876,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Valais",
    "capital": "minor",
    "population": 13109,
    "id": 1756778801
  },
  {
    "city": "Marion",
    "city_ascii": "Marion",
    "lat": 35.6774,
    "lng": -82.0013,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 13454,
    "id": 1840015372
  },
  {
    "city": "Wernau",
    "city_ascii": "Wernau",
    "lat": 48.6886,
    "lng": 9.4222,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 12324,
    "id": 1276040712
  },
  {
    "city": "Muisne",
    "city_ascii": "Muisne",
    "lat": 0.61,
    "lng": -80.02,
    "country": "Ecuador",
    "iso2": "EC",
    "iso3": "ECU",
    "admin_name": "Esmeraldas",
    "capital": "",
    "population": 13393,
    "id": 1218271569
  },
  {
    "city": "Alexandria",
    "city_ascii": "Alexandria",
    "lat": 55.98,
    "lng": -4.58,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "West Dunbartonshire",
    "capital": "",
    "population": 13444,
    "id": 1826818723
  },
  {
    "city": "Santa Fe",
    "city_ascii": "Santa Fe",
    "lat": 29.3892,
    "lng": -95.1005,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 13449,
    "id": 1840022233
  },
  {
    "city": "Fortuna",
    "city_ascii": "Fortuna",
    "lat": 40.5862,
    "lng": -124.1419,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 13449,
    "id": 1840009553
  },
  {
    "city": "Ruza",
    "city_ascii": "Ruza",
    "lat": 55.6989,
    "lng": 36.1953,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Moskovskaya Oblast’",
    "capital": "",
    "population": 13403,
    "id": 1643967155
  },
  {
    "city": "Lindale",
    "city_ascii": "Lindale",
    "lat": 32.4934,
    "lng": -95.4069,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 13442,
    "id": 1840022078
  },
  {
    "city": "Oboyan",
    "city_ascii": "Oboyan",
    "lat": 51.2088,
    "lng": 36.2637,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kurskaya Oblast’",
    "capital": "",
    "population": 13413,
    "id": 1643628324
  },
  {
    "city": "Tórshavn",
    "city_ascii": "Torshavn",
    "lat": 62,
    "lng": -6.7833,
    "country": "Faroe Islands",
    "iso2": "FO",
    "iso3": "FRO",
    "admin_name": "",
    "capital": "",
    "population": 13326,
    "id": 1234904517
  },
  {
    "city": "Englewood",
    "city_ascii": "Englewood",
    "lat": 39.8644,
    "lng": -84.3071,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 13435,
    "id": 1840008435
  },
  {
    "city": "Washington",
    "city_ascii": "Washington",
    "lat": 40.174,
    "lng": -80.2466,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 13433,
    "id": 1840003668
  },
  {
    "city": "Middleburg",
    "city_ascii": "Middleburg",
    "lat": 30.0502,
    "lng": -81.9011,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 13431,
    "id": 1840013998
  },
  {
    "city": "Golyshmanovo",
    "city_ascii": "Golyshmanovo",
    "lat": 56.3819,
    "lng": 68.3715,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Tyumenskaya Oblast’",
    "capital": "",
    "population": 13424,
    "id": 1643817304
  },
  {
    "city": "Balchik",
    "city_ascii": "Balchik",
    "lat": 43.4078,
    "lng": 28.1622,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Dobrich",
    "capital": "minor",
    "population": 13393,
    "id": 1100579546
  },
  {
    "city": "Huron",
    "city_ascii": "Huron",
    "lat": 44.3622,
    "lng": -98.2102,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Dakota",
    "capital": "",
    "population": 13427,
    "id": 1840002373
  },
  {
    "city": "Itapuí",
    "city_ascii": "Itapui",
    "lat": -22.2333,
    "lng": -48.7192,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 13328,
    "id": 1076494773
  },
  {
    "city": "Twentynine Palms",
    "city_ascii": "Twentynine Palms",
    "lat": 34.1478,
    "lng": -116.0659,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 13423,
    "id": 1840021729
  },
  {
    "city": "Tauberbischofsheim",
    "city_ascii": "Tauberbischofsheim",
    "lat": 49.6225,
    "lng": 9.6628,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "minor",
    "population": 13231,
    "id": 1276697756
  },
  {
    "city": "Aš",
    "city_ascii": "As",
    "lat": 50.224,
    "lng": 12.1951,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Karlovarský Kraj",
    "capital": "",
    "population": 13182,
    "id": 1203503000
  },
  {
    "city": "Bollnäs",
    "city_ascii": "Bollnas",
    "lat": 61.352,
    "lng": 16.3666,
    "country": "Sweden",
    "iso2": "SE",
    "iso3": "SWE",
    "admin_name": "Gävleborg",
    "capital": "minor",
    "population": 13398,
    "id": 1752287863
  },
  {
    "city": "Berkeley Heights",
    "city_ascii": "Berkeley Heights",
    "lat": 40.6764,
    "lng": -74.4244,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 13417,
    "id": 1840081748
  },
  {
    "city": "Verona",
    "city_ascii": "Verona",
    "lat": 40.8323,
    "lng": -74.243,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 13413,
    "id": 1840131594
  },
  {
    "city": "Khoyniki",
    "city_ascii": "Khoyniki",
    "lat": 51.9,
    "lng": 29.9667,
    "country": "Belarus",
    "iso2": "BY",
    "iso3": "BLR",
    "admin_name": "Homyel’skaya Voblasts’",
    "capital": "minor",
    "population": 13400,
    "id": 1112972491
  },
  {
    "city": "Espita",
    "city_ascii": "Espita",
    "lat": 21.0128,
    "lng": -88.3047,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Yucatán",
    "capital": "minor",
    "population": 11551,
    "id": 1484433962
  },
  {
    "city": "Plattling",
    "city_ascii": "Plattling",
    "lat": 48.7767,
    "lng": 12.8736,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 13043,
    "id": 1276078281
  },
  {
    "city": "Colac",
    "city_ascii": "Colac",
    "lat": -38.3403,
    "lng": 143.5847,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Victoria",
    "capital": "",
    "population": 12547,
    "id": 1036633113
  },
  {
    "city": "Paso de los Toros",
    "city_ascii": "Paso de los Toros",
    "lat": -32.8181,
    "lng": -56.5064,
    "country": "Uruguay",
    "iso2": "UY",
    "iso3": "URY",
    "admin_name": "Durazno",
    "capital": "",
    "population": 12985,
    "id": 1858768851
  },
  {
    "city": "Andover",
    "city_ascii": "Andover",
    "lat": 37.6869,
    "lng": -97.1354,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kansas",
    "capital": "",
    "population": 13405,
    "id": 1840007546
  },
  {
    "city": "Buchs",
    "city_ascii": "Buchs",
    "lat": 47.1656,
    "lng": 9.4711,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Sankt Gallen",
    "capital": "",
    "population": 12612,
    "id": 1756006204
  },
  {
    "city": "Luís Antônio",
    "city_ascii": "Luis Antonio",
    "lat": -21.555,
    "lng": -47.7044,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 13378,
    "id": 1076517541
  },
  {
    "city": "Gmunden",
    "city_ascii": "Gmunden",
    "lat": 47.9181,
    "lng": 13.7994,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Oberösterreich",
    "capital": "minor",
    "population": 13191,
    "id": 1040670341
  },
  {
    "city": "Gourock",
    "city_ascii": "Gourock",
    "lat": 55.9538,
    "lng": -4.8173,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Inverclyde",
    "capital": "",
    "population": 11511,
    "id": 1826142319
  },
  {
    "city": "Carpinteria",
    "city_ascii": "Carpinteria",
    "lat": 34.3962,
    "lng": -119.5118,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 13385,
    "id": 1840019187
  },
  {
    "city": "Grimstad",
    "city_ascii": "Grimstad",
    "lat": 58.3405,
    "lng": 8.5934,
    "country": "Norway",
    "iso2": "NO",
    "iso3": "NOR",
    "admin_name": "Aust-Agder",
    "capital": "minor",
    "population": 13304,
    "id": 1578703293
  },
  {
    "city": "Kingston",
    "city_ascii": "Kingston",
    "lat": 41.9862,
    "lng": -70.7482,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 13381,
    "id": 1840053680
  },
  {
    "city": "Joaquín V. González",
    "city_ascii": "Joaquin V. Gonzalez",
    "lat": -25.0833,
    "lng": -64.1834,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Salta",
    "capital": "minor",
    "population": 13376,
    "id": 1032690861
  },
  {
    "city": "Besigheim",
    "city_ascii": "Besigheim",
    "lat": 48.9989,
    "lng": 9.1414,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 12627,
    "id": 1276386454
  },
  {
    "city": "Glenn Heights",
    "city_ascii": "Glenn Heights",
    "lat": 32.5506,
    "lng": -96.8548,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 13377,
    "id": 1840020708
  },
  {
    "city": "Itayanagi",
    "city_ascii": "Itayanagi",
    "lat": 40.6961,
    "lng": 140.4575,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Aomori",
    "capital": "",
    "population": 13064,
    "id": 1392658620
  },
  {
    "city": "Pegnitz",
    "city_ascii": "Pegnitz",
    "lat": 49.7564,
    "lng": 11.545,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 13244,
    "id": 1276197483
  },
  {
    "city": "Ribeira Brava",
    "city_ascii": "Ribeira Brava",
    "lat": 32.6722,
    "lng": -17.0639,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Madeira",
    "capital": "",
    "population": 13375,
    "id": 1620853683
  },
  {
    "city": "Powell",
    "city_ascii": "Powell",
    "lat": 40.1684,
    "lng": -83.0826,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 13375,
    "id": 1840009430
  },
  {
    "city": "Sainte-Sophie",
    "city_ascii": "Sainte-Sophie",
    "lat": 45.82,
    "lng": -73.9,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 13375,
    "id": 1124001574
  },
  {
    "city": "Rödental",
    "city_ascii": "Rodental",
    "lat": 50.2883,
    "lng": 11.0276,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 13107,
    "id": 1276302053
  },
  {
    "city": "Wasserburg am Inn",
    "city_ascii": "Wasserburg am Inn",
    "lat": 48.0608,
    "lng": 12.2333,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 12691,
    "id": 1276900487
  },
  {
    "city": "Lyndhurst",
    "city_ascii": "Lyndhurst",
    "lat": 41.5172,
    "lng": -81.4922,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 13366,
    "id": 1840000610
  },
  {
    "city": "North Walsham",
    "city_ascii": "North Walsham",
    "lat": 52.821,
    "lng": 1.387,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Norfolk",
    "capital": "",
    "population": 12634,
    "id": 1826981933
  },
  {
    "city": "South Park",
    "city_ascii": "South Park",
    "lat": 40.2989,
    "lng": -79.9944,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 13363,
    "id": 1840150312
  },
  {
    "city": "South Park Township",
    "city_ascii": "South Park Township",
    "lat": 40.2989,
    "lng": -79.9944,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 13363,
    "id": 1840073713
  },
  {
    "city": "Western Springs",
    "city_ascii": "Western Springs",
    "lat": 41.8022,
    "lng": -87.9006,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 13359,
    "id": 1840011333
  },
  {
    "city": "Tachov",
    "city_ascii": "Tachov",
    "lat": 49.7954,
    "lng": 12.6337,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Plzeňský Kraj",
    "capital": "",
    "population": 13038,
    "id": 1203227002
  },
  {
    "city": "Okmulgee",
    "city_ascii": "Okmulgee",
    "lat": 35.6136,
    "lng": -96.0069,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oklahoma",
    "capital": "",
    "population": 13357,
    "id": 1840020400
  },
  {
    "city": "DeRidder",
    "city_ascii": "DeRidder",
    "lat": 30.8467,
    "lng": -93.2931,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 13354,
    "id": 1840015013
  },
  {
    "city": "Ţăndărei",
    "city_ascii": "Tandarei",
    "lat": 44.6403,
    "lng": 27.6586,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Ialomiţa",
    "capital": "",
    "population": 13219,
    "id": 1642705239
  },
  {
    "city": "Azle",
    "city_ascii": "Azle",
    "lat": 32.8955,
    "lng": -97.5379,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 13351,
    "id": 1840019423
  },
  {
    "city": "Swansea",
    "city_ascii": "Swansea",
    "lat": 38.5507,
    "lng": -89.9859,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 13350,
    "id": 1840012880
  },
  {
    "city": "Vidalia",
    "city_ascii": "Vidalia",
    "lat": 32.2125,
    "lng": -82.4019,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 13350,
    "id": 1840015820
  },
  {
    "city": "Moss Point",
    "city_ascii": "Moss Point",
    "lat": 30.424,
    "lng": -88.5289,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Mississippi",
    "capital": "",
    "population": 13350,
    "id": 1840015016
  },
  {
    "city": "Fort Morgan",
    "city_ascii": "Fort Morgan",
    "lat": 40.2538,
    "lng": -103.7909,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 13349,
    "id": 1840020175
  },
  {
    "city": "Dardenne Prairie",
    "city_ascii": "Dardenne Prairie",
    "lat": 38.7566,
    "lng": -90.732,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 13348,
    "id": 1840007428
  },
  {
    "city": "Sudbury",
    "city_ascii": "Sudbury",
    "lat": 52.0417,
    "lng": 0.7282,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Suffolk",
    "capital": "",
    "population": 13063,
    "id": 1826131737
  },
  {
    "city": "Bad Salzdetfurth",
    "city_ascii": "Bad Salzdetfurth",
    "lat": 52.0578,
    "lng": 10.0058,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 13145,
    "id": 1276420532
  },
  {
    "city": "Moorreesburg",
    "city_ascii": "Moorreesburg",
    "lat": -33.15,
    "lng": 18.6667,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "Western Cape",
    "capital": "",
    "population": 12877,
    "id": 1710940552
  },
  {
    "city": "Bombarral",
    "city_ascii": "Bombarral",
    "lat": 39.2667,
    "lng": -9.15,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Leiria",
    "capital": "minor",
    "population": 13193,
    "id": 1620112554
  },
  {
    "city": "Neustadt",
    "city_ascii": "Neustadt",
    "lat": 49.58,
    "lng": 10.6089,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "minor",
    "population": 13121,
    "id": 1276596771
  },
  {
    "city": "Newark",
    "city_ascii": "Newark",
    "lat": 43.0418,
    "lng": -77.093,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 13333,
    "id": 1840004292
  },
  {
    "city": "Dinklage",
    "city_ascii": "Dinklage",
    "lat": 52.6622,
    "lng": 8.125,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 13150,
    "id": 1276444417
  },
  {
    "city": "Van Buren",
    "city_ascii": "Van Buren",
    "lat": 43.1211,
    "lng": -76.3591,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 13328,
    "id": 1840058558
  },
  {
    "city": "Kent",
    "city_ascii": "Kent",
    "lat": 41.4735,
    "lng": -73.7319,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 13325,
    "id": 1840058632
  },
  {
    "city": "Bonita",
    "city_ascii": "Bonita",
    "lat": 32.6652,
    "lng": -117.0295,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 13324,
    "id": 1840018015
  },
  {
    "city": "Mounds View",
    "city_ascii": "Mounds View",
    "lat": 45.1071,
    "lng": -93.2076,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 13324,
    "id": 1840007840
  },
  {
    "city": "Dalby",
    "city_ascii": "Dalby",
    "lat": -27.1833,
    "lng": 151.2667,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Queensland",
    "capital": "",
    "population": 12719,
    "id": 1036053707
  },
  {
    "city": "Chiroqchi",
    "city_ascii": "Chiroqchi",
    "lat": 39.0336,
    "lng": 66.5739,
    "country": "Uzbekistan",
    "iso2": "UZ",
    "iso3": "UZB",
    "admin_name": "Qashqadaryo",
    "capital": "",
    "population": 13220,
    "id": 1860377999
  },
  {
    "city": "Jefferson",
    "city_ascii": "Jefferson",
    "lat": 34.1373,
    "lng": -83.6021,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 13322,
    "id": 1840014723
  },
  {
    "city": "Saint-Charles-Borromée",
    "city_ascii": "Saint-Charles-Borromee",
    "lat": 46.05,
    "lng": -73.4667,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 13321,
    "id": 1124000877
  },
  {
    "city": "Zella-Mehlis",
    "city_ascii": "Zella-Mehlis",
    "lat": 50.6597,
    "lng": 10.6669,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Thuringia",
    "capital": "",
    "population": 12863,
    "id": 1276496702
  },
  {
    "city": "Kerepestarcsa",
    "city_ascii": "Kerepestarcsa",
    "lat": 47.5478,
    "lng": 19.2633,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Pest",
    "capital": "",
    "population": 12212,
    "id": 1348751669
  },
  {
    "city": "Velen",
    "city_ascii": "Velen",
    "lat": 51.8939,
    "lng": 6.9897,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 13130,
    "id": 1276638227
  },
  {
    "city": "Yuza",
    "city_ascii": "Yuza",
    "lat": 39.0147,
    "lng": 139.9075,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Yamagata",
    "capital": "",
    "population": 13250,
    "id": 1392728665
  },
  {
    "city": "Big Bear City",
    "city_ascii": "Big Bear City",
    "lat": 34.2536,
    "lng": -116.7903,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 13312,
    "id": 1840017797
  },
  {
    "city": "Olivehurst",
    "city_ascii": "Olivehurst",
    "lat": 39.0817,
    "lng": -121.5549,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 13309,
    "id": 1840018801
  },
  {
    "city": "Ashibetsu",
    "city_ascii": "Ashibetsu",
    "lat": 43.5183,
    "lng": 142.1894,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hokkaidō",
    "capital": "",
    "population": 13291,
    "id": 1392076834
  },
  {
    "city": "Talladega",
    "city_ascii": "Talladega",
    "lat": 33.4329,
    "lng": -86.0976,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 13304,
    "id": 1840008244
  },
  {
    "city": "Portage La Prairie",
    "city_ascii": "Portage La Prairie",
    "lat": 49.9728,
    "lng": -98.2919,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Manitoba",
    "capital": "",
    "population": 13304,
    "id": 1124282782
  },
  {
    "city": "Cocotitlán",
    "city_ascii": "Cocotitlan",
    "lat": 19.2167,
    "lng": -98.85,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "México",
    "capital": "minor",
    "population": 12142,
    "id": 1484387908
  },
  {
    "city": "Rüti",
    "city_ascii": "Ruti",
    "lat": 47.2614,
    "lng": 8.8556,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Zürich",
    "capital": "",
    "population": 12098,
    "id": 1756152565
  },
  {
    "city": "Wiesmoor",
    "city_ascii": "Wiesmoor",
    "lat": 53.4,
    "lng": 7.7333,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 13141,
    "id": 1276001031
  },
  {
    "city": "Solana Beach",
    "city_ascii": "Solana Beach",
    "lat": 32.9942,
    "lng": -117.2575,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 13296,
    "id": 1840021993
  },
  {
    "city": "Rochester",
    "city_ascii": "Rochester",
    "lat": 42.6866,
    "lng": -83.1198,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 13296,
    "id": 1840002428
  },
  {
    "city": "Sendenhorst",
    "city_ascii": "Sendenhorst",
    "lat": 51.8439,
    "lng": 7.8278,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 13157,
    "id": 1276094140
  },
  {
    "city": "Richton Park",
    "city_ascii": "Richton Park",
    "lat": 41.4816,
    "lng": -87.7387,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 13292,
    "id": 1840011283
  },
  {
    "city": "Mogocha",
    "city_ascii": "Mogocha",
    "lat": 53.7333,
    "lng": 119.7667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Zabaykal’skiy Kray",
    "capital": "",
    "population": 13285,
    "id": 1643177886
  },
  {
    "city": "Beyla",
    "city_ascii": "Beyla",
    "lat": 8.687,
    "lng": -8.657,
    "country": "Guinea",
    "iso2": "GN",
    "iso3": "GIN",
    "admin_name": "N’Zérékoré",
    "capital": "minor",
    "population": 13204,
    "id": 1324564039
  },
  {
    "city": "Svirsk",
    "city_ascii": "Svirsk",
    "lat": 53.0833,
    "lng": 103.3333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Irkutskaya Oblast’",
    "capital": "",
    "population": 12945,
    "id": 1643442205
  },
  {
    "city": "Trzebnica",
    "city_ascii": "Trzebnica",
    "lat": 51.305,
    "lng": 17.0614,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Dolnośląskie",
    "capital": "minor",
    "population": 13161,
    "id": 1616145534
  },
  {
    "city": "Marshall",
    "city_ascii": "Marshall",
    "lat": 44.4488,
    "lng": -95.7897,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 13274,
    "id": 1840007879
  },
  {
    "city": "Herbrechtingen",
    "city_ascii": "Herbrechtingen",
    "lat": 48.6253,
    "lng": 10.1739,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 13051,
    "id": 1276327261
  },
  {
    "city": "Palos Verdes Estates",
    "city_ascii": "Palos Verdes Estates",
    "lat": 33.7871,
    "lng": -118.3976,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 13273,
    "id": 1840020503
  },
  {
    "city": "Chulumani",
    "city_ascii": "Chulumani",
    "lat": -16.4102,
    "lng": -67.5255,
    "country": "Bolivia",
    "iso2": "BO",
    "iso3": "BOL",
    "admin_name": "La Paz",
    "capital": "",
    "population": 13204,
    "id": 1068146431
  },
  {
    "city": "Montgomeryville",
    "city_ascii": "Montgomeryville",
    "lat": 40.2502,
    "lng": -75.2405,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 13271,
    "id": 1840005457
  },
  {
    "city": "Ulvila",
    "city_ascii": "Ulvila",
    "lat": 61.4292,
    "lng": 21.875,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Satakunta",
    "capital": "minor",
    "population": 13237,
    "id": 1246357042
  },
  {
    "city": "Novouzensk",
    "city_ascii": "Novouzensk",
    "lat": 50.45,
    "lng": 48.15,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Saratovskaya Oblast’",
    "capital": "",
    "population": 13261,
    "id": 1643676305
  },
  {
    "city": "Nocatee",
    "city_ascii": "Nocatee",
    "lat": 30.0918,
    "lng": -81.4097,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 13266,
    "id": 1840028805
  },
  {
    "city": "Gvardeysk",
    "city_ascii": "Gvardeysk",
    "lat": 54.65,
    "lng": 21.0667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kaliningradskaya Oblast’",
    "capital": "minor",
    "population": 13227,
    "id": 1643013384
  },
  {
    "city": "Woodinville",
    "city_ascii": "Woodinville",
    "lat": 47.7569,
    "lng": -122.1478,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 13263,
    "id": 1840021121
  },
  {
    "city": "Serra Azul",
    "city_ascii": "Serra Azul",
    "lat": -21.3108,
    "lng": -47.5658,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 13216,
    "id": 1076292642
  },
  {
    "city": "Richland Township",
    "city_ascii": "Richland Township",
    "lat": 40.4491,
    "lng": -75.3362,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 13260,
    "id": 1840152881
  },
  {
    "city": "Östringen",
    "city_ascii": "Ostringen",
    "lat": 49.2194,
    "lng": 8.7108,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 13015,
    "id": 1276810660
  },
  {
    "city": "Franconia",
    "city_ascii": "Franconia",
    "lat": 40.3055,
    "lng": -75.359,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 13258,
    "id": 1840102936
  },
  {
    "city": "Union Park",
    "city_ascii": "Union Park",
    "lat": 28.5645,
    "lng": -81.2354,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 13254,
    "id": 1840014090
  },
  {
    "city": "Tonoshō",
    "city_ascii": "Tonosho",
    "lat": 34.4805,
    "lng": 134.1702,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Kagawa",
    "capital": "",
    "population": 13078,
    "id": 1392016671
  },
  {
    "city": "Beverungen",
    "city_ascii": "Beverungen",
    "lat": 51.6628,
    "lng": 9.3725,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 13115,
    "id": 1276597510
  },
  {
    "city": "Andkhōy",
    "city_ascii": "Andkhoy",
    "lat": 36.95,
    "lng": 65.1167,
    "country": "Afghanistan",
    "iso2": "AF",
    "iso3": "AFG",
    "admin_name": "Fāryāb",
    "capital": "minor",
    "population": 13137,
    "id": 1004472345
  },
  {
    "city": "Jasper",
    "city_ascii": "Jasper",
    "lat": 33.8503,
    "lng": -87.2708,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 13248,
    "id": 1840003778
  },
  {
    "city": "Bensville",
    "city_ascii": "Bensville",
    "lat": 38.6176,
    "lng": -77.0077,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 13247,
    "id": 1840073595
  },
  {
    "city": "Medway",
    "city_ascii": "Medway",
    "lat": 42.1535,
    "lng": -71.4291,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 13244,
    "id": 1840053674
  },
  {
    "city": "Chigwell",
    "city_ascii": "Chigwell",
    "lat": 51.6225,
    "lng": 0.0723,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Essex",
    "capital": "",
    "population": 12449,
    "id": 1826768266
  },
  {
    "city": "Sabinov",
    "city_ascii": "Sabinov",
    "lat": 49.1,
    "lng": 21.1,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Prešovský",
    "capital": "minor",
    "population": 12700,
    "id": 1703204865
  },
  {
    "city": "River Ridge",
    "city_ascii": "River Ridge",
    "lat": 29.9593,
    "lng": -90.2202,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 13241,
    "id": 1840013995
  },
  {
    "city": "Channahon",
    "city_ascii": "Channahon",
    "lat": 41.4213,
    "lng": -88.2593,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 13239,
    "id": 1840010214
  },
  {
    "city": "Port Royal",
    "city_ascii": "Port Royal",
    "lat": 32.3557,
    "lng": -80.7029,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 13235,
    "id": 1840016975
  },
  {
    "city": "Verona",
    "city_ascii": "Verona",
    "lat": 42.9893,
    "lng": -89.5383,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 13233,
    "id": 1840002903
  },
  {
    "city": "Ocna Mureş",
    "city_ascii": "Ocna Mures",
    "lat": 46.39,
    "lng": 23.86,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Alba",
    "capital": "",
    "population": 13036,
    "id": 1642234121
  },
  {
    "city": "Bolintin Vale",
    "city_ascii": "Bolintin Vale",
    "lat": 44.4472,
    "lng": 25.7572,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Giurgiu",
    "capital": "",
    "population": 12929,
    "id": 1642230164
  },
  {
    "city": "Clayton",
    "city_ascii": "Clayton",
    "lat": 39.8689,
    "lng": -84.3292,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 13222,
    "id": 1840010584
  },
  {
    "city": "Affoltern am Albis",
    "city_ascii": "Affoltern am Albis",
    "lat": 47.2817,
    "lng": 8.4502,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Zürich",
    "capital": "",
    "population": 12080,
    "id": 1756025824
  },
  {
    "city": "Razlog",
    "city_ascii": "Razlog",
    "lat": 41.8853,
    "lng": 23.4667,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Blagoevgrad",
    "capital": "",
    "population": 13158,
    "id": 1100974105
  },
  {
    "city": "Vila Pouca de Aguiar",
    "city_ascii": "Vila Pouca de Aguiar",
    "lat": 41.4833,
    "lng": -7.6333,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Vila Real",
    "capital": "minor",
    "population": 13187,
    "id": 1620331126
  },
  {
    "city": "Weilburg",
    "city_ascii": "Weilburg",
    "lat": 50.4833,
    "lng": 8.25,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 12990,
    "id": 1276833970
  },
  {
    "city": "Vasylivka",
    "city_ascii": "Vasylivka",
    "lat": 47.4344,
    "lng": 35.2808,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Zaporiz’ka Oblast’",
    "capital": "minor",
    "population": 13166,
    "id": 1804609243
  },
  {
    "city": "Astravyets",
    "city_ascii": "Astravyets",
    "lat": 54.6136,
    "lng": 25.9553,
    "country": "Belarus",
    "iso2": "BY",
    "iso3": "BLR",
    "admin_name": "Hrodzyenskaya Voblasts’",
    "capital": "minor",
    "population": 13200,
    "id": 1112665123
  },
  {
    "city": "Stamboliyski",
    "city_ascii": "Stamboliyski",
    "lat": 42.1344,
    "lng": 24.5353,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Plovdiv",
    "capital": "",
    "population": 12346,
    "id": 1100303839
  },
  {
    "city": "Harrow on the Hill",
    "city_ascii": "Harrow on the Hill",
    "lat": 51.5655,
    "lng": -0.3327,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Harrow",
    "capital": "",
    "population": 12270,
    "id": 1826937930
  },
  {
    "city": "Ansião",
    "city_ascii": "Ansiao",
    "lat": 39.9167,
    "lng": -8.4333,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Leiria",
    "capital": "minor",
    "population": 13128,
    "id": 1620604793
  },
  {
    "city": "Bridgnorth",
    "city_ascii": "Bridgnorth",
    "lat": 52.535,
    "lng": -2.4195,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Shropshire",
    "capital": "",
    "population": 12079,
    "id": 1826579574
  },
  {
    "city": "Miranda do Corvo",
    "city_ascii": "Miranda do Corvo",
    "lat": 40.1,
    "lng": -8.3333,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Coimbra",
    "capital": "minor",
    "population": 13098,
    "id": 1620469916
  },
  {
    "city": "Balatonfüred",
    "city_ascii": "Balatonfured",
    "lat": 46.95,
    "lng": 17.8833,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Veszprém",
    "capital": "minor",
    "population": 12923,
    "id": 1348517997
  },
  {
    "city": "Belëv",
    "city_ascii": "Belev",
    "lat": 53.8,
    "lng": 36.1333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Tul’skaya Oblast’",
    "capital": "",
    "population": 13180,
    "id": 1643708912
  },
  {
    "city": "Baker",
    "city_ascii": "Baker",
    "lat": 30.5833,
    "lng": -91.1581,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 13194,
    "id": 1840013939
  },
  {
    "city": "Brookside",
    "city_ascii": "Brookside",
    "lat": 39.6666,
    "lng": -75.7153,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Delaware",
    "capital": "",
    "population": 13191,
    "id": 1840005569
  },
  {
    "city": "Thames Centre",
    "city_ascii": "Thames Centre",
    "lat": 43.03,
    "lng": -81.08,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 13191,
    "id": 1124000993
  },
  {
    "city": "Krasnozavodsk",
    "city_ascii": "Krasnozavodsk",
    "lat": 56.4381,
    "lng": 38.2294,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Moskovskaya Oblast’",
    "capital": "",
    "population": 13076,
    "id": 1643009707
  },
  {
    "city": "Cedar Lake",
    "city_ascii": "Cedar Lake",
    "lat": 41.3697,
    "lng": -87.4383,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 13183,
    "id": 1840009259
  },
  {
    "city": "Singuilucan",
    "city_ascii": "Singuilucan",
    "lat": 19.9675,
    "lng": -98.5178,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Hidalgo",
    "capital": "minor",
    "population": 13143,
    "id": 1484024966
  },
  {
    "city": "Globe",
    "city_ascii": "Globe",
    "lat": 33.3869,
    "lng": -110.7515,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 13182,
    "id": 1840020533
  },
  {
    "city": "Newton",
    "city_ascii": "Newton",
    "lat": 35.663,
    "lng": -81.2333,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 13177,
    "id": 1840014523
  },
  {
    "city": "Uyar",
    "city_ascii": "Uyar",
    "lat": 55.8167,
    "lng": 94.3167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Krasnoyarskiy Kray",
    "capital": "",
    "population": 13167,
    "id": 1643343542
  },
  {
    "city": "Lower Moreland",
    "city_ascii": "Lower Moreland",
    "lat": 40.1346,
    "lng": -75.0542,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 13176,
    "id": 1840142130
  },
  {
    "city": "Wolfhagen",
    "city_ascii": "Wolfhagen",
    "lat": 51.3272,
    "lng": 9.1709,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 13059,
    "id": 1276757955
  },
  {
    "city": "Sankt Georgen im Schwarzwald",
    "city_ascii": "Sankt Georgen im Schwarzwald",
    "lat": 48.1247,
    "lng": 8.3308,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 12958,
    "id": 1276384519
  },
  {
    "city": "Warrenville",
    "city_ascii": "Warrenville",
    "lat": 41.821,
    "lng": -88.1857,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 13174,
    "id": 1840010164
  },
  {
    "city": "Artondale",
    "city_ascii": "Artondale",
    "lat": 47.3024,
    "lng": -122.6391,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 13172,
    "id": 1840037617
  },
  {
    "city": "Baños",
    "city_ascii": "Banos",
    "lat": -1.3964,
    "lng": -78.4247,
    "country": "Ecuador",
    "iso2": "EC",
    "iso3": "ECU",
    "admin_name": "Tungurahua",
    "capital": "",
    "population": 12995,
    "id": 1218100027
  },
  {
    "city": "Lakeland Village",
    "city_ascii": "Lakeland Village",
    "lat": 33.648,
    "lng": -117.3706,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 13170,
    "id": 1840017989
  },
  {
    "city": "Mississippi Mills",
    "city_ascii": "Mississippi Mills",
    "lat": 45.2167,
    "lng": -76.2,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 13163,
    "id": 1124001617
  },
  {
    "city": "Bonyhád",
    "city_ascii": "Bonyhad",
    "lat": 46.3,
    "lng": 18.53,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Tolna",
    "capital": "minor",
    "population": 12982,
    "id": 1348576105
  },
  {
    "city": "Schleiden",
    "city_ascii": "Schleiden",
    "lat": 50.5331,
    "lng": 6.4667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 13053,
    "id": 1276198974
  },
  {
    "city": "Spreitenbach",
    "city_ascii": "Spreitenbach",
    "lat": 47.4181,
    "lng": 8.3661,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Aargau",
    "capital": "",
    "population": 11788,
    "id": 1756282407
  },
  {
    "city": "Zabaykalsk",
    "city_ascii": "Zabaykalsk",
    "lat": 49.6514,
    "lng": 117.3269,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Zabaykal’skiy Kray",
    "capital": "",
    "population": 13154,
    "id": 1643974760
  },
  {
    "city": "Powell River",
    "city_ascii": "Powell River",
    "lat": 49.8353,
    "lng": -124.5247,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "British Columbia",
    "capital": "",
    "population": 13157,
    "id": 1124154376
  },
  {
    "city": "Sete Barras",
    "city_ascii": "Sete Barras",
    "lat": -24.3878,
    "lng": -47.9258,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 13144,
    "id": 1076515429
  },
  {
    "city": "Waltershausen",
    "city_ascii": "Waltershausen",
    "lat": 50.8975,
    "lng": 10.5558,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Thuringia",
    "capital": "",
    "population": 12973,
    "id": 1276892683
  },
  {
    "city": "St. James",
    "city_ascii": "St. James",
    "lat": 40.8761,
    "lng": -73.1521,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 13155,
    "id": 1840005103
  },
  {
    "city": "Petushki",
    "city_ascii": "Petushki",
    "lat": 55.9333,
    "lng": 39.4667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Vladimirskaya Oblast’",
    "capital": "",
    "population": 13112,
    "id": 1643011606
  },
  {
    "city": "South Glengarry",
    "city_ascii": "South Glengarry",
    "lat": 45.2,
    "lng": -74.5833,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 13150,
    "id": 1124001506
  },
  {
    "city": "Adygeysk",
    "city_ascii": "Adygeysk",
    "lat": 44.88,
    "lng": 39.19,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Adygeya",
    "capital": "minor",
    "population": 12745,
    "id": 1643365200
  },
  {
    "city": "Hajdúsámson",
    "city_ascii": "Hajdusamson",
    "lat": 47.6,
    "lng": 21.7667,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Hajdú-Bihar",
    "capital": "",
    "population": 12961,
    "id": 1348454380
  },
  {
    "city": "Schodack",
    "city_ascii": "Schodack",
    "lat": 42.5297,
    "lng": -73.6858,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 13146,
    "id": 1840087891
  },
  {
    "city": "Adi Keyh",
    "city_ascii": "Adi Keyh",
    "lat": 14.8333,
    "lng": 39.3667,
    "country": "Eritrea",
    "iso2": "ER",
    "iso3": "ERI",
    "admin_name": "Ma’ākel",
    "capital": "",
    "population": 13061,
    "id": 1232384326
  },
  {
    "city": "Sandpoint",
    "city_ascii": "Sandpoint",
    "lat": 48.2822,
    "lng": -116.5613,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Idaho",
    "capital": "",
    "population": 13145,
    "id": 1840021064
  },
  {
    "city": "Loveland",
    "city_ascii": "Loveland",
    "lat": 39.2679,
    "lng": -84.2731,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 13145,
    "id": 1840003809
  },
  {
    "city": "Yavoriv",
    "city_ascii": "Yavoriv",
    "lat": 49.9469,
    "lng": 23.3931,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "L’vivs’ka Oblast’",
    "capital": "minor",
    "population": 13057,
    "id": 1804586607
  },
  {
    "city": "Raymond Terrace",
    "city_ascii": "Raymond Terrace",
    "lat": -32.7615,
    "lng": 151.7441,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 12820,
    "id": 1036570825
  },
  {
    "city": "Bolsover",
    "city_ascii": "Bolsover",
    "lat": 53.2304,
    "lng": -1.2875,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Derbyshire",
    "capital": "",
    "population": 11673,
    "id": 1826732756
  },
  {
    "city": "Trenton",
    "city_ascii": "Trenton",
    "lat": 39.4792,
    "lng": -84.462,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 13141,
    "id": 1840003805
  },
  {
    "city": "Izyum",
    "city_ascii": "Izyum",
    "lat": 49.2238,
    "lng": 37.2915,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Kharkivs’ka Oblast’",
    "capital": "minor",
    "population": 13108,
    "id": 1804385952
  },
  {
    "city": "Tapiratiba",
    "city_ascii": "Tapiratiba",
    "lat": -21.4678,
    "lng": -46.7489,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 13076,
    "id": 1076955040
  },
  {
    "city": "Qulan",
    "city_ascii": "Qulan",
    "lat": 42.9204,
    "lng": 72.705,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Zhambyl",
    "capital": "",
    "population": 13128,
    "id": 1398525079
  },
  {
    "city": "Strunino",
    "city_ascii": "Strunino",
    "lat": 56.3733,
    "lng": 38.585,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Vladimirskaya Oblast’",
    "capital": "",
    "population": 13094,
    "id": 1643212321
  },
  {
    "city": "Short Hills",
    "city_ascii": "Short Hills",
    "lat": 40.7389,
    "lng": -74.3278,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 13134,
    "id": 1840033408
  },
  {
    "city": "North Perth",
    "city_ascii": "North Perth",
    "lat": 43.73,
    "lng": -80.95,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 13130,
    "id": 1124000749
  },
  {
    "city": "Kaspiyskiy",
    "city_ascii": "Kaspiyskiy",
    "lat": 45.3833,
    "lng": 47.3667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kalmykiya",
    "capital": "",
    "population": 13125,
    "id": 1643395852
  },
  {
    "city": "Comines",
    "city_ascii": "Comines",
    "lat": 50.7611,
    "lng": 3.0078,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Hauts-de-France",
    "capital": "",
    "population": 12358,
    "id": 1250749868
  },
  {
    "city": "North Palm Beach",
    "city_ascii": "North Palm Beach",
    "lat": 26.8216,
    "lng": -80.0576,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 13127,
    "id": 1840018307
  },
  {
    "city": "Piratininga",
    "city_ascii": "Piratininga",
    "lat": -22.4155,
    "lng": -49.194,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 13093,
    "id": 1076861726
  },
  {
    "city": "Cypress Lake",
    "city_ascii": "Cypress Lake",
    "lat": 26.5391,
    "lng": -81.9,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 13120,
    "id": 1840029032
  },
  {
    "city": "Mercier",
    "city_ascii": "Mercier",
    "lat": 45.32,
    "lng": -73.75,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 13115,
    "id": 1124186621
  },
  {
    "city": "Stoughton",
    "city_ascii": "Stoughton",
    "lat": 42.9234,
    "lng": -89.2215,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 13114,
    "id": 1840002901
  },
  {
    "city": "Bellefontaine",
    "city_ascii": "Bellefontaine",
    "lat": 40.3627,
    "lng": -83.763,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 13114,
    "id": 1840007233
  },
  {
    "city": "South Stormont",
    "city_ascii": "South Stormont",
    "lat": 45.0833,
    "lng": -74.9667,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 13110,
    "id": 1124001793
  },
  {
    "city": "Fort Drum",
    "city_ascii": "Fort Drum",
    "lat": 44.0451,
    "lng": -75.7847,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 13109,
    "id": 1840073579
  },
  {
    "city": "Warrensville Heights",
    "city_ascii": "Warrensville Heights",
    "lat": 41.4363,
    "lng": -81.5222,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 13108,
    "id": 1840003419
  },
  {
    "city": "Lahaina",
    "city_ascii": "Lahaina",
    "lat": 20.8848,
    "lng": -156.6618,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Hawaii",
    "capital": "",
    "population": 13103,
    "id": 1840023234
  },
  {
    "city": "Plover",
    "city_ascii": "Plover",
    "lat": 44.4614,
    "lng": -89.5383,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 13099,
    "id": 1840002328
  },
  {
    "city": "Martinsville",
    "city_ascii": "Martinsville",
    "lat": 39.4228,
    "lng": -86.4208,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 13097,
    "id": 1840008461
  },
  {
    "city": "Song Phi Nong",
    "city_ascii": "Song Phi Nong",
    "lat": 14.2306,
    "lng": 100.0389,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Suphan Buri",
    "capital": "minor",
    "population": 12926,
    "id": 1764684967
  },
  {
    "city": "Ciudad Insurgentes",
    "city_ascii": "Ciudad Insurgentes",
    "lat": 25.2617,
    "lng": -111.7744,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Baja California Sur",
    "capital": "",
    "population": 11503,
    "id": 1484354076
  },
  {
    "city": "Mahomet",
    "city_ascii": "Mahomet",
    "lat": 40.1885,
    "lng": -88.3904,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 13094,
    "id": 1840012232
  },
  {
    "city": "Olfen",
    "city_ascii": "Olfen",
    "lat": 51.7086,
    "lng": 7.38,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 12846,
    "id": 1276474415
  },
  {
    "city": "Superior",
    "city_ascii": "Superior",
    "lat": 39.9341,
    "lng": -105.1588,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 13087,
    "id": 1840022425
  },
  {
    "city": "Vinings",
    "city_ascii": "Vinings",
    "lat": 33.8608,
    "lng": -84.4686,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 13086,
    "id": 1840013687
  },
  {
    "city": "Lone Tree",
    "city_ascii": "Lone Tree",
    "lat": 39.5309,
    "lng": -104.871,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 13082,
    "id": 1840020222
  },
  {
    "city": "South Daytona",
    "city_ascii": "South Daytona",
    "lat": 29.1656,
    "lng": -81.0056,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 13080,
    "id": 1840015950
  },
  {
    "city": "Newington",
    "city_ascii": "Newington",
    "lat": 38.7358,
    "lng": -77.1993,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 13080,
    "id": 1840006021
  },
  {
    "city": "Saint-Colomban",
    "city_ascii": "Saint-Colomban",
    "lat": 45.73,
    "lng": -74.13,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 13080,
    "id": 1124001676
  },
  {
    "city": "Macará",
    "city_ascii": "Macara",
    "lat": -4.3833,
    "lng": -79.95,
    "country": "Ecuador",
    "iso2": "EC",
    "iso3": "ECU",
    "admin_name": "Loja",
    "capital": "",
    "population": 13035,
    "id": 1218426387
  },
  {
    "city": "Riverdale",
    "city_ascii": "Riverdale",
    "lat": 41.6441,
    "lng": -87.6365,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 13077,
    "id": 1840011286
  },
  {
    "city": "Lumberton",
    "city_ascii": "Lumberton",
    "lat": 30.2562,
    "lng": -94.2071,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 13073,
    "id": 1840020896
  },
  {
    "city": "East Greenwich",
    "city_ascii": "East Greenwich",
    "lat": 41.6362,
    "lng": -71.5058,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Rhode Island",
    "capital": "",
    "population": 13073,
    "id": 1840066133
  },
  {
    "city": "Herzberg am Harz",
    "city_ascii": "Herzberg am Harz",
    "lat": 51.6556,
    "lng": 10.3394,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 12889,
    "id": 1276001736
  },
  {
    "city": "Treuchtlingen",
    "city_ascii": "Treuchtlingen",
    "lat": 48.9553,
    "lng": 10.9094,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 12942,
    "id": 1276141545
  },
  {
    "city": "Moody",
    "city_ascii": "Moody",
    "lat": 33.5986,
    "lng": -86.4963,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 13065,
    "id": 1840014770
  },
  {
    "city": "Monfort Heights",
    "city_ascii": "Monfort Heights",
    "lat": 39.1822,
    "lng": -84.6075,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 13064,
    "id": 1840034325
  },
  {
    "city": "Alaverdi",
    "city_ascii": "Alaverdi",
    "lat": 41.095,
    "lng": 44.6558,
    "country": "Armenia",
    "iso2": "AM",
    "iso3": "ARM",
    "admin_name": "Lorri",
    "capital": "",
    "population": 13000,
    "id": 1051787137
  },
  {
    "city": "New Ulm",
    "city_ascii": "New Ulm",
    "lat": 44.3121,
    "lng": -94.4686,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 13060,
    "id": 1840007894
  },
  {
    "city": "Tetiyiv",
    "city_ascii": "Tetiyiv",
    "lat": 49.3708,
    "lng": 29.69,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Kyyivs’ka Oblast’",
    "capital": "minor",
    "population": 13015,
    "id": 1804600319
  },
  {
    "city": "Hersbruck",
    "city_ascii": "Hersbruck",
    "lat": 49.5081,
    "lng": 11.4328,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 12512,
    "id": 1276272170
  },
  {
    "city": "Vieira do Minho",
    "city_ascii": "Vieira do Minho",
    "lat": 41.6333,
    "lng": -8.1333,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Braga",
    "capital": "minor",
    "population": 12997,
    "id": 1620164873
  },
  {
    "city": "Röthenbach an der Pegnitz",
    "city_ascii": "Rothenbach an der Pegnitz",
    "lat": 49.4847,
    "lng": 11.2475,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 12203,
    "id": 1276836579
  },
  {
    "city": "Lacombe",
    "city_ascii": "Lacombe",
    "lat": 52.4683,
    "lng": -113.7369,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Alberta",
    "capital": "",
    "population": 13057,
    "id": 1124057569
  },
  {
    "city": "Honiton",
    "city_ascii": "Honiton",
    "lat": 50.8,
    "lng": -3.19,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Devon",
    "capital": "",
    "population": 11822,
    "id": 1826581914
  },
  {
    "city": "Alcácer do Sal",
    "city_ascii": "Alcacer do Sal",
    "lat": 38.3725,
    "lng": -8.5133,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Setúbal",
    "capital": "minor",
    "population": 13046,
    "id": 1620351188
  },
  {
    "city": "East Milton",
    "city_ascii": "East Milton",
    "lat": 30.6175,
    "lng": -86.9636,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 13054,
    "id": 1840025112
  },
  {
    "city": "Davidson",
    "city_ascii": "Davidson",
    "lat": 35.4846,
    "lng": -80.8252,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 13054,
    "id": 1840016353
  },
  {
    "city": "Edgewood",
    "city_ascii": "Edgewood",
    "lat": 47.2309,
    "lng": -122.2832,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 13053,
    "id": 1840019852
  },
  {
    "city": "Suamico",
    "city_ascii": "Suamico",
    "lat": 44.6352,
    "lng": -88.0664,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 13052,
    "id": 1840000309
  },
  {
    "city": "Farmington",
    "city_ascii": "Farmington",
    "lat": 42.9894,
    "lng": -77.3087,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 13049,
    "id": 1840058090
  },
  {
    "city": "San Marino",
    "city_ascii": "San Marino",
    "lat": 34.1224,
    "lng": -118.1132,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 13048,
    "id": 1840021863
  },
  {
    "city": "Čelákovice",
    "city_ascii": "Celakovice",
    "lat": 50.1605,
    "lng": 14.7501,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Středočeský Kraj",
    "capital": "",
    "population": 12273,
    "id": 1203396532
  },
  {
    "city": "Esperance",
    "city_ascii": "Esperance",
    "lat": -33.8611,
    "lng": 121.8919,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Western Australia",
    "capital": "",
    "population": 12145,
    "id": 1036299314
  },
  {
    "city": "Parigi",
    "city_ascii": "Parigi",
    "lat": 13.8929,
    "lng": 77.4598,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Andhra Pradesh",
    "capital": "",
    "population": 12614,
    "id": 1356618142
  },
  {
    "city": "Mariánské Lázně",
    "city_ascii": "Marianske Lazne",
    "lat": 49.9647,
    "lng": 12.7012,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Karlovarský Kraj",
    "capital": "",
    "population": 12795,
    "id": 1203682983
  },
  {
    "city": "Newtown Township",
    "city_ascii": "Newtown Township",
    "lat": 39.992,
    "lng": -75.4106,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 13039,
    "id": 1840152845
  },
  {
    "city": "Muswellbrook",
    "city_ascii": "Muswellbrook",
    "lat": -32.2654,
    "lng": 150.8885,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 12364,
    "id": 1036253543
  },
  {
    "city": "Trentham",
    "city_ascii": "Trentham",
    "lat": 52.9663,
    "lng": -2.1899,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Staffordshire",
    "capital": "",
    "population": 11836,
    "id": 1826068717
  },
  {
    "city": "Morungaba",
    "city_ascii": "Morungaba",
    "lat": -22.88,
    "lng": -46.7917,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 12934,
    "id": 1076597172
  },
  {
    "city": "Oxford",
    "city_ascii": "Oxford",
    "lat": 41.4313,
    "lng": -73.135,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 13022,
    "id": 1840035533
  },
  {
    "city": "Colesberg",
    "city_ascii": "Colesberg",
    "lat": -30.7167,
    "lng": 25.1,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "Northern Cape",
    "capital": "",
    "population": 12423,
    "id": 1710885210
  },
  {
    "city": "Chatham",
    "city_ascii": "Chatham",
    "lat": 39.6733,
    "lng": -89.6938,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 13008,
    "id": 1840010560
  },
  {
    "city": "Kanel",
    "city_ascii": "Kanel",
    "lat": 15.4833,
    "lng": -13.1667,
    "country": "Senegal",
    "iso2": "SN",
    "iso3": "SEN",
    "admin_name": "Matam",
    "capital": "",
    "population": 12975,
    "id": 1686649291
  },
  {
    "city": "Al Burayqah",
    "city_ascii": "Al Burayqah",
    "lat": 30.4061,
    "lng": 19.5739,
    "country": "Libya",
    "iso2": "LY",
    "iso3": "LBY",
    "admin_name": "Al Wāḩāt",
    "capital": "",
    "population": 13000,
    "id": 1434516624
  },
  {
    "city": "Sooke",
    "city_ascii": "Sooke",
    "lat": 48.3761,
    "lng": -123.7378,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "British Columbia",
    "capital": "",
    "population": 13001,
    "id": 1124034713
  },
  {
    "city": "Bellefonte",
    "city_ascii": "Bellefonte",
    "lat": 40.9141,
    "lng": -77.7683,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 12998,
    "id": 1840000822
  },
  {
    "city": "Rozhyshche",
    "city_ascii": "Rozhyshche",
    "lat": 50.9131,
    "lng": 25.27,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Volyns’ka Oblast’",
    "capital": "minor",
    "population": 12953,
    "id": 1804390315
  },
  {
    "city": "Haselünne",
    "city_ascii": "Haselunne",
    "lat": 52.6667,
    "lng": 7.4667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 12914,
    "id": 1276706458
  },
  {
    "city": "Wolverton",
    "city_ascii": "Wolverton",
    "lat": 52.0626,
    "lng": -0.8102,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Milton Keynes",
    "capital": "",
    "population": 12492,
    "id": 1826613545
  },
  {
    "city": "Brzeg Dolny",
    "city_ascii": "Brzeg Dolny",
    "lat": 51.273,
    "lng": 16.7081,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Dolnośląskie",
    "capital": "",
    "population": 12816,
    "id": 1616585341
  },
  {
    "city": "McPherson",
    "city_ascii": "McPherson",
    "lat": 38.3714,
    "lng": -97.6605,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kansas",
    "capital": "",
    "population": 12989,
    "id": 1840001659
  },
  {
    "city": "Forest Hill",
    "city_ascii": "Forest Hill",
    "lat": 32.6619,
    "lng": -97.2662,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 12988,
    "id": 1840020695
  },
  {
    "city": "Piketberg",
    "city_ascii": "Piketberg",
    "lat": -32.9,
    "lng": 18.7667,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "Western Cape",
    "capital": "",
    "population": 12075,
    "id": 1710229097
  },
  {
    "city": "Gornyak",
    "city_ascii": "Gornyak",
    "lat": 51,
    "lng": 81.4667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Altayskiy Kray",
    "capital": "",
    "population": 12972,
    "id": 1643814454
  },
  {
    "city": "Dawson Creek",
    "city_ascii": "Dawson Creek",
    "lat": 55.7606,
    "lng": -120.2356,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "British Columbia",
    "capital": "",
    "population": 12978,
    "id": 1124081402
  },
  {
    "city": "Schongau",
    "city_ascii": "Schongau",
    "lat": 47.8167,
    "lng": 10.9,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 12396,
    "id": 1276690057
  },
  {
    "city": "Clinton",
    "city_ascii": "Clinton",
    "lat": 41.298,
    "lng": -72.53,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 12976,
    "id": 1840034364
  },
  {
    "city": "Timberlake",
    "city_ascii": "Timberlake",
    "lat": 37.3167,
    "lng": -79.2482,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 12973,
    "id": 1840006437
  },
  {
    "city": "Spring Garden",
    "city_ascii": "Spring Garden",
    "lat": 39.9454,
    "lng": -76.7212,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 12971,
    "id": 1840147124
  },
  {
    "city": "Ulubey",
    "city_ascii": "Ulubey",
    "lat": 38.4216,
    "lng": 29.2895,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Uşak",
    "capital": "minor",
    "population": 12955,
    "id": 1792931185
  },
  {
    "city": "Byala Slatina",
    "city_ascii": "Byala Slatina",
    "lat": 43.4667,
    "lng": 23.9333,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Vratsa",
    "capital": "",
    "population": 12935,
    "id": 1100994011
  },
  {
    "city": "York",
    "city_ascii": "York",
    "lat": 43.186,
    "lng": -70.6661,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maine",
    "capital": "",
    "population": 12966,
    "id": 1840066551
  },
  {
    "city": "Braselton",
    "city_ascii": "Braselton",
    "lat": 34.1088,
    "lng": -83.8128,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 12961,
    "id": 1840015596
  },
  {
    "city": "Syston",
    "city_ascii": "Syston",
    "lat": 52.7,
    "lng": -1.08,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Leicestershire",
    "capital": "",
    "population": 12804,
    "id": 1826679514
  },
  {
    "city": "Lapeer",
    "city_ascii": "Lapeer",
    "lat": 43.0447,
    "lng": -83.3254,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 12959,
    "id": 1840002896
  },
  {
    "city": "Sulingen",
    "city_ascii": "Sulingen",
    "lat": 52.6667,
    "lng": 8.8,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 12842,
    "id": 1276180018
  },
  {
    "city": "Clinton",
    "city_ascii": "Clinton",
    "lat": 40.6315,
    "lng": -74.8553,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 12957,
    "id": 1840003604
  },
  {
    "city": "Herk-de-Stad",
    "city_ascii": "Herk-de-Stad",
    "lat": 50.9406,
    "lng": 5.1672,
    "country": "Belgium",
    "iso2": "BE",
    "iso3": "BEL",
    "admin_name": "Flanders",
    "capital": "",
    "population": 12661,
    "id": 1056088942
  },
  {
    "city": "Dunmore",
    "city_ascii": "Dunmore",
    "lat": 41.4152,
    "lng": -75.6072,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 12954,
    "id": 1840003381
  },
  {
    "city": "Crayford",
    "city_ascii": "Crayford",
    "lat": 51.4491,
    "lng": 0.1812,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Kent",
    "capital": "",
    "population": 11226,
    "id": 1826027984
  },
  {
    "city": "Flitwick",
    "city_ascii": "Flitwick",
    "lat": 52.0038,
    "lng": -0.4966,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Central Bedfordshire",
    "capital": "",
    "population": 12700,
    "id": 1826751494
  },
  {
    "city": "Kittanning",
    "city_ascii": "Kittanning",
    "lat": 40.8279,
    "lng": -79.5233,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 12950,
    "id": 1840003519
  },
  {
    "city": "Kūshk",
    "city_ascii": "Kushk",
    "lat": 32.6425,
    "lng": 51.4997,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Eşfahān",
    "capital": "",
    "population": 11264,
    "id": 1364515692
  },
  {
    "city": "Worthington",
    "city_ascii": "Worthington",
    "lat": 43.6281,
    "lng": -95.599,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 12947,
    "id": 1840010036
  },
  {
    "city": "Vöcklabruck",
    "city_ascii": "Vocklabruck",
    "lat": 48.0086,
    "lng": 13.6558,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Oberösterreich",
    "capital": "minor",
    "population": 12179,
    "id": 1040721177
  },
  {
    "city": "Ribeirão Bonito",
    "city_ascii": "Ribeirao Bonito",
    "lat": -22.0669,
    "lng": -48.1758,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 12909,
    "id": 1076447966
  },
  {
    "city": "Fort Lewis",
    "city_ascii": "Fort Lewis",
    "lat": 47.0955,
    "lng": -122.5672,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 12933,
    "id": 1840073933
  },
  {
    "city": "Sharon",
    "city_ascii": "Sharon",
    "lat": 41.234,
    "lng": -80.4997,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 12933,
    "id": 1840003441
  },
  {
    "city": "El Qâa",
    "city_ascii": "El Qaa",
    "lat": 34.3436,
    "lng": 36.4756,
    "country": "Lebanon",
    "iso2": "LB",
    "iso3": "LBN",
    "admin_name": "Baalbek-Hermel",
    "capital": "",
    "population": 12000,
    "id": 1422667021
  },
  {
    "city": "Oakland",
    "city_ascii": "Oakland",
    "lat": 41.0313,
    "lng": -74.2408,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 12926,
    "id": 1840000912
  },
  {
    "city": "Amity",
    "city_ascii": "Amity",
    "lat": 40.2905,
    "lng": -75.7477,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 12924,
    "id": 1840151686
  },
  {
    "city": "Stolin",
    "city_ascii": "Stolin",
    "lat": 51.8897,
    "lng": 26.8507,
    "country": "Belarus",
    "iso2": "BY",
    "iso3": "BLR",
    "admin_name": "Brestskaya Voblasts’",
    "capital": "",
    "population": 12900,
    "id": 1112925605
  },
  {
    "city": "Lake Country",
    "city_ascii": "Lake Country",
    "lat": 50.0833,
    "lng": -119.4142,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "British Columbia",
    "capital": "",
    "population": 12922,
    "id": 1124001544
  },
  {
    "city": "Veľký Krtíš",
    "city_ascii": "Vel'ky Krtis",
    "lat": 48.215,
    "lng": 19.3381,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Banskobystrický",
    "capital": "minor",
    "population": 12115,
    "id": 1703541479
  },
  {
    "city": "Pfarrkirchen",
    "city_ascii": "Pfarrkirchen",
    "lat": 48.4419,
    "lng": 12.9443,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "minor",
    "population": 12677,
    "id": 1276885935
  },
  {
    "city": "Mount Vernon",
    "city_ascii": "Mount Vernon",
    "lat": 38.714,
    "lng": -77.1043,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 12919,
    "id": 1840006020
  },
  {
    "city": "Chilca",
    "city_ascii": "Chilca",
    "lat": -12.5196,
    "lng": -76.74,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "Lima",
    "capital": "",
    "population": 12884,
    "id": 1604361895
  },
  {
    "city": "Sim",
    "city_ascii": "Sim",
    "lat": 54.9833,
    "lng": 57.6833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Chelyabinskaya Oblast’",
    "capital": "",
    "population": 12893,
    "id": 1643571460
  },
  {
    "city": "Dorog",
    "city_ascii": "Dorog",
    "lat": 47.7194,
    "lng": 18.7292,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Komárom-Esztergom",
    "capital": "",
    "population": 11883,
    "id": 1348677837
  },
  {
    "city": "Avrig",
    "city_ascii": "Avrig",
    "lat": 45.7081,
    "lng": 24.4,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Sibiu",
    "capital": "",
    "population": 12815,
    "id": 1642698711
  },
  {
    "city": "Ch’iatura",
    "city_ascii": "Ch'iatura",
    "lat": 42.2833,
    "lng": 43.2833,
    "country": "Georgia",
    "iso2": "GE",
    "iso3": "GEO",
    "admin_name": "Imereti",
    "capital": "minor",
    "population": 12803,
    "id": 1268109210
  },
  {
    "city": "Frutillar",
    "city_ascii": "Frutillar",
    "lat": -41.1258,
    "lng": -73.0605,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Los Lagos",
    "capital": "",
    "population": 12876,
    "id": 1152151773
  },
  {
    "city": "Krupka",
    "city_ascii": "Krupka",
    "lat": 50.6846,
    "lng": 13.8583,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Ústecký Kraj",
    "capital": "",
    "population": 12633,
    "id": 1203244914
  },
  {
    "city": "Rocky Point",
    "city_ascii": "Rocky Point",
    "lat": 40.9357,
    "lng": -72.9364,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 12902,
    "id": 1840005070
  },
  {
    "city": "Wawarsing",
    "city_ascii": "Wawarsing",
    "lat": 41.7526,
    "lng": -74.4172,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 12900,
    "id": 1840058585
  },
  {
    "city": "Trent Hills",
    "city_ascii": "Trent Hills",
    "lat": 44.3142,
    "lng": -77.8514,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 12900,
    "id": 1124001755
  },
  {
    "city": "Möckern",
    "city_ascii": "Mockern",
    "lat": 52.1406,
    "lng": 11.9525,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony-Anhalt",
    "capital": "",
    "population": 12874,
    "id": 1276562892
  },
  {
    "city": "Sidmouth",
    "city_ascii": "Sidmouth",
    "lat": 50.68,
    "lng": -3.239,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Devon",
    "capital": "",
    "population": 12569,
    "id": 1826699367
  },
  {
    "city": "Lubaczów",
    "city_ascii": "Lubaczow",
    "lat": 50.1556,
    "lng": 23.123,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Podkarpackie",
    "capital": "minor",
    "population": 12415,
    "id": 1616650009
  },
  {
    "city": "Kaeng Khoi",
    "city_ascii": "Kaeng Khoi",
    "lat": 14.5864,
    "lng": 100.9967,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Sara Buri",
    "capital": "minor",
    "population": 12752,
    "id": 1764167813
  },
  {
    "city": "Jaroměř",
    "city_ascii": "Jaromer",
    "lat": 50.3562,
    "lng": 15.9214,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Královéhradecký Kraj",
    "capital": "",
    "population": 12378,
    "id": 1203124128
  },
  {
    "city": "Vyazemskiy",
    "city_ascii": "Vyazemskiy",
    "lat": 47.5333,
    "lng": 134.75,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Khabarovskiy Kray",
    "capital": "",
    "population": 12889,
    "id": 1643608053
  },
  {
    "city": "Irondale",
    "city_ascii": "Irondale",
    "lat": 33.544,
    "lng": -86.6599,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 12893,
    "id": 1840014797
  },
  {
    "city": "Hemsbach",
    "city_ascii": "Hemsbach",
    "lat": 49.5903,
    "lng": 8.6564,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 11968,
    "id": 1276078759
  },
  {
    "city": "Maglód",
    "city_ascii": "Maglod",
    "lat": 47.4439,
    "lng": 19.3525,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Pest",
    "capital": "",
    "population": 12341,
    "id": 1348383412
  },
  {
    "city": "Weinsberg",
    "city_ascii": "Weinsberg",
    "lat": 49.1519,
    "lng": 9.2858,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 12336,
    "id": 1276634003
  },
  {
    "city": "Tierra Amarilla",
    "city_ascii": "Tierra Amarilla",
    "lat": -27.48,
    "lng": -70.28,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Atacama",
    "capital": "",
    "population": 12888,
    "id": 1152576362
  },
  {
    "city": "Sainte-Marie",
    "city_ascii": "Sainte-Marie",
    "lat": 46.45,
    "lng": -71.0333,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 12889,
    "id": 1124650507
  },
  {
    "city": "Dorking",
    "city_ascii": "Dorking",
    "lat": 51.234,
    "lng": -0.3318,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Surrey",
    "capital": "",
    "population": 11185,
    "id": 1826338951
  },
  {
    "city": "Colonial Park",
    "city_ascii": "Colonial Park",
    "lat": 40.2997,
    "lng": -76.8068,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 12886,
    "id": 1840005398
  },
  {
    "city": "Nobeji",
    "city_ascii": "Nobeji",
    "lat": 40.8644,
    "lng": 141.1286,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Aomori",
    "capital": "",
    "population": 12728,
    "id": 1392690088
  },
  {
    "city": "Fallsburg",
    "city_ascii": "Fallsburg",
    "lat": 41.7391,
    "lng": -74.6038,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 12882,
    "id": 1840058089
  },
  {
    "city": "Pine Township",
    "city_ascii": "Pine Township",
    "lat": 40.6437,
    "lng": -80.0315,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 12881,
    "id": 1840147147
  },
  {
    "city": "Sturgis",
    "city_ascii": "Sturgis",
    "lat": 41.7995,
    "lng": -85.4182,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 12881,
    "id": 1840003254
  },
  {
    "city": "Loganville",
    "city_ascii": "Loganville",
    "lat": 33.8353,
    "lng": -83.8957,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 12880,
    "id": 1840015656
  },
  {
    "city": "Weigelstown",
    "city_ascii": "Weigelstown",
    "lat": 39.9843,
    "lng": -76.8315,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 12879,
    "id": 1840005509
  },
  {
    "city": "Brugg",
    "city_ascii": "Brugg",
    "lat": 47.4864,
    "lng": 8.2083,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Aargau",
    "capital": "",
    "population": 11129,
    "id": 1756121125
  },
  {
    "city": "Bassersdorf",
    "city_ascii": "Bassersdorf",
    "lat": 47.4431,
    "lng": 8.6283,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Zürich",
    "capital": "",
    "population": 11593,
    "id": 1756124438
  },
  {
    "city": "Lawrenceburg",
    "city_ascii": "Lawrenceburg",
    "lat": 39.0986,
    "lng": -84.8713,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 12871,
    "id": 1840008523
  },
  {
    "city": "Marumori",
    "city_ascii": "Marumori",
    "lat": 37.9114,
    "lng": 140.7656,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Miyagi",
    "capital": "",
    "population": 12823,
    "id": 1392129975
  },
  {
    "city": "West Bradford",
    "city_ascii": "West Bradford",
    "lat": 39.9633,
    "lng": -75.716,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 12869,
    "id": 1840145641
  },
  {
    "city": "Mora",
    "city_ascii": "Mora",
    "lat": 61.0096,
    "lng": 14.5635,
    "country": "Sweden",
    "iso2": "SE",
    "iso3": "SWE",
    "admin_name": "Dalarna",
    "capital": "minor",
    "population": 12858,
    "id": 1752665392
  },
  {
    "city": "West Carrollton",
    "city_ascii": "West Carrollton",
    "lat": 39.6701,
    "lng": -84.2542,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 12864,
    "id": 1840059955
  },
  {
    "city": "Kilindoni",
    "city_ascii": "Kilindoni",
    "lat": -7.9163,
    "lng": 39.65,
    "country": "Tanzania",
    "iso2": "TZ",
    "iso3": "TZA",
    "admin_name": "Coast",
    "capital": "",
    "population": 12409,
    "id": 1834982666
  },
  {
    "city": "Chorleywood",
    "city_ascii": "Chorleywood",
    "lat": 51.65,
    "lng": -0.51,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hertfordshire",
    "capital": "",
    "population": 11286,
    "id": 1826183168
  },
  {
    "city": "Oxapampa",
    "city_ascii": "Oxapampa",
    "lat": -10.5775,
    "lng": -75.4017,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "Pasco",
    "capital": "",
    "population": 12826,
    "id": 1604643521
  },
  {
    "city": "East Hampton",
    "city_ascii": "East Hampton",
    "lat": 41.5696,
    "lng": -72.5074,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 12856,
    "id": 1840034616
  },
  {
    "city": "Camabatela",
    "city_ascii": "Camabatela",
    "lat": -8.1896,
    "lng": 15.37,
    "country": "Angola",
    "iso2": "AO",
    "iso3": "AGO",
    "admin_name": "Kwanza Norte",
    "capital": "",
    "population": 12837,
    "id": 1024701540
  },
  {
    "city": "Guelph/Eramosa",
    "city_ascii": "Guelph/Eramosa",
    "lat": 43.63,
    "lng": -80.22,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 12854,
    "id": 1124001707
  },
  {
    "city": "Glen Carbon",
    "city_ascii": "Glen Carbon",
    "lat": 38.758,
    "lng": -89.983,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 12850,
    "id": 1840012795
  },
  {
    "city": "Parker",
    "city_ascii": "Parker",
    "lat": 34.8514,
    "lng": -82.4512,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 12850,
    "id": 1840035848
  },
  {
    "city": "Lynnfield",
    "city_ascii": "Lynnfield",
    "lat": 42.5356,
    "lng": -71.0382,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 12847,
    "id": 1840053531
  },
  {
    "city": "Bershad’",
    "city_ascii": "Bershad'",
    "lat": 48.3728,
    "lng": 29.5325,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Vinnyts’ka Oblast’",
    "capital": "minor",
    "population": 12795,
    "id": 1804698312
  },
  {
    "city": "Berwick-Upon-Tweed",
    "city_ascii": "Berwick-Upon-Tweed",
    "lat": 55.771,
    "lng": -2.007,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Northumberland",
    "capital": "",
    "population": 12043,
    "id": 1826037734
  },
  {
    "city": "Tsuruta",
    "city_ascii": "Tsuruta",
    "lat": 40.7589,
    "lng": 140.4286,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Aomori",
    "capital": "",
    "population": 12569,
    "id": 1392736759
  },
  {
    "city": "Syasstroy",
    "city_ascii": "Syasstroy",
    "lat": 60.1333,
    "lng": 32.5667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Leningradskaya Oblast’",
    "capital": "",
    "population": 12824,
    "id": 1643899356
  },
  {
    "city": "Spearfish",
    "city_ascii": "Spearfish",
    "lat": 44.4912,
    "lng": -103.8167,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Dakota",
    "capital": "",
    "population": 12836,
    "id": 1840002374
  },
  {
    "city": "Újfehértó",
    "city_ascii": "Ujfeherto",
    "lat": 47.8,
    "lng": 21.6833,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Szabolcs-Szatmár-Bereg",
    "capital": "",
    "population": 12736,
    "id": 1348703777
  },
  {
    "city": "Gyomaendrőd",
    "city_ascii": "Gyomaendrod",
    "lat": 46.9361,
    "lng": 20.8233,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Békés",
    "capital": "minor",
    "population": 12784,
    "id": 1348198279
  },
  {
    "city": "Los Lagos",
    "city_ascii": "Los Lagos",
    "lat": -39.8495,
    "lng": -72.83,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Los Ríos",
    "capital": "",
    "population": 12813,
    "id": 1152340454
  },
  {
    "city": "Truro",
    "city_ascii": "Truro",
    "lat": 45.3647,
    "lng": -63.28,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Nova Scotia",
    "capital": "",
    "population": 12826,
    "id": 1124952899
  },
  {
    "city": "Steinheim",
    "city_ascii": "Steinheim",
    "lat": 51.8658,
    "lng": 9.0944,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 12657,
    "id": 1276434002
  },
  {
    "city": "Amos",
    "city_ascii": "Amos",
    "lat": 48.5667,
    "lng": -78.1167,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 12823,
    "id": 1124939649
  },
  {
    "city": "Lakeland North",
    "city_ascii": "Lakeland North",
    "lat": 47.3374,
    "lng": -122.2812,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 12820,
    "id": 1840074254
  },
  {
    "city": "Cedartown",
    "city_ascii": "Cedartown",
    "lat": 34.0223,
    "lng": -85.2479,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 12819,
    "id": 1840014752
  },
  {
    "city": "Diébougou",
    "city_ascii": "Diebougou",
    "lat": 10.952,
    "lng": -3.248,
    "country": "Burkina Faso",
    "iso2": "BF",
    "iso3": "BFA",
    "admin_name": "Sud-Ouest",
    "capital": "minor",
    "population": 12732,
    "id": 1854858995
  },
  {
    "city": "Malaryta",
    "city_ascii": "Malaryta",
    "lat": 51.7972,
    "lng": 24.0808,
    "country": "Belarus",
    "iso2": "BY",
    "iso3": "BLR",
    "admin_name": "Brestskaya Voblasts’",
    "capital": "minor",
    "population": 12800,
    "id": 1112089191
  },
  {
    "city": "Dubak",
    "city_ascii": "Dubak",
    "lat": 18.1914,
    "lng": 78.6783,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Telangana",
    "capital": "",
    "population": 12349,
    "id": 1356243033
  },
  {
    "city": "Pecica",
    "city_ascii": "Pecica",
    "lat": 46.17,
    "lng": 21.07,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Arad",
    "capital": "",
    "population": 12762,
    "id": 1642561590
  },
  {
    "city": "Gorokhovets",
    "city_ascii": "Gorokhovets",
    "lat": 56.2028,
    "lng": 42.6925,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Vladimirskaya Oblast’",
    "capital": "",
    "population": 12799,
    "id": 1643628827
  },
  {
    "city": "Hajdúhadház",
    "city_ascii": "Hajduhadhaz",
    "lat": 47.6833,
    "lng": 21.6667,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Hajdú-Bihar",
    "capital": "minor",
    "population": 12669,
    "id": 1348644629
  },
  {
    "city": "Cloquet",
    "city_ascii": "Cloquet",
    "lat": 46.7221,
    "lng": -92.4923,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 12813,
    "id": 1840006646
  },
  {
    "city": "Betma",
    "city_ascii": "Betma",
    "lat": 22.68,
    "lng": 75.62,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Madhya Pradesh",
    "capital": "",
    "population": 12529,
    "id": 1356357879
  },
  {
    "city": "Lilburn",
    "city_ascii": "Lilburn",
    "lat": 33.8887,
    "lng": -84.1379,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 12810,
    "id": 1840015613
  },
  {
    "city": "South Sioux City",
    "city_ascii": "South Sioux City",
    "lat": 42.4627,
    "lng": -96.4126,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Nebraska",
    "capital": "",
    "population": 12809,
    "id": 1840009129
  },
  {
    "city": "Simeria",
    "city_ascii": "Simeria",
    "lat": 45.85,
    "lng": 23.01,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Hunedoara",
    "capital": "",
    "population": 12556,
    "id": 1642409253
  },
  {
    "city": "Maryville",
    "city_ascii": "Maryville",
    "lat": 40.3428,
    "lng": -94.8701,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 12808,
    "id": 1840008349
  },
  {
    "city": "The Nation / La Nation",
    "city_ascii": "The Nation / La Nation",
    "lat": 45.35,
    "lng": -75.0333,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 12808,
    "id": 1124001243
  },
  {
    "city": "Taldom",
    "city_ascii": "Taldom",
    "lat": 56.7333,
    "lng": 37.5333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Moskovskaya Oblast’",
    "capital": "",
    "population": 12777,
    "id": 1643222038
  },
  {
    "city": "Franklin",
    "city_ascii": "Franklin",
    "lat": 29.7851,
    "lng": -91.5098,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 12803,
    "id": 1840014019
  },
  {
    "city": "Churchdown",
    "city_ascii": "Churchdown",
    "lat": 51.88,
    "lng": -2.17,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Gloucestershire",
    "capital": "",
    "population": 10990,
    "id": 1826299477
  },
  {
    "city": "Pichilemu",
    "city_ascii": "Pichilemu",
    "lat": -34.3851,
    "lng": -72.0049,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Libertador General Bernardo O’Higgins",
    "capital": "minor",
    "population": 12776,
    "id": 1152498637
  },
  {
    "city": "Bamber Bridge",
    "city_ascii": "Bamber Bridge",
    "lat": 53.7281,
    "lng": -2.6606,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Lancashire",
    "capital": "",
    "population": 12126,
    "id": 1826794193
  },
  {
    "city": "Sankt Veit an der Glan",
    "city_ascii": "Sankt Veit an der Glan",
    "lat": 46.7667,
    "lng": 14.3603,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Kärnten",
    "capital": "minor",
    "population": 12547,
    "id": 1040281881
  },
  {
    "city": "Ascot",
    "city_ascii": "Ascot",
    "lat": 51.4084,
    "lng": -0.6707,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Windsor and Maidenhead",
    "capital": "",
    "population": 11603,
    "id": 1826467973
  },
  {
    "city": "Wächtersbach",
    "city_ascii": "Wachtersbach",
    "lat": 50.2667,
    "lng": 9.3,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 12542,
    "id": 1276414935
  },
  {
    "city": "Vrchlabí",
    "city_ascii": "Vrchlabi",
    "lat": 50.627,
    "lng": 15.6095,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Královéhradecký Kraj",
    "capital": "",
    "population": 12340,
    "id": 1203953730
  },
  {
    "city": "Linnich",
    "city_ascii": "Linnich",
    "lat": 50.9789,
    "lng": 6.2678,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 12593,
    "id": 1276427998
  },
  {
    "city": "Kalyazin",
    "city_ascii": "Kalyazin",
    "lat": 57.2333,
    "lng": 37.85,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Tverskaya Oblast’",
    "capital": "",
    "population": 12770,
    "id": 1643298637
  },
  {
    "city": "Hadamar",
    "city_ascii": "Hadamar",
    "lat": 50.45,
    "lng": 8.05,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 12480,
    "id": 1276022783
  },
  {
    "city": "Santa Ana de Yacuma",
    "city_ascii": "Santa Ana de Yacuma",
    "lat": -13.7444,
    "lng": -65.4269,
    "country": "Bolivia",
    "iso2": "BO",
    "iso3": "BOL",
    "admin_name": "El Beni",
    "capital": "",
    "population": 12783,
    "id": 1068925682
  },
  {
    "city": "Burj al ‘Arab",
    "city_ascii": "Burj al `Arab",
    "lat": 30.9167,
    "lng": 29.5333,
    "country": "Egypt",
    "iso2": "EG",
    "iso3": "EGY",
    "admin_name": "Al Iskandarīyah",
    "capital": "",
    "population": 12625,
    "id": 1818114873
  },
  {
    "city": "San Francisco de Mostazal",
    "city_ascii": "San Francisco de Mostazal",
    "lat": -33.9799,
    "lng": -70.7122,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Libertador General Bernardo O’Higgins",
    "capital": "",
    "population": 12719,
    "id": 1152382411
  },
  {
    "city": "Santiago Amoltepec",
    "city_ascii": "Santiago Amoltepec",
    "lat": 16.6167,
    "lng": -97.5,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Oaxaca",
    "capital": "",
    "population": 12683,
    "id": 1484936827
  },
  {
    "city": "Lake Tapps",
    "city_ascii": "Lake Tapps",
    "lat": 47.2307,
    "lng": -122.1694,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 12771,
    "id": 1840041971
  },
  {
    "city": "Glendale",
    "city_ascii": "Glendale",
    "lat": 43.1288,
    "lng": -87.9277,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 12768,
    "id": 1840003037
  },
  {
    "city": "Warsop",
    "city_ascii": "Warsop",
    "lat": 53.2,
    "lng": -1.15,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Nottinghamshire",
    "capital": "",
    "population": 11999,
    "id": 1826909482
  },
  {
    "city": "Othello",
    "city_ascii": "Othello",
    "lat": 46.8221,
    "lng": -119.1652,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 12761,
    "id": 1840019862
  },
  {
    "city": "Mineiros do Tietê",
    "city_ascii": "Mineiros do Tiete",
    "lat": -22.4089,
    "lng": -48.4508,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 12700,
    "id": 1076206689
  },
  {
    "city": "Joanópolis",
    "city_ascii": "Joanopolis",
    "lat": -22.9303,
    "lng": -46.2756,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 12725,
    "id": 1076442097
  },
  {
    "city": "Gulf Shores",
    "city_ascii": "Gulf Shores",
    "lat": 30.2759,
    "lng": -87.7013,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 12757,
    "id": 1840013886
  },
  {
    "city": "Ingersoll",
    "city_ascii": "Ingersoll",
    "lat": 43.0392,
    "lng": -80.8836,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 12757,
    "id": 1124716784
  },
  {
    "city": "Dorridge",
    "city_ascii": "Dorridge",
    "lat": 52.372,
    "lng": -1.7554,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Solihull",
    "capital": "",
    "population": 11140,
    "id": 1826008544
  },
  {
    "city": "Avanhandava",
    "city_ascii": "Avanhandava",
    "lat": -21.4608,
    "lng": -49.9497,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 12719,
    "id": 1076708682
  },
  {
    "city": "Tarboro",
    "city_ascii": "Tarboro",
    "lat": 35.9046,
    "lng": -77.5563,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 12748,
    "id": 1840016177
  },
  {
    "city": "Medfield",
    "city_ascii": "Medfield",
    "lat": 42.1848,
    "lng": -71.305,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 12748,
    "id": 1840053552
  },
  {
    "city": "Steinheim am der Murr",
    "city_ascii": "Steinheim am der Murr",
    "lat": 48.9667,
    "lng": 9.2833,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 12220,
    "id": 1276793728
  },
  {
    "city": "Brunsbüttel",
    "city_ascii": "Brunsbuttel",
    "lat": 53.8964,
    "lng": 9.1386,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Schleswig-Holstein",
    "capital": "",
    "population": 12554,
    "id": 1276581579
  },
  {
    "city": "Wickliffe",
    "city_ascii": "Wickliffe",
    "lat": 41.6072,
    "lng": -81.469,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 12744,
    "id": 1840000546
  },
  {
    "city": "Snezhnogorsk",
    "city_ascii": "Snezhnogorsk",
    "lat": 69.1942,
    "lng": 33.2331,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Murmanskaya Oblast’",
    "capital": "",
    "population": 12642,
    "id": 1643272014
  },
  {
    "city": "Kuçovë",
    "city_ascii": "Kucove",
    "lat": 40.8039,
    "lng": 19.9144,
    "country": "Albania",
    "iso2": "AL",
    "iso3": "ALB",
    "admin_name": "Berat",
    "capital": "",
    "population": 12654,
    "id": 1008520632
  },
  {
    "city": "Elgin",
    "city_ascii": "Elgin",
    "lat": 30.352,
    "lng": -97.3879,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 12733,
    "id": 1840020903
  },
  {
    "city": "Croxley Green",
    "city_ascii": "Croxley Green",
    "lat": 51.647,
    "lng": -0.445,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hertfordshire",
    "capital": "",
    "population": 12562,
    "id": 1826810678
  },
  {
    "city": "Dover",
    "city_ascii": "Dover",
    "lat": 40.5302,
    "lng": -81.4805,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 12723,
    "id": 1840007217
  },
  {
    "city": "Spondon",
    "city_ascii": "Spondon",
    "lat": 52.92,
    "lng": -1.401,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Derby",
    "capital": "",
    "population": 12377,
    "id": 1826000037
  },
  {
    "city": "Greenville",
    "city_ascii": "Greenville",
    "lat": 40.1043,
    "lng": -84.6209,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 12720,
    "id": 1840008366
  },
  {
    "city": "Neuenburg am Rhein",
    "city_ascii": "Neuenburg am Rhein",
    "lat": 47.8147,
    "lng": 7.5619,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 12437,
    "id": 1276286003
  },
  {
    "city": "Borger",
    "city_ascii": "Borger",
    "lat": 35.6598,
    "lng": -101.4012,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 12718,
    "id": 1840019083
  },
  {
    "city": "Te Awamutu",
    "city_ascii": "Te Awamutu",
    "lat": -38.0083,
    "lng": 175.325,
    "country": "New Zealand",
    "iso2": "NZ",
    "iso3": "NZL",
    "admin_name": "Waikato",
    "capital": "",
    "population": 12400,
    "id": 1554001222
  },
  {
    "city": "Zofingen",
    "city_ascii": "Zofingen",
    "lat": 47.2884,
    "lng": 7.9475,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Aargau",
    "capital": "",
    "population": 11655,
    "id": 1756295445
  },
  {
    "city": "Derventa",
    "city_ascii": "Derventa",
    "lat": 44.98,
    "lng": 17.91,
    "country": "Bosnia And Herzegovina",
    "iso2": "BA",
    "iso3": "BIH",
    "admin_name": "Srpska, Republika",
    "capital": "minor",
    "population": 12680,
    "id": 1070137362
  },
  {
    "city": "German Flatts",
    "city_ascii": "German Flatts",
    "lat": 42.9868,
    "lng": -74.9804,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 12704,
    "id": 1840087468
  },
  {
    "city": "Albertirsa",
    "city_ascii": "Albertirsa",
    "lat": 47.24,
    "lng": 19.6067,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Pest",
    "capital": "",
    "population": 12532,
    "id": 1348408587
  },
  {
    "city": "Charlotte",
    "city_ascii": "Charlotte",
    "lat": 42.5662,
    "lng": -84.8304,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 12703,
    "id": 1840003107
  },
  {
    "city": "Broussard",
    "city_ascii": "Broussard",
    "lat": 30.1393,
    "lng": -91.9538,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 12700,
    "id": 1840013964
  },
  {
    "city": "Bad Urach",
    "city_ascii": "Bad Urach",
    "lat": 48.4932,
    "lng": 9.3989,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 12472,
    "id": 1276416917
  },
  {
    "city": "Winterberg",
    "city_ascii": "Winterberg",
    "lat": 51.195,
    "lng": 8.53,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 12611,
    "id": 1276682144
  },
  {
    "city": "Lansdowne",
    "city_ascii": "Lansdowne",
    "lat": 39.0846,
    "lng": -77.4839,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 12696,
    "id": 1840041773
  },
  {
    "city": "Beaconsfield",
    "city_ascii": "Beaconsfield",
    "lat": 51.6009,
    "lng": -0.6347,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Buckinghamshire",
    "capital": "",
    "population": 12081,
    "id": 1826964711
  },
  {
    "city": "Leibnitz",
    "city_ascii": "Leibnitz",
    "lat": 46.7831,
    "lng": 15.545,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Steiermark",
    "capital": "minor",
    "population": 12176,
    "id": 1040580132
  },
  {
    "city": "Horndean",
    "city_ascii": "Horndean",
    "lat": 50.9136,
    "lng": -0.9961,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hampshire",
    "capital": "",
    "population": 12639,
    "id": 1826591617
  },
  {
    "city": "Finneytown",
    "city_ascii": "Finneytown",
    "lat": 39.2159,
    "lng": -84.5145,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 12689,
    "id": 1840034109
  },
  {
    "city": "Herrin",
    "city_ascii": "Herrin",
    "lat": 37.7983,
    "lng": -89.0305,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 12687,
    "id": 1840007576
  },
  {
    "city": "Fergus Falls",
    "city_ascii": "Fergus Falls",
    "lat": 46.2853,
    "lng": -96.076,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 12683,
    "id": 1840007747
  },
  {
    "city": "Hungen",
    "city_ascii": "Hungen",
    "lat": 50.4731,
    "lng": 8.8996,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 12538,
    "id": 1276067778
  },
  {
    "city": "Kam”yanka-Dniprovs’ka",
    "city_ascii": "Kam'yanka-Dniprovs'ka",
    "lat": 47.4792,
    "lng": 34.4232,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Zaporiz’ka Oblast’",
    "capital": "minor",
    "population": 12638,
    "id": 1804716389
  },
  {
    "city": "Blaubeuren",
    "city_ascii": "Blaubeuren",
    "lat": 48.4119,
    "lng": 9.785,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 12521,
    "id": 1276939647
  },
  {
    "city": "San Miguel de Salcedo",
    "city_ascii": "San Miguel de Salcedo",
    "lat": -1.05,
    "lng": -78.5833,
    "country": "Ecuador",
    "iso2": "EC",
    "iso3": "ECU",
    "admin_name": "Cotopaxi",
    "capital": "",
    "population": 12488,
    "id": 1218435695
  },
  {
    "city": "Amesbury",
    "city_ascii": "Amesbury",
    "lat": 51.17,
    "lng": -1.77,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Wiltshire",
    "capital": "",
    "population": 10724,
    "id": 1826157369
  },
  {
    "city": "Pionerskiy",
    "city_ascii": "Pionerskiy",
    "lat": 54.95,
    "lng": 20.2167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kaliningradskaya Oblast’",
    "capital": "minor",
    "population": 11312,
    "id": 1643251057
  },
  {
    "city": "Choctaw",
    "city_ascii": "Choctaw",
    "lat": 35.48,
    "lng": -97.2666,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oklahoma",
    "capital": "",
    "population": 12674,
    "id": 1840019152
  },
  {
    "city": "Manvel",
    "city_ascii": "Manvel",
    "lat": 29.4793,
    "lng": -95.3659,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 12671,
    "id": 1840020977
  },
  {
    "city": "Magalia",
    "city_ascii": "Magalia",
    "lat": 39.8228,
    "lng": -121.6078,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 12671,
    "id": 1840017530
  },
  {
    "city": "Shilka",
    "city_ascii": "Shilka",
    "lat": 51.85,
    "lng": 116.0333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Zabaykal’skiy Kray",
    "capital": "",
    "population": 12663,
    "id": 1643938937
  },
  {
    "city": "Kantang",
    "city_ascii": "Kantang",
    "lat": 7.4067,
    "lng": 99.515,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Trang",
    "capital": "minor",
    "population": 12533,
    "id": 1764372106
  },
  {
    "city": "Oregon",
    "city_ascii": "Oregon",
    "lat": 42.9253,
    "lng": -89.3892,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 12664,
    "id": 1840002922
  },
  {
    "city": "Commerce",
    "city_ascii": "Commerce",
    "lat": 33.9963,
    "lng": -118.1519,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 12661,
    "id": 1840019239
  },
  {
    "city": "Winkler",
    "city_ascii": "Winkler",
    "lat": 49.1817,
    "lng": -97.9397,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Manitoba",
    "capital": "",
    "population": 12660,
    "id": 1124205424
  },
  {
    "city": "Fehmarnsund",
    "city_ascii": "Fehmarnsund",
    "lat": 54.4454,
    "lng": 11.1702,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Schleswig-Holstein",
    "capital": "",
    "population": 12592,
    "id": 1276306503
  },
  {
    "city": "Taylorville",
    "city_ascii": "Taylorville",
    "lat": 39.5328,
    "lng": -89.2804,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 12659,
    "id": 1840009547
  },
  {
    "city": "Kasaishi",
    "city_ascii": "Kasaishi",
    "lat": 37.2528,
    "lng": 140.3436,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Fukushima",
    "capital": "",
    "population": 12266,
    "id": 1392964767
  },
  {
    "city": "Port Neches",
    "city_ascii": "Port Neches",
    "lat": 29.9765,
    "lng": -93.946,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 12655,
    "id": 1840020920
  },
  {
    "city": "Wetaskiwin",
    "city_ascii": "Wetaskiwin",
    "lat": 52.9694,
    "lng": -113.3769,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Alberta",
    "capital": "",
    "population": 12655,
    "id": 1124492484
  },
  {
    "city": "Shchastia",
    "city_ascii": "Shchastia",
    "lat": 48.7381,
    "lng": 39.2311,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Luhans’ka Oblast’",
    "capital": "",
    "population": 12629,
    "id": 1804786776
  },
  {
    "city": "Emiliano Zapata",
    "city_ascii": "Emiliano Zapata",
    "lat": 19.65,
    "lng": -98.55,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Hidalgo",
    "capital": "minor",
    "population": 12309,
    "id": 1484053015
  },
  {
    "city": "Prineville",
    "city_ascii": "Prineville",
    "lat": 44.2985,
    "lng": -120.8607,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 12650,
    "id": 1840019999
  },
  {
    "city": "Baykalsk",
    "city_ascii": "Baykalsk",
    "lat": 51.5172,
    "lng": 104.1561,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Irkutskaya Oblast’",
    "capital": "",
    "population": 12629,
    "id": 1643186912
  },
  {
    "city": "Pennsville",
    "city_ascii": "Pennsville",
    "lat": 39.6266,
    "lng": -75.5089,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 12643,
    "id": 1840081664
  },
  {
    "city": "Lakeland",
    "city_ascii": "Lakeland",
    "lat": 35.2585,
    "lng": -89.7308,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 12642,
    "id": 1840014594
  },
  {
    "city": "Peru",
    "city_ascii": "Peru",
    "lat": 40.7594,
    "lng": -86.0757,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 12642,
    "id": 1840009363
  },
  {
    "city": "Lauenburg",
    "city_ascii": "Lauenburg",
    "lat": 53.3758,
    "lng": 10.5589,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Schleswig-Holstein",
    "capital": "",
    "population": 11444,
    "id": 1276704232
  },
  {
    "city": "Stony Brook",
    "city_ascii": "Stony Brook",
    "lat": 40.906,
    "lng": -73.1278,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 12641,
    "id": 1840005104
  },
  {
    "city": "Vakhrusheve",
    "city_ascii": "Vakhrusheve",
    "lat": 48.1606,
    "lng": 38.8389,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Luhans’ka Oblast’",
    "capital": "",
    "population": 12197,
    "id": 1804986291
  },
  {
    "city": "Dalmatovo",
    "city_ascii": "Dalmatovo",
    "lat": 56.2667,
    "lng": 62.9167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kurganskaya Oblast’",
    "capital": "",
    "population": 12631,
    "id": 1643336308
  },
  {
    "city": "White House",
    "city_ascii": "White House",
    "lat": 36.4648,
    "lng": -86.6665,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 12638,
    "id": 1840015274
  },
  {
    "city": "Elfers",
    "city_ascii": "Elfers",
    "lat": 28.214,
    "lng": -82.723,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 12637,
    "id": 1840013122
  },
  {
    "city": "New Hanover",
    "city_ascii": "New Hanover",
    "lat": 40.3145,
    "lng": -75.5566,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 12637,
    "id": 1840103521
  },
  {
    "city": "Lakewood Park",
    "city_ascii": "Lakewood Park",
    "lat": 27.539,
    "lng": -80.3865,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 12635,
    "id": 1840014177
  },
  {
    "city": "Keokuk",
    "city_ascii": "Keokuk",
    "lat": 40.4095,
    "lng": -91.403,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Iowa",
    "capital": "",
    "population": 12635,
    "id": 1840001036
  },
  {
    "city": "Kalajoki",
    "city_ascii": "Kalajoki",
    "lat": 64.2597,
    "lng": 23.9486,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Pohjois-Pohjanmaa",
    "capital": "minor",
    "population": 12621,
    "id": 1246746115
  },
  {
    "city": "Brunswick",
    "city_ascii": "Brunswick",
    "lat": 42.7558,
    "lng": -73.5903,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 12634,
    "id": 1840087244
  },
  {
    "city": "Greetland",
    "city_ascii": "Greetland",
    "lat": 53.6869,
    "lng": -1.8714,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Calderdale",
    "capital": "",
    "population": 11389,
    "id": 1826686743
  },
  {
    "city": "Yemva",
    "city_ascii": "Yemva",
    "lat": 62.5833,
    "lng": 50.85,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Komi",
    "capital": "",
    "population": 12630,
    "id": 1643717483
  },
  {
    "city": "St. Ann",
    "city_ascii": "St. Ann",
    "lat": 38.7266,
    "lng": -90.3872,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 12629,
    "id": 1840009771
  },
  {
    "city": "Mablethorpe",
    "city_ascii": "Mablethorpe",
    "lat": 53.3409,
    "lng": 0.261,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Lincolnshire",
    "capital": "",
    "population": 12531,
    "id": 1826000615
  },
  {
    "city": "Mikashevichy",
    "city_ascii": "Mikashevichy",
    "lat": 52.2167,
    "lng": 27.4667,
    "country": "Belarus",
    "iso2": "BY",
    "iso3": "BLR",
    "admin_name": "Brestskaya Voblasts’",
    "capital": "",
    "population": 12600,
    "id": 1112846282
  },
  {
    "city": "Oshikango",
    "city_ascii": "Oshikango",
    "lat": -17.3964,
    "lng": 15.8903,
    "country": "Namibia",
    "iso2": "NA",
    "iso3": "NAM",
    "admin_name": "Ohangwena",
    "capital": "",
    "population": 12423,
    "id": 1516819374
  },
  {
    "city": "Novalukoml’",
    "city_ascii": "Novalukoml'",
    "lat": 54.6658,
    "lng": 29.1547,
    "country": "Belarus",
    "iso2": "BY",
    "iso3": "BLR",
    "admin_name": "Vitsyebskaya Voblasts’",
    "capital": "",
    "population": 12600,
    "id": 1112086719
  },
  {
    "city": "Newtown",
    "city_ascii": "Newtown",
    "lat": 52.5132,
    "lng": -3.3141,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Powys",
    "capital": "",
    "population": 11357,
    "id": 1826506895
  },
  {
    "city": "Brandfort",
    "city_ascii": "Brandfort",
    "lat": -28.7,
    "lng": 26.4667,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "Free State",
    "capital": "",
    "population": 12091,
    "id": 1710953731
  },
  {
    "city": "Darfield",
    "city_ascii": "Darfield",
    "lat": 53.538,
    "lng": -1.3812,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Barnsley",
    "capital": "",
    "population": 10685,
    "id": 1826920550
  },
  {
    "city": "Yulee",
    "city_ascii": "Yulee",
    "lat": 30.635,
    "lng": -81.5678,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 12616,
    "id": 1840013928
  },
  {
    "city": "Duartina",
    "city_ascii": "Duartina",
    "lat": -22.4144,
    "lng": -49.4039,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 12567,
    "id": 1076361175
  },
  {
    "city": "Cananéia",
    "city_ascii": "Cananeia",
    "lat": -25.015,
    "lng": -47.9269,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 12604,
    "id": 1076252867
  },
  {
    "city": "Yuzha",
    "city_ascii": "Yuzha",
    "lat": 56.5833,
    "lng": 42.0167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Ivanovskaya Oblast’",
    "capital": "",
    "population": 12595,
    "id": 1643003357
  },
  {
    "city": "Lewisboro",
    "city_ascii": "Lewisboro",
    "lat": 41.2697,
    "lng": -73.5827,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 12613,
    "id": 1840058242
  },
  {
    "city": "Windsor Locks",
    "city_ascii": "Windsor Locks",
    "lat": 41.9267,
    "lng": -72.6544,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 12613,
    "id": 1840033751
  },
  {
    "city": "Tranent",
    "city_ascii": "Tranent",
    "lat": 55.945,
    "lng": -2.954,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "East Lothian",
    "capital": "",
    "population": 12582,
    "id": 1826125667
  },
  {
    "city": "Galashiels",
    "city_ascii": "Galashiels",
    "lat": 55.6194,
    "lng": -2.8033,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Scottish Borders, The",
    "capital": "",
    "population": 12600,
    "id": 1826185661
  },
  {
    "city": "Goražde",
    "city_ascii": "Gorazde",
    "lat": 43.6667,
    "lng": 18.9778,
    "country": "Bosnia And Herzegovina",
    "iso2": "BA",
    "iso3": "BIH",
    "admin_name": "Bosnia and Herzegovina, Federation of",
    "capital": "minor",
    "population": 12512,
    "id": 1070619970
  },
  {
    "city": "Justice",
    "city_ascii": "Justice",
    "lat": 41.7495,
    "lng": -87.8345,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 12608,
    "id": 1840011253
  },
  {
    "city": "Central Elgin",
    "city_ascii": "Central Elgin",
    "lat": 42.7667,
    "lng": -81.1,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 12607,
    "id": 1124000475
  },
  {
    "city": "Tiszavasvári",
    "city_ascii": "Tiszavasvari",
    "lat": 47.9511,
    "lng": 21.3689,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Szabolcs-Szatmár-Bereg",
    "capital": "minor",
    "population": 12508,
    "id": 1348419818
  },
  {
    "city": "Taguaí",
    "city_ascii": "Taguai",
    "lat": -23.4519,
    "lng": -49.4089,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 12586,
    "id": 1076580589
  },
  {
    "city": "Anklam",
    "city_ascii": "Anklam",
    "lat": 53.85,
    "lng": 13.6833,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Mecklenburg-Western Pomerania",
    "capital": "",
    "population": 12385,
    "id": 1276192154
  },
  {
    "city": "Minneola",
    "city_ascii": "Minneola",
    "lat": 28.6067,
    "lng": -81.7322,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 12595,
    "id": 1840015081
  },
  {
    "city": "Anna Regina",
    "city_ascii": "Anna Regina",
    "lat": 7.25,
    "lng": -58.5167,
    "country": "Guyana",
    "iso2": "GY",
    "iso3": "GUY",
    "admin_name": "Pomeroon-Supenaam",
    "capital": "",
    "population": 12448,
    "id": 1328787362
  },
  {
    "city": "Oskaloosa",
    "city_ascii": "Oskaloosa",
    "lat": 41.2922,
    "lng": -92.6403,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Iowa",
    "capital": "",
    "population": 12592,
    "id": 1840000650
  },
  {
    "city": "Florence",
    "city_ascii": "Florence",
    "lat": 40.0978,
    "lng": -74.7886,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 12589,
    "id": 1840081638
  },
  {
    "city": "Buchach",
    "city_ascii": "Buchach",
    "lat": 49.0647,
    "lng": 25.3872,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Ternopil’s’ka Oblast’",
    "capital": "minor",
    "population": 12511,
    "id": 1804566704
  },
  {
    "city": "Lienz",
    "city_ascii": "Lienz",
    "lat": 46.8297,
    "lng": 12.7697,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Tirol",
    "capital": "minor",
    "population": 11844,
    "id": 1040923905
  },
  {
    "city": "Grand Terrace",
    "city_ascii": "Grand Terrace",
    "lat": 34.0312,
    "lng": -117.3132,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 12584,
    "id": 1840020403
  },
  {
    "city": "Panama City Beach",
    "city_ascii": "Panama City Beach",
    "lat": 30.2369,
    "lng": -85.8775,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 12583,
    "id": 1840015035
  },
  {
    "city": "Woodland Park",
    "city_ascii": "Woodland Park",
    "lat": 40.8905,
    "lng": -74.1945,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 12581,
    "id": 1840056421
  },
  {
    "city": "Rosbach vor der Höhe",
    "city_ascii": "Rosbach vor der Hohe",
    "lat": 50.2986,
    "lng": 8.7006,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 12307,
    "id": 1276636774
  },
  {
    "city": "Vysoké Mýto",
    "city_ascii": "Vysoke Myto",
    "lat": 49.9532,
    "lng": 16.1618,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Pardubický Kraj",
    "capital": "",
    "population": 12288,
    "id": 1203435938
  },
  {
    "city": "Beydağ",
    "city_ascii": "Beydag",
    "lat": 38.0869,
    "lng": 28.2089,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Kayseri",
    "capital": "minor",
    "population": 12507,
    "id": 1792722415
  },
  {
    "city": "Richmond",
    "city_ascii": "Richmond",
    "lat": 29.5825,
    "lng": -95.7602,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 12578,
    "id": 1840020947
  },
  {
    "city": "Nelson Bay",
    "city_ascii": "Nelson Bay",
    "lat": -32.715,
    "lng": 152.1511,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 11820,
    "id": 1036777604
  },
  {
    "city": "Riviera Beach",
    "city_ascii": "Riviera Beach",
    "lat": 39.1628,
    "lng": -76.5263,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 12577,
    "id": 1840005916
  },
  {
    "city": "Jackson",
    "city_ascii": "Jackson",
    "lat": 43.4721,
    "lng": -110.7745,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wyoming",
    "capital": "",
    "population": 12576,
    "id": 1840021258
  },
  {
    "city": "Mercerville",
    "city_ascii": "Mercerville",
    "lat": 40.236,
    "lng": -74.6916,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 12574,
    "id": 1840033344
  },
  {
    "city": "Kataysk",
    "city_ascii": "Kataysk",
    "lat": 56.3,
    "lng": 62.5667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kurganskaya Oblast’",
    "capital": "",
    "population": 12565,
    "id": 1643869391
  },
  {
    "city": "Elland",
    "city_ascii": "Elland",
    "lat": 53.683,
    "lng": -1.84,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Calderdale",
    "capital": "",
    "population": 11676,
    "id": 1826432819
  },
  {
    "city": "Lake Wylie",
    "city_ascii": "Lake Wylie",
    "lat": 35.0997,
    "lng": -81.0677,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 12569,
    "id": 1840013518
  },
  {
    "city": "Fredericksburg",
    "city_ascii": "Fredericksburg",
    "lat": 30.2661,
    "lng": -98.8749,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 12567,
    "id": 1840020898
  },
  {
    "city": "Mira",
    "city_ascii": "Mira",
    "lat": 40.4285,
    "lng": -8.7363,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Coimbra",
    "capital": "minor",
    "population": 12465,
    "id": 1620156397
  },
  {
    "city": "Revúca",
    "city_ascii": "Revuca",
    "lat": 48.6833,
    "lng": 20.1167,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Banskobystrický",
    "capital": "minor",
    "population": 12249,
    "id": 1703490352
  },
  {
    "city": "Joppatowne",
    "city_ascii": "Joppatowne",
    "lat": 39.4181,
    "lng": -76.3516,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 12560,
    "id": 1840005665
  },
  {
    "city": "Sabugal",
    "city_ascii": "Sabugal",
    "lat": 40.35,
    "lng": -7.0833,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Guarda",
    "capital": "minor",
    "population": 12544,
    "id": 1620978952
  },
  {
    "city": "Belmont",
    "city_ascii": "Belmont",
    "lat": 35.2211,
    "lng": -81.0402,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 12558,
    "id": 1840013469
  },
  {
    "city": "Twistringen",
    "city_ascii": "Twistringen",
    "lat": 52.8,
    "lng": 8.65,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 12449,
    "id": 1276091874
  },
  {
    "city": "Petrov Val",
    "city_ascii": "Petrov Val",
    "lat": 50.1333,
    "lng": 45.2167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Volgogradskaya Oblast’",
    "capital": "",
    "population": 12544,
    "id": 1643860504
  },
  {
    "city": "Frohburg",
    "city_ascii": "Frohburg",
    "lat": 51.0561,
    "lng": 12.555,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 12470,
    "id": 1276859230
  },
  {
    "city": "Truckee",
    "city_ascii": "Truckee",
    "lat": 39.3455,
    "lng": -120.1848,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 12554,
    "id": 1840022462
  },
  {
    "city": "Mwingi",
    "city_ascii": "Mwingi",
    "lat": -0.9296,
    "lng": 38.07,
    "country": "Kenya",
    "iso2": "KE",
    "iso3": "KEN",
    "admin_name": "Kitui",
    "capital": "",
    "population": 11219,
    "id": 1404312666
  },
  {
    "city": "Lachute",
    "city_ascii": "Lachute",
    "lat": 45.65,
    "lng": -74.3333,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 12551,
    "id": 1124217062
  },
  {
    "city": "Xavantes",
    "city_ascii": "Xavantes",
    "lat": -23.0389,
    "lng": -49.7094,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 12484,
    "id": 1076632906
  },
  {
    "city": "Prairie Ridge",
    "city_ascii": "Prairie Ridge",
    "lat": 47.1443,
    "lng": -122.1408,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 12550,
    "id": 1840037882
  },
  {
    "city": "Oberwil",
    "city_ascii": "Oberwil",
    "lat": 47.5135,
    "lng": 7.5546,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Basel-Landschaft",
    "capital": "",
    "population": 11136,
    "id": 1756150153
  },
  {
    "city": "Jacksonville",
    "city_ascii": "Jacksonville",
    "lat": 33.8088,
    "lng": -85.7545,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 12544,
    "id": 1840007433
  },
  {
    "city": "Brzeszcze",
    "city_ascii": "Brzeszcze",
    "lat": 50,
    "lng": 19.15,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Małopolskie",
    "capital": "",
    "population": 11921,
    "id": 1616019723
  },
  {
    "city": "Feuchtwangen",
    "city_ascii": "Feuchtwangen",
    "lat": 49.1667,
    "lng": 10.3167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 12452,
    "id": 1276691065
  },
  {
    "city": "Hamilton Square",
    "city_ascii": "Hamilton Square",
    "lat": 40.2248,
    "lng": -74.6526,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 12542,
    "id": 1840024324
  },
  {
    "city": "Bad Windsheim",
    "city_ascii": "Bad Windsheim",
    "lat": 49.5,
    "lng": 10.4167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 12382,
    "id": 1276120612
  },
  {
    "city": "Ebersberg",
    "city_ascii": "Ebersberg",
    "lat": 48.0833,
    "lng": 11.9667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "minor",
    "population": 12239,
    "id": 1276452047
  },
  {
    "city": "Lüderitz",
    "city_ascii": "Luderitz",
    "lat": -26.6478,
    "lng": 15.1578,
    "country": "Namibia",
    "iso2": "NA",
    "iso3": "NAM",
    "admin_name": "//Karas",
    "capital": "",
    "population": 12537,
    "id": 1516141896
  },
  {
    "city": "Sheerness",
    "city_ascii": "Sheerness",
    "lat": 51.441,
    "lng": 0.76,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Kent",
    "capital": "",
    "population": 11938,
    "id": 1826263896
  },
  {
    "city": "Astley",
    "city_ascii": "Astley",
    "lat": 53.5008,
    "lng": -2.4454,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Wigan",
    "capital": "",
    "population": 11270,
    "id": 1826000042
  },
  {
    "city": "Endicott",
    "city_ascii": "Endicott",
    "lat": 42.098,
    "lng": -76.0639,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 12532,
    "id": 1840004664
  },
  {
    "city": "Cedar Grove",
    "city_ascii": "Cedar Grove",
    "lat": 40.8565,
    "lng": -74.2287,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 12531,
    "id": 1840081752
  },
  {
    "city": "Culcheth",
    "city_ascii": "Culcheth",
    "lat": 53.4517,
    "lng": -2.5218,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Warrington",
    "capital": "",
    "population": 11454,
    "id": 1826044537
  },
  {
    "city": "Hudson",
    "city_ascii": "Hudson",
    "lat": 28.3594,
    "lng": -82.6888,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 12528,
    "id": 1840014115
  },
  {
    "city": "Erkner",
    "city_ascii": "Erkner",
    "lat": 52.4167,
    "lng": 13.75,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Brandenburg",
    "capital": "",
    "population": 11815,
    "id": 1276566171
  },
  {
    "city": "Milovice",
    "city_ascii": "Milovice",
    "lat": 50.226,
    "lng": 14.8887,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Středočeský Kraj",
    "capital": "",
    "population": 12098,
    "id": 1203709386
  },
  {
    "city": "Kamin’-Kashyrs’kyy",
    "city_ascii": "Kamin'-Kashyrs'kyy",
    "lat": 51.6242,
    "lng": 24.9606,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Volyns’ka Oblast’",
    "capital": "minor",
    "population": 12489,
    "id": 1804718360
  },
  {
    "city": "Sakawa",
    "city_ascii": "Sakawa",
    "lat": 33.5008,
    "lng": 133.2867,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Kōchi",
    "capital": "",
    "population": 12402,
    "id": 1392787592
  },
  {
    "city": "Kameshkovo",
    "city_ascii": "Kameshkovo",
    "lat": 56.3492,
    "lng": 40.9978,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Vladimirskaya Oblast’",
    "capital": "",
    "population": 12496,
    "id": 1643012226
  },
  {
    "city": "Lindenberg im Allgäu",
    "city_ascii": "Lindenberg im Allgau",
    "lat": 47.6031,
    "lng": 9.8861,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 11546,
    "id": 1276413375
  },
  {
    "city": "Stainland",
    "city_ascii": "Stainland",
    "lat": 53.6728,
    "lng": -1.8805,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Calderdale",
    "capital": "",
    "population": 11389,
    "id": 1826451055
  },
  {
    "city": "Palos Heights",
    "city_ascii": "Palos Heights",
    "lat": 41.6637,
    "lng": -87.7959,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 12520,
    "id": 1840009181
  },
  {
    "city": "West Grey",
    "city_ascii": "West Grey",
    "lat": 44.1833,
    "lng": -80.8167,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 12518,
    "id": 1124000272
  },
  {
    "city": "Kozloduy",
    "city_ascii": "Kozloduy",
    "lat": 43.7781,
    "lng": 23.7275,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Vratsa",
    "capital": "minor",
    "population": 12400,
    "id": 1100662672
  },
  {
    "city": "Beelitz",
    "city_ascii": "Beelitz",
    "lat": 52.2333,
    "lng": 12.9667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Brandenburg",
    "capital": "",
    "population": 12448,
    "id": 1276861998
  },
  {
    "city": "Charmahīn",
    "city_ascii": "Charmahin",
    "lat": 32.3375,
    "lng": 51.1958,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Eşfahān",
    "capital": "",
    "population": 12292,
    "id": 1364383489
  },
  {
    "city": "Parksville",
    "city_ascii": "Parksville",
    "lat": 49.315,
    "lng": -124.312,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "British Columbia",
    "capital": "",
    "population": 12514,
    "id": 1124698963
  },
  {
    "city": "Boumalne",
    "city_ascii": "Boumalne",
    "lat": 31.3738,
    "lng": -5.9956,
    "country": "Morocco",
    "iso2": "MA",
    "iso3": "MAR",
    "admin_name": "Drâa-Tafilalet",
    "capital": "",
    "population": 12328,
    "id": 1504840361
  },
  {
    "city": "Boone",
    "city_ascii": "Boone",
    "lat": 42.053,
    "lng": -93.877,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Iowa",
    "capital": "",
    "population": 12513,
    "id": 1840007012
  },
  {
    "city": "Rainhill",
    "city_ascii": "Rainhill",
    "lat": 53.4157,
    "lng": -2.7625,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "St. Helens",
    "capital": "",
    "population": 10853,
    "id": 1826589564
  },
  {
    "city": "North St. Paul",
    "city_ascii": "North St. Paul",
    "lat": 45.0137,
    "lng": -92.9995,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 12506,
    "id": 1840007843
  },
  {
    "city": "Sátão",
    "city_ascii": "Satao",
    "lat": 40.7333,
    "lng": -7.7167,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Viseu",
    "capital": "minor",
    "population": 12444,
    "id": 1620157053
  },
  {
    "city": "Bickenhill",
    "city_ascii": "Bickenhill",
    "lat": 52.439,
    "lng": -1.725,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Solihull",
    "capital": "",
    "population": 12456,
    "id": 1826206537
  },
  {
    "city": "Cheney",
    "city_ascii": "Cheney",
    "lat": 47.4901,
    "lng": -117.579,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 12497,
    "id": 1840018398
  },
  {
    "city": "Hopewell Township",
    "city_ascii": "Hopewell Township",
    "lat": 40.5906,
    "lng": -80.2731,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 12497,
    "id": 1840146082
  },
  {
    "city": "Arroyo Seco",
    "city_ascii": "Arroyo Seco",
    "lat": 21.5472,
    "lng": -99.6883,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Querétaro",
    "capital": "minor",
    "population": 12493,
    "id": 1484500576
  },
  {
    "city": "Cowansville",
    "city_ascii": "Cowansville",
    "lat": 45.2,
    "lng": -72.75,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 12489,
    "id": 1124357421
  },
  {
    "city": "Paris",
    "city_ascii": "Paris",
    "lat": 38.2016,
    "lng": -84.2719,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kentucky",
    "capital": "",
    "population": 12485,
    "id": 1840014315
  },
  {
    "city": "Irmo",
    "city_ascii": "Irmo",
    "lat": 34.1018,
    "lng": -81.1957,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 12483,
    "id": 1840016723
  },
  {
    "city": "Hnivan’",
    "city_ascii": "Hnivan'",
    "lat": 49.0833,
    "lng": 28.35,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Vinnyts’ka Oblast’",
    "capital": "",
    "population": 12433,
    "id": 1804579295
  },
  {
    "city": "Bottesford",
    "city_ascii": "Bottesford",
    "lat": 53.5521,
    "lng": -0.6714,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "North Lincolnshire",
    "capital": "",
    "population": 11038,
    "id": 1826232962
  },
  {
    "city": "Plan-les-Ouates",
    "city_ascii": "Plan-les-Ouates",
    "lat": 46.1667,
    "lng": 6.1167,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Genève",
    "capital": "",
    "population": 10656,
    "id": 1756223041
  },
  {
    "city": "Velten",
    "city_ascii": "Velten",
    "lat": 52.6833,
    "lng": 13.1833,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Brandenburg",
    "capital": "",
    "population": 11965,
    "id": 1276342323
  },
  {
    "city": "Sulz am Neckar",
    "city_ascii": "Sulz am Neckar",
    "lat": 48.3628,
    "lng": 8.6317,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 12336,
    "id": 1276239131
  },
  {
    "city": "Epping",
    "city_ascii": "Epping",
    "lat": 51.7004,
    "lng": 0.1087,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Essex",
    "capital": "",
    "population": 11047,
    "id": 1826234838
  },
  {
    "city": "Newington Forest",
    "city_ascii": "Newington Forest",
    "lat": 38.7371,
    "lng": -77.2339,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 12476,
    "id": 1840041848
  },
  {
    "city": "Marysville",
    "city_ascii": "Marysville",
    "lat": 39.1518,
    "lng": -121.5835,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 12476,
    "id": 1840020219
  },
  {
    "city": "San Anselmo",
    "city_ascii": "San Anselmo",
    "lat": 37.9821,
    "lng": -122.5699,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 12476,
    "id": 1840022520
  },
  {
    "city": "Hessisch Lichtenau",
    "city_ascii": "Hessisch Lichtenau",
    "lat": 51.2,
    "lng": 9.7167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 12359,
    "id": 1276002742
  },
  {
    "city": "Thame",
    "city_ascii": "Thame",
    "lat": 51.75,
    "lng": -0.97,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Oxfordshire",
    "capital": "",
    "population": 11561,
    "id": 1826274943
  },
  {
    "city": "Verde Village",
    "city_ascii": "Verde Village",
    "lat": 34.7122,
    "lng": -111.9943,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 12473,
    "id": 1840017843
  },
  {
    "city": "Kingsbury",
    "city_ascii": "Kingsbury",
    "lat": 43.344,
    "lng": -73.5395,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 12471,
    "id": 1840058216
  },
  {
    "city": "Cortês",
    "city_ascii": "Cortes",
    "lat": -8.47,
    "lng": -35.5436,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Pernambuco",
    "capital": "",
    "population": 12452,
    "id": 1076047462
  },
  {
    "city": "Woodhaven",
    "city_ascii": "Woodhaven",
    "lat": 42.132,
    "lng": -83.2374,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 12469,
    "id": 1840001848
  },
  {
    "city": "Preußisch Oldendorf",
    "city_ascii": "Preussisch Oldendorf",
    "lat": 52.2833,
    "lng": 8.5,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 12289,
    "id": 1276631008
  },
  {
    "city": "Four Corners",
    "city_ascii": "Four Corners",
    "lat": 29.6705,
    "lng": -95.6596,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 12465,
    "id": 1840018265
  },
  {
    "city": "Bni Drar",
    "city_ascii": "Bni Drar",
    "lat": 34.8281,
    "lng": -1.9936,
    "country": "Morocco",
    "iso2": "MA",
    "iso3": "MAR",
    "admin_name": "Oriental",
    "capital": "",
    "population": 10934,
    "id": 1504593239
  },
  {
    "city": "Bad Freienwalde",
    "city_ascii": "Bad Freienwalde",
    "lat": 52.7856,
    "lng": 14.0325,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Brandenburg",
    "capital": "",
    "population": 12365,
    "id": 1276111750
  },
  {
    "city": "Bedford",
    "city_ascii": "Bedford",
    "lat": 41.3919,
    "lng": -81.5359,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 12457,
    "id": 1840003396
  },
  {
    "city": "Sever do Vouga",
    "city_ascii": "Sever do Vouga",
    "lat": 40.7167,
    "lng": -8.3667,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Aveiro",
    "capital": "minor",
    "population": 12356,
    "id": 1620380173
  },
  {
    "city": "Trophy Club",
    "city_ascii": "Trophy Club",
    "lat": 33.0038,
    "lng": -97.1897,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 12451,
    "id": 1840022993
  },
  {
    "city": "Beryslav",
    "city_ascii": "Beryslav",
    "lat": 46.8333,
    "lng": 33.4167,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Khersons’ka Oblast’",
    "capital": "minor",
    "population": 12417,
    "id": 1804296510
  },
  {
    "city": "Marshall",
    "city_ascii": "Marshall",
    "lat": 39.1147,
    "lng": -93.201,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 12447,
    "id": 1840008509
  },
  {
    "city": "Romanshorn",
    "city_ascii": "Romanshorn",
    "lat": 47.5635,
    "lng": 9.3564,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Thurgau",
    "capital": "",
    "population": 11167,
    "id": 1756539997
  },
  {
    "city": "Reẕvānshahr",
    "city_ascii": "Rezvanshahr",
    "lat": 37.5511,
    "lng": 49.1394,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Gīlān",
    "capital": "minor",
    "population": 12355,
    "id": 1364695165
  },
  {
    "city": "Riverton",
    "city_ascii": "Riverton",
    "lat": 43.0421,
    "lng": -108.414,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wyoming",
    "capital": "",
    "population": 12445,
    "id": 1840020026
  },
  {
    "city": "Hirao",
    "city_ascii": "Hirao",
    "lat": 33.9381,
    "lng": 132.0733,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Yamaguchi",
    "capital": "",
    "population": 12095,
    "id": 1392671270
  },
  {
    "city": "Niepołomice",
    "city_ascii": "Niepolomice",
    "lat": 50.0339,
    "lng": 20.2172,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Małopolskie",
    "capital": "",
    "population": 12000,
    "id": 1616183842
  },
  {
    "city": "Cornwall",
    "city_ascii": "Cornwall",
    "lat": 41.4195,
    "lng": -74.054,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 12442,
    "id": 1840087346
  },
  {
    "city": "Sault Ste. Marie",
    "city_ascii": "Sault Ste. Marie",
    "lat": 46.4817,
    "lng": -84.3723,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 12442,
    "id": 1840003938
  },
  {
    "city": "Shepherdsville",
    "city_ascii": "Shepherdsville",
    "lat": 37.9806,
    "lng": -85.6999,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kentucky",
    "capital": "",
    "population": 12442,
    "id": 1840015214
  },
  {
    "city": "Kálymnos",
    "city_ascii": "Kalymnos",
    "lat": 36.9512,
    "lng": 26.9832,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Notío Aigaío",
    "capital": "minor",
    "population": 12324,
    "id": 1300286595
  },
  {
    "city": "Bécancour",
    "city_ascii": "Becancour",
    "lat": 46.3333,
    "lng": -72.4333,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 12438,
    "id": 1124242297
  },
  {
    "city": "Moldova Nouă",
    "city_ascii": "Moldova Noua",
    "lat": 44.7347,
    "lng": 21.6664,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Caraş-Severin",
    "capital": "",
    "population": 12350,
    "id": 1642091214
  },
  {
    "city": "Shady Hills",
    "city_ascii": "Shady Hills",
    "lat": 28.4042,
    "lng": -82.5467,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 12435,
    "id": 1840029004
  },
  {
    "city": "Manchester",
    "city_ascii": "Manchester",
    "lat": 35.463,
    "lng": -86.0774,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 12433,
    "id": 1840015409
  },
  {
    "city": "Plymouth",
    "city_ascii": "Plymouth",
    "lat": 41.3483,
    "lng": -86.3187,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 12433,
    "id": 1840014170
  },
  {
    "city": "Gladenbach",
    "city_ascii": "Gladenbach",
    "lat": 50.7681,
    "lng": 8.5828,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 12262,
    "id": 1276762551
  },
  {
    "city": "Nagykáta",
    "city_ascii": "Nagykata",
    "lat": 47.4178,
    "lng": 19.7414,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Pest",
    "capital": "minor",
    "population": 12280,
    "id": 1348906968
  },
  {
    "city": "Waconia",
    "city_ascii": "Waconia",
    "lat": 44.8412,
    "lng": -93.7927,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 12428,
    "id": 1840010008
  },
  {
    "city": "Vardenis",
    "city_ascii": "Vardenis",
    "lat": 40.18,
    "lng": 45.72,
    "country": "Armenia",
    "iso2": "AM",
    "iso3": "ARM",
    "admin_name": "Geghark’unik’",
    "capital": "",
    "population": 12363,
    "id": 1051776950
  },
  {
    "city": "Taiynsha",
    "city_ascii": "Taiynsha",
    "lat": 53.8478,
    "lng": 69.7639,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Soltüstik Qazaqstan",
    "capital": "",
    "population": 12418,
    "id": 1398304928
  },
  {
    "city": "Coventry",
    "city_ascii": "Coventry",
    "lat": 41.7828,
    "lng": -72.3394,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 12422,
    "id": 1840034370
  },
  {
    "city": "Pfäffikon",
    "city_ascii": "Pfaffikon",
    "lat": 47.3667,
    "lng": 8.7822,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Zürich",
    "capital": "minor",
    "population": 11817,
    "id": 1756406468
  },
  {
    "city": "Ōsako",
    "city_ascii": "Osako",
    "lat": 31.4292,
    "lng": 131.0058,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Kagoshima",
    "capital": "",
    "population": 12299,
    "id": 1392990377
  },
  {
    "city": "St. Peter",
    "city_ascii": "St. Peter",
    "lat": 44.3296,
    "lng": -93.9659,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 12421,
    "id": 1840008972
  },
  {
    "city": "Pößneck",
    "city_ascii": "Possneck",
    "lat": 50.7,
    "lng": 11.6,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Thuringia",
    "capital": "",
    "population": 11924,
    "id": 1276601734
  },
  {
    "city": "Saint Ives",
    "city_ascii": "Saint Ives",
    "lat": 50.211,
    "lng": -5.48,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Cornwall",
    "capital": "",
    "population": 11226,
    "id": 1826132004
  },
  {
    "city": "Lymm",
    "city_ascii": "Lymm",
    "lat": 53.3834,
    "lng": -2.475,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Cheshire East",
    "capital": "",
    "population": 12350,
    "id": 1826822160
  },
  {
    "city": "Kelso",
    "city_ascii": "Kelso",
    "lat": 46.1236,
    "lng": -122.891,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 12417,
    "id": 1840018503
  },
  {
    "city": "Logan Township",
    "city_ascii": "Logan Township",
    "lat": 40.5263,
    "lng": -78.4235,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 12414,
    "id": 1840151322
  },
  {
    "city": "Titisee-Neustadt",
    "city_ascii": "Titisee-Neustadt",
    "lat": 47.9122,
    "lng": 8.2147,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 12269,
    "id": 1276001947
  },
  {
    "city": "Khvalynsk",
    "city_ascii": "Khvalynsk",
    "lat": 52.4833,
    "lng": 48.1,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Saratovskaya Oblast’",
    "capital": "",
    "population": 12391,
    "id": 1643012410
  },
  {
    "city": "São Sebastião da Grama",
    "city_ascii": "Sao Sebastiao da Grama",
    "lat": -21.7108,
    "lng": -46.8208,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 12355,
    "id": 1076446818
  },
  {
    "city": "Elkhorn",
    "city_ascii": "Elkhorn",
    "lat": 42.6711,
    "lng": -88.5377,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 12404,
    "id": 1840002480
  },
  {
    "city": "Ongwediva",
    "city_ascii": "Ongwediva",
    "lat": -17.7833,
    "lng": 15.7667,
    "country": "Namibia",
    "iso2": "NA",
    "iso3": "NAM",
    "admin_name": "Oshana",
    "capital": "",
    "population": 10964,
    "id": 1516962966
  },
  {
    "city": "Sânnicolau Mare",
    "city_ascii": "Sannicolau Mare",
    "lat": 46.0722,
    "lng": 20.6294,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Timiş",
    "capital": "",
    "population": 12312,
    "id": 1642288572
  },
  {
    "city": "Vobkent Shahri",
    "city_ascii": "Vobkent Shahri",
    "lat": 40.0233,
    "lng": 64.5139,
    "country": "Uzbekistan",
    "iso2": "UZ",
    "iso3": "UZB",
    "admin_name": "Buxoro",
    "capital": "minor",
    "population": 12351,
    "id": 1860136901
  },
  {
    "city": "Kavarna",
    "city_ascii": "Kavarna",
    "lat": 43.4333,
    "lng": 28.3389,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Dobrich",
    "capital": "minor",
    "population": 12369,
    "id": 1100727601
  },
  {
    "city": "Ludwigslust",
    "city_ascii": "Ludwigslust",
    "lat": 53.3332,
    "lng": 11.5023,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Mecklenburg-Western Pomerania",
    "capital": "",
    "population": 12233,
    "id": 1276582763
  },
  {
    "city": "Hammonton",
    "city_ascii": "Hammonton",
    "lat": 39.6572,
    "lng": -74.7678,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 12389,
    "id": 1840003800
  },
  {
    "city": "Lom Sak",
    "city_ascii": "Lom Sak",
    "lat": 16.7775,
    "lng": 101.2468,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Phetchabun",
    "capital": "minor",
    "population": 12290,
    "id": 1764798281
  },
  {
    "city": "Ulster",
    "city_ascii": "Ulster",
    "lat": 41.9699,
    "lng": -74.0041,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 12388,
    "id": 1840087981
  },
  {
    "city": "Ried im Innkreis",
    "city_ascii": "Ried im Innkreis",
    "lat": 48.21,
    "lng": 13.4894,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Oberösterreich",
    "capital": "minor",
    "population": 11903,
    "id": 1040700214
  },
  {
    "city": "Overlea",
    "city_ascii": "Overlea",
    "lat": 39.3642,
    "lng": -76.5176,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 12384,
    "id": 1840005683
  },
  {
    "city": "Corinth",
    "city_ascii": "Corinth",
    "lat": 34.9474,
    "lng": -88.5143,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Mississippi",
    "capital": "",
    "population": 12382,
    "id": 1840014638
  },
  {
    "city": "Woolwich",
    "city_ascii": "Woolwich",
    "lat": 39.74,
    "lng": -75.317,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 12381,
    "id": 1840081674
  },
  {
    "city": "Virginia",
    "city_ascii": "Virginia",
    "lat": 47.5169,
    "lng": -92.5128,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 12381,
    "id": 1840009954
  },
  {
    "city": "Jüterbog",
    "city_ascii": "Juterbog",
    "lat": 51.9933,
    "lng": 13.0728,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Brandenburg",
    "capital": "",
    "population": 12311,
    "id": 1276769458
  },
  {
    "city": "Lakeland Highlands",
    "city_ascii": "Lakeland Highlands",
    "lat": 27.9572,
    "lng": -81.9496,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 12376,
    "id": 1840029047
  },
  {
    "city": "Inverell",
    "city_ascii": "Inverell",
    "lat": -29.7817,
    "lng": 151.1178,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 11660,
    "id": 1036652902
  },
  {
    "city": "Los Alamos",
    "city_ascii": "Los Alamos",
    "lat": 35.8926,
    "lng": -106.2862,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Mexico",
    "capital": "",
    "population": 12373,
    "id": 1840017779
  },
  {
    "city": "Blue Ash",
    "city_ascii": "Blue Ash",
    "lat": 39.248,
    "lng": -84.3827,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 12372,
    "id": 1840003812
  },
  {
    "city": "Holiday City-Berkeley",
    "city_ascii": "Holiday City-Berkeley",
    "lat": 39.9639,
    "lng": -74.2787,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 12360,
    "id": 1840073689
  },
  {
    "city": "Harrison",
    "city_ascii": "Harrison",
    "lat": 44.1935,
    "lng": -88.2941,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 12358,
    "id": 1840038103
  },
  {
    "city": "Holly Hill",
    "city_ascii": "Holly Hill",
    "lat": 29.2443,
    "lng": -81.0463,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 12357,
    "id": 1840015078
  },
  {
    "city": "Foča",
    "city_ascii": "Foca",
    "lat": 43.5,
    "lng": 18.7833,
    "country": "Bosnia And Herzegovina",
    "iso2": "BA",
    "iso3": "BIH",
    "admin_name": "Srpska, Republika",
    "capital": "minor",
    "population": 12334,
    "id": 1070603568
  },
  {
    "city": "Maltby",
    "city_ascii": "Maltby",
    "lat": 47.8027,
    "lng": -122.1044,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 12350,
    "id": 1840017326
  },
  {
    "city": "Rushall",
    "city_ascii": "Rushall",
    "lat": 52.611,
    "lng": -1.957,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Walsall",
    "capital": "",
    "population": 11871,
    "id": 1826029820
  },
  {
    "city": "North Smithfield",
    "city_ascii": "North Smithfield",
    "lat": 41.9727,
    "lng": -71.5514,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Rhode Island",
    "capital": "",
    "population": 12349,
    "id": 1840106199
  },
  {
    "city": "New Baltimore",
    "city_ascii": "New Baltimore",
    "lat": 42.6903,
    "lng": -82.7398,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 12347,
    "id": 1840003090
  },
  {
    "city": "Palmares Paulista",
    "city_ascii": "Palmares Paulista",
    "lat": -21.0828,
    "lng": -48.8008,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 12336,
    "id": 1076440891
  },
  {
    "city": "Gulfport",
    "city_ascii": "Gulfport",
    "lat": 27.7463,
    "lng": -82.71,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 12342,
    "id": 1840014145
  },
  {
    "city": "North Union",
    "city_ascii": "North Union",
    "lat": 39.9102,
    "lng": -79.6731,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 12342,
    "id": 1840149586
  },
  {
    "city": "Terryville",
    "city_ascii": "Terryville",
    "lat": 40.909,
    "lng": -73.0492,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 12340,
    "id": 1840005105
  },
  {
    "city": "Derby",
    "city_ascii": "Derby",
    "lat": 41.3265,
    "lng": -73.0833,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 12339,
    "id": 1840004847
  },
  {
    "city": "Orange City",
    "city_ascii": "Orange City",
    "lat": 28.9347,
    "lng": -81.2881,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 12335,
    "id": 1840015075
  },
  {
    "city": "Portland",
    "city_ascii": "Portland",
    "lat": 36.5883,
    "lng": -86.5194,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 12335,
    "id": 1840014418
  },
  {
    "city": "Chino Valley",
    "city_ascii": "Chino Valley",
    "lat": 34.7593,
    "lng": -112.4107,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 12335,
    "id": 1840022753
  },
  {
    "city": "Dorado",
    "city_ascii": "Dorado",
    "lat": 18.4657,
    "lng": -66.2724,
    "country": "Puerto Rico",
    "iso2": "PR",
    "iso3": "PRI",
    "admin_name": "Puerto Rico",
    "capital": "",
    "population": 12330,
    "id": 1630023736
  },
  {
    "city": "Ulverston",
    "city_ascii": "Ulverston",
    "lat": 54.193,
    "lng": -3.09,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Cumbria",
    "capital": "",
    "population": 11678,
    "id": 1826513559
  },
  {
    "city": "Massena",
    "city_ascii": "Massena",
    "lat": 44.9609,
    "lng": -74.8339,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 12329,
    "id": 1840004058
  },
  {
    "city": "Bastrop",
    "city_ascii": "Bastrop",
    "lat": 32.7749,
    "lng": -91.9058,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 12327,
    "id": 1840013793
  },
  {
    "city": "Županja",
    "city_ascii": "Zupanja",
    "lat": 45.0667,
    "lng": 18.7,
    "country": "Croatia",
    "iso2": "HR",
    "iso3": "HRV",
    "admin_name": "Vukovarsko-Srijemska Županija",
    "capital": "minor",
    "population": 12090,
    "id": 1191054836
  },
  {
    "city": "Gladstone",
    "city_ascii": "Gladstone",
    "lat": 45.3864,
    "lng": -122.5933,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 12324,
    "id": 1840019949
  },
  {
    "city": "Khem Karan",
    "city_ascii": "Khem Karan",
    "lat": 31.16,
    "lng": 74.66,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Punjab",
    "capital": "",
    "population": 11938,
    "id": 1356049560
  },
  {
    "city": "Uren",
    "city_ascii": "Uren",
    "lat": 57.4667,
    "lng": 45.7833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Nizhegorodskaya Oblast’",
    "capital": "",
    "population": 12309,
    "id": 1643733031
  },
  {
    "city": "Tha Maka",
    "city_ascii": "Tha Maka",
    "lat": 13.9499,
    "lng": 99.753,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Kanchanaburi",
    "capital": "minor",
    "population": 11966,
    "id": 1764775732
  },
  {
    "city": "Hagenow",
    "city_ascii": "Hagenow",
    "lat": 53.4317,
    "lng": 11.1931,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Mecklenburg-Western Pomerania",
    "capital": "",
    "population": 12137,
    "id": 1276783883
  },
  {
    "city": "Bodaybo",
    "city_ascii": "Bodaybo",
    "lat": 57.8506,
    "lng": 114.1933,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Irkutskaya Oblast’",
    "capital": "",
    "population": 12316,
    "id": 1643017251
  },
  {
    "city": "Winnetka",
    "city_ascii": "Winnetka",
    "lat": 42.1064,
    "lng": -87.7421,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 12316,
    "id": 1840011317
  },
  {
    "city": "Markham",
    "city_ascii": "Markham",
    "lat": 41.6,
    "lng": -87.6905,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 12314,
    "id": 1840008130
  },
  {
    "city": "Gravenhurst",
    "city_ascii": "Gravenhurst",
    "lat": 44.9167,
    "lng": -79.3667,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 12311,
    "id": 1124842372
  },
  {
    "city": "Sākib",
    "city_ascii": "Sakib",
    "lat": 32.2843,
    "lng": 35.8092,
    "country": "Jordan",
    "iso2": "JO",
    "iso3": "JOR",
    "admin_name": "Jarash",
    "capital": "",
    "population": 11586,
    "id": 1400579775
  },
  {
    "city": "Marion",
    "city_ascii": "Marion",
    "lat": 35.2041,
    "lng": -90.2061,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arkansas",
    "capital": "",
    "population": 12310,
    "id": 1840015445
  },
  {
    "city": "Forrest City",
    "city_ascii": "Forrest City",
    "lat": 35.0135,
    "lng": -90.7931,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arkansas",
    "capital": "",
    "population": 12309,
    "id": 1840013522
  },
  {
    "city": "Aj Jourf",
    "city_ascii": "Aj Jourf",
    "lat": 31.4903,
    "lng": -4.4014,
    "country": "Morocco",
    "iso2": "MA",
    "iso3": "MAR",
    "admin_name": "Drâa-Tafilalet",
    "capital": "",
    "population": 12302,
    "id": 1504982069
  },
  {
    "city": "Leon Valley",
    "city_ascii": "Leon Valley",
    "lat": 29.4954,
    "lng": -98.6143,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 12306,
    "id": 1840020959
  },
  {
    "city": "Mays Chapel",
    "city_ascii": "Mays Chapel",
    "lat": 39.4343,
    "lng": -76.6516,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 12303,
    "id": 1840031492
  },
  {
    "city": "Winchester",
    "city_ascii": "Winchester",
    "lat": 35.1898,
    "lng": -86.1074,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 12299,
    "id": 1840015466
  },
  {
    "city": "New Mills",
    "city_ascii": "New Mills",
    "lat": 53.367,
    "lng": -2.007,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Derbyshire",
    "capital": "",
    "population": 12291,
    "id": 1826456632
  },
  {
    "city": "Enns",
    "city_ascii": "Enns",
    "lat": 48.2167,
    "lng": 14.475,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Oberösterreich",
    "capital": "",
    "population": 11937,
    "id": 1040630607
  },
  {
    "city": "Hexham",
    "city_ascii": "Hexham",
    "lat": 54.971,
    "lng": -2.101,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Northumberland",
    "capital": "",
    "population": 11829,
    "id": 1826406735
  },
  {
    "city": "Ichinomiya",
    "city_ascii": "Ichinomiya",
    "lat": 35.3728,
    "lng": 140.3689,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Chiba",
    "capital": "",
    "population": 11780,
    "id": 1392597662
  },
  {
    "city": "Dărmăneşti",
    "city_ascii": "Darmanesti",
    "lat": 46.37,
    "lng": 26.4797,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Bacău",
    "capital": "",
    "population": 12247,
    "id": 1642492566
  },
  {
    "city": "Newcastle",
    "city_ascii": "Newcastle",
    "lat": 47.5303,
    "lng": -122.1633,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 12292,
    "id": 1840019831
  },
  {
    "city": "Highlands",
    "city_ascii": "Highlands",
    "lat": 41.3601,
    "lng": -74.0084,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 12292,
    "id": 1840087533
  },
  {
    "city": "New Kensington",
    "city_ascii": "New Kensington",
    "lat": 40.5711,
    "lng": -79.7521,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 12292,
    "id": 1840001151
  },
  {
    "city": "Lumberton",
    "city_ascii": "Lumberton",
    "lat": 39.9569,
    "lng": -74.8035,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 12287,
    "id": 1840081622
  },
  {
    "city": "Kewanee",
    "city_ascii": "Kewanee",
    "lat": 41.2399,
    "lng": -89.9263,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 12283,
    "id": 1840008206
  },
  {
    "city": "Weinfelden",
    "city_ascii": "Weinfelden",
    "lat": 47.5698,
    "lng": 9.112,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Thurgau",
    "capital": "minor",
    "population": 11534,
    "id": 1756205526
  },
  {
    "city": "Tzintzuntzán",
    "city_ascii": "Tzintzuntzan",
    "lat": 19.6283,
    "lng": -101.5789,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Michoacán de Ocampo",
    "capital": "minor",
    "population": 12259,
    "id": 1484799786
  },
  {
    "city": "Pell City",
    "city_ascii": "Pell City",
    "lat": 33.5609,
    "lng": -86.2669,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 12275,
    "id": 1840014771
  },
  {
    "city": "Prieska",
    "city_ascii": "Prieska",
    "lat": -29.6683,
    "lng": 22.7439,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "Northern Cape",
    "capital": "",
    "population": 11236,
    "id": 1710649877
  },
  {
    "city": "Tyngsborough",
    "city_ascii": "Tyngsborough",
    "lat": 42.6662,
    "lng": -71.429,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 12272,
    "id": 1840070201
  },
  {
    "city": "Poquoson",
    "city_ascii": "Poquoson",
    "lat": 37.1318,
    "lng": -76.3569,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 12271,
    "id": 1840003863
  },
  {
    "city": "Arth",
    "city_ascii": "Arth",
    "lat": 47.0644,
    "lng": 8.5242,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Schwyz",
    "capital": "",
    "population": 11983,
    "id": 1756840226
  },
  {
    "city": "Clayton",
    "city_ascii": "Clayton",
    "lat": 37.9403,
    "lng": -121.9301,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 12265,
    "id": 1840018901
  },
  {
    "city": "Rovinari",
    "city_ascii": "Rovinari",
    "lat": 44.9125,
    "lng": 23.1622,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Gorj",
    "capital": "",
    "population": 11816,
    "id": 1642920797
  },
  {
    "city": "Perth East",
    "city_ascii": "Perth East",
    "lat": 43.47,
    "lng": -80.95,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 12261,
    "id": 1124001760
  },
  {
    "city": "Gaildorf",
    "city_ascii": "Gaildorf",
    "lat": 49,
    "lng": 9.7667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 12080,
    "id": 1276657591
  },
  {
    "city": "Blythe",
    "city_ascii": "Blythe",
    "lat": 33.622,
    "lng": -114.6188,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 12260,
    "id": 1840019299
  },
  {
    "city": "Velddrif",
    "city_ascii": "Velddrif",
    "lat": -32.7667,
    "lng": 18.1667,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "Western Cape",
    "capital": "",
    "population": 11017,
    "id": 1710031295
  },
  {
    "city": "Larkspur",
    "city_ascii": "Larkspur",
    "lat": 37.9393,
    "lng": -122.5313,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 12254,
    "id": 1840020261
  },
  {
    "city": "Cottonwood",
    "city_ascii": "Cottonwood",
    "lat": 34.7192,
    "lng": -112.0014,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 12253,
    "id": 1840019168
  },
  {
    "city": "Wünnenberg",
    "city_ascii": "Wunnenberg",
    "lat": 51.5167,
    "lng": 8.7,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 12177,
    "id": 1276918694
  },
  {
    "city": "Vulcăneşti",
    "city_ascii": "Vulcanesti",
    "lat": 45.6842,
    "lng": 28.4028,
    "country": "Moldova",
    "iso2": "MD",
    "iso3": "MDA",
    "admin_name": "Găgăuzia",
    "capital": "",
    "population": 12185,
    "id": 1498275011
  },
  {
    "city": "Vilsbiburg",
    "city_ascii": "Vilsbiburg",
    "lat": 48.4475,
    "lng": 12.3475,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 12074,
    "id": 1276843205
  },
  {
    "city": "Vălenii de Munte",
    "city_ascii": "Valenii de Munte",
    "lat": 45.1856,
    "lng": 26.0397,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Prahova",
    "capital": "",
    "population": 11707,
    "id": 1642303389
  },
  {
    "city": "Palestina",
    "city_ascii": "Palestina",
    "lat": -20.39,
    "lng": -49.4328,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 12231,
    "id": 1076718506
  },
  {
    "city": "Bankya",
    "city_ascii": "Bankya",
    "lat": 42.7,
    "lng": 23.1333,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Sofia-Grad",
    "capital": "",
    "population": 11233,
    "id": 1100031419
  },
  {
    "city": "Węgorzewo",
    "city_ascii": "Wegorzewo",
    "lat": 54.2167,
    "lng": 21.75,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Warmińsko-Mazurskie",
    "capital": "minor",
    "population": 12186,
    "id": 1616206015
  },
  {
    "city": "Ocean Pines",
    "city_ascii": "Ocean Pines",
    "lat": 38.3825,
    "lng": -75.1475,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 12246,
    "id": 1840006252
  },
  {
    "city": "Boloso",
    "city_ascii": "Boloso",
    "lat": 2.0337,
    "lng": 15.2167,
    "country": "Congo (Brazzaville)",
    "iso2": "CG",
    "iso3": "COG",
    "admin_name": "Sangha",
    "capital": "",
    "population": 12244,
    "id": 1178777874
  },
  {
    "city": "Cecil",
    "city_ascii": "Cecil",
    "lat": 40.3147,
    "lng": -80.1943,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 12245,
    "id": 1840034893
  },
  {
    "city": "Lincolnwood",
    "city_ascii": "Lincolnwood",
    "lat": 42.0054,
    "lng": -87.7329,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 12245,
    "id": 1840011259
  },
  {
    "city": "Burladingen",
    "city_ascii": "Burladingen",
    "lat": 48.2903,
    "lng": 9.1094,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 12146,
    "id": 1276159850
  },
  {
    "city": "North Valley",
    "city_ascii": "North Valley",
    "lat": 35.1736,
    "lng": -106.6231,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Mexico",
    "capital": "",
    "population": 12242,
    "id": 1840033721
  },
  {
    "city": "Arkansas City",
    "city_ascii": "Arkansas City",
    "lat": 37.0726,
    "lng": -97.0385,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kansas",
    "capital": "",
    "population": 12242,
    "id": 1840001700
  },
  {
    "city": "Dăbuleni",
    "city_ascii": "Dabuleni",
    "lat": 43.8011,
    "lng": 24.0919,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Dolj",
    "capital": "",
    "population": 12175,
    "id": 1642780769
  },
  {
    "city": "Sapna",
    "city_ascii": "Sapna",
    "lat": 44.4917,
    "lng": 19.0028,
    "country": "Bosnia And Herzegovina",
    "iso2": "BA",
    "iso3": "BIH",
    "admin_name": "Bosnia and Herzegovina, Federation of",
    "capital": "minor",
    "population": 12136,
    "id": 1070454621
  },
  {
    "city": "Elon",
    "city_ascii": "Elon",
    "lat": 36.1014,
    "lng": -79.5085,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 12232,
    "id": 1840016143
  },
  {
    "city": "Palmer",
    "city_ascii": "Palmer",
    "lat": 42.1888,
    "lng": -72.3112,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 12232,
    "id": 1840132584
  },
  {
    "city": "Perleberg",
    "city_ascii": "Perleberg",
    "lat": 53.0667,
    "lng": 11.8667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Brandenburg",
    "capital": "minor",
    "population": 12141,
    "id": 1276426476
  },
  {
    "city": "Unterzollikofen",
    "city_ascii": "Unterzollikofen",
    "lat": 46.9997,
    "lng": 7.4569,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Bern",
    "capital": "",
    "population": 10314,
    "id": 1756008686
  },
  {
    "city": "Aleksandrovsk",
    "city_ascii": "Aleksandrovsk",
    "lat": 59.1667,
    "lng": 57.5833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Permskiy Kray",
    "capital": "",
    "population": 12222,
    "id": 1643050793
  },
  {
    "city": "Wells Branch",
    "city_ascii": "Wells Branch",
    "lat": 30.4432,
    "lng": -97.6792,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 12227,
    "id": 1840019588
  },
  {
    "city": "West Monroe",
    "city_ascii": "West Monroe",
    "lat": 32.512,
    "lng": -92.1513,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 12227,
    "id": 1840015795
  },
  {
    "city": "Beatrice",
    "city_ascii": "Beatrice",
    "lat": 40.2737,
    "lng": -96.7454,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Nebraska",
    "capital": "",
    "population": 12226,
    "id": 1840001352
  },
  {
    "city": "Wolgast",
    "city_ascii": "Wolgast",
    "lat": 54.05,
    "lng": 13.7667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Mecklenburg-Western Pomerania",
    "capital": "",
    "population": 12028,
    "id": 1276669762
  },
  {
    "city": "North Castle",
    "city_ascii": "North Castle",
    "lat": 41.1331,
    "lng": -73.695,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 12223,
    "id": 1840087730
  },
  {
    "city": "Parkes",
    "city_ascii": "Parkes",
    "lat": -33.095,
    "lng": 148.2628,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 11408,
    "id": 1036977270
  },
  {
    "city": "Gatumba",
    "city_ascii": "Gatumba",
    "lat": -3.3333,
    "lng": 29.25,
    "country": "Burundi",
    "iso2": "BI",
    "iso3": "BDI",
    "admin_name": "Bujumbura Rural",
    "capital": "",
    "population": 11700,
    "id": 1108258446
  },
  {
    "city": "Wilmington",
    "city_ascii": "Wilmington",
    "lat": 39.4387,
    "lng": -83.8184,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 12222,
    "id": 1840010630
  },
  {
    "city": "Ahram",
    "city_ascii": "Ahram",
    "lat": 28.8825,
    "lng": 51.2744,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Būshehr",
    "capital": "minor",
    "population": 12182,
    "id": 1364866540
  },
  {
    "city": "Batesville",
    "city_ascii": "Batesville",
    "lat": 35.7687,
    "lng": -91.6226,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arkansas",
    "capital": "",
    "population": 12220,
    "id": 1840013398
  },
  {
    "city": "Prince Rupert",
    "city_ascii": "Prince Rupert",
    "lat": 54.3122,
    "lng": -130.3271,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "British Columbia",
    "capital": "",
    "population": 12220,
    "id": 1124847707
  },
  {
    "city": "Amherst",
    "city_ascii": "Amherst",
    "lat": 41.4022,
    "lng": -82.2303,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 12219,
    "id": 1840000640
  },
  {
    "city": "Kibungo",
    "city_ascii": "Kibungo",
    "lat": -2.1608,
    "lng": 30.5442,
    "country": "Rwanda",
    "iso2": "RW",
    "iso3": "RWA",
    "admin_name": "Eastern Province",
    "capital": "",
    "population": 11537,
    "id": 1646746155
  },
  {
    "city": "Nový Bor",
    "city_ascii": "Novy Bor",
    "lat": 50.7577,
    "lng": 14.5557,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Liberecký Kraj",
    "capital": "",
    "population": 11616,
    "id": 1203651923
  },
  {
    "city": "Athens",
    "city_ascii": "Athens",
    "lat": 32.2041,
    "lng": -95.8321,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 12210,
    "id": 1840019500
  },
  {
    "city": "Neuenrade",
    "city_ascii": "Neuenrade",
    "lat": 51.2839,
    "lng": 7.78,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 11982,
    "id": 1276329110
  },
  {
    "city": "Ringwood",
    "city_ascii": "Ringwood",
    "lat": 41.1064,
    "lng": -74.2749,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 12198,
    "id": 1840000835
  },
  {
    "city": "Jesup",
    "city_ascii": "Jesup",
    "lat": 31.5992,
    "lng": -81.8895,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 12198,
    "id": 1840014943
  },
  {
    "city": "Ishpeming",
    "city_ascii": "Ishpeming",
    "lat": 46.4862,
    "lng": -87.6658,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 12198,
    "id": 1840003922
  },
  {
    "city": "Üllő",
    "city_ascii": "Ullo",
    "lat": 47.3842,
    "lng": 19.3444,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Pest",
    "capital": "",
    "population": 11949,
    "id": 1348501834
  },
  {
    "city": "Neustadt",
    "city_ascii": "Neustadt",
    "lat": 51.0239,
    "lng": 14.2167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 12051,
    "id": 1276897254
  },
  {
    "city": "Speedway",
    "city_ascii": "Speedway",
    "lat": 39.7937,
    "lng": -86.2479,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 12193,
    "id": 1840010574
  },
  {
    "city": "Enumclaw",
    "city_ascii": "Enumclaw",
    "lat": 47.2017,
    "lng": -121.9897,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 12190,
    "id": 1840019825
  },
  {
    "city": "Uzyn",
    "city_ascii": "Uzyn",
    "lat": 49.8242,
    "lng": 30.4425,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Kyyivs’ka Oblast’",
    "capital": "",
    "population": 12147,
    "id": 1804493270
  },
  {
    "city": "Roding",
    "city_ascii": "Roding",
    "lat": 49.1936,
    "lng": 12.5192,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 12081,
    "id": 1276027598
  },
  {
    "city": "Arganil",
    "city_ascii": "Arganil",
    "lat": 40.2167,
    "lng": -8.05,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Coimbra",
    "capital": "minor",
    "population": 12145,
    "id": 1620980524
  },
  {
    "city": "Lakeside",
    "city_ascii": "Lakeside",
    "lat": 37.6133,
    "lng": -77.4768,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 12180,
    "id": 1840006392
  },
  {
    "city": "Zwönitz",
    "city_ascii": "Zwonitz",
    "lat": 50.6303,
    "lng": 12.8133,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 11993,
    "id": 1276062398
  },
  {
    "city": "Neosho",
    "city_ascii": "Neosho",
    "lat": 36.8441,
    "lng": -94.3756,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 12176,
    "id": 1840008793
  },
  {
    "city": "Leominster",
    "city_ascii": "Leominster",
    "lat": 52.2282,
    "lng": -2.7385,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Herefordshire",
    "capital": "",
    "population": 11691,
    "id": 1826948829
  },
  {
    "city": "Lincoln City",
    "city_ascii": "Lincoln City",
    "lat": 44.9751,
    "lng": -124.0073,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 12172,
    "id": 1840019974
  },
  {
    "city": "Socuéllamos",
    "city_ascii": "Socuellamos",
    "lat": 39.2933,
    "lng": -2.7942,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Castille-La Mancha",
    "capital": "",
    "population": 12139,
    "id": 1724254841
  },
  {
    "city": "Prévost",
    "city_ascii": "Prevost",
    "lat": 45.87,
    "lng": -74.08,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 12171,
    "id": 1124001584
  },
  {
    "city": "Northlake",
    "city_ascii": "Northlake",
    "lat": 41.9143,
    "lng": -87.9054,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 12161,
    "id": 1840008131
  },
  {
    "city": "North Greenbush",
    "city_ascii": "North Greenbush",
    "lat": 42.6706,
    "lng": -73.6632,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 12158,
    "id": 1840087735
  },
  {
    "city": "Eatontown",
    "city_ascii": "Eatontown",
    "lat": 40.2913,
    "lng": -74.0558,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 12157,
    "id": 1840003679
  },
  {
    "city": "Newport",
    "city_ascii": "Newport",
    "lat": 44.6242,
    "lng": -124.0513,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 12155,
    "id": 1840019975
  },
  {
    "city": "Lauffen am Neckar",
    "city_ascii": "Lauffen am Neckar",
    "lat": 49.0764,
    "lng": 9.1567,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 11640,
    "id": 1276145495
  },
  {
    "city": "Nova Gradiška",
    "city_ascii": "Nova Gradiska",
    "lat": 45.25,
    "lng": 17.3833,
    "country": "Croatia",
    "iso2": "HR",
    "iso3": "HRV",
    "admin_name": "Brodsko-Posavska Županija",
    "capital": "minor",
    "population": 11821,
    "id": 1191025176
  },
  {
    "city": "Bad Vöslau",
    "city_ascii": "Bad Voslau",
    "lat": 47.9669,
    "lng": 16.2144,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Niederösterreich",
    "capital": "",
    "population": 11844,
    "id": 1040153986
  },
  {
    "city": "Richland Township",
    "city_ascii": "Richland Township",
    "lat": 40.2841,
    "lng": -78.845,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 12148,
    "id": 1840143652
  },
  {
    "city": "Ladenburg",
    "city_ascii": "Ladenburg",
    "lat": 49.4719,
    "lng": 8.6092,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 11537,
    "id": 1276610621
  },
  {
    "city": "Harrislee",
    "city_ascii": "Harrislee",
    "lat": 54.7972,
    "lng": 9.3764,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Schleswig-Holstein",
    "capital": "",
    "population": 11531,
    "id": 1276177135
  },
  {
    "city": "Harwich",
    "city_ascii": "Harwich",
    "lat": 41.6957,
    "lng": -70.0684,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 12142,
    "id": 1840070211
  },
  {
    "city": "Lower Pottsgrove",
    "city_ascii": "Lower Pottsgrove",
    "lat": 40.2538,
    "lng": -75.5975,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 12140,
    "id": 1840142133
  },
  {
    "city": "Tabapuã",
    "city_ascii": "Tabapua",
    "lat": -20.9639,
    "lng": -49.0319,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 12103,
    "id": 1076931996
  },
  {
    "city": "Sainte-Adèle",
    "city_ascii": "Sainte-Adele",
    "lat": 45.95,
    "lng": -74.13,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 12137,
    "id": 1124439200
  },
  {
    "city": "Freeport",
    "city_ascii": "Freeport",
    "lat": 28.9454,
    "lng": -95.3601,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 12136,
    "id": 1840020974
  },
  {
    "city": "Zaouiet Sousse",
    "city_ascii": "Zaouiet Sousse",
    "lat": 35.7887,
    "lng": 10.6274,
    "country": "Tunisia",
    "iso2": "TN",
    "iso3": "TUN",
    "admin_name": "Sousse",
    "capital": "",
    "population": 10740,
    "id": 1788010481
  },
  {
    "city": "Webb City",
    "city_ascii": "Webb City",
    "lat": 37.1413,
    "lng": -94.4676,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 12134,
    "id": 1840010878
  },
  {
    "city": "Tábua",
    "city_ascii": "Tabua",
    "lat": 40.3667,
    "lng": -8.0333,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Coimbra",
    "capital": "minor",
    "population": 12071,
    "id": 1620014800
  },
  {
    "city": "Cherlak",
    "city_ascii": "Cherlak",
    "lat": 54.1605,
    "lng": 74.82,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Omskaya Oblast’",
    "capital": "",
    "population": 12122,
    "id": 1643336296
  },
  {
    "city": "Leingarten",
    "city_ascii": "Leingarten",
    "lat": 49.15,
    "lng": 9.1167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 11633,
    "id": 1276343480
  },
  {
    "city": "Coolidge",
    "city_ascii": "Coolidge",
    "lat": 32.9395,
    "lng": -111.5261,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 12124,
    "id": 1840019367
  },
  {
    "city": "Nová Dubnica",
    "city_ascii": "Nova Dubnica",
    "lat": 48.9333,
    "lng": 18.15,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Nitriansky",
    "capital": "",
    "population": 11134,
    "id": 1703517526
  },
  {
    "city": "Knowsley",
    "city_ascii": "Knowsley",
    "lat": 53.4498,
    "lng": -2.8501,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Knowsley",
    "capital": "",
    "population": 11343,
    "id": 1826568900
  },
  {
    "city": "Bicske",
    "city_ascii": "Bicske",
    "lat": 47.4907,
    "lng": 18.6363,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Fejér",
    "capital": "minor",
    "population": 11969,
    "id": 1348286686
  },
  {
    "city": "Melissa",
    "city_ascii": "Melissa",
    "lat": 33.2891,
    "lng": -96.5573,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 12117,
    "id": 1840020658
  },
  {
    "city": "Motegi",
    "city_ascii": "Motegi",
    "lat": 36.5167,
    "lng": 140.1833,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Tochigi",
    "capital": "",
    "population": 12046,
    "id": 1392187288
  },
  {
    "city": "Drouin",
    "city_ascii": "Drouin",
    "lat": -38.1333,
    "lng": 145.85,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Victoria",
    "capital": "",
    "population": 11887,
    "id": 1036564546
  },
  {
    "city": "Uacu Cungo",
    "city_ascii": "Uacu Cungo",
    "lat": -11.3583,
    "lng": 15.1194,
    "country": "Angola",
    "iso2": "AO",
    "iso3": "AGO",
    "admin_name": "Kwanza Sul",
    "capital": "",
    "population": 12069,
    "id": 1024677127
  },
  {
    "city": "Rogerstone",
    "city_ascii": "Rogerstone",
    "lat": 51.5906,
    "lng": -3.0537,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Newport",
    "capital": "",
    "population": 10158,
    "id": 1826510350
  },
  {
    "city": "New Garden",
    "city_ascii": "New Garden",
    "lat": 39.8119,
    "lng": -75.7517,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 12111,
    "id": 1840100548
  },
  {
    "city": "James Island",
    "city_ascii": "James Island",
    "lat": 32.7353,
    "lng": -79.9396,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 12109,
    "id": 1840043446
  },
  {
    "city": "Kingsburg",
    "city_ascii": "Kingsburg",
    "lat": 36.5245,
    "lng": -119.5602,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 12108,
    "id": 1840020325
  },
  {
    "city": "Brwinów",
    "city_ascii": "Brwinow",
    "lat": 52.1417,
    "lng": 20.7167,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Mazowieckie",
    "capital": "",
    "population": 11743,
    "id": 1616620268
  },
  {
    "city": "Comarnic",
    "city_ascii": "Comarnic",
    "lat": 45.2511,
    "lng": 25.6353,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Prahova",
    "capital": "",
    "population": 11970,
    "id": 1642476433
  },
  {
    "city": "Xichú",
    "city_ascii": "Xichu",
    "lat": 21.2997,
    "lng": -100.0553,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Guanajuato",
    "capital": "minor",
    "population": 11323,
    "id": 1484008759
  },
  {
    "city": "Boskovice",
    "city_ascii": "Boskovice",
    "lat": 49.4875,
    "lng": 16.66,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Jihomoravský Kraj",
    "capital": "",
    "population": 11681,
    "id": 1203299524
  },
  {
    "city": "Platteville",
    "city_ascii": "Platteville",
    "lat": 42.728,
    "lng": -90.4676,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 12100,
    "id": 1840002981
  },
  {
    "city": "Mineral del Monte",
    "city_ascii": "Mineral del Monte",
    "lat": 20.1333,
    "lng": -98.6667,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Hidalgo",
    "capital": "minor",
    "population": 11944,
    "id": 1484360018
  },
  {
    "city": "Lovington",
    "city_ascii": "Lovington",
    "lat": 32.9125,
    "lng": -103.3277,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Mexico",
    "capital": "",
    "population": 12097,
    "id": 1840020617
  },
  {
    "city": "Middle Valley",
    "city_ascii": "Middle Valley",
    "lat": 35.1877,
    "lng": -85.1958,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 12097,
    "id": 1840013450
  },
  {
    "city": "Eureka",
    "city_ascii": "Eureka",
    "lat": 38.5015,
    "lng": -90.6492,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 12097,
    "id": 1840008582
  },
  {
    "city": "Jacala",
    "city_ascii": "Jacala",
    "lat": 21.0053,
    "lng": -99.1719,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Hidalgo",
    "capital": "",
    "population": 12057,
    "id": 1484527650
  },
  {
    "city": "Kentville",
    "city_ascii": "Kentville",
    "lat": 45.0775,
    "lng": -64.4958,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Nova Scotia",
    "capital": "",
    "population": 12088,
    "id": 1124530137
  },
  {
    "city": "Pepperell",
    "city_ascii": "Pepperell",
    "lat": 42.6713,
    "lng": -71.6043,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 12083,
    "id": 1840053483
  },
  {
    "city": "Rehoboth",
    "city_ascii": "Rehoboth",
    "lat": 41.8439,
    "lng": -71.2456,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 12082,
    "id": 1840053661
  },
  {
    "city": "Little Chute",
    "city_ascii": "Little Chute",
    "lat": 44.2905,
    "lng": -88.3206,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 12081,
    "id": 1840002408
  },
  {
    "city": "Tiszakécske",
    "city_ascii": "Tiszakecske",
    "lat": 46.9311,
    "lng": 20.095,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Bács-Kiskun",
    "capital": "minor",
    "population": 11990,
    "id": 1348645870
  },
  {
    "city": "Erfelek",
    "city_ascii": "Erfelek",
    "lat": 41.8793,
    "lng": 34.9184,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Sinop",
    "capital": "minor",
    "population": 12049,
    "id": 1792532648
  },
  {
    "city": "Wang Saphung",
    "city_ascii": "Wang Saphung",
    "lat": 17.2995,
    "lng": 101.7624,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Loei",
    "capital": "minor",
    "population": 11992,
    "id": 1764106072
  },
  {
    "city": "Sakamoto",
    "city_ascii": "Sakamoto",
    "lat": 37.9625,
    "lng": 140.8778,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Miyagi",
    "capital": "",
    "population": 11890,
    "id": 1392480384
  },
  {
    "city": "Sedro-Woolley",
    "city_ascii": "Sedro-Woolley",
    "lat": 48.5112,
    "lng": -122.2321,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 12072,
    "id": 1840021066
  },
  {
    "city": "Minden",
    "city_ascii": "Minden",
    "lat": 32.6187,
    "lng": -93.2762,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 12071,
    "id": 1840014864
  },
  {
    "city": "Ardanuç",
    "city_ascii": "Ardanuc",
    "lat": 41.1233,
    "lng": 42.0647,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Artvin",
    "capital": "minor",
    "population": 12056,
    "id": 1792304431
  },
  {
    "city": "Oestrich-Winkel",
    "city_ascii": "Oestrich-Winkel",
    "lat": 50.0085,
    "lng": 8.0199,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Rhineland-Palatinate",
    "capital": "",
    "population": 11869,
    "id": 1276526238
  },
  {
    "city": "Weston",
    "city_ascii": "Weston",
    "lat": 42.3589,
    "lng": -71.3001,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 12067,
    "id": 1840053494
  },
  {
    "city": "Bath",
    "city_ascii": "Bath",
    "lat": 42.3219,
    "lng": -77.3083,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 12064,
    "id": 1840004559
  },
  {
    "city": "Somerville",
    "city_ascii": "Somerville",
    "lat": 40.5696,
    "lng": -74.6092,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 12063,
    "id": 1840001056
  },
  {
    "city": "Ottawa",
    "city_ascii": "Ottawa",
    "lat": 38.6,
    "lng": -95.2642,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kansas",
    "capital": "",
    "population": 12060,
    "id": 1840003840
  },
  {
    "city": "Oswaldtwistle",
    "city_ascii": "Oswaldtwistle",
    "lat": 53.743,
    "lng": -2.393,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Lancashire",
    "capital": "",
    "population": 11803,
    "id": 1826124855
  },
  {
    "city": "Alavus",
    "city_ascii": "Alavus",
    "lat": 62.5861,
    "lng": 23.6194,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Etelä-Pohjanmaa",
    "capital": "minor",
    "population": 12044,
    "id": 1246444159
  },
  {
    "city": "La Homa",
    "city_ascii": "La Homa",
    "lat": 26.2773,
    "lng": -98.3579,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 12054,
    "id": 1840037093
  },
  {
    "city": "Toropets",
    "city_ascii": "Toropets",
    "lat": 56.5,
    "lng": 31.6333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Tverskaya Oblast’",
    "capital": "",
    "population": 12048,
    "id": 1643009626
  },
  {
    "city": "Alvorada",
    "city_ascii": "Alvorada",
    "lat": -12.47,
    "lng": -49.082,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Tocantins",
    "capital": "",
    "population": 10232,
    "id": 1076121080
  },
  {
    "city": "Gatesville",
    "city_ascii": "Gatesville",
    "lat": 31.4419,
    "lng": -97.7351,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 12053,
    "id": 1840020825
  },
  {
    "city": "Don Sak",
    "city_ascii": "Don Sak",
    "lat": 9.3169,
    "lng": 99.6944,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Surat Thani",
    "capital": "minor",
    "population": 11934,
    "id": 1764466914
  },
  {
    "city": "Martuni",
    "city_ascii": "Martuni",
    "lat": 40.14,
    "lng": 45.3064,
    "country": "Armenia",
    "iso2": "AM",
    "iso3": "ARM",
    "admin_name": "Geghark’unik’",
    "capital": "",
    "population": 11987,
    "id": 1051260753
  },
  {
    "city": "South Charleston",
    "city_ascii": "South Charleston",
    "lat": 38.3482,
    "lng": -81.711,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "West Virginia",
    "capital": "",
    "population": 12047,
    "id": 1840006200
  },
  {
    "city": "Sowerby Bridge",
    "city_ascii": "Sowerby Bridge",
    "lat": 53.71,
    "lng": -1.91,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Calderdale",
    "capital": "",
    "population": 11703,
    "id": 1826733950
  },
  {
    "city": "Muroto-misakicho",
    "city_ascii": "Muroto-misakicho",
    "lat": 33.29,
    "lng": 134.1519,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Kōchi",
    "capital": "",
    "population": 11996,
    "id": 1392826132
  },
  {
    "city": "Vail",
    "city_ascii": "Vail",
    "lat": 32.0217,
    "lng": -110.6937,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 12044,
    "id": 1840019487
  },
  {
    "city": "Leeds",
    "city_ascii": "Leeds",
    "lat": 33.5429,
    "lng": -86.5636,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 12040,
    "id": 1840005106
  },
  {
    "city": "Bad Fallingbostel",
    "city_ascii": "Bad Fallingbostel",
    "lat": 52.8675,
    "lng": 9.6967,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "minor",
    "population": 11852,
    "id": 1276618111
  },
  {
    "city": "East Bethel",
    "city_ascii": "East Bethel",
    "lat": 45.3557,
    "lng": -93.2038,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 12038,
    "id": 1840007797
  },
  {
    "city": "Southwick",
    "city_ascii": "Southwick",
    "lat": 54.9193,
    "lng": -1.4062,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Sunderland",
    "capital": "",
    "population": 10535,
    "id": 1826865049
  },
  {
    "city": "Villas",
    "city_ascii": "Villas",
    "lat": 26.5504,
    "lng": -81.8679,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 12036,
    "id": 1840029092
  },
  {
    "city": "Festus",
    "city_ascii": "Festus",
    "lat": 38.2192,
    "lng": -90.4095,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 12036,
    "id": 1840008652
  },
  {
    "city": "Moss Bluff",
    "city_ascii": "Moss Bluff",
    "lat": 30.3039,
    "lng": -93.2051,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 12034,
    "id": 1840013962
  },
  {
    "city": "Falmouth",
    "city_ascii": "Falmouth",
    "lat": 43.7476,
    "lng": -70.2827,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maine",
    "capital": "",
    "population": 12034,
    "id": 1840052817
  },
  {
    "city": "Jupiter Farms",
    "city_ascii": "Jupiter Farms",
    "lat": 26.9224,
    "lng": -80.2187,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 12032,
    "id": 1840042432
  },
  {
    "city": "Riverview",
    "city_ascii": "Riverview",
    "lat": 42.1728,
    "lng": -83.1935,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 12032,
    "id": 1840003961
  },
  {
    "city": "Smithfield",
    "city_ascii": "Smithfield",
    "lat": 41.8347,
    "lng": -111.8266,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Utah",
    "capital": "",
    "population": 12025,
    "id": 1840021321
  },
  {
    "city": "Damascus",
    "city_ascii": "Damascus",
    "lat": 45.4233,
    "lng": -122.4437,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 12024,
    "id": 1840018571
  },
  {
    "city": "Kőszeg",
    "city_ascii": "Koszeg",
    "lat": 47.3817,
    "lng": 16.5519,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Vas",
    "capital": "minor",
    "population": 11805,
    "id": 1348854812
  },
  {
    "city": "Swan Hill",
    "city_ascii": "Swan Hill",
    "lat": -35.3333,
    "lng": 143.55,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Victoria",
    "capital": "",
    "population": 11103,
    "id": 1036220501
  },
  {
    "city": "Lichtenstein",
    "city_ascii": "Lichtenstein",
    "lat": 50.7564,
    "lng": 12.6317,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 11285,
    "id": 1276704122
  },
  {
    "city": "Sajószentpéter",
    "city_ascii": "Sajoszentpeter",
    "lat": 48.2169,
    "lng": 20.7183,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Borsod-Abaúj-Zemplén",
    "capital": "",
    "population": 11679,
    "id": 1348595790
  },
  {
    "city": "Ponteland",
    "city_ascii": "Ponteland",
    "lat": 55.048,
    "lng": -1.747,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Northumberland",
    "capital": "",
    "population": 10921,
    "id": 1826040320
  },
  {
    "city": "Fulton",
    "city_ascii": "Fulton",
    "lat": 38.8551,
    "lng": -91.9511,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 12012,
    "id": 1840008560
  },
  {
    "city": "Beauharnois",
    "city_ascii": "Beauharnois",
    "lat": 45.32,
    "lng": -73.87,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 12011,
    "id": 1124880971
  },
  {
    "city": "Les Îles-de-la-Madeleine",
    "city_ascii": "Les Iles-de-la-Madeleine",
    "lat": 47.3833,
    "lng": -61.8667,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 12010,
    "id": 1124000721
  },
  {
    "city": "Zlaté Moravce",
    "city_ascii": "Zlate Moravce",
    "lat": 48.3781,
    "lng": 18.3964,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Nitriansky",
    "capital": "minor",
    "population": 11583,
    "id": 1703464744
  },
  {
    "city": "Mitai",
    "city_ascii": "Mitai",
    "lat": 32.7117,
    "lng": 131.3078,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Miyazaki",
    "capital": "",
    "population": 11959,
    "id": 1392405708
  },
  {
    "city": "Nyírbátor",
    "city_ascii": "Nyirbator",
    "lat": 47.8353,
    "lng": 22.13,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Szabolcs-Szatmár-Bereg",
    "capital": "minor",
    "population": 11831,
    "id": 1348699897
  },
  {
    "city": "Princetown",
    "city_ascii": "Princetown",
    "lat": 5.9,
    "lng": -57.17,
    "country": "Guyana",
    "iso2": "GY",
    "iso3": "GUY",
    "admin_name": "East Berbice-Corentyne",
    "capital": "",
    "population": 12000,
    "id": 1328276701
  },
  {
    "city": "Volosovo",
    "city_ascii": "Volosovo",
    "lat": 59.45,
    "lng": 29.4833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Leningradskaya Oblast’",
    "capital": "minor",
    "population": 11987,
    "id": 1643898126
  },
  {
    "city": "Spring Lake",
    "city_ascii": "Spring Lake",
    "lat": 35.1842,
    "lng": -78.9959,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 12005,
    "id": 1840017875
  },
  {
    "city": "Holyhead",
    "city_ascii": "Holyhead",
    "lat": 53.309,
    "lng": -4.633,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Isle of Anglesey",
    "capital": "",
    "population": 11431,
    "id": 1826382629
  },
  {
    "city": "Folkston",
    "city_ascii": "Folkston",
    "lat": 30.8393,
    "lng": -82.0073,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 12001,
    "id": 1840013903
  },
  {
    "city": "Sárbogárd",
    "city_ascii": "Sarbogard",
    "lat": 46.8878,
    "lng": 18.6193,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Fejér",
    "capital": "minor",
    "population": 11937,
    "id": 1348805330
  },
  {
    "city": "Macedonia",
    "city_ascii": "Macedonia",
    "lat": 41.3147,
    "lng": -81.4989,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 12000,
    "id": 1840000797
  },
  {
    "city": "Hildburghausen",
    "city_ascii": "Hildburghausen",
    "lat": 50.4261,
    "lng": 10.7289,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Thuringia",
    "capital": "minor",
    "population": 11836,
    "id": 1276098756
  },
  {
    "city": "Jerome",
    "city_ascii": "Jerome",
    "lat": 42.7183,
    "lng": -114.5159,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Idaho",
    "capital": "",
    "population": 11996,
    "id": 1840018680
  },
  {
    "city": "Kasaji",
    "city_ascii": "Kasaji",
    "lat": -10.3662,
    "lng": 23.45,
    "country": "Congo (Kinshasa)",
    "iso2": "CD",
    "iso3": "COD",
    "admin_name": "Lualaba",
    "capital": "",
    "population": 11969,
    "id": 1180795640
  },
  {
    "city": "Pritzwalk",
    "city_ascii": "Pritzwalk",
    "lat": 53.1497,
    "lng": 12.1831,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Brandenburg",
    "capital": "",
    "population": 11924,
    "id": 1276349904
  },
  {
    "city": "Geisenheim",
    "city_ascii": "Geisenheim",
    "lat": 49.9831,
    "lng": 7.9656,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 11704,
    "id": 1276461244
  },
  {
    "city": "Olivenza",
    "city_ascii": "Olivenza",
    "lat": 38.6857,
    "lng": -7.1007,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Extremadura",
    "capital": "",
    "population": 11963,
    "id": 1724189870
  },
  {
    "city": "Montrose",
    "city_ascii": "Montrose",
    "lat": 56.708,
    "lng": -2.467,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Angus",
    "capital": "",
    "population": 11955,
    "id": 1826865974
  },
  {
    "city": "Diamond Springs",
    "city_ascii": "Diamond Springs",
    "lat": 38.692,
    "lng": -120.8391,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 11989,
    "id": 1840018829
  },
  {
    "city": "Abergele",
    "city_ascii": "Abergele",
    "lat": 53.28,
    "lng": -3.58,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Conwy",
    "capital": "",
    "population": 10577,
    "id": 1826468444
  },
  {
    "city": "Moncks Corner",
    "city_ascii": "Moncks Corner",
    "lat": 33.1631,
    "lng": -80.0135,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 11986,
    "id": 1840016863
  },
  {
    "city": "Minabe",
    "city_ascii": "Minabe",
    "lat": 33.7725,
    "lng": 135.3217,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Wakayama",
    "capital": "",
    "population": 11886,
    "id": 1392208953
  },
  {
    "city": "Killarney",
    "city_ascii": "Killarney",
    "lat": 52.0588,
    "lng": -9.5072,
    "country": "Ireland",
    "iso2": "IE",
    "iso3": "IRL",
    "admin_name": "Kerry",
    "capital": "",
    "population": 11902,
    "id": 1372916792
  },
  {
    "city": "Dinkelsbühl",
    "city_ascii": "Dinkelsbuhl",
    "lat": 49.0708,
    "lng": 10.3194,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 11825,
    "id": 1276514661
  },
  {
    "city": "Carnot-Moon",
    "city_ascii": "Carnot-Moon",
    "lat": 40.5187,
    "lng": -80.2178,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 11982,
    "id": 1840073606
  },
  {
    "city": "Santoña",
    "city_ascii": "Santona",
    "lat": 43.4414,
    "lng": -3.4575,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Cantabria",
    "capital": "",
    "population": 11024,
    "id": 1724291294
  },
  {
    "city": "Bátonyterenye",
    "city_ascii": "Batonyterenye",
    "lat": 47.9892,
    "lng": 19.8286,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Nógrád",
    "capital": "minor",
    "population": 11829,
    "id": 1348134134
  },
  {
    "city": "Newton",
    "city_ascii": "Newton",
    "lat": 41.0534,
    "lng": -74.7527,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 11974,
    "id": 1840000786
  },
  {
    "city": "Garden City",
    "city_ascii": "Garden City",
    "lat": 43.6526,
    "lng": -116.2743,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Idaho",
    "capital": "",
    "population": 11969,
    "id": 1840020045
  },
  {
    "city": "Somersworth",
    "city_ascii": "Somersworth",
    "lat": 43.2534,
    "lng": -70.8856,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Hampshire",
    "capital": "",
    "population": 11968,
    "id": 1840002810
  },
  {
    "city": "Diez",
    "city_ascii": "Diez",
    "lat": 50.3708,
    "lng": 8.0158,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Rhineland-Palatinate",
    "capital": "",
    "population": 11074,
    "id": 1276836214
  },
  {
    "city": "Norton",
    "city_ascii": "Norton",
    "lat": 41.0294,
    "lng": -81.6461,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 11966,
    "id": 1840000800
  },
  {
    "city": "Bad Dürrenberg",
    "city_ascii": "Bad Durrenberg",
    "lat": 51.2955,
    "lng": 12.0658,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony-Anhalt",
    "capital": "",
    "population": 11643,
    "id": 1276847644
  },
  {
    "city": "Ellwood City",
    "city_ascii": "Ellwood City",
    "lat": 40.8619,
    "lng": -80.283,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 11962,
    "id": 1840000937
  },
  {
    "city": "Glenwood Springs",
    "city_ascii": "Glenwood Springs",
    "lat": 39.5455,
    "lng": -107.3346,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 11962,
    "id": 1840020184
  },
  {
    "city": "Great Wyrley",
    "city_ascii": "Great Wyrley",
    "lat": 52.6593,
    "lng": -2.0102,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Staffordshire",
    "capital": "",
    "population": 11060,
    "id": 1826969267
  },
  {
    "city": "Greensburg",
    "city_ascii": "Greensburg",
    "lat": 39.3515,
    "lng": -85.5024,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 11960,
    "id": 1840014567
  },
  {
    "city": "Pavlikeni",
    "city_ascii": "Pavlikeni",
    "lat": 43.2367,
    "lng": 25.3092,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Veliko Tarnovo",
    "capital": "",
    "population": 11921,
    "id": 1100655871
  },
  {
    "city": "Negreşti-Oaş",
    "city_ascii": "Negresti-Oas",
    "lat": 47.8694,
    "lng": 23.4242,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Satu Mare",
    "capital": "",
    "population": 11867,
    "id": 1642109083
  },
  {
    "city": "Macclenny",
    "city_ascii": "Macclenny",
    "lat": 30.281,
    "lng": -82.1252,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 11958,
    "id": 1840015921
  },
  {
    "city": "East Grand Rapids",
    "city_ascii": "East Grand Rapids",
    "lat": 42.9464,
    "lng": -85.6088,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 11956,
    "id": 1840002927
  },
  {
    "city": "Drolshagen",
    "city_ascii": "Drolshagen",
    "lat": 51.0333,
    "lng": 7.7667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 11779,
    "id": 1276163887
  },
  {
    "city": "Newberry",
    "city_ascii": "Newberry",
    "lat": 34.2812,
    "lng": -81.601,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 11954,
    "id": 1840014694
  },
  {
    "city": "Mastic Beach",
    "city_ascii": "Mastic Beach",
    "lat": 40.7679,
    "lng": -72.8375,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 11953,
    "id": 1840005089
  },
  {
    "city": "Chuquicamata",
    "city_ascii": "Chuquicamata",
    "lat": -22.32,
    "lng": -68.93,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Antofagasta",
    "capital": "",
    "population": 11941,
    "id": 1152468996
  },
  {
    "city": "Çüngüş",
    "city_ascii": "Cungus",
    "lat": 38.2122,
    "lng": 39.2884,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Diyarbakır",
    "capital": "minor",
    "population": 11927,
    "id": 1792972356
  },
  {
    "city": "Myjava",
    "city_ascii": "Myjava",
    "lat": 48.7578,
    "lng": 17.5686,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Nitriansky",
    "capital": "minor",
    "population": 11708,
    "id": 1703306179
  },
  {
    "city": "Sandy",
    "city_ascii": "Sandy",
    "lat": 52.131,
    "lng": -0.297,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Central Bedfordshire",
    "capital": "",
    "population": 11657,
    "id": 1826630573
  },
  {
    "city": "Lansing",
    "city_ascii": "Lansing",
    "lat": 39.2428,
    "lng": -94.8971,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kansas",
    "capital": "",
    "population": 11949,
    "id": 1840001590
  },
  {
    "city": "Nowe Miasto Lubawskie",
    "city_ascii": "Nowe Miasto Lubawskie",
    "lat": 53.4167,
    "lng": 19.5833,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Warmińsko-Mazurskie",
    "capital": "minor",
    "population": 10997,
    "id": 1616871039
  },
  {
    "city": "Norwalk",
    "city_ascii": "Norwalk",
    "lat": 41.4895,
    "lng": -93.6913,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Iowa",
    "capital": "",
    "population": 11938,
    "id": 1840008213
  },
  {
    "city": "Nambour",
    "city_ascii": "Nambour",
    "lat": -26.6269,
    "lng": 152.9592,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Queensland",
    "capital": "",
    "population": 11187,
    "id": 1036804920
  },
  {
    "city": "Fairfax Station",
    "city_ascii": "Fairfax Station",
    "lat": 38.7942,
    "lng": -77.3358,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 11934,
    "id": 1840024566
  },
  {
    "city": "Qazmalar",
    "city_ascii": "Qazmalar",
    "lat": 40.9814,
    "lng": 47.8458,
    "country": "Azerbaijan",
    "iso2": "AZ",
    "iso3": "AZE",
    "admin_name": "Qəbələ",
    "capital": "",
    "population": 11867,
    "id": 1031242200
  },
  {
    "city": "Belp",
    "city_ascii": "Belp",
    "lat": 46.8914,
    "lng": 7.4972,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Bern",
    "capital": "",
    "population": 11439,
    "id": 1756855405
  },
  {
    "city": "West Deer",
    "city_ascii": "West Deer",
    "lat": 40.635,
    "lng": -79.8693,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 11929,
    "id": 1840147889
  },
  {
    "city": "Pozharan",
    "city_ascii": "Pozharan",
    "lat": 42.3648,
    "lng": 21.3372,
    "country": "Kosovo",
    "iso2": "XK",
    "iso3": "XKS",
    "admin_name": "Viti",
    "capital": "",
    "population": 11749,
    "id": 1901243584
  },
  {
    "city": "Robinson",
    "city_ascii": "Robinson",
    "lat": 31.4501,
    "lng": -97.1201,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 11926,
    "id": 1840020813
  },
  {
    "city": "Pilis",
    "city_ascii": "Pilis",
    "lat": 47.2858,
    "lng": 19.5469,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Pest",
    "capital": "",
    "population": 11678,
    "id": 1348981551
  },
  {
    "city": "Patterson",
    "city_ascii": "Patterson",
    "lat": 41.4849,
    "lng": -73.5921,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 11922,
    "id": 1840058386
  },
  {
    "city": "Portales",
    "city_ascii": "Portales",
    "lat": 34.1753,
    "lng": -103.3565,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Mexico",
    "capital": "",
    "population": 11921,
    "id": 1840020529
  },
  {
    "city": "Holešov",
    "city_ascii": "Holesov",
    "lat": 49.3333,
    "lng": 17.5783,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Zlínský Kraj",
    "capital": "",
    "population": 11579,
    "id": 1203380921
  },
  {
    "city": "Valencia West",
    "city_ascii": "Valencia West",
    "lat": 32.1355,
    "lng": -111.1123,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 11919,
    "id": 1840074666
  },
  {
    "city": "Miesbach",
    "city_ascii": "Miesbach",
    "lat": 47.789,
    "lng": 11.8338,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "minor",
    "population": 11562,
    "id": 1276609193
  },
  {
    "city": "Amelia",
    "city_ascii": "Amelia",
    "lat": 42.5535,
    "lng": 12.4168,
    "country": "Italy",
    "iso2": "IT",
    "iso3": "ITA",
    "admin_name": "Umbria",
    "capital": "",
    "population": 11828,
    "id": 1380920464
  },
  {
    "city": "Bordentown",
    "city_ascii": "Bordentown",
    "lat": 40.142,
    "lng": -74.7098,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 11917,
    "id": 1840001476
  },
  {
    "city": "Downham Market",
    "city_ascii": "Downham Market",
    "lat": 52.6,
    "lng": 0.39,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Norfolk",
    "capital": "",
    "population": 9994,
    "id": 1826885065
  },
  {
    "city": "Meghraj",
    "city_ascii": "Meghraj",
    "lat": 23.5,
    "lng": 73.5,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Gujarāt",
    "capital": "",
    "population": 11363,
    "id": 1356158185
  },
  {
    "city": "Wellington North",
    "city_ascii": "Wellington North",
    "lat": 43.9,
    "lng": -80.57,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 11914,
    "id": 1124001997
  },
  {
    "city": "Spencer",
    "city_ascii": "Spencer",
    "lat": 42.2471,
    "lng": -71.9919,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 11913,
    "id": 1840053607
  },
  {
    "city": "St. Andrews",
    "city_ascii": "St. Andrews",
    "lat": 50.27,
    "lng": -96.9747,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Manitoba",
    "capital": "",
    "population": 11913,
    "id": 1124001672
  },
  {
    "city": "Veurne",
    "city_ascii": "Veurne",
    "lat": 51.0722,
    "lng": 2.6622,
    "country": "Belgium",
    "iso2": "BE",
    "iso3": "BEL",
    "admin_name": "Flanders",
    "capital": "minor",
    "population": 11790,
    "id": 1056727573
  },
  {
    "city": "Torton",
    "city_ascii": "Torton",
    "lat": 52.4522,
    "lng": -2.1606,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Worcestershire",
    "capital": "",
    "population": 11841,
    "id": 1826331260
  },
  {
    "city": "Port Washington",
    "city_ascii": "Port Washington",
    "lat": 43.3847,
    "lng": -87.8852,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 11911,
    "id": 1840002826
  },
  {
    "city": "Somerville",
    "city_ascii": "Somerville",
    "lat": -38.226,
    "lng": 145.177,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Victoria",
    "capital": "",
    "population": 11336,
    "id": 1036275983
  },
  {
    "city": "Wootton",
    "city_ascii": "Wootton",
    "lat": 52.2007,
    "lng": -0.8857,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Northamptonshire",
    "capital": "",
    "population": 11180,
    "id": 1826816584
  },
  {
    "city": "Upper",
    "city_ascii": "Upper",
    "lat": 39.2563,
    "lng": -74.727,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 11909,
    "id": 1840081578
  },
  {
    "city": "Maxhütte-Haidhof",
    "city_ascii": "Maxhutte-Haidhof",
    "lat": 49.2,
    "lng": 12.1,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 11575,
    "id": 1276703973
  },
  {
    "city": "Arkadak",
    "city_ascii": "Arkadak",
    "lat": 51.9333,
    "lng": 43.5,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Saratovskaya Oblast’",
    "capital": "",
    "population": 11895,
    "id": 1643300892
  },
  {
    "city": "Green River",
    "city_ascii": "Green River",
    "lat": 41.5124,
    "lng": -109.4708,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wyoming",
    "capital": "",
    "population": 11906,
    "id": 1840020100
  },
  {
    "city": "Tremonton",
    "city_ascii": "Tremonton",
    "lat": 41.7187,
    "lng": -112.1891,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Utah",
    "capital": "",
    "population": 11905,
    "id": 1840021326
  },
  {
    "city": "Ibirá",
    "city_ascii": "Ibira",
    "lat": -21.08,
    "lng": -49.2408,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 11861,
    "id": 1076713231
  },
  {
    "city": "Osterhofen",
    "city_ascii": "Osterhofen",
    "lat": 48.7,
    "lng": 13.0167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 11798,
    "id": 1276874172
  },
  {
    "city": "Carleton Place",
    "city_ascii": "Carleton Place",
    "lat": 45.1333,
    "lng": -76.1333,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 11901,
    "id": 1124676010
  },
  {
    "city": "Vendas Novas",
    "city_ascii": "Vendas Novas",
    "lat": 38.6833,
    "lng": -8.45,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Évora",
    "capital": "minor",
    "population": 11846,
    "id": 1620891430
  },
  {
    "city": "Laichingen",
    "city_ascii": "Laichingen",
    "lat": 48.4897,
    "lng": 9.6861,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 11731,
    "id": 1276291281
  },
  {
    "city": "Accokeek",
    "city_ascii": "Accokeek",
    "lat": 38.6745,
    "lng": -77.0023,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 11895,
    "id": 1840005943
  },
  {
    "city": "Ivybridge",
    "city_ascii": "Ivybridge",
    "lat": 50.389,
    "lng": -3.921,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Devon",
    "capital": "",
    "population": 11851,
    "id": 1826566747
  },
  {
    "city": "Carahue",
    "city_ascii": "Carahue",
    "lat": -38.7095,
    "lng": -73.17,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Araucanía",
    "capital": "",
    "population": 11875,
    "id": 1152919462
  },
  {
    "city": "Cottage Grove",
    "city_ascii": "Cottage Grove",
    "lat": 43.7961,
    "lng": -123.0573,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 11892,
    "id": 1840018624
  },
  {
    "city": "Stafford",
    "city_ascii": "Stafford",
    "lat": 41.9876,
    "lng": -72.3122,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 11890,
    "id": 1840033668
  },
  {
    "city": "Büdelsdorf",
    "city_ascii": "Budelsdorf",
    "lat": 54.3167,
    "lng": 9.6833,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Schleswig-Holstein",
    "capital": "",
    "population": 10297,
    "id": 1276054085
  },
  {
    "city": "Yuasa",
    "city_ascii": "Yuasa",
    "lat": 34.0331,
    "lng": 135.1786,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Wakayama",
    "capital": "",
    "population": 11340,
    "id": 1392420914
  },
  {
    "city": "Sárospatak",
    "city_ascii": "Sarospatak",
    "lat": 48.3189,
    "lng": 21.5661,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Borsod-Abaúj-Zemplén",
    "capital": "minor",
    "population": 11768,
    "id": 1348369624
  },
  {
    "city": "Ukrainsk",
    "city_ascii": "Ukrainsk",
    "lat": 48.1,
    "lng": 37.3667,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Donets’ka Oblast’",
    "capital": "",
    "population": 11824,
    "id": 1804349001
  },
  {
    "city": "Weatherford",
    "city_ascii": "Weatherford",
    "lat": 35.5384,
    "lng": -98.6872,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oklahoma",
    "capital": "",
    "population": 11883,
    "id": 1840021725
  },
  {
    "city": "Novaya Lyalya",
    "city_ascii": "Novaya Lyalya",
    "lat": 59.05,
    "lng": 60.6,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sverdlovskaya Oblast’",
    "capital": "",
    "population": 11879,
    "id": 1643222721
  },
  {
    "city": "Bopfingen",
    "city_ascii": "Bopfingen",
    "lat": 48.8569,
    "lng": 10.3522,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 11727,
    "id": 1276407317
  },
  {
    "city": "Nacozari Viejo",
    "city_ascii": "Nacozari Viejo",
    "lat": 30.42,
    "lng": -109.65,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Sonora",
    "capital": "",
    "population": 11872,
    "id": 1484108383
  },
  {
    "city": "Alamedin",
    "city_ascii": "Alamedin",
    "lat": 42.89,
    "lng": 74.63,
    "country": "Kyrgyzstan",
    "iso2": "KG",
    "iso3": "KGZ",
    "admin_name": "Chüy",
    "capital": "",
    "population": 11773,
    "id": 1417392357
  },
  {
    "city": "Delavan",
    "city_ascii": "Delavan",
    "lat": 42.6281,
    "lng": -88.6324,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 11871,
    "id": 1840002483
  },
  {
    "city": "Abasolo",
    "city_ascii": "Abasolo",
    "lat": 24.0559,
    "lng": -98.3733,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Tamaulipas",
    "capital": "minor",
    "population": 11862,
    "id": 1484491384
  },
  {
    "city": "Sandown",
    "city_ascii": "Sandown",
    "lat": 50.6551,
    "lng": -1.1541,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Isle of Wight",
    "capital": "",
    "population": 11868,
    "id": 1826580279
  },
  {
    "city": "Kingston",
    "city_ascii": "Kingston",
    "lat": -42.9769,
    "lng": 147.3083,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Tasmania",
    "capital": "",
    "population": 10409,
    "id": 1036297213
  },
  {
    "city": "Vineyard",
    "city_ascii": "Vineyard",
    "lat": 40.3059,
    "lng": -111.7545,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Utah",
    "capital": "",
    "population": 11866,
    "id": 1840022419
  },
  {
    "city": "Coswig",
    "city_ascii": "Coswig",
    "lat": 51.8833,
    "lng": 12.4333,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony-Anhalt",
    "capital": "",
    "population": 11824,
    "id": 1276417362
  },
  {
    "city": "North Lebanon",
    "city_ascii": "North Lebanon",
    "lat": 40.3668,
    "lng": -76.4215,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 11863,
    "id": 1840143428
  },
  {
    "city": "Mülheim-Kärlich",
    "city_ascii": "Mulheim-Karlich",
    "lat": 50.3869,
    "lng": 7.4953,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Rhineland-Palatinate",
    "capital": "",
    "population": 11177,
    "id": 1276940149
  },
  {
    "city": "Kuchinarai",
    "city_ascii": "Kuchinarai",
    "lat": 16.5318,
    "lng": 104.044,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Kalasin",
    "capital": "minor",
    "population": 11753,
    "id": 1764821226
  },
  {
    "city": "Isperih",
    "city_ascii": "Isperih",
    "lat": 43.7156,
    "lng": 26.8275,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Razgrad",
    "capital": "",
    "population": 11797,
    "id": 1100443393
  },
  {
    "city": "Richfield",
    "city_ascii": "Richfield",
    "lat": 43.2372,
    "lng": -88.2413,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 11854,
    "id": 1840002835
  },
  {
    "city": "Whistler",
    "city_ascii": "Whistler",
    "lat": 50.1208,
    "lng": -122.9544,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "British Columbia",
    "capital": "",
    "population": 11854,
    "id": 1124001562
  },
  {
    "city": "Otsego",
    "city_ascii": "Otsego",
    "lat": 42.4575,
    "lng": -85.6979,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 11852,
    "id": 1840003111
  },
  {
    "city": "Malvern",
    "city_ascii": "Malvern",
    "lat": 34.3734,
    "lng": -92.8205,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arkansas",
    "capital": "",
    "population": 11851,
    "id": 1840015563
  },
  {
    "city": "Cricova",
    "city_ascii": "Cricova",
    "lat": 47.1389,
    "lng": 28.8614,
    "country": "Moldova",
    "iso2": "MD",
    "iso3": "MDA",
    "admin_name": "Chişinău",
    "capital": "",
    "population": 10669,
    "id": 1498628577
  },
  {
    "city": "Monschau",
    "city_ascii": "Monschau",
    "lat": 50.55,
    "lng": 6.25,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 11726,
    "id": 1276023228
  },
  {
    "city": "Cadillac",
    "city_ascii": "Cadillac",
    "lat": 44.2494,
    "lng": -85.4163,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 11850,
    "id": 1840002512
  },
  {
    "city": "Lexington Park",
    "city_ascii": "Lexington Park",
    "lat": 38.2543,
    "lng": -76.4415,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 11848,
    "id": 1840006231
  },
  {
    "city": "Malargüe",
    "city_ascii": "Malargue",
    "lat": -35.4745,
    "lng": -69.5853,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Mendoza",
    "capital": "minor",
    "population": 11847,
    "id": 1032824937
  },
  {
    "city": "Santiago",
    "city_ascii": "Santiago",
    "lat": 9.8291,
    "lng": -84.3044,
    "country": "Costa Rica",
    "iso2": "CR",
    "iso3": "CRI",
    "admin_name": "San José",
    "capital": "",
    "population": 11512,
    "id": 1188768291
  },
  {
    "city": "Healdsburg",
    "city_ascii": "Healdsburg",
    "lat": 38.6229,
    "lng": -122.8651,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 11845,
    "id": 1840020242
  },
  {
    "city": "Lake Station",
    "city_ascii": "Lake Station",
    "lat": 41.5729,
    "lng": -87.2599,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 11845,
    "id": 1840008195
  },
  {
    "city": "Brighton",
    "city_ascii": "Brighton",
    "lat": 44.1222,
    "lng": -77.7642,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 11844,
    "id": 1124672085
  },
  {
    "city": "Tolcayuca",
    "city_ascii": "Tolcayuca",
    "lat": 19.95,
    "lng": -98.9167,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Morelos",
    "capital": "minor",
    "population": 11746,
    "id": 1484403443
  },
  {
    "city": "Sandy",
    "city_ascii": "Sandy",
    "lat": 45.3986,
    "lng": -122.2692,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 11843,
    "id": 1840021217
  },
  {
    "city": "Bocaina",
    "city_ascii": "Bocaina",
    "lat": -22.1361,
    "lng": -48.5181,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 11810,
    "id": 1076989834
  },
  {
    "city": "Casino",
    "city_ascii": "Casino",
    "lat": -28.8483,
    "lng": 153.0511,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 10914,
    "id": 1036855900
  },
  {
    "city": "Scorniceşti",
    "city_ascii": "Scornicesti",
    "lat": 44.57,
    "lng": 24.55,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Olt",
    "capital": "",
    "population": 11766,
    "id": 1642000053
  },
  {
    "city": "Robertsville",
    "city_ascii": "Robertsville",
    "lat": 40.3395,
    "lng": -74.2939,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 11840,
    "id": 1840026509
  },
  {
    "city": "Red Bank",
    "city_ascii": "Red Bank",
    "lat": 35.1117,
    "lng": -85.2961,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 11840,
    "id": 1840014571
  },
  {
    "city": "Brown Deer",
    "city_ascii": "Brown Deer",
    "lat": 43.1743,
    "lng": -87.975,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 11839,
    "id": 1840003041
  },
  {
    "city": "Rapho",
    "city_ascii": "Rapho",
    "lat": 40.1576,
    "lng": -76.458,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 11839,
    "id": 1840142495
  },
  {
    "city": "Crestwood",
    "city_ascii": "Crestwood",
    "lat": 38.5569,
    "lng": -90.3782,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 11834,
    "id": 1840007442
  },
  {
    "city": "Grants",
    "city_ascii": "Grants",
    "lat": 35.1538,
    "lng": -107.8335,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Mexico",
    "capital": "",
    "population": 11833,
    "id": 1840020452
  },
  {
    "city": "Opatija",
    "city_ascii": "Opatija",
    "lat": 45.3347,
    "lng": 14.3069,
    "country": "Croatia",
    "iso2": "HR",
    "iso3": "HRV",
    "admin_name": "Primorsko-Goranska Županija",
    "capital": "minor",
    "population": 11659,
    "id": 1191982660
  },
  {
    "city": "Moldava nad Bodvou",
    "city_ascii": "Moldava nad Bodvou",
    "lat": 48.6156,
    "lng": 20.9992,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Košický",
    "capital": "",
    "population": 11260,
    "id": 1703800646
  },
  {
    "city": "Vlašim",
    "city_ascii": "Vlasim",
    "lat": 49.7064,
    "lng": 14.8989,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Středočeský Kraj",
    "capital": "",
    "population": 11550,
    "id": 1203658512
  },
  {
    "city": "Valley Falls",
    "city_ascii": "Valley Falls",
    "lat": 41.9234,
    "lng": -71.3923,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Rhode Island",
    "capital": "",
    "population": 11828,
    "id": 1840003295
  },
  {
    "city": "Dolo Bay",
    "city_ascii": "Dolo Bay",
    "lat": 4.1833,
    "lng": 42.0833,
    "country": "Ethiopia",
    "iso2": "ET",
    "iso3": "ETH",
    "admin_name": "Sumalē",
    "capital": "",
    "population": 11810,
    "id": 1231539120
  },
  {
    "city": "Neuhausen am Rheinfall",
    "city_ascii": "Neuhausen am Rheinfall",
    "lat": 47.6831,
    "lng": 8.6167,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Schaffhausen",
    "capital": "",
    "population": 10510,
    "id": 1756815481
  },
  {
    "city": "Chaplygin",
    "city_ascii": "Chaplygin",
    "lat": 53.2417,
    "lng": 39.9667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Lipetskaya Oblast’",
    "capital": "",
    "population": 11802,
    "id": 1643946136
  },
  {
    "city": "Prudhoe",
    "city_ascii": "Prudhoe",
    "lat": 54.961,
    "lng": -1.849,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Northumberland",
    "capital": "",
    "population": 11675,
    "id": 1826202112
  },
  {
    "city": "Berkley",
    "city_ascii": "Berkley",
    "lat": 39.8045,
    "lng": -105.0281,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 11822,
    "id": 1840028561
  },
  {
    "city": "Malibu",
    "city_ascii": "Malibu",
    "lat": 34.0368,
    "lng": -118.7845,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 11820,
    "id": 1840028310
  },
  {
    "city": "Smyrna",
    "city_ascii": "Smyrna",
    "lat": 39.2936,
    "lng": -75.6083,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Delaware",
    "capital": "",
    "population": 11813,
    "id": 1840005820
  },
  {
    "city": "Mudgee",
    "city_ascii": "Mudgee",
    "lat": -32.6125,
    "lng": 149.5872,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 10923,
    "id": 1036213564
  },
  {
    "city": "Óbidos",
    "city_ascii": "Obidos",
    "lat": 39.3585,
    "lng": -9.176,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Leiria",
    "capital": "minor",
    "population": 11689,
    "id": 1620276354
  },
  {
    "city": "Mareeba",
    "city_ascii": "Mareeba",
    "lat": -16.9833,
    "lng": 145.4167,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Queensland",
    "capital": "",
    "population": 11079,
    "id": 1036797275
  },
  {
    "city": "Haradok",
    "city_ascii": "Haradok",
    "lat": 55.4667,
    "lng": 29.9833,
    "country": "Belarus",
    "iso2": "BY",
    "iso3": "BLR",
    "admin_name": "Vitsyebskaya Voblasts’",
    "capital": "minor",
    "population": 11800,
    "id": 1112131300
  },
  {
    "city": "Baranivka",
    "city_ascii": "Baranivka",
    "lat": 50.3,
    "lng": 27.6667,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Zhytomyrs’ka Oblast’",
    "capital": "minor",
    "population": 11765,
    "id": 1804259330
  },
  {
    "city": "Dranesville",
    "city_ascii": "Dranesville",
    "lat": 38.9955,
    "lng": -77.3693,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 11802,
    "id": 1840024565
  },
  {
    "city": "Market Drayton",
    "city_ascii": "Market Drayton",
    "lat": 52.9044,
    "lng": -2.4848,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Staffordshire",
    "capital": "",
    "population": 11773,
    "id": 1826166610
  },
  {
    "city": "Labin",
    "city_ascii": "Labin",
    "lat": 45.0833,
    "lng": 14.1167,
    "country": "Croatia",
    "iso2": "HR",
    "iso3": "HRV",
    "admin_name": "Istarska Županija",
    "capital": "minor",
    "population": 11642,
    "id": 1191000718
  },
  {
    "city": "Bucyrus",
    "city_ascii": "Bucyrus",
    "lat": 40.8054,
    "lng": -82.9719,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 11800,
    "id": 1840007170
  },
  {
    "city": "Cafayate",
    "city_ascii": "Cafayate",
    "lat": -26.07,
    "lng": -65.98,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Salta",
    "capital": "minor",
    "population": 11785,
    "id": 1032961594
  },
  {
    "city": "Phon",
    "city_ascii": "Phon",
    "lat": 15.816,
    "lng": 102.5998,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Khon Kaen",
    "capital": "minor",
    "population": 11593,
    "id": 1764718749
  },
  {
    "city": "San Pedro",
    "city_ascii": "San Pedro",
    "lat": 17.9214,
    "lng": -87.9611,
    "country": "Belize",
    "iso2": "BZ",
    "iso3": "BLZ",
    "admin_name": "Belize",
    "capital": "",
    "population": 11767,
    "id": 1084929106
  },
  {
    "city": "Târgu Lăpuş",
    "city_ascii": "Targu Lapus",
    "lat": 47.4525,
    "lng": 23.8631,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Maramureş",
    "capital": "",
    "population": 11744,
    "id": 1642400561
  },
  {
    "city": "Hauzenberg",
    "city_ascii": "Hauzenberg",
    "lat": 48.6517,
    "lng": 13.6236,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 11649,
    "id": 1276386776
  },
  {
    "city": "Urbana",
    "city_ascii": "Urbana",
    "lat": 39.3237,
    "lng": -77.3411,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 11788,
    "id": 1840024455
  },
  {
    "city": "Tiny",
    "city_ascii": "Tiny",
    "lat": 44.6833,
    "lng": -79.95,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 11787,
    "id": 1124000103
  },
  {
    "city": "Kankaanpää",
    "city_ascii": "Kankaanpaa",
    "lat": 61.8042,
    "lng": 22.3944,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Satakunta",
    "capital": "minor",
    "population": 11769,
    "id": 1246054276
  },
  {
    "city": "Frenštát pod Radhoštěm",
    "city_ascii": "Frenstat pod Radhostem",
    "lat": 49.5483,
    "lng": 18.2108,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Moravskoslezský Kraj",
    "capital": "",
    "population": 10837,
    "id": 1203465419
  },
  {
    "city": "Wakefield",
    "city_ascii": "Wakefield",
    "lat": 38.823,
    "lng": -77.2407,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 11782,
    "id": 1840143806
  },
  {
    "city": "Plymouth",
    "city_ascii": "Plymouth",
    "lat": 41.6642,
    "lng": -73.0263,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 11782,
    "id": 1840004913
  },
  {
    "city": "Velké Meziříčí",
    "city_ascii": "Velke Mezirici",
    "lat": 49.3553,
    "lng": 16.0123,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Vysočina",
    "capital": "",
    "population": 11498,
    "id": 1203943102
  },
  {
    "city": "Walker Mill",
    "city_ascii": "Walker Mill",
    "lat": 38.8754,
    "lng": -76.8862,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 11780,
    "id": 1840031499
  },
  {
    "city": "Tring",
    "city_ascii": "Tring",
    "lat": 51.7962,
    "lng": -0.6592,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hertfordshire",
    "capital": "",
    "population": 11730,
    "id": 1826144643
  },
  {
    "city": "Tomball",
    "city_ascii": "Tomball",
    "lat": 30.0951,
    "lng": -95.6194,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 11778,
    "id": 1840022203
  },
  {
    "city": "Gänserndorf",
    "city_ascii": "Ganserndorf",
    "lat": 48.3406,
    "lng": 16.7175,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Niederösterreich",
    "capital": "minor",
    "population": 11404,
    "id": 1040375279
  },
  {
    "city": "Lieksa",
    "city_ascii": "Lieksa",
    "lat": 63.3167,
    "lng": 30.0167,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Pohjois-Karjala",
    "capital": "minor",
    "population": 11772,
    "id": 1246128959
  },
  {
    "city": "Dolinsk",
    "city_ascii": "Dolinsk",
    "lat": 47.3167,
    "lng": 142.8,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sakhalinskaya Oblast’",
    "capital": "",
    "population": 11765,
    "id": 1643206913
  },
  {
    "city": "Padiham",
    "city_ascii": "Padiham",
    "lat": 53.797,
    "lng": -2.311,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Lancashire",
    "capital": "",
    "population": 10098,
    "id": 1826532234
  },
  {
    "city": "Florida City",
    "city_ascii": "Florida City",
    "lat": 25.4418,
    "lng": -80.4685,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 11771,
    "id": 1840014250
  },
  {
    "city": "Wanaque",
    "city_ascii": "Wanaque",
    "lat": 41.044,
    "lng": -74.29,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 11762,
    "id": 1840000840
  },
  {
    "city": "Winton",
    "city_ascii": "Winton",
    "lat": 37.3854,
    "lng": -120.6173,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 11761,
    "id": 1840018933
  },
  {
    "city": "Arizona City",
    "city_ascii": "Arizona City",
    "lat": 32.7506,
    "lng": -111.6707,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 11761,
    "id": 1840018030
  },
  {
    "city": "Englefield Green",
    "city_ascii": "Englefield Green",
    "lat": 51.4301,
    "lng": -0.5699,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Surrey",
    "capital": "",
    "population": 10607,
    "id": 1826887313
  },
  {
    "city": "Tucson Estates",
    "city_ascii": "Tucson Estates",
    "lat": 32.1792,
    "lng": -111.1254,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 11758,
    "id": 1840027929
  },
  {
    "city": "Hollabrunn",
    "city_ascii": "Hollabrunn",
    "lat": 48.5667,
    "lng": 16.0833,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Niederösterreich",
    "capital": "minor",
    "population": 11681,
    "id": 1040573324
  },
  {
    "city": "Scotts Valley",
    "city_ascii": "Scotts Valley",
    "lat": 37.0555,
    "lng": -122.0118,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 11757,
    "id": 1840021580
  },
  {
    "city": "Jarābulus",
    "city_ascii": "Jarabulus",
    "lat": 36.8175,
    "lng": 38.0111,
    "country": "Syria",
    "iso2": "SY",
    "iso3": "SYR",
    "admin_name": "Ḩalab",
    "capital": "minor",
    "population": 11570,
    "id": 1760404681
  },
  {
    "city": "Robstown",
    "city_ascii": "Robstown",
    "lat": 27.7886,
    "lng": -97.6685,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 11755,
    "id": 1840021010
  },
  {
    "city": "Miller Place",
    "city_ascii": "Miller Place",
    "lat": 40.9374,
    "lng": -72.9864,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 11755,
    "id": 1840005046
  },
  {
    "city": "Mezdra",
    "city_ascii": "Mezdra",
    "lat": 43.1425,
    "lng": 23.7142,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Vratsa",
    "capital": "",
    "population": 11719,
    "id": 1100715931
  },
  {
    "city": "The Pinery",
    "city_ascii": "The Pinery",
    "lat": 39.4462,
    "lng": -104.7591,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 11754,
    "id": 1840028583
  },
  {
    "city": "Georgetown",
    "city_ascii": "Georgetown",
    "lat": 33.3595,
    "lng": -79.2958,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 11754,
    "id": 1840013743
  },
  {
    "city": "Sunninghill",
    "city_ascii": "Sunninghill",
    "lat": 51.405,
    "lng": -0.656,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Windsor and Maidenhead",
    "capital": "",
    "population": 11603,
    "id": 1826713508
  },
  {
    "city": "Krujë",
    "city_ascii": "Kruje",
    "lat": 41.5178,
    "lng": 19.7978,
    "country": "Albania",
    "iso2": "AL",
    "iso3": "ALB",
    "admin_name": "Durrës",
    "capital": "",
    "population": 11721,
    "id": 1008696208
  },
  {
    "city": "Rurrenabaque",
    "city_ascii": "Rurrenabaque",
    "lat": -14.4422,
    "lng": -67.5283,
    "country": "Bolivia",
    "iso2": "BO",
    "iso3": "BOL",
    "admin_name": "El Beni",
    "capital": "",
    "population": 11749,
    "id": 1068557134
  },
  {
    "city": "Aesch",
    "city_ascii": "Aesch",
    "lat": 47.4694,
    "lng": 7.5942,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Basel-Landschaft",
    "capital": "",
    "population": 10352,
    "id": 1756884116
  },
  {
    "city": "Choppington",
    "city_ascii": "Choppington",
    "lat": 55.145,
    "lng": -1.601,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Northumberland",
    "capital": "",
    "population": 9787,
    "id": 1826999025
  },
  {
    "city": "Wolmirstedt",
    "city_ascii": "Wolmirstedt",
    "lat": 52.25,
    "lng": 11.6167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony-Anhalt",
    "capital": "",
    "population": 11536,
    "id": 1276525433
  },
  {
    "city": "Norfolk",
    "city_ascii": "Norfolk",
    "lat": 42.1163,
    "lng": -71.3295,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 11748,
    "id": 1840053554
  },
  {
    "city": "Ichinohe",
    "city_ascii": "Ichinohe",
    "lat": 40.2131,
    "lng": 141.2953,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Iwate",
    "capital": "",
    "population": 11708,
    "id": 1392741013
  },
  {
    "city": "Ross on Wye",
    "city_ascii": "Ross on Wye",
    "lat": 51.914,
    "lng": -2.587,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Herefordshire",
    "capital": "",
    "population": 10700,
    "id": 1826303681
  },
  {
    "city": "East Renton Highlands",
    "city_ascii": "East Renton Highlands",
    "lat": 47.4718,
    "lng": -122.0854,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 11745,
    "id": 1840037866
  },
  {
    "city": "Kuriyama",
    "city_ascii": "Kuriyama",
    "lat": 43.0564,
    "lng": 141.7842,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hokkaidō",
    "capital": "",
    "population": 11687,
    "id": 1392201076
  },
  {
    "city": "North Tidworth",
    "city_ascii": "North Tidworth",
    "lat": 51.237,
    "lng": -1.664,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Wiltshire",
    "capital": "",
    "population": 10621,
    "id": 1826577257
  },
  {
    "city": "Upper Montclair",
    "city_ascii": "Upper Montclair",
    "lat": 40.8428,
    "lng": -74.2014,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 11744,
    "id": 1840024255
  },
  {
    "city": "Nanakuli",
    "city_ascii": "Nanakuli",
    "lat": 21.3892,
    "lng": -158.1445,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Hawaii",
    "capital": "",
    "population": 11742,
    "id": 1840029531
  },
  {
    "city": "Magdagachi",
    "city_ascii": "Magdagachi",
    "lat": 53.45,
    "lng": 125.8,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Amurskaya Oblast’",
    "capital": "",
    "population": 11739,
    "id": 1643413401
  },
  {
    "city": "Somerset",
    "city_ascii": "Somerset",
    "lat": 40.005,
    "lng": -79.0778,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 11738,
    "id": 1840003747
  },
  {
    "city": "Delhi",
    "city_ascii": "Delhi",
    "lat": 37.4306,
    "lng": -120.7759,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 11735,
    "id": 1840018930
  },
  {
    "city": "Nemyriv",
    "city_ascii": "Nemyriv",
    "lat": 48.9794,
    "lng": 28.8439,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Vinnyts’ka Oblast’",
    "capital": "minor",
    "population": 11697,
    "id": 1804059627
  },
  {
    "city": "Wrentham",
    "city_ascii": "Wrentham",
    "lat": 42.0513,
    "lng": -71.3552,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 11734,
    "id": 1840053562
  },
  {
    "city": "Río Colorado",
    "city_ascii": "Rio Colorado",
    "lat": -38.9908,
    "lng": -64.0958,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "La Pampa",
    "capital": "",
    "population": 11733,
    "id": 1032592194
  },
  {
    "city": "Lundazi",
    "city_ascii": "Lundazi",
    "lat": -12.2895,
    "lng": 33.17,
    "country": "Zambia",
    "iso2": "ZM",
    "iso3": "ZMB",
    "admin_name": "Eastern",
    "capital": "",
    "population": 11635,
    "id": 1894277247
  },
  {
    "city": "Port St. John",
    "city_ascii": "Port St. John",
    "lat": 28.4771,
    "lng": -80.7874,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 11729,
    "id": 1840014079
  },
  {
    "city": "Arlington",
    "city_ascii": "Arlington",
    "lat": 35.2594,
    "lng": -89.668,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 11728,
    "id": 1840015458
  },
  {
    "city": "Gräfenhainichen",
    "city_ascii": "Grafenhainichen",
    "lat": 51.7292,
    "lng": 12.4556,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony-Anhalt",
    "capital": "",
    "population": 11654,
    "id": 1276450109
  },
  {
    "city": "Cordele",
    "city_ascii": "Cordele",
    "lat": 31.9563,
    "lng": -83.7694,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 11719,
    "id": 1840014934
  },
  {
    "city": "Forestville",
    "city_ascii": "Forestville",
    "lat": 38.8518,
    "lng": -76.8708,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 11719,
    "id": 1840005942
  },
  {
    "city": "Elma",
    "city_ascii": "Elma",
    "lat": 42.8231,
    "lng": -78.6371,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 11718,
    "id": 1840058079
  },
  {
    "city": "Orchha",
    "city_ascii": "Orchha",
    "lat": 25.35,
    "lng": 78.64,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Madhya Pradesh",
    "capital": "",
    "population": 11511,
    "id": 1356593159
  },
  {
    "city": "Mendota",
    "city_ascii": "Mendota",
    "lat": 36.7555,
    "lng": -120.3776,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 11716,
    "id": 1840020321
  },
  {
    "city": "Grafton",
    "city_ascii": "Grafton",
    "lat": 43.3204,
    "lng": -87.948,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 11715,
    "id": 1840002823
  },
  {
    "city": "Glen Rock",
    "city_ascii": "Glen Rock",
    "lat": 40.9601,
    "lng": -74.1249,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 11707,
    "id": 1840003555
  },
  {
    "city": "Cocoa Beach",
    "city_ascii": "Cocoa Beach",
    "lat": 28.3326,
    "lng": -80.6274,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 11705,
    "id": 1840015093
  },
  {
    "city": "Prymors’k",
    "city_ascii": "Prymors'k",
    "lat": 46.7333,
    "lng": 36.3596,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Zaporiz’ka Oblast’",
    "capital": "minor",
    "population": 11679,
    "id": 1804278318
  },
  {
    "city": "Mena",
    "city_ascii": "Mena",
    "lat": 51.5167,
    "lng": 32.2167,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Chernihivs’ka Oblast’",
    "capital": "minor",
    "population": 11670,
    "id": 1804891578
  },
  {
    "city": "Billerbeck",
    "city_ascii": "Billerbeck",
    "lat": 51.9792,
    "lng": 7.295,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 11566,
    "id": 1276001817
  },
  {
    "city": "Griswold",
    "city_ascii": "Griswold",
    "lat": 41.5852,
    "lng": -71.9226,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 11693,
    "id": 1840045021
  },
  {
    "city": "Nyazepetrovsk",
    "city_ascii": "Nyazepetrovsk",
    "lat": 56.05,
    "lng": 59.6,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Chelyabinskaya Oblast’",
    "capital": "",
    "population": 11687,
    "id": 1643877254
  },
  {
    "city": "Athol",
    "city_ascii": "Athol",
    "lat": 42.5841,
    "lng": -72.2177,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 11691,
    "id": 1840053683
  },
  {
    "city": "Clute",
    "city_ascii": "Clute",
    "lat": 29.0256,
    "lng": -95.3975,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 11690,
    "id": 1840019669
  },
  {
    "city": "Gander",
    "city_ascii": "Gander",
    "lat": 48.9569,
    "lng": -54.6089,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Newfoundland and Labrador",
    "capital": "",
    "population": 11688,
    "id": 1124310517
  },
  {
    "city": "Waimea",
    "city_ascii": "Waimea",
    "lat": 20.0124,
    "lng": -155.6378,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Hawaii",
    "capital": "",
    "population": 11687,
    "id": 1840137122
  },
  {
    "city": "Bayou Blue",
    "city_ascii": "Bayou Blue",
    "lat": 29.6341,
    "lng": -90.6732,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 11682,
    "id": 1840039382
  },
  {
    "city": "Dudley",
    "city_ascii": "Dudley",
    "lat": 42.055,
    "lng": -71.9352,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 11682,
    "id": 1840053586
  },
  {
    "city": "Hinwil",
    "city_ascii": "Hinwil",
    "lat": 47.3033,
    "lng": 8.8444,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Zürich",
    "capital": "minor",
    "population": 11179,
    "id": 1756628529
  },
  {
    "city": "Southborough",
    "city_ascii": "Southborough",
    "lat": 51.1598,
    "lng": 0.2652,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Kent",
    "capital": "",
    "population": 11124,
    "id": 1826565398
  },
  {
    "city": "Winfield",
    "city_ascii": "Winfield",
    "lat": 37.274,
    "lng": -96.9499,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kansas",
    "capital": "",
    "population": 11679,
    "id": 1840001701
  },
  {
    "city": "Udachnyy",
    "city_ascii": "Udachnyy",
    "lat": 66.4,
    "lng": 112.3,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sakha (Yakutiya)",
    "capital": "",
    "population": 11676,
    "id": 1643138235
  },
  {
    "city": "Guaiçara",
    "city_ascii": "Guaicara",
    "lat": -21.6219,
    "lng": -49.7986,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 11633,
    "id": 1076186233
  },
  {
    "city": "Dingman",
    "city_ascii": "Dingman",
    "lat": 41.3226,
    "lng": -74.9264,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 11674,
    "id": 1840146119
  },
  {
    "city": "Iver",
    "city_ascii": "Iver",
    "lat": 51.521,
    "lng": -0.507,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Buckinghamshire",
    "capital": "",
    "population": 11119,
    "id": 1826636637
  },
  {
    "city": "Cascades",
    "city_ascii": "Cascades",
    "lat": 39.0464,
    "lng": -77.3874,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 11670,
    "id": 1840041712
  },
  {
    "city": "Deutschlandsberg",
    "city_ascii": "Deutschlandsberg",
    "lat": 46.8161,
    "lng": 15.215,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Steiermark",
    "capital": "minor",
    "population": 11604,
    "id": 1040677286
  },
  {
    "city": "Plano",
    "city_ascii": "Plano",
    "lat": 41.6757,
    "lng": -88.5294,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 11665,
    "id": 1840009258
  },
  {
    "city": "Port Morant",
    "city_ascii": "Port Morant",
    "lat": 17.891,
    "lng": -76.329,
    "country": "Jamaica",
    "iso2": "JM",
    "iso3": "JAM",
    "admin_name": "Saint Thomas",
    "capital": "",
    "population": 11536,
    "id": 1388362870
  },
  {
    "city": "Brock Hall",
    "city_ascii": "Brock Hall",
    "lat": 38.8617,
    "lng": -76.7549,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 11664,
    "id": 1840024541
  },
  {
    "city": "Huandacareo",
    "city_ascii": "Huandacareo",
    "lat": 19.9906,
    "lng": -101.275,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Michoacán de Ocampo",
    "capital": "minor",
    "population": 11592,
    "id": 1484155611
  },
  {
    "city": "Ban Sam Khwai Phueak",
    "city_ascii": "Ban Sam Khwai Phueak",
    "lat": 13.8283,
    "lng": 100.1041,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Nakhon Pathom",
    "capital": "",
    "population": 10837,
    "id": 1764661640
  },
  {
    "city": "Carver",
    "city_ascii": "Carver",
    "lat": 41.8739,
    "lng": -70.7563,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 11661,
    "id": 1840053565
  },
  {
    "city": "Royal Wootton Bassett",
    "city_ascii": "Royal Wootton Bassett",
    "lat": 51.533,
    "lng": -1.9,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Wiltshire",
    "capital": "",
    "population": 11385,
    "id": 1826578502
  },
  {
    "city": "Rothesay",
    "city_ascii": "Rothesay",
    "lat": 45.3831,
    "lng": -65.9969,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "New Brunswick",
    "capital": "",
    "population": 11659,
    "id": 1124211328
  },
  {
    "city": "Lewisburg",
    "city_ascii": "Lewisburg",
    "lat": 35.4494,
    "lng": -86.7897,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 11658,
    "id": 1840014544
  },
  {
    "city": "Schwaigern",
    "city_ascii": "Schwaigern",
    "lat": 49.1333,
    "lng": 9.05,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 11425,
    "id": 1276311065
  },
  {
    "city": "El Campo",
    "city_ascii": "El Campo",
    "lat": 29.1987,
    "lng": -96.2724,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 11655,
    "id": 1840020966
  },
  {
    "city": "Varzaneh",
    "city_ascii": "Varzaneh",
    "lat": 32.4194,
    "lng": 52.6481,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Eşfahān",
    "capital": "",
    "population": 11506,
    "id": 1364732883
  },
  {
    "city": "Putnam Valley",
    "city_ascii": "Putnam Valley",
    "lat": 41.3979,
    "lng": -73.8368,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 11654,
    "id": 1840058429
  },
  {
    "city": "Videle",
    "city_ascii": "Videle",
    "lat": 44.2833,
    "lng": 25.5333,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Teleorman",
    "capital": "",
    "population": 11508,
    "id": 1642959308
  },
  {
    "city": "Whitburn",
    "city_ascii": "Whitburn",
    "lat": 55.8621,
    "lng": -3.6872,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "West Lothian",
    "capital": "",
    "population": 10527,
    "id": 1826366350
  },
  {
    "city": "Mala Vyska",
    "city_ascii": "Mala Vyska",
    "lat": 48.65,
    "lng": 31.6333,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Kirovohrads’ka Oblast’",
    "capital": "minor",
    "population": 11614,
    "id": 1804230769
  },
  {
    "city": "Panajachel",
    "city_ascii": "Panajachel",
    "lat": 14.7361,
    "lng": -91.1558,
    "country": "Guatemala",
    "iso2": "GT",
    "iso3": "GTM",
    "admin_name": "Sololá",
    "capital": "minor",
    "population": 11142,
    "id": 1320143050
  },
  {
    "city": "Ulricehamn",
    "city_ascii": "Ulricehamn",
    "lat": 57.7917,
    "lng": 13.4186,
    "country": "Sweden",
    "iso2": "SE",
    "iso3": "SWE",
    "admin_name": "Västra Götaland",
    "capital": "minor",
    "population": 11443,
    "id": 1752323454
  },
  {
    "city": "Budakalász",
    "city_ascii": "Budakalasz",
    "lat": 47.6215,
    "lng": 19.046,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Pest",
    "capital": "",
    "population": 10924,
    "id": 1348378695
  },
  {
    "city": "Mistelbach",
    "city_ascii": "Mistelbach",
    "lat": 48.5667,
    "lng": 16.5667,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Niederösterreich",
    "capital": "minor",
    "population": 11559,
    "id": 1040942506
  },
  {
    "city": "Svidník",
    "city_ascii": "Svidnik",
    "lat": 49.3056,
    "lng": 21.5678,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Prešovský",
    "capital": "minor",
    "population": 11096,
    "id": 1703982053
  },
  {
    "city": "Saint Helena Bay",
    "city_ascii": "Saint Helena Bay",
    "lat": -32.7583,
    "lng": 18.0278,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "Western Cape",
    "capital": "",
    "population": 11529,
    "id": 1710255294
  },
  {
    "city": "Krynica",
    "city_ascii": "Krynica",
    "lat": 49.4222,
    "lng": 20.9594,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Małopolskie",
    "capital": "",
    "population": 11361,
    "id": 1616638760
  },
  {
    "city": "Pine Castle",
    "city_ascii": "Pine Castle",
    "lat": 28.4651,
    "lng": -81.374,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 11643,
    "id": 1840014097
  },
  {
    "city": "Kalmiuske",
    "city_ascii": "Kalmiuske",
    "lat": 47.6667,
    "lng": 38.0667,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Donets’ka Oblast’",
    "capital": "",
    "population": 11603,
    "id": 1804369480
  },
  {
    "city": "Brock",
    "city_ascii": "Brock",
    "lat": 44.3167,
    "lng": -79.0833,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 11642,
    "id": 1124001106
  },
  {
    "city": "Stupava",
    "city_ascii": "Stupava",
    "lat": 48.2833,
    "lng": 17.0333,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Bratislavský",
    "capital": "",
    "population": 11471,
    "id": 1703216738
  },
  {
    "city": "Endwell",
    "city_ascii": "Endwell",
    "lat": 42.1185,
    "lng": -76.0219,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 11641,
    "id": 1840004657
  },
  {
    "city": "Eccleston",
    "city_ascii": "Eccleston",
    "lat": 53.4539,
    "lng": -2.7748,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "St. Helens",
    "capital": "",
    "population": 10433,
    "id": 1826176719
  },
  {
    "city": "Travilah",
    "city_ascii": "Travilah",
    "lat": 39.0571,
    "lng": -77.2458,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 11633,
    "id": 1840005846
  },
  {
    "city": "Anatolí",
    "city_ascii": "Anatoli",
    "lat": 39.6386,
    "lng": 20.8661,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Ípeiros",
    "capital": "",
    "population": 9798,
    "id": 1300401701
  },
  {
    "city": "Hipperholme",
    "city_ascii": "Hipperholme",
    "lat": 53.7258,
    "lng": -1.812,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Calderdale",
    "capital": "",
    "population": 11308,
    "id": 1826718988
  },
  {
    "city": "Kuřim",
    "city_ascii": "Kurim",
    "lat": 49.2985,
    "lng": 16.5315,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Jihomoravský Kraj",
    "capital": "",
    "population": 10993,
    "id": 1203206546
  },
  {
    "city": "Möhlin",
    "city_ascii": "Mohlin",
    "lat": 47.5583,
    "lng": 7.8458,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Aargau",
    "capital": "",
    "population": 11042,
    "id": 1756997029
  },
  {
    "city": "Bad Nenndorf",
    "city_ascii": "Bad Nenndorf",
    "lat": 52.3369,
    "lng": 9.3786,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 11144,
    "id": 1276123356
  },
  {
    "city": "Walldürn",
    "city_ascii": "Walldurn",
    "lat": 49.5831,
    "lng": 9.3681,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 11518,
    "id": 1276425115
  },
  {
    "city": "Schöningen",
    "city_ascii": "Schoningen",
    "lat": 52.138,
    "lng": 10.9674,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 11306,
    "id": 1276898175
  },
  {
    "city": "Bytča",
    "city_ascii": "Bytca",
    "lat": 49.2228,
    "lng": 18.5581,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Žilinský",
    "capital": "minor",
    "population": 11362,
    "id": 1703630221
  },
  {
    "city": "Calheta",
    "city_ascii": "Calheta",
    "lat": 32.7333,
    "lng": -17.1667,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Madeira",
    "capital": "",
    "population": 11521,
    "id": 1620158427
  },
  {
    "city": "South Yarmouth",
    "city_ascii": "South Yarmouth",
    "lat": 41.6692,
    "lng": -70.2005,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 11624,
    "id": 1840004766
  },
  {
    "city": "Pīr Bakrān",
    "city_ascii": "Pir Bakran",
    "lat": 32.4686,
    "lng": 51.5578,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Eşfahān",
    "capital": "",
    "population": 10851,
    "id": 1364421592
  },
  {
    "city": "Pontiac",
    "city_ascii": "Pontiac",
    "lat": 40.8881,
    "lng": -88.6414,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 11622,
    "id": 1840009352
  },
  {
    "city": "Stonehaven",
    "city_ascii": "Stonehaven",
    "lat": 56.964,
    "lng": -2.211,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Aberdeenshire",
    "capital": "",
    "population": 11602,
    "id": 1826190740
  },
  {
    "city": "Lyuban’",
    "city_ascii": "Lyuban'",
    "lat": 52.7819,
    "lng": 28.0525,
    "country": "Belarus",
    "iso2": "BY",
    "iso3": "BLR",
    "admin_name": "Minskaya Voblasts’",
    "capital": "minor",
    "population": 11600,
    "id": 1112654360
  },
  {
    "city": "Summerland",
    "city_ascii": "Summerland",
    "lat": 49.6006,
    "lng": -119.6778,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "British Columbia",
    "capital": "",
    "population": 11615,
    "id": 1124400731
  },
  {
    "city": "Bradwell",
    "city_ascii": "Bradwell",
    "lat": 52.05,
    "lng": -0.787,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Milton Keynes",
    "capital": "",
    "population": 9657,
    "id": 1826794750
  },
  {
    "city": "Kuruman",
    "city_ascii": "Kuruman",
    "lat": -27.4597,
    "lng": 23.4125,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "Northern Cape",
    "capital": "",
    "population": 10006,
    "id": 1710332978
  },
  {
    "city": "Wyandanch",
    "city_ascii": "Wyandanch",
    "lat": 40.7467,
    "lng": -73.3769,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 11612,
    "id": 1840005115
  },
  {
    "city": "Franklin",
    "city_ascii": "Franklin",
    "lat": 39.5538,
    "lng": -84.295,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 11612,
    "id": 1840008466
  },
  {
    "city": "Selsey",
    "city_ascii": "Selsey",
    "lat": 50.735,
    "lng": -0.7898,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "West Sussex",
    "capital": "",
    "population": 10737,
    "id": 1826710788
  },
  {
    "city": "Batemans Bay",
    "city_ascii": "Batemans Bay",
    "lat": -35.7081,
    "lng": 150.1744,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 11294,
    "id": 1036272805
  },
  {
    "city": "Likhoslavl",
    "city_ascii": "Likhoslavl",
    "lat": 57.1167,
    "lng": 35.4667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Tverskaya Oblast’",
    "capital": "",
    "population": 11594,
    "id": 1643616895
  },
  {
    "city": "Brattleboro",
    "city_ascii": "Brattleboro",
    "lat": 42.8618,
    "lng": -72.6145,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Vermont",
    "capital": "",
    "population": 11608,
    "id": 1840070391
  },
  {
    "city": "Kem",
    "city_ascii": "Kem",
    "lat": 64.95,
    "lng": 34.6,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kareliya",
    "capital": "",
    "population": 11604,
    "id": 1643304829
  },
  {
    "city": "Kalaoa",
    "city_ascii": "Kalaoa",
    "lat": 19.7369,
    "lng": -156.0122,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Hawaii",
    "capital": "",
    "population": 11604,
    "id": 1840029493
  },
  {
    "city": "Largo",
    "city_ascii": "Largo",
    "lat": 38.88,
    "lng": -76.8289,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 11604,
    "id": 1840005960
  },
  {
    "city": "Cedarburg",
    "city_ascii": "Cedarburg",
    "lat": 43.2991,
    "lng": -87.9887,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 11603,
    "id": 1840002825
  },
  {
    "city": "Săcueni",
    "city_ascii": "Sacueni",
    "lat": 47.3525,
    "lng": 22.0914,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Bihor",
    "capital": "",
    "population": 11526,
    "id": 1642478221
  },
  {
    "city": "Temamatla",
    "city_ascii": "Temamatla",
    "lat": 19.2028,
    "lng": -98.87,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "México",
    "capital": "minor",
    "population": 11206,
    "id": 1484069603
  },
  {
    "city": "Sursee",
    "city_ascii": "Sursee",
    "lat": 47.1742,
    "lng": 8.1081,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Luzern",
    "capital": "minor",
    "population": 9900,
    "id": 1756349177
  },
  {
    "city": "Samaná",
    "city_ascii": "Samana",
    "lat": 19.212,
    "lng": -69.332,
    "country": "Dominican Republic",
    "iso2": "DO",
    "iso3": "DOM",
    "admin_name": "Cibao Nordeste",
    "capital": "minor",
    "population": 11432,
    "id": 1214655701
  },
  {
    "city": "De Panne",
    "city_ascii": "De Panne",
    "lat": 51.1019,
    "lng": 2.5917,
    "country": "Belgium",
    "iso2": "BE",
    "iso3": "BEL",
    "admin_name": "Flanders",
    "capital": "",
    "population": 11129,
    "id": 1056000077
  },
  {
    "city": "Langelsheim",
    "city_ascii": "Langelsheim",
    "lat": 51.9381,
    "lng": 10.335,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 11361,
    "id": 1276567894
  },
  {
    "city": "North Bellport",
    "city_ascii": "North Bellport",
    "lat": 40.7868,
    "lng": -72.9457,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 11593,
    "id": 1840005056
  },
  {
    "city": "Stollberg",
    "city_ascii": "Stollberg",
    "lat": 50.7083,
    "lng": 12.7783,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 11303,
    "id": 1276429853
  },
  {
    "city": "Werther",
    "city_ascii": "Werther",
    "lat": 52.0764,
    "lng": 8.4136,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 11274,
    "id": 1276520918
  },
  {
    "city": "Ghinda’e",
    "city_ascii": "Ghinda'e",
    "lat": 15.45,
    "lng": 39.0833,
    "country": "Eritrea",
    "iso2": "ER",
    "iso3": "ERI",
    "admin_name": "Semēnawī K’eyih Bahrī",
    "capital": "",
    "population": 11560,
    "id": 1232629758
  },
  {
    "city": "Annapolis Neck",
    "city_ascii": "Annapolis Neck",
    "lat": 38.9408,
    "lng": -76.4997,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 11590,
    "id": 1840039445
  },
  {
    "city": "Beachwood",
    "city_ascii": "Beachwood",
    "lat": 41.4759,
    "lng": -81.503,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 11590,
    "id": 1840003395
  },
  {
    "city": "Seven Hills",
    "city_ascii": "Seven Hills",
    "lat": 41.3805,
    "lng": -81.6737,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 11590,
    "id": 1840003411
  },
  {
    "city": "Rutesheim",
    "city_ascii": "Rutesheim",
    "lat": 48.8097,
    "lng": 8.945,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 10916,
    "id": 1276637060
  },
  {
    "city": "Epazoyucan",
    "city_ascii": "Epazoyucan",
    "lat": 20.0177,
    "lng": -98.6361,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Hidalgo",
    "capital": "minor",
    "population": 11522,
    "id": 1484543443
  },
  {
    "city": "Rotkreuz",
    "city_ascii": "Rotkreuz",
    "lat": 47.1411,
    "lng": 8.4314,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Zug",
    "capital": "",
    "population": 10857,
    "id": 1756187376
  },
  {
    "city": "Tadley",
    "city_ascii": "Tadley",
    "lat": 51.3506,
    "lng": -1.1376,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hampshire",
    "capital": "",
    "population": 11473,
    "id": 1826538843
  },
  {
    "city": "Honmachi",
    "city_ascii": "Honmachi",
    "lat": 43.9114,
    "lng": 144.6708,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hokkaidō",
    "capital": "",
    "population": 11571,
    "id": 1392192562
  },
  {
    "city": "Ciechocinek",
    "city_ascii": "Ciechocinek",
    "lat": 52.8833,
    "lng": 18.7833,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Kujawsko-Pomorskie",
    "capital": "",
    "population": 10874,
    "id": 1616015629
  },
  {
    "city": "Echelon",
    "city_ascii": "Echelon",
    "lat": 39.8482,
    "lng": -74.9957,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 11586,
    "id": 1840033470
  },
  {
    "city": "Cranleigh",
    "city_ascii": "Cranleigh",
    "lat": 51.1363,
    "lng": -0.4784,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Surrey",
    "capital": "",
    "population": 11241,
    "id": 1826383822
  },
  {
    "city": "Chowchilla",
    "city_ascii": "Chowchilla",
    "lat": 37.1095,
    "lng": -120.2349,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 11583,
    "id": 1840018919
  },
  {
    "city": "Val-des-Monts",
    "city_ascii": "Val-des-Monts",
    "lat": 45.65,
    "lng": -75.6667,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 11582,
    "id": 1124001051
  },
  {
    "city": "Excelsior Springs",
    "city_ascii": "Excelsior Springs",
    "lat": 39.339,
    "lng": -94.24,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 11581,
    "id": 1840008495
  },
  {
    "city": "Cerrillos",
    "city_ascii": "Cerrillos",
    "lat": -24.8996,
    "lng": -65.4833,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Salta",
    "capital": "minor",
    "population": 11498,
    "id": 1032917216
  },
  {
    "city": "Uettligen",
    "city_ascii": "Uettligen",
    "lat": 46.9775,
    "lng": 7.4792,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Bern",
    "capital": "",
    "population": 11335,
    "id": 1756811836
  },
  {
    "city": "Holywood",
    "city_ascii": "Holywood",
    "lat": 54.636,
    "lng": -5.845,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Ards and North Down",
    "capital": "",
    "population": 11257,
    "id": 1826639765
  },
  {
    "city": "Uničov",
    "city_ascii": "Unicov",
    "lat": 49.7709,
    "lng": 17.1215,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Olomoucký Kraj",
    "capital": "",
    "population": 11335,
    "id": 1203442454
  },
  {
    "city": "Leatherhead",
    "city_ascii": "Leatherhead",
    "lat": 51.295,
    "lng": -0.329,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Surrey",
    "capital": "",
    "population": 11316,
    "id": 1826812300
  },
  {
    "city": "Taché",
    "city_ascii": "Tache",
    "lat": 49.7081,
    "lng": -96.6736,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Manitoba",
    "capital": "",
    "population": 11568,
    "id": 1124000169
  },
  {
    "city": "Kin",
    "city_ascii": "Kin",
    "lat": 26.4561,
    "lng": 127.9261,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Okinawa",
    "capital": "",
    "population": 11270,
    "id": 1392554480
  },
  {
    "city": "Severnyy",
    "city_ascii": "Severnyy",
    "lat": 67.6083,
    "lng": 64.1233,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Komi",
    "capital": "",
    "population": 11562,
    "id": 1643427677
  },
  {
    "city": "Ozark",
    "city_ascii": "Ozark",
    "lat": 31.4508,
    "lng": -85.6473,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 11562,
    "id": 1840005283
  },
  {
    "city": "Silver City",
    "city_ascii": "Silver City",
    "lat": 32.7784,
    "lng": -108.2698,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Mexico",
    "capital": "",
    "population": 11561,
    "id": 1840023009
  },
  {
    "city": "Newport East",
    "city_ascii": "Newport East",
    "lat": 41.5158,
    "lng": -71.2878,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Rhode Island",
    "capital": "",
    "population": 11561,
    "id": 1840073469
  },
  {
    "city": "Kyjov",
    "city_ascii": "Kyjov",
    "lat": 49.0102,
    "lng": 17.1225,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Jihomoravský Kraj",
    "capital": "",
    "population": 11185,
    "id": 1203980144
  },
  {
    "city": "Glückstadt",
    "city_ascii": "Gluckstadt",
    "lat": 53.7917,
    "lng": 9.4219,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Schleswig-Holstein",
    "capital": "",
    "population": 11069,
    "id": 1276320912
  },
  {
    "city": "Wittingen",
    "city_ascii": "Wittingen",
    "lat": 52.7281,
    "lng": 10.7391,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 11503,
    "id": 1276184475
  },
  {
    "city": "Whitchurch",
    "city_ascii": "Whitchurch",
    "lat": 51.4064,
    "lng": -2.5594,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Bath and North East Somerset",
    "capital": "",
    "population": 11061,
    "id": 1826527232
  },
  {
    "city": "Center Township",
    "city_ascii": "Center Township",
    "lat": 40.6483,
    "lng": -80.2977,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 11551,
    "id": 1840144996
  },
  {
    "city": "Drezna",
    "city_ascii": "Drezna",
    "lat": 55.75,
    "lng": 38.8333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Moskovskaya Oblast’",
    "capital": "",
    "population": 11480,
    "id": 1643752314
  },
  {
    "city": "Rheinau",
    "city_ascii": "Rheinau",
    "lat": 48.6678,
    "lng": 7.9347,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 11395,
    "id": 1276702024
  },
  {
    "city": "Omaruru",
    "city_ascii": "Omaruru",
    "lat": -21.4183,
    "lng": 15.9539,
    "country": "Namibia",
    "iso2": "NA",
    "iso3": "NAM",
    "admin_name": "Erongo",
    "capital": "",
    "population": 11547,
    "id": 1516735467
  },
  {
    "city": "Domažlice",
    "city_ascii": "Domazlice",
    "lat": 49.4406,
    "lng": 12.9298,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Plzeňský Kraj",
    "capital": "",
    "population": 11094,
    "id": 1203358449
  },
  {
    "city": "Cholpon-Ata",
    "city_ascii": "Cholpon-Ata",
    "lat": 42.65,
    "lng": 77.0833,
    "country": "Kyrgyzstan",
    "iso2": "KG",
    "iso3": "KGZ",
    "admin_name": "Ysyk-Köl",
    "capital": "minor",
    "population": 11520,
    "id": 1417433118
  },
  {
    "city": "Martinsville",
    "city_ascii": "Martinsville",
    "lat": 40.603,
    "lng": -74.5751,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 11541,
    "id": 1840024279
  },
  {
    "city": "West Plains",
    "city_ascii": "West Plains",
    "lat": 36.7378,
    "lng": -91.868,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 11540,
    "id": 1840010887
  },
  {
    "city": "Sântana",
    "city_ascii": "Santana",
    "lat": 46.35,
    "lng": 21.5,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Arad",
    "capital": "",
    "population": 11428,
    "id": 1642162746
  },
  {
    "city": "Târgu Ocna",
    "city_ascii": "Targu Ocna",
    "lat": 46.2803,
    "lng": 26.6094,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Bacău",
    "capital": "",
    "population": 11300,
    "id": 1642346375
  },
  {
    "city": "Woodland Park",
    "city_ascii": "Woodland Park",
    "lat": 38.9985,
    "lng": -105.0594,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 11530,
    "id": 1840021468
  },
  {
    "city": "Spirit Lake",
    "city_ascii": "Spirit Lake",
    "lat": 43.4176,
    "lng": -95.1109,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Iowa",
    "capital": "",
    "population": 11530,
    "id": 1840009023
  },
  {
    "city": "Mount Sinai",
    "city_ascii": "Mount Sinai",
    "lat": 40.9372,
    "lng": -73.018,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 11529,
    "id": 1840005049
  },
  {
    "city": "Bainbridge",
    "city_ascii": "Bainbridge",
    "lat": 30.9046,
    "lng": -84.5727,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 11529,
    "id": 1840013899
  },
  {
    "city": "Largs",
    "city_ascii": "Largs",
    "lat": 55.794,
    "lng": -4.867,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "North Ayrshire",
    "capital": "",
    "population": 11241,
    "id": 1826043639
  },
  {
    "city": "Tagta",
    "city_ascii": "Tagta",
    "lat": 41.65,
    "lng": 59.9,
    "country": "Turkmenistan",
    "iso2": "TM",
    "iso3": "TKM",
    "admin_name": "Daşoguz",
    "capital": "",
    "population": 11501,
    "id": 1795090277
  },
  {
    "city": "Zvenigovo",
    "city_ascii": "Zvenigovo",
    "lat": 55.9667,
    "lng": 48.0167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Mariy-El",
    "capital": "",
    "population": 11504,
    "id": 1643775497
  },
  {
    "city": "Valdez",
    "city_ascii": "Valdez",
    "lat": 1.2671,
    "lng": -78.9855,
    "country": "Ecuador",
    "iso2": "EC",
    "iso3": "ECU",
    "admin_name": "Esmeraldas",
    "capital": "",
    "population": 11441,
    "id": 1218064465
  },
  {
    "city": "Bridgeton",
    "city_ascii": "Bridgeton",
    "lat": 38.7673,
    "lng": -90.4275,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 11520,
    "id": 1840006129
  },
  {
    "city": "Lokhvytsya",
    "city_ascii": "Lokhvytsya",
    "lat": 50.361,
    "lng": 33.2652,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Poltavs’ka Oblast’",
    "capital": "minor",
    "population": 11485,
    "id": 1804270399
  },
  {
    "city": "Palos de la Frontera",
    "city_ascii": "Palos de la Frontera",
    "lat": 37.2278,
    "lng": -6.8932,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Andalusia",
    "capital": "",
    "population": 11289,
    "id": 1724003384
  },
  {
    "city": "Myronivka",
    "city_ascii": "Myronivka",
    "lat": 49.65,
    "lng": 30.9833,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Kyyivs’ka Oblast’",
    "capital": "minor",
    "population": 11478,
    "id": 1804440436
  },
  {
    "city": "Blundellsands",
    "city_ascii": "Blundellsands",
    "lat": 53.48,
    "lng": -3.05,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Sefton",
    "capital": "",
    "population": 11514,
    "id": 1826007490
  },
  {
    "city": "Archdale",
    "city_ascii": "Archdale",
    "lat": 35.9033,
    "lng": -79.9592,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 11513,
    "id": 1840013399
  },
  {
    "city": "Mamakating",
    "city_ascii": "Mamakating",
    "lat": 41.586,
    "lng": -74.4951,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 11513,
    "id": 1840087643
  },
  {
    "city": "Balve",
    "city_ascii": "Balve",
    "lat": 51.3333,
    "lng": 7.8667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 11361,
    "id": 1276429587
  },
  {
    "city": "Rothenburg ob der Tauber",
    "city_ascii": "Rothenburg ob der Tauber",
    "lat": 49.3772,
    "lng": 10.1789,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 11243,
    "id": 1276993004
  },
  {
    "city": "Coronel Dorrego",
    "city_ascii": "Coronel Dorrego",
    "lat": -38.7187,
    "lng": -61.2873,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires",
    "capital": "minor",
    "population": 11510,
    "id": 1032800825
  },
  {
    "city": "Hanover",
    "city_ascii": "Hanover",
    "lat": 43.7155,
    "lng": -72.1911,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Hampshire",
    "capital": "",
    "population": 11512,
    "id": 1840054971
  },
  {
    "city": "Upper Uwchlan",
    "city_ascii": "Upper Uwchlan",
    "lat": 40.0817,
    "lng": -75.7071,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 11509,
    "id": 1840145646
  },
  {
    "city": "Divinolândia",
    "city_ascii": "Divinolandia",
    "lat": -21.6614,
    "lng": -46.7392,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 11492,
    "id": 1076108804
  },
  {
    "city": "Lower Gwynedd",
    "city_ascii": "Lower Gwynedd",
    "lat": 40.188,
    "lng": -75.2373,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 11508,
    "id": 1840150802
  },
  {
    "city": "Marktheidenfeld",
    "city_ascii": "Marktheidenfeld",
    "lat": 49.85,
    "lng": 9.6,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 11194,
    "id": 1276902218
  },
  {
    "city": "Crikvenica",
    "city_ascii": "Crikvenica",
    "lat": 45.1833,
    "lng": 14.7,
    "country": "Croatia",
    "iso2": "HR",
    "iso3": "HRV",
    "admin_name": "Primorsko-Goranska Županija",
    "capital": "minor",
    "population": 11122,
    "id": 1191344167
  },
  {
    "city": "Lenzburg",
    "city_ascii": "Lenzburg",
    "lat": 47.3875,
    "lng": 8.1803,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Aargau",
    "capital": "",
    "population": 10569,
    "id": 1756304845
  },
  {
    "city": "Poldasht",
    "city_ascii": "Poldasht",
    "lat": 39.3481,
    "lng": 45.0711,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Āz̄arbāyjān-e Gharbī",
    "capital": "minor",
    "population": 11472,
    "id": 1364811524
  },
  {
    "city": "Newport",
    "city_ascii": "Newport",
    "lat": 35.9617,
    "lng": -83.1977,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 11500,
    "id": 1840014487
  },
  {
    "city": "North Middleton",
    "city_ascii": "North Middleton",
    "lat": 40.2462,
    "lng": -77.2168,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 11499,
    "id": 1840144641
  },
  {
    "city": "Ochsenfurt",
    "city_ascii": "Ochsenfurt",
    "lat": 49.6711,
    "lng": 10.0498,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 11319,
    "id": 1276225092
  },
  {
    "city": "Aginskoye",
    "city_ascii": "Aginskoye",
    "lat": 51.1031,
    "lng": 114.5228,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Zabaykal’skiy Kray",
    "capital": "",
    "population": 11491,
    "id": 1643514234
  },
  {
    "city": "Florham Park",
    "city_ascii": "Florham Park",
    "lat": 40.7773,
    "lng": -74.3953,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 11496,
    "id": 1840003582
  },
  {
    "city": "Geisenfeld",
    "city_ascii": "Geisenfeld",
    "lat": 48.684,
    "lng": 11.6117,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 11363,
    "id": 1276340970
  },
  {
    "city": "Montmagny",
    "city_ascii": "Montmagny",
    "lat": 46.9833,
    "lng": -70.55,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 11491,
    "id": 1124025705
  },
  {
    "city": "Kostinbrod",
    "city_ascii": "Kostinbrod",
    "lat": 42.8167,
    "lng": 23.2167,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Sofia",
    "capital": "",
    "population": 11430,
    "id": 1100892739
  },
  {
    "city": "Glenfield",
    "city_ascii": "Glenfield",
    "lat": 52.6491,
    "lng": -1.2062,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Leicestershire",
    "capital": "",
    "population": 9643,
    "id": 1826730292
  },
  {
    "city": "Roma",
    "city_ascii": "Roma",
    "lat": 26.4166,
    "lng": -99.006,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 11490,
    "id": 1840021017
  },
  {
    "city": "Chkalovsk",
    "city_ascii": "Chkalovsk",
    "lat": 56.7667,
    "lng": 43.25,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Nizhegorodskaya Oblast’",
    "capital": "",
    "population": 11462,
    "id": 1643806509
  },
  {
    "city": "Bogalusa",
    "city_ascii": "Bogalusa",
    "lat": 30.7812,
    "lng": -89.8633,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 11486,
    "id": 1840013905
  },
  {
    "city": "Madison Heights",
    "city_ascii": "Madison Heights",
    "lat": 37.4487,
    "lng": -79.1057,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 11483,
    "id": 1840006372
  },
  {
    "city": "Avigliano",
    "city_ascii": "Avigliano",
    "lat": 40.7333,
    "lng": 15.7167,
    "country": "Italy",
    "iso2": "IT",
    "iso3": "ITA",
    "admin_name": "Basilicata",
    "capital": "",
    "population": 11350,
    "id": 1380120301
  },
  {
    "city": "Rumburk",
    "city_ascii": "Rumburk",
    "lat": 50.9516,
    "lng": 14.5571,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Ústecký Kraj",
    "capital": "",
    "population": 11036,
    "id": 1203370008
  },
  {
    "city": "Hawaiian Paradise Park",
    "city_ascii": "Hawaiian Paradise Park",
    "lat": 19.5828,
    "lng": -154.9693,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Hawaii",
    "capital": "",
    "population": 11479,
    "id": 1840029582
  },
  {
    "city": "Richland Township",
    "city_ascii": "Richland Township",
    "lat": 40.644,
    "lng": -79.9579,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 11477,
    "id": 1840141800
  },
  {
    "city": "Sales Oliveira",
    "city_ascii": "Sales Oliveira",
    "lat": -20.7719,
    "lng": -47.8378,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 11438,
    "id": 1076954861
  },
  {
    "city": "Ḑurumā",
    "city_ascii": "Duruma",
    "lat": 24.6,
    "lng": 46.1333,
    "country": "Saudi Arabia",
    "iso2": "SA",
    "iso3": "SAU",
    "admin_name": "Ar Riyāḑ",
    "capital": "",
    "population": 11461,
    "id": 1682041945
  },
  {
    "city": "Gerasdorf bei Wien",
    "city_ascii": "Gerasdorf bei Wien",
    "lat": 48.295,
    "lng": 16.4675,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Niederösterreich",
    "capital": "",
    "population": 11155,
    "id": 1040814671
  },
  {
    "city": "Welzheim",
    "city_ascii": "Welzheim",
    "lat": 48.8747,
    "lng": 9.6344,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 11176,
    "id": 1276045821
  },
  {
    "city": "Phanat Nikhom",
    "city_ascii": "Phanat Nikhom",
    "lat": 13.4458,
    "lng": 101.1844,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Chon Buri",
    "capital": "minor",
    "population": 11032,
    "id": 1764762615
  },
  {
    "city": "Lake Los Angeles",
    "city_ascii": "Lake Los Angeles",
    "lat": 34.6097,
    "lng": -117.8339,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 11469,
    "id": 1840017922
  },
  {
    "city": "Naliya",
    "city_ascii": "Naliya",
    "lat": 23.2611,
    "lng": 68.8267,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Gujarāt",
    "capital": "",
    "population": 11415,
    "id": 1356368840
  },
  {
    "city": "Egersund",
    "city_ascii": "Egersund",
    "lat": 58.45,
    "lng": 6.0067,
    "country": "Norway",
    "iso2": "NO",
    "iso3": "NOR",
    "admin_name": "Rogaland",
    "capital": "minor",
    "population": 11433,
    "id": 1578432421
  },
  {
    "city": "Emmaus",
    "city_ascii": "Emmaus",
    "lat": 40.5352,
    "lng": -75.4978,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 11467,
    "id": 1840001048
  },
  {
    "city": "Holíč",
    "city_ascii": "Holic",
    "lat": 48.8119,
    "lng": 17.1628,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Trnavský",
    "capital": "",
    "population": 11171,
    "id": 1703056774
  },
  {
    "city": "Bad Schwalbach",
    "city_ascii": "Bad Schwalbach",
    "lat": 50.1401,
    "lng": 8.0694,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "minor",
    "population": 11187,
    "id": 1276875328
  },
  {
    "city": "Gracemere",
    "city_ascii": "Gracemere",
    "lat": -23.4333,
    "lng": 150.45,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Queensland",
    "capital": "",
    "population": 11315,
    "id": 1036779939
  },
  {
    "city": "Jackson",
    "city_ascii": "Jackson",
    "lat": 39.046,
    "lng": -82.6492,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 11462,
    "id": 1840008548
  },
  {
    "city": "Camp Pendleton South",
    "city_ascii": "Camp Pendleton South",
    "lat": 33.2284,
    "lng": -117.3791,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 11459,
    "id": 1840073902
  },
  {
    "city": "Boldeşti-Scăeni",
    "city_ascii": "Boldesti-Scaeni",
    "lat": 45.03,
    "lng": 26.03,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Prahova",
    "capital": "",
    "population": 11137,
    "id": 1642726960
  },
  {
    "city": "Resende",
    "city_ascii": "Resende",
    "lat": 41.1,
    "lng": -7.95,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Viseu",
    "capital": "minor",
    "population": 11364,
    "id": 1620858385
  },
  {
    "city": "Chalco",
    "city_ascii": "Chalco",
    "lat": 41.1817,
    "lng": -96.1353,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Nebraska",
    "capital": "",
    "population": 11456,
    "id": 1840005176
  },
  {
    "city": "Goulds",
    "city_ascii": "Goulds",
    "lat": 25.5614,
    "lng": -80.388,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 11453,
    "id": 1840014239
  },
  {
    "city": "Greentree",
    "city_ascii": "Greentree",
    "lat": 39.8989,
    "lng": -74.9614,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 11453,
    "id": 1840033472
  },
  {
    "city": "Webster",
    "city_ascii": "Webster",
    "lat": 29.5317,
    "lng": -95.1188,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 11451,
    "id": 1840022204
  },
  {
    "city": "Hanover Township",
    "city_ascii": "Hanover Township",
    "lat": 40.6669,
    "lng": -75.3979,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 11451,
    "id": 1840151272
  },
  {
    "city": "Oraviţa",
    "city_ascii": "Oravita",
    "lat": 45.0333,
    "lng": 21.6833,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Caraş-Severin",
    "capital": "",
    "population": 11382,
    "id": 1642696824
  },
  {
    "city": "Körmend",
    "city_ascii": "Kormend",
    "lat": 47.011,
    "lng": 16.606,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Vas",
    "capital": "minor",
    "population": 11236,
    "id": 1348688701
  },
  {
    "city": "Byelaazyorsk",
    "city_ascii": "Byelaazyorsk",
    "lat": 52.45,
    "lng": 25.1667,
    "country": "Belarus",
    "iso2": "BY",
    "iso3": "BLR",
    "admin_name": "Brestskaya Voblasts’",
    "capital": "",
    "population": 11400,
    "id": 1112431328
  },
  {
    "city": "Elizabethtown",
    "city_ascii": "Elizabethtown",
    "lat": 40.1534,
    "lng": -76.5991,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 11445,
    "id": 1840003716
  },
  {
    "city": "Ágios Nikólaos",
    "city_ascii": "Agios Nikolaos",
    "lat": 35.1892,
    "lng": 25.7173,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Kríti",
    "capital": "minor",
    "population": 11421,
    "id": 1300945111
  },
  {
    "city": "Lafrayta",
    "city_ascii": "Lafrayta",
    "lat": 31.932,
    "lng": -7.26,
    "country": "Morocco",
    "iso2": "MA",
    "iso3": "MAR",
    "admin_name": "Marrakech-Safi",
    "capital": "",
    "population": 11298,
    "id": 1504011173
  },
  {
    "city": "Trostberg an der Alz",
    "city_ascii": "Trostberg an der Alz",
    "lat": 48.0167,
    "lng": 12.55,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 11222,
    "id": 1276546741
  },
  {
    "city": "Elwood",
    "city_ascii": "Elwood",
    "lat": 40.8462,
    "lng": -73.3389,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 11439,
    "id": 1840005008
  },
  {
    "city": "Erin",
    "city_ascii": "Erin",
    "lat": 43.7667,
    "lng": -80.0667,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 11439,
    "id": 1124418313
  },
  {
    "city": "Jimaní",
    "city_ascii": "Jimani",
    "lat": 18.4833,
    "lng": -71.85,
    "country": "Dominican Republic",
    "iso2": "DO",
    "iso3": "DOM",
    "admin_name": "Enriquillo",
    "capital": "minor",
    "population": 11414,
    "id": 1214388193
  },
  {
    "city": "Wildau",
    "city_ascii": "Wildau",
    "lat": 52.3167,
    "lng": 13.6333,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Brandenburg",
    "capital": "",
    "population": 10303,
    "id": 1276865619
  },
  {
    "city": "Port Lavaca",
    "city_ascii": "Port Lavaca",
    "lat": 28.6181,
    "lng": -96.6278,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 11436,
    "id": 1840020996
  },
  {
    "city": "Claiborne",
    "city_ascii": "Claiborne",
    "lat": 32.5379,
    "lng": -92.1981,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 11435,
    "id": 1840013811
  },
  {
    "city": "Flawil",
    "city_ascii": "Flawil",
    "lat": 47.4053,
    "lng": 9.1977,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Sankt Gallen",
    "capital": "",
    "population": 10520,
    "id": 1756417053
  },
  {
    "city": "Woods Cross",
    "city_ascii": "Woods Cross",
    "lat": 40.8731,
    "lng": -111.917,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Utah",
    "capital": "",
    "population": 11431,
    "id": 1840021355
  },
  {
    "city": "Baldock",
    "city_ascii": "Baldock",
    "lat": 51.99,
    "lng": -0.19,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hertfordshire",
    "capital": "",
    "population": 9900,
    "id": 1826427353
  },
  {
    "city": "Belén",
    "city_ascii": "Belen",
    "lat": -23.4695,
    "lng": -57.24,
    "country": "Paraguay",
    "iso2": "PY",
    "iso3": "PRY",
    "admin_name": "Concepción",
    "capital": "",
    "population": 11359,
    "id": 1600551990
  },
  {
    "city": "Klyetsk",
    "city_ascii": "Klyetsk",
    "lat": 53.0636,
    "lng": 26.6372,
    "country": "Belarus",
    "iso2": "BY",
    "iso3": "BLR",
    "admin_name": "Minskaya Voblasts’",
    "capital": "minor",
    "population": 11400,
    "id": 1112054647
  },
  {
    "city": "Byram",
    "city_ascii": "Byram",
    "lat": 32.189,
    "lng": -90.2861,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Mississippi",
    "capital": "",
    "population": 11428,
    "id": 1840023049
  },
  {
    "city": "Chapantongo",
    "city_ascii": "Chapantongo",
    "lat": 20.2833,
    "lng": -99.4,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Hidalgo",
    "capital": "",
    "population": 11389,
    "id": 1484970395
  },
  {
    "city": "Sorsk",
    "city_ascii": "Sorsk",
    "lat": 54,
    "lng": 90.25,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Khakasiya",
    "capital": "",
    "population": 11416,
    "id": 1643209786
  },
  {
    "city": "Highland",
    "city_ascii": "Highland",
    "lat": 38.7602,
    "lng": -89.681,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 11425,
    "id": 1840007425
  },
  {
    "city": "Yuryuzan",
    "city_ascii": "Yuryuzan",
    "lat": 54.8667,
    "lng": 58.4333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Chelyabinskaya Oblast’",
    "capital": "",
    "population": 11415,
    "id": 1643397550
  },
  {
    "city": "Kastav",
    "city_ascii": "Kastav",
    "lat": 45.3726,
    "lng": 14.349,
    "country": "Croatia",
    "iso2": "HR",
    "iso3": "HRV",
    "admin_name": "Primorsko-Goranska Županija",
    "capital": "minor",
    "population": 10472,
    "id": 1191243923
  },
  {
    "city": "Lyndon",
    "city_ascii": "Lyndon",
    "lat": 38.2644,
    "lng": -85.5891,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kentucky",
    "capital": "",
    "population": 11423,
    "id": 1840015191
  },
  {
    "city": "Mack",
    "city_ascii": "Mack",
    "lat": 39.1503,
    "lng": -84.6792,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 11422,
    "id": 1840034079
  },
  {
    "city": "Eckington",
    "city_ascii": "Eckington",
    "lat": 53.308,
    "lng": -1.3643,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Derbyshire",
    "capital": "",
    "population": 11152,
    "id": 1826393351
  },
  {
    "city": "Waidhofen an der Ybbs",
    "city_ascii": "Waidhofen an der Ybbs",
    "lat": 47.9596,
    "lng": 14.7745,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Niederösterreich",
    "capital": "",
    "population": 11333,
    "id": 1040615361
  },
  {
    "city": "East Whiteland",
    "city_ascii": "East Whiteland",
    "lat": 40.0474,
    "lng": -75.5547,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 11415,
    "id": 1840150930
  },
  {
    "city": "Horbury",
    "city_ascii": "Horbury",
    "lat": 53.6595,
    "lng": -1.5566,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Wakefield",
    "capital": "",
    "population": 10002,
    "id": 1826401665
  },
  {
    "city": "Woodward",
    "city_ascii": "Woodward",
    "lat": 36.4247,
    "lng": -99.4057,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oklahoma",
    "capital": "",
    "population": 11410,
    "id": 1840021636
  },
  {
    "city": "Hailey",
    "city_ascii": "Hailey",
    "lat": 43.5141,
    "lng": -114.3,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Idaho",
    "capital": "",
    "population": 11409,
    "id": 1840020028
  },
  {
    "city": "Snovsk",
    "city_ascii": "Snovsk",
    "lat": 51.8167,
    "lng": 31.95,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Chernihivs’ka Oblast’",
    "capital": "minor",
    "population": 11390,
    "id": 1804059681
  },
  {
    "city": "Scottsburg",
    "city_ascii": "Scottsburg",
    "lat": 38.6851,
    "lng": -85.783,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 11408,
    "id": 1840009788
  },
  {
    "city": "Suhr",
    "city_ascii": "Suhr",
    "lat": 47.3747,
    "lng": 8.0806,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Aargau",
    "capital": "",
    "population": 10424,
    "id": 1756546975
  },
  {
    "city": "Kingsnorth",
    "city_ascii": "Kingsnorth",
    "lat": 51.1178,
    "lng": 0.8615,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Kent",
    "capital": "",
    "population": 11243,
    "id": 1826823710
  },
  {
    "city": "Schiller Park",
    "city_ascii": "Schiller Park",
    "lat": 41.9586,
    "lng": -87.8693,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 11403,
    "id": 1840011320
  },
  {
    "city": "Fenton",
    "city_ascii": "Fenton",
    "lat": 42.7994,
    "lng": -83.7144,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 11403,
    "id": 1840002948
  },
  {
    "city": "Lajosmizse",
    "city_ascii": "Lajosmizse",
    "lat": 47.0264,
    "lng": 19.5575,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Bács-Kiskun",
    "capital": "",
    "population": 11334,
    "id": 1348320390
  },
  {
    "city": "Salisbury Township",
    "city_ascii": "Salisbury Township",
    "lat": 40.038,
    "lng": -75.9961,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 11400,
    "id": 1840142490
  },
  {
    "city": "Catskill",
    "city_ascii": "Catskill",
    "lat": 42.2063,
    "lng": -73.9435,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 11399,
    "id": 1840004650
  },
  {
    "city": "Los Alamitos",
    "city_ascii": "Los Alamitos",
    "lat": 33.7972,
    "lng": -118.0594,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 11399,
    "id": 1840020579
  },
  {
    "city": "Minooka",
    "city_ascii": "Minooka",
    "lat": 41.4507,
    "lng": -88.2792,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 11397,
    "id": 1840011618
  },
  {
    "city": "Burrel",
    "city_ascii": "Burrel",
    "lat": 41.6,
    "lng": 20,
    "country": "Albania",
    "iso2": "AL",
    "iso3": "ALB",
    "admin_name": "Dibër",
    "capital": "",
    "population": 10862,
    "id": 1008883153
  },
  {
    "city": "Călan",
    "city_ascii": "Calan",
    "lat": 45.7361,
    "lng": 23.0086,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Hunedoara",
    "capital": "",
    "population": 11279,
    "id": 1642642024
  },
  {
    "city": "Kiáto",
    "city_ascii": "Kiato",
    "lat": 38.0117,
    "lng": 22.7467,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Peloponnísos",
    "capital": "minor",
    "population": 9812,
    "id": 1300543493
  },
  {
    "city": "Monte Quemado",
    "city_ascii": "Monte Quemado",
    "lat": -25.7996,
    "lng": -62.8666,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Santiago del Estero",
    "capital": "minor",
    "population": 11387,
    "id": 1032834102
  },
  {
    "city": "Kincardine",
    "city_ascii": "Kincardine",
    "lat": 44.1667,
    "lng": -81.6333,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 11389,
    "id": 1124781881
  },
  {
    "city": "Hillsborough",
    "city_ascii": "Hillsborough",
    "lat": 37.5573,
    "lng": -122.3587,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 11387,
    "id": 1840021554
  },
  {
    "city": "Onoto",
    "city_ascii": "Onoto",
    "lat": 9.5958,
    "lng": -65.1897,
    "country": "Venezuela",
    "iso2": "VE",
    "iso3": "VEN",
    "admin_name": "Anzoátegui",
    "capital": "minor",
    "population": 11378,
    "id": 1862162566
  },
  {
    "city": "Gárdony",
    "city_ascii": "Gardony",
    "lat": 47.1973,
    "lng": 18.6091,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Fejér",
    "capital": "minor",
    "population": 11204,
    "id": 1348505408
  },
  {
    "city": "Hikawadai",
    "city_ascii": "Hikawadai",
    "lat": 32.5825,
    "lng": 130.6736,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Kumamoto",
    "capital": "",
    "population": 11278,
    "id": 1392660609
  },
  {
    "city": "East Windsor",
    "city_ascii": "East Windsor",
    "lat": 41.9049,
    "lng": -72.5672,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 11379,
    "id": 1840044807
  },
  {
    "city": "Gornozavodsk",
    "city_ascii": "Gornozavodsk",
    "lat": 58.3833,
    "lng": 58.3167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Permskiy Kray",
    "capital": "",
    "population": 11375,
    "id": 1643388843
  },
  {
    "city": "Collegedale",
    "city_ascii": "Collegedale",
    "lat": 35.0525,
    "lng": -85.0487,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 11378,
    "id": 1840014570
  },
  {
    "city": "Dent",
    "city_ascii": "Dent",
    "lat": 39.1922,
    "lng": -84.6593,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 11378,
    "id": 1840005880
  },
  {
    "city": "Herbolzheim",
    "city_ascii": "Herbolzheim",
    "lat": 48.2219,
    "lng": 7.7775,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 11065,
    "id": 1276610094
  },
  {
    "city": "Summerfield",
    "city_ascii": "Summerfield",
    "lat": 36.1973,
    "lng": -79.8997,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 11376,
    "id": 1840016139
  },
  {
    "city": "Great Harwood",
    "city_ascii": "Great Harwood",
    "lat": 53.786,
    "lng": -2.408,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Lancashire",
    "capital": "",
    "population": 10800,
    "id": 1826598586
  },
  {
    "city": "Nueva Helvecia",
    "city_ascii": "Nueva Helvecia",
    "lat": -34.2889,
    "lng": -57.2333,
    "country": "Uruguay",
    "iso2": "UY",
    "iso3": "URY",
    "admin_name": "Colonia",
    "capital": "",
    "population": 10630,
    "id": 1858393787
  },
  {
    "city": "Humahuaca",
    "city_ascii": "Humahuaca",
    "lat": -23.2054,
    "lng": -65.3505,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Jujuy",
    "capital": "minor",
    "population": 11369,
    "id": 1032904210
  },
  {
    "city": "Ogose",
    "city_ascii": "Ogose",
    "lat": 35.9644,
    "lng": 139.2942,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Saitama",
    "capital": "",
    "population": 11098,
    "id": 1392027157
  },
  {
    "city": "Oxted",
    "city_ascii": "Oxted",
    "lat": 51.257,
    "lng": -0.006,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Surrey",
    "capital": "",
    "population": 11314,
    "id": 1826650120
  },
  {
    "city": "Lang Suan",
    "city_ascii": "Lang Suan",
    "lat": 9.9519,
    "lng": 99.0813,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Chumphon",
    "capital": "minor",
    "population": 11250,
    "id": 1764676997
  },
  {
    "city": "Yangirabot",
    "city_ascii": "Yangirabot",
    "lat": 40.0333,
    "lng": 65.9667,
    "country": "Uzbekistan",
    "iso2": "UZ",
    "iso3": "UZB",
    "admin_name": "Navoiy",
    "capital": "minor",
    "population": 11364,
    "id": 1860058903
  },
  {
    "city": "Soham",
    "city_ascii": "Soham",
    "lat": 52.3338,
    "lng": 0.3361,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Cambridgeshire",
    "capital": "",
    "population": 10860,
    "id": 1826606528
  },
  {
    "city": "Snyder",
    "city_ascii": "Snyder",
    "lat": 32.7133,
    "lng": -100.9113,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 11371,
    "id": 1840022059
  },
  {
    "city": "Jeseník",
    "city_ascii": "Jesenik",
    "lat": 50.2294,
    "lng": 17.2047,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Olomoucký Kraj",
    "capital": "",
    "population": 11081,
    "id": 1203267061
  },
  {
    "city": "Tarrytown",
    "city_ascii": "Tarrytown",
    "lat": 41.0647,
    "lng": -73.8673,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 11370,
    "id": 1840004945
  },
  {
    "city": "South Normanton",
    "city_ascii": "South Normanton",
    "lat": 53.107,
    "lng": -1.343,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Derbyshire",
    "capital": "",
    "population": 9445,
    "id": 1826448406
  },
  {
    "city": "Caracaraí",
    "city_ascii": "Caracarai",
    "lat": 1.8162,
    "lng": -61.1277,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Roraima",
    "capital": "",
    "population": 11368,
    "id": 1076314585
  },
  {
    "city": "Fairfield",
    "city_ascii": "Fairfield",
    "lat": 41.0061,
    "lng": -91.9669,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Iowa",
    "capital": "",
    "population": 11367,
    "id": 1840000887
  },
  {
    "city": "Mount Sterling",
    "city_ascii": "Mount Sterling",
    "lat": 38.0648,
    "lng": -83.9472,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kentucky",
    "capital": "",
    "population": 11363,
    "id": 1840014319
  },
  {
    "city": "Belén",
    "city_ascii": "Belen",
    "lat": -27.6496,
    "lng": -67.0333,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Catamarca",
    "capital": "minor",
    "population": 11359,
    "id": 1032969950
  },
  {
    "city": "Ravenna",
    "city_ascii": "Ravenna",
    "lat": 41.1613,
    "lng": -81.2421,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 11361,
    "id": 1840009317
  },
  {
    "city": "Bellmawr",
    "city_ascii": "Bellmawr",
    "lat": 39.8665,
    "lng": -75.0941,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 11359,
    "id": 1840000731
  },
  {
    "city": "Makoua",
    "city_ascii": "Makoua",
    "lat": -0.0047,
    "lng": 15.6228,
    "country": "Congo (Brazzaville)",
    "iso2": "CG",
    "iso3": "COG",
    "admin_name": "Cuvette",
    "capital": "",
    "population": 11355,
    "id": 1178500492
  },
  {
    "city": "Angola",
    "city_ascii": "Angola",
    "lat": 41.6433,
    "lng": -85.0051,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 11352,
    "id": 1840007084
  },
  {
    "city": "Alatyr",
    "city_ascii": "Alatyr",
    "lat": 54.85,
    "lng": 46.5833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Chuvashiya",
    "capital": "",
    "population": 11086,
    "id": 1643942115
  },
  {
    "city": "Doctor Phillips",
    "city_ascii": "Doctor Phillips",
    "lat": 28.4476,
    "lng": -81.4922,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 11347,
    "id": 1840013119
  },
  {
    "city": "Coshocton",
    "city_ascii": "Coshocton",
    "lat": 40.2618,
    "lng": -81.848,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 11347,
    "id": 1840003692
  },
  {
    "city": "Kennebunk",
    "city_ascii": "Kennebunk",
    "lat": 43.3972,
    "lng": -70.5707,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maine",
    "capital": "",
    "population": 11343,
    "id": 1840052870
  },
  {
    "city": "Mendota Heights",
    "city_ascii": "Mendota Heights",
    "lat": 44.8815,
    "lng": -93.14,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 11343,
    "id": 1840007855
  },
  {
    "city": "Groß-Enzersdorf",
    "city_ascii": "Gross-Enzersdorf",
    "lat": 48.2,
    "lng": 16.55,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Niederösterreich",
    "capital": "",
    "population": 11206,
    "id": 1040341836
  },
  {
    "city": "Wantage",
    "city_ascii": "Wantage",
    "lat": 51.589,
    "lng": -1.427,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Oxfordshire",
    "capital": "",
    "population": 11327,
    "id": 1826123790
  },
  {
    "city": "Haysville",
    "city_ascii": "Haysville",
    "lat": 37.5649,
    "lng": -97.3527,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kansas",
    "capital": "",
    "population": 11338,
    "id": 1840007568
  },
  {
    "city": "Tega Cay",
    "city_ascii": "Tega Cay",
    "lat": 35.039,
    "lng": -81.011,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 11335,
    "id": 1840015488
  },
  {
    "city": "Mexico",
    "city_ascii": "Mexico",
    "lat": 39.1625,
    "lng": -91.8711,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 11330,
    "id": 1840008515
  },
  {
    "city": "Lansing",
    "city_ascii": "Lansing",
    "lat": 42.5667,
    "lng": -76.5316,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 11329,
    "id": 1840004544
  },
  {
    "city": "Eisenberg",
    "city_ascii": "Eisenberg",
    "lat": 50.9667,
    "lng": 11.9,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Thuringia",
    "capital": "minor",
    "population": 10885,
    "id": 1276013201
  },
  {
    "city": "Imuris",
    "city_ascii": "Imuris",
    "lat": 30.7667,
    "lng": -110.8333,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Sonora",
    "capital": "minor",
    "population": 9921,
    "id": 1484234881
  },
  {
    "city": "Hucclecote",
    "city_ascii": "Hucclecote",
    "lat": 51.85,
    "lng": -2.18,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Gloucestershire",
    "capital": "",
    "population": 10158,
    "id": 1826233272
  },
  {
    "city": "Etropole",
    "city_ascii": "Etropole",
    "lat": 42.8333,
    "lng": 24,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Sofia",
    "capital": "",
    "population": 11292,
    "id": 1100554574
  },
  {
    "city": "Maryport",
    "city_ascii": "Maryport",
    "lat": 54.7128,
    "lng": -3.4926,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Cumbria",
    "capital": "",
    "population": 11262,
    "id": 1826196892
  },
  {
    "city": "Burtonwood",
    "city_ascii": "Burtonwood",
    "lat": 53.4302,
    "lng": -2.6614,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Warrington",
    "capital": "",
    "population": 11265,
    "id": 1826767649
  },
  {
    "city": "Evanston",
    "city_ascii": "Evanston",
    "lat": 41.2602,
    "lng": -110.9646,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wyoming",
    "capital": "",
    "population": 11319,
    "id": 1840020122
  },
  {
    "city": "Mountain Top",
    "city_ascii": "Mountain Top",
    "lat": 41.1353,
    "lng": -75.9045,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 11319,
    "id": 1840004911
  },
  {
    "city": "Port Salerno",
    "city_ascii": "Port Salerno",
    "lat": 27.1461,
    "lng": -80.1895,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 11317,
    "id": 1840014193
  },
  {
    "city": "Haddonfield",
    "city_ascii": "Haddonfield",
    "lat": 39.8955,
    "lng": -75.0346,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 11317,
    "id": 1840016699
  },
  {
    "city": "Rychnov nad Kněžnou",
    "city_ascii": "Rychnov nad Kneznou",
    "lat": 50.1629,
    "lng": 16.275,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Královéhradecký Kraj",
    "capital": "",
    "population": 10999,
    "id": 1203123765
  },
  {
    "city": "Urbana",
    "city_ascii": "Urbana",
    "lat": 40.1085,
    "lng": -83.7542,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 11313,
    "id": 1840010488
  },
  {
    "city": "Fiľakovo",
    "city_ascii": "Fil'akovo",
    "lat": 48.2719,
    "lng": 19.8286,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Banskobystrický",
    "capital": "",
    "population": 10654,
    "id": 1703493042
  },
  {
    "city": "Illintsi",
    "city_ascii": "Illintsi",
    "lat": 49.1,
    "lng": 29.2,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Vinnyts’ka Oblast’",
    "capital": "minor",
    "population": 11270,
    "id": 1804230677
  },
  {
    "city": "Beachwood",
    "city_ascii": "Beachwood",
    "lat": 39.9286,
    "lng": -74.2023,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 11312,
    "id": 1840001483
  },
  {
    "city": "Bradford",
    "city_ascii": "Bradford",
    "lat": 41.9604,
    "lng": -78.6414,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 11311,
    "id": 1840000523
  },
  {
    "city": "Byerazino",
    "city_ascii": "Byerazino",
    "lat": 53.8333,
    "lng": 28.9833,
    "country": "Belarus",
    "iso2": "BY",
    "iso3": "BLR",
    "admin_name": "Minskaya Voblasts’",
    "capital": "",
    "population": 11300,
    "id": 1112685859
  },
  {
    "city": "Amherst",
    "city_ascii": "Amherst",
    "lat": 42.8705,
    "lng": -71.6068,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Hampshire",
    "capital": "",
    "population": 11310,
    "id": 1840054510
  },
  {
    "city": "North Vernon",
    "city_ascii": "North Vernon",
    "lat": 39.017,
    "lng": -85.6317,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 11310,
    "id": 1840008549
  },
  {
    "city": "Samford",
    "city_ascii": "Samford",
    "lat": -27.3667,
    "lng": 152.8833,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Queensland",
    "capital": "",
    "population": 10931,
    "id": 1036810408
  },
  {
    "city": "Nhandeara",
    "city_ascii": "Nhandeara",
    "lat": -20.6939,
    "lng": -50.0378,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 11303,
    "id": 1076817297
  },
  {
    "city": "Štúrovo",
    "city_ascii": "Sturovo",
    "lat": 47.7992,
    "lng": 18.7181,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Nitriansky",
    "capital": "",
    "population": 10524,
    "id": 1703382552
  },
  {
    "city": "Granby",
    "city_ascii": "Granby",
    "lat": 41.9694,
    "lng": -72.8346,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 11305,
    "id": 1840004831
  },
  {
    "city": "Getulina",
    "city_ascii": "Getulina",
    "lat": -21.7986,
    "lng": -49.9286,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 11287,
    "id": 1076426209
  },
  {
    "city": "Groton",
    "city_ascii": "Groton",
    "lat": 42.6137,
    "lng": -71.5614,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 11301,
    "id": 1840053636
  },
  {
    "city": "Lake Morton-Berrydale",
    "city_ascii": "Lake Morton-Berrydale",
    "lat": 47.3325,
    "lng": -122.1032,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 11295,
    "id": 1840074253
  },
  {
    "city": "Lakeville",
    "city_ascii": "Lakeville",
    "lat": 41.831,
    "lng": -70.9594,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 11293,
    "id": 1840053571
  },
  {
    "city": "New Britain",
    "city_ascii": "New Britain",
    "lat": 40.3084,
    "lng": -75.2069,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 11292,
    "id": 1840001327
  },
  {
    "city": "Hakone",
    "city_ascii": "Hakone",
    "lat": 35.2325,
    "lng": 139.1069,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Kanagawa",
    "capital": "",
    "population": 11169,
    "id": 1392115612
  },
  {
    "city": "Boguchar",
    "city_ascii": "Boguchar",
    "lat": 49.9333,
    "lng": 40.55,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Voronezhskaya Oblast’",
    "capital": "",
    "population": 11270,
    "id": 1643779708
  },
  {
    "city": "Hawera",
    "city_ascii": "Hawera",
    "lat": -39.5833,
    "lng": 174.2833,
    "country": "New Zealand",
    "iso2": "NZ",
    "iso3": "NZL",
    "admin_name": "Taranaki",
    "capital": "",
    "population": 9810,
    "id": 1554872573
  },
  {
    "city": "Măgurele",
    "city_ascii": "Magurele",
    "lat": 44.3461,
    "lng": 25.9999,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Ilfov",
    "capital": "",
    "population": 11041,
    "id": 1642420938
  },
  {
    "city": "Leicester",
    "city_ascii": "Leicester",
    "lat": 42.24,
    "lng": -71.912,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 11286,
    "id": 1840053693
  },
  {
    "city": "Celina",
    "city_ascii": "Celina",
    "lat": 40.5551,
    "lng": -84.5629,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 11285,
    "id": 1840007210
  },
  {
    "city": "Hartsville",
    "city_ascii": "Hartsville",
    "lat": 36.3921,
    "lng": -86.1568,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 11284,
    "id": 1840073881
  },
  {
    "city": "Canyon Lake",
    "city_ascii": "Canyon Lake",
    "lat": 33.6885,
    "lng": -117.2621,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 11280,
    "id": 1840019301
  },
  {
    "city": "North Dundas",
    "city_ascii": "North Dundas",
    "lat": 45.0833,
    "lng": -75.35,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 11278,
    "id": 1124000474
  },
  {
    "city": "Marcali",
    "city_ascii": "Marcali",
    "lat": 46.5831,
    "lng": 17.4064,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Somogy",
    "capital": "minor",
    "population": 11169,
    "id": 1348924700
  },
  {
    "city": "Gardnerville Ranchos",
    "city_ascii": "Gardnerville Ranchos",
    "lat": 38.8872,
    "lng": -119.7426,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Nevada",
    "capital": "",
    "population": 11274,
    "id": 1840033828
  },
  {
    "city": "Sušice",
    "city_ascii": "Susice",
    "lat": 49.2312,
    "lng": 13.5202,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Plzeňský Kraj",
    "capital": "",
    "population": 11032,
    "id": 1203245330
  },
  {
    "city": "Križevci",
    "city_ascii": "Krizevci",
    "lat": 46.0333,
    "lng": 16.5333,
    "country": "Croatia",
    "iso2": "HR",
    "iso3": "HRV",
    "admin_name": "Koprivničko-Križevačka Županija",
    "capital": "minor",
    "population": 11231,
    "id": 1191282973
  },
  {
    "city": "Pirangi",
    "city_ascii": "Pirangi",
    "lat": -21.0914,
    "lng": -48.6578,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 11220,
    "id": 1076411878
  },
  {
    "city": "Lunenburg",
    "city_ascii": "Lunenburg",
    "lat": 42.5897,
    "lng": -71.7199,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 11271,
    "id": 1840053592
  },
  {
    "city": "Lighthouse Point",
    "city_ascii": "Lighthouse Point",
    "lat": 26.2785,
    "lng": -80.0891,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 11270,
    "id": 1840015998
  },
  {
    "city": "Eunice",
    "city_ascii": "Eunice",
    "lat": 30.4904,
    "lng": -92.4191,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 11269,
    "id": 1840013927
  },
  {
    "city": "Monmouth",
    "city_ascii": "Monmouth",
    "lat": 51.81,
    "lng": -2.72,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Monmouthshire",
    "capital": "",
    "population": 10508,
    "id": 1826379429
  },
  {
    "city": "Tetyushi",
    "city_ascii": "Tetyushi",
    "lat": 54.9333,
    "lng": 48.8333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Tatarstan",
    "capital": "",
    "population": 11250,
    "id": 1643097545
  },
  {
    "city": "Joquicingo",
    "city_ascii": "Joquicingo",
    "lat": 19.0556,
    "lng": -99.5467,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "México",
    "capital": "minor",
    "population": 11042,
    "id": 1484561507
  },
  {
    "city": "Santa Cruz",
    "city_ascii": "Santa Cruz",
    "lat": -0.5333,
    "lng": -90.35,
    "country": "Ecuador",
    "iso2": "EC",
    "iso3": "ECU",
    "admin_name": "Galápagos",
    "capital": "",
    "population": 11262,
    "id": 1218773967
  },
  {
    "city": "Edd",
    "city_ascii": "Edd",
    "lat": 13.9333,
    "lng": 41.7,
    "country": "Eritrea",
    "iso2": "ER",
    "iso3": "ERI",
    "admin_name": "Debubawī K’eyih Bahrī",
    "capital": "",
    "population": 11259,
    "id": 1232211688
  },
  {
    "city": "Tamalpais-Homestead Valley",
    "city_ascii": "Tamalpais-Homestead Valley",
    "lat": 37.8793,
    "lng": -122.5382,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 11261,
    "id": 1840074828
  },
  {
    "city": "Drākshārāma",
    "city_ascii": "Draksharama",
    "lat": 16.7928,
    "lng": 82.0635,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Andhra Pradesh",
    "capital": "",
    "population": 9299,
    "id": 1356061765
  },
  {
    "city": "Friedrichsthal",
    "city_ascii": "Friedrichsthal",
    "lat": 49.3256,
    "lng": 7.0961,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saarland",
    "capital": "",
    "population": 10133,
    "id": 1276137098
  },
  {
    "city": "Saltcoats",
    "city_ascii": "Saltcoats",
    "lat": 55.6352,
    "lng": -4.7896,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "North Ayrshire",
    "capital": "",
    "population": 11260,
    "id": 1826618463
  },
  {
    "city": "Wellesley",
    "city_ascii": "Wellesley",
    "lat": 43.55,
    "lng": -80.7167,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 11260,
    "id": 1124590159
  },
  {
    "city": "Capilla del Monte",
    "city_ascii": "Capilla del Monte",
    "lat": -30.85,
    "lng": -64.5167,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Córdoba",
    "capital": "",
    "population": 11181,
    "id": 1032258122
  },
  {
    "city": "Estevan",
    "city_ascii": "Estevan",
    "lat": 49.1392,
    "lng": -102.9861,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Saskatchewan",
    "capital": "",
    "population": 11258,
    "id": 1124416742
  },
  {
    "city": "Hibbing",
    "city_ascii": "Hibbing",
    "lat": 47.3981,
    "lng": -92.9487,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 11257,
    "id": 1840006594
  },
  {
    "city": "Fairview Shores",
    "city_ascii": "Fairview Shores",
    "lat": 28.602,
    "lng": -81.3948,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 11254,
    "id": 1840013120
  },
  {
    "city": "Portland",
    "city_ascii": "Portland",
    "lat": -38.3333,
    "lng": 141.6,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Victoria",
    "capital": "",
    "population": 10900,
    "id": 1036439594
  },
  {
    "city": "Barton upon Humber",
    "city_ascii": "Barton upon Humber",
    "lat": 53.6833,
    "lng": -0.45,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "North Lincolnshire",
    "capital": "",
    "population": 11066,
    "id": 1826911147
  },
  {
    "city": "North Saanich",
    "city_ascii": "North Saanich",
    "lat": 48.6142,
    "lng": -123.42,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "British Columbia",
    "capital": "",
    "population": 11249,
    "id": 1124000779
  },
  {
    "city": "Lauingen",
    "city_ascii": "Lauingen",
    "lat": 48.5667,
    "lng": 10.4333,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 11000,
    "id": 1276983214
  },
  {
    "city": "Otar",
    "city_ascii": "Otar",
    "lat": 43.5346,
    "lng": 75.2139,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Zhambyl",
    "capital": "",
    "population": 11238,
    "id": 1398243169
  },
  {
    "city": "Bewdley",
    "city_ascii": "Bewdley",
    "lat": 52.3752,
    "lng": -2.3162,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Worcestershire",
    "capital": "",
    "population": 9470,
    "id": 1826282948
  },
  {
    "city": "Lamesa",
    "city_ascii": "Lamesa",
    "lat": 32.7333,
    "lng": -101.9542,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 11241,
    "id": 1840020725
  },
  {
    "city": "DuBois",
    "city_ascii": "DuBois",
    "lat": 41.1225,
    "lng": -78.7564,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 11241,
    "id": 1840035398
  },
  {
    "city": "Korablino",
    "city_ascii": "Korablino",
    "lat": 53.9167,
    "lng": 40.0167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Ryazanskaya Oblast’",
    "capital": "",
    "population": 11220,
    "id": 1643006109
  },
  {
    "city": "Berlin",
    "city_ascii": "Berlin",
    "lat": 44.4869,
    "lng": -71.2599,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Hampshire",
    "capital": "",
    "population": 11237,
    "id": 1840002100
  },
  {
    "city": "North Logan",
    "city_ascii": "North Logan",
    "lat": 41.7759,
    "lng": -111.8066,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Utah",
    "capital": "",
    "population": 11237,
    "id": 1840020114
  },
  {
    "city": "Miyato",
    "city_ascii": "Miyato",
    "lat": 36.1833,
    "lng": 139.183,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Saitama",
    "capital": "",
    "population": 10909,
    "id": 1392417076
  },
  {
    "city": "Waupun",
    "city_ascii": "Waupun",
    "lat": 43.6315,
    "lng": -88.738,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 11232,
    "id": 1840002600
  },
  {
    "city": "Kuršėnai",
    "city_ascii": "Kursenai",
    "lat": 55.9979,
    "lng": 22.9415,
    "country": "Lithuania",
    "iso2": "LT",
    "iso3": "LTU",
    "admin_name": "Šiauliai",
    "capital": "",
    "population": 11175,
    "id": 1440741018
  },
  {
    "city": "Týrnavos",
    "city_ascii": "Tyrnavos",
    "lat": 39.7353,
    "lng": 22.2869,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Thessalía",
    "capital": "minor",
    "population": 11069,
    "id": 1300449251
  },
  {
    "city": "Mango",
    "city_ascii": "Mango",
    "lat": 27.9915,
    "lng": -82.307,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 11228,
    "id": 1840014157
  },
  {
    "city": "Leers",
    "city_ascii": "Leers",
    "lat": 50.6817,
    "lng": 3.2439,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Hauts-de-France",
    "capital": "",
    "population": 9473,
    "id": 1250179174
  },
  {
    "city": "Big Lake",
    "city_ascii": "Big Lake",
    "lat": 45.3416,
    "lng": -93.7431,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 11226,
    "id": 1840006701
  },
  {
    "city": "Hillsdale",
    "city_ascii": "Hillsdale",
    "lat": 41.9266,
    "lng": -84.6356,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 11223,
    "id": 1840003252
  },
  {
    "city": "San José de Aerocuar",
    "city_ascii": "San Jose de Aerocuar",
    "lat": 10.6014,
    "lng": -63.3278,
    "country": "Venezuela",
    "iso2": "VE",
    "iso3": "VEN",
    "admin_name": "Sucre",
    "capital": "minor",
    "population": 11162,
    "id": 1862054804
  },
  {
    "city": "Jamapa",
    "city_ascii": "Jamapa",
    "lat": 19.04,
    "lng": -96.24,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Veracruz",
    "capital": "minor",
    "population": 9772,
    "id": 1484467927
  },
  {
    "city": "Gloucester City",
    "city_ascii": "Gloucester City",
    "lat": 39.8924,
    "lng": -75.1172,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 11219,
    "id": 1840003777
  },
  {
    "city": "Chulym",
    "city_ascii": "Chulym",
    "lat": 55.1,
    "lng": 80.9667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Novosibirskaya Oblast’",
    "capital": "",
    "population": 11216,
    "id": 1643503284
  },
  {
    "city": "Qaşr-e Shīrīn",
    "city_ascii": "Qasr-e Shirin",
    "lat": 34.5156,
    "lng": 45.5792,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Kermānshāh",
    "capital": "minor",
    "population": 11202,
    "id": 1364110689
  },
  {
    "city": "Mitchellville",
    "city_ascii": "Mitchellville",
    "lat": 38.9358,
    "lng": -76.8146,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 11215,
    "id": 1840005951
  },
  {
    "city": "Staryya Darohi",
    "city_ascii": "Staryya Darohi",
    "lat": 53.0394,
    "lng": 28.265,
    "country": "Belarus",
    "iso2": "BY",
    "iso3": "BLR",
    "admin_name": "Minskaya Voblasts’",
    "capital": "minor",
    "population": 11200,
    "id": 1112727949
  },
  {
    "city": "Emsworth",
    "city_ascii": "Emsworth",
    "lat": 50.849,
    "lng": -0.938,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hampshire",
    "capital": "",
    "population": 9492,
    "id": 1826718241
  },
  {
    "city": "Braunfels",
    "city_ascii": "Braunfels",
    "lat": 50.5175,
    "lng": 8.3889,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 10976,
    "id": 1276721051
  },
  {
    "city": "Lorch",
    "city_ascii": "Lorch",
    "lat": 48.7983,
    "lng": 9.6883,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 10885,
    "id": 1276178171
  },
  {
    "city": "Shiloh",
    "city_ascii": "Shiloh",
    "lat": 39.9734,
    "lng": -76.792,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 11206,
    "id": 1840035195
  },
  {
    "city": "Gengenbach",
    "city_ascii": "Gengenbach",
    "lat": 48.4042,
    "lng": 8.0153,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 11023,
    "id": 1276778224
  },
  {
    "city": "Trofaiach",
    "city_ascii": "Trofaiach",
    "lat": 47.4261,
    "lng": 15.0067,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Steiermark",
    "capital": "",
    "population": 11125,
    "id": 1040300354
  },
  {
    "city": "Veselí nad Moravou",
    "city_ascii": "Veseli nad Moravou",
    "lat": 48.9536,
    "lng": 17.3765,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Jihomoravský Kraj",
    "capital": "",
    "population": 10889,
    "id": 1203951522
  },
  {
    "city": "Ebreichsdorf",
    "city_ascii": "Ebreichsdorf",
    "lat": 47.9611,
    "lng": 16.4047,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Niederösterreich",
    "capital": "",
    "population": 10942,
    "id": 1040140481
  },
  {
    "city": "Belzig",
    "city_ascii": "Belzig",
    "lat": 52.1422,
    "lng": 12.5956,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Brandenburg",
    "capital": "",
    "population": 11144,
    "id": 1276375390
  },
  {
    "city": "Camp Verde",
    "city_ascii": "Camp Verde",
    "lat": 34.5699,
    "lng": -111.8573,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 11187,
    "id": 1840022752
  },
  {
    "city": "Ilfracombe",
    "city_ascii": "Ilfracombe",
    "lat": 51.208,
    "lng": -4.12,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Devon",
    "capital": "",
    "population": 11184,
    "id": 1826182553
  },
  {
    "city": "Humpolec",
    "city_ascii": "Humpolec",
    "lat": 49.5416,
    "lng": 15.3594,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Vysočina",
    "capital": "",
    "population": 10970,
    "id": 1203780100
  },
  {
    "city": "Viera East",
    "city_ascii": "Viera East",
    "lat": 28.261,
    "lng": -80.715,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 11183,
    "id": 1840039118
  },
  {
    "city": "Weddington",
    "city_ascii": "Weddington",
    "lat": 35.0228,
    "lng": -80.7383,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 11182,
    "id": 1840016459
  },
  {
    "city": "Red Hook",
    "city_ascii": "Red Hook",
    "lat": 42.0188,
    "lng": -73.8788,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 11178,
    "id": 1840004752
  },
  {
    "city": "Areiópolis",
    "city_ascii": "Areiopolis",
    "lat": -22.6681,
    "lng": -48.665,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 11049,
    "id": 1076875790
  },
  {
    "city": "Hârlău",
    "city_ascii": "Harlau",
    "lat": 47.4278,
    "lng": 26.9114,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Iaşi",
    "capital": "",
    "population": 10905,
    "id": 1642634665
  },
  {
    "city": "Show Low",
    "city_ascii": "Show Low",
    "lat": 34.2671,
    "lng": -110.0384,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 11173,
    "id": 1840021582
  },
  {
    "city": "Matsuda-sōryō",
    "city_ascii": "Matsuda-soryo",
    "lat": 35.3483,
    "lng": 139.1394,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Kanagawa",
    "capital": "",
    "population": 10884,
    "id": 1392523691
  },
  {
    "city": "Ridgefield",
    "city_ascii": "Ridgefield",
    "lat": 40.8313,
    "lng": -74.0147,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 11171,
    "id": 1840000897
  },
  {
    "city": "Vorë",
    "city_ascii": "Vore",
    "lat": 41.3939,
    "lng": 19.6544,
    "country": "Albania",
    "iso2": "AL",
    "iso3": "ALB",
    "admin_name": "Tiranë",
    "capital": "",
    "population": 10901,
    "id": 1008893438
  },
  {
    "city": "Aguadilla",
    "city_ascii": "Aguadilla",
    "lat": 18.4382,
    "lng": -67.1536,
    "country": "Puerto Rico",
    "iso2": "PR",
    "iso3": "PRI",
    "admin_name": "Puerto Rico",
    "capital": "",
    "population": 11170,
    "id": 1630035605
  },
  {
    "city": "Regen",
    "city_ascii": "Regen",
    "lat": 48.97,
    "lng": 13.1264,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "minor",
    "population": 11001,
    "id": 1276612143
  },
  {
    "city": "Knezha",
    "city_ascii": "Knezha",
    "lat": 43.5,
    "lng": 24.0833,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Vratsa",
    "capital": "minor",
    "population": 11124,
    "id": 1100486510
  },
  {
    "city": "Poulsbo",
    "city_ascii": "Poulsbo",
    "lat": 47.7417,
    "lng": -122.6407,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 11168,
    "id": 1840019822
  },
  {
    "city": "Miastko",
    "city_ascii": "Miastko",
    "lat": 54,
    "lng": 16.9833,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Pomorskie",
    "capital": "",
    "population": 11123,
    "id": 1616803904
  },
  {
    "city": "Zakamensk",
    "city_ascii": "Zakamensk",
    "lat": 50.3833,
    "lng": 103.2833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Buryatiya",
    "capital": "",
    "population": 11164,
    "id": 1643401848
  },
  {
    "city": "Springdale",
    "city_ascii": "Springdale",
    "lat": 39.2909,
    "lng": -84.476,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 11166,
    "id": 1840001615
  },
  {
    "city": "Stayton",
    "city_ascii": "Stayton",
    "lat": 44.8033,
    "lng": -122.7983,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 11166,
    "id": 1840021227
  },
  {
    "city": "Tinchlik",
    "city_ascii": "Tinchlik",
    "lat": 40.4264,
    "lng": 71.4956,
    "country": "Uzbekistan",
    "iso2": "UZ",
    "iso3": "UZB",
    "admin_name": "Farg‘ona",
    "capital": "",
    "population": 10644,
    "id": 1860903091
  },
  {
    "city": "Brookings",
    "city_ascii": "Brookings",
    "lat": 42.0697,
    "lng": -124.3003,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 11162,
    "id": 1840018677
  },
  {
    "city": "Osterwieck",
    "city_ascii": "Osterwieck",
    "lat": 51.9667,
    "lng": 10.7167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony-Anhalt",
    "capital": "",
    "population": 11103,
    "id": 1276571992
  },
  {
    "city": "Betzdorf",
    "city_ascii": "Betzdorf",
    "lat": 50.7856,
    "lng": 7.8728,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Rhineland-Palatinate",
    "capital": "",
    "population": 10141,
    "id": 1276302333
  },
  {
    "city": "Baley",
    "city_ascii": "Baley",
    "lat": 51.5833,
    "lng": 116.6333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Zabaykal’skiy Kray",
    "capital": "",
    "population": 11151,
    "id": 1643419393
  },
  {
    "city": "Jeumont",
    "city_ascii": "Jeumont",
    "lat": 50.2944,
    "lng": 4.1014,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Hauts-de-France",
    "capital": "",
    "population": 10159,
    "id": 1250978042
  },
  {
    "city": "Broomall",
    "city_ascii": "Broomall",
    "lat": 39.9694,
    "lng": -75.3529,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 11154,
    "id": 1840005549
  },
  {
    "city": "Shepperton",
    "city_ascii": "Shepperton",
    "lat": 51.39,
    "lng": -0.46,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Surrey",
    "capital": "",
    "population": 9753,
    "id": 1826632972
  },
  {
    "city": "Flöha",
    "city_ascii": "Floha",
    "lat": 50.8558,
    "lng": 13.0714,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 10762,
    "id": 1276141987
  },
  {
    "city": "Niles",
    "city_ascii": "Niles",
    "lat": 41.8346,
    "lng": -86.2473,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 11149,
    "id": 1840003212
  },
  {
    "city": "Wisła",
    "city_ascii": "Wisla",
    "lat": 49.6667,
    "lng": 18.8667,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Śląskie",
    "capital": "",
    "population": 11048,
    "id": 1616358703
  },
  {
    "city": "Khlung",
    "city_ascii": "Khlung",
    "lat": 12.4547,
    "lng": 102.2214,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Chanthaburi",
    "capital": "minor",
    "population": 11073,
    "id": 1764654331
  },
  {
    "city": "Şā’īn Qal‘eh",
    "city_ascii": "Sa'in Qal`eh",
    "lat": 36.2994,
    "lng": 49.0744,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Zanjān",
    "capital": "",
    "population": 11083,
    "id": 1364854942
  },
  {
    "city": "Ogano",
    "city_ascii": "Ogano",
    "lat": 36.0172,
    "lng": 139.0086,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Saitama",
    "capital": "",
    "population": 11078,
    "id": 1392226506
  },
  {
    "city": "Tolna",
    "city_ascii": "Tolna",
    "lat": 46.4167,
    "lng": 18.7833,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Tolna",
    "capital": "minor",
    "population": 10987,
    "id": 1348510202
  },
  {
    "city": "Kirensk",
    "city_ascii": "Kirensk",
    "lat": 57.7833,
    "lng": 108.1,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Irkutskaya Oblast’",
    "capital": "",
    "population": 11139,
    "id": 1643494440
  },
  {
    "city": "Somers",
    "city_ascii": "Somers",
    "lat": 41.9949,
    "lng": -72.453,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 11137,
    "id": 1840033631
  },
  {
    "city": "Fitzgerald",
    "city_ascii": "Fitzgerald",
    "lat": 31.7135,
    "lng": -83.2515,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 11135,
    "id": 1840013855
  },
  {
    "city": "Canton",
    "city_ascii": "Canton",
    "lat": 44.5802,
    "lng": -75.1978,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 11134,
    "id": 1840004065
  },
  {
    "city": "Huntingdon",
    "city_ascii": "Huntingdon",
    "lat": 40.5,
    "lng": -78.0097,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 11134,
    "id": 1840001068
  },
  {
    "city": "Selma",
    "city_ascii": "Selma",
    "lat": 29.5866,
    "lng": -98.3133,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 11132,
    "id": 1840022221
  },
  {
    "city": "Warka",
    "city_ascii": "Warka",
    "lat": 51.7833,
    "lng": 21.2,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Mazowieckie",
    "capital": "",
    "population": 11035,
    "id": 1616212108
  },
  {
    "city": "Satellite Beach",
    "city_ascii": "Satellite Beach",
    "lat": 28.1782,
    "lng": -80.6019,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 11130,
    "id": 1840015961
  },
  {
    "city": "Rostraver",
    "city_ascii": "Rostraver",
    "lat": 40.1686,
    "lng": -79.8086,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 11127,
    "id": 1840144128
  },
  {
    "city": "Hammelburg",
    "city_ascii": "Hammelburg",
    "lat": 50.1167,
    "lng": 9.9,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 11037,
    "id": 1276000245
  },
  {
    "city": "Charlton Kings",
    "city_ascii": "Charlton Kings",
    "lat": 51.8877,
    "lng": -2.0413,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Gloucestershire",
    "capital": "",
    "population": 10396,
    "id": 1826345759
  },
  {
    "city": "Mayfield",
    "city_ascii": "Mayfield",
    "lat": 36.737,
    "lng": -88.6446,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kentucky",
    "capital": "",
    "population": 11121,
    "id": 1840015268
  },
  {
    "city": "Aydıncık",
    "city_ascii": "Aydincik",
    "lat": 36.1667,
    "lng": 33.35,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Mersin",
    "capital": "minor",
    "population": 11088,
    "id": 1792846540
  },
  {
    "city": "Franklin Lakes",
    "city_ascii": "Franklin Lakes",
    "lat": 41.0086,
    "lng": -74.2083,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 11119,
    "id": 1840003553
  },
  {
    "city": "Prachatice",
    "city_ascii": "Prachatice",
    "lat": 49.013,
    "lng": 13.9975,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Jihočeský Kraj",
    "capital": "",
    "population": 10840,
    "id": 1203326615
  },
  {
    "city": "Bischofswerda",
    "city_ascii": "Bischofswerda",
    "lat": 51.1275,
    "lng": 14.1797,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 10883,
    "id": 1276164300
  },
  {
    "city": "San Germán",
    "city_ascii": "San German",
    "lat": 18.0827,
    "lng": -67.046,
    "country": "Puerto Rico",
    "iso2": "PR",
    "iso3": "PRI",
    "admin_name": "Puerto Rico",
    "capital": "",
    "population": 11117,
    "id": 1630023526
  },
  {
    "city": "Chicureo Abajo",
    "city_ascii": "Chicureo Abajo",
    "lat": -33.2808,
    "lng": -70.6366,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Región Metropolitana",
    "capital": "",
    "population": 10975,
    "id": 1152232267
  },
  {
    "city": "Viera West",
    "city_ascii": "Viera West",
    "lat": 28.243,
    "lng": -80.7368,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 11116,
    "id": 1840039133
  },
  {
    "city": "East Hanover",
    "city_ascii": "East Hanover",
    "lat": 40.8192,
    "lng": -74.3638,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 11116,
    "id": 1840081722
  },
  {
    "city": "Skelton",
    "city_ascii": "Skelton",
    "lat": 54.5619,
    "lng": -0.9874,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Redcar and Cleveland",
    "capital": "",
    "population": 9526,
    "id": 1826892783
  },
  {
    "city": "Lowes Island",
    "city_ascii": "Lowes Island",
    "lat": 39.0471,
    "lng": -77.3524,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 11111,
    "id": 1840024504
  },
  {
    "city": "Yorktown",
    "city_ascii": "Yorktown",
    "lat": 40.183,
    "lng": -85.5123,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 11111,
    "id": 1840010463
  },
  {
    "city": "Town and Country",
    "city_ascii": "Town and Country",
    "lat": 38.6317,
    "lng": -90.479,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 11109,
    "id": 1840010738
  },
  {
    "city": "Nazyvayevsk",
    "city_ascii": "Nazyvayevsk",
    "lat": 55.5667,
    "lng": 71.35,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Omskaya Oblast’",
    "capital": "",
    "population": 11101,
    "id": 1643639751
  },
  {
    "city": "Parapuã",
    "city_ascii": "Parapua",
    "lat": -21.7681,
    "lng": -50.7717,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 11098,
    "id": 1076691844
  },
  {
    "city": "Panguipulli",
    "city_ascii": "Panguipulli",
    "lat": -39.6419,
    "lng": -72.3334,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Los Ríos",
    "capital": "",
    "population": 11091,
    "id": 1152158717
  },
  {
    "city": "Fulton",
    "city_ascii": "Fulton",
    "lat": 43.3171,
    "lng": -76.4162,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 11102,
    "id": 1840000347
  },
  {
    "city": "Ōtsuchi",
    "city_ascii": "Otsuchi",
    "lat": 39.3597,
    "lng": 141.9064,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Iwate",
    "capital": "",
    "population": 11046,
    "id": 1392244776
  },
  {
    "city": "Jefferson Hills",
    "city_ascii": "Jefferson Hills",
    "lat": 40.2927,
    "lng": -79.9329,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 11101,
    "id": 1840001280
  },
  {
    "city": "Nantucket",
    "city_ascii": "Nantucket",
    "lat": 41.2831,
    "lng": -70.0692,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 11101,
    "id": 1840053617
  },
  {
    "city": "Princess Anne",
    "city_ascii": "Princess Anne",
    "lat": 38.2054,
    "lng": -75.6969,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 11101,
    "id": 1840006279
  },
  {
    "city": "Pedernales",
    "city_ascii": "Pedernales",
    "lat": 18.038,
    "lng": -71.741,
    "country": "Dominican Republic",
    "iso2": "DO",
    "iso3": "DOM",
    "admin_name": "Enriquillo",
    "capital": "minor",
    "population": 11072,
    "id": 1214415453
  },
  {
    "city": "Lindon",
    "city_ascii": "Lindon",
    "lat": 40.3414,
    "lng": -111.7187,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Utah",
    "capital": "",
    "population": 11100,
    "id": 1840020169
  },
  {
    "city": "Stannington",
    "city_ascii": "Stannington",
    "lat": 53.396,
    "lng": -1.536,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Sheffield",
    "capital": "",
    "population": 10747,
    "id": 1826474759
  },
  {
    "city": "Al ‘Amādīyah",
    "city_ascii": "Al `Amadiyah",
    "lat": 37.0922,
    "lng": 43.4878,
    "country": "Iraq",
    "iso2": "IQ",
    "iso3": "IRQ",
    "admin_name": "Dahūk",
    "capital": "minor",
    "population": 11000,
    "id": 1368510045
  },
  {
    "city": "Vilyuysk",
    "city_ascii": "Vilyuysk",
    "lat": 63.75,
    "lng": 121.6167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sakha (Yakutiya)",
    "capital": "",
    "population": 11095,
    "id": 1643430483
  },
  {
    "city": "Maysville",
    "city_ascii": "Maysville",
    "lat": 38.6454,
    "lng": -83.7911,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kentucky",
    "capital": "",
    "population": 11093,
    "id": 1840015180
  },
  {
    "city": "Campton Hills",
    "city_ascii": "Campton Hills",
    "lat": 41.9499,
    "lng": -88.4166,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 11091,
    "id": 1840000495
  },
  {
    "city": "Woodbury",
    "city_ascii": "Woodbury",
    "lat": 41.3284,
    "lng": -74.1004,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 11089,
    "id": 1840004883
  },
  {
    "city": "Rifle",
    "city_ascii": "Rifle",
    "lat": 39.5362,
    "lng": -107.7729,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 11089,
    "id": 1840020185
  },
  {
    "city": "Narimanov",
    "city_ascii": "Narimanov",
    "lat": 46.6833,
    "lng": 47.85,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Astrakhanskaya Oblast’",
    "capital": "",
    "population": 11079,
    "id": 1643669598
  },
  {
    "city": "Iacanga",
    "city_ascii": "Iacanga",
    "lat": -21.89,
    "lng": -49.0247,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 11066,
    "id": 1076775071
  },
  {
    "city": "Bennettsville",
    "city_ascii": "Bennettsville",
    "lat": 34.6303,
    "lng": -79.6874,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 11086,
    "id": 1840013583
  },
  {
    "city": "Stropkov",
    "city_ascii": "Stropkov",
    "lat": 49.205,
    "lng": 21.6514,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Prešovský",
    "capital": "minor",
    "population": 10654,
    "id": 1703240636
  },
  {
    "city": "Walden",
    "city_ascii": "Walden",
    "lat": 41.5603,
    "lng": -74.1879,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 11085,
    "id": 1840004880
  },
  {
    "city": "Grove City",
    "city_ascii": "Grove City",
    "lat": 41.1572,
    "lng": -80.0894,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 11085,
    "id": 1840000660
  },
  {
    "city": "Burgstädt",
    "city_ascii": "Burgstadt",
    "lat": 50.9167,
    "lng": 12.8167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 10672,
    "id": 1276198411
  },
  {
    "city": "Sankt Johann im Pongau",
    "city_ascii": "Sankt Johann im Pongau",
    "lat": 47.35,
    "lng": 13.2,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Salzburg",
    "capital": "minor",
    "population": 10944,
    "id": 1040330095
  },
  {
    "city": "Hamilton Township",
    "city_ascii": "Hamilton Township",
    "lat": 39.9432,
    "lng": -77.7327,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 11084,
    "id": 1840147456
  },
  {
    "city": "Merriam",
    "city_ascii": "Merriam",
    "lat": 39.0186,
    "lng": -94.6933,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kansas",
    "capital": "",
    "population": 11081,
    "id": 1840003830
  },
  {
    "city": "Olbernhau",
    "city_ascii": "Olbernhau",
    "lat": 50.6667,
    "lng": 13.3333,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 10991,
    "id": 1276224877
  },
  {
    "city": "Westwood",
    "city_ascii": "Westwood",
    "lat": 40.9878,
    "lng": -74.0308,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 11078,
    "id": 1840000923
  },
  {
    "city": "Lower Burrell",
    "city_ascii": "Lower Burrell",
    "lat": 40.5818,
    "lng": -79.7141,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 11078,
    "id": 1840001132
  },
  {
    "city": "Kaneohe Station",
    "city_ascii": "Kaneohe Station",
    "lat": 21.4451,
    "lng": -157.7515,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Hawaii",
    "capital": "",
    "population": 11075,
    "id": 1840075025
  },
  {
    "city": "Sigatoka",
    "city_ascii": "Sigatoka",
    "lat": -18.15,
    "lng": 177.5,
    "country": "Fiji",
    "iso2": "FJ",
    "iso3": "FJI",
    "admin_name": "Nadroga and Navosa",
    "capital": "",
    "population": 9622,
    "id": 1242813237
  },
  {
    "city": "Westview",
    "city_ascii": "Westview",
    "lat": 25.8826,
    "lng": -80.2415,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 11068,
    "id": 1840029098
  },
  {
    "city": "Silverton",
    "city_ascii": "Silverton",
    "lat": 45.0031,
    "lng": -122.7809,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 11068,
    "id": 1840021226
  },
  {
    "city": "Wahpeton",
    "city_ascii": "Wahpeton",
    "lat": 46.2722,
    "lng": -96.6118,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Dakota",
    "capital": "",
    "population": 11067,
    "id": 1840000228
  },
  {
    "city": "Storm Lake",
    "city_ascii": "Storm Lake",
    "lat": 42.6431,
    "lng": -95.1969,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Iowa",
    "capital": "",
    "population": 11066,
    "id": 1840000397
  },
  {
    "city": "Whitehall",
    "city_ascii": "Whitehall",
    "lat": 43.4003,
    "lng": -86.3406,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 11061,
    "id": 1840000366
  },
  {
    "city": "Sosnovka",
    "city_ascii": "Sosnovka",
    "lat": 56.25,
    "lng": 51.2833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kirovskaya Oblast’",
    "capital": "",
    "population": 11027,
    "id": 1643274224
  },
  {
    "city": "Eṭ Ṭīra",
    "city_ascii": "Et Tira",
    "lat": 32.2328,
    "lng": 34.9503,
    "country": "Israel",
    "iso2": "IL",
    "iso3": "ISR",
    "admin_name": "Central",
    "capital": "",
    "population": 10200,
    "id": 1376315793
  },
  {
    "city": "Urdorf",
    "city_ascii": "Urdorf",
    "lat": 47.3867,
    "lng": 8.4278,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Zürich",
    "capital": "",
    "population": 9768,
    "id": 1756382154
  },
  {
    "city": "Lake Grove",
    "city_ascii": "Lake Grove",
    "lat": 40.8586,
    "lng": -73.1168,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 11056,
    "id": 1840005121
  },
  {
    "city": "Nieder-Olm",
    "city_ascii": "Nieder-Olm",
    "lat": 49.9083,
    "lng": 8.2028,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Rhineland-Palatinate",
    "capital": "",
    "population": 10150,
    "id": 1276571549
  },
  {
    "city": "Young",
    "city_ascii": "Young",
    "lat": -34.3,
    "lng": 148.3,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 10295,
    "id": 1036073044
  },
  {
    "city": "Grosse Pointe Park",
    "city_ascii": "Grosse Pointe Park",
    "lat": 42.3794,
    "lng": -82.9288,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 11050,
    "id": 1840003978
  },
  {
    "city": "Loyalsock",
    "city_ascii": "Loyalsock",
    "lat": 41.2743,
    "lng": -76.984,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 11048,
    "id": 1840152775
  },
  {
    "city": "Schleusingen",
    "city_ascii": "Schleusingen",
    "lat": 50.5117,
    "lng": 10.7506,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Thuringia",
    "capital": "",
    "population": 10960,
    "id": 1276153970
  },
  {
    "city": "Grand Haven",
    "city_ascii": "Grand Haven",
    "lat": 43.0553,
    "lng": -86.2201,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 11047,
    "id": 1840002995
  },
  {
    "city": "Snodland",
    "city_ascii": "Snodland",
    "lat": 51.328,
    "lng": 0.4467,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Kent",
    "capital": "",
    "population": 10211,
    "id": 1826888487
  },
  {
    "city": "Borovsk",
    "city_ascii": "Borovsk",
    "lat": 55.2,
    "lng": 36.4833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kaluzhskaya Oblast’",
    "capital": "",
    "population": 10966,
    "id": 1643432341
  },
  {
    "city": "Dek’emhāre",
    "city_ascii": "Dek'emhare",
    "lat": 15.07,
    "lng": 39.0475,
    "country": "Eritrea",
    "iso2": "ER",
    "iso3": "ERI",
    "admin_name": "Debub",
    "capital": "",
    "population": 10959,
    "id": 1232763839
  },
  {
    "city": "Two Rivers",
    "city_ascii": "Two Rivers",
    "lat": 44.1564,
    "lng": -87.5824,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 11041,
    "id": 1840002206
  },
  {
    "city": "Gleisdorf",
    "city_ascii": "Gleisdorf",
    "lat": 47.1039,
    "lng": 15.7083,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Steiermark",
    "capital": "",
    "population": 10763,
    "id": 1040059456
  },
  {
    "city": "Methil",
    "city_ascii": "Methil",
    "lat": 56.1844,
    "lng": -3.0223,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Fife",
    "capital": "",
    "population": 10800,
    "id": 1826468854
  },
  {
    "city": "Gold Canyon",
    "city_ascii": "Gold Canyon",
    "lat": 33.3715,
    "lng": -111.4369,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 11038,
    "id": 1840018032
  },
  {
    "city": "Holbrook",
    "city_ascii": "Holbrook",
    "lat": 42.1471,
    "lng": -71.0057,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 11036,
    "id": 1840053551
  },
  {
    "city": "Mossley",
    "city_ascii": "Mossley",
    "lat": 53.5147,
    "lng": -2.0387,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Tameside",
    "capital": "",
    "population": 10921,
    "id": 1826750637
  },
  {
    "city": "Drăgăneşti-Olt",
    "city_ascii": "Draganesti-Olt",
    "lat": 44.1697,
    "lng": 24.53,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Olt",
    "capital": "",
    "population": 10894,
    "id": 1642857216
  },
  {
    "city": "Clinton",
    "city_ascii": "Clinton",
    "lat": 34.4777,
    "lng": -81.8636,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 11031,
    "id": 1840014667
  },
  {
    "city": "Zhydachiv",
    "city_ascii": "Zhydachiv",
    "lat": 49.3833,
    "lng": 24.1333,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "L’vivs’ka Oblast’",
    "capital": "minor",
    "population": 10962,
    "id": 1804137545
  },
  {
    "city": "Dobbs Ferry",
    "city_ascii": "Dobbs Ferry",
    "lat": 41.0127,
    "lng": -73.8698,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 11027,
    "id": 1840004952
  },
  {
    "city": "Rüthen",
    "city_ascii": "Ruthen",
    "lat": 51.4933,
    "lng": 8.4333,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 10957,
    "id": 1276776072
  },
  {
    "city": "Völkermarkt",
    "city_ascii": "Volkermarkt",
    "lat": 46.6622,
    "lng": 14.6344,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Kärnten",
    "capital": "minor",
    "population": 10946,
    "id": 1040264683
  },
  {
    "city": "Estiva Gerbi",
    "city_ascii": "Estiva Gerbi",
    "lat": -22.2708,
    "lng": -46.945,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 10873,
    "id": 1076001518
  },
  {
    "city": "Warman",
    "city_ascii": "Warman",
    "lat": 52.3219,
    "lng": -106.5842,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Saskatchewan",
    "capital": "",
    "population": 11020,
    "id": 1124688931
  },
  {
    "city": "Karabash",
    "city_ascii": "Karabash",
    "lat": 55.4833,
    "lng": 60.2,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Chelyabinskaya Oblast’",
    "capital": "",
    "population": 10999,
    "id": 1643422680
  },
  {
    "city": "Kingaroy",
    "city_ascii": "Kingaroy",
    "lat": -26.5333,
    "lng": 151.8333,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Queensland",
    "capital": "",
    "population": 10398,
    "id": 1036463308
  },
  {
    "city": "Kiskunmajsa",
    "city_ascii": "Kiskunmajsa",
    "lat": 46.4922,
    "lng": 19.7367,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Bács-Kiskun",
    "capital": "minor",
    "population": 10968,
    "id": 1348210119
  },
  {
    "city": "Gulf Gate Estates",
    "city_ascii": "Gulf Gate Estates",
    "lat": 27.2587,
    "lng": -82.5065,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 11017,
    "id": 1840029040
  },
  {
    "city": "Xudat",
    "city_ascii": "Xudat",
    "lat": 41.6339,
    "lng": 48.6772,
    "country": "Azerbaijan",
    "iso2": "AZ",
    "iso3": "AZE",
    "admin_name": "Xaçmaz",
    "capital": "",
    "population": 10894,
    "id": 1031218803
  },
  {
    "city": "Akyaka",
    "city_ascii": "Akyaka",
    "lat": 40.7397,
    "lng": 43.6253,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Kars",
    "capital": "minor",
    "population": 10985,
    "id": 1792979414
  },
  {
    "city": "Hormigueros",
    "city_ascii": "Hormigueros",
    "lat": 18.1437,
    "lng": -67.12,
    "country": "Puerto Rico",
    "iso2": "PR",
    "iso3": "PRI",
    "admin_name": "Puerto Rico",
    "capital": "",
    "population": 11009,
    "id": 1630023672
  },
  {
    "city": "Iwai",
    "city_ascii": "Iwai",
    "lat": 35.5758,
    "lng": 134.3319,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Tottori",
    "capital": "",
    "population": 10919,
    "id": 1392109528
  },
  {
    "city": "Knowle",
    "city_ascii": "Knowle",
    "lat": 52.3881,
    "lng": -1.7318,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Solihull",
    "capital": "",
    "population": 10678,
    "id": 1826087458
  },
  {
    "city": "Verkhniy Tagil",
    "city_ascii": "Verkhniy Tagil",
    "lat": 57.3833,
    "lng": 59.9333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sverdlovskaya Oblast’",
    "capital": "",
    "population": 10962,
    "id": 1643392209
  },
  {
    "city": "Munhall",
    "city_ascii": "Munhall",
    "lat": 40.3937,
    "lng": -79.9004,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 11006,
    "id": 1840001247
  },
  {
    "city": "Altensteig",
    "city_ascii": "Altensteig",
    "lat": 48.5864,
    "lng": 8.6047,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 10799,
    "id": 1276878570
  },
  {
    "city": "La Tuque",
    "city_ascii": "La Tuque",
    "lat": 48.0652,
    "lng": -74.0528,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 11001,
    "id": 1124000430
  },
  {
    "city": "Norwich",
    "city_ascii": "Norwich",
    "lat": 42.9833,
    "lng": -80.6,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 11001,
    "id": 1124219807
  },
  {
    "city": "Obuse",
    "city_ascii": "Obuse",
    "lat": 36.6978,
    "lng": 138.3122,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Nagano",
    "capital": "",
    "population": 10454,
    "id": 1392708424
  },
  {
    "city": "Florence",
    "city_ascii": "Florence",
    "lat": 43.9914,
    "lng": -124.1062,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 11000,
    "id": 1840020008
  },
  {
    "city": "Shagonar",
    "city_ascii": "Shagonar",
    "lat": 51.5333,
    "lng": 92.9,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Tyva",
    "capital": "",
    "population": 10995,
    "id": 1643785605
  },
  {
    "city": "Murata",
    "city_ascii": "Murata",
    "lat": 38.1186,
    "lng": 140.7225,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Miyagi",
    "capital": "",
    "population": 10858,
    "id": 1392652565
  },
  {
    "city": "Cristuru Secuiesc",
    "city_ascii": "Cristuru Secuiesc",
    "lat": 46.2917,
    "lng": 25.0353,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Harghita",
    "capital": "",
    "population": 10796,
    "id": 1642875734
  },
  {
    "city": "Oak Grove",
    "city_ascii": "Oak Grove",
    "lat": 33.9809,
    "lng": -81.1438,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 10993,
    "id": 1840135088
  },
  {
    "city": "Minobu",
    "city_ascii": "Minobu",
    "lat": 35.4675,
    "lng": 138.4425,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Yamanashi",
    "capital": "",
    "population": 10956,
    "id": 1392309481
  },
  {
    "city": "Phibun Mangsahan",
    "city_ascii": "Phibun Mangsahan",
    "lat": 15.2482,
    "lng": 105.2296,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Ubon Ratchathani",
    "capital": "minor",
    "population": 10842,
    "id": 1764591980
  },
  {
    "city": "Meaford",
    "city_ascii": "Meaford",
    "lat": 44.58,
    "lng": -80.73,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 10991,
    "id": 1124445257
  },
  {
    "city": "Al Atārib",
    "city_ascii": "Al Atarib",
    "lat": 36.1389,
    "lng": 36.83,
    "country": "Syria",
    "iso2": "SY",
    "iso3": "SYR",
    "admin_name": "Ḩalab",
    "capital": "minor",
    "population": 10657,
    "id": 1760645780
  },
  {
    "city": "Süßen",
    "city_ascii": "Sussen",
    "lat": 48.6797,
    "lng": 9.7575,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 10192,
    "id": 1276876267
  },
  {
    "city": "Norwell",
    "city_ascii": "Norwell",
    "lat": 42.1608,
    "lng": -70.8177,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 10987,
    "id": 1840053572
  },
  {
    "city": "Wantage",
    "city_ascii": "Wantage",
    "lat": 41.2431,
    "lng": -74.6258,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 10986,
    "id": 1840081776
  },
  {
    "city": "Pompton Lakes",
    "city_ascii": "Pompton Lakes",
    "lat": 41.0024,
    "lng": -74.2859,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 10986,
    "id": 1840000833
  },
  {
    "city": "Millsboro",
    "city_ascii": "Millsboro",
    "lat": 38.593,
    "lng": -75.3118,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Delaware",
    "capital": "",
    "population": 10985,
    "id": 1840006079
  },
  {
    "city": "Pedro de Toledo",
    "city_ascii": "Pedro de Toledo",
    "lat": -24.275,
    "lng": -47.2328,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 10967,
    "id": 1076725526
  },
  {
    "city": "General Salgado",
    "city_ascii": "General Salgado",
    "lat": -20.6478,
    "lng": -50.3608,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 10961,
    "id": 1076323931
  },
  {
    "city": "Kings Mountain",
    "city_ascii": "Kings Mountain",
    "lat": 35.2348,
    "lng": -81.3501,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 10982,
    "id": 1840014552
  },
  {
    "city": "Sussex",
    "city_ascii": "Sussex",
    "lat": 43.1341,
    "lng": -88.2232,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 10981,
    "id": 1840003006
  },
  {
    "city": "Davos",
    "city_ascii": "Davos",
    "lat": 46.8091,
    "lng": 9.8398,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Graubünden",
    "capital": "minor",
    "population": 10937,
    "id": 1756286446
  },
  {
    "city": "Adjala-Tosorontio",
    "city_ascii": "Adjala-Tosorontio",
    "lat": 44.1333,
    "lng": -79.9333,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 10975,
    "id": 1124000498
  },
  {
    "city": "Pearsall",
    "city_ascii": "Pearsall",
    "lat": 28.8885,
    "lng": -99.0988,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 10973,
    "id": 1840020992
  },
  {
    "city": "Barmstedt",
    "city_ascii": "Barmstedt",
    "lat": 53.7833,
    "lng": 9.7667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Schleswig-Holstein",
    "capital": "",
    "population": 10368,
    "id": 1276501444
  },
  {
    "city": "Ocean City",
    "city_ascii": "Ocean City",
    "lat": 39.2682,
    "lng": -74.6019,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 10971,
    "id": 1840001598
  },
  {
    "city": "California City",
    "city_ascii": "California City",
    "lat": 35.1578,
    "lng": -117.8721,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 10968,
    "id": 1840019149
  },
  {
    "city": "Wooburn",
    "city_ascii": "Wooburn",
    "lat": 51.581,
    "lng": -0.691,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Buckinghamshire",
    "capital": "",
    "population": 10792,
    "id": 1826286228
  },
  {
    "city": "Neman",
    "city_ascii": "Neman",
    "lat": 55.0333,
    "lng": 22.0333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kaliningradskaya Oblast’",
    "capital": "minor",
    "population": 10931,
    "id": 1643209452
  },
  {
    "city": "Springbok",
    "city_ascii": "Springbok",
    "lat": -29.6667,
    "lng": 17.8833,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "Northern Cape",
    "capital": "",
    "population": 10438,
    "id": 1710894218
  },
  {
    "city": "Gonzales",
    "city_ascii": "Gonzales",
    "lat": 30.2132,
    "lng": -90.9234,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 10957,
    "id": 1840013965
  },
  {
    "city": "West Point",
    "city_ascii": "West Point",
    "lat": 41.122,
    "lng": -112.0994,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Utah",
    "capital": "",
    "population": 10957,
    "id": 1840021354
  },
  {
    "city": "Sultanhanı",
    "city_ascii": "Sultanhani",
    "lat": 38.2481,
    "lng": 33.5465,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Aksaray",
    "capital": "minor",
    "population": 10884,
    "id": 1792616267
  },
  {
    "city": "Liberty Lake",
    "city_ascii": "Liberty Lake",
    "lat": 47.6687,
    "lng": -117.1032,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 10956,
    "id": 1840037678
  },
  {
    "city": "Bad Iburg",
    "city_ascii": "Bad Iburg",
    "lat": 52.1592,
    "lng": 8.0472,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 10661,
    "id": 1276794541
  },
  {
    "city": "Earlestown",
    "city_ascii": "Earlestown",
    "lat": 53.45,
    "lng": -2.65,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "St. Helens",
    "capital": "",
    "population": 10830,
    "id": 1826235843
  },
  {
    "city": "Ardrossan",
    "city_ascii": "Ardrossan",
    "lat": 55.6432,
    "lng": -4.8097,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "North Ayrshire",
    "capital": "",
    "population": 10952,
    "id": 1826874237
  },
  {
    "city": "Aylesford",
    "city_ascii": "Aylesford",
    "lat": 51.3033,
    "lng": 0.4796,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Kent",
    "capital": "",
    "population": 10660,
    "id": 1826024683
  },
  {
    "city": "Zwettl",
    "city_ascii": "Zwettl",
    "lat": 48.6033,
    "lng": 15.1689,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Niederösterreich",
    "capital": "minor",
    "population": 10908,
    "id": 1040676174
  },
  {
    "city": "Donzdorf",
    "city_ascii": "Donzdorf",
    "lat": 48.6833,
    "lng": 9.8167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 10682,
    "id": 1276029380
  },
  {
    "city": "Boizenburg",
    "city_ascii": "Boizenburg",
    "lat": 53.3743,
    "lng": 10.7231,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Mecklenburg-Western Pomerania",
    "capital": "",
    "population": 10724,
    "id": 1276001527
  },
  {
    "city": "Elhovo",
    "city_ascii": "Elhovo",
    "lat": 42.1719,
    "lng": 26.5658,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Yambol",
    "capital": "",
    "population": 10929,
    "id": 1100602957
  },
  {
    "city": "Engen",
    "city_ascii": "Engen",
    "lat": 47.8528,
    "lng": 8.7714,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 10796,
    "id": 1276209924
  },
  {
    "city": "Desborough",
    "city_ascii": "Desborough",
    "lat": 52.4398,
    "lng": -0.8172,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Northamptonshire",
    "capital": "",
    "population": 10697,
    "id": 1826690378
  },
  {
    "city": "Decatur",
    "city_ascii": "Decatur",
    "lat": 40.8286,
    "lng": -84.9282,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 10943,
    "id": 1840007177
  },
  {
    "city": "Isselburg",
    "city_ascii": "Isselburg",
    "lat": 51.8331,
    "lng": 6.4667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 10692,
    "id": 1276291183
  },
  {
    "city": "Heath",
    "city_ascii": "Heath",
    "lat": 40.0241,
    "lng": -82.4412,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 10942,
    "id": 1840001418
  },
  {
    "city": "Hamilton Township",
    "city_ascii": "Hamilton Township",
    "lat": 44.054,
    "lng": -78.2164,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 10942,
    "id": 1124000994
  },
  {
    "city": "Destrehan",
    "city_ascii": "Destrehan",
    "lat": 29.9627,
    "lng": -90.3676,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 10933,
    "id": 1840013110
  },
  {
    "city": "New Albany",
    "city_ascii": "New Albany",
    "lat": 40.0802,
    "lng": -82.7883,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 10933,
    "id": 1840012407
  },
  {
    "city": "Figuig",
    "city_ascii": "Figuig",
    "lat": 32.1167,
    "lng": -1.2269,
    "country": "Morocco",
    "iso2": "MA",
    "iso3": "MAR",
    "admin_name": "Oriental",
    "capital": "",
    "population": 10872,
    "id": 1504487887
  },
  {
    "city": "Kisújszállás",
    "city_ascii": "Kisujszallas",
    "lat": 47.2167,
    "lng": 20.7667,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Jász-Nagykun-Szolnok",
    "capital": "",
    "population": 10870,
    "id": 1348563920
  },
  {
    "city": "Canandaigua",
    "city_ascii": "Canandaigua",
    "lat": 42.8607,
    "lng": -77.3183,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 10922,
    "id": 1840000392
  },
  {
    "city": "Shevington",
    "city_ascii": "Shevington",
    "lat": 53.572,
    "lng": -2.69,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Wigan",
    "capital": "",
    "population": 10000,
    "id": 1826958487
  },
  {
    "city": "Bobrynets’",
    "city_ascii": "Bobrynets'",
    "lat": 48.0578,
    "lng": 32.1581,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Kirovohrads’ka Oblast’",
    "capital": "minor",
    "population": 10898,
    "id": 1804282287
  },
  {
    "city": "Westtown",
    "city_ascii": "Westtown",
    "lat": 39.9417,
    "lng": -75.5565,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 10916,
    "id": 1840035366
  },
  {
    "city": "Sagarejo",
    "city_ascii": "Sagarejo",
    "lat": 41.7333,
    "lng": 45.3333,
    "country": "Georgia",
    "iso2": "GE",
    "iso3": "GEO",
    "admin_name": "K’akheti",
    "capital": "minor",
    "population": 10871,
    "id": 1268647899
  },
  {
    "city": "Hanover Township",
    "city_ascii": "Hanover Township",
    "lat": 41.2012,
    "lng": -75.9288,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 10913,
    "id": 1840148628
  },
  {
    "city": "Yungay",
    "city_ascii": "Yungay",
    "lat": -37.1194,
    "lng": -72.0189,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Biobío",
    "capital": "",
    "population": 10884,
    "id": 1152990591
  },
  {
    "city": "São Bento do Sapucaí",
    "city_ascii": "Sao Bento do Sapucai",
    "lat": -22.6889,
    "lng": -45.7308,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 10864,
    "id": 1076414119
  },
  {
    "city": "Cusseta",
    "city_ascii": "Cusseta",
    "lat": 32.347,
    "lng": -84.787,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 10907,
    "id": 1840029395
  },
  {
    "city": "Borgloon",
    "city_ascii": "Borgloon",
    "lat": 50.8022,
    "lng": 5.3433,
    "country": "Belgium",
    "iso2": "BE",
    "iso3": "BEL",
    "admin_name": "Flanders",
    "capital": "",
    "population": 10697,
    "id": 1056883817
  },
  {
    "city": "Surazh",
    "city_ascii": "Surazh",
    "lat": 53.0167,
    "lng": 32.4,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Bryanskaya Oblast’",
    "capital": "",
    "population": 10884,
    "id": 1643268069
  },
  {
    "city": "Tenango del Aire",
    "city_ascii": "Tenango del Aire",
    "lat": 19.1575,
    "lng": -98.8581,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "México",
    "capital": "minor",
    "population": 10578,
    "id": 1484285163
  },
  {
    "city": "Felton",
    "city_ascii": "Felton",
    "lat": 51.51,
    "lng": -2.574,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "North Somerset",
    "capital": "",
    "population": 10607,
    "id": 1826498722
  },
  {
    "city": "Elkins",
    "city_ascii": "Elkins",
    "lat": 38.9237,
    "lng": -79.854,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "West Virginia",
    "capital": "",
    "population": 10899,
    "id": 1840005991
  },
  {
    "city": "Nivala",
    "city_ascii": "Nivala",
    "lat": 63.9292,
    "lng": 24.9778,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Pohjois-Pohjanmaa",
    "capital": "minor",
    "population": 10876,
    "id": 1246609695
  },
  {
    "city": "Bofete",
    "city_ascii": "Bofete",
    "lat": -23.1022,
    "lng": -48.2578,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 10879,
    "id": 1076000023
  },
  {
    "city": "Langenzenn",
    "city_ascii": "Langenzenn",
    "lat": 49.4944,
    "lng": 10.7947,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 10665,
    "id": 1276092377
  },
  {
    "city": "Willistown",
    "city_ascii": "Willistown",
    "lat": 40.001,
    "lng": -75.4915,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 10895,
    "id": 1840104145
  },
  {
    "city": "Linthicum",
    "city_ascii": "Linthicum",
    "lat": 39.2088,
    "lng": -76.6625,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 10895,
    "id": 1840005921
  },
  {
    "city": "Guymon",
    "city_ascii": "Guymon",
    "lat": 36.6901,
    "lng": -101.4776,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oklahoma",
    "capital": "",
    "population": 10895,
    "id": 1840020350
  },
  {
    "city": "Nakayama",
    "city_ascii": "Nakayama",
    "lat": 38.3333,
    "lng": 140.2831,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Yamagata",
    "capital": "",
    "population": 10800,
    "id": 1392891031
  },
  {
    "city": "Krzeszowice",
    "city_ascii": "Krzeszowice",
    "lat": 50.1333,
    "lng": 19.6333,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Małopolskie",
    "capital": "",
    "population": 10281,
    "id": 1616471634
  },
  {
    "city": "Kangaroo Flat",
    "city_ascii": "Kangaroo Flat",
    "lat": -36.8,
    "lng": 144.25,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Victoria",
    "capital": "",
    "population": 10394,
    "id": 1036769935
  },
  {
    "city": "Le Locle",
    "city_ascii": "Le Locle",
    "lat": 47.0532,
    "lng": 6.7482,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Neuchâtel",
    "capital": "",
    "population": 10433,
    "id": 1756888589
  },
  {
    "city": "Kranuan",
    "city_ascii": "Kranuan",
    "lat": 16.7081,
    "lng": 103.0811,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Khon Kaen",
    "capital": "minor",
    "population": 10726,
    "id": 1764256426
  },
  {
    "city": "Lollar",
    "city_ascii": "Lollar",
    "lat": 50.6497,
    "lng": 8.7044,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 10395,
    "id": 1276402462
  },
  {
    "city": "Scartho",
    "city_ascii": "Scartho",
    "lat": 53.5399,
    "lng": -0.0926,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "North East Lincolnshire",
    "capital": "",
    "population": 10408,
    "id": 1826290531
  },
  {
    "city": "Koronowo",
    "city_ascii": "Koronowo",
    "lat": 53.3137,
    "lng": 17.937,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Kujawsko-Pomorskie",
    "capital": "",
    "population": 10818,
    "id": 1616300116
  },
  {
    "city": "Tosagua",
    "city_ascii": "Tosagua",
    "lat": -0.7864,
    "lng": -80.2342,
    "country": "Ecuador",
    "iso2": "EC",
    "iso3": "ECU",
    "admin_name": "Manabí",
    "capital": "",
    "population": 10751,
    "id": 1218293218
  },
  {
    "city": "Amioûn",
    "city_ascii": "Amioun",
    "lat": 34.2994,
    "lng": 35.8097,
    "country": "Lebanon",
    "iso2": "LB",
    "iso3": "LBN",
    "admin_name": "Liban-Nord",
    "capital": "minor",
    "population": 10000,
    "id": 1422143071
  },
  {
    "city": "Holbeach",
    "city_ascii": "Holbeach",
    "lat": 52.8037,
    "lng": 0.0154,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Lincolnshire",
    "capital": "",
    "population": 10458,
    "id": 1826410577
  },
  {
    "city": "St. Clements",
    "city_ascii": "St. Clements",
    "lat": 50.2689,
    "lng": -96.6742,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Manitoba",
    "capital": "",
    "population": 10876,
    "id": 1124000566
  },
  {
    "city": "Omurtag",
    "city_ascii": "Omurtag",
    "lat": 43.1072,
    "lng": 26.4192,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Targovishte",
    "capital": "",
    "population": 10825,
    "id": 1100244508
  },
  {
    "city": "Seymour",
    "city_ascii": "Seymour",
    "lat": 35.8761,
    "lng": -83.7742,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 10873,
    "id": 1840013386
  },
  {
    "city": "Montgomery",
    "city_ascii": "Montgomery",
    "lat": 39.2496,
    "lng": -84.3457,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 10872,
    "id": 1840008521
  },
  {
    "city": "Saint-Amable",
    "city_ascii": "Saint-Amable",
    "lat": 45.65,
    "lng": -73.3,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 10870,
    "id": 1124000904
  },
  {
    "city": "Weyburn",
    "city_ascii": "Weyburn",
    "lat": 49.6611,
    "lng": -103.8525,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Saskatchewan",
    "capital": "",
    "population": 10870,
    "id": 1124618383
  },
  {
    "city": "Forestville",
    "city_ascii": "Forestville",
    "lat": 39.0711,
    "lng": -84.3389,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 10866,
    "id": 1840034110
  },
  {
    "city": "Beclean",
    "city_ascii": "Beclean",
    "lat": 47.1797,
    "lng": 24.1797,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Bistriţa-Năsăud",
    "capital": "",
    "population": 10628,
    "id": 1642699805
  },
  {
    "city": "Soquel",
    "city_ascii": "Soquel",
    "lat": 36.9978,
    "lng": -121.9482,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 10862,
    "id": 1840018957
  },
  {
    "city": "La Bañeza",
    "city_ascii": "La Baneza",
    "lat": 42.2975,
    "lng": -5.9017,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Castille-Leon",
    "capital": "",
    "population": 10338,
    "id": 1724574131
  },
  {
    "city": "Erlenbach am Main",
    "city_ascii": "Erlenbach am Main",
    "lat": 49.8039,
    "lng": 9.1639,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 10227,
    "id": 1276645888
  },
  {
    "city": "Essex Junction",
    "city_ascii": "Essex Junction",
    "lat": 44.4902,
    "lng": -73.114,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Vermont",
    "capital": "",
    "population": 10852,
    "id": 1840004083
  },
  {
    "city": "Reguengos de Monsaraz",
    "city_ascii": "Reguengos de Monsaraz",
    "lat": 38.4167,
    "lng": -7.5333,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Évora",
    "capital": "minor",
    "population": 10828,
    "id": 1620982468
  },
  {
    "city": "Celldömölk",
    "city_ascii": "Celldomolk",
    "lat": 47.2575,
    "lng": 17.1525,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Vas",
    "capital": "minor",
    "population": 10646,
    "id": 1348592916
  },
  {
    "city": "Ngorongoro",
    "city_ascii": "Ngorongoro",
    "lat": -3.2496,
    "lng": 35.52,
    "country": "Tanzania",
    "iso2": "TZ",
    "iso3": "TZA",
    "admin_name": "Arusha",
    "capital": "",
    "population": 10836,
    "id": 1834381549
  },
  {
    "city": "Pocono",
    "city_ascii": "Pocono",
    "lat": 41.0612,
    "lng": -75.3102,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 10846,
    "id": 1840144156
  },
  {
    "city": "Milton",
    "city_ascii": "Milton",
    "lat": 44.643,
    "lng": -73.1542,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Vermont",
    "capital": "",
    "population": 10845,
    "id": 1840070912
  },
  {
    "city": "Vermillion",
    "city_ascii": "Vermillion",
    "lat": 42.7811,
    "lng": -96.9255,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Dakota",
    "capital": "",
    "population": 10844,
    "id": 1840003082
  },
  {
    "city": "Hrebinka",
    "city_ascii": "Hrebinka",
    "lat": 50.118,
    "lng": 32.4396,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Poltavs’ka Oblast’",
    "capital": "minor",
    "population": 10805,
    "id": 1804281882
  },
  {
    "city": "West Caldwell",
    "city_ascii": "West Caldwell",
    "lat": 40.8489,
    "lng": -74.2971,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 10843,
    "id": 1840131595
  },
  {
    "city": "Kitee",
    "city_ascii": "Kitee",
    "lat": 62.0986,
    "lng": 30.1375,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Pohjois-Karjala",
    "capital": "minor",
    "population": 10832,
    "id": 1246458844
  },
  {
    "city": "Gardere",
    "city_ascii": "Gardere",
    "lat": 30.3582,
    "lng": -91.1345,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 10841,
    "id": 1840013938
  },
  {
    "city": "Pleasant View",
    "city_ascii": "Pleasant View",
    "lat": 41.3249,
    "lng": -112.0011,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Utah",
    "capital": "",
    "population": 10839,
    "id": 1840020137
  },
  {
    "city": "Wasilla",
    "city_ascii": "Wasilla",
    "lat": 61.577,
    "lng": -149.466,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alaska",
    "capital": "",
    "population": 10838,
    "id": 1840023371
  },
  {
    "city": "Sharg‘un",
    "city_ascii": "Sharg`un",
    "lat": 38.46,
    "lng": 67.9669,
    "country": "Uzbekistan",
    "iso2": "UZ",
    "iso3": "UZB",
    "admin_name": "Surxondaryo",
    "capital": "",
    "population": 10716,
    "id": 1860470094
  },
  {
    "city": "South Dundas",
    "city_ascii": "South Dundas",
    "lat": 44.9167,
    "lng": -75.2667,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 10833,
    "id": 1124001404
  },
  {
    "city": "Aberbargoed",
    "city_ascii": "Aberbargoed",
    "lat": 51.6968,
    "lng": -3.224,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Caerphilly",
    "capital": "",
    "population": 9184,
    "id": 1826650581
  },
  {
    "city": "Kingsteignton",
    "city_ascii": "Kingsteignton",
    "lat": 50.5458,
    "lng": -3.5962,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Devon",
    "capital": "",
    "population": 10600,
    "id": 1826679607
  },
  {
    "city": "Rincão",
    "city_ascii": "Rincao",
    "lat": -21.5869,
    "lng": -48.0708,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 10796,
    "id": 1076750486
  },
  {
    "city": "Diavatá",
    "city_ascii": "Diavata",
    "lat": 40.6883,
    "lng": 22.8583,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Kentrikí Makedonía",
    "capital": "",
    "population": 9890,
    "id": 1300787037
  },
  {
    "city": "Braunsbedra",
    "city_ascii": "Braunsbedra",
    "lat": 51.2833,
    "lng": 11.9,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony-Anhalt",
    "capital": "",
    "population": 10678,
    "id": 1276157165
  },
  {
    "city": "Khrystynivka",
    "city_ascii": "Khrystynivka",
    "lat": 48.8333,
    "lng": 29.9667,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Cherkas’ka Oblast’",
    "capital": "minor",
    "population": 10762,
    "id": 1804529706
  },
  {
    "city": "River Forest",
    "city_ascii": "River Forest",
    "lat": 41.895,
    "lng": -87.8194,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 10816,
    "id": 1840011284
  },
  {
    "city": "Tecoh",
    "city_ascii": "Tecoh",
    "lat": 20.7419,
    "lng": -89.4744,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Yucatán",
    "capital": "",
    "population": 9134,
    "id": 1484904738
  },
  {
    "city": "Villiersdorp",
    "city_ascii": "Villiersdorp",
    "lat": -33.9833,
    "lng": 19.2833,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "Western Cape",
    "capital": "",
    "population": 10004,
    "id": 1710014567
  },
  {
    "city": "Haigerloch",
    "city_ascii": "Haigerloch",
    "lat": 48.3647,
    "lng": 8.805,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 10669,
    "id": 1276522466
  },
  {
    "city": "Manchester",
    "city_ascii": "Manchester",
    "lat": 37.4902,
    "lng": -77.5396,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 10807,
    "id": 1840024785
  },
  {
    "city": "Chenango",
    "city_ascii": "Chenango",
    "lat": 42.1954,
    "lng": -75.8989,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 10806,
    "id": 1840087298
  },
  {
    "city": "Tiszaföldvár",
    "city_ascii": "Tiszafoldvar",
    "lat": 46.9833,
    "lng": 20.25,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Jász-Nagykun-Szolnok",
    "capital": "",
    "population": 10672,
    "id": 1348506959
  },
  {
    "city": "Tezontepec",
    "city_ascii": "Tezontepec",
    "lat": 19.8833,
    "lng": -98.8167,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Hidalgo",
    "capital": "minor",
    "population": 10723,
    "id": 1484120923
  },
  {
    "city": "Winchendon",
    "city_ascii": "Winchendon",
    "lat": 42.6667,
    "lng": -72.0487,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 10798,
    "id": 1840053615
  },
  {
    "city": "Winchester",
    "city_ascii": "Winchester",
    "lat": 41.9219,
    "lng": -73.1028,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 10798,
    "id": 1840045107
  },
  {
    "city": "Vladimirescu",
    "city_ascii": "Vladimirescu",
    "lat": 46.1674,
    "lng": 21.4616,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Arad",
    "capital": "",
    "population": 10710,
    "id": 1642446879
  },
  {
    "city": "Tiszafüred",
    "city_ascii": "Tiszafured",
    "lat": 47.619,
    "lng": 20.76,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Jász-Nagykun-Szolnok",
    "capital": "minor",
    "population": 10745,
    "id": 1348745006
  },
  {
    "city": "Soyaniquilpan",
    "city_ascii": "Soyaniquilpan",
    "lat": 19.9892,
    "lng": -99.4361,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "México",
    "capital": "minor",
    "population": 10719,
    "id": 1484895616
  },
  {
    "city": "Mount Kisco",
    "city_ascii": "Mount Kisco",
    "lat": 41.2018,
    "lng": -73.7282,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 10795,
    "id": 1840004936
  },
  {
    "city": "Smithville",
    "city_ascii": "Smithville",
    "lat": 39.3921,
    "lng": -94.5748,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 10795,
    "id": 1840009605
  },
  {
    "city": "Guaraci",
    "city_ascii": "Guaraci",
    "lat": -20.4986,
    "lng": -48.9447,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 10778,
    "id": 1076832083
  },
  {
    "city": "Orlu",
    "city_ascii": "Orlu",
    "lat": 5.7837,
    "lng": 7.0333,
    "country": "Nigeria",
    "iso2": "NG",
    "iso3": "NGA",
    "admin_name": "Imo",
    "capital": "minor",
    "population": 9351,
    "id": 1566062510
  },
  {
    "city": "Sierra Madre",
    "city_ascii": "Sierra Madre",
    "lat": 34.1687,
    "lng": -118.0504,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 10793,
    "id": 1840021867
  },
  {
    "city": "Bananal",
    "city_ascii": "Bananal",
    "lat": -22.6839,
    "lng": -44.3228,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 10775,
    "id": 1076455705
  },
  {
    "city": "Totowa",
    "city_ascii": "Totowa",
    "lat": 40.9039,
    "lng": -74.2213,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 10792,
    "id": 1840000839
  },
  {
    "city": "Meßstetten",
    "city_ascii": "Messstetten",
    "lat": 48.1806,
    "lng": 8.9625,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 10653,
    "id": 1276150050
  },
  {
    "city": "Totolapan",
    "city_ascii": "Totolapan",
    "lat": 18.9869,
    "lng": -98.9197,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Morelos",
    "capital": "minor",
    "population": 10790,
    "id": 1484963370
  },
  {
    "city": "Blandford Forum",
    "city_ascii": "Blandford Forum",
    "lat": 50.856,
    "lng": -2.1648,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Dorset",
    "capital": "",
    "population": 10610,
    "id": 1826579765
  },
  {
    "city": "Bleicherode",
    "city_ascii": "Bleicherode",
    "lat": 51.4167,
    "lng": 10.5667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Thuringia",
    "capital": "",
    "population": 10419,
    "id": 1276520742
  },
  {
    "city": "Mărăşeşti",
    "city_ascii": "Marasesti",
    "lat": 45.88,
    "lng": 27.23,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Vrancea",
    "capital": "",
    "population": 10671,
    "id": 1642459723
  },
  {
    "city": "Demmin",
    "city_ascii": "Demmin",
    "lat": 53.905,
    "lng": 13.0439,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Mecklenburg-Western Pomerania",
    "capital": "",
    "population": 10657,
    "id": 1276386093
  },
  {
    "city": "Abram",
    "city_ascii": "Abram",
    "lat": 53.508,
    "lng": -2.588,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Wigan",
    "capital": "",
    "population": 9855,
    "id": 1826869753
  },
  {
    "city": "Halfway",
    "city_ascii": "Halfway",
    "lat": 39.6163,
    "lng": -77.77,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 10784,
    "id": 1840005606
  },
  {
    "city": "Urlaţi",
    "city_ascii": "Urlati",
    "lat": 44.9911,
    "lng": 26.2306,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Prahova",
    "capital": "",
    "population": 10541,
    "id": 1642991505
  },
  {
    "city": "Pencoed",
    "city_ascii": "Pencoed",
    "lat": 51.5228,
    "lng": -3.5047,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Bridgend",
    "capital": "",
    "population": 9166,
    "id": 1826165735
  },
  {
    "city": "Castleton",
    "city_ascii": "Castleton",
    "lat": 53.5907,
    "lng": -2.1737,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Rochdale",
    "capital": "",
    "population": 10159,
    "id": 1826243181
  },
  {
    "city": "Yeghvard",
    "city_ascii": "Yeghvard",
    "lat": 40.3167,
    "lng": 44.4833,
    "country": "Armenia",
    "iso2": "AM",
    "iso3": "ARM",
    "admin_name": "Kotayk’",
    "capital": "",
    "population": 10656,
    "id": 1051779287
  },
  {
    "city": "Highland Park",
    "city_ascii": "Highland Park",
    "lat": 42.4052,
    "lng": -83.0977,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 10775,
    "id": 1840003982
  },
  {
    "city": "Naganuma",
    "city_ascii": "Naganuma",
    "lat": 43.0103,
    "lng": 141.6953,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hokkaidō",
    "capital": "",
    "population": 10709,
    "id": 1392210651
  },
  {
    "city": "Yuzhno-Sukhokumsk",
    "city_ascii": "Yuzhno-Sukhokumsk",
    "lat": 44.6667,
    "lng": 45.65,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Dagestan",
    "capital": "",
    "population": 10641,
    "id": 1643359037
  },
  {
    "city": "Ráckeve",
    "city_ascii": "Rackeve",
    "lat": 47.1608,
    "lng": 18.9456,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Pest",
    "capital": "minor",
    "population": 10605,
    "id": 1348331289
  },
  {
    "city": "Tlachichilco",
    "city_ascii": "Tlachichilco",
    "lat": 20.6217,
    "lng": -98.1994,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Veracruz",
    "capital": "minor",
    "population": 10729,
    "id": 1484876244
  },
  {
    "city": "Winnemucca",
    "city_ascii": "Winnemucca",
    "lat": 40.9645,
    "lng": -117.7247,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Nevada",
    "capital": "",
    "population": 10764,
    "id": 1840021334
  },
  {
    "city": "Ballston",
    "city_ascii": "Ballston",
    "lat": 42.9542,
    "lng": -73.8794,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 10763,
    "id": 1840087194
  },
  {
    "city": "Castle Pines",
    "city_ascii": "Castle Pines",
    "lat": 39.4625,
    "lng": -104.8706,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 10763,
    "id": 1840022456
  },
  {
    "city": "Saks",
    "city_ascii": "Saks",
    "lat": 33.7118,
    "lng": -85.8536,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 10763,
    "id": 1840013708
  },
  {
    "city": "Burr Ridge",
    "city_ascii": "Burr Ridge",
    "lat": 41.7485,
    "lng": -87.9198,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 10758,
    "id": 1840010168
  },
  {
    "city": "Galena Park",
    "city_ascii": "Galena Park",
    "lat": 29.7452,
    "lng": -95.2333,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 10757,
    "id": 1840020922
  },
  {
    "city": "L’Île-Perrot",
    "city_ascii": "L'Ile-Perrot",
    "lat": 45.3833,
    "lng": -73.95,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 10756,
    "id": 1124063001
  },
  {
    "city": "Notre-Dame-de-l'Île-Perrot",
    "city_ascii": "Notre-Dame-de-l'Ile-Perrot",
    "lat": 45.3667,
    "lng": -73.9333,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 10756,
    "id": 1124001191
  },
  {
    "city": "Felsberg",
    "city_ascii": "Felsberg",
    "lat": 51.1346,
    "lng": 9.4215,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 10627,
    "id": 1276987745
  },
  {
    "city": "Tangerhütte",
    "city_ascii": "Tangerhutte",
    "lat": 52.4333,
    "lng": 11.8,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony-Anhalt",
    "capital": "",
    "population": 10718,
    "id": 1276201487
  },
  {
    "city": "Kami-furano",
    "city_ascii": "Kami-furano",
    "lat": 43.4556,
    "lng": 142.4672,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hokkaidō",
    "capital": "",
    "population": 10709,
    "id": 1392709435
  },
  {
    "city": "Williams Lake",
    "city_ascii": "Williams Lake",
    "lat": 52.1294,
    "lng": -122.1383,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "British Columbia",
    "capital": "",
    "population": 10753,
    "id": 1124821980
  },
  {
    "city": "Bischofshofen",
    "city_ascii": "Bischofshofen",
    "lat": 47.4172,
    "lng": 13.2194,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Salzburg",
    "capital": "",
    "population": 10540,
    "id": 1040799035
  },
  {
    "city": "Estaimpuis",
    "city_ascii": "Estaimpuis",
    "lat": 50.6756,
    "lng": 3.2758,
    "country": "Belgium",
    "iso2": "BE",
    "iso3": "BEL",
    "admin_name": "Wallonia",
    "capital": "",
    "population": 10424,
    "id": 1056179340
  },
  {
    "city": "Ludington",
    "city_ascii": "Ludington",
    "lat": 43.9573,
    "lng": -86.4434,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 10752,
    "id": 1840002547
  },
  {
    "city": "Santa Catarina Ayotzingo",
    "city_ascii": "Santa Catarina Ayotzingo",
    "lat": 19.2647,
    "lng": -98.8975,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "México",
    "capital": "",
    "population": 10702,
    "id": 1484300687
  },
  {
    "city": "São Luís do Paraitinga",
    "city_ascii": "Sao Luis do Paraitinga",
    "lat": -23.2219,
    "lng": -45.31,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 10731,
    "id": 1076106138
  },
  {
    "city": "Aigle",
    "city_ascii": "Aigle",
    "lat": 46.3173,
    "lng": 6.9646,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Valais",
    "capital": "",
    "population": 10131,
    "id": 1756593148
  },
  {
    "city": "Lower Saucon",
    "city_ascii": "Lower Saucon",
    "lat": 40.5881,
    "lng": -75.3188,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 10746,
    "id": 1840142185
  },
  {
    "city": "Elk City",
    "city_ascii": "Elk City",
    "lat": 35.385,
    "lng": -99.4331,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oklahoma",
    "capital": "",
    "population": 10746,
    "id": 1840020437
  },
  {
    "city": "Bellmead",
    "city_ascii": "Bellmead",
    "lat": 31.6025,
    "lng": -97.0896,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 10744,
    "id": 1840019531
  },
  {
    "city": "Mount Evelyn",
    "city_ascii": "Mount Evelyn",
    "lat": -37.7833,
    "lng": 145.3833,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Victoria",
    "capital": "",
    "population": 9702,
    "id": 1036617292
  },
  {
    "city": "Southwater",
    "city_ascii": "Southwater",
    "lat": 51.0238,
    "lng": -0.3526,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "West Sussex",
    "capital": "",
    "population": 10025,
    "id": 1826135276
  },
  {
    "city": "Elliot Lake",
    "city_ascii": "Elliot Lake",
    "lat": 46.3833,
    "lng": -82.65,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 10741,
    "id": 1124793448
  },
  {
    "city": "Muskegon Heights",
    "city_ascii": "Muskegon Heights",
    "lat": 43.2024,
    "lng": -86.242,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 10736,
    "id": 1840002871
  },
  {
    "city": "Balderton",
    "city_ascii": "Balderton",
    "lat": 53.0549,
    "lng": -0.7727,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Nottinghamshire",
    "capital": "",
    "population": 9757,
    "id": 1826134256
  },
  {
    "city": "São Brás de Alportel",
    "city_ascii": "Sao Bras de Alportel",
    "lat": 37.15,
    "lng": -7.8833,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Faro",
    "capital": "minor",
    "population": 10662,
    "id": 1620714794
  },
  {
    "city": "Mapleton",
    "city_ascii": "Mapleton",
    "lat": 40.1188,
    "lng": -111.5742,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Utah",
    "capital": "",
    "population": 10731,
    "id": 1840020170
  },
  {
    "city": "Fruitland Park",
    "city_ascii": "Fruitland Park",
    "lat": 28.86,
    "lng": -81.919,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 10730,
    "id": 1840014055
  },
  {
    "city": "Murtosa",
    "city_ascii": "Murtosa",
    "lat": 40.7369,
    "lng": -8.6386,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Aveiro",
    "capital": "minor",
    "population": 10585,
    "id": 1620337032
  },
  {
    "city": "Orono",
    "city_ascii": "Orono",
    "lat": 44.8867,
    "lng": -68.7166,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maine",
    "capital": "",
    "population": 10729,
    "id": 1840052945
  },
  {
    "city": "Zavitinsk",
    "city_ascii": "Zavitinsk",
    "lat": 50.1167,
    "lng": 129.4333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Amurskaya Oblast’",
    "capital": "",
    "population": 10721,
    "id": 1643858622
  },
  {
    "city": "Waiuku",
    "city_ascii": "Waiuku",
    "lat": -37.25,
    "lng": 174.7333,
    "country": "New Zealand",
    "iso2": "NZ",
    "iso3": "NZL",
    "admin_name": "Auckland",
    "capital": "",
    "population": 9660,
    "id": 1554334008
  },
  {
    "city": "Rochelle",
    "city_ascii": "Rochelle",
    "lat": 41.9195,
    "lng": -89.0632,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 10723,
    "id": 1840009179
  },
  {
    "city": "Uzda",
    "city_ascii": "Uzda",
    "lat": 53.4661,
    "lng": 27.2244,
    "country": "Belarus",
    "iso2": "BY",
    "iso3": "BLR",
    "admin_name": "Minskaya Voblasts’",
    "capital": "",
    "population": 10700,
    "id": 1112634742
  },
  {
    "city": "Browns Mills",
    "city_ascii": "Browns Mills",
    "lat": 39.9737,
    "lng": -74.5689,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 10719,
    "id": 1840005517
  },
  {
    "city": "Gig Harbor",
    "city_ascii": "Gig Harbor",
    "lat": 47.3353,
    "lng": -122.5964,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 10717,
    "id": 1840019855
  },
  {
    "city": "Bentley",
    "city_ascii": "Bentley",
    "lat": -32.001,
    "lng": 115.924,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Western Australia",
    "capital": "",
    "population": 8782,
    "id": 1036133213
  },
  {
    "city": "Frodsham",
    "city_ascii": "Frodsham",
    "lat": 53.295,
    "lng": -2.725,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Cheshire West and Chester",
    "capital": "",
    "population": 9077,
    "id": 1826347011
  },
  {
    "city": "Čáslav",
    "city_ascii": "Caslav",
    "lat": 49.911,
    "lng": 15.3898,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Středočeský Kraj",
    "capital": "",
    "population": 10322,
    "id": 1203177244
  },
  {
    "city": "Isabela",
    "city_ascii": "Isabela",
    "lat": 18.4991,
    "lng": -67.022,
    "country": "Puerto Rico",
    "iso2": "PR",
    "iso3": "PRI",
    "admin_name": "Puerto Rico",
    "capital": "",
    "population": 10708,
    "id": 1630023674
  },
  {
    "city": "Crestwood",
    "city_ascii": "Crestwood",
    "lat": 41.6454,
    "lng": -87.7396,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 10706,
    "id": 1840011267
  },
  {
    "city": "Waterford",
    "city_ascii": "Waterford",
    "lat": 39.7415,
    "lng": -74.8207,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 10705,
    "id": 1840081681
  },
  {
    "city": "Rosaryville",
    "city_ascii": "Rosaryville",
    "lat": 38.7672,
    "lng": -76.8266,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 10705,
    "id": 1840005952
  },
  {
    "city": "Farmersville",
    "city_ascii": "Farmersville",
    "lat": 36.3053,
    "lng": -119.2083,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 10703,
    "id": 1840020366
  },
  {
    "city": "Tafalla",
    "city_ascii": "Tafalla",
    "lat": 42.5289,
    "lng": -1.6736,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Navarre",
    "capital": "",
    "population": 10595,
    "id": 1724582472
  },
  {
    "city": "Smithfield",
    "city_ascii": "Smithfield",
    "lat": 36.9754,
    "lng": -76.6162,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 10702,
    "id": 1840006487
  },
  {
    "city": "Garden Acres",
    "city_ascii": "Garden Acres",
    "lat": 37.9637,
    "lng": -121.2296,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 10701,
    "id": 1840028376
  },
  {
    "city": "Cantley",
    "city_ascii": "Cantley",
    "lat": 45.5667,
    "lng": -75.7833,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 10699,
    "id": 1124000263
  },
  {
    "city": "Scottdale",
    "city_ascii": "Scottdale",
    "lat": 33.795,
    "lng": -84.2634,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 10698,
    "id": 1840013700
  },
  {
    "city": "Jibou",
    "city_ascii": "Jibou",
    "lat": 47.2583,
    "lng": 23.2583,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Sălaj",
    "capital": "",
    "population": 10407,
    "id": 1642643434
  },
  {
    "city": "Sweet Home",
    "city_ascii": "Sweet Home",
    "lat": 44.4023,
    "lng": -122.7028,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 10697,
    "id": 1840021253
  },
  {
    "city": "Inkerman",
    "city_ascii": "Inkerman",
    "lat": 44.6142,
    "lng": 33.6083,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Sevastopol’, Misto",
    "capital": "",
    "population": 10196,
    "id": 1804447396
  },
  {
    "city": "Ickenham",
    "city_ascii": "Ickenham",
    "lat": 51.558,
    "lng": -0.4484,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hillingdon",
    "capital": "",
    "population": 10387,
    "id": 1826334339
  },
  {
    "city": "DeForest",
    "city_ascii": "DeForest",
    "lat": 43.2301,
    "lng": -89.3437,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 10691,
    "id": 1840037935
  },
  {
    "city": "Riedlingen",
    "city_ascii": "Riedlingen",
    "lat": 48.1553,
    "lng": 9.4728,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 10528,
    "id": 1276567884
  },
  {
    "city": "Cleveland",
    "city_ascii": "Cleveland",
    "lat": 30.3374,
    "lng": -95.0931,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 10690,
    "id": 1840019596
  },
  {
    "city": "Avon Park",
    "city_ascii": "Avon Park",
    "lat": 27.5908,
    "lng": -81.5081,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 10689,
    "id": 1840014166
  },
  {
    "city": "Litomyšl",
    "city_ascii": "Litomysl",
    "lat": 49.8721,
    "lng": 16.3106,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Pardubický Kraj",
    "capital": "",
    "population": 10378,
    "id": 1203430500
  },
  {
    "city": "Lawrenceburg",
    "city_ascii": "Lawrenceburg",
    "lat": 35.2497,
    "lng": -87.3325,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 10688,
    "id": 1840014568
  },
  {
    "city": "Cowra",
    "city_ascii": "Cowra",
    "lat": -33.8183,
    "lng": 148.6578,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 10063,
    "id": 1036204877
  },
  {
    "city": "Lichtenau",
    "city_ascii": "Lichtenau",
    "lat": 51.6,
    "lng": 8.8833,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 10632,
    "id": 1276302835
  },
  {
    "city": "Rhosllanerchrugog",
    "city_ascii": "Rhosllanerchrugog",
    "lat": 53.011,
    "lng": -3.052,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Wrexham",
    "capital": "",
    "population": 9694,
    "id": 1826735616
  },
  {
    "city": "Raceland",
    "city_ascii": "Raceland",
    "lat": 29.7282,
    "lng": -90.6362,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 10686,
    "id": 1840014027
  },
  {
    "city": "Szigetvár",
    "city_ascii": "Szigetvar",
    "lat": 46.0481,
    "lng": 17.8125,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Baranya",
    "capital": "minor",
    "population": 10421,
    "id": 1348527343
  },
  {
    "city": "Nossen",
    "city_ascii": "Nossen",
    "lat": 51.05,
    "lng": 13.3,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 10598,
    "id": 1276123820
  },
  {
    "city": "Gernsheim",
    "city_ascii": "Gernsheim",
    "lat": 49.7517,
    "lng": 8.485,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 10423,
    "id": 1276371631
  },
  {
    "city": "Bothell East",
    "city_ascii": "Bothell East",
    "lat": 47.8064,
    "lng": -122.1845,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 10682,
    "id": 1840041892
  },
  {
    "city": "Aver-o-Mar",
    "city_ascii": "Aver-o-Mar",
    "lat": 41.4039,
    "lng": -8.769,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Porto",
    "capital": "",
    "population": 8961,
    "id": 1620327215
  },
  {
    "city": "Darling",
    "city_ascii": "Darling",
    "lat": -33.3833,
    "lng": 18.3833,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "Western Cape",
    "capital": "",
    "population": 10420,
    "id": 1710629684
  },
  {
    "city": "Spencer",
    "city_ascii": "Spencer",
    "lat": 43.1468,
    "lng": -95.1534,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Iowa",
    "capital": "",
    "population": 10678,
    "id": 1840000381
  },
  {
    "city": "Almoloya",
    "city_ascii": "Almoloya",
    "lat": 19.7,
    "lng": -98.4,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Hidalgo",
    "capital": "minor",
    "population": 10638,
    "id": 1484494575
  },
  {
    "city": "Oţelu Roşu",
    "city_ascii": "Otelu Rosu",
    "lat": 45.5333,
    "lng": 22.3667,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Caraş-Severin",
    "capital": "",
    "population": 10510,
    "id": 1642740234
  },
  {
    "city": "Orşova",
    "city_ascii": "Orsova",
    "lat": 44.7253,
    "lng": 22.3961,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Mehedinţi",
    "capital": "",
    "population": 10441,
    "id": 1642923767
  },
  {
    "city": "Newburn",
    "city_ascii": "Newburn",
    "lat": 54.983,
    "lng": -1.743,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Gateshead",
    "capital": "",
    "population": 9536,
    "id": 1826270642
  },
  {
    "city": "Hanson",
    "city_ascii": "Hanson",
    "lat": 42.0558,
    "lng": -70.8723,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 10668,
    "id": 1840053679
  },
  {
    "city": "Waldkirchen",
    "city_ascii": "Waldkirchen",
    "lat": 48.7306,
    "lng": 13.6011,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 10534,
    "id": 1276702006
  },
  {
    "city": "Paimio",
    "city_ascii": "Paimio",
    "lat": 60.4569,
    "lng": 22.6861,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Varsinais-Suomi",
    "capital": "minor",
    "population": 10620,
    "id": 1246967951
  },
  {
    "city": "Pike Creek Valley",
    "city_ascii": "Pike Creek Valley",
    "lat": 39.7296,
    "lng": -75.6993,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Delaware",
    "capital": "",
    "population": 10664,
    "id": 1840028614
  },
  {
    "city": "Nelson",
    "city_ascii": "Nelson",
    "lat": 49.5,
    "lng": -117.2833,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "British Columbia",
    "capital": "",
    "population": 10664,
    "id": 1124361295
  },
  {
    "city": "Knin",
    "city_ascii": "Knin",
    "lat": 44.0333,
    "lng": 16.1833,
    "country": "Croatia",
    "iso2": "HR",
    "iso3": "HRV",
    "admin_name": "Šibensko-Kninska Županija",
    "capital": "minor",
    "population": 10633,
    "id": 1191313799
  },
  {
    "city": "Weilheim an der Teck",
    "city_ascii": "Weilheim an der Teck",
    "lat": 48.615,
    "lng": 9.5386,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 10275,
    "id": 1276869346
  },
  {
    "city": "Querfurt",
    "city_ascii": "Querfurt",
    "lat": 51.3833,
    "lng": 11.6,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony-Anhalt",
    "capital": "",
    "population": 10593,
    "id": 1276096185
  },
  {
    "city": "Hornell",
    "city_ascii": "Hornell",
    "lat": 42.3332,
    "lng": -77.6633,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 10661,
    "id": 1840000444
  },
  {
    "city": "Pacific",
    "city_ascii": "Pacific",
    "lat": 38.4805,
    "lng": -90.7541,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 10661,
    "id": 1840009644
  },
  {
    "city": "Geneseo",
    "city_ascii": "Geneseo",
    "lat": 42.8038,
    "lng": -77.7783,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 10657,
    "id": 1840004422
  },
  {
    "city": "Altötting",
    "city_ascii": "Altotting",
    "lat": 48.2264,
    "lng": 12.6759,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "minor",
    "population": 10119,
    "id": 1276161581
  },
  {
    "city": "Sosenskiy",
    "city_ascii": "Sosenskiy",
    "lat": 54.05,
    "lng": 35.9667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kaluzhskaya Oblast’",
    "capital": "",
    "population": 10628,
    "id": 1643480430
  },
  {
    "city": "Newcastle",
    "city_ascii": "Newcastle",
    "lat": 35.2404,
    "lng": -97.5998,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oklahoma",
    "capital": "",
    "population": 10655,
    "id": 1840020453
  },
  {
    "city": "Vysokovsk",
    "city_ascii": "Vysokovsk",
    "lat": 56.3167,
    "lng": 36.55,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Moskovskaya Oblast’",
    "capital": "",
    "population": 10586,
    "id": 1643011226
  },
  {
    "city": "Ketchikan",
    "city_ascii": "Ketchikan",
    "lat": 55.3556,
    "lng": -131.6698,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alaska",
    "capital": "",
    "population": 10652,
    "id": 1840023270
  },
  {
    "city": "Alamosa",
    "city_ascii": "Alamosa",
    "lat": 37.4751,
    "lng": -105.8769,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 10652,
    "id": 1840018921
  },
  {
    "city": "Bacliff",
    "city_ascii": "Bacliff",
    "lat": 29.5077,
    "lng": -94.988,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 10649,
    "id": 1840018273
  },
  {
    "city": "Toccoa",
    "city_ascii": "Toccoa",
    "lat": 34.5807,
    "lng": -83.3256,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 10647,
    "id": 1840015551
  },
  {
    "city": "Trinity",
    "city_ascii": "Trinity",
    "lat": 28.1809,
    "lng": -82.6581,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 10646,
    "id": 1840029012
  },
  {
    "city": "Brooklyn",
    "city_ascii": "Brooklyn",
    "lat": 41.4349,
    "lng": -81.7498,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 10646,
    "id": 1840000592
  },
  {
    "city": "Kolárovo",
    "city_ascii": "Kolarovo",
    "lat": 47.9153,
    "lng": 17.9972,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Nitriansky",
    "capital": "",
    "population": 10546,
    "id": 1703541383
  },
  {
    "city": "Highland City",
    "city_ascii": "Highland City",
    "lat": 27.9633,
    "lng": -81.8781,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 10641,
    "id": 1840014122
  },
  {
    "city": "Millington",
    "city_ascii": "Millington",
    "lat": 35.335,
    "lng": -89.8991,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 10641,
    "id": 1840014593
  },
  {
    "city": "Lázaro Cárdenas",
    "city_ascii": "Lazaro Cardenas",
    "lat": 28.3897,
    "lng": -105.6236,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Chihuahua",
    "capital": "",
    "population": 8704,
    "id": 1484739082
  },
  {
    "city": "Birchington",
    "city_ascii": "Birchington",
    "lat": 51.377,
    "lng": 1.305,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Kent",
    "capital": "",
    "population": 9961,
    "id": 1826115786
  },
  {
    "city": "Georgetown",
    "city_ascii": "Georgetown",
    "lat": 38.6894,
    "lng": -75.3872,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Delaware",
    "capital": "",
    "population": 10638,
    "id": 1840006065
  },
  {
    "city": "Ogdensburg",
    "city_ascii": "Ogdensburg",
    "lat": 44.7088,
    "lng": -75.4717,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 10638,
    "id": 1840000297
  },
  {
    "city": "Wyomissing",
    "city_ascii": "Wyomissing",
    "lat": 40.3317,
    "lng": -75.9703,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 10635,
    "id": 1840001196
  },
  {
    "city": "Petal",
    "city_ascii": "Petal",
    "lat": 31.3477,
    "lng": -89.2359,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Mississippi",
    "capital": "",
    "population": 10632,
    "id": 1840014968
  },
  {
    "city": "Lambton Shores",
    "city_ascii": "Lambton Shores",
    "lat": 43.1833,
    "lng": -81.9,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 10631,
    "id": 1124001891
  },
  {
    "city": "Anderson",
    "city_ascii": "Anderson",
    "lat": 40.4497,
    "lng": -122.295,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 10630,
    "id": 1840018733
  },
  {
    "city": "Keila",
    "city_ascii": "Keila",
    "lat": 59.3081,
    "lng": 24.4264,
    "country": "Estonia",
    "iso2": "EE",
    "iso3": "EST",
    "admin_name": "Harjumaa",
    "capital": "minor",
    "population": 9758,
    "id": 1233230839
  },
  {
    "city": "Monroe",
    "city_ascii": "Monroe",
    "lat": 42.603,
    "lng": -89.6382,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 10619,
    "id": 1840002464
  },
  {
    "city": "Bovingdon",
    "city_ascii": "Bovingdon",
    "lat": 51.7231,
    "lng": -0.5367,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hertfordshire",
    "capital": "",
    "population": 9000,
    "id": 1826320957
  },
  {
    "city": "Grigiškės",
    "city_ascii": "Grigiskes",
    "lat": 54.6694,
    "lng": 25.0944,
    "country": "Lithuania",
    "iso2": "LT",
    "iso3": "LTU",
    "admin_name": "Vilniaus Miestas",
    "capital": "",
    "population": 10580,
    "id": 1440322258
  },
  {
    "city": "Shcholkine",
    "city_ascii": "Shcholkine",
    "lat": 45.4236,
    "lng": 35.8186,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Krym, Avtonomna Respublika",
    "capital": "",
    "population": 10593,
    "id": 1804743398
  },
  {
    "city": "Jablanica",
    "city_ascii": "Jablanica",
    "lat": 43.6583,
    "lng": 17.7583,
    "country": "Bosnia And Herzegovina",
    "iso2": "BA",
    "iso3": "BIH",
    "admin_name": "Bosnia and Herzegovina, Federation of",
    "capital": "minor",
    "population": 10580,
    "id": 1070524039
  },
  {
    "city": "Atchison",
    "city_ascii": "Atchison",
    "lat": 39.5625,
    "lng": -95.1367,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kansas",
    "capital": "",
    "population": 10613,
    "id": 1840001543
  },
  {
    "city": "Benito Juárez",
    "city_ascii": "Benito Juarez",
    "lat": -37.6655,
    "lng": -59.8,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires",
    "capital": "minor",
    "population": 10609,
    "id": 1032382110
  },
  {
    "city": "Kimpese",
    "city_ascii": "Kimpese",
    "lat": -5.55,
    "lng": 14.43,
    "country": "Congo (Kinshasa)",
    "iso2": "CD",
    "iso3": "COD",
    "admin_name": "Kongo Central",
    "capital": "",
    "population": 10578,
    "id": 1180273961
  },
  {
    "city": "Park Forest Village",
    "city_ascii": "Park Forest Village",
    "lat": 40.7996,
    "lng": -77.9084,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 10610,
    "id": 1840035143
  },
  {
    "city": "Khilok",
    "city_ascii": "Khilok",
    "lat": 51.35,
    "lng": 110.45,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Zabaykal’skiy Kray",
    "capital": "",
    "population": 10607,
    "id": 1643397911
  },
  {
    "city": "Biskupiec",
    "city_ascii": "Biskupiec",
    "lat": 53.8647,
    "lng": 20.9569,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Warmińsko-Mazurskie",
    "capital": "",
    "population": 10539,
    "id": 1616213985
  },
  {
    "city": "Gommern",
    "city_ascii": "Gommern",
    "lat": 52.0739,
    "lng": 11.8231,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony-Anhalt",
    "capital": "",
    "population": 10543,
    "id": 1276399095
  },
  {
    "city": "Mold",
    "city_ascii": "Mold",
    "lat": 53.166,
    "lng": -3.133,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Flintshire",
    "capital": "",
    "population": 10058,
    "id": 1826650301
  },
  {
    "city": "Malaya Vishera",
    "city_ascii": "Malaya Vishera",
    "lat": 58.85,
    "lng": 32.2167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Novgorodskaya Oblast’",
    "capital": "",
    "population": 10602,
    "id": 1643002504
  },
  {
    "city": "Burtonsville",
    "city_ascii": "Burtonsville",
    "lat": 39.1166,
    "lng": -76.9356,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 10604,
    "id": 1840005827
  },
  {
    "city": "Cheval",
    "city_ascii": "Cheval",
    "lat": 28.1459,
    "lng": -82.5185,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 10603,
    "id": 1840028977
  },
  {
    "city": "Easttown",
    "city_ascii": "Easttown",
    "lat": 40.0281,
    "lng": -75.4403,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 10603,
    "id": 1840150924
  },
  {
    "city": "Scituate",
    "city_ascii": "Scituate",
    "lat": 41.7926,
    "lng": -71.6202,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Rhode Island",
    "capital": "",
    "population": 10603,
    "id": 1840106234
  },
  {
    "city": "Strehaia",
    "city_ascii": "Strehaia",
    "lat": 44.6222,
    "lng": 23.1972,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Mehedinţi",
    "capital": "",
    "population": 10506,
    "id": 1642350779
  },
  {
    "city": "Songo",
    "city_ascii": "Songo",
    "lat": -7.3496,
    "lng": 14.85,
    "country": "Angola",
    "iso2": "AO",
    "iso3": "AGO",
    "admin_name": "Uíge",
    "capital": "",
    "population": 10579,
    "id": 1024923411
  },
  {
    "city": "Fort Bliss",
    "city_ascii": "Fort Bliss",
    "lat": 31.8137,
    "lng": -106.4119,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 10602,
    "id": 1840073931
  },
  {
    "city": "Mildenhall",
    "city_ascii": "Mildenhall",
    "lat": 52.3446,
    "lng": 0.5089,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Suffolk",
    "capital": "",
    "population": 10315,
    "id": 1826551750
  },
  {
    "city": "Maynard",
    "city_ascii": "Maynard",
    "lat": 42.4264,
    "lng": -71.4561,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 10600,
    "id": 1840053481
  },
  {
    "city": "Imst",
    "city_ascii": "Imst",
    "lat": 47.2394,
    "lng": 10.7381,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Tirol",
    "capital": "minor",
    "population": 10504,
    "id": 1040504243
  },
  {
    "city": "Ludlow",
    "city_ascii": "Ludlow",
    "lat": 52.368,
    "lng": -2.718,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Shropshire",
    "capital": "",
    "population": 10266,
    "id": 1826562344
  },
  {
    "city": "Lavínia",
    "city_ascii": "Lavinia",
    "lat": -21.1683,
    "lng": -51.0397,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 10590,
    "id": 1076751331
  },
  {
    "city": "Canteras",
    "city_ascii": "Canteras",
    "lat": 37.6121,
    "lng": -1.0437,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Murcia",
    "capital": "",
    "population": 10178,
    "id": 1724341004
  },
  {
    "city": "Barcs",
    "city_ascii": "Barcs",
    "lat": 45.96,
    "lng": 17.46,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Somogy",
    "capital": "minor",
    "population": 10501,
    "id": 1348676786
  },
  {
    "city": "Vorsma",
    "city_ascii": "Vorsma",
    "lat": 55.9833,
    "lng": 43.2667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Nizhegorodskaya Oblast’",
    "capital": "",
    "population": 10491,
    "id": 1643536345
  },
  {
    "city": "Panthersville",
    "city_ascii": "Panthersville",
    "lat": 33.7059,
    "lng": -84.2764,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 10581,
    "id": 1840013705
  },
  {
    "city": "Van Wert",
    "city_ascii": "Van Wert",
    "lat": 40.865,
    "lng": -84.5879,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 10579,
    "id": 1840010350
  },
  {
    "city": "San Pedro La Laguna",
    "city_ascii": "San Pedro La Laguna",
    "lat": 14.6918,
    "lng": -91.273,
    "country": "Guatemala",
    "iso2": "GT",
    "iso3": "GTM",
    "admin_name": "Sololá",
    "capital": "minor",
    "population": 10150,
    "id": 1320860856
  },
  {
    "city": "Norton",
    "city_ascii": "Norton",
    "lat": 36.9314,
    "lng": -82.6262,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 10570,
    "id": 1840003870
  },
  {
    "city": "Fairfield",
    "city_ascii": "Fairfield",
    "lat": 33.4747,
    "lng": -86.9194,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 10568,
    "id": 1840001883
  },
  {
    "city": "Greencastle",
    "city_ascii": "Greencastle",
    "lat": 39.6431,
    "lng": -86.8419,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 10567,
    "id": 1840008439
  },
  {
    "city": "Covington",
    "city_ascii": "Covington",
    "lat": 30.4808,
    "lng": -90.1122,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 10564,
    "id": 1840015019
  },
  {
    "city": "Myrtle Grove",
    "city_ascii": "Myrtle Grove",
    "lat": 34.123,
    "lng": -77.8834,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 10563,
    "id": 1840013632
  },
  {
    "city": "Three Rivers",
    "city_ascii": "Three Rivers",
    "lat": 41.9465,
    "lng": -85.6281,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 10560,
    "id": 1840003255
  },
  {
    "city": "Wilkau-Haßlau",
    "city_ascii": "Wilkau-Hasslau",
    "lat": 50.6667,
    "lng": 12.5167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 9784,
    "id": 1276704667
  },
  {
    "city": "Grevesmühlen",
    "city_ascii": "Grevesmuhlen",
    "lat": 53.8667,
    "lng": 11.1667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Mecklenburg-Western Pomerania",
    "capital": "",
    "population": 10354,
    "id": 1276634445
  },
  {
    "city": "Frontera",
    "city_ascii": "Frontera",
    "lat": -31.4278,
    "lng": -62.0619,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Córdoba",
    "capital": "",
    "population": 10520,
    "id": 1032791892
  },
  {
    "city": "Kapaa",
    "city_ascii": "Kapaa",
    "lat": 22.091,
    "lng": -159.352,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Hawaii",
    "capital": "",
    "population": 10544,
    "id": 1840029499
  },
  {
    "city": "Morro Bay",
    "city_ascii": "Morro Bay",
    "lat": 35.3681,
    "lng": -120.8481,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 10543,
    "id": 1840020415
  },
  {
    "city": "Lowell",
    "city_ascii": "Lowell",
    "lat": 41.2921,
    "lng": -87.4184,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 10541,
    "id": 1840009260
  },
  {
    "city": "Zmeinogorsk",
    "city_ascii": "Zmeinogorsk",
    "lat": 51.158,
    "lng": 82.1874,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Altayskiy Kray",
    "capital": "",
    "population": 10533,
    "id": 1643913373
  },
  {
    "city": "Paris",
    "city_ascii": "Paris",
    "lat": 36.2934,
    "lng": -88.3065,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 10536,
    "id": 1840014438
  },
  {
    "city": "Dnestrovsc",
    "city_ascii": "Dnestrovsc",
    "lat": 46.6222,
    "lng": 29.9133,
    "country": "Moldova",
    "iso2": "MD",
    "iso3": "MDA",
    "admin_name": "Stînga Nistrului",
    "capital": "",
    "population": 10436,
    "id": 1498610361
  },
  {
    "city": "Ironton",
    "city_ascii": "Ironton",
    "lat": 38.5319,
    "lng": -82.6777,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 10532,
    "id": 1840008607
  },
  {
    "city": "Kauniainen",
    "city_ascii": "Kauniainen",
    "lat": 60.2097,
    "lng": 24.7264,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Uusimaa",
    "capital": "minor",
    "population": 9486,
    "id": 1246749773
  },
  {
    "city": "Saddlebrooke",
    "city_ascii": "Saddlebrooke",
    "lat": 32.5385,
    "lng": -110.8582,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 10530,
    "id": 1840075484
  },
  {
    "city": "Arlesheim",
    "city_ascii": "Arlesheim",
    "lat": 47.4922,
    "lng": 7.6203,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Basel-Landschaft",
    "capital": "",
    "population": 9202,
    "id": 1756790806
  },
  {
    "city": "Mapleton",
    "city_ascii": "Mapleton",
    "lat": 43.7358,
    "lng": -80.6681,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 10527,
    "id": 1124000835
  },
  {
    "city": "Sinaia",
    "city_ascii": "Sinaia",
    "lat": 45.35,
    "lng": 25.5514,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Prahova",
    "capital": "",
    "population": 10410,
    "id": 1642021189
  },
  {
    "city": "Shumanay",
    "city_ascii": "Shumanay",
    "lat": 42.6386,
    "lng": 58.9172,
    "country": "Uzbekistan",
    "iso2": "UZ",
    "iso3": "UZB",
    "admin_name": "Qoraqalpog‘iston",
    "capital": "",
    "population": 10513,
    "id": 1860689478
  },
  {
    "city": "Milton",
    "city_ascii": "Milton",
    "lat": 30.6286,
    "lng": -87.0522,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 10523,
    "id": 1840015006
  },
  {
    "city": "Sandy",
    "city_ascii": "Sandy",
    "lat": 41.1447,
    "lng": -78.7296,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 10522,
    "id": 1840005149
  },
  {
    "city": "Topoloveni",
    "city_ascii": "Topoloveni",
    "lat": 44.8069,
    "lng": 25.0839,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Argeş",
    "capital": "",
    "population": 10219,
    "id": 1642642782
  },
  {
    "city": "Tanaina",
    "city_ascii": "Tanaina",
    "lat": 61.656,
    "lng": -149.4272,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alaska",
    "capital": "",
    "population": 10520,
    "id": 1840023662
  },
  {
    "city": "Bad Lauterberg",
    "city_ascii": "Bad Lauterberg",
    "lat": 51.6317,
    "lng": 10.4706,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 10269,
    "id": 1276934554
  },
  {
    "city": "Lloyd",
    "city_ascii": "Lloyd",
    "lat": 41.7286,
    "lng": -73.9962,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 10515,
    "id": 1840058260
  },
  {
    "city": "Gateway",
    "city_ascii": "Gateway",
    "lat": 26.5804,
    "lng": -81.7453,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 10513,
    "id": 1840014213
  },
  {
    "city": "Columbia",
    "city_ascii": "Columbia",
    "lat": 38.4581,
    "lng": -90.2156,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 10513,
    "id": 1840007502
  },
  {
    "city": "Bolekhiv",
    "city_ascii": "Bolekhiv",
    "lat": 49.0669,
    "lng": 23.8514,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Ivano-Frankivs’ka Oblast’",
    "capital": "",
    "population": 10476,
    "id": 1804748946
  },
  {
    "city": "Hockley",
    "city_ascii": "Hockley",
    "lat": 51.6014,
    "lng": 0.6363,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Essex",
    "capital": "",
    "population": 9616,
    "id": 1826444113
  },
  {
    "city": "Roscoe",
    "city_ascii": "Roscoe",
    "lat": 42.4256,
    "lng": -89.0083,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 10510,
    "id": 1840011122
  },
  {
    "city": "Târgu Frumos",
    "city_ascii": "Targu Frumos",
    "lat": 47.2097,
    "lng": 27.0131,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Iaşi",
    "capital": "",
    "population": 10475,
    "id": 1642141035
  },
  {
    "city": "Nova Europa",
    "city_ascii": "Nova Europa",
    "lat": -21.7783,
    "lng": -48.5608,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 10439,
    "id": 1076407128
  },
  {
    "city": "Arkadelphia",
    "city_ascii": "Arkadelphia",
    "lat": 34.1255,
    "lng": -93.0725,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arkansas",
    "capital": "",
    "population": 10503,
    "id": 1840013638
  },
  {
    "city": "Little Canada",
    "city_ascii": "Little Canada",
    "lat": 45.0244,
    "lng": -93.0863,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 10501,
    "id": 1840008936
  },
  {
    "city": "Wapakoneta",
    "city_ascii": "Wapakoneta",
    "lat": 40.5663,
    "lng": -84.1915,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 10500,
    "id": 1840010391
  },
  {
    "city": "Alpine",
    "city_ascii": "Alpine",
    "lat": 40.4629,
    "lng": -111.7724,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Utah",
    "capital": "",
    "population": 10498,
    "id": 1840018755
  },
  {
    "city": "Staffelstein",
    "city_ascii": "Staffelstein",
    "lat": 50.099,
    "lng": 10.9962,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 10389,
    "id": 1276439660
  },
  {
    "city": "Huittinen",
    "city_ascii": "Huittinen",
    "lat": 61.1764,
    "lng": 22.6986,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Satakunta",
    "capital": "minor",
    "population": 10473,
    "id": 1246269907
  },
  {
    "city": "Lyuboml’",
    "city_ascii": "Lyuboml'",
    "lat": 51.2158,
    "lng": 24.0408,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Volyns’ka Oblast’",
    "capital": "minor",
    "population": 10466,
    "id": 1804570446
  },
  {
    "city": "Farmington",
    "city_ascii": "Farmington",
    "lat": 42.4614,
    "lng": -83.3784,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 10491,
    "id": 1840002441
  },
  {
    "city": "Ivangorod",
    "city_ascii": "Ivangorod",
    "lat": 59.375,
    "lng": 28.2053,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Leningradskaya Oblast’",
    "capital": "",
    "population": 10453,
    "id": 1643468545
  },
  {
    "city": "Douglass",
    "city_ascii": "Douglass",
    "lat": 40.3438,
    "lng": -75.5909,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 10490,
    "id": 1840143118
  },
  {
    "city": "Warren",
    "city_ascii": "Warren",
    "lat": 41.7282,
    "lng": -71.2629,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Rhode Island",
    "capital": "",
    "population": 10488,
    "id": 1840066117
  },
  {
    "city": "Costeşti",
    "city_ascii": "Costesti",
    "lat": 44.6697,
    "lng": 24.88,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Argeş",
    "capital": "",
    "population": 10375,
    "id": 1642679241
  },
  {
    "city": "Karkamış",
    "city_ascii": "Karkamis",
    "lat": 36.8345,
    "lng": 37.9983,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Gaziantep",
    "capital": "minor",
    "population": 10436,
    "id": 1792055477
  },
  {
    "city": "Exeter",
    "city_ascii": "Exeter",
    "lat": 36.294,
    "lng": -119.1459,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 10485,
    "id": 1840020365
  },
  {
    "city": "Byarozawka",
    "city_ascii": "Byarozawka",
    "lat": 53.7167,
    "lng": 25.5,
    "country": "Belarus",
    "iso2": "BY",
    "iso3": "BLR",
    "admin_name": "Hrodzyenskaya Voblasts’",
    "capital": "",
    "population": 10400,
    "id": 1112003239
  },
  {
    "city": "Acushnet",
    "city_ascii": "Acushnet",
    "lat": 41.7139,
    "lng": -70.9012,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 10483,
    "id": 1840131572
  },
  {
    "city": "Pitkyaranta",
    "city_ascii": "Pitkyaranta",
    "lat": 61.5667,
    "lng": 31.4833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kareliya",
    "capital": "",
    "population": 10479,
    "id": 1643011862
  },
  {
    "city": "South Union",
    "city_ascii": "South Union",
    "lat": 39.8706,
    "lng": -79.7221,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 10481,
    "id": 1840143167
  },
  {
    "city": "East Cocalico",
    "city_ascii": "East Cocalico",
    "lat": 40.2242,
    "lng": -76.1057,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 10481,
    "id": 1840148813
  },
  {
    "city": "Georgian Bluffs",
    "city_ascii": "Georgian Bluffs",
    "lat": 44.65,
    "lng": -81.0333,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 10479,
    "id": 1124001470
  },
  {
    "city": "Bernalillo",
    "city_ascii": "Bernalillo",
    "lat": 35.3127,
    "lng": -106.5537,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Mexico",
    "capital": "",
    "population": 10477,
    "id": 1840020388
  },
  {
    "city": "Oelsnitz",
    "city_ascii": "Oelsnitz",
    "lat": 50.4167,
    "lng": 12.1667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 10285,
    "id": 1276364084
  },
  {
    "city": "Olevsk",
    "city_ascii": "Olevsk",
    "lat": 51.2278,
    "lng": 27.6481,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Zhytomyrs’ka Oblast’",
    "capital": "minor",
    "population": 10457,
    "id": 1804287361
  },
  {
    "city": "Jaltocan",
    "city_ascii": "Jaltocan",
    "lat": 21.1333,
    "lng": -98.5383,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Hidalgo",
    "capital": "",
    "population": 10265,
    "id": 1484352270
  },
  {
    "city": "Great Missenden",
    "city_ascii": "Great Missenden",
    "lat": 51.7042,
    "lng": -0.7078,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Buckinghamshire",
    "capital": "",
    "population": 10138,
    "id": 1826171263
  },
  {
    "city": "Point Pleasant",
    "city_ascii": "Point Pleasant",
    "lat": 38.8529,
    "lng": -82.1303,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "West Virginia",
    "capital": "",
    "population": 10473,
    "id": 1840006048
  },
  {
    "city": "Cape Canaveral",
    "city_ascii": "Cape Canaveral",
    "lat": 28.3933,
    "lng": -80.605,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 10470,
    "id": 1840015091
  },
  {
    "city": "Bogen",
    "city_ascii": "Bogen",
    "lat": 48.9167,
    "lng": 12.6833,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 10263,
    "id": 1276422463
  },
  {
    "city": "Worth",
    "city_ascii": "Worth",
    "lat": 41.6877,
    "lng": -87.7916,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 10466,
    "id": 1840011318
  },
  {
    "city": "Portage",
    "city_ascii": "Portage",
    "lat": 43.5489,
    "lng": -89.4658,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 10465,
    "id": 1840002712
  },
  {
    "city": "Salanso",
    "city_ascii": "Salanso",
    "lat": 12.1833,
    "lng": -4.0833,
    "country": "Burkina Faso",
    "iso2": "BF",
    "iso3": "BFA",
    "admin_name": "Boucle du Mouhoun",
    "capital": "minor",
    "population": 10385,
    "id": 1854892822
  },
  {
    "city": "Fort William",
    "city_ascii": "Fort William",
    "lat": 56.8198,
    "lng": -5.1052,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Highland",
    "capital": "",
    "population": 10459,
    "id": 1826742184
  },
  {
    "city": "Sudogda",
    "city_ascii": "Sudogda",
    "lat": 55.95,
    "lng": 40.8667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Vladimirskaya Oblast’",
    "capital": "",
    "population": 10442,
    "id": 1643470978
  },
  {
    "city": "Santiago",
    "city_ascii": "Santiago",
    "lat": -14.1896,
    "lng": -75.74,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "Ica",
    "capital": "",
    "population": 10449,
    "id": 1604889626
  },
  {
    "city": "Millstone",
    "city_ascii": "Millstone",
    "lat": 40.2123,
    "lng": -74.4302,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 10460,
    "id": 1840152826
  },
  {
    "city": "Bedford Heights",
    "city_ascii": "Bedford Heights",
    "lat": 41.4042,
    "lng": -81.5053,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 10460,
    "id": 1840003397
  },
  {
    "city": "Woodmere",
    "city_ascii": "Woodmere",
    "lat": 29.8493,
    "lng": -90.0751,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 10458,
    "id": 1840031148
  },
  {
    "city": "Lake Arbor",
    "city_ascii": "Lake Arbor",
    "lat": 38.907,
    "lng": -76.8299,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 10456,
    "id": 1840031485
  },
  {
    "city": "Zuyevka",
    "city_ascii": "Zuyevka",
    "lat": 58.4033,
    "lng": 51.1304,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kirovskaya Oblast’",
    "capital": "",
    "population": 10447,
    "id": 1643984570
  },
  {
    "city": "Star",
    "city_ascii": "Star",
    "lat": 43.7013,
    "lng": -116.4934,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Idaho",
    "capital": "",
    "population": 10454,
    "id": 1840021279
  },
  {
    "city": "Bogué",
    "city_ascii": "Bogue",
    "lat": 16.5904,
    "lng": -14.27,
    "country": "Mauritania",
    "iso2": "MR",
    "iso3": "MRT",
    "admin_name": "Brakna",
    "capital": "",
    "population": 10415,
    "id": 1478095096
  },
  {
    "city": "Fox Lake",
    "city_ascii": "Fox Lake",
    "lat": 42.4239,
    "lng": -88.1844,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 10451,
    "id": 1840011168
  },
  {
    "city": "Pembroke Dock",
    "city_ascii": "Pembroke Dock",
    "lat": 51.6933,
    "lng": -4.9458,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Pembrokeshire",
    "capital": "",
    "population": 9753,
    "id": 1826264078
  },
  {
    "city": "Sovata",
    "city_ascii": "Sovata",
    "lat": 46.5961,
    "lng": 25.0744,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Mureş",
    "capital": "",
    "population": 10385,
    "id": 1642965657
  },
  {
    "city": "Fountain Inn",
    "city_ascii": "Fountain Inn",
    "lat": 34.6994,
    "lng": -82.1999,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 10441,
    "id": 1840013500
  },
  {
    "city": "Nevelsk",
    "city_ascii": "Nevelsk",
    "lat": 46.65,
    "lng": 141.8667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sakhalinskaya Oblast’",
    "capital": "",
    "population": 10421,
    "id": 1643719954
  },
  {
    "city": "Lehigh Township",
    "city_ascii": "Lehigh Township",
    "lat": 40.7679,
    "lng": -75.5394,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 10432,
    "id": 1840142186
  },
  {
    "city": "Kendallville",
    "city_ascii": "Kendallville",
    "lat": 41.4441,
    "lng": -85.2578,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 10432,
    "id": 1840008208
  },
  {
    "city": "Coroneo",
    "city_ascii": "Coroneo",
    "lat": 20.1333,
    "lng": -100.3333,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Guanajuato",
    "capital": "minor",
    "population": 10347,
    "id": 1484498281
  },
  {
    "city": "Molango",
    "city_ascii": "Molango",
    "lat": 20.7844,
    "lng": -98.7175,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Hidalgo",
    "capital": "",
    "population": 10385,
    "id": 1484340479
  },
  {
    "city": "Sumner",
    "city_ascii": "Sumner",
    "lat": 47.2189,
    "lng": -122.2338,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 10427,
    "id": 1840021128
  },
  {
    "city": "Burkburnett",
    "city_ascii": "Burkburnett",
    "lat": 34.0746,
    "lng": -98.5672,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 10427,
    "id": 1840019278
  },
  {
    "city": "Felling",
    "city_ascii": "Felling",
    "lat": 54.95,
    "lng": -1.564,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Gateshead",
    "capital": "",
    "population": 8908,
    "id": 1826214509
  },
  {
    "city": "Nishi-kagura",
    "city_ascii": "Nishi-kagura",
    "lat": 43.6964,
    "lng": 142.4517,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hokkaidō",
    "capital": "",
    "population": 10276,
    "id": 1392972250
  },
  {
    "city": "Hughenden",
    "city_ascii": "Hughenden",
    "lat": 51.6558,
    "lng": -0.749,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Buckinghamshire",
    "capital": "",
    "population": 8506,
    "id": 1826163493
  },
  {
    "city": "Tangermünde",
    "city_ascii": "Tangermunde",
    "lat": 52.5408,
    "lng": 11.9689,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony-Anhalt",
    "capital": "",
    "population": 10310,
    "id": 1276714669
  },
  {
    "city": "Hull",
    "city_ascii": "Hull",
    "lat": 42.2861,
    "lng": -70.8835,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 10424,
    "id": 1840053570
  },
  {
    "city": "Takahama",
    "city_ascii": "Takahama",
    "lat": 35.4903,
    "lng": 135.5511,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Fukui",
    "capital": "",
    "population": 10282,
    "id": 1392343755
  },
  {
    "city": "Roseira",
    "city_ascii": "Roseira",
    "lat": -22.8978,
    "lng": -45.305,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 10344,
    "id": 1076731583
  },
  {
    "city": "Werlte",
    "city_ascii": "Werlte",
    "lat": 52.85,
    "lng": 7.6833,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 10260,
    "id": 1276655654
  },
  {
    "city": "Kapyl’",
    "city_ascii": "Kapyl'",
    "lat": 53.15,
    "lng": 27.0917,
    "country": "Belarus",
    "iso2": "BY",
    "iso3": "BLR",
    "admin_name": "Minskaya Voblasts’",
    "capital": "minor",
    "population": 10400,
    "id": 1112976876
  },
  {
    "city": "Pine Hill",
    "city_ascii": "Pine Hill",
    "lat": 39.7879,
    "lng": -74.9857,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 10417,
    "id": 1840001509
  },
  {
    "city": "Rawdon",
    "city_ascii": "Rawdon",
    "lat": 46.05,
    "lng": -73.7167,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 10416,
    "id": 1124084263
  },
  {
    "city": "Martin",
    "city_ascii": "Martin",
    "lat": 36.3386,
    "lng": -88.8513,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 10415,
    "id": 1840015282
  },
  {
    "city": "Budd Lake",
    "city_ascii": "Budd Lake",
    "lat": 40.8733,
    "lng": -74.7374,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 10414,
    "id": 1840005195
  },
  {
    "city": "Hudson",
    "city_ascii": "Hudson",
    "lat": 42.2515,
    "lng": -73.7859,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 10413,
    "id": 1840000454
  },
  {
    "city": "Shasta Lake",
    "city_ascii": "Shasta Lake",
    "lat": 40.679,
    "lng": -122.3775,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 10413,
    "id": 1840021349
  },
  {
    "city": "Hamilton",
    "city_ascii": "Hamilton",
    "lat": -37.7333,
    "lng": 142.0167,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Victoria",
    "capital": "",
    "population": 9974,
    "id": 1036699883
  },
  {
    "city": "Rodeo",
    "city_ascii": "Rodeo",
    "lat": 38.0368,
    "lng": -122.2526,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 10411,
    "id": 1840018900
  },
  {
    "city": "Campbellton",
    "city_ascii": "Campbellton",
    "lat": 48.005,
    "lng": -66.6731,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "New Brunswick",
    "capital": "",
    "population": 10411,
    "id": 1124336512
  },
  {
    "city": "Appleton",
    "city_ascii": "Appleton",
    "lat": 53.3508,
    "lng": -2.5441,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Warrington",
    "capital": "",
    "population": 10265,
    "id": 1826486906
  },
  {
    "city": "Dayton",
    "city_ascii": "Dayton",
    "lat": 35.4912,
    "lng": -85.012,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 10410,
    "id": 1840014525
  },
  {
    "city": "Belaya Kholunitsa",
    "city_ascii": "Belaya Kholunitsa",
    "lat": 58.8333,
    "lng": 50.85,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kirovskaya Oblast’",
    "capital": "",
    "population": 10406,
    "id": 1643961452
  },
  {
    "city": "View Royal",
    "city_ascii": "View Royal",
    "lat": 48.4517,
    "lng": -123.4339,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "British Columbia",
    "capital": "",
    "population": 10408,
    "id": 1124001985
  },
  {
    "city": "Vidor",
    "city_ascii": "Vidor",
    "lat": 30.1291,
    "lng": -93.9967,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 10403,
    "id": 1840022193
  },
  {
    "city": "Tutrakan",
    "city_ascii": "Tutrakan",
    "lat": 44.0481,
    "lng": 26.6044,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Silistra",
    "capital": "minor",
    "population": 10370,
    "id": 1100290695
  },
  {
    "city": "Yaotsu",
    "city_ascii": "Yaotsu",
    "lat": 35.4761,
    "lng": 137.1417,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Gifu",
    "capital": "",
    "population": 10321,
    "id": 1392537302
  },
  {
    "city": "Cajobi",
    "city_ascii": "Cajobi",
    "lat": -20.88,
    "lng": -48.8089,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 10341,
    "id": 1076127516
  },
  {
    "city": "Pasewalk",
    "city_ascii": "Pasewalk",
    "lat": 53.5063,
    "lng": 13.99,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Mecklenburg-Western Pomerania",
    "capital": "",
    "population": 10213,
    "id": 1276152058
  },
  {
    "city": "Ianca",
    "city_ascii": "Ianca",
    "lat": 45.135,
    "lng": 27.4747,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Brăila",
    "capital": "",
    "population": 10343,
    "id": 1642741254
  },
  {
    "city": "Bellefontaine Neighbors",
    "city_ascii": "Bellefontaine Neighbors",
    "lat": 38.7529,
    "lng": -90.228,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 10397,
    "id": 1840007449
  },
  {
    "city": "East Greenwich",
    "city_ascii": "East Greenwich",
    "lat": 39.7903,
    "lng": -75.2396,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 10396,
    "id": 1840081671
  },
  {
    "city": "Salinas",
    "city_ascii": "Salinas",
    "lat": -34.7751,
    "lng": -55.8402,
    "country": "Uruguay",
    "iso2": "UY",
    "iso3": "URY",
    "admin_name": "Canelones",
    "capital": "",
    "population": 8626,
    "id": 1858193593
  },
  {
    "city": "Harrison Township",
    "city_ascii": "Harrison Township",
    "lat": 40.6376,
    "lng": -79.717,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 10394,
    "id": 1840147154
  },
  {
    "city": "Vermilion",
    "city_ascii": "Vermilion",
    "lat": 41.4103,
    "lng": -82.3214,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 10394,
    "id": 1840010234
  },
  {
    "city": "Puerto Quijarro",
    "city_ascii": "Puerto Quijarro",
    "lat": -17.7796,
    "lng": -57.77,
    "country": "Bolivia",
    "iso2": "BO",
    "iso3": "BOL",
    "admin_name": "Santa Cruz",
    "capital": "",
    "population": 10392,
    "id": 1068582123
  },
  {
    "city": "Dolhasca",
    "city_ascii": "Dolhasca",
    "lat": 47.4303,
    "lng": 26.6094,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Suceava",
    "capital": "",
    "population": 10298,
    "id": 1642508577
  },
  {
    "city": "Lipova",
    "city_ascii": "Lipova",
    "lat": 46.0894,
    "lng": 21.6914,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Arad",
    "capital": "",
    "population": 10313,
    "id": 1642895162
  },
  {
    "city": "Raymond",
    "city_ascii": "Raymond",
    "lat": 43.0322,
    "lng": -71.1994,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Hampshire",
    "capital": "",
    "population": 10389,
    "id": 1840055480
  },
  {
    "city": "Bolivar",
    "city_ascii": "Bolivar",
    "lat": 37.6057,
    "lng": -93.4174,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 10389,
    "id": 1840007578
  },
  {
    "city": "Kapuvár",
    "city_ascii": "Kapuvar",
    "lat": 47.6,
    "lng": 17.0331,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Győr-Moson-Sopron",
    "capital": "minor",
    "population": 10281,
    "id": 1348249118
  },
  {
    "city": "Lanškroun",
    "city_ascii": "Lanskroun",
    "lat": 49.9122,
    "lng": 16.612,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Pardubický Kraj",
    "capital": "",
    "population": 9906,
    "id": 1203727130
  },
  {
    "city": "Hiranai",
    "city_ascii": "Hiranai",
    "lat": 40.9261,
    "lng": 140.9561,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Aomori",
    "capital": "",
    "population": 10337,
    "id": 1392363448
  },
  {
    "city": "Bad Frankenhausen",
    "city_ascii": "Bad Frankenhausen",
    "lat": 51.3558,
    "lng": 11.1011,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Thuringia",
    "capital": "",
    "population": 10230,
    "id": 1276187719
  },
  {
    "city": "Garliava",
    "city_ascii": "Garliava",
    "lat": 54.8202,
    "lng": 23.8726,
    "country": "Lithuania",
    "iso2": "LT",
    "iso3": "LTU",
    "admin_name": "Kaunas",
    "capital": "",
    "population": 10324,
    "id": 1440296799
  },
  {
    "city": "Bowen",
    "city_ascii": "Bowen",
    "lat": -20.0167,
    "lng": 148.2333,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Queensland",
    "capital": "",
    "population": 10377,
    "id": 1036979225
  },
  {
    "city": "San Diego Country Estates",
    "city_ascii": "San Diego Country Estates",
    "lat": 33.0093,
    "lng": -116.7874,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 10381,
    "id": 1840028398
  },
  {
    "city": "Kennett",
    "city_ascii": "Kennett",
    "lat": 36.2403,
    "lng": -90.0481,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 10379,
    "id": 1840008809
  },
  {
    "city": "Tököl",
    "city_ascii": "Tokol",
    "lat": 47.3219,
    "lng": 18.9619,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Pest",
    "capital": "",
    "population": 10116,
    "id": 1348214866
  },
  {
    "city": "Heves",
    "city_ascii": "Heves",
    "lat": 47.6,
    "lng": 20.2833,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Heves",
    "capital": "minor",
    "population": 10275,
    "id": 1348843058
  },
  {
    "city": "Prestonpans",
    "city_ascii": "Prestonpans",
    "lat": 55.9597,
    "lng": -2.961,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "East Lothian",
    "capital": "",
    "population": 10360,
    "id": 1826462891
  },
  {
    "city": "Verkhn’odniprovs’k",
    "city_ascii": "Verkhn'odniprovs'k",
    "lat": 48.6561,
    "lng": 34.3283,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Dnipropetrovs’ka Oblast’",
    "capital": "minor",
    "population": 10314,
    "id": 1804265302
  },
  {
    "city": "Chatham",
    "city_ascii": "Chatham",
    "lat": 40.7273,
    "lng": -74.4289,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 10378,
    "id": 1840000948
  },
  {
    "city": "Troy",
    "city_ascii": "Troy",
    "lat": 38.7266,
    "lng": -89.8973,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 10375,
    "id": 1840010709
  },
  {
    "city": "Frýdlant nad Ostravicí",
    "city_ascii": "Frydlant nad Ostravici",
    "lat": 49.5928,
    "lng": 18.3597,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Moravskoslezský Kraj",
    "capital": "",
    "population": 9922,
    "id": 1203314077
  },
  {
    "city": "Worcester",
    "city_ascii": "Worcester",
    "lat": 40.1899,
    "lng": -75.3522,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 10374,
    "id": 1840035368
  },
  {
    "city": "Fort Bragg",
    "city_ascii": "Fort Bragg",
    "lat": 39.4399,
    "lng": -123.8013,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 10374,
    "id": 1840020189
  },
  {
    "city": "Comstock Park",
    "city_ascii": "Comstock Park",
    "lat": 43.0438,
    "lng": -85.6779,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 10374,
    "id": 1840004307
  },
  {
    "city": "Köflach",
    "city_ascii": "Koflach",
    "lat": 47.0639,
    "lng": 15.0889,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Steiermark",
    "capital": "",
    "population": 9888,
    "id": 1040578038
  },
  {
    "city": "Steinau an der Straße",
    "city_ascii": "Steinau an der Strasse",
    "lat": 50.3167,
    "lng": 9.4667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 10275,
    "id": 1276321802
  },
  {
    "city": "Lyons",
    "city_ascii": "Lyons",
    "lat": 41.8121,
    "lng": -87.8192,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 10372,
    "id": 1840011292
  },
  {
    "city": "Neuenstadt am Kocher",
    "city_ascii": "Neuenstadt am Kocher",
    "lat": 49.2333,
    "lng": 9.3333,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 10123,
    "id": 1276757079
  },
  {
    "city": "Ardino",
    "city_ascii": "Ardino",
    "lat": 41.5833,
    "lng": 25.1333,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Kardzhali",
    "capital": "",
    "population": 10336,
    "id": 1100759594
  },
  {
    "city": "Miami Shores",
    "city_ascii": "Miami Shores",
    "lat": 25.867,
    "lng": -80.1779,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 10365,
    "id": 1840018334
  },
  {
    "city": "Shamva",
    "city_ascii": "Shamva",
    "lat": -17.3196,
    "lng": 31.57,
    "country": "Zimbabwe",
    "iso2": "ZW",
    "iso3": "ZWE",
    "admin_name": "Mashonaland Central",
    "capital": "",
    "population": 10317,
    "id": 1716210091
  },
  {
    "city": "Murfatlar",
    "city_ascii": "Murfatlar",
    "lat": 44.1736,
    "lng": 28.4083,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Constanţa",
    "capital": "",
    "population": 10216,
    "id": 1642326198
  },
  {
    "city": "Münchberg",
    "city_ascii": "Munchberg",
    "lat": 50.1831,
    "lng": 11.7857,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 10215,
    "id": 1276739492
  },
  {
    "city": "Bethanie",
    "city_ascii": "Bethanie",
    "lat": -26.4995,
    "lng": 17.15,
    "country": "Namibia",
    "iso2": "NA",
    "iso3": "NAM",
    "admin_name": "//Karas",
    "capital": "",
    "population": 10363,
    "id": 1516358571
  },
  {
    "city": "Levanger",
    "city_ascii": "Levanger",
    "lat": 63.7464,
    "lng": 11.2996,
    "country": "Norway",
    "iso2": "NO",
    "iso3": "NOR",
    "admin_name": "Nord-Trøndelag",
    "capital": "minor",
    "population": 10333,
    "id": 1578010304
  },
  {
    "city": "Kenzingen",
    "city_ascii": "Kenzingen",
    "lat": 48.1917,
    "lng": 7.7683,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 10089,
    "id": 1276334266
  },
  {
    "city": "Førde",
    "city_ascii": "Forde",
    "lat": 61.4519,
    "lng": 5.8569,
    "country": "Norway",
    "iso2": "NO",
    "iso3": "NOR",
    "admin_name": "Sogn og Fjordane",
    "capital": "minor",
    "population": 10339,
    "id": 1578914241
  },
  {
    "city": "Coal",
    "city_ascii": "Coal",
    "lat": 40.7876,
    "lng": -76.5489,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 10362,
    "id": 1840150358
  },
  {
    "city": "Taksimo",
    "city_ascii": "Taksimo",
    "lat": 56.3315,
    "lng": 114.89,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Buryatiya",
    "capital": "",
    "population": 10359,
    "id": 1643663655
  },
  {
    "city": "Cypress Gardens",
    "city_ascii": "Cypress Gardens",
    "lat": 28.0036,
    "lng": -81.6857,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 10358,
    "id": 1840028660
  },
  {
    "city": "Aydarken",
    "city_ascii": "Aydarken",
    "lat": 39.9333,
    "lng": 71.3333,
    "country": "Kyrgyzstan",
    "iso2": "KG",
    "iso3": "KGZ",
    "admin_name": "Batken",
    "capital": "",
    "population": 10331,
    "id": 1417865074
  },
  {
    "city": "Nagyatád",
    "city_ascii": "Nagyatad",
    "lat": 46.2294,
    "lng": 17.3575,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Somogy",
    "capital": "minor",
    "population": 10212,
    "id": 1348804654
  },
  {
    "city": "Aulendorf",
    "city_ascii": "Aulendorf",
    "lat": 47.9542,
    "lng": 9.6389,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 10180,
    "id": 1276668317
  },
  {
    "city": "Columbia",
    "city_ascii": "Columbia",
    "lat": 40.0348,
    "lng": -76.4944,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 10355,
    "id": 1840003713
  },
  {
    "city": "Lukovit",
    "city_ascii": "Lukovit",
    "lat": 43.2061,
    "lng": 24.1631,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Lovech",
    "capital": "",
    "population": 10316,
    "id": 1100460209
  },
  {
    "city": "Payerne",
    "city_ascii": "Payerne",
    "lat": 46.8167,
    "lng": 6.9333,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Vaud",
    "capital": "",
    "population": 9943,
    "id": 1756860201
  },
  {
    "city": "Waterloo",
    "city_ascii": "Waterloo",
    "lat": 38.3403,
    "lng": -90.1538,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 10354,
    "id": 1840010803
  },
  {
    "city": "Beverly Hills",
    "city_ascii": "Beverly Hills",
    "lat": 42.522,
    "lng": -83.2423,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 10352,
    "id": 1840011052
  },
  {
    "city": "Sobral de Monte Agraço",
    "city_ascii": "Sobral de Monte Agraco",
    "lat": 39.0167,
    "lng": -9.15,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Lisboa",
    "capital": "minor",
    "population": 10156,
    "id": 1620360918
  },
  {
    "city": "Lexington",
    "city_ascii": "Lexington",
    "lat": 40.7779,
    "lng": -99.7461,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Nebraska",
    "capital": "",
    "population": 10345,
    "id": 1840008285
  },
  {
    "city": "Neuenhaus",
    "city_ascii": "Neuenhaus",
    "lat": 52.5,
    "lng": 6.9667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 10025,
    "id": 1276154780
  },
  {
    "city": "Richmond Heights",
    "city_ascii": "Richmond Heights",
    "lat": 41.5589,
    "lng": -81.5029,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 10342,
    "id": 1840003409
  },
  {
    "city": "Jefferson",
    "city_ascii": "Jefferson",
    "lat": 29.9609,
    "lng": -90.1554,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 10341,
    "id": 1840013986
  },
  {
    "city": "Csorna",
    "city_ascii": "Csorna",
    "lat": 47.6167,
    "lng": 17.25,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Győr-Moson-Sopron",
    "capital": "minor",
    "population": 10228,
    "id": 1348687588
  },
  {
    "city": "Magnolia",
    "city_ascii": "Magnolia",
    "lat": 33.2774,
    "lng": -93.2261,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arkansas",
    "capital": "",
    "population": 10339,
    "id": 1840015726
  },
  {
    "city": "Summerville",
    "city_ascii": "Summerville",
    "lat": 34.4787,
    "lng": -85.3491,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 10331,
    "id": 1840015554
  },
  {
    "city": "Wellington",
    "city_ascii": "Wellington",
    "lat": 40.7,
    "lng": -105.0054,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 10329,
    "id": 1840022411
  },
  {
    "city": "Iwashita",
    "city_ascii": "Iwashita",
    "lat": 32.6514,
    "lng": 130.8117,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Kumamoto",
    "capital": "",
    "population": 10152,
    "id": 1392528224
  },
  {
    "city": "Fuller Heights",
    "city_ascii": "Fuller Heights",
    "lat": 27.9226,
    "lng": -81.9978,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 10327,
    "id": 1840025156
  },
  {
    "city": "Vytegra",
    "city_ascii": "Vytegra",
    "lat": 61,
    "lng": 36.45,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Vologodskaya Oblast’",
    "capital": "",
    "population": 10324,
    "id": 1643014673
  },
  {
    "city": "Králŭv Dvŭr",
    "city_ascii": "Kraluv Dvur",
    "lat": 49.9499,
    "lng": 14.0345,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Středočeský Kraj",
    "capital": "",
    "population": 9690,
    "id": 1203703070
  },
  {
    "city": "Kawara",
    "city_ascii": "Kawara",
    "lat": 33.6681,
    "lng": 130.8472,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Fukuoka",
    "capital": "",
    "population": 10099,
    "id": 1392191099
  },
  {
    "city": "Chavinda",
    "city_ascii": "Chavinda",
    "lat": 20.0167,
    "lng": -102.45,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Michoacán de Ocampo",
    "capital": "minor",
    "population": 10258,
    "id": 1484159886
  },
  {
    "city": "Lentvaris",
    "city_ascii": "Lentvaris",
    "lat": 54.6436,
    "lng": 25.0517,
    "country": "Lithuania",
    "iso2": "LT",
    "iso3": "LTU",
    "admin_name": "Trakai",
    "capital": "",
    "population": 10286,
    "id": 1440638790
  },
  {
    "city": "Skidal’",
    "city_ascii": "Skidal'",
    "lat": 53.5833,
    "lng": 24.25,
    "country": "Belarus",
    "iso2": "BY",
    "iso3": "BLR",
    "admin_name": "Hrodzyenskaya Voblasts’",
    "capital": "",
    "population": 10300,
    "id": 1112017248
  },
  {
    "city": "Shannon",
    "city_ascii": "Shannon",
    "lat": 52.7137,
    "lng": -8.8686,
    "country": "Ireland",
    "iso2": "IE",
    "iso3": "IRL",
    "admin_name": "Clare",
    "capital": "",
    "population": 8781,
    "id": 1372328164
  },
  {
    "city": "Aich",
    "city_ascii": "Aich",
    "lat": 48.6228,
    "lng": 9.2372,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 9901,
    "id": 1276245097
  },
  {
    "city": "Nor Hachn",
    "city_ascii": "Nor Hachn",
    "lat": 40.3,
    "lng": 44.58,
    "country": "Armenia",
    "iso2": "AM",
    "iso3": "ARM",
    "admin_name": "Kotayk’",
    "capital": "",
    "population": 10198,
    "id": 1051802790
  },
  {
    "city": "Tyukalinsk",
    "city_ascii": "Tyukalinsk",
    "lat": 55.8667,
    "lng": 72.2,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Omskaya Oblast’",
    "capital": "",
    "population": 10311,
    "id": 1643338887
  },
  {
    "city": "Banská Štiavnica",
    "city_ascii": "Banska Stiavnica",
    "lat": 48.4586,
    "lng": 18.8931,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Banskobystrický",
    "capital": "minor",
    "population": 10097,
    "id": 1703575986
  },
  {
    "city": "Coldstream",
    "city_ascii": "Coldstream",
    "lat": 50.22,
    "lng": -119.2481,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "British Columbia",
    "capital": "",
    "population": 10314,
    "id": 1124000216
  },
  {
    "city": "Bowdon",
    "city_ascii": "Bowdon",
    "lat": 53.376,
    "lng": -2.366,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Trafford",
    "capital": "",
    "population": 9228,
    "id": 1826944016
  },
  {
    "city": "Nanticoke",
    "city_ascii": "Nanticoke",
    "lat": 41.2004,
    "lng": -76.0003,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 10312,
    "id": 1840000761
  },
  {
    "city": "Bad Ems",
    "city_ascii": "Bad Ems",
    "lat": 50.3381,
    "lng": 7.7106,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Rhineland-Palatinate",
    "capital": "minor",
    "population": 9681,
    "id": 1276819164
  },
  {
    "city": "Chester",
    "city_ascii": "Chester",
    "lat": 44.65,
    "lng": -64.3,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Nova Scotia",
    "capital": "",
    "population": 10310,
    "id": 1124772236
  },
  {
    "city": "Teteven",
    "city_ascii": "Teteven",
    "lat": 42.9167,
    "lng": 24.2667,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Lovech",
    "capital": "",
    "population": 10279,
    "id": 1100032419
  },
  {
    "city": "Chavusy",
    "city_ascii": "Chavusy",
    "lat": 53.8,
    "lng": 30.9667,
    "country": "Belarus",
    "iso2": "BY",
    "iso3": "BLR",
    "admin_name": "Mahilyowskaya Voblasts’",
    "capital": "minor",
    "population": 10300,
    "id": 1112357951
  },
  {
    "city": "Hillsdale",
    "city_ascii": "Hillsdale",
    "lat": 41.0074,
    "lng": -74.044,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 10307,
    "id": 1840003560
  },
  {
    "city": "Queens",
    "city_ascii": "Queens",
    "lat": 44.0333,
    "lng": -64.7167,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Nova Scotia",
    "capital": "",
    "population": 10307,
    "id": 1124001652
  },
  {
    "city": "Kambarka",
    "city_ascii": "Kambarka",
    "lat": 56.2667,
    "lng": 54.2,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Udmurtiya",
    "capital": "",
    "population": 10276,
    "id": 1643279946
  },
  {
    "city": "Canton",
    "city_ascii": "Canton",
    "lat": 41.86,
    "lng": -72.9083,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 10306,
    "id": 1840034294
  },
  {
    "city": "Burgau",
    "city_ascii": "Burgau",
    "lat": 48.4322,
    "lng": 10.4069,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 9923,
    "id": 1276192712
  },
  {
    "city": "Fredonia",
    "city_ascii": "Fredonia",
    "lat": 42.4407,
    "lng": -79.3319,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 10303,
    "id": 1840004566
  },
  {
    "city": "Lanham",
    "city_ascii": "Lanham",
    "lat": 38.9621,
    "lng": -76.8421,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 10301,
    "id": 1840026687
  },
  {
    "city": "Fairview",
    "city_ascii": "Fairview",
    "lat": 37.676,
    "lng": -122.048,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 10299,
    "id": 1840028375
  },
  {
    "city": "Nehoiu",
    "city_ascii": "Nehoiu",
    "lat": 45.4153,
    "lng": 26.3082,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Buzău",
    "capital": "",
    "population": 10211,
    "id": 1642541496
  },
  {
    "city": "Forest",
    "city_ascii": "Forest",
    "lat": 37.3728,
    "lng": -79.2831,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 10298,
    "id": 1840006398
  },
  {
    "city": "Forest Acres",
    "city_ascii": "Forest Acres",
    "lat": 34.0324,
    "lng": -80.9716,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 10298,
    "id": 1840013653
  },
  {
    "city": "Oakengates",
    "city_ascii": "Oakengates",
    "lat": 52.695,
    "lng": -2.451,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Telford and Wrekin",
    "capital": "",
    "population": 8517,
    "id": 1826612540
  },
  {
    "city": "Fort Meade",
    "city_ascii": "Fort Meade",
    "lat": 39.1058,
    "lng": -76.7438,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 10297,
    "id": 1840073581
  },
  {
    "city": "Reading",
    "city_ascii": "Reading",
    "lat": 39.2243,
    "lng": -84.4333,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 10296,
    "id": 1840001611
  },
  {
    "city": "Zaozërnyy",
    "city_ascii": "Zaozernyy",
    "lat": 55.9667,
    "lng": 94.7,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Krasnoyarskiy Kray",
    "capital": "",
    "population": 10286,
    "id": 1643658238
  },
  {
    "city": "Sixaola",
    "city_ascii": "Sixaola",
    "lat": 9.5083,
    "lng": -82.6147,
    "country": "Costa Rica",
    "iso2": "CR",
    "iso3": "CRI",
    "admin_name": "Limón",
    "capital": "",
    "population": 10234,
    "id": 1188537249
  },
  {
    "city": "Southport",
    "city_ascii": "Southport",
    "lat": 42.04,
    "lng": -76.8774,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 10293,
    "id": 1840058508
  },
  {
    "city": "Hecelchakán",
    "city_ascii": "Hecelchakan",
    "lat": 20.1667,
    "lng": -90.1333,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Campeche",
    "capital": "minor",
    "population": 10285,
    "id": 1484700920
  },
  {
    "city": "London Colney",
    "city_ascii": "London Colney",
    "lat": 51.726,
    "lng": -0.3,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hertfordshire",
    "capital": "",
    "population": 9507,
    "id": 1826250958
  },
  {
    "city": "Weston",
    "city_ascii": "Weston",
    "lat": 41.2284,
    "lng": -73.3726,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 10288,
    "id": 1840035663
  },
  {
    "city": "Kilkunda",
    "city_ascii": "Kilkunda",
    "lat": 11.2569,
    "lng": 76.6697,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Tamil Nādu",
    "capital": "",
    "population": 10150,
    "id": 1356523989
  },
  {
    "city": "Corfe Mullen",
    "city_ascii": "Corfe Mullen",
    "lat": 50.7701,
    "lng": -2.0175,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Dorset",
    "capital": "",
    "population": 10133,
    "id": 1826707592
  },
  {
    "city": "Ertil",
    "city_ascii": "Ertil",
    "lat": 51.8333,
    "lng": 40.8,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Voronezhskaya Oblast’",
    "capital": "",
    "population": 10265,
    "id": 1643566170
  },
  {
    "city": "Chivhu",
    "city_ascii": "Chivhu",
    "lat": -19.0211,
    "lng": 30.8922,
    "country": "Zimbabwe",
    "iso2": "ZW",
    "iso3": "ZWE",
    "admin_name": "Mashonaland East",
    "capital": "",
    "population": 10263,
    "id": 1716626042
  },
  {
    "city": "Arden Hills",
    "city_ascii": "Arden Hills",
    "lat": 45.0721,
    "lng": -93.167,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 10281,
    "id": 1840006752
  },
  {
    "city": "Pine Ridge",
    "city_ascii": "Pine Ridge",
    "lat": 28.933,
    "lng": -82.4761,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 10279,
    "id": 1840028997
  },
  {
    "city": "Selkirk",
    "city_ascii": "Selkirk",
    "lat": 50.1436,
    "lng": -96.8839,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Manitoba",
    "capital": "",
    "population": 10278,
    "id": 1124499880
  },
  {
    "city": "Saint-Félicien",
    "city_ascii": "Saint-Felicien",
    "lat": 48.65,
    "lng": -72.45,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 10278,
    "id": 1124555496
  },
  {
    "city": "Nishi",
    "city_ascii": "Nishi",
    "lat": 32.2011,
    "lng": 130.8411,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Kumamoto",
    "capital": "",
    "population": 10155,
    "id": 1392580790
  },
  {
    "city": "Slatina",
    "city_ascii": "Slatina",
    "lat": 45.7,
    "lng": 17.7,
    "country": "Croatia",
    "iso2": "HR",
    "iso3": "HRV",
    "admin_name": "Virovitičko-Podravska Županija",
    "capital": "minor",
    "population": 10208,
    "id": 1191732993
  },
  {
    "city": "Independence",
    "city_ascii": "Independence",
    "lat": 44.8551,
    "lng": -123.1948,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 10272,
    "id": 1840018589
  },
  {
    "city": "Liphook",
    "city_ascii": "Liphook",
    "lat": 51.076,
    "lng": -0.803,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hampshire",
    "capital": "",
    "population": 8491,
    "id": 1826850012
  },
  {
    "city": "Doraville",
    "city_ascii": "Doraville",
    "lat": 33.9072,
    "lng": -84.2711,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 10265,
    "id": 1840014778
  },
  {
    "city": "Oster",
    "city_ascii": "Oster",
    "lat": 50.9486,
    "lng": 30.8811,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Chernihivs’ka Oblast’",
    "capital": "",
    "population": 10245,
    "id": 1804570315
  },
  {
    "city": "Caldera",
    "city_ascii": "Caldera",
    "lat": -27.0696,
    "lng": -70.83,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Atacama",
    "capital": "",
    "population": 10259,
    "id": 1152916235
  },
  {
    "city": "Hawkesbury",
    "city_ascii": "Hawkesbury",
    "lat": 45.6,
    "lng": -74.6,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 10263,
    "id": 1124065659
  },
  {
    "city": "Moimenta da Beira",
    "city_ascii": "Moimenta da Beira",
    "lat": 40.9819,
    "lng": -7.6158,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Viseu",
    "capital": "minor",
    "population": 10212,
    "id": 1620112700
  },
  {
    "city": "Meuselwitz",
    "city_ascii": "Meuselwitz",
    "lat": 51.05,
    "lng": 12.3,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Thuringia",
    "capital": "",
    "population": 10065,
    "id": 1276499309
  },
  {
    "city": "Eumseong",
    "city_ascii": "Eumseong",
    "lat": 36.9353,
    "lng": 127.6897,
    "country": "Korea, South",
    "iso2": "KR",
    "iso3": "KOR",
    "admin_name": "Chungbuk",
    "capital": "",
    "population": 10077,
    "id": 1410812829
  },
  {
    "city": "Plattekill",
    "city_ascii": "Plattekill",
    "lat": 41.6478,
    "lng": -74.0717,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 10251,
    "id": 1840058407
  },
  {
    "city": "Bonham",
    "city_ascii": "Bonham",
    "lat": 33.588,
    "lng": -96.1901,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 10250,
    "id": 1840019332
  },
  {
    "city": "Melvindale",
    "city_ascii": "Melvindale",
    "lat": 42.2802,
    "lng": -83.1782,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 10248,
    "id": 1840003986
  },
  {
    "city": "Wildberg",
    "city_ascii": "Wildberg",
    "lat": 48.6239,
    "lng": 8.7472,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 10069,
    "id": 1276603722
  },
  {
    "city": "Sauk Village",
    "city_ascii": "Sauk Village",
    "lat": 41.4905,
    "lng": -87.5706,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 10246,
    "id": 1840011290
  },
  {
    "city": "Marawī",
    "city_ascii": "Marawi",
    "lat": 18.4833,
    "lng": 31.8167,
    "country": "Sudan",
    "iso2": "SD",
    "iso3": "SDN",
    "admin_name": "Northern",
    "capital": "",
    "population": 10234,
    "id": 1729782394
  },
  {
    "city": "Clearfield",
    "city_ascii": "Clearfield",
    "lat": 41.0216,
    "lng": -78.439,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 10243,
    "id": 1840003490
  },
  {
    "city": "Andergrove",
    "city_ascii": "Andergrove",
    "lat": -21.0931,
    "lng": 149.186,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Queensland",
    "capital": "",
    "population": 9419,
    "id": 1036907443
  },
  {
    "city": "Lonquimay",
    "city_ascii": "Lonquimay",
    "lat": -38.4333,
    "lng": -71.2333,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Araucanía",
    "capital": "",
    "population": 10237,
    "id": 1152602801
  },
  {
    "city": "Radyvyliv",
    "city_ascii": "Radyvyliv",
    "lat": 50.1333,
    "lng": 25.25,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Rivnens’ka Oblast’",
    "capital": "minor",
    "population": 10190,
    "id": 1804330249
  },
  {
    "city": "Luza",
    "city_ascii": "Luza",
    "lat": 60.6167,
    "lng": 47.2833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kirovskaya Oblast’",
    "capital": "",
    "population": 10232,
    "id": 1643009448
  },
  {
    "city": "Wells",
    "city_ascii": "Wells",
    "lat": 43.3268,
    "lng": -70.6335,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maine",
    "capital": "",
    "population": 10235,
    "id": 1840053052
  },
  {
    "city": "Jesenice",
    "city_ascii": "Jesenice",
    "lat": 49.9682,
    "lng": 14.5136,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Středočeský Kraj",
    "capital": "",
    "population": 9682,
    "id": 1203216192
  },
  {
    "city": "Tarashcha",
    "city_ascii": "Tarashcha",
    "lat": 49.55,
    "lng": 30.5,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Kyyivs’ka Oblast’",
    "capital": "minor",
    "population": 10195,
    "id": 1804874474
  },
  {
    "city": "Blumberg",
    "city_ascii": "Blumberg",
    "lat": 47.8392,
    "lng": 8.5342,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 10127,
    "id": 1276268293
  },
  {
    "city": "Bad Orb",
    "city_ascii": "Bad Orb",
    "lat": 50.2167,
    "lng": 9.35,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 10020,
    "id": 1276166672
  },
  {
    "city": "Sparta",
    "city_ascii": "Sparta",
    "lat": 43.9378,
    "lng": -90.8131,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 10229,
    "id": 1840002565
  },
  {
    "city": "Flămânzi",
    "city_ascii": "Flamanzi",
    "lat": 47.5644,
    "lng": 26.8728,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Botoşani",
    "capital": "",
    "population": 10136,
    "id": 1642974440
  },
  {
    "city": "Box Elder",
    "city_ascii": "Box Elder",
    "lat": 44.1121,
    "lng": -103.0827,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Dakota",
    "capital": "",
    "population": 10228,
    "id": 1840002183
  },
  {
    "city": "Roberval",
    "city_ascii": "Roberval",
    "lat": 48.52,
    "lng": -72.23,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 10227,
    "id": 1124395055
  },
  {
    "city": "Bad Wildbad",
    "city_ascii": "Bad Wildbad",
    "lat": 48.7503,
    "lng": 8.5506,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 10130,
    "id": 1276260316
  },
  {
    "city": "Borzna",
    "city_ascii": "Borzna",
    "lat": 51.2333,
    "lng": 32.4167,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Chernihivs’ka Oblast’",
    "capital": "minor",
    "population": 10205,
    "id": 1804695729
  },
  {
    "city": "Snyatyn",
    "city_ascii": "Snyatyn",
    "lat": 48.45,
    "lng": 25.5667,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Ivano-Frankivs’ka Oblast’",
    "capital": "minor",
    "population": 9942,
    "id": 1804025263
  },
  {
    "city": "Sainte-Agathe-des-Monts",
    "city_ascii": "Sainte-Agathe-des-Monts",
    "lat": 46.05,
    "lng": -74.28,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 10223,
    "id": 1124041166
  },
  {
    "city": "Barrington",
    "city_ascii": "Barrington",
    "lat": 42.1515,
    "lng": -88.1281,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 10217,
    "id": 1840011244
  },
  {
    "city": "Baran’",
    "city_ascii": "Baran'",
    "lat": 54.4833,
    "lng": 30.3333,
    "country": "Belarus",
    "iso2": "BY",
    "iso3": "BLR",
    "admin_name": "Vitsyebskaya Voblasts’",
    "capital": "",
    "population": 10200,
    "id": 1112544646
  },
  {
    "city": "Mstsislaw",
    "city_ascii": "Mstsislaw",
    "lat": 54.0196,
    "lng": 31.7247,
    "country": "Belarus",
    "iso2": "BY",
    "iso3": "BLR",
    "admin_name": "Mahilyowskaya Voblasts’",
    "capital": "minor",
    "population": 10200,
    "id": 1112491469
  },
  {
    "city": "Pasadena Hills",
    "city_ascii": "Pasadena Hills",
    "lat": 28.2881,
    "lng": -82.238,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 10216,
    "id": 1840039051
  },
  {
    "city": "Middle Island",
    "city_ascii": "Middle Island",
    "lat": 40.8857,
    "lng": -72.9454,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 10216,
    "id": 1840005045
  },
  {
    "city": "North Dumfries",
    "city_ascii": "North Dumfries",
    "lat": 43.32,
    "lng": -80.38,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 10215,
    "id": 1124000802
  },
  {
    "city": "Nové Město na Moravě",
    "city_ascii": "Nove Mesto na Morave",
    "lat": 49.5615,
    "lng": 16.0742,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Vysočina",
    "capital": "",
    "population": 10049,
    "id": 1203071196
  },
  {
    "city": "Rehburg-Loccum",
    "city_ascii": "Rehburg-Loccum",
    "lat": 52.4508,
    "lng": 9.2078,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 10110,
    "id": 1276006999
  },
  {
    "city": "Lake Monticello",
    "city_ascii": "Lake Monticello",
    "lat": 37.921,
    "lng": -78.3295,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 10210,
    "id": 1840006342
  },
  {
    "city": "Fort Lupton",
    "city_ascii": "Fort Lupton",
    "lat": 40.0831,
    "lng": -104.8024,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 10208,
    "id": 1840020148
  },
  {
    "city": "Simbach am Inn",
    "city_ascii": "Simbach am Inn",
    "lat": 48.2656,
    "lng": 13.0231,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 9923,
    "id": 1276572176
  },
  {
    "city": "Rideau Lakes",
    "city_ascii": "Rideau Lakes",
    "lat": 44.6667,
    "lng": -76.2167,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 10207,
    "id": 1124000369
  },
  {
    "city": "Victoria",
    "city_ascii": "Victoria",
    "lat": 44.8634,
    "lng": -93.6586,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 10206,
    "id": 1840010007
  },
  {
    "city": "Aleşd",
    "city_ascii": "Alesd",
    "lat": 47.0572,
    "lng": 22.3969,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Bihor",
    "capital": "",
    "population": 10066,
    "id": 1642008624
  },
  {
    "city": "Göytəpə",
    "city_ascii": "Goytapa",
    "lat": 39.1167,
    "lng": 48.5953,
    "country": "Azerbaijan",
    "iso2": "AZ",
    "iso3": "AZE",
    "admin_name": "Cəlilabad",
    "capital": "",
    "population": 10067,
    "id": 1031848361
  },
  {
    "city": "Wahlstedt",
    "city_ascii": "Wahlstedt",
    "lat": 53.95,
    "lng": 10.2167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Schleswig-Holstein",
    "capital": "",
    "population": 9596,
    "id": 1276000715
  },
  {
    "city": "Niebüll",
    "city_ascii": "Niebull",
    "lat": 54.7881,
    "lng": 8.8297,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Schleswig-Holstein",
    "capital": "",
    "population": 9882,
    "id": 1276213521
  },
  {
    "city": "Přelouč",
    "city_ascii": "Prelouc",
    "lat": 50.0399,
    "lng": 15.5604,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Pardubický Kraj",
    "capital": "",
    "population": 9880,
    "id": 1203203039
  },
  {
    "city": "Bluffton",
    "city_ascii": "Bluffton",
    "lat": 40.7424,
    "lng": -85.173,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 10202,
    "id": 1840007178
  },
  {
    "city": "Southampton",
    "city_ascii": "Southampton",
    "lat": 39.9137,
    "lng": -74.7171,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 10201,
    "id": 1840081621
  },
  {
    "city": "Sechelt",
    "city_ascii": "Sechelt",
    "lat": 49.4742,
    "lng": -123.7542,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "British Columbia",
    "capital": "",
    "population": 10200,
    "id": 1124845591
  },
  {
    "city": "Aldenham",
    "city_ascii": "Aldenham",
    "lat": 51.6723,
    "lng": -0.3546,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hertfordshire",
    "capital": "",
    "population": 9942,
    "id": 1826963300
  },
  {
    "city": "Mikhaylov",
    "city_ascii": "Mikhaylov",
    "lat": 54.2333,
    "lng": 39.0333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Ryazanskaya Oblast’",
    "capital": "",
    "population": 10174,
    "id": 1643366422
  },
  {
    "city": "Beccles",
    "city_ascii": "Beccles",
    "lat": 52.458,
    "lng": 1.563,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Suffolk",
    "capital": "",
    "population": 10123,
    "id": 1826744549
  },
  {
    "city": "Moravská Třebová",
    "city_ascii": "Moravska Trebova",
    "lat": 49.758,
    "lng": 16.6643,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Pardubický Kraj",
    "capital": "",
    "population": 9948,
    "id": 1203455488
  },
  {
    "city": "Fife",
    "city_ascii": "Fife",
    "lat": 47.2328,
    "lng": -122.3518,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 10184,
    "id": 1840019853
  },
  {
    "city": "Almoloya del Río",
    "city_ascii": "Almoloya del Rio",
    "lat": 19.1586,
    "lng": -99.4886,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Morelos",
    "capital": "minor",
    "population": 8939,
    "id": 1484000708
  },
  {
    "city": "Covasna",
    "city_ascii": "Covasna",
    "lat": 45.8492,
    "lng": 26.1853,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Covasna",
    "capital": "",
    "population": 10114,
    "id": 1642162134
  },
  {
    "city": "Lehman",
    "city_ascii": "Lehman",
    "lat": 41.1518,
    "lng": -74.9924,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 10178,
    "id": 1840147215
  },
  {
    "city": "Somers Point",
    "city_ascii": "Somers Point",
    "lat": 39.3167,
    "lng": -74.6066,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 10174,
    "id": 1840001537
  },
  {
    "city": "Seyé",
    "city_ascii": "Seye",
    "lat": 20.8372,
    "lng": -89.3719,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Yucatán",
    "capital": "minor",
    "population": 8369,
    "id": 1484902665
  },
  {
    "city": "Union",
    "city_ascii": "Union",
    "lat": 38.44,
    "lng": -90.9927,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 10172,
    "id": 1840010769
  },
  {
    "city": "Yermolino",
    "city_ascii": "Yermolino",
    "lat": 55.2,
    "lng": 36.6,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kaluzhskaya Oblast’",
    "capital": "",
    "population": 10089,
    "id": 1643925447
  },
  {
    "city": "Moree",
    "city_ascii": "Moree",
    "lat": -29.465,
    "lng": 149.8344,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 9311,
    "id": 1036377829
  },
  {
    "city": "West Hanover",
    "city_ascii": "West Hanover",
    "lat": 40.3635,
    "lng": -76.7467,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 10165,
    "id": 1840150556
  },
  {
    "city": "Erquelinnes",
    "city_ascii": "Erquelinnes",
    "lat": 50.3101,
    "lng": 4.1219,
    "country": "Belgium",
    "iso2": "BE",
    "iso3": "BEL",
    "admin_name": "Wallonia",
    "capital": "",
    "population": 9940,
    "id": 1056578378
  },
  {
    "city": "Laakirchen",
    "city_ascii": "Laakirchen",
    "lat": 47.9828,
    "lng": 13.8242,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Oberösterreich",
    "capital": "",
    "population": 9861,
    "id": 1040055619
  },
  {
    "city": "Quartz Hill",
    "city_ascii": "Quartz Hill",
    "lat": 34.6527,
    "lng": -118.2163,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 10164,
    "id": 1840019210
  },
  {
    "city": "Gyangzê",
    "city_ascii": "Gyangze",
    "lat": 28.95,
    "lng": 89.6333,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Tibet",
    "capital": "minor",
    "population": 10000,
    "id": 1156755973
  },
  {
    "city": "New Port Richey East",
    "city_ascii": "New Port Richey East",
    "lat": 28.2605,
    "lng": -82.693,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 10161,
    "id": 1840073871
  },
  {
    "city": "Haxby",
    "city_ascii": "Haxby",
    "lat": 54.016,
    "lng": -1.075,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "York",
    "capital": "",
    "population": 8428,
    "id": 1826072985
  },
  {
    "city": "Pike Road",
    "city_ascii": "Pike Road",
    "lat": 32.2934,
    "lng": -86.0902,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 10159,
    "id": 1840017000
  },
  {
    "city": "Ozimek",
    "city_ascii": "Ozimek",
    "lat": 50.6731,
    "lng": 18.2131,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Opolskie",
    "capital": "",
    "population": 10000,
    "id": 1616756545
  },
  {
    "city": "Opochka",
    "city_ascii": "Opochka",
    "lat": 56.7167,
    "lng": 28.65,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Pskovskaya Oblast’",
    "capital": "minor",
    "population": 10148,
    "id": 1643125012
  },
  {
    "city": "Havre",
    "city_ascii": "Havre",
    "lat": 48.5427,
    "lng": -109.6803,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Montana",
    "capital": "",
    "population": 10155,
    "id": 1840019758
  },
  {
    "city": "Snohomish",
    "city_ascii": "Snohomish",
    "lat": 47.9276,
    "lng": -122.0968,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 10154,
    "id": 1840021078
  },
  {
    "city": "Brookhaven",
    "city_ascii": "Brookhaven",
    "lat": 31.5803,
    "lng": -90.4432,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Mississippi",
    "capital": "",
    "population": 10154,
    "id": 1840013862
  },
  {
    "city": "Heubach",
    "city_ascii": "Heubach",
    "lat": 48.7833,
    "lng": 9.9333,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 9774,
    "id": 1276568606
  },
  {
    "city": "Sîngera",
    "city_ascii": "Singera",
    "lat": 46.9131,
    "lng": 28.9775,
    "country": "Moldova",
    "iso2": "MD",
    "iso3": "MDA",
    "admin_name": "Chişinău",
    "capital": "",
    "population": 9966,
    "id": 1498679513
  },
  {
    "city": "La Misión",
    "city_ascii": "La Mision",
    "lat": 21.1,
    "lng": -99.1333,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Hidalgo",
    "capital": "",
    "population": 10096,
    "id": 1484006779
  },
  {
    "city": "Wyndham",
    "city_ascii": "Wyndham",
    "lat": 37.6924,
    "lng": -77.6123,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 10151,
    "id": 1840006396
  },
  {
    "city": "Tashir",
    "city_ascii": "Tashir",
    "lat": 41.1144,
    "lng": 44.2906,
    "country": "Armenia",
    "iso2": "AM",
    "iso3": "ARM",
    "admin_name": "Lorri",
    "capital": "",
    "population": 10090,
    "id": 1051680734
  },
  {
    "city": "Welwyn",
    "city_ascii": "Welwyn",
    "lat": 51.831,
    "lng": -0.215,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hertfordshire",
    "capital": "",
    "population": 8425,
    "id": 1826187003
  },
  {
    "city": "Choele Choel",
    "city_ascii": "Choele Choel",
    "lat": -39.2858,
    "lng": -65.6542,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Río Negro",
    "capital": "minor",
    "population": 10146,
    "id": 1032421959
  },
  {
    "city": "College",
    "city_ascii": "College",
    "lat": 40.8144,
    "lng": -77.8172,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 10147,
    "id": 1840144819
  },
  {
    "city": "Kami-kawabe",
    "city_ascii": "Kami-kawabe",
    "lat": 35.4867,
    "lng": 137.0706,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Gifu",
    "capital": "",
    "population": 9902,
    "id": 1392777604
  },
  {
    "city": "Waynesville",
    "city_ascii": "Waynesville",
    "lat": 35.4854,
    "lng": -82.9995,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 10141,
    "id": 1840016297
  },
  {
    "city": "Timonium",
    "city_ascii": "Timonium",
    "lat": 39.4463,
    "lng": -76.6083,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 10140,
    "id": 1840026624
  },
  {
    "city": "Rinópolis",
    "city_ascii": "Rinopolis",
    "lat": -21.7258,
    "lng": -50.7219,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 10133,
    "id": 1076562968
  },
  {
    "city": "Clarion",
    "city_ascii": "Clarion",
    "lat": 41.2106,
    "lng": -79.3803,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 10138,
    "id": 1840000739
  },
  {
    "city": "Kutztown",
    "city_ascii": "Kutztown",
    "lat": 40.5213,
    "lng": -75.7772,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 10137,
    "id": 1840001178
  },
  {
    "city": "Zavolzhsk",
    "city_ascii": "Zavolzhsk",
    "lat": 57.4833,
    "lng": 42.1333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Ivanovskaya Oblast’",
    "capital": "",
    "population": 10121,
    "id": 1643171028
  },
  {
    "city": "Progress Village",
    "city_ascii": "Progress Village",
    "lat": 27.8832,
    "lng": -82.3593,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 10136,
    "id": 1840028917
  },
  {
    "city": "Fairview Township",
    "city_ascii": "Fairview Township",
    "lat": 42.0261,
    "lng": -80.2361,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 10136,
    "id": 1840144222
  },
  {
    "city": "Schotten",
    "city_ascii": "Schotten",
    "lat": 50.5,
    "lng": 9.1167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 10059,
    "id": 1276543859
  },
  {
    "city": "Steinhaus",
    "city_ascii": "Steinhaus",
    "lat": 47.1969,
    "lng": 8.4861,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Luzern",
    "capital": "",
    "population": 10025,
    "id": 1756020648
  },
  {
    "city": "Fort Madison",
    "city_ascii": "Fort Madison",
    "lat": 40.6207,
    "lng": -91.3509,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Iowa",
    "capital": "",
    "population": 10130,
    "id": 1840008321
  },
  {
    "city": "Salaverry",
    "city_ascii": "Salaverry",
    "lat": -8.22,
    "lng": -78.99,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "La Libertad",
    "capital": "",
    "population": 10066,
    "id": 1604741233
  },
  {
    "city": "Danville",
    "city_ascii": "Danville",
    "lat": 39.7603,
    "lng": -86.5076,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 10126,
    "id": 1840009539
  },
  {
    "city": "Tsunō",
    "city_ascii": "Tsuno",
    "lat": 32.2567,
    "lng": 131.5597,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Miyazaki",
    "capital": "",
    "population": 10028,
    "id": 1392643881
  },
  {
    "city": "Keuruu",
    "city_ascii": "Keuruu",
    "lat": 62.2597,
    "lng": 24.7069,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Keski-Suomi",
    "capital": "minor",
    "population": 10117,
    "id": 1246102142
  },
  {
    "city": "Vienna",
    "city_ascii": "Vienna",
    "lat": 39.324,
    "lng": -81.5383,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "West Virginia",
    "capital": "",
    "population": 10124,
    "id": 1840005787
  },
  {
    "city": "Manville",
    "city_ascii": "Manville",
    "lat": 40.5421,
    "lng": -74.5892,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 10121,
    "id": 1840003610
  },
  {
    "city": "Kirkland",
    "city_ascii": "Kirkland",
    "lat": 43.0368,
    "lng": -75.3865,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 10121,
    "id": 1840058218
  },
  {
    "city": "Bel Air",
    "city_ascii": "Bel Air",
    "lat": 39.5348,
    "lng": -76.346,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 10119,
    "id": 1840005668
  },
  {
    "city": "Old Saybrook",
    "city_ascii": "Old Saybrook",
    "lat": 41.3017,
    "lng": -72.3857,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 10118,
    "id": 1840045060
  },
  {
    "city": "Warr Acres",
    "city_ascii": "Warr Acres",
    "lat": 35.5285,
    "lng": -97.6182,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oklahoma",
    "capital": "",
    "population": 10118,
    "id": 1840021743
  },
  {
    "city": "Franklin",
    "city_ascii": "Franklin",
    "lat": 36.7178,
    "lng": -86.5595,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kentucky",
    "capital": "",
    "population": 10118,
    "id": 1840013290
  },
  {
    "city": "Rüdesheim am Rhein",
    "city_ascii": "Rudesheim am Rhein",
    "lat": 49.979,
    "lng": 7.9234,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 9922,
    "id": 1276263757
  },
  {
    "city": "Judenburg",
    "city_ascii": "Judenburg",
    "lat": 47.1725,
    "lng": 14.6603,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Steiermark",
    "capital": "minor",
    "population": 9960,
    "id": 1040723538
  },
  {
    "city": "Tipp City",
    "city_ascii": "Tipp City",
    "lat": 39.9643,
    "lng": -84.1858,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 10115,
    "id": 1840010507
  },
  {
    "city": "Klötze",
    "city_ascii": "Klotze",
    "lat": 52.6272,
    "lng": 11.1642,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony-Anhalt",
    "capital": "",
    "population": 10077,
    "id": 1276729835
  },
  {
    "city": "Lincoln Park",
    "city_ascii": "Lincoln Park",
    "lat": 40.9239,
    "lng": -74.3035,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 10111,
    "id": 1840000953
  },
  {
    "city": "Struthers",
    "city_ascii": "Struthers",
    "lat": 41.051,
    "lng": -80.592,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 10111,
    "id": 1840003562
  },
  {
    "city": "Chapel en le Frith",
    "city_ascii": "Chapel en le Frith",
    "lat": 53.322,
    "lng": -1.917,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Derbyshire",
    "capital": "",
    "population": 8635,
    "id": 1826041922
  },
  {
    "city": "North Glengarry",
    "city_ascii": "North Glengarry",
    "lat": 45.3333,
    "lng": -74.7333,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 10109,
    "id": 1124000836
  },
  {
    "city": "Kurort Steinbach-Hallenberg",
    "city_ascii": "Kurort Steinbach-Hallenberg",
    "lat": 50.7006,
    "lng": 10.5667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Thuringia",
    "capital": "",
    "population": 9681,
    "id": 1276381862
  },
  {
    "city": "Satipo",
    "city_ascii": "Satipo",
    "lat": -11.2522,
    "lng": -74.6386,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "Junín",
    "capital": "",
    "population": 10052,
    "id": 1604355080
  },
  {
    "city": "Digora",
    "city_ascii": "Digora",
    "lat": 43.15,
    "lng": 44.15,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "North Ossetia",
    "capital": "",
    "population": 10075,
    "id": 1643075544
  },
  {
    "city": "Waldwick",
    "city_ascii": "Waldwick",
    "lat": 41.0134,
    "lng": -74.1259,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 10108,
    "id": 1840000921
  },
  {
    "city": "Shawano",
    "city_ascii": "Shawano",
    "lat": 44.7748,
    "lng": -88.5843,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 10107,
    "id": 1840002266
  },
  {
    "city": "Mountsorrel",
    "city_ascii": "Mountsorrel",
    "lat": 52.7167,
    "lng": -1.15,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Leicestershire",
    "capital": "",
    "population": 8223,
    "id": 1826286160
  },
  {
    "city": "Nogliki",
    "city_ascii": "Nogliki",
    "lat": 51.8333,
    "lng": 143.1667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sakhalinskaya Oblast’",
    "capital": "",
    "population": 10098,
    "id": 1643874280
  },
  {
    "city": "Nideggen",
    "city_ascii": "Nideggen",
    "lat": 50.7,
    "lng": 6.4833,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 9945,
    "id": 1276330889
  },
  {
    "city": "South Huron",
    "city_ascii": "South Huron",
    "lat": 43.32,
    "lng": -81.5,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 10096,
    "id": 1124000910
  },
  {
    "city": "Mengen",
    "city_ascii": "Mengen",
    "lat": 48.0497,
    "lng": 9.33,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 9896,
    "id": 1276073963
  },
  {
    "city": "Marieville",
    "city_ascii": "Marieville",
    "lat": 45.4333,
    "lng": -73.1667,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 10094,
    "id": 1124834229
  },
  {
    "city": "Ontario",
    "city_ascii": "Ontario",
    "lat": 43.2407,
    "lng": -77.314,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 10088,
    "id": 1840058362
  },
  {
    "city": "Lysá nad Labem",
    "city_ascii": "Lysa nad Labem",
    "lat": 50.2015,
    "lng": 14.8329,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Středočeský Kraj",
    "capital": "",
    "population": 9795,
    "id": 1203091035
  },
  {
    "city": "Cedar Hills",
    "city_ascii": "Cedar Hills",
    "lat": 40.4135,
    "lng": -111.753,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Utah",
    "capital": "",
    "population": 10083,
    "id": 1840018754
  },
  {
    "city": "Hobart",
    "city_ascii": "Hobart",
    "lat": 44.4967,
    "lng": -88.1602,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 10082,
    "id": 1840038100
  },
  {
    "city": "North Versailles",
    "city_ascii": "North Versailles",
    "lat": 40.3784,
    "lng": -79.8083,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 10081,
    "id": 1840133154
  },
  {
    "city": "Oldenburg in Holstein",
    "city_ascii": "Oldenburg in Holstein",
    "lat": 54.2922,
    "lng": 10.8867,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Schleswig-Holstein",
    "capital": "",
    "population": 9833,
    "id": 1276671809
  },
  {
    "city": "Crigglestone",
    "city_ascii": "Crigglestone",
    "lat": 53.644,
    "lng": -1.5226,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Wakefield",
    "capital": "",
    "population": 9271,
    "id": 1826293505
  },
  {
    "city": "Conway",
    "city_ascii": "Conway",
    "lat": 44.0085,
    "lng": -71.0719,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Hampshire",
    "capital": "",
    "population": 10078,
    "id": 1840054762
  },
  {
    "city": "Cresson",
    "city_ascii": "Cresson",
    "lat": 40.4626,
    "lng": -78.5866,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 10078,
    "id": 1840001105
  },
  {
    "city": "Makale",
    "city_ascii": "Makale",
    "lat": -3.086,
    "lng": 119.8469,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Sulawesi Selatan",
    "capital": "minor",
    "population": 9960,
    "id": 1360576738
  },
  {
    "city": "Clinton",
    "city_ascii": "Clinton",
    "lat": 36.0981,
    "lng": -84.1283,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 10075,
    "id": 1840014471
  },
  {
    "city": "Southborough",
    "city_ascii": "Southborough",
    "lat": 42.3012,
    "lng": -71.5297,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 10074,
    "id": 1840053604
  },
  {
    "city": "Standish",
    "city_ascii": "Standish",
    "lat": 43.7811,
    "lng": -70.5685,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maine",
    "capital": "",
    "population": 10073,
    "id": 1840053009
  },
  {
    "city": "Edwards",
    "city_ascii": "Edwards",
    "lat": 39.6215,
    "lng": -106.6183,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 10071,
    "id": 1840017535
  },
  {
    "city": "Garden City",
    "city_ascii": "Garden City",
    "lat": 33.5927,
    "lng": -79.007,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 10070,
    "id": 1840013645
  },
  {
    "city": "Nolensville",
    "city_ascii": "Nolensville",
    "lat": 35.9572,
    "lng": -86.672,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 10062,
    "id": 1840016219
  },
  {
    "city": "Glocester",
    "city_ascii": "Glocester",
    "lat": 41.8934,
    "lng": -71.6889,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Rhode Island",
    "capital": "",
    "population": 10062,
    "id": 1840106230
  },
  {
    "city": "Camden",
    "city_ascii": "Camden",
    "lat": 33.5672,
    "lng": -92.8467,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arkansas",
    "capital": "",
    "population": 10058,
    "id": 1840013736
  },
  {
    "city": "Kargopol",
    "city_ascii": "Kargopol",
    "lat": 61.5,
    "lng": 38.9333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Arkhangel’skaya Oblast’",
    "capital": "",
    "population": 10055,
    "id": 1643025947
  },
  {
    "city": "Wood River",
    "city_ascii": "Wood River",
    "lat": 38.8631,
    "lng": -90.0773,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 10051,
    "id": 1840010711
  },
  {
    "city": "Akabira",
    "city_ascii": "Akabira",
    "lat": 43.5581,
    "lng": 142.0442,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hokkaidō",
    "capital": "",
    "population": 9971,
    "id": 1392691701
  },
  {
    "city": "Ollerton",
    "city_ascii": "Ollerton",
    "lat": 53.2,
    "lng": -1.02,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Nottinghamshire",
    "capital": "",
    "population": 9840,
    "id": 1826540290
  },
  {
    "city": "Kubrat",
    "city_ascii": "Kubrat",
    "lat": 43.7967,
    "lng": 26.5011,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Razgrad",
    "capital": "minor",
    "population": 10011,
    "id": 1100746291
  },
  {
    "city": "Sleepy Hollow",
    "city_ascii": "Sleepy Hollow",
    "lat": 41.0936,
    "lng": -73.8724,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 10046,
    "id": 1840004944
  },
  {
    "city": "Milford Township",
    "city_ascii": "Milford Township",
    "lat": 40.4291,
    "lng": -75.4153,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 10046,
    "id": 1840151115
  },
  {
    "city": "Sankt Andrä",
    "city_ascii": "Sankt Andra",
    "lat": 46.7667,
    "lng": 14.8167,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Kärnten",
    "capital": "",
    "population": 9957,
    "id": 1040740376
  },
  {
    "city": "Šurany",
    "city_ascii": "Surany",
    "lat": 48.0833,
    "lng": 18.1833,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Nitriansky",
    "capital": "",
    "population": 9878,
    "id": 1703675308
  },
  {
    "city": "Fair Oaks Ranch",
    "city_ascii": "Fair Oaks Ranch",
    "lat": 29.7468,
    "lng": -98.6375,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 10042,
    "id": 1840020939
  },
  {
    "city": "Countryside",
    "city_ascii": "Countryside",
    "lat": 39.0518,
    "lng": -77.4124,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 10042,
    "id": 1840024500
  },
  {
    "city": "Hlinsko",
    "city_ascii": "Hlinsko",
    "lat": 49.7622,
    "lng": 15.9076,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Pardubický Kraj",
    "capital": "",
    "population": 9642,
    "id": 1203108794
  },
  {
    "city": "Roboré",
    "city_ascii": "Robore",
    "lat": -18.3295,
    "lng": -59.76,
    "country": "Bolivia",
    "iso2": "BO",
    "iso3": "BOL",
    "admin_name": "Santa Cruz",
    "capital": "",
    "population": 10036,
    "id": 1068498462
  },
  {
    "city": "Valea lui Mihai",
    "city_ascii": "Valea lui Mihai",
    "lat": 47.52,
    "lng": 22.13,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Bihor",
    "capital": "",
    "population": 9902,
    "id": 1642733254
  },
  {
    "city": "Vashon",
    "city_ascii": "Vashon",
    "lat": 47.4122,
    "lng": -122.4726,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 10036,
    "id": 1840018414
  },
  {
    "city": "Moba",
    "city_ascii": "Moba",
    "lat": -7.0596,
    "lng": 29.72,
    "country": "Congo (Kinshasa)",
    "iso2": "CD",
    "iso3": "COD",
    "admin_name": "Tanganyika",
    "capital": "",
    "population": 10006,
    "id": 1180776583
  },
  {
    "city": "Holmen",
    "city_ascii": "Holmen",
    "lat": 43.9699,
    "lng": -91.2661,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 10034,
    "id": 1840002576
  },
  {
    "city": "Santa María Ajoloapan",
    "city_ascii": "Santa Maria Ajoloapan",
    "lat": 19.9692,
    "lng": -99.0353,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Morelos",
    "capital": "",
    "population": 9185,
    "id": 1484494406
  },
  {
    "city": "Tay",
    "city_ascii": "Tay",
    "lat": 44.7167,
    "lng": -79.7667,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 10033,
    "id": 1124001057
  },
  {
    "city": "Alden",
    "city_ascii": "Alden",
    "lat": 42.9114,
    "lng": -78.5211,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 10032,
    "id": 1840004394
  },
  {
    "city": "Coweta",
    "city_ascii": "Coweta",
    "lat": 35.968,
    "lng": -95.6543,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oklahoma",
    "capital": "",
    "population": 10032,
    "id": 1840019077
  },
  {
    "city": "Ohrdruf",
    "city_ascii": "Ohrdruf",
    "lat": 50.8281,
    "lng": 10.7328,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Thuringia",
    "capital": "",
    "population": 9784,
    "id": 1276929737
  },
  {
    "city": "Warrenton",
    "city_ascii": "Warrenton",
    "lat": 38.7176,
    "lng": -77.7975,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 10027,
    "id": 1840006058
  },
  {
    "city": "Volodarsk",
    "city_ascii": "Volodarsk",
    "lat": 56.2333,
    "lng": 43.2,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Nizhegorodskaya Oblast’",
    "capital": "",
    "population": 9972,
    "id": 1643851131
  },
  {
    "city": "Purkersdorf",
    "city_ascii": "Purkersdorf",
    "lat": 48.2092,
    "lng": 16.1792,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Niederösterreich",
    "capital": "",
    "population": 9701,
    "id": 1040636796
  },
  {
    "city": "Pleasant Hill",
    "city_ascii": "Pleasant Hill",
    "lat": 41.5868,
    "lng": -93.4952,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Iowa",
    "capital": "",
    "population": 10019,
    "id": 1840009219
  },
  {
    "city": "Taltal",
    "city_ascii": "Taltal",
    "lat": -25.4,
    "lng": -70.47,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Antofagasta",
    "capital": "",
    "population": 10018,
    "id": 1152265576
  },
  {
    "city": "Vuktyl",
    "city_ascii": "Vuktyl",
    "lat": 63.8667,
    "lng": 57.3167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Komi",
    "capital": "",
    "population": 10017,
    "id": 1643046290
  },
  {
    "city": "Senanga",
    "city_ascii": "Senanga",
    "lat": -16.1196,
    "lng": 23.27,
    "country": "Zambia",
    "iso2": "ZM",
    "iso3": "ZMB",
    "admin_name": "Western",
    "capital": "",
    "population": 10005,
    "id": 1894742251
  },
  {
    "city": "Alajärvi",
    "city_ascii": "Alajarvi",
    "lat": 63,
    "lng": 23.8167,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Etelä-Pohjanmaa",
    "capital": "minor",
    "population": 10006,
    "id": 1246799974
  },
  {
    "city": "Aveley",
    "city_ascii": "Aveley",
    "lat": 51.5018,
    "lng": 0.2534,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Thurrock",
    "capital": "",
    "population": 8381,
    "id": 1826305482
  },
  {
    "city": "Byalynichy",
    "city_ascii": "Byalynichy",
    "lat": 53.9956,
    "lng": 29.7096,
    "country": "Belarus",
    "iso2": "BY",
    "iso3": "BLR",
    "admin_name": "Mahilyowskaya Voblasts’",
    "capital": "minor",
    "population": 10000,
    "id": 1112253671
  },
  {
    "city": "Bee Ridge",
    "city_ascii": "Bee Ridge",
    "lat": 27.2855,
    "lng": -82.4731,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 10012,
    "id": 1840028632
  },
  {
    "city": "Pyetrykaw",
    "city_ascii": "Pyetrykaw",
    "lat": 52.1333,
    "lng": 28.5,
    "country": "Belarus",
    "iso2": "BY",
    "iso3": "BLR",
    "admin_name": "Homyel’skaya Voblasts’",
    "capital": "minor",
    "population": 10000,
    "id": 1112852097
  },
  {
    "city": "Laurens",
    "city_ascii": "Laurens",
    "lat": 34.5022,
    "lng": -82.0207,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 10008,
    "id": 1840014668
  },
  {
    "city": "Uchoa",
    "city_ascii": "Uchoa",
    "lat": -20.9528,
    "lng": -49.175,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 9968,
    "id": 1076170916
  },
  {
    "city": "Flat Rock",
    "city_ascii": "Flat Rock",
    "lat": 42.0991,
    "lng": -83.2716,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 10004,
    "id": 1840003973
  },
  {
    "city": "Molalla",
    "city_ascii": "Molalla",
    "lat": 45.1502,
    "lng": -122.5844,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 10001,
    "id": 1840019953
  },
  {
    "city": "Gar",
    "city_ascii": "Gar",
    "lat": 32.2004,
    "lng": 79.9833,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Tibet",
    "capital": "minor",
    "population": 10000,
    "id": 1156975633
  },
  {
    "city": "South Huntington",
    "city_ascii": "South Huntington",
    "lat": 40.8225,
    "lng": -73.3921,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 10000,
    "id": 1840005100
  },
  {
    "city": "Bude",
    "city_ascii": "Bude",
    "lat": 50.824,
    "lng": -4.542,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Cornwall",
    "capital": "",
    "population": 9222,
    "id": 1826412704
  },
  {
    "city": "Fairmount",
    "city_ascii": "Fairmount",
    "lat": 43.0414,
    "lng": -76.2485,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 9997,
    "id": 1840000377
  },
  {
    "city": "Fort Oglethorpe",
    "city_ascii": "Fort Oglethorpe",
    "lat": 34.9319,
    "lng": -85.246,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 9994,
    "id": 1840013554
  },
  {
    "city": "Marlton",
    "city_ascii": "Marlton",
    "lat": 39.9016,
    "lng": -74.9297,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 9990,
    "id": 1840005522
  },
  {
    "city": "Darabani",
    "city_ascii": "Darabani",
    "lat": 48.1864,
    "lng": 26.5892,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Botoşani",
    "capital": "",
    "population": 9893,
    "id": 1642289705
  },
  {
    "city": "Buckie",
    "city_ascii": "Buckie",
    "lat": 57.676,
    "lng": -2.965,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Moray",
    "capital": "",
    "population": 8273,
    "id": 1826944488
  },
  {
    "city": "Vera",
    "city_ascii": "Vera",
    "lat": -29.4662,
    "lng": -60.2166,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Santa Fe",
    "capital": "minor",
    "population": 9979,
    "id": 1032305174
  },
  {
    "city": "Alcoa",
    "city_ascii": "Alcoa",
    "lat": 35.8073,
    "lng": -83.9752,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 9980,
    "id": 1840013404
  },
  {
    "city": "River Vale",
    "city_ascii": "River Vale",
    "lat": 41.0136,
    "lng": -74.008,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 9976,
    "id": 1840081804
  },
  {
    "city": "Töging am Inn",
    "city_ascii": "Toging am Inn",
    "lat": 48.272,
    "lng": 12.6059,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 9291,
    "id": 1276814406
  },
  {
    "city": "Mīāndasht",
    "city_ascii": "Miandasht",
    "lat": 33.0736,
    "lng": 50.1647,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Eşfahān",
    "capital": "",
    "population": 9933,
    "id": 1364540083
  },
  {
    "city": "Bad König",
    "city_ascii": "Bad Konig",
    "lat": 49.7413,
    "lng": 9.0071,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 9762,
    "id": 1276917706
  },
  {
    "city": "Oued Laou",
    "city_ascii": "Oued Laou",
    "lat": 35.45,
    "lng": -5.0833,
    "country": "Morocco",
    "iso2": "MA",
    "iso3": "MAR",
    "admin_name": "Tanger-Tétouan-Al Hoceïma",
    "capital": "",
    "population": 9665,
    "id": 1504708598
  },
  {
    "city": "Pereshchepyne",
    "city_ascii": "Pereshchepyne",
    "lat": 49.0179,
    "lng": 35.3598,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Dnipropetrovs’ka Oblast’",
    "capital": "",
    "population": 9931,
    "id": 1804752457
  },
  {
    "city": "Daruvar",
    "city_ascii": "Daruvar",
    "lat": 45.6,
    "lng": 17.2167,
    "country": "Croatia",
    "iso2": "HR",
    "iso3": "HRV",
    "admin_name": "Bjelovarsko-Bilogorska Županija",
    "capital": "minor",
    "population": 9815,
    "id": 1191459511
  },
  {
    "city": "Santa Cruz Atizapán",
    "city_ascii": "Santa Cruz Atizapan",
    "lat": 19.1756,
    "lng": -99.4886,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "México",
    "capital": "minor",
    "population": 8909,
    "id": 1484011710
  },
  {
    "city": "Ivančice",
    "city_ascii": "Ivancice",
    "lat": 49.1015,
    "lng": 16.3775,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Jihomoravský Kraj",
    "capital": "",
    "population": 9760,
    "id": 1203249406
  },
  {
    "city": "Orland",
    "city_ascii": "Orland",
    "lat": 39.7461,
    "lng": -122.1856,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 9964,
    "id": 1840020209
  },
  {
    "city": "Biei",
    "city_ascii": "Biei",
    "lat": 43.5883,
    "lng": 142.4669,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hokkaidō",
    "capital": "",
    "population": 9948,
    "id": 1392568507
  },
  {
    "city": "Helotes",
    "city_ascii": "Helotes",
    "lat": 29.5693,
    "lng": -98.6956,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 9961,
    "id": 1840020957
  },
  {
    "city": "East Bradford",
    "city_ascii": "East Bradford",
    "lat": 39.959,
    "lng": -75.6469,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 9959,
    "id": 1840149882
  },
  {
    "city": "Haddington",
    "city_ascii": "Haddington",
    "lat": 55.958,
    "lng": -2.774,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "East Lothian",
    "capital": "",
    "population": 9944,
    "id": 1826363081
  },
  {
    "city": "Monte Escobedo",
    "city_ascii": "Monte Escobedo",
    "lat": 22.3,
    "lng": -103.5667,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Zacatecas",
    "capital": "minor",
    "population": 8929,
    "id": 1484046726
  },
  {
    "city": "Tha Mai",
    "city_ascii": "Tha Mai",
    "lat": 12.6196,
    "lng": 102.0112,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Chanthaburi",
    "capital": "minor",
    "population": 9843,
    "id": 1764588619
  },
  {
    "city": "Key Largo",
    "city_ascii": "Key Largo",
    "lat": 25.1224,
    "lng": -80.412,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 9954,
    "id": 1840014253
  },
  {
    "city": "Piedras Blancas",
    "city_ascii": "Piedras Blancas",
    "lat": 43.56,
    "lng": -5.976,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Asturias",
    "capital": "",
    "population": 9533,
    "id": 1724519867
  },
  {
    "city": "Colts Neck",
    "city_ascii": "Colts Neck",
    "lat": 40.2928,
    "lng": -74.168,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 9948,
    "id": 1840081833
  },
  {
    "city": "Litovel",
    "city_ascii": "Litovel",
    "lat": 49.7012,
    "lng": 17.0762,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Olomoucký Kraj",
    "capital": "",
    "population": 9738,
    "id": 1203150242
  },
  {
    "city": "Madera Acres",
    "city_ascii": "Madera Acres",
    "lat": 37.0123,
    "lng": -120.0799,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 9946,
    "id": 1840028386
  },
  {
    "city": "Néa Moudaniá",
    "city_ascii": "Nea Moudania",
    "lat": 40.2386,
    "lng": 23.2814,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Kentrikí Makedonía",
    "capital": "minor",
    "population": 9342,
    "id": 1300681996
  },
  {
    "city": "Zell am See",
    "city_ascii": "Zell am See",
    "lat": 47.3233,
    "lng": 12.7981,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Salzburg",
    "capital": "minor",
    "population": 9762,
    "id": 1040352901
  },
  {
    "city": "Gunnedah",
    "city_ascii": "Gunnedah",
    "lat": -30.9667,
    "lng": 150.25,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 9726,
    "id": 1036500021
  },
  {
    "city": "Zaozërsk",
    "city_ascii": "Zaozersk",
    "lat": 69.4,
    "lng": 32.45,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Murmanskaya Oblast’",
    "capital": "",
    "population": 9915,
    "id": 1643823652
  },
  {
    "city": "Littleton",
    "city_ascii": "Littleton",
    "lat": 42.535,
    "lng": -71.4891,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 9935,
    "id": 1840070362
  },
  {
    "city": "Bad Breisig",
    "city_ascii": "Bad Breisig",
    "lat": 50.5092,
    "lng": 7.2964,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Rhineland-Palatinate",
    "capital": "",
    "population": 9460,
    "id": 1276102640
  },
  {
    "city": "Yashima",
    "city_ascii": "Yashima",
    "lat": 32.74,
    "lng": 130.7572,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Kumamoto",
    "capital": "",
    "population": 9371,
    "id": 1392284053
  },
  {
    "city": "Bad Gandersheim",
    "city_ascii": "Bad Gandersheim",
    "lat": 51.8719,
    "lng": 10.0253,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 9823,
    "id": 1276778177
  },
  {
    "city": "Winterville",
    "city_ascii": "Winterville",
    "lat": 35.5287,
    "lng": -77.3994,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 9931,
    "id": 1840016279
  },
  {
    "city": "Bogandé",
    "city_ascii": "Bogande",
    "lat": 12.969,
    "lng": -0.138,
    "country": "Burkina Faso",
    "iso2": "BF",
    "iso3": "BFA",
    "admin_name": "Est",
    "capital": "minor",
    "population": 9854,
    "id": 1854811035
  },
  {
    "city": "Urzhum",
    "city_ascii": "Urzhum",
    "lat": 57.1167,
    "lng": 50,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kirovskaya Oblast’",
    "capital": "",
    "population": 9919,
    "id": 1643578582
  },
  {
    "city": "Ramsey",
    "city_ascii": "Ramsey",
    "lat": 52.45,
    "lng": -0.12,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Cambridgeshire",
    "capital": "",
    "population": 8479,
    "id": 1826625567
  },
  {
    "city": "Pórto Ráfti",
    "city_ascii": "Porto Rafti",
    "lat": 37.8844,
    "lng": 24.0125,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Attikí",
    "capital": "",
    "population": 9686,
    "id": 1300931279
  },
  {
    "city": "Jilotlán de los Dolores",
    "city_ascii": "Jilotlan de los Dolores",
    "lat": 19.3708,
    "lng": -103.0197,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Jalisco",
    "capital": "minor",
    "population": 9917,
    "id": 1484298923
  },
  {
    "city": "Clay",
    "city_ascii": "Clay",
    "lat": 33.6976,
    "lng": -86.607,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 9923,
    "id": 1840014790
  },
  {
    "city": "Saarijärvi",
    "city_ascii": "Saarijarvi",
    "lat": 62.7056,
    "lng": 25.2569,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Keski-Suomi",
    "capital": "minor",
    "population": 9915,
    "id": 1246361506
  },
  {
    "city": "Temiskaming Shores",
    "city_ascii": "Temiskaming Shores",
    "lat": 47.5167,
    "lng": -79.6833,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 9920,
    "id": 1124001880
  },
  {
    "city": "Wilnecote",
    "city_ascii": "Wilnecote",
    "lat": 52.6081,
    "lng": -1.6677,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Staffordshire",
    "capital": "",
    "population": 9358,
    "id": 1826733076
  },
  {
    "city": "Carregal do Sal",
    "city_ascii": "Carregal do Sal",
    "lat": 40.4333,
    "lng": -8,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Viseu",
    "capital": "",
    "population": 9835,
    "id": 1620089281
  },
  {
    "city": "Vernon",
    "city_ascii": "Vernon",
    "lat": 34.148,
    "lng": -99.3,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 9919,
    "id": 1840021912
  },
  {
    "city": "Towcester",
    "city_ascii": "Towcester",
    "lat": 52.13,
    "lng": -0.99,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Northamptonshire",
    "capital": "",
    "population": 9252,
    "id": 1826221011
  },
  {
    "city": "St. Albans",
    "city_ascii": "St. Albans",
    "lat": 38.3769,
    "lng": -81.8198,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "West Virginia",
    "capital": "",
    "population": 9918,
    "id": 1840006201
  },
  {
    "city": "Talachyn",
    "city_ascii": "Talachyn",
    "lat": 54.4167,
    "lng": 29.7,
    "country": "Belarus",
    "iso2": "BY",
    "iso3": "BLR",
    "admin_name": "Vitsyebskaya Voblasts’",
    "capital": "minor",
    "population": 9900,
    "id": 1112016670
  },
  {
    "city": "Berd",
    "city_ascii": "Berd",
    "lat": 40.8808,
    "lng": 45.3917,
    "country": "Armenia",
    "iso2": "AM",
    "iso3": "ARM",
    "admin_name": "Tavush",
    "capital": "",
    "population": 9864,
    "id": 1051965968
  },
  {
    "city": "Mission",
    "city_ascii": "Mission",
    "lat": 39.027,
    "lng": -94.6568,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kansas",
    "capital": "",
    "population": 9911,
    "id": 1840003831
  },
  {
    "city": "Osthofen",
    "city_ascii": "Osthofen",
    "lat": 49.7078,
    "lng": 8.3289,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Rhineland-Palatinate",
    "capital": "",
    "population": 9402,
    "id": 1276502148
  },
  {
    "city": "Trancoso",
    "city_ascii": "Trancoso",
    "lat": 40.7833,
    "lng": -7.35,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Guarda",
    "capital": "minor",
    "population": 9878,
    "id": 1620483334
  },
  {
    "city": "Berwick",
    "city_ascii": "Berwick",
    "lat": 41.0555,
    "lng": -76.2492,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 9903,
    "id": 1840003484
  },
  {
    "city": "Großröhrsdorf",
    "city_ascii": "Grossrohrsdorf",
    "lat": 51.1419,
    "lng": 14.0139,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 9666,
    "id": 1276821884
  },
  {
    "city": "Rockcreek",
    "city_ascii": "Rockcreek",
    "lat": 45.5525,
    "lng": -122.8757,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 9898,
    "id": 1840034833
  },
  {
    "city": "Néa Artáki",
    "city_ascii": "Nea Artaki",
    "lat": 38.5167,
    "lng": 23.6333,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Stereá Elláda",
    "capital": "",
    "population": 9489,
    "id": 1300651375
  },
  {
    "city": "Kinnelon",
    "city_ascii": "Kinnelon",
    "lat": 40.9847,
    "lng": -74.3862,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 9896,
    "id": 1840000952
  },
  {
    "city": "Worsborough",
    "city_ascii": "Worsborough",
    "lat": 53.52,
    "lng": -1.47,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Barnsley",
    "capital": "",
    "population": 9516,
    "id": 1826794690
  },
  {
    "city": "Ventnor City",
    "city_ascii": "Ventnor City",
    "lat": 39.3457,
    "lng": -74.486,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 9895,
    "id": 1840001539
  },
  {
    "city": "Qiziltepa",
    "city_ascii": "Qiziltepa",
    "lat": 40.0319,
    "lng": 64.8492,
    "country": "Uzbekistan",
    "iso2": "UZ",
    "iso3": "UZB",
    "admin_name": "Navoiy",
    "capital": "",
    "population": 9884,
    "id": 1860348538
  },
  {
    "city": "Mezőberény",
    "city_ascii": "Mezobereny",
    "lat": 46.8256,
    "lng": 21.0289,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Békés",
    "capital": "",
    "population": 9809,
    "id": 1348124079
  },
  {
    "city": "Chimay",
    "city_ascii": "Chimay",
    "lat": 50.0479,
    "lng": 4.3173,
    "country": "Belgium",
    "iso2": "BE",
    "iso3": "BEL",
    "admin_name": "Wallonia",
    "capital": "",
    "population": 9841,
    "id": 1056608598
  },
  {
    "city": "Emmett",
    "city_ascii": "Emmett",
    "lat": 43.8685,
    "lng": -116.489,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Idaho",
    "capital": "",
    "population": 9889,
    "id": 1840020000
  },
  {
    "city": "Brandis",
    "city_ascii": "Brandis",
    "lat": 51.3347,
    "lng": 12.6089,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 9613,
    "id": 1276455051
  },
  {
    "city": "Sugar Grove",
    "city_ascii": "Sugar Grove",
    "lat": 41.7758,
    "lng": -88.448,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 9888,
    "id": 1840011355
  },
  {
    "city": "Bockenem",
    "city_ascii": "Bockenem",
    "lat": 52.0117,
    "lng": 10.1319,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 9795,
    "id": 1276993037
  },
  {
    "city": "River Grove",
    "city_ascii": "River Grove",
    "lat": 41.9243,
    "lng": -87.8379,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 9883,
    "id": 1840011285
  },
  {
    "city": "Hinton",
    "city_ascii": "Hinton",
    "lat": 53.4114,
    "lng": -117.5639,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Alberta",
    "capital": "",
    "population": 9882,
    "id": 1124131074
  },
  {
    "city": "Zin’kiv",
    "city_ascii": "Zin'kiv",
    "lat": 50.2081,
    "lng": 34.3668,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Poltavs’ka Oblast’",
    "capital": "minor",
    "population": 9856,
    "id": 1804890384
  },
  {
    "city": "Saint-Sauveur",
    "city_ascii": "Saint-Sauveur",
    "lat": 45.9,
    "lng": -74.17,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 9881,
    "id": 1124720935
  },
  {
    "city": "Great Billing",
    "city_ascii": "Great Billing",
    "lat": 52.2577,
    "lng": -0.8222,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Northamptonshire",
    "capital": "",
    "population": 8457,
    "id": 1826085165
  },
  {
    "city": "Titu",
    "city_ascii": "Titu",
    "lat": 44.6622,
    "lng": 25.5736,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Dâmboviţa",
    "capital": "",
    "population": 9658,
    "id": 1642607155
  },
  {
    "city": "Quesnel",
    "city_ascii": "Quesnel",
    "lat": 52.9784,
    "lng": -122.4927,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "British Columbia",
    "capital": "",
    "population": 9879,
    "id": 1124028015
  },
  {
    "city": "Torrinha",
    "city_ascii": "Torrinha",
    "lat": -22.4258,
    "lng": -48.1689,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 9846,
    "id": 1076302345
  },
  {
    "city": "Pingree Grove",
    "city_ascii": "Pingree Grove",
    "lat": 42.0857,
    "lng": -88.4362,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 9874,
    "id": 1840011352
  },
  {
    "city": "Ellon",
    "city_ascii": "Ellon",
    "lat": 57.366,
    "lng": -2.086,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Aberdeenshire",
    "capital": "",
    "population": 9860,
    "id": 1826126945
  },
  {
    "city": "Sherborne",
    "city_ascii": "Sherborne",
    "lat": 50.9469,
    "lng": -2.5171,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Dorset",
    "capital": "",
    "population": 9523,
    "id": 1826383149
  },
  {
    "city": "Northampton",
    "city_ascii": "Northampton",
    "lat": 40.6866,
    "lng": -75.4904,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 9870,
    "id": 1840000975
  },
  {
    "city": "Brewster",
    "city_ascii": "Brewster",
    "lat": 41.7463,
    "lng": -70.0675,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 9868,
    "id": 1840053498
  },
  {
    "city": "Kobelyaky",
    "city_ascii": "Kobelyaky",
    "lat": 49.1474,
    "lng": 34.1993,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Poltavs’ka Oblast’",
    "capital": "minor",
    "population": 9841,
    "id": 1804297105
  },
  {
    "city": "Waggaman",
    "city_ascii": "Waggaman",
    "lat": 29.9373,
    "lng": -90.2354,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 9866,
    "id": 1840013991
  },
  {
    "city": "Beilngries",
    "city_ascii": "Beilngries",
    "lat": 49.0333,
    "lng": 11.4667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 9768,
    "id": 1276091719
  },
  {
    "city": "Kawanishichō",
    "city_ascii": "Kawanishicho",
    "lat": 34.5844,
    "lng": 135.7742,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Nara",
    "capital": "",
    "population": 8442,
    "id": 1392711904
  },
  {
    "city": "Berezivka",
    "city_ascii": "Berezivka",
    "lat": 47.2039,
    "lng": 30.9125,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Odes’ka Oblast’",
    "capital": "minor",
    "population": 9845,
    "id": 1804490359
  },
  {
    "city": "Gudensberg",
    "city_ascii": "Gudensberg",
    "lat": 51.1762,
    "lng": 9.3575,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 9657,
    "id": 1276969422
  },
  {
    "city": "Sarapuí",
    "city_ascii": "Sarapui",
    "lat": -23.6408,
    "lng": -47.825,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 9836,
    "id": 1076402812
  },
  {
    "city": "Belomorsk",
    "city_ascii": "Belomorsk",
    "lat": 64.5167,
    "lng": 34.7667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kareliya",
    "capital": "",
    "population": 9861,
    "id": 1643588400
  },
  {
    "city": "Gages Lake",
    "city_ascii": "Gages Lake",
    "lat": 42.3519,
    "lng": -87.9828,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 9862,
    "id": 1840004638
  },
  {
    "city": "Ellisville",
    "city_ascii": "Ellisville",
    "lat": 38.5897,
    "lng": -90.5884,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 9862,
    "id": 1840008581
  },
  {
    "city": "Blackhawk",
    "city_ascii": "Blackhawk",
    "lat": 37.816,
    "lng": -121.9071,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 9860,
    "id": 1840028358
  },
  {
    "city": "Bad Liebenzell",
    "city_ascii": "Bad Liebenzell",
    "lat": 48.7742,
    "lng": 8.7314,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 9573,
    "id": 1276138487
  },
  {
    "city": "Elizabethtown-Kitley",
    "city_ascii": "Elizabethtown-Kitley",
    "lat": 44.7,
    "lng": -75.8833,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 9854,
    "id": 1124001450
  },
  {
    "city": "Photharam",
    "city_ascii": "Photharam",
    "lat": 13.6918,
    "lng": 99.8531,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Ratchaburi",
    "capital": "minor",
    "population": 9575,
    "id": 1764429218
  },
  {
    "city": "Morinville",
    "city_ascii": "Morinville",
    "lat": 53.8022,
    "lng": -113.6497,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Alberta",
    "capital": "",
    "population": 9848,
    "id": 1124322535
  },
  {
    "city": "Mentone",
    "city_ascii": "Mentone",
    "lat": 34.0609,
    "lng": -117.1108,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 9847,
    "id": 1840019109
  },
  {
    "city": "Country Club",
    "city_ascii": "Country Club",
    "lat": 37.9687,
    "lng": -121.3408,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 9846,
    "id": 1840028364
  },
  {
    "city": "Höhr-Grenzhausen",
    "city_ascii": "Hohr-Grenzhausen",
    "lat": 50.435,
    "lng": 7.6711,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Rhineland-Palatinate",
    "capital": "",
    "population": 9260,
    "id": 1276961599
  },
  {
    "city": "Mikun",
    "city_ascii": "Mikun",
    "lat": 62.35,
    "lng": 50.0667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Komi",
    "capital": "",
    "population": 9837,
    "id": 1643910961
  },
  {
    "city": "Haţeg",
    "city_ascii": "Hateg",
    "lat": 45.6075,
    "lng": 22.95,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Hunedoara",
    "capital": "",
    "population": 9685,
    "id": 1642953184
  },
  {
    "city": "Scottsboro",
    "city_ascii": "Scottsboro",
    "lat": 34.6438,
    "lng": -86.0491,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 9841,
    "id": 1840005605
  },
  {
    "city": "Ware",
    "city_ascii": "Ware",
    "lat": 42.2806,
    "lng": -72.2843,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 9838,
    "id": 1840053468
  },
  {
    "city": "T’q’ibuli",
    "city_ascii": "T'q'ibuli",
    "lat": 42.3442,
    "lng": 42.99,
    "country": "Georgia",
    "iso2": "GE",
    "iso3": "GEO",
    "admin_name": "Imereti",
    "capital": "minor",
    "population": 9770,
    "id": 1268479108
  },
  {
    "city": "Sakai",
    "city_ascii": "Sakai",
    "lat": 35.3308,
    "lng": 139.2189,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Kanagawa",
    "capital": "",
    "population": 9368,
    "id": 1392689992
  },
  {
    "city": "Sweetwater",
    "city_ascii": "Sweetwater",
    "lat": 32.4693,
    "lng": -100.4092,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 9836,
    "id": 1840022089
  },
  {
    "city": "Carlton Colville",
    "city_ascii": "Carlton Colville",
    "lat": 52.454,
    "lng": 1.691,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Suffolk",
    "capital": "",
    "population": 8505,
    "id": 1826912261
  },
  {
    "city": "Schlitz",
    "city_ascii": "Schlitz",
    "lat": 50.6759,
    "lng": 9.5593,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 9764,
    "id": 1276405370
  },
  {
    "city": "New Baltimore",
    "city_ascii": "New Baltimore",
    "lat": 38.7495,
    "lng": -77.7151,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 9831,
    "id": 1840024594
  },
  {
    "city": "Ban Krot",
    "city_ascii": "Ban Krot",
    "lat": 14.3121,
    "lng": 100.6005,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Phra Nakhon Si Ayutthaya",
    "capital": "",
    "population": 9471,
    "id": 1764143463
  },
  {
    "city": "Coffeyville",
    "city_ascii": "Coffeyville",
    "lat": 37.0518,
    "lng": -95.618,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kansas",
    "capital": "",
    "population": 9827,
    "id": 1840001707
  },
  {
    "city": "Heilsbronn",
    "city_ascii": "Heilsbronn",
    "lat": 49.3386,
    "lng": 10.7908,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 9670,
    "id": 1276724619
  },
  {
    "city": "Osterburg",
    "city_ascii": "Osterburg",
    "lat": 52.7833,
    "lng": 11.7667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony-Anhalt",
    "capital": "",
    "population": 9782,
    "id": 1276131088
  },
  {
    "city": "Chillicothe",
    "city_ascii": "Chillicothe",
    "lat": 39.7953,
    "lng": -93.5499,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 9824,
    "id": 1840007302
  },
  {
    "city": "Lake Barcroft",
    "city_ascii": "Lake Barcroft",
    "lat": 38.8514,
    "lng": -77.1579,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 9823,
    "id": 1840006033
  },
  {
    "city": "Skopin",
    "city_ascii": "Skopin",
    "lat": 53.8167,
    "lng": 39.55,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Ryazanskaya Oblast’",
    "capital": "",
    "population": 9511,
    "id": 1643315805
  },
  {
    "city": "Franklin",
    "city_ascii": "Franklin",
    "lat": 43.4499,
    "lng": -71.6691,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Hampshire",
    "capital": "",
    "population": 9817,
    "id": 1840002748
  },
  {
    "city": "Robertsdale",
    "city_ascii": "Robertsdale",
    "lat": 30.5534,
    "lng": -87.7023,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 9817,
    "id": 1840014981
  },
  {
    "city": "South Lebanon",
    "city_ascii": "South Lebanon",
    "lat": 40.3058,
    "lng": -76.3708,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 9815,
    "id": 1840149649
  },
  {
    "city": "Dorogobuzh",
    "city_ascii": "Dorogobuzh",
    "lat": 54.92,
    "lng": 33.3078,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Smolenskaya Oblast’",
    "capital": "",
    "population": 9793,
    "id": 1643314720
  },
  {
    "city": "Năsăud",
    "city_ascii": "Nasaud",
    "lat": 47.2833,
    "lng": 24.4067,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Bistriţa-Năsăud",
    "capital": "",
    "population": 9587,
    "id": 1642277064
  },
  {
    "city": "Totma",
    "city_ascii": "Totma",
    "lat": 59.9833,
    "lng": 42.7667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Vologodskaya Oblast’",
    "capital": "",
    "population": 9805,
    "id": 1643361608
  },
  {
    "city": "Mont-Saint-Martin",
    "city_ascii": "Mont-Saint-Martin",
    "lat": 49.5406,
    "lng": 5.7794,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Grand Est",
    "capital": "",
    "population": 8807,
    "id": 1250537534
  },
  {
    "city": "Herkimer",
    "city_ascii": "Herkimer",
    "lat": 43.061,
    "lng": -74.9894,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 9806,
    "id": 1840004148
  },
  {
    "city": "Itasca",
    "city_ascii": "Itasca",
    "lat": 41.9773,
    "lng": -88.0182,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 9805,
    "id": 1840011400
  },
  {
    "city": "Nové Město nad Metují",
    "city_ascii": "Nove Mesto nad Metuji",
    "lat": 50.3446,
    "lng": 16.1515,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Královéhradecký Kraj",
    "capital": "",
    "population": 9398,
    "id": 1203259477
  },
  {
    "city": "Grey Highlands",
    "city_ascii": "Grey Highlands",
    "lat": 44.3333,
    "lng": -80.5,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 9804,
    "id": 1124000119
  },
  {
    "city": "Atherstone",
    "city_ascii": "Atherstone",
    "lat": 52.5787,
    "lng": -1.5462,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Warwickshire",
    "capital": "",
    "population": 8670,
    "id": 1826567686
  },
  {
    "city": "Marlboro Village",
    "city_ascii": "Marlboro Village",
    "lat": 38.8307,
    "lng": -76.7699,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 9803,
    "id": 1840039492
  },
  {
    "city": "Tišnov",
    "city_ascii": "Tisnov",
    "lat": 49.3487,
    "lng": 16.4244,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Jihomoravský Kraj",
    "capital": "",
    "population": 9258,
    "id": 1203595572
  },
  {
    "city": "Woodbury",
    "city_ascii": "Woodbury",
    "lat": 39.8379,
    "lng": -75.1524,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 9794,
    "id": 1840001527
  },
  {
    "city": "Leeton",
    "city_ascii": "Leeton",
    "lat": -34.5483,
    "lng": 146.4011,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 8623,
    "id": 1036102538
  },
  {
    "city": "Friendly",
    "city_ascii": "Friendly",
    "lat": 38.7601,
    "lng": -76.9642,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 9793,
    "id": 1840005954
  },
  {
    "city": "Mittagong",
    "city_ascii": "Mittagong",
    "lat": -34.45,
    "lng": 150.45,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 8999,
    "id": 1036443307
  },
  {
    "city": "Gunbarrel",
    "city_ascii": "Gunbarrel",
    "lat": 40.0634,
    "lng": -105.1714,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 9791,
    "id": 1840028424
  },
  {
    "city": "Mooresville",
    "city_ascii": "Mooresville",
    "lat": 39.6022,
    "lng": -86.3681,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 9788,
    "id": 1840009668
  },
  {
    "city": "Nyurba",
    "city_ascii": "Nyurba",
    "lat": 63.2833,
    "lng": 118.3333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sakha (Yakutiya)",
    "capital": "",
    "population": 9786,
    "id": 1643555326
  },
  {
    "city": "Studénka",
    "city_ascii": "Studenka",
    "lat": 49.7234,
    "lng": 18.0786,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Moravskoslezský Kraj",
    "capital": "",
    "population": 9477,
    "id": 1203000978
  },
  {
    "city": "Grenada",
    "city_ascii": "Grenada",
    "lat": 33.7816,
    "lng": -89.813,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Mississippi",
    "capital": "",
    "population": 9782,
    "id": 1840013717
  },
  {
    "city": "Middleton",
    "city_ascii": "Middleton",
    "lat": 42.6043,
    "lng": -71.0164,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 9779,
    "id": 1840053534
  },
  {
    "city": "Suzdal",
    "city_ascii": "Suzdal",
    "lat": 56.4211,
    "lng": 40.4489,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Vladimirskaya Oblast’",
    "capital": "",
    "population": 9749,
    "id": 1643926701
  },
  {
    "city": "Kemberg",
    "city_ascii": "Kemberg",
    "lat": 51.7736,
    "lng": 12.6359,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony-Anhalt",
    "capital": "",
    "population": 9737,
    "id": 1276029857
  },
  {
    "city": "Fort Salonga",
    "city_ascii": "Fort Salonga",
    "lat": 40.906,
    "lng": -73.2992,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 9776,
    "id": 1840005013
  },
  {
    "city": "Rio del Mar",
    "city_ascii": "Rio del Mar",
    "lat": 36.9607,
    "lng": -121.8807,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 9775,
    "id": 1840028179
  },
  {
    "city": "Russellville",
    "city_ascii": "Russellville",
    "lat": 34.5056,
    "lng": -87.7282,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 9772,
    "id": 1840004247
  },
  {
    "city": "Tweed Heads",
    "city_ascii": "Tweed Heads",
    "lat": -28.1833,
    "lng": 153.55,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 8176,
    "id": 1036571941
  },
  {
    "city": "Annan",
    "city_ascii": "Annan",
    "lat": 54.983,
    "lng": -3.266,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Dumfries and Galloway",
    "capital": "",
    "population": 8389,
    "id": 1826837081
  },
  {
    "city": "Podu Iloaiei",
    "city_ascii": "Podu Iloaiei",
    "lat": 47.2167,
    "lng": 27.2667,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Iaşi",
    "capital": "",
    "population": 9573,
    "id": 1642361317
  },
  {
    "city": "North Bend",
    "city_ascii": "North Bend",
    "lat": 43.4075,
    "lng": -124.2364,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 9768,
    "id": 1840020057
  },
  {
    "city": "Lake Arrowhead",
    "city_ascii": "Lake Arrowhead",
    "lat": 34.2531,
    "lng": -117.1945,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 9765,
    "id": 1840017803
  },
  {
    "city": "Union City",
    "city_ascii": "Union City",
    "lat": 36.4268,
    "lng": -89.0474,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 9763,
    "id": 1840015280
  },
  {
    "city": "Grimmen",
    "city_ascii": "Grimmen",
    "lat": 54.11,
    "lng": 13.0414,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Mecklenburg-Western Pomerania",
    "capital": "",
    "population": 9572,
    "id": 1276798826
  },
  {
    "city": "Brandywine",
    "city_ascii": "Brandywine",
    "lat": 38.6963,
    "lng": -76.8846,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 9761,
    "id": 1840005946
  },
  {
    "city": "Eisenberg",
    "city_ascii": "Eisenberg",
    "lat": 49.5614,
    "lng": 8.0725,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Rhineland-Palatinate",
    "capital": "",
    "population": 9264,
    "id": 1276928244
  },
  {
    "city": "Neustadt",
    "city_ascii": "Neustadt",
    "lat": 50.85,
    "lng": 9.1167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 9586,
    "id": 1276694590
  },
  {
    "city": "Sarkad",
    "city_ascii": "Sarkad",
    "lat": 46.74,
    "lng": 21.3778,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Békés",
    "capital": "minor",
    "population": 9677,
    "id": 1348531339
  },
  {
    "city": "Philipsburg",
    "city_ascii": "Philipsburg",
    "lat": 40.8952,
    "lng": -78.2145,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 9754,
    "id": 1840000827
  },
  {
    "city": "Denham Springs",
    "city_ascii": "Denham Springs",
    "lat": 30.4743,
    "lng": -90.9594,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 9753,
    "id": 1840015027
  },
  {
    "city": "Glencoe",
    "city_ascii": "Glencoe",
    "lat": -46.192,
    "lng": 168.646,
    "country": "New Zealand",
    "iso2": "NZ",
    "iso3": "NZL",
    "admin_name": "Southland",
    "capital": "",
    "population": 9750,
    "id": 1554979133
  },
  {
    "city": "Corte Madera",
    "city_ascii": "Corte Madera",
    "lat": 37.9238,
    "lng": -122.5129,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 9751,
    "id": 1840022519
  },
  {
    "city": "Progress",
    "city_ascii": "Progress",
    "lat": 40.2905,
    "lng": -76.8382,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 9750,
    "id": 1840035164
  },
  {
    "city": "Oberstdorf",
    "city_ascii": "Oberstdorf",
    "lat": 47.4097,
    "lng": 10.2792,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 9707,
    "id": 1276143452
  },
  {
    "city": "Cuero",
    "city_ascii": "Cuero",
    "lat": 29.1024,
    "lng": -97.2871,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 9749,
    "id": 1840019676
  },
  {
    "city": "Jewett City",
    "city_ascii": "Jewett City",
    "lat": 41.607,
    "lng": -71.9807,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 9749,
    "id": 1840004826
  },
  {
    "city": "Conning Towers Nautilus Park",
    "city_ascii": "Conning Towers Nautilus Park",
    "lat": 41.3855,
    "lng": -72.0686,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 9749,
    "id": 1840073288
  },
  {
    "city": "Argostóli",
    "city_ascii": "Argostoli",
    "lat": 38.1739,
    "lng": 20.4883,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Ionía Nísia",
    "capital": "minor",
    "population": 9748,
    "id": 1300532537
  },
  {
    "city": "Plains",
    "city_ascii": "Plains",
    "lat": 41.2658,
    "lng": -75.8145,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 9748,
    "id": 1840025529
  },
  {
    "city": "Puigcerdá",
    "city_ascii": "Puigcerda",
    "lat": 42.4317,
    "lng": 1.9283,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Catalonia",
    "capital": "",
    "population": 9258,
    "id": 1724441850
  },
  {
    "city": "Nopaltepec",
    "city_ascii": "Nopaltepec",
    "lat": 19.7819,
    "lng": -98.7125,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Morelos",
    "capital": "minor",
    "population": 8182,
    "id": 1484979836
  },
  {
    "city": "Sângeorz-Băi",
    "city_ascii": "Sangeorz-Bai",
    "lat": 47.37,
    "lng": 24.68,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Bistriţa-Năsăud",
    "capital": "",
    "population": 9679,
    "id": 1642207424
  },
  {
    "city": "Yamakita",
    "city_ascii": "Yamakita",
    "lat": 35.3606,
    "lng": 139.0839,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Kanagawa",
    "capital": "",
    "population": 9702,
    "id": 1392889256
  },
  {
    "city": "Thurso",
    "city_ascii": "Thurso",
    "lat": 58.596,
    "lng": -3.521,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Highland",
    "capital": "",
    "population": 7933,
    "id": 1826252714
  },
  {
    "city": "Prospect",
    "city_ascii": "Prospect",
    "lat": 41.4993,
    "lng": -72.976,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 9736,
    "id": 1840035549
  },
  {
    "city": "Columbia City",
    "city_ascii": "Columbia City",
    "lat": 41.1612,
    "lng": -85.4855,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 9735,
    "id": 1840007140
  },
  {
    "city": "Voitsberg",
    "city_ascii": "Voitsberg",
    "lat": 47.0483,
    "lng": 15.1503,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Steiermark",
    "capital": "minor",
    "population": 9403,
    "id": 1040639919
  },
  {
    "city": "Chesapeake Ranch Estates",
    "city_ascii": "Chesapeake Ranch Estates",
    "lat": 38.3574,
    "lng": -76.4147,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 9732,
    "id": 1840026723
  },
  {
    "city": "Nolinsk",
    "city_ascii": "Nolinsk",
    "lat": 57.5572,
    "lng": 49.9342,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kirovskaya Oblast’",
    "capital": "",
    "population": 9722,
    "id": 1643468029
  },
  {
    "city": "Woodfield",
    "city_ascii": "Woodfield",
    "lat": 34.0587,
    "lng": -80.9309,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 9730,
    "id": 1840035853
  },
  {
    "city": "Liteni",
    "city_ascii": "Liteni",
    "lat": 47.52,
    "lng": 26.5319,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Suceava",
    "capital": "",
    "population": 9596,
    "id": 1642459185
  },
  {
    "city": "Southwick",
    "city_ascii": "Southwick",
    "lat": 42.0544,
    "lng": -72.7785,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 9727,
    "id": 1840053452
  },
  {
    "city": "Kittery",
    "city_ascii": "Kittery",
    "lat": 43.0998,
    "lng": -70.7126,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maine",
    "capital": "",
    "population": 9726,
    "id": 1840052873
  },
  {
    "city": "Philipstown",
    "city_ascii": "Philipstown",
    "lat": 41.4188,
    "lng": -73.9152,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 9724,
    "id": 1840087800
  },
  {
    "city": "Idanha-a-Nova",
    "city_ascii": "Idanha-a-Nova",
    "lat": 39.9167,
    "lng": -7.2333,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Castelo Branco",
    "capital": "minor",
    "population": 9716,
    "id": 1620152186
  },
  {
    "city": "Bearsted",
    "city_ascii": "Bearsted",
    "lat": 51.2738,
    "lng": 0.5789,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Kent",
    "capital": "",
    "population": 8010,
    "id": 1826884124
  },
  {
    "city": "Halen",
    "city_ascii": "Halen",
    "lat": 50.9481,
    "lng": 5.1144,
    "country": "Belgium",
    "iso2": "BE",
    "iso3": "BEL",
    "admin_name": "Flanders",
    "capital": "",
    "population": 9461,
    "id": 1056456739
  },
  {
    "city": "East Aurora",
    "city_ascii": "East Aurora",
    "lat": 42.7666,
    "lng": -78.6172,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 9720,
    "id": 1840004398
  },
  {
    "city": "Heiligenhafen",
    "city_ascii": "Heiligenhafen",
    "lat": 54.3739,
    "lng": 10.9797,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Schleswig-Holstein",
    "capital": "",
    "population": 9211,
    "id": 1276378464
  },
  {
    "city": "Minano",
    "city_ascii": "Minano",
    "lat": 36.0708,
    "lng": 139.0989,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Saitama",
    "capital": "",
    "population": 9569,
    "id": 1392100965
  },
  {
    "city": "Shchuchye",
    "city_ascii": "Shchuchye",
    "lat": 55.2167,
    "lng": 62.7667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kurganskaya Oblast’",
    "capital": "",
    "population": 9711,
    "id": 1643139853
  },
  {
    "city": "Imías",
    "city_ascii": "Imias",
    "lat": 20.0767,
    "lng": -74.6519,
    "country": "Cuba",
    "iso2": "CU",
    "iso3": "CUB",
    "admin_name": "Guantánamo",
    "capital": "minor",
    "population": 9699,
    "id": 1192551385
  },
  {
    "city": "Carroll",
    "city_ascii": "Carroll",
    "lat": 42.0699,
    "lng": -94.8647,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Iowa",
    "capital": "",
    "population": 9717,
    "id": 1840000490
  },
  {
    "city": "Valozhyn",
    "city_ascii": "Valozhyn",
    "lat": 54.0833,
    "lng": 26.5167,
    "country": "Belarus",
    "iso2": "BY",
    "iso3": "BLR",
    "admin_name": "Minskaya Voblasts’",
    "capital": "minor",
    "population": 9700,
    "id": 1112252303
  },
  {
    "city": "Dungarvan",
    "city_ascii": "Dungarvan",
    "lat": 52.0845,
    "lng": -7.6397,
    "country": "Ireland",
    "iso2": "IE",
    "iso3": "IRL",
    "admin_name": "Waterford",
    "capital": "",
    "population": 7991,
    "id": 1372697938
  },
  {
    "city": "Stuarts Draft",
    "city_ascii": "Stuarts Draft",
    "lat": 38.0245,
    "lng": -79.0308,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 9716,
    "id": 1840006247
  },
  {
    "city": "Pleasant Valley",
    "city_ascii": "Pleasant Valley",
    "lat": 41.7697,
    "lng": -73.805,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 9715,
    "id": 1840058409
  },
  {
    "city": "Alexandria",
    "city_ascii": "Alexandria",
    "lat": 38.9621,
    "lng": -84.386,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kentucky",
    "capital": "",
    "population": 9715,
    "id": 1840013162
  },
  {
    "city": "Dushanovë",
    "city_ascii": "Dushanove",
    "lat": 42.2347,
    "lng": 20.7091,
    "country": "Kosovo",
    "iso2": "XK",
    "iso3": "XKS",
    "admin_name": "Prizren",
    "capital": "",
    "population": 9398,
    "id": 1901150015
  },
  {
    "city": "George Mason",
    "city_ascii": "George Mason",
    "lat": 38.8355,
    "lng": -77.3185,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 9711,
    "id": 1840041768
  },
  {
    "city": "Wabash",
    "city_ascii": "Wabash",
    "lat": 40.8034,
    "lng": -85.8301,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 9710,
    "id": 1840010335
  },
  {
    "city": "Stratford",
    "city_ascii": "Stratford",
    "lat": 46.2167,
    "lng": -63.0893,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Prince Edward Island",
    "capital": "",
    "population": 9706,
    "id": 1124001331
  },
  {
    "city": "Boaz",
    "city_ascii": "Boaz",
    "lat": 34.1985,
    "lng": -86.1529,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 9704,
    "id": 1840013602
  },
  {
    "city": "Benalla",
    "city_ascii": "Benalla",
    "lat": -36.5519,
    "lng": 145.9817,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Victoria",
    "capital": "",
    "population": 9298,
    "id": 1036934047
  },
  {
    "city": "Roztoky",
    "city_ascii": "Roztoky",
    "lat": 50.1585,
    "lng": 14.3977,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Středočeský Kraj",
    "capital": "",
    "population": 8645,
    "id": 1203472886
  },
  {
    "city": "Matlock",
    "city_ascii": "Matlock",
    "lat": 53.14,
    "lng": -1.55,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Derbyshire",
    "capital": "",
    "population": 9543,
    "id": 1826168850
  },
  {
    "city": "Oak Hills",
    "city_ascii": "Oak Hills",
    "lat": 34.3912,
    "lng": -117.4125,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 9700,
    "id": 1840024934
  },
  {
    "city": "St. Francis",
    "city_ascii": "St. Francis",
    "lat": 42.9716,
    "lng": -87.8729,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 9699,
    "id": 1840003049
  },
  {
    "city": "Holywell",
    "city_ascii": "Holywell",
    "lat": 53.274,
    "lng": -3.223,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Flintshire",
    "capital": "",
    "population": 8886,
    "id": 1826527562
  },
  {
    "city": "Kola",
    "city_ascii": "Kola",
    "lat": 68.8831,
    "lng": 33.0219,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Murmanskaya Oblast’",
    "capital": "",
    "population": 9691,
    "id": 1643826849
  },
  {
    "city": "Hohenmölsen",
    "city_ascii": "Hohenmolsen",
    "lat": 51.1564,
    "lng": 12.0981,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony-Anhalt",
    "capital": "",
    "population": 9565,
    "id": 1276691866
  },
  {
    "city": "Visselhövede",
    "city_ascii": "Visselhovede",
    "lat": 52.9833,
    "lng": 9.6,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 9629,
    "id": 1276207705
  },
  {
    "city": "Dassel",
    "city_ascii": "Dassel",
    "lat": 51.8033,
    "lng": 9.6903,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 9604,
    "id": 1276866794
  },
  {
    "city": "Edgewater",
    "city_ascii": "Edgewater",
    "lat": 38.9373,
    "lng": -76.5572,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 9689,
    "id": 1840024528
  },
  {
    "city": "Immingham",
    "city_ascii": "Immingham",
    "lat": 53.6139,
    "lng": -0.2183,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "North East Lincolnshire",
    "capital": "",
    "population": 9642,
    "id": 1826538121
  },
  {
    "city": "Bersted",
    "city_ascii": "Bersted",
    "lat": 50.797,
    "lng": -0.689,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "West Sussex",
    "capital": "",
    "population": 8496,
    "id": 1826358905
  },
  {
    "city": "Swanage",
    "city_ascii": "Swanage",
    "lat": 50.608,
    "lng": -1.96,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Dorset",
    "capital": "",
    "population": 9601,
    "id": 1826896220
  },
  {
    "city": "East Rutherford",
    "city_ascii": "East Rutherford",
    "lat": 40.8179,
    "lng": -74.0854,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 9687,
    "id": 1840003542
  },
  {
    "city": "Pechory",
    "city_ascii": "Pechory",
    "lat": 57.8167,
    "lng": 27.6,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Pskovskaya Oblast’",
    "capital": "",
    "population": 9670,
    "id": 1643575087
  },
  {
    "city": "Miajadas",
    "city_ascii": "Miajadas",
    "lat": 39.1528,
    "lng": -5.9081,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Extremadura",
    "capital": "",
    "population": 9607,
    "id": 1724704147
  },
  {
    "city": "Fort Valley",
    "city_ascii": "Fort Valley",
    "lat": 32.552,
    "lng": -83.8819,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 9683,
    "id": 1840013819
  },
  {
    "city": "Laubach",
    "city_ascii": "Laubach",
    "lat": 50.5426,
    "lng": 8.9891,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 9583,
    "id": 1276831355
  },
  {
    "city": "Alfred and Plantagenet",
    "city_ascii": "Alfred and Plantagenet",
    "lat": 45.5667,
    "lng": -74.9167,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 9680,
    "id": 1124001813
  },
  {
    "city": "Medulla",
    "city_ascii": "Medulla",
    "lat": 27.957,
    "lng": -81.9866,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 9679,
    "id": 1840014131
  },
  {
    "city": "Gifford",
    "city_ascii": "Gifford",
    "lat": 27.6747,
    "lng": -80.4102,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 9679,
    "id": 1840014160
  },
  {
    "city": "West Tawakoni",
    "city_ascii": "West Tawakoni",
    "lat": 32.8976,
    "lng": -96.0217,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 9679,
    "id": 1840022015
  },
  {
    "city": "Hartford",
    "city_ascii": "Hartford",
    "lat": 43.6644,
    "lng": -72.3866,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Vermont",
    "capital": "",
    "population": 9678,
    "id": 1840070579
  },
  {
    "city": "Ilsenburg",
    "city_ascii": "Ilsenburg",
    "lat": 51.8667,
    "lng": 10.6833,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony-Anhalt",
    "capital": "",
    "population": 9526,
    "id": 1276193551
  },
  {
    "city": "Tryavna",
    "city_ascii": "Tryavna",
    "lat": 42.8667,
    "lng": 25.4919,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Gabrovo",
    "capital": "",
    "population": 9634,
    "id": 1100037989
  },
  {
    "city": "Croydon",
    "city_ascii": "Croydon",
    "lat": 40.0911,
    "lng": -74.8975,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 9675,
    "id": 1840005406
  },
  {
    "city": "Khodoriv",
    "city_ascii": "Khodoriv",
    "lat": 49.41,
    "lng": 24.3094,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "L’vivs’ka Oblast’",
    "capital": "",
    "population": 9605,
    "id": 1804664914
  },
  {
    "city": "Warrenton",
    "city_ascii": "Warrenton",
    "lat": 38.8187,
    "lng": -91.1384,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 9672,
    "id": 1840010714
  },
  {
    "city": "Marysville",
    "city_ascii": "Marysville",
    "lat": 42.9084,
    "lng": -82.4806,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 9672,
    "id": 1840003957
  },
  {
    "city": "Greenville",
    "city_ascii": "Greenville",
    "lat": 43.1797,
    "lng": -85.2533,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 9672,
    "id": 1840002877
  },
  {
    "city": "Attnang-Puchheim",
    "city_ascii": "Attnang-Puchheim",
    "lat": 48.0167,
    "lng": 13.7167,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Oberösterreich",
    "capital": "",
    "population": 8944,
    "id": 1040023749
  },
  {
    "city": "Ledbury",
    "city_ascii": "Ledbury",
    "lat": 52.0339,
    "lng": -2.4235,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Herefordshire",
    "capital": "",
    "population": 9290,
    "id": 1826478885
  },
  {
    "city": "Butler Township",
    "city_ascii": "Butler Township",
    "lat": 41.0362,
    "lng": -75.9801,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 9668,
    "id": 1840147559
  },
  {
    "city": "Ita",
    "city_ascii": "Ita",
    "lat": 33.6528,
    "lng": 130.7792,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Fukuoka",
    "capital": "",
    "population": 8598,
    "id": 1392811051
  },
  {
    "city": "Stary Sącz",
    "city_ascii": "Stary Sacz",
    "lat": 49.5625,
    "lng": 20.6364,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Małopolskie",
    "capital": "",
    "population": 9056,
    "id": 1616399913
  },
  {
    "city": "Brand-Erbisdorf",
    "city_ascii": "Brand-Erbisdorf",
    "lat": 50.8689,
    "lng": 13.3219,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 9452,
    "id": 1276212051
  },
  {
    "city": "Hârşova",
    "city_ascii": "Harsova",
    "lat": 44.6833,
    "lng": 27.9519,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Constanţa",
    "capital": "",
    "population": 9642,
    "id": 1642407029
  },
  {
    "city": "Ikeda",
    "city_ascii": "Ikeda",
    "lat": 36.4214,
    "lng": 137.8747,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Nagano",
    "capital": "",
    "population": 9417,
    "id": 1392276481
  },
  {
    "city": "Zwiesel",
    "city_ascii": "Zwiesel",
    "lat": 49.0167,
    "lng": 13.2333,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 9421,
    "id": 1276620797
  },
  {
    "city": "Waterford",
    "city_ascii": "Waterford",
    "lat": 37.6429,
    "lng": -120.7553,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 9648,
    "id": 1840021534
  },
  {
    "city": "Pinhel",
    "city_ascii": "Pinhel",
    "lat": 40.7833,
    "lng": -7.0667,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Guarda",
    "capital": "minor",
    "population": 9627,
    "id": 1620892355
  },
  {
    "city": "Mont-Tremblant",
    "city_ascii": "Mont-Tremblant",
    "lat": 46.1167,
    "lng": -74.6,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 9646,
    "id": 1124041173
  },
  {
    "city": "Martensville",
    "city_ascii": "Martensville",
    "lat": 52.2897,
    "lng": -106.6667,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Saskatchewan",
    "capital": "",
    "population": 9645,
    "id": 1124000654
  },
  {
    "city": "Port Wentworth",
    "city_ascii": "Port Wentworth",
    "lat": 32.1942,
    "lng": -81.1984,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 9641,
    "id": 1840014915
  },
  {
    "city": "Valley Cottage",
    "city_ascii": "Valley Cottage",
    "lat": 41.116,
    "lng": -73.9436,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 9637,
    "id": 1840004971
  },
  {
    "city": "Hermantown",
    "city_ascii": "Hermantown",
    "lat": 46.8058,
    "lng": -92.2407,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 9637,
    "id": 1840006593
  },
  {
    "city": "Zadonsk",
    "city_ascii": "Zadonsk",
    "lat": 52.3833,
    "lng": 38.9167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Lipetskaya Oblast’",
    "capital": "",
    "population": 9614,
    "id": 1643560956
  },
  {
    "city": "Irthlingborough",
    "city_ascii": "Irthlingborough",
    "lat": 52.324,
    "lng": -0.614,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Northamptonshire",
    "capital": "",
    "population": 8900,
    "id": 1826205713
  },
  {
    "city": "Winfield",
    "city_ascii": "Winfield",
    "lat": 41.8776,
    "lng": -88.1507,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 9636,
    "id": 1840011410
  },
  {
    "city": "Pella",
    "city_ascii": "Pella",
    "lat": 41.4051,
    "lng": -92.9177,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Iowa",
    "capital": "",
    "population": 9636,
    "id": 1840009293
  },
  {
    "city": "Oxford",
    "city_ascii": "Oxford",
    "lat": 36.3155,
    "lng": -78.5848,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 9634,
    "id": 1840014435
  },
  {
    "city": "Manduri",
    "city_ascii": "Manduri",
    "lat": -23.0033,
    "lng": -49.3219,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 9592,
    "id": 1076633866
  },
  {
    "city": "Coleford",
    "city_ascii": "Coleford",
    "lat": 51.791,
    "lng": -2.6162,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Gloucestershire",
    "capital": "",
    "population": 8359,
    "id": 1826833780
  },
  {
    "city": "La Plata",
    "city_ascii": "La Plata",
    "lat": 38.5352,
    "lng": -76.9701,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 9631,
    "id": 1840006177
  },
  {
    "city": "Merrill",
    "city_ascii": "Merrill",
    "lat": 45.182,
    "lng": -89.6995,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 9630,
    "id": 1840001562
  },
  {
    "city": "Luckau",
    "city_ascii": "Luckau",
    "lat": 51.85,
    "lng": 13.7167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Brandenburg",
    "capital": "",
    "population": 9582,
    "id": 1276191048
  },
  {
    "city": "Red Bank",
    "city_ascii": "Red Bank",
    "lat": 33.9296,
    "lng": -81.2321,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 9623,
    "id": 1840013658
  },
  {
    "city": "Wadebridge",
    "city_ascii": "Wadebridge",
    "lat": 50.517,
    "lng": -4.835,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Cornwall",
    "capital": "",
    "population": 7900,
    "id": 1826771408
  },
  {
    "city": "Penn Forest",
    "city_ascii": "Penn Forest",
    "lat": 40.9571,
    "lng": -75.6313,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 9620,
    "id": 1840143698
  },
  {
    "city": "Cross Lanes",
    "city_ascii": "Cross Lanes",
    "lat": 38.4351,
    "lng": -81.7706,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "West Virginia",
    "capital": "",
    "population": 9619,
    "id": 1840006190
  },
  {
    "city": "Woodbury",
    "city_ascii": "Woodbury",
    "lat": 41.5615,
    "lng": -73.207,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 9617,
    "id": 1840045111
  },
  {
    "city": "Zschopau",
    "city_ascii": "Zschopau",
    "lat": 50.75,
    "lng": 13.0667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 9214,
    "id": 1276308597
  },
  {
    "city": "Trebbin",
    "city_ascii": "Trebbin",
    "lat": 52.2167,
    "lng": 13.1997,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Brandenburg",
    "capital": "",
    "population": 9541,
    "id": 1276041588
  },
  {
    "city": "Wilmore",
    "city_ascii": "Wilmore",
    "lat": 37.8786,
    "lng": -84.6545,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kentucky",
    "capital": "",
    "population": 9616,
    "id": 1840015220
  },
  {
    "city": "Saint-Raymond",
    "city_ascii": "Saint-Raymond",
    "lat": 46.9,
    "lng": -71.8333,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 9615,
    "id": 1124162305
  },
  {
    "city": "San Justo",
    "city_ascii": "San Justo",
    "lat": -30.7829,
    "lng": -60.5833,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Santa Fe",
    "capital": "minor",
    "population": 9607,
    "id": 1032734419
  },
  {
    "city": "Audley",
    "city_ascii": "Audley",
    "lat": 53.053,
    "lng": -2.304,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Staffordshire",
    "capital": "",
    "population": 8437,
    "id": 1826819184
  },
  {
    "city": "Rainbow City",
    "city_ascii": "Rainbow City",
    "lat": 33.9337,
    "lng": -86.0922,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 9611,
    "id": 1840014732
  },
  {
    "city": "Chevy Chase",
    "city_ascii": "Chevy Chase",
    "lat": 38.9943,
    "lng": -77.0737,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 9611,
    "id": 1840031323
  },
  {
    "city": "Burlington",
    "city_ascii": "Burlington",
    "lat": 41.7598,
    "lng": -72.9589,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 9607,
    "id": 1840034217
  },
  {
    "city": "Pleasant Grove",
    "city_ascii": "Pleasant Grove",
    "lat": 33.4936,
    "lng": -86.9782,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 9604,
    "id": 1840014793
  },
  {
    "city": "Howell",
    "city_ascii": "Howell",
    "lat": 42.6078,
    "lng": -83.9339,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 9604,
    "id": 1840003100
  },
  {
    "city": "Cody",
    "city_ascii": "Cody",
    "lat": 44.5212,
    "lng": -109.0549,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wyoming",
    "capital": "",
    "population": 9601,
    "id": 1840018595
  },
  {
    "city": "Palmeira d’Oeste",
    "city_ascii": "Palmeira d'Oeste",
    "lat": -20.4158,
    "lng": -50.7619,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 9596,
    "id": 1076247939
  },
  {
    "city": "Emu Plains",
    "city_ascii": "Emu Plains",
    "lat": -33.7483,
    "lng": 150.6678,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 8421,
    "id": 1036920187
  },
  {
    "city": "Roxboro",
    "city_ascii": "Roxboro",
    "lat": 36.3879,
    "lng": -78.9812,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 9599,
    "id": 1840014432
  },
  {
    "city": "Sarahs",
    "city_ascii": "Sarahs",
    "lat": 36.5167,
    "lng": 61.2167,
    "country": "Turkmenistan",
    "iso2": "TM",
    "iso3": "TKM",
    "admin_name": "Ahal",
    "capital": "",
    "population": 9585,
    "id": 1795405865
  },
  {
    "city": "Bishop",
    "city_ascii": "Bishop",
    "lat": 37.3665,
    "lng": -118.3958,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 9597,
    "id": 1840018955
  },
  {
    "city": "Blacklick Estates",
    "city_ascii": "Blacklick Estates",
    "lat": 39.9049,
    "lng": -82.8655,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 9596,
    "id": 1840034379
  },
  {
    "city": "Middletown",
    "city_ascii": "Middletown",
    "lat": 40.201,
    "lng": -76.7289,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 9594,
    "id": 1840001291
  },
  {
    "city": "Greenville",
    "city_ascii": "Greenville",
    "lat": 41.4052,
    "lng": -80.3837,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 9594,
    "id": 1840000659
  },
  {
    "city": "Bull Mountain",
    "city_ascii": "Bull Mountain",
    "lat": 45.4126,
    "lng": -122.8322,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 9591,
    "id": 1840040416
  },
  {
    "city": "St. Pete Beach",
    "city_ascii": "St. Pete Beach",
    "lat": 27.7235,
    "lng": -82.7387,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 9587,
    "id": 1840015976
  },
  {
    "city": "Rice Lake",
    "city_ascii": "Rice Lake",
    "lat": 45.4863,
    "lng": -91.7447,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 9586,
    "id": 1840002075
  },
  {
    "city": "Hayle",
    "city_ascii": "Hayle",
    "lat": 50.183,
    "lng": -5.416,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Cornwall",
    "capital": "",
    "population": 8939,
    "id": 1826999750
  },
  {
    "city": "Lovosice",
    "city_ascii": "Lovosice",
    "lat": 50.5151,
    "lng": 14.0511,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Ústecký Kraj",
    "capital": "",
    "population": 8840,
    "id": 1203138826
  },
  {
    "city": "Yorkshire",
    "city_ascii": "Yorkshire",
    "lat": 38.7882,
    "lng": -77.4495,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 9582,
    "id": 1840006107
  },
  {
    "city": "Vareš",
    "city_ascii": "Vares",
    "lat": 44.1644,
    "lng": 18.3283,
    "country": "Bosnia And Herzegovina",
    "iso2": "BA",
    "iso3": "BIH",
    "admin_name": "Bosnia and Herzegovina, Federation of",
    "capital": "minor",
    "population": 9556,
    "id": 1070474354
  },
  {
    "city": "Reinfeld",
    "city_ascii": "Reinfeld",
    "lat": 53.8333,
    "lng": 10.4833,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Schleswig-Holstein",
    "capital": "",
    "population": 9058,
    "id": 1276759795
  },
  {
    "city": "Gorē",
    "city_ascii": "Gore",
    "lat": 8.149,
    "lng": 35.537,
    "country": "Ethiopia",
    "iso2": "ET",
    "iso3": "ETH",
    "admin_name": "Oromīya",
    "capital": "",
    "population": 9352,
    "id": 1231481918
  },
  {
    "city": "Altlandsberg",
    "city_ascii": "Altlandsberg",
    "lat": 52.5667,
    "lng": 13.7331,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Brandenburg",
    "capital": "",
    "population": 9490,
    "id": 1276344426
  },
  {
    "city": "Grand Rapids",
    "city_ascii": "Grand Rapids",
    "lat": 47.238,
    "lng": -93.5327,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 9579,
    "id": 1840007714
  },
  {
    "city": "Niesky",
    "city_ascii": "Niesky",
    "lat": 51.2897,
    "lng": 14.83,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 9402,
    "id": 1276763097
  },
  {
    "city": "Lwówek Śląski",
    "city_ascii": "Lwowek Slaski",
    "lat": 51.1167,
    "lng": 15.5833,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Dolnośląskie",
    "capital": "minor",
    "population": 9500,
    "id": 1616807220
  },
  {
    "city": "Mount Holly",
    "city_ascii": "Mount Holly",
    "lat": 39.995,
    "lng": -74.7863,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 9573,
    "id": 1840081635
  },
  {
    "city": "Tombos",
    "city_ascii": "Tombos",
    "lat": -20.9047,
    "lng": -42.0228,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Minas Gerais",
    "capital": "",
    "population": 9537,
    "id": 1076473026
  },
  {
    "city": "Ecorse",
    "city_ascii": "Ecorse",
    "lat": 42.2489,
    "lng": -83.1399,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 9570,
    "id": 1840003972
  },
  {
    "city": "Ouroeste",
    "city_ascii": "Ouroeste",
    "lat": -20.0008,
    "lng": -50.3719,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 9564,
    "id": 1076000763
  },
  {
    "city": "Fairview",
    "city_ascii": "Fairview",
    "lat": 45.5471,
    "lng": -122.4391,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 9567,
    "id": 1840019938
  },
  {
    "city": "Treorchy",
    "city_ascii": "Treorchy",
    "lat": 51.6594,
    "lng": -3.5055,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Rhondda Cynon Taff",
    "capital": "",
    "population": 7681,
    "id": 1826702624
  },
  {
    "city": "The Village",
    "city_ascii": "The Village",
    "lat": 35.5706,
    "lng": -97.5567,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oklahoma",
    "capital": "",
    "population": 9564,
    "id": 1840021742
  },
  {
    "city": "Oudenburg",
    "city_ascii": "Oudenburg",
    "lat": 51.1833,
    "lng": 3,
    "country": "Belgium",
    "iso2": "BE",
    "iso3": "BEL",
    "admin_name": "Flanders",
    "capital": "",
    "population": 9300,
    "id": 1056496818
  },
  {
    "city": "Fürstenau",
    "city_ascii": "Furstenau",
    "lat": 52.5167,
    "lng": 7.6667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 9439,
    "id": 1276365707
  },
  {
    "city": "Lügde",
    "city_ascii": "Lugde",
    "lat": 51.95,
    "lng": 9.25,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 9448,
    "id": 1276075848
  },
  {
    "city": "Edelény",
    "city_ascii": "Edeleny",
    "lat": 48.2967,
    "lng": 20.7442,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Borsod-Abaúj-Zemplén",
    "capital": "minor",
    "population": 9389,
    "id": 1348035547
  },
  {
    "city": "Eagle Point",
    "city_ascii": "Eagle Point",
    "lat": 42.4677,
    "lng": -122.8016,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 9554,
    "id": 1840018676
  },
  {
    "city": "Harleysville",
    "city_ascii": "Harleysville",
    "lat": 40.2791,
    "lng": -75.3872,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 9553,
    "id": 1840005463
  },
  {
    "city": "Craig",
    "city_ascii": "Craig",
    "lat": 40.517,
    "lng": -107.5555,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 9553,
    "id": 1840018741
  },
  {
    "city": "Willoughby Hills",
    "city_ascii": "Willoughby Hills",
    "lat": 41.5873,
    "lng": -81.4333,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 9553,
    "id": 1840000548
  },
  {
    "city": "Pohrebyshche",
    "city_ascii": "Pohrebyshche",
    "lat": 49.4869,
    "lng": 29.2733,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Vinnyts’ka Oblast’",
    "capital": "minor",
    "population": 9525,
    "id": 1804131540
  },
  {
    "city": "Walterboro",
    "city_ascii": "Walterboro",
    "lat": 32.901,
    "lng": -80.676,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 9550,
    "id": 1840015757
  },
  {
    "city": "Amherst",
    "city_ascii": "Amherst",
    "lat": 45.8167,
    "lng": -64.2167,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Nova Scotia",
    "capital": "",
    "population": 9550,
    "id": 1124895094
  },
  {
    "city": "Santo Tomás de los Plátanos",
    "city_ascii": "Santo Tomas de los Platanos",
    "lat": 19.1817,
    "lng": -100.2589,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "México",
    "capital": "minor",
    "population": 8888,
    "id": 1484531949
  },
  {
    "city": "Gurjaani",
    "city_ascii": "Gurjaani",
    "lat": 41.75,
    "lng": 45.8,
    "country": "Georgia",
    "iso2": "GE",
    "iso3": "GEO",
    "admin_name": "K’akheti",
    "capital": "minor",
    "population": 9467,
    "id": 1268569881
  },
  {
    "city": "Kearney",
    "city_ascii": "Kearney",
    "lat": 39.355,
    "lng": -94.3598,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 9546,
    "id": 1840008501
  },
  {
    "city": "Sankt Valentin",
    "city_ascii": "Sankt Valentin",
    "lat": 48.1747,
    "lng": 14.5333,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Niederösterreich",
    "capital": "",
    "population": 9340,
    "id": 1040852174
  },
  {
    "city": "Lowell",
    "city_ascii": "Lowell",
    "lat": 36.2561,
    "lng": -94.1532,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arkansas",
    "capital": "",
    "population": 9544,
    "id": 1840015285
  },
  {
    "city": "Rehau",
    "city_ascii": "Rehau",
    "lat": 50.2486,
    "lng": 12.0354,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 9424,
    "id": 1276647977
  },
  {
    "city": "Ecatzingo",
    "city_ascii": "Ecatzingo",
    "lat": 18.95,
    "lng": -98.75,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "México",
    "capital": "minor",
    "population": 9369,
    "id": 1484003696
  },
  {
    "city": "Silsbee",
    "city_ascii": "Silsbee",
    "lat": 30.3456,
    "lng": -94.1764,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 9540,
    "id": 1840022182
  },
  {
    "city": "Sebnitz",
    "city_ascii": "Sebnitz",
    "lat": 50.9667,
    "lng": 14.2833,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 9432,
    "id": 1276466123
  },
  {
    "city": "Sturbridge",
    "city_ascii": "Sturbridge",
    "lat": 42.1076,
    "lng": -72.0904,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 9537,
    "id": 1840053606
  },
  {
    "city": "Lantana",
    "city_ascii": "Lantana",
    "lat": 33.092,
    "lng": -97.1216,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 9536,
    "id": 1840025053
  },
  {
    "city": "Usworth",
    "city_ascii": "Usworth",
    "lat": 54.94,
    "lng": -1.55,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Sunderland",
    "capital": "",
    "population": 9100,
    "id": 1826648481
  },
  {
    "city": "Harvard",
    "city_ascii": "Harvard",
    "lat": 42.43,
    "lng": -88.6217,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 9533,
    "id": 1840006970
  },
  {
    "city": "Eidson Road",
    "city_ascii": "Eidson Road",
    "lat": 28.6677,
    "lng": -100.4787,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 9531,
    "id": 1840037044
  },
  {
    "city": "Central City",
    "city_ascii": "Central City",
    "lat": 37.2962,
    "lng": -87.128,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kentucky",
    "capital": "",
    "population": 9531,
    "id": 1840014378
  },
  {
    "city": "Shanklin",
    "city_ascii": "Shanklin",
    "lat": 50.631,
    "lng": -1.1734,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Isle of Wight",
    "capital": "",
    "population": 9072,
    "id": 1826746532
  },
  {
    "city": "Obernkirchen",
    "city_ascii": "Obernkirchen",
    "lat": 52.2664,
    "lng": 9.1178,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 9246,
    "id": 1276033571
  },
  {
    "city": "Tell City",
    "city_ascii": "Tell City",
    "lat": 37.9528,
    "lng": -86.7597,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 9529,
    "id": 1840009755
  },
  {
    "city": "Odobeşti",
    "city_ascii": "Odobesti",
    "lat": 45.7497,
    "lng": 27.1155,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Vrancea",
    "capital": "",
    "population": 9364,
    "id": 1642659916
  },
  {
    "city": "Sawbridgeworth",
    "city_ascii": "Sawbridgeworth",
    "lat": 51.814,
    "lng": 0.15,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hertfordshire",
    "capital": "",
    "population": 8458,
    "id": 1826916498
  },
  {
    "city": "Williston",
    "city_ascii": "Williston",
    "lat": 44.4348,
    "lng": -73.0894,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Vermont",
    "capital": "",
    "population": 9526,
    "id": 1840071854
  },
  {
    "city": "Lüchow",
    "city_ascii": "Luchow",
    "lat": 52.9667,
    "lng": 11.15,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "minor",
    "population": 9420,
    "id": 1276197749
  },
  {
    "city": "Bansko",
    "city_ascii": "Bansko",
    "lat": 41.8333,
    "lng": 23.4833,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Blagoevgrad",
    "capital": "",
    "population": 9497,
    "id": 1100442389
  },
  {
    "city": "Neu Bleckede",
    "city_ascii": "Neu Bleckede",
    "lat": 53.3,
    "lng": 10.7333,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Mecklenburg-Western Pomerania",
    "capital": "",
    "population": 9457,
    "id": 1276752770
  },
  {
    "city": "Little River",
    "city_ascii": "Little River",
    "lat": 33.8787,
    "lng": -78.6393,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 9523,
    "id": 1840013648
  },
  {
    "city": "Hebron",
    "city_ascii": "Hebron",
    "lat": 41.6594,
    "lng": -72.3905,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 9522,
    "id": 1840035074
  },
  {
    "city": "Rye Brook",
    "city_ascii": "Rye Brook",
    "lat": 41.0303,
    "lng": -73.6865,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 9521,
    "id": 1840004942
  },
  {
    "city": "Hazle",
    "city_ascii": "Hazle",
    "lat": 40.9561,
    "lng": -75.9992,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 9520,
    "id": 1840148627
  },
  {
    "city": "Saßnitz",
    "city_ascii": "Sassnitz",
    "lat": 54.5164,
    "lng": 13.6411,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Mecklenburg-Western Pomerania",
    "capital": "",
    "population": 9320,
    "id": 1276897378
  },
  {
    "city": "Zacualpan de Amilpas",
    "city_ascii": "Zacualpan de Amilpas",
    "lat": 18.7836,
    "lng": -98.7594,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Morelos",
    "capital": "minor",
    "population": 9370,
    "id": 1484800291
  },
  {
    "city": "DuPont",
    "city_ascii": "DuPont",
    "lat": 47.1079,
    "lng": -122.6496,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 9516,
    "id": 1840018457
  },
  {
    "city": "Runkel",
    "city_ascii": "Runkel",
    "lat": 50.4053,
    "lng": 8.155,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 9303,
    "id": 1276349515
  },
  {
    "city": "Bardmoor",
    "city_ascii": "Bardmoor",
    "lat": 27.8575,
    "lng": -82.7534,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 9514,
    "id": 1840038841
  },
  {
    "city": "Miltenberg",
    "city_ascii": "Miltenberg",
    "lat": 49.7039,
    "lng": 9.2644,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "minor",
    "population": 9355,
    "id": 1276744365
  },
  {
    "city": "Reedsburg",
    "city_ascii": "Reedsburg",
    "lat": 43.5347,
    "lng": -89.9965,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 9510,
    "id": 1840002727
  },
  {
    "city": "Asbury Lake",
    "city_ascii": "Asbury Lake",
    "lat": 30.0472,
    "lng": -81.7855,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 9510,
    "id": 1840029019
  },
  {
    "city": "Mound",
    "city_ascii": "Mound",
    "lat": 44.9328,
    "lng": -93.6591,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 9509,
    "id": 1840007828
  },
  {
    "city": "Dayton",
    "city_ascii": "Dayton",
    "lat": 39.258,
    "lng": -119.5677,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Nevada",
    "capital": "",
    "population": 9508,
    "id": 1840018796
  },
  {
    "city": "Aleksandrovsk-Sakhalinskiy",
    "city_ascii": "Aleksandrovsk-Sakhalinskiy",
    "lat": 50.9,
    "lng": 142.15,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sakhalinskaya Oblast’",
    "capital": "",
    "population": 9504,
    "id": 1643873847
  },
  {
    "city": "Puerto Morelos",
    "city_ascii": "Puerto Morelos",
    "lat": 20.8536,
    "lng": -86.8753,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Quintana Roo",
    "capital": "",
    "population": 9188,
    "id": 1484801229
  },
  {
    "city": "Cameron",
    "city_ascii": "Cameron",
    "lat": 39.7469,
    "lng": -94.2364,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 9501,
    "id": 1840007321
  },
  {
    "city": "Erwin",
    "city_ascii": "Erwin",
    "lat": 36.1456,
    "lng": -82.4115,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 9500,
    "id": 1840016130
  },
  {
    "city": "Independent Hill",
    "city_ascii": "Independent Hill",
    "lat": 38.6404,
    "lng": -77.409,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 9499,
    "id": 1840026708
  },
  {
    "city": "Hope",
    "city_ascii": "Hope",
    "lat": 33.6682,
    "lng": -93.5895,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arkansas",
    "capital": "",
    "population": 9499,
    "id": 1840014765
  },
  {
    "city": "Baker City",
    "city_ascii": "Baker City",
    "lat": 44.7749,
    "lng": -117.832,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 9499,
    "id": 1840018587
  },
  {
    "city": "Huedin",
    "city_ascii": "Huedin",
    "lat": 46.8738,
    "lng": 23.0041,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Cluj",
    "capital": "",
    "population": 9346,
    "id": 1642303721
  },
  {
    "city": "Rigby",
    "city_ascii": "Rigby",
    "lat": 43.6735,
    "lng": -111.9126,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Idaho",
    "capital": "",
    "population": 9496,
    "id": 1840020020
  },
  {
    "city": "Rudnya",
    "city_ascii": "Rudnya",
    "lat": 54.95,
    "lng": 31.0667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Smolenskaya Oblast’",
    "capital": "",
    "population": 9484,
    "id": 1643921469
  },
  {
    "city": "Fort Stockton",
    "city_ascii": "Fort Stockton",
    "lat": 30.8926,
    "lng": -102.8844,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 9495,
    "id": 1840020848
  },
  {
    "city": "Helena-West Helena",
    "city_ascii": "Helena-West Helena",
    "lat": 34.5314,
    "lng": -90.6201,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arkansas",
    "capital": "",
    "population": 9493,
    "id": 1840027858
  },
  {
    "city": "Ramara",
    "city_ascii": "Ramara",
    "lat": 44.6333,
    "lng": -79.2167,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 9488,
    "id": 1124000641
  },
  {
    "city": "Airway Heights",
    "city_ascii": "Airway Heights",
    "lat": 47.646,
    "lng": -117.5792,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 9485,
    "id": 1840018399
  },
  {
    "city": "Síndos",
    "city_ascii": "Sindos",
    "lat": 40.6667,
    "lng": 22.8,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Kentrikí Makedonía",
    "capital": "minor",
    "population": 9289,
    "id": 1300637403
  },
  {
    "city": "South Strabane",
    "city_ascii": "South Strabane",
    "lat": 40.1756,
    "lng": -80.191,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 9479,
    "id": 1840101300
  },
  {
    "city": "Tanfield",
    "city_ascii": "Tanfield",
    "lat": 54.897,
    "lng": -1.703,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Durham",
    "capital": "",
    "population": 8270,
    "id": 1826347778
  },
  {
    "city": "Laurel",
    "city_ascii": "Laurel",
    "lat": 27.1446,
    "lng": -82.4618,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 9477,
    "id": 1840014184
  },
  {
    "city": "Le Mars",
    "city_ascii": "Le Mars",
    "lat": 42.7809,
    "lng": -96.1743,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Iowa",
    "capital": "",
    "population": 9477,
    "id": 1840008022
  },
  {
    "city": "Rapid Valley",
    "city_ascii": "Rapid Valley",
    "lat": 44.0674,
    "lng": -103.1223,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Dakota",
    "capital": "",
    "population": 9475,
    "id": 1840004098
  },
  {
    "city": "Rauenberg",
    "city_ascii": "Rauenberg",
    "lat": 49.2678,
    "lng": 8.7036,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 8693,
    "id": 1276997920
  },
  {
    "city": "Zwenkau",
    "city_ascii": "Zwenkau",
    "lat": 51.2175,
    "lng": 12.3242,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 9274,
    "id": 1276888789
  },
  {
    "city": "Wertingen",
    "city_ascii": "Wertingen",
    "lat": 48.5333,
    "lng": 10.6667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 9294,
    "id": 1276445466
  },
  {
    "city": "Fayetteville",
    "city_ascii": "Fayetteville",
    "lat": 35.149,
    "lng": -86.5634,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 9473,
    "id": 1840013476
  },
  {
    "city": "Penn Township",
    "city_ascii": "Penn Township",
    "lat": 40.1864,
    "lng": -76.3726,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 9473,
    "id": 1840147756
  },
  {
    "city": "Chiasso",
    "city_ascii": "Chiasso",
    "lat": 45.8353,
    "lng": 9.032,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Ticino",
    "capital": "",
    "population": 7975,
    "id": 1756413890
  },
  {
    "city": "Lititz",
    "city_ascii": "Lititz",
    "lat": 40.154,
    "lng": -76.3044,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 9465,
    "id": 1840003719
  },
  {
    "city": "Leeds and the Thousand Islands",
    "city_ascii": "Leeds and the Thousand Islands",
    "lat": 44.45,
    "lng": -76.08,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 9465,
    "id": 1124000531
  },
  {
    "city": "Siler City",
    "city_ascii": "Siler City",
    "lat": 35.7252,
    "lng": -79.4561,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 9464,
    "id": 1840017783
  },
  {
    "city": "Platte City",
    "city_ascii": "Platte City",
    "lat": 39.3576,
    "lng": -94.7655,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 9462,
    "id": 1840009591
  },
  {
    "city": "Carignan",
    "city_ascii": "Carignan",
    "lat": 45.45,
    "lng": -73.3,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 9462,
    "id": 1124001655
  },
  {
    "city": "Dunblane",
    "city_ascii": "Dunblane",
    "lat": 56.1838,
    "lng": -3.9674,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Stirling",
    "capital": "",
    "population": 9000,
    "id": 1826445077
  },
  {
    "city": "Brockton",
    "city_ascii": "Brockton",
    "lat": 44.1667,
    "lng": -81.2167,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 9461,
    "id": 1124000713
  },
  {
    "city": "Bezhetsk",
    "city_ascii": "Bezhetsk",
    "lat": 57.7833,
    "lng": 36.7,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Tverskaya Oblast’",
    "capital": "",
    "population": 9450,
    "id": 1643708632
  },
  {
    "city": "Codsall",
    "city_ascii": "Codsall",
    "lat": 52.6267,
    "lng": -2.1924,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Staffordshire",
    "capital": "",
    "population": 7582,
    "id": 1826956381
  },
  {
    "city": "Mount Airy",
    "city_ascii": "Mount Airy",
    "lat": 39.3743,
    "lng": -77.1535,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 9458,
    "id": 1840005703
  },
  {
    "city": "Fársala",
    "city_ascii": "Farsala",
    "lat": 39.2833,
    "lng": 22.3833,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Thessalía",
    "capital": "minor",
    "population": 9298,
    "id": 1300870783
  },
  {
    "city": "Bithlo",
    "city_ascii": "Bithlo",
    "lat": 28.5644,
    "lng": -81.1074,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 9457,
    "id": 1840014083
  },
  {
    "city": "Kirs",
    "city_ascii": "Kirs",
    "lat": 59.3372,
    "lng": 52.2455,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kirovskaya Oblast’",
    "capital": "",
    "population": 9453,
    "id": 1643457660
  },
  {
    "city": "Townsend",
    "city_ascii": "Townsend",
    "lat": 42.6671,
    "lng": -71.7115,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 9455,
    "id": 1840053491
  },
  {
    "city": "White Marsh",
    "city_ascii": "White Marsh",
    "lat": 39.3819,
    "lng": -76.4573,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 9455,
    "id": 1840005696
  },
  {
    "city": "Roxborough Park",
    "city_ascii": "Roxborough Park",
    "lat": 39.4492,
    "lng": -105.0746,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 9452,
    "id": 1840028568
  },
  {
    "city": "Picture Rocks",
    "city_ascii": "Picture Rocks",
    "lat": 32.3274,
    "lng": -111.2557,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 9452,
    "id": 1840019482
  },
  {
    "city": "Aniva",
    "city_ascii": "Aniva",
    "lat": 46.7167,
    "lng": 142.5167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sakhalinskaya Oblast’",
    "capital": "",
    "population": 9445,
    "id": 1643419980
  },
  {
    "city": "Dubrovytsya",
    "city_ascii": "Dubrovytsya",
    "lat": 51.5667,
    "lng": 26.5667,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Rivnens’ka Oblast’",
    "capital": "minor",
    "population": 9424,
    "id": 1804570134
  },
  {
    "city": "Carencro",
    "city_ascii": "Carencro",
    "lat": 30.3126,
    "lng": -92.0387,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 9449,
    "id": 1840015043
  },
  {
    "city": "Ţālkhvoncheh",
    "city_ascii": "Talkhvoncheh",
    "lat": 32.2628,
    "lng": 51.5622,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Eşfahān",
    "capital": "",
    "population": 9307,
    "id": 1364903302
  },
  {
    "city": "Winslow",
    "city_ascii": "Winslow",
    "lat": 35.0253,
    "lng": -110.7098,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 9443,
    "id": 1840021583
  },
  {
    "city": "Ruidoso",
    "city_ascii": "Ruidoso",
    "lat": 33.3647,
    "lng": -105.6432,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Mexico",
    "capital": "",
    "population": 9442,
    "id": 1840022873
  },
  {
    "city": "Nagaoki",
    "city_ascii": "Nagaoki",
    "lat": 32.9781,
    "lng": 130.6058,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Kumamoto",
    "capital": "",
    "population": 9347,
    "id": 1392700206
  },
  {
    "city": "Gramsh",
    "city_ascii": "Gramsh",
    "lat": 40.8667,
    "lng": 20.1833,
    "country": "Albania",
    "iso2": "AL",
    "iso3": "ALB",
    "admin_name": "Elbasan",
    "capital": "",
    "population": 8440,
    "id": 1008632813
  },
  {
    "city": "Ócsa",
    "city_ascii": "Ocsa",
    "lat": 47.2933,
    "lng": 19.2258,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Pest",
    "capital": "",
    "population": 9325,
    "id": 1348733489
  },
  {
    "city": "Miles City",
    "city_ascii": "Miles City",
    "lat": 46.4059,
    "lng": -105.8385,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Montana",
    "capital": "",
    "population": 9436,
    "id": 1840019878
  },
  {
    "city": "Grigoriopol",
    "city_ascii": "Grigoriopol",
    "lat": 47.1536,
    "lng": 29.2964,
    "country": "Moldova",
    "iso2": "MD",
    "iso3": "MDA",
    "admin_name": "Stînga Nistrului",
    "capital": "",
    "population": 9381,
    "id": 1498783401
  },
  {
    "city": "Wallan",
    "city_ascii": "Wallan",
    "lat": -37.4167,
    "lng": 144.9833,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Victoria",
    "capital": "",
    "population": 8520,
    "id": 1036357414
  },
  {
    "city": "Mohelnice",
    "city_ascii": "Mohelnice",
    "lat": 49.777,
    "lng": 16.9195,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Olomoucký Kraj",
    "capital": "",
    "population": 9234,
    "id": 1203067373
  },
  {
    "city": "Manistee",
    "city_ascii": "Manistee",
    "lat": 44.244,
    "lng": -86.3242,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 9433,
    "id": 1840002511
  },
  {
    "city": "Rayne",
    "city_ascii": "Rayne",
    "lat": 30.2403,
    "lng": -92.2668,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 9431,
    "id": 1840015042
  },
  {
    "city": "Scaggsville",
    "city_ascii": "Scaggsville",
    "lat": 39.1416,
    "lng": -76.8843,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 9430,
    "id": 1840024483
  },
  {
    "city": "Wunsiedel",
    "city_ascii": "Wunsiedel",
    "lat": 50.0374,
    "lng": 11.9994,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "minor",
    "population": 9259,
    "id": 1276110549
  },
  {
    "city": "Marcy",
    "city_ascii": "Marcy",
    "lat": 43.1732,
    "lng": -75.2662,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 9424,
    "id": 1840058287
  },
  {
    "city": "Staryy Krym",
    "city_ascii": "Staryy Krym",
    "lat": 45.0292,
    "lng": 35.0886,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Krym, Avtonomna Respublika",
    "capital": "",
    "population": 9370,
    "id": 1804335230
  },
  {
    "city": "Castelli",
    "city_ascii": "Castelli",
    "lat": -25.95,
    "lng": -60.6167,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Chaco",
    "capital": "minor",
    "population": 9421,
    "id": 1032193694
  },
  {
    "city": "Augusta",
    "city_ascii": "Augusta",
    "lat": 37.6955,
    "lng": -96.9921,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kansas",
    "capital": "",
    "population": 9422,
    "id": 1840001675
  },
  {
    "city": "Orivesi",
    "city_ascii": "Orivesi",
    "lat": 61.6778,
    "lng": 24.3569,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Pirkanmaa",
    "capital": "minor",
    "population": 9408,
    "id": 1246191762
  },
  {
    "city": "St. Augustine Shores",
    "city_ascii": "St. Augustine Shores",
    "lat": 29.8039,
    "lng": -81.3086,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 9418,
    "id": 1840013972
  },
  {
    "city": "Spáta",
    "city_ascii": "Spata",
    "lat": 37.9667,
    "lng": 23.9167,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Attikí",
    "capital": "minor",
    "population": 9198,
    "id": 1300805467
  },
  {
    "city": "Krasnoslobodsk",
    "city_ascii": "Krasnoslobodsk",
    "lat": 54.4333,
    "lng": 43.7833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Mordoviya",
    "capital": "",
    "population": 9397,
    "id": 1643898157
  },
  {
    "city": "Ichenhausen",
    "city_ascii": "Ichenhausen",
    "lat": 48.3712,
    "lng": 10.3071,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 9148,
    "id": 1276943409
  },
  {
    "city": "Braslaw",
    "city_ascii": "Braslaw",
    "lat": 55.6397,
    "lng": 27.0397,
    "country": "Belarus",
    "iso2": "BY",
    "iso3": "BLR",
    "admin_name": "Vitsyebskaya Voblasts’",
    "capital": "minor",
    "population": 9400,
    "id": 1112414592
  },
  {
    "city": "Laufenburg (Baden)",
    "city_ascii": "Laufenburg (Baden)",
    "lat": 47.5656,
    "lng": 8.0647,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 9029,
    "id": 1276283641
  },
  {
    "city": "Harrisonville",
    "city_ascii": "Harrisonville",
    "lat": 38.6529,
    "lng": -94.3467,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 9412,
    "id": 1840007464
  },
  {
    "city": "Krichim",
    "city_ascii": "Krichim",
    "lat": 42.0414,
    "lng": 24.47,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Plovdiv",
    "capital": "",
    "population": 9188,
    "id": 1100205448
  },
  {
    "city": "Tenabo",
    "city_ascii": "Tenabo",
    "lat": 20.0333,
    "lng": -90.2167,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Campeche",
    "capital": "minor",
    "population": 7543,
    "id": 1484006331
  },
  {
    "city": "Monticello",
    "city_ascii": "Monticello",
    "lat": 33.6258,
    "lng": -91.7934,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arkansas",
    "capital": "",
    "population": 9409,
    "id": 1840014805
  },
  {
    "city": "Golden Hills",
    "city_ascii": "Golden Hills",
    "lat": 35.1512,
    "lng": -118.5024,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 9407,
    "id": 1840017819
  },
  {
    "city": "Little Falls",
    "city_ascii": "Little Falls",
    "lat": 45.9833,
    "lng": -94.36,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 9406,
    "id": 1840008861
  },
  {
    "city": "Great Dunmow",
    "city_ascii": "Great Dunmow",
    "lat": 51.873,
    "lng": 0.3617,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Essex",
    "capital": "",
    "population": 8830,
    "id": 1826161396
  },
  {
    "city": "Maidencreek",
    "city_ascii": "Maidencreek",
    "lat": 40.4618,
    "lng": -75.8927,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 9402,
    "id": 1840103346
  },
  {
    "city": "Kandel",
    "city_ascii": "Kandel",
    "lat": 49.0828,
    "lng": 8.1964,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Rhineland-Palatinate",
    "capital": "",
    "population": 9061,
    "id": 1276402753
  },
  {
    "city": "Bryan",
    "city_ascii": "Bryan",
    "lat": 41.4706,
    "lng": -84.5484,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 9401,
    "id": 1840000573
  },
  {
    "city": "Francisville",
    "city_ascii": "Francisville",
    "lat": 39.1067,
    "lng": -84.7277,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kentucky",
    "capital": "",
    "population": 9401,
    "id": 1840026679
  },
  {
    "city": "Newfane",
    "city_ascii": "Newfane",
    "lat": 43.2818,
    "lng": -78.6932,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 9400,
    "id": 1840023982
  },
  {
    "city": "Amityville",
    "city_ascii": "Amityville",
    "lat": 40.6696,
    "lng": -73.4156,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 9399,
    "id": 1840005140
  },
  {
    "city": "Shimizu",
    "city_ascii": "Shimizu",
    "lat": 43.0111,
    "lng": 142.8847,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hokkaidō",
    "capital": "",
    "population": 9375,
    "id": 1392903163
  },
  {
    "city": "Närpes",
    "city_ascii": "Narpes",
    "lat": 62.4736,
    "lng": 21.3375,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Pohjanmaa",
    "capital": "minor",
    "population": 9387,
    "id": 1246805566
  },
  {
    "city": "Tysmenytsya",
    "city_ascii": "Tysmenytsya",
    "lat": 48.9008,
    "lng": 24.8492,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Ivano-Frankivs’ka Oblast’",
    "capital": "minor",
    "population": 9287,
    "id": 1804289789
  },
  {
    "city": "Murrells Inlet",
    "city_ascii": "Murrells Inlet",
    "lat": 33.5566,
    "lng": -79.0593,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 9396,
    "id": 1840013742
  },
  {
    "city": "Hostivice",
    "city_ascii": "Hostivice",
    "lat": 50.0817,
    "lng": 14.2586,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Středočeský Kraj",
    "capital": "",
    "population": 8788,
    "id": 1203249506
  },
  {
    "city": "Vega Alta",
    "city_ascii": "Vega Alta",
    "lat": 18.4152,
    "lng": -66.3211,
    "country": "Puerto Rico",
    "iso2": "PR",
    "iso3": "PRI",
    "admin_name": "Puerto Rico",
    "capital": "",
    "population": 9394,
    "id": 1630023587
  },
  {
    "city": "Lambertville",
    "city_ascii": "Lambertville",
    "lat": 41.7502,
    "lng": -83.625,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 9394,
    "id": 1840006576
  },
  {
    "city": "Brunswick",
    "city_ascii": "Brunswick",
    "lat": 39.318,
    "lng": -77.6253,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 9393,
    "id": 1840005709
  },
  {
    "city": "Usakos",
    "city_ascii": "Usakos",
    "lat": -22,
    "lng": 15.6,
    "country": "Namibia",
    "iso2": "NA",
    "iso3": "NAM",
    "admin_name": "Erongo",
    "capital": "",
    "population": 9147,
    "id": 1516430978
  },
  {
    "city": "Navoloki",
    "city_ascii": "Navoloki",
    "lat": 57.4667,
    "lng": 41.9667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Ivanovskaya Oblast’",
    "capital": "",
    "population": 9374,
    "id": 1643201351
  },
  {
    "city": "Nová Paka",
    "city_ascii": "Nova Paka",
    "lat": 50.4945,
    "lng": 15.5151,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Liberecký Kraj",
    "capital": "",
    "population": 9072,
    "id": 1203255589
  },
  {
    "city": "Sompting",
    "city_ascii": "Sompting",
    "lat": 50.8303,
    "lng": -0.3395,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "West Sussex",
    "capital": "",
    "population": 8561,
    "id": 1826847818
  },
  {
    "city": "Roanoke",
    "city_ascii": "Roanoke",
    "lat": 33.0148,
    "lng": -97.2268,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 9388,
    "id": 1840020641
  },
  {
    "city": "Argoncilhe",
    "city_ascii": "Argoncilhe",
    "lat": 41.0167,
    "lng": -8.55,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Aveiro",
    "capital": "",
    "population": 8420,
    "id": 1620670627
  },
  {
    "city": "Meadowbrook",
    "city_ascii": "Meadowbrook",
    "lat": 33.3932,
    "lng": -86.7041,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 9387,
    "id": 1840027633
  },
  {
    "city": "Laurentian Valley",
    "city_ascii": "Laurentian Valley",
    "lat": 45.7681,
    "lng": -77.2239,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 9387,
    "id": 1124000736
  },
  {
    "city": "Navasota",
    "city_ascii": "Navasota",
    "lat": 30.3874,
    "lng": -96.0895,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 9386,
    "id": 1840023174
  },
  {
    "city": "Sutton",
    "city_ascii": "Sutton",
    "lat": 42.1337,
    "lng": -71.7503,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 9385,
    "id": 1840053701
  },
  {
    "city": "White Meadow Lake",
    "city_ascii": "White Meadow Lake",
    "lat": 40.924,
    "lng": -74.5121,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 9383,
    "id": 1840005198
  },
  {
    "city": "Irshava",
    "city_ascii": "Irshava",
    "lat": 48.3172,
    "lng": 23.0375,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Zakarpats’ka Oblast’",
    "capital": "minor",
    "population": 9276,
    "id": 1804188148
  },
  {
    "city": "Beverly Hills",
    "city_ascii": "Beverly Hills",
    "lat": 28.9175,
    "lng": -82.4541,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 9381,
    "id": 1840014061
  },
  {
    "city": "Crestline",
    "city_ascii": "Crestline",
    "lat": 34.2486,
    "lng": -117.289,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 9381,
    "id": 1840017796
  },
  {
    "city": "Igaratá",
    "city_ascii": "Igarata",
    "lat": -23.2044,
    "lng": -46.1561,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 9349,
    "id": 1076600029
  },
  {
    "city": "Flowood",
    "city_ascii": "Flowood",
    "lat": 32.3359,
    "lng": -90.0802,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Mississippi",
    "capital": "",
    "population": 9380,
    "id": 1840013824
  },
  {
    "city": "Liberty",
    "city_ascii": "Liberty",
    "lat": 41.8132,
    "lng": -74.7775,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 9378,
    "id": 1840004790
  },
  {
    "city": "Kargat",
    "city_ascii": "Kargat",
    "lat": 55.2,
    "lng": 80.2833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Novosibirskaya Oblast’",
    "capital": "",
    "population": 9370,
    "id": 1643914194
  },
  {
    "city": "Néa Péramos",
    "city_ascii": "Nea Peramos",
    "lat": 38,
    "lng": 23.4167,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Attikí",
    "capital": "",
    "population": 8333,
    "id": 1300896175
  },
  {
    "city": "Currumbin",
    "city_ascii": "Currumbin",
    "lat": -28.158,
    "lng": 153.469,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Queensland",
    "capital": "",
    "population": 9121,
    "id": 1036194178
  },
  {
    "city": "Guthrie",
    "city_ascii": "Guthrie",
    "lat": 35.8428,
    "lng": -97.4357,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oklahoma",
    "capital": "",
    "population": 9373,
    "id": 1840020390
  },
  {
    "city": "Brandon",
    "city_ascii": "Brandon",
    "lat": 43.5928,
    "lng": -96.5799,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Dakota",
    "capital": "",
    "population": 9372,
    "id": 1840002650
  },
  {
    "city": "East St. Paul",
    "city_ascii": "East St. Paul",
    "lat": 49.9772,
    "lng": -97.0103,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Manitoba",
    "capital": "",
    "population": 9372,
    "id": 1124000695
  },
  {
    "city": "Crissier",
    "city_ascii": "Crissier",
    "lat": 46.55,
    "lng": 6.5833,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Vaud",
    "capital": "",
    "population": 7930,
    "id": 1756968744
  },
  {
    "city": "Bloomfield",
    "city_ascii": "Bloomfield",
    "lat": 36.7401,
    "lng": -107.9734,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Mexico",
    "capital": "",
    "population": 9370,
    "id": 1840018994
  },
  {
    "city": "Rio Vista",
    "city_ascii": "Rio Vista",
    "lat": 38.1763,
    "lng": -121.7025,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 9369,
    "id": 1840020254
  },
  {
    "city": "Siteía",
    "city_ascii": "Siteia",
    "lat": 35.2,
    "lng": 26.1,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Kríti",
    "capital": "minor",
    "population": 9348,
    "id": 1300275780
  },
  {
    "city": "Kyritz",
    "city_ascii": "Kyritz",
    "lat": 52.9422,
    "lng": 12.3972,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Brandenburg",
    "capital": "",
    "population": 9303,
    "id": 1276035237
  },
  {
    "city": "Portland",
    "city_ascii": "Portland",
    "lat": 41.5988,
    "lng": -72.589,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 9362,
    "id": 1840004915
  },
  {
    "city": "Boonville",
    "city_ascii": "Boonville",
    "lat": 38.9588,
    "lng": -92.7471,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 9362,
    "id": 1840007420
  },
  {
    "city": "Bli Bli",
    "city_ascii": "Bli Bli",
    "lat": -26.618,
    "lng": 153.037,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Queensland",
    "capital": "",
    "population": 7801,
    "id": 1036863296
  },
  {
    "city": "Putnam",
    "city_ascii": "Putnam",
    "lat": 41.9093,
    "lng": -71.8711,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 9360,
    "id": 1840035552
  },
  {
    "city": "Louisville",
    "city_ascii": "Louisville",
    "lat": 40.8371,
    "lng": -81.2643,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 9360,
    "id": 1840000964
  },
  {
    "city": "Tvrdošín",
    "city_ascii": "Tvrdosin",
    "lat": 49.3369,
    "lng": 19.5503,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Žilinský",
    "capital": "minor",
    "population": 9195,
    "id": 1703880169
  },
  {
    "city": "Clay Cross",
    "city_ascii": "Clay Cross",
    "lat": 53.1637,
    "lng": -1.4128,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Derbyshire",
    "capital": "",
    "population": 9222,
    "id": 1826222339
  },
  {
    "city": "Hackettstown",
    "city_ascii": "Hackettstown",
    "lat": 40.854,
    "lng": -74.8257,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 9356,
    "id": 1840003573
  },
  {
    "city": "North Gates",
    "city_ascii": "North Gates",
    "lat": 43.1718,
    "lng": -77.7064,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 9353,
    "id": 1840024047
  },
  {
    "city": "Lorraine",
    "city_ascii": "Lorraine",
    "lat": 45.6833,
    "lng": -73.7833,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 9352,
    "id": 1124001859
  },
  {
    "city": "Khotyn",
    "city_ascii": "Khotyn",
    "lat": 48.5,
    "lng": 26.5,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Chernivets’ka Oblast’",
    "capital": "minor",
    "population": 9262,
    "id": 1804000263
  },
  {
    "city": "Verkhneuralsk",
    "city_ascii": "Verkhneuralsk",
    "lat": 53.8833,
    "lng": 59.2167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Chelyabinskaya Oblast’",
    "capital": "",
    "population": 9340,
    "id": 1643635523
  },
  {
    "city": "Nagykálló",
    "city_ascii": "Nagykallo",
    "lat": 47.8831,
    "lng": 21.85,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Szabolcs-Szatmár-Bereg",
    "capital": "minor",
    "population": 9214,
    "id": 1348262569
  },
  {
    "city": "Chotěboř",
    "city_ascii": "Chotebor",
    "lat": 49.7208,
    "lng": 15.6702,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Vysočina",
    "capital": "",
    "population": 9178,
    "id": 1203957935
  },
  {
    "city": "Ariranha",
    "city_ascii": "Ariranha",
    "lat": -21.1878,
    "lng": -48.7869,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 9277,
    "id": 1076878901
  },
  {
    "city": "Kendall Park",
    "city_ascii": "Kendall Park",
    "lat": 40.4138,
    "lng": -74.5626,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 9346,
    "id": 1840005419
  },
  {
    "city": "Thompson",
    "city_ascii": "Thompson",
    "lat": 41.9798,
    "lng": -71.8735,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 9343,
    "id": 1840035623
  },
  {
    "city": "Saline",
    "city_ascii": "Saline",
    "lat": 42.1741,
    "lng": -83.778,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 9343,
    "id": 1840003171
  },
  {
    "city": "Madras",
    "city_ascii": "Madras",
    "lat": 44.6425,
    "lng": -121.1315,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 9340,
    "id": 1840019987
  },
  {
    "city": "Ineu",
    "city_ascii": "Ineu",
    "lat": 46.4258,
    "lng": 21.8369,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Arad",
    "capital": "",
    "population": 9260,
    "id": 1642186469
  },
  {
    "city": "Cheat Lake",
    "city_ascii": "Cheat Lake",
    "lat": 39.6672,
    "lng": -79.8565,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "West Virginia",
    "capital": "",
    "population": 9337,
    "id": 1840005645
  },
  {
    "city": "Vallendar",
    "city_ascii": "Vallendar",
    "lat": 50.4003,
    "lng": 7.6172,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Rhineland-Palatinate",
    "capital": "",
    "population": 8680,
    "id": 1276411152
  },
  {
    "city": "Clinton",
    "city_ascii": "Clinton",
    "lat": 35.0005,
    "lng": -78.3311,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 9334,
    "id": 1840014605
  },
  {
    "city": "Porangaba",
    "city_ascii": "Porangaba",
    "lat": -23.1758,
    "lng": -48.125,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 9299,
    "id": 1076548805
  },
  {
    "city": "Banārūyeh",
    "city_ascii": "Banaruyeh",
    "lat": 28.0839,
    "lng": 54.0483,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Fārs",
    "capital": "",
    "population": 9318,
    "id": 1364350931
  },
  {
    "city": "Heath",
    "city_ascii": "Heath",
    "lat": 32.8444,
    "lng": -96.4679,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 9331,
    "id": 1840020716
  },
  {
    "city": "Sainte-Julienne",
    "city_ascii": "Sainte-Julienne",
    "lat": 45.97,
    "lng": -73.72,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 9331,
    "id": 1124086540
  },
  {
    "city": "Celebration",
    "city_ascii": "Celebration",
    "lat": 28.3102,
    "lng": -81.551,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 9329,
    "id": 1840014138
  },
  {
    "city": "Blackfalds",
    "city_ascii": "Blackfalds",
    "lat": 52.3833,
    "lng": -113.8,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Alberta",
    "capital": "",
    "population": 9328,
    "id": 1124056144
  },
  {
    "city": "Fort Stewart",
    "city_ascii": "Fort Stewart",
    "lat": 31.8818,
    "lng": -81.6105,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 9326,
    "id": 1840073839
  },
  {
    "city": "Belousovo",
    "city_ascii": "Belousovo",
    "lat": 55.0917,
    "lng": 36.6667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kaluzhskaya Oblast’",
    "capital": "",
    "population": 9288,
    "id": 1643537529
  },
  {
    "city": "Lenoir City",
    "city_ascii": "Lenoir City",
    "lat": 35.811,
    "lng": -84.2818,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 9324,
    "id": 1840014514
  },
  {
    "city": "Hartland",
    "city_ascii": "Hartland",
    "lat": 43.1027,
    "lng": -88.3399,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 9320,
    "id": 1840003009
  },
  {
    "city": "College Place",
    "city_ascii": "College Place",
    "lat": 46.0419,
    "lng": -118.3879,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 9317,
    "id": 1840018490
  },
  {
    "city": "Svit",
    "city_ascii": "Svit",
    "lat": 49.0583,
    "lng": 20.2025,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Prešovský",
    "capital": "",
    "population": 7624,
    "id": 1703210837
  },
  {
    "city": "Jennings",
    "city_ascii": "Jennings",
    "lat": 30.2233,
    "lng": -92.6582,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 9315,
    "id": 1840015040
  },
  {
    "city": "Trumpington",
    "city_ascii": "Trumpington",
    "lat": 52.174,
    "lng": 0.109,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Cambridgeshire",
    "capital": "",
    "population": 8034,
    "id": 1826399711
  },
  {
    "city": "Bad Bevensen",
    "city_ascii": "Bad Bevensen",
    "lat": 53.0792,
    "lng": 10.5833,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 9122,
    "id": 1276837566
  },
  {
    "city": "Stevenston",
    "city_ascii": "Stevenston",
    "lat": 55.645,
    "lng": -4.758,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "North Ayrshire",
    "capital": "",
    "population": 9129,
    "id": 1826555115
  },
  {
    "city": "Veľké Kapušany",
    "city_ascii": "Vel'ke Kapusany",
    "lat": 48.55,
    "lng": 22.0833,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Košický",
    "capital": "",
    "population": 9004,
    "id": 1703807609
  },
  {
    "city": "Tomah",
    "city_ascii": "Tomah",
    "lat": 43.9879,
    "lng": -90.4999,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 9303,
    "id": 1840002566
  },
  {
    "city": "New Richmond",
    "city_ascii": "New Richmond",
    "lat": 45.1249,
    "lng": -92.5377,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 9302,
    "id": 1840002138
  },
  {
    "city": "Oakbrook",
    "city_ascii": "Oakbrook",
    "lat": 38.9996,
    "lng": -84.6797,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kentucky",
    "capital": "",
    "population": 9301,
    "id": 1840030814
  },
  {
    "city": "Manchester",
    "city_ascii": "Manchester",
    "lat": 42.9921,
    "lng": -77.1897,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 9300,
    "id": 1840004410
  },
  {
    "city": "Zimmerman",
    "city_ascii": "Zimmerman",
    "lat": 45.4416,
    "lng": -93.5981,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 9300,
    "id": 1840009993
  },
  {
    "city": "Hemau",
    "city_ascii": "Hemau",
    "lat": 49.0519,
    "lng": 11.7828,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 9224,
    "id": 1276739454
  },
  {
    "city": "Zörbig",
    "city_ascii": "Zorbig",
    "lat": 51.6167,
    "lng": 12.1167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony-Anhalt",
    "capital": "",
    "population": 9216,
    "id": 1276066607
  },
  {
    "city": "Wesley Chapel",
    "city_ascii": "Wesley Chapel",
    "lat": 34.9985,
    "lng": -80.6905,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 9295,
    "id": 1840017881
  },
  {
    "city": "Claremont",
    "city_ascii": "Claremont",
    "lat": 43.379,
    "lng": -72.3368,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Hampshire",
    "capital": "",
    "population": 9294,
    "id": 1840002805
  },
  {
    "city": "Gloucester Point",
    "city_ascii": "Gloucester Point",
    "lat": 37.2767,
    "lng": -76.5043,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 9294,
    "id": 1840006399
  },
  {
    "city": "Parsons",
    "city_ascii": "Parsons",
    "lat": 37.3405,
    "lng": -95.2959,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kansas",
    "capital": "",
    "population": 9294,
    "id": 1840001712
  },
  {
    "city": "Bohemia",
    "city_ascii": "Bohemia",
    "lat": 40.7717,
    "lng": -73.1271,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 9293,
    "id": 1840005020
  },
  {
    "city": "Malahide",
    "city_ascii": "Malahide",
    "lat": 42.7928,
    "lng": -80.9361,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 9292,
    "id": 1124001777
  },
  {
    "city": "Torihama",
    "city_ascii": "Torihama",
    "lat": 35.6006,
    "lng": 135.9406,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Fukui",
    "capital": "",
    "population": 9230,
    "id": 1392000087
  },
  {
    "city": "Shijak",
    "city_ascii": "Shijak",
    "lat": 41.3456,
    "lng": 19.5672,
    "country": "Albania",
    "iso2": "AL",
    "iso3": "ALB",
    "admin_name": "Durrës",
    "capital": "",
    "population": 7568,
    "id": 1008348826
  },
  {
    "city": "Didymóteicho",
    "city_ascii": "Didymoteicho",
    "lat": 41.35,
    "lng": 26.5,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Anatolikí Makedonía kai Thráki",
    "capital": "minor",
    "population": 9263,
    "id": 1300999441
  },
  {
    "city": "Fultondale",
    "city_ascii": "Fultondale",
    "lat": 33.6177,
    "lng": -86.8015,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 9284,
    "id": 1840002481
  },
  {
    "city": "North College Hill",
    "city_ascii": "North College Hill",
    "lat": 39.2174,
    "lng": -84.552,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 9283,
    "id": 1840001609
  },
  {
    "city": "Boiling Springs",
    "city_ascii": "Boiling Springs",
    "lat": 35.045,
    "lng": -81.9779,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 9282,
    "id": 1840013509
  },
  {
    "city": "Herculândia",
    "city_ascii": "Herculandia",
    "lat": -22.0036,
    "lng": -50.3853,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 9278,
    "id": 1076531050
  },
  {
    "city": "Reserve",
    "city_ascii": "Reserve",
    "lat": 30.0741,
    "lng": -90.5557,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 9280,
    "id": 1840013969
  },
  {
    "city": "Westwood",
    "city_ascii": "Westwood",
    "lat": 42.303,
    "lng": -85.6286,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 9280,
    "id": 1840006978
  },
  {
    "city": "Torgelow",
    "city_ascii": "Torgelow",
    "lat": 53.6167,
    "lng": 14,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Mecklenburg-Western Pomerania",
    "capital": "",
    "population": 9153,
    "id": 1276888250
  },
  {
    "city": "Cape Elizabeth",
    "city_ascii": "Cape Elizabeth",
    "lat": 43.5891,
    "lng": -70.238,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maine",
    "capital": "",
    "population": 9279,
    "id": 1840052639
  },
  {
    "city": "Pozo Almonte",
    "city_ascii": "Pozo Almonte",
    "lat": -20.2597,
    "lng": -69.7862,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Tarapacá",
    "capital": "minor",
    "population": 9277,
    "id": 1152924094
  },
  {
    "city": "Neshannock",
    "city_ascii": "Neshannock",
    "lat": 41.051,
    "lng": -80.352,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 9278,
    "id": 1840152742
  },
  {
    "city": "Westerland",
    "city_ascii": "Westerland",
    "lat": 54.91,
    "lng": 8.3075,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Schleswig-Holstein",
    "capital": "",
    "population": 9032,
    "id": 1276492039
  },
  {
    "city": "Aljustrel",
    "city_ascii": "Aljustrel",
    "lat": 37.8785,
    "lng": -8.1614,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Beja",
    "capital": "minor",
    "population": 9257,
    "id": 1620079386
  },
  {
    "city": "Castelões de Cepeda",
    "city_ascii": "Casteloes de Cepeda",
    "lat": 41.2008,
    "lng": -8.3306,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Porto",
    "capital": "",
    "population": 7299,
    "id": 1620020628
  },
  {
    "city": "Horodenka",
    "city_ascii": "Horodenka",
    "lat": 48.6675,
    "lng": 25.5003,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Ivano-Frankivs’ka Oblast’",
    "capital": "minor",
    "population": 9113,
    "id": 1804663549
  },
  {
    "city": "Moore",
    "city_ascii": "Moore",
    "lat": 40.7798,
    "lng": -75.422,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 9275,
    "id": 1840142184
  },
  {
    "city": "Tecklenburg",
    "city_ascii": "Tecklenburg",
    "lat": 52.2194,
    "lng": 7.8125,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 9145,
    "id": 1276770196
  },
  {
    "city": "Lo Miranda",
    "city_ascii": "Lo Miranda",
    "lat": -34.1957,
    "lng": -70.8891,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Libertador General Bernardo O’Higgins",
    "capital": "",
    "population": 9000,
    "id": 1152468313
  },
  {
    "city": "Forestdale",
    "city_ascii": "Forestdale",
    "lat": 33.5737,
    "lng": -86.9002,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 9273,
    "id": 1840013723
  },
  {
    "city": "Santa María del Tule",
    "city_ascii": "Santa Maria del Tule",
    "lat": 17.0465,
    "lng": -96.6363,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Oaxaca",
    "capital": "minor",
    "population": 8918,
    "id": 1484488353
  },
  {
    "city": "Marathon",
    "city_ascii": "Marathon",
    "lat": 24.7262,
    "lng": -81.0376,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 9271,
    "id": 1840016010
  },
  {
    "city": "Mendig",
    "city_ascii": "Mendig",
    "lat": 50.3744,
    "lng": 7.2808,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Rhineland-Palatinate",
    "capital": "",
    "population": 8895,
    "id": 1276853202
  },
  {
    "city": "Valky",
    "city_ascii": "Valky",
    "lat": 49.8386,
    "lng": 35.6217,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Kharkivs’ka Oblast’",
    "capital": "minor",
    "population": 9235,
    "id": 1804819263
  },
  {
    "city": "Salto Grande",
    "city_ascii": "Salto Grande",
    "lat": -22.8928,
    "lng": -49.9858,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 9223,
    "id": 1076969780
  },
  {
    "city": "Hastings",
    "city_ascii": "Hastings",
    "lat": 43.3215,
    "lng": -76.1582,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 9265,
    "id": 1840058174
  },
  {
    "city": "Lexington",
    "city_ascii": "Lexington",
    "lat": 37.7825,
    "lng": -79.444,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 9264,
    "id": 1840001689
  },
  {
    "city": "Dumont",
    "city_ascii": "Dumont",
    "lat": -21.2364,
    "lng": -47.9733,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 9178,
    "id": 1076221690
  },
  {
    "city": "Hawkinge",
    "city_ascii": "Hawkinge",
    "lat": 51.117,
    "lng": 1.1638,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Kent",
    "capital": "",
    "population": 8002,
    "id": 1826871537
  },
  {
    "city": "Hickam Housing",
    "city_ascii": "Hickam Housing",
    "lat": 21.3311,
    "lng": -157.9474,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Hawaii",
    "capital": "",
    "population": 9258,
    "id": 1840075009
  },
  {
    "city": "Pokrovsk",
    "city_ascii": "Pokrovsk",
    "lat": 61.4833,
    "lng": 129.15,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sakha (Yakutiya)",
    "capital": "",
    "population": 9256,
    "id": 1643183370
  },
  {
    "city": "Alderwood Manor",
    "city_ascii": "Alderwood Manor",
    "lat": 47.8147,
    "lng": -122.2672,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 9257,
    "id": 1840017323
  },
  {
    "city": "Nizhniye Sergi",
    "city_ascii": "Nizhniye Sergi",
    "lat": 56.6667,
    "lng": 59.3,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sverdlovskaya Oblast’",
    "capital": "",
    "population": 9243,
    "id": 1643721871
  },
  {
    "city": "Bad Liebenwerda",
    "city_ascii": "Bad Liebenwerda",
    "lat": 51.5167,
    "lng": 13.4,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Brandenburg",
    "capital": "",
    "population": 9188,
    "id": 1276044120
  },
  {
    "city": "St. Stephens",
    "city_ascii": "St. Stephens",
    "lat": 35.7641,
    "lng": -81.2746,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 9254,
    "id": 1840033023
  },
  {
    "city": "Currie",
    "city_ascii": "Currie",
    "lat": 55.896,
    "lng": -3.3074,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Edinburgh, City of",
    "capital": "",
    "population": 7494,
    "id": 1826768930
  },
  {
    "city": "Kalanchak",
    "city_ascii": "Kalanchak",
    "lat": 46.255,
    "lng": 33.2906,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Khersons’ka Oblast’",
    "capital": "minor",
    "population": 9224,
    "id": 1804573757
  },
  {
    "city": "Melgaço",
    "city_ascii": "Melgaco",
    "lat": 42.1167,
    "lng": -8.2667,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Viana do Castelo",
    "capital": "minor",
    "population": 9213,
    "id": 1620521790
  },
  {
    "city": "Freetown",
    "city_ascii": "Freetown",
    "lat": 41.7714,
    "lng": -71.0157,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 9251,
    "id": 1840070385
  },
  {
    "city": "Harahan",
    "city_ascii": "Harahan",
    "lat": 29.9374,
    "lng": -90.203,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 9250,
    "id": 1840013997
  },
  {
    "city": "Pásztó",
    "city_ascii": "Paszto",
    "lat": 47.9194,
    "lng": 19.6978,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Nógrád",
    "capital": "minor",
    "population": 9124,
    "id": 1348953784
  },
  {
    "city": "Sredets",
    "city_ascii": "Sredets",
    "lat": 42.3461,
    "lng": 27.1808,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Burgas",
    "capital": "",
    "population": 9237,
    "id": 1100026609
  },
  {
    "city": "Washington Terrace",
    "city_ascii": "Washington Terrace",
    "lat": 41.1683,
    "lng": -111.9783,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Utah",
    "capital": "",
    "population": 9248,
    "id": 1840021343
  },
  {
    "city": "Commerce",
    "city_ascii": "Commerce",
    "lat": 33.2421,
    "lng": -95.8991,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 9247,
    "id": 1840019394
  },
  {
    "city": "Sultan",
    "city_ascii": "Sultan",
    "lat": 47.871,
    "lng": -121.8043,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 9246,
    "id": 1840021080
  },
  {
    "city": "Blackstone",
    "city_ascii": "Blackstone",
    "lat": 42.0399,
    "lng": -71.5313,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 9246,
    "id": 1840053687
  },
  {
    "city": "Wendover",
    "city_ascii": "Wendover",
    "lat": 51.768,
    "lng": -0.7463,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Buckinghamshire",
    "capital": "",
    "population": 7399,
    "id": 1826061990
  },
  {
    "city": "Muncy",
    "city_ascii": "Muncy",
    "lat": 41.2021,
    "lng": -76.7854,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 9245,
    "id": 1840000633
  },
  {
    "city": "Madeira",
    "city_ascii": "Madeira",
    "lat": 39.1856,
    "lng": -84.3734,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 9245,
    "id": 1840001605
  },
  {
    "city": "Washington Township",
    "city_ascii": "Washington Township",
    "lat": 40.9884,
    "lng": -74.0637,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 9243,
    "id": 1840151551
  },
  {
    "city": "Princes Risborough",
    "city_ascii": "Princes Risborough",
    "lat": 51.724,
    "lng": -0.8344,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Buckinghamshire",
    "capital": "",
    "population": 7978,
    "id": 1826427216
  },
  {
    "city": "Saidia",
    "city_ascii": "Saidia",
    "lat": 35.085,
    "lng": -2.2392,
    "country": "Morocco",
    "iso2": "MA",
    "iso3": "MAR",
    "admin_name": "Oriental",
    "capital": "",
    "population": 8780,
    "id": 1504105811
  },
  {
    "city": "Benner",
    "city_ascii": "Benner",
    "lat": 40.8698,
    "lng": -77.8154,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 9242,
    "id": 1840143700
  },
  {
    "city": "Valldoreix",
    "city_ascii": "Valldoreix",
    "lat": 41.4678,
    "lng": 2.0647,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Catalonia",
    "capital": "",
    "population": 8401,
    "id": 1724617046
  },
  {
    "city": "Anthony",
    "city_ascii": "Anthony",
    "lat": 32.0132,
    "lng": -106.5984,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Mexico",
    "capital": "",
    "population": 9239,
    "id": 1840018049
  },
  {
    "city": "Walworth",
    "city_ascii": "Walworth",
    "lat": 43.1633,
    "lng": -77.3132,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 9237,
    "id": 1840058571
  },
  {
    "city": "Gridley",
    "city_ascii": "Gridley",
    "lat": 39.3622,
    "lng": -121.6971,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 9237,
    "id": 1840020181
  },
  {
    "city": "Ziębice",
    "city_ascii": "Ziebice",
    "lat": 50.6,
    "lng": 17.0444,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Dolnośląskie",
    "capital": "",
    "population": 9154,
    "id": 1616161642
  },
  {
    "city": "Veliki Preslav",
    "city_ascii": "Veliki Preslav",
    "lat": 43.1614,
    "lng": 26.8125,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Shumen",
    "capital": "",
    "population": 9196,
    "id": 1100285364
  },
  {
    "city": "Brownfield",
    "city_ascii": "Brownfield",
    "lat": 33.1757,
    "lng": -102.273,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 9234,
    "id": 1840019404
  },
  {
    "city": "Fort Polk South",
    "city_ascii": "Fort Polk South",
    "lat": 31.0512,
    "lng": -93.2159,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 9233,
    "id": 1840073837
  },
  {
    "city": "Mittenwalde",
    "city_ascii": "Mittenwalde",
    "lat": 52.2667,
    "lng": 13.5333,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Brandenburg",
    "capital": "",
    "population": 9140,
    "id": 1276476761
  },
  {
    "city": "Sekimachi",
    "city_ascii": "Sekimachi",
    "lat": 33.0617,
    "lng": 130.5414,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Kumamoto",
    "capital": "",
    "population": 9100,
    "id": 1392125733
  },
  {
    "city": "Dallas",
    "city_ascii": "Dallas",
    "lat": 41.3604,
    "lng": -75.9662,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 9230,
    "id": 1840000745
  },
  {
    "city": "Furth im Wald",
    "city_ascii": "Furth im Wald",
    "lat": 49.3097,
    "lng": 12.84,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 9093,
    "id": 1276212241
  },
  {
    "city": "Kamień Pomorski",
    "city_ascii": "Kamien Pomorski",
    "lat": 53.9697,
    "lng": 14.7858,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Zachodniopomorskie",
    "capital": "minor",
    "population": 9144,
    "id": 1616000112
  },
  {
    "city": "Girard",
    "city_ascii": "Girard",
    "lat": 41.1666,
    "lng": -80.6963,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 9227,
    "id": 1840008239
  },
  {
    "city": "Moss Vale",
    "city_ascii": "Moss Vale",
    "lat": -34.5483,
    "lng": 150.3678,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 8579,
    "id": 1036872626
  },
  {
    "city": "Hillview",
    "city_ascii": "Hillview",
    "lat": 38.0563,
    "lng": -85.6848,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kentucky",
    "capital": "",
    "population": 9225,
    "id": 1840014325
  },
  {
    "city": "Burlington",
    "city_ascii": "Burlington",
    "lat": 48.4676,
    "lng": -122.3298,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 9224,
    "id": 1840018367
  },
  {
    "city": "Almargem",
    "city_ascii": "Almargem",
    "lat": 38.8475,
    "lng": -9.2714,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Lisboa",
    "capital": "",
    "population": 8983,
    "id": 1620407856
  },
  {
    "city": "Oromocto",
    "city_ascii": "Oromocto",
    "lat": 45.8488,
    "lng": -66.4788,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "New Brunswick",
    "capital": "",
    "population": 9223,
    "id": 1124928183
  },
  {
    "city": "La Cruz",
    "city_ascii": "La Cruz",
    "lat": 11.0742,
    "lng": -85.6294,
    "country": "Costa Rica",
    "iso2": "CR",
    "iso3": "CRI",
    "admin_name": "Guanacaste",
    "capital": "",
    "population": 9195,
    "id": 1188840021
  },
  {
    "city": "Steger",
    "city_ascii": "Steger",
    "lat": 41.4723,
    "lng": -87.6176,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 9221,
    "id": 1840011325
  },
  {
    "city": "Marlton",
    "city_ascii": "Marlton",
    "lat": 38.762,
    "lng": -76.7857,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 9216,
    "id": 1840005962
  },
  {
    "city": "Spanish Fort",
    "city_ascii": "Spanish Fort",
    "lat": 30.7257,
    "lng": -87.8601,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 9214,
    "id": 1840015879
  },
  {
    "city": "Krompachy",
    "city_ascii": "Krompachy",
    "lat": 48.9167,
    "lng": 20.8744,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Košický",
    "capital": "",
    "population": 8828,
    "id": 1703996106
  },
  {
    "city": "Olëkminsk",
    "city_ascii": "Olekminsk",
    "lat": 60.3833,
    "lng": 120.4333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sakha (Yakutiya)",
    "capital": "",
    "population": 9213,
    "id": 1643265838
  },
  {
    "city": "Wanaka",
    "city_ascii": "Wanaka",
    "lat": -44.7081,
    "lng": 169.1239,
    "country": "New Zealand",
    "iso2": "NZ",
    "iso3": "NZL",
    "admin_name": "Otago",
    "capital": "",
    "population": 8900,
    "id": 1554152950
  },
  {
    "city": "Bethalto",
    "city_ascii": "Bethalto",
    "lat": 38.9014,
    "lng": -90.0467,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 9210,
    "id": 1840012789
  },
  {
    "city": "Lake Elmo",
    "city_ascii": "Lake Elmo",
    "lat": 44.9944,
    "lng": -92.9031,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 9210,
    "id": 1840007814
  },
  {
    "city": "Salisbury",
    "city_ascii": "Salisbury",
    "lat": 42.8465,
    "lng": -70.8616,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 9209,
    "id": 1840053539
  },
  {
    "city": "Redding",
    "city_ascii": "Redding",
    "lat": 41.3051,
    "lng": -73.3916,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 9209,
    "id": 1840035553
  },
  {
    "city": "Iijima",
    "city_ascii": "Iijima",
    "lat": 35.6764,
    "lng": 137.9194,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Nagano",
    "capital": "",
    "population": 9104,
    "id": 1392103734
  },
  {
    "city": "Midland",
    "city_ascii": "Midland",
    "lat": 47.1734,
    "lng": -122.412,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 9208,
    "id": 1840018452
  },
  {
    "city": "Lake Mohawk",
    "city_ascii": "Lake Mohawk",
    "lat": 41.015,
    "lng": -74.6639,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 9208,
    "id": 1840004955
  },
  {
    "city": "Brewood",
    "city_ascii": "Brewood",
    "lat": 52.6769,
    "lng": -2.1738,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Staffordshire",
    "capital": "",
    "population": 7329,
    "id": 1826958033
  },
  {
    "city": "Cloverdale",
    "city_ascii": "Cloverdale",
    "lat": 38.7961,
    "lng": -123.0151,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 9205,
    "id": 1840018838
  },
  {
    "city": "Furtwangen im Schwarzwald",
    "city_ascii": "Furtwangen im Schwarzwald",
    "lat": 48.0503,
    "lng": 8.2092,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 9091,
    "id": 1276132141
  },
  {
    "city": "Mendon",
    "city_ascii": "Mendon",
    "lat": 42.9859,
    "lng": -77.5479,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 9197,
    "id": 1840058299
  },
  {
    "city": "Abdurahmoni Jomí",
    "city_ascii": "Abdurahmoni Jomi",
    "lat": 37.9794,
    "lng": 68.689,
    "country": "Tajikistan",
    "iso2": "TJ",
    "iso3": "TJK",
    "admin_name": "Khatlon",
    "capital": "minor",
    "population": 8925,
    "id": 1762589864
  },
  {
    "city": "Audubon",
    "city_ascii": "Audubon",
    "lat": 40.1304,
    "lng": -75.428,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 9195,
    "id": 1840034852
  },
  {
    "city": "Matamata",
    "city_ascii": "Matamata",
    "lat": -37.8097,
    "lng": 175.7733,
    "country": "New Zealand",
    "iso2": "NZ",
    "iso3": "NZL",
    "admin_name": "Waikato",
    "capital": "",
    "population": 7910,
    "id": 1554001179
  },
  {
    "city": "Lynwood",
    "city_ascii": "Lynwood",
    "lat": 41.5234,
    "lng": -87.5508,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 9194,
    "id": 1840011291
  },
  {
    "city": "Basalt",
    "city_ascii": "Basalt",
    "lat": 39.3663,
    "lng": -107.0414,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 9193,
    "id": 1840020195
  },
  {
    "city": "Ivins",
    "city_ascii": "Ivins",
    "lat": 37.1742,
    "lng": -113.6809,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Utah",
    "capital": "",
    "population": 9192,
    "id": 1840018937
  },
  {
    "city": "Neuhaus am Rennweg",
    "city_ascii": "Neuhaus am Rennweg",
    "lat": 50.51,
    "lng": 11.1378,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Thuringia",
    "capital": "",
    "population": 9076,
    "id": 1276404975
  },
  {
    "city": "Griesbach",
    "city_ascii": "Griesbach",
    "lat": 48.45,
    "lng": 13.2,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 9055,
    "id": 1276720222
  },
  {
    "city": "Perkiomen",
    "city_ascii": "Perkiomen",
    "lat": 40.2315,
    "lng": -75.4644,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 9186,
    "id": 1840142126
  },
  {
    "city": "Crawford",
    "city_ascii": "Crawford",
    "lat": 41.5685,
    "lng": -74.3168,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 9185,
    "id": 1840058024
  },
  {
    "city": "Elsfleth",
    "city_ascii": "Elsfleth",
    "lat": 53.2333,
    "lng": 8.4667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 9105,
    "id": 1276056093
  },
  {
    "city": "Olds",
    "city_ascii": "Olds",
    "lat": 51.7928,
    "lng": -114.1067,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Alberta",
    "capital": "",
    "population": 9184,
    "id": 1124330412
  },
  {
    "city": "Żychlin",
    "city_ascii": "Zychlin",
    "lat": 52.2453,
    "lng": 19.6236,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Łódzkie",
    "capital": "",
    "population": 9021,
    "id": 1616508648
  },
  {
    "city": "Brimington",
    "city_ascii": "Brimington",
    "lat": 53.258,
    "lng": -1.3905,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Derbyshire",
    "capital": "",
    "population": 8788,
    "id": 1826142035
  },
  {
    "city": "Cairo",
    "city_ascii": "Cairo",
    "lat": 30.8791,
    "lng": -84.205,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 9181,
    "id": 1840013900
  },
  {
    "city": "Coutances",
    "city_ascii": "Coutances",
    "lat": 49.0453,
    "lng": -1.4453,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Normandie",
    "capital": "minor",
    "population": 8501,
    "id": 1250453260
  },
  {
    "city": "Elwood",
    "city_ascii": "Elwood",
    "lat": 40.2744,
    "lng": -85.837,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 9180,
    "id": 1840008364
  },
  {
    "city": "St. Marys",
    "city_ascii": "St. Marys",
    "lat": 40.5475,
    "lng": -84.3931,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 9180,
    "id": 1840009408
  },
  {
    "city": "Waldheim",
    "city_ascii": "Waldheim",
    "lat": 51.0667,
    "lng": 13.0167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 8964,
    "id": 1276028317
  },
  {
    "city": "Horokhiv",
    "city_ascii": "Horokhiv",
    "lat": 50.4994,
    "lng": 24.765,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Volyns’ka Oblast’",
    "capital": "minor",
    "population": 9134,
    "id": 1804715823
  },
  {
    "city": "Salcea",
    "city_ascii": "Salcea",
    "lat": 47.65,
    "lng": 26.37,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Suceava",
    "capital": "",
    "population": 9015,
    "id": 1642120043
  },
  {
    "city": "Neuötting",
    "city_ascii": "Neuotting",
    "lat": 48.2167,
    "lng": 12.6833,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 8932,
    "id": 1276928616
  },
  {
    "city": "Scotchtown",
    "city_ascii": "Scotchtown",
    "lat": 41.4759,
    "lng": -74.3682,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 9174,
    "id": 1840004862
  },
  {
    "city": "Good Hope",
    "city_ascii": "Good Hope",
    "lat": 33.7706,
    "lng": -117.2772,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 9173,
    "id": 1840028330
  },
  {
    "city": "Fair Oaks",
    "city_ascii": "Fair Oaks",
    "lat": 33.9192,
    "lng": -84.5444,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 9173,
    "id": 1840013091
  },
  {
    "city": "Stansbury Park",
    "city_ascii": "Stansbury Park",
    "lat": 40.6356,
    "lng": -112.3054,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Utah",
    "capital": "",
    "population": 9173,
    "id": 1840037176
  },
  {
    "city": "Sullivan",
    "city_ascii": "Sullivan",
    "lat": 38.2129,
    "lng": -91.1636,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 9172,
    "id": 1840009643
  },
  {
    "city": "Rensselaer",
    "city_ascii": "Rensselaer",
    "lat": 42.6465,
    "lng": -73.7328,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 9171,
    "id": 1840000394
  },
  {
    "city": "Clifton Springs",
    "city_ascii": "Clifton Springs",
    "lat": -38.15,
    "lng": 144.5667,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Victoria",
    "capital": "",
    "population": 7519,
    "id": 1036319529
  },
  {
    "city": "Pryor Creek",
    "city_ascii": "Pryor Creek",
    "lat": 36.2998,
    "lng": -95.3102,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oklahoma",
    "capital": "",
    "population": 9169,
    "id": 1840037410
  },
  {
    "city": "Cold Springs",
    "city_ascii": "Cold Springs",
    "lat": 39.6927,
    "lng": -119.9775,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Nevada",
    "capital": "",
    "population": 9168,
    "id": 1840033824
  },
  {
    "city": "Ortenberg",
    "city_ascii": "Ortenberg",
    "lat": 50.3558,
    "lng": 9.0553,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 9001,
    "id": 1276546212
  },
  {
    "city": "Hallstadt",
    "city_ascii": "Hallstadt",
    "lat": 49.9333,
    "lng": 10.8833,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 8575,
    "id": 1276896189
  },
  {
    "city": "Bingham",
    "city_ascii": "Bingham",
    "lat": 52.952,
    "lng": -0.953,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Nottinghamshire",
    "capital": "",
    "population": 9131,
    "id": 1826632789
  },
  {
    "city": "Calimesa",
    "city_ascii": "Calimesa",
    "lat": 33.9874,
    "lng": -117.0542,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 9160,
    "id": 1840019300
  },
  {
    "city": "Modra",
    "city_ascii": "Modra",
    "lat": 48.3331,
    "lng": 17.3069,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Bratislavský",
    "capital": "",
    "population": 8976,
    "id": 1703770416
  },
  {
    "city": "Plön",
    "city_ascii": "Plon",
    "lat": 54.1622,
    "lng": 10.4214,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Schleswig-Holstein",
    "capital": "minor",
    "population": 8914,
    "id": 1276527623
  },
  {
    "city": "Dunn Loring",
    "city_ascii": "Dunn Loring",
    "lat": 38.8945,
    "lng": -77.2316,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 9155,
    "id": 1840006017
  },
  {
    "city": "Flossmoor",
    "city_ascii": "Flossmoor",
    "lat": 41.5391,
    "lng": -87.6857,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 9155,
    "id": 1840011274
  },
  {
    "city": "Plymouth",
    "city_ascii": "Plymouth",
    "lat": 42.3718,
    "lng": -83.468,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 9154,
    "id": 1840003959
  },
  {
    "city": "Shaftesbury",
    "city_ascii": "Shaftesbury",
    "lat": 51.0059,
    "lng": -2.1969,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Dorset",
    "capital": "",
    "population": 7314,
    "id": 1826486861
  },
  {
    "city": "Hooper",
    "city_ascii": "Hooper",
    "lat": 41.1599,
    "lng": -112.2871,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Utah",
    "capital": "",
    "population": 9152,
    "id": 1840020130
  },
  {
    "city": "Minyar",
    "city_ascii": "Minyar",
    "lat": 55.0667,
    "lng": 57.55,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Chelyabinskaya Oblast’",
    "capital": "",
    "population": 9128,
    "id": 1643182106
  },
  {
    "city": "Bethel Township",
    "city_ascii": "Bethel Township",
    "lat": 39.8458,
    "lng": -75.4891,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 9150,
    "id": 1840150552
  },
  {
    "city": "Thief River Falls",
    "city_ascii": "Thief River Falls",
    "lat": 48.1112,
    "lng": -96.1771,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 9147,
    "id": 1840008825
  },
  {
    "city": "Devnya",
    "city_ascii": "Devnya",
    "lat": 43.2189,
    "lng": 27.5603,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Varna",
    "capital": "",
    "population": 9036,
    "id": 1100328780
  },
  {
    "city": "Katoomba",
    "city_ascii": "Katoomba",
    "lat": -33.715,
    "lng": 150.3128,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 7964,
    "id": 1036674004
  },
  {
    "city": "Ystradgynlais",
    "city_ascii": "Ystradgynlais",
    "lat": 51.781,
    "lng": -3.7511,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Powys",
    "capital": "",
    "population": 8092,
    "id": 1826512910
  },
  {
    "city": "Terra Roxa",
    "city_ascii": "Terra Roxa",
    "lat": -20.7889,
    "lng": -48.33,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 9101,
    "id": 1076064545
  },
  {
    "city": "Chadan",
    "city_ascii": "Chadan",
    "lat": 51.2833,
    "lng": 91.5667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Tyva",
    "capital": "",
    "population": 9139,
    "id": 1643075116
  },
  {
    "city": "Whitnash",
    "city_ascii": "Whitnash",
    "lat": 52.268,
    "lng": -1.524,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Warwickshire",
    "capital": "",
    "population": 9129,
    "id": 1826439036
  },
  {
    "city": "Fairview",
    "city_ascii": "Fairview",
    "lat": 33.1399,
    "lng": -96.6116,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 9141,
    "id": 1840022021
  },
  {
    "city": "Hirayama",
    "city_ascii": "Hirayama",
    "lat": 33.6467,
    "lng": 130.5,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Fukuoka",
    "capital": "",
    "population": 8902,
    "id": 1392375726
  },
  {
    "city": "Seini",
    "city_ascii": "Seini",
    "lat": 47.7478,
    "lng": 23.2853,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Maramureş",
    "capital": "",
    "population": 8987,
    "id": 1642934911
  },
  {
    "city": "Huron East",
    "city_ascii": "Huron East",
    "lat": 43.63,
    "lng": -81.28,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 9138,
    "id": 1124000724
  },
  {
    "city": "Holualoa",
    "city_ascii": "Holualoa",
    "lat": 19.6238,
    "lng": -155.9269,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Hawaii",
    "capital": "",
    "population": 9136,
    "id": 1840029482
  },
  {
    "city": "Tulbagh",
    "city_ascii": "Tulbagh",
    "lat": -33.285,
    "lng": 19.1378,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "Western Cape",
    "capital": "",
    "population": 8969,
    "id": 1710401390
  },
  {
    "city": "Borgholzhausen",
    "city_ascii": "Borgholzhausen",
    "lat": 52.1,
    "lng": 8.3,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 8973,
    "id": 1276557759
  },
  {
    "city": "Cockermouth",
    "city_ascii": "Cockermouth",
    "lat": 54.6613,
    "lng": -3.362,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Cumbria",
    "capital": "",
    "population": 8761,
    "id": 1826229039
  },
  {
    "city": "Union",
    "city_ascii": "Union",
    "lat": 34.7235,
    "lng": -81.6248,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 9132,
    "id": 1840015533
  },
  {
    "city": "Beecher",
    "city_ascii": "Beecher",
    "lat": 43.0903,
    "lng": -83.7039,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 9128,
    "id": 1840004343
  },
  {
    "city": "Dobříš",
    "city_ascii": "Dobris",
    "lat": 49.7812,
    "lng": 14.1672,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Středočeský Kraj",
    "capital": "",
    "population": 8960,
    "id": 1203853361
  },
  {
    "city": "Challapata",
    "city_ascii": "Challapata",
    "lat": -18.9,
    "lng": -66.7667,
    "country": "Bolivia",
    "iso2": "BO",
    "iso3": "BOL",
    "admin_name": "Oruro",
    "capital": "",
    "population": 9114,
    "id": 1068352309
  },
  {
    "city": "Elze",
    "city_ascii": "Elze",
    "lat": 52.1167,
    "lng": 9.7333,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 8939,
    "id": 1276949287
  },
  {
    "city": "Freystadt",
    "city_ascii": "Freystadt",
    "lat": 49.1989,
    "lng": 11.3292,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 9013,
    "id": 1276684749
  },
  {
    "city": "Doberlug-Kirchhain",
    "city_ascii": "Doberlug-Kirchhain",
    "lat": 51.6167,
    "lng": 13.5667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Brandenburg",
    "capital": "",
    "population": 9062,
    "id": 1276784712
  },
  {
    "city": "Tarusa",
    "city_ascii": "Tarusa",
    "lat": 54.7167,
    "lng": 37.1833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kaluzhskaya Oblast’",
    "capital": "",
    "population": 9101,
    "id": 1643162455
  },
  {
    "city": "Bolokhovo",
    "city_ascii": "Bolokhovo",
    "lat": 54.0833,
    "lng": 37.8167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Tul’skaya Oblast’",
    "capital": "",
    "population": 9043,
    "id": 1643953673
  },
  {
    "city": "Duchcov",
    "city_ascii": "Duchcov",
    "lat": 50.6039,
    "lng": 13.7463,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Ústecký Kraj",
    "capital": "",
    "population": 8564,
    "id": 1203260902
  },
  {
    "city": "Ōtaki",
    "city_ascii": "Otaki",
    "lat": 35.285,
    "lng": 140.2456,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Chiba",
    "capital": "",
    "population": 9049,
    "id": 1392151200
  },
  {
    "city": "Soeda",
    "city_ascii": "Soeda",
    "lat": 33.5717,
    "lng": 130.8542,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Fukuoka",
    "capital": "",
    "population": 9049,
    "id": 1392649831
  },
  {
    "city": "Woodmoor",
    "city_ascii": "Woodmoor",
    "lat": 39.1063,
    "lng": -104.8456,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 9117,
    "id": 1840028584
  },
  {
    "city": "Jersey Shore",
    "city_ascii": "Jersey Shore",
    "lat": 41.2012,
    "lng": -77.2666,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 9116,
    "id": 1840000630
  },
  {
    "city": "Triangle",
    "city_ascii": "Triangle",
    "lat": 38.5483,
    "lng": -77.3195,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 9116,
    "id": 1840006105
  },
  {
    "city": "Beckett Ridge",
    "city_ascii": "Beckett Ridge",
    "lat": 39.3448,
    "lng": -84.4381,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 9114,
    "id": 1840034377
  },
  {
    "city": "Grosse Pointe Farms",
    "city_ascii": "Grosse Pointe Farms",
    "lat": 42.4067,
    "lng": -82.8992,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 9114,
    "id": 1840003977
  },
  {
    "city": "Tyachiv",
    "city_ascii": "Tyachiv",
    "lat": 48.0114,
    "lng": 23.5722,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Zakarpats’ka Oblast’",
    "capital": "minor",
    "population": 9019,
    "id": 1804591278
  },
  {
    "city": "Ménaka",
    "city_ascii": "Menaka",
    "lat": 15.9167,
    "lng": 2.4,
    "country": "Mali",
    "iso2": "ML",
    "iso3": "MLI",
    "admin_name": "Gao",
    "capital": "minor",
    "population": 9110,
    "id": 1466373205
  },
  {
    "city": "Nittenau",
    "city_ascii": "Nittenau",
    "lat": 49.1964,
    "lng": 12.2686,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 9019,
    "id": 1276358281
  },
  {
    "city": "Taragi",
    "city_ascii": "Taragi",
    "lat": 32.2642,
    "lng": 130.9358,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Kumamoto",
    "capital": "",
    "population": 9055,
    "id": 1392044742
  },
  {
    "city": "Stará Turá",
    "city_ascii": "Stara Tura",
    "lat": 48.7772,
    "lng": 17.6956,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Nitriansky",
    "capital": "",
    "population": 8932,
    "id": 1703420460
  },
  {
    "city": "Somero",
    "city_ascii": "Somero",
    "lat": 60.6292,
    "lng": 23.5139,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Varsinais-Suomi",
    "capital": "minor",
    "population": 9093,
    "id": 1246015002
  },
  {
    "city": "Whitwick",
    "city_ascii": "Whitwick",
    "lat": 52.7403,
    "lng": -1.3556,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Leicestershire",
    "capital": "",
    "population": 8612,
    "id": 1826669578
  },
  {
    "city": "Mnichovo Hradiště",
    "city_ascii": "Mnichovo Hradiste",
    "lat": 50.5273,
    "lng": 14.9714,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Středočeský Kraj",
    "capital": "",
    "population": 8846,
    "id": 1203949277
  },
  {
    "city": "Kharovsk",
    "city_ascii": "Kharovsk",
    "lat": 59.9642,
    "lng": 40.1912,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Vologodskaya Oblast’",
    "capital": "",
    "population": 9099,
    "id": 1643046840
  },
  {
    "city": "Waldenbuch",
    "city_ascii": "Waldenbuch",
    "lat": 48.6372,
    "lng": 9.1317,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 8717,
    "id": 1276938935
  },
  {
    "city": "Economy",
    "city_ascii": "Economy",
    "lat": 40.641,
    "lng": -80.184,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 9098,
    "id": 1840066074
  },
  {
    "city": "Villa Morelos",
    "city_ascii": "Villa Morelos",
    "lat": 20.0033,
    "lng": -101.4144,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "México",
    "capital": "",
    "population": 7806,
    "id": 1484528995
  },
  {
    "city": "Plymouth",
    "city_ascii": "Plymouth",
    "lat": 43.7447,
    "lng": -87.9657,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 9097,
    "id": 1840002626
  },
  {
    "city": "Berthoud",
    "city_ascii": "Berthoud",
    "lat": 40.307,
    "lng": -105.0419,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 9094,
    "id": 1840020153
  },
  {
    "city": "Epanomí",
    "city_ascii": "Epanomi",
    "lat": 40.4261,
    "lng": 22.9281,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Kentrikí Makedonía",
    "capital": "",
    "population": 8979,
    "id": 1300802037
  },
  {
    "city": "Wakefield-Peacedale",
    "city_ascii": "Wakefield-Peacedale",
    "lat": 41.4461,
    "lng": -71.5004,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Rhode Island",
    "capital": "",
    "population": 9092,
    "id": 1840073472
  },
  {
    "city": "Whitestown",
    "city_ascii": "Whitestown",
    "lat": 39.9705,
    "lng": -86.3612,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 9092,
    "id": 1840010514
  },
  {
    "city": "Portlethen",
    "city_ascii": "Portlethen",
    "lat": 57.061,
    "lng": -2.13,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Aberdeenshire",
    "capital": "",
    "population": 9090,
    "id": 1826375407
  },
  {
    "city": "Breckerfeld",
    "city_ascii": "Breckerfeld",
    "lat": 51.2611,
    "lng": 7.4667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 8938,
    "id": 1276888240
  },
  {
    "city": "San Elizario",
    "city_ascii": "San Elizario",
    "lat": 31.5793,
    "lng": -106.2632,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 9089,
    "id": 1840019523
  },
  {
    "city": "Dillon",
    "city_ascii": "Dillon",
    "lat": 34.4233,
    "lng": -79.3689,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 9089,
    "id": 1840014676
  },
  {
    "city": "Plainville",
    "city_ascii": "Plainville",
    "lat": 42.0141,
    "lng": -71.3364,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 9088,
    "id": 1840053555
  },
  {
    "city": "Herzberg",
    "city_ascii": "Herzberg",
    "lat": 51.6831,
    "lng": 13.2331,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Brandenburg",
    "capital": "minor",
    "population": 9027,
    "id": 1276357277
  },
  {
    "city": "Siklós",
    "city_ascii": "Siklos",
    "lat": 45.8519,
    "lng": 18.2986,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Baranya",
    "capital": "minor",
    "population": 8912,
    "id": 1348986521
  },
  {
    "city": "Livno",
    "city_ascii": "Livno",
    "lat": 43.8269,
    "lng": 17.0081,
    "country": "Bosnia And Herzegovina",
    "iso2": "BA",
    "iso3": "BIH",
    "admin_name": "Bosnia and Herzegovina, Federation of",
    "capital": "minor",
    "population": 9045,
    "id": 1070523528
  },
  {
    "city": "Notodden",
    "city_ascii": "Notodden",
    "lat": 59.5594,
    "lng": 9.2585,
    "country": "Norway",
    "iso2": "NO",
    "iso3": "NOR",
    "admin_name": "Telemark",
    "capital": "minor",
    "population": 9072,
    "id": 1578892380
  },
  {
    "city": "Fort Irwin",
    "city_ascii": "Fort Irwin",
    "lat": 35.2476,
    "lng": -116.6834,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 9086,
    "id": 1840025802
  },
  {
    "city": "Tiburon",
    "city_ascii": "Tiburon",
    "lat": 37.8854,
    "lng": -122.4637,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 9084,
    "id": 1840022521
  },
  {
    "city": "Monmouth",
    "city_ascii": "Monmouth",
    "lat": 40.9141,
    "lng": -90.6423,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 9084,
    "id": 1840008283
  },
  {
    "city": "Highland Park",
    "city_ascii": "Highland Park",
    "lat": 32.8311,
    "lng": -96.8012,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 9083,
    "id": 1840022058
  },
  {
    "city": "West Caln",
    "city_ascii": "West Caln",
    "lat": 40.0237,
    "lng": -75.8866,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 9080,
    "id": 1840145648
  },
  {
    "city": "Polička",
    "city_ascii": "Policka",
    "lat": 49.7147,
    "lng": 16.2655,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Pardubický Kraj",
    "capital": "",
    "population": 8813,
    "id": 1203964642
  },
  {
    "city": "Vinhais",
    "city_ascii": "Vinhais",
    "lat": 41.8167,
    "lng": -7,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Bragança",
    "capital": "minor",
    "population": 9066,
    "id": 1620473528
  },
  {
    "city": "Verkhnyaya Tura",
    "city_ascii": "Verkhnyaya Tura",
    "lat": 58.3574,
    "lng": 59.8224,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sverdlovskaya Oblast’",
    "capital": "",
    "population": 9039,
    "id": 1643668753
  },
  {
    "city": "Te Puke",
    "city_ascii": "Te Puke",
    "lat": -37.7833,
    "lng": 176.3167,
    "country": "New Zealand",
    "iso2": "NZ",
    "iso3": "NZL",
    "admin_name": "Bay of Plenty",
    "capital": "",
    "population": 8230,
    "id": 1554000416
  },
  {
    "city": "Kuvshinovo",
    "city_ascii": "Kuvshinovo",
    "lat": 57.0333,
    "lng": 34.1667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Tverskaya Oblast’",
    "capital": "",
    "population": 9068,
    "id": 1643901783
  },
  {
    "city": "Chrysoúpoli",
    "city_ascii": "Chrysoupoli",
    "lat": 40.9833,
    "lng": 24.7,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Anatolikí Makedonía kai Thráki",
    "capital": "minor",
    "population": 8885,
    "id": 1300208248
  },
  {
    "city": "Jeannette",
    "city_ascii": "Jeannette",
    "lat": 40.3277,
    "lng": -79.6139,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 9074,
    "id": 1840001141
  },
  {
    "city": "Rafard",
    "city_ascii": "Rafard",
    "lat": -23.0117,
    "lng": -47.5269,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 9004,
    "id": 1076001121
  },
  {
    "city": "Werneuchen",
    "city_ascii": "Werneuchen",
    "lat": 52.6331,
    "lng": 13.7331,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Brandenburg",
    "capital": "",
    "population": 8994,
    "id": 1276171183
  },
  {
    "city": "Guerrero",
    "city_ascii": "Guerrero",
    "lat": 28.5478,
    "lng": -107.4856,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Chihuahua",
    "capital": "minor",
    "population": 7751,
    "id": 1484042029
  },
  {
    "city": "Armadale",
    "city_ascii": "Armadale",
    "lat": 55.8978,
    "lng": -3.7047,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "West Lothian",
    "capital": "",
    "population": 9063,
    "id": 1826278494
  },
  {
    "city": "Windermere",
    "city_ascii": "Windermere",
    "lat": 54.376,
    "lng": -2.907,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Cumbria",
    "capital": "",
    "population": 8359,
    "id": 1826181785
  },
  {
    "city": "Sutherlin",
    "city_ascii": "Sutherlin",
    "lat": 43.3884,
    "lng": -123.3231,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 9067,
    "id": 1840021274
  },
  {
    "city": "Bolnisi",
    "city_ascii": "Bolnisi",
    "lat": 41.45,
    "lng": 44.5333,
    "country": "Georgia",
    "iso2": "GE",
    "iso3": "GEO",
    "admin_name": "Kvemo Kartli",
    "capital": "minor",
    "population": 8967,
    "id": 1268256910
  },
  {
    "city": "Khoni",
    "city_ascii": "Khoni",
    "lat": 42.3167,
    "lng": 42.4333,
    "country": "Georgia",
    "iso2": "GE",
    "iso3": "GEO",
    "admin_name": "Imereti",
    "capital": "",
    "population": 8987,
    "id": 1268451521
  },
  {
    "city": "Wollert",
    "city_ascii": "Wollert",
    "lat": -37.597,
    "lng": 145.033,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Victoria",
    "capital": "",
    "population": 9060,
    "id": 1036068932
  },
  {
    "city": "Obernburg am Main",
    "city_ascii": "Obernburg am Main",
    "lat": 49.84,
    "lng": 9.1414,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 8712,
    "id": 1276112630
  },
  {
    "city": "Greenwood",
    "city_ascii": "Greenwood",
    "lat": 35.2134,
    "lng": -94.2408,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arkansas",
    "capital": "",
    "population": 9063,
    "id": 1840013457
  },
  {
    "city": "Port Townsend",
    "city_ascii": "Port Townsend",
    "lat": 48.122,
    "lng": -122.7872,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 9062,
    "id": 1840019797
  },
  {
    "city": "Seaside",
    "city_ascii": "Seaside",
    "lat": 45.9889,
    "lng": -123.9214,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 9060,
    "id": 1840021182
  },
  {
    "city": "Valley Center",
    "city_ascii": "Valley Center",
    "lat": 33.233,
    "lng": -117.0157,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 9059,
    "id": 1840019349
  },
  {
    "city": "Kelso",
    "city_ascii": "Kelso",
    "lat": -33.4186,
    "lng": 149.6056,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 8968,
    "id": 1036270756
  },
  {
    "city": "Brownsville",
    "city_ascii": "Brownsville",
    "lat": 35.589,
    "lng": -89.2578,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 9055,
    "id": 1840013413
  },
  {
    "city": "Merrimac",
    "city_ascii": "Merrimac",
    "lat": -28.05,
    "lng": 153.3667,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 7071,
    "id": 1036000674
  },
  {
    "city": "Grantsville",
    "city_ascii": "Grantsville",
    "lat": 40.6148,
    "lng": -112.4777,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Utah",
    "capital": "",
    "population": 9052,
    "id": 1840020146
  },
  {
    "city": "Kings Grant",
    "city_ascii": "Kings Grant",
    "lat": 34.2665,
    "lng": -77.8659,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 9051,
    "id": 1840032902
  },
  {
    "city": "Livingston",
    "city_ascii": "Livingston",
    "lat": 45.6666,
    "lng": -110.5538,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Montana",
    "capital": "",
    "population": 9050,
    "id": 1840019906
  },
  {
    "city": "Neves Paulista",
    "city_ascii": "Neves Paulista",
    "lat": -20.8458,
    "lng": -49.63,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 9008,
    "id": 1076295222
  },
  {
    "city": "Springfield",
    "city_ascii": "Springfield",
    "lat": 43.2906,
    "lng": -72.4809,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Vermont",
    "capital": "",
    "population": 9049,
    "id": 1840071330
  },
  {
    "city": "Cherry Hinton",
    "city_ascii": "Cherry Hinton",
    "lat": 52.1849,
    "lng": 0.176,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Cambridgeshire",
    "capital": "",
    "population": 8780,
    "id": 1826045496
  },
  {
    "city": "Choceň",
    "city_ascii": "Chocen",
    "lat": 50.0016,
    "lng": 16.2231,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Pardubický Kraj",
    "capital": "",
    "population": 8648,
    "id": 1203284714
  },
  {
    "city": "Hornsea",
    "city_ascii": "Hornsea",
    "lat": 53.9108,
    "lng": -0.1676,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "East Riding of Yorkshire",
    "capital": "",
    "population": 8432,
    "id": 1826612208
  },
  {
    "city": "Alexander City",
    "city_ascii": "Alexander City",
    "lat": 32.9242,
    "lng": -85.9361,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 9046,
    "id": 1840013788
  },
  {
    "city": "Ballenstedt",
    "city_ascii": "Ballenstedt",
    "lat": 51.72,
    "lng": 11.2375,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony-Anhalt",
    "capital": "",
    "population": 8940,
    "id": 1276375352
  },
  {
    "city": "Westphalia",
    "city_ascii": "Westphalia",
    "lat": 38.8385,
    "lng": -76.8231,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 9040,
    "id": 1840024549
  },
  {
    "city": "Rakitovo",
    "city_ascii": "Rakitovo",
    "lat": 41.99,
    "lng": 24.0872,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Pazardzhik",
    "capital": "minor",
    "population": 8977,
    "id": 1100370778
  },
  {
    "city": "Ayr",
    "city_ascii": "Ayr",
    "lat": -19.5744,
    "lng": 147.4066,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Queensland",
    "capital": "",
    "population": 8738,
    "id": 1036228772
  },
  {
    "city": "Newmarket",
    "city_ascii": "Newmarket",
    "lat": 43.0691,
    "lng": -70.9516,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Hampshire",
    "capital": "",
    "population": 9038,
    "id": 1840055265
  },
  {
    "city": "Stanley",
    "city_ascii": "Stanley",
    "lat": 49.1331,
    "lng": -98.0656,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Manitoba",
    "capital": "",
    "population": 9038,
    "id": 1124001503
  },
  {
    "city": "Birch Bay",
    "city_ascii": "Birch Bay",
    "lat": 48.923,
    "lng": -122.7543,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 9037,
    "id": 1840017289
  },
  {
    "city": "Dumbarton",
    "city_ascii": "Dumbarton",
    "lat": 37.613,
    "lng": -77.5065,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 9036,
    "id": 1840006387
  },
  {
    "city": "Redlands",
    "city_ascii": "Redlands",
    "lat": 39.0886,
    "lng": -108.6583,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 9036,
    "id": 1840018812
  },
  {
    "city": "Ferryhill",
    "city_ascii": "Ferryhill",
    "lat": 54.69,
    "lng": -1.55,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Durham",
    "capital": "",
    "population": 8942,
    "id": 1826891568
  },
  {
    "city": "Brewer",
    "city_ascii": "Brewer",
    "lat": 44.7835,
    "lng": -68.7352,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maine",
    "capital": "",
    "population": 9035,
    "id": 1840000239
  },
  {
    "city": "Northwest Harborcreek",
    "city_ascii": "Northwest Harborcreek",
    "lat": 42.1494,
    "lng": -79.9946,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 9034,
    "id": 1840035146
  },
  {
    "city": "East Haddam",
    "city_ascii": "East Haddam",
    "lat": 41.4798,
    "lng": -72.3943,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 9032,
    "id": 1840073554
  },
  {
    "city": "McFarland",
    "city_ascii": "McFarland",
    "lat": 43.0203,
    "lng": -89.2858,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 9031,
    "id": 1840002914
  },
  {
    "city": "La Grange",
    "city_ascii": "La Grange",
    "lat": 38.3987,
    "lng": -85.375,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kentucky",
    "capital": "",
    "population": 9031,
    "id": 1840014287
  },
  {
    "city": "Rhymney",
    "city_ascii": "Rhymney",
    "lat": 51.759,
    "lng": -3.283,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Caerphilly",
    "capital": "",
    "population": 8845,
    "id": 1826457934
  },
  {
    "city": "Pineville",
    "city_ascii": "Pineville",
    "lat": 35.0864,
    "lng": -80.8915,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 9028,
    "id": 1840016354
  },
  {
    "city": "North Codorus",
    "city_ascii": "North Codorus",
    "lat": 39.8646,
    "lng": -76.8189,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 9028,
    "id": 1840147120
  },
  {
    "city": "Woodway",
    "city_ascii": "Woodway",
    "lat": 31.4988,
    "lng": -97.2314,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 9024,
    "id": 1840022142
  },
  {
    "city": "Hamlin",
    "city_ascii": "Hamlin",
    "lat": 43.3213,
    "lng": -77.9135,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 9023,
    "id": 1840058157
  },
  {
    "city": "Lindstrom",
    "city_ascii": "Lindstrom",
    "lat": 45.387,
    "lng": -92.8477,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 9023,
    "id": 1840008892
  },
  {
    "city": "Littleport",
    "city_ascii": "Littleport",
    "lat": 52.4568,
    "lng": 0.3046,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Cambridgeshire",
    "capital": "",
    "population": 8738,
    "id": 1826638568
  },
  {
    "city": "Pomichna",
    "city_ascii": "Pomichna",
    "lat": 48.25,
    "lng": 31.4167,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Kirovohrads’ka Oblast’",
    "capital": "",
    "population": 8994,
    "id": 1804146388
  },
  {
    "city": "Shelby",
    "city_ascii": "Shelby",
    "lat": 40.8847,
    "lng": -82.6578,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 9019,
    "id": 1840009369
  },
  {
    "city": "Cinderford",
    "city_ascii": "Cinderford",
    "lat": 51.8225,
    "lng": -2.4989,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Gloucestershire",
    "capital": "",
    "population": 8494,
    "id": 1826291167
  },
  {
    "city": "Oak Hills Place",
    "city_ascii": "Oak Hills Place",
    "lat": 30.369,
    "lng": -91.0887,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 9015,
    "id": 1840031107
  },
  {
    "city": "Babadag",
    "city_ascii": "Babadag",
    "lat": 44.8933,
    "lng": 28.7119,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Tulcea",
    "capital": "",
    "population": 8940,
    "id": 1642622503
  },
  {
    "city": "St. Anthony",
    "city_ascii": "St. Anthony",
    "lat": 45.0278,
    "lng": -93.2174,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 9013,
    "id": 1840008933
  },
  {
    "city": "Buşteni",
    "city_ascii": "Busteni",
    "lat": 45.4153,
    "lng": 25.5375,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Prahova",
    "capital": "",
    "population": 8894,
    "id": 1642809990
  },
  {
    "city": "Weare",
    "city_ascii": "Weare",
    "lat": 43.0813,
    "lng": -71.7219,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Hampshire",
    "capital": "",
    "population": 9011,
    "id": 1840055744
  },
  {
    "city": "Uricani",
    "city_ascii": "Uricani",
    "lat": 45.3364,
    "lng": 23.1525,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Hunedoara",
    "capital": "",
    "population": 8972,
    "id": 1642297592
  },
  {
    "city": "Fairmont",
    "city_ascii": "Fairmont",
    "lat": 43.6441,
    "lng": -94.4621,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 9007,
    "id": 1840007950
  },
  {
    "city": "Worplesdon",
    "city_ascii": "Worplesdon",
    "lat": 51.272,
    "lng": -0.612,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Surrey",
    "capital": "",
    "population": 8529,
    "id": 1826340455
  },
  {
    "city": "Volkach",
    "city_ascii": "Volkach",
    "lat": 49.8641,
    "lng": 10.2269,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 8857,
    "id": 1276344574
  },
  {
    "city": "Karkkila",
    "city_ascii": "Karkkila",
    "lat": 60.5347,
    "lng": 24.2097,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Uusimaa",
    "capital": "minor",
    "population": 8969,
    "id": 1246261386
  },
  {
    "city": "Ochsenhausen",
    "city_ascii": "Ochsenhausen",
    "lat": 48.0722,
    "lng": 9.9481,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 8856,
    "id": 1276059571
  },
  {
    "city": "Manteno",
    "city_ascii": "Manteno",
    "lat": 41.2471,
    "lng": -87.8457,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 9002,
    "id": 1840011703
  },
  {
    "city": "Yelnya",
    "city_ascii": "Yelnya",
    "lat": 54.5667,
    "lng": 33.1667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Smolenskaya Oblast’",
    "capital": "",
    "population": 8993,
    "id": 1643873845
  },
  {
    "city": "Mbamba Bay",
    "city_ascii": "Mbamba Bay",
    "lat": -11.2833,
    "lng": 34.7667,
    "country": "Tanzania",
    "iso2": "TZ",
    "iso3": "TZA",
    "admin_name": "Ruvuma",
    "capital": "",
    "population": 8997,
    "id": 1834067294
  },
  {
    "city": "North Sarasota",
    "city_ascii": "North Sarasota",
    "lat": 27.3711,
    "lng": -82.5177,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 8997,
    "id": 1840029061
  },
  {
    "city": "Dexter",
    "city_ascii": "Dexter",
    "lat": 36.7928,
    "lng": -89.9634,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 8996,
    "id": 1840007643
  },
  {
    "city": "Diss",
    "city_ascii": "Diss",
    "lat": 52.3812,
    "lng": 1.1079,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Norfolk",
    "capital": "",
    "population": 7572,
    "id": 1826598466
  },
  {
    "city": "Szentgotthárd",
    "city_ascii": "Szentgotthard",
    "lat": 46.9525,
    "lng": 16.2736,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Vas",
    "capital": "minor",
    "population": 8864,
    "id": 1348047105
  },
  {
    "city": "Chamical",
    "city_ascii": "Chamical",
    "lat": -30.3496,
    "lng": -66.3167,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "La Rioja",
    "capital": "minor",
    "population": 8989,
    "id": 1032026963
  },
  {
    "city": "Powdersville",
    "city_ascii": "Powdersville",
    "lat": 34.7825,
    "lng": -82.4958,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 8991,
    "id": 1840013581
  },
  {
    "city": "Chop",
    "city_ascii": "Chop",
    "lat": 48.4333,
    "lng": 22.2,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Zakarpats’ka Oblast’",
    "capital": "",
    "population": 8897,
    "id": 1804775994
  },
  {
    "city": "St. Marys",
    "city_ascii": "St. Marys",
    "lat": 41.4574,
    "lng": -78.5342,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 8990,
    "id": 1840000618
  },
  {
    "city": "Mechanicsburg",
    "city_ascii": "Mechanicsburg",
    "lat": 40.2115,
    "lng": -77.006,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 8990,
    "id": 1840001388
  },
  {
    "city": "Albion",
    "city_ascii": "Albion",
    "lat": 42.2478,
    "lng": -84.7572,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 8990,
    "id": 1840003175
  },
  {
    "city": "Chyhyryn",
    "city_ascii": "Chyhyryn",
    "lat": 49.0833,
    "lng": 32.6667,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Cherkas’ka Oblast’",
    "capital": "minor",
    "population": 8962,
    "id": 1804035691
  },
  {
    "city": "Merstham",
    "city_ascii": "Merstham",
    "lat": 51.264,
    "lng": -0.153,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Surrey",
    "capital": "",
    "population": 8123,
    "id": 1826933392
  },
  {
    "city": "Tepechitlán",
    "city_ascii": "Tepechitlan",
    "lat": 21.6667,
    "lng": -103.3333,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Zacatecas",
    "capital": "minor",
    "population": 8972,
    "id": 1484570912
  },
  {
    "city": "Banak",
    "city_ascii": "Banak",
    "lat": 27.8708,
    "lng": 52.0272,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Būshehr",
    "capital": "",
    "population": 8753,
    "id": 1364681484
  },
  {
    "city": "Veľký Meder",
    "city_ascii": "Vel'ky Meder",
    "lat": 47.85,
    "lng": 17.7667,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Trnavský",
    "capital": "",
    "population": 8828,
    "id": 1703165181
  },
  {
    "city": "LaSalle",
    "city_ascii": "LaSalle",
    "lat": 41.3575,
    "lng": -89.0718,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 8986,
    "id": 1840029891
  },
  {
    "city": "Waseca",
    "city_ascii": "Waseca",
    "lat": 44.0826,
    "lng": -93.5025,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 8984,
    "id": 1840010030
  },
  {
    "city": "Chanute",
    "city_ascii": "Chanute",
    "lat": 37.6695,
    "lng": -95.4621,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kansas",
    "capital": "",
    "population": 8983,
    "id": 1840001694
  },
  {
    "city": "Grinnell",
    "city_ascii": "Grinnell",
    "lat": 41.7361,
    "lng": -92.7244,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Iowa",
    "capital": "",
    "population": 8980,
    "id": 1840000531
  },
  {
    "city": "Barrington",
    "city_ascii": "Barrington",
    "lat": 43.2139,
    "lng": -71.0424,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Hampshire",
    "capital": "",
    "population": 8979,
    "id": 1840018720
  },
  {
    "city": "Linganore",
    "city_ascii": "Linganore",
    "lat": 39.4111,
    "lng": -77.3026,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 8978,
    "id": 1840026627
  },
  {
    "city": "Monastyryshche",
    "city_ascii": "Monastyryshche",
    "lat": 48.99,
    "lng": 29.8011,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Cherkas’ka Oblast’",
    "capital": "minor",
    "population": 8925,
    "id": 1804117787
  },
  {
    "city": "South Abington",
    "city_ascii": "South Abington",
    "lat": 41.4901,
    "lng": -75.6891,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 8977,
    "id": 1840146632
  },
  {
    "city": "Picnic Point",
    "city_ascii": "Picnic Point",
    "lat": 47.8744,
    "lng": -122.3078,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 8976,
    "id": 1840037897
  },
  {
    "city": "Macedon",
    "city_ascii": "Macedon",
    "lat": 43.0792,
    "lng": -77.3095,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 8976,
    "id": 1840058275
  },
  {
    "city": "Murphysboro",
    "city_ascii": "Murphysboro",
    "lat": 37.7679,
    "lng": -89.3321,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 8976,
    "id": 1840008701
  },
  {
    "city": "Bumbeşti-Jiu",
    "city_ascii": "Bumbesti-Jiu",
    "lat": 45.1786,
    "lng": 23.3814,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Gorj",
    "capital": "",
    "population": 8932,
    "id": 1642000047
  },
  {
    "city": "Florstadt",
    "city_ascii": "Florstadt",
    "lat": 50.3158,
    "lng": 8.8631,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 8753,
    "id": 1276644408
  },
  {
    "city": "San Pablo Villa de Mitla",
    "city_ascii": "San Pablo Villa de Mitla",
    "lat": 16.917,
    "lng": -96.4,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Oaxaca",
    "capital": "minor",
    "population": 7547,
    "id": 1484844758
  },
  {
    "city": "Chester",
    "city_ascii": "Chester",
    "lat": 34.705,
    "lng": -81.2132,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 8967,
    "id": 1840014663
  },
  {
    "city": "Silver Springs Shores",
    "city_ascii": "Silver Springs Shores",
    "lat": 29.1031,
    "lng": -82.005,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 8966,
    "id": 1840028949
  },
  {
    "city": "Shiprock",
    "city_ascii": "Shiprock",
    "lat": 36.7924,
    "lng": -108.7005,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Mexico",
    "capital": "",
    "population": 8966,
    "id": 1840018988
  },
  {
    "city": "Fort Knox",
    "city_ascii": "Fort Knox",
    "lat": 37.8915,
    "lng": -85.9636,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kentucky",
    "capital": "",
    "population": 8965,
    "id": 1840073833
  },
  {
    "city": "Bernsbach",
    "city_ascii": "Bernsbach",
    "lat": 50.5667,
    "lng": 12.75,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 8678,
    "id": 1276498523
  },
  {
    "city": "Horden",
    "city_ascii": "Horden",
    "lat": 54.764,
    "lng": -1.315,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Durham",
    "capital": "",
    "population": 8087,
    "id": 1826873432
  },
  {
    "city": "Parque del Plata",
    "city_ascii": "Parque del Plata",
    "lat": -34.7667,
    "lng": -55.7167,
    "country": "Uruguay",
    "iso2": "UY",
    "iso3": "URY",
    "admin_name": "Canelones",
    "capital": "",
    "population": 7896,
    "id": 1858274411
  },
  {
    "city": "Penetanguishene",
    "city_ascii": "Penetanguishene",
    "lat": 44.7667,
    "lng": -79.9333,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 8962,
    "id": 1124304117
  },
  {
    "city": "Byfleet",
    "city_ascii": "Byfleet",
    "lat": 51.34,
    "lng": -0.47,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Surrey",
    "capital": "",
    "population": 7724,
    "id": 1826179551
  },
  {
    "city": "Schleiz",
    "city_ascii": "Schleiz",
    "lat": 50.5833,
    "lng": 11.8167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Thuringia",
    "capital": "minor",
    "population": 8854,
    "id": 1276386563
  },
  {
    "city": "Iwaizumi",
    "city_ascii": "Iwaizumi",
    "lat": 39.8431,
    "lng": 141.7967,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Iwate",
    "capital": "",
    "population": 8949,
    "id": 1392666013
  },
  {
    "city": "Tlumach",
    "city_ascii": "Tlumach",
    "lat": 48.8669,
    "lng": 25.0012,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Ivano-Frankivs’ka Oblast’",
    "capital": "minor",
    "population": 8886,
    "id": 1804009591
  },
  {
    "city": "Micco",
    "city_ascii": "Micco",
    "lat": 27.8683,
    "lng": -80.51,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 8956,
    "id": 1840014077
  },
  {
    "city": "Naunhof",
    "city_ascii": "Naunhof",
    "lat": 51.2778,
    "lng": 12.5883,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 8735,
    "id": 1276828471
  },
  {
    "city": "White City",
    "city_ascii": "White City",
    "lat": 42.4316,
    "lng": -122.8322,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 8953,
    "id": 1840018673
  },
  {
    "city": "Thames",
    "city_ascii": "Thames",
    "lat": -37.1383,
    "lng": 175.5375,
    "country": "New Zealand",
    "iso2": "NZ",
    "iso3": "NZL",
    "admin_name": "Waikato",
    "capital": "",
    "population": 7680,
    "id": 1554802053
  },
  {
    "city": "Rain",
    "city_ascii": "Rain",
    "lat": 48.6833,
    "lng": 10.9167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 8836,
    "id": 1276518985
  },
  {
    "city": "Hermsdorf",
    "city_ascii": "Hermsdorf",
    "lat": 50.8981,
    "lng": 11.8567,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Thuringia",
    "capital": "",
    "population": 7893,
    "id": 1276816983
  },
  {
    "city": "Skovorodino",
    "city_ascii": "Skovorodino",
    "lat": 53.9833,
    "lng": 123.9333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Amurskaya Oblast’",
    "capital": "",
    "population": 8943,
    "id": 1643357581
  },
  {
    "city": "Auerbach",
    "city_ascii": "Auerbach",
    "lat": 49.692,
    "lng": 11.6333,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 8818,
    "id": 1276188143
  },
  {
    "city": "Qualicum Beach",
    "city_ascii": "Qualicum Beach",
    "lat": 49.35,
    "lng": -124.4333,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "British Columbia",
    "capital": "",
    "population": 8943,
    "id": 1124822520
  },
  {
    "city": "Sodankylä",
    "city_ascii": "Sodankyla",
    "lat": 67.4171,
    "lng": 26.6,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Lappi",
    "capital": "minor",
    "population": 8942,
    "id": 1246413171
  },
  {
    "city": "Florø",
    "city_ascii": "Floro",
    "lat": 61.5986,
    "lng": 5.0172,
    "country": "Norway",
    "iso2": "NO",
    "iso3": "NOR",
    "admin_name": "Sogn og Fjordane",
    "capital": "minor",
    "population": 8925,
    "id": 1578596650
  },
  {
    "city": "San Ramón",
    "city_ascii": "San Ramon",
    "lat": -34.2914,
    "lng": -55.9542,
    "country": "Uruguay",
    "iso2": "UY",
    "iso3": "URY",
    "admin_name": "Canelones",
    "capital": "",
    "population": 7133,
    "id": 1858512396
  },
  {
    "city": "Hanko",
    "city_ascii": "Hanko",
    "lat": 59.8236,
    "lng": 22.9681,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Uusimaa",
    "capital": "minor",
    "population": 8864,
    "id": 1246686751
  },
  {
    "city": "Pittsgrove",
    "city_ascii": "Pittsgrove",
    "lat": 39.5404,
    "lng": -75.1291,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 8940,
    "id": 1840131599
  },
  {
    "city": "Paulo de Faria",
    "city_ascii": "Paulo de Faria",
    "lat": -20.0308,
    "lng": -49.3828,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 8924,
    "id": 1076000170
  },
  {
    "city": "Oakwood",
    "city_ascii": "Oakwood",
    "lat": 39.7202,
    "lng": -84.1733,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 8936,
    "id": 1840003785
  },
  {
    "city": "Mikhaylovsk",
    "city_ascii": "Mikhaylovsk",
    "lat": 56.4333,
    "lng": 59.1167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sverdlovskaya Oblast’",
    "capital": "",
    "population": 8921,
    "id": 1643402294
  },
  {
    "city": "Deutsch-Wagram",
    "city_ascii": "Deutsch-Wagram",
    "lat": 48.2994,
    "lng": 16.5644,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Niederösterreich",
    "capital": "",
    "population": 8651,
    "id": 1040363748
  },
  {
    "city": "Lenox",
    "city_ascii": "Lenox",
    "lat": 43.1113,
    "lng": -75.7582,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 8933,
    "id": 1840058237
  },
  {
    "city": "Harrisburg",
    "city_ascii": "Harrisburg",
    "lat": 37.7373,
    "lng": -88.5457,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 8933,
    "id": 1840007570
  },
  {
    "city": "Liman",
    "city_ascii": "Liman",
    "lat": 38.8733,
    "lng": 48.8139,
    "country": "Azerbaijan",
    "iso2": "AZ",
    "iso3": "AZE",
    "admin_name": "Lənkəran",
    "capital": "",
    "population": 8661,
    "id": 1031959844
  },
  {
    "city": "Eceabat",
    "city_ascii": "Eceabat",
    "lat": 40.1842,
    "lng": 26.3575,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Çanakkale",
    "capital": "minor",
    "population": 8912,
    "id": 1792350740
  },
  {
    "city": "Hořice",
    "city_ascii": "Horice",
    "lat": 50.3661,
    "lng": 15.6319,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Královéhradecký Kraj",
    "capital": "",
    "population": 8533,
    "id": 1203829404
  },
  {
    "city": "Independence",
    "city_ascii": "Independence",
    "lat": 37.2118,
    "lng": -95.7328,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kansas",
    "capital": "",
    "population": 8931,
    "id": 1840001708
  },
  {
    "city": "East Nottingham",
    "city_ascii": "East Nottingham",
    "lat": 39.7621,
    "lng": -75.9705,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 8929,
    "id": 1840150922
  },
  {
    "city": "Oakville",
    "city_ascii": "Oakville",
    "lat": 41.5923,
    "lng": -73.0858,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 8929,
    "id": 1840003260
  },
  {
    "city": "Cape St. Claire",
    "city_ascii": "Cape St. Claire",
    "lat": 39.0433,
    "lng": -76.4471,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 8928,
    "id": 1840005908
  },
  {
    "city": "Larkfield-Wikiup",
    "city_ascii": "Larkfield-Wikiup",
    "lat": 38.513,
    "lng": -122.7536,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 8926,
    "id": 1840074293
  },
  {
    "city": "Achacachi",
    "city_ascii": "Achacachi",
    "lat": -16.0444,
    "lng": -68.685,
    "country": "Bolivia",
    "iso2": "BO",
    "iso3": "BOL",
    "admin_name": "La Paz",
    "capital": "",
    "population": 8857,
    "id": 1068370700
  },
  {
    "city": "Suoyarvi",
    "city_ascii": "Suoyarvi",
    "lat": 62.0833,
    "lng": 32.3667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kareliya",
    "capital": "",
    "population": 8920,
    "id": 1643031731
  },
  {
    "city": "Rotonda",
    "city_ascii": "Rotonda",
    "lat": 26.8845,
    "lng": -82.2791,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 8921,
    "id": 1840014201
  },
  {
    "city": "Serafimovich",
    "city_ascii": "Serafimovich",
    "lat": 49.5833,
    "lng": 42.7333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Volgogradskaya Oblast’",
    "capital": "",
    "population": 8914,
    "id": 1643516718
  },
  {
    "city": "Boonton",
    "city_ascii": "Boonton",
    "lat": 40.9047,
    "lng": -74.4048,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 8919,
    "id": 1840000946
  },
  {
    "city": "Penig",
    "city_ascii": "Penig",
    "lat": 50.9336,
    "lng": 12.7058,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 8780,
    "id": 1276085565
  },
  {
    "city": "Ðurđevac",
    "city_ascii": "Durdevac",
    "lat": 46.0272,
    "lng": 17.0672,
    "country": "Croatia",
    "iso2": "HR",
    "iso3": "HRV",
    "admin_name": "Koprivničko-Križevačka Županija",
    "capital": "minor",
    "population": 8862,
    "id": 1191122948
  },
  {
    "city": "Camenca",
    "city_ascii": "Camenca",
    "lat": 48.0333,
    "lng": 28.7,
    "country": "Moldova",
    "iso2": "MD",
    "iso3": "MDA",
    "admin_name": "Stînga Nistrului",
    "capital": "",
    "population": 8871,
    "id": 1498446889
  },
  {
    "city": "Oak Grove",
    "city_ascii": "Oak Grove",
    "lat": 45.3409,
    "lng": -93.3264,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 8917,
    "id": 1840007799
  },
  {
    "city": "Sheffield Lake",
    "city_ascii": "Sheffield Lake",
    "lat": 41.4884,
    "lng": -82.0978,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 8916,
    "id": 1840000647
  },
  {
    "city": "Harrodsburg",
    "city_ascii": "Harrodsburg",
    "lat": 37.7654,
    "lng": -84.8474,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kentucky",
    "capital": "",
    "population": 8916,
    "id": 1840013222
  },
  {
    "city": "Szerencs",
    "city_ascii": "Szerencs",
    "lat": 48.1622,
    "lng": 21.205,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Borsod-Abaúj-Zemplén",
    "capital": "minor",
    "population": 8676,
    "id": 1348564586
  },
  {
    "city": "Belsh",
    "city_ascii": "Belsh",
    "lat": 40.9833,
    "lng": 19.8833,
    "country": "Albania",
    "iso2": "AL",
    "iso3": "ALB",
    "admin_name": "Elbasan",
    "capital": "",
    "population": 8781,
    "id": 1008080226
  },
  {
    "city": "Ararat",
    "city_ascii": "Ararat",
    "lat": -37.2833,
    "lng": 142.9167,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Victoria",
    "capital": "",
    "population": 8300,
    "id": 1036674088
  },
  {
    "city": "Kozlovka",
    "city_ascii": "Kozlovka",
    "lat": 55.8333,
    "lng": 48.25,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Chuvashiya",
    "capital": "",
    "population": 8866,
    "id": 1643561903
  },
  {
    "city": "Aledo",
    "city_ascii": "Aledo",
    "lat": 32.6973,
    "lng": -97.607,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 8909,
    "id": 1840019418
  },
  {
    "city": "Sellersburg",
    "city_ascii": "Sellersburg",
    "lat": 38.4028,
    "lng": -85.7706,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 8908,
    "id": 1840010786
  },
  {
    "city": "Charters Towers",
    "city_ascii": "Charters Towers",
    "lat": -20.1,
    "lng": 146.2667,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Queensland",
    "capital": "",
    "population": 8120,
    "id": 1036733088
  },
  {
    "city": "Stadtilm",
    "city_ascii": "Stadtilm",
    "lat": 50.7758,
    "lng": 11.0825,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Thuringia",
    "capital": "",
    "population": 8420,
    "id": 1276544769
  },
  {
    "city": "Blanchard",
    "city_ascii": "Blanchard",
    "lat": 35.1523,
    "lng": -97.6613,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oklahoma",
    "capital": "",
    "population": 8907,
    "id": 1840019175
  },
  {
    "city": "San José de Gracia",
    "city_ascii": "San Jose de Gracia",
    "lat": 22.15,
    "lng": -102.4167,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Aguascalientes",
    "capital": "minor",
    "population": 8896,
    "id": 1484004442
  },
  {
    "city": "Takua Pa",
    "city_ascii": "Takua Pa",
    "lat": 8.8658,
    "lng": 98.3413,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Phangnga",
    "capital": "minor",
    "population": 8815,
    "id": 1764000562
  },
  {
    "city": "Whitehouse",
    "city_ascii": "Whitehouse",
    "lat": 32.2222,
    "lng": -95.2217,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 8905,
    "id": 1840022077
  },
  {
    "city": "Southside",
    "city_ascii": "Southside",
    "lat": 33.9007,
    "lng": -86.0238,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 8905,
    "id": 1840015604
  },
  {
    "city": "Waverly",
    "city_ascii": "Waverly",
    "lat": 42.725,
    "lng": -92.4708,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Iowa",
    "capital": "",
    "population": 8904,
    "id": 1840000404
  },
  {
    "city": "Połaniec",
    "city_ascii": "Polaniec",
    "lat": 50.4333,
    "lng": 21.2833,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Świętokrzyskie",
    "capital": "",
    "population": 8419,
    "id": 1616934087
  },
  {
    "city": "Caister-on-Sea",
    "city_ascii": "Caister-on-Sea",
    "lat": 52.651,
    "lng": 1.733,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Norfolk",
    "capital": "",
    "population": 8901,
    "id": 1826817641
  },
  {
    "city": "Sheffield",
    "city_ascii": "Sheffield",
    "lat": 34.757,
    "lng": -87.6977,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 8901,
    "id": 1840015537
  },
  {
    "city": "St. Albans",
    "city_ascii": "St. Albans",
    "lat": 44.8118,
    "lng": -73.0846,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Vermont",
    "capital": "",
    "population": 8900,
    "id": 1840002267
  },
  {
    "city": "Pudozh",
    "city_ascii": "Pudozh",
    "lat": 61.8,
    "lng": 36.5333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kareliya",
    "capital": "",
    "population": 8897,
    "id": 1643479302
  },
  {
    "city": "Lisbon",
    "city_ascii": "Lisbon",
    "lat": 44.0265,
    "lng": -70.09,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maine",
    "capital": "",
    "population": 8896,
    "id": 1840066320
  },
  {
    "city": "Rainworth",
    "city_ascii": "Rainworth",
    "lat": 53.1197,
    "lng": -1.1235,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Nottinghamshire",
    "capital": "",
    "population": 7821,
    "id": 1826171227
  },
  {
    "city": "Koror",
    "city_ascii": "Koror",
    "lat": 7.3433,
    "lng": 134.4804,
    "country": "Palau",
    "iso2": "PW",
    "iso3": "PLW",
    "admin_name": "",
    "capital": "",
    "population": 8744,
    "id": 1585174728
  },
  {
    "city": "Septemvri",
    "city_ascii": "Septemvri",
    "lat": 42.2183,
    "lng": 24.1237,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Pazardzhik",
    "capital": "minor",
    "population": 8813,
    "id": 1100764982
  },
  {
    "city": "Rye",
    "city_ascii": "Rye",
    "lat": -38.377,
    "lng": 144.838,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Victoria",
    "capital": "",
    "population": 8416,
    "id": 1036066082
  },
  {
    "city": "Bad Schussenried",
    "city_ascii": "Bad Schussenried",
    "lat": 48.0067,
    "lng": 9.6586,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 8734,
    "id": 1276967245
  },
  {
    "city": "Landstuhl",
    "city_ascii": "Landstuhl",
    "lat": 49.4122,
    "lng": 7.5722,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Rhineland-Palatinate",
    "capital": "",
    "population": 8348,
    "id": 1276114618
  },
  {
    "city": "Oakland",
    "city_ascii": "Oakland",
    "lat": 35.2256,
    "lng": -89.5372,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 8891,
    "id": 1840016407
  },
  {
    "city": "Ōwani",
    "city_ascii": "Owani",
    "lat": 40.5183,
    "lng": 140.5678,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Aomori",
    "capital": "",
    "population": 8832,
    "id": 1392282662
  },
  {
    "city": "Bad Lauchstädt",
    "city_ascii": "Bad Lauchstadt",
    "lat": 51.3865,
    "lng": 11.8696,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony-Anhalt",
    "capital": "",
    "population": 8783,
    "id": 1276634742
  },
  {
    "city": "San Joaquín",
    "city_ascii": "San Joaquin",
    "lat": 20.9833,
    "lng": -99.5,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Querétaro",
    "capital": "minor",
    "population": 8865,
    "id": 1484855056
  },
  {
    "city": "Bad Bergzabern",
    "city_ascii": "Bad Bergzabern",
    "lat": 49.1031,
    "lng": 7.9989,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Rhineland-Palatinate",
    "capital": "",
    "population": 8124,
    "id": 1276024319
  },
  {
    "city": "Logan",
    "city_ascii": "Logan",
    "lat": 37.8509,
    "lng": -81.9857,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "West Virginia",
    "capital": "",
    "population": 8882,
    "id": 1840006336
  },
  {
    "city": "Vráble",
    "city_ascii": "Vrable",
    "lat": 48.25,
    "lng": 18.3167,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Nitriansky",
    "capital": "",
    "population": 8656,
    "id": 1703078244
  },
  {
    "city": "Gilgit",
    "city_ascii": "Gilgit",
    "lat": 35.9208,
    "lng": 74.3144,
    "country": "Pakistan",
    "iso2": "PK",
    "iso3": "PAK",
    "admin_name": "Gilgit-Baltistan",
    "capital": "minor",
    "population": 8851,
    "id": 1586284242
  },
  {
    "city": "Ak’ordat",
    "city_ascii": "Ak'ordat",
    "lat": 15.55,
    "lng": 37.8833,
    "country": "Eritrea",
    "iso2": "ER",
    "iso3": "ERI",
    "admin_name": "Gash Barka",
    "capital": "",
    "population": 8857,
    "id": 1232800240
  },
  {
    "city": "Nierstein",
    "city_ascii": "Nierstein",
    "lat": 49.8694,
    "lng": 8.3375,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Rhineland-Palatinate",
    "capital": "",
    "population": 8443,
    "id": 1276411938
  },
  {
    "city": "Ciudad Cerralvo",
    "city_ascii": "Ciudad Cerralvo",
    "lat": 26.0899,
    "lng": -99.6147,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Nuevo León",
    "capital": "minor",
    "population": 7169,
    "id": 1484627083
  },
  {
    "city": "Wagoner",
    "city_ascii": "Wagoner",
    "lat": 35.9641,
    "lng": -95.379,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oklahoma",
    "capital": "",
    "population": 8875,
    "id": 1840021696
  },
  {
    "city": "Bang Mun Nak",
    "city_ascii": "Bang Mun Nak",
    "lat": 16.0278,
    "lng": 100.3792,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Phichit",
    "capital": "minor",
    "population": 8756,
    "id": 1764958140
  },
  {
    "city": "Upper Leacock",
    "city_ascii": "Upper Leacock",
    "lat": 40.0801,
    "lng": -76.1854,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 8873,
    "id": 1840142492
  },
  {
    "city": "Bussy",
    "city_ascii": "Bussy",
    "lat": 46.55,
    "lng": 6.55,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Vaud",
    "capital": "",
    "population": 8736,
    "id": 1756000766
  },
  {
    "city": "Corozal",
    "city_ascii": "Corozal",
    "lat": 18.3411,
    "lng": -66.3124,
    "country": "Puerto Rico",
    "iso2": "PR",
    "iso3": "PRI",
    "admin_name": "Puerto Rico",
    "capital": "",
    "population": 8871,
    "id": 1630035655
  },
  {
    "city": "Neya",
    "city_ascii": "Neya",
    "lat": 58.2833,
    "lng": 43.8667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kostromskaya Oblast’",
    "capital": "",
    "population": 8865,
    "id": 1643055534
  },
  {
    "city": "Barth",
    "city_ascii": "Barth",
    "lat": 54.3667,
    "lng": 12.7167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Mecklenburg-Western Pomerania",
    "capital": "",
    "population": 8658,
    "id": 1276722582
  },
  {
    "city": "Woodbridge",
    "city_ascii": "Woodbridge",
    "lat": 41.3566,
    "lng": -73.0102,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 8868,
    "id": 1840035674
  },
  {
    "city": "Notre-Dame-des-Prairies",
    "city_ascii": "Notre-Dame-des-Prairies",
    "lat": 46.05,
    "lng": -73.4333,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 8868,
    "id": 1124001393
  },
  {
    "city": "Polesworth",
    "city_ascii": "Polesworth",
    "lat": 52.6152,
    "lng": -1.6174,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Warwickshire",
    "capital": "",
    "population": 8423,
    "id": 1826761381
  },
  {
    "city": "Brecon",
    "city_ascii": "Brecon",
    "lat": 51.948,
    "lng": -3.391,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Powys",
    "capital": "",
    "population": 8250,
    "id": 1826444860
  },
  {
    "city": "Hemmoor",
    "city_ascii": "Hemmoor",
    "lat": 53.7025,
    "lng": 9.1394,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 8673,
    "id": 1276546938
  },
  {
    "city": "Semily",
    "city_ascii": "Semily",
    "lat": 50.602,
    "lng": 15.3356,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Liberecký Kraj",
    "capital": "",
    "population": 8353,
    "id": 1203493915
  },
  {
    "city": "West Perth",
    "city_ascii": "West Perth",
    "lat": 43.47,
    "lng": -81.2,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 8865,
    "id": 1124001056
  },
  {
    "city": "Hamilton Township",
    "city_ascii": "Hamilton Township",
    "lat": 40.9333,
    "lng": -75.2844,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 8864,
    "id": 1840145220
  },
  {
    "city": "Ponta do Sol",
    "city_ascii": "Ponta do Sol",
    "lat": 32.6811,
    "lng": -17.1042,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Madeira",
    "capital": "",
    "population": 8862,
    "id": 1620041092
  },
  {
    "city": "Cabo Rojo",
    "city_ascii": "Cabo Rojo",
    "lat": 18.0867,
    "lng": -67.1482,
    "country": "Puerto Rico",
    "iso2": "PR",
    "iso3": "PRI",
    "admin_name": "Puerto Rico",
    "capital": "",
    "population": 8862,
    "id": 1630023608
  },
  {
    "city": "Taftanāz",
    "city_ascii": "Taftanaz",
    "lat": 35.9981,
    "lng": 36.7847,
    "country": "Syria",
    "iso2": "SY",
    "iso3": "SYR",
    "admin_name": "Idlib",
    "capital": "minor",
    "population": 8540,
    "id": 1760676375
  },
  {
    "city": "Moe",
    "city_ascii": "Moe",
    "lat": -38.1722,
    "lng": 146.2678,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Victoria",
    "capital": "",
    "population": 8778,
    "id": 1036922580
  },
  {
    "city": "New Prague",
    "city_ascii": "New Prague",
    "lat": 44.5459,
    "lng": -93.5758,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 8859,
    "id": 1840007882
  },
  {
    "city": "Příbor",
    "city_ascii": "Pribor",
    "lat": 49.6409,
    "lng": 18.145,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Moravskoslezský Kraj",
    "capital": "",
    "population": 8476,
    "id": 1203617012
  },
  {
    "city": "Štětí",
    "city_ascii": "Steti",
    "lat": 50.4531,
    "lng": 14.3743,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Ústecký Kraj",
    "capital": "",
    "population": 8695,
    "id": 1203443601
  },
  {
    "city": "São José da Bela Vista",
    "city_ascii": "Sao Jose da Bela Vista",
    "lat": -20.5928,
    "lng": -47.64,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 8823,
    "id": 1076871004
  },
  {
    "city": "Rrëshen",
    "city_ascii": "Rreshen",
    "lat": 41.7667,
    "lng": 19.8833,
    "country": "Albania",
    "iso2": "AL",
    "iso3": "ALB",
    "admin_name": "Lezhë",
    "capital": "",
    "population": 8803,
    "id": 1008845269
  },
  {
    "city": "Sugarmill Woods",
    "city_ascii": "Sugarmill Woods",
    "lat": 28.7321,
    "lng": -82.4986,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 8851,
    "id": 1840014063
  },
  {
    "city": "Kodiak",
    "city_ascii": "Kodiak",
    "lat": 57.7934,
    "lng": -152.406,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alaska",
    "capital": "",
    "population": 8851,
    "id": 1840023301
  },
  {
    "city": "Kirk of Shotts",
    "city_ascii": "Kirk of Shotts",
    "lat": 55.823,
    "lng": -3.804,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "North Lanarkshire",
    "capital": "",
    "population": 8840,
    "id": 1826280322
  },
  {
    "city": "Deniliquin",
    "city_ascii": "Deniliquin",
    "lat": -35.5317,
    "lng": 144.9344,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 7862,
    "id": 1036325219
  },
  {
    "city": "Seabrook",
    "city_ascii": "Seabrook",
    "lat": 42.887,
    "lng": -70.8613,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Hampshire",
    "capital": "",
    "population": 8846,
    "id": 1840055531
  },
  {
    "city": "Altenerding",
    "city_ascii": "Altenerding",
    "lat": 48.2932,
    "lng": 11.9054,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 8196,
    "id": 1276007257
  },
  {
    "city": "Aliquippa",
    "city_ascii": "Aliquippa",
    "lat": 40.6155,
    "lng": -80.2547,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 8844,
    "id": 1840001009
  },
  {
    "city": "Bridgeport",
    "city_ascii": "Bridgeport",
    "lat": 39.3036,
    "lng": -80.2477,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "West Virginia",
    "capital": "",
    "population": 8842,
    "id": 1840005766
  },
  {
    "city": "Großbottwar",
    "city_ascii": "Grossbottwar",
    "lat": 49.0014,
    "lng": 9.2931,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 8512,
    "id": 1276002930
  },
  {
    "city": "Berkeley",
    "city_ascii": "Berkeley",
    "lat": 38.7439,
    "lng": -90.3361,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 8841,
    "id": 1840007450
  },
  {
    "city": "Riverdale",
    "city_ascii": "Riverdale",
    "lat": 41.1732,
    "lng": -112.0024,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Utah",
    "capital": "",
    "population": 8838,
    "id": 1840020131
  },
  {
    "city": "Darley",
    "city_ascii": "Darley",
    "lat": -37.6547,
    "lng": 144.437,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Victoria",
    "capital": "",
    "population": 8372,
    "id": 1036031812
  },
  {
    "city": "Shopokov",
    "city_ascii": "Shopokov",
    "lat": 42.8167,
    "lng": 74.2333,
    "country": "Kyrgyzstan",
    "iso2": "KG",
    "iso3": "KGZ",
    "admin_name": "Chüy",
    "capital": "",
    "population": 8749,
    "id": 1417801567
  },
  {
    "city": "Fallston",
    "city_ascii": "Fallston",
    "lat": 39.5332,
    "lng": -76.4452,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 8836,
    "id": 1840005662
  },
  {
    "city": "Mkazi",
    "city_ascii": "Mkazi",
    "lat": -11.7323,
    "lng": 43.2671,
    "country": "Comoros",
    "iso2": "KM",
    "iso3": "COM",
    "admin_name": "Grande Comore",
    "capital": "",
    "population": 8438,
    "id": 1174910201
  },
  {
    "city": "Bálsamo",
    "city_ascii": "Balsamo",
    "lat": -20.735,
    "lng": -49.5839,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 8773,
    "id": 1076470597
  },
  {
    "city": "Esopus",
    "city_ascii": "Esopus",
    "lat": 41.8425,
    "lng": -73.9936,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 8831,
    "id": 1840058084
  },
  {
    "city": "Rhinelander",
    "city_ascii": "Rhinelander",
    "lat": 45.6361,
    "lng": -89.4256,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 8830,
    "id": 1840002046
  },
  {
    "city": "San Jacinto",
    "city_ascii": "San Jacinto",
    "lat": -34.5444,
    "lng": -55.8719,
    "country": "Uruguay",
    "iso2": "UY",
    "iso3": "URY",
    "admin_name": "Canelones",
    "capital": "",
    "population": 7000,
    "id": 1858537441
  },
  {
    "city": "Ville Platte",
    "city_ascii": "Ville Platte",
    "lat": 30.6901,
    "lng": -92.2744,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 8829,
    "id": 1840015896
  },
  {
    "city": "Cavan Monaghan",
    "city_ascii": "Cavan Monaghan",
    "lat": 44.2,
    "lng": -78.4667,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 8829,
    "id": 1124001281
  },
  {
    "city": "Olmsted Falls",
    "city_ascii": "Olmsted Falls",
    "lat": 41.3658,
    "lng": -81.9042,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 8828,
    "id": 1840003404
  },
  {
    "city": "Mansfeld",
    "city_ascii": "Mansfeld",
    "lat": 51.5942,
    "lng": 11.4547,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony-Anhalt",
    "capital": "",
    "population": 8765,
    "id": 1276609161
  },
  {
    "city": "Glencoe",
    "city_ascii": "Glencoe",
    "lat": 42.1347,
    "lng": -87.7641,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 8826,
    "id": 1840011279
  },
  {
    "city": "Lyaskovets",
    "city_ascii": "Lyaskovets",
    "lat": 43.1061,
    "lng": 25.7153,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Veliko Tarnovo",
    "capital": "",
    "population": 8728,
    "id": 1100739412
  },
  {
    "city": "Orange Park",
    "city_ascii": "Orange Park",
    "lat": 30.1706,
    "lng": -81.7041,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 8824,
    "id": 1840017206
  },
  {
    "city": "Agnita",
    "city_ascii": "Agnita",
    "lat": 45.9733,
    "lng": 24.6172,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Sibiu",
    "capital": "",
    "population": 8732,
    "id": 1642697925
  },
  {
    "city": "Mürzzuschlag",
    "city_ascii": "Murzzuschlag",
    "lat": 47.6075,
    "lng": 15.6731,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Steiermark",
    "capital": "",
    "population": 8654,
    "id": 1040974582
  },
  {
    "city": "Krumovgrad",
    "city_ascii": "Krumovgrad",
    "lat": 41.4719,
    "lng": 25.6528,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Kardzhali",
    "capital": "minor",
    "population": 8800,
    "id": 1100516476
  },
  {
    "city": "Buda-Kashalyova",
    "city_ascii": "Buda-Kashalyova",
    "lat": 52.7167,
    "lng": 30.5667,
    "country": "Belarus",
    "iso2": "BY",
    "iso3": "BLR",
    "admin_name": "Homyel’skaya Voblasts’",
    "capital": "minor",
    "population": 8800,
    "id": 1112915706
  },
  {
    "city": "Absecon",
    "city_ascii": "Absecon",
    "lat": 39.4228,
    "lng": -74.4944,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 8818,
    "id": 1840003797
  },
  {
    "city": "Canal Winchester",
    "city_ascii": "Canal Winchester",
    "lat": 39.8432,
    "lng": -82.8121,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 8818,
    "id": 1840010524
  },
  {
    "city": "Kappeln",
    "city_ascii": "Kappeln",
    "lat": 54.6614,
    "lng": 9.9311,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Schleswig-Holstein",
    "capital": "",
    "population": 8619,
    "id": 1276715573
  },
  {
    "city": "Yacimiento Río Turbio",
    "city_ascii": "Yacimiento Rio Turbio",
    "lat": -51.5333,
    "lng": -72.3,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Santa Cruz",
    "capital": "",
    "population": 8814,
    "id": 1032704902
  },
  {
    "city": "Palm Beach",
    "city_ascii": "Palm Beach",
    "lat": 26.6932,
    "lng": -80.0406,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 8816,
    "id": 1840017267
  },
  {
    "city": "Soltsy",
    "city_ascii": "Soltsy",
    "lat": 58.1167,
    "lng": 30.3167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Novgorodskaya Oblast’",
    "capital": "",
    "population": 8803,
    "id": 1643041908
  },
  {
    "city": "Caimanera",
    "city_ascii": "Caimanera",
    "lat": 19.9947,
    "lng": -75.16,
    "country": "Cuba",
    "iso2": "CU",
    "iso3": "CUB",
    "admin_name": "Guantánamo",
    "capital": "",
    "population": 8790,
    "id": 1192287141
  },
  {
    "city": "Gorodovikovsk",
    "city_ascii": "Gorodovikovsk",
    "lat": 46.0833,
    "lng": 41.9333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kalmykiya",
    "capital": "",
    "population": 8798,
    "id": 1643064112
  },
  {
    "city": "Balatonalmádi",
    "city_ascii": "Balatonalmadi",
    "lat": 47.0292,
    "lng": 18.0219,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Veszprém",
    "capital": "minor",
    "population": 8640,
    "id": 1348907128
  },
  {
    "city": "Rochford",
    "city_ascii": "Rochford",
    "lat": 51.5821,
    "lng": 0.7065,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Essex",
    "capital": "",
    "population": 8471,
    "id": 1826801130
  },
  {
    "city": "Old Colwyn",
    "city_ascii": "Old Colwyn",
    "lat": 53.291,
    "lng": -3.704,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Conwy",
    "capital": "",
    "population": 8113,
    "id": 1826316600
  },
  {
    "city": "Cedral",
    "city_ascii": "Cedral",
    "lat": -20.9028,
    "lng": -49.2678,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 8767,
    "id": 1076000902
  },
  {
    "city": "Takaharu",
    "city_ascii": "Takaharu",
    "lat": 31.9283,
    "lng": 131.0078,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Miyazaki",
    "capital": "",
    "population": 8709,
    "id": 1392262891
  },
  {
    "city": "Pine Lake Park",
    "city_ascii": "Pine Lake Park",
    "lat": 40.0017,
    "lng": -74.2595,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 8811,
    "id": 1840024354
  },
  {
    "city": "Monson",
    "city_ascii": "Monson",
    "lat": 42.0934,
    "lng": -72.3261,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 8811,
    "id": 1840070156
  },
  {
    "city": "Old Orchard Beach",
    "city_ascii": "Old Orchard Beach",
    "lat": 43.5239,
    "lng": -70.3904,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maine",
    "capital": "",
    "population": 8809,
    "id": 1840052942
  },
  {
    "city": "Merrydale",
    "city_ascii": "Merrydale",
    "lat": 30.4998,
    "lng": -91.1081,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 8808,
    "id": 1840013940
  },
  {
    "city": "Kuhmo",
    "city_ascii": "Kuhmo",
    "lat": 64.125,
    "lng": 29.5167,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Kainuu",
    "capital": "minor",
    "population": 8806,
    "id": 1246485252
  },
  {
    "city": "Glens Falls North",
    "city_ascii": "Glens Falls North",
    "lat": 43.335,
    "lng": -73.6824,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 8807,
    "id": 1840073682
  },
  {
    "city": "Indianola",
    "city_ascii": "Indianola",
    "lat": 33.4492,
    "lng": -90.6447,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Mississippi",
    "capital": "",
    "population": 8804,
    "id": 1840014774
  },
  {
    "city": "Waihee-Waiehu",
    "city_ascii": "Waihee-Waiehu",
    "lat": 20.9188,
    "lng": -156.5063,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Hawaii",
    "capital": "",
    "population": 8802,
    "id": 1840037549
  },
  {
    "city": "Franklin",
    "city_ascii": "Franklin",
    "lat": 41.3936,
    "lng": -79.8425,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 8802,
    "id": 1840003425
  },
  {
    "city": "Richmond Heights",
    "city_ascii": "Richmond Heights",
    "lat": 38.6309,
    "lng": -90.3333,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 8801,
    "id": 1840009769
  },
  {
    "city": "Temperance",
    "city_ascii": "Temperance",
    "lat": 41.7653,
    "lng": -83.5755,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 8798,
    "id": 1840006579
  },
  {
    "city": "Arnprior",
    "city_ascii": "Arnprior",
    "lat": 45.4333,
    "lng": -76.35,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 8795,
    "id": 1124700031
  },
  {
    "city": "Douglas",
    "city_ascii": "Douglas",
    "lat": 42.0525,
    "lng": -71.7516,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 8794,
    "id": 1840053689
  },
  {
    "city": "Bad Sooden-Allendorf",
    "city_ascii": "Bad Sooden-Allendorf",
    "lat": 51.2667,
    "lng": 9.9667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 8675,
    "id": 1276874812
  },
  {
    "city": "Roznov",
    "city_ascii": "Roznov",
    "lat": 46.8356,
    "lng": 26.5117,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Neamţ",
    "capital": "",
    "population": 8593,
    "id": 1642134720
  },
  {
    "city": "Mariinskiy Posad",
    "city_ascii": "Mariinskiy Posad",
    "lat": 56.1167,
    "lng": 47.7167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Chuvashiya",
    "capital": "",
    "population": 8755,
    "id": 1643337574
  },
  {
    "city": "Tirschenreuth",
    "city_ascii": "Tirschenreuth",
    "lat": 49.8789,
    "lng": 12.3369,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "minor",
    "population": 8707,
    "id": 1276972262
  },
  {
    "city": "Kuppenheim",
    "city_ascii": "Kuppenheim",
    "lat": 48.8275,
    "lng": 8.2544,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 8330,
    "id": 1276892297
  },
  {
    "city": "Tōya",
    "city_ascii": "Toya",
    "lat": 42.5514,
    "lng": 140.7644,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hokkaidō",
    "capital": "",
    "population": 8742,
    "id": 1392273720
  },
  {
    "city": "Earlimart",
    "city_ascii": "Earlimart",
    "lat": 35.8824,
    "lng": -119.2715,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 8790,
    "id": 1840019032
  },
  {
    "city": "Belozërsk",
    "city_ascii": "Belozersk",
    "lat": 60.0333,
    "lng": 37.7833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Vologodskaya Oblast’",
    "capital": "",
    "population": 8786,
    "id": 1643349305
  },
  {
    "city": "Memphis",
    "city_ascii": "Memphis",
    "lat": 27.5435,
    "lng": -82.5607,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 8788,
    "id": 1840014172
  },
  {
    "city": "Lower Swatara",
    "city_ascii": "Lower Swatara",
    "lat": 40.2188,
    "lng": -76.7602,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 8788,
    "id": 1840149542
  },
  {
    "city": "Iernut",
    "city_ascii": "Iernut",
    "lat": 46.4533,
    "lng": 24.2333,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Mureş",
    "capital": "",
    "population": 8705,
    "id": 1642578622
  },
  {
    "city": "Seffner",
    "city_ascii": "Seffner",
    "lat": 27.9981,
    "lng": -82.2735,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 8786,
    "id": 1840014153
  },
  {
    "city": "Airmont",
    "city_ascii": "Airmont",
    "lat": 41.0992,
    "lng": -74.099,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 8786,
    "id": 1840004991
  },
  {
    "city": "Quakertown",
    "city_ascii": "Quakertown",
    "lat": 40.4398,
    "lng": -75.3456,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 8784,
    "id": 1840001310
  },
  {
    "city": "Walmer",
    "city_ascii": "Walmer",
    "lat": 51.2043,
    "lng": 1.3985,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Kent",
    "capital": "",
    "population": 8178,
    "id": 1826830845
  },
  {
    "city": "Villas",
    "city_ascii": "Villas",
    "lat": 39.0158,
    "lng": -74.9349,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 8783,
    "id": 1840005869
  },
  {
    "city": "Skidaway Island",
    "city_ascii": "Skidaway Island",
    "lat": 31.9372,
    "lng": -81.0449,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 8783,
    "id": 1840029458
  },
  {
    "city": "Smiths Falls",
    "city_ascii": "Smiths Falls",
    "lat": 44.9,
    "lng": -76.0167,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 8780,
    "id": 1124233827
  },
  {
    "city": "Sans Souci",
    "city_ascii": "Sans Souci",
    "lat": 34.8901,
    "lng": -82.4241,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 8776,
    "id": 1840013497
  },
  {
    "city": "Seneca Falls",
    "city_ascii": "Seneca Falls",
    "lat": 42.9136,
    "lng": -76.7904,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 8774,
    "id": 1840058484
  },
  {
    "city": "Azandarīān",
    "city_ascii": "Azandarian",
    "lat": 34.5089,
    "lng": 48.6867,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Hamadān",
    "capital": "",
    "population": 8685,
    "id": 1364125839
  },
  {
    "city": "Mücheln",
    "city_ascii": "Mucheln",
    "lat": 51.3,
    "lng": 11.8,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony-Anhalt",
    "capital": "",
    "population": 8681,
    "id": 1276286774
  },
  {
    "city": "South Kensington",
    "city_ascii": "South Kensington",
    "lat": 39.0188,
    "lng": -77.0785,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 8769,
    "id": 1840031497
  },
  {
    "city": "Jesús Nazareno",
    "city_ascii": "Jesus Nazareno",
    "lat": -32.9111,
    "lng": -68.79,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Mendoza",
    "capital": "",
    "population": 6875,
    "id": 1032795161
  },
  {
    "city": "Berching",
    "city_ascii": "Berching",
    "lat": 49.1064,
    "lng": 11.4394,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 8702,
    "id": 1276699990
  },
  {
    "city": "O'Hara",
    "city_ascii": "O'Hara",
    "lat": 40.5092,
    "lng": -79.895,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 8768,
    "id": 1840146056
  },
  {
    "city": "Upholland",
    "city_ascii": "Upholland",
    "lat": 53.541,
    "lng": -2.728,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Lancashire",
    "capital": "",
    "population": 7376,
    "id": 1826100533
  },
  {
    "city": "Wharton",
    "city_ascii": "Wharton",
    "lat": 29.3138,
    "lng": -96.1044,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 8765,
    "id": 1840022229
  },
  {
    "city": "Rathdrum",
    "city_ascii": "Rathdrum",
    "lat": 47.7995,
    "lng": -116.8908,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Idaho",
    "capital": "",
    "population": 8764,
    "id": 1840019812
  },
  {
    "city": "Hajdúdorog",
    "city_ascii": "Hajdudorog",
    "lat": 47.8167,
    "lng": 21.5,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Hajdú-Bihar",
    "capital": "",
    "population": 8677,
    "id": 1348352479
  },
  {
    "city": "Topsham",
    "city_ascii": "Topsham",
    "lat": 43.9615,
    "lng": -69.9588,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maine",
    "capital": "",
    "population": 8763,
    "id": 1840053028
  },
  {
    "city": "Calbe",
    "city_ascii": "Calbe",
    "lat": 51.9033,
    "lng": 11.7758,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony-Anhalt",
    "capital": "",
    "population": 8609,
    "id": 1276644969
  },
  {
    "city": "Jackson Township",
    "city_ascii": "Jackson Township",
    "lat": 40.3774,
    "lng": -76.3142,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 8760,
    "id": 1840143432
  },
  {
    "city": "Canonsburg",
    "city_ascii": "Canonsburg",
    "lat": 40.2643,
    "lng": -80.1868,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 8760,
    "id": 1840003643
  },
  {
    "city": "Edgewood",
    "city_ascii": "Edgewood",
    "lat": 39.0091,
    "lng": -84.5604,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kentucky",
    "capital": "",
    "population": 8759,
    "id": 1840013166
  },
  {
    "city": "Miramar Beach",
    "city_ascii": "Miramar Beach",
    "lat": 30.3854,
    "lng": -86.3443,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 8758,
    "id": 1840013925
  },
  {
    "city": "Graham",
    "city_ascii": "Graham",
    "lat": 33.1017,
    "lng": -98.5778,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 8755,
    "id": 1840020668
  },
  {
    "city": "Hainichen",
    "city_ascii": "Hainichen",
    "lat": 50.9697,
    "lng": 13.1253,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 8588,
    "id": 1276053815
  },
  {
    "city": "Needles",
    "city_ascii": "Needles",
    "lat": 34.8164,
    "lng": -114.6189,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 8752,
    "id": 1840020409
  },
  {
    "city": "Clarendon Hills",
    "city_ascii": "Clarendon Hills",
    "lat": 41.7981,
    "lng": -87.9569,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 8752,
    "id": 1840010170
  },
  {
    "city": "Porkhov",
    "city_ascii": "Porkhov",
    "lat": 57.7667,
    "lng": 29.55,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Pskovskaya Oblast’",
    "capital": "",
    "population": 8743,
    "id": 1643988613
  },
  {
    "city": "Vásárosnamény",
    "city_ascii": "Vasarosnameny",
    "lat": 48.1267,
    "lng": 22.3183,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Szabolcs-Szatmár-Bereg",
    "capital": "minor",
    "population": 8618,
    "id": 1348707721
  },
  {
    "city": "Norton Canes",
    "city_ascii": "Norton Canes",
    "lat": 52.6833,
    "lng": -1.9666,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Staffordshire",
    "capital": "",
    "population": 7479,
    "id": 1826796020
  },
  {
    "city": "Ripponden",
    "city_ascii": "Ripponden",
    "lat": 53.672,
    "lng": -1.946,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Calderdale",
    "capital": "",
    "population": 7421,
    "id": 1826594774
  },
  {
    "city": "Santa Lúcia",
    "city_ascii": "Santa Lucia",
    "lat": -21.685,
    "lng": -48.0839,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 8687,
    "id": 1076930150
  },
  {
    "city": "Volchansk",
    "city_ascii": "Volchansk",
    "lat": 59.9333,
    "lng": 60.05,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sverdlovskaya Oblast’",
    "capital": "",
    "population": 8722,
    "id": 1643386083
  },
  {
    "city": "Gremyachinsk",
    "city_ascii": "Gremyachinsk",
    "lat": 58.5667,
    "lng": 57.8333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Permskiy Kray",
    "capital": "",
    "population": 8732,
    "id": 1643585361
  },
  {
    "city": "Pitman",
    "city_ascii": "Pitman",
    "lat": 39.7335,
    "lng": -75.1306,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 8741,
    "id": 1840001521
  },
  {
    "city": "Makaha",
    "city_ascii": "Makaha",
    "lat": 21.4734,
    "lng": -158.2103,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Hawaii",
    "capital": "",
    "population": 8740,
    "id": 1840029523
  },
  {
    "city": "Rock Falls",
    "city_ascii": "Rock Falls",
    "lat": 41.7723,
    "lng": -89.6928,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 8740,
    "id": 1840009202
  },
  {
    "city": "Baraolt",
    "city_ascii": "Baraolt",
    "lat": 46.075,
    "lng": 25.6,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Covasna",
    "capital": "",
    "population": 8672,
    "id": 1642011195
  },
  {
    "city": "New Scotland",
    "city_ascii": "New Scotland",
    "lat": 42.6047,
    "lng": -73.9413,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 8739,
    "id": 1840058347
  },
  {
    "city": "Clayton",
    "city_ascii": "Clayton",
    "lat": 39.6627,
    "lng": -75.0782,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 8738,
    "id": 1840001520
  },
  {
    "city": "Perkasie",
    "city_ascii": "Perkasie",
    "lat": 40.3719,
    "lng": -75.292,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 8738,
    "id": 1840001309
  },
  {
    "city": "Moranbah",
    "city_ascii": "Moranbah",
    "lat": -22.0016,
    "lng": 148.0533,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Queensland",
    "capital": "",
    "population": 8735,
    "id": 1036916453
  },
  {
    "city": "Forbes",
    "city_ascii": "Forbes",
    "lat": -33.3833,
    "lng": 148.0167,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 8432,
    "id": 1036523834
  },
  {
    "city": "Sileby",
    "city_ascii": "Sileby",
    "lat": 52.731,
    "lng": -1.106,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Leicestershire",
    "capital": "",
    "population": 7835,
    "id": 1826945985
  },
  {
    "city": "Princeton",
    "city_ascii": "Princeton",
    "lat": 38.3553,
    "lng": -87.5784,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 8733,
    "id": 1840009801
  },
  {
    "city": "Rava-Rus’ka",
    "city_ascii": "Rava-Rus'ka",
    "lat": 50.25,
    "lng": 23.6167,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "L’vivs’ka Oblast’",
    "capital": "",
    "population": 8647,
    "id": 1804385058
  },
  {
    "city": "West Donegal",
    "city_ascii": "West Donegal",
    "lat": 40.1297,
    "lng": -76.6227,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 8732,
    "id": 1840142496
  },
  {
    "city": "Olney",
    "city_ascii": "Olney",
    "lat": 38.7285,
    "lng": -88.0838,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 8732,
    "id": 1840009783
  },
  {
    "city": "Barntrup",
    "city_ascii": "Barntrup",
    "lat": 51.9831,
    "lng": 9.1167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 8587,
    "id": 1276882548
  },
  {
    "city": "Hadleigh",
    "city_ascii": "Hadleigh",
    "lat": 52.044,
    "lng": 0.961,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Suffolk",
    "capital": "",
    "population": 8253,
    "id": 1826961283
  },
  {
    "city": "Edgewater Park",
    "city_ascii": "Edgewater Park",
    "lat": 40.054,
    "lng": -74.9117,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 8731,
    "id": 1840081632
  },
  {
    "city": "Hernando",
    "city_ascii": "Hernando",
    "lat": 28.9451,
    "lng": -82.3781,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 8727,
    "id": 1840014058
  },
  {
    "city": "Solotvyno",
    "city_ascii": "Solotvyno",
    "lat": 47.9597,
    "lng": 23.8669,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Zakarpats’ka Oblast’",
    "capital": "",
    "population": 8632,
    "id": 1804988039
  },
  {
    "city": "Kirby",
    "city_ascii": "Kirby",
    "lat": 29.4611,
    "lng": -98.3861,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 8723,
    "id": 1840020962
  },
  {
    "city": "Pont-Rouge",
    "city_ascii": "Pont-Rouge",
    "lat": 46.75,
    "lng": -71.7,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 8723,
    "id": 1124608325
  },
  {
    "city": "Staufenberg",
    "city_ascii": "Staufenberg",
    "lat": 50.6667,
    "lng": 8.7167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 8423,
    "id": 1276773596
  },
  {
    "city": "Tăşnad",
    "city_ascii": "Tasnad",
    "lat": 47.4772,
    "lng": 22.5833,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Satu Mare",
    "capital": "",
    "population": 8631,
    "id": 1642178252
  },
  {
    "city": "Szeghalom",
    "city_ascii": "Szeghalom",
    "lat": 47.0239,
    "lng": 21.1678,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Békés",
    "capital": "minor",
    "population": 8680,
    "id": 1348071592
  },
  {
    "city": "Fürstenfeld",
    "city_ascii": "Furstenfeld",
    "lat": 47.05,
    "lng": 16.0833,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Steiermark",
    "capital": "",
    "population": 8549,
    "id": 1040284464
  },
  {
    "city": "Tlanalapa",
    "city_ascii": "Tlanalapa",
    "lat": 19.8167,
    "lng": -98.6,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Hidalgo",
    "capital": "minor",
    "population": 8662,
    "id": 1484762044
  },
  {
    "city": "Mystic Island",
    "city_ascii": "Mystic Island",
    "lat": 39.5659,
    "lng": -74.3831,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 8717,
    "id": 1840005530
  },
  {
    "city": "Neuenkirchen",
    "city_ascii": "Neuenkirchen",
    "lat": 52.5167,
    "lng": 8.0667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 8621,
    "id": 1276221041
  },
  {
    "city": "Glenwood",
    "city_ascii": "Glenwood",
    "lat": 41.5409,
    "lng": -87.6116,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 8715,
    "id": 1840011281
  },
  {
    "city": "Maryborough",
    "city_ascii": "Maryborough",
    "lat": -37.05,
    "lng": 143.735,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Victoria",
    "capital": "",
    "population": 7921,
    "id": 1036781601
  },
  {
    "city": "Glenshaw",
    "city_ascii": "Glenshaw",
    "lat": 40.5391,
    "lng": -79.9735,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 8714,
    "id": 1840026474
  },
  {
    "city": "Garden City",
    "city_ascii": "Garden City",
    "lat": 32.0868,
    "lng": -81.1773,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 8713,
    "id": 1840013839
  },
  {
    "city": "Kirchen",
    "city_ascii": "Kirchen",
    "lat": 50.8086,
    "lng": 7.8833,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Rhineland-Palatinate",
    "capital": "",
    "population": 8498,
    "id": 1276153112
  },
  {
    "city": "Fairmount",
    "city_ascii": "Fairmount",
    "lat": 39.7931,
    "lng": -105.1712,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 8710,
    "id": 1840038703
  },
  {
    "city": "Monett",
    "city_ascii": "Monett",
    "lat": 36.922,
    "lng": -93.9261,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 8710,
    "id": 1840008797
  },
  {
    "city": "Jerseyville",
    "city_ascii": "Jerseyville",
    "lat": 39.1175,
    "lng": -90.3271,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 8708,
    "id": 1840008530
  },
  {
    "city": "Červený Kostelec",
    "city_ascii": "Cerveny Kostelec",
    "lat": 50.4763,
    "lng": 16.093,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Královéhradecký Kraj",
    "capital": "",
    "population": 8360,
    "id": 1203414080
  },
  {
    "city": "Champlain",
    "city_ascii": "Champlain",
    "lat": 45.5333,
    "lng": -74.65,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 8706,
    "id": 1124000537
  },
  {
    "city": "Perg",
    "city_ascii": "Perg",
    "lat": 48.2503,
    "lng": 14.6336,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Oberösterreich",
    "capital": "minor",
    "population": 8388,
    "id": 1040523501
  },
  {
    "city": "Citrusdal",
    "city_ascii": "Citrusdal",
    "lat": -32.5894,
    "lng": 19.0147,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "Western Cape",
    "capital": "",
    "population": 7177,
    "id": 1710007154
  },
  {
    "city": "Bay Minette",
    "city_ascii": "Bay Minette",
    "lat": 30.893,
    "lng": -87.7912,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 8704,
    "id": 1840000959
  },
  {
    "city": "Mount Vista",
    "city_ascii": "Mount Vista",
    "lat": 45.7373,
    "lng": -122.6315,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 8703,
    "id": 1840037858
  },
  {
    "city": "Westampton",
    "city_ascii": "Westampton",
    "lat": 40.0168,
    "lng": -74.8213,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 8703,
    "id": 1840081634
  },
  {
    "city": "Weida",
    "city_ascii": "Weida",
    "lat": 50.7667,
    "lng": 12.0667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Thuringia",
    "capital": "",
    "population": 8472,
    "id": 1276200949
  },
  {
    "city": "Bersenbrück",
    "city_ascii": "Bersenbruck",
    "lat": 52.5553,
    "lng": 7.9472,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 8501,
    "id": 1276969333
  },
  {
    "city": "Sečovce",
    "city_ascii": "Secovce",
    "lat": 48.7,
    "lng": 21.65,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Košický",
    "capital": "",
    "population": 8441,
    "id": 1703206081
  },
  {
    "city": "Băbeni",
    "city_ascii": "Babeni",
    "lat": 44.975,
    "lng": 24.2311,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Vâlcea",
    "capital": "",
    "population": 8451,
    "id": 1642822229
  },
  {
    "city": "Nevada",
    "city_ascii": "Nevada",
    "lat": 37.8445,
    "lng": -94.3492,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 8699,
    "id": 1840008690
  },
  {
    "city": "Coaticook",
    "city_ascii": "Coaticook",
    "lat": 45.1333,
    "lng": -71.8,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 8698,
    "id": 1124454176
  },
  {
    "city": "Des Peres",
    "city_ascii": "Des Peres",
    "lat": 38.5973,
    "lng": -90.448,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 8697,
    "id": 1840007456
  },
  {
    "city": "Raunds",
    "city_ascii": "Raunds",
    "lat": 52.345,
    "lng": -0.537,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Northamptonshire",
    "capital": "",
    "population": 8641,
    "id": 1826048317
  },
  {
    "city": "Corrales",
    "city_ascii": "Corrales",
    "lat": 35.2366,
    "lng": -106.6191,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Mexico",
    "capital": "",
    "population": 8696,
    "id": 1840022681
  },
  {
    "city": "Perryville",
    "city_ascii": "Perryville",
    "lat": 37.7263,
    "lng": -89.8759,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 8696,
    "id": 1840009871
  },
  {
    "city": "Park Ridge",
    "city_ascii": "Park Ridge",
    "lat": 41.0353,
    "lng": -74.0423,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 8694,
    "id": 1840000917
  },
  {
    "city": "Gresham Park",
    "city_ascii": "Gresham Park",
    "lat": 33.7053,
    "lng": -84.3155,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 8694,
    "id": 1840029454
  },
  {
    "city": "Uglegorsk",
    "city_ascii": "Uglegorsk",
    "lat": 49.0667,
    "lng": 142.0333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sakhalinskaya Oblast’",
    "capital": "",
    "population": 8687,
    "id": 1643572665
  },
  {
    "city": "Chikhli",
    "city_ascii": "Chikhli",
    "lat": 20.75,
    "lng": 73.07,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Gujarāt",
    "capital": "",
    "population": 6953,
    "id": 1356649674
  },
  {
    "city": "Ueckermünde",
    "city_ascii": "Ueckermunde",
    "lat": 53.7333,
    "lng": 14.0333,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Mecklenburg-Western Pomerania",
    "capital": "",
    "population": 8591,
    "id": 1276552736
  },
  {
    "city": "Pickering",
    "city_ascii": "Pickering",
    "lat": 54.2439,
    "lng": -0.776,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "North Yorkshire",
    "capital": "",
    "population": 6830,
    "id": 1826894099
  },
  {
    "city": "Waltham Cross",
    "city_ascii": "Waltham Cross",
    "lat": 51.686,
    "lng": -0.0331,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hertfordshire",
    "capital": "",
    "population": 7407,
    "id": 1826629009
  },
  {
    "city": "Kirn",
    "city_ascii": "Kirn",
    "lat": 49.7881,
    "lng": 7.4572,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Rhineland-Palatinate",
    "capital": "",
    "population": 8193,
    "id": 1276119778
  },
  {
    "city": "Marlborough",
    "city_ascii": "Marlborough",
    "lat": 41.6338,
    "lng": -73.9903,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 8686,
    "id": 1840087655
  },
  {
    "city": "Montesano",
    "city_ascii": "Montesano",
    "lat": 47.0102,
    "lng": -123.5857,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 8685,
    "id": 1840019847
  },
  {
    "city": "Chamonix-Mont-Blanc",
    "city_ascii": "Chamonix-Mont-Blanc",
    "lat": 45.9222,
    "lng": 6.8689,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Auvergne-Rhône-Alpes",
    "capital": "",
    "population": 8611,
    "id": 1250219507
  },
  {
    "city": "Bicaz",
    "city_ascii": "Bicaz",
    "lat": 46.9108,
    "lng": 26.0911,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Neamţ",
    "capital": "",
    "population": 8626,
    "id": 1642668954
  },
  {
    "city": "Succasunna",
    "city_ascii": "Succasunna",
    "lat": 40.8507,
    "lng": -74.6596,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 8681,
    "id": 1840024235
  },
  {
    "city": "Perl",
    "city_ascii": "Perl",
    "lat": 49.4667,
    "lng": 6.3667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saarland",
    "capital": "",
    "population": 8566,
    "id": 1276009165
  },
  {
    "city": "Zoutleeuw",
    "city_ascii": "Zoutleeuw",
    "lat": 50.8333,
    "lng": 5.1036,
    "country": "Belgium",
    "iso2": "BE",
    "iso3": "BEL",
    "admin_name": "Flanders",
    "capital": "",
    "population": 8498,
    "id": 1056468656
  },
  {
    "city": "Ballsh",
    "city_ascii": "Ballsh",
    "lat": 40.5991,
    "lng": 19.7359,
    "country": "Albania",
    "iso2": "AL",
    "iso3": "ALB",
    "admin_name": "Fier",
    "capital": "",
    "population": 7657,
    "id": 1008709957
  },
  {
    "city": "Clinton",
    "city_ascii": "Clinton",
    "lat": 38.3716,
    "lng": -93.7679,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 8679,
    "id": 1840007492
  },
  {
    "city": "Verkhnyaya Teberda",
    "city_ascii": "Verkhnyaya Teberda",
    "lat": 43.45,
    "lng": 41.75,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Karachayevo-Cherkesiya",
    "capital": "",
    "population": 8670,
    "id": 1643635404
  },
  {
    "city": "Bus’k",
    "city_ascii": "Bus'k",
    "lat": 49.9667,
    "lng": 24.6167,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "L’vivs’ka Oblast’",
    "capital": "minor",
    "population": 8624,
    "id": 1804314944
  },
  {
    "city": "Polegate",
    "city_ascii": "Polegate",
    "lat": 50.8216,
    "lng": 0.2442,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "East Sussex",
    "capital": "",
    "population": 8586,
    "id": 1826857740
  },
  {
    "city": "Großräschen",
    "city_ascii": "Grossraschen",
    "lat": 51.5831,
    "lng": 14,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Brandenburg",
    "capital": "",
    "population": 8572,
    "id": 1276514067
  },
  {
    "city": "Barvynkove",
    "city_ascii": "Barvynkove",
    "lat": 48.9067,
    "lng": 37.0131,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Kharkivs’ka Oblast’",
    "capital": "minor",
    "population": 8660,
    "id": 1804415450
  },
  {
    "city": "Claymont",
    "city_ascii": "Claymont",
    "lat": 39.8032,
    "lng": -75.4606,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Delaware",
    "capital": "",
    "population": 8676,
    "id": 1840005570
  },
  {
    "city": "Scott",
    "city_ascii": "Scott",
    "lat": 30.2398,
    "lng": -92.0947,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 8675,
    "id": 1840015932
  },
  {
    "city": "Stillwater",
    "city_ascii": "Stillwater",
    "lat": 42.9701,
    "lng": -73.6885,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 8675,
    "id": 1840004252
  },
  {
    "city": "Nádudvar",
    "city_ascii": "Nadudvar",
    "lat": 47.4167,
    "lng": 21.1667,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Hajdú-Bihar",
    "capital": "",
    "population": 8636,
    "id": 1348692293
  },
  {
    "city": "Newstead",
    "city_ascii": "Newstead",
    "lat": 43.0196,
    "lng": -78.5223,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 8674,
    "id": 1840087722
  },
  {
    "city": "Harlington",
    "city_ascii": "Harlington",
    "lat": 53.481,
    "lng": -1.182,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Doncaster",
    "capital": "",
    "population": 8276,
    "id": 1826923681
  },
  {
    "city": "Golden Valley",
    "city_ascii": "Golden Valley",
    "lat": 35.206,
    "lng": -114.2327,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 8673,
    "id": 1840018339
  },
  {
    "city": "Minto",
    "city_ascii": "Minto",
    "lat": 43.9167,
    "lng": -80.8667,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 8671,
    "id": 1124000198
  },
  {
    "city": "Dunaföldvár",
    "city_ascii": "Dunafoldvar",
    "lat": 46.8,
    "lng": 18.9167,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Tolna",
    "capital": "",
    "population": 8593,
    "id": 1348165944
  },
  {
    "city": "Binfield",
    "city_ascii": "Binfield",
    "lat": 51.432,
    "lng": -0.792,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Bracknell Forest",
    "capital": "",
    "population": 7880,
    "id": 1826891094
  },
  {
    "city": "Cresskill",
    "city_ascii": "Cresskill",
    "lat": 40.9405,
    "lng": -73.9596,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 8668,
    "id": 1840003539
  },
  {
    "city": "Morden",
    "city_ascii": "Morden",
    "lat": 49.1919,
    "lng": -98.1006,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Manitoba",
    "capital": "",
    "population": 8668,
    "id": 1124327817
  },
  {
    "city": "Hawthorn Woods",
    "city_ascii": "Hawthorn Woods",
    "lat": 42.2313,
    "lng": -88.0623,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 8666,
    "id": 1840011154
  },
  {
    "city": "Ferreira do Zêzere",
    "city_ascii": "Ferreira do Zezere",
    "lat": 39.7,
    "lng": -8.2833,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Santarém",
    "capital": "minor",
    "population": 8619,
    "id": 1620885794
  },
  {
    "city": "La Libertad",
    "city_ascii": "La Libertad",
    "lat": 16.7804,
    "lng": -90.12,
    "country": "Guatemala",
    "iso2": "GT",
    "iso3": "GTM",
    "admin_name": "Petén",
    "capital": "minor",
    "population": 8646,
    "id": 1320389989
  },
  {
    "city": "Tuba City",
    "city_ascii": "Tuba City",
    "lat": 36.125,
    "lng": -111.2468,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 8662,
    "id": 1840018972
  },
  {
    "city": "Reginópolis",
    "city_ascii": "Reginopolis",
    "lat": -21.8878,
    "lng": -49.225,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 8640,
    "id": 1076245325
  },
  {
    "city": "West Vero Corridor",
    "city_ascii": "West Vero Corridor",
    "lat": 27.6378,
    "lng": -80.4855,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 8661,
    "id": 1840073271
  },
  {
    "city": "Nishiōwa",
    "city_ascii": "Nishiowa",
    "lat": 36.022,
    "lng": 139.6543,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Saitama",
    "capital": "",
    "population": 6731,
    "id": 1392916319
  },
  {
    "city": "Bloomingdale",
    "city_ascii": "Bloomingdale",
    "lat": 36.5793,
    "lng": -82.5096,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 8660,
    "id": 1840013297
  },
  {
    "city": "Long Hill",
    "city_ascii": "Long Hill",
    "lat": 40.6838,
    "lng": -74.4878,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 8659,
    "id": 1840081726
  },
  {
    "city": "Klingenthal",
    "city_ascii": "Klingenthal",
    "lat": 50.3669,
    "lng": 12.4686,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 8365,
    "id": 1276346594
  },
  {
    "city": "Hoquiam",
    "city_ascii": "Hoquiam",
    "lat": 46.9863,
    "lng": -123.9022,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 8655,
    "id": 1840019845
  },
  {
    "city": "Tibro",
    "city_ascii": "Tibro",
    "lat": 58.419,
    "lng": 14.158,
    "country": "Sweden",
    "iso2": "SE",
    "iso3": "SWE",
    "admin_name": "Västra Götaland",
    "capital": "minor",
    "population": 8572,
    "id": 1752652082
  },
  {
    "city": "Penkridge",
    "city_ascii": "Penkridge",
    "lat": 52.7252,
    "lng": -2.1164,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Staffordshire",
    "capital": "",
    "population": 8526,
    "id": 1826440883
  },
  {
    "city": "Brigantine",
    "city_ascii": "Brigantine",
    "lat": 39.4138,
    "lng": -74.3787,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 8650,
    "id": 1840001533
  },
  {
    "city": "Teterow",
    "city_ascii": "Teterow",
    "lat": 53.7667,
    "lng": 12.5667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Mecklenburg-Western Pomerania",
    "capital": "",
    "population": 8470,
    "id": 1276267195
  },
  {
    "city": "Dobrada",
    "city_ascii": "Dobrada",
    "lat": -21.5167,
    "lng": -48.3939,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 8592,
    "id": 1076112653
  },
  {
    "city": "Georgetown",
    "city_ascii": "Georgetown",
    "lat": 42.724,
    "lng": -70.9821,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 8649,
    "id": 1840053667
  },
  {
    "city": "Kennedale",
    "city_ascii": "Kennedale",
    "lat": 32.6434,
    "lng": -97.2173,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 8645,
    "id": 1840019429
  },
  {
    "city": "Brightlingsea",
    "city_ascii": "Brightlingsea",
    "lat": 51.81,
    "lng": 1.03,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Essex",
    "capital": "",
    "population": 8076,
    "id": 1826794522
  },
  {
    "city": "Detroit Lakes",
    "city_ascii": "Detroit Lakes",
    "lat": 46.806,
    "lng": -95.8449,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 8642,
    "id": 1840006632
  },
  {
    "city": "Andrushivka",
    "city_ascii": "Andrushivka",
    "lat": 50.0167,
    "lng": 29.0167,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Zhytomyrs’ka Oblast’",
    "capital": "minor",
    "population": 8606,
    "id": 1804544646
  },
  {
    "city": "Matawan",
    "city_ascii": "Matawan",
    "lat": 40.4127,
    "lng": -74.2365,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 8640,
    "id": 1840003675
  },
  {
    "city": "Premnitz",
    "city_ascii": "Premnitz",
    "lat": 52.5331,
    "lng": 12.3331,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Brandenburg",
    "capital": "",
    "population": 8453,
    "id": 1276708634
  },
  {
    "city": "Monahans",
    "city_ascii": "Monahans",
    "lat": 31.6289,
    "lng": -103.0403,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 8639,
    "id": 1840036514
  },
  {
    "city": "Schuylkill",
    "city_ascii": "Schuylkill",
    "lat": 40.1086,
    "lng": -75.4982,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 8639,
    "id": 1840141676
  },
  {
    "city": "Frankfort Square",
    "city_ascii": "Frankfort Square",
    "lat": 41.5221,
    "lng": -87.8033,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 8638,
    "id": 1840029897
  },
  {
    "city": "Mount Pleasant",
    "city_ascii": "Mount Pleasant",
    "lat": 40.9625,
    "lng": -91.5452,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Iowa",
    "capital": "",
    "population": 8635,
    "id": 1840000888
  },
  {
    "city": "Marshall",
    "city_ascii": "Marshall",
    "lat": 40.6453,
    "lng": -80.11,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 8634,
    "id": 1840146796
  },
  {
    "city": "Santa Eulalia",
    "city_ascii": "Santa Eulalia",
    "lat": 28.5939,
    "lng": -105.8878,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Chihuahua",
    "capital": "minor",
    "population": 7135,
    "id": 1484258446
  },
  {
    "city": "Edgemere",
    "city_ascii": "Edgemere",
    "lat": 39.2273,
    "lng": -76.459,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 8633,
    "id": 1840005674
  },
  {
    "city": "Springwood",
    "city_ascii": "Springwood",
    "lat": -33.6967,
    "lng": 150.5683,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 8475,
    "id": 1036106857
  },
  {
    "city": "Nefasīt",
    "city_ascii": "Nefasit",
    "lat": 15.3333,
    "lng": 39.0619,
    "country": "Eritrea",
    "iso2": "ER",
    "iso3": "ERI",
    "admin_name": "Semēnawī K’eyih Bahrī",
    "capital": "",
    "population": 8600,
    "id": 1232567577
  },
  {
    "city": "Ikata-chō",
    "city_ascii": "Ikata-cho",
    "lat": 33.4883,
    "lng": 132.3542,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Ehime",
    "capital": "minor",
    "population": 8540,
    "id": 1392208267
  },
  {
    "city": "Cortez",
    "city_ascii": "Cortez",
    "lat": 37.3505,
    "lng": -108.5766,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 8630,
    "id": 1840018928
  },
  {
    "city": "Laishevo",
    "city_ascii": "Laishevo",
    "lat": 55.4,
    "lng": 49.55,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Tatarstan",
    "capital": "",
    "population": 8604,
    "id": 1643137529
  },
  {
    "city": "Oppenheim",
    "city_ascii": "Oppenheim",
    "lat": 49.8556,
    "lng": 8.3603,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Rhineland-Palatinate",
    "capital": "",
    "population": 7562,
    "id": 1276015793
  },
  {
    "city": "Ustyuzhna",
    "city_ascii": "Ustyuzhna",
    "lat": 58.8333,
    "lng": 36.4333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Vologodskaya Oblast’",
    "capital": "",
    "population": 8622,
    "id": 1643553144
  },
  {
    "city": "Prairie du Sac",
    "city_ascii": "Prairie du Sac",
    "lat": 43.292,
    "lng": -89.7353,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 8627,
    "id": 1840002726
  },
  {
    "city": "Hampstead",
    "city_ascii": "Hampstead",
    "lat": 42.8821,
    "lng": -71.1709,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Hampshire",
    "capital": "",
    "population": 8625,
    "id": 1840054954
  },
  {
    "city": "Lützen",
    "city_ascii": "Lutzen",
    "lat": 51.2583,
    "lng": 12.1417,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony-Anhalt",
    "capital": "",
    "population": 8546,
    "id": 1276691830
  },
  {
    "city": "York",
    "city_ascii": "York",
    "lat": 34.9967,
    "lng": -81.234,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 8623,
    "id": 1840015489
  },
  {
    "city": "Salem",
    "city_ascii": "Salem",
    "lat": 40.054,
    "lng": -111.672,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Utah",
    "capital": "",
    "population": 8621,
    "id": 1840021395
  },
  {
    "city": "Highfields",
    "city_ascii": "Highfields",
    "lat": -27.4581,
    "lng": 151.9492,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Queensland",
    "capital": "",
    "population": 8131,
    "id": 1036399510
  },
  {
    "city": "Paddock Wood",
    "city_ascii": "Paddock Wood",
    "lat": 51.1756,
    "lng": 0.3955,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Kent",
    "capital": "",
    "population": 8263,
    "id": 1826300495
  },
  {
    "city": "Byureghavan",
    "city_ascii": "Byureghavan",
    "lat": 40.3147,
    "lng": 44.5936,
    "country": "Armenia",
    "iso2": "AM",
    "iso3": "ARM",
    "admin_name": "Kotayk’",
    "capital": "",
    "population": 8500,
    "id": 1051696950
  },
  {
    "city": "Hyrum",
    "city_ascii": "Hyrum",
    "lat": 41.6311,
    "lng": -111.8418,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Utah",
    "capital": "",
    "population": 8619,
    "id": 1840018699
  },
  {
    "city": "Akhmet’a",
    "city_ascii": "Akhmet'a",
    "lat": 42.0333,
    "lng": 45.2,
    "country": "Georgia",
    "iso2": "GE",
    "iso3": "GEO",
    "admin_name": "K’akheti",
    "capital": "minor",
    "population": 8600,
    "id": 1268885821
  },
  {
    "city": "Lipki",
    "city_ascii": "Lipki",
    "lat": 53.95,
    "lng": 37.7,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Tul’skaya Oblast’",
    "capital": "",
    "population": 8541,
    "id": 1643393830
  },
  {
    "city": "Richlands",
    "city_ascii": "Richlands",
    "lat": 37.0878,
    "lng": -81.808,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 8618,
    "id": 1840006454
  },
  {
    "city": "Schieder-Schwalenberg",
    "city_ascii": "Schieder-Schwalenberg",
    "lat": 51.8831,
    "lng": 9.1831,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 8475,
    "id": 1276403786
  },
  {
    "city": "Rockland",
    "city_ascii": "Rockland",
    "lat": 44.1274,
    "lng": -69.1369,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maine",
    "capital": "",
    "population": 8616,
    "id": 1840000322
  },
  {
    "city": "Ladue",
    "city_ascii": "Ladue",
    "lat": 38.6378,
    "lng": -90.3815,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 8616,
    "id": 1840008599
  },
  {
    "city": "Caerleon",
    "city_ascii": "Caerleon",
    "lat": 51.615,
    "lng": -2.959,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Newport",
    "capital": "",
    "population": 8061,
    "id": 1826946689
  },
  {
    "city": "London Grove",
    "city_ascii": "London Grove",
    "lat": 39.8327,
    "lng": -75.8155,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 8615,
    "id": 1840151981
  },
  {
    "city": "Verkhoturye",
    "city_ascii": "Verkhoturye",
    "lat": 58.8667,
    "lng": 60.8,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sverdlovskaya Oblast’",
    "capital": "",
    "population": 8612,
    "id": 1643322280
  },
  {
    "city": "Simmern",
    "city_ascii": "Simmern",
    "lat": 49.9833,
    "lng": 7.5167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Rhineland-Palatinate",
    "capital": "minor",
    "population": 7950,
    "id": 1276700169
  },
  {
    "city": "Kosiv",
    "city_ascii": "Kosiv",
    "lat": 48.315,
    "lng": 25.0953,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Ivano-Frankivs’ka Oblast’",
    "capital": "minor",
    "population": 8522,
    "id": 1804417553
  },
  {
    "city": "Alamo Heights",
    "city_ascii": "Alamo Heights",
    "lat": 29.4828,
    "lng": -98.4682,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 8614,
    "id": 1840019653
  },
  {
    "city": "Pleasant Hill",
    "city_ascii": "Pleasant Hill",
    "lat": 38.8061,
    "lng": -94.2655,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 8613,
    "id": 1840009786
  },
  {
    "city": "Montecito",
    "city_ascii": "Montecito",
    "lat": 34.4382,
    "lng": -119.6286,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 8611,
    "id": 1840019182
  },
  {
    "city": "Lauta",
    "city_ascii": "Lauta",
    "lat": 51.4481,
    "lng": 14.0997,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Brandenburg",
    "capital": "",
    "population": 8411,
    "id": 1276023779
  },
  {
    "city": "Orting",
    "city_ascii": "Orting",
    "lat": 47.0966,
    "lng": -122.2111,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 8610,
    "id": 1840019858
  },
  {
    "city": "Mono",
    "city_ascii": "Mono",
    "lat": 44.0167,
    "lng": -80.0667,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 8609,
    "id": 1124001904
  },
  {
    "city": "Kiuruvesi",
    "city_ascii": "Kiuruvesi",
    "lat": 63.6528,
    "lng": 26.6194,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Pohjois-Savo",
    "capital": "minor",
    "population": 8600,
    "id": 1246969515
  },
  {
    "city": "Signal Mountain",
    "city_ascii": "Signal Mountain",
    "lat": 35.1449,
    "lng": -85.3457,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 8606,
    "id": 1840017859
  },
  {
    "city": "Goundam",
    "city_ascii": "Goundam",
    "lat": 16.417,
    "lng": -3.6666,
    "country": "Mali",
    "iso2": "ML",
    "iso3": "MLI",
    "admin_name": "Tombouctou",
    "capital": "minor",
    "population": 8456,
    "id": 1466655191
  },
  {
    "city": "Corning",
    "city_ascii": "Corning",
    "lat": 39.9282,
    "lng": -122.182,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 8604,
    "id": 1840018762
  },
  {
    "city": "Woodbury",
    "city_ascii": "Woodbury",
    "lat": 40.8177,
    "lng": -73.4703,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 8603,
    "id": 1840150821
  },
  {
    "city": "Orrville",
    "city_ascii": "Orrville",
    "lat": 40.848,
    "lng": -81.7743,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 8603,
    "id": 1840009371
  },
  {
    "city": "Evergreen",
    "city_ascii": "Evergreen",
    "lat": 39.6349,
    "lng": -105.3356,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 8602,
    "id": 1840017539
  },
  {
    "city": "Susanville",
    "city_ascii": "Susanville",
    "lat": 40.4205,
    "lng": -120.6129,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 8602,
    "id": 1840021348
  },
  {
    "city": "Middlebury",
    "city_ascii": "Middlebury",
    "lat": 44.0043,
    "lng": -73.1223,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Vermont",
    "capital": "",
    "population": 8600,
    "id": 1840070864
  },
  {
    "city": "Elsmere",
    "city_ascii": "Elsmere",
    "lat": 38.9949,
    "lng": -84.6017,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kentucky",
    "capital": "",
    "population": 8595,
    "id": 1840013167
  },
  {
    "city": "Aksakovo",
    "city_ascii": "Aksakovo",
    "lat": 43.2592,
    "lng": 27.8175,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Varna",
    "capital": "",
    "population": 8164,
    "id": 1100727470
  },
  {
    "city": "Farmville",
    "city_ascii": "Farmville",
    "lat": 37.2959,
    "lng": -78.4002,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 8594,
    "id": 1840006443
  },
  {
    "city": "Wissen",
    "city_ascii": "Wissen",
    "lat": 50.7825,
    "lng": 7.735,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Rhineland-Palatinate",
    "capital": "",
    "population": 8354,
    "id": 1276756362
  },
  {
    "city": "Solebury",
    "city_ascii": "Solebury",
    "lat": 40.3676,
    "lng": -75.0031,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 8593,
    "id": 1840101286
  },
  {
    "city": "Sheridan",
    "city_ascii": "Sheridan",
    "lat": 45.096,
    "lng": -123.3983,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 8590,
    "id": 1840021220
  },
  {
    "city": "Innisfail",
    "city_ascii": "Innisfail",
    "lat": -17.5236,
    "lng": 146.0292,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Queensland",
    "capital": "",
    "population": 7236,
    "id": 1036930229
  },
  {
    "city": "Torre de Moncorvo",
    "city_ascii": "Torre de Moncorvo",
    "lat": 41.2,
    "lng": -7.1333,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Bragança",
    "capital": "minor",
    "population": 8572,
    "id": 1620001140
  },
  {
    "city": "Rutland",
    "city_ascii": "Rutland",
    "lat": 42.3848,
    "lng": -71.9673,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 8586,
    "id": 1840053603
  },
  {
    "city": "Borgentreich",
    "city_ascii": "Borgentreich",
    "lat": 51.5692,
    "lng": 9.2411,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 8523,
    "id": 1276811823
  },
  {
    "city": "Richmond",
    "city_ascii": "Richmond",
    "lat": 54.403,
    "lng": -1.737,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "North Yorkshire",
    "capital": "",
    "population": 8413,
    "id": 1826225909
  },
  {
    "city": "Simeonovgrad",
    "city_ascii": "Simeonovgrad",
    "lat": 42.0344,
    "lng": 25.8319,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Haskovo",
    "capital": "",
    "population": 8539,
    "id": 1100988549
  },
  {
    "city": "Perry Heights",
    "city_ascii": "Perry Heights",
    "lat": 40.7977,
    "lng": -81.468,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 8581,
    "id": 1840005201
  },
  {
    "city": "Strathaven",
    "city_ascii": "Strathaven",
    "lat": 55.677,
    "lng": -4.065,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "South Lanarkshire",
    "capital": "",
    "population": 7484,
    "id": 1826467940
  },
  {
    "city": "Willowbrook",
    "city_ascii": "Willowbrook",
    "lat": 41.7635,
    "lng": -87.9456,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 8579,
    "id": 1840011409
  },
  {
    "city": "Irondale",
    "city_ascii": "Irondale",
    "lat": 33.4773,
    "lng": -84.36,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 8578,
    "id": 1840029456
  },
  {
    "city": "Whitworth",
    "city_ascii": "Whitworth",
    "lat": 53.663,
    "lng": -2.177,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Lancashire",
    "capital": "",
    "population": 7500,
    "id": 1826578540
  },
  {
    "city": "Springfield",
    "city_ascii": "Springfield",
    "lat": 30.1713,
    "lng": -85.6089,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 8577,
    "id": 1840015923
  },
  {
    "city": "Green Cove Springs",
    "city_ascii": "Green Cove Springs",
    "lat": 29.9904,
    "lng": -81.6807,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 8577,
    "id": 1840013999
  },
  {
    "city": "Kellinghusen",
    "city_ascii": "Kellinghusen",
    "lat": 53.95,
    "lng": 9.7167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Schleswig-Holstein",
    "capital": "",
    "population": 8142,
    "id": 1276296041
  },
  {
    "city": "Rhôs-on-Sea",
    "city_ascii": "Rhos-on-Sea",
    "lat": 53.309,
    "lng": -3.738,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Conwy",
    "capital": "",
    "population": 7593,
    "id": 1826830987
  },
  {
    "city": "Lake Villa",
    "city_ascii": "Lake Villa",
    "lat": 42.4184,
    "lng": -88.0836,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 8573,
    "id": 1840011159
  },
  {
    "city": "North Madison",
    "city_ascii": "North Madison",
    "lat": 41.8298,
    "lng": -81.0511,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 8573,
    "id": 1840004801
  },
  {
    "city": "Colditz",
    "city_ascii": "Colditz",
    "lat": 51.1167,
    "lng": 12.8167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 8472,
    "id": 1276177121
  },
  {
    "city": "Guntersville",
    "city_ascii": "Guntersville",
    "lat": 34.367,
    "lng": -86.2638,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 8571,
    "id": 1840006456
  },
  {
    "city": "Lansdowne",
    "city_ascii": "Lansdowne",
    "lat": 39.2365,
    "lng": -76.6659,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 8571,
    "id": 1840026625
  },
  {
    "city": "Karumai",
    "city_ascii": "Karumai",
    "lat": 40.3267,
    "lng": 141.4606,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Iwate",
    "capital": "",
    "population": 8536,
    "id": 1392014962
  },
  {
    "city": "Montvale",
    "city_ascii": "Montvale",
    "lat": 41.0529,
    "lng": -74.0499,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 8570,
    "id": 1840000906
  },
  {
    "city": "Aspen",
    "city_ascii": "Aspen",
    "lat": 39.195,
    "lng": -106.8369,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 8569,
    "id": 1840018813
  },
  {
    "city": "Joane",
    "city_ascii": "Joane",
    "lat": 41.4333,
    "lng": -8.4167,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Braga",
    "capital": "",
    "population": 7528,
    "id": 1620704080
  },
  {
    "city": "Brooksville",
    "city_ascii": "Brooksville",
    "lat": 28.5404,
    "lng": -82.3903,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 8564,
    "id": 1840014112
  },
  {
    "city": "Fairfield Glade",
    "city_ascii": "Fairfield Glade",
    "lat": 36.0028,
    "lng": -84.8711,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 8564,
    "id": 1840013067
  },
  {
    "city": "Sanatoga",
    "city_ascii": "Sanatoga",
    "lat": 40.2498,
    "lng": -75.5886,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 8564,
    "id": 1840035186
  },
  {
    "city": "Riverside",
    "city_ascii": "Riverside",
    "lat": 41.831,
    "lng": -87.8169,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 8563,
    "id": 1840011287
  },
  {
    "city": "Wyoming",
    "city_ascii": "Wyoming",
    "lat": 39.2297,
    "lng": -84.4816,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 8562,
    "id": 1840001619
  },
  {
    "city": "Staufen im Breisgau",
    "city_ascii": "Staufen im Breisgau",
    "lat": 47.8814,
    "lng": 7.7314,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 8209,
    "id": 1276961822
  },
  {
    "city": "West Frankfort",
    "city_ascii": "West Frankfort",
    "lat": 37.8997,
    "lng": -88.9301,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 8561,
    "id": 1840010833
  },
  {
    "city": "Chos Malal",
    "city_ascii": "Chos Malal",
    "lat": -37.383,
    "lng": -70.2666,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Neuquén",
    "capital": "minor",
    "population": 8556,
    "id": 1032817076
  },
  {
    "city": "Mamurras",
    "city_ascii": "Mamurras",
    "lat": 41.5775,
    "lng": 19.6922,
    "country": "Albania",
    "iso2": "AL",
    "iso3": "ALB",
    "admin_name": "Lezhë",
    "capital": "",
    "population": 8282,
    "id": 1008549519
  },
  {
    "city": "Pochinok",
    "city_ascii": "Pochinok",
    "lat": 54.4,
    "lng": 32.45,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Smolenskaya Oblast’",
    "capital": "",
    "population": 8545,
    "id": 1643214179
  },
  {
    "city": "Indian Harbour Beach",
    "city_ascii": "Indian Harbour Beach",
    "lat": 28.153,
    "lng": -80.5976,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 8557,
    "id": 1840015096
  },
  {
    "city": "Dzhebel",
    "city_ascii": "Dzhebel",
    "lat": 41.5,
    "lng": 25.3,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Kardzhali",
    "capital": "minor",
    "population": 8514,
    "id": 1100504854
  },
  {
    "city": "Helmbrechts",
    "city_ascii": "Helmbrechts",
    "lat": 50.2368,
    "lng": 11.7159,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 8413,
    "id": 1276467392
  },
  {
    "city": "Lake Park",
    "city_ascii": "Lake Park",
    "lat": 26.7998,
    "lng": -80.068,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 8556,
    "id": 1840017258
  },
  {
    "city": "Murten",
    "city_ascii": "Murten",
    "lat": 46.9281,
    "lng": 7.1169,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Fribourg",
    "capital": "",
    "population": 8222,
    "id": 1756274965
  },
  {
    "city": "Lanco",
    "city_ascii": "Lanco",
    "lat": -39.4523,
    "lng": -72.7755,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Los Ríos",
    "capital": "",
    "population": 8521,
    "id": 1152703909
  },
  {
    "city": "Kildare",
    "city_ascii": "Kildare",
    "lat": 53.1569,
    "lng": -6.909,
    "country": "Ireland",
    "iso2": "IE",
    "iso3": "IRL",
    "admin_name": "Kildare",
    "capital": "",
    "population": 7538,
    "id": 1372940357
  },
  {
    "city": "Lucas",
    "city_ascii": "Lucas",
    "lat": 33.0942,
    "lng": -96.5803,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 8553,
    "id": 1840020656
  },
  {
    "city": "Piedmont",
    "city_ascii": "Piedmont",
    "lat": 35.6784,
    "lng": -97.7528,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oklahoma",
    "capital": "",
    "population": 8551,
    "id": 1840020422
  },
  {
    "city": "Barton",
    "city_ascii": "Barton",
    "lat": 42.0812,
    "lng": -76.4982,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 8550,
    "id": 1840057223
  },
  {
    "city": "Franklin",
    "city_ascii": "Franklin",
    "lat": 36.6831,
    "lng": -76.9386,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 8549,
    "id": 1840003876
  },
  {
    "city": "Innsbrook",
    "city_ascii": "Innsbrook",
    "lat": 37.6552,
    "lng": -77.5775,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 8549,
    "id": 1840042829
  },
  {
    "city": "Chumsaeng",
    "city_ascii": "Chumsaeng",
    "lat": 15.898,
    "lng": 100.311,
    "country": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "admin_name": "Nakhon Sawan",
    "capital": "minor",
    "population": 8426,
    "id": 1764781477
  },
  {
    "city": "Obluchye",
    "city_ascii": "Obluchye",
    "lat": 49,
    "lng": 131.05,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Yevreyskaya Avtonomnaya Oblast’",
    "capital": "",
    "population": 8540,
    "id": 1643987123
  },
  {
    "city": "Conover",
    "city_ascii": "Conover",
    "lat": 35.7155,
    "lng": -81.217,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 8540,
    "id": 1840014522
  },
  {
    "city": "Coxsackie",
    "city_ascii": "Coxsackie",
    "lat": 42.3465,
    "lng": -73.8624,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 8540,
    "id": 1840004651
  },
  {
    "city": "Mourniés",
    "city_ascii": "Mournies",
    "lat": 35.485,
    "lng": 24.013,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Kríti",
    "capital": "",
    "population": 7553,
    "id": 1300122496
  },
  {
    "city": "Dulovo",
    "city_ascii": "Dulovo",
    "lat": 43.8225,
    "lng": 27.1406,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Silistra",
    "capital": "",
    "population": 8493,
    "id": 1100313030
  },
  {
    "city": "Litchfield",
    "city_ascii": "Litchfield",
    "lat": 42.843,
    "lng": -71.455,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Hampshire",
    "capital": "",
    "population": 8538,
    "id": 1840055112
  },
  {
    "city": "Mansfield",
    "city_ascii": "Mansfield",
    "lat": 40.0854,
    "lng": -74.7149,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 8538,
    "id": 1840081639
  },
  {
    "city": "Wetumpka",
    "city_ascii": "Wetumpka",
    "lat": 32.5407,
    "lng": -86.2052,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 8538,
    "id": 1840006013
  },
  {
    "city": "Lößnitz",
    "city_ascii": "Lossnitz",
    "lat": 50.6214,
    "lng": 12.7317,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 8267,
    "id": 1276284686
  },
  {
    "city": "Ladysmith",
    "city_ascii": "Ladysmith",
    "lat": 48.9975,
    "lng": -123.8203,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "British Columbia",
    "capital": "",
    "population": 8537,
    "id": 1124872385
  },
  {
    "city": "Conkal",
    "city_ascii": "Conkal",
    "lat": 21.0733,
    "lng": -89.5208,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Yucatán",
    "capital": "minor",
    "population": 7173,
    "id": 1484022909
  },
  {
    "city": "Laitila",
    "city_ascii": "Laitila",
    "lat": 60.8792,
    "lng": 21.6931,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Varsinais-Suomi",
    "capital": "minor",
    "population": 8520,
    "id": 1246064059
  },
  {
    "city": "Divjakë",
    "city_ascii": "Divjake",
    "lat": 40.9964,
    "lng": 19.5327,
    "country": "Albania",
    "iso2": "AL",
    "iso3": "ALB",
    "admin_name": "Fier",
    "capital": "",
    "population": 8445,
    "id": 1008489904
  },
  {
    "city": "East Grand Forks",
    "city_ascii": "East Grand Forks",
    "lat": 47.9284,
    "lng": -97.0138,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 8535,
    "id": 1840007700
  },
  {
    "city": "Bridgewater",
    "city_ascii": "Bridgewater",
    "lat": 44.37,
    "lng": -64.52,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Nova Scotia",
    "capital": "",
    "population": 8532,
    "id": 1124736310
  },
  {
    "city": "Krupki",
    "city_ascii": "Krupki",
    "lat": 54.325,
    "lng": 29.1361,
    "country": "Belarus",
    "iso2": "BY",
    "iso3": "BLR",
    "admin_name": "Minskaya Voblasts’",
    "capital": "minor",
    "population": 8500,
    "id": 1112668114
  },
  {
    "city": "Guaraçaí",
    "city_ascii": "Guaracai",
    "lat": -21.0283,
    "lng": -51.2067,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 8525,
    "id": 1076853685
  },
  {
    "city": "Park Hills",
    "city_ascii": "Park Hills",
    "lat": 37.8211,
    "lng": -90.505,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 8529,
    "id": 1840009857
  },
  {
    "city": "Meßkirch",
    "city_ascii": "Messkirch",
    "lat": 47.9928,
    "lng": 9.1125,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 8418,
    "id": 1276541566
  },
  {
    "city": "Laurel",
    "city_ascii": "Laurel",
    "lat": 45.6735,
    "lng": -108.7707,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Montana",
    "capital": "",
    "population": 8526,
    "id": 1840019900
  },
  {
    "city": "Cullompton",
    "city_ascii": "Cullompton",
    "lat": 50.855,
    "lng": -3.393,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Devon",
    "capital": "",
    "population": 8499,
    "id": 1826414120
  },
  {
    "city": "Freistadt",
    "city_ascii": "Freistadt",
    "lat": 48.5117,
    "lng": 14.5061,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Oberösterreich",
    "capital": "minor",
    "population": 7909,
    "id": 1040267648
  },
  {
    "city": "Mooroopna",
    "city_ascii": "Mooroopna",
    "lat": -36.3833,
    "lng": 145.35,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Victoria",
    "capital": "",
    "population": 7942,
    "id": 1036788714
  },
  {
    "city": "Rawlins",
    "city_ascii": "Rawlins",
    "lat": 41.7849,
    "lng": -107.2265,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wyoming",
    "capital": "",
    "population": 8521,
    "id": 1840020094
  },
  {
    "city": "Morrisville",
    "city_ascii": "Morrisville",
    "lat": 40.2074,
    "lng": -74.78,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 8521,
    "id": 1840001326
  },
  {
    "city": "Negreşti",
    "city_ascii": "Negresti",
    "lat": 46.8403,
    "lng": 27.4417,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Vaslui",
    "capital": "",
    "population": 8380,
    "id": 1642692069
  },
  {
    "city": "Odessa",
    "city_ascii": "Odessa",
    "lat": 28.182,
    "lng": -82.553,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 8517,
    "id": 1840014121
  },
  {
    "city": "Narrabri",
    "city_ascii": "Narrabri",
    "lat": -30.3317,
    "lng": 149.7678,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 7606,
    "id": 1036714493
  },
  {
    "city": "Plumsted",
    "city_ascii": "Plumsted",
    "lat": 40.0468,
    "lng": -74.4852,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 8514,
    "id": 1840081609
  },
  {
    "city": "White Horse",
    "city_ascii": "White Horse",
    "lat": 40.192,
    "lng": -74.7022,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 8512,
    "id": 1840005475
  },
  {
    "city": "Yatton",
    "city_ascii": "Yatton",
    "lat": 51.3855,
    "lng": -2.8256,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "North Somerset",
    "capital": "",
    "population": 7552,
    "id": 1826709629
  },
  {
    "city": "Closter",
    "city_ascii": "Closter",
    "lat": 40.9733,
    "lng": -73.9604,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 8511,
    "id": 1840003538
  },
  {
    "city": "Perryton",
    "city_ascii": "Perryton",
    "lat": 36.393,
    "lng": -100.7976,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 8510,
    "id": 1840020379
  },
  {
    "city": "St. Johns",
    "city_ascii": "St. Johns",
    "lat": 43.0005,
    "lng": -84.5555,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 8510,
    "id": 1840003076
  },
  {
    "city": "Wörrstadt",
    "city_ascii": "Worrstadt",
    "lat": 49.8431,
    "lng": 8.1156,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Rhineland-Palatinate",
    "capital": "",
    "population": 8027,
    "id": 1276807960
  },
  {
    "city": "Frostburg",
    "city_ascii": "Frostburg",
    "lat": 39.6506,
    "lng": -78.9269,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 8505,
    "id": 1840005622
  },
  {
    "city": "Kingscliff",
    "city_ascii": "Kingscliff",
    "lat": -28.2564,
    "lng": 153.5781,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 7464,
    "id": 1036796141
  },
  {
    "city": "Concord",
    "city_ascii": "Concord",
    "lat": 42.545,
    "lng": -78.7075,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 8504,
    "id": 1840058009
  },
  {
    "city": "Lacombe",
    "city_ascii": "Lacombe",
    "lat": 30.3141,
    "lng": -89.9311,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 8503,
    "id": 1840013942
  },
  {
    "city": "Lypovets’",
    "city_ascii": "Lypovets'",
    "lat": 49.2161,
    "lng": 29.0561,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Vinnyts’ka Oblast’",
    "capital": "minor",
    "population": 8463,
    "id": 1804026252
  },
  {
    "city": "Dunavarsány",
    "city_ascii": "Dunavarsany",
    "lat": 47.2828,
    "lng": 19.0661,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Pest",
    "capital": "",
    "population": 8141,
    "id": 1348821728
  },
  {
    "city": "Rolesville",
    "city_ascii": "Rolesville",
    "lat": 35.9224,
    "lng": -78.4656,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 8501,
    "id": 1840017759
  },
  {
    "city": "Yarmouth",
    "city_ascii": "Yarmouth",
    "lat": 43.7978,
    "lng": -70.1719,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maine",
    "capital": "",
    "population": 8501,
    "id": 1840053071
  },
  {
    "city": "Biryusinsk",
    "city_ascii": "Biryusinsk",
    "lat": 55.95,
    "lng": 97.8167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Irkutskaya Oblast’",
    "capital": "",
    "population": 8497,
    "id": 1643008672
  },
  {
    "city": "Bo‘ston",
    "city_ascii": "Bo`ston",
    "lat": 41.8478,
    "lng": 60.93,
    "country": "Uzbekistan",
    "iso2": "UZ",
    "iso3": "UZB",
    "admin_name": "Qoraqalpog‘iston",
    "capital": "",
    "population": 8488,
    "id": 1860795913
  },
  {
    "city": "Mina Clavero",
    "city_ascii": "Mina Clavero",
    "lat": -31.7239,
    "lng": -65.005,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Córdoba",
    "capital": "",
    "population": 8487,
    "id": 1032241641
  },
  {
    "city": "Viechtach",
    "city_ascii": "Viechtach",
    "lat": 49.0792,
    "lng": 12.8847,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 8364,
    "id": 1276786566
  },
  {
    "city": "Túrkeve",
    "city_ascii": "Turkeve",
    "lat": 47.1,
    "lng": 20.75,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Jász-Nagykun-Szolnok",
    "capital": "",
    "population": 8462,
    "id": 1348829322
  },
  {
    "city": "Neuenbürg",
    "city_ascii": "Neuenburg",
    "lat": 48.8461,
    "lng": 8.5889,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 8206,
    "id": 1276032527
  },
  {
    "city": "Vohburg an der Donau",
    "city_ascii": "Vohburg an der Donau",
    "lat": 48.7667,
    "lng": 11.6167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 8312,
    "id": 1276723735
  },
  {
    "city": "Southampton Township",
    "city_ascii": "Southampton Township",
    "lat": 40.0249,
    "lng": -77.546,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 8495,
    "id": 1840146372
  },
  {
    "city": "Incline Village",
    "city_ascii": "Incline Village",
    "lat": 39.2639,
    "lng": -119.9453,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Nevada",
    "capital": "",
    "population": 8491,
    "id": 1840033809
  },
  {
    "city": "Campo Maior",
    "city_ascii": "Campo Maior",
    "lat": 39.0167,
    "lng": -7.0667,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Portalegre",
    "capital": "minor",
    "population": 8456,
    "id": 1620866518
  },
  {
    "city": "San Sebastián",
    "city_ascii": "San Sebastian",
    "lat": 18.3356,
    "lng": -66.9949,
    "country": "Puerto Rico",
    "iso2": "PR",
    "iso3": "PRI",
    "admin_name": "Puerto Rico",
    "capital": "",
    "population": 8490,
    "id": 1630023635
  },
  {
    "city": "Bolgar",
    "city_ascii": "Bolgar",
    "lat": 54.9667,
    "lng": 49.0333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Tatarstan",
    "capital": "",
    "population": 8476,
    "id": 1643006914
  },
  {
    "city": "Visp",
    "city_ascii": "Visp",
    "lat": 46.2922,
    "lng": 7.8828,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Valais",
    "capital": "",
    "population": 7891,
    "id": 1756588096
  },
  {
    "city": "Namsos",
    "city_ascii": "Namsos",
    "lat": 64.467,
    "lng": 11.494,
    "country": "Norway",
    "iso2": "NO",
    "iso3": "NOR",
    "admin_name": "Nord-Trøndelag",
    "capital": "minor",
    "population": 8471,
    "id": 1578576567
  },
  {
    "city": "Billingshurst",
    "city_ascii": "Billingshurst",
    "lat": 51.0226,
    "lng": -0.4508,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "West Sussex",
    "capital": "",
    "population": 8232,
    "id": 1826124502
  },
  {
    "city": "Pocklington",
    "city_ascii": "Pocklington",
    "lat": 53.9309,
    "lng": -0.7781,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "East Riding of Yorkshire",
    "capital": "",
    "population": 8337,
    "id": 1826728939
  },
  {
    "city": "University of Virginia",
    "city_ascii": "University of Virginia",
    "lat": 38.0405,
    "lng": -78.5163,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 8487,
    "id": 1840140574
  },
  {
    "city": "Derecske",
    "city_ascii": "Derecske",
    "lat": 47.3537,
    "lng": 21.5718,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Hajdú-Bihar",
    "capital": "minor",
    "population": 8403,
    "id": 1348758778
  },
  {
    "city": "Eufaula",
    "city_ascii": "Eufaula",
    "lat": 31.9102,
    "lng": -85.1505,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 8484,
    "id": 1840001685
  },
  {
    "city": "Habo",
    "city_ascii": "Habo",
    "lat": 57.9066,
    "lng": 14.0856,
    "country": "Sweden",
    "iso2": "SE",
    "iso3": "SWE",
    "admin_name": "Jönköping",
    "capital": "minor",
    "population": 8455,
    "id": 1752321919
  },
  {
    "city": "Shinfield",
    "city_ascii": "Shinfield",
    "lat": 51.408,
    "lng": -0.947,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Wokingham",
    "capital": "",
    "population": 8136,
    "id": 1826735275
  },
  {
    "city": "Cedar Hills",
    "city_ascii": "Cedar Hills",
    "lat": 45.5047,
    "lng": -122.8051,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 8480,
    "id": 1840034716
  },
  {
    "city": "Kecel",
    "city_ascii": "Kecel",
    "lat": 46.525,
    "lng": 19.2547,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Bács-Kiskun",
    "capital": "",
    "population": 8406,
    "id": 1348688027
  },
  {
    "city": "Dock Junction",
    "city_ascii": "Dock Junction",
    "lat": 31.2031,
    "lng": -81.5156,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 8478,
    "id": 1840029453
  },
  {
    "city": "Milevsko",
    "city_ascii": "Milevsko",
    "lat": 49.451,
    "lng": 14.36,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Jihočeský Kraj",
    "capital": "",
    "population": 8280,
    "id": 1203487347
  },
  {
    "city": "Clinton",
    "city_ascii": "Clinton",
    "lat": 35.5058,
    "lng": -98.9724,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oklahoma",
    "capital": "",
    "population": 8475,
    "id": 1840019108
  },
  {
    "city": "Waterford",
    "city_ascii": "Waterford",
    "lat": 42.8044,
    "lng": -73.6917,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 8474,
    "id": 1840004253
  },
  {
    "city": "Cacalchen",
    "city_ascii": "Cacalchen",
    "lat": 20.9822,
    "lng": -89.2278,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Yucatán",
    "capital": "",
    "population": 6787,
    "id": 1484727616
  },
  {
    "city": "Middleton",
    "city_ascii": "Middleton",
    "lat": 43.7114,
    "lng": -116.6155,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Idaho",
    "capital": "",
    "population": 8466,
    "id": 1840020040
  },
  {
    "city": "Estrêla d’Oeste",
    "city_ascii": "Estrela d'Oeste",
    "lat": -20.2878,
    "lng": -50.4008,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 8462,
    "id": 1076949391
  },
  {
    "city": "Woodburn",
    "city_ascii": "Woodburn",
    "lat": 38.8503,
    "lng": -77.2322,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 8462,
    "id": 1840024584
  },
  {
    "city": "Ione",
    "city_ascii": "Ione",
    "lat": 38.3613,
    "lng": -120.9422,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 8462,
    "id": 1840018851
  },
  {
    "city": "Tuscumbia",
    "city_ascii": "Tuscumbia",
    "lat": 34.7204,
    "lng": -87.7035,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 8461,
    "id": 1840005567
  },
  {
    "city": "Vetluga",
    "city_ascii": "Vetluga",
    "lat": 57.8556,
    "lng": 45.7811,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Nizhegorodskaya Oblast’",
    "capital": "",
    "population": 8455,
    "id": 1643763895
  },
  {
    "city": "East York",
    "city_ascii": "East York",
    "lat": 39.9687,
    "lng": -76.6759,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 8459,
    "id": 1840034943
  },
  {
    "city": "As Sallūm",
    "city_ascii": "As Sallum",
    "lat": 31.5525,
    "lng": 25.1575,
    "country": "Egypt",
    "iso2": "EG",
    "iso3": "EGY",
    "admin_name": "Maţrūḩ",
    "capital": "",
    "population": 8445,
    "id": 1818639105
  },
  {
    "city": "Mason",
    "city_ascii": "Mason",
    "lat": 42.5806,
    "lng": -84.4427,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 8458,
    "id": 1840003104
  },
  {
    "city": "Dauphin",
    "city_ascii": "Dauphin",
    "lat": 51.1992,
    "lng": -100.0633,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Manitoba",
    "capital": "",
    "population": 8457,
    "id": 1124144510
  },
  {
    "city": "Humboldt",
    "city_ascii": "Humboldt",
    "lat": 35.8254,
    "lng": -88.9043,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 8456,
    "id": 1840014484
  },
  {
    "city": "Baiersdorf",
    "city_ascii": "Baiersdorf",
    "lat": 49.6564,
    "lng": 11.0328,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 7794,
    "id": 1276096763
  },
  {
    "city": "Lesmurdie",
    "city_ascii": "Lesmurdie",
    "lat": -31.994,
    "lng": 116.05,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Western Australia",
    "capital": "",
    "population": 8437,
    "id": 1036803976
  },
  {
    "city": "Kirchberg",
    "city_ascii": "Kirchberg",
    "lat": 50.6222,
    "lng": 12.5256,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 8242,
    "id": 1276566241
  },
  {
    "city": "Otterburn Park",
    "city_ascii": "Otterburn Park",
    "lat": 45.5333,
    "lng": -73.2167,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 8450,
    "id": 1124899409
  },
  {
    "city": "Cohasset",
    "city_ascii": "Cohasset",
    "lat": 42.2363,
    "lng": -70.8189,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 8449,
    "id": 1840053546
  },
  {
    "city": "Barby",
    "city_ascii": "Barby",
    "lat": 51.9667,
    "lng": 11.8667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony-Anhalt",
    "capital": "",
    "population": 8394,
    "id": 1276107276
  },
  {
    "city": "Bystřice pod Hostýnem",
    "city_ascii": "Bystrice pod Hostynem",
    "lat": 49.3992,
    "lng": 17.674,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Zlínský Kraj",
    "capital": "",
    "population": 8144,
    "id": 1203344046
  },
  {
    "city": "Sunset Hills",
    "city_ascii": "Sunset Hills",
    "lat": 38.531,
    "lng": -90.4087,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 8447,
    "id": 1840009742
  },
  {
    "city": "Sand Lake",
    "city_ascii": "Sand Lake",
    "lat": 42.6284,
    "lng": -73.5477,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 8444,
    "id": 1840058466
  },
  {
    "city": "Tobyhanna",
    "city_ascii": "Tobyhanna",
    "lat": 41.1113,
    "lng": -75.5211,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 8444,
    "id": 1840035224
  },
  {
    "city": "Ellicott",
    "city_ascii": "Ellicott",
    "lat": 42.133,
    "lng": -79.2361,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 8443,
    "id": 1840058075
  },
  {
    "city": "Zāzerān",
    "city_ascii": "Zazeran",
    "lat": 32.6025,
    "lng": 51.4969,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Eşfahān",
    "capital": "",
    "population": 7670,
    "id": 1364024070
  },
  {
    "city": "Neulengbach",
    "city_ascii": "Neulengbach",
    "lat": 48.1833,
    "lng": 15.9,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Niederösterreich",
    "capital": "",
    "population": 8281,
    "id": 1040795317
  },
  {
    "city": "Hanawa",
    "city_ascii": "Hanawa",
    "lat": 36.9572,
    "lng": 140.4097,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Fukushima",
    "capital": "",
    "population": 8401,
    "id": 1392769533
  },
  {
    "city": "West Point",
    "city_ascii": "West Point",
    "lat": 33.6064,
    "lng": -88.6571,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Mississippi",
    "capital": "",
    "population": 8440,
    "id": 1840015681
  },
  {
    "city": "Thompsonville",
    "city_ascii": "Thompsonville",
    "lat": 41.9916,
    "lng": -72.5965,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 8439,
    "id": 1840003267
  },
  {
    "city": "Zwingenberg",
    "city_ascii": "Zwingenberg",
    "lat": 49.7225,
    "lng": 8.6139,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 7171,
    "id": 1276925635
  },
  {
    "city": "Būlaevo",
    "city_ascii": "Bulaevo",
    "lat": 54.9056,
    "lng": 70.4439,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Soltüstik Qazaqstan",
    "capital": "",
    "population": 8433,
    "id": 1398659796
  },
  {
    "city": "Guabito",
    "city_ascii": "Guabito",
    "lat": 9.4949,
    "lng": -82.6117,
    "country": "Panama",
    "iso2": "PA",
    "iso3": "PAN",
    "admin_name": "Bocas del Toro",
    "capital": "minor",
    "population": 8387,
    "id": 1591317249
  },
  {
    "city": "Summit",
    "city_ascii": "Summit",
    "lat": 47.1694,
    "lng": -122.3628,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 8435,
    "id": 1840018456
  },
  {
    "city": "Onalaska",
    "city_ascii": "Onalaska",
    "lat": 30.8209,
    "lng": -95.1111,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 8435,
    "id": 1840020858
  },
  {
    "city": "West Haven-Sylvan",
    "city_ascii": "West Haven-Sylvan",
    "lat": 45.5164,
    "lng": -122.7654,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 8434,
    "id": 1840074715
  },
  {
    "city": "Măcin",
    "city_ascii": "Macin",
    "lat": 45.2456,
    "lng": 28.1231,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Tulcea",
    "capital": "",
    "population": 8245,
    "id": 1642034078
  },
  {
    "city": "Dover",
    "city_ascii": "Dover",
    "lat": 41.6839,
    "lng": -73.5739,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 8431,
    "id": 1840087382
  },
  {
    "city": "Taber",
    "city_ascii": "Taber",
    "lat": 49.7847,
    "lng": -112.1508,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Alberta",
    "capital": "",
    "population": 8428,
    "id": 1124113583
  },
  {
    "city": "Mahopac",
    "city_ascii": "Mahopac",
    "lat": 41.3684,
    "lng": -73.7401,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 8426,
    "id": 1840004891
  },
  {
    "city": "Los Altos Hills",
    "city_ascii": "Los Altos Hills",
    "lat": 37.3671,
    "lng": -122.139,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 8423,
    "id": 1840021574
  },
  {
    "city": "Witley",
    "city_ascii": "Witley",
    "lat": 51.15,
    "lng": -0.646,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Surrey",
    "capital": "",
    "population": 8130,
    "id": 1826484458
  },
  {
    "city": "Kamyanyets",
    "city_ascii": "Kamyanyets",
    "lat": 52.4028,
    "lng": 23.8083,
    "country": "Belarus",
    "iso2": "BY",
    "iso3": "BLR",
    "admin_name": "Brestskaya Voblasts’",
    "capital": "",
    "population": 8400,
    "id": 1112796625
  },
  {
    "city": "Citrus Springs",
    "city_ascii": "Citrus Springs",
    "lat": 28.9931,
    "lng": -82.4595,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 8421,
    "id": 1840014062
  },
  {
    "city": "Bushkill",
    "city_ascii": "Bushkill",
    "lat": 40.7976,
    "lng": -75.3281,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 8421,
    "id": 1840144379
  },
  {
    "city": "Yadrin",
    "city_ascii": "Yadrin",
    "lat": 55.95,
    "lng": 46.2,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Chuvashiya",
    "capital": "",
    "population": 8387,
    "id": 1643161672
  },
  {
    "city": "Jászapáti",
    "city_ascii": "Jaszapati",
    "lat": 47.5125,
    "lng": 20.1417,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Jász-Nagykun-Szolnok",
    "capital": "",
    "population": 8313,
    "id": 1348332602
  },
  {
    "city": "Sturgeon Bay",
    "city_ascii": "Sturgeon Bay",
    "lat": 44.8228,
    "lng": -87.366,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 8419,
    "id": 1840003935
  },
  {
    "city": "North Londonderry",
    "city_ascii": "North Londonderry",
    "lat": 40.3227,
    "lng": -76.5867,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 8419,
    "id": 1840149647
  },
  {
    "city": "Magtymguly",
    "city_ascii": "Magtymguly",
    "lat": 38.4333,
    "lng": 56.2833,
    "country": "Turkmenistan",
    "iso2": "TM",
    "iso3": "TKM",
    "admin_name": "Balkan",
    "capital": "minor",
    "population": 8412,
    "id": 1795795103
  },
  {
    "city": "Santa Clara",
    "city_ascii": "Santa Clara",
    "lat": 37.1311,
    "lng": -113.6561,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Utah",
    "capital": "",
    "population": 8417,
    "id": 1840021560
  },
  {
    "city": "Oranienbaum",
    "city_ascii": "Oranienbaum",
    "lat": 51.8036,
    "lng": 12.4064,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony-Anhalt",
    "capital": "",
    "population": 8344,
    "id": 1276228283
  },
  {
    "city": "East Brandywine",
    "city_ascii": "East Brandywine",
    "lat": 40.0364,
    "lng": -75.7505,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 8416,
    "id": 1840149883
  },
  {
    "city": "Monticello",
    "city_ascii": "Monticello",
    "lat": 40.7455,
    "lng": -86.7669,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 8416,
    "id": 1840008296
  },
  {
    "city": "South Bruce Peninsula",
    "city_ascii": "South Bruce Peninsula",
    "lat": 44.7333,
    "lng": -81.2,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 8416,
    "id": 1124000114
  },
  {
    "city": "Kitzbühel",
    "city_ascii": "Kitzbuhel",
    "lat": 47.4464,
    "lng": 12.3919,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Tirol",
    "capital": "minor",
    "population": 8272,
    "id": 1040301739
  },
  {
    "city": "Riverside",
    "city_ascii": "Riverside",
    "lat": 41.0319,
    "lng": -73.5827,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 8414,
    "id": 1840026286
  },
  {
    "city": "Edson",
    "city_ascii": "Edson",
    "lat": 53.5817,
    "lng": -116.4344,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Alberta",
    "capital": "",
    "population": 8414,
    "id": 1124553562
  },
  {
    "city": "Neunburg vorm Wald",
    "city_ascii": "Neunburg vorm Wald",
    "lat": 49.3467,
    "lng": 12.3908,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 8338,
    "id": 1276625156
  },
  {
    "city": "Bönnigheim",
    "city_ascii": "Bonnigheim",
    "lat": 49.0408,
    "lng": 9.095,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 8015,
    "id": 1276845983
  },
  {
    "city": "Seaton",
    "city_ascii": "Seaton",
    "lat": 50.705,
    "lng": -3.078,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Devon",
    "capital": "",
    "population": 8413,
    "id": 1826695306
  },
  {
    "city": "Gewanē",
    "city_ascii": "Gewane",
    "lat": 10.1664,
    "lng": 40.6453,
    "country": "Ethiopia",
    "iso2": "ET",
    "iso3": "ETH",
    "admin_name": "Āfar",
    "capital": "",
    "population": 8408,
    "id": 1231439257
  },
  {
    "city": "Congers",
    "city_ascii": "Congers",
    "lat": 41.1484,
    "lng": -73.9456,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 8406,
    "id": 1840004960
  },
  {
    "city": "Stuttgart",
    "city_ascii": "Stuttgart",
    "lat": 34.495,
    "lng": -91.5485,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arkansas",
    "capital": "",
    "population": 8404,
    "id": 1840015556
  },
  {
    "city": "Savoy",
    "city_ascii": "Savoy",
    "lat": 40.06,
    "lng": -88.2552,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 8401,
    "id": 1840012236
  },
  {
    "city": "Yuryevets",
    "city_ascii": "Yuryevets",
    "lat": 57.3167,
    "lng": 43.1,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Ivanovskaya Oblast’",
    "capital": "",
    "population": 8378,
    "id": 1643782980
  },
  {
    "city": "Jánoshalma",
    "city_ascii": "Janoshalma",
    "lat": 46.2967,
    "lng": 19.3228,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Bács-Kiskun",
    "capital": "minor",
    "population": 8336,
    "id": 1348268788
  },
  {
    "city": "Kahrīz Sang",
    "city_ascii": "Kahriz Sang",
    "lat": 32.6267,
    "lng": 51.4808,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Eşfahān",
    "capital": "",
    "population": 8267,
    "id": 1364628620
  },
  {
    "city": "Tecumseh",
    "city_ascii": "Tecumseh",
    "lat": 42.0065,
    "lng": -83.945,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 8398,
    "id": 1840003228
  },
  {
    "city": "North Haledon",
    "city_ascii": "North Haledon",
    "lat": 40.9628,
    "lng": -74.1844,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 8395,
    "id": 1840000836
  },
  {
    "city": "Center Moriches",
    "city_ascii": "Center Moriches",
    "lat": 40.8015,
    "lng": -72.796,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 8395,
    "id": 1840005025
  },
  {
    "city": "Coxhoe",
    "city_ascii": "Coxhoe",
    "lat": 54.714,
    "lng": -1.503,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Durham",
    "capital": "",
    "population": 7020,
    "id": 1826767688
  },
  {
    "city": "Dornstetten",
    "city_ascii": "Dornstetten",
    "lat": 48.47,
    "lng": 8.4994,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 8061,
    "id": 1276163938
  },
  {
    "city": "Paris",
    "city_ascii": "Paris",
    "lat": 39.6149,
    "lng": -87.6904,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 8394,
    "id": 1840009542
  },
  {
    "city": "Mikasa",
    "city_ascii": "Mikasa",
    "lat": 43.2458,
    "lng": 141.8756,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hokkaidō",
    "capital": "",
    "population": 8366,
    "id": 1392478231
  },
  {
    "city": "Hollymead",
    "city_ascii": "Hollymead",
    "lat": 38.1266,
    "lng": -78.4386,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 8393,
    "id": 1840024700
  },
  {
    "city": "Bayport",
    "city_ascii": "Bayport",
    "lat": 40.746,
    "lng": -73.0546,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 8392,
    "id": 1840005018
  },
  {
    "city": "Aransas Pass",
    "city_ascii": "Aransas Pass",
    "lat": 27.8877,
    "lng": -97.1134,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 8388,
    "id": 1840019708
  },
  {
    "city": "Garrison",
    "city_ascii": "Garrison",
    "lat": 39.4023,
    "lng": -76.7514,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 8388,
    "id": 1840005691
  },
  {
    "city": "Gaylord",
    "city_ascii": "Gaylord",
    "lat": 45.0213,
    "lng": -84.6803,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 8388,
    "id": 1840002148
  },
  {
    "city": "Greenville",
    "city_ascii": "Greenville",
    "lat": 41.88,
    "lng": -71.5549,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Rhode Island",
    "capital": "",
    "population": 8387,
    "id": 1840003292
  },
  {
    "city": "Freeport",
    "city_ascii": "Freeport",
    "lat": 43.8556,
    "lng": -70.1009,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maine",
    "capital": "",
    "population": 8387,
    "id": 1840052826
  },
  {
    "city": "East Cowes",
    "city_ascii": "East Cowes",
    "lat": 50.757,
    "lng": -1.2865,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Isle of Wight",
    "capital": "",
    "population": 7010,
    "id": 1826991123
  },
  {
    "city": "Grafenau",
    "city_ascii": "Grafenau",
    "lat": 48.8561,
    "lng": 13.3969,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 8256,
    "id": 1276582475
  },
  {
    "city": "Ostseebad Kühlungsborn",
    "city_ascii": "Ostseebad Kuhlungsborn",
    "lat": 54.1333,
    "lng": 11.75,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Mecklenburg-Western Pomerania",
    "capital": "",
    "population": 7896,
    "id": 1276191924
  },
  {
    "city": "Covington",
    "city_ascii": "Covington",
    "lat": 35.5661,
    "lng": -89.6482,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 8383,
    "id": 1840014547
  },
  {
    "city": "Carbondale",
    "city_ascii": "Carbondale",
    "lat": 41.5714,
    "lng": -75.5048,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 8383,
    "id": 1840003376
  },
  {
    "city": "Kandern",
    "city_ascii": "Kandern",
    "lat": 47.7144,
    "lng": 7.6608,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 8249,
    "id": 1276847947
  },
  {
    "city": "Peebles",
    "city_ascii": "Peebles",
    "lat": 55.65,
    "lng": -3.18,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Scottish Borders, The",
    "capital": "",
    "population": 8376,
    "id": 1826563131
  },
  {
    "city": "Neusiedl am See",
    "city_ascii": "Neusiedl am See",
    "lat": 47.9486,
    "lng": 16.8431,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Burgenland",
    "capital": "minor",
    "population": 8235,
    "id": 1040084469
  },
  {
    "city": "Fairless Hills",
    "city_ascii": "Fairless Hills",
    "lat": 40.1784,
    "lng": -74.8524,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 8376,
    "id": 1840001307
  },
  {
    "city": "Ventanas",
    "city_ascii": "Ventanas",
    "lat": -32.7419,
    "lng": -71.4839,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Valparaíso",
    "capital": "",
    "population": 8314,
    "id": 1152227207
  },
  {
    "city": "Lugau",
    "city_ascii": "Lugau",
    "lat": 50.7383,
    "lng": 12.7464,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 8013,
    "id": 1276002866
  },
  {
    "city": "Highland Heights",
    "city_ascii": "Highland Heights",
    "lat": 41.5518,
    "lng": -81.4691,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 8373,
    "id": 1840000605
  },
  {
    "city": "Ictar Budinţi",
    "city_ascii": "Ictar Budinti",
    "lat": 45.8014,
    "lng": 21.5133,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Timiş",
    "capital": "",
    "population": 8336,
    "id": 1642096588
  },
  {
    "city": "Bonnybridge",
    "city_ascii": "Bonnybridge",
    "lat": 55.999,
    "lng": -3.887,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Falkirk",
    "capital": "",
    "population": 6870,
    "id": 1826660559
  },
  {
    "city": "Hassocks",
    "city_ascii": "Hassocks",
    "lat": 50.9233,
    "lng": -0.1509,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "West Sussex",
    "capital": "",
    "population": 7667,
    "id": 1826256340
  },
  {
    "city": "Somers",
    "city_ascii": "Somers",
    "lat": 42.6411,
    "lng": -87.8919,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 8371,
    "id": 1840038024
  },
  {
    "city": "Venice Gardens",
    "city_ascii": "Venice Gardens",
    "lat": 27.0694,
    "lng": -82.4054,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 8370,
    "id": 1840014182
  },
  {
    "city": "Fletcher",
    "city_ascii": "Fletcher",
    "lat": 35.4316,
    "lng": -82.5038,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 8369,
    "id": 1840016371
  },
  {
    "city": "Arealva",
    "city_ascii": "Arealva",
    "lat": -22.0286,
    "lng": -48.9111,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 8351,
    "id": 1076417809
  },
  {
    "city": "Warsash",
    "city_ascii": "Warsash",
    "lat": 50.8532,
    "lng": -1.301,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hampshire",
    "capital": "",
    "population": 7183,
    "id": 1826561282
  },
  {
    "city": "Kilmore",
    "city_ascii": "Kilmore",
    "lat": -37.2833,
    "lng": 144.95,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Victoria",
    "capital": "",
    "population": 7958,
    "id": 1036740563
  },
  {
    "city": "Baxter",
    "city_ascii": "Baxter",
    "lat": 46.3426,
    "lng": -94.2793,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 8364,
    "id": 1840006643
  },
  {
    "city": "Peasedown Saint John",
    "city_ascii": "Peasedown Saint John",
    "lat": 51.315,
    "lng": -2.424,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Bath and North East Somerset",
    "capital": "",
    "population": 6446,
    "id": 1826198793
  },
  {
    "city": "Leisnig",
    "city_ascii": "Leisnig",
    "lat": 51.1667,
    "lng": 12.9167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 8257,
    "id": 1276353533
  },
  {
    "city": "Vila Viçosa",
    "city_ascii": "Vila Vicosa",
    "lat": 38.75,
    "lng": -7.4333,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Évora",
    "capital": "minor",
    "population": 8319,
    "id": 1620378145
  },
  {
    "city": "Ouro Verde",
    "city_ascii": "Ouro Verde",
    "lat": -21.4894,
    "lng": -51.7003,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 8330,
    "id": 1076804048
  },
  {
    "city": "Willow Street",
    "city_ascii": "Willow Street",
    "lat": 39.9809,
    "lng": -76.2705,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 8360,
    "id": 1840005494
  },
  {
    "city": "Lugoff",
    "city_ascii": "Lugoff",
    "lat": 34.2216,
    "lng": -80.6849,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 8360,
    "id": 1840013597
  },
  {
    "city": "Millersville",
    "city_ascii": "Millersville",
    "lat": 40.0047,
    "lng": -76.3522,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 8359,
    "id": 1840003722
  },
  {
    "city": "Jessup",
    "city_ascii": "Jessup",
    "lat": 39.1457,
    "lng": -76.7745,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 8359,
    "id": 1840005919
  },
  {
    "city": "Rottenburg an der Laaber",
    "city_ascii": "Rottenburg an der Laaber",
    "lat": 48.7019,
    "lng": 12.0272,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 8267,
    "id": 1276689062
  },
  {
    "city": "Kinderhook",
    "city_ascii": "Kinderhook",
    "lat": 42.4116,
    "lng": -73.6823,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 8357,
    "id": 1840004630
  },
  {
    "city": "Upper Makefield",
    "city_ascii": "Upper Makefield",
    "lat": 40.2942,
    "lng": -74.925,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 8357,
    "id": 1840150060
  },
  {
    "city": "Tidaholm",
    "city_ascii": "Tidaholm",
    "lat": 58.1794,
    "lng": 13.9599,
    "country": "Sweden",
    "iso2": "SE",
    "iso3": "SWE",
    "admin_name": "Västra Götaland",
    "capital": "minor",
    "population": 8233,
    "id": 1752287671
  },
  {
    "city": "Knittlingen",
    "city_ascii": "Knittlingen",
    "lat": 49.0239,
    "lng": 8.7569,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 8048,
    "id": 1276093094
  },
  {
    "city": "Buckhannon",
    "city_ascii": "Buckhannon",
    "lat": 38.9927,
    "lng": -80.2282,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "West Virginia",
    "capital": "",
    "population": 8351,
    "id": 1840005997
  },
  {
    "city": "Ransbach-Baumbach",
    "city_ascii": "Ransbach-Baumbach",
    "lat": 50.4661,
    "lng": 7.7253,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Rhineland-Palatinate",
    "capital": "",
    "population": 7715,
    "id": 1276056129
  },
  {
    "city": "Akhalkalaki",
    "city_ascii": "Akhalkalaki",
    "lat": 41.4056,
    "lng": 43.4861,
    "country": "Georgia",
    "iso2": "GE",
    "iso3": "GEO",
    "admin_name": "Samtskhe-Javakheti",
    "capital": "minor",
    "population": 8295,
    "id": 1268261654
  },
  {
    "city": "Hillsboro",
    "city_ascii": "Hillsboro",
    "lat": 32.0091,
    "lng": -97.1151,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 8347,
    "id": 1840020783
  },
  {
    "city": "Tallulah",
    "city_ascii": "Tallulah",
    "lat": 32.4067,
    "lng": -91.1915,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 8346,
    "id": 1840015808
  },
  {
    "city": "Northam",
    "city_ascii": "Northam",
    "lat": 51.0393,
    "lng": -4.2104,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Devon",
    "capital": "",
    "population": 7489,
    "id": 1826338973
  },
  {
    "city": "Higashi-asahikawa",
    "city_ascii": "Higashi-asahikawa",
    "lat": 43.6989,
    "lng": 142.51,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hokkaidō",
    "capital": "",
    "population": 8310,
    "id": 1392026262
  },
  {
    "city": "Arab",
    "city_ascii": "Arab",
    "lat": 34.3309,
    "lng": -86.4991,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 8342,
    "id": 1840013600
  },
  {
    "city": "Messancy",
    "city_ascii": "Messancy",
    "lat": 49.595,
    "lng": 5.8183,
    "country": "Belgium",
    "iso2": "BE",
    "iso3": "BEL",
    "admin_name": "Wallonia",
    "capital": "",
    "population": 8185,
    "id": 1056874547
  },
  {
    "city": "Fern Park",
    "city_ascii": "Fern Park",
    "lat": 28.6484,
    "lng": -81.3458,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 8340,
    "id": 1840014068
  },
  {
    "city": "Orono",
    "city_ascii": "Orono",
    "lat": 44.9657,
    "lng": -93.5908,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 8339,
    "id": 1840008927
  },
  {
    "city": "West Manheim",
    "city_ascii": "West Manheim",
    "lat": 39.7458,
    "lng": -76.943,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 8338,
    "id": 1840146058
  },
  {
    "city": "Bath",
    "city_ascii": "Bath",
    "lat": 43.9346,
    "lng": -69.8346,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maine",
    "capital": "",
    "population": 8338,
    "id": 1840000332
  },
  {
    "city": "Highworth",
    "city_ascii": "Highworth",
    "lat": 51.631,
    "lng": -1.712,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Swindon",
    "capital": "",
    "population": 8151,
    "id": 1826130096
  },
  {
    "city": "Proença-a-Nova",
    "city_ascii": "Proenca-a-Nova",
    "lat": 39.75,
    "lng": -7.9167,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Castelo Branco",
    "capital": "minor",
    "population": 8314,
    "id": 1620516554
  },
  {
    "city": "Kalampáka",
    "city_ascii": "Kalampaka",
    "lat": 39.7044,
    "lng": 21.6269,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Thessalía",
    "capital": "minor",
    "population": 8330,
    "id": 1300028482
  },
  {
    "city": "Clover",
    "city_ascii": "Clover",
    "lat": 35.1125,
    "lng": -81.2203,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 8335,
    "id": 1840016478
  },
  {
    "city": "Zell am Harmersbach",
    "city_ascii": "Zell am Harmersbach",
    "lat": 48.3467,
    "lng": 8.0639,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 8112,
    "id": 1276476250
  },
  {
    "city": "Montague",
    "city_ascii": "Montague",
    "lat": 42.5549,
    "lng": -72.5177,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 8334,
    "id": 1840053435
  },
  {
    "city": "Petoskey",
    "city_ascii": "Petoskey",
    "lat": 45.365,
    "lng": -84.9887,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 8334,
    "id": 1840003941
  },
  {
    "city": "Beaver Falls",
    "city_ascii": "Beaver Falls",
    "lat": 40.762,
    "lng": -80.3225,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 8332,
    "id": 1840001013
  },
  {
    "city": "Farnham",
    "city_ascii": "Farnham",
    "lat": 45.2833,
    "lng": -72.9833,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 8330,
    "id": 1124553013
  },
  {
    "city": "Tillamook",
    "city_ascii": "Tillamook",
    "lat": 45.4562,
    "lng": -123.8331,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 8328,
    "id": 1840021203
  },
  {
    "city": "Könnern",
    "city_ascii": "Konnern",
    "lat": 51.6697,
    "lng": 11.7708,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony-Anhalt",
    "capital": "",
    "population": 8261,
    "id": 1276797246
  },
  {
    "city": "Westwego",
    "city_ascii": "Westwego",
    "lat": 29.9058,
    "lng": -90.1434,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 8326,
    "id": 1840015937
  },
  {
    "city": "Corona de Tucson",
    "city_ascii": "Corona de Tucson",
    "lat": 31.9495,
    "lng": -110.7836,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 8326,
    "id": 1840018106
  },
  {
    "city": "Bulqizë",
    "city_ascii": "Bulqize",
    "lat": 41.5,
    "lng": 20.2167,
    "country": "Albania",
    "iso2": "AL",
    "iso3": "ALB",
    "admin_name": "Dibër",
    "capital": "",
    "population": 8177,
    "id": 1008809272
  },
  {
    "city": "Flowery Branch",
    "city_ascii": "Flowery Branch",
    "lat": 34.1713,
    "lng": -83.9142,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 8325,
    "id": 1840013611
  },
  {
    "city": "Falkenstein",
    "city_ascii": "Falkenstein",
    "lat": 50.4667,
    "lng": 12.3667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 8061,
    "id": 1276920557
  },
  {
    "city": "Dalton in Furness",
    "city_ascii": "Dalton in Furness",
    "lat": 54.1544,
    "lng": -3.1814,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Cumbria",
    "capital": "",
    "population": 8125,
    "id": 1826419499
  },
  {
    "city": "Durrington",
    "city_ascii": "Durrington",
    "lat": 51.199,
    "lng": -1.775,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Wiltshire",
    "capital": "",
    "population": 7379,
    "id": 1826502121
  },
  {
    "city": "St. Joseph",
    "city_ascii": "St. Joseph",
    "lat": 42.0967,
    "lng": -86.485,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 8317,
    "id": 1840003213
  },
  {
    "city": "Rýmařov",
    "city_ascii": "Rymarov",
    "lat": 49.9318,
    "lng": 17.2718,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Moravskoslezský Kraj",
    "capital": "",
    "population": 8181,
    "id": 1203613106
  },
  {
    "city": "Yarrawonga",
    "city_ascii": "Yarrawonga",
    "lat": -36.0167,
    "lng": 146,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Victoria",
    "capital": "",
    "population": 7930,
    "id": 1036458763
  },
  {
    "city": "Fair Lakes",
    "city_ascii": "Fair Lakes",
    "lat": 38.853,
    "lng": -77.3885,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 8314,
    "id": 1840041732
  },
  {
    "city": "Quincy",
    "city_ascii": "Quincy",
    "lat": 47.2344,
    "lng": -119.8531,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 8313,
    "id": 1840019818
  },
  {
    "city": "Erwin",
    "city_ascii": "Erwin",
    "lat": 42.138,
    "lng": -77.1415,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 8313,
    "id": 1840087416
  },
  {
    "city": "Holly",
    "city_ascii": "Holly",
    "lat": 42.7987,
    "lng": -83.6235,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 8313,
    "id": 1840011056
  },
  {
    "city": "Pike Creek",
    "city_ascii": "Pike Creek",
    "lat": 39.7485,
    "lng": -75.6953,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Delaware",
    "capital": "",
    "population": 8312,
    "id": 1840028618
  },
  {
    "city": "Frostproof",
    "city_ascii": "Frostproof",
    "lat": 27.7493,
    "lng": -81.5252,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 8312,
    "id": 1840014135
  },
  {
    "city": "Egremont",
    "city_ascii": "Egremont",
    "lat": 54.484,
    "lng": -3.5306,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Cumbria",
    "capital": "",
    "population": 8194,
    "id": 1826460932
  },
  {
    "city": "Vyetka",
    "city_ascii": "Vyetka",
    "lat": 52.5592,
    "lng": 31.1808,
    "country": "Belarus",
    "iso2": "BY",
    "iso3": "BLR",
    "admin_name": "Homyel’skaya Voblasts’",
    "capital": "minor",
    "population": 8300,
    "id": 1112844638
  },
  {
    "city": "Felida",
    "city_ascii": "Felida",
    "lat": 45.7138,
    "lng": -122.7104,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 8311,
    "id": 1840037495
  },
  {
    "city": "Giánnouli",
    "city_ascii": "Giannouli",
    "lat": 39.6672,
    "lng": 22.3958,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Thessalía",
    "capital": "",
    "population": 7847,
    "id": 1300795531
  },
  {
    "city": "Niāla Kondapalle",
    "city_ascii": "Niala Kondapalle",
    "lat": 17.1,
    "lng": 80.0506,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Telangana",
    "capital": "",
    "population": 7767,
    "id": 1356698261
  },
  {
    "city": "Applewood",
    "city_ascii": "Applewood",
    "lat": 39.7524,
    "lng": -105.1605,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 8308,
    "id": 1840028573
  },
  {
    "city": "Narowlya",
    "city_ascii": "Narowlya",
    "lat": 51.8,
    "lng": 29.5,
    "country": "Belarus",
    "iso2": "BY",
    "iso3": "BLR",
    "admin_name": "Homyel’skaya Voblasts’",
    "capital": "minor",
    "population": 8300,
    "id": 1112536668
  },
  {
    "city": "Dannenberg",
    "city_ascii": "Dannenberg",
    "lat": 53.0833,
    "lng": 11.0833,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 8200,
    "id": 1276403285
  },
  {
    "city": "Floris",
    "city_ascii": "Floris",
    "lat": 38.9347,
    "lng": -77.4083,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 8305,
    "id": 1840024569
  },
  {
    "city": "Wroughton",
    "city_ascii": "Wroughton",
    "lat": 51.525,
    "lng": -1.792,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Swindon",
    "capital": "",
    "population": 8033,
    "id": 1826171684
  },
  {
    "city": "Brighton",
    "city_ascii": "Brighton",
    "lat": 40.7023,
    "lng": -80.3677,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 8303,
    "id": 1840145004
  },
  {
    "city": "Cleator Moor",
    "city_ascii": "Cleator Moor",
    "lat": 54.5211,
    "lng": -3.5119,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Cumbria",
    "capital": "",
    "population": 6936,
    "id": 1826873650
  },
  {
    "city": "Třeboň",
    "city_ascii": "Trebon",
    "lat": 49.0037,
    "lng": 14.7707,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Jihočeský Kraj",
    "capital": "",
    "population": 8217,
    "id": 1203741449
  },
  {
    "city": "Runnemede",
    "city_ascii": "Runnemede",
    "lat": 39.8521,
    "lng": -75.0739,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 8300,
    "id": 1840001511
  },
  {
    "city": "Bel Aire",
    "city_ascii": "Bel Aire",
    "lat": 37.7749,
    "lng": -97.2457,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kansas",
    "capital": "",
    "population": 8300,
    "id": 1840045965
  },
  {
    "city": "Milton",
    "city_ascii": "Milton",
    "lat": 47.2522,
    "lng": -122.3156,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 8299,
    "id": 1840019857
  },
  {
    "city": "Tāzeh Shahr",
    "city_ascii": "Tazeh Shahr",
    "lat": 38.1756,
    "lng": 44.6919,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Āz̄arbāyjān-e Gharbī",
    "capital": "",
    "population": 8216,
    "id": 1364552545
  },
  {
    "city": "Aurora",
    "city_ascii": "Aurora",
    "lat": 36.9674,
    "lng": -93.7183,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 8297,
    "id": 1840007636
  },
  {
    "city": "Silsden",
    "city_ascii": "Silsden",
    "lat": 53.914,
    "lng": -1.937,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Bradford",
    "capital": "",
    "population": 8268,
    "id": 1826136607
  },
  {
    "city": "Kunszentmiklós",
    "city_ascii": "Kunszentmiklos",
    "lat": 47.0264,
    "lng": 19.1228,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Bács-Kiskun",
    "capital": "minor",
    "population": 8248,
    "id": 1348358789
  },
  {
    "city": "Mahtomedi",
    "city_ascii": "Mahtomedi",
    "lat": 45.0619,
    "lng": -92.966,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 8294,
    "id": 1840008913
  },
  {
    "city": "Gonzales",
    "city_ascii": "Gonzales",
    "lat": 36.506,
    "lng": -121.4429,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 8293,
    "id": 1840020355
  },
  {
    "city": "Ashland",
    "city_ascii": "Ashland",
    "lat": 39.8782,
    "lng": -75.0085,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 8292,
    "id": 1840033467
  },
  {
    "city": "Socorro",
    "city_ascii": "Socorro",
    "lat": 34.0543,
    "lng": -106.9066,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Mexico",
    "capital": "",
    "population": 8292,
    "id": 1840021898
  },
  {
    "city": "Kapuskasing",
    "city_ascii": "Kapuskasing",
    "lat": 49.4167,
    "lng": -82.4333,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 8292,
    "id": 1124764245
  },
  {
    "city": "Coleshill",
    "city_ascii": "Coleshill",
    "lat": 52.499,
    "lng": -1.708,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Warwickshire",
    "capital": "",
    "population": 6481,
    "id": 1826438133
  },
  {
    "city": "Bargara",
    "city_ascii": "Bargara",
    "lat": -24.8205,
    "lng": 152.4625,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Queensland",
    "capital": "",
    "population": 7485,
    "id": 1036308552
  },
  {
    "city": "Prabuty",
    "city_ascii": "Prabuty",
    "lat": 53.7558,
    "lng": 19.1975,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Pomorskie",
    "capital": "",
    "population": 8215,
    "id": 1616481359
  },
  {
    "city": "Lopatcong",
    "city_ascii": "Lopatcong",
    "lat": 40.7091,
    "lng": -75.1552,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 8288,
    "id": 1840081771
  },
  {
    "city": "Savannah",
    "city_ascii": "Savannah",
    "lat": 35.221,
    "lng": -88.236,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 8288,
    "id": 1840015453
  },
  {
    "city": "Bismark",
    "city_ascii": "Bismark",
    "lat": 52.6667,
    "lng": 11.55,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony-Anhalt",
    "capital": "",
    "population": 8256,
    "id": 1276696695
  },
  {
    "city": "Pebble Creek",
    "city_ascii": "Pebble Creek",
    "lat": 28.1583,
    "lng": -82.3411,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 8284,
    "id": 1840028995
  },
  {
    "city": "Guapuã",
    "city_ascii": "Guapua",
    "lat": -20.3972,
    "lng": -47.4203,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 8260,
    "id": 1076401197
  },
  {
    "city": "Skowhegan",
    "city_ascii": "Skowhegan",
    "lat": 44.7554,
    "lng": -69.6657,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maine",
    "capital": "",
    "population": 8281,
    "id": 1840051814
  },
  {
    "city": "Liezen",
    "city_ascii": "Liezen",
    "lat": 47.5667,
    "lng": 14.2333,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Steiermark",
    "capital": "minor",
    "population": 8191,
    "id": 1040464860
  },
  {
    "city": "Mount Joy",
    "city_ascii": "Mount Joy",
    "lat": 40.1105,
    "lng": -76.5065,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 8278,
    "id": 1840003723
  },
  {
    "city": "McRae-Helena",
    "city_ascii": "McRae-Helena",
    "lat": 32.0635,
    "lng": -82.8968,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 8277,
    "id": 1840043457
  },
  {
    "city": "Helena Valley West Central",
    "city_ascii": "Helena Valley West Central",
    "lat": 46.6634,
    "lng": -112.0604,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Montana",
    "capital": "",
    "population": 8277,
    "id": 1840074081
  },
  {
    "city": "Trinidad",
    "city_ascii": "Trinidad",
    "lat": 37.175,
    "lng": -104.4908,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 8276,
    "id": 1840021545
  },
  {
    "city": "Kenton",
    "city_ascii": "Kenton",
    "lat": 40.6448,
    "lng": -83.6095,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 8276,
    "id": 1840008320
  },
  {
    "city": "North Elba",
    "city_ascii": "North Elba",
    "lat": 44.2394,
    "lng": -73.9969,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 8275,
    "id": 1840024001
  },
  {
    "city": "Carl Junction",
    "city_ascii": "Carl Junction",
    "lat": 37.1668,
    "lng": -94.5468,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 8274,
    "id": 1840007627
  },
  {
    "city": "Bad Schmiedeberg",
    "city_ascii": "Bad Schmiedeberg",
    "lat": 51.6881,
    "lng": 12.7375,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony-Anhalt",
    "capital": "",
    "population": 8222,
    "id": 1276417789
  },
  {
    "city": "Terrace Heights",
    "city_ascii": "Terrace Heights",
    "lat": 46.6024,
    "lng": -120.4482,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 8273,
    "id": 1840018465
  },
  {
    "city": "Summit Park",
    "city_ascii": "Summit Park",
    "lat": 40.7432,
    "lng": -111.5814,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Utah",
    "capital": "",
    "population": 8273,
    "id": 1840018725
  },
  {
    "city": "West Earl",
    "city_ascii": "West Earl",
    "lat": 40.126,
    "lng": -76.1774,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 8272,
    "id": 1840152739
  },
  {
    "city": "La Malbaie",
    "city_ascii": "La Malbaie",
    "lat": 47.65,
    "lng": -70.15,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 8271,
    "id": 1124466004
  },
  {
    "city": "Boxford",
    "city_ascii": "Boxford",
    "lat": 42.6815,
    "lng": -71.0189,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 8270,
    "id": 1840053527
  },
  {
    "city": "Paw Paw",
    "city_ascii": "Paw Paw",
    "lat": 42.2147,
    "lng": -85.8918,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 8270,
    "id": 1840011208
  },
  {
    "city": "Kiunga",
    "city_ascii": "Kiunga",
    "lat": -6.1167,
    "lng": 141.3,
    "country": "Papua New Guinea",
    "iso2": "PG",
    "iso3": "PNG",
    "admin_name": "Western",
    "capital": "",
    "population": 8265,
    "id": 1598534040
  },
  {
    "city": "Lake City",
    "city_ascii": "Lake City",
    "lat": 33.8676,
    "lng": -79.7533,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 8268,
    "id": 1840014715
  },
  {
    "city": "Haddam",
    "city_ascii": "Haddam",
    "lat": 41.4677,
    "lng": -72.5458,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 8267,
    "id": 1840034775
  },
  {
    "city": "Pawling",
    "city_ascii": "Pawling",
    "lat": 41.5661,
    "lng": -73.5971,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 8264,
    "id": 1840004751
  },
  {
    "city": "Lanark",
    "city_ascii": "Lanark",
    "lat": 55.6749,
    "lng": -3.777,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "South Lanarkshire",
    "capital": "",
    "population": 8253,
    "id": 1826114395
  },
  {
    "city": "Moengo",
    "city_ascii": "Moengo",
    "lat": 5.6167,
    "lng": -54.4,
    "country": "Suriname",
    "iso2": "SR",
    "iso3": "SUR",
    "admin_name": "Marowijne",
    "capital": "",
    "population": 8252,
    "id": 1740230194
  },
  {
    "city": "Pudasjärvi",
    "city_ascii": "Pudasjarvi",
    "lat": 65.3583,
    "lng": 27,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Pohjois-Pohjanmaa",
    "capital": "minor",
    "population": 8257,
    "id": 1246520515
  },
  {
    "city": "Colorado City",
    "city_ascii": "Colorado City",
    "lat": 36.9774,
    "lng": -112.983,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 8258,
    "id": 1840023170
  },
  {
    "city": "Oak Grove",
    "city_ascii": "Oak Grove",
    "lat": 39.0077,
    "lng": -94.1283,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 8258,
    "id": 1840008533
  },
  {
    "city": "East Donegal",
    "city_ascii": "East Donegal",
    "lat": 40.0823,
    "lng": -76.5631,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 8257,
    "id": 1840150410
  },
  {
    "city": "Kosterëvo",
    "city_ascii": "Kosterevo",
    "lat": 55.9333,
    "lng": 39.6333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Vladimirskaya Oblast’",
    "capital": "",
    "population": 8216,
    "id": 1643771239
  },
  {
    "city": "Kiama",
    "city_ascii": "Kiama",
    "lat": -34.6708,
    "lng": 150.8542,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 7700,
    "id": 1036754695
  },
  {
    "city": "Shëngjin",
    "city_ascii": "Shengjin",
    "lat": 41.8136,
    "lng": 19.5939,
    "country": "Albania",
    "iso2": "AL",
    "iso3": "ALB",
    "admin_name": "Lezhë",
    "capital": "",
    "population": 8091,
    "id": 1008373202
  },
  {
    "city": "Moundsville",
    "city_ascii": "Moundsville",
    "lat": 39.9221,
    "lng": -80.7422,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "West Virginia",
    "capital": "",
    "population": 8252,
    "id": 1840005557
  },
  {
    "city": "Fishersville",
    "city_ascii": "Fishersville",
    "lat": 38.1005,
    "lng": -78.9687,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 8252,
    "id": 1840006240
  },
  {
    "city": "Gulf Hills",
    "city_ascii": "Gulf Hills",
    "lat": 30.4365,
    "lng": -88.8149,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Mississippi",
    "capital": "",
    "population": 8252,
    "id": 1840013930
  },
  {
    "city": "Pereiras",
    "city_ascii": "Pereiras",
    "lat": -23.0761,
    "lng": -47.9758,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 8213,
    "id": 1076745377
  },
  {
    "city": "Warlingham",
    "city_ascii": "Warlingham",
    "lat": 51.3095,
    "lng": -0.0574,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Surrey",
    "capital": "",
    "population": 8036,
    "id": 1826495873
  },
  {
    "city": "Druid Hills",
    "city_ascii": "Druid Hills",
    "lat": 33.7842,
    "lng": -84.3273,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 8244,
    "id": 1840013092
  },
  {
    "city": "Almirante",
    "city_ascii": "Almirante",
    "lat": 9.3,
    "lng": -82.4,
    "country": "Panama",
    "iso2": "PA",
    "iso3": "PAN",
    "admin_name": "Bocas del Toro",
    "capital": "minor",
    "population": 8114,
    "id": 1591293829
  },
  {
    "city": "Locust Grove",
    "city_ascii": "Locust Grove",
    "lat": 33.3442,
    "lng": -84.1071,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 8243,
    "id": 1840015705
  },
  {
    "city": "Brooklyn",
    "city_ascii": "Brooklyn",
    "lat": 41.787,
    "lng": -71.9543,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 8243,
    "id": 1840034199
  },
  {
    "city": "Lipník nad Bečvou",
    "city_ascii": "Lipnik nad Becvou",
    "lat": 49.5275,
    "lng": 17.5863,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Olomoucký Kraj",
    "capital": "",
    "population": 7982,
    "id": 1203395873
  },
  {
    "city": "Lake Norman of Catawba",
    "city_ascii": "Lake Norman of Catawba",
    "lat": 35.5995,
    "lng": -80.984,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 8241,
    "id": 1840073862
  },
  {
    "city": "Fort Mitchell",
    "city_ascii": "Fort Mitchell",
    "lat": 39.0459,
    "lng": -84.5563,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kentucky",
    "capital": "",
    "population": 8241,
    "id": 1840013170
  },
  {
    "city": "Möckmühl",
    "city_ascii": "Mockmuhl",
    "lat": 49.3167,
    "lng": 9.35,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 8078,
    "id": 1276533939
  },
  {
    "city": "Manhattan",
    "city_ascii": "Manhattan",
    "lat": 41.4274,
    "lng": -87.9805,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 8240,
    "id": 1840011490
  },
  {
    "city": "Ahuimanu",
    "city_ascii": "Ahuimanu",
    "lat": 21.4379,
    "lng": -157.8404,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Hawaii",
    "capital": "",
    "population": 8239,
    "id": 1840029468
  },
  {
    "city": "Schroeppel",
    "city_ascii": "Schroeppel",
    "lat": 43.265,
    "lng": -76.2748,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 8238,
    "id": 1840087893
  },
  {
    "city": "Crowthorne",
    "city_ascii": "Crowthorne",
    "lat": 51.37,
    "lng": -0.792,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Bracknell Forest",
    "capital": "",
    "population": 6711,
    "id": 1826160167
  },
  {
    "city": "Akranes",
    "city_ascii": "Akranes",
    "lat": 64.3167,
    "lng": -22.1,
    "country": "Iceland",
    "iso2": "IS",
    "iso3": "ISL",
    "admin_name": "Vesturland",
    "capital": "",
    "population": 7411,
    "id": 1352521514
  },
  {
    "city": "Cortlandville",
    "city_ascii": "Cortlandville",
    "lat": 42.5892,
    "lng": -76.1615,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 8234,
    "id": 1840087348
  },
  {
    "city": "Bongaree",
    "city_ascii": "Bongaree",
    "lat": -27.0814,
    "lng": 153.1633,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Queensland",
    "capital": "",
    "population": 6947,
    "id": 1036269345
  },
  {
    "city": "Miyake-naka",
    "city_ascii": "Miyake-naka",
    "lat": 34.5736,
    "lng": 135.7731,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Ōsaka",
    "capital": "",
    "population": 6604,
    "id": 1392944914
  },
  {
    "city": "Oberkochen",
    "city_ascii": "Oberkochen",
    "lat": 48.7839,
    "lng": 10.1053,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 7895,
    "id": 1276678198
  },
  {
    "city": "Johnstown",
    "city_ascii": "Johnstown",
    "lat": 43.0073,
    "lng": -74.3755,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 8230,
    "id": 1840000376
  },
  {
    "city": "Tara",
    "city_ascii": "Tara",
    "lat": 33.0194,
    "lng": 130.1792,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Saga",
    "capital": "",
    "population": 8119,
    "id": 1392508192
  },
  {
    "city": "Spotswood",
    "city_ascii": "Spotswood",
    "lat": 40.3949,
    "lng": -74.392,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 8228,
    "id": 1840001336
  },
  {
    "city": "Sychëvka",
    "city_ascii": "Sychevka",
    "lat": 55.8253,
    "lng": 34.2736,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Smolenskaya Oblast’",
    "capital": "",
    "population": 8220,
    "id": 1643603757
  },
  {
    "city": "Waynesburg",
    "city_ascii": "Waynesburg",
    "lat": 39.8983,
    "lng": -80.1855,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 8227,
    "id": 1840000722
  },
  {
    "city": "Irapuru",
    "city_ascii": "Irapuru",
    "lat": -21.5708,
    "lng": -51.345,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 8187,
    "id": 1076696803
  },
  {
    "city": "Long Branch",
    "city_ascii": "Long Branch",
    "lat": 38.8302,
    "lng": -77.2713,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 8225,
    "id": 1840041774
  },
  {
    "city": "Thomson",
    "city_ascii": "Thomson",
    "lat": 33.4666,
    "lng": -82.4992,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 8225,
    "id": 1840015703
  },
  {
    "city": "Renfrew",
    "city_ascii": "Renfrew",
    "lat": 45.4717,
    "lng": -76.6831,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 8223,
    "id": 1124652971
  },
  {
    "city": "Neustadt",
    "city_ascii": "Neustadt",
    "lat": 50.7333,
    "lng": 11.75,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Thuringia",
    "capital": "",
    "population": 8002,
    "id": 1276606241
  },
  {
    "city": "Whitefish",
    "city_ascii": "Whitefish",
    "lat": 48.4329,
    "lng": -114.3591,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Montana",
    "capital": "",
    "population": 8221,
    "id": 1840021047
  },
  {
    "city": "Bruck an der Leitha",
    "city_ascii": "Bruck an der Leitha",
    "lat": 48.0255,
    "lng": 16.779,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Niederösterreich",
    "capital": "minor",
    "population": 7887,
    "id": 1040346220
  },
  {
    "city": "Stornoway",
    "city_ascii": "Stornoway",
    "lat": 58.209,
    "lng": -6.387,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Eilean Siar",
    "capital": "",
    "population": 8038,
    "id": 1826708788
  },
  {
    "city": "Novaya Ladoga",
    "city_ascii": "Novaya Ladoga",
    "lat": 60.1,
    "lng": 32.3,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Leningradskaya Oblast’",
    "capital": "",
    "population": 8203,
    "id": 1643123607
  },
  {
    "city": "Westlake Village",
    "city_ascii": "Westlake Village",
    "lat": 34.1369,
    "lng": -118.822,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 8217,
    "id": 1840037458
  },
  {
    "city": "Castle Shannon",
    "city_ascii": "Castle Shannon",
    "lat": 40.3664,
    "lng": -80.0194,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 8216,
    "id": 1840001215
  },
  {
    "city": "Kirawsk",
    "city_ascii": "Kirawsk",
    "lat": 53.2686,
    "lng": 29.4736,
    "country": "Belarus",
    "iso2": "BY",
    "iso3": "BLR",
    "admin_name": "Mahilyowskaya Voblasts’",
    "capital": "minor",
    "population": 8200,
    "id": 1112908122
  },
  {
    "city": "Coaldale",
    "city_ascii": "Coaldale",
    "lat": 49.7333,
    "lng": -112.6167,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Alberta",
    "capital": "",
    "population": 8215,
    "id": 1124989507
  },
  {
    "city": "Zavodske",
    "city_ascii": "Zavodske",
    "lat": 50.4002,
    "lng": 33.3908,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Poltavs’ka Oblast’",
    "capital": "",
    "population": 8180,
    "id": 1804705298
  },
  {
    "city": "Landeck",
    "city_ascii": "Landeck",
    "lat": 47.1333,
    "lng": 10.5667,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Tirol",
    "capital": "minor",
    "population": 7725,
    "id": 1040988782
  },
  {
    "city": "Kulpsville",
    "city_ascii": "Kulpsville",
    "lat": 40.244,
    "lng": -75.3407,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 8208,
    "id": 1840005465
  },
  {
    "city": "Vau i Dejës",
    "city_ascii": "Vau i Dejes",
    "lat": 42,
    "lng": 19.6333,
    "country": "Albania",
    "iso2": "AL",
    "iso3": "ALB",
    "admin_name": "Shkodër",
    "capital": "",
    "population": 8117,
    "id": 1008394491
  },
  {
    "city": "Willebadessen",
    "city_ascii": "Willebadessen",
    "lat": 51.6331,
    "lng": 9.0331,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 8142,
    "id": 1276518473
  },
  {
    "city": "Komsomolsk",
    "city_ascii": "Komsomolsk",
    "lat": 57.0333,
    "lng": 40.3833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Ivanovskaya Oblast’",
    "capital": "",
    "population": 8189,
    "id": 1643602001
  },
  {
    "city": "Upper Saddle River",
    "city_ascii": "Upper Saddle River",
    "lat": 41.0633,
    "lng": -74.0986,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 8205,
    "id": 1840000920
  },
  {
    "city": "Honey Brook",
    "city_ascii": "Honey Brook",
    "lat": 40.0902,
    "lng": -75.8886,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 8205,
    "id": 1840001427
  },
  {
    "city": "St. Martin",
    "city_ascii": "St. Martin",
    "lat": 30.4399,
    "lng": -88.8651,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Mississippi",
    "capital": "",
    "population": 8203,
    "id": 1840032563
  },
  {
    "city": "Millis",
    "city_ascii": "Millis",
    "lat": 42.1693,
    "lng": -71.3626,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 8202,
    "id": 1840070268
  },
  {
    "city": "Buxton",
    "city_ascii": "Buxton",
    "lat": 43.6428,
    "lng": -70.5376,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maine",
    "capital": "",
    "population": 8202,
    "id": 1840066159
  },
  {
    "city": "Burton",
    "city_ascii": "Burton",
    "lat": 32.4233,
    "lng": -80.7454,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 8199,
    "id": 1840013816
  },
  {
    "city": "Litchfield",
    "city_ascii": "Litchfield",
    "lat": 41.7413,
    "lng": -73.1931,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 8198,
    "id": 1840004769
  },
  {
    "city": "High Ackworth",
    "city_ascii": "High Ackworth",
    "lat": 53.6408,
    "lng": -1.3364,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Wakefield",
    "capital": "",
    "population": 7049,
    "id": 1826617652
  },
  {
    "city": "Arnstein",
    "city_ascii": "Arnstein",
    "lat": 49.9667,
    "lng": 9.9833,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 8125,
    "id": 1276400726
  },
  {
    "city": "Jefferson City",
    "city_ascii": "Jefferson City",
    "lat": 36.1197,
    "lng": -83.4838,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 8194,
    "id": 1840014485
  },
  {
    "city": "Laurel Hill",
    "city_ascii": "Laurel Hill",
    "lat": 38.7026,
    "lng": -77.2422,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 8194,
    "id": 1840025639
  },
  {
    "city": "Harefield",
    "city_ascii": "Harefield",
    "lat": 51.6034,
    "lng": -0.478,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hillingdon",
    "capital": "",
    "population": 7399,
    "id": 1826669882
  },
  {
    "city": "Kenilworth",
    "city_ascii": "Kenilworth",
    "lat": 40.6781,
    "lng": -74.2889,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 8191,
    "id": 1840003616
  },
  {
    "city": "Kensington",
    "city_ascii": "Kensington",
    "lat": 41.6298,
    "lng": -72.7714,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 8190,
    "id": 1840000505
  },
  {
    "city": "Bad Herrenalb",
    "city_ascii": "Bad Herrenalb",
    "lat": 48.8006,
    "lng": 8.4408,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 7948,
    "id": 1276660530
  },
  {
    "city": "Ercsi",
    "city_ascii": "Ercsi",
    "lat": 47.2496,
    "lng": 18.891,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Fejér",
    "capital": "",
    "population": 8065,
    "id": 1348286833
  },
  {
    "city": "Village Green-Green Ridge",
    "city_ascii": "Village Green-Green Ridge",
    "lat": 39.8638,
    "lng": -75.4256,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 8187,
    "id": 1840073727
  },
  {
    "city": "Denison",
    "city_ascii": "Denison",
    "lat": 42.016,
    "lng": -95.3528,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Iowa",
    "capital": "",
    "population": 8185,
    "id": 1840007008
  },
  {
    "city": "Ridgefield",
    "city_ascii": "Ridgefield",
    "lat": 45.8114,
    "lng": -122.705,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 8184,
    "id": 1840019909
  },
  {
    "city": "Napoleon",
    "city_ascii": "Napoleon",
    "lat": 41.3978,
    "lng": -84.1244,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 8183,
    "id": 1840008242
  },
  {
    "city": "Laprida",
    "city_ascii": "Laprida",
    "lat": -37.5333,
    "lng": -60.8167,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires",
    "capital": "minor",
    "population": 8178,
    "id": 1032804706
  },
  {
    "city": "Canton Township",
    "city_ascii": "Canton Township",
    "lat": 40.1991,
    "lng": -80.3009,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 8180,
    "id": 1840151796
  },
  {
    "city": "Ellsworth",
    "city_ascii": "Ellsworth",
    "lat": 44.5847,
    "lng": -68.4875,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maine",
    "capital": "",
    "population": 8180,
    "id": 1840000281
  },
  {
    "city": "Kennett",
    "city_ascii": "Kennett",
    "lat": 39.8374,
    "lng": -75.6808,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 8177,
    "id": 1840151978
  },
  {
    "city": "Clanwilliam",
    "city_ascii": "Clanwilliam",
    "lat": -32.1786,
    "lng": 18.8911,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "Western Cape",
    "capital": "",
    "population": 7674,
    "id": 1710888535
  },
  {
    "city": "Vrútky",
    "city_ascii": "Vrutky",
    "lat": 49.1117,
    "lng": 18.9236,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Žilinský",
    "capital": "",
    "population": 7760,
    "id": 1703987770
  },
  {
    "city": "Vetschau",
    "city_ascii": "Vetschau",
    "lat": 51.7831,
    "lng": 14.0667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Brandenburg",
    "capital": "",
    "population": 8103,
    "id": 1276019655
  },
  {
    "city": "Teuchern",
    "city_ascii": "Teuchern",
    "lat": 51.1167,
    "lng": 12.0167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony-Anhalt",
    "capital": "",
    "population": 8077,
    "id": 1276447300
  },
  {
    "city": "Hurbanovo",
    "city_ascii": "Hurbanovo",
    "lat": 47.8761,
    "lng": 18.1972,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Nitriansky",
    "capital": "",
    "population": 8041,
    "id": 1703540174
  },
  {
    "city": "Monona",
    "city_ascii": "Monona",
    "lat": 43.054,
    "lng": -89.3334,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 8175,
    "id": 1840002920
  },
  {
    "city": "Ngaruawahia",
    "city_ascii": "Ngaruawahia",
    "lat": -37.668,
    "lng": 175.147,
    "country": "New Zealand",
    "iso2": "NZ",
    "iso3": "NZL",
    "admin_name": "Waikato",
    "capital": "",
    "population": 6580,
    "id": 1554001086
  },
  {
    "city": "West Thurrock",
    "city_ascii": "West Thurrock",
    "lat": 51.4836,
    "lng": 0.282,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Thurrock",
    "capital": "",
    "population": 7795,
    "id": 1826272551
  },
  {
    "city": "Manāli",
    "city_ascii": "Manali",
    "lat": 32.2044,
    "lng": 77.17,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Himāchal Pradesh",
    "capital": "",
    "population": 8096,
    "id": 1356961623
  },
  {
    "city": "Adams",
    "city_ascii": "Adams",
    "lat": 42.6269,
    "lng": -73.1187,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 8172,
    "id": 1840053644
  },
  {
    "city": "Mayo",
    "city_ascii": "Mayo",
    "lat": 38.9041,
    "lng": -76.518,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 8171,
    "id": 1840005923
  },
  {
    "city": "Center Line",
    "city_ascii": "Center Line",
    "lat": 42.4805,
    "lng": -83.0274,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 8171,
    "id": 1840002419
  },
  {
    "city": "Pismo Beach",
    "city_ascii": "Pismo Beach",
    "lat": 35.1484,
    "lng": -120.6492,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 8168,
    "id": 1840020416
  },
  {
    "city": "Bramcote",
    "city_ascii": "Bramcote",
    "lat": 52.934,
    "lng": -1.242,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Nottinghamshire",
    "capital": "",
    "population": 7270,
    "id": 1826659985
  },
  {
    "city": "Crestwood Village",
    "city_ascii": "Crestwood Village",
    "lat": 39.9568,
    "lng": -74.3524,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 8164,
    "id": 1840005527
  },
  {
    "city": "Milliken",
    "city_ascii": "Milliken",
    "lat": 40.3115,
    "lng": -104.8561,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 8164,
    "id": 1840022397
  },
  {
    "city": "Murphy",
    "city_ascii": "Murphy",
    "lat": 38.4922,
    "lng": -90.4856,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 8163,
    "id": 1840006236
  },
  {
    "city": "Black Mountain",
    "city_ascii": "Black Mountain",
    "lat": 35.6142,
    "lng": -82.3275,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 8162,
    "id": 1840015392
  },
  {
    "city": "Dzidzantun",
    "city_ascii": "Dzidzantun",
    "lat": 21.2472,
    "lng": -89.0417,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Yucatán",
    "capital": "",
    "population": 8119,
    "id": 1484105150
  },
  {
    "city": "Country Club Estates",
    "city_ascii": "Country Club Estates",
    "lat": 31.2113,
    "lng": -81.4622,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 8160,
    "id": 1840029452
  },
  {
    "city": "Siret",
    "city_ascii": "Siret",
    "lat": 47.95,
    "lng": 26.06,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Suceava",
    "capital": "",
    "population": 7976,
    "id": 1642359444
  },
  {
    "city": "Allegheny Township",
    "city_ascii": "Allegheny Township",
    "lat": 40.6151,
    "lng": -79.6439,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 8158,
    "id": 1840151149
  },
  {
    "city": "Belene",
    "city_ascii": "Belene",
    "lat": 43.6456,
    "lng": 25.1258,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Pleven",
    "capital": "minor",
    "population": 8122,
    "id": 1100975983
  },
  {
    "city": "Bystřice nad Pernštejnem",
    "city_ascii": "Bystrice nad Pernstejnem",
    "lat": 49.523,
    "lng": 16.2615,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Vysočina",
    "capital": "",
    "population": 8004,
    "id": 1203647591
  },
  {
    "city": "Todos Santos",
    "city_ascii": "Todos Santos",
    "lat": 23.4486,
    "lng": -110.2233,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Baja California Sur",
    "capital": "",
    "population": 6485,
    "id": 1484568659
  },
  {
    "city": "Watton",
    "city_ascii": "Watton",
    "lat": 52.5713,
    "lng": 0.8259,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Norfolk",
    "capital": "",
    "population": 7202,
    "id": 1826637615
  },
  {
    "city": "Portugal Cove-St. Philip's",
    "city_ascii": "Portugal Cove-St. Philip's",
    "lat": 47.6272,
    "lng": -52.8506,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Newfoundland and Labrador",
    "capital": "",
    "population": 8147,
    "id": 1124001559
  },
  {
    "city": "Beeskow",
    "city_ascii": "Beeskow",
    "lat": 52.1667,
    "lng": 14.25,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Brandenburg",
    "capital": "minor",
    "population": 8042,
    "id": 1276512427
  },
  {
    "city": "Port Jefferson",
    "city_ascii": "Port Jefferson",
    "lat": 40.9465,
    "lng": -73.0579,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 8145,
    "id": 1840005131
  },
  {
    "city": "Middleport",
    "city_ascii": "Middleport",
    "lat": 38.9948,
    "lng": -82.0643,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 8145,
    "id": 1840012749
  },
  {
    "city": "Týn nad Vltavou",
    "city_ascii": "Tyn nad Vltavou",
    "lat": 49.2235,
    "lng": 14.4206,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Jihočeský Kraj",
    "capital": "",
    "population": 7960,
    "id": 1203946478
  },
  {
    "city": "San Antonio",
    "city_ascii": "San Antonio",
    "lat": 18.4468,
    "lng": -66.3002,
    "country": "Puerto Rico",
    "iso2": "PR",
    "iso3": "PRI",
    "admin_name": "Puerto Rico",
    "capital": "",
    "population": 8144,
    "id": 1630023733
  },
  {
    "city": "Beloslav",
    "city_ascii": "Beloslav",
    "lat": 43.1892,
    "lng": 27.705,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Varna",
    "capital": "minor",
    "population": 8017,
    "id": 1100318349
  },
  {
    "city": "Cumberland Hill",
    "city_ascii": "Cumberland Hill",
    "lat": 41.9736,
    "lng": -71.4605,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Rhode Island",
    "capital": "",
    "population": 8142,
    "id": 1840003291
  },
  {
    "city": "Manhasset",
    "city_ascii": "Manhasset",
    "lat": 40.7884,
    "lng": -73.6943,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 8138,
    "id": 1840005273
  },
  {
    "city": "Jennings Lodge",
    "city_ascii": "Jennings Lodge",
    "lat": 45.3926,
    "lng": -122.6153,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 8138,
    "id": 1840017419
  },
  {
    "city": "Zorra",
    "city_ascii": "Zorra",
    "lat": 43.15,
    "lng": -80.95,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 8138,
    "id": 1124000608
  },
  {
    "city": "Daun",
    "city_ascii": "Daun",
    "lat": 50.1986,
    "lng": 6.8319,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Rhineland-Palatinate",
    "capital": "minor",
    "population": 7974,
    "id": 1276401330
  },
  {
    "city": "Kindberg",
    "city_ascii": "Kindberg",
    "lat": 47.5044,
    "lng": 15.4489,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Steiermark",
    "capital": "",
    "population": 8047,
    "id": 1040311420
  },
  {
    "city": "West Livingston",
    "city_ascii": "West Livingston",
    "lat": 30.6957,
    "lng": -95.0097,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 8134,
    "id": 1840019561
  },
  {
    "city": "Kalifornsky",
    "city_ascii": "Kalifornsky",
    "lat": 60.4417,
    "lng": -151.1972,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alaska",
    "capital": "",
    "population": 8134,
    "id": 1840023406
  },
  {
    "city": "Fehérgyarmat",
    "city_ascii": "Fehergyarmat",
    "lat": 47.985,
    "lng": 22.5169,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Szabolcs-Szatmár-Bereg",
    "capital": "minor",
    "population": 7981,
    "id": 1348244030
  },
  {
    "city": "Leesville",
    "city_ascii": "Leesville",
    "lat": 31.1397,
    "lng": -93.2741,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 8132,
    "id": 1840014974
  },
  {
    "city": "Cosalá",
    "city_ascii": "Cosala",
    "lat": 24.4125,
    "lng": -106.6917,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Sinaloa",
    "capital": "minor",
    "population": 6577,
    "id": 1484038292
  },
  {
    "city": "Oradell",
    "city_ascii": "Oradell",
    "lat": 40.9562,
    "lng": -74.0314,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 8131,
    "id": 1840000914
  },
  {
    "city": "Kitimat",
    "city_ascii": "Kitimat",
    "lat": 54,
    "lng": -128.7,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "British Columbia",
    "capital": "",
    "population": 8131,
    "id": 1124198272
  },
  {
    "city": "Minnetrista",
    "city_ascii": "Minnetrista",
    "lat": 44.9356,
    "lng": -93.7103,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 8130,
    "id": 1840007833
  },
  {
    "city": "Tarouca",
    "city_ascii": "Tarouca",
    "lat": 41.0167,
    "lng": -7.7833,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Viseu",
    "capital": "minor",
    "population": 8048,
    "id": 1620399116
  },
  {
    "city": "Gering",
    "city_ascii": "Gering",
    "lat": 41.8275,
    "lng": -103.6649,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Nebraska",
    "capital": "",
    "population": 8128,
    "id": 1840008139
  },
  {
    "city": "Sanctuary Point",
    "city_ascii": "Sanctuary Point",
    "lat": -35.1036,
    "lng": 150.6267,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 7225,
    "id": 1036336388
  },
  {
    "city": "Kyabram",
    "city_ascii": "Kyabram",
    "lat": -36.3167,
    "lng": 145.05,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Victoria",
    "capital": "",
    "population": 7321,
    "id": 1036000064
  },
  {
    "city": "Templeton",
    "city_ascii": "Templeton",
    "lat": 42.5686,
    "lng": -72.0736,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 8127,
    "id": 1840053702
  },
  {
    "city": "Cintruénigo",
    "city_ascii": "Cintruenigo",
    "lat": 42.08,
    "lng": -1.805,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Navarre",
    "capital": "",
    "population": 7903,
    "id": 1724650055
  },
  {
    "city": "Shelburne",
    "city_ascii": "Shelburne",
    "lat": 44.0833,
    "lng": -80.2,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 8126,
    "id": 1124470888
  },
  {
    "city": "Conewago Township",
    "city_ascii": "Conewago Township",
    "lat": 40.0657,
    "lng": -76.7932,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 8124,
    "id": 1840150916
  },
  {
    "city": "Robinwood",
    "city_ascii": "Robinwood",
    "lat": 39.6266,
    "lng": -77.663,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 8123,
    "id": 1840031480
  },
  {
    "city": "Alnwick",
    "city_ascii": "Alnwick",
    "lat": 55.4134,
    "lng": -1.7069,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Northumberland",
    "capital": "",
    "population": 8116,
    "id": 1826458841
  },
  {
    "city": "Healesville",
    "city_ascii": "Healesville",
    "lat": -37.6561,
    "lng": 145.5139,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Victoria",
    "capital": "",
    "population": 7461,
    "id": 1036553015
  },
  {
    "city": "Medyn",
    "city_ascii": "Medyn",
    "lat": 54.9667,
    "lng": 35.8667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kaluzhskaya Oblast’",
    "capital": "",
    "population": 8109,
    "id": 1643406194
  },
  {
    "city": "Bad Lausick",
    "city_ascii": "Bad Lausick",
    "lat": 51.1447,
    "lng": 12.6453,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 8005,
    "id": 1276355841
  },
  {
    "city": "Medebach",
    "city_ascii": "Medebach",
    "lat": 51.1972,
    "lng": 8.7069,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 8055,
    "id": 1276925254
  },
  {
    "city": "Hazard",
    "city_ascii": "Hazard",
    "lat": 37.2583,
    "lng": -83.1977,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kentucky",
    "capital": "",
    "population": 8118,
    "id": 1840014376
  },
  {
    "city": "Šlapanice",
    "city_ascii": "Slapanice",
    "lat": 49.1687,
    "lng": 16.7273,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Jihomoravský Kraj",
    "capital": "",
    "population": 7599,
    "id": 1203592744
  },
  {
    "city": "Dzyatlava",
    "city_ascii": "Dzyatlava",
    "lat": 53.45,
    "lng": 25.4,
    "country": "Belarus",
    "iso2": "BY",
    "iso3": "BLR",
    "admin_name": "Hrodzyenskaya Voblasts’",
    "capital": "",
    "population": 8100,
    "id": 1112528638
  },
  {
    "city": "Sodus",
    "city_ascii": "Sodus",
    "lat": 43.2199,
    "lng": -77.0516,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 8116,
    "id": 1840004295
  },
  {
    "city": "Omak",
    "city_ascii": "Omak",
    "lat": 48.4228,
    "lng": -119.5159,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 8114,
    "id": 1840019761
  },
  {
    "city": "Lake Carmel",
    "city_ascii": "Lake Carmel",
    "lat": 41.4612,
    "lng": -73.6681,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 8114,
    "id": 1840004890
  },
  {
    "city": "West Dundee",
    "city_ascii": "West Dundee",
    "lat": 42.0985,
    "lng": -88.3073,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 8113,
    "id": 1840011357
  },
  {
    "city": "Bedminster",
    "city_ascii": "Bedminster",
    "lat": 40.6693,
    "lng": -74.6824,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 8112,
    "id": 1840081706
  },
  {
    "city": "Totnes",
    "city_ascii": "Totnes",
    "lat": 50.432,
    "lng": -3.684,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Devon",
    "capital": "",
    "population": 8076,
    "id": 1826061912
  },
  {
    "city": "Hopkinton",
    "city_ascii": "Hopkinton",
    "lat": 41.4782,
    "lng": -71.7538,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Rhode Island",
    "capital": "",
    "population": 8111,
    "id": 1840026107
  },
  {
    "city": "Dubí",
    "city_ascii": "Dubi",
    "lat": 50.6811,
    "lng": 13.7889,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Ústecký Kraj",
    "capital": "",
    "population": 7876,
    "id": 1203804841
  },
  {
    "city": "Duvall",
    "city_ascii": "Duvall",
    "lat": 47.7354,
    "lng": -121.9726,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 8107,
    "id": 1840018427
  },
  {
    "city": "Hempstead",
    "city_ascii": "Hempstead",
    "lat": 30.1004,
    "lng": -96.0779,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 8107,
    "id": 1840020913
  },
  {
    "city": "Yaremche",
    "city_ascii": "Yaremche",
    "lat": 48.4536,
    "lng": 24.5564,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Ivano-Frankivs’ka Oblast’",
    "capital": "",
    "population": 8094,
    "id": 1804382351
  },
  {
    "city": "Oederan",
    "city_ascii": "Oederan",
    "lat": 50.8617,
    "lng": 13.1672,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 8002,
    "id": 1276335906
  },
  {
    "city": "Kennedy",
    "city_ascii": "Kennedy",
    "lat": 40.4767,
    "lng": -80.1028,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 8105,
    "id": 1840146792
  },
  {
    "city": "Vinton",
    "city_ascii": "Vinton",
    "lat": 37.2746,
    "lng": -79.8887,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 8104,
    "id": 1840003856
  },
  {
    "city": "Poteau",
    "city_ascii": "Poteau",
    "lat": 35.043,
    "lng": -94.6357,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oklahoma",
    "capital": "",
    "population": 8104,
    "id": 1840020445
  },
  {
    "city": "Joshua",
    "city_ascii": "Joshua",
    "lat": 32.458,
    "lng": -97.3849,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 8101,
    "id": 1840019468
  },
  {
    "city": "Melton",
    "city_ascii": "Melton",
    "lat": -37.6833,
    "lng": 144.5833,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Victoria",
    "capital": "",
    "population": 8069,
    "id": 1036007778
  },
  {
    "city": "Clausthal",
    "city_ascii": "Clausthal",
    "lat": 51.8036,
    "lng": 10.3331,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 7741,
    "id": 1276596982
  },
  {
    "city": "Kirchheimbolanden",
    "city_ascii": "Kirchheimbolanden",
    "lat": 49.6664,
    "lng": 8.0117,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Rhineland-Palatinate",
    "capital": "minor",
    "population": 7802,
    "id": 1276494609
  },
  {
    "city": "Oak Hill",
    "city_ascii": "Oak Hill",
    "lat": 37.9844,
    "lng": -81.1277,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "West Virginia",
    "capital": "",
    "population": 8098,
    "id": 1840006297
  },
  {
    "city": "Burpengary",
    "city_ascii": "Burpengary",
    "lat": -27.1442,
    "lng": 153,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Queensland",
    "capital": "",
    "population": 6433,
    "id": 1036894671
  },
  {
    "city": "Herrieden",
    "city_ascii": "Herrieden",
    "lat": 49.233,
    "lng": 10.5064,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 7999,
    "id": 1276520323
  },
  {
    "city": "Hadfield",
    "city_ascii": "Hadfield",
    "lat": 53.46,
    "lng": -1.97,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Derbyshire",
    "capital": "",
    "population": 6305,
    "id": 1826533208
  },
  {
    "city": "Monument",
    "city_ascii": "Monument",
    "lat": 39.0735,
    "lng": -104.8467,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 8097,
    "id": 1840022474
  },
  {
    "city": "Croton-on-Hudson",
    "city_ascii": "Croton-on-Hudson",
    "lat": 41.2005,
    "lng": -73.9002,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 8095,
    "id": 1840004951
  },
  {
    "city": "Zapadnaya Dvina",
    "city_ascii": "Zapadnaya Dvina",
    "lat": 56.2667,
    "lng": 32.0833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Tverskaya Oblast’",
    "capital": "",
    "population": 8089,
    "id": 1643428090
  },
  {
    "city": "Briarcliff Manor",
    "city_ascii": "Briarcliff Manor",
    "lat": 41.14,
    "lng": -73.844,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 8094,
    "id": 1840004948
  },
  {
    "city": "Bellevue",
    "city_ascii": "Bellevue",
    "lat": 41.2743,
    "lng": -82.8394,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 8094,
    "id": 1840000654
  },
  {
    "city": "Olonets",
    "city_ascii": "Olonets",
    "lat": 60.9833,
    "lng": 32.9667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kareliya",
    "capital": "",
    "population": 8091,
    "id": 1643873627
  },
  {
    "city": "Sound Beach",
    "city_ascii": "Sound Beach",
    "lat": 40.9578,
    "lng": -72.9726,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 8093,
    "id": 1840005099
  },
  {
    "city": "Târgu Cărbuneşti",
    "city_ascii": "Targu Carbunesti",
    "lat": 44.9583,
    "lng": 23.5064,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Gorj",
    "capital": "",
    "population": 8034,
    "id": 1642453036
  },
  {
    "city": "Fort Belvoir",
    "city_ascii": "Fort Belvoir",
    "lat": 38.7119,
    "lng": -77.1459,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 8091,
    "id": 1840073578
  },
  {
    "city": "Kunszentmárton",
    "city_ascii": "Kunszentmarton",
    "lat": 46.84,
    "lng": 20.29,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Jász-Nagykun-Szolnok",
    "capital": "minor",
    "population": 8034,
    "id": 1348331856
  },
  {
    "city": "Ánoixi",
    "city_ascii": "Anoixi",
    "lat": 38.1333,
    "lng": 23.8667,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Attikí",
    "capital": "",
    "population": 6510,
    "id": 1300243964
  },
  {
    "city": "Selah",
    "city_ascii": "Selah",
    "lat": 46.6482,
    "lng": -120.539,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 8087,
    "id": 1840021149
  },
  {
    "city": "Dalhart",
    "city_ascii": "Dalhart",
    "lat": 36.0579,
    "lng": -102.5123,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 8086,
    "id": 1840019052
  },
  {
    "city": "Alliance",
    "city_ascii": "Alliance",
    "lat": 42.1025,
    "lng": -102.8766,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Nebraska",
    "capital": "",
    "population": 8086,
    "id": 1840006975
  },
  {
    "city": "Hammerfest",
    "city_ascii": "Hammerfest",
    "lat": 70.6634,
    "lng": 23.6821,
    "country": "Norway",
    "iso2": "NO",
    "iso3": "NOR",
    "admin_name": "Finnmark",
    "capital": "minor",
    "population": 8073,
    "id": 1578368356
  },
  {
    "city": "Gray",
    "city_ascii": "Gray",
    "lat": 43.8877,
    "lng": -70.3494,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maine",
    "capital": "",
    "population": 8085,
    "id": 1840052837
  },
  {
    "city": "Niagara",
    "city_ascii": "Niagara",
    "lat": 43.1154,
    "lng": -78.981,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 8084,
    "id": 1840087724
  },
  {
    "city": "Shorewood",
    "city_ascii": "Shorewood",
    "lat": 44.9033,
    "lng": -93.5903,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 8084,
    "id": 1840008925
  },
  {
    "city": "Saint-Hippolyte",
    "city_ascii": "Saint-Hippolyte",
    "lat": 45.93,
    "lng": -74.02,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 8083,
    "id": 1124001758
  },
  {
    "city": "Chester",
    "city_ascii": "Chester",
    "lat": 37.9199,
    "lng": -89.8259,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 8082,
    "id": 1840007529
  },
  {
    "city": "Águas da Prata",
    "city_ascii": "Aguas da Prata",
    "lat": -21.9369,
    "lng": -46.7169,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 8025,
    "id": 1076783240
  },
  {
    "city": "Yungay",
    "city_ascii": "Yungay",
    "lat": -9.1389,
    "lng": -77.7444,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "Ancash",
    "capital": "",
    "population": 8000,
    "id": 1604138857
  },
  {
    "city": "Kronenwetter",
    "city_ascii": "Kronenwetter",
    "lat": 44.8164,
    "lng": -89.5807,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 8079,
    "id": 1840038099
  },
  {
    "city": "Schweich",
    "city_ascii": "Schweich",
    "lat": 49.82,
    "lng": 6.7522,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Rhineland-Palatinate",
    "capital": "",
    "population": 7827,
    "id": 1276273892
  },
  {
    "city": "Sezimovo Ústí",
    "city_ascii": "Sezimovo Usti",
    "lat": 49.3853,
    "lng": 14.6848,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Jihočeský Kraj",
    "capital": "",
    "population": 7221,
    "id": 1203838425
  },
  {
    "city": "Gilberts",
    "city_ascii": "Gilberts",
    "lat": 42.1096,
    "lng": -88.3716,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 8076,
    "id": 1840011348
  },
  {
    "city": "Bargersville",
    "city_ascii": "Bargersville",
    "lat": 39.5412,
    "lng": -86.2004,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 8076,
    "id": 1840009569
  },
  {
    "city": "Treuen",
    "city_ascii": "Treuen",
    "lat": 50.5425,
    "lng": 12.3022,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 7894,
    "id": 1276727865
  },
  {
    "city": "Hampton",
    "city_ascii": "Hampton",
    "lat": 33.3835,
    "lng": -84.2855,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 8073,
    "id": 1840013751
  },
  {
    "city": "Rockmart",
    "city_ascii": "Rockmart",
    "lat": 34.0103,
    "lng": -85.0441,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 8071,
    "id": 1840014753
  },
  {
    "city": "Corella",
    "city_ascii": "Corella",
    "lat": 42.1147,
    "lng": -1.7867,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Navarre",
    "capital": "",
    "population": 7971,
    "id": 1724452724
  },
  {
    "city": "South Cleveland",
    "city_ascii": "South Cleveland",
    "lat": 35.1097,
    "lng": -84.9097,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 8069,
    "id": 1840036101
  },
  {
    "city": "Helena Valley Southeast",
    "city_ascii": "Helena Valley Southeast",
    "lat": 46.6175,
    "lng": -111.9186,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Montana",
    "capital": "",
    "population": 8067,
    "id": 1840074075
  },
  {
    "city": "Wyoming",
    "city_ascii": "Wyoming",
    "lat": 45.3365,
    "lng": -92.9766,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 8066,
    "id": 1840009992
  },
  {
    "city": "Old Jefferson",
    "city_ascii": "Old Jefferson",
    "lat": 30.3776,
    "lng": -91.006,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 8065,
    "id": 1840031108
  },
  {
    "city": "Lake Dallas",
    "city_ascii": "Lake Dallas",
    "lat": 33.1277,
    "lng": -97.0234,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 8063,
    "id": 1840020646
  },
  {
    "city": "Haiku-Pauwela",
    "city_ascii": "Haiku-Pauwela",
    "lat": 20.9156,
    "lng": -156.3022,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Hawaii",
    "capital": "",
    "population": 8061,
    "id": 1840074990
  },
  {
    "city": "Bloomingdale",
    "city_ascii": "Bloomingdale",
    "lat": 41.03,
    "lng": -74.3319,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 8061,
    "id": 1840000832
  },
  {
    "city": "Ramstein-Miesenbach",
    "city_ascii": "Ramstein-Miesenbach",
    "lat": 49.4461,
    "lng": 7.5547,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Rhineland-Palatinate",
    "capital": "",
    "population": 7876,
    "id": 1276693089
  },
  {
    "city": "Rolling Hills Estates",
    "city_ascii": "Rolling Hills Estates",
    "lat": 33.7783,
    "lng": -118.3509,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 8058,
    "id": 1840020494
  },
  {
    "city": "Tapiraí",
    "city_ascii": "Tapirai",
    "lat": -23.9639,
    "lng": -47.5069,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 8047,
    "id": 1076172303
  },
  {
    "city": "Tamási",
    "city_ascii": "Tamasi",
    "lat": 46.6167,
    "lng": 18.3,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Tolna",
    "capital": "minor",
    "population": 7986,
    "id": 1348722062
  },
  {
    "city": "Willits",
    "city_ascii": "Willits",
    "lat": 39.4047,
    "lng": -123.3494,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 8055,
    "id": 1840021421
  },
  {
    "city": "Clinton",
    "city_ascii": "Clinton",
    "lat": 40.1468,
    "lng": -88.9633,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 8055,
    "id": 1840007255
  },
  {
    "city": "Ayer",
    "city_ascii": "Ayer",
    "lat": 42.5628,
    "lng": -71.5718,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 8055,
    "id": 1840053473
  },
  {
    "city": "Chişineu Criş",
    "city_ascii": "Chisineu Cris",
    "lat": 46.5225,
    "lng": 21.5158,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Arad",
    "capital": "",
    "population": 7987,
    "id": 1642472426
  },
  {
    "city": "Námestovo",
    "city_ascii": "Namestovo",
    "lat": 49.41,
    "lng": 19.48,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Žilinský",
    "capital": "minor",
    "population": 7876,
    "id": 1703178058
  },
  {
    "city": "Sterling",
    "city_ascii": "Sterling",
    "lat": 42.4412,
    "lng": -71.773,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 8052,
    "id": 1840053700
  },
  {
    "city": "Decorah",
    "city_ascii": "Decorah",
    "lat": 43.3015,
    "lng": -91.7844,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Iowa",
    "capital": "",
    "population": 8051,
    "id": 1840006864
  },
  {
    "city": "Elsterwerda",
    "city_ascii": "Elsterwerda",
    "lat": 51.4578,
    "lng": 13.5239,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Brandenburg",
    "capital": "",
    "population": 7856,
    "id": 1276068223
  },
  {
    "city": "Iepê",
    "city_ascii": "Iepe",
    "lat": -22.6606,
    "lng": -51.0761,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 8036,
    "id": 1076650411
  },
  {
    "city": "Clydach",
    "city_ascii": "Clydach",
    "lat": 51.69,
    "lng": -3.91,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Swansea",
    "capital": "",
    "population": 7503,
    "id": 1826033886
  },
  {
    "city": "Brookhaven",
    "city_ascii": "Brookhaven",
    "lat": 39.8715,
    "lng": -75.3918,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 8049,
    "id": 1840000689
  },
  {
    "city": "Macaubal",
    "city_ascii": "Macaubal",
    "lat": -20.8058,
    "lng": -49.9639,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 8033,
    "id": 1076533042
  },
  {
    "city": "Åkrehamn",
    "city_ascii": "Akrehamn",
    "lat": 59.2603,
    "lng": 5.1856,
    "country": "Norway",
    "iso2": "NO",
    "iso3": "NOR",
    "admin_name": "Rogaland",
    "capital": "",
    "population": 7873,
    "id": 1578208239
  },
  {
    "city": "Ripley",
    "city_ascii": "Ripley",
    "lat": 35.7449,
    "lng": -89.5358,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 8045,
    "id": 1840014509
  },
  {
    "city": "Chardon",
    "city_ascii": "Chardon",
    "lat": 41.5803,
    "lng": -81.2082,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 8044,
    "id": 1840010219
  },
  {
    "city": "Morehead",
    "city_ascii": "Morehead",
    "lat": 38.1906,
    "lng": -83.4467,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kentucky",
    "capital": "",
    "population": 8044,
    "id": 1840014291
  },
  {
    "city": "Mihăileşti",
    "city_ascii": "Mihailesti",
    "lat": 44.3239,
    "lng": 25.9069,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Giurgiu",
    "capital": "",
    "population": 7923,
    "id": 1642433164
  },
  {
    "city": "Lancaster",
    "city_ascii": "Lancaster",
    "lat": 42.4817,
    "lng": -71.6808,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 8043,
    "id": 1840053591
  },
  {
    "city": "Angmering",
    "city_ascii": "Angmering",
    "lat": 50.8288,
    "lng": -0.4846,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "West Sussex",
    "capital": "",
    "population": 7614,
    "id": 1826030758
  },
  {
    "city": "Ripon",
    "city_ascii": "Ripon",
    "lat": 43.8436,
    "lng": -88.8387,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 8040,
    "id": 1840002609
  },
  {
    "city": "Rheinsberg",
    "city_ascii": "Rheinsberg",
    "lat": 53.0983,
    "lng": 12.8958,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Brandenburg",
    "capital": "",
    "population": 8015,
    "id": 1276639991
  },
  {
    "city": "Castlegar",
    "city_ascii": "Castlegar",
    "lat": 49.3256,
    "lng": -117.6661,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "British Columbia",
    "capital": "",
    "population": 8039,
    "id": 1124379972
  },
  {
    "city": "Titchfield",
    "city_ascii": "Titchfield",
    "lat": 50.8491,
    "lng": -1.2344,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hampshire",
    "capital": "",
    "population": 7280,
    "id": 1826908062
  },
  {
    "city": "Bad Düben",
    "city_ascii": "Bad Duben",
    "lat": 51.5919,
    "lng": 12.5853,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 7865,
    "id": 1276208860
  },
  {
    "city": "Wading River",
    "city_ascii": "Wading River",
    "lat": 40.9464,
    "lng": -72.823,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 8034,
    "id": 1840005107
  },
  {
    "city": "McGregor",
    "city_ascii": "McGregor",
    "lat": 26.5611,
    "lng": -81.9134,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 8032,
    "id": 1840029055
  },
  {
    "city": "Northfield",
    "city_ascii": "Northfield",
    "lat": 39.3718,
    "lng": -74.5543,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 8031,
    "id": 1840001538
  },
  {
    "city": "Altomünster",
    "city_ascii": "Altomunster",
    "lat": 48.3833,
    "lng": 11.25,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 7925,
    "id": 1276488416
  },
  {
    "city": "Boston",
    "city_ascii": "Boston",
    "lat": 42.6528,
    "lng": -78.7555,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 8029,
    "id": 1840023297
  },
  {
    "city": "Menominee",
    "city_ascii": "Menominee",
    "lat": 45.122,
    "lng": -87.6234,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 8029,
    "id": 1840002006
  },
  {
    "city": "Heyin",
    "city_ascii": "Heyin",
    "lat": 36.0451,
    "lng": 101.4242,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Qinghai",
    "capital": "minor",
    "population": 7642,
    "id": 1156514054
  },
  {
    "city": "Collier",
    "city_ascii": "Collier",
    "lat": 40.3991,
    "lng": -80.132,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 8027,
    "id": 1840142357
  },
  {
    "city": "Svoge",
    "city_ascii": "Svoge",
    "lat": 42.9594,
    "lng": 23.3477,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Sofia",
    "capital": "",
    "population": 8003,
    "id": 1100064054
  },
  {
    "city": "Pleasant Hills",
    "city_ascii": "Pleasant Hills",
    "lat": 40.3298,
    "lng": -79.9596,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 8026,
    "id": 1840001255
  },
  {
    "city": "Pahokee",
    "city_ascii": "Pahokee",
    "lat": 26.8202,
    "lng": -80.662,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 8025,
    "id": 1840015124
  },
  {
    "city": "Insar",
    "city_ascii": "Insar",
    "lat": 53.8667,
    "lng": 44.3667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Mordoviya",
    "capital": "",
    "population": 8010,
    "id": 1643619919
  },
  {
    "city": "Crete",
    "city_ascii": "Crete",
    "lat": 41.44,
    "lng": -87.6236,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 8023,
    "id": 1840011484
  },
  {
    "city": "Fruitvale",
    "city_ascii": "Fruitvale",
    "lat": 39.0933,
    "lng": -108.4789,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 8021,
    "id": 1840017557
  },
  {
    "city": "Lagkadás",
    "city_ascii": "Lagkadas",
    "lat": 40.75,
    "lng": 23.0667,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Kentrikí Makedonía",
    "capital": "minor",
    "population": 7764,
    "id": 1300163444
  },
  {
    "city": "Topolobampo",
    "city_ascii": "Topolobampo",
    "lat": 25.6056,
    "lng": -109.05,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Sinaloa",
    "capital": "",
    "population": 6361,
    "id": 1484652048
  },
  {
    "city": "Mamonovo",
    "city_ascii": "Mamonovo",
    "lat": 54.4667,
    "lng": 19.9333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kaliningradskaya Oblast’",
    "capital": "",
    "population": 7953,
    "id": 1643953757
  },
  {
    "city": "Chestnut Ridge",
    "city_ascii": "Chestnut Ridge",
    "lat": 41.0829,
    "lng": -74.0551,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 8020,
    "id": 1840004992
  },
  {
    "city": "Island Lake",
    "city_ascii": "Island Lake",
    "lat": 42.2783,
    "lng": -88.1999,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 8020,
    "id": 1840011155
  },
  {
    "city": "Hamilton",
    "city_ascii": "Hamilton",
    "lat": 42.6267,
    "lng": -70.858,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 8020,
    "id": 1840070395
  },
  {
    "city": "Icém",
    "city_ascii": "Icem",
    "lat": -20.3333,
    "lng": -49.1833,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 7997,
    "id": 1076303473
  },
  {
    "city": "Church Point",
    "city_ascii": "Church Point",
    "lat": 44.3333,
    "lng": -66.1167,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Nova Scotia",
    "capital": "",
    "population": 8018,
    "id": 1124316445
  },
  {
    "city": "Lochgelly",
    "city_ascii": "Lochgelly",
    "lat": 56.1282,
    "lng": -3.3111,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Fife",
    "capital": "",
    "population": 6834,
    "id": 1826860287
  },
  {
    "city": "Oak Brook",
    "city_ascii": "Oak Brook",
    "lat": 41.8372,
    "lng": -87.9513,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 8016,
    "id": 1840011408
  },
  {
    "city": "Penalva do Castelo",
    "city_ascii": "Penalva do Castelo",
    "lat": 40.6667,
    "lng": -7.6833,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Viseu",
    "capital": "minor",
    "population": 7956,
    "id": 1620532055
  },
  {
    "city": "Zeltweg",
    "city_ascii": "Zeltweg",
    "lat": 47.1906,
    "lng": 14.7511,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Steiermark",
    "capital": "",
    "population": 7212,
    "id": 1040000110
  },
  {
    "city": "Cherykaw",
    "city_ascii": "Cherykaw",
    "lat": 53.5667,
    "lng": 31.3667,
    "country": "Belarus",
    "iso2": "BY",
    "iso3": "BLR",
    "admin_name": "Mahilyowskaya Voblasts’",
    "capital": "minor",
    "population": 8000,
    "id": 1112416616
  },
  {
    "city": "Seaford",
    "city_ascii": "Seaford",
    "lat": 38.6538,
    "lng": -75.611,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Delaware",
    "capital": "",
    "population": 8013,
    "id": 1840006071
  },
  {
    "city": "Neresheim",
    "city_ascii": "Neresheim",
    "lat": 48.7542,
    "lng": 10.3344,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 7945,
    "id": 1276988797
  },
  {
    "city": "Narre Warren North",
    "city_ascii": "Narre Warren North",
    "lat": -37.9833,
    "lng": 145.3167,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Victoria",
    "capital": "",
    "population": 7674,
    "id": 1036476634
  },
  {
    "city": "Byram",
    "city_ascii": "Byram",
    "lat": 40.9567,
    "lng": -74.7182,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 8010,
    "id": 1840081782
  },
  {
    "city": "Antoing",
    "city_ascii": "Antoing",
    "lat": 50.5669,
    "lng": 3.4494,
    "country": "Belgium",
    "iso2": "BE",
    "iso3": "BEL",
    "admin_name": "Wallonia",
    "capital": "",
    "population": 7760,
    "id": 1056797229
  },
  {
    "city": "Millom",
    "city_ascii": "Millom",
    "lat": 54.2096,
    "lng": -3.2702,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Cumbria",
    "capital": "",
    "population": 7829,
    "id": 1826714160
  },
  {
    "city": "Loftus",
    "city_ascii": "Loftus",
    "lat": 54.5569,
    "lng": -0.8919,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Redcar and Cleveland",
    "capital": "",
    "population": 7988,
    "id": 1826443898
  },
  {
    "city": "Crediton",
    "city_ascii": "Crediton",
    "lat": 50.791,
    "lng": -3.656,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Devon",
    "capital": "",
    "population": 7835,
    "id": 1826309219
  },
  {
    "city": "Pershore",
    "city_ascii": "Pershore",
    "lat": 52.114,
    "lng": -2.08,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Worcestershire",
    "capital": "",
    "population": 7125,
    "id": 1826473864
  },
  {
    "city": "Nurmes",
    "city_ascii": "Nurmes",
    "lat": 63.5444,
    "lng": 29.1333,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Pohjois-Karjala",
    "capital": "minor",
    "population": 7996,
    "id": 1246620108
  },
  {
    "city": "Firebaugh",
    "city_ascii": "Firebaugh",
    "lat": 36.8534,
    "lng": -120.4536,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 8000,
    "id": 1840020317
  },
  {
    "city": "Sudden Valley",
    "city_ascii": "Sudden Valley",
    "lat": 48.7199,
    "lng": -122.3468,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 7999,
    "id": 1840037740
  },
  {
    "city": "Nikolsk",
    "city_ascii": "Nikolsk",
    "lat": 59.5333,
    "lng": 45.45,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Vologodskaya Oblast’",
    "capital": "",
    "population": 7996,
    "id": 1643649423
  },
  {
    "city": "Berchtesgaden",
    "city_ascii": "Berchtesgaden",
    "lat": 47.6314,
    "lng": 13.0042,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 7780,
    "id": 1276107615
  },
  {
    "city": "North Coventry",
    "city_ascii": "North Coventry",
    "lat": 40.2199,
    "lng": -75.6817,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 7996,
    "id": 1840141675
  },
  {
    "city": "Clementina",
    "city_ascii": "Clementina",
    "lat": -21.5597,
    "lng": -50.4492,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 7992,
    "id": 1076874694
  },
  {
    "city": "Bützow",
    "city_ascii": "Butzow",
    "lat": 53.85,
    "lng": 11.9833,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Mecklenburg-Western Pomerania",
    "capital": "",
    "population": 7799,
    "id": 1276827819
  },
  {
    "city": "Almyrós",
    "city_ascii": "Almyros",
    "lat": 39.18,
    "lng": 22.76,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Thessalía",
    "capital": "minor",
    "population": 7955,
    "id": 1300996538
  },
  {
    "city": "Brazil",
    "city_ascii": "Brazil",
    "lat": 39.5226,
    "lng": -87.124,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 7993,
    "id": 1840005744
  },
  {
    "city": "Iaras",
    "city_ascii": "Iaras",
    "lat": -22.8667,
    "lng": -49.1667,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 7970,
    "id": 1076892374
  },
  {
    "city": "Aberdeen",
    "city_ascii": "Aberdeen",
    "lat": 35.135,
    "lng": -79.4326,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 7988,
    "id": 1840015433
  },
  {
    "city": "Jefferson",
    "city_ascii": "Jefferson",
    "lat": 43.0044,
    "lng": -88.8084,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 7986,
    "id": 1840003001
  },
  {
    "city": "Lillesand",
    "city_ascii": "Lillesand",
    "lat": 58.2488,
    "lng": 8.3778,
    "country": "Norway",
    "iso2": "NO",
    "iso3": "NOR",
    "admin_name": "Aust-Agder",
    "capital": "minor",
    "population": 7930,
    "id": 1578656800
  },
  {
    "city": "Drumheller",
    "city_ascii": "Drumheller",
    "lat": 51.4636,
    "lng": -112.7194,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Alberta",
    "capital": "",
    "population": 7982,
    "id": 1124745292
  },
  {
    "city": "Kirkland Lake",
    "city_ascii": "Kirkland Lake",
    "lat": 48.15,
    "lng": -80.0333,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 7981,
    "id": 1124683504
  },
  {
    "city": "Fīrūraq",
    "city_ascii": "Firuraq",
    "lat": 38.58,
    "lng": 44.8358,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Āz̄arbāyjān-e Gharbī",
    "capital": "",
    "population": 7903,
    "id": 1364137173
  },
  {
    "city": "Krupina",
    "city_ascii": "Krupina",
    "lat": 48.35,
    "lng": 19.0669,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Banskobystrický",
    "capital": "minor",
    "population": 7890,
    "id": 1703921196
  },
  {
    "city": "Rychvald",
    "city_ascii": "Rychvald",
    "lat": 49.8662,
    "lng": 18.3763,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Moravskoslezský Kraj",
    "capital": "",
    "population": 7536,
    "id": 1203089321
  },
  {
    "city": "Prien",
    "city_ascii": "Prien",
    "lat": 30.1601,
    "lng": -93.2611,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 7976,
    "id": 1840013963
  },
  {
    "city": "Jackson Township",
    "city_ascii": "Jackson Township",
    "lat": 39.9057,
    "lng": -76.8796,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 7976,
    "id": 1840151964
  },
  {
    "city": "Ide",
    "city_ascii": "Ide",
    "lat": 34.7986,
    "lng": 135.8033,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Kyōto",
    "capital": "",
    "population": 7553,
    "id": 1392428784
  },
  {
    "city": "Koufália",
    "city_ascii": "Koufalia",
    "lat": 40.7792,
    "lng": 22.5767,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Kentrikí Makedonía",
    "capital": "minor",
    "population": 7850,
    "id": 1300114101
  },
  {
    "city": "Polgár",
    "city_ascii": "Polgar",
    "lat": 47.8663,
    "lng": 21.1242,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Hajdú-Bihar",
    "capital": "",
    "population": 7889,
    "id": 1348871159
  },
  {
    "city": "Artemivsk",
    "city_ascii": "Artemivsk",
    "lat": 48.4403,
    "lng": 38.7422,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Luhans’ka Oblast’",
    "capital": "",
    "population": 7506,
    "id": 1804988052
  },
  {
    "city": "Williamsburg",
    "city_ascii": "Williamsburg",
    "lat": 28.4015,
    "lng": -81.4461,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 7967,
    "id": 1840029100
  },
  {
    "city": "Brentwood",
    "city_ascii": "Brentwood",
    "lat": 38.6194,
    "lng": -90.3476,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 7966,
    "id": 1840006128
  },
  {
    "city": "Chalford",
    "city_ascii": "Chalford",
    "lat": 51.7238,
    "lng": -2.1491,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Gloucestershire",
    "capital": "",
    "population": 6215,
    "id": 1826660998
  },
  {
    "city": "San Felipe Tejalapan",
    "city_ascii": "San Felipe Tejalapan",
    "lat": 17.1111,
    "lng": -96.8542,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Oaxaca",
    "capital": "",
    "population": 7862,
    "id": 1484085457
  },
  {
    "city": "Aztec",
    "city_ascii": "Aztec",
    "lat": 36.819,
    "lng": -107.9826,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Mexico",
    "capital": "",
    "population": 7963,
    "id": 1840018993
  },
  {
    "city": "La Junta",
    "city_ascii": "La Junta",
    "lat": 37.9792,
    "lng": -103.5473,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 7963,
    "id": 1840020270
  },
  {
    "city": "Kinchil",
    "city_ascii": "Kinchil",
    "lat": 20.9161,
    "lng": -89.9469,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Yucatán",
    "capital": "",
    "population": 6307,
    "id": 1484602892
  },
  {
    "city": "Altenberg",
    "city_ascii": "Altenberg",
    "lat": 50.7644,
    "lng": 13.7578,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 7908,
    "id": 1276544160
  },
  {
    "city": "Brockworth",
    "city_ascii": "Brockworth",
    "lat": 51.85,
    "lng": -2.15,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Gloucestershire",
    "capital": "",
    "population": 7387,
    "id": 1826331782
  },
  {
    "city": "Dehaq",
    "city_ascii": "Dehaq",
    "lat": 33.1044,
    "lng": 50.9589,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Eşfahān",
    "capital": "",
    "population": 7828,
    "id": 1364662944
  },
  {
    "city": "Baltimore Highlands",
    "city_ascii": "Baltimore Highlands",
    "lat": 39.2355,
    "lng": -76.6367,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 7959,
    "id": 1840024447
  },
  {
    "city": "South Londonderry",
    "city_ascii": "South Londonderry",
    "lat": 40.2424,
    "lng": -76.5432,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 7958,
    "id": 1840149648
  },
  {
    "city": "Denny",
    "city_ascii": "Denny",
    "lat": 56.018,
    "lng": -3.907,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Falkirk",
    "capital": "",
    "population": 7934,
    "id": 1826810259
  },
  {
    "city": "Bosanski Petrovac",
    "city_ascii": "Bosanski Petrovac",
    "lat": 44.55,
    "lng": 16.3667,
    "country": "Bosnia And Herzegovina",
    "iso2": "BA",
    "iso3": "BIH",
    "admin_name": "Bosnia and Herzegovina, Federation of",
    "capital": "minor",
    "population": 7946,
    "id": 1070850291
  },
  {
    "city": "Southwest Ranches",
    "city_ascii": "Southwest Ranches",
    "lat": 26.0476,
    "lng": -80.375,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 7957,
    "id": 1840018328
  },
  {
    "city": "Quincy",
    "city_ascii": "Quincy",
    "lat": 30.5659,
    "lng": -84.5857,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 7957,
    "id": 1840015022
  },
  {
    "city": "Nejdek",
    "city_ascii": "Nejdek",
    "lat": 50.3225,
    "lng": 12.7294,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Karlovarský Kraj",
    "capital": "",
    "population": 7806,
    "id": 1203575913
  },
  {
    "city": "Richland Hills",
    "city_ascii": "Richland Hills",
    "lat": 32.8095,
    "lng": -97.2273,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 7953,
    "id": 1840020700
  },
  {
    "city": "Maryville",
    "city_ascii": "Maryville",
    "lat": 38.7261,
    "lng": -89.9646,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 7952,
    "id": 1840012801
  },
  {
    "city": "Bad Liebenstein",
    "city_ascii": "Bad Liebenstein",
    "lat": 50.8144,
    "lng": 10.3542,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Thuringia",
    "capital": "",
    "population": 7786,
    "id": 1276199299
  },
  {
    "city": "Chartiers",
    "city_ascii": "Chartiers",
    "lat": 40.2504,
    "lng": -80.2495,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 7945,
    "id": 1840151797
  },
  {
    "city": "Porterville",
    "city_ascii": "Porterville",
    "lat": -33,
    "lng": 18.9833,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "Western Cape",
    "capital": "",
    "population": 7057,
    "id": 1710211314
  },
  {
    "city": "Mrkonjić Grad",
    "city_ascii": "Mrkonjic Grad",
    "lat": 44.4167,
    "lng": 17.0833,
    "country": "Bosnia And Herzegovina",
    "iso2": "BA",
    "iso3": "BIH",
    "admin_name": "Srpska, Republika",
    "capital": "minor",
    "population": 7915,
    "id": 1070046769
  },
  {
    "city": "West Boylston",
    "city_ascii": "West Boylston",
    "lat": 42.369,
    "lng": -71.7846,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 7941,
    "id": 1840053612
  },
  {
    "city": "Herzogenburg",
    "city_ascii": "Herzogenburg",
    "lat": 48.2833,
    "lng": 15.6833,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Niederösterreich",
    "capital": "",
    "population": 7771,
    "id": 1040570527
  },
  {
    "city": "Liptovský Hrádok",
    "city_ascii": "Liptovsky Hradok",
    "lat": 49.0394,
    "lng": 19.7244,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Žilinský",
    "capital": "",
    "population": 7528,
    "id": 1703762165
  },
  {
    "city": "Templeton",
    "city_ascii": "Templeton",
    "lat": 35.556,
    "lng": -120.7182,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 7938,
    "id": 1840019127
  },
  {
    "city": "Newport-On-Tay",
    "city_ascii": "Newport-On-Tay",
    "lat": 56.4402,
    "lng": -2.941,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Fife",
    "capital": "",
    "population": 7922,
    "id": 1826832202
  },
  {
    "city": "Higham Ferrers",
    "city_ascii": "Higham Ferrers",
    "lat": 52.3057,
    "lng": -0.5844,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Northamptonshire",
    "capital": "",
    "population": 7145,
    "id": 1826895941
  },
  {
    "city": "Hillside",
    "city_ascii": "Hillside",
    "lat": 41.8674,
    "lng": -87.9019,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 7933,
    "id": 1840011261
  },
  {
    "city": "Hüfingen",
    "city_ascii": "Hufingen",
    "lat": 47.9261,
    "lng": 8.49,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 7799,
    "id": 1276003064
  },
  {
    "city": "Columbiana",
    "city_ascii": "Columbiana",
    "lat": 40.8871,
    "lng": -80.675,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 7931,
    "id": 1840007175
  },
  {
    "city": "Batesville",
    "city_ascii": "Batesville",
    "lat": 39.2974,
    "lng": -85.2139,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 7930,
    "id": 1840007379
  },
  {
    "city": "Logan",
    "city_ascii": "Logan",
    "lat": 39.5386,
    "lng": -82.4063,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 7930,
    "id": 1840009566
  },
  {
    "city": "Yuzawa",
    "city_ascii": "Yuzawa",
    "lat": 36.934,
    "lng": 138.8174,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Niigata",
    "capital": "",
    "population": 7907,
    "id": 1392803827
  },
  {
    "city": "Našice",
    "city_ascii": "Nasice",
    "lat": 45.4833,
    "lng": 18.1,
    "country": "Croatia",
    "iso2": "HR",
    "iso3": "HRV",
    "admin_name": "Osječko-Baranjska Županija",
    "capital": "minor",
    "population": 7888,
    "id": 1191359646
  },
  {
    "city": "Sheboygan Falls",
    "city_ascii": "Sheboygan Falls",
    "lat": 43.729,
    "lng": -87.8266,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 7926,
    "id": 1840002629
  },
  {
    "city": "Lyubimets",
    "city_ascii": "Lyubimets",
    "lat": 41.8333,
    "lng": 26.0833,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Haskovo",
    "capital": "",
    "population": 7892,
    "id": 1100823795
  },
  {
    "city": "Yūbari",
    "city_ascii": "Yubari",
    "lat": 43.0569,
    "lng": 141.9739,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hokkaidō",
    "capital": "",
    "population": 7908,
    "id": 1392938739
  },
  {
    "city": "Delta",
    "city_ascii": "Delta",
    "lat": 38.756,
    "lng": -108.0772,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 7918,
    "id": 1840018820
  },
  {
    "city": "Hitchcock",
    "city_ascii": "Hitchcock",
    "lat": 29.2945,
    "lng": -95.025,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 7914,
    "id": 1840020971
  },
  {
    "city": "Bollington",
    "city_ascii": "Bollington",
    "lat": 53.298,
    "lng": -2.093,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Cheshire East",
    "capital": "",
    "population": 7885,
    "id": 1826739593
  },
  {
    "city": "Myory",
    "city_ascii": "Myory",
    "lat": 55.6167,
    "lng": 27.6167,
    "country": "Belarus",
    "iso2": "BY",
    "iso3": "BLR",
    "admin_name": "Vitsyebskaya Voblasts’",
    "capital": "minor",
    "population": 7900,
    "id": 1112297787
  },
  {
    "city": "Slawharad",
    "city_ascii": "Slawharad",
    "lat": 53.4453,
    "lng": 30.9964,
    "country": "Belarus",
    "iso2": "BY",
    "iso3": "BLR",
    "admin_name": "Mahilyowskaya Voblasts’",
    "capital": "minor",
    "population": 7900,
    "id": 1112995791
  },
  {
    "city": "Mandurah",
    "city_ascii": "Mandurah",
    "lat": -32.5289,
    "lng": 115.7231,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Western Australia",
    "capital": "",
    "population": 7837,
    "id": 1036773118
  },
  {
    "city": "Gorodishche",
    "city_ascii": "Gorodishche",
    "lat": 53.2667,
    "lng": 45.7,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Penzenskaya Oblast’",
    "capital": "",
    "population": 7885,
    "id": 1643336903
  },
  {
    "city": "Sontra",
    "city_ascii": "Sontra",
    "lat": 51.0667,
    "lng": 9.9333,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 7839,
    "id": 1276109976
  },
  {
    "city": "Lake Shore",
    "city_ascii": "Lake Shore",
    "lat": 45.6911,
    "lng": -122.6911,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 7909,
    "id": 1840037873
  },
  {
    "city": "Dryanovo",
    "city_ascii": "Dryanovo",
    "lat": 42.9736,
    "lng": 25.4747,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Gabrovo",
    "capital": "",
    "population": 7908,
    "id": 1100541319
  },
  {
    "city": "Evergreen",
    "city_ascii": "Evergreen",
    "lat": 48.2308,
    "lng": -114.2701,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Montana",
    "capital": "",
    "population": 7907,
    "id": 1840017297
  },
  {
    "city": "Bonner Springs",
    "city_ascii": "Bonner Springs",
    "lat": 39.0817,
    "lng": -94.8776,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kansas",
    "capital": "",
    "population": 7906,
    "id": 1840001624
  },
  {
    "city": "Camp Hill",
    "city_ascii": "Camp Hill",
    "lat": 40.2423,
    "lng": -76.9274,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 7905,
    "id": 1840001385
  },
  {
    "city": "Long Grove",
    "city_ascii": "Long Grove",
    "lat": 42.1968,
    "lng": -88.0057,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 7905,
    "id": 1840011164
  },
  {
    "city": "São João da Pesqueira",
    "city_ascii": "Sao Joao da Pesqueira",
    "lat": 41.1333,
    "lng": -7.4,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Viseu",
    "capital": "minor",
    "population": 7874,
    "id": 1620541810
  },
  {
    "city": "Camp Pendleton North",
    "city_ascii": "Camp Pendleton North",
    "lat": 33.3148,
    "lng": -117.3162,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 7903,
    "id": 1840073901
  },
  {
    "city": "Wilmington Manor",
    "city_ascii": "Wilmington Manor",
    "lat": 39.6858,
    "lng": -75.5849,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Delaware",
    "capital": "",
    "population": 7902,
    "id": 1840005575
  },
  {
    "city": "Stříbro",
    "city_ascii": "Stribro",
    "lat": 49.753,
    "lng": 13.0041,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Plzeňský Kraj",
    "capital": "",
    "population": 7738,
    "id": 1203206834
  },
  {
    "city": "Tura",
    "city_ascii": "Tura",
    "lat": 47.6067,
    "lng": 19.5967,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Pest",
    "capital": "",
    "population": 7761,
    "id": 1348464950
  },
  {
    "city": "Argyle",
    "city_ascii": "Argyle",
    "lat": 43.8,
    "lng": -65.85,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Nova Scotia",
    "capital": "",
    "population": 7899,
    "id": 1124503052
  },
  {
    "city": "Torbay",
    "city_ascii": "Torbay",
    "lat": 47.65,
    "lng": -52.7333,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Newfoundland and Labrador",
    "capital": "",
    "population": 7899,
    "id": 1124406642
  },
  {
    "city": "Downingtown",
    "city_ascii": "Downingtown",
    "lat": 40.0076,
    "lng": -75.7019,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 7897,
    "id": 1840001425
  },
  {
    "city": "Grand Ledge",
    "city_ascii": "Grand Ledge",
    "lat": 42.7534,
    "lng": -84.7448,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 7896,
    "id": 1840003109
  },
  {
    "city": "Stony Stratford",
    "city_ascii": "Stony Stratford",
    "lat": 52.0567,
    "lng": -0.8526,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Milton Keynes",
    "capital": "",
    "population": 7736,
    "id": 1826316765
  },
  {
    "city": "Saltinho",
    "city_ascii": "Saltinho",
    "lat": -22.8469,
    "lng": -47.6769,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 7818,
    "id": 1076409109
  },
  {
    "city": "Sándorfalva",
    "city_ascii": "Sandorfalva",
    "lat": 46.3667,
    "lng": 20.1,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Csongrád",
    "capital": "",
    "population": 7756,
    "id": 1348647328
  },
  {
    "city": "Hrádek nad Nisou",
    "city_ascii": "Hradek nad Nisou",
    "lat": 50.8529,
    "lng": 14.8447,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Liberecký Kraj",
    "capital": "",
    "population": 7735,
    "id": 1203549927
  },
  {
    "city": "Waller",
    "city_ascii": "Waller",
    "lat": 47.2035,
    "lng": -122.3699,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 7894,
    "id": 1840037889
  },
  {
    "city": "Old Forge",
    "city_ascii": "Old Forge",
    "lat": 41.3705,
    "lng": -75.7409,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 7894,
    "id": 1840003387
  },
  {
    "city": "Lincolnshire",
    "city_ascii": "Lincolnshire",
    "lat": 42.1957,
    "lng": -87.9182,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 7893,
    "id": 1840011162
  },
  {
    "city": "Mansfield Center",
    "city_ascii": "Mansfield Center",
    "lat": 42.0225,
    "lng": -71.218,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 7892,
    "id": 1840073506
  },
  {
    "city": "Naila",
    "city_ascii": "Naila",
    "lat": 50.3299,
    "lng": 11.7083,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 7684,
    "id": 1276627413
  },
  {
    "city": "Jersey Village",
    "city_ascii": "Jersey Village",
    "lat": 29.8903,
    "lng": -95.5721,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 7890,
    "id": 1840019620
  },
  {
    "city": "Itobi",
    "city_ascii": "Itobi",
    "lat": -21.7369,
    "lng": -46.975,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 7831,
    "id": 1076452986
  },
  {
    "city": "Hardyston",
    "city_ascii": "Hardyston",
    "lat": 41.1206,
    "lng": -74.5598,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 7886,
    "id": 1840081788
  },
  {
    "city": "Centerville",
    "city_ascii": "Centerville",
    "lat": 32.6342,
    "lng": -83.6853,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 7884,
    "id": 1840014884
  },
  {
    "city": "Kyneton",
    "city_ascii": "Kyneton",
    "lat": -37.2333,
    "lng": 144.45,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Victoria",
    "capital": "",
    "population": 6951,
    "id": 1036250832
  },
  {
    "city": "Oberlin",
    "city_ascii": "Oberlin",
    "lat": 41.2857,
    "lng": -82.2197,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 7882,
    "id": 1840008209
  },
  {
    "city": "West Long Branch",
    "city_ascii": "West Long Branch",
    "lat": 40.2883,
    "lng": -74.0185,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 7881,
    "id": 1840001370
  },
  {
    "city": "Dilolo",
    "city_ascii": "Dilolo",
    "lat": -10.6833,
    "lng": 22.3333,
    "country": "Congo (Kinshasa)",
    "iso2": "CD",
    "iso3": "COD",
    "admin_name": "Lualaba",
    "capital": "",
    "population": 7854,
    "id": 1180154207
  },
  {
    "city": "Hollis",
    "city_ascii": "Hollis",
    "lat": 42.7494,
    "lng": -71.5834,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Hampshire",
    "capital": "",
    "population": 7880,
    "id": 1840055019
  },
  {
    "city": "Flushing",
    "city_ascii": "Flushing",
    "lat": 43.0637,
    "lng": -83.8403,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 7880,
    "id": 1840002950
  },
  {
    "city": "Trzemeszno",
    "city_ascii": "Trzemeszno",
    "lat": 52.5614,
    "lng": 17.8231,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Wielkopolskie",
    "capital": "",
    "population": 7792,
    "id": 1616843653
  },
  {
    "city": "Middletown",
    "city_ascii": "Middletown",
    "lat": 38.241,
    "lng": -85.5215,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kentucky",
    "capital": "",
    "population": 7877,
    "id": 1840014295
  },
  {
    "city": "Ashland",
    "city_ascii": "Ashland",
    "lat": 37.7596,
    "lng": -77.4715,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 7875,
    "id": 1840006341
  },
  {
    "city": "Burley in Wharfedale",
    "city_ascii": "Burley in Wharfedale",
    "lat": 53.9137,
    "lng": -1.748,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Bradford",
    "capital": "",
    "population": 7041,
    "id": 1826475445
  },
  {
    "city": "Temax",
    "city_ascii": "Temax",
    "lat": 21.1511,
    "lng": -88.9403,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Yucatán",
    "capital": "",
    "population": 6239,
    "id": 1484369863
  },
  {
    "city": "Seminole",
    "city_ascii": "Seminole",
    "lat": 32.7208,
    "lng": -102.6503,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 7871,
    "id": 1840022061
  },
  {
    "city": "Altoona",
    "city_ascii": "Altoona",
    "lat": 44.8029,
    "lng": -91.4385,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 7870,
    "id": 1840002295
  },
  {
    "city": "Tuskegee",
    "city_ascii": "Tuskegee",
    "lat": 32.4395,
    "lng": -85.7139,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 7870,
    "id": 1840004337
  },
  {
    "city": "Mascoutah",
    "city_ascii": "Mascoutah",
    "lat": 38.5192,
    "lng": -89.8044,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 7870,
    "id": 1840008631
  },
  {
    "city": "Grand Blanc",
    "city_ascii": "Grand Blanc",
    "lat": 42.9258,
    "lng": -83.6181,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 7870,
    "id": 1840002951
  },
  {
    "city": "Abingdon",
    "city_ascii": "Abingdon",
    "lat": 36.7089,
    "lng": -81.9713,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 7867,
    "id": 1840006529
  },
  {
    "city": "Wilmington",
    "city_ascii": "Wilmington",
    "lat": 51.4309,
    "lng": 0.1876,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Kent",
    "capital": "",
    "population": 7178,
    "id": 1826420723
  },
  {
    "city": "Kelty",
    "city_ascii": "Kelty",
    "lat": 56.133,
    "lng": -3.38,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Fife",
    "capital": "",
    "population": 6529,
    "id": 1826679593
  },
  {
    "city": "La Pêche",
    "city_ascii": "La Peche",
    "lat": 45.6833,
    "lng": -75.9833,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 7863,
    "id": 1124001249
  },
  {
    "city": "Edenbridge",
    "city_ascii": "Edenbridge",
    "lat": 51.197,
    "lng": 0.065,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Kent",
    "capital": "",
    "population": 7808,
    "id": 1826946540
  },
  {
    "city": "Bridge City",
    "city_ascii": "Bridge City",
    "lat": 30.0298,
    "lng": -93.8406,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 7862,
    "id": 1840019609
  },
  {
    "city": "Vandenberg Village",
    "city_ascii": "Vandenberg Village",
    "lat": 34.7111,
    "lng": -120.4623,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 7861,
    "id": 1840019185
  },
  {
    "city": "Abira",
    "city_ascii": "Abira",
    "lat": 42.7628,
    "lng": 141.8181,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hokkaidō",
    "capital": "",
    "population": 7825,
    "id": 1392460076
  },
  {
    "city": "Eaton",
    "city_ascii": "Eaton",
    "lat": 39.7506,
    "lng": -84.6343,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 7858,
    "id": 1840001519
  },
  {
    "city": "Cumberland",
    "city_ascii": "Cumberland",
    "lat": 43.7933,
    "lng": -70.258,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maine",
    "capital": "",
    "population": 7855,
    "id": 1840066197
  },
  {
    "city": "Woodlake",
    "city_ascii": "Woodlake",
    "lat": 36.4124,
    "lng": -119.0999,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 7854,
    "id": 1840021640
  },
  {
    "city": "North Weeki Wachee",
    "city_ascii": "North Weeki Wachee",
    "lat": 28.5591,
    "lng": -82.5537,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 7853,
    "id": 1840073872
  },
  {
    "city": "Hastings-on-Hudson",
    "city_ascii": "Hastings-on-Hudson",
    "lat": 40.9902,
    "lng": -73.8801,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 7853,
    "id": 1840004932
  },
  {
    "city": "Fieni",
    "city_ascii": "Fieni",
    "lat": 45.1222,
    "lng": 25.4183,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Dâmboviţa",
    "capital": "",
    "population": 7587,
    "id": 1642550063
  },
  {
    "city": "Fremantle",
    "city_ascii": "Fremantle",
    "lat": -32.0569,
    "lng": 115.7439,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Western Australia",
    "capital": "",
    "population": 7459,
    "id": 1036824217
  },
  {
    "city": "Plopeni",
    "city_ascii": "Plopeni",
    "lat": 45.0458,
    "lng": 25.9536,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Prahova",
    "capital": "",
    "population": 7718,
    "id": 1642978143
  },
  {
    "city": "Pivdenne",
    "city_ascii": "Pivdenne",
    "lat": 49.8849,
    "lng": 36.0687,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Kharkivs’ka Oblast’",
    "capital": "",
    "population": 7705,
    "id": 1804968695
  },
  {
    "city": "Rohatyn",
    "city_ascii": "Rohatyn",
    "lat": 49.4167,
    "lng": 24.6167,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Ivano-Frankivs’ka Oblast’",
    "capital": "minor",
    "population": 7797,
    "id": 1804693643
  },
  {
    "city": "Banff",
    "city_ascii": "Banff",
    "lat": 51.1781,
    "lng": -115.5719,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Alberta",
    "capital": "",
    "population": 7847,
    "id": 1124351648
  },
  {
    "city": "Innisfail",
    "city_ascii": "Innisfail",
    "lat": 52.0333,
    "lng": -113.95,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Alberta",
    "capital": "",
    "population": 7847,
    "id": 1124612670
  },
  {
    "city": "Princeton",
    "city_ascii": "Princeton",
    "lat": 41.3807,
    "lng": -89.464,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 7840,
    "id": 1840009276
  },
  {
    "city": "Fakenham",
    "city_ascii": "Fakenham",
    "lat": 52.8356,
    "lng": 0.851,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Norfolk",
    "capital": "",
    "population": 7617,
    "id": 1826247178
  },
  {
    "city": "Los Fresnos",
    "city_ascii": "Los Fresnos",
    "lat": 26.075,
    "lng": -97.4877,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 7837,
    "id": 1840021037
  },
  {
    "city": "Blandon",
    "city_ascii": "Blandon",
    "lat": 40.4446,
    "lng": -75.8799,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 7837,
    "id": 1840026444
  },
  {
    "city": "South Weber",
    "city_ascii": "South Weber",
    "lat": 41.1334,
    "lng": -111.9392,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Utah",
    "capital": "",
    "population": 7836,
    "id": 1840021350
  },
  {
    "city": "Smithville",
    "city_ascii": "Smithville",
    "lat": 39.4934,
    "lng": -74.4782,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 7835,
    "id": 1840024384
  },
  {
    "city": "Watford City",
    "city_ascii": "Watford City",
    "lat": 47.8028,
    "lng": -103.2678,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Dakota",
    "capital": "",
    "population": 7835,
    "id": 1840001892
  },
  {
    "city": "Upton",
    "city_ascii": "Upton",
    "lat": 42.1771,
    "lng": -71.6043,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 7835,
    "id": 1840053608
  },
  {
    "city": "Crookston",
    "city_ascii": "Crookston",
    "lat": 47.7747,
    "lng": -96.6062,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 7835,
    "id": 1840006604
  },
  {
    "city": "Farington",
    "city_ascii": "Farington",
    "lat": 53.703,
    "lng": -2.685,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Lancashire",
    "capital": "",
    "population": 6674,
    "id": 1826495062
  },
  {
    "city": "Covington",
    "city_ascii": "Covington",
    "lat": 37.7785,
    "lng": -79.9868,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 7832,
    "id": 1840001688
  },
  {
    "city": "Latrobe",
    "city_ascii": "Latrobe",
    "lat": 40.3125,
    "lng": -79.3826,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 7830,
    "id": 1840001142
  },
  {
    "city": "San Juan del Sur",
    "city_ascii": "San Juan del Sur",
    "lat": 11.25,
    "lng": -85.86,
    "country": "Nicaragua",
    "iso2": "NI",
    "iso3": "NIC",
    "admin_name": "Rivas",
    "capital": "minor",
    "population": 7790,
    "id": 1558967577
  },
  {
    "city": "Nicolet",
    "city_ascii": "Nicolet",
    "lat": 46.2167,
    "lng": -72.6167,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 7828,
    "id": 1124746363
  },
  {
    "city": "Sallisaw",
    "city_ascii": "Sallisaw",
    "lat": 35.4605,
    "lng": -94.807,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oklahoma",
    "capital": "",
    "population": 7825,
    "id": 1840021749
  },
  {
    "city": "Halifax",
    "city_ascii": "Halifax",
    "lat": 41.9914,
    "lng": -70.8633,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 7824,
    "id": 1840017567
  },
  {
    "city": "Rrogozhinë",
    "city_ascii": "Rrogozhine",
    "lat": 41.0761,
    "lng": 19.6667,
    "country": "Albania",
    "iso2": "AL",
    "iso3": "ALB",
    "admin_name": "Tiranë",
    "capital": "",
    "population": 7049,
    "id": 1008422115
  },
  {
    "city": "DeFuniak Springs",
    "city_ascii": "DeFuniak Springs",
    "lat": 30.7123,
    "lng": -86.1208,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 7823,
    "id": 1840015011
  },
  {
    "city": "Rockwood",
    "city_ascii": "Rockwood",
    "lat": 50.2856,
    "lng": -97.2869,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Manitoba",
    "capital": "",
    "population": 7823,
    "id": 1124000435
  },
  {
    "city": "Chester",
    "city_ascii": "Chester",
    "lat": 40.7795,
    "lng": -74.6841,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 7822,
    "id": 1840000949
  },
  {
    "city": "Baldwinsville",
    "city_ascii": "Baldwinsville",
    "lat": 43.157,
    "lng": -76.3318,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 7822,
    "id": 1840004330
  },
  {
    "city": "Olivette",
    "city_ascii": "Olivette",
    "lat": 38.6724,
    "lng": -90.3786,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 7822,
    "id": 1840009743
  },
  {
    "city": "Kráľovský Chlmec",
    "city_ascii": "Kral'ovsky Chlmec",
    "lat": 48.4233,
    "lng": 21.9797,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Košický",
    "capital": "",
    "population": 7505,
    "id": 1703165368
  },
  {
    "city": "Winters",
    "city_ascii": "Winters",
    "lat": 38.532,
    "lng": -121.9781,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 7820,
    "id": 1840021484
  },
  {
    "city": "Breckenridge",
    "city_ascii": "Breckenridge",
    "lat": 39.4995,
    "lng": -106.0433,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 7818,
    "id": 1840021428
  },
  {
    "city": "Sarandí del Yi",
    "city_ascii": "Sarandi del Yi",
    "lat": -33.3442,
    "lng": -55.6313,
    "country": "Uruguay",
    "iso2": "UY",
    "iso3": "URY",
    "admin_name": "Durazno",
    "capital": "",
    "population": 7176,
    "id": 1858908164
  },
  {
    "city": "Calau",
    "city_ascii": "Calau",
    "lat": 51.7458,
    "lng": 13.9508,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Brandenburg",
    "capital": "",
    "population": 7769,
    "id": 1276948458
  },
  {
    "city": "Makushino",
    "city_ascii": "Makushino",
    "lat": 55.2,
    "lng": 67.25,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kurganskaya Oblast’",
    "capital": "",
    "population": 7811,
    "id": 1643875963
  },
  {
    "city": "Bow",
    "city_ascii": "Bow",
    "lat": 43.1308,
    "lng": -71.5307,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Hampshire",
    "capital": "",
    "population": 7816,
    "id": 1840080248
  },
  {
    "city": "Medina",
    "city_ascii": "Medina",
    "lat": 43.2197,
    "lng": -78.3888,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 7816,
    "id": 1840004264
  },
  {
    "city": "North Cornwall",
    "city_ascii": "North Cornwall",
    "lat": 40.3134,
    "lng": -76.4515,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 7815,
    "id": 1840143427
  },
  {
    "city": "Thousand Palms",
    "city_ascii": "Thousand Palms",
    "lat": 33.815,
    "lng": -116.3545,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 7814,
    "id": 1840019291
  },
  {
    "city": "Manazuru",
    "city_ascii": "Manazuru",
    "lat": 35.1583,
    "lng": 139.1372,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Kanagawa",
    "capital": "",
    "population": 6843,
    "id": 1392192684
  },
  {
    "city": "Southeast Arcadia",
    "city_ascii": "Southeast Arcadia",
    "lat": 27.1862,
    "lng": -81.8521,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 7813,
    "id": 1840035778
  },
  {
    "city": "Woodstock",
    "city_ascii": "Woodstock",
    "lat": 41.9694,
    "lng": -72.0222,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 7813,
    "id": 1840035675
  },
  {
    "city": "Seville",
    "city_ascii": "Seville",
    "lat": 41.0208,
    "lng": -81.8671,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 7812,
    "id": 1840011714
  },
  {
    "city": "Rainford",
    "city_ascii": "Rainford",
    "lat": 53.503,
    "lng": -2.783,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "St. Helens",
    "capital": "",
    "population": 7779,
    "id": 1826728148
  },
  {
    "city": "Petřvald",
    "city_ascii": "Petrvald",
    "lat": 49.831,
    "lng": 18.3894,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Moravskoslezský Kraj",
    "capital": "",
    "population": 7237,
    "id": 1203137002
  },
  {
    "city": "Montrose",
    "city_ascii": "Montrose",
    "lat": 37.52,
    "lng": -77.3772,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 7810,
    "id": 1840006389
  },
  {
    "city": "Zavāreh",
    "city_ascii": "Zavareh",
    "lat": 33.4486,
    "lng": 52.4933,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Eşfahān",
    "capital": "",
    "population": 7806,
    "id": 1364819342
  },
  {
    "city": "Vratimov",
    "city_ascii": "Vratimov",
    "lat": 49.7699,
    "lng": 18.3102,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Moravskoslezský Kraj",
    "capital": "",
    "population": 7293,
    "id": 1203846615
  },
  {
    "city": "Budeşti",
    "city_ascii": "Budesti",
    "lat": 44.23,
    "lng": 26.45,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Călăraşi",
    "capital": "",
    "population": 7725,
    "id": 1642553914
  },
  {
    "city": "Collie",
    "city_ascii": "Collie",
    "lat": -33.363,
    "lng": 116.156,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Western Australia",
    "capital": "",
    "population": 7192,
    "id": 1036553705
  },
  {
    "city": "Port Jefferson Station",
    "city_ascii": "Port Jefferson Station",
    "lat": 40.926,
    "lng": -73.0651,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 7808,
    "id": 1840005066
  },
  {
    "city": "Aubrey",
    "city_ascii": "Aubrey",
    "lat": 33.2872,
    "lng": -96.9622,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 7806,
    "id": 1840019387
  },
  {
    "city": "Carnegie",
    "city_ascii": "Carnegie",
    "lat": 40.408,
    "lng": -80.0861,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 7806,
    "id": 1840001214
  },
  {
    "city": "Monte Alegre do Sul",
    "city_ascii": "Monte Alegre do Sul",
    "lat": -22.68,
    "lng": -46.68,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 7736,
    "id": 1076576986
  },
  {
    "city": "Setana",
    "city_ascii": "Setana",
    "lat": 42.4169,
    "lng": 139.8833,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hokkaidō",
    "capital": "",
    "population": 7792,
    "id": 1392930105
  },
  {
    "city": "Irapuã",
    "city_ascii": "Irapua",
    "lat": -21.2794,
    "lng": -49.4089,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 7774,
    "id": 1076875397
  },
  {
    "city": "Sarandí Grande",
    "city_ascii": "Sarandi Grande",
    "lat": -33.725,
    "lng": -56.3303,
    "country": "Uruguay",
    "iso2": "UY",
    "iso3": "URY",
    "admin_name": "Florida",
    "capital": "",
    "population": 6130,
    "id": 1858373689
  },
  {
    "city": "Waterboro",
    "city_ascii": "Waterboro",
    "lat": 43.5972,
    "lng": -70.7302,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maine",
    "capital": "",
    "population": 7803,
    "id": 1840053049
  },
  {
    "city": "Porters Neck",
    "city_ascii": "Porters Neck",
    "lat": 34.2942,
    "lng": -77.7695,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 7802,
    "id": 1840040293
  },
  {
    "city": "Pittston",
    "city_ascii": "Pittston",
    "lat": 41.3274,
    "lng": -75.7885,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 7802,
    "id": 1840000766
  },
  {
    "city": "Kinvere",
    "city_ascii": "Kinvere",
    "lat": 52.4488,
    "lng": -2.228,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Staffordshire",
    "capital": "",
    "population": 7225,
    "id": 1826047283
  },
  {
    "city": "Santana",
    "city_ascii": "Santana",
    "lat": 32.8,
    "lng": -16.8667,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Madeira",
    "capital": "",
    "population": 7719,
    "id": 1620412812
  },
  {
    "city": "Santa María del Oro",
    "city_ascii": "Santa Maria del Oro",
    "lat": 25.9333,
    "lng": -105.3667,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Durango",
    "capital": "minor",
    "population": 5878,
    "id": 1484044585
  },
  {
    "city": "Allstedt",
    "city_ascii": "Allstedt",
    "lat": 51.4,
    "lng": 11.3833,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony-Anhalt",
    "capital": "",
    "population": 7745,
    "id": 1276620338
  },
  {
    "city": "Waltham",
    "city_ascii": "Waltham",
    "lat": 53.5165,
    "lng": -0.1021,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "North East Lincolnshire",
    "capital": "",
    "population": 6413,
    "id": 1826581191
  },
  {
    "city": "Gerolstein",
    "city_ascii": "Gerolstein",
    "lat": 50.2239,
    "lng": 6.6614,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Rhineland-Palatinate",
    "capital": "",
    "population": 7676,
    "id": 1276387586
  },
  {
    "city": "Harzgerode",
    "city_ascii": "Harzgerode",
    "lat": 51.6417,
    "lng": 11.1428,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony-Anhalt",
    "capital": "",
    "population": 7745,
    "id": 1276781709
  },
  {
    "city": "Eisfeld",
    "city_ascii": "Eisfeld",
    "lat": 50.4167,
    "lng": 10.9167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Thuringia",
    "capital": "",
    "population": 7646,
    "id": 1276593412
  },
  {
    "city": "Jászárokszállás",
    "city_ascii": "Jaszarokszallas",
    "lat": 47.6333,
    "lng": 19.9833,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Jász-Nagykun-Szolnok",
    "capital": "",
    "population": 7691,
    "id": 1348936950
  },
  {
    "city": "Mattighofen",
    "city_ascii": "Mattighofen",
    "lat": 48.1067,
    "lng": 13.1494,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Oberösterreich",
    "capital": "",
    "population": 6524,
    "id": 1040983702
  },
  {
    "city": "Withamsville",
    "city_ascii": "Withamsville",
    "lat": 39.0628,
    "lng": -84.2808,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 7788,
    "id": 1840007390
  },
  {
    "city": "Panciu",
    "city_ascii": "Panciu",
    "lat": 45.91,
    "lng": 27.09,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Vrancea",
    "capital": "",
    "population": 7664,
    "id": 1642583883
  },
  {
    "city": "Carneys Point",
    "city_ascii": "Carneys Point",
    "lat": 39.6967,
    "lng": -75.4475,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 7787,
    "id": 1840081665
  },
  {
    "city": "Saarburg",
    "city_ascii": "Saarburg",
    "lat": 49.6092,
    "lng": 6.5503,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Rhineland-Palatinate",
    "capital": "",
    "population": 7427,
    "id": 1276037274
  },
  {
    "city": "Campbell",
    "city_ascii": "Campbell",
    "lat": 41.0777,
    "lng": -80.5904,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 7785,
    "id": 1840000926
  },
  {
    "city": "Mhlume",
    "city_ascii": "Mhlume",
    "lat": -26.0333,
    "lng": 31.85,
    "country": "Swaziland",
    "iso2": "SZ",
    "iso3": "SWZ",
    "admin_name": "Lubombo",
    "capital": "",
    "population": 7761,
    "id": 1748701026
  },
  {
    "city": "Alpiarça",
    "city_ascii": "Alpiarca",
    "lat": 39.2593,
    "lng": -8.585,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Santarém",
    "capital": "minor",
    "population": 7702,
    "id": 1620994582
  },
  {
    "city": "Charlestown",
    "city_ascii": "Charlestown",
    "lat": 41.3972,
    "lng": -71.6702,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Rhode Island",
    "capital": "",
    "population": 7780,
    "id": 1840066130
  },
  {
    "city": "Clarksville",
    "city_ascii": "Clarksville",
    "lat": 35.457,
    "lng": -93.4803,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arkansas",
    "capital": "",
    "population": 7780,
    "id": 1840014533
  },
  {
    "city": "Providence",
    "city_ascii": "Providence",
    "lat": 41.7033,
    "lng": -111.8121,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Utah",
    "capital": "",
    "population": 7780,
    "id": 1840020115
  },
  {
    "city": "Oberwart",
    "city_ascii": "Oberwart",
    "lat": 47.2878,
    "lng": 16.2031,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Burgenland",
    "capital": "minor",
    "population": 7572,
    "id": 1040589942
  },
  {
    "city": "Chobham",
    "city_ascii": "Chobham",
    "lat": 51.348,
    "lng": -0.604,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Surrey",
    "capital": "",
    "population": 7456,
    "id": 1826991166
  },
  {
    "city": "Milan",
    "city_ascii": "Milan",
    "lat": 42.0816,
    "lng": -83.6853,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 7778,
    "id": 1840003170
  },
  {
    "city": "Crewkerne",
    "city_ascii": "Crewkerne",
    "lat": 50.8803,
    "lng": -2.7939,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Somerset",
    "capital": "",
    "population": 7000,
    "id": 1826075236
  },
  {
    "city": "Turzovka",
    "city_ascii": "Turzovka",
    "lat": 49.405,
    "lng": 18.6253,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Žilinský",
    "capital": "",
    "population": 7559,
    "id": 1703871315
  },
  {
    "city": "Ertis",
    "city_ascii": "Ertis",
    "lat": 53.3167,
    "lng": 75.45,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Pavlodar",
    "capital": "",
    "population": 7772,
    "id": 1398172080
  },
  {
    "city": "Drummond/North Elmsley",
    "city_ascii": "Drummond/North Elmsley",
    "lat": 44.9667,
    "lng": -76.2,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 7773,
    "id": 1124001787
  },
  {
    "city": "East Huntingdon",
    "city_ascii": "East Huntingdon",
    "lat": 40.1416,
    "lng": -79.5999,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 7771,
    "id": 1840144808
  },
  {
    "city": "Pukalani",
    "city_ascii": "Pukalani",
    "lat": 20.8329,
    "lng": -156.3415,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Hawaii",
    "capital": "",
    "population": 7769,
    "id": 1840029545
  },
  {
    "city": "Estes Park",
    "city_ascii": "Estes Park",
    "lat": 40.3699,
    "lng": -105.5216,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 7769,
    "id": 1840021382
  },
  {
    "city": "Kemijärvi",
    "city_ascii": "Kemijarvi",
    "lat": 66.7167,
    "lng": 27.4333,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Lappi",
    "capital": "minor",
    "population": 7766,
    "id": 1246358285
  },
  {
    "city": "Rhinebeck",
    "city_ascii": "Rhinebeck",
    "lat": 41.9276,
    "lng": -73.898,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 7768,
    "id": 1840004753
  },
  {
    "city": "Waite Park",
    "city_ascii": "Waite Park",
    "lat": 45.5313,
    "lng": -94.2528,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 7768,
    "id": 1840009989
  },
  {
    "city": "Reszel",
    "city_ascii": "Reszel",
    "lat": 54.0504,
    "lng": 21.1458,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Warmińsko-Mazurskie",
    "capital": "",
    "population": 7721,
    "id": 1616872630
  },
  {
    "city": "Greenfield",
    "city_ascii": "Greenfield",
    "lat": 43.1374,
    "lng": -73.8762,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 7765,
    "id": 1840087487
  },
  {
    "city": "Park City",
    "city_ascii": "Park City",
    "lat": 37.8103,
    "lng": -97.3255,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kansas",
    "capital": "",
    "population": 7764,
    "id": 1840009870
  },
  {
    "city": "Löffingen",
    "city_ascii": "Loffingen",
    "lat": 47.8839,
    "lng": 8.3436,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 7676,
    "id": 1276473515
  },
  {
    "city": "Longridge",
    "city_ascii": "Longridge",
    "lat": 53.831,
    "lng": -2.597,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Lancashire",
    "capital": "",
    "population": 7724,
    "id": 1826067023
  },
  {
    "city": "Shiranuka",
    "city_ascii": "Shiranuka",
    "lat": 42.9561,
    "lng": 144.0717,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hokkaidō",
    "capital": "",
    "population": 7751,
    "id": 1392294466
  },
  {
    "city": "Forsyth",
    "city_ascii": "Forsyth",
    "lat": 36.686,
    "lng": -93.1016,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 7761,
    "id": 1840008806
  },
  {
    "city": "Sangaree",
    "city_ascii": "Sangaree",
    "lat": 33.0328,
    "lng": -80.1253,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 7760,
    "id": 1840035792
  },
  {
    "city": "Tenterden",
    "city_ascii": "Tenterden",
    "lat": 51.0696,
    "lng": 0.6898,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Kent",
    "capital": "",
    "population": 7735,
    "id": 1826293239
  },
  {
    "city": "Williamstown",
    "city_ascii": "Williamstown",
    "lat": 42.6848,
    "lng": -73.2284,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 7759,
    "id": 1840053657
  },
  {
    "city": "Nakata",
    "city_ascii": "Nakata",
    "lat": 30.5331,
    "lng": 130.9586,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Kagoshima",
    "capital": "",
    "population": 7702,
    "id": 1392487535
  },
  {
    "city": "Swainsboro",
    "city_ascii": "Swainsboro",
    "lat": 32.5866,
    "lng": -82.3345,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 7757,
    "id": 1840015787
  },
  {
    "city": "Cheraw",
    "city_ascii": "Cheraw",
    "lat": 34.6955,
    "lng": -79.9085,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 7757,
    "id": 1840016582
  },
  {
    "city": "Nyíradony",
    "city_ascii": "Nyiradony",
    "lat": 47.6833,
    "lng": 21.9167,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Hajdú-Bihar",
    "capital": "minor",
    "population": 7677,
    "id": 1348883503
  },
  {
    "city": "Cherry Valley",
    "city_ascii": "Cherry Valley",
    "lat": 33.9797,
    "lng": -116.9694,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 7755,
    "id": 1840017983
  },
  {
    "city": "Dry Run",
    "city_ascii": "Dry Run",
    "lat": 39.1049,
    "lng": -84.3312,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 7754,
    "id": 1840034384
  },
  {
    "city": "Fort Dix",
    "city_ascii": "Fort Dix",
    "lat": 40.006,
    "lng": -74.6089,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 7753,
    "id": 1840005516
  },
  {
    "city": "Naujoji Akmenė",
    "city_ascii": "Naujoji Akmene",
    "lat": 56.325,
    "lng": 22.8833,
    "country": "Lithuania",
    "iso2": "LT",
    "iso3": "LTU",
    "admin_name": "Akmenė",
    "capital": "",
    "population": 7725,
    "id": 1440899932
  },
  {
    "city": "Jasper",
    "city_ascii": "Jasper",
    "lat": 30.9221,
    "lng": -93.9947,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 7750,
    "id": 1840019560
  },
  {
    "city": "Dryden",
    "city_ascii": "Dryden",
    "lat": 49.7833,
    "lng": -92.8333,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 7749,
    "id": 1124295097
  },
  {
    "city": "Blair",
    "city_ascii": "Blair",
    "lat": 41.5417,
    "lng": -96.1361,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Nebraska",
    "capital": "",
    "population": 7748,
    "id": 1840007094
  },
  {
    "city": "Round Lake Park",
    "city_ascii": "Round Lake Park",
    "lat": 42.3309,
    "lng": -88.075,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 7747,
    "id": 1840011173
  },
  {
    "city": "Q’vareli",
    "city_ascii": "Q'vareli",
    "lat": 41.95,
    "lng": 45.8,
    "country": "Georgia",
    "iso2": "GE",
    "iso3": "GEO",
    "admin_name": "K’akheti",
    "capital": "",
    "population": 7739,
    "id": 1268398914
  },
  {
    "city": "Neufchâteau",
    "city_ascii": "Neufchateau",
    "lat": 49.8411,
    "lng": 5.4353,
    "country": "Belgium",
    "iso2": "BE",
    "iso3": "BEL",
    "admin_name": "Wallonia",
    "capital": "minor",
    "population": 7679,
    "id": 1056857306
  },
  {
    "city": "Pulsnitz",
    "city_ascii": "Pulsnitz",
    "lat": 51.1817,
    "lng": 14.0131,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 7467,
    "id": 1276185407
  },
  {
    "city": "Gallneukirchen",
    "city_ascii": "Gallneukirchen",
    "lat": 48.3531,
    "lng": 14.4158,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Oberösterreich",
    "capital": "",
    "population": 6492,
    "id": 1040975167
  },
  {
    "city": "Spring Valley Lake",
    "city_ascii": "Spring Valley Lake",
    "lat": 34.4987,
    "lng": -117.2683,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 7744,
    "id": 1840024936
  },
  {
    "city": "Kotake",
    "city_ascii": "Kotake",
    "lat": 33.6925,
    "lng": 130.7128,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Fukuoka",
    "capital": "",
    "population": 7232,
    "id": 1392026146
  },
  {
    "city": "Strathmore",
    "city_ascii": "Strathmore",
    "lat": 40.4018,
    "lng": -74.2193,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 7742,
    "id": 1840005451
  },
  {
    "city": "Glastonbury Center",
    "city_ascii": "Glastonbury Center",
    "lat": 41.7019,
    "lng": -72.6001,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 7742,
    "id": 1840000503
  },
  {
    "city": "Spring Township",
    "city_ascii": "Spring Township",
    "lat": 40.8949,
    "lng": -77.733,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 7741,
    "id": 1840145899
  },
  {
    "city": "Quarryville",
    "city_ascii": "Quarryville",
    "lat": 39.8957,
    "lng": -76.1617,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 7741,
    "id": 1840003726
  },
  {
    "city": "Zlatograd",
    "city_ascii": "Zlatograd",
    "lat": 41.3797,
    "lng": 25.0928,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Smolyan",
    "capital": "minor",
    "population": 7623,
    "id": 1100099994
  },
  {
    "city": "South Boston",
    "city_ascii": "South Boston",
    "lat": 36.7132,
    "lng": -78.9135,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 7740,
    "id": 1840037461
  },
  {
    "city": "Salida",
    "city_ascii": "Salida",
    "lat": 38.53,
    "lng": -105.9981,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 7740,
    "id": 1840021473
  },
  {
    "city": "Fort Frances",
    "city_ascii": "Fort Frances",
    "lat": 48.6167,
    "lng": -93.4,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 7739,
    "id": 1124939714
  },
  {
    "city": "Bad Wimpfen",
    "city_ascii": "Bad Wimpfen",
    "lat": 49.2306,
    "lng": 9.1631,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 7359,
    "id": 1276487458
  },
  {
    "city": "Albion",
    "city_ascii": "Albion",
    "lat": 43.246,
    "lng": -78.1902,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 7738,
    "id": 1840004265
  },
  {
    "city": "Roßwein",
    "city_ascii": "Rosswein",
    "lat": 51.0667,
    "lng": 13.1833,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 7564,
    "id": 1276403011
  },
  {
    "city": "Bilac",
    "city_ascii": "Bilac",
    "lat": -21.4033,
    "lng": -50.4706,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 7682,
    "id": 1076607535
  },
  {
    "city": "Salem",
    "city_ascii": "Salem",
    "lat": 38.6279,
    "lng": -88.959,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 7730,
    "id": 1840009789
  },
  {
    "city": "Shelburne",
    "city_ascii": "Shelburne",
    "lat": 44.3905,
    "lng": -73.2413,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Vermont",
    "capital": "",
    "population": 7728,
    "id": 1840071299
  },
  {
    "city": "Árgos Orestikó",
    "city_ascii": "Argos Orestiko",
    "lat": 40.4525,
    "lng": 21.2581,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Dytikí Makedonía",
    "capital": "minor",
    "population": 7473,
    "id": 1300749412
  },
  {
    "city": "Payette",
    "city_ascii": "Payette",
    "lat": 44.0788,
    "lng": -116.9255,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Idaho",
    "capital": "",
    "population": 7727,
    "id": 1840020015
  },
  {
    "city": "Vlăhiţa",
    "city_ascii": "Vlahita",
    "lat": 46.35,
    "lng": 25.53,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Harghita",
    "capital": "",
    "population": 7649,
    "id": 1642456978
  },
  {
    "city": "North Tamborine",
    "city_ascii": "North Tamborine",
    "lat": -27.9694,
    "lng": 153.1992,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Queensland",
    "capital": "",
    "population": 7506,
    "id": 1036063516
  },
  {
    "city": "Grove",
    "city_ascii": "Grove",
    "lat": 36.593,
    "lng": -94.7879,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oklahoma",
    "capital": "",
    "population": 7726,
    "id": 1840020369
  },
  {
    "city": "Lengefeld",
    "city_ascii": "Lengefeld",
    "lat": 50.7167,
    "lng": 13.1833,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 7634,
    "id": 1276387084
  },
  {
    "city": "Celorico da Beira",
    "city_ascii": "Celorico da Beira",
    "lat": 40.6333,
    "lng": -7.4,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Guarda",
    "capital": "minor",
    "population": 7693,
    "id": 1620858442
  },
  {
    "city": "Gettysburg",
    "city_ascii": "Gettysburg",
    "lat": 39.8304,
    "lng": -77.2339,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 7724,
    "id": 1840000683
  },
  {
    "city": "Center Township",
    "city_ascii": "Center Township",
    "lat": 40.9218,
    "lng": -79.9252,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 7723,
    "id": 1840149064
  },
  {
    "city": "Langenlois",
    "city_ascii": "Langenlois",
    "lat": 48.4667,
    "lng": 15.6833,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Niederösterreich",
    "capital": "",
    "population": 7609,
    "id": 1040567279
  },
  {
    "city": "Thornbury",
    "city_ascii": "Thornbury",
    "lat": 39.9182,
    "lng": -75.5164,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 7719,
    "id": 1840142586
  },
  {
    "city": "La Sarre",
    "city_ascii": "La Sarre",
    "lat": 48.8,
    "lng": -79.2,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 7719,
    "id": 1124902252
  },
  {
    "city": "Wick",
    "city_ascii": "Wick",
    "lat": 58.454,
    "lng": -3.089,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Highland",
    "capital": "",
    "population": 7333,
    "id": 1826766399
  },
  {
    "city": "Quiévrechain",
    "city_ascii": "Quievrechain",
    "lat": 50.3953,
    "lng": 3.6669,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Hauts-de-France",
    "capital": "",
    "population": 6366,
    "id": 1250408197
  },
  {
    "city": "Câmpeni",
    "city_ascii": "Campeni",
    "lat": 46.3625,
    "lng": 23.0456,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Alba",
    "capital": "",
    "population": 7629,
    "id": 1642658174
  },
  {
    "city": "Southwood Acres",
    "city_ascii": "Southwood Acres",
    "lat": 41.961,
    "lng": -72.5719,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 7715,
    "id": 1840028602
  },
  {
    "city": "Kasson",
    "city_ascii": "Kasson",
    "lat": 44.0333,
    "lng": -92.7482,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 7715,
    "id": 1840007913
  },
  {
    "city": "Granite Falls",
    "city_ascii": "Granite Falls",
    "lat": 48.0874,
    "lng": -121.9706,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 7713,
    "id": 1840019787
  },
  {
    "city": "Frýdlant",
    "city_ascii": "Frydlant",
    "lat": 50.9215,
    "lng": 15.0799,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Liberecký Kraj",
    "capital": "",
    "population": 7476,
    "id": 1203653640
  },
  {
    "city": "Rockwood",
    "city_ascii": "Rockwood",
    "lat": 37.463,
    "lng": -77.5744,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 7712,
    "id": 1840041858
  },
  {
    "city": "Kingsbridge",
    "city_ascii": "Kingsbridge",
    "lat": 50.2833,
    "lng": -3.7765,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Devon",
    "capital": "",
    "population": 6116,
    "id": 1826155303
  },
  {
    "city": "York",
    "city_ascii": "York",
    "lat": 40.8698,
    "lng": -97.5928,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Nebraska",
    "capital": "",
    "population": 7711,
    "id": 1840010328
  },
  {
    "city": "Clear Lake",
    "city_ascii": "Clear Lake",
    "lat": 43.1346,
    "lng": -93.374,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Iowa",
    "capital": "",
    "population": 7711,
    "id": 1840000379
  },
  {
    "city": "Murwillumbah",
    "city_ascii": "Murwillumbah",
    "lat": -28.3333,
    "lng": 153.3833,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 7032,
    "id": 1036989894
  },
  {
    "city": "Trail",
    "city_ascii": "Trail",
    "lat": 49.095,
    "lng": -117.71,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "British Columbia",
    "capital": "",
    "population": 7709,
    "id": 1124817036
  },
  {
    "city": "Belinskiy",
    "city_ascii": "Belinskiy",
    "lat": 52.9667,
    "lng": 43.4167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Penzenskaya Oblast’",
    "capital": "",
    "population": 7694,
    "id": 1643873820
  },
  {
    "city": "Maurice River",
    "city_ascii": "Maurice River",
    "lat": 39.3057,
    "lng": -74.9349,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 7704,
    "id": 1840081594
  },
  {
    "city": "‘Omer",
    "city_ascii": "`Omer",
    "lat": 31.2683,
    "lng": 34.8489,
    "country": "Israel",
    "iso2": "IL",
    "iso3": "ISR",
    "admin_name": "Southern",
    "capital": "",
    "population": 7339,
    "id": 1376441239
  },
  {
    "city": "Oundle",
    "city_ascii": "Oundle",
    "lat": 52.48,
    "lng": -0.472,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Northamptonshire",
    "capital": "",
    "population": 5735,
    "id": 1826530571
  },
  {
    "city": "Chandler",
    "city_ascii": "Chandler",
    "lat": 48.35,
    "lng": -64.6833,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 7703,
    "id": 1124111932
  },
  {
    "city": "Stone Mills",
    "city_ascii": "Stone Mills",
    "lat": 44.45,
    "lng": -76.9167,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 7702,
    "id": 1124000075
  },
  {
    "city": "Broumov",
    "city_ascii": "Broumov",
    "lat": 50.5857,
    "lng": 16.3319,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Královéhradecký Kraj",
    "capital": "",
    "population": 7370,
    "id": 1203240850
  },
  {
    "city": "Wedgefield",
    "city_ascii": "Wedgefield",
    "lat": 28.4847,
    "lng": -81.0808,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 7701,
    "id": 1840014091
  },
  {
    "city": "Novoselytsya",
    "city_ascii": "Novoselytsya",
    "lat": 48.2167,
    "lng": 26.2667,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Chernivets’ka Oblast’",
    "capital": "minor",
    "population": 7588,
    "id": 1804491559
  },
  {
    "city": "Antigo",
    "city_ascii": "Antigo",
    "lat": 45.1413,
    "lng": -89.1556,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 7695,
    "id": 1840001565
  },
  {
    "city": "Green",
    "city_ascii": "Green",
    "lat": 43.1509,
    "lng": -123.3854,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 7695,
    "id": 1840017439
  },
  {
    "city": "Călimăneşti",
    "city_ascii": "Calimanesti",
    "lat": 45.2392,
    "lng": 24.3433,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Vâlcea",
    "capital": "",
    "population": 7622,
    "id": 1642632000
  },
  {
    "city": "Mammoth Lakes",
    "city_ascii": "Mammoth Lakes",
    "lat": 37.6273,
    "lng": -118.99,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 7694,
    "id": 1840022493
  },
  {
    "city": "Kremmen",
    "city_ascii": "Kremmen",
    "lat": 52.7667,
    "lng": 13.0331,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Brandenburg",
    "capital": "",
    "population": 7657,
    "id": 1276977885
  },
  {
    "city": "Wissembourg",
    "city_ascii": "Wissembourg",
    "lat": 49.0375,
    "lng": 7.9461,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Grand Est",
    "capital": "",
    "population": 7537,
    "id": 1250802565
  },
  {
    "city": "Inami",
    "city_ascii": "Inami",
    "lat": 33.8183,
    "lng": 135.2181,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Wakayama",
    "capital": "",
    "population": 7626,
    "id": 1392294652
  },
  {
    "city": "Markneukirchen",
    "city_ascii": "Markneukirchen",
    "lat": 50.3167,
    "lng": 12.3167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 7583,
    "id": 1276538158
  },
  {
    "city": "Ogden",
    "city_ascii": "Ogden",
    "lat": 34.2656,
    "lng": -77.7965,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 7692,
    "id": 1840013633
  },
  {
    "city": "Cootamundra",
    "city_ascii": "Cootamundra",
    "lat": -34.6417,
    "lng": 148.025,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 6782,
    "id": 1036618399
  },
  {
    "city": "Big Flats",
    "city_ascii": "Big Flats",
    "lat": 42.1387,
    "lng": -76.9138,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 7689,
    "id": 1840057237
  },
  {
    "city": "Hanover",
    "city_ascii": "Hanover",
    "lat": 44.15,
    "lng": -81.0333,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 7688,
    "id": 1124868817
  },
  {
    "city": "Münnerstadt",
    "city_ascii": "Munnerstadt",
    "lat": 50.2526,
    "lng": 10.1966,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 7606,
    "id": 1276003873
  },
  {
    "city": "Pressbaum",
    "city_ascii": "Pressbaum",
    "lat": 48.1833,
    "lng": 16.0825,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Niederösterreich",
    "capital": "",
    "population": 7559,
    "id": 1040060387
  },
  {
    "city": "Kahla",
    "city_ascii": "Kahla",
    "lat": 50.8008,
    "lng": 11.5875,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Thuringia",
    "capital": "",
    "population": 6822,
    "id": 1276126902
  },
  {
    "city": "Treherbert",
    "city_ascii": "Treherbert",
    "lat": 51.6722,
    "lng": -3.5306,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Rhondda Cynon Taff",
    "capital": "",
    "population": 5727,
    "id": 1826603509
  },
  {
    "city": "Las Lomitas",
    "city_ascii": "Las Lomitas",
    "lat": -24.7072,
    "lng": -60.5944,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Formosa",
    "capital": "",
    "population": 7683,
    "id": 1032153825
  },
  {
    "city": "Chari̇̄koṭ",
    "city_ascii": "Charikot",
    "lat": 27.6667,
    "lng": 86.0333,
    "country": "Nepal",
    "iso2": "NP",
    "iso3": "NPL",
    "admin_name": "Janakpur",
    "capital": "",
    "population": 7349,
    "id": 1524788609
  },
  {
    "city": "Boultham",
    "city_ascii": "Boultham",
    "lat": 53.214,
    "lng": -0.5561,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Lincolnshire",
    "capital": "",
    "population": 7465,
    "id": 1826621706
  },
  {
    "city": "Carbondale",
    "city_ascii": "Carbondale",
    "lat": 39.3949,
    "lng": -107.2147,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 7684,
    "id": 1840022431
  },
  {
    "city": "Chippewa",
    "city_ascii": "Chippewa",
    "lat": 40.7614,
    "lng": -80.3791,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 7682,
    "id": 1840144997
  },
  {
    "city": "Orange",
    "city_ascii": "Orange",
    "lat": 42.6066,
    "lng": -72.2931,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 7682,
    "id": 1840053437
  },
  {
    "city": "Chōnan",
    "city_ascii": "Chonan",
    "lat": 35.3867,
    "lng": 140.2372,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Chiba",
    "capital": "",
    "population": 7563,
    "id": 1392096688
  },
  {
    "city": "Oceano",
    "city_ascii": "Oceano",
    "lat": 35.1019,
    "lng": -120.609,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 7678,
    "id": 1840019123
  },
  {
    "city": "Hardegsen",
    "city_ascii": "Hardegsen",
    "lat": 51.6528,
    "lng": 9.8322,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 7587,
    "id": 1276677713
  },
  {
    "city": "Plaistow",
    "city_ascii": "Plaistow",
    "lat": 42.8403,
    "lng": -71.096,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Hampshire",
    "capital": "",
    "population": 7677,
    "id": 1840055411
  },
  {
    "city": "Sebastopol",
    "city_ascii": "Sebastopol",
    "lat": 38.4001,
    "lng": -122.8276,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 7674,
    "id": 1840021489
  },
  {
    "city": "Ilminster",
    "city_ascii": "Ilminster",
    "lat": 50.9262,
    "lng": -2.9114,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Somerset",
    "capital": "",
    "population": 5808,
    "id": 1826606309
  },
  {
    "city": "Treharris",
    "city_ascii": "Treharris",
    "lat": 51.6689,
    "lng": -3.31,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Merthyr Tydfil",
    "capital": "",
    "population": 6356,
    "id": 1826550011
  },
  {
    "city": "Wivenhoe",
    "city_ascii": "Wivenhoe",
    "lat": 51.8667,
    "lng": 0.9625,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Essex",
    "capital": "",
    "population": 7637,
    "id": 1826531596
  },
  {
    "city": "Hastings",
    "city_ascii": "Hastings",
    "lat": 42.6498,
    "lng": -85.2887,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 7670,
    "id": 1840003110
  },
  {
    "city": "Benátky nad Jizerou",
    "city_ascii": "Benatky nad Jizerou",
    "lat": 50.2909,
    "lng": 14.8235,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Středočeský Kraj",
    "capital": "",
    "population": 7459,
    "id": 1203961818
  },
  {
    "city": "Indiantown",
    "city_ascii": "Indiantown",
    "lat": 27.0375,
    "lng": -80.4913,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 7669,
    "id": 1840014189
  },
  {
    "city": "Half Moon",
    "city_ascii": "Half Moon",
    "lat": 34.8298,
    "lng": -77.4591,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 7669,
    "id": 1840013556
  },
  {
    "city": "Plain City",
    "city_ascii": "Plain City",
    "lat": 41.307,
    "lng": -112.0877,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Utah",
    "capital": "",
    "population": 7669,
    "id": 1840020136
  },
  {
    "city": "Richfield",
    "city_ascii": "Richfield",
    "lat": 38.7628,
    "lng": -112.0926,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Utah",
    "capital": "",
    "population": 7668,
    "id": 1840020239
  },
  {
    "city": "Polk Township",
    "city_ascii": "Polk Township",
    "lat": 40.9291,
    "lng": -75.5022,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 7666,
    "id": 1840144155
  },
  {
    "city": "Southwell",
    "city_ascii": "Southwell",
    "lat": 53.078,
    "lng": -0.955,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Nottinghamshire",
    "capital": "",
    "population": 7297,
    "id": 1826116893
  },
  {
    "city": "St. Rose",
    "city_ascii": "St. Rose",
    "lat": 29.9649,
    "lng": -90.3088,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 7665,
    "id": 1840013973
  },
  {
    "city": "Westminster",
    "city_ascii": "Westminster",
    "lat": 42.5512,
    "lng": -71.9027,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 7665,
    "id": 1840053614
  },
  {
    "city": "South-West Oxford",
    "city_ascii": "South-West Oxford",
    "lat": 42.95,
    "lng": -80.8,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 7664,
    "id": 1124000210
  },
  {
    "city": "Acton Vale",
    "city_ascii": "Acton Vale",
    "lat": 45.65,
    "lng": -72.5667,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 7664,
    "id": 1124864792
  },
  {
    "city": "Allegany",
    "city_ascii": "Allegany",
    "lat": 42.0869,
    "lng": -78.5212,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 7663,
    "id": 1840004597
  },
  {
    "city": "Tervel",
    "city_ascii": "Tervel",
    "lat": 43.75,
    "lng": 27.4,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Dobrich",
    "capital": "minor",
    "population": 7632,
    "id": 1100435548
  },
  {
    "city": "Valley Township",
    "city_ascii": "Valley Township",
    "lat": 39.9849,
    "lng": -75.8503,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 7661,
    "id": 1840145643
  },
  {
    "city": "Middlebury",
    "city_ascii": "Middlebury",
    "lat": 41.5271,
    "lng": -73.1228,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 7661,
    "id": 1840035388
  },
  {
    "city": "Auburn",
    "city_ascii": "Auburn",
    "lat": 34.0157,
    "lng": -83.8319,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 7660,
    "id": 1840013680
  },
  {
    "city": "Thurmont",
    "city_ascii": "Thurmont",
    "lat": 39.621,
    "lng": -77.4076,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 7659,
    "id": 1840005716
  },
  {
    "city": "Donje Žabare",
    "city_ascii": "Donje Zabare",
    "lat": 42.8714,
    "lng": 20.8411,
    "country": "Kosovo",
    "iso2": "XK",
    "iso3": "XKS",
    "admin_name": "Mitrovicë e Jug",
    "capital": "",
    "population": 7394,
    "id": 1901942268
  },
  {
    "city": "Groitzsch",
    "city_ascii": "Groitzsch",
    "lat": 51.1556,
    "lng": 12.2806,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 7550,
    "id": 1276487800
  },
  {
    "city": "Eden",
    "city_ascii": "Eden",
    "lat": 42.6527,
    "lng": -78.8787,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 7657,
    "id": 1840058066
  },
  {
    "city": "Oakdale",
    "city_ascii": "Oakdale",
    "lat": 30.8157,
    "lng": -92.6542,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 7656,
    "id": 1840015012
  },
  {
    "city": "Meadow Lakes",
    "city_ascii": "Meadow Lakes",
    "lat": 61.638,
    "lng": -149.608,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alaska",
    "capital": "",
    "population": 7656,
    "id": 1840023681
  },
  {
    "city": "Brighton",
    "city_ascii": "Brighton",
    "lat": 42.5298,
    "lng": -83.7847,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 7656,
    "id": 1840003099
  },
  {
    "city": "Catiguá",
    "city_ascii": "Catigua",
    "lat": -21.05,
    "lng": -49.0667,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 7603,
    "id": 1076476526
  },
  {
    "city": "Chehalis",
    "city_ascii": "Chehalis",
    "lat": 46.6638,
    "lng": -122.965,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 7654,
    "id": 1840018472
  },
  {
    "city": "Butler",
    "city_ascii": "Butler",
    "lat": 40.9989,
    "lng": -74.3471,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 7654,
    "id": 1840000947
  },
  {
    "city": "Villa Díaz Ordaz",
    "city_ascii": "Villa Diaz Ordaz",
    "lat": 16.9981,
    "lng": -96.4314,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Oaxaca",
    "capital": "minor",
    "population": 6646,
    "id": 1484683191
  },
  {
    "city": "Întorsura Buzăului",
    "city_ascii": "Intorsura Buzaului",
    "lat": 45.6728,
    "lng": 26.0342,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Covasna",
    "capital": "",
    "population": 7528,
    "id": 1642148572
  },
  {
    "city": "Inwood",
    "city_ascii": "Inwood",
    "lat": 28.0391,
    "lng": -81.7677,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 7651,
    "id": 1840014123
  },
  {
    "city": "Lake of the Woods",
    "city_ascii": "Lake of the Woods",
    "lat": 38.3342,
    "lng": -77.7599,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 7649,
    "id": 1840042948
  },
  {
    "city": "Bromont",
    "city_ascii": "Bromont",
    "lat": 45.3167,
    "lng": -72.65,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 7649,
    "id": 1124286457
  },
  {
    "city": "Palmyra",
    "city_ascii": "Palmyra",
    "lat": 43.0834,
    "lng": -77.1917,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 7646,
    "id": 1840004293
  },
  {
    "city": "Lamar",
    "city_ascii": "Lamar",
    "lat": 38.0739,
    "lng": -102.6153,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 7646,
    "id": 1840020267
  },
  {
    "city": "Windsor",
    "city_ascii": "Windsor",
    "lat": 43.2406,
    "lng": -89.2952,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 7644,
    "id": 1840006867
  },
  {
    "city": "North Springfield",
    "city_ascii": "North Springfield",
    "lat": 38.8024,
    "lng": -77.2028,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 7644,
    "id": 1840006022
  },
  {
    "city": "Beckwith",
    "city_ascii": "Beckwith",
    "lat": 45.0833,
    "lng": -76.0667,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 7644,
    "id": 1124000163
  },
  {
    "city": "Scotia",
    "city_ascii": "Scotia",
    "lat": 42.8321,
    "lng": -73.9607,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 7642,
    "id": 1840004448
  },
  {
    "city": "Poreč",
    "city_ascii": "Porec",
    "lat": 45.2167,
    "lng": 13.5833,
    "country": "Croatia",
    "iso2": "HR",
    "iso3": "HRV",
    "admin_name": "Istarska Županija",
    "capital": "minor",
    "population": 7585,
    "id": 1191152483
  },
  {
    "city": "Lindóia",
    "city_ascii": "Lindoia",
    "lat": -22.5231,
    "lng": -46.65,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 7485,
    "id": 1076734969
  },
  {
    "city": "Upper Milford",
    "city_ascii": "Upper Milford",
    "lat": 40.4934,
    "lng": -75.5192,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 7638,
    "id": 1840148584
  },
  {
    "city": "Marshfield",
    "city_ascii": "Marshfield",
    "lat": 37.3414,
    "lng": -92.9116,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 7638,
    "id": 1840008742
  },
  {
    "city": "Charlestown",
    "city_ascii": "Charlestown",
    "lat": 38.427,
    "lng": -85.6677,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 7637,
    "id": 1840007489
  },
  {
    "city": "Catalina",
    "city_ascii": "Catalina",
    "lat": 32.4848,
    "lng": -110.8998,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 7635,
    "id": 1840018109
  },
  {
    "city": "Sahuaripa",
    "city_ascii": "Sahuaripa",
    "lat": 29.05,
    "lng": -109.2333,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Sonora",
    "capital": "minor",
    "population": 5792,
    "id": 1484110174
  },
  {
    "city": "Devils Lake",
    "city_ascii": "Devils Lake",
    "lat": 48.1131,
    "lng": -98.875,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Dakota",
    "capital": "",
    "population": 7631,
    "id": 1840091411
  },
  {
    "city": "River Oaks",
    "city_ascii": "River Oaks",
    "lat": 32.7767,
    "lng": -97.3984,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 7630,
    "id": 1840020701
  },
  {
    "city": "Mitsamiouli",
    "city_ascii": "Mitsamiouli",
    "lat": -11.3819,
    "lng": 43.3,
    "country": "Comoros",
    "iso2": "KM",
    "iso3": "COM",
    "admin_name": "Grande Comore",
    "capital": "",
    "population": 7235,
    "id": 1174546487
  },
  {
    "city": "Bairnsdale",
    "city_ascii": "Bairnsdale",
    "lat": -37.8333,
    "lng": 147.6167,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Victoria",
    "capital": "",
    "population": 7580,
    "id": 1036432237
  },
  {
    "city": "Goderich",
    "city_ascii": "Goderich",
    "lat": 43.7333,
    "lng": -81.7,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 7628,
    "id": 1124989247
  },
  {
    "city": "Şomcuta Mare",
    "city_ascii": "Somcuta Mare",
    "lat": 47.5167,
    "lng": 23.4667,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Maramureş",
    "capital": "",
    "population": 7565,
    "id": 1642764917
  },
  {
    "city": "Wapato",
    "city_ascii": "Wapato",
    "lat": 46.4435,
    "lng": -120.4215,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 7627,
    "id": 1840021153
  },
  {
    "city": "Waupaca",
    "city_ascii": "Waupaca",
    "lat": 44.3506,
    "lng": -89.0719,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 7627,
    "id": 1840002353
  },
  {
    "city": "Richmond",
    "city_ascii": "Richmond",
    "lat": 41.4983,
    "lng": -71.6608,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Rhode Island",
    "capital": "",
    "population": 7626,
    "id": 1840106243
  },
  {
    "city": "Dzilam González",
    "city_ascii": "Dzilam Gonzalez",
    "lat": 21.28,
    "lng": -88.9292,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Yucatán",
    "capital": "minor",
    "population": 5875,
    "id": 1484123404
  },
  {
    "city": "Hořovice",
    "city_ascii": "Horovice",
    "lat": 49.8361,
    "lng": 13.9027,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Středočeský Kraj",
    "capital": "",
    "population": 6901,
    "id": 1203139428
  },
  {
    "city": "South Beloit",
    "city_ascii": "South Beloit",
    "lat": 42.4822,
    "lng": -89.0248,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 7624,
    "id": 1840009131
  },
  {
    "city": "Winslow",
    "city_ascii": "Winslow",
    "lat": 44.5277,
    "lng": -69.5768,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maine",
    "capital": "",
    "population": 7624,
    "id": 1840053064
  },
  {
    "city": "Thomaston",
    "city_ascii": "Thomaston",
    "lat": 41.6696,
    "lng": -73.0859,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 7623,
    "id": 1840035622
  },
  {
    "city": "Oulainen",
    "city_ascii": "Oulainen",
    "lat": 64.2667,
    "lng": 24.8167,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Pohjois-Pohjanmaa",
    "capital": "minor",
    "population": 7610,
    "id": 1246331534
  },
  {
    "city": "Summit View",
    "city_ascii": "Summit View",
    "lat": 47.1343,
    "lng": -122.3468,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 7621,
    "id": 1840042106
  },
  {
    "city": "Wimauma",
    "city_ascii": "Wimauma",
    "lat": 27.6964,
    "lng": -82.3034,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 7621,
    "id": 1840014155
  },
  {
    "city": "Berwick",
    "city_ascii": "Berwick",
    "lat": 43.3006,
    "lng": -70.844,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maine",
    "capital": "",
    "population": 7620,
    "id": 1840052461
  },
  {
    "city": "Mikulov",
    "city_ascii": "Mikulov",
    "lat": 48.8056,
    "lng": 16.6378,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Jihomoravský Kraj",
    "capital": "",
    "population": 7455,
    "id": 1203690511
  },
  {
    "city": "Kötzting",
    "city_ascii": "Kotzting",
    "lat": 49.1769,
    "lng": 12.855,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 7498,
    "id": 1276036752
  },
  {
    "city": "Broadview",
    "city_ascii": "Broadview",
    "lat": 41.8584,
    "lng": -87.8562,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 7618,
    "id": 1840010141
  },
  {
    "city": "Kruhlaye",
    "city_ascii": "Kruhlaye",
    "lat": 54.2479,
    "lng": 29.7966,
    "country": "Belarus",
    "iso2": "BY",
    "iso3": "BLR",
    "admin_name": "Mahilyowskaya Voblasts’",
    "capital": "minor",
    "population": 7600,
    "id": 1112435342
  },
  {
    "city": "Teublitz",
    "city_ascii": "Teublitz",
    "lat": 49.2208,
    "lng": 12.0853,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 7418,
    "id": 1276570133
  },
  {
    "city": "Gaoual",
    "city_ascii": "Gaoual",
    "lat": 11.754,
    "lng": -13.213,
    "country": "Guinea",
    "iso2": "GN",
    "iso3": "GIN",
    "admin_name": "Boké",
    "capital": "minor",
    "population": 7461,
    "id": 1324589428
  },
  {
    "city": "Mattersburg",
    "city_ascii": "Mattersburg",
    "lat": 47.7381,
    "lng": 16.3969,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Burgenland",
    "capital": "minor",
    "population": 7349,
    "id": 1040428013
  },
  {
    "city": "Kokemäki",
    "city_ascii": "Kokemaki",
    "lat": 61.2556,
    "lng": 22.3486,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Satakunta",
    "capital": "minor",
    "population": 7591,
    "id": 1246209483
  },
  {
    "city": "Schaghticoke",
    "city_ascii": "Schaghticoke",
    "lat": 42.885,
    "lng": -73.6115,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 7604,
    "id": 1840004438
  },
  {
    "city": "Livonia",
    "city_ascii": "Livonia",
    "lat": 42.8091,
    "lng": -77.6523,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 7601,
    "id": 1840004425
  },
  {
    "city": "Escalon",
    "city_ascii": "Escalon",
    "lat": 37.7912,
    "lng": -120.9982,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 7601,
    "id": 1840020262
  },
  {
    "city": "Perry",
    "city_ascii": "Perry",
    "lat": 41.8387,
    "lng": -94.0937,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Iowa",
    "capital": "",
    "population": 7601,
    "id": 1840009211
  },
  {
    "city": "Hnúšťa",
    "city_ascii": "Hnust'a",
    "lat": 48.5736,
    "lng": 19.9531,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Banskobystrický",
    "capital": "",
    "population": 7490,
    "id": 1703146231
  },
  {
    "city": "Farmington",
    "city_ascii": "Farmington",
    "lat": 44.676,
    "lng": -70.141,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maine",
    "capital": "",
    "population": 7600,
    "id": 1840052819
  },
  {
    "city": "Shirley",
    "city_ascii": "Shirley",
    "lat": 42.5725,
    "lng": -71.6471,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 7598,
    "id": 1840053486
  },
  {
    "city": "Omitlán de Juárez",
    "city_ascii": "Omitlan de Juarez",
    "lat": 20.1697,
    "lng": -98.6478,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Hidalgo",
    "capital": "minor",
    "population": 7529,
    "id": 1484135531
  },
  {
    "city": "Emerson",
    "city_ascii": "Emerson",
    "lat": 40.9748,
    "lng": -74.0239,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 7596,
    "id": 1840003545
  },
  {
    "city": "Dolni Chiflik",
    "city_ascii": "Dolni Chiflik",
    "lat": 42.9931,
    "lng": 27.7158,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Varna",
    "capital": "",
    "population": 7557,
    "id": 1100672695
  },
  {
    "city": "Mglin",
    "city_ascii": "Mglin",
    "lat": 53.0611,
    "lng": 32.8483,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Bryanskaya Oblast’",
    "capital": "",
    "population": 7578,
    "id": 1643364663
  },
  {
    "city": "Hriňová",
    "city_ascii": "Hrinova",
    "lat": 48.5667,
    "lng": 19.5167,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Banskobystrický",
    "capital": "",
    "population": 7535,
    "id": 1703355338
  },
  {
    "city": "Bernardsville",
    "city_ascii": "Bernardsville",
    "lat": 40.7268,
    "lng": -74.5918,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 7594,
    "id": 1840001052
  },
  {
    "city": "Esashi",
    "city_ascii": "Esashi",
    "lat": 41.8694,
    "lng": 140.1275,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hokkaidō",
    "capital": "",
    "population": 7525,
    "id": 1392253106
  },
  {
    "city": "Petite-Rosselle",
    "city_ascii": "Petite-Rosselle",
    "lat": 49.2117,
    "lng": 6.8575,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Grand Est",
    "capital": "",
    "population": 6338,
    "id": 1250604171
  },
  {
    "city": "Caribou",
    "city_ascii": "Caribou",
    "lat": 46.8662,
    "lng": -67.9919,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maine",
    "capital": "",
    "population": 7593,
    "id": 1840000145
  },
  {
    "city": "Tuttle",
    "city_ascii": "Tuttle",
    "lat": 35.3069,
    "lng": -97.7562,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oklahoma",
    "capital": "",
    "population": 7593,
    "id": 1840021802
  },
  {
    "city": "Piñon Hills",
    "city_ascii": "Pinon Hills",
    "lat": 34.4438,
    "lng": -117.6214,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 7592,
    "id": 1840025799
  },
  {
    "city": "Renchen",
    "city_ascii": "Renchen",
    "lat": 48.5858,
    "lng": 8.0106,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 7361,
    "id": 1276636887
  },
  {
    "city": "Zuni Pueblo",
    "city_ascii": "Zuni Pueblo",
    "lat": 35.0708,
    "lng": -108.8484,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Mexico",
    "capital": "",
    "population": 7590,
    "id": 1840019095
  },
  {
    "city": "LaFayette",
    "city_ascii": "LaFayette",
    "lat": 34.7088,
    "lng": -85.2815,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 7589,
    "id": 1840014656
  },
  {
    "city": "Palmyra",
    "city_ascii": "Palmyra",
    "lat": 40.31,
    "lng": -76.5945,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 7587,
    "id": 1840001350
  },
  {
    "city": "Twin Lakes",
    "city_ascii": "Twin Lakes",
    "lat": 39.8229,
    "lng": -105.0036,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 7586,
    "id": 1840136771
  },
  {
    "city": "Denham",
    "city_ascii": "Denham",
    "lat": 51.563,
    "lng": -0.499,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Buckinghamshire",
    "capital": "",
    "population": 7139,
    "id": 1826428149
  },
  {
    "city": "Bílovec",
    "city_ascii": "Bilovec",
    "lat": 49.7564,
    "lng": 18.0159,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Moravskoslezský Kraj",
    "capital": "",
    "population": 7394,
    "id": 1203065827
  },
  {
    "city": "Pakefield",
    "city_ascii": "Pakefield",
    "lat": 52.451,
    "lng": 1.728,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Suffolk",
    "capital": "",
    "population": 6563,
    "id": 1826187084
  },
  {
    "city": "Ras Kebdana",
    "city_ascii": "Ras Kebdana",
    "lat": 35.1333,
    "lng": -2.4167,
    "country": "Morocco",
    "iso2": "MA",
    "iso3": "MAR",
    "admin_name": "Oriental",
    "capital": "",
    "population": 7580,
    "id": 1504603676
  },
  {
    "city": "Burton Latimer",
    "city_ascii": "Burton Latimer",
    "lat": 52.365,
    "lng": -0.678,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Northamptonshire",
    "capital": "",
    "population": 7449,
    "id": 1826004598
  },
  {
    "city": "São Pedro do Turvo",
    "city_ascii": "Sao Pedro do Turvo",
    "lat": -22.7469,
    "lng": -49.74,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 7567,
    "id": 1076170682
  },
  {
    "city": "Sappington",
    "city_ascii": "Sappington",
    "lat": 38.526,
    "lng": -90.373,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 7577,
    "id": 1840006122
  },
  {
    "city": "Plympton-Wyoming",
    "city_ascii": "Plympton-Wyoming",
    "lat": 43.0167,
    "lng": -82.0833,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 7576,
    "id": 1124001273
  },
  {
    "city": "Central Huron",
    "city_ascii": "Central Huron",
    "lat": 43.63,
    "lng": -81.57,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 7576,
    "id": 1124001983
  },
  {
    "city": "Nykarleby",
    "city_ascii": "Nykarleby",
    "lat": 63.5167,
    "lng": 22.5333,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Pohjanmaa",
    "capital": "minor",
    "population": 7564,
    "id": 1246463530
  },
  {
    "city": "Banchory",
    "city_ascii": "Banchory",
    "lat": 57.055,
    "lng": -2.49,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Aberdeenshire",
    "capital": "",
    "population": 7560,
    "id": 1826461185
  },
  {
    "city": "Füzesabony",
    "city_ascii": "Fuzesabony",
    "lat": 47.751,
    "lng": 20.409,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Heves",
    "capital": "minor",
    "population": 7412,
    "id": 1348915639
  },
  {
    "city": "Kunhegyes",
    "city_ascii": "Kunhegyes",
    "lat": 47.37,
    "lng": 20.6308,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Jász-Nagykun-Szolnok",
    "capital": "minor",
    "population": 7521,
    "id": 1348635256
  },
  {
    "city": "Floresville",
    "city_ascii": "Floresville",
    "lat": 29.14,
    "lng": -98.163,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 7571,
    "id": 1840020981
  },
  {
    "city": "Dighton",
    "city_ascii": "Dighton",
    "lat": 41.836,
    "lng": -71.1552,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 7571,
    "id": 1840053520
  },
  {
    "city": "Makawao",
    "city_ascii": "Makawao",
    "lat": 20.848,
    "lng": -156.319,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Hawaii",
    "capital": "",
    "population": 7570,
    "id": 1840023236
  },
  {
    "city": "Bad Sachsa",
    "city_ascii": "Bad Sachsa",
    "lat": 51.5969,
    "lng": 10.5522,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 7346,
    "id": 1276052147
  },
  {
    "city": "Hales Corners",
    "city_ascii": "Hales Corners",
    "lat": 42.941,
    "lng": -88.0491,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 7567,
    "id": 1840003040
  },
  {
    "city": "East Fallowfield",
    "city_ascii": "East Fallowfield",
    "lat": 39.9508,
    "lng": -75.8185,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 7567,
    "id": 1840150926
  },
  {
    "city": "Rigaud",
    "city_ascii": "Rigaud",
    "lat": 45.4833,
    "lng": -74.3,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 7566,
    "id": 1124176101
  },
  {
    "city": "Hughson",
    "city_ascii": "Hughson",
    "lat": 37.602,
    "lng": -120.866,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 7565,
    "id": 1840020285
  },
  {
    "city": "Kleinmond",
    "city_ascii": "Kleinmond",
    "lat": -34.35,
    "lng": 19.0333,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "Western Cape",
    "capital": "",
    "population": 6634,
    "id": 1710397289
  },
  {
    "city": "Scappoose",
    "city_ascii": "Scappoose",
    "lat": 45.7564,
    "lng": -122.8771,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 7564,
    "id": 1840021186
  },
  {
    "city": "Lawrence Township",
    "city_ascii": "Lawrence Township",
    "lat": 41.0843,
    "lng": -78.4499,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 7562,
    "id": 1840147812
  },
  {
    "city": "Urraween",
    "city_ascii": "Urraween",
    "lat": -25.2955,
    "lng": 152.8219,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Queensland",
    "capital": "",
    "population": 6969,
    "id": 1036945468
  },
  {
    "city": "North Patchogue",
    "city_ascii": "North Patchogue",
    "lat": 40.7833,
    "lng": -73.0234,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 7561,
    "id": 1840005059
  },
  {
    "city": "Easton",
    "city_ascii": "Easton",
    "lat": 41.2649,
    "lng": -73.3,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 7558,
    "id": 1840034684
  },
  {
    "city": "Columbia Falls",
    "city_ascii": "Columbia Falls",
    "lat": 48.3708,
    "lng": -114.1903,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Montana",
    "capital": "",
    "population": 7556,
    "id": 1840018351
  },
  {
    "city": "Khoshk Bījār",
    "city_ascii": "Khoshk Bijar",
    "lat": 37.3747,
    "lng": 49.7575,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Gīlān",
    "capital": "",
    "population": 7478,
    "id": 1364288944
  },
  {
    "city": "Curtici",
    "city_ascii": "Curtici",
    "lat": 46.3419,
    "lng": 21.3061,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Arad",
    "capital": "",
    "population": 7453,
    "id": 1642892960
  },
  {
    "city": "Harrison",
    "city_ascii": "Harrison",
    "lat": 35.1276,
    "lng": -85.1464,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 7555,
    "id": 1840013449
  },
  {
    "city": "Eden Isle",
    "city_ascii": "Eden Isle",
    "lat": 30.2268,
    "lng": -89.8043,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 7554,
    "id": 1840031150
  },
  {
    "city": "Ancient Oaks",
    "city_ascii": "Ancient Oaks",
    "lat": 40.536,
    "lng": -75.5852,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 7554,
    "id": 1840034845
  },
  {
    "city": "Jackson",
    "city_ascii": "Jackson",
    "lat": 38.3485,
    "lng": -120.7728,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 7550,
    "id": 1840018852
  },
  {
    "city": "Fairview",
    "city_ascii": "Fairview",
    "lat": 35.9815,
    "lng": -87.1291,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 7549,
    "id": 1840013379
  },
  {
    "city": "Shibecha",
    "city_ascii": "Shibecha",
    "lat": 43.3033,
    "lng": 144.6008,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hokkaidō",
    "capital": "",
    "population": 7542,
    "id": 1392811701
  },
  {
    "city": "Marshall",
    "city_ascii": "Marshall",
    "lat": 42.2617,
    "lng": -84.9597,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 7548,
    "id": 1840003177
  },
  {
    "city": "East Manchester",
    "city_ascii": "East Manchester",
    "lat": 40.0567,
    "lng": -76.7019,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 7545,
    "id": 1840151848
  },
  {
    "city": "Fukaura",
    "city_ascii": "Fukaura",
    "lat": 40.6481,
    "lng": 139.9278,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Aomori",
    "capital": "",
    "population": 7529,
    "id": 1392518219
  },
  {
    "city": "Meldorf",
    "city_ascii": "Meldorf",
    "lat": 54.0833,
    "lng": 9.0667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Schleswig-Holstein",
    "capital": "",
    "population": 7204,
    "id": 1276761814
  },
  {
    "city": "Market Deeping",
    "city_ascii": "Market Deeping",
    "lat": 52.6776,
    "lng": -0.3173,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Lincolnshire",
    "capital": "",
    "population": 6008,
    "id": 1826725246
  },
  {
    "city": "Kula",
    "city_ascii": "Kula",
    "lat": 20.7706,
    "lng": -156.3284,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Hawaii",
    "capital": "",
    "population": 7541,
    "id": 1840039188
  },
  {
    "city": "Llandudno Junction",
    "city_ascii": "Llandudno Junction",
    "lat": 53.284,
    "lng": -3.809,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Conwy",
    "capital": "",
    "population": 6722,
    "id": 1826451209
  },
  {
    "city": "North Hanover",
    "city_ascii": "North Hanover",
    "lat": 40.0799,
    "lng": -74.5869,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 7540,
    "id": 1840081618
  },
  {
    "city": "Sanger",
    "city_ascii": "Sanger",
    "lat": 33.3716,
    "lng": -97.1677,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 7540,
    "id": 1840022004
  },
  {
    "city": "Albertville",
    "city_ascii": "Albertville",
    "lat": 45.2364,
    "lng": -93.6618,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 7539,
    "id": 1840006706
  },
  {
    "city": "Mondim de Basto",
    "city_ascii": "Mondim de Basto",
    "lat": 41.4,
    "lng": -7.95,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Vila Real",
    "capital": "minor",
    "population": 7493,
    "id": 1620754590
  },
  {
    "city": "Anina",
    "city_ascii": "Anina",
    "lat": 45.0917,
    "lng": 21.8533,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Caraş-Severin",
    "capital": "",
    "population": 7485,
    "id": 1642309709
  },
  {
    "city": "Delafield",
    "city_ascii": "Delafield",
    "lat": 43.072,
    "lng": -88.3913,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 7536,
    "id": 1840003017
  },
  {
    "city": "Berlin",
    "city_ascii": "Berlin",
    "lat": 39.7915,
    "lng": -74.9375,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 7536,
    "id": 1840001505
  },
  {
    "city": "Néa Kallikráteia",
    "city_ascii": "Nea Kallikrateia",
    "lat": 40.3139,
    "lng": 23.0633,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Kentrikí Makedonía",
    "capital": "",
    "population": 7238,
    "id": 1300364296
  },
  {
    "city": "Dno",
    "city_ascii": "Dno",
    "lat": 57.8333,
    "lng": 29.9667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Pskovskaya Oblast’",
    "capital": "",
    "population": 7525,
    "id": 1643207742
  },
  {
    "city": "Vimperk",
    "city_ascii": "Vimperk",
    "lat": 49.0525,
    "lng": 13.7743,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Jihočeský Kraj",
    "capital": "",
    "population": 7440,
    "id": 1203222398
  },
  {
    "city": "Big Stone Gap",
    "city_ascii": "Big Stone Gap",
    "lat": 36.8626,
    "lng": -82.7769,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 7533,
    "id": 1840006481
  },
  {
    "city": "Napajedla",
    "city_ascii": "Napajedla",
    "lat": 49.1716,
    "lng": 17.512,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Zlínský Kraj",
    "capital": "",
    "population": 7171,
    "id": 1203613770
  },
  {
    "city": "Royalton",
    "city_ascii": "Royalton",
    "lat": 43.1522,
    "lng": -78.5434,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 7532,
    "id": 1840087867
  },
  {
    "city": "Pikeville",
    "city_ascii": "Pikeville",
    "lat": 37.4807,
    "lng": -82.5262,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kentucky",
    "capital": "",
    "population": 7531,
    "id": 1840014348
  },
  {
    "city": "Fehring",
    "city_ascii": "Fehring",
    "lat": 46.9364,
    "lng": 16.0106,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Steiermark",
    "capital": "",
    "population": 7284,
    "id": 1040806778
  },
  {
    "city": "Haddon Heights",
    "city_ascii": "Haddon Heights",
    "lat": 39.8791,
    "lng": -75.0645,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 7529,
    "id": 1840003779
  },
  {
    "city": "Larose",
    "city_ascii": "Larose",
    "lat": 29.5669,
    "lng": -90.3751,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 7529,
    "id": 1840014025
  },
  {
    "city": "Loudoun Valley Estates",
    "city_ascii": "Loudoun Valley Estates",
    "lat": 38.9801,
    "lng": -77.5082,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 7528,
    "id": 1840041775
  },
  {
    "city": "Porto Cristo",
    "city_ascii": "Porto Cristo",
    "lat": 39.5444,
    "lng": 3.3375,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Balearic Islands",
    "capital": "",
    "population": 7355,
    "id": 1724344814
  },
  {
    "city": "Gold River",
    "city_ascii": "Gold River",
    "lat": 38.6268,
    "lng": -121.2488,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 7523,
    "id": 1840017583
  },
  {
    "city": "Fairfax",
    "city_ascii": "Fairfax",
    "lat": 37.9886,
    "lng": -122.5952,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 7522,
    "id": 1840021516
  },
  {
    "city": "Dumbrăveni",
    "city_ascii": "Dumbraveni",
    "lat": 46.2275,
    "lng": 24.5758,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Sibiu",
    "capital": "",
    "population": 7388,
    "id": 1642970323
  },
  {
    "city": "Königsee",
    "city_ascii": "Konigsee",
    "lat": 50.6613,
    "lng": 11.0974,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Thuringia",
    "capital": "",
    "population": 7448,
    "id": 1276823420
  },
  {
    "city": "Mount Horeb",
    "city_ascii": "Mount Horeb",
    "lat": 43.006,
    "lng": -89.7317,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 7520,
    "id": 1840002921
  },
  {
    "city": "Mantua",
    "city_ascii": "Mantua",
    "lat": 38.8526,
    "lng": -77.2571,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 7520,
    "id": 1840006036
  },
  {
    "city": "Albbruck",
    "city_ascii": "Albbruck",
    "lat": 47.5908,
    "lng": 8.1295,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 7335,
    "id": 1276244529
  },
  {
    "city": "Zlatna",
    "city_ascii": "Zlatna",
    "lat": 46.1589,
    "lng": 23.2211,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Alba",
    "capital": "",
    "population": 7490,
    "id": 1642768857
  },
  {
    "city": "Drvar",
    "city_ascii": "Drvar",
    "lat": 44.3739,
    "lng": 16.3808,
    "country": "Bosnia And Herzegovina",
    "iso2": "BA",
    "iso3": "BIH",
    "admin_name": "Bosnia and Herzegovina, Federation of",
    "capital": "minor",
    "population": 7506,
    "id": 1070145657
  },
  {
    "city": "Norwich",
    "city_ascii": "Norwich",
    "lat": 42.5333,
    "lng": -75.5227,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 7518,
    "id": 1840000424
  },
  {
    "city": "Benton",
    "city_ascii": "Benton",
    "lat": 38.011,
    "lng": -88.9179,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 7518,
    "id": 1840007543
  },
  {
    "city": "Marengo",
    "city_ascii": "Marengo",
    "lat": 42.2312,
    "lng": -88.6153,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 7517,
    "id": 1840008084
  },
  {
    "city": "Louiseville",
    "city_ascii": "Louiseville",
    "lat": 46.25,
    "lng": -72.95,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 7517,
    "id": 1124866425
  },
  {
    "city": "Chesterfield",
    "city_ascii": "Chesterfield",
    "lat": 40.1165,
    "lng": -74.6459,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 7516,
    "id": 1840081640
  },
  {
    "city": "Lower Windsor",
    "city_ascii": "Lower Windsor",
    "lat": 39.962,
    "lng": -76.5362,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 7514,
    "id": 1840151965
  },
  {
    "city": "Fairlawn",
    "city_ascii": "Fairlawn",
    "lat": 41.127,
    "lng": -81.6215,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 7514,
    "id": 1840008253
  },
  {
    "city": "Fort Scott",
    "city_ascii": "Fort Scott",
    "lat": 37.8283,
    "lng": -94.7038,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kansas",
    "capital": "",
    "population": 7514,
    "id": 1840001683
  },
  {
    "city": "Robinson",
    "city_ascii": "Robinson",
    "lat": 39.0089,
    "lng": -87.7333,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 7513,
    "id": 1840009691
  },
  {
    "city": "Úvaly",
    "city_ascii": "Uvaly",
    "lat": 50.074,
    "lng": 14.7309,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Středočeský Kraj",
    "capital": "",
    "population": 6884,
    "id": 1203453685
  },
  {
    "city": "El Centenario",
    "city_ascii": "El Centenario",
    "lat": 24.1025,
    "lng": -110.4144,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Baja California Sur",
    "capital": "",
    "population": 6068,
    "id": 1484810855
  },
  {
    "city": "Tyrone",
    "city_ascii": "Tyrone",
    "lat": 33.4753,
    "lng": -84.5941,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 7506,
    "id": 1840016845
  },
  {
    "city": "Tateishi",
    "city_ascii": "Tateishi",
    "lat": 38.9867,
    "lng": 141.1142,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Iwate",
    "capital": "",
    "population": 7389,
    "id": 1392007825
  },
  {
    "city": "Castlemaine",
    "city_ascii": "Castlemaine",
    "lat": -37.0636,
    "lng": 144.2172,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Victoria",
    "capital": "",
    "population": 6757,
    "id": 1036572269
  },
  {
    "city": "Diamondhead",
    "city_ascii": "Diamondhead",
    "lat": 30.3791,
    "lng": -89.3708,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Mississippi",
    "capital": "",
    "population": 7504,
    "id": 1840013106
  },
  {
    "city": "Chibougamau",
    "city_ascii": "Chibougamau",
    "lat": 49.9167,
    "lng": -74.3667,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 7504,
    "id": 1124650514
  },
  {
    "city": "Swaffham",
    "city_ascii": "Swaffham",
    "lat": 52.6529,
    "lng": 0.6843,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Norfolk",
    "capital": "",
    "population": 7258,
    "id": 1826045315
  },
  {
    "city": "Laughlin",
    "city_ascii": "Laughlin",
    "lat": 35.1316,
    "lng": -114.689,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Nevada",
    "capital": "",
    "population": 7502,
    "id": 1840017689
  },
  {
    "city": "Purcell",
    "city_ascii": "Purcell",
    "lat": 35.018,
    "lng": -97.3747,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oklahoma",
    "capital": "",
    "population": 7502,
    "id": 1840020454
  },
  {
    "city": "Lowell",
    "city_ascii": "Lowell",
    "lat": 42.9351,
    "lng": -85.3457,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 7501,
    "id": 1840002931
  },
  {
    "city": "Creston",
    "city_ascii": "Creston",
    "lat": 41.0597,
    "lng": -94.365,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Iowa",
    "capital": "",
    "population": 7499,
    "id": 1840000892
  },
  {
    "city": "West Slope",
    "city_ascii": "West Slope",
    "lat": 45.4962,
    "lng": -122.7731,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 7498,
    "id": 1840018554
  },
  {
    "city": "Vohenstrauß",
    "city_ascii": "Vohenstrauss",
    "lat": 49.6167,
    "lng": 12.3333,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 7398,
    "id": 1276360116
  },
  {
    "city": "Miranda do Douro",
    "city_ascii": "Miranda do Douro",
    "lat": 41.5,
    "lng": -6.2667,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Bragança",
    "capital": "minor",
    "population": 7482,
    "id": 1620082713
  },
  {
    "city": "Beebe",
    "city_ascii": "Beebe",
    "lat": 35.0712,
    "lng": -91.8996,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arkansas",
    "capital": "",
    "population": 7497,
    "id": 1840013439
  },
  {
    "city": "Moravské Budějovice",
    "city_ascii": "Moravske Budejovice",
    "lat": 49.0521,
    "lng": 15.8087,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Vysočina",
    "capital": "",
    "population": 7300,
    "id": 1203654629
  },
  {
    "city": "Birkenfeld",
    "city_ascii": "Birkenfeld",
    "lat": 49.65,
    "lng": 7.1833,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Rhineland-Palatinate",
    "capital": "minor",
    "population": 6984,
    "id": 1276784348
  },
  {
    "city": "Haslach im Kinzigtal",
    "city_ascii": "Haslach im Kinzigtal",
    "lat": 48.2778,
    "lng": 8.0869,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 7114,
    "id": 1276600823
  },
  {
    "city": "Dos Palos",
    "city_ascii": "Dos Palos",
    "lat": 36.9854,
    "lng": -120.6336,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 7494,
    "id": 1840018935
  },
  {
    "city": "Šahy",
    "city_ascii": "Sahy",
    "lat": 48.0667,
    "lng": 18.9667,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Nitriansky",
    "capital": "",
    "population": 7321,
    "id": 1703295714
  },
  {
    "city": "Citrus Hills",
    "city_ascii": "Citrus Hills",
    "lat": 28.887,
    "lng": -82.4312,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 7492,
    "id": 1840028978
  },
  {
    "city": "Aylmer",
    "city_ascii": "Aylmer",
    "lat": 42.7667,
    "lng": -80.9833,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 7492,
    "id": 1124964102
  },
  {
    "city": "Peqin",
    "city_ascii": "Peqin",
    "lat": 41.05,
    "lng": 19.75,
    "country": "Albania",
    "iso2": "AL",
    "iso3": "ALB",
    "admin_name": "Elbasan",
    "capital": "",
    "population": 6353,
    "id": 1008394656
  },
  {
    "city": "Strömstad",
    "city_ascii": "Stromstad",
    "lat": 58.9441,
    "lng": 11.1864,
    "country": "Sweden",
    "iso2": "SE",
    "iso3": "SWE",
    "admin_name": "Västra Götaland",
    "capital": "minor",
    "population": 7355,
    "id": 1752574047
  },
  {
    "city": "Park Street",
    "city_ascii": "Park Street",
    "lat": 51.722,
    "lng": -0.339,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hertfordshire",
    "capital": "",
    "population": 6781,
    "id": 1826341267
  },
  {
    "city": "Naka",
    "city_ascii": "Naka",
    "lat": 33.8574,
    "lng": 134.4966,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Tokushima",
    "capital": "",
    "population": 7476,
    "id": 1392537815
  },
  {
    "city": "Nová Baňa",
    "city_ascii": "Nova Bana",
    "lat": 48.4267,
    "lng": 18.6394,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Banskobystrický",
    "capital": "",
    "population": 7364,
    "id": 1703126832
  },
  {
    "city": "Homberg",
    "city_ascii": "Homberg",
    "lat": 50.7333,
    "lng": 9,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 7400,
    "id": 1276062055
  },
  {
    "city": "Upper Hanover",
    "city_ascii": "Upper Hanover",
    "lat": 40.3954,
    "lng": -75.5106,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 7483,
    "id": 1840152391
  },
  {
    "city": "West Brandywine",
    "city_ascii": "West Brandywine",
    "lat": 40.0413,
    "lng": -75.8121,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 7482,
    "id": 1840145642
  },
  {
    "city": "Malchin",
    "city_ascii": "Malchin",
    "lat": 53.7333,
    "lng": 12.7833,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Mecklenburg-Western Pomerania",
    "capital": "",
    "population": 7403,
    "id": 1276013353
  },
  {
    "city": "Blaxland",
    "city_ascii": "Blaxland",
    "lat": -33.7453,
    "lng": 150.6175,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 7451,
    "id": 1036026558
  },
  {
    "city": "Bilisht",
    "city_ascii": "Bilisht",
    "lat": 40.6275,
    "lng": 20.99,
    "country": "Albania",
    "iso2": "AL",
    "iso3": "ALB",
    "admin_name": "Korçë",
    "capital": "",
    "population": 7114,
    "id": 1008832248
  },
  {
    "city": "McCordsville",
    "city_ascii": "McCordsville",
    "lat": 39.8967,
    "lng": -85.9209,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 7479,
    "id": 1840009526
  },
  {
    "city": "Lockwood",
    "city_ascii": "Lockwood",
    "lat": 45.8199,
    "lng": -108.4072,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Montana",
    "capital": "",
    "population": 7479,
    "id": 1840018492
  },
  {
    "city": "Librazhd",
    "city_ascii": "Librazhd",
    "lat": 41.1833,
    "lng": 20.3167,
    "country": "Albania",
    "iso2": "AL",
    "iso3": "ALB",
    "admin_name": "Elbasan",
    "capital": "",
    "population": 6937,
    "id": 1008800835
  },
  {
    "city": "Sovetsk",
    "city_ascii": "Sovetsk",
    "lat": 53.9333,
    "lng": 37.6333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Tul’skaya Oblast’",
    "capital": "",
    "population": 7405,
    "id": 1643068211
  },
  {
    "city": "Silvis",
    "city_ascii": "Silvis",
    "lat": 41.4976,
    "lng": -90.4101,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 7475,
    "id": 1840009232
  },
  {
    "city": "Bilton",
    "city_ascii": "Bilton",
    "lat": 52.3594,
    "lng": -1.29,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Warwickshire",
    "capital": "",
    "population": 6175,
    "id": 1826936718
  },
  {
    "city": "Upper Deerfield",
    "city_ascii": "Upper Deerfield",
    "lat": 39.4936,
    "lng": -75.2158,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 7473,
    "id": 1840081586
  },
  {
    "city": "Friedrichroda",
    "city_ascii": "Friedrichroda",
    "lat": 50.8575,
    "lng": 10.5651,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Thuringia",
    "capital": "",
    "population": 7275,
    "id": 1276292686
  },
  {
    "city": "Pulaski",
    "city_ascii": "Pulaski",
    "lat": 35.1933,
    "lng": -87.035,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 7472,
    "id": 1840014575
  },
  {
    "city": "Mount Shasta",
    "city_ascii": "Mount Shasta",
    "lat": 41.3206,
    "lng": -122.315,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 7472,
    "id": 1840020106
  },
  {
    "city": "Salair",
    "city_ascii": "Salair",
    "lat": 54.2333,
    "lng": 85.8,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kemerovskaya Oblast’",
    "capital": "",
    "population": 7452,
    "id": 1643506139
  },
  {
    "city": "Kirillov",
    "city_ascii": "Kirillov",
    "lat": 59.8667,
    "lng": 38.3833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Vologodskaya Oblast’",
    "capital": "",
    "population": 7468,
    "id": 1643527169
  },
  {
    "city": "Ojai",
    "city_ascii": "Ojai",
    "lat": 34.4487,
    "lng": -119.2469,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 7470,
    "id": 1840020473
  },
  {
    "city": "Monticello",
    "city_ascii": "Monticello",
    "lat": 41.6522,
    "lng": -74.6876,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 7468,
    "id": 1840004791
  },
  {
    "city": "Lennox Head",
    "city_ascii": "Lennox Head",
    "lat": -28.7983,
    "lng": 153.5844,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 6407,
    "id": 1036688711
  },
  {
    "city": "Brecknock",
    "city_ascii": "Brecknock",
    "lat": 40.1977,
    "lng": -76.027,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 7465,
    "id": 1840145617
  },
  {
    "city": "Guatapará",
    "city_ascii": "Guatapara",
    "lat": -21.4967,
    "lng": -48.0378,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 7446,
    "id": 1076115869
  },
  {
    "city": "Nisa",
    "city_ascii": "Nisa",
    "lat": 39.5167,
    "lng": -7.65,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Portalegre",
    "capital": "minor",
    "population": 7450,
    "id": 1620160949
  },
  {
    "city": "Delson",
    "city_ascii": "Delson",
    "lat": 45.37,
    "lng": -73.55,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 7462,
    "id": 1124405717
  },
  {
    "city": "Villa Hills",
    "city_ascii": "Villa Hills",
    "lat": 39.0656,
    "lng": -84.595,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kentucky",
    "capital": "",
    "population": 7461,
    "id": 1840015176
  },
  {
    "city": "Radeburg",
    "city_ascii": "Radeburg",
    "lat": 51.2125,
    "lng": 13.7256,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 7325,
    "id": 1276619918
  },
  {
    "city": "Kaplice",
    "city_ascii": "Kaplice",
    "lat": 48.7386,
    "lng": 14.4963,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Jihočeský Kraj",
    "capital": "",
    "population": 7282,
    "id": 1203715170
  },
  {
    "city": "St. Gabriel",
    "city_ascii": "St. Gabriel",
    "lat": 30.2537,
    "lng": -91.1013,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 7460,
    "id": 1840015926
  },
  {
    "city": "Píllaro",
    "city_ascii": "Pillaro",
    "lat": -1.1667,
    "lng": -78.5333,
    "country": "Ecuador",
    "iso2": "EC",
    "iso3": "ECU",
    "admin_name": "Tungurahua",
    "capital": "",
    "population": 7444,
    "id": 1218633386
  },
  {
    "city": "Trstená",
    "city_ascii": "Trstena",
    "lat": 49.3667,
    "lng": 19.6167,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Žilinský",
    "capital": "",
    "population": 7368,
    "id": 1703661992
  },
  {
    "city": "Palmer",
    "city_ascii": "Palmer",
    "lat": 61.5971,
    "lng": -149.1147,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alaska",
    "capital": "",
    "population": 7456,
    "id": 1840023379
  },
  {
    "city": "Sheringham",
    "city_ascii": "Sheringham",
    "lat": 52.94,
    "lng": 1.213,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Norfolk",
    "capital": "",
    "population": 7367,
    "id": 1826691574
  },
  {
    "city": "Otterndorf",
    "city_ascii": "Otterndorf",
    "lat": 53.8081,
    "lng": 8.8997,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 7238,
    "id": 1276340226
  },
  {
    "city": "Nădlac",
    "city_ascii": "Nadlac",
    "lat": 46.1667,
    "lng": 20.75,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Arad",
    "capital": "",
    "population": 7398,
    "id": 1642740241
  },
  {
    "city": "Bomaderry",
    "city_ascii": "Bomaderry",
    "lat": -34.848,
    "lng": 150.605,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 6661,
    "id": 1036102200
  },
  {
    "city": "Techirghiol",
    "city_ascii": "Techirghiol",
    "lat": 44.0575,
    "lng": 28.5958,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Constanţa",
    "capital": "",
    "population": 7292,
    "id": 1642840114
  },
  {
    "city": "Amara",
    "city_ascii": "Amara",
    "lat": 44.62,
    "lng": 27.32,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Ialomiţa",
    "capital": "",
    "population": 7345,
    "id": 1642292368
  },
  {
    "city": "Keystone Heights",
    "city_ascii": "Keystone Heights",
    "lat": 29.7812,
    "lng": -82.034,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 7449,
    "id": 1840015049
  },
  {
    "city": "Topanga",
    "city_ascii": "Topanga",
    "lat": 34.0964,
    "lng": -118.6053,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 7449,
    "id": 1840025006
  },
  {
    "city": "Haapajärvi",
    "city_ascii": "Haapajarvi",
    "lat": 63.7486,
    "lng": 25.3181,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Pohjois-Pohjanmaa",
    "capital": "minor",
    "population": 7438,
    "id": 1246579975
  },
  {
    "city": "Lenti",
    "city_ascii": "Lenti",
    "lat": 46.6236,
    "lng": 16.5458,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Zala",
    "capital": "minor",
    "population": 7348,
    "id": 1348130226
  },
  {
    "city": "Waterloo",
    "city_ascii": "Waterloo",
    "lat": 42.9156,
    "lng": -76.9123,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 7443,
    "id": 1840004421
  },
  {
    "city": "Gundelsheim",
    "city_ascii": "Gundelsheim",
    "lat": 49.2833,
    "lng": 9.1667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 7254,
    "id": 1276032630
  },
  {
    "city": "North Star",
    "city_ascii": "North Star",
    "lat": 39.755,
    "lng": -75.7331,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Delaware",
    "capital": "",
    "population": 7441,
    "id": 1840028615
  },
  {
    "city": "White Oak",
    "city_ascii": "White Oak",
    "lat": 40.3415,
    "lng": -79.8007,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 7441,
    "id": 1840001277
  },
  {
    "city": "Orosi",
    "city_ascii": "Orosi",
    "lat": 36.5434,
    "lng": -119.2903,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 7441,
    "id": 1840019035
  },
  {
    "city": "Rockton",
    "city_ascii": "Rockton",
    "lat": 42.45,
    "lng": -89.0629,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 7441,
    "id": 1840011121
  },
  {
    "city": "Cheadle",
    "city_ascii": "Cheadle",
    "lat": 53.3933,
    "lng": -2.2113,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Stockport",
    "capital": "",
    "population": 5698,
    "id": 1826944634
  },
  {
    "city": "Treuenbrietzen",
    "city_ascii": "Treuenbrietzen",
    "lat": 52.0972,
    "lng": 12.8711,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Brandenburg",
    "capital": "",
    "population": 7405,
    "id": 1276242069
  },
  {
    "city": "Gedern",
    "city_ascii": "Gedern",
    "lat": 50.4244,
    "lng": 9.1997,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 7342,
    "id": 1276392684
  },
  {
    "city": "Nyergesújfalu",
    "city_ascii": "Nyergesujfalu",
    "lat": 47.7603,
    "lng": 18.5567,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Komárom-Esztergom",
    "capital": "",
    "population": 7255,
    "id": 1348664163
  },
  {
    "city": "Rhoose",
    "city_ascii": "Rhoose",
    "lat": 51.3906,
    "lng": -3.3524,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Vale of Glamorgan, The",
    "capital": "",
    "population": 6160,
    "id": 1826678049
  },
  {
    "city": "Ashland",
    "city_ascii": "Ashland",
    "lat": 40.7811,
    "lng": -76.3451,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 7437,
    "id": 1840002753
  },
  {
    "city": "Eibenstock",
    "city_ascii": "Eibenstock",
    "lat": 50.4956,
    "lng": 12.5975,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 7370,
    "id": 1276659381
  },
  {
    "city": "Bernkastel-Kues",
    "city_ascii": "Bernkastel-Kues",
    "lat": 49.9161,
    "lng": 7.0694,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Rhineland-Palatinate",
    "capital": "",
    "population": 7134,
    "id": 1276821773
  },
  {
    "city": "Malmyzh",
    "city_ascii": "Malmyzh",
    "lat": 56.5167,
    "lng": 50.6667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kirovskaya Oblast’",
    "capital": "",
    "population": 7422,
    "id": 1643855035
  },
  {
    "city": "Leipheim",
    "city_ascii": "Leipheim",
    "lat": 48.4489,
    "lng": 10.2208,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 7209,
    "id": 1276106198
  },
  {
    "city": "Wingerworth",
    "city_ascii": "Wingerworth",
    "lat": 53.2033,
    "lng": -1.4347,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Derbyshire",
    "capital": "",
    "population": 6533,
    "id": 1826060407
  },
  {
    "city": "Florence",
    "city_ascii": "Florence",
    "lat": 38.3836,
    "lng": -105.1114,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 7433,
    "id": 1840020250
  },
  {
    "city": "Coracora",
    "city_ascii": "Coracora",
    "lat": -15.017,
    "lng": -73.7804,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "Ayacucho",
    "capital": "",
    "population": 7420,
    "id": 1604800656
  },
  {
    "city": "Highlands",
    "city_ascii": "Highlands",
    "lat": 29.813,
    "lng": -95.0577,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 7432,
    "id": 1840018254
  },
  {
    "city": "Edgerton",
    "city_ascii": "Edgerton",
    "lat": 42.8385,
    "lng": -89.0699,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 7431,
    "id": 1840002468
  },
  {
    "city": "Old Lyme",
    "city_ascii": "Old Lyme",
    "lat": 41.32,
    "lng": -72.3034,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 7431,
    "id": 1840035523
  },
  {
    "city": "Old Town",
    "city_ascii": "Old Town",
    "lat": 44.9491,
    "lng": -68.7249,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maine",
    "capital": "",
    "population": 7431,
    "id": 1840000240
  },
  {
    "city": "New London",
    "city_ascii": "New London",
    "lat": 44.395,
    "lng": -88.7394,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 7430,
    "id": 1840002361
  },
  {
    "city": "Kingswood",
    "city_ascii": "Kingswood",
    "lat": 51.2931,
    "lng": -0.2086,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Surrey",
    "capital": "",
    "population": 6891,
    "id": 1826912606
  },
  {
    "city": "McCook",
    "city_ascii": "McCook",
    "lat": 40.2046,
    "lng": -100.6214,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Nebraska",
    "capital": "",
    "population": 7426,
    "id": 1840008369
  },
  {
    "city": "Shenfield",
    "city_ascii": "Shenfield",
    "lat": 51.6297,
    "lng": 0.3192,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Essex",
    "capital": "",
    "population": 5432,
    "id": 1826482164
  },
  {
    "city": "Kimberley",
    "city_ascii": "Kimberley",
    "lat": 49.6697,
    "lng": -115.9775,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "British Columbia",
    "capital": "",
    "population": 7425,
    "id": 1124170837
  },
  {
    "city": "Weikersheim",
    "city_ascii": "Weikersheim",
    "lat": 49.4817,
    "lng": 9.8992,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 7333,
    "id": 1276859303
  },
  {
    "city": "North Bend",
    "city_ascii": "North Bend",
    "lat": 47.49,
    "lng": -121.7742,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 7423,
    "id": 1840019833
  },
  {
    "city": "Etowah",
    "city_ascii": "Etowah",
    "lat": 35.3061,
    "lng": -82.5902,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 7420,
    "id": 1840013074
  },
  {
    "city": "Hiawatha",
    "city_ascii": "Hiawatha",
    "lat": 42.0547,
    "lng": -91.6911,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Iowa",
    "capital": "",
    "population": 7420,
    "id": 1840006989
  },
  {
    "city": "South Berwick",
    "city_ascii": "South Berwick",
    "lat": 43.2388,
    "lng": -70.7478,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maine",
    "capital": "",
    "population": 7420,
    "id": 1840053002
  },
  {
    "city": "Mansôa",
    "city_ascii": "Mansoa",
    "lat": 12.0667,
    "lng": -15.3167,
    "country": "Guinea-Bissau",
    "iso2": "GW",
    "iso3": "GNB",
    "admin_name": "Oio",
    "capital": "",
    "population": 7376,
    "id": 1624108919
  },
  {
    "city": "Hubbard",
    "city_ascii": "Hubbard",
    "lat": 41.1595,
    "lng": -80.5672,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 7419,
    "id": 1840007130
  },
  {
    "city": "River Rouge",
    "city_ascii": "River Rouge",
    "lat": 42.2731,
    "lng": -83.1246,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 7419,
    "id": 1840003960
  },
  {
    "city": "Stratham",
    "city_ascii": "Stratham",
    "lat": 43.0157,
    "lng": -70.9006,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Hampshire",
    "capital": "",
    "population": 7418,
    "id": 1840055601
  },
  {
    "city": "Sandwich",
    "city_ascii": "Sandwich",
    "lat": 41.6496,
    "lng": -88.6177,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 7418,
    "id": 1840009188
  },
  {
    "city": "Oebisfelde",
    "city_ascii": "Oebisfelde",
    "lat": 52.4333,
    "lng": 10.9833,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony-Anhalt",
    "capital": "",
    "population": 7341,
    "id": 1276898332
  },
  {
    "city": "Belen",
    "city_ascii": "Belen",
    "lat": 34.7115,
    "lng": -106.7985,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Mexico",
    "capital": "",
    "population": 7416,
    "id": 1840019194
  },
  {
    "city": "Inverness",
    "city_ascii": "Inverness",
    "lat": 28.8397,
    "lng": -82.3437,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 7414,
    "id": 1840015085
  },
  {
    "city": "Marion",
    "city_ascii": "Marion",
    "lat": 36.8389,
    "lng": -81.5135,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 7414,
    "id": 1840006515
  },
  {
    "city": "Iwye",
    "city_ascii": "Iwye",
    "lat": 53.9307,
    "lng": 25.7703,
    "country": "Belarus",
    "iso2": "BY",
    "iso3": "BLR",
    "admin_name": "Hrodzyenskaya Voblasts’",
    "capital": "",
    "population": 7400,
    "id": 1112135629
  },
  {
    "city": "Elassóna",
    "city_ascii": "Elassona",
    "lat": 39.8947,
    "lng": 22.1886,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Thessalía",
    "capital": "minor",
    "population": 7338,
    "id": 1300625964
  },
  {
    "city": "Přeštice",
    "city_ascii": "Prestice",
    "lat": 49.5731,
    "lng": 13.3335,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Plzeňský Kraj",
    "capital": "",
    "population": 7131,
    "id": 1203411660
  },
  {
    "city": "Great Bookham",
    "city_ascii": "Great Bookham",
    "lat": 51.278,
    "lng": -0.373,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Surrey",
    "capital": "",
    "population": 5596,
    "id": 1826910231
  },
  {
    "city": "Cotati",
    "city_ascii": "Cotati",
    "lat": 38.3279,
    "lng": -122.7092,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 7410,
    "id": 1840018840
  },
  {
    "city": "Soltvadkert",
    "city_ascii": "Soltvadkert",
    "lat": 46.5808,
    "lng": 19.3936,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Bács-Kiskun",
    "capital": "",
    "population": 7342,
    "id": 1348773002
  },
  {
    "city": "Atherton",
    "city_ascii": "Atherton",
    "lat": -17.2658,
    "lng": 145.478,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Queensland",
    "capital": "",
    "population": 7331,
    "id": 1036456869
  },
  {
    "city": "Český Brod",
    "city_ascii": "Cesky Brod",
    "lat": 50.0743,
    "lng": 14.8609,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Středočeský Kraj",
    "capital": "",
    "population": 7049,
    "id": 1203723784
  },
  {
    "city": "Victoria",
    "city_ascii": "Victoria",
    "lat": 45.6466,
    "lng": 24.7059,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Braşov",
    "capital": "",
    "population": 7386,
    "id": 1642446315
  },
  {
    "city": "Mills River",
    "city_ascii": "Mills River",
    "lat": 35.3853,
    "lng": -82.5854,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 7406,
    "id": 1840016370
  },
  {
    "city": "Calverton",
    "city_ascii": "Calverton",
    "lat": 53.037,
    "lng": -1.083,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Nottinghamshire",
    "capital": "",
    "population": 7076,
    "id": 1826493628
  },
  {
    "city": "Saranac Lake",
    "city_ascii": "Saranac Lake",
    "lat": 44.3245,
    "lng": -74.1314,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 7404,
    "id": 1840004068
  },
  {
    "city": "Talayuela",
    "city_ascii": "Talayuela",
    "lat": 39.986,
    "lng": -5.6096,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Extremadura",
    "capital": "",
    "population": 7371,
    "id": 1724921640
  },
  {
    "city": "West Cocalico",
    "city_ascii": "West Cocalico",
    "lat": 40.2658,
    "lng": -76.163,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 7403,
    "id": 1840142497
  },
  {
    "city": "Rzhyshchiv",
    "city_ascii": "Rzhyshchiv",
    "lat": 49.9611,
    "lng": 31.0436,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Kyyivs’ka Oblast’",
    "capital": "",
    "population": 7359,
    "id": 1804173963
  },
  {
    "city": "Kawerau",
    "city_ascii": "Kawerau",
    "lat": -38.0847,
    "lng": 176.6996,
    "country": "New Zealand",
    "iso2": "NZ",
    "iso3": "NZL",
    "admin_name": "Bay of Plenty",
    "capital": "",
    "population": 7080,
    "id": 1554460221
  },
  {
    "city": "West Concord",
    "city_ascii": "West Concord",
    "lat": 42.4518,
    "lng": -71.4035,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 7402,
    "id": 1840003133
  },
  {
    "city": "Sanibel",
    "city_ascii": "Sanibel",
    "lat": 26.4534,
    "lng": -82.1023,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 7401,
    "id": 1840015996
  },
  {
    "city": "Fairfield",
    "city_ascii": "Fairfield",
    "lat": 40.8828,
    "lng": -74.3041,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 7400,
    "id": 1840073255
  },
  {
    "city": "Frankfort",
    "city_ascii": "Frankfort",
    "lat": 43.0389,
    "lng": -75.1377,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 7400,
    "id": 1840004147
  },
  {
    "city": "Farmington",
    "city_ascii": "Farmington",
    "lat": 36.037,
    "lng": -94.2537,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arkansas",
    "capital": "",
    "population": 7400,
    "id": 1840013367
  },
  {
    "city": "Suonenjoki",
    "city_ascii": "Suonenjoki",
    "lat": 62.625,
    "lng": 27.1222,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Pohjois-Savo",
    "capital": "minor",
    "population": 7390,
    "id": 1246784846
  },
  {
    "city": "Blandford-Blenheim",
    "city_ascii": "Blandford-Blenheim",
    "lat": 43.2333,
    "lng": -80.6,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 7399,
    "id": 1124001001
  },
  {
    "city": "Soldotna",
    "city_ascii": "Soldotna",
    "lat": 60.4862,
    "lng": -151.0672,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alaska",
    "capital": "",
    "population": 7398,
    "id": 1840023415
  },
  {
    "city": "Simitli",
    "city_ascii": "Simitli",
    "lat": 41.8919,
    "lng": 23.1111,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Blagoevgrad",
    "capital": "",
    "population": 7372,
    "id": 1100000742
  },
  {
    "city": "Pirdop",
    "city_ascii": "Pirdop",
    "lat": 42.7,
    "lng": 24.1833,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Sofia",
    "capital": "",
    "population": 7353,
    "id": 1100198336
  },
  {
    "city": "Dévaványa",
    "city_ascii": "Devavanya",
    "lat": 47.03,
    "lng": 20.9589,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Békés",
    "capital": "",
    "population": 7363,
    "id": 1348309074
  },
  {
    "city": "Dačice",
    "city_ascii": "Dacice",
    "lat": 49.0816,
    "lng": 15.4373,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Jihočeský Kraj",
    "capital": "",
    "population": 7288,
    "id": 1203951769
  },
  {
    "city": "Bayham",
    "city_ascii": "Bayham",
    "lat": 42.7333,
    "lng": -80.7833,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 7396,
    "id": 1124000461
  },
  {
    "city": "Robeson",
    "city_ascii": "Robeson",
    "lat": 40.2363,
    "lng": -75.8724,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 7395,
    "id": 1840144018
  },
  {
    "city": "Pătârlagele",
    "city_ascii": "Patarlagele",
    "lat": 45.3189,
    "lng": 26.3597,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Buzău",
    "capital": "",
    "population": 7304,
    "id": 1642932973
  },
  {
    "city": "Welcome",
    "city_ascii": "Welcome",
    "lat": 34.8204,
    "lng": -82.46,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 7394,
    "id": 1840013499
  },
  {
    "city": "New Bremen",
    "city_ascii": "New Bremen",
    "lat": 40.4356,
    "lng": -84.3777,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 7393,
    "id": 1840012071
  },
  {
    "city": "Neugersdorf",
    "city_ascii": "Neugersdorf",
    "lat": 50.9789,
    "lng": 14.6108,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 6260,
    "id": 1276966059
  },
  {
    "city": "Mentor-on-the-Lake",
    "city_ascii": "Mentor-on-the-Lake",
    "lat": 41.7135,
    "lng": -81.365,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 7390,
    "id": 1840034156
  },
  {
    "city": "Aiquile",
    "city_ascii": "Aiquile",
    "lat": -18.1667,
    "lng": -65.1667,
    "country": "Bolivia",
    "iso2": "BO",
    "iso3": "BOL",
    "admin_name": "Cochabamba",
    "capital": "",
    "population": 7381,
    "id": 1068121487
  },
  {
    "city": "Baywood",
    "city_ascii": "Baywood",
    "lat": 40.7533,
    "lng": -73.29,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 7389,
    "id": 1840034028
  },
  {
    "city": "Middletown",
    "city_ascii": "Middletown",
    "lat": 40.6441,
    "lng": -75.3244,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 7386,
    "id": 1840145547
  },
  {
    "city": "Tuncurry",
    "city_ascii": "Tuncurry",
    "lat": -32.175,
    "lng": 152.4989,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 6186,
    "id": 1036846186
  },
  {
    "city": "Farr West",
    "city_ascii": "Farr West",
    "lat": 41.3015,
    "lng": -112.0318,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Utah",
    "capital": "",
    "population": 7385,
    "id": 1840020128
  },
  {
    "city": "Borba",
    "city_ascii": "Borba",
    "lat": 38.8,
    "lng": -7.45,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Évora",
    "capital": "minor",
    "population": 7333,
    "id": 1620379777
  },
  {
    "city": "Odry",
    "city_ascii": "Odry",
    "lat": 49.6625,
    "lng": 17.8309,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Moravskoslezský Kraj",
    "capital": "",
    "population": 7285,
    "id": 1203190683
  },
  {
    "city": "Ephrata",
    "city_ascii": "Ephrata",
    "lat": 47.3122,
    "lng": -119.534,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 7383,
    "id": 1840019813
  },
  {
    "city": "South Heidelberg",
    "city_ascii": "South Heidelberg",
    "lat": 40.3122,
    "lng": -76.0969,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 7382,
    "id": 1840144015
  },
  {
    "city": "Homun",
    "city_ascii": "Homun",
    "lat": 20.7386,
    "lng": -89.285,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Yucatán",
    "capital": "",
    "population": 6146,
    "id": 1484015198
  },
  {
    "city": "Lihue",
    "city_ascii": "Lihue",
    "lat": 21.9728,
    "lng": -159.3541,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Hawaii",
    "capital": "",
    "population": 7381,
    "id": 1840029519
  },
  {
    "city": "Pembroke",
    "city_ascii": "Pembroke",
    "lat": 34.6766,
    "lng": -79.1934,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 7381,
    "id": 1840016541
  },
  {
    "city": "Komījān",
    "city_ascii": "Komijan",
    "lat": 34.7192,
    "lng": 49.3267,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Markazī",
    "capital": "minor",
    "population": 7358,
    "id": 1364723077
  },
  {
    "city": "Steele Creek",
    "city_ascii": "Steele Creek",
    "lat": 64.9295,
    "lng": -147.3955,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alaska",
    "capital": "",
    "population": 7380,
    "id": 1840075216
  },
  {
    "city": "Le Roy",
    "city_ascii": "Le Roy",
    "lat": 42.9937,
    "lng": -77.972,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 7379,
    "id": 1840004365
  },
  {
    "city": "University of California-Davis",
    "city_ascii": "University of California-Davis",
    "lat": 38.5374,
    "lng": -121.7576,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 7379,
    "id": 1840042938
  },
  {
    "city": "Slavkov u Brna",
    "city_ascii": "Slavkov u Brna",
    "lat": 49.1533,
    "lng": 16.8765,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Jihomoravský Kraj",
    "capital": "",
    "population": 6916,
    "id": 1203145713
  },
  {
    "city": "Ibirarema",
    "city_ascii": "Ibirarema",
    "lat": -22.8175,
    "lng": -50.0725,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 7376,
    "id": 1076430861
  },
  {
    "city": "Nambu",
    "city_ascii": "Nambu",
    "lat": 35.2425,
    "lng": 138.4861,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Yamanashi",
    "capital": "",
    "population": 7341,
    "id": 1392148578
  },
  {
    "city": "Tornaľa",
    "city_ascii": "Tornal'a",
    "lat": 48.4167,
    "lng": 20.3333,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Banskobystrický",
    "capital": "",
    "population": 7252,
    "id": 1703238478
  },
  {
    "city": "Providence Village",
    "city_ascii": "Providence Village",
    "lat": 33.2363,
    "lng": -96.9611,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 7377,
    "id": 1840043421
  },
  {
    "city": "Webster City",
    "city_ascii": "Webster City",
    "lat": 42.4623,
    "lng": -93.8167,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Iowa",
    "capital": "",
    "population": 7377,
    "id": 1840000449
  },
  {
    "city": "Inverness",
    "city_ascii": "Inverness",
    "lat": 42.1152,
    "lng": -88.1019,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 7376,
    "id": 1840011266
  },
  {
    "city": "Clarkston",
    "city_ascii": "Clarkston",
    "lat": 46.4161,
    "lng": -117.0505,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 7375,
    "id": 1840018499
  },
  {
    "city": "Arraiolos",
    "city_ascii": "Arraiolos",
    "lat": 38.7229,
    "lng": -7.9843,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Évora",
    "capital": "minor",
    "population": 7363,
    "id": 1620818948
  },
  {
    "city": "Smithfield Township",
    "city_ascii": "Smithfield Township",
    "lat": 41.0198,
    "lng": -75.1359,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 7373,
    "id": 1840144159
  },
  {
    "city": "Middlesex",
    "city_ascii": "Middlesex",
    "lat": 40.2502,
    "lng": -77.1356,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 7373,
    "id": 1840100367
  },
  {
    "city": "Tolleson",
    "city_ascii": "Tolleson",
    "lat": 33.4484,
    "lng": -112.2561,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 7372,
    "id": 1840021943
  },
  {
    "city": "Gröditz",
    "city_ascii": "Groditz",
    "lat": 51.4142,
    "lng": 13.4464,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Brandenburg",
    "capital": "",
    "population": 7125,
    "id": 1276026580
  },
  {
    "city": "New Roads",
    "city_ascii": "New Roads",
    "lat": 30.6959,
    "lng": -91.4537,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 7370,
    "id": 1840015001
  },
  {
    "city": "Ponchatoula",
    "city_ascii": "Ponchatoula",
    "lat": 30.4402,
    "lng": -90.4428,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 7369,
    "id": 1840015004
  },
  {
    "city": "Strathalbyn",
    "city_ascii": "Strathalbyn",
    "lat": -35.2667,
    "lng": 138.9,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "South Australia",
    "capital": "",
    "population": 6504,
    "id": 1036708974
  },
  {
    "city": "Oroville East",
    "city_ascii": "Oroville East",
    "lat": 39.4947,
    "lng": -121.4866,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 7368,
    "id": 1840074539
  },
  {
    "city": "Mapimí",
    "city_ascii": "Mapimi",
    "lat": 25.8331,
    "lng": -103.8478,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Durango",
    "capital": "minor",
    "population": 5623,
    "id": 1484156937
  },
  {
    "city": "Slivnitsa",
    "city_ascii": "Slivnitsa",
    "lat": 42.8522,
    "lng": 23.0375,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Sofia",
    "capital": "minor",
    "population": 7320,
    "id": 1100666506
  },
  {
    "city": "Poiares",
    "city_ascii": "Poiares",
    "lat": 40.2167,
    "lng": -8.25,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Coimbra",
    "capital": "minor",
    "population": 7281,
    "id": 1620186087
  },
  {
    "city": "Plumas Lake",
    "city_ascii": "Plumas Lake",
    "lat": 38.9924,
    "lng": -121.558,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 7367,
    "id": 1840024456
  },
  {
    "city": "Winsted",
    "city_ascii": "Winsted",
    "lat": 41.9268,
    "lng": -73.0675,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 7367,
    "id": 1840003262
  },
  {
    "city": "Skiatook",
    "city_ascii": "Skiatook",
    "lat": 36.3694,
    "lng": -95.9819,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oklahoma",
    "capital": "",
    "population": 7367,
    "id": 1840022597
  },
  {
    "city": "Santa Marta de Penaguião",
    "city_ascii": "Santa Marta de Penaguiao",
    "lat": 41.2,
    "lng": -7.7833,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Vila Real",
    "capital": "minor",
    "population": 7356,
    "id": 1620623907
  },
  {
    "city": "Humberston",
    "city_ascii": "Humberston",
    "lat": 53.5281,
    "lng": -0.0249,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "North East Lincolnshire",
    "capital": "",
    "population": 5634,
    "id": 1826603192
  },
  {
    "city": "Buena Vista",
    "city_ascii": "Buena Vista",
    "lat": 39.515,
    "lng": -74.8883,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 7366,
    "id": 1840081579
  },
  {
    "city": "New Hanover",
    "city_ascii": "New Hanover",
    "lat": 40.0159,
    "lng": -74.5741,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 7366,
    "id": 1840081619
  },
  {
    "city": "Bannockburn",
    "city_ascii": "Bannockburn",
    "lat": 56.09,
    "lng": -3.91,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Stirling",
    "capital": "",
    "population": 7352,
    "id": 1826144957
  },
  {
    "city": "Wolkersdorf im Weinviertel",
    "city_ascii": "Wolkersdorf im Weinviertel",
    "lat": 48.3667,
    "lng": 16.5167,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Niederösterreich",
    "capital": "",
    "population": 7201,
    "id": 1040214159
  },
  {
    "city": "Coeymans",
    "city_ascii": "Coeymans",
    "lat": 42.4936,
    "lng": -73.8835,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 7363,
    "id": 1840087324
  },
  {
    "city": "Pomona",
    "city_ascii": "Pomona",
    "lat": 39.4687,
    "lng": -74.5501,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 7362,
    "id": 1840005596
  },
  {
    "city": "Houston",
    "city_ascii": "Houston",
    "lat": 55.867,
    "lng": -4.5521,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Renfrewshire",
    "capital": "",
    "population": 6470,
    "id": 1826214371
  },
  {
    "city": "Charles City",
    "city_ascii": "Charles City",
    "lat": 43.0644,
    "lng": -92.6745,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Iowa",
    "capital": "",
    "population": 7359,
    "id": 1840000382
  },
  {
    "city": "Dettelbach",
    "city_ascii": "Dettelbach",
    "lat": 49.8017,
    "lng": 10.1618,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 7240,
    "id": 1276055883
  },
  {
    "city": "Ulmeni",
    "city_ascii": "Ulmeni",
    "lat": 47.4656,
    "lng": 23.3003,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Maramureş",
    "capital": "",
    "population": 7270,
    "id": 1642400445
  },
  {
    "city": "Turceni",
    "city_ascii": "Turceni",
    "lat": 44.6794,
    "lng": 23.3731,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Gorj",
    "capital": "",
    "population": 7269,
    "id": 1642921121
  },
  {
    "city": "Frankstown",
    "city_ascii": "Frankstown",
    "lat": 40.4453,
    "lng": -78.3185,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 7356,
    "id": 1840104471
  },
  {
    "city": "Yreka",
    "city_ascii": "Yreka",
    "lat": 41.7292,
    "lng": -122.6312,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 7356,
    "id": 1840021320
  },
  {
    "city": "Harjavalta",
    "city_ascii": "Harjavalta",
    "lat": 61.3139,
    "lng": 22.1417,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Satakunta",
    "capital": "minor",
    "population": 7296,
    "id": 1246726451
  },
  {
    "city": "Stanwood",
    "city_ascii": "Stanwood",
    "lat": 48.2449,
    "lng": -122.3425,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 7354,
    "id": 1840021079
  },
  {
    "city": "Augusta",
    "city_ascii": "Augusta",
    "lat": 44.7511,
    "lng": -75.6003,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 7353,
    "id": 1124000619
  },
  {
    "city": "Leola",
    "city_ascii": "Leola",
    "lat": 40.0915,
    "lng": -76.1891,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 7352,
    "id": 1840005492
  },
  {
    "city": "Canóvanas",
    "city_ascii": "Canovanas",
    "lat": 18.3782,
    "lng": -65.9056,
    "country": "Puerto Rico",
    "iso2": "PR",
    "iso3": "PRI",
    "admin_name": "Puerto Rico",
    "capital": "",
    "population": 7351,
    "id": 1630023717
  },
  {
    "city": "St. Joseph",
    "city_ascii": "St. Joseph",
    "lat": 45.5611,
    "lng": -94.3081,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 7351,
    "id": 1840008882
  },
  {
    "city": "Taga",
    "city_ascii": "Taga",
    "lat": 35.2219,
    "lng": 136.2922,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Shiga",
    "capital": "",
    "population": 7291,
    "id": 1392221443
  },
  {
    "city": "Abersychan",
    "city_ascii": "Abersychan",
    "lat": 51.7239,
    "lng": -3.0587,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Torfaen",
    "capital": "",
    "population": 7064,
    "id": 1826701602
  },
  {
    "city": "Sergeevka",
    "city_ascii": "Sergeevka",
    "lat": 53.88,
    "lng": 67.4158,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Soltüstik Qazaqstan",
    "capital": "",
    "population": 7344,
    "id": 1398880514
  },
  {
    "city": "Crumlin",
    "city_ascii": "Crumlin",
    "lat": 51.6798,
    "lng": -3.1368,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Caerphilly",
    "capital": "",
    "population": 5947,
    "id": 1826791090
  },
  {
    "city": "Hudsonville",
    "city_ascii": "Hudsonville",
    "lat": 42.8631,
    "lng": -85.8628,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 7348,
    "id": 1840002997
  },
  {
    "city": "Ebern",
    "city_ascii": "Ebern",
    "lat": 50.1,
    "lng": 10.7936,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saarland",
    "capital": "",
    "population": 7270,
    "id": 1276237272
  },
  {
    "city": "Spring Hill",
    "city_ascii": "Spring Hill",
    "lat": 38.7565,
    "lng": -94.82,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kansas",
    "capital": "",
    "population": 7345,
    "id": 1840001642
  },
  {
    "city": "Philadelphia",
    "city_ascii": "Philadelphia",
    "lat": 32.7761,
    "lng": -89.1221,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Mississippi",
    "capital": "",
    "population": 7345,
    "id": 1840014869
  },
  {
    "city": "Chalástra",
    "city_ascii": "Chalastra",
    "lat": 40.6267,
    "lng": 22.7322,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Kentrikí Makedonía",
    "capital": "",
    "population": 7270,
    "id": 1300506159
  },
  {
    "city": "Bellbrook",
    "city_ascii": "Bellbrook",
    "lat": 39.6384,
    "lng": -84.0863,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 7344,
    "id": 1840007313
  },
  {
    "city": "Northridge",
    "city_ascii": "Northridge",
    "lat": 39.9971,
    "lng": -83.777,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 7343,
    "id": 1840034124
  },
  {
    "city": "Zbąszyń",
    "city_ascii": "Zbaszyn",
    "lat": 52.2531,
    "lng": 15.9178,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Wielkopolskie",
    "capital": "",
    "population": 7264,
    "id": 1616410793
  },
  {
    "city": "Dayton",
    "city_ascii": "Dayton",
    "lat": 40.3815,
    "lng": -74.5137,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 7340,
    "id": 1840005414
  },
  {
    "city": "Hampden",
    "city_ascii": "Hampden",
    "lat": 44.735,
    "lng": -68.8896,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maine",
    "capital": "",
    "population": 7340,
    "id": 1840052845
  },
  {
    "city": "Seymour",
    "city_ascii": "Seymour",
    "lat": -37.03,
    "lng": 145.13,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Victoria",
    "capital": "",
    "population": 6327,
    "id": 1036919223
  },
  {
    "city": "Gibsonville",
    "city_ascii": "Gibsonville",
    "lat": 36.0991,
    "lng": -79.5417,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 7339,
    "id": 1840016137
  },
  {
    "city": "Parsberg",
    "city_ascii": "Parsberg",
    "lat": 49.15,
    "lng": 11.7167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 7213,
    "id": 1276835529
  },
  {
    "city": "Elzach",
    "city_ascii": "Elzach",
    "lat": 48.1747,
    "lng": 8.0717,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 7242,
    "id": 1276906927
  },
  {
    "city": "Dade City",
    "city_ascii": "Dade City",
    "lat": 28.3568,
    "lng": -82.1942,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 7338,
    "id": 1840015101
  },
  {
    "city": "Southgate",
    "city_ascii": "Southgate",
    "lat": 27.3082,
    "lng": -82.5096,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 7338,
    "id": 1840029078
  },
  {
    "city": "Fresia",
    "city_ascii": "Fresia",
    "lat": -41.1531,
    "lng": -73.4223,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Los Lagos",
    "capital": "",
    "population": 7328,
    "id": 1152963859
  },
  {
    "city": "Nortorf",
    "city_ascii": "Nortorf",
    "lat": 54.1667,
    "lng": 9.8667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Schleswig-Holstein",
    "capital": "",
    "population": 6804,
    "id": 1276829537
  },
  {
    "city": "Greenville",
    "city_ascii": "Greenville",
    "lat": 40.9981,
    "lng": -73.8194,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 7336,
    "id": 1840133415
  },
  {
    "city": "Puslinch",
    "city_ascii": "Puslinch",
    "lat": 43.45,
    "lng": -80.1667,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 7336,
    "id": 1124129947
  },
  {
    "city": "Scheßlitz",
    "city_ascii": "Schesslitz",
    "lat": 49.9767,
    "lng": 11.0339,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 7259,
    "id": 1276033353
  },
  {
    "city": "Pogoanele",
    "city_ascii": "Pogoanele",
    "lat": 44.9167,
    "lng": 27,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Buzău",
    "capital": "",
    "population": 7275,
    "id": 1642935531
  },
  {
    "city": "Lávrio",
    "city_ascii": "Lavrio",
    "lat": 37.7,
    "lng": 24.05,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Attikí",
    "capital": "minor",
    "population": 7078,
    "id": 1300335441
  },
  {
    "city": "Winooski",
    "city_ascii": "Winooski",
    "lat": 44.4951,
    "lng": -73.1843,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Vermont",
    "capital": "",
    "population": 7333,
    "id": 1840002312
  },
  {
    "city": "Middleborough Center",
    "city_ascii": "Middleborough Center",
    "lat": 41.8945,
    "lng": -70.926,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 7333,
    "id": 1840073513
  },
  {
    "city": "Rockville",
    "city_ascii": "Rockville",
    "lat": 41.8667,
    "lng": -72.4528,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 7332,
    "id": 1840003274
  },
  {
    "city": "Whaley Bridge",
    "city_ascii": "Whaley Bridge",
    "lat": 53.3304,
    "lng": -1.9838,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Derbyshire",
    "capital": "",
    "population": 6455,
    "id": 1826682902
  },
  {
    "city": "Kuklen",
    "city_ascii": "Kuklen",
    "lat": 42.0353,
    "lng": 24.7853,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Plovdiv",
    "capital": "",
    "population": 7331,
    "id": 1100127749
  },
  {
    "city": "East Pikeland",
    "city_ascii": "East Pikeland",
    "lat": 40.1326,
    "lng": -75.5631,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 7331,
    "id": 1840150921
  },
  {
    "city": "Wriezen",
    "city_ascii": "Wriezen",
    "lat": 52.7167,
    "lng": 14.1331,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Brandenburg",
    "capital": "",
    "population": 7254,
    "id": 1276665818
  },
  {
    "city": "Sturry",
    "city_ascii": "Sturry",
    "lat": 51.3036,
    "lng": 1.1211,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Kent",
    "capital": "",
    "population": 6820,
    "id": 1826679931
  },
  {
    "city": "Rabaul",
    "city_ascii": "Rabaul",
    "lat": -4.1981,
    "lng": 152.1681,
    "country": "Papua New Guinea",
    "iso2": "PG",
    "iso3": "PNG",
    "admin_name": "East New Britain",
    "capital": "",
    "population": 7024,
    "id": 1598973225
  },
  {
    "city": "East Marlborough",
    "city_ascii": "East Marlborough",
    "lat": 39.8798,
    "lng": -75.7231,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 7326,
    "id": 1840150928
  },
  {
    "city": "Homeland",
    "city_ascii": "Homeland",
    "lat": 33.7459,
    "lng": -117.1132,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 7326,
    "id": 1840017988
  },
  {
    "city": "Bryans Road",
    "city_ascii": "Bryans Road",
    "lat": 38.6144,
    "lng": -77.085,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 7325,
    "id": 1840006172
  },
  {
    "city": "Valley Center",
    "city_ascii": "Valley Center",
    "lat": 37.8333,
    "lng": -97.3645,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kansas",
    "capital": "",
    "population": 7325,
    "id": 1840010848
  },
  {
    "city": "New Sewickley",
    "city_ascii": "New Sewickley",
    "lat": 40.7215,
    "lng": -80.1979,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 7323,
    "id": 1840145553
  },
  {
    "city": "Vila Nova da Barquinha",
    "city_ascii": "Vila Nova da Barquinha",
    "lat": 39.45,
    "lng": -8.4333,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Santarém",
    "capital": "minor",
    "population": 7322,
    "id": 1620806338
  },
  {
    "city": "Ormond-by-the-Sea",
    "city_ascii": "Ormond-by-the-Sea",
    "lat": 29.3436,
    "lng": -81.0677,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 7322,
    "id": 1840014042
  },
  {
    "city": "Cannington",
    "city_ascii": "Cannington",
    "lat": -32.0167,
    "lng": 115.95,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Western Australia",
    "capital": "",
    "population": 5929,
    "id": 1036626234
  },
  {
    "city": "Umatilla",
    "city_ascii": "Umatilla",
    "lat": 45.912,
    "lng": -119.3145,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 7321,
    "id": 1840021195
  },
  {
    "city": "Cehu Silvaniei",
    "city_ascii": "Cehu Silvaniei",
    "lat": 47.412,
    "lng": 23.18,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Sălaj",
    "capital": "",
    "population": 7214,
    "id": 1642540391
  },
  {
    "city": "Strasburg",
    "city_ascii": "Strasburg",
    "lat": 38.9961,
    "lng": -78.3548,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 7320,
    "id": 1840006004
  },
  {
    "city": "Oak Grove",
    "city_ascii": "Oak Grove",
    "lat": 36.6686,
    "lng": -87.4216,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kentucky",
    "capital": "",
    "population": 7320,
    "id": 1840014397
  },
  {
    "city": "Wauchope",
    "city_ascii": "Wauchope",
    "lat": -31.465,
    "lng": 152.7344,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 6312,
    "id": 1036327296
  },
  {
    "city": "Vanrhynsdorp",
    "city_ascii": "Vanrhynsdorp",
    "lat": -31.6167,
    "lng": 18.7167,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "Western Cape",
    "capital": "",
    "population": 6272,
    "id": 1710187274
  },
  {
    "city": "Geneva",
    "city_ascii": "Geneva",
    "lat": 41.8006,
    "lng": -80.9461,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 7317,
    "id": 1840008145
  },
  {
    "city": "Immenhausen",
    "city_ascii": "Immenhausen",
    "lat": 51.4167,
    "lng": 9.5,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 7068,
    "id": 1276548462
  },
  {
    "city": "Gau-Algesheim",
    "city_ascii": "Gau-Algesheim",
    "lat": 49.95,
    "lng": 8.0167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Rhineland-Palatinate",
    "capital": "",
    "population": 6827,
    "id": 1276908578
  },
  {
    "city": "Whiteville",
    "city_ascii": "Whiteville",
    "lat": 34.3306,
    "lng": -78.7013,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 7315,
    "id": 1840015570
  },
  {
    "city": "South Lockport",
    "city_ascii": "South Lockport",
    "lat": 43.1377,
    "lng": -78.6864,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 7315,
    "id": 1840004271
  },
  {
    "city": "Derry Township",
    "city_ascii": "Derry Township",
    "lat": 40.633,
    "lng": -77.5367,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 7314,
    "id": 1840146278
  },
  {
    "city": "Franklin",
    "city_ascii": "Franklin",
    "lat": 37.3277,
    "lng": -120.5321,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 7314,
    "id": 1840075835
  },
  {
    "city": "Whitchurch",
    "city_ascii": "Whitchurch",
    "lat": 51.23,
    "lng": -1.34,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hampshire",
    "capital": "",
    "population": 7198,
    "id": 1826750764
  },
  {
    "city": "Freyung",
    "city_ascii": "Freyung",
    "lat": 48.8075,
    "lng": 13.5475,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "minor",
    "population": 7166,
    "id": 1276001126
  },
  {
    "city": "Vila Nova de Foz Côa",
    "city_ascii": "Vila Nova de Foz Coa",
    "lat": 41.0833,
    "lng": -7.1333,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Guarda",
    "capital": "minor",
    "population": 7312,
    "id": 1620521339
  },
  {
    "city": "Syanno",
    "city_ascii": "Syanno",
    "lat": 54.8,
    "lng": 29.7,
    "country": "Belarus",
    "iso2": "BY",
    "iso3": "BLR",
    "admin_name": "Vitsyebskaya Voblasts’",
    "capital": "",
    "population": 7300,
    "id": 1112544512
  },
  {
    "city": "Nailsworth",
    "city_ascii": "Nailsworth",
    "lat": 51.6954,
    "lng": -2.2234,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Gloucestershire",
    "capital": "",
    "population": 5794,
    "id": 1826978348
  },
  {
    "city": "Farnham Royal",
    "city_ascii": "Farnham Royal",
    "lat": 51.5386,
    "lng": -0.6164,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Buckinghamshire",
    "capital": "",
    "population": 5972,
    "id": 1826545852
  },
  {
    "city": "Karpenísi",
    "city_ascii": "Karpenisi",
    "lat": 38.9203,
    "lng": 21.7833,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Stereá Elláda",
    "capital": "minor",
    "population": 7183,
    "id": 1300819795
  },
  {
    "city": "Heringen",
    "city_ascii": "Heringen",
    "lat": 50.8872,
    "lng": 10.0056,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 7187,
    "id": 1276340154
  },
  {
    "city": "Nýřany",
    "city_ascii": "Nyrany",
    "lat": 49.7116,
    "lng": 13.2119,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Plzeňský Kraj",
    "capital": "",
    "population": 6996,
    "id": 1203749733
  },
  {
    "city": "Centerville",
    "city_ascii": "Centerville",
    "lat": 34.5256,
    "lng": -82.7161,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 7303,
    "id": 1840134659
  },
  {
    "city": "Faringdon",
    "city_ascii": "Faringdon",
    "lat": 51.657,
    "lng": -1.586,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Oxfordshire",
    "capital": "",
    "population": 7121,
    "id": 1826475167
  },
  {
    "city": "Belišće",
    "city_ascii": "Belisce",
    "lat": 45.6833,
    "lng": 18.4,
    "country": "Croatia",
    "iso2": "HR",
    "iso3": "HRV",
    "admin_name": "Osječko-Baranjska Županija",
    "capital": "minor",
    "population": 7197,
    "id": 1191824256
  },
  {
    "city": "Shenango",
    "city_ascii": "Shenango",
    "lat": 40.9493,
    "lng": -80.3059,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 7299,
    "id": 1840144542
  },
  {
    "city": "Woodlake",
    "city_ascii": "Woodlake",
    "lat": 37.4216,
    "lng": -77.6783,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 7299,
    "id": 1840026845
  },
  {
    "city": "St. Johnsbury",
    "city_ascii": "St. Johnsbury",
    "lat": 44.4552,
    "lng": -72.0115,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Vermont",
    "capital": "",
    "population": 7299,
    "id": 1840115464
  },
  {
    "city": "Rosales",
    "city_ascii": "Rosales",
    "lat": 28.2,
    "lng": -105.55,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Chihuahua",
    "capital": "minor",
    "population": 5570,
    "id": 1484613026
  },
  {
    "city": "Briton Ferry",
    "city_ascii": "Briton Ferry",
    "lat": 51.64,
    "lng": -3.83,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Neath Port Talbot",
    "capital": "",
    "population": 5911,
    "id": 1826781600
  },
  {
    "city": "Belmont",
    "city_ascii": "Belmont",
    "lat": 43.4685,
    "lng": -71.4757,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Hampshire",
    "capital": "",
    "population": 7298,
    "id": 1840054639
  },
  {
    "city": "Wellington",
    "city_ascii": "Wellington",
    "lat": 37.2622,
    "lng": -97.4282,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kansas",
    "capital": "",
    "population": 7297,
    "id": 1840001703
  },
  {
    "city": "Lakhdenpokhya",
    "city_ascii": "Lakhdenpokhya",
    "lat": 61.5167,
    "lng": 30.2,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kareliya",
    "capital": "",
    "population": 7294,
    "id": 1643105629
  },
  {
    "city": "Uddingston",
    "city_ascii": "Uddingston",
    "lat": 55.8183,
    "lng": -4.0827,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "South Lanarkshire",
    "capital": "",
    "population": 6710,
    "id": 1826718081
  },
  {
    "city": "Longboat Key",
    "city_ascii": "Longboat Key",
    "lat": 27.3926,
    "lng": -82.6341,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 7296,
    "id": 1840017250
  },
  {
    "city": "Meraux",
    "city_ascii": "Meraux",
    "lat": 29.9284,
    "lng": -89.9179,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 7296,
    "id": 1840014003
  },
  {
    "city": "Pompey",
    "city_ascii": "Pompey",
    "lat": 42.9229,
    "lng": -75.9803,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 7294,
    "id": 1840058413
  },
  {
    "city": "Connellsville",
    "city_ascii": "Connellsville",
    "lat": 40.0158,
    "lng": -79.5899,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 7290,
    "id": 1840001501
  },
  {
    "city": "Hardeeville",
    "city_ascii": "Hardeeville",
    "lat": 32.2951,
    "lng": -81.0318,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 7290,
    "id": 1840013808
  },
  {
    "city": "Torrington",
    "city_ascii": "Torrington",
    "lat": 42.0658,
    "lng": -104.1623,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wyoming",
    "capital": "",
    "population": 7289,
    "id": 1840021302
  },
  {
    "city": "Castro Verde",
    "city_ascii": "Castro Verde",
    "lat": 37.6976,
    "lng": -8.0819,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Beja",
    "capital": "minor",
    "population": 7276,
    "id": 1620300080
  },
  {
    "city": "Helensburgh",
    "city_ascii": "Helensburgh",
    "lat": -34.1833,
    "lng": 151,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 6383,
    "id": 1036359360
  },
  {
    "city": "New Cumberland",
    "city_ascii": "New Cumberland",
    "lat": 40.2298,
    "lng": -76.8763,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 7288,
    "id": 1840001391
  },
  {
    "city": "Altofts",
    "city_ascii": "Altofts",
    "lat": 53.697,
    "lng": -1.416,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Wakefield",
    "capital": "",
    "population": 6198,
    "id": 1826413238
  },
  {
    "city": "Kaufman",
    "city_ascii": "Kaufman",
    "lat": 32.5769,
    "lng": -96.316,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 7281,
    "id": 1840019450
  },
  {
    "city": "Huron",
    "city_ascii": "Huron",
    "lat": 36.2041,
    "lng": -120.0961,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 7281,
    "id": 1840018946
  },
  {
    "city": "Beauport",
    "city_ascii": "Beauport",
    "lat": 46.9667,
    "lng": -71.3,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 7281,
    "id": 1124715267
  },
  {
    "city": "Yass",
    "city_ascii": "Yass",
    "lat": -34.8203,
    "lng": 148.9136,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 6506,
    "id": 1036799517
  },
  {
    "city": "Buffalo Township",
    "city_ascii": "Buffalo Township",
    "lat": 40.706,
    "lng": -79.7387,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 7280,
    "id": 1840149058
  },
  {
    "city": "Mértola",
    "city_ascii": "Mertola",
    "lat": 37.6333,
    "lng": -7.65,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Beja",
    "capital": "minor",
    "population": 7274,
    "id": 1620362252
  },
  {
    "city": "Terras de Bouro",
    "city_ascii": "Terras de Bouro",
    "lat": 41.7167,
    "lng": -8.3,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Braga",
    "capital": "minor",
    "population": 7253,
    "id": 1620645381
  },
  {
    "city": "Moab",
    "city_ascii": "Moab",
    "lat": 38.5701,
    "lng": -109.5477,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Utah",
    "capital": "",
    "population": 7278,
    "id": 1840020227
  },
  {
    "city": "Głuszyca",
    "city_ascii": "Gluszyca",
    "lat": 50.6874,
    "lng": 16.3717,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Dolnośląskie",
    "capital": "",
    "population": 6855,
    "id": 1616839301
  },
  {
    "city": "Busti",
    "city_ascii": "Busti",
    "lat": 42.0459,
    "lng": -79.312,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 7277,
    "id": 1840057576
  },
  {
    "city": "Wildwood",
    "city_ascii": "Wildwood",
    "lat": 28.8014,
    "lng": -82.0058,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 7276,
    "id": 1840015956
  },
  {
    "city": "Wawayanda",
    "city_ascii": "Wawayanda",
    "lat": 41.3843,
    "lng": -74.4618,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 7276,
    "id": 1840088019
  },
  {
    "city": "Nuporanga",
    "city_ascii": "Nuporanga",
    "lat": -20.7308,
    "lng": -47.7306,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 7255,
    "id": 1076616625
  },
  {
    "city": "Pinehurst",
    "city_ascii": "Pinehurst",
    "lat": 42.5334,
    "lng": -71.234,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 7275,
    "id": 1840004521
  },
  {
    "city": "Cupcini",
    "city_ascii": "Cupcini",
    "lat": 48.1108,
    "lng": 27.3853,
    "country": "Moldova",
    "iso2": "MD",
    "iso3": "MDA",
    "admin_name": "Edineţ",
    "capital": "",
    "population": 7190,
    "id": 1498421512
  },
  {
    "city": "Cotgrave",
    "city_ascii": "Cotgrave",
    "lat": 52.914,
    "lng": -1.042,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Nottinghamshire",
    "capital": "",
    "population": 7203,
    "id": 1826053509
  },
  {
    "city": "Ralston",
    "city_ascii": "Ralston",
    "lat": 41.2005,
    "lng": -96.0354,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Nebraska",
    "capital": "",
    "population": 7273,
    "id": 1840009316
  },
  {
    "city": "Northport",
    "city_ascii": "Northport",
    "lat": 40.9036,
    "lng": -73.3446,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 7273,
    "id": 1840005126
  },
  {
    "city": "Tăuţii Măgheruş",
    "city_ascii": "Tautii Magherus",
    "lat": 47.6678,
    "lng": 23.4722,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Maramureş",
    "capital": "",
    "population": 7136,
    "id": 1642024653
  },
  {
    "city": "Lengenfeld",
    "city_ascii": "Lengenfeld",
    "lat": 50.5667,
    "lng": 12.3667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 7118,
    "id": 1276728827
  },
  {
    "city": "Wauseon",
    "city_ascii": "Wauseon",
    "lat": 41.5532,
    "lng": -84.141,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 7269,
    "id": 1840010217
  },
  {
    "city": "Delphos",
    "city_ascii": "Delphos",
    "lat": 40.8481,
    "lng": -84.3368,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 7267,
    "id": 1840007172
  },
  {
    "city": "Gatton",
    "city_ascii": "Gatton",
    "lat": -27.5611,
    "lng": 152.2755,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Queensland",
    "capital": "",
    "population": 7101,
    "id": 1036434682
  },
  {
    "city": "North Berwick",
    "city_ascii": "North Berwick",
    "lat": 56.058,
    "lng": -2.717,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "East Lothian",
    "capital": "",
    "population": 6605,
    "id": 1826981985
  },
  {
    "city": "St. Louis",
    "city_ascii": "St. Louis",
    "lat": 43.4082,
    "lng": -84.6118,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 7265,
    "id": 1840002878
  },
  {
    "city": "Saint-Rémi",
    "city_ascii": "Saint-Remi",
    "lat": 45.2667,
    "lng": -73.6167,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 7265,
    "id": 1124638080
  },
  {
    "city": "St. Marys",
    "city_ascii": "St. Marys",
    "lat": 43.2583,
    "lng": -81.1333,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 7265,
    "id": 1124438866
  },
  {
    "city": "Gerolzhofen",
    "city_ascii": "Gerolzhofen",
    "lat": 49.9,
    "lng": 10.35,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 6889,
    "id": 1276501121
  },
  {
    "city": "Lampasas",
    "city_ascii": "Lampasas",
    "lat": 31.064,
    "lng": -98.1824,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 7263,
    "id": 1840020846
  },
  {
    "city": "Muxton",
    "city_ascii": "Muxton",
    "lat": 52.72,
    "lng": -2.421,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Telford and Wrekin",
    "capital": "",
    "population": 6557,
    "id": 1826374919
  },
  {
    "city": "Pleasantville",
    "city_ascii": "Pleasantville",
    "lat": 41.1378,
    "lng": -73.7827,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 7260,
    "id": 1840004940
  },
  {
    "city": "Neptune Beach",
    "city_ascii": "Neptune Beach",
    "lat": 30.3165,
    "lng": -81.4118,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 7259,
    "id": 1840015030
  },
  {
    "city": "Annweiler am Trifels",
    "city_ascii": "Annweiler am Trifels",
    "lat": 49.2033,
    "lng": 7.9667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Rhineland-Palatinate",
    "capital": "",
    "population": 7081,
    "id": 1276459494
  },
  {
    "city": "Napili-Honokowai",
    "city_ascii": "Napili-Honokowai",
    "lat": 20.9767,
    "lng": -156.6646,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Hawaii",
    "capital": "",
    "population": 7258,
    "id": 1840075038
  },
  {
    "city": "Ash Vale",
    "city_ascii": "Ash Vale",
    "lat": 51.27,
    "lng": -0.72,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Surrey",
    "capital": "",
    "population": 5686,
    "id": 1826023346
  },
  {
    "city": "Shyryayeve",
    "city_ascii": "Shyryayeve",
    "lat": 47.3878,
    "lng": 30.1911,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Odes’ka Oblast’",
    "capital": "minor",
    "population": 7238,
    "id": 1804609043
  },
  {
    "city": "Lebanon",
    "city_ascii": "Lebanon",
    "lat": 41.6313,
    "lng": -72.2402,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 7256,
    "id": 1840035207
  },
  {
    "city": "Crystal City",
    "city_ascii": "Crystal City",
    "lat": 28.6909,
    "lng": -99.8257,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 7255,
    "id": 1840019688
  },
  {
    "city": "Soběslav",
    "city_ascii": "Sobeslav",
    "lat": 49.26,
    "lng": 14.7186,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Jihočeský Kraj",
    "capital": "",
    "population": 6907,
    "id": 1203332311
  },
  {
    "city": "Union Grove",
    "city_ascii": "Union Grove",
    "lat": 42.6863,
    "lng": -88.0495,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 7251,
    "id": 1840002487
  },
  {
    "city": "Shinmachi",
    "city_ascii": "Shinmachi",
    "lat": 35.1917,
    "lng": 140.3486,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Chiba",
    "capital": "",
    "population": 6970,
    "id": 1392474659
  },
  {
    "city": "Durham",
    "city_ascii": "Durham",
    "lat": 41.4622,
    "lng": -72.6829,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 7248,
    "id": 1840034511
  },
  {
    "city": "Shibayama",
    "city_ascii": "Shibayama",
    "lat": 35.6931,
    "lng": 140.4142,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Chiba",
    "capital": "",
    "population": 7082,
    "id": 1392037492
  },
  {
    "city": "Kapoeta",
    "city_ascii": "Kapoeta",
    "lat": 4.7721,
    "lng": 33.5902,
    "country": "South Sudan",
    "iso2": "SS",
    "iso3": "SSD",
    "admin_name": "Eastern Equatoria",
    "capital": "",
    "population": 7042,
    "id": 1728483282
  },
  {
    "city": "Kenwood",
    "city_ascii": "Kenwood",
    "lat": 39.2067,
    "lng": -84.3746,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 7244,
    "id": 1840034154
  },
  {
    "city": "Lakes of the Four Seasons",
    "city_ascii": "Lakes of the Four Seasons",
    "lat": 41.4074,
    "lng": -87.2203,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 7243,
    "id": 1840004833
  },
  {
    "city": "Elin Pelin",
    "city_ascii": "Elin Pelin",
    "lat": 42.6689,
    "lng": 23.6025,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Sofia",
    "capital": "",
    "population": 7189,
    "id": 1100619917
  },
  {
    "city": "Fordingbridge",
    "city_ascii": "Fordingbridge",
    "lat": 50.9275,
    "lng": -1.7901,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hampshire",
    "capital": "",
    "population": 5998,
    "id": 1826757876
  },
  {
    "city": "Richland",
    "city_ascii": "Richland",
    "lat": 32.2309,
    "lng": -90.1592,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Mississippi",
    "capital": "",
    "population": 7241,
    "id": 1840014889
  },
  {
    "city": "Belle Isle",
    "city_ascii": "Belle Isle",
    "lat": 28.4724,
    "lng": -81.3491,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 7240,
    "id": 1840014101
  },
  {
    "city": "Bedford",
    "city_ascii": "Bedford",
    "lat": 37.336,
    "lng": -79.5179,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 7240,
    "id": 1840001713
  },
  {
    "city": "Attica",
    "city_ascii": "Attica",
    "lat": 42.8257,
    "lng": -78.249,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 7239,
    "id": 1840004474
  },
  {
    "city": "Woodstock",
    "city_ascii": "Woodstock",
    "lat": 38.875,
    "lng": -78.516,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 7239,
    "id": 1840006006
  },
  {
    "city": "Franklin",
    "city_ascii": "Franklin",
    "lat": 35.1798,
    "lng": -83.3808,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 7238,
    "id": 1840016421
  },
  {
    "city": "Tranqueras",
    "city_ascii": "Tranqueras",
    "lat": -31.1833,
    "lng": -55.7667,
    "country": "Uruguay",
    "iso2": "UY",
    "iso3": "URY",
    "admin_name": "Rivera",
    "capital": "",
    "population": 7235,
    "id": 1858727708
  },
  {
    "city": "Chipping Ongar",
    "city_ascii": "Chipping Ongar",
    "lat": 51.703,
    "lng": 0.244,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Essex",
    "capital": "",
    "population": 6093,
    "id": 1826697064
  },
  {
    "city": "Emmitsburg",
    "city_ascii": "Emmitsburg",
    "lat": 39.7051,
    "lng": -77.3216,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 7235,
    "id": 1840005708
  },
  {
    "city": "Drayton Valley",
    "city_ascii": "Drayton Valley",
    "lat": 53.2222,
    "lng": -114.9769,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Alberta",
    "capital": "",
    "population": 7235,
    "id": 1124814220
  },
  {
    "city": "Willows",
    "city_ascii": "Willows",
    "lat": 39.5149,
    "lng": -122.1995,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 7234,
    "id": 1840021438
  },
  {
    "city": "Kerikeri",
    "city_ascii": "Kerikeri",
    "lat": -35.2244,
    "lng": 173.9514,
    "country": "New Zealand",
    "iso2": "NZ",
    "iso3": "NZL",
    "admin_name": "Northland",
    "capital": "",
    "population": 6960,
    "id": 1554530676
  },
  {
    "city": "East Hills",
    "city_ascii": "East Hills",
    "lat": 40.7958,
    "lng": -73.6292,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 7233,
    "id": 1840005342
  },
  {
    "city": "Oakdale",
    "city_ascii": "Oakdale",
    "lat": 40.7373,
    "lng": -73.1345,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 7231,
    "id": 1840005063
  },
  {
    "city": "Dingelstädt",
    "city_ascii": "Dingelstadt",
    "lat": 51.3156,
    "lng": 10.3194,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Thuringia",
    "capital": "",
    "population": 6893,
    "id": 1276847045
  },
  {
    "city": "La Feria",
    "city_ascii": "La Feria",
    "lat": 26.1544,
    "lng": -97.8255,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 7230,
    "id": 1840021041
  },
  {
    "city": "Darganata",
    "city_ascii": "Darganata",
    "lat": 40.4833,
    "lng": 62.1667,
    "country": "Turkmenistan",
    "iso2": "TM",
    "iso3": "TKM",
    "admin_name": "Lebap",
    "capital": "",
    "population": 7212,
    "id": 1795420063
  },
  {
    "city": "Kurri Kurri",
    "city_ascii": "Kurri Kurri",
    "lat": -32.8167,
    "lng": 151.4833,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 6044,
    "id": 1036219161
  },
  {
    "city": "Stavelot",
    "city_ascii": "Stavelot",
    "lat": 50.3947,
    "lng": 5.9297,
    "country": "Belgium",
    "iso2": "BE",
    "iso3": "BEL",
    "admin_name": "Wallonia",
    "capital": "",
    "population": 7145,
    "id": 1056769188
  },
  {
    "city": "Ponoka",
    "city_ascii": "Ponoka",
    "lat": 52.6833,
    "lng": -113.5667,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Alberta",
    "capital": "",
    "population": 7229,
    "id": 1124308190
  },
  {
    "city": "West Byfleet",
    "city_ascii": "West Byfleet",
    "lat": 51.338,
    "lng": -0.5033,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Surrey",
    "capital": "",
    "population": 5626,
    "id": 1826373302
  },
  {
    "city": "Krasnogvardeyskoye",
    "city_ascii": "Krasnogvardeyskoye",
    "lat": 50.65,
    "lng": 38.4,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Belgorodskaya Oblast’",
    "capital": "",
    "population": 7205,
    "id": 1643183314
  },
  {
    "city": "Maxatawny",
    "city_ascii": "Maxatawny",
    "lat": 40.526,
    "lng": -75.7444,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 7227,
    "id": 1840100292
  },
  {
    "city": "New Albany",
    "city_ascii": "New Albany",
    "lat": 34.4909,
    "lng": -89.0201,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Mississippi",
    "capital": "",
    "population": 7226,
    "id": 1840014678
  },
  {
    "city": "Amarkantak",
    "city_ascii": "Amarkantak",
    "lat": 22.6751,
    "lng": 81.7596,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Chhattīsgarh",
    "capital": "",
    "population": 7074,
    "id": 1356062517
  },
  {
    "city": "Tlalnepantla",
    "city_ascii": "Tlalnepantla",
    "lat": 19.01,
    "lng": -99,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Morelos",
    "capital": "minor",
    "population": 7166,
    "id": 1484624789
  },
  {
    "city": "Sedlčany",
    "city_ascii": "Sedlcany",
    "lat": 49.6606,
    "lng": 14.4267,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Středočeský Kraj",
    "capital": "",
    "population": 7029,
    "id": 1203219771
  },
  {
    "city": "Vodňany",
    "city_ascii": "Vodnany",
    "lat": 49.148,
    "lng": 14.1751,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Jihočeský Kraj",
    "capital": "",
    "population": 7028,
    "id": 1203434889
  },
  {
    "city": "Labrador City",
    "city_ascii": "Labrador City",
    "lat": 52.95,
    "lng": -66.9167,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Newfoundland and Labrador",
    "capital": "",
    "population": 7220,
    "id": 1124000773
  },
  {
    "city": "Chichimila",
    "city_ascii": "Chichimila",
    "lat": 20.6308,
    "lng": -88.2172,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Yucatán",
    "capital": "",
    "population": 5528,
    "id": 1484055870
  },
  {
    "city": "Ikaalinen",
    "city_ascii": "Ikaalinen",
    "lat": 61.7694,
    "lng": 23.0681,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Pirkanmaa",
    "capital": "minor",
    "population": 7207,
    "id": 1246676169
  },
  {
    "city": "Midland Park",
    "city_ascii": "Midland Park",
    "lat": 40.9952,
    "lng": -74.1411,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 7216,
    "id": 1840000905
  },
  {
    "city": "Landen",
    "city_ascii": "Landen",
    "lat": 39.3153,
    "lng": -84.277,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 7214,
    "id": 1840034146
  },
  {
    "city": "Beaumont",
    "city_ascii": "Beaumont",
    "lat": 50.2355,
    "lng": 4.2383,
    "country": "Belgium",
    "iso2": "BE",
    "iso3": "BEL",
    "admin_name": "Wallonia",
    "capital": "",
    "population": 7137,
    "id": 1056521734
  },
  {
    "city": "Rockport",
    "city_ascii": "Rockport",
    "lat": 42.6543,
    "lng": -70.6223,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 7212,
    "id": 1840053537
  },
  {
    "city": "West Mersea",
    "city_ascii": "West Mersea",
    "lat": 51.7784,
    "lng": 0.9168,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Essex",
    "capital": "",
    "population": 7183,
    "id": 1826632085
  },
  {
    "city": "Klichaw",
    "city_ascii": "Klichaw",
    "lat": 53.4828,
    "lng": 29.3411,
    "country": "Belarus",
    "iso2": "BY",
    "iso3": "BLR",
    "admin_name": "Mahilyowskaya Voblasts’",
    "capital": "minor",
    "population": 7200,
    "id": 1112059828
  },
  {
    "city": "Alta Sierra",
    "city_ascii": "Alta Sierra",
    "lat": 39.1237,
    "lng": -121.0523,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 7207,
    "id": 1840017553
  },
  {
    "city": "Klawer",
    "city_ascii": "Klawer",
    "lat": -31.7833,
    "lng": 18.6167,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "Western Cape",
    "capital": "",
    "population": 6234,
    "id": 1710292909
  },
  {
    "city": "Tarabaí",
    "city_ascii": "Tarabai",
    "lat": -22.3028,
    "lng": -51.5589,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 7168,
    "id": 1076230935
  },
  {
    "city": "Corbin",
    "city_ascii": "Corbin",
    "lat": 36.9321,
    "lng": -84.1004,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kentucky",
    "capital": "",
    "population": 7202,
    "id": 1840014405
  },
  {
    "city": "Ferlach",
    "city_ascii": "Ferlach",
    "lat": 46.5269,
    "lng": 14.3019,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Kärnten",
    "capital": "",
    "population": 7141,
    "id": 1040909637
  },
  {
    "city": "Marathónas",
    "city_ascii": "Marathonas",
    "lat": 38.155,
    "lng": 23.9636,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Attikí",
    "capital": "minor",
    "population": 7170,
    "id": 1300766363
  },
  {
    "city": "Milan",
    "city_ascii": "Milan",
    "lat": 35.9126,
    "lng": -88.7554,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 7201,
    "id": 1840014483
  },
  {
    "city": "Cooma",
    "city_ascii": "Cooma",
    "lat": -36.235,
    "lng": 149.1258,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 6681,
    "id": 1036070931
  },
  {
    "city": "Jackson",
    "city_ascii": "Jackson",
    "lat": 43.3237,
    "lng": -88.166,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 7200,
    "id": 1840002832
  },
  {
    "city": "Riverdale Park",
    "city_ascii": "Riverdale Park",
    "lat": 38.9642,
    "lng": -76.9266,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 7200,
    "id": 1840005989
  },
  {
    "city": "Donnacona",
    "city_ascii": "Donnacona",
    "lat": 46.6747,
    "lng": -71.7294,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 7200,
    "id": 1124002794
  },
  {
    "city": "Knoxville",
    "city_ascii": "Knoxville",
    "lat": 41.3188,
    "lng": -93.1024,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Iowa",
    "capital": "",
    "population": 7199,
    "id": 1840008229
  },
  {
    "city": "Hedon",
    "city_ascii": "Hedon",
    "lat": 53.7395,
    "lng": -0.1948,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "East Riding of Yorkshire",
    "capital": "",
    "population": 7100,
    "id": 1826368634
  },
  {
    "city": "Polýkastro",
    "city_ascii": "Polykastro",
    "lat": 40.9954,
    "lng": 22.5714,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Kentrikí Makedonía",
    "capital": "minor",
    "population": 7064,
    "id": 1300678089
  },
  {
    "city": "Skaneateles",
    "city_ascii": "Skaneateles",
    "lat": 42.9321,
    "lng": -76.4131,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 7198,
    "id": 1840004327
  },
  {
    "city": "Lander",
    "city_ascii": "Lander",
    "lat": 42.8313,
    "lng": -108.7599,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wyoming",
    "capital": "",
    "population": 7197,
    "id": 1840020027
  },
  {
    "city": "Hanga Roa",
    "city_ascii": "Hanga Roa",
    "lat": -27.1492,
    "lng": -109.4323,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Valparaíso",
    "capital": "minor",
    "population": 7163,
    "id": 1152267672
  },
  {
    "city": "Swanzey",
    "city_ascii": "Swanzey",
    "lat": 42.8625,
    "lng": -72.2961,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Hampshire",
    "capital": "",
    "population": 7195,
    "id": 1840055665
  },
  {
    "city": "Unionville",
    "city_ascii": "Unionville",
    "lat": 35.0716,
    "lng": -80.5173,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 7195,
    "id": 1840016457
  },
  {
    "city": "Washington Township",
    "city_ascii": "Washington Township",
    "lat": 40.5093,
    "lng": -79.6011,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 7195,
    "id": 1840145884
  },
  {
    "city": "Gunnison",
    "city_ascii": "Gunnison",
    "lat": 38.5455,
    "lng": -106.9225,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 7195,
    "id": 1840020235
  },
  {
    "city": "Greding",
    "city_ascii": "Greding",
    "lat": 49.0519,
    "lng": 11.3606,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 7126,
    "id": 1276984286
  },
  {
    "city": "Hornsby Bend",
    "city_ascii": "Hornsby Bend",
    "lat": 30.245,
    "lng": -97.5833,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 7194,
    "id": 1840027020
  },
  {
    "city": "Church Gresley",
    "city_ascii": "Church Gresley",
    "lat": 52.76,
    "lng": -1.566,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Derbyshire",
    "capital": "",
    "population": 6881,
    "id": 1826138074
  },
  {
    "city": "K’ajaran",
    "city_ascii": "K'ajaran",
    "lat": 39.1511,
    "lng": 46.16,
    "country": "Armenia",
    "iso2": "AM",
    "iso3": "ARM",
    "admin_name": "Syunik’",
    "capital": "",
    "population": 7163,
    "id": 1051149529
  },
  {
    "city": "Carhué",
    "city_ascii": "Carhue",
    "lat": -37.1829,
    "lng": -62.7333,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires",
    "capital": "minor",
    "population": 7190,
    "id": 1032595269
  },
  {
    "city": "Morrow",
    "city_ascii": "Morrow",
    "lat": 33.5816,
    "lng": -84.3392,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 7192,
    "id": 1840014814
  },
  {
    "city": "Interlaken",
    "city_ascii": "Interlaken",
    "lat": 36.9505,
    "lng": -121.7363,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 7191,
    "id": 1840017653
  },
  {
    "city": "Atlántida",
    "city_ascii": "Atlantida",
    "lat": -34.7701,
    "lng": -55.7613,
    "country": "Uruguay",
    "iso2": "UY",
    "iso3": "URY",
    "admin_name": "Canelones",
    "capital": "",
    "population": 5562,
    "id": 1858331514
  },
  {
    "city": "Southgate",
    "city_ascii": "Southgate",
    "lat": 44.1,
    "lng": -80.5833,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 7190,
    "id": 1124000656
  },
  {
    "city": "Bełżyce",
    "city_ascii": "Belzyce",
    "lat": 51.1833,
    "lng": 22.2667,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Lubelskie",
    "capital": "",
    "population": 7090,
    "id": 1616649550
  },
  {
    "city": "Downside",
    "city_ascii": "Downside",
    "lat": 51.3117,
    "lng": -0.4095,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Surrey",
    "capital": "",
    "population": 6564,
    "id": 1826524058
  },
  {
    "city": "Káto Achaḯa",
    "city_ascii": "Kato Achaia",
    "lat": 38.15,
    "lng": 21.55,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Dytikí Elláda",
    "capital": "minor",
    "population": 6618,
    "id": 1300115086
  },
  {
    "city": "Acton",
    "city_ascii": "Acton",
    "lat": 34.4956,
    "lng": -118.1857,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 7185,
    "id": 1840017917
  },
  {
    "city": "Waitara",
    "city_ascii": "Waitara",
    "lat": -38.9958,
    "lng": 174.2331,
    "country": "New Zealand",
    "iso2": "NZ",
    "iso3": "NZL",
    "admin_name": "Taranaki",
    "capital": "",
    "population": 7040,
    "id": 1554951741
  },
  {
    "city": "Sobótka",
    "city_ascii": "Sobotka",
    "lat": 50.8986,
    "lng": 16.7444,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Dolnośląskie",
    "capital": "",
    "population": 6965,
    "id": 1616973304
  },
  {
    "city": "Hobart",
    "city_ascii": "Hobart",
    "lat": 47.412,
    "lng": -121.996,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 7182,
    "id": 1840017344
  },
  {
    "city": "Enoch",
    "city_ascii": "Enoch",
    "lat": 37.767,
    "lng": -113.0449,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Utah",
    "capital": "",
    "population": 7180,
    "id": 1840020273
  },
  {
    "city": "Gerbstedt",
    "city_ascii": "Gerbstedt",
    "lat": 51.6331,
    "lng": 11.6167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony-Anhalt",
    "capital": "",
    "population": 7110,
    "id": 1276051215
  },
  {
    "city": "Nuriootpa",
    "city_ascii": "Nuriootpa",
    "lat": -34.4667,
    "lng": 138.9833,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "South Australia",
    "capital": "",
    "population": 6314,
    "id": 1036952732
  },
  {
    "city": "Rodalben",
    "city_ascii": "Rodalben",
    "lat": 49.2397,
    "lng": 7.6425,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Rhineland-Palatinate",
    "capital": "",
    "population": 6749,
    "id": 1276852589
  },
  {
    "city": "Cajvana",
    "city_ascii": "Cajvana",
    "lat": 47.7044,
    "lng": 25.9694,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Suceava",
    "capital": "",
    "population": 6901,
    "id": 1642941621
  },
  {
    "city": "Pacific",
    "city_ascii": "Pacific",
    "lat": 47.261,
    "lng": -122.2507,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 7178,
    "id": 1840019834
  },
  {
    "city": "McNab/Braeside",
    "city_ascii": "McNab/Braeside",
    "lat": 45.45,
    "lng": -76.5,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 7178,
    "id": 1124001458
  },
  {
    "city": "Canfield",
    "city_ascii": "Canfield",
    "lat": 41.0315,
    "lng": -80.7672,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 7176,
    "id": 1840007163
  },
  {
    "city": "Independence",
    "city_ascii": "Independence",
    "lat": 41.378,
    "lng": -81.6385,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 7175,
    "id": 1840000607
  },
  {
    "city": "Elsa",
    "city_ascii": "Elsa",
    "lat": 26.2978,
    "lng": -97.9936,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 7174,
    "id": 1840021021
  },
  {
    "city": "Mecca",
    "city_ascii": "Mecca",
    "lat": 33.5767,
    "lng": -116.0645,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 7174,
    "id": 1840019285
  },
  {
    "city": "Sioux Center",
    "city_ascii": "Sioux Center",
    "lat": 43.0748,
    "lng": -96.1707,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Iowa",
    "capital": "",
    "population": 7174,
    "id": 1840009043
  },
  {
    "city": "Ettersburg",
    "city_ascii": "Ettersburg",
    "lat": 51.0611,
    "lng": 11.2442,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Thuringia",
    "capital": "",
    "population": 7097,
    "id": 1276324443
  },
  {
    "city": "Haapavesi",
    "city_ascii": "Haapavesi",
    "lat": 64.1375,
    "lng": 25.3667,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Pohjois-Pohjanmaa",
    "capital": "minor",
    "population": 7167,
    "id": 1246025135
  },
  {
    "city": "Pembroke",
    "city_ascii": "Pembroke",
    "lat": 43.1803,
    "lng": -71.45,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Hampshire",
    "capital": "",
    "population": 7172,
    "id": 1840055381
  },
  {
    "city": "Commerce",
    "city_ascii": "Commerce",
    "lat": 34.2133,
    "lng": -83.473,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 7172,
    "id": 1840014720
  },
  {
    "city": "Murgeni",
    "city_ascii": "Murgeni",
    "lat": 46.2,
    "lng": 28.0167,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Vaslui",
    "capital": "",
    "population": 7119,
    "id": 1642852423
  },
  {
    "city": "General Toshevo",
    "city_ascii": "General Toshevo",
    "lat": 43.7006,
    "lng": 28.0367,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Dobrich",
    "capital": "minor",
    "population": 7157,
    "id": 1100248357
  },
  {
    "city": "Austell",
    "city_ascii": "Austell",
    "lat": 33.82,
    "lng": -84.645,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 7170,
    "id": 1840013689
  },
  {
    "city": "Earl",
    "city_ascii": "Earl",
    "lat": 40.1142,
    "lng": -76.0959,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 7169,
    "id": 1840150411
  },
  {
    "city": "Esher",
    "city_ascii": "Esher",
    "lat": 51.3691,
    "lng": -0.365,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Surrey",
    "capital": "",
    "population": 6743,
    "id": 1826230641
  },
  {
    "city": "West Bridgewater",
    "city_ascii": "West Bridgewater",
    "lat": 42.0214,
    "lng": -71.0267,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 7165,
    "id": 1840053579
  },
  {
    "city": "Conewago Township",
    "city_ascii": "Conewago Township",
    "lat": 39.7977,
    "lng": -77.0239,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 7164,
    "id": 1840143124
  },
  {
    "city": "Hartha",
    "city_ascii": "Hartha",
    "lat": 51.0978,
    "lng": 12.9772,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 7034,
    "id": 1276001104
  },
  {
    "city": "Cërrik",
    "city_ascii": "Cerrik",
    "lat": 41.0333,
    "lng": 19.9833,
    "country": "Albania",
    "iso2": "AL",
    "iso3": "ALB",
    "admin_name": "Elbasan",
    "capital": "",
    "population": 6695,
    "id": 1008302673
  },
  {
    "city": "Ixtapan del Oro",
    "city_ascii": "Ixtapan del Oro",
    "lat": 19.2614,
    "lng": -100.2628,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "México",
    "capital": "minor",
    "population": 6349,
    "id": 1484137881
  },
  {
    "city": "Parkville",
    "city_ascii": "Parkville",
    "lat": 39.7859,
    "lng": -76.9686,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 7162,
    "id": 1840005507
  },
  {
    "city": "Parkville",
    "city_ascii": "Parkville",
    "lat": 39.2004,
    "lng": -94.7222,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 7162,
    "id": 1840009590
  },
  {
    "city": "Macdonald",
    "city_ascii": "Macdonald",
    "lat": 49.6725,
    "lng": -97.4472,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Manitoba",
    "capital": "",
    "population": 7162,
    "id": 1124000633
  },
  {
    "city": "Schwarzenbach an der Saale",
    "city_ascii": "Schwarzenbach an der Saale",
    "lat": 50.2208,
    "lng": 11.9333,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 7042,
    "id": 1276286000
  },
  {
    "city": "Hillsborough",
    "city_ascii": "Hillsborough",
    "lat": 36.0681,
    "lng": -79.0994,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 7161,
    "id": 1840016151
  },
  {
    "city": "Granville",
    "city_ascii": "Granville",
    "lat": -33.8578,
    "lng": 151.0106,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 5500,
    "id": 1036825743
  },
  {
    "city": "Utuado",
    "city_ascii": "Utuado",
    "lat": 18.2697,
    "lng": -66.7049,
    "country": "Puerto Rico",
    "iso2": "PR",
    "iso3": "PRI",
    "admin_name": "Puerto Rico",
    "capital": "",
    "population": 7160,
    "id": 1630023623
  },
  {
    "city": "Green Brook",
    "city_ascii": "Green Brook",
    "lat": 40.6038,
    "lng": -74.4825,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 7158,
    "id": 1840081702
  },
  {
    "city": "Gonzales",
    "city_ascii": "Gonzales",
    "lat": 29.5126,
    "lng": -97.4472,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 7158,
    "id": 1840020953
  },
  {
    "city": "Outokumpu",
    "city_ascii": "Outokumpu",
    "lat": 62.725,
    "lng": 29.0167,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Pohjois-Karjala",
    "capital": "minor",
    "population": 7139,
    "id": 1246024227
  },
  {
    "city": "Teshikaga",
    "city_ascii": "Teshikaga",
    "lat": 43.4853,
    "lng": 144.4592,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hokkaidō",
    "capital": "",
    "population": 7145,
    "id": 1392642840
  },
  {
    "city": "Calverton",
    "city_ascii": "Calverton",
    "lat": 40.9163,
    "lng": -72.7645,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 7154,
    "id": 1840005024
  },
  {
    "city": "Wynne",
    "city_ascii": "Wynne",
    "lat": 35.2322,
    "lng": -90.7894,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arkansas",
    "capital": "",
    "population": 7152,
    "id": 1840015443
  },
  {
    "city": "Ogunimachi",
    "city_ascii": "Ogunimachi",
    "lat": 38.0614,
    "lng": 139.7433,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Yamagata",
    "capital": "",
    "population": 7142,
    "id": 1392624469
  },
  {
    "city": "Tyrone",
    "city_ascii": "Tyrone",
    "lat": 40.6764,
    "lng": -78.246,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 7151,
    "id": 1840001087
  },
  {
    "city": "Lawrenceville",
    "city_ascii": "Lawrenceville",
    "lat": 38.7264,
    "lng": -87.6873,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 7150,
    "id": 1840008606
  },
  {
    "city": "Fundulea",
    "city_ascii": "Fundulea",
    "lat": 44.4528,
    "lng": 26.5153,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Călăraşi",
    "capital": "",
    "population": 6851,
    "id": 1642207771
  },
  {
    "city": "Murston",
    "city_ascii": "Murston",
    "lat": 51.341,
    "lng": 0.7524,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Kent",
    "capital": "",
    "population": 5300,
    "id": 1826235840
  },
  {
    "city": "Yardville",
    "city_ascii": "Yardville",
    "lat": 40.1849,
    "lng": -74.6603,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 7148,
    "id": 1840026523
  },
  {
    "city": "Hoegaarden",
    "city_ascii": "Hoegaarden",
    "lat": 50.7833,
    "lng": 4.9,
    "country": "Belgium",
    "iso2": "BE",
    "iso3": "BEL",
    "admin_name": "Flanders",
    "capital": "",
    "population": 6942,
    "id": 1056041264
  },
  {
    "city": "Baie-Saint-Paul",
    "city_ascii": "Baie-Saint-Paul",
    "lat": 47.45,
    "lng": -70.5,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 7146,
    "id": 1124415452
  },
  {
    "city": "Cottage Grove",
    "city_ascii": "Cottage Grove",
    "lat": 43.091,
    "lng": -89.2034,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 7143,
    "id": 1840002910
  },
  {
    "city": "De Soto",
    "city_ascii": "De Soto",
    "lat": 38.141,
    "lng": -90.5609,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 7141,
    "id": 1840007509
  },
  {
    "city": "Palmyra",
    "city_ascii": "Palmyra",
    "lat": 40.0025,
    "lng": -75.036,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 7140,
    "id": 1840001479
  },
  {
    "city": "Gilford",
    "city_ascii": "Gilford",
    "lat": 43.5581,
    "lng": -71.385,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Hampshire",
    "capital": "",
    "population": 7139,
    "id": 1840054887
  },
  {
    "city": "Nappanee",
    "city_ascii": "Nappanee",
    "lat": 41.4452,
    "lng": -85.9941,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 7139,
    "id": 1840008189
  },
  {
    "city": "Portage Lakes",
    "city_ascii": "Portage Lakes",
    "lat": 41.0034,
    "lng": -81.5347,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 7139,
    "id": 1840004956
  },
  {
    "city": "Merritt",
    "city_ascii": "Merritt",
    "lat": 50.1128,
    "lng": -120.7897,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "British Columbia",
    "capital": "",
    "population": 7139,
    "id": 1124550302
  },
  {
    "city": "Fair Plain",
    "city_ascii": "Fair Plain",
    "lat": 42.0823,
    "lng": -86.4515,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 7138,
    "id": 1840004697
  },
  {
    "city": "Atherton",
    "city_ascii": "Atherton",
    "lat": 37.4539,
    "lng": -122.2032,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 7137,
    "id": 1840020308
  },
  {
    "city": "Bluewater",
    "city_ascii": "Bluewater",
    "lat": 43.45,
    "lng": -81.6,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 7136,
    "id": 1124000066
  },
  {
    "city": "Nibley",
    "city_ascii": "Nibley",
    "lat": 41.6725,
    "lng": -111.8454,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Utah",
    "capital": "",
    "population": 7135,
    "id": 1840020113
  },
  {
    "city": "Dalcahue",
    "city_ascii": "Dalcahue",
    "lat": -42.3796,
    "lng": -73.6473,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Los Lagos",
    "capital": "",
    "population": 7120,
    "id": 1152458056
  },
  {
    "city": "Walled Lake",
    "city_ascii": "Walled Lake",
    "lat": 42.538,
    "lng": -83.4786,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 7134,
    "id": 1840002435
  },
  {
    "city": "Nový Bydžov",
    "city_ascii": "Novy Bydzov",
    "lat": 50.2416,
    "lng": 15.4909,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Královéhradecký Kraj",
    "capital": "",
    "population": 6935,
    "id": 1203908405
  },
  {
    "city": "Galliano",
    "city_ascii": "Galliano",
    "lat": 29.447,
    "lng": -90.3096,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 7131,
    "id": 1840014023
  },
  {
    "city": "East Zorra-Tavistock",
    "city_ascii": "East Zorra-Tavistock",
    "lat": 43.2333,
    "lng": -80.7833,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 7129,
    "id": 1124000189
  },
  {
    "city": "Pinhoe",
    "city_ascii": "Pinhoe",
    "lat": 50.7407,
    "lng": -3.4672,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Devon",
    "capital": "",
    "population": 6454,
    "id": 1826753176
  },
  {
    "city": "Freeland",
    "city_ascii": "Freeland",
    "lat": 43.5198,
    "lng": -84.1124,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 7125,
    "id": 1840004224
  },
  {
    "city": "Bednodem’yanovsk",
    "city_ascii": "Bednodem'yanovsk",
    "lat": 53.9333,
    "lng": 43.1833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Penzenskaya Oblast’",
    "capital": "",
    "population": 7106,
    "id": 1643965471
  },
  {
    "city": "Létavértes",
    "city_ascii": "Letavertes",
    "lat": 47.3833,
    "lng": 21.9,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Hajdú-Bihar",
    "capital": "",
    "population": 7061,
    "id": 1348145727
  },
  {
    "city": "Live Oak",
    "city_ascii": "Live Oak",
    "lat": 30.2956,
    "lng": -82.9847,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 7118,
    "id": 1840015929
  },
  {
    "city": "Bonne Terre",
    "city_ascii": "Bonne Terre",
    "lat": 37.9209,
    "lng": -90.5426,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 7118,
    "id": 1840007551
  },
  {
    "city": "Săveni",
    "city_ascii": "Saveni",
    "lat": 47.9533,
    "lng": 26.8589,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Botoşani",
    "capital": "",
    "population": 6999,
    "id": 1642101791
  },
  {
    "city": "Brook Highland",
    "city_ascii": "Brook Highland",
    "lat": 33.4359,
    "lng": -86.6849,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 7116,
    "id": 1840027551
  },
  {
    "city": "Fort Riley",
    "city_ascii": "Fort Riley",
    "lat": 39.1111,
    "lng": -96.8138,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kansas",
    "capital": "",
    "population": 7116,
    "id": 1840073757
  },
  {
    "city": "Givet",
    "city_ascii": "Givet",
    "lat": 50.1381,
    "lng": 4.8242,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Grand Est",
    "capital": "",
    "population": 6749,
    "id": 1250082748
  },
  {
    "city": "Myadzyel",
    "city_ascii": "Myadzyel",
    "lat": 54.8756,
    "lng": 26.9386,
    "country": "Belarus",
    "iso2": "BY",
    "iso3": "BLR",
    "admin_name": "Minskaya Voblasts’",
    "capital": "minor",
    "population": 7100,
    "id": 1112205603
  },
  {
    "city": "Calipatria",
    "city_ascii": "Calipatria",
    "lat": 33.1493,
    "lng": -115.5056,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 7114,
    "id": 1840019380
  },
  {
    "city": "Novosokolniki",
    "city_ascii": "Novosokolniki",
    "lat": 56.3333,
    "lng": 30.15,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Pskovskaya Oblast’",
    "capital": "",
    "population": 7105,
    "id": 1643428907
  },
  {
    "city": "Colonia Venustiano Carranza",
    "city_ascii": "Colonia Venustiano Carranza",
    "lat": 32.2275,
    "lng": -115.1703,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Baja California",
    "capital": "",
    "population": 6098,
    "id": 1484353279
  },
  {
    "city": "Dubrowna",
    "city_ascii": "Dubrowna",
    "lat": 54.5667,
    "lng": 30.6833,
    "country": "Belarus",
    "iso2": "BY",
    "iso3": "BLR",
    "admin_name": "Vitsyebskaya Voblasts’",
    "capital": "minor",
    "population": 7100,
    "id": 1112348244
  },
  {
    "city": "Ebermannstadt",
    "city_ascii": "Ebermannstadt",
    "lat": 49.7806,
    "lng": 11.1848,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 6971,
    "id": 1276921042
  },
  {
    "city": "New Romney",
    "city_ascii": "New Romney",
    "lat": 50.985,
    "lng": 0.941,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Kent",
    "capital": "",
    "population": 6996,
    "id": 1826600315
  },
  {
    "city": "Southam",
    "city_ascii": "Southam",
    "lat": 52.252,
    "lng": -1.39,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Warwickshire",
    "capital": "",
    "population": 6567,
    "id": 1826061405
  },
  {
    "city": "Pinson",
    "city_ascii": "Pinson",
    "lat": 33.7057,
    "lng": -86.6674,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 7106,
    "id": 1840022941
  },
  {
    "city": "Stoneham-et-Tewkesbury",
    "city_ascii": "Stoneham-et-Tewkesbury",
    "lat": 47.1667,
    "lng": -71.4333,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 7106,
    "id": 1124000439
  },
  {
    "city": "Rishton",
    "city_ascii": "Rishton",
    "lat": 53.7701,
    "lng": -2.4187,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Lancashire",
    "capital": "",
    "population": 6625,
    "id": 1826974953
  },
  {
    "city": "Gryfów Śląski",
    "city_ascii": "Gryfow Slaski",
    "lat": 51.0308,
    "lng": 15.4202,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Dolnośląskie",
    "capital": "",
    "population": 6943,
    "id": 1616607082
  },
  {
    "city": "Selma",
    "city_ascii": "Selma",
    "lat": 35.5436,
    "lng": -78.2954,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 7101,
    "id": 1840017794
  },
  {
    "city": "Kupiansk",
    "city_ascii": "Kupiansk",
    "lat": 49.7064,
    "lng": 37.6167,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Kharkivs’ka Oblast’",
    "capital": "minor",
    "population": 6893,
    "id": 1804300255
  },
  {
    "city": "Marble Falls",
    "city_ascii": "Marble Falls",
    "lat": 30.5649,
    "lng": -98.2768,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 7099,
    "id": 1840020870
  },
  {
    "city": "Northgate",
    "city_ascii": "Northgate",
    "lat": 39.2531,
    "lng": -84.5943,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 7099,
    "id": 1840034390
  },
  {
    "city": "Okhansk",
    "city_ascii": "Okhansk",
    "lat": 57.7167,
    "lng": 55.3833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Permskiy Kray",
    "capital": "minor",
    "population": 7087,
    "id": 1643550283
  },
  {
    "city": "Bedminster",
    "city_ascii": "Bedminster",
    "lat": 40.4223,
    "lng": -75.1938,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 7098,
    "id": 1840098454
  },
  {
    "city": "Stow",
    "city_ascii": "Stow",
    "lat": 42.4298,
    "lng": -71.5115,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 7098,
    "id": 1840053488
  },
  {
    "city": "Bridge of Allan",
    "city_ascii": "Bridge of Allan",
    "lat": 56.155,
    "lng": -3.942,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Stirling",
    "capital": "",
    "population": 6762,
    "id": 1826777809
  },
  {
    "city": "Aya",
    "city_ascii": "Aya",
    "lat": 31.9992,
    "lng": 131.2531,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Miyazaki",
    "capital": "",
    "population": 7023,
    "id": 1392387777
  },
  {
    "city": "Asbestos",
    "city_ascii": "Asbestos",
    "lat": 45.7667,
    "lng": -71.9333,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 7096,
    "id": 1124583779
  },
  {
    "city": "Juana Díaz",
    "city_ascii": "Juana Diaz",
    "lat": 18.0532,
    "lng": -66.5047,
    "country": "Puerto Rico",
    "iso2": "PR",
    "iso3": "PRI",
    "admin_name": "Puerto Rico",
    "capital": "",
    "population": 7095,
    "id": 1630023583
  },
  {
    "city": "Lake Lorraine",
    "city_ascii": "Lake Lorraine",
    "lat": 30.4407,
    "lng": -86.5657,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 7095,
    "id": 1840029048
  },
  {
    "city": "Lavrinhas",
    "city_ascii": "Lavrinhas",
    "lat": -22.5708,
    "lng": -44.9022,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 7052,
    "id": 1076708756
  },
  {
    "city": "Fort Myers Beach",
    "city_ascii": "Fort Myers Beach",
    "lat": 26.4324,
    "lng": -81.9168,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 7094,
    "id": 1840017269
  },
  {
    "city": "Oberndorf bei Salzburg",
    "city_ascii": "Oberndorf bei Salzburg",
    "lat": 47.9417,
    "lng": 12.9417,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Salzburg",
    "capital": "",
    "population": 5815,
    "id": 1040531486
  },
  {
    "city": "Dunbar",
    "city_ascii": "Dunbar",
    "lat": 38.3688,
    "lng": -81.7345,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "West Virginia",
    "capital": "",
    "population": 7093,
    "id": 1840006197
  },
  {
    "city": "Neukirchen",
    "city_ascii": "Neukirchen",
    "lat": 50.8667,
    "lng": 9.3333,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 6986,
    "id": 1276553451
  },
  {
    "city": "Stadtroda",
    "city_ascii": "Stadtroda",
    "lat": 50.85,
    "lng": 11.7333,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Thuringia",
    "capital": "",
    "population": 6692,
    "id": 1276290371
  },
  {
    "city": "Ladera Heights",
    "city_ascii": "Ladera Heights",
    "lat": 33.9972,
    "lng": -118.374,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 7090,
    "id": 1840028381
  },
  {
    "city": "Buziaş",
    "city_ascii": "Buzias",
    "lat": 45.65,
    "lng": 21.6,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Timiş",
    "capital": "",
    "population": 7023,
    "id": 1642000046
  },
  {
    "city": "Genadendal",
    "city_ascii": "Genadendal",
    "lat": -34.0333,
    "lng": 19.55,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "Western Cape",
    "capital": "",
    "population": 5663,
    "id": 1710684087
  },
  {
    "city": "Osceola",
    "city_ascii": "Osceola",
    "lat": 35.6942,
    "lng": -89.9934,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arkansas",
    "capital": "",
    "population": 7085,
    "id": 1840014501
  },
  {
    "city": "Syracuse",
    "city_ascii": "Syracuse",
    "lat": 41.4226,
    "lng": -85.7492,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 7085,
    "id": 1840010280
  },
  {
    "city": "Neustadt-Glewe",
    "city_ascii": "Neustadt-Glewe",
    "lat": 53.3794,
    "lng": 11.5883,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Mecklenburg-Western Pomerania",
    "capital": "",
    "population": 7009,
    "id": 1276906064
  },
  {
    "city": "Andreapol",
    "city_ascii": "Andreapol",
    "lat": 56.65,
    "lng": 32.2667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Tverskaya Oblast’",
    "capital": "",
    "population": 7079,
    "id": 1643244449
  },
  {
    "city": "Marshfield",
    "city_ascii": "Marshfield",
    "lat": 51.5439,
    "lng": -3.0469,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Newport",
    "capital": "",
    "population": 6270,
    "id": 1826384578
  },
  {
    "city": "Clarkson",
    "city_ascii": "Clarkson",
    "lat": 43.2533,
    "lng": -77.9228,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 7079,
    "id": 1840057987
  },
  {
    "city": "Flatwoods",
    "city_ascii": "Flatwoods",
    "lat": 38.521,
    "lng": -82.7195,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kentucky",
    "capital": "",
    "population": 7079,
    "id": 1840013182
  },
  {
    "city": "Sharhorod",
    "city_ascii": "Sharhorod",
    "lat": 48.75,
    "lng": 28.0833,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Vinnyts’ka Oblast’",
    "capital": "minor",
    "population": 7029,
    "id": 1804714771
  },
  {
    "city": "Segarcea",
    "city_ascii": "Segarcea",
    "lat": 44.0919,
    "lng": 23.7334,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Dolj",
    "capital": "",
    "population": 7019,
    "id": 1642618558
  },
  {
    "city": "Quiévrain",
    "city_ascii": "Quievrain",
    "lat": 50.4049,
    "lng": 3.6829,
    "country": "Belgium",
    "iso2": "BE",
    "iso3": "BEL",
    "admin_name": "Wallonia",
    "capital": "",
    "population": 6759,
    "id": 1056374664
  },
  {
    "city": "Roosevelt",
    "city_ascii": "Roosevelt",
    "lat": 40.2924,
    "lng": -110.0093,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Utah",
    "capital": "",
    "population": 7075,
    "id": 1840020162
  },
  {
    "city": "Milton-Freewater",
    "city_ascii": "Milton-Freewater",
    "lat": 45.9348,
    "lng": -118.3913,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 7074,
    "id": 1840019916
  },
  {
    "city": "Buttstädt",
    "city_ascii": "Buttstadt",
    "lat": 51.1167,
    "lng": 11.4167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Thuringia",
    "capital": "",
    "population": 6707,
    "id": 1276689847
  },
  {
    "city": "Dietenheim",
    "city_ascii": "Dietenheim",
    "lat": 48.2119,
    "lng": 10.0733,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 6715,
    "id": 1276572579
  },
  {
    "city": "Ben Lomond",
    "city_ascii": "Ben Lomond",
    "lat": 37.0782,
    "lng": -122.0882,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 7071,
    "id": 1840017649
  },
  {
    "city": "Marne",
    "city_ascii": "Marne",
    "lat": 53.95,
    "lng": 9,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Schleswig-Holstein",
    "capital": "",
    "population": 5858,
    "id": 1276479909
  },
  {
    "city": "Fort Pierce North",
    "city_ascii": "Fort Pierce North",
    "lat": 27.4736,
    "lng": -80.3594,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 7070,
    "id": 1840073834
  },
  {
    "city": "Wellsville",
    "city_ascii": "Wellsville",
    "lat": 42.1206,
    "lng": -77.9219,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 7070,
    "id": 1840004607
  },
  {
    "city": "Nebraska City",
    "city_ascii": "Nebraska City",
    "lat": 40.6762,
    "lng": -95.8613,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Nebraska",
    "capital": "",
    "population": 7069,
    "id": 1840008324
  },
  {
    "city": "Huron-Kinloss",
    "city_ascii": "Huron-Kinloss",
    "lat": 44.05,
    "lng": -81.5333,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 7069,
    "id": 1124000614
  },
  {
    "city": "Sausalito",
    "city_ascii": "Sausalito",
    "lat": 37.858,
    "lng": -122.4932,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 7068,
    "id": 1840021514
  },
  {
    "city": "Batesville",
    "city_ascii": "Batesville",
    "lat": 34.3147,
    "lng": -89.9249,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Mississippi",
    "capital": "",
    "population": 7068,
    "id": 1840013607
  },
  {
    "city": "Delaware Township",
    "city_ascii": "Delaware Township",
    "lat": 41.2372,
    "lng": -74.9281,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 7066,
    "id": 1840146120
  },
  {
    "city": "Chādegān",
    "city_ascii": "Chadegan",
    "lat": 32.7683,
    "lng": 50.6286,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Eşfahān",
    "capital": "minor",
    "population": 7037,
    "id": 1364090165
  },
  {
    "city": "Highland Heights",
    "city_ascii": "Highland Heights",
    "lat": 39.0355,
    "lng": -84.4567,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kentucky",
    "capital": "",
    "population": 7065,
    "id": 1840014262
  },
  {
    "city": "Barnesville",
    "city_ascii": "Barnesville",
    "lat": 33.0509,
    "lng": -84.1527,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 7064,
    "id": 1840013783
  },
  {
    "city": "Kravaře",
    "city_ascii": "Kravare",
    "lat": 49.932,
    "lng": 18.0048,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Moravskoslezský Kraj",
    "capital": "",
    "population": 6717,
    "id": 1203621648
  },
  {
    "city": "Edenkoben",
    "city_ascii": "Edenkoben",
    "lat": 49.2839,
    "lng": 8.1272,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Rhineland-Palatinate",
    "capital": "",
    "population": 6690,
    "id": 1276040741
  },
  {
    "city": "Tlapanaloya",
    "city_ascii": "Tlapanaloya",
    "lat": 19.9358,
    "lng": -99.1019,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Morelos",
    "capital": "",
    "population": 6466,
    "id": 1484594369
  },
  {
    "city": "Lemoore Station",
    "city_ascii": "Lemoore Station",
    "lat": 36.2633,
    "lng": -119.9049,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 7063,
    "id": 1840074319
  },
  {
    "city": "East Vincent",
    "city_ascii": "East Vincent",
    "lat": 40.1684,
    "lng": -75.5946,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 7062,
    "id": 1840150923
  },
  {
    "city": "Berrien Springs",
    "city_ascii": "Berrien Springs",
    "lat": 41.9474,
    "lng": -86.3403,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 7060,
    "id": 1840011224
  },
  {
    "city": "St. Helena",
    "city_ascii": "St. Helena",
    "lat": 38.5063,
    "lng": -122.4682,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 7059,
    "id": 1840021486
  },
  {
    "city": "Polch",
    "city_ascii": "Polch",
    "lat": 50.3011,
    "lng": 7.3167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Rhineland-Palatinate",
    "capital": "",
    "population": 6821,
    "id": 1276551717
  },
  {
    "city": "Whitemarsh Island",
    "city_ascii": "Whitemarsh Island",
    "lat": 32.0304,
    "lng": -81.0109,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 7058,
    "id": 1840029460
  },
  {
    "city": "Tismana",
    "city_ascii": "Tismana",
    "lat": 45.0506,
    "lng": 22.9489,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Gorj",
    "capital": "",
    "population": 7035,
    "id": 1642364959
  },
  {
    "city": "Molochans’k",
    "city_ascii": "Molochans'k",
    "lat": 47.2038,
    "lng": 35.5985,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Zaporiz’ka Oblast’",
    "capital": "",
    "population": 7040,
    "id": 1804015869
  },
  {
    "city": "Hudson Falls",
    "city_ascii": "Hudson Falls",
    "lat": 43.3042,
    "lng": -73.5818,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 7056,
    "id": 1840004178
  },
  {
    "city": "Sandston",
    "city_ascii": "Sandston",
    "lat": 37.512,
    "lng": -77.3149,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 7056,
    "id": 1840026826
  },
  {
    "city": "Fukuyama",
    "city_ascii": "Fukuyama",
    "lat": 41.43,
    "lng": 140.1106,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hokkaidō",
    "capital": "",
    "population": 7032,
    "id": 1392232626
  },
  {
    "city": "Ungheni",
    "city_ascii": "Ungheni",
    "lat": 46.4858,
    "lng": 24.4608,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Mureş",
    "capital": "",
    "population": 6945,
    "id": 1642828169
  },
  {
    "city": "Noble",
    "city_ascii": "Noble",
    "lat": 35.1385,
    "lng": -97.371,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oklahoma",
    "capital": "",
    "population": 7053,
    "id": 1840020450
  },
  {
    "city": "Sotuta",
    "city_ascii": "Sotuta",
    "lat": 20.5968,
    "lng": -89.0082,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Yucatán",
    "capital": "minor",
    "population": 5548,
    "id": 1484594318
  },
  {
    "city": "Redondo",
    "city_ascii": "Redondo",
    "lat": 38.6464,
    "lng": -7.5464,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Évora",
    "capital": "minor",
    "population": 7031,
    "id": 1620909637
  },
  {
    "city": "Pâncota",
    "city_ascii": "Pancota",
    "lat": 46.3225,
    "lng": 21.6869,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Arad",
    "capital": "",
    "population": 6946,
    "id": 1642271257
  },
  {
    "city": "Oak Ridge",
    "city_ascii": "Oak Ridge",
    "lat": 36.174,
    "lng": -79.9916,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 7049,
    "id": 1840016141
  },
  {
    "city": "Fort Payne",
    "city_ascii": "Fort Payne",
    "lat": 34.4557,
    "lng": -85.6965,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 7049,
    "id": 1840004822
  },
  {
    "city": "Honley",
    "city_ascii": "Honley",
    "lat": 53.6027,
    "lng": -1.7921,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Kirklees",
    "capital": "",
    "population": 5897,
    "id": 1826821997
  },
  {
    "city": "Rochester",
    "city_ascii": "Rochester",
    "lat": 41.059,
    "lng": -86.196,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 7046,
    "id": 1840009329
  },
  {
    "city": "In Guezzam",
    "city_ascii": "In Guezzam",
    "lat": 19.5686,
    "lng": 5.7722,
    "country": "Algeria",
    "iso2": "DZ",
    "iso3": "DZA",
    "admin_name": "Tamanrasset",
    "capital": "",
    "population": 7045,
    "id": 1012000031
  },
  {
    "city": "Bothwell",
    "city_ascii": "Bothwell",
    "lat": 55.8023,
    "lng": -4.0662,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "South Lanarkshire",
    "capital": "",
    "population": 6458,
    "id": 1826943843
  },
  {
    "city": "Tumut",
    "city_ascii": "Tumut",
    "lat": -35.3047,
    "lng": 148.2228,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 6230,
    "id": 1036777587
  },
  {
    "city": "Orange City",
    "city_ascii": "Orange City",
    "lat": 43.0023,
    "lng": -96.0566,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Iowa",
    "capital": "",
    "population": 7044,
    "id": 1840009044
  },
  {
    "city": "Coteau-du-Lac",
    "city_ascii": "Coteau-du-Lac",
    "lat": 45.3,
    "lng": -74.18,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 7044,
    "id": 1124000308
  },
  {
    "city": "Aígina",
    "city_ascii": "Aigina",
    "lat": 37.7467,
    "lng": 23.4275,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Attikí",
    "capital": "minor",
    "population": 6867,
    "id": 1300658693
  },
  {
    "city": "Carnarvon",
    "city_ascii": "Carnarvon",
    "lat": -30.9667,
    "lng": 22.1333,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "Northern Cape",
    "capital": "",
    "population": 5785,
    "id": 1710331786
  },
  {
    "city": "Hecklingen",
    "city_ascii": "Hecklingen",
    "lat": 51.85,
    "lng": 11.5167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony-Anhalt",
    "capital": "",
    "population": 6970,
    "id": 1276749682
  },
  {
    "city": "Moringen",
    "city_ascii": "Moringen",
    "lat": 51.7,
    "lng": 9.8667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 6956,
    "id": 1276640951
  },
  {
    "city": "Litóchoro",
    "city_ascii": "Litochoro",
    "lat": 40.1028,
    "lng": 22.5069,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Kentrikí Makedonía",
    "capital": "minor",
    "population": 6995,
    "id": 1300373052
  },
  {
    "city": "Gateway",
    "city_ascii": "Gateway",
    "lat": 61.5737,
    "lng": -149.2389,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alaska",
    "capital": "",
    "population": 7037,
    "id": 1840023654
  },
  {
    "city": "Marksville",
    "city_ascii": "Marksville",
    "lat": 31.1247,
    "lng": -92.0652,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 7036,
    "id": 1840015878
  },
  {
    "city": "Truth or Consequences",
    "city_ascii": "Truth or Consequences",
    "lat": 33.1864,
    "lng": -107.2589,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Mexico",
    "capital": "",
    "population": 7035,
    "id": 1840021995
  },
  {
    "city": "Rancho Calaveras",
    "city_ascii": "Rancho Calaveras",
    "lat": 38.1248,
    "lng": -120.857,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 7035,
    "id": 1840018867
  },
  {
    "city": "Polessk",
    "city_ascii": "Polessk",
    "lat": 54.8667,
    "lng": 21.1,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kaliningradskaya Oblast’",
    "capital": "minor",
    "population": 7011,
    "id": 1643617277
  },
  {
    "city": "Nuevo",
    "city_ascii": "Nuevo",
    "lat": 33.8011,
    "lng": -117.1415,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 7033,
    "id": 1840019287
  },
  {
    "city": "Nagatoro",
    "city_ascii": "Nagatoro",
    "lat": 36.11,
    "lng": 139.11,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Saitama",
    "capital": "",
    "population": 6809,
    "id": 1392832217
  },
  {
    "city": "Laurium",
    "city_ascii": "Laurium",
    "lat": 47.2351,
    "lng": -88.4382,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 7032,
    "id": 1840013133
  },
  {
    "city": "Nagaya",
    "city_ascii": "Nagaya",
    "lat": 35.4308,
    "lng": 140.2269,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Chiba",
    "capital": "",
    "population": 6886,
    "id": 1392798686
  },
  {
    "city": "Sărmaşu",
    "city_ascii": "Sarmasu",
    "lat": 46.7536,
    "lng": 24.1667,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Mureş",
    "capital": "",
    "population": 6942,
    "id": 1642011538
  },
  {
    "city": "Collinsville",
    "city_ascii": "Collinsville",
    "lat": 36.7215,
    "lng": -79.9121,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 7031,
    "id": 1840006553
  },
  {
    "city": "Newbury",
    "city_ascii": "Newbury",
    "lat": 42.7706,
    "lng": -70.8747,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 7031,
    "id": 1840070398
  },
  {
    "city": "Blythebridge",
    "city_ascii": "Blythebridge",
    "lat": 52.9704,
    "lng": -2.0709,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Staffordshire",
    "capital": "",
    "population": 5931,
    "id": 1826518590
  },
  {
    "city": "Archbald",
    "city_ascii": "Archbald",
    "lat": 41.5077,
    "lng": -75.5453,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 7030,
    "id": 1840003374
  },
  {
    "city": "Ámfissa",
    "city_ascii": "Amfissa",
    "lat": 38.5253,
    "lng": 22.3753,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Stereá Elláda",
    "capital": "minor",
    "population": 6919,
    "id": 1300329241
  },
  {
    "city": "Gouverneur",
    "city_ascii": "Gouverneur",
    "lat": 44.3673,
    "lng": -75.4838,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 7029,
    "id": 1840004054
  },
  {
    "city": "Osprey",
    "city_ascii": "Osprey",
    "lat": 27.1914,
    "lng": -82.48,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 7028,
    "id": 1840014186
  },
  {
    "city": "Willis",
    "city_ascii": "Willis",
    "lat": 30.4314,
    "lng": -95.4832,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 7028,
    "id": 1840022175
  },
  {
    "city": "Néo Karlovási",
    "city_ascii": "Neo Karlovasi",
    "lat": 37.7917,
    "lng": 26.7051,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Voreío Aigaío",
    "capital": "",
    "population": 6708,
    "id": 1300578595
  },
  {
    "city": "Cannonvale",
    "city_ascii": "Cannonvale",
    "lat": -20.2863,
    "lng": 148.6866,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Queensland",
    "capital": "",
    "population": 5716,
    "id": 1036428480
  },
  {
    "city": "Krásno nad Kysucou",
    "city_ascii": "Krasno nad Kysucou",
    "lat": 49.3978,
    "lng": 18.8364,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Žilinský",
    "capital": "",
    "population": 6783,
    "id": 1703284159
  },
  {
    "city": "The Blue Mountains",
    "city_ascii": "The Blue Mountains",
    "lat": 44.4833,
    "lng": -80.3833,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 7025,
    "id": 1124000370
  },
  {
    "city": "Meopham",
    "city_ascii": "Meopham",
    "lat": 51.364,
    "lng": 0.36,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Kent",
    "capital": "",
    "population": 6722,
    "id": 1826768782
  },
  {
    "city": "Aridaía",
    "city_ascii": "Aridaia",
    "lat": 40.975,
    "lng": 22.0583,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Kentrikí Makedonía",
    "capital": "minor",
    "population": 6561,
    "id": 1300115599
  },
  {
    "city": "Eston",
    "city_ascii": "Eston",
    "lat": 54.56,
    "lng": -1.144,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Redcar and Cleveland",
    "capital": "",
    "population": 7005,
    "id": 1826647655
  },
  {
    "city": "Abercynon",
    "city_ascii": "Abercynon",
    "lat": 51.6445,
    "lng": -3.3267,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Rhondda Cynon Taff",
    "capital": "",
    "population": 6390,
    "id": 1826152445
  },
  {
    "city": "East End",
    "city_ascii": "East End",
    "lat": 34.5554,
    "lng": -92.3261,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arkansas",
    "capital": "",
    "population": 7019,
    "id": 1840013086
  },
  {
    "city": "Kiel",
    "city_ascii": "Kiel",
    "lat": 43.9167,
    "lng": -88.0266,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 7018,
    "id": 1840002210
  },
  {
    "city": "Răcari",
    "city_ascii": "Racari",
    "lat": 44.6333,
    "lng": 25.7333,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Dâmboviţa",
    "capital": "",
    "population": 6930,
    "id": 1642271241
  },
  {
    "city": "Kistelek",
    "city_ascii": "Kistelek",
    "lat": 46.4731,
    "lng": 19.98,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Csongrád",
    "capital": "minor",
    "population": 6917,
    "id": 1348641696
  },
  {
    "city": "Wigton",
    "city_ascii": "Wigton",
    "lat": 54.823,
    "lng": -3.159,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Cumbria",
    "capital": "",
    "population": 5831,
    "id": 1826098296
  },
  {
    "city": "Carlton",
    "city_ascii": "Carlton",
    "lat": 52.967,
    "lng": -1.088,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Nottinghamshire",
    "capital": "",
    "population": 6881,
    "id": 1826277512
  },
  {
    "city": "Mount Carmel",
    "city_ascii": "Mount Carmel",
    "lat": 38.4187,
    "lng": -87.7694,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 7016,
    "id": 1840008643
  },
  {
    "city": "Newbridge",
    "city_ascii": "Newbridge",
    "lat": 51.67,
    "lng": -3.136,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Caerphilly",
    "capital": "",
    "population": 6509,
    "id": 1826628425
  },
  {
    "city": "Reichelsheim",
    "city_ascii": "Reichelsheim",
    "lat": 50.3569,
    "lng": 8.8725,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 6769,
    "id": 1276757445
  },
  {
    "city": "Carnforth",
    "city_ascii": "Carnforth",
    "lat": 54.123,
    "lng": -2.766,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Lancashire",
    "capital": "",
    "population": 5560,
    "id": 1826876067
  },
  {
    "city": "Geithain",
    "city_ascii": "Geithain",
    "lat": 51.05,
    "lng": 12.6833,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 6888,
    "id": 1276186644
  },
  {
    "city": "Bonndorf im Schwarzwald",
    "city_ascii": "Bonndorf im Schwarzwald",
    "lat": 47.8194,
    "lng": 8.3431,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 6922,
    "id": 1276790713
  },
  {
    "city": "Polgárdi",
    "city_ascii": "Polgardi",
    "lat": 47.0538,
    "lng": 18.3049,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Fejér",
    "capital": "minor",
    "population": 6917,
    "id": 1348300041
  },
  {
    "city": "Vyerkhnyadzvinsk",
    "city_ascii": "Vyerkhnyadzvinsk",
    "lat": 55.7667,
    "lng": 27.9333,
    "country": "Belarus",
    "iso2": "BY",
    "iso3": "BLR",
    "admin_name": "Vitsyebskaya Voblasts’",
    "capital": "minor",
    "population": 7000,
    "id": 1112221488
  },
  {
    "city": "Fort Myers Shores",
    "city_ascii": "Fort Myers Shores",
    "lat": 26.7135,
    "lng": -81.7383,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 7010,
    "id": 1840014212
  },
  {
    "city": "San Martin",
    "city_ascii": "San Martin",
    "lat": 37.0829,
    "lng": -121.5963,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 7010,
    "id": 1840018948
  },
  {
    "city": "Whitewater Region",
    "city_ascii": "Whitewater Region",
    "lat": 45.7167,
    "lng": -76.8333,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 7009,
    "id": 1124001363
  },
  {
    "city": "Virrat",
    "city_ascii": "Virrat",
    "lat": 62.2403,
    "lng": 23.7708,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Pirkanmaa",
    "capital": "minor",
    "population": 7002,
    "id": 1246625508
  },
  {
    "city": "Preesall",
    "city_ascii": "Preesall",
    "lat": 53.917,
    "lng": -2.967,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Lancashire",
    "capital": "",
    "population": 5694,
    "id": 1826573807
  },
  {
    "city": "Cazenovia",
    "city_ascii": "Cazenovia",
    "lat": 42.9122,
    "lng": -75.8636,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 7007,
    "id": 1840004360
  },
  {
    "city": "Point Vernon",
    "city_ascii": "Point Vernon",
    "lat": -25.2538,
    "lng": 152.8177,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Queensland",
    "capital": "",
    "population": 5699,
    "id": 1036205903
  },
  {
    "city": "Vorozhba",
    "city_ascii": "Vorozhba",
    "lat": 51.1749,
    "lng": 34.2299,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Sums’ka Oblast’",
    "capital": "",
    "population": 6972,
    "id": 1804005245
  },
  {
    "city": "West Glens Falls",
    "city_ascii": "West Glens Falls",
    "lat": 43.3019,
    "lng": -73.6874,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 7005,
    "id": 1840004184
  },
  {
    "city": "St. Augustine Beach",
    "city_ascii": "St. Augustine Beach",
    "lat": 29.8414,
    "lng": -81.2713,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 7004,
    "id": 1840015936
  },
  {
    "city": "Velika Kladuša",
    "city_ascii": "Velika Kladusa",
    "lat": 45.1667,
    "lng": 15.8,
    "country": "Bosnia And Herzegovina",
    "iso2": "BA",
    "iso3": "BIH",
    "admin_name": "Bosnia and Herzegovina, Federation of",
    "capital": "minor",
    "population": 6902,
    "id": 1070100775
  },
  {
    "city": "Kouango",
    "city_ascii": "Kouango",
    "lat": 4.9667,
    "lng": 19.9833,
    "country": "Central African Republic",
    "iso2": "CF",
    "iso3": "CAF",
    "admin_name": "Ouaka",
    "capital": "",
    "population": 6984,
    "id": 1140674244
  },
  {
    "city": "Warnemünde",
    "city_ascii": "Warnemunde",
    "lat": 54.1753,
    "lng": 12.0883,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Mecklenburg-Western Pomerania",
    "capital": "",
    "population": 5937,
    "id": 1276733905
  },
  {
    "city": "Piława Górna",
    "city_ascii": "Pilawa Gorna",
    "lat": 50.6833,
    "lng": 16.75,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Dolnośląskie",
    "capital": "",
    "population": 6673,
    "id": 1616426625
  },
  {
    "city": "Westmere",
    "city_ascii": "Westmere",
    "lat": 42.6883,
    "lng": -73.8744,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 7001,
    "id": 1840004489
  },
  {
    "city": "Ovacık",
    "city_ascii": "Ovacik",
    "lat": 39.3586,
    "lng": 39.2164,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Tunceli",
    "capital": "minor",
    "population": 6998,
    "id": 1792343727
  },
  {
    "city": "Pryvillya",
    "city_ascii": "Pryvillya",
    "lat": 48.9999,
    "lng": 38.2948,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Luhans’ka Oblast’",
    "capital": "",
    "population": 6970,
    "id": 1804675799
  },
  {
    "city": "Providence",
    "city_ascii": "Providence",
    "lat": 39.9092,
    "lng": -76.233,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 6998,
    "id": 1840142493
  },
  {
    "city": "Lipany",
    "city_ascii": "Lipany",
    "lat": 49.1528,
    "lng": 20.9619,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Prešovský",
    "capital": "",
    "population": 6484,
    "id": 1703017227
  },
  {
    "city": "Tappan",
    "city_ascii": "Tappan",
    "lat": 41.0269,
    "lng": -73.952,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 6996,
    "id": 1840004969
  },
  {
    "city": "Torrisholme",
    "city_ascii": "Torrisholme",
    "lat": 54.066,
    "lng": -2.83,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Lancashire",
    "capital": "",
    "population": 6755,
    "id": 1826182736
  },
  {
    "city": "Crete",
    "city_ascii": "Crete",
    "lat": 40.6254,
    "lng": -96.9575,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Nebraska",
    "capital": "",
    "population": 6995,
    "id": 1840007215
  },
  {
    "city": "Nambucca Heads",
    "city_ascii": "Nambucca Heads",
    "lat": -30.6414,
    "lng": 152.9906,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 6327,
    "id": 1036453067
  },
  {
    "city": "Adel",
    "city_ascii": "Adel",
    "lat": 31.1272,
    "lng": -83.4232,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 6989,
    "id": 1840013879
  },
  {
    "city": "Wynyard",
    "city_ascii": "Wynyard",
    "lat": -41,
    "lng": 145.7167,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Tasmania",
    "capital": "",
    "population": 6001,
    "id": 1036260821
  },
  {
    "city": "Zirc",
    "city_ascii": "Zirc",
    "lat": 47.2633,
    "lng": 17.8725,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Veszprém",
    "capital": "minor",
    "population": 6805,
    "id": 1348793365
  },
  {
    "city": "Sekigahara",
    "city_ascii": "Sekigahara",
    "lat": 35.3653,
    "lng": 136.4672,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Gifu",
    "capital": "",
    "population": 6848,
    "id": 1392983834
  },
  {
    "city": "Hillsboro",
    "city_ascii": "Hillsboro",
    "lat": 39.1668,
    "lng": -89.4735,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 6984,
    "id": 1840007364
  },
  {
    "city": "Paulicéia",
    "city_ascii": "Pauliceia",
    "lat": -21.3167,
    "lng": -51.8333,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 6981,
    "id": 1076216479
  },
  {
    "city": "Kieta",
    "city_ascii": "Kieta",
    "lat": -6.2058,
    "lng": 155.6227,
    "country": "Papua New Guinea",
    "iso2": "PG",
    "iso3": "PNG",
    "admin_name": "Bougainville",
    "capital": "",
    "population": 6958,
    "id": 1598490894
  },
  {
    "city": "Staré Město",
    "city_ascii": "Stare Mesto",
    "lat": 49.0752,
    "lng": 17.4334,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Zlínský Kraj",
    "capital": "",
    "population": 6661,
    "id": 1203171534
  },
  {
    "city": "Želiezovce",
    "city_ascii": "Zeliezovce",
    "lat": 48.0486,
    "lng": 18.6603,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Nitriansky",
    "capital": "",
    "population": 6859,
    "id": 1703469340
  },
  {
    "city": "Joshua Tree",
    "city_ascii": "Joshua Tree",
    "lat": 34.1236,
    "lng": -116.3128,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 6980,
    "id": 1840017802
  },
  {
    "city": "Keyport",
    "city_ascii": "Keyport",
    "lat": 40.4327,
    "lng": -74.2011,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 6977,
    "id": 1840003691
  },
  {
    "city": "Conemaugh Township",
    "city_ascii": "Conemaugh Township",
    "lat": 40.2432,
    "lng": -78.966,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 6977,
    "id": 1840145481
  },
  {
    "city": "Summit Township",
    "city_ascii": "Summit Township",
    "lat": 42.0375,
    "lng": -80.0513,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 6976,
    "id": 1840143177
  },
  {
    "city": "North Mackay",
    "city_ascii": "North Mackay",
    "lat": -21.1216,
    "lng": 149.1783,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Queensland",
    "capital": "",
    "population": 5933,
    "id": 1036075287
  },
  {
    "city": "Ammanford",
    "city_ascii": "Ammanford",
    "lat": 51.8,
    "lng": -3.993,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Carmarthenshire",
    "capital": "",
    "population": 5411,
    "id": 1826559160
  },
  {
    "city": "Schelklingen",
    "city_ascii": "Schelklingen",
    "lat": 48.3756,
    "lng": 9.7325,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 6883,
    "id": 1276427856
  },
  {
    "city": "Stevensville",
    "city_ascii": "Stevensville",
    "lat": 38.9745,
    "lng": -76.3184,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 6973,
    "id": 1840005897
  },
  {
    "city": "Hulín",
    "city_ascii": "Hulin",
    "lat": 49.3169,
    "lng": 17.4638,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Zlínský Kraj",
    "capital": "",
    "population": 6762,
    "id": 1203269367
  },
  {
    "city": "Brier",
    "city_ascii": "Brier",
    "lat": 47.7924,
    "lng": -122.2734,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 6970,
    "id": 1840018379
  },
  {
    "city": "Sedona",
    "city_ascii": "Sedona",
    "lat": 34.8574,
    "lng": -111.7951,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 6969,
    "id": 1840021584
  },
  {
    "city": "Milltown",
    "city_ascii": "Milltown",
    "lat": 40.4504,
    "lng": -74.4351,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 6967,
    "id": 1840001338
  },
  {
    "city": "Kamihonbetsu",
    "city_ascii": "Kamihonbetsu",
    "lat": 43.1244,
    "lng": 143.6106,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hokkaidō",
    "capital": "",
    "population": 6948,
    "id": 1392608478
  },
  {
    "city": "Hamilton",
    "city_ascii": "Hamilton",
    "lat": 46.2527,
    "lng": -114.1598,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Montana",
    "capital": "",
    "population": 6964,
    "id": 1840019896
  },
  {
    "city": "Ashland",
    "city_ascii": "Ashland",
    "lat": 46.5801,
    "lng": -90.8715,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 6962,
    "id": 1840003916
  },
  {
    "city": "Leitchfield",
    "city_ascii": "Leitchfield",
    "lat": 37.4862,
    "lng": -86.2857,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kentucky",
    "capital": "",
    "population": 6962,
    "id": 1840014366
  },
  {
    "city": "Böhlen",
    "city_ascii": "Bohlen",
    "lat": 51.2025,
    "lng": 12.3858,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 6687,
    "id": 1276120846
  },
  {
    "city": "Cos Cob",
    "city_ascii": "Cos Cob",
    "lat": 41.0513,
    "lng": -73.5931,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 6959,
    "id": 1840026281
  },
  {
    "city": "Creswell",
    "city_ascii": "Creswell",
    "lat": 43.9212,
    "lng": -123.016,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 6959,
    "id": 1840018625
  },
  {
    "city": "Edwardsburgh/Cardinal",
    "city_ascii": "Edwardsburgh/Cardinal",
    "lat": 44.8333,
    "lng": -75.5,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 6959,
    "id": 1124001736
  },
  {
    "city": "Hartberg",
    "city_ascii": "Hartberg",
    "lat": 47.2806,
    "lng": 15.97,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Steiermark",
    "capital": "minor",
    "population": 6650,
    "id": 1040068205
  },
  {
    "city": "Bondurant",
    "city_ascii": "Bondurant",
    "lat": 41.6986,
    "lng": -93.4551,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Iowa",
    "capital": "",
    "population": 6958,
    "id": 1840007067
  },
  {
    "city": "San Buenaventura",
    "city_ascii": "San Buenaventura",
    "lat": 29.8333,
    "lng": -107.4667,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Chihuahua",
    "capital": "minor",
    "population": 6957,
    "id": 1484524609
  },
  {
    "city": "Mechanicstown",
    "city_ascii": "Mechanicstown",
    "lat": 41.4472,
    "lng": -74.3914,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 6957,
    "id": 1840004857
  },
  {
    "city": "Wytheville",
    "city_ascii": "Wytheville",
    "lat": 36.953,
    "lng": -81.0881,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 6956,
    "id": 1840006504
  },
  {
    "city": "Freckleton",
    "city_ascii": "Freckleton",
    "lat": 53.753,
    "lng": -2.867,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Lancashire",
    "capital": "",
    "population": 6019,
    "id": 1826757058
  },
  {
    "city": "Haworth",
    "city_ascii": "Haworth",
    "lat": 53.83,
    "lng": -1.96,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Bradford",
    "capital": "",
    "population": 6379,
    "id": 1826616247
  },
  {
    "city": "Stratford",
    "city_ascii": "Stratford",
    "lat": 39.829,
    "lng": -75.0156,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 6955,
    "id": 1840001516
  },
  {
    "city": "Catoosa",
    "city_ascii": "Catoosa",
    "lat": 36.1832,
    "lng": -95.7662,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oklahoma",
    "capital": "",
    "population": 6953,
    "id": 1840019048
  },
  {
    "city": "Bern",
    "city_ascii": "Bern",
    "lat": 40.4005,
    "lng": -75.9933,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 6952,
    "id": 1840145744
  },
  {
    "city": "Paintsville",
    "city_ascii": "Paintsville",
    "lat": 37.8167,
    "lng": -82.8088,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kentucky",
    "capital": "",
    "population": 6952,
    "id": 1840014331
  },
  {
    "city": "Néoi Epivátes",
    "city_ascii": "Neoi Epivates",
    "lat": 40.501,
    "lng": 22.909,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Kentrikí Makedonía",
    "capital": "",
    "population": 5984,
    "id": 1300073585
  },
  {
    "city": "Ipeúna",
    "city_ascii": "Ipeuna",
    "lat": -22.4333,
    "lng": -47.7167,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 6914,
    "id": 1076773773
  },
  {
    "city": "Artern",
    "city_ascii": "Artern",
    "lat": 51.3667,
    "lng": 11.3,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Thuringia",
    "capital": "",
    "population": 6799,
    "id": 1276225112
  },
  {
    "city": "Peru",
    "city_ascii": "Peru",
    "lat": 44.582,
    "lng": -73.556,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 6950,
    "id": 1840024050
  },
  {
    "city": "Kamitakeshi",
    "city_ascii": "Kamitakeshi",
    "lat": 36.2719,
    "lng": 138.3161,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Nagano",
    "capital": "",
    "population": 6847,
    "id": 1392154343
  },
  {
    "city": "Desert Palms",
    "city_ascii": "Desert Palms",
    "lat": 33.779,
    "lng": -116.293,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 6947,
    "id": 1840043016
  },
  {
    "city": "Ixtenco",
    "city_ascii": "Ixtenco",
    "lat": 19.2514,
    "lng": -97.8942,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Tlaxcala",
    "capital": "minor",
    "population": 6791,
    "id": 1484538100
  },
  {
    "city": "Cliza",
    "city_ascii": "Cliza",
    "lat": -17.5919,
    "lng": -65.9342,
    "country": "Bolivia",
    "iso2": "BO",
    "iso3": "BOL",
    "admin_name": "Cochabamba",
    "capital": "",
    "population": 6534,
    "id": 1068759245
  },
  {
    "city": "Epping",
    "city_ascii": "Epping",
    "lat": 43.0501,
    "lng": -71.0747,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Hampshire",
    "capital": "",
    "population": 6945,
    "id": 1840054819
  },
  {
    "city": "Bremen",
    "city_ascii": "Bremen",
    "lat": 33.7085,
    "lng": -85.1495,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 6945,
    "id": 1840013715
  },
  {
    "city": "Enderby",
    "city_ascii": "Enderby",
    "lat": 52.5881,
    "lng": -1.2092,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Leicestershire",
    "capital": "",
    "population": 6314,
    "id": 1826688106
  },
  {
    "city": "Qatsrin",
    "city_ascii": "Qatsrin",
    "lat": 32.9925,
    "lng": 35.6906,
    "country": "Israel",
    "iso2": "IL",
    "iso3": "ISR",
    "admin_name": "Northern",
    "capital": "",
    "population": 6913,
    "id": 1376000047
  },
  {
    "city": "Prairie View",
    "city_ascii": "Prairie View",
    "lat": 30.085,
    "lng": -95.9897,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 6944,
    "id": 1840021698
  },
  {
    "city": "Ocean City",
    "city_ascii": "Ocean City",
    "lat": 38.3998,
    "lng": -75.0715,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 6944,
    "id": 1840006259
  },
  {
    "city": "Kōchi",
    "city_ascii": "Kochi",
    "lat": 34.7572,
    "lng": 138.9875,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Shizuoka",
    "capital": "",
    "population": 6874,
    "id": 1392567991
  },
  {
    "city": "Cochituate",
    "city_ascii": "Cochituate",
    "lat": 42.329,
    "lng": -71.3576,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 6942,
    "id": 1840003129
  },
  {
    "city": "Barberton",
    "city_ascii": "Barberton",
    "lat": 45.7136,
    "lng": -122.6115,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 6941,
    "id": 1840017397
  },
  {
    "city": "Madison Park",
    "city_ascii": "Madison Park",
    "lat": 40.4461,
    "lng": -74.2959,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 6941,
    "id": 1840005421
  },
  {
    "city": "Franklin Township",
    "city_ascii": "Franklin Township",
    "lat": 39.8865,
    "lng": -80.1807,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 6941,
    "id": 1840142433
  },
  {
    "city": "Whitinsville",
    "city_ascii": "Whitinsville",
    "lat": 42.1146,
    "lng": -71.6688,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 6941,
    "id": 1840003153
  },
  {
    "city": "Selfoss",
    "city_ascii": "Selfoss",
    "lat": 63.9322,
    "lng": -21.0002,
    "country": "Iceland",
    "iso2": "IS",
    "iso3": "ISL",
    "admin_name": "Suðurland",
    "capital": "",
    "population": 6878,
    "id": 1352214400
  },
  {
    "city": "Decatur",
    "city_ascii": "Decatur",
    "lat": 33.2262,
    "lng": -97.5876,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 6939,
    "id": 1840019385
  },
  {
    "city": "Kórnik",
    "city_ascii": "Kornik",
    "lat": 52.2367,
    "lng": 17.0986,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Wielkopolskie",
    "capital": "",
    "population": 6807,
    "id": 1616373679
  },
  {
    "city": "Roskovec",
    "city_ascii": "Roskovec",
    "lat": 40.7375,
    "lng": 19.7022,
    "country": "Albania",
    "iso2": "AL",
    "iso3": "ALB",
    "admin_name": "Fier",
    "capital": "",
    "population": 6657,
    "id": 1008765146
  },
  {
    "city": "Höchstädt an der Donau",
    "city_ascii": "Hochstadt an der Donau",
    "lat": 48.6112,
    "lng": 10.5682,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 6756,
    "id": 1276922460
  },
  {
    "city": "Gleneagle",
    "city_ascii": "Gleneagle",
    "lat": 39.0453,
    "lng": -104.8288,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 6936,
    "id": 1840028578
  },
  {
    "city": "Vandalia",
    "city_ascii": "Vandalia",
    "lat": 38.9754,
    "lng": -89.1117,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 6936,
    "id": 1840010693
  },
  {
    "city": "Tommot",
    "city_ascii": "Tommot",
    "lat": 58.9667,
    "lng": 126.2667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sakha (Yakutiya)",
    "capital": "",
    "population": 6935,
    "id": 1643466057
  },
  {
    "city": "Madison",
    "city_ascii": "Madison",
    "lat": 44.0062,
    "lng": -97.1084,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Dakota",
    "capital": "",
    "population": 6933,
    "id": 1840002540
  },
  {
    "city": "Sainte-Anne-des-Monts",
    "city_ascii": "Sainte-Anne-des-Monts",
    "lat": 49.1333,
    "lng": -66.5,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 6933,
    "id": 1124183859
  },
  {
    "city": "Fort Meade",
    "city_ascii": "Fort Meade",
    "lat": 27.7645,
    "lng": -81.8058,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 6932,
    "id": 1840014134
  },
  {
    "city": "Gniew",
    "city_ascii": "Gniew",
    "lat": 53.8333,
    "lng": 18.8333,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Pomorskie",
    "capital": "",
    "population": 6844,
    "id": 1616464336
  },
  {
    "city": "Willow Oak",
    "city_ascii": "Willow Oak",
    "lat": 27.9216,
    "lng": -82.0244,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 6930,
    "id": 1840028919
  },
  {
    "city": "Kentfield",
    "city_ascii": "Kentfield",
    "lat": 37.9481,
    "lng": -122.5496,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 6930,
    "id": 1840017603
  },
  {
    "city": "Newton Grange",
    "city_ascii": "Newton Grange",
    "lat": 55.868,
    "lng": -3.067,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Midlothian",
    "capital": "",
    "population": 5341,
    "id": 1826418567
  },
  {
    "city": "Waddington",
    "city_ascii": "Waddington",
    "lat": 53.1664,
    "lng": -0.5389,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Lincolnshire",
    "capital": "",
    "population": 6122,
    "id": 1826970637
  },
  {
    "city": "Geiselhöring",
    "city_ascii": "Geiselhoring",
    "lat": 48.8258,
    "lng": 12.3925,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 6860,
    "id": 1276012875
  },
  {
    "city": "Roma",
    "city_ascii": "Roma",
    "lat": -26.5733,
    "lng": 148.7869,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Queensland",
    "capital": "",
    "population": 6848,
    "id": 1036698836
  },
  {
    "city": "Römhild",
    "city_ascii": "Romhild",
    "lat": 50.3833,
    "lng": 10.55,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Thuringia",
    "capital": "",
    "population": 6869,
    "id": 1276685524
  },
  {
    "city": "Mount Ivy",
    "city_ascii": "Mount Ivy",
    "lat": 41.1926,
    "lng": -74.0297,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 6925,
    "id": 1840004962
  },
  {
    "city": "Avon",
    "city_ascii": "Avon",
    "lat": 42.8955,
    "lng": -77.7341,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 6924,
    "id": 1840004428
  },
  {
    "city": "Page",
    "city_ascii": "Page",
    "lat": 36.9426,
    "lng": -111.5071,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 6924,
    "id": 1840020336
  },
  {
    "city": "Felsőzsolca",
    "city_ascii": "Felsozsolca",
    "lat": 48.1083,
    "lng": 20.8556,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Borsod-Abaúj-Zemplén",
    "capital": "",
    "population": 6521,
    "id": 1348370642
  },
  {
    "city": "Rodenberg",
    "city_ascii": "Rodenberg",
    "lat": 52.3128,
    "lng": 9.3594,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 6505,
    "id": 1276151075
  },
  {
    "city": "Treasure Island",
    "city_ascii": "Treasure Island",
    "lat": 27.774,
    "lng": -82.7663,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 6921,
    "id": 1840015981
  },
  {
    "city": "Tălmaciu",
    "city_ascii": "Talmaciu",
    "lat": 45.6667,
    "lng": 24.2611,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Sibiu",
    "capital": "",
    "population": 6905,
    "id": 1642705565
  },
  {
    "city": "Spring Lake Park",
    "city_ascii": "Spring Lake Park",
    "lat": 45.1161,
    "lng": -93.2451,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 6919,
    "id": 1840008903
  },
  {
    "city": "Dobruška",
    "city_ascii": "Dobruska",
    "lat": 50.292,
    "lng": 16.1601,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Královéhradecký Kraj",
    "capital": "",
    "population": 6723,
    "id": 1203725539
  },
  {
    "city": "Black Jack",
    "city_ascii": "Black Jack",
    "lat": 38.7993,
    "lng": -90.264,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 6918,
    "id": 1840007452
  },
  {
    "city": "Lewisburg",
    "city_ascii": "Lewisburg",
    "lat": 37.8096,
    "lng": -80.4327,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "West Virginia",
    "capital": "",
    "population": 6917,
    "id": 1840006285
  },
  {
    "city": "Viola",
    "city_ascii": "Viola",
    "lat": 41.1287,
    "lng": -74.0855,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 6917,
    "id": 1840004972
  },
  {
    "city": "Carrigtohill",
    "city_ascii": "Carrigtohill",
    "lat": 51.9,
    "lng": -8.2667,
    "country": "Ireland",
    "iso2": "IE",
    "iso3": "IRL",
    "admin_name": "Cork",
    "capital": "",
    "population": 6665,
    "id": 1372316117
  },
  {
    "city": "Sen‘afe",
    "city_ascii": "Sen`afe",
    "lat": 14.7,
    "lng": 39.4167,
    "country": "Eritrea",
    "iso2": "ER",
    "iso3": "ERI",
    "admin_name": "Debub",
    "capital": "",
    "population": 6831,
    "id": 1232846614
  },
  {
    "city": "Holice",
    "city_ascii": "Holice",
    "lat": 50.0661,
    "lng": 15.986,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Pardubický Kraj",
    "capital": "",
    "population": 6581,
    "id": 1203389387
  },
  {
    "city": "Müncheberg",
    "city_ascii": "Muncheberg",
    "lat": 52.5036,
    "lng": 14.1397,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Brandenburg",
    "capital": "",
    "population": 6870,
    "id": 1276376107
  },
  {
    "city": "Saue",
    "city_ascii": "Saue",
    "lat": 59.3231,
    "lng": 24.5622,
    "country": "Estonia",
    "iso2": "EE",
    "iso3": "EST",
    "admin_name": "Harjumaa",
    "capital": "minor",
    "population": 5631,
    "id": 1233555053
  },
  {
    "city": "Gália",
    "city_ascii": "Galia",
    "lat": -22.2914,
    "lng": -49.5528,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 6894,
    "id": 1076294691
  },
  {
    "city": "Springs",
    "city_ascii": "Springs",
    "lat": 41.0212,
    "lng": -72.1584,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 6912,
    "id": 1840005102
  },
  {
    "city": "Mira Monte",
    "city_ascii": "Mira Monte",
    "lat": 34.4284,
    "lng": -119.2853,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 6912,
    "id": 1840019197
  },
  {
    "city": "Woodland",
    "city_ascii": "Woodland",
    "lat": 45.9145,
    "lng": -122.7506,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 6911,
    "id": 1840021181
  },
  {
    "city": "Devin",
    "city_ascii": "Devin",
    "lat": 41.7425,
    "lng": 24.3992,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Smolyan",
    "capital": "minor",
    "population": 6859,
    "id": 1100395981
  },
  {
    "city": "Vésztő",
    "city_ascii": "Veszto",
    "lat": 46.9256,
    "lng": 21.2639,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Békés",
    "capital": "",
    "population": 6855,
    "id": 1348546414
  },
  {
    "city": "Old Chelsea",
    "city_ascii": "Old Chelsea",
    "lat": 45.5,
    "lng": -75.7833,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 6909,
    "id": 1124835028
  },
  {
    "city": "Phelps",
    "city_ascii": "Phelps",
    "lat": 42.9574,
    "lng": -77.048,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 6907,
    "id": 1840004412
  },
  {
    "city": "Upper Freehold",
    "city_ascii": "Upper Freehold",
    "lat": 40.1552,
    "lng": -74.5283,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 6906,
    "id": 1840081645
  },
  {
    "city": "Pollock Pines",
    "city_ascii": "Pollock Pines",
    "lat": 38.7564,
    "lng": -120.5904,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 6905,
    "id": 1840018831
  },
  {
    "city": "Volkmarsen",
    "city_ascii": "Volkmarsen",
    "lat": 51.3833,
    "lng": 9.1167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 6804,
    "id": 1276918425
  },
  {
    "city": "Laurence Harbor",
    "city_ascii": "Laurence Harbor",
    "lat": 40.4489,
    "lng": -74.2494,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 6904,
    "id": 1840005420
  },
  {
    "city": "Westbrook",
    "city_ascii": "Westbrook",
    "lat": 41.3069,
    "lng": -72.4665,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 6904,
    "id": 1840045100
  },
  {
    "city": "Clover Creek",
    "city_ascii": "Clover Creek",
    "lat": 47.1404,
    "lng": -122.3827,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 6902,
    "id": 1840041920
  },
  {
    "city": "Indian River Estates",
    "city_ascii": "Indian River Estates",
    "lat": 27.3564,
    "lng": -80.2983,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 6902,
    "id": 1840029044
  },
  {
    "city": "Interlaken",
    "city_ascii": "Interlaken",
    "lat": 46.6881,
    "lng": 7.8646,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Bern",
    "capital": "minor",
    "population": 5592,
    "id": 1756062861
  },
  {
    "city": "Gulf Breeze",
    "city_ascii": "Gulf Breeze",
    "lat": 30.3685,
    "lng": -87.1769,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 6900,
    "id": 1840013920
  },
  {
    "city": "Village St. George",
    "city_ascii": "Village St. George",
    "lat": 30.3598,
    "lng": -91.0672,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 6900,
    "id": 1840031109
  },
  {
    "city": "Lake Mills",
    "city_ascii": "Lake Mills",
    "lat": 43.0776,
    "lng": -88.9054,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 6897,
    "id": 1840003003
  },
  {
    "city": "Atkinson",
    "city_ascii": "Atkinson",
    "lat": 42.837,
    "lng": -71.1605,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Hampshire",
    "capital": "",
    "population": 6897,
    "id": 1840054547
  },
  {
    "city": "Byron",
    "city_ascii": "Byron",
    "lat": 42.1224,
    "lng": -89.2665,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 6896,
    "id": 1840007021
  },
  {
    "city": "Karabogaz",
    "city_ascii": "Karabogaz",
    "lat": 41.5397,
    "lng": 52.57,
    "country": "Turkmenistan",
    "iso2": "TM",
    "iso3": "TKM",
    "admin_name": "Balkan",
    "capital": "",
    "population": 6895,
    "id": 1795203659
  },
  {
    "city": "Bridge City",
    "city_ascii": "Bridge City",
    "lat": 29.9321,
    "lng": -90.1594,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 6894,
    "id": 1840013989
  },
  {
    "city": "Great Barrington",
    "city_ascii": "Great Barrington",
    "lat": 42.211,
    "lng": -73.3416,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 6894,
    "id": 1840053648
  },
  {
    "city": "Dunbar",
    "city_ascii": "Dunbar",
    "lat": 39.9637,
    "lng": -79.6027,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 6893,
    "id": 1840001503
  },
  {
    "city": "Colusa",
    "city_ascii": "Colusa",
    "lat": 39.2059,
    "lng": -122.0125,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 6891,
    "id": 1840018810
  },
  {
    "city": "Union",
    "city_ascii": "Union",
    "lat": 39.909,
    "lng": -84.2896,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 6891,
    "id": 1840010583
  },
  {
    "city": "Junction City",
    "city_ascii": "Junction City",
    "lat": 44.2067,
    "lng": -123.2101,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 6891,
    "id": 1840018623
  },
  {
    "city": "Stawell",
    "city_ascii": "Stawell",
    "lat": -37.05,
    "lng": 142.7667,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Victoria",
    "capital": "",
    "population": 6032,
    "id": 1036073093
  },
  {
    "city": "Hondo",
    "city_ascii": "Hondo",
    "lat": 29.3531,
    "lng": -99.162,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 6889,
    "id": 1840020963
  },
  {
    "city": "University Park",
    "city_ascii": "University Park",
    "lat": 41.4461,
    "lng": -87.7154,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 6887,
    "id": 1840029849
  },
  {
    "city": "Yeniceoba",
    "city_ascii": "Yeniceoba",
    "lat": 38.8708,
    "lng": 32.7919,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Konya",
    "capital": "minor",
    "population": 6872,
    "id": 1792805859
  },
  {
    "city": "Southampton Township",
    "city_ascii": "Southampton Township",
    "lat": 40.0423,
    "lng": -77.4569,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 6886,
    "id": 1840151632
  },
  {
    "city": "Ephraim",
    "city_ascii": "Ephraim",
    "lat": 39.3564,
    "lng": -111.5847,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Utah",
    "capital": "",
    "population": 6886,
    "id": 1840020202
  },
  {
    "city": "Beerwah",
    "city_ascii": "Beerwah",
    "lat": -26.8556,
    "lng": 152.96,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Queensland",
    "capital": "",
    "population": 6769,
    "id": 1036411138
  },
  {
    "city": "Mountainside",
    "city_ascii": "Mountainside",
    "lat": 40.6811,
    "lng": -74.36,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 6885,
    "id": 1840003612
  },
  {
    "city": "Farmington",
    "city_ascii": "Farmington",
    "lat": 43.3629,
    "lng": -71.076,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Hampshire",
    "capital": "",
    "population": 6884,
    "id": 1840054843
  },
  {
    "city": "Jerichow",
    "city_ascii": "Jerichow",
    "lat": 52.4995,
    "lng": 12.028,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony-Anhalt",
    "capital": "",
    "population": 6858,
    "id": 1276882856
  },
  {
    "city": "Battle",
    "city_ascii": "Battle",
    "lat": 50.92,
    "lng": 0.48,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "East Sussex",
    "capital": "",
    "population": 6673,
    "id": 1826227818
  },
  {
    "city": "Milo",
    "city_ascii": "Milo",
    "lat": 42.615,
    "lng": -77.0031,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 6879,
    "id": 1840087673
  },
  {
    "city": "Chestertown",
    "city_ascii": "Chestertown",
    "lat": 39.2182,
    "lng": -76.0714,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 6878,
    "id": 1840005798
  },
  {
    "city": "Letovice",
    "city_ascii": "Letovice",
    "lat": 49.5471,
    "lng": 16.5736,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Jihomoravský Kraj",
    "capital": "",
    "population": 6745,
    "id": 1203748184
  },
  {
    "city": "Cheddleton",
    "city_ascii": "Cheddleton",
    "lat": 53.064,
    "lng": -2.043,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Staffordshire",
    "capital": "",
    "population": 6311,
    "id": 1826205804
  },
  {
    "city": "Belle Haven",
    "city_ascii": "Belle Haven",
    "lat": 38.7775,
    "lng": -77.0574,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 6877,
    "id": 1840145044
  },
  {
    "city": "Seward",
    "city_ascii": "Seward",
    "lat": 40.9099,
    "lng": -97.0957,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Nebraska",
    "capital": "",
    "population": 6876,
    "id": 1840009356
  },
  {
    "city": "Sturgis",
    "city_ascii": "Sturgis",
    "lat": 44.411,
    "lng": -103.4975,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Dakota",
    "capital": "",
    "population": 6876,
    "id": 1840002174
  },
  {
    "city": "Beacon Square",
    "city_ascii": "Beacon Square",
    "lat": 28.2118,
    "lng": -82.7504,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 6873,
    "id": 1840028798
  },
  {
    "city": "North Stormont",
    "city_ascii": "North Stormont",
    "lat": 45.2167,
    "lng": -75,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 6873,
    "id": 1124000261
  },
  {
    "city": "St. Martinville",
    "city_ascii": "St. Martinville",
    "lat": 30.1263,
    "lng": -91.8319,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 6872,
    "id": 1840015924
  },
  {
    "city": "Harrisville",
    "city_ascii": "Harrisville",
    "lat": 41.2853,
    "lng": -111.9859,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Utah",
    "capital": "",
    "population": 6872,
    "id": 1840020129
  },
  {
    "city": "Mead",
    "city_ascii": "Mead",
    "lat": 47.7795,
    "lng": -117.35,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 6870,
    "id": 1840023786
  },
  {
    "city": "Oakley",
    "city_ascii": "Oakley",
    "lat": 51.251,
    "lng": -1.1764,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hampshire",
    "capital": "",
    "population": 5086,
    "id": 1826547704
  },
  {
    "city": "Huron",
    "city_ascii": "Huron",
    "lat": 41.3913,
    "lng": -82.5635,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 6869,
    "id": 1840007095
  },
  {
    "city": "Alnwick/Haldimand",
    "city_ascii": "Alnwick/Haldimand",
    "lat": 44.0833,
    "lng": -78.0333,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 6869,
    "id": 1124000698
  },
  {
    "city": "Tabernacle",
    "city_ascii": "Tabernacle",
    "lat": 39.8192,
    "lng": -74.6551,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 6868,
    "id": 1840081612
  },
  {
    "city": "Newbiggin-by-the-Sea",
    "city_ascii": "Newbiggin-by-the-Sea",
    "lat": 55.183,
    "lng": -1.515,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Northumberland",
    "capital": "",
    "population": 6308,
    "id": 1826839407
  },
  {
    "city": "Loomis",
    "city_ascii": "Loomis",
    "lat": 38.8093,
    "lng": -121.1955,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 6866,
    "id": 1840021462
  },
  {
    "city": "Northam",
    "city_ascii": "Northam",
    "lat": -31.6531,
    "lng": 116.6661,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Western Australia",
    "capital": "",
    "population": 6548,
    "id": 1036868267
  },
  {
    "city": "Sawtry",
    "city_ascii": "Sawtry",
    "lat": 52.44,
    "lng": -0.28,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Cambridgeshire",
    "capital": "",
    "population": 6536,
    "id": 1826107710
  },
  {
    "city": "Thorpe",
    "city_ascii": "Thorpe",
    "lat": 51.4069,
    "lng": -0.5354,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Surrey",
    "capital": "",
    "population": 5465,
    "id": 1826815349
  },
  {
    "city": "King",
    "city_ascii": "King",
    "lat": 36.2766,
    "lng": -80.3564,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 6861,
    "id": 1840014431
  },
  {
    "city": "Latimer",
    "city_ascii": "Latimer",
    "lat": 30.4972,
    "lng": -88.8607,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Mississippi",
    "capital": "",
    "population": 6861,
    "id": 1840013936
  },
  {
    "city": "Jackson Township",
    "city_ascii": "Jackson Township",
    "lat": 41.008,
    "lng": -75.3578,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 6860,
    "id": 1840145221
  },
  {
    "city": "Mimoň",
    "city_ascii": "Mimon",
    "lat": 50.6588,
    "lng": 14.7248,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Liberecký Kraj",
    "capital": "",
    "population": 6443,
    "id": 1203640247
  },
  {
    "city": "Herdorf",
    "city_ascii": "Herdorf",
    "lat": 50.7775,
    "lng": 7.9547,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Rhineland-Palatinate",
    "capital": "",
    "population": 6498,
    "id": 1276667010
  },
  {
    "city": "Youngtown",
    "city_ascii": "Youngtown",
    "lat": 33.5846,
    "lng": -112.3047,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 6859,
    "id": 1840022917
  },
  {
    "city": "Russellville",
    "city_ascii": "Russellville",
    "lat": 36.8393,
    "lng": -86.895,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kentucky",
    "capital": "",
    "population": 6858,
    "id": 1840014401
  },
  {
    "city": "North Branch",
    "city_ascii": "North Branch",
    "lat": 45.5137,
    "lng": -92.9601,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 6856,
    "id": 1840007790
  },
  {
    "city": "Milford",
    "city_ascii": "Milford",
    "lat": 39.1699,
    "lng": -84.2811,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 6855,
    "id": 1840003825
  },
  {
    "city": "Spišská Belá",
    "city_ascii": "Spisska Bela",
    "lat": 49.1858,
    "lng": 20.4567,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Prešovský",
    "capital": "",
    "population": 6657,
    "id": 1703218777
  },
  {
    "city": "Kingsland",
    "city_ascii": "Kingsland",
    "lat": 30.6651,
    "lng": -98.4545,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 6853,
    "id": 1840018217
  },
  {
    "city": "Greenbrier",
    "city_ascii": "Greenbrier",
    "lat": 36.4239,
    "lng": -86.7976,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 6852,
    "id": 1840016014
  },
  {
    "city": "Southbourne",
    "city_ascii": "Southbourne",
    "lat": 50.845,
    "lng": -0.9081,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "West Sussex",
    "capital": "",
    "population": 6265,
    "id": 1826709483
  },
  {
    "city": "Kraslice",
    "city_ascii": "Kraslice",
    "lat": 50.3238,
    "lng": 12.5176,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Karlovarský Kraj",
    "capital": "",
    "population": 6767,
    "id": 1203123355
  },
  {
    "city": "Horncastle",
    "city_ascii": "Horncastle",
    "lat": 53.208,
    "lng": -0.114,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Lincolnshire",
    "capital": "",
    "population": 6815,
    "id": 1826336642
  },
  {
    "city": "Lake Mathews",
    "city_ascii": "Lake Mathews",
    "lat": 33.825,
    "lng": -117.3683,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 6848,
    "id": 1840075920
  },
  {
    "city": "Maulbronn",
    "city_ascii": "Maulbronn",
    "lat": 49.0003,
    "lng": 8.8108,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 6588,
    "id": 1276527728
  },
  {
    "city": "Mount Cotton",
    "city_ascii": "Mount Cotton",
    "lat": -27.6188,
    "lng": 153.221,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Queensland",
    "capital": "",
    "population": 6835,
    "id": 1036481380
  },
  {
    "city": "Eldridge",
    "city_ascii": "Eldridge",
    "lat": 41.639,
    "lng": -90.5809,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Iowa",
    "capital": "",
    "population": 6846,
    "id": 1840008182
  },
  {
    "city": "Teiuş",
    "city_ascii": "Teius",
    "lat": 46.2,
    "lng": 23.68,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Alba",
    "capital": "",
    "population": 6695,
    "id": 1642977662
  },
  {
    "city": "Malvern Link",
    "city_ascii": "Malvern Link",
    "lat": 52.1255,
    "lng": -2.3161,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Worcestershire",
    "capital": "",
    "population": 6155,
    "id": 1826437146
  },
  {
    "city": "Upper Mount Bethel",
    "city_ascii": "Upper Mount Bethel",
    "lat": 40.8984,
    "lng": -75.1386,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 6843,
    "id": 1840146262
  },
  {
    "city": "Moapa Valley",
    "city_ascii": "Moapa Valley",
    "lat": 36.6078,
    "lng": -114.4566,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Nevada",
    "capital": "",
    "population": 6843,
    "id": 1840033831
  },
  {
    "city": "Peace River",
    "city_ascii": "Peace River",
    "lat": 56.2339,
    "lng": -117.2897,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Alberta",
    "capital": "",
    "population": 6842,
    "id": 1124941936
  },
  {
    "city": "Sunnyvale",
    "city_ascii": "Sunnyvale",
    "lat": 32.7974,
    "lng": -96.5577,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 6841,
    "id": 1840023031
  },
  {
    "city": "Bee Cave",
    "city_ascii": "Bee Cave",
    "lat": 30.3084,
    "lng": -97.9629,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 6841,
    "id": 1840023142
  },
  {
    "city": "Lucas Valley-Marinwood",
    "city_ascii": "Lucas Valley-Marinwood",
    "lat": 38.0405,
    "lng": -122.5765,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 6841,
    "id": 1840073922
  },
  {
    "city": "Lovejoy",
    "city_ascii": "Lovejoy",
    "lat": 33.4426,
    "lng": -84.3176,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 6840,
    "id": 1840015704
  },
  {
    "city": "Merrimac",
    "city_ascii": "Merrimac",
    "lat": 42.8386,
    "lng": -71.0119,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 6839,
    "id": 1840053533
  },
  {
    "city": "Ács",
    "city_ascii": "Acs",
    "lat": 47.71,
    "lng": 18.0156,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Komárom-Esztergom",
    "capital": "",
    "population": 6771,
    "id": 1348054634
  },
  {
    "city": "Fircrest",
    "city_ascii": "Fircrest",
    "lat": 47.2307,
    "lng": -122.5157,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 6835,
    "id": 1840019854
  },
  {
    "city": "Landore",
    "city_ascii": "Landore",
    "lat": 51.64,
    "lng": -3.94,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Swansea",
    "capital": "",
    "population": 6168,
    "id": 1826893250
  },
  {
    "city": "Altenkirchen",
    "city_ascii": "Altenkirchen",
    "lat": 50.6872,
    "lng": 7.6456,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Rhineland-Palatinate",
    "capital": "minor",
    "population": 6263,
    "id": 1276616587
  },
  {
    "city": "Hainburg an der Donau",
    "city_ascii": "Hainburg an der Donau",
    "lat": 48.1478,
    "lng": 16.9419,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Niederösterreich",
    "capital": "",
    "population": 6570,
    "id": 1040058108
  },
  {
    "city": "Bălan",
    "city_ascii": "Balan",
    "lat": 46.6497,
    "lng": 25.81,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Harghita",
    "capital": "",
    "population": 6115,
    "id": 1642727004
  },
  {
    "city": "Puchezh",
    "city_ascii": "Puchezh",
    "lat": 56.9833,
    "lng": 43.1667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Ivanovskaya Oblast’",
    "capital": "",
    "population": 6813,
    "id": 1643869074
  },
  {
    "city": "Alstonville",
    "city_ascii": "Alstonville",
    "lat": -28.8317,
    "lng": 153.4344,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 5739,
    "id": 1036379241
  },
  {
    "city": "Brixworth",
    "city_ascii": "Brixworth",
    "lat": 52.329,
    "lng": -0.904,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Northamptonshire",
    "capital": "",
    "population": 5162,
    "id": 1826215413
  },
  {
    "city": "Livada",
    "city_ascii": "Livada",
    "lat": 47.8667,
    "lng": 23.1333,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Satu Mare",
    "capital": "",
    "population": 6773,
    "id": 1642793209
  },
  {
    "city": "Clay Township",
    "city_ascii": "Clay Township",
    "lat": 40.2353,
    "lng": -76.2369,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 6831,
    "id": 1840145615
  },
  {
    "city": "Waki",
    "city_ascii": "Waki",
    "lat": 34.2022,
    "lng": 132.2203,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Yamaguchi",
    "capital": "",
    "population": 6238,
    "id": 1392843750
  },
  {
    "city": "Ashoro",
    "city_ascii": "Ashoro",
    "lat": 43.2447,
    "lng": 143.5542,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hokkaidō",
    "capital": "",
    "population": 6822,
    "id": 1392133580
  },
  {
    "city": "Trumann",
    "city_ascii": "Trumann",
    "lat": 35.6771,
    "lng": -90.5261,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arkansas",
    "capital": "",
    "population": 6826,
    "id": 1840015406
  },
  {
    "city": "Mendota",
    "city_ascii": "Mendota",
    "lat": 41.5553,
    "lng": -89.1042,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 6826,
    "id": 1840008199
  },
  {
    "city": "East Earl",
    "city_ascii": "East Earl",
    "lat": 40.1235,
    "lng": -76.0332,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 6825,
    "id": 1840034939
  },
  {
    "city": "Huntertown",
    "city_ascii": "Huntertown",
    "lat": 41.2155,
    "lng": -85.1715,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 6824,
    "id": 1840010297
  },
  {
    "city": "Ocampo",
    "city_ascii": "Ocampo",
    "lat": 22.8444,
    "lng": -99.3358,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Tamaulipas",
    "capital": "minor",
    "population": 5095,
    "id": 1484097897
  },
  {
    "city": "Saint Blazey",
    "city_ascii": "Saint Blazey",
    "lat": 50.361,
    "lng": -4.716,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Cornwall",
    "capital": "",
    "population": 6799,
    "id": 1826386613
  },
  {
    "city": "Crossett",
    "city_ascii": "Crossett",
    "lat": 33.128,
    "lng": -91.9631,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arkansas",
    "capital": "",
    "population": 6820,
    "id": 1840014838
  },
  {
    "city": "Puerto Nariño",
    "city_ascii": "Puerto Narino",
    "lat": -3.7703,
    "lng": -70.3831,
    "country": "Colombia",
    "iso2": "CO",
    "iso3": "COL",
    "admin_name": "Amazonas",
    "capital": "minor",
    "population": 6816,
    "id": 1170109460
  },
  {
    "city": "Waldeck",
    "city_ascii": "Waldeck",
    "lat": 51.2,
    "lng": 9.0667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 6761,
    "id": 1276540962
  },
  {
    "city": "Old Greenwich",
    "city_ascii": "Old Greenwich",
    "lat": 41.0253,
    "lng": -73.5691,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 6819,
    "id": 1840026284
  },
  {
    "city": "Knyaginino",
    "city_ascii": "Knyaginino",
    "lat": 55.8167,
    "lng": 45.0333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Nizhegorodskaya Oblast’",
    "capital": "",
    "population": 6804,
    "id": 1643925479
  },
  {
    "city": "José Pedro Varela",
    "city_ascii": "Jose Pedro Varela",
    "lat": -33.45,
    "lng": -54.5333,
    "country": "Uruguay",
    "iso2": "UY",
    "iso3": "URY",
    "admin_name": "Lavalleja",
    "capital": "",
    "population": 5118,
    "id": 1858511994
  },
  {
    "city": "Baboua",
    "city_ascii": "Baboua",
    "lat": 5.7833,
    "lng": 14.8192,
    "country": "Central African Republic",
    "iso2": "CF",
    "iso3": "CAF",
    "admin_name": "Nana-Mambéré",
    "capital": "",
    "population": 6812,
    "id": 1140184570
  },
  {
    "city": "River Park",
    "city_ascii": "River Park",
    "lat": 27.3214,
    "lng": -80.3307,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 6818,
    "id": 1840028958
  },
  {
    "city": "Santo Antônio do Pinhal",
    "city_ascii": "Santo Antonio do Pinhal",
    "lat": -22.8269,
    "lng": -45.6628,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 6767,
    "id": 1076803118
  },
  {
    "city": "Margaret River",
    "city_ascii": "Margaret River",
    "lat": -33.955,
    "lng": 115.075,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Western Australia",
    "capital": "",
    "population": 6392,
    "id": 1036193817
  },
  {
    "city": "Bermuda Dunes",
    "city_ascii": "Bermuda Dunes",
    "lat": 33.7434,
    "lng": -116.2874,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 6817,
    "id": 1840017981
  },
  {
    "city": "Hoosick",
    "city_ascii": "Hoosick",
    "lat": 42.8903,
    "lng": -73.3561,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 6816,
    "id": 1840023826
  },
  {
    "city": "Mount Vernon",
    "city_ascii": "Mount Vernon",
    "lat": 37.9364,
    "lng": -87.8959,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 6816,
    "id": 1840013884
  },
  {
    "city": "Glen Innes",
    "city_ascii": "Glen Innes",
    "lat": -29.75,
    "lng": 151.7361,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 6155,
    "id": 1036276799
  },
  {
    "city": "Kostenets",
    "city_ascii": "Kostenets",
    "lat": 42.3075,
    "lng": 23.8575,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Sofia",
    "capital": "",
    "population": 6777,
    "id": 1100076482
  },
  {
    "city": "Moores Mill",
    "city_ascii": "Moores Mill",
    "lat": 34.8491,
    "lng": -86.5222,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 6815,
    "id": 1840013547
  },
  {
    "city": "Puerto Aventuras",
    "city_ascii": "Puerto Aventuras",
    "lat": 20.5117,
    "lng": -87.2342,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Quintana Roo",
    "capital": "",
    "population": 5979,
    "id": 1484598759
  },
  {
    "city": "St. Francis",
    "city_ascii": "St. Francis",
    "lat": 45.3991,
    "lng": -93.3902,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 6813,
    "id": 1840008905
  },
  {
    "city": "Lossiemouth",
    "city_ascii": "Lossiemouth",
    "lat": 57.7189,
    "lng": -3.2875,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Moray",
    "capital": "",
    "population": 6803,
    "id": 1826354816
  },
  {
    "city": "Kirtland",
    "city_ascii": "Kirtland",
    "lat": 41.5969,
    "lng": -81.3406,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 6812,
    "id": 1840000535
  },
  {
    "city": "Bishops Waltham",
    "city_ascii": "Bishops Waltham",
    "lat": 50.9536,
    "lng": -1.2118,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hampshire",
    "capital": "",
    "population": 6723,
    "id": 1826586384
  },
  {
    "city": "Shikama",
    "city_ascii": "Shikama",
    "lat": 38.5489,
    "lng": 140.85,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Miyagi",
    "capital": "",
    "population": 6745,
    "id": 1392230009
  },
  {
    "city": "East Buffalo",
    "city_ascii": "East Buffalo",
    "lat": 40.9337,
    "lng": -76.9135,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 6806,
    "id": 1840150969
  },
  {
    "city": "Făget",
    "city_ascii": "Faget",
    "lat": 45.85,
    "lng": 22.18,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Timiş",
    "capital": "",
    "population": 6761,
    "id": 1642792698
  },
  {
    "city": "Yamba",
    "city_ascii": "Yamba",
    "lat": -29.4333,
    "lng": 153.35,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 6076,
    "id": 1036259324
  },
  {
    "city": "Ōmachi",
    "city_ascii": "Omachi",
    "lat": 33.2139,
    "lng": 130.1161,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Saga",
    "capital": "",
    "population": 6261,
    "id": 1392590862
  },
  {
    "city": "Aquia Harbour",
    "city_ascii": "Aquia Harbour",
    "lat": 38.4597,
    "lng": -77.3806,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 6805,
    "id": 1840006216
  },
  {
    "city": "Marion",
    "city_ascii": "Marion",
    "lat": 34.1787,
    "lng": -79.3966,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 6804,
    "id": 1840015595
  },
  {
    "city": "Kristinestad",
    "city_ascii": "Kristinestad",
    "lat": 62.2736,
    "lng": 21.3778,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Pohjanmaa",
    "capital": "minor",
    "population": 6793,
    "id": 1246079000
  },
  {
    "city": "Arran-Elderslie",
    "city_ascii": "Arran-Elderslie",
    "lat": 44.4,
    "lng": -81.2,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 6803,
    "id": 1124001766
  },
  {
    "city": "Kimberly",
    "city_ascii": "Kimberly",
    "lat": 44.267,
    "lng": -88.3377,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 6802,
    "id": 1840002404
  },
  {
    "city": "South Patrick Shores",
    "city_ascii": "South Patrick Shores",
    "lat": 28.202,
    "lng": -80.6137,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 6802,
    "id": 1840029080
  },
  {
    "city": "Taylor Mill",
    "city_ascii": "Taylor Mill",
    "lat": 39.0092,
    "lng": -84.4988,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kentucky",
    "capital": "",
    "population": 6802,
    "id": 1840015175
  },
  {
    "city": "Bani Bangou",
    "city_ascii": "Bani Bangou",
    "lat": 15.0408,
    "lng": 2.705,
    "country": "Niger",
    "iso2": "NE",
    "iso3": "NER",
    "admin_name": "Tillabéri",
    "capital": "",
    "population": 6788,
    "id": 1562021955
  },
  {
    "city": "Ōishida",
    "city_ascii": "Oishida",
    "lat": 38.5939,
    "lng": 140.3728,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Yamagata",
    "capital": "",
    "population": 6713,
    "id": 1392447005
  },
  {
    "city": "Velizh",
    "city_ascii": "Velizh",
    "lat": 55.6,
    "lng": 31.2,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Smolenskaya Oblast’",
    "capital": "",
    "population": 6788,
    "id": 1643700363
  },
  {
    "city": "Perry",
    "city_ascii": "Perry",
    "lat": 30.109,
    "lng": -83.5821,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 6796,
    "id": 1840015046
  },
  {
    "city": "Valley Park",
    "city_ascii": "Valley Park",
    "lat": 38.5513,
    "lng": -90.4924,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 6796,
    "id": 1840010740
  },
  {
    "city": "Moraleja",
    "city_ascii": "Moraleja",
    "lat": 40.0695,
    "lng": -6.6572,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Extremadura",
    "capital": "",
    "population": 6750,
    "id": 1724066264
  },
  {
    "city": "Waldsassen",
    "city_ascii": "Waldsassen",
    "lat": 50,
    "lng": 12.3,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 6694,
    "id": 1276182408
  },
  {
    "city": "Waldmünchen",
    "city_ascii": "Waldmunchen",
    "lat": 49.3785,
    "lng": 12.7049,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 6728,
    "id": 1276963038
  },
  {
    "city": "Natividade da Serra",
    "city_ascii": "Natividade da Serra",
    "lat": -23.3756,
    "lng": -45.4419,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 6786,
    "id": 1076519726
  },
  {
    "city": "Columbus",
    "city_ascii": "Columbus",
    "lat": 43.3354,
    "lng": -89.03,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 6793,
    "id": 1840002708
  },
  {
    "city": "Waterford",
    "city_ascii": "Waterford",
    "lat": -27.6833,
    "lng": 153.133,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Queensland",
    "capital": "",
    "population": 6431,
    "id": 1036727537
  },
  {
    "city": "Mexia",
    "city_ascii": "Mexia",
    "lat": 31.6809,
    "lng": -96.4833,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 6792,
    "id": 1840020822
  },
  {
    "city": "Marvin",
    "city_ascii": "Marvin",
    "lat": 34.989,
    "lng": -80.8032,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 6792,
    "id": 1840017880
  },
  {
    "city": "Heitersheim",
    "city_ascii": "Heitersheim",
    "lat": 47.8753,
    "lng": 7.6547,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 6257,
    "id": 1276661472
  },
  {
    "city": "Gypsum",
    "city_ascii": "Gypsum",
    "lat": 39.6285,
    "lng": -106.9334,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 6790,
    "id": 1840021426
  },
  {
    "city": "Fowler",
    "city_ascii": "Fowler",
    "lat": 36.6243,
    "lng": -119.6737,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 6790,
    "id": 1840020318
  },
  {
    "city": "Kingston",
    "city_ascii": "Kingston",
    "lat": 41.4738,
    "lng": -71.5236,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Rhode Island",
    "capital": "",
    "population": 6789,
    "id": 1840003365
  },
  {
    "city": "Rosice",
    "city_ascii": "Rosice",
    "lat": 49.1824,
    "lng": 16.3879,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Jihomoravský Kraj",
    "capital": "",
    "population": 6291,
    "id": 1203036203
  },
  {
    "city": "Exeter",
    "city_ascii": "Exeter",
    "lat": 41.5658,
    "lng": -71.6308,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Rhode Island",
    "capital": "",
    "population": 6782,
    "id": 1840066134
  },
  {
    "city": "Guánica",
    "city_ascii": "Guanica",
    "lat": 17.9698,
    "lng": -66.9309,
    "country": "Puerto Rico",
    "iso2": "PR",
    "iso3": "PRI",
    "admin_name": "Puerto Rico",
    "capital": "",
    "population": 6781,
    "id": 1630023537
  },
  {
    "city": "Mount Hope",
    "city_ascii": "Mount Hope",
    "lat": 41.46,
    "lng": -74.5281,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 6781,
    "id": 1840058322
  },
  {
    "city": "Lincoln",
    "city_ascii": "Lincoln",
    "lat": 33.5935,
    "lng": -86.1371,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 6781,
    "id": 1840015698
  },
  {
    "city": "Charagua",
    "city_ascii": "Charagua",
    "lat": -19.7919,
    "lng": -63.2006,
    "country": "Bolivia",
    "iso2": "BO",
    "iso3": "BOL",
    "admin_name": "Santa Cruz",
    "capital": "",
    "population": 6780,
    "id": 1068232771
  },
  {
    "city": "Kishkeneköl",
    "city_ascii": "Kishkenekol",
    "lat": 53.6394,
    "lng": 72.3439,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Soltüstik Qazaqstan",
    "capital": "",
    "population": 6779,
    "id": 1398061261
  },
  {
    "city": "Šenov",
    "city_ascii": "Senov",
    "lat": 49.7931,
    "lng": 18.3761,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Moravskoslezský Kraj",
    "capital": "",
    "population": 6393,
    "id": 1203368549
  },
  {
    "city": "Inniswold",
    "city_ascii": "Inniswold",
    "lat": 30.3982,
    "lng": -91.071,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 6777,
    "id": 1840031059
  },
  {
    "city": "Malchow",
    "city_ascii": "Malchow",
    "lat": 53.4833,
    "lng": 12.4333,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Mecklenburg-Western Pomerania",
    "capital": "",
    "population": 6627,
    "id": 1276863765
  },
  {
    "city": "Luftkurort Arendsee",
    "city_ascii": "Luftkurort Arendsee",
    "lat": 52.8767,
    "lng": 11.4867,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony-Anhalt",
    "capital": "",
    "population": 6750,
    "id": 1276924634
  },
  {
    "city": "‘Ayn ‘Īsá",
    "city_ascii": "`Ayn `Isa",
    "lat": 36.3858,
    "lng": 38.8472,
    "country": "Syria",
    "iso2": "SY",
    "iso3": "SYR",
    "admin_name": "Ar Raqqah",
    "capital": "minor",
    "population": 6730,
    "id": 1760078370
  },
  {
    "city": "Ghafsaï",
    "city_ascii": "Ghafsai",
    "lat": 34.6245,
    "lng": -4.9112,
    "country": "Morocco",
    "iso2": "MA",
    "iso3": "MAR",
    "admin_name": "Fès-Meknès",
    "capital": "",
    "population": 6361,
    "id": 1504792927
  },
  {
    "city": "Colombier",
    "city_ascii": "Colombier",
    "lat": 46.9667,
    "lng": 6.8667,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Neuchâtel",
    "capital": "",
    "population": 5547,
    "id": 1756484368
  },
  {
    "city": "Parkano",
    "city_ascii": "Parkano",
    "lat": 62.0097,
    "lng": 23.025,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Pirkanmaa",
    "capital": "minor",
    "population": 6766,
    "id": 1246564244
  },
  {
    "city": "Lutherville",
    "city_ascii": "Lutherville",
    "lat": 39.4239,
    "lng": -76.6176,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 6773,
    "id": 1840026623
  },
  {
    "city": "Saint-Zotique",
    "city_ascii": "Saint-Zotique",
    "lat": 45.25,
    "lng": -74.25,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 6773,
    "id": 1124170824
  },
  {
    "city": "Williamson",
    "city_ascii": "Williamson",
    "lat": 43.2417,
    "lng": -77.1923,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 6772,
    "id": 1840058613
  },
  {
    "city": "Whitmore Lake",
    "city_ascii": "Whitmore Lake",
    "lat": 42.4235,
    "lng": -83.7524,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 6771,
    "id": 1840006923
  },
  {
    "city": "Oleksandrivsk",
    "city_ascii": "Oleksandrivsk",
    "lat": 48.5833,
    "lng": 39.1833,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Luhans’ka Oblast’",
    "capital": "",
    "population": 6709,
    "id": 1804689452
  },
  {
    "city": "East Coventry",
    "city_ascii": "East Coventry",
    "lat": 40.2014,
    "lng": -75.6162,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 6770,
    "id": 1840149886
  },
  {
    "city": "Danbury",
    "city_ascii": "Danbury",
    "lat": 51.715,
    "lng": 0.582,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Essex",
    "capital": "",
    "population": 6500,
    "id": 1826143972
  },
  {
    "city": "Castro Marim",
    "city_ascii": "Castro Marim",
    "lat": 37.2167,
    "lng": -7.45,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Faro",
    "capital": "minor",
    "population": 6747,
    "id": 1620482248
  },
  {
    "city": "Millersville",
    "city_ascii": "Millersville",
    "lat": 36.3968,
    "lng": -86.7111,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 6769,
    "id": 1840014416
  },
  {
    "city": "Glashütte",
    "city_ascii": "Glashutte",
    "lat": 50.85,
    "lng": 13.7833,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 6698,
    "id": 1276110942
  },
  {
    "city": "Cortland",
    "city_ascii": "Cortland",
    "lat": 41.332,
    "lng": -80.7195,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 6768,
    "id": 1840007128
  },
  {
    "city": "South Hanover",
    "city_ascii": "South Hanover",
    "lat": 40.2962,
    "lng": -76.7085,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 6766,
    "id": 1840149544
  },
  {
    "city": "Upper Nazareth",
    "city_ascii": "Upper Nazareth",
    "lat": 40.7369,
    "lng": -75.3346,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 6764,
    "id": 1840150357
  },
  {
    "city": "Mitterteich",
    "city_ascii": "Mitterteich",
    "lat": 49.9498,
    "lng": 12.2442,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 6596,
    "id": 1276213104
  },
  {
    "city": "Lexington",
    "city_ascii": "Lexington",
    "lat": 35.6618,
    "lng": -88.3945,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 6763,
    "id": 1840014527
  },
  {
    "city": "Annaburg",
    "city_ascii": "Annaburg",
    "lat": 51.7328,
    "lng": 13.0456,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony-Anhalt",
    "capital": "",
    "population": 6731,
    "id": 1276926714
  },
  {
    "city": "Santo Antônio da Alegria",
    "city_ascii": "Santo Antonio da Alegria",
    "lat": -21.0869,
    "lng": -47.1508,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 6739,
    "id": 1076357734
  },
  {
    "city": "Ibrány",
    "city_ascii": "Ibrany",
    "lat": 48.1282,
    "lng": 21.7135,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Szabolcs-Szatmár-Bereg",
    "capital": "minor",
    "population": 6648,
    "id": 1348097537
  },
  {
    "city": "New Hartford",
    "city_ascii": "New Hartford",
    "lat": 41.8442,
    "lng": -73.0055,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 6755,
    "id": 1840045050
  },
  {
    "city": "Cranbrook",
    "city_ascii": "Cranbrook",
    "lat": 51.0967,
    "lng": 0.5356,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Kent",
    "capital": "",
    "population": 6717,
    "id": 1826747115
  },
  {
    "city": "Medzilaborce",
    "city_ascii": "Medzilaborce",
    "lat": 49.2717,
    "lng": 21.9042,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Prešovský",
    "capital": "minor",
    "population": 6612,
    "id": 1703717688
  },
  {
    "city": "Bullskin",
    "city_ascii": "Bullskin",
    "lat": 40.0803,
    "lng": -79.5124,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 6751,
    "id": 1840147205
  },
  {
    "city": "Pembroke Park",
    "city_ascii": "Pembroke Park",
    "lat": 25.9852,
    "lng": -80.1777,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 6749,
    "id": 1840017273
  },
  {
    "city": "Groveland",
    "city_ascii": "Groveland",
    "lat": 42.752,
    "lng": -71.0149,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 6749,
    "id": 1840053530
  },
  {
    "city": "Sobrance",
    "city_ascii": "Sobrance",
    "lat": 48.746,
    "lng": 22.179,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Košický",
    "capital": "minor",
    "population": 6170,
    "id": 1703777027
  },
  {
    "city": "Lake Hallie",
    "city_ascii": "Lake Hallie",
    "lat": 44.8921,
    "lng": -91.4199,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 6747,
    "id": 1840000279
  },
  {
    "city": "Ellettsville",
    "city_ascii": "Ellettsville",
    "lat": 39.2322,
    "lng": -86.6232,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 6747,
    "id": 1840010665
  },
  {
    "city": "Güglingen",
    "city_ascii": "Guglingen",
    "lat": 49.0667,
    "lng": 9,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 6353,
    "id": 1276744847
  },
  {
    "city": "Gūged",
    "city_ascii": "Guged",
    "lat": 33.4756,
    "lng": 50.3525,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Eşfahān",
    "capital": "",
    "population": 6686,
    "id": 1364583429
  },
  {
    "city": "Tvarditsa",
    "city_ascii": "Tvarditsa",
    "lat": 42.7017,
    "lng": 25.8975,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Sliven",
    "capital": "",
    "population": 6711,
    "id": 1100703668
  },
  {
    "city": "Gorom-Gorom",
    "city_ascii": "Gorom-Gorom",
    "lat": 14.45,
    "lng": -0.2333,
    "country": "Burkina Faso",
    "iso2": "BF",
    "iso3": "BFA",
    "admin_name": "Sahel",
    "capital": "minor",
    "population": 6691,
    "id": 1854609702
  },
  {
    "city": "Montevallo",
    "city_ascii": "Montevallo",
    "lat": 33.1246,
    "lng": -86.8476,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 6738,
    "id": 1840014825
  },
  {
    "city": "Kimberley",
    "city_ascii": "Kimberley",
    "lat": 52.997,
    "lng": -1.255,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Nottinghamshire",
    "capital": "",
    "population": 6659,
    "id": 1826282994
  },
  {
    "city": "Hanover",
    "city_ascii": "Hanover",
    "lat": 42.4922,
    "lng": -79.1253,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 6737,
    "id": 1840058164
  },
  {
    "city": "Countesthorpe",
    "city_ascii": "Countesthorpe",
    "lat": 52.555,
    "lng": -1.14,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Leicestershire",
    "capital": "",
    "population": 6377,
    "id": 1826108290
  },
  {
    "city": "Mount Healthy",
    "city_ascii": "Mount Healthy",
    "lat": 39.2338,
    "lng": -84.5469,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 6736,
    "id": 1840001607
  },
  {
    "city": "Enying",
    "city_ascii": "Enying",
    "lat": 46.9296,
    "lng": 18.243,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Fejér",
    "capital": "minor",
    "population": 6655,
    "id": 1348527259
  },
  {
    "city": "Allendale",
    "city_ascii": "Allendale",
    "lat": 41.0333,
    "lng": -74.1333,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 6734,
    "id": 1840003550
  },
  {
    "city": "Snowflake",
    "city_ascii": "Snowflake",
    "lat": 34.5225,
    "lng": -110.0913,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 6733,
    "id": 1840022572
  },
  {
    "city": "Rupert",
    "city_ascii": "Rupert",
    "lat": 42.6189,
    "lng": -113.674,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Idaho",
    "capital": "",
    "population": 6733,
    "id": 1840020066
  },
  {
    "city": "Bayville",
    "city_ascii": "Bayville",
    "lat": 40.9076,
    "lng": -73.5602,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 6732,
    "id": 1840005336
  },
  {
    "city": "Perechyn",
    "city_ascii": "Perechyn",
    "lat": 48.7333,
    "lng": 22.4667,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Zakarpats’ka Oblast’",
    "capital": "minor",
    "population": 6683,
    "id": 1804641604
  },
  {
    "city": "Plymouth",
    "city_ascii": "Plymouth",
    "lat": 43.7438,
    "lng": -71.7221,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Hampshire",
    "capital": "",
    "population": 6731,
    "id": 1840055417
  },
  {
    "city": "Litchfield",
    "city_ascii": "Litchfield",
    "lat": 39.1959,
    "lng": -89.6295,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 6731,
    "id": 1840009601
  },
  {
    "city": "Boxberg",
    "city_ascii": "Boxberg",
    "lat": 49.4814,
    "lng": 9.6417,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 6664,
    "id": 1276977191
  },
  {
    "city": "Lincoln",
    "city_ascii": "Lincoln",
    "lat": 42.4266,
    "lng": -71.3086,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 6726,
    "id": 1840053639
  },
  {
    "city": "Washington Township",
    "city_ascii": "Washington Township",
    "lat": 40.7389,
    "lng": -75.6392,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 6725,
    "id": 1840146592
  },
  {
    "city": "Kamennogorsk",
    "city_ascii": "Kamennogorsk",
    "lat": 60.95,
    "lng": 29.1333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Leningradskaya Oblast’",
    "capital": "",
    "population": 6696,
    "id": 1643097170
  },
  {
    "city": "Kiama Downs",
    "city_ascii": "Kiama Downs",
    "lat": -34.6317,
    "lng": 150.8511,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 5004,
    "id": 1036560277
  },
  {
    "city": "Blatná",
    "city_ascii": "Blatna",
    "lat": 49.425,
    "lng": 13.8818,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Jihočeský Kraj",
    "capital": "",
    "population": 6573,
    "id": 1203608765
  },
  {
    "city": "Bulkington",
    "city_ascii": "Bulkington",
    "lat": 52.4769,
    "lng": -1.425,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Warwickshire",
    "capital": "",
    "population": 6146,
    "id": 1826006235
  },
  {
    "city": "Lacy-Lakeview",
    "city_ascii": "Lacy-Lakeview",
    "lat": 31.6292,
    "lng": -97.1052,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 6721,
    "id": 1840020818
  },
  {
    "city": "Bad Brückenau",
    "city_ascii": "Bad Bruckenau",
    "lat": 50.3091,
    "lng": 9.7871,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 6449,
    "id": 1276227003
  },
  {
    "city": "Green Knoll",
    "city_ascii": "Green Knoll",
    "lat": 40.6048,
    "lng": -74.615,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 6720,
    "id": 1840033460
  },
  {
    "city": "Sitka",
    "city_ascii": "Sitka",
    "lat": 57.2401,
    "lng": -135.3153,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alaska",
    "capital": "",
    "population": 6720,
    "id": 1840023319
  },
  {
    "city": "Roaring Spring",
    "city_ascii": "Roaring Spring",
    "lat": 40.3348,
    "lng": -78.3958,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 6718,
    "id": 1840001086
  },
  {
    "city": "Woodfin",
    "city_ascii": "Woodfin",
    "lat": 35.6458,
    "lng": -82.5914,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 6717,
    "id": 1840016285
  },
  {
    "city": "Tanvald",
    "city_ascii": "Tanvald",
    "lat": 50.7374,
    "lng": 15.306,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Liberecký Kraj",
    "capital": "",
    "population": 6216,
    "id": 1203752278
  },
  {
    "city": "Rumson",
    "city_ascii": "Rumson",
    "lat": 40.3626,
    "lng": -74.0046,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 6714,
    "id": 1840001360
  },
  {
    "city": "Homeacre-Lyndora",
    "city_ascii": "Homeacre-Lyndora",
    "lat": 40.8721,
    "lng": -79.9211,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 6714,
    "id": 1840073691
  },
  {
    "city": "Bāzargān",
    "city_ascii": "Bazargan",
    "lat": 39.3914,
    "lng": 44.3875,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Āz̄arbāyjān-e Gharbī",
    "capital": "",
    "population": 6686,
    "id": 1364598473
  },
  {
    "city": "Broadway",
    "city_ascii": "Broadway",
    "lat": 38.6083,
    "lng": -78.8016,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 6712,
    "id": 1840006136
  },
  {
    "city": "Medina",
    "city_ascii": "Medina",
    "lat": 45.0326,
    "lng": -93.5834,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 6712,
    "id": 1840007827
  },
  {
    "city": "Val-Shefford",
    "city_ascii": "Val-Shefford",
    "lat": 45.35,
    "lng": -72.5667,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 6711,
    "id": 1124787548
  },
  {
    "city": "Byron Center",
    "city_ascii": "Byron Center",
    "lat": 42.8121,
    "lng": -85.7279,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 6710,
    "id": 1840004306
  },
  {
    "city": "Douro-Dummer",
    "city_ascii": "Douro-Dummer",
    "lat": 44.45,
    "lng": -78.1,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 6709,
    "id": 1124001679
  },
  {
    "city": "Frohnleiten",
    "city_ascii": "Frohnleiten",
    "lat": 47.2703,
    "lng": 15.3244,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Steiermark",
    "capital": "",
    "population": 6655,
    "id": 1040237570
  },
  {
    "city": "Ilshofen",
    "city_ascii": "Ilshofen",
    "lat": 49.1703,
    "lng": 9.9203,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 6584,
    "id": 1276896546
  },
  {
    "city": "Santa Lucía Monte Verde",
    "city_ascii": "Santa Lucia Monte Verde",
    "lat": 16.95,
    "lng": -97.6667,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Oaxaca",
    "capital": "",
    "population": 6398,
    "id": 1484105891
  },
  {
    "city": "Hiroo",
    "city_ascii": "Hiroo",
    "lat": 42.2861,
    "lng": 143.3117,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hokkaidō",
    "capital": "",
    "population": 6694,
    "id": 1392437281
  },
  {
    "city": "Pottsville",
    "city_ascii": "Pottsville",
    "lat": -28.3869,
    "lng": 153.5608,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 6704,
    "id": 1036001080
  },
  {
    "city": "Thermalito",
    "city_ascii": "Thermalito",
    "lat": 39.4909,
    "lng": -121.615,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 6704,
    "id": 1840018780
  },
  {
    "city": "Tunkhannock Township",
    "city_ascii": "Tunkhannock Township",
    "lat": 41.0447,
    "lng": -75.4813,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 6703,
    "id": 1840144162
  },
  {
    "city": "Polmont",
    "city_ascii": "Polmont",
    "lat": 55.9908,
    "lng": -3.7064,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Falkirk",
    "capital": "",
    "population": 5321,
    "id": 1826903991
  },
  {
    "city": "Hermeskeil",
    "city_ascii": "Hermeskeil",
    "lat": 49.6572,
    "lng": 6.9489,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Rhineland-Palatinate",
    "capital": "",
    "population": 6492,
    "id": 1276049927
  },
  {
    "city": "Bučovice",
    "city_ascii": "Bucovice",
    "lat": 49.149,
    "lng": 17.0019,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Jihomoravský Kraj",
    "capital": "",
    "population": 6493,
    "id": 1203405541
  },
  {
    "city": "New Alresford",
    "city_ascii": "New Alresford",
    "lat": 51.0907,
    "lng": -1.1612,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hampshire",
    "capital": "",
    "population": 5431,
    "id": 1826791679
  },
  {
    "city": "Wilhelmsburg",
    "city_ascii": "Wilhelmsburg",
    "lat": 48.1108,
    "lng": 15.61,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Niederösterreich",
    "capital": "",
    "population": 6557,
    "id": 1040716919
  },
  {
    "city": "Parkwood",
    "city_ascii": "Parkwood",
    "lat": 47.5266,
    "lng": -122.5986,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 6700,
    "id": 1840037518
  },
  {
    "city": "Olney",
    "city_ascii": "Olney",
    "lat": 52.1529,
    "lng": -0.7015,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Milton Keynes",
    "capital": "",
    "population": 6477,
    "id": 1826238780
  },
  {
    "city": "Horstmar",
    "city_ascii": "Horstmar",
    "lat": 52.0806,
    "lng": 7.3083,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 6551,
    "id": 1276771431
  },
  {
    "city": "Dahlonega",
    "city_ascii": "Dahlonega",
    "lat": 34.5309,
    "lng": -83.9804,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 6696,
    "id": 1840014672
  },
  {
    "city": "Lityn",
    "city_ascii": "Lityn",
    "lat": 49.3255,
    "lng": 28.0872,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Vinnyts’ka Oblast’",
    "capital": "minor",
    "population": 6658,
    "id": 1804831001
  },
  {
    "city": "Green Hill",
    "city_ascii": "Green Hill",
    "lat": 36.2349,
    "lng": -86.5733,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 6695,
    "id": 1840013350
  },
  {
    "city": "Sobernheim",
    "city_ascii": "Sobernheim",
    "lat": 49.7872,
    "lng": 7.6528,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Rhineland-Palatinate",
    "capital": "",
    "population": 6573,
    "id": 1276431612
  },
  {
    "city": "Staryy Sambir",
    "city_ascii": "Staryy Sambir",
    "lat": 49.4333,
    "lng": 23,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "L’vivs’ka Oblast’",
    "capital": "minor",
    "population": 6630,
    "id": 1804927692
  },
  {
    "city": "Chinchilla",
    "city_ascii": "Chinchilla",
    "lat": -26.7397,
    "lng": 150.625,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Queensland",
    "capital": "",
    "population": 6612,
    "id": 1036824282
  },
  {
    "city": "Rinconada",
    "city_ascii": "Rinconada",
    "lat": -22.4403,
    "lng": -66.1672,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Jujuy",
    "capital": "minor",
    "population": 6692,
    "id": 1032429190
  },
  {
    "city": "Roeland Park",
    "city_ascii": "Roeland Park",
    "lat": 39.0358,
    "lng": -94.6374,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kansas",
    "capital": "",
    "population": 6688,
    "id": 1840001640
  },
  {
    "city": "Plessisville",
    "city_ascii": "Plessisville",
    "lat": 46.2167,
    "lng": -71.7833,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 6688,
    "id": 1124223899
  },
  {
    "city": "Horn",
    "city_ascii": "Horn",
    "lat": 48.6653,
    "lng": 15.6558,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Niederösterreich",
    "capital": "minor",
    "population": 6520,
    "id": 1040255352
  },
  {
    "city": "Dayton",
    "city_ascii": "Dayton",
    "lat": 45.1906,
    "lng": -93.4758,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 6686,
    "id": 1840006750
  },
  {
    "city": "Taneytown",
    "city_ascii": "Taneytown",
    "lat": 39.657,
    "lng": -77.1683,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 6685,
    "id": 1840005700
  },
  {
    "city": "Odolena Voda",
    "city_ascii": "Odolena Voda",
    "lat": 50.2335,
    "lng": 14.4109,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Středočeský Kraj",
    "capital": "",
    "population": 6136,
    "id": 1203374878
  },
  {
    "city": "Cherryville",
    "city_ascii": "Cherryville",
    "lat": 35.3844,
    "lng": -81.3781,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 6682,
    "id": 1840014589
  },
  {
    "city": "East Porterville",
    "city_ascii": "East Porterville",
    "lat": 36.0573,
    "lng": -118.9713,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 6679,
    "id": 1840017691
  },
  {
    "city": "Berkley",
    "city_ascii": "Berkley",
    "lat": 41.8349,
    "lng": -71.0754,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 6679,
    "id": 1840053659
  },
  {
    "city": "Ritchot",
    "city_ascii": "Ritchot",
    "lat": 49.6647,
    "lng": -97.1167,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Manitoba",
    "capital": "",
    "population": 6679,
    "id": 1124001990
  },
  {
    "city": "Putnok",
    "city_ascii": "Putnok",
    "lat": 48.2936,
    "lng": 20.4367,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Borsod-Abaúj-Zemplén",
    "capital": "minor",
    "population": 6492,
    "id": 1348320550
  },
  {
    "city": "Letohrad",
    "city_ascii": "Letohrad",
    "lat": 50.0358,
    "lng": 16.4988,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Pardubický Kraj",
    "capital": "",
    "population": 6406,
    "id": 1203689778
  },
  {
    "city": "Fishguard",
    "city_ascii": "Fishguard",
    "lat": 51.9982,
    "lng": -4.9804,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Pembrokeshire",
    "capital": "",
    "population": 5407,
    "id": 1826372140
  },
  {
    "city": "Sevsk",
    "city_ascii": "Sevsk",
    "lat": 52.15,
    "lng": 34.4939,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Bryanskaya Oblast’",
    "capital": "",
    "population": 6663,
    "id": 1643806776
  },
  {
    "city": "Lysterfield",
    "city_ascii": "Lysterfield",
    "lat": -37.9333,
    "lng": 145.3,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Victoria",
    "capital": "",
    "population": 6663,
    "id": 1036662782
  },
  {
    "city": "Viitasaari",
    "city_ascii": "Viitasaari",
    "lat": 63.075,
    "lng": 25.8597,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Keski-Suomi",
    "capital": "minor",
    "population": 6666,
    "id": 1246824064
  },
  {
    "city": "Crystal Lake",
    "city_ascii": "Crystal Lake",
    "lat": 28.0371,
    "lng": -81.9063,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 6671,
    "id": 1840029030
  },
  {
    "city": "Savage",
    "city_ascii": "Savage",
    "lat": 39.1485,
    "lng": -76.8228,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 6671,
    "id": 1840026664
  },
  {
    "city": "Mindszent",
    "city_ascii": "Mindszent",
    "lat": 46.525,
    "lng": 20.185,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Csongrád",
    "capital": "",
    "population": 6560,
    "id": 1348905539
  },
  {
    "city": "Arnstedt",
    "city_ascii": "Arnstedt",
    "lat": 51.6833,
    "lng": 11.4667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony-Anhalt",
    "capital": "",
    "population": 6616,
    "id": 1276201188
  },
  {
    "city": "West Point",
    "city_ascii": "West Point",
    "lat": 41.3642,
    "lng": -74.0118,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 6670,
    "id": 1840004866
  },
  {
    "city": "Otonabee-South Monaghan",
    "city_ascii": "Otonabee-South Monaghan",
    "lat": 44.2333,
    "lng": -78.2333,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 6670,
    "id": 1124000517
  },
  {
    "city": "Schladming",
    "city_ascii": "Schladming",
    "lat": 47.3942,
    "lng": 13.6892,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Steiermark",
    "capital": "",
    "population": 6660,
    "id": 1040957044
  },
  {
    "city": "Zierenberg",
    "city_ascii": "Zierenberg",
    "lat": 51.3667,
    "lng": 9.3,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 6592,
    "id": 1276833722
  },
  {
    "city": "Neuenstein",
    "city_ascii": "Neuenstein",
    "lat": 49.2,
    "lng": 9.5833,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 6531,
    "id": 1276191875
  },
  {
    "city": "South Molton",
    "city_ascii": "South Molton",
    "lat": 51.02,
    "lng": -3.83,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Devon",
    "capital": "",
    "population": 5108,
    "id": 1826908469
  },
  {
    "city": "Garden Home-Whitford",
    "city_ascii": "Garden Home-Whitford",
    "lat": 45.4642,
    "lng": -122.7589,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 6666,
    "id": 1840073958
  },
  {
    "city": "Guarantã",
    "city_ascii": "Guaranta",
    "lat": -21.895,
    "lng": -49.5897,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 6651,
    "id": 1076910114
  },
  {
    "city": "Ar Rawḑah",
    "city_ascii": "Ar Rawdah",
    "lat": 14.48,
    "lng": 47.27,
    "country": "Yemen",
    "iso2": "YE",
    "iso3": "YEM",
    "admin_name": "Shabwah",
    "capital": "minor",
    "population": 6649,
    "id": 1887271386
  },
  {
    "city": "Church Hill",
    "city_ascii": "Church Hill",
    "lat": 36.5204,
    "lng": -82.715,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 6664,
    "id": 1840014428
  },
  {
    "city": "Shediac",
    "city_ascii": "Shediac",
    "lat": 46.2167,
    "lng": -64.5333,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "New Brunswick",
    "capital": "",
    "population": 6664,
    "id": 1124770042
  },
  {
    "city": "Neuffen",
    "city_ascii": "Neuffen",
    "lat": 48.5544,
    "lng": 9.3756,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 6299,
    "id": 1276718584
  },
  {
    "city": "Belle Plaine",
    "city_ascii": "Belle Plaine",
    "lat": 44.6188,
    "lng": -93.7643,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 6660,
    "id": 1840006775
  },
  {
    "city": "Cooranbong",
    "city_ascii": "Cooranbong",
    "lat": -33.074,
    "lng": 151.451,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 5449,
    "id": 1036555720
  },
  {
    "city": "Illogan",
    "city_ascii": "Illogan",
    "lat": 50.25,
    "lng": -5.268,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Cornwall",
    "capital": "",
    "population": 5404,
    "id": 1826961434
  },
  {
    "city": "Straldzha",
    "city_ascii": "Straldzha",
    "lat": 42.6,
    "lng": 26.6833,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Yambol",
    "capital": "",
    "population": 6635,
    "id": 1100976940
  },
  {
    "city": "Hopefield",
    "city_ascii": "Hopefield",
    "lat": -33.0667,
    "lng": 18.35,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "Western Cape",
    "capital": "",
    "population": 6460,
    "id": 1710210038
  },
  {
    "city": "Linwood",
    "city_ascii": "Linwood",
    "lat": 39.3436,
    "lng": -74.5708,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 6658,
    "id": 1840016701
  },
  {
    "city": "Ashville",
    "city_ascii": "Ashville",
    "lat": 39.7239,
    "lng": -82.9575,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 6658,
    "id": 1840012552
  },
  {
    "city": "Wirksworth",
    "city_ascii": "Wirksworth",
    "lat": 53.082,
    "lng": -1.574,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Derbyshire",
    "capital": "",
    "population": 5038,
    "id": 1826425467
  },
  {
    "city": "Ventnor",
    "city_ascii": "Ventnor",
    "lat": 50.5976,
    "lng": -1.2084,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Isle of Wight",
    "capital": "",
    "population": 5976,
    "id": 1826088502
  },
  {
    "city": "Groesbeck",
    "city_ascii": "Groesbeck",
    "lat": 39.2292,
    "lng": -84.5964,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 6656,
    "id": 1840005878
  },
  {
    "city": "Sandersville",
    "city_ascii": "Sandersville",
    "lat": 32.9827,
    "lng": -82.8089,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 6655,
    "id": 1840015746
  },
  {
    "city": "Colnbrook",
    "city_ascii": "Colnbrook",
    "lat": 51.4835,
    "lng": -0.5221,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Slough",
    "capital": "",
    "population": 6157,
    "id": 1826455512
  },
  {
    "city": "Blairsville",
    "city_ascii": "Blairsville",
    "lat": 40.4325,
    "lng": -79.2599,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 6654,
    "id": 1840000990
  },
  {
    "city": "Haparanda",
    "city_ascii": "Haparanda",
    "lat": 65.8342,
    "lng": 24.117,
    "country": "Sweden",
    "iso2": "SE",
    "iso3": "SWE",
    "admin_name": "Norrbotten",
    "capital": "minor",
    "population": 6642,
    "id": 1752528595
  },
  {
    "city": "Hunters Quay",
    "city_ascii": "Hunters Quay",
    "lat": 55.9707,
    "lng": -4.9116,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Argyll and Bute",
    "capital": "",
    "population": 5198,
    "id": 1826631598
  },
  {
    "city": "Sturtevant",
    "city_ascii": "Sturtevant",
    "lat": 42.6995,
    "lng": -87.9019,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 6652,
    "id": 1840002486
  },
  {
    "city": "Nyírtelek",
    "city_ascii": "Nyirtelek",
    "lat": 48.0094,
    "lng": 21.6322,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Szabolcs-Szatmár-Bereg",
    "capital": "",
    "population": 6555,
    "id": 1348588074
  },
  {
    "city": "Slave Lake",
    "city_ascii": "Slave Lake",
    "lat": 55.2853,
    "lng": -114.7706,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Alberta",
    "capital": "",
    "population": 6651,
    "id": 1124106662
  },
  {
    "city": "Port-Cartier",
    "city_ascii": "Port-Cartier",
    "lat": 50.0333,
    "lng": -66.8667,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 6651,
    "id": 1124795368
  },
  {
    "city": "Cherry Hills Village",
    "city_ascii": "Cherry Hills Village",
    "lat": 39.6375,
    "lng": -104.9481,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 6647,
    "id": 1840018793
  },
  {
    "city": "Soúda",
    "city_ascii": "Souda",
    "lat": 35.4833,
    "lng": 24.0667,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Kríti",
    "capital": "",
    "population": 6358,
    "id": 1300494450
  },
  {
    "city": "Shimonita",
    "city_ascii": "Shimonita",
    "lat": 36.2125,
    "lng": 138.7892,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Gunma",
    "capital": "",
    "population": 6611,
    "id": 1392945278
  },
  {
    "city": "Barrington",
    "city_ascii": "Barrington",
    "lat": 43.5646,
    "lng": -65.5639,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Nova Scotia",
    "capital": "",
    "population": 6646,
    "id": 1124548310
  },
  {
    "city": "Kozármisleny",
    "city_ascii": "Kozarmisleny",
    "lat": 46.0289,
    "lng": 18.2925,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Baranya",
    "capital": "",
    "population": 6215,
    "id": 1348357791
  },
  {
    "city": "La Moncada",
    "city_ascii": "La Moncada",
    "lat": 20.2667,
    "lng": -100.8,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Guanajuato",
    "capital": "",
    "population": 5100,
    "id": 1484000155
  },
  {
    "city": "Osterburken",
    "city_ascii": "Osterburken",
    "lat": 49.4308,
    "lng": 9.4261,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 6507,
    "id": 1276012874
  },
  {
    "city": "Světlá nad Sázavou",
    "city_ascii": "Svetla nad Sazavou",
    "lat": 49.6681,
    "lng": 15.404,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Vysočina",
    "capital": "",
    "population": 6490,
    "id": 1203996580
  },
  {
    "city": "Barrington",
    "city_ascii": "Barrington",
    "lat": 39.8689,
    "lng": -75.0514,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 6642,
    "id": 1840003771
  },
  {
    "city": "Pendleton",
    "city_ascii": "Pendleton",
    "lat": 43.1015,
    "lng": -78.7664,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 6642,
    "id": 1840024044
  },
  {
    "city": "Tecumseh",
    "city_ascii": "Tecumseh",
    "lat": 35.2639,
    "lng": -96.9338,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oklahoma",
    "capital": "",
    "population": 6636,
    "id": 1840021786
  },
  {
    "city": "Rocky Mountain House",
    "city_ascii": "Rocky Mountain House",
    "lat": 52.3753,
    "lng": -114.9217,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Alberta",
    "capital": "",
    "population": 6635,
    "id": 1124203206
  },
  {
    "city": "Dubňany",
    "city_ascii": "Dubnany",
    "lat": 48.917,
    "lng": 17.09,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Jihomoravský Kraj",
    "capital": "",
    "population": 6352,
    "id": 1203206938
  },
  {
    "city": "Air Force Academy",
    "city_ascii": "Air Force Academy",
    "lat": 38.9942,
    "lng": -104.8639,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 6633,
    "id": 1840036508
  },
  {
    "city": "Kounoupidianá",
    "city_ascii": "Kounoupidiana",
    "lat": 35.536,
    "lng": 24.076,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Kríti",
    "capital": "",
    "population": 6334,
    "id": 1300694718
  },
  {
    "city": "Creel",
    "city_ascii": "Creel",
    "lat": 27.7522,
    "lng": -107.6347,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Chihuahua",
    "capital": "",
    "population": 5026,
    "id": 1484888276
  },
  {
    "city": "Tsiolkovskiy",
    "city_ascii": "Tsiolkovskiy",
    "lat": 51.7603,
    "lng": 128.1212,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Amurskaya Oblast’",
    "capital": "",
    "population": 6535,
    "id": 1643778071
  },
  {
    "city": "Chatsworth",
    "city_ascii": "Chatsworth",
    "lat": 44.38,
    "lng": -80.87,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 6630,
    "id": 1124525225
  },
  {
    "city": "Belmont",
    "city_ascii": "Belmont",
    "lat": 39.065,
    "lng": -77.4965,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 6629,
    "id": 1840024496
  },
  {
    "city": "North Syracuse",
    "city_ascii": "North Syracuse",
    "lat": 43.1339,
    "lng": -76.1306,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 6628,
    "id": 1840004326
  },
  {
    "city": "Veneta",
    "city_ascii": "Veneta",
    "lat": 44.0471,
    "lng": -123.3514,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 6628,
    "id": 1840021264
  },
  {
    "city": "Uffenheim",
    "city_ascii": "Uffenheim",
    "lat": 49.5333,
    "lng": 10.25,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 6518,
    "id": 1276305335
  },
  {
    "city": "Dalton",
    "city_ascii": "Dalton",
    "lat": 42.4795,
    "lng": -73.1533,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 6625,
    "id": 1840053646
  },
  {
    "city": "Ghadāmis",
    "city_ascii": "Ghadamis",
    "lat": 30.1333,
    "lng": 9.5,
    "country": "Libya",
    "iso2": "LY",
    "iso3": "LBY",
    "admin_name": "Nālūt",
    "capital": "",
    "population": 6623,
    "id": 1434013321
  },
  {
    "city": "Monte Águila",
    "city_ascii": "Monte Aguila",
    "lat": -37.0879,
    "lng": -72.4385,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Biobío",
    "capital": "",
    "population": 6574,
    "id": 1152417424
  },
  {
    "city": "Stephenville",
    "city_ascii": "Stephenville",
    "lat": 48.55,
    "lng": -58.5667,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Newfoundland and Labrador",
    "capital": "",
    "population": 6623,
    "id": 1124000201
  },
  {
    "city": "Battlefield",
    "city_ascii": "Battlefield",
    "lat": 37.1194,
    "lng": -93.3683,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 6622,
    "id": 1840007616
  },
  {
    "city": "East Leake",
    "city_ascii": "East Leake",
    "lat": 52.832,
    "lng": -1.177,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Nottinghamshire",
    "capital": "",
    "population": 6337,
    "id": 1826041730
  },
  {
    "city": "North Caldwell",
    "city_ascii": "North Caldwell",
    "lat": 40.8629,
    "lng": -74.2576,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 6621,
    "id": 1840002793
  },
  {
    "city": "Beaver Dam",
    "city_ascii": "Beaver Dam",
    "lat": 37.4042,
    "lng": -86.8742,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kentucky",
    "capital": "",
    "population": 6621,
    "id": 1840013232
  },
  {
    "city": "Myers Corner",
    "city_ascii": "Myers Corner",
    "lat": 41.5947,
    "lng": -73.8744,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 6620,
    "id": 1840004742
  },
  {
    "city": "Stewarton",
    "city_ascii": "Stewarton",
    "lat": 55.68,
    "lng": -4.515,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "East Ayrshire",
    "capital": "",
    "population": 6584,
    "id": 1826224149
  },
  {
    "city": "Winthrop Harbor",
    "city_ascii": "Winthrop Harbor",
    "lat": 42.4805,
    "lng": -87.8294,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 6618,
    "id": 1840011178
  },
  {
    "city": "Market Weighton",
    "city_ascii": "Market Weighton",
    "lat": 53.8642,
    "lng": -0.6624,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "East Riding of Yorkshire",
    "capital": "",
    "population": 6429,
    "id": 1826471742
  },
  {
    "city": "Scriba",
    "city_ascii": "Scriba",
    "lat": 43.4599,
    "lng": -76.4186,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 6616,
    "id": 1840058482
  },
  {
    "city": "Hillsboro",
    "city_ascii": "Hillsboro",
    "lat": 39.2123,
    "lng": -83.6112,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 6616,
    "id": 1840007375
  },
  {
    "city": "Brewton",
    "city_ascii": "Brewton",
    "lat": 31.1111,
    "lng": -87.0737,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 6615,
    "id": 1840006146
  },
  {
    "city": "Maple Glen",
    "city_ascii": "Maple Glen",
    "lat": 40.1778,
    "lng": -75.1793,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 6614,
    "id": 1840035082
  },
  {
    "city": "Tamanredjo",
    "city_ascii": "Tamanredjo",
    "lat": 5.7667,
    "lng": -55,
    "country": "Suriname",
    "iso2": "SR",
    "iso3": "SUR",
    "admin_name": "Commewijne",
    "capital": "",
    "population": 6601,
    "id": 1740708668
  },
  {
    "city": "Anávyssos",
    "city_ascii": "Anavyssos",
    "lat": 37.7333,
    "lng": 23.95,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Attikí",
    "capital": "",
    "population": 6202,
    "id": 1300646410
  },
  {
    "city": "Orlov",
    "city_ascii": "Orlov",
    "lat": 58.5333,
    "lng": 48.8833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kirovskaya Oblast’",
    "capital": "",
    "population": 6607,
    "id": 1643357562
  },
  {
    "city": "Kermit",
    "city_ascii": "Kermit",
    "lat": 31.854,
    "lng": -103.0924,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 6613,
    "id": 1840020796
  },
  {
    "city": "Thalheim",
    "city_ascii": "Thalheim",
    "lat": 50.7025,
    "lng": 12.8517,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 6051,
    "id": 1276826807
  },
  {
    "city": "Awjilah",
    "city_ascii": "Awjilah",
    "lat": 29.1081,
    "lng": 21.2869,
    "country": "Libya",
    "iso2": "LY",
    "iso3": "LBY",
    "admin_name": "Al Wāḩāt",
    "capital": "",
    "population": 6610,
    "id": 1434938770
  },
  {
    "city": "Burgkunstadt",
    "city_ascii": "Burgkunstadt",
    "lat": 50.1356,
    "lng": 11.2499,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 6451,
    "id": 1276128340
  },
  {
    "city": "Meridianville",
    "city_ascii": "Meridianville",
    "lat": 34.8729,
    "lng": -86.5722,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 6609,
    "id": 1840013546
  },
  {
    "city": "Nevada",
    "city_ascii": "Nevada",
    "lat": 42.0186,
    "lng": -93.4635,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Iowa",
    "capital": "",
    "population": 6608,
    "id": 1840008125
  },
  {
    "city": "Talent",
    "city_ascii": "Talent",
    "lat": 42.2404,
    "lng": -122.7807,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 6608,
    "id": 1840021300
  },
  {
    "city": "Sudova Vyshnya",
    "city_ascii": "Sudova Vyshnya",
    "lat": 49.7892,
    "lng": 23.3722,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "L’vivs’ka Oblast’",
    "capital": "",
    "population": 6538,
    "id": 1804528042
  },
  {
    "city": "Pan de Azúcar",
    "city_ascii": "Pan de Azucar",
    "lat": -34.8,
    "lng": -55.2167,
    "country": "Uruguay",
    "iso2": "UY",
    "iso3": "URY",
    "admin_name": "Maldonado",
    "capital": "",
    "population": 6597,
    "id": 1858825191
  },
  {
    "city": "Trinity",
    "city_ascii": "Trinity",
    "lat": 35.8756,
    "lng": -80.0093,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 6606,
    "id": 1840015374
  },
  {
    "city": "Hamilton",
    "city_ascii": "Hamilton",
    "lat": 34.1346,
    "lng": -87.9755,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 6606,
    "id": 1840003629
  },
  {
    "city": "Ploče",
    "city_ascii": "Ploce",
    "lat": 43.0525,
    "lng": 17.4367,
    "country": "Croatia",
    "iso2": "HR",
    "iso3": "HRV",
    "admin_name": "Dubrovačko-Neretvanska Županija",
    "capital": "minor",
    "population": 6537,
    "id": 1191861085
  },
  {
    "city": "Normandy Park",
    "city_ascii": "Normandy Park",
    "lat": 47.4343,
    "lng": -122.3436,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 6604,
    "id": 1840019832
  },
  {
    "city": "Lago Vista",
    "city_ascii": "Lago Vista",
    "lat": 30.4519,
    "lng": -97.9908,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 6604,
    "id": 1840020893
  },
  {
    "city": "Ambridge",
    "city_ascii": "Ambridge",
    "lat": 40.5922,
    "lng": -80.2265,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 6601,
    "id": 1840001010
  },
  {
    "city": "Orchard Mesa",
    "city_ascii": "Orchard Mesa",
    "lat": 39.0363,
    "lng": -108.5169,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 6601,
    "id": 1840018811
  },
  {
    "city": "Amite City",
    "city_ascii": "Amite City",
    "lat": 30.7323,
    "lng": -90.5133,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 6600,
    "id": 1840036149
  },
  {
    "city": "Porter",
    "city_ascii": "Porter",
    "lat": 43.2517,
    "lng": -78.972,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 6599,
    "id": 1840058414
  },
  {
    "city": "Catasauqua",
    "city_ascii": "Catasauqua",
    "lat": 40.6531,
    "lng": -75.4643,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 6599,
    "id": 1840001045
  },
  {
    "city": "Essex",
    "city_ascii": "Essex",
    "lat": 41.3499,
    "lng": -72.4146,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 6599,
    "id": 1840045013
  },
  {
    "city": "Lydd",
    "city_ascii": "Lydd",
    "lat": 50.9509,
    "lng": 0.9064,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Kent",
    "capital": "",
    "population": 6567,
    "id": 1826780016
  },
  {
    "city": "Rodewisch",
    "city_ascii": "Rodewisch",
    "lat": 50.5167,
    "lng": 12.4167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 6359,
    "id": 1276002058
  },
  {
    "city": "San Adrián",
    "city_ascii": "San Adrian",
    "lat": 42.3325,
    "lng": -1.9333,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Navarre",
    "capital": "",
    "population": 6292,
    "id": 1724174328
  },
  {
    "city": "Tetecala",
    "city_ascii": "Tetecala",
    "lat": 18.7167,
    "lng": -99.3833,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Morelos",
    "capital": "minor",
    "population": 6473,
    "id": 1484463800
  },
  {
    "city": "Strongoli",
    "city_ascii": "Strongoli",
    "lat": 39.2667,
    "lng": 17.0667,
    "country": "Italy",
    "iso2": "IT",
    "iso3": "ITA",
    "admin_name": "Calabria",
    "capital": "",
    "population": 6518,
    "id": 1380686663
  },
  {
    "city": "Allegheny Township",
    "city_ascii": "Allegheny Township",
    "lat": 40.4583,
    "lng": -78.4768,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 6594,
    "id": 1840152336
  },
  {
    "city": "Washington",
    "city_ascii": "Washington",
    "lat": 41.2982,
    "lng": -91.6928,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Iowa",
    "capital": "",
    "population": 6594,
    "id": 1840010255
  },
  {
    "city": "Strensall",
    "city_ascii": "Strensall",
    "lat": 54.0401,
    "lng": -1.0344,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "York",
    "capital": "",
    "population": 6047,
    "id": 1826306635
  },
  {
    "city": "Dingwall",
    "city_ascii": "Dingwall",
    "lat": 57.597,
    "lng": -4.428,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Highland",
    "capital": "",
    "population": 6545,
    "id": 1826526673
  },
  {
    "city": "Granby",
    "city_ascii": "Granby",
    "lat": 43.2918,
    "lng": -76.4408,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 6592,
    "id": 1840087479
  },
  {
    "city": "Roslyn Heights",
    "city_ascii": "Roslyn Heights",
    "lat": 40.7787,
    "lng": -73.6396,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 6591,
    "id": 1840005254
  },
  {
    "city": "Thannhausen",
    "city_ascii": "Thannhausen",
    "lat": 48.2667,
    "lng": 10.4667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 6277,
    "id": 1276723132
  },
  {
    "city": "Kerekegyháza",
    "city_ascii": "Kerekegyhaza",
    "lat": 46.9369,
    "lng": 19.4769,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Bács-Kiskun",
    "capital": "",
    "population": 6509,
    "id": 1348633823
  },
  {
    "city": "Treforest",
    "city_ascii": "Treforest",
    "lat": 51.5878,
    "lng": -3.3222,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Rhondda Cynon Taff",
    "capital": "",
    "population": 5050,
    "id": 1826993599
  },
  {
    "city": "Bad Blankenburg",
    "city_ascii": "Bad Blankenburg",
    "lat": 50.6833,
    "lng": 11.2667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Thuringia",
    "capital": "",
    "population": 6407,
    "id": 1276181009
  },
  {
    "city": "Nova Aliança",
    "city_ascii": "Nova Alianca",
    "lat": -21.0158,
    "lng": -49.4958,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 6555,
    "id": 1076892600
  },
  {
    "city": "Tuiuti",
    "city_ascii": "Tuiuti",
    "lat": -22.8158,
    "lng": -46.6939,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 6533,
    "id": 1076809438
  },
  {
    "city": "Fairfield",
    "city_ascii": "Fairfield",
    "lat": 44.6372,
    "lng": -69.6751,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maine",
    "capital": "",
    "population": 6583,
    "id": 1840052816
  },
  {
    "city": "Sretensk",
    "city_ascii": "Sretensk",
    "lat": 52.25,
    "lng": 117.7167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Zabaykal’skiy Kray",
    "capital": "",
    "population": 6581,
    "id": 1643428997
  },
  {
    "city": "Makaryev",
    "city_ascii": "Makaryev",
    "lat": 57.8833,
    "lng": 43.8,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kostromskaya Oblast’",
    "capital": "",
    "population": 6579,
    "id": 1643500999
  },
  {
    "city": "Tanglewilde",
    "city_ascii": "Tanglewilde",
    "lat": 47.0512,
    "lng": -122.781,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 6581,
    "id": 1840037899
  },
  {
    "city": "Turčianske Teplice",
    "city_ascii": "Turcianske Teplice",
    "lat": 48.8589,
    "lng": 18.8636,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Žilinský",
    "capital": "minor",
    "population": 6390,
    "id": 1703219724
  },
  {
    "city": "Havelberg",
    "city_ascii": "Havelberg",
    "lat": 52.825,
    "lng": 12.0744,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony-Anhalt",
    "capital": "",
    "population": 6537,
    "id": 1276651557
  },
  {
    "city": "Ishqoshim",
    "city_ascii": "Ishqoshim",
    "lat": 36.7272,
    "lng": 71.6117,
    "country": "Tajikistan",
    "iso2": "TJ",
    "iso3": "TJK",
    "admin_name": "Kŭhistoni Badakhshon",
    "capital": "minor",
    "population": 6567,
    "id": 1762005667
  },
  {
    "city": "Varshets",
    "city_ascii": "Varshets",
    "lat": 43.1953,
    "lng": 23.2858,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Montana",
    "capital": "minor",
    "population": 6500,
    "id": 1100932516
  },
  {
    "city": "Isen",
    "city_ascii": "Isen",
    "lat": 27.6736,
    "lng": 128.9375,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Kagoshima",
    "capital": "",
    "population": 6476,
    "id": 1392971525
  },
  {
    "city": "Devon",
    "city_ascii": "Devon",
    "lat": 53.3633,
    "lng": -113.7322,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Alberta",
    "capital": "",
    "population": 6578,
    "id": 1124268366
  },
  {
    "city": "Lehliu-Gară",
    "city_ascii": "Lehliu-Gara",
    "lat": 44.4386,
    "lng": 26.8533,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Călăraşi",
    "capital": "",
    "population": 6502,
    "id": 1642561263
  },
  {
    "city": "Veselí nad Lužnicí",
    "city_ascii": "Veseli nad Luznici",
    "lat": 49.1844,
    "lng": 14.6974,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Jihočeský Kraj",
    "capital": "",
    "population": 6361,
    "id": 1203676048
  },
  {
    "city": "Slavičín",
    "city_ascii": "Slavicin",
    "lat": 49.088,
    "lng": 17.8735,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Zlínský Kraj",
    "capital": "",
    "population": 6386,
    "id": 1203016880
  },
  {
    "city": "Ribeira de Pena",
    "city_ascii": "Ribeira de Pena",
    "lat": 41.5167,
    "lng": -7.8,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Vila Real",
    "capital": "minor",
    "population": 6544,
    "id": 1620735983
  },
  {
    "city": "Orlovista",
    "city_ascii": "Orlovista",
    "lat": 28.5441,
    "lng": -81.4629,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 6574,
    "id": 1840014096
  },
  {
    "city": "Mont Belvieu",
    "city_ascii": "Mont Belvieu",
    "lat": 29.8524,
    "lng": -94.8784,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 6574,
    "id": 1840020942
  },
  {
    "city": "Enola",
    "city_ascii": "Enola",
    "lat": 40.2908,
    "lng": -76.9348,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 6574,
    "id": 1840005483
  },
  {
    "city": "Téglás",
    "city_ascii": "Teglas",
    "lat": 47.7167,
    "lng": 21.6833,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Hajdú-Bihar",
    "capital": "",
    "population": 6406,
    "id": 1348643886
  },
  {
    "city": "Großalmerode",
    "city_ascii": "Grossalmerode",
    "lat": 51.2575,
    "lng": 9.7844,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 6402,
    "id": 1276706918
  },
  {
    "city": "Cold Spring",
    "city_ascii": "Cold Spring",
    "lat": 39.013,
    "lng": -84.4349,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kentucky",
    "capital": "",
    "population": 6571,
    "id": 1840014257
  },
  {
    "city": "Makarov",
    "city_ascii": "Makarov",
    "lat": 48.627,
    "lng": 142.78,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sakhalinskaya Oblast’",
    "capital": "",
    "population": 6567,
    "id": 1643711002
  },
  {
    "city": "Doctor Petru Groza",
    "city_ascii": "Doctor Petru Groza",
    "lat": 46.54,
    "lng": 22.47,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Bihor",
    "capital": "",
    "population": 6529,
    "id": 1642114682
  },
  {
    "city": "Harvard",
    "city_ascii": "Harvard",
    "lat": 42.5059,
    "lng": -71.5853,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 6570,
    "id": 1840053692
  },
  {
    "city": "Keene",
    "city_ascii": "Keene",
    "lat": 32.3955,
    "lng": -97.3226,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 6568,
    "id": 1840019469
  },
  {
    "city": "Walnut Ridge",
    "city_ascii": "Walnut Ridge",
    "lat": 36.0851,
    "lng": -90.9463,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arkansas",
    "capital": "",
    "population": 6568,
    "id": 1840015327
  },
  {
    "city": "Thompson's Station",
    "city_ascii": "Thompson's Station",
    "lat": 35.809,
    "lng": -86.8994,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 6567,
    "id": 1840016218
  },
  {
    "city": "Stickney",
    "city_ascii": "Stickney",
    "lat": 41.8183,
    "lng": -87.773,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 6566,
    "id": 1840011326
  },
  {
    "city": "Aszód",
    "city_ascii": "Aszod",
    "lat": 47.6514,
    "lng": 19.4894,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Pest",
    "capital": "minor",
    "population": 6184,
    "id": 1348488151
  },
  {
    "city": "Yorketown",
    "city_ascii": "Yorketown",
    "lat": 40.3064,
    "lng": -74.3385,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 6565,
    "id": 1840033488
  },
  {
    "city": "Burwell",
    "city_ascii": "Burwell",
    "lat": 52.275,
    "lng": 0.327,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Cambridgeshire",
    "capital": "",
    "population": 6309,
    "id": 1826185767
  },
  {
    "city": "Spassk-Ryazanskiy",
    "city_ascii": "Spassk-Ryazanskiy",
    "lat": 54.4,
    "lng": 40.3833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Ryazanskaya Oblast’",
    "capital": "",
    "population": 6553,
    "id": 1643613381
  },
  {
    "city": "Wentworth Falls",
    "city_ascii": "Wentworth Falls",
    "lat": -33.7164,
    "lng": 150.3772,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 6076,
    "id": 1036272828
  },
  {
    "city": "Kaitaia",
    "city_ascii": "Kaitaia",
    "lat": -35.1125,
    "lng": 173.2628,
    "country": "New Zealand",
    "iso2": "NZ",
    "iso3": "NZL",
    "admin_name": "Northland",
    "capital": "",
    "population": 5870,
    "id": 1554000934
  },
  {
    "city": "Rossford",
    "city_ascii": "Rossford",
    "lat": 41.5832,
    "lng": -83.5692,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 6562,
    "id": 1840000624
  },
  {
    "city": "Outjo",
    "city_ascii": "Outjo",
    "lat": -20.1069,
    "lng": 16.1503,
    "country": "Namibia",
    "iso2": "NA",
    "iso3": "NAM",
    "admin_name": "Kunene",
    "capital": "",
    "population": 6557,
    "id": 1516062528
  },
  {
    "city": "Cushing",
    "city_ascii": "Cushing",
    "lat": 35.9798,
    "lng": -96.7602,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oklahoma",
    "capital": "",
    "population": 6560,
    "id": 1840019062
  },
  {
    "city": "Bad Marienberg",
    "city_ascii": "Bad Marienberg",
    "lat": 50.6519,
    "lng": 7.9522,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Rhineland-Palatinate",
    "capital": "",
    "population": 5961,
    "id": 1276727576
  },
  {
    "city": "Homestead Meadows South",
    "city_ascii": "Homestead Meadows South",
    "lat": 31.811,
    "lng": -106.1643,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 6559,
    "id": 1840073263
  },
  {
    "city": "Însurăţei",
    "city_ascii": "Insuratei",
    "lat": 44.9167,
    "lng": 27.6,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Brăila",
    "capital": "",
    "population": 6528,
    "id": 1642422424
  },
  {
    "city": "Nemšová",
    "city_ascii": "Nemsova",
    "lat": 48.9669,
    "lng": 18.1169,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Nitriansky",
    "capital": "",
    "population": 6368,
    "id": 1703388778
  },
  {
    "city": "Scone",
    "city_ascii": "Scone",
    "lat": -32.0833,
    "lng": 150.85,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 5624,
    "id": 1036918224
  },
  {
    "city": "Plymouth Meeting",
    "city_ascii": "Plymouth Meeting",
    "lat": 40.11,
    "lng": -75.2794,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 6557,
    "id": 1840005459
  },
  {
    "city": "Lawrence",
    "city_ascii": "Lawrence",
    "lat": 40.6042,
    "lng": -73.7149,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 6556,
    "id": 1840005303
  },
  {
    "city": "Barrow upon Soar",
    "city_ascii": "Barrow upon Soar",
    "lat": 52.7517,
    "lng": -1.15,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Leicestershire",
    "capital": "",
    "population": 5856,
    "id": 1826688308
  },
  {
    "city": "Camp Swift",
    "city_ascii": "Camp Swift",
    "lat": 30.1885,
    "lng": -97.2933,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 6553,
    "id": 1840018243
  },
  {
    "city": "Topsfield",
    "city_ascii": "Topsfield",
    "lat": 42.6373,
    "lng": -70.9425,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 6551,
    "id": 1840053541
  },
  {
    "city": "Earlswood",
    "city_ascii": "Earlswood",
    "lat": 51.228,
    "lng": -0.171,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Surrey",
    "capital": "",
    "population": 5049,
    "id": 1826024227
  },
  {
    "city": "Chizu",
    "city_ascii": "Chizu",
    "lat": 35.265,
    "lng": 134.2267,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Tottori",
    "capital": "",
    "population": 6520,
    "id": 1392257762
  },
  {
    "city": "Litchfield",
    "city_ascii": "Litchfield",
    "lat": 45.1221,
    "lng": -94.5255,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 6549,
    "id": 1840008912
  },
  {
    "city": "Cumming",
    "city_ascii": "Cumming",
    "lat": 34.2064,
    "lng": -84.1337,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 6547,
    "id": 1840014712
  },
  {
    "city": "Kathleen",
    "city_ascii": "Kathleen",
    "lat": 28.1224,
    "lng": -82.0387,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 6546,
    "id": 1840014129
  },
  {
    "city": "Portage",
    "city_ascii": "Portage",
    "lat": 40.3868,
    "lng": -78.6746,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 6546,
    "id": 1840001122
  },
  {
    "city": "Linz am Rhein",
    "city_ascii": "Linz am Rhein",
    "lat": 50.5703,
    "lng": 7.2847,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Rhineland-Palatinate",
    "capital": "",
    "population": 6200,
    "id": 1276609082
  },
  {
    "city": "South Elmsall",
    "city_ascii": "South Elmsall",
    "lat": 53.5959,
    "lng": -1.2726,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Wakefield",
    "capital": "",
    "population": 6519,
    "id": 1826782534
  },
  {
    "city": "Fox Point",
    "city_ascii": "Fox Point",
    "lat": 43.1581,
    "lng": -87.9013,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 6543,
    "id": 1840003042
  },
  {
    "city": "Beaudesert",
    "city_ascii": "Beaudesert",
    "lat": -27.988,
    "lng": 152.9958,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Queensland",
    "capital": "",
    "population": 6395,
    "id": 1036443236
  },
  {
    "city": "Slippery Rock",
    "city_ascii": "Slippery Rock",
    "lat": 41.0695,
    "lng": -80.058,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 6541,
    "id": 1840000874
  },
  {
    "city": "Clairton",
    "city_ascii": "Clairton",
    "lat": 40.2976,
    "lng": -79.8853,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 6541,
    "id": 1840001219
  },
  {
    "city": "Oakmont",
    "city_ascii": "Oakmont",
    "lat": 40.5201,
    "lng": -79.8365,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 6541,
    "id": 1840001250
  },
  {
    "city": "Plaquemine",
    "city_ascii": "Plaquemine",
    "lat": 30.2834,
    "lng": -91.2429,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 6539,
    "id": 1840015037
  },
  {
    "city": "Agriá",
    "city_ascii": "Agria",
    "lat": 39.34,
    "lng": 23.0133,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Thessalía",
    "capital": "",
    "population": 5191,
    "id": 1300594984
  },
  {
    "city": "Elkin",
    "city_ascii": "Elkin",
    "lat": 36.2621,
    "lng": -80.8441,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 6536,
    "id": 1840016031
  },
  {
    "city": "Beyram",
    "city_ascii": "Beyram",
    "lat": 27.4306,
    "lng": 53.5144,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Fārs",
    "capital": "",
    "population": 6520,
    "id": 1364394280
  },
  {
    "city": "Plattsmouth",
    "city_ascii": "Plattsmouth",
    "lat": 41.0053,
    "lng": -95.894,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Nebraska",
    "capital": "",
    "population": 6534,
    "id": 1840009355
  },
  {
    "city": "Hapeville",
    "city_ascii": "Hapeville",
    "lat": 33.6609,
    "lng": -84.4093,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 6534,
    "id": 1840013663
  },
  {
    "city": "Salem Township",
    "city_ascii": "Salem Township",
    "lat": 40.4071,
    "lng": -79.5141,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 6533,
    "id": 1840151384
  },
  {
    "city": "Swanton",
    "city_ascii": "Swanton",
    "lat": 44.9081,
    "lng": -73.1252,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Vermont",
    "capital": "",
    "population": 6530,
    "id": 1840004033
  },
  {
    "city": "Bursledon",
    "city_ascii": "Bursledon",
    "lat": 50.8847,
    "lng": -1.3117,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hampshire",
    "capital": "",
    "population": 6188,
    "id": 1826768153
  },
  {
    "city": "Pedda Nandipādu",
    "city_ascii": "Pedda Nandipadu",
    "lat": 16.0728,
    "lng": 80.3294,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Andhra Pradesh",
    "capital": "",
    "population": 6090,
    "id": 1356679236
  },
  {
    "city": "Chrastava",
    "city_ascii": "Chrastava",
    "lat": 50.817,
    "lng": 14.9689,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Liberecký Kraj",
    "capital": "",
    "population": 6298,
    "id": 1203988885
  },
  {
    "city": "Crozet",
    "city_ascii": "Crozet",
    "lat": 38.0645,
    "lng": -78.6962,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 6526,
    "id": 1840006280
  },
  {
    "city": "Blaby",
    "city_ascii": "Blaby",
    "lat": 52.5724,
    "lng": -1.1678,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Leicestershire",
    "capital": "",
    "population": 6194,
    "id": 1826055735
  },
  {
    "city": "Jowzdān",
    "city_ascii": "Jowzdan",
    "lat": 32.5567,
    "lng": 51.3725,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Eşfahān",
    "capital": "",
    "population": 6393,
    "id": 1364010380
  },
  {
    "city": "Peterborough",
    "city_ascii": "Peterborough",
    "lat": 42.8905,
    "lng": -71.9394,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Hampshire",
    "capital": "",
    "population": 6524,
    "id": 1840055383
  },
  {
    "city": "Valley Falls",
    "city_ascii": "Valley Falls",
    "lat": 35.0073,
    "lng": -81.9692,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 6522,
    "id": 1840013512
  },
  {
    "city": "Wadomari",
    "city_ascii": "Wadomari",
    "lat": 27.3922,
    "lng": 128.6553,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Kagoshima",
    "capital": "",
    "population": 6364,
    "id": 1392062707
  },
  {
    "city": "Friedland",
    "city_ascii": "Friedland",
    "lat": 53.659,
    "lng": 13.5397,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Mecklenburg-Western Pomerania",
    "capital": "",
    "population": 6472,
    "id": 1276869290
  },
  {
    "city": "Orindiúva",
    "city_ascii": "Orindiuva",
    "lat": -20.1819,
    "lng": -49.3508,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 6493,
    "id": 1076167041
  },
  {
    "city": "Eagle",
    "city_ascii": "Eagle",
    "lat": 39.6368,
    "lng": -106.8123,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 6519,
    "id": 1840021424
  },
  {
    "city": "Bowleys Quarters",
    "city_ascii": "Bowleys Quarters",
    "lat": 39.3198,
    "lng": -76.3858,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 6519,
    "id": 1840005677
  },
  {
    "city": "Fairview",
    "city_ascii": "Fairview",
    "lat": 34.9296,
    "lng": -85.294,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 6518,
    "id": 1840013084
  },
  {
    "city": "Yarmouth",
    "city_ascii": "Yarmouth",
    "lat": 43.8361,
    "lng": -66.1175,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Nova Scotia",
    "capital": "",
    "population": 6518,
    "id": 1124983867
  },
  {
    "city": "Grand Turk",
    "city_ascii": "Grand Turk",
    "lat": 21.4664,
    "lng": -71.136,
    "country": "Turks And Caicos Islands",
    "iso2": "TC",
    "iso3": "TCA",
    "admin_name": "",
    "capital": "",
    "population": 5801,
    "id": 1796965313
  },
  {
    "city": "Martins Ferry",
    "city_ascii": "Martins Ferry",
    "lat": 40.1014,
    "lng": -80.7253,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 6517,
    "id": 1840008388
  },
  {
    "city": "Russells Point",
    "city_ascii": "Russells Point",
    "lat": 40.468,
    "lng": -83.8939,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 6517,
    "id": 1840012155
  },
  {
    "city": "Port Mathurin",
    "city_ascii": "Port Mathurin",
    "lat": -19.6833,
    "lng": 63.4211,
    "country": "Mauritius",
    "iso2": "MU",
    "iso3": "MUS",
    "admin_name": "Rodrigues",
    "capital": "",
    "population": 5929,
    "id": 1480878391
  },
  {
    "city": "Calstock",
    "city_ascii": "Calstock",
    "lat": 50.497,
    "lng": -4.21,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Devon",
    "capital": "",
    "population": 6253,
    "id": 1826405766
  },
  {
    "city": "Dromana",
    "city_ascii": "Dromana",
    "lat": -38.338,
    "lng": 144.965,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Victoria",
    "capital": "",
    "population": 5803,
    "id": 1036001258
  },
  {
    "city": "Milford",
    "city_ascii": "Milford",
    "lat": 42.587,
    "lng": -83.6012,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 6515,
    "id": 1840011058
  },
  {
    "city": "West Tamworth",
    "city_ascii": "West Tamworth",
    "lat": -31.0894,
    "lng": 150.9436,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 5342,
    "id": 1036187167
  },
  {
    "city": "Otis Orchards-East Farms",
    "city_ascii": "Otis Orchards-East Farms",
    "lat": 47.703,
    "lng": -117.0854,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 6514,
    "id": 1840037126
  },
  {
    "city": "Bagrationovsk",
    "city_ascii": "Bagrationovsk",
    "lat": 54.3833,
    "lng": 20.6333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kaliningradskaya Oblast’",
    "capital": "minor",
    "population": 6482,
    "id": 1643211055
  },
  {
    "city": "Bácsalmás",
    "city_ascii": "Bacsalmas",
    "lat": 46.1239,
    "lng": 19.3261,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Bács-Kiskun",
    "capital": "minor",
    "population": 6453,
    "id": 1348610453
  },
  {
    "city": "Blankenhain",
    "city_ascii": "Blankenhain",
    "lat": 50.86,
    "lng": 11.3439,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Thuringia",
    "capital": "",
    "population": 6455,
    "id": 1276207910
  },
  {
    "city": "Hamilton",
    "city_ascii": "Hamilton",
    "lat": 42.7897,
    "lng": -75.494,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 6511,
    "id": 1840004354
  },
  {
    "city": "Collins",
    "city_ascii": "Collins",
    "lat": 42.4908,
    "lng": -78.8623,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 6511,
    "id": 1840058004
  },
  {
    "city": "Avon",
    "city_ascii": "Avon",
    "lat": 39.6445,
    "lng": -106.5133,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 6511,
    "id": 1840020194
  },
  {
    "city": "New Inn",
    "city_ascii": "New Inn",
    "lat": 51.691,
    "lng": -3.009,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Torfaen",
    "capital": "",
    "population": 5986,
    "id": 1826346365
  },
  {
    "city": "Pimmit Hills",
    "city_ascii": "Pimmit Hills",
    "lat": 38.9105,
    "lng": -77.1991,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 6506,
    "id": 1840006024
  },
  {
    "city": "Rittman",
    "city_ascii": "Rittman",
    "lat": 40.9736,
    "lng": -81.7849,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 6506,
    "id": 1840009372
  },
  {
    "city": "El Rio",
    "city_ascii": "El Rio",
    "lat": 34.2452,
    "lng": -119.1568,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 6505,
    "id": 1840017913
  },
  {
    "city": "Granville",
    "city_ascii": "Granville",
    "lat": 43.4188,
    "lng": -73.3086,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 6503,
    "id": 1840004176
  },
  {
    "city": "Bahman",
    "city_ascii": "Bahman",
    "lat": 31.1942,
    "lng": 52.4839,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Fārs",
    "capital": "",
    "population": 6484,
    "id": 1364686179
  },
  {
    "city": "Duanesburg",
    "city_ascii": "Duanesburg",
    "lat": 42.7799,
    "lng": -74.1805,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 6501,
    "id": 1840058053
  },
  {
    "city": "Zell im Wiesental",
    "city_ascii": "Zell im Wiesental",
    "lat": 47.7069,
    "lng": 7.8514,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 6325,
    "id": 1276966044
  },
  {
    "city": "Monticello",
    "city_ascii": "Monticello",
    "lat": 36.8404,
    "lng": -84.8506,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kentucky",
    "capital": "",
    "population": 6499,
    "id": 1840014404
  },
  {
    "city": "Williamston",
    "city_ascii": "Williamston",
    "lat": 42.6835,
    "lng": -84.2836,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 6498,
    "id": 1840000420
  },
  {
    "city": "Champion Heights",
    "city_ascii": "Champion Heights",
    "lat": 41.3031,
    "lng": -80.8514,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 6495,
    "id": 1840073783
  },
  {
    "city": "Podbořany",
    "city_ascii": "Podborany",
    "lat": 50.2295,
    "lng": 13.412,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Ústecký Kraj",
    "capital": "",
    "population": 6386,
    "id": 1203340150
  },
  {
    "city": "La Habra Heights",
    "city_ascii": "La Habra Heights",
    "lat": 33.9602,
    "lng": -117.951,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 6492,
    "id": 1840020511
  },
  {
    "city": "San Ramón",
    "city_ascii": "San Ramon",
    "lat": -13.2672,
    "lng": -64.6172,
    "country": "Bolivia",
    "iso2": "BO",
    "iso3": "BOL",
    "admin_name": "El Beni",
    "capital": "",
    "population": 6490,
    "id": 1068370064
  },
  {
    "city": "East Rochester",
    "city_ascii": "East Rochester",
    "lat": 43.112,
    "lng": -77.4869,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 6490,
    "id": 1840004289
  },
  {
    "city": "Mulvane",
    "city_ascii": "Mulvane",
    "lat": 37.4788,
    "lng": -97.2724,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kansas",
    "capital": "",
    "population": 6489,
    "id": 1840008748
  },
  {
    "city": "Beerfelden",
    "city_ascii": "Beerfelden",
    "lat": 49.5676,
    "lng": 8.9738,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 6399,
    "id": 1276859508
  },
  {
    "city": "Looe",
    "city_ascii": "Looe",
    "lat": 50.354,
    "lng": -4.454,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Cornwall",
    "capital": "",
    "population": 5112,
    "id": 1826444022
  },
  {
    "city": "Bungay",
    "city_ascii": "Bungay",
    "lat": 52.4496,
    "lng": 1.4477,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Suffolk",
    "capital": "",
    "population": 5127,
    "id": 1826648415
  },
  {
    "city": "Lynchburg",
    "city_ascii": "Lynchburg",
    "lat": 35.2846,
    "lng": -86.3587,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 6488,
    "id": 1840036144
  },
  {
    "city": "Camilla",
    "city_ascii": "Camilla",
    "lat": 31.2337,
    "lng": -84.2089,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 6488,
    "id": 1840013877
  },
  {
    "city": "Žarnovica",
    "city_ascii": "Zarnovica",
    "lat": 48.4833,
    "lng": 18.7167,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Banskobystrický",
    "capital": "minor",
    "population": 6284,
    "id": 1703234084
  },
  {
    "city": "Grayvoron",
    "city_ascii": "Grayvoron",
    "lat": 50.4833,
    "lng": 35.6667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Belgorodskaya Oblast’",
    "capital": "minor",
    "population": 6449,
    "id": 1643809154
  },
  {
    "city": "Iacri",
    "city_ascii": "Iacri",
    "lat": -21.8583,
    "lng": -50.6894,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 6481,
    "id": 1076477503
  },
  {
    "city": "Lincoln",
    "city_ascii": "Lincoln",
    "lat": -43.64,
    "lng": 172.486,
    "country": "New Zealand",
    "iso2": "NZ",
    "iso3": "NZL",
    "admin_name": "Canterbury",
    "capital": "",
    "population": 6030,
    "id": 1554645119
  },
  {
    "city": "East Shoreham",
    "city_ascii": "East Shoreham",
    "lat": 40.946,
    "lng": -72.8811,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 6484,
    "id": 1840034048
  },
  {
    "city": "Cheshire Village",
    "city_ascii": "Cheshire Village",
    "lat": 41.5026,
    "lng": -72.8993,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 6484,
    "id": 1840073562
  },
  {
    "city": "Eliot",
    "city_ascii": "Eliot",
    "lat": 43.1488,
    "lng": -70.786,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maine",
    "capital": "",
    "population": 6484,
    "id": 1840052811
  },
  {
    "city": "Theodore",
    "city_ascii": "Theodore",
    "lat": 30.5408,
    "lng": -88.1884,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 6483,
    "id": 1840013895
  },
  {
    "city": "São Martinho",
    "city_ascii": "Sao Martinho",
    "lat": 38.8125,
    "lng": -9.4208,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Lisboa",
    "capital": "",
    "population": 6226,
    "id": 1620177495
  },
  {
    "city": "Abercarn",
    "city_ascii": "Abercarn",
    "lat": 51.649,
    "lng": -3.135,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Caerphilly",
    "capital": "",
    "population": 5352,
    "id": 1826505762
  },
  {
    "city": "Yoshinoyama",
    "city_ascii": "Yoshinoyama",
    "lat": 34.4,
    "lng": 135.85,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Nara",
    "capital": "",
    "population": 6412,
    "id": 1392799101
  },
  {
    "city": "Prairie du Chien",
    "city_ascii": "Prairie du Chien",
    "lat": 43.0429,
    "lng": -91.1373,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 6477,
    "id": 1840002890
  },
  {
    "city": "Wervicq-Sud",
    "city_ascii": "Wervicq-Sud",
    "lat": 50.7722,
    "lng": 3.0478,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Hauts-de-France",
    "capital": "",
    "population": 5413,
    "id": 1250773155
  },
  {
    "city": "Rodach",
    "city_ascii": "Rodach",
    "lat": 50.3333,
    "lng": 10.7833,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 6394,
    "id": 1276671158
  },
  {
    "city": "Drayton",
    "city_ascii": "Drayton",
    "lat": 52.675,
    "lng": 1.225,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Norfolk",
    "capital": "",
    "population": 5489,
    "id": 1826056613
  },
  {
    "city": "Laage",
    "city_ascii": "Laage",
    "lat": 53.9322,
    "lng": 12.3467,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Mecklenburg-Western Pomerania",
    "capital": "",
    "population": 6396,
    "id": 1276149730
  },
  {
    "city": "Irvington",
    "city_ascii": "Irvington",
    "lat": 41.0349,
    "lng": -73.8661,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 6473,
    "id": 1840004933
  },
  {
    "city": "Prestonsburg",
    "city_ascii": "Prestonsburg",
    "lat": 37.6816,
    "lng": -82.7662,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kentucky",
    "capital": "",
    "population": 6473,
    "id": 1840014346
  },
  {
    "city": "Tōma",
    "city_ascii": "Toma",
    "lat": 43.8281,
    "lng": 142.5083,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hokkaidō",
    "capital": "",
    "population": 6441,
    "id": 1392468609
  },
  {
    "city": "Gómez Farías",
    "city_ascii": "Gomez Farias",
    "lat": 29.3583,
    "lng": -107.7361,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Chihuahua",
    "capital": "minor",
    "population": 5330,
    "id": 1484446758
  },
  {
    "city": "Rosenfeld",
    "city_ascii": "Rosenfeld",
    "lat": 48.2864,
    "lng": 8.7242,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 6347,
    "id": 1276473331
  },
  {
    "city": "Nagyecsed",
    "city_ascii": "Nagyecsed",
    "lat": 47.8619,
    "lng": 22.3867,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Szabolcs-Szatmár-Bereg",
    "capital": "",
    "population": 6327,
    "id": 1348321559
  },
  {
    "city": "Edgemoor",
    "city_ascii": "Edgemoor",
    "lat": 39.7551,
    "lng": -75.507,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Delaware",
    "capital": "",
    "population": 6471,
    "id": 1840028608
  },
  {
    "city": "Neumarkt am Wallersee",
    "city_ascii": "Neumarkt am Wallersee",
    "lat": 47.9453,
    "lng": 13.2247,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Salzburg",
    "capital": "",
    "population": 6297,
    "id": 1040373485
  },
  {
    "city": "Sidley",
    "city_ascii": "Sidley",
    "lat": 50.86,
    "lng": 0.47,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "East Sussex",
    "capital": "",
    "population": 5718,
    "id": 1826517116
  },
  {
    "city": "Moraine",
    "city_ascii": "Moraine",
    "lat": 39.6983,
    "lng": -84.2459,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 6470,
    "id": 1840003784
  },
  {
    "city": "Laurel Bay",
    "city_ascii": "Laurel Bay",
    "lat": 32.4599,
    "lng": -80.7869,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 6470,
    "id": 1840013817
  },
  {
    "city": "Penn Yan",
    "city_ascii": "Penn Yan",
    "lat": 42.6608,
    "lng": -77.0536,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 6469,
    "id": 1840004503
  },
  {
    "city": "Lanchkhuti",
    "city_ascii": "Lanchkhuti",
    "lat": 42.0944,
    "lng": 42.0417,
    "country": "Georgia",
    "iso2": "GE",
    "iso3": "GEO",
    "admin_name": "Guria",
    "capital": "minor",
    "population": 6395,
    "id": 1268008920
  },
  {
    "city": "Red Chute",
    "city_ascii": "Red Chute",
    "lat": 32.5732,
    "lng": -93.606,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 6468,
    "id": 1840013789
  },
  {
    "city": "South Hill",
    "city_ascii": "South Hill",
    "lat": 42.4113,
    "lng": -76.4883,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 6468,
    "id": 1840004541
  },
  {
    "city": "Potomac Mills",
    "city_ascii": "Potomac Mills",
    "lat": 38.6547,
    "lng": -77.3012,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 6468,
    "id": 1840026711
  },
  {
    "city": "Columbia",
    "city_ascii": "Columbia",
    "lat": 31.2564,
    "lng": -89.8266,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Mississippi",
    "capital": "",
    "population": 6468,
    "id": 1840014970
  },
  {
    "city": "Jan Phyl Village",
    "city_ascii": "Jan Phyl Village",
    "lat": 28.0201,
    "lng": -81.7933,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 6467,
    "id": 1840028708
  },
  {
    "city": "Genoa",
    "city_ascii": "Genoa",
    "lat": 42.0926,
    "lng": -88.6964,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 6467,
    "id": 1840008133
  },
  {
    "city": "Ligonier",
    "city_ascii": "Ligonier",
    "lat": 40.2348,
    "lng": -79.2137,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 6466,
    "id": 1840001143
  },
  {
    "city": "Sidney",
    "city_ascii": "Sidney",
    "lat": 47.7152,
    "lng": -104.168,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Montana",
    "capital": "",
    "population": 6466,
    "id": 1840021085
  },
  {
    "city": "Boischatel",
    "city_ascii": "Boischatel",
    "lat": 46.9,
    "lng": -71.15,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 6465,
    "id": 1124332563
  },
  {
    "city": "Loutolim",
    "city_ascii": "Loutolim",
    "lat": 15.33,
    "lng": 73.98,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Goa",
    "capital": "",
    "population": 6121,
    "id": 1356023315
  },
  {
    "city": "Dalaba",
    "city_ascii": "Dalaba",
    "lat": 10.656,
    "lng": -12.272,
    "country": "Guinea",
    "iso2": "GN",
    "iso3": "GIN",
    "admin_name": "Mamou",
    "capital": "minor",
    "population": 6349,
    "id": 1324455027
  },
  {
    "city": "Ridgeway",
    "city_ascii": "Ridgeway",
    "lat": 43.2619,
    "lng": -78.3806,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 6464,
    "id": 1840024104
  },
  {
    "city": "South Grafton",
    "city_ascii": "South Grafton",
    "lat": -29.715,
    "lng": 152.9344,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 6068,
    "id": 1036377966
  },
  {
    "city": "Kölleda",
    "city_ascii": "Kolleda",
    "lat": 51.1667,
    "lng": 11.2167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Thuringia",
    "capital": "",
    "population": 6391,
    "id": 1276377154
  },
  {
    "city": "Glendive",
    "city_ascii": "Glendive",
    "lat": 47.1102,
    "lng": -104.7069,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Montana",
    "capital": "",
    "population": 6460,
    "id": 1840019824
  },
  {
    "city": "Cuddington",
    "city_ascii": "Cuddington",
    "lat": 53.24,
    "lng": -2.6,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Cheshire West and Chester",
    "capital": "",
    "population": 5333,
    "id": 1826034617
  },
  {
    "city": "Guichón",
    "city_ascii": "Guichon",
    "lat": -32.35,
    "lng": -57.2,
    "country": "Uruguay",
    "iso2": "UY",
    "iso3": "URY",
    "admin_name": "Paysandú",
    "capital": "",
    "population": 5025,
    "id": 1858589907
  },
  {
    "city": "Wem",
    "city_ascii": "Wem",
    "lat": 52.8536,
    "lng": -2.7267,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Shropshire",
    "capital": "",
    "population": 6100,
    "id": 1826790460
  },
  {
    "city": "Ingeniero Guillermo N. Juárez",
    "city_ascii": "Ingeniero Guillermo N. Juarez",
    "lat": -23.9,
    "lng": -61.85,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Formosa",
    "capital": "minor",
    "population": 6453,
    "id": 1032585569
  },
  {
    "city": "Corinth",
    "city_ascii": "Corinth",
    "lat": 43.2269,
    "lng": -73.8847,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 6455,
    "id": 1840004255
  },
  {
    "city": "Encs",
    "city_ascii": "Encs",
    "lat": 48.3306,
    "lng": 21.1217,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Borsod-Abaúj-Zemplén",
    "capital": "minor",
    "population": 6254,
    "id": 1348493605
  },
  {
    "city": "Lackland AFB",
    "city_ascii": "Lackland AFB",
    "lat": 29.3867,
    "lng": -98.6179,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 6454,
    "id": 1840074237
  },
  {
    "city": "Cairo",
    "city_ascii": "Cairo",
    "lat": 42.2965,
    "lng": -74.0205,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 6453,
    "id": 1840057626
  },
  {
    "city": "Lübz",
    "city_ascii": "Lubz",
    "lat": 53.4631,
    "lng": 12.0283,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Mecklenburg-Western Pomerania",
    "capital": "",
    "population": 6342,
    "id": 1276808728
  },
  {
    "city": "Klingenberg am Main",
    "city_ascii": "Klingenberg am Main",
    "lat": 49.7833,
    "lng": 9.1833,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 6160,
    "id": 1276868883
  },
  {
    "city": "Higashikyōshin",
    "city_ascii": "Higashikyoshin",
    "lat": 31.3858,
    "lng": 130.9733,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Kagoshima",
    "capital": "",
    "population": 6226,
    "id": 1392608481
  },
  {
    "city": "Ironwood",
    "city_ascii": "Ironwood",
    "lat": 46.4522,
    "lng": -90.1505,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 6448,
    "id": 1840001942
  },
  {
    "city": "Halych",
    "city_ascii": "Halych",
    "lat": 49.1128,
    "lng": 24.7217,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Ivano-Frankivs’ka Oblast’",
    "capital": "minor",
    "population": 6196,
    "id": 1804076203
  },
  {
    "city": "Trochtelfingen",
    "city_ascii": "Trochtelfingen",
    "lat": 48.3081,
    "lng": 9.2444,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 6366,
    "id": 1276845905
  },
  {
    "city": "Falkenberg",
    "city_ascii": "Falkenberg",
    "lat": 51.5831,
    "lng": 13.2322,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Brandenburg",
    "capital": "",
    "population": 6368,
    "id": 1276839320
  },
  {
    "city": "Deta",
    "city_ascii": "Deta",
    "lat": 45.4,
    "lng": 21.22,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Timiş",
    "capital": "",
    "population": 6260,
    "id": 1642851742
  },
  {
    "city": "Dmitriyev-L’govskiy",
    "city_ascii": "Dmitriyev-L'govskiy",
    "lat": 52.1289,
    "lng": 35.0756,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kurskaya Oblast’",
    "capital": "",
    "population": 6430,
    "id": 1643791004
  },
  {
    "city": "Emporia",
    "city_ascii": "Emporia",
    "lat": 36.6953,
    "lng": -77.5356,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 6443,
    "id": 1840003875
  },
  {
    "city": "Bridgeport",
    "city_ascii": "Bridgeport",
    "lat": 43.3706,
    "lng": -83.8828,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 6443,
    "id": 1840004227
  },
  {
    "city": "Quintana",
    "city_ascii": "Quintana",
    "lat": -22.0725,
    "lng": -50.3075,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 6437,
    "id": 1076808651
  },
  {
    "city": "Lee",
    "city_ascii": "Lee",
    "lat": 43.3257,
    "lng": -75.5175,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 6441,
    "id": 1840058235
  },
  {
    "city": "Beilstein",
    "city_ascii": "Beilstein",
    "lat": 49.0333,
    "lng": 9.3167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 6195,
    "id": 1276654199
  },
  {
    "city": "Geneseo",
    "city_ascii": "Geneseo",
    "lat": 41.4508,
    "lng": -90.154,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 6440,
    "id": 1840008205
  },
  {
    "city": "Gammertingen",
    "city_ascii": "Gammertingen",
    "lat": 48.2494,
    "lng": 9.2175,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 6320,
    "id": 1276612304
  },
  {
    "city": "Vine Grove",
    "city_ascii": "Vine Grove",
    "lat": 37.8133,
    "lng": -85.9828,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kentucky",
    "capital": "",
    "population": 6439,
    "id": 1840015218
  },
  {
    "city": "Portel",
    "city_ascii": "Portel",
    "lat": 38.3,
    "lng": -7.7,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Évora",
    "capital": "minor",
    "population": 6428,
    "id": 1620000002
  },
  {
    "city": "Kununurra",
    "city_ascii": "Kununurra",
    "lat": -15.7736,
    "lng": 128.7386,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Western Australia",
    "capital": "",
    "population": 5308,
    "id": 1036556231
  },
  {
    "city": "Meersburg",
    "city_ascii": "Meersburg",
    "lat": 47.6958,
    "lng": 9.2708,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 5944,
    "id": 1276526391
  },
  {
    "city": "Žamberk",
    "city_ascii": "Zamberk",
    "lat": 50.086,
    "lng": 16.4674,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Pardubický Kraj",
    "capital": "",
    "population": 6077,
    "id": 1203198119
  },
  {
    "city": "Martfů",
    "city_ascii": "Martfu",
    "lat": 47.017,
    "lng": 20.284,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Jász-Nagykun-Szolnok",
    "capital": "",
    "population": 6169,
    "id": 1348824135
  },
  {
    "city": "Devine",
    "city_ascii": "Devine",
    "lat": 29.1457,
    "lng": -98.9049,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 6436,
    "id": 1840019657
  },
  {
    "city": "Litchfield Park",
    "city_ascii": "Litchfield Park",
    "lat": 33.5024,
    "lng": -112.3586,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 6436,
    "id": 1840020565
  },
  {
    "city": "Liberty",
    "city_ascii": "Liberty",
    "lat": 30.0379,
    "lng": -94.788,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 6435,
    "id": 1840020965
  },
  {
    "city": "Oelwein",
    "city_ascii": "Oelwein",
    "lat": 42.6715,
    "lng": -91.9133,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Iowa",
    "capital": "",
    "population": 6433,
    "id": 1840008007
  },
  {
    "city": "Zouar",
    "city_ascii": "Zouar",
    "lat": 20.45,
    "lng": 16.5167,
    "country": "Chad",
    "iso2": "TD",
    "iso3": "TCD",
    "admin_name": "Tibesti",
    "capital": "",
    "population": 6432,
    "id": 1148690803
  },
  {
    "city": "Großbreitenbach",
    "city_ascii": "Grossbreitenbach",
    "lat": 50.5833,
    "lng": 11.012,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Thuringia",
    "capital": "",
    "population": 6352,
    "id": 1276992782
  },
  {
    "city": "Hurricane",
    "city_ascii": "Hurricane",
    "lat": 38.4293,
    "lng": -82.0168,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "West Virginia",
    "capital": "",
    "population": 6430,
    "id": 1840006180
  },
  {
    "city": "Cottenham",
    "city_ascii": "Cottenham",
    "lat": 52.2865,
    "lng": 0.1244,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Cambridgeshire",
    "capital": "",
    "population": 6095,
    "id": 1826834854
  },
  {
    "city": "North Riverside",
    "city_ascii": "North Riverside",
    "lat": 41.8461,
    "lng": -87.8263,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 6429,
    "id": 1840011303
  },
  {
    "city": "Cheverly",
    "city_ascii": "Cheverly",
    "lat": 38.9254,
    "lng": -76.914,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 6428,
    "id": 1840005984
  },
  {
    "city": "Lake Murray of Richland",
    "city_ascii": "Lake Murray of Richland",
    "lat": 34.1209,
    "lng": -81.2653,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 6427,
    "id": 1840073861
  },
  {
    "city": "Sengiley",
    "city_ascii": "Sengiley",
    "lat": 53.9667,
    "lng": 48.8,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Ul’yanovskaya Oblast’",
    "capital": "",
    "population": 6405,
    "id": 1643154611
  },
  {
    "city": "Darnestown",
    "city_ascii": "Darnestown",
    "lat": 39.096,
    "lng": -77.3033,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 6424,
    "id": 1840005833
  },
  {
    "city": "Rumelange",
    "city_ascii": "Rumelange",
    "lat": 49.4597,
    "lng": 6.0306,
    "country": "Luxembourg",
    "iso2": "LU",
    "iso3": "LUX",
    "admin_name": "Esch-sur-Alzette",
    "capital": "minor",
    "population": 5604,
    "id": 1442912029
  },
  {
    "city": "Valley City",
    "city_ascii": "Valley City",
    "lat": 46.9223,
    "lng": -98.0055,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Dakota",
    "capital": "",
    "population": 6423,
    "id": 1840000160
  },
  {
    "city": "Salem",
    "city_ascii": "Salem",
    "lat": 38.6048,
    "lng": -86.0977,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 6422,
    "id": 1840009790
  },
  {
    "city": "Douglas",
    "city_ascii": "Douglas",
    "lat": 42.7541,
    "lng": -105.3968,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wyoming",
    "capital": "",
    "population": 6421,
    "id": 1840018655
  },
  {
    "city": "Hjo",
    "city_ascii": "Hjo",
    "lat": 58.3044,
    "lng": 14.2786,
    "country": "Sweden",
    "iso2": "SE",
    "iso3": "SWE",
    "admin_name": "Västra Götaland",
    "capital": "minor",
    "population": 6351,
    "id": 1752355806
  },
  {
    "city": "Pegau",
    "city_ascii": "Pegau",
    "lat": 51.1667,
    "lng": 12.25,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 6288,
    "id": 1276912473
  },
  {
    "city": "Gogolin",
    "city_ascii": "Gogolin",
    "lat": 50.4881,
    "lng": 18.0239,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Opolskie",
    "capital": "",
    "population": 6116,
    "id": 1616622963
  },
  {
    "city": "Melut",
    "city_ascii": "Melut",
    "lat": 10.4404,
    "lng": 32.2015,
    "country": "South Sudan",
    "iso2": "SS",
    "iso3": "SSD",
    "admin_name": "Upper Nile",
    "capital": "",
    "population": 6407,
    "id": 1728493346
  },
  {
    "city": "Shamong",
    "city_ascii": "Shamong",
    "lat": 39.7781,
    "lng": -74.7272,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 6415,
    "id": 1840081615
  },
  {
    "city": "Walkersville",
    "city_ascii": "Walkersville",
    "lat": 39.4832,
    "lng": -77.3559,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 6415,
    "id": 1840005717
  },
  {
    "city": "Eudora",
    "city_ascii": "Eudora",
    "lat": 38.9345,
    "lng": -95.0957,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kansas",
    "capital": "",
    "population": 6415,
    "id": 1840008558
  },
  {
    "city": "Killingworth",
    "city_ascii": "Killingworth",
    "lat": 41.3792,
    "lng": -72.5785,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 6414,
    "id": 1840004858
  },
  {
    "city": "Garrett",
    "city_ascii": "Garrett",
    "lat": 41.3526,
    "lng": -85.1238,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 6413,
    "id": 1840008207
  },
  {
    "city": "Branford Center",
    "city_ascii": "Branford Center",
    "lat": 41.2779,
    "lng": -72.8148,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 6413,
    "id": 1840073557
  },
  {
    "city": "Waikoloa Village",
    "city_ascii": "Waikoloa Village",
    "lat": 19.9285,
    "lng": -155.8185,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Hawaii",
    "capital": "",
    "population": 6412,
    "id": 1840029587
  },
  {
    "city": "Svislach",
    "city_ascii": "Svislach",
    "lat": 53.0333,
    "lng": 24.1,
    "country": "Belarus",
    "iso2": "BY",
    "iso3": "BLR",
    "admin_name": "Hrodzyenskaya Voblasts’",
    "capital": "minor",
    "population": 6400,
    "id": 1112351893
  },
  {
    "city": "Naco",
    "city_ascii": "Naco",
    "lat": 31.3269,
    "lng": -109.9478,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Sonora",
    "capital": "minor",
    "population": 6401,
    "id": 1484213843
  },
  {
    "city": "Horgoš",
    "city_ascii": "Horgos",
    "lat": 46.1556,
    "lng": 19.9725,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Severno Banatski Okrug",
    "capital": "",
    "population": 6325,
    "id": 1688035977
  },
  {
    "city": "Littlestown",
    "city_ascii": "Littlestown",
    "lat": 39.7452,
    "lng": -77.0892,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 6409,
    "id": 1840000684
  },
  {
    "city": "Parry Sound",
    "city_ascii": "Parry Sound",
    "lat": 45.3333,
    "lng": -80.0333,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 6408,
    "id": 1124245809
  },
  {
    "city": "Schriever",
    "city_ascii": "Schriever",
    "lat": 29.7334,
    "lng": -90.831,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 6407,
    "id": 1840014033
  },
  {
    "city": "Andalusia",
    "city_ascii": "Andalusia",
    "lat": 31.3101,
    "lng": -86.4781,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 6407,
    "id": 1840004591
  },
  {
    "city": "Szentlőrinc",
    "city_ascii": "Szentlorinc",
    "lat": 46.0422,
    "lng": 17.9856,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Baranya",
    "capital": "minor",
    "population": 6184,
    "id": 1348005315
  },
  {
    "city": "Mszczonów",
    "city_ascii": "Mszczonow",
    "lat": 51.9742,
    "lng": 20.5267,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Mazowieckie",
    "capital": "",
    "population": 6323,
    "id": 1616040914
  },
  {
    "city": "Clarkston Heights-Vineland",
    "city_ascii": "Clarkston Heights-Vineland",
    "lat": 46.3876,
    "lng": -117.0831,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 6403,
    "id": 1840073891
  },
  {
    "city": "Alpirsbach",
    "city_ascii": "Alpirsbach",
    "lat": 48.3461,
    "lng": 8.4039,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 6304,
    "id": 1276323724
  },
  {
    "city": "Sabrosa",
    "city_ascii": "Sabrosa",
    "lat": 41.2651,
    "lng": -7.5751,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Vila Real",
    "capital": "minor",
    "population": 6361,
    "id": 1620018088
  },
  {
    "city": "Hampstead",
    "city_ascii": "Hampstead",
    "lat": 39.6104,
    "lng": -76.855,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 6401,
    "id": 1840005699
  },
  {
    "city": "Piedmont",
    "city_ascii": "Piedmont",
    "lat": 34.7074,
    "lng": -82.4651,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 6401,
    "id": 1840013580
  },
  {
    "city": "Fenton",
    "city_ascii": "Fenton",
    "lat": 42.2009,
    "lng": -75.8012,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 6400,
    "id": 1840087429
  },
  {
    "city": "Howland Center",
    "city_ascii": "Howland Center",
    "lat": 41.2483,
    "lng": -80.7444,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 6400,
    "id": 1840034386
  },
  {
    "city": "Carolina Beach",
    "city_ascii": "Carolina Beach",
    "lat": 34.0396,
    "lng": -77.8966,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 6399,
    "id": 1840015575
  },
  {
    "city": "Blackwell",
    "city_ascii": "Blackwell",
    "lat": 36.8011,
    "lng": -97.3008,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oklahoma",
    "capital": "",
    "population": 6399,
    "id": 1840018980
  },
  {
    "city": "Mount Vernon",
    "city_ascii": "Mount Vernon",
    "lat": 41.923,
    "lng": -91.4244,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Iowa",
    "capital": "",
    "population": 6398,
    "id": 1840008100
  },
  {
    "city": "Tabuaço",
    "city_ascii": "Tabuaco",
    "lat": 41.1167,
    "lng": -7.5667,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Viseu",
    "capital": "minor",
    "population": 6350,
    "id": 1620778227
  },
  {
    "city": "Vrbové",
    "city_ascii": "Vrbove",
    "lat": 48.6208,
    "lng": 17.7225,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Trnavský",
    "capital": "",
    "population": 5969,
    "id": 1703979280
  },
  {
    "city": "Pointe-Calumet",
    "city_ascii": "Pointe-Calumet",
    "lat": 45.5,
    "lng": -73.97,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 6396,
    "id": 1124629762
  },
  {
    "city": "Carrazeda de Anciães",
    "city_ascii": "Carrazeda de Anciaes",
    "lat": 41.25,
    "lng": -7.3,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Bragança",
    "capital": "minor",
    "population": 6373,
    "id": 1620152961
  },
  {
    "city": "Greenville",
    "city_ascii": "Greenville",
    "lat": 38.8866,
    "lng": -89.3893,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 6395,
    "id": 1840008563
  },
  {
    "city": "Blaenavon",
    "city_ascii": "Blaenavon",
    "lat": 51.7736,
    "lng": -3.0828,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Torfaen",
    "capital": "",
    "population": 6055,
    "id": 1826515183
  },
  {
    "city": "Grybów",
    "city_ascii": "Grybow",
    "lat": 49.6244,
    "lng": 20.9483,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Małopolskie",
    "capital": "",
    "population": 6038,
    "id": 1616995232
  },
  {
    "city": "Mila Doce",
    "city_ascii": "Mila Doce",
    "lat": 26.223,
    "lng": -97.9601,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 6394,
    "id": 1840037094
  },
  {
    "city": "Marlborough",
    "city_ascii": "Marlborough",
    "lat": 41.6331,
    "lng": -72.4547,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 6394,
    "id": 1840035317
  },
  {
    "city": "Grafenwöhr",
    "city_ascii": "Grafenwohr",
    "lat": 49.7167,
    "lng": 11.9,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 6363,
    "id": 1276973860
  },
  {
    "city": "Wittenburg",
    "city_ascii": "Wittenburg",
    "lat": 53.5,
    "lng": 11.0667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Mecklenburg-Western Pomerania",
    "capital": "",
    "population": 6313,
    "id": 1276816280
  },
  {
    "city": "Rockford",
    "city_ascii": "Rockford",
    "lat": 43.1266,
    "lng": -85.5582,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 6391,
    "id": 1840002924
  },
  {
    "city": "Steilacoom",
    "city_ascii": "Steilacoom",
    "lat": 47.1703,
    "lng": -122.5934,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 6390,
    "id": 1840022309
  },
  {
    "city": "Wollochet",
    "city_ascii": "Wollochet",
    "lat": 47.2828,
    "lng": -122.5769,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 6390,
    "id": 1840023843
  },
  {
    "city": "Yaphank",
    "city_ascii": "Yaphank",
    "lat": 40.8323,
    "lng": -72.9233,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 6390,
    "id": 1840005116
  },
  {
    "city": "Abilene",
    "city_ascii": "Abilene",
    "lat": 38.9229,
    "lng": -97.2251,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kansas",
    "capital": "",
    "population": 6390,
    "id": 1840001629
  },
  {
    "city": "Rhome",
    "city_ascii": "Rhome",
    "lat": 33.0647,
    "lng": -97.4779,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 6387,
    "id": 1840020632
  },
  {
    "city": "Povoação",
    "city_ascii": "Povoacao",
    "lat": 37.746,
    "lng": -25.245,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Azores",
    "capital": "",
    "population": 6327,
    "id": 1620274111
  },
  {
    "city": "Newport",
    "city_ascii": "Newport",
    "lat": 43.3649,
    "lng": -72.2001,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Hampshire",
    "capital": "",
    "population": 6386,
    "id": 1840055268
  },
  {
    "city": "Belpre",
    "city_ascii": "Belpre",
    "lat": 39.2815,
    "lng": -81.5971,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 6386,
    "id": 1840001544
  },
  {
    "city": "Ellenville",
    "city_ascii": "Ellenville",
    "lat": 41.7009,
    "lng": -74.3609,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 6384,
    "id": 1840004733
  },
  {
    "city": "Rushville",
    "city_ascii": "Rushville",
    "lat": 39.6172,
    "lng": -85.4463,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 6383,
    "id": 1840014235
  },
  {
    "city": "International Falls",
    "city_ascii": "International Falls",
    "lat": 48.5884,
    "lng": -93.4083,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 6382,
    "id": 1840007684
  },
  {
    "city": "Përmet",
    "city_ascii": "Permet",
    "lat": 40.2333,
    "lng": 20.35,
    "country": "Albania",
    "iso2": "AL",
    "iso3": "ALB",
    "admin_name": "Gjirokastër",
    "capital": "",
    "population": 5945,
    "id": 1008604833
  },
  {
    "city": "Piatra Olt",
    "city_ascii": "Piatra Olt",
    "lat": 44.3603,
    "lng": 24.2942,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Olt",
    "capital": "",
    "population": 6299,
    "id": 1642105839
  },
  {
    "city": "Naracoorte",
    "city_ascii": "Naracoorte",
    "lat": -36.955,
    "lng": 140.7429,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "South Australia",
    "capital": "",
    "population": 5960,
    "id": 1036098836
  },
  {
    "city": "Corowa",
    "city_ascii": "Corowa",
    "lat": -35.9833,
    "lng": 146.3833,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 5482,
    "id": 1036866154
  },
  {
    "city": "Prosser",
    "city_ascii": "Prosser",
    "lat": 46.2068,
    "lng": -119.7662,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 6380,
    "id": 1840019890
  },
  {
    "city": "Rogersville",
    "city_ascii": "Rogersville",
    "lat": 36.4081,
    "lng": -83.0043,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 6380,
    "id": 1840017699
  },
  {
    "city": "Kostelec nad Orlicí",
    "city_ascii": "Kostelec nad Orlici",
    "lat": 50.1227,
    "lng": 16.2133,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Královéhradecký Kraj",
    "capital": "",
    "population": 6145,
    "id": 1203285144
  },
  {
    "city": "Georges",
    "city_ascii": "Georges",
    "lat": 39.8135,
    "lng": -79.7617,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 6378,
    "id": 1840142198
  },
  {
    "city": "Headley",
    "city_ascii": "Headley",
    "lat": 51.1192,
    "lng": -0.8271,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hampshire",
    "capital": "",
    "population": 5613,
    "id": 1826491547
  },
  {
    "city": "Beaubassin East / Beaubassin-est",
    "city_ascii": "Beaubassin East / Beaubassin-est",
    "lat": 46.1726,
    "lng": -64.3122,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "New Brunswick",
    "capital": "",
    "population": 6376,
    "id": 1124000427
  },
  {
    "city": "Woolgoolga",
    "city_ascii": "Woolgoolga",
    "lat": -30.115,
    "lng": 153.2011,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 5290,
    "id": 1036143859
  },
  {
    "city": "Hronov",
    "city_ascii": "Hronov",
    "lat": 50.4797,
    "lng": 16.1824,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Královéhradecký Kraj",
    "capital": "",
    "population": 6098,
    "id": 1203638022
  },
  {
    "city": "Jasper",
    "city_ascii": "Jasper",
    "lat": 34.471,
    "lng": -84.4496,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 6374,
    "id": 1840014687
  },
  {
    "city": "Arandu",
    "city_ascii": "Arandu",
    "lat": -23.1706,
    "lng": -49.0586,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 6351,
    "id": 1076369696
  },
  {
    "city": "Nitro",
    "city_ascii": "Nitro",
    "lat": 38.4119,
    "lng": -81.8194,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "West Virginia",
    "capital": "",
    "population": 6373,
    "id": 1840006199
  },
  {
    "city": "Wainfleet",
    "city_ascii": "Wainfleet",
    "lat": 42.925,
    "lng": -79.375,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 6372,
    "id": 1124538125
  },
  {
    "city": "Usolye",
    "city_ascii": "Usolye",
    "lat": 59.4167,
    "lng": 56.6833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Permskiy Kray",
    "capital": "",
    "population": 6368,
    "id": 1643126944
  },
  {
    "city": "East Glenville",
    "city_ascii": "East Glenville",
    "lat": 42.8614,
    "lng": -73.9206,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 6371,
    "id": 1840004444
  },
  {
    "city": "Okehampton",
    "city_ascii": "Okehampton",
    "lat": 50.7388,
    "lng": -4.0041,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Devon",
    "capital": "",
    "population": 5922,
    "id": 1826619519
  },
  {
    "city": "Meredith",
    "city_ascii": "Meredith",
    "lat": 43.6301,
    "lng": -71.5018,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Hampshire",
    "capital": "",
    "population": 6369,
    "id": 1840055178
  },
  {
    "city": "Wharton",
    "city_ascii": "Wharton",
    "lat": 40.8999,
    "lng": -74.5808,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 6369,
    "id": 1840000955
  },
  {
    "city": "Traismauer",
    "city_ascii": "Traismauer",
    "lat": 48.3333,
    "lng": 15.7331,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Niederösterreich",
    "capital": "",
    "population": 6224,
    "id": 1040590929
  },
  {
    "city": "Johnsburg",
    "city_ascii": "Johnsburg",
    "lat": 42.383,
    "lng": -88.2476,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 6368,
    "id": 1840011128
  },
  {
    "city": "Buena Vista",
    "city_ascii": "Buena Vista",
    "lat": 37.7319,
    "lng": -79.3569,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 6367,
    "id": 1840003851
  },
  {
    "city": "Sunbury",
    "city_ascii": "Sunbury",
    "lat": 40.2485,
    "lng": -82.8804,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 6367,
    "id": 1840003707
  },
  {
    "city": "Laa an der Thaya",
    "city_ascii": "Laa an der Thaya",
    "lat": 48.7167,
    "lng": 16.3833,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Niederösterreich",
    "capital": "",
    "population": 6280,
    "id": 1040786111
  },
  {
    "city": "Lochbuie",
    "city_ascii": "Lochbuie",
    "lat": 40.0119,
    "lng": -104.7271,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 6366,
    "id": 1840021373
  },
  {
    "city": "Bujor",
    "city_ascii": "Bujor",
    "lat": 45.8667,
    "lng": 27.9,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Galaţi",
    "capital": "",
    "population": 6299,
    "id": 1642660151
  },
  {
    "city": "Heathrow",
    "city_ascii": "Heathrow",
    "lat": 28.7753,
    "lng": -81.3721,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 6364,
    "id": 1840014070
  },
  {
    "city": "Richboro",
    "city_ascii": "Richboro",
    "lat": 40.2262,
    "lng": -75.0006,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 6364,
    "id": 1840005407
  },
  {
    "city": "Goondiwindi",
    "city_ascii": "Goondiwindi",
    "lat": -28.5461,
    "lng": 150.3097,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Queensland",
    "capital": "",
    "population": 6355,
    "id": 1036452066
  },
  {
    "city": "Keyser",
    "city_ascii": "Keyser",
    "lat": 39.4394,
    "lng": -78.9822,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "West Virginia",
    "capital": "",
    "population": 6361,
    "id": 1840005723
  },
  {
    "city": "Dohna",
    "city_ascii": "Dohna",
    "lat": 50.9547,
    "lng": 13.8575,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 6146,
    "id": 1276926166
  },
  {
    "city": "Holmes Chapel",
    "city_ascii": "Holmes Chapel",
    "lat": 53.204,
    "lng": -2.353,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Cheshire East",
    "capital": "",
    "population": 5605,
    "id": 1826901176
  },
  {
    "city": "Itirapuã",
    "city_ascii": "Itirapua",
    "lat": -20.6408,
    "lng": -47.2192,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 6321,
    "id": 1076640162
  },
  {
    "city": "Beith",
    "city_ascii": "Beith",
    "lat": 55.7533,
    "lng": -4.6319,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "North Ayrshire",
    "capital": "",
    "population": 6346,
    "id": 1826483582
  },
  {
    "city": "Dadaab",
    "city_ascii": "Dadaab",
    "lat": 0.0531,
    "lng": 40.3086,
    "country": "Kenya",
    "iso2": "KE",
    "iso3": "KEN",
    "admin_name": "Garissa",
    "capital": "",
    "population": 5723,
    "id": 1404002655
  },
  {
    "city": "Kusatsu",
    "city_ascii": "Kusatsu",
    "lat": 36.6206,
    "lng": 138.5961,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Gunma",
    "capital": "",
    "population": 6232,
    "id": 1392784382
  },
  {
    "city": "Cobleskill",
    "city_ascii": "Cobleskill",
    "lat": 42.6841,
    "lng": -74.4478,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 6357,
    "id": 1840004488
  },
  {
    "city": "Cynthiana",
    "city_ascii": "Cynthiana",
    "lat": 38.386,
    "lng": -84.2993,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kentucky",
    "capital": "",
    "population": 6356,
    "id": 1840014283
  },
  {
    "city": "Tabor City",
    "city_ascii": "Tabor City",
    "lat": 34.1538,
    "lng": -78.8737,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 6355,
    "id": 1840016665
  },
  {
    "city": "Cramahe",
    "city_ascii": "Cramahe",
    "lat": 44.0833,
    "lng": -77.8833,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 6355,
    "id": 1124000879
  },
  {
    "city": "Beauceville",
    "city_ascii": "Beauceville",
    "lat": 46.2,
    "lng": -70.7833,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 6354,
    "id": 1124575286
  },
  {
    "city": "Snyderville",
    "city_ascii": "Snyderville",
    "lat": 40.7042,
    "lng": -111.5438,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Utah",
    "capital": "",
    "population": 6352,
    "id": 1840024187
  },
  {
    "city": "North Middlesex",
    "city_ascii": "North Middlesex",
    "lat": 43.15,
    "lng": -81.6333,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 6352,
    "id": 1124001914
  },
  {
    "city": "Buena Vista",
    "city_ascii": "Buena Vista",
    "lat": 43.4196,
    "lng": -83.8992,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 6351,
    "id": 1840004228
  },
  {
    "city": "Armamar",
    "city_ascii": "Armamar",
    "lat": 41.1167,
    "lng": -7.6833,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Viseu",
    "capital": "minor",
    "population": 6297,
    "id": 1620182147
  },
  {
    "city": "Sandown",
    "city_ascii": "Sandown",
    "lat": 42.9332,
    "lng": -71.1833,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Hampshire",
    "capital": "",
    "population": 6350,
    "id": 1840055523
  },
  {
    "city": "Carroll Township",
    "city_ascii": "Carroll Township",
    "lat": 40.1142,
    "lng": -77.0191,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 6350,
    "id": 1840150917
  },
  {
    "city": "Dacula",
    "city_ascii": "Dacula",
    "lat": 33.9816,
    "lng": -83.8951,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 6350,
    "id": 1840014743
  },
  {
    "city": "Allegan",
    "city_ascii": "Allegan",
    "lat": 42.5299,
    "lng": -85.8462,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 6350,
    "id": 1840003114
  },
  {
    "city": "Lincroft",
    "city_ascii": "Lincroft",
    "lat": 40.3391,
    "lng": -74.1283,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 6348,
    "id": 1840005449
  },
  {
    "city": "Schuyler",
    "city_ascii": "Schuyler",
    "lat": 41.4497,
    "lng": -97.0619,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Nebraska",
    "capital": "",
    "population": 6348,
    "id": 1840009252
  },
  {
    "city": "Orange Lake",
    "city_ascii": "Orange Lake",
    "lat": 41.5319,
    "lng": -74.0936,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 6348,
    "id": 1840004859
  },
  {
    "city": "Tipton",
    "city_ascii": "Tipton",
    "lat": 40.282,
    "lng": -86.0422,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 6348,
    "id": 1840010448
  },
  {
    "city": "Messíni",
    "city_ascii": "Messini",
    "lat": 37.05,
    "lng": 22,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Peloponnísos",
    "capital": "minor",
    "population": 6065,
    "id": 1300524059
  },
  {
    "city": "Swarthmore",
    "city_ascii": "Swarthmore",
    "lat": 39.9023,
    "lng": -75.3488,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 6346,
    "id": 1840000712
  },
  {
    "city": "Krk",
    "city_ascii": "Krk",
    "lat": 45.0261,
    "lng": 14.5756,
    "country": "Croatia",
    "iso2": "HR",
    "iso3": "HRV",
    "admin_name": "Primorsko-Goranska Županija",
    "capital": "minor",
    "population": 6281,
    "id": 1191228076
  },
  {
    "city": "Neumarkt-Sankt Veit",
    "city_ascii": "Neumarkt-Sankt Veit",
    "lat": 48.3667,
    "lng": 12.5,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 6243,
    "id": 1276658424
  },
  {
    "city": "Chipping Norton",
    "city_ascii": "Chipping Norton",
    "lat": 51.94,
    "lng": -1.55,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Oxfordshire",
    "capital": "",
    "population": 6337,
    "id": 1826447113
  },
  {
    "city": "Carmel Hamlet",
    "city_ascii": "Carmel Hamlet",
    "lat": 41.415,
    "lng": -73.6855,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 6345,
    "id": 1840073604
  },
  {
    "city": "Hachenburg",
    "city_ascii": "Hachenburg",
    "lat": 50.6614,
    "lng": 7.8203,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Rhineland-Palatinate",
    "capital": "",
    "population": 6059,
    "id": 1276366046
  },
  {
    "city": "Gladewater",
    "city_ascii": "Gladewater",
    "lat": 32.5426,
    "lng": -94.9465,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 6341,
    "id": 1840020745
  },
  {
    "city": "Lely Resort",
    "city_ascii": "Lely Resort",
    "lat": 26.0889,
    "lng": -81.7031,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 6340,
    "id": 1840028991
  },
  {
    "city": "Kosciusko",
    "city_ascii": "Kosciusko",
    "lat": 33.0584,
    "lng": -89.5893,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Mississippi",
    "capital": "",
    "population": 6340,
    "id": 1840014848
  },
  {
    "city": "Zalaszentgrót",
    "city_ascii": "Zalaszentgrot",
    "lat": 46.9469,
    "lng": 17.0789,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Zala",
    "capital": "minor",
    "population": 6263,
    "id": 1348701752
  },
  {
    "city": "St. John",
    "city_ascii": "St. John",
    "lat": 38.7148,
    "lng": -90.3462,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 6338,
    "id": 1840073797
  },
  {
    "city": "Peñón Blanco",
    "city_ascii": "Penon Blanco",
    "lat": 24.7833,
    "lng": -104.0333,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Durango",
    "capital": "minor",
    "population": 5271,
    "id": 1484011380
  },
  {
    "city": "Bangor Base",
    "city_ascii": "Bangor Base",
    "lat": 47.7227,
    "lng": -122.7146,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 6336,
    "id": 1840036750
  },
  {
    "city": "Cumberland",
    "city_ascii": "Cumberland",
    "lat": 39.8915,
    "lng": -79.9898,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 6334,
    "id": 1840142434
  },
  {
    "city": "Fegyvernek",
    "city_ascii": "Fegyvernek",
    "lat": 47.2667,
    "lng": 20.5333,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Jász-Nagykun-Szolnok",
    "capital": "",
    "population": 6246,
    "id": 1348992141
  },
  {
    "city": "Granby",
    "city_ascii": "Granby",
    "lat": 42.2608,
    "lng": -72.5036,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 6333,
    "id": 1840053459
  },
  {
    "city": "Karasburg",
    "city_ascii": "Karasburg",
    "lat": -28.0172,
    "lng": 18.7478,
    "country": "Namibia",
    "iso2": "NA",
    "iso3": "NAM",
    "admin_name": "//Karas",
    "capital": "",
    "population": 6054,
    "id": 1516005938
  },
  {
    "city": "Silver Lake",
    "city_ascii": "Silver Lake",
    "lat": 34.1408,
    "lng": -77.909,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 6330,
    "id": 1840032901
  },
  {
    "city": "Red Lion",
    "city_ascii": "Red Lion",
    "lat": 39.8986,
    "lng": -76.6078,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 6330,
    "id": 1840001461
  },
  {
    "city": "Pepper Pike",
    "city_ascii": "Pepper Pike",
    "lat": 41.4801,
    "lng": -81.4619,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 6330,
    "id": 1840003408
  },
  {
    "city": "Echaporã",
    "city_ascii": "Echapora",
    "lat": -22.4294,
    "lng": -50.2006,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 6316,
    "id": 1076302994
  },
  {
    "city": "South Gate Ridge",
    "city_ascii": "South Gate Ridge",
    "lat": 27.2856,
    "lng": -82.497,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 6328,
    "id": 1840029079
  },
  {
    "city": "Hamlet",
    "city_ascii": "Hamlet",
    "lat": 34.8891,
    "lng": -79.7099,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 6328,
    "id": 1840013516
  },
  {
    "city": "Olival",
    "city_ascii": "Olival",
    "lat": 41.0711,
    "lng": -8.5272,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Porto",
    "capital": "",
    "population": 5812,
    "id": 1620630570
  },
  {
    "city": "Bensley",
    "city_ascii": "Bensley",
    "lat": 37.447,
    "lng": -77.442,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 6327,
    "id": 1840006408
  },
  {
    "city": "Baloži",
    "city_ascii": "Balozi",
    "lat": 56.875,
    "lng": 24.1194,
    "country": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "admin_name": "Ķekavas Novads",
    "capital": "",
    "population": 6240,
    "id": 1428894887
  },
  {
    "city": "Mýrina",
    "city_ascii": "Myrina",
    "lat": 39.8797,
    "lng": 25.0742,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Voreío Aigaío",
    "capital": "minor",
    "population": 5711,
    "id": 1300592029
  },
  {
    "city": "Combee Settlement",
    "city_ascii": "Combee Settlement",
    "lat": 28.0596,
    "lng": -81.9053,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 6323,
    "id": 1840029027
  },
  {
    "city": "Lionville",
    "city_ascii": "Lionville",
    "lat": 40.0524,
    "lng": -75.644,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 6323,
    "id": 1840005502
  },
  {
    "city": "White Oak",
    "city_ascii": "White Oak",
    "lat": 32.5313,
    "lng": -94.8564,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 6322,
    "id": 1840022080
  },
  {
    "city": "North East",
    "city_ascii": "North East",
    "lat": 42.2,
    "lng": -79.825,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 6322,
    "id": 1840000483
  },
  {
    "city": "Amqui",
    "city_ascii": "Amqui",
    "lat": 48.4667,
    "lng": -67.4333,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 6322,
    "id": 1124681333
  },
  {
    "city": "Norderney",
    "city_ascii": "Norderney",
    "lat": 53.7072,
    "lng": 7.1469,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 6089,
    "id": 1276314539
  },
  {
    "city": "Železný Brod",
    "city_ascii": "Zelezny Brod",
    "lat": 50.6428,
    "lng": 15.2542,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Liberecký Kraj",
    "capital": "",
    "population": 6051,
    "id": 1203728657
  },
  {
    "city": "Dobřany",
    "city_ascii": "Dobrany",
    "lat": 49.6549,
    "lng": 13.2931,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Plzeňský Kraj",
    "capital": "",
    "population": 6145,
    "id": 1203000044
  },
  {
    "city": "Sainte-Catherine-de-la-Jacques-Cartier",
    "city_ascii": "Sainte-Catherine-de-la-Jacques-Cartier",
    "lat": 46.85,
    "lng": -71.6167,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 6319,
    "id": 1124001417
  },
  {
    "city": "Barnhart",
    "city_ascii": "Barnhart",
    "lat": 38.336,
    "lng": -90.4046,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 6318,
    "id": 1840006237
  },
  {
    "city": "Islamorada, Village of Islands",
    "city_ascii": "Islamorada, Village of Islands",
    "lat": 24.9408,
    "lng": -80.6097,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 6317,
    "id": 1840073890
  },
  {
    "city": "Horezu",
    "city_ascii": "Horezu",
    "lat": 45.1433,
    "lng": 23.9917,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Vâlcea",
    "capital": "",
    "population": 6263,
    "id": 1642881778
  },
  {
    "city": "Anhembi",
    "city_ascii": "Anhembi",
    "lat": -22.7897,
    "lng": -48.1278,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 6307,
    "id": 1076000175
  },
  {
    "city": "Mascotte",
    "city_ascii": "Mascotte",
    "lat": 28.611,
    "lng": -81.9107,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 6315,
    "id": 1840015951
  },
  {
    "city": "Bátaszék",
    "city_ascii": "Bataszek",
    "lat": 46.1833,
    "lng": 18.7167,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Tolna",
    "capital": "",
    "population": 6217,
    "id": 1348682463
  },
  {
    "city": "Asakawa",
    "city_ascii": "Asakawa",
    "lat": 37.0808,
    "lng": 140.4128,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Fukushima",
    "capital": "",
    "population": 6146,
    "id": 1392650159
  },
  {
    "city": "Jedlicze",
    "city_ascii": "Jedlicze",
    "lat": 49.7164,
    "lng": 21.6456,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Podkarpackie",
    "capital": "",
    "population": 5766,
    "id": 1616854672
  },
  {
    "city": "Delle",
    "city_ascii": "Delle",
    "lat": 47.5069,
    "lng": 6.9981,
    "country": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "admin_name": "Bourgogne-Franche-Comté",
    "capital": "",
    "population": 5690,
    "id": 1250527213
  },
  {
    "city": "Ohio",
    "city_ascii": "Ohio",
    "lat": 40.5425,
    "lng": -80.0926,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 6308,
    "id": 1840141803
  },
  {
    "city": "South Run",
    "city_ascii": "South Run",
    "lat": 38.7467,
    "lng": -77.2754,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 6308,
    "id": 1840041864
  },
  {
    "city": "Gulf Park Estates",
    "city_ascii": "Gulf Park Estates",
    "lat": 30.3801,
    "lng": -88.7581,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Mississippi",
    "capital": "",
    "population": 6308,
    "id": 1840032475
  },
  {
    "city": "Zubtsov",
    "city_ascii": "Zubtsov",
    "lat": 56.1667,
    "lng": 34.5833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Tverskaya Oblast’",
    "capital": "",
    "population": 6300,
    "id": 1643790140
  },
  {
    "city": "Pass Christian",
    "city_ascii": "Pass Christian",
    "lat": 30.327,
    "lng": -89.2436,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Mississippi",
    "capital": "",
    "population": 6307,
    "id": 1840015024
  },
  {
    "city": "Balkány",
    "city_ascii": "Balkany",
    "lat": 47.7694,
    "lng": 21.8569,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Szabolcs-Szatmár-Bereg",
    "capital": "",
    "population": 6238,
    "id": 1348636165
  },
  {
    "city": "Neustadt",
    "city_ascii": "Neustadt",
    "lat": 49.7306,
    "lng": 12.1706,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "minor",
    "population": 5727,
    "id": 1276346080
  },
  {
    "city": "Cobram",
    "city_ascii": "Cobram",
    "lat": -35.9667,
    "lng": 145.65,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Victoria",
    "capital": "",
    "population": 6014,
    "id": 1036438159
  },
  {
    "city": "Jeanerette",
    "city_ascii": "Jeanerette",
    "lat": 29.9157,
    "lng": -91.6758,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 6303,
    "id": 1840015054
  },
  {
    "city": "Waveland",
    "city_ascii": "Waveland",
    "lat": 30.293,
    "lng": -89.3904,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Mississippi",
    "capital": "",
    "population": 6302,
    "id": 1840015918
  },
  {
    "city": "Forest Hills",
    "city_ascii": "Forest Hills",
    "lat": 40.4251,
    "lng": -79.8544,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 6298,
    "id": 1840001232
  },
  {
    "city": "Rowley",
    "city_ascii": "Rowley",
    "lat": 42.7224,
    "lng": -70.8883,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 6298,
    "id": 1840053538
  },
  {
    "city": "Lubizhdë",
    "city_ascii": "Lubizhde",
    "lat": 42.238,
    "lng": 20.7615,
    "country": "Kosovo",
    "iso2": "XK",
    "iso3": "XKS",
    "admin_name": "Prizren",
    "capital": "",
    "population": 5982,
    "id": 1901080082
  },
  {
    "city": "Marsh Harbour",
    "city_ascii": "Marsh Harbour",
    "lat": 26.4,
    "lng": -77.17,
    "country": "Bahamas, The",
    "iso2": "BS",
    "iso3": "BHS",
    "admin_name": "Central Abaco",
    "capital": "",
    "population": 6283,
    "id": 1044773084
  },
  {
    "city": "Tarleton",
    "city_ascii": "Tarleton",
    "lat": 53.678,
    "lng": -2.841,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Lancashire",
    "capital": "",
    "population": 5652,
    "id": 1826930345
  },
  {
    "city": "Mattapoisett",
    "city_ascii": "Mattapoisett",
    "lat": 41.6687,
    "lng": -70.817,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 6294,
    "id": 1840070409
  },
  {
    "city": "Antis",
    "city_ascii": "Antis",
    "lat": 40.6221,
    "lng": -78.3563,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 6291,
    "id": 1840152337
  },
  {
    "city": "Clarenville",
    "city_ascii": "Clarenville",
    "lat": 48.1566,
    "lng": -53.965,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Newfoundland and Labrador",
    "capital": "",
    "population": 6291,
    "id": 1124924217
  },
  {
    "city": "Inverness Highlands South",
    "city_ascii": "Inverness Highlands South",
    "lat": 28.8007,
    "lng": -82.3371,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 6288,
    "id": 1840073844
  },
  {
    "city": "Geisingen",
    "city_ascii": "Geisingen",
    "lat": 47.9222,
    "lng": 8.6464,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 6202,
    "id": 1276524264
  },
  {
    "city": "Bridgeport",
    "city_ascii": "Bridgeport",
    "lat": 33.21,
    "lng": -97.7708,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 6286,
    "id": 1840019384
  },
  {
    "city": "Port Saint John’s",
    "city_ascii": "Port Saint John's",
    "lat": -31.6288,
    "lng": 29.5369,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "Eastern Cape",
    "capital": "",
    "population": 5939,
    "id": 1710506018
  },
  {
    "city": "Oberlungwitz",
    "city_ascii": "Oberlungwitz",
    "lat": 50.7833,
    "lng": 12.7167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 5881,
    "id": 1276381845
  },
  {
    "city": "Stone Mountain",
    "city_ascii": "Stone Mountain",
    "lat": 33.8034,
    "lng": -84.1724,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 6281,
    "id": 1840015645
  },
  {
    "city": "Mont-Joli",
    "city_ascii": "Mont-Joli",
    "lat": 48.58,
    "lng": -68.18,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 6281,
    "id": 1124642037
  },
  {
    "city": "Sursk",
    "city_ascii": "Sursk",
    "lat": 53.0833,
    "lng": 45.7,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Penzenskaya Oblast’",
    "capital": "",
    "population": 6254,
    "id": 1643139137
  },
  {
    "city": "Glücksburg",
    "city_ascii": "Glucksburg",
    "lat": 54.8336,
    "lng": 9.55,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Schleswig-Holstein",
    "capital": "",
    "population": 6124,
    "id": 1276697636
  },
  {
    "city": "Wolfeboro",
    "city_ascii": "Wolfeboro",
    "lat": 43.6117,
    "lng": -71.1705,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Hampshire",
    "capital": "",
    "population": 6278,
    "id": 1840055884
  },
  {
    "city": "East Foothills",
    "city_ascii": "East Foothills",
    "lat": 37.3827,
    "lng": -121.8138,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 6278,
    "id": 1840028369
  },
  {
    "city": "California",
    "city_ascii": "California",
    "lat": 40.0692,
    "lng": -79.9152,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 6276,
    "id": 1840003642
  },
  {
    "city": "Ardud",
    "city_ascii": "Ardud",
    "lat": 47.6333,
    "lng": 22.8833,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Satu Mare",
    "capital": "",
    "population": 6231,
    "id": 1642782986
  },
  {
    "city": "New Castle",
    "city_ascii": "New Castle",
    "lat": 39.5776,
    "lng": -107.5267,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 6275,
    "id": 1840022429
  },
  {
    "city": "St. Robert",
    "city_ascii": "St. Robert",
    "lat": 37.8244,
    "lng": -92.1532,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 6275,
    "id": 1840009866
  },
  {
    "city": "Gildersome",
    "city_ascii": "Gildersome",
    "lat": 53.759,
    "lng": -1.6322,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Leeds",
    "capital": "",
    "population": 5804,
    "id": 1826345595
  },
  {
    "city": "Rötha",
    "city_ascii": "Rotha",
    "lat": 51.1972,
    "lng": 12.4172,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 6141,
    "id": 1276132999
  },
  {
    "city": "Kojetín",
    "city_ascii": "Kojetin",
    "lat": 49.3518,
    "lng": 17.3021,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Olomoucký Kraj",
    "capital": "",
    "population": 6078,
    "id": 1203221005
  },
  {
    "city": "Dąbrowa Białostocka",
    "city_ascii": "Dabrowa Bialostocka",
    "lat": 53.65,
    "lng": 23.35,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Podlaskie",
    "capital": "",
    "population": 6226,
    "id": 1616548793
  },
  {
    "city": "Matsuzaki",
    "city_ascii": "Matsuzaki",
    "lat": 34.7531,
    "lng": 138.7789,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Shizuoka",
    "capital": "",
    "population": 6200,
    "id": 1392566701
  },
  {
    "city": "Figueira de Castelo Rodrigo",
    "city_ascii": "Figueira de Castelo Rodrigo",
    "lat": 40.9,
    "lng": -6.9667,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Guarda",
    "capital": "minor",
    "population": 6260,
    "id": 1620406895
  },
  {
    "city": "Wainwright",
    "city_ascii": "Wainwright",
    "lat": 52.8333,
    "lng": -110.8667,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Alberta",
    "capital": "",
    "population": 6270,
    "id": 1124385336
  },
  {
    "city": "Burntisland",
    "city_ascii": "Burntisland",
    "lat": 56.06,
    "lng": -3.231,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Fife",
    "capital": "",
    "population": 6269,
    "id": 1826811274
  },
  {
    "city": "Iowa Park",
    "city_ascii": "Iowa Park",
    "lat": 33.9622,
    "lng": -98.6819,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 6268,
    "id": 1840019279
  },
  {
    "city": "Donji Miholjac",
    "city_ascii": "Donji Miholjac",
    "lat": 45.75,
    "lng": 18.15,
    "country": "Croatia",
    "iso2": "HR",
    "iso3": "HRV",
    "admin_name": "Osječko-Baranjska Županija",
    "capital": "minor",
    "population": 6226,
    "id": 1191901775
  },
  {
    "city": "Pigeon Forge",
    "city_ascii": "Pigeon Forge",
    "lat": 35.7977,
    "lng": -83.5623,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 6266,
    "id": 1840014500
  },
  {
    "city": "Huntington Woods",
    "city_ascii": "Huntington Woods",
    "lat": 42.4816,
    "lng": -83.1685,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 6265,
    "id": 1840002445
  },
  {
    "city": "Lecanto",
    "city_ascii": "Lecanto",
    "lat": 28.8359,
    "lng": -82.488,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 6264,
    "id": 1840014064
  },
  {
    "city": "Taiaçu",
    "city_ascii": "Taiacu",
    "lat": -21.1439,
    "lng": -48.5128,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 6205,
    "id": 1076819994
  },
  {
    "city": "Blahovishchenske",
    "city_ascii": "Blahovishchenske",
    "lat": 48.3197,
    "lng": 30.2353,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Kirovohrads’ka Oblast’",
    "capital": "minor",
    "population": 6232,
    "id": 1804644971
  },
  {
    "city": "Maffra",
    "city_ascii": "Maffra",
    "lat": -37.95,
    "lng": 146.983,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Victoria",
    "capital": "",
    "population": 5280,
    "id": 1036194694
  },
  {
    "city": "Richmond",
    "city_ascii": "Richmond",
    "lat": 42.8094,
    "lng": -82.7518,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 6262,
    "id": 1840003091
  },
  {
    "city": "Ingeniero Jacobacci",
    "city_ascii": "Ingeniero Jacobacci",
    "lat": -41.3,
    "lng": -69.5833,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Río Negro",
    "capital": "",
    "population": 6261,
    "id": 1032402027
  },
  {
    "city": "Homer",
    "city_ascii": "Homer",
    "lat": 42.6661,
    "lng": -76.1701,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 6261,
    "id": 1840004497
  },
  {
    "city": "Stowe",
    "city_ascii": "Stowe",
    "lat": 40.4829,
    "lng": -80.0734,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 6260,
    "id": 1840150309
  },
  {
    "city": "Oakland",
    "city_ascii": "Oakland",
    "lat": 44.5595,
    "lng": -69.7328,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maine",
    "capital": "",
    "population": 6260,
    "id": 1840052940
  },
  {
    "city": "Tahmoor",
    "city_ascii": "Tahmoor",
    "lat": -34.2233,
    "lng": 150.5928,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 5067,
    "id": 1036345521
  },
  {
    "city": "Lake Alfred",
    "city_ascii": "Lake Alfred",
    "lat": 28.1041,
    "lng": -81.7264,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 6257,
    "id": 1840015106
  },
  {
    "city": "Mayfield",
    "city_ascii": "Mayfield",
    "lat": 43.1248,
    "lng": -74.26,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 6256,
    "id": 1840004309
  },
  {
    "city": "Morris Plains",
    "city_ascii": "Morris Plains",
    "lat": 40.8357,
    "lng": -74.4786,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 6255,
    "id": 1840003576
  },
  {
    "city": "Lambert’s Bay",
    "city_ascii": "Lambert's Bay",
    "lat": -32.0833,
    "lng": 18.3,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "Western Cape",
    "capital": "",
    "population": 6120,
    "id": 1710515323
  },
  {
    "city": "Pinxton",
    "city_ascii": "Pinxton",
    "lat": 53.0905,
    "lng": -1.3237,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Derbyshire",
    "capital": "",
    "population": 5699,
    "id": 1826634910
  },
  {
    "city": "Powell",
    "city_ascii": "Powell",
    "lat": 44.7958,
    "lng": -108.7681,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wyoming",
    "capital": "",
    "population": 6253,
    "id": 1840019976
  },
  {
    "city": "Ashburnham",
    "city_ascii": "Ashburnham",
    "lat": 42.659,
    "lng": -71.9252,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 6253,
    "id": 1840053581
  },
  {
    "city": "Glyncorrwg",
    "city_ascii": "Glyncorrwg",
    "lat": 51.6776,
    "lng": -3.6292,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Neath Port Talbot",
    "capital": "",
    "population": 5283,
    "id": 1826106322
  },
  {
    "city": "Contrecoeur",
    "city_ascii": "Contrecoeur",
    "lat": 45.85,
    "lng": -73.2333,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 6252,
    "id": 1124384220
  },
  {
    "city": "Corcoran",
    "city_ascii": "Corcoran",
    "lat": 45.1089,
    "lng": -93.5837,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 6250,
    "id": 1840006744
  },
  {
    "city": "Walker",
    "city_ascii": "Walker",
    "lat": 30.485,
    "lng": -90.8656,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 6248,
    "id": 1840017185
  },
  {
    "city": "Wilkins",
    "city_ascii": "Wilkins",
    "lat": 40.4265,
    "lng": -79.823,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 6247,
    "id": 1840150316
  },
  {
    "city": "Spangenberg",
    "city_ascii": "Spangenberg",
    "lat": 51.1167,
    "lng": 9.6667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 6183,
    "id": 1276172106
  },
  {
    "city": "Woodend",
    "city_ascii": "Woodend",
    "lat": -37.363,
    "lng": 144.526,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Victoria",
    "capital": "",
    "population": 5806,
    "id": 1036376029
  },
  {
    "city": "Dennis",
    "city_ascii": "Dennis",
    "lat": 39.2019,
    "lng": -74.8188,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 6244,
    "id": 1840081577
  },
  {
    "city": "Elva",
    "city_ascii": "Elva",
    "lat": 58.2275,
    "lng": 26.4158,
    "country": "Estonia",
    "iso2": "EE",
    "iso3": "EST",
    "admin_name": "Tartumaa",
    "capital": "minor",
    "population": 5669,
    "id": 1233576584
  },
  {
    "city": "New Whiteland",
    "city_ascii": "New Whiteland",
    "lat": 39.5617,
    "lng": -86.0996,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 6241,
    "id": 1840009570
  },
  {
    "city": "Sylvester",
    "city_ascii": "Sylvester",
    "lat": 31.53,
    "lng": -83.8338,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 6240,
    "id": 1840015855
  },
  {
    "city": "Holly Springs",
    "city_ascii": "Holly Springs",
    "lat": 34.7768,
    "lng": -89.4466,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Mississippi",
    "capital": "",
    "population": 6239,
    "id": 1840014644
  },
  {
    "city": "Boonville",
    "city_ascii": "Boonville",
    "lat": 38.0469,
    "lng": -87.2846,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 6238,
    "id": 1840007528
  },
  {
    "city": "Inverloch",
    "city_ascii": "Inverloch",
    "lat": -38.6334,
    "lng": 145.7278,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Victoria",
    "capital": "",
    "population": 5437,
    "id": 1036393902
  },
  {
    "city": "Murillo",
    "city_ascii": "Murillo",
    "lat": 26.2642,
    "lng": -98.1233,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 6236,
    "id": 1840037125
  },
  {
    "city": "Orange Beach",
    "city_ascii": "Orange Beach",
    "lat": 30.2941,
    "lng": -87.5851,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 6235,
    "id": 1840014980
  },
  {
    "city": "Solvay",
    "city_ascii": "Solvay",
    "lat": 43.0573,
    "lng": -76.2126,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 6234,
    "id": 1840004328
  },
  {
    "city": "Whittlesea",
    "city_ascii": "Whittlesea",
    "lat": -37.5139,
    "lng": 145.114,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Victoria",
    "capital": "",
    "population": 5611,
    "id": 1036742059
  },
  {
    "city": "Galax",
    "city_ascii": "Galax",
    "lat": 36.6661,
    "lng": -80.9176,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 6232,
    "id": 1840003877
  },
  {
    "city": "Newberry",
    "city_ascii": "Newberry",
    "lat": 29.6385,
    "lng": -82.6057,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 6231,
    "id": 1840015058
  },
  {
    "city": "Lebanon",
    "city_ascii": "Lebanon",
    "lat": 43.4033,
    "lng": -70.9115,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maine",
    "capital": "",
    "population": 6230,
    "id": 1840052875
  },
  {
    "city": "Glenwood",
    "city_ascii": "Glenwood",
    "lat": 41.0446,
    "lng": -95.7408,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Iowa",
    "capital": "",
    "population": 6226,
    "id": 1840008273
  },
  {
    "city": "Vesyegonsk",
    "city_ascii": "Vesyegonsk",
    "lat": 58.65,
    "lng": 37.2667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Tverskaya Oblast’",
    "capital": "",
    "population": 6219,
    "id": 1643529453
  },
  {
    "city": "Zhur",
    "city_ascii": "Zhur",
    "lat": 42.1639,
    "lng": 20.6211,
    "country": "Kosovo",
    "iso2": "XK",
    "iso3": "XKS",
    "admin_name": "Prizren",
    "capital": "",
    "population": 5909,
    "id": 1901520274
  },
  {
    "city": "Kenedy",
    "city_ascii": "Kenedy",
    "lat": 28.8176,
    "lng": -97.8518,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 6223,
    "id": 1840019684
  },
  {
    "city": "Hillandale",
    "city_ascii": "Hillandale",
    "lat": 39.0254,
    "lng": -76.9751,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 6223,
    "id": 1840005841
  },
  {
    "city": "Edinboro",
    "city_ascii": "Edinboro",
    "lat": 41.8762,
    "lng": -80.1246,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 6222,
    "id": 1840000476
  },
  {
    "city": "Murashi",
    "city_ascii": "Murashi",
    "lat": 59.3833,
    "lng": 48.9667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kirovskaya Oblast’",
    "capital": "",
    "population": 6218,
    "id": 1643329165
  },
  {
    "city": "Addis",
    "city_ascii": "Addis",
    "lat": 30.3652,
    "lng": -91.2659,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 6221,
    "id": 1840015915
  },
  {
    "city": "Verona",
    "city_ascii": "Verona",
    "lat": 43.1592,
    "lng": -75.6189,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 6221,
    "id": 1840058561
  },
  {
    "city": "Fruit Heights",
    "city_ascii": "Fruit Heights",
    "lat": 41.0277,
    "lng": -111.9081,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Utah",
    "capital": "",
    "population": 6221,
    "id": 1840020143
  },
  {
    "city": "Pratt",
    "city_ascii": "Pratt",
    "lat": 37.6764,
    "lng": -98.7451,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kansas",
    "capital": "",
    "population": 6220,
    "id": 1840001687
  },
  {
    "city": "Colômbia",
    "city_ascii": "Colombia",
    "lat": -20.1758,
    "lng": -48.6889,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 6211,
    "id": 1076258609
  },
  {
    "city": "Swanwick",
    "city_ascii": "Swanwick",
    "lat": 53.075,
    "lng": -1.398,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Derbyshire",
    "capital": "",
    "population": 5084,
    "id": 1826296193
  },
  {
    "city": "Mórahalom",
    "city_ascii": "Morahalom",
    "lat": 46.2167,
    "lng": 19.8833,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Csongrád",
    "capital": "minor",
    "population": 6145,
    "id": 1348258355
  },
  {
    "city": "Gloggnitz",
    "city_ascii": "Gloggnitz",
    "lat": 47.6758,
    "lng": 15.9383,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Niederösterreich",
    "capital": "",
    "population": 5916,
    "id": 1040986050
  },
  {
    "city": "Hustopeče",
    "city_ascii": "Hustopece",
    "lat": 48.9409,
    "lng": 16.7376,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Jihomoravský Kraj",
    "capital": "",
    "population": 5975,
    "id": 1203611571
  },
  {
    "city": "Demidov",
    "city_ascii": "Demidov",
    "lat": 55.2667,
    "lng": 31.5167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Smolenskaya Oblast’",
    "capital": "",
    "population": 6213,
    "id": 1643626996
  },
  {
    "city": "Khūr",
    "city_ascii": "Khur",
    "lat": 33.775,
    "lng": 55.0831,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Eşfahān",
    "capital": "minor",
    "population": 6216,
    "id": 1364503570
  },
  {
    "city": "Pea Ridge",
    "city_ascii": "Pea Ridge",
    "lat": 38.4154,
    "lng": -82.3188,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "West Virginia",
    "capital": "",
    "population": 6215,
    "id": 1840038311
  },
  {
    "city": "Nabburg",
    "city_ascii": "Nabburg",
    "lat": 49.4533,
    "lng": 12.1808,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 6117,
    "id": 1276493973
  },
  {
    "city": "Tsarevo",
    "city_ascii": "Tsarevo",
    "lat": 42.1708,
    "lng": 27.8511,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Burgas",
    "capital": "",
    "population": 6196,
    "id": 1100848106
  },
  {
    "city": "Bad Hönningen",
    "city_ascii": "Bad Honningen",
    "lat": 50.5178,
    "lng": 7.3086,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Rhineland-Palatinate",
    "capital": "",
    "population": 5920,
    "id": 1276936737
  },
  {
    "city": "Hethersett",
    "city_ascii": "Hethersett",
    "lat": 52.5983,
    "lng": 1.1833,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Norfolk",
    "capital": "",
    "population": 5691,
    "id": 1826313162
  },
  {
    "city": "Brownville",
    "city_ascii": "Brownville",
    "lat": 44.0298,
    "lng": -76.053,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 6213,
    "id": 1840004123
  },
  {
    "city": "Wescosville",
    "city_ascii": "Wescosville",
    "lat": 40.5617,
    "lng": -75.5489,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 6213,
    "id": 1840035250
  },
  {
    "city": "Chelan",
    "city_ascii": "Chelan",
    "lat": 47.8414,
    "lng": -120.0263,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 6212,
    "id": 1840018369
  },
  {
    "city": "Blue Bell",
    "city_ascii": "Blue Bell",
    "lat": 40.1474,
    "lng": -75.2687,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 6212,
    "id": 1840034868
  },
  {
    "city": "Nephi",
    "city_ascii": "Nephi",
    "lat": 39.7072,
    "lng": -111.8323,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Utah",
    "capital": "",
    "population": 6212,
    "id": 1840020188
  },
  {
    "city": "Pea Ridge",
    "city_ascii": "Pea Ridge",
    "lat": 36.449,
    "lng": -94.1211,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arkansas",
    "capital": "",
    "population": 6211,
    "id": 1840014443
  },
  {
    "city": "Kingston",
    "city_ascii": "Kingston",
    "lat": 42.9156,
    "lng": -71.0665,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Hampshire",
    "capital": "",
    "population": 6210,
    "id": 1840055052
  },
  {
    "city": "Blakely",
    "city_ascii": "Blakely",
    "lat": 41.4859,
    "lng": -75.6012,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 6210,
    "id": 1840003375
  },
  {
    "city": "Covedale",
    "city_ascii": "Covedale",
    "lat": 39.1267,
    "lng": -84.637,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 6209,
    "id": 1840034226
  },
  {
    "city": "Montgomery Township",
    "city_ascii": "Montgomery Township",
    "lat": 39.7717,
    "lng": -77.8979,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 6207,
    "id": 1840147458
  },
  {
    "city": "Gelnica",
    "city_ascii": "Gelnica",
    "lat": 48.855,
    "lng": 20.9397,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Košický",
    "capital": "minor",
    "population": 6099,
    "id": 1703458048
  },
  {
    "city": "Figueiró dos Vinhos",
    "city_ascii": "Figueiro dos Vinhos",
    "lat": 39.9,
    "lng": -8.2667,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Leiria",
    "capital": "minor",
    "population": 6169,
    "id": 1620942909
  },
  {
    "city": "Twin Lakes",
    "city_ascii": "Twin Lakes",
    "lat": 42.5192,
    "lng": -88.2489,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 6204,
    "id": 1840003159
  },
  {
    "city": "East Calder",
    "city_ascii": "East Calder",
    "lat": 55.896,
    "lng": -3.462,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "West Lothian",
    "capital": "",
    "population": 5790,
    "id": 1826487904
  },
  {
    "city": "Fishhook",
    "city_ascii": "Fishhook",
    "lat": 61.711,
    "lng": -149.2657,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alaska",
    "capital": "",
    "population": 6203,
    "id": 1840037578
  },
  {
    "city": "Everman",
    "city_ascii": "Everman",
    "lat": 32.6296,
    "lng": -97.2827,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 6201,
    "id": 1840020694
  },
  {
    "city": "Union Gap",
    "city_ascii": "Union Gap",
    "lat": 46.5566,
    "lng": -120.4977,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 6200,
    "id": 1840021152
  },
  {
    "city": "Saint-Joseph-du-Lac",
    "city_ascii": "Saint-Joseph-du-Lac",
    "lat": 45.5333,
    "lng": -74,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 6195,
    "id": 1124001195
  },
  {
    "city": "Satsuma",
    "city_ascii": "Satsuma",
    "lat": 30.8577,
    "lng": -88.0632,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 6190,
    "id": 1840015887
  },
  {
    "city": "Heathcote",
    "city_ascii": "Heathcote",
    "lat": 40.3908,
    "lng": -74.5756,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 6189,
    "id": 1840033480
  },
  {
    "city": "Kirton",
    "city_ascii": "Kirton",
    "lat": 52.9278,
    "lng": -0.0598,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Lincolnshire",
    "capital": "",
    "population": 5371,
    "id": 1826587031
  },
  {
    "city": "Temnikov",
    "city_ascii": "Temnikov",
    "lat": 54.6333,
    "lng": 43.2167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Mordoviya",
    "capital": "",
    "population": 6179,
    "id": 1643923726
  },
  {
    "city": "Lütjenburg",
    "city_ascii": "Lutjenburg",
    "lat": 54.2947,
    "lng": 10.5914,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Schleswig-Holstein",
    "capital": "",
    "population": 5322,
    "id": 1276401269
  },
  {
    "city": "Vilseck",
    "city_ascii": "Vilseck",
    "lat": 49.6,
    "lng": 11.8,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 6093,
    "id": 1276068509
  },
  {
    "city": "Riviersonderend",
    "city_ascii": "Riviersonderend",
    "lat": -34.15,
    "lng": 19.9,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "Western Cape",
    "capital": "",
    "population": 5245,
    "id": 1710437145
  },
  {
    "city": "Fort Edward",
    "city_ascii": "Fort Edward",
    "lat": 43.2294,
    "lng": -73.56,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 6186,
    "id": 1840004175
  },
  {
    "city": "Chamberlayne",
    "city_ascii": "Chamberlayne",
    "lat": 37.628,
    "lng": -77.4288,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 6186,
    "id": 1840037466
  },
  {
    "city": "Dyce",
    "city_ascii": "Dyce",
    "lat": 57.2031,
    "lng": -2.192,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Aberdeen City",
    "capital": "",
    "population": 5811,
    "id": 1826467438
  },
  {
    "city": "Keyes",
    "city_ascii": "Keyes",
    "lat": 37.5618,
    "lng": -120.9088,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 6185,
    "id": 1840017618
  },
  {
    "city": "Slănic",
    "city_ascii": "Slanic",
    "lat": 45.2333,
    "lng": 25.9392,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Prahova",
    "capital": "",
    "population": 6034,
    "id": 1642467949
  },
  {
    "city": "Sheridan",
    "city_ascii": "Sheridan",
    "lat": 39.6466,
    "lng": -105.0181,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 6183,
    "id": 1840021440
  },
  {
    "city": "Leonardtown",
    "city_ascii": "Leonardtown",
    "lat": 38.3039,
    "lng": -76.6396,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 6182,
    "id": 1840006232
  },
  {
    "city": "Steyning",
    "city_ascii": "Steyning",
    "lat": 50.8868,
    "lng": -0.3279,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "West Sussex",
    "capital": "",
    "population": 5812,
    "id": 1826407829
  },
  {
    "city": "Naples Park",
    "city_ascii": "Naples Park",
    "lat": 26.2633,
    "lng": -81.8094,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 6181,
    "id": 1840014233
  },
  {
    "city": "Hope",
    "city_ascii": "Hope",
    "lat": 49.3858,
    "lng": -121.4419,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "British Columbia",
    "capital": "",
    "population": 6181,
    "id": 1124662863
  },
  {
    "city": "Gimli",
    "city_ascii": "Gimli",
    "lat": 50.6619,
    "lng": -97.0297,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Manitoba",
    "capital": "",
    "population": 6181,
    "id": 1124472413
  },
  {
    "city": "Limbourg",
    "city_ascii": "Limbourg",
    "lat": 50.6122,
    "lng": 5.9403,
    "country": "Belgium",
    "iso2": "BE",
    "iso3": "BEL",
    "admin_name": "Wallonia",
    "capital": "",
    "population": 5939,
    "id": 1056726615
  },
  {
    "city": "Eastman",
    "city_ascii": "Eastman",
    "lat": 32.1973,
    "lng": -83.1714,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 6180,
    "id": 1840013829
  },
  {
    "city": "West Greenwich",
    "city_ascii": "West Greenwich",
    "lat": 41.6291,
    "lng": -71.6671,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Rhode Island",
    "capital": "",
    "population": 6179,
    "id": 1840106222
  },
  {
    "city": "Dusheti",
    "city_ascii": "Dusheti",
    "lat": 42.0845,
    "lng": 44.6961,
    "country": "Georgia",
    "iso2": "GE",
    "iso3": "GEO",
    "admin_name": "Mtskheta Mtianeti",
    "capital": "minor",
    "population": 6167,
    "id": 1268483712
  },
  {
    "city": "South Monroe",
    "city_ascii": "South Monroe",
    "lat": 41.893,
    "lng": -83.4179,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 6176,
    "id": 1840006577
  },
  {
    "city": "Corry",
    "city_ascii": "Corry",
    "lat": 41.9259,
    "lng": -79.6358,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 6175,
    "id": 1840000474
  },
  {
    "city": "University at Buffalo",
    "city_ascii": "University at Buffalo",
    "lat": 43.0025,
    "lng": -78.7887,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 6173,
    "id": 1840043236
  },
  {
    "city": "Silveiras",
    "city_ascii": "Silveiras",
    "lat": -22.6639,
    "lng": -44.8528,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 6158,
    "id": 1076497222
  },
  {
    "city": "Solt",
    "city_ascii": "Solt",
    "lat": 46.8008,
    "lng": 19.0042,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Bács-Kiskun",
    "capital": "",
    "population": 6125,
    "id": 1348965448
  },
  {
    "city": "Mims",
    "city_ascii": "Mims",
    "lat": 28.6928,
    "lng": -80.8468,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 6171,
    "id": 1840014078
  },
  {
    "city": "Týniště nad Orlicí",
    "city_ascii": "Tyniste nad Orlici",
    "lat": 50.1514,
    "lng": 16.0778,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Královéhradecký Kraj",
    "capital": "",
    "population": 6055,
    "id": 1203543761
  },
  {
    "city": "Nieheim",
    "city_ascii": "Nieheim",
    "lat": 51.7997,
    "lng": 9.1097,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "North Rhine-Westphalia",
    "capital": "",
    "population": 6093,
    "id": 1276745307
  },
  {
    "city": "Emerald",
    "city_ascii": "Emerald",
    "lat": -37.9331,
    "lng": 145.437,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Victoria",
    "capital": "",
    "population": 5778,
    "id": 1036823239
  },
  {
    "city": "Treffurt",
    "city_ascii": "Treffurt",
    "lat": 51.1367,
    "lng": 10.2375,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Thuringia",
    "capital": "",
    "population": 6084,
    "id": 1276953886
  },
  {
    "city": "Richmond",
    "city_ascii": "Richmond",
    "lat": -33.5983,
    "lng": 150.7511,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 5482,
    "id": 1036934091
  },
  {
    "city": "Dartmouth",
    "city_ascii": "Dartmouth",
    "lat": 50.351,
    "lng": -3.579,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Devon",
    "capital": "",
    "population": 5064,
    "id": 1826002814
  },
  {
    "city": "Chanceford",
    "city_ascii": "Chanceford",
    "lat": 39.8832,
    "lng": -76.4757,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 6166,
    "id": 1840150918
  },
  {
    "city": "Eagar",
    "city_ascii": "Eagar",
    "lat": 34.1058,
    "lng": -109.2956,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 6164,
    "id": 1840021623
  },
  {
    "city": "Henryetta",
    "city_ascii": "Henryetta",
    "lat": 35.4421,
    "lng": -95.9848,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oklahoma",
    "capital": "",
    "population": 6162,
    "id": 1840020398
  },
  {
    "city": "Unterseen",
    "city_ascii": "Unterseen",
    "lat": 46.6872,
    "lng": 7.8497,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Bern",
    "capital": "",
    "population": 5750,
    "id": 1756540929
  },
  {
    "city": "Hebron",
    "city_ascii": "Hebron",
    "lat": 39.0626,
    "lng": -84.709,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kentucky",
    "capital": "",
    "population": 6160,
    "id": 1840026680
  },
  {
    "city": "Montgomery",
    "city_ascii": "Montgomery",
    "lat": 41.1709,
    "lng": -76.874,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 6159,
    "id": 1840000631
  },
  {
    "city": "Morrilton",
    "city_ascii": "Morrilton",
    "lat": 35.1558,
    "lng": -92.7387,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arkansas",
    "capital": "",
    "population": 6159,
    "id": 1840014564
  },
  {
    "city": "Polýgyros",
    "city_ascii": "Polygyros",
    "lat": 40.3783,
    "lng": 23.4453,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Kentrikí Makedonía",
    "capital": "minor",
    "population": 6121,
    "id": 1300297120
  },
  {
    "city": "Szabadszállás",
    "city_ascii": "Szabadszallas",
    "lat": 46.8761,
    "lng": 19.2217,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Bács-Kiskun",
    "capital": "",
    "population": 6121,
    "id": 1348755195
  },
  {
    "city": "Carthage",
    "city_ascii": "Carthage",
    "lat": 32.1526,
    "lng": -94.3368,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 6158,
    "id": 1840019497
  },
  {
    "city": "Pinehurst",
    "city_ascii": "Pinehurst",
    "lat": 30.1889,
    "lng": -95.7017,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 6157,
    "id": 1840141710
  },
  {
    "city": "Bellwood",
    "city_ascii": "Bellwood",
    "lat": 37.406,
    "lng": -77.4363,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 6157,
    "id": 1840006407
  },
  {
    "city": "Homeland Park",
    "city_ascii": "Homeland Park",
    "lat": 34.4644,
    "lng": -82.6593,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 6157,
    "id": 1840013578
  },
  {
    "city": "Mníšek pod Brdy",
    "city_ascii": "Mnisek pod Brdy",
    "lat": 49.8666,
    "lng": 14.2618,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Středočeský Kraj",
    "capital": "",
    "population": 5933,
    "id": 1203937127
  },
  {
    "city": "Wilna",
    "city_ascii": "Wilna",
    "lat": 44.0562,
    "lng": -75.5903,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 6156,
    "id": 1840088053
  },
  {
    "city": "Chatham",
    "city_ascii": "Chatham",
    "lat": 41.6698,
    "lng": -69.9755,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 6155,
    "id": 1840053499
  },
  {
    "city": "Wassertrüdingen",
    "city_ascii": "Wassertrudingen",
    "lat": 49.0333,
    "lng": 10.6,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 6041,
    "id": 1276229085
  },
  {
    "city": "De Queen",
    "city_ascii": "De Queen",
    "lat": 34.0426,
    "lng": -94.342,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arkansas",
    "capital": "",
    "population": 6153,
    "id": 1840014735
  },
  {
    "city": "Evansville",
    "city_ascii": "Evansville",
    "lat": 42.7781,
    "lng": -89.2967,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 6152,
    "id": 1840002469
  },
  {
    "city": "Cocoa West",
    "city_ascii": "Cocoa West",
    "lat": 28.3595,
    "lng": -80.7712,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 6152,
    "id": 1840073847
  },
  {
    "city": "Clinton",
    "city_ascii": "Clinton",
    "lat": 39.6608,
    "lng": -87.4045,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 6152,
    "id": 1840007263
  },
  {
    "city": "Pardinho",
    "city_ascii": "Pardinho",
    "lat": -23.0811,
    "lng": -48.3736,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 6122,
    "id": 1076265258
  },
  {
    "city": "Lanett",
    "city_ascii": "Lanett",
    "lat": 32.8571,
    "lng": -85.2081,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 6151,
    "id": 1840014858
  },
  {
    "city": "Gardnerville",
    "city_ascii": "Gardnerville",
    "lat": 38.939,
    "lng": -119.7369,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Nevada",
    "capital": "",
    "population": 6148,
    "id": 1840017563
  },
  {
    "city": "Újszász",
    "city_ascii": "Ujszasz",
    "lat": 47.3,
    "lng": 20.0833,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Jász-Nagykun-Szolnok",
    "capital": "",
    "population": 6043,
    "id": 1348770356
  },
  {
    "city": "Bridgewater",
    "city_ascii": "Bridgewater",
    "lat": 38.3862,
    "lng": -78.9674,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 6145,
    "id": 1840006135
  },
  {
    "city": "Comstock Northwest",
    "city_ascii": "Comstock Northwest",
    "lat": 42.3219,
    "lng": -85.518,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 6144,
    "id": 1840073785
  },
  {
    "city": "East Farmingdale",
    "city_ascii": "East Farmingdale",
    "lat": 40.7336,
    "lng": -73.4169,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 6143,
    "id": 1840034046
  },
  {
    "city": "Glenarden",
    "city_ascii": "Glenarden",
    "lat": 38.9293,
    "lng": -76.8577,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 6143,
    "id": 1840005973
  },
  {
    "city": "Plainfield",
    "city_ascii": "Plainfield",
    "lat": 40.8185,
    "lng": -75.2609,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 6142,
    "id": 1840151279
  },
  {
    "city": "New Norfolk",
    "city_ascii": "New Norfolk",
    "lat": -42.7828,
    "lng": 147.0594,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Tasmania",
    "capital": "",
    "population": 5432,
    "id": 1036880683
  },
  {
    "city": "Southampton",
    "city_ascii": "Southampton",
    "lat": 42.2308,
    "lng": -72.7386,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 6140,
    "id": 1840053466
  },
  {
    "city": "Dornhan",
    "city_ascii": "Dornhan",
    "lat": 48.3494,
    "lng": 8.5122,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 6006,
    "id": 1276325291
  },
  {
    "city": "Cullowhee",
    "city_ascii": "Cullowhee",
    "lat": 35.3107,
    "lng": -83.1815,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 6139,
    "id": 1840013072
  },
  {
    "city": "Tequesta",
    "city_ascii": "Tequesta",
    "lat": 26.9618,
    "lng": -80.1011,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 6138,
    "id": 1840018310
  },
  {
    "city": "Broughton",
    "city_ascii": "Broughton",
    "lat": 53.169,
    "lng": -2.985,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Flintshire",
    "capital": "",
    "population": 5974,
    "id": 1826313937
  },
  {
    "city": "Elm Grove",
    "city_ascii": "Elm Grove",
    "lat": 43.0479,
    "lng": -88.0867,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 6136,
    "id": 1840003011
  },
  {
    "city": "Heber Springs",
    "city_ascii": "Heber Springs",
    "lat": 35.5003,
    "lng": -92.0332,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arkansas",
    "capital": "",
    "population": 6136,
    "id": 1840014543
  },
  {
    "city": "Ashton-Sandy Spring",
    "city_ascii": "Ashton-Sandy Spring",
    "lat": 39.1515,
    "lng": -77.0065,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 6136,
    "id": 1840073588
  },
  {
    "city": "Waimanalo",
    "city_ascii": "Waimanalo",
    "lat": 21.3421,
    "lng": -157.7303,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Hawaii",
    "capital": "",
    "population": 6135,
    "id": 1840029558
  },
  {
    "city": "Welshpool",
    "city_ascii": "Welshpool",
    "lat": 52.6597,
    "lng": -3.1473,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Powys",
    "capital": "",
    "population": 5948,
    "id": 1826815718
  },
  {
    "city": "Carlstadt",
    "city_ascii": "Carlstadt",
    "lat": 40.8247,
    "lng": -74.0613,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 6132,
    "id": 1840000895
  },
  {
    "city": "Lebanon",
    "city_ascii": "Lebanon",
    "lat": 40.7278,
    "lng": -74.8903,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 6132,
    "id": 1840001040
  },
  {
    "city": "Sümeg",
    "city_ascii": "Sumeg",
    "lat": 46.9775,
    "lng": 17.2817,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Veszprém",
    "capital": "minor",
    "population": 6037,
    "id": 1348562948
  },
  {
    "city": "Carrollton",
    "city_ascii": "Carrollton",
    "lat": 36.9394,
    "lng": -76.5264,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 6131,
    "id": 1840024808
  },
  {
    "city": "Cochran",
    "city_ascii": "Cochran",
    "lat": 32.3875,
    "lng": -83.3523,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 6131,
    "id": 1840014890
  },
  {
    "city": "Sedgefield",
    "city_ascii": "Sedgefield",
    "lat": 54.65,
    "lng": -1.45,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Durham",
    "capital": "",
    "population": 5211,
    "id": 1826637871
  },
  {
    "city": "Helmshore",
    "city_ascii": "Helmshore",
    "lat": 53.687,
    "lng": -2.329,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Lancashire",
    "capital": "",
    "population": 5805,
    "id": 1826093831
  },
  {
    "city": "Hurley",
    "city_ascii": "Hurley",
    "lat": 41.9622,
    "lng": -74.1159,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 6126,
    "id": 1840058194
  },
  {
    "city": "Novokhopërsk",
    "city_ascii": "Novokhopersk",
    "lat": 51.1,
    "lng": 41.6167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Voronezhskaya Oblast’",
    "capital": "",
    "population": 6108,
    "id": 1643890535
  },
  {
    "city": "Jambeiro",
    "city_ascii": "Jambeiro",
    "lat": -23.2536,
    "lng": -45.6878,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 6092,
    "id": 1076856953
  },
  {
    "city": "Shikhany",
    "city_ascii": "Shikhany",
    "lat": 52.1167,
    "lng": 47.2,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Saratovskaya Oblast’",
    "capital": "",
    "population": 5560,
    "id": 1643221188
  },
  {
    "city": "Lower Nazareth",
    "city_ascii": "Lower Nazareth",
    "lat": 40.7067,
    "lng": -75.3258,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 6125,
    "id": 1840151267
  },
  {
    "city": "Braunlage",
    "city_ascii": "Braunlage",
    "lat": 51.7264,
    "lng": 10.6101,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 5854,
    "id": 1276710518
  },
  {
    "city": "Garrettsville",
    "city_ascii": "Garrettsville",
    "lat": 41.2843,
    "lng": -81.0933,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 6123,
    "id": 1840000807
  },
  {
    "city": "Kōzaki",
    "city_ascii": "Kozaki",
    "lat": 35.9017,
    "lng": 140.4053,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Chiba",
    "capital": "",
    "population": 5827,
    "id": 1392568918
  },
  {
    "city": "Isanti",
    "city_ascii": "Isanti",
    "lat": 45.4928,
    "lng": -93.2407,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 6120,
    "id": 1840007789
  },
  {
    "city": "Taciba",
    "city_ascii": "Taciba",
    "lat": -22.39,
    "lng": -51.285,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 6110,
    "id": 1076588060
  },
  {
    "city": "Otaci",
    "city_ascii": "Otaci",
    "lat": 48.43,
    "lng": 27.7939,
    "country": "Moldova",
    "iso2": "MD",
    "iso3": "MDA",
    "admin_name": "Ocniţa",
    "capital": "",
    "population": 6043,
    "id": 1498515016
  },
  {
    "city": "Sky Lake",
    "city_ascii": "Sky Lake",
    "lat": 28.4611,
    "lng": -81.3912,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 6119,
    "id": 1840014085
  },
  {
    "city": "Marcellus",
    "city_ascii": "Marcellus",
    "lat": 42.9539,
    "lng": -76.3255,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 6117,
    "id": 1840004324
  },
  {
    "city": "Punxsutawney",
    "city_ascii": "Punxsutawney",
    "lat": 40.9437,
    "lng": -78.9767,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 6117,
    "id": 1840003471
  },
  {
    "city": "Brigg",
    "city_ascii": "Brigg",
    "lat": 53.5529,
    "lng": -0.485,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "North Lincolnshire",
    "capital": "",
    "population": 5626,
    "id": 1826755330
  },
  {
    "city": "Dunlap",
    "city_ascii": "Dunlap",
    "lat": 41.6346,
    "lng": -85.9235,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 6115,
    "id": 1840004814
  },
  {
    "city": "Beacon Falls",
    "city_ascii": "Beacon Falls",
    "lat": 41.439,
    "lng": -73.0568,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 6115,
    "id": 1840004771
  },
  {
    "city": "Bartonville",
    "city_ascii": "Bartonville",
    "lat": 40.6398,
    "lng": -89.6608,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 6113,
    "id": 1840011920
  },
  {
    "city": "Ixcateopan de Cuauhtémoc",
    "city_ascii": "Ixcateopan de Cuauhtemoc",
    "lat": 18.5,
    "lng": -99.7933,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Guerrero",
    "capital": "minor",
    "population": 6104,
    "id": 1484181453
  },
  {
    "city": "Laie",
    "city_ascii": "Laie",
    "lat": 21.6443,
    "lng": -157.928,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Hawaii",
    "capital": "",
    "population": 6111,
    "id": 1840029514
  },
  {
    "city": "Saint-Apollinaire",
    "city_ascii": "Saint-Apollinaire",
    "lat": 46.6167,
    "lng": -71.5167,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 6110,
    "id": 1124951601
  },
  {
    "city": "Munford",
    "city_ascii": "Munford",
    "lat": 35.4433,
    "lng": -89.8148,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 6109,
    "id": 1840014548
  },
  {
    "city": "Tarrant",
    "city_ascii": "Tarrant",
    "lat": 33.5945,
    "lng": -86.7684,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 6109,
    "id": 1840015671
  },
  {
    "city": "Woodville",
    "city_ascii": "Woodville",
    "lat": 52.769,
    "lng": -1.533,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Leicestershire",
    "capital": "",
    "population": 5161,
    "id": 1826512362
  },
  {
    "city": "Santo Antônio do Jardim",
    "city_ascii": "Santo Antonio do Jardim",
    "lat": -22.1158,
    "lng": -46.68,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 6053,
    "id": 1076695336
  },
  {
    "city": "Los Ranchos de Albuquerque",
    "city_ascii": "Los Ranchos de Albuquerque",
    "lat": 35.1625,
    "lng": -106.6481,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Mexico",
    "capital": "",
    "population": 6108,
    "id": 1840022795
  },
  {
    "city": "Megalópoli",
    "city_ascii": "Megalopoli",
    "lat": 37.4,
    "lng": 22.1333,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Peloponnísos",
    "capital": "minor",
    "population": 5748,
    "id": 1300870529
  },
  {
    "city": "Përrenjas",
    "city_ascii": "Perrenjas",
    "lat": 41.0667,
    "lng": 20.55,
    "country": "Albania",
    "iso2": "AL",
    "iso3": "ALB",
    "admin_name": "Elbasan",
    "capital": "",
    "population": 5847,
    "id": 1008654405
  },
  {
    "city": "Edgewood",
    "city_ascii": "Edgewood",
    "lat": 35.1318,
    "lng": -106.2151,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Mexico",
    "capital": "",
    "population": 6107,
    "id": 1840074899
  },
  {
    "city": "Windsbach",
    "city_ascii": "Windsbach",
    "lat": 49.2489,
    "lng": 10.8291,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 6018,
    "id": 1276289608
  },
  {
    "city": "Rindge",
    "city_ascii": "Rindge",
    "lat": 42.7523,
    "lng": -72.0107,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Hampshire",
    "capital": "",
    "population": 6106,
    "id": 1840055492
  },
  {
    "city": "Scenic Oaks",
    "city_ascii": "Scenic Oaks",
    "lat": 29.7038,
    "lng": -98.6713,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 6106,
    "id": 1840019650
  },
  {
    "city": "Bresje",
    "city_ascii": "Bresje",
    "lat": 42.6264,
    "lng": 21.0874,
    "country": "Kosovo",
    "iso2": "XK",
    "iso3": "XKS",
    "admin_name": "Fushë Kosovë",
    "capital": "",
    "population": 5596,
    "id": 1901668701
  },
  {
    "city": "Mattydale",
    "city_ascii": "Mattydale",
    "lat": 43.0992,
    "lng": -76.1388,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 6102,
    "id": 1840004317
  },
  {
    "city": "Shihoro",
    "city_ascii": "Shihoro",
    "lat": 43.1683,
    "lng": 143.2411,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hokkaidō",
    "capital": "",
    "population": 6078,
    "id": 1392341428
  },
  {
    "city": "Ts’nori",
    "city_ascii": "Ts'nori",
    "lat": 41.6167,
    "lng": 45.9667,
    "country": "Georgia",
    "iso2": "GE",
    "iso3": "GEO",
    "admin_name": "K’akheti",
    "capital": "",
    "population": 6066,
    "id": 1268042583
  },
  {
    "city": "Tillicoultry",
    "city_ascii": "Tillicoultry",
    "lat": 56.153,
    "lng": -3.742,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Clackmannanshire",
    "capital": "",
    "population": 5900,
    "id": 1826148279
  },
  {
    "city": "Amory",
    "city_ascii": "Amory",
    "lat": 33.9813,
    "lng": -88.4823,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Mississippi",
    "capital": "",
    "population": 6098,
    "id": 1840013686
  },
  {
    "city": "Ranchettes",
    "city_ascii": "Ranchettes",
    "lat": 41.2186,
    "lng": -104.7729,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wyoming",
    "capital": "",
    "population": 6097,
    "id": 1840038441
  },
  {
    "city": "Rio Espera",
    "city_ascii": "Rio Espera",
    "lat": -20.8536,
    "lng": -43.5205,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Minas Gerais",
    "capital": "",
    "population": 6070,
    "id": 1076899427
  },
  {
    "city": "Jordan",
    "city_ascii": "Jordan",
    "lat": 44.6652,
    "lng": -93.6351,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 6094,
    "id": 1840007864
  },
  {
    "city": "Filiatrá",
    "city_ascii": "Filiatra",
    "lat": 37.1572,
    "lng": 21.5858,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Peloponnísos",
    "capital": "",
    "population": 5969,
    "id": 1300134794
  },
  {
    "city": "Jánossomorja",
    "city_ascii": "Janossomorja",
    "lat": 47.7852,
    "lng": 17.1359,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Győr-Moson-Sopron",
    "capital": "",
    "population": 6052,
    "id": 1348090856
  },
  {
    "city": "Hordle",
    "city_ascii": "Hordle",
    "lat": 50.7603,
    "lng": -1.6212,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hampshire",
    "capital": "",
    "population": 5654,
    "id": 1826013167
  },
  {
    "city": "Simsbury Center",
    "city_ascii": "Simsbury Center",
    "lat": 41.8808,
    "lng": -72.8111,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 6091,
    "id": 1840034967
  },
  {
    "city": "Shrewsbury",
    "city_ascii": "Shrewsbury",
    "lat": 38.5866,
    "lng": -90.3282,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 6091,
    "id": 1840009741
  },
  {
    "city": "Adlington",
    "city_ascii": "Adlington",
    "lat": 53.6165,
    "lng": -2.5986,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Lancashire",
    "capital": "",
    "population": 6010,
    "id": 1826696408
  },
  {
    "city": "Madalena",
    "city_ascii": "Madalena",
    "lat": 38.5356,
    "lng": -28.5234,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Azores",
    "capital": "",
    "population": 6049,
    "id": 1620872660
  },
  {
    "city": "Riedenburg",
    "city_ascii": "Riedenburg",
    "lat": 48.9667,
    "lng": 11.6833,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 6030,
    "id": 1276310114
  },
  {
    "city": "Alpine",
    "city_ascii": "Alpine",
    "lat": 30.364,
    "lng": -103.665,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 6090,
    "id": 1840019580
  },
  {
    "city": "Harlan",
    "city_ascii": "Harlan",
    "lat": 36.8432,
    "lng": -83.3184,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kentucky",
    "capital": "",
    "population": 6089,
    "id": 1840013280
  },
  {
    "city": "Hindon Hill",
    "city_ascii": "Hindon Hill",
    "lat": 44.9333,
    "lng": -78.7333,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 6088,
    "id": 1124076260
  },
  {
    "city": "East Ballina",
    "city_ascii": "East Ballina",
    "lat": -28.8594,
    "lng": 153.5872,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 5571,
    "id": 1036094141
  },
  {
    "city": "Dinguiraye",
    "city_ascii": "Dinguiraye",
    "lat": 11.299,
    "lng": -10.726,
    "country": "Guinea",
    "iso2": "GN",
    "iso3": "GIN",
    "admin_name": "Faranah",
    "capital": "minor",
    "population": 6062,
    "id": 1324546502
  },
  {
    "city": "Krumë",
    "city_ascii": "Krume",
    "lat": 42.1961,
    "lng": 20.4146,
    "country": "Albania",
    "iso2": "AL",
    "iso3": "ALB",
    "admin_name": "Kukës",
    "capital": "",
    "population": 6006,
    "id": 1008823158
  },
  {
    "city": "Willard",
    "city_ascii": "Willard",
    "lat": 41.0518,
    "lng": -82.7232,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 6086,
    "id": 1840000818
  },
  {
    "city": "Senatobia",
    "city_ascii": "Senatobia",
    "lat": 34.6081,
    "lng": -89.9762,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Mississippi",
    "capital": "",
    "population": 6086,
    "id": 1840015545
  },
  {
    "city": "Biloela",
    "city_ascii": "Biloela",
    "lat": -24.4002,
    "lng": 150.5133,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Queensland",
    "capital": "",
    "population": 5758,
    "id": 1036014905
  },
  {
    "city": "Ocean Shores",
    "city_ascii": "Ocean Shores",
    "lat": 46.9685,
    "lng": -124.1521,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 6085,
    "id": 1840019849
  },
  {
    "city": "Lee Acres",
    "city_ascii": "Lee Acres",
    "lat": 36.7103,
    "lng": -108.0725,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Mexico",
    "capital": "",
    "population": 6084,
    "id": 1840033565
  },
  {
    "city": "Fairwood",
    "city_ascii": "Fairwood",
    "lat": 38.9565,
    "lng": -76.778,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 6084,
    "id": 1840039458
  },
  {
    "city": "Ontario",
    "city_ascii": "Ontario",
    "lat": 40.771,
    "lng": -82.6105,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 6084,
    "id": 1840009370
  },
  {
    "city": "Pedra Bela",
    "city_ascii": "Pedra Bela",
    "lat": -22.7931,
    "lng": -46.4433,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 6044,
    "id": 1076349740
  },
  {
    "city": "West Clarkston-Highland",
    "city_ascii": "West Clarkston-Highland",
    "lat": 46.4022,
    "lng": -117.0628,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 6081,
    "id": 1840074702
  },
  {
    "city": "South Apopka",
    "city_ascii": "South Apopka",
    "lat": 28.6569,
    "lng": -81.5057,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 6081,
    "id": 1840028779
  },
  {
    "city": "Monroe Township",
    "city_ascii": "Monroe Township",
    "lat": 40.1612,
    "lng": -77.0701,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 6081,
    "id": 1840144640
  },
  {
    "city": "Les Cèdres",
    "city_ascii": "Les Cedres",
    "lat": 45.3,
    "lng": -74.05,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 6079,
    "id": 1124051098
  },
  {
    "city": "Worragee",
    "city_ascii": "Worragee",
    "lat": -34.9144,
    "lng": 150.6386,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 5068,
    "id": 1036339460
  },
  {
    "city": "Ramtown",
    "city_ascii": "Ramtown",
    "lat": 40.1144,
    "lng": -74.1492,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 6076,
    "id": 1840033393
  },
  {
    "city": "La Broquerie",
    "city_ascii": "La Broquerie",
    "lat": 49.3994,
    "lng": -96.5103,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Manitoba",
    "capital": "",
    "population": 6076,
    "id": 1124000582
  },
  {
    "city": "Ähtäri",
    "city_ascii": "Ahtari",
    "lat": 62.55,
    "lng": 24.0694,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Etelä-Pohjanmaa",
    "capital": "minor",
    "population": 6068,
    "id": 1246810016
  },
  {
    "city": "Alexandra",
    "city_ascii": "Alexandra",
    "lat": -45.2492,
    "lng": 169.3797,
    "country": "New Zealand",
    "iso2": "NZ",
    "iso3": "NZL",
    "admin_name": "Otago",
    "capital": "",
    "population": 5510,
    "id": 1554972985
  },
  {
    "city": "Henderson",
    "city_ascii": "Henderson",
    "lat": 35.4446,
    "lng": -88.6531,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 6073,
    "id": 1840014550
  },
  {
    "city": "Westmoreland",
    "city_ascii": "Westmoreland",
    "lat": 43.1216,
    "lng": -75.4476,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 6072,
    "id": 1840058601
  },
  {
    "city": "Clifton Springs",
    "city_ascii": "Clifton Springs",
    "lat": 42.9608,
    "lng": -77.1348,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 6072,
    "id": 1840004416
  },
  {
    "city": "Santa Catarina",
    "city_ascii": "Santa Catarina",
    "lat": 21.1406,
    "lng": -100.0619,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Guanajuato",
    "capital": "minor",
    "population": 5120,
    "id": 1484243935
  },
  {
    "city": "Bad Königshofen im Grabfeld",
    "city_ascii": "Bad Konigshofen im Grabfeld",
    "lat": 50.2992,
    "lng": 10.4667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 5984,
    "id": 1276522845
  },
  {
    "city": "Sebiş",
    "city_ascii": "Sebis",
    "lat": 46.3728,
    "lng": 22.1294,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Arad",
    "capital": "",
    "population": 5979,
    "id": 1642914416
  },
  {
    "city": "Sandnessjøen",
    "city_ascii": "Sandnessjoen",
    "lat": 66.0167,
    "lng": 12.6333,
    "country": "Norway",
    "iso2": "NO",
    "iso3": "NOR",
    "admin_name": "Nordland",
    "capital": "minor",
    "population": 6052,
    "id": 1578514131
  },
  {
    "city": "Fort Ann",
    "city_ascii": "Fort Ann",
    "lat": 43.4698,
    "lng": -73.5231,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 6069,
    "id": 1840004174
  },
  {
    "city": "North Hobbs",
    "city_ascii": "North Hobbs",
    "lat": 32.7731,
    "lng": -103.125,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Mexico",
    "capital": "",
    "population": 6068,
    "id": 1840043200
  },
  {
    "city": "South Williamsport",
    "city_ascii": "South Williamsport",
    "lat": 41.2294,
    "lng": -77.0009,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 6068,
    "id": 1840000636
  },
  {
    "city": "Mendon",
    "city_ascii": "Mendon",
    "lat": 42.093,
    "lng": -71.5515,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 6068,
    "id": 1840053593
  },
  {
    "city": "Kent",
    "city_ascii": "Kent",
    "lat": 49.2833,
    "lng": -121.75,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "British Columbia",
    "capital": "",
    "population": 6067,
    "id": 1124001999
  },
  {
    "city": "Geislingen",
    "city_ascii": "Geislingen",
    "lat": 48.2875,
    "lng": 8.8125,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 5881,
    "id": 1276407524
  },
  {
    "city": "Johnson Lane",
    "city_ascii": "Johnson Lane",
    "lat": 39.0489,
    "lng": -119.7245,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Nevada",
    "capital": "",
    "population": 6065,
    "id": 1840033830
  },
  {
    "city": "Anaconda",
    "city_ascii": "Anaconda",
    "lat": 46.0607,
    "lng": -113.0679,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Montana",
    "capital": "",
    "population": 6065,
    "id": 1840074775
  },
  {
    "city": "Çıralı",
    "city_ascii": "Cirali",
    "lat": 36.4073,
    "lng": 30.4786,
    "country": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "admin_name": "Antalya",
    "capital": "",
    "population": 6000,
    "id": 1792905014
  },
  {
    "city": "Williams",
    "city_ascii": "Williams",
    "lat": 40.6313,
    "lng": -75.2278,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 6061,
    "id": 1840148473
  },
  {
    "city": "Northfield",
    "city_ascii": "Northfield",
    "lat": 44.1453,
    "lng": -72.6841,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Vermont",
    "capital": "",
    "population": 6061,
    "id": 1840070960
  },
  {
    "city": "Delano",
    "city_ascii": "Delano",
    "lat": 45.0383,
    "lng": -93.7922,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 6061,
    "id": 1840006710
  },
  {
    "city": "Monchique",
    "city_ascii": "Monchique",
    "lat": 37.3167,
    "lng": -8.6,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Faro",
    "capital": "minor",
    "population": 6045,
    "id": 1620866674
  },
  {
    "city": "Windsor",
    "city_ascii": "Windsor",
    "lat": 42.0637,
    "lng": -75.6597,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 6058,
    "id": 1840004662
  },
  {
    "city": "Village of Oak Creek",
    "city_ascii": "Village of Oak Creek",
    "lat": 34.7813,
    "lng": -111.7606,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 6057,
    "id": 1840036808
  },
  {
    "city": "Country Homes",
    "city_ascii": "Country Homes",
    "lat": 47.7478,
    "lng": -117.4196,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 6054,
    "id": 1840017334
  },
  {
    "city": "Indian Hills",
    "city_ascii": "Indian Hills",
    "lat": 39.0894,
    "lng": -119.7977,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Nevada",
    "capital": "",
    "population": 6054,
    "id": 1840033829
  },
  {
    "city": "Stewartville",
    "city_ascii": "Stewartville",
    "lat": 43.8605,
    "lng": -92.4896,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 6054,
    "id": 1840008985
  },
  {
    "city": "Rhuthun",
    "city_ascii": "Rhuthun",
    "lat": 53.115,
    "lng": -3.312,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Denbighshire",
    "capital": "",
    "population": 5461,
    "id": 1826254442
  },
  {
    "city": "Moorbad Lobenstein",
    "city_ascii": "Moorbad Lobenstein",
    "lat": 50.4522,
    "lng": 11.6393,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Thuringia",
    "capital": "",
    "population": 5931,
    "id": 1276247046
  },
  {
    "city": "Lindfield",
    "city_ascii": "Lindfield",
    "lat": 51.0135,
    "lng": -0.0833,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "West Sussex",
    "capital": "",
    "population": 5394,
    "id": 1826535163
  },
  {
    "city": "Askern",
    "city_ascii": "Askern",
    "lat": 53.6167,
    "lng": -1.15,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Doncaster",
    "capital": "",
    "population": 5570,
    "id": 1826141992
  },
  {
    "city": "Nelsonville",
    "city_ascii": "Nelsonville",
    "lat": 39.456,
    "lng": -82.2219,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 6050,
    "id": 1840008482
  },
  {
    "city": "Writtle",
    "city_ascii": "Writtle",
    "lat": 51.729,
    "lng": 0.427,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Essex",
    "capital": "",
    "population": 5383,
    "id": 1826904412
  },
  {
    "city": "Kastellaun",
    "city_ascii": "Kastellaun",
    "lat": 50.0694,
    "lng": 7.4431,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Rhineland-Palatinate",
    "capital": "",
    "population": 5410,
    "id": 1276355232
  },
  {
    "city": "Hainesport",
    "city_ascii": "Hainesport",
    "lat": 39.9767,
    "lng": -74.8369,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 6048,
    "id": 1840081623
  },
  {
    "city": "Brezoi",
    "city_ascii": "Brezoi",
    "lat": 45.3442,
    "lng": 24.2394,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Vâlcea",
    "capital": "",
    "population": 6022,
    "id": 1642282171
  },
  {
    "city": "Třebechovice pod Orebem",
    "city_ascii": "Trebechovice pod Orebem",
    "lat": 50.201,
    "lng": 15.9923,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Královéhradecký Kraj",
    "capital": "",
    "population": 5771,
    "id": 1203453933
  },
  {
    "city": "Kotel",
    "city_ascii": "Kotel",
    "lat": 42.8894,
    "lng": 26.4469,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Sliven",
    "capital": "",
    "population": 6021,
    "id": 1100910228
  },
  {
    "city": "Hidden Valley Lake",
    "city_ascii": "Hidden Valley Lake",
    "lat": 38.8003,
    "lng": -122.5505,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 6044,
    "id": 1840017549
  },
  {
    "city": "Tweed",
    "city_ascii": "Tweed",
    "lat": 44.6,
    "lng": -77.3333,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 6044,
    "id": 1124220211
  },
  {
    "city": "Carrizo Springs",
    "city_ascii": "Carrizo Springs",
    "lat": 28.5266,
    "lng": -99.8589,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 6043,
    "id": 1840019702
  },
  {
    "city": "Tótkomlós",
    "city_ascii": "Totkomlos",
    "lat": 46.4169,
    "lng": 20.7328,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Békés",
    "capital": "",
    "population": 5995,
    "id": 1348119630
  },
  {
    "city": "Maintirano",
    "city_ascii": "Maintirano",
    "lat": -18.0666,
    "lng": 44.0167,
    "country": "Madagascar",
    "iso2": "MG",
    "iso3": "MDG",
    "admin_name": "Mahajanga",
    "capital": "minor",
    "population": 5925,
    "id": 1450625023
  },
  {
    "city": "Barbourville",
    "city_ascii": "Barbourville",
    "lat": 36.8667,
    "lng": -83.885,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kentucky",
    "capital": "",
    "population": 6036,
    "id": 1840013277
  },
  {
    "city": "Dacono",
    "city_ascii": "Dacono",
    "lat": 40.062,
    "lng": -104.9484,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 6034,
    "id": 1840018740
  },
  {
    "city": "Union",
    "city_ascii": "Union",
    "lat": 38.9472,
    "lng": -84.6731,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kentucky",
    "capital": "",
    "population": 6034,
    "id": 1840015166
  },
  {
    "city": "Tapa",
    "city_ascii": "Tapa",
    "lat": 59.2644,
    "lng": 25.9628,
    "country": "Estonia",
    "iso2": "EE",
    "iso3": "EST",
    "admin_name": "Lääne-Virumaa",
    "capital": "minor",
    "population": 5706,
    "id": 1233432173
  },
  {
    "city": "Rib Mountain",
    "city_ascii": "Rib Mountain",
    "lat": 44.9196,
    "lng": -89.6763,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 6033,
    "id": 1840038097
  },
  {
    "city": "Rensselaer",
    "city_ascii": "Rensselaer",
    "lat": 40.9375,
    "lng": -87.1684,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 6029,
    "id": 1840009320
  },
  {
    "city": "Saint-Félix-de-Valois",
    "city_ascii": "Saint-Felix-de-Valois",
    "lat": 46.17,
    "lng": -73.43,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 6029,
    "id": 1124578689
  },
  {
    "city": "Krasavino",
    "city_ascii": "Krasavino",
    "lat": 60.9667,
    "lng": 46.4833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Vologodskaya Oblast’",
    "capital": "",
    "population": 6021,
    "id": 1643080380
  },
  {
    "city": "Penela",
    "city_ascii": "Penela",
    "lat": 40.0333,
    "lng": -8.3833,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Coimbra",
    "capital": "minor",
    "population": 5983,
    "id": 1620059601
  },
  {
    "city": "Schlüsselfeld",
    "city_ascii": "Schlusselfeld",
    "lat": 49.7567,
    "lng": 10.6193,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 5941,
    "id": 1276858441
  },
  {
    "city": "Marton",
    "city_ascii": "Marton",
    "lat": -40.0692,
    "lng": 175.3783,
    "country": "New Zealand",
    "iso2": "NZ",
    "iso3": "NZL",
    "admin_name": "Manawatu-Wanganui",
    "capital": "",
    "population": 5200,
    "id": 1554000171
  },
  {
    "city": "Chepes",
    "city_ascii": "Chepes",
    "lat": -31.35,
    "lng": -66.6,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "La Rioja",
    "capital": "minor",
    "population": 6020,
    "id": 1032137147
  },
  {
    "city": "Sales",
    "city_ascii": "Sales",
    "lat": -21.3408,
    "lng": -49.485,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 6002,
    "id": 1076483280
  },
  {
    "city": "Mügeln",
    "city_ascii": "Mugeln",
    "lat": 51.2333,
    "lng": 13.05,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 5913,
    "id": 1276613750
  },
  {
    "city": "Nerokoúros",
    "city_ascii": "Nerokouros",
    "lat": 35.4758,
    "lng": 24.0383,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Kríti",
    "capital": "",
    "population": 5531,
    "id": 1300362346
  },
  {
    "city": "Eucalyptus Hills",
    "city_ascii": "Eucalyptus Hills",
    "lat": 32.885,
    "lng": -116.9453,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 6017,
    "id": 1840026996
  },
  {
    "city": "Cumberland",
    "city_ascii": "Cumberland",
    "lat": 39.7844,
    "lng": -85.9458,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 6017,
    "id": 1840009532
  },
  {
    "city": "Lake Mohegan",
    "city_ascii": "Lake Mohegan",
    "lat": 41.3165,
    "lng": -73.8475,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 6013,
    "id": 1840004923
  },
  {
    "city": "Chillicothe",
    "city_ascii": "Chillicothe",
    "lat": 40.9157,
    "lng": -89.502,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 6012,
    "id": 1840007174
  },
  {
    "city": "Bay Roberts",
    "city_ascii": "Bay Roberts",
    "lat": 47.5847,
    "lng": -53.2783,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Newfoundland and Labrador",
    "capital": "",
    "population": 6012,
    "id": 1124372298
  },
  {
    "city": "Crofton",
    "city_ascii": "Crofton",
    "lat": 53.657,
    "lng": -1.427,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Wakefield",
    "capital": "",
    "population": 5781,
    "id": 1826612386
  },
  {
    "city": "Metropolis",
    "city_ascii": "Metropolis",
    "lat": 37.1565,
    "lng": -88.7083,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 6008,
    "id": 1840008773
  },
  {
    "city": "Úpice",
    "city_ascii": "Upice",
    "lat": 50.5124,
    "lng": 16.0162,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Královéhradecký Kraj",
    "capital": "",
    "population": 5639,
    "id": 1203784596
  },
  {
    "city": "Maquoketa",
    "city_ascii": "Maquoketa",
    "lat": 42.0598,
    "lng": -90.6651,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Iowa",
    "capital": "",
    "population": 6007,
    "id": 1840009137
  },
  {
    "city": "Watchung",
    "city_ascii": "Watchung",
    "lat": 40.6432,
    "lng": -74.4391,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 6006,
    "id": 1840001061
  },
  {
    "city": "Davenport",
    "city_ascii": "Davenport",
    "lat": 28.1588,
    "lng": -81.6084,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 6005,
    "id": 1840015104
  },
  {
    "city": "Conley",
    "city_ascii": "Conley",
    "lat": 33.6398,
    "lng": -84.3376,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 6002,
    "id": 1840013096
  },
  {
    "city": "Williamsburg",
    "city_ascii": "Williamsburg",
    "lat": 36.7392,
    "lng": -84.1647,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kentucky",
    "capital": "",
    "population": 6002,
    "id": 1840015267
  },
  {
    "city": "Herrnhut",
    "city_ascii": "Herrnhut",
    "lat": 51.0167,
    "lng": 14.7417,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 5922,
    "id": 1276542270
  },
  {
    "city": "Soligalich",
    "city_ascii": "Soligalich",
    "lat": 59.0833,
    "lng": 42.2833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kostromskaya Oblast’",
    "capital": "",
    "population": 5998,
    "id": 1643010609
  },
  {
    "city": "Jablunkov",
    "city_ascii": "Jablunkov",
    "lat": 49.5767,
    "lng": 18.7646,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Moravskoslezský Kraj",
    "capital": "",
    "population": 5474,
    "id": 1203326680
  },
  {
    "city": "Du Quoin",
    "city_ascii": "Du Quoin",
    "lat": 38.0019,
    "lng": -89.2323,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 6001,
    "id": 1840008673
  },
  {
    "city": "Rajec",
    "city_ascii": "Rajec",
    "lat": 49.0833,
    "lng": 18.6333,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Žilinský",
    "capital": "",
    "population": 5816,
    "id": 1703980916
  },
  {
    "city": "Tessalit",
    "city_ascii": "Tessalit",
    "lat": 20.2014,
    "lng": 1.0888,
    "country": "Mali",
    "iso2": "ML",
    "iso3": "MLI",
    "admin_name": "Kidal",
    "capital": "minor",
    "population": 6000,
    "id": 1466140377
  },
  {
    "city": "Baldwin",
    "city_ascii": "Baldwin",
    "lat": 44.954,
    "lng": -92.3709,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 6000,
    "id": 1840002136
  },
  {
    "city": "Hillsborough",
    "city_ascii": "Hillsborough",
    "lat": 43.1489,
    "lng": -71.9469,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Hampshire",
    "capital": "",
    "population": 5999,
    "id": 1840080319
  },
  {
    "city": "Eldorado at Santa Fe",
    "city_ascii": "Eldorado at Santa Fe",
    "lat": 35.5273,
    "lng": -105.934,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Mexico",
    "capital": "",
    "population": 5999,
    "id": 1840033719
  },
  {
    "city": "Blean",
    "city_ascii": "Blean",
    "lat": 51.307,
    "lng": 1.043,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Kent",
    "capital": "",
    "population": 5589,
    "id": 1826355765
  },
  {
    "city": "Shintoku",
    "city_ascii": "Shintoku",
    "lat": 43.0797,
    "lng": 142.8389,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hokkaidō",
    "capital": "",
    "population": 5992,
    "id": 1392056780
  },
  {
    "city": "Elburn",
    "city_ascii": "Elburn",
    "lat": 41.8838,
    "lng": -88.4615,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 5997,
    "id": 1840011347
  },
  {
    "city": "Andover",
    "city_ascii": "Andover",
    "lat": 41.0244,
    "lng": -74.7283,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 5996,
    "id": 1840003479
  },
  {
    "city": "Wheelersburg",
    "city_ascii": "Wheelersburg",
    "lat": 38.7383,
    "lng": -82.8421,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 5996,
    "id": 1840007422
  },
  {
    "city": "Tea",
    "city_ascii": "Tea",
    "lat": 43.4511,
    "lng": -96.834,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Dakota",
    "capital": "",
    "population": 5995,
    "id": 1840002841
  },
  {
    "city": "Yukhnov",
    "city_ascii": "Yukhnov",
    "lat": 54.75,
    "lng": 35.2333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kaluzhskaya Oblast’",
    "capital": "",
    "population": 5985,
    "id": 1643613150
  },
  {
    "city": "Kingston",
    "city_ascii": "Kingston",
    "lat": 35.8713,
    "lng": -84.4959,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 5994,
    "id": 1840014499
  },
  {
    "city": "Winthrop",
    "city_ascii": "Winthrop",
    "lat": 44.3116,
    "lng": -69.9615,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maine",
    "capital": "",
    "population": 5994,
    "id": 1840053067
  },
  {
    "city": "Shields",
    "city_ascii": "Shields",
    "lat": 43.4174,
    "lng": -84.0731,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 5993,
    "id": 1840004226
  },
  {
    "city": "Santa Albertina",
    "city_ascii": "Santa Albertina",
    "lat": -20.0319,
    "lng": -50.7278,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 5971,
    "id": 1076605287
  },
  {
    "city": "Porvenir",
    "city_ascii": "Porvenir",
    "lat": -53.2956,
    "lng": -70.3687,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Magallanes y de la Antártica Chilena",
    "capital": "minor",
    "population": 5992,
    "id": 1152790211
  },
  {
    "city": "St. Thomas",
    "city_ascii": "St. Thomas",
    "lat": 39.9239,
    "lng": -77.8063,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5992,
    "id": 1840146371
  },
  {
    "city": "Melfort",
    "city_ascii": "Melfort",
    "lat": 52.8564,
    "lng": -104.61,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Saskatchewan",
    "capital": "",
    "population": 5992,
    "id": 1124817334
  },
  {
    "city": "San Dorligo della Valle",
    "city_ascii": "San Dorligo della Valle",
    "lat": 45.6225,
    "lng": 13.8578,
    "country": "Italy",
    "iso2": "IT",
    "iso3": "ITA",
    "admin_name": "Friuli-Venezia Giulia",
    "capital": "",
    "population": 5754,
    "id": 1380967686
  },
  {
    "city": "Nové Strašecí",
    "city_ascii": "Nove Straseci",
    "lat": 50.1528,
    "lng": 13.9005,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Středočeský Kraj",
    "capital": "",
    "population": 5572,
    "id": 1203604066
  },
  {
    "city": "Pinkafeld",
    "city_ascii": "Pinkafeld",
    "lat": 47.3717,
    "lng": 16.1219,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Burgenland",
    "capital": "",
    "population": 5779,
    "id": 1040205170
  },
  {
    "city": "Wingham",
    "city_ascii": "Wingham",
    "lat": -31.85,
    "lng": 152.367,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 5374,
    "id": 1036745521
  },
  {
    "city": "Stadtoldendorf",
    "city_ascii": "Stadtoldendorf",
    "lat": 51.8833,
    "lng": 9.6167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 5757,
    "id": 1276456434
  },
  {
    "city": "Mullica",
    "city_ascii": "Mullica",
    "lat": 39.6018,
    "lng": -74.6805,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 5987,
    "id": 1840081584
  },
  {
    "city": "Pecan Plantation",
    "city_ascii": "Pecan Plantation",
    "lat": 32.3628,
    "lng": -97.6554,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 5987,
    "id": 1840019460
  },
  {
    "city": "Winfield",
    "city_ascii": "Winfield",
    "lat": 41.4098,
    "lng": -87.2623,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 5987,
    "id": 1840010228
  },
  {
    "city": "Dover",
    "city_ascii": "Dover",
    "lat": 42.2366,
    "lng": -71.2842,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 5987,
    "id": 1840053548
  },
  {
    "city": "Lewistown",
    "city_ascii": "Lewistown",
    "lat": 47.0514,
    "lng": -109.4524,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Montana",
    "capital": "",
    "population": 5987,
    "id": 1840019823
  },
  {
    "city": "Grayson Valley",
    "city_ascii": "Grayson Valley",
    "lat": 33.6469,
    "lng": -86.6414,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 5986,
    "id": 1840027631
  },
  {
    "city": "Bredstedt",
    "city_ascii": "Bredstedt",
    "lat": 54.62,
    "lng": 8.9644,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Schleswig-Holstein",
    "capital": "",
    "population": 5429,
    "id": 1276369501
  },
  {
    "city": "Wemding",
    "city_ascii": "Wemding",
    "lat": 48.8667,
    "lng": 10.7167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 5802,
    "id": 1276341705
  },
  {
    "city": "Finley",
    "city_ascii": "Finley",
    "lat": 46.1697,
    "lng": -119.0446,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 5984,
    "id": 1840017380
  },
  {
    "city": "Murça",
    "city_ascii": "Murca",
    "lat": 41.4,
    "lng": -7.45,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Vila Real",
    "capital": "minor",
    "population": 5952,
    "id": 1620008243
  },
  {
    "city": "Northern Cambria",
    "city_ascii": "Northern Cambria",
    "lat": 40.6561,
    "lng": -78.7784,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5981,
    "id": 1840001097
  },
  {
    "city": "Balatonboglár",
    "city_ascii": "Balatonboglar",
    "lat": 46.7667,
    "lng": 17.6667,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Somogy",
    "capital": "",
    "population": 5798,
    "id": 1348399823
  },
  {
    "city": "Pevely",
    "city_ascii": "Pevely",
    "lat": 38.2863,
    "lng": -90.4005,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 5978,
    "id": 1840009810
  },
  {
    "city": "Cresaptown",
    "city_ascii": "Cresaptown",
    "lat": 39.5912,
    "lng": -78.855,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 5977,
    "id": 1840026620
  },
  {
    "city": "Bourne End",
    "city_ascii": "Bourne End",
    "lat": 51.5817,
    "lng": -0.7134,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Buckinghamshire",
    "capital": "",
    "population": 5320,
    "id": 1826489634
  },
  {
    "city": "Hungerford",
    "city_ascii": "Hungerford",
    "lat": 51.414,
    "lng": -1.515,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "West Berkshire",
    "capital": "",
    "population": 5767,
    "id": 1826664814
  },
  {
    "city": "Finderne",
    "city_ascii": "Finderne",
    "lat": 40.5626,
    "lng": -74.5743,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 5976,
    "id": 1840024274
  },
  {
    "city": "Breinigsville",
    "city_ascii": "Breinigsville",
    "lat": 40.5394,
    "lng": -75.6344,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5975,
    "id": 1840026409
  },
  {
    "city": "Bonnyville",
    "city_ascii": "Bonnyville",
    "lat": 54.2667,
    "lng": -110.75,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Alberta",
    "capital": "",
    "population": 5975,
    "id": 1124166469
  },
  {
    "city": "Westerburg",
    "city_ascii": "Westerburg",
    "lat": 50.5639,
    "lng": 7.9725,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Rhineland-Palatinate",
    "capital": "",
    "population": 5666,
    "id": 1276754919
  },
  {
    "city": "Groton",
    "city_ascii": "Groton",
    "lat": 42.5847,
    "lng": -76.3598,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 5972,
    "id": 1840004543
  },
  {
    "city": "East Pasadena",
    "city_ascii": "East Pasadena",
    "lat": 34.1377,
    "lng": -118.0776,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 5971,
    "id": 1840028234
  },
  {
    "city": "Kiviõli",
    "city_ascii": "Kivioli",
    "lat": 59.3517,
    "lng": 26.9611,
    "country": "Estonia",
    "iso2": "EE",
    "iso3": "EST",
    "admin_name": "Ida-Virumaa",
    "capital": "minor",
    "population": 5504,
    "id": 1233298951
  },
  {
    "city": "Kazimierza Wielka",
    "city_ascii": "Kazimierza Wielka",
    "lat": 50.2656,
    "lng": 20.4936,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Świętokrzyskie",
    "capital": "minor",
    "population": 5848,
    "id": 1616505351
  },
  {
    "city": "Berhida",
    "city_ascii": "Berhida",
    "lat": 47.1131,
    "lng": 18.1342,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Veszprém",
    "capital": "",
    "population": 5833,
    "id": 1348136308
  },
  {
    "city": "North Hills",
    "city_ascii": "North Hills",
    "lat": 40.7765,
    "lng": -73.6778,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 5969,
    "id": 1840005314
  },
  {
    "city": "Lakemoor",
    "city_ascii": "Lakemoor",
    "lat": 42.3396,
    "lng": -88.2038,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 5967,
    "id": 1840011130
  },
  {
    "city": "Silver Lakes",
    "city_ascii": "Silver Lakes",
    "lat": 34.7519,
    "lng": -117.3431,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 5966,
    "id": 1840024935
  },
  {
    "city": "Avra Valley",
    "city_ascii": "Avra Valley",
    "lat": 32.4195,
    "lng": -111.3393,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 5966,
    "id": 1840027985
  },
  {
    "city": "Fatezh",
    "city_ascii": "Fatezh",
    "lat": 52.0894,
    "lng": 35.8589,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kurskaya Oblast’",
    "capital": "",
    "population": 5951,
    "id": 1643270845
  },
  {
    "city": "Hellam",
    "city_ascii": "Hellam",
    "lat": 40.0206,
    "lng": -76.5962,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5965,
    "id": 1840151962
  },
  {
    "city": "Newport",
    "city_ascii": "Newport",
    "lat": 35.6234,
    "lng": -91.2322,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arkansas",
    "capital": "",
    "population": 5965,
    "id": 1840014516
  },
  {
    "city": "Vail",
    "city_ascii": "Vail",
    "lat": 39.6386,
    "lng": -106.3607,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 5964,
    "id": 1840022434
  },
  {
    "city": "Zaraysk",
    "city_ascii": "Zaraysk",
    "lat": 54.7653,
    "lng": 38.8836,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Moskovskaya Oblast’",
    "capital": "",
    "population": 5918,
    "id": 1643156955
  },
  {
    "city": "Axminster",
    "city_ascii": "Axminster",
    "lat": 50.781,
    "lng": -3,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Devon",
    "capital": "",
    "population": 5761,
    "id": 1826766054
  },
  {
    "city": "Steelton",
    "city_ascii": "Steelton",
    "lat": 40.2258,
    "lng": -76.8254,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5962,
    "id": 1840001297
  },
  {
    "city": "Týnec nad Sázavou",
    "city_ascii": "Tynec nad Sazavou",
    "lat": 49.8335,
    "lng": 14.5899,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Středočeský Kraj",
    "capital": "",
    "population": 5739,
    "id": 1203588178
  },
  {
    "city": "Haiterbach",
    "city_ascii": "Haiterbach",
    "lat": 48.5244,
    "lng": 8.6503,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 5761,
    "id": 1276102566
  },
  {
    "city": "Fort Shawnee",
    "city_ascii": "Fort Shawnee",
    "lat": 40.6814,
    "lng": -84.1487,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 5960,
    "id": 1840011955
  },
  {
    "city": "Santa Bárbara do Rio Pardo",
    "city_ascii": "Santa Barbara do Rio Pardo",
    "lat": -22.8808,
    "lng": -49.2389,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 5944,
    "id": 1076093471
  },
  {
    "city": "Pusztaszabolcs",
    "city_ascii": "Pusztaszabolcs",
    "lat": 47.1413,
    "lng": 18.7594,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Fejér",
    "capital": "",
    "population": 5845,
    "id": 1348280190
  },
  {
    "city": "Northville",
    "city_ascii": "Northville",
    "lat": 42.4355,
    "lng": -83.489,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 5958,
    "id": 1840003958
  },
  {
    "city": "Sealy",
    "city_ascii": "Sealy",
    "lat": 29.7676,
    "lng": -96.1679,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 5957,
    "id": 1840022209
  },
  {
    "city": "St. Clair",
    "city_ascii": "St. Clair",
    "lat": 38.3479,
    "lng": -90.9934,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 5955,
    "id": 1840009645
  },
  {
    "city": "Eastampton",
    "city_ascii": "Eastampton",
    "lat": 40.001,
    "lng": -74.7553,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 5954,
    "id": 1840081636
  },
  {
    "city": "East Alton",
    "city_ascii": "East Alton",
    "lat": 38.884,
    "lng": -90.1073,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 5954,
    "id": 1840012794
  },
  {
    "city": "Rocky Mount",
    "city_ascii": "Rocky Mount",
    "lat": 37.0045,
    "lng": -79.8854,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 5953,
    "id": 1840006479
  },
  {
    "city": "Dedoplists’q’aro",
    "city_ascii": "Dedoplists'q'aro",
    "lat": 41.4652,
    "lng": 46.1045,
    "country": "Georgia",
    "iso2": "GE",
    "iso3": "GEO",
    "admin_name": "K’akheti",
    "capital": "minor",
    "population": 5940,
    "id": 1268323839
  },
  {
    "city": "San Miguel Panixtlahuaca",
    "city_ascii": "San Miguel Panixtlahuaca",
    "lat": 16.2597,
    "lng": -97.3781,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Oaxaca",
    "capital": "minor",
    "population": 5930,
    "id": 1484082675
  },
  {
    "city": "Stettler",
    "city_ascii": "Stettler",
    "lat": 52.3236,
    "lng": -112.7192,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Alberta",
    "capital": "",
    "population": 5952,
    "id": 1124010388
  },
  {
    "city": "Diónysos",
    "city_ascii": "Dionysos",
    "lat": 38.1,
    "lng": 23.8667,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Attikí",
    "capital": "",
    "population": 5651,
    "id": 1300635126
  },
  {
    "city": "Münzenberg",
    "city_ascii": "Munzenberg",
    "lat": 50.4533,
    "lng": 8.7761,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 5769,
    "id": 1276478470
  },
  {
    "city": "Rochlitz",
    "city_ascii": "Rochlitz",
    "lat": 51.0481,
    "lng": 12.7986,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 5711,
    "id": 1276631042
  },
  {
    "city": "Hopedale",
    "city_ascii": "Hopedale",
    "lat": 42.1247,
    "lng": -71.535,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 5951,
    "id": 1840053589
  },
  {
    "city": "Vidigueira",
    "city_ascii": "Vidigueira",
    "lat": 38.2,
    "lng": -7.8,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Beja",
    "capital": "minor",
    "population": 5932,
    "id": 1620950899
  },
  {
    "city": "Ocean City",
    "city_ascii": "Ocean City",
    "lat": 30.4398,
    "lng": -86.6071,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 5949,
    "id": 1840013922
  },
  {
    "city": "Logan",
    "city_ascii": "Logan",
    "lat": 39.7923,
    "lng": -75.355,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 5949,
    "id": 1840081673
  },
  {
    "city": "Whitianga",
    "city_ascii": "Whitianga",
    "lat": -36.8331,
    "lng": 175.6997,
    "country": "New Zealand",
    "iso2": "NZ",
    "iso3": "NZL",
    "admin_name": "Waikato",
    "capital": "",
    "population": 5130,
    "id": 1554392056
  },
  {
    "city": "Chuí",
    "city_ascii": "Chui",
    "lat": -33.6908,
    "lng": -53.4569,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Rio Grande do Sul",
    "capital": "",
    "population": 5917,
    "id": 1076536404
  },
  {
    "city": "Fabens",
    "city_ascii": "Fabens",
    "lat": 31.5136,
    "lng": -106.1521,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 5946,
    "id": 1840018152
  },
  {
    "city": "Gowanda",
    "city_ascii": "Gowanda",
    "lat": 42.4612,
    "lng": -78.9339,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 5945,
    "id": 1840004590
  },
  {
    "city": "Shatsk",
    "city_ascii": "Shatsk",
    "lat": 54.0333,
    "lng": 41.7,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Ryazanskaya Oblast’",
    "capital": "",
    "population": 5935,
    "id": 1643778417
  },
  {
    "city": "Progreso",
    "city_ascii": "Progreso",
    "lat": 26.0962,
    "lng": -97.9566,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 5944,
    "id": 1840021031
  },
  {
    "city": "Frasin",
    "city_ascii": "Frasin",
    "lat": 47.5183,
    "lng": 25.7819,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Suceava",
    "capital": "",
    "population": 5876,
    "id": 1642719080
  },
  {
    "city": "Westville",
    "city_ascii": "Westville",
    "lat": 41.5375,
    "lng": -86.9049,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 5943,
    "id": 1840010203
  },
  {
    "city": "Callington",
    "city_ascii": "Callington",
    "lat": 50.503,
    "lng": -4.316,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Cornwall",
    "capital": "",
    "population": 5786,
    "id": 1826196022
  },
  {
    "city": "Rousínov",
    "city_ascii": "Rousinov",
    "lat": 49.2013,
    "lng": 16.8822,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Jihomoravský Kraj",
    "capital": "",
    "population": 5694,
    "id": 1203816374
  },
  {
    "city": "Darlington",
    "city_ascii": "Darlington",
    "lat": 34.3015,
    "lng": -79.867,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 5940,
    "id": 1840014693
  },
  {
    "city": "Kaba",
    "city_ascii": "Kaba",
    "lat": 47.3557,
    "lng": 21.2742,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Hajdú-Bihar",
    "capital": "",
    "population": 5878,
    "id": 1348576656
  },
  {
    "city": "Ubarana",
    "city_ascii": "Ubarana",
    "lat": -21.1658,
    "lng": -49.7178,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 5910,
    "id": 1076427251
  },
  {
    "city": "Wolfach",
    "city_ascii": "Wolfach",
    "lat": 48.2994,
    "lng": 8.2228,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 5850,
    "id": 1276124300
  },
  {
    "city": "Atlantic",
    "city_ascii": "Atlantic",
    "lat": 41.3957,
    "lng": -95.0138,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Iowa",
    "capital": "",
    "population": 5937,
    "id": 1840007125
  },
  {
    "city": "Kungsör",
    "city_ascii": "Kungsor",
    "lat": 59.4227,
    "lng": 16.1059,
    "country": "Sweden",
    "iso2": "SE",
    "iso3": "SWE",
    "admin_name": "Västmanland",
    "capital": "minor",
    "population": 5898,
    "id": 1752957577
  },
  {
    "city": "Trooper",
    "city_ascii": "Trooper",
    "lat": 40.1489,
    "lng": -75.3995,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5936,
    "id": 1840005470
  },
  {
    "city": "Goodrich",
    "city_ascii": "Goodrich",
    "lat": 42.9147,
    "lng": -83.5092,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 5935,
    "id": 1840011007
  },
  {
    "city": "Psachná",
    "city_ascii": "Psachna",
    "lat": 38.5828,
    "lng": 23.6328,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Stereá Elláda",
    "capital": "minor",
    "population": 5827,
    "id": 1300776515
  },
  {
    "city": "Freshwater",
    "city_ascii": "Freshwater",
    "lat": 50.6826,
    "lng": -1.5249,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Isle of Wight",
    "capital": "",
    "population": 5369,
    "id": 1826438887
  },
  {
    "city": "Morshyn",
    "city_ascii": "Morshyn",
    "lat": 49.155,
    "lng": 23.8725,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "L’vivs’ka Oblast’",
    "capital": "",
    "population": 5850,
    "id": 1804460530
  },
  {
    "city": "Leongatha",
    "city_ascii": "Leongatha",
    "lat": -38.4833,
    "lng": 145.95,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Victoria",
    "capital": "",
    "population": 5119,
    "id": 1036910660
  },
  {
    "city": "Saint-Calixte",
    "city_ascii": "Saint-Calixte",
    "lat": 45.95,
    "lng": -73.85,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 5934,
    "id": 1124001462
  },
  {
    "city": "Campina do Monte Alegre",
    "city_ascii": "Campina do Monte Alegre",
    "lat": -23.4253,
    "lng": -48.4772,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 5901,
    "id": 1076444719
  },
  {
    "city": "Tracyton",
    "city_ascii": "Tracyton",
    "lat": 47.6095,
    "lng": -122.6533,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 5933,
    "id": 1840018408
  },
  {
    "city": "Countryside",
    "city_ascii": "Countryside",
    "lat": 41.7741,
    "lng": -87.8752,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 5933,
    "id": 1840007026
  },
  {
    "city": "West Hattiesburg",
    "city_ascii": "West Hattiesburg",
    "lat": 31.3114,
    "lng": -89.374,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Mississippi",
    "capital": "",
    "population": 5933,
    "id": 1840032564
  },
  {
    "city": "Xylókastro",
    "city_ascii": "Xylokastro",
    "lat": 38.0667,
    "lng": 22.6333,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Peloponnísos",
    "capital": "minor",
    "population": 5500,
    "id": 1300956672
  },
  {
    "city": "Sullivan City",
    "city_ascii": "Sullivan City",
    "lat": 26.2752,
    "lng": -98.5644,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 5932,
    "id": 1840022257
  },
  {
    "city": "Lac-Mégantic",
    "city_ascii": "Lac-Megantic",
    "lat": 45.5833,
    "lng": -70.8833,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 5932,
    "id": 1124329615
  },
  {
    "city": "Perth",
    "city_ascii": "Perth",
    "lat": 44.9,
    "lng": -76.25,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 5930,
    "id": 1124732094
  },
  {
    "city": "Mucuchíes",
    "city_ascii": "Mucuchies",
    "lat": 8.75,
    "lng": -70.9167,
    "country": "Venezuela",
    "iso2": "VE",
    "iso3": "VEN",
    "admin_name": "Mérida",
    "capital": "minor",
    "population": 5900,
    "id": 1862256135
  },
  {
    "city": "Takamori",
    "city_ascii": "Takamori",
    "lat": 32.8272,
    "lng": 131.1219,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Kumamoto",
    "capital": "",
    "population": 5895,
    "id": 1392733744
  },
  {
    "city": "Keswick",
    "city_ascii": "Keswick",
    "lat": 54.5999,
    "lng": -3.1293,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Cumbria",
    "capital": "",
    "population": 5243,
    "id": 1826014502
  },
  {
    "city": "Paradise",
    "city_ascii": "Paradise",
    "lat": 39.7558,
    "lng": -121.6063,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 5928,
    "id": 1840022428
  },
  {
    "city": "Leun",
    "city_ascii": "Leun",
    "lat": 50.55,
    "lng": 8.3667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 5728,
    "id": 1276802968
  },
  {
    "city": "Hausach",
    "city_ascii": "Hausach",
    "lat": 48.2853,
    "lng": 8.1797,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 5768,
    "id": 1276001334
  },
  {
    "city": "Leiston",
    "city_ascii": "Leiston",
    "lat": 52.206,
    "lng": 1.579,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Suffolk",
    "capital": "",
    "population": 5508,
    "id": 1826695491
  },
  {
    "city": "Littleton",
    "city_ascii": "Littleton",
    "lat": 44.3322,
    "lng": -71.8097,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Hampshire",
    "capital": "",
    "population": 5927,
    "id": 1840055127
  },
  {
    "city": "Pagosa Springs",
    "city_ascii": "Pagosa Springs",
    "lat": 37.2674,
    "lng": -107.0307,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 5927,
    "id": 1840022565
  },
  {
    "city": "Santa Maria da Serra",
    "city_ascii": "Santa Maria da Serra",
    "lat": -22.5672,
    "lng": -48.1606,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 5902,
    "id": 1076250835
  },
  {
    "city": "Woodcreek",
    "city_ascii": "Woodcreek",
    "lat": 30.0266,
    "lng": -98.1115,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 5925,
    "id": 1840022191
  },
  {
    "city": "Homer",
    "city_ascii": "Homer",
    "lat": 59.653,
    "lng": -151.5255,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alaska",
    "capital": "",
    "population": 5922,
    "id": 1840023419
  },
  {
    "city": "Dumfries",
    "city_ascii": "Dumfries",
    "lat": 38.567,
    "lng": -77.3233,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 5922,
    "id": 1840006102
  },
  {
    "city": "Oliver Paipoonge",
    "city_ascii": "Oliver Paipoonge",
    "lat": 48.39,
    "lng": -89.52,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 5922,
    "id": 1124000729
  },
  {
    "city": "Bräunlingen",
    "city_ascii": "Braunlingen",
    "lat": 47.9297,
    "lng": 8.4481,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 5828,
    "id": 1276097897
  },
  {
    "city": "New London",
    "city_ascii": "New London",
    "lat": 39.7743,
    "lng": -75.8797,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5921,
    "id": 1840151977
  },
  {
    "city": "Gransee",
    "city_ascii": "Gransee",
    "lat": 53.0069,
    "lng": 13.1586,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Brandenburg",
    "capital": "",
    "population": 5871,
    "id": 1276406974
  },
  {
    "city": "Mineola",
    "city_ascii": "Mineola",
    "lat": 32.6461,
    "lng": -95.4775,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 5919,
    "id": 1840020685
  },
  {
    "city": "Wesley Hills",
    "city_ascii": "Wesley Hills",
    "lat": 41.1579,
    "lng": -74.0768,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 5919,
    "id": 1840004989
  },
  {
    "city": "East Hanover",
    "city_ascii": "East Hanover",
    "lat": 40.391,
    "lng": -76.6845,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5919,
    "id": 1840152634
  },
  {
    "city": "Zebulon",
    "city_ascii": "Zebulon",
    "lat": 35.8318,
    "lng": -78.3162,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 5917,
    "id": 1840016201
  },
  {
    "city": "Banatski Karlovac",
    "city_ascii": "Banatski Karlovac",
    "lat": 45.0472,
    "lng": 21.0161,
    "country": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "admin_name": "Južno Banatski Okrug",
    "capital": "",
    "population": 5820,
    "id": 1688942456
  },
  {
    "city": "Granville",
    "city_ascii": "Granville",
    "lat": 40.0648,
    "lng": -82.5023,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 5916,
    "id": 1840012324
  },
  {
    "city": "Kaltennordheim",
    "city_ascii": "Kaltennordheim",
    "lat": 50.6333,
    "lng": 10.1667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Thuringia",
    "capital": "",
    "population": 5853,
    "id": 1276694949
  },
  {
    "city": "Palmwoods",
    "city_ascii": "Palmwoods",
    "lat": -26.6883,
    "lng": 152.9597,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Queensland",
    "capital": "",
    "population": 5676,
    "id": 1036016511
  },
  {
    "city": "Taylor",
    "city_ascii": "Taylor",
    "lat": 41.3957,
    "lng": -75.7147,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5913,
    "id": 1840003390
  },
  {
    "city": "Staunton",
    "city_ascii": "Staunton",
    "lat": 39.0117,
    "lng": -89.7906,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 5913,
    "id": 1840009602
  },
  {
    "city": "Willington",
    "city_ascii": "Willington",
    "lat": 41.8896,
    "lng": -72.2593,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 5912,
    "id": 1840045105
  },
  {
    "city": "West Modesto",
    "city_ascii": "West Modesto",
    "lat": 37.618,
    "lng": -121.0343,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 5911,
    "id": 1840074716
  },
  {
    "city": "Upper Sandusky",
    "city_ascii": "Upper Sandusky",
    "lat": 40.8298,
    "lng": -83.2721,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 5911,
    "id": 1840010346
  },
  {
    "city": "Chambarak",
    "city_ascii": "Chambarak",
    "lat": 40.5931,
    "lng": 45.3572,
    "country": "Armenia",
    "iso2": "AM",
    "iso3": "ARM",
    "admin_name": "Geghark’unik’",
    "capital": "",
    "population": 5850,
    "id": 1051172497
  },
  {
    "city": "Dunsborough",
    "city_ascii": "Dunsborough",
    "lat": -33.6167,
    "lng": 115.1,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Western Australia",
    "capital": "",
    "population": 5320,
    "id": 1036428544
  },
  {
    "city": "Alzamay",
    "city_ascii": "Alzamay",
    "lat": 55.55,
    "lng": 98.6667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Irkutskaya Oblast’",
    "capital": "",
    "population": 5908,
    "id": 1643351432
  },
  {
    "city": "Steinfort",
    "city_ascii": "Steinfort",
    "lat": 49.66,
    "lng": 5.9156,
    "country": "Luxembourg",
    "iso2": "LU",
    "iso3": "LUX",
    "admin_name": "Capellen",
    "capital": "minor",
    "population": 5459,
    "id": 1442825743
  },
  {
    "city": "Woods Creek",
    "city_ascii": "Woods Creek",
    "lat": 47.8821,
    "lng": -121.8982,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 5907,
    "id": 1840037864
  },
  {
    "city": "Rosendale",
    "city_ascii": "Rosendale",
    "lat": 41.8473,
    "lng": -74.0786,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 5907,
    "id": 1840058452
  },
  {
    "city": "Luzerne",
    "city_ascii": "Luzerne",
    "lat": 39.9715,
    "lng": -79.9357,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5905,
    "id": 1840142192
  },
  {
    "city": "Waibstadt",
    "city_ascii": "Waibstadt",
    "lat": 49.2975,
    "lng": 8.92,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 5682,
    "id": 1276358841
  },
  {
    "city": "Chena Ridge",
    "city_ascii": "Chena Ridge",
    "lat": 64.7941,
    "lng": -148.0357,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alaska",
    "capital": "",
    "population": 5903,
    "id": 1840075179
  },
  {
    "city": "Selinsgrove",
    "city_ascii": "Selinsgrove",
    "lat": 40.8003,
    "lng": -76.8647,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5902,
    "id": 1840002799
  },
  {
    "city": "Clanton",
    "city_ascii": "Clanton",
    "lat": 32.844,
    "lng": -86.623,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 5901,
    "id": 1840006157
  },
  {
    "city": "Gătaia",
    "city_ascii": "Gataia",
    "lat": 45.4333,
    "lng": 21.4333,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Timiş",
    "capital": "",
    "population": 5861,
    "id": 1642896649
  },
  {
    "city": "Brookhaven",
    "city_ascii": "Brookhaven",
    "lat": 39.6062,
    "lng": -79.8812,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "West Virginia",
    "capital": "",
    "population": 5900,
    "id": 1840005643
  },
  {
    "city": "Wailua Homesteads",
    "city_ascii": "Wailua Homesteads",
    "lat": 22.065,
    "lng": -159.3771,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Hawaii",
    "capital": "",
    "population": 5900,
    "id": 1840029591
  },
  {
    "city": "China",
    "city_ascii": "China",
    "lat": 27.3336,
    "lng": 128.5736,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Kagoshima",
    "capital": "",
    "population": 5791,
    "id": 1392983200
  },
  {
    "city": "Naşrābād",
    "city_ascii": "Nasrabad",
    "lat": 32.2794,
    "lng": 52.0631,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Eşfahān",
    "capital": "",
    "population": 5751,
    "id": 1364000282
  },
  {
    "city": "Cut Off",
    "city_ascii": "Cut Off",
    "lat": 29.5164,
    "lng": -90.3291,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 5897,
    "id": 1840013113
  },
  {
    "city": "Radenthein",
    "city_ascii": "Radenthein",
    "lat": 46.8,
    "lng": 13.7,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Kärnten",
    "capital": "",
    "population": 5831,
    "id": 1040391967
  },
  {
    "city": "Višegrad",
    "city_ascii": "Visegrad",
    "lat": 43.7833,
    "lng": 19.2833,
    "country": "Bosnia And Herzegovina",
    "iso2": "BA",
    "iso3": "BIH",
    "admin_name": "Srpska, Republika",
    "capital": "minor",
    "population": 5869,
    "id": 1070100453
  },
  {
    "city": "Independence",
    "city_ascii": "Independence",
    "lat": 42.4622,
    "lng": -91.9027,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Iowa",
    "capital": "",
    "population": 5895,
    "id": 1840008058
  },
  {
    "city": "Old Tappan",
    "city_ascii": "Old Tappan",
    "lat": 41.0163,
    "lng": -73.9856,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 5894,
    "id": 1840000913
  },
  {
    "city": "Gray",
    "city_ascii": "Gray",
    "lat": 29.6776,
    "lng": -90.7833,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 5893,
    "id": 1840014030
  },
  {
    "city": "Onagawa",
    "city_ascii": "Onagawa",
    "lat": 38.4456,
    "lng": 141.4444,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Miyagi",
    "capital": "",
    "population": 5822,
    "id": 1392593569
  },
  {
    "city": "Drighlington",
    "city_ascii": "Drighlington",
    "lat": 53.7568,
    "lng": -1.6616,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Leeds",
    "capital": "",
    "population": 5528,
    "id": 1826641578
  },
  {
    "city": "Raleigh Hills",
    "city_ascii": "Raleigh Hills",
    "lat": 45.4852,
    "lng": -122.7567,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 5892,
    "id": 1840034810
  },
  {
    "city": "Willard",
    "city_ascii": "Willard",
    "lat": 37.2929,
    "lng": -93.4171,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 5891,
    "id": 1840010870
  },
  {
    "city": "Ybbs an der Donau",
    "city_ascii": "Ybbs an der Donau",
    "lat": 48.1667,
    "lng": 15.0667,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Niederösterreich",
    "capital": "",
    "population": 5653,
    "id": 1040524585
  },
  {
    "city": "Los Chaves",
    "city_ascii": "Los Chaves",
    "lat": 34.7332,
    "lng": -106.7631,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Mexico",
    "capital": "",
    "population": 5890,
    "id": 1840017903
  },
  {
    "city": "Loudon",
    "city_ascii": "Loudon",
    "lat": 35.7413,
    "lng": -84.3704,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 5890,
    "id": 1840016254
  },
  {
    "city": "Madisonville",
    "city_ascii": "Madisonville",
    "lat": 35.5233,
    "lng": -84.363,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 5890,
    "id": 1840015414
  },
  {
    "city": "Tuakau",
    "city_ascii": "Tuakau",
    "lat": -37.2667,
    "lng": 174.95,
    "country": "New Zealand",
    "iso2": "NZ",
    "iso3": "NZL",
    "admin_name": "Waikato",
    "capital": "",
    "population": 5090,
    "id": 1554293881
  },
  {
    "city": "Irymple",
    "city_ascii": "Irymple",
    "lat": -34.2333,
    "lng": 142.1667,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Victoria",
    "capital": "",
    "population": 5325,
    "id": 1036990765
  },
  {
    "city": "Pocahontas",
    "city_ascii": "Pocahontas",
    "lat": 36.2637,
    "lng": -90.9706,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arkansas",
    "capital": "",
    "population": 5887,
    "id": 1840014448
  },
  {
    "city": "Montezuma",
    "city_ascii": "Montezuma",
    "lat": 32.2997,
    "lng": -84.0246,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 5887,
    "id": 1840014898
  },
  {
    "city": "Biesenthal",
    "city_ascii": "Biesenthal",
    "lat": 52.7667,
    "lng": 13.6331,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Brandenburg",
    "capital": "",
    "population": 5791,
    "id": 1276741204
  },
  {
    "city": "Clyde",
    "city_ascii": "Clyde",
    "lat": 41.3046,
    "lng": -82.9782,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 5886,
    "id": 1840007131
  },
  {
    "city": "Stratmoor",
    "city_ascii": "Stratmoor",
    "lat": 38.7732,
    "lng": -104.7787,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 5883,
    "id": 1840028582
  },
  {
    "city": "Gustine",
    "city_ascii": "Gustine",
    "lat": 37.2545,
    "lng": -120.9949,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 5882,
    "id": 1840020310
  },
  {
    "city": "Booneville",
    "city_ascii": "Booneville",
    "lat": 34.6643,
    "lng": -88.5684,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Mississippi",
    "capital": "",
    "population": 5880,
    "id": 1840013591
  },
  {
    "city": "Korocha",
    "city_ascii": "Korocha",
    "lat": 50.8167,
    "lng": 37.2,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Belgorodskaya Oblast’",
    "capital": "",
    "population": 5853,
    "id": 1643723020
  },
  {
    "city": "Poltár",
    "city_ascii": "Poltar",
    "lat": 48.4306,
    "lng": 19.7975,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Banskobystrický",
    "capital": "minor",
    "population": 5693,
    "id": 1703890935
  },
  {
    "city": "Mezőkovácsháza",
    "city_ascii": "Mezokovacshaza",
    "lat": 46.4119,
    "lng": 20.9108,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Békés",
    "capital": "minor",
    "population": 5787,
    "id": 1348993252
  },
  {
    "city": "Nolanville",
    "city_ascii": "Nolanville",
    "lat": 31.0754,
    "lng": -97.609,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 5879,
    "id": 1840020853
  },
  {
    "city": "Crosspointe",
    "city_ascii": "Crosspointe",
    "lat": 38.7253,
    "lng": -77.2638,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 5879,
    "id": 1840041713
  },
  {
    "city": "Tittmoning",
    "city_ascii": "Tittmoning",
    "lat": 48.0631,
    "lng": 12.7669,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 5798,
    "id": 1276573454
  },
  {
    "city": "Dierdorf",
    "city_ascii": "Dierdorf",
    "lat": 50.5489,
    "lng": 7.6594,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Rhineland-Palatinate",
    "capital": "",
    "population": 5700,
    "id": 1276542576
  },
  {
    "city": "Las Flores",
    "city_ascii": "Las Flores",
    "lat": 33.5838,
    "lng": -117.6235,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 5877,
    "id": 1840136071
  },
  {
    "city": "Poloni",
    "city_ascii": "Poloni",
    "lat": -20.7853,
    "lng": -49.8236,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 5833,
    "id": 1076500451
  },
  {
    "city": "Windcrest",
    "city_ascii": "Windcrest",
    "lat": 29.5149,
    "lng": -98.3818,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 5876,
    "id": 1840022226
  },
  {
    "city": "Brookville",
    "city_ascii": "Brookville",
    "lat": 39.8393,
    "lng": -84.4176,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 5874,
    "id": 1840005562
  },
  {
    "city": "Hagenbach",
    "city_ascii": "Hagenbach",
    "lat": 49.0206,
    "lng": 8.2483,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Rhineland-Palatinate",
    "capital": "",
    "population": 5523,
    "id": 1276019811
  },
  {
    "city": "Houlton",
    "city_ascii": "Houlton",
    "lat": 46.1403,
    "lng": -67.8429,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maine",
    "capital": "",
    "population": 5871,
    "id": 1840052858
  },
  {
    "city": "Marlow Heights",
    "city_ascii": "Marlow Heights",
    "lat": 38.8237,
    "lng": -76.9485,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 5869,
    "id": 1840005961
  },
  {
    "city": "Humboldt",
    "city_ascii": "Humboldt",
    "lat": 52.2019,
    "lng": -105.1231,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Saskatchewan",
    "capital": "",
    "population": 5869,
    "id": 1124147660
  },
  {
    "city": "Mikomeseng",
    "city_ascii": "Mikomeseng",
    "lat": 2.1333,
    "lng": 10.6167,
    "country": "Equatorial Guinea",
    "iso2": "GQ",
    "iso3": "GNQ",
    "admin_name": "Kié-Ntem",
    "capital": "",
    "population": 5813,
    "id": 1226529757
  },
  {
    "city": "Wirges",
    "city_ascii": "Wirges",
    "lat": 50.4742,
    "lng": 7.7953,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Rhineland-Palatinate",
    "capital": "",
    "population": 5341,
    "id": 1276662542
  },
  {
    "city": "Hermon",
    "city_ascii": "Hermon",
    "lat": 44.8141,
    "lng": -68.9087,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maine",
    "capital": "",
    "population": 5867,
    "id": 1840052853
  },
  {
    "city": "Martlesham",
    "city_ascii": "Martlesham",
    "lat": 52.0733,
    "lng": 1.2818,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Suffolk",
    "capital": "",
    "population": 5478,
    "id": 1826066956
  },
  {
    "city": "Hutchins",
    "city_ascii": "Hutchins",
    "lat": 32.6421,
    "lng": -96.7093,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 5866,
    "id": 1840019437
  },
  {
    "city": "Chester",
    "city_ascii": "Chester",
    "lat": 41.357,
    "lng": -74.2769,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 5866,
    "id": 1840004884
  },
  {
    "city": "Glendale",
    "city_ascii": "Glendale",
    "lat": 38.5935,
    "lng": -90.3825,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 5866,
    "id": 1840008588
  },
  {
    "city": "Fürstenberg",
    "city_ascii": "Furstenberg",
    "lat": 53.1853,
    "lng": 13.1456,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Brandenburg",
    "capital": "",
    "population": 5838,
    "id": 1276740051
  },
  {
    "city": "Margate City",
    "city_ascii": "Margate City",
    "lat": 39.3307,
    "lng": -74.5071,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 5865,
    "id": 1840003793
  },
  {
    "city": "Posen",
    "city_ascii": "Posen",
    "lat": 41.6291,
    "lng": -87.6858,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 5865,
    "id": 1840011282
  },
  {
    "city": "Belton",
    "city_ascii": "Belton",
    "lat": 34.5237,
    "lng": -82.4937,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 5865,
    "id": 1840013582
  },
  {
    "city": "Delvinë",
    "city_ascii": "Delvine",
    "lat": 39.9494,
    "lng": 20.0958,
    "country": "Albania",
    "iso2": "AL",
    "iso3": "ALB",
    "admin_name": "Vlorë",
    "capital": "",
    "population": 5754,
    "id": 1008273800
  },
  {
    "city": "Paoli",
    "city_ascii": "Paoli",
    "lat": 40.042,
    "lng": -75.4912,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5864,
    "id": 1840005501
  },
  {
    "city": "Atmore",
    "city_ascii": "Atmore",
    "lat": 31.0927,
    "lng": -87.4763,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 5864,
    "id": 1840013888
  },
  {
    "city": "Alma",
    "city_ascii": "Alma",
    "lat": 35.4919,
    "lng": -94.2165,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arkansas",
    "capital": "",
    "population": 5864,
    "id": 1840013420
  },
  {
    "city": "Dowagiac",
    "city_ascii": "Dowagiac",
    "lat": 41.9834,
    "lng": -86.1126,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 5864,
    "id": 1840003256
  },
  {
    "city": "Durham",
    "city_ascii": "Durham",
    "lat": 39.6232,
    "lng": -121.7875,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 5863,
    "id": 1840018775
  },
  {
    "city": "Grebenstein",
    "city_ascii": "Grebenstein",
    "lat": 51.45,
    "lng": 9.4167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 5745,
    "id": 1276994004
  },
  {
    "city": "Modřice",
    "city_ascii": "Modrice",
    "lat": 49.1279,
    "lng": 16.6144,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Jihomoravský Kraj",
    "capital": "",
    "population": 5329,
    "id": 1203997915
  },
  {
    "city": "Spring Ridge",
    "city_ascii": "Spring Ridge",
    "lat": 39.4043,
    "lng": -77.3413,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 5859,
    "id": 1840031475
  },
  {
    "city": "Orchard Homes",
    "city_ascii": "Orchard Homes",
    "lat": 46.8559,
    "lng": -114.0778,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Montana",
    "capital": "",
    "population": 5859,
    "id": 1840032750
  },
  {
    "city": "Eastwood",
    "city_ascii": "Eastwood",
    "lat": 42.3028,
    "lng": -85.5447,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 5858,
    "id": 1840004656
  },
  {
    "city": "Arroyo de la Luz",
    "city_ascii": "Arroyo de la Luz",
    "lat": 39.484,
    "lng": -6.5846,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Extremadura",
    "capital": "",
    "population": 5811,
    "id": 1724665069
  },
  {
    "city": "Ḑawrān ad Daydah",
    "city_ascii": "Dawran ad Daydah",
    "lat": 14.7608,
    "lng": 44.1904,
    "country": "Yemen",
    "iso2": "YE",
    "iso3": "YEM",
    "admin_name": "Dhamār",
    "capital": "minor",
    "population": 5695,
    "id": 1887892367
  },
  {
    "city": "Weidenberg",
    "city_ascii": "Weidenberg",
    "lat": 49.9422,
    "lng": 11.7203,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 5772,
    "id": 1276385117
  },
  {
    "city": "Paulsboro",
    "city_ascii": "Paulsboro",
    "lat": 39.84,
    "lng": -75.2397,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 5854,
    "id": 1840001523
  },
  {
    "city": "Martonvásár",
    "city_ascii": "Martonvasar",
    "lat": 47.314,
    "lng": 18.7885,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Fejér",
    "capital": "minor",
    "population": 5672,
    "id": 1348618816
  },
  {
    "city": "New Martinsville",
    "city_ascii": "New Martinsville",
    "lat": 39.6636,
    "lng": -80.8569,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "West Virginia",
    "capital": "",
    "population": 5853,
    "id": 1840005670
  },
  {
    "city": "Breese",
    "city_ascii": "Breese",
    "lat": 38.6138,
    "lng": -89.523,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 5853,
    "id": 1840006153
  },
  {
    "city": "Red Cliffs",
    "city_ascii": "Red Cliffs",
    "lat": -34.3075,
    "lng": 142.1881,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Victoria",
    "capital": "",
    "population": 5060,
    "id": 1036463286
  },
  {
    "city": "Mount Arlington",
    "city_ascii": "Mount Arlington",
    "lat": 40.919,
    "lng": -74.639,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 5852,
    "id": 1840003579
  },
  {
    "city": "Neubulach",
    "city_ascii": "Neubulach",
    "lat": 48.6611,
    "lng": 8.6967,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 5624,
    "id": 1276878113
  },
  {
    "city": "Botley",
    "city_ascii": "Botley",
    "lat": 50.9144,
    "lng": -1.27,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hampshire",
    "capital": "",
    "population": 5100,
    "id": 1826553905
  },
  {
    "city": "Pontiac",
    "city_ascii": "Pontiac",
    "lat": 45.5833,
    "lng": -76.1333,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 5850,
    "id": 1124000248
  },
  {
    "city": "Carterville",
    "city_ascii": "Carterville",
    "lat": 37.763,
    "lng": -89.0841,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 5847,
    "id": 1840007575
  },
  {
    "city": "Hilmar-Irwin",
    "city_ascii": "Hilmar-Irwin",
    "lat": 37.4045,
    "lng": -120.8504,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 5846,
    "id": 1840074136
  },
  {
    "city": "Lyndon",
    "city_ascii": "Lyndon",
    "lat": 44.545,
    "lng": -72.0076,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Vermont",
    "capital": "",
    "population": 5845,
    "id": 1840070820
  },
  {
    "city": "Yakoruda",
    "city_ascii": "Yakoruda",
    "lat": 42.0183,
    "lng": 23.6697,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Blagoevgrad",
    "capital": "",
    "population": 5813,
    "id": 1100407647
  },
  {
    "city": "Pelican Bay",
    "city_ascii": "Pelican Bay",
    "lat": 26.2326,
    "lng": -81.8108,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 5844,
    "id": 1840028996
  },
  {
    "city": "Willow Park",
    "city_ascii": "Willow Park",
    "lat": 32.7548,
    "lng": -97.6499,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 5842,
    "id": 1840022042
  },
  {
    "city": "Hartwell",
    "city_ascii": "Hartwell",
    "lat": 34.3496,
    "lng": -82.9289,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 5841,
    "id": 1840013614
  },
  {
    "city": "Wilson",
    "city_ascii": "Wilson",
    "lat": 43.2692,
    "lng": -78.8238,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 5840,
    "id": 1840004274
  },
  {
    "city": "Woodcliff Lake",
    "city_ascii": "Woodcliff Lake",
    "lat": 41.0253,
    "lng": -74.0603,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 5839,
    "id": 1840000924
  },
  {
    "city": "Shady Side",
    "city_ascii": "Shady Side",
    "lat": 38.8285,
    "lng": -76.5211,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 5839,
    "id": 1840005927
  },
  {
    "city": "Riverside",
    "city_ascii": "Riverside",
    "lat": 39.4777,
    "lng": -76.2385,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 5839,
    "id": 1840031473
  },
  {
    "city": "Cave Creek",
    "city_ascii": "Cave Creek",
    "lat": 33.8513,
    "lng": -111.9801,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 5838,
    "id": 1840022915
  },
  {
    "city": "Cambria",
    "city_ascii": "Cambria",
    "lat": 40.4931,
    "lng": -78.7448,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5837,
    "id": 1840145844
  },
  {
    "city": "Chelsea",
    "city_ascii": "Chelsea",
    "lat": 42.3132,
    "lng": -84.0188,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 5837,
    "id": 1840006976
  },
  {
    "city": "Lower Heidelberg",
    "city_ascii": "Lower Heidelberg",
    "lat": 40.3556,
    "lng": -76.0528,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5836,
    "id": 1840150386
  },
  {
    "city": "Třešť",
    "city_ascii": "Trest'",
    "lat": 49.291,
    "lng": 15.4821,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Vysočina",
    "capital": "",
    "population": 5714,
    "id": 1203116635
  },
  {
    "city": "Roseland",
    "city_ascii": "Roseland",
    "lat": 40.8208,
    "lng": -74.3085,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 5835,
    "id": 1840000998
  },
  {
    "city": "Luling",
    "city_ascii": "Luling",
    "lat": 29.6814,
    "lng": -97.6468,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 5834,
    "id": 1840020937
  },
  {
    "city": "Greenwood",
    "city_ascii": "Greenwood",
    "lat": 38.8508,
    "lng": -94.3378,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 5833,
    "id": 1840007397
  },
  {
    "city": "Jászfényszaru",
    "city_ascii": "Jaszfenyszaru",
    "lat": 47.5667,
    "lng": 19.7167,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Jász-Nagykun-Szolnok",
    "capital": "",
    "population": 5757,
    "id": 1348708347
  },
  {
    "city": "Fílyro",
    "city_ascii": "Filyro",
    "lat": 40.6911,
    "lng": 23.0042,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Kentrikí Makedonía",
    "capital": "",
    "population": 5495,
    "id": 1300219931
  },
  {
    "city": "Moosic",
    "city_ascii": "Moosic",
    "lat": 41.3584,
    "lng": -75.7027,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5832,
    "id": 1840003385
  },
  {
    "city": "Potcoava",
    "city_ascii": "Potcoava",
    "lat": 44.491,
    "lng": 24.6083,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Olt",
    "capital": "",
    "population": 5743,
    "id": 1642744206
  },
  {
    "city": "Hellertown",
    "city_ascii": "Hellertown",
    "lat": 40.5811,
    "lng": -75.3378,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5831,
    "id": 1840000973
  },
  {
    "city": "Moravský Krumlov",
    "city_ascii": "Moravsky Krumlov",
    "lat": 49.049,
    "lng": 16.3117,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Jihomoravský Kraj",
    "capital": "",
    "population": 5715,
    "id": 1203850566
  },
  {
    "city": "Tewksbury",
    "city_ascii": "Tewksbury",
    "lat": 40.6951,
    "lng": -74.7783,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 5830,
    "id": 1840081720
  },
  {
    "city": "Berne",
    "city_ascii": "Berne",
    "lat": 40.6572,
    "lng": -84.9555,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 5830,
    "id": 1840007176
  },
  {
    "city": "Oreland",
    "city_ascii": "Oreland",
    "lat": 40.1148,
    "lng": -75.1801,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5827,
    "id": 1840005458
  },
  {
    "city": "St. Paul",
    "city_ascii": "St. Paul",
    "lat": 53.9928,
    "lng": -111.2972,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Alberta",
    "capital": "",
    "population": 5827,
    "id": 1124528022
  },
  {
    "city": "Tupper Lake",
    "city_ascii": "Tupper Lake",
    "lat": 44.2431,
    "lng": -74.4747,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 5826,
    "id": 1840004069
  },
  {
    "city": "Poshekhonye",
    "city_ascii": "Poshekhonye",
    "lat": 58.5,
    "lng": 39.1167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Yaroslavskaya Oblast’",
    "capital": "",
    "population": 5821,
    "id": 1643498463
  },
  {
    "city": "‘Alavīcheh",
    "city_ascii": "`Alavicheh",
    "lat": 33.0528,
    "lng": 51.0825,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Eşfahān",
    "capital": "",
    "population": 5692,
    "id": 1364605877
  },
  {
    "city": "Fountainhead-Orchard Hills",
    "city_ascii": "Fountainhead-Orchard Hills",
    "lat": 39.6878,
    "lng": -77.7173,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 5823,
    "id": 1840073674
  },
  {
    "city": "Davyd-Haradok",
    "city_ascii": "Davyd-Haradok",
    "lat": 52.0556,
    "lng": 27.2139,
    "country": "Belarus",
    "iso2": "BY",
    "iso3": "BLR",
    "admin_name": "Brestskaya Voblasts’",
    "capital": "",
    "population": 5800,
    "id": 1112859009
  },
  {
    "city": "Woodstock",
    "city_ascii": "Woodstock",
    "lat": 42.0557,
    "lng": -74.1653,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 5822,
    "id": 1840058625
  },
  {
    "city": "Elverta",
    "city_ascii": "Elverta",
    "lat": 38.7185,
    "lng": -121.4455,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 5821,
    "id": 1840024623
  },
  {
    "city": "Orleans",
    "city_ascii": "Orleans",
    "lat": 41.7665,
    "lng": -69.9675,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 5821,
    "id": 1840053503
  },
  {
    "city": "Schwarzheide",
    "city_ascii": "Schwarzheide",
    "lat": 51.4831,
    "lng": 13.8333,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Brandenburg",
    "capital": "",
    "population": 5652,
    "id": 1276258365
  },
  {
    "city": "Kremastí",
    "city_ascii": "Kremasti",
    "lat": 36.4105,
    "lng": 28.1191,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Notío Aigaío",
    "capital": "",
    "population": 5363,
    "id": 1300559185
  },
  {
    "city": "Kameno",
    "city_ascii": "Kameno",
    "lat": 42.5667,
    "lng": 27.3,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Burgas",
    "capital": "",
    "population": 5788,
    "id": 1100253032
  },
  {
    "city": "North Windham",
    "city_ascii": "North Windham",
    "lat": 43.8238,
    "lng": -70.4288,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maine",
    "capital": "",
    "population": 5820,
    "id": 1840002553
  },
  {
    "city": "Füzesgyarmat",
    "city_ascii": "Fuzesgyarmat",
    "lat": 47.1058,
    "lng": 21.2119,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Békés",
    "capital": "",
    "population": 5774,
    "id": 1348078449
  },
  {
    "city": "Szécsény",
    "city_ascii": "Szecseny",
    "lat": 48.0808,
    "lng": 19.5194,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Nógrád",
    "capital": "minor",
    "population": 5695,
    "id": 1348174146
  },
  {
    "city": "El Granada",
    "city_ascii": "El Granada",
    "lat": 37.5134,
    "lng": -122.466,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 5819,
    "id": 1840017624
  },
  {
    "city": "Balerno",
    "city_ascii": "Balerno",
    "lat": 55.8852,
    "lng": -3.3375,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Edinburgh, City of",
    "capital": "",
    "population": 5486,
    "id": 1826189671
  },
  {
    "city": "Aylsham",
    "city_ascii": "Aylsham",
    "lat": 52.79,
    "lng": 1.256,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Norfolk",
    "capital": "",
    "population": 5504,
    "id": 1826659481
  },
  {
    "city": "Lebanon",
    "city_ascii": "Lebanon",
    "lat": 37.5689,
    "lng": -85.2578,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kentucky",
    "capital": "",
    "population": 5817,
    "id": 1840014353
  },
  {
    "city": "Charlestown",
    "city_ascii": "Charlestown",
    "lat": 40.0851,
    "lng": -75.5588,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5813,
    "id": 1840149884
  },
  {
    "city": "Volo",
    "city_ascii": "Volo",
    "lat": 42.3298,
    "lng": -88.1599,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 5813,
    "id": 1840011182
  },
  {
    "city": "South Hooksett",
    "city_ascii": "South Hooksett",
    "lat": 43.0337,
    "lng": -71.4256,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Hampshire",
    "capital": "",
    "population": 5811,
    "id": 1840002803
  },
  {
    "city": "White Rock",
    "city_ascii": "White Rock",
    "lat": 35.8075,
    "lng": -106.2067,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Mexico",
    "capital": "",
    "population": 5809,
    "id": 1840019105
  },
  {
    "city": "Wittichenau",
    "city_ascii": "Wittichenau",
    "lat": 51.385,
    "lng": 14.244,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 5715,
    "id": 1276775107
  },
  {
    "city": "Burnet",
    "city_ascii": "Burnet",
    "lat": 30.7496,
    "lng": -98.2383,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 5808,
    "id": 1840019567
  },
  {
    "city": "Sewickley",
    "city_ascii": "Sewickley",
    "lat": 40.2522,
    "lng": -79.731,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5808,
    "id": 1840149354
  },
  {
    "city": "Fischamend",
    "city_ascii": "Fischamend",
    "lat": 48.1156,
    "lng": 16.6128,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Niederösterreich",
    "capital": "",
    "population": 5583,
    "id": 1040012933
  },
  {
    "city": "Beardstown",
    "city_ascii": "Beardstown",
    "lat": 39.9994,
    "lng": -90.4178,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 5807,
    "id": 1840007268
  },
  {
    "city": "Manasquan",
    "city_ascii": "Manasquan",
    "lat": 40.1186,
    "lng": -74.045,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 5806,
    "id": 1840003674
  },
  {
    "city": "Nagyhalász",
    "city_ascii": "Nagyhalasz",
    "lat": 48.1333,
    "lng": 21.7583,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Szabolcs-Szatmár-Bereg",
    "capital": "",
    "population": 5676,
    "id": 1348415216
  },
  {
    "city": "Hamanaka-sakuraminami",
    "city_ascii": "Hamanaka-sakuraminami",
    "lat": 43.0772,
    "lng": 145.1294,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hokkaidō",
    "capital": "",
    "population": 5789,
    "id": 1392930380
  },
  {
    "city": "Fellsmere",
    "city_ascii": "Fellsmere",
    "lat": 27.7241,
    "lng": -80.5975,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 5800,
    "id": 1840014165
  },
  {
    "city": "West Bountiful",
    "city_ascii": "West Bountiful",
    "lat": 40.8982,
    "lng": -111.9082,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Utah",
    "capital": "",
    "population": 5800,
    "id": 1840021353
  },
  {
    "city": "Ross Township",
    "city_ascii": "Ross Township",
    "lat": 40.8772,
    "lng": -75.3607,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5799,
    "id": 1840144160
  },
  {
    "city": "Tatsugō",
    "city_ascii": "Tatsugo",
    "lat": 28.4131,
    "lng": 129.5894,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Kagoshima",
    "capital": "",
    "population": 5728,
    "id": 1392478933
  },
  {
    "city": "Idabel",
    "city_ascii": "Idabel",
    "lat": 33.9041,
    "lng": -94.8294,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oklahoma",
    "capital": "",
    "population": 5798,
    "id": 1840019260
  },
  {
    "city": "Aptos",
    "city_ascii": "Aptos",
    "lat": 36.9912,
    "lng": -121.8934,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 5797,
    "id": 1840017648
  },
  {
    "city": "Svätý Jur",
    "city_ascii": "Svaty Jur",
    "lat": 48.2519,
    "lng": 17.2156,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Bratislavský",
    "capital": "",
    "population": 5655,
    "id": 1703571007
  },
  {
    "city": "Selkirk",
    "city_ascii": "Selkirk",
    "lat": 55.55,
    "lng": -2.84,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Scottish Borders, The",
    "capital": "",
    "population": 5784,
    "id": 1826863244
  },
  {
    "city": "Vårgårda",
    "city_ascii": "Vargarda",
    "lat": 58.0341,
    "lng": 12.8083,
    "country": "Sweden",
    "iso2": "SE",
    "iso3": "SWE",
    "admin_name": "Västra Götaland",
    "capital": "minor",
    "population": 5735,
    "id": 1752801056
  },
  {
    "city": "Tomislavgrad",
    "city_ascii": "Tomislavgrad",
    "lat": 43.7135,
    "lng": 17.2266,
    "country": "Bosnia And Herzegovina",
    "iso2": "BA",
    "iso3": "BIH",
    "admin_name": "Bosnia and Herzegovina, Federation of",
    "capital": "minor",
    "population": 5760,
    "id": 1070139386
  },
  {
    "city": "Schrozberg",
    "city_ascii": "Schrozberg",
    "lat": 49.3444,
    "lng": 9.9806,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 5741,
    "id": 1276175014
  },
  {
    "city": "Wyndmoor",
    "city_ascii": "Wyndmoor",
    "lat": 40.0856,
    "lng": -75.1941,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5795,
    "id": 1840035271
  },
  {
    "city": "Mount Zion",
    "city_ascii": "Mount Zion",
    "lat": 39.7794,
    "lng": -88.8834,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 5795,
    "id": 1840012429
  },
  {
    "city": "Boxborough",
    "city_ascii": "Boxborough",
    "lat": 42.4884,
    "lng": -71.5178,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 5794,
    "id": 1840070183
  },
  {
    "city": "Fernando Prestes",
    "city_ascii": "Fernando Prestes",
    "lat": -21.2644,
    "lng": -48.6853,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 5760,
    "id": 1076151102
  },
  {
    "city": "Türi",
    "city_ascii": "Turi",
    "lat": 58.8094,
    "lng": 25.4292,
    "country": "Estonia",
    "iso2": "EE",
    "iso3": "EST",
    "admin_name": "Järvamaa",
    "capital": "minor",
    "population": 5257,
    "id": 1233742654
  },
  {
    "city": "Norwood",
    "city_ascii": "Norwood",
    "lat": 40.9933,
    "lng": -73.951,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 5793,
    "id": 1840000911
  },
  {
    "city": "Portland",
    "city_ascii": "Portland",
    "lat": 40.4375,
    "lng": -84.9833,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 5793,
    "id": 1840014175
  },
  {
    "city": "Riachos",
    "city_ascii": "Riachos",
    "lat": 39.4453,
    "lng": -8.5142,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Santarém",
    "capital": "",
    "population": 5420,
    "id": 1620669339
  },
  {
    "city": "Mendham",
    "city_ascii": "Mendham",
    "lat": 40.7828,
    "lng": -74.5943,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 5792,
    "id": 1840003575
  },
  {
    "city": "Patterson",
    "city_ascii": "Patterson",
    "lat": 29.6909,
    "lng": -91.3096,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 5792,
    "id": 1840015057
  },
  {
    "city": "Jilemnice",
    "city_ascii": "Jilemnice",
    "lat": 50.6089,
    "lng": 15.5066,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Liberecký Kraj",
    "capital": "",
    "population": 5401,
    "id": 1203130081
  },
  {
    "city": "Villa Park",
    "city_ascii": "Villa Park",
    "lat": 33.818,
    "lng": -117.8104,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 5790,
    "id": 1840021968
  },
  {
    "city": "Belogradchik",
    "city_ascii": "Belogradchik",
    "lat": 43.6269,
    "lng": 22.6833,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Vidin",
    "capital": "minor",
    "population": 5775,
    "id": 1100340297
  },
  {
    "city": "Marianna",
    "city_ascii": "Marianna",
    "lat": 30.7943,
    "lng": -85.226,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 5787,
    "id": 1840015897
  },
  {
    "city": "The Village of Indian Hill",
    "city_ascii": "The Village of Indian Hill",
    "lat": 39.1916,
    "lng": -84.3344,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 5786,
    "id": 1840034360
  },
  {
    "city": "Sudzha",
    "city_ascii": "Sudzha",
    "lat": 51.1907,
    "lng": 35.2708,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kurskaya Oblast’",
    "capital": "",
    "population": 5759,
    "id": 1643775034
  },
  {
    "city": "Little Silver",
    "city_ascii": "Little Silver",
    "lat": 40.3357,
    "lng": -74.0346,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 5782,
    "id": 1840003671
  },
  {
    "city": "East Falmouth",
    "city_ascii": "East Falmouth",
    "lat": 41.5707,
    "lng": -70.5556,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 5782,
    "id": 1840003237
  },
  {
    "city": "Rudná",
    "city_ascii": "Rudna",
    "lat": 50.0351,
    "lng": 14.2344,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Středočeský Kraj",
    "capital": "",
    "population": 5153,
    "id": 1203265351
  },
  {
    "city": "Crockett",
    "city_ascii": "Crockett",
    "lat": 31.3177,
    "lng": -95.4564,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 5780,
    "id": 1840019547
  },
  {
    "city": "Peoria Heights",
    "city_ascii": "Peoria Heights",
    "lat": 40.7466,
    "lng": -89.57,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 5779,
    "id": 1840011929
  },
  {
    "city": "Dunboyne",
    "city_ascii": "Dunboyne",
    "lat": 53.4199,
    "lng": -6.4751,
    "country": "Ireland",
    "iso2": "IE",
    "iso3": "IRL",
    "admin_name": "Meath",
    "capital": "",
    "population": 5713,
    "id": 1372446066
  },
  {
    "city": "Cambria",
    "city_ascii": "Cambria",
    "lat": 43.1766,
    "lng": -78.8202,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 5777,
    "id": 1840087256
  },
  {
    "city": "Hilton",
    "city_ascii": "Hilton",
    "lat": 43.29,
    "lng": -77.7925,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 5777,
    "id": 1840004281
  },
  {
    "city": "Deschutes River Woods",
    "city_ascii": "Deschutes River Woods",
    "lat": 43.9887,
    "lng": -121.3608,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 5775,
    "id": 1840034826
  },
  {
    "city": "Meymeh",
    "city_ascii": "Meymeh",
    "lat": 33.4461,
    "lng": 51.1681,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Eşfahān",
    "capital": "",
    "population": 5733,
    "id": 1364218666
  },
  {
    "city": "Kunovice",
    "city_ascii": "Kunovice",
    "lat": 49.045,
    "lng": 17.4701,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Zlínský Kraj",
    "capital": "",
    "population": 5579,
    "id": 1203763674
  },
  {
    "city": "Palmview",
    "city_ascii": "Palmview",
    "lat": 26.2303,
    "lng": -98.3791,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 5774,
    "id": 1840021028
  },
  {
    "city": "Granite Shoals",
    "city_ascii": "Granite Shoals",
    "lat": 30.5897,
    "lng": -98.3715,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 5774,
    "id": 1840020867
  },
  {
    "city": "Vítkov",
    "city_ascii": "Vitkov",
    "lat": 49.7744,
    "lng": 17.7495,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Moravskoslezský Kraj",
    "capital": "",
    "population": 5670,
    "id": 1203808580
  },
  {
    "city": "Eaton",
    "city_ascii": "Eaton",
    "lat": 40.5256,
    "lng": -104.713,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 5773,
    "id": 1840021359
  },
  {
    "city": "Vetovo",
    "city_ascii": "Vetovo",
    "lat": 43.7,
    "lng": 26.2667,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Ruse",
    "capital": "",
    "population": 5707,
    "id": 1100750747
  },
  {
    "city": "Šluknov",
    "city_ascii": "Sluknov",
    "lat": 51.0038,
    "lng": 14.4527,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Ústecký Kraj",
    "capital": "",
    "population": 5653,
    "id": 1203890462
  },
  {
    "city": "Gas City",
    "city_ascii": "Gas City",
    "lat": 40.4889,
    "lng": -85.5946,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 5772,
    "id": 1840008338
  },
  {
    "city": "Turner",
    "city_ascii": "Turner",
    "lat": 44.2671,
    "lng": -70.2429,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maine",
    "capital": "",
    "population": 5772,
    "id": 1840053031
  },
  {
    "city": "Lomnice nad Popelkou",
    "city_ascii": "Lomnice nad Popelkou",
    "lat": 50.5307,
    "lng": 15.3735,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Liberecký Kraj",
    "capital": "",
    "population": 5554,
    "id": 1203444057
  },
  {
    "city": "Claverack",
    "city_ascii": "Claverack",
    "lat": 42.2235,
    "lng": -73.6873,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 5771,
    "id": 1840087313
  },
  {
    "city": "Attalla",
    "city_ascii": "Attalla",
    "lat": 34.0049,
    "lng": -86.104,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 5771,
    "id": 1840013655
  },
  {
    "city": "Belding",
    "city_ascii": "Belding",
    "lat": 43.0968,
    "lng": -85.2331,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 5771,
    "id": 1840003074
  },
  {
    "city": "Bannockburn",
    "city_ascii": "Bannockburn",
    "lat": -38.05,
    "lng": 144.1667,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Victoria",
    "capital": "",
    "population": 5283,
    "id": 1036085175
  },
  {
    "city": "Washingtonville",
    "city_ascii": "Washingtonville",
    "lat": 41.4296,
    "lng": -74.1578,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 5770,
    "id": 1840004882
  },
  {
    "city": "Crafton",
    "city_ascii": "Crafton",
    "lat": 40.4333,
    "lng": -80.0712,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5770,
    "id": 1840001221
  },
  {
    "city": "Suolahti",
    "city_ascii": "Suolahti",
    "lat": 62.5639,
    "lng": 25.8528,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Keski-Suomi",
    "capital": "",
    "population": 5747,
    "id": 1246348554
  },
  {
    "city": "Brush",
    "city_ascii": "Brush",
    "lat": 40.2598,
    "lng": -103.6323,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 5769,
    "id": 1840018758
  },
  {
    "city": "Sidney",
    "city_ascii": "Sidney",
    "lat": 41.134,
    "lng": -102.9681,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Nebraska",
    "capital": "",
    "population": 5767,
    "id": 1840009309
  },
  {
    "city": "Ajdir",
    "city_ascii": "Ajdir",
    "lat": 35.193,
    "lng": -3.912,
    "country": "Morocco",
    "iso2": "MA",
    "iso3": "MAR",
    "admin_name": "Tanger-Tétouan-Al Hoceïma",
    "capital": "",
    "population": 5314,
    "id": 1504143111
  },
  {
    "city": "Ramblewood",
    "city_ascii": "Ramblewood",
    "lat": 39.9322,
    "lng": -74.9527,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 5765,
    "id": 1840033392
  },
  {
    "city": "Dobšiná",
    "city_ascii": "Dobsina",
    "lat": 48.8206,
    "lng": 20.3675,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Košický",
    "capital": "",
    "population": 5696,
    "id": 1703079364
  },
  {
    "city": "Blairstown",
    "city_ascii": "Blairstown",
    "lat": 40.9813,
    "lng": -74.9984,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 5764,
    "id": 1840081810
  },
  {
    "city": "Piacatu",
    "city_ascii": "Piacatu",
    "lat": -21.5922,
    "lng": -50.5992,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 5739,
    "id": 1076475752
  },
  {
    "city": "Oak Point",
    "city_ascii": "Oak Point",
    "lat": 33.1803,
    "lng": -96.9911,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 5762,
    "id": 1840020642
  },
  {
    "city": "Elbridge",
    "city_ascii": "Elbridge",
    "lat": 43.0535,
    "lng": -76.4389,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 5761,
    "id": 1840004333
  },
  {
    "city": "Dickson City",
    "city_ascii": "Dickson City",
    "lat": 41.4684,
    "lng": -75.6358,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5761,
    "id": 1840003380
  },
  {
    "city": "Pauls Valley",
    "city_ascii": "Pauls Valley",
    "lat": 34.7236,
    "lng": -97.2291,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oklahoma",
    "capital": "",
    "population": 5761,
    "id": 1840020479
  },
  {
    "city": "Coroados",
    "city_ascii": "Coroados",
    "lat": -21.3519,
    "lng": -50.2814,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 5753,
    "id": 1076699768
  },
  {
    "city": "Lee",
    "city_ascii": "Lee",
    "lat": 42.302,
    "lng": -73.2341,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 5760,
    "id": 1840053513
  },
  {
    "city": "Strawberry",
    "city_ascii": "Strawberry",
    "lat": 37.8925,
    "lng": -122.5078,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 5759,
    "id": 1840136945
  },
  {
    "city": "Viana do Alentejo",
    "city_ascii": "Viana do Alentejo",
    "lat": 38.3342,
    "lng": -8.0014,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Évora",
    "capital": "minor",
    "population": 5743,
    "id": 1620076149
  },
  {
    "city": "Chlumec nad Cidlinou",
    "city_ascii": "Chlumec nad Cidlinou",
    "lat": 50.1545,
    "lng": 15.4603,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Královéhradecký Kraj",
    "capital": "",
    "population": 5501,
    "id": 1203796935
  },
  {
    "city": "Cromwell",
    "city_ascii": "Cromwell",
    "lat": -45.04,
    "lng": 169.2,
    "country": "New Zealand",
    "iso2": "NZ",
    "iso3": "NZL",
    "admin_name": "Otago",
    "capital": "",
    "population": 5440,
    "id": 1554511782
  },
  {
    "city": "Greenacres",
    "city_ascii": "Greenacres",
    "lat": 35.3831,
    "lng": -119.1184,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 5757,
    "id": 1840026940
  },
  {
    "city": "Großschirma",
    "city_ascii": "Grossschirma",
    "lat": 50.9664,
    "lng": 13.2781,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 5665,
    "id": 1276346022
  },
  {
    "city": "Três Fronteiras",
    "city_ascii": "Tres Fronteiras",
    "lat": -20.235,
    "lng": -50.89,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 5719,
    "id": 1076993785
  },
  {
    "city": "Elena",
    "city_ascii": "Elena",
    "lat": 42.9297,
    "lng": 25.8772,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Veliko Tarnovo",
    "capital": "",
    "population": 5742,
    "id": 1100928563
  },
  {
    "city": "Lansdowne",
    "city_ascii": "Lansdowne",
    "lat": 29.83,
    "lng": 78.68,
    "country": "India",
    "iso2": "IN",
    "iso3": "IND",
    "admin_name": "Uttarakhand",
    "capital": "",
    "population": 5667,
    "id": 1356052732
  },
  {
    "city": "Bodenwerder",
    "city_ascii": "Bodenwerder",
    "lat": 51.9667,
    "lng": 9.5167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 5561,
    "id": 1276686500
  },
  {
    "city": "Mondorf-les-Bains",
    "city_ascii": "Mondorf-les-Bains",
    "lat": 49.5069,
    "lng": 6.2806,
    "country": "Luxembourg",
    "iso2": "LU",
    "iso3": "LUX",
    "admin_name": "Remich",
    "capital": "minor",
    "population": 5359,
    "id": 1442368398
  },
  {
    "city": "St. Augustine South",
    "city_ascii": "St. Augustine South",
    "lat": 29.8449,
    "lng": -81.3156,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 5750,
    "id": 1840029110
  },
  {
    "city": "Volney",
    "city_ascii": "Volney",
    "lat": 43.3594,
    "lng": -76.3732,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 5750,
    "id": 1840058568
  },
  {
    "city": "Sansom Park",
    "city_ascii": "Sansom Park",
    "lat": 32.8028,
    "lng": -97.4021,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 5748,
    "id": 1840022047
  },
  {
    "city": "Asbury",
    "city_ascii": "Asbury",
    "lat": 42.5119,
    "lng": -90.7795,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Iowa",
    "capital": "",
    "population": 5747,
    "id": 1840006928
  },
  {
    "city": "Fort Wright",
    "city_ascii": "Fort Wright",
    "lat": 39.0462,
    "lng": -84.5362,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kentucky",
    "capital": "",
    "population": 5745,
    "id": 1840013171
  },
  {
    "city": "Tonantins",
    "city_ascii": "Tonantins",
    "lat": -2.8731,
    "lng": -67.8022,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Amazonas",
    "capital": "",
    "population": 4561,
    "id": 1076820908
  },
  {
    "city": "Traben-Trarbach",
    "city_ascii": "Traben-Trarbach",
    "lat": 49.9511,
    "lng": 7.1167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Rhineland-Palatinate",
    "capital": "",
    "population": 5567,
    "id": 1276000651
  },
  {
    "city": "Melk",
    "city_ascii": "Melk",
    "lat": 48.2269,
    "lng": 15.3439,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Niederösterreich",
    "capital": "minor",
    "population": 5529,
    "id": 1040399784
  },
  {
    "city": "Yoakum",
    "city_ascii": "Yoakum",
    "lat": 29.2933,
    "lng": -97.1469,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 5744,
    "id": 1840022242
  },
  {
    "city": "Zubří",
    "city_ascii": "Zubri",
    "lat": 49.466,
    "lng": 18.0925,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Zlínský Kraj",
    "capital": "",
    "population": 5547,
    "id": 1203980349
  },
  {
    "city": "Linglestown",
    "city_ascii": "Linglestown",
    "lat": 40.3445,
    "lng": -76.795,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5742,
    "id": 1840005401
  },
  {
    "city": "Petrolia",
    "city_ascii": "Petrolia",
    "lat": 42.8833,
    "lng": -82.1417,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 5742,
    "id": 1124479624
  },
  {
    "city": "Warrenton",
    "city_ascii": "Warrenton",
    "lat": 46.1685,
    "lng": -123.9302,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 5739,
    "id": 1840021183
  },
  {
    "city": "Sinton",
    "city_ascii": "Sinton",
    "lat": 28.0392,
    "lng": -97.5154,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 5738,
    "id": 1840022251
  },
  {
    "city": "Hjärup",
    "city_ascii": "Hjarup",
    "lat": 55.6707,
    "lng": 13.141,
    "country": "Sweden",
    "iso2": "SE",
    "iso3": "SWE",
    "admin_name": "Skåne",
    "capital": "",
    "population": 5607,
    "id": 1752111742
  },
  {
    "city": "Pyrford",
    "city_ascii": "Pyrford",
    "lat": 51.3146,
    "lng": -0.5064,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Surrey",
    "capital": "",
    "population": 5022,
    "id": 1826682377
  },
  {
    "city": "Harris Hill",
    "city_ascii": "Harris Hill",
    "lat": 42.973,
    "lng": -78.6793,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 5737,
    "id": 1840004378
  },
  {
    "city": "Fair Haven",
    "city_ascii": "Fair Haven",
    "lat": 40.3619,
    "lng": -74.0392,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 5736,
    "id": 1840003681
  },
  {
    "city": "Union Township",
    "city_ascii": "Union Township",
    "lat": 40.2477,
    "lng": -79.9843,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5735,
    "id": 1840147978
  },
  {
    "city": "Zsámbék",
    "city_ascii": "Zsambek",
    "lat": 47.5483,
    "lng": 18.7186,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Pest",
    "capital": "",
    "population": 5569,
    "id": 1348335976
  },
  {
    "city": "East Rockhill",
    "city_ascii": "East Rockhill",
    "lat": 40.4082,
    "lng": -75.2831,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5734,
    "id": 1840152151
  },
  {
    "city": "Oleiros",
    "city_ascii": "Oleiros",
    "lat": 39.9167,
    "lng": -7.9167,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Castelo Branco",
    "capital": "minor",
    "population": 5721,
    "id": 1620323086
  },
  {
    "city": "Uncia",
    "city_ascii": "Uncia",
    "lat": -18.4682,
    "lng": -66.5648,
    "country": "Bolivia",
    "iso2": "BO",
    "iso3": "BOL",
    "admin_name": "Potosí",
    "capital": "",
    "population": 5709,
    "id": 1068104554
  },
  {
    "city": "Benton",
    "city_ascii": "Benton",
    "lat": 32.6906,
    "lng": -93.74,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 5732,
    "id": 1840015769
  },
  {
    "city": "Wasungen",
    "city_ascii": "Wasungen",
    "lat": 50.6667,
    "lng": 10.3667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Thuringia",
    "capital": "",
    "population": 5541,
    "id": 1276611989
  },
  {
    "city": "Sennwald",
    "city_ascii": "Sennwald",
    "lat": 47.2439,
    "lng": 9.4768,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Sankt Gallen",
    "capital": "",
    "population": 5592,
    "id": 1756513022
  },
  {
    "city": "Cambria",
    "city_ascii": "Cambria",
    "lat": 35.5523,
    "lng": -121.0847,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 5725,
    "id": 1840017806
  },
  {
    "city": "Lőrinci",
    "city_ascii": "Lorinci",
    "lat": 47.7378,
    "lng": 19.6786,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Heves",
    "capital": "",
    "population": 5557,
    "id": 1348635054
  },
  {
    "city": "Santa Ernestina",
    "city_ascii": "Santa Ernestina",
    "lat": -21.4628,
    "lng": -48.3908,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 5681,
    "id": 1076666581
  },
  {
    "city": "New Gloucester",
    "city_ascii": "New Gloucester",
    "lat": 43.9641,
    "lng": -70.2959,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maine",
    "capital": "",
    "population": 5723,
    "id": 1840052925
  },
  {
    "city": "Southwest Middlesex",
    "city_ascii": "Southwest Middlesex",
    "lat": 42.75,
    "lng": -81.7,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 5723,
    "id": 1124000520
  },
  {
    "city": "Guaimbê",
    "city_ascii": "Guaimbe",
    "lat": -21.91,
    "lng": -49.8967,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 5696,
    "id": 1076615587
  },
  {
    "city": "Oceanport",
    "city_ascii": "Oceanport",
    "lat": 40.316,
    "lng": -74.0205,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 5722,
    "id": 1840001365
  },
  {
    "city": "Hachirougata",
    "city_ascii": "Hachirougata",
    "lat": 39.9494,
    "lng": 140.0733,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Akita",
    "capital": "",
    "population": 5636,
    "id": 1392976539
  },
  {
    "city": "South Haven",
    "city_ascii": "South Haven",
    "lat": 42.4011,
    "lng": -86.2677,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 5719,
    "id": 1840003178
  },
  {
    "city": "Velence",
    "city_ascii": "Velence",
    "lat": 47.2418,
    "lng": 18.6479,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Fejér",
    "capital": "",
    "population": 5552,
    "id": 1348649825
  },
  {
    "city": "Hampstead",
    "city_ascii": "Hampstead",
    "lat": 34.3627,
    "lng": -77.7318,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 5717,
    "id": 1840025847
  },
  {
    "city": "Mezőcsát",
    "city_ascii": "Mezocsat",
    "lat": 47.8233,
    "lng": 20.9028,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Borsod-Abaúj-Zemplén",
    "capital": "minor",
    "population": 5661,
    "id": 1348108060
  },
  {
    "city": "Grafton",
    "city_ascii": "Grafton",
    "lat": 39.3409,
    "lng": -80.0161,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "West Virginia",
    "capital": "",
    "population": 5714,
    "id": 1840005779
  },
  {
    "city": "Strážnice",
    "city_ascii": "Straznice",
    "lat": 48.901,
    "lng": 17.3168,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Jihomoravský Kraj",
    "capital": "",
    "population": 5537,
    "id": 1203943711
  },
  {
    "city": "Slaton",
    "city_ascii": "Slaton",
    "lat": 33.4421,
    "lng": -101.6476,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 5713,
    "id": 1840021982
  },
  {
    "city": "Saratoga",
    "city_ascii": "Saratoga",
    "lat": 43.0604,
    "lng": -73.6474,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 5713,
    "id": 1840087886
  },
  {
    "city": "Grabow",
    "city_ascii": "Grabow",
    "lat": 53.2798,
    "lng": 11.5531,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Mecklenburg-Western Pomerania",
    "capital": "",
    "population": 5633,
    "id": 1276851550
  },
  {
    "city": "Ulysses",
    "city_ascii": "Ulysses",
    "lat": 37.5774,
    "lng": -101.3549,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kansas",
    "capital": "",
    "population": 5711,
    "id": 1840010856
  },
  {
    "city": "Ilava",
    "city_ascii": "Ilava",
    "lat": 48.9994,
    "lng": 18.2361,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Nitriansky",
    "capital": "minor",
    "population": 5485,
    "id": 1703643289
  },
  {
    "city": "Primorsk",
    "city_ascii": "Primorsk",
    "lat": 60.3667,
    "lng": 28.6167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Leningradskaya Oblast’",
    "capital": "",
    "population": 5682,
    "id": 1643507174
  },
  {
    "city": "Front of Yonge",
    "city_ascii": "Front of Yonge",
    "lat": 44.5333,
    "lng": -75.8667,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 5710,
    "id": 1124001901
  },
  {
    "city": "Dripping Springs",
    "city_ascii": "Dripping Springs",
    "lat": 30.1954,
    "lng": -98.094,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 5708,
    "id": 1840019606
  },
  {
    "city": "Penn Estates",
    "city_ascii": "Penn Estates",
    "lat": 41.0346,
    "lng": -75.2417,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5708,
    "id": 1840035454
  },
  {
    "city": "Vegreville",
    "city_ascii": "Vegreville",
    "lat": 53.4928,
    "lng": -112.0522,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Alberta",
    "capital": "",
    "population": 5708,
    "id": 1124534321
  },
  {
    "city": "Wareham",
    "city_ascii": "Wareham",
    "lat": 50.686,
    "lng": -2.1099,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Dorset",
    "capital": "",
    "population": 5496,
    "id": 1826933039
  },
  {
    "city": "Grafton",
    "city_ascii": "Grafton",
    "lat": 41.2808,
    "lng": -82.0367,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 5707,
    "id": 1840011590
  },
  {
    "city": "Douglass Hills",
    "city_ascii": "Douglass Hills",
    "lat": 38.2366,
    "lng": -85.5499,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kentucky",
    "capital": "",
    "population": 5707,
    "id": 1840014294
  },
  {
    "city": "Spring Grove",
    "city_ascii": "Spring Grove",
    "lat": 42.4543,
    "lng": -88.2402,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 5706,
    "id": 1840011143
  },
  {
    "city": "Rumford",
    "city_ascii": "Rumford",
    "lat": 44.5347,
    "lng": -70.6154,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maine",
    "capital": "",
    "population": 5705,
    "id": 1840052983
  },
  {
    "city": "Hirschau",
    "city_ascii": "Hirschau",
    "lat": 49.5333,
    "lng": 11.95,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 5629,
    "id": 1276114870
  },
  {
    "city": "Longswamp",
    "city_ascii": "Longswamp",
    "lat": 40.4907,
    "lng": -75.6601,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5703,
    "id": 1840100169
  },
  {
    "city": "Anadarko",
    "city_ascii": "Anadarko",
    "lat": 35.0652,
    "lng": -98.2441,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oklahoma",
    "capital": "",
    "population": 5703,
    "id": 1840019159
  },
  {
    "city": "Nazareth",
    "city_ascii": "Nazareth",
    "lat": 40.74,
    "lng": -75.3132,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5702,
    "id": 1840000974
  },
  {
    "city": "Chickasaw",
    "city_ascii": "Chickasaw",
    "lat": 30.7714,
    "lng": -88.0793,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 5702,
    "id": 1840014986
  },
  {
    "city": "North Fort Lewis",
    "city_ascii": "North Fort Lewis",
    "lat": 47.122,
    "lng": -122.5966,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 5701,
    "id": 1840042007
  },
  {
    "city": "Demopolis",
    "city_ascii": "Demopolis",
    "lat": 32.498,
    "lng": -87.8298,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 5701,
    "id": 1840014897
  },
  {
    "city": "Harvest",
    "city_ascii": "Harvest",
    "lat": 34.8558,
    "lng": -86.7521,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 5700,
    "id": 1840013544
  },
  {
    "city": "Munderkingen",
    "city_ascii": "Munderkingen",
    "lat": 48.2353,
    "lng": 9.6439,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 5292,
    "id": 1276002966
  },
  {
    "city": "Santa Teresa",
    "city_ascii": "Santa Teresa",
    "lat": 31.8701,
    "lng": -106.6714,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Mexico",
    "capital": "",
    "population": 5696,
    "id": 1840019414
  },
  {
    "city": "Sainte-Brigitte-de-Laval",
    "city_ascii": "Sainte-Brigitte-de-Laval",
    "lat": 47,
    "lng": -71.2,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 5696,
    "id": 1124647754
  },
  {
    "city": "Großschönau",
    "city_ascii": "Grossschonau",
    "lat": 50.8964,
    "lng": 14.6622,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 5465,
    "id": 1276000576
  },
  {
    "city": "Kosmonosy",
    "city_ascii": "Kosmonosy",
    "lat": 50.4386,
    "lng": 14.9301,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Středočeský Kraj",
    "capital": "",
    "population": 5233,
    "id": 1203691834
  },
  {
    "city": "Penamacor",
    "city_ascii": "Penamacor",
    "lat": 40.1667,
    "lng": -7.1667,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Castelo Branco",
    "capital": "minor",
    "population": 5682,
    "id": 1620921747
  },
  {
    "city": "Babək",
    "city_ascii": "Babak",
    "lat": 39.1111,
    "lng": 45.4114,
    "country": "Azerbaijan",
    "iso2": "AZ",
    "iso3": "AZE",
    "admin_name": "Babək",
    "capital": "minor",
    "population": 5600,
    "id": 1031598031
  },
  {
    "city": "Verchères",
    "city_ascii": "Vercheres",
    "lat": 45.7833,
    "lng": -73.35,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 5692,
    "id": 1124549666
  },
  {
    "city": "Princeton",
    "city_ascii": "Princeton",
    "lat": 37.1068,
    "lng": -87.8854,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kentucky",
    "capital": "",
    "population": 5691,
    "id": 1840014382
  },
  {
    "city": "Bobov Dol",
    "city_ascii": "Bobov Dol",
    "lat": 42.3661,
    "lng": 23.0053,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Kyustendil",
    "capital": "minor",
    "population": 5651,
    "id": 1100718546
  },
  {
    "city": "The Pas",
    "city_ascii": "The Pas",
    "lat": 53.825,
    "lng": -101.2533,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Manitoba",
    "capital": "",
    "population": 5689,
    "id": 1124755168
  },
  {
    "city": "Duffield",
    "city_ascii": "Duffield",
    "lat": 52.989,
    "lng": -1.489,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Derbyshire",
    "capital": "",
    "population": 5046,
    "id": 1826782897
  },
  {
    "city": "Wrightsville",
    "city_ascii": "Wrightsville",
    "lat": 32.7265,
    "lng": -82.7197,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 5688,
    "id": 1840015789
  },
  {
    "city": "Saint-Césaire",
    "city_ascii": "Saint-Cesaire",
    "lat": 45.4167,
    "lng": -73,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 5686,
    "id": 1124948389
  },
  {
    "city": "Adams Township",
    "city_ascii": "Adams Township",
    "lat": 40.2876,
    "lng": -78.7452,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5685,
    "id": 1840145840
  },
  {
    "city": "Kuzumaki",
    "city_ascii": "Kuzumaki",
    "lat": 40.0397,
    "lng": 141.4364,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Iwate",
    "capital": "",
    "population": 5671,
    "id": 1392191294
  },
  {
    "city": "James City",
    "city_ascii": "James City",
    "lat": 35.0592,
    "lng": -77.02,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 5684,
    "id": 1840013471
  },
  {
    "city": "Ruhla",
    "city_ascii": "Ruhla",
    "lat": 50.8919,
    "lng": 10.3667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Thuringia",
    "capital": "",
    "population": 5540,
    "id": 1276053290
  },
  {
    "city": "Forestbrook",
    "city_ascii": "Forestbrook",
    "lat": 33.7243,
    "lng": -78.9678,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 5682,
    "id": 1840035797
  },
  {
    "city": "Leutershausen",
    "city_ascii": "Leutershausen",
    "lat": 49.2919,
    "lng": 10.4162,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 5615,
    "id": 1276210588
  },
  {
    "city": "Glenbrook",
    "city_ascii": "Glenbrook",
    "lat": -33.765,
    "lng": 150.6267,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 5051,
    "id": 1036560336
  },
  {
    "city": "Amagi",
    "city_ascii": "Amagi",
    "lat": 27.8092,
    "lng": 128.8978,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Kagoshima",
    "capital": "",
    "population": 5608,
    "id": 1392316482
  },
  {
    "city": "Prüm",
    "city_ascii": "Prum",
    "lat": 50.2081,
    "lng": 6.4244,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Rhineland-Palatinate",
    "capital": "",
    "population": 5438,
    "id": 1276214721
  },
  {
    "city": "Grangetown",
    "city_ascii": "Grangetown",
    "lat": 54.58,
    "lng": -1.144,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Redcar and Cleveland",
    "capital": "",
    "population": 5088,
    "id": 1826320300
  },
  {
    "city": "Topolovgrad",
    "city_ascii": "Topolovgrad",
    "lat": 42.0842,
    "lng": 26.3375,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Haskovo",
    "capital": "",
    "population": 5659,
    "id": 1100686772
  },
  {
    "city": "Princeton",
    "city_ascii": "Princeton",
    "lat": 37.3689,
    "lng": -81.0961,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "West Virginia",
    "capital": "",
    "population": 5675,
    "id": 1840006402
  },
  {
    "city": "Gavrilov Posad",
    "city_ascii": "Gavrilov Posad",
    "lat": 56.5667,
    "lng": 40.1167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Ivanovskaya Oblast’",
    "capital": "",
    "population": 5657,
    "id": 1643905569
  },
  {
    "city": "Pittstown",
    "city_ascii": "Pittstown",
    "lat": 42.8647,
    "lng": -73.5051,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 5674,
    "id": 1840058406
  },
  {
    "city": "Hollidaysburg",
    "city_ascii": "Hollidaysburg",
    "lat": 40.4311,
    "lng": -78.393,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5674,
    "id": 1840001083
  },
  {
    "city": "Fulnek",
    "city_ascii": "Fulnek",
    "lat": 49.7124,
    "lng": 17.9032,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Moravskoslezský Kraj",
    "capital": "",
    "population": 5592,
    "id": 1203132715
  },
  {
    "city": "Františkovy Lázně",
    "city_ascii": "Frantiskovy Lazne",
    "lat": 50.1205,
    "lng": 12.3518,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Karlovarský Kraj",
    "capital": "",
    "population": 5461,
    "id": 1203641518
  },
  {
    "city": "Manz̧arīyeh",
    "city_ascii": "Manzariyeh",
    "lat": 31.9458,
    "lng": 51.8719,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Eşfahān",
    "capital": "",
    "population": 5617,
    "id": 1364699308
  },
  {
    "city": "North Manchester",
    "city_ascii": "North Manchester",
    "lat": 41.0044,
    "lng": -85.775,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 5671,
    "id": 1840009360
  },
  {
    "city": "La Ronge",
    "city_ascii": "La Ronge",
    "lat": 55.1,
    "lng": -105.3,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Saskatchewan",
    "capital": "",
    "population": 5671,
    "id": 1124763455
  },
  {
    "city": "Narjeh",
    "city_ascii": "Narjeh",
    "lat": 35.9908,
    "lng": 49.6247,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Qazvīn",
    "capital": "",
    "population": 5604,
    "id": 1364043591
  },
  {
    "city": "Hopkinton",
    "city_ascii": "Hopkinton",
    "lat": 43.1979,
    "lng": -71.6968,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Hampshire",
    "capital": "",
    "population": 5670,
    "id": 1840055031
  },
  {
    "city": "Pecan Acres",
    "city_ascii": "Pecan Acres",
    "lat": 32.9703,
    "lng": -97.4727,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 5670,
    "id": 1840019420
  },
  {
    "city": "Paxtonia",
    "city_ascii": "Paxtonia",
    "lat": 40.3166,
    "lng": -76.7884,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5670,
    "id": 1840005399
  },
  {
    "city": "Kelso",
    "city_ascii": "Kelso",
    "lat": 55.5985,
    "lng": -2.4336,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Scottish Borders, The",
    "capital": "",
    "population": 5639,
    "id": 1826201607
  },
  {
    "city": "Stolpen",
    "city_ascii": "Stolpen",
    "lat": 51.0489,
    "lng": 14.0828,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 5576,
    "id": 1276721274
  },
  {
    "city": "Khobi",
    "city_ascii": "Khobi",
    "lat": 42.3167,
    "lng": 41.9,
    "country": "Georgia",
    "iso2": "GE",
    "iso3": "GEO",
    "admin_name": "Samegrelo-Zemo Svaneti",
    "capital": "minor",
    "population": 5600,
    "id": 1268453710
  },
  {
    "city": "Siesta Key",
    "city_ascii": "Siesta Key",
    "lat": 27.2779,
    "lng": -82.5516,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 5667,
    "id": 1840028968
  },
  {
    "city": "Zermatt",
    "city_ascii": "Zermatt",
    "lat": 46.0193,
    "lng": 7.7461,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Valais",
    "capital": "",
    "population": 5643,
    "id": 1756628768
  },
  {
    "city": "Philomath",
    "city_ascii": "Philomath",
    "lat": 44.5422,
    "lng": -123.3599,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 5666,
    "id": 1840019998
  },
  {
    "city": "Homestead Meadows North",
    "city_ascii": "Homestead Meadows North",
    "lat": 31.8483,
    "lng": -106.1707,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 5665,
    "id": 1840034867
  },
  {
    "city": "Tay Valley",
    "city_ascii": "Tay Valley",
    "lat": 44.8667,
    "lng": -76.3833,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 5665,
    "id": 1124000734
  },
  {
    "city": "Mathis",
    "city_ascii": "Mathis",
    "lat": 28.0909,
    "lng": -97.817,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 5662,
    "id": 1840021004
  },
  {
    "city": "Vadul lui Vodă",
    "city_ascii": "Vadul lui Voda",
    "lat": 47.0917,
    "lng": 29.0756,
    "country": "Moldova",
    "iso2": "MD",
    "iso3": "MDA",
    "admin_name": "Chişinău",
    "capital": "",
    "population": 5295,
    "id": 1498130040
  },
  {
    "city": "Myshkin",
    "city_ascii": "Myshkin",
    "lat": 57.7833,
    "lng": 38.45,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Yaroslavskaya Oblast’",
    "capital": "",
    "population": 5647,
    "id": 1643384603
  },
  {
    "city": "Wailea",
    "city_ascii": "Wailea",
    "lat": 20.6873,
    "lng": -156.4291,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Hawaii",
    "capital": "",
    "population": 5656,
    "id": 1840025210
  },
  {
    "city": "Miercurea Nirajului",
    "city_ascii": "Miercurea Nirajului",
    "lat": 46.53,
    "lng": 24.8,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Mureş",
    "capital": "",
    "population": 5554,
    "id": 1642320938
  },
  {
    "city": "Wilmington",
    "city_ascii": "Wilmington",
    "lat": 41.3204,
    "lng": -88.164,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 5653,
    "id": 1840010213
  },
  {
    "city": "Gardiner",
    "city_ascii": "Gardiner",
    "lat": 44.191,
    "lng": -69.7921,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maine",
    "capital": "",
    "population": 5653,
    "id": 1840000303
  },
  {
    "city": "Presque Isle",
    "city_ascii": "Presque Isle",
    "lat": 46.6868,
    "lng": -67.9874,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maine",
    "capital": "",
    "population": 5651,
    "id": 1840000146
  },
  {
    "city": "Raton",
    "city_ascii": "Raton",
    "lat": 36.8849,
    "lng": -104.4396,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Mexico",
    "capital": "",
    "population": 5650,
    "id": 1840020353
  },
  {
    "city": "Bright",
    "city_ascii": "Bright",
    "lat": 39.2254,
    "lng": -84.8613,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 5649,
    "id": 1840005882
  },
  {
    "city": "Haag",
    "city_ascii": "Haag",
    "lat": 48.1122,
    "lng": 14.5656,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Niederösterreich",
    "capital": "",
    "population": 5548,
    "id": 1040598593
  },
  {
    "city": "Domburg",
    "city_ascii": "Domburg",
    "lat": 5.7,
    "lng": -55.0833,
    "country": "Suriname",
    "iso2": "SR",
    "iso3": "SUR",
    "admin_name": "Wanica",
    "capital": "",
    "population": 5500,
    "id": 1740780058
  },
  {
    "city": "Edna",
    "city_ascii": "Edna",
    "lat": 28.9757,
    "lng": -96.6483,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 5648,
    "id": 1840020984
  },
  {
    "city": "Taiuva",
    "city_ascii": "Taiuva",
    "lat": -21.1239,
    "lng": -48.4519,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 5605,
    "id": 1076764363
  },
  {
    "city": "Starke",
    "city_ascii": "Starke",
    "lat": 29.9474,
    "lng": -82.1129,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 5647,
    "id": 1840015940
  },
  {
    "city": "Dandridge",
    "city_ascii": "Dandridge",
    "lat": 36.0285,
    "lng": -83.4308,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 5647,
    "id": 1840016168
  },
  {
    "city": "Yosemite Lakes",
    "city_ascii": "Yosemite Lakes",
    "lat": 37.1885,
    "lng": -119.7723,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 5647,
    "id": 1840018920
  },
  {
    "city": "Gadebusch",
    "city_ascii": "Gadebusch",
    "lat": 53.7,
    "lng": 11.1167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Mecklenburg-Western Pomerania",
    "capital": "",
    "population": 5530,
    "id": 1276489588
  },
  {
    "city": "Corydon",
    "city_ascii": "Corydon",
    "lat": 38.213,
    "lng": -86.1257,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 5645,
    "id": 1840009820
  },
  {
    "city": "Chonchi",
    "city_ascii": "Chonchi",
    "lat": -42.6238,
    "lng": -73.7725,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Los Lagos",
    "capital": "",
    "population": 5632,
    "id": 1152428145
  },
  {
    "city": "Champlain",
    "city_ascii": "Champlain",
    "lat": 44.9591,
    "lng": -73.4324,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 5643,
    "id": 1840004044
  },
  {
    "city": "Pontoon Beach",
    "city_ascii": "Pontoon Beach",
    "lat": 38.7208,
    "lng": -90.0609,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 5641,
    "id": 1840012797
  },
  {
    "city": "South Bruce",
    "city_ascii": "South Bruce",
    "lat": 44.0333,
    "lng": -81.2,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 5639,
    "id": 1124001457
  },
  {
    "city": "Kirkwood",
    "city_ascii": "Kirkwood",
    "lat": 42.0889,
    "lng": -75.8033,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 5638,
    "id": 1840058219
  },
  {
    "city": "Unkel",
    "city_ascii": "Unkel",
    "lat": 50.6008,
    "lng": 7.215,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Rhineland-Palatinate",
    "capital": "",
    "population": 5021,
    "id": 1276893554
  },
  {
    "city": "Gardiner",
    "city_ascii": "Gardiner",
    "lat": 41.6949,
    "lng": -74.1869,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 5636,
    "id": 1840058118
  },
  {
    "city": "Wright",
    "city_ascii": "Wright",
    "lat": 41.1211,
    "lng": -75.9046,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5633,
    "id": 1840146475
  },
  {
    "city": "Hradec nad Moravici",
    "city_ascii": "Hradec nad Moravici",
    "lat": 49.871,
    "lng": 17.8759,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Moravskoslezský Kraj",
    "capital": "",
    "population": 5506,
    "id": 1203039569
  },
  {
    "city": "Waihi",
    "city_ascii": "Waihi",
    "lat": -37.393,
    "lng": 175.832,
    "country": "New Zealand",
    "iso2": "NZ",
    "iso3": "NZL",
    "admin_name": "Waikato",
    "capital": "",
    "population": 5310,
    "id": 1554925569
  },
  {
    "city": "Elstree",
    "city_ascii": "Elstree",
    "lat": 51.64,
    "lng": -0.3,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hertfordshire",
    "capital": "",
    "population": 5110,
    "id": 1826109964
  },
  {
    "city": "Taquarivaí",
    "city_ascii": "Taquarivai",
    "lat": -23.9239,
    "lng": -48.6928,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 5605,
    "id": 1076166604
  },
  {
    "city": "Alva",
    "city_ascii": "Alva",
    "lat": 36.7892,
    "lng": -98.6648,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oklahoma",
    "capital": "",
    "population": 5628,
    "id": 1840018976
  },
  {
    "city": "Hotton",
    "city_ascii": "Hotton",
    "lat": 50.2683,
    "lng": 5.4467,
    "country": "Belgium",
    "iso2": "BE",
    "iso3": "BEL",
    "admin_name": "Wallonia",
    "capital": "",
    "population": 5531,
    "id": 1056288696
  },
  {
    "city": "Milton",
    "city_ascii": "Milton",
    "lat": 42.7751,
    "lng": -88.943,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 5627,
    "id": 1840002473
  },
  {
    "city": "Onrus",
    "city_ascii": "Onrus",
    "lat": -34.4122,
    "lng": 19.17,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "Western Cape",
    "capital": "",
    "population": 5151,
    "id": 1710903983
  },
  {
    "city": "Spielberg bei Knittelfeld",
    "city_ascii": "Spielberg bei Knittelfeld",
    "lat": 47.2167,
    "lng": 14.7833,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Steiermark",
    "capital": "",
    "population": 5383,
    "id": 1040725914
  },
  {
    "city": "Abenberg",
    "city_ascii": "Abenberg",
    "lat": 49.2406,
    "lng": 10.9619,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 5511,
    "id": 1276058642
  },
  {
    "city": "Mellrichstadt",
    "city_ascii": "Mellrichstadt",
    "lat": 50.4278,
    "lng": 10.3027,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 5525,
    "id": 1276917684
  },
  {
    "city": "Ellrich",
    "city_ascii": "Ellrich",
    "lat": 51.5856,
    "lng": 10.6681,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Thuringia",
    "capital": "",
    "population": 5543,
    "id": 1276608449
  },
  {
    "city": "Willow Springs",
    "city_ascii": "Willow Springs",
    "lat": 41.7332,
    "lng": -87.8859,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 5621,
    "id": 1840011315
  },
  {
    "city": "Groveport",
    "city_ascii": "Groveport",
    "lat": 39.8585,
    "lng": -82.8978,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 5621,
    "id": 1840012401
  },
  {
    "city": "Waidhofen an der Thaya",
    "city_ascii": "Waidhofen an der Thaya",
    "lat": 48.8167,
    "lng": 15.2833,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Niederösterreich",
    "capital": "minor",
    "population": 5501,
    "id": 1040085999
  },
  {
    "city": "Cidra",
    "city_ascii": "Cidra",
    "lat": 18.1775,
    "lng": -66.1582,
    "country": "Puerto Rico",
    "iso2": "PR",
    "iso3": "PRI",
    "admin_name": "Puerto Rico",
    "capital": "",
    "population": 5620,
    "id": 1630035647
  },
  {
    "city": "Blauvelt",
    "city_ascii": "Blauvelt",
    "lat": 41.0689,
    "lng": -73.9545,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 5620,
    "id": 1840004959
  },
  {
    "city": "Highland",
    "city_ascii": "Highland",
    "lat": 41.7179,
    "lng": -73.9646,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 5620,
    "id": 1840004716
  },
  {
    "city": "Findlay",
    "city_ascii": "Findlay",
    "lat": 40.4782,
    "lng": -80.2811,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5619,
    "id": 1840147157
  },
  {
    "city": "Chudniv",
    "city_ascii": "Chudniv",
    "lat": 50.0528,
    "lng": 28.0969,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Zhytomyrs’ka Oblast’",
    "capital": "minor",
    "population": 5583,
    "id": 1804716219
  },
  {
    "city": "Strazhitsa",
    "city_ascii": "Strazhitsa",
    "lat": 43.2333,
    "lng": 25.9667,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Veliko Tarnovo",
    "capital": "",
    "population": 5589,
    "id": 1100500516
  },
  {
    "city": "New Boston",
    "city_ascii": "New Boston",
    "lat": 42.9774,
    "lng": -71.686,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Hampshire",
    "capital": "",
    "population": 5616,
    "id": 1840055220
  },
  {
    "city": "Richland",
    "city_ascii": "Richland",
    "lat": 43.5468,
    "lng": -76.1381,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 5616,
    "id": 1840058441
  },
  {
    "city": "Izsák",
    "city_ascii": "Izsak",
    "lat": 46.8014,
    "lng": 19.3587,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Bács-Kiskun",
    "capital": "",
    "population": 5567,
    "id": 1348579870
  },
  {
    "city": "Neilston",
    "city_ascii": "Neilston",
    "lat": 55.7847,
    "lng": -4.4234,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "East Renfrewshire",
    "capital": "",
    "population": 5320,
    "id": 1826325436
  },
  {
    "city": "Riga",
    "city_ascii": "Riga",
    "lat": 43.0802,
    "lng": -77.8753,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 5615,
    "id": 1840058443
  },
  {
    "city": "McMasterville",
    "city_ascii": "McMasterville",
    "lat": 45.55,
    "lng": -73.2333,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 5615,
    "id": 1124000115
  },
  {
    "city": "Veľký Šariš",
    "city_ascii": "Vel'ky Saris",
    "lat": 49.0383,
    "lng": 21.1917,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Prešovský",
    "capital": "",
    "population": 5404,
    "id": 1703876931
  },
  {
    "city": "Ceiba",
    "city_ascii": "Ceiba",
    "lat": 18.265,
    "lng": -65.6488,
    "country": "Puerto Rico",
    "iso2": "PR",
    "iso3": "PRI",
    "admin_name": "Puerto Rico",
    "capital": "",
    "population": 5613,
    "id": 1630035643
  },
  {
    "city": "Copşa Mică",
    "city_ascii": "Copsa Mica",
    "lat": 46.1125,
    "lng": 24.2306,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Sibiu",
    "capital": "",
    "population": 5404,
    "id": 1642224148
  },
  {
    "city": "Schöppenstedt",
    "city_ascii": "Schoppenstedt",
    "lat": 52.1331,
    "lng": 10.7783,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 5474,
    "id": 1276386653
  },
  {
    "city": "Avarua",
    "city_ascii": "Avarua",
    "lat": -21.207,
    "lng": -159.771,
    "country": "Cook Islands",
    "iso2": "CK",
    "iso3": "COK",
    "admin_name": "",
    "capital": "",
    "population": 5445,
    "id": 1184217570
  },
  {
    "city": "Tellico Village",
    "city_ascii": "Tellico Village",
    "lat": 35.6977,
    "lng": -84.2661,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 5612,
    "id": 1840040591
  },
  {
    "city": "Harris",
    "city_ascii": "Harris",
    "lat": 40.7633,
    "lng": -77.7674,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5612,
    "id": 1840144817
  },
  {
    "city": "Jonesborough",
    "city_ascii": "Jonesborough",
    "lat": 36.2959,
    "lng": -82.4766,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 5611,
    "id": 1840016092
  },
  {
    "city": "Salton City",
    "city_ascii": "Salton City",
    "lat": 33.2994,
    "lng": -115.9609,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 5611,
    "id": 1840019374
  },
  {
    "city": "Harburg",
    "city_ascii": "Harburg",
    "lat": 48.7667,
    "lng": 10.6667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 5535,
    "id": 1276235429
  },
  {
    "city": "Mount Plymouth",
    "city_ascii": "Mount Plymouth",
    "lat": 28.8024,
    "lng": -81.535,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 5610,
    "id": 1840014049
  },
  {
    "city": "Anna",
    "city_ascii": "Anna",
    "lat": 37.4612,
    "lng": -89.2388,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 5610,
    "id": 1840007602
  },
  {
    "city": "Kingstree",
    "city_ascii": "Kingstree",
    "lat": 33.6665,
    "lng": -79.8292,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 5610,
    "id": 1840016790
  },
  {
    "city": "Lake Delton",
    "city_ascii": "Lake Delton",
    "lat": 43.5932,
    "lng": -89.7842,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 5609,
    "id": 1840002718
  },
  {
    "city": "Dayton",
    "city_ascii": "Dayton",
    "lat": 39.1127,
    "lng": -84.464,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kentucky",
    "capital": "",
    "population": 5609,
    "id": 1840014259
  },
  {
    "city": "Cottleville",
    "city_ascii": "Cottleville",
    "lat": 38.7513,
    "lng": -90.6582,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 5608,
    "id": 1840007427
  },
  {
    "city": "Bowling Green",
    "city_ascii": "Bowling Green",
    "lat": 39.3447,
    "lng": -91.2032,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 5608,
    "id": 1840007344
  },
  {
    "city": "Blaine",
    "city_ascii": "Blaine",
    "lat": 48.9839,
    "lng": -122.7414,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 5607,
    "id": 1840018347
  },
  {
    "city": "Kemnath",
    "city_ascii": "Kemnath",
    "lat": 49.8667,
    "lng": 11.8833,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 5508,
    "id": 1276456872
  },
  {
    "city": "Mansfield",
    "city_ascii": "Mansfield",
    "lat": 32.0355,
    "lng": -93.7004,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 5604,
    "id": 1840015821
  },
  {
    "city": "Hartford City",
    "city_ascii": "Hartford City",
    "lat": 40.4537,
    "lng": -85.3736,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 5604,
    "id": 1840007231
  },
  {
    "city": "West Pennsboro",
    "city_ascii": "West Pennsboro",
    "lat": 40.1798,
    "lng": -77.3335,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5603,
    "id": 1840152630
  },
  {
    "city": "Rancho Murieta",
    "city_ascii": "Rancho Murieta",
    "lat": 38.5085,
    "lng": -121.0716,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 5603,
    "id": 1840028329
  },
  {
    "city": "Huntingburg",
    "city_ascii": "Huntingburg",
    "lat": 38.301,
    "lng": -86.9622,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 5603,
    "id": 1840007496
  },
  {
    "city": "Redcliff",
    "city_ascii": "Redcliff",
    "lat": 50.0792,
    "lng": -110.7783,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Alberta",
    "capital": "",
    "population": 5600,
    "id": 1124603057
  },
  {
    "city": "Barra do Chapéu",
    "city_ascii": "Barra do Chapeu",
    "lat": -24.4731,
    "lng": -49.0244,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 5585,
    "id": 1076607293
  },
  {
    "city": "Smithfield Heights",
    "city_ascii": "Smithfield Heights",
    "lat": -16.8314,
    "lng": 145.691,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Queensland",
    "capital": "",
    "population": 5303,
    "id": 1036797461
  },
  {
    "city": "Paradise Township",
    "city_ascii": "Paradise Township",
    "lat": 39.9859,
    "lng": -76.1066,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5598,
    "id": 1840144047
  },
  {
    "city": "Ingelfingen",
    "city_ascii": "Ingelfingen",
    "lat": 49.3,
    "lng": 9.65,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 5480,
    "id": 1276752846
  },
  {
    "city": "Dove Valley",
    "city_ascii": "Dove Valley",
    "lat": 39.5741,
    "lng": -104.8289,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 5597,
    "id": 1840028589
  },
  {
    "city": "Boothwyn",
    "city_ascii": "Boothwyn",
    "lat": 39.8357,
    "lng": -75.4453,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5596,
    "id": 1840005548
  },
  {
    "city": "Linton",
    "city_ascii": "Linton",
    "lat": 39.0356,
    "lng": -87.1586,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 5596,
    "id": 1840009692
  },
  {
    "city": "Alambari",
    "city_ascii": "Alambari",
    "lat": -23.5667,
    "lng": -47.8861,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 5560,
    "id": 1076631769
  },
  {
    "city": "Korishë",
    "city_ascii": "Korishe",
    "lat": 42.2576,
    "lng": 20.7981,
    "country": "Kosovo",
    "iso2": "XK",
    "iso3": "XKS",
    "admin_name": "Prizren",
    "capital": "",
    "population": 5279,
    "id": 1901393938
  },
  {
    "city": "Semmes",
    "city_ascii": "Semmes",
    "lat": 30.7941,
    "lng": -88.2358,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 5594,
    "id": 1840027491
  },
  {
    "city": "Gmünd",
    "city_ascii": "Gmund",
    "lat": 48.7667,
    "lng": 14.9833,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Niederösterreich",
    "capital": "minor",
    "population": 5375,
    "id": 1040980800
  },
  {
    "city": "Honesdale",
    "city_ascii": "Honesdale",
    "lat": 41.5774,
    "lng": -75.2524,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5589,
    "id": 1840003314
  },
  {
    "city": "South Huntingdon",
    "city_ascii": "South Huntingdon",
    "lat": 40.1702,
    "lng": -79.7001,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5589,
    "id": 1840144812
  },
  {
    "city": "Springfield Township",
    "city_ascii": "Springfield Township",
    "lat": 39.8466,
    "lng": -76.7112,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5586,
    "id": 1840147113
  },
  {
    "city": "Lipcani",
    "city_ascii": "Lipcani",
    "lat": 48.2653,
    "lng": 26.8039,
    "country": "Moldova",
    "iso2": "MD",
    "iso3": "MDA",
    "admin_name": "Briceni",
    "capital": "",
    "population": 5500,
    "id": 1498497733
  },
  {
    "city": "Pedro Vicente Maldonado",
    "city_ascii": "Pedro Vicente Maldonado",
    "lat": 0.0853,
    "lng": -79.0511,
    "country": "Ecuador",
    "iso2": "EC",
    "iso3": "ECU",
    "admin_name": "Pichincha",
    "capital": "",
    "population": 5561,
    "id": 1218943732
  },
  {
    "city": "Upper Pottsgrove",
    "city_ascii": "Upper Pottsgrove",
    "lat": 40.2824,
    "lng": -75.6362,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5584,
    "id": 1840145448
  },
  {
    "city": "Poysdorf",
    "city_ascii": "Poysdorf",
    "lat": 48.6667,
    "lng": 16.6333,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Niederösterreich",
    "capital": "",
    "population": 5527,
    "id": 1040117659
  },
  {
    "city": "Byron",
    "city_ascii": "Byron",
    "lat": 44.0379,
    "lng": -92.6411,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 5582,
    "id": 1840006809
  },
  {
    "city": "Dargaville",
    "city_ascii": "Dargaville",
    "lat": -35.9333,
    "lng": 173.8833,
    "country": "New Zealand",
    "iso2": "NZ",
    "iso3": "NZL",
    "admin_name": "Northland",
    "capital": "",
    "population": 5170,
    "id": 1554230037
  },
  {
    "city": "Oxford",
    "city_ascii": "Oxford",
    "lat": 39.7858,
    "lng": -75.9801,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5581,
    "id": 1840001431
  },
  {
    "city": "Bessemer City",
    "city_ascii": "Bessemer City",
    "lat": 35.2841,
    "lng": -81.283,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 5577,
    "id": 1840013470
  },
  {
    "city": "Seminole",
    "city_ascii": "Seminole",
    "lat": 35.2346,
    "lng": -96.65,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oklahoma",
    "capital": "",
    "population": 5577,
    "id": 1840021776
  },
  {
    "city": "Drebkau",
    "city_ascii": "Drebkau",
    "lat": 51.65,
    "lng": 14.2167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Brandenburg",
    "capital": "",
    "population": 5538,
    "id": 1276897745
  },
  {
    "city": "Harrietstown",
    "city_ascii": "Harrietstown",
    "lat": 44.243,
    "lng": -74.2363,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 5576,
    "id": 1840058167
  },
  {
    "city": "Chackbay",
    "city_ascii": "Chackbay",
    "lat": 29.8817,
    "lng": -90.7742,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 5573,
    "id": 1840014024
  },
  {
    "city": "New Carlisle",
    "city_ascii": "New Carlisle",
    "lat": 39.9446,
    "lng": -84.0257,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 5568,
    "id": 1840008401
  },
  {
    "city": "Slinger",
    "city_ascii": "Slinger",
    "lat": 43.3318,
    "lng": -88.2799,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 5565,
    "id": 1840002828
  },
  {
    "city": "Mali",
    "city_ascii": "Mali",
    "lat": 12.084,
    "lng": -12.301,
    "country": "Guinea",
    "iso2": "GN",
    "iso3": "GIN",
    "admin_name": "Labé",
    "capital": "minor",
    "population": 5479,
    "id": 1324065197
  },
  {
    "city": "Cochem",
    "city_ascii": "Cochem",
    "lat": 50.1469,
    "lng": 7.1667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Rhineland-Palatinate",
    "capital": "minor",
    "population": 5312,
    "id": 1276548255
  },
  {
    "city": "Waterford",
    "city_ascii": "Waterford",
    "lat": 42.7646,
    "lng": -88.216,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 5562,
    "id": 1840002488
  },
  {
    "city": "Lake Bluff",
    "city_ascii": "Lake Bluff",
    "lat": 42.2826,
    "lng": -87.851,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 5562,
    "id": 1840011158
  },
  {
    "city": "Caiuá",
    "city_ascii": "Caiua",
    "lat": -21.8317,
    "lng": -51.9983,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 5560,
    "id": 1076796531
  },
  {
    "city": "Eureka",
    "city_ascii": "Eureka",
    "lat": 40.7148,
    "lng": -89.2775,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 5560,
    "id": 1840008294
  },
  {
    "city": "Frankenmuth",
    "city_ascii": "Frankenmuth",
    "lat": 43.3321,
    "lng": -83.7395,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 5560,
    "id": 1840002814
  },
  {
    "city": "Fort Washington",
    "city_ascii": "Fort Washington",
    "lat": 40.1407,
    "lng": -75.1925,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5559,
    "id": 1840005460
  },
  {
    "city": "Stroudsburg",
    "city_ascii": "Stroudsburg",
    "lat": 40.9838,
    "lng": -75.1972,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5558,
    "id": 1840003509
  },
  {
    "city": "Wayne",
    "city_ascii": "Wayne",
    "lat": 42.2379,
    "lng": -97.01,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Nebraska",
    "capital": "",
    "population": 5557,
    "id": 1840010121
  },
  {
    "city": "Duquesne",
    "city_ascii": "Duquesne",
    "lat": 40.3732,
    "lng": -79.8502,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5557,
    "id": 1840001224
  },
  {
    "city": "Otterberg",
    "city_ascii": "Otterberg",
    "lat": 49.5044,
    "lng": 7.7711,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Rhineland-Palatinate",
    "capital": "",
    "population": 5389,
    "id": 1276571122
  },
  {
    "city": "San Nicolás Tolentino",
    "city_ascii": "San Nicolas Tolentino",
    "lat": 22.2489,
    "lng": -100.5525,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "San Luis Potosí",
    "capital": "minor",
    "population": 5547,
    "id": 1484584889
  },
  {
    "city": "Nashville",
    "city_ascii": "Nashville",
    "lat": 35.9692,
    "lng": -77.9555,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 5554,
    "id": 1840016167
  },
  {
    "city": "Leadville",
    "city_ascii": "Leadville",
    "lat": 39.2473,
    "lng": -106.2934,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 5554,
    "id": 1840020228
  },
  {
    "city": "Battonya",
    "city_ascii": "Battonya",
    "lat": 46.2833,
    "lng": 21.0167,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Békés",
    "capital": "",
    "population": 5516,
    "id": 1348698214
  },
  {
    "city": "Stochov",
    "city_ascii": "Stochov",
    "lat": 50.1464,
    "lng": 13.9635,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Středočeský Kraj",
    "capital": "",
    "population": 5392,
    "id": 1203496529
  },
  {
    "city": "Seelow",
    "city_ascii": "Seelow",
    "lat": 52.5167,
    "lng": 14.3831,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Brandenburg",
    "capital": "minor",
    "population": 5426,
    "id": 1276427518
  },
  {
    "city": "Sweetwater",
    "city_ascii": "Sweetwater",
    "lat": 35.6029,
    "lng": -84.4717,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 5553,
    "id": 1840015415
  },
  {
    "city": "Vila do Porto",
    "city_ascii": "Vila do Porto",
    "lat": 36.945,
    "lng": -25.145,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Azores",
    "capital": "",
    "population": 5552,
    "id": 1620528680
  },
  {
    "city": "Poland",
    "city_ascii": "Poland",
    "lat": 44.0463,
    "lng": -70.39,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maine",
    "capital": "",
    "population": 5552,
    "id": 1840052967
  },
  {
    "city": "Vinita",
    "city_ascii": "Vinita",
    "lat": 36.6364,
    "lng": -95.177,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oklahoma",
    "capital": "",
    "population": 5550,
    "id": 1840021599
  },
  {
    "city": "Horní Slavkov",
    "city_ascii": "Horni Slavkov",
    "lat": 50.1387,
    "lng": 12.8077,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Karlovarský Kraj",
    "capital": "",
    "population": 5403,
    "id": 1203464131
  },
  {
    "city": "Brookshire",
    "city_ascii": "Brookshire",
    "lat": 29.7824,
    "lng": -95.9515,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 5548,
    "id": 1840019610
  },
  {
    "city": "Siátista",
    "city_ascii": "Siatista",
    "lat": 40.2564,
    "lng": 21.5522,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Dytikí Makedonía",
    "capital": "minor",
    "population": 5490,
    "id": 1300472525
  },
  {
    "city": "Sarina",
    "city_ascii": "Sarina",
    "lat": -21.4225,
    "lng": 149.2175,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Queensland",
    "capital": "",
    "population": 5522,
    "id": 1036990230
  },
  {
    "city": "Taylorsville",
    "city_ascii": "Taylorsville",
    "lat": 35.9175,
    "lng": -81.1754,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 5544,
    "id": 1840016220
  },
  {
    "city": "Wappingers Falls",
    "city_ascii": "Wappingers Falls",
    "lat": 41.5984,
    "lng": -73.9182,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 5544,
    "id": 1840004755
  },
  {
    "city": "Palermo",
    "city_ascii": "Palermo",
    "lat": 39.4313,
    "lng": -121.5225,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 5544,
    "id": 1840018777
  },
  {
    "city": "Lake Park",
    "city_ascii": "Lake Park",
    "lat": 30.6852,
    "lng": -83.1875,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 5544,
    "id": 1840015000
  },
  {
    "city": "Pabradė",
    "city_ascii": "Pabrade",
    "lat": 54.9806,
    "lng": 25.7667,
    "country": "Lithuania",
    "iso2": "LT",
    "iso3": "LTU",
    "admin_name": "Švenčionys",
    "capital": "",
    "population": 5528,
    "id": 1440681485
  },
  {
    "city": "Exeter",
    "city_ascii": "Exeter",
    "lat": 41.3338,
    "lng": -75.8214,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5541,
    "id": 1840000749
  },
  {
    "city": "Barre",
    "city_ascii": "Barre",
    "lat": 42.4201,
    "lng": -72.1061,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 5539,
    "id": 1840053685
  },
  {
    "city": "Waterville",
    "city_ascii": "Waterville",
    "lat": 41.5015,
    "lng": -83.7365,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 5539,
    "id": 1840011479
  },
  {
    "city": "Ada",
    "city_ascii": "Ada",
    "lat": 40.7681,
    "lng": -83.8253,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 5537,
    "id": 1840010371
  },
  {
    "city": "Golegã",
    "city_ascii": "Golega",
    "lat": 39.4,
    "lng": -8.4833,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Santarém",
    "capital": "minor",
    "population": 5465,
    "id": 1620801525
  },
  {
    "city": "Zeeland",
    "city_ascii": "Zeeland",
    "lat": 42.8139,
    "lng": -86.0129,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 5536,
    "id": 1840000383
  },
  {
    "city": "Farmers Loop",
    "city_ascii": "Farmers Loop",
    "lat": 64.9061,
    "lng": -147.6957,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alaska",
    "capital": "",
    "population": 5535,
    "id": 1840075181
  },
  {
    "city": "Pelham Manor",
    "city_ascii": "Pelham Manor",
    "lat": 40.893,
    "lng": -73.8057,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 5534,
    "id": 1840004939
  },
  {
    "city": "Taiki",
    "city_ascii": "Taiki",
    "lat": 42.4975,
    "lng": 143.2789,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hokkaidō",
    "capital": "",
    "population": 5527,
    "id": 1392800901
  },
  {
    "city": "Colorado City",
    "city_ascii": "Colorado City",
    "lat": 32.3994,
    "lng": -100.8582,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 5533,
    "id": 1840019472
  },
  {
    "city": "Weldon Spring",
    "city_ascii": "Weldon Spring",
    "lat": 38.7118,
    "lng": -90.6513,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 5533,
    "id": 1840010716
  },
  {
    "city": "Jestetten",
    "city_ascii": "Jestetten",
    "lat": 47.6522,
    "lng": 8.5708,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 5276,
    "id": 1276717681
  },
  {
    "city": "Kannus",
    "city_ascii": "Kannus",
    "lat": 63.9,
    "lng": 23.9167,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Keski-Pohjanmaa",
    "capital": "minor",
    "population": 5520,
    "id": 1246529130
  },
  {
    "city": "Carroll Township",
    "city_ascii": "Carroll Township",
    "lat": 40.1813,
    "lng": -79.9313,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5530,
    "id": 1840151795
  },
  {
    "city": "Cap Malheureux",
    "city_ascii": "Cap Malheureux",
    "lat": -19.9861,
    "lng": 57.6119,
    "country": "Mauritius",
    "iso2": "MU",
    "iso3": "MUS",
    "admin_name": "Rivière du Rempart",
    "capital": "",
    "population": 5070,
    "id": 1480466673
  },
  {
    "city": "Islip Terrace",
    "city_ascii": "Islip Terrace",
    "lat": 40.7506,
    "lng": -73.1872,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 5529,
    "id": 1840005083
  },
  {
    "city": "Old Fig Garden",
    "city_ascii": "Old Fig Garden",
    "lat": 36.7989,
    "lng": -119.8051,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 5529,
    "id": 1840076004
  },
  {
    "city": "Falcon Heights",
    "city_ascii": "Falcon Heights",
    "lat": 44.9899,
    "lng": -93.177,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 5528,
    "id": 1840007838
  },
  {
    "city": "Ore",
    "city_ascii": "Ore",
    "lat": 50.8721,
    "lng": 0.6085,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "East Sussex",
    "capital": "",
    "population": 5195,
    "id": 1826248211
  },
  {
    "city": "Féres",
    "city_ascii": "Feres",
    "lat": 40.8933,
    "lng": 26.1739,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Anatolikí Makedonía kai Thráki",
    "capital": "",
    "population": 5457,
    "id": 1300630630
  },
  {
    "city": "Rochester",
    "city_ascii": "Rochester",
    "lat": 41.76,
    "lng": -70.8385,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 5527,
    "id": 1840053575
  },
  {
    "city": "Atlanta",
    "city_ascii": "Atlanta",
    "lat": 33.1136,
    "lng": -94.1672,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 5526,
    "id": 1840019407
  },
  {
    "city": "Mutriku",
    "city_ascii": "Mutriku",
    "lat": 43.3072,
    "lng": -2.385,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Basque Country",
    "capital": "",
    "population": 5333,
    "id": 1724666395
  },
  {
    "city": "Elmwood",
    "city_ascii": "Elmwood",
    "lat": 29.9555,
    "lng": -90.188,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 5525,
    "id": 1840031147
  },
  {
    "city": "Leacock",
    "city_ascii": "Leacock",
    "lat": 40.0406,
    "lng": -76.1117,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5525,
    "id": 1840035054
  },
  {
    "city": "Hopewell Township",
    "city_ascii": "Hopewell Township",
    "lat": 39.7582,
    "lng": -76.5906,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5525,
    "id": 1840152712
  },
  {
    "city": "Weiser",
    "city_ascii": "Weiser",
    "lat": 44.2547,
    "lng": -116.9689,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Idaho",
    "capital": "",
    "population": 5524,
    "id": 1840021250
  },
  {
    "city": "Barnard Castle",
    "city_ascii": "Barnard Castle",
    "lat": 54.55,
    "lng": -1.92,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Durham",
    "capital": "",
    "population": 5495,
    "id": 1826240589
  },
  {
    "city": "Greenwich Township",
    "city_ascii": "Greenwich Township",
    "lat": 40.6791,
    "lng": -75.1121,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 5523,
    "id": 1840056372
  },
  {
    "city": "Takae",
    "city_ascii": "Takae",
    "lat": 42.3625,
    "lng": 142.3186,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hokkaidō",
    "capital": "",
    "population": 5513,
    "id": 1392571325
  },
  {
    "city": "Old Bethpage",
    "city_ascii": "Old Bethpage",
    "lat": 40.7557,
    "lng": -73.4544,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 5522,
    "id": 1840005247
  },
  {
    "city": "Clare",
    "city_ascii": "Clare",
    "lat": 43.8258,
    "lng": -84.763,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 5522,
    "id": 1840002561
  },
  {
    "city": "Tonganoxie",
    "city_ascii": "Tonganoxie",
    "lat": 39.1083,
    "lng": -95.0829,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kansas",
    "capital": "",
    "population": 5522,
    "id": 1840010661
  },
  {
    "city": "Byron Bay",
    "city_ascii": "Byron Bay",
    "lat": -28.6483,
    "lng": 153.6178,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 5521,
    "id": 1036606974
  },
  {
    "city": "Sabino",
    "city_ascii": "Sabino",
    "lat": -21.46,
    "lng": -49.5778,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 5502,
    "id": 1076051875
  },
  {
    "city": "Wool",
    "city_ascii": "Wool",
    "lat": 50.6789,
    "lng": -2.2189,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Dorset",
    "capital": "",
    "population": 5310,
    "id": 1826429345
  },
  {
    "city": "Gersfeld",
    "city_ascii": "Gersfeld",
    "lat": 50.45,
    "lng": 9.9167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 5458,
    "id": 1276742197
  },
  {
    "city": "Germantown",
    "city_ascii": "Germantown",
    "lat": 39.6324,
    "lng": -84.3645,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 5519,
    "id": 1840012489
  },
  {
    "city": "Massanetta Springs",
    "city_ascii": "Massanetta Springs",
    "lat": 38.3899,
    "lng": -78.834,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 5517,
    "id": 1840026720
  },
  {
    "city": "Luisiânia",
    "city_ascii": "Luisiania",
    "lat": -21.6758,
    "lng": -50.3267,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 5511,
    "id": 1076185494
  },
  {
    "city": "Estherville",
    "city_ascii": "Estherville",
    "lat": 43.3998,
    "lng": -94.8343,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Iowa",
    "capital": "",
    "population": 5516,
    "id": 1840007962
  },
  {
    "city": "Broşteni",
    "city_ascii": "Brosteni",
    "lat": 47.2442,
    "lng": 25.6981,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Suceava",
    "capital": "",
    "population": 5506,
    "id": 1642522635
  },
  {
    "city": "Baxley",
    "city_ascii": "Baxley",
    "lat": 31.7643,
    "lng": -82.3508,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 5515,
    "id": 1840013850
  },
  {
    "city": "Carlinville",
    "city_ascii": "Carlinville",
    "lat": 39.2774,
    "lng": -89.8761,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 5514,
    "id": 1840007366
  },
  {
    "city": "Śmigiel",
    "city_ascii": "Smigiel",
    "lat": 52.0134,
    "lng": 16.527,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Wielkopolskie",
    "capital": "",
    "population": 5420,
    "id": 1616104537
  },
  {
    "city": "Aramina",
    "city_ascii": "Aramina",
    "lat": -20.1672,
    "lng": -47.8265,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 5486,
    "id": 1076022346
  },
  {
    "city": "Elberton",
    "city_ascii": "Elberton",
    "lat": 34.1064,
    "lng": -82.8705,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 5512,
    "id": 1840013651
  },
  {
    "city": "Paola",
    "city_ascii": "Paola",
    "lat": 38.5784,
    "lng": -94.862,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kansas",
    "capital": "",
    "population": 5512,
    "id": 1840001654
  },
  {
    "city": "Penn Township",
    "city_ascii": "Penn Township",
    "lat": 39.8226,
    "lng": -75.8742,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5511,
    "id": 1840141674
  },
  {
    "city": "Winston",
    "city_ascii": "Winston",
    "lat": 43.12,
    "lng": -123.4242,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 5511,
    "id": 1840021275
  },
  {
    "city": "Pyhäjärvi",
    "city_ascii": "Pyhajarvi",
    "lat": 63.6833,
    "lng": 25.9833,
    "country": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "admin_name": "Pohjois-Pohjanmaa",
    "capital": "",
    "population": 5505,
    "id": 1246224367
  },
  {
    "city": "Marbletown",
    "city_ascii": "Marbletown",
    "lat": 41.8699,
    "lng": -74.1628,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 5509,
    "id": 1840058285
  },
  {
    "city": "Wellston",
    "city_ascii": "Wellston",
    "lat": 39.1172,
    "lng": -82.5375,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 5507,
    "id": 1840001622
  },
  {
    "city": "Blackrod",
    "city_ascii": "Blackrod",
    "lat": 53.5898,
    "lng": -2.5809,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Bolton",
    "capital": "",
    "population": 5001,
    "id": 1826761187
  },
  {
    "city": "Bethany",
    "city_ascii": "Bethany",
    "lat": 41.4261,
    "lng": -72.9931,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 5504,
    "id": 1840034038
  },
  {
    "city": "Hamptonburgh",
    "city_ascii": "Hamptonburgh",
    "lat": 41.4484,
    "lng": -74.254,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 5502,
    "id": 1840058161
  },
  {
    "city": "Swartz Creek",
    "city_ascii": "Swartz Creek",
    "lat": 42.9626,
    "lng": -83.826,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 5501,
    "id": 1840002944
  },
  {
    "city": "Tympáki",
    "city_ascii": "Tympaki",
    "lat": 35.0719,
    "lng": 24.7683,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Kríti",
    "capital": "",
    "population": 5285,
    "id": 1300790482
  },
  {
    "city": "Highland Lakes",
    "city_ascii": "Highland Lakes",
    "lat": 33.397,
    "lng": -86.6497,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 5500,
    "id": 1840075149
  },
  {
    "city": "Anamosa",
    "city_ascii": "Anamosa",
    "lat": 42.1091,
    "lng": -91.2758,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Iowa",
    "capital": "",
    "population": 5500,
    "id": 1840006993
  },
  {
    "city": "Aguiar da Beira",
    "city_ascii": "Aguiar da Beira",
    "lat": 40.818,
    "lng": -7.5414,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Guarda",
    "capital": "minor",
    "population": 5473,
    "id": 1620715941
  },
  {
    "city": "Holdenville",
    "city_ascii": "Holdenville",
    "lat": 35.0836,
    "lng": -96.4004,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oklahoma",
    "capital": "",
    "population": 5497,
    "id": 1840020460
  },
  {
    "city": "Jamestown",
    "city_ascii": "Jamestown",
    "lat": 41.5149,
    "lng": -71.377,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Rhode Island",
    "capital": "",
    "population": 5496,
    "id": 1840066118
  },
  {
    "city": "Saint-Philippe",
    "city_ascii": "Saint-Philippe",
    "lat": 45.35,
    "lng": -73.47,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 5495,
    "id": 1124461923
  },
  {
    "city": "Gabčíkovo",
    "city_ascii": "Gabcikovo",
    "lat": 47.8921,
    "lng": 17.5788,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Trnavský",
    "capital": "",
    "population": 5391,
    "id": 1703668722
  },
  {
    "city": "Oura",
    "city_ascii": "Oura",
    "lat": 33.9594,
    "lng": 135.1183,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Wakayama",
    "capital": "",
    "population": 5321,
    "id": 1392542577
  },
  {
    "city": "Sidney",
    "city_ascii": "Sidney",
    "lat": 42.3073,
    "lng": -75.2772,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 5493,
    "id": 1840004621
  },
  {
    "city": "Schrems",
    "city_ascii": "Schrems",
    "lat": 48.7833,
    "lng": 15.0667,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Niederösterreich",
    "capital": "",
    "population": 5404,
    "id": 1040881676
  },
  {
    "city": "Warkworth",
    "city_ascii": "Warkworth",
    "lat": -36.4,
    "lng": 174.6667,
    "country": "New Zealand",
    "iso2": "NZ",
    "iso3": "NZL",
    "admin_name": "Auckland",
    "capital": "",
    "population": 5320,
    "id": 1554014050
  },
  {
    "city": "Stokesdale",
    "city_ascii": "Stokesdale",
    "lat": 36.2318,
    "lng": -79.9834,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 5489,
    "id": 1840016138
  },
  {
    "city": "Loudon",
    "city_ascii": "Loudon",
    "lat": 43.321,
    "lng": -71.4413,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Hampshire",
    "capital": "",
    "population": 5488,
    "id": 1840055143
  },
  {
    "city": "Independence",
    "city_ascii": "Independence",
    "lat": 40.8801,
    "lng": -74.8788,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 5488,
    "id": 1840081763
  },
  {
    "city": "Fort Plain",
    "city_ascii": "Fort Plain",
    "lat": 42.9316,
    "lng": -74.6277,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 5488,
    "id": 1840004402
  },
  {
    "city": "Wolfforth",
    "city_ascii": "Wolfforth",
    "lat": 33.5148,
    "lng": -102.0066,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 5486,
    "id": 1840021983
  },
  {
    "city": "Beekmantown",
    "city_ascii": "Beekmantown",
    "lat": 44.7719,
    "lng": -73.4997,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 5486,
    "id": 1840057228
  },
  {
    "city": "Telč",
    "city_ascii": "Telc",
    "lat": 49.1842,
    "lng": 15.4528,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Vysočina",
    "capital": "",
    "population": 5273,
    "id": 1203523076
  },
  {
    "city": "Wahneta",
    "city_ascii": "Wahneta",
    "lat": 27.9574,
    "lng": -81.7288,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 5484,
    "id": 1840014125
  },
  {
    "city": "Pregarten",
    "city_ascii": "Pregarten",
    "lat": 48.3556,
    "lng": 14.5306,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Oberösterreich",
    "capital": "",
    "population": 5293,
    "id": 1040457074
  },
  {
    "city": "Collinsville",
    "city_ascii": "Collinsville",
    "lat": 36.3718,
    "lng": -95.8611,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oklahoma",
    "capital": "",
    "population": 5483,
    "id": 1840019061
  },
  {
    "city": "Tvardiţa",
    "city_ascii": "Tvardita",
    "lat": 46.1547,
    "lng": 28.9656,
    "country": "Moldova",
    "iso2": "MD",
    "iso3": "MDA",
    "admin_name": "Taraclia",
    "capital": "",
    "population": 5420,
    "id": 1498920865
  },
  {
    "city": "Planá",
    "city_ascii": "Plana",
    "lat": 49.8683,
    "lng": 12.7438,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Plzeňský Kraj",
    "capital": "",
    "population": 5396,
    "id": 1203670604
  },
  {
    "city": "Kremnica",
    "city_ascii": "Kremnica",
    "lat": 48.7044,
    "lng": 18.9183,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Banskobystrický",
    "capital": "",
    "population": 5358,
    "id": 1703202488
  },
  {
    "city": "San Antonio de los Cobres",
    "city_ascii": "San Antonio de los Cobres",
    "lat": -24.2256,
    "lng": -66.3192,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Salta",
    "capital": "minor",
    "population": 5482,
    "id": 1032191344
  },
  {
    "city": "Manteo",
    "city_ascii": "Manteo",
    "lat": 35.9013,
    "lng": -75.661,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 5482,
    "id": 1840016156
  },
  {
    "city": "Lyons",
    "city_ascii": "Lyons",
    "lat": 43.0882,
    "lng": -76.9996,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 5479,
    "id": 1840058270
  },
  {
    "city": "Hluboká nad Vltavou",
    "city_ascii": "Hluboka nad Vltavou",
    "lat": 49.0523,
    "lng": 14.4343,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Jihočeský Kraj",
    "capital": "",
    "population": 5419,
    "id": 1203893686
  },
  {
    "city": "Breckenridge",
    "city_ascii": "Breckenridge",
    "lat": 32.7566,
    "lng": -98.9125,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 5478,
    "id": 1840019448
  },
  {
    "city": "Severance",
    "city_ascii": "Severance",
    "lat": 40.527,
    "lng": -104.8642,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 5478,
    "id": 1840022400
  },
  {
    "city": "Muldrow",
    "city_ascii": "Muldrow",
    "lat": 35.4039,
    "lng": -94.5969,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oklahoma",
    "capital": "",
    "population": 5478,
    "id": 1840022738
  },
  {
    "city": "Peculiar",
    "city_ascii": "Peculiar",
    "lat": 38.7306,
    "lng": -94.4736,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 5478,
    "id": 1840009785
  },
  {
    "city": "Somerdale",
    "city_ascii": "Somerdale",
    "lat": 39.8454,
    "lng": -75.0218,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 5477,
    "id": 1840001512
  },
  {
    "city": "Greenville",
    "city_ascii": "Greenville",
    "lat": 31.8437,
    "lng": -86.6379,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 5477,
    "id": 1840003498
  },
  {
    "city": "Desaguadero",
    "city_ascii": "Desaguadero",
    "lat": -16.5684,
    "lng": -69.0421,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "Puno",
    "capital": "",
    "population": 5329,
    "id": 1604523021
  },
  {
    "city": "Sparta",
    "city_ascii": "Sparta",
    "lat": 35.9347,
    "lng": -85.4726,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 5476,
    "id": 1840015354
  },
  {
    "city": "Tharandt",
    "city_ascii": "Tharandt",
    "lat": 50.9833,
    "lng": 13.5808,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 5400,
    "id": 1276699012
  },
  {
    "city": "Oryahovo",
    "city_ascii": "Oryahovo",
    "lat": 43.7367,
    "lng": 23.9583,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Vratsa",
    "capital": "minor",
    "population": 5367,
    "id": 1100565108
  },
  {
    "city": "Niedenstein",
    "city_ascii": "Niedenstein",
    "lat": 51.2333,
    "lng": 9.3167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 5299,
    "id": 1276393083
  },
  {
    "city": "Manchester",
    "city_ascii": "Manchester",
    "lat": 47.5519,
    "lng": -122.5517,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 5471,
    "id": 1840017336
  },
  {
    "city": "Quincy",
    "city_ascii": "Quincy",
    "lat": 39.8148,
    "lng": -77.5493,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5471,
    "id": 1840100936
  },
  {
    "city": "Indian Wells",
    "city_ascii": "Indian Wells",
    "lat": 33.7036,
    "lng": -116.3396,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 5470,
    "id": 1840019303
  },
  {
    "city": "La Herradura",
    "city_ascii": "La Herradura",
    "lat": 36.735,
    "lng": -3.7372,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Andalusia",
    "capital": "",
    "population": 5133,
    "id": 1724582411
  },
  {
    "city": "Zhizdra",
    "city_ascii": "Zhizdra",
    "lat": 53.7503,
    "lng": 34.7361,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kaluzhskaya Oblast’",
    "capital": "",
    "population": 5461,
    "id": 1643776159
  },
  {
    "city": "Lemmon Valley",
    "city_ascii": "Lemmon Valley",
    "lat": 39.6879,
    "lng": -119.8364,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Nevada",
    "capital": "",
    "population": 5469,
    "id": 1840033785
  },
  {
    "city": "Williams",
    "city_ascii": "Williams",
    "lat": 39.1498,
    "lng": -122.1389,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 5469,
    "id": 1840021460
  },
  {
    "city": "Rincon Valley",
    "city_ascii": "Rincon Valley",
    "lat": 32.1101,
    "lng": -110.6889,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 5469,
    "id": 1840038089
  },
  {
    "city": "Harwinton",
    "city_ascii": "Harwinton",
    "lat": 41.755,
    "lng": -73.0582,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 5469,
    "id": 1840034917
  },
  {
    "city": "Vernon",
    "city_ascii": "Vernon",
    "lat": 41.6255,
    "lng": -80.2189,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5467,
    "id": 1840142388
  },
  {
    "city": "Richelieu",
    "city_ascii": "Richelieu",
    "lat": 45.45,
    "lng": -73.25,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 5467,
    "id": 1124000387
  },
  {
    "city": "Notre-Dame-du-Mont-Carmel",
    "city_ascii": "Notre-Dame-du-Mont-Carmel",
    "lat": 46.4833,
    "lng": -72.65,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 5467,
    "id": 1124893320
  },
  {
    "city": "Rockenhausen",
    "city_ascii": "Rockenhausen",
    "lat": 49.6286,
    "lng": 7.8206,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Rhineland-Palatinate",
    "capital": "",
    "population": 5322,
    "id": 1276083593
  },
  {
    "city": "Buckner",
    "city_ascii": "Buckner",
    "lat": 38.3867,
    "lng": -85.4503,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kentucky",
    "capital": "",
    "population": 5465,
    "id": 1840013192
  },
  {
    "city": "New Holland",
    "city_ascii": "New Holland",
    "lat": 40.1008,
    "lng": -76.09,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5464,
    "id": 1840003725
  },
  {
    "city": "L'Ange-Gardien",
    "city_ascii": "L'Ange-Gardien",
    "lat": 45.5833,
    "lng": -75.45,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 5464,
    "id": 1124001197
  },
  {
    "city": "Pana",
    "city_ascii": "Pana",
    "lat": 39.3828,
    "lng": -89.0642,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 5463,
    "id": 1840009548
  },
  {
    "city": "Sládkovičovo",
    "city_ascii": "Sladkovicovo",
    "lat": 48.2,
    "lng": 17.65,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Trnavský",
    "capital": "",
    "population": 5281,
    "id": 1703910883
  },
  {
    "city": "Sainte-Martine",
    "city_ascii": "Sainte-Martine",
    "lat": 45.25,
    "lng": -73.8,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 5461,
    "id": 1124000017
  },
  {
    "city": "Komádi",
    "city_ascii": "Komadi",
    "lat": 47,
    "lng": 21.5,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Hajdú-Bihar",
    "capital": "",
    "population": 5421,
    "id": 1348286958
  },
  {
    "city": "Woodside",
    "city_ascii": "Woodside",
    "lat": 37.4222,
    "lng": -122.2591,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 5458,
    "id": 1840022548
  },
  {
    "city": "Braúna",
    "city_ascii": "Brauna",
    "lat": -21.5,
    "lng": -50.3167,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 5454,
    "id": 1076848088
  },
  {
    "city": "Nursling",
    "city_ascii": "Nursling",
    "lat": 50.9449,
    "lng": -1.4727,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hampshire",
    "capital": "",
    "population": 5137,
    "id": 1826012560
  },
  {
    "city": "Vámospércs",
    "city_ascii": "Vamospercs",
    "lat": 47.5266,
    "lng": 21.8984,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Hajdú-Bihar",
    "capital": "",
    "population": 5362,
    "id": 1348362655
  },
  {
    "city": "Marlin",
    "city_ascii": "Marlin",
    "lat": 31.3085,
    "lng": -96.8934,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 5454,
    "id": 1840020844
  },
  {
    "city": "Novaci-Străini",
    "city_ascii": "Novaci-Straini",
    "lat": 45.18,
    "lng": 23.67,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Gorj",
    "capital": "",
    "population": 5431,
    "id": 1642074535
  },
  {
    "city": "Pfreimd",
    "city_ascii": "Pfreimd",
    "lat": 49.5,
    "lng": 12.1833,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 5349,
    "id": 1276211281
  },
  {
    "city": "Zistersdorf",
    "city_ascii": "Zistersdorf",
    "lat": 48.5167,
    "lng": 16.75,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Niederösterreich",
    "capital": "",
    "population": 5391,
    "id": 1040605021
  },
  {
    "city": "San Simón de Guerrero",
    "city_ascii": "San Simon de Guerrero",
    "lat": 19.0225,
    "lng": -100.0067,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "México",
    "capital": "minor",
    "population": 5408,
    "id": 1484005428
  },
  {
    "city": "Vrontádos",
    "city_ascii": "Vrontados",
    "lat": 38.4167,
    "lng": 26.1333,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Voreío Aigaío",
    "capital": "",
    "population": 5323,
    "id": 1300409203
  },
  {
    "city": "Jim Thorpe",
    "city_ascii": "Jim Thorpe",
    "lat": 40.8712,
    "lng": -75.7433,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5449,
    "id": 1840000931
  },
  {
    "city": "Hidden Valley",
    "city_ascii": "Hidden Valley",
    "lat": 39.1673,
    "lng": -84.8444,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 5449,
    "id": 1840005881
  },
  {
    "city": "Glencoe",
    "city_ascii": "Glencoe",
    "lat": 44.7699,
    "lng": -94.1512,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 5448,
    "id": 1840007845
  },
  {
    "city": "Terrell Hills",
    "city_ascii": "Terrell Hills",
    "lat": 29.4771,
    "lng": -98.4472,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 5447,
    "id": 1840022224
  },
  {
    "city": "Carlisle",
    "city_ascii": "Carlisle",
    "lat": 39.5807,
    "lng": -84.3201,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 5446,
    "id": 1840007345
  },
  {
    "city": "Colby",
    "city_ascii": "Colby",
    "lat": 39.3843,
    "lng": -101.0459,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kansas",
    "capital": "",
    "population": 5445,
    "id": 1840001553
  },
  {
    "city": "Sălişte",
    "city_ascii": "Saliste",
    "lat": 45.7942,
    "lng": 23.8864,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Sibiu",
    "capital": "",
    "population": 5421,
    "id": 1642473434
  },
  {
    "city": "Velas",
    "city_ascii": "Velas",
    "lat": 38.6817,
    "lng": -28.2079,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Azores",
    "capital": "",
    "population": 5398,
    "id": 1620693269
  },
  {
    "city": "Springhill",
    "city_ascii": "Springhill",
    "lat": 33.0019,
    "lng": -93.4613,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 5444,
    "id": 1840015771
  },
  {
    "city": "North Sewickley",
    "city_ascii": "North Sewickley",
    "lat": 40.8055,
    "lng": -80.2837,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5444,
    "id": 1840100618
  },
  {
    "city": "Bear Valley Springs",
    "city_ascii": "Bear Valley Springs",
    "lat": 35.1775,
    "lng": -118.646,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 5442,
    "id": 1840017809
  },
  {
    "city": "South Pittsburg",
    "city_ascii": "South Pittsburg",
    "lat": 35.0111,
    "lng": -85.7183,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 5439,
    "id": 1840015471
  },
  {
    "city": "Arlington Heights",
    "city_ascii": "Arlington Heights",
    "lat": 41.0039,
    "lng": -75.2116,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5439,
    "id": 1840034849
  },
  {
    "city": "Eaton Rapids",
    "city_ascii": "Eaton Rapids",
    "lat": 42.5092,
    "lng": -84.653,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 5439,
    "id": 1840003108
  },
  {
    "city": "Robbins",
    "city_ascii": "Robbins",
    "lat": 41.6431,
    "lng": -87.708,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 5438,
    "id": 1840011288
  },
  {
    "city": "Sherwood Manor",
    "city_ascii": "Sherwood Manor",
    "lat": 42.0125,
    "lng": -72.566,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 5438,
    "id": 1840028600
  },
  {
    "city": "Saint-Pie",
    "city_ascii": "Saint-Pie",
    "lat": 45.5,
    "lng": -72.9,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 5438,
    "id": 1124508787
  },
  {
    "city": "Marabá Paulista",
    "city_ascii": "Maraba Paulista",
    "lat": -22.1081,
    "lng": -51.9625,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 5435,
    "id": 1076619249
  },
  {
    "city": "Rye",
    "city_ascii": "Rye",
    "lat": 43.0141,
    "lng": -70.7607,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Hampshire",
    "capital": "",
    "population": 5434,
    "id": 1840055509
  },
  {
    "city": "Northwood",
    "city_ascii": "Northwood",
    "lat": 41.6089,
    "lng": -83.4836,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 5434,
    "id": 1840000622
  },
  {
    "city": "Eltmann",
    "city_ascii": "Eltmann",
    "lat": 49.9718,
    "lng": 10.6666,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 5299,
    "id": 1276848886
  },
  {
    "city": "Starý Plzenec",
    "city_ascii": "Stary Plzenec",
    "lat": 49.6978,
    "lng": 13.4736,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Plzeňský Kraj",
    "capital": "",
    "population": 5151,
    "id": 1203911263
  },
  {
    "city": "Archángelos",
    "city_ascii": "Archangelos",
    "lat": 36.2167,
    "lng": 28.1167,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Notío Aigaío",
    "capital": "",
    "population": 5384,
    "id": 1300451264
  },
  {
    "city": "Black River Falls",
    "city_ascii": "Black River Falls",
    "lat": 44.2981,
    "lng": -90.842,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 5430,
    "id": 1840002397
  },
  {
    "city": "Grieskirchen",
    "city_ascii": "Grieskirchen",
    "lat": 48.235,
    "lng": 13.8319,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Oberösterreich",
    "capital": "minor",
    "population": 5002,
    "id": 1040822650
  },
  {
    "city": "Skýdra",
    "city_ascii": "Skydra",
    "lat": 40.7667,
    "lng": 22.15,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Kentrikí Makedonía",
    "capital": "minor",
    "population": 5406,
    "id": 1300086862
  },
  {
    "city": "Quorndon",
    "city_ascii": "Quorndon",
    "lat": 52.7428,
    "lng": -1.17,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Leicestershire",
    "capital": "",
    "population": 5177,
    "id": 1826805586
  },
  {
    "city": "Honeoye Falls",
    "city_ascii": "Honeoye Falls",
    "lat": 42.9557,
    "lng": -77.5903,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 5428,
    "id": 1840004282
  },
  {
    "city": "Peachland",
    "city_ascii": "Peachland",
    "lat": 49.7736,
    "lng": -119.7369,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "British Columbia",
    "capital": "",
    "population": 5428,
    "id": 1124440160
  },
  {
    "city": "Fruitland",
    "city_ascii": "Fruitland",
    "lat": 44.0195,
    "lng": -116.9221,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Idaho",
    "capital": "",
    "population": 5426,
    "id": 1840020013
  },
  {
    "city": "Neudenau",
    "city_ascii": "Neudenau",
    "lat": 49.2833,
    "lng": 9.2667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 5266,
    "id": 1276645643
  },
  {
    "city": "Rockwood",
    "city_ascii": "Rockwood",
    "lat": 35.8693,
    "lng": -84.6731,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 5425,
    "id": 1840014498
  },
  {
    "city": "Bar Harbor",
    "city_ascii": "Bar Harbor",
    "lat": 44.3852,
    "lng": -68.2711,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maine",
    "capital": "",
    "population": 5425,
    "id": 1840052424
  },
  {
    "city": "Violet",
    "city_ascii": "Violet",
    "lat": 29.8962,
    "lng": -89.892,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 5424,
    "id": 1840014002
  },
  {
    "city": "Coraopolis",
    "city_ascii": "Coraopolis",
    "lat": 40.5148,
    "lng": -80.1627,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5424,
    "id": 1840001220
  },
  {
    "city": "Schärding",
    "city_ascii": "Scharding",
    "lat": 48.4569,
    "lng": 13.4317,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Oberösterreich",
    "capital": "minor",
    "population": 5253,
    "id": 1040841987
  },
  {
    "city": "Szikszó",
    "city_ascii": "Szikszo",
    "lat": 48.195,
    "lng": 20.9461,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Borsod-Abaúj-Zemplén",
    "capital": "minor",
    "population": 5278,
    "id": 1348727333
  },
  {
    "city": "Littlefield",
    "city_ascii": "Littlefield",
    "lat": 33.9191,
    "lng": -102.3349,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 5423,
    "id": 1840020539
  },
  {
    "city": "Lucerne Valley",
    "city_ascii": "Lucerne Valley",
    "lat": 34.4427,
    "lng": -116.9021,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 5423,
    "id": 1840025800
  },
  {
    "city": "Naie",
    "city_ascii": "Naie",
    "lat": 43.4253,
    "lng": 141.8828,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hokkaidō",
    "capital": "",
    "population": 5362,
    "id": 1392114331
  },
  {
    "city": "Hanamaulu",
    "city_ascii": "Hanamaulu",
    "lat": 21.9954,
    "lng": -159.3493,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Hawaii",
    "capital": "",
    "population": 5422,
    "id": 1840029477
  },
  {
    "city": "Waynesboro",
    "city_ascii": "Waynesboro",
    "lat": 33.0909,
    "lng": -82.0146,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 5422,
    "id": 1840015742
  },
  {
    "city": "Ashfield-Colborne-Wawanosh",
    "city_ascii": "Ashfield-Colborne-Wawanosh",
    "lat": 43.8667,
    "lng": -81.6,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 5422,
    "id": 1124000025
  },
  {
    "city": "Monaca",
    "city_ascii": "Monaca",
    "lat": 40.6833,
    "lng": -80.2736,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5421,
    "id": 1840001025
  },
  {
    "city": "Columbia",
    "city_ascii": "Columbia",
    "lat": 41.6939,
    "lng": -72.3072,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 5421,
    "id": 1840034367
  },
  {
    "city": "Dalry",
    "city_ascii": "Dalry",
    "lat": 55.711,
    "lng": -4.723,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "North Ayrshire",
    "capital": "",
    "population": 5398,
    "id": 1826866609
  },
  {
    "city": "Mittersill",
    "city_ascii": "Mittersill",
    "lat": 47.2667,
    "lng": 12.4667,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Salzburg",
    "capital": "",
    "population": 5380,
    "id": 1040110638
  },
  {
    "city": "Bad Hall",
    "city_ascii": "Bad Hall",
    "lat": 48.0344,
    "lng": 14.2097,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Oberösterreich",
    "capital": "",
    "population": 5043,
    "id": 1040705265
  },
  {
    "city": "Morristown",
    "city_ascii": "Morristown",
    "lat": 44.5485,
    "lng": -72.6376,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Vermont",
    "capital": "",
    "population": 5420,
    "id": 1840070935
  },
  {
    "city": "Basehor",
    "city_ascii": "Basehor",
    "lat": 39.1332,
    "lng": -94.9333,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kansas",
    "capital": "",
    "population": 5419,
    "id": 1840007373
  },
  {
    "city": "Thum",
    "city_ascii": "Thum",
    "lat": 50.6711,
    "lng": 12.9514,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Saxony",
    "capital": "",
    "population": 5146,
    "id": 1276879643
  },
  {
    "city": "Jackson",
    "city_ascii": "Jackson",
    "lat": 33.292,
    "lng": -83.9678,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 5418,
    "id": 1840014836
  },
  {
    "city": "Lafayette",
    "city_ascii": "Lafayette",
    "lat": 36.5242,
    "lng": -86.0307,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 5417,
    "id": 1840014423
  },
  {
    "city": "Charleston",
    "city_ascii": "Charleston",
    "lat": 36.9179,
    "lng": -89.3344,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 5417,
    "id": 1840007648
  },
  {
    "city": "Angier",
    "city_ascii": "Angier",
    "lat": 35.512,
    "lng": -78.7405,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 5415,
    "id": 1840015423
  },
  {
    "city": "Denver City",
    "city_ascii": "Denver City",
    "lat": 32.968,
    "lng": -102.8318,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 5413,
    "id": 1840022029
  },
  {
    "city": "Ferriday",
    "city_ascii": "Ferriday",
    "lat": 31.6343,
    "lng": -91.5562,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 5412,
    "id": 1840017075
  },
  {
    "city": "Kisbér",
    "city_ascii": "Kisber",
    "lat": 47.5017,
    "lng": 18.0267,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Komárom-Esztergom",
    "capital": "minor",
    "population": 5336,
    "id": 1348318587
  },
  {
    "city": "Kenai",
    "city_ascii": "Kenai",
    "lat": 60.5619,
    "lng": -151.1985,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alaska",
    "capital": "",
    "population": 5411,
    "id": 1840023421
  },
  {
    "city": "New Hempstead",
    "city_ascii": "New Hempstead",
    "lat": 41.1488,
    "lng": -74.0485,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 5410,
    "id": 1840033996
  },
  {
    "city": "Fort Lee",
    "city_ascii": "Fort Lee",
    "lat": 37.2357,
    "lng": -77.3325,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 5409,
    "id": 1840073580
  },
  {
    "city": "Coal City",
    "city_ascii": "Coal City",
    "lat": 41.2772,
    "lng": -88.2803,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 5409,
    "id": 1840011613
  },
  {
    "city": "Kusel",
    "city_ascii": "Kusel",
    "lat": 49.5347,
    "lng": 7.3981,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Rhineland-Palatinate",
    "capital": "minor",
    "population": 5405,
    "id": 1276922495
  },
  {
    "city": "Gilbertsville",
    "city_ascii": "Gilbertsville",
    "lat": 40.3219,
    "lng": -75.609,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5408,
    "id": 1840005461
  },
  {
    "city": "Canal Fulton",
    "city_ascii": "Canal Fulton",
    "lat": 40.8895,
    "lng": -81.5882,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 5408,
    "id": 1840007173
  },
  {
    "city": "Apple Valley",
    "city_ascii": "Apple Valley",
    "lat": 40.4389,
    "lng": -82.3481,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 5408,
    "id": 1840026491
  },
  {
    "city": "Sebezh",
    "city_ascii": "Sebezh",
    "lat": 56.2833,
    "lng": 28.4833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Pskovskaya Oblast’",
    "capital": "minor",
    "population": 5401,
    "id": 1643427849
  },
  {
    "city": "Baia de Aramă",
    "city_ascii": "Baia de Arama",
    "lat": 45,
    "lng": 22.8114,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Mehedinţi",
    "capital": "",
    "population": 5349,
    "id": 1642701435
  },
  {
    "city": "Altentreptow",
    "city_ascii": "Altentreptow",
    "lat": 53.6927,
    "lng": 13.2562,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Mecklenburg-Western Pomerania",
    "capital": "",
    "population": 5307,
    "id": 1276676254
  },
  {
    "city": "Valencia de Alcántara",
    "city_ascii": "Valencia de Alcantara",
    "lat": 39.4133,
    "lng": -7.2436,
    "country": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "admin_name": "Extremadura",
    "capital": "",
    "population": 5397,
    "id": 1724447883
  },
  {
    "city": "Battenberg",
    "city_ascii": "Battenberg",
    "lat": 51.0167,
    "lng": 8.65,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 5323,
    "id": 1276685373
  },
  {
    "city": "Qamīnis",
    "city_ascii": "Qaminis",
    "lat": 31.6572,
    "lng": 20.0144,
    "country": "Libya",
    "iso2": "LY",
    "iso3": "LBY",
    "admin_name": "Banghāzī",
    "capital": "",
    "population": 5348,
    "id": 1434752325
  },
  {
    "city": "Valparaiso",
    "city_ascii": "Valparaiso",
    "lat": 30.4926,
    "lng": -86.5079,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 5401,
    "id": 1840015903
  },
  {
    "city": "Valatie",
    "city_ascii": "Valatie",
    "lat": 42.4134,
    "lng": -73.6778,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 5400,
    "id": 1840004632
  },
  {
    "city": "Warren",
    "city_ascii": "Warren",
    "lat": 33.6113,
    "lng": -92.0677,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arkansas",
    "capital": "",
    "population": 5399,
    "id": 1840015696
  },
  {
    "city": "Ourique",
    "city_ascii": "Ourique",
    "lat": 37.65,
    "lng": -8.225,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Beja",
    "capital": "minor",
    "population": 5389,
    "id": 1620704423
  },
  {
    "city": "Mocksville",
    "city_ascii": "Mocksville",
    "lat": 35.9006,
    "lng": -80.5631,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 5397,
    "id": 1840016212
  },
  {
    "city": "Trent Lakes",
    "city_ascii": "Trent Lakes",
    "lat": 44.6667,
    "lng": -78.4333,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 5397,
    "id": 1124001268
  },
  {
    "city": "Monticello",
    "city_ascii": "Monticello",
    "lat": 40.0341,
    "lng": -88.5729,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 5396,
    "id": 1840008377
  },
  {
    "city": "Pinckneyville",
    "city_ascii": "Pinckneyville",
    "lat": 38.0851,
    "lng": -89.3718,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 5393,
    "id": 1840009844
  },
  {
    "city": "Northern Rockies",
    "city_ascii": "Northern Rockies",
    "lat": 59,
    "lng": -123.75,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "British Columbia",
    "capital": "",
    "population": 5393,
    "id": 1124001362
  },
  {
    "city": "New Castle",
    "city_ascii": "New Castle",
    "lat": 39.6685,
    "lng": -75.5692,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Delaware",
    "capital": "",
    "population": 5392,
    "id": 1840005577
  },
  {
    "city": "Redstone",
    "city_ascii": "Redstone",
    "lat": 39.9809,
    "lng": -79.8451,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5392,
    "id": 1840149585
  },
  {
    "city": "Kinross",
    "city_ascii": "Kinross",
    "lat": 56.205,
    "lng": -3.423,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Perth and Kinross",
    "capital": "",
    "population": 5120,
    "id": 1826912793
  },
  {
    "city": "Smiths Station",
    "city_ascii": "Smiths Station",
    "lat": 32.5284,
    "lng": -85.096,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 5391,
    "id": 1840015792
  },
  {
    "city": "Pinardville",
    "city_ascii": "Pinardville",
    "lat": 43.001,
    "lng": -71.5171,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Hampshire",
    "capital": "",
    "population": 5390,
    "id": 1840002991
  },
  {
    "city": "Vienna",
    "city_ascii": "Vienna",
    "lat": 43.2344,
    "lng": -75.7777,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 5390,
    "id": 1840058566
  },
  {
    "city": "Manitou Springs",
    "city_ascii": "Manitou Springs",
    "lat": 38.8576,
    "lng": -104.9128,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Colorado",
    "capital": "",
    "population": 5390,
    "id": 1840020237
  },
  {
    "city": "General Bravo",
    "city_ascii": "General Bravo",
    "lat": 25.8,
    "lng": -99.1667,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Nuevo León",
    "capital": "minor",
    "population": 5385,
    "id": 1484226392
  },
  {
    "city": "Heimsheim",
    "city_ascii": "Heimsheim",
    "lat": 48.8056,
    "lng": 8.8619,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 5035,
    "id": 1276569364
  },
  {
    "city": "Northfield",
    "city_ascii": "Northfield",
    "lat": 42.1026,
    "lng": -87.7791,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 5386,
    "id": 1840011305
  },
  {
    "city": "Zlynka",
    "city_ascii": "Zlynka",
    "lat": 52.4333,
    "lng": 31.7333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Bryanskaya Oblast’",
    "capital": "",
    "population": 5369,
    "id": 1643497999
  },
  {
    "city": "Auburn",
    "city_ascii": "Auburn",
    "lat": 42.9906,
    "lng": -71.3438,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Hampshire",
    "capital": "",
    "population": 5385,
    "id": 1840054548
  },
  {
    "city": "Central",
    "city_ascii": "Central",
    "lat": 34.7234,
    "lng": -82.7787,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 5385,
    "id": 1840016494
  },
  {
    "city": "Stühlingen",
    "city_ascii": "Stuhlingen",
    "lat": 47.7453,
    "lng": 8.4458,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 5327,
    "id": 1276748330
  },
  {
    "city": "La Grange",
    "city_ascii": "La Grange",
    "lat": 29.9129,
    "lng": -96.8767,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 5384,
    "id": 1840020934
  },
  {
    "city": "Minot AFB",
    "city_ascii": "Minot AFB",
    "lat": 48.4209,
    "lng": -101.3381,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Dakota",
    "capital": "",
    "population": 5384,
    "id": 1840073766
  },
  {
    "city": "Jamul",
    "city_ascii": "Jamul",
    "lat": 32.7184,
    "lng": -116.8709,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 5384,
    "id": 1840018021
  },
  {
    "city": "Oneonta",
    "city_ascii": "Oneonta",
    "lat": 33.9392,
    "lng": -86.4929,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 5382,
    "id": 1840005206
  },
  {
    "city": "Bellows Falls",
    "city_ascii": "Bellows Falls",
    "lat": 43.1344,
    "lng": -72.455,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Vermont",
    "capital": "",
    "population": 5381,
    "id": 1840004334
  },
  {
    "city": "Vernon",
    "city_ascii": "Vernon",
    "lat": 43.066,
    "lng": -75.5356,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 5380,
    "id": 1840004214
  },
  {
    "city": "Wonthaggi",
    "city_ascii": "Wonthaggi",
    "lat": -38.6056,
    "lng": 145.5917,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Victoria",
    "capital": "",
    "population": 4965,
    "id": 1036415078
  },
  {
    "city": "Chinle",
    "city_ascii": "Chinle",
    "lat": 36.1504,
    "lng": -109.5794,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 5378,
    "id": 1840017672
  },
  {
    "city": "Totontepec Villa de Morelos",
    "city_ascii": "Totontepec Villa de Morelos",
    "lat": 17.2567,
    "lng": -96.0269,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Oaxaca",
    "capital": "minor",
    "population": 5361,
    "id": 1484058603
  },
  {
    "city": "Orwigsburg",
    "city_ascii": "Orwigsburg",
    "lat": 40.6541,
    "lng": -76.104,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5376,
    "id": 1840002770
  },
  {
    "city": "Newport",
    "city_ascii": "Newport",
    "lat": 41.1785,
    "lng": -76.0486,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5374,
    "id": 1840145400
  },
  {
    "city": "Caldwell",
    "city_ascii": "Caldwell",
    "lat": 39.7467,
    "lng": -81.5127,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 5374,
    "id": 1840010565
  },
  {
    "city": "Trenton",
    "city_ascii": "Trenton",
    "lat": 40.0819,
    "lng": -93.603,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 5374,
    "id": 1840010490
  },
  {
    "city": "Marilla",
    "city_ascii": "Marilla",
    "lat": 42.8251,
    "lng": -78.5322,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 5373,
    "id": 1840058288
  },
  {
    "city": "Cleveland",
    "city_ascii": "Cleveland",
    "lat": 34.5971,
    "lng": -83.7621,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 5372,
    "id": 1840014665
  },
  {
    "city": "Lisbon",
    "city_ascii": "Lisbon",
    "lat": 40.7752,
    "lng": -80.7628,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 5372,
    "id": 1840011932
  },
  {
    "city": "Mount Carmel",
    "city_ascii": "Mount Carmel",
    "lat": 39.0978,
    "lng": -84.2995,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 5372,
    "id": 1840034326
  },
  {
    "city": "Ochi",
    "city_ascii": "Ochi",
    "lat": 33.5328,
    "lng": 133.2519,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Kōchi",
    "capital": "",
    "population": 5324,
    "id": 1392035701
  },
  {
    "city": "Momignies",
    "city_ascii": "Momignies",
    "lat": 50.0294,
    "lng": 4.1667,
    "country": "Belgium",
    "iso2": "BE",
    "iso3": "BEL",
    "admin_name": "Wallonia",
    "capital": "",
    "population": 5309,
    "id": 1056025203
  },
  {
    "city": "Connell",
    "city_ascii": "Connell",
    "lat": 46.6622,
    "lng": -118.8404,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 5370,
    "id": 1840018479
  },
  {
    "city": "Manchester-by-the-Sea",
    "city_ascii": "Manchester-by-the-Sea",
    "lat": 42.5815,
    "lng": -70.7682,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 5370,
    "id": 1840053669
  },
  {
    "city": "Polson",
    "city_ascii": "Polson",
    "lat": 47.6886,
    "lng": -114.1411,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Montana",
    "capital": "",
    "population": 5370,
    "id": 1840019802
  },
  {
    "city": "Česká Kamenice",
    "city_ascii": "Ceska Kamenice",
    "lat": 50.7979,
    "lng": 14.4178,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Ústecký Kraj",
    "capital": "",
    "population": 5235,
    "id": 1203636633
  },
  {
    "city": "Ḩannā",
    "city_ascii": "Hanna",
    "lat": 31.1989,
    "lng": 51.7247,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Eşfahān",
    "capital": "",
    "population": 5358,
    "id": 1364733100
  },
  {
    "city": "Ciacova",
    "city_ascii": "Ciacova",
    "lat": 45.5,
    "lng": 21.1333,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Timiş",
    "capital": "",
    "population": 5348,
    "id": 1642400327
  },
  {
    "city": "Ştefăneşti",
    "city_ascii": "Stefanesti",
    "lat": 47.7922,
    "lng": 27.2011,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Botoşani",
    "capital": "",
    "population": 5314,
    "id": 1642332050
  },
  {
    "city": "Bridgton",
    "city_ascii": "Bridgton",
    "lat": 44.0481,
    "lng": -70.7362,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maine",
    "capital": "",
    "population": 5369,
    "id": 1840052541
  },
  {
    "city": "Rockdale",
    "city_ascii": "Rockdale",
    "lat": 30.6542,
    "lng": -97.0089,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 5368,
    "id": 1840020859
  },
  {
    "city": "Estacada",
    "city_ascii": "Estacada",
    "lat": 45.2987,
    "lng": -122.3338,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 5368,
    "id": 1840019948
  },
  {
    "city": "West St. Paul",
    "city_ascii": "West St. Paul",
    "lat": 50.0119,
    "lng": -97.115,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Manitoba",
    "capital": "",
    "population": 5368,
    "id": 1124001136
  },
  {
    "city": "Vânju-Mare",
    "city_ascii": "Vanju-Mare",
    "lat": 44.4319,
    "lng": 22.8769,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Mehedinţi",
    "capital": "",
    "population": 5311,
    "id": 1642312344
  },
  {
    "city": "Lindenfels",
    "city_ascii": "Lindenfels",
    "lat": 49.6849,
    "lng": 8.7803,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 5124,
    "id": 1276003590
  },
  {
    "city": "Manchester",
    "city_ascii": "Manchester",
    "lat": 39.6584,
    "lng": -76.8881,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 5367,
    "id": 1840005702
  },
  {
    "city": "Windsor",
    "city_ascii": "Windsor",
    "lat": 45.5667,
    "lng": -72,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 5367,
    "id": 1124798166
  },
  {
    "city": "Bakov nad Jizerou",
    "city_ascii": "Bakov nad Jizerou",
    "lat": 50.4824,
    "lng": 14.9416,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Středočeský Kraj",
    "capital": "",
    "population": 5175,
    "id": 1203963920
  },
  {
    "city": "Mahlberg",
    "city_ascii": "Mahlberg",
    "lat": 48.2869,
    "lng": 7.8114,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 5061,
    "id": 1276917476
  },
  {
    "city": "Pytalovo",
    "city_ascii": "Pytalovo",
    "lat": 57.0667,
    "lng": 27.9167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Pskovskaya Oblast’",
    "capital": "",
    "population": 5354,
    "id": 1643718643
  },
  {
    "city": "Sunset",
    "city_ascii": "Sunset",
    "lat": 41.1392,
    "lng": -112.0285,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Utah",
    "capital": "",
    "population": 5364,
    "id": 1840021351
  },
  {
    "city": "Kdyně",
    "city_ascii": "Kdyne",
    "lat": 49.3909,
    "lng": 13.0397,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Plzeňský Kraj",
    "capital": "",
    "population": 5183,
    "id": 1203239267
  },
  {
    "city": "Třemošná",
    "city_ascii": "Tremosna",
    "lat": 49.8159,
    "lng": 13.3951,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Plzeňský Kraj",
    "capital": "",
    "population": 5082,
    "id": 1203235254
  },
  {
    "city": "Alness",
    "city_ascii": "Alness",
    "lat": 57.695,
    "lng": -4.258,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Highland",
    "capital": "",
    "population": 5310,
    "id": 1826138078
  },
  {
    "city": "St. Paul Park",
    "city_ascii": "St. Paul Park",
    "lat": 44.836,
    "lng": -92.9949,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 5362,
    "id": 1840008917
  },
  {
    "city": "Horažďovice",
    "city_ascii": "Horazd'ovice",
    "lat": 49.3208,
    "lng": 13.701,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Plzeňský Kraj",
    "capital": "",
    "population": 5240,
    "id": 1203182737
  },
  {
    "city": "Frankford",
    "city_ascii": "Frankford",
    "lat": 41.1615,
    "lng": -74.7381,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 5361,
    "id": 1840081786
  },
  {
    "city": "Noyemberyan",
    "city_ascii": "Noyemberyan",
    "lat": 41.1725,
    "lng": 44.9936,
    "country": "Armenia",
    "iso2": "AM",
    "iso3": "ARM",
    "admin_name": "Tavush",
    "capital": "",
    "population": 5310,
    "id": 1051943920
  },
  {
    "city": "East Moriches",
    "city_ascii": "East Moriches",
    "lat": 40.8097,
    "lng": -72.7581,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 5359,
    "id": 1840005038
  },
  {
    "city": "Ward",
    "city_ascii": "Ward",
    "lat": 35.0117,
    "lng": -91.9577,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arkansas",
    "capital": "",
    "population": 5358,
    "id": 1840015504
  },
  {
    "city": "Rio Pinar",
    "city_ascii": "Rio Pinar",
    "lat": 28.5271,
    "lng": -81.2628,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 5354,
    "id": 1840039091
  },
  {
    "city": "Englewood Cliffs",
    "city_ascii": "Englewood Cliffs",
    "lat": 40.8822,
    "lng": -73.9466,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 5354,
    "id": 1840003547
  },
  {
    "city": "Velká Bíteš",
    "city_ascii": "Velka Bites",
    "lat": 49.2887,
    "lng": 16.2259,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Vysočina",
    "capital": "",
    "population": 5243,
    "id": 1203698205
  },
  {
    "city": "Palmview South",
    "city_ascii": "Palmview South",
    "lat": 26.2164,
    "lng": -98.378,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 5352,
    "id": 1840073265
  },
  {
    "city": "Anthony",
    "city_ascii": "Anthony",
    "lat": 31.9876,
    "lng": -106.5933,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 5352,
    "id": 1840022138
  },
  {
    "city": "Dickinson",
    "city_ascii": "Dickinson",
    "lat": 40.1008,
    "lng": -77.2468,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5352,
    "id": 1840099045
  },
  {
    "city": "Doney Park",
    "city_ascii": "Doney Park",
    "lat": 35.2687,
    "lng": -111.5053,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 5352,
    "id": 1840075310
  },
  {
    "city": "De Soto",
    "city_ascii": "De Soto",
    "lat": 38.9686,
    "lng": -94.9548,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kansas",
    "capital": "",
    "population": 5352,
    "id": 1840001633
  },
  {
    "city": "Shepherdstown",
    "city_ascii": "Shepherdstown",
    "lat": 39.4318,
    "lng": -77.8048,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "West Virginia",
    "capital": "",
    "population": 5351,
    "id": 1840005761
  },
  {
    "city": "Turpin Hills",
    "city_ascii": "Turpin Hills",
    "lat": 39.1063,
    "lng": -84.3703,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 5351,
    "id": 1840034162
  },
  {
    "city": "North Oaks",
    "city_ascii": "North Oaks",
    "lat": 45.1002,
    "lng": -93.0882,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 5351,
    "id": 1840007842
  },
  {
    "city": "Creston",
    "city_ascii": "Creston",
    "lat": 49.09,
    "lng": -116.51,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "British Columbia",
    "capital": "",
    "population": 5351,
    "id": 1124204302
  },
  {
    "city": "Smithers",
    "city_ascii": "Smithers",
    "lat": 54.7819,
    "lng": -127.1681,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "British Columbia",
    "capital": "",
    "population": 5351,
    "id": 1124191574
  },
  {
    "city": "Bajram Curri",
    "city_ascii": "Bajram Curri",
    "lat": 42.3582,
    "lng": 20.076,
    "country": "Albania",
    "iso2": "AL",
    "iso3": "ALB",
    "admin_name": "Kukës",
    "capital": "",
    "population": 5340,
    "id": 1008251523
  },
  {
    "city": "Holýšov",
    "city_ascii": "Holysov",
    "lat": 49.5937,
    "lng": 13.1013,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Plzeňský Kraj",
    "capital": "",
    "population": 5173,
    "id": 1203400873
  },
  {
    "city": "Mattawa",
    "city_ascii": "Mattawa",
    "lat": 46.737,
    "lng": -119.904,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 5349,
    "id": 1840022292
  },
  {
    "city": "Bederkesa",
    "city_ascii": "Bederkesa",
    "lat": 53.6271,
    "lng": 8.8304,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 5227,
    "id": 1276174546
  },
  {
    "city": "Cornwall",
    "city_ascii": "Cornwall",
    "lat": 46.2407,
    "lng": -63.2009,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Prince Edward Island",
    "capital": "",
    "population": 5348,
    "id": 1124001245
  },
  {
    "city": "San Pedro de Atacama",
    "city_ascii": "San Pedro de Atacama",
    "lat": -22.9108,
    "lng": -68.2001,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Antofagasta",
    "capital": "",
    "population": 5347,
    "id": 1152557633
  },
  {
    "city": "Oshamambe",
    "city_ascii": "Oshamambe",
    "lat": 42.5136,
    "lng": 140.3804,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hokkaidō",
    "capital": "",
    "population": 5329,
    "id": 1392512191
  },
  {
    "city": "Travelers Rest",
    "city_ascii": "Travelers Rest",
    "lat": 34.9684,
    "lng": -82.4417,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 5346,
    "id": 1840015478
  },
  {
    "city": "Kyparissía",
    "city_ascii": "Kyparissia",
    "lat": 37.2333,
    "lng": 21.6667,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Peloponnísos",
    "capital": "minor",
    "population": 5131,
    "id": 1300616536
  },
  {
    "city": "Meadow Lake",
    "city_ascii": "Meadow Lake",
    "lat": 54.1242,
    "lng": -108.4358,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Saskatchewan",
    "capital": "",
    "population": 5344,
    "id": 1124434578
  },
  {
    "city": "Balatonlelle",
    "city_ascii": "Balatonlelle",
    "lat": 46.7822,
    "lng": 17.6757,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Somogy",
    "capital": "",
    "population": 5223,
    "id": 1348471733
  },
  {
    "city": "Velburg",
    "city_ascii": "Velburg",
    "lat": 49.2328,
    "lng": 11.6719,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 5312,
    "id": 1276907482
  },
  {
    "city": "Belle Fourche",
    "city_ascii": "Belle Fourche",
    "lat": 44.6642,
    "lng": -103.8564,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Dakota",
    "capital": "",
    "population": 5341,
    "id": 1840002127
  },
  {
    "city": "Ledeč nad Sázavou",
    "city_ascii": "Ledec nad Sazavou",
    "lat": 49.6952,
    "lng": 15.2778,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Vysočina",
    "capital": "",
    "population": 5043,
    "id": 1203867932
  },
  {
    "city": "Rupea",
    "city_ascii": "Rupea",
    "lat": 46.0389,
    "lng": 25.2225,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Braşov",
    "capital": "",
    "population": 5269,
    "id": 1642778921
  },
  {
    "city": "Spas-Klepiki",
    "city_ascii": "Spas-Klepiki",
    "lat": 55.1333,
    "lng": 40.1667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Ryazanskaya Oblast’",
    "capital": "",
    "population": 5331,
    "id": 1643019358
  },
  {
    "city": "West Hills",
    "city_ascii": "West Hills",
    "lat": 40.8198,
    "lng": -73.4339,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 5338,
    "id": 1840034031
  },
  {
    "city": "Lanark Highlands",
    "city_ascii": "Lanark Highlands",
    "lat": 45.088,
    "lng": -76.517,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 5338,
    "id": 1124000887
  },
  {
    "city": "Česká Skalice",
    "city_ascii": "Ceska Skalice",
    "lat": 50.3947,
    "lng": 16.0428,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Královéhradecký Kraj",
    "capital": "",
    "population": 5047,
    "id": 1203777632
  },
  {
    "city": "North Dansville",
    "city_ascii": "North Dansville",
    "lat": 42.5583,
    "lng": -77.6915,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 5337,
    "id": 1840087732
  },
  {
    "city": "Morganfield",
    "city_ascii": "Morganfield",
    "lat": 37.6869,
    "lng": -87.8876,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kentucky",
    "capital": "",
    "population": 5337,
    "id": 1840014342
  },
  {
    "city": "Palmerton",
    "city_ascii": "Palmerton",
    "lat": 40.8023,
    "lng": -75.616,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5336,
    "id": 1840003564
  },
  {
    "city": "Jonesboro",
    "city_ascii": "Jonesboro",
    "lat": 32.2348,
    "lng": -92.7098,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 5333,
    "id": 1840017001
  },
  {
    "city": "Terrujem",
    "city_ascii": "Terrujem",
    "lat": 38.8511,
    "lng": -9.3747,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Lisboa",
    "capital": "",
    "population": 5113,
    "id": 1620102041
  },
  {
    "city": "Fairview",
    "city_ascii": "Fairview",
    "lat": 41.732,
    "lng": -73.9139,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 5332,
    "id": 1840132690
  },
  {
    "city": "Exton",
    "city_ascii": "Exton",
    "lat": 40.0307,
    "lng": -75.6303,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5332,
    "id": 1840001421
  },
  {
    "city": "Tickhill",
    "city_ascii": "Tickhill",
    "lat": 53.4305,
    "lng": -1.1135,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Doncaster",
    "capital": "",
    "population": 5228,
    "id": 1826735727
  },
  {
    "city": "Puchuncaví",
    "city_ascii": "Puchuncavi",
    "lat": -32.7261,
    "lng": -71.4151,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Valparaíso",
    "capital": "",
    "population": 5271,
    "id": 1152345768
  },
  {
    "city": "East Port Orchard",
    "city_ascii": "East Port Orchard",
    "lat": 47.5193,
    "lng": -122.6183,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 5331,
    "id": 1840037838
  },
  {
    "city": "Lake Fenton",
    "city_ascii": "Lake Fenton",
    "lat": 42.8453,
    "lng": -83.7086,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 5331,
    "id": 1840004341
  },
  {
    "city": "Sackville",
    "city_ascii": "Sackville",
    "lat": 45.9,
    "lng": -64.3667,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "New Brunswick",
    "capital": "",
    "population": 5331,
    "id": 1124877244
  },
  {
    "city": "Salamanca",
    "city_ascii": "Salamanca",
    "lat": 42.1631,
    "lng": -78.7233,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 5330,
    "id": 1840000453
  },
  {
    "city": "Westernport",
    "city_ascii": "Westernport",
    "lat": 39.488,
    "lng": -79.0429,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 5330,
    "id": 1840005627
  },
  {
    "city": "Geoagiu",
    "city_ascii": "Geoagiu",
    "lat": 45.92,
    "lng": 23.2,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Hunedoara",
    "capital": "",
    "population": 5294,
    "id": 1642302096
  },
  {
    "city": "Mapire",
    "city_ascii": "Mapire",
    "lat": 7.7411,
    "lng": -64.7107,
    "country": "Venezuela",
    "iso2": "VE",
    "iso3": "VEN",
    "admin_name": "Anzoátegui",
    "capital": "minor",
    "population": 5326,
    "id": 1862784124
  },
  {
    "city": "Coco",
    "city_ascii": "Coco",
    "lat": 18.001,
    "lng": -66.2603,
    "country": "Puerto Rico",
    "iso2": "PR",
    "iso3": "PRI",
    "admin_name": "Puerto Rico",
    "capital": "",
    "population": 5328,
    "id": 1630023633
  },
  {
    "city": "Wake Village",
    "city_ascii": "Wake Village",
    "lat": 33.424,
    "lng": -94.1188,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 5327,
    "id": 1840021988
  },
  {
    "city": "Grand Falls",
    "city_ascii": "Grand Falls",
    "lat": 47.0344,
    "lng": -67.7394,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "New Brunswick",
    "capital": "",
    "population": 5326,
    "id": 1124904843
  },
  {
    "city": "Jefferson Township",
    "city_ascii": "Jefferson Township",
    "lat": 40.7803,
    "lng": -79.8304,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5325,
    "id": 1840147944
  },
  {
    "city": "Tiszalök",
    "city_ascii": "Tiszalok",
    "lat": 48.0197,
    "lng": 21.3778,
    "country": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "admin_name": "Szabolcs-Szatmár-Bereg",
    "capital": "",
    "population": 5234,
    "id": 1348623423
  },
  {
    "city": "Sterling",
    "city_ascii": "Sterling",
    "lat": 60.5405,
    "lng": -150.8089,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alaska",
    "capital": "",
    "population": 5321,
    "id": 1840023417
  },
  {
    "city": "Cochrane",
    "city_ascii": "Cochrane",
    "lat": 49.0667,
    "lng": -81.0167,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 5321,
    "id": 1124296729
  },
  {
    "city": "Cavalero",
    "city_ascii": "Cavalero",
    "lat": 47.9846,
    "lng": -122.0743,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 5320,
    "id": 1840041913
  },
  {
    "city": "Bilibino",
    "city_ascii": "Bilibino",
    "lat": 68.05,
    "lng": 166.45,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Chukotskiy Avtonomnyy Okrug",
    "capital": "",
    "population": 5319,
    "id": 1643015766
  },
  {
    "city": "Batesburg-Leesville",
    "city_ascii": "Batesburg-Leesville",
    "lat": 33.9125,
    "lng": -81.5313,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 5318,
    "id": 1840003900
  },
  {
    "city": "South Sarasota",
    "city_ascii": "South Sarasota",
    "lat": 27.2856,
    "lng": -82.5333,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 5316,
    "id": 1840029081
  },
  {
    "city": "Marystown",
    "city_ascii": "Marystown",
    "lat": 47.1667,
    "lng": -55.1667,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Newfoundland and Labrador",
    "capital": "",
    "population": 5316,
    "id": 1124408131
  },
  {
    "city": "Ocean Shores",
    "city_ascii": "Ocean Shores",
    "lat": -28.5092,
    "lng": 153.5375,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 5137,
    "id": 1036584832
  },
  {
    "city": "Uhrichsville",
    "city_ascii": "Uhrichsville",
    "lat": 40.4005,
    "lng": -81.3515,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 5314,
    "id": 1840010393
  },
  {
    "city": "Lehighton",
    "city_ascii": "Lehighton",
    "lat": 40.8306,
    "lng": -75.7166,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5313,
    "id": 1840000933
  },
  {
    "city": "Bandar-e Rīg",
    "city_ascii": "Bandar-e Rig",
    "lat": 29.4856,
    "lng": 50.6281,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Būshehr",
    "capital": "",
    "population": 5257,
    "id": 1364009238
  },
  {
    "city": "Hadley",
    "city_ascii": "Hadley",
    "lat": 42.3556,
    "lng": -72.5692,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 5310,
    "id": 1840053460
  },
  {
    "city": "Fruitland",
    "city_ascii": "Fruitland",
    "lat": 38.3214,
    "lng": -75.6246,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maryland",
    "capital": "",
    "population": 5309,
    "id": 1840006218
  },
  {
    "city": "Floral City",
    "city_ascii": "Floral City",
    "lat": 28.7065,
    "lng": -82.309,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 5308,
    "id": 1840014057
  },
  {
    "city": "Delhi Hills",
    "city_ascii": "Delhi Hills",
    "lat": 39.0871,
    "lng": -84.6178,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 5308,
    "id": 1840034107
  },
  {
    "city": "Jaffrey",
    "city_ascii": "Jaffrey",
    "lat": 42.8294,
    "lng": -72.0597,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Hampshire",
    "capital": "",
    "population": 5307,
    "id": 1840055042
  },
  {
    "city": "Wilton",
    "city_ascii": "Wilton",
    "lat": 38.413,
    "lng": -121.2127,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 5307,
    "id": 1840018848
  },
  {
    "city": "Savannah",
    "city_ascii": "Savannah",
    "lat": 39.9391,
    "lng": -94.8279,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 5307,
    "id": 1840009477
  },
  {
    "city": "Lampazos de Naranjo",
    "city_ascii": "Lampazos de Naranjo",
    "lat": 27.025,
    "lng": -100.5056,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Nuevo León",
    "capital": "minor",
    "population": 5305,
    "id": 1484271462
  },
  {
    "city": "Trhové Sviny",
    "city_ascii": "Trhove Sviny",
    "lat": 48.8424,
    "lng": 14.6392,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Jihočeský Kraj",
    "capital": "",
    "population": 5207,
    "id": 1203720832
  },
  {
    "city": "Union Beach",
    "city_ascii": "Union Beach",
    "lat": 40.4454,
    "lng": -74.1699,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 5305,
    "id": 1840001369
  },
  {
    "city": "Fairport",
    "city_ascii": "Fairport",
    "lat": 43.099,
    "lng": -77.4427,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 5305,
    "id": 1840004280
  },
  {
    "city": "Berri",
    "city_ascii": "Berri",
    "lat": -34.2833,
    "lng": 140.6,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "South Australia",
    "capital": "",
    "population": 4103,
    "id": 1036554717
  },
  {
    "city": "Hightstown",
    "city_ascii": "Hightstown",
    "lat": 40.2686,
    "lng": -74.5253,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 5304,
    "id": 1840003708
  },
  {
    "city": "Cameron",
    "city_ascii": "Cameron",
    "lat": 30.8608,
    "lng": -96.9762,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 5304,
    "id": 1840019562
  },
  {
    "city": "Suvorovo",
    "city_ascii": "Suvorovo",
    "lat": 43.33,
    "lng": 27.5925,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Varna",
    "capital": "",
    "population": 5214,
    "id": 1100221510
  },
  {
    "city": "Jílové",
    "city_ascii": "Jilove",
    "lat": 50.7609,
    "lng": 14.1039,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Ústecký Kraj",
    "capital": "",
    "population": 5162,
    "id": 1203832138
  },
  {
    "city": "Timber Pines",
    "city_ascii": "Timber Pines",
    "lat": 28.469,
    "lng": -82.5999,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 5303,
    "id": 1840029088
  },
  {
    "city": "Brent",
    "city_ascii": "Brent",
    "lat": 32.9421,
    "lng": -87.1753,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 5302,
    "id": 1840013781
  },
  {
    "city": "Henfield",
    "city_ascii": "Henfield",
    "lat": 50.933,
    "lng": -0.277,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "West Sussex",
    "capital": "",
    "population": 5012,
    "id": 1826261536
  },
  {
    "city": "Bulverde",
    "city_ascii": "Bulverde",
    "lat": 29.7744,
    "lng": -98.4364,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 5300,
    "id": 1840019628
  },
  {
    "city": "Centerville",
    "city_ascii": "Centerville",
    "lat": 40.7294,
    "lng": -92.8718,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Iowa",
    "capital": "",
    "population": 5299,
    "id": 1840001006
  },
  {
    "city": "Alton",
    "city_ascii": "Alton",
    "lat": 43.4906,
    "lng": -71.2462,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Hampshire",
    "capital": "",
    "population": 5298,
    "id": 1840054507
  },
  {
    "city": "Brookline",
    "city_ascii": "Brookline",
    "lat": 42.7464,
    "lng": -71.6705,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Hampshire",
    "capital": "",
    "population": 5298,
    "id": 1840054705
  },
  {
    "city": "Pottenstein",
    "city_ascii": "Pottenstein",
    "lat": 49.7722,
    "lng": 11.4114,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 5226,
    "id": 1276683481
  },
  {
    "city": "Williamson",
    "city_ascii": "Williamson",
    "lat": 34.7082,
    "lng": -112.5342,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 5297,
    "id": 1840019166
  },
  {
    "city": "Medina",
    "city_ascii": "Medina",
    "lat": 26.929,
    "lng": -99.2614,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 5296,
    "id": 1840136274
  },
  {
    "city": "Ochamchire",
    "city_ascii": "Ochamchire",
    "lat": 42.71,
    "lng": 41.47,
    "country": "Georgia",
    "iso2": "GE",
    "iso3": "GEO",
    "admin_name": "Abkhazia",
    "capital": "",
    "population": 5280,
    "id": 1268462696
  },
  {
    "city": "Waynesville",
    "city_ascii": "Waynesville",
    "lat": 37.8207,
    "lng": -92.22,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 5295,
    "id": 1840010846
  },
  {
    "city": "Mount Carmel",
    "city_ascii": "Mount Carmel",
    "lat": 36.562,
    "lng": -82.6618,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 5294,
    "id": 1840016025
  },
  {
    "city": "Borkum",
    "city_ascii": "Borkum",
    "lat": 53.5881,
    "lng": 6.6697,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 5125,
    "id": 1276473242
  },
  {
    "city": "Oettingen in Bayern",
    "city_ascii": "Oettingen in Bayern",
    "lat": 48.95,
    "lng": 10.5833,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 5142,
    "id": 1276373051
  },
  {
    "city": "Kings Point",
    "city_ascii": "Kings Point",
    "lat": 40.8162,
    "lng": -73.7407,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 5292,
    "id": 1840005299
  },
  {
    "city": "Benjamín Hill",
    "city_ascii": "Benjamin Hill",
    "lat": 30.1667,
    "lng": -111.1,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Sonora",
    "capital": "minor",
    "population": 5285,
    "id": 1484490786
  },
  {
    "city": "Orange",
    "city_ascii": "Orange",
    "lat": 38.2486,
    "lng": -78.1127,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 5291,
    "id": 1840006268
  },
  {
    "city": "Myrtletown",
    "city_ascii": "Myrtletown",
    "lat": 40.7888,
    "lng": -124.1286,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 5290,
    "id": 1840018716
  },
  {
    "city": "Vacha",
    "city_ascii": "Vacha",
    "lat": 50.8289,
    "lng": 10.0214,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Thuringia",
    "capital": "",
    "population": 5173,
    "id": 1276649759
  },
  {
    "city": "Ronneburg",
    "city_ascii": "Ronneburg",
    "lat": 50.8636,
    "lng": 12.1808,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Thuringia",
    "capital": "",
    "population": 5026,
    "id": 1276798153
  },
  {
    "city": "Lakehills",
    "city_ascii": "Lakehills",
    "lat": 29.6237,
    "lng": -98.9448,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 5288,
    "id": 1840018261
  },
  {
    "city": "Iola",
    "city_ascii": "Iola",
    "lat": 37.9274,
    "lng": -95.4006,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kansas",
    "capital": "",
    "population": 5288,
    "id": 1840001681
  },
  {
    "city": "Vila do Bispo",
    "city_ascii": "Vila do Bispo",
    "lat": 37.0825,
    "lng": -8.9119,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Faro",
    "capital": "minor",
    "population": 5258,
    "id": 1620734353
  },
  {
    "city": "Polná",
    "city_ascii": "Polna",
    "lat": 49.487,
    "lng": 15.7188,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Vysočina",
    "capital": "",
    "population": 5150,
    "id": 1203490581
  },
  {
    "city": "Avaí",
    "city_ascii": "Avai",
    "lat": -22.1467,
    "lng": -49.3331,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 5275,
    "id": 1076844658
  },
  {
    "city": "New Ipswich",
    "city_ascii": "New Ipswich",
    "lat": 42.7489,
    "lng": -71.8747,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Hampshire",
    "capital": "",
    "population": 5284,
    "id": 1840055249
  },
  {
    "city": "Wahrenbrück",
    "city_ascii": "Wahrenbruck",
    "lat": 51.55,
    "lng": 13.35,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Brandenburg",
    "capital": "",
    "population": 5245,
    "id": 1276561519
  },
  {
    "city": "Zephyrhills West",
    "city_ascii": "Zephyrhills West",
    "lat": 28.2311,
    "lng": -82.2052,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 5281,
    "id": 1840035801
  },
  {
    "city": "Chepelare",
    "city_ascii": "Chepelare",
    "lat": 41.7233,
    "lng": 24.6861,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Smolyan",
    "capital": "",
    "population": 5262,
    "id": 1100862358
  },
  {
    "city": "Midway",
    "city_ascii": "Midway",
    "lat": 40.5183,
    "lng": -111.4745,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Utah",
    "capital": "",
    "population": 5280,
    "id": 1840020165
  },
  {
    "city": "Titusville",
    "city_ascii": "Titusville",
    "lat": 41.6273,
    "lng": -79.6699,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5279,
    "id": 1840000562
  },
  {
    "city": "Big Bear Lake",
    "city_ascii": "Big Bear Lake",
    "lat": 34.2429,
    "lng": -116.8951,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 5279,
    "id": 1840019119
  },
  {
    "city": "St. Clair",
    "city_ascii": "St. Clair",
    "lat": 42.8262,
    "lng": -82.493,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 5279,
    "id": 1840003954
  },
  {
    "city": "Grayson",
    "city_ascii": "Grayson",
    "lat": 38.3317,
    "lng": -82.9371,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kentucky",
    "capital": "",
    "population": 5277,
    "id": 1840013196
  },
  {
    "city": "Chabana",
    "city_ascii": "Chabana",
    "lat": 27.0486,
    "lng": 128.4147,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Kagoshima",
    "capital": "",
    "population": 5032,
    "id": 1392090601
  },
  {
    "city": "Cave Springs",
    "city_ascii": "Cave Springs",
    "lat": 36.2701,
    "lng": -94.2225,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arkansas",
    "capital": "",
    "population": 5276,
    "id": 1840014440
  },
  {
    "city": "Samsula-Spruce Creek",
    "city_ascii": "Samsula-Spruce Creek",
    "lat": 29.0484,
    "lng": -81.0628,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 5272,
    "id": 1840035478
  },
  {
    "city": "Pontotoc",
    "city_ascii": "Pontotoc",
    "lat": 34.2532,
    "lng": -89.0091,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Mississippi",
    "capital": "",
    "population": 5272,
    "id": 1840014705
  },
  {
    "city": "Sioux Lookout",
    "city_ascii": "Sioux Lookout",
    "lat": 50.1,
    "lng": -91.9167,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 5272,
    "id": 1124804545
  },
  {
    "city": "Sevelen",
    "city_ascii": "Sevelen",
    "lat": 47.1204,
    "lng": 9.4857,
    "country": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "admin_name": "Sankt Gallen",
    "capital": "",
    "population": 5103,
    "id": 1756193090
  },
  {
    "city": "Arzberg",
    "city_ascii": "Arzberg",
    "lat": 50.0667,
    "lng": 12.1833,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 5152,
    "id": 1276883944
  },
  {
    "city": "West Yarmouth",
    "city_ascii": "West Yarmouth",
    "lat": 41.6496,
    "lng": -70.2487,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 5271,
    "id": 1840003246
  },
  {
    "city": "Forsyth",
    "city_ascii": "Forsyth",
    "lat": 33.0347,
    "lng": -83.9381,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 5270,
    "id": 1840013784
  },
  {
    "city": "Didsbury",
    "city_ascii": "Didsbury",
    "lat": 51.6658,
    "lng": -114.1311,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Alberta",
    "capital": "",
    "population": 5268,
    "id": 1124574154
  },
  {
    "city": "Shibetsu",
    "city_ascii": "Shibetsu",
    "lat": 43.6614,
    "lng": 145.1314,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hokkaidō",
    "capital": "",
    "population": 5259,
    "id": 1392017464
  },
  {
    "city": "Rothschild",
    "city_ascii": "Rothschild",
    "lat": 44.8761,
    "lng": -89.6173,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 5267,
    "id": 1840002172
  },
  {
    "city": "Wind Lake",
    "city_ascii": "Wind Lake",
    "lat": 42.823,
    "lng": -88.1573,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 5266,
    "id": 1840006921
  },
  {
    "city": "Carroll Township",
    "city_ascii": "Carroll Township",
    "lat": 40.3364,
    "lng": -77.1735,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5265,
    "id": 1840152434
  },
  {
    "city": "West Rockhill",
    "city_ascii": "West Rockhill",
    "lat": 40.3686,
    "lng": -75.3489,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5263,
    "id": 1840150056
  },
  {
    "city": "Mena",
    "city_ascii": "Mena",
    "lat": 34.5812,
    "lng": -94.2369,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arkansas",
    "capital": "",
    "population": 5261,
    "id": 1840015549
  },
  {
    "city": "Grandwood Park",
    "city_ascii": "Grandwood Park",
    "lat": 42.3929,
    "lng": -87.9871,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 5261,
    "id": 1840004639
  },
  {
    "city": "Néa Anchiálos",
    "city_ascii": "Nea Anchialos",
    "lat": 39.2667,
    "lng": 22.8167,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Thessalía",
    "capital": "",
    "population": 5132,
    "id": 1300012024
  },
  {
    "city": "Rottenmann",
    "city_ascii": "Rottenmann",
    "lat": 47.5267,
    "lng": 14.3558,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Steiermark",
    "capital": "",
    "population": 5232,
    "id": 1040405543
  },
  {
    "city": "Kaneyama",
    "city_ascii": "Kaneyama",
    "lat": 38.8833,
    "lng": 140.3394,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Yamagata",
    "capital": "",
    "population": 5225,
    "id": 1392255901
  },
  {
    "city": "West Vincent",
    "city_ascii": "West Vincent",
    "lat": 40.1256,
    "lng": -75.6477,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5257,
    "id": 1840146656
  },
  {
    "city": "Howard Springs",
    "city_ascii": "Howard Springs",
    "lat": -12.4922,
    "lng": 131.053,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Northern Territory",
    "capital": "",
    "population": 5132,
    "id": 1036674070
  },
  {
    "city": "East Granby",
    "city_ascii": "East Granby",
    "lat": 41.9424,
    "lng": -72.741,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 5256,
    "id": 1840034586
  },
  {
    "city": "Doksy",
    "city_ascii": "Doksy",
    "lat": 50.5648,
    "lng": 14.6556,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Liberecký Kraj",
    "capital": "",
    "population": 5186,
    "id": 1203586093
  },
  {
    "city": "Holdrege",
    "city_ascii": "Holdrege",
    "lat": 40.4395,
    "lng": -99.3773,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Nebraska",
    "capital": "",
    "population": 5255,
    "id": 1840001131
  },
  {
    "city": "Giddings",
    "city_ascii": "Giddings",
    "lat": 30.1833,
    "lng": -96.9279,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 5254,
    "id": 1840020895
  },
  {
    "city": "Wadesboro",
    "city_ascii": "Wadesboro",
    "lat": 34.9645,
    "lng": -80.0746,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 5254,
    "id": 1840016450
  },
  {
    "city": "Houffalize",
    "city_ascii": "Houffalize",
    "lat": 50.132,
    "lng": 5.7894,
    "country": "Belgium",
    "iso2": "BE",
    "iso3": "BEL",
    "admin_name": "Wallonia",
    "capital": "",
    "population": 5222,
    "id": 1056443256
  },
  {
    "city": "Pohořelice",
    "city_ascii": "Pohorelice",
    "lat": 48.9812,
    "lng": 16.5245,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Jihomoravský Kraj",
    "capital": "",
    "population": 5133,
    "id": 1203487900
  },
  {
    "city": "Abrud",
    "city_ascii": "Abrud",
    "lat": 46.2739,
    "lng": 23.05,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Alba",
    "capital": "",
    "population": 5072,
    "id": 1642576907
  },
  {
    "city": "Venus",
    "city_ascii": "Venus",
    "lat": 32.4312,
    "lng": -97.102,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 5252,
    "id": 1840023050
  },
  {
    "city": "Bechyně",
    "city_ascii": "Bechyne",
    "lat": 49.2953,
    "lng": 14.4681,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Jihočeský Kraj",
    "capital": "",
    "population": 5016,
    "id": 1203303764
  },
  {
    "city": "Centerport",
    "city_ascii": "Centerport",
    "lat": 40.8943,
    "lng": -73.3714,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 5251,
    "id": 1840005027
  },
  {
    "city": "Lagodekhi",
    "city_ascii": "Lagodekhi",
    "lat": 41.8167,
    "lng": 46.2833,
    "country": "Georgia",
    "iso2": "GE",
    "iso3": "GEO",
    "admin_name": "K’akheti",
    "capital": "minor",
    "population": 5198,
    "id": 1268532082
  },
  {
    "city": "Orchomenós",
    "city_ascii": "Orchomenos",
    "lat": 38.4933,
    "lng": 22.9749,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Stereá Elláda",
    "capital": "minor",
    "population": 5238,
    "id": 1300927153
  },
  {
    "city": "Eagleton Village",
    "city_ascii": "Eagleton Village",
    "lat": 35.7885,
    "lng": -83.9363,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 5250,
    "id": 1840013068
  },
  {
    "city": "Pawcatuck",
    "city_ascii": "Pawcatuck",
    "lat": 41.3774,
    "lng": -71.8492,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 5249,
    "id": 1840003353
  },
  {
    "city": "Fernie",
    "city_ascii": "Fernie",
    "lat": 49.5042,
    "lng": -115.0628,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "British Columbia",
    "capital": "",
    "population": 5249,
    "id": 1124927114
  },
  {
    "city": "Deer Lake",
    "city_ascii": "Deer Lake",
    "lat": 49.1744,
    "lng": -57.4269,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Newfoundland and Labrador",
    "capital": "",
    "population": 5249,
    "id": 1124841556
  },
  {
    "city": "Perry",
    "city_ascii": "Perry",
    "lat": 41.4648,
    "lng": -112.0401,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Utah",
    "capital": "",
    "population": 5248,
    "id": 1840020119
  },
  {
    "city": "Calistoga",
    "city_ascii": "Calistoga",
    "lat": 38.5818,
    "lng": -122.5824,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 5247,
    "id": 1840018835
  },
  {
    "city": "Perushtitsa",
    "city_ascii": "Perushtitsa",
    "lat": 42.0561,
    "lng": 24.5444,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Plovdiv",
    "capital": "",
    "population": 5162,
    "id": 1100745982
  },
  {
    "city": "McGuire AFB",
    "city_ascii": "McGuire AFB",
    "lat": 40.0285,
    "lng": -74.5883,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 5246,
    "id": 1840073621
  },
  {
    "city": "Holmfirth",
    "city_ascii": "Holmfirth",
    "lat": 53.57,
    "lng": -1.787,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Kirklees",
    "capital": "",
    "population": 5173,
    "id": 1826025811
  },
  {
    "city": "Walkertown",
    "city_ascii": "Walkertown",
    "lat": 36.1578,
    "lng": -80.1642,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 5242,
    "id": 1840016125
  },
  {
    "city": "North Stonington",
    "city_ascii": "North Stonington",
    "lat": 41.4697,
    "lng": -71.8755,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 5242,
    "id": 1840035516
  },
  {
    "city": "Gbely",
    "city_ascii": "Gbely",
    "lat": 48.7175,
    "lng": 17.115,
    "country": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "admin_name": "Trnavský",
    "capital": "",
    "population": 5155,
    "id": 1703229235
  },
  {
    "city": "Ranson",
    "city_ascii": "Ranson",
    "lat": 39.325,
    "lng": -77.8666,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "West Virginia",
    "capital": "",
    "population": 5239,
    "id": 1840038308
  },
  {
    "city": "Gouvy",
    "city_ascii": "Gouvy",
    "lat": 50.1874,
    "lng": 5.9449,
    "country": "Belgium",
    "iso2": "BE",
    "iso3": "BEL",
    "admin_name": "Wallonia",
    "capital": "",
    "population": 5206,
    "id": 1056928691
  },
  {
    "city": "Bolton",
    "city_ascii": "Bolton",
    "lat": 42.4362,
    "lng": -71.6073,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 5236,
    "id": 1840053582
  },
  {
    "city": "Salem",
    "city_ascii": "Salem",
    "lat": 39.5681,
    "lng": -75.4724,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 5235,
    "id": 1840001529
  },
  {
    "city": "Wrightsboro",
    "city_ascii": "Wrightsboro",
    "lat": 34.2895,
    "lng": -77.9217,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 5233,
    "id": 1840013630
  },
  {
    "city": "Buje",
    "city_ascii": "Buje",
    "lat": 45.4,
    "lng": 13.65,
    "country": "Croatia",
    "iso2": "HR",
    "iso3": "HRV",
    "admin_name": "Istarska Županija",
    "capital": "minor",
    "population": 5182,
    "id": 1191618049
  },
  {
    "city": "Conklin",
    "city_ascii": "Conklin",
    "lat": 42.0399,
    "lng": -75.8342,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 5232,
    "id": 1840058013
  },
  {
    "city": "McGregor",
    "city_ascii": "McGregor",
    "lat": 31.4187,
    "lng": -97.4283,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 5231,
    "id": 1840020816
  },
  {
    "city": "Winterset",
    "city_ascii": "Winterset",
    "lat": 41.3457,
    "lng": -94.0137,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Iowa",
    "capital": "",
    "population": 5231,
    "id": 1840000649
  },
  {
    "city": "Greene",
    "city_ascii": "Greene",
    "lat": 42.3187,
    "lng": -75.7692,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 5230,
    "id": 1840004508
  },
  {
    "city": "Walton",
    "city_ascii": "Walton",
    "lat": 42.1741,
    "lng": -75.1273,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 5229,
    "id": 1840004623
  },
  {
    "city": "Naganohara",
    "city_ascii": "Naganohara",
    "lat": 36.5522,
    "lng": 138.6375,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Gunma",
    "capital": "",
    "population": 5190,
    "id": 1392960848
  },
  {
    "city": "Bangor",
    "city_ascii": "Bangor",
    "lat": 40.8678,
    "lng": -75.2085,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5228,
    "id": 1840000966
  },
  {
    "city": "Woodstock",
    "city_ascii": "Woodstock",
    "lat": 46.1522,
    "lng": -67.5983,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "New Brunswick",
    "capital": "",
    "population": 5228,
    "id": 1124035280
  },
  {
    "city": "Imagane",
    "city_ascii": "Imagane",
    "lat": 42.4294,
    "lng": 140.0086,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hokkaidō",
    "capital": "",
    "population": 5218,
    "id": 1392052641
  },
  {
    "city": "Hollywood",
    "city_ascii": "Hollywood",
    "lat": 32.7523,
    "lng": -80.2106,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 5227,
    "id": 1840017281
  },
  {
    "city": "Ballston Spa",
    "city_ascii": "Ballston Spa",
    "lat": 43.0068,
    "lng": -73.8525,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 5226,
    "id": 1840004254
  },
  {
    "city": "Byron",
    "city_ascii": "Byron",
    "lat": 32.6474,
    "lng": -83.7541,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 5226,
    "id": 1840013820
  },
  {
    "city": "Kalaheo",
    "city_ascii": "Kalaheo",
    "lat": 21.9159,
    "lng": -159.5236,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Hawaii",
    "capital": "",
    "population": 5224,
    "id": 1840029492
  },
  {
    "city": "Springfield",
    "city_ascii": "Springfield",
    "lat": 32.3634,
    "lng": -81.3029,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 5224,
    "id": 1840015803
  },
  {
    "city": "Chisago City",
    "city_ascii": "Chisago City",
    "lat": 45.3474,
    "lng": -92.9116,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 5224,
    "id": 1840000269
  },
  {
    "city": "Union City",
    "city_ascii": "Union City",
    "lat": 40.1995,
    "lng": -84.8206,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 5223,
    "id": 1840010480
  },
  {
    "city": "Chiny",
    "city_ascii": "Chiny",
    "lat": 49.7383,
    "lng": 5.3433,
    "country": "Belgium",
    "iso2": "BE",
    "iso3": "BEL",
    "admin_name": "Wallonia",
    "capital": "",
    "population": 5175,
    "id": 1056542075
  },
  {
    "city": "Gilmer",
    "city_ascii": "Gilmer",
    "lat": 32.7317,
    "lng": -94.946,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 5220,
    "id": 1840020731
  },
  {
    "city": "Williamsville",
    "city_ascii": "Williamsville",
    "lat": 42.9623,
    "lng": -78.7418,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 5220,
    "id": 1840004392
  },
  {
    "city": "Bedford",
    "city_ascii": "Bedford",
    "lat": 40.0456,
    "lng": -78.4998,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5220,
    "id": 1840001396
  },
  {
    "city": "Kelly",
    "city_ascii": "Kelly",
    "lat": 40.9984,
    "lng": -76.9245,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5219,
    "id": 1840149929
  },
  {
    "city": "Jinzhong",
    "city_ascii": "Jinzhong",
    "lat": 26.3504,
    "lng": 103.4167,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Yunnan",
    "capital": "minor",
    "population": 5170,
    "id": 1156252749
  },
  {
    "city": "Newfield",
    "city_ascii": "Newfield",
    "lat": 42.3377,
    "lng": -76.613,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 5218,
    "id": 1840058340
  },
  {
    "city": "Vereya",
    "city_ascii": "Vereya",
    "lat": 55.3333,
    "lng": 36.1833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Moskovskaya Oblast’",
    "capital": "",
    "population": 5123,
    "id": 1643983279
  },
  {
    "city": "Martic",
    "city_ascii": "Martic",
    "lat": 39.8721,
    "lng": -76.3144,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5217,
    "id": 1840147752
  },
  {
    "city": "Elmsford",
    "city_ascii": "Elmsford",
    "lat": 41.0541,
    "lng": -73.8143,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 5216,
    "id": 1840004953
  },
  {
    "city": "Center",
    "city_ascii": "Center",
    "lat": 31.793,
    "lng": -94.1796,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 5215,
    "id": 1840019528
  },
  {
    "city": "Clarinda",
    "city_ascii": "Clarinda",
    "lat": 40.738,
    "lng": -95.034,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Iowa",
    "capital": "",
    "population": 5215,
    "id": 1840007180
  },
  {
    "city": "Hutchinson Island South",
    "city_ascii": "Hutchinson Island South",
    "lat": 27.3243,
    "lng": -80.2425,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 5214,
    "id": 1840073842
  },
  {
    "city": "Quantico Base",
    "city_ascii": "Quantico Base",
    "lat": 38.5228,
    "lng": -77.3187,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 5213,
    "id": 1840073661
  },
  {
    "city": "Luhačovice",
    "city_ascii": "Luhacovice",
    "lat": 49.0998,
    "lng": 17.7575,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Zlínský Kraj",
    "capital": "",
    "population": 5059,
    "id": 1203520041
  },
  {
    "city": "Amöneburg",
    "city_ascii": "Amoneburg",
    "lat": 50.7978,
    "lng": 8.9231,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 5096,
    "id": 1276637668
  },
  {
    "city": "Maine",
    "city_ascii": "Maine",
    "lat": 42.2,
    "lng": -76.0314,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 5212,
    "id": 1840058278
  },
  {
    "city": "Lake Montezuma",
    "city_ascii": "Lake Montezuma",
    "lat": 34.6414,
    "lng": -111.796,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 5212,
    "id": 1840017849
  },
  {
    "city": "Londonderry Township",
    "city_ascii": "Londonderry Township",
    "lat": 40.1814,
    "lng": -76.6964,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5211,
    "id": 1840152636
  },
  {
    "city": "Warren",
    "city_ascii": "Warren",
    "lat": 42.2002,
    "lng": -72.2006,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 5210,
    "id": 1840017612
  },
  {
    "city": "Val-David",
    "city_ascii": "Val-David",
    "lat": 46.03,
    "lng": -74.22,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 5209,
    "id": 1124707666
  },
  {
    "city": "Röbel",
    "city_ascii": "Robel",
    "lat": 53.3761,
    "lng": 12.6061,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Mecklenburg-Western Pomerania",
    "capital": "",
    "population": 5044,
    "id": 1276548429
  },
  {
    "city": "Tichigan",
    "city_ascii": "Tichigan",
    "lat": 42.8087,
    "lng": -88.215,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 5208,
    "id": 1840006920
  },
  {
    "city": "Lake Wildwood",
    "city_ascii": "Lake Wildwood",
    "lat": 39.235,
    "lng": -121.2003,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 5208,
    "id": 1840028313
  },
  {
    "city": "Wenham",
    "city_ascii": "Wenham",
    "lat": 42.6008,
    "lng": -70.8826,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 5208,
    "id": 1840053671
  },
  {
    "city": "Montrose",
    "city_ascii": "Montrose",
    "lat": 45.0668,
    "lng": -93.9206,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 5208,
    "id": 1840007796
  },
  {
    "city": "Roseburg North",
    "city_ascii": "Roseburg North",
    "lat": 43.2653,
    "lng": -123.3025,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oregon",
    "capital": "",
    "population": 5207,
    "id": 1840074590
  },
  {
    "city": "Vila Nova de Paiva",
    "city_ascii": "Vila Nova de Paiva",
    "lat": 40.85,
    "lng": -7.7333,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Viseu",
    "capital": "minor",
    "population": 5176,
    "id": 1620015183
  },
  {
    "city": "Ravena",
    "city_ascii": "Ravena",
    "lat": 42.4755,
    "lng": -73.8113,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 5202,
    "id": 1840004492
  },
  {
    "city": "Tannum Sands",
    "city_ascii": "Tannum Sands",
    "lat": -23.9474,
    "lng": 151.3675,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Queensland",
    "capital": "",
    "population": 5145,
    "id": 1036139879
  },
  {
    "city": "South Bay",
    "city_ascii": "South Bay",
    "lat": 26.677,
    "lng": -80.7265,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 5200,
    "id": 1840015992
  },
  {
    "city": "Gardnertown",
    "city_ascii": "Gardnertown",
    "lat": 41.5328,
    "lng": -74.0594,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 5200,
    "id": 1840004861
  },
  {
    "city": "Thatcher",
    "city_ascii": "Thatcher",
    "lat": 32.832,
    "lng": -109.7595,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 5200,
    "id": 1840022955
  },
  {
    "city": "Hofheim in Unterfranken",
    "city_ascii": "Hofheim in Unterfranken",
    "lat": 50.1333,
    "lng": 10.5167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 5109,
    "id": 1276166493
  },
  {
    "city": "Springfield",
    "city_ascii": "Springfield",
    "lat": 42.3246,
    "lng": -85.2371,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 5198,
    "id": 1840003174
  },
  {
    "city": "Llangefni",
    "city_ascii": "Llangefni",
    "lat": 53.256,
    "lng": -4.314,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Isle of Anglesey",
    "capital": "",
    "population": 5116,
    "id": 1826557923
  },
  {
    "city": "West Wendover",
    "city_ascii": "West Wendover",
    "lat": 40.7407,
    "lng": -114.0783,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Nevada",
    "capital": "",
    "population": 5197,
    "id": 1840021336
  },
  {
    "city": "Bethel Township",
    "city_ascii": "Bethel Township",
    "lat": 40.4485,
    "lng": -76.42,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5196,
    "id": 1840152892
  },
  {
    "city": "Obetz",
    "city_ascii": "Obetz",
    "lat": 39.8671,
    "lng": -82.9451,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 5196,
    "id": 1840012408
  },
  {
    "city": "Upper Yoder",
    "city_ascii": "Upper Yoder",
    "lat": 40.2995,
    "lng": -78.9907,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5195,
    "id": 1840143649
  },
  {
    "city": "Chipping Sodbury",
    "city_ascii": "Chipping Sodbury",
    "lat": 51.538,
    "lng": -2.394,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "South Gloucestershire",
    "capital": "",
    "population": 5045,
    "id": 1826082781
  },
  {
    "city": "Bisbee",
    "city_ascii": "Bisbee",
    "lat": 31.4126,
    "lng": -109.9179,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 5193,
    "id": 1840019495
  },
  {
    "city": "River Ridge",
    "city_ascii": "River Ridge",
    "lat": 28.2669,
    "lng": -82.6257,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 5191,
    "id": 1840039098
  },
  {
    "city": "Hampden",
    "city_ascii": "Hampden",
    "lat": 42.0638,
    "lng": -72.4157,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 5191,
    "id": 1840053446
  },
  {
    "city": "Hampshire",
    "city_ascii": "Hampshire",
    "lat": 42.1124,
    "lng": -88.5122,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 5190,
    "id": 1840011343
  },
  {
    "city": "Ocean Bluff-Brant Rock",
    "city_ascii": "Ocean Bluff-Brant Rock",
    "lat": 42.1006,
    "lng": -70.6625,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 5190,
    "id": 1840073527
  },
  {
    "city": "Forchtenberg",
    "city_ascii": "Forchtenberg",
    "lat": 49.2833,
    "lng": 9.5667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 5057,
    "id": 1276130592
  },
  {
    "city": "Skuteč",
    "city_ascii": "Skutec",
    "lat": 49.8435,
    "lng": 15.9966,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Pardubický Kraj",
    "capital": "",
    "population": 5047,
    "id": 1203632121
  },
  {
    "city": "Sawmills",
    "city_ascii": "Sawmills",
    "lat": 35.8162,
    "lng": -81.4779,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 5189,
    "id": 1840017755
  },
  {
    "city": "Washington Township",
    "city_ascii": "Washington Township",
    "lat": 40.8467,
    "lng": -75.1972,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5188,
    "id": 1840150356
  },
  {
    "city": "Attica",
    "city_ascii": "Attica",
    "lat": 40.2874,
    "lng": -87.2452,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 5187,
    "id": 1840007244
  },
  {
    "city": "Funagata",
    "city_ascii": "Funagata",
    "lat": 38.6917,
    "lng": 140.32,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Yamagata",
    "capital": "",
    "population": 5143,
    "id": 1392068234
  },
  {
    "city": "Sarmiento",
    "city_ascii": "Sarmiento",
    "lat": -45.6,
    "lng": -69.0833,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Chubut",
    "capital": "minor",
    "population": 5185,
    "id": 1032479340
  },
  {
    "city": "Thiells",
    "city_ascii": "Thiells",
    "lat": 41.2067,
    "lng": -74.0122,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 5186,
    "id": 1840004970
  },
  {
    "city": "Carlisle",
    "city_ascii": "Carlisle",
    "lat": 42.53,
    "lng": -71.3513,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 5186,
    "id": 1840053634
  },
  {
    "city": "Pound Ridge",
    "city_ascii": "Pound Ridge",
    "lat": 41.2115,
    "lng": -73.5747,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 5185,
    "id": 1840058421
  },
  {
    "city": "Flin Flon",
    "city_ascii": "Flin Flon",
    "lat": 54.7667,
    "lng": -101.8778,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Saskatchewan",
    "capital": "",
    "population": 5185,
    "id": 1124500458
  },
  {
    "city": "Külsheim",
    "city_ascii": "Kulsheim",
    "lat": 49.6694,
    "lng": 9.5206,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Baden-Württemberg",
    "capital": "",
    "population": 5122,
    "id": 1276739235
  },
  {
    "city": "Ghent",
    "city_ascii": "Ghent",
    "lat": 42.312,
    "lng": -73.6509,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 5184,
    "id": 1840058126
  },
  {
    "city": "Mount Olive",
    "city_ascii": "Mount Olive",
    "lat": 35.1997,
    "lng": -78.0662,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 5183,
    "id": 1840016321
  },
  {
    "city": "Algona",
    "city_ascii": "Algona",
    "lat": 43.0743,
    "lng": -94.2302,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Iowa",
    "capital": "",
    "population": 5182,
    "id": 1840000352
  },
  {
    "city": "Salmourão",
    "city_ascii": "Salmourao",
    "lat": -21.6239,
    "lng": -50.8608,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 5152,
    "id": 1076875320
  },
  {
    "city": "Firthcliffe",
    "city_ascii": "Firthcliffe",
    "lat": 41.4409,
    "lng": -74.0353,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 5178,
    "id": 1840004854
  },
  {
    "city": "Parker",
    "city_ascii": "Parker",
    "lat": 33.057,
    "lng": -96.6248,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 5177,
    "id": 1840020661
  },
  {
    "city": "Abūzeydābād",
    "city_ascii": "Abuzeydabad",
    "lat": 33.9042,
    "lng": 51.7686,
    "country": "Iran",
    "iso2": "IR",
    "iso3": "IRN",
    "admin_name": "Eşfahān",
    "capital": "",
    "population": 5160,
    "id": 1364675175
  },
  {
    "city": "Švenčionėliai",
    "city_ascii": "Svencioneliai",
    "lat": 55.1667,
    "lng": 26,
    "country": "Lithuania",
    "iso2": "LT",
    "iso3": "LTU",
    "admin_name": "Švenčionys",
    "capital": "",
    "population": 5160,
    "id": 1440431932
  },
  {
    "city": "Collegeville",
    "city_ascii": "Collegeville",
    "lat": 40.1873,
    "lng": -75.4581,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5174,
    "id": 1840003696
  },
  {
    "city": "Richmond",
    "city_ascii": "Richmond",
    "lat": 39.2755,
    "lng": -93.9731,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 5174,
    "id": 1840009600
  },
  {
    "city": "Zlatitsa",
    "city_ascii": "Zlatitsa",
    "lat": 42.715,
    "lng": 24.1389,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Sofia",
    "capital": "",
    "population": 5139,
    "id": 1100696383
  },
  {
    "city": "North Salem",
    "city_ascii": "North Salem",
    "lat": 41.333,
    "lng": -73.6042,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 5172,
    "id": 1840024004
  },
  {
    "city": "Creve Coeur",
    "city_ascii": "Creve Coeur",
    "lat": 40.6425,
    "lng": -89.5983,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 5169,
    "id": 1840011987
  },
  {
    "city": "Morris",
    "city_ascii": "Morris",
    "lat": 45.5856,
    "lng": -95.9048,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 5169,
    "id": 1840007786
  },
  {
    "city": "Sângeorgiu de Pădure",
    "city_ascii": "Sangeorgiu de Padure",
    "lat": 46.4303,
    "lng": 24.8417,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Mureş",
    "capital": "",
    "population": 5166,
    "id": 1642787886
  },
  {
    "city": "Monheim",
    "city_ascii": "Monheim",
    "lat": 48.8422,
    "lng": 10.8564,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 5093,
    "id": 1276076523
  },
  {
    "city": "Hudson",
    "city_ascii": "Hudson",
    "lat": 45.45,
    "lng": -74.15,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 5165,
    "id": 1124590540
  },
  {
    "city": "Cold Spring Harbor",
    "city_ascii": "Cold Spring Harbor",
    "lat": 40.8608,
    "lng": -73.4488,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 5164,
    "id": 1840005029
  },
  {
    "city": "Eidaichō",
    "city_ascii": "Eidaicho",
    "lat": 44.0178,
    "lng": 143.7747,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hokkaidō",
    "capital": "",
    "population": 5151,
    "id": 1392561023
  },
  {
    "city": "Maplewood",
    "city_ascii": "Maplewood",
    "lat": 47.3716,
    "lng": -122.5689,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 5163,
    "id": 1840037569
  },
  {
    "city": "Zephyrhills South",
    "city_ascii": "Zephyrhills South",
    "lat": 28.2152,
    "lng": -82.1886,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 5163,
    "id": 1840073858
  },
  {
    "city": "Victor Harbor",
    "city_ascii": "Victor Harbor",
    "lat": -35.55,
    "lng": 138.6167,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "South Australia",
    "capital": "",
    "population": 4233,
    "id": 1036536547
  },
  {
    "city": "Lake City",
    "city_ascii": "Lake City",
    "lat": 44.4453,
    "lng": -92.2796,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 5162,
    "id": 1840000321
  },
  {
    "city": "Three Points",
    "city_ascii": "Three Points",
    "lat": 32.0596,
    "lng": -111.2866,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 5161,
    "id": 1840019486
  },
  {
    "city": "Erbendorf",
    "city_ascii": "Erbendorf",
    "lat": 49.8333,
    "lng": 12.05,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 5085,
    "id": 1276136399
  },
  {
    "city": "Ettrick",
    "city_ascii": "Ettrick",
    "lat": 37.2435,
    "lng": -77.4287,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 5159,
    "id": 1840001699
  },
  {
    "city": "South Haven",
    "city_ascii": "South Haven",
    "lat": 41.5438,
    "lng": -87.1367,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 5159,
    "id": 1840004834
  },
  {
    "city": "Gananoque",
    "city_ascii": "Gananoque",
    "lat": 44.33,
    "lng": -76.17,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 5159,
    "id": 1124349596
  },
  {
    "city": "Holland",
    "city_ascii": "Holland",
    "lat": 40.5966,
    "lng": -75.1221,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 5157,
    "id": 1840081715
  },
  {
    "city": "Erwin",
    "city_ascii": "Erwin",
    "lat": 35.3226,
    "lng": -78.6734,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 5156,
    "id": 1840016328
  },
  {
    "city": "Grosse Pointe",
    "city_ascii": "Grosse Pointe",
    "lat": 42.3915,
    "lng": -82.9118,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 5154,
    "id": 1840003976
  },
  {
    "city": "Schwaan",
    "city_ascii": "Schwaan",
    "lat": 53.9333,
    "lng": 12.1,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Mecklenburg-Western Pomerania",
    "capital": "",
    "population": 5022,
    "id": 1276820294
  },
  {
    "city": "Broadalbin",
    "city_ascii": "Broadalbin",
    "lat": 43.0764,
    "lng": -74.1574,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 5153,
    "id": 1840004311
  },
  {
    "city": "Grambling",
    "city_ascii": "Grambling",
    "lat": 32.5276,
    "lng": -92.7124,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 5150,
    "id": 1840013807
  },
  {
    "city": "Lake Township",
    "city_ascii": "Lake Township",
    "lat": 41.4549,
    "lng": -75.3754,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5150,
    "id": 1840150110
  },
  {
    "city": "Warm Mineral Springs",
    "city_ascii": "Warm Mineral Springs",
    "lat": 27.0469,
    "lng": -82.2702,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 5149,
    "id": 1840014183
  },
  {
    "city": "San Dionisio del Mar",
    "city_ascii": "San Dionisio del Mar",
    "lat": 16.3217,
    "lng": -94.7569,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Oaxaca",
    "capital": "",
    "population": 5127,
    "id": 1484014548
  },
  {
    "city": "Milişăuţi",
    "city_ascii": "Milisauti",
    "lat": 47.7864,
    "lng": 26.0044,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Suceava",
    "capital": "",
    "population": 5005,
    "id": 1642292408
  },
  {
    "city": "Krasnyy Kholm",
    "city_ascii": "Krasnyy Kholm",
    "lat": 58.05,
    "lng": 37.0167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Tverskaya Oblast’",
    "capital": "",
    "population": 5140,
    "id": 1643698781
  },
  {
    "city": "Lakeview",
    "city_ascii": "Lakeview",
    "lat": 34.9777,
    "lng": -85.2539,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Georgia",
    "capital": "",
    "population": 5147,
    "id": 1840013553
  },
  {
    "city": "Ottawa",
    "city_ascii": "Ottawa",
    "lat": 41.0203,
    "lng": -84.0354,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 5147,
    "id": 1840011758
  },
  {
    "city": "Boiling Spring Lakes",
    "city_ascii": "Boiling Spring Lakes",
    "lat": 34.0322,
    "lng": -78.068,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 5146,
    "id": 1840013635
  },
  {
    "city": "Odessa",
    "city_ascii": "Odessa",
    "lat": 38.9988,
    "lng": -93.9666,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 5146,
    "id": 1840008526
  },
  {
    "city": "Birdsboro",
    "city_ascii": "Birdsboro",
    "lat": 40.262,
    "lng": -75.8099,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5143,
    "id": 1840001172
  },
  {
    "city": "Bristol",
    "city_ascii": "Bristol",
    "lat": 42.5378,
    "lng": -88.0149,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 5142,
    "id": 1840037916
  },
  {
    "city": "Tice",
    "city_ascii": "Tice",
    "lat": 26.6758,
    "lng": -81.8171,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 5142,
    "id": 1840014219
  },
  {
    "city": "Red Oak",
    "city_ascii": "Red Oak",
    "lat": 41.0141,
    "lng": -95.2248,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Iowa",
    "capital": "",
    "population": 5142,
    "id": 1840009346
  },
  {
    "city": "St. James",
    "city_ascii": "St. James",
    "lat": 33.9467,
    "lng": -78.1132,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 5141,
    "id": 1840017954
  },
  {
    "city": "Ayden",
    "city_ascii": "Ayden",
    "lat": 35.4694,
    "lng": -77.4167,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 5141,
    "id": 1840015388
  },
  {
    "city": "Waterbury",
    "city_ascii": "Waterbury",
    "lat": 44.3851,
    "lng": -72.746,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Vermont",
    "capital": "",
    "population": 5141,
    "id": 1840004103
  },
  {
    "city": "St. Clairsville",
    "city_ascii": "St. Clairsville",
    "lat": 40.0792,
    "lng": -80.8997,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 5141,
    "id": 1840009468
  },
  {
    "city": "Turriff",
    "city_ascii": "Turriff",
    "lat": 57.539,
    "lng": -2.4611,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Aberdeenshire",
    "capital": "",
    "population": 5060,
    "id": 1826117968
  },
  {
    "city": "Weisenberg",
    "city_ascii": "Weisenberg",
    "lat": 40.6051,
    "lng": -75.7099,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5140,
    "id": 1840147554
  },
  {
    "city": "Fălciu",
    "city_ascii": "Falciu",
    "lat": 46.3095,
    "lng": 28.0849,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Vaslui",
    "capital": "",
    "population": 5103,
    "id": 1642412009
  },
  {
    "city": "Brady",
    "city_ascii": "Brady",
    "lat": 31.1322,
    "lng": -99.3697,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 5137,
    "id": 1840019550
  },
  {
    "city": "Margaret",
    "city_ascii": "Margaret",
    "lat": 33.6735,
    "lng": -86.468,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 5137,
    "id": 1840016772
  },
  {
    "city": "Bliss Corner",
    "city_ascii": "Bliss Corner",
    "lat": 41.6054,
    "lng": -70.9421,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 5137,
    "id": 1840003221
  },
  {
    "city": "Colville",
    "city_ascii": "Colville",
    "lat": 48.5454,
    "lng": -117.8986,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 5135,
    "id": 1840018361
  },
  {
    "city": "Northumberland",
    "city_ascii": "Northumberland",
    "lat": 43.1621,
    "lng": -73.6305,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 5135,
    "id": 1840058355
  },
  {
    "city": "Bierutów",
    "city_ascii": "Bierutow",
    "lat": 51.1333,
    "lng": 17.5333,
    "country": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "admin_name": "Dolnośląskie",
    "capital": "",
    "population": 5065,
    "id": 1616959868
  },
  {
    "city": "Fort Rucker",
    "city_ascii": "Fort Rucker",
    "lat": 31.3428,
    "lng": -85.7154,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 5134,
    "id": 1840073838
  },
  {
    "city": "Dickinson",
    "city_ascii": "Dickinson",
    "lat": 42.1301,
    "lng": -75.9097,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 5132,
    "id": 1840058047
  },
  {
    "city": "Denair",
    "city_ascii": "Denair",
    "lat": 37.5262,
    "lng": -120.7994,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 5131,
    "id": 1840018906
  },
  {
    "city": "Paris",
    "city_ascii": "Paris",
    "lat": 44.2445,
    "lng": -70.4883,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maine",
    "capital": "",
    "population": 5131,
    "id": 1840052952
  },
  {
    "city": "Primera",
    "city_ascii": "Primera",
    "lat": 26.2237,
    "lng": -97.7528,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 5130,
    "id": 1840022261
  },
  {
    "city": "Humboldt",
    "city_ascii": "Humboldt",
    "lat": 42.7232,
    "lng": -94.2245,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Iowa",
    "capital": "",
    "population": 5128,
    "id": 1840000400
  },
  {
    "city": "Sergeant Bluff",
    "city_ascii": "Sergeant Bluff",
    "lat": 42.3976,
    "lng": -96.3517,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Iowa",
    "capital": "",
    "population": 5127,
    "id": 1840009111
  },
  {
    "city": "Freren",
    "city_ascii": "Freren",
    "lat": 52.4667,
    "lng": 7.5333,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Lower Saxony",
    "capital": "",
    "population": 5023,
    "id": 1276955957
  },
  {
    "city": "Yuni",
    "city_ascii": "Yuni",
    "lat": 42.9994,
    "lng": 141.7903,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hokkaidō",
    "capital": "",
    "population": 5087,
    "id": 1392173904
  },
  {
    "city": "Spring Valley",
    "city_ascii": "Spring Valley",
    "lat": 41.3357,
    "lng": -89.2034,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 5125,
    "id": 1840009275
  },
  {
    "city": "Osceola",
    "city_ascii": "Osceola",
    "lat": 41.0302,
    "lng": -93.7829,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Iowa",
    "capital": "",
    "population": 5125,
    "id": 1840000889
  },
  {
    "city": "Flagler Beach",
    "city_ascii": "Flagler Beach",
    "lat": 29.4716,
    "lng": -81.1303,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 5123,
    "id": 1840014035
  },
  {
    "city": "Rainsville",
    "city_ascii": "Rainsville",
    "lat": 34.4939,
    "lng": -85.8435,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 5123,
    "id": 1840014658
  },
  {
    "city": "Brokenhead",
    "city_ascii": "Brokenhead",
    "lat": 50.1428,
    "lng": -96.5319,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Manitoba",
    "capital": "",
    "population": 5122,
    "id": 1124000321
  },
  {
    "city": "Saint-Paul",
    "city_ascii": "Saint-Paul",
    "lat": 45.9833,
    "lng": -73.45,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 5122,
    "id": 1124001817
  },
  {
    "city": "Schuyler Falls",
    "city_ascii": "Schuyler Falls",
    "lat": 44.6578,
    "lng": -73.5842,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 5121,
    "id": 1840058479
  },
  {
    "city": "Simonton Lake",
    "city_ascii": "Simonton Lake",
    "lat": 41.7478,
    "lng": -85.9657,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 5121,
    "id": 1840004816
  },
  {
    "city": "Lone Grove",
    "city_ascii": "Lone Grove",
    "lat": 34.1809,
    "lng": -97.2559,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Oklahoma",
    "capital": "",
    "population": 5121,
    "id": 1840020532
  },
  {
    "city": "Colona",
    "city_ascii": "Colona",
    "lat": 41.4678,
    "lng": -90.3445,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 5120,
    "id": 1840007098
  },
  {
    "city": "Negru Vodă",
    "city_ascii": "Negru Voda",
    "lat": 43.8181,
    "lng": 28.2125,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Constanţa",
    "capital": "",
    "population": 5088,
    "id": 1642042554
  },
  {
    "city": "Halaç",
    "city_ascii": "Halac",
    "lat": 38.0686,
    "lng": 64.8811,
    "country": "Turkmenistan",
    "iso2": "TM",
    "iso3": "TKM",
    "admin_name": "Lebap",
    "capital": "",
    "population": 5101,
    "id": 1795944414
  },
  {
    "city": "Montecastrilli",
    "city_ascii": "Montecastrilli",
    "lat": 42.65,
    "lng": 12.4833,
    "country": "Italy",
    "iso2": "IT",
    "iso3": "ITA",
    "admin_name": "Umbria",
    "capital": "",
    "population": 5037,
    "id": 1380086045
  },
  {
    "city": "Dmitrovsk-Orlovskiy",
    "city_ascii": "Dmitrovsk-Orlovskiy",
    "lat": 52.505,
    "lng": 35.1464,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Orlovskaya Oblast’",
    "capital": "",
    "population": 5105,
    "id": 1643688423
  },
  {
    "city": "Adams",
    "city_ascii": "Adams",
    "lat": 43.8511,
    "lng": -76.0379,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 5114,
    "id": 1840004119
  },
  {
    "city": "Spalt",
    "city_ascii": "Spalt",
    "lat": 49.1739,
    "lng": 10.9275,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 5023,
    "id": 1276814380
  },
  {
    "city": "Guzolândia",
    "city_ascii": "Guzolandia",
    "lat": -20.6497,
    "lng": -50.6619,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 5103,
    "id": 1076229667
  },
  {
    "city": "Suncook",
    "city_ascii": "Suncook",
    "lat": 43.1382,
    "lng": -71.4529,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Hampshire",
    "capital": "",
    "population": 5111,
    "id": 1840002804
  },
  {
    "city": "Carmi",
    "city_ascii": "Carmi",
    "lat": 38.0863,
    "lng": -88.1718,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 5111,
    "id": 1840007525
  },
  {
    "city": "Oberviechtach",
    "city_ascii": "Oberviechtach",
    "lat": 49.4667,
    "lng": 12.4167,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 5030,
    "id": 1276565064
  },
  {
    "city": "Shelby",
    "city_ascii": "Shelby",
    "lat": 43.1738,
    "lng": -78.3868,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 5110,
    "id": 1840087909
  },
  {
    "city": "Ilok",
    "city_ascii": "Ilok",
    "lat": 45.2225,
    "lng": 19.3728,
    "country": "Croatia",
    "iso2": "HR",
    "iso3": "HRV",
    "admin_name": "Vukovarsko-Srijemska Županija",
    "capital": "minor",
    "population": 5072,
    "id": 1191635605
  },
  {
    "city": "Gargaliánoi",
    "city_ascii": "Gargalianoi",
    "lat": 37.0667,
    "lng": 21.6333,
    "country": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "admin_name": "Peloponnísos",
    "capital": "",
    "population": 5007,
    "id": 1300653438
  },
  {
    "city": "Mexico",
    "city_ascii": "Mexico",
    "lat": 43.4549,
    "lng": -76.205,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 5107,
    "id": 1840004191
  },
  {
    "city": "Shanor-Northvue",
    "city_ascii": "Shanor-Northvue",
    "lat": 40.9104,
    "lng": -79.9157,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5107,
    "id": 1840035235
  },
  {
    "city": "Utica",
    "city_ascii": "Utica",
    "lat": 42.629,
    "lng": -83.0218,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 5107,
    "id": 1840002417
  },
  {
    "city": "San Leon",
    "city_ascii": "San Leon",
    "lat": 29.4901,
    "lng": -94.9403,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 5106,
    "id": 1840019662
  },
  {
    "city": "Schuylkill Haven",
    "city_ascii": "Schuylkill Haven",
    "lat": 40.6284,
    "lng": -76.1729,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5106,
    "id": 1840002778
  },
  {
    "city": "Hollfeld",
    "city_ascii": "Hollfeld",
    "lat": 49.9369,
    "lng": 11.2908,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 5043,
    "id": 1276797226
  },
  {
    "city": "Horšovský Týn",
    "city_ascii": "Horsovsky Tyn",
    "lat": 49.5298,
    "lng": 12.9441,
    "country": "Czechia",
    "iso2": "CZ",
    "iso3": "CZE",
    "admin_name": "Plzeňský Kraj",
    "capital": "",
    "population": 5034,
    "id": 1203205008
  },
  {
    "city": "Poplar Grove",
    "city_ascii": "Poplar Grove",
    "lat": 42.3516,
    "lng": -88.8353,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 5104,
    "id": 1840011146
  },
  {
    "city": "Scott City",
    "city_ascii": "Scott City",
    "lat": 37.2243,
    "lng": -89.536,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 5104,
    "id": 1840009923
  },
  {
    "city": "Portland",
    "city_ascii": "Portland",
    "lat": 42.8696,
    "lng": -84.899,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Michigan",
    "capital": "",
    "population": 5104,
    "id": 1840003073
  },
  {
    "city": "Naumburg",
    "city_ascii": "Naumburg",
    "lat": 51.25,
    "lng": 9.1667,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Hesse",
    "capital": "",
    "population": 5028,
    "id": 1276277559
  },
  {
    "city": "Bozhurishte",
    "city_ascii": "Bozhurishte",
    "lat": 42.75,
    "lng": 23.2,
    "country": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "admin_name": "Sofia",
    "capital": "",
    "population": 5047,
    "id": 1100578677
  },
  {
    "city": "Buellton",
    "city_ascii": "Buellton",
    "lat": 34.6152,
    "lng": -120.1943,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 5102,
    "id": 1840019186
  },
  {
    "city": "Daleville",
    "city_ascii": "Daleville",
    "lat": 31.2915,
    "lng": -85.7117,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 5102,
    "id": 1840014955
  },
  {
    "city": "Belleview",
    "city_ascii": "Belleview",
    "lat": 29.0609,
    "lng": -82.0565,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 5101,
    "id": 1840014040
  },
  {
    "city": "Chadron",
    "city_ascii": "Chadron",
    "lat": 42.826,
    "lng": -103.0025,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Nebraska",
    "capital": "",
    "population": 5101,
    "id": 1840006893
  },
  {
    "city": "Westlock",
    "city_ascii": "Westlock",
    "lat": 54.1522,
    "lng": -113.8511,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Alberta",
    "capital": "",
    "population": 5101,
    "id": 1124037311
  },
  {
    "city": "Marion",
    "city_ascii": "Marion",
    "lat": 41.7091,
    "lng": -70.7635,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 5100,
    "id": 1840070287
  },
  {
    "city": "Ostrov",
    "city_ascii": "Ostrov",
    "lat": 44.102,
    "lng": 27.4017,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Constanţa",
    "capital": "",
    "population": 5069,
    "id": 1642976394
  },
  {
    "city": "West Mead",
    "city_ascii": "West Mead",
    "lat": 41.6266,
    "lng": -80.1247,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5097,
    "id": 1840142386
  },
  {
    "city": "Témiscouata-sur-le-Lac",
    "city_ascii": "Temiscouata-sur-le-Lac",
    "lat": 47.68,
    "lng": -68.88,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 5096,
    "id": 1124001776
  },
  {
    "city": "Sousel",
    "city_ascii": "Sousel",
    "lat": 38.9532,
    "lng": -7.6757,
    "country": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "admin_name": "Portalegre",
    "capital": "minor",
    "population": 5074,
    "id": 1620270791
  },
  {
    "city": "Brownfields",
    "city_ascii": "Brownfields",
    "lat": 30.5467,
    "lng": -91.1225,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Louisiana",
    "capital": "",
    "population": 5092,
    "id": 1840031149
  },
  {
    "city": "Candor",
    "city_ascii": "Candor",
    "lat": 42.2318,
    "lng": -76.3329,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 5089,
    "id": 1840004671
  },
  {
    "city": "Shannon",
    "city_ascii": "Shannon",
    "lat": 46.8833,
    "lng": -71.5167,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 5086,
    "id": 1124440867
  },
  {
    "city": "Colesville",
    "city_ascii": "Colesville",
    "lat": 42.1742,
    "lng": -75.6627,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 5085,
    "id": 1840087329
  },
  {
    "city": "Osoyoos",
    "city_ascii": "Osoyoos",
    "lat": 49.0325,
    "lng": -119.4661,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "British Columbia",
    "capital": "",
    "population": 5085,
    "id": 1124713973
  },
  {
    "city": "Vale",
    "city_ascii": "Vale",
    "lat": 41.6167,
    "lng": 42.8667,
    "country": "Georgia",
    "iso2": "GE",
    "iso3": "GEO",
    "admin_name": "Samtskhe-Javakheti",
    "capital": "",
    "population": 5031,
    "id": 1268581181
  },
  {
    "city": "Rio Hondo",
    "city_ascii": "Rio Hondo",
    "lat": 26.2347,
    "lng": -97.5817,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 5082,
    "id": 1840021038
  },
  {
    "city": "Lyubim",
    "city_ascii": "Lyubim",
    "lat": 58.35,
    "lng": 40.7,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Yaroslavskaya Oblast’",
    "capital": "",
    "population": 5076,
    "id": 1643368159
  },
  {
    "city": "North Fond du Lac",
    "city_ascii": "North Fond du Lac",
    "lat": 43.8107,
    "lng": -88.4861,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 5081,
    "id": 1840002607
  },
  {
    "city": "Crestwood",
    "city_ascii": "Crestwood",
    "lat": 38.3356,
    "lng": -85.4839,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Kentucky",
    "capital": "",
    "population": 5081,
    "id": 1840014284
  },
  {
    "city": "Paradise Hills",
    "city_ascii": "Paradise Hills",
    "lat": 35.1981,
    "lng": -106.7023,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Mexico",
    "capital": "",
    "population": 5079,
    "id": 1840024971
  },
  {
    "city": "Isaccea",
    "city_ascii": "Isaccea",
    "lat": 45.2697,
    "lng": 28.4597,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Tulcea",
    "capital": "",
    "population": 5026,
    "id": 1642847805
  },
  {
    "city": "Fox Chapel",
    "city_ascii": "Fox Chapel",
    "lat": 40.5247,
    "lng": -79.8898,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5076,
    "id": 1840001197
  },
  {
    "city": "Colebrookdale",
    "city_ascii": "Colebrookdale",
    "lat": 40.3468,
    "lng": -75.6464,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5075,
    "id": 1840102653
  },
  {
    "city": "Nisekoan",
    "city_ascii": "Nisekoan",
    "lat": 42.8047,
    "lng": 140.6875,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Hokkaidō",
    "capital": "",
    "population": 5049,
    "id": 1392936047
  },
  {
    "city": "Midway North",
    "city_ascii": "Midway North",
    "lat": 26.1872,
    "lng": -98.0188,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 5074,
    "id": 1840034872
  },
  {
    "city": "Offutt AFB",
    "city_ascii": "Offutt AFB",
    "lat": 41.1207,
    "lng": -95.9209,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Nebraska",
    "capital": "",
    "population": 5074,
    "id": 1840073770
  },
  {
    "city": "Canterbury",
    "city_ascii": "Canterbury",
    "lat": 41.6979,
    "lng": -71.9998,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Connecticut",
    "capital": "",
    "population": 5074,
    "id": 1840034278
  },
  {
    "city": "De Motte",
    "city_ascii": "De Motte",
    "lat": 41.1988,
    "lng": -87.1973,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Indiana",
    "capital": "",
    "population": 5073,
    "id": 1840030064
  },
  {
    "city": "Laraquete",
    "city_ascii": "Laraquete",
    "lat": -37.1677,
    "lng": -73.1859,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Biobío",
    "capital": "",
    "population": 5014,
    "id": 1152199352
  },
  {
    "city": "Scott Township",
    "city_ascii": "Scott Township",
    "lat": 41.0219,
    "lng": -76.4169,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5071,
    "id": 1840145429
  },
  {
    "city": "Glencoe",
    "city_ascii": "Glencoe",
    "lat": 33.9449,
    "lng": -85.9319,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 5071,
    "id": 1840003257
  },
  {
    "city": "McKenzie",
    "city_ascii": "McKenzie",
    "lat": 36.1371,
    "lng": -88.5077,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Tennessee",
    "capital": "",
    "population": 5070,
    "id": 1840015346
  },
  {
    "city": "Hearst",
    "city_ascii": "Hearst",
    "lat": 49.6869,
    "lng": -83.6544,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 5070,
    "id": 1124376843
  },
  {
    "city": "Rockingham",
    "city_ascii": "Rockingham",
    "lat": 43.1815,
    "lng": -72.5014,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Vermont",
    "capital": "",
    "population": 5068,
    "id": 1840028534
  },
  {
    "city": "Alfred",
    "city_ascii": "Alfred",
    "lat": 42.2385,
    "lng": -77.7891,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 5067,
    "id": 1840004608
  },
  {
    "city": "Middle Paxton",
    "city_ascii": "Middle Paxton",
    "lat": 40.3934,
    "lng": -76.8753,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5067,
    "id": 1840149540
  },
  {
    "city": "Bay Hill",
    "city_ascii": "Bay Hill",
    "lat": 28.4558,
    "lng": -81.5122,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 5066,
    "id": 1840029020
  },
  {
    "city": "Shipston on Stour",
    "city_ascii": "Shipston on Stour",
    "lat": 52.06,
    "lng": -1.623,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Warwickshire",
    "capital": "",
    "population": 5038,
    "id": 1826514652
  },
  {
    "city": "Doffing",
    "city_ascii": "Doffing",
    "lat": 26.2788,
    "lng": -98.3856,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 5065,
    "id": 1840037042
  },
  {
    "city": "Kings Park",
    "city_ascii": "Kings Park",
    "lat": 38.8026,
    "lng": -77.2396,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 5064,
    "id": 1840024574
  },
  {
    "city": "Álvaro de Carvalho",
    "city_ascii": "Alvaro de Carvalho",
    "lat": -22.0797,
    "lng": -49.7197,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 5030,
    "id": 1076135695
  },
  {
    "city": "Munroe Falls",
    "city_ascii": "Munroe Falls",
    "lat": 41.1386,
    "lng": -81.4344,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 5063,
    "id": 1840000798
  },
  {
    "city": "Canutillo",
    "city_ascii": "Canutillo",
    "lat": 31.9185,
    "lng": -106.6006,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 5061,
    "id": 1840018151
  },
  {
    "city": "Monee",
    "city_ascii": "Monee",
    "lat": 41.4181,
    "lng": -87.7499,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 5061,
    "id": 1840011492
  },
  {
    "city": "Williamston",
    "city_ascii": "Williamston",
    "lat": 35.8468,
    "lng": -77.0655,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 5060,
    "id": 1840016206
  },
  {
    "city": "Ipiguá",
    "city_ascii": "Ipigua",
    "lat": -20.6569,
    "lng": -49.3869,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 5022,
    "id": 1076348289
  },
  {
    "city": "Buckley",
    "city_ascii": "Buckley",
    "lat": 47.1615,
    "lng": -122.02,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 5058,
    "id": 1840018455
  },
  {
    "city": "Gretna",
    "city_ascii": "Gretna",
    "lat": 41.1319,
    "lng": -96.2447,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Nebraska",
    "capital": "",
    "population": 5058,
    "id": 1840007143
  },
  {
    "city": "Wayne Township",
    "city_ascii": "Wayne Township",
    "lat": 40.583,
    "lng": -76.2294,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5058,
    "id": 1840147638
  },
  {
    "city": "Lower Oxford",
    "city_ascii": "Lower Oxford",
    "lat": 39.806,
    "lng": -75.9895,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5058,
    "id": 1840151982
  },
  {
    "city": "Brønnøysund",
    "city_ascii": "Bronnoysund",
    "lat": 65.4749,
    "lng": 12.212,
    "country": "Norway",
    "iso2": "NO",
    "iso3": "NOR",
    "admin_name": "Nordland",
    "capital": "minor",
    "population": 5050,
    "id": 1578503953
  },
  {
    "city": "Stewartstown",
    "city_ascii": "Stewartstown",
    "lat": 39.7528,
    "lng": -76.5925,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5057,
    "id": 1840001465
  },
  {
    "city": "Garnet",
    "city_ascii": "Garnet",
    "lat": 33.9179,
    "lng": -116.4796,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "California",
    "capital": "",
    "population": 5057,
    "id": 1840026985
  },
  {
    "city": "Băile Herculane",
    "city_ascii": "Baile Herculane",
    "lat": 44.8772,
    "lng": 22.4175,
    "country": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "admin_name": "Caraş-Severin",
    "capital": "",
    "population": 5008,
    "id": 1642980353
  },
  {
    "city": "Jericho",
    "city_ascii": "Jericho",
    "lat": 44.4764,
    "lng": -72.9629,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Vermont",
    "capital": "",
    "population": 5055,
    "id": 1840004084
  },
  {
    "city": "Clifton",
    "city_ascii": "Clifton",
    "lat": 33.0249,
    "lng": -109.2883,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 5049,
    "id": 1840022949
  },
  {
    "city": "Pratânia",
    "city_ascii": "Pratania",
    "lat": -22.8083,
    "lng": -48.6661,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "São Paulo",
    "capital": "",
    "population": 5021,
    "id": 1076303219
  },
  {
    "city": "Livingston",
    "city_ascii": "Livingston",
    "lat": 30.71,
    "lng": -94.9381,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 5048,
    "id": 1840022159
  },
  {
    "city": "Springfield Township",
    "city_ascii": "Springfield Township",
    "lat": 40.5249,
    "lng": -75.2998,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5048,
    "id": 1840150061
  },
  {
    "city": "Berkeley",
    "city_ascii": "Berkeley",
    "lat": 41.8891,
    "lng": -87.9114,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 5048,
    "id": 1840011249
  },
  {
    "city": "Vancleave",
    "city_ascii": "Vancleave",
    "lat": 30.5483,
    "lng": -88.6675,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Mississippi",
    "capital": "",
    "population": 5048,
    "id": 1840013934
  },
  {
    "city": "Deerfield",
    "city_ascii": "Deerfield",
    "lat": 42.522,
    "lng": -72.6097,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 5046,
    "id": 1840053428
  },
  {
    "city": "Carver",
    "city_ascii": "Carver",
    "lat": 44.76,
    "lng": -93.6305,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 5046,
    "id": 1840006758
  },
  {
    "city": "Yarmouth Port",
    "city_ascii": "Yarmouth Port",
    "lat": 41.71,
    "lng": -70.2257,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Massachusetts",
    "capital": "",
    "population": 5045,
    "id": 1840003248
  },
  {
    "city": "Dundee",
    "city_ascii": "Dundee",
    "lat": 28.0115,
    "lng": -81.5995,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 5044,
    "id": 1840017241
  },
  {
    "city": "Summit",
    "city_ascii": "Summit",
    "lat": 43.0504,
    "lng": -88.4815,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 5041,
    "id": 1840072793
  },
  {
    "city": "Morganville",
    "city_ascii": "Morganville",
    "lat": 40.3756,
    "lng": -74.2444,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 5041,
    "id": 1840005442
  },
  {
    "city": "Bowie",
    "city_ascii": "Bowie",
    "lat": 33.5566,
    "lng": -97.844,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 5040,
    "id": 1840019314
  },
  {
    "city": "Chester",
    "city_ascii": "Chester",
    "lat": 42.9672,
    "lng": -71.2509,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Hampshire",
    "capital": "",
    "population": 5039,
    "id": 1840054735
  },
  {
    "city": "Amelia",
    "city_ascii": "Amelia",
    "lat": 39.0229,
    "lng": -84.2184,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 5039,
    "id": 1840010681
  },
  {
    "city": "Harrisburg",
    "city_ascii": "Harrisburg",
    "lat": 43.4321,
    "lng": -96.7037,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Dakota",
    "capital": "",
    "population": 5038,
    "id": 1840002845
  },
  {
    "city": "Krum",
    "city_ascii": "Krum",
    "lat": 33.2652,
    "lng": -97.2256,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 5036,
    "id": 1840020645
  },
  {
    "city": "East Flat Rock",
    "city_ascii": "East Flat Rock",
    "lat": 35.2802,
    "lng": -82.4171,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "North Carolina",
    "capital": "",
    "population": 5036,
    "id": 1840013073
  },
  {
    "city": "Barling",
    "city_ascii": "Barling",
    "lat": 35.3284,
    "lng": -94.2792,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arkansas",
    "capital": "",
    "population": 5036,
    "id": 1840013455
  },
  {
    "city": "Citrus Park",
    "city_ascii": "Citrus Park",
    "lat": 33.5304,
    "lng": -112.444,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 5033,
    "id": 1840022921
  },
  {
    "city": "Whiskey Creek",
    "city_ascii": "Whiskey Creek",
    "lat": 26.5733,
    "lng": -81.8903,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Florida",
    "capital": "",
    "population": 5030,
    "id": 1840029099
  },
  {
    "city": "La Crescent",
    "city_ascii": "La Crescent",
    "lat": 43.8299,
    "lng": -91.3043,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 5029,
    "id": 1840007938
  },
  {
    "city": "Takko",
    "city_ascii": "Takko",
    "lat": 40.34,
    "lng": 141.1519,
    "country": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "admin_name": "Aomori",
    "capital": "",
    "population": 5008,
    "id": 1392382825
  },
  {
    "city": "King George",
    "city_ascii": "King George",
    "lat": 38.2807,
    "lng": -77.1896,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 5028,
    "id": 1840026758
  },
  {
    "city": "Summit",
    "city_ascii": "Summit",
    "lat": 32.0619,
    "lng": -110.9483,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Arizona",
    "capital": "",
    "population": 5027,
    "id": 1840019488
  },
  {
    "city": "Commercial",
    "city_ascii": "Commercial",
    "lat": 39.2763,
    "lng": -75.0407,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Jersey",
    "capital": "",
    "population": 5026,
    "id": 1840081593
  },
  {
    "city": "Olyphant",
    "city_ascii": "Olyphant",
    "lat": 41.4507,
    "lng": -75.5753,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5023,
    "id": 1840003388
  },
  {
    "city": "Pine Township",
    "city_ascii": "Pine Township",
    "lat": 41.1681,
    "lng": -80.0657,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5023,
    "id": 1840141989
  },
  {
    "city": "Dulles Town Center",
    "city_ascii": "Dulles Town Center",
    "lat": 39.0265,
    "lng": -77.4196,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Virginia",
    "capital": "",
    "population": 5023,
    "id": 1840024501
  },
  {
    "city": "Sea Cliff",
    "city_ascii": "Sea Cliff",
    "lat": 40.8441,
    "lng": -73.6442,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New York",
    "capital": "",
    "population": 5020,
    "id": 1840005326
  },
  {
    "city": "Charlestown",
    "city_ascii": "Charlestown",
    "lat": 43.2469,
    "lng": -72.3939,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Hampshire",
    "capital": "",
    "population": 5019,
    "id": 1840054717
  },
  {
    "city": "Abbeville",
    "city_ascii": "Abbeville",
    "lat": 34.1787,
    "lng": -82.3774,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 5019,
    "id": 1840013618
  },
  {
    "city": "Chukhloma",
    "city_ascii": "Chukhloma",
    "lat": 58.75,
    "lng": 42.7,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kostromskaya Oblast’",
    "capital": "",
    "population": 5015,
    "id": 1643918369
  },
  {
    "city": "Granville Township",
    "city_ascii": "Granville Township",
    "lat": 40.5735,
    "lng": -77.6129,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5017,
    "id": 1840146277
  },
  {
    "city": "Caruthersville",
    "city_ascii": "Caruthersville",
    "lat": 36.1814,
    "lng": -89.6664,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Missouri",
    "capital": "",
    "population": 5017,
    "id": 1840007669
  },
  {
    "city": "Wakefield",
    "city_ascii": "Wakefield",
    "lat": 43.5916,
    "lng": -71.0098,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Hampshire",
    "capital": "",
    "population": 5016,
    "id": 1840055706
  },
  {
    "city": "Sabattus",
    "city_ascii": "Sabattus",
    "lat": 44.095,
    "lng": -70.0662,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Maine",
    "capital": "",
    "population": 5016,
    "id": 1840052984
  },
  {
    "city": "West Salem",
    "city_ascii": "West Salem",
    "lat": 43.8989,
    "lng": -91.0883,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wisconsin",
    "capital": "",
    "population": 5015,
    "id": 1840002575
  },
  {
    "city": "Mullins",
    "city_ascii": "Mullins",
    "lat": 34.2042,
    "lng": -79.2535,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 5010,
    "id": 1840014719
  },
  {
    "city": "Swoyersville",
    "city_ascii": "Swoyersville",
    "lat": 41.2975,
    "lng": -75.8799,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Pennsylvania",
    "capital": "",
    "population": 5009,
    "id": 1840000771
  },
  {
    "city": "Greenfield",
    "city_ascii": "Greenfield",
    "lat": 39.3535,
    "lng": -83.3884,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Ohio",
    "capital": "",
    "population": 5008,
    "id": 1840008513
  },
  {
    "city": "Hudson",
    "city_ascii": "Hudson",
    "lat": 31.3285,
    "lng": -94.8014,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Texas",
    "capital": "",
    "population": 5005,
    "id": 1840020838
  },
  {
    "city": "Dwight",
    "city_ascii": "Dwight",
    "lat": 41.0987,
    "lng": -88.424,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 5005,
    "id": 1840011783
  },
  {
    "city": "Ridgeland",
    "city_ascii": "Ridgeland",
    "lat": 32.468,
    "lng": -80.9176,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "South Carolina",
    "capital": "",
    "population": 5005,
    "id": 1840018086
  },
  {
    "city": "Nottingham",
    "city_ascii": "Nottingham",
    "lat": 43.1242,
    "lng": -71.121,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "New Hampshire",
    "capital": "",
    "population": 5004,
    "id": 1840055357
  },
  {
    "city": "Worland",
    "city_ascii": "Worland",
    "lat": 44.0026,
    "lng": -107.9543,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Wyoming",
    "capital": "",
    "population": 5003,
    "id": 1840021266
  },
  {
    "city": "Oak Park Heights",
    "city_ascii": "Oak Park Heights",
    "lat": 45.0324,
    "lng": -92.8099,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 5003,
    "id": 1840007812
  },
  {
    "city": "Midfield",
    "city_ascii": "Midfield",
    "lat": 33.4552,
    "lng": -86.9226,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Alabama",
    "capital": "",
    "population": 5002,
    "id": 1840014791
  },
  {
    "city": "Gillespie",
    "city_ascii": "Gillespie",
    "lat": 39.1258,
    "lng": -89.8173,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Illinois",
    "capital": "",
    "population": 5002,
    "id": 1840008489
  },
  {
    "city": "Antigonish",
    "city_ascii": "Antigonish",
    "lat": 45.6167,
    "lng": -61.9833,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Nova Scotia",
    "capital": "",
    "population": 5002,
    "id": 1124839247
  },
  {
    "city": "Hockinson",
    "city_ascii": "Hockinson",
    "lat": 45.7302,
    "lng": -122.4833,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Washington",
    "capital": "",
    "population": 5001,
    "id": 1840017399
  },
  {
    "city": "Montevideo",
    "city_ascii": "Montevideo",
    "lat": 44.9505,
    "lng": -95.7153,
    "country": "United States",
    "iso2": "US",
    "iso3": "USA",
    "admin_name": "Minnesota",
    "capital": "",
    "population": 5001,
    "id": 1840007837
  },
  {
    "city": "Igarka",
    "city_ascii": "Igarka",
    "lat": 67.4667,
    "lng": 86.5667,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Krasnoyarskiy Kray",
    "capital": "",
    "population": 4975,
    "id": 1643125542
  },
  {
    "city": "Turangi",
    "city_ascii": "Turangi",
    "lat": -38.989,
    "lng": 175.81,
    "country": "New Zealand",
    "iso2": "NZ",
    "iso3": "NZL",
    "admin_name": "Waikato",
    "capital": "",
    "population": 3320,
    "id": 1554981086
  },
  {
    "city": "Vadsø",
    "city_ascii": "Vadso",
    "lat": 70.0667,
    "lng": 29.75,
    "country": "Norway",
    "iso2": "NO",
    "iso3": "NOR",
    "admin_name": "Finnmark",
    "capital": "minor",
    "population": 4902,
    "id": 1578863149
  },
  {
    "city": "Ingham",
    "city_ascii": "Ingham",
    "lat": -18.65,
    "lng": 146.1667,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Queensland",
    "capital": "",
    "population": 4357,
    "id": 1036238542
  },
  {
    "city": "Oranjemund",
    "city_ascii": "Oranjemund",
    "lat": -28.55,
    "lng": 16.4333,
    "country": "Namibia",
    "iso2": "NA",
    "iso3": "NAM",
    "admin_name": "//Karas",
    "capital": "",
    "population": 3900,
    "id": 1516991599
  },
  {
    "city": "Manjimup",
    "city_ascii": "Manjimup",
    "lat": -34.2411,
    "lng": 116.1464,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Western Australia",
    "capital": "",
    "population": 4349,
    "id": 1036022434
  },
  {
    "city": "Carnarvon",
    "city_ascii": "Carnarvon",
    "lat": -24.8672,
    "lng": 113.6611,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Western Australia",
    "capital": "",
    "population": 4426,
    "id": 1036344209
  },
  {
    "city": "Susuman",
    "city_ascii": "Susuman",
    "lat": 62.7808,
    "lng": 148.1539,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Magadanskaya Oblast’",
    "capital": "",
    "population": 4760,
    "id": 1643731360
  },
  {
    "city": "Smithton",
    "city_ascii": "Smithton",
    "lat": -40.844,
    "lng": 145.12,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Tasmania",
    "capital": "",
    "population": 3881,
    "id": 1036207188
  },
  {
    "city": "Svolvær",
    "city_ascii": "Svolvaer",
    "lat": 68.2333,
    "lng": 14.5667,
    "country": "Norway",
    "iso2": "NO",
    "iso3": "NOR",
    "admin_name": "Nordland",
    "capital": "minor",
    "population": 4686,
    "id": 1578516140
  },
  {
    "city": "Narrogin",
    "city_ascii": "Narrogin",
    "lat": -32.936,
    "lng": 117.178,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Western Australia",
    "capital": "",
    "population": 4274,
    "id": 1036221961
  },
  {
    "city": "Westport",
    "city_ascii": "Westport",
    "lat": -41.75,
    "lng": 171.6,
    "country": "New Zealand",
    "iso2": "NZ",
    "iso3": "NZL",
    "admin_name": "West Coast",
    "capital": "",
    "population": 4660,
    "id": 1554650685
  },
  {
    "city": "Perito Moreno",
    "city_ascii": "Perito Moreno",
    "lat": -46.59,
    "lng": -70.9297,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Santa Cruz",
    "capital": "minor",
    "population": 4617,
    "id": 1032891854
  },
  {
    "city": "Newman",
    "city_ascii": "Newman",
    "lat": -23.3539,
    "lng": 119.7319,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Western Australia",
    "capital": "",
    "population": 4567,
    "id": 1036587511
  },
  {
    "city": "Otavi",
    "city_ascii": "Otavi",
    "lat": -19.6381,
    "lng": 17.3403,
    "country": "Namibia",
    "iso2": "NA",
    "iso3": "NAM",
    "admin_name": "Otjozondjupa",
    "capital": "",
    "population": 4562,
    "id": 1516874756
  },
  {
    "city": "Tepelenë",
    "city_ascii": "Tepelene",
    "lat": 40.3,
    "lng": 20.0167,
    "country": "Albania",
    "iso2": "AL",
    "iso3": "ALB",
    "admin_name": "Gjirokastër",
    "capital": "",
    "population": 4342,
    "id": 1008683571
  },
  {
    "city": "Camargo",
    "city_ascii": "Camargo",
    "lat": -20.6403,
    "lng": -65.2103,
    "country": "Bolivia",
    "iso2": "BO",
    "iso3": "BOL",
    "admin_name": "Chuquisaca",
    "capital": "",
    "population": 4502,
    "id": 1068344721
  },
  {
    "city": "Abra Pampa",
    "city_ascii": "Abra Pampa",
    "lat": -22.7167,
    "lng": -65.7,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Jujuy",
    "capital": "minor",
    "population": 4480,
    "id": 1032997640
  },
  {
    "city": "Pofadder",
    "city_ascii": "Pofadder",
    "lat": -29.1286,
    "lng": 19.3947,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "Northern Cape",
    "capital": "",
    "population": 4220,
    "id": 1710817164
  },
  {
    "city": "Victorica",
    "city_ascii": "Victorica",
    "lat": -36.2163,
    "lng": -65.45,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "La Pampa",
    "capital": "minor",
    "population": 4458,
    "id": 1032392251
  },
  {
    "city": "La Paz",
    "city_ascii": "La Paz",
    "lat": -33.4661,
    "lng": -67.55,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Mendoza",
    "capital": "minor",
    "population": 4400,
    "id": 1032294439
  },
  {
    "city": "Kyaukpyu",
    "city_ascii": "Kyaukpyu",
    "lat": 19.4333,
    "lng": 93.55,
    "country": "Burma",
    "iso2": "MM",
    "iso3": "MMR",
    "admin_name": "Rakhine State",
    "capital": "",
    "population": 4261,
    "id": 1104321745
  },
  {
    "city": "Merimbula",
    "city_ascii": "Merimbula",
    "lat": -36.8983,
    "lng": 149.9011,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 3544,
    "id": 1036933197
  },
  {
    "city": "Manica",
    "city_ascii": "Manica",
    "lat": -18.9344,
    "lng": 32.8756,
    "country": "Mozambique",
    "iso2": "MZ",
    "iso3": "MOZ",
    "admin_name": "Manica",
    "capital": "",
    "population": 4000,
    "id": 1508848017
  },
  {
    "city": "Katanning",
    "city_ascii": "Katanning",
    "lat": -33.6908,
    "lng": 117.5553,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Western Australia",
    "capital": "",
    "population": 3687,
    "id": 1036411379
  },
  {
    "city": "Dehiba",
    "city_ascii": "Dehiba",
    "lat": 32.011,
    "lng": 10.7028,
    "country": "Tunisia",
    "iso2": "TN",
    "iso3": "TUN",
    "admin_name": "Tataouine",
    "capital": "",
    "population": 4295,
    "id": 1788484869
  },
  {
    "city": "Comandante Fontana",
    "city_ascii": "Comandante Fontana",
    "lat": -25.3333,
    "lng": -59.6833,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Formosa",
    "capital": "minor",
    "population": 4277,
    "id": 1032594164
  },
  {
    "city": "Ersekë",
    "city_ascii": "Erseke",
    "lat": 40.3333,
    "lng": 20.6833,
    "country": "Albania",
    "iso2": "AL",
    "iso3": "ALB",
    "admin_name": "Korçë",
    "capital": "",
    "population": 3746,
    "id": 1008049831
  },
  {
    "city": "Weipa",
    "city_ascii": "Weipa",
    "lat": -12.6167,
    "lng": 141.8667,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Queensland",
    "capital": "",
    "population": 3899,
    "id": 1036501067
  },
  {
    "city": "Wallaroo",
    "city_ascii": "Wallaroo",
    "lat": -33.9167,
    "lng": 137.6167,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "South Australia",
    "capital": "",
    "population": 3988,
    "id": 1036743246
  },
  {
    "city": "Renwick",
    "city_ascii": "Renwick",
    "lat": -41.5099,
    "lng": 173.8282,
    "country": "New Zealand",
    "iso2": "NZ",
    "iso3": "NZL",
    "admin_name": "Marlborough",
    "capital": "",
    "population": 2928,
    "id": 1554865401
  },
  {
    "city": "Mitzic",
    "city_ascii": "Mitzic",
    "lat": 0.7833,
    "lng": 11.5667,
    "country": "Gabon",
    "iso2": "GA",
    "iso3": "GAB",
    "admin_name": "Woleu-Ntem",
    "capital": "",
    "population": 4111,
    "id": 1266866714
  },
  {
    "city": "Brus Laguna",
    "city_ascii": "Brus Laguna",
    "lat": 15.75,
    "lng": -84.4833,
    "country": "Honduras",
    "iso2": "HN",
    "iso3": "HND",
    "admin_name": "Gracias a Dios",
    "capital": "",
    "population": 4067,
    "id": 1340003428
  },
  {
    "city": "Çorovodë",
    "city_ascii": "Corovode",
    "lat": 40.5042,
    "lng": 20.2272,
    "country": "Albania",
    "iso2": "AL",
    "iso3": "ALB",
    "admin_name": "Berat",
    "capital": "",
    "population": 4051,
    "id": 1008566247
  },
  {
    "city": "Pevek",
    "city_ascii": "Pevek",
    "lat": 69.7,
    "lng": 170.3167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Chukotskiy Avtonomnyy Okrug",
    "capital": "",
    "population": 4053,
    "id": 1643597133
  },
  {
    "city": "Central Coast",
    "city_ascii": "Central Coast",
    "lat": -33.42,
    "lng": 151.3,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 3026,
    "id": 1036067845
  },
  {
    "city": "Mayumba",
    "city_ascii": "Mayumba",
    "lat": -3.4167,
    "lng": 10.65,
    "country": "Gabon",
    "iso2": "GA",
    "iso3": "GAB",
    "admin_name": "Nyanga",
    "capital": "",
    "population": 3996,
    "id": 1266317571
  },
  {
    "city": "La Paloma",
    "city_ascii": "La Paloma",
    "lat": -34.67,
    "lng": -54.17,
    "country": "Uruguay",
    "iso2": "UY",
    "iso3": "URY",
    "admin_name": "Rocha",
    "capital": "",
    "population": 3230,
    "id": 1858559267
  },
  {
    "city": "Nautla",
    "city_ascii": "Nautla",
    "lat": 20.2167,
    "lng": -96.7833,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Veracruz",
    "capital": "minor",
    "population": 2890,
    "id": 1484174535
  },
  {
    "city": "Finnsnes",
    "city_ascii": "Finnsnes",
    "lat": 69.2406,
    "lng": 18.0086,
    "country": "Norway",
    "iso2": "NO",
    "iso3": "NOR",
    "admin_name": "Troms",
    "capital": "minor",
    "population": 3907,
    "id": 1578015471
  },
  {
    "city": "Sapouy",
    "city_ascii": "Sapouy",
    "lat": 11.5544,
    "lng": -1.7736,
    "country": "Burkina Faso",
    "iso2": "BF",
    "iso3": "BFA",
    "admin_name": "Centre-Ouest",
    "capital": "minor",
    "population": 3837,
    "id": 1854971695
  },
  {
    "city": "Sicasica",
    "city_ascii": "Sicasica",
    "lat": -17.3333,
    "lng": -67.7333,
    "country": "Bolivia",
    "iso2": "BO",
    "iso3": "BOL",
    "admin_name": "La Paz",
    "capital": "",
    "population": 3831,
    "id": 1068066667
  },
  {
    "city": "Vergara",
    "city_ascii": "Vergara",
    "lat": -32.95,
    "lng": -53.9333,
    "country": "Uruguay",
    "iso2": "UY",
    "iso3": "URY",
    "admin_name": "Treinta y Tres",
    "capital": "",
    "population": 3810,
    "id": 1858979569
  },
  {
    "city": "Teseney",
    "city_ascii": "Teseney",
    "lat": 15.11,
    "lng": 36.6575,
    "country": "Eritrea",
    "iso2": "ER",
    "iso3": "ERI",
    "admin_name": "Gash Barka",
    "capital": "",
    "population": 3753,
    "id": 1232609825
  },
  {
    "city": "Pukë",
    "city_ascii": "Puke",
    "lat": 42.05,
    "lng": 19.9,
    "country": "Albania",
    "iso2": "AL",
    "iso3": "ALB",
    "admin_name": "Shkodër",
    "capital": "",
    "population": 3607,
    "id": 1008739218
  },
  {
    "city": "Ulaan-Uul",
    "city_ascii": "Ulaan-Uul",
    "lat": 44.3337,
    "lng": 111.2333,
    "country": "Mongolia",
    "iso2": "MN",
    "iso3": "MNG",
    "admin_name": "Dornogovĭ",
    "capital": "minor",
    "population": 3726,
    "id": 1496264968
  },
  {
    "city": "Yomou",
    "city_ascii": "Yomou",
    "lat": 7.566,
    "lng": -9.2533,
    "country": "Guinea",
    "iso2": "GN",
    "iso3": "GIN",
    "admin_name": "N’Zérékoré",
    "capital": "minor",
    "population": 3614,
    "id": 1324839778
  },
  {
    "city": "Lavumisa",
    "city_ascii": "Lavumisa",
    "lat": -27.3167,
    "lng": 31.9,
    "country": "Swaziland",
    "iso2": "SZ",
    "iso3": "SWZ",
    "admin_name": "Shiselweni",
    "capital": "",
    "population": 3695,
    "id": 1748490870
  },
  {
    "city": "Proserpine",
    "city_ascii": "Proserpine",
    "lat": -20.4017,
    "lng": 148.5814,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Queensland",
    "capital": "",
    "population": 3562,
    "id": 1036150859
  },
  {
    "city": "Port Douglas",
    "city_ascii": "Port Douglas",
    "lat": -16.4834,
    "lng": 145.4652,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Queensland",
    "capital": "",
    "population": 3504,
    "id": 1036053723
  },
  {
    "city": "Baltasar Brum",
    "city_ascii": "Baltasar Brum",
    "lat": -30.73,
    "lng": -57.32,
    "country": "Uruguay",
    "iso2": "UY",
    "iso3": "URY",
    "admin_name": "Artigas",
    "capital": "",
    "population": 2517,
    "id": 1858039985
  },
  {
    "city": "Clare",
    "city_ascii": "Clare",
    "lat": -33.8333,
    "lng": 138.6,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "South Australia",
    "capital": "",
    "population": 3160,
    "id": 1036842122
  },
  {
    "city": "Tom Price",
    "city_ascii": "Tom Price",
    "lat": -22.6939,
    "lng": 117.795,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Western Australia",
    "capital": "",
    "population": 3005,
    "id": 1036464109
  },
  {
    "city": "Hokitika",
    "city_ascii": "Hokitika",
    "lat": -42.7167,
    "lng": 170.9667,
    "country": "New Zealand",
    "iso2": "NZ",
    "iso3": "NZL",
    "admin_name": "West Coast",
    "capital": "",
    "population": 3090,
    "id": 1554205461
  },
  {
    "city": "Kirkenes",
    "city_ascii": "Kirkenes",
    "lat": 69.725,
    "lng": 30.0517,
    "country": "Norway",
    "iso2": "NO",
    "iso3": "NOR",
    "admin_name": "Finnmark",
    "capital": "minor",
    "population": 3529,
    "id": 1578657312
  },
  {
    "city": "Santa Bárbara",
    "city_ascii": "Santa Barbara",
    "lat": -37.6667,
    "lng": -72.0167,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Biobío",
    "capital": "",
    "population": 3494,
    "id": 1152827647
  },
  {
    "city": "Srednekolymsk",
    "city_ascii": "Srednekolymsk",
    "lat": 67.45,
    "lng": 153.7,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sakha (Yakutiya)",
    "capital": "",
    "population": 3489,
    "id": 1643536960
  },
  {
    "city": "Magdalena",
    "city_ascii": "Magdalena",
    "lat": -13.2606,
    "lng": -64.0528,
    "country": "Bolivia",
    "iso2": "BO",
    "iso3": "BOL",
    "admin_name": "El Beni",
    "capital": "",
    "population": 3445,
    "id": 1068699781
  },
  {
    "city": "Charleville",
    "city_ascii": "Charleville",
    "lat": -26.4016,
    "lng": 146.2383,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Queensland",
    "capital": "",
    "population": 3335,
    "id": 1036575498
  },
  {
    "city": "Sebba",
    "city_ascii": "Sebba",
    "lat": 13.4364,
    "lng": 0.5304,
    "country": "Burkina Faso",
    "iso2": "BF",
    "iso3": "BFA",
    "admin_name": "Sahel",
    "capital": "minor",
    "population": 3273,
    "id": 1854960093
  },
  {
    "city": "Donegal",
    "city_ascii": "Donegal",
    "lat": 54.65,
    "lng": -8.117,
    "country": "Ireland",
    "iso2": "IE",
    "iso3": "IRL",
    "admin_name": "Donegal",
    "capital": "",
    "population": 2513,
    "id": 1372576120
  },
  {
    "city": "Mezen",
    "city_ascii": "Mezen",
    "lat": 65.85,
    "lng": 44.2333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Arkhangel’skaya Oblast’",
    "capital": "",
    "population": 3267,
    "id": 1643928515
  },
  {
    "city": "Caballococha",
    "city_ascii": "Caballococha",
    "lat": -3.9058,
    "lng": -70.5164,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "Loreto",
    "capital": "",
    "population": 3195,
    "id": 1604340559
  },
  {
    "city": "Sinnamary",
    "city_ascii": "Sinnamary",
    "lat": 5.38,
    "lng": -52.96,
    "country": "French Guiana",
    "iso2": "GF",
    "iso3": "GUF",
    "admin_name": "",
    "capital": "minor",
    "population": 3180,
    "id": 1254385870
  },
  {
    "city": "Cloncurry",
    "city_ascii": "Cloncurry",
    "lat": -20.7,
    "lng": 140.5,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Queensland",
    "capital": "",
    "population": 2719,
    "id": 1036672657
  },
  {
    "city": "Bordertown",
    "city_ascii": "Bordertown",
    "lat": -36.3118,
    "lng": 140.7702,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "South Australia",
    "capital": "",
    "population": 2953,
    "id": 1036115980
  },
  {
    "city": "Aiguá",
    "city_ascii": "Aigua",
    "lat": -34.2,
    "lng": -54.75,
    "country": "Uruguay",
    "iso2": "UY",
    "iso3": "URY",
    "admin_name": "Maldonado",
    "capital": "",
    "population": 2692,
    "id": 1858068520
  },
  {
    "city": "Mkokotoni",
    "city_ascii": "Mkokotoni",
    "lat": -5.88,
    "lng": 39.2731,
    "country": "Tanzania",
    "iso2": "TZ",
    "iso3": "TZA",
    "admin_name": "Zanzibar North",
    "capital": "minor",
    "population": 2572,
    "id": 1834206004
  },
  {
    "city": "Karungu",
    "city_ascii": "Karungu",
    "lat": -0.8496,
    "lng": 34.15,
    "country": "Kenya",
    "iso2": "KE",
    "iso3": "KEN",
    "admin_name": "Migori",
    "capital": "",
    "population": 2376,
    "id": 1404796412
  },
  {
    "city": "Mangbwalu",
    "city_ascii": "Mangbwalu",
    "lat": 1.9504,
    "lng": 30.0333,
    "country": "Congo (Kinshasa)",
    "iso2": "CD",
    "iso3": "COD",
    "admin_name": "Ituri",
    "capital": "",
    "population": 2819,
    "id": 1180299514
  },
  {
    "city": "Longreach",
    "city_ascii": "Longreach",
    "lat": -23.4422,
    "lng": 144.2491,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Queensland",
    "capital": "",
    "population": 2970,
    "id": 1036622618
  },
  {
    "city": "General Conesa",
    "city_ascii": "General Conesa",
    "lat": -40.1,
    "lng": -64.4333,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Río Negro",
    "capital": "minor",
    "population": 2958,
    "id": 1032658385
  },
  {
    "city": "Merredin",
    "city_ascii": "Merredin",
    "lat": -31.482,
    "lng": 118.279,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Western Australia",
    "capital": "",
    "population": 2850,
    "id": 1036578320
  },
  {
    "city": "Samaipata",
    "city_ascii": "Samaipata",
    "lat": -18.1794,
    "lng": -63.8756,
    "country": "Bolivia",
    "iso2": "BO",
    "iso3": "BOL",
    "admin_name": "Santa Cruz",
    "capital": "",
    "population": 2926,
    "id": 1068681645
  },
  {
    "city": "Padilla",
    "city_ascii": "Padilla",
    "lat": -19.3075,
    "lng": -64.3022,
    "country": "Bolivia",
    "iso2": "BO",
    "iso3": "BOL",
    "admin_name": "Chuquisaca",
    "capital": "",
    "population": 2920,
    "id": 1068422501
  },
  {
    "city": "Pampa del Infierno",
    "city_ascii": "Pampa del Infierno",
    "lat": -26.5167,
    "lng": -61.1667,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Chaco",
    "capital": "minor",
    "population": 2921,
    "id": 1032896072
  },
  {
    "city": "Kailu",
    "city_ascii": "Kailu",
    "lat": 43.5837,
    "lng": 121.2,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Inner Mongolia",
    "capital": "minor",
    "population": 2809,
    "id": 1156813834
  },
  {
    "city": "Te Anau",
    "city_ascii": "Te Anau",
    "lat": -45.4167,
    "lng": 167.7167,
    "country": "New Zealand",
    "iso2": "NZ",
    "iso3": "NZL",
    "admin_name": "Southland",
    "capital": "",
    "population": 2250,
    "id": 1554603580
  },
  {
    "city": "Orocué",
    "city_ascii": "Orocue",
    "lat": 4.7946,
    "lng": -71.34,
    "country": "Colombia",
    "iso2": "CO",
    "iso3": "COL",
    "admin_name": "Casanare",
    "capital": "minor",
    "population": 2835,
    "id": 1170535682
  },
  {
    "city": "Urubamba",
    "city_ascii": "Urubamba",
    "lat": -13.3042,
    "lng": -72.1167,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "Cusco",
    "capital": "",
    "population": 2700,
    "id": 1604753575
  },
  {
    "city": "Hlatikulu",
    "city_ascii": "Hlatikulu",
    "lat": -26.9667,
    "lng": 31.3167,
    "country": "Swaziland",
    "iso2": "SZ",
    "iso3": "SWZ",
    "admin_name": "Shiselweni",
    "capital": "",
    "population": 2748,
    "id": 1748564468
  },
  {
    "city": "Cochrane",
    "city_ascii": "Cochrane",
    "lat": -47.2539,
    "lng": -72.5732,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Aysén",
    "capital": "minor",
    "population": 2789,
    "id": 1152054657
  },
  {
    "city": "Puerto Villamil",
    "city_ascii": "Puerto Villamil",
    "lat": -0.9333,
    "lng": -91.0167,
    "country": "Ecuador",
    "iso2": "EC",
    "iso3": "ECU",
    "admin_name": "Galápagos",
    "capital": "",
    "population": 2200,
    "id": 1218383536
  },
  {
    "city": "Nuquí",
    "city_ascii": "Nuqui",
    "lat": 5.6852,
    "lng": -77.2745,
    "country": "Colombia",
    "iso2": "CO",
    "iso3": "COL",
    "admin_name": "Chocó",
    "capital": "minor",
    "population": 2741,
    "id": 1170020146
  },
  {
    "city": "Saint-Georges",
    "city_ascii": "Saint-Georges",
    "lat": 3.9105,
    "lng": -51.81,
    "country": "French Guiana",
    "iso2": "GF",
    "iso3": "GUF",
    "admin_name": "",
    "capital": "minor",
    "population": 2742,
    "id": 1254726319
  },
  {
    "city": "Scottsdale",
    "city_ascii": "Scottsdale",
    "lat": -41.1667,
    "lng": 147.5167,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Tasmania",
    "capital": "",
    "population": 2373,
    "id": 1036807079
  },
  {
    "city": "Rørvik",
    "city_ascii": "Rorvik",
    "lat": 64.868,
    "lng": 11.2053,
    "country": "Norway",
    "iso2": "NO",
    "iso3": "NOR",
    "admin_name": "Nord-Trøndelag",
    "capital": "minor",
    "population": 2615,
    "id": 1578785320
  },
  {
    "city": "Ísafjörður",
    "city_ascii": "Isafjordhur",
    "lat": 66.0738,
    "lng": -23.1417,
    "country": "Iceland",
    "iso2": "IS",
    "iso3": "ISL",
    "admin_name": "Vestfirðir",
    "capital": "",
    "population": 2620,
    "id": 1352563432
  },
  {
    "city": "Bourke",
    "city_ascii": "Bourke",
    "lat": -30.0817,
    "lng": 145.9511,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 1909,
    "id": 1036074667
  },
  {
    "city": "Chumbicha",
    "city_ascii": "Chumbicha",
    "lat": -28.8666,
    "lng": -66.2333,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Catamarca",
    "capital": "minor",
    "population": 2572,
    "id": 1032994295
  },
  {
    "city": "Sauðárkrókur",
    "city_ascii": "Saudharkrokur",
    "lat": 65.7461,
    "lng": -19.6394,
    "country": "Iceland",
    "iso2": "IS",
    "iso3": "ISL",
    "admin_name": "Norðurland Vestra",
    "capital": "",
    "population": 2574,
    "id": 1352929836
  },
  {
    "city": "Gobernador Gregores",
    "city_ascii": "Gobernador Gregores",
    "lat": -48.7666,
    "lng": -70.25,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Santa Cruz",
    "capital": "minor",
    "population": 2519,
    "id": 1032720049
  },
  {
    "city": "Severo-Kuril’sk",
    "city_ascii": "Severo-Kuril'sk",
    "lat": 50.6833,
    "lng": 156.1167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sakhalinskaya Oblast’",
    "capital": "",
    "population": 2507,
    "id": 1643689971
  },
  {
    "city": "Nauta",
    "city_ascii": "Nauta",
    "lat": -4.5083,
    "lng": -73.5833,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "Loreto",
    "capital": "",
    "population": 2500,
    "id": 1604159322
  },
  {
    "city": "Exmouth",
    "city_ascii": "Exmouth",
    "lat": -21.9331,
    "lng": 114.1281,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Western Australia",
    "capital": "",
    "population": 2486,
    "id": 1036006022
  },
  {
    "city": "Queenstown",
    "city_ascii": "Queenstown",
    "lat": -42.0667,
    "lng": 145.55,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Tasmania",
    "capital": "",
    "population": 1790,
    "id": 1036082142
  },
  {
    "city": "Tarabuco",
    "city_ascii": "Tarabuco",
    "lat": -19.1825,
    "lng": -64.915,
    "country": "Bolivia",
    "iso2": "BO",
    "iso3": "BOL",
    "admin_name": "Chuquisaca",
    "capital": "",
    "population": 2428,
    "id": 1068481915
  },
  {
    "city": "Baures",
    "city_ascii": "Baures",
    "lat": -13.5833,
    "lng": -63.5833,
    "country": "Bolivia",
    "iso2": "BO",
    "iso3": "BOL",
    "admin_name": "El Beni",
    "capital": "",
    "population": 2422,
    "id": 1068657733
  },
  {
    "city": "Al ‘Alamayn",
    "city_ascii": "Al `Alamayn",
    "lat": 30.8333,
    "lng": 28.95,
    "country": "Egypt",
    "iso2": "EG",
    "iso3": "EGY",
    "admin_name": "Maţrūḩ",
    "capital": "",
    "population": 2413,
    "id": 1818237095
  },
  {
    "city": "Vila Bela da Santíssima Trindade",
    "city_ascii": "Vila Bela da Santissima Trindade",
    "lat": -15,
    "lng": -59.95,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Mato Grosso",
    "capital": "",
    "population": 1612,
    "id": 1076493823
  },
  {
    "city": "El Dorado",
    "city_ascii": "El Dorado",
    "lat": 6.7167,
    "lng": -61.6167,
    "country": "Venezuela",
    "iso2": "VE",
    "iso3": "VEN",
    "admin_name": "Bolívar",
    "capital": "",
    "population": 2383,
    "id": 1862242782
  },
  {
    "city": "Höfn",
    "city_ascii": "Hofn",
    "lat": 64.25,
    "lng": -15.2167,
    "country": "Iceland",
    "iso2": "IS",
    "iso3": "ISL",
    "admin_name": "Austurland",
    "capital": "",
    "population": 2389,
    "id": 1352857630
  },
  {
    "city": "Boffa",
    "city_ascii": "Boffa",
    "lat": 10.185,
    "lng": -14.043,
    "country": "Guinea",
    "iso2": "GN",
    "iso3": "GIN",
    "admin_name": "Boké",
    "capital": "minor",
    "population": 2332,
    "id": 1324801511
  },
  {
    "city": "Dondo",
    "city_ascii": "Dondo",
    "lat": -9.6942,
    "lng": 14.4228,
    "country": "Angola",
    "iso2": "AO",
    "iso3": "AGO",
    "admin_name": "Kwanza Norte",
    "capital": "",
    "population": 2353,
    "id": 1024323066
  },
  {
    "city": "Juradó",
    "city_ascii": "Jurado",
    "lat": 7.1114,
    "lng": -77.7714,
    "country": "Colombia",
    "iso2": "CO",
    "iso3": "COL",
    "admin_name": "Chocó",
    "capital": "minor",
    "population": 2351,
    "id": 1170520604
  },
  {
    "city": "San Julián",
    "city_ascii": "San Julian",
    "lat": -49.3,
    "lng": -67.7167,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Santa Cruz",
    "capital": "minor",
    "population": 2347,
    "id": 1032464702
  },
  {
    "city": "Coroico",
    "city_ascii": "Coroico",
    "lat": -16.1833,
    "lng": -67.7333,
    "country": "Bolivia",
    "iso2": "BO",
    "iso3": "BOL",
    "admin_name": "La Paz",
    "capital": "",
    "population": 2319,
    "id": 1068106383
  },
  {
    "city": "Egilsstaðir",
    "city_ascii": "Egilsstadhir",
    "lat": 65.2667,
    "lng": -14.4,
    "country": "Iceland",
    "iso2": "IS",
    "iso3": "ISL",
    "admin_name": "Austurland",
    "capital": "",
    "population": 2332,
    "id": 1352699507
  },
  {
    "city": "Mount Barker",
    "city_ascii": "Mount Barker",
    "lat": -34.63,
    "lng": 117.6669,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Western Australia",
    "capital": "",
    "population": 1905,
    "id": 1036651498
  },
  {
    "city": "Roura",
    "city_ascii": "Roura",
    "lat": 4.73,
    "lng": -52.33,
    "country": "French Guiana",
    "iso2": "GF",
    "iso3": "GUF",
    "admin_name": "",
    "capital": "minor",
    "population": 2229,
    "id": 1254511393
  },
  {
    "city": "Sorata",
    "city_ascii": "Sorata",
    "lat": -15.7736,
    "lng": -68.6486,
    "country": "Bolivia",
    "iso2": "BO",
    "iso3": "BOL",
    "admin_name": "La Paz",
    "capital": "",
    "population": 2217,
    "id": 1068157921
  },
  {
    "city": "Plumtree",
    "city_ascii": "Plumtree",
    "lat": -20.4869,
    "lng": 27.8042,
    "country": "Zimbabwe",
    "iso2": "ZW",
    "iso3": "ZWE",
    "admin_name": "Matabeleland South",
    "capital": "",
    "population": 2148,
    "id": 1716916272
  },
  {
    "city": "Kaikoura",
    "city_ascii": "Kaikoura",
    "lat": -42.4,
    "lng": 173.6814,
    "country": "New Zealand",
    "iso2": "NZ",
    "iso3": "NZL",
    "admin_name": "Canterbury",
    "capital": "",
    "population": 2210,
    "id": 1554578431
  },
  {
    "city": "Katwe",
    "city_ascii": "Katwe",
    "lat": -0.1296,
    "lng": 29.92,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Kasese",
    "capital": "",
    "population": 1957,
    "id": 1800329579
  },
  {
    "city": "Alexander Bay",
    "city_ascii": "Alexander Bay",
    "lat": -28.6083,
    "lng": 16.5033,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "Northern Cape",
    "capital": "",
    "population": 1500,
    "id": 1710616152
  },
  {
    "city": "Wagin",
    "city_ascii": "Wagin",
    "lat": -33.3167,
    "lng": 117.35,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Western Australia",
    "capital": "",
    "population": 1824,
    "id": 1036851354
  },
  {
    "city": "Tasiilaq",
    "city_ascii": "Tasiilaq",
    "lat": 65.615,
    "lng": -37.641,
    "country": "Greenland",
    "iso2": "GL",
    "iso3": "GRL",
    "admin_name": "Sermersooq",
    "capital": "",
    "population": 2018,
    "id": 1304230512
  },
  {
    "city": "Kingston South East",
    "city_ascii": "Kingston South East",
    "lat": -36.8167,
    "lng": 139.85,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "South Australia",
    "capital": "",
    "population": 1648,
    "id": 1036261175
  },
  {
    "city": "Tumby Bay",
    "city_ascii": "Tumby Bay",
    "lat": -34.3667,
    "lng": 136.1,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "South Australia",
    "capital": "",
    "population": 1474,
    "id": 1036529327
  },
  {
    "city": "Borgarnes",
    "city_ascii": "Borgarnes",
    "lat": 64.5333,
    "lng": -21.9167,
    "country": "Iceland",
    "iso2": "IS",
    "iso3": "ISL",
    "admin_name": "Vesturland",
    "capital": "",
    "population": 1962,
    "id": 1352063842
  },
  {
    "city": "Qasigiannguit",
    "city_ascii": "Qasigiannguit",
    "lat": 68.8201,
    "lng": -51.1932,
    "country": "Greenland",
    "iso2": "GL",
    "iso3": "GRL",
    "admin_name": "Qaasuitsup",
    "capital": "",
    "population": 1803,
    "id": 1304001772
  },
  {
    "city": "Puerto Williams",
    "city_ascii": "Puerto Williams",
    "lat": -54.9341,
    "lng": -67.6109,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Magallanes y de la Antártica Chilena",
    "capital": "minor",
    "population": 1868,
    "id": 1152455279
  },
  {
    "city": "Kalbarri",
    "city_ascii": "Kalbarri",
    "lat": -27.71,
    "lng": 114.16,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Western Australia",
    "capital": "",
    "population": 1349,
    "id": 1036429141
  },
  {
    "city": "Vatican City",
    "city_ascii": "Vatican City",
    "lat": 41.9,
    "lng": 12.4478,
    "country": "Vatican City",
    "iso2": "VA",
    "iso3": "VAT",
    "admin_name": "",
    "capital": "",
    "population": 825,
    "id": 1336000000
  },
  {
    "city": "Port Denison",
    "city_ascii": "Port Denison",
    "lat": -29.2833,
    "lng": 114.9167,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Western Australia",
    "capital": "",
    "population": 1410,
    "id": 1036873405
  },
  {
    "city": "Penola",
    "city_ascii": "Penola",
    "lat": -37.3786,
    "lng": 140.8362,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "South Australia",
    "capital": "",
    "population": 1312,
    "id": 1036121853
  },
  {
    "city": "Artëmovsk",
    "city_ascii": "Artemovsk",
    "lat": 54.35,
    "lng": 93.4333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Krasnoyarskiy Kray",
    "capital": "",
    "population": 1688,
    "id": 1643012116
  },
  {
    "city": "Streaky Bay",
    "city_ascii": "Streaky Bay",
    "lat": -32.8,
    "lng": 134.2167,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "South Australia",
    "capital": "",
    "population": 1378,
    "id": 1036134944
  },
  {
    "city": "Cuevo",
    "city_ascii": "Cuevo",
    "lat": -20.4547,
    "lng": -63.5189,
    "country": "Bolivia",
    "iso2": "BO",
    "iso3": "BOL",
    "admin_name": "Santa Cruz",
    "capital": "",
    "population": 1637,
    "id": 1068137787
  },
  {
    "city": "Uummannaq",
    "city_ascii": "Uummannaq",
    "lat": 70.6747,
    "lng": -52.1264,
    "country": "Greenland",
    "iso2": "GL",
    "iso3": "GRL",
    "admin_name": "Qaasuitsup",
    "capital": "",
    "population": 1497,
    "id": 1304056798
  },
  {
    "city": "Halls Creek",
    "city_ascii": "Halls Creek",
    "lat": -18.23,
    "lng": 127.67,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Western Australia",
    "capital": "",
    "population": 1546,
    "id": 1036444511
  },
  {
    "city": "Trancas",
    "city_ascii": "Trancas",
    "lat": -26.2167,
    "lng": -65.2833,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Tucumán",
    "capital": "minor",
    "population": 1599,
    "id": 1032418123
  },
  {
    "city": "Peterborough",
    "city_ascii": "Peterborough",
    "lat": -32.9667,
    "lng": 138.8333,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "South Australia",
    "capital": "",
    "population": 1416,
    "id": 1036104272
  },
  {
    "city": "Alto Río Senguer",
    "city_ascii": "Alto Rio Senguer",
    "lat": -45.0419,
    "lng": -70.8234,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Chubut",
    "capital": "minor",
    "population": 1570,
    "id": 1032532362
  },
  {
    "city": "Iracoubo",
    "city_ascii": "Iracoubo",
    "lat": 5.4804,
    "lng": -53.22,
    "country": "French Guiana",
    "iso2": "GF",
    "iso3": "GUF",
    "admin_name": "",
    "capital": "minor",
    "population": 1536,
    "id": 1254480447
  },
  {
    "city": "Ouyen",
    "city_ascii": "Ouyen",
    "lat": -35.0667,
    "lng": 142.317,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Victoria",
    "capital": "",
    "population": 1191,
    "id": 1036764173
  },
  {
    "city": "Sierra Colorada",
    "city_ascii": "Sierra Colorada",
    "lat": -40.5829,
    "lng": -67.8,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Río Negro",
    "capital": "minor",
    "population": 1522,
    "id": 1032417224
  },
  {
    "city": "San Vicente del Caguán",
    "city_ascii": "San Vicente del Caguan",
    "lat": 2.0704,
    "lng": -74.64,
    "country": "Colombia",
    "iso2": "CO",
    "iso3": "COL",
    "admin_name": "Caquetá",
    "capital": "minor",
    "population": 1500,
    "id": 1170922792
  },
  {
    "city": "Katherine",
    "city_ascii": "Katherine",
    "lat": -14.4667,
    "lng": 132.2667,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Northern Territory",
    "capital": "",
    "population": 1488,
    "id": 1036323110
  },
  {
    "city": "Oatlands",
    "city_ascii": "Oatlands",
    "lat": -42.2996,
    "lng": 147.3666,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Tasmania",
    "capital": "",
    "population": 1157,
    "id": 1036926323
  },
  {
    "city": "Paamiut",
    "city_ascii": "Paamiut",
    "lat": 61.9956,
    "lng": -49.6636,
    "country": "Greenland",
    "iso2": "GL",
    "iso3": "GRL",
    "admin_name": "Sermersooq",
    "capital": "",
    "population": 1429,
    "id": 1304323922
  },
  {
    "city": "Barcaldine",
    "city_ascii": "Barcaldine",
    "lat": -23.5555,
    "lng": 145.2888,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Queensland",
    "capital": "",
    "population": 1422,
    "id": 1036284609
  },
  {
    "city": "Tarutung",
    "city_ascii": "Tarutung",
    "lat": 2.0171,
    "lng": 98.9667,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Sumatera Utara",
    "capital": "minor",
    "population": 1305,
    "id": 1360333240
  },
  {
    "city": "Jaqué",
    "city_ascii": "Jaque",
    "lat": 7.519,
    "lng": -78.166,
    "country": "Panama",
    "iso2": "PA",
    "iso3": "PAN",
    "admin_name": "Darién",
    "capital": "minor",
    "population": 1357,
    "id": 1591579329
  },
  {
    "city": "Cowell",
    "city_ascii": "Cowell",
    "lat": -33.6833,
    "lng": 136.9167,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "South Australia",
    "capital": "",
    "population": 1109,
    "id": 1036921929
  },
  {
    "city": "Príncipe da Beira",
    "city_ascii": "Principe da Beira",
    "lat": -12.4167,
    "lng": -64.4166,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Rondônia",
    "capital": "",
    "population": 956,
    "id": 1076482734
  },
  {
    "city": "Meningie",
    "city_ascii": "Meningie",
    "lat": -35.6883,
    "lng": 139.338,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "South Australia",
    "capital": "",
    "population": 1118,
    "id": 1036445634
  },
  {
    "city": "Las Lajas",
    "city_ascii": "Las Lajas",
    "lat": -38.5163,
    "lng": -70.3666,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Neuquén",
    "capital": "minor",
    "population": 1218,
    "id": 1032092474
  },
  {
    "city": "Upernavik",
    "city_ascii": "Upernavik",
    "lat": 72.7839,
    "lng": -56.1506,
    "country": "Greenland",
    "iso2": "GL",
    "iso3": "GRL",
    "admin_name": "Qaasuitsup",
    "capital": "",
    "population": 1218,
    "id": 1304750851
  },
  {
    "city": "Hughenden",
    "city_ascii": "Hughenden",
    "lat": -20.8438,
    "lng": 144.1986,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Queensland",
    "capital": "",
    "population": 1136,
    "id": 1036738932
  },
  {
    "city": "Verkhoyansk",
    "city_ascii": "Verkhoyansk",
    "lat": 67.55,
    "lng": 133.3833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sakha (Yakutiya)",
    "capital": "",
    "population": 1122,
    "id": 1643386560
  },
  {
    "city": "Meekatharra",
    "city_ascii": "Meekatharra",
    "lat": -26.5931,
    "lng": 118.4911,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Western Australia",
    "capital": "",
    "population": 708,
    "id": 1036608457
  },
  {
    "city": "Yulara",
    "city_ascii": "Yulara",
    "lat": -25.2406,
    "lng": 130.9889,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Northern Territory",
    "capital": "",
    "population": 1099,
    "id": 1036245584
  },
  {
    "city": "Wyndham",
    "city_ascii": "Wyndham",
    "lat": -15.4825,
    "lng": 128.123,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Western Australia",
    "capital": "",
    "population": 780,
    "id": 1036474435
  },
  {
    "city": "Susques",
    "city_ascii": "Susques",
    "lat": -23.4006,
    "lng": -66.3672,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Jujuy",
    "capital": "minor",
    "population": 1093,
    "id": 1032742255
  },
  {
    "city": "Roebourne",
    "city_ascii": "Roebourne",
    "lat": -20.7667,
    "lng": 117.15,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Western Australia",
    "capital": "",
    "population": 981,
    "id": 1036752479
  },
  {
    "city": "Yélimané",
    "city_ascii": "Yelimane",
    "lat": 15.1337,
    "lng": -10.5666,
    "country": "Mali",
    "iso2": "ML",
    "iso3": "MLI",
    "admin_name": "Kayes",
    "capital": "minor",
    "population": 988,
    "id": 1466380722
  },
  {
    "city": "Tunduru",
    "city_ascii": "Tunduru",
    "lat": -11.0896,
    "lng": 37.37,
    "country": "Tanzania",
    "iso2": "TZ",
    "iso3": "TZA",
    "admin_name": "Ruvuma",
    "capital": "",
    "population": 600,
    "id": 1834030694
  },
  {
    "city": "Bicheno",
    "city_ascii": "Bicheno",
    "lat": -41.8667,
    "lng": 148.2833,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Tasmania",
    "capital": "",
    "population": 943,
    "id": 1036189642
  },
  {
    "city": "Kullorsuaq",
    "city_ascii": "Kullorsuaq",
    "lat": 74.5781,
    "lng": -57.225,
    "country": "Greenland",
    "iso2": "GL",
    "iso3": "GRL",
    "admin_name": "Qaasuitsup",
    "capital": "",
    "population": 450,
    "id": 1304004889
  },
  {
    "city": "Winton",
    "city_ascii": "Winton",
    "lat": -22.3913,
    "lng": 143.0381,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Queensland",
    "capital": "",
    "population": 875,
    "id": 1036031036
  },
  {
    "city": "Godhavn",
    "city_ascii": "Godhavn",
    "lat": 69.2472,
    "lng": -53.5333,
    "country": "Greenland",
    "iso2": "GL",
    "iso3": "GRL",
    "admin_name": "Qaasuitsup",
    "capital": "",
    "population": 847,
    "id": 1304388071
  },
  {
    "city": "Leonora",
    "city_ascii": "Leonora",
    "lat": -28.88,
    "lng": 121.33,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Western Australia",
    "capital": "",
    "population": 781,
    "id": 1036140404
  },
  {
    "city": "Gingin",
    "city_ascii": "Gingin",
    "lat": -31.34,
    "lng": 115.91,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Western Australia",
    "capital": "",
    "population": 852,
    "id": 1036686561
  },
  {
    "city": "Wilcannia",
    "city_ascii": "Wilcannia",
    "lat": -31.565,
    "lng": 143.3678,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 745,
    "id": 1036134474
  },
  {
    "city": "Onslow",
    "city_ascii": "Onslow",
    "lat": -21.6333,
    "lng": 115.1167,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Western Australia",
    "capital": "",
    "population": 857,
    "id": 1036255184
  },
  {
    "city": "Laverton",
    "city_ascii": "Laverton",
    "lat": -28.628,
    "lng": 122.403,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Western Australia",
    "capital": "",
    "population": 871,
    "id": 1036624366
  },
  {
    "city": "Morawa",
    "city_ascii": "Morawa",
    "lat": -29.2111,
    "lng": 116.009,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Western Australia",
    "capital": "",
    "population": 532,
    "id": 1036194388
  },
  {
    "city": "Southern Cross",
    "city_ascii": "Southern Cross",
    "lat": -31.25,
    "lng": 119.35,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Western Australia",
    "capital": "",
    "population": 680,
    "id": 1036931490
  },
  {
    "city": "Linxi",
    "city_ascii": "Linxi",
    "lat": 43.5171,
    "lng": 118.0333,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Inner Mongolia",
    "capital": "minor",
    "population": 679,
    "id": 1156608652
  },
  {
    "city": "Norseman",
    "city_ascii": "Norseman",
    "lat": -32.1961,
    "lng": 121.778,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Western Australia",
    "capital": "",
    "population": 581,
    "id": 1036257263
  },
  {
    "city": "Ravensthorpe",
    "city_ascii": "Ravensthorpe",
    "lat": -33.5831,
    "lng": 120.049,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Western Australia",
    "capital": "",
    "population": 498,
    "id": 1036878618
  },
  {
    "city": "Eidsvold",
    "city_ascii": "Eidsvold",
    "lat": -25.3667,
    "lng": 151.1333,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Queensland",
    "capital": "",
    "population": 574,
    "id": 1036235197
  },
  {
    "city": "Pannawonica",
    "city_ascii": "Pannawonica",
    "lat": -21.6333,
    "lng": 116.333,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Western Australia",
    "capital": "",
    "population": 695,
    "id": 1036914573
  },
  {
    "city": "Rodeo",
    "city_ascii": "Rodeo",
    "lat": -30.2156,
    "lng": -69.14,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "San Juan",
    "capital": "minor",
    "population": 701,
    "id": 1032937217
  },
  {
    "city": "Ubombo",
    "city_ascii": "Ubombo",
    "lat": -27.5667,
    "lng": 32.0833,
    "country": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "admin_name": "KwaZulu-Natal",
    "capital": "",
    "population": 564,
    "id": 1710203500
  },
  {
    "city": "Qaanaaq",
    "city_ascii": "Qaanaaq",
    "lat": 77.467,
    "lng": -69.233,
    "country": "Greenland",
    "iso2": "GL",
    "iso3": "GRL",
    "admin_name": "Qaasuitsup",
    "capital": "",
    "population": 678,
    "id": 1304094556
  },
  {
    "city": "Kimba",
    "city_ascii": "Kimba",
    "lat": -33.1333,
    "lng": 136.417,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "South Australia",
    "capital": "",
    "population": 629,
    "id": 1036035341
  },
  {
    "city": "Peterborough",
    "city_ascii": "Peterborough",
    "lat": 52.5833,
    "lng": -0.25,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Peterborough",
    "capital": "",
    "population": 82,
    "id": 1826626144
  },
  {
    "city": "Mount Magnet",
    "city_ascii": "Mount Magnet",
    "lat": -28.06,
    "lng": 117.846,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Western Australia",
    "capital": "",
    "population": 470,
    "id": 1036327720
  },
  {
    "city": "Three Springs",
    "city_ascii": "Three Springs",
    "lat": -29.5333,
    "lng": 115.717,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Western Australia",
    "capital": "",
    "population": 381,
    "id": 1036669084
  },
  {
    "city": "Theodore",
    "city_ascii": "Theodore",
    "lat": -24.95,
    "lng": 150.0833,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Queensland",
    "capital": "",
    "population": 438,
    "id": 1036423364
  },
  {
    "city": "Gastre",
    "city_ascii": "Gastre",
    "lat": -42.2667,
    "lng": -69.2167,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Chubut",
    "capital": "minor",
    "population": 602,
    "id": 1032815504
  },
  {
    "city": "Tinogasta",
    "city_ascii": "Tinogasta",
    "lat": -28.0666,
    "lng": -67.5666,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Catamarca",
    "capital": "minor",
    "population": 587,
    "id": 1032163046
  },
  {
    "city": "Lasserre",
    "city_ascii": "Lasserre",
    "lat": 16.2304,
    "lng": -61.44,
    "country": "Guadeloupe",
    "iso2": "GP",
    "iso3": "GLP",
    "admin_name": "",
    "capital": "",
    "population": 307,
    "id": 1312109298
  },
  {
    "city": "Scoresbysund",
    "city_ascii": "Scoresbysund",
    "lat": 70.4853,
    "lng": -21.9667,
    "country": "Greenland",
    "iso2": "GL",
    "iso3": "GRL",
    "admin_name": "Sermersooq",
    "capital": "",
    "population": 539,
    "id": 1304036812
  },
  {
    "city": "Telsen",
    "city_ascii": "Telsen",
    "lat": -42.3833,
    "lng": -66.95,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Chubut",
    "capital": "minor",
    "population": 544,
    "id": 1032824407
  },
  {
    "city": "Karumba",
    "city_ascii": "Karumba",
    "lat": -17.4838,
    "lng": 140.8397,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Queensland",
    "capital": "",
    "population": 531,
    "id": 1036926106
  },
  {
    "city": "Richmond",
    "city_ascii": "Richmond",
    "lat": -20.7306,
    "lng": 143.1422,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Queensland",
    "capital": "",
    "population": 522,
    "id": 1036156498
  },
  {
    "city": "Halfmoon Bay",
    "city_ascii": "Halfmoon Bay",
    "lat": -46.899,
    "lng": 168.127,
    "country": "New Zealand",
    "iso2": "NZ",
    "iso3": "NZL",
    "admin_name": "Southland",
    "capital": "",
    "population": 310,
    "id": 1554077152
  },
  {
    "city": "Andamooka",
    "city_ascii": "Andamooka",
    "lat": -30.447,
    "lng": 137.166,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "South Australia",
    "capital": "",
    "population": 316,
    "id": 1036260794
  },
  {
    "city": "Georgetown",
    "city_ascii": "Georgetown",
    "lat": -18.3,
    "lng": 143.55,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Queensland",
    "capital": "",
    "population": 348,
    "id": 1036412066
  },
  {
    "city": "Xangongo",
    "city_ascii": "Xangongo",
    "lat": -16.7467,
    "lng": 14.9747,
    "country": "Angola",
    "iso2": "AO",
    "iso3": "AGO",
    "admin_name": "Cunene",
    "capital": "",
    "population": 447,
    "id": 1024323582
  },
  {
    "city": "Boulia",
    "city_ascii": "Boulia",
    "lat": -22.9,
    "lng": 139.9,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Queensland",
    "capital": "",
    "population": 301,
    "id": 1036512474
  },
  {
    "city": "Espungabera",
    "city_ascii": "Espungabera",
    "lat": -20.4531,
    "lng": 32.7725,
    "country": "Mozambique",
    "iso2": "MZ",
    "iso3": "MOZ",
    "admin_name": "Manica",
    "capital": "",
    "population": 393,
    "id": 1508256538
  },
  {
    "city": "Adelaide River",
    "city_ascii": "Adelaide River",
    "lat": -13.2381,
    "lng": 131.1061,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Northern Territory",
    "capital": "",
    "population": 353,
    "id": 1036136750
  },
  {
    "city": "Burketown",
    "city_ascii": "Burketown",
    "lat": -17.7167,
    "lng": 139.5667,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Queensland",
    "capital": "",
    "population": 238,
    "id": 1036956849
  },
  {
    "city": "Progress",
    "city_ascii": "Progress",
    "lat": 49.7504,
    "lng": 129.6167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Amurskaya Oblast’",
    "capital": "",
    "population": 146,
    "id": 1643007077
  },
  {
    "city": "Kairaki",
    "city_ascii": "Kairaki",
    "lat": -43.385,
    "lng": 172.703,
    "country": "New Zealand",
    "iso2": "NZ",
    "iso3": "NZL",
    "admin_name": "Canterbury",
    "capital": "",
    "population": 225,
    "id": 1554001309
  },
  {
    "city": "Ivanhoe",
    "city_ascii": "Ivanhoe",
    "lat": -32.8983,
    "lng": 144.3,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "New South Wales",
    "capital": "",
    "population": 327,
    "id": 1036571526
  },
  {
    "city": "Nimule",
    "city_ascii": "Nimule",
    "lat": 3.6,
    "lng": 32.06,
    "country": "South Sudan",
    "iso2": "SS",
    "iso3": "SSD",
    "admin_name": "Eastern Equatoria",
    "capital": "",
    "population": 242,
    "id": 1728476966
  },
  {
    "city": "Thargomindah",
    "city_ascii": "Thargomindah",
    "lat": -28,
    "lng": 143.8167,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Queensland",
    "capital": "",
    "population": 270,
    "id": 1036793708
  },
  {
    "city": "Pine Creek",
    "city_ascii": "Pine Creek",
    "lat": -13.8231,
    "lng": 131.833,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Northern Territory",
    "capital": "",
    "population": 328,
    "id": 1036858004
  },
  {
    "city": "Ikela",
    "city_ascii": "Ikela",
    "lat": -1.1833,
    "lng": 23.2667,
    "country": "Congo (Kinshasa)",
    "iso2": "CD",
    "iso3": "COD",
    "admin_name": "Tshuapa",
    "capital": "",
    "population": 291,
    "id": 1180365335
  },
  {
    "city": "Timbedgha",
    "city_ascii": "Timbedgha",
    "lat": 16.2447,
    "lng": -8.1675,
    "country": "Mauritania",
    "iso2": "MR",
    "iso3": "MRT",
    "admin_name": "Hodh ech Chargui",
    "capital": "",
    "population": 245,
    "id": 1478847634
  },
  {
    "city": "Greytown",
    "city_ascii": "Greytown",
    "lat": 10.9167,
    "lng": -83.7,
    "country": "Nicaragua",
    "iso2": "NI",
    "iso3": "NIC",
    "admin_name": "Río San Juan",
    "capital": "minor",
    "population": 275,
    "id": 1558125707
  },
  {
    "city": "Camooweal",
    "city_ascii": "Camooweal",
    "lat": -19.9167,
    "lng": 138.117,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Queensland",
    "capital": "",
    "population": 208,
    "id": 1036815011
  },
  {
    "city": "Vilankulo",
    "city_ascii": "Vilankulo",
    "lat": -22,
    "lng": 35.3167,
    "country": "Mozambique",
    "iso2": "MZ",
    "iso3": "MOZ",
    "admin_name": "Inhambane",
    "capital": "",
    "population": 177,
    "id": 1508379289
  },
  {
    "city": "Sayḩūt",
    "city_ascii": "Sayhut",
    "lat": 15.2105,
    "lng": 51.2454,
    "country": "Yemen",
    "iso2": "YE",
    "iso3": "YEM",
    "admin_name": "Al Mahrah",
    "capital": "minor",
    "population": 189,
    "id": 1887613470
  },
  {
    "city": "Río Cuarto",
    "city_ascii": "Rio Cuarto",
    "lat": -33.123,
    "lng": -64.3478,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Córdoba",
    "capital": "minor",
    "population": 158.298,
    "id": 1032552145
  },
  {
    "city": "La Esmeralda",
    "city_ascii": "La Esmeralda",
    "lat": 3.1738,
    "lng": -65.5466,
    "country": "Venezuela",
    "iso2": "VE",
    "iso3": "VEN",
    "admin_name": "Amazonas",
    "capital": "minor",
    "population": 150,
    "id": 1862255876
  },
  {
    "city": "Birdsville",
    "city_ascii": "Birdsville",
    "lat": -25.8989,
    "lng": 139.352,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Queensland",
    "capital": "",
    "population": 140,
    "id": 1036830335
  },
  {
    "city": "Bedourie",
    "city_ascii": "Bedourie",
    "lat": -24.35,
    "lng": 139.4667,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Queensland",
    "capital": "",
    "population": 122,
    "id": 1036680924
  },
  {
    "city": "Windorah",
    "city_ascii": "Windorah",
    "lat": -25.4206,
    "lng": 142.6547,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Queensland",
    "capital": "",
    "population": 115,
    "id": 1036272226
  },
  {
    "city": "Punta Prieta",
    "city_ascii": "Punta Prieta",
    "lat": 28.9289,
    "lng": -114.1556,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Baja California",
    "capital": "",
    "population": 112,
    "id": 1484583029
  },
  {
    "city": "Al ‘Uqaylah",
    "city_ascii": "Al `Uqaylah",
    "lat": 30.2558,
    "lng": 19.1994,
    "country": "Libya",
    "iso2": "LY",
    "iso3": "LBY",
    "admin_name": "Al Wāḩāt",
    "capital": "",
    "population": 100,
    "id": 1434822941
  },
  {
    "city": "Ituni",
    "city_ascii": "Ituni",
    "lat": 5.5333,
    "lng": -58.25,
    "country": "Guyana",
    "iso2": "GY",
    "iso3": "GUY",
    "admin_name": "Upper Demerara-Berbice",
    "capital": "",
    "population": 100,
    "id": 1328501744
  },
  {
    "city": "Southend",
    "city_ascii": "Southend",
    "lat": 51.55,
    "lng": 0.71,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Southend-on-Sea",
    "capital": "",
    "population": 93,
    "id": 1826524208
  },
  {
    "city": "As Sidrah",
    "city_ascii": "As Sidrah",
    "lat": 30.6294,
    "lng": 18.3503,
    "country": "Libya",
    "iso2": "LY",
    "iso3": "LBY",
    "admin_name": "Surt",
    "capital": "",
    "population": 50,
    "id": 1434673703
  },
  {
    "city": "Kingoonya",
    "city_ascii": "Kingoonya",
    "lat": -30.9,
    "lng": 135.3,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "South Australia",
    "capital": "",
    "population": 13,
    "id": 1036942792
  },
  {
    "city": "Hödrögö",
    "city_ascii": "Hodrogo",
    "lat": 48.9664,
    "lng": 96.7833,
    "country": "Mongolia",
    "iso2": "MN",
    "iso3": "MNG",
    "admin_name": "Dzavhan",
    "capital": "minor",
    "population": 10,
    "id": 1496403046
  },
  {
    "city": "Chegga",
    "city_ascii": "Chegga",
    "lat": 25.3719,
    "lng": -5.7867,
    "country": "Mauritania",
    "iso2": "MR",
    "iso3": "MRT",
    "admin_name": "Tiris Zemmour",
    "capital": "",
    "population": 10,
    "id": 1478632134
  },
  {
    "city": "Euxton",
    "city_ascii": "Euxton",
    "lat": 53.662,
    "lng": -2.674,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Lancashire",
    "capital": "",
    "population": 9993,
    "id": 1826461548
  },
  {
    "city": "Mazoe",
    "city_ascii": "Mazoe",
    "lat": -17.5196,
    "lng": 30.97,
    "country": "Zimbabwe",
    "iso2": "ZW",
    "iso3": "ZWE",
    "admin_name": "Mashonaland Central",
    "capital": "",
    "population": 9966,
    "id": 1716608716
  },
  {
    "city": "Al Qurayyāt",
    "city_ascii": "Al Qurayyat",
    "lat": 31.3333,
    "lng": 37.3333,
    "country": "Saudi Arabia",
    "iso2": "SA",
    "iso3": "SAU",
    "admin_name": "Al Jawf",
    "capital": "",
    "population": 10000,
    "id": 1682805250
  },
  {
    "city": "Worth",
    "city_ascii": "Worth",
    "lat": 51.113,
    "lng": -0.147,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "West Sussex",
    "capital": "",
    "population": 9888,
    "id": 1826978438
  },
  {
    "city": "Gamba",
    "city_ascii": "Gamba",
    "lat": -2.65,
    "lng": 10,
    "country": "Gabon",
    "iso2": "GA",
    "iso3": "GAB",
    "admin_name": "Ogooué-Maritime",
    "capital": "",
    "population": 9928,
    "id": 1266236988
  },
  {
    "city": "Dodworth",
    "city_ascii": "Dodworth",
    "lat": 53.5417,
    "lng": -1.5214,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Barnsley",
    "capital": "",
    "population": 9777,
    "id": 1826948211
  },
  {
    "city": "Qarabalyq",
    "city_ascii": "Qarabalyq",
    "lat": 53.7506,
    "lng": 62.0502,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Qostanay",
    "capital": "",
    "population": 9838,
    "id": 1398254114
  },
  {
    "city": "Mandritsara",
    "city_ascii": "Mandritsara",
    "lat": -15.8328,
    "lng": 48.8166,
    "country": "Madagascar",
    "iso2": "MG",
    "iso3": "MDG",
    "admin_name": "Mahajanga",
    "capital": "",
    "population": 9705,
    "id": 1450981137
  },
  {
    "city": "Olmos",
    "city_ascii": "Olmos",
    "lat": -5.9796,
    "lng": -79.75,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "Lambayeque",
    "capital": "",
    "population": 9800,
    "id": 1604825576
  },
  {
    "city": "Cobham",
    "city_ascii": "Cobham",
    "lat": 51.329,
    "lng": -0.409,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Surrey",
    "capital": "",
    "population": 9739,
    "id": 1826478182
  },
  {
    "city": "Felpham",
    "city_ascii": "Felpham",
    "lat": 50.7905,
    "lng": -0.6539,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "West Sussex",
    "capital": "",
    "population": 9746,
    "id": 1826012790
  },
  {
    "city": "Aqadyr",
    "city_ascii": "Aqadyr",
    "lat": 48.2749,
    "lng": 72.8599,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Qaraghandy",
    "capital": "",
    "population": 9710,
    "id": 1398472084
  },
  {
    "city": "Sémbé",
    "city_ascii": "Sembe",
    "lat": 1.6404,
    "lng": 14.58,
    "country": "Congo (Brazzaville)",
    "iso2": "CG",
    "iso3": "COG",
    "admin_name": "Sangha",
    "capital": "",
    "population": 9679,
    "id": 1178118997
  },
  {
    "city": "Lototla",
    "city_ascii": "Lototla",
    "lat": 20.8392,
    "lng": -98.7178,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Hidalgo",
    "capital": "",
    "population": 9541,
    "id": 1484853725
  },
  {
    "city": "Dombarovskiy",
    "city_ascii": "Dombarovskiy",
    "lat": 50.7546,
    "lng": 59.54,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Orenburgskaya Oblast’",
    "capital": "",
    "population": 9556,
    "id": 1643794034
  },
  {
    "city": "Igrim",
    "city_ascii": "Igrim",
    "lat": 63.1933,
    "lng": 64.4194,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Khanty-Mansiyskiy Avtonomnyy Okrug-Yugra",
    "capital": "",
    "population": 9545,
    "id": 1643468393
  },
  {
    "city": "Tadworth",
    "city_ascii": "Tadworth",
    "lat": 51.294,
    "lng": -0.242,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Surrey",
    "capital": "",
    "population": 9522,
    "id": 1826571557
  },
  {
    "city": "Pedro Luro",
    "city_ascii": "Pedro Luro",
    "lat": -39.5,
    "lng": -62.6833,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires",
    "capital": "",
    "population": 9494,
    "id": 1032549913
  },
  {
    "city": "Shira",
    "city_ascii": "Shira",
    "lat": 54.4914,
    "lng": 89.9531,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Khakasiya",
    "capital": "",
    "population": 9358,
    "id": 1643191566
  },
  {
    "city": "Burscough",
    "city_ascii": "Burscough",
    "lat": 53.596,
    "lng": -2.843,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Lancashire",
    "capital": "",
    "population": 9182,
    "id": 1826272901
  },
  {
    "city": "Tlahuiltepa",
    "city_ascii": "Tlahuiltepa",
    "lat": 20.9233,
    "lng": -98.9497,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Hidalgo",
    "capital": "",
    "population": 9264,
    "id": 1484500392
  },
  {
    "city": "Yaxley",
    "city_ascii": "Yaxley",
    "lat": 52.52,
    "lng": -0.26,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Cambridgeshire",
    "capital": "",
    "population": 9174,
    "id": 1826156250
  },
  {
    "city": "Ust’-Nera",
    "city_ascii": "Ust'-Nera",
    "lat": 64.5666,
    "lng": 143.2,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sakha (Yakutiya)",
    "capital": "",
    "population": 9148,
    "id": 1643124244
  },
  {
    "city": "Jumlā",
    "city_ascii": "Jumla",
    "lat": 29.25,
    "lng": 82.2167,
    "country": "Nepal",
    "iso2": "NP",
    "iso3": "NPL",
    "admin_name": "Karnālī",
    "capital": "",
    "population": 9073,
    "id": 1524473131
  },
  {
    "city": "Coatetelco",
    "city_ascii": "Coatetelco",
    "lat": 18.7294,
    "lng": -99.3256,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Morelos",
    "capital": "",
    "population": 9094,
    "id": 1484016853
  },
  {
    "city": "Ixtapa Zihuatanejo",
    "city_ascii": "Ixtapa Zihuatanejo",
    "lat": 17.6367,
    "lng": -101.5514,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Guerrero",
    "capital": "",
    "population": 8992,
    "id": 1484980277
  },
  {
    "city": "Shar",
    "city_ascii": "Shar",
    "lat": 49.6003,
    "lng": 81.0549,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Shyghys Qazaqstan",
    "capital": "",
    "population": 9087,
    "id": 1398393299
  },
  {
    "city": "Queensferry",
    "city_ascii": "Queensferry",
    "lat": 55.99,
    "lng": -3.398,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Edinburgh, City of",
    "capital": "",
    "population": 9026,
    "id": 1826335881
  },
  {
    "city": "Aguelhok",
    "city_ascii": "Aguelhok",
    "lat": 19.4549,
    "lng": 0.8564,
    "country": "Mali",
    "iso2": "ML",
    "iso3": "MLI",
    "admin_name": "Kidal",
    "capital": "",
    "population": 9000,
    "id": 1466967655
  },
  {
    "city": "Great Cornard",
    "city_ascii": "Great Cornard",
    "lat": 52.0245,
    "lng": 0.7497,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Suffolk",
    "capital": "",
    "population": 8908,
    "id": 1826607712
  },
  {
    "city": "Tepetitlan",
    "city_ascii": "Tepetitlan",
    "lat": 20.1842,
    "lng": -99.3808,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Hidalgo",
    "capital": "",
    "population": 8893,
    "id": 1484277299
  },
  {
    "city": "Qashyr",
    "city_ascii": "Qashyr",
    "lat": 53.0804,
    "lng": 76.09,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Pavlodar",
    "capital": "",
    "population": 8931,
    "id": 1398362078
  },
  {
    "city": "Quibala",
    "city_ascii": "Quibala",
    "lat": -10.7296,
    "lng": 14.98,
    "country": "Angola",
    "iso2": "AO",
    "iso3": "AGO",
    "admin_name": "Kwanza Sul",
    "capital": "",
    "population": 8915,
    "id": 1024007950
  },
  {
    "city": "Makhambet",
    "city_ascii": "Makhambet",
    "lat": 47.6714,
    "lng": 51.5798,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Atyraū",
    "capital": "",
    "population": 8905,
    "id": 1398232608
  },
  {
    "city": "Yanchep",
    "city_ascii": "Yanchep",
    "lat": -31.55,
    "lng": 115.634,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Western Australia",
    "capital": "",
    "population": 8868,
    "id": 1036796651
  },
  {
    "city": "Zhangaözen",
    "city_ascii": "Zhangaozen",
    "lat": 43.3004,
    "lng": 52.8,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Mangghystaū",
    "capital": "",
    "population": 8895,
    "id": 1398274968
  },
  {
    "city": "Marrupa",
    "city_ascii": "Marrupa",
    "lat": -13.1833,
    "lng": 37.5,
    "country": "Mozambique",
    "iso2": "MZ",
    "iso3": "MOZ",
    "admin_name": "Niassa",
    "capital": "",
    "population": 8762,
    "id": 1508890063
  },
  {
    "city": "Al Quway‘īyah",
    "city_ascii": "Al Quway`iyah",
    "lat": 24.0737,
    "lng": 45.2806,
    "country": "Saudi Arabia",
    "iso2": "SA",
    "iso3": "SAU",
    "admin_name": "Ar Riyāḑ",
    "capital": "",
    "population": 8712,
    "id": 1682154232
  },
  {
    "city": "Suntar",
    "city_ascii": "Suntar",
    "lat": 62.1444,
    "lng": 117.6319,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sakha (Yakutiya)",
    "capital": "",
    "population": 8639,
    "id": 1643961858
  },
  {
    "city": "Skewen",
    "city_ascii": "Skewen",
    "lat": 51.6609,
    "lng": -3.8399,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Neath Port Talbot",
    "capital": "",
    "population": 8500,
    "id": 1826000012
  },
  {
    "city": "Canford Cliffs",
    "city_ascii": "Canford Cliffs",
    "lat": 50.7,
    "lng": -1.93,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Bournemouth",
    "capital": "",
    "population": 8620,
    "id": 1826721850
  },
  {
    "city": "Vícam Pueblo",
    "city_ascii": "Vicam Pueblo",
    "lat": 27.6422,
    "lng": -110.2897,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Sonora",
    "capital": "",
    "population": 8578,
    "id": 1484237374
  },
  {
    "city": "Meltham",
    "city_ascii": "Meltham",
    "lat": 53.592,
    "lng": -1.85,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Kirklees",
    "capital": "",
    "population": 8534,
    "id": 1826260567
  },
  {
    "city": "Corman Park No. 344",
    "city_ascii": "Corman Park No. 344",
    "lat": 52.2291,
    "lng": -106.8002,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Saskatchewan",
    "capital": "",
    "population": 8568,
    "id": 1124000077
  },
  {
    "city": "Aqsū",
    "city_ascii": "Aqsu",
    "lat": 52.4502,
    "lng": 71.9597,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Aqmola",
    "capital": "",
    "population": 8543,
    "id": 1398268556
  },
  {
    "city": "Agua Blanca Iturbide",
    "city_ascii": "Agua Blanca Iturbide",
    "lat": 20.35,
    "lng": -98.35,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Hidalgo",
    "capital": "",
    "population": 8443,
    "id": 1484459690
  },
  {
    "city": "Aughton",
    "city_ascii": "Aughton",
    "lat": 53.542,
    "lng": -2.912,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Lancashire",
    "capital": "",
    "population": 8342,
    "id": 1826100170
  },
  {
    "city": "Masindi Port",
    "city_ascii": "Masindi Port",
    "lat": 1.7004,
    "lng": 32.0699,
    "country": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "admin_name": "Kiryandongo",
    "capital": "",
    "population": 8319,
    "id": 1800188907
  },
  {
    "city": "Toton",
    "city_ascii": "Toton",
    "lat": 52.907,
    "lng": -1.254,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Derbyshire",
    "capital": "",
    "population": 8238,
    "id": 1826247369
  },
  {
    "city": "Goffs Oak",
    "city_ascii": "Goffs Oak",
    "lat": 51.7109,
    "lng": -0.0825,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hertfordshire",
    "capital": "",
    "population": 8172,
    "id": 1826459935
  },
  {
    "city": "Radcliffe on Trent",
    "city_ascii": "Radcliffe on Trent",
    "lat": 52.947,
    "lng": -1.04,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Nottinghamshire",
    "capital": "",
    "population": 8205,
    "id": 1826826336
  },
  {
    "city": "Olovyannaya",
    "city_ascii": "Olovyannaya",
    "lat": 50.95,
    "lng": 115.5666,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Zabaykal’skiy Kray",
    "capital": "",
    "population": 8247,
    "id": 1643754076
  },
  {
    "city": "Zhänibek",
    "city_ascii": "Zhanibek",
    "lat": 49.4276,
    "lng": 46.8772,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Batys Qazaqstan",
    "capital": "",
    "population": 8212,
    "id": 1398255562
  },
  {
    "city": "Qarqaraly",
    "city_ascii": "Qarqaraly",
    "lat": 49.4249,
    "lng": 75.4649,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Qaraghandy",
    "capital": "",
    "population": 8195,
    "id": 1398407379
  },
  {
    "city": "Putina",
    "city_ascii": "Putina",
    "lat": -15.47,
    "lng": -69.43,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "Puno",
    "capital": "",
    "population": 8118,
    "id": 1604856559
  },
  {
    "city": "Merrow",
    "city_ascii": "Merrow",
    "lat": 51.2463,
    "lng": -0.5279,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Surrey",
    "capital": "",
    "population": 8036,
    "id": 1826431473
  },
  {
    "city": "Happy Valley",
    "city_ascii": "Happy Valley",
    "lat": 53.3396,
    "lng": -60.4467,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Newfoundland and Labrador",
    "capital": "",
    "population": 8109,
    "id": 1124879731
  },
  {
    "city": "Oldeani",
    "city_ascii": "Oldeani",
    "lat": -3.3496,
    "lng": 35.55,
    "country": "Tanzania",
    "iso2": "TZ",
    "iso3": "TZA",
    "admin_name": "Arusha",
    "capital": "",
    "population": 8000,
    "id": 1834474820
  },
  {
    "city": "Willerby",
    "city_ascii": "Willerby",
    "lat": 53.7633,
    "lng": -0.4504,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "East Riding of Yorkshire",
    "capital": "",
    "population": 7940,
    "id": 1826347968
  },
  {
    "city": "Qusmuryn",
    "city_ascii": "Qusmuryn",
    "lat": 52.458,
    "lng": 64.6,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Qostanay",
    "capital": "",
    "population": 8049,
    "id": 1398335766
  },
  {
    "city": "Mineral del Chico",
    "city_ascii": "Mineral del Chico",
    "lat": 20.2031,
    "lng": -98.7478,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Hidalgo",
    "capital": "",
    "population": 7980,
    "id": 1484095242
  },
  {
    "city": "Kontcha",
    "city_ascii": "Kontcha",
    "lat": 7.967,
    "lng": 12.2333,
    "country": "Cameroon",
    "iso2": "CM",
    "iso3": "CMR",
    "admin_name": "Adamaoua",
    "capital": "",
    "population": 8018,
    "id": 1120016766
  },
  {
    "city": "Seasalter",
    "city_ascii": "Seasalter",
    "lat": 51.3456,
    "lng": 0.9981,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Kent",
    "capital": "",
    "population": 7967,
    "id": 1826765773
  },
  {
    "city": "Goring by Sea",
    "city_ascii": "Goring by Sea",
    "lat": 50.8122,
    "lng": -0.4244,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "West Sussex",
    "capital": "",
    "population": 7990,
    "id": 1826564099
  },
  {
    "city": "Annfield Plain",
    "city_ascii": "Annfield Plain",
    "lat": 54.857,
    "lng": -1.737,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Durham",
    "capital": "",
    "population": 7774,
    "id": 1826265966
  },
  {
    "city": "Kinmel",
    "city_ascii": "Kinmel",
    "lat": 53.31,
    "lng": -3.519,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Denbighshire",
    "capital": "",
    "population": 7864,
    "id": 1826173712
  },
  {
    "city": "Ch’osan-ŭp",
    "city_ascii": "Ch'osan-up",
    "lat": 40.8255,
    "lng": 125.8008,
    "country": "Korea, North",
    "iso2": "KP",
    "iso3": "PRK",
    "admin_name": "Chagang",
    "capital": "",
    "population": 7786,
    "id": 1408838898
  },
  {
    "city": "Willesborough",
    "city_ascii": "Willesborough",
    "lat": 51.1386,
    "lng": 0.8957,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Kent",
    "capital": "",
    "population": 7800,
    "id": 1826693144
  },
  {
    "city": "Hook",
    "city_ascii": "Hook",
    "lat": 51.2773,
    "lng": -0.9633,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hampshire",
    "capital": "",
    "population": 7770,
    "id": 1826584799
  },
  {
    "city": "Iqaluit",
    "city_ascii": "Iqaluit",
    "lat": 63.7598,
    "lng": -68.5107,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Nunavut",
    "capital": "",
    "population": 7740,
    "id": 1124379539
  },
  {
    "city": "Kalabo",
    "city_ascii": "Kalabo",
    "lat": -14.9896,
    "lng": 22.68,
    "country": "Zambia",
    "iso2": "ZM",
    "iso3": "ZMB",
    "admin_name": "Western",
    "capital": "",
    "population": 7731,
    "id": 1894966417
  },
  {
    "city": "Bansang",
    "city_ascii": "Bansang",
    "lat": 13.4336,
    "lng": -14.65,
    "country": "Gambia, The",
    "iso2": "GM",
    "iso3": "GMB",
    "admin_name": "Central River",
    "capital": "",
    "population": 7615,
    "id": 1270543142
  },
  {
    "city": "Kingsbury",
    "city_ascii": "Kingsbury",
    "lat": 52.5614,
    "lng": -1.6917,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Warwickshire",
    "capital": "",
    "population": 7652,
    "id": 1826749677
  },
  {
    "city": "Bayghanīn",
    "city_ascii": "Bayghanin",
    "lat": 48.6917,
    "lng": 55.874,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Aqtöbe",
    "capital": "",
    "population": 7657,
    "id": 1398205914
  },
  {
    "city": "Saint Agnes",
    "city_ascii": "Saint Agnes",
    "lat": 50.312,
    "lng": -5.204,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Cornwall",
    "capital": "",
    "population": 7565,
    "id": 1826113041
  },
  {
    "city": "Stonehouse",
    "city_ascii": "Stonehouse",
    "lat": 55.6968,
    "lng": -3.9824,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "South Lanarkshire",
    "capital": "",
    "population": 7500,
    "id": 1826070141
  },
  {
    "city": "Neiafu",
    "city_ascii": "Neiafu",
    "lat": -18.6496,
    "lng": -173.9833,
    "country": "Tonga",
    "iso2": "TO",
    "iso3": "TON",
    "admin_name": "Vava‘u",
    "capital": "",
    "population": 7391,
    "id": 1776829745
  },
  {
    "city": "Braunton",
    "city_ascii": "Braunton",
    "lat": 51.11,
    "lng": -4.162,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Devon",
    "capital": "",
    "population": 7353,
    "id": 1826987782
  },
  {
    "city": "Shenstone",
    "city_ascii": "Shenstone",
    "lat": 52.637,
    "lng": -1.841,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Staffordshire",
    "capital": "",
    "population": 7359,
    "id": 1826707052
  },
  {
    "city": "Luân Châu",
    "city_ascii": "Luan Chau",
    "lat": 21.74,
    "lng": 103.343,
    "country": "Vietnam",
    "iso2": "VN",
    "iso3": "VNM",
    "admin_name": "Điện Biên",
    "capital": "",
    "population": 7335,
    "id": 1704201464
  },
  {
    "city": "Reyes",
    "city_ascii": "Reyes",
    "lat": -14.3096,
    "lng": -67.37,
    "country": "Bolivia",
    "iso2": "BO",
    "iso3": "BOL",
    "admin_name": "El Beni",
    "capital": "",
    "population": 7376,
    "id": 1068609630
  },
  {
    "city": "Basing",
    "city_ascii": "Basing",
    "lat": 51.2704,
    "lng": -1.0473,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hampshire",
    "capital": "",
    "population": 7232,
    "id": 1826187889
  },
  {
    "city": "Herne",
    "city_ascii": "Herne",
    "lat": 51.3492,
    "lng": 1.1331,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Kent",
    "capital": "",
    "population": 7325,
    "id": 1826808602
  },
  {
    "city": "Claygate",
    "city_ascii": "Claygate",
    "lat": 51.36,
    "lng": -0.342,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Surrey",
    "capital": "",
    "population": 7168,
    "id": 1826292521
  },
  {
    "city": "Osakarovka",
    "city_ascii": "Osakarovka",
    "lat": 50.5799,
    "lng": 72.5699,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Qaraghandy",
    "capital": "",
    "population": 7305,
    "id": 1398243007
  },
  {
    "city": "Ruddington",
    "city_ascii": "Ruddington",
    "lat": 52.893,
    "lng": -1.15,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Nottinghamshire",
    "capital": "",
    "population": 7216,
    "id": 1826466701
  },
  {
    "city": "Bossembele",
    "city_ascii": "Bossembele",
    "lat": 5.267,
    "lng": 17.65,
    "country": "Central African Republic",
    "iso2": "CF",
    "iso3": "CAF",
    "admin_name": "Ombella-Mpoko",
    "capital": "",
    "population": 7287,
    "id": 1140955210
  },
  {
    "city": "Peterculter",
    "city_ascii": "Peterculter",
    "lat": 57.097,
    "lng": -2.2657,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Aberdeen City",
    "capital": "",
    "population": 7220,
    "id": 1826000004
  },
  {
    "city": "Grove",
    "city_ascii": "Grove",
    "lat": 51.612,
    "lng": -1.421,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Oxfordshire",
    "capital": "",
    "population": 7178,
    "id": 1826315012
  },
  {
    "city": "Coundon",
    "city_ascii": "Coundon",
    "lat": 54.6637,
    "lng": -1.6341,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Durham",
    "capital": "",
    "population": 7139,
    "id": 1826182728
  },
  {
    "city": "Hafendorf",
    "city_ascii": "Hafendorf",
    "lat": 47.4556,
    "lng": 15.3194,
    "country": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "admin_name": "Steiermark",
    "capital": "",
    "population": 7177,
    "id": 1040467554
  },
  {
    "city": "Bestöbe",
    "city_ascii": "Bestobe",
    "lat": 52.4997,
    "lng": 73.0997,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Aqmola",
    "capital": "",
    "population": 7189,
    "id": 1398545422
  },
  {
    "city": "Tobyl",
    "city_ascii": "Tobyl",
    "lat": 52.698,
    "lng": 62.5749,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Qostanay",
    "capital": "",
    "population": 7155,
    "id": 1398858827
  },
  {
    "city": "Okondja",
    "city_ascii": "Okondja",
    "lat": -0.6829,
    "lng": 13.7833,
    "country": "Gabon",
    "iso2": "GA",
    "iso3": "GAB",
    "admin_name": "Haut-Ogooué",
    "capital": "",
    "population": 7155,
    "id": 1266588926
  },
  {
    "city": "Brownsburg",
    "city_ascii": "Brownsburg",
    "lat": 45.6703,
    "lng": -74.4467,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 7122,
    "id": 1124023263
  },
  {
    "city": "Deeping Saint James",
    "city_ascii": "Deeping Saint James",
    "lat": 52.6717,
    "lng": -0.2995,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Lincolnshire",
    "capital": "",
    "population": 6923,
    "id": 1826146057
  },
  {
    "city": "Zambezi",
    "city_ascii": "Zambezi",
    "lat": -13.54,
    "lng": 23.1099,
    "country": "Zambia",
    "iso2": "ZM",
    "iso3": "ZMB",
    "admin_name": "North-Western",
    "capital": "",
    "population": 7074,
    "id": 1894660797
  },
  {
    "city": "Quarrington",
    "city_ascii": "Quarrington",
    "lat": 52.987,
    "lng": -0.4304,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Lincolnshire",
    "capital": "",
    "population": 7046,
    "id": 1826773379
  },
  {
    "city": "Melekeok",
    "city_ascii": "Melekeok",
    "lat": 7.4874,
    "lng": 134.6265,
    "country": "Palau",
    "iso2": "PW",
    "iso3": "PLW",
    "admin_name": "Melekeok",
    "capital": "",
    "population": 7026,
    "id": 1585892017
  },
  {
    "city": "Kirkleatham",
    "city_ascii": "Kirkleatham",
    "lat": 54.5833,
    "lng": -1.0833,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Redcar and Cleveland",
    "capital": "",
    "population": 7045,
    "id": 1826350336
  },
  {
    "city": "Quellón",
    "city_ascii": "Quellon",
    "lat": -43.1201,
    "lng": -73.6203,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Los Lagos",
    "capital": "",
    "population": 7029,
    "id": 1152502358
  },
  {
    "city": "Nicoadala",
    "city_ascii": "Nicoadala",
    "lat": -17.6077,
    "lng": 36.8197,
    "country": "Mozambique",
    "iso2": "MZ",
    "iso3": "MOZ",
    "admin_name": "Zambézia",
    "capital": "",
    "population": 6945,
    "id": 1508484170
  },
  {
    "city": "Xochicoatlán",
    "city_ascii": "Xochicoatlan",
    "lat": 20.7767,
    "lng": -98.68,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Hidalgo",
    "capital": "",
    "population": 6954,
    "id": 1484144977
  },
  {
    "city": "Branston",
    "city_ascii": "Branston",
    "lat": 52.787,
    "lng": -1.659,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Staffordshire",
    "capital": "",
    "population": 6749,
    "id": 1826771802
  },
  {
    "city": "Karibib",
    "city_ascii": "Karibib",
    "lat": -21.939,
    "lng": 15.853,
    "country": "Namibia",
    "iso2": "NA",
    "iso3": "NAM",
    "admin_name": "Erongo",
    "capital": "",
    "population": 6898,
    "id": 1516472073
  },
  {
    "city": "Zholymbet",
    "city_ascii": "Zholymbet",
    "lat": 51.7502,
    "lng": 71.7099,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Aqmola",
    "capital": "",
    "population": 6876,
    "id": 1398035914
  },
  {
    "city": "Fishtoft",
    "city_ascii": "Fishtoft",
    "lat": 52.9617,
    "lng": 0.0259,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Lincolnshire",
    "capital": "",
    "population": 6835,
    "id": 1826328454
  },
  {
    "city": "Great Chart",
    "city_ascii": "Great Chart",
    "lat": 51.1409,
    "lng": 0.8372,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Kent",
    "capital": "",
    "population": 6801,
    "id": 1826348969
  },
  {
    "city": "Sandycroft",
    "city_ascii": "Sandycroft",
    "lat": 53.195,
    "lng": -2.999,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Flintshire",
    "capital": "",
    "population": 6724,
    "id": 1826333446
  },
  {
    "city": "Golfito",
    "city_ascii": "Golfito",
    "lat": 8.65,
    "lng": -83.15,
    "country": "Costa Rica",
    "iso2": "CR",
    "iso3": "CRI",
    "admin_name": "Puntarenas",
    "capital": "",
    "population": 6777,
    "id": 1188695145
  },
  {
    "city": "Khandyga",
    "city_ascii": "Khandyga",
    "lat": 62.666,
    "lng": 135.6,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sakha (Yakutiya)",
    "capital": "",
    "population": 6796,
    "id": 1643340100
  },
  {
    "city": "Gordonvale",
    "city_ascii": "Gordonvale",
    "lat": -17.0936,
    "lng": 145.7866,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Queensland",
    "capital": "",
    "population": 6671,
    "id": 1036505934
  },
  {
    "city": "Alvechurch",
    "city_ascii": "Alvechurch",
    "lat": 52.3506,
    "lng": -1.9647,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Worcestershire",
    "capital": "",
    "population": 6564,
    "id": 1826728524
  },
  {
    "city": "Sawley",
    "city_ascii": "Sawley",
    "lat": 52.882,
    "lng": -1.299,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Leicestershire",
    "capital": "",
    "population": 6629,
    "id": 1826583246
  },
  {
    "city": "Cwmafan",
    "city_ascii": "Cwmafan",
    "lat": 51.6155,
    "lng": -3.7717,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Neath Port Talbot",
    "capital": "",
    "population": 6538,
    "id": 1826757663
  },
  {
    "city": "Chapelhall",
    "city_ascii": "Chapelhall",
    "lat": 55.845,
    "lng": -3.9472,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "North Lanarkshire",
    "capital": "",
    "population": 6560,
    "id": 1826347870
  },
  {
    "city": "Saint-Lambert-de-Lauzon",
    "city_ascii": "Saint-Lambert-de-Lauzon",
    "lat": 46.5865,
    "lng": -71.2271,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 6647,
    "id": 1124610423
  },
  {
    "city": "Muskoka Falls",
    "city_ascii": "Muskoka Falls",
    "lat": 45.1264,
    "lng": -79.558,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 6588,
    "id": 1124955753
  },
  {
    "city": "Qazaly",
    "city_ascii": "Qazaly",
    "lat": 45.7628,
    "lng": 62.1075,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Qyzylorda",
    "capital": "",
    "population": 6582,
    "id": 1398983835
  },
  {
    "city": "Quirihue",
    "city_ascii": "Quirihue",
    "lat": -36.28,
    "lng": -72.53,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Biobío",
    "capital": "",
    "population": 6529,
    "id": 1152146130
  },
  {
    "city": "Weaverham",
    "city_ascii": "Weaverham",
    "lat": 53.262,
    "lng": -2.577,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Cheshire West and Chester",
    "capital": "",
    "population": 6391,
    "id": 1826056264
  },
  {
    "city": "Castle Donnington",
    "city_ascii": "Castle Donnington",
    "lat": 52.8444,
    "lng": -1.3377,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Leicestershire",
    "capital": "",
    "population": 6416,
    "id": 1826826582
  },
  {
    "city": "Liss",
    "city_ascii": "Liss",
    "lat": 51.0429,
    "lng": -0.8918,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hampshire",
    "capital": "",
    "population": 6291,
    "id": 1826487395
  },
  {
    "city": "Swanscombe",
    "city_ascii": "Swanscombe",
    "lat": 51.4491,
    "lng": 0.2993,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Kent",
    "capital": "",
    "population": 6300,
    "id": 1826018472
  },
  {
    "city": "Wheathampstead",
    "city_ascii": "Wheathampstead",
    "lat": 51.812,
    "lng": -0.293,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hertfordshire",
    "capital": "",
    "population": 6410,
    "id": 1826432060
  },
  {
    "city": "San Carlos",
    "city_ascii": "San Carlos",
    "lat": -17.4,
    "lng": -63.73,
    "country": "Bolivia",
    "iso2": "BO",
    "iso3": "BOL",
    "admin_name": "Santa Cruz",
    "capital": "",
    "population": 6353,
    "id": 1068028866
  },
  {
    "city": "Garswood",
    "city_ascii": "Garswood",
    "lat": 53.488,
    "lng": -2.67,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "St. Helens",
    "capital": "",
    "population": 6183,
    "id": 1826807848
  },
  {
    "city": "San Matías",
    "city_ascii": "San Matias",
    "lat": -16.36,
    "lng": -58.42,
    "country": "Bolivia",
    "iso2": "BO",
    "iso3": "BOL",
    "admin_name": "Santa Cruz",
    "capital": "",
    "population": 6352,
    "id": 1068402765
  },
  {
    "city": "Verkhnevilyuysk",
    "city_ascii": "Verkhnevilyuysk",
    "lat": 63.4457,
    "lng": 120.3167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sakha (Yakutiya)",
    "capital": "",
    "population": 6341,
    "id": 1643968444
  },
  {
    "city": "Lydiate",
    "city_ascii": "Lydiate",
    "lat": 53.536,
    "lng": -2.959,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Sefton",
    "capital": "",
    "population": 6308,
    "id": 1826627925
  },
  {
    "city": "Newarthill",
    "city_ascii": "Newarthill",
    "lat": 55.81,
    "lng": -3.9333,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "North Lanarkshire",
    "capital": "",
    "population": 6280,
    "id": 1826949566
  },
  {
    "city": "Tamchen",
    "city_ascii": "Tamchen",
    "lat": 20.8753,
    "lng": -89.9319,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Yucatán",
    "capital": "",
    "population": 6307,
    "id": 1484385687
  },
  {
    "city": "Stonewood",
    "city_ascii": "Stonewood",
    "lat": 51.445,
    "lng": 0.256,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Kent",
    "capital": "",
    "population": 6100,
    "id": 1826698037
  },
  {
    "city": "Dysart et al",
    "city_ascii": "Dysart et al",
    "lat": 45.2042,
    "lng": -78.4047,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Ontario",
    "capital": "",
    "population": 6280,
    "id": 1124000824
  },
  {
    "city": "Beresford",
    "city_ascii": "Beresford",
    "lat": 47.7181,
    "lng": -65.8794,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "New Brunswick",
    "capital": "",
    "population": 6248,
    "id": 1124000299
  },
  {
    "city": "Nicolás Flores",
    "city_ascii": "Nicolas Flores",
    "lat": 20.7669,
    "lng": -99.1514,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Hidalgo",
    "capital": "",
    "population": 6202,
    "id": 1484734938
  },
  {
    "city": "Ndendé",
    "city_ascii": "Ndende",
    "lat": -2.3829,
    "lng": 11.3833,
    "country": "Gabon",
    "iso2": "GA",
    "iso3": "GAB",
    "admin_name": "Ngounié",
    "capital": "",
    "population": 6200,
    "id": 1266578957
  },
  {
    "city": "Rowlands Gill",
    "city_ascii": "Rowlands Gill",
    "lat": 54.9209,
    "lng": -1.7441,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Gateshead",
    "capital": "",
    "population": 6096,
    "id": 1826569273
  },
  {
    "city": "El Palqui",
    "city_ascii": "El Palqui",
    "lat": -30.7679,
    "lng": -70.9433,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Coquimbo",
    "capital": "",
    "population": 6175,
    "id": 1152942704
  },
  {
    "city": "Orsett",
    "city_ascii": "Orsett",
    "lat": 51.51,
    "lng": 0.37,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Thurrock",
    "capital": "",
    "population": 6115,
    "id": 1826904946
  },
  {
    "city": "Pembury",
    "city_ascii": "Pembury",
    "lat": 51.1456,
    "lng": 0.3224,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Kent",
    "capital": "",
    "population": 6128,
    "id": 1826968649
  },
  {
    "city": "Douglas",
    "city_ascii": "Douglas",
    "lat": 46.2819,
    "lng": -66.942,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "New Brunswick",
    "capital": "",
    "population": 6154,
    "id": 1124000768
  },
  {
    "city": "Umba",
    "city_ascii": "Umba",
    "lat": 66.6814,
    "lng": 34.3455,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Murmanskaya Oblast’",
    "capital": "",
    "population": 6128,
    "id": 1643774657
  },
  {
    "city": "Hollington",
    "city_ascii": "Hollington",
    "lat": 50.875,
    "lng": 0.548,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "East Sussex",
    "capital": "",
    "population": 6099,
    "id": 1826944275
  },
  {
    "city": "Chalfont Saint Giles",
    "city_ascii": "Chalfont Saint Giles",
    "lat": 51.632,
    "lng": -0.576,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Buckinghamshire",
    "capital": "",
    "population": 5925,
    "id": 1826361426
  },
  {
    "city": "Pagham",
    "city_ascii": "Pagham",
    "lat": 50.7708,
    "lng": -0.7448,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "West Sussex",
    "capital": "",
    "population": 5941,
    "id": 1826570427
  },
  {
    "city": "Colney Heath",
    "city_ascii": "Colney Heath",
    "lat": 51.7353,
    "lng": -0.2564,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hertfordshire",
    "capital": "",
    "population": 5962,
    "id": 1826459899
  },
  {
    "city": "Lea Town",
    "city_ascii": "Lea Town",
    "lat": 53.774,
    "lng": -2.795,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Lancashire",
    "capital": "",
    "population": 5962,
    "id": 1826551027
  },
  {
    "city": "Staplehurst",
    "city_ascii": "Staplehurst",
    "lat": 51.164,
    "lng": 0.553,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Kent",
    "capital": "",
    "population": 6003,
    "id": 1826804762
  },
  {
    "city": "Great Warley Street",
    "city_ascii": "Great Warley Street",
    "lat": 51.607,
    "lng": 0.3,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Essex",
    "capital": "",
    "population": 5973,
    "id": 1826579485
  },
  {
    "city": "Stoke Mandeville",
    "city_ascii": "Stoke Mandeville",
    "lat": 51.7861,
    "lng": -0.7914,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Buckinghamshire",
    "capital": "",
    "population": 5825,
    "id": 1826260717
  },
  {
    "city": "Seaton Carew",
    "city_ascii": "Seaton Carew",
    "lat": 54.6628,
    "lng": -1.1888,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hartlepool",
    "capital": "",
    "population": 6018,
    "id": 1826230025
  },
  {
    "city": "Godstone",
    "city_ascii": "Godstone",
    "lat": 51.248,
    "lng": -0.064,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Surrey",
    "capital": "",
    "population": 5949,
    "id": 1826968217
  },
  {
    "city": "Chapaev",
    "city_ascii": "Chapaev",
    "lat": 50.1915,
    "lng": 51.1449,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Batys Qazaqstan",
    "capital": "",
    "population": 6000,
    "id": 1398386733
  },
  {
    "city": "Burghfield",
    "city_ascii": "Burghfield",
    "lat": 51.411,
    "lng": -1.042,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "West Berkshire",
    "capital": "",
    "population": 5955,
    "id": 1826550876
  },
  {
    "city": "Bramley",
    "city_ascii": "Bramley",
    "lat": 51.329,
    "lng": -1.0613,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hampshire",
    "capital": "",
    "population": 5875,
    "id": 1826671131
  },
  {
    "city": "Tazovskiy",
    "city_ascii": "Tazovskiy",
    "lat": 67.4667,
    "lng": 78.7,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Yamalo-Nenetskiy Avtonomnyy Okrug",
    "capital": "",
    "population": 5981,
    "id": 1643167905
  },
  {
    "city": "Chinnor",
    "city_ascii": "Chinnor",
    "lat": 51.702,
    "lng": -0.911,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Oxfordshire",
    "capital": "",
    "population": 5924,
    "id": 1826124007
  },
  {
    "city": "General Zaragoza",
    "city_ascii": "General Zaragoza",
    "lat": 23.8667,
    "lng": -97.8833,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Tamaulipas",
    "capital": "",
    "population": 5942,
    "id": 1484418136
  },
  {
    "city": "Studley",
    "city_ascii": "Studley",
    "lat": 52.2696,
    "lng": -1.8915,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Warwickshire",
    "capital": "",
    "population": 5879,
    "id": 1826898578
  },
  {
    "city": "East Preston",
    "city_ascii": "East Preston",
    "lat": 50.8104,
    "lng": -0.4819,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "West Sussex",
    "capital": "",
    "population": 5938,
    "id": 1826135129
  },
  {
    "city": "Wellesbourne Hastings",
    "city_ascii": "Wellesbourne Hastings",
    "lat": 52.1943,
    "lng": -1.5947,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Warwickshire",
    "capital": "",
    "population": 5849,
    "id": 1826755530
  },
  {
    "city": "Mundybash",
    "city_ascii": "Mundybash",
    "lat": 53.2333,
    "lng": 87.3167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kemerovskaya Oblast’",
    "capital": "",
    "population": 5870,
    "id": 1643009473
  },
  {
    "city": "Cumnor",
    "city_ascii": "Cumnor",
    "lat": 51.735,
    "lng": -1.332,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Oxfordshire",
    "capital": "",
    "population": 5755,
    "id": 1826660768
  },
  {
    "city": "Ibstock",
    "city_ascii": "Ibstock",
    "lat": 52.687,
    "lng": -1.401,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Leicestershire",
    "capital": "",
    "population": 5760,
    "id": 1826330058
  },
  {
    "city": "Aiyomojok",
    "city_ascii": "Aiyomojok",
    "lat": 5.7504,
    "lng": 8.9833,
    "country": "Cameroon",
    "iso2": "CM",
    "iso3": "CMR",
    "admin_name": "South-West",
    "capital": "",
    "population": 5798,
    "id": 1120658014
  },
  {
    "city": "Markfield",
    "city_ascii": "Markfield",
    "lat": 52.6878,
    "lng": -1.2775,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Leicestershire",
    "capital": "",
    "population": 5681,
    "id": 1826251524
  },
  {
    "city": "Gorebridge",
    "city_ascii": "Gorebridge",
    "lat": 55.8436,
    "lng": -3.05,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Midlothian",
    "capital": "",
    "population": 5777,
    "id": 1826664295
  },
  {
    "city": "Piggs Peak",
    "city_ascii": "Piggs Peak",
    "lat": -25.961,
    "lng": 31.247,
    "country": "Swaziland",
    "iso2": "SZ",
    "iso3": "SWZ",
    "admin_name": "Hhohho",
    "capital": "",
    "population": 5750,
    "id": 1748284923
  },
  {
    "city": "Fdérik",
    "city_ascii": "Fderik",
    "lat": 22.679,
    "lng": -12.707,
    "country": "Mauritania",
    "iso2": "MR",
    "iso3": "MRT",
    "admin_name": "Tiris Zemmour",
    "capital": "",
    "population": 5760,
    "id": 1478557883
  },
  {
    "city": "Bagshot",
    "city_ascii": "Bagshot",
    "lat": 51.3607,
    "lng": -0.6982,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Surrey",
    "capital": "",
    "population": 5592,
    "id": 1826197366
  },
  {
    "city": "Saal",
    "city_ascii": "Saal",
    "lat": 48.9011,
    "lng": 11.9319,
    "country": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "admin_name": "Bavaria",
    "capital": "",
    "population": 5626,
    "id": 1276401122
  },
  {
    "city": "General Levalle",
    "city_ascii": "General Levalle",
    "lat": -34.0167,
    "lng": -63.9167,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Córdoba",
    "capital": "",
    "population": 5697,
    "id": 1032232869
  },
  {
    "city": "Tiksi",
    "city_ascii": "Tiksi",
    "lat": 71.6269,
    "lng": 128.835,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sakha (Yakutiya)",
    "capital": "",
    "population": 5700,
    "id": 1643870212
  },
  {
    "city": "Princeville",
    "city_ascii": "Princeville",
    "lat": 46.1667,
    "lng": -71.8833,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 5693,
    "id": 1124715340
  },
  {
    "city": "Marchwood",
    "city_ascii": "Marchwood",
    "lat": 50.89,
    "lng": -1.454,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hampshire",
    "capital": "",
    "population": 5586,
    "id": 1826445296
  },
  {
    "city": "Longton",
    "city_ascii": "Longton",
    "lat": 53.729,
    "lng": -2.797,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Lancashire",
    "capital": "",
    "population": 5500,
    "id": 1826840641
  },
  {
    "city": "Beni Ounif",
    "city_ascii": "Beni Ounif",
    "lat": 32.0493,
    "lng": -1.2514,
    "country": "Algeria",
    "iso2": "DZ",
    "iso3": "DZA",
    "admin_name": "Béchar",
    "capital": "",
    "population": 5628,
    "id": 1012327989
  },
  {
    "city": "Kasempa",
    "city_ascii": "Kasempa",
    "lat": -13.4596,
    "lng": 25.82,
    "country": "Zambia",
    "iso2": "ZM",
    "iso3": "ZMB",
    "admin_name": "North-Western",
    "capital": "",
    "population": 5622,
    "id": 1894152710
  },
  {
    "city": "Wyke Regis",
    "city_ascii": "Wyke Regis",
    "lat": 50.592,
    "lng": -2.475,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Dorset",
    "capital": "",
    "population": 5458,
    "id": 1826158829
  },
  {
    "city": "Hartford",
    "city_ascii": "Hartford",
    "lat": 53.245,
    "lng": -2.549,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Cheshire West and Chester",
    "capital": "",
    "population": 5515,
    "id": 1826526517
  },
  {
    "city": "Pannal",
    "city_ascii": "Pannal",
    "lat": 53.9609,
    "lng": -1.5362,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "North Yorkshire",
    "capital": "",
    "population": 5562,
    "id": 1826649829
  },
  {
    "city": "Crowsnest Pass",
    "city_ascii": "Crowsnest Pass",
    "lat": 49.5955,
    "lng": -114.5136,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Alberta",
    "capital": "",
    "population": 5589,
    "id": 1124000595
  },
  {
    "city": "Vitomiricë",
    "city_ascii": "Vitomirice",
    "lat": 42.6819,
    "lng": 20.3173,
    "country": "Kosovo",
    "iso2": "XK",
    "iso3": "XKS",
    "admin_name": "Pejë",
    "capital": "",
    "population": 5409,
    "id": 1901482786
  },
  {
    "city": "Vossevangen",
    "city_ascii": "Vossevangen",
    "lat": 60.63,
    "lng": 6.441,
    "country": "Norway",
    "iso2": "NO",
    "iso3": "NOR",
    "admin_name": "Hordaland",
    "capital": "",
    "population": 5571,
    "id": 1578107030
  },
  {
    "city": "Okhotsk",
    "city_ascii": "Okhotsk",
    "lat": 59.383,
    "lng": 143.217,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Khabarovskiy Kray",
    "capital": "",
    "population": 5570,
    "id": 1643017106
  },
  {
    "city": "Stanthorpe",
    "city_ascii": "Stanthorpe",
    "lat": -28.6547,
    "lng": 151.935,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Queensland",
    "capital": "",
    "population": 5406,
    "id": 1036976908
  },
  {
    "city": "Bowling",
    "city_ascii": "Bowling",
    "lat": 55.932,
    "lng": -4.494,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Renfrewshire",
    "capital": "",
    "population": 5500,
    "id": 1826872358
  },
  {
    "city": "Tidenham",
    "city_ascii": "Tidenham",
    "lat": 51.6605,
    "lng": -2.6435,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Gloucestershire",
    "capital": "",
    "population": 5486,
    "id": 1826735722
  },
  {
    "city": "Bullsbrook",
    "city_ascii": "Bullsbrook",
    "lat": -31.663,
    "lng": 116.03,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Western Australia",
    "capital": "",
    "population": 5448,
    "id": 1036370763
  },
  {
    "city": "Perranzabuloe",
    "city_ascii": "Perranzabuloe",
    "lat": 50.326,
    "lng": -5.133,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Cornwall",
    "capital": "",
    "population": 5406,
    "id": 1826095235
  },
  {
    "city": "Hartley",
    "city_ascii": "Hartley",
    "lat": 51.3868,
    "lng": 0.3037,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Kent",
    "capital": "",
    "population": 5359,
    "id": 1826311133
  },
  {
    "city": "Witu",
    "city_ascii": "Witu",
    "lat": -2.3796,
    "lng": 40.43,
    "country": "Kenya",
    "iso2": "KE",
    "iso3": "KEN",
    "admin_name": "Lamu",
    "capital": "",
    "population": 5380,
    "id": 1404037095
  },
  {
    "city": "Barton on Sea",
    "city_ascii": "Barton on Sea",
    "lat": 50.7428,
    "lng": -1.6552,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hampshire",
    "capital": "",
    "population": 5454,
    "id": 1826987295
  },
  {
    "city": "Sollom",
    "city_ascii": "Sollom",
    "lat": 53.662,
    "lng": -2.825,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Lancashire",
    "capital": "",
    "population": 5350,
    "id": 1826569253
  },
  {
    "city": "Earls Barton",
    "city_ascii": "Earls Barton",
    "lat": 52.263,
    "lng": -0.746,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Northamptonshire",
    "capital": "",
    "population": 5387,
    "id": 1826467205
  },
  {
    "city": "Bilton",
    "city_ascii": "Bilton",
    "lat": 54.0099,
    "lng": -1.5359,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "North Yorkshire",
    "capital": "",
    "population": 5409,
    "id": 1826540848
  },
  {
    "city": "Tura",
    "city_ascii": "Tura",
    "lat": 64.2833,
    "lng": 100.25,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Krasnoyarskiy Kray",
    "capital": "",
    "population": 5444,
    "id": 1643809018
  },
  {
    "city": "San Quintín",
    "city_ascii": "San Quintin",
    "lat": 30.4837,
    "lng": -115.95,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Baja California",
    "capital": "",
    "population": 5433,
    "id": 1484693426
  },
  {
    "city": "Ouadda",
    "city_ascii": "Ouadda",
    "lat": 8.0671,
    "lng": 22.4,
    "country": "Central African Republic",
    "iso2": "CF",
    "iso3": "CAF",
    "admin_name": "Haute-Kotto",
    "capital": "",
    "population": 5434,
    "id": 1140640564
  },
  {
    "city": "Cookshire",
    "city_ascii": "Cookshire",
    "lat": 45.3729,
    "lng": -71.672,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 5393,
    "id": 1124895156
  },
  {
    "city": "Bidford-on-Avon",
    "city_ascii": "Bidford-on-Avon",
    "lat": 52.1644,
    "lng": -1.8567,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Warwickshire",
    "capital": "",
    "population": 5350,
    "id": 1826152393
  },
  {
    "city": "Measham",
    "city_ascii": "Measham",
    "lat": 52.7061,
    "lng": -1.508,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Leicestershire",
    "capital": "",
    "population": 5209,
    "id": 1826926544
  },
  {
    "city": "Newmains",
    "city_ascii": "Newmains",
    "lat": 55.7832,
    "lng": -3.8771,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "North Lanarkshire",
    "capital": "",
    "population": 5329,
    "id": 1826903762
  },
  {
    "city": "Redbourn",
    "city_ascii": "Redbourn",
    "lat": 51.8002,
    "lng": -0.3965,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hertfordshire",
    "capital": "",
    "population": 5344,
    "id": 1826801312
  },
  {
    "city": "Roundway",
    "city_ascii": "Roundway",
    "lat": 51.368,
    "lng": -1.981,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Wiltshire",
    "capital": "",
    "population": 5290,
    "id": 1826671490
  },
  {
    "city": "Hohenau",
    "city_ascii": "Hohenau",
    "lat": -27.0795,
    "lng": -55.75,
    "country": "Paraguay",
    "iso2": "PY",
    "iso3": "PRY",
    "admin_name": "Itapúa",
    "capital": "",
    "population": 5306,
    "id": 1600972500
  },
  {
    "city": "Damjan",
    "city_ascii": "Damjan",
    "lat": 42.296,
    "lng": 20.5162,
    "country": "Kosovo",
    "iso2": "XK",
    "iso3": "XKS",
    "admin_name": "Gjakovë",
    "capital": "",
    "population": 5133,
    "id": 1901520545
  },
  {
    "city": "Veintiocho de Noviembre",
    "city_ascii": "Veintiocho de Noviembre",
    "lat": -51.65,
    "lng": -72.3,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Santa Cruz",
    "capital": "",
    "population": 5300,
    "id": 1032715340
  },
  {
    "city": "Torghay",
    "city_ascii": "Torghay",
    "lat": 49.626,
    "lng": 63.499,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Qostanay",
    "capital": "",
    "population": 5277,
    "id": 1398845535
  },
  {
    "city": "Edwinstowe",
    "city_ascii": "Edwinstowe",
    "lat": 53.16,
    "lng": -1.07,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Nottinghamshire",
    "capital": "",
    "population": 5188,
    "id": 1826878671
  },
  {
    "city": "Saint-Honoré",
    "city_ascii": "Saint-Honore",
    "lat": 48.5333,
    "lng": -71.0833,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 5257,
    "id": 1124504668
  },
  {
    "city": "Holytown",
    "city_ascii": "Holytown",
    "lat": 55.8229,
    "lng": -3.9701,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "North Lanarkshire",
    "capital": "",
    "population": 5180,
    "id": 1826528147
  },
  {
    "city": "Ruskington",
    "city_ascii": "Ruskington",
    "lat": 53.0444,
    "lng": -0.3875,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Lincolnshire",
    "capital": "",
    "population": 5169,
    "id": 1826191626
  },
  {
    "city": "Fort-Shevchenko",
    "city_ascii": "Fort-Shevchenko",
    "lat": 44.5171,
    "lng": 50.2666,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Mangghystaū",
    "capital": "",
    "population": 5213,
    "id": 1398201971
  },
  {
    "city": "Xiangkhoang",
    "city_ascii": "Xiangkhoang",
    "lat": 19.3337,
    "lng": 103.3666,
    "country": "Laos",
    "iso2": "LA",
    "iso3": "LAO",
    "admin_name": "Xiangkhouang",
    "capital": "",
    "population": 5189,
    "id": 1418829021
  },
  {
    "city": "Baystonhill",
    "city_ascii": "Baystonhill",
    "lat": 52.6727,
    "lng": -2.7654,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Shropshire",
    "capital": "",
    "population": 5079,
    "id": 1826911422
  },
  {
    "city": "San Francisco",
    "city_ascii": "San Francisco",
    "lat": 20.5333,
    "lng": -102.5,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Jalisco",
    "capital": "",
    "population": 5167,
    "id": 1484609468
  },
  {
    "city": "Gresford",
    "city_ascii": "Gresford",
    "lat": 53.087,
    "lng": -2.966,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Wrexham",
    "capital": "",
    "population": 5010,
    "id": 1826005729
  },
  {
    "city": "Kings Langley",
    "city_ascii": "Kings Langley",
    "lat": 51.7156,
    "lng": -0.4569,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Hertfordshire",
    "capital": "",
    "population": 5072,
    "id": 1826435089
  },
  {
    "city": "Na‘jān",
    "city_ascii": "Na`jan",
    "lat": 24.0731,
    "lng": 47.17,
    "country": "Saudi Arabia",
    "iso2": "SA",
    "iso3": "SAU",
    "admin_name": "Ar Riyāḑ",
    "capital": "",
    "population": 5125,
    "id": 1682775277
  },
  {
    "city": "Combarbalá",
    "city_ascii": "Combarbala",
    "lat": -31.18,
    "lng": -71,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Coquimbo",
    "capital": "",
    "population": 5134,
    "id": 1152896722
  },
  {
    "city": "Chernyshevskiy",
    "city_ascii": "Chernyshevskiy",
    "lat": 63.0128,
    "lng": 112.4714,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sakha (Yakutiya)",
    "capital": "",
    "population": 5137,
    "id": 1643326299
  },
  {
    "city": "Villa del Rosario",
    "city_ascii": "Villa del Rosario",
    "lat": -24.4196,
    "lng": -57.1,
    "country": "Paraguay",
    "iso2": "PY",
    "iso3": "PRY",
    "admin_name": "San Pedro",
    "capital": "",
    "population": 5123,
    "id": 1600049206
  },
  {
    "city": "Burton",
    "city_ascii": "Burton",
    "lat": 45.8009,
    "lng": -66.4066,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "New Brunswick",
    "capital": "",
    "population": 5119,
    "id": 1124000544
  },
  {
    "city": "McMinns Lagoon",
    "city_ascii": "McMinns Lagoon",
    "lat": -12.5329,
    "lng": 131.05,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Northern Territory",
    "capital": "",
    "population": 5025,
    "id": 1036673821
  },
  {
    "city": "Spallumcheen",
    "city_ascii": "Spallumcheen",
    "lat": 50.4462,
    "lng": -119.2121,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "British Columbia",
    "capital": "",
    "population": 5106,
    "id": 1124001340
  },
  {
    "city": "Whiston",
    "city_ascii": "Whiston",
    "lat": 53.4,
    "lng": -1.32,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "Rotherham",
    "capital": "",
    "population": 5042,
    "id": 1826470625
  },
  {
    "city": "Middleton-on-Sea",
    "city_ascii": "Middleton-on-Sea",
    "lat": 50.7949,
    "lng": -0.6197,
    "country": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "admin_name": "West Sussex",
    "capital": "",
    "population": 5077,
    "id": 1826296040
  },
  {
    "city": "Qaşr al Farāfirah",
    "city_ascii": "Qasr al Farafirah",
    "lat": 27.0671,
    "lng": 27.9666,
    "country": "Egypt",
    "iso2": "EG",
    "iso3": "EGY",
    "admin_name": "Al Wādī al Jadīd",
    "capital": "",
    "population": 5000,
    "id": 1818000020
  },
  {
    "city": "Saint-Henri",
    "city_ascii": "Saint-Henri",
    "lat": 46.7,
    "lng": -71.0667,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Quebec",
    "capital": "",
    "population": 5023,
    "id": 1124057702
  },
  {
    "city": "Ste. Anne",
    "city_ascii": "Ste. Anne",
    "lat": 49.6186,
    "lng": -96.5708,
    "country": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "admin_name": "Manitoba",
    "capital": "",
    "population": 5003,
    "id": 1124000501
  },
  {
    "city": "Puerto Casado",
    "city_ascii": "Puerto Casado",
    "lat": -22.2896,
    "lng": -57.94,
    "country": "Paraguay",
    "iso2": "PY",
    "iso3": "PRY",
    "admin_name": "Alto Paraguay",
    "capital": "",
    "population": 5000,
    "id": 1600731282
  },
  {
    "city": "Ust’-Kamchatsk",
    "city_ascii": "Ust'-Kamchatsk",
    "lat": 56.2135,
    "lng": 162.435,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kamchatskiy Kray",
    "capital": "",
    "population": 4939,
    "id": 1643715088
  },
  {
    "city": "Cubal",
    "city_ascii": "Cubal",
    "lat": -13.0396,
    "lng": 14.24,
    "country": "Angola",
    "iso2": "AO",
    "iso3": "AGO",
    "admin_name": "Benguela",
    "capital": "",
    "population": 4837,
    "id": 1024580158
  },
  {
    "city": "Betanzos",
    "city_ascii": "Betanzos",
    "lat": -19.56,
    "lng": -65.45,
    "country": "Bolivia",
    "iso2": "BO",
    "iso3": "BOL",
    "admin_name": "Potosí",
    "capital": "",
    "population": 4847,
    "id": 1068258629
  },
  {
    "city": "Nata",
    "city_ascii": "Nata",
    "lat": -20.2095,
    "lng": 26.19,
    "country": "Botswana",
    "iso2": "BW",
    "iso3": "BWA",
    "admin_name": "Central",
    "capital": "",
    "population": 4794,
    "id": 1072462404
  },
  {
    "city": "Turukhansk",
    "city_ascii": "Turukhansk",
    "lat": 65.8382,
    "lng": 87.955,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Krasnoyarskiy Kray",
    "capital": "",
    "population": 4774,
    "id": 1643037921
  },
  {
    "city": "Bagdarin",
    "city_ascii": "Bagdarin",
    "lat": 54.4333,
    "lng": 113.6,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Buryatiya",
    "capital": "",
    "population": 4676,
    "id": 1643127681
  },
  {
    "city": "Sangar",
    "city_ascii": "Sangar",
    "lat": 63.9241,
    "lng": 127.4739,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sakha (Yakutiya)",
    "capital": "",
    "population": 4633,
    "id": 1643096707
  },
  {
    "city": "Brownsweg",
    "city_ascii": "Brownsweg",
    "lat": 5.02,
    "lng": -55.17,
    "country": "Suriname",
    "iso2": "SR",
    "iso3": "SUR",
    "admin_name": "Brokopondo",
    "capital": "",
    "population": 4582,
    "id": 1740310469
  },
  {
    "city": "Sokolo",
    "city_ascii": "Sokolo",
    "lat": 14.7337,
    "lng": -6.1333,
    "country": "Mali",
    "iso2": "ML",
    "iso3": "MLI",
    "admin_name": "Ségou",
    "capital": "",
    "population": 4374,
    "id": 1466033359
  },
  {
    "city": "Saryshaghan",
    "city_ascii": "Saryshaghan",
    "lat": 46.1195,
    "lng": 73.6191,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Qaraghandy",
    "capital": "",
    "population": 4365,
    "id": 1398222668
  },
  {
    "city": "Bekily",
    "city_ascii": "Bekily",
    "lat": -24.2162,
    "lng": 45.3166,
    "country": "Madagascar",
    "iso2": "MG",
    "iso3": "MDG",
    "admin_name": "Toliara",
    "capital": "",
    "population": 4286,
    "id": 1450655091
  },
  {
    "city": "El Maitén",
    "city_ascii": "El Maiten",
    "lat": -42.05,
    "lng": -71.1666,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires, Ciudad Autónoma de",
    "capital": "",
    "population": 4269,
    "id": 1032286116
  },
  {
    "city": "Batagay",
    "city_ascii": "Batagay",
    "lat": 67.656,
    "lng": 134.635,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sakha (Yakutiya)",
    "capital": "",
    "population": 4266,
    "id": 1643604003
  },
  {
    "city": "San Javier",
    "city_ascii": "San Javier",
    "lat": -16.2896,
    "lng": -62.5,
    "country": "Bolivia",
    "iso2": "BO",
    "iso3": "BOL",
    "admin_name": "Santa Cruz",
    "capital": "",
    "population": 4210,
    "id": 1068099078
  },
  {
    "city": "Omsukchan",
    "city_ascii": "Omsukchan",
    "lat": 62.5333,
    "lng": 155.8,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Magadanskaya Oblast’",
    "capital": "",
    "population": 4201,
    "id": 1643004933
  },
  {
    "city": "Apolo",
    "city_ascii": "Apolo",
    "lat": -14.7196,
    "lng": -68.42,
    "country": "Bolivia",
    "iso2": "BO",
    "iso3": "BOL",
    "admin_name": "La Paz",
    "capital": "",
    "population": 4189,
    "id": 1068238601
  },
  {
    "city": "Novyy Uoyan",
    "city_ascii": "Novyy Uoyan",
    "lat": 56.135,
    "lng": 111.7339,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Buryatiya",
    "capital": "",
    "population": 4184,
    "id": 1643043728
  },
  {
    "city": "Bongandanga",
    "city_ascii": "Bongandanga",
    "lat": 1.5104,
    "lng": 21.05,
    "country": "Congo (Kinshasa)",
    "iso2": "CD",
    "iso3": "COD",
    "admin_name": "Mongala",
    "capital": "",
    "population": 4105,
    "id": 1180746434
  },
  {
    "city": "Quime",
    "city_ascii": "Quime",
    "lat": -16.98,
    "lng": -67.22,
    "country": "Bolivia",
    "iso2": "BO",
    "iso3": "BOL",
    "admin_name": "La Paz",
    "capital": "",
    "population": 4045,
    "id": 1068690050
  },
  {
    "city": "Araouane",
    "city_ascii": "Araouane",
    "lat": 18.9,
    "lng": -3.53,
    "country": "Mali",
    "iso2": "ML",
    "iso3": "MLI",
    "admin_name": "Tombouctou",
    "capital": "",
    "population": 4026,
    "id": 1466354924
  },
  {
    "city": "Karmah an Nuzul",
    "city_ascii": "Karmah an Nuzul",
    "lat": 19.6337,
    "lng": 30.4166,
    "country": "Sudan",
    "iso2": "SD",
    "iso3": "SDN",
    "admin_name": "Northern",
    "capital": "",
    "population": 3928,
    "id": 1729918529
  },
  {
    "city": "Mbé",
    "city_ascii": "Mbe",
    "lat": 7.8504,
    "lng": 13.6,
    "country": "Cameroon",
    "iso2": "CM",
    "iso3": "CMR",
    "admin_name": "Nord",
    "capital": "",
    "population": 3950,
    "id": 1120400000
  },
  {
    "city": "Shongzhy",
    "city_ascii": "Shongzhy",
    "lat": 43.5421,
    "lng": 79.4703,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Almaty",
    "capital": "",
    "population": 3902,
    "id": 1398268525
  },
  {
    "city": "Ciudad Cortés",
    "city_ascii": "Ciudad Cortes",
    "lat": 8.96,
    "lng": -83.5239,
    "country": "Costa Rica",
    "iso2": "CR",
    "iso3": "CRI",
    "admin_name": "Puntarenas",
    "capital": "",
    "population": 3850,
    "id": 1188296792
  },
  {
    "city": "P’ungsan",
    "city_ascii": "P'ungsan",
    "lat": 40.8175,
    "lng": 128.1553,
    "country": "Korea, North",
    "iso2": "KP",
    "iso3": "PRK",
    "admin_name": "Yanggang",
    "capital": "",
    "population": 3839,
    "id": 1408098792
  },
  {
    "city": "Vitim",
    "city_ascii": "Vitim",
    "lat": 59.4515,
    "lng": 112.5578,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sakha (Yakutiya)",
    "capital": "",
    "population": 3843,
    "id": 1643662368
  },
  {
    "city": "Palana",
    "city_ascii": "Palana",
    "lat": 59.084,
    "lng": 159.95,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kamchatskiy Kray",
    "capital": "",
    "population": 3671,
    "id": 1643584640
  },
  {
    "city": "Teeli",
    "city_ascii": "Teeli",
    "lat": 51.0333,
    "lng": 90.2333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Tyva",
    "capital": "",
    "population": 3732,
    "id": 1643964699
  },
  {
    "city": "Cherskiy",
    "city_ascii": "Cherskiy",
    "lat": 68.7501,
    "lng": 161.33,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sakha (Yakutiya)",
    "capital": "",
    "population": 3707,
    "id": 1643174283
  },
  {
    "city": "Zyryanka",
    "city_ascii": "Zyryanka",
    "lat": 65.736,
    "lng": 150.89,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sakha (Yakutiya)",
    "capital": "",
    "population": 3627,
    "id": 1643201511
  },
  {
    "city": "De-Kastri",
    "city_ascii": "De-Kastri",
    "lat": 51.4666,
    "lng": 140.7833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Khabarovskiy Kray",
    "capital": "",
    "population": 3615,
    "id": 1643988786
  },
  {
    "city": "Ligonha",
    "city_ascii": "Ligonha",
    "lat": -15.1757,
    "lng": 37.74,
    "country": "Mozambique",
    "iso2": "MZ",
    "iso3": "MOZ",
    "admin_name": "Nampula",
    "capital": "",
    "population": 3500,
    "id": 1508105140
  },
  {
    "city": "Darregueira",
    "city_ascii": "Darregueira",
    "lat": -37.6996,
    "lng": -63.1666,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires",
    "capital": "",
    "population": 3412,
    "id": 1032968004
  },
  {
    "city": "Bukachacha",
    "city_ascii": "Bukachacha",
    "lat": 52.9833,
    "lng": 116.9166,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Zabaykal’skiy Kray",
    "capital": "",
    "population": 3372,
    "id": 1643741623
  },
  {
    "city": "Ugol’nyye Kopi",
    "city_ascii": "Ugol'nyye Kopi",
    "lat": 64.7333,
    "lng": 177.7,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Chukotskiy Avtonomnyy Okrug",
    "capital": "",
    "population": 3367,
    "id": 1643017360
  },
  {
    "city": "Lukulu",
    "city_ascii": "Lukulu",
    "lat": -14.3896,
    "lng": 23.24,
    "country": "Zambia",
    "iso2": "ZM",
    "iso3": "ZMB",
    "admin_name": "Western",
    "capital": "",
    "population": 3349,
    "id": 1894281443
  },
  {
    "city": "Krasnogorsk",
    "city_ascii": "Krasnogorsk",
    "lat": 48.4615,
    "lng": 142.09,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sakhalinskaya Oblast’",
    "capital": "",
    "population": 3304,
    "id": 1643415310
  },
  {
    "city": "Mopipi",
    "city_ascii": "Mopipi",
    "lat": -21.1795,
    "lng": 24.88,
    "country": "Botswana",
    "iso2": "BW",
    "iso3": "BWA",
    "admin_name": "Central",
    "capital": "",
    "population": 3301,
    "id": 1072326657
  },
  {
    "city": "Zhigansk",
    "city_ascii": "Zhigansk",
    "lat": 66.7697,
    "lng": 123.3711,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sakha (Yakutiya)",
    "capital": "",
    "population": 3237,
    "id": 1643742366
  },
  {
    "city": "Khatanga",
    "city_ascii": "Khatanga",
    "lat": 72.0411,
    "lng": 102.465,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Krasnoyarskiy Kray",
    "capital": "",
    "population": 3205,
    "id": 1643018667
  },
  {
    "city": "Mékambo",
    "city_ascii": "Mekambo",
    "lat": 1.0171,
    "lng": 13.9333,
    "country": "Gabon",
    "iso2": "GA",
    "iso3": "GAB",
    "admin_name": "Ogooué-Ivindo",
    "capital": "",
    "population": 3170,
    "id": 1266553682
  },
  {
    "city": "Arroyos y Esteros",
    "city_ascii": "Arroyos y Esteros",
    "lat": -25.05,
    "lng": -57.09,
    "country": "Paraguay",
    "iso2": "PY",
    "iso3": "PRY",
    "admin_name": "Cordillera",
    "capital": "",
    "population": 3055,
    "id": 1600827428
  },
  {
    "city": "Buur Gaabo",
    "city_ascii": "Buur Gaabo",
    "lat": -1.2009,
    "lng": 41.852,
    "country": "Somalia",
    "iso2": "SO",
    "iso3": "SOM",
    "admin_name": "Jubbada Hoose",
    "capital": "",
    "population": 3096,
    "id": 1706122850
  },
  {
    "city": "Ust’-Maya",
    "city_ascii": "Ust'-Maya",
    "lat": 60.4566,
    "lng": 134.5433,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sakha (Yakutiya)",
    "capital": "",
    "population": 3062,
    "id": 1643532310
  },
  {
    "city": "Abaí",
    "city_ascii": "Abai",
    "lat": -26.0296,
    "lng": -55.94,
    "country": "Paraguay",
    "iso2": "PY",
    "iso3": "PRY",
    "admin_name": "Caazapá",
    "capital": "",
    "population": 3024,
    "id": 1600189926
  },
  {
    "city": "I-n-Amguel",
    "city_ascii": "I-n-Amguel",
    "lat": 23.6939,
    "lng": 5.1647,
    "country": "Algeria",
    "iso2": "DZ",
    "iso3": "DZA",
    "admin_name": "Tamanrasset",
    "capital": "",
    "population": 3030,
    "id": 1012162135
  },
  {
    "city": "Taoudenni",
    "city_ascii": "Taoudenni",
    "lat": 22.6666,
    "lng": -3.9834,
    "country": "Mali",
    "iso2": "ML",
    "iso3": "MLI",
    "admin_name": "Tombouctou",
    "capital": "",
    "population": 3019,
    "id": 1466976172
  },
  {
    "city": "San Lorenzo",
    "city_ascii": "San Lorenzo",
    "lat": -21.4799,
    "lng": -64.77,
    "country": "Bolivia",
    "iso2": "BO",
    "iso3": "BOL",
    "admin_name": "Tarija",
    "capital": "",
    "population": 3000,
    "id": 1068000024
  },
  {
    "city": "Saranpaul",
    "city_ascii": "Saranpaul",
    "lat": 64.2505,
    "lng": 60.97,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Khanty-Mansiyskiy Avtonomnyy Okrug-Yugra",
    "capital": "",
    "population": 2985,
    "id": 1643184738
  },
  {
    "city": "Villalonga",
    "city_ascii": "Villalonga",
    "lat": -39.8829,
    "lng": -62.5833,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Buenos Aires",
    "capital": "",
    "population": 2838,
    "id": 1032282782
  },
  {
    "city": "Villa Ygatimí",
    "city_ascii": "Villa Ygatimi",
    "lat": -24.0796,
    "lng": -55.5,
    "country": "Paraguay",
    "iso2": "PY",
    "iso3": "PRY",
    "admin_name": "Canindeyú",
    "capital": "",
    "population": 2809,
    "id": 1600339943
  },
  {
    "city": "Entre Ríos",
    "city_ascii": "Entre Rios",
    "lat": -21.53,
    "lng": -64.19,
    "country": "Bolivia",
    "iso2": "BO",
    "iso3": "BOL",
    "admin_name": "Tarija",
    "capital": "",
    "population": 2685,
    "id": 1068494403
  },
  {
    "city": "Huasco",
    "city_ascii": "Huasco",
    "lat": -28.4696,
    "lng": -71.22,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Atacama",
    "capital": "",
    "population": 2558,
    "id": 1152643675
  },
  {
    "city": "Provideniya",
    "city_ascii": "Provideniya",
    "lat": 64.4235,
    "lng": -173.2258,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Chukotskiy Avtonomnyy Okrug",
    "capital": "",
    "population": 2536,
    "id": 1643868459
  },
  {
    "city": "Chokurdakh",
    "city_ascii": "Chokurdakh",
    "lat": 70.6183,
    "lng": 147.8946,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sakha (Yakutiya)",
    "capital": "",
    "population": 2506,
    "id": 1643382256
  },
  {
    "city": "Marādah",
    "city_ascii": "Maradah",
    "lat": 29.2337,
    "lng": 19.2166,
    "country": "Libya",
    "iso2": "LY",
    "iso3": "LBY",
    "admin_name": "Al Wāḩāt",
    "capital": "",
    "population": 2500,
    "id": 1434000794
  },
  {
    "city": "Mariscal José Félix Estigarribia",
    "city_ascii": "Mariscal Jose Felix Estigarribia",
    "lat": -22.03,
    "lng": -60.61,
    "country": "Paraguay",
    "iso2": "PY",
    "iso3": "PRY",
    "admin_name": "Boquerón",
    "capital": "",
    "population": 2500,
    "id": 1600781023
  },
  {
    "city": "José Batlle y Ordóñez",
    "city_ascii": "Jose Batlle y Ordonez",
    "lat": -33.47,
    "lng": -55.12,
    "country": "Uruguay",
    "iso2": "UY",
    "iso3": "URY",
    "admin_name": "Lavalleja",
    "capital": "",
    "population": 2438,
    "id": 1858852252
  },
  {
    "city": "Uspallata",
    "city_ascii": "Uspallata",
    "lat": -32.5931,
    "lng": -69.346,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Mendoza",
    "capital": "",
    "population": 2390,
    "id": 1032547934
  },
  {
    "city": "Sohano",
    "city_ascii": "Sohano",
    "lat": -5.4297,
    "lng": 154.6711,
    "country": "Papua New Guinea",
    "iso2": "PG",
    "iso3": "PNG",
    "admin_name": "Bougainville",
    "capital": "",
    "population": 2338,
    "id": 1598474458
  },
  {
    "city": "Oficina María Elena",
    "city_ascii": "Oficina Maria Elena",
    "lat": -22.35,
    "lng": -69.67,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Antofagasta",
    "capital": "",
    "population": 2370,
    "id": 1152876089
  },
  {
    "city": "Ceduna",
    "city_ascii": "Ceduna",
    "lat": -32.1167,
    "lng": 133.6667,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "South Australia",
    "capital": "",
    "population": 2157,
    "id": 1036725988
  },
  {
    "city": "Maltahöhe",
    "city_ascii": "Maltahohe",
    "lat": -24.84,
    "lng": 16.94,
    "country": "Namibia",
    "iso2": "NA",
    "iso3": "NAM",
    "admin_name": "Hardap",
    "capital": "",
    "population": 2329,
    "id": 1516195038
  },
  {
    "city": "Muconda",
    "city_ascii": "Muconda",
    "lat": -10.5996,
    "lng": 21.32,
    "country": "Angola",
    "iso2": "AO",
    "iso3": "AGO",
    "admin_name": "Lunda Sul",
    "capital": "",
    "population": 2324,
    "id": 1024646532
  },
  {
    "city": "Toltén",
    "city_ascii": "Tolten",
    "lat": -39.2166,
    "lng": -73.2123,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Araucanía",
    "capital": "",
    "population": 2293,
    "id": 1152450943
  },
  {
    "city": "Stanley",
    "city_ascii": "Stanley",
    "lat": -51.7,
    "lng": -57.85,
    "country": "Falkland Islands (Islas Malvinas)",
    "iso2": "FK",
    "iso3": "FLK",
    "admin_name": "",
    "capital": "",
    "population": 2213,
    "id": 1238780509
  },
  {
    "city": "Mwenga",
    "city_ascii": "Mwenga",
    "lat": -3.0382,
    "lng": 28.4325,
    "country": "Congo (Kinshasa)",
    "iso2": "CD",
    "iso3": "COD",
    "admin_name": "Sud-Kivu",
    "capital": "",
    "population": 2216,
    "id": 1180606289
  },
  {
    "city": "Egvekinot",
    "city_ascii": "Egvekinot",
    "lat": 66.3221,
    "lng": -179.1837,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Chukotskiy Avtonomnyy Okrug",
    "capital": "",
    "population": 2248,
    "id": 1643466779
  },
  {
    "city": "El Manteco",
    "city_ascii": "El Manteco",
    "lat": 7.3504,
    "lng": -62.5333,
    "country": "Venezuela",
    "iso2": "VE",
    "iso3": "VEN",
    "admin_name": "Bolívar",
    "capital": "",
    "population": 2215,
    "id": 1862113510
  },
  {
    "city": "Pozo Colorado",
    "city_ascii": "Pozo Colorado",
    "lat": -23.43,
    "lng": -58.86,
    "country": "Paraguay",
    "iso2": "PY",
    "iso3": "PRY",
    "admin_name": "Presidente Hayes",
    "capital": "",
    "population": 2135,
    "id": 1600340660
  },
  {
    "city": "Mejillones",
    "city_ascii": "Mejillones",
    "lat": -23.1,
    "lng": -70.45,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Antofagasta",
    "capital": "",
    "population": 2041,
    "id": 1152559509
  },
  {
    "city": "Evensk",
    "city_ascii": "Evensk",
    "lat": 61.95,
    "lng": 159.2333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Magadanskaya Oblast’",
    "capital": "",
    "population": 2024,
    "id": 1643589710
  },
  {
    "city": "Konza",
    "city_ascii": "Konza",
    "lat": -1.7496,
    "lng": 37.12,
    "country": "Kenya",
    "iso2": "KE",
    "iso3": "KEN",
    "admin_name": "Machakos",
    "capital": "",
    "population": 2004,
    "id": 1404143289
  },
  {
    "city": "Altata",
    "city_ascii": "Altata",
    "lat": 24.6333,
    "lng": -107.9167,
    "country": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "admin_name": "Sinaloa",
    "capital": "",
    "population": 2001,
    "id": 1484699069
  },
  {
    "city": "Dawwah",
    "city_ascii": "Dawwah",
    "lat": 20.633,
    "lng": 58.908,
    "country": "Oman",
    "iso2": "OM",
    "iso3": "OMN",
    "admin_name": "Janūb ash Sharqīyah",
    "capital": "",
    "population": 2000,
    "id": 1512793920
  },
  {
    "city": "Kaupanger",
    "city_ascii": "Kaupanger",
    "lat": 61.1833,
    "lng": 6.85,
    "country": "Norway",
    "iso2": "NO",
    "iso3": "NOR",
    "admin_name": "Sogn og Fjordane",
    "capital": "",
    "population": 1965,
    "id": 1578815332
  },
  {
    "city": "Tae-dong",
    "city_ascii": "Tae-dong",
    "lat": 40.6171,
    "lng": 125.4501,
    "country": "Korea, North",
    "iso2": "KP",
    "iso3": "PRK",
    "admin_name": "P’yŏngbuk",
    "capital": "",
    "population": 1884,
    "id": 1408763732
  },
  {
    "city": "Amapá",
    "city_ascii": "Amapa",
    "lat": 2.05,
    "lng": -50.8,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Amapá",
    "capital": "",
    "population": 1947,
    "id": 1076834242
  },
  {
    "city": "Lehututu",
    "city_ascii": "Lehututu",
    "lat": -23.9696,
    "lng": 21.87,
    "country": "Botswana",
    "iso2": "BW",
    "iso3": "BWA",
    "admin_name": "Kgalagadi",
    "capital": "",
    "population": 1942,
    "id": 1072398415
  },
  {
    "city": "Abunã",
    "city_ascii": "Abuna",
    "lat": -9.6954,
    "lng": -65.3597,
    "country": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "admin_name": "Rondônia",
    "capital": "",
    "population": 1929,
    "id": 1076632448
  },
  {
    "city": "Saskylakh",
    "city_ascii": "Saskylakh",
    "lat": 71.9166,
    "lng": 114.0833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sakha (Yakutiya)",
    "capital": "",
    "population": 1920,
    "id": 1643818493
  },
  {
    "city": "Corocoro",
    "city_ascii": "Corocoro",
    "lat": -17.1696,
    "lng": -68.45,
    "country": "Bolivia",
    "iso2": "BO",
    "iso3": "BOL",
    "admin_name": "La Paz",
    "capital": "",
    "population": 1884,
    "id": 1068830457
  },
  {
    "city": "Beringovskiy",
    "city_ascii": "Beringovskiy",
    "lat": 63.0655,
    "lng": 179.3067,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Chukotskiy Avtonomnyy Okrug",
    "capital": "",
    "population": 1861,
    "id": 1643017874
  },
  {
    "city": "Río Mayo",
    "city_ascii": "Rio Mayo",
    "lat": -45.6834,
    "lng": -70.2666,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Chubut",
    "capital": "",
    "population": 1825,
    "id": 1032155044
  },
  {
    "city": "Puerto Villarroel",
    "city_ascii": "Puerto Villarroel",
    "lat": -16.87,
    "lng": -64.78,
    "country": "Bolivia",
    "iso2": "BO",
    "iso3": "BOL",
    "admin_name": "Cochabamba",
    "capital": "",
    "population": 1778,
    "id": 1068957347
  },
  {
    "city": "Nasir",
    "city_ascii": "Nasir",
    "lat": 8.6004,
    "lng": 33.0666,
    "country": "South Sudan",
    "iso2": "SS",
    "iso3": "SSD",
    "admin_name": "Upper Nile",
    "capital": "",
    "population": 1741,
    "id": 1728210480
  },
  {
    "city": "Labutta",
    "city_ascii": "Labutta",
    "lat": 16.1619,
    "lng": 94.7014,
    "country": "Burma",
    "iso2": "MM",
    "iso3": "MMR",
    "admin_name": "Ayeyawady",
    "capital": "",
    "population": 1667,
    "id": 1104103403
  },
  {
    "city": "Novyy Port",
    "city_ascii": "Novyy Port",
    "lat": 67.6983,
    "lng": 72.9283,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Yamalo-Nenetskiy Avtonomnyy Okrug",
    "capital": "",
    "population": 1790,
    "id": 1643538343
  },
  {
    "city": "Nokaneng",
    "city_ascii": "Nokaneng",
    "lat": -19.6696,
    "lng": 22.27,
    "country": "Botswana",
    "iso2": "BW",
    "iso3": "BWA",
    "admin_name": "North West",
    "capital": "",
    "population": 1763,
    "id": 1072707427
  },
  {
    "city": "Al Jaghbūb",
    "city_ascii": "Al Jaghbub",
    "lat": 29.7504,
    "lng": 24.5166,
    "country": "Libya",
    "iso2": "LY",
    "iso3": "LBY",
    "admin_name": "Al Buţnān",
    "capital": "",
    "population": 1744,
    "id": 1434128425
  },
  {
    "city": "Al Qaşr",
    "city_ascii": "Al Qasr",
    "lat": 25.7004,
    "lng": 28.8833,
    "country": "Egypt",
    "iso2": "EG",
    "iso3": "EGY",
    "admin_name": "Al Wādī al Jadīd",
    "capital": "",
    "population": 1716,
    "id": 1818618401
  },
  {
    "city": "Omboué",
    "city_ascii": "Omboue",
    "lat": -1.5662,
    "lng": 9.25,
    "country": "Gabon",
    "iso2": "GA",
    "iso3": "GAB",
    "admin_name": "Ogooué-Maritime",
    "capital": "",
    "population": 1667,
    "id": 1266708175
  },
  {
    "city": "Kipili",
    "city_ascii": "Kipili",
    "lat": -7.4329,
    "lng": 30.6,
    "country": "Tanzania",
    "iso2": "TZ",
    "iso3": "TZA",
    "admin_name": "Rukwa",
    "capital": "",
    "population": 1533,
    "id": 1834589411
  },
  {
    "city": "Manja",
    "city_ascii": "Manja",
    "lat": -21.4329,
    "lng": 44.3333,
    "country": "Madagascar",
    "iso2": "MG",
    "iso3": "MDG",
    "admin_name": "Toliara",
    "capital": "",
    "population": 1536,
    "id": 1450949899
  },
  {
    "city": "Ust’-Kuyga",
    "city_ascii": "Ust'-Kuyga",
    "lat": 70.0171,
    "lng": 135.6,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sakha (Yakutiya)",
    "capital": "",
    "population": 1517,
    "id": 1643953506
  },
  {
    "city": "Eldikan",
    "city_ascii": "Eldikan",
    "lat": 60.8,
    "lng": 135.1833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sakha (Yakutiya)",
    "capital": "",
    "population": 1516,
    "id": 1643197422
  },
  {
    "city": "Chibemba",
    "city_ascii": "Chibemba",
    "lat": -15.7496,
    "lng": 14.08,
    "country": "Angola",
    "iso2": "AO",
    "iso3": "AGO",
    "admin_name": "Huíla",
    "capital": "",
    "population": 1502,
    "id": 1024009942
  },
  {
    "city": "Tajarhī",
    "city_ascii": "Tajarhi",
    "lat": 24.3704,
    "lng": 14.4708,
    "country": "Libya",
    "iso2": "LY",
    "iso3": "LBY",
    "admin_name": "Murzuq",
    "capital": "",
    "population": 1500,
    "id": 1434443909
  },
  {
    "city": "Lokwabe",
    "city_ascii": "Lokwabe",
    "lat": -24.1696,
    "lng": 21.83,
    "country": "Botswana",
    "iso2": "BW",
    "iso3": "BWA",
    "admin_name": "Kgalagadi",
    "capital": "",
    "population": 1473,
    "id": 1072642304
  },
  {
    "city": "Chibia",
    "city_ascii": "Chibia",
    "lat": -15.1896,
    "lng": 13.69,
    "country": "Angola",
    "iso2": "AO",
    "iso3": "AGO",
    "admin_name": "Huíla",
    "capital": "",
    "population": 1411,
    "id": 1024017930
  },
  {
    "city": "Tsau",
    "city_ascii": "Tsau",
    "lat": -20.1596,
    "lng": 22.46,
    "country": "Botswana",
    "iso2": "BW",
    "iso3": "BWA",
    "admin_name": "North West",
    "capital": "",
    "population": 1409,
    "id": 1072736607
  },
  {
    "city": "Qardho",
    "city_ascii": "Qardho",
    "lat": 9.5004,
    "lng": 49.166,
    "country": "Somalia",
    "iso2": "SO",
    "iso3": "SOM",
    "admin_name": "Bari",
    "capital": "",
    "population": 1341,
    "id": 1706405003
  },
  {
    "city": "Nyimba",
    "city_ascii": "Nyimba",
    "lat": -14.5495,
    "lng": 30.81,
    "country": "Zambia",
    "iso2": "ZM",
    "iso3": "ZMB",
    "admin_name": "Eastern",
    "capital": "",
    "population": 1336,
    "id": 1894088472
  },
  {
    "city": "Fulacunda",
    "city_ascii": "Fulacunda",
    "lat": 11.773,
    "lng": -15.195,
    "country": "Guinea-Bissau",
    "iso2": "GW",
    "iso3": "GNB",
    "admin_name": "Quinara",
    "capital": "",
    "population": 1311,
    "id": 1624165742
  },
  {
    "city": "Lubutu",
    "city_ascii": "Lubutu",
    "lat": -0.7329,
    "lng": 26.5833,
    "country": "Congo (Kinshasa)",
    "iso2": "CD",
    "iso3": "COD",
    "admin_name": "Maniema",
    "capital": "",
    "population": 1313,
    "id": 1180330836
  },
  {
    "city": "Cangamba",
    "city_ascii": "Cangamba",
    "lat": -13.6996,
    "lng": 19.86,
    "country": "Angola",
    "iso2": "AO",
    "iso3": "AGO",
    "admin_name": "Moxico",
    "capital": "",
    "population": 1307,
    "id": 1024036836
  },
  {
    "city": "Regedor Quissico",
    "city_ascii": "Regedor Quissico",
    "lat": -24.7257,
    "lng": 34.766,
    "country": "Mozambique",
    "iso2": "MZ",
    "iso3": "MOZ",
    "admin_name": "Inhambane",
    "capital": "",
    "population": 1210,
    "id": 1508214411
  },
  {
    "city": "Chumikan",
    "city_ascii": "Chumikan",
    "lat": 54.7114,
    "lng": 135.3145,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Khabarovskiy Kray",
    "capital": "",
    "population": 1305,
    "id": 1643639466
  },
  {
    "city": "Ayan",
    "city_ascii": "Ayan",
    "lat": 56.4542,
    "lng": 138.1673,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Khabarovskiy Kray",
    "capital": "",
    "population": 1286,
    "id": 1643586492
  },
  {
    "city": "Villa Rumipal",
    "city_ascii": "Villa Rumipal",
    "lat": -32.1833,
    "lng": -64.4833,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Córdoba",
    "capital": "",
    "population": 1269,
    "id": 1032717709
  },
  {
    "city": "Ñacunday",
    "city_ascii": "Nacunday",
    "lat": -26.02,
    "lng": -54.7699,
    "country": "Paraguay",
    "iso2": "PY",
    "iso3": "PRY",
    "admin_name": "Alto Paraná",
    "capital": "",
    "population": 1250,
    "id": 1600303466
  },
  {
    "city": "Lavrentiya",
    "city_ascii": "Lavrentiya",
    "lat": 65.5833,
    "lng": -171.025,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Chukotskiy Avtonomnyy Okrug",
    "capital": "",
    "population": 1242,
    "id": 1643889063
  },
  {
    "city": "Çeleken",
    "city_ascii": "Celeken",
    "lat": 39.4362,
    "lng": 53.1226,
    "country": "Turkmenistan",
    "iso2": "TM",
    "iso3": "TKM",
    "admin_name": "Balkan",
    "capital": "",
    "population": 1206,
    "id": 1795554809
  },
  {
    "city": "Buluko",
    "city_ascii": "Buluko",
    "lat": -0.757,
    "lng": 28.528,
    "country": "Congo (Kinshasa)",
    "iso2": "CD",
    "iso3": "COD",
    "admin_name": "Nord-Kivu",
    "capital": "",
    "population": 1192,
    "id": 1180000569
  },
  {
    "city": "San Rafael",
    "city_ascii": "San Rafael",
    "lat": -16.7795,
    "lng": -60.68,
    "country": "Bolivia",
    "iso2": "BO",
    "iso3": "BOL",
    "admin_name": "Santa Cruz",
    "capital": "",
    "population": 1201,
    "id": 1068405265
  },
  {
    "city": "Capitán Pablo Lagerenza",
    "city_ascii": "Capitan Pablo Lagerenza",
    "lat": -19.9161,
    "lng": -60.7833,
    "country": "Paraguay",
    "iso2": "PY",
    "iso3": "PRY",
    "admin_name": "Alto Paraguay",
    "capital": "",
    "population": 1200,
    "id": 1600527430
  },
  {
    "city": "Puerto Acosta",
    "city_ascii": "Puerto Acosta",
    "lat": -15.4996,
    "lng": -69.1667,
    "country": "Bolivia",
    "iso2": "BO",
    "iso3": "BOL",
    "admin_name": "La Paz",
    "capital": "",
    "population": 1123,
    "id": 1068206449
  },
  {
    "city": "Los Blancos",
    "city_ascii": "Los Blancos",
    "lat": -23.5996,
    "lng": -62.6,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Salta",
    "capital": "",
    "population": 1145,
    "id": 1032336288
  },
  {
    "city": "Mirbāţ",
    "city_ascii": "Mirbat",
    "lat": 16.9924,
    "lng": 54.6918,
    "country": "Oman",
    "iso2": "OM",
    "iso3": "OMN",
    "admin_name": "Z̧ufār",
    "capital": "",
    "population": 1120,
    "id": 1512455360
  },
  {
    "city": "Dikson",
    "city_ascii": "Dikson",
    "lat": 73.507,
    "lng": 80.5451,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Krasnoyarskiy Kray",
    "capital": "",
    "population": 1113,
    "id": 1643554430
  },
  {
    "city": "Klyuchi",
    "city_ascii": "Klyuchi",
    "lat": 56.3,
    "lng": 160.85,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kamchatskiy Kray",
    "capital": "",
    "population": 1089,
    "id": 1643863467
  },
  {
    "city": "Omolon",
    "city_ascii": "Omolon",
    "lat": 65.25,
    "lng": 160.5,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Chukotskiy Avtonomnyy Okrug",
    "capital": "",
    "population": 1050,
    "id": 1643719081
  },
  {
    "city": "Besalampy",
    "city_ascii": "Besalampy",
    "lat": -16.7495,
    "lng": 44.4833,
    "country": "Madagascar",
    "iso2": "MG",
    "iso3": "MDG",
    "admin_name": "Mahajanga",
    "capital": "",
    "population": 1022,
    "id": 1450740445
  },
  {
    "city": "Cacolo",
    "city_ascii": "Cacolo",
    "lat": -10.1496,
    "lng": 19.26,
    "country": "Angola",
    "iso2": "AO",
    "iso3": "AGO",
    "admin_name": "Lunda Sul",
    "capital": "",
    "population": 984,
    "id": 1024592975
  },
  {
    "city": "General Eugenio A. Garay",
    "city_ascii": "General Eugenio A. Garay",
    "lat": -20.52,
    "lng": -62.21,
    "country": "Paraguay",
    "iso2": "PY",
    "iso3": "PRY",
    "admin_name": "Boquerón",
    "capital": "",
    "population": 972,
    "id": 1600467772
  },
  {
    "city": "Daraj",
    "city_ascii": "Daraj",
    "lat": 30.15,
    "lng": 10.45,
    "country": "Libya",
    "iso2": "LY",
    "iso3": "LBY",
    "admin_name": "Nālūt",
    "capital": "",
    "population": 931,
    "id": 1434822177
  },
  {
    "city": "Bávaro",
    "city_ascii": "Bavaro",
    "lat": 18.717,
    "lng": -68.45,
    "country": "Dominican Republic",
    "iso2": "DO",
    "iso3": "DOM",
    "admin_name": "Yuma",
    "capital": "",
    "population": 795,
    "id": 1214902144
  },
  {
    "city": "Luanza",
    "city_ascii": "Luanza",
    "lat": -8.6996,
    "lng": 28.7,
    "country": "Congo (Kinshasa)",
    "iso2": "CD",
    "iso3": "COD",
    "admin_name": "Haut-Katanga",
    "capital": "",
    "population": 861,
    "id": 1180293709
  },
  {
    "city": "Hoskins",
    "city_ascii": "Hoskins",
    "lat": -5.4746,
    "lng": 150.41,
    "country": "Papua New Guinea",
    "iso2": "PG",
    "iso3": "PNG",
    "admin_name": "West New Britain",
    "capital": "",
    "population": 871,
    "id": 1598684520
  },
  {
    "city": "Calulo",
    "city_ascii": "Calulo",
    "lat": -9.9996,
    "lng": 14.9,
    "country": "Angola",
    "iso2": "AO",
    "iso3": "AGO",
    "admin_name": "Kwanza Sul",
    "capital": "",
    "population": 795,
    "id": 1024545873
  },
  {
    "city": "Uelen",
    "city_ascii": "Uelen",
    "lat": 66.1541,
    "lng": -169.8106,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Chukotskiy Avtonomnyy Okrug",
    "capital": "",
    "population": 776,
    "id": 1643387315
  },
  {
    "city": "Muhembo",
    "city_ascii": "Muhembo",
    "lat": -18.2996,
    "lng": 21.8,
    "country": "Botswana",
    "iso2": "BW",
    "iso3": "BWA",
    "admin_name": "North West",
    "capital": "",
    "population": 757,
    "id": 1072113578
  },
  {
    "city": "Yerëma",
    "city_ascii": "Yerema",
    "lat": 60.3808,
    "lng": 107.7794,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Irkutskaya Oblast’",
    "capital": "",
    "population": 745,
    "id": 1643177367
  },
  {
    "city": "Comallo",
    "city_ascii": "Comallo",
    "lat": -41.0333,
    "lng": -70.2666,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Río Negro",
    "capital": "",
    "population": 741,
    "id": 1032806030
  },
  {
    "city": "Satadougou",
    "city_ascii": "Satadougou",
    "lat": 12.617,
    "lng": -11.4066,
    "country": "Mali",
    "iso2": "ML",
    "iso3": "MLI",
    "admin_name": "Kayes",
    "capital": "",
    "population": 706,
    "id": 1466591334
  },
  {
    "city": "Dibaya",
    "city_ascii": "Dibaya",
    "lat": -6.5095,
    "lng": 22.87,
    "country": "Congo (Kinshasa)",
    "iso2": "CD",
    "iso3": "COD",
    "admin_name": "Kasaï Central",
    "capital": "",
    "population": 603,
    "id": 1180985189
  },
  {
    "city": "Serebryansk",
    "city_ascii": "Serebryansk",
    "lat": 49.6999,
    "lng": 83.4249,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Shyghys Qazaqstan",
    "capital": "",
    "population": 701,
    "id": 1398188434
  },
  {
    "city": "Zhaltyr",
    "city_ascii": "Zhaltyr",
    "lat": 51.6324,
    "lng": 69.8328,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Aqmola",
    "capital": "",
    "population": 694,
    "id": 1398809487
  },
  {
    "city": "Calatrava",
    "city_ascii": "Calatrava",
    "lat": 1.1164,
    "lng": 9.4186,
    "country": "Equatorial Guinea",
    "iso2": "GQ",
    "iso3": "GNQ",
    "admin_name": "Litoral",
    "capital": "",
    "population": 628,
    "id": 1226322426
  },
  {
    "city": "Massangena",
    "city_ascii": "Massangena",
    "lat": -21.5373,
    "lng": 32.9564,
    "country": "Mozambique",
    "iso2": "MZ",
    "iso3": "MOZ",
    "admin_name": "Gaza",
    "capital": "",
    "population": 650,
    "id": 1508224482
  },
  {
    "city": "Panda",
    "city_ascii": "Panda",
    "lat": -24.0629,
    "lng": 34.7303,
    "country": "Mozambique",
    "iso2": "MZ",
    "iso3": "MOZ",
    "admin_name": "Inhambane",
    "capital": "",
    "population": 602,
    "id": 1508574997
  },
  {
    "city": "Ypejhú",
    "city_ascii": "Ypejhu",
    "lat": -23.91,
    "lng": -55.46,
    "country": "Paraguay",
    "iso2": "PY",
    "iso3": "PRY",
    "admin_name": "Canindeyú",
    "capital": "",
    "population": 600,
    "id": 1600827924
  },
  {
    "city": "Quilpie",
    "city_ascii": "Quilpie",
    "lat": -26.6161,
    "lng": 144.2675,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "Queensland",
    "capital": "",
    "population": 595,
    "id": 1036696232
  },
  {
    "city": "Sabaya",
    "city_ascii": "Sabaya",
    "lat": -19.0163,
    "lng": -68.3833,
    "country": "Bolivia",
    "iso2": "BO",
    "iso3": "BOL",
    "admin_name": "Oruro",
    "capital": "",
    "population": 573,
    "id": 1068184161
  },
  {
    "city": "Chiramba",
    "city_ascii": "Chiramba",
    "lat": -16.8921,
    "lng": 34.6559,
    "country": "Mozambique",
    "iso2": "MZ",
    "iso3": "MOZ",
    "admin_name": "Manica",
    "capital": "",
    "population": 556,
    "id": 1508392455
  },
  {
    "city": "Mikhalkino",
    "city_ascii": "Mikhalkino",
    "lat": 69.4244,
    "lng": 161.4811,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sakha (Yakutiya)",
    "capital": "",
    "population": 570,
    "id": 1643001530
  },
  {
    "city": "Mereeg",
    "city_ascii": "Mereeg",
    "lat": 3.7666,
    "lng": 47.3,
    "country": "Somalia",
    "iso2": "SO",
    "iso3": "SOM",
    "admin_name": "Galguduud",
    "capital": "",
    "population": 548,
    "id": 1706308443
  },
  {
    "city": "Llica",
    "city_ascii": "Llica",
    "lat": -19.8496,
    "lng": -68.25,
    "country": "Bolivia",
    "iso2": "BO",
    "iso3": "BOL",
    "admin_name": "Potosí",
    "capital": "",
    "population": 553,
    "id": 1068053822
  },
  {
    "city": "Calenga",
    "city_ascii": "Calenga",
    "lat": -11.3196,
    "lng": 16.2,
    "country": "Angola",
    "iso2": "AO",
    "iso3": "AGO",
    "admin_name": "Bié",
    "capital": "",
    "population": 531,
    "id": 1024822780
  },
  {
    "city": "Caluula",
    "city_ascii": "Caluula",
    "lat": 11.967,
    "lng": 50.75,
    "country": "Somalia",
    "iso2": "SO",
    "iso3": "SOM",
    "admin_name": "Bari",
    "capital": "",
    "population": 513,
    "id": 1706796061
  },
  {
    "city": "Tournavista",
    "city_ascii": "Tournavista",
    "lat": -8.9322,
    "lng": -74.7052,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "Huánuco",
    "capital": "",
    "population": 511,
    "id": 1604006133
  },
  {
    "city": "Puerto Pinasco",
    "city_ascii": "Puerto Pinasco",
    "lat": -22.64,
    "lng": -57.79,
    "country": "Paraguay",
    "iso2": "PY",
    "iso3": "PRY",
    "admin_name": "Presidente Hayes",
    "capital": "",
    "population": 500,
    "id": 1600670025
  },
  {
    "city": "Chitado",
    "city_ascii": "Chitado",
    "lat": -17.3196,
    "lng": 13.92,
    "country": "Angola",
    "iso2": "AO",
    "iso3": "AGO",
    "admin_name": "Cunene",
    "capital": "",
    "population": 500,
    "id": 1024158837
  },
  {
    "city": "Yakossi",
    "city_ascii": "Yakossi",
    "lat": 5.617,
    "lng": 23.3167,
    "country": "Central African Republic",
    "iso2": "CF",
    "iso3": "CAF",
    "admin_name": "Mbomou",
    "capital": "",
    "population": 500,
    "id": 1140246753
  },
  {
    "city": "Tmassah",
    "city_ascii": "Tmassah",
    "lat": 26.3666,
    "lng": 15.8,
    "country": "Libya",
    "iso2": "LY",
    "iso3": "LBY",
    "admin_name": "Murzuq",
    "capital": "",
    "population": 500,
    "id": 1434333715
  },
  {
    "city": "Manyoni",
    "city_ascii": "Manyoni",
    "lat": -5.7796,
    "lng": 34.9,
    "country": "Tanzania",
    "iso2": "TZ",
    "iso3": "TZA",
    "admin_name": "Singida",
    "capital": "",
    "population": 310,
    "id": 1834963293
  },
  {
    "city": "Woomera",
    "city_ascii": "Woomera",
    "lat": -31.1496,
    "lng": 136.8,
    "country": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "admin_name": "South Australia",
    "capital": "",
    "population": 450,
    "id": 1036392822
  },
  {
    "city": "Tasiusaq",
    "city_ascii": "Tasiusaq",
    "lat": 73.369,
    "lng": -56.0598,
    "country": "Greenland",
    "iso2": "GL",
    "iso3": "GRL",
    "admin_name": "Qaasuitsup",
    "capital": "",
    "population": 250,
    "id": 1304103267
  },
  {
    "city": "Sherlovaya Gora",
    "city_ascii": "Sherlovaya Gora",
    "lat": 50.5306,
    "lng": 116.3006,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Zabaykal’skiy Kray",
    "capital": "",
    "population": 411,
    "id": 1643008235
  },
  {
    "city": "Tsavo",
    "city_ascii": "Tsavo",
    "lat": -2.9828,
    "lng": 38.4666,
    "country": "Kenya",
    "iso2": "KE",
    "iso3": "KEN",
    "admin_name": "Taita/Taveta",
    "capital": "",
    "population": 414,
    "id": 1404321644
  },
  {
    "city": "Comandante Luis Piedra Buena",
    "city_ascii": "Comandante Luis Piedra Buena",
    "lat": -49.9745,
    "lng": -68.9035,
    "country": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "admin_name": "Santa Cruz",
    "capital": "",
    "population": 410,
    "id": 1032946044
  },
  {
    "city": "Korf",
    "city_ascii": "Korf",
    "lat": 60.3321,
    "lng": 165.8183,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kamchatskiy Kray",
    "capital": "",
    "population": 400,
    "id": 1643630456
  },
  {
    "city": "Nizhneyansk",
    "city_ascii": "Nizhneyansk",
    "lat": 71.4333,
    "lng": 136.0666,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sakha (Yakutiya)",
    "capital": "",
    "population": 400,
    "id": 1643012685
  },
  {
    "city": "Toconao",
    "city_ascii": "Toconao",
    "lat": -23.1829,
    "lng": -68.0166,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Antofagasta",
    "capital": "",
    "population": 378,
    "id": 1152732862
  },
  {
    "city": "Balsadero Río Verde",
    "city_ascii": "Balsadero Rio Verde",
    "lat": -52.65,
    "lng": -71.4666,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Magallanes y de la Antártica Chilena",
    "capital": "",
    "population": 358,
    "id": 1152114092
  },
  {
    "city": "Kanyato",
    "city_ascii": "Kanyato",
    "lat": -4.4565,
    "lng": 30.2614,
    "country": "Tanzania",
    "iso2": "TZ",
    "iso3": "TZA",
    "admin_name": "Kigoma",
    "capital": "",
    "population": 232,
    "id": 1834158031
  },
  {
    "city": "Kulusuk",
    "city_ascii": "Kulusuk",
    "lat": 65.5666,
    "lng": -37.1833,
    "country": "Greenland",
    "iso2": "GL",
    "iso3": "GRL",
    "admin_name": "Sermersooq",
    "capital": "",
    "population": 286,
    "id": 1304671891
  },
  {
    "city": "Umm al ‘Abīd",
    "city_ascii": "Umm al `Abid",
    "lat": 27.517,
    "lng": 15.0333,
    "country": "Libya",
    "iso2": "LY",
    "iso3": "LBY",
    "admin_name": "Wādī ash Shāţi’",
    "capital": "",
    "population": 300,
    "id": 1434373121
  },
  {
    "city": "Cazombo",
    "city_ascii": "Cazombo",
    "lat": -11.8896,
    "lng": 22.9,
    "country": "Angola",
    "iso2": "AO",
    "iso3": "AGO",
    "admin_name": "Moxico",
    "capital": "",
    "population": 298,
    "id": 1024334740
  },
  {
    "city": "Shoyna",
    "city_ascii": "Shoyna",
    "lat": 67.8666,
    "lng": 44.1334,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Nenetskiy Avtonomnyy Okrug",
    "capital": "",
    "population": 300,
    "id": 1643012303
  },
  {
    "city": "Bugrino",
    "city_ascii": "Bugrino",
    "lat": 68.8079,
    "lng": 49.3042,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Nenetskiy Avtonomnyy Okrug",
    "capital": "",
    "population": 300,
    "id": 1643062803
  },
  {
    "city": "Put’ Lenina",
    "city_ascii": "Put' Lenina",
    "lat": 68.5166,
    "lng": 107.8,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sakha (Yakutiya)",
    "capital": "",
    "population": 298,
    "id": 1643749671
  },
  {
    "city": "Enurmino",
    "city_ascii": "Enurmino",
    "lat": 66.95,
    "lng": -171.8166,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Chukotskiy Avtonomnyy Okrug",
    "capital": "",
    "population": 297,
    "id": 1643693470
  },
  {
    "city": "Yaupi",
    "city_ascii": "Yaupi",
    "lat": -2.8543,
    "lng": -77.9363,
    "country": "Ecuador",
    "iso2": "EC",
    "iso3": "ECU",
    "admin_name": "Morona-Santiago",
    "capital": "",
    "population": 293,
    "id": 1218516951
  },
  {
    "city": "Amderma",
    "city_ascii": "Amderma",
    "lat": 69.763,
    "lng": 61.6677,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Nenetskiy Avtonomnyy Okrug",
    "capital": "",
    "population": 282,
    "id": 1643719353
  },
  {
    "city": "Kangersuatsiaq",
    "city_ascii": "Kangersuatsiaq",
    "lat": 72.3796,
    "lng": -55.5491,
    "country": "Greenland",
    "iso2": "GL",
    "iso3": "GRL",
    "admin_name": "Qaasuitsup",
    "capital": "",
    "population": 200,
    "id": 1304939198
  },
  {
    "city": "Villa O’Higgins",
    "city_ascii": "Villa O'Higgins",
    "lat": -48.4679,
    "lng": -72.56,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Aysén",
    "capital": "",
    "population": 250,
    "id": 1152709181
  },
  {
    "city": "Amau",
    "city_ascii": "Amau",
    "lat": -10.0426,
    "lng": 148.565,
    "country": "Papua New Guinea",
    "iso2": "PG",
    "iso3": "PNG",
    "admin_name": "Central",
    "capital": "",
    "population": 230,
    "id": 1598610032
  },
  {
    "city": "Kalima",
    "city_ascii": "Kalima",
    "lat": -2.5096,
    "lng": 26.43,
    "country": "Congo (Kinshasa)",
    "iso2": "CD",
    "iso3": "COD",
    "admin_name": "Maniema",
    "capital": "",
    "population": 194,
    "id": 1180335450
  },
  {
    "city": "I-n-Amenas",
    "city_ascii": "I-n-Amenas",
    "lat": 28.0503,
    "lng": 9.55,
    "country": "Algeria",
    "iso2": "DZ",
    "iso3": "DZA",
    "admin_name": "Illizi",
    "capital": "",
    "population": 216,
    "id": 1012221429
  },
  {
    "city": "Quipungo",
    "city_ascii": "Quipungo",
    "lat": -14.8295,
    "lng": 14.55,
    "country": "Angola",
    "iso2": "AO",
    "iso3": "AGO",
    "admin_name": "Huíla",
    "capital": "",
    "population": 186,
    "id": 1024929138
  },
  {
    "city": "Al Qunfudhah",
    "city_ascii": "Al Qunfudhah",
    "lat": 19.1264,
    "lng": 41.0789,
    "country": "Saudi Arabia",
    "iso2": "SA",
    "iso3": "SAU",
    "admin_name": "Makkah al Mukarramah",
    "capital": "",
    "population": 157,
    "id": 1682434353
  },
  {
    "city": "Lusanga",
    "city_ascii": "Lusanga",
    "lat": -5.5829,
    "lng": 16.5167,
    "country": "Congo (Kinshasa)",
    "iso2": "CD",
    "iso3": "COD",
    "admin_name": "Kwango",
    "capital": "",
    "population": 177,
    "id": 1180423801
  },
  {
    "city": "Kraulshavn",
    "city_ascii": "Kraulshavn",
    "lat": 74.121,
    "lng": -57.0674,
    "country": "Greenland",
    "iso2": "GL",
    "iso3": "GRL",
    "admin_name": "Qaasuitsup",
    "capital": "",
    "population": 204,
    "id": 1304966853
  },
  {
    "city": "Androka",
    "city_ascii": "Androka",
    "lat": -25.0219,
    "lng": 44.0749,
    "country": "Madagascar",
    "iso2": "MG",
    "iso3": "MDG",
    "admin_name": "Toliara",
    "capital": "",
    "population": 174,
    "id": 1450495720
  },
  {
    "city": "Charaña",
    "city_ascii": "Charana",
    "lat": -17.5996,
    "lng": -69.4666,
    "country": "Bolivia",
    "iso2": "BO",
    "iso3": "BOL",
    "admin_name": "La Paz",
    "capital": "",
    "population": 197,
    "id": 1068774768
  },
  {
    "city": "Nichicun",
    "city_ascii": "Nichicun",
    "lat": 29.5333,
    "lng": 94.4167,
    "country": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "admin_name": "Tibet",
    "capital": "",
    "population": 100,
    "id": 1156860651
  },
  {
    "city": "Hurdiyo",
    "city_ascii": "Hurdiyo",
    "lat": 10.5667,
    "lng": 51.1333,
    "country": "Somalia",
    "iso2": "SO",
    "iso3": "SOM",
    "admin_name": "Bari",
    "capital": "",
    "population": 176,
    "id": 1706025904
  },
  {
    "city": "Buton",
    "city_ascii": "Buton",
    "lat": 4.217,
    "lng": 108.2,
    "country": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "admin_name": "Kepulauan Riau",
    "capital": "",
    "population": 140,
    "id": 1360984658
  },
  {
    "city": "Narsarsuaq",
    "city_ascii": "Narsarsuaq",
    "lat": 61.1666,
    "lng": -45.4166,
    "country": "Greenland",
    "iso2": "GL",
    "iso3": "GRL",
    "admin_name": "Kujalleq",
    "capital": "",
    "population": 145,
    "id": 1304138343
  },
  {
    "city": "Bafwasende",
    "city_ascii": "Bafwasende",
    "lat": 1.0838,
    "lng": 27.2666,
    "country": "Congo (Kinshasa)",
    "iso2": "CD",
    "iso3": "COD",
    "admin_name": "Tshopo",
    "capital": "",
    "population": 149,
    "id": 1180841746
  },
  {
    "city": "Luiana",
    "city_ascii": "Luiana",
    "lat": -17.3695,
    "lng": 23,
    "country": "Angola",
    "iso2": "AO",
    "iso3": "AGO",
    "admin_name": "Kuando Kubango",
    "capital": "",
    "population": 150,
    "id": 1024455938
  },
  {
    "city": "Cuito Cuanavale",
    "city_ascii": "Cuito Cuanavale",
    "lat": -15.1596,
    "lng": 19.17,
    "country": "Angola",
    "iso2": "AO",
    "iso3": "AGO",
    "admin_name": "Kuando Kubango",
    "capital": "",
    "population": 149,
    "id": 1024195525
  },
  {
    "city": "Bifoun",
    "city_ascii": "Bifoun",
    "lat": -0.3329,
    "lng": 10.3832,
    "country": "Gabon",
    "iso2": "GA",
    "iso3": "GAB",
    "admin_name": "Moyen-Ogooué",
    "capital": "",
    "population": 134,
    "id": 1266655418
  },
  {
    "city": "Sharbaqty",
    "city_ascii": "Sharbaqty",
    "lat": 52.4999,
    "lng": 78.1499,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Pavlodar",
    "capital": "",
    "population": 107,
    "id": 1398705015
  },
  {
    "city": "Ağdam",
    "city_ascii": "Agdam",
    "lat": 40.9053,
    "lng": 45.5564,
    "country": "Azerbaijan",
    "iso2": "AZ",
    "iso3": "AZE",
    "admin_name": "Tovuz",
    "capital": "",
    "population": 0,
    "id": 1031834023
  },
  {
    "city": "Savissivik",
    "city_ascii": "Savissivik",
    "lat": 76.0195,
    "lng": -65.1125,
    "country": "Greenland",
    "iso2": "GL",
    "iso3": "GRL",
    "admin_name": "Qaasuitsup",
    "capital": "",
    "population": 66,
    "id": 1304246272
  },
  {
    "city": "Cuya",
    "city_ascii": "Cuya",
    "lat": -19.1597,
    "lng": -70.1794,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Arica y Parinacota",
    "capital": "",
    "population": 20,
    "id": 1152944039
  },
  {
    "city": "Villa Martín Colchak",
    "city_ascii": "Villa Martin Colchak",
    "lat": -20.7666,
    "lng": -67.7833,
    "country": "Bolivia",
    "iso2": "BO",
    "iso3": "BOL",
    "admin_name": "Potosí",
    "capital": "",
    "population": 10,
    "id": 1068713966
  },
  {
    "city": "Gyda",
    "city_ascii": "Gyda",
    "lat": 70.8814,
    "lng": 78.4661,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Yamalo-Nenetskiy Avtonomnyy Okrug",
    "capital": "",
    "population": 10,
    "id": 1643172565
  },
  {
    "city": "Güeppí",
    "city_ascii": "Gueppi",
    "lat": -0.1166,
    "lng": -75.23,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "Loreto",
    "capital": "",
    "population": 10,
    "id": 1604411859
  },
  {
    "city": "Tiyerbes",
    "city_ascii": "Tiyerbes",
    "lat": 64.3761,
    "lng": 120.5469,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sakha (Yakutiya)",
    "capital": "",
    "population": 10,
    "id": 1643794725
  },
  {
    "city": "Puerto Heath",
    "city_ascii": "Puerto Heath",
    "lat": -12.52,
    "lng": -68.6186,
    "country": "Bolivia",
    "iso2": "BO",
    "iso3": "BOL",
    "admin_name": "La Paz",
    "capital": "",
    "population": 10,
    "id": 1068996459
  },
  {
    "city": "Bîr Mogreïn",
    "city_ascii": "Bir Mogrein",
    "lat": 25.2333,
    "lng": -11.5833,
    "country": "Mauritania",
    "iso2": "MR",
    "iso3": "MRT",
    "admin_name": "Tiris Zemmour",
    "capital": "",
    "population": 10,
    "id": 1478537499
  },
  {
    "city": "Zhilinda",
    "city_ascii": "Zhilinda",
    "lat": 70.1337,
    "lng": 114,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sakha (Yakutiya)",
    "capital": "",
    "population": 10,
    "id": 1643497497
  },
  {
    "city": "Manily",
    "city_ascii": "Manily",
    "lat": 62.55,
    "lng": 165.3,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kamchatskiy Kray",
    "capital": "",
    "population": 10,
    "id": 1643235167
  },
  {
    "city": "Ust’-Olenëk",
    "city_ascii": "Ust'-Olenek",
    "lat": 72.9978,
    "lng": 119.7729,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sakha (Yakutiya)",
    "capital": "",
    "population": 10,
    "id": 1643349100
  },
  {
    "city": "Yessey",
    "city_ascii": "Yessey",
    "lat": 68.4837,
    "lng": 102.1666,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Krasnoyarskiy Kray",
    "capital": "",
    "population": 10,
    "id": 1643816547
  },
  {
    "city": "Logashkino",
    "city_ascii": "Logashkino",
    "lat": 70.8504,
    "lng": 153.9,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sakha (Yakutiya)",
    "capital": "",
    "population": 0,
    "id": 1643050775
  },
  {
    "city": "Mucusso",
    "city_ascii": "Mucusso",
    "lat": -18.0195,
    "lng": 21.43,
    "country": "Angola",
    "iso2": "AO",
    "iso3": "AGO",
    "admin_name": "Kuando Kubango",
    "capital": "",
    "population": 100,
    "id": 1024035453
  },
  {
    "city": "Burubaytal",
    "city_ascii": "Burubaytal",
    "lat": 44.9387,
    "lng": 74.0303,
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "admin_name": "Zhambyl",
    "capital": "",
    "population": 100,
    "id": 1398655547
  },
  {
    "city": "Mukhomornoye",
    "city_ascii": "Mukhomornoye",
    "lat": 66.4171,
    "lng": 173.3333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Chukotskiy Avtonomnyy Okrug",
    "capital": "",
    "population": 100,
    "id": 1643631484
  },
  {
    "city": "Vorontsovo",
    "city_ascii": "Vorontsovo",
    "lat": 71.6983,
    "lng": 83.5642,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Krasnoyarskiy Kray",
    "capital": "",
    "population": 100,
    "id": 1643504976
  },
  {
    "city": "Grytviken",
    "city_ascii": "Grytviken",
    "lat": -54.2806,
    "lng": -36.508,
    "country": "South Georgia And South Sandwich Islands",
    "iso2": "GS",
    "iso3": "SGS",
    "admin_name": "",
    "capital": "",
    "population": 99,
    "id": 1239048837
  },
  {
    "city": "Piso Firme",
    "city_ascii": "Piso Firme",
    "lat": -13.683,
    "lng": -61.8666,
    "country": "Bolivia",
    "iso2": "BO",
    "iso3": "BOL",
    "admin_name": "El Beni",
    "capital": "",
    "population": 72,
    "id": 1068400874
  },
  {
    "city": "Rocafuerte",
    "city_ascii": "Rocafuerte",
    "lat": -0.9329,
    "lng": -75.4,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "Loreto",
    "capital": "",
    "population": 40,
    "id": 1604951110
  },
  {
    "city": "Kovda",
    "city_ascii": "Kovda",
    "lat": 66.6903,
    "lng": 32.8702,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Murmanskaya Oblast’",
    "capital": "",
    "population": 20,
    "id": 1643959579
  },
  {
    "city": "Peregrebnoye",
    "city_ascii": "Peregrebnoye",
    "lat": 62.967,
    "lng": 65.0859,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Khanty-Mansiyskiy Avtonomnyy Okrug-Yugra",
    "capital": "",
    "population": 10,
    "id": 1643322655
  },
  {
    "city": "Laryak",
    "city_ascii": "Laryak",
    "lat": 61.1012,
    "lng": 80.2514,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Khanty-Mansiyskiy Avtonomnyy Okrug-Yugra",
    "capital": "",
    "population": 10,
    "id": 1643861109
  },
  {
    "city": "Lagunas",
    "city_ascii": "Lagunas",
    "lat": -20.9829,
    "lng": -69.6833,
    "country": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "admin_name": "Tarapacá",
    "capital": "",
    "population": 10,
    "id": 1152360178
  },
  {
    "city": "Andoas",
    "city_ascii": "Andoas",
    "lat": -2.9021,
    "lng": -76.4025,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "Loreto",
    "capital": "",
    "population": 10,
    "id": 1604038416
  },
  {
    "city": "Puca Urco",
    "city_ascii": "Puca Urco",
    "lat": -2.3328,
    "lng": -71.9167,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "Loreto",
    "capital": "",
    "population": 10,
    "id": 1604085670
  },
  {
    "city": "Zillah",
    "city_ascii": "Zillah",
    "lat": 28.5504,
    "lng": 17.5834,
    "country": "Libya",
    "iso2": "LY",
    "iso3": "LBY",
    "admin_name": "Al Jufrah",
    "capital": "",
    "population": 10,
    "id": 1434245699
  },
  {
    "city": "Ulkan",
    "city_ascii": "Ulkan",
    "lat": 55.9004,
    "lng": 107.7833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Irkutskaya Oblast’",
    "capital": "",
    "population": 10,
    "id": 1643020424
  },
  {
    "city": "Barnīs",
    "city_ascii": "Barnis",
    "lat": 23.946,
    "lng": 35.4842,
    "country": "Egypt",
    "iso2": "EG",
    "iso3": "EGY",
    "admin_name": "Al Baḩr al Aḩmar",
    "capital": "",
    "population": 10,
    "id": 1818107956
  },
  {
    "city": "Soldado Bartra",
    "city_ascii": "Soldado Bartra",
    "lat": -2.5161,
    "lng": -75.7666,
    "country": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "admin_name": "Loreto",
    "capital": "",
    "population": 10,
    "id": 1604302193
  },
  {
    "city": "Strelka",
    "city_ascii": "Strelka",
    "lat": 61.867,
    "lng": 152.2502,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Magadanskaya Oblast’",
    "capital": "",
    "population": 10,
    "id": 1643297872
  },
  {
    "city": "Bol’sheretsk",
    "city_ascii": "Bol'sheretsk",
    "lat": 52.439,
    "lng": 156.3594,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kamchatskiy Kray",
    "capital": "",
    "population": 10,
    "id": 1643981807
  },
  {
    "city": "Karamken",
    "city_ascii": "Karamken",
    "lat": 60.2004,
    "lng": 151.1666,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Magadanskaya Oblast’",
    "capital": "",
    "population": 10,
    "id": 1643511192
  },
  {
    "city": "Ust’-Nyukzha",
    "city_ascii": "Ust'-Nyukzha",
    "lat": 56.5312,
    "lng": 121.6131,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Amurskaya Oblast’",
    "capital": "",
    "population": 10,
    "id": 1643347046
  },
  {
    "city": "Djado",
    "city_ascii": "Djado",
    "lat": 21.015,
    "lng": 12.3075,
    "country": "Niger",
    "iso2": "NE",
    "iso3": "NER",
    "admin_name": "Agadez",
    "capital": "",
    "population": 10,
    "id": 1562138807
  },
  {
    "city": "Omchak",
    "city_ascii": "Omchak",
    "lat": 61.6333,
    "lng": 147.9167,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Magadanskaya Oblast’",
    "capital": "",
    "population": 10,
    "id": 1643827519
  },
  {
    "city": "Shalaurova",
    "city_ascii": "Shalaurova",
    "lat": 73.2204,
    "lng": 143.1833,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sakha (Yakutiya)",
    "capital": "",
    "population": 10,
    "id": 1643617349
  },
  {
    "city": "Khorgo",
    "city_ascii": "Khorgo",
    "lat": 73.4833,
    "lng": 113.63,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sakha (Yakutiya)",
    "capital": "",
    "population": 10,
    "id": 1643550363
  },
  {
    "city": "Komsa",
    "city_ascii": "Komsa",
    "lat": 61.868,
    "lng": 89.2577,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Krasnoyarskiy Kray",
    "capital": "",
    "population": 10,
    "id": 1643255641
  },
  {
    "city": "Pakhachi",
    "city_ascii": "Pakhachi",
    "lat": 60.5816,
    "lng": 169.05,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kamchatskiy Kray",
    "capital": "",
    "population": 10,
    "id": 1643406349
  },
  {
    "city": "Indiga",
    "city_ascii": "Indiga",
    "lat": 67.6898,
    "lng": 49.0166,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Nenetskiy Avtonomnyy Okrug",
    "capital": "",
    "population": 10,
    "id": 1643522900
  },
  {
    "city": "Sagastyr",
    "city_ascii": "Sagastyr",
    "lat": 73.3779,
    "lng": 126.5924,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sakha (Yakutiya)",
    "capital": "",
    "population": 10,
    "id": 1643535665
  },
  {
    "city": "Chagda",
    "city_ascii": "Chagda",
    "lat": 60.1,
    "lng": 133.9,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sakha (Yakutiya)",
    "capital": "",
    "population": 10,
    "id": 1643773533
  },
  {
    "city": "Il’pyrskiy",
    "city_ascii": "Il'pyrskiy",
    "lat": 59.9674,
    "lng": 164.172,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kamchatskiy Kray",
    "capital": "",
    "population": 10,
    "id": 1643660854
  },
  {
    "city": "Tunguskhaya",
    "city_ascii": "Tunguskhaya",
    "lat": 64.9004,
    "lng": 125.25,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sakha (Yakutiya)",
    "capital": "",
    "population": 10,
    "id": 1643610062
  },
  {
    "city": "Podkamennaya Tunguska",
    "city_ascii": "Podkamennaya Tunguska",
    "lat": 61.5995,
    "lng": 90.1236,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Krasnoyarskiy Kray",
    "capital": "",
    "population": 10,
    "id": 1643764711
  },
  {
    "city": "Siglan",
    "city_ascii": "Siglan",
    "lat": 59.0337,
    "lng": 152.4166,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Magadanskaya Oblast’",
    "capital": "",
    "population": 10,
    "id": 1643408923
  },
  {
    "city": "Utkholok",
    "city_ascii": "Utkholok",
    "lat": 57.5504,
    "lng": 157.2333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Kamchatskiy Kray",
    "capital": "",
    "population": 10,
    "id": 1643251905
  },
  {
    "city": "Varnek",
    "city_ascii": "Varnek",
    "lat": 69.7301,
    "lng": 60.0636,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Nenetskiy Avtonomnyy Okrug",
    "capital": "",
    "population": 10,
    "id": 1643378778
  },
  {
    "city": "Trofimovsk",
    "city_ascii": "Trofimovsk",
    "lat": 72.5997,
    "lng": 127.0337,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sakha (Yakutiya)",
    "capital": "",
    "population": 10,
    "id": 1643214114
  },
  {
    "city": "Matochkin Shar",
    "city_ascii": "Matochkin Shar",
    "lat": 73.27,
    "lng": 56.4497,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Arkhangel’skaya Oblast’",
    "capital": "",
    "population": 10,
    "id": 1643843865
  },
  {
    "city": "Menkerya",
    "city_ascii": "Menkerya",
    "lat": 67.9886,
    "lng": 123.3505,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sakha (Yakutiya)",
    "capital": "",
    "population": 10,
    "id": 1643637503
  },
  {
    "city": "Khakhar",
    "city_ascii": "Khakhar",
    "lat": 57.6666,
    "lng": 135.43,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Khabarovskiy Kray",
    "capital": "",
    "population": 10,
    "id": 1643315253
  },
  {
    "city": "Zvëzdnyy",
    "city_ascii": "Zvezdnyy",
    "lat": 70.9566,
    "lng": -179.59,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Chukotskiy Avtonomnyy Okrug",
    "capital": "",
    "population": 10,
    "id": 1643877771
  },
  {
    "city": "Zemlya Bunge",
    "city_ascii": "Zemlya Bunge",
    "lat": 74.8983,
    "lng": 142.105,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sakha (Yakutiya)",
    "capital": "",
    "population": 10,
    "id": 1643720805
  },
  {
    "city": "Starorybnoye",
    "city_ascii": "Starorybnoye",
    "lat": 72.7666,
    "lng": 104.8,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Krasnoyarskiy Kray",
    "capital": "",
    "population": 10,
    "id": 1643724242
  },
  {
    "city": "Agapa",
    "city_ascii": "Agapa",
    "lat": 71.4504,
    "lng": 89.25,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Krasnoyarskiy Kray",
    "capital": "",
    "population": 10,
    "id": 1643009087
  },
  {
    "city": "Tukchi",
    "city_ascii": "Tukchi",
    "lat": 57.367,
    "lng": 139.5,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Khabarovskiy Kray",
    "capital": "",
    "population": 10,
    "id": 1643472801
  },
  {
    "city": "Numto",
    "city_ascii": "Numto",
    "lat": 63.6667,
    "lng": 71.3333,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Khanty-Mansiyskiy Avtonomnyy Okrug-Yugra",
    "capital": "",
    "population": 10,
    "id": 1643985006
  },
  {
    "city": "Olenëk",
    "city_ascii": "Olenek",
    "lat": 68.525,
    "lng": 112.45,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sakha (Yakutiya)",
    "capital": "",
    "population": 10,
    "id": 1643535703
  },
  {
    "city": "Nord",
    "city_ascii": "Nord",
    "lat": 81.7166,
    "lng": -17.8,
    "country": "Greenland",
    "iso2": "GL",
    "iso3": "GRL",
    "admin_name": "Sermersooq",
    "capital": "",
    "population": 10,
    "id": 1304217709
  },
  {
    "city": "Timmiarmiut",
    "city_ascii": "Timmiarmiut",
    "lat": 62.5333,
    "lng": -42.2167,
    "country": "Greenland",
    "iso2": "GL",
    "iso3": "GRL",
    "admin_name": "Kujalleq",
    "capital": "",
    "population": 10,
    "id": 1304206491
  },
  {
    "city": "Cheremoshna",
    "city_ascii": "Cheremoshna",
    "lat": 51.3894,
    "lng": 30.0989,
    "country": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "admin_name": "Kyyivs’ka Oblast’",
    "capital": "",
    "population": 0,
    "id": 1804043438
  },
  {
    "city": "Ambarchik",
    "city_ascii": "Ambarchik",
    "lat": 69.651,
    "lng": 162.3336,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Sakha (Yakutiya)",
    "capital": "",
    "population": 0,
    "id": 1643739159
  },
  {
    "city": "Nordvik",
    "city_ascii": "Nordvik",
    "lat": 74.0165,
    "lng": 111.51,
    "country": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "admin_name": "Krasnoyarskiy Kray",
    "capital": "",
    "population": 0,
    "id": 1643587468
  }
]
